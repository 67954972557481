import { Directive, ElementRef, Input, HostListener } from '@angular/core';

//Has angularjs twin
@Directive({
	selector: '[blockKbNavigation]'
})
export class BlockKbNavigationDirective {
	@Input('blockKbNavigation') blockKb: boolean;

	constructor(private readonly el: ElementRef) {}

	@HostListener('window:keydown.tab', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		if (this.blockKb) {
			$(this.el.nativeElement).find(':focusable').attr('tabindex', -1);
		}
	}
}
