import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { SentenceMetadataService } from '@cxstudio/reports/preview/sentence-metadata.service';

export class SentenceMetadataComponent implements ng.IComponentController {

	documentManager: IDocumentPreviewerControls;
	sentence;
	formatters: {
		ease: (sentence) => string;
		sentiment: (sentence) => string;
		date: (documentDate) => string;
	};

	constructor(private sentenceMetadataService: SentenceMetadataService) {
	}

	$onInit = () => {
		// required for typescript validation
	}

	showSentenceSelected = (): boolean => this.documentManager.isFeedbackSelectionEnabled()
		&& this.documentManager.isSentenceItemCurated(this.sentence)

	isFeedbackSharingMode = (): boolean => this.documentManager.isFeedbackSharingAvailable();

	hasEase = (): boolean => !!(this.formatters.ease) && this.sentenceMetadataService.hasEaseScore(this.sentence);

	hasEmotion = (): boolean => !!(this.formatters.ease) && this.sentenceMetadataService.hasEmotion(this.sentence);
}

app.component('sentenceMetadata', {
	bindings: {
		documentManager: '<',
		sentence: '<',
		formatters: '<',
		showSentiment: '<',
		showSource: '<'
	},
	controller: SentenceMetadataComponent,
	template: `
<div class="sentence-metadata">
	<div class="sentence-icons">
		<div
			ng-show="$ctrl.showSentenceSelected()"
			class="feedback-preview-icon feedback-preview-selection-toggled-icon mr-8">
				<i class="analytic-feedback-selection-flag toggled" ng-class="$ctrl.isFeedbackSharingMode() ? 'q-icon-binder-clip' : 'q-icon-check'"></i>
		</div>
		<div class="sentiment-icons" ng-show="$ctrl.showSentiment">
			<div class="lh-1 mr-8">
				<div ng-bind-html="::$ctrl.formatters.sentiment($ctrl.sentence) | htmlSafe:true"></div>
			</div>
			<div class="lh-1 feedback-preview-ease-icon mr-8" ng-show="::$ctrl.hasEase($ctrl.sentence)">
				<div ng-bind-html="::$ctrl.formatters.ease($ctrl.sentence) | htmlSafe:true"></div>
			</div>
			<div class="lh-1 feedback-preview-emotion-icon mr-8" ng-show="::$ctrl.hasEmotion($ctrl.sentence)">
				<div ng-bind-html="::$ctrl.formatters.emotion($ctrl.sentence) | htmlSafe:true"></div>
			</div>
		</div>
		<div ng-show="$ctrl.showSource">
			<div class="icon-highlight feedback-preview-icon feedback-preview-source-icon text-white bg-facebook-color"
				ng-show="::$ctrl.sentence.smService==='facebook'">
				<i class="q-icon-facebook"></i>
			</div>
			<div class="icon-highlight feedback-preview-icon feedback-preview-source-icon text-white bg-twitter-color"
				ng-show="::$ctrl.sentence.smService==='twitter'">
				<i class="q-icon-twitter"></i>
			</div>
		</div>
	</div>

	<div class="dex-sentence-date">
		<label class="mb-0" title="{{::$ctrl.formatters.time($ctrl.sentence.documentDate)}}">
			{{::$ctrl.formatters.date($ctrl.sentence.documentDate)}}
		</label>
	</div>
</div>`
});
