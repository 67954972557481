<div class="bg-white d-flex flex-direction-column h-100-percent">
	<div class="p-16 d-flex">
		<h2 class="m-0">{{header}}</h2>
	</div>
	<div class="overflow-x-hidden overflow-y-auto flex-fill">
		<ng-container *ngTemplateOutlet="template;context:{$implicit:this}"></ng-container>
	</div>
	<div class="d-flex justify-between ph-16 pv-8 border-t-1 border-t-solid">
		<button
			id="btn-properties-discard"
			type="button"
			class="btn btn-secondary"
			(click)="onDiscard()">
			{{'common.discard'|i18n}}</button>
		<button
			id="btn-properties-apply"
			type="button"
			class="btn btn-primary"
			[disabled]="!isValid()"
			(click)="onApply()">
			{{'common.apply'|i18n}}</button>
	</div>
</div>
