import { Injectable } from '@angular/core';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { GroupColorUtils } from '@cxstudio/reports/utils/color/group-color-utils';

@Injectable({
	providedIn: 'root'
})
export class LegendSeriesRulesService {

	constructor(
	) { }

	// do not process data point for legend if sentiment, metric, or custom color that is not recolored
	skipDataPoint(dataPoint: IDataPoint, options: VisualProperties, processedColors: string[]): boolean {
		if (ColorUtilsHelper.dataPointHasRecolor(options, dataPoint)) return false;
		
		return this.isNonPointSentimentColor(dataPoint, options) 
			|| this.isNonPointMetricColor(dataPoint, options) 
			|| this.isPopLightensDynamicColor(dataPoint, options) 
			// if it's pop color, set to custom, 
			// and if we've already processed it once, don't process it again (only show in legend once)
			|| (this.isProcessedCustomColorPop(dataPoint, options, processedColors) && this.isUnrecoloredCustom(dataPoint, options)); 
	}

	// if it's sentiment color we can skip it unless it is pointColor, in which case we need to process to get the line color
	isNonPointSentimentColor(dataPoint: IDataPoint, options: VisualProperties) {
		let colorField = this.getNonPointColorField(dataPoint);
		return ColorUtilsHelper.isSentimentColor(options, colorField) 
				|| this.isColorSentiment(options, colorField);
	}

	private isColorSentiment(visualProps: VisualProperties, colorName: ColorTypes) {
		let sentiment3 = ColorUtils.GROUP_COLOR_PREFIX + PredefinedMetricConstants.SENTIMENT_3;
		let sentiment5 = ColorUtils.GROUP_COLOR_PREFIX + PredefinedMetricConstants.SENTIMENT_5;

		return [sentiment3, sentiment5].contains(visualProps[colorName]);
	}

	// if it's metric color we can skip it unless it is pointColor, in which case we need to process to get the line color
	isNonPointMetricColor(dataPoint: IDataPoint, options: VisualProperties) {
		let colorField = this.getNonPointColorField(dataPoint);
		let isCalculationColor = CalculationColorUtils.isCalculationColor(options[colorField]) 
				|| GroupColorUtils.isGroupColor(options[colorField]);
		let isNotSentimentColor = !ColorUtilsHelper.isSentimentColor(options, colorField);

		return isCalculationColor && isNotSentimentColor;
	}

	private getNonPointColorField(dataPoint: IDataPoint): ColorTypes {
		let colorField = dataPoint.object.colorType;
		let pointColorToColorMapping = {
			pointColor: 'color',
			secondaryPointColor: 'secondaryColor'
		};
		if (this.isPointColorField(colorField)) {
			colorField = pointColorToColorMapping[colorField];
		}
		return colorField;
	}

	isUnrecoloredCustom(dataPoint: IDataPoint, options: VisualProperties): boolean {
		return this.isCustomColor(options[dataPoint.object.colorType]) 
				&& (dataPoint.color === ColorUtilsHelper.getCustomColor(options, dataPoint.object.colorType));
	}

	isCustomColor(color: string): boolean {
		return color === ColorPaletteNames.CUSTOM;
	}

	isRecolored(dataPoint: IDataPoint, options: VisualProperties): boolean {
		return _.find(options.recolors, ColorUtilsHelper.findMatchedRecolor(dataPoint.object));
	}

	isRecoloredCustom(dataPoint: IDataPoint, options: VisualProperties): boolean {
		return this.isCustomColor(options[dataPoint.object.colorType]) 
				&& (dataPoint.color !== ColorUtilsHelper.getCustomColor(options, dataPoint.object.colorType));
	}

	isPointColorField(field: ColorTypes): boolean {
		return (field === ColorTypes.POINT || field === ColorTypes.SECONDARY_POINT);
	}

	isPopColorField(field: ColorTypes): boolean {
		return (field === ColorTypes.PERIOD_OVER_PERIOD || field === ColorTypes.SECONDARY_PERIOD_OVER_PERIOD);
	}

	disableColorClick(dataPoint: IDataPoint, options: VisualProperties) {
		return this.isRecoloredCustom(dataPoint, options) 
			|| ColorUtilsHelper.isObjectBasedColor(options, dataPoint.object.colorType);
	}

	// check if pop color is lightening a dynamic color
	// if it's pop color, lightening, and the main color is a dynamic color (sentiment/metric), do not show lightened color in legend
	isPopLightensDynamicColor(dataPoint: IDataPoint, options: VisualProperties): boolean {
		let isLighten = (options[dataPoint.object.colorType] === ColorPaletteNames.LIGHTEN);
		return this.isPopColorField(dataPoint.object.colorType) && isLighten && this.isPopBaseColorDynamic(dataPoint, options);
	}

	// check if base color for pop item is dynamic (sentiment or metric)
	isPopBaseColorDynamic(dataPoint: IDataPoint, options: VisualProperties): boolean {
		let colorProperty = (dataPoint.object.colorType === 'popColor') ? ColorTypes.PRIMARY : ColorTypes.SECONDARY;
		return ColorUtilsHelper.isObjectBasedColor(options, colorProperty);
	}

	// we only want to process custom colored pop one time
	isProcessedCustomColorPop(dataPoint: IDataPoint, options: VisualProperties, processedColors: string[]): boolean {
		if (!this.isCustomColor(options[dataPoint.object.colorType])) return false;

		if (processedColors.indexOf(dataPoint.object.colorType) >= 0) {
			return true;
		} else {
			processedColors.push(dataPoint.object.colorType); // TODO this is very ugly, refactor when have a chance
			return false;
		}
	}
}
