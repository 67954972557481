<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.updateBulkLicenseType' | i18n">
</modal-header>

<div class="modal-body" [ngBusy]=loading.promise>
	<div class="mb-16">
		<label>{{getLicenseTypeBodyText()}}</label>
	</div>
	<div class="mb-16">
		<label>{{'administration.newLicenseType' | i18n}}</label>
		<simple-dropdown
				[options]="input.availableLicenses"
				[(value)]="selectedLicense"
				sortField="displayName"
				displayField="licenseTypeName"
				(onChange)="changeLicenseType($event)"
				selectPrompt="{{'administration.licenseType'|i18n}}">
		</simple-dropdown>
	</div>
	<div class="labeled-checkbox mb-0 labeled-checkbox-inline">
		<checkbox-button
			name="inheritPermissions"
			id="inheritPermissions"
			[buttonClass]="'btn-sm'"
			label="{{'administration.inheritDefaultPermissions'|i18n}}"
			inlineHelp="{{'administration.inheritPermissionsTooltip'|i18n}}"
			[(ngModel)]="output.inheritPermissions">
		</checkbox-button>
	</div>
</div>

<div class="modal-footer">
	<button
			id="btn-dialog-cancel"
			type="button"
			class="btn btn-secondary"
			(click)="cancel()">{{'common.cancel'|i18n}}
	</button>
	<button
		id="btn-dialog-continue"
		type="button"
		[disabled]="!licenseSelected()"
		class="btn btn-primary"
		(click)="save()">{{'common.save'|i18n}}
	</button>
</div>
