import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { SidebarEditorComponent } from '@app/modules/layout/sidebar-editor/sidebar-editor.component';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { Deferred, PromiseUtils } from '@app/util/promise-utils';
import { Observable, Subject } from 'rxjs';

export interface PreviewUpdateEntity {
	entity: any;
	refreshType?: PreviewChartRefreshType;
}

@Injectable({
	providedIn: 'root'
})
export class SidebarEditorService {
	private editorRef: ComponentRef<SidebarEditorComponent>;
	private entity: any;
	private deferredResult: Deferred<any>;

	private readonly previewUpdateSubject = new Subject<PreviewUpdateEntity>();

	constructor(
		private resolver: ComponentFactoryResolver,
		private injector: Injector,
		private appRef: ApplicationRef,
	) {}

	getPreviewUpdateObserver(): Observable<PreviewUpdateEntity> {
		return this.previewUpdateSubject;
	}

	setPreviewUpdateChange(previewUpdateEntity: PreviewUpdateEntity): void {
		this.entity = previewUpdateEntity.entity;
		this.previewUpdateSubject.next(previewUpdateEntity);
	}

	open<T>(
		entity: T,
		settingsComponent: Type<any>,
		previewComponent: Type<any>,
		containerSelector: string
	): Promise<T> {
		if (this.isActive()) {
			console.warn('Editor was already opened');
			return Promise.reject();
		}
		this.entity = entity;
		let settingsRef = this.createFromComponent(settingsComponent);
		let previewRef = this.createFromComponent(previewComponent);
		this.editorRef = this.attachEditorComponent(
			containerSelector, [settingsRef, previewRef]);
		this.editorRef.changeDetectorRef.detectChanges();
		this.editorRef.onDestroy(() => {
			settingsRef.destroy();
			previewRef.destroy();
		});

		this.deferredResult = PromiseUtils.defer<T>();
		return this.deferredResult.promise;
	}

	getEntity<T>(): T {
		return this.entity;
	}

	setEntity<T>(entity: T): void {
		this.entity = entity;
	}

	apply(): void {
		if (!this.isActive()) {
			console.warn('There is no active editor');
			return;
		}
		this.deferredResult.resolve(this.entity);
		this.removeModalElements();
	}

	dismiss(): void {
		if (!this.isActive()) {
			console.warn('There is no active editor');
			return;
		}
		this.deferredResult.reject();
		this.removeModalElements();
	}

	openProperties<T>(header: string, template: TemplateRef<unknown>): Promise<T> {
		if (!this.isActive()) {
			console.warn('There is no active editor');
			return;
		}
		return this.editorRef.instance.openProperties(header, template);
	}

	private isActive(): boolean {
		return !!this.editorRef;
	}

	private createFromComponent<T>(
		componentType: Type<any>
	): ComponentRef<T> {
		let factory = this.resolver.resolveComponentFactory(componentType);
		let componentRef = factory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);
		return componentRef;
	}

	private attachEditorComponent(
		containerSelector: string, children: ComponentRef<any>[]
	): ComponentRef<SidebarEditorComponent> {
		let containerEl = document.querySelector(containerSelector);
		let factory = this.resolver.resolveComponentFactory(SidebarEditorComponent);
		let projectableNodes = children.map(componentRef => Array.from(componentRef.location.nativeElement.childNodes));
		let editorRef = factory.create(this.injector, projectableNodes);
		this.appRef.attachView(editorRef.hostView);
		containerEl.appendChild(editorRef.location.nativeElement);

		editorRef.onDestroy(() => {
			this.editorRef = null;
			this.resetState();
		});
		return editorRef;
	}

	private removeModalElements(): void {
		const { nativeElement } = this.editorRef.location;
		nativeElement.parentNode.removeChild(nativeElement);
		this.editorRef.destroy();
	}

	private resetState(): void {
		this.editorRef = null;
		this.entity = null;
		this.deferredResult = null;
	}
}
