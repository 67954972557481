import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AppLogoComponent } from './app-logo/app-logo.component';
import { HeaderModule } from '@app/modules/header/header.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		HeaderModule,
	],
	exports: [
		AuthCallbackComponent,
		AppLogoComponent
	],
	declarations: [
		AuthCallbackComponent,
		AppLogoComponent,
	],
})
export class LoginModule { }
