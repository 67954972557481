import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'highlightMatch'
})

export class HighlightMatchPipe implements PipeTransform {
	transform(item: string, matchString: string): string {
		if (!matchString) return item;

		return item.replace(new RegExp(`(${matchString.escapeRegExp()})`, 'gi'), `<b>$1</b>`);
	}
}