import EngagorCasePriority from './engagor-case-priority';
import EngagorCaseAssignment from './engagor-case-assignment';
import EngagorCaseSource from './engagor-case-source';
import EngagorCaseDate from './engagor-case-date';
import EngagorCaseAction from './engagor-case-action';
import EngagorCaseTopic from './engagor-case-topic';


export default class EngagorCase {
	id: string;
	status: string;
	permalink: string;
	priority: EngagorCasePriority;
	assignment: EngagorCaseAssignment;
	source: EngagorCaseSource;
	topic: EngagorCaseTopic;
	message?: {title: string};
	date: EngagorCaseDate;
	actions: EngagorCaseAction[];

}
