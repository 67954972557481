import { EventEmitter } from '@angular/core';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';


export class ProjectSelectorData<T> {
	static readonly NO_SELECTION: number = -1;
	private options: T[];
	private selection: number;
	onChange = new EventEmitter<number>();
	invalidSelection = new EventEmitter<void>();

	constructor(
		private idField: string,
		selection: number = ProjectSelectorData.NO_SELECTION,
		private nameField?: string
	) {
		//this.options = [];
		this.selection = selection;
	}

	hasOptions(): boolean {
		return this.options && !this.options.isEmpty();
	}

	getOptions(): T[] {
		return this.options;
	}

	hasSingleOption(): boolean {
		return this.options?.length === 1;
	}

	hasMultipleOptions(): boolean {
		return this.options?.length > 1;
	}

	getOptionsAmount(): OptionsAmount {
		if (!this.hasOptions) {
			return OptionsAmount.NO_OPTIONS;
		}
		if (this.hasSingleOption()) {
			return OptionsAmount.SINGLE_OPTION;
		}
		return this.options.length > 10
			? OptionsAmount.MANY_OPTIONS
			: OptionsAmount.SEVERAL_OPTIONS;
	}

	isSelected(): boolean {
		return this.selection !== ProjectSelectorData.NO_SELECTION;
	}

	getSelection(): number {
		return this.selection;
	}

	getSelectedObject(): T | null {
		return this.isSelected()
			? this.getOptions().find(option => option[this.idField] === this.selection)
			: null;
	}

	setSelection(selection: number): void {
		this.selection = selection;
	}

	getSelectionName(): string {
		if (!this.isSelected() || !this.nameField) {
			return '';
		}
		let searchObject = {};
		searchObject[this.idField] = this.selection;
		let selectedObject = _.findWhere(this.options, searchObject);
		if (selectedObject && selectedObject[this.nameField]) {
			return selectedObject[this.nameField];
		}
		return '';

	}

	changeSelection(selection: number): void {
		this.selection = selection;
		this.onChange.emit(this.selection);
	}

	setOptions(options: T[]): void {
		this.options = options;
		if (this.hasOptions()) {
			this.validateSelection();
			if (this.hasSingleOption() && this.options[0][this.idField] !== this.selection) {
				this.changeSelection(this.options[0][this.idField]);
			}
		}
	}

	clearSelection(): void {
		if (!this.hasSingleOption()) {
			//only set to no selection if there is more than one option
			this.selection = ProjectSelectorData.NO_SELECTION;
		}
		this.onChange.emit(this.selection);
	}

	clear(): void {
		this.options = [];
		this.clearSelection();
	}

	private validateSelection(): void {
		let searchObject = {};
		searchObject[this.idField] = this.selection;
		if (this.isSelected() && !_.findWhere(this.options, searchObject)) {
			this.clearSelection();
			this.invalidSelection.emit();
		}
	}
}
