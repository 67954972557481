import { Directive, ElementRef, Input, HostBinding } from '@angular/core';

/*
 * Has AngularJS twin
 */
@Directive({
	selector: '[btnLoading]'
})
export class BtnLoadingDirective {

	private element: HTMLElement;
	private loading;
	private btnText;
	@Input() loadingText;
	// "external" disabled attribute, to fix interaction with [disabled] of a button, for instance
	@Input() disabled: boolean;

	constructor(el: ElementRef) {
		this.element = el.nativeElement;
	}

	@Input() set btnLoading(value: boolean) {
		if (!this.btnText) this.btnText = this.element.innerHTML;
		this.loading = value;

		const text = document.createTextNode(this.loading ? this.loadingText : this.btnText);
		// TS thinks replaceChildren does not exist on HTMLElement...
		if ((this.element as any).replaceChildren) {
			(this.element as any).replaceChildren(text);
		} else {
			// ... protractor agrees with TS :(
			$(this.element).empty();
			this.element.append(text);
		}
	}

	@HostBinding('class.disabled') get disabledClass(): boolean { return this.disabled || this.loading; }
	@HostBinding('disabled') get disabledAttribute(): boolean { return this.disabled || this.loading; }
}
