<div id="quick-search-panel" *ngIf="quickInsightsEnabled" class="pv-8 ph-24 d-flex">
	<quick-insights-search
		class="flex-fill pr-16"
		[homePage]="homePage"
		[searchValue]="searchValue"
		(search)="onSearch($event)">
	</quick-insights-search>
	<div class='home-page-actions d-flex'>
		<home-page-personalization *ngIf="hasPersonalization" [homePage]="homePage" (nodeChange)="onNodeChange($event)">
		</home-page-personalization>
		<home-page-menu></home-page-menu>
	</div>
</div>
<div id="home-page-title" class="ph-24 pv-16 d-flex bg-main">
	<h1 class="flex-fill mb-0 text-lg-1 font-bold">{{homePage.name || ('homePage.defaultName'|i18n)}}</h1>
	<div *ngIf="!quickInsightsEnabled" class='home-page-actions d-flex'>
		<home-page-personalization *ngIf="hasPersonalization" (nodeChange)="onNodeChange($event)">
		</home-page-personalization>
		<home-page-menu></home-page-menu>
	</div>
</div>
