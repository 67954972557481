
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ListColumnFilter } from '@app/shared/components/filter/list-column-filter';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Component({
	selector: 'models-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<object-list-filter
			[columns]="columns"
			[pinnedColumns]="pinnedColumns"
			(apply)="applyFilters($event)"
		></object-list-filter>
	`
})
export class ModelsFilterComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Output() apply = new EventEmitter<ObjectListFilter[]>();

	columns: ColumnFilterOption[];
	pinnedColumns: ColumnFilterOption[];

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		let name: ColumnFilterOption = {
			name: this.locale.getString('common.name'),
			value: 'name',
			columnFilterType: ColumnFilterType.TEXT
		};

		let lastClassified: ColumnFilterOption = {
			name: this.locale.getString('administration.lastClassified'),
			value: 'lastClassified',
			columnFilterType: ColumnFilterType.DATE
		};

		this.columns = [name, lastClassified];
		this.columns.pushAll(this.getFlagColumns());
	}

	getFlagColumns = (): ListColumnFilter[] => {
		let flagOptions: Array<ListOption<string>> = [{
			name: this.locale.getString('common.yes'),
			value: 'true'
		}, {
			name: this.locale.getString('common.no'),
			value: 'false'
		}];

		return [{
			name: this.locale.getString('administration.incrementalDataLoads'),
			value: 'incrementalDataLoads',
			columnFilterType: ColumnFilterType.FLAG,
			options: flagOptions
		}, {
			name: this.locale.getString('administration.localOther'),
			value: 'includeLocalOther',
			columnFilterType: ColumnFilterType.FLAG,
			options: flagOptions
		}, {
			name: this.locale.getString('administration.emptyRecords'),
			value: 'includeEmptyRecords',
			columnFilterType: ColumnFilterType.FLAG,
			options: flagOptions
		}];
	}


	applyFilters = (filters: ObjectListFilter[]): void => {
		this.apply.emit(filters);
	}
}

app.directive('modelsFilter', downgradeComponent({component: ModelsFilterComponent}) as angular.IDirectiveFactory);
