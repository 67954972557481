
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as _ from 'underscore';
import { CxLocaleService } from '@app/core';
import { DialogStyle, DialogStyleUtils } from '@app/modules/dialog/dialog-style';

@Component({
	selector: 'save-modal-footer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<footer class="modal-footer">
		<button
			id="btn-dialog-cancel"
			type="button"
			class="btn btn-secondary"
			[disabled]="isLoading"
			(click)="cancel.emit()">{{cancelText}}</button>
		<button *ngIf="secondaryBtnTxt"
			id="btn-unsaved-not-save"
			type="button"
			class="btn btn-secondary"
			(click)="secondaryAction.emit()">{{secondaryBtnTxt}}</button>
		<button
			id="btn-dialog-save"
			type="button"
			class="btn"
			[ngClass]="getStyleClass()"
			[disabled]="isDisabled"
			(click)="save.emit()">
			<span *ngIf="!isLoading">{{saveText}}</span>
			<icon *ngIf="isLoading" name="spinner" class="rotate-infinite"></icon>
		</button>
	</footer>
	`
})

export class SaveModalFooterComponent implements OnInit {
	@Input() saveText: string;
	@Input() secondaryBtnTxt: string;
	@Input() cancelText: string;
	@Output() save: EventEmitter<string> = new EventEmitter();
	@Output() secondaryAction: EventEmitter<string> = new EventEmitter();
	@Output() cancel: EventEmitter<string> = new EventEmitter();
	@Input() isDisabled: boolean;
	@Input() isLoading: boolean;
	@Input() style: DialogStyle;

	constructor(private locale: CxLocaleService) { }

	ngOnInit(): void {
		if (!this.saveText)
			this.saveText = this.locale.getString('common.save');
		if (!this.cancelText)
			this.cancelText = this.locale.getString('common.cancel');
	}

	getStyleClass(): string {
		return DialogStyleUtils.getButtonClass(this.style);
	}
}

app.directive('saveModalFooter', downgradeComponent({component: SaveModalFooterComponent}) as angular.IDirectiveFactory);
