export class WidgetColorPalette {
	id?: number;
	name: string;
	displayName: string;
	colors: string[];
	darkModeColors?: string[];
	enabled: boolean;
	deleted?: boolean;
	replacement?: number;
	system?: boolean;
	designerPalette?: boolean;
	defaultPalette?: boolean;
}


