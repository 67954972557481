import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { UserQueryParams } from '@app/modules/user-bulk/user-query-params';
import { LicenseType } from '@cxstudio/common/license-types';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import Group from '@cxstudio/user-administration/groups/Group';
import { GroupQueryParams } from '@cxstudio/user-administration/groups/group-query-params';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { BulkAccessMode } from '@cxstudio/user-administration/users/project-access/bulk-access-mode';
import { BulkPermissionsUpdate } from '../bulk-permissions/bulk-permissions.component';
import { BulkDataAccessUpdate } from '../user-bulk-data-access/user-bulk-data-access.component';

@Injectable({
	providedIn: 'root'
})
export class UserModificationApiService {

	constructor(
		private cxHttp: CxHttpService,
	) { }

	callBulkGroupUpdateAPI = (
		userIds: number[],
		groups: { added?: Group[], removed?: Group[] },
		queryParams: UserQueryParams
	): Promise<any> => {
		if (_.isEmpty(groups.added) && _.isEmpty(groups.removed)) {
			return Promise.resolve();
		}

		const params = CxHttpUtils.objectToParams(queryParams);

		//backend api logic, it only support added or removed per request
		const bulkData = {
			users: userIds,
			added: _.map(groups.added, (group: Group) => {
				return group.groupId;
			}),
			removed: _.map(groups.removed, (group: Group) => {
				return group.groupId;
			})
		};

		const url = 'rest/groups/bulk/group-membership/update';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params });
	}

	callBulkUserMembershipUpdateAPI = (groupIds: number[], users, queryParams: GroupQueryParams): Promise<any> => {
		if (_.isEmpty(users.added) && _.isEmpty(users.removed)) {
			return  Promise.resolve();
		}

		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			groupIds,
			added: _.map(users.added, (user: User) => {
				return user.userId;
			}),
			removed: _.map(users.removed, (user: User) => {
				return user.userId;
			})
		};

		const url = 'rest/groups/bulk/user-membership/update';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params });
	}

	updateUsersInGroup = (group: Group, users: { added?: number[], removed?: number[] }): Promise<any[]> => {
		const addedPromise = _.isEmpty(users.added)
			?  Promise.resolve()
			: this.callBulkGroupUpdateAPI(users.added, { added: [group] }, {});

		const removedPromise = _.isEmpty(users.removed)
			?  Promise.resolve()
			: this.callBulkGroupUpdateAPI(users.removed, { removed: [group] }, {});

		return Promise.all([addedPromise, removedPromise]);
	}

	updateGroupsForUser = (user: number, groups: { added?: Group[], removed?: Group[] }): Promise<any> => {
		return this.callBulkGroupUpdateAPI([user], groups, {});
	}

	callBulkPermissionsUpdateAPI = (
		userIds: number[],
		permissions: BulkPermissionsUpdate,
		queryParams: UserQueryParams
	): Promise<any> => {
		if (_.isEmpty(permissions.added) && _.isEmpty(permissions.removed)) {
			return  Promise.resolve();
		}

		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			users: userIds,
			added: permissions.added,
			removed: permissions.removed
		};

		const url = 'rest/users/bulk/permissions/update';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params });
	}

	updateExistingUsersPermissionsForLicenseType = (typeId: LicenseType): Promise<any> => {
		const bulkData = {};
		const url = `rest/users/bulk/permissions/reset/${typeId}`;

		return this.cxHttp.put(url, bulkData);
	}

	resetPassword = (userIds: number[], queryParams: UserQueryParams) => {
		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			users: userIds
		};

		const url = 'rest/users/bulk/reset-password';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params }).then(() => {});
	}

	removeFromMA = (userIds: number[], queryParams?: UserQueryParams, candidate?: string) => {
		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			users: userIds,
			candidate: candidate || null
		};

		const url = 'rest/users/bulk/delete-from';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params }).then(() => {});
	}

	callBulkDataAccessUpdateAPI = (userIds: number[], dataAccess: BulkDataAccessUpdate, queryParams: UserQueryParams): Promise<any> => {
		if (_.isEmpty(dataAccess.admin) && _.isEmpty(dataAccess.readOnly) && _.isEmpty(dataAccess.noAccess)) {
			return Promise.resolve();
		}

		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			users: userIds,
			admin: _.map(dataAccess.admin, (project: AccountProject) => {
				return { id: project.projectId, name: project.name };
			}),
			readOnly: _.map(dataAccess.readOnly, (project: AccountProject) => {
				return { id: project.projectId, name: project.name };
			}),
			noAccess: _.map(dataAccess.noAccess, (project: AccountProject) => {
				return { id: project.projectId, name: project.name };
			}),
			updateMode: BulkAccessMode.REPLACE,
			accountIdentifier: dataAccess.accountIdentifier
		};

		const url = 'rest/masteraccount/updateCPUserBulk';

		return this.cxHttp.put(url, angular.toJson(bulkData), { params });
	}

	updateLicenseTypeBulk = (userIds: number[], newLicenseType: LicenseTypeItem, inheritPermissions: boolean) => {
		const url = 'rest/users/bulk/update-license-type';
		const bulkData = {
			users: userIds,
			licenseTypeId: newLicenseType.licenseTypeId,
			inheritPermissions
		};

		return this.cxHttp.put(url, angular.toJson(bulkData));
	}

	updateCustomFieldBulk = (userIds: number[], customFieldValue: string, queryParams: UserQueryParams) => {
		const url = 'rest/users/bulk/update-custom-label';
		const params = CxHttpUtils.objectToParams(queryParams);
		const bulkData = {
			users: userIds,
			customFieldValue
		};

		return this.cxHttp.put(url, angular.toJson(bulkData), { params });
	}
}

app.service('userModificationService', downgradeInjectable(UserModificationApiService));
