import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Alert } from '@app/modules/user-administration/groups/alert-subscription/alert.class';
import { AccountId, ContentProviderId, ProjectId } from '@cxstudio/generic-types';


@Injectable({
	providedIn: 'root'
})
export class SubscriptionsService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) { }

	getMasterAccountAlerts(): Promise<any> {
		let masterAccountAlertsUrl = 'rest/alerts/all';
		return this.cxHttp.get(masterAccountAlertsUrl);
	}

	getMasterAccountAlertsForContentProvider(cpId: ContentProviderId): Promise<any> {
		let masterAccountAlertsUrl = `rest/alerts/${cpId}`;
		return this.cxHttp.get(masterAccountAlertsUrl);
	}

	getMasterAccountAlertsForAccount(cpId: ContentProviderId, accountId: AccountId,
			withDisabled: boolean = false): Promise<any> {
		let masterAccountAlertsUrl = `rest/alerts/cp/${cpId}/account/${accountId}`;
		let params = new HttpParams({fromObject: {withDisabled: withDisabled as unknown as string}});
		return this.cxHttp.get(masterAccountAlertsUrl, { params});
	}

	getMasterAccountAlertsForProject(cpId: ContentProviderId, accountId: AccountId, projectId: ProjectId,
			withDisabled: boolean = false): Promise<any> {
		let masterAccountAlertsUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}`;
		let params = new HttpParams({fromObject: {withDisabled: withDisabled as unknown as string}});
		return this.cxHttp.get(masterAccountAlertsUrl,  { params});
	}

	getMasterAccountAlertsForWorkspaceProject(workspaceProject: WorkspaceProject,
			withDisabled: boolean = false): Promise<any> {
		let masterAccountAlertsUrl = `rest/alerts/ws/${workspaceProject.workspaceId}/project/${workspaceProject.projectId}`;
		let params = new HttpParams({fromObject: {withDisabled: withDisabled as unknown as string}});
		return this.cxHttp.get(masterAccountAlertsUrl,  { params});
	}

	getGroupSubscriptions(groupId): Promise<any> {
		let groupSubscriptionsUrl = `rest/alerts/subscriptions/${groupId}`;
		return this.cxHttp.get(groupSubscriptionsUrl);
	}


	updateAlert(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId, alertId } = alert;
		let updateAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/id/${alertId}`;
		return this.cxHttp.put(updateAlertUrl, alert);

	}

	addAlert(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId } = alert;
		let createAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}`;
		return this.cxHttp.post(createAlertUrl, alert);

	}

	deleteAlert(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId, alertId } = alert;
		let deleteAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/id/${alertId}`;
		return this.cxHttp.delete(deleteAlertUrl);

	}

	enableAlert(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId, alertId } = alert;
		let enableAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/id/${alertId}/enable/true`;
		return this.cxHttp.put(enableAlertUrl);

	}

	disableAlert(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId, alertId } = alert;
		let enableAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/id/${alertId}/enable/false`;
		return this.cxHttp.put(enableAlertUrl);
	}

	getAlertDetail(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId, alertId } = alert;
		let getAlertDetailUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/id/${alertId}`;
		return this.cxHttp.get(getAlertDetailUrl);
	}

	checkAlertName(cpId: ContentProviderId, alert: Alert): Promise<any> {
		let { accountId, projectId } = alert;
		let enableAlertUrl = `rest/alerts/cp/${cpId}/account/${accountId}/project/${projectId}/name`;
		return this.cxHttp.post(enableAlertUrl, alert.alertName);

	}
}


app.service('subscriptionsService', downgradeInjectable(SubscriptionsService));
