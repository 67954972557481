import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { BaseWidgetProperties } from '@cxstudio/reports/entities/widget-properties';

export enum TextRotation {
	DOWN = 'rotate-down',
	UP = 'rotate-up'
}

export enum TextVAlign {
	MIDDLE = 'valign-middle',
	BOTTOM = 'valign-bottom'
}

export enum BorderOptions {
	TOP = 'borderTop',
	BOTTOM = 'borderBottom',
	ALL = 'border',
}

export enum BorderValue {
	OFF = '',
	ON = 1
}

export enum BackgroundType {
	NONE = 'none',
	DEFAULT = 'default',
	CUSTOM = 'custom'
}

export enum MarginType {
	NARROW = 'narrow',
	NORMAL = 'normal'
}

export const MAX_TEXT_LENGTH = 8000;

export type IWidgetBorder = {[key in BorderOptions]: BorderValue };

export interface ContentWidgetProperties extends BaseWidgetProperties, IWidgetBorder,
	TextWidgetProperties, ImageWidgetProperties, VideoWidgetProperties {}

export interface TextWidgetProperties extends BaseWidgetProperties {
	newTextFormat: boolean;
	text: string;
	textLength?: number; // only for validation
	background: string;
	backgroundType: BackgroundType;
	rotation: TextRotation;
	valign: TextVAlign;
	borderTop: BorderValue;
	borderBottom: BorderValue;
	border: BorderValue;
	imageNames: string[];
	margins: MarginType;
}

export interface ImageWidgetProperties extends BaseWidgetProperties {
	imageUrl: string;
	imageName: string;
	ignoreRatio: boolean;
	altText: string;
}

export interface VideoWidgetProperties extends BaseWidgetProperties {
	videoUrl: string;
}

export enum ButtonPosition {
	LEFT = 'LEFT',
	CENTER = 'CENTER',
	RIGHT = 'RIGHT',
}

export enum ButtonTextPosition {
	ABOVE = 'ABOVE',
	BELOW = 'BELOW',
}

export interface ButtonWidgetVisualProperties extends VisualProperties {
	text: string;
	linkUrl: string;
	buttonColor: string;
	position: ButtonPosition;
	fullWidth: boolean;
	instructionalTextEnabled: boolean;
	instructionalText: string;
	instructionalTextPosition: ButtonTextPosition;
	backgroundColor: BackgroundType;
}
