import * as _ from 'underscore';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IReportModel } from '@app/modules/project/model/report-model';
import ProjectGeographies from '@cxstudio/attribute-geography/project-geographies';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IProjectContext } from '@app/modules/project/context/project-context';

export interface IProjectSettings {
	attributes: IReportAttribute[];
	wordAttributes?: IReportAttribute[];
	models: IReportModel[];
	scorecards?: any[];
	projectContext: IProjectContext;
	projectGeographies?: ProjectGeographies;
}

export default class ProjectSettingsService {

	constructor(
		private $q: ng.IQService,
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
		private adminProjectsService: AdminProjectsService,
	) {}

	getSettings = (project: AccountOrWorkspaceProject, cache = CacheOptions.NOT_CACHED): ng.IPromise<IProjectSettings> => {
		if (InternalProjectTypes.isAdminProject(project.projectId)) {
			return this.$q.when({
				attributes: this.adminProjectsService.getProjectAttributes(project.projectId),
				models: [],
				projectContext: {} as IProjectContext
			});
		}

		let attributesPromise = PromiseUtils.old(this.attributesService.getAttributes(project, cache));

		let wordAttributesPromise = PromiseUtils.old(this.attributesService.getWordAttributes(project, cache));

		//here we need all models for now, as we compare published hierarchies to hidden models
		//see optionsBuilderProvider
		let modelsPromise = PromiseUtils.old(this.modelsService.getModelsWithHierarchies(project, cache));

		let projectContextPromise = PromiseUtils.old(this.projectContextService.getProjectContext(project));

		let resultPromise = this.$q.all([
			attributesPromise,
			modelsPromise,
			projectContextPromise,
			wordAttributesPromise
		]).then((resultArray: any[]): IProjectSettings => {
			return {
				attributes: resultArray[0],
				models: resultArray[1],
				projectContext: resultArray[2],
				wordAttributes: resultArray[3]
			};
		});
		return resultPromise;
	}
}

app.service('projectSettingsService', ProjectSettingsService);
