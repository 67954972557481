
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardListTabType } from './dashboard-list-tab-type.class';

export class DashboardsByLabelTab extends DashboardListTabType {
	static readonly TAB_ICON = undefined;

	constructor() {
		super();
	}

	static getList(dashboards: Dashboard[], label: string): Dashboard[] {
		let dashboardsWithLabel: Dashboard[];

		dashboardsWithLabel = _.chain(dashboards)
			.filter(dashboard => this.dashboardsOnly(dashboard) && !!_.find(dashboard.labels, {text: label}))
			.sortBy('name')
			.value();

		return dashboardsWithLabel;
	}

	static getEmptyListHTML(localeService: CxLocaleService): string {
		return `<div class="d-inline-block p-16 text-center dashboard-preview-tile text-0_875rem">
		<span class="${this.TAB_ICON}" aria-hidden="true"></span>

			<p>${localeService.getString('dashboard.noItemsWithLabel')}</p>
		</div>`;
	}

	static getTabName(localeService: CxLocaleService, label: string): string {
		return label;
	}

	static getTabId(label: string): string {
		return `tab_${label.toLowerCase().replace(' ', '_')}`;
	}

	static getContentId(label: string): string {
		return `tab_content_${label.toLowerCase().replace(' ', '_')}`;
	}

	static is(tab): boolean {
		return !tab.pinned;
	}
}
