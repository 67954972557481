type TranslatableLanguage = {
	locale: string;
	name: string;
};

export function getTranslatableLanguages(): TranslatableLanguage[] {
	return [
		{ locale: 'zh', name: 'Chinese'},
		{ locale: 'nl', name: 'Dutch'},
		{ locale: 'fr', name: 'French'},
		{ locale: 'de', name: 'German'},
		{ locale: 'it', name: 'Italian'},
		{ locale: 'ja', name: 'Japanese'},
		{ locale: 'pt', name: 'Portuguese'},
		{ locale: 'es', name: 'Spanish'},
	];
}

export default TranslatableLanguage;
