import { Component, OnInit, OnDestroy, ElementRef, Input, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

// TODO: Refactor JQuery - 2022-03-23 and update spec
@Component({
	selector: 'floatable-widget',
	templateUrl: './floatable-widget.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'floatableWidget'
})
export class FloatableWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
	INITIAL_HEADER_OFFSET: number = 130;
	headerHeight = this.INITIAL_HEADER_OFFSET;
	clickLocation: { x: number, y: number } = { x: 0, y: 0 };
	elem: any;
	floatHandle: JQuery;
	dockableHandle: JQuery;

	@Input() opened: boolean = false;
	@Input() width: number = 0;
	@Input() layoutOffset;

	constructor(
		private readonly elementRef: ElementRef
	) { }

	ngOnInit(): void {
		this.elem = this.elementRef.nativeElement.firstChild;
	}

	ngOnDestroy(): void {
		this.dockableHandle.off('click');
		this.floatHandle.off('mousedown');
	}

	ngAfterViewInit(): void {
		this.width = this.elem.offsetWidth;

		this.floatHandle = $(this.elem).find('[floatable-handle]');
		this.dockableHandle = $(this.elem).find('[dockable-handle]');
		this.floatHandle.css({ 'cursor': 'move' });
		this.dockableHandle.css({ 'cursor': 'pointer' });

		let context = this;
		this.floatHandle.on('mousedown', (event) => {
			if ($(context.elem).hasClass('docked-widget')) {
				$(context.elem).removeClass('docked-widget');
			}
			$('.top-floating-widget').removeClass('top-floating-widget');
			$(context.elem).addClass('floating-widget top-floating-widget');
			context.clickLocation.x = (!event.offsetX) ? context.calcOffsetX(event.pageX) : event.offsetX;
			context.clickLocation.y = (!event.offsetY) ? context.calcOffsetY(event.pageY) : event.offsetY;

			let offset = $('.br-global-view').offset();
			context.headerHeight = offset ? offset.top : context.INITIAL_HEADER_OFFSET; // fallback just in case
			context.moveElement(event);
			$(document).on('mousemove', context.moveElement);
			$(document).on('mouseup', context.handleMouseUp);
		});

		this.dockableHandle.on('click', (event) => {
			context.dockElement();
		});
	}

	mainToggleHandler() {
		this.opened = !this.opened;
	}

	dockElement = (): void => {
		if ($(this.elem).hasClass('floating-widget')) {
			$(this.elem).removeClass('floating-widget');
			$(this.elem).css({ 'left': '', 'top': '' });
		}
	}

	moveTo = (x, y): void => {
		$(this.elem).css({ 'left': x + 'px', 'top': y + 'px' });
	}

	moveElement = (mouseLocation): void => {
		const yCalc = this.restrictYCoordinate(mouseLocation.clientY - this.clickLocation.y - this.headerHeight);
		const xCalc = this.restrictXCoordinate(mouseLocation.clientX - this.clickLocation.x);
		this.moveTo(xCalc, yCalc);
	}

	restrictXCoordinate = (xCoordinate): number => {
		if (xCoordinate <= 0) {
			return 0;
		}
		const rightBound = ($(window).width() - this.elem.getBoundingClientRect().width);
		if (xCoordinate >= rightBound) {
			return rightBound;
		}
		return xCoordinate;
	}

	restrictYCoordinate = (yCoordinate): number => {
		if (yCoordinate <= 0) {
			return 0;
		}

		let bottomBound = ($(window).height() - this.elem.getBoundingClientRect().height);
		if (yCoordinate >= bottomBound) {
			return bottomBound;
		}
		return yCoordinate;
	}

	handleMouseUp = (event): void => {
		$(document).off('mousemove', this.moveElement);
		$(document).off('mouseup', this.handleMouseUp);
		this.moveElement(event);
	}

	calcOffsetX = (pageX): number => {
		const elementPos = $(this.elem).offset().left;
		return pageX - elementPos;
	}

	calcOffsetY = (pageY): number => {
		let elementPos = $(this.elem).offset().top;
		return pageY - elementPos;
	}
}

app.directive('floatableWidget', downgradeComponent({ component: FloatableWidgetComponent }) as angular.IDirectiveFactory);
