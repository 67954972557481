
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { ReportScope } from '@cxstudio/reports/report-scope';
import { PreviewHelper } from './preview-helper-service';

import * as _ from 'underscore';

export class TuningSuggestions {

	hasTuningSuggestionTemplates: boolean;
	private tuningSuggestionsMode: boolean;

	changeMode = (enabled: boolean) => {
		this.tuningSuggestionsMode = enabled;
	}

	hasTuningSuggestions = (widget: PreviewWidget): boolean => {
		return widget.visualProperties?.tuningSuggestionsEnabled;
	}

	isTuningSuggestionsMode = (): boolean => {
		return this.tuningSuggestionsMode;
	}
}
