<div class="document-controls">
	<div class="d-flex align-items-center justify-between l-margin-offset-3 flex-fill">
		<div class="d-flex align-items-center">
			<div *ngIf="!isCurationMode()" class="d-flex align-items-center hide-pdf">
				<translate-button
					class="mr-8"
					*ngIf="!documentManager.isDemo() && !!document"
					[active]="translateActive"
					(translate)="translate.emit()">
				</translate-button>

				<button *ngIf="isShowingCuratingControls() && isFeedbackSharingAvailable()"
					class="curate-document-icon q-icon-binder-clip hide-fullscreen btn btn-icon"
					[class.btn-selected]="isCurrentItemCurated()"
					[class.focus:text-white]="isCurrentItemCurated()"
					(click)="curateCurrentItem()"
					[attr.aria-label]="getCurateAriaLabel()"
					[disabled]="auditMode"
					title="{{ getRelevantCuratedItemsCountLabel() }}">
					<div class="curated-items-count-label"
						[hidden]="getRelevantCuratedItemsCount() === 0">
						{{getRelevantCuratedItemsCount()}}
					</div>
				</button>

				<div *ngIf="showShareMenu()"
					ngbDropdown
					#shareMenu="ngbDropdown"
					container="body"
					(keydown.arrowup)="processArrowDropdown($event)"
					(keydown.arrowdown)="processArrowDropdown($event)"
					(keydown.tab)="shareMenu.close();"
					(keydown.shift.tab)="shareMenu.close();"
					class="dropup ml-8">
					<button #menuButton class="q-icon q-icon-share btn btn-icon mr-8"
						data-testid="de-share-menu"
						role="button"
						[disabled]="auditMode"
						[title]="'common.share' | i18n"
						[attr.aria-label]="'common.share'|i18n"
						ngbDropdownToggle></button>
					<ul ngbDropdownMenu class="share-menu" role="menu" (keydown.arrowup)="processArrowDropdown($event)" (keydown.arrowdown)="processArrowDropdown($event)" (keydown.tab)="close($event);" (keydown.shift.tab)="close($event);" (keydown.escape)="close($event);">
						<li data-testid="link-download-audio" (click)="shareMenu.close();downloadAudio()" *ngIf="conversation && documentManager?.conversationMethods?.allowAudioDownload()">
							<a href="javascript:void(0)" role="menuitem">{{'preview.menuDownloadAudio'|i18n}}</a>
						</li>
						<li data-testid="link-download-transcript" (click)="shareMenu.close();downloadTranscript()" *ngIf="conversation && documentManager?.conversationMethods?.allowTranscriptDownload()">
							<a href="javascript:void(0)" role="menuitem">{{'preview.menuDownloadTranscript'|i18n}}</a>
						</li>
						<li *ngIf="showStats && documentExplorer" (click)="shareMenu.close();openStatsDialog()">
							<a href="javascript:void(0)" role="menuitem" class="option">{{'docExplorer.stats'|i18n}}</a>
						</li>
						<li *ngIf="showExportData && documentExplorer" data-testid="export-data" (click)="shareMenu.close();exportData.emit()">
							<a href="javascript:void(0)" class="option" role="menuitem">{{'preview.export'|i18n}}</a>
						</li>
						<li>
							<a href="javascript:void(0)" role="menuitem"
								*ngIf="canCopyLink() && documentExplorer"
								data-testid="copy-link"
								[title]="'preview.copyDocumentLinkToClipboard' | i18n"
								(click)="shareMenu.close();copyDocumentsLink($event)"
								(keydown.enter)="onMultiDocumentCopyLinkKeyboard($event)">{{'common.copyLink'|i18n}}</a>
						</li>
					</ul>
				</div>

				<button *ngIf="isShowingCuratingControls() && isFeedbackSharingAvailable() && hasAnyCuratedItems()"
					class="q-icon-paper-plane hide-fullscreen btn btn-icon"
					(click)="share.emit()"
					[attr.aria-label]="getShareLinkAriaLabel()"
					[disabled]="auditMode"
					title="{{'docExplorer.shareSelectedFeedback'|i18n}}">
				</button>

				<button *ngIf="showCreateCaseIcon()"
					class="ml-8 q-icon-clipboard btn btn-icon mr-8"
					[class.cursor-not-allowed]="isCreateCaseDisabled()"
					[title]="getCreateCaseTooltip()"
					[attr.aria-label]="getCreateCaseAriaLabel()"
					[disabled]="auditMode"
					(click)="createCase()">
				</button>
			</div>
			<div *ngIf="isCurationMode()" class="d-flex align-items-center hide-pdf">
				<button class="d-flex mr-16 align-items-center btn btn-secondary"
					(click)="curateCurrentItem()">
					<span class="cursor-pointer mr-8 cursor-no-selection">{{'preview.selected'|i18n}}</span>
					<span *ngIf="isShowingCuratingControls()"
						class="curate-document-icon hide-fullscreen btn btn-icon hover:bg-none"
						[ngClass]="{'toggled q-icon-check': isCurrentItemCurated(), 'q-icon-circle': !isCurrentItemCurated()}"
						title="{{ getRelevantCuratedItemsCountLabel() }}">
						<div class="curated-items-count-label"
							[hidden]="getRelevantCuratedItemsCount() === 0">
							{{getRelevantCuratedItemsCount()}}
						</div>
					</span>
				</button>
			</div>
		</div>
	</div>
</div>
