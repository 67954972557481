import * as _ from 'underscore';
import { ExportTypes } from '@cxstudio/master-accounts/export-types.constant';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { AccountReportExportSettings } from '@app/modules/system-administration/admin-reports/account-report-export/account-report-export-dialog.component';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

export interface ExportQueryParameters {
	isPaginated?: boolean;
	currentPage?: number;
	pageSize?: number;
	withTotal?: boolean;
	filterText?: string;
	filterField?: string;
	filterMode?: string;
	sortField?: string;
	sortDirection?: string;
	startDate?: Date;
	endDate?: Date;
	adminExport?: boolean;
}

export class ExportService {

	constructor(
		private readonly exportApiService: ExportApiService,
		private readonly httpHandlers: HttpHandlers,
	) {}

	export = (type: ExportTypes, queryParameters: ExportQueryParameters): ng.IPromise<void> | undefined => {
		switch (type) {
			case ExportTypes.MASTERACCOUNT_AUDIT:
				return this.exportMaAudit(queryParameters).then(this.httpHandlers.success);

			case ExportTypes.SYSTEM_AUDIT:
				return this.exportSystemAudit(queryParameters).then(this.httpHandlers.success);
		}
	}

	retrieveAmountOfRecords = (type: ExportTypes, queryParameters): ng.IPromise<number> | undefined => {
		switch (type) {
			case ExportTypes.MASTERACCOUNT_AUDIT:
				return this.retrieveMaAuditRecordsCount(queryParameters);
			case ExportTypes.SYSTEM_AUDIT:
				return this.retrieveSystemAuditRecordsCount(queryParameters);
		}
	}
	exportWidgetUsageReport = () => {
		return this.exportApiService.requestWidgetUsageReport();
	}
	exportUserDataReport = (queryParameters: ExportQueryParameters, isMasterAccountFilter?: boolean) => {
		return this.exportApiService.requestUserDataReport(queryParameters, isMasterAccountFilter);
	}
	exportGroupDataReport = (queryParameters: ExportQueryParameters) => {
		return this.exportApiService.requestGroupDataReport(queryParameters);
	}
	requestDashInfoReport = () => {
		return this.exportApiService.requestDashboardAccessReport();
	}
	requestPermissionMappingReport = () => {
		return this.exportApiService.requestPermissionMappingReport();
	}
	requestLexiconSuggestions = (lexiconProjectData) => {
		return this.exportApiService.requestLexiconSuggestions(lexiconProjectData);
	}

	private exportMaAudit = (queryParameters: ExportQueryParameters) => {
		return this.exportApiService.requestMaAudit(queryParameters);
	}

	private exportSystemAudit = (queryParameters: ExportQueryParameters) => {
		return this.exportApiService.requestSystemAudit(queryParameters);
	}

	private retrieveMaAuditRecordsCount = (queryParameters: ExportQueryParameters): ng.IPromise<number> => {
		return this.exportApiService.getMaAuditRecordsCount(queryParameters);
	}

	private retrieveSystemAuditRecordsCount = (queryParameters: ExportQueryParameters): ng.IPromise<number> => {
		return this.exportApiService.getSystemAuditRecordsCount(queryParameters);
	}

	exportAccountOverviewReport = (exportSettings: AccountReportExportSettings) => {
		return this.exportApiService.requestAccountOverviewReport(exportSettings);
	}

	exportAccountPropertiesReport = (exportSettings: AccountReportExportSettings) => {
		return this.exportApiService.requestAccountPropertiesReport(exportSettings);
	}

	requestDataAccessReport = () => {
		return this.exportApiService.requestDataAccessReport();
	}

	requestWidgetVersionReport = () => {
		return this.exportApiService.requestWidgetVersionReport();
	}

	requestEmbeddedWidgetsReport = () => {
		return this.exportApiService.requestEmbeddedWidgetsReport();
	}

	requestMetricSharingReport = () => {
		return this.exportApiService.requestMetricSharingReport();
	}

	requestFilterSharingReport = () => {
		return this.exportApiService.requestFilterSharingReport();
	}

	requestModelAccessReport = (projectSelection: IProjectSelection) => {
		return this.exportApiService.requestModelAccessReport(projectSelection);
	}
}

app.service('exportService', ExportService);
