import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { PromiseUtils } from '@app/util/promise-utils';
import { ManageAlertTemplatesService } from '@app/modules/alert-subscription-template/services/manage-alert-subscription-templates.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import * as uib from 'angular-ui-bootstrap';
import * as _ from 'underscore';
import { GridTypes } from '../grids/grid-types-constant';
import AlertSubscriptionTemplatesApi from './alert-subscription-templates-api.service';
import IAlertSubscriptionTemplate, { AlertSubscriptionTemplateType } from './types/alert-subscription-template';

import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';

interface ITemplatesEditorModalResult {
	template: IAlertSubscriptionTemplate;
	updateCases: boolean;
}

export class AlertSubscriptionTemplatesList implements ng.IComponentController {

	templates: IAlertSubscriptionTemplate[] = [];
	gridType: GridTypes = GridTypes.ALERT_SUBSCRIPTION_TEMPLATE;
	gridNameField: 'alertName';

	gridOptions: SlickgridOptions;
	lastChange;
	loading: ProjectAssetsLoading = {};
	errors: ProjectAssetsErrors = {};
	project: AccountOrWorkspaceProject;
	projectsAmount: OptionsAmount;
	isWorkspaceEnabled: boolean;
	private adminAccessProjects: number[];

	constructor(
		private $q: ng.IQService,
		private locale: ILocale,
		private alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		private gridUpdateService: GridUpdateService,
		private security: Security,
		private authorization: Authorization,
		private contextMenuTree: any,
		private $uibModal: uib.IModalService,
		private manageAlertTemplatesService: ManageAlertTemplatesService,
		private gridUtils: GridUtilsService,
		private betaFeaturesService: BetaFeaturesService,
		private cxDialogService: CxDialogService,
		private scorecardsApiService: ScorecardsApiService,
		private cbDialogService: CBDialogService,
	) {}

	$onInit = () => {
		this.security.preventMobileAccess();
		this.security.restrictPage(this.authorization.hasInboxTemplateManagementAccess);

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.gridOptions = {
			onClick: this.onClick
		};
		this.project = {} as AccountOrWorkspaceProject;
	}

	loadTemplates = (): ng.IPromise<void> => {
		if (this.errors.tooManyProjects) {
			this.clearTemplates();
			return this.$q.when();
		}
		let workspace = WorkspaceTransitionUtils.getWorkspace(this.project);
		return this.manageAlertTemplatesService.getAdminAccessProjects(workspace)
			.then((projects) => {
				this.adminAccessProjects = projects;
				return this.getTemplates();
			}).then(result => {
				this.templates = result || [];
				this.templates.forEach((template: any) => {
					template.level = 0;
				});
				this.refreshGrid(this.templates);
			});
	}

	private getTemplates = (): ng.IPromise<IAlertSubscriptionTemplate[]> => {
		return WorkspaceTransitionUtils.isWorkspaceProject(this.project)
			? this.alertSubscriptionTemplatesApi.getWorkspaceTemplates(this.project)
			: this.alertSubscriptionTemplatesApi.getAllTemplates(this.project);
	}

	private clearTemplates = (): void => {
		this.templates = [];
		this.refreshGrid();
	}

	addTemplate = (): void => {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.errors.noProjectSelected = true;
			return;
		}

		let templateTypes = [{
			value: AlertSubscriptionTemplateType.NEW_VERBATIM,
			name: this.locale.getString('alertTemplates.interaction')
		}];

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_ALERT_CASES)) {
			templateTypes.push({
					value: AlertSubscriptionTemplateType.METRIC,
					name: this.locale.getString('alertTemplates.metric')
				});
		}

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.PRODUCT_FEEDBACK)) {
			templateTypes.push({
				value: AlertSubscriptionTemplateType.PRODUCT_FEEDBACK,
				name: this.locale.getString('alertTemplates.tuningSuggestions')
			});
		}

		let typePromise = templateTypes.length === 1
			? this.$q.when(AlertSubscriptionTemplateType.NEW_VERBATIM)
			: this.cxDialogService.openSelectDialog(
					this.locale.getString('alertTemplates.typeDialogHeader'),
					this.locale.getString('alertTemplates.typeDialogText'),
					templateTypes,
					this.locale.getString('alertTemplates.typeDialogHelp'));

		this.loading.promise = typePromise.then((type) => {
			let projectData = WorkspaceTransitionUtils.getProjectData(this.project as AccountOrWorkspaceProjectData, true);
			let template = _.extend({
					type,
					masterAccountId: this.security.getCurrentMasterAccount().accountId,
					owner: this.security.getEmail()
				}, projectData);
			return this.openEditTemplateModal(template, false);
			})
			.then((result: ITemplatesEditorModalResult) => {
				let template: IAlertSubscriptionTemplate = result.template;
				this.assignAttributeOrderIndices(template);
				return this.alertSubscriptionTemplatesApi.createTemplate(template);
			})
			.then(result => {
				return this.loadTemplates();
			});
	}

	editTemplate = (template: IAlertSubscriptionTemplate): void => {
		const modal = this.openEditTemplateModal(template, true);
		this.loading.promise = modal.then((dialogResult: ITemplatesEditorModalResult) => {
			let editedTemplate: IAlertSubscriptionTemplate = dialogResult.template;
			let updateCases: boolean = dialogResult.updateCases;
			this.assignAttributeOrderIndices(editedTemplate);
			return this.alertSubscriptionTemplatesApi.updateTemplate(editedTemplate, updateCases).then(result => {
				return this.loadTemplates();
			});
		});
	}

	deleteTemplate = (template): void => {
		const deleteCallback = (items) => this.alertSubscriptionTemplatesApi.deleteTemplate(template);

		this.gridUpdateService.delete([template], this.templates, deleteCallback, {
			title: this.locale.getString('alertTemplates.deleteConfirmationHeader'),
			warning: this.locale.getString('alertTemplates.deleteConfirmationBody')
		}).then(() => {
			this.refreshGrid();
		}, () => {});
	}

	assignAttributeOrderIndices = (template): void => {
		if (!template || template.type === AlertSubscriptionTemplateType.METRIC) {
			return;
		}
		(template.caseMetadata.attributes || []).forEach((attr, index) => {
			attr.orderIndex = index;
		});
		(template.mentionMetadata.attributes || []).forEach((attr, index) => {
			attr.orderIndex = index;
		});
	}

	onClick = (event, template: IAlertSubscriptionTemplate): void => {
		if (!this.canEditTemplate(template)) {
			return;
		}

		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, template, this.getContextMenu(template));
			return;
		}

		if (this.gridUtils.isToggleClick(event)) {
			this.toggleTemplate(template);
			return;
		}

		if (this.gridUtils.isNameClick(event)) {
			this.editTemplate(template);
			return;
		}
	}

	canEditTemplate = (template: IAlertSubscriptionTemplate): boolean => {
		return this.manageAlertTemplatesService.canEdit(template, this.adminAccessProjects);
	}

	private toggleTemplate = (template: IAlertSubscriptionTemplate): void => {
		let templateNewState: boolean = !template.enabled;

		this.loading.promise = (template.type === AlertSubscriptionTemplateType.SCORECARD && !templateNewState)
			? this.scorecardsApiService.getScorecard(template.contentProviderId, template.accountId, template.scorecardId)
			.then(scorecardData => {
				return scorecardData.scorecard.active
					? this.cbDialogService.confirm(
						this.locale.getString('scorecards.disableTitle'),
						this.locale.getString('scorecards.disableMessage', { templateName: template.name }))
							.result.then(confirmed => {
								return confirmed ? this.$q.when() : this.$q.reject();
							})
					: this.$q.when();
				})
			: this.$q.when();

		this.loading.promise.then(() => {
			this.toggleTemplateInternal(template, templateNewState);
		});
	}

	private toggleTemplateInternal = (template: IAlertSubscriptionTemplate, templateState: boolean): void => {
		this.loading.promise = this.alertSubscriptionTemplatesApi.toggleEnabledFlag(template, templateState)
				.then(updatedTemplate => {
					template.enabled = updatedTemplate.enabled;
					this.refreshGrid(template);
				});
	}

	getContextMenu = (template: IAlertSubscriptionTemplate): any[] => {
		let MENU_OPTIONS = {
			MenuDivider,
			enable: { name: 'enable', text: this.locale.getString('administration.enable'), func: this.toggleTemplate },
			disable: { name: 'disable', text: this.locale.getString('administration.disable'), func: this.toggleTemplate },
			edit: { name: 'edit', text: this.locale.getString('common.edit'), func: this.editTemplate },
			delete: { name: 'delete', text: this.locale.getString('common.delete'), func: this.deleteTemplate }
		};

		let options = [
			MENU_OPTIONS.edit,
			(template.enabled) ? MENU_OPTIONS.disable : MENU_OPTIONS.enable
		] as any;

		if (template.type !== AlertSubscriptionTemplateType.SCORECARD) {
			options.push(MenuDivider);
			options.push(MENU_OPTIONS.delete);
		}

		return options;
	}

	isOwner = (template: IAlertSubscriptionTemplate): boolean => {
		return template.owner && this.security.isCurrentUser(template.owner);
	}

	refreshGrid = (items?: any): void => {
		this.lastChange = [].concat(items);
	}

	accountChanged = (newProps?: IProjectSelection): void => {
		if (newProps) {
			this.project = newProps;
		}
		if (this.project.projectId !== undefined) {
			this.loading.promise = this.loadTemplates();
		} else {
			this.clearTemplates();
		}
	}

	projectChanged = (newProps: IProjectSelection): void => {
		if (newProps) {
			this.project = newProps;
		}

		if (ValueUtils.isSelected(newProps.accountId)) {
			this.loading.promise = this.loadTemplates();
		}
	}

	workspaceChanged = () => {
		this.projectsAmount = undefined;
	}

	workspaceProjectChanged = (newProject?: WorkspaceProject): void => {
		this.cleanProjectErrors();
		let projectSelectedCheck = WorkspaceProjectUtils.isProjectSelected(this.project as WorkspaceProject);
		if (newProject) {
			this.project = newProject;
		}
		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.clearAndRemoveAll();

		if (!_.isEmpty(this.project) && projectSelectedCheck) {
			this.loading.promise = this.loadTemplates();
		} else if (this.checkManyProjectError(newProject)) {
			this.errors.tooManyProjects = true;
			return;
		} else {
			this.loading.promise = this.loadTemplates();
		}
	}

	private checkManyProjectError = (newProject: WorkspaceProject): boolean => {
		if ( this.projectsAmount === OptionsAmount.MANY_OPTIONS ) {
			return true;
		}

		return false;
	}

	private clearAndRemoveAll = () => {
		this.templates = [];
	}

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	}

	projectsLoaded = (amount: OptionsAmount): void => {
		this.projectsAmount = amount;
		this.workspaceProjectChanged();
	}

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	}

	openEditTemplateModal = (template: Partial<IAlertSubscriptionTemplate>, editMode: boolean): ng.IPromise<ITemplatesEditorModalResult> => {
		return this.$uibModal.open({
			component: 'templatesEditorModal',
			size: 'lg',
			windowClass: 'modal-max-1000',
			backdrop: 'static',
			resolve: {
				template: () => template,
				editMode: () => editMode
			}
		}).result;
	}
}

app.component('alertSubscriptionTemplatesList', {
	controller: AlertSubscriptionTemplatesList,
	templateUrl: 'partials/alert-subscription-templates/alert-subscription-templates-list.component.html'
});
