import { SpineLane, UISpineLane } from './spine-lane.class';
import { ConversationChannelLabels } from './conversation-channel-labels.class';

export class SpineSettings {
	labels: ConversationChannelLabels;
	participantLane: SpineLane;
	singleLanes: SpineLane[];
}

export interface UISpineSettings extends SpineSettings {
	singleLanes: UISpineLane[];
}