<menu id="profile-dropdown"
	ngbDropdown
	role="menu"
	[attr.aria-expanded]="userMenuDropdown?.isOpen()"
	[autoClose]="true"
	[display]="'static'"
	class="m-0 dropdown pull-left br-user-menu header-element">

	<button id="profile-button"
		ngbDropdownToggle
		role="button"
		(click)="onUserMenuToggle($event)"
		(keydown)="onUserMenuToggleKeydown($event)"
		[attr.aria-label]="'common.userMenu'|i18n"
		aria-haspopup="true"
		[attr.aria-expanded]="userMenuDropdown?.isOpen()"
		class="d-flex align-items-center header-link"
		[title]="getCurrentUserFullName()">
		<cb-avatar class="d-flex inline mr-4"
				   [user]="user"
				   [size]="'30'"
				   [gravatarFallback]="MYSTERY_PERSON">
		</cb-avatar>
		<span aria-hidden="true" class="q-icon q-icon-angle-bottom menu-caret"></span>
	</button>

	<section ngbDropdownMenu aria-labelledby="profile-button" id="profile-menu" class="w-max-600 dropdown-menu" role="menu" (keydown)="onUserMenuKeydown($event)">

		<a
			*ngIf="showSystemAdministration"
			[attr.badge]="'common.admin'|i18n"
			ngbDropdownItem
			class="option system_management overflow-hidden text-ellipsis"
			href="#/system"
			data-testid="user-menu-system-administration">
			{{'administration.systemAdministrationTitle' | i18n}}
		</a>
		<hr role="separator" aria-hidden="true" *ngIf="showSystemAdministration">

		<button
			ngbDropdownItem
			class="align-items-center border-0 d-flex white-space-nowrap w-100-percent"
			[title]="getCurrentUserFullName()"
			href="javascript:void(0)"
			(click)="openProps()"
			(keydown)="onUserPreferencesKeydown($event)"
			[attr.aria-label]="getUserPreferencesAreaLabel()"
			data-testid="user-menu-user-preferences">
			<span class="flex-fill text-ellipsis">{{getCurrentUserFullName()}}</span>
			<icon class="ml-16" name="cog"></icon>
		</button>

		<hr role="separator" aria-hidden="true">

		<a
			ngbDropdownItem
			href="https://www.qualtrics.com/support/?utm_medium=product&utm_source=studio"
			class="option help clearfix"
			target="_blank"
			data-testid="user-menu-help"
		>
			<span [i18n]="'header.help'"></span>
		</a>

		<a
			*ngIf="showIdeasForumLink()"
			ngbDropdownItem
			[href]="getUserVoiceRedirectionURL()"
			class="option help clearfix"
			target="_blank"
			data-testid="user-menu-ideas"
		>
			<span [i18n]="'header.userVoice'"></span>
		</a>
		<a
			(click)="about()"
			data-testid="user-menu-about"
			ngbDropdownItem class='option about clearfix'
			href="javascript:void(0)">
			<span [i18n]="'header.about'"></span>
		</a>

		<a
			(click)="accessibility()"
			data-testid="user-menu-accessibility"
			ngbDropdownItem
			class='option about clearfix' href="javascript:void(0)">
			<span [i18n]="'header.accessibility'"></span>
		</a>


		<support-post-link #supportLink></support-post-link>
		<a
			ngbDropdownItem
			href="javascript:void(0)"
			class="option help clearfix"
			(click)="supportLink.postRedirect()"
			data-testid="user-menu-support"
		>
			<span [i18n]="'header.support'"></span>
		</a>


		<hr role="separator" aria-hidden="true" *ngIf="showSettings()">

		<a
			*ngIf="hasMasterAccountManagementAccess()"
			ngbDropdownItem
			href="#/master-account-properties"
			class="option master_account_management"
			[i18n]="'mAccount.menuOption'"
			data-testid="user-menu-account-settings"
		></a>

		<a
			*ngIf="hasUserAdministrationAccess()"
			ngbDropdownItem
			href="#/user-group-management"
			class="option user_management"
			[i18n]="'header.userAdministrationOption'"
			data-testid="user-menu-users-and-groups"
		></a>

		<a
			*ngIf="hasOrgHierarchyAccess()"
			ngbDropdownItem
			href="#/master-account-properties?tab=organization"
			class="option"
			[i18n]="'header.orgHierarchy'"
			data-testid="user-menu-org-hierarchy"
		></a>

		<hr role="separator" aria-hidden="true">

		<a
			ngbDropdownItem
			class="option logout"
			(click)="checkedLogout()"
			title="{{'header.logout'|i18n}}"
			href="javascript:void(0)"
			data-testid="user-menu-sign-out"
		>
			{{'header.logout'|i18n}}
		</a>
	</section>
</menu>
