import { SpotCheckResultStatus } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-status';
import { SpotCheckTopic } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-topic';

export class SpotCheckUtils {
	static getBadgeClasses(status: SpotCheckResultStatus): string[] {
		switch (status) {
			case SpotCheckResultStatus.ISSUE:
				return ['text-warning-600', 'border-warning-600', 'bg-warning-100'];
			case SpotCheckResultStatus.TIP:
				return ['text-blue-700', 'border-blue-700', 'bg-blue-100'];
			case SpotCheckResultStatus.PASSED:
				return ['text-success-600', 'border-success-600', 'bg-success-100'];
			case SpotCheckResultStatus.IGNORED:
				return ['text-gray-1000', 'border-gray-1000', 'bg-gray-100'];
			default:
				return [];
		}
	}

	static getStatusLabelKey(status: SpotCheckResultStatus): string {
		switch (status) {
			case SpotCheckResultStatus.ISSUE:
				return 'dashboardSpotCheck.issue';
			case SpotCheckResultStatus.TIP:
				return 'dashboardSpotCheck.tip';
			case SpotCheckResultStatus.PASSED:
				return 'dashboardSpotCheck.passed';
			case SpotCheckResultStatus.IGNORED:
				return 'dashboardSpotCheck.ignored';
		}
	}

	static getTopicLabelKey(topic: SpotCheckTopic): string {
		switch (topic) {
			case SpotCheckTopic.ACCESSIBILITY:
				return 'dashboardSpotCheck.accessibility';
			case SpotCheckTopic.SPELLING:
				return 'dashboardSpotCheck.spellingAndStyle';
			case SpotCheckTopic.PERFORMANCE:
				return 'dashboardSpotCheck.performance';
		}
	}
}
