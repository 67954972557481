import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountQualtricsStatus } from './master-account-qualtrics-status';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { QualtricsStatusApiService } from '@app/modules/system-administration/status/integration-status/qualtrics/qualtrics-status-api.service';
import { QualtricsStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/qualtrics/qualtrics-status-table-columns.service';

@Injectable({providedIn: 'root'})
export class QualtricsStatusService implements IntegrationStatusService<MasterAccountQualtricsStatus> {
	constructor(
		private qualtricsStatusApiService: QualtricsStatusApiService,
		private qualtricsStatusTableColumnsService: QualtricsStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.qualtricsIntegration';
	}

	getColumns(): TableColumn<MasterAccountQualtricsStatus>[] {
		return this.qualtricsStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountQualtricsStatus[]> {
		return this.qualtricsStatusApiService.getQualtricsStatuses();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.qualtricsStatusApiService.export(search);
	}
}
