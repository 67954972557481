import { Hideable } from '@cxstudio/common/entities/hideable.interface';

export class Model implements Hideable {
	id: number;
	name: string;
	root?: any;
	hidden?: boolean;
	hide?: boolean;
	classified?: boolean;
	rootNodeId?: number;
	qualityManagement?: boolean;
}
