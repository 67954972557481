import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils } from '@app/util/change-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';

@Component({
	selector: 'metric-real-data-preview',
	templateUrl: './metric-real-data-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricRealDataPreviewComponent implements OnChanges {

	@Input() widget: Widget;
	@Input() utils: WidgetUtils;
	@Input() trigger: number;
	@Input() showTotalCount: boolean;
	@Input() visualizationUpdateRequired: boolean;

	@Output() updateDataLoading = new EventEmitter<Promise<any>>();
	@Output() updateChart = new EventEmitter<void>();

	constructor(
		private readonly ref: ChangeDetectorRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.visualizationUpdateRequired)) {
			if (changes.visualizationUpdateRequired.currentValue) {
				this.ref.detach();
			} else {
				this.ref.reattach();
			}
		}
	}

}

app.directive('metricRealDataPreview', downgradeComponent({component: MetricRealDataPreviewComponent}) as angular.IDirectiveFactory);
