<form #newPasswordForm="ngForm">
	<div class="login-wrapper">
		<login-banner
			[message]="getMessage()"
		></login-banner>
		<app-logo></app-logo>
		<div class="login-form" [ngBusy]="loading" [ngClass]="{ 'border-t-radius-0': !!getMessage() }">
			<div class="form-group">
				<button id="btn-generate" class="btn btn-secondary" (click)="generate()">
					<span *ngIf="!loadingGenerate" [i18n]="'login.generatePassword'"></span>
					<span *ngIf="loadingGenerate" class="q-icon q-icon-spinner rotate-infinite"></span>
				</button>
			</div>
			<div class="form-group">
				<label for="new-password" [i18n]="'userDialog.newPassword'"></label>
				<input id="new-password" type="text" name="newPassword" class="form-control border-b-radius-0"
					autocomplete="off" placeholder="" [(ngModel)]="newPassword" (ngModelChange)="onPasswordChange()" />
				<password-strength *ngIf="resetContext" [passwordState]="passwordState"
					[passwordPolicy]="resetContext.passwordPolicy"></password-strength>
				<div *ngFor="let note of passwordNotes" class="mt-8">
					<i [ngClass]="getNoteClass(note)" class="q-icon-check"></i>
					<span class="ml-4">{{ note.text }}</span>
				</div>
				<alert *ngFor="let error of passwordErrors" class="mt-8" type="danger" [dismissable]="false"
					[fullWidth]="true">
					<span>{{ error }}</span>
				</alert>
			</div>
			<div class="form-group">
				<label for="confirm-password" [i18n]="'userDialog.confirmPassword'"></label>
				<input id="confirm-password" type="text" name="confirmPassword" class="form-control" placeholder=""
					autocomplete="off" #confirmPasswordField="ngModel" [pwCheck]="newPassword"
					(pwCompareChange)="onPasswordCompareChange()" [(ngModel)]="confirmPassword" />
				<alert *ngIf="
						!passwordGeneration &&
						confirmPassword &&
						confirmPasswordField.errors?.pwmatch
					" class="mt-8" type="danger" [dismissable]="false" [fullWidth]="true">
					<span [i18n]="'administration.passwordNotMatch'"></span>
				</alert>
			</div>
			<div class="d-flex">
				<button id="btn-cancel" class="btn btn-secondary w-25-percent" (click)="cancel()">
					<span [i18n]="'common.cancel'"></span>
				</button>
				<button id="btn-confirm-password" class="btn btn-primary w-75-percent" [disabled]="!isPasswordValid()"
					(click)="updatePassword()">
					<span [i18n]="'login.confirmResetPassword'"></span>
				</button>
			</div>
		</div>
	</div>
</form>
