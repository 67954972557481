app.directive('starRating', () => {
	return {
		restrict: 'A',
		template: '',
		link: (scope: ng.IScope, elem: JQuery, attrs: ng.IAttributes) => {

			scope.$watch(attrs.starRating, (rating: number) => {
				let stars = '';
				let intValue = Math.round(rating); // after dividing by 2: 2.3 -> 2.5, 2.2 -> 2
				if (intValue === 0) {
					elem.html('<span>rate</span>');
				} else {	
					for (let i = 0; i < Math.floor(intValue / 2); i++) {
						stars += '<i class="rating-star q-icon-star"></i>';
					}
					if (intValue % 2 === 1) {
						stars += '<i class="rating-star q-icon-star-half"></i>';
					}
					elem.html(stars);
				}
			});
		}
	};
});