
enum BulkUpdateValue {
	NONE = 'update-none',
	ADD = 'update-add',
	REMOVE = 'update-remove',

	//data access
	ADMIN = 'update-admin',
	READ_ONLY = 'update-read-only',
	NO_ACCESS = 'update-no-access'
}

export default BulkUpdateValue;

