import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';


@Directive({
	selector: 'widget-endpoint'
})

export class WidgetEndpointUpgrade extends UpgradeComponent {
	@Input() clearErrors: any;
	@Input() pushError: any;
	@Input() addLoadingPromise: any;
	@Input() loading: any;
	@Input() currentSettings: any;
	@Input() props: WidgetProperties;
	@Input() widget: Widget;
	@Input() showContentProviderErrors: any[];

	constructor(elementRef: ElementRef, injector: Injector) {
		super('widgetEndpoint', elementRef, injector);
	}
}
