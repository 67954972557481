import { Inject, Injectable } from '@angular/core';
import { CustomMathTokenizerService } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-tokenizer.service';
import { CustomMathValidationService} from '@cxstudio/metrics/custom-math/custom-math-validation.service';
import { SymbolAdapter } from '@app/modules/metric/definition/custom-math/adapter/symbol-adapter.service';
import { AttributeAdapter } from '@app/modules/metric/definition/custom-math/adapter/attribute-adapter.service';
import { MetricAdapter } from '@app/modules/metric/definition/custom-math/adapter/metric-adapter.service';
import { NumberAdapter } from '@app/modules/metric/definition/custom-math/adapter/number-adapter.service';
import { SystemMetricAdapter } from '@app/modules/metric/definition/custom-math/adapter/system-metric-adapter.service';
import { HierarchyMetricAdapter } from '@app/modules/metric/definition/custom-math/adapter/hierarchy-metric-adapter.service';
import { ScorecardMetricAdapter } from '@app/modules/metric/definition/custom-math/adapter/scorecard-metric-adapter.service';
import { CustomMathExpressionAdapter } from './custom-math-expression-adapter';
import { CustomMathTokenType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { SpaceAdapter } from '@app/modules/metric/definition/custom-math/adapter/space-adapter.service';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathAdapter } from './custom-math-adapter.class';
import { ScorecardNodeMetricAdapter } from './scorecard-node-metric-adapter.service';

@Injectable({providedIn: 'root'})
export class CustomMathAdapterProvider {
	private adapters: Map<CustomMathTokenType, CustomMathExpressionAdapter>;

	constructor(
		private customMathTokenizerService: CustomMathTokenizerService,
		private symbolAdapter: SymbolAdapter,
		private numberAdapter: NumberAdapter,
		private attributeAdapter: AttributeAdapter,
		private systemMetricAdapter: SystemMetricAdapter,
		private metricAdapter: MetricAdapter,
		private hierarchyMetricAdapter: HierarchyMetricAdapter,
		private scorecardMetricAdapter: ScorecardMetricAdapter,
		private scorecardNodeMetricAdapter: ScorecardNodeMetricAdapter,
		private spaceAdapter: SpaceAdapter,
		@Inject('customMathValidationService') private customMathValidationService: CustomMathValidationService
	) {
		this.adapters = new Map();
		this.adapters.set(CustomMathTokenType.NUMBER, this.numberAdapter);
		this.adapters.set(CustomMathTokenType.SYMBOL, this.symbolAdapter);
		this.adapters.set(CustomMathTokenType.ATTRIBUTE, this.attributeAdapter);
		this.adapters.set(CustomMathTokenType.SYSTEM, this.systemMetricAdapter);
		this.adapters.set(CustomMathTokenType.METRIC, this.metricAdapter);
		this.adapters.set(CustomMathTokenType.HIERARCHY_METRIC, this.hierarchyMetricAdapter);
		this.adapters.set(CustomMathTokenType.SCORECARD_METRIC, this.scorecardMetricAdapter);
		this.adapters.set(CustomMathTokenType.SCORECARD_NODE_METRIC, this.scorecardNodeMetricAdapter);
		this.adapters.set(CustomMathTokenType.SPACE, this.spaceAdapter);
	}

	getInstance(assets: CustomMathAssets): CustomMathAdapter {
		return new CustomMathAdapter(assets,
			this.adapters,
			this.customMathTokenizerService,
			this.customMathValidationService);
	}
}
