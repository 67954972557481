import { Component, OnInit, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';
import { CxLocaleService } from '@app/core';
import { MasterAccountPropertiesApiService } from '@app/modules/account-administration/api/master-account-properties-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { License } from '@app/modules/user-administration/permissions/license.class';
import { ToastService, AlertLevel } from '@clarabridge/unified-angular-components';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
@Component({
	selector: 'licensing-permissions',
	template: `
	<div class="panel-default" [ngBusy]=loading>
		<div class="panel-heading">{{head}}</div>
		<div class="panel-body p-relative">
			<permissions
				[head]=headLabel
				[license]=license
				layout=horizontal
				[selectedPermissions]=permissions
				[permissions]=availablePermissions
				(onChange)="permissionsChangedCallback($event)"
			></permissions>
		</div>
		<div class="panel-footer d-flex justify-end">
			<button
				type="submit"
				class="btn btn-primary"
				(click)=saveChanges()
				[disabled]=!hasChanges()>{{'common.save'|i18n}}</button>
			<button
				type="button"
				class="btn btn-secondary"
				[disabled]=!hasChanges()
				(click)="cancelChanges()">{{'common.resetChanges'|i18n}}</button>
			<button
				type="button"
				class="btn btn-secondary"
				[disabled]=!differentFromSystemDefaults()
				(click)="resetToDefault()">{{'common.restoreDefault'|i18n}}</button>
			<button
				type="button"
				class="btn btn-secondary"
				[disabled]= hasChanges()
				(click)="updateExistingUsersPermissions()"
				[i18n]="'common.updateExistingUsers'"
				[i18nParams]="{licenseType: license.licenseTypeName}"></button>
		</div>
	</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LicensingPermissionsComponent implements OnInit {

	@Input() availablePermissions: MasterAccountPermission[];
	@Input() license: License;
	@Input() licenseDefaultPermissions: MasterAccountPermission[];
	@Input() systemDefaultPermissions: MasterAccountPermission[];
	head: string;
	headLabel: string;
	permissions: MasterAccountPermission[];
	beforeChanges: MasterAccountPermission[];
	loading: Promise<any>;

	constructor(
		private locale: CxLocaleService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		private cxDialogService: CxDialogService,
		private userModificationService: UserModificationApiService,
		private masterAccountPropertiesApiService: MasterAccountPropertiesApiService,
		private readonly toastService: ToastService
	) {}

	ngOnInit(): void {
		this.head = this.license.licenseTypeName;
		let typeName = this.license.licenseTypeName;
		this.headLabel = this.locale.getString('licensing.headLabel', { typeName });
		this.resetInit();
	}

	saveChanges(): void {
		this.loading = Promise.resolve(this.cbDialogService.confirm(this.locale.getString('common.warning'),
			this.locale.getString('licensing.updateDefaultConfirm'),
			this.locale.getString('common.save'),
			this.locale.getString('common.cancel'), 'warning-modal').result.then(() => {
				return this.masterAccountPropertiesApiService.updateDefaultPermissions(this.license.licenseTypeId, this.permissions)
					.then((response) => {
						let typeName = this.license.licenseTypeName;
						this.resetInit(this.permissions);
						this.toastService.addToast(this.locale.getString('licensing.successUpdated', { typeName }), AlertLevel.SUCCESS);
					}) as PromiseLike<any>;
			}) as PromiseLike<any>);
	}

	updateExistingUsersPermissions(): void {
		let typeName = this.license.licenseTypeName;
		let dialog = this.cxDialogService.warningWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('licensing.updateExistingUsers', {typeName}),
			this.locale.getString('common.confirm'));
		
		this.loading = dialog.result.then(() => {
			return this.userModificationService.updateExistingUsersPermissionsForLicenseType(this.license.licenseTypeId)
				.then((response) => {
					this.toastService.addToast(this.locale.getString('licensing.permissionUpdateStarted', { typeName }), AlertLevel.SUCCESS);
				}) as PromiseLike<any>;
		}).catch(() => {});
	}

	cancelChanges(): void {
		this.resetInit(this.beforeChanges);
	}

	resetToDefault(): void {
		this.loading = Promise.resolve(this.cbDialogService.confirm(this.locale.getString('common.warning'),
			this.locale.getString('licensing.restoreDefaultConfirm'),
			this.locale.getString('common.restoreDefault'),
			this.locale.getString('common.cancel'),
			'warning-modal').result
			.then(() => {
				return this.masterAccountPropertiesApiService.restoreDefaultPermissions(this.license.licenseTypeId).then((response) => {
					let typeName = this.license.licenseTypeName;
					this.resetInit(response);
					this.toastService.addToast(
						this.locale.getString(`licensing.successRestored`, { typeName }), AlertLevel.SUCCESS);
				}) as PromiseLike<any>;
			}) as PromiseLike<any>);
	}

	hasChanges(): boolean {
		return this.permissionsChanged(this.permissions, this.beforeChanges);
	}

	differentFromSystemDefaults(): boolean {
		return this.permissionsChanged(this.permissions, this.systemDefaultPermissions);
	}

	permissionsChanged(initialPermissions: MasterAccountPermission[], currentPermissions: MasterAccountPermission[]): boolean {
		return currentPermissions.length !== initialPermissions.length
			|| _.some(currentPermissions, (permission) => !_.findWhere(initialPermissions, {action: permission.action}));
	}

	permissionsChangedCallback(selectedPermissions): void {
		this.permissions = selectedPermissions.selectedPermissions;
	}

	private resetInit(permissions?: MasterAccountPermission[]): void {
		if (!permissions) {
			permissions = this.licenseDefaultPermissions;
		}

		this.permissions = [...permissions];
		this.beforeChanges = [...permissions];
	}
}
