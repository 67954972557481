import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { GaugeBlockSizeUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-block-size-utils';
import { GaugeDefaults } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-defaults';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';
import { WidgetSize } from '@app/modules/widget-visualizations/widget-size';

export abstract class GaugeBaseLayout {
	protected abstract getNeededWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number;
	protected abstract getNeededHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number;
	abstract getComparisonsColumnHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number;
	abstract getComparisonsRowWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number;
	abstract isComparisonsStacked(): boolean;

	isFit(
		box: WidgetSize, cssVars: GaugeVariables, comparisons: ComparisonData[]
	): boolean {
		const availableHeight = box.height - 2 * GaugeDefaults.margin;
		const neededHeight = this.getNeededHeight(cssVars, comparisons);
		const availableWidth = box.width - 2 * GaugeDefaults.margin;
		const neededWidth = this.getNeededWidth(cssVars, comparisons);
		return availableHeight >= neededHeight
			&& availableWidth >= neededWidth;
	}

	protected getGaugeWidth(cssVars: GaugeVariables): number {
		return GaugeBlockSizeUtils.getGaugeWidth(cssVars);
	}

	protected getGaugeHeight(cssVars: GaugeVariables): number {
		return GaugeBlockSizeUtils.getGaugeHeight(cssVars);
	}

	protected getComparisonWidth(cssVars: GaugeVariables): number {
		return GaugeBlockSizeUtils.getComparisonWidth(cssVars);
	}

	protected getComparisonHeight(cssVars: GaugeVariables): number {
		return GaugeBlockSizeUtils.getComparisonHeight(cssVars);
	}

	protected hasComparisons(comparisons: ComparisonData[]): boolean {
		return this.getComparisonsCount(comparisons) > 0;
	}

	protected getComparisonsCount(comparisons: ComparisonData[]): number {
		return comparisons ? comparisons.length : 0;
	}
}
