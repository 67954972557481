import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export default class DashboardPermissionUtils {

	constructor(
		private security: Security
	) {
	}

	canScheduleDashboard(dashboard: Dashboard): boolean {
		return (dashboard.permissions.OWN || dashboard.permissions.EDIT) && this.security.has('create_dashboard');
	}

}

app.service('dashboardPermissionUtils', DashboardPermissionUtils);