import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { PointSelectionCallback } from '@app/modules/widget-visualizations/highcharts/point-selection-callback.interface';
import ChartType from '@cxstudio/reports/entities/chart-type';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';

@Injectable({
	providedIn: 'root'
})
export class HighchartsDualUtilService {
	readonly MAX_BAR_WIDTH = 24;
	constructor(
		private locale: CxLocaleService,
		private highchartsUtils: HighchartsUtilsService,
		private betaFeaturesService: BetaFeaturesService,
		@Inject('widgetsEditService') private widgetsEditService: WidgetsEditService,
		@Inject('reportNumberFormatUtils') private reportNumberFormatUtils: ReportNumberFormatUtils,
	) { }

	getBaseOptions(options: VisualProperties, utils: WidgetUtils, pointCallback: PointSelectionCallback): Highcharts.Options {
		let scaleY = this.highchartsUtils.getAxisScale('y', options);
		const modernLookAndFeelEnabled = this.widgetsEditService.getDashboard()?.properties.modernLookAndFeelEnabled || false;
		return $.extend(this.highchartsUtils.getDefaultConfig(), {
			chart: {
				...this.getPolarOrRadarFlag(options),
				spacingTop: 30,
				spacingBottom: this.highchartsUtils.getSpacingBottom(options, 5), // 5 px to avoid truncation of labels
				borderRadius: 0,
				alignTicks: isEmpty(options.alignTicks) ? true : options.alignTicks,
			},
			tooltip: {
				enabled: true,
				shared: false,
				headerFormat: '',
				pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
					return '<span style="font-size:10px"><span style="color:{series.color}">'
						+ `\u25CF</span> ${(scope.displayName || scope.name)}</span><br/>`
						+ `${scope.series.name} : ${scope.y}`;
				})
			},
			yAxis: [{
				title: {
					text: this.getAxisTitle(options, utils)
				},
				min: scaleY.min !== null
					? scaleY.min
					: DualDefinitionHelper.isZeroBased(options.yAxis) ? 0 : null,
				max: scaleY.max,
				startOnTick: scaleY.min === null,
				endOnTick: scaleY.max === null,
				labels: {
					formatter: HighchartsClosureUtils.closureWrapper((scope) => {
						return utils.dataFormatter ? utils.dataFormatter(scope.value)
							: this.reportNumberFormatUtils.formatMetric(options.yAxis, scope.value);
					})
				}
			}],
			xAxis: {
				labels: {
					formatter: HighchartsClosureUtils.closureWrapper((scope) => {
						return scope.value;
					})
				}
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: isTrue(options.showLabels),
						formatter: HighchartsClosureUtils.closureWrapper((scope) => {
							return this.reportNumberFormatUtils.formatAny(scope.y);
						})
					},
					stickyTracking: false,
					point: {
						events: {
							mouseOver: HighchartsClosureUtils.closureWrapper((scope) => {
								let point = this.highchartsUtils.getSelectedPoint(scope);
								if (scope.series.index === 1) {
									point.seriesType = PlotLineAxis.secondary;
								}
								pointCallback.onSelect(point);
							}),

							mouseOut: HighchartsClosureUtils.closureWrapper(() => {
								pointCallback.onDeselect();
							})
						}
					},
					events: {
						afterAnimate: HighchartsClosureUtils.closureWrapper((scope) => {
							this.highchartsUtils.handleRenderedEvent(scope, utils);
						})
					}
				},
				bar: {
					maxPointWidth: modernLookAndFeelEnabled ? this.MAX_BAR_WIDTH : undefined
				},
				column: {
					maxPointWidth: modernLookAndFeelEnabled ? this.MAX_BAR_WIDTH : undefined
				},
				bubble: {
					maxSize: !options.secondarySize ? '9%' : '20%',
					minSize: '5%',
					tooltip: {
						headerFormat: '',
						pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
							let sizeText: string;
							let size: string;
							let isPrimary = (scope.series.options as any).primary;
							if (isPrimary && options.size) {
								size = utils.titleFormatter(options.size);
							} else if (!isPrimary && options.secondarySize) {
								size = utils.titleFormatter(options.secondarySize);
							}
							sizeText = (size && size !== scope.series.name) ? (`${size} : ${scope.z}<br />`) : '';
							return '<span style="font-size:10px"><span style="color:{series.color}">'
								+ `\u25CF</span> ${(scope.displayName || scope.name)}</span><br/>`
								+ `${sizeText}${scope.series.name} : ${scope.y}`;
						})
					},
					marker: {
						fillOpacity: 1,
						lineWidth: 2,
						lineColor: '#ffffff'
					}
				}
			},
			series: []
		});
	}

	configureAxis(options: VisualProperties, utils: WidgetUtils, chartOptions: Highcharts.Options): void {
		this.configureAxisLabel(options, chartOptions);
		this.configureAxisColor(options, utils, chartOptions);
	}

	getAxisTitle(options: VisualProperties, utils: WidgetUtils): string {
		if (this.isCalculationSeries(options)) {
			return options.axisTitle || '';
		} else {
			return utils.titleFormatter(options.yAxis);
		}
	}

	private configureAxisColor(options: VisualProperties, utils: WidgetUtils, chartOptions: Highcharts.Options) {
		let primaryColor = this.getAxisColor(options.color, utils.colorFunction);
		let secondaryColor = this.getAxisColor(options.secondaryColor, utils.secondaryColorFunction);
		if (primaryColor === secondaryColor)
			return;
		let colors = [primaryColor, secondaryColor];
		for (let i = 0; i < 2; i++) {
			if (colors[i] === null)
				continue;
			chartOptions.yAxis[i].title.style = $.extend(chartOptions.yAxis[i].title.style, {
				color: colors[i]
			});
			let labels = chartOptions.yAxis[i].labels || {};
			labels.style = $.extend(labels.style, {
				color: colors[i]
			});
			chartOptions.yAxis[i].labels = labels;
		}
	}

	private getAxisColor(type, colorFunction): string {
		if (type === 'solid' || type === 'custom') {
			let suggestedColor = colorFunction({}, 0);
			return _.isObject(suggestedColor) ? suggestedColor.color : suggestedColor;
		}
		return null;
	}

	private configureAxisLabel(options: VisualProperties, chartOptions: Highcharts.Options): void {
		let subChartType = options.subChartType === ChartType.PARETO
			? ChartType.COLUMN : options.subChartType;
		if (subChartType !== options.secondaryChartType) {
			let types = [subChartType, options.secondaryChartType];
			for (let i = 0; i < 2; i++) {
				chartOptions.yAxis[i].title.text += ` (${this.locale.getString(`widget.${types[i].toLowerCase()}Label`)})`;
			}
		}
	}

	private isCalculationSeries(options: VisualProperties) {
		return options.subChartType === ChartType.SPLINE
			&& options.secondaryGroup === KeyMetricListTypes.CALCULATION_SERIES;
	}

	/**
	 * Return the highcharts `polar` flag if subchart is polar or radar
	 * Both polar and radar use this flag
	 */
	private getPolarOrRadarFlag(options: VisualProperties): { polar: boolean } {
		const polar = this.isPolarOrRadar(options);
		return { polar };
	}

	isPolarOrRadar(options: VisualProperties): boolean {
		const isFeatureEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.BAR_LINE_SUBTYPES);
		return isFeatureEnabled &&
				((options.subChartType === ChartType.POLAR) || (options.subChartType === ChartType.RADAR));
	}
}
