import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { InteractionPermissions } from './interaction-permissions';
import { DeletionDocumentMetadata } from './interaction-actions.service';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { MixedFilter } from '@cxstudio/report-filters/filter-builder/mixed-filter';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { DeleteReason } from '@app/modules/interaction-explorer/delete-reason.enum';

@Injectable()
export class InteractionApiService {

	constructor(
		private cxHttp: CxHttpService,
		private sessionPreferences: SessionPreferencesService,
	) {}

	getDocumentsSubmittedForDeletion = (cpId: number, accountId: number, projectId: number): Promise<number[]> => {
		return this.cxHttp.get(`rest/interactions/submitted-for-deletion/content-provider/${cpId}/account/${accountId}/project/${projectId}`);
	}

	deleteDocuments = (
		contentProviderId: number,
		accountId: number,
		projectId: number,
		reason: DeleteReason,
		documentsMetadata: DeletionDocumentMetadata[]): Promise<void> => {

			return this.cxHttp.post('rest/interactions/delete', {
				contentProviderId,
				accountId,
				projectId,
				reason,
				documentsMetadata
			});
	}

	getInteractionPermissions = (contentProviderId: number, accountId: number, projectId: number): Promise<InteractionPermissions> => {
		return this.cxHttp.post('rest/interactions/permissions', {
			contentProviderId,
			accountId,
			projectId
		});
	}

	saveFilter = (projectSelection: IProjectSelection, filter: MixedFilter): void => {
		this.sessionPreferences.setProperty('interactionFilter', ProjectIdentifier.toStringKey(projectSelection), filter);
	}

	getFilter = (projectSelection: IProjectSelection): MixedFilter => {
		return this.sessionPreferences.getProperty('interactionFilter', ProjectIdentifier.toStringKey(projectSelection));
	}

}

app.service('interactionApiService', downgradeInjectable(InteractionApiService));
