import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nameFilter'
})

export class NameFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items || items.length < 1) return [];
		if (!searchText || searchText.length < 1) return items;

		return items.filter(item => {
			return item.displayName && item.displayName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
		});
	}
}