import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { LoadingErrorType } from '@app/modules/embed/embedded-error-container/embedded-error-container.component';
import { EmbedApiService } from '@app/modules/embed/embed-api.service';
import { HttpResponse } from '@cxstudio/common/http-response';
import { CHANGE_MA_STATUS, UrlService } from '@cxstudio/common/url-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { Security } from '@cxstudio/auth/security-service';

enum EmbedPageType {
	DASHBOARD = 'DASHBOARD',
	WIDGET = 'WIDGET',
}

export enum EmbedType {
	INTERNAL = 'internal',
	EXTERNAL = 'external'
}

@Component({
	selector: 'embedded-page',
	templateUrl: './embedded-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedPageComponent implements OnInit {
	@Input() type: EmbedPageType;

	errorType: LoadingErrorType;
	errorMessage: string;
	ready: boolean;
	validationLoading: PromiseLike<any>;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly embedApi: EmbedApiService,
		private readonly locale: CxLocaleService,
		@Inject('$routeParams') private readonly $routeParams: IRouteParams,
		@Inject('redirectService') private readonly redirectService: RedirectService,
		@Inject('dashboardApiService') private readonly dashboardApiService: DashboardApiService,
		@Inject('security') private security: Security,
		@Inject('urlService') private urlService: UrlService,
	) { }

	ngOnInit(): void {
		this.init();
	}

	init(): void {
		this.ready = false;
		delete this.errorType;
		delete this.errorMessage;
		if (!this.security.getEngageDashboardAccessToken() && this.$routeParams['engageToken']) {
			this.consumeEngageToken(this.$routeParams['engageToken']);
		} else {
			this.validateDashboard();
		}
	}

	hasError(): boolean {
		return !!this.errorType;
	}

	validateDashboard(): void {
		this.validationLoading = this.embedApi.validateDashboardAccess(this.getDashboardId(), this.$routeParams.identifier).then(() => {
			this.ready = true;
			this.ref.markForCheck();
		}, errorResponse => this.handleDashboardError(errorResponse));
	}

	consumeEngageToken(engageToken): void {
		let engageJWTPayload = {
			'engageToken': engageToken,
			'dashboardId': this.getDashboardId()
		};
		this.dashboardApiService.consumeEngageToken(engageJWTPayload).then((tokenResponse) => {
			if (tokenResponse.data.length !== 0) {
				this.security.setEngageDashboardAccessToken(tokenResponse.data);
			}
			this.validateDashboard();
		});
	}

	getDashboardId(): number {
		return Number(this.$routeParams.dashboardId);
	}

	getEmbedLocation = (): string => {
		if (this.$routeParams['internal-dbpage']) {
			return EmbedType.INTERNAL;
		}
		return EmbedType.EXTERNAL;
	}

	isEmbeddedInternally = (): boolean => {
		return this.getEmbedLocation() === EmbedType.INTERNAL;
	}

	private handleDashboardError = (response: HttpResponse<any>): void => {
		let status = response.status;
		if (status === CHANGE_MA_STATUS) { // dashboard from another MA, need to reload
			this.redirectService.saveCurrentMA({accountId: response.data});
		} else if (status === 400) {
			this.errorType = LoadingErrorType.message;
			this.errorMessage = response.data;
		} else if (status === 403) {
			this.errorType = LoadingErrorType.noAccess;
			this.redirectService.goToRequestAccess(ObjectType.DASHBOARD, this.getDashboardId());
			return;
		} else if (status === 406) {
			this.errorType = LoadingErrorType.message;
			if (this.type === EmbedPageType.WIDGET) {
				this.errorMessage = this.locale.getString('widget.embedNoRegistration');
			} else {
				const url = this.urlService.getBookUrl(this.getDashboardId());
				this.errorMessage = this.locale.getString('error.noEmbedding', { url });
			}

		} else {
			this.errorType = LoadingErrorType.message;
			this.errorMessage = this.locale.getString('common.unknownError');
		}
		this.ref.markForCheck();
	}

}

app.directive('embeddedPage', downgradeComponent({component: EmbeddedPageComponent}) as angular.IDirectiveFactory);
