import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DashboardGridHelperService } from '@app/modules/dashboard-list/context-menu/dashboard-grid-helper.service';
import { AgGridNested } from '@app/modules/object-list/types/ag-grid-nested.interface';
import { AssetSpecificUtilsService } from '@app/modules/unified-templates/common-templates/asset-specific-utils.service';
import { AssetTemplateActions } from '@app/modules/unified-templates/common-templates/asset-template-actions-factory.service';
import { UnifiedTemplateTreeItem } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { Folders, ROOT_FOLDER_ID } from '@cxstudio/folders/folders-constant';
import { MenuItemDef, RowNode } from 'ag-grid-community';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';

type AgFolder = IFolderItem & AgGridNested;

@Injectable({
	providedIn: 'root'
})
export class AssetTemplateMenuFactoryService {
	constructor(
		private locale: CxLocaleService,
		private assetSpecificUtils: AssetSpecificUtilsService,
		@Inject('security') private security: Security,
		@Inject('authorization') private authorization: Authorization,
	) {}

	get(actionsService: AssetTemplateActions): AssetTemplateMenu {
		return new AssetTemplateMenu(
			this.locale,
			actionsService,
			this.assetSpecificUtils,
			actionsService.folderService,
			this.security,
			this.authorization
		);
	}
}

export class AssetTemplateMenu {

	constructor(
		private locale: CxLocaleService,
		private actionsService: AssetTemplateActions,
		private assetSpecificUtils: AssetSpecificUtilsService,
		private folderService: IFolderService,
		private security: Security,
		private authorization: Authorization,
	) {}

	getMenuOptions(node: RowNode, allTemplates: (UnifiedTemplateTreeItem | IFolderItem)[]): (string | MenuItemDef)[] {
		if (FolderUtils.isFolder(node.data)) {
			return this.getFolderMenuOptions(node, allTemplates);
		} else {
			return this.getTemplateMenuOptions(node, allTemplates);
		}
	}

	private getFolderMenuOptions(node: RowNode, templates: (UnifiedTemplateTreeItem | IFolderItem)[]): (string | MenuItemDef)[] {
		let folder = node.data as IFolderItem & AgGridNested;

		let items: (string | MenuItemDef)[] = [];

		if (folder.path?.length < FolderUtils.MAX_DEPTH) {
			items.push({
				name: this.locale.getString('dashboard.addSubfolder'),
				action: () => this.actionsService.createSubFolder(folder, templates)
			});
		}

		items.push({
			name: this.locale.getString('common.rename'),
			action: () => {
				this.actionsService.renameFolder(folder, templates);
			}
		});

		let moveToOption = this.getMoveToOption(folder, templates);
		if (moveToOption) {
			items.push(moveToOption);
		}

		items.push({
			name: this.locale.getString('common.delete'),
			action: () => this.actionsService.removeFolder(folder)
		});

		return items;
	}

	private getTemplateMenuOptions(node: RowNode, templates: (UnifiedTemplateTreeItem | IFolderItem)[]): (string | MenuItemDef)[] {
		let template = node.data as UnifiedTemplateTreeItem;

		let options: (string | MenuItemDef)[] = [];

		if (this.assetSpecificUtils.hasCreationPermission(template.assetType)) {
			options.push({
				name: this.assetSpecificUtils.getAssetCreateLabel(template.assetType),
				action: ( ) => this.actionsService.createFromTemplate(template)
			});
		}

		if (this.assetSpecificUtils.canShareTemplate(template)) {
			if (template.sharingStatus === SharingStatus.PRIVATE) {
				options.push({
					name: this.locale.getString('templates.shareTemplate'),
					action: () => this.actionsService.changePublicStatus(template, true)
				});
			} else {
				options.push({
					name: this.locale.getString('templates.unshareTemplate'),
					action: () => this.actionsService.changePublicStatus(template, false)
				});
			}
		}

		if (this.authorization.hasManageTemplatePermission()
			|| (this.security.isCurrentUser(template.ownerName)
				&& this.authorization.hasCreateTemplatePermission())) {
			options.push({
				name: this.locale.getString('templates.editProperties'),
				action: () => this.actionsService.editProperties(template)
			});

			options.push({
				name: this.locale.getString('templates.editMappings'),
				action: () => this.actionsService.editMappings(template)
			});

			if (this.security.isSysAdmin() && template.assetType === TemplateAssetType.DASHBOARD) {
				options.push({
					name: this.locale.getString('common.export'),
					action: () => this.actionsService.exportTemplate(template)
				});
			}

			let moveToOption = this.getMoveToOption(template, templates);
			if (moveToOption) {
				options.push(moveToOption);
			}

			if (this.security.isCurrentUser(template.ownerName) || this.authorization.hasManageTemplatePermission()) {
				options.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
				options.push({
					name: this.locale.getString('templates.deleteTemplate'),
					action: () => this.actionsService.deleteTemplate(template)
				});
			}
		}

		return options;
	}

	private getMoveToOption(item: UnifiedTemplateTreeItem | IFolderItem, templates: (UnifiedTemplateTreeItem | IFolderItem)[]): MenuItemDef {
		let moveToItems: (MenuItemDef | string)[] = _.chain(this.folderService.getPlainFoldersForMove(templates, item))
			.map(folder => folder as any as AgFolder)
			.filter(folder => folder.id !== ROOT_FOLDER_ID) // ignore root folder, will be added below
			.filter(folder => folder.path.length < FolderUtils.MAX_DEPTH)
			.sortBy(folder => folder.path.join('>'))
			.map(folder => ({
				name: `${'--'.repeat(folder.path.length - 1)} ${folder.name}`.trim(),
				action: () => this.actionsService.moveToFolder(item, folder)
			})).value();
		if (!!item.parentId) {
			if (moveToItems.length > 0) {
				moveToItems.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
			}
			moveToItems.push({
				name: this.locale.getString('common.moveToRoot'),
				action: () => this.actionsService.moveToFolder(item, Folders.ROOT_FOLDER as any)
			});
		}

		if (!_.isEmpty(moveToItems)) {
			return {
				name: this.locale.getString('dashboard.move'),
				subMenu: moveToItems
			};
		} else {
			return null;
		}
	}
}
