import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';


@Injectable({providedIn: 'root'})
export class SymbolAdapter implements CustomMathExpressionAdapter {
	private allowedSymbols: string[];

	constructor() {
		this.allowedSymbols = ['+', '-', '*', '/', '(', ')', 'abs(', '^(', '[', ']'];
	}
	
	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let valid = this.allowedSymbols.contains(token.value);

		let textToken: TextToken;
		if (token.text) {
			textToken = {text: token.text}; 
			if (valid) {
				textToken.type = TextTokenType.SYNTAX;
			} else {
				textToken.errors = [CustomMathErrorType.INVALID_TEXT];
			}
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: []
		};
		if (textToken) segment.textTokens.push(textToken);

		let display = token.value === '*' ? 'X' : token.value;
		segment.expression = new ExpressionItem(ExpressionPieces.SYMBOL, display, token.value);

		let nextTokenIsError = token.nextTokenIsError;
		if (nextTokenIsError) {
			segment.expression.nextItemIsError = token.nextTokenIsError;
		}
		
		return segment;
	}
	
	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		if (expression.value === '^(') return '^ (';
		return expression.value;
	}
}