import { Component, ChangeDetectionStrategy } from '@angular/core';
import { VoiceStatusService } from '@app/modules/system-administration/status/integration-status/voice/voice-status.service';

@Component({
	selector: 'voice-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceStatusTabComponent {
	constructor(
		public service: VoiceStatusService,
	) {}
}
