import { CustomMathErrorType, CustomMathWarning } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';

export enum TextTokenType {
	SYNTAX = 'syntax',
	NUMBER = 'number',
	METRIC = 'metric',
	PREDEFINED_METRIC = 'predefined-metric',
	NUMERIC_ATTRIBUTE = 'attribute-number',
	TEXT_ATTRIBUTE = 'attribute-text',
	GENERIC_ATTRIBUTE = 'attribute-generic',
	NUMERIC_AGGREGATION = 'numeric-function',
	TEXT_AGGREGATION = 'text-function',
	GENERIC_AGGREGATION = 'generic-function',
	SCORECARD_METRIC = 'scorecard-metric',
	HIERARCHY_METRIC = 'hierarchy-metric',
	RESERVED_WORD = 'reserved-word',
	PREFIX = 'prefix',
	SPACE = 'space'
}

export interface SupportTextErrors {
	text: string;
	errors?: CustomMathErrorType[];
	warnings?: CustomMathWarning[];
}

export interface TextToken extends SupportTextErrors {
	type?: TextTokenType;
	isFunction?: boolean;
}

export interface FormulaSegment extends SupportTextErrors {
	expression?: ExpressionItem;
	startOffset: number;
	textTokens?: TextToken[];
	title?: string;
}