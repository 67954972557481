import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { AssetManagementModule } from '../asset-management/asset-management.module';
import { ModelDependenciesApiService } from './services/model-dependencies-api-service';
import { ModelDependencyTypesProvider } from './services/model-dependency-types-provider';
import { ModelGroupingSettingsEditorProvider } from './services/model-grouping-settings-editor.provider';


@NgModule({
	imports: [
		SharedModule,
		AssetManagementModule,
	],
	providers: [
		ModelDependenciesApiService,
		ModelDependencyTypesProvider,
		ModelGroupingSettingsEditorProvider,
	]
})
export class ModelModule { }
