import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';

import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';

@Injectable()
export class TextFilterService {

	constructor(
		//private locale: ILocale,
		private esQueryService: EsQueryService
		
	) {}

	getFilterRule = (text: string): IFilterRule => {
		return {
			type: FilterRuleType.esQueryRule,
			esQueryObject: {
				keyword: this.esQueryService.processQuery(text)
			}//,
			//displayName: this.locale.getString('preview.esQuery');
		};
	}
}

app.service('textFilterService', downgradeInjectable(TextFilterService));