import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { MetricConverter } from '@cxstudio/metrics/metric-converter.service';

import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { AbstractDependencyTypesProvider } from '@app/modules/asset-management/services/abstract-dependency-types-provider';
import { ModelDependenciesApiService } from './model-dependencies-api-service';


@Injectable()
export class ModelDependencyTypesProvider extends AbstractDependencyTypesProvider<ProjectAsset> {

	dependencyTypes: Array<AssetObjectType<ProjectAsset, AssetDependency>>;

	constructor(
		locale: CxLocaleService,
		@Inject('metricConverter') metricConverter: MetricConverter,
		private modelDependenciesApiService: ModelDependenciesApiService
	) {
		super(locale, metricConverter);

		this.dependencyTypes = [
			this.withHandler(ObjectType.DASHBOARD, this.modelDependenciesApiService.getDashboards),
			this.withHandler(ObjectType.FILTER, this.modelDependenciesApiService.getFilters),
			this.withMetricsHandler(this.modelDependenciesApiService.getMetrics)
		];

		this.title = locale.getString('common.model');
		this.exportHandler = this.modelDependenciesApiService;
	}
}

app.service('modelDependencyTypesProvider', downgradeInjectable(ModelDependencyTypesProvider));
