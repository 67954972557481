import { NgModule } from '@angular/core';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { LayoutModule } from '@app/modules/layout/layout.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { PushButtonsModule } from '@clarabridge/unified-angular-components';
import { ReportCalculationPropertiesComponent } from './report-calculation-properties/report-calculation-properties.component';
import { ReportCalculationSelectorComponent } from './report-calculation-selector/report-calculation-selector.component';
import { DirectionColorComponent } from './direction-color/direction-color.component';

@NgModule({
	imports: [
		I18nModule,
		SharedModule,
		PushButtonsModule,
		LayoutModule,
		AssetManagementModule,
	],
	declarations: [
		ReportCalculationSelectorComponent,
		ReportCalculationPropertiesComponent,
		DirectionColorComponent,
	],
	exports: [
		ReportCalculationSelectorComponent,
		ReportCalculationPropertiesComponent,
	]
})
export class ReportCalculationModule { }
