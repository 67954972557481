import * as _ from 'underscore';
import {Component, ChangeDetectionStrategy, OnInit, Input, Output, Inject, EventEmitter} from '@angular/core';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { WorldAwarenessService } from '@cxstudio/reports/document-explorer/world-awareness-attributes.service';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { PreviewTableColumn } from '@cxstudio/reports/preview/preview-table-column';
import {downgradeComponent} from '@angular/upgrade/static';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

@Component({
	selector: 'doc-explorer-selected-items',
	templateUrl: './doc-explorer-selected-items.component.html'
})
export class DocExplorerSelectedItemsComponent implements OnInit {

	worldAwarenessList: any[];
	groups: any[];
	isIntelligentScoringEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	isAutomatedSummariesEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_SUMMARIES);
	isComplianceManagementEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.COMPLIANCE_MANAGEMENT);
	@Input() items: TableColumn<PreviewSentence>[];
	@Input() isFavorited: (item, type) => boolean;
	@Input() sections: { [panelName: string]: ExplorerSectionGroup };
	@Input() contextPaneEnabled: boolean;
	@Input() narrativeEnabled: boolean;
	@Output() remove = new EventEmitter<{item: PreviewTableColumn}>();
	@Output() config = new EventEmitter<{item: PreviewTableColumn}>();
	@Output() toggleFavorite = new EventEmitter<{item: PreviewTableColumn,  type: FavoriteType}>();
	@Output() toggleSectionVisibility = new EventEmitter<{item: PreviewTableColumn}>();

	constructor(
		@Inject('worldAwarenessService') private worldAwarenessService: WorldAwarenessService,
		private engagorService: EngagorService,
		private betaFeaturesService: BetaFeaturesService
	) {}

	ngOnInit(): void {
		this.worldAwarenessList = this.worldAwarenessService.getWorldAwarenessAttributes();
		this.sections = this.sections || {};
		let sections = [FavoriteType.SYSTEM];

		if (this.narrativeEnabled) {
			sections.push(FavoriteType.NARRATIVE);
		}

		if (this.engagorService.isIntegrationEnabled()) {
			sections.push(FavoriteType.ENGAGE);
		}

		if (this.isIntelligentScoringEnabled) {
			sections.push(FavoriteType.SCORECARDS);
		}

		if (this.isAutomatedSummariesEnabled) {
			sections.push(FavoriteType.AUTOMATED_SUMMARIES);
		}

		if (this.isComplianceManagementEnabled) {
			sections.push(FavoriteType.COMPLIANCE_MANAGEMENT);
		}

		sections.pushAll([FavoriteType.ATTRIBUTE, FavoriteType.WORLD_AWARENESS, FavoriteType.MODEL]);
		//to get correct order
		this.groups = _.map(sections, (key) => {
			return this.sections[key] || new ExplorerSectionGroup(key, true);
		});
	}

	isDisabled = (item: TableColumn<PreviewSentence>): boolean => {
		return item.disabled
			|| (!this.contextPaneEnabled && !this.isModel(item) && item.name !== PreviewColumn.SENTENCE);
	}

	isRemovable = (item: TableColumn<PreviewSentence>): boolean => {
		return item.name !== PreviewColumn.SENTENCE;
	}

	//only text context (name: sentence) has sentiment highlighten option,
	hasOptions = (item: TableColumn<PreviewSentence>): boolean => {
		return item.name === PreviewColumn.SENTENCE;
	}

	hasFavorite = (item: TableColumn<PreviewSentence>, group: string): boolean => {
		return this.contextPaneEnabled && !this.isDisabled(item) && group !== FavoriteType.SYSTEM && group !== FavoriteType.SCORECARDS;
	}

	groupBySection = (group: ExplorerSectionGroup): TableColumn<PreviewSentence>[] => {
		let tempItems: TableColumn<PreviewSentence>[] = [];
		this.items.forEach((item) => {
			if (group.key === this.groupBy(item)) {
				tempItems.push(item);
			}
		});
		return tempItems;
	}

	groupBy = (item): string => {
		return this.getType(item);
	}

	getType = (item): FavoriteType => {
		if (item.type === ReportAssetType.SYS) {
			return FavoriteType.SYSTEM;
		} else if (this.isModel(item)) {
			return FavoriteType.MODEL;
		} else if (this.isWorldAwareness(item)) {
			return FavoriteType.WORLD_AWARENESS;
		} else if (this.isScorecards(item)) {
			return FavoriteType.SCORECARDS;
		} else {
			return FavoriteType.ATTRIBUTE;
		}
	}

	toggleFavorited = (item): void => {
		this.toggleFavorite.emit({ item, type: this.getType(item)});
	}

	toggleSectionVisibleButton = (item): void => {
		this.toggleSectionVisibility.emit({ item });
	}

	isItemFavorited = (item): boolean => {
		return this.isFavorited(item, this.getType(item));
	}

	private isModel = (item): boolean => item.model || item.type === ReportAssetType.TOPICS;

	private isWorldAwareness = (item): boolean => !!_.findWhere(this.worldAwarenessList, { name: item.name });

	private isScorecards = (item): boolean => item.type === ReportAssetType.SCORECARDS;
}

app.directive('docExplorerSelectedItems', downgradeComponent({component: DocExplorerSelectedItemsComponent}) as angular.IDirectiveFactory);

