import { Schema, NodeSpec } from 'prosemirror-model';
import { tableNodes } from 'prosemirror-tables';
import { addListNodes } from 'prosemirror-schema-list';
import OrderedMap from 'orderedmap';

const pDOM = ['p', 0];
const blockquoteDOM = ['blockquote', 0];
const hrDOM = ['hr'];
const preDOM = ['pre', ['code', 0]];
const brDOM = ['br'];

// :: Object
// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes: any = {
	// :: NodeSpec The top level document node.
	doc: {
		content: '(block | quote)+'
	},

	// :: NodeSpec A plain paragraph textblock. Represented in the DOM
	// as a `<p>` element.
	paragraph: {
		content: 'inline*',
		group: 'block',
		attrs: { align: { default: null } },
		parseDOM: [{
			tag: 'p', getAttrs(dom): any {
				let align = getAlignment(dom);
				return { align };
			}
		}, {
			tag: 'div', getAttrs(dom): any {
				let align = getAlignment(dom);
				return { align };
			}
		}],
		toDOM(node): any {
			return node.attrs.align
				? ['p', { style: 'text-align: ' + node.attrs.align }, 0]
				: pDOM;
		}
	},

	// :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
	blockquote: {
		content: 'block+',
		group: 'quote',
		defining: true,
		parseDOM: [{ tag: 'blockquote' }],
		toDOM(): any { return blockquoteDOM; }
	},

	// :: NodeSpec A horizontal rule (`<hr>`).
	horizontal_rule: {
		group: 'block',
		parseDOM: [{ tag: 'hr' }],
		toDOM(): any { return hrDOM; }
	},

	// :: NodeSpec A heading textblock, with a `level` attribute that
	// should hold the number 1 to 6. Parsed and serialized as `<h1>` to
	// `<h6>` elements.
	heading: {
		attrs: {
			level: { default: 1 },
			align: { default: null }
		},
		content: 'inline*',
		group: 'block',
		defining: true,
		parseDOM: [{ tag: 'h1', getAttrs: getHeadingAttrs },
		{ tag: 'h2', getAttrs: getHeadingAttrs },
		{ tag: 'h3', getAttrs: getHeadingAttrs },
		{ tag: 'h4', getAttrs: getHeadingAttrs },
		{ tag: 'h5', getAttrs: getHeadingAttrs },
		{ tag: 'h6', getAttrs: getHeadingAttrs }],
		toDOM(node): any {
			return node.attrs.align
				? ['h' + node.attrs.level, { style: 'text-align: ' + node.attrs.align }, 0]
				: ['h' + node.attrs.level, 0];
		}
	},

	// :: NodeSpec A code listing. Disallows marks or non-text inline
	// nodes by default. Represented as a `<pre>` element with a
	// `<code>` element inside of it.
	code_block: {
		content: 'text*',
		marks: '',
		group: 'block',
		code: true,
		defining: true,
		parseDOM: [{
			tag: 'pre', preserveWhitespace: 'full'
		}],
		toDOM(): any {
			return preDOM;
		}
	},

	// :: NodeSpec The text node.
	text: {
		group: 'inline'
	},

	// :: NodeSpec An inline image (`<img>`) node. Supports `src`,
	// `alt`, and `href`, and `width` attributes. The latter three default to the empty
	// string.
	image: {
		inline: true,
		attrs: {
			src: {},
			alt: { default: '' },
			title: { default: null },
			width: { default: 360 },
			height: { default: null }
		},
		group: 'inline',
		draggable: true,
		parseDOM: [{
			tag: 'img[src]',
			getAttrs(dom): any {
				let attrs: { [key: string]: any } = {
					src: dom.getAttribute('src'),
					title: dom.getAttribute('title'),
					alt: dom.getAttribute('alt') || ''
				};

				let styleWidth = getPixelValue(dom.style.width);
				let styleHeight = getPixelValue(dom.style.height);
				let imgWidth = dom.naturalWidth;
				let imgHeight = dom.naturalHeight;

				attrs.width = styleWidth || dom.width;
				attrs.height = styleHeight || dom.height;
				if (attrs.width === imgWidth) {
					attrs.width = attrs.height * imgWidth / imgHeight;
				} else if (attrs.height === imgHeight) {
					attrs.height = attrs.width * imgHeight / imgWidth;
				}
				return attrs;
			}
		}],
		toDOM(node): any {
			let { src, alt, title } = node.attrs;
			let style = `width: ${node.attrs.width}px; height: ${node.attrs.height}px; margin: 16px;`;
			return ['img', { src, alt, title, style }];
		}
	},

	// :: NodeSpec A hard line break, represented in the DOM as `<br>`.
	hard_break: {
		inline: true,
		group: 'inline',
		selectable: false,
		parseDOM: [{ tag: 'br' }],
		toDOM(): any { return brDOM; }
	}
};

const emDOM = ['em', 0];
const strongDOM = ['strong', 0];
const uDOM = ['u', 0];
const delDOM = ['del', 0];
const fontSizes = [
	'font-xxx-small-scale',
	'font-xx-small-scale',
	'font-x-small-scale', // default size
	'font-small-scale',
	'font-medium-scale',
	'font-large-scale',
	'font-x-large-scale'
];
const altFontSizes = ['xx-small', 'x-small', 'small', 'medium', 'large', 'x-large', 'xx-large'];
const fonts = [
	'Arial, Helvetica, sans-serif',
	'times new roman, serif',
	'arial black, sans-serif',
	'arial narrow, sans-serif',
	'courier new, monospace',
	'garamond, serif',
	'georgia, serif',
	'tahoma, sans-serif',
	'trebuchet ms, sans-serif',
	// 'Helvetica Neue, Helvetica, Arial, sans-serif', // default font
	'verdana, sans-serif',
	'proxima_nova_rgregular'
];

// :: Object [Specs](#model.MarkSpec) for the marks in the schema.
export const marks: any = {
	// :: MarkSpec A link. Has `href` and `title` attributes. `title`
	// defaults to the empty string. Rendered and parsed as an `<a>`
	// element.
	link: {
		attrs: {
			href: {},
			title: { default: null }
		},
		inclusive: false,
		parseDOM: [{
			tag: 'a[href]', getAttrs(dom): any {
				return { href: dom.getAttribute('href'), title: dom.getAttribute('title') };
			}
		}],
		toDOM(node): any { let { href, title } = node.attrs; return ['a', { href, title, target: '_blank' }, 0]; }
	},

	// :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
	// Has parse rules that also match `<i>` and `font-style: italic`.
	em: {
		parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
		toDOM(): any { return emDOM; }
	},

	fontColor: {
		attrs: {
			color: {}
		},
		parseDOM: [{
			style: 'color', getAttrs(value): any {
				let color = colorToHex(value);
				return !!color && { color };
			}
		}, {
			tag: 'font[color]', getAttrs(dom): any {
				let color = colorToHex(dom.getAttribute('color'));
				return !!color && { color };
			}
		}],
		toDOM(node): any {
			let style = 'color: ' + node.attrs.color;
			return ['span', { style }, 0];
		}
	},

	fontSize: {
		attrs: {
			size: {}
		},
		parseDOM: [{
			tag: '.' + fontSizes.filter(fs => fs !== 'font-x-small-scale').join(', .'), getAttrs(dom): any {
				let index = fontSizes.findIndex(fs => dom.classList.contains(fs));
				return { size: index + 1 };
			}
		}, {
			tag: 'font[size]', getAttrs(dom): any {
				let size = dom.getAttribute('size');
				return ('' + size) !== '3' && { size };
			}
		}, {
			style: 'font-size', getAttrs(value): any {
				let size = altFontSizes.indexOf(value);
				return size >= 0 && ('' + size) !== '3' && { size };
			}
		}],
		toDOM(node): any {
			return ['span', { class: fontSizes[node.attrs.size - 1] }, 0];
		}
	},

	highlightColor: {
		attrs: {
			color: {}
		},
		parseDOM: [{
			style: 'background-color', getAttrs(value): any {
				let color = colorToHex(value);
				return !!color && { color };
			}
		}],
		toDOM(node): any {
			let style = 'background-color: ' + node.attrs.color;
			return ['span', { style }, 0];
		}
	},

	// :: MarkSpec An underline mark. Rendered as `<u>` element.
	// Has parse rules that also match `text-decoration: underline`.
	u: {
		parseDOM: [{ tag: 'u' }, { style: 'text-decoration=underline' }],
		toDOM(): any { return uDOM; }
	},

	// :: MarkSpec A strike mark. Rendered as `<u>` element.
	// Has parse rules that also match `text-decoration: line-through`.
	del: {
		parseDOM: [{ tag: 'del' }, { tag: 'strike' }, { style: 'text-decoration=line-through' }],
		toDOM(): any { return delDOM; }
	},

	// :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
	// also match `<b>` and `font-weight: bold`.
	strong: {
		parseDOM: [{ tag: 'strong' },
		// This works around a Google Docs misbehavior where
		// pasted content will be inexplicably wrapped in `<b>`
		// tags with a font-weight normal.
		{ tag: 'b', getAttrs: node => node.style.fontWeight !== 'normal' && null },
		{ style: 'font-weight', getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }],
		toDOM(): any { return strongDOM; }
	},

	fontFamily: {
		attrs: {
			font: {}
		},
		parseDOM: [{
			style: 'font-family', getAttrs(value): any {
				let font = value && value.replace(/\'|\"/g, '');
				return fonts.contains(font) && { font };
			}
		}, {
			tag: 'font[face]', getAttrs(dom): any {
				let font = dom.getAttribute('face').replace(/\'|\"/g, '');
				return fonts.contains(font) && { font };
			}
		}],
		toDOM(node): any {
			return ['span', { style: 'font-family: ' + node.attrs.font }, 0];
		}

	}
};

// helpers for parsing

function getHeadingAttrs(dom): any {
	let level = parseInt(dom.tagName.slice(1), 10);
	let align = getAlignment(dom);
	return { level, align };
}

function getAlignment(dom): string {
	let align = dom.style.textAlign;
	return align && align !== 'left'
		? align
		: null;
}

function colorToHex(colorStr: string): string {
	if (!colorStr) { return ''; }
	let color = colorStr.toLowerCase();
	if (/^#(?:[0-9a-f]{3}){1,2}$/.test(color)) {
		return color;
	}
	return rgbToHex(color) || colorNameToHex(color);
}

function rgbToHex(color: string): string {
	let match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i);
	if (match) {
		let rgb = match.slice(-3).map(Number);
		return '#' + rgb.map(numberToHex).join('').toLowerCase();
	}
	return '';
}

function numberToHex(n: number): string {
	return `0${n.toString(16)}`.slice(-2);
}
// tslint:disable: max-line-length
function colorNameToHex(name: string): string {
	const colors = {
		aliceblue: '#f0f8ff', antiquewhite: '#faebd7', aqua: '#00ffff', aquamarine: '#7fffd4', azure: '#f0ffff', beige: '#f5f5dc', bisque: '#ffe4c4', black: '#000000',
		blanchedalmond: '#ffebcd', blue: '#0000ff', blueviolet: '#8a2be2', brown: '#a52a2a', burlywood: '#deb887', cadetblue: '#5f9ea0', chartreuse: '#7fff00',
		chocolate: '#d2691e', coral: '#ff7f50', cornflowerblue: '#6495ed', cornsilk: '#fff8dc', crimson: '#dc143c', cyan: '#00ffff', darkblue: '#00008b',
		darkcyan: '#008b8b', darkgoldenrod: '#b8860b', darkgray: '#a9a9a9', darkgreen: '#006400', darkkhaki: '#bdb76b', darkmagenta: '#8b008b', darkolivegreen: '#556b2f',
		darkorange: '#ff8c00', darkorchid: '#9932cc', darkred: '#8b0000', darksalmon: '#e9967a', darkseagreen: '#8fbc8f', darkslateblue: '#483d8b', darkslategray: '#2f4f4f',
		darkturquoise: '#00ced1', darkviolet: '#9400d3', deeppink: '#ff1493', deepskyblue: '#00bfff', dimgray: '#696969', dodgerblue: '#1e90ff', firebrick: '#b22222',
		floralwhite: '#fffaf0', forestgreen: '#228b22', fuchsia: '#ff00ff', gainsboro: '#dcdcdc', ghostwhite: '#f8f8ff', gold: '#ffd700', goldenrod: '#daa520',
		gray: '#808080', green: '#008000', greenyellow: '#adff2f', honeydew: '#f0fff0', hotpink: '#ff69b4', 'indianred ': '#cd5c5c', indigo: '#4b0082', ivory: '#fffff0',
		khaki: '#f0e68c', lavender: '#e6e6fa', lavenderblush: '#fff0f5', lawngreen: '#7cfc00', lemonchiffon: '#fffacd', lightblue: '#add8e6', lightcoral: '#f08080',
		lightcyan: '#e0ffff', lightgoldenrodyellow: '#fafad2', lightgrey: '#d3d3d3', lightgreen: '#90ee90', lightpink: '#ffb6c1', lightsalmon: '#ffa07a',
		lightseagreen: '#20b2aa', lightskyblue: '#87cefa', lightslategray: '#778899', lightsteelblue: '#b0c4de', lightyellow: '#ffffe0', lime: '#00ff00', limegreen: '#32cd32',
		linen: '#faf0e6', magenta: '#ff00ff', maroon: '#800000', mediumaquamarine: '#66cdaa', mediumblue: '#0000cd', mediumorchid: '#ba55d3', mediumpurple: '#9370d8',
		mediumseagreen: '#3cb371', mediumslateblue: '#7b68ee', mediumspringgreen: '#00fa9a', mediumturquoise: '#48d1cc', mediumvioletred: '#c71585', midnightblue: '#191970',
		mintcream: '#f5fffa', mistyrose: '#ffe4e1', moccasin: '#ffe4b5', navajowhite: '#ffdead', navy: '#000080', oldlace: '#fdf5e6', olive: '#808000', olivedrab: '#6b8e23',
		orange: '#ffa500', orangered: '#ff4500', orchid: '#da70d6', palegoldenrod: '#eee8aa', palegreen: '#98fb98', paleturquoise: '#afeeee', palevioletred: '#d87093',
		papayawhip: '#ffefd5', peachpuff: '#ffdab9', peru: '#cd853f', pink: '#ffc0cb', plum: '#dda0dd', powderblue: '#b0e0e6', purple: '#800080', rebeccapurple: '#663399',
		red: '#ff0000', rosybrown: '#bc8f8f', royalblue: '#4169e1', saddlebrown: '#8b4513', salmon: '#fa8072', sandybrown: '#f4a460', seagreen: '#2e8b57', seashell: '#fff5ee',
		sienna: '#a0522d', silver: '#c0c0c0', skyblue: '#87ceeb', slateblue: '#6a5acd', slategray: '#708090', snow: '#fffafa', springgreen: '#00ff7f', steelblue: '#4682b4',
		tan: '#d2b48c', teal: '#008080', thistle: '#d8bfd8', tomato: '#ff6347', turquoise: '#40e0d0', violet: '#ee82ee', wheat: '#f5deb3', white: '#ffffff',
		whitesmoke: '#f5f5f5', yellow: '#ffff00', yellowgreen: '#9acd32'
	};
	return colors[name] || '';
}
// tslint:enable: max-line-length

function getPixelValue(pxStr: string): number | null {
	let pxValue = pxStr.match(/(.+)px/)?.[1];
	return pxValue && parseFloat(pxValue);
}

// list and table node specs

let tables: any = tableNodes({
	tableGroup: 'block', cellContent: 'block+', cellAttributes: {
		columnWidth: {
			default: null,
			getFromDOM(dom: any): number {
				return dom.dataset.colwidth || null;
			},
			setDOMAttr(value, attrs): void {
				if (value) {
					attrs.style = (attrs.style || '') + `width: ${value}px;`;
				}
			}
		}
	}
});

let baseNodes: any = new Schema({ nodes, marks }).spec.nodes;

export const schema = new Schema({
	nodes: (addListNodes(baseNodes, 'paragraph block*', 'block') as OrderedMap<NodeSpec>).append(tables),
	marks
});
