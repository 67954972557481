import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackgroundColorComponent } from '@app/modules/widget-settings/common-settings/background-color.component';
import { SharedModule } from '@app/shared/shared.module';
import { ReportPropertySwitcherComponent } from './report-property-switcher/report-property-switcher.component';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
	],
	declarations: [
		BackgroundColorComponent,
		ReportPropertySwitcherComponent
	],
	exports: [
		BackgroundColorComponent,
		ReportPropertySwitcherComponent
	],
})
export class CommonSettingsModule { }
