import { AlertThresholdTypes } from './alert-threshold-types';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { AlertTriggerCriteria } from '@app/modules/alert/alert-trigger-criteria';
import { SchedulePeriod } from '@app/modules/dashboard/schedule/schedule-period';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { IDateRange } from '@cxstudio/reports/entities/date-period';

export class MetricAlertCondition {
	calculation?: ReportCalculation;
	criteria?: AlertTriggerCriteria;
	threshold?: number;
	thresholdType?: AlertThresholdTypes;
	comparisonPeriod?: string;
	comparisonPeriodRange?: IDateRange;
}

export class AlertTrigger {
	grouping?: ReportGrouping;
	sortBy?: ReportCalculation;
	period?: string;
	periodCustomized?: boolean;
	minVolume?: number;
	filterRules?: any[];
	periodRange?: any;
	scheduleType?: SchedulePeriod;
	conditions?: MetricAlertCondition[];
}
