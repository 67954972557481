<submenu data-testid="move-submenu" label="{{'dashboard.move'|i18n}}">
	<a (click)="moveToTop()"
		class="dropdown-item hide-on-home-page border-t-radius-4"
		href="javascript:void(0)"
		role="menuitem"
		[i18n]="'dashboard.pushToTop'">
	</a>

	<a (click)="moveToBottom()"
		class="dropdown-item hide-on-home-page border-b-radius-4"
		href="javascript:void(0)"
		role="menuitem"
		[i18n]="'dashboard.pushToBottom'">
	</a>
</submenu>
