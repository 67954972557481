<div [ngBusy]="loading">
	<div class="row">
		<div class="col-sm-12 form-group">
			<label for="grouping" [i18n]="'packages.dataOverviewSource'"></label>
			<div class="col-md-12 pl-0">
				<select-from-tree
					name="grouping"
					class="attribute-selector col-sm-4 pl-0"
					[hierarchyList]="groupingOptions"
					(onNodeClick)="selectGrouping($event.node)">
				</select-from-tree>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 form-group">
			<label for="calculation" [i18n]="'packages.dataOverviewTrend'"></label>
			<div class="col-md-12 pl-0">
				<select-from-tree
					name="calculation"
					class="attribute-selector col-sm-4 pl-0"
					[disabledItems]="disabledCalculations"
					[hierarchyList]="calculationOptions"
					(onNodeClick)="selectCalculation($event.node)">
				</select-from-tree>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 form-group">
			<label for="calculation" [i18n]="'packages.dataOverviewMetrics'"></label>
			<div class="col-md-12 pl-0">
				<additional-metrics-builder
					[maxLimit]="10"
					[metrics]="additionalMetrics"
					[hierarchyList]="calculationOptions"
					[fixedMetrics]="fixedAdditionalMetrics"
					(metricChange)="additionalMetricChange()"
				>
				</additional-metrics-builder>
			</div>
		</div>
	</div>
</div>