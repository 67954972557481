import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface INameRendererParams extends ICellRendererParams {
	nameField: string;
	getNameCellIcon: (data: AgGridNestable) => string;
}

@Component({
	selector: 'name-renderer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div data-testid="name-cell" attr.data-objid="{{obj.id || null}}" attr.data-test-objtype="{{obj.type || null}}" [style.paddingLeft.em]="padLeft">
	<span [innerHTML]="getIcon()" aria-hidden="true"></span><span>{{obj[nameField]}}</span>
</div>`,
})
export class NameRendererComponent implements ICellRendererAngularComp {
	obj: AgGridNestable & {type?: string};
	padLeft: number;
	expanded: boolean;
	group: boolean;
	nameField: string;
	getNameCellIcon: (data: AgGridNestable) => string;

	refresh(params: INameRendererParams): boolean {
		this.agInit(params);
		this.padLeft = (params.node.level - 1) * 16;
		return true;
	}

	agInit(params: INameRendererParams): void {
		this.obj = params.data;
		this.group = params.node.group;
		this.expanded = params.node.expanded;
		this.nameField = params.nameField || 'name';
		this.getNameCellIcon = params.getNameCellIcon;
	}

	getIcon(): string {
		if (!this.obj) return '';

		let iconClass = this.getNameCellIcon?.(this.obj);
		return !iconClass ? '' :
			`<span class="q-icon ${iconClass} mr-8"></span>`;
	}
}
