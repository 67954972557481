import { Inject, Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { PieVisualProperties } from '@cxstudio/reports/entities/pie-visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { CommonDrillService } from '../common-drill.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';

@Injectable({
	providedIn: 'root'
})
export class DrillToPieProcessorService extends DefaultDrillProcessor {
	constants = this.metricConstants.get();

	constructor(
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		private readonly commonDrill: CommonDrillService,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils
	) {
		super();
		this.addInheritedVisualProperties(['attributeSelections', 'sideBySide', 'primaryGroup']
			.concat(CommonInherentProperties.highchartsVisual));
	}

	drillTo(widget: Widget, drillToType, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget {
		widget.name = WidgetType.PIE;
		widget.properties.widgetType = WidgetType.PIE;
		widget.visualProperties.subChartType = undefined;
		widget.visualProperties.visualization = WidgetVisualization.STACKEDPIE;
		widget.displayName += ' - ' + point.groupBy.displayName;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy));
		widget.properties.selectedAttributes = [point.groupBy];
		widget.visualProperties.primaryGroup = point.groupBy.name;

		this.commonDrill.processSelectedMetric(point, widget, _.noop);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);
		this.commonDrill.processHierarchyMetrics(widget);
		delete widget.properties.dateRangeP2;
		this.intializeDefaultProperties(widget, point, defaultColor);

		widget = this.inheritPropertiesAndVisProps(widget);

		return widget;
	}

	private intializeDefaultProperties(widget: Widget, point: DrillPoint, defaultColor: IColorSelectorPalette): void {
		let calculationAttribute = this.commonDrill.getCalculationAttribute(widget, point.seriesType);
		if (this.constants.PARENT_PERCENT.name !== calculationAttribute.name
			&& this.constants.PERCENT_OF_TOTAL.name !== calculationAttribute.name) {
			calculationAttribute = this.constants.VOLUME;
		}

		widget.properties.selectedMetrics = [calculationAttribute];
		widget.visualProperties.attributeSelections = {};
		widget.visualProperties.attributeSelections.size = calculationAttribute;
		widget.visualProperties.sortBy = calculationAttribute.name;

		widget.visualProperties.showLegend = true;

		if (widget.visualProperties.color) {
			this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.PRIMARY, defaultColor);
		} else {
			widget.visualProperties.color = this.colorUtils.getDefaultColor(defaultColor);
			widget.visualProperties.customColor = this.colorUtils.getDefaultCustomColor(defaultColor);
		}

		(widget.visualProperties as PieVisualProperties).sideBySide = false;
	}
}
