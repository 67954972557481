<div class="row mb-16 d-flex align-items-center">
	<div *ngIf="showSecondary" class="col-sm-4 btn-group">
		<button *ngFor="let option of axisOptions"
			class="btn br-axis-btn col-xs-12 col-md-6 br-text-nowrap"
			[ngClass]="{'btn-selected': plotLine.axis === option.name}"
			(click)="plotLine.axis = option.name"
			[disabled]="(!isCreationRow() && !isBeingEdited())"
			title="{{option.displayName}}">
			{{option.displayName}}
		</button>
	</div>

	<div class="col-sm-4 row">
		<div class="col-sm-7">
			<input class="form-control _t-plotline-label"
				type="text"
				[(ngModel)]="plotLine.label"
				placeholder="{{'common.name'|i18n}}"
				[disabled]="(!isCreationRow() && !isBeingEdited())">
		</div>
		<div class="col-sm-5">
			<input class="form-control _t-plotline-value"
				type="number"
				[(ngModel)]="plotLine.value"
				placeholder="{{'common.value'|i18n}}"
				[disabled]="(!isCreationRow() && !isBeingEdited())">
		</div>
	</div>

	<div class="col-sm-2">
		<plot-line-dropdown
			[plotLine]="plotLine"
			[isDisabled]="!isCreationRow() && !isBeingEdited()"
			dropdown-direction="left">
		</plot-line-dropdown>
	</div>

	<button *ngIf="showAddButton()"
		class="btn btn-primary add-plotline"
		(click)="addPlotLine()"
		[disabled]="disableAddSaveButton()">{{'common.add'|i18n}}</button>
	<div class="has-error pull-right">
		<span *ngIf="showDuplicateErrorMessage()" class="help-block">{{'widget.duplicateReferenceLines'|i18n}}</span>
	</div>

	<button *ngIf="showUpdateButton()"
		class="btn btn-primary btn-icon update-plotline"
		(click)="updatePlotLine()"
		[disabled]="disableAddSaveButton()"
		title="{{'common.apply'|i18n}}">
		<span class="q-icon q-icon-check" aria-hidden="true"></span>
	</button>
	<button *ngIf="showEditButton()"
		class="btn btn-primary btn-icon edit-plotline"
		(click)="editPlotLine()"
		title="{{'common.edit'|i18n}}">
		<span class="q-icon q-icon-edit" aria-hidden="true"></span>
	</button>
	<button *ngIf="showRemoveButton()"
		class="btn btn-secondary btn-icon delete-plotline"
		(click)="removePlotLine()"
		title="{{'common.delete'|i18n}}">
		<span class="q-icon q-icon-trash" aria-hidden="true"></span>
	</button>
	<button *ngIf="showClearButton()"
		class="btn btn-secondary btn-icon revert-plotline"
		(click)="clearChanges()"
		title="{{'common.cancel'|i18n}}">×</button>

	<simple-number-format-preview *ngIf="showFormatPreview()"
		class="ml-16 d-flex-inline"
		[format]="getReferenceLineFormat()"
		[previewValue]="plotLine.value">
	</simple-number-format-preview>
</div>
