import * as moment from 'moment';
import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { RunnablePackage } from '../runnable-package.interface';
import { DataOverviewWidgetBuilderService } from './data-overview-widget-builder.service';
import { DataOverviewWizardOutput, DataOverviewWizardWizardComponent } from './data-overview-wizard/data-overview-wizard.component';

@Injectable()
export class DataOverviewPackage implements RunnablePackage {
	name: string;
	description: string;

	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private dataOverviewWidgetBuilderService: DataOverviewWidgetBuilderService,
		@Inject('dashboardService') private dashboardService: DashboardService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
	) {
		this.name = this.locale.getString('packages.dataOverview');
		this.description = this.locale.getString('packages.dataOverviewDescription');
	}

	runPackage(): Promise<void> {
		return this.cxDialogService.openDialog(DataOverviewWizardWizardComponent, undefined, {size: ModalSize.MEDIUM}).result
			.then((wizardData: DataOverviewWizardOutput) => {
				return this.createDashboard(wizardData);
			})
			.catch(_.noop);
	}

	private createDashboard(wizardData: DataOverviewWizardOutput): Promise<void> {
		const dashboardName = this.locale.getString('packages.dataOverviewDashboardName');
		const uniqueDashboardName = this.dashboardService.getUniqueDashboardName(dashboardName);

		let dashboard = {
			name: uniqueDashboardName,
			properties: {
				cbContentProvider: wizardData.projectSelection.contentProviderId,
				cbAccount: wizardData.projectSelection.accountId,
				project: wizardData.projectSelection.projectId,
				isAttributeFiltersApplied: true,
				optimization: true
			},
			appliedFiltersArray: this.buildDashboardFilters()
		} as Dashboard;

		const dashboardWidgets: Widget[] = this.dataOverviewWidgetBuilderService.getWidgetsFromWizardSettings(wizardData);

		return PromiseUtils.wrap(
			this.dashboardService.createPredefinedDashboard(dashboardWidgets, uniqueDashboardName, dashboard)).then(() => {
				let notificationMessage = this.locale.getString('packages.notificationDashboardCreated', { name: dashboard.name });
				this.globalNotificationService.addSuccessNotification(notificationMessage);
			});
	}

	private buildDashboardFilters(): DashboardFilter[] {
		let dateFilterSelection = this.getDateFilterSelection();
		let filterRule = this.dashboardFiltersService.buildDashboardFilterRule({
			selectedAttribute: dateFilterSelection
		});

		let dateRangeFilter: DashboardFilter = {
			selectedAttribute: dateFilterSelection,
			genericFilterFormat: filterRule,
			selectedAttributeValue: {
				dateFilterMode: DateFilterMode.CUSTOM,
				dateFilterRange: {
					from: moment().subtract(2, 'year').startOf('day').format(),
					to: moment().endOf('day').format()
				} as IDateRange
			}
		};

		return [dateRangeFilter];
	}

	private getDateFilterSelection(): DashboardFilterSelection {
		return {
			displayName: this.locale.getString('filter.dateRange'),
			name: 'dateFilter',
			filterType: DashboardFilterTypes.DATE_RANGE,
			matchMode: FilterMatchModeValue.IS
		};
	}
}
