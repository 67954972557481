/*

	This is an angular native service, but with one method (_addStylesToHost) modified to support nonce in component styles
	Angular 16 supports nonce natively, but we have a long way to go to get there.

	References / source:
		https://dev.to/ferdiesletering/how-to-implement-an-inline-styles-content-security-policy-with-angular-and-nginx-2ke2
		https://github.com/ferdiesletering/angular-csp-nonce


*/

import { DOCUMENT, ɵgetDOM as getDOM } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ɵSharedStylesHost } from '@angular/platform-browser';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class CustomDomSharedStylesHost extends ɵSharedStylesHost implements OnDestroy {
	// Maps all registered host nodes to a list of style nodes that have been added to the host node.
	private _hostNodes = new Map<Node, Node[]>();
	private _nonce: string | null | undefined = null;

	constructor(
		@Inject(DOCUMENT) private _doc: any,
		@Inject('cspMetaSelector') private _metaCSPTag: string
	) {
		super();
		this._hostNodes.set(_doc.head, []);
		this._setCSPNonce();
	}

	private _addStylesToHost(
		styles: Set<string>,
		host: Node,
		styleNodes: Node[]
	): void {
		styles.forEach((style: string) => {
			const styleEl = this._doc.createElement('style');
			styleEl.textContent = style;

			if (!style.includes('without-nonce') && this._nonce) {
				styleEl.setAttribute('nonce', this._nonce);
			}

			styleNodes.push(host.appendChild(styleEl));
		});
	}

	private _setCSPNonce(): void {
		this._nonce = document.querySelector(this._metaCSPTag)?.getAttribute('content');
	}

	getCSPNonce(): string {
		return this._nonce || '';
	}

	getCSPNonceAttribute(): string {
		return `nonce=${this.getCSPNonce()}`;
	}

	addHost(hostNode: Node): void {
		const styleNodes: Node[] = [];
		this._hostNodes.set(hostNode, styleNodes);
	}

	removeHost(hostNode: Node): void {
		const styleNodes = this._hostNodes.get(hostNode);
		if (styleNodes) {
			styleNodes.forEach(removeStyle);
		}
		this._hostNodes.delete(hostNode);
	}

	onStylesAdded(additions: Set<string>): void {
		this._hostNodes.forEach((styleNodes, hostNode) => {
			this._addStylesToHost(additions, hostNode, styleNodes);
		});
	}

	ngOnDestroy(): void {
		this._hostNodes.forEach((styleNodes) => styleNodes.forEach(removeStyle));
	}
}

function removeStyle(styleNode: Node): void {
	getDOM().remove(styleNode);
}

app.service('customDomSharedStylesHost', downgradeInjectable(CustomDomSharedStylesHost));
