import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { UrlService } from '@cxstudio/common/url-service.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { BackgroundType, ContentWidgetProperties, MarginType } from '@cxstudio/reports/entities/content-widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { SanitizePipe } from '@app/shared/pipes/sanitize.pipe';
import { HTMLStringUtils } from '@app/util/html-string-utils.class';

@Injectable({
	providedIn: 'root'
})
export class TextWidgetUtilsService {

	readonly SIZES = ['xx-small', 'x-small', 'small', 'medium', 'large', 'x-large', 'xx-large'];

	constructor(
		@Inject('urlService') private readonly urlService: UrlService,
		private readonly textSanitizer: SanitizePipe,
	) {}

	processFontSize = (htmlElement: HTMLElement): void => { // the same in text
		let element = $(htmlElement);
		element.addClass(this.convertSizeToClass(3)); // set default size for elements without style
		element.find('font[size]').each((i, font: HTMLFontElement) => {
			let size = font.size;
			$(font).removeAttr('size');
			$(font).addClass(this.convertSizeToClass(size));
		});
		element.find('font,span,p').filter((i, elem: HTMLElement) => {
			return this.isTextSize(elem.style.fontSize);
		}).each((i, elem: HTMLElement) => {
			let size = elem.style.fontSize;
			$(elem).addClass(this.convertSizeToClass(size));
			$(elem).css({'font-size': ''});
		});
		element.css({'font-size': ''});

	}

	private isTextSize(size): boolean {
		return this.SIZES.indexOf(size) !== -1;
	}

	private convertSizeToClass(size: string | number): string {
		let sizeValue: number | string = Number(size);
		if (isNaN(sizeValue))
			sizeValue = size;

		// class names match with the display names of the fonts, which is why they do not match the name in case statement
		switch (sizeValue) {
		case 1: case 'xx-small': return 'font-xxx-small-scale';
		case 2: case 'x-small' : return 'font-xx-small-scale';
		case 3: case 'small' : return 'font-x-small-scale';
		case 4: case 'medium' : return 'font-small-scale';
		case 5: case 'large' : return 'font-medium-scale';
		case 6: case 'x-large' : return 'font-large-scale';
		case 7: case 'xx-large' : return 'font-x-large-scale';
		default : return 'font-medium-scale';
		}
	}

	private wrapFont(node: HTMLElement, attr: string, value: string): void {
		node.removeAttribute(attr);
		let font = document.createElement('font');
		font.setAttribute(attr, value);
		(font as any).replaceChildren(node.innerHTML);	// any should be unnecessary after move to TS 4.4
		(node as any).replaceChildren(font.outerHTML);	// any should be unnecessary after move to TS 4.4
	}

	private createDiv(text): HTMLElement {
		let div = document.createElement('div');
		(div as any).replaceChildren();	// any should be unnecessary after move to TS 4.4
		const nodes = HTMLStringUtils.stringToDOM(this.textSanitizer.transform(text));
		while (nodes.length) {
			// nodes disappear from HTMLCollection as they are moved to div
			div.appendChild(nodes[0]);
		}

		return div;
	}

	splitFontAttributes = (text: string): string => {
		let div = this.createDiv(text);
		div.querySelectorAll('font[color][face][size], font[color][face], font[color][size], font[face][size]')
			.forEach((node: HTMLElement) => {
				let color = node.getAttribute('color');
				let size = node.getAttribute('size');
				let face = node.getAttribute('face');
				if (color && size && face) {
					this.wrapFont(node, 'color', color);
					this.wrapFont(node, 'size', size);
					return;
				}
				if (color) {
					this.wrapFont(node, 'color', color);
				} else {
					this.wrapFont(node, 'size', size);
				}
			});
		return div.innerHTML;
	}

	getImageNames = (text: string, dashboardId: number): string[] => {
		let imageNames = [];
		let div = this.createDiv(text);
		div.querySelectorAll('img[src]')
			.forEach(node => {
				let src = node.getAttribute('src');
				if (src) {
					let imgName = this.urlService.getImageNameFromUrl(src, dashboardId);
					if (imgName) {
						imageNames.push(imgName);
					}
				}
			});
		return _.uniq(imageNames);
	}

	convertLabelToText = (widget: Widget): Widget => {
		widget.name = WidgetType.TEXT;
		widget.visualProperties.visualization = WidgetVisualization.TEXT;
		let properties = widget.properties as ContentWidgetProperties;
		if (properties.background === '#ffffff') {
			properties.background = '';
			properties.backgroundType = BackgroundType.DEFAULT;
		} else {
			properties.background = 'none';
			properties.backgroundType = BackgroundType.NONE;
		}
		properties.margins = MarginType.NARROW;

		return widget;
	}

	getBorderStyles(props: ContentWidgetProperties): any {
		let styles = {};
		styles['border-width'] = 0;
		if (props.borderBottom)
			styles['border-bottom-width'] = '1px';
		if (props.borderTop)
			styles['border-top-width'] = '1px';
		if (props.border)
			styles['border-width'] = '1px';
		return styles;
	}
}

app.service('textWidgetUtils', downgradeInjectable(TextWidgetUtilsService));
