import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { WidgetDataAccessService } from '@app/modules/widget-settings/widget-data-access.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { Security } from '@cxstudio/auth/security-service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import TitleUtils from '@cxstudio/reports/utils/title-utils';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';
import * as _ from 'underscore';
import { PromiseUtils } from '@app/util/promise-utils';


export default class WidgetService {

	constructor(
		private security: Security,
		private $q: ng.IQService,
		private cbDialogService,
		private locale: ILocale,
		private titleUtils: TitleUtils,
		private gridsterConfigurer: GridsterConfigurer,
		private calculationColorService,
		private currentWidgets: ICurrentWidgets,
		private metricConstants: MetricConstants,
		private textWidgetUtils: TextWidgetUtilsService,
		private widgetDataAccessService: WidgetDataAccessService,
		private widgetDescriptionService: WidgetDescriptionService,
		private widgetUtilsService: WidgetUtilsService,
	) {}

	/*
	 * returns promise (with created widget, when resolved)
	 */
	copyWidget(widget: Widget): angular.IPromise<Widget> {
		return this.copyWidgets([widget]).then((widgets) => {
			return widgets[0];
		});
	}

	copyWidgets(widgets: Widget[], externalDashboard: boolean = false, preserveIds: boolean = false): angular.IPromise<Widget[]> {
		return this.checkPageBreakWidget(widgets)
			.then((supportedWidgets: Widget[]) => {
				return this.checkAccessToReferencedObjects(supportedWidgets);
			})
			.then((widgetsToCopy: Widget[]) => {
				let copiedWidgets = _.map(widgetsToCopy, (widget) => {
					return this.createWidgetCopy(widget, externalDashboard, preserveIds);
				});
				return copiedWidgets;
			});
	}

	private checkPageBreakWidget(widgets: Widget[]): angular.IPromise<Widget[]> {
		let pageBreakWidgetCopyWarning = null;

		let supportedWidgets: Widget[] = _.filter(widgets, (widget) => {
			return !this.isPageBreakWidget(widget);
		});

		if (supportedWidgets.length < widgets.length) {
			pageBreakWidgetCopyWarning = this.cbDialogService.confirm(
				this.locale.getString('widget.copyingWidget'),
				this.locale.getString('widget.pageBreakWidgetCopyWarning')
			).result;
		}

		return this.$q.when(pageBreakWidgetCopyWarning).then(() => {
			return supportedWidgets;
		});
	}

	private checkAccessToReferencedObjects(widgets: Widget[]): angular.IPromise<Widget[]> {
		let sharedReportingWidgets: Widget[] = _.filter(widgets, (widget) => {
			return !ReportConstants.isContentWidget(widget) && this.isShared(widget);
		});

		let promise = this.widgetDataAccessService.hasAccessToPrivateObjects(sharedReportingWidgets).then(hasAccess => {
			if (hasAccess) return this.$q.resolve();

			if (widgets.length === 1) {
				return this.cbDialogService.confirm(
					this.locale.getString('widget.copyingWidget'),
					this.locale.getString('widget.copiedWidgetMayLookDiff')
				).result;
			} else {
				return this.cbDialogService.confirm(
					this.locale.getString('widget.copyingWidget'),
					this.locale.getString('widget.copiedWidgetsMayLookDiff')
				).result;
			}
		});

		return this.$q.when(promise).then(() => widgets);
	}

	private isPageBreakWidget(widget: Widget): boolean {
		return widget && widget.type === WidgetDisplayType.CONTENT && widget.name === 'page_break';
	}

	private isShared(widget: Widget): boolean {
		return !this.security.isCurrentUser(widget.properties.runAs);
	}

	isCBWidget(widget: Widget): boolean {
		return !_.isUndefined(widget) && widget.type === WidgetDisplayType.CB;
	}

	private createWidgetCopy(widget: Widget, externalDashboard: boolean = false, preserveIds: boolean = false): Widget {
		let created: Widget = angular.copy(widget);
		if (created.name === WidgetType.LABEL) {
			this.textWidgetUtils.convertLabelToText(created);
		}
		if (!externalDashboard) {
			created.posY = created.posY + created.height;

		}
		created.properties.runAs = this.security.getEmail();

		if (!preserveIds) {
			delete created.id;
		}

		if (created.properties.autoDescription) {
			this.widgetDescriptionService.generateDescription(created).then((description) => {
				created.description = description;
			});
		}

		delete created.linkedTo;
		delete created.linkedWidgets;
		delete created.properties.quickFilter;
		delete created.embedConfig;
		delete created.properties.initialPageNumber;
		return created;
	}

	updateAutoTitleAsync(widget: Widget): Promise<void> {
		if (!widget.properties.isCustomTitle) {
			return PromiseUtils.wrap(this.widgetUtilsService.getBuilder(widget).build().then(utils => {
				widget.displayName = this.updateAutoTitle(widget.properties, utils);
			}));
		} else {
			return Promise.resolve();
		}
	}

	// used while viewing widget and in settings, but different assets are available at those times
	updateAutoTitle(props: WidgetProperties, options: Partial<IWidgetSettings | WidgetUtils>): string {
		if (!props) {
			return;
		}

		if (props.widgetType === WidgetType.TABLE) {
			return this.titleUtils.generateTableReportTitle(props, options.attributes);
		} else if (props.widgetType === WidgetType.METRIC) {
			return this.titleUtils.generateAnMetricReportTitle(props, options.attributes);
		} else if (props.widgetType === WidgetType.MAP
			&& !_.isEmpty((options as IWidgetSettings).projectGeographies)) {
			// in view mode we don't have geographies
			// not sure if we even need this, as seems we can live with attributes
			return this.titleUtils.generateAnMapTitle(props, (options as IWidgetSettings).projectGeographies);
		} else if (props.widgetType === WidgetType.OBJECT_VIEWER) {
			return this.titleUtils.generateObjectViewerTitle(props, (options as IWidgetSettings).scorecards);
		} else if (props.widgetType === WidgetType.MODEL_VIEWER) {
			return this.titleUtils.generateModelViewerTitle(props);
		} else if (ReportConstants.isAnalyticWidget(props.widgetType)) {
			return this.titleUtils.generateAnalyticReportTitle(props, options.attributes);
		} else { // shouldn't go here, unless some ancient legacy
			return this.locale.getString('widget.widgetName');
		}
	}

	requireDateFilters(widgetType: WidgetType): boolean {
		return !widgetType || !ReportConstants.isAnalyticWidgetNotSelector(widgetType);
	}

	isOverlapping(target: Widget, containerId: string): boolean {
		let rightBoundary = this.gridsterConfigurer.getGridsterColumns();
		// out of gridster boundary
		if (target.posX < 0 || target.posY < 0 || target.posX + target.width > rightBoundary) {
			return true;
		}
		let widgetList = this.currentWidgets.getAllWidgets(containerId);
		return _.some(widgetList, widget => this.doOverlap(target, widget));
	}

	private doOverlap(widget1: Widget, widget2: Widget): boolean {
		// if one is on left side of the other
		if (widget1.posX >= widget2.posX + widget2.width || widget2.posX >= widget1.posX + widget1.width) {
			return false;
		}
		// if one is above the other
		if (widget1.posY >= widget2.posY + widget2.height || widget2.posY >= widget1.posY + widget1.height) {
			return false;
		}
		return true;
	}

	isCustomSelectorWidget(widget: Widget): boolean {
		return widget.name === WidgetType.SELECTOR
			&& widget.properties.selectedAttributes[0].sortOrder === 'custom'
			&& widget.visualProperties.sortBy === this.metricConstants.get().ALPHANUMERIC.name
			&& !this.calculationColorService.isCalculationColor(widget.visualProperties.color);
	}

	getWidgetContrastColor(widget: Widget): { color?: string } {
		let dashboardBackgroundColor = this.security.getActiveMasterAccountColors().COLOR_10 || '';
		let color = ColorUtilsHelper.pickContrastTextColor(dashboardBackgroundColor);
		return { color };
	}

	isDataNotRequired(widget: Widget): boolean {
		let isSelectorSearch = widget.name === WidgetType.SELECTOR
			&& widget.visualProperties.visualization === SelectorWidgetNavigationType.SELECTOR_SEARCH;
		return isSelectorSearch;
	}

	isEnterpriseViewersAllowed(widget: Widget): boolean {
		return widget.embedConfig?.enabled
			&& widget.embedConfig?.enterpriseViewersAllowed;
	}

}

app.service('widgetService', WidgetService);
