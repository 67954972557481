<modal-header modalTitle="{{ 'schedule.dashboardScheduling' | i18n:{name: input.dashboard.name|middleEllipsis:50} }}"
	(cancel)="close()">
</modal-header>
<div id="scheduleSettings" [ngBusy]="busy" class="modal-body new-scheduler schedules-{{schedules.length}} p-static" scrollingModal>
	<div class="row">
		<p class="col-sm-12">{{'schedule.description'|i18n}}</p>
	</div>
	<div *ngIf="dataReady" [ngBusy]="schedulesUpdating">
		<schedule-panel
			class="br-schedule-panel"
			*ngFor="let subscope of schedules; index as i;"

			[index]="i"
			[settings]="subscope.settings"
			[subscribers]="subscope.subscribers"
			[dashboard]="input.dashboard"
			[hierarchies]="hierarchies"
			[dateFormat]="dateFormat"
			[numberOfUsers]="numberOfUsers"
			[autoOpen]="subscope.autoOpen"

			[updateSummaryFunction]="updateSummary"
			[removeScheduleFunction]="remove"
			[revert]="revertSchedule"
			[getSummaryFunction]="getSummary"
			[validateFunction]="validateFunction"
			[saveSchedule]="saveSchedule"
			[updateMasterSchedule]="updateMasterSchedule"
			[isModified]="isScheduleChangedByIndex"
			[testEmailFunction]="sendTestEmail">
		</schedule-panel>
		<p *ngIf="schedules.length < 1"><em>{{'schedule.noActionsScheduled'|i18n}}</em></p>
		<button class="br-add-schedule btn btn-primary pull-right"
			[disabled]="schedules.length >= scheduleLimit"
			(click)="addSchedule()">{{'schedule.addSchedule'|i18n}}</button>
	</div>
</div>
<ok-modal-footer
	okText="{{'common.close' | i18n}}"
	(cancel)="close()">
</ok-modal-footer>
