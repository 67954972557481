import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CloudD3UtilsService } from '@app/modules/widget-visualizations/utilities/cloud-d3-utils.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { CxLocaleService } from '@app/core';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { CloudVisualProperties } from '@cxstudio/dashboards/widgets/type-definitions/cloud-visual-properties';
import { UIOption } from '@clarabridge/unified-angular-components';

export interface IAdvancedCloudSettingsInput {
	visualProps: VisualProperties;
}

@Component({
	selector: 'advanced-cloud-settings',
	templateUrl: './advanced-cloud-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedCloudSettingsComponent extends ModalBindings<IAdvancedCloudSettingsInput> implements OnInit {
	advancedOptionsToggles = [
		{
			label: this.locale.getString('widget.cloudShape'),
			options: [
				{ displayName: this.locale.getString('widget.cloud'), value: OptionsConstant.CLOUD_SHAPE_CLOUD },
				{ displayName: this.locale.getString('widget.cloudShapeRectangle'), value: OptionsConstant.CLOUD_SHAPE_RECTANGLE }
			] as UIOption<any>[],
			field: 'cloudShape'
		},
		{
			label: this.locale.getString('widget.cloudRelativeSizing'),
			options: [
				{ displayName: this.locale.getString('widget.cloudSizingOptionOne'), value: OptionsConstant.CLOUD_SIZING_OPTION_ONE },
				{ displayName: this.locale.getString('widget.cloudSizingOptionTwo'), value: OptionsConstant.CLOUD_SIZING_OPTION_TWO }
			] as UIOption<any>[],
			field: 'cloudSizing'
		}
	];

	visualProps: CloudVisualProperties;

	constructor(
		private readonly cloudD3Utils: CloudD3UtilsService,
		private readonly locale: CxLocaleService,
		modal: NgbActiveModal,
	) {
		super(modal);
	}

	ngOnInit() {
		this.visualProps = this.input.visualProps as CloudVisualProperties;
	}
}
