import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';


export class SampledAudits implements ng.IController {

	loading: ProjectAssetsLoading = {};
	errors: ProjectAssetsErrors = {};
	projectSelection: IProjectSelection;

	sessionsList: any[];
	textFilter: string;

	gridType: GridTypes;
	gridNameField: string;
	gridOptions: SlickgridOptions;
	lastChange: any;
	sampledAuditsColumns: IGridColumn[];

	constructor(
		private locale: ILocale
	) {}

	$onInit = () => {

		this.gridType = GridTypes.SAMPLED_AUDITS;
		this.gridNameField = 'displayName';
		this.gridOptions = {};
	}

	onProjectChanged = (newProps: IProjectSelection): void => {
		this.applyProjectSelection(newProps);
	}

	onAccountChanged = (newProps?: IProjectSelection): void => {
		this.applyProjectSelection(newProps);
	}

	private applyProjectSelection = (props: IProjectSelection): void => {
		if (props) {
			this.projectSelection = props;
		}
	}

	isProjectSelected = (): boolean => {
		return ProjectIdentifier.isProjectSelected(this.projectSelection);
	}

	onColumnsChanged = (columns): void => {
		this.sampledAuditsColumns = columns;
	}
}

app.component('sampledAudits', {
	controller: SampledAudits,
	templateUrl: 'partials/sampled-audits/sampled-audits.component.html'
});
