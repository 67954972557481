
import * as _ from 'underscore';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';

export default class MobileAppSharingControlController implements ng.IComponentController {

	settings: MobileAppSettings;
	toggledExternalSharing = false;

	constructor(
	) {
	}

	$onInit(): void {
		// required for typescript validation of IController
	}

	toggleExternalSharing(): void {
		this.toggledExternalSharing = this.settings.externalSharing?.enabled;
	}

}

app.component('mobileAppSharingControl', {
	controller: MobileAppSharingControlController,
	templateUrl: 'partials/mobile/mobile-app-sharing-control.html',
	bindings: {
		settings: '=',
	}
});
