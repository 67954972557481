import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AnalyticsHelperService } from '@app/modules/analytics/analytics-helper.service';
import { AnalyticsService } from '@app/modules/analytics/analytics.service';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { SiteInterceptComponent } from './site-intercept.component';
import { AmplitudeEventDirective } from './amplitude/amplitude-event.directive';

@NgModule({
	imports: [
		CommonModule,
		ScriptLoaderModule,
	],
	exports: [
		AmplitudeEventDirective
	],
	declarations: [
		SiteInterceptComponent,
		AmplitudeEventDirective,
	],
	providers: [
		AnalyticsHelperService,
		AnalyticsService,
		AmplitudeAnalyticsService,
	],
})
export class AnalyticsModule { }
