import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { DateFiltersOptions, DateFiltersSelectorProperties } from '@cxstudio/reports/settings/date-filters.component';


@Directive({
	selector: 'date-filters'
})

export class DateFiltersUpgrade extends UpgradeComponent {
	@Output() onFilterChange: EventEmitter<{
		dateFilterMode: DateFilterMode,
		dateFilterRange: IDateRange, dateDisplayName: string
	}>;
	@Output() onNodeSelection: EventEmitter<void>;
	@Input() historicOptions: boolean;
	@Input() options: DateFiltersOptions;
	@Input() timezone: string;
	@Input() selectorProperties: DateFiltersSelectorProperties;
	@Input() autoClose: boolean;
	@Input() fitParentWidth: boolean;
	@Input('dateFilterMode') dateFilterModeArgument: DateFilterMode | string;
	@Input('dateFilterRange') dateFilterRangeArgument: {from: string, to: string};
	@Input() dateFilterName: string;
	@Input() currentPeriodForHistoric: DateFilterMode;
	@Input() ngDisabled: () => boolean;
	@Input() fixedPosition: string;
	@Input() appendToBody: boolean;
	@Input() dateOptionsFilter: (item) => boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('dateFilters', elementRef, injector);
	}
}
