import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { PreviewSortAttributes } from './preview-sort-attributes';
export class SortMetric {
	displayName: string; // it's the name for analytics, not a real "display" name
	direction: AnSortDirection;

	constructor(metric: PreviewSortAttributes, direction: AnSortDirection) {
		this.displayName = metric;
		this.direction = direction;
	}
}
