import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils } from '@app/util/change-utils';
import { UserEditFormData } from '../../editor/user-edit-form-data';

@Component({
	selector: 'user-create-groups-step',
	templateUrl: './user-create-groups-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateGroupsStepComponent implements OnInit, OnChanges {
	@Input() last: boolean;
	@Input() finishEnabled: boolean;

	@Input() user: UserEditFormData;

	constructor(
		private wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.wizardStep.markAsLast(this.last);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.last)) {
			let last: boolean = changes.last.currentValue;
			this.wizardStep.markAsLast(last);
			this.updateValidity();
		} else if (ChangeUtils.hasChange(changes.finishEnabled)) {
			this.updateValidity();
		}
	}

	private updateValidity(): void {
		if (this.last) {
			this.wizardStep.setValid(this.finishEnabled);
		} else {
			this.wizardStep.setValid(true);
		}
	}
}