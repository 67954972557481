import * as cloneDeep from 'lodash.clonedeep';

export class ObjectUtils {
	static copy<T>(object: T): T {
		if (object === undefined || object === null)
			return object;
		let copy = cloneDeep(object);
		delete copy.$$hashKey;
		if (_.isArray(copy)) {
			_.each(copy, item => {
				if (item !== undefined && item !== null) {
					delete item.$$hashKey;
				}
			});
		}
		return copy;
	}
}
