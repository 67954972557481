import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';

export class SentenceMetadataService {
	constructor() {
	}

	hasSentiment = (sentence: PreviewSentence): boolean => !_.isUndefined(sentence.sentiment);

	hasEaseScore = (sentence: PreviewSentence): boolean => !_.isUndefined(sentence.easeScore);

	hasEmotion = (sentence: PreviewSentence): boolean => !_.isUndefined(sentence.emotionIntensity);

	getEmotion = (sentence: PreviewSentence) => sentence.emotionIntensity;
}

app.service('sentenceMetadataService', SentenceMetadataService);
