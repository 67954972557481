import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { ContentProviderApi } from '@app/modules/system-administration/content-provider/content-provider.api.service';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { FilterByPipe } from 'ngx-pipes';

@Component({
	selector: 'content-provider-linking',
	templateUrl: './content-provider-linking.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FilterByPipe],
})
export class ContentProviderLinkingComponent implements OnInit {

	@Input() accountMap: {[cpId: number]: number[]};

	@Output() accountMapUpdate = new EventEmitter<void>();

	contentProviders: ContentProvider[];
	searchText: string;
	pagination: Pagination;

	loadingCPs: Promise<any>;
	loadingAccount: {[accountId: number]: Promise<any>} = {};
	accounts: {[cpId: number]: ContentProviderAccount[]} = {};

	constructor(
		private ref: ChangeDetectorRef,
		private contentProviderApi: ContentProviderApi,
		private filterBy: FilterByPipe,
	) { }

	ngOnInit(): void {
		this.initPagination();
		this.loadingCPs = this.contentProviderApi.getContentProviderList().then(result => {
			this.contentProviders = result;
			this.initPagination();
			this.ref.markForCheck();
		});
	}

	onSearchChange() {
		this.initPagination();
		this.pagination.totalItems = this.filterBy.transform(this.contentProviders, ['name'], this.searchText).length;
	}

	private initPagination() {
		this.pagination = new Pagination(10);
		if (this.contentProviders) {
			this.pagination.totalItems = this.contentProviders.length;
		}
	}

	getPanelId(cpId: number): string {
		return `cp-${cpId}`;
	}

	loadAccounts(panelId: string): void {
		const cpId = Number(panelId.replace('cp-', ''));
		if (this.accounts[cpId])
			return;
		this.loadingAccount[cpId] = this.contentProviderApi.getContentProviderAccounts(cpId).then(accounts => {
			this.accounts[cpId] = accounts;
			if (!this.accountMap[cpId])
				this.accountMap[cpId] = [];
			this.ref.markForCheck();
		});
	}

	getCheckedStatus(cpId: number, accountId: number): 'checked' | '' {
		if (this.accountMap[cpId].contains(accountId))
			return 'checked';
		else return '';
	}

	updateCheckedStatus(cpId: number, accountId: number, event: any): void {
		if (event.target.checked) {
			this.accountMap[cpId].push(accountId);
		} else {
			this.accountMap[cpId].remove(accountId);
		}

		this.accountMapUpdate.emit();
	}
}
