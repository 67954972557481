<form [formGroup]="integration">
	<nav ngbNav #nav="ngbNav" class="nav-tabs br-tab-integration-content" [destroyOnHide]="false">
		<ng-container ngbNavItem>
			<a ngbNavLink  class="text-uppercase br-tab-oauth">{{"mAccount.externalAuthIntegration"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!externalAuthSettingsForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<external-auth-settings-integration></external-auth-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-engagor">{{"mAccount.engage"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!engagorSettingsForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<engagor-settings-integration [masterAccountId]="masterAccountId">
				</engagor-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-tuning-suggestion">{{"mAccount.tuningSuggestion"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!tuningSuggestionIntegrationForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<tuning-suggestion-integration></tuning-suggestion-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-connectors">{{"mAccount.connectorsIntegration"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!connectorsSettingsForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<connectors-settings-integration></connectors-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-predictive">{{"mAccount.predictiveIntegration"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!predictionSettingsForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<predictive-settings-integration></predictive-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-filestorage">{{"mAccount.voice"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!voiceSettingsForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<voice-settings-integration></voice-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-units">{{"mAccount.unitsIntegration"|i18n}}</a>
			<ng-template ngbNavContent>
				<units-settings-integration
					[masterAccountId]="masterAccountId"
					[masterAccountName]="masterAccountName"
					formControlName="unitIds">
				</units-settings-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-narrative">{{"mAccount.narrativeIntegration"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!narrativeIntegrationForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<narrative-integration></narrative-integration>
			</ng-template>
		</ng-container>

		<ng-container ngbNavItem>
			<a ngbNavLink class="text-uppercase br-tab-qualtrics">{{"mAccount.qualtricsIntegration"|i18n}}
				<span class="text-danger q-icon-warning" aria-hidden="true"
					  *ngIf="!qualtricsIntegrationForm?.valid || !qualtricsApiIntegrationForm?.valid"></span></a>
			<ng-template ngbNavContent>
				<qualtrics-integration></qualtrics-integration>
			</ng-template>
		</ng-container>

	</nav>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</form>
