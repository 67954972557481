import { Component, OnInit, Input, Inject } from '@angular/core';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { TransferData } from '@app/modules/user-administration/transfer/transfer-data';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardTransferMode } from '@cxstudio/user-administration/users/asset-transfer/dashboard-transfer-mode';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';
import { TransferCandidate } from '@app/modules/user-administration/transfer/transfer-candidate';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';


interface BulkTransferDialogInput {
	mode: TransferGroup;
	projectIdentifier: AccountOrWorkspaceProject;
	selectedObjects: any[];
}

@Component({
	selector: 'bulk-transfer-dialog',
	templateUrl: './bulk-transfer-dialog.component.html'
})
export class BulkTransferDialogComponent implements OnInit {
	private mode: TransferGroup;
	selectedObjects: any[];
	private projectIdentifier: AccountOrWorkspaceProject;
	candidates: TransferCandidate[];
	selectedCandidate: TransferCandidate;
	private loading: Promise<any>;
	private owner: TransferCandidate;

	private itemCount: number;
	retainEditPermission: boolean;
	dashboardTransferMode: DashboardTransferMode;
	icons: any;

	@Input() input: BulkTransferDialogInput;
	constructor(
		@Inject('security') private readonly security: Security,
		private readonly transferApiService: TransferApiService,
		private readonly locale: CxLocaleService,
		private readonly activeModal: NgbActiveModal,
	) {}

	ngOnInit(): void {
		this.mode = this.input.mode;
		const selectedObjects = this.input.selectedObjects;

		this.projectIdentifier = this.input.projectIdentifier;
		this.candidates = [];
		this.owner = {id: selectedObjects[0].ownerId, email: selectedObjects[0].ownerName};
		// sort the selected objects after setting the correct owner
		this.selectedObjects = this.getSortedSelectedObjects(selectedObjects);

		this.itemCount = this.selectedObjects.length;
		this.retainEditPermission = true;
		this.icons = IconsMap.getDashboardIconsMap();
		if (this.mode === TransferGroup.DASHBOARDS) {
		// if mode is dashboards, determine dashboard transfer mode
			this.populateDashboardTransferMode();
		}
		this.init();
	}

	init(): void {
		const getCandidatesPromise = this.transferApiService.getTransferCandidatesByGroup(this.mode, this.projectIdentifier);
		this.loading = getCandidatesPromise;
		getCandidatesPromise.then((candidates: any[]) => {
			let owner = candidates.find(candidate => candidate.id === this.owner.id);
			if (!owner) {
				owner = this.owner;
				this.candidates.push(this.owner);
			}
			this.candidates.pushAll(this.getSortedCandidatesByEmail(candidates));
			this.selectedCandidate = owner;
		});
	}
	populateDashboardTransferMode(): void {
		const hasBooks = _.any(this.selectedObjects, {type: DashboardType.BOOK});
		const hasDashboards = _.any(this.selectedObjects, {type: DashboardType.DASHBOARD});

		if (hasBooks && hasDashboards) {
			this.dashboardTransferMode = DashboardTransferMode.MIXED;
		} else {
			this.dashboardTransferMode = hasBooks
				? DashboardTransferMode.ONLY_BOOKS
				: DashboardTransferMode.ONLY_DASHBOARDS;
		}
	}

	showRetainEditPermissionCheckbox(): boolean {
		return this.mode === TransferGroup.DASHBOARDS
			|| this.mode === TransferGroup.FILTERS
			|| this.mode === TransferGroup.DATE_FILTERS
			|| this.mode === TransferGroup.METRICS
			|| this.mode === TransferGroup.DRIVERS
			|| this.mode === TransferGroup.METRIC_ALERTS;
	}

	getHeader(): string {
		switch (this.mode) {
			case TransferGroup.DASHBOARDS: {
				let key = 'dashboard.bulkDashboardTransferHeader';
				if (this.dashboardTransferMode === DashboardTransferMode.MIXED) {
					key = 'dashboard.bulkMixedTransferHeader';
				}
				if (this.dashboardTransferMode === DashboardTransferMode.ONLY_BOOKS) {
					key = 'dashboard.bulkBooksTransferHeader';
				}
				return this.locale.getString(key, {dashboardCount: this.itemCount});
			}
			case TransferGroup.METRICS: {
				return this.locale.getString('metrics.bulkTransferMetricTitle', {metricCount: this.itemCount});
			}
			case TransferGroup.FILTERS: {
				return this.locale.getString('reportFilters.bulkTransferFilterTitle', {filterCount: this.itemCount});
			}
			case TransferGroup.DRIVERS: {
				return this.locale.getString('drivers.bulkTransferDriverTitle', {driverCount: this.itemCount});
			}
			case TransferGroup.METRIC_ALERTS: {
				return this.locale.getString('alert.bulkTransferMetricAlertTitle', {alertCount: this.itemCount});
			}
		}
	}

	getTransferMessage(): string {
		let messageKey: string = this.getTransferMessageKey(this.mode);
		if (this.itemCount === 1) {
			messageKey = messageKey + 'Singular';
		}
		return this.locale.getString(messageKey, {itemCount: this.itemCount});
	}

	private getTransferMessageKey(mode: TransferGroup): string {
		switch (mode) {
			case TransferGroup.DASHBOARDS: {
				if (this.dashboardTransferMode === DashboardTransferMode.MIXED) {
					return 'dashboard.bulkMixedTransferMessage';
				}
				if (this.dashboardTransferMode === DashboardTransferMode.ONLY_BOOKS) {
					return 'dashboard.bulkBooksTransferMessage';
				}
				return 'dashboard.bulkDashboardTransferMessage';
			}

			case TransferGroup.METRICS: {
				return 'metrics.bulkTransferMetricText';
			}
			case TransferGroup.FILTERS: {
				return 'reportFilters.bulkTransferFilterText';
			}
			case TransferGroup.DRIVERS: {
				return 'drivers.bulkTransferDriverText';
			}
			case TransferGroup.METRIC_ALERTS: {
				return 'alert.bulkTransferMetricAlertText';
			}
		}
	}

	getTransferData(): TransferData {
		const transferData = new TransferData();
		transferData.userId = this.owner.id;
		const currentMasterAccountId = this.security.getMasterAccountId();
		const transferItems = _.map(this.selectedObjects, (object: any) => {
			object.ownerName = this.selectedCandidate.email;
			return {id: object.id,
				name: object.displayName
					? object.displayName
					: object.name,
				selectedCandidateId: this.selectedCandidate.id};
		});
		transferData.transferItems = {} as any;
		transferData.transferItems[currentMasterAccountId] = {};
		transferData.transferItems[currentMasterAccountId][this.mode] = transferItems;
		transferData.transferWidgetsInUnownedDashboards = false;
		transferData.retainEditPermission = this.retainEditPermission;
		return transferData;
	}

	getSortedCandidatesByEmail(candidates: TransferCandidate[]): TransferCandidate[] {
		return candidates.sort((a, b) => a.email.localeCompare(b.email));
	}
	getSortedSelectedObjects(selectedObjects: any[]): any[] {
		return selectedObjects.sort((a, b) => a.name.localeCompare(b.name));
	}
	getDisplayName(selectedObject: any): string {
		return selectedObject.displayName
			? selectedObject.displayName
			: selectedObject.name;
	}

	continue(): void {
		if (this.selectedCandidate.id === this.owner.id) {
			this.cancel();
		} else {
			this.activeModal.close(this.getTransferData());
		}
	}

	cancel(): void {
		this.activeModal.dismiss();
	}

	getTransferBtnText(): string {
		return this.locale.getString('dashboard.transferOwnerWidgetConfirmOption');
	}

	getNewOwnerText(): string {
		return this.locale.getString('mAccount.newOwner');
	}
}
