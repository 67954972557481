<input
	id="internal-user-add-input"
	type="text"
	class="w-100-percent"
	size="25"
	autocomplete="off"
	[placeholder]="'administration.internalUserInputPlaceholder' | i18n"
	[(ngModel)]="selectedUser"
	[inputFormatter]="formatUserForInput"
	[ngbTypeahead]="typeAheadUserSuggestions"
	(selectItem)="onUserSelect($event)"
	[disabled]="disabled"
	[resultTemplate]="resultTemplate"
	[editable]="false"
>

<ng-template #resultTemplate let-user="result" let-term="term">
	<icon name="user" className="mr-4"></icon>
	<ngb-highlight [result]="user.userEmail" [term]="term"></ngb-highlight>
</ng-template>
