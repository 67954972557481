const SENTIMENT = [
	'q-icon-thumb-down',
	'q-icon-thumb-down',
	'q-icon-minus-2',
	'q-icon-thumb-up',
	'q-icon-thumb-up'
];

// TODO: Update the icons to the new icons once they are available.
const ML_SENTIMENT = [
	'q-icon-thumb-down',
	'q-icon-thumb-down',
	'q-icon-minus-2',
	'q-icon-thumb-up',
	'q-icon-thumb-up'
];

export class PredefinedMetricIcons {
	static readonly NUMERIC_BREAKDOWN = {
		emotion: 'q-icon-intensity'
	};

	static readonly EASE_SCORE = [
		'q-icon-effort-negative', //very hard
		'q-icon-effort-negative', //hard
		'q-icon-effort', //neutral
		'q-icon-effort-positive', //easy
		'q-icon-effort-positive' //very easy
	];

	static getSentimentIcons(isMlSentimentEnabled?: boolean): string[] {
		if (isMlSentimentEnabled) {
			return ML_SENTIMENT;
		} else {
			return SENTIMENT;
		}
	}
}


