
import * as _ from 'underscore';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import MobileDateRange from './mobile-date-range.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from './mobile-app-dialog-tab.enum';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { PromiseUtils } from '@app/util/promise-utils';

export default class MobileAppDateRangesController implements ng.IComponentController {

	readonly AVAILABLE_DATE_RANGES_LIMIT: number = 5;
	readonly CUSTOM_DATE_RANGE_PREFIX: string = 'customFilter:';

	/** If you update it, make sure to update it in MobileScreenReportProperties.java::CUSTOM_DATE_FILTER_DAYS_LIMIT */
	readonly DAYS_LIMIT = 90;

	settings: MobileAppSettings;
	promises: MobileAppConfigurationPromises;

	dateRanges: MobileDateRange[];
	private userTriedToExceedDateRangeLimit: boolean = false;
	selectedItem: MobileDateRange;
	private errors: Errors;

	constructor(
		private $scope: ISimpleScope,
		private DateRange,
		private locale: ILocale,
		private filterManagementApiService: FilterManagementApiService,
		private readonly filtersService: FiltersService,
	) {}

	$onInit(): void {
		this.loadDateRanges();
		this.populateSelectedItem();
		this.$scope.$on('settings.projectChanged', this.changeProject);
	}

	private changeProject = (): void => {
		this.populateDefaultDateRanges();
		this.loadDateRanges();
	}

	private populateDefaultDateRanges = (): void => {
		this.settings.dateRanges = [
			{ value: 'last24h', selectedAsDefault: false },
			{ value: 'last3d', selectedAsDefault: false },
			{ value: 'last7d', selectedAsDefault: true },
			{ value: 'last30d', selectedAsDefault: false }
		];
	}

	populateSelectedItem = (): any => {
		this.selectedItem = _.findWhere(this.settings.dateRanges, {selectedAsDefault: true});
	}

	isFilterVisible = (item): boolean => {
		let value = this.selectedItem.value;

		return item && ((item.enabled || item.value === value) || item.value.startsWith(this.CUSTOM_DATE_RANGE_PREFIX));
	}

	private loadDateRanges = (): void => {
		if (!ProjectIdentifier.isProjectSelected(this.settings)) {
			return;
		}

		this.promises.dateRanges.promise = this.loadCustomDateRanges().then(customDateRanges => {
			let presetFiltersStatesPromise = this.filterManagementApiService.getPresetDateFiltersStates();
			return presetFiltersStatesPromise.then((presetFiltersStates) => {
				let index = 0;

				let predefinedDateRangeOptions = this.DateRange.mobileOptions
					.map(mobileDateRangeOption => {
						return {
							enabled: presetFiltersStates[mobileDateRangeOption.id],
							index: index++,
							value: mobileDateRangeOption.value,
							displayName: this.locale.getString('dateRange.' + mobileDateRangeOption.value),
							selectedAsDefault: false
						} as MobileDateRange;
					});

				this.settings.dateRanges.forEach(dateRange => {
					let item: any = _.findWhere(predefinedDateRangeOptions, {value: dateRange.value});
					if (item) {
						dateRange.enabled = item.enabled;
					}
				});

				let customDateRangeOptions = customDateRanges
					.map(customDateRangeOption => {
						return {
							index: index++,
							value: this.CUSTOM_DATE_RANGE_PREFIX + customDateRangeOption.id,
							displayName: customDateRangeOption.name,
							selectedAsDefault: false
						} as MobileDateRange;
					});

				this.dateRanges = [];
				this.dateRanges.pushAll(predefinedDateRangeOptions);
				this.dateRanges.pushAll(customDateRangeOptions);

				this.matchAvailableDateRanges();
			});
		});
	}

	private loadCustomDateRanges = (): ng.IPromise<any[]> => {
		let project = {
			contentProviderId: this.settings.contentProviderId,
			accountId: this.settings.accountId,
			projectId: this.settings.projectId,
		};
		return PromiseUtils.old(this.filtersService.getStudioDateFilters(project, this.DAYS_LIMIT));
	}

	private matchAvailableDateRanges = (): void => {
		let notMatchingDateRanges = this.settings.dateRanges.filter(dateRange => !this.matchDateRange(dateRange));
		notMatchingDateRanges.forEach(dateRange => {
			this.settings.dateRanges.remove(dateRange);
			if (dateRange.selectedAsDefault) {
				this.resetDefaultDateRange(dateRange);
			}
		});

		this.validateDateRanges();
	}

	private matchDateRange = (dateRange: MobileDateRange): boolean => {
		let dateRangeOption = _.findWhere(this.dateRanges, { value: dateRange.value });

		if (dateRangeOption) {
			// found existing date range option for selected date range, removing it from the list of available options
			dateRange.index = dateRangeOption.index;
			dateRange.displayName = dateRangeOption.displayName;

			this.dateRanges.remove(dateRangeOption);
			return true;
		} else {
			return false;
		}
	}

	selectDateRange = (dateRange: MobileDateRange): void => {
		this.userTriedToExceedDateRangeLimit = this.isAvailableDateRangesLimitReached();

		if (!this.isAvailableDateRangesLimitReached()) {
			this.settings.dateRanges.push(dateRange);
			this.dateRanges.remove(dateRange);
		}

		if (this.settings.dateRanges.length === 1) {
			dateRange.selectedAsDefault = true;
		}

		this.validateDateRanges();
	}

	moveAvailableDateRange = (dateRange: MobileDateRange, index: number): void => {
		this.settings.dateRanges.remove(dateRange);
		this.settings.dateRanges.splice(index, 0, dateRange);
	}

	private isAvailableDateRangesLimitReached = (): boolean => {
		return this.settings.dateRanges.length >= this.AVAILABLE_DATE_RANGES_LIMIT;
	}

	hasUserTriedToExceedDateRangeLimit = (): boolean => {
		return this.userTriedToExceedDateRangeLimit;
	}

	setDefaultDateRange = (defaultDateRange: MobileDateRange): void => {
		this.settings.dateRanges.forEach(dateRange => dateRange.selectedAsDefault = false);
		defaultDateRange.selectedAsDefault = true;

		this.validateDateRanges();
	}

	removeDateRange = (dateRange: MobileDateRange): void => {
		this.settings.dateRanges.remove(dateRange);
		if (dateRange.selectedAsDefault) {
			this.resetDefaultDateRange(dateRange);
		}

		if (dateRange.enabled || dateRange.value.startsWith(this.CUSTOM_DATE_RANGE_PREFIX)) {
			this.dateRanges.push(dateRange);
			this.sortDateRangeOptions();
		}

		this.validateDateRanges();
	}

	private validateDateRanges = (): void => {
		this.errors.setValid([ MobileAppDialogTab.CUSTOMIZATIONS, 'date-ranges' ], 'no-date-ranges', (Boolean) (this.settings.dateRanges.length));

		const hasDefaultDateRange = this.settings.dateRanges.filter(dateRange => dateRange.selectedAsDefault).length > 0;
		this.errors.setValid([ MobileAppDialogTab.CUSTOMIZATIONS, 'date-ranges' ], 'no-default-date-range', hasDefaultDateRange);
	}

	private resetDefaultDateRange = (dateRange: MobileDateRange): void => {
		dateRange.selectedAsDefault = false;
		if (this.settings.dateRanges.length > 0) {
			this.settings.dateRanges[0].selectedAsDefault = true;
		}
	}

	private sortDateRangeOptions = (): void => {
		this.dateRanges = this.dateRanges.sort((left, right) => left.index - right.index);
	}

}

app.component('mobileAppDateRanges', {
	controller: MobileAppDateRangesController,
	templateUrl: 'partials/mobile/mobile-app-date-ranges.html',
	bindings: {
		settings: '=',
		promises: '=',
		errors: '='
	}
});
