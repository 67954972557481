export default class WizardStep {

	constructor(
		private templateUrl: string,
		private nextStepAllowed: () => boolean = () => true,
		private previousStepAllowed: () => boolean = () => true
	) {}

	getTemplateUrl(): string {
		return this.templateUrl;
	}

	isNextStepAllowed(): boolean {
		return this.nextStepAllowed();
	}

	isPreviousStepAllowed(): boolean {
		return this.previousStepAllowed();
	}

}
