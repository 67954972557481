import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ListOption } from '@app/shared/components/forms/list-option';
import { downgradeComponent } from '@angular/upgrade/static';
import { BorderOptions, BorderValue, IWidgetBorder } from '@cxstudio/reports/entities/content-widget-properties';

@Component({
	selector: 'border-options',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div class="btn-group" id="text-borders">
		<button class="btn btn-secondary"
			*ngFor="let border of options"
			[class.btn-selected]="values[border.value]"
			[ngClass]="border.value"
			[disabled]="isDisabled(border.value)"
			(click)="toggleBorder(border.value)">
			{{border.name}}
		</button>
	</div>
	`
})
export class BorderOptionsComponent {

	@Input() values: IWidgetBorder;
	@Output() onChange = new EventEmitter<void>();

	options: ListOption<BorderOptions>[] = [
		{value: BorderOptions.BOTTOM, name: this.locale.getString('widget.bottom')},
		{value: BorderOptions.TOP, name: this.locale.getString('widget.top')},
		{value: BorderOptions.ALL, name: this.locale.getString('widget.all')}
	];

	constructor(private locale: CxLocaleService) {}

	toggleBorder = (value: BorderOptions): void => {
		this.values[value] = this.values[value] === BorderValue.ON ? BorderValue.OFF : BorderValue.ON;
		this.onChange.emit();
	}

	isDisabled(option: BorderOptions): boolean {
		return this.values.border && option !== BorderOptions.ALL;
	}

}

app.directive('borderOptions', downgradeComponent({component: BorderOptionsComponent}) as angular.IDirectiveFactory);
