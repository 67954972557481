<div [ngBusy]="loadingPromise">
	<form [formGroup]="engagorSettings">
		<div class="rounded-grouping">
			<div class="rounded-grouping-label">
				<div class="labeled-checkbox">
					<checkbox-button
						id="enableEngagor"
						class="mr-0"
						name="enableEngagor"
						label="{{'mAccount.enableEngagorIntegration'|i18n}}"
						formControlName="engagorEnabled">
					</checkbox-button>
				</div>
			</div>

			<div class="form-group">
				<label for="accountId" class="control-label col-md-3">{{'mAccount.propEngagorAccountId'|i18n}}</label>
				<div class="col-md-8">
					<input id="accountId" type="text" class="form-control"
						name="engagorAccountId" placeholder=""
						formControlName="accountId">
					<span *ngIf="accountId?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.propEngagorAccountId') }}
					</span>
				</div>
			</div>

			<div class="form-group">
				<label for="topicId" class="control-label col-md-3">{{'mAccount.propEngagorTopicId'|i18n}}</label>
				<div class="col-md-8">
					<input id="topicId" type="text" class="form-control"
						name="engagorTopicId" placeholder=""
						formControlName="topicId">
					<span *ngIf="topicId?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.propEngagorTopicId') }}
					</span>
				</div>
			</div>

			<div class="form-group">
				<label for="engageApplication" class="control-label col-md-3">{{'mAccount.engageApplication'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown
						class="form-control p-0 border-0"
						id="engageApplication"
						[options]="engageApplications"
						formControlName="applicationId"
						valueField="id"
						sortField="name">
					</simple-dropdown>
					<span *ngIf="applicationId?.errors?.required" class="text-danger"><span class="q-icon-warning"></span>
						{{ formUtils.getErrorMessage('mAccount.engageApplication') }}
					</span>
				</div>
			</div>

			<div class="form-group" *ngIf="hierarchies && hierarchies.length" formGroupName="selectedHierarchy">
				<label for="caseHierarchy" class="control-label col-md-3">{{'mAccount.propEngagorHierarchy'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown
						class="form-control p-0 border-0"
						id="caseHierarchy"
						[options]="hierarchies"
						formControlName="id"
						valueField="id"
						sortField="id"
						(onChange)="selectHierarchy($event)"
						allowClear="true">
					</simple-dropdown>
				</div>
			</div>

			<div class="row">
				<div class="col-md-8 col-md-offset-1">
					<div class="labeled-checkbox">						
						<checkbox-button
							id="externalEngagementEnabled"
							name="externalEngagementEnabled"
							label="{{'mAccount.propEngagorExternalEngagement'|i18n}}"
							formControlName="externalEngagementEnabled">
						</checkbox-button>
					</div>
					<div class="labeled-checkbox">
						<cb-inline-help>
							<help-body [innerHTML]="'mAccount.redirectToEngageLandingPageHelp'|i18n"></help-body>
						</cb-inline-help>
						<checkbox-button
							id="engageLandingPage"
							name="engageLandingPage"
							label="{{'mAccount.redirectToEngageLandingPage'|i18n}}"
							formControlName="redirectToEngageLandingPage">
						</checkbox-button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
