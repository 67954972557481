<div>
	<div class="col-xs-12" >
		<h3>{{topDisplayText}}</h3>
	</div>

	<div class="col-sm-5 clearfix">
		<p class="large">{{ getFilteredAvailableItems().length }} {{objectAvailableDescriptor}}</p>
		<div class="clearfix">
			<input [(ngModel)]="itemsFilter"
				type="search"
				class="block"
				placeholder="{{'common.search'|i18n}}">
			<div class="pull-left mt-16">
				<button class="btn btn-primary add-all-btn"
					type="button"
					(click)="selectAllItems()"
					[disabled]="!allowSelectItems()">
					{{addButtonText()}}
				</button>
			</div>
		</div>
		<select multiple
			class="case-mgmt-attributes-list available-items col-xs-12 mt-16"
			[(ngModel)]="availableItems">
			<option
				*ngFor="let item of getSortedFilteredAvailableItems()"
				value="{{item[itemsFieldIdentifier]}}">
				{{item.groupName}}
			</option>
		</select>
	</div>

	<div class="col-sm-2 text-center" >
		<div class="hidden-xs case-attr-swap-btns">
			<div>
				<button class="btn btn-secondary"
					type="button"
					(click)="toggleSelections(availableItems)"
					[disabled]="!allowSelectItems()">
					<span class="q-icon q-icon-arrow-right" attr.aria-label="{{'common.add'|i18n}}"></span>
				</button>
			</div>
			<div class="mt-16">
				<button class="btn btn-secondary"
					type="button"
					(click)="toggleSelections(selectedItems)"
					[disabled]="!allowDeselectItems()">
					<span class="q-icon q-icon-arrow-left" attr.aria-label="{{'common.remove'|i18n}}"></span>
				</button>
			</div>
		</div>
	</div>

	<div class="col-sm-5">
		<p class="large">{{ getFilteredSelectedItems().length }} {{objectSelectedDescriptor}}</p>
		<div class="clearfix">
			<input [(ngModel)]="selectedItemFilter"
				type="search"
				class="block"
				placeholder="{{'common.search'|i18n}}">
			<div class="pull-left mt-16">
				<button class="btn btn-primary remove-all-btn"
					type="button"
					(click)="deselectAllItems()"
					[disabled]="!allowDeselectItems()">
					{{removeButtonText()}}
				</button>
			</div>
		</div>
		<select multiple
			class="case-mgmt-attributes-list selected-items col-xs-12 mt-16"
			[(ngModel)]="selectedItems">
			<option
				*ngFor="let item of getSortedFilteredSelectedItems()"
				value="{{item[itemsFieldIdentifier]}}">
				{{item.groupName}}
			</option>
		</select>
	</div>
</div>
