import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillGroupBy, DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class TopicOrTermsToFilterService implements IPointToFilter {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly reportModelsService: ReportModelsService,
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: ReportGrouping): DrillFilter | null => {
		let filter;
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);
		const nodeName = point && point[identifier];
		if (_.isUndefined(nodeName)) return null;

		if (groupBy.type === ReportAssetType.TOPICS || groupBy.type === ReportAssetType.TOPIC_LEAF) {
			const modelId = groupBy.name;
			const nodePath = point && point[identifier + ReportConstants.FULL_PATH];

			if (_.isUndefined(nodePath)) {
				return null;
			}

			filter = {
				type: DrillType.TOPIC,
				name: modelId,
				values: [nodePath, nodeName],
			};
		} else {
			let type;
			if (groupBy.name === DrillGroupBy.KEY_TERMS) {
				type = this.groupByToDrillType(point.rootCause_attributeName);
			} else {
				type = this.groupByToDrillType(groupBy.name);
			}
			filter = {
				type,
				name: groupBy.name,
				values: [nodeName],
			};
		}

		return filter;
	}

	toFilterTypes = (parsers: DrillParsers): void => {
		[
			DrillType.TOPIC,
			DrillType.WORD,
			DrillType.HASHTAG,
			DrillType.LC,
		].forEach((type) => {
			parsers[type] = this;
		});
	}

	getRuleString = (
		filter: DrillFilter,
		widget: Widget
	): Promise<string | void> => {
		let result;
		if (filter.type === DrillType.TOPIC) {
			//get node id
			if (filter.values && filter.values.length > 0) {
				const nodeIdPath = filter.values[0];
				const nodeId = parseInt(
					nodeIdPath.substring(nodeIdPath.lastIndexOf('/') + 1),
					10
				);
				return this.reportModelsService
					.getWidgetNodeInfo(widget, nodeId)
					.then((node) => {
						return `${node.modelName}: ${node.path}`;
					})
					.catch((err) => {
						console.log(`Error fetching widget node info: ${err}`);
					});
			}
		} else {
			let attributeName;
			if (filter.name === DrillGroupBy.KEY_TERMS) {
				attributeName = this.locale.getString('widget.keyTerms');
			} else if (filter.type === DrillType.WORD) {
				attributeName = this.locale.getString('widget.words');
			} else if (filter.type === DrillType.HASHTAG) {
				attributeName = this.locale.getString('widget.hashtags');
			} else if (filter.type === DrillType.LC) {
				attributeName = this.locale.getString('widget.assocWords');
			}

			if (attributeName) {
				const value = filter.values[0];
				result = `${attributeName}: ${value}`;
			}
		}

		return Promise.resolve(result);
	}

	groupByToDrillType(groupByName: string): DrillType | undefined {
		switch (groupByName) {
			case DrillGroupBy.WORD:
				return DrillType.WORD;
			case DrillGroupBy.LC:
				return DrillType.LC;
			case DrillGroupBy.HASHTAG:
				return DrillType.HASHTAG;
			case DrillGroupBy.SENTIMENT_3:
			case DrillGroupBy.SENTIMENT_5:
				throw new Error(
					'Sentiment drill should not go here. Probably a legacy widget.'
				);
			default:
				return;
		}
	}
}
