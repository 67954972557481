import { IDirectiveFactory, INgModelController } from 'angular';

// by default uses "itemList" and "initialItem" from parent scope, and "name" as default field

const uniqueName = () => {
	return {
		require: 'ngModel',
		link: (scope, element, attributes, controller: INgModelController) => {
			let config = scope.$eval(attributes.uniqueName);
			let initialItem = config?.initialItem || scope.initialItem || {};
			let nameField = config?.property || 'name';
			let folder = config?.folder || false;

			let namesInList = () => {
				return _.chain(getItems())
					.filter((item) => {
						return item.type
							? folder === item.type.toLowerCase().endsWith('folder')
							: true;
					})
					.map(nameField)
					.filter(item => !!item)
					.value();
			};

			let isUniqueName = (value) => {
				let trimmedName = value.trim();

				return namesInList().indexOf(trimmedName) === -1 || trimmedName === initialItem[nameField];
			};

			controller.$validators.unique = (modelValue, viewValue) => {
				return !viewValue || isUniqueName(viewValue);
			};

			function getItems(): any[] {
				return config?.items || scope.itemList;
			}
		}
	};
};

app.directive('uniqueName', uniqueName as IDirectiveFactory);
