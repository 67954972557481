import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetDeleteAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-delete-action';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';

export class WidgetCreateAction implements DashboardChangeAction {
	private widget: Widget;

	constructor(widget: Widget) {
		this.widget = ObjectUtils.copy(widget);
	}

	reverse(): DashboardChangeAction {
		return new WidgetDeleteAction(this.widget);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		return api.createWidgets(ObjectUtils.copy(this.widget)).then((created) => {
			widgets.push(created);
			DashboardUtils.sortWidgets(widgets);
			if (!_.isUndefined(this.widget.id)) {
				idMapper.addIdMapping(this.widget.id, created.id);
			}
			this.widget.id = created.id; // need to have id for undo action
		});
	}
}
