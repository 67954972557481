<div #dropdown="ngbDropdown" ngbDropdown
	[autoClose]="'outside'"
	[display]="'static'"
	(openChange)="toggleHierarchyDropdown($event)"
	class="dropdown br-actions-dropdown d-flex pl-0 mv-0">
	<button #ngDropdownToggle ngbDropdownToggle
		class="btn btn-icon border-0 kb-focusable"
		(keydown.enter)="onHierarchyToggleKeydown($event)"
		aria-haspopup="true"
		[attr.aria-expanded]="dropdown.isOpen()"
		[attr.aria-label]="getTitle()"
		[attr.title]="getTitle()"
		[disabled]="isLoading"
	>
		<span *ngIf="isLoading" class="q-icon q-icon-spinner rotate-infinite"></span>
		<span *ngIf="!isLoading" class="q-icon q-icon-flow"></span>
	</button>
	<div ngbDropdownMenu  id="hierarchy-selector-menu" class="min-width-2 dropdown-menu pull-right" role="menu">
		<hierarchy-tree-selector *ngIf="!!personalization"
			[personalization]="personalization"
			[showOrganizationContext]="showOrganizationContext"
			(searchClear)="onSearchClear()"
			(nodeChange)="onNodeChange($event)"
		></hierarchy-tree-selector>
	</div>
</div>
