<div class="w-100-percent d-flex align-items-center">
	<select-from-tree class="flex-fill overflow-hidden"
		[appendToBody]="true"
		[disabled]="disabled"
		[ngModel]="selection?.name"
		[hierarchyList]="options"
		(onNodeClick)="select($event.node)">
	</select-from-tree>

	<button *ngIf="!!selection"
		type="button"
		class="btn btn-icon calculation-config ml-8"
		[disabled]="disabled || !selection"
		(click)="openProperties.emit(selection)">
		<span class="q-icon q-icon-cog"></span>
	</button>
</div>


