import * as _ from 'underscore';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output, EventEmitter,
	OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { Pill, PillType } from '@app/modules/pills/pill';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { CxLocaleService } from '@app/core';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ConversationEnrichmentUtils } from './enrichments/conversation-enrichment-utils.service';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ChangeUtils } from '@app/util/change-utils';
import { PillTuningEvent } from '@app/modules/pills/pill-tuning-event';

@Component({
	selector: 'sentence-pills',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<collapsible-pills
			*ngIf="width && pills?.length"
			[pills]="pills"
			[width]="width"
			[allowTuning]="auditMode && !hasProfanity"
			[showAll]="showAll"
			[highlightTrigger]="highlightTrigger"
			(tune)="tunePill($event.pill, $event.event)"
			[useContrastTextColor]="true"
			[inert]="!focusable"
		></collapsible-pills>`,
})
export class SentencePillsComponent implements OnInit, OnChanges {
	@Input() width: number;
	@Input() auditMode: boolean;
	@Input() enabledModels: IReportModel[];
	@Input() topics: SentenceTopic[];
	@Input() predefinedMetrics: Metric[];
	@Input() sentences: ConversationSentence[];
	@Input() enrichmentPills: Pill[];
	@Input() highlightTrigger: number;
	@Input() showAll: boolean;
	@Input() hasProfanity: boolean;
	@Input() focusable: boolean = true;
	@Input() showTopics: boolean = true;
	@Input() showEnrichments: boolean = true;
	@Output() removeTopic = new EventEmitter<string>();
	@Output() tuneEnrichment = new EventEmitter<PillTuningEvent>();

	topicTuningEnabled: boolean;
	pills: Pill[];

	constructor(
		@Inject('security') private security: Security,
		private readonly conversationEnrichmentUtils: ConversationEnrichmentUtils,
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.initPills();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isTopicsChanged(changes) || this.isEnrichmentChanged(changes) || !!changes.enrichmentPills) {
			this.initPills();
			this.ref.detectChanges();
		}
	}

	private initPills(): void {
		this.topicTuningEnabled = this.security.getCurrentMasterAccount().tuningSuggestionsSettings?.topic;
		let enabledModelIds = _.map(this.enabledModels, model => model.id);
		this.pills = this.showTopics
			? this.topics.map(topic => {
				let disabled = this.auditMode && (!_.contains(enabledModelIds, topic.modelId) || this.hasProfanity);
				return {
					name: topic.name,
					type: PillType.TOPIC,
					title: this.getTitle(topic, disabled, this.hasProfanity),
					value: topic,
					disabled,
					additionalClass: this.getPillClass(topic)
				};
			})
			: [];
		if (this.showEnrichments) {
			if (this.enrichmentPills?.length) {
				this.pills.pushAll(this.enrichmentPills);
			} else if (this.sentences && this.predefinedMetrics) {
				this.pills.pushAll(this.conversationEnrichmentUtils.getAllPills(this.sentences, this.predefinedMetrics));
			}
		}
	}

	private isTopicsChanged(changes: SimpleChanges): boolean {
		return !!(changes.topics || changes.enabledModels || changes.auditMode) || ChangeUtils.hasChange(changes.showTopics);
	}

	private isEnrichmentChanged(changes: SimpleChanges): boolean {
		return ChangeUtils.hasChange(changes.sentences) || ChangeUtils.hasChange(changes.showEnrichments);
	}

	private getTitle(topic: SentenceTopic, disabled: boolean, hasProfanity: boolean): string {
		if (disabled) {
			return hasProfanity
				? this.locale.getString('preview.topicSuggestionDisabledByFeedbackScreening')
				: this.locale.getString('preview.topicSuggestionDisabled');
		}

		return this.getFullPath(topic);
	}

	private getPillClass(topic: SentenceTopic): string {
		const modelId = this.getModelIdClass(topic);
		const topicId = this.getTopicIdClass(topic);
		return `${modelId} ${topicId}`;
	}

	getFullPath(topic: SentenceTopic): string {
		return `${topic.path.join(' > ')} > ${topic.name}`;
	}

	getModelIdClass(topic: SentenceTopic): string {
		return `model-${topic.modelId}`;
	}

	getTopicIdClass(topic: SentenceTopic): string {
		return `topic-${topic.id}`;
	}

	tunePill(pill: Pill, event: MouseEvent): void {
		if (pill.type === PillType.TOPIC) {
			this.removeTopic.emit(this.getFullPath(pill.value as SentenceTopic));
		} else {
			this.tuneEnrichment.emit({pill, event});
		}
	}
}
