import { NgModule, ModuleWithProviders } from '@angular/core';

import interop from '@app/util/interop';
import { DowngradeExampleService } from './downgrade-example.service';
import { SessionPreferencesService } from './storage/session-preferences.service';
import { LocalStorageModule } from 'angular-2-local-storage';
import { ConfigService } from './config.service';
import { SessionService } from './sessions/session.service';
import { CxHeadersUtilService } from './http/cx-headers-util.service';

@NgModule({
	imports: [ LocalStorageModule ],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: interop.migrateModule(app)
				.concat(interop.migrateDeps([
					'$log',
					'$http',
					'locale',
					'$location'
				]))
				.concat([
					DowngradeExampleService,
					SessionPreferencesService,
					ConfigService,
					SessionService,
					CxHeadersUtilService
				])
		};
	}
}
