import * as _ from 'underscore';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { CapitalizationSettings } from '../settings.interfaces';


export class CapitalizationSettingsGroup implements ISettingsGroup<CapitalizationSettings> {

	settings: CapitalizationSettings;

	constructor(source: CapitalizationSettings) {
		this.settings = source ? _.pick(source, ['capitalization']) : {} as CapitalizationSettings;
	}

	postProcessSettings(): CapitalizationSettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = {} as CapitalizationSettings;
	}

}
