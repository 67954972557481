import { PipeTransform, Pipe } from '@angular/core';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';

@Pipe({
	name: 'treeSearch'
})
export class TreeSearchPipe implements PipeTransform {
	transform(nodes: TreeNode[], search: string, displayProperty: string, parent?: any): TreeNode[] {
		if (!nodes) return [];

		let result = _.filter(nodes, (node) => {
			if (!search || search.trim().length < 1)
				return true;

			let directMatch = this.checkMatch(node[displayProperty], search);
			let parentMatch = parent && parent._searchMatched;
			if (node.children) // populate only folders to not change attributes
				node._searchMatched = directMatch || parentMatch;
			let childrenMatch = this.transform(node.children as TreeNode[], search, displayProperty).length;

			return directMatch || parentMatch || childrenMatch;
		});
		return result;
	}

	private checkMatch(text: string, search: string): boolean {
		return (text.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0);
	}
}