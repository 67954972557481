import { SigningAlgorithm } from './saml-signinig-algorithms';

export class SamlIdentityProvider {
	id: string;

	enabled: boolean;

	entityId: string;
	aliasName: string;

	authenticationAgeCheckRequired: boolean;
	signatureCheckRequired: boolean;

	authenticationAgeSeconds: number = 3600000;

	createdDate: Date;
	lastModifiedDate: Date;

	metadataFilename: string;
	userIdentity: UserIdentity = new UserIdentity();
	authSettings: OAuthSettings = new OAuthSettings();
	spAliasName: string;

	logoutMechanism: LogoutMechanism = LogoutMechanism.GLOBAL;
	signatureAlgorithm: SigningAlgorithm = SigningAlgorithm.SHA_1;
	linkedMasterAccounts?: string[];

	allowedQueryParams?: string[];
}

export class UserIdentity {
	identifyUserByNameId: boolean;
	sendNameIdPolicyInRequest: boolean;
	allowEnterpriseViewer: boolean;

	identityAttributeName: string = 'emailAddress';
	enterpriseViewer: string = '';

	ldapSearchFilter: LdapSearchFilter = LdapSearchFilter.USER_EMAIL;
}

export class OAuthSettings {
	defaultClientId: string;
}

export enum LdapSearchFilter {
	UNIQUE_ID = 'uniqueId',
	USER_EMAIL = 'userEmail'
}

export enum LogoutMechanism {
	LOCAL = 'local',
	GLOBAL = 'global'
}
