<div class="mb-16">
	<label>{{'widget.url'|i18n}}</label>
	<div>
		<div class="btn-group url-type-selector">
			<cb-push-buttons
				[list]="urlTypes"
				[(selectedValue)]="attribute.urlType">
			</cb-push-buttons>
		</div>
	</div>
</div>

<h3 *ngIf="attribute.urlType === 'image'">{{'widget.imageSettings'|i18n}}</h3>
<div class="mb-16 d-flex l-margin-offset-6" *ngIf="attribute.urlType === 'image'">
	<div>
		<label>{{'widget.tableRowHeight'|i18n}}</label>
		<div>
			<input
				class="width-4"
				[(ngModel)]="attribute.tableRowHeight"
				type="number"
				[valueRequired]
				[strictMin]="1"
				[strictMax]="600">
			<p class="help-block" [i18nParams]="{max:600}" [i18n]="'widget.maximumPixels'"></p>
		</div>
	</div>

	<div>
		<label>{{'widget.scale'|i18n}}</label>
		<div class="labeled-radio">
			<input [(ngModel)]="attribute.scale" type="radio" id="fit" value="fit"><label for="fit">{{'widget.fit'|i18n}}</label>
		</div>
		<div class="labeled-radio">
			<input [(ngModel)]="attribute.scale" type="radio" id="fill" value="fill"><label for="fill">{{'widget.fill'|i18n}}</label>
		</div>
	</div>

	<div>
		<label>{{'widget.verticalAlignment'|i18n}}</label>
		<div class="btn-group">
			<cb-push-buttons
				[list]="verticalAlignOptions"
				[(selectedValue)]="attribute.verticalAlign">
			</cb-push-buttons>
		</div>
	</div>

	<div>
		<label>{{'widget.horizontalAlignment'|i18n}}</label>
		<div class="btn-group">
			<cb-push-buttons
				[list]="horizontalAlignOptions"
				[(selectedValue)]="attribute.horizontalAlign">
			</cb-push-buttons>
		</div>
	</div>
</div>
