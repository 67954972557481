import { StudioAsset } from '@cxstudio/asset-management/studio-asset';

export class ProjectAsset extends StudioAsset {
	contentProviderId: number;
	projectId: number;
	accountId?: number;

	constructor(contentProviderId: number, projectId: number, assetId: number | string) {
		super(assetId);
		this.contentProviderId = contentProviderId;
		this.projectId = projectId;
	}
}
