<div class="panel panel-default qualtrics-embedding-settings">
	<div class="panel-heading" [i18n]="'mAccount.qualtricsEmbeddingHeader'"></div>
	<div class="panel-body clearfix">
		<div  *ngIf="showDashboardToggle" class="d-flex align-items-center mb-16">
			<cb-toggle
				name="qualtricsDashboardEmbeddingSettingsEnabled"
				[(ngModel)]="settings.dashboardEmbeddingEnabled"
				(ngModelChange)="changeSettings($event)"
			></cb-toggle>
			<span class="ml-16" [i18n]="'mAccount.qualtricsDashboardEmbeddingDescription'"></span>
		</div>
		<div *ngIf="showWidgetToggle" class="d-flex align-items-center mb-16">
			<cb-toggle
				name="qualtricsWidgetEmbeddingSettingsEnabled"
				[(ngModel)]="settings.widgetEmbeddingEnabled"
				(ngModelChange)="changeSettings($event)"
			></cb-toggle>
			<span class="ml-16" [i18n]="'mAccount.qualtricsWidgetEmbeddingDescription'"></span>
		</div>
	</div>
</div>
