<widget-box
	[widget]="widget"
	[layout]="layout"
	[menu]="menu"
	[widgetActions]="widgetActions"
	[editable]="true"
	[editMode]="editMode()">
	<widget-content
		class="h-100-percent w-100-percent widget-main-content"
		[view]="widget.visualProperties.visualization"
		[trigger]="trigger"
		[widget]="widget"
		[data]="{}"
		[utils]="utils">
	</widget-content>
</widget-box>
<ng-template #menu let-menuActions>
	<div *ngIf="!widget.multiSelection">
		<li *ngIf="editMode() && !layout.isZoomModeOn()" (click)="edit()">
			<a class="option settings"
				[class.disabled]="!editable()"
				[i18n]="'common.edit'"
				href="javascript:void(0)"
				role="menuitem"></a>
		</li>
		<li *ngIf="!layout.isZoomModeOn()" (click)="refresh()">
			<a class="option refresh"
				[class.disabled]="!editable()"
				[i18n]="'common.refresh'"
				href="javascript:void(0)"
				role="menuitem"></a>
		</li>
		<li *ngIf="editMode() && !layout.isZoomModeOn()" (click)="duplicate()">
			<a class="option copy"
				[class.disabled]="!editable()"
				[i18n]="'common.makeCopy'"
				href="javascript:void(0)"
				role="menuitem"></a>
		</li>
		<copy-submenu *ngIf="editMode() && !layout.isZoomModeOn()"
			[dashboard]="dashboardData.dashboard"
			[disabled]="!editable()"
			label="{{'widget.copyTo' | i18n}}"
			[widgets]="[widget]"
			(targetSelected)="menuActions.closeMenu()">
		</copy-submenu>
		<li *ngIf="editMode()" (click)="widgetActions.emit(WidgetAction.MOVE_TO_TOP)">
			<a class="option push-top" [i18n]="'dashboard.pushToTop'" href="javascript:void(0)" role="menuitem"></a>
		</li>
		<li *ngIf="editMode()" (click)="widgetActions.emit(WidgetAction.MOVE_TO_BOTTOM)">
			<a class="option push-bottom" [i18n]="'dashboard.pushToBottom'" href="javascript:void(0)" role="menuitem"></a>
		</li>
		<li *ngIf="editMode() && !layout.isZoomModeOn()" class="divider" role="separator"></li>
		<li *ngIf="editMode() && !layout.isZoomModeOn()"
			(click)="widgetActions.emit(WidgetAction.DELETE)"
			class="border-b-radius-4 overflow-hidden">
			<a class="option danger-menu-item delete d-flex justify-between" href="javascript:void(0)" role="menuitem">
				<span [i18n]="'dashboard.delete'"></span>
				<i class="q-icon q-icon-trash"></i>
			</a>
		</li>
	</div>
	<bulk-widget-menu *ngIf="widget.multiSelection"
		[dashboard]="dashboardData.dashboard"
		(closeMenu)="menuActions.closeMenu()"
		(bulkAction)="bulkAction.emit($event)">
	</bulk-widget-menu>
</ng-template>
