import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SampledAuditsGridDefinition } from './sampled-audits-grid-definition.service';

@NgModule({
	imports: [
		SharedModule
	],
	providers: [
		SampledAuditsGridDefinition
	],
})
export class SampledAuditsModule {}
