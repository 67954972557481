
<label id="embedCopyLabel">{{'widgetSettings.embedCopyText'|i18n}}</label>
<div class="input-group align-items-stretch">
	<input
		disabled
		type="text"
		class="form-control _t-embed-link cursor-text"
		aria-labelledby="embedCopyLabel"
		[ngModel]="getEmbedWidgetCode()">
	<button class="btn-secondary q-icon-clipboard btn-icon"
		ngxClipboard
		[cbContent]="getEmbedWidgetCode()"
		(cbOnSuccess)="onCopySuccess()"
		[attr.title]="'common.copyToClipboard' | i18n">
	</button>
</div>

<checkbox-button *ngIf="showEnterpriseViewersOption()"
	class="_t-embed-checkbox"
	buttonClass="btn-sm"
	label="{{'widgetSettings.embedEnterpriseViewersCheckboxText'|i18n}}"
	[(ngModel)]="embedConfig.enterpriseViewersAllowed"
></checkbox-button>

<checkbox-button
	class="_t-dashboard-link-checkbox"
	buttonClass="btn-sm"
	label="{{'widgetSettings.embedDashboardLink'|i18n}}"
	[(ngModel)]="embedConfig.showDashboardLink"
></checkbox-button>
