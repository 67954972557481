<li (click)="sharingFeedbackSelection.clearSelection()"
		*ngIf="sharingFeedbackSelection.hasAnyCuratedItems()"
		class="hide-in-audit"
		(mousedown)="$event.stopPropagation()">
		<a role="menuitem" href="javascript:void(0)" class="option">{{'docExplorer.clearFeedbackSelection'|i18n}}</a>
</li>
<li class="divider hide-in-audit" role="separator"
	*ngIf="sharingFeedbackSelection.hasAnyCuratedItems()"></li>
<dropdown-menu-group
	[amplitudeCategoryProperties]="{category: 'Viewing Preferences'}"
	role="group"
	label="{{'preview.viewingPrefs'|i18n}}">
	<dropdown-toggle-option
		[amplitudeEvent]="getAmplitudeEvent('Show Sentiment')"
		class="hide-in-audit"
		data-testid="show-sentiment"
		[(value)]="preferences.settings.showSentiment"
		(valueChange)="preferences.storeUpdates()"
		[small]="true"
		label="{{'preview.displayEnrichment'|i18n}}"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
	<dropdown-toggle-option
		[amplitudeEvent]="getAmplitudeEvent('Single Verbatim Mode')"
		class="hide-in-audit"
		data-testid="verbatim-mode"
		[(value)]="preferences.settings.singleVerbatimMode"
		(valueChange)="preferences.storeUpdates()"
		[small]="true"
		label="{{'docExplorer.verbatimOnly'|i18n}}"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
	<dropdown-toggle-option *ngIf="isConversation()"
		[amplitudeEvent]="getAmplitudeEvent('Show Sentences')"
		data-testid="show-sentences"
		[(value)]="preferences.settings.showSentences"
		(valueChange)="documentManager.conversationMethods.toggleShowSentences()"
		[small]="true"
		label="{{'preview.menuShowSentences'|i18n}}"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
	<dropdown-toggle-option
		[amplitudeEvent]="getAmplitudeEvent('Show Topics')"
		data-testid="show-topics"
		[(value)]="preferences.settings.showTopics"
		(valueChange)="documentManager.toggleShowTopics()"
		[small]="true"
		label="{{'preview.menuShowTopics'|i18n}}"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
	<dropdown-toggle-option
		[amplitudeEvent]="getAmplitudeEvent('Show Leaf Only')"
		[(value)]="preferences.settings.leafOnly"
		(valueChange)="documentManager.toggleShowTopics()"
		[small]="true"
		label="{{'preview.menuShowTopicLeaves'|i18n}}"
		[disabled]="!preferences.settings.showTopics"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
	<dropdown-toggle-option
		[amplitudeEvent]="getAmplitudeEvent('Show Expand Topics')"
		[(value)]="preferences.settings.expandTopics"
		(valueChange)="documentManager.toggleExpandTopics()"
		[small]="true"
		label="{{'preview.menuExpandTopics'|i18n}}"
		[disabled]="!preferences.settings.showTopics"
		groupLabel="{{'preview.viewingPrefs'|i18n}}">
	</dropdown-toggle-option>
</dropdown-menu-group>

<playback-menu-item *ngIf="isConversation() && documentManager.conversationMethods.isAudioPlayable()"
	role="group"
	class="hide-in-audit"
	[documentManager]="documentManager"
	[preferences]="preferences">
</playback-menu-item>

<dropdown-menu-group
	[amplitudeCategoryProperties]="{category: 'Pane Preferences'}"
	role="group"
	label="{{'preview.panePrefs'|i18n}}">
	<dropdown-collapsible-toggle-option class="hide-in-audit"
		data-testid="sentence-pane-toggle"
		[(value)]="preferences.settings.visiblePanes.sentence"
		[small]="true"
		[amplitudeEvent]="getAmplitudeEvent('Results Pane')"
		label="{{'docExplorer.toggleResultsPane'|i18n}}"
		groupLabel="{{'preview.panePrefs'|i18n}}">
		<dropdown-toggle-option class="hide-in-audit"
			[amplitudeEvent]="getAmplitudeEvent('Show Enrichments')"
			data-testid="show-sentences-sentiment"
			[(value)]="preferences.settings.sentencePane.showSentiment"
			(valueChange)="preferences.storeUpdates()"
			label="{{'preview.displayEnrichment'|i18n}}"
			[small]="true"
			groupLabel="{{'docExplorer.toggleResultsPane'|i18n}}">
		</dropdown-toggle-option>
		<dropdown-toggle-option class="hide-in-audit"
			[amplitudeEvent]="getAmplitudeEvent('Display Source Badges')"
			[(value)]="preferences.settings.sentencePane.showSource"
			(valueChange)="preferences.storeUpdates()"
			label="{{'preview.displaySource'|i18n}}"
			[small]="true"
			groupLabel="{{'docExplorer.toggleResultsPane'|i18n}}">
		</dropdown-toggle-option>
	</dropdown-collapsible-toggle-option>
	<dropdown-collapsible-toggle-option class="hide-in-audit"
		data-testid="context-pane-toggle"
		[(value)]="preferences.settings.visiblePanes.context"
		label="{{'docExplorer.toggleContextPane'|i18n}}"
		[small]="true"
		[amplitudeEvent]="getAmplitudeEvent('Context Pane')"
		groupLabel="{{'preview.panePrefs'|i18n}}">
		<dropdown-toggle-option class="hide-in-audit"
			[amplitudeEvent]="getAmplitudeEvent('Show Empty Attributes')"
			[(value)]="preferences.settings.showEmptyAttributes"
			(valueChange)="preferences.storeUpdates()"
			label="{{'docExplorer.showEmptyAttributes'|i18n}}"
			[small]="true"
			groupLabel="{{'docExplorer.toggleContextPane'|i18n}}">
		</dropdown-toggle-option>
		<dropdown-toggle-option *ngFor="let toggle of contextSectionToggles"
			[amplitudeEvent]="getAmplitudeEvent('Context Pane' + toggle.key)"
			class="hide-in-audit"
			[(value)]="panels[toggle.key].visible"
			(valueChange)="toggle.callback()"
			label="{{('preview.' + toggle.key)|i18n}}"
			[small]="true"
			groupLabel="{{'docExplorer.toggleContextPane'|i18n}}">
		</dropdown-toggle-option>
	</dropdown-collapsible-toggle-option>
</dropdown-menu-group>
