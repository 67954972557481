import { Inject, Injectable } from '@angular/core';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import {
	UsersGroupsApiService
} from '@cxstudio/services/data-services/users-groups-api.service';
import Group from '@cxstudio/user-administration/groups/Group';
import {
	GroupQueryParams
} from '@cxstudio/user-administration/groups/group-query-params';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { BulkPermissionsComponent } from '@app/modules/user-bulk/bulk-permissions/bulk-permissions.component';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class GroupActionsService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly userModificationService: UserModificationApiService,
		@Inject('usersGroupsApiService') private readonly usersGroupsApiService: UsersGroupsApiService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService
	) {}

	updatePermissionsInBulk(groups: Group[], params: GroupQueryParams): Promise<void> {
		const bodyText = this.locale.getString('permission.bulkGroupUpdatePermissionsBody');

		return this.cxDialogService.openDialog(BulkPermissionsComponent, {bodyText}, {
			size: ModalSize.MEDIUM
		}).result.then(
			result => this.usersGroupsApiService.updateGroupPermissionsInBulk(this.extractGroupIds(groups), result, params),
			() => {}
		);
	}

	updateMembershipInBulk(groups: Group[], params: GroupQueryParams): Promise<void> {
		return PromiseUtils.wrap(this.cbDialogService.showGroupsUserMembership()).then((updatedUsers) => {
			return this.userModificationService.callBulkUserMembershipUpdateAPI(
				this.extractGroupIds(groups),
				updatedUsers,
				params
			);
		});
	}

	removeGroupsInBulk(groups: Group[], masterAccountName: string, params: GroupQueryParams): Promise<void> {
		const confirmationDialog: NgbModalRef = this.cxDialogService.dangerWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('group.removeGroupsFromMAWarning', { groupCount: groups.length, masterAccountName }),
			this.locale.getString('common.remove'),
			this.locale.getString('common.cancel'));

		return confirmationDialog.result.then(() => {
			return this.usersGroupsApiService.removeGroupsInBulk(this.extractGroupIds(groups), params);
		});
	}

	private extractGroupIds = (groups: Group[]): number[] => groups.map((group: Group) => group.groupId);
}

app.service('groupActionsService', downgradeInjectable(GroupActionsService));
