
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { LoginMessage } from './login-message';

@Component({
	selector: 'login-banner',
	template: `
	<div *ngIf="!!message && !hasLink && !isError">
		<div class="sign-in-message info">
			<div class="d-flex align-items-center" aria-live="polite" role="status">
				<span class="q-icon q-icon-info mr-8" aria-hidden="true"></span>
				<span class="info-message-text" [innerHTML]="getText()"></span>
			</div>
		</div>
	</div>

	<a *ngIf="!!message && hasLink && !isError" href="{{ message.url }}" class="message-link">
		<div class="sign-in-message info">
			<div class="d-flex align-items-center" aria-live="polite" role="status">
				<span class="q-icon q-icon-info mr-8" aria-hidden="true"></span>
				<span class="info-message-text" [innerHTML]="getText()"></span>
			</div>
		</div>
	</a>

	<div *ngIf="!!message && !hasLink && isError">
		<div data-testid="login-error-msg" class="sign-in-message bg-danger-700">
			<div class="d-flex align-items-center" aria-live="assertive" role="alert">
				<span class="q-icon q-icon-accessdenied mr-8" aria-hidden="true"></span>
				<span class="info-message-text" [innerHTML]="getText()"></span>
			</div>
		</div>
	</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBannerComponent {
	@Input() message: LoginMessage;
	@Input() isError: boolean;
	@Input() hasLink: boolean;

	getText = () => {
		return this.message?.message || '';
	}
}

app.directive('loginBanner', downgradeComponent({component: LoginBannerComponent}) as angular.IDirectiveFactory);
