import { LanguageLoaderService } from '@app/core/i18n/language-loader.service';
import { EnvironmentService } from './services/environment-service';

/**
 * Root application controller.
 * !!! All resource initialization, like locales, should happen here.
 * !!! Make sure it does not inject any application logic services.
 * !!! WARNING: don't change its name, as it can be inconsistent with index.html
 */
export class ApplicationInitializationCtrl implements ng.IController {
	constructor(
		private $scope: ng.IScope,
		private readonly environmentService: EnvironmentService,
		private readonly languageLoader: LanguageLoaderService,
	) {}

	$onInit(): void {
		this.initLocale();

		if (this.environmentService.isUnderTest()) {
			angular.element('body').addClass('e2e-testing');
		}

		this.$scope.isApplicationInitialized = () => {
			return this.environmentService.isApplicationInitialized();
		};
	}

	private initLocale(): void {
		if (!this.environmentService.canRunApplication())
			return;
		this.languageLoader.initialize().then(() => {
			this.environmentService.setApplicationInitialized(true);
		});
	}
}

app.controller('ApplicationInitializationController', ApplicationInitializationCtrl);
