import * as _ from 'underscore';

import { ColorPalettesApi } from '@app/modules/account-administration/appearance/color-palettes/api/color-palettes-api.service';
import { IColorFunction } from './../utils/color-utils.service';
import { WidgetColorPalette } from './entities/widget-color-palette';
import { ColorPaletteConstants, ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ApplicationThemeService } from '@app/core/application-theme.service';

export class ColorPalettes {

	private readonly PALETTE_REGEX = /^_palette_\d+$/;
	private systemPalettes = [
		ColorPaletteNames.PALETTE_1,
		ColorPaletteNames.PALETTE_2,
		ColorPaletteNames.PALETTE_3,
		ColorPaletteNames.PROVIDER_PALETTE,
		ColorPaletteNames.STUDIO_PALETTE
	];

	constructor(
		private locale: ILocale,
		private readonly colorPalettesApi: ColorPalettesApi,
		private readonly reportProjectContextService: ReportProjectContextService,
		private readonly applicationThemeService: ApplicationThemeService,
	) {}

	getPredefinedPaletteArray = (type: string): string[] => {
		switch (type) {
			case ColorPaletteNames.PALETTE_1: return ColorPaletteConstants.getPalette1();
			case ColorPaletteNames.PALETTE_2: return ColorPaletteConstants.getPalette2();
			case ColorPaletteNames.PALETTE_3: return ColorPaletteConstants.getPalette3();
			case ColorPaletteNames.STUDIO_PALETTE:
				return ColorPaletteConstants.getStudioPalette(this.applicationThemeService.isShowingDashboardDarkTheme());
			default: return [];
		}
	}

	// for 1.0
	getPredefinedPaletteFunction = (name: string): IColorFunction => {
		return this.getPaletteFunction(name, this.getPredefinedPaletteArray(name));
	}

	getPaletteFunction = (name: string, colors: string[]): IColorFunction => {
		let palette = {name, colors} as WidgetColorPalette;
		return this.wrapPaletteFunction(palette, (item, index) => {
			if (!colors)
				return null;
			return colors[index % colors.length];
		});
	}

	private wrapPaletteFunction(palette: WidgetColorPalette, func: IColorFunction): IColorFunction {
		func.palette = palette;
		return func;
	}

	isPaletteColor = (name: string): boolean => {
		return this.PALETTE_REGEX.test(name) || this.isSystemPalette(name);
	}

	isSystemPalette = (name: string): boolean => {
		return _.contains(this.systemPalettes, name);
	}

	getWidgetPalettes = (): ng.IPromise<WidgetColorPalette[]> => {
		return PromiseUtils.old(this.colorPalettesApi.getWidgetPalettes()).then(
			(palettes) => {
				_.chain(palettes)
					.filter(palette => palette.system)
					.each(palette => palette.displayName = this.locale.getString(palette.displayName));

				return palettes;
			}
		);
	}

	getDefaultPalette = (): ng.IPromise<WidgetColorPalette> => {
		return this.getWidgetPalettes().then(palettes => {
			return _.findWhere(palettes, { defaultPalette: true });
		});
	}

	getWidgetDesignerPalette = (widgetProperties: WidgetProperties): ng.IPromise<string[]> => {
		return PromiseUtils.old(this.reportProjectContextService.getWidgetDesignerPalette(widgetProperties));
	}

	getDashboardDesignerPalette = (dashboard: Dashboard): ng.IPromise<string[]> => {
		return PromiseUtils.old(this.reportProjectContextService.getDashboardDesignerPalette(dashboard));
	}
}

app.service('colorPalettes', ColorPalettes);
