import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class NotificationsApiService {
	private readonly BASE_URL = 'rest/notifications';

	constructor(
		private readonly http: CxHttpService
	) {}

	getMessages = (messageType, count: number): Promise<any> => {
		return this.http.get(`${this.BASE_URL}/state/${messageType}/${count}`);
	}

	getNewMessages = (messageType, count: number): Promise<any> => {
		return this.http.get(`${this.BASE_URL}/new/${messageType}/${count}`, {ignoreConnectionErrors: true});
	}

	markRead = (messageId: number): Promise<any> => {
		return this.http.post(`${this.BASE_URL}/mark/${messageId}`);
	}

	markMultipleRead = (messages): Promise<any> => {
		return this.http.post(`${this.BASE_URL}/mark`, messages);
	}

	markAllRead = (messageType): Promise<any> => {
		return this.http.post(`${this.BASE_URL}/markAll/${messageType}`);
	}

	getAlertByNotificationId = (notificationId: number): Promise<any> => {
		return this.http.get(`${this.BASE_URL}/${notificationId}/alert_result`);
	}
}

app.service('notificationsApiService', downgradeInjectable(NotificationsApiService));
