export class ScorecardRebuttedDocument {
	modelId: number;
	documentId: number;
	rebuttedNodes: ScorecardRebuttedNode[];
}

export class ScorecardRebuttedNode {
	nodeId: number;
	present: boolean;
	children: ScorecardRebuttedNode[];
}