<div (keydown.escape)="closeComparisonConfigurationModal($event)">
	<modal-header
		(cancel)="cancel()"
		modalTitle="{{input.displayName}}: {{'widget.comparisonMetricConfig' | i18n}}">
	</modal-header>
	<div id="settings" class='modal-body'>
		<collapsing-panel [collapsed]="false">
			<panel-heading>
				<b>{{'widget.headerProps'|i18n}}</b>
			</panel-heading>
			<panel-body>
				<label class="mb-8" for="comparison-name">{{'widget.comparisonLabel'|i18n}}</label>
				<div class="mb-8">
					<input
						id="comparison-name"
						type="text"
						class="w-100-percent"
						placeholder="{{'widget.comparisonLabel'|i18n}}"
						maxlength="30"
						[(ngModel)]="comparison.label"
						[debounce]="500">
				</div>
				<div *ngIf="popMetricOptions.length && comparison.calculation">
					<label class="mb-8">{{'widget.calculationOptions'|i18n}}</label>
					<div class="btn-group">
						<button *ngFor="let popOption of popMetricOptions"
							class="btn btn-secondary btn-icon"
							[title]="getCalcOptionTitle(popOption)"
							[ngClass]="popOption.cssClass"
							[class.btn-selected]="isCalcOptionSelected(popOption)"
							(click)="selectCalcOption(popOption)">{{popOption.symbol}}
						</button>
					</div>
				</div>
			</panel-body>
		</collapsing-panel>
		<collapsing-panel *ngIf="showNumberFormatPanel()">
			<panel-heading class="font-bold">{{'widget.numericOptions'|i18n}}</panel-heading>
			<panel-body>
				<number-format
					[formatObject]="comparison.format"
					[(inheritFormatFromMetric)]="comparison.inheritFormatFromMetric"
				></number-format>
			</panel-body>
		</collapsing-panel>
	</div>
	<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
		saveText="{{'common.update' | i18n}}"
	></save-modal-footer>
</div>
