import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { ContentProviderId, AccountId, ProjectId } from '@cxstudio/generic-types';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { Subject } from 'rxjs';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { NarrativeSettingsListActions } from './narrative-settings-list-actions.service';
import { NarrativeSettingsContextMenu } from './narrative-settings-list-menu.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';

export class NarrativeSettingEntry implements IProjectSelection {
	id: number;
	contentProviderId: ContentProviderId;
	accountId: AccountId;
	projectId: ProjectId;
	projectName: string;
	workspaceProject: WorkspaceProject;
	attributeName: string;
	attributeDisplayName: string;
	name: string;
	settings: SpineSettings;
	modifiedDate?: Date;
	enabled: boolean;
}

@Component({
	selector: 'narrative-settings-list',
	templateUrl: './narrative-settings-list.component.html',
	providers: [NarrativeSettingsContextMenu, NarrativeSettingsListActions],
})
export class NarrativeSettingsListComponent implements OnInit {
	loading: Promise<any>;
	settingsArray: NarrativeSettingEntry[] = [];
	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.AUTOMATED_NARRATIVE;
	contextMenuUtils: NarrativeSettingsContextMenu;
	actionsService: NarrativeSettingsListActions;

	projectProperties: IProjectPreselection;
	projects?: Project[];
	showErrorsForCP: string[];

	clearDefaultsSubject: Subject<void> = new Subject<void>();
	changedItems: NarrativeSettingEntry[];
	isWorkspaceEnabled: boolean;
	workspaceProject: WorkspaceProject;

	constructor(
		private settingsContextMenu: NarrativeSettingsContextMenu,
		private settingsListActions: NarrativeSettingsListActions,
		private ref: ChangeDetectorRef,
		private readonly narrativeSettingsApi: NarrativeSettingsApi,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree,
		private readonly betaFeaturesService: BetaFeaturesService) { }

	ngOnInit(): void {

		this.gridOptions = {
			onClick: this.onClick
		};
		this.projectProperties = {};
		this.contextMenuUtils = this.settingsContextMenu;
		this.actionsService = this.settingsListActions;
		this.actionsService.onChange.subscribe(this.loadSettings);
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	private onClick = (event, object: NarrativeSettingEntry): void => {
		if (this.gridUtils.isNameClick(event)) {
			this.actionsService.edit(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'narratives', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.toggle(object);
		}
	}

	loadSettings = (): void => {
		if (this.projectProperties?.project) {
			this.loading = this.narrativeSettingsApi.getAccountNarrativeSettings(this.projectProperties.cbContentProvider,
				this.projectProperties.cbAccount)
				.then(customSettingsList => {
					this.settingsArray = customSettingsList;
					this.changedItems = customSettingsList;
				});
		}
	}

	createSettings(): void {
		let newSettings = new NarrativeSettingEntry();
		newSettings.contentProviderId = this.projectProperties.cbContentProvider;
		newSettings.accountId = this.projectProperties.cbAccount;
		newSettings.projectId = this.projectProperties.project;
		newSettings.projectName = this.projectProperties.projectName;
		newSettings.workspaceProject = this.workspaceProject;
		newSettings.enabled = true;
		this.actionsService.edit(newSettings);
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading = loadingPromise;
		this.ref.markForCheck();
	}

	onProjectSelectionChange = (projectSelection: IProjectPreselection): void => {
		this.projectProperties.cbContentProvider = projectSelection.cbContentProvider;
		this.projectProperties.cbAccount = projectSelection.cbAccount;
		this.projectProperties.project = projectSelection.project;
		this.projectProperties.projectName = projectSelection.projectName;
		if (this.projectProperties.cbAccount > -1) {
			this.loadSettings();
		}
	}

	onWorkspaceChange = (workspace: Unit): void => {
		if (workspace) {
			this.projectProperties.cbContentProvider = workspace.contentProviderId;
			this.projectProperties.cbAccount = workspace.accountId;
		} else {
			this.projectProperties.cbContentProvider = -1;
			this.projectProperties.cbAccount = -1;
		}
	}

	onProjectChange = (workspaceProject: WorkspaceProjectData): void => {
		this.workspaceProject = workspaceProject;
		this.projectProperties.project = workspaceProject.projectId;
		this.projectProperties.projectName = workspaceProject.projectName;
		if (this.projectProperties.cbAccount > -1) {
			this.loadSettings();
		}
	}

	disableCreation = (): boolean => {
		if (this.projectProperties?.project && this.projectProperties.projectName) {
			return this.settingsExist();
		}
		return true;
	}

	settingsExist = (): boolean => {
		return _.some(this.settingsArray, (settingsEntry: any) => {
			return settingsEntry.projectId === this.projectProperties.project;
		});
	}

	onCpErrorsChange = (errors: string[]) => {
		this.showErrorsForCP = errors;
	}
}

app.directive('narrativeSettingsList',
	downgradeComponent({component: NarrativeSettingsListComponent}) as angular.IDirectiveFactory);
