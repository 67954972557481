import * as _ from 'underscore';

export enum TreeSelectionStrategy {
	EVERYTHING = 'EVERYTHING',
	NONE = 'NONE',
	SUBSET = 'SUBSET',
}

export interface ITreeSelectionNode {
	id?: number;
	rootNodeId?: number;
	name?: string;
	path?: string;
	idPath?: string;
	modelId?: number;
}

export interface ITreeSelection {
	strategy: TreeSelectionStrategy;
	nodes: ITreeSelectionNode[];
}

export class TreeSelectionBuilder {

	static everything(): ITreeSelection {
		return {
			strategy: TreeSelectionStrategy.EVERYTHING,
			nodes: []
		};
	}

	static none(): ITreeSelection {
		return {
			strategy: TreeSelectionStrategy.NONE,
			nodes: []
		};
	}

	static subset(nodes): ITreeSelection {
		if (!nodes || nodes.length === 0)
			return TreeSelectionBuilder.none();

		return {
			strategy: TreeSelectionStrategy.SUBSET,
			nodes
		};
	}

	static copy(from): ITreeSelection {
		return {
			strategy: from.strategy,
			nodes: from.nodes
		};
	}
}