<div>
	<div class="grid-list-tools">
		<div class="tools">
			<div class="grid-filters d-block">
				<label class="font-bold mb-0 input-group" for="workplace-search">
					{{'administration.workspaceSearchPlaceholder'|i18n}}
				</label>
				<div class="input-group">
					<input
						type="text"
						class="grid-search-bar d-block"
						id="workplace-search"
						attr.aria-label="{{'administration.workspaceSearchPlaceholder'|i18n}}"
						[disabled]="isSearchDisabled()"
						[(ngModel)]="searchText"
						[debounce]="300">
					<simple-dropdown
						class="border-0"
						[attr.aria-label]="'common.searchBy'|i18n"
						[options]="searchOptions"
						[(value)]="searchMode"
						(onChange)="onSearchChange()">
					</simple-dropdown>
				</div>
			</div>
			<div class="grid-buttons">
				<button
					class="btn btn-primary br-btn-add-work-space"
					*ngIf="isSysAdmin"
					(click)="createWorkspace()">
					{{'administration.createWorkspace'|i18n}}
				</button>
			</div>
		</div>
	</div>

	<div [ngBusy]="loading">
		<item-grid
			class="br-grid"
			[treeData]="filteredWorkspaces"
			[gridOptions]="gridOptions"
			[gridType]="gridType"
			[gridFilter]="searchText"
			[gridChange]="changedItems"
			[pagingOptions]="pagingOptions"
		></item-grid>
		<pagination [hidden]="!(pagination.totalItems > pagination.pageSize)"
			[pagination]="pagination"
			[pullRight]="true"
			(paginationChange)="pageChanged($event)"
		></pagination>
	</div>

</div>
