import * as _ from 'underscore';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { AnalyticFeedbackSelectionUtils } from '@cxstudio/reports/preview/analytic-feedback-selection-utils.service';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { CxLocaleService } from '@app/core';
import { Inject, Injectable } from '@angular/core';
import { ObjectUtils } from '@app/util/object-utils';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { PreviewExportContextType } from '@cxstudio/reports/entities/preview-export-context-types.class.';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CuratedItemType } from '@cxstudio/reports/preview/curated-item';
import { PromiseUtils } from '@app/util/promise-utils';
import { PreviewExportApiService } from './preview-export-api.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { IExportOptions, ReportExportUtilsService } from './report-export-utils.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { UserObjectsService } from '@cxstudio/services/user-objects.service';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { ExportBuilder } from '@cxstudio/reports/utils/export/export-builder.class';
import moment from 'moment';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { PreviewHelper } from '@cxstudio/reports/preview/preview-helper-service';
import { ANPreviewExportColumn } from '@cxstudio/reports/preview/export/an-preview-export-column.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

export interface PreviewExportColumnInfo {
	name: string;
	field: string;
	context?: boolean;
}

export interface PreviewExportHeaderData {
	header: string;
	columns: PreviewExportColumnInfo[];
}

@Injectable({
	providedIn: 'root'
})
export class PreviewExportService {

	EXPORT_SENTENCES_LIMIT: number = 5000;
	EXPORT_VERBATIM_LIMIT: number = 2000;
	EXPORT_DOCUMENT_LIMIT: number = 500;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly previewExportApi: PreviewExportApiService,
		private readonly reportExportUtils: ReportExportUtilsService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly cxDialogService: CxDialogService,
		@Inject('ANPreviewExportHelper') private ANPreviewExportHelper,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		@Inject('previewHelper') private previewHelper: PreviewHelper,
		@Inject('userObjectsService') private userObjectsService: UserObjectsService,
		@Inject('customFilterService') private customFilterService: CustomFilterService,
		@Inject('reportDataApiService') private reportDataApiService: ReportDataApiService
	) {	}

	getPreviewExport(widget: PreviewWidget, docExplorer?: boolean, leafOnly?: boolean): Promise<any> {
		let widgetSettings = this.getWidgetSettings(widget, docExplorer, leafOnly);
		return PromiseUtils.wrap(this.reportDataApiService.getReportData(widgetSettings));
	}

	useScheduledExport(widget: PreviewWidget): boolean {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.IMPROVED_FEEDBACK_EXPORT)) {
			return false;
		}

		let totalCount: number = this.getWidgetTotalCount(widget);
		let contextTypes = this.getContextType(widget);
		return totalCount > this.getPageExportLimit(contextTypes);
	}

	schedulePreviewDataExport(
		widget: PreviewWidget,
		widgetMetadata: WidgetFiltersMetadata,
		viewFilterChanged: boolean,
		leafOnly?: boolean
	): Promise<any> {
		return this.getPreviewExportHeaderData(widget, widgetMetadata, viewFilterChanged).then(headerData => {
			let previewExportRequest = {
				widget: this.getWidgetSettings(widget, false, leafOnly),
				totalCount: this.getWidgetTotalCount(widget),
				header: headerData.header,
				columns: headerData.columns
			};

			return this.previewExportApi.schedulePreviewExportData(previewExportRequest)
				.then(this.showPreviewExportLimitNotification);
		});
	}

	private showPreviewExportLimitNotification = (): Promise<void> => {
		return this.cxDialogService.notify(
				this.locale.getString('preview.previewExportHeader'),
				this.locale.getString('preview.previewExportMessage')).result;
	}

	private getWidgetTotalCount(widget: PreviewWidget): number {
		let widgetData: ReportDataObject = this.getWidgetData(widget);
		let totalInfo = widgetData?.total || { volume: 0, entitiesVolume: 0 };

		return this.previewHelper.isDocumentPreview(widget) && !widget.visualProperties.sentencePaneEnabled
				? totalInfo.volume
				: totalInfo.entitiesVolume;
	}

	private getWidgetData(widget: PreviewWidget): ReportDataObject {
		let widgetCache = this.currentWidgets.getWidgetsCache(widget.containerId);
		return widgetCache.getData(widget.id);
	}

	private getPreviewExportHeaderData(
		widget: PreviewWidget,
		widgetMetadata: WidgetFiltersMetadata,
		viewFilterChanged: boolean
	): Promise<PreviewExportHeaderData> {
		let widgetSettings = ObjectUtils.copy(widget);
		let exportOptions: IExportOptions = { hasChanges: viewFilterChanged };
		let widgetData: ReportDataObject = this.getWidgetData(widget);

		return PromiseUtils.wrap(this.reportExportUtils.prepareExportAssets(widgetSettings, widgetData, exportOptions)).then((allOptions) => {
			this.customFilterService.updateAppliedFilters(allOptions.options.newFilters, widgetMetadata.appliedFilters);
			return PromiseUtils.wrap(
					this.exportUtils.getWidgetHeaderForExport(widgetSettings, widgetData, allOptions, widgetMetadata)).then(header => {
				return this.getPreviewExportColumns(widget, widgetData, allOptions).then(columns => {
					return { header, columns };
				});
			});
		});
	}

	private getPreviewExportColumns(widget: PreviewWidget, data: ReportDataObject, allOptions): Promise<PreviewExportColumnInfo[]> {
		return PromiseUtils.wrap(this.userObjectsService.getHiddenAttributesAndModels(widget, false)).then(hiddenObjects => {
			let widgetCopy = ObjectUtils.copy(widget);
			let exportContext = new ExportBuilder([]);
			let projectTimezone = (allOptions.options && allOptions.options.projectTimezone) || moment.tz.guess();

			let exportHelper = new this.ANPreviewExportHelper(widgetCopy, data, exportContext, projectTimezone, allOptions, hiddenObjects);
			return _.map(exportHelper.getRawColumns(false), column => {
				return {
					field: column.field,
					name: column.name,
					context: this.isContextColumn(column)
				} as PreviewExportColumnInfo;
			});
		});
	}

	private isContextColumn(column: ANPreviewExportColumn): boolean {
		return column.name === this.locale.getString('preview.sentencesWithContext');
	}

	private getWidgetSettings(widget: PreviewWidget, docExplorer?: boolean, leafOnly?: boolean) {
		let copiedWidget: PreviewWidget = ObjectUtils.copy(widget);

		this.populateBasicSettings(copiedWidget, docExplorer, leafOnly);
		this.populateDrillFilters(copiedWidget);

		if (copiedWidget.properties.page) {
			this.popultatePageLimits(copiedWidget);
		}

		return copiedWidget;
	}

	private popultatePageLimits(widget: PreviewWidget): void {
		let contextTypes = this.getContextType(widget);
		let limit = this.getPageExportLimit(contextTypes);

		widget.properties.page.start = 0;
		widget.properties.page.limit = limit;
		widget.properties.page.lookAheadLimit = limit;
	}

	private populateBasicSettings(widget: PreviewWidget, isDocExplorer?: boolean, leafOnly?: boolean): void {
		widget.properties.documentExplorer = isDocExplorer;
		widget.properties.leafOnly = leafOnly;
		widget.properties.widgetType = this.getExportType(widget);
		widget.properties.contextType = this.getContextType(widget);
	}

	private populateDrillFilters(widget: PreviewWidget): void {
		if (widget.properties.analyticFeedbackSelection && widget.properties.analyticFeedbackSelection.items) {
			widget.properties.drillFilters = widget.properties.drillFilters || [];

			let type: CuratedItemType = AnalyticFeedbackSelectionUtils.getCuratedItemType(widget);
			let filterName: string = this.locale.getString('preview.feedbackSelection');

			let drillFilter = AnalyticFeedbackSelectionUtils.getDrillFilter(widget, type, filterName);
			if (drillFilter) {
				widget.properties.drillFilters.push(drillFilter);
			}
		}
	}

	private getPageExportLimit(contextTypes: PreviewExportContextType[]): number {
		let limit = this.EXPORT_DOCUMENT_LIMIT;

		if (contextTypes.contains(PreviewExportContextType.VERBATIM_TEXT)) {
			limit = this.EXPORT_VERBATIM_LIMIT;
		}

		if (contextTypes.contains(PreviewExportContextType.SENTENCE_TEXT)) {
			limit = this.EXPORT_SENTENCES_LIMIT;
		}

		return limit;
	}

	private getContextType(widget: PreviewWidget): PreviewExportContextType[] {
		let contextTypes = [];
		let properties = widget.properties;
		let prefs = widget.visualProperties && widget.visualProperties.preferences;
		let isVerbatimMode = prefs && prefs.settings && prefs.settings.singleVerbatimMode;
		let visualProperties = widget.visualProperties;

		switch (properties.previewMode) {
			case (PreviewMode.DOCUMENT): {
				contextTypes.push(isVerbatimMode ?
					PreviewExportContextType.VERBATIM_TEXT :
					PreviewExportContextType.DOCUMENT_TEXT);
				break;
			}
			case (PreviewMode.VERBATIM): {
				contextTypes.push(PreviewExportContextType.VERBATIM_TEXT);
				break;
			}
			default:
				contextTypes.push(PreviewExportContextType.SENTENCE_TEXT);
		}

		if (visualProperties.sentencePaneEnabled) {
			contextTypes.push(PreviewExportContextType.SENTENCE_TEXT);
		}

		return _.uniq(contextTypes);
	}

	private getExportType(widget: PreviewWidget): WidgetType {
		return widget.properties.widgetType === WidgetType.PREVIEW
			? WidgetType.PREVIEW_EXPORT
			: widget.properties.widgetType;
	}

}

app.service('previewExportService', downgradeInjectable(PreviewExportService));
