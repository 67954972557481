<modal-header
	(cancel)="dismiss()"
	modalTitle="{{modalTitle | middleEllipsis:60}}">
</modal-header>

<div class='modal-body configuration-modal-body'>
	<ng-container *ngIf="tabs.length > 1">
		<ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
			<li ngbNavItem *ngFor="let tab of tabs">
				<a ngbNavLink role="tab">{{tab.name}}</a>
				<ng-template ngbNavContent>
					<configuration-settings [editor]="tab.editor"></configuration-settings>
				</ng-template>
			</li>
		</ul>
		<section [ngbNavOutlet]="nav" class="mt-2"></section>
	</ng-container>

	<configuration-settings *ngIf="tabs.length === 1" [editor]="tabs[0].editor"></configuration-settings>
</div>

<save-modal-footer
	(save)="submit()"
	(cancel)="dismiss()"
></save-modal-footer>
