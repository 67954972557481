import { Injectable } from '@angular/core';
import { SpotCheckRequest } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-request';
import { SpotCheckResultItem } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-item.component';
import { SpotCheckApiService } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check.api.service';
import { SpotCheckResultStatus } from './spot-check-result-status';

@Injectable({providedIn: 'root'})
export class SpotCheckService {
	private ignoredSpotChecks: string[];

	constructor(
		private spotCheckApiService: SpotCheckApiService,
	) {
		this.ignoredSpotChecks = [];
	}

	performSpotCheck = (dashboardId: number, spotCheckRequest: SpotCheckRequest): Promise<SpotCheckResultItem[]> => {
		return this.spotCheckApiService.performSpotCheck(dashboardId, spotCheckRequest);
	}

	clearIgnoreList = (): void => {
		this.ignoredSpotChecks = [];
	}

	isIgnored = (item: SpotCheckResultItem): boolean => {
		return this.ignoredSpotChecks.contains(item.identifier);
	}

	toggleIgnored = (spotItem: SpotCheckResultItem): void => {
		if (this.isIgnored(spotItem)) {
			this.ignoredSpotChecks.remove(spotItem.identifier);
		} else {
			this.ignoredSpotChecks.push(spotItem.identifier);
		}
	}

	populateState = (spotItem: SpotCheckResultItem): void => {
		let currentStatus: SpotCheckResultStatus;

		if (this.isIgnored(spotItem) && !this.hasPassedStatus(spotItem)) {
			currentStatus = SpotCheckResultStatus.IGNORED;
		} else {
			currentStatus = spotItem.status;
			this.ignoredSpotChecks.remove(spotItem.identifier);
		}

		spotItem.state = { currentStatus };
	}

	private hasPassedStatus = (item: SpotCheckResultItem): boolean => {
		return item.status === SpotCheckResultStatus.PASSED;
	}
}
