import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import {IWidgetActions, WidgetAction, WidgetsEditService} from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'push-widget-menu',
	templateUrl: './push-widget-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PushWidgetMenuComponent {

	@Input() widgetActions?: IWidgetActions;
	@Input() isBulk?: boolean = false;

	pushToTop: string;
	pushToBottom: string;

	constructor(
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService
	){}

	moveToTop(): void {
		if (this.isBulk) {
			this.widgetsEditService.moveSelectedWidgetsToTop();
		} else {
			this.widgetActions.emit(WidgetAction.MOVE_TO_TOP);
		}
	}

	moveToBottom(): void {
		if (this.isBulk) {
			this.widgetsEditService.moveSelectedWidgetsToBottom();
		} else {
			this.widgetActions.emit(WidgetAction.MOVE_TO_BOTTOM);
		}
	}
}

app.directive('pushWidgetMenu', downgradeComponent({component: PushWidgetMenuComponent}));

