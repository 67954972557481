import { ConversationChartOptions } from './conversation-chart-options.class';
import { ConversationDataPoint } from './conversation-data-point.class';
import { IChartSVG } from './conversation-chart.class';
import { ConversationSpineState } from './conversation-style-utils.class';
import { ConversationDataFilter } from './basic-conversation-chart.class';
import { ConversationSentence } from '../reports/document-explorer/conversations/conversation-sentence.class';

export class ConversationRowIndicator {
	config: ConversationChartOptions;
	dataSet: ConversationDataPoint[];
	rowIndicators: IChartSVG;

	constructor(dataSet, config: ConversationChartOptions, rootSvg: IChartSVG) {
		this.dataSet = dataSet;
		this.config = config;

		this.rowIndicators = rootSvg.append('g')
			.attr('id', 'spine-rows');
		this.renderData();
	}

	private renderData(): void {
		this.rowIndicators.append('g')
			.selectAll('rect')
			.data(this.dataSet)
			.enter()
			.append('rect')
			.attr('width', '100%')
			.attr('height', d => d.height)
			.attr('y', d => d.yPosition)
			.attr('rx', 2)
			.attr('ry', 2)
			.attr('tabindex', -1)
			.attr('aria-describedby', 'conversation-tooltip')		
			.attr('id', (data: ConversationDataPoint, i: number) => `__${data.id}`)
			.classed('cb-spine-row', true);
	}

	private applyState(filterFunction: ConversationDataFilter, state: ConversationSpineState): void {
		let selected = this.rowIndicators
			.selectAll('rect')
			.filter(filterFunction)
			.classed(state, true)
			.raise();
	}	

	private clearState(state: ConversationSpineState): void {
		this.rowIndicators
			.selectAll('rect')
			.classed(state, false);
	}

	hoverHighlight = (filterFunction: ConversationDataFilter): void => {
		this.clearState(ConversationSpineState.topicHover);
		this.rowIndicators.classed(ConversationSpineState.topicHover, true);
		this.applyState(filterFunction, ConversationSpineState.topicHover);
	}

	clearHover = (): void => {
		this.rowIndicators.classed(ConversationSpineState.topicHover, false);
		this.clearState(ConversationSpineState.topicHover);
	}

	highlight = (filterFunction: ConversationDataFilter): void => {
		this.clearHighlight();
		this.applyState(filterFunction, ConversationSpineState.highlighted);
	}

	topicHighlight = (filterFunction: ConversationDataFilter): void => {
		this.clearState(ConversationSpineState.topicHighlight);
		this.rowIndicators.classed(ConversationSpineState.topicHighlight, true);
		this.applyState(filterFunction, ConversationSpineState.topicHighlight);
	}

	clearTopicHighlight = (): void => {
		this.rowIndicators.classed(ConversationSpineState.topicHighlight, false);
		this.clearState(ConversationSpineState.topicHighlight);
	}

	select = (dataPoint: ConversationDataPoint): void => {
		this.clearState(ConversationSpineState.selected);
		if (dataPoint)
			this.applyState(data => data === dataPoint, ConversationSpineState.selected);
	}

	selectSentence = (selectedSentence: ConversationSentence): void => {
		this.clearState(ConversationSpineState.selected);
		if (selectedSentence)
			this.applyState(data => data.id === selectedSentence.id, ConversationSpineState.selected);

	}

	clearSelected = (): void => {
		this.clearState(ConversationSpineState.selected);
	}

	updateTopicClasses = (filterFunction: ConversationDataFilter, node: any): void => {
		let selected = this.rowIndicators
			.selectAll('rect')
			.filter(filterFunction)
			.classed('topic-row-' + node.id, true)
			.raise();
	}
	clearHighlight = (): void => {
		this.clearState(ConversationSpineState.highlighted);
	}
}