import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { BackgroundType } from '@cxstudio/reports/entities/content-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { SelectorDisplaySize } from '../../selector-display-size.class';
import AnalyticWidget from './analytic-widget.class';

export class SelectorWidget extends AnalyticWidget {
	static get(): SelectorWidget {
		return new SelectorWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new VisualProperties();

		this.name = WidgetType.SELECTOR;
		this.properties.widgetType = WidgetType.SELECTOR;
		this.visualProperties.visualization = SelectorWidgetNavigationType.SELECTOR_BUTTON;
		this.visualProperties.orientation = DirectionalOrientation.HORIZONTAL;
		this.visualProperties.displaySize = SelectorDisplaySize.EQUAL_SIZE;
		this.visualProperties.backgroundColor = BackgroundType.NONE;
		this.visualProperties.color = ColorPaletteNames.PALETTE_1;
	}

	withSorting(sortBy: string, direction: SortDirection): SelectorWidget {
		this.visualProperties.direction = direction;
		this.visualProperties.sortBy = sortBy;
		return this;
	}
}
