import { TableService } from '@cxstudio/services/table-service';
import { WordsFilteringMode } from '../providers/cb/constants/words-filtering-mode';
import { GroupIdentifierHelper } from '../utils/analytic/group-identifier-helper';

/**
 * Table visualization
 */
app.directive('cloudExcludeWords', (
	tableService: TableService) => {
	return {
		restrict: 'A',
		templateUrl: 'partials/widgets/views/cloud-selection.html',
		replace: true,
		scope: {
			options: '=',
			dataObject: '=data',
			view: '=',
			trigger: '=',
			utils: '='
		},

		link: (scope, element, attrs) => {
			let grouping = scope.utils.selectedAttributes[0];
			let wordsList;
			if (grouping?.wordsFilteringMode === WordsFilteringMode.INCLUDE) {
				wordsList = grouping.wordsList;
			}
			// show words selection when we have inclusion words, even when there is no data
			if (_.isEmpty(wordsList) && !tableService.processIfNoData(element, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			if (!scope.utils?.selectedAttributes) {
				return;
			}

			let field = GroupIdentifierHelper.getIdentifier(scope.utils.selectedAttributes[0]);

			scope.words = _.map(scope.dataObject.data, (word) => {
				return {
					selected: false,
					name: word[field],
					displayName: scope.utils.getGroupingFormatter(0)(word[field])
				};
			});

			// need to show all inclusion words, even if they didn't show in report
			if (!_.isEmpty(wordsList)) {
				let currentWords = _.map(scope.words, 'name');
				let extraWords = _.chain(wordsList)
					.filter(word => !_.contains(currentWords, word))
					.map(word => ({name: word, displayName: scope.utils.getGroupingFormatter(0)(word) }))
					.value();
				scope.words = scope.words.concat(extraWords);

				// all included words are selected by default
				_.each(scope.words, word => word.selected = true);
			}

			scope.onSelectAll = () => {
				_.each(scope.words, word => word.selected = true);
			};

			scope.onSelectNone = () => {
				_.each(scope.words, word => word.selected = false);
			};

			scope.onDone = () => {
				let words = _.chain(scope.words)
					.filter('selected')
					.map('name')
					.value();
				scope.$emit('words-selection:save', words);
			};

			scope.onCancel = () => {
				scope.$emit('words-selection:cancel');
			};

		}
	};
});
