import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { PreviewExportRequest } from './preview-export-request';

@Injectable({
	providedIn: 'root'
})
export class PreviewExportApiService {
	constructor(
		private cxHttp: CxHttpService
	) {}

	schedulePreviewExportData(previewExportRequest: PreviewExportRequest): Promise<void> {
		return this.cxHttp.post(`rest/widget/preview/export/scheduled`, previewExportRequest);
	}

}