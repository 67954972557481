import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ConversationSettings, DocumentPlaybackIdentifier, PlaybackSettings } from './conversation-settings.class';
import { ConversationSettingsService } from './conversation-settings.service';

@Injectable({
	providedIn: 'root'
})
export class ConversationPlaybackSettingsService {
	constructor(
		private conversationSettingsService: ConversationSettingsService
	) {
	}

	getSettings(identifier: DocumentPlaybackIdentifier): PlaybackSettings {
		let conversationSettings: ConversationSettings = this.conversationSettingsService.getSettings();
		return conversationSettings.playbackSettings[this.documentKey(identifier)];
	}

	saveSettings(identifier: DocumentPlaybackIdentifier, playbackSettings: PlaybackSettings): void {
		let conversationSettings: ConversationSettings = this.conversationSettingsService.getSettings();
		conversationSettings.playbackSettings[this.documentKey(identifier)] = playbackSettings;
		this.conversationSettingsService.saveSettings(conversationSettings);
	}

	removeSettings(identifier: DocumentPlaybackIdentifier): void {
		let conversationSettings: ConversationSettings = this.conversationSettingsService.getSettings();
		delete conversationSettings.playbackSettings[this.documentKey(identifier)];
		this.conversationSettingsService.saveSettings(conversationSettings);
	}

	private documentKey(identifier: DocumentPlaybackIdentifier): string {
		return `${identifier.projectId}/${identifier.documentId}`;
	}
}

app.service('conversationPlaybackSettingsService', downgradeInjectable(ConversationPlaybackSettingsService));