import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable()
export class DowngradeExampleService {

	isBackwardInteropSupported(): string {
		return 'Backward interop is supported.';
	}

	isAngularInjectionSupported(): string {
		return 'Angular injection is supported.';
	}

}

app.service('downgradeExampleService', downgradeInjectable(DowngradeExampleService));