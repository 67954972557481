import * as _ from 'underscore';

import MobileAppSettings from './mobile-app-settings.interface';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import WidgetSettingsService from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import Listener from '@cxstudio/common/listener';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { CombinedFiltersService } from '@app/modules/filter/services/combined-filters.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

export default class MobileDataFiltersController implements ng.IComponentController {

	private readonly MAX_SAVED_FILTERS = 5;

	private settings: MobileAppSettings;
	private promises: MobileAppConfigurationPromises;
	private projectIdentifier: ProjectIdentifier;
	private widgetSettingsCache: CachedInvocation;
	private saveListener: Listener;

	private configurationItems: any;
	private savedFilterOptions: any;

	constructor(
		private $scope: ISimpleScope,
		private $q: ng.IQService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
		private readonly projectContextService: ProjectContextService,
		private readonly combinedFiltersService: CombinedFiltersService,
		private widgetSettingsService: WidgetSettingsService,
	) {}

	$onInit(): void {
		if (!this.settings.customFilter
			|| !this.settings.customFilter.filterRules
			|| !this.settings.customFilter.filterRules.length) {
			this.settings.customFilter = {
				type: FilterTypes.AND,
				filterRules: [ angular.copy(FilterEmptyObject.RULE) ]
			};
		}

		if (!this.settings.savedFilter
				|| !this.settings.savedFilter.filters
				|| !this.settings.savedFilter.filters.length) {
			this.settings.savedFilter = {
				type: FilterTypes.AND,
				filters: [ angular.copy(FilterEmptyObject.FILTER) ]
			};
		}

		this.loadConfigurationItems();
		this.$scope.$on('settings.projectChanged', this.loadConfigurationItems);
	}

	private loadConfigurationItems = (): void => {
		this.configurationItems = null;

		let widgetProperties = this.propertiesForProjectSelection(this.projectIdentifier);

		let getWidgetSettings = this.widgetSettingsService.getWidgetSettings;
		if (this.widgetSettingsCache) {
			getWidgetSettings = this.widgetSettingsCache.get(getWidgetSettings);
		}

		this.promises.filters.promise = this.$q
			.all([
				getWidgetSettings(widgetProperties),
				PromiseUtils.old(this.combinedFiltersService.getProjectFilters(this.projectIdentifier))
			])
			.then(result => {
				let widgetSettings = result[0];
				let filterFolders = result[1];

				let attributes = widgetSettings.attributes;
				let models = widgetSettings.models;

				this.configurationItems = this.optionsBuilderProvider.getBuilder()
					.withTemplate(this.optionsTemplatesService.filterItemsDefault())
					.withModels(models)
					.withAttributes(attributes, MetricFilters.NOT_DOCUMENT_DATE)
					.build();
				this.savedFilterOptions = this.buildSavedFilterOptions(filterFolders);
			});
	}

	private buildSavedFilterOptions = (filterFolders): any => {
		if (this.settings.filterId) {
			this.matchLegacySavedFilter(filterFolders);
		}

		return filterFolders;
	}

	private matchLegacySavedFilter = (filterFolders): void => {
		let matched = false;

		filterFolders.forEach(filterFolder => {
			filterFolder.list.forEach(item => {
				if (item.type === FilterTypes.STUDIO && parseInt(item.filterId, 10) === this.settings.filterId) {
					this.settings.savedFilter.filters[0] = {
						type: FilterTypes.STUDIO,
						filterId: this.settings.filterId,
						name: item.name
					};

					matched = true;
				}
			});
		});

		if (matched) {
			this.settings.filterId = null;
		}
	}

	private propertiesForProjectSelection = (projectIdentifier: ProjectIdentifier): WidgetProperties => {
		return {
			contentProviderId: projectIdentifier.contentProviderId,
			accountId: projectIdentifier.accountId,
			project: projectIdentifier.projectId
		};
	}

	private getProjectContext = (): ng.IPromise<IProjectContext> => {
		return PromiseUtils.old(this.projectContextService.getProjectContext(this.projectIdentifier));
	}

	applySavedFilter = (filter, priorFilter) => {
		angular.copy(filter, priorFilter);
	}

	canRemoveSavedFilter = (): boolean => {
		return this.settings.savedFilter.filters.length > 1;
	}

	canAddSavedFilter = (): boolean => {
		return this.settings.savedFilter.filters.length < this.MAX_SAVED_FILTERS && !this.hasEmptySavedFilter();
	}

	addSavedFilter = (): void => {
		this.settings.savedFilter.filters.push(angular.copy(FilterEmptyObject.FILTER));
	}

	savedFilterIsSelected = (filterItem) => {
		return !!filterItem.type && filterItem.type !== FilterTypes.EMPTY && !!filterItem.name;
	}

	removeSavedFilter = (index: number) => {
		if (this.canRemoveSavedFilter()) {
			this.settings.savedFilter.filters.splice(index, 1);
		} else {
			this.settings.savedFilter.filters[0] = angular.copy(FilterEmptyObject.FILTER);
		}
	}

	private hasEmptySavedFilter = (): boolean => {
		return this.settings.savedFilter.filters.filter(filter => filter.type === FilterTypes.EMPTY).length > 0;
	}

}

app.component('mobileDataFilters', {
	controller: MobileDataFiltersController,
	templateUrl: 'partials/mobile/mobile-data-filters.component.html',
	bindings: {
		settings: '=',
		promises: '<',
		projectIdentifier: '<',
		widgetSettingsCache: '<',
		saveListener: '='
	}
});
