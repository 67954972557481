import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject, Injectable } from '@angular/core';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import MetricSettingsService from '@cxstudio/metrics/metric-settings.service';
import ScorecardMetricsService from '@cxstudio/projects/scorecards/scorecard-metrics.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { PromiseUtils } from '@app/util/promise-utils';



export interface IBaseWidgetSettings extends IProjectSettings {
	dateFilters: IFilter[];
}

export interface IAlertSettings extends IBaseWidgetSettings {
	metrics: Metric[];
	scorecardMetrics: any[];
	predefinedMetrics: Metric[];
}

@Injectable({
	providedIn: 'root'
})
export class AlertSettingsService {

	constructor(
		private filtersService: FiltersService,
		@Inject('projectSettingsService') private readonly projectSettingsService: ProjectSettingsService,
		@Inject('metricSettingsService') private readonly metricSettingsService: MetricSettingsService,
		@Inject('scorecardMetricsService') private scorecardMetricsService: ScorecardMetricsService,
	) {}

	getAlertSettings = (props: IProjectSelection): Promise<IAlertSettings> => {
		let project = new ProjectIdentifier(props.contentProviderId, props.accountId, props.projectId);
		let metricSettingsPromise = PromiseUtils.wrap(this.metricSettingsService.getSettings(project)); //ng.iPromise
		let projectSettingsPromise = PromiseUtils.wrap(this.projectSettingsService.getSettings(project)); //ng.Ipromise
		let dateFiltersPromise = this.filtersService.getStudioDateFilters(props); // returns regular promise
		let scorecardMetricsPromise = PromiseUtils.wrap(this.scorecardMetricsService.getFullScorecardMetrics(
			props.contentProviderId, props.accountId, props.projectId)); // ng.IPromise

		let resultPromise = PromiseUtils.all([
			metricSettingsPromise,
			projectSettingsPromise,
			dateFiltersPromise,
			scorecardMetricsPromise

		]).then((result: any[]): IAlertSettings => {
			return {
				metrics: result[0].metrics,
				predefinedMetrics: result[0].predefinedMetrics,
				attributes: result[1].attributes,
				wordAttributes: result[1].wordAttributes,
				models: result[1].models,
				projectContext: result[1].projectContext,
				dateFilters: result[2],
				scorecardMetrics: result[3]
			};
		});
		return resultPromise;
	}
}

app.service('alertSettingsService', downgradeInjectable(AlertSettingsService));

