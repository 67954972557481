import { ExtendedHierarchyNode } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';

export class TreeRendererUtils {
	static isLeaf(node: ExtendedHierarchyNode): boolean {
		return !node._children;
	}

	static hideChildren(node: ExtendedHierarchyNode): void {
		node.children = null;
	}

	static showChildren(node: ExtendedHierarchyNode): void {
		node.children = node._children;
	}

	static toggleChildren(node: ExtendedHierarchyNode): void {
		if (TreeRendererUtils.isCollapsed(node))
			TreeRendererUtils.showChildren(node);
		else TreeRendererUtils.hideChildren(node);
	}

	static isCollapsed(node: ExtendedHierarchyNode): boolean {
		return !node.children && !!node._children;
	}
}