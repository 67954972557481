import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';

import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { TextAttributeGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { ISettingsEditor } from '@app/modules/asset-management/entities/settings-editor.interface';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { ShowQuantitySettingsGroup } from '@app/modules/asset-management/entities/settings-groups/show-quantity-settings-group';
import { SettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.enum';
import { URLSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/url-settings-group';
import { NullIncludeSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/null-include-settings-group';
import { CapitalizationSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/capitalization-settings-group';
import { LimitToSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/limit-to-settings-group';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

export class TextAttributeGroupingSettingsEditor implements ISettingsEditor<TextAttributeGroupingSettings> {

	private groups: {[key: string]: ISettingsGroup<any>};

	constructor(
		private optionsBuilderProvider: OptionsBuilderProvider,
		private tagService: TagsService,
		private sortByOptionsService: SortByOptionsService,
		private metricConstants: MetricConstants,
	) {}

	setSettings(
		attribute,
		source: TextAttributeGroupingSettings,
		project: AccountOrWorkspaceProject,
		sentenceLevelParent: boolean = false
	): Promise<void> {
		return this.sortByOptionsService.getOptions(project).then((sortByOptions) => {
			let groups = {};

			let sentenceLevel = attribute.objectType === AttributeObjectType.SENTENCE || sentenceLevelParent;

			let sortOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(sortByOptions.standardMetrics)
				.withPredefinedMetrics(sortByOptions.predefinedMetrics)
				.withAttributes(sortByOptions.attributes, MetricFilters.CALCULATION)
				.withMetrics(sortByOptions.metrics, project.projectId)
				.build();

			groups[SettingsGroup.SHOW_QUANTITY] = new ShowQuantitySettingsGroup(source, sortOptions, sentenceLevel);
			groups[SettingsGroup.LIMIT_TO] = new LimitToSettingsGroup(source, attribute.name, project, this.tagService);

			if (!this.metricConstants.isWordAttribute(attribute.name)) {
				groups[SettingsGroup.URL] = new URLSettingsGroup(source);
				groups[SettingsGroup.NULL_INCLUDE] = new NullIncludeSettingsGroup(source);
			}

			if (!this.metricConstants.isHashtagAttribute(attribute.name)) {
				groups[SettingsGroup.CAPITALIZATION] = new CapitalizationSettingsGroup(source);
			}

			this.groups = groups;
		});
	}

	getGroups(): {[key: string]: ISettingsGroup<any>} {
		return this.groups;
	}

	getSettings(): TextAttributeGroupingSettings {
		let tabResult = {};
		for (const group of Object.keys(this.groups)) {
			tabResult = _.extend(tabResult, this.groups[group].postProcessSettings());
		}
		return tabResult as TextAttributeGroupingSettings;
	}

}
