import { ChartHierarchyData } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-data-processing.service';
import { CustomGroupMetadata } from '@cxstudio/reports/entities/custom-group-metadata';
import { TimeGrouping } from '@cxstudio/reports/groupings/time-grouping';
import * as moment from 'moment';

export interface DateInterval {
	name: string;
	from: moment.Moment;
	to: moment.Moment;
}

export class PointMetadataUtils {
	static readonly DEFAULT_ELASTIC_FORMAT = 'YYYYMMDD';
	static readonly DOC_TIME_ELASTIC_FORMAT = 'YYYYMMDDHHmmss';
	static readonly DOC_TIME_ATTRIBUTE = '_doc_time';

	private static extractMetadata(data: ChartHierarchyData, primaryGroup: string): CustomGroupMetadata[] {
		return _.map(data, point => {
			return point['_metadata_' + primaryGroup];
		});
	}

	static extractDateIntervals(hierarchyData: ChartHierarchyData, timeGrouping: TimeGrouping): DateInterval[] {
		let metadata = this.extractMetadata(hierarchyData, timeGrouping.identifier);
		let format = (timeGrouping.attributeName === this.DOC_TIME_ATTRIBUTE)
			? this.DOC_TIME_ELASTIC_FORMAT
			: this.DEFAULT_ELASTIC_FORMAT;
		return _.map(metadata, item => {
			return {
				name: item.displayName,
				from: moment(item.projectDateFrom, format),
				to: moment(item.projectDateTo, format)
			};
		});
	}
}
