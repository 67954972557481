import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class HierarchyMetricExpressionItem extends ExpressionItem {
	hierarchyId: number;
	name: string;
	identityName: string;

	constructor(
		hierarchyId: number,
		name: string,
		identityName: string,
		displayName: string
	) {
		super(ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC, displayName);
		this.hierarchyId = hierarchyId;
		this.name = name;
		this.identityName = identityName;
	}
}