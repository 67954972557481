<modal-header
    (cancel)="dismiss()"
    [modalTitle]="ui.modalTitle">
</modal-header>
<section class="modal-body">
    <p class="mb-16 ml-16">
        {{ ui.bodyText }}<br />
        <strong class="font-bold">{{ 'colors.paletteUndo' | i18n }}</strong>
    </p>
    <div class="mb-16 d-flex flex-direction-column">
        <div class="ml-16">
            <label>{{ ui.selectorLabel }}</label>
        </div>
        <color-selector
            class="col-sm-9"
            [visualProps]="selected"
            [filter]="['palette']"
            [palettes]="itemList">
        </color-selector>
    </div>
</section>
<save-modal-footer
    (save)="save()"
    (cancel)="dismiss()"
    [saveText]="ui.buttonText"
></save-modal-footer>
