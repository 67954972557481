import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AnalyticsHelperService } from '@app/modules/analytics/analytics-helper.service';
import { Security } from '@cxstudio/auth/security-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { AmplitudeGroups } from '@app/modules/analytics/amplitude/amplitude-groups';
import * as amplitude from '@amplitude/analytics-browser';

@Injectable({
	providedIn: 'root'
})
export class AmplitudeAnalyticsService {

	constructor(
		@Inject('security') private security: Security,
		@Inject('environmentService') private environmentService: EnvironmentService,
		private readonly analyticsHelperService: AnalyticsHelperService,
	) {}

	initAmplitude(): void {
		let user = this.security.getUser();
		const userId = this.security.getUniquePreferredUserId();
		if (this.environmentService.isUnderTest() || this.environmentService.hasPdfToken()) {
			return;
		}

		// keys were retrieved from Stefan in slack
		const KEY_STAGING = 'b01ed998d2f096897b468e5767bdd484';
		const KEY_PROD = '986fa66d31c57a6e6c5fd80d55de1d61';

		amplitude.init(this.analyticsHelperService.isProduction() ? KEY_PROD : KEY_STAGING, userId);

		const defaultAccountId = user.defaultMasterAccountId;
		const defaultAccountName = this.security.getMasterAccounts()
			.find(account => account.accountId === defaultAccountId)?.accountName;

		const userIdentify = new amplitude.Identify()
			.set('userID', userId)
			.set('defaultAccountID', defaultAccountId)
			.set('defaultAccountName', defaultAccountName)
			.set('licenseType', this.security.getLicenseType())
			.set('accountID', this.security.getMasterAccountId())
			.set('isQualtricsEmployee', this.security.isQualtricsEmployee());
		amplitude.setGroup('masterAccount', this.security.getCurrentMasterAccount().accountName);
		if (this.security.getCurrentMasterAccount().qualtricsIntegrationEnabled) {
			userIdentify
				.set('XMBrandID', this.security.getCurrentMasterAccount().qualtricsBrandId)
				.set('XMAccountID', user.xmAccountId);
		}
		amplitude.identify(userIdentify);


		const accountGroup = new amplitude.Identify()
			.set('userId', userId);
		amplitude.groupIdentify('account', user.userEmail, accountGroup);

		const maGroup = new amplitude.Identify()
			.set('masterAccountId', this.security.getCurrentMasterAccount().accountId);
		amplitude.groupIdentify('masterAccount', this.security.getCurrentMasterAccount().accountName, maGroup);

	}


	static trackEvent(action: AmplitudeEvent, eventGroups: AmplitudeGroups, eventData?: any): void {
		amplitude.track({
			event_type: action,
			event_properties: eventData,
			groups: eventGroups
		});
	}
}

app.service('amplitudeAnalytics', downgradeInjectable(AmplitudeAnalyticsService));
