import { HttpParams } from '@angular/common/http';
import { IAttributeValuesParams } from '@app/modules/project/attribute/attributes.service';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';

export class AttributeValuesSearchParams {
	attributeNames: string[];
	filter: string;
	start?: number;
	limit?: number;
	runAs?: string;
	cacheOption?: AnalyticCacheOptions;

	static fromSearchObject(searchObject: Partial<IAttributeValuesParams>): AttributeValuesSearchParams {
		const filterQuery = searchObject.filter ? searchObject.filter.replace('{', '') : '';
		const attributes = searchObject.attributeNames.map(attrName => {
			//Need to send _mstokenname for values available, but use _mtoken for actual filter
			return attrName === '_mtoken' ?  '_mstokenname' : attrName;
		});

		let searchParams = new AttributeValuesSearchParams();
		searchParams.attributeNames = attributes;
		searchParams.filter = filterQuery;
		searchParams.start = searchObject.start;
		searchParams.limit = searchObject.limit;
		searchParams.runAs = searchObject.runAs;
		searchParams.cacheOption = searchObject.cacheOption;
		return searchParams;
	}

	toHttpParams(): HttpParams {
		let httpParams = new HttpParams()
			.append('filter', this.filter);
		this.attributeNames.forEach(
			attrName => httpParams = httpParams.append('attributeNames', attrName));
		if (this.start) {
			httpParams = httpParams.append('start', this.start + '');
		}
		if (this.limit) {
			httpParams = httpParams.append('limit', this.limit + '');
		}
		if (this.runAs) {
			httpParams = httpParams.append('runAs', this.runAs);
		}
		if (this.cacheOption) {
			httpParams = httpParams.append('cacheOption', this.cacheOption);
		}
		return httpParams;
	}
}
