import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import ChartType from '@cxstudio/reports/entities/chart-type';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { IConversionWidgetProperty } from '@cxstudio/reports/widgets/conversion-widget-property.class';
import { IReportWidgetContext, Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export class ScatterWidget extends ReportWidget {

	constructor(
		private metricConstants: MetricConstants
	) {
		super(WidgetType.SCATTER);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initFromContext(context);
		super.withFilters(context);

		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = [];
		this.widget.visualProperties.visualization = WidgetVisualization.BUBBLE;

		super.withShowSampleSize(context);

		this.initDateRanges(context);
		this.initGroupings(context);
		this.initCalculations(context);
		this.initAdvancedAxisOptions(context);
	}

	private initDateRanges(context: IReportWidgetContext): void {
		super.withDateRange(context);
	}

	private initGroupings(context: IReportWidgetContext): void {
		let groupings = WidgetPropertyService.getValue(
			'properties.selectedAttributes', context, []);
		groupings.forEach(grouping => {
			this.addGrouping(grouping);
		});
	}

	private initCalculations(context: IReportWidgetContext): void {
		let calculations = WidgetPropertyService.getValue(
			'properties.selectedMetrics', context, []) as ReportCalculation[];

		let calculationData = this.isDualAxisSource(context)
			? this.processDualAxisSource(context)
			: (this.isTableSource(context) || this.isScatterSource(context))
				? this.processTableOrScatterSource(context, _.reject(calculations, this.isPoPCalculation))
				: this.processSource(context, calculations);

		this.populateVerticalAxis(calculationData.verticalAxis);
		this.populateHorizontalAxis(calculationData.horizontalAxis);
		this.populateBubbleSize(calculationData.bubbleSize);

		super.withColors(context);
	}

	private processDualAxisSource(context: IReportWidgetContext): void {
		let selections = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections', context);
		let chartType = context.source.visualProperties.subChartType;

		let calculationData = {} as any;
		calculationData.verticalAxis = selections.yAxis;
		calculationData.horizontalAxis = this.hasSecondaryAxis(context)
			? selections.secondaryYAxis
			: this.getDefaultCalculation(calculationData.verticalAxis);

		calculationData.bubbleSize = chartType === ChartType.BUBBLE
			? selections.size
			: this.hasSecondaryAxis(context)
				? selections.secondarySize
				: this.metricConstants.get().CONSTANT_SIZE;

		return calculationData;
	}

	private processTableOrScatterSource(context: IReportWidgetContext, calculations: ReportCalculation[]): any {
		let calculationData = {
			verticalAxis: calculations[0],
			horizontalAxis: calculations[1],
			bubbleSize: calculations[2]
		};

		if (isEmpty(calculationData.verticalAxis)) {
			calculationData.verticalAxis = this.metricConstants.get().SENTIMENT;
		}

		if (isEmpty(calculationData.horizontalAxis)) {
			calculationData.horizontalAxis = this.getDefaultCalculation(calculationData.verticalAxis);
		}

		let bubbleSize = calculationData.bubbleSize;
		if (isEmpty(bubbleSize) || bubbleSize.standardMetric && !this.isVolumeMetric(bubbleSize)) {
			calculationData.bubbleSize = this.metricConstants.get().CONSTANT_SIZE;
		}

		return calculationData;
	}

	private processSource(context: IReportWidgetContext, calculations: ReportCalculation[]): any {
		let verticalAxis = _.first(calculations);
		if (this.isConstantSize(verticalAxis))
			verticalAxis = this.metricConstants.get().SENTIMENT;
		return {
			verticalAxis,
			horizontalAxis: this.getDefaultCalculation(verticalAxis),
			bubbleSize: this.metricConstants.get().CONSTANT_SIZE
		};
	}

	private initAdvancedAxisOptions(context: IReportWidgetContext): void {
		let axisNames: IConversionWidgetProperty[] = [
			{name: 'y'}, {name: 'x'}
		];

		if (this.isDualAxisSource(context)) {
			axisNames[1].sourceName = 'secondary';
		}

		super.withAdvancedAxisOptions(context, axisNames);
	}

	private getDefaultCalculation(existingCalculation): ReportCalculation {
		return !this.isVolumeMetric(existingCalculation)
			? this.metricConstants.get().VOLUME
			: this.metricConstants.get().SENTIMENT;
	}

	private populateBubbleSize(calculation): void {
		super.addCalculation(calculation);
		this.widget.visualProperties.size = calculation.name;
	}

	private populateVerticalAxis(calculation): void {
		this.populateAxis('yAxis', calculation);
	}

	private populateHorizontalAxis(calculation): void {
		this.populateAxis('xAxis', calculation);
	}

	private populateAxis(axisName, calculation): void {
		if (isEmpty(calculation)) {
			return;
		}

		super.addCalculation(calculation);
		this.widget.visualProperties[axisName] = calculation.name;
	}

	private hasSecondaryAxis(context: IReportWidgetContext): boolean {
		return !!context.source.visualProperties.secondaryYAxis;
	}

	private addGrouping(grouping: AttributeGrouping): void {
		if (!grouping) return;
		if (this.widget.properties.selectedAttributes.length > 0) return;
		delete grouping.stackType;

		this.widget.properties.selectedAttributes.push(grouping);
		this.widget.visualProperties.primaryGroup = grouping.name;

		if (AnalyticMetricTypes.isTime(grouping)) {
			this.widget.properties.primaryTimeGrouping = grouping;
		}
	}

	private isDualAxisSource(context: IReportWidgetContext): boolean {
		return context.source.properties.widgetType === WidgetType.LINE
			|| context.source.properties.widgetType === WidgetType.BAR;
	}

	private isTableSource(context: IReportWidgetContext): boolean {
		return context.source.properties.widgetType === WidgetType.TABLE;
	}

	private isScatterSource(context: IReportWidgetContext): boolean {
		return context.source.properties.widgetType === WidgetType.SCATTER;
	}

	private isVolumeMetric(metric: ReportCalculation): boolean {
		return !isEmpty(metric) && metric.name === StandardMetricName.VOLUME;
	}

	private isConstantSize(metric: ReportCalculation): boolean {
		return !isEmpty(metric) && metric.name === StandardMetricName.CONSTANT_SIZE;
	}
}
