import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';

app.filter('internalUserTimeFormat', (dateService: DateService) => {
	return (dateStr) => {
		if (!dateStr) {
			return '';
		}

		let expirationDate = moment.parseZone(dateStr);
		let date = new Date(expirationDate.year(),
			expirationDate.month(),
			expirationDate.date(),
			expirationDate.hour(),
			expirationDate.minute(),
			expirationDate.second()
		);
		let timezoneOffset = moment(date).utcOffset();
		let adjustedDate = new Date(date.getTime() + (timezoneOffset * 60000));

		let tz = `GMT${dateService.getTimezoneString(timezoneOffset)}`;
		return dateService.format(adjustedDate, DateTimeFormat.BASIC_DATE_TIME) + ' ' + tz;
	};
});
