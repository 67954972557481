<ngb-accordion [closeOthers]="true" type="default">
	<ngb-panel>
		<ng-template ngbPanelTitle>
			{{'administration.scheduledJobs'|i18n}}
		</ng-template>
		<ng-template ngbPanelContent>
			<div class="row">
				<div class="col-xs-12">
					<div class="form-group clearfix">
						<div class="pull-left">
							<refresh-trigger 
								class="btn btn-secondary"
								[refreshFunction]="refreshScheduledJobs" 
								refreshLimit="10">
							</refresh-trigger>
						</div>
						<simple-dropdown
							class="pull-right"
							[options]="scheduledJobs.groups"
							displayField="displayName"
							[(value)]="scheduledJobs.group"
							(valueChange)=refreshScheduledJobs()>
						</simple-dropdown>
					</div>
	
					<formatted-table
						[columns]="scheduledJobs.columns"
						[rows]="scheduledJobs.data">
					</formatted-table>
				</div>
				<pagination
					class="row col-xs-12"
					[pagination]="scheduledJobs.pagination"
					(paginationChange)="scheduledJobsPageChanged($event)">
				</pagination>
			</div>
		</ng-template>
	</ngb-panel>

	<ngb-panel>
		<ng-template ngbPanelTitle>
			{{'administration.completedJobs'|i18n}}
		</ng-template>
		<ng-template ngbPanelContent>
			<div class="row">
				<div class="col-xs-12">
					<div class="form-group clearfix">
						<div class="pull-left">
							<refresh-trigger 
								class="btn btn-secondary"
								[refreshFunction]="refreshCompletedJobs" 
								refreshLimit="10">
							</refresh-trigger>
						</div>
						<simple-dropdown
							class="pull-right"
							[options]="completedJobs.groups"
							displayField="displayName"
							[(value)]="completedJobs.group"
							(valueChange)=refreshCompletedJobs()>
						</simple-dropdown>
					</div>
	
					<formatted-table
						[columns]="completedJobs.columns"
						[rows]="completedJobs.data">
					</formatted-table>
				</div>
				<pagination
					class="row col-xs-12"
					[pagination]="completedJobs.pagination"
					(paginationChange)="completedJobsPageChanged($event)">
				</pagination>
			</div>
		</ng-template>
	</ngb-panel>

	<ngb-panel>
		<ng-template ngbPanelTitle>
			{{'administration.optionalJobs'|i18n}}
		</ng-template>
		<ng-template ngbPanelContent>
			<div class="row">
				<div class="col-xs-12">
					<div class="form-group clearfix">
						<formatted-table
							[columns]="optionalJobs.columns"
							[rows]="optionalJobs.data">
						</formatted-table>
					</div>
				</div>
			</div>
		</ng-template>
	</ngb-panel>
</ngb-accordion>