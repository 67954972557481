import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import PlotBand from '@app/modules/plot-lines/plot-bands/plot-band';

@Component({
	selector: 'plot-band-item',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<input type="text" [(ngModel)]="plotBand.text">
	<color-dropdown class="band-text-color cursor-pointer"
		[(ngModel)]="plotBand.textColor">
		<i class="q-icon-text-2 font-icon flex-fill"
			[ngStyle]="{color: plotBand.textColor, 'background-color': plotBand.backgroundColor}">
		</i>
	</color-dropdown>
	<color-dropdown class="band-background-color cursor-pointer"
		[(ngModel)]="plotBand.backgroundColor">
	</color-dropdown>`,
})
export class PlotBandItemComponent {
	@Input() plotBand: PlotBand;

	constructor(
	) {}
}
