import * as _ from 'underscore';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ConversionWidgetProperty from './conversion-widget-property.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';
import { IReportWidgetContext, Replacement } from '@cxstudio/reports/widgets/report-widget.class';
const NETWORK_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('visualization'), new ConversionWidgetProperty('showLegend'),
	new ConversionWidgetProperty('showNodeNames'), new ConversionWidgetProperty('showLabels'),
	new ConversionWidgetProperty('showSampleSize')
];

export class NetworkWidget extends SimpleReportWidget {

	constructor(private metricContsants: MetricConstants) {
		super(WidgetType.NETWORK);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initSimpleReport(context);
		super.initProperties(NETWORK_VISUAL_PROPERTIES, context, 'visualProperties');

		this.initGrouping(context);
		this.initNodeSize(context);
	}

	private initGrouping(context: IReportWidgetContext): void {
		let groupings = WidgetPropertyService.getValue('properties.selectedAttributes', context, []);
		let newGrouping = groupings[0] || {};
		if (this.isValidNetworkGrouping(newGrouping)) {
			this.widget.properties.selectedAttributes.push(newGrouping);
			this.widget.visualProperties.primaryGroup = newGrouping.name;
		}
	}

	private isValidNetworkGrouping(grouping): boolean {
		return grouping.model || grouping.derivedFromCategory || grouping.multiValue;
	}

	private initNodeSize(context: IReportWidgetContext): void {
		this.initSingleCalculation(context, this.metricContsants.get().VOLUME);
		this.widget.visualProperties.size = this.widget.properties.selectedMetrics[0]?.name;
	}
}
