import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { AlertOptOutEntry } from '@cxstudio/alerts/entities/alert-opt-out-entry';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { CxLocaleService } from '@app/core';
import { TableView } from '@app/shared/components/table/formatted-table.component';
import { DomSanitizer } from '@angular/platform-browser';

interface OptOutsModalComponentInput {
	alert: StudioAlert;
}

@Component({
	selector: 'opt-outs-dialog',
	templateUrl: './opt-outs-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptOutsModalComponent extends ModalBindings<OptOutsModalComponentInput> implements OnInit {
	alert: StudioAlert;
	loading: boolean;
	modalTitle: string;
	columns: Array<TableColumn<any>>;
	rows: AlertOptOutEntry[];
	tableView: TableView = TableView.COMPACT;

	constructor(
		modal: NgbActiveModal,
		private ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('alertingApiService') private alertingApiService: AlertingApiService,
		private readonly domSanitizer: DomSanitizer,
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.loading = true;
		this.alert = this.input.alert;
		this.modalTitle = this.locale.getString('alert.optOutHeading', { name: this.alert.name });
		this.loadRecipients(this.alert);
	}

	getColumns = (): Array<TableColumn<any>> => {
		return [
			{
				name: 'recipient',
				displayName: this.locale.getString('alert.user'),
				formatter: this.defaultFormatter,
				width: 0.4
			},
			{
				name: 'delayPeriod',
				displayName: this.locale.getString('alert.delayPeriod'),
				formatter: this.delayPeriodFormatter,
				width: 0.2
			},
			{
				name: 'startDate',
				displayName: this.locale.getString('alert.lastAction'),
				width: 0.3,
				formatter: this.dateFormatter
			},
			{
				name: 'enabled',
				displayName: this.locale.getString('alert.resume'),
				width: 0.1,
				formatter: this.toggleFormatter,
				action: (alertOptOutEntry) => alertOptOutEntry.enabled = !alertOptOutEntry.enabled
			}
		];
	}

	private defaultFormatter = (alertOptOutEntry: AlertOptOutEntry, field: string): string => {
		return alertOptOutEntry[field];
	}

	private dateFormatter = (alertOptOutEntry: AlertOptOutEntry, field: string): string => {
		return this.gridFormatterService.DateFormatter(alertOptOutEntry, field, alertOptOutEntry[field]);
	}

	private toggleFormatter = (alertOptOutEntry: AlertOptOutEntry, field: string): string => {
		const getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(alertOptOutEntry.recipient, 'w-100-percent');
		const switchButton = getSwitch(alertOptOutEntry[field] ?? false);

		return this.domSanitizer.bypassSecurityTrustHtml(switchButton) as string;
	}

	private delayPeriodFormatter = (alertOptOutEntry: AlertOptOutEntry, field: string): string => {
		return !alertOptOutEntry.permanent
			? this.locale.getString('alert.delayPeriodPattern', { period: alertOptOutEntry.delayPeriodDays })
			: this.locale.getString('alert.delayForever')
		;
	}

	private loadRecipients = (alert: StudioAlert): void => {
		this.loading = true;

		this
			.alertingApiService
			.loadDisabledRecipients(alert.id)
			.then(
				(alertOptOutEntry) => {
					this.rows = alertOptOutEntry;
					this.columns = this.getColumns();
				}
			)
			.finally(
				() => {
					this.loading = false;
					this.ref.markForCheck();
				}
			)
		;
	}

	save = (): void => {
		const enabledRecipients = this
			.rows
			.filter(alertOptOutEntry => alertOptOutEntry.enabled)
			.map(alertOptOutEntry => alertOptOutEntry.recipient)
		;

		this.close(enabledRecipients);
	}

	resumeAllAndSave = (): void => {
		const recipients = this
			.rows
			.map(alertOptOutEntry => alertOptOutEntry.recipient)
		;

		this.close(recipients);
	}
}
