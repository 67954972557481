import Widget from '@cxstudio/dashboards/widgets/widget';
import * as _ from 'underscore';


interface GridsterOptionsRequest {
	widgetsProvider: () => Widget[];
	editModeValueProvider?: () => boolean;
}

export interface GridsterWidgetMapping {
	idProvider: () => number;
	sizeX: string;
	sizeY: string;
	minSizeY: string;
	minSizeX: string;
	row: string;
	col: string;
	group: string;
}

export default class GridsterConfigurer {

	private static GRIDSTER_SELECTOR: string = '#dsh-widget-container';

	static GRIDSTER_COLUMNS: number = 24;
	static GRIDSTER_ITEM_WIDTH: number = 12;
	static GRIDSTER_ITEM_HEIGHT: number = 12;

	constructor(
		private $rootScope: ng.IRootScopeService,
	) {}

	getWidgetMapping = (widget: Widget): GridsterWidgetMapping => {
		return {
			idProvider: () => widget.id,
			sizeX: 'widget.width',
			sizeY: 'widget.height',
			minSizeY: 'widget.minSizeY',
			minSizeX: 'widget.minSizeX',
			row: 'widget.posY',
			col: 'widget.posX',
			group: 'widget.group' // multi-widget dragging
		};
	}

	getDefaultGridsterOptions = (gridsterOptionsRequest: GridsterOptionsRequest) => {
		return {
			floating: true,
			pushing: true,
			margins: [ 20, 20 ],
			maxRows: 10000,
			sparse: true,
			mobileBreakPoint: this.$rootScope.isMobile ? 0 : 750, // 767 - (margins, borders)
			mobileModeEnabled: this.$rootScope.isMobile,
			columns: GridsterConfigurer.GRIDSTER_COLUMNS,
			rowHeight: '/2',
			defaultSizeX: GridsterConfigurer.GRIDSTER_ITEM_WIDTH, // the default width of a gridster item, if not specifed
			defaultSizeY: GridsterConfigurer.GRIDSTER_ITEM_HEIGHT, // the default height of a gridster item, if not specified
			draggable: {
				enabled: false,
				container: GridsterConfigurer.GRIDSTER_SELECTOR,
				handle: '[gridster-drag-handle]'
			},
			resizable: {
				enabled: false,
				handles: ['se', 'ne', 'nw', 'sw']
			},
			floatingSettings: {
				getPageBreakPosition: () => {
					let widgets = gridsterOptionsRequest.widgetsProvider();
					let pageBreakWidget = _.findWhere(widgets, { name: 'page_break' });
					return pageBreakWidget && pageBreakWidget.posY;
				},
				isEditMode: () => {
					let editModeValueProvider = gridsterOptionsRequest.editModeValueProvider;
					return editModeValueProvider ? editModeValueProvider() : false;
				},
				isNewPageBreak: () => {
					let widgets = gridsterOptionsRequest.widgetsProvider();
					let pageBreakWidget = _.findWhere(widgets, { name: 'page_break' });
					return pageBreakWidget && pageBreakWidget.properties.pageBreakV2;
				}
			}
		};
	}

	getGridsterSelector = (): string => {
		return GridsterConfigurer.GRIDSTER_SELECTOR;
	}

	getGridsterItemWidth = (): number => {
		return GridsterConfigurer.GRIDSTER_ITEM_WIDTH;
	}

	getGridsterItemHeight = (): number => {
		return GridsterConfigurer.GRIDSTER_ITEM_HEIGHT;
	}

	getGridsterColumns = (): number => {
		return GridsterConfigurer.GRIDSTER_COLUMNS;
	}

}

app.service('gridsterConfigurer', GridsterConfigurer);
