import { Chart } from 'highcharts';

export interface HighchartsFunctionScope {
	value: any;
	series: Highcharts.Series | any;
	name: string;
	displayName: string;
	y: number;
	z: number;
	id: number | string;
	_uniqueName: string;
	fullPath: string;
	idPath: string;
	color: string;
	attributeName: string;
	graphic: any;
	object: any;
	options: any;

	fromNode: any;
	toNode: any;
	chart: Chart;
	index: number;
	point: {
		object: any;
		rawValue: any;
	};
	percentage: number;
	rawValue: number;
	marker: any;
	visible: boolean;
	// don't forget to add new fields into the array below
}

export class HighchartsClosureUtils {

	/**
	 * This function allows us to normally pass TS functions () => {} into highcharts callback properties
	 * */
	static closureWrapper(callback: (_this: Partial<HighchartsFunctionScope>, arg?: any) => any, extraProperties = []) {
		return function(arg?) {
			// tslint:disable-next-line: no-invalid-this
			let scope = _.pick(this, [
				'value',
				'series',
				'name',
				'displayName',
				'y',
				'z',
				'id',
				'_uniqueName',
				'fullPath',
				'idPath',
				'color',
				'attributeName',
				'graphic',
				'object',
				'options',
				'fromNode',
				'toNode',
				'chart',
				'index',
				'point',
				'options',
				'percentage',
				'rawValue',
				'visible',
			].concat(extraProperties));
			return callback(scope, arg);
		};
	}
}
