/**
 * Widget states to accommodate to the cached / not cached report flows (report-responsiviess.ts)
 * Three flows are possible:
 * - 1 request flow: loading_fresh_data -> displaying_fresh_data
 * - 2 request flow (cache is fresh): loading_cached_data -> displaying_fresh_data
 * - 2 request flow (cache is out of date): loading_cached_data -> loading_fresh_data -> displaying_cached_data -> displaying_fresh_data
 * - 3 request flow (cache is out of date, but data no difference):
 * 		loading_cached_data -> loading_fresh_data -> displaying_fresh_data_no_change
 */

export enum WidgetReportDisplayState {
	LOADING_CACHED_DATA = 'LOADING_CACHED_DATA',
	LOADING_FRESH_DATA = 'LOADING_FRESH_DATA',
	DISPLAYING_CACHED_DATA = 'DISPLAYING_CACHED_DATA',
	DISPLAYING_FRESH_DATA = 'DISPLAYING_FRESH_DATA',
	DISPLAYING_FRESH_DATA_NO_CHANGE = 'DISPLAYING_FRESH_DATA_NO_CHANGE'
}

