<modal-header
	(cancel)="cancel()"
	[modalTitle]="getModalTitle()">
</modal-header>

<div class="modal-body edit-external-application-modal" [ngBusy]="loading">
	<form class="form-horizontal" name="clientDetailsDialog" #clientDetailsDialog="ngForm">
		<div class="row form-group">
			<label class="control-label col-md-3">
				{{'administration.oauthClientId'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'administration.oauthClientIdHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input *ngIf="!isEditMode()" class="form-control" autocomplete="nope"
					   [(ngModel)]="clientDetails.clientId" name="clientId" required>
				<span *ngIf="isEditMode()">{{clientDetails.clientId}}</span>
			</div>
		</div>

		<div class="row form-group" *ngIf="showLegacyClientId()">
			<label class="control-label col-md-3">{{'administration.oauthClientLegacyId'|i18n}}
				({{'administration.optional'|i18n}})</label>
			<div class="col-md-8">
				<input class="form-control" autocomplete="nope"
					   [(ngModel)]="clientDetails.legacyClientId"
					   name="legacyClientId">
			</div>
		</div>

		<div class="row form-group" *ngIf="!clientDetails.description">
			<label class="control-label col-md-3 text-danger">
				<span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>
			<div class="col-md-8">
				<label class="text-danger">{{'administration.oauthClientDescriptionHasToBeAdded'|i18n}}</label>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">
				{{'common.description'|i18n}}
			</label>
			<div class="col-md-8">
				<textarea class="form-control" maxlength="500" autocomplete="nope"
					   [(ngModel)]="clientDetails.description" name="description" required>
				</textarea>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">
				{{'administration.oauthClientSecret'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'administration.oauthClientSecretHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input class="form-control" type="password" autocomplete="new-password"
					   [(ngModel)]="clientDetails.clientSecret" name="clientSecret" required>
			</div>
		</div>

		<div class="row form-group" show-errors *ngIf="showRegisteredRedirectUri()">
			<label class="control-label col-md-3">
				{{'administration.oauthRegisteredRedirectUri'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'administration.oauthRedirectHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<tags-input
					class="w-100-percent"
					name="redirectUri"
					[maxTags]="isStudioClient() ? 1 : 10"
					[(ngModel)]="registeredRedirectUri"
					[addOnBlur]="false"
					[addOnEnter]="true"
					[attr.required]="registeredRedirectUriRequired()">
				</tags-input>
			</div>
		</div>

		<div class="row form-group" show-errors>
			<label class="control-label col-md-3">{{'administration.oauthAccessTokenValiditySeconds'|i18n}}</label>
			<div class="col-md-2">
				<input type="number" class="form-control" min="-1" max="31536000" pattern="^-?\d*"
					   [(ngModel)]="clientDetails.accessTokenValiditySeconds"
					   name="accessTokenValiditySeconds" required>
			</div>
			<label class="col-md-1">{{'administration.oauthTokenValidityUnit'|i18n}}</label>
		</div>

		<div class="row form-group" show-errors>
			<label class="control-label col-md-3">{{'administration.oauthRefreshTokenValiditySeconds'|i18n}}</label>
			<div class="col-md-2">
				<input type="number" class="form-control" min="-1" max="31536000" pattern="^-?\d*"
					   [(ngModel)]="clientDetails.refreshTokenValiditySeconds"
					   name="refreshTokenValiditySeconds" required>
			</div>
			<label class="col-md-1">{{'administration.oauthTokenValidityUnit'|i18n}}</label>
		</div>

		<div class="row form-group" *ngIf="!scopeSelected() && !supportDesignerScope">
			<label class="control-label col-md-3 text-danger">
				<span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>
			<div class="col-md-8">
				<label class="text-danger">{{'administration.oauthScopeHasToBeAdded'|i18n}}</label>
			</div>
		</div>

		<div class="form-group" *ngIf="!supportDesignerScope">
			<label class="control-label col-md-3" [i18n]="'administration.oauthScope'">
				<cb-inline-help>
					<help-body [innerHTML]="'administration.oauthScopeHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<tags-input class="w-100-percent" name="oauthScopes"
							[(ngModel)]="oauthScopes"
							[minLength]="1"
							[addOnBlur]="true"
							[addOnEnter]="true"
							(onTagRemoved)="checkRemovedTag($event)">
				</tags-input>
			</div>
		</div>

		<div class="row form-group" *ngIf="isOpenidClient()" show-errors>
			<label class="control-label col-md-3">{{'administration.jwtSecretKey'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control" type="password"
					   [(ngModel)]="clientDetails.additionalInformation.jwtSecretKey"
					   name="jwtSecretKey" required>
			</div>
		</div>

		<div class="row form-group" *ngIf="!grantTypeSelected()" >
			<label class="control-label col-md-3 text-danger">
				<span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>
			<div class="col-md-8">
				<label class="text-danger">{{'administration.oauthGrantTypeHasToBeSelected'|i18n}}</label>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAuthorizedGrantTypes'|i18n}}</label>
			<div class="columns-3">
				<div class="labeled-checkbox" *ngFor="let grantType of grantTypes; index as $index">
					<input id="grantType_{{$index}}" name="grantType_{{$index}}" type="checkbox"
							class="checkbox ng-not-empty"
							[(ngModel)]="selectedGrantTypes[grantType.type]">
					<label for="grantType_{{$index}}">{{ grantType.displayName }}</label>
				</div>
			</div>
		</div>

		<div class="row form-group" *ngIf="showAuthorities()">
			<label class="control-label col-md-3">{{'administration.oauthAuthorities'|i18n}}</label>
			<div class="columns-3">
				<div class="labeled-checkbox" *ngFor="let authority of authorityOptions; index as $index">
					<input id="oauth-authority-{{$index}}" name="oauth-authority-{{$index}}" type="checkbox"
							class="checkbox ng-not-empty"
							[(ngModel)]="selectedAuthorities[authority.role]">
					<label for="oauth-authority-{{$index}}">{{ authority.displayName }}</label>
				</div>
			</div>
		</div>

	</form>

	<oauth-client-details-unified-links *ngIf="showUnifiedLinks()"
		[additionalInformation]="clientDetails.additionalInformation"
		[masterAccounts]="masterAccounts"
		[isLegacySuiteApplication]="isLegacySuiteApplication()"
		(unifiedLinksUpdated)="updateUnifiedLinks($event)">
	</oauth-client-details-unified-links>

	<oauth-client-details-designer-scope *ngIf="supportDesignerScope"
		[clientDetails]="clientDetails"
		(designerScopeUpdated)="updateDesignerScope($event)">
	</oauth-client-details-designer-scope>

	<oauth-client-details-additional-information
		[clientDetails]="clientDetails"
		[masterAccounts]="masterAccounts"
		(detailsUpdated)="updateAdditionalInformation($event)">
	</oauth-client-details-additional-information>

</div>


<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="disableSave()">
</save-modal-footer>
