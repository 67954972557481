import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { JmsListenersState } from './jms-listeners-state';

@Injectable({
	providedIn: 'root'
})
export class JmsStatusApi {
	constructor(
		private cxHttp: CxHttpService,
	) {}

	getData = (): Promise<JmsListenersState> => {
		return this.cxHttp.get('rest/listeners');
	}

	restartEmailListener = (nodeId: string): Promise<void> => {
		return this.cxHttp.put(`rest/listeners/email/host/${nodeId}`);
	}
}
