enum MediaType {
	XLS = 'xls',
	CSV = 'csv',
	XML = 'xml',
	DAT = 'dat',
}

export function getFileExtensionsForMediaType(mediaType: MediaType): string[] {
	if (mediaType === MediaType.XLS) {
		return ['xls', 'xlsx'];
	}

	if (mediaType === MediaType.CSV) {
		return ['csv'];
	}

	if (mediaType === MediaType.XML) {
		return ['xml'];
	}

	if (mediaType === MediaType.DAT) {
		return ['dat'];
	}

	return [];
}

export default MediaType;
