import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { RunnablePackage, RUNNABLE_PACKAGE } from '../packages/runnable-package.interface';

export class CatalogMetricPackageEntry {
	metrics: any[];
	dashboard: Dashboard;
}

@Component({
	selector: 'catalog-tab',
	templateUrl: './catalog-tab.component.html',
	styles: [`
	.btn-radio {
		transform: scale(1.5);
		margin: 0px;
	}
	.row-height {
		height: 40px !important;
	}
`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogTabComponent implements OnInit {
	packageSearch: string;
	selectedPackage: RunnablePackage;
	loadingTemplates: Promise<any>;
	template: any;
	packages: RunnablePackage[];

	constructor(
		@Inject(RUNNABLE_PACKAGE) private runnablePackages: RunnablePackage[]
	) {
		this.packages = runnablePackages;
	}

	ngOnInit(): void {
	}

	onSelect(pack: RunnablePackage): void {
		this.selectedPackage = pack;
	}

	isPackageSelected(): boolean {
		return !_.isUndefined(this.selectedPackage);
	}

	runPackage(): void {
		this.loadingTemplates = this.selectedPackage.runPackage();
	}
}