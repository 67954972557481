<div class="page-tab-content">
	<div class="br-list-box sub-tab-content">
		<ul ngbNav #nav="ngbNav" class="nav-tabs">
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.oauthStudioClientsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<oauth-studio-internal-clients [masterAccounts]="masterAccounts"></oauth-studio-internal-clients>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.oauthDesignerClientsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<oauth-designer-internal-clients [masterAccounts]="masterAccounts"></oauth-designer-internal-clients>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.oauthSuiteClientsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<oauth-suite-clients [masterAccounts]="masterAccounts"></oauth-suite-clients>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.oauthLinkClientsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<oauth-link-clients [masterAccounts]="masterAccounts"></oauth-link-clients>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.oauthExternalProvidersTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<oauth-external-providers></oauth-external-providers>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.qualtricsApiProvidersTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<qualtrics-api-providers></qualtrics-api-providers>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</div>
</div>
