<div class="cx-date-range-selector v-middle" [ngClass]="{'exact-only': exactOnly}">
	<date-point class="cx-date-range-from"
		popupId="customDate1"
		label="{{'reportFilters.fromDateLabel'|i18n}}"
		adjustTime="start"
		[autoClose]="true"
		[pointMode]="dateRange.fromMode"
		[pointValue]="fromValue"
		[timezone]="timezone"
		(onChange)="onFromValueChange($event)"
		[modes]="fromModes"
		[datepickerOptions]="datepickerOptions.from"
		[selectorHidden]="exactOnly"
		[hideTime]="hideTime"
		[disableDate]="disableDates"
		[appendToBody]="appendToBody"
	></date-point>
	<span class="filter-text-piece mh-8" >{{'reportFilters.to'|i18n}}</span>
	<date-point class="cx-date-range-to"
		popupId="customDate2"
		label="{{'reportFilters.toDateLabel'|i18n}}"
		adjustTime="end"
		[autoClose]="true"
		[pointMode]="dateRange.toMode"
		[pointValue]="toValue"
		[timezone]="timezone"
		(onChange)="onToValueChange($event)"
		[modes]="toModes"
		[datepickerOptions]="datepickerOptions.to"
		[selectorHidden]="toSelectorHidden"
		[hideTime]="hideTime"
		[disableDate]="disableDates"
		[appendToBody]="appendToBody">
	</date-point>
	<div #ngDropdown ngbDropdown
		(openChange)="onDropdownToggle($event)"
		[autoClose]="false"
		[container]="appendToBody ? 'body' : null"
		display="dynamic"
		placement="bottom-right bottom bottom-left"
		[hidden]="isExactDateRange()"
		class="filter-text-piece test-date-filter-button">
		<a ngbDropdownToggle href="javascript:void(0)" title="{{'filter.testButton'|i18n}}"><i class="q-icon-binoculars"></i></a>
		<div ngbDropdownMenu class="dropdown-menu" role="menu" (keydown.escape)="closeDropdown(); focusToggle($event)">
			<div class="cx-date-filter-test-wrap" #popup>
				<label for="sel-mode">{{'filter.resolve'|i18n}}:</label>
				<ngb-datepicker #picker
					class="well well-sm m-0 p-0 no-border d-block"
					[(ngModel)]="pickerDate"
					(dateSelect)="dateChange($event)"
					[firstDayOfWeek]=7
					[maxDate]="fromDate(datepickerOptions.maxDate)"
					[minDate]="fromDate(datepickerOptions.minDate)"
					[dayTemplate]="dayTemplate"
				></ngb-datepicker>
				<div class="cx-date-filter-test-button-wrap pt-8">
					<button type="button"
						class="btn btn-sm btn-secondary cx-date-filter-test-close"
						(click)="closeDropdown()"
						(keydown.enter)="closeDropdown(); focusToggle($event)"
						>{{'common.close'|i18n}}
					</button>
					<button type="button" class="btn btn-sm btn-secondary"
						(click)="setAsToday();">{{'reportFilters.today'|i18n}}
					</button>
					<button type="button"
						class="btn btn-sm btn-primary btn-ok cx-date-filter-test-button"
						(click)="resolveDateFilter(today, dateRange)"
						>{{'common.test'|i18n}}
					</button>
				</div>
				<li class="divider" role="separator"></li>
				<div class="p-relative test-result">
					<label for="sel-mode" [ngBusy]="{ busy: loadingResolvedDateFilter, disableAnimation: true }"
						>{{'filter.resolveTo'|i18n}}:</label>
					<div class="cx-date-filter-test-start-date" [hidden]="!hasResolvedDateFilterText()">
						{{'reportFilters.fromDateLabel'|i18n}} {{resolvedDateFilterText.startDate}}</div>
					<div class="cx-date-filter-test-end-date" [hidden]="!hasResolvedDateFilterText()">
						{{'reportFilters.toDateLabel'|i18n}} {{resolvedDateFilterText.endDate}}</div>
					<span class="cx-date-filter-test-place-holder" [hidden]="hasResolvedDateFilterText()">
						{{'filter.resolvePlaceHolder'|i18n}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #dayTemplate let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
	<div class="date-point-day btn"
		[class.focused]="focused"
		[class.selected]="selected"
		[class.outside]="date.month !== currentMonth"
		[class.disabled]="disabled">
		{{ date.day }}
	</div>
</ng-template>
