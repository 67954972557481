export enum ContextPaneSubpanels {
	KEY_INFO = 'panel-favorites',
	NARRATIVES = 'panel-narrative',
	INBOX = 'panel-engagor',
	SCORECARDS = 'panel-scorecards',
	ATTRIBUTES = 'panel-attribute',
	ENRICHMENTS = 'panel-world-awareness',
	TOPICS = 'topics-panel',
	AUTOMATED_SUMMARIES = 'panel-automated-summaries',
	COMPLIANCE_MANAGEMENT = 'panel-compliance-management',
}
