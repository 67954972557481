export class ContextMenuItem<T> {
	name: string;
	text?: string;

	/**
	 * Option name with custom html support
	 */
	formattedOptionName?: string;
	func?: (item: T|T[], relatedObject?: any, models?: any, $event?: Event) => void;
	classes?: string[];
	disabled?: boolean;
	disabledMessage?: string;
	isDivider?: boolean;
	searchBox?: boolean;
	isExpandable?: boolean;
}
