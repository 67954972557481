import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import * as cloneDeep from 'lodash.clonedeep';
import { IManageableAsset } from '@cxstudio/asset-management/management/abstract-manage-asset.service';
import { NameService } from '@cxstudio/common/name-service';
import ManageFilterService from '@app/modules/filter/services/manage-filter.service';
import { Folders } from '@cxstudio/folders/folders-constant';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterValidationMessages } from '@cxstudio/report-filters/constants/filter-validation-messages.constant';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { LabelsApiService } from '@cxstudio/services/data-services/labels-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

export interface FilterData {
	attributes;
	models;
	internalProject: boolean;
	projectTimezone;
	projectContext;
	wordAttributes;
	customDateRanges;
	viewMode: boolean;
	canShare: boolean;
}

export interface UI {
	filterItems;
	filterReady: boolean;
	filterOwner: { userEmail: string; };
	filterRulesError: string | null;
	savedFilterName: string;
	labels: {text: string}[];
	targetFolder;
	folders;
}

export interface FilterCreationWizardInput {
	filterBuildingData: FilterData;
	project: AccountOrWorkspaceProject;
	currentList;
	filter: IFilter;
	editMode: boolean;
	folders;
	currentFolder;
}

@Component({
	selector: 'filter-creation-wizard',
	templateUrl: './filter-creation-wizard.component.html',
	styles: [
		`
		[hidden] {
			display: none !important;
		}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCreationWizardComponent implements OnInit, AfterViewInit {
	defaultDateFolder;
	defaultDateFoldersList;
	initialState;
	ui: UI;
	newAdhocFilter;
	initialItem;
	filterModalTitle: string;
	viewMode: boolean;
	closeButtonTitle: string;
	itemList;
	maxFilterLevels: number;
	disableESQuery: boolean;
	testing: string = '';

	@Input() input: FilterCreationWizardInput;
	@ViewChild('filterCreationForm', {static: true}) public filterCreationForm: FormGroup;
	canShare: boolean;
	project: AccountOrWorkspaceProject;

	constructor(
		private readonly modal: NgbActiveModal,
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private ref: ChangeDetectorRef,
		private readonly manageFilterService: ManageFilterService,
		private readonly optionsTemplatesService: OptionsTemplatesService,
		@Inject('nameService') private readonly nameService: NameService,
		@Inject('labelsApiService') private readonly labelsApiService: LabelsApiService,
		@Inject('filterValidationService') private readonly filterValidationService: FilterValidationService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('filterManagementApiService') private readonly filterManagementApiService: FilterManagementApiService,
	) {}

	ngOnInit(): void {
		this.defaultDateFolder = {
			id: -1,
			name: this.locale.getString('filter.dateFilters'),
			nameWithPath: this.locale.getString('filter.dateFilters')
		};
		this.defaultDateFoldersList = [];

		this.newAdhocFilter = {
			type: 'AND',
			filterRules: [{ type: 'EMPTY'}]
		};

		this.maxFilterLevels = 5;
		this.disableESQuery = true;
		this.canShare = this.manageFilterService.canShare(this.input.filter as IManageableAsset) && this.input.filterBuildingData.canShare;

		this.ui = this.ui || {} as UI;
		this.ui.labels = [];
		this.ui.savedFilterName = this.nameService.uniqueName(this.locale.getString(
			'reportFilters.defaultFilterName'), this.input.currentList, 'name');

		this.itemList = this.input.currentList;

		this.viewMode = this.input.filterBuildingData.viewMode;
		this.filterModalTitle = this.locale.getString('reportFilters.createFilter');
		this.closeButtonTitle = this.locale.getString('common.cancel');

		if (this.input.filter && Object.keys(this.input.filter).length) {
			this.initializeEdit(this.input.filter);
		}

		this.initFolders();

		this.project = this.input.project;

		this.ui.filterReady = true;
		const template = this.optionsTemplatesService.filterItems({
			filter: this.input.filter,
			withDateRange: this.input.filterBuildingData.customDateRanges,
			timezone: this.input.filterBuildingData.projectTimezone,
			omitNlpGroup: this.input.filterBuildingData.internalProject
		});
		this.ui.filterItems = this.optionsBuilderProvider.getBuilder()
			.withTemplate(template)
			.withModels(this.input.filterBuildingData.models)
			.withAttributes(this.input.filterBuildingData.attributes, MetricFilters.NOT_DOCUMENT_DATE)
			.withWordAttributes(this.input.filterBuildingData.wordAttributes)
			.build();
		this.initialState = cloneDeep(this.getCurrentSettings());
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.ref.detectChanges());
		this.initialState = cloneDeep(this.getCurrentSettings());
	}

	isFilterNameValid(): boolean {
		return this.filterCreationForm.controls.name?.invalid === true;
	}

	isFilterNameAbsent(): boolean {
		return (this.filterCreationForm.controls.name?.dirty &&
			this.filterCreationForm.controls.name.errors?.required);
	}

	isFilterNameNotUnique(): boolean {
		return this.filterCreationForm &&
			this.filterCreationForm.controls.name?.dirty &&
			(this.filterCreationForm.controls.name?.errors?.unique !== undefined);
	}

	initializeEdit = (filter): void => {
		this.initialItem = filter;
		this.ui.savedFilterName = filter.name;
		this.ui.filterOwner = { userEmail: filter.ownerName };
		this.newAdhocFilter = this.transformFilterRule(filter);
		this.filterModalTitle = this.locale.getString('reportFilters.editFilter');
		this.ui.labels = cloneDeep(filter.labels || []);
		if (this.viewMode) {
			this.filterModalTitle = this.locale.getString('reportFilters.viewFilter');
			this.closeButtonTitle = this.locale.getString('common.close');
		}
	}

	initFolders = (): void => {
		_.map(this.input.folders, (folder: any) => {
			if (!folder.displayName) {
				folder.displayName = folder.name;
			}
		});
		if (this.input.currentFolder) {
			this.input.currentFolder.displayName = this.input.currentFolder.name;
			this.ui.targetFolder = this.input.currentFolder;
		} else {
			this.ui.targetFolder = Folders.ROOT_FOLDER;
		}

		if (this.input.filter.type === FilterTypes.CXSCORECARD) {
			this.input.folders.push(this.input.currentFolder);
		}
		this.ui.folders = [];
		this.ui.folders.pushAll(cloneDeep(this.input.folders || []));
	}

	getCurrentSettings = () => {
		const ui = cloneDeep(this.ui);
		delete ui.folders;
		delete ui.filterItems;
		if (ui.targetFolder && ui.targetFolder.children) {
			delete ui.targetFolder.children;
		}
		return {
			ui,
			filter: this.input.filter,
			newAdhocFilter: this.newAdhocFilter
		};
	}

	validateFilter = (rule: any): boolean => {
		this.ui.filterRulesError = this.getFilterRulesError(rule);
		return !this.ui.filterRulesError && !this.filterCreationForm.invalid;
	}

	private getFilterRulesError = (rule: any): string => {
		const validationResult = this.filterValidationService.getValidationResult(rule);
		return (FilterValidationMessages.VALID === validationResult ? null : validationResult);
	}

	submit = (shareAfterSubmit?: boolean): void => {
		if (!this.validateFilter(this.newAdhocFilter)) {
			return;
		}

		const rule = this.isDateFilter() ? this.newAdhocFilter.filterRules[0] : this.newAdhocFilter;
		this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(rule.filterRules);

		const dialogResult = {
			name: this.ui.savedFilterName,
			rule,
			labels: this.ui.labels
		};

		if (this.isMovableToFolder() && this.isFolderChanged()) {
			dialogResult['parentId'] = this.ui.targetFolder.id ? this.ui.targetFolder.id : null;
		}

		this.modal.close({result: dialogResult, doShare: shareAfterSubmit});
	}

	isFilterValid = (): boolean => {
		return !this.getFilterRulesError(this.newAdhocFilter) && !this.filterCreationForm.invalid;
	}

	dismiss = (): void => {
		if (!this.hasFilterChanged()) {
			this.modal.dismiss('cancel');

			return;
		}

		this.cxDialogService.showUnsavedChangesDialogAndResolve(
			this.submit,
			this.modal.dismiss,
			this.locale.getString('reportFilters.unsavedChangesHeader'),
			this.locale.getString('reportFilters.unsavedChanges'),
			this.filterCreationForm?.invalid
		);
	}

	hasFilterChanged = (): boolean => {
		let finalState = cloneDeep(this.getCurrentSettings());
		let initialJSON = this.buildSettingsJson(this.initialState);
		let finalJSON = this.buildSettingsJson(finalState);
		return this.initialState && !_.isEqual(initialJSON, finalJSON);
	}

	buildSettingsJson(settings) {
		return JSON.stringify(settings, (key, value) => {
			if (['_disabled', '$$hashKey', 'children', 'parent'].includes(key)) {
				return undefined;
			}

			return value;
		});
	}

	labelAdded = (tag): void => {
		this.ui.labels = this.ui.labels || [];
		this.ui.labels.push(tag);
	}

	labelRemoved = (tag): void => {
		this.ui.labels = this.ui.labels.filter(label => label.text !== tag.text);
	}

	loadLabels = (query) => {
		return this.labelsApiService.getLabels(query);
	}

	isDateFilter = (): boolean => {
		return this.newAdhocFilter?.filterRules?.[0]
			&& FilterRuleTypes.isDateRangeRule(this.newAdhocFilter.filterRules[0])
			&& FilterRuleTypes.isDocumentDateRule(this.newAdhocFilter.filterRules[0]);
	}

	transformFilterRule = (filter) => {
		if (filter.dateFilter) {
			return {
				'type': 'AND',
				filterRules: [filter.rule]
			};
		} else {
			return filter.rule;
		}
	}

	getFolders = () => {
		return this.isDateFilter() ? this.defaultDateFoldersList : this.ui.folders;
	}

	getTargetFolder = () => {
		return this.isDateFilter() ? this.defaultDateFolder : this.ui.targetFolder;
	}

	selectFolder = (folder): void => {
		this.ui.targetFolder = folder;
	}

	isMovableToFolder = (): boolean => {
		return this.ui?.folders.length > 0;
	}

	isFolderChanged = (): boolean => {
		const newFolder = this.ui.targetFolder;
		return !isEmpty(this.input.filter)
			&& !isEmpty(newFolder) && (this.input.filter.parent && this.input.filter.parent.id) !== newFolder.id;
	}
}
app.directive('filterCreationWizard', downgradeComponent({ component: FilterCreationWizardComponent }) as angular.IDirectiveFactory);
