import * as _ from 'underscore';

export class TreeService {

	// returns item and its children count
	private countChildren = (array, item, includeItem?): number => {
		let count = includeItem ? 1 : 0; // count of children
		let start = array.indexOf(item);
		for (let i = start + 1; i < array.length && array[i].level > item.level; i++, count++);
		return count;
	}

	updateItem = (array: any[], item, unchangedProperty?: string): void => {
		unchangedProperty = unchangedProperty || 'id';
		let finderObject = {};
		finderObject[unchangedProperty] = item[unchangedProperty];

		let itemIndex = _.findIndex(array, finderObject);
		_.extend(array[itemIndex], item);
	}

	/**
	 * Return removed items as array
	 */
	deleteItem = (array: any[], item): any[] => {
		// remove from parent
		if (item.parent) {
			item.parent.children.remove(item);
		}
		// no children - remove easily
		if (!item.children) {
			let itemInArray = _.find(array, {id: item.id});
			//try to find item in array, in case some properties changes remove wont work
			return array.remove(itemInArray ? itemInArray : item);
		}

		// remove item and all its ancestors
		return array.removeRange(array.indexOf(item), this.countChildren(array, item, true));
	}

	/**
	 * Add item to parent(or null, if to root) in the array
	 */
	addItem = (array: any[], item, parent?): void => {
		// if adding to root - just push to the end
		if (!parent) {
			array.push(item);
			item.level = 0;
			return;
		}
		if (!parent.children) {
			parent.children = [];
		}
		// push to parent's children, fill "parent" field, fill level, push to correct place in array.
		if (!item.parent || item.parent.id !== parent.id) {
			parent.children.push(item);
			item.parent = parent;
		}
		item.level = parent.level + 1;

		array.insert(array.indexOf(parent) + this.countChildren(array, parent) + 1, item);
	}

	/**
	 * Move item to target
	 */
	moveItem = (array: any[], item, target?): void => {
		// delete it first, then put to correct place
		let items = this.deleteItem(array, item);

		item.parent = target;
		if (target === null) {
			delete item['parent'];
		}

		let level = -1;
		if (target) { // target == null, if root
			if (!target.children) {
				target.children = [];
			}
			target.children.push(item);
			level = target.level;
			item.parentId = target.id;
		} else {
			item.parentId = null;
		}
		let levelDiff = level + 1 - item.level;
		items.forEach((treeItem) => { treeItem.level += levelDiff; });

		//put back to array
		if (target) {
			array.insertAll(array.indexOf(target) + this.countChildren(array, target) + 1, items);
		} else {
			array.pushAll(items);
		}
	}

	/**
	 * Copy item without tree properties, to avoid cycles while converting to json
	 */
	copyItem = (item) => {
		let ignore = ['children', 'parent', 'level'];
		let copy = {};
		for (let k in item) {
			if (ignore.indexOf(k) === -1) {
				copy[k] = item[k];
			}
		}
		return copy;
	}
}

app.service('treeService', TreeService);
