import { Observable, Subscribable } from 'rxjs';

export class HybridModeUtils {
	/**
	 * Used to convert observable from angularjs folder to observable from angular (when it complains about incompatible types)
	 * */
	static convertObservable<T>(observable: Subscribable<T>): Observable<T> {
		return observable as any;
	}
}
