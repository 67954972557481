import { Component, ViewChild, ElementRef, Input, Inject, OnInit, ChangeDetectorRef, Output,
	EventEmitter, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { filter, debounce } from 'rxjs/operators';
import { fromEvent, Subscription, timer } from 'rxjs';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { CxLocaleService } from '@app/core';
import { WeightedFilterPipe, FilterMatchMode } from '@app/shared/pipes/weighted-filter.pipe';
import { TokenTypePipe } from './token-type.pipe';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { FormulaSegment, SupportTextErrors, TextToken, TextTokenType } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathErrorType, CustomMathWarningType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathAdapterProvider } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-provider.service';
import { MathAggregation, MathFunction, MathKeyword } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-tokenizer.service';
import { CustomMathFormula } from '@app/modules/metric/definition/custom-math/editor/custom-math-formula.class';
import { ContentEditableHelper } from '@app/modules/metric/definition/custom-math/editor/content-editable-helper.class';
import { MathMetricToken, MathMetricTokenType } from '@app/modules/metric/definition/custom-math/editor/math-metric-token';
import { TokenSuggestion } from '@app/modules/metric/definition/custom-math/editor/custom-math-suggestion.class';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathSuggestion } from '@app/modules/metric/definition/custom-math/editor/custom-math-suggestion.class';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { CustomMathPlaceholderService } from './custom-math-placeholder.service';
import { CustomMathHighlightService } from './custom-math-highlight.service';
import { WeightedSortPipe } from '@app/shared/pipes/weighted-sort.pipe';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { CustomMathKeywordHelper } from './custom-math-keyword-helper.service';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CustomMathMetrics } from '../adapter/custom-math-metrics';
import { CustomMathSuggestionsService, ReplaceText, StickSuggestionSegments, StickSuggestionTokens } from './custom-math-suggestions.service';
import { CustomMathMemoryService, EditorState } from './custom-math-memory.service';
import * as cloneDeep from 'lodash.clonedeep';
import { CustomMathTooltipsService } from './custom-math-tooltips.service';
import { TokenTooltip, TooltipType } from './token-tooltip';
import { CustomMathValidationService } from '@cxstudio/metrics/custom-math/custom-math-validation.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { CustomMathUtils } from './custom-math-utils.service';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ScorecardMetricsManagementService } from '@app/modules/scorecards/metrics/scorecard-metrics-management.service';
import { ScorecardsService } from '@app/modules/scorecards/services/scorecards.service';
import { OrganizationEnrichmentService } from '@app/modules/hierarchy/enrichment/organization-enrichment.service';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';

@Component({
	selector: 'custom-math-editor',
	templateUrl: './custom-math-editor.component.html',
	styles: [`
		:host .formula-input {
			min-height: 120px;
			font-family: monospace;
			overflow-wrap: break-word;
		}
		.dropdown-menu:not(.disabled):not(.read-only) .suggestion-list-item>a:hover {
			fill: var(--white);
			background-color: var(--default-primary-color);
			color: var(--white);
		}

		.offscreen {
			opacity: 0;
			position: absolute;
			left: -4000px !important;
		}
	`]
})

export class CustomMathEditorComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('editor', {static: false}) mathInput: ElementRef;
	@ViewChild('suggestions', {static: false}) suggestionsElement: ElementRef;
	@ViewChild('suggestionSearchInput', {static: false}) suggestionSearchElement: ElementRef;

	@Input() attributes: IReportAttribute[];
	@Input() project: AccountOrWorkspaceProject;
	@Input() expressions: ExpressionItem[];
	@Output() expressionsChange = new EventEmitter<ExpressionItem[]>();
	@Output() validityChange = new EventEmitter<boolean>();

	readonly KEYBOARD_DEBOUNCE_TIME = 250;
	readonly RESERVED_WORDS = [
		..._.values(MathAggregation),
		..._.values(MathFunction),
		..._.values(MathKeyword)
	];
	readonly PREFIX = _.values(MathKeyword);

	readonly TOKEN_TYPES = [
		MathMetricTokenType.METRIC,
		MathMetricTokenType.PREDEFINED_METRIC,
		MathMetricTokenType.NUMERIC_ATTRIBUTE,
		MathMetricTokenType.TEXT_ATTRIBUTE,
		MathMetricTokenType.NUMERIC_AGGREGATION,
		MathMetricTokenType.TEXT_AGGREGATION,
		MathMetricTokenType.SCORECARD_METRIC,
		MathMetricTokenType.HIERARCHY_METRIC,
		MathMetricTokenType.RESERVED_WORD,
		MathMetricTokenType.PREFIX
	];

	// keys that should not trigger a reprocess of the input value
	readonly NEVER_TRIGGER_KEYS: string[] = [
		Key.END, Key.HOME, Key.PAGEDOWN, Key.PAGEUP,
		Key.LEFT, Key.RIGHT, Key.DOWN, Key.UP,
		KeyModifier.CTRL, KeyModifier.SHIFT, ' '
	];
	// keys that should only trigger suggestions navigation and not reprocess of text values
	readonly MENU_ONLY_KEYS: string[] = [Key.ESCAPE, Key.TAB];

	formula: CustomMathFormula;

	currentTextBlock: string;
	allowedSuggestionTypes: string[];
	showSuggestions: boolean = false;
	placeholderShown: boolean;

	availableSuggestions: TokenSuggestion[];

	attributesSuggestions: TokenSuggestion[];
	metricSuggestions: TokenSuggestion[];
	hierarchyMetricsSuggestions: TokenSuggestion[];

	numericAggregationsSuggestions: TokenSuggestion[];

	onKeydown: Subscription;
	highlightedSuggestionIndex: number = 0;
	suggestionsX;
	suggestionsY;
	isSuggestedWrap: boolean;
	tokenSuggestionDisplayStyle: string;

	suggestionSearch: string = '';
	showSuggestionSearch: boolean;

	loading: Promise<any>;

	singleClickTimer = null;

	customMathErrors: string[];
	customMathWarnings: string[];
	dismissedWarnings: string[] = [];

	assets: CustomMathAssets;

	appliedMetrics: CustomMathMetrics;

	submenuOpenedFromSuggestion: TokenSuggestion;

	skipEditorStateRemembering: boolean;

	tokenTooltip: TokenTooltip = {};

	lastValidFormulaSegments: FormulaSegment[];

	maxHeight: number;
	itemSize = 36; // height of each suggestion item in cdk-virtual-scroll

	private contentEditableHelper: ContentEditableHelper;
	private suggestionCaretPosition: number;
	private formulaValid: boolean;
	private errorMessages: {[key in CustomMathErrorType]: string};
	private warningMessages: {[key in CustomMathWarningType]: string};

	onInput = _.debounce(() => {
		let skipNextRemembering = this.skipEditorStateRemembering;
		this.skipEditorStateRemembering = false;
		this.onChange(!skipNextRemembering);
	}, 300);

	constructor(
		@Inject('reportMetricService') private reportMetricService: ReportMetricService,
		@Inject('metricUtils') private metricUtils: MetricUtils,
		@Inject('MetricCalculationsType') private MetricCalculationsType: MetricCalculationsTypeService,
		@Inject('customMathValidationService') private customMathValidationService: CustomMathValidationService,
		private customMathPlaceholderService: CustomMathPlaceholderService,
		private customMathSuggestionsService: CustomMathSuggestionsService,
		private customMathMemoryService: CustomMathMemoryService,
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private weightedFilterPipe: WeightedFilterPipe<TokenSuggestion>,
		private customMathAdapterProvider: CustomMathAdapterProvider,
		private customMathHighlightService: CustomMathHighlightService,
		private customMathKeywordHelper: CustomMathKeywordHelper,
		private customMathTooltipsService: CustomMathTooltipsService,
		private tokenTypePipe: TokenTypePipe,
		private prioritySortPipe: WeightedSortPipe<TokenSuggestion>,
		private elementRef: ElementRef,
		private readonly metricsService: MetricsService,
		private readonly scorecardsService: ScorecardsService,
		private readonly scorecardMetricsManagementService: ScorecardMetricsManagementService,
		private readonly organizationEnrichmentService: OrganizationEnrichmentService,
	) {}

	ngOnInit(): void {
		this.expressions = this.expressions || [];
		this.initMessages();

		this.loading = Promise.all(this.buildPromises()).then((response: any) => {

			// for predefined-metric suggestions
			let standardMetrics: Metric[] = this.reportMetricService.getStandardMetrics();
			let predefinedMetrics: Metric[] = this.metricUtils.toPredefinedCalculation(response[1]);

			// for metric suggestions
			let customMetrics: Metric[] = response[0].filter(oneMetric => {
				return !oneMetric.hide
					&& !this.MetricCalculationsType.CUSTOM_MATH.is(oneMetric)
					&& oneMetric.type !== FolderTypes.METRIC;
			});

			// for scorecard-metric suggestions
			let scorecardMetrics: ScorecardMetric[] = response[3];

			// for scorecard TextToken tooltips
			let scorecards = response[4];

			// for hierarchy-metric metrics suggestions
			let hierarchies: ReportableHierarchy[] = response[2];

			// assets
			this.assets = {
				attributes: this.attributes,
				metrics: customMetrics,
				standardMetrics,
				predefinedMetrics,
				hierarchies,
				scorecardMetrics,
				scorecards
			};
			let hasRemovedMetrics: boolean = this.populateAppliedMetrics();

			// should be invoked after this.assets are populated
			this.populateSuggestions(standardMetrics, predefinedMetrics, customMetrics, scorecardMetrics, hierarchies);

			let customMathAdapter = this.customMathAdapterProvider.getInstance(this.assets);
			this.formula = new CustomMathFormula(customMathAdapter, this.expressions, this.appliedMetrics);
			this.expressions = this.formula.getExpressions();
			if (this.formula.getText() === '') {
				this.mathInput.nativeElement.replaceChildren(this.initEditorContentWrapperWithPlaceholder());
			} else {
				this.mathInput.nativeElement.replaceChildren(this.highlight());
				this.customMathTooltipsService.updateTooltipElements(this.onTooltipSpanMouseenter, this.onTooltipSpanMouseleave);
			}

			// apply the first metric of the same type with the same displayName otherwise skip it as not recognized
			if (hasRemovedMetrics) {
				this.populateAppliedMetrics();
			}
			CustomMathSuggestion.populateShowWarning(this.metricSuggestions, this.appliedMetrics);

			this.customMathMemoryService.setInitialEditorState(this.formula.getText(), this.appliedMetrics);

			this.formulaValid = this.formula.isValid();
			this.lastValidFormulaSegments = this.formula.getSegments();
			this.validityChange.emit(this.formulaValid);
		});
	}

	private initMessages(): void {
		this.errorMessages = {
			HANGING_OPERATOR: 'metrics.errorHangingOrConsecutiveOperator',
			CONSECUTIVE_OPERATOR: 'metrics.errorHangingOrConsecutiveOperator',
			CONSECUTIVE_VARIABLE: 'metrics.errorConsecutiveVariable',
			EMPTY_PARENTHESES: 'metrics.errorEmptyParentheses',
			INVALID_TEXT: 'metrics.errorInvalidText',
			IMPLIED_MULTIPLICATION: 'metrics.errorConsecutiveVariable',
			MISSED_REFERENCE: 'metrics.errorMissedReference',
			ATTRIBUTE_MISMATCH: 'metrics.errorAttributeMismatch',
			NOT_COMPLETED_FUNCTION: 'metrics.notCompletedFunction',
			NOT_COMPLETED_OPERATORS: 'metrics.notClosedBracketsOrParenthesis',
			MAX_VARIABLES_IN_EXPRESSION: 'metrics.invalidTooManyVariables',
			NOT_SUPPORTED_AGGREGATION: 'metrics.invalidNotSupportedAggregation'
		};
		this.warningMessages = {
			DUPLICATE_NAME_WITH_OWNER: 'metrics.warningDuplicateNameWithOwner',
			DUPLICATE_NAME_WITH_PATH: 'metrics.warningDuplicateNameWithPath'
		};
	}

	private buildPromises(): Promise<any>[] {
		const hasProjectId: boolean = WorkspaceTransitionUtils.isProjectSelected(this.project);
		const workspace = WorkspaceTransitionUtils.getWorkspace(this.project);

		let metricsPromise: any = hasProjectId
			? this.metricsService.getMetrics(this.project)
			: this.metricsService.getMetricsForWorkspace(workspace);

		let predefinedMetricsPromise: any = hasProjectId
			? this.metricsService.getPredefinedMetrics(this.project)
			: this.metricsService.getPredefinedMetricsForWorkspace(workspace);

		let hierarchyPromise: Promise<any> = this.organizationEnrichmentService.getReportableHierarchies(this.project);
		let scorecardMetricsPromise: Promise<any> = this.scorecardMetricsManagementService.getScorecardMetrics(this.project);
		let scorecardsPromise: Promise<any> = this.scorecardsService.getScorecards(this.project);

		return [metricsPromise, predefinedMetricsPromise, hierarchyPromise, scorecardMetricsPromise, scorecardsPromise];
	}

	private populateSuggestions(standardMetrics: Metric[], predefinedMetrics: Metric[], customMetrics: Metric[],
		scorecardMetrics: ScorecardMetric[], hierarchies: ReportableHierarchy[]): void {
			this.populateMetricsSuggestions(standardMetrics, predefinedMetrics, customMetrics, scorecardMetrics);
			this.attributesSuggestions = this.attributes.map(attr => CustomMathSuggestion.getAttributeSuggestion(attr));
			this.populateHierarchyMetricsSuggestions(hierarchies);

			this.availableSuggestions = []
				.concat(this.metricSuggestions)
				.concat(this.attributesSuggestions)
				.concat(this.customMathSuggestionsService.getNumericAggregations())
				.concat(this.customMathSuggestionsService.getGenericAggregations())
				.concat([CustomMathSuggestion.getMathFunctionSuggestion('abs')])
				.concat(this.hierarchyMetricsSuggestions);
	}

	private populateMetricsSuggestions(
		standardMetrics: Metric[], predefinedMetrics: Metric[], customMetrics: Metric[], scorecardMetrics: ScorecardMetric[]): void {
			let customMetricSuggestions = customMetrics.map(metric => CustomMathSuggestion.getMetricSuggestion(metric,
				this.locale.getString('metrics.warningReferencesReplacement', { displayName: metric.displayName })));
			this.populateHelpMessages(customMetricSuggestions);

			let enabledScorecardMetrics = scorecardMetrics.filter(metric => !metric.disabled);
			let scorecardMetricSuggestions = enabledScorecardMetrics.map(metric => CustomMathSuggestion.getScorecardMetricSuggestion(metric,
				this.locale.getString('metrics.warningReferencesReplacement', { displayName: metric.displayName })));
			this.populateHelpMessages(scorecardMetricSuggestions);

			this.metricSuggestions = []
				.concat(standardMetrics.map(metric => CustomMathSuggestion.getPredefinedMetricSuggestion(metric)))
				.concat(predefinedMetrics.map(metric => CustomMathSuggestion.getPredefinedMetricSuggestion(metric)))
				.concat(customMetricSuggestions)
				.concat(scorecardMetricSuggestions);
	}

	private populateHelpMessages(suggestions: TokenSuggestion[]): void {
		let suggestionHelpMessages: string[] = _.map(suggestions, suggestion => {
			if (suggestion.tokenType === MathMetricTokenType.METRIC) {
				let customMetric = _.findWhere(this.assets.metrics, {name: suggestion.metricName});
				return this.locale.getString('metrics.ownerTitle', { owner: customMetric.ownerName });
			} else if (suggestion.tokenType === MathMetricTokenType.SCORECARD_METRIC) {
				let scorecardMetric = _.findWhere(this.assets.scorecardMetrics, {name: suggestion.metricName});
				return scorecardMetric.nodeFullPath;
			}
		});
		CustomMathSuggestion.populateHelpMessages(suggestions, suggestionHelpMessages);
	}

	private populateHierarchyMetricsSuggestions(hierarchies: ReportableHierarchy[]): void {
		this.hierarchyMetricsSuggestions = [];

		hierarchies.forEach(hierarchy => {
			this.hierarchyMetricsSuggestions = this.hierarchyMetricsSuggestions.concat(CustomMathSuggestion.getHierarchySuggestions(hierarchy));
		});
	}

	private populateAppliedMetrics(): boolean {
		let hasRemovedMetrics = false;

		this.appliedMetrics = {
			customMetrics: [],
			scorecardMetrics: [],
		};

		_.each(this.expressions, expression => {
			switch (expression.type) {
				case(ExpressionPieces.METRIC):
					let appliedMetric: Metric = _.findWhere(this.assets.metrics, {name: expression.name});
					if (!appliedMetric) {
						hasRemovedMetrics = true;
					} else if (!_.contains(this.appliedMetrics.customMetrics, appliedMetric)) {
						this.appliedMetrics.customMetrics.push(appliedMetric);
					}
					break;
				case(ExpressionPieces.SCORECARD_STUDIO_METRIC):
					let appliedScorecardMetric: ScorecardMetric = _.findWhere(this.assets.scorecardMetrics, {name: expression.name});
					if (!appliedScorecardMetric) {
						hasRemovedMetrics = true;
					} else if (!_.contains(this.appliedMetrics.scorecardMetrics, appliedScorecardMetric)) {
						this.appliedMetrics.scorecardMetrics.push(appliedScorecardMetric);
					}
					break;
			}
		});

		return hasRemovedMetrics;
	}

	ngAfterViewInit(): void {
		this.contentEditableHelper = new ContentEditableHelper(this.mathInput.nativeElement);
		this.onKeydown = fromEvent(this.mathInput.nativeElement, 'keydown')
			.pipe(
				filter(Boolean),
				filter((event: KeyboardEvent) =>
					!this.NEVER_TRIGGER_KEYS.includes(event.key) && (!this.MENU_ONLY_KEYS.includes(event.key) || this.showSuggestions)),
				debounce((event: KeyboardEvent) => this.showImmediate(event) ? timer(0) : timer(this.KEYBOARD_DEBOUNCE_TIME))
			).subscribe(this.processKeyboard);
	}

	private showImmediate(event: KeyboardEvent): boolean {
		return this.isMenuNavigationEvent(event) || KeyboardUtils.isUndoEvent(event) || KeyboardUtils.isRedoEvent(event);
	}

	ngOnDestroy(): void {
		// make 100% sure we don't leave any dom elements behind in memory
		this.mathInput.nativeElement.remove();
		this.mathInput = null;

		this.onKeydown.unsubscribe();
	}

	handleSuggestionsKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			KeyboardUtils.handleUpDownNavigation(event, this.suggestionsElement.nativeElement);
		}
	}

	onChange = (saveEditorState?: boolean): void => {
		if (!this.formula) return;
		this.tokenTooltip = {};
		let selectionIndexes = this.contentEditableHelper.getCurrentSelection();
		let text = this.mathInput.nativeElement.textContent;
		if (saveEditorState) {
			this.customMathMemoryService.remember(text, this.appliedMetrics, this.contentEditableHelper.getCaretPosition());
		}
		this.formula.setText(text, this.appliedMetrics);
		this.mathInput.nativeElement.replaceChildren(this.highlight());

		this.customMathTooltipsService.updateTooltipElements(this.onTooltipSpanMouseenter, this.onTooltipSpanMouseleave);

		this.contentEditableHelper.restoreSelection(selectionIndexes);
		this.expressions = this.formula.getExpressions();
		this.populateAppliedMetrics();
		let changesValid = this.formula.isValid();
		if (changesValid) {
			this.expressionsChange.emit(this.expressions);
		}
		if (changesValid !== this.formulaValid) {
			this.validityChange.emit(changesValid);
		}

		this.formulaValid = changesValid;
		if (this.formulaValid) {
			this.lastValidFormulaSegments = cloneDeep(this.formula.getSegments());
		}

		CustomMathSuggestion.populateShowWarning(this.metricSuggestions, this.appliedMetrics);
	}

	processEditorKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isUndoEvent(event)) {
			KeyboardUtils.intercept(event);
			return;
		}

		let text: string = this.mathInput.nativeElement.textContent;
		let caretPosition: number = this.contentEditableHelper.getCaretPosition();
		if (KeyboardUtils.isEventKey(event, Key.LEFT_BRACKET)
			&& this.customMathKeywordHelper.isKeywordBefore(text, caretPosition)
			&& !this.customMathKeywordHelper.nextCharIsClosingBracket(text, caretPosition)) {
				KeyboardUtils.intercept(event);
				this.mathInput.nativeElement.textContent = this.customMathKeywordHelper.addBrackets(text, caretPosition);
				this.contentEditableHelper.setCaretPosition(caretPosition + 1);
				this.onInput();
		}
		this.processArrowKeys(event);
	}

	private processArrowKeys(event: KeyboardEvent): void {
		if (this.showSuggestions && KeyboardUtils.isEventKeyOneOf(event, [Key.UP, Key.DOWN])) {
			return;
		}
		if (this.isNavigationToSubmenu(event)) {
			KeyboardUtils.intercept(event);
			this.navigateSuggestions(event.key);
			return;
		}
		let selectionIndexes = this.contentEditableHelper.getCurrentSelection();
		let hasSelection = selectionIndexes.anchorIndex !== selectionIndexes.focusIndex;
		if (KeyboardUtils.isEventKeyOneOf(event, [Key.LEFT, Key.RIGHT, Key.UP, Key.DOWN])) {
			if (KeyboardUtils.isEventKey(event, Key.LEFT) && hasSelection) {
				this.setCaretPosition(selectionIndexes.focusIndex);
			}
			if (KeyboardUtils.isEventKey(event, Key.RIGHT) && hasSelection) {
				this.setCaretPosition(selectionIndexes.anchorIndex);
			}
			let prevNode = this.contentEditableHelper.getSelectionNode();
			setTimeout(() => {
				let curNode = this.contentEditableHelper.getSelectionNode();
				if (curNode) {
					if (this.targetIsSwappable(curNode.parentElement)) {
						if (!hasSelection && curNode !== prevNode) {
							this.contentEditableHelper.selectElementContent(curNode);
							this.provideSuggestionsOfType(this.getTokenTypes(curNode.parentElement), true);
						} else {
							this.provideSuggestions(this.getTokenTypes(curNode.parentElement), false, true);
						}
					} else {
						this.hideSuggestions();
					}
				}
			});
		}
	}

	hasErrors = (items: SupportTextErrors[]): boolean => {
		return !items.filter(item => item.errors).isEmpty();
	}

	/**
	 * Checks if keyboard event should be handled by menu navigation and if so prevents
	 * it from moving the caret in the metric editor
	 */
	private isMenuNavigationEvent = (event: KeyboardEvent): boolean => {
		if (this.showSuggestions && (this.MENU_ONLY_KEYS.includes(event.key) || event.key === Key.ENTER)) {
			return true;
		}
	}

	private isNavigationToSubmenu(event: KeyboardEvent): boolean {
		return this.showSuggestions
			&& event.key === Key.RIGHT
			&& this.hasSubmenuArrow(this.getFilteredSuggestions()[this.highlightedSuggestionIndex]);
	}

	private processKeyboard = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isUndoEvent(event)) {
			this.undoChanges();
			return;
		} else if (KeyboardUtils.isRedoEvent(event)) {
			this.redoChanges();
			return;
		} else if (event.metaKey) {
			return;
		}

		if (this.isMenuNavigationEvent(event)) {
			KeyboardUtils.intercept(event);
			this.navigateSuggestions(event.key);
			this.ref.markForCheck();
			this.ref.detectChanges();
			return;
		}

		if (this.isSeparatorCharacter(event.key) && !this.isMetricStartCharacter(event.key)) {
			this.hideSuggestions();
			this.ref.markForCheck();
			this.ref.detectChanges();
		} else {
			this.onChange();
			this.provideSuggestions(this.getTypeLimitations());
		}
	}

	undoChanges(buttonBarAction?: boolean): void {
		this.hideSuggestions();
		if (this.customMathMemoryService.canUndo()) {
			let editorState: EditorState = this.customMathMemoryService.undo();
			this.updateEditorState(editorState, buttonBarAction);
		}
	}

	redoChanges(buttonBarAction?: boolean): void {
		this.hideSuggestions();
		if (this.customMathMemoryService.canRedo()) {
			let editorState: EditorState = this.customMathMemoryService.redo();
			this.updateEditorState(editorState, buttonBarAction);
		}
	}

	private updateEditorState(editorState: EditorState, buttonBarAction?: boolean): void {
		if (!buttonBarAction) {
			this.skipEditorStateRemembering = true;
		}
		this.mathInput.nativeElement.textContent = editorState.text;
		this.appliedMetrics = editorState.appliedMetrics;
		this.contentEditableHelper.setCaretPosition(editorState.caretPosition);
		this.onChange();
	}

	getTypeLimitations = (): string[] => {
		this.suggestionCaretPosition = this.contentEditableHelper.getCaretPosition();
		let previousTokens = this.formula.getPreviousTokens(this.getCurrentTextBlockPosition().startIndex);
		if (previousTokens?.length > 1 && previousTokens[0].text === '[') {
			return this.getTypeLimitationsByToken(previousTokens[1]);
		} else if (previousTokens?.length > 2 && previousTokens[0].text === '' && previousTokens[1].text === '[') {
			return this.getTypeLimitationsByToken(previousTokens[2]);
		}
		return [];
	}

	getTypeLimitationsByToken = (keyword: MathMetricToken): string[] => {
		if (keyword.type === TextTokenType.NUMERIC_AGGREGATION) {
			return [ MathMetricTokenType.NUMERIC_ATTRIBUTE ];
		}

		if (keyword.type === TextTokenType.TEXT_AGGREGATION) {
			return [ MathMetricTokenType.TEXT_ATTRIBUTE];
		}

		if (keyword.type === TextTokenType.GENERIC_AGGREGATION) {
			return [ MathMetricTokenType.NUMERIC_ATTRIBUTE, MathMetricTokenType.TEXT_ATTRIBUTE];
		}

		if (keyword.type === TextTokenType.PREFIX) {
			if (keyword.text === 'metric') return [ MathMetricTokenType.METRIC ];
			if (keyword.text === 'scorecard') return [ MathMetricTokenType.SCORECARD_METRIC ];
			if (keyword.text === 'hierarchy') return [ MathMetricTokenType.HIERARCHY_METRIC ];
		}

		return [ MathMetricTokenType.PREDEFINED_METRIC ];
	}

	getFilteredSuggestions = (): TokenSuggestion[] => {
		let filteredSuggestions = this.getSuggestions(this.currentTextBlock, FilterMatchMode.PARTIAL);
		// suggestion menu display is controlled by below AND this.showSuggestions
		this.tokenSuggestionDisplayStyle = filteredSuggestions.length ? 'block' : 'none';
		setTimeout(() => {
			$(this.suggestionsElement.nativeElement).find('cdk-virtual-scroll-viewport').css('max-height', `${this.maxHeight}px`);
		}, 0);
		return filteredSuggestions;
	}

	getMatchedSuggestion = (): TokenSuggestion | null => {
		let matches = this.getSuggestions(this.getCurrentTextBlock(), FilterMatchMode.EXACT);
		if (matches.length === 1)
			return matches[0];
		matches = this.getSuggestions(this.getCurrentTextBlock(), FilterMatchMode.CASE_INSENSITIVE);
		if (matches.length === 1)
			return matches[0];
		return null;
	}

	private getSuggestions(search: string, matchMode?: FilterMatchMode): TokenSuggestion[] {
		let filteredByType = this.tokenTypePipe.transform(this.availableSuggestions, this.allowedSuggestionTypes);
		if (search) {
			return this.weightedFilterPipe.transform(filteredByType, 'displayName', search, matchMode);
		} else {
			let weightFunction = (suggestion: TokenSuggestion): number => {
				if (this.formula.getTokens().find(token =>
						token.text === suggestion.displayName
							&&  CustomMathSuggestion.getTextTokenTypes(suggestion).contains(token.type)
				)) return 2;
				return 0;
			};
			return this.prioritySortPipe.transform(filteredByType, weightFunction, 'displayName');
		}
	}

	private navigateSuggestions(pressedKey: string): void {
		// escape to hide suggestions
		if (pressedKey === Key.ESCAPE) {
			this.hideSuggestions();
			return;
		}

		let filteredSuggestions = this.getFilteredSuggestions();

		if (pressedKey === Key.ENTER || pressedKey === Key.TAB || pressedKey === Key.RIGHT) {
			this.handleSuggestionClick(filteredSuggestions[this.highlightedSuggestionIndex], this.highlightedSuggestionIndex, true);
		}
	}

	private setSuggestionsPosition(): void {
		let coordinates = this.contentEditableHelper.getSuggestionCoordinates();
		this.suggestionsX = `${coordinates.x}px`;
		this.suggestionsY = `${coordinates.y}px`;
	}

	private provideSuggestionsOfType = (types: string | string[], withSearch?: boolean): void => {
		if (typeof types === 'string')
			types = [types];
		this.provideSuggestions(types, true, withSearch);
	}

	private provideSuggestions(limitToTypes?: string[], bypassTextSearch?: boolean, withSearch?: boolean): void {
		this.allowedSuggestionTypes = limitToTypes;
		this.suggestionCaretPosition = this.contentEditableHelper.getCaretPosition();

		let boundaries = this.getCurrentTextBlockPosition();
		let isWrapped = this.isCurrentTextBlockWrapped(boundaries.startIndex);

		let currentToken = this.formula.getTokenAtPosition(boundaries.startIndex);
		let text = currentToken?.text || '';
		this.currentTextBlock = text.substring(0, this.suggestionCaretPosition - boundaries.startIndex);

		let currentSelection = this.contentEditableHelper.getCurrentSelection();

		let currentSegment = this.customMathSuggestionsService.getSegmentByOffset(
			this.formula.getSegments(), this.getCurrentTextBlockPosition().startIndex);

		let hasErrors = !!currentSegment?.errors?.length || !!currentToken?.errors?.length;
		let currentTokenSelected = currentSelection.anchorIndex === boundaries.startIndex &&
		currentSelection.focusIndex === boundaries.endIndex + 1;

		// hide suggestions dropdown if current element is selected, CB-20748.
		// show dropdown if segment has errors to allow editing.
		if (!currentTokenSelected && !hasErrors) {
			this.hideSuggestions();
			return;
		}

		if (!this.currentTextBlock.trim().length && !bypassTextSearch && !isWrapped
			|| currentToken?.type === TextTokenType.SYNTAX) {
			// may be caused by backspace or something
			this.hideSuggestions();
			return;
		}

		// if we only want to search by type...
		if (bypassTextSearch) {
			this.currentTextBlock = '';

			// can't show only by type if we don't have type...
			if (!this.allowedSuggestionTypes) {
				this.hideSuggestions();
				return;
			}
		}

		this.setSuggestionsPosition();
		this.showSuggestions = true;
		this.showSuggestionSearch = withSearch;
		this.suggestionSearch = ''; // clear search every time popup is opened
		this.highlightedSuggestionIndex = 0;
		this.ref.markForCheck();
		this.ref.detectChanges();
	}

	hideSuggestions(): void {
		this.showSuggestions = false;
		this.highlightedSuggestionIndex = 0;
		this.ref.markForCheck();
		this.ref.detectChanges();
	}


	hasSubmenuArrow = (suggestion: TokenSuggestion): boolean => {
		return this.isNumericAttribute(suggestion) && !this.isInsideAggregation(MathMetricTokenType.NUMERIC_ATTRIBUTE);
	}

	private isNumericAttribute = (suggestion: TokenSuggestion): boolean => {
		return suggestion?.tokenType === MathMetricTokenType.NUMERIC_ATTRIBUTE;
	}

	private isInsideAggregation(type: MathMetricTokenType): boolean {
		return _.contains(this.allowedSuggestionTypes, type);
	}

	handleSuggestionClick(suggestion: TokenSuggestion, index: number, focusSubmenu?: boolean): void {
		if (this.hasSubmenuArrow(suggestion)) {
			if (this.isOpenClick(suggestion)) {
				this.highlightedSuggestionIndex = index;
				this.saveAttributeSuggestion(suggestion);
				if (focusSubmenu) {
					setTimeout(() => {
						$('.aggregations-submenu > li.active .math-aggregation').trigger('focus');
					});
				}
			} else {
				this.resetAttributeSuggestion();
			}
		} else {
			this.resetAttributeSuggestion();
			this.applySuggestion(suggestion);
		}
	}

	private isOpenClick(suggestion: TokenSuggestion): boolean {
		return this.submenuOpenedFromSuggestion === undefined || this.submenuOpenedFromSuggestion !== suggestion;
	}

	private saveAttributeSuggestion(suggestion: TokenSuggestion): void {
		this.submenuOpenedFromSuggestion = suggestion;
	}

	resetAttributeSuggestion(): void {
		this.submenuOpenedFromSuggestion = undefined;
	}

	onSuggestionsDropdownScroll(event: any): void {
		event.stopPropagation();
		this.resetAttributeSuggestion();
	}

	showAggregationsSubmenu(suggestion: TokenSuggestion): boolean {
		return suggestion === this.submenuOpenedFromSuggestion;
	}

	onSubmenuItemClick(numericAggregationSuggestion: TokenSuggestion): void {
		this.applySuggestion(this.submenuOpenedFromSuggestion, true);
		this.applySuggestion(numericAggregationSuggestion);
		this.resetAttributeSuggestion();
	}

	getSuggestionIcon(suggestion: TokenSuggestion): string {
		return this.customMathSuggestionsService.getIconClass(suggestion);
	}

	applySuggestion = (suggestion: TokenSuggestion, skipEditorStateRemembering?: boolean): void => {
		this.suggestionSearch = '';
		this.hideSuggestions();
		if (!suggestion) return;

		let newCaretPosition: number;
		if (!_.isEmpty(this.lastValidFormulaSegments) && this.isSuggestionStickToAggregationOrKeyword()) {
			// inserting aggregation or keyword suggetsion at the beginning, at the end or in between of an existing one.
			let stickSuggestionSegments: StickSuggestionSegments = this.getStickSuggestionSegments();

			let nearestNotEmptyStoredSegment: FormulaSegment = this.customMathSuggestionsService.getNearestNotEmptySegment(
				this.lastValidFormulaSegments,
				_.indexOf(this.lastValidFormulaSegments, stickSuggestionSegments.stored)
			);

			if (stickSuggestionSegments.next && nearestNotEmptyStoredSegment) {
				let currentTextBlockStartOffset: number = this.getCurrentTextBlockPosition().startIndex;

				let replaceText: ReplaceText = this.customMathSuggestionsService.getReplaceText(
					suggestion, stickSuggestionSegments, nearestNotEmptyStoredSegment, currentTextBlockStartOffset);

				this.mathInput.nativeElement.textContent =
					this.mathInput.nativeElement.textContent.replaceAt(replaceText.from, replaceText.to, replaceText.value);

				newCaretPosition = this.customMathSuggestionsService
					.getAfterStickSuggestionAppliedCaretPosition(suggestion.tokenType, currentTextBlockStartOffset, replaceText.value);
			}

		} else if (!_.isEmpty(this.lastValidFormulaSegments) && this.isSuggestionInsideExistingAttribute()) {
			// inserting attribute suggetsion between brackets at the beginning, at the end or in between of an existing one.
			let tokens: StickSuggestionTokens = this.getSuggestionTokens();

			let replaceText: ReplaceText = this.customMathSuggestionsService.getReplaceTextForTokens(suggestion, tokens);

			this.mathInput.nativeElement.textContent =
					this.mathInput.nativeElement.textContent.replaceAt(replaceText.from, replaceText.to, replaceText.value);

			newCaretPosition = this.customMathSuggestionsService
				.getAfterInsideAttributeSuggestionAppliedCaretPosition(suggestion, tokens);
		} else {
		// TODO: if this is a swap, we won't want to use insertValue as that includes parenthesis
			newCaretPosition = this.replaceCurrentWith(suggestion);
		}

		let boundaries = this.getCurrentTextBlockPosition();
		let leadingTokens = this.formula.getPreviousTokens(boundaries.startIndex);
		this.contentEditableHelper.setCaretPosition(newCaretPosition);
		if (suggestion.onAfterInsert) {
			this.provideSuggestionAfterInsert(suggestion, leadingTokens);
		}

		let appliedMetricsBeforeUpdate: CustomMathMetrics = cloneDeep(this.appliedMetrics);
		this.updateAppliedMetrics(suggestion);

		let saveEditorState: boolean = this.customMathMemoryService.saveEditorStateOnSuggestionApply(
			skipEditorStateRemembering, suggestion, appliedMetricsBeforeUpdate, this.appliedMetrics);

		this.onChange(saveEditorState);
		if (CustomMathUtils.isAggregation(suggestion.tokenType)) {
			this.provideSuggestions(this.getTypeLimitations());
		}
	}

	isSuggestionStickToAggregationOrKeyword(): boolean {
		return this.customMathSuggestionsService.isSuggestionStickToAggregationOrKeyword(
				this.formula.getSegments(), this.lastValidFormulaSegments, this.getCurrentTextBlockPosition().startIndex);
	}

	isSuggestionInsideExistingAttribute(): boolean {
		return this.customMathSuggestionsService.isSuggestionInsideExistingAttribute(
				this.formula.getTokens(), this.formula.getTokensFromSegments(this.lastValidFormulaSegments),
				this.getCurrentTextBlockPosition().startIndex);
	}

	getSuggestionTokens(): StickSuggestionTokens {
		return this.customMathSuggestionsService.getSuggestionTokens(
			this.formula.getTokens(), this.formula.getTokensFromSegments(this.lastValidFormulaSegments),
			this.getCurrentTextBlockPosition().startIndex);
	}

	getStickSuggestionSegments(): StickSuggestionSegments {
		return this.customMathSuggestionsService.getStickSuggestionSegments(
			this.formula.getSegments(), this.lastValidFormulaSegments, this.getCurrentTextBlockPosition().startIndex);
	}

	updateAppliedMetrics(suggestion: TokenSuggestion): void {
		if (suggestion.tokenType === MathMetricTokenType.METRIC) {
			this.updateMetrics(suggestion, this.appliedMetrics.customMetrics, this.assets.metrics);
		} else if (suggestion.tokenType === MathMetricTokenType.SCORECARD_METRIC) {
			this.updateMetrics(suggestion, this.appliedMetrics.scorecardMetrics, this.assets.scorecardMetrics);
		}
	}

	private updateMetrics(suggestion: TokenSuggestion, metrics: any[], metricAssets: any[]): void {
		let appliedMetric = _.findWhere(metrics, {displayName: suggestion.displayName});
		if (appliedMetric) {
			if (appliedMetric.name !== suggestion.metricName) {
				metrics.remove(appliedMetric);
				metrics.push(_.findWhere(metricAssets, {name: suggestion.metricName}));
			}
		} else {
			metrics.push(_.findWhere(metricAssets, {name: suggestion.metricName}));
		}
	}

	private provideSuggestionAfterInsert(suggestion: TokenSuggestion, leadingTokens: MathMetricToken[]): void {
		let afterInsert = suggestion.onAfterInsert(leadingTokens) || {};
		this.isSuggestedWrap = afterInsert.suggestedWrap;
		if (afterInsert.typeToSuggest) {
			this.provideSuggestionsOfType(afterInsert.typeToSuggest);
		}
	}

	onAfterInsert(data: {suggestion: TokenSuggestion, leadingTokens: MathMetricToken[]}): void {
		this.provideSuggestionAfterInsert(data.suggestion, data.leadingTokens);
	}

	setCaretPosition(newCaretPosition: number): void {
		this.contentEditableHelper.setCaretPosition(newCaretPosition);
	}

	addTextToContent(text: string): void {
		this.mathInput.nativeElement.textContent = this.mathInput.nativeElement.textContent + text;
	}

	/**
	 * Inserts the desired text in place of the current block.
	 * Block is defined as everything from the caret position out in both directions until a separator character is reached.
	 * @param replacement Item to insert at current position
	 * @return Suggested caret position after replacement
	 */
	replaceCurrentWith(replacement: TokenSuggestion): number {
		let aggregationSuggestion = CustomMathUtils.isAggregation(replacement.tokenType);
		let boundaries = this.isSuggestedWrap && aggregationSuggestion
			? this.getCurrentAttributeBlockPosition()
			: this.getCurrentTextBlockPosition();
		let isWrapped = this.isCurrentTextBlockWrapped(boundaries.startIndex);
		let leadingTokens = this.formula.getPreviousTokens(boundaries.startIndex);

		let wrapText;
		if (this.isSuggestedWrap) {
			wrapText = aggregationSuggestion ? this.getCurrentAttributeTextBlock() : this.getCurrentTextBlock();
			delete this.isSuggestedWrap;
		}

		let replaceText = replacement.insertValue(isWrapped, leadingTokens, wrapText);
		if (isWrapped && this.suggestionCaretPosition - 1 < boundaries.endIndex) {
			replaceText += ']';
		}
		let endIndex = isWrapped ? this.suggestionCaretPosition - 1 : boundaries.endIndex;
		this.mathInput.nativeElement.textContent =
			this.mathInput.nativeElement.textContent.replaceAt(boundaries.startIndex, endIndex, replaceText);
		let insertionCaretOffset = replacement.getCaretPositionAfterInsert(isWrapped, leadingTokens, wrapText);
		return boundaries.startIndex + insertionCaretOffset;
	}

	// get the start and end indices of the current BLOCK of text (between separator characters)
	private getCurrentTextBlockPosition(): {startIndex: number, endIndex: number} {
		let token = this.getCurrentTextBlockToken();
		let startIndex = token ? token.offset : 0;
		let endIndex = token ? token.offset + token.text.length - 1 : 0;
		return { startIndex, endIndex };
	}

	private getCurrentAttributeBlockPosition(): {startIndex: number, endIndex: number} {
		let tokenIndex = this.formula.getTokenIndex(this.suggestionCaretPosition);
		let openBracketOffset = this.formula.getToken(tokenIndex).offset;
		let closeBracketOffset = this.formula.getToken(tokenIndex + 2).offset;
		return {startIndex: openBracketOffset, endIndex: closeBracketOffset};
	}

	private getCurrentTextBlockToken(): MathMetricToken {
		let offset: number = this.suggestionCaretPosition || this.contentEditableHelper.getCaretPosition();
		let index = this.formula.getTokenIndex(offset);
		//cursor after formula text
		if (index === -1) return this.formula.getLastToken();
		let token = this.formula.getToken(index);
		if (this.isSeparatorCharacter(token.text)) {
			//cursor on separator after text block
			token = this.formula.getPreviousToken(index);
		}
		return token;
	}

	private getCurrentAttributeTextBlock(): string {
		let tokenIndex = this.formula.getTokenIndex(this.suggestionCaretPosition);
		return `[${this.formula.getToken(tokenIndex + 1).text}]`;
	}

	private getCurrentTextBlock(): string {
		let token = this.getCurrentTextBlockToken();
		return token?.text || '';
	}

	private isCurrentTextBlockWrapped(startIndex: number): boolean {
		let tokenIndex = this.formula.getTokenIndex(startIndex);

		let previousSeparator = this.formula.getPreviousToken(tokenIndex)?.text;
		let nextSeparator = this.formula.getNextToken(tokenIndex)?.text;
		let currentTextBlock = this.formula.getToken(tokenIndex)?.text;
		if (currentTextBlock === 'abs')
			return nextSeparator === '(';
		return previousSeparator === '[' || nextSeparator === '[';
	}

	private isMetricStartCharacter(character: string): boolean {
		return /^\[$/.test(character);
	}

	private isSeparatorCharacter(character: string): boolean {
		return /^[\+\-\/\*\^\|\(\)\[\]\n\t$]$/.test(character);
	}

	private getTokenClassNames(target: HTMLElement): string {
		// target or target parent...
		return `${target.classList.value} ${target.parentElement.classList.value}`;
	}

	private getTokenTypes(target: HTMLElement): string[] {
		let clickTokenClasses = this.getTokenClassNames(target).split(' ');

		for (let oneClass of clickTokenClasses) {
			if ((this.TOKEN_TYPES as string[]).contains(oneClass)) {
				if (this.isTextAttribute(oneClass) || this.isCountDistinctNumericAttribute(oneClass, target)) {
					return [MathMetricTokenType.TEXT_ATTRIBUTE, MathMetricTokenType.NUMERIC_ATTRIBUTE];
				}

				return [oneClass];
			}
		}
	}

	private isTextAttribute(cssClass: string): boolean {
		return cssClass === MathMetricTokenType.TEXT_ATTRIBUTE;
	}

	private isCountDistinctNumericAttribute(cssClass: string, target: HTMLElement): boolean {
		return cssClass === MathMetricTokenType.NUMERIC_ATTRIBUTE && this.isInCountDistinctAggregation(target);
	}

	private isInCountDistinctAggregation(target: HTMLElement): boolean {
		let targetWrapper: HTMLElement = target.parentElement;
		let openBracket: Element = targetWrapper?.previousElementSibling;
		let aggregation: Element = openBracket?.previousElementSibling;
		return aggregation?.textContent === MathAggregation.COUNT_DISTINCT;
	}

	private targetIsSwappable(target: HTMLElement): boolean {
		let targetElementClasses = this.getTokenClassNames(target).split(' ');
		// numbers, syntax, and prefix are not swappable
		return targetElementClasses.contains('text-token') &&
			!targetElementClasses.contains('syntax') &&
			!targetElementClasses.contains('number') &&
			!targetElementClasses.contains('prefix') &&
			!targetElementClasses.contains('space');
	}

	processMousedown(event: MouseEvent): void {
		this.resetAttributeSuggestion();
		this.identifyClick(event);
		this.processClick(event);
	}

	identifyClick(event: MouseEvent): void {
		if (event.detail > 1) {
			// handle double click
			clearTimeout(this.singleClickTimer);
			this.singleClickTimer = null;
			event.preventDefault();
		} else {
			// handle single click
			let target = event.target as HTMLElement;
			let textElement = target?.firstChild;
			let isCurrentElementSelected = this.contentEditableHelper.isElementContentSelected(textElement);
			this.singleClickTimer = setTimeout(() => {
				if (this.singleClickTimer && this.targetIsSwappable(target)) {
					if (!isCurrentElementSelected) {
						this.contentEditableHelper.selectElementContent(textElement);
						this.provideSuggestionsOfType(this.getTokenTypes(target), true);
					} else {
						this.provideSuggestions(this.getTokenTypes(target), false, true);
					}
				}
			}, 200);
		}
	}

	processClick(event: MouseEvent): void {
		let target = event.target as HTMLElement;
		if (this.targetIsSwappable(target)) {
			this.provideSuggestions(this.getTokenTypes(target), false, true);
		} else {
			if (this.placeholderShown) {
				this.removePlaceholder();
			}
			this.hideSuggestions();
		}
	}

	private highlight = (): HTMLElement => {
		let formulaText = this.formula.getText();
		let segments = this.formula.getSegments();
		this.customMathHighlightService.markFunctionTokensForHighlighting(segments);
		let processedOffset = 0;
		let parent = document.createElement('div');
		parent.id = CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID;
		let formulaStack = [parent] as HTMLElement[];
		this.customMathErrors = [];
		this.customMathWarnings = [];

		segments.filter(segment => !segment.textTokens.isEmpty()).forEach((segment) => {
			let offset = segment.startOffset;
			if (processedOffset < offset) {
				let text = formulaText.substring(processedOffset, offset);
				parent.appendChild(document.createTextNode(text));
			}
			if (segment.textTokens[0].isFunction && segment.text.contains('(')) {
				let functionWrapper = document.createElement('span');
				functionWrapper.className = 'd-inline-block';
				formulaStack.push(functionWrapper);
			}
			let segmentWrapper = document.createElement('span');
			segmentWrapper.className = 'd-inline-block';
			if (segment.errors) {
				segmentWrapper.className += ` ${TooltipType.ERROR}`;
				segmentWrapper.setAttribute(CustomMathTooltipsService.TOOLTIP_TEXT_ATTRIBUTE, this.getTextErrors(segment));
			}
			segment.textTokens.forEach(textToken => {
				segmentWrapper.appendChild(this.highlightToken(textToken, segment?.expression?.type));
			});
			formulaStack.last().appendChild(segmentWrapper);
			if (segment.textTokens[0].isFunction && segment.text.contains(')')) {
				this.mergeWrappers(formulaStack, false);
			}
			let nextTokenOffset = offset + segment.text.length;
			processedOffset = nextTokenOffset;
		});
		this.mergeWrappers(formulaStack, true);
		this.ref.markForCheck();
		this.ref.detectChanges();

		if (processedOffset < formulaText.length) {
			let text = formulaText.substring(processedOffset, formulaText.length);
			parent.appendChild(document.createTextNode(text));
		}

		return parent;
	}

	private mergeWrappers = (stack: HTMLElement[], mergeAll: boolean): void => {
		do {
			if (stack.length > 1) {
				let lastWrapper = stack.pop();
				stack.last().appendChild(lastWrapper);
			}
		} while (mergeAll && stack.length > 1);
	}

	private getTextErrors = (segment: SupportTextErrors): string => {
		return _.chain(segment.errors)
			.map(error => {
				const errorMessage = this.getErrorMessage(segment.text, error);
				if (!_.contains(this.customMathErrors, errorMessage)) {
					this.customMathErrors.push(errorMessage);
				}
				return errorMessage;
			})
			.uniq().join(' ').value();
	}

	private getErrorMessage = (text: string, error: CustomMathErrorType): string => {
		text = text.trim().length ? text : this.locale.getString('metrics.token');

		if (error === CustomMathErrorType.MAX_VARIABLES_IN_EXPRESSION) {
			return this.locale.getString(this.errorMessages[error],
				{maxVariables: this.customMathValidationService.MAX_VARIABLES_IN_EXPRESSION});
		}

		return this.locale.getString(this.errorMessages[error], {token: text});
	}

	private highlightToken = (token: TextToken, expressionType: string): HTMLElement => {
		let wrapper = document.createElement('span');
		let textEl = document.createElement('span');
		textEl.textContent = token.text.replace(new RegExp(/^\s+|\s+$/, 'g'), replaced => '\xA0'.repeat(replaced.length));
		wrapper.appendChild(textEl);
		wrapper.className = `text-token ${token.type} ${this.customMathHighlightService.getTokenClass(token, expressionType)}`;
		if (token.errors) {
			wrapper.className += ` ${TooltipType.ERROR}`;
			wrapper.setAttribute(CustomMathTooltipsService.TOOLTIP_TEXT_ATTRIBUTE,
				this.customMathTooltipsService.getTextErrors(token, this.customMathErrors, this.errorMessages));
		} else if (token.warnings) {
			wrapper.className += ` ${TooltipType.WARNING}`;
			wrapper.setAttribute(CustomMathTooltipsService.TOOLTIP_TEXT_ATTRIBUTE,
				this.customMathTooltipsService.getTextWarnings(token, this.customMathWarnings, this.dismissedWarnings, this.warningMessages));
		} else if (this.customMathTooltipsService.infoTooltipsSupported(token.type)) {
			wrapper.className += ` ${TooltipType.INFO}`;
		}
		return wrapper;
	}

	onTooltipSpanMouseenter = (event: any) => {
		let target: HTMLElement = event.target;
		let classList: DOMTokenList = target.classList;

		if (this.customMathTooltipsService.isInfoToken(classList)) {
			this.populateTooltipDisplayOptions(target);
			let displayName: string = target.textContent.replaceAll('\u00a0', ' ');
			this.tokenTooltip.text = this.customMathTooltipsService.getInfoTooltipContent(classList, displayName, this.assets);
		} else if (this.customMathTooltipsService.isWarningOrErrorToken(classList)) {
			this.populateTooltipDisplayOptions(target);
			this.tokenTooltip.text = target.getAttribute(CustomMathTooltipsService.TOOLTIP_TEXT_ATTRIBUTE);
		}

		this.hideCaret();
		this.ref.detectChanges();
	}

	private populateTooltipDisplayOptions(span: HTMLElement) {
		this.tokenTooltip.visible = true;
		this.tokenTooltip.position = this.customMathTooltipsService.getTooltipPosition(span);
	}

	private hideCaret(): void {
		if (document.activeElement.classList.contains('formula-input')) {
			this.tokenTooltip.focusFormulaOnHide = true;
			$(document.activeElement).trigger('blur');
		}
	}

	onTooltipSpanMouseleave = (event: any): void => {
		let target: HTMLElement = event.target;
		let targetClasses: DOMTokenList = target?.classList;
		let focusFormulaInput: boolean = this.tokenTooltip.focusFormulaOnHide;

		if (this.customMathTooltipsService.isInfoToken(targetClasses) || this.customMathTooltipsService.isWarningOrErrorToken(targetClasses)) {
			this.tokenTooltip = {};
			this.showCaret(focusFormulaInput);
		}

		this.ref.detectChanges();
	}

	private showCaret(focusFormulaInput: boolean): void {
		if (focusFormulaInput) {
			$('.formula-input').trigger('focus');
		}
	}

	onWarningDismissed = (text: string): void => {
		this.dismissedWarnings.push(text);
	}

	@HostListener('document:click', ['$event.target'])
	onClickPermormed(target: any): void {
		let clickedInsideComponent = this.elementRef.nativeElement.contains(target);
		if (!clickedInsideComponent) {
			let editorContentWrapper: HTMLElement = document.getElementById(CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID);
			if (editorContentWrapper?.textContent === '') {
				this.appendPlaceholder(editorContentWrapper);
			}
		}
	}

	private initEditorContentWrapperWithPlaceholder(): HTMLElement {
		let contentWrapper = document.createElement('div');
		contentWrapper.id = CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID;

		this.appendPlaceholder(contentWrapper);
		return contentWrapper;
	}

	private appendPlaceholder(editorContentWrapper: HTMLElement): void {
		this.customMathPlaceholderService.appendPlaceholder(editorContentWrapper);
		this.placeholderShown = true;
	}

	removePlaceholder(): void {
		this.customMathPlaceholderService.removePlaceholder(CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID);
		this.placeholderShown = false;
	}

	getSuggestionClasses(suggestion: TokenSuggestion): string[] {
		return this.customMathSuggestionsService.getSuggestionClasses(suggestion.tokenType, this.hasSubmenuArrow(suggestion));
	}

	changeSelection = (selectionIndex: number): void => {
		this.highlightedSuggestionIndex = selectionIndex;
	}

	/* If list of items is small, set the scrollheight to be smaller to fit the list */
	setScrollHeight = (length = 0): string => {
		const max = 320; // max height of the virtual scroll dropdown
		this.maxHeight = length < Math.floor(max/this.itemSize) ? length * this.itemSize : max;
		return this.maxHeight + 'px !important';
	}
}

app.directive('customMathEditor', downgradeComponent({component: CustomMathEditorComponent}) as angular.IDirectiveFactory);
