

export const WidgetGridsterSelectors = {
	FILTER_BAR_SELECTOR: '.filters-bar-compact',

	WIDGET_TOOLBAR_SELECTOR: '.cx-add-widget-toolbar',

	PLACEHOLDER_SELECTOR: '.br-widget-placeholder .br-widget-header',
	GRIDSTER_SCOPE_SELECTOR: 'div[gridster]'
};


