export class ITableData {
	header: string[];
	body: IRow[];
}

interface IRow extends Array<any> {
	empty?: boolean;
}
export class SimpleTableDataBuilder {

	private header = new Array(this.columnsCount);
	private body: IRow[] = [];

	constructor(private columnsCount?: number) {}

	fromWellFormedData = (tableData: ITableData): SimpleTableDataBuilder => {
		this.header = tableData.header;
		this.body = tableData.body;
		this.columnsCount = tableData.body[0]?.length || 0;
		return this;
	}

	withoutHeader = (): SimpleTableDataBuilder => {
		this.header = null;
		return this;
	}

	withHeader = (header: string[]): SimpleTableDataBuilder => {
		this.header = header;
		return this;
	}

	addEmptyRow = (): SimpleTableDataBuilder => {
		let row = new Array(this.columnsCount) as IRow;
		row.empty = true;
		this.addRow(row);

		return this;
	}

	addRow = (row: IRow): SimpleTableDataBuilder => {
		if (row.length < this.columnsCount) {
			row = row.concat(new Array(this.columnsCount - row.length));
		}

		this.body.push(row);
		return this;
	}

	withoutEmptyRows = (): SimpleTableDataBuilder => {
		this.body = this.body.filter((row) => {
			return !row.empty;
		});
		return this;
	}

	removeMatchingRows = (filterFn: (row: IRow) => boolean): SimpleTableDataBuilder => {
		this.body = this.body.filter((row) => {
			return !filterFn(row);
		});
		return this;
	}

	withoutEmptyColumns = (): SimpleTableDataBuilder => {
		let counters = new Array(this.columnsCount);
		for (let i = 0; i < this.columnsCount; i++) {
			counters[i] = 0;
		}

		this.body.forEach((row) => {
			row.forEach((cell, index) => {
				if (cell) counters[index]++;
			});
		});

		this.header = this.header && this.header.filter((element, index) => {
			return counters[index] !== 0;
		});
		this.body.forEach((row, rowIndex) => {
			this.body[rowIndex] = row.filter((element, cellIndex) => {
				return counters[cellIndex] !== 0;
			});
		});
		this.columnsCount = this.header ? this.header.length : 0;

		return this;
	}

	/* Terminal operations */
	build = (): ITableData => {
		return {
			header: this.header,
			body: this.body
		};
	}

	exportApplyingMapping = (mapFn: (row: IRow) => any) => {
		this.withoutEmptyRows();
		return JSON.stringify(this.body.map(mapFn), null, 4);
	}

	exportFullTable = () => {
		let exportRows = this.body.map((row) => {
			let result = {};
			for (let i = 0; i < this.columnsCount; i++) {
				result[this.header[i]] = row[i];
			}
			return result;
		});

		return JSON.stringify(exportRows, null, 4);
	}
}
