export enum WidgetType {
	LINE = 'cb_an_line',
	BAR = 'cb_an_bar',
	METRIC = 'cb_an_metric',
	HEATMAP = 'cb_an_heatmap',
	CLOUD = 'cb_an_cloud',
	PIE = 'cb_an_pie',
	TABLE = 'cb_an_table',
	SCATTER = 'cb_an_scatter',
	DOCUMENT_EXPLORER = 'cb_an_document',
	PREVIEW = 'cb_an_preview',
	PREVIEW_EXPORT = 'cb_an_preview_export',
	ATTR_STATS = 'cb_an_attr_stats',
	SCORECARD = 'cb_an_scorecard',
	SELECTOR = 'cb_an_selector',
	MAP = 'cb_an_map',
	NETWORK = 'cb_an_network',
	OBJECT_VIEWER = 'cb_object_viewer',
	MODEL_VIEWER = 'cb_an_model_viewer',
	PAGE_BREAK = 'page_break',
	QUALTRICS = 'qualtrics',
	IMAGE = 'image',
	VIDEO = 'video',
	LABEL = 'label',
	TEXT = 'text',
	BUTTON = 'button',

	CONTENT = 'content', // legacy content widgets
}

export default WidgetType;
