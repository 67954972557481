import { Inject, Injectable } from '@angular/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Book } from '@cxstudio/dashboards/entity/book';


@Injectable({
	providedIn: 'root'
})
export class AgGridDashboardService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly clipboard: Clipboard,
		@Inject('urlService') private urlService: UrlService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
	) {}

	copyDashboardLink = (dashboard: Dashboard): void => {
		let dashboardUrl: string = this.urlService.getDashboardUrl(dashboard.id);
		this.copyLinkToClipboard(dashboardUrl);
	}

	copyBookLink = (book: Book): void => {
		let bookUrl: string = this.urlService.getBookUrl(book.id);
		this.copyLinkToClipboard(bookUrl);
	}

	private copyLinkToClipboard(url: string): void {
		this.clipboard.copy(url);
		this.globalNotificationService.addSuccessNotification(this.locale.getString('common.linkCopied'));
	}
}
