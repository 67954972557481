import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '../../drill-menu-option';
import { DrillWidgetType } from '../drill-widget-type';
import * as moment from 'moment';

export class TrendDrillService {

	readonly DEFAULT_ATTR = '_doc_date';
	readonly DEFAULT_TREND = this.timePrimaryGroups.MONTH.trendBy;

	constructor(
		private readonly locale: ILocale,
		private readonly DrillTo,
		private readonly metricUtils: MetricUtils,
		private readonly timePrimaryGroups: TimePrimaryGroupsService
	) {}

	getOptions(params, attributes, point: DrillPoint): IDrillMenuOption {
		let defaultPeriod = this.DEFAULT_TREND;
		if (point) {
			defaultPeriod = this.calculateDefaultPeriod(point, params);
		}
		let timeAttributes = this.metricUtils.getTimeGroup(attributes, true).children;
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 20,
			text: this.locale.getString('widget.trendDrill'),
			items: this.getTrendAttributes(params, timeAttributes, defaultPeriod),
			name: DrillWidgetType.TREND,
			levels: 3
		};
	}

	getTrendAttributes(params, timeAttributes, defaultPeriod): IDrillMenuOption[] {
		return _.map(timeAttributes, (item) => {
			let menuItem: IDrillMenuOption = {
				text: item.displayName,
				name: item.name,
				items: _.map(item.children, this.createTreeItem(params, defaultPeriod))
			};
			if (item.name === this.DEFAULT_ATTR) {
				menuItem.selected = true;
			}
			return menuItem;
		});
	}

	createTreeItem(params, defaultPeriod): (...args) => IDrillMenuOption {
		let drillToHelper = new this.DrillTo(params.widget, params.menuActions, params.defaultColor);
		return (attributeItem) => {
			let result: IDrillMenuOption = {
				text: attributeItem.displayName,
				name: attributeItem.name,
				items: drillToHelper.getDrillTo(DrillWidgetType.TREND, WidgetType.LINE),
				obj: attributeItem,
				func: this.addGroupBy
			};
			if (attributeItem.trendBy === defaultPeriod) {
				result.selected = true;
			}
			return result;
		};
	}

	calculateDefaultPeriod(point: DrillPoint, params): any {
		let groupings = params.widget.properties.selectedAttributes;
		let timeGrouping = _.find(groupings, AnalyticMetricTypes.isTime);
		if (timeGrouping) {
			let trendBy = timeGrouping.trendBy;
			let currentIndex = _.findIndex(this.timePrimaryGroups.values, { trendBy });
			if (currentIndex < this.timePrimaryGroups.values.length - 1) {
				return this.timePrimaryGroups.values[currentIndex + 1].trendBy;
			} else {
				return trendBy;
			}
		} else {
			return this.getDateRangeBasedPeriod(point);
		}
	}

	getDateRangeBasedPeriod(point: DrillPoint): any {
		let from, to;
		let hasHistoricDate = point.dateRangeP2 || (point.P2StartDate && point.P2EndDate);
		if (point._pop && point._pop === ReportPeriods.HISTORIC && hasHistoricDate) {
			// table has different P2 fields
			let range = point.dateRangeP2 ? point.dateRangeP2 : {from: point.P2StartDate, to: point.P2EndDate};
			from = moment(range.from);
			to = moment(range.to);
		} else {
			from = moment(point.P1StartDate);
			to = moment(point.P1EndDate);
		}
		let days = to.diff(from, 'days');
		if (isNaN(days)) {
			return this.DEFAULT_TREND;
		}

		if (days <= 2) {
			return this.timePrimaryGroups.HOUR.trendBy;
		} else if (days <= 31) {
			return this.timePrimaryGroups.DAY.trendBy;
		} else if (days <= 180) {
			return this.timePrimaryGroups.WEEK.trendBy;
		} else if (days <= 456) {
			return this.timePrimaryGroups.MONTH.trendBy;
		} else if (days <= 730) {
			return this.timePrimaryGroups.QUARTER.trendBy;
		} else {
			return this.timePrimaryGroups.YEAR.trendBy;
		}
	}

	addGroupBy(point: DrillPoint, trendAttribute): void {
		let groupBy: any = {};
		_.extend(groupBy, trendAttribute);
		groupBy.metricType = AnalyticMetricType.TIME;
		point.groupBy = groupBy;
	}
}

app.service('trendDrill', TrendDrillService);
