import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import ProjectGeographies from './project-geographies';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import AttributeGeography from './attribute-geography';
import ModelGeography from './model-geography';
import { BoundaryField } from './boundary-field';
import { GeographyApiService } from './geography-api.service';

export default class GeographyReportService {

	constructor(
		private geographyApiService: GeographyApiService
	) {
	}

	getGroupingBoundaryField = (grouping: AttributeGrouping | TopicReportGrouping, projectGeographies: ProjectGeographies): BoundaryField => {
		if (grouping.metricType === AnalyticMetricType.ATTRIBUTE) {
			let geography = _.find(projectGeographies.attributeGeographies,
				(attributeGeography: AttributeGeography) => attributeGeography.attributeName.toLowerCase() === grouping.name.toLowerCase());

			return geography?.boundaryField || null;
		} else if (grouping.metricType === AnalyticMetricType.CLARABRIDGE) {
			let modelGrouping = grouping as TopicReportGrouping;
			let geography = _.find(projectGeographies.modelGeographies,
				(modelGeography: ModelGeography) => modelGeography.modelId === Number.parseInt(grouping.name, 10));

			return modelGrouping.selectedLevel
				? geography.boundaryFields[modelGrouping.selectedLevel - 1]
				: this.findFirstBoundaryFieldLevel(geography);
		} else {
			return null;
		}
	}

	findFirstBoundaryFieldLevel = (modelGeography: ModelGeography): BoundaryField => {
		let minimumFilledLevel: number;
		let minimumLevelBoundaryField: BoundaryField;

		for (let levelKey of Object.keys(modelGeography.boundaryFields)) {
			let level = Number.parseInt(levelKey, 10);
			let boundaryField = modelGeography.boundaryFields[level];
			if (boundaryField) {
				if (!minimumFilledLevel || level < minimumFilledLevel) {
					minimumFilledLevel = level;
					minimumLevelBoundaryField = boundaryField;
				}
			}
		}

		return minimumLevelBoundaryField;
	}

	generateGeographyReport = (boundaryField: BoundaryField, level: number): ng.IPromise<{ metadata: any, data: any[] }> => {
		return this.geographyApiService.generateGeographyReport(boundaryField).then(geographyReport => {
			let metadata = {
				boundaryType: geographyReport.boundaryType,
				boundaryField,
				polyTilesetName: geographyReport.polyTilesetName,
				polyLayerName: geographyReport.polyLayerName
			};

			let data = [];
			for (let value of Object.keys(geographyReport.features)) {
				let feature = geographyReport.features[value];
				let dataItem = {
					volume: Math.floor(Math.random() * 1000),
					__featureId: feature.featureId,
					__featureBounds: feature.bounds,
					group1: value,
					level: 0,
					metric1: Math.floor(Math.random() * 1000),
					metric2: Math.random() * 10 - 5,
					sentiment: Math.random() * 10 - 5,
					easeScore: Math.random() * 10 - 5,
					_pop: 'period_1_'
				};

				data.push(dataItem);

				if (level === 1) {
					let childDataItem = _.extend(angular.copy(dataItem), {
						group2: 'category' + Math.floor(Math.random() * 5),
						level
					});
					data.push(childDataItem);
				}
			}

			return {
				metadata,
				data
			};
		});
	}

}

app.service('geographyReportService', GeographyReportService);
