<modal-header
	(cancel)="cancel()"
	modalTitle="{{modalHeading}}">
</modal-header>

<div class="modal-body alert-editor-modal">
	<div [ngBusy]="loading">
		<div class="row mb-16" *ngIf="isNew">
			<div class="col-xs-12">
				<div class="input-group w-100-percent">
					<input
						type="text"
						maxlength="30"
						class="form-control"
						[(ngModel)]="alert.alertName"
						placeholder="{{'alert.namePlaceholder'|i18n}}"
						(change)="checkName()"
						[debounce]="300">
				</div>
			</div>
		</div>
		<div>
			<cb-notice type="'danger'" *ngIf="uniqueNameError" text="{{'alert.nameNotUnique'|i18n}}"></cb-notice>
			<cb-notice type="'danger'" *ngIf="invalidNameError" text="{{'alert.invalidName'|i18n}}"></cb-notice>
		</div>
		<div *ngIf="isNew">
			<ng-container *ngTemplateOutlet="steps[currentStep].template"></ng-container>
		</div>

		<div *ngIf="!isNew">
			<ul ngbNav #nav="ngbNav" class="nav-tabs">
				<li *ngFor="let step of steps; index as $index" ngbNavItem>
					<a ngbNavLink>{{step.tabName}}</a>
					<ng-template ngbNavContent>
						<div>
							<ng-container *ngTemplateOutlet="step.template"></ng-container>
						</div>
					</ng-template>
				</li>
			</ul>
			<section [ngbNavOutlet]="nav"></section>
		</div>

	</div>
</div>

<div class="modal-footer">
	<button
		id="btn-dialog-back"
		*ngIf="currentStep>0"
		type="button"
		class="btn btn-secondary"
		[disabled]="loading"
		(click)="back()">
			<span *ngIf="!loading">{{'common.back'|i18n}}</span>
			<span *ngIf="loading" class="q-icon q-icon-spinner rotate-infinite"></span>
	</button><button
		id="btn-dialog-continue"
		type="button"
		class="btn btn-primary"
		[disabled]="loading || !isSettingsValid()"
		(click)="continue()">
			<span *ngIf="!loading">{{getContinueButtonText()}}</span>
			<span *ngIf="loading" class="q-icon q-icon-spinner rotate-infinite"></span>
	</button><button
		id="btn-dialog-cancel"
		type="button"
		class="btn btn-secondary"
		(click)="cancel()">{{'common.cancel'|i18n}}
	</button>
</div>

<ng-template #step1>
	<p [i18n]="'alert.modelSelectionText'"></p>

	<select
		class="form-group"
		[(ngModel)]="alert.modelId"
		(change)="loadModelNodes()">
		<option *ngFor="let option of modelList" [ngValue]="option.id">{{ option.name }}</option>
	</select>

	<div class="row" *ngIf="loaded">
		<div class="col-sm-8 col-md-6 model-selection-box">
			<searchable-hierarchy
				*ngIf="modelNodes?.length"
				placeholder="{{'common.search'|i18n}}"
				[hierarchyList]="modelNodes"
				[displayProperty]="'name'"
				[ngShowNodeCheckbox]="isNodeCheckable"
				[ngNodeIsChecked]="isNodeChecked"
				(onNodeClick)="handleNodeCheck($event.node)"
				[folderClickIgnore]="true">
			</searchable-hierarchy>
		</div>
	</div>
</ng-template>
<ng-template #step2>
	<p [i18n]="'alert.attributeSelectionText'" [i18nParams]="{maxAttributes: validation.attributeLimit}"></p>
	<div class="row">
		<div class="col-md-12">
			<inclusion-list
				*ngIf="loaded"
				class="attributes-selection"
				[allItems]="attributes"
				[selectedItems]="alert.selectedAttributes"
				(selectedItemsChange)="updateSelectedAttributes($event)"
				[disableSelection]="isAttributeLimitReached"
			></inclusion-list>
		</div>
	</div>
</ng-template>
<ng-template #step3>
	<p [i18n]="'alert.filterSelectionText'"></p>
	<div class="row">
		<div class="col-md-12">
			<inclusion-list
				*ngIf="loaded"
				class="filters-selection"
				[allItems]="filters"
				[selectedItems]="alert.selectedFilters"
				(selectedItemsChange)="updateSelectedFilters($event)"
			></inclusion-list>
		</div>
	</div>
</ng-template>
