import * as cloneDeep from 'lodash.clonedeep';
import { Inject, Injectable } from '@angular/core';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';

import { DualDefinitionUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-utils.class';
import { SortUtils } from '@app/shared/util/sort-utils';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { GroupingUtils } from '@cxstudio/reports/utils/analytic/grouping-utils.class';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { TimePrimaryGroup, TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { CapitalizationUtils } from '@cxstudio/services/capitalization-utils.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

export type ChartRawData = any[];
export type ChartHierarchyData = any[];

@Injectable({
	providedIn: 'root'
})
export class DualDataProcessingService {

	constructor(
		@Inject('formatterBuilderUtils') private formatterBuilderUtils: FormatterBuilderUtilsService,
		private analyticsDataFormatting: AnalyticsDataFormattingService,
	) { }

	getInitialData(dataObject: ReportDataObject, dualUtils: DualDefinitionUtils, demo: boolean): ChartRawData {
		let data = cloneDeep(dataObject.data);
		if (demo && dualUtils.isCalculationSeries()) {
			data = this.getLevel(data, 0);
		}
		return data;
	}

	processPopData(data: ChartRawData, dualUtils: DualDefinitionUtils, utils: WidgetUtils): ChartRawData {
		if (dualUtils.hasPop()) {
			if (utils.periods && utils.periods.period_1_ === DateFilterMode.MISS_DATE)
				this.initPopData(data);
			// to create parent node for all current/previous pairs
			data = AnalyticDataUtils.processPopData(data, dualUtils.getPopLevel(), dualUtils.sortByP1Volume());
		} else {
			data = this.filterPopData(data);
		}
		return data;
	}

	private filterPopData(rawData: IDataPoint[]) {
		return _.filter(rawData, (row: any) => {
			return !row._pop || row._pop === ReportPeriods.CURRENT;
		});
	}

	private initPopData(rawData: IDataPoint[]): void {
		_.each(rawData, (row: any) => {
			row._pop = ReportPeriods.CURRENT;
		});
	}

	processCalculationSeriesData(data: ChartRawData, dualUtils: DualDefinitionUtils,
			options: VisualProperties, metrics: Metric[]): ChartRawData {
		if (dualUtils.isCalculationSeries()) {
			data = this.transformCalculationSeriesData(data, options, metrics);
		}
		return data;
	}

	private transformCalculationSeriesData(data: ChartRawData, options: VisualProperties, metrics: Metric[]): ChartRawData {
		let result = [];
		_.each(data, (row) => {

			let level = row.level;

			let primary = this.getCalculationRow(row, options.attributeSelections.yAxis, metrics);

			let children = [primary];

			let otherMetrics = options.calculationSeries
				.filter((metric) => !!metric)
				.map((metric) => this.getCalculationRow(row, metric, metrics));

			children.pushAll(otherMetrics);

			_.each(children, (child) => {
				child.level = level + 1;
				child.leaf = true;
			});

			row.leaf = false;

			result.push(row);
			result.pushAll(children);
		});

		return result;
	}

	private getCalculationRow(originalRow: any, metric: ReportCalculation, metrics: Metric[]): any {
		let row = cloneDeep(originalRow);
		row._calculation_series = metric.name;
		let value = row[metric.name];
		let metricFormat: Partial<NumberFormatSettings> = metric as any;
		if (metric.useDefaultFormat) {
			metricFormat = _.findWhere(metrics, {name: metric.name})?.format || metricFormat;
		}
		if (metricFormat.conversion && _.isFinite(value)) {
			value = this.formatterBuilderUtils.multiplyValue(value, metricFormat.conversion as MetricMultiplierType);
		}
		row._calculation_series_value = value;

		return row;
	}

	transformData(data: ChartRawData, options: VisualProperties, utils: WidgetUtils): ChartHierarchyData {
		let seriesNameFormatters = this.getGroupingFormatters(options, utils);
		return AnalyticDataUtils.getHierarchyData(data, DualDefinitionHelper.getGroupings(options), seriesNameFormatters);
	}

	postProcessData(hierarchyData: ChartHierarchyData, dualUtils: DualDefinitionUtils,
			options: VisualProperties): ChartHierarchyData {
		let comparatorArray = this.getComparatorsArray(options, DualDefinitionHelper.getGroupings(options), dualUtils.getSortIgnoreLevels());
		hierarchyData = AnalyticDataUtils.recursiveSort(hierarchyData, comparatorArray);
		hierarchyData = this.applyEmptyPointsSettings(hierarchyData, dualUtils, options);

		return hierarchyData;
	}

	private getComparatorsArray(options: VisualProperties, groups: ReportGrouping[], ignoreLevels: number[]): Array<(a, b) => number> {
		return groups.map(group => {
			return options.sortBy === StandardMetricName.ALPHANUMERIC
				? this.getWrappedComparator(group.identifier, options.direction, ignoreLevels, true)
				: this.getWrappedComparator(options.sortBy, options.direction, ignoreLevels);
		});
	}

	private getLevel(data: any[], level: number) {
		return _.filter(data, item => item.level === level);
	}

	private getWrappedComparator(sortBy, direction, ignoreLevels: number[], alphanumericSort?: boolean) {
		let comparator = SortUtils.getComparator(sortBy, direction, alphanumericSort);
		if (ignoreLevels) // don't sort PoP group level and time group
			comparator = this.wrapComparator(comparator, ignoreLevels);
		return comparator;
	}

	private wrapComparator(comparator: (a, b) => number, ignoredLevels: number[]): (a, b) => number {
		return (row1, row2) => {
			if (ignoredLevels.indexOf(row1.level) > -1)
				return 0;
			return comparator(row1, row2);
		};
	}

	private applyEmptyPointsSettings(hierarchyData: ChartHierarchyData, dualUtils: DualDefinitionUtils,
			options: VisualProperties) {
		let selections = options.attributeSelections;

		let level = 0;

		let primaryGroup = selections && selections.primaryGroup;
		let hasEmptyPeriodTypeDefined = primaryGroup && primaryGroup.emptyPeriodType;

		if (primaryGroup && hasEmptyPeriodTypeDefined && AnalyticMetricTypes.isTime(primaryGroup)) {
			if (GroupingUtils.isDoNotShowEmptyPeriods(primaryGroup)) {
				hierarchyData = this.removeEmptyPoints(hierarchyData, level, selections);
			}
		} else if (primaryGroup && !dualUtils.isIncludeEmptyPointsGroup(primaryGroup)) {
			hierarchyData = this.removeEmptyPoints(hierarchyData, level);
		}

		if (AnalyticMetricTypes.isTime(primaryGroup)) {
			return hierarchyData;
		}

		let secondaryGroup = selections && selections.secondaryGroup;
		if (secondaryGroup && secondaryGroup.name) level++;
		if (secondaryGroup && secondaryGroup.name
				&& !this.isPopOrClustering(dualUtils) && !dualUtils.isIncludeEmptyPointsGroup(secondaryGroup)) {
			hierarchyData = this.removeEmptyPoints(hierarchyData, level);
		}

		let stackedGroup = selections && selections.stackedGroup;
		if (stackedGroup && stackedGroup.name) level++;
		if (stackedGroup && stackedGroup.name
				&& !dualUtils.isIncludeEmptyPointsGroup(stackedGroup)) {
			hierarchyData = this.removeEmptyPoints(hierarchyData, level);
		}

		return hierarchyData;
	}

	private removeEmptyPoints(hierarchyData: ChartHierarchyData, level: number, selections?): ChartHierarchyData {
		return HierarchyUtils.removeIf(hierarchyData, (item: any) => {
			return item.level !== level || !this.isPointEmpty(item, selections);
		}, '_children');
	}

	private isPointEmpty(item: any, selections?) {
		if (!item.volume) {
			return true;
		}
		let value = item[selections?.yAxis?.name];
		return value !== undefined && isNaN(value);
	}

	private isPopOrClustering(dualUtils: DualDefinitionUtils) {
		return dualUtils.isPop() || dualUtils.applyClustering();
	}

	getGroupingFormatters(options: VisualProperties, utils: WidgetUtils, isWeekDescription?: boolean): {[group: string]: (item) => string} {
		let formatters = utils.getGroupingFormatters && utils.getGroupingFormatters();
		if (formatters) {
			if (DualDefinitionHelper.isTimePrimaryGrouping(options)) {
				let primaryGroup = options.attributeSelections.primaryGroup;
				let timeName = primaryGroup.timeName;
				if (TimePrimaryGroups.isWeek(primaryGroup.timeName) && isWeekDescription) {
					timeName = TimePrimaryGroup.WEEK_WITH_DES;
				}
				let labelFormatter = CapitalizationUtils.getWrappedFormatter(primaryGroup.capitalization);

				formatters[primaryGroup.name] =
					this.analyticsDataFormatting.timeGroupLabelFormatter(timeName, labelFormatter);
			}

			if (DualDefinitionHelper.isCalculationSeries(options, utils.widgetType)) {
				let calculationSeries = options.calculationSeries.concat(options.attributeSelections.yAxis);
				formatters._calculation_series = (metricName) => {
					let metric = _.findWhere(calculationSeries, {name: metricName});
					return metric ? metric.displayName : metricName;
				};
			}
			if (DualDefinitionHelper.hasPop(options)) {
				formatters._pop = this.analyticsDataFormatting.formatPeriodLabels(
					options.periodLabel, utils.periods);
			}
		}

		return formatters;
	}
}
