import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class ScatterDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let selectedAttribute = _.find(this.properties.selectedAttributes || [],
			attr => attr.name?.toLocaleLowerCase() === this.visual.primaryGroup?.toLocaleLowerCase());
		let grouping = new Grouping(selectedAttribute?.displayName,
			this.utils.getGroupingType(selectedAttribute?.type));
		let metrics = this.properties.selectedMetrics || [];
		let firstCalculation = this.utils.getMetricDisplayName(metrics, this.visual.xAxis);
		let secondCalculation = this.utils.getMetricDisplayName(metrics, this.visual.yAxis);
		let size = this.utils.getMetricDisplayName(metrics, this.visual.size);
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('bubblePalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.scatterDescription', {
			firstCalculation,
			secondCalculation,
			grouping,
			total,
			palette
		});
		if (size) {
			let topBubbles = '';
			if (!!data?.data && selectedAttribute && this.visual.size) {
				let bubbles = _.sortBy(data.data, item => -item[this.visual.size]);
				if (bubbles?.length >= 2) {
					topBubbles = this.locale.getString('widgetDescription.topBubbles', {
						bubble1: this.getGroupName(selectedAttribute, bubbles[0]),
						bubble2: this.getGroupName(selectedAttribute, bubbles[1])
					});
				}
			}
			description = `${description} ${this.locale.getString('widgetDescription.bubbleSize', {size})}${topBubbles}`;
		}
		return description;
	}

	private getGroupName(attribute: any, dataItem: any): string {
		if (AnalyticMetricTypes.isTime(attribute)) {
			return this.utils.getFormattedDate(attribute, dataItem);
		}
		return dataItem[attribute.identifier];
	}

}
