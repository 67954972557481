import * as _ from 'underscore';
import { AppLoadingService } from '@cxstudio/app-loading';
import { EnvironmentService } from '@cxstudio/services/environment-service';

export class ExternalAuthController implements ng.IController {
	constructor(
		private appLoading: AppLoadingService,
		private environmentService: EnvironmentService,
		private $location: ng.ILocationService,
		private $window: ng.IWindowService,

	) {}

	$onInit(): void {
		this.appLoading.hideAppSpinner();
	}

	openAuthPopup(): void {
		this.environmentService.openOAuthLoginPopup(this.$location.search().redirectUrl, (event) => {
			// external auth passes current url, see index.html
			this.$window.location.href = event.data?.replaceAll('<', '%3C').replaceAll('>', '%3E');
		});
	}
}

app.component('externalAuth', {
	controller: ExternalAuthController,
	template: `
		<div class="d-flex flex-direction-column h-100-percent align-center justify-center">
			<div class="mb-16 text-center">{{::'login.externalLoginError' | i18n}}</div>
			<button class="btn btn-primary"
				ng-click="$ctrl.openAuthPopup()">
				{{::'login.signIn' | i18n}}
		</div>
	`,
});
