import { Security } from '@cxstudio/auth/security-service';
import { AdvancedPasswordSettings, SecurityApiService } from '@cxstudio/services/data-services/security-api.service';

export class PasswordResetOnLoginCtrl implements ng.IController {
	constructor(
		private readonly $scope,
		private readonly $uibModalInstance,
		private readonly securityApiService: SecurityApiService,
		private readonly user,
		private readonly security: Security,
		private readonly passwordPolicy,
		private readonly forceRegistered: boolean
	) {}

	$onInit(): void {
		this.$scope.save = this.save;
		this.$scope.cancel = this.cancel;

		this.$scope.model = {
			newPassword: '',
			confirmPassword: '',
			firstName: '',
			lastName: '',
			oldPassword: ''
		};
		this.$scope.edit = true;
		this.$scope.error = false;
		this.$scope.incomplete = false;
		this.$scope.user = this.user;
		this.$scope.model.firstName = this.user.firstName === 'null' ? '' : this.user.firstName?.trim();
		this.$scope.model.lastName = this.user.lastName === 'null' ? '' : this.user.lastName?.trim();
		this.$scope.passwordPolicy = this.passwordPolicy.data;
		this.$scope.pwdPattern = new RegExp(/.*/);

		if (this.$scope.passwordPolicy && this.$scope.passwordPolicy.requireSpecialChars) {
			this.$scope.pwdPattern = this.security.getPwdPattern();
		}
	}

	cancel = () => {
		this.$uibModalInstance.dismiss('cancel');
	}

	save = () => {
		this.$scope.errorMessage = null;
		if (this.$scope.model.newPassword) {
			this.$scope.loading = true;
			let pwd: AdvancedPasswordSettings = {
				oldPassword: this.$scope.model.oldPassword,
				newPassword: this.$scope.model.newPassword,
				firstName: this.$scope.model.firstName,
				lastName: this.$scope.model.lastName,
				forceRegistered: this.forceRegistered
			};
			this.securityApiService.setPassword(pwd).then((resp) => {
				let result = resp.data;
				this.$scope.loading = false;
				if (result) {
					if (result === 'incorrect') {
						this.$scope.errorMessage = 'Incorrect password';
					} else {
						this.$scope.errorMessage = result;
					}
				} else {
					this.updateUserContext();
					this.$uibModalInstance.close();
				}
			}, () => {
				this.$scope.loading = false;
			});


		} else {
			this.$uibModalInstance.dismiss('cancel');
		}
	}

	private updateUserContext(): void {
		if (this.user.user) {
			this.user.user.firstName = this.$scope.model.firstName;
			this.user.firstName = this.$scope.model.firstName;

			this.user.user.lastName = this.$scope.model.lastName;
			this.user.lastName = this.$scope.model.lastName;
		}
		this.security.setContext(this.user);
	}
}
app.controller('PasswordResetOnLoginCtrl', PasswordResetOnLoginCtrl);
