<div class="form-group d-inline-block">
	<div>
		<p class="mb-24">{{'administration.selectPermsToGrant' | i18n}}</p>

		<div>
			<permissions
				[permissions]="permissions"
				[selectedPermissions]="selectedPermissions"
				[license]="license"
				[user]="user"
				(onChange)="permissionsChange.emit($event)">
			</permissions>
		</div>
	</div>
</div>