import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'quick-insights-clear',
	template: `
<a
	tabindex="0"
	class="quick-insights-clear btn btn-icon no-border btn-secondary cursor-pointer text-1rem ml-4"
	[attr.aria-label]="'common.clearValue'|i18n"
	title="{{'common.clearValue'|i18n}}">
	<i class="q-icon-delete v-middle"></i>
</a>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickInsightsClearComponent {
}
