<div class="p-relative" (click)="$event.stopPropagation()"> <!-- to not close parent dropdown, e.g. in users column filter -->
	<div ngbDropdown
		class="d-flex flex-singleline"
		placement="bottom-left bottom bottom-right"
		autoClose="outside">
		<button ngbDropdownAnchor
			type="button"
			class="btn btn-primary date-picker-anchor"
			(click)="onDropdownAnchor()">
			<i class="q-icon-calendar"></i>
		</button>
		<input type="text"
			class="flex-fill ngb-datepicker-input"
			[readonly]="true"
			[(ngModel)]="dateRangeText">
		<div ngbDropdownMenu class="dropdown-menu ngb-datepicker-menu filter-date-picker">
			<div class="filter-date-wrap pb-0">
				<label>{{'reportFilters.fromDateLabel'|i18n}}</label>
				<ngb-datepicker
						class="well well-sm m-0 p-0 no-border d-block"
						[(ngModel)]="localDateModel.from"
						(dateSelect)="updateDateLimits()"
						[firstDayOfWeek]=7
						[maxDate]="datePickerLimits.maxDate"
				></ngb-datepicker>

				<div class="">
					<button type="button" class="btn btn-sm btn-secondary pull-left"
						[disabled]="disableFromTodayButton()"
						(click)="setFromToday()">{{'common.today'|i18n}}
					</button>
					<button type="button" class="__TEST-clear-button ml-16 btn btn-sm btn-secondary pull-left"
						(click)="clear()">{{'common.clear'|i18n}}
					</button>
					<button type="button" class="btn btn-sm btn-primary btn-ok pull-right"
						(click)="saveDate()">{{'common.close'|i18n}}
					</button>
				</div>
			</div>

			<div class="filter-date-wrap pb-0">
				<label>{{'reportFilters.toDateLabel'|i18n}}</label>
				<ngb-datepicker
						class="well well-sm m-0 p-0 no-border d-block"
						[(ngModel)]="localDateModel.to"
						(dateSelect)="updateDateLimits()"
						[firstDayOfWeek]=7
						[minDate]="datePickerLimits.minDate"
				></ngb-datepicker>

				<div class="">
					<button type="button" class="btn btn-sm btn-secondary pull-left"
						[disabled]="disableToTodayButton()"
						(click)="setToToday()">{{'common.today'|i18n}}
					</button>
					<button type="button" class="ml-16 btn btn-sm btn-secondary pull-left"
						(click)="clear()">{{'common.clear'|i18n}}
					</button>
					<button type="button" class="btn btn-sm btn-primary btn-ok pull-right"
						(click)="saveDate()">{{'common.close'|i18n}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
