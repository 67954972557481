import * as cloneDeep from 'lodash.clonedeep';

export class DashboardPropsService {
	readonly renamingProperties = [ 'name', 'description' ];

	currentDashboard;
	firstInitState: {[key: string]: any};
	current: {[key: string]: any};
	initial: {[key: string]: any};

	nameBeforeUnsavedChange: string;
	redoUndoCheck: boolean = false;

	constructor() {
		this.currentDashboard = undefined;
		this.current = {};
		this.initial = {};
		this.firstInitState = {};
	}

	updateProperty = (propertyName: string, newVal): void => {
		if (newVal) {
			newVal = newVal.trim();
		}

		this.current[propertyName] = newVal;
	}

	setRedoUndo = (result: boolean): void => {
		this.redoUndoCheck = result;
	}

	getRedoUndo = (): boolean => {
		return this.redoUndoCheck;
	}

	// replace the initial value with the current value, probably because changes were saved
	commitUpdates = (): void => {
		_.each(this.current, (val, key) => {
			this.initial[key] = val;
		});

		if (this.nameBeforeUnsavedChange) {
			delete this.nameBeforeUnsavedChange;
		}
	}

	// replace the current value with the initial value, probably because changes were canceled
	revertUpdates = (): void => {
		_.each(this.initial, (val, key) => {
			this.current[key] = val;
		});

		if (this.nameBeforeUnsavedChange) {
			// when we close props modal, the initial name -- the name we revert back to on cancel --
			// reverts back to the original name since the modified name was not saved
			this.initial.name = this.nameBeforeUnsavedChange;
			delete this.nameBeforeUnsavedChange;
		}
	}

	revertInitialUpdates = (): void => {
		_.each(this.firstInitState, (val, key) => {
			this.current[key] = val;
			this.initial[key] = val;
		});

		if (this.nameBeforeUnsavedChange) {
			// when we close props modal, the initial name -- the name we revert back to on cancel --
			// reverts back to the original name since the modified name was not saved
			this.initial.name = this.nameBeforeUnsavedChange;
			delete this.nameBeforeUnsavedChange;
		}
	}


	revertUpdatesWithState = (initialState: {[key: string]: any}, nameBeforeUnsavedChange: string): void => {
		_.each(initialState, (val, key) => {
			this.current[key] = val;
		});

		if (this.nameBeforeUnsavedChange) {
			// when we close props modal, the initial name -- the name we revert back to on cancel --
			// reverts back to the original name since the modified name was not saved
			initialState.name = this.nameBeforeUnsavedChange;
			delete this.nameBeforeUnsavedChange;
		}
	}

	initProperty = (propertyName: string, init) => {
		this.initial[propertyName] = init;
		this.current[propertyName] = init;
	}

	hasChanges = (model): boolean => {
		return !_.isEqual(this.initial, model);
	}

	isItemRenamed = (model): boolean => {
		for (let prop of this.renamingProperties) {
			if (this.isPropertyChanged(prop, model))
				return true;
		}

		return false;
	}

	private isPropertyChanged(propertyName: string, model): boolean {
		return this.initial[propertyName] !== model[propertyName];
	}

	initializeDashboardProps = (model, existingItem: boolean = true): void => {
		// dashboard name can change outside of modal via dashboard header
		// in this case we have to store the last saved name, but show the new unsaved name
		if (model.name !== this.initial.name && existingItem) {
			this.nameBeforeUnsavedChange = this.initial.name;
		}

		this.initial = cloneDeep(model);
		this.current = cloneDeep(model);
	}

	updateInitialDashboardProps = (model): void => {
		this.initial = cloneDeep(model);
	}

	updateFirstDashboardProps = (model): void => {
		this.firstInitState = cloneDeep(model);
	}

	updateDashboardProps = (model): void => {
		this.current = cloneDeep(model);
	}

	loadDashboard = (dashboard): void => {
		this.setRenamingProperties(dashboard);

		if (dashboard.id) {
			this.initProperty('id', dashboard.id);
			this.currentDashboard = dashboard.id;
		}
	}

	setRenamingProperties = (dashboard): void => {
		for (let prop of this.renamingProperties) {
			if (!_.isUndefined(dashboard[prop])) {
				this.initProperty(prop, dashboard[prop]);
			}
		}
	}
}

// used to keep dashboard properties in sync across controllers
app.service('dashboardPropsService', DashboardPropsService);
