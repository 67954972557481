import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { QualtricsIntegrationComponent } from './qualtrics-integration/qualtrics-integration.component';
import { NarrativeIntegrationComponent } from './narrative-integration/narrative-integration.component';
import { VoiceSettingsIntegrationComponent } from './voice-settings-integration.component';
import { PredictiveSettingsIntegrationComponent } from './predictive-settings-integration.component';
import { ConnectorsSettingsIntegrationComponent } from './connectors-settings-integration.component';
import { TuningSuggestionSettingsIntegrationComponent } from './tuning-suggestion-integration.component';
import { EngagorSettingsIntegrationComponent } from './engagor-settings-integration.component';
import { ExternalAuthSettingsIntegrationComponent } from './external-auth-settings-integration.component';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
	selector: 'master-account-integration',
	templateUrl: './master-account-integration.component.html'
})
export class MasterAccountIntegrationComponent implements AfterViewInit {
	@Input() masterAccountId: number;
	@Input() masterAccountName: number;
	@Input() upload: any;
	@Input() initialAliasName: string;

	@ViewChild(QualtricsIntegrationComponent, { static: false })
	qualtricsIntegration: QualtricsIntegrationComponent;

	@ViewChild(NarrativeIntegrationComponent, { static: false })
	narrativeIntegration: NarrativeIntegrationComponent;

	@ViewChild(VoiceSettingsIntegrationComponent, { static: false })
	voiceSettings: VoiceSettingsIntegrationComponent;

	@ViewChild(PredictiveSettingsIntegrationComponent, { static: false })
	predictionSettings: PredictiveSettingsIntegrationComponent;

	@ViewChild(ConnectorsSettingsIntegrationComponent, { static: false })
	connectorsSettings: ConnectorsSettingsIntegrationComponent;

	@ViewChild(TuningSuggestionSettingsIntegrationComponent, { static: false })
	tuningSuggestionIntegration: TuningSuggestionSettingsIntegrationComponent;

	@ViewChild(EngagorSettingsIntegrationComponent, { static: false })
	engagorSettings: EngagorSettingsIntegrationComponent;

	@ViewChild(ExternalAuthSettingsIntegrationComponent, { static: false })
	externalAuthSettings: ExternalAuthSettingsIntegrationComponent;

	integration: FormGroup;

	constructor(private fb: FormBuilder) {
		this.integration = this.fb.group({
			unitIds: [ ]
		});
	}

	ngAfterViewInit(): void {
		this.integration = this.fb.group({
			qualtricsIntegration: this.qualtricsIntegration.getGroup(),
			qualtricsApiIntegration: this.qualtricsIntegration.getApiGroup(),
			narrativeIntegration: this.narrativeIntegration.getGroup(),
			voiceSettings: this.voiceSettings.getGroup(),
			predictionSettings: this.predictionSettings.getGroup(),
			connectorsSettings: this.connectorsSettings.getGroup(),
			tuningSuggestionIntegration: this.tuningSuggestionIntegration.getGroup(),
			unitIds: [ ],
			engagorSettings: this.engagorSettings.getGroup(),
			externalAuthSettings: this.externalAuthSettings.getGroup(),
		});
	}

	isNewMasterAccount(): boolean {
		return !this.masterAccountId;
	}

	getGroup(): FormGroup {
		return this.integration;
	}

	get qualtricsIntegrationForm(): FormGroup {
		return this.integration.controls.qualtricsIntegration as FormGroup;
	}

	get qualtricsApiIntegrationForm(): FormGroup {
		return this.integration.controls.qualtricsApiIntegration as FormGroup;
	}

	get narrativeIntegrationForm(): FormGroup {
		return this.integration.controls.narrativeIntegration as FormGroup;
	}

	get voiceSettingsForm(): FormGroup {
		return this.integration.controls.voiceSettings as FormGroup;
	}

	get predictionSettingsForm(): FormGroup {
		return this.integration.controls.predictionSettings as FormGroup;
	}

	get connectorsSettingsForm(): FormGroup {
		return this.integration.controls.connectorsSettings as FormGroup;
	}

	get tuningSuggestionIntegrationForm(): FormGroup {
		return this.integration.controls.tuningSuggestionIntegration as FormGroup;
	}

	get engagorSettingsForm(): FormGroup {
		return this.integration.controls.engagorSettings as FormGroup;
	}

	get externalAuthSettingsForm(): FormGroup {
		return this.integration.controls.externalAuthSettings as FormGroup;
	}
}
