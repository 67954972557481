import Widget from '@cxstudio/dashboards/widgets/widget';
import { ResponsiveReportService } from './responsive-report-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';

export class ResponsiveDashboardFooterComponentController implements ng.IComponentController {

	private widgets: Widget[];

	constructor(
		private responsiveReportService: ResponsiveReportService,
		private locale: ILocale,
		private $rootScope: ng.IRootScopeService
	) {
	}

	$onInit(): void {
	}

	isResponsivenessEnabled(): boolean {
		return this.responsiveReportService.isResponsivenessEnabled();
	}

	hasLoadingWidgets(): boolean {
		let readyToDisplayWidgetsCount = this.getReadyToDisplayWidgetsCount();
		let loadingWidgetsCount = this.getLoadingWidgetsCount();
		return loadingWidgetsCount > 0 || readyToDisplayWidgetsCount > 0;
	}

	getReadyToDisplayWidgetsCount(): number {
		return _.filter(this.widgets, (widget) => this.responsiveReportService.isReadyToDisplayFreshData(widget)).length;
	}

	getLoadingWidgetsCount(): number {
		return _.filter(this.widgets, (widget) => this.responsiveReportService.isLoadingReport(widget)).length;
	}

	getLoadedWidgetsCount(): number {
		return this.getTotalWidgetsCount() - this.getLoadingWidgetsCount();
	}

	getLoadedWidgetsProgressValue(): number {
		return Math.log(this.getLoadedWidgetsCount() + 1) / Math.log(this.getTotalWidgetsCount() + 1);
	}
	getTotalWidgetsCount(): number {
		return _.filter(this.widgets, (widget) => WidgetTypeFilters.isReportWidget(widget)).length;
	}

	showProgressText(): boolean {
		return this.getReadyToDisplayWidgetsCount() > 0;
	}
	getProgressText(): string {
		let readyToDisplayWidgetsCount = this.getReadyToDisplayWidgetsCount();
			
		let message = '';
		let plural = 'Plural';

		if (readyToDisplayWidgetsCount > 0) {
			message += this.locale.getString('widget.responsiveProgressWidgetsReady' + (readyToDisplayWidgetsCount > 1 ? plural : ''),
				{ count: readyToDisplayWidgetsCount });
		}
		return message;
	}

	viewResults(): void {
		this.$rootScope.$broadcast('displayFreshData');
	}

}

app.component('responsiveDashboardFooter', {
	bindings: {
		widgets: '<'
	},
	controller: ResponsiveDashboardFooterComponentController,
	templateUrl: 'partials/widgets/responsive-dashboard-footer.component.html'
});