import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ColorMetricDefinition } from '@app/modules/metric/definition/color-metric-definition';
import { BoxMetricDefinition } from '@app/modules/metric/definition/box-metric-definition';
import { PredefinedMetricDefinition } from '@app/modules/metric/definition/predefined-metric-definition';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { IColorGrades, IColorGradeOptions } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { MetricTypesService } from '@app/modules/metric/editor/metric-types.service';

@Injectable({providedIn: 'root'})
export class MetricColorGradesService {

	constructor(
		private locale: CxLocaleService,
		private metricTypesService: MetricTypesService
	) {}

	getColorGrades = (definition: ColorMetricDefinition): IColorGrades => {
		let metricObject = this.metricTypesService.getMetricObjectByType(definition.type);
		return metricObject.getColorData(definition);
	}

	getCalculationColorGrades = (definition: ColorMetricDefinition): IColorGrades => {
		let metricObject = this.metricTypesService.getMetricObjectByType(definition.type);
		return metricObject.getCalculationColorData(definition);
	}

	getDefinitionOptions = (type: MetricType): Partial<IColorGradeOptions> => {
		if (this.isBoxMetric(type)) {
			return {
				minGrades: 2,
				maxGrades: 5,
				canAddRemoveGrades: false,
				areLimitsDisplayed: true,
				areLimitsEditable: true,
				areGradesEditable: true,
				integerOnly: true
			};
		}
		return this.getPredefinedDefinitionOptions(type);
	}

	getCalculationOptions = (type: MetricType): Partial<IColorGradeOptions> => {
		if (this.isPredefinedMetric(type)) {
			return this.getPredefinedCalculationOptions(type);
		}
		return this.getBaseCalculationOptions(type);
	}

	private getBaseCalculationOptions = (type: MetricType) => {
		return {
			minGrades: 2,
			maxGrades: 5,
			canAddRemoveGrades: true,
			areLimitsDisplayed: true,
			areLimitsEditable: !this.isBoxMetric(type),
			areGradesEditable: true,
			canChangeColor: true,
			integerOnly: false,
			decimals: 5
		};
	}

	getPredefinedDefinitionOptions = (type: MetricType) => 
		type === MetricType.SENTIMENT ? this.getSentimentOptions() : this.getNumericBreakdownOptions()

	getPredefinedCalculationOptions = (type: MetricType) => 
	type === MetricType.SENTIMENT ? this.getSentimentOptions() : this.getNumericBreakdownCalculationOptions()

	private getBasePredefinedOptions = (): Partial<IColorGradeOptions> => {
		return {
			minGrades: 5,
			maxGrades: 5,
			canAddRemoveGrades: false,
			areLimitsDisplayed: true,
			areLimitsEditable: false,
			areGradesEditable: true,
			canChangeColor: false,
			isNameDisabled: true
		};
	}

	private getNumericBreakdownOptions = () => _.extend(this.getBasePredefinedOptions(), { integerOnly: true });

	private getNumericBreakdownCalculationOptions = () => _.extend(this.getBasePredefinedOptions(), { integerOnly: false, decimals: 2 });

	private getSentimentOptions = () => _.extend(this.getBasePredefinedOptions(), { integerOnly: false, canChangeColor: true, decimals: 2 });

	updateBoxMetricDefinition = (definition: BoxMetricDefinition, colorGrades: IColorGrades): void => {
		let type = definition.type;
		if (type === MetricType.TOP_BOX) {
			definition.topDisplayName = this.getCategoryName(colorGrades, 1);
			definition.topThreshold = colorGrades.thresholds[0];
			definition.topColor = colorGrades.colorPalette[1];
			definition.otherDisplayName = this.getCategoryName(colorGrades, 0);
			definition.otherColor = colorGrades.colorPalette[0];
		} else if (type === MetricType.BOTTOM_BOX) {
			definition.bottomDisplayName = this.getCategoryName(colorGrades, 0);
			definition.bottomThreshold = colorGrades.thresholds[0];
			definition.bottomColor = colorGrades.colorPalette[0];
			definition.otherDisplayName = this.getCategoryName(colorGrades, 1);
			definition.otherColor = colorGrades.colorPalette[1];
		} else if (type === MetricType.SATISFACTION) {
			definition.bottomDisplayName = this.getCategoryName(colorGrades, 0);
			definition.bottomThreshold = colorGrades.thresholds[0];
			definition.bottomColor = colorGrades.colorPalette[0];
			definition.middleDisplayName = this.getCategoryName(colorGrades, 1);
			definition.middleColor = colorGrades.colorPalette[1];
			definition.topDisplayName = this.getCategoryName(colorGrades, 2);
			definition.topThreshold = colorGrades.thresholds[1];
			definition.topColor = colorGrades.colorPalette[2];
		}
	}

	private getCategoryName = (colorGrades: IColorGrades, index: number) => colorGrades.displayNames[index] || colorGrades.defaultNames[index];

	updatePredefinedMetricDefinition = (definition: PredefinedMetricDefinition, colorGrades: IColorGrades): void => {
		definition.thresholds = [...colorGrades.thresholds];
		definition.colorPalette = [...colorGrades.colorPalette];
	}

	updateMetricCalculations = (definition: ColorMetricDefinition, colorGrades: IColorGrades): void => {
		definition.calculation.thresholds = [...colorGrades.thresholds];
		definition.calculation.colorPalette = [...colorGrades.colorPalette];
		if (colorGrades.displayNames && !this.isPredefinedMetric(definition.type)) {
			definition.calculation.displayNames = [...colorGrades.displayNames];
		}
	}

	private isBoxMetric = (type: MetricType): boolean => {
		return type === MetricType.TOP_BOX 
			|| type === MetricType.BOTTOM_BOX 
			|| type === MetricType.SATISFACTION;
	}

	private isPredefinedMetric = (type: MetricType): boolean => {
		return type === MetricType.EASE_SCORE
			|| type === MetricType.SENTIMENT
			|| type === MetricType.NUMERIC_BREAKDOWN;
	}
}
