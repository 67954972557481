export default class CachedInvocation {
	
	cache: any = {};
	cachedInvocation: (...args) => any = null;

	get = (fn: (...args) => any): ((...args) => any) => {
		if (this.cachedInvocation === null) {
			this.cachedInvocation = (...args) =>	{
				let cacheKey = this.createCacheKey(args);
				if (cacheKey in this.cache) {
					return this.cache[cacheKey];
				} else {
					let result = fn.apply(null, args);
					this.cache[cacheKey] = result;
					return result;
				}
			};
		}

		return this.cachedInvocation;
	}

	private createCacheKey(...args): string {
		return args
			.map(arg => JSON.stringify(arg))
			.reduce((left, right) => left + right, '');
	}

}