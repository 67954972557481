import { Injectable } from '@angular/core';
import GeographyDataItem from '@cxstudio/attribute-geography/geography-data-item';
import { GeographyPoint } from '@app/modules/reports/visualizations/definitions/mapbox/geography.point';
import { GeographyBounds } from '@app/modules/reports/visualizations/definitions/mapbox/geography.bounds';
import { MapFeature } from '@app/modules/reports/visualizations/definitions/mapbox/map.feature';
import { downgradeInjectable } from '@angular/upgrade/static';

type Pattern = { color: string, path: string };
type PatternImage = { id: string, url: string };

@Injectable({
	providedIn: 'root'
})
export class MapboxUtilsService {
	private static GEOJSON_GEOGRAPHY_TYPE_POLYGON = 'Polygon';
	private patternCache = {};
	private patternPromise: Promise<PatternImage[]> = Promise.resolve([]);

	public getPatternImages = (patterns: any[]): Promise<PatternImage[]> => {
		this.patternPromise = this.patternPromise.then(
			() => {
				return Promise.all(
					patterns.map(
						async pattern => {
							const url = await this.getPatternDataUrl(pattern);

							return { id: this.getPatternId(pattern), url };
						}
					)
				);
			}
		);

		return this.patternPromise;
	}

	public getPatternId = (pattern: Pattern): string => {
		return `p_${pattern.color}_${pattern.path.trim().replaceAll(' ', '_')}`;
	}

	private getPatternDataUrl = (pattern: Pattern): Promise<string> => {
		const id = this.getPatternId(pattern);
		if (this.patternCache[id]) {
			return Promise.resolve(this.patternCache[id]);
		}

		const canvas = document.createElement('canvas');
		canvas.width = 16;
		canvas.height = 16;

		const ctx =  canvas.getContext('2d');

		const patternImage = document.createElement('img');
		patternImage.width = 16;
		patternImage.height = 16;

		const url = this.getPatternSvgUrl(pattern.path, pattern.color);
		return new Promise((resolve, reject) => {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			patternImage.onload = () => {
				ctx.drawImage(patternImage, 0, 0);
				let result = canvas.toDataURL('image/png');
				URL.revokeObjectURL(url);
				patternImage.remove();
				canvas.remove();
				this.patternCache[id] = result;
				resolve(result);
			};
			patternImage.src = url;
		});
	}

	private getPatternSvgUrl = (path: string, color: string): string => {
		const svg = `
		<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
			<defs>
				<pattern id="map-pattern" width="10" height="10" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse" patternTransform="scale(0.8, 0.8)">
				<path d="${path}" stroke="${color}" stroke-width="2" fill="none"></path>
				</pattern>
			</defs>
			<rect x="0" y="0" width="16" height="16" fill="url(#map-pattern)"></rect>
		</svg>`;
		const blob = new Blob([svg], { type: 'image/svg+xml' });

		return URL.createObjectURL(blob);
	}

	/**
	 * First, try to "smartly" calculate map bounds by polygon points.
	 * If fails for any reason, use more naive approach.
	 */
	public getMapBounds = (mapFeatures: MapFeature[], features: GeographyDataItem[]): GeographyBounds => {
		let mapBounds: GeographyBounds;

		if (mapFeatures && mapFeatures.length > 0) {
			const polygonPoints = this.extractPolygonPoints(mapFeatures);
			mapBounds = this.calculateMapBoundsFromPolygonPoints(polygonPoints);
		}

		return mapBounds ?? this.calculateMapBoundsFromFeatures(features);
	}

	/**
	 * Extracts points from "Polygons" and "MultiPolygons"
	 *
	 * https://docs.mapbox.com/archive/android/java/api/libjava-geojson/3.0.1/com/mapbox/geojson/Polygon.html
	 * https://docs.mapbox.com/archive/android/java/api/libjava-geojson/3.0.1/com/mapbox/geojson/MultiPolygon.html
	 */
	private extractPolygonPoints = (mapFeatures: MapFeature[]): GeographyPoint[] => {
		return mapFeatures
			.reduce((polygonMemo, polygon) => {
				let geometry = polygon.geometry;
				let rawFeaturePolygonPoints = geometry.type === MapboxUtilsService.GEOJSON_GEOGRAPHY_TYPE_POLYGON
					? polygon.geometry.coordinates[0]
					: polygon.geometry.coordinates.reduce((pointsMemo, points) => {
						points.forEach(pointsArray => pointsMemo.pushAll(pointsArray));
						return pointsMemo;
					}, []);
				return polygonMemo.concat(rawFeaturePolygonPoints);
			}, [])
			.map(point => ({ longitude: point[0], latitude: point[1] }));
	}

	/**
	 * "Smartly" calculate map bounds from polygon points, taking Prime Meridian crossing into consideration.
	 * Gratefully stolen from https://github.com/react-native-mapbox-gl/maps/issues/877#issuecomment-707033260 :)
	 */
	private calculateMapBoundsFromPolygonPoints = (polygonPoints: GeographyPoint[]): GeographyBounds => {
		const latitudes = [];
		const longitudes = [];
		polygonPoints.forEach((mixedBound) => {
			latitudes.push(mixedBound.latitude);
			longitudes.push(mixedBound.longitude);
		});

		const latitudeNE = Math.max(...latitudes);
		const latitudeSW = Math.min(...latitudes);
		let longitudeNE: number;
		let longitudeSW: number;

		const eastLongitudes = longitudes.filter(longitude => longitude >= 0);
		const westLongitudes = longitudes.filter(longitude => longitude < 0);

		if (eastLongitudes.length === 0 || westLongitudes.length === 0) {
			longitudeNE = Math.max(...longitudes);
			longitudeSW = Math.min(...longitudes);
		} else {
			const minEastLongitude = Math.min(...eastLongitudes);
			const maxEastLongitude = Math.max(...eastLongitudes);
			const minWestLongitude = Math.min(...westLongitudes);
			const maxWestLongitude = Math.max(...westLongitudes);

			const longitudeSpreadDefault = maxEastLongitude - minWestLongitude;
			const longitudeSpreadDateline = 360 - minEastLongitude + maxWestLongitude;

			if (longitudeSpreadDateline < longitudeSpreadDefault) {
				longitudeNE = minEastLongitude;
				longitudeSW = 360 + maxWestLongitude;
			} else {
				longitudeNE = maxEastLongitude;
				longitudeSW = minWestLongitude;
			}
		}

		if (
			!(
				Number.isFinite(latitudeNE)
				&& Number.isFinite(latitudeSW)
				&& Number.isFinite(longitudeNE)
				&& Number.isFinite(longitudeSW)
			)
		) {
			return null;
		}

		return {
			sw: {
				latitude: latitudeSW,
				longitude: longitudeSW,
			},
			ne: {
				latitude: latitudeNE,
				longitude: longitudeNE,
			},
		};
	}

	private calculateMapBoundsFromFeatures = (features: GeographyDataItem[]): GeographyBounds => {
		const featureBounds = features
			.filter(dataItem => !_.isEmpty(dataItem.__featureBounds)
				&& dataItem.__featureBounds.length === 4)
			.map(dataItem => {
				const bounds = dataItem.__featureBounds;
				const sw = [bounds[0], bounds[1]];
				const ne = [bounds[2], bounds[3]];

				return new mapboxgl.LngLatBounds(sw, ne);
			});

		if (featureBounds.length <= 0) {
			return null;
		}

		const rawMapBounds = featureBounds.reduce((memo, next) => memo.extend(next), featureBounds[0]);
		return {
			sw: {
				latitude: rawMapBounds.getSouth(),
				longitude: rawMapBounds.getWest()
			},
			ne: {
				latitude: rawMapBounds.getNorth(),
				longitude: rawMapBounds.getEast()
			}
		};
	}

}

app.service('mapboxUtils', downgradeInjectable(MapboxUtilsService));
