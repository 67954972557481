import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SimpleTableData } from '@app/shared/components/simple-table/simple-table.component';

import Widget from '@cxstudio/dashboards/widgets/widget';
import { FeedbackUtils } from '../../feedback-utils.service';

@Component({
	selector: 'current-sentences-table',
	templateUrl: './current-sentences-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentSentencesTableComponent {

	@Input() data: SimpleTableData;

	@Input() widget: Widget;
	@Input() showDescription: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
	) {}

	getHeader(): string {
		let isVerbatimMode: boolean = FeedbackUtils.isSingleVerbatimMode(this.widget);

		return isVerbatimMode
			? this.locale.getString('widget.verbatimView')
			: this.locale.getString('widget.documentView');
	}
}
