import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { WorkspaceManagementComponent } from '@app/modules/system-administration/platform/workspace/workspace-management/workspace-management.component';
import { Security } from '@cxstudio/auth/security-service';
import { SortDirection } from '@cxstudio/common/sort-direction';

@Injectable({
	providedIn: 'root'
})
export class WorkspacesGridDefinition implements IGridDefinition<WorkspaceManagementComponent> {
	static readonly REQUEST_UPDATE_CLASS: string = 'request-update';

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('security') private readonly security: Security,
	) {}

	private readonly refreshIcon: string =
		`<span class='q-icon q-icon-refresh ${WorkspacesGridDefinition.REQUEST_UPDATE_CLASS}'
			title = ${this.locale.getString('common.update')}></span>`;

	init = (): Promise<IGridColumn[]> => {
		const menuColumn: IGridColumn = {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<span class="q-icon-layer"></span>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		};

		const nameFormatter = this.security.isSysAdmin()
			? this.gridFormatterService.getNameFormatter(GridTypes.WORKSPACES)
			: this.gridFormatterService.NoFormat;
		const columns: IGridColumn[] = [{
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'workspaceName',
			name: this.locale.getString('common.workspace'),
			field: 'name',
			sortable: true,
			minWidth: 100,
			width: 120,
			cssClass: 'cell-name',
			searchable: true,
			formatter: nameFormatter,
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: this.security.isSysAdmin()
		}, {
			id: 'contentProviderName',
			name: this.locale.getString('common.contentProvider'),
			field: 'contentProviderName',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: true,
			formatter: this.gridFormatterService.NoFormat
		}, {
			id: 'accountName',
			name: this.locale.getString('common.account'),
			field: 'accountName',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: true,
			formatter: this.gridFormatterService.NoFormat
		}, {
			id: 'lastUpdated',
			name: this.locale.getString('administration.lastUpdatedDate'),
			field: 'lastUpdated',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'requestUpdate',
			name: this.refreshIcon,
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'text-center',
			cssClass: 'text-center action-hover-text',
			searchable: false,
			formatter: () => this.refreshIcon,
			resizable: false
		}];

		if (this.security.isSysAdmin()) {
			columns.unshift(menuColumn);
		}
		return Promise.resolve(columns);
	}


}

app.service('workspacesGridDefinition', downgradeInjectable(WorkspacesGridDefinition));
