import { PaginationQueryParams } from '@cxstudio/query/pagination-query-params';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { Pagination } from '@app/shared/components/pagination/pagination';

export class ComponentsQueryParams extends PaginationQueryParams {

	cpId: number;
	projectId: number;

	constructor(
		asset: ProjectAsset,
		pagination: Pagination
	) {
		super(pagination);
		this.cpId = asset.contentProviderId;
		this.projectId = asset.projectId;
	}
}
