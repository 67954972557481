import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SpellCheckResult, SpellCheckType } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spell-check-result';
import { SpellCheckChunk, SpellCheckUtils } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spell-check-utils.class';

@Component({
	selector: 'spell-check-results',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<ul class="bullet">
		<li *ngFor="let spellCheckResult of results">
			<span class="p-0">{{formatSpellCheckTitle(spellCheckResult)}}</span>
			<ul *ngIf=!isOverflowError(spellCheckResult) class="bullet">
				<li class="italic">
					<div>
						<span *ngFor="let chunk of getChunks(spellCheckResult)"
							class="break-word p-0"
							[ngClass]="getChunkClasses(chunk)">
							{{chunk.text}}
						</span>
					</div>
				</li>
			</ul>
		</li>
	</ul>
	`
})
export class SpellCheckResultsComponent {
	@Input() results: SpellCheckResult[];

	constructor(
		private readonly locale: CxLocaleService,
	) {}

	getChunks(result: SpellCheckResult): SpellCheckChunk[] {
		if (this.isOverflowError(result)) {
			return [];
		}
		return SpellCheckUtils.getChunks(result);
	}

	isOverflowError(result: SpellCheckResult): boolean {
		return result.type === SpellCheckType.EXTRA_ISSUES;
	}

	getChunkClasses(chunk: SpellCheckChunk): string[] {
		return chunk.error ? ['text-danger', 'underline'] : [];
	}

	formatSpellCheckTitle(result: SpellCheckResult): string {
		switch(result.type) {
			case SpellCheckType.DASHBOARD_TITLE:
				return this.locale.getString('dashboardSpotCheck.dashboardTitle');
			case SpellCheckType.WIDGET_TEXT:
				return this.locale.getString('dashboardSpotCheck.textWidgetContent');
			case SpellCheckType.EXTRA_ISSUES:
				return result.text;
			case SpellCheckType.WIDGET_TITLE:
			default:
				return this.locale.getString('dashboardSpotCheck.widgetTitle');
		}
	}
}
