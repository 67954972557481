<div class="form-group stack-md">
	<div class="row d-flex">
		<!--add field name label-->
		<label *ngIf="!showNameInput && !name"
			class="d-flex align-items-center mb-0 control-label justify-end br-custom-field col-md-3 br-custom-field-toggle"
			(click)="toggle()">
			{{'administration.customField'|i18n}}
		</label>

		<!--name-->
		<label *ngIf="!showNameInput && name"
			class="d-flex align-items-center mb-0 control-label justify-end col-md-3 br-custom-field-toggle"
			[class.disabled]="!isAccountOwner()"
			(click)="toggle()">
			{{ name }}
		</label>

		<!--name edit input-->
		<div *ngIf="showNameInput && isAccountOwner()" class="col-md-3">
			<input
				class="form-control br-custom-field-name-input"
				[(ngModel)]="name"
				(ngModelChange)="onNameChange()"
				(keydown.enter)="toggle()"
				minlength="1"
				maxlength="15">
		</div>

		<!--value input-->
		<div class="col-md-9">
			<input
				class="form-control br-custom-field-value-input"
				[(ngModel)]="value"
				(ngModelChange)="onValueChange()"
				[ngModelOptions]="{debounce: 300}"
				[readonly]="valueDisabled()"
				[ngbTypeahead]="valueSuggestions"
				(click)="toggle(false)"
				minlength="1"
				maxlength="25">
		</div>
	</div>
</div>
