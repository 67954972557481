import { Component, OnInit, Input, Output, EventEmitter,
	ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { FilterCondition } from '@app/shared/components/filter/filter-condition';


@Component({
	selector: 'object-list-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `

		<div class="objects-filter">
			<div ngbDropdown
				placement="bottom-right bottom bottom-left"
				autoClose="outside"
				(openChange)="onDropdownStateChange($event)">
				<button type="button"
					class="btn btn-selected"
					[ngClass]="{'btn-selected' : active, 'btn-secondary' : !active}"
					ngbDropdownToggle>
					{{'common.filter'|i18n}}
				</button>
				<div ngbDropdownMenu class="ph-8 filter-dropdown">
					<filter-lanes
						[maxLanes]="maxRules"
						[columns]="columns"
						[refreshTrigger]="refreshTrigger"
						[pinnedColumns]="pinnedColumns"
						(filtersChange)="onFiltersChange($event)"
					></filter-lanes>
				</div>
			</div>
		</div>
	`
})
export class ObjectListFilterComponent implements OnChanges {
	@Input() maxRules?: number;
	@Input() columns: ColumnFilterOption[];
	@Input() pinnedColumns: ColumnFilterOption[];
	@Input() refreshTrigger: boolean;
	@Output() apply = new EventEmitter<ObjectListFilter[]>();

	private initialFilters: ObjectListFilter[] = [];
	private filters: ObjectListFilter[];

	active: boolean = false;

	ngOnChanges() {
		this.active = false;
	}

	onDropdownStateChange = (isOpen: boolean): void => {
		if (isOpen) {
			this.saveState();
		}
	}

	private hasChanges = (): boolean => {
		let currentFilters = this.filters.filter(this.isActiveFilter);
		let appliedFilters = this.initialFilters.filter(this.isActiveFilter);

		if (currentFilters.isEmpty() && appliedFilters.isEmpty()) {
			return false;
		}

		return !_.isEqual(currentFilters, appliedFilters);
	}

	private saveState = (): void => {
		this.initialFilters = this.filters.map(x => Object.assign({}, x));
	}

	onFiltersChange = (filters: ObjectListFilter[]): void => {
		this.filters = filters;
		this.active = !this.filters.filter(this.isActiveFilter).isEmpty();

		if ((this.hasChanges())) {
			this.saveState();
			this.apply.emit(this.filters);
		}
	}

	private isActiveFilter = (filter: ObjectListFilter): boolean => {
		if (filter.condition === FilterCondition.AFTER || filter.condition === FilterCondition.BEFORE) {
			return !!filter.date;
		}

		if (filter.condition === FilterCondition.BETWEEN) {
			return !!filter.fromDate && !!filter.toDate;
		}

		return !!filter.value || filter.condition === FilterCondition.IS_NOT;
	}
}
