import { EventEmitter, Output } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Pill } from '@app/modules/pills/pill';

@Component({
	selector: 'sentence-pill',
	template: `
	<button *ngIf="pill"
		class="label-bubble h-40 border-radius-full text-1rem font-standard ph-16 mr-8 mb-8"
		(click)="pillClick.emit($event)"
		[class.disabled]="pill.disabled"
		[ngClass]="classes"
		[attr.title]="pill.title"
		[attr.tabindex]="inert ? -1 : null"
		>
		{{pill.name}}
		<span class="p-0 ml-4 pill-remove color-inherit no-background"
			*ngIf="removable && !pill.disabled">
			<i class="q-icon q-icon-delete" title="{{'common.remove'|i18n}}"></i>
		</span>
	</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SentencePillComponent {
	@Input() pill: Pill;
	@Input() classes: string[];
	@Input() inert: boolean;
	@Input() removable: boolean;
	@Output() pillClick: EventEmitter<MouseEvent> = new EventEmitter();
}
