import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TranscriptionService } from '@cxstudio/system-administration/master-accounts/integrations/voice-settings';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'voice-settings-integration',
	templateUrl: './voice-settings-integration.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceSettingsIntegrationComponent extends SelfCleaningComponent {
	nfsApplications: Array<{id: any, name: string}>;
	vttApplications: Array<{id: any, name: string}>;
	providers: Array<{id: TranscriptionService, name: string}>;

	voiceSettings: FormGroup;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		readonly formUtils: CxFormUtils,
		private fb: FormBuilder,
		private externalApplicationsApiService: ExternalApplicationsApiService
	) {
		super();
		this.voiceSettings = this.fb.group({
			vociEnabled: [ false ],
			voiceNfsApplicationId: [{ value: 0, disabled: true }, [ Validators.required, Validators.min(0) ]],
			voiceVttApplicationId: [{ value: 0, disabled: true }, [ Validators.required, Validators.min(0) ]],
			transcriptionService: [{ value: null, disabled: true }],
			meaningfulSilenceThreshold: [{ value: undefined, disabled: true }, [ Validators.min(0), Validators.max(36000) ]],
			audioDownloadEnabled: [{ value: false, disabled: true}],
			transcriptDownloadEnabled: [{ value: false, disabled: true}]
		});

		this.loadProviders();

		this.addSubscription(this.voiceSettings.get('vociEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.voiceNfsApplicationId, this.voiceVttApplicationId,
					this.transcriptionService, this.meaningfulSilenceThreshold, this.audioDownloadEnabled,
					this.transcriptDownloadEnabled);
				this.voiceSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.voiceNfsApplicationId, this.voiceVttApplicationId,
					this.transcriptionService, this.meaningfulSilenceThreshold, this.audioDownloadEnabled,
					this.transcriptDownloadEnabled);
				this.voiceSettings.updateValueAndValidity();
			}
		}));
	}

	private loadProviders(): void {
		this.providers = [
			{id: null, name: this.locale.getString('mAccount.defaultProvider')}
		];
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.NFS).then((applications) => {
			this.nfsApplications = [
				{id: 0, name: this.locale.getString('mAccount.defaultProvider')},
				...applications
			];
			this.ref.markForCheck();
		});

		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.VTT).then((applications) => {
			this.vttApplications = [
				{id: 0, name: this.locale.getString('mAccount.defaultProvider')},
				...applications
			];
			this.ref.markForCheck();
		});
	}

	getGroup(): FormGroup {
		return this.voiceSettings;
	}

	get voiceNfsApplicationId(): FormControl {
		return this.voiceSettings.controls.voiceNfsApplicationId as FormControl;
	}

	get voiceVttApplicationId(): FormControl {
		return this.voiceSettings.controls.voiceVttApplicationId as FormControl;
	}

	get transcriptionService(): FormControl {
		return this.voiceSettings.controls.transcriptionService as FormControl;
	}

	get meaningfulSilenceThreshold(): FormControl {
		return this.voiceSettings.controls.meaningfulSilenceThreshold as FormControl;
	}

	get audioDownloadEnabled(): FormControl {
		return this.voiceSettings.controls.audioDownloadEnabled as FormControl;
	}

	get transcriptDownloadEnabled(): FormControl {
		return this.voiceSettings.controls.transcriptDownloadEnabled as FormControl;
	}
}
