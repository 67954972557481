import { GenericSelectUtils } from './generic-selection-utils.factory';
import { PaginatedSelectionController } from './paginated-selection-controller';

export class PaginatedSelectionUtils<T> extends GenericSelectUtils<T> {

	private paginatedController: PaginatedSelectionController<T>;

	constructor(paginatedController: PaginatedSelectionController<T>) {
		super({
			getProjectId: () => paginatedController.getProjectId(),
			getSearchFilter: () => '',
			getVisibleItems: () => paginatedController.getCurrentPageItems(),
			isSelectionSupported: (item: T) => paginatedController.isSelectionSupported(item),
			isShowHidden: () => true,
			refreshGrid: (items: T | T[]) => paginatedController.refreshGrid(items)
		});
		this.paginatedController = paginatedController;
	}

	isListFiltered = (): boolean => {
		return true;
	}

	clearCurrentPageSelections = (): void => {
		this.setObjectsSelected(0, this.getVisibleObjectsList().length - 1, false, this.getVisibleObjectsList());
	}

	clearAllSelections = (): void => {
		this.setObjectsSelected(0, this.getObjectsList().length - 1, false, this.getObjectsList());
	}

	getVisibleObjectsList = (): T[] => {
		return this.paginatedController.getCurrentPageItems() || [];
	}

	getObjectsList = (): T[] => {
		return this.paginatedController.getItemsFromAllPages() || [];
	}

	getAllSelectedObjects = (): T[] => {
		return this.getObjectsList().filter(item => (item as any).selected);
	}

	getAllSelectedCount = (): number => {
		return this.getAllSelectedObjects().length;
	}

	areMultipleItemsSelected = (): boolean => {
		return this.getAllSelectedCount() > 1;
	}

}
