import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { TimezoneService } from '@app/shared/services/timezone.service';
import { Security } from '@cxstudio/auth/security-service';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';
import { SchedulePeriod } from '@app/modules/dashboard/schedule/schedule-period';
import { ObjectUtils } from '@app/util/object-utils';
import { ScheduleRecurrence } from '@app/modules/dashboard/schedule/schedule-recurrence';
import { WeekdayOption } from '@app/modules/dashboard/schedule/weekday-option';

@Injectable({
	providedIn: 'root'
})
export class ScheduleUtilsService {
	readonly SCHEDULE_EXPIRATION_MONTHS = 6;
	readonly EMAIL_WARN_THRESHOLD = 200;
	readonly EMAIL_DANGER_THRESHOLD = 500;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly timezoneService: TimezoneService,
		@Inject('dateService') private readonly dateService: DateService,
		@Inject('security') private readonly security: Security,
	) {}

	readonly schedulingOptions = {
		ONLY_ONCE: {
			name: this.locale.getString('schedule.onlyOnce'),
			value: SchedulePeriod.ONLY_ONCE,
		},
		DAILY: {
			name: this.locale.getString('schedule.daily'),
			value: SchedulePeriod.DAILY,
		},
		WEEKLY: {
			name: this.locale.getString('schedule.weekly'),
			value: SchedulePeriod.WEEKLY,
		},
		MONTHLY: {
			name: this.locale.getString('schedule.monthly'),
			value: SchedulePeriod.MONTHLY,
		},
	};

	readonly defaultSchedulingOptions = [
		this.schedulingOptions.ONLY_ONCE,
		this.schedulingOptions.WEEKLY,
		this.schedulingOptions.MONTHLY
	];

	readonly ampmOptions = [this.locale.getString('schedule.am'), this.locale.getString('schedule.pm')];

	readonly minOptions = [
		{label: '00', value: '0'},
		{label: '05', value: '5'},
		{label: '10', value: '10'},
		{label: '15', value: '15'},
		{label: '20', value: '20'},
		{label: '25', value: '25'},
		{label: '30', value: '30'},
		{label: '35', value: '35'},
		{label: '40', value: '40'},
		{label: '45', value: '45'},
		{label: '50', value: '50'},
		{label: '55', value: '55'}
	];

	readonly weekDaysOptions: WeekdayOption[] = [
		{
			shortName: this.locale.getString('schedule.sundayShort'),
			name: this.locale.getString('schedule.sunday'),
			value: false,
			id: 1
		},
		{
			shortName: this.locale.getString('schedule.mondayShort'),
			name: this.locale.getString('schedule.monday'),
			value: false,
			id: 2
		},
		{
			shortName: this.locale.getString('schedule.tuesdayShort'),
			name: this.locale.getString('schedule.tuesday'),
			value: false,
			id: 3
		},
		{
			shortName: this.locale.getString('schedule.wednesdayShort'),
			name: this.locale.getString('schedule.wednesday'),
			value: false,
			id: 4
		},
		{
			shortName: this.locale.getString('schedule.thursdayShort'),
			name: this.locale.getString('schedule.thursday'),
			value: false,
			id: 5
		},
		{
			shortName: this.locale.getString('schedule.fridayShort'),
			name: this.locale.getString('schedule.friday'),
			value: false,
			id: 6
		},
		{
			shortName: this.locale.getString('schedule.saturdayShort'),
			name: this.locale.getString('schedule.saturday'),
			value: false,
			id: 7
		}
	];

	readonly startTimeOptions = [{
		value: '0',
		name: this.locale.getString('schedule.night')
	}, {
		value: '6',
		name: this.locale.getString('schedule.morning')
	}, {
		value: '12',
		name: this.locale.getString('schedule.afternoon')
	}, {
		value: '18',
		name: this.locale.getString('schedule.evening')
	}];


	getWeekDaysOptions = () => ObjectUtils.copy(this.weekDaysOptions);

	getMinOptions = () => this.minOptions;

	getAmpmOptions = () => this.ampmOptions;

	getSchedulingOptions = () => ObjectUtils.copy(this.schedulingOptions);

	getDefaultSchedulingOptions = () => ObjectUtils.copy(this.defaultSchedulingOptions);

	getStartTimeOptions = () => this.startTimeOptions;

	getActiveForOptions = () => {
		return [3, 6, 9, 12].map((a) => {
			return {
				displayName: this.locale.getString(`schedule.${a}months`),
				value: `${a}mo`,
				titleName: this.locale.getString(`schedule.${a}months`).toLowerCase() };
		});
	}

	getAmPmMessage = (timeObject) => {
		if (this.security.loggedUser.dateFormat === 'us') {
			return ` ${timeObject.ampm} `;
		}
		return '';
	}

	getScheduleInitialState = (cronExpression) => {
		const weekDaysOptions = this.getWeekDaysOptions();
		const recurrence = {day: 1, week: 1, month: 1, dayOfMonth: 1};
		const tokens = cronExpression.split(' ');
		if (tokens[3].indexOf('?') >= 0 || tokens[3].indexOf('*') >= 0) {
			recurrence.day = 1;
		} else if (tokens[3].indexOf('/') >= 0) {
			const subtokens = tokens[3].split('/');
			recurrence.day = parseInt(subtokens[1], 10);
		} else {
			recurrence.day = parseInt(tokens[3], 10);
		}
		recurrence.dayOfMonth = recurrence.day;

		if (tokens[4].indexOf('*') >= 0 || tokens[4].indexOf('?') >= 0) {
			recurrence.month = 1;
		} else {
			if (tokens[4].indexOf('/') < 0) {
				recurrence.month = parseInt(tokens[4], 10);
			} else {
				const tk = tokens[4].split('/');
				recurrence.month = parseInt(tk[1], 10);
			}
		}

		if (tokens[5].indexOf('*') < 0 && tokens[5].indexOf('?') < 0) {
			const subTokens = tokens[5].split(',');
			for (const day of weekDaysOptions) {
				if (subTokens.indexOf(day.id.toString()) >= 0) {
					day.value = true;
				}
			}
		}
		return {recurrence, weekDaysOptions};
	}

	processCron = (
		startDate: Date,
		endDate: Date,
		type: SchedulePeriod,
		activeFor: string,
		timeObject,
		recurrenceObject: ScheduleRecurrence,
		dateFormat: DateTimeFormat,
		weekDaysOptions: WeekdayOption[],
		useTimeDescription?: boolean
	) => {

		const activeForDisabled = this.isActiveForDisabled();
		const seconds = '0';
		const minutes = startDate.getMinutes().toString();
		const hours = startDate.getHours().toString();
		let dayOfMonth = '*';
		let month = '*';
		let dayOfWeek = '*';
		const year = '*';
		const dateLabel = this.dateService.format(startDate, dateFormat);
		const timezoneLabel = this.timezoneService.offsetToString(timeObject.timezoneOffset, true);

		let cronExpression = null;
		let scheduleDescription = '';
		let expirationMessage = '';
		let showWeekRequired = false;
		let monthValue;
		let weekDays;

		let activeText = '';
		let active;

		if (!activeForDisabled) {
			active = _.find(this.getActiveForOptions(), {value: activeFor}).titleName;
			activeText = this.locale.getString('schedule.forDuration', {duration: active});
		}

		if (type === SchedulePeriod.ONLY_ONCE) {
			scheduleDescription = this.locale.getString('schedule.onceSummary', {
				date: dateLabel,
				timeLabel: this.getTimeLabel(timeObject, useTimeDescription),
				timezone: timezoneLabel
			});
		} else if (type === SchedulePeriod.DAILY) {
			dayOfWeek = '?';

			scheduleDescription = this.locale.getString('schedule.dailySummary', {
				active: activeText,
				date: dateLabel,
				dayPart: this.locale.getString('schedule.everyDay'),
				timeLabel: this.getTimeLabel(timeObject, useTimeDescription),
				timezone: timezoneLabel
			});

			cronExpression = `${seconds} ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;
		} else if (type === SchedulePeriod.WEEKLY) {
			dayOfMonth = '?';

			const selectedWeekDaysOptions = weekDaysOptions.filter((option) => option.value);
			dayOfWeek = selectedWeekDaysOptions.reduce((left, right) => {
				return left === '' ? right.id + '' : `${left},${right.id}`;
			}, '');
			weekDays = selectedWeekDaysOptions.reduce((left, right) => {
				return left === '' ? right.name : `${left}, ${right.name}`;
			}, '');

			scheduleDescription = this.locale.getString('schedule.weeklySummary', {
				active: activeText,
				date: dateLabel,
				timeLabel: this.getTimeLabel(timeObject, useTimeDescription),
				weekdays: weekDays,
				timezone: timezoneLabel
			});
			cronExpression = `${seconds} ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;

			if (dayOfWeek === '') {
				showWeekRequired = true;
			}
		} else if (type === SchedulePeriod.MONTHLY) {
			dayOfMonth = `${recurrenceObject.dayOfMonth}`;
			month = `1/${recurrenceObject.month}`;
			dayOfWeek = '?';
			monthValue = recurrenceObject.month > 1 ?
				this.locale.getString('schedule.everyXMonths', {monthCount: recurrenceObject.month}) :
				this.locale.getString('schedule.everyMonth');

			scheduleDescription = this.locale.getString('schedule.newMonthlySummary', {
				active: activeText,
				date: dateLabel,
				timeLabel: this.getTimeLabel(timeObject, useTimeDescription),
				dayNumber: recurrenceObject.dayOfMonth,
				monthValue,
				timezone: timezoneLabel
			});

			cronExpression = `${seconds} ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;
		}

		if (type !== SchedulePeriod.ONLY_ONCE) {
			expirationMessage = this.isExpired(endDate)
				? this.locale.getString('schedule.expiredMessage', { date: this.dateService.format(endDate, dateFormat) })
				: this.locale.getString('schedule.expirationMessage', { date: this.dateService.format(endDate, dateFormat) });
		}

		return {cronExpression, scheduleDescription, showWeekRequired, expirationMessage};
	}

	isExpired = (endDate) => endDate && moment().diff(moment(endDate), 'days') >= 0;

	getNormalHour = (showampm, timeObject) => {
		if (showampm) {
			if (timeObject.ampm === this.ampmOptions[0]) {
				if (timeObject.hour === '12') {
					return '0';
				}
				return timeObject.hour;
			} else {
				if (timeObject.hour === '12') {
					return '12';
				}
				return (Number.parseInt(timeObject.hour, 10) + 12).toString();
			}
		} else {
			return timeObject.hour;
		}
	}

	convertHourForRange = (timeObject) => {
		const hour = parseInt(timeObject.hour, 10);

		if (timeObject.ampm === this.ampmOptions[0] && hour === 12) {
			//if its AM, check for being 12 and set to 0
			return '0';
		}
		if (timeObject.ampm === this.ampmOptions[1] && hour !== 12) {
			//if its PM, check for being 12 otherwise add 12
			return (hour + 12).toString();
		}

		return timeObject.hour;
	}

	setAmPmHour = (timeObject, showampm) => {
		const hourStr = timeObject.hour;
		const hour = Number.parseInt(hourStr, 10);
		if (showampm) {
			if (hour === 0) {
				timeObject.hour = '12';
				timeObject.ampm = this.ampmOptions[0];
			} else if (hour > 0 && hour < 12) {
				timeObject.hour = hour.toString();
				timeObject.ampm = this.ampmOptions[0];
			} else if (hour === 12) {
				timeObject.hour = '12';
				timeObject.ampm = this.ampmOptions[1];
			} else {
				timeObject.hour = (hour - 12).toString();
				timeObject.ampm = this.ampmOptions[1];
			}
		} else {
			timeObject.hour = hour.toString();
		}
	}

	getDefaultTimeObject = (startDate, timezoneOffset?) => {
		return {
			hour: startDate.getHours().toString(),
			min: this.round5(startDate.getMinutes()).toString(),
			ampm: this.ampmOptions[0],
			timezoneOffset
		};
	}

	round5 = (x) => Math.floor(x / 5) * 5;

	// CES-5011
	// activeFor is not supported right now. When it is, this can just be set to true, or better yet the code can be cleaned up to remove this and other references
	isActiveForDisabled = () => true;

	getScheduleType = (cronExpression?, supportedTypes?): SchedulePeriod => {
		const supportedTypeValues = (supportedTypes || this.getDefaultSchedulingOptions())
			.map((type) => type.value);

		if (!cronExpression && supportedTypeValues.contains(SchedulePeriod.ONLY_ONCE)) {
			return SchedulePeriod.ONLY_ONCE;
		}

		const tokens = cronExpression.split(' ');
		if (supportedTypeValues.contains(SchedulePeriod.DAILY)
				&& (tokens[1] === '*' || tokens[2] === '*' || tokens[3] === '*')) {
			// expression generated by the old version of scheduler
			return SchedulePeriod.DAILY;
		}

		if (supportedTypeValues.contains(SchedulePeriod.DAILY) && cronExpression.endsWith('* * ? *')) {
			return SchedulePeriod.DAILY;
		}

		if (supportedTypeValues.contains(SchedulePeriod.WEEKLY) && tokens[5] !== '?') {
			return SchedulePeriod.WEEKLY;
		}

		if (supportedTypeValues.contains(SchedulePeriod.MONTHLY) && tokens[5] === '?' && tokens[4].indexOf('/') >= 0) {
			return SchedulePeriod.MONTHLY;
		}

		return supportedTypeValues[0];
	}

	getScheduleDescription = (rawStartDate, rawEndDate, cronExpression, activeFor, timezoneOffset, useTimeDescription) => {
		const startDate = this.adjustDate(rawStartDate, timezoneOffset);
		const showAmPm = this.security.loggedUser.dateFormat === 'us';
		const timeObject = this.getDefaultTimeObject(startDate, timezoneOffset);
		let recurrenceObject;
		this.setAmPmHour(timeObject, showAmPm);

		if (cronExpression) {
			const initializationResult = this.getScheduleInitialState(cronExpression);
			recurrenceObject = initializationResult.recurrence;
			// let weekDaysOptions = initializationResult.weekDaysOptions;
		}

		const type = this.getScheduleType(cronExpression);

		return this.processCron(startDate, rawEndDate, type, activeFor,
			timeObject, recurrenceObject, DateTimeFormat.BASIC_DATE, this.weekDaysOptions, useTimeDescription).scheduleDescription;
	}

	// date is returned in millisecond offset format, which means we lose UTC offset
	// have to manually readjust to compensate
	adjustDate = (date, timezoneOffset) => {
		const localOffset = moment(date).utcOffset();

		if (typeof date === 'string') {
			date = moment(date);
		}
		return moment(date).add(timezoneOffset - localOffset, 'minutes').toDate();
	}

	getScheduleExpirationDate = (fromDate) => {
		return moment(fromDate).add(this.SCHEDULE_EXPIRATION_MONTHS, 'months').toDate();
	}

	getWarningClass = (count) => {
		if (count > this.EMAIL_WARN_THRESHOLD && count < this.EMAIL_DANGER_THRESHOLD) {
			return 'text-warning';
		} else if (count < 1 || count > this.EMAIL_WARN_THRESHOLD) {
			return 'text-danger';
		}
		return '';
	}

	getTimeLabel = (timeObject, useTimeDescription) => {
		if (useTimeDescription) {
			const descr = _.findWhere(this.startTimeOptions, {value: timeObject.hour});
			if (descr) {
				// else - fallback to old format
				return descr.name;
			}
		}
		const minuteLabel = _.findWhere(this.minOptions, {value: timeObject.min}).label;
		return `${timeObject.hour}:${minuteLabel}${this.getAmPmMessage(timeObject)}`;
	}
}

app.service('scheduleUtilsService', downgradeInjectable(ScheduleUtilsService));
