<page-content-header [title]="getTitle()"></page-content-header>
<div class="bg-white h-100-percent p-16 border-v-1 border-h-0 border-solid">
	<collapsing-panel>
		<panel-heading class="font-bold">{{'userAdministration.fileUploadTitle'|i18n}}</panel-heading>
		<panel-body>
			<div *ngIf="!bulkUpdate">
				<p>
					<span>{{ 'userAdministration.fileUploadInstructions' | i18n | i18nCut:0 }}</span>
					<a (click)="downloadTemplate()">{{ 'userAdministration.fileUploadInstructions' | i18n | i18nCut:1 }}</a>
					<span>{{ 'userAdministration.fileUploadInstructions' | i18n | i18nCut:2 }}</span>
				</p>
				<div class="ml-8 checkbox-inline">
					<input name="forceRegister"
						id="force-register"
						type="checkbox"
						[disabled]="forceRegisterDisabled()"
						[(ngModel)]="forceRegister">
					<label for="force-register">
						{{'administration.forceRegistration'|i18n}}
						<cb-inline-help right>
							<help-body>{{'userAdministration.forceRegisterHelpTooltip'|i18n}}</help-body>
						</cb-inline-help>
					</label>
				</div>
			</div>
			<div *ngIf="bulkUpdate">
				<p>
					<span>{{ 'userAdministration.bulkUpdateFileUploadInstructions' | i18n }}</span><br/>
					<span>{{ 'userAdministration.bulkUpdateFileUploadInstructionsLine2' | i18n | i18nCut:0 }}</span>
					<a (click)="downloadTemplate()">
						{{ 'userAdministration.bulkUpdateFileUploadInstructionsLine2' | i18n | i18nCut:1 }}</a>
					<span>{{ 'userAdministration.bulkUpdateFileUploadInstructionsLine2' | i18n | i18nCut:2 }}</span>
				</p>
			</div>
			<file-upload
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.XLS, MediaType.CSV]"
				[uploadMetadata]="getFileUploadMetadata()"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(erroneousUpload)="handleFailedUpload()"
				(removedUpload)="handleRemovedUpload()"
			></file-upload>
		</panel-body>
	</collapsing-panel>
	<collapsing-panel
		[collapsed]="!showProcessingResult"
		[disableCollapse]="!hasData()">
		<panel-heading class="font-bold">{{'userAdministration.fileSummaryTitle'|i18n}}</panel-heading>
		<panel-body>
			<p>{{ fileSummary }}</p>
			<div class="bg-secondary d-flex flex-direction-column fill-container" *ngIf="showStatusFilter">
				<ul ngbNav #nav="ngbNav" class="nav-tabs">
					<li ngbNavItem *ngFor="let filterOption of statusFilterOptions"
						[ngClass]="{'active': filterOption.status === filter.statusFilter}"
						(click)="updateStatusFilter(filterOption.status)"
					>
						<a ngbNavLink>
							<span [ngClass]="filterOption.class" class="mr-4"></span>
							{{ getFilterOptionTitle(filterOption) | uppercase }}
						</a>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav" class="mt-2 flex-fill tab-content-fill-container"></div>
			</div>

			<p [i18n]="'userAdministration.findUser'" class="font-bold"></p>
			<div class="grid-list-tools" [ngBusy]="loading">
				<input
					type="text"
					class="grid-search-bar"
					placeholder="{{'common.find'|i18n}}"
					[(ngModel)]="filter.textFilter"
					(ngModelChange)="updateTextFilter($event)">
			</div>

			<formatted-table
				[rows]="filteredUsers"
				[columns]="currentTabColumns"
				[scrollable]="false"
			></formatted-table>
		</panel-body>
	</collapsing-panel>
</div>

<div class="bg-white pv-8 ph-16 d-flex justify-end">
	<button
		class="btn btn-primary"
		[disabled]="!hasItemsToProcess()"
		(click)="save()">
		{{'common.save'|i18n}}
		<span *ngIf="fileUploadStatus === statuses.SCHEDULING" class="q-icon q-icon-spinner rotate-infinite"></span>
	</button>
	<button
		*ngIf="fileUploadStatus != statuses.PROCESSING"
		class="btn btn-secondary"
		(click)="cancel()">
		{{'common.cancel'|i18n}}
	</button>
	<button
		*ngIf="fileUploadStatus == statuses.PROCESSING"
		class="btn btn-secondary"
		(click)="cancel()">
		{{'common.back'|i18n}}
	</button>
</div>
