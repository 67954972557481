import { Pipe, PipeTransform } from '@angular/core';
import { getDefaultWhiteList, IWhiteList, filterXSS } from 'xss';

@Pipe({
	name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {

	private whiteList: IWhiteList;

	constructor() {
		this.whiteList = getDefaultWhiteList();
		_.each(this.whiteList, (list, tag) => {
			list.push('style');
			list.push('class');
		});
	}

	transform(value: string): string {
		return filterXSS(value, {
			css: false,
			whiteList: this.whiteList,
			stripIgnoreTag: true,
			stripIgnoreTagBody: true,
		});
	}

}
