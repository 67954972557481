import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DashboardGridHelperService } from '@app/modules/dashboard-list/context-menu/dashboard-grid-helper.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { ScorecardEditorWizardInput } from '@app/modules/project/scorecard/editor/scorecard-editor-wizard.component';
import { ScorecardEditorWizardService } from '@app/modules/project/scorecard/editor/scorecard-editor-wizard.service';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { Model } from '@cxstudio/reports/entities/model';
import { ColDef, GetContextMenuItemsParams, MenuItemDef, GridReadyEvent, GridApi, ColumnApi } from 'ag-grid-community';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';

@Component({
	selector: 'scorecards-table',
	template: `
<div class="w-100-percent h-100-percent d-flex flex-direction-column pt-16" [ngBusy]="loading">
	<alert *ngIf="noScorecardModels" type="danger" text="{{'scorecards.noModelSelectedError'|i18n}}"></alert>
	<default-objects-table class="w-100-percent flex-fill overflow-hidden"
		tableName="projectScorecards"
		[columnDefs]="columnDefs"
		[rowData]="scorecards"
		[withFolders]="false"
		[quickFilterText]="searchText"
		[getContextMenuItems]="getContextMenuItems"
		(gridReady)="onGridReady($event)"
	></default-objects-table>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorecardsTableComponent implements OnInit, OnChanges {
	@Input() project: WorkspaceProjectData;
	@Input() accountProject: IProjectSelection;
	@Input() searchText: string;

	loading: Promise<unknown>;
	noScorecardModels: boolean;

	scorecards: Scorecard[];

	columnDefs: ColDef[];
	gridApi: GridApi;
	gridColumnApi: ColumnApi;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private objectListColumns: ObjectListColumnsService,
		private cxDialogService: CxDialogService,
		private scorecardEditorWizardService: ScorecardEditorWizardService,
		@Inject('security') private security: Security,
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
	) { }

	ngOnInit(): void {
		this.columnDefs = [
			this.objectListColumns.contextMenuColumn(),
			this.objectListColumns.toggleColumn('active', this.locale.getString('common.enabled'),
				(scorecard, active) => this.setActive(scorecard, active),
				() => !this.canManageScorecards()),
			this.objectListColumns.textColumn('name', this.locale.getString('common.name')),
			this.objectListColumns.ownerColumn('creatorText', this.locale.getString('scorecards.creator')),
			this.objectListColumns.dateColumn('lastModifiedDate', this.locale.getString('scorecards.lastModified')),
			this.objectListColumns.textColumn('categoryModel', this.locale.getString('scorecards.categoryModel')),
			this.objectListColumns.numberColumn('autoFailCriteria', this.locale.getString('scorecards.autoFailCriteria')),
			this.objectListColumns.numberColumn('scoredCriteria', this.locale.getString('scorecards.scoredCriteria')),
			this.objectListColumns.numberColumn('threshold', this.locale.getString('scorecards.targetLabel')),
		];
		this.reloadScorecards();
	}

	ngOnChanges(changes: SimpleChanges<ScorecardsTableComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.reloadScorecards();
		}
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

	}

	private canManageScorecards(): boolean {
		return this.project?.accessLevel === ProjectAccessLevelValue.MANAGER
			&& this.security.has(MasterAccountPermissionAction.MANAGE_PROJECTS);
	}

	getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
		if (!params.node) {
			return;
		}
		const scorecard = params.node.data as Scorecard;
		let options: (string | MenuItemDef)[];

		if (this.canManageScorecards()) {
			options = [{
				name: this.locale.getString('common.edit'),
				action: () => this.editScorecard(scorecard),
			},
			{
				name: scorecard.active ? this.locale.getString('common.disable') : this.locale.getString('common.enable'),
				action: () => this.setActive(scorecard, !scorecard.active),
			},
			DashboardGridHelperService.MENU_OPTIONS_SEPARATOR,
			{
				name: this.locale.getString('common.delete'),
				action: () => this.deleteScorecard(scorecard),
			}];
		} else {
			options = [{
				name: this.locale.getString('common.view'),
				action: () => this.viewScorecard(scorecard),
			}];
		}
		return options;
	}

	private reloadScorecards(): void {
		if (!WorkspaceProjectUtils.isProjectSelected(this.project)) {
			return;
		}
		let scorecardsPromise = PromiseUtils.wrap(this.scorecardsApiService.getScorecards(
				this.accountProject.contentProviderId, this.accountProject.accountId, this.accountProject.projectId));
		this.loading = PromiseUtils.all([this.getScorecardModels(), scorecardsPromise]).then((response) => {
			if (response[0]) {
				let models = response[0];
				let scorecards = response[1];
				// scorecards are returned in random order every time, so need to sort them
				scorecards.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
				this.populateCreatorText(scorecards);
				this.populateMetadata(scorecards, models);
				this.scorecards = scorecards;
				this.ref.markForCheck();
			}
		});
	}

	private getScorecardModels(): Promise<Model[]> {
		this.noScorecardModels = false;
		return PromiseUtils.wrap(this.scorecardsApiService.getScorecardModels(this.accountProject.contentProviderId,
			this.accountProject.accountId,
			this.accountProject.projectId
		)).then(result => {
			if (result.isEmpty()) {
				this.noScorecardModels = true;
				return [];
			}
			return result;
		});
	}

	private populateCreatorText(scorecards: Scorecard[]): void {
		scorecards.forEach((scorecard: Scorecard) => {
			scorecard.creatorText = scorecard.creatorEmail ? scorecard.creatorEmail : scorecard.creator;
		});
	}

	private populateMetadata(scorecards: Scorecard[], models: Model[]): void {
		scorecards.forEach((scorecard: Scorecard) => {
			if (!_.isEmpty(models)) {
				let model = _.findWhere(models, {id: scorecard.modelId});
				scorecard.categoryModel = model?.name;
			}
			this.populateScoringCriteriaCount(scorecard);
		});
	}

	private populateScoringCriteriaCount(scorecard: Scorecard): void {
		let autoFailCount = 0;
		let weightedCount = 0;
		(scorecard.scorecardTopics || []).forEach((scorecardTopic: ScorecardTopic) => {
			if (scorecardTopic.autoFail) {
				autoFailCount++;
			} else if (scorecardTopic.topicWeight) {
				weightedCount++;
			}
		});
		scorecard.autoFailCriteria = autoFailCount;
		scorecard.scoredCriteria = weightedCount;
	}

	private deleteScorecard(scorecard: Scorecard): void {
		const dialog = this.cxDialogService.warningWithConfirm(
			this.locale.getString('scorecards.deleteScorecardsTitle', {name: scorecard.name}),
			this.locale.getString('scorecards.deleteScorecardsText', {name: scorecard.name})
		);

		dialog.result.then(() => {
			this.loading = PromiseUtils.wrap(this.scorecardsApiService.remove(
				this.accountProject.contentProviderId, this.accountProject.accountId, scorecard))
				.then(() => this.reloadScorecards());
		}, () => {});
	}

	private setActive(scorecard: Scorecard, value: boolean): Promise<unknown> {
		scorecard.active = value;
		const promise = PromiseUtils.wrap(this.scorecardsApiService.updateActiveState(
			this.accountProject.contentProviderId, this.accountProject.accountId, scorecard));
		this.gridApi.redrawRows();
		this.loading = promise;
		return promise.then(_.noop, () => {
			scorecard.active = !value; // revert value
			this.showErrorMessage(scorecard);
			return Promise.reject();
		});
	}

	private showErrorMessage = (scorecard: Scorecard): void => {
		this.cxDialogService.warning(
			this.locale.getString('scorecards.enableErrorHeader', { name: scorecard.name }),
			this.locale.getString('scorecards.enableErrorMessage'));
	}

	newScorecard(): void {
		this.getScorecardModels().then(models => {
			if (!models.isEmpty()) {
				const newScorecardModalResult = this.openScorecardsModal(CxWizardMode.EDIT, models);
				newScorecardModalResult.then(() => this.reloadScorecards());
			}
		});
	}

	private openScorecardsModal(mode: CxWizardMode, models: Model[], scorecard?: Scorecard): Promise<Scorecard> {
		let input: ScorecardEditorWizardInput = {
			mode,
			models,
			props: this.accountProject,
			scorecard,
			scorecards: this.scorecards
		};

		return this.scorecardEditorWizardService.open(input).result.then(result => result, _.noop);
	}

	private editScorecard(scorecard: Scorecard): void {
		this.getScorecardModels().then((models: Model[]) => {
			if (models && models.length) {
				const editScorecardModalResult = this.openScorecardsModal(CxWizardMode.EDIT, models, scorecard);
				editScorecardModalResult.then(() => this.reloadScorecards());
			}
		});
	}

	private viewScorecard(scorecard: Scorecard): void {
		this.getScorecardModels().then((model) => {
			if (model) {
				this.openScorecardsModal(CxWizardMode.VIEW_LAST_PAGE_ONLY, model, scorecard);
			}
		});
	}

}
