import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Label } from '@cxstudio/dashboards/entity/label';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'labels-renderer',
	styles: [`
		.labels-list {
			gap: 8px;
			margin-top: 6px;
			margin-bottom: 8px;
		}
	`],
	template: `
	<div class="d-flex flex-wrap labels-list" *ngIf="labels?.length > 0">
		<span *ngFor="let label of labels"
			class="border-2 border-solid ph-8 pv-4 lh-1">
			{{label}}
		</span>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsRendererComponent implements ICellRendererAngularComp {

	labels: Label[];

	constructor(
	) { }

	refresh(params: ICellRendererParams): boolean {
		this.agInit(params);
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.labels = params.value;
	}

}
