<div class="d-flex flex-direction-column w-100-percent h-100-percent"
	[ngBusy]="loading">
	<embedded-dashboard-header
		*ngIf="!!embeddedDashboard && loaded"
		[containerId]="containerId"
		[dashboard]="embeddedDashboard.dashboard"
		[dashboardHistory]="dashboardHistory"
		[personalization]="personalization"
		[isEmbeddedInternally]="isEmbeddedInternally"
	></embedded-dashboard-header>
	<dashboard-view *ngIf="!!embeddedDashboard"
		class="flex-fill"
		[loaded]="loaded"
		[firstLoad]="true"
		[containerId]="containerId"
		[dashboard]="embeddedDashboard.dashboard"
		[dashboardHistory]="dashboardHistory"
		[personalization]="personalization"
		[widgets]="embeddedDashboard.widgets">
	</dashboard-view>
	<embedded-dashboard-footer
		*ngIf="!!embeddedDashboard && loaded && !isEmbeddedInternally"
		[dashboard]="embeddedDashboard.dashboard"
		></embedded-dashboard-footer>
</div>
