import * as _ from 'underscore';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, AfterViewInit, Inject, ViewEncapsulation } from '@angular/core';
import { CxLocaleService } from '@app/core';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { MasterAccountIntegrationComponent } from '@app/modules/system-administration/master-account/integration/master-account-integration.component';
import { MasterAccountAdminPropertiesComponent } from './master-account-admin-properties.component';
import { MasterAccountLicensingComponent } from './master-account-licensing.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MasterAccountFormDataConverter, MasterAccountFormData } from './master-account-form-data-converter.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { MasterAccountHierarchySettingsComponent } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-hierarchy-settings.component';

export interface MasterAccountEditDialogInput {
	masterAccount: MasterAccount;
	licenseTypes: LicenseTypeItem[];
}

export interface MasterAccountEditDialogOutput {
	masterAccount: MasterAccount;
}

@Component({
	selector: 'master-account-dialog',
	templateUrl: './master-account-edit-dialog.component.html',
	encapsulation: ViewEncapsulation.None,
	styles: [`
		master-account-dialog .nav-tabs {
			--tab-border-color: var(--gray-300);
		}
		master-account-dialog .nav-tabs .nav-link:not(.active):hover {
			--tab-border-color: var(--action-800);
			color: var(--action-800);
		}
	`]
})

export class MasterAccountEditDialogComponent extends SelfCleaningComponent implements OnInit, AfterViewInit {

	@Input() input: MasterAccountEditDialogInput;

	@ViewChild(MasterAccountAdminPropertiesComponent, { static: false })
	properties: MasterAccountAdminPropertiesComponent;

	@ViewChild(MasterAccountLicensingComponent, { static: false })
	licensing: MasterAccountLicensingComponent;

	@ViewChild(MasterAccountIntegrationComponent, { static: false })
	integration: MasterAccountIntegrationComponent;

	@ViewChild(MasterAccountHierarchySettingsComponent, { static: false })
	hierarchySettings: MasterAccountHierarchySettingsComponent;

	masterAccountForm: FormGroup;

	masterAccountFormInitData: MasterAccountFormData;
	licenses: LicenseTypeItem[];
	masterAccountId: number;
	initIdpAliasName: string;


	upload = {
		certificate: {} as any,
		metadata: {} as any
	};
	showError = false;

	constructor(
		private formUtils: CxFormUtils,
		private fb: FormBuilder,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		private masterAccountFormDataConverter: MasterAccountFormDataConverter,
		private cxDialogService: CxDialogService,
		@Inject('securityApiService') private securityApiService,
	) {
		super();
		this.masterAccountForm = this.fb.group({
			betaFeatures: [ [] ]
		});
	}

	ngOnInit(): void {
		this.licenses = ObjectUtils.copy(this.input.licenseTypes);
		this.masterAccountFormInitData = this.getMasterAccountCopy(this.input.masterAccount);
		this.masterAccountId = this.input.masterAccount?.accountId;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.initialGroups();
			this.masterAccountForm.patchValue(this.masterAccountFormInitData);
		});
	}

	initialGroups(): void {
		this.masterAccountForm = this.fb.group({
			accountId: [],
			properties: this.properties.getGroup(),
			licensing: this.licensing.getGroup(),
			integration: this.integration.getGroup(),
			betaFeatures: [ [] ],
			hierarchySettings: this.hierarchySettings.getGroup(),
		});
	}

	getModalTitle(): string {
		return this.masterAccountId
			? this.locale.getString('mAccount.accountTitle')
			: this.locale.getString('mAccount.createAccountTitle');
	}

	disableSaveButton = (): boolean => {
		return !this.masterAccountForm.dirty;
	}

	getErrorMessage(): string {
		return this.locale.getString('mAccount.editFormError', {count: this.formUtils.getErrorsCount(this.masterAccountForm)});
	}

	private getMasterAccountCopy(initialMasterAccount: MasterAccount): MasterAccountFormData {
		let formData = this.masterAccountFormDataConverter.from(initialMasterAccount);

		this.populateEmptyCredits(formData);
		return formData;
	}

	private processPredictionSettings(masterAccount: MasterAccount): void {
		if (masterAccount?.predictionSettings?.password &&
			this.masterAccountFormInitData.integration.predictionSettings?.password
				=== masterAccount?.predictionSettings?.password) {
			delete masterAccount.predictionSettings.password;
		}
	}

	private populateEmptyCredits(masterAccount: MasterAccountFormData): void {
		for (let license of this.licenses) {
			if (!masterAccount.licensing.contractLicenses[license.licenseTypeId]) {
				masterAccount.licensing.contractLicenses[license.licenseTypeId] = 0;
			}
		}
	}

	save(): void {
		this.masterAccountForm.markAllAsTouched();
		this.integration.qualtricsIntegration.ref.markForCheck();

		if (this.masterAccountForm.invalid) {
			this.showError = true;
			return;
		}

		this.input.masterAccount = _.extend({}, this.input.masterAccount,
			this.masterAccountFormDataConverter.to(this.masterAccountForm.getRawValue()));

		this.processPredictionSettings(this.input.masterAccount);

		let dialogOutput: MasterAccountEditDialogOutput = {
			masterAccount: ObjectUtils.copy(this.input.masterAccount),
		};
		this.modal.close(dialogOutput);
	}

	cancel(): void {
		if (this.masterAccountForm.touched && this.masterAccountForm.dirty) {
			let dialog = this.cxDialogService.warningWithConfirm(
				this.locale.getString('common.unsavedChangesHeader'),
				this.locale.getString('mAccount.unsavedChanges'),
				this.locale.getString('common.closeWithoutSave'));

			dialog.result.then(() => {
				this.modal.dismiss();
			}, _.noop);
		} else {
			this.modal.dismiss();
		}
	}

	get betaFeaturesForm(): FormControl {
		return this.masterAccountForm.controls.betaFeatures as FormControl;
	}

	get propertiesForm(): FormGroup {
		return this.masterAccountForm.controls.properties as FormGroup;
	}

	get licensingForm(): FormGroup {
		return this.masterAccountForm.controls.licensing as FormGroup;
	}

	get integrationForm(): FormGroup {
		return this.masterAccountForm.controls.integration as FormGroup;
	}

	get hierarchySettingsForm(): FormGroup {
		return this.masterAccountForm.controls.hierarchySettings as FormGroup;
	}
}
