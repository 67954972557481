
// directive sets 'direction' property to 'left' or 'right' depending on 'direction-threshold' attribute
// if threshold is 0.75, then it will set 'right' in first 3/4 of screen, and 'left' - on last 1/4
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { DropdownDirection } from '@app/shared/menu/dropdown-direction.type';

interface IDirectiveScope extends ng.IScope {
	directionThreshold: number;
	directionChanged: (params: {$direction: DropdownDirection}) => void;
}
app.directive('dropdownDirection', ($window: ng.IWindowService) => {
	return {
		restrict: 'A',
		scope: {
			directionThreshold: '=',
			directionChanged: '&',
		},
		link: (scope: IDirectiveScope, element: ng.IAugmentedJQuery) => {
			let lastDirection: DropdownDirection;
			element.on('mousedown keydown', (event: JQuery.MouseDownEvent | JQuery.KeyDownEvent) => {
				let currentX = event.pageX;
				if (KeyboardUtils.isKeyDown(event) && KeyboardUtils.isEventKey(event as any, Key.ENTER)) {
					let rect = event.target.getBoundingClientRect();
					currentX = rect.width / 2 + rect.x;
				}
				let width = $window.innerWidth;
				let threshold = scope.directionThreshold * width;
				let showLeft = currentX > threshold;
				let alwaysRight = false;
				if (showLeft) {
					// when zoom - use right direction due to position issues
					alwaysRight = $(event.target).parents('.br-gridster-zoom').length > 0;
				}
				let newDirection: DropdownDirection = showLeft && !alwaysRight ? 'left' : 'right';
				if (lastDirection !== newDirection) {
					lastDirection = newDirection;
					scope.directionChanged({$direction: newDirection});
				}
			});

		}
	};
});
