<div class="col-sm-6">
	<logo-settings
		[brandingColors]="branding$">
	</logo-settings>

	<hr>

	<div class="row">
		<div class="columns-2 mb-8">
			<div class="form-group" *ngFor="let color of colorsNameMap">
				<label for="{{color.displayName}}" class="color-label">{{color.displayName}}</label>
				<color-input
					id="{{color.displayName}}"
					class="branding-color-input {{color.name}}"
					[(ngModel)]="brandingColors[color.name]"
					(ngModelChange)="updateColor(color.name)">
				</color-input>
			</div>
		</div>
	</div>

	<checkbox-button
		data-testid="use-more-accessible-color"
		class="mb-8"
		label="{{'appearance.useMoreAccessibleColor'|i18n}}"
		inlineHelp="{{'appearance.useMoreAccessibleColorTooltip'|i18n}}"
		[(ngModel)]="useMoreAccessibleColor">
	</checkbox-button>


	<div class="mv-16">
		<cb-notice class="br-save-success" type="success" [hidden]="!brandingSubmissionSuccess">
			{{'mAccount.propBrandingSavedSuccess'|i18n}}
		</cb-notice>

		<cb-notice type="danger" [hidden]="!brandColorError">
			{{'administration.colorsCantBeEmpty'|i18n}}
		</cb-notice>
	</div>

	<div class="row">
		<div class="col-sm-4 d-flex">
			<button
				id="btn-branding-save"
				type="submit"
				class="btn btn-primary"
				(click)="saveChanges()"
				[disabled]="!hasChanges() || !areColorsValid()"
				[btnLoading]="loading"
				loadingText="{{'common.saving'|i18n}}"
				[i18n]="'common.save'"></button>

			<button
				id="btn-branding-cancel"
				type="button"
				class="btn btn-secondary"
				[disabled]="!hasChanges()"
				(click)="cancelChanges()">{{'common.resetChanges'|i18n}}</button>

			<button id="btn-branding-default" type="button" class="btn btn-secondary" (click)="resetToDefault()">{{'common.resetDefault'|i18n}}</button>
		</div>
	</div>
</div>
<div class="col-sm-6">
	<app-colors-preview [brandingColors]="branding$"></app-colors-preview>
</div>
