import { IDateRange } from '@cxstudio/reports/entities/date-period';

export class LogQueryOptionsInput {
	query: any;
	logQueryBy: string;
	dateRange: IDateRange;

	constructor() {
		this.query = '';
		this.logQueryBy = '';
		this.dateRange = null;
	}
}