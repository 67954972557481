import * as _ from 'underscore';
import { ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { TopicSelectionStrategy } from './topic-selection-strategy.interface';

export class SimpleTopicSelection extends TopicSelectionStrategy {

	private nodesMap: {[nodeId: number]: ModelTreeNode} = {};

	constructor(
		private root: ModelTreeNode,
		private selectedIds?: number[],
		private disabledItemsProvider?: () => string[], // idPath list of disabled items
	) {
		super();
		this.init();
	}

	private init(): void {
		this.updateNodesMap();
		this.resetDisabledItems();
	}

	getRoot = (): ModelTreeNode => {
		return this.root;
	}

	getAllNodeIds = (): number[] => {
		return _.chain(_.keys(this.nodesMap))
			.map(id => parseInt(id, 10))
			.filter(id => id !== this.root.id)
			.value();
	}

	isNodeChecked = (node: ModelTreeNode): boolean => {
		return _.contains(this.selectedIds, node.id);
	}

	isNodeCheckboxVisible = (node: ModelTreeNode): boolean => {
		return !node._disabled;
	}

	isNodeIndeterminate = (node: ModelTreeNode): boolean => {
		return false;
	}

	handleNodeClick = (node: ModelTreeNode): void => {
		if (node._disabled)
			return;
		if (this.isNodeChecked(node)) { 
			this.removeSelection(node.id);
		} else {
			this.addSelection(node.id);
		}
	}

	resetDisabledItems = (): void => {
		let nodeIdRegexp = /(\d+)$/;
		let disabledItems = _.map(this.disabledItemsProvider && this.disabledItemsProvider(), idPath => {
			return Number(nodeIdRegexp.exec(idPath)[1]); // get last id from path
		});
		_.each(this.nodesMap, (node: ModelTreeNode) => {
			delete node._disabled;
		});
		_.each(disabledItems, nodeId => {
			this.disableNodeRecursively(this.nodesMap[nodeId]);
		});
	}

	refresh = (): void => {
		
	}

	setSelectedIds = (selectedIds: number[]): void => {
		this.selectedIds = selectedIds;
	}

	getSelectedIds = (): number[] => {
		return this.selectedIds;
	}

	private updateNodesMap = (): void => {
		this.nodesMap = {};
		this.mapChildrenRecursively(this.root);
	}

	private mapChildrenRecursively = (node: ModelTreeNode): void => {
		this.nodesMap[node.id] = node;
		_.each(node.children, this.mapChildrenRecursively);
	}

	private addSelection = (nodeId): void => {
		this.selectedIds.push(nodeId);
	}

	private removeSelection = (nodeId): void => {
		this.selectedIds.remove(nodeId);
	}

	// we need more recursions!
	private disableNodeRecursively = (node: ModelTreeNode): void => {
		node._disabled = true;
		_.each(node.children, this.disableNodeRecursively);
	}
}