import { OnInit, Directive } from '@angular/core';
import { WidgetLocalEvent } from '@app/core/cx-event.enum';
import { LocalEventBus } from '@app/core/local-event-bus.service';
import { WidgetEventBridgeComponent } from '@app/modules/widget-container/widget-event-bridge';

@Directive()
export abstract class SimpleWidgetBaseComponent extends WidgetEventBridgeComponent implements OnInit {

	constructor(
		protected localEventBus: LocalEventBus,
	) {
		super(localEventBus);
	}

	protected abstract redrawWidget();

	ngOnInit(): void {
		super.ngOnInit();

		this.redrawWidget();

		this.addSubscription(this.localEventBus.subscribe(WidgetLocalEvent.REFRESH, () => this.widgetRefresh()));
		this.addSubscription(this.localEventBus.subscribe(WidgetLocalEvent.HARD_REFRESH, () => this.widgetRefresh()));
	}

	private widgetRefresh(): void {
		this.redrawWidget();
		this.localEventBus.emitOutside(WidgetLocalEvent.REFRESH_FINISHED);
	}
}
