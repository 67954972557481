import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { IWidgetMenuState } from '@app/modules/widget-container/widget-menu/widget-menu-state.interface';
import { WidgetMenuComponent } from '@app/modules/widget-container/widget-menu/widget-menu.component';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetKeyboardUtils } from '@cxstudio/reports/widget-utils/widget-keyboard-utils';
import { EventEmitterService, IHandlerCallback } from '@cxstudio/services/event/event-emitter.service';
import { ContentWidgetKeyboardNavigationUtils } from '../content-widget-kb-navigation-utils.service';
import { IWidgetActions } from '@cxstudio/home/widgets-edit.service';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';

@Component({
	selector: 'widget-box',
	templateUrl: './widget-box.component.html',
	styles: [`
.fixed-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.fixed-header widget-title-container {
	flex: 1 1 auto !important;
}
header {
	z-index: 1;
}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBoxComponent implements OnInit, OnDestroy {

	@Input() widget: Widget;
	@Input() layout: LayoutHelper;
	@Input() headerDelay = undefined; // delay in milliseconds before showing the header
	@Input() showHeader = true;
	@Input() fixedHeader: boolean;
	@Input() editMode: boolean;
	@Input() editable: boolean;
	@Input() widgetActions: IWidgetActions;

	@Input() headerLeft: TemplateRef<any>;
	@Input() headerInfo: TemplateRef<any>;
	@Input() menu: TemplateRef<any>;
	@Input() footer: TemplateRef<any>;
	@Input() other: TemplateRef<any>;

	containerOrMenuButtonFocused = false;
	hovered = false;
	private headerTimeout: ReturnType<typeof setTimeout>;
	menuState: IWidgetMenuState;
	isContentWidget: boolean;

	@ViewChild('mainMenu', {static: false}) mainMenu: WidgetMenuComponent;

	expanded: boolean; // mobile view

	headerVisibilityKeyboardNavigationHandler: IHandlerCallback;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		@Inject('eventEmitterService') private readonly eventEmitterService: EventEmitterService,
	) {}

	ngOnInit(): void {
		this.menuState = {
			open: false,
			dropdownDirection: 'left',
			position: {},
		};

		this.isContentWidget = WidgetTypeFilters.isContentWidget(this.widget);

		if (this.isContentWidget || WidgetTypeFilters.isPageBreak(this.widget)) {
			// content widgets are not customizable, so always translate to current UI language
			this.widget.displayName = this.locale.getString(`widget.${this.widget.name}`) ?? this.widget.displayName;
		}

		this.headerVisibilityKeyboardNavigationHandler = this.eventEmitterService.subscribe(
			ContentWidgetKeyboardNavigationUtils.generateEventType(this.widget),
			(containerOrMenuButtonFocused) => {
				this.containerOrMenuButtonFocused = containerOrMenuButtonFocused;
				this.ref.detectChanges();
			});
	}

	ngOnDestroy(): void {
		if (this.headerVisibilityKeyboardNavigationHandler) {
			this.headerVisibilityKeyboardNavigationHandler.unsubscribe();
		}
	}

	@HostListener('mouseenter')
	onMouseEnter(): void {
		if (this.isHeaderDelayed() && !this.headerTimeout) {
			this.headerTimeout = setTimeout(() => {
				this.hovered = true;
				this.ref.markForCheck();
			}, this.headerDelay);
		}
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		if (this.headerTimeout) {
			clearTimeout(this.headerTimeout);
			delete this.headerTimeout;
		}
		if (this.isHeaderDelayed()) {
			this.hovered = false;
		}
	}

	private isHeaderDelayed(): boolean {
		return this.headerDelay >= 0;
	}

	isHeaderVisible(): boolean {
		return this.showHeader && (this.containerOrMenuButtonFocused || this.hovered || !this.isHeaderDelayed() || this.menuState.open);
	}

	handleRightClick(event: MouseEvent): void {
		if (!this.isHeaderVisible()) {
			return;
		}
		event.preventDefault();
		this.menuState.open = true;

		let menuElement = $(this.mainMenu.toggleElement.nativeElement);

		let left = event.pageX - menuElement.offset().left - menuElement.width();
		let top = event.pageY - menuElement.offset().top - menuElement.height();

		const newPosition = {
			visibility: null,
			left: `${left}px`,
			top: `${top}px`
		};

		this.menuState.position = newPosition;

		this.ref.markForCheck();
	}

	getWidgetMenuClass(): string {
		if (!this.editMode && this.layout?.zoomLevel
			&& this.widget && !WidgetTypeFilters.isContentWidget(this.widget)) {
			return 'pointer-events-children-none';
		}

		return '';
	}

	processCommonKb(event: KeyboardEvent): void {
		WidgetKeyboardUtils.processKeyboardEvent(event, this.widget.id);
	}

	getDefaultDisplayName(): string {
		return this.locale.getString(`widget.${this.widget.name}`);
	}

	setMenuOpen(open: boolean) {
		this.menuState.open = open;
		this.ref.markForCheck();
	}

	getMenuActions() {
		return {
			closeMenu: () => this.setMenuOpen(false)
		};
	}

}

app.directive('widgetBox', downgradeComponent({component: WidgetBoxComponent}) as angular.IDirectiveFactory);
