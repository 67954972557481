import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { IScorecardPreviewResultParams, ScorecardPreviewResultModalComponent } from './modal/scorecard-preview-result-modal.component';

@Injectable({
	providedIn: 'root'
})
export default class ScorecardPreviewResultService {

	constructor(
		private cxDialogService: CxDialogService
	) {}

	open (input: IScorecardPreviewResultParams): NgbModalRef {
		return this.cxDialogService.openDialog(
			ScorecardPreviewResultModalComponent, input, { size: ModalSize.MEDIUM });
	}

}
