import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBindings } from '@app/modules/modal/modal-bindings';

export const ODO_NEW_LEGAL_TICKET_URL = 'https://odo.corp.qualtrics.com/?createTicket=page:Legal_Review_FAQ';

@Component({
	selector: 'qualtrics-integration-confirmation-dialog',
	template: `

		<div class="modal-header">
			<h2 class="modal-title">{{'mAccount.reviewRequired'|i18n}}</h2>
		</div>
		<section class="modal-body">
			<p>{{'mAccount.qualtricsIntegrationWarning'|i18n}}</p>

			<p class="mb-32">{{'mAccount.getStartedBy'|i18n}} <a [href]="newOdoTicketURL" class="underline">{{'mAccount.creatingOdoLink'|i18n}}</a>.</p>

			<p class="font-semibold mb-16">{{'mAccount.completedLinkVerification'|i18n}}</p>

			<div class="form-group d-flex align-items-center">
				<label class="control-label col-md-3 mb-0">
					{{'mAccount.ticketLink'|i18n}}
				</label>
				<input class="form-control" [(ngModel)]="ticketURL">
			</div>

		</section>
		<save-modal-footer
			(save)="close(ticketURL.trim())"
			(cancel)="dismiss()"
			[isDisabled]="isApproveButtonDisabled()"
			[saveText]="'mAccount.integrationApproved'|i18n">
		</save-modal-footer>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsIntegrationConfirmationDialogComponent extends ModalBindings<any>{

	ticketURL: string | null;
	newOdoTicketURL = ODO_NEW_LEGAL_TICKET_URL;
	constructor(
		private ref: ChangeDetectorRef,
		modal: NgbActiveModal
	) {
		super(modal);
	}

	isApproveButtonDisabled(): boolean {
		return !(this.ticketURL?.trim());
	}
}
