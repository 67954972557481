import { StudioAlert } from '../entities/studio-alert';
import { AlertingApiService } from '../api/alerting-api.service';
import { IShareUiConfig, ISharePermissionNameGetter } from '@cxstudio/sharing/generic-share.component';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import {CBDialogService} from '@cxstudio/services/cb-dialog-service';
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { GroupType } from '@app/modules/user-administration/groups/group-type';

export class AlertSharingComponent implements ng.IComponentController {
	private alert: StudioAlert;
	groupingValidator: {validate?: (grouping: any) => ng.IPromise<boolean>};
	changeTracker;
	addedEntities;
	isNewAlert: boolean;
	ui = { addPanelToggle: 0 };

	resolve: {};

	getPermissionName: ISharePermissionNameGetter = {
		edit: (): string => 'manage_studio_alerts',
		shareView: (): string => 'manage_studio_alerts',
		shareEdit: (): string => 'manage_studio_alerts',
		defaultSharePermission: (): string => PermissionType.VIEW
	};

	uiConfig: IShareUiConfig = {
		hideShareAll: true,
		hideAllowEditorsToShare: true
	};

	getShareStatus: () => any;
	removeAdded: (entity: any) => void;

	constructor(
		private alertingApiService: AlertingApiService,
		private $q: ng.IQService,
		private cbDialogService: CBDialogService,
		private locale: ILocale) {
	}

	$onInit = () => {
		this.resolve = {
			items: [ this.alert ]
		};

		this.getShareStatus = _.noop;
		this.groupingValidator.validate = this.validateGrouping;
	}

	getAlertAccess = (): ng.IPromise<any> => {
		if (this.alert.id) {
			return this.alertingApiService.getAlertAccess(this.alert.id);
		} else {
			let ownerEntity = { entity: { userId: this.alert.ownerId }};
			return this.$q.when({ shareEntities: { USER: [ ownerEntity ], GROUP: [], META_GROUP: [] }});
		}
	}

	private getSharingList = (): any[] => {
		return this.changeTracker.updates.sharedEntities.concat(this.changeTracker.updates.changedEntities)
			.filter(item => !this.isRemovedItem(item) && item.permission !== PermissionType.OWN);
	}

	private containsWholeOrgGroup = (sharingList: any[], groupName: string) => {
		return _.some(sharingList, (item: any) => this.isWholeOrgGroup(item, groupName));
	}

	private containsNotWholeOrgGroup = (sharingList: any[], groupName: string) => {
		return _.some(sharingList, (item: any) => !this.isWholeOrgGroup(item, groupName));
	}

	validateInvite = (entityName: string) => {
		let grouping = this.alert.trigger.grouping;
		if (grouping && ReportAssetType.HIERARCHY_MODEL === grouping.type) {
			let entities = this.getSharingList();
			if (grouping.displayName === entityName) {
				return this.containsNotWholeOrgGroup(entities, entityName)
						? this.showReplaceDialog(this.locale.getString('sharing.replaceWithFullOrg'), () => this.replaceWithFullOrg(entityName))
						: true;
			} else if (this.containsWholeOrgGroup(entities, grouping.displayName)) {
				return this.showReplaceDialog(this.locale.getString('sharing.removeFullOrg'), () => this.removeWholeOrg(grouping.displayName));
			}
		}
		return true;
	}

	showReplaceDialog = (msg: string, saveCallback: () => void): ng.IPromise<boolean> => {
		return this.cbDialogService.showDialog({
			header: this.locale.getString('common.warning'),
			template: 'partials/custom/confirm-dialog-content.html',
			okBtnName: this.locale.getString('common.replace'),
			cancelBtnName: this.locale.getString('common.cancel'),
			msg,
			cancel: (dialog: ng.ui.bootstrap.IModalInstanceService) => {
				dialog.close(false);
			},
			save: (dialog: ng.ui.bootstrap.IModalInstanceService) =>  {
				saveCallback();
				dialog.close(true);
			}
		}).result;
	}

	private removeSharedItem = (item: any): void => {
		item.permission = PermissionType.REMOVE;
		item.action = ShareAction.DELETE;
		// style any children (if it's a group) with the deleted style as well
		if (item._children) {
			item._children.map((entityChild) => entityChild.action = ShareAction.DELETE);
		}
	}

	replaceWithFullOrg = (wholeOrgGroupName: string) => {

		this.changeTracker.updates.sharedEntities.forEach(item => {
			if (!this.isWholeOrgGroup(item, wholeOrgGroupName) && item.permission !== PermissionType.OWN) {
				this.removeSharedItem(item);
			}
		});
		this.addedEntities.filter((item: any) => !this.isWholeOrgGroup(item, wholeOrgGroupName))
				.forEach((entity: any) => this.removeAdded(entity));
	}

	removeWholeOrg = (wholeOrgGroupName: string) => {
		let sharedOrg = _.find(this.changeTracker.updates.sharedEntities, (item: any) =>
				this.isWholeOrgGroup(item, wholeOrgGroupName));
		if (sharedOrg) {
			this.removeSharedItem(sharedOrg);
			return;
		}
		this.addedEntities.filter((item: any) => this.isWholeOrgGroup(item, wholeOrgGroupName))
				.forEach((entity: any) => this.removeAdded(entity));
	}

	isWholeOrgGroup = (item: any, wholeOrgGroupName: string): boolean => {
		return item.type === 'group'
				&& item.entity.type === GroupType.WHOLE_HIERARCHY
				&& item.entity.groupName === wholeOrgGroupName;
	}

	isRemovedItem = (item: any): boolean => {
		return item.permission === PermissionType.REMOVE || item.action === ShareAction.DELETE;
	}

	validateGrouping = (grouping: any): ng.IPromise<boolean> => {
		if (grouping && ReportAssetType.HIERARCHY_MODEL === grouping.type) {
			let entities = this.getSharingList();
			let showDialog = this.containsNotWholeOrgGroup(entities, grouping.displayName)
				&& this.containsWholeOrgGroup(entities, grouping.displayName);
			if (showDialog) {
				return this.showKeepDialog(grouping.displayName);
			}
		}
		return this.$q.resolve(true);
	}

	private showKeepDialog = (groupName: string): ng.IPromise<boolean> => {
		return this.cbDialogService.pickOneOrCancel(
			this.locale.getString('common.warning'),
			this.locale.getString('sharing.keepUsersGroupsOrHierarchy'),
			(result) => this.removeOrKeepWholeHierarchy(groupName, result),
			undefined,
			this.locale.getString('sharing.usersAndGroups'),
			this.locale.getString('sharing.orgHierarchy')
		).result;
	}

	private removeOrKeepWholeHierarchy = (groupName: string, remove: boolean) => {
		if (remove) {
			this.removeWholeOrg(groupName);
		} else {
			this.replaceWithFullOrg(groupName);
		}
	}

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.MANAGE_STUDIO_ALERTS;
}

app.component('alertSharing', {
	bindings: {
		alert: '<',
		addedEntities: '=changedEntities',
		sharedEntities: '=',
		isNewAlert: '<',
		changeTracker: '<',
		groupingValidator: '<'
	},
	controller: AlertSharingComponent,
	templateUrl: 'partials/alerts/metric-alert-sharing.component.html'
});
