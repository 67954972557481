export class ElementResizer {
	private lastSize: string;
	constructor(
		private element: HTMLElement,
		private resizeCallback: (newSize: string, oldSize: string) => void,
	) {}

	resize(): void {
		let currentSize = this.getSize();
		this.resizeCallback(currentSize, this.lastSize);
		this.lastSize = currentSize;
	}

	private getSize(): string {
		return this.element.clientWidth + 'x' + this.element.clientHeight;
	}

}
