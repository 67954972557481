import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AssetAccessOptionsBuilder } from './asset-access-options-builder';

@Injectable({
	providedIn: 'root'
})
export class AssetAccessOptionsBuilderProvider {

	constructor(
		private locale: CxLocaleService,
	) { }

	getInstance(): AssetAccessOptionsBuilder {
		return new AssetAccessOptionsBuilder(
			this.locale
		);
	}

	
}
