import { IntervalInstance } from './interval-instance';
import * as _ from 'underscore';


export class Interval {
	intervals: IntervalInstance[] = [];

	constructor(
		private $log: ng.ILogService,
		private $interval: ng.IIntervalService) {
	}

	registerInterval = (name, func, delay, startImmediately): void => {
		let interval = this.getIntervalByName(name);

		if (interval) {
			// previous interval may look at wrong scope if called from non-singleton service
			this.stopIntervalWithName(name, true);
		}

		this.intervals.push(new IntervalInstance(name, func, delay));
		this.$log.debug('registerInterval, name=' + name + ', func=' + func + ', delay=' + delay);

		if (startImmediately) {
			this.startIntervalWithName(name);
		}
	}

	startIntervalWithName = (name): void => {
		let interval = this.getIntervalByName(name);

		if (interval !== undefined && interval.isActive === false) {
			interval.promise = this.$interval(interval.func, interval.delay);
			interval.isActive = true;

			this.$log.debug('startIntervalWithName, name=' + interval.name + ', func=' + interval.func + ', delay=' + interval.delay);
		}
	}

	stopIntervalWithName = (name: string, remove?: boolean): void => {
		let interval = this.getIntervalByName(name);

		if (interval !== undefined) {
			interval.isActive = false;

			this.$interval.cancel(interval.promise);
			this.$log.debug('stopIntervalWithName, name=' + interval.name + ', func=' + interval.func + ', delay=' + interval.delay);

			if (remove)
				this.intervals.remove(interval);
		}
	}

	startAllIntervals = (): void => {
		this.$log.debug('startAllIntervals invoked');

		_.each(this.intervals, (interval) => {
			this.startIntervalWithName(interval.name);
		});
	}

	stopAllIntervals = (): void => {
		this.$log.debug('stopAllIntervals invoked');

		_.each(this.intervals, (interval) => {
			this.stopIntervalWithName(interval.name);
		});
	}

	private getIntervalByName(intervalName): IntervalInstance {
		return _.findWhere(this.intervals, {name: intervalName});
	}
}

/**
 * Service for registering intervals
 */
app.service('interval', Interval);
