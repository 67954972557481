import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { DependentMetric } from '@cxstudio/asset-management/dependencies/dependent-metric';
import { PagedResponse } from '@cxstudio/paged-response';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { DependentDashboard } from '@cxstudio/asset-management/dependencies/dependent-dashboard';

import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { AbstractDependenciesApiService } from '@app/modules/asset-management/services/abstract-dependencies-api-service';


@Injectable()
export class ModelDependenciesApiService extends AbstractDependenciesApiService<ProjectAsset> {

	constructor(
		cxHttp: CxHttpService,
	) {
		super(cxHttp);
	}

	getDashboards = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<DependentDashboard>> => {
		return this.getDependencies(asset, 'dashboards', pagination);
	}

	getFilters = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<AssetDependency>> => {
		return this.getDependencies(asset, 'filters', pagination);
	}

	getMetrics = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<DependentMetric>> => {
		return this.getDependencies(asset, 'metrics', pagination);
	}

	protected getBaseUrl = (asset: ProjectAsset): string => {
		return 'rest/cp/' + asset.contentProviderId
			+ '/account/' + asset.accountId
			+ '/project/' + asset.projectId
			+ '/model/' + asset.assetId
			+ '/dependencies';
	}
}
