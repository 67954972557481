import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import * as _ from 'underscore';
import { Book, IBookTab } from './../entity/book';
import { Dashboard } from './../entity/dashboard';


export class BookValidationService {
	constructor() {}

	private isBlank(name: string): boolean {
		return !name || name.trim().length === 0;
	}

	bookNameInvalid(book: Book): boolean {
		return !book || this.isBlank(book.name);
	}

	bookNameExists(book: Book, dashboards: Dashboard[]): boolean {
		let sameNameBook = _.find(dashboards, dashboard => {
			return dashboard.id !== book.id
				&& dashboard.name === book.name
				&& dashboard.type === DashboardType.BOOK;
		});
		return !!sameNameBook;
	}

	tabNameInvalid(tab: IBookTab): boolean {
		return !tab || this.isBlank(tab.name);
	}

	tabEmpty(tab: IBookTab): boolean {
		return !tab.properties;
	}

	hasInvalidTabs(book: Book): boolean {
		return !!_.find(book.tabs, tab => {
			return this.tabNameInvalid(tab) || this.tabEmpty(tab);
		});
	}

	hasValidationWarnings(book: Book, dashboards: Dashboard[]): boolean {
		return this.bookNameInvalid(book)
		|| this.bookNameExists(book, dashboards)
		|| this.hasInvalidTabs(book);
	}
}

app.service('bookValidationService', BookValidationService);

