import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';
import { PermissionGroup } from '@app/modules/user-administration/permissions/permission-group';
import { Security } from '@cxstudio/auth/security-service';
import { PermissionAction } from '@app/modules/user-administration/permissions/permission-action';
import { PermissionApplication } from '@app/modules/user-administration/permissions/permission-application';
import { CxLocaleService } from '@app/core/cx-locale.service';

interface IPermissionDependency {
	action: string;
	dependsOn: string[];
}

@Injectable()
export class PermissionsService {

	constructor(@Inject('security') private security: Security,
		private locale: CxLocaleService) {
	}

	private exceptions = [
		{ action: PermissionAction.CREATE_FILTER, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.SHARE_FILTER, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.SHARE_EDIT_FILTER, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.MANAGE_DATE_FILTERS, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.CREATE_METRIC, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.SHARE_METRIC, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.SHARE_EDIT_METRIC, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.MANAGE_ALERT_TEMPLATES, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.MANAGE_DRIVERS, group: PermissionGroup.DASHBOARD, uiGroup: PermissionGroup.OBJECT },
		{ action: PermissionAction.SHARE_CREATE_USER, group: PermissionGroup.MASTER_ACCOUNT, uiGroup: PermissionGroup.USER }
	];
	private dependencies: IPermissionDependency[] = [
	];

	getGroups = (): PermissionGroup[] => {
		return Object.values(PermissionGroup);
	}

	getPermissionGroup = (permission: MasterAccountPermission): PermissionGroup => {
		if (!this.security.isEngagorIntegrationEnabled() && permission.action === PermissionAction.MANUAL_CASE_CREATION) {
			return PermissionGroup.INTERACTION;
		}
		let exception = _.findWhere(this.exceptions, {action: permission.action as PermissionAction});
		return exception ? exception.uiGroup : permission.group;
	}

	getDependants = (permission: MasterAccountPermission): string[] => {
		return _.chain(this.dependencies)
			.filter(item => _.contains(item.dependsOn, permission.action))
			.pluck('action')
			.value();
	}

	getDependensOn = (permission: MasterAccountPermission): string[] => {
		let dependentItem = _.findWhere(this.dependencies, {action: permission.action});
		return dependentItem ? dependentItem.dependsOn : [];
	}

	filterProfanityPermission = (permissions: MasterAccountPermission[]): MasterAccountPermission[] => {
		return _.filter(permissions, (perm) => perm.action !== PermissionAction.VIEW_PROFANITY);
	}

	getPermissionText = (permission: MasterAccountPermission): string => {
		if (!this.security.isEngagorIntegrationEnabled() && permission.action === PermissionAction.MANUAL_CASE_CREATION) {
			return this.locale.getString('administration.manualTicketCreation');
		}

		if (permission.application === PermissionApplication.CX_STUDIO) {
			return this.locale.getString('administration.' + permission.action);
		}
		return permission.label;
	}
}

app.service('permissionsService', downgradeInjectable(PermissionsService));
