import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { TuningSuggestionsService } from '@app/modules/document-explorer/tuning-suggestions/tuning-suggestions.service';
import AlertSubscriptionTemplatesApi from '@cxstudio/alert-subscription-templates/alert-subscription-templates-api.service';
import { AlertSubscriptionTemplateType } from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';


export class PreviewHelper {

	constructor(
		private alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		private tuningSuggestionsService: TuningSuggestionsService,
		private $q: ng.IQService,
	) {}

	isAnalyticsPreview = (typeString: string): boolean => {
		return typeString && (typeString.toUpperCase() === 'CB_AN_PREVIEW');
	}

	isPaginated = (widget: Widget): boolean => {
		return !_.isUndefined(widget.properties) && this.isAnalyticsPreview(widget.properties.widgetType);
	}

	isDocumentPreview = (widget: Widget): boolean => {
		return !_.isUndefined(widget.properties) &&
			this.isAnalyticsPreview(widget.properties.widgetType) &&
			(widget.properties.previewMode === 'document');
	}

	getTuningSuggestionsAvailability = (widget: PreviewWidget): ng.IPromise<boolean> => {
		if (this.tuningSuggestionsService.isAvailable()) {
			const project = ProjectIdentifier.fromWidgetProperties(widget.properties);
			const assetParameters = AssetParametersFactory.fromWidget(widget);
			return this.alertSubscriptionTemplatesApi.getEnabledTemplates(
				project,
				widget.properties.workspaceProject,
				assetParameters).then((templates) => {
					return _.some(templates, (template) => {
						return template.type === AlertSubscriptionTemplateType.PRODUCT_FEEDBACK;
					});
			});
		} else return this.$q.when(false);
	}

}

app.service('previewHelper', PreviewHelper);
