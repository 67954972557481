import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { IContextMenuActions } from '@app/modules/reports/utils/context-menu/drill/widget-drill-actions.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { DefaultMetricConfig } from '@cxstudio/reports/providers/cb/constants/default-metric-config';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { AttributeDrillService } from '@cxstudio/reports/utils/contextMenu/drill/drill-options/attribute-drill.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject, Injectable } from '@angular/core';
import { DrillHelperService} from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { CxLocaleService } from '@app/core';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { ObjectUtils } from '@app/util/object-utils';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';

@Injectable({
	providedIn: 'root'
})
export class WordsDrillService {

	constants = this.metricConstants.get();

	constructor(
		private readonly locale: CxLocaleService,
		private readonly drillHelperService: DrillHelperService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('attributeDrill') private readonly attributeDrill: AttributeDrillService
	) {}

	private addGroupBy(point, wordType): void {
		let groupBy: any = ObjectUtils.copy(DefaultMetricConfig);
		_.extend(groupBy, wordType);
		groupBy.metricType = AnalyticMetricType.CLARABRIDGE;
		point.groupBy = groupBy;
	}

	private getWordTypes(widget: Widget, menuActions: IContextMenuActions, defaultColor: IColorSelectorPalette,
		hideAllWords: boolean = false): IDrillMenuOption[] {
		let opts = [];

		if (!hideAllWords) {
			opts.push(this.constants.WORDS);
		}

		opts.push(this.constants.HASHTAG);

		let typeNames = ['all', 'hashtag']; // for e2e
		let drillToHelper = this.drillHelperService.create( { widget, menuActions, defaultColor } as IDrillParams);
		return _.map(opts, (wordType, i) => {
			return {
				text: wordType.displayName,
				items: drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, WidgetType.CLOUD),
				name: `words-${typeNames[i]}`,
				selected: i === 0,
				obj: wordType,
				func: this.addGroupBy
			} as any;
		});
	}

	getWordsOptions(widget: Widget, menuActions: IContextMenuActions, defaultColor: IColorSelectorPalette): IDrillMenuOption {
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 40, // to have space for other options
			text: this.locale.getString('widget.words'),
			items: this.getWordTypes(widget, menuActions, defaultColor),
			name: 'words'
		};
	}

	getLCOptions(widget: Widget, menuActions: IContextMenuActions, defaultColor: IColorSelectorPalette): IDrillMenuOption {
		let drillToHelper = this.drillHelperService.create( {widget, menuActions, defaultColor} as IDrillParams);
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 50, // to have space for other options
			text: this.locale.getString('widget.assocWords'),
			items: drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, WidgetType.CLOUD) as any,
			name: DrillType.LC,
			obj: this.constants.LC,
			func: this.addGroupBy
		};
	}

	getNLPOptions(widget: Widget, menuActions: IContextMenuActions, attributes, wordAttributes,
		defaultColor: IColorSelectorPalette): IDrillMenuOption {
		let hideAllWords = this.isHideOption(wordAttributes, this.constants.WORDS);
		let hideAssociatedWords = this.isHideOption(wordAttributes, this.constants.LC);

		let allWords = this.getWordTypes(widget, menuActions, defaultColor, hideAllWords);
		let lc = this.getLCOptions(widget, menuActions, defaultColor);
		let nlpOptions = this.attributeDrill.getOptions(widget, menuActions, attributes, defaultColor).items;

		let options: Array<IDrillMenuOption[] | IDrillMenuOption> = [allWords];

		if (!hideAssociatedWords) {
			options.push([lc]);
		}

		options.push(nlpOptions as IDrillMenuOption[]);

		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 40,
			text: this.locale.getString('widget.words'),
			items: _.union.apply(_, options),
			levels: 2,
			name: 'words'
		};
	}

	private isHideOption(attributes, option): boolean {
		let selectedOption = _.findWhere(attributes, {name: option.name});
		return !(selectedOption === undefined || selectedOption === null)
			? selectedOption.hide
			: true;
	}

}

app.service('wordsDrillService', downgradeInjectable(WordsDrillService));

