import * as _ from 'underscore';
import ConversionWidgetProperty from './conversion-widget-property.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import Widget from '@cxstudio/dashboards/widgets/widget';

const MAP_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('visualization'), new ConversionWidgetProperty('mapBackground'),
	new ConversionWidgetProperty('showLabels'), new ConversionWidgetProperty('showSampleSize')
];

export class MapWidget extends SimpleReportWidget {

	constructor(private metricContsants: MetricConstants) {
		super(WidgetType.MAP);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initSimpleReport(context);
		super.initProperties(MAP_VISUAL_PROPERTIES, context, 'visualProperties');

		super.initGroupings(context, 1, true);
		super.initSingleCalculation(context, this.metricContsants.get().VOLUME);
	}
}
