import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { UIOption } from '@clarabridge/unified-angular-components';
import { Color } from '@iplab/ngx-color-picker';

type ColorType = 'hex' | 'rgba';

@Component({
	selector: 'cb-color-sketch',
	templateUrl: './cb-color-sketch.component.html',
	styles: [`
		::ng-deep cb-color-sketch .column {
			padding: 0 !important;
			display: flex;
			width: 0 !important;
			flex: 2;
		}

		::ng-deep cb-color-sketch .column + .column { margin-left: -1px }

		::ng-deep cb-color-sketch hue-component .pointer {
			height: 14px !important;
			width: 10px !important;
			margin: 0 0 0 -5px !important;
			border: 1px solid #445460 !important;
		}

		::ng-deep hex-input-component input, ::ng-deep rgba-input-component input {
			margin-bottom: 0 !important;
		}

		::ng-deep cb-color-sketch cb-push-buttons button { width: 46px; }
	`], // width: 0 to disable default sizing (which takes ~200px), flex: 2 to fill all available space
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CbColorSketchComponent implements OnInit, OnChanges {
	@Input() valueColor: Color;
	hueColor: Color;
	@Output() valueColorChange = new EventEmitter<Color>();
	@Output() hueColorChange = new EventEmitter<Color>();

	colorType: ColorType = 'hex';
	colorOptions: UIOption<string>[] = [{displayName: 'HEX', value: 'hex'}, {displayName: 'RGB', value: 'rgba'}];

	ngOnInit(): void {
		this.hueColor = this.getHueColor(this.valueColor);
	}

	ngOnChanges(changes: SimpleChanges<CbColorSketchComponent>): void {
		if (ChangeUtils.hasChange(changes.valueColor)) {
			this.hueColor = this.getHueColor(this.valueColor);
		}
	}

	private getHueColor(color: Color): Color {
		return new Color().setHsva(color.getHsva().hue);
	}
}
