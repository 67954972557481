import { Inject, Injectable } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricEditorUtilsService } from '@app/modules/metric/editor/metric-editor-utils.service';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { PromiseUtils } from '@app/util/promise-utils';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { FilterUpdateService } from '@cxstudio/report-filters/filter-update-service.service';

@Injectable({
	providedIn: 'root'
})
export class AssetEditDialogService {
	constructor(
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly metricEditorUtils: MetricEditorUtilsService,
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
		@Inject('filterManagementApiService') private filterManagementApiService: FilterManagementApiService,
		@Inject('filterUpdateService') private filterUpdateService: FilterUpdateService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
	) {}

	openEditMetricDialog(metricId: number): Promise<any> {
		return PromiseUtils.wrap(this.metricApiService.getMetric(metricId, false)).then(response => {
			let metric = response.data as Metric;
			let project = this.getMetricProject(metric);
			return this.metricEditorUtils.openMetricModal(project, metric, MetricMode.EDIT).then((dialogOutput) => {
				let updatedMetric = $.extend(dialogOutput.result, {
					contentProviderId: metric.contentProviderId,
					accountId: metric.accountId,
					projectId: metric.projectId,
					projectName: metric.projectName,
					type: 'metricStudio'
				});
				return PromiseUtils.wrap(this.metricApiService.updateMetric(updatedMetric))
					.then(() => this.globalNotificationService.addItemSavedNotification(updatedMetric.displayName));
			}, _.noop);
		});
	}

	openEditFilterDialog(filterId: number): Promise<any> {
		return PromiseUtils.wrap(this.filterManagementApiService.getFilter(filterId)).then(filter => {
			let filterBuildingData = {
				viewMode: false,
				canShare: false
			};

			return PromiseUtils.wrap(this.filterUpdateService.edit(filter, true, filterBuildingData)).then(updatedResult => {
				let updatedFilter: IFilter = updatedResult.items as IFilter;
				this.globalNotificationService.addItemSavedNotification(updatedFilter.name);
			}, _.noop);
		});
	}

	openEditDriverDialog(driverId: number): Promise<any> {
		// We need to convert the driver edit dialog to the new angular
		return Promise.resolve();
	}

	private getMetricProject(metric: Metric): AccountOrWorkspaceProjectData {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return _.extend({}, metric.workspaceProject, metric.projectName) ;
		} else {
			return {
				contentProviderId: metric.contentProviderId,
				accountId: metric.accountId,
				projectId: metric.projectId,
				projectName: metric.projectName
			};
		}
	}
}
