import { downgradeComponent } from '@angular/upgrade/static';
import { DragHandleComponent } from '@clarabridge/unified-angular-components';
import { Component } from '@angular/core';

// component declaration and class required to make this thing build
@Component({
	selector: 'downgrade-drag-handle', // this should never be used anywhere, but we have to put something
	template: '<div></div>'
})
export class DragHandleDowngradeComponent {}

app.directive('cbDragHandle', downgradeComponent({component: DragHandleComponent}) as angular.IDirectiveFactory);