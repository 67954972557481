import { GaugeColorMode } from '@cxstudio/reports/entities/gauge-color-mode.enum';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

export class MetricWidgetVisualProperties extends VisualProperties {
	selectedMetrics: ReportCalculation[];
	previousPeriod: boolean;
	trendArrow: boolean;
	showTotal: boolean;
	colorMode: GaugeColorMode;

	constructor(previousPeriod: boolean = false, trendArrow: boolean = false, showTotal: boolean = true) {
		super();
		this.previousPeriod = previousPeriod;
		this.trendArrow = trendArrow;
		this.showTotal = showTotal;
		this.selectedMetrics = [];
	}

	extend(properties: MetricWidgetVisualProperties) {
		this.previousPeriod = properties.previousPeriod;
		this.trendArrow = properties.trendArrow;
		this.showTotal = properties.showTotal;
	}
}
