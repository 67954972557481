import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

export class TemplateWidgetUtils {
	private static readonly PREFILLED_CONFIG: {[key in WidgetType]?: string[]} = {
		video: ['videoUrl'],
		label: ['text'],
		text: ['text'],
		image: ['imageUrl', 'imageName'],
		page_break: [''],
		qualtrics: [''],
	};

	static isDisplayedAsTemplate = (widget: Widget): boolean => {
		return widget.template && !TemplateWidgetUtils.isPrefilledWidget(widget);
	}

	static isPrefilledWidget = (widget: Widget): boolean => {
		if (!widget.visualProperties || !widget.properties) {
			return false;
		}

		let fields = TemplateWidgetUtils.PREFILLED_CONFIG[widget.properties.widgetType] || [];
		return _.some(fields, (field: any) => {
			return field !== ''
				? !!widget.properties[field] && widget.properties[field].length > 0
				: true;
		});
	}
}
