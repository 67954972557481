<simple-dialog
	[header]="'topicConversions.topicTranslationConfirmationHeader' | i18n"
	[value]="true"
	[okBtnName]="'common.confirm' | i18n"
	[cancelBtnName]="'common.cancel' | i18n"
>
	<p class="mb-16">
		{{ 'topicConversions.topicTranslationConfirmationDescription' | i18n:{ masterAccountName: masterAccountName } }}
	</p>
	<div class="topic-translation__summary">
		<section class="break-word">
			<h2>{{ 'common.uploadedFile' | i18n }}</h2>
			<ul class="pl-0">
				<li>{{ uploadedTopicModelFile }}</li>
			</ul>
		</section>
		<section class="d-flex align-items-center justify-center">
			<icon name="arrow-right"></icon>
		</section>
		<section class="break-word">
			<h2>{{ 'topicConversions.convertedFiles' | i18n}}</h2>
			<ul class="pl-0">
				<li *ngFor="let convertedTopicModelFile of convertedTopicModelFiles">{{ convertedTopicModelFile }}</li>
			</ul>
		</section>
	</div>
</simple-dialog>
