import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';

export type ModalInput = {
	uploadedTopicModelFile: string;
	convertedTopicModelFiles: string[];
};

@Component({
	selector: 'topic-translation-confirmation-dialog',
	templateUrl: './topic-translation-confirmation-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		.topic-translation__summary {
			display: grid;
			grid-template-columns: 1fr 0.5fr 1fr;
		}
	`]
})
export class TopicTranslationConfirmationDialogComponent implements OnInit {
	@Input() input: ModalInput;

	public masterAccountName: string;
	public uploadedTopicModelFile: string;
	public convertedTopicModelFiles: string[];

	constructor(@Inject('security') private readonly security: Security) {}

	ngOnInit(): void {
		this.masterAccountName = this.security.getCurrentMasterAccount().accountName;
		this.uploadedTopicModelFile = this.input?.uploadedTopicModelFile;
		this.convertedTopicModelFiles = this.input?.convertedTopicModelFiles;
	}
}
