<modal-header
	(cancel)="cancel()"
	[modalTitle]="input.titleLabel">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<div class="form-horizontal ph-16">
		<template-properties *ngIf="input.showProperties && !!template"
			[template]="template"
			[folders]="folders"
		></template-properties>
		<!-- Placeholders -->
		<div *ngIf="input.showMappings && !!placeholders">
			<hr style="margin-left: -32px; margin-right: -32px" class="border-gray-500">
			<template-mappings [placeholders]="placeholders"></template-mappings>
		</div>

		<!-- Warning -->
		<ng-container *ngIf="input.showMappings">
			<alert [dismissable]="false" type='info' class="mt-32" text="{{'templates.metricTemplateReplacementWarning'|i18n}}">
			</alert>

		</ng-container>
	</div>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'administration.save' | i18n"
	[isDisabled]="!isValid()">
</save-modal-footer>
