import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConnectorsStatusService } from '@app/modules/system-administration/status/integration-status/connectors/connectors-status.service';

@Component({
	selector: 'connectors-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectorsStatusTabComponent {

	constructor(
		public service: ConnectorsStatusService,
	) {}
}
