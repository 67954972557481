<search-list [dropdown]="true"
	class="d-flex"
	displayField="displayName"
	[nonclickable]="nonclickable"
	[matchFunction]="matchAssignee"
	[data]="assignee"
	[listOptions]="options"
	[nodeTemplate]="compileNodeTemplate"
	(onNodeSelection)="onSelectAssignee.emit({assignee: $event.node.item})"
	(onClearSelection)="onClearAssignee.emit()"
	selectPrompt="{{'common.unassigned'|i18n}}"
	allowClear="true">
</search-list>