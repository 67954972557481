import { Security } from '@cxstudio/auth/security-service';
import * as moment from 'moment';
import { DateFormat } from '@cxstudio/header/date-format';
import ILocale from '@cxstudio/interfaces/locale-interface';

export type StudioDateFormatSpecification = string;

export enum DateTimeFormat {
	BASIC_TIME = 'basicTime',
	BASIC_DATE = 'basicDate',
	BASIC_DATE_TIME = 'basicDateTime',
	DEFAULT_DATE = 'defaultDate',
	TREND_DATE_LONG = 'trendDateLong',
	TREND_DATE = 'trendDate',
	DASH_DATE = 'dashDate',
}

export interface ILocalDateFormat {
	id: DateFormat;
	name: string;
	format: { [key in DateTimeFormat]: StudioDateFormatSpecification};
	momentFormat: {
		basicDate: moment.MomentFormatSpecification;
		basicDateTime: moment.MomentFormatSpecification;
	};
}

export class DateService {
	constructor(private $filter: ng.IFilterService, private security: Security, private locale: ILocale) {
	}

	getDateFormats = (): ILocalDateFormat[] => {
		return 	[{
			id: DateFormat.US,
			name: 'US (MM/DD/YYYY)',
			format:	{
				basicTime: 'h:mm a',
				basicDate: 'MM/dd/yyyy',
				basicDateTime: 'MM/dd/yyyy h:mm a',
				defaultDate: 'MMM dd yyyy',
				trendDateLong: 'MMM-dd-yyyy H:mm',
				trendDate: 'MMM-dd-yyyy',
				dashDate: 'MM-dd-yyyy'
			},
			momentFormat: {
				basicDate: 'MM/DD/YYYY',
				basicDateTime: 'MM/DD/YYYY h:mm a'
			}
		}, {
			id: DateFormat.UK,
			name: 'UK (DD/MM/YYYY)',
			format:	{
				basicTime: 'H:mm',
				basicDate: 'dd/MM/yyyy',
				basicDateTime: 'dd/MM/yyyy H:mm',
				defaultDate: 'dd MMM yyyy',
				trendDateLong: 'dd-MMM-yyyy H:mm',
				trendDate: 'dd-MMM-yyyy',
				dashDate: 'dd-MM-yyyy'
			},
			momentFormat: {
				basicDate: 'DD/MM/YYYY',
				basicDateTime: 'DD/MM/YYYY H:mm'
			}
		}];
	}

	getUserDateFormat = (requiredFormat: DateTimeFormat = DateTimeFormat.BASIC_DATE_TIME): StudioDateFormatSpecification => {
		let dateFormat = this.security.loggedUser.dateFormat;
		let dateFormats = this.getDateFormats();
		let formats = dateFormat ? _.find(dateFormats, {id: dateFormat}) : dateFormats[0];

		return formats.format[requiredFormat];
	}

	getUserMomentDateFormat = (requiredFormat: DateTimeFormat = DateTimeFormat.BASIC_DATE): moment.MomentFormatSpecification => {
		let dateFormat = this.security.loggedUser.dateFormat;
		let dateFormats = this.getDateFormats();
		let formats = dateFormat ? _.find(dateFormats, {id: dateFormat}) : dateFormats[0];

		return formats.momentFormat[requiredFormat];
	}

	getTimezoneString = (offset): string => {
		if (!offset)
			return '';
		let abs = Math.abs(offset);
		let hours = this.pad(Math.floor(abs / 60), 2);
		let minutes = this.pad(abs % 60, 2);
		let sign = offset > 0 ? '+' : '-';
		return `${sign + hours}:${minutes}`;
	}

	pad = (num, size: number): string => {
		let s = `${num}`;
		while (s.length < size) s = `0${s}`;
		return s;
	}

	addColonToTimezone = (dateStr: string): string => {
		let indexOfColon = dateStr.indexOf('UTC') + 6;
		return `${dateStr.slice(0, indexOfColon)}:${dateStr.slice(indexOfColon)}`;
	}

	formatWithTimezone = (date: Date): string => {
		let dateFilter = this.$filter('date');
		let pattern = `${this.getUserDateFormat()} (UTCZ)`;
		return this.addColonToTimezone(dateFilter(date, pattern));
	}

	format = (date, requiredFormat?: DateTimeFormat): string => {
		let dateFilter = this.$filter('date');
		let pattern = this.getUserDateFormat(requiredFormat);
		return dateFilter(date, pattern);
	}

	getElapsedTime = (seconds: number = 0): string => {
		const SECONDS_IN_MINUTE = 60;
		const SECONDS_IN_HOUR = (SECONDS_IN_MINUTE * 60);
		const SECONDS_IN_DAY = (SECONDS_IN_HOUR * 24);

		if (seconds < 60) return `<1${this.locale.getString('common.minuteAbbreviation')}`;

		let days = Math.floor(seconds / SECONDS_IN_DAY);
		let dayString = days ? `${days}${this.locale.getString('common.dayAbbreviation')} ` : '';
		let remainder = seconds % SECONDS_IN_DAY;

		let hours = Math.floor(remainder / SECONDS_IN_HOUR);
		let hourString = hours ? `${hours}${this.locale.getString('common.hourAbbreviation')} ` : '';
		remainder = remainder % SECONDS_IN_HOUR;

		let minutes = Math.floor(remainder / SECONDS_IN_MINUTE);
		let minuteString = minutes ? `${minutes}${this.locale.getString('common.minuteAbbreviation')} ` : '';
		remainder = remainder % SECONDS_IN_MINUTE;


		return `${dayString}${hourString}${minuteString}`.trim();
	}

	// converts seconds count to mm:ss
	// ex. 100.5 -> 1:40, 601 -> 10:01
	secondsToTime = (seconds: number): string => {
		seconds = Math.floor(seconds); // full seconds only
		let minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;

		let secondsStr = `00${seconds}`;

		return `${minutes}:${secondsStr.right(2)}`;
	}
}


app.service('dateService', DateService);
