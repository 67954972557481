import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ListColumnFilter } from '@app/shared/components/filter/list-column-filter';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { ListOption } from '@app/shared/components/forms/list-option';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'external-application-filter',
	template: `
		<object-list-filter
			[maxRules]="1"
			[columns]="columns"
			(apply)="applyFilters($event)"
		></object-list-filter>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalApplicationFilterComponent implements OnInit {

	@Output() apply = new EventEmitter<ObjectListFilter[]>();

	columns: ListColumnFilter[];

	constructor(
		private locale: CxLocaleService,
	) {}

	ngOnInit(): void {
		const values = Object.keys(ExternalApplicationType);
		let options = _.map(values, (value) => {
			return { name: value, value };
		});
		let type: ListColumnFilter = {
			name: this.locale.getString('common.type'),
			value: 'type',
			columnFilterType: ColumnFilterType.LIST,
			options
		};
		this.columns = [type];
	}

	applyFilters = (filters: ObjectListFilter[]): void => {
		this.apply.emit(filters || []);
	}

}

app.directive('externalApplicationFilter',
	downgradeComponent({component: ExternalApplicationFilterComponent}) as angular.IDirectiveFactory);