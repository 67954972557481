import { ChangeDetectionStrategy, Component, Input, HostBinding, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as uuid from 'uuid';

@Component({
	selector: 'menu-subsection',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<section attr.aria-labelledby="{{id}}" role="group">
	<span id="{{id}}" class="font-normal dropdown-header">{{name}}</span>
	<ng-content></ng-content>
</section>`
})
export class MenuSubsectionComponent implements OnInit {
	@Input() name: string;
	id;

	@HostBinding('role') role = 'menu';

	ngOnInit(): void {
		this.id = uuid.v4();
	}
}

app.directive('menuSubsection', downgradeComponent({component: MenuSubsectionComponent}));
