import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { MenuItemDef, RowNode } from 'ag-grid-community';
import { AgGridCommonActionsService } from '../actions/ag-grid-common-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';

@Injectable({
	providedIn: 'root'
})
export class CommonOptionsProviderService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly agGridCommonActionsService: AgGridCommonActionsService,
		private readonly dashboardGridHelperService: DashboardGridHelperService,
	) {}

	getDashboardViewOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('common.view'),
			action: () => this.agGridCommonActionsService.view(dashboard)
		};
	}

	getDashboardShareOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.share'),
			action: () => this.agGridCommonActionsService.share(node)
		};
	}

	getDashboardCopyOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.copy'),
			action: () => this.agGridCommonActionsService.copy(dashboard)
		};
	}

	getDashboardFavoriteOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('common.toggleFavorite'),
			action: () => this.agGridCommonActionsService.toggleFavorite(node)
		};
	}

	getDashboardRemoveOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.dashboardGridHelperService.getDeletionText(dashboard),
			action: () => this.agGridCommonActionsService.remove(dashboard)
		};
	}

	geTransferOption(dashboards: Dashboard[]): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.transferOwnerWidgetConfirmOption'),
			action: () => this.agGridCommonActionsService.transfer(dashboards)
		};
	}
}
