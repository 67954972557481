import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';
import {RedirectService} from '@cxstudio/services/redirect-service';
import {EnvironmentService} from '@cxstudio/services/environment-service';
import {ErrorCode} from '@app/modules/error-page/error-page/error-code';
import {CxLocationService} from '@app/core/cx-location.service';
import {UrlService} from '@cxstudio/common/url-service.service';

@Component({
	selector: 'error-page',
	templateUrl: './error-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent implements OnInit {
	// makes the ErrorCode enum available in the template
	ErrorCode = ErrorCode;
	errorCode: ErrorCode;
	requestedPathLink: string;
	showRedirectToHome: boolean = false;

	private redirectTo: string = '';
	providerName: string;
	externalLoginUrl: string;

	constructor(
		private readonly cxLocation: CxLocationService,
		@Inject('redirectService') private readonly redirectService: RedirectService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject(Window) private readonly window: Window,
	) {
	}

	ngOnInit(): void {
		this.errorCode = this.cxLocation.search().code as ErrorCode;
		this.showRedirectToHome = !this.environmentService.isIframe();


		if (this.errorCode === ErrorCode.REFRESH_REQUIRED) {
			return;
		}

		if (this.errorCode === ErrorCode.NOT_LINKED || this.errorCode === ErrorCode.ENGAGE_REDIRECT) {
			this.setupNotLinked();
			return;
		}

		if (this.errorCode === ErrorCode.CANNOT_EMBED) {
			this.setupCannotEmbed();
			return;
		}

		this.errorCode = ErrorCode.DEFAULT;
	}

	loginPopup(): void {
		this.environmentService.openStudioLoginPopupWithRedirect('/dashboard/#/login', () => {
			this.window.location.href = this.redirectTo;
		});
	}

	close(): void {
		this.redirectToHome();
	}

	private redirectToHome(): void {
		this.redirectService.goToDashboardList();
	}

	private setupNotLinked(): void {
		this.redirectTo = this.cxLocation.search().redirectTo;
		this.providerName = this.cxLocation.search().provider?.capitalize();
		this.externalLoginUrl = this.cxLocation.search().providerUrl;
	}

	private setupCannotEmbed(): void {
		const requestedPath = this.cxLocation.search().requestedPath;
		this.requestedPathLink = this.urlService.getRequestedPathLink(requestedPath);
	}
}

app.directive('errorPage', downgradeComponent({component: ErrorPageComponent}) as angular.IDirectiveFactory);
