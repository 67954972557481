import { ChangeDetectionStrategy, Component, Inject, ChangeDetectorRef } from '@angular/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { HierarchyEnrichmentAttributeSelectorComponent } from './attributes-selector/hierarchy-enrichment-attribute-selector.component';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { HierarchyUploadWizardPageBaseComponent } from '../hierarchy-upload-wizard-base.component';
import { WizardTagMode } from '@app/modules/wizard/cx-wizard-tag/cx-wizard-tag.component';
import { FileUploadResult } from '../../upload/upload-result/file-upload-result';
import { HierarchyComponentInput } from '../hierarchy-component-input';
import { CxLocaleService } from '@app/core';


@Component({
	selector: 'hierarchy-enrichment-wizard-page',
	templateUrl: './hierarchy-enrichment-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyEnrichmentWizardPageComponent extends HierarchyUploadWizardPageBaseComponent {

	private hasChanges: boolean = false;

	constructor(
		ref: ChangeDetectorRef,
		protected locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private organizationApiService: OrganizationApiService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('urlService') private urlService: UrlService,
	) { 
		super(ref, locale);
	}

	getFileName(): string {
		return this.hierarchy.enrichmentFileName;
	}
	
	getUpdateFileName(): string {
		return this.hierarchy.updateEnrichmentFileName;
	}

	downloadDataFile(): void {
		this.loading = this.organizationApiService.getOrganizationEnrichment(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	downloadTemplateFile(): void {
		this.loading = this.organizationApiService.getOrganizationEnrichmentTemplate(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}


	getFileUploadUrl(): string {
		return this.urlService.getAPIUrl(
			`rest/organization/enrichment/file/upload/${this.hierarchy.id}`);
	}

	editReportableParameters(): void {
		const input: HierarchyComponentInput = {
			hierarchy: this.hierarchy
		};

		this.cxDialogService.openDialog(HierarchyEnrichmentAttributeSelectorComponent, input);
	}

	getStepStatus(): WizardTagMode {
		// this data is optional, so do not need to display UPDATE_REQUIRED
		if (!this.hierarchy) {
			return;
		}
		
		if (this.firstTimeHierarchyEdit()) {
			return this.hasChanges? WizardTagMode.COMPLETED : WizardTagMode.NO_CHANGES;
		}

		if (!this.hierarchy.hasUpdate) {
			if (this.getFileName()) {
				return WizardTagMode.COMPLETED;
			}
		} else {
			if (this.getUpdateFileName()) {
				return WizardTagMode.COMPLETED;
			}
		}
	}

	handleSuccessfulUpload(event: any): void {
		// updating enrichemnt does not create an update (pending changes)
		// need to set "completed" tag separately
		this.hasChanges = true;
		this.uploadResult = FileUploadResult.empty();
		this.hierarchyUpdated.emit();
		this.tagChange.emit(WizardTagMode.COMPLETED);
		this.ref.detectChanges();
	}

}

