<modal-header (cancel)="cancel()" modalTitle="{{'common.selectProject'|i18n}}">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<div class="d-flex flex-direction-column">
		<ng-container *ngIf="!isWorkspaceEnabled">
			<div *ngIf="contentProviders.length > 1" class="mb-16">
				<label for="reportCP" class="control-label font-bold">{{'common.contentProvider'|i18n}}</label>
				<div>
					<select [(ngModel)]="props.contentProviderId" (change)="onSelectProvider()" id="reportCP">
						<option *ngIf="!isContentProviderSelected()" selected disabled [ngValue]="null">
							{{'common.selectProvider'|i18n}}
						</option>
						<option *ngFor="let contentProvider of contentProviders" [ngValue]="contentProvider.id">
							{{contentProvider.name}}</option>

					</select>
				</div>
			</div>

			<div *ngIf="!shouldHideAccountSelection()" class="mb-16">
				<label for="reportAccount" class="control-label font-bold">{{'common.account'|i18n}}</label>
				<select [(ngModel)]='props.accountId' (change)="onSelectAccount()" id="reportAccount">
					<option *ngIf='!isAccountSelected()' selected disabled [ngValue]="null">{{'common.selectAccount'|i18n}}
					</option>
					<option *ngFor="let account of accounts" [ngValue]="account.accountId">{{account.accountName}}</option>
				</select>
			</div>
			<ng-container *ngIf="hasNoAccounts()">
				<alert type="danger" text="{{'metrics.noAccounts'|i18n}}"></alert>
			</ng-container>
		</ng-container>

		<div *ngIf="isWorkspaceEnabled && workspaces?.length > 1" class="mb-16">
			<label class="control-label font-bold help-label">{{'common.workspace'|i18n}}
				<cb-inline-help right>
					<help-body>{{'dashboard.providerHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<div class="col-sm-6 pl-0">
				<simple-dropdown
					[options]="workspaces"
					[(value)]="selectedWorkspaceId"
					(valueChange)="switchWorkspace($event)"
					displayField="name"
					valueField="id"
				></simple-dropdown>
			</div>
		</div>

		<div *ngIf="!shouldHideProjectSelection()" class="mb-16">
			<label id="_t-reportProjects" class="control-label font-bold">{{'common.projects'|i18n}}</label>
			<div class="panel panel-default pre-scrollable">
				<div class="panel-body clearfix">
					<div *ngFor="let project of projects; index as i" class="labeled-checkbox">
						<input id="project_{{i}}" type="checkbox" [value]="project.projectId"
							(click)="updateSelectedProjects($event.target.checked, project)">
						<label for="project_{{i}}">{{project.name}}</label>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="hasNoProjects()">
			<alert type="danger" text="{{'metrics.noProjects'|i18n}}"></alert>
		</ng-container>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="cancel()" [i18n]="'common.cancel'"></button>
	<button type="button" class="btn btn-primary" [disabled]="!isProjectSelected()" (click)="continue()"
		[i18n]="'administration.exportLexiconSuggestions'"></button>
</div>
