import { Directive, Input, OnInit, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
	selector: '[ngModel][debounce]',
})
export class DebounceDirective implements OnInit, OnDestroy {
	@Input('debounce') debounceTime: number;
	@Output() debouncedChange = new EventEmitter<any>();

	private subscription: Subscription;

	constructor(
		private model: NgControl
	) {}

	ngOnInit() {
		this.subscription = this.model.valueChanges.pipe(
			filter(value => value != null),
			debounceTime(this.debounceTime),
			distinctUntilChanged()
		).subscribe((modelValue) => {
			this.debouncedChange.emit(modelValue);
		});
	}

	@HostListener('keydown.enter')
	onKeyEnter() {
		this.debouncedChange.emit(this.model.value);
	}

	@HostListener('keydown.escape')
	onKeyEscape() {
		this.debouncedChange.emit(this.model.value);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
