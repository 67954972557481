<div class="h-100-percent">
    <ng-container *ngIf="!previousResponse && !isLoading">
        <div class="form-group d-flex flex-direction-column border-b-1 border-b-solid border-gray-400 mb-0">
            <label class="text-0_875rem">{{ 'preview.survey' | i18n }}</label>
            <simple-dropdown
                class="survey-dropdown flex-fill mb-4"
                displayField="name"
                [options]="surveyList"
                valueField="id"
                [(value)]="selectedSurveyId"
                (valueChange)="switchSurvey($event)"
                noMinWidth="true"
                searchable="true">
            </simple-dropdown>
		</div>
    </ng-container>

    <ng-container *ngIf="previousResponse">
        <div class="d-flex w-100-percent justify-between border-b-1 border-b-solid border-gray-400 mb-0 pb-8 pl-8 pr-8">
            <p class="font-bold mb-0 pr-8">{{ selectedSurvey?.name }}</p>
            <div class="d-flex align-items-center justify-center">
                <p class="border-1 border-solid border-success-700 border-radius-4 text-center bg-success-200 text-success-700 mb-0 pl-4 pr-4">
                    {{ previousResponse.audit ? ('preview.updated' | i18n) : ('preview.complete' | i18n) }}
                </p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedSurvey">
        <div class="compliance-form-iframe d-flex flex-fill h-100-percent w-100-percent pb-16">
            <iframe class="w-100-percent pb-32" [src]="iframeUrl" frameborder="0"></iframe>
        </div>
    </ng-container>

    <ng-container *ngIf="!selectedSurvey && !isLoading">
        <div class="d-flex flex-direction-column align-items-center justify-center h-100-percent pb-32">
            <p class="pb-32">{{ 'preview.selectSurvey' | i18n }}</p>
        </div>
    </ng-container>
</div>
