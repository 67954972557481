import { DashboardId } from '@cxstudio/generic-types';

/** Need to switch to url-based state.
 * This is done for book editing behind "NEW_TABLES" beta, but dashboard edit from book still requires rework
 * @deprecated
 */
export class DashboardBookStateService {
	_bookId = undefined;

	isCreateBook = false;
	isEditBook = false;
	editFromBookId;
	dashboardId: DashboardId;
	isBookPage: boolean;

	setBookId = (bookId: number): void => {
		this._bookId = bookId;
	}

	getBookId = (): number => {
		return this._bookId;
	}

	hasState = (): boolean => {
		return this.isCreateBook || this.isEditBook;
	}

	setEditedFromBook = (bookId: number): void => {
		this.editFromBookId = bookId;
	}

	resetEditedFromBook = (): void => {
		delete this.editFromBookId;
		this.resetEditedDashboard();
	}

	getEditedFromBook = (): number => {
		return this.editFromBookId;
	}

	isEditedFromBook = (): boolean => {
		return !_.isUndefined(this.editFromBookId);
	}

	getEditedDashboard = (): DashboardId => {
		return this.dashboardId;
	}

	setEditedDashboard(dashboardId: DashboardId | string): void {
		this.dashboardId = parseInt(dashboardId as unknown as string, 10);
	}

	resetEditedDashboard = (): void => {
		delete this.dashboardId;
	}

	resetState = (): void => {
		this.isCreateBook = false;
		this.isEditBook = false;
		this._bookId = undefined;
		this.resetEditedFromBook();
	}
}


app.service('dashboardBookState', DashboardBookStateService);
