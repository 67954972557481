import { RequestAssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters-factory.class';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Injectable } from '@angular/core';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';

/**
 * Marker interface to ensure it covers workspace project + account project + admin project.
 * !!!!! DON'T BLINDLY ASSIGN TO THIS TYPE !!!!!
 * */
export interface AssetPromise<T> extends Promise<T> {
	dummyField: any; // this field doesn't allow passing a simple promise into argument which requires this interface
}

/**
 * Requests marked with such promise properly handle Studio Admin project, i.e. it's safe to pass any projectId here.
 * Directly extends AssetPromise and can be used with any project.
 * */
export interface AssetPromiseAnyProject<T> extends AssetPromise<T> {
	dummyField2: any;
}
/**
 * Requests marked with such promise only support valid Platform projects, i.e. cannot pass Studio Admin into such request
 * as it will likely fail or cause unexpected results.
 * */
export interface AssetPromisePlatformProject<T> extends Promise<T> {
	dummyField3: any;
}

@Injectable({
	providedIn: 'root'
})
export class ProjectAssetsHelper {
	constructor(
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly currentObjects: CurrentObjectsService,
	) {}

	static getAssetsForProject<T>(
		project: AccountOrWorkspaceProject,
		accountProjectDataProvider: (project: IProjectSelection) => AssetPromisePlatformProject<T>,
		workspaceProjectDataProvider: (project: WorkspaceProject) => AssetPromisePlatformProject<T>,
		internalProjectDataProvider: (projectId: number) => T,
	): AssetPromise<T> {
		if (InternalProjectTypes.isAdminProject(project.projectId)) {
			return Promise.resolve(internalProjectDataProvider(project.projectId)) as AssetPromise<T>;
		} else if (WorkspaceTransitionUtils.isWorkspaceProject(project)) {
			return this.platformProjectAssetPromise(workspaceProjectDataProvider(project));
		} else {
			return this.platformProjectAssetPromise(accountProjectDataProvider(project));
		}
	}

	static getAssetsForAnyProject<T>(
		project: AccountOrWorkspaceProject,
		accountProjectDataProvider: (project: IProjectSelection) => AssetPromiseAnyProject<T>,
		workspaceProjectDataProvider: (project: WorkspaceProject) => AssetPromiseAnyProject<T>,
	): AssetPromise<T> {
		if (WorkspaceTransitionUtils.isWorkspaceProject(project)) {
			return workspaceProjectDataProvider(project);
		} else {
			return accountProjectDataProvider(project);
		}
	}

	static getViewModeProjectData<T>(
		params: RequestAssetParameters,
		unifiedDataProvider: (project: RequestAssetParameters) => AssetPromisePlatformProject<T>,
		internalProjectDataProvider: (projectId: number) => T,
	): AssetPromise<T> {
		if (InternalProjectTypes.isAdminProject(params.getProjectId())) {
			return Promise.resolve(internalProjectDataProvider(params.getProjectId())) as AssetPromise<T>;
		} else {
			return this.platformProjectAssetPromise(unifiedDataProvider(params));
		}
	}

	/**
	 * This is just a named "cast" to AssetPromise,
	 * forcing us to think twice and check that it only accepts valid platform project.
	 * There should be a check for Studio Admin project where we use this.
	 * */
	private static platformProjectAssetPromise<T>(promise: AssetPromisePlatformProject<T>): AssetPromise<T> {
		return promise as any as AssetPromise<T>;
	}

	getWidgetProjectData<T>(
		widget: Widget,
		editModeDataProvider: (project: AccountOrWorkspaceProject) => AssetPromise<T>,
		viewModeDataProvider: (requestParams: RequestAssetParameters) => AssetPromise<T>
	): Promise<T> {
		if (this.currentObjects.isEditMode()) {
			let project = this.reportAssetUtilsService.getWidgetProject(widget);
			return editModeDataProvider(project);
		} else {
			let requestParams = RequestAssetParametersFactory.fromWidget(widget);
			return viewModeDataProvider(requestParams);
		}
	}

	getDashboardProjectData<T>(
		dashboard: Dashboard,
		editModeDataProvider: (project: AccountOrWorkspaceProject) => AssetPromise<T>,
		viewModeDataProvider: (requestParams: RequestAssetParameters) => AssetPromise<T>
	): Promise<T> {
		if (this.currentObjects.isEditMode()) {
			let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
			return editModeDataProvider(project);
		} else {
			let requestParams = RequestAssetParametersFactory.fromDashboard(dashboard);
			return viewModeDataProvider(requestParams);
		}
	}
}
