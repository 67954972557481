import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'date-range-picker-popup',
	templateUrl: './date-range-picker-popup.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter}]
})

export class DateRangePickerPopupComponent implements OnInit {
	hoveredDate: NgbDate | null = null;

	@Input() fromValue: Date;
	@Input() toValue: Date;

	@Output() fromValueChange = new EventEmitter<Date>();
	@Output() toValueChange = new EventEmitter<Date>();

	@Output() onChange = new EventEmitter<void>();

	ngOnInit(): void {
	}

	constructor(
		public formatter: NgbDateParserFormatter,
		public adapter: NgbDateAdapter<Date>
	) {}

	onDateSelection = (date: NgbDate): void => {
		if (!this.fromValue 
				|| this.fromValue.getTime() !== this.toValue.getTime() 
				|| date.before(this.fromDate(this.fromValue))) {
			this.updateFromValue(date);
			this.updateToValue(date);
		} else {
			this.updateToValue(date);
		}
		this.broadcastChangeEvent();
	}
	
	isHovered = (date: NgbDate): boolean => {
		return this.fromValue
				&& !this.toValue
				&& this.hoveredDate
				&& date.after(this.fromDate(this.fromValue))
				&& date.before(this.hoveredDate);
	}

	isInside = (date: NgbDate): boolean => {
		return this.toValue
				&& date.after(this.fromDate(this.fromValue))
				&& date.before(this.fromDate(this.toValue));
	}

	isRange = (date: NgbDate): boolean => {
		return date.equals(this.fromDate(this.fromValue))
				|| (this.toValue && date.equals(this.fromDate(this.toValue)))
				|| this.isInside(date)
				|| this.isHovered(date);
	}

	private updateFromValue = (date: NgbDate): void => {
		if (date === null) {
			this.fromValue = null;
			return;
		}

		this.fromValue = this.toDate(date);
		this.fromValueChange.emit(this.fromValue);
	}

	private updateToValue = (date: NgbDate): void => {
		if (date === null) {
			this.toValue = null;
			return;
		}

		this.toValue = this.toDate(date);
		this.toValueChange.emit(this.toValue);
	}

	private broadcastChangeEvent = (): void => {
		this.onChange.emit();
	}

	private toDate = (date: NgbDateStruct): Date => {
		return this.adapter.toModel(date);
	}

	private fromDate = (date: Date): NgbDateStruct => {
		return this.adapter.fromModel(date);
	}
}