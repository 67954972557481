import * as cloneDeep from 'lodash.clonedeep';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { AdminWorkspace } from '@app/modules/system-administration/platform/workspace/admin-workspace';
import WorkspaceManagementApiService from '@app/modules/system-administration/platform/workspace/workspace-management/workspace-management-api.service';
import { WorkspacesGridDefinition } from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-grid-definition.service';
import { WorkspacesListActions } from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-list-actions.service';
import { WorkspacesListMenu } from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-list-menu.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { GridEvent } from '@app/core/cx-event.enum';

export enum WorkspaceSearchMode {
	ALL = 'ALL',
	LINKED = 'LINKED',
	NOT_LINKED = 'NOT_LINKED',
}

@Component({
	selector: 'workspace-management',
	templateUrl: './workspace-management.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class WorkspaceManagementComponent extends SelfCleaningComponent implements OnInit {
	loading: Promise<any>;
	searchText: string;
	workspaces: AdminWorkspace[];
	filteredWorkspaces: AdminWorkspace[];

	gridOptions: SlickgridOptions;
	gridType = GridTypes.WORKSPACES;

	changedItems: AdminWorkspace[];
	isSysAdmin: boolean;
	pagination: Pagination;
	pagingOptions;
	private readonly PAGE_SIZE = 25;

	searchMode: WorkspaceSearchMode;
	searchOptions: Array<{ value: WorkspaceSearchMode, name: string }> =
		[{
			value: WorkspaceSearchMode.ALL,
			name: this.locale.getString('administration.workspaceSearchAll')
		}, {
			value: WorkspaceSearchMode.LINKED,
			name: this.locale.getString('administration.workspaceSearchLinked')
		}, {
			value: WorkspaceSearchMode.NOT_LINKED,
			name: this.locale.getString('administration.workspaceSearchNotLinked')
		}];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly workspaceManagementApi: WorkspaceManagementApiService,
		private readonly contextMenuUtils: WorkspacesListMenu,
		private readonly workspacesListActions: WorkspacesListActions,
		private readonly locale: CxLocaleService,
		private readonly eventBus: GlobalEventBus,
		@Inject('security') private security: Security,
		private readonly gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
	) {
		super();
	}

	ngOnInit(): void {
		this.pagination = new Pagination(this.PAGE_SIZE);
		this.pagingOptions = {
			pageSize: this.pagination.pageSize
		};
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};
		this.isSysAdmin = this.security.isSysAdmin();
		this.searchMode = WorkspaceSearchMode.ALL;
		this.addSubscription(this.workspacesListActions.getListChangeObservable().subscribe(this.loadWorkspaces));
		this.addListener(this.eventBus.subscribe(GridEvent.GRID_LIST_UPDATED, (event, dataView) => {
			this.pagination.totalItems = dataView.getPagingInfo().totalRows;
			this.pagination.currentPage = 1;
			this.applyPagination();
		}));
		this.loadWorkspaces();
	}

	private readonly loadWorkspaces = (): void => {
		this.loading = this.workspaceManagementApi.getWorkspaces()
			.then(workspaces => {
				workspaces.forEach(ws => ws.name = ws.name || '');
				this.workspaces = workspaces;
				this.filteredWorkspaces = cloneDeep(workspaces);
				this.pagination.totalItems = this.workspaces.length;
				this.pagination.currentPage = 1;
				this.applyPagination();
			});
		this.ref.detectChanges();
	}

	isSearchDisabled(): boolean {
		return _.isEmpty(this.workspaces) && _.isEmpty(this.searchText);
	}

	private applyPagination(): void {
		this.pagingOptions = {
			pageSize: this.pagination.pageSize,
			pageNum: this.pagination.currentPage - 1
		};
		this.refreshGrid();
		this.ref.detectChanges();
	}

	private refreshGrid(): void {
		this.changedItems = this.workspaces;
	}

	private onItemClick(event, object: AdminWorkspace): void {
		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object,
				this.contextMenuUtils.getContextMenu(object, this), 'workspaces', 360);
		} else if (this.gridUtils.isNameClick(event)) {
			this.workspacesListActions.edit(object, this.workspaces);
		} else if (this.gridUtils.isElementClick(event, WorkspacesGridDefinition.REQUEST_UPDATE_CLASS)) {
			this.loading = this.workspacesListActions.requestSync(object);
			this.ref.detectChanges();
		}
	}

	createWorkspace(): void {
		this.workspacesListActions.create(this.workspaces);
	}

	onSearchChange = () => {
		this.searchText = '';
		this.filteredWorkspaces = _.filter(this.workspaces, (workspace) => {
			if (this.searchMode === WorkspaceSearchMode.ALL) {
				return true;
			}

			if (this.searchMode === WorkspaceSearchMode.LINKED) {
				return workspace.masterAccounts?.length !== 0;
			}

			if (this.searchMode === WorkspaceSearchMode.NOT_LINKED) {
				return workspace.masterAccounts?.isEmpty();
			}

			return false;
		});
	}

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.applyPagination();
	}
}
