import { ChangeDetectionStrategy, Component, Inject, ChangeDetectorRef } from '@angular/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { HierarchyFiltersProjectSelectorComponent } from './project-selector/hierarchy-filters-project-selector.component';
import { HierarchyUploadWizardPageBaseComponent } from '../hierarchy-upload-wizard-base.component';
import { HierarchyComponentInput } from '../hierarchy-component-input';
import { CxLocaleService } from '@app/core';


@Component({
	selector: 'hierarchy-filters-wizard-page',
	templateUrl: './hierarchy-filters-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyFiltersWizardPageComponent extends HierarchyUploadWizardPageBaseComponent {

	constructor(
		ref: ChangeDetectorRef,
		private organizationApiService: OrganizationApiService,
		private readonly cxDialogService: CxDialogService,
		protected locale: CxLocaleService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('urlService') private urlService: UrlService,
	) { 
		super(ref, locale);
	}

	getFileName(): string {
		return this.hierarchy.filterFileName;
	}
	
	getUpdateFileName(): string {
		return this.hierarchy.updateFilterFileName;
	}

	downloadDataFile(): void {
		this.loading = this.organizationApiService.getOrganizationFilters(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	downloadTemplateFile(): void {
		const input: HierarchyComponentInput = {
			hierarchy: this.hierarchy
		};

		this.cxDialogService.openDialog(HierarchyFiltersProjectSelectorComponent, input);
	}

	getFileUploadUrl(): string {
		return this.urlService.getAPIUrl(
			`rest/organization/filters/file/upload/${this.hierarchy.id}`);
	}

}

