import { HttpParams } from '@angular/common/http';
import { Pagination } from './pagination';

export class PaginationHttpParams extends HttpParams {
	/*
	 * We can't add this method to Pagination as it used  in AngularJs
	 */
	static of(pagination: Pagination): HttpParams {
		return new HttpParams()
			.append('isPaginated', 'true')
			.append('currentPage', pagination.currentPage.toString())
			.append('pageSize', pagination.pageSize.toString());
	}
}
