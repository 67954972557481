import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {setAngularJSGlobal} from '@angular/upgrade/static';
import './../build/angularJS';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import '../ajs/src/lib/highcharts-extensions';

import { StudioSchemaRegistryProvider } from '@app/studio-elements-schema-registry';

if (environment.production) {
	enableProdMode();
}

setAngularJSGlobal(angular);

platformBrowserDynamic()
	.bootstrapModule(AppModule, {providers: [StudioSchemaRegistryProvider]})
	.catch((err) => console.error(err));
