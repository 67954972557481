import * as _ from 'underscore';


export class PeriodSelectorController implements ng.IController {

	historicOptions;
	showEditor: boolean;
	options;
	selectorProperties;
	onLabelChange: () => void;

	constructor() {}

	$onInit(): void {
		this.historicOptions = this.historicOptions || false;
		this.showEditor = this.showEditor || false;
		this.options = this.options || {};
		this.selectorProperties = this.selectorProperties || {};
		this.onLabelChange = this.onLabelChange || _.noop;
	}
}

app.component('periodSelector', {
	controller: PeriodSelectorController,
	templateUrl: 'partials/widgets/settings/period-selector.html',
	bindings: {
		period: '<',
		showEditor: '<',
		historicOptions: '<',
		options: '<',
		projectTimezone: '<',
		selectorProperties: '<',
		label: '@',
		visualProps: '=',
		inherited: '<',
		ngDisabled: '&',
		currentPeriodForHistoric: '<',
		onLabelChange: '&',
		periodOptionsFilter: '<?'
	},
});
