export class ModificationDelta<T> {
	added: T[];
	removed: T[];

	constructor() {
		this.added = [];
		this.removed = [];
	}

	isEmpty(): boolean {
		return this.added.isEmpty() && this.removed.isEmpty();
	}
}
