import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { PromiseUtils } from '@app/util/promise-utils';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';

export class SystemAdminApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private licenseApiService: LicenseApiService
	) {}

	getEmailDomainsByMa = (): ng.IHttpPromise<any> =>
		this.$http.get('rest/system_admin/master_account/email-domains/counts')

	getAllMasterAccounts = (): ng.IHttpPromise<any> =>
		this.$http.get('rest/system_admin/master_account/list')

	getPagedMaList = (queryParams): ng.IHttpPromise<any> =>
		this.$http.get('rest/system_admin/master_account/paged', queryParams)

	updateMasterAccount = (masterAccountData: MasterAccount): ng.IHttpPromise<any> =>
		this.$http.put('rest/system_admin/master_account/update', angular.toJson(masterAccountData))

	addMasterAccount = (masterAccountData: MasterAccount): ng.IHttpPromise<any> =>
		this.$http.post('rest/system_admin/master_account/register', angular.toJson(masterAccountData))

	disableMasterAccount = (masterAccountId: number|string): ng.IHttpPromise<any> =>
		this.$http.post(`rest/system_admin/master_account/${masterAccountId}/disable`, undefined)

	enableMasterAccount = (masterAccountId: number|string): ng.IHttpPromise<any> =>
		this.$http.post(`rest/system_admin/master_account/${masterAccountId}/enable`, undefined)

	deleteMasterAccount = (masterAccountId: number|string): ng.IHttpPromise<any> => {
		let queryParams = {
			params: {
				mode: 'remove'
			}
		};
		return this.$http.delete(`rest/system_admin/master_account/${masterAccountId}`, queryParams);
	}

	isQualtricsBrandIdExisting = (brandId: string): Promise<any> =>
		PromiseUtils.wrap(this.$http.get(`rest/system_admin/master_account/brandId/exists/${brandId}`))

	checkIdpAliasNameExist = (aliasName): Promise<any> =>
		PromiseUtils.wrap(this.$http.get(`rest/system_admin/master_account/idpAliasNameExist/${aliasName}`))

	samlEnabledMasterAccounts = (): ng.IHttpPromise<any> =>
		this.$http.get('rest/system_admin/master_account/list/samlEnabled')

	disableUser = (userEmail): ng.IHttpPromise<any> =>
		this.$http.post('rest/system_admin/users/disable', userEmail)

	deleteUser = (userEmail): ng.IHttpPromise<any> =>
		this.$http.post('rest/system_admin/users/delete', userEmail)

	getAllUsersPaged = (queryParams): ng.IHttpPromise<any> =>
		this.$http.get('rest/system_admin/users/paged', queryParams)
			.then((response: { data: any }) => {
				response.data.data = response.data.data.map(this.licenseApiService.localizeLicenseTypeName);
				return response;
			})

	getMasterAccountAdminOrgUsersSuggestion = (masterAccountId: number|string, emailFilter): ng.IHttpPromise<any> => {
		let properties: any = {};
		properties.isPaginated = true;
		properties.pageSize = 20;
		properties.currentPage = 1;
		properties.filterText = emailFilter;
		properties.filterField = 'userEmail';

		return this.$http.get(`rest/system_admin/users/master_account/${masterAccountId}/admin_org/paged`,
			{params: properties});
	}

	getOrganizationList = (masterAccountId: number|string): ng.IPromise<any> =>
		this.$http.get(`rest/system_admin/master_account/${masterAccountId}/organization_hierarchies`).then(this.httpHandlers.success)
}

app.service('systemAdminApiService', SystemAdminApiService);
