import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IGridColumn } from './grid-column';


export class CommonGridColumns {

	constructor(
		private gridFormatterService: GridFormatter,
		private locale: ILocale
	) {}

	getRowSelectorColumn = (): IGridColumn => {
		return {
			id: 'checkbox',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-checkbox text-center',
			name: '<span></span>',
			cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.SelectedRowFormatter,
			resizable: false
			// no name property, as we'll add the checkbox in the html
		};
	}

	getMenuColumn = (onClick: (event, object) => void, localizedObjectType?: string): IGridColumn => {
		let menuTitle = localizedObjectType ?
			this.locale.getString('common.objectTypeMenu', {objectType: localizedObjectType}) :
			this.locale.getString('common.genericObjectMenu');
		return {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: `<i class="q-icon-layer" aria-label="${menuTitle}" title="${menuTitle}"></i>`,
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false,
			onClick
		};
	}

	getSpacerColumn = (): IGridColumn => {
		return {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		};
	}

	getLabelsColumn = (localizedObjectType?: string): IGridColumn => {
		let labelTitle = localizedObjectType ?
			this.locale.getString('common.objectTypeLabel', {objectType: localizedObjectType}) :
			this.locale.getString('common.genericObjectLabel');

		return {
			id: 'labels',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'text-center',
			name: `<i class="q-icon-tag" aria-label="${labelTitle}" title="${labelTitle}"></i>`,
			cssClass: 'text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.LabelsFormatter,
			resizable: false,
			customCellAttributes: (row, cell, value, columnDef, dataContext): {[key: string]: any} | undefined => {
				if (/.*folder.*/i.test(dataContext.type)) return {'aria-hidden': true};

				let emptyLabelsText = localizedObjectType ?
					this.locale.getString('common.objectTypeEmptyLabel', {objectType: localizedObjectType}) :
					this.locale.getString('common.genericObjectEmptyLabel');
				if (!dataContext.labels || !dataContext.labels.length) return {'aria-label' : emptyLabelsText};
			}
		};
	}
}

app.service('commonGridColumns', CommonGridColumns);