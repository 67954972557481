import { Injectable } from '@angular/core';

import { PagedResponse } from '@cxstudio/paged-response';

import { CxHttpService } from '@app/core';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { SocialCaseLogItem } from './social-case-log-item';
import { PaginationHttpParams } from '@app/shared/components/pagination/pagination-http-params';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { ListOption } from '@app/shared/components/forms/list-option';

@Injectable({
	providedIn: 'root'
})
export class SocialCaseLogApi {
	constructor(private cxHttp: CxHttpService) {}

	getSocialCasesLog = (pagination: Pagination, filters: ObjectListFilter[]):
			Promise<PagedResponse<SocialCaseLogItem>> => {
		let url = 'rest/system-admin/social-cases-log';
		let params = PaginationHttpParams.of(pagination);
		return this.cxHttp.post(url, filters, {params});
	}

	getFilterOptions = (): Promise<{[field: string]: ListOption<string>[]}> => {
		let url = 'rest/system-admin/social-cases-log/filters';
		return this.cxHttp.get(url);
	}
}
