import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { PredefinedMetricDefinition } from '@app/modules/metric/definition/predefined-metric-definition';
import { ControlContainer, NgForm } from '@angular/forms';
import { IColorGrades, IColorGradeOptions } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { MetricColorGradesService } from '@app/modules/metric/services/metric-color-grades.service';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { AlertLevel } from '@clarabridge/unified-angular-components';

@Component({
	selector: 'predefined-metric-definition',
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
	template: `
		<fieldset ngModelGroup="predefinedMetricDefinition">
			<div class="form-group">
				<div class="line-label bg-pewter-400">
					<label class="bg-white">{{'metrics.metricBands'|i18n}}</label>
				</div>
				<cb-color-grade-bar
					class="metric-color-grade br-predefined"
					[grades]="colorGrades"
					(gradesChange)="colorGradesChangeHandler($event)"
					[options]="options"
					[min]="definition.min"
					[max]="definition.max"
					(validityChange)="validityChangeHandler($event)">
				</cb-color-grade-bar>
				<alert [type]="AlertLevel.INFO">{{'metrics.invertInDarkMode'|i18n}}</alert>
				<div *ngIf="showBreakdownNote">
					<label>{{'metrics.breakDownNote'|i18n}}</label>
				</div>
				<div>
					<a class="cb-restore-defaults"
						(click)="resetPredefinedMetric()"
						[i18n]="'metrics.restoreDefaults'">
					</a>
				</div>
			</div>
		</fieldset>
	`
})
export class PredefinedMetricDefinitionComponent implements OnInit {

	@Input() definition: PredefinedMetricDefinition;
	@Output() definitionChange = new EventEmitter<PredefinedMetricDefinition>();
	@Output() validityChange = new EventEmitter<boolean>();
	@Output() restoreDefaults = new EventEmitter<void>();

	colorGrades: IColorGrades;
	options: Partial<IColorGradeOptions>;
	AlertLevel = AlertLevel;

	showBreakdownNote: boolean;

	constructor(
		private metricColorGradesService: MetricColorGradesService,
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
	) {}

	ngOnInit(): void {
		this.reloadColorGrades();
		this.options = this.metricColorGradesService.getPredefinedDefinitionOptions(this.definition.type);

		let type = this.definition.type;
		this.showBreakdownNote = type === MetricType.SENTIMENT || type === MetricType.EASE_SCORE;
	}

	private reloadColorGrades = () => {
		this.colorGrades = this.metricColorGradesService.getColorGrades(this.definition);
	}

	colorGradesChangeHandler = (colorGrades: IColorGrades): void => {
		this.metricColorGradesService.updatePredefinedMetricDefinition(this.definition, colorGrades);
		this.definitionChange.emit(this.definition);
	}

	validityChangeHandler = (isValid: boolean): void => {
		this.validityChange.emit(isValid);
	}

	resetPredefinedMetric = (): void => {
		this.metricApiService.getPredefinedMetricDefaults(this.definition.name).then(predefinedMetric => {
			let definition = predefinedMetric.definition;
			this.definition.thresholds = definition.thresholds;
			this.definition.colorPalette = definition.hexColors;
			this.definition.calculation.thresholds = definition.calculation.thresholds;
			this.definition.calculation.colorPalette = definition.hexColors;
			// to re-render definition color bar
			this.reloadColorGrades();
			// to re-render conditional formatting color bar
			this.restoreDefaults.emit();
		});
	}

}
