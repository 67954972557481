import * as _ from 'underscore';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SimpleChanges } from '@app/util/change-utils';


enum ComponentState {
	BEFORE_NG_INIT,
	AFTER_NG_INIT,
	POPULATED
}

// helper to make simple dropdowns easier
// only one required binding (list of options), plus ng-model
// option to add custom selection handling, and display name / value properties
@Component({
	selector: 'simple-dropdown',
	template: `
		<search-list [dropdown]="true"
			class='w-100-percent d-flex'
			[ngStyle]="{'min-width': minWidth}"
			[disableSearch]=!searchable
			[displayField]="displayField"
			[sortField]="sortField"
			[disableSort]="disableSort"
			[data]="selectedOption || preselectedValue"
			[nonclickable]="nonclickable || disabled"
			[selectPrompt]="selectPrompt"
			[allowClear]="allowClear"
			[listOptions]="options"
			[optionValueField]="valueField"
			matchSize="true"
			[tooltipField]="tooltipField"
			[visibilityChange]="visibilityChange"
			(onNodeSelection)="updateValue($event.node)"
			(onClearSelection)="clearSelection()"
			[isItemDisabled]="isItemDisabled"
			[attr.disabled]="disabled ? '' : null"
			[appendToBody]="appendToBody">
		</search-list>`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
		 	multi:true,
		 	useExisting: SimpleDropdownComponent
		}]
})
export class SimpleDropdownComponent<T, V = any> implements OnInit, OnChanges, ControlValueAccessor {
	// the maximum value to honor as the minimum width
	// prevents extremely long value from blowing out the width of the dropdown
	private readonly MAX_MIN_WIDTH = 20;

	@Input() value: V;
	@Output() valueChange = new EventEmitter<V>();

	@Input() options: T[];

	@Input() displayField: string;
	@Input() valueField: string;
	@Input() sortField: string;
	@Input() disableSort: boolean;
	@Input() selectPrompt: string;
	@Input() allowClear: boolean;
	@Input() nonclickable: boolean;
	@Input() disabled: boolean;
	@Input() searchable: boolean;
	@Input() appendToBody: boolean;
	@Input() tooltipField?: string;
	@Input() preselectedValue: T; //in case if show the existing value that is not valid any more

	@Input() noMinWidth: boolean;
	// used to trigger update of dropdown size when <simple-dropdown> starts hidden
	@Input() visibilityChange: boolean;
	@Output() onChange = new EventEmitter<T>();

	selectedOption: T;
	minWidth: string = 'none';

	private state = ComponentState.BEFORE_NG_INIT;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.displayField = this.displayField || 'name';
		this.valueField = this.valueField || 'value';
		this.noMinWidth = this.noMinWidth || false;

		this.state = ComponentState.AFTER_NG_INIT;
		this.initializeValue(this.isReadyToInitialize());
	}

	ngOnChanges(changes: SimpleChanges<SimpleDropdownComponent<T>>): void {
		if (this.state !== ComponentState.POPULATED && changes.options) {
			this.initializeValue(this.isReadyToInitialize());
		}
		if (changes.value !== undefined) {
			this.modelValueUpdated(changes.value);
		}
	}

	initializeValue = (isReady: boolean): void => {
		if (isReady) {
			this.selectedOption = _.find(this.options,
				(opt): boolean => opt[this.valueField] === this.value) || this.preselectedValue;

			let longestOption: number =  this.selectedOption
				? this.selectedOption[this.displayField].length
				// possible that the search prompt may be the longest text item
				: this.locale.getString('common.selectPrompt').length;

			if (!this.noMinWidth) {
				_.map(this.options, (opt) => {
					if (opt[this.displayField].length > longestOption)
						longestOption = opt[this.displayField].length;
				});

				// if longest option is longer than our maximum, just use our predefined maximum
				this.minWidth = (longestOption < this.MAX_MIN_WIDTH) ?
					`${longestOption}em` :
					`${this.MAX_MIN_WIDTH}em`;
			}

			this.state = ComponentState.POPULATED;
		}
	}

	isReadyToInitialize = (): boolean => {
		return this.state === ComponentState.AFTER_NG_INIT
			&& this.options
			&& this.value !== undefined;
	}

	modelValueUpdated = (valueChange: SimpleChange) => {
		if (valueChange.currentValue !== valueChange.previousValue) {
			this.selectedOption = _.find(this.options,
				(opt) => opt[this.valueField] === valueChange.currentValue);
		}
	}

	updateValue = (newOption: T): void => {
		if (this.isChanged(newOption)) {
			this.selectedOption = newOption;
			this.valueChange.emit(newOption[this.valueField]);
			this.onChange.emit(newOption);
			this.onChangeCallback(newOption[this.valueField]);
			this.onTouchedCallback();
		}
	}

	private isChanged = (newOption: T): boolean => {
		if (!this.selectedOption && !newOption) {
			return false;
		}
		if (this.selectedOption && newOption) {
			return this.selectedOption[this.valueField] !== newOption[this.valueField];
		}
		return true;
	}

	clearSelection = (): void => {
		if (this.allowClear) {
			this.selectedOption = undefined;
			this.valueChange.emit('' as any);
			this.onChange.emit(undefined);
			this.onChangeCallback(undefined);
			this.onTouchedCallback();
		}
	}

	isItemDisabled = (option: any): boolean => {
		return !option || option.disabled;
	}

	//ControlValueAccessor
	writeValue(value): void {
		if (value !== undefined && value !== this.value) {
			this.value = value;
			this.initializeValue(this.isReadyToInitialize());
		}
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.ref.markForCheck();
	}
}

app.directive('simpleDropdown', downgradeComponent({component: SimpleDropdownComponent}) as angular.IDirectiveFactory);
