import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { ProjectContextApiService } from '@app/modules/project/context/project-context-api.service';
import { WorkspaceProjectContextApiService } from '@app/modules/project/context/workspace-project-context-api.service';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CacheOptions } from '@cxstudio/common/cache-options';


@Injectable({
	providedIn: 'root'
})
export class ProjectContextService {
	constructor(
		private readonly projectContextApi: ProjectContextApiService,
		private readonly workspaceProjectContextApi: WorkspaceProjectContextApiService,
	) {}

	getProjectTimezone(project: AccountOrWorkspaceProject): AssetPromise<string> {
		return ProjectAssetsHelper.getAssetsForProject<string>(
			project,
			(accountProject) => this.projectContextApi.getProjectTimezone(accountProject),
			(workspaceProject) => this.workspaceProjectContextApi.getProjectTimezone(workspaceProject),
			(projectId) => ''
		);
	}

	getDesignerPalette(project: AccountOrWorkspaceProject, local = true, cache = CacheOptions.CACHED): AssetPromise<string[]> {
		return ProjectAssetsHelper.getAssetsForProject<string[]>(
			project,
			(accountProject) => this.projectContextApi.getDesignerPalette(accountProject, local, cache),
			(workspaceProject) => this.workspaceProjectContextApi.getDesignerPalette(workspaceProject, local, cache),
			(projectId) => []
		);
	}

	getProjectContext(project: AccountOrWorkspaceProject): Promise<IProjectContext> {
		return Promise.resolve({});
	}
}

app.service('projectContextService', downgradeInjectable(ProjectContextService));
