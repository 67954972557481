import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import AnalyticWidget from './analytic-widget.class';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';

export class DualChartWidget extends AnalyticWidget {
	visualProperties: VisualProperties;

	static get(): DualChartWidget {
		return new DualChartWidget();
	}

	constructor() {
		super();
		this.name = WidgetType.LINE;
		this.properties = {} as WidgetProperties;
		this.properties.widgetType = WidgetType.LINE;
		this.visualProperties = new VisualProperties();
		let visualProps = {
			visualization: WidgetVisualization.DUAL,
			subChartType: 'SPLINE',
			secondaryChartType: 'BUBBLE',
			color: ColorPaletteNames.PALETTE_1,
			secondaryColor: ColorPaletteNames.PALETTE_1,
			yAxisAutoMax: false,
			yAxisAutoMin: false,
			yAxisMax: '1',
			yAxisMin: '0',
			secondaryAxisAutoMax: true,
			secondaryAxisAutoMin: false,
			secondaryAxisMin: '0',
			secondaryScale: '2',
			attributeSelections: {
				primaryGroup: {},
				yAxis: {},
				secondaryYAxis: {}
			}
		};
		_.extend(this.visualProperties, visualProps);
	}

	withCalculations(calculations: ReportCalculation[]): DualChartWidget {
		let primary = calculations[0];
		this.properties.selectedMetrics = calculations;
		this.visualProperties.yAxis = primary.name;
		this.visualProperties.attributeSelections.yAxis = primary;

		if (calculations?.length === 2) {
			let secondary = calculations[1];
			this.visualProperties.secondaryYAxis = secondary.name;
			this.visualProperties.attributeSelections.secondaryYAxis = secondary;
		} else {
			delete this.visualProperties.secondaryChartType;
		}

		return this;
	}

	withTimeGrouping(grouping: any): DualChartWidget {
		super.withGroupings([grouping]);
		this.visualProperties.attributeSelections.primaryGroup = grouping;
		this.visualProperties.primaryGroup = grouping.name;
		_.extend(this.properties, {primaryTimeGrouping: grouping});
		return this;
	}

	withColors(primaryColor: string, secondaryColor: string): DualChartWidget {
		this.visualProperties.color = ColorPaletteNames.CUSTOM;
		this.visualProperties.secondaryColor = ColorPaletteNames.CUSTOM;
		this.visualProperties.customColor = primaryColor;
		this.visualProperties.secondaryCustomColor = secondaryColor;
		return this;
	}
}
