import * as _ from 'underscore';
import Authorization from '@cxstudio/auth/authorization-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import Hierarchy from './Hierarchy';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { HierarchyPublicationState, OrgHierarchyUIStatus } from '@cxstudio/organizations/hierarchy-publication-state';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { HierarchyDependencyTypesProvider } from '@app/modules/hierarchy/services/hierarchy-dependency-types-provider';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { DependenciesDialogInput } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import {
	OrganizationStructureWizardUploadDialogInput
} from '@app/modules/hierarchy/structure/upload/organization-structure-wizard-upload-modal.component';
import {
	OrganizationFiltersWizardUploadDialogInput
} from '@app/modules/hierarchy/filters/upload/organization-filters-wizard-upload-modal.component';
import {
	OrganizationEnrichmentWizardUploadDialogInput
} from '@app/modules/hierarchy/enrichment/upload/organization-enrichment-wizard-upload-modal.component';
import { MenuDivider, MenuOptionType } from '@cxstudio/context-menu/drill-menu-option.component';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';
import { HierarchyMode } from './hierarchy-mode';
import { OrganizationUtils } from '@app/modules/hierarchy/organization-utils';

export class OrganizationsTableComponent implements ng.IComponentController {
	// should match Hierarchy.java::FORBIDDEN_UNPUBLISHING_STATES except NOT_PUBLISHED
	readonly DISALLOWED_REPLACE_STATES = [
		HierarchyPublicationState.UNPUBLISHING,
		HierarchyPublicationState.PUBLISHING,
		HierarchyPublicationState.CLASSIFYING
	];

	promises: {
		loadingHierarchiesBackground?: PromiseLike<any>
	};

	organizations: Hierarchy[];

	isImpliedFilterEnabled: boolean;

	reloadList: () => void;
	isHierarchyPublishingEnabled: () => boolean;
	isHierarchyPublishing: (hierarchy: Hierarchy) => boolean;

	constructor(
		private $log: ng.ILogService,
		private organizationApiService: OrganizationApiService,
		private contextMenuTree: ContextMenuTree,
		private authorization: Authorization,
		private $uibModal: ng.ui.bootstrap.IModalService,
		private cbDialogService: CBDialogService,
		private locale: ILocale,
		private dateService,
		private hierarchyDependencyTypesProvider: HierarchyDependencyTypesProvider,
		private globalNotificationService: GlobalNotificationService,
		private betaFeaturesService: BetaFeaturesService,
		private downgradeDialogService: DowngradeDialogService,
		private $location: ng.ILocationService) {
	}

	$onInit = () => {
		// required for typescript validation
		this.isImpliedFilterEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.OH_IMPLIED_FILTER);
	}

	uploadStructureWizard = (hierarchy: Hierarchy) => {
		this.openStructureWizard(hierarchy, false);
	}

	editStructureWizard = (hierarchy: Hierarchy) => {
		this.openStructureWizard(hierarchy, true);
	}

	uploadWizardEnabled = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_HIERARCHY_UPLOAD);
	}

	openUploadWizard = (hierarchy: Hierarchy) => {
		this.$location.url('/hierarchy/edit/' + hierarchy.id);
	}

	isFileWizardUploadComplete(hierarchy: Hierarchy): boolean {
		return OrganizationUtils.isFileWizardUploadComplete(hierarchy);
	}

	private openStructureWizard = (hierarchy: Hierarchy, update: boolean) => {
		const input: OrganizationStructureWizardUploadDialogInput = {
			hierarchy,
			update
		};

		const editStructureDialog = this.downgradeDialogService.openOrganizationStructureWizardUploadModal(input);

		editStructureDialog.result
			.then(this.reloadList)
			.catch(_.noop);
	}

	uploadFiltersWizard = (hierarchy: Hierarchy) => {
		this.openFiltersWizard(hierarchy, false);
	}

	editFiltersWizard = (hierarchy: Hierarchy) => {
		this.openFiltersWizard(hierarchy, true);
	}

	private openFiltersWizard = (hierarchy: Hierarchy, update: boolean) => {
		const input: OrganizationFiltersWizardUploadDialogInput = {
			hierarchy,
			update
		};

		const editFiltersDialog = this.downgradeDialogService.openOrganizationFiltersWizardUploadModal(input);

		editFiltersDialog.result
			.then(this.reloadList)
			.catch(_.noop);
	}

	uploadUsersWizard = (hierarchy: Hierarchy) => {
		let uploadUsersDialog = this.$uibModal.open({
			templateUrl: 'partials/organizations/users/organization-users-wizard.html',
			controller: 'OrganizationsUsersWizardCtrl',
			resolve: {
				update: () => false,
				hierarchy: () => hierarchy
			}
		});

		uploadUsersDialog.result.then(this.reloadList);
	}

	editUsersWizard = (hierarchy: Hierarchy) => {
		let editUsersDialog = this.$uibModal.open({
			templateUrl: 'partials/organizations/users/organization-users-wizard.html',
			controller: 'OrganizationsUsersWizardCtrl',
			windowClass: 'modal-md',
			resolve: {
				update: () => true,
				hierarchy: () => hierarchy
			}
		});

		editUsersDialog.result.then(this.reloadList);
	}

	uploadEnrichmentWizard = (hierarchy: Hierarchy) => {
		this.openEnrichmentWizard(hierarchy, false);
	}

	editEnrichmentWizard = (hierarchy: Hierarchy) => {
		this.openEnrichmentWizard(hierarchy, true);
	}

	private openEnrichmentWizard = (hierarchy: Hierarchy, update: boolean) => {
		const input: OrganizationEnrichmentWizardUploadDialogInput = {
			hierarchy,
			update
		};

		const editEnrichmentDialog = this.downgradeDialogService.openOrganizationEnrichmentWizardUploadModal(input);

		editEnrichmentDialog.result
			.then(this.reloadList)
			.catch(_.noop);
	}

	removeOrg = (hierarchy: Hierarchy) => {
		let removeHierarchyDialog = this.$uibModal.open({
			templateUrl: 'partials/organizations/remove-hierarchy/remove-hierarchy-wizard.html',
			controller: 'RemoveHierarchyWizardController',
			windowClass: 'modal-md',
			resolve: {
				hierarchy: () => hierarchy
			}
		});

		removeHierarchyDialog.result.then(this.reloadList);
	}

	viewHierarchyDependencies = (hierarchy: Hierarchy) => {
		let input: DependenciesDialogInput<StudioAsset> = {
			asset: {
				assetId: hierarchy.id,
				name: hierarchy.name
			} as any,
			dependencyTypesProvider: this.hierarchyDependencyTypesProvider
		};

		this.downgradeDialogService.openDependenciesModal(input);
	}

	hierarchyPublished = (hierarchy: Hierarchy): boolean => OrganizationUtils.isPublishedHierarchy(hierarchy);

	orgActivation = (hierarchy: Hierarchy) => {
		const id = hierarchy.id;
		const isHierarchyActive = hierarchy.active;
		hierarchy.active = !hierarchy.active;

		let modalBody = this.locale.getString('organization.confirmHierarchyDeactivate');
		let modalTitle = this.locale.getString('organization.confirmTitle');

		if (isHierarchyActive) {
			if (this.isClassifiedHierarchy(hierarchy)) {
				modalBody = this.locale.getString('organization.confirmHierarchyActivate');
			} else {
				modalBody = this.locale.getString('organization.confirmClassifiedHierarchyActivate') +
					`<br><br>` + this.locale.getString('organization.confirmOrgHierarchyWithoutClassifying');
				modalTitle = this.locale.getString('organization.confirmHierarchy', {hierarchyName: hierarchy.name});
			}
		}

		const apiCall = isHierarchyActive ? this.organizationApiService.activateOrgHierarchy : this.organizationApiService.deactivateOrgHierarchy;

		if (isHierarchyActive && OrganizationUtils.isDynamicFilteringHierarchy(hierarchy)) {
			// if activating an attribute filter, we don't want to show a dialog
			apiCall(id).then(() => this.reloadAfterActivationChange(id));
		} else {
			let activationConfirmation = this.cbDialogService.confirm(modalTitle, modalBody,
				this.locale.getString('organization.confirmPositive'), this.locale.getString('organization.confirmNegative'));
			activationConfirmation.result.then(() => {
				apiCall(id).then(() => this.reloadAfterActivationChange(id));
			});
		}
	}

	private reloadAfterActivationChange = (hierarchyId: number) => {
		this.$log.debug('Organization activation changed:', hierarchyId);
		this.reloadList();
	}

	isActiveAllowed = (hierarchy: Hierarchy) => OrganizationUtils.isActiveAllowed(hierarchy);

	getModifiedDate = (date) => date ? this.dateService.format(date, DateTimeFormat.BASIC_DATE_TIME) : undefined;

	getProjectsList = (hierarchy: Hierarchy) => {
		let projectList = hierarchy.projects;
		if (!projectList || !projectList.length) {
			return '';
		}
		if (projectList.length > 1) {
			return this.locale.getString('organization.multipleProjects');
		}
		return projectList[0].projectName;
	}

	canHierarchyBePublished = (hierarchy: Hierarchy) =>
		OrganizationUtils.isHierarchyStructureUploaded(hierarchy) && OrganizationUtils.isHierarchyFiltersUploaded(hierarchy)


	publishHierarchy = (hierarchy: Hierarchy) => {
		this.downgradeDialogService.openHierarchyPublishingDialog().result.then(publishingConfiguration => {
			hierarchy.publicationState = HierarchyPublicationState.PUBLISHING;

			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('organization.startedClassificationPublishing', {hierarchyName: hierarchy.name}));

			this.organizationApiService
				.publishHierarchy(hierarchy.id, publishingConfiguration)
				.then(this.reloadList, this.reloadList);
		});
	}

	republishHierarchy = (hierarchy: Hierarchy) => {
		this.downgradeDialogService.openHierarchyPublishingDialog().result.then((publishingConfiguration) => {
			hierarchy.publicationState = HierarchyPublicationState.PUBLISHING;

			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('organization.startedClassificationPublishing', {hierarchyName: hierarchy.name}));

			this.organizationApiService
				.republishHierarchy(hierarchy.id, publishingConfiguration)
				.then(this.reloadList, this.reloadList);
		});
	}

	applyUpdate = (hierarchy: Hierarchy) => {
		this.downgradeDialogService.openHierarchyDeltaPublishingDialog().result.then((publishingConfiguration) => {
			hierarchy.publicationState = HierarchyPublicationState.PUBLISHING;

			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('organization.startedUpdateApplication', {hierarchyName: hierarchy.name})
			);

			this.organizationApiService
				.applyUpdate(hierarchy.id, publishingConfiguration)
				.then(this.reloadList, this.reloadList);
		});
	}

	discardUpdate = (hierarchy: Hierarchy) => {
		this.globalNotificationService.addSuccessNotification(
			this.locale.getString('organization.discardingUpdate', {hierarchyName: hierarchy.name})
		);

		this.organizationApiService
			.discardUpdate(hierarchy.id)
			.then(this.reloadList, this.reloadList);
	}

	refreshHierarchyPublicationStatus = (hierarchy: Hierarchy) => {
		this.organizationApiService.refreshHierarchyPublicationStatus(hierarchy.id)
			.then(this.reloadList, this.reloadList);
	}

	getIncludingEmptyRecordsLabel = (organization) => {
		let publication = organization.publications && organization.publications[0];
		if (!publication)
			return '';

		return publication.includingEmptyRecords ? this.locale.getString('common.yes') : this.locale.getString('common.no');
	}

	getIncrementalModeLabel = (organization) => {
		let publication = organization.publications && organization.publications[0];
		if (!publication)
			return '';

		return publication.incrementalMode ? this.locale.getString('common.yes') : this.locale.getString('common.no');
	}

	unpublishHierarchy = (hierarchy: Hierarchy): void => {
		if (hierarchy.active) {
			let unpublishingConfirmation = this.cbDialogService.confirm(
				this.locale.getString('organization.confirmHierarchy', {hierarchyName: hierarchy.name}),
				this.locale.getString('organization.confirmHierarchyUnpublishing'),
				this.locale.getString('common.yes'),
				this.locale.getString('common.cancel'));

			unpublishingConfirmation.result.then(() => {
				this.unpublish(hierarchy);
			});
		} else {
			this.unpublish(hierarchy);
		}
	}

	private unpublish(hierarchy: Hierarchy): void {
		hierarchy.publicationState = HierarchyPublicationState.UNPUBLISHING;

		this.globalNotificationService.addSuccessNotification(
			this.locale.getString('organization.startedClassificationUnpublishing', {hierarchyName: hierarchy.name}));

		this.promises.loadingHierarchiesBackground = this.organizationApiService
			.unpublishHierarchy(hierarchy.id)
			.then(this.reloadList, this.reloadList)
			.then(() => {
				this.globalNotificationService.addSuccessNotification(
					this.locale.getString('organization.finishedClassificationUnpublishing', {hierarchyName: hierarchy.name}));
			});
	}

	isPublishedHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isPublishedHierarchy(hierarchy);

	isUnsynchronizedHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isUnsynchronizedHierarchy(hierarchy);

	isUnpublishedHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isUnpublishedHierarchy(hierarchy);

	isClassifyingHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isClassifyingHierarchy(hierarchy);

	isClassifiedHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isClassifiedHierarchy(hierarchy);

	isReplacementFailedHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isReplacementFailedHierarchy(hierarchy);

	isUnpublishingHierarchy = (hierarchy: Hierarchy) => OrganizationUtils.isUnpublishingHierarchy(hierarchy);

	isHierarchyEnrichmentEnabled = () => this.authorization.hasManageOrganizationsAccess();

	hierarchicalFiltering = (hierarchy: Hierarchy): boolean => {
		return this.isImpliedFilterEnabled && !isEmpty(hierarchy.hierarchicalFiltering)
			? hierarchy.hierarchicalFiltering
			: OrganizationUtils.isClassifiedHierarchy(hierarchy);
	}

	changeHierarchicalFiltering = (hierarchy: Hierarchy): void => {
		if (!this.isImpliedFilterEnabled || OrganizationUtils.isClassifiedHierarchy(hierarchy)) {
			return;
		}
		this.organizationApiService.changeHierarchicalFiltering(hierarchy.id, hierarchy.hierarchicalFiltering);
	}

	createVersion = (hierarchy: Hierarchy) => {
		this.promises.loadingHierarchiesBackground = this.organizationApiService
			.createVersion(hierarchy.id)
			.then(this.reloadList, this.reloadList);
	}

	replaceOriginal = (hierarchy: Hierarchy) => {
		let originalHierarchy = _.find(this.organizations, {id: hierarchy.originalHierarchyId});

		if (_.isUndefined(originalHierarchy)) {
			this.organizationApiService.getOrgHierarchy(hierarchy.originalHierarchyId).then((resp) => {
				this.openReplaceVersionDialog(resp.data, hierarchy);
			});
		} else {
			this.openReplaceVersionDialog(originalHierarchy, hierarchy);
		}
	}

	openReplaceVersionDialog = (originalHierarchy: Hierarchy, hierarchyVersion: Hierarchy) => {
		if (this.DISALLOWED_REPLACE_STATES.contains(originalHierarchy.publicationState)) {
			this.cbDialogService.warning(
				this.locale.getString('organization.replaceOriginalHeader',
					{hierarchyName: originalHierarchy.name}),
				this.locale.getString('organization.replaceOriginalInvalidState'));
			return;
		}
		let dialog = this.cbDialogService.confirm(
			this.locale.getString('organization.replaceOriginalHeader',
				{hierarchyName: originalHierarchy.name}),
			this.locale.getString('organization.replaceOriginalBody'));

		dialog.result.then(() => {
			this.promises.loadingHierarchiesBackground = this.organizationApiService
				.replaceOriginal(hierarchyVersion.id)
				.then(this.reloadList, this.reloadList);
		});

	}

	renameHierarchy = (currentHierarchy: Hierarchy) => {
		let addHierarchyDialog = this.$uibModal.open({
			component: 'organizationNameDialog',
			resolve: {
				headerText: () => this.locale.getString('organization.renameOrgHierarchy', {hierarchyName: currentHierarchy.name}),
				bodyText: () => this.locale.getString('organization.newNameLabel'),
				hierarchy: currentHierarchy
			}
		});
		addHierarchyDialog.result.then((newHierarchyName: string) => {
			this.organizationApiService.renameOrganizationHierarchy(currentHierarchy.id, newHierarchyName)
				.then(this.reloadList, this.reloadList);
		});
	}


	// returns all menu options, then we pick the ones we actually need/want
	private getAllMenuOptions = (organization: Hierarchy) => {
		return {
			EDIT: {
				text: this.locale.getString('common.edit'),
				name: 'edit',
				func: () => this.openUploadWizard(organization)
			},
			ENABLE: {
				text: this.locale.getString('common.enable'),
				name: 'enable',
				func: () => {
					organization.active = !organization.active;
					this.orgActivation(organization);
				}
			},

			DISABLE: {
				text: this.locale.getString('common.disable'),
				name: 'disable',
				func: () => {
					organization.active = !organization.active;
					this.orgActivation(organization);
				}
			},

			RENAME: {
				text: this.locale.getString('common.rename'),
				name: 'rename',
				func: () => this.renameHierarchy(organization)
			},

			DELETE: {
				text: this.locale.getString('common.delete'),
				name: 'delete',
				func: () => this.removeOrg(organization)
			},

			PUBLISH: {
				text: this.locale.getString('organization.publish'),
				name: 'publish',
				func: () => this.publishHierarchy(organization)
			},

			REPUBLISH: {
				text: this.locale.getString('organization.republish'),
				name: 'republish',
				func: () => this.republishHierarchy(organization)
			},

			UNPUBLISH: {
				text: this.locale.getString('organization.unpublish'),
				name: 'unpublish',
				func: () => this.unpublishHierarchy(organization)
			},

			CREATE_VERSION: {
				text: this.locale.getString('organization.createVersion'),
				name: 'create_version',
				func: () => this.createVersion(organization)
			},
			REPLACE_ORIGINAL: {
				text: this.locale.getString('organization.replaceOriginal'),
				name: 'replace_original',
				func: () => this.replaceOriginal(organization)
			},
			VIEW_DEPENDENCIES: {
				text: this.locale.getString('common.dependencies'),
				name: 'view_dependencies',
				func: () => this.viewHierarchyDependencies(organization)
			},

			APPLY_UPDATE: {
				text: this.locale.getString('organization.applyUpdate'),
				name: 'apply_uodate',
				func: () => this.applyUpdate(organization)
			},

			DISCARD_UPDATE: {
				text: this.locale.getString('organization.discardUpdate'),
				name: 'discard_uodate',
				func: () => this.discardUpdate(organization)
			}
		};
	}

	private getMenuOptions = (organization: Hierarchy) => {
		let menuOptions: Array<{ text?: string, name: string, func: () => void, isDivider?: boolean }> = [];
		let allMenuOptions = this.getAllMenuOptions(organization);

		if (!organization.editable) {
			return menuOptions;
		}

		if (this.uploadWizardEnabled()) {
			menuOptions.push(allMenuOptions.EDIT);
		}

		if (organization.hasUpdate) {
			// separate set of actions when staged update is present for a hierarchy.
			if (organization.hasUpdateReady) {
				menuOptions.push(allMenuOptions.APPLY_UPDATE);
			}
			menuOptions.push(allMenuOptions.DISCARD_UPDATE);
			this.addDivider(organization, menuOptions);
			menuOptions.push(organization.active ? allMenuOptions.DISABLE : allMenuOptions.ENABLE);
			this.addDependenciesOptions(organization, menuOptions);
		} else {
			if (OrganizationUtils.isUnpublishedHierarchy(organization) && !organization.originalHierarchyId) {
				menuOptions.push(allMenuOptions.RENAME);
			}

			if (OrganizationUtils.isActiveAllowed(organization)) {
				menuOptions.push(organization.active ? allMenuOptions.DISABLE : allMenuOptions.ENABLE);
				this.addDependenciesOptions(organization, menuOptions);
				if (this.isHierarchyPublishingEnabled() && !OrganizationUtils.isDynamicFilteringHierarchy(organization)
					&& !OrganizationUtils.isClassifyingHierarchy(organization)) {
					this.addDivider(organization, menuOptions);

					if (OrganizationUtils.isUnpublishedHierarchy(organization)) {
						menuOptions.push(allMenuOptions.PUBLISH);
					} else if (!this.hasOngoingProcess(organization)) {
						menuOptions.push(allMenuOptions.REPUBLISH);
						menuOptions.push(allMenuOptions.UNPUBLISH);
					}
				}
			} else {
				this.addDependenciesOptions(organization, menuOptions);
			}

			if (!this.uploadWizardEnabled() && !this.hasOngoingProcess(organization) &&
				!OrganizationUtils.isDynamicFilteringHierarchy(organization)) {
				if (organization.active && !organization.originalHierarchyId
					&& !_.find(this.organizations, {originalHierarchyId: organization.id})) {
					menuOptions.push(allMenuOptions.CREATE_VERSION);
				}

				if (organization.originalHierarchyId) {
					menuOptions.push(allMenuOptions.REPLACE_ORIGINAL);
				}
			}
		}

		this.addDivider(organization, menuOptions);
		menuOptions.push(allMenuOptions.DELETE);
		return menuOptions;
	}

	private hasOngoingProcess = (organization: Hierarchy): boolean => {
		return OrganizationUtils.isHierarchyPublishing(organization)
			|| OrganizationUtils.isUnpublishingHierarchy(organization)
			|| OrganizationUtils.isClassifyingHierarchy(organization);
	}

	private addDependenciesOptions = (organization, menuOptions): void => {
		const allMenuOptions = this.getAllMenuOptions(organization);
		this.addDivider(organization, menuOptions);
		menuOptions.push(allMenuOptions.VIEW_DEPENDENCIES);
		this.addDivider(organization, menuOptions);
	}

	private addDivider = (organization, menuOptions): void => {
		if (menuOptions.length > 0 && menuOptions[menuOptions.length - 1].name !== MenuOptionType.DIVIDER) {
			menuOptions.push(MenuDivider);
		}
	}

	showOrganizationOptions = (event, organization) => {
		this.contextMenuTree.show(event, organization, this.getMenuOptions(organization), '', 360);
	}

	structureFileUploaded = (org: Hierarchy) => OrganizationUtils.structureFileUploaded(org);

	filtersFileUploaded = (org: Hierarchy) => OrganizationUtils.filtersFileUploaded(org);

	usersFileUploaded = (org: Hierarchy) => OrganizationUtils.usersFileUploaded(org);

	enrichmentsFileUploaded = (org: Hierarchy) => OrganizationUtils.enrichmentsFileUploaded(org);

	getChangesPendingLabel = (org: Hierarchy) => {
		return org.hasUpdate ? this.locale.getString('common.yes') : this.locale.getString('common.no');
	}

	reloadListForStatus = (org: Hierarchy): void => {
		if (this.hasOngoingProcess(org)) {
			this.refreshHierarchyPublicationStatus(org);
		}
	}

	groupProcessingInProgress = (org: Hierarchy) => {
		return org.groupsProcessingInProgress;
	}

	getUsersHintTitle = (org: Hierarchy) => {
		if (this.groupProcessingInProgress(org)) {
			return this.locale.getString('organization.groupCreationInProgress');
		}
		return '';
	}

	isDynamicFilteringEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.DYNAMIC_FILTERING_HIERARCHY);
	}

	getOrgTypeLabel = (org: Hierarchy): string => {
		if (org.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING) {
			return this.locale.getString('organization.dynamicFilteringType');
		}
		return this.locale.getString('organization.classicType');
	}

	getOrgHierarchyUIStatus = (org: Hierarchy): OrgHierarchyUIStatus => OrganizationUtils.getOrgHierarchyUIStatus(org);
}

app.component('organizationsTable', {
	bindings: {
		reloadList: '&',
		promises: '=',
		pagination: '<',
		organizations: '<',
		isHierarchyPublishingEnabled: '<',
		isHierarchyPublishing: '<'
	},
	controller: OrganizationsTableComponent,
	templateUrl: 'partials/organizations/organizations-table.component.html'
});
