import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

@Component({
	selector: 'book-edit-tooltip',
	template: `
<dashboard-preview-tooltip
	*ngIf="dashboard"
	class="d-flex justify-center p-absolute"
	style="top: -32px"
	[dashboard]="dashboard">
</dashboard-preview-tooltip>`,
})
export class BookEditTooltipComponent implements ITooltipAngularComp {
	dashboard: Dashboard;

	agInit(params: { type: string } & ITooltipParams): void {
		this.dashboard = params.data;
	}
}
