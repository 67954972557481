import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'acsSummary'
})
export class ACSSummaryPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return value;
		// Regex looks for headers in the ACS summary respons (ie. Contact Reason:)
		const headerRegex = /(\b[A-Za-z ]+):/g;
		const headers = value.match(headerRegex);

		if (headers) {
			headers.forEach(header => {
				const boldHeader = `<b>${header.trim()}</b>`;
				value = value.replace(header, boldHeader);
			});
		}
		return value.split('\n').map(v => `<p>${v}</p>`).join('');
	}
}
