import * as _ from 'underscore';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ScorecardCriteriaSortOrder, ScorecardSortCategory, ScorecardCriteriaFilter } from '@app/modules/scorecards/scorecard-criteria-sort.service';
import { UIOption } from '@clarabridge/unified-angular-components';

export interface IntelligentScoringPreference {
	sortCategory: ScorecardSortCategory;
	sort: ScorecardCriteriaSortOrder;
	filterCategory: ScorecardSortCategory;
	filter: ScorecardCriteriaFilter;
}

export enum ScorecardResultColumn {
	CRITERIA = 'criteria', RESULT = 'result'
}

@Component({
	selector: 'explorer-scorecard-sort',
	templateUrl: './explorer-scorecard-sort.component.html',
	styles: [`.filter-statement { width: 280px; }`]
})
export class ExplorerScorecardSortComponent implements OnInit {
	@Input() column: ScorecardResultColumn;
	@Output() onSelectedChange = new EventEmitter<IntelligentScoringPreference>();
	@Input() selectedValue: IntelligentScoringPreference;

	sortCategories: UIOption<string>[];
	sortOptions;

	filterCategories: UIOption<string>[];
	filterOptions;

	constructor(
		private locale: CxLocaleService) {
	}

	ngOnInit(): void {
		this.sortCategories = [
			{ displayName: this.locale.getString('scorecards.criteria'), value: 'criteria' },
			{ displayName: this.locale.getString('scorecards.result'), value: 'result' }
		];

		this.sortOptions = {
			criteria: [
				{ displayName: this.locale.getString('scorecards.model'), value: ScorecardCriteriaSortOrder.NONE },
				{ displayName: this.locale.getString('common.name'), value: ScorecardCriteriaSortOrder.CRITERIA_NAME_ASCENDING },
				{ displayName: this.locale.getString('scorecards.weightLabel'), value: ScorecardCriteriaSortOrder.CRITERIA_WEIGHT_DESCENDING }
			],
			result: [
				{ displayName: this.locale.getString('scorecards.failed'), value: ScorecardCriteriaSortOrder.RESULT_FAILED },
				{ displayName: this.locale.getString('scorecards.passed'), value: ScorecardCriteriaSortOrder.RESULT_PASSED },
				{ displayName: this.locale.getString('scorecards.rebuttals'), value: ScorecardCriteriaSortOrder.RESULT_REBUTTALS }
			]
		};

		this.filterCategories = Object.assign([], this.sortCategories);

		this.filterOptions = {
			criteria: [
				{ displayName: this.locale.getString('scorecards.showAll'), value: ScorecardCriteriaFilter.NONE },
				{ displayName: this.locale.getString('scorecards.autoFail'), value: ScorecardCriteriaFilter.CRITERIA_AUTO_FAILED },
				{ displayName: this.locale.getString('scorecards.considered'), value: ScorecardCriteriaFilter.RESULT_CONSIDERED },
				{ displayName: this.locale.getString('scorecards.notConsidered'), value: ScorecardCriteriaFilter.RESULT_IGNORED },
				{ displayName: this.locale.getString('scorecards.weighted'), value: ScorecardCriteriaFilter.CRITERIA_WEIGHTED }
			],
			result: [
				{ displayName: this.locale.getString('scorecards.failed'), value: ScorecardCriteriaFilter.RESULT_FAILED },
				{ displayName: this.locale.getString('scorecards.passed'), value: ScorecardCriteriaFilter.RESULT_PASSED },
				{ displayName: this.locale.getString('scorecards.rebuttals'), value: ScorecardCriteriaFilter.RESULT_REBUTTALS }
			]
		};
	}

	selectSortCategory(category: ScorecardSortCategory): void {
		if (this.selectedValue.sortCategory === category) {
			return;
		}
		this.selectedValue.sortCategory = category;
		this.selectedValue.sort = ScorecardCriteriaSortOrder.NONE;
		this.onSelectedChange.emit(this.selectedValue);
	}

	setSort(sortOption: ScorecardCriteriaSortOrder): void {
		this.selectedValue.sort = sortOption;
		this.onSelectedChange.emit(this.selectedValue);
	}

	isCriteriaSort(): boolean {
		return this.column === ScorecardResultColumn.CRITERIA && [ScorecardCriteriaSortOrder.CRITERIA_NAME_ASCENDING,
			ScorecardCriteriaSortOrder.CRITERIA_NAME_DESCENDING,
			ScorecardCriteriaSortOrder.CRITERIA_WEIGHT_ASCENDING,
			ScorecardCriteriaSortOrder.CRITERIA_WEIGHT_DESCENDING].contains(this.selectedValue.sort);
	}

	isResultSort(): boolean {
		return this.column === ScorecardResultColumn.RESULT && [ScorecardCriteriaSortOrder.RESULT_FAILED,
				ScorecardCriteriaSortOrder.RESULT_PASSED,
				ScorecardCriteriaSortOrder.RESULT_REBUTTALS].contains(this.selectedValue.sort);
	}

	isSortApplied(): boolean {
		return this.isCriteriaSort() || this.isResultSort();
	}

	getSortTitle() {
		return this.isSortApplied() ?
			this.locale.getString('common.appliedSort') :
			this.locale.getString('common.sortColumn');
	}

	getSelectedSortCategory = (): any => {
		return _.find(this.sortCategories, { value: this.selectedValue.sortCategory });
	}

	getSelectedSort = (): any => {
		return _.find(this.sortOptions[this.selectedValue.sortCategory], {value: this.selectedValue.sort});
	}

	selectFilterCategory(category: ScorecardSortCategory): void {
		if (this.selectedValue.filterCategory === category) {
			return;
		}
		this.selectedValue.filterCategory = category;
		this.selectedValue.filter = ScorecardCriteriaFilter.NONE;
		this.onSelectedChange.emit(this.selectedValue);
	}

	setFilter(filterOption: ScorecardCriteriaFilter): void {
		this.selectedValue.filter = filterOption;
		this.onSelectedChange.emit(this.selectedValue);
	}

	isCriteriaFilter(): boolean {
		return this.column === ScorecardResultColumn.CRITERIA && [ScorecardCriteriaFilter.CRITERIA_AUTO_FAILED,
			ScorecardCriteriaFilter.CRITERIA_WEIGHTED,
			ScorecardCriteriaFilter.RESULT_CONSIDERED,
			ScorecardCriteriaFilter.RESULT_IGNORED].contains(this.selectedValue.filter);
	}

	isResultFilter(): boolean {
		return this.column === ScorecardResultColumn.RESULT && [ScorecardCriteriaFilter.RESULT_FAILED,
				ScorecardCriteriaFilter.RESULT_PASSED,
				ScorecardCriteriaFilter.RESULT_REBUTTALS].contains(this.selectedValue.filter);
	}

	isFilterApplied(): boolean {
		return this.isResultFilter() || this.isCriteriaFilter();
	}

	getFilterTitle() {
		return this.isFilterApplied() ?
			this.locale.getString('scorecards.appliedFilter') :
			this.locale.getString('scorecards.filterColumn');
	}

	getSelectedFilterCategory = (): any => {
		return _.find(this.filterCategories, { value: this.selectedValue.filterCategory });
	}

	getSelectedFilter = (): any => {
		return _.find(this.filterOptions[this.selectedValue.filterCategory], {value: this.selectedValue.filter});
	}
}
