export class RestoreConfig {
	parameters: {[key: string]: string};

	constructor() {
		this.parameters = {};
	}

	addParam(name: string, value: string): RestoreConfig {
		this.parameters[name] = value;
		return this;
	}
}
