import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ImageApiService } from '@app/modules/widget-settings/content-widget/image/image-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { FileItem, FileUploader } from 'ng2-file-upload';

@Component({
	selector: 'image-gallery',
	templateUrl: './image-gallery.component.html',
	styles: [`
		.br-image-list {
			height: 350px;
		}

		.br-image-item {
			width: 145px;
			height: 143px;
			text-transform: none; /* override btn */
		}

		img {
			max-width: 100%;
			max-height: 80px;
			border: 1px solid var(--gray-600);
		}

		.br-image-progress {
			width: 100%;
			max-height: 80px;
		}

		.br-image-remove {
			position: absolute;
			right: 2px;
			top: 0;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGalleryComponent implements OnInit {

	readonly MAX_IMAGE_SIZE = 5 * Math.pow(2, 20); //5Mb
	readonly ALLOWED_EXTENSIONS = ['.gif', '.jpg', '.jpeg', '.png', '.svg'];

	@Input() properties: ContentWidgetProperties;
	@Input() dashboardId: number;

	url: string;
	fileUploader: FileUploader;

	images: string[];
	loadingItem: {
		progress?: number;
		name?: string;
	};
	promiseLoading: Promise<any>;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private imageApiService: ImageApiService,
		private cxDialogService: CxDialogService,
		@Inject('urlService') private urlService: UrlService,
		@Inject('security') private readonly security: Security,
	) {}

	ngOnInit() {
		this.url = this.urlService.getAPIUrl('rest/image/' + this.dashboardId);

		this.images = [];
		this.promiseLoading = this.imageApiService.getImageList(this.dashboardId).then((names) => {
			this.images = names;
			this.ref.markForCheck();
		});

		this.initializeUploader();
	}

	initializeUploader = (): void => {
		this.fileUploader = new FileUploader({
			url: this.url,
			itemAlias: 'file',
			filters: [{
				name: 'img',
				fn: (item, options) => {
					let isValidExtension = _.any(this.ALLOWED_EXTENSIONS, (extension) => {
						return item?.name?.toLowerCase().endsWith(extension);
					});

					return isValidExtension
						&& item.type
						&& item.type.indexOf('image') === 0
						&& item.size <= this.MAX_IMAGE_SIZE;
				}
			}],
			headers: [{ name: 'ma-id', value: this.security.getMasterAccountId().toString() }],
		});

		this.fileUploader.onWhenAddingFileFailed = (item, filter, options) => {
			this.cxDialogService.warning(this.locale.getString('common.error'),
				item.size <= this.MAX_IMAGE_SIZE
					? this.locale.getString('common.unsupportedFileFormat')
					: this.locale.getString('organization.exceedMaxSize'));
			this.ref.detectChanges();
		};

		this.fileUploader.onAfterAddingFile = (fileItem: FileItem) => {
			this.loadingItem = {
				progress: 0,
				name: fileItem.file.name
			};
			if (this.images.indexOf(fileItem.file.name) >= 0) {
				this.images.remove(fileItem.file.name);
			}
			fileItem.upload();
			this.ref.detectChanges();
		};

		this.fileUploader.onProgressItem = (fileItem: FileItem, progress: number) => {
			if (this.loadingItem)
				this.loadingItem.progress = progress;
			this.ref.detectChanges();
		};

		this.fileUploader.onSuccessItem = (fileItem: FileItem, response, status, headers) => {
			this.loadingItem = null;
			this.images.insert(0, fileItem.file.name);
			this.properties.imageName = fileItem.file.name;
			this.properties.imageUrl = '';
			this.ref.detectChanges();
		};

		this.fileUploader.onErrorItem = (fileItem: FileItem, response, status, headers) => {
			this.loadingItem = null;
			this.cxDialogService.warning(this.locale.getString('common.error'),
				status === 400 ? response : this.locale.getString('common.internalServerError'));
			this.ref.detectChanges();
		};
	}

	getImageUrl(image: string): string {
		return `${this.url}/${encodeURIComponent(image)}`;
	}

	removeImage(image: string): void {
		this.promiseLoading = this.imageApiService.deleteImage(this.dashboardId, image).then(() => {
			this.images.remove(image);
		});
	}

	selectImage(image: string): void {
		this.properties.imageName = image;
		this.properties.imageUrl = '';
	}

}
