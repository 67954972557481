import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';

export class AlertCaseSettings {
	createCase: boolean;
	multiCases?: boolean;
	inboxTemplateId?: number;
	topicId?: number;
	title?: string;
	priority: CasePriority;
	description?: string;
	assigneeObject?: Assignee;
}