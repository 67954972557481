
export const RedirectDestinationValues = {
	USER_VOICE: {
		url: 'rest/security/redirect/ideas',
		name: 'uservoice'
	},
	ANALYZE: {
		url: 'rest/security/redirect/analyze',
		name: 'designer'
	},
	ENGAGOR: {
		url: 'rest/security/redirect/engagor',
		name: 'social'
	},
	NARRATIVE: {
		url: 'rest/security/redirect/narrative',
		name: 'narrative'
	},
	CONNECTORS: {
		url: 'rest/security/redirect/connectors',
		name: 'connectors'
	},
	DOWNLOAD_FILE: {
		url: 'rest/file/download',
		name: 'downloadFile'
	},
	STREAM_FILE: {
		url: 'rest/file/stream',
		name: 'streamFile'
	}
};

export class RedirectDestinations {
	static valuesArray(): Array<{url: string, name: string}>  {
		let retArray = [];

		for (let key in RedirectDestinationValues) {
			if (RedirectDestinationValues[key]) {
				retArray.push(RedirectDestinationValues[key]);
			}
		}

		return retArray;
	}

	static findDestination(name: string): {url: string, name: string} {
		let matchedValue = this.valuesArray().filter(destination => destination.name === name);
		return matchedValue.length ?
			matchedValue[0] :
			undefined;
	}
}
