<div>
	<div class="dropdown-header">
		<div class="hierarchy-search p-8">
			<div>
				<label for="input-dashboard-hierarchy-search">{{'dashboard.searchHierarchy'|i18n}}</label>
				<clearable-input id="input-dashboard-hierarchy-search"
					class="flex-fill hierarchy-search-box"
					[ngModel]="personalization.hierarchySearchText"
					[debounce]="500"
					(debouncedChange)="onSearchChange($event)"
					(onClear)="clearHierarchySearch()"
					placeholder="{{'common.search'|i18n}}">
				</clearable-input>
			</div>
		</div>

		<div class="d-flex justify-between align-items-center pb-4 ph-8">
			<p class="text-0_875rem mb-0" [textContent]="personalization.currentHierarchyName"></p>

			<div *ngIf="personalization.hasMultipleHierarchyPlacements()"
					id="explicit-node-selection"
					class="d-flex align-items-center justify-end text-default ml-8">
				<i
					class="q-icon-triangle-up select-previous-node"
					(click)="selectPreviousExplicitNode()"
					attr.title="{{'common.previous'|i18n}}"
					attr.aria-label="{{'common.previous'|i18n}}"></i>
				<i
					class="ml-4 q-icon-triangle-down select-next-node"
					(click)="selectNextExplicitNode()"
					attr.title="{{'common.next'|i18n}}"
					attr.aria-label="{{'common.next'|i18n}}">
				</i>
				<cb-inline-help left>
					<help-body>{{'organization.navigationHelpText'|i18n}}</help-body>
				</cb-inline-help>
			</div>
		</div>
	</div>
	<div class="hierarchy-list" [ngClass]="{'multiple-root-nodes': isMultipleRootNodes()}" *ngIf="personalization.hierarchyLoaded">
		<hierarchy-group
			[groupLevel]=1
			displayAttribute="name"
			[personalization]="personalization"
			[nodes]="personalization.getHighestLevelVisibleChildren()"
			[selectedNode]="personalization.currentHierarchyNode"
			(nodeClick)="onNodeClick($event)"
			[showContext]="showOrganizationContext">
		</hierarchy-group>
	</div>
	<div *ngIf="showOrganizationContext" class="hierarchy-context ph-8 pt-8 text-0_875rem">
		{{getHierarchyPath()}}
	</div>
	<div *ngIf="!personalization.hierarchyLoaded">
		<div class="loader"></div>
	</div>
</div>
