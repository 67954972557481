export class QualtricsIntegration {
	enabled: boolean;
	aliasName: string;
	brandId: string;
	datacenter: string;
	loginUrl: string;

	authSettings: QualtricsAuthSettings;
	userSyncEnabled: boolean;
	enforceXmAuthenticationEnabled: boolean;
	ticketLink: string;
	ticketingEnabled: boolean;
	ticketingApplicationId: number;

}

export class QualtricsAuthSettings {
	ssoProviderName: string;
	apiProviderName: string;
}

export class QualtricsApiIntegration {
	enabled: boolean;
	providerId: number;
}
