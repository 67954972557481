import { Component, OnInit, Inject, ChangeDetectionStrategy, Input } from '@angular/core';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { GridsterEvent, WidgetEvent } from '@app/core/cx-event.enum';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

@Component({
	selector: 'pattern-fills-toggle',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div (keydown.enter)="toggle($event)" (click)="clickToggle($event)"
		[attr.aria-checked]="patternFills"
		[attr.tabindex]="!disabled ? 0 : undefined" class="kb-focusable">
	<div class="dropdown-item pv-4 d-flex justify-between align-items-center">
		{{'dashboard.applyPatternFills'|i18n}}
		<div style="pointer-events: none" class="d-flex-inline">
			<cb-toggle
				class="ml-4"
				[label]="'common.toggleObject'|i18n:{objectName: 'dashboard.applyPatternFills'|i18n}"
				[attr.tabindex]="-1"
				[(ngModel)]="patternFills"
				[disabled]="disabled"
				(ngModelChange)="applyChange()">
			</cb-toggle>
		</div>
	</div>
</div>`
})

export class PatternFillsToggleComponent implements OnInit {

	@Input() disabled: boolean;
	patternFills: boolean;

	constructor(
		private userPropertiesApiService: UserPropertiesApiService,
		@Inject('security') private security: Security,
		private eventBus: GlobalEventBus
	) {}

	ngOnInit(): void {
		this.patternFills = !!this.security.loggedUser.patternFills;
	}

	toggle(event: any): void {
		if (this.disabled)
			return;
		KeyboardUtils.intercept(event);
		this.patternFills = !this.patternFills;
		this.applyChange();
	}

	clickToggle(event: any): void {
		if ($(event.target).hasClass('dropdown-item')) {
			this.toggle(event);
		}
	}

	applyChange(): void {
		this.userPropertiesApiService.updateProperties({ pattern_fills: this.patternFills })
			.then(() => {
				this.security.loggedUser.patternFills = this.patternFills;
				this.notifyGridster();
			});
	}

	private notifyGridster(): void {
		this.eventBus.broadcast(GridsterEvent.RESET_DRAG);
		this.eventBus.broadcast(WidgetEvent.RELOAD);
	}
}
