import { Directive, ElementRef, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

/*
 * Has AngularJS twin
 */
@Directive({
	selector: '[pwCheck]',
	providers: [{provide: NG_VALIDATORS, useExisting: PwCheckDirective, multi: true}]
})
export class PwCheckDirective implements Validator, OnChanges {
	@Input('pwCheck') toMatch: string;
	@Output('pwCompareChange') pwCompareChange = new EventEmitter<void>();

	private onChange: () => void;

	constructor(private el: ElementRef) { 
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value !== this.toMatch) {
			return {pwmatch: true};
		}
		return null;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onChange = fn;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.toMatch) {
			if (this.onChange) this.onChange();
			this.pwCompareChange.emit();
		}
	}
}