import { IDirectiveFactory, INgModelController } from 'angular';

/**
 * 2-way binding between content editable element and model
 */
const contentEditableDirective = ($timeout: ng.ITimeoutService) => {
	return {
		require: 'ngModel',
		scope: {
			positionSelector: '=cbContenteditable', // move cursor to the end of this element on each update
			calculateMarkupsApplied: '&'
		},
		link: (scope, elm, attrs, ngModel: INgModelController) => {
			// view -> model
			elm.bind('blur', () => {
				$timeout(() => {
					ngModel.$setViewValue(elm.html());
				});
			});

			elm.bind('keyup', (event) => {
				$timeout(() => {
					scope.calculateMarkupsApplied();
					ngModel.$setViewValue(elm.html());
				});
			});

			elm.bind('mouseup', (event) => {
				$timeout(() => {
					scope.calculateMarkupsApplied();
				});
			});

			// model -> view
			ngModel.$render = () => {
				elm.html(ngModel.$viewValue);
				restoreSelection(); // move caret to the end
			};

			// load init value from DOM
			ngModel.$setViewValue(elm.html());

			function restoreSelection(): void {
				let range, selection;
				if (document.createRange) {
					range = document.createRange();
					range.selectNodeContents(elm[0]);

					if (scope.positionSelector) {
						let after = $(elm).find(scope.positionSelector)[0];
						if (after) {
							range.setStartAfter(after);
							range.setEndAfter(after);
						}
					} else {
						range.collapse(false);
					}
					selection = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
				}
			}
		}
	};
};

app.directive('cbContenteditable', contentEditableDirective as IDirectiveFactory);
