import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges,
	SimpleChanges, Inject, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CxLocaleService } from '@app/core';
import { WidgetsLayout } from '@app/modules/home-page/home-page-common/entities/widgets-layout';
import { HomePageWidgetUtilsService } from '@app/modules/home-page/home-page-common/home-page-widget-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction, WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageReportProperties } from '@app/modules/home-page/home-page-common/entities/home-page-report-properties';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { WidgetSettingsModalService } from '@app/modules/widget-settings/services/widget-settings-modal.service';

interface ILayoutOption {
	name: string;
	value: WidgetsLayout;
}

@Component({
	selector: 'home-page-widgets-config',
	templateUrl: './home-page-widgets-config.component.html',
	styles: [`
		.widgets-layout {
			height: 342px;
		}
		.drag-container {
			flex: 1;
			cursor: move;
		}
		.cdk-drag-preview {
			box-sizing: border-box;
			border-radius: 4px;
			box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
			0 8px 10px 1px rgba(0, 0, 0, 0.14),
			0 3px 14px 2px rgba(0, 0, 0, 0.12);
		}
		.cdk-drag-placeholder {
			opacity: 0;
		}
		.cdk-drag-animating {
			transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
		}
		.widgets-layout.cdk-drop-list-dragging .drag-container:not(.cdk-drag-placeholder) {
			transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageWidgetsConfigComponent implements OnInit, OnChanges {

	readonly CONTAINER_ID = 'homePageEdit';
	readonly WIDGET_LIMIT = WidgetsLayout.FOUR;

	@Input() homePage: HomePage;
	@Input() reportProperties: HomePageReportProperties; // this is required to trigger change detection
	@Output() onChange = new EventEmitter<boolean>();

	dashboardData: IDashboardData;
	loading: Promise<any>;
	loaded = false;
	layoutOptions: ILayoutOption[];
	widgetMode = WidgetDisplayMode.HOME_PAGE;
	previousLayout: number;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly homePageWidgetUtils: HomePageWidgetUtilsService,
		private readonly ref: ChangeDetectorRef,
		private readonly currentObjects: CurrentObjectsService,
		private readonly widgetSettingsModalService: WidgetSettingsModalService,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		@Inject('security') private readonly security: Security,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
	) { }

	ngOnInit(): void {
		if (!this.homePage.widgetsConfig.layout) {
			this.homePage.widgetsConfig.layout = WidgetsLayout.TWO;
		}
		this.fillWidgetsWithNull();
		this.previousLayout = this.homePage.widgetsConfig.layout;

		this.layoutOptions = this.getLayoutOptions();
		this.initDashboard();
	}

	ngOnChanges(change: SimpleChanges) {
		if (change.reportProperties) {
			this.initDashboard();
		}
	}

	getWidgetActions(widget: Widget): IWidgetActions {
		return {
			canEditDashboard: () => true,
			emit: (event: WidgetAction) => {
				switch (event) {
					case WidgetAction.DELETE: {
						let index = this.homePage.widgetsConfig.widgets.indexOf(widget);
						this.homePage.widgetsConfig.widgets[index] = undefined;
						this.onChange.emit(true);
						this.ref.markForCheck();
						return;
					}
					case WidgetAction.UPDATE: return this.onChange.emit(true);
					case WidgetAction.RENAME: return this.onChange.emit(true);
					case WidgetAction.OPEN_SETTINGS:
						return this.openWidgetSettings(widget, this.homePage.widgetsConfig.widgets.indexOf(widget));
				}
			}
		};
	}

	onLayoutChange() {
		let layout = this.homePage.widgetsConfig.layout;
		if (!this.previousLayout || layout >= this.previousLayout) {
			this.previousLayout = layout;
			return;
		}

		let configuredWidgets = _.filter(this.homePage.widgetsConfig.widgets, (widget) => !!widget);
		let numberOfNulls = layout - configuredWidgets.length;
		if (numberOfNulls > 0) {
			//if more configured than layout, insert nulls at index
			let i = 0;
			while (numberOfNulls && i < this.homePage.widgetsConfig.widgets.length) {
				if (!this.homePage.widgetsConfig.widgets[i]) {
					numberOfNulls--;
					configuredWidgets.splice(i, 0, null);
				}
				i++;
			}
		}
		this.homePage.widgetsConfig.widgets = configuredWidgets.slice(0, layout);
		this.fillWidgetsWithNull();
		this.previousLayout = layout;
		this.ref.markForCheck();
	}

	private fillWidgetsWithNull() {
		if (!this.homePage.widgetsConfig.widgets) {
			this.homePage.widgetsConfig.widgets = [];
		}
		let start = this.homePage.widgetsConfig.widgets.length;
		this.homePage.widgetsConfig.widgets.length = this.WIDGET_LIMIT;
		this.homePage.widgetsConfig.widgets.fill(null, start, this.WIDGET_LIMIT);
	}

	onCollapseChange(collapsed: boolean) {
		if (!collapsed)
			this.initDashboard(); // redraw widget
	}

	private initDashboard() {
		this.loaded = false;
		this.loading = this.homePageWidgetUtils.getDashboardData(this.homePage, true).then(dashboardData => {
			this.dashboardData = dashboardData;
			this.currentObjects.setEditMode(true);
			this.currentWidgets.setWidgets(this.CONTAINER_ID,
				_.filter(this.homePage.widgetsConfig.widgets, widget => !!widget), // don't need original array while editing home page
				this.dashboardData.dashboardHistory);
			this.widgetsEditService.reset();
			this.loaded = true;
			this.ref.markForCheck();
		});
	}

	private getLayoutOptions(): ILayoutOption[] {
		return [{
			name: this.locale.getString('homePage.twoWidgetView'),
			value: WidgetsLayout.TWO
		}, {
			name: this.locale.getString('homePage.threeWidgetView'),
			value: WidgetsLayout.THREE
		}, {
			name: this.locale.getString('homePage.fourWidgetView'),
			value: WidgetsLayout.FOUR
		}];
	}

	onDrop(event: CdkDragDrop<Widget[]>): void {
		moveItemInArray(this.homePage.widgetsConfig.widgets, event.previousIndex, event.currentIndex);
		this.onChange.emit(true);
	}

	createWidget(index: number, widget: Widget) {
		widget.containerId = this.CONTAINER_ID;
		widget.properties.runAs = this.security.getEmail();
		widget.properties.contentProviderId = this.dashboardData.dashboard.properties.cbContentProvider;
		widget.properties.accountId = this.dashboardData.dashboard.properties.cbAccount;
		widget.properties.project = this.dashboardData.dashboard.properties.project;
		widget.properties.workspaceProject = this.dashboardData.dashboard.workspaceProject;
		widget.id = HomePageWidgetConstants.TEMP_WIDGET_ID - index; // to allow running within settings
		this.openWidgetSettings(widget, index);
	}

	private openWidgetSettings(widget: Widget, index: number): void {
		this.widgetSettingsModalService.openSettings(widget).then(updatedWidget => {
			this.homePage.widgetsConfig.widgets[index] = updatedWidget;
			this.onChange.emit(true);
		});
	}

}
