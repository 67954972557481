import { PreviewSentence, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';

export class ConversationSentence extends PreviewSentence {	
	channel?: string;
	timestamp?: number;
	endTimestamp?: number;
	wordCount?: number;
	sentenceId?: string;
	plotBandId?: string;
	events?: { click: () => void };
	select?: (enable?: boolean, accumulate?: boolean) => void;
	isSameChannelAsLast?: boolean;

	getTooltip?: (data: ConversationDataPoint, enrichment: ConversationEnrichment[]) => string;
	isSilence?: boolean;
	isSelected?: boolean;
	highlightAdjacent?: boolean; // redundant for new ui
	isFiltered?: boolean;
	participantIndex?: number;

	groupedIds?: number[];

	sentenceTopics?: SentenceTopic[];
	mergedSentences?: number[];
}