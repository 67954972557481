import * as _ from 'underscore';

import IAlertSubscriptionTemplate from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { CaseTitleMode } from '@cxstudio/alert-subscription-templates/types/case-title-mode.enum';
import { SubscriptionTemplateTitleVariable, TitleVariableType } from '@cxstudio/alert-subscription-templates/types/subscription-template-title-variable';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { SortUtils } from '@app/shared/util/sort-utils';
import { CaseTitle } from '@cxstudio/engagor/engagor-create-case-request-document';

export class InboxTemplateTitleUtils {

	static prepareTitleVariablesToView(template: IAlertSubscriptionTemplate): void {
		let title = template.caseSettings.customTitle;
		let titleVariables = template.caseSettings.titleVariables;
		if (title && titleVariables) {
			titleVariables.forEach(variable => title = title.replaceAll(variable.identifier, variable.label));
			template.caseSettings.customTitle = title;
		}
	}

	static prepareTitleVariablesToSave(template: IAlertSubscriptionTemplate, caseTitleVariables: SubscriptionTemplateTitleVariable[]): void {
		let text = template.caseSettings.customTitle;
		if (text && template.caseSettings.titleMode === CaseTitleMode.CUSTOM) {
			let title = this.prepareTitleToSave({text, variables: caseTitleVariables});
			template.caseSettings.customTitle = title.text;

			if (title.variables) {
				template.caseSettings.titleVariables = title.variables;
			}
		}
	}

	static prepareTitleToSave(title: CaseTitle): CaseTitle {
		let text = title.text;
		let variables = title.variables.filter(
			variable => text.contains(this.getVariableDisplayText(variable)));
		variables.forEach(variable => text = text.replaceAll(variable.label, variable.identifier));
		return {text, variables};
	}

	static getVariableDisplayText(variable: SubscriptionTemplateTitleVariable): string {
		return '{' + variable.label + '}';
	}

	static getAttributeTitleVariables(template: IAlertSubscriptionTemplate): SubscriptionTemplateTitleVariable[] {
		let comparator = SortUtils.getAlphanumericComparator((row: any) => row.label, SortDirection.ASC);
		let allAttributes = [].concat(template.caseMetadata.attributes).concat(template.mentionMetadata.attributes);
		let attributeVariables = allAttributes
			.map(attribute => ({
				type: TitleVariableType.ATTRIBUTE,
				identifier: attribute.name,
				label: attribute.displayName
			}))
			.sort(comparator);
		return _.uniq(attributeVariables, true, (variable) => variable.identifier);
	}

	static getMetricAlertTitleVariables(): SubscriptionTemplateTitleVariable[] {
		let variables = [
			'alertName', 'alertTrigger', 'alertCalculation',
			'alertCriteria', 'alertThreshold', 'alertGroupValues'
		];
		return variables.map<SubscriptionTemplateTitleVariable>(variable => {
			return {
				type: TitleVariableType.METRIC_ALERT,
				identifier: variable,
				label: variable
			};
		});
	}
}
