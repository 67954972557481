import { ConversationChannelService } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';

export type Phrase = {
	text: string;
	speaker: string;
};

export enum ACSSpeaker {
	CUSTOMER = 'Customer',
	AGENT = 'Agent',
}

export enum SpeakerIdentifier {
	CLIENT_IDENTIFIER = 'client',
	AGENT_IDENTIFIER = 'agent',
}

export class AutomatedSummariesUtils {
	static getConversationPhrases(document: ConversationDocument): Phrase[] {
		const conversationPhrases: Phrase[] = document.sentences.map((sentence: ConversationSentence) => {
			let speaker = 'Unknown';
			let participant = ConversationChannelService.getParticipant(sentence);
			if (participant.type === SpeakerIdentifier.CLIENT_IDENTIFIER) {
				speaker = ACSSpeaker.CUSTOMER;
			} else if (participant.type === SpeakerIdentifier.AGENT_IDENTIFIER) {
				speaker = ACSSpeaker.AGENT;
			}
			 return {
				text: sentence.text,
				speaker
			};
		});
		return conversationPhrases;
	}
	
}
