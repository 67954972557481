import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { UserSearchAPI } from '@app/modules/user-administration/search-panel/user-search-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

export class BulkCustomFieldEntry {
	labelName: string;
}

export class BulkCustomFieldOutput {
	newFieldValue: string;
}

@Component({
	selector: 'bulk-custom-field',
	templateUrl: './bulk-custom-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkCustomFieldComponent implements OnInit {
	@Input() input: BulkCustomFieldEntry;
	newFieldValue: string;
	loading = {} as any;
	constructor(
		private modal: NgbActiveModal,
		private userSearchAPI: UserSearchAPI,
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.newFieldValue = '';
	}

	valueSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(search => this.userSearchAPI.getCustomFieldsSuggestion(search))
		);
	}

	isSaveDisabled = (): boolean => {
		return !this.newFieldValue;
	}

	save = (): void => {
		this.modal.close(this.newFieldValue);
	}

	cancel = (): void => {
		this.modal.dismiss();
	}

	getModalTitle = (): string => {
		return this.locale.getString('administration.updateBulkLabels', {customField: this.input.labelName});
	}

}
app.directive('bulkCustomFieldUpdateDialog', downgradeComponent({ component: BulkCustomFieldComponent }) as angular.IDirectiveFactory);
