import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { User } from '../users/entities/user';

export default class Group {
	groupId: number;
	groupName: string;
	permission?: any;
	description?: string;
	ownerEmail?: string;
	owner?: User;
	type?: GroupType;
	users?: User[];
	usersCount?: number;
	selected?: boolean;
	permissions?: any[];
}
