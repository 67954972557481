<li class="br-hierarchy-node" 
	[attr.node-name]="node.name" 
	[attr.selected]="selectedNode.id == node.id ? true : null"
	[ngClass]="{
		'node-hierarchy-collapsed': !node.expanded, 
		'node-hierarchy-expanded': node.expanded, 
		hidden: node.hidden || isEmptyFolder(node), 
		leaf: !(node.children && node.children.length)
	}">
	<a href="javascript:void(0)"
		[ngClass]="{'cursor-not-allowed': node.disabled}"
		(keydown)="onNodeKeydown($event)">
		<div *ngIf="isNeedToggle(node)"
			(click)="expandNode(node)">
			<span [attr.node-name]="node.name"
				class="mr-8 br-hierarchy-node-toggle cursor-pointer"
			></span>
			<i class="structure-top-line"></i>
			<i class="structure-bottom-line"></i>
			<i class="structure-side-line"></i>
		</div>
		<i *ngIf="!isNeedToggle(node)"
			class="structure-lines"
			[ngClass]="{'last-leaf': isLast}" 
		></i>
		<span [attr.node-name]="node.name" 
			(click)="onNodeClick(node)" 
			class="br-hierarchy-node-name"
			title="{{nodePath(node)}}" 
			[ngStyle]="{'max-width': getNodeWidth(node) }">
			{{getNodeDisplay(node)}}
		</span>
	</a>
	<hierarchy-group 
		*ngIf="node.expanded && !!node.children"
		[showContext]="showContext" 
		[groupLevel]="groupLevel + 1" 
		[nodes]="node.children" 
		[selectedNode]="selectedNode"
		(nodeClick)="onNodeClick($event)" 
		[displayAttribute]="displayAttribute" 
		[personalization]="personalization"
		(mouseleave)="mouseLeave($event)"
		(mouseover)="mouseOver($event)">
	</hierarchy-group>
</li>
