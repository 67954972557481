import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';

export class Project {
	id: number;
	name: string;
	accessLevel?: ProjectAccessLevelValue;

	constructor(
		id: number,
		name: string
	) {
		this.id = id;
		this.name = name;
	}
}
