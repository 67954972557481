import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ConfidentialitySettings } from '@cxstudio/dashboards/entity/dashboard-properties';
import { downgradeInjectable } from '@angular/upgrade/static';
import {
	DrillHelperService,
	IDrillTargetType
} from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';

@Injectable({
	providedIn: 'root'
})
export class PreviewDrillService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly drillHelperService: DrillHelperService
	) {}

	getPreviewOption(params: IDrillParams): IDrillTargetType {
		const drillToHelper = this.drillHelperService.create(params);

		return drillToHelper.getDrillToANPreview();
	}

	getDocExplorerOption(params: IDrillParams): IDrillTargetType {
		const drillToHelper = this.drillHelperService.create(params);

		return drillToHelper.getDrillToAnDocExplorer();
	}

	getFullPageOption(params: IDrillParams): IDrillTargetType {
		const drillToHelper = this.drillHelperService.create(params);

		return drillToHelper.getDrillToFullPageDocExplorer();
	}

	getFeedbackOption(params: IDrillParams, point: DrillPoint): any {
		const confidentialitySettings: ConfidentialitySettings = params.dashboardProperties.confidentiality;
		if (confidentialitySettings.enabled && point.volume < confidentialitySettings.commentsThreshold) {
			return {
				obj: 'an_doc_explorer',
				name: 'an_doc_explorer',
				levels: 1,
				disabled: true,
				text: this.locale.getString('widget.feedbackPreview'),
				label: this.locale.getString('widget.feedbackDrillConfidentialityWarning')
			};
		}

		const preview = this.getPreviewOption(params);
		preview.text = this.locale.getString('widget.feedbackDrill');

		const docExplorer = this.getDocExplorerOption(params);
		docExplorer.default = true;
		docExplorer.text = this.locale.getString('widget.docExplorerDrill');

		const items = [docExplorer, preview];
		if (!params.editMode) {
			items.unshift(this.getFullPageOption(params));
		}

		return {
			obj: 'an_doc_explorer',
			name: 'an_doc_explorer',
			items,
			levels: 1,
			text: this.locale.getString('widget.feedbackPreview')
		};
	}
}

app.service('previewDrill', downgradeInjectable(PreviewDrillService));
