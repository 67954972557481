<form [formGroup]="hierarchySettings">
	<div class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					class="mr-0"
					id="customRestrictionsEnabled"
					name="customRestrictionsEnabled"
					label="{{'mAccount.customRestrictions'|i18n}}"
					formControlName="customRestrictionsEnabled"
					[buttonClass]="'btn-sm'">
				</checkbox-button>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'mAccount.nodesMaxCount'|i18n}}
				<cb-inline-help>
					<help-body>{{'mAccount.nodesMaxCountHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input type="number" class="form-control" name="nodesMaxCount"
					formControlName="nodesMaxCount">
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'mAccount.filterMaxLength'|i18n}}
				<cb-inline-help>
					<help-body>{{'mAccount.filterMaxLengthHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input type="number" class="form-control" name="filterMaxLength"
					formControlName="filterMaxLength">
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'mAccount.parentRuleInheritanceLimit'|i18n}}
				<cb-inline-help>
					<help-body>{{'mAccount.parentRuleInheritanceLimitHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input type="number" class="form-control" name="parentRuleInheritanceLimit"
					formControlName="parentRuleInheritanceLimit">
			</div>
		</div>
	</div>
</form>
