<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.oauthEditExternalProviderDialogTitle'|i18n">
</modal-header>

<div class="modal-body edit-external-application-modal">
	<form class="form-horizontal" #externalProviderForm="ngForm">
		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAliasName'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="aliasName"
					#aliasName="ngModel"
					required
					[(ngModel)]="externalProvider.aliasName">

				<p *ngIf="aliasName.invalid && aliasName.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthClientId'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="clientId"
					#clientId="ngModel"
					required
					[(ngModel)]="externalProvider.clientId">

				<p *ngIf="clientId.invalid && clientId.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthClientSecret'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="clientSecret"
					#clientSecret="ngModel"
					required
					[(ngModel)]="externalProvider.clientSecret">

				<p *ngIf="clientSecret.invalid && clientSecret.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthScope'|i18n}}</label>
			<div class="col-md-8">
				<tags-input class="w-100-percent" name="scope"
					[(ngModel)]="externalProvider.scope"
					[minLength]="1"
					[addOnBlur]="true"
					[addOnEnter]="true">
				</tags-input>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAuthorizationCodeUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="authorizationCodeUri"
					#authorizationCodeUri="ngModel"
					required
					[(ngModel)]="externalProvider.authorizationCodeUri">

				<p *ngIf="authorizationCodeUri.invalid && authorizationCodeUri.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthCreateTokenUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="createTokenUri"
					#createTokenUri="ngModel"
					required
					[(ngModel)]="externalProvider.createTokenUri">

				<p *ngIf="createTokenUri.invalid && createTokenUri.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group" title="{{'administration.oauthDeprecatedField'|i18n}}">
			<label class="control-label col-md-3" disabled>{{'administration.oauthCheckTokenUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					[(ngModel)]="externalProvider.checkTokenUri"
					name="checkTokenUri" disabled>
			</div>
		</div>

		<div class="row form-group" title="{{'administration.oauthDeprecatedField'|i18n}}">
			<label class="control-label col-md-3" disabled>{{'administration.oauthAccessTokenName'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					[(ngModel)]="externalProvider.accessTokenParameterName"
					name="accessTokenParameterName" disabled>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthUserInfoUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					[(ngModel)]="externalProvider.userInfoUri"
					autocomplete="off"
					name="userInfoUri"
					#userInfoUri="ngModel">

				<p *ngIf="userInfoUri.invalid && userInfoUri.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthApplicationKind'|i18n}}</label>
			<div class="col-md-8">
				<simple-dropdown
					[options]="applicationKinds"
					[(value)]="externalProvider.applicationKind">
				</simple-dropdown>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelIdentityConfig'|i18n}}</label>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthUserIdentityField'|i18n}}</label>
			<div class="col-md-3">
				<input class="form-control"
					autocomplete="off"
					name="userIdentityField"
					#userIdentityField="ngModel"
					required
					[(ngModel)]="externalProvider.userIdentityField">

				<p *ngIf="userIdentityField.invalid && userIdentityField.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthUserSearchField'|i18n}}</label>
			<div class="col-md-3">
				<input class="form-control"
					[(ngModel)]="externalProvider.userSearchField"
					autocomplete="off"
					name="userSearchField">
			</div>
		</div>
	</form>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="!isSettingsValid()">
</save-modal-footer>
