import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { NumberFormatSettings } from '../settings.interfaces';


export class NumberFormatSettingsGroup implements ISettingsGroup<NumberFormatSettings> {

	settings: NumberFormatSettings;
	config;
	defaultFormatterSettings;

	constructor(
		source: NumberFormatSettings,
		defaultFormatterSettings
	) {
		this.settings = source
			? cloneDeep(source)
			: cloneDeep(defaultFormatterSettings);

		this.config = {
			showCalculationType: this.showCalculationType
		};

		this.defaultFormatterSettings = defaultFormatterSettings;
	}

	private showCalculationType(): boolean {
		return true;
	}

	postProcessSettings(): NumberFormatSettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = cloneDeep(this.defaultFormatterSettings);
	}

}
