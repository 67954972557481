export class TimelineItem {
	value: number;
	percentage: number;
	label: string;
	visible: boolean;

	static empty(): TimelineItem {
		return new TimelineItem(0, undefined, undefined, undefined);
	}

	constructor(value: number, percentage: number, label: string, visible: boolean) {
		this.value = value;
		this.percentage = percentage;
		this.label = label;
		this.visible = visible;
	}
}
