import { CxHttpService } from '@app/core';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ImageApiService {
	constructor(
		private readonly http: CxHttpService
	) {}

	getImageList(dashboardId: number): Promise<string[]> {
		return this.http.get(`rest/image/list/${dashboardId}`);
	}

	deleteImage(dashboardId: number, image: string): Promise<void> {
		return this.http.delete(`rest/image/${dashboardId}/${encodeURIComponent(image)}`);
	}

}
