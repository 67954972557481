export enum PermissionGroup {
	DASHBOARD = 'dashboard', 
	OBJECT = 'object',
	GROUP = 'group',
	USER = 'user',
	MASTER_ACCOUNT = 'masterAccount', 
	CASE_MANAGEMENT = 'caseManagement', 
	INTERACTION = 'interaction', 
	CONNECTORS = 'connectors',
	NARRATIVE = 'narrative'
}