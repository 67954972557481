import { Security } from '@cxstudio/auth/security-service';

import * as moment from 'moment';
import { Interval } from '@cxstudio/interval/interval.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CapsLockUtils, CapsLockDetection } from '@app/modules/keyboard-navigation/caps-lock-utils.class';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { EmailUtils } from '@app/modules/user-administration/editor/email-utils.class';
import { LoginMessage } from '@app/modules/user/login-message';
import { ConfigService } from '@app/core/config.service';
import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';
import { AppPage } from '@app/util/app-page';
import { UrlService } from '@cxstudio/common/url-service.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { SingleLoginService } from './single-login.service';
import { RouteService } from '@cxstudio/services/route-service';
import { LoginFlow } from './login-flow';

interface CodeRequestParams {
	responseType: string;
	clientId: string;
	redirectUri: string;
	scope: any;
}

interface IWindow extends Window {
	encodeURIComponent: (stringToConvert: string) => string;
}

enum LoginState {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset_password',
	NEW_PASSWORD = 'new_password'
}

declare let window: IWindow;

export class LoginComponentController implements ng.IController {
	readonly SESSION_EXPIRE_DAYS: number = 30;
	readonly RESET_PASSWORD_PARAM: string = 'resetPassword';
	readonly RESET_PASSWORD_CODE_PARAM: string = 'resetPasswordCode';
	readonly NO_LINK_URL = '#/login';

	messages: string[] = [
		'incorrectPassword', 'lockedAccount', 'expiredPassword', 'authenticationFailed',
		'hasNoMasterAccount', 'defaultMADisabled', 'accessBlocked', 'externalLoginError',
		'noAccessToTargetApplication'
	];

	user: { password?: string; login?: string } = {};
	isLoading: boolean;
	propertiesLoaded: boolean;
	loginInfoMessage: LoginMessage;
	loginErrorMessage: LoginMessage;
	errorMessage = '';
	errorParams: any = {};
	submitting = false;
	rememberEmail = false;
	resetEmail: string;

	login: any; // login form

	graphic = '';
	currentYear = moment().tz('US/Eastern').year();

	capsLockActive: boolean = false;
	capsLockHandler: CapsLockDetection;
	isPopup: boolean;
	state: LoginState = LoginState.LOGIN;
	ariaEmailInvalid = false;
	ariaPasswordInvalid = false;

	constructor(
		private $scope: ng.IScope,
		private $rootScope: ng.IRootScopeService,
		private $log: ng.ILogService,
		private $location: ng.ILocationService,
		private $cookies,
		private $timeout: ng.ITimeoutService,
		private $element: ng.IAugmentedJQuery,
		private interval: Interval,
		private security: Security,
		private urlService: UrlService,
		private securityApiService: SecurityApiService,
		private singleLoginService: SingleLoginService,
		private routeService: RouteService,
		private authenticationService: AuthenticationService,
		private locale: ILocale,
		private $routeParams,
		private globalNotificationService: GlobalNotificationService,
		private environmentService: EnvironmentService,
		private userSessionApiService: UserSessionApiService,
		private configService: ConfigService,
		private $window: ng.IWindowService,
	) {}

	$onInit(): void {
		if (!!this.security.getContext()) {
			this.$window.location.reload();
			return;
		}
		this.configService.loadServerConfig().then((): void => {

			this.propertiesLoaded = true;

			if (this.environmentService.isPopupLogin() && this.security.isAuthenticated()) {
				this.closeAndReloadIframe();
				return;
			}

			this.graphic = this.urlService.getAPIUrl(`rest/image/background?r=${CONFIG.revision}`);
			this.init();
		});
	}

	loginPopup(): void {
		this.environmentService.openStudioLoginPopup();
	}

	$onDestroy(): void {
		this.removeCapsLockDetection();
	}

	usePopupLogin(): boolean {
		return this.environmentService.isIframe() && !this.environmentService.isUnderTest();
	}

	shouldAlignContentToTop(): boolean {
		const elementHeight = this.$element.height();

		return elementHeight > 450;
	}

	checkResetPassword(): void {
		if (this.$location.search()[this.RESET_PASSWORD_PARAM]) {
			let email;
			let searchVal = this.$location.search()[this.RESET_PASSWORD_PARAM];

			// if email address is provided and is valid format, prefill
			if (typeof searchVal === 'string' && EmailUtils.validate(searchVal)) {
				email = searchVal;
			}
			this.showResetPasswordForm(email);
		}
	}

	checkResetPasswordCode(): void {
		if (this.$location.search()[this.RESET_PASSWORD_CODE_PARAM]) {
			let resetCode = this.$location.search()[this.RESET_PASSWORD_CODE_PARAM];
			this.security.setPassCode(resetCode);
			this.showNewPasswordForm();
		}
	}

	init = (): void => {
		let email = this.$cookies.get('lastEmail');

		if (email) {
			this.rememberEmail = true;
			this.user.login = email.trim().replace(/"/g, '');
		}

		this.setLogoFocus();

		this.checkResetPassword();
		this.checkResetPasswordCode();
		this.initCapsLockDetection();
	}

	setFocus = (): void => {
		angular.element('input[type="password"]').trigger('focus');
	}

	setSubmitFocus = (): void => {
		angular.element('button[type="submit"]').trigger('focus');
	}

	setLogoFocus = (): void => {
		this.$timeout(() => {
			angular.element('app-logo a').trigger('focus');
		}, 100);
	}

	passwordValid = (): boolean => {
		return !_.isUndefined(this.user) && !_.isUndefined(this.user.password) && this.user.password.length > 0;
	}

	proceedUserLogin = (startupAppUrl?: string): void => {
		this.interval.startAllIntervals();
		this.handleLastEmail();
		this.security.setLoginFlow(LoginFlow.GENERAL);
		if (this.isUrlValid(startupAppUrl) && (!this.$rootScope.redirect || this.$rootScope.redirect === AppPage.INDEX)) {
			this.routeService.redirect(startupAppUrl);
		} else {
			this.$location.url(this.$rootScope.redirect ? this.$rootScope.redirect : AppPage.INDEX);
		}
	}

	private isUrlValid = (url?): boolean => {
		return url && typeof url === 'string' && this.urlService.isUrl(url);
	}

	redirectUserToExternalUrl = (externalRedirect): void => {
		this.securityApiService.getExternalRedirectUrl(externalRedirect).then((response) => {
			if (response.data.type === 'ENGAGOR') {
				return this.securityApiService.createTokenForEngagor().then((engagorTokenResp) => {
					return externalRedirect + '?jwt=' + engagorTokenResp.data.token;
				});
			}
			return response.data.url;
		})
		.then(targetUrl => this.routeService.redirect(targetUrl))
		.catch(this.proceedUserLogin);
	}

	getCodeGettingUrl = (): string => {
		return this.urlService.getCXStudioEndpoint() +
		`/security/code?
		response_type=${this.$routeParams.response_type}&client_id=${this.$routeParams.client_id}&redirect_uri=${this.$routeParams.redirect_uri}
		&scope=${this.$routeParams.scope}&state=${this.$routeParams.state}`;
	}

	getCodeRequestParams = (): CodeRequestParams => {
		let responseType: string = this.$routeParams.response_type;
		let clientId: string = this.$routeParams.client_id;
		let redirectUri: string = this.$routeParams.redirect_uri;
		let scope: any = this.$routeParams.scope;

		return {
			responseType,
			clientId,
			redirectUri,
			scope
		};
	}

	codeRequestLoginContainsAllRequiredParams = (): boolean => {
		// check only required parameters, state isn't
		let params: CodeRequestParams = this.getCodeRequestParams();

		return params.responseType && params.clientId && params.redirectUri && params.scope;
	}

	codeRequestLoginContainsSomeRequiredParams = (): boolean => {
		let params: CodeRequestParams = this.getCodeRequestParams();

		return params.responseType || params.clientId || params.redirectUri || params.scope;
	}

	loginWithCodeRequest = (): boolean => {
		// check only required parameters, state isn't
		let params: CodeRequestParams = this.getCodeRequestParams();

		return params.responseType && params.clientId && params.redirectUri && params.scope;
	}

	private closeAndReloadIframe(): void {
		window.opener.postMessage('ready', window.opener.origin);	// seems to fail silently if you don't pass anything
		window.close();
	}

	submit = (): void => {
		this.isLoading = true;
		this.$scope.$broadcast('autofill:update');
		this.submitting = true;
		this.errorMessage = '';
		this.errorParams = {};

		this.security.setContext(null);

		this.userSessionApiService.login(this.user).then((response) => {
			if (response.data.passwordExpired) {
				response.data = 'expiredPassword';
				this.handleLoginError(response);
				return;
			}
			this.singleLoginService.removeLogoutEvent();

			let responseData = response.data;
			if (responseData.token) {
				this.security.setToken(responseData.token);
			}

			if (responseData.accessToken) {
				this.security.setAccessToken(responseData.accessToken);

				if (responseData.expiresIn) {
					this.authenticationService.startRefreshAccessTokenWatcher(responseData.expiresIn);
				}
			}

			if (this.codeRequestLoginContainsAllRequiredParams()) {
				this.routeService.redirect(this.getCodeGettingUrl());
				return;
			} else if (this.codeRequestLoginContainsSomeRequiredParams()) {
				let notificationDuration = 10;
				this.globalNotificationService.showWarning(this.locale.getString('login.ssoRequiredParametersWarning'), notificationDuration);
			}

			if (this.environmentService.isPopupLogin()) {
				this.closeAndReloadIframe();
				return;
			}

			if (responseData.redirectionUrl) {
				this.proceedUserLogin(responseData.redirectionUrl);
				return;
			}

			let externalRedirect = this.$rootScope.externalRedirect;

			if (!isEmpty(externalRedirect)) {
				this.redirectUserToExternalUrl(externalRedirect);
				return;
			}

			this.proceedUserLogin(responseData.startupAppUrl);
		}, (resp)  => {
			this.handleLoginError(resp);
		});
	}

	private handleLoginError = (response): void => {
		this.isLoading = false;
		let data = response.data;
		let status = response.status;
		let headers = response.headers;

		// Erase the token if the user fails to log in
		this.security.setToken(undefined);

		this.$log.debug('Failed login');
		this.$log.debug(data);

		if (status === 404 || status === 0) {
			this.errorMessage = 'login.serverUnavailable';
		} else {
			if (headers('error')) {
				data = headers('error');
			}

			if (this.messages.indexOf(data) !== -1) {
				this.errorMessage = 'login.' + data;
			} else {
				this.errorMessage = 'login.incorrectPassword';
			}

			if (headers('localization-params')) {
				this.errorParams = JSON.parse(headers('localization-params'));
			}
		}
		setTimeout(this.setSubmitFocus, 100);
		this.submitting = false;
	}

	showResetPasswordForm = (email: string): void => {
		this.resetEmail = email;
		this.state = LoginState.RESET_PASSWORD;
	}

	showNewPasswordForm = (): void => {
		this.state = LoginState.NEW_PASSWORD;
	}

	showLoginForm = (): void => {
		this.state = LoginState.LOGIN;
		this.$location.search(this.RESET_PASSWORD_PARAM, null);
		this.$location.search(this.RESET_PASSWORD_CODE_PARAM, null);
		this.setLogoFocus();
	}

	handleLastEmail = (): void => {
		if (this.rememberEmail) {
			let expires = new Date();
			expires.setTime(expires.getTime() + (this.SESSION_EXPIRE_DAYS * 24 * 60 * 60 * 1000));
			this.$cookies.put('lastEmail', this.user.login, { expires });
		} else {
			this.$cookies.remove('lastEmail');
		}
	}

	validateEmail = (): void => {
		const email = this.user.login;
		const isValid = EmailUtils.validate(email);
		this.ariaEmailInvalid = email === undefined || !isValid;
		this.login.email.$setValidity('login.email.$valid', EmailUtils.validate(email));
	}

	validatePassword = (): void => {
		const isValid = this.user.password?.length > 0;
		const isPasswordUndefined = this.user.password === undefined;
		this.ariaPasswordInvalid = isPasswordUndefined || !isValid;
		this.login.password.$setValidity('login.password.$valid', !this.ariaPasswordInvalid);
	}

	getErrorMessage = (): LoginMessage => {
		let newMessage: LoginMessage = null;
		if (this.errorMessage) {
			let message = this.locale.getString(this.errorMessage);
			if (this.errorMessage === 'login.incorrectPassword') {
				if (this.capsLockActive) {
					message += ' ' + this.locale.getString('login.capsLockIsOn');
				}
			}
			newMessage = {
				message,
				url: this.NO_LINK_URL
			};
		}

		if (!_.isEqual(this.loginErrorMessage, newMessage)) {
			this.loginErrorMessage = newMessage;
		}

		return this.loginErrorMessage;
	}

	hasMessageLink = (): boolean => {
		let message = this.loginInfoMessage;
		return message && message.url && message.url !== this.NO_LINK_URL;
	}

	getInfoMessage = (): LoginMessage => {
		let newMessage: LoginMessage = null;
		if (CONFIG.environment && CONFIG.environment.name) {
			newMessage = {
				message: this.locale.getString('login.environmentMessage', { name: CONFIG.environment.name }),
				url: this.NO_LINK_URL
			};
		} else if (CONFIG.login && CONFIG.login['custom.message']) {
			newMessage = {
				message: CONFIG.login['custom.message'],
				url: CONFIG.login['custom.url'] || this.NO_LINK_URL
			};
		}
		if (!_.isEqual(this.loginInfoMessage, newMessage)) {
			this.loginInfoMessage = newMessage;
		}

		return this.loginInfoMessage;
	}

	private initCapsLockDetection = (): void => {
		this.capsLockHandler = CapsLockUtils.onCapsLock(this.detectCapsLock);
	}

	private detectCapsLock = (state: boolean): void => {
		this.capsLockActive = state;
	}

	private removeCapsLockDetection = (): void => {
		CapsLockUtils.removeHandler(this.capsLockHandler);
	}

	getBackgroundColorClass = (): string | void => {
		if (this.graphic) {
			return 'bg-pewter-color-900';
		}
	}

	getBackgroundStyle = (): any => {
		if (this.graphic) {
			return {
				'background-image': `url('${this.graphic}')`,
				opacity: 0.2
			};
		}
	}

}


app.component('login', {
	controller: LoginComponentController,
	templateUrl: 'partials/login.component.html'
});
