<modal-header
	(cancel)="cancel()"
	[modalTitle]="modalTitle">
</modal-header>
<div class="modal-body" [ngBusy]="loading">
	<form [formGroup]="editForm">
		<div class="form-group">
			<label class="font-bold" for="ws-name" [i18n]="'administration.workspaceName'"></label>
			<input id="ws-name"
				type="text"
				formControlName="workspaceName"
				class="form-control">
			<form-error *ngIf="workspaceName.errors?.required"
				text="{{ 'administration.workspaceNameRequiredError'|i18n }}">
			</form-error>
			<form-error *ngIf="workspaceName.errors?.maxlength"
				text="{{ 'administration.workspaceNameMaxLengthError'|i18n }}">
			</form-error>
			<form-error *ngIf="workspaceName.errors?.unique"
				text="{{ 'administration.workspaceNameUniqueError'|i18n }}">
			</form-error>
		</div>
		<div class="form-group">
			<label class="font-bold" for="cp-name" [i18n]="'common.contentProvider'"></label>
			<input id="cp-name"
				type="text"
				[ngClass]="{ 'hidden': createMode }"
				formControlName="contentProviderName"
				class="form-control">
			<simple-dropdown
				*ngIf="createMode"
				[(value)]="input.workspace.contentProviderId"
				displayField="name"
				valueField="id"
				[options]="contentProviders"
				(onChange)="onContentProviderChange($event)">
			</simple-dropdown>
		</div>
		<div class="form-group">
			<label class="font-bold" for="account-name" [i18n]="'common.account'"></label>
			<input id="account-name"
				type="text"
				[ngClass]="{ 'hidden': createMode }"
				formControlName="accountName"
				class="form-control">
			<simple-dropdown
				*ngIf="createMode"
				[disabled]="!input.workspace.contentProviderId"
				[(value)]="input.workspace.accountId"
				displayField="accountName"
				valueField="accountId"
				[options]="cpAccounts"
				(onChange)="onAccountChange($event)">
			</simple-dropdown>
		</div>
		<form-error *ngIf="existError"
			text="{{ 'administration.workspaceExistError'|i18n }}">
		</form-error>
	</form>
</div>
<save-modal-footer
	[saveText]="saveButtonTitle"
	(save)="save()"
	cancelText="{{'common.cancel'|i18n}}"
	(cancel)="cancel()"
	[isDisabled]="isInvalid()"
></save-modal-footer>
