import { Input, Output, Inject, EventEmitter, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import WidgetSettingsService, { IWidgetSettings, IWidgetSettingsConfig } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

@Component({
	selector: 'data-overview-settings-step',
	templateUrl: './data-overview-settings-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataOverviewSettingsStepComponent implements OnInit, OnChanges {
	@Input() projectSelection: IProjectSelection;

	@Input() grouping: ReportGrouping;
	@Output() groupingChange = new EventEmitter<ReportGrouping>();

	@Input() calculation: ReportCalculation;
	@Output() calculationChange = new EventEmitter<ReportCalculation>();

	@Input() additionalMetrics: ReportCalculation[];
	@Output() additionalMetricsChange = new EventEmitter<ReportCalculation[]>();

	@Input() fixedAdditionalMetrics: ReportCalculation[];

	@Output() onSettingsLoaded = new EventEmitter<IWidgetSettings>();

	loading: Promise<any>;

	groupingOptions: INode[];
	calculationOptions: INode[];
	disabledCalculations: INode[];

	metrics: ReportCalculation[] = [];

	constructor(
		private ref: ChangeDetectorRef,
		private wizardStep: CxWizardStepComponent,
		private betaFeaturesService: BetaFeaturesService,
		@Inject('metricConstants') private metricConstants: MetricConstants,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('widgetSettingsService') private widgetSettingsService: WidgetSettingsService
	) {}

	ngOnInit(): void {
		this.wizardStep.setValid(false);
	}

	ngOnChanges(changes: SimpleChanges<DataOverviewSettingsStepComponent>): void {
		if (ChangeUtils.hasChange(changes.projectSelection)
			&& ProjectIdentifier.isProjectSelected(this.projectSelection)) {
				this.loading = this.reloadOptions();
		}
	}

	selectGrouping(node) {
		this.grouping = node;
		this.grouping.size = this.grouping.size || 10;
		this.groupingChange.emit(node);
		this.wizardStep.setValid(true);
	}

	selectCalculation(node) {
		this.calculation = node;
		this.calculationChange.emit(node);
	}

	private reloadOptions(): any {
		let config: IWidgetSettingsConfig = {
			withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
		};

		let widgetProperties = this.propertiesForProjectSelection(this.projectSelection);

		return this.widgetSettingsService.getWidgetSettings(widgetProperties, config)
				.then(settings => this.populateOptions(settings));
	}

	private propertiesForProjectSelection(projectSelection: IProjectSelection): WidgetProperties {
		return {
			contentProviderId: projectSelection.contentProviderId,
			accountId: projectSelection.accountId,
			project: projectSelection.projectId
		};
	}

	private populateOptions(settings: IWidgetSettings) {
		this.groupingOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
			.withModels(settings.models)
			.withAttributes(settings.attributes, MetricFilters.NOT_DATE)
			.build();

		this.calculationOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(this.metricConstants.getStandardCalculations())
			.withPredefinedMetrics(settings.predefinedMetrics)
			.withAttributes(settings.attributes, MetricFilters.CALCULATION)
			.withMetrics(settings.metrics, this.projectSelection.projectId)
			.withScorecardMetrics(settings.scorecardMetrics)
			.build();

		this.disabledCalculations = [ this.metricConstants.get().VOLUME ];

		this.onSettingsLoaded.emit(settings);
		this.ref.markForCheck();
	}

	additionalMetricChange() {
		this.additionalMetricsChange.emit(this.additionalMetrics);
	}
}