import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import AnalyticWidget from './analytic-widget.class';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';

export class TableWidget extends AnalyticWidget {
	static get(): TableWidget {
		return new TableWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new VisualProperties();
		this.name = WidgetType.TABLE;
		this.visualProperties.visualization = WidgetVisualization.CB_AN_TABLE;
		this.properties.widgetType = WidgetType.TABLE;
	}

	withCalculations(calculations: ReportCalculation[]): AnalyticWidget {
		this.properties.selectedMetrics = calculations;
		return this;
	}
}
