import * as _ from 'underscore';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { ChangeDetectorRef, Input, OnInit, Directive } from '@angular/core';
import { OAuthClientDetailsApiService } from './service/oauth-client-details-api.service';
import { OAuthAuthorizedGrantTypes } from './oauth-authorized-grant-type.factory';
import { OAuthAuthorityRole } from './oauth-authority-roles.factory';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import OAuthClientDetails from './oauth-client-details';
import { OauthClientDetailsApplicationKind } from './oauth-client-details-application-kind';
import { FormattedTableColumnsOauthClientsService } from '@app/shared/components/table/formatted-table-columns-oauth-clients.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';

export enum OAuthClientDialogMode {
	CREATE = 'CREATE',
	EDIT = 'EDIT'
}


@Directive()
export abstract class OAuthClientsComponent implements OnInit {

	@Input() masterAccounts: Array<MasterAccount>;

	loadingPromise: Promise<any>;
	clientDetailsList;
	columns;

	protected abstract getClientDetailsList(): Promise<OAuthClientDetails[]>;
	protected abstract openClientEditDialog(clientDetails: OAuthClientDetails, mode: OAuthClientDialogMode): Promise<any>;

	constructor(
		protected oauthClientDetailsApiService: OAuthClientDetailsApiService,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected contextMenuTree: ContextMenuTree,
		protected locale: CxLocaleService,
		protected ref: ChangeDetectorRef,
		protected applicationKind: OauthClientDetailsApplicationKind,
		protected formattedTableColumnsOauthClientsService: FormattedTableColumnsOauthClientsService,
		protected cbDialogService: CBDialogService) {
	}

	ngOnInit(): void {
		this.reloadClientDetails();
		this.columns = this.getColumns();
	}

	reloadClientDetails(): void {
		this.loadingPromise = this.getClientDetailsList()
			.then(clientDetailsList => {
				this.clientDetailsList = this.sortData(clientDetailsList);
			});
	}

	addClient(): void {
		let clientDetails: OAuthClientDetails = new OAuthClientDetails();

		this.loadingPromise = this.openClientEditDialog(clientDetails, OAuthClientDialogMode.CREATE)
			.then((clientDetailsData) => this.oauthClientDetailsApiService.createClientDetails(clientDetailsData))
			.then(() => this.reloadClientDetails());
	}

	editClient = (clientDetails: OAuthClientDetails): void => {
		if (clientDetails.broken) {
			return;
		}
		this.loadingPromise = this.openClientEditDialog(clientDetails, OAuthClientDialogMode.EDIT)
			.then((clientDetailsData) => this.oauthClientDetailsApiService.updateClientDetails(clientDetails.clientId, clientDetailsData))
			.then(() => this.reloadClientDetails());
		this.ref.markForCheck();
	}

	removeClient = (clientDetails: OAuthClientDetails): void => {
		const dialog = this.cbDialogService.danger(
			this.locale.getString('common.pleaseConfirm'),
			this.getRemoveWarningMessage(clientDetails),
			this.locale.getString('common.delete'),
			this.locale.getString('common.cancel'));

		dialog.result.then(() => {
			this.loadingPromise = this.oauthClientDetailsApiService.removeClientDetails(clientDetails.clientId)
				.then(() => this.reloadClientDetails());
			this.ref.markForCheck();
		});
	}

	private readonly enableClient = (clientDetails: OAuthClientDetails): void => {
		if (clientDetails.broken) {
			return;
		}
		this.loadingPromise = this.oauthClientDetailsApiService.enableClient(clientDetails.clientId)
			.then(() => this.reloadClientDetails());
		this.ref.markForCheck();
	}

	private readonly getDisableWarningMessage = (clientDetails: OAuthClientDetails): string => {
		if (clientDetails.authorities.contains(OAuthAuthorityRole.ROLE_AUTH_ADMIN)) {
			return this.locale.getString('administration.oauthClientDisableNoteAdmin', { name: clientDetails.clientId });
		}
		return this.locale.getString('administration.oauthClientDisableNote', { name: clientDetails.clientId });
	}

	private readonly getRemoveWarningMessage = (clientDetails: OAuthClientDetails): string => {
		if (clientDetails.authorities.contains(OAuthAuthorityRole.ROLE_AUTH_ADMIN)) {
			return this.locale.getString('administration.oauthClientDisableNoteAdmin', { name: clientDetails.clientId });
		}
		return this.locale.getString('administration.oauthClientRemoveNote', { name: clientDetails.clientId });
	}

	private readonly toggleClientEnabled = (clientDetails: OAuthClientDetails): void => {
		if (clientDetails.disabled) {
			this.enableClient(clientDetails);
		} else {
			this.disableClient(clientDetails);
		}
	}

	private readonly disableClient = (clientDetails: OAuthClientDetails): void => {
		if (clientDetails.broken) {
			return;
		}

		const dialog = this.cbDialogService.danger(
			this.locale.getString('common.pleaseConfirm'),
			this.getDisableWarningMessage(clientDetails),
			this.locale.getString('common.disable'),
			this.locale.getString('common.cancel'));

		dialog.result.then(() => {
			this.loadingPromise = this.oauthClientDetailsApiService.disableClient(clientDetails.clientId)
				.then(() => this.reloadClientDetails());
			this.ref.markForCheck();
		});
	}

	protected sortData(rawRows: OAuthClientDetails[]): OAuthClientDetails[] {
		const preliminarySorted = _.sortBy(rawRows, 'clientId');
		const brokenRows: OAuthClientDetails[] = [];
		const rows: OAuthClientDetails[] = [];

		preliminarySorted.forEach((element: any) => {
			if (element.broken) {
				brokenRows.push(element);
			} else {
				rows.push(element);
			}
		});

		return brokenRows.concat(rows);
	}

	protected onMenuClick = (object: OAuthClientDetails, event: any): void => {
		this.contextMenuTree.showObjectListMenu(event, object, this.getContextMenu(object), 'oauth-internal-clients', 360);
	}

	private getContextMenu(clientDetails: OAuthClientDetails): Array<ContextMenuItem<OAuthClientDetails>> {
		const OPTIONS = this.getOptions();

		const menuOptions: Array<ContextMenuItem<OAuthClientDetails>> = [];

		if (clientDetails.broken) {
			menuOptions.push(OPTIONS.DELETE);
			return menuOptions;
		}

		menuOptions.push(OPTIONS.EDIT);

		if (clientDetails.disabled) {
			menuOptions.push(OPTIONS.ENABLE);
			menuOptions.push(OPTIONS.DELETE);
		} else {
			menuOptions.push(OPTIONS.DISABLE);
		}

		return menuOptions;
	}

	private getOptions(): { [name: string]: ContextMenuItem<OAuthClientDetails> } {
		return {
			EDIT: {
				text: this.locale.getString('common.edit'),
				name: 'edit',
				func: (clientDetails: OAuthClientDetails) => this.editClient(clientDetails)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (clientDetails: OAuthClientDetails) => this.removeClient(clientDetails)
			},

			DISABLE: {
				name: 'disable',
				text: this.locale.getString('administration.disable'),
				func: (clientDetails: OAuthClientDetails) => this.disableClient(clientDetails)
			},

			ENABLE: {
				name: 'enable',
				text: this.locale.getString('administration.enable'),
				func: (clientDetails: OAuthClientDetails) => this.enableClient(clientDetails)
			}
		};
	}

	protected getColumns = (): Array<TableColumn<any>> => {
		const columns = [];
		columns.push(this.formattedTableColumnsOauthClientsService.getHamburgerColumn(this.onMenuClick));
		columns.push(this.formattedTableColumnsOauthClientsService.getEnabledColumn(this.toggleClientEnabled));
		columns.push(this.formattedTableColumnsOauthClientsService.getClientIdWithWarningColumn());
		if (this.applicationKind === OauthClientDetailsApplicationKind.CB_LINK) {
			columns.push(this.formattedTableColumnsOauthClientsService.getMasterAccountColumn(this.masterAccounts));
		}
		if (this.applicationKind !== OauthClientDetailsApplicationKind.CB_LINK) {
			columns.push(this.formattedTableColumnsOauthClientsService.getRegisteredRedirectUriColumn());
		}
		columns.push(this.formattedTableColumnsOauthClientsService.getAccessTokenValidityColumn());
		columns.push(this.formattedTableColumnsOauthClientsService.getRefreshTokenValidityColumn());
		columns.push(this.formattedTableColumnsOauthClientsService.getScopeColumn());
		columns.push(this.formattedTableColumnsOauthClientsService.getAuthorizedGrantTypesColumn());
		columns.push(this.formattedTableColumnsOauthClientsService.getSaveClientStateColumn());
		if (this.applicationKind === OauthClientDetailsApplicationKind.CX_SUITE) {
			columns.push(this.formattedTableColumnsOauthClientsService.getOauthLinksEnabledColumn());
			columns.push(this.formattedTableColumnsOauthClientsService.getOauthLinksNumberColumn());
		}

		return columns;
	}

}
