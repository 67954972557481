export enum WidgetVisualization {
	PREVIEW_BUBBLES = 'PREVIEW-BUBBLES',
	PREVIEW_TABLE = 'AN-PREVIEW-TABLE',
	VERBATIM_PREVIEW = 'AN-VERBATIM-PREVIEW',
	DOCUMENT_PREVIEW = 'DOCUMENT-PREVIEW',
	STACKEDPIE = 'STACKEDPIE',
	BUBBLE = 'BUBBLE',
	DUAL = 'DUAL',
	HEATMAP = 'HEATMAP',
	CLOUD = 'CLOUD',
	CLOUDWORDSTABLE = 'CLOUDWORDSTABLE',
	CB_AN_TABLE = 'CB_AN_TABLE',
	CB_AN_METRIC = 'CB_AN_METRIC',
	CB_AN_MAP = 'CB_AN_MAP',
	NETWORK = 'NETWORK',
	DEFINITION_TABLE = 'DEFINITION_TABLE',
	HIERARCHY_TREE = 'HIERARCHY_TREE',
	GAUGE = 'GAUGE',
	TEXT = 'TEXT',
	BUTTON = 'BUTTON',
	IMAGE = 'IMAGE',
	// label remains only because there are such widgets in the database which cannot be converted on backend
	// because it requires text editor to process html on UI
	LABEL = 'LABEL',
	VIDEO = 'VIDEO',
	QUALTRICS = 'QUALTRICS',
}

