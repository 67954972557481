import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { RandomUtils } from '@app/util/random-utils.class';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { SelectorWidgetVisualProperties } from '@cxstudio/reports/providers/cb/components/selector-widget-visualization-properties.class';
import { Subscription } from 'rxjs';

export interface PatternFillsOptions {
	id: string;
	pattern: string;
	color: string;
	backgroundColor: string;
}

export class SelectorWidgetButton implements ng.IComponentController {

	props: WidgetProperties;
	data;
	capitalizer: (name: string) => string;
	selectedButtons: any[];
	utils;
	dashboardBackgroundColor: string;
	visualProps: SelectorWidgetVisualProperties;
	demo: boolean;

	colors: {[key: string]: string};
	colorsOnHover: {[key: string]: string};
	hover: boolean;

	patternFillsOptions: PatternFillsOptions;

	customColorChangeSubscription: Subscription;

	constructor(
		private applicationThemeService: ApplicationThemeService,
		private chartAccessibilityService: ChartAccessibilityService,
		private realDataPreviewService: RealDataPreviewService,
	) {}

	$onInit = () => {
		this.setColors();

		if (this.demo && this.realDataPreviewService.showRealDataPreview(this.props?.widgetType)) {
			this.customColorChangeSubscription = this.realDataPreviewService.getPreviewUIChangeObserver().subscribe(() => {
				if (!this.realDataPreviewService.hasPreviewChanges()) {
					this.data.color = this.visualProps.customColor;
					this.setColors();
				}
			});
		}
	}

	private setColors(): void {
		this.colors = this.getColors();
		this.colorsOnHover = this.getColorsOnHover(this.colors);
		if (this.patternFillsApplied()) {
			this.initPatternFillsOptions();
		}
	}

	$onDestroy(): void {
		if (this.customColorChangeSubscription) {
			this.customColorChangeSubscription.unsubscribe();
		}
	}

	private initPatternFillsOptions(): void {
		let isDarkMode: boolean = this.applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER);

		this.patternFillsOptions = {
			id: `d3-pattern-${_.uniqueId()}-${RandomUtils.randomString()}`,
			pattern: this.data.color.pattern.path,
			color: this.data.color.pattern.color,
			backgroundColor: isDarkMode ? ColorConstants.DARK_MODE_WIDGET_BACKGROUND : ColorConstants.WHITE
		};
	}

	patternFillsApplied = (): boolean => {
		return this.chartAccessibilityService.isPatternFillEnabled();
	}

	getPatternFillUrl = (): string => {
		return `url(#${this.patternFillsOptions?.id})`;
	}

	getButtonName = (): string => {
		let nameProperty = this.props.selectedAttributes[0].identifier;
		let displayName = this.data.displayName || this.data[nameProperty];
		return this.capitalizer(displayName);
	}

	getColors = (): {backgroundColor: string, color: string} => {
		let dataColor = this.data.color;
		if (!dataColor) {
			return undefined;
		}
		let backgroundColor: string = _.isObject(dataColor)
			? dataColor.pattern.color
			: dataColor;

		let color: string = ColorUtilsHelper.pickContrastTextColor(backgroundColor);
		return { backgroundColor, color };
	}

	getColorsOnHover = (colors: {[key: string]: string}): {[key: string]: string} => {
		if (!colors) {
			return undefined;
		}
		let textColor = colors.backgroundColor.toLowerCase() === this.dashboardBackgroundColor.toLowerCase()
			? colors.color
			: colors.backgroundColor;
		return {
			backgroundColor: 'rgba(0, 0, 0, 0)',
			color: textColor,
			borderColor: textColor
		};
	}

	getButtonStyle = (): {[key: string]: string} => this.hover ? this.colorsOnHover : this.colors;
}

app.component('selectorWidgetButton', {
	bindings: {
		data: '<',
		props: '<',
		capitalizer: '<',
		utils: '<',
		dashboardBackgroundColor: '<',
		demo: '<',
		visualProps: '<'
	},
	controller: SelectorWidgetButton,
	template: `
		<div class="kb-focusable-inset d-flex"
			ng-style="$ctrl.getButtonStyle()"
			ng-class="{ 'justify-center ph-8': !$ctrl.patternFillsApplied() }"
			ng-mouseover="$ctrl.hover = true"
			ng-mouseleave="$ctrl.hover = false"
			tabindex="0">
			<svg ng-if="$ctrl.patternFillsApplied()" class="btn-pattern-fills ph-4">
  				<defs>
					<pattern
						id="{{$ctrl.patternFillsOptions.id}}"
						height="10"
						width="10"
						patternUnits="userSpaceOnUse">
 						<rect
							height="10"
							width="10"
							fill="{{$ctrl.patternFillsOptions.backgroundColor}}"
						></rect>
  						<path
							stroke="{{$ctrl.patternFillsOptions.color}}"
							d="{{$ctrl.patternFillsOptions.pattern}}"
							fill="none"
						></path>
					</pattern>
  				</defs>
				<circle
					class="btn-circle"
					cx="8"
					cy="8"
					r="8"
					fill="{{$ctrl.getPatternFillUrl()}}"
				></circle>
  			</svg>
			<span
				class="btn-name"
				ng-class="{ 'm-auto pr-8': $ctrl.patternFillsApplied() }"
				title="{{::$ctrl.getButtonName()}}">{{::$ctrl.getButtonName()}}
			</span>
		</div>`
});
