import { Inject, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { WizardTagMode } from '@app/modules/wizard/cx-wizard-tag/cx-wizard-tag.component';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { FileUploadResult } from '../upload/upload-result/file-upload-result';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import { FileUploadFailureDetails } from '@app/modules/cx-form/file-upload/file-upload.component';
import { ChangeUtils } from '@app/util/change-utils';
import { HierarchyPublicationState } from '@cxstudio/organizations/hierarchy-publication-state';
import { CxLocaleService } from '@app/core';
import { HierarchyWizardUtils } from './hierarchy-wizard-utils';


@Directive()
export abstract class HierarchyUploadWizardPageBaseComponent implements OnInit, OnChanges {

	@Input() hierarchy: Hierarchy;
	@Output() tagChange = new EventEmitter<string>();
	@Output() hierarchyUpdated = new EventEmitter<void>();

	loading: Promise<unknown>;
	readonly MediaType = MediaType;
	uploadResult: FileUploadResult;

	constructor(
		protected ref: ChangeDetectorRef,
		protected locale: CxLocaleService
	) {

	}

	abstract getFileName(): string;
	abstract getUpdateFileName(): string;

	ngOnInit(): void {
		this.uploadResult = FileUploadResult.empty();
		this.tagChange.emit(this.getStepStatus());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.hierarchy)) {
			this.tagChange.emit(this.getStepStatus());
		}
	}

	isUpdate(): boolean {
		return !!this.hierarchy && !!this.getFileName();
	}

	getUploadButtonLabel(): string {
		if (this.isUpdate()) {
			return this.locale.getString('organization.uploadNewFile');
		}
		return this.locale.getString('organization.uploadFile');
	}

	getStepStatus(): WizardTagMode {
		if (!this.hierarchy) {
			return;
		}

		if (this.firstTimeHierarchyEdit()) {
			return WizardTagMode.NO_CHANGES;
		}

		if (!this.hierarchy.hasUpdate) {
			if (this.getFileName()) {
				return WizardTagMode.COMPLETED;
			} else {
				return WizardTagMode.UPDATE_REQIURED;
			}
		} else {
			if (this.getUpdateFileName()) {
				return WizardTagMode.COMPLETED;
			} else {
				return WizardTagMode.UPDATE_REQIURED;
			}
		}
	}

	protected hierarchyHasModel(): boolean {
		return HierarchyWizardUtils.hierarchyHasModel(this.hierarchy);
	}

	protected firstTimeHierarchyEdit(): boolean {
		return this.hierarchyHasModel() && !this.hierarchy.hasUpdate;
	}

	protected isOriginalHierarchyComplete(): boolean {
		return !!this.hierarchy.structureFileName && !!this.hierarchy.filterFileName && !!this.hierarchy.userFileName;
	}

	handleSuccessfulUpload(event: any): void {
		this.uploadResult = FileUploadResult.empty();
		this.hierarchyUpdated.emit();
		this.ref.detectChanges();
	}

	handleFailedUpload(event: FileUploadFailureDetails): void {
		this.uploadResult = FileUploadResult.failed(event.headers, event.response, this.locale);
		this.tagChange.emit(WizardTagMode.ERROR);
		this.ref.detectChanges();
	}

	handleRemovedUpload(): void {
		this.uploadResult = FileUploadResult.empty();
		this.tagChange.emit(this.getStepStatus());
		this.ref.detectChanges();
	}

	showExistingStructureButton(): boolean {
		if (this.hierarchy.hasUpdate) {
			return !!this.getUpdateFileName();
		}
		return !!this.getFileName();
	}
}
