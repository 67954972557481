
export enum OrderableListItemType {
	BOUND_TO_TOP = 'BOUND_TO_TOP',
}

export class OrderableList {

	constructor(
		public list = [],
		private bound = {min: 0, max: 10},
		private countingFilter = (item) => true
	) {
	}

	contains = (item): boolean => {
		return this.list.contains(item);
	}

	addItem = (item): boolean => {
		let itemCountsTowardLimit = this.countingFilter(item);

		if (!this.violatesRules(item, itemCountsTowardLimit)) {
			if (this.isItemBoundToTop(item)) {
				let insertIndex = this.getBoundToTopCount();
				this.list.splice(insertIndex, 0, item);
			} else {
				this.list.push(item);
			}
			return true;
		}
		return false;
	}


	private violatesRules(item, itemCountsTowardLimit: boolean): boolean {
		if (this.isFull() && itemCountsTowardLimit) return true;

		return false;
	}

	addItemUnder = (item, under, skipFullCheck = false): boolean => {
		let itemCountsTowardLimit = this.countingFilter(item);

		if (skipFullCheck || !this.isFull() || !itemCountsTowardLimit) {
			let itemIndex = this.findIndexInList(under) + 1;
			this.list.splice(itemIndex, 0, item);
			return true;
		}
		return false;
	}

	removeItem = (item): void => {
		this.list.remove(item);
	}

	isFull = (): boolean => {
		let listLength = this.list.filter(this.countingFilter).length;
		return (listLength === this.bound.max);
	}

	setBound = (listLimits: {min: number, max: number}) => {
		this.bound = listLimits || {min: 0, max: 10};
	}

	canBeMovedUp = (item): boolean => {
		let itemIndex = this.findIndexInList(item);

		if (itemIndex > 0) {
			let isBoundToTopItem = this.isItemBoundToTop(item);
			let isTopSimpleItem = this.isSimpleItem(item) && this.isItemBoundToTop(this.list[itemIndex - 1]);
			return !isBoundToTopItem && !isTopSimpleItem;
		}
		return false;
	}

	canBeMoved = (item): boolean => {
		return item && (this.canBeMovedDown(item) || this.canBeMovedUp(item));
	}

	canBeMovedDown = (item): boolean => {
		let itemIndex = this.findIndexInList(item);

		if (itemIndex >= 0 && itemIndex < this.list.length - 1) {
			let isBoundToTopItem = this.isItemBoundToTop(item);
			return !isBoundToTopItem;
		}
		return false;
	}

	isPresent = (item): boolean => {
		let itemIndex = this.findIndexInList(item);
		return itemIndex >= 0;
	}

	isPresentByName = (item): boolean => {
		let itemIndex = this.findIndexInListByName(item);
		return itemIndex >= 0;
	}

	private getBoundToTopCount(): number {
		return this.list.filter((item) => {
			return this.isItemBoundToTop(item);
		}).length;
	}

	private findIndexInList(item): number {
		return _.findIndex(this.list, item);
	}

	private findIndexInListByName(name): number {
		return _.findIndex(this.list, (item) => {
			return item.name === name;
		});
	}

	private isItemBoundToTop(item): boolean {
		return item.selectionType === OrderableListItemType.BOUND_TO_TOP;
	}

	private isSimpleItem(item): boolean {
		return !this.isItemBoundToTop(item);
	}
}

