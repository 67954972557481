import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AbstractAssetParameters } from '@app/modules/asset-management/access/asset-parameters/abstract-asset-parameters';

export class QuickInsightsWidgetAssetParameters extends AbstractAssetParameters {
	static fromWidget(widget: Widget): QuickInsightsWidgetAssetParameters {
		return new QuickInsightsWidgetAssetParameters(widget.properties?.homePageId);
	}

	constructor(
		private homePageId: number
	) {
		super(AssetParametersType.QUICK_INSIGHT_WIDGET);
	}

	getHttpParams(): {[key: string]: string} {
		let params = { homePageId: this.homePageId.toString() };
		return _.extend(super.getHttpParams(), params);
	}
}
