export class ResizeHandlerUtils {
	static addResizeHandler(domElm: Element, callback: () => void): any | undefined {
		let resizeObserver: ResizeObserver;
		resizeObserver = new window.ResizeObserver(callback);
		resizeObserver.observe(domElm);
		return resizeObserver;
	}

	static removeResizeHandler(resizeObserver?: ResizeObserver): void {
		if (resizeObserver)
			resizeObserver.disconnect();
	}
}
