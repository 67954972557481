import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardListTabType } from './dashboard-list-tab-type.class';
import { DashboardListTab } from './dashboard-list-tab.class';

export class RecentDashboardsTab extends DashboardListTabType {
	static readonly TAB_NAME = 'recent';
	static readonly TAB_ICON = 'q-icon-time-machine';
	constructor() {
		super();
	}

	static getList(dashboards: Dashboard[]): Dashboard[] {
		return _.chain(dashboards)
			.filter(dashboard => !!dashboard.useDate && this.dashboardsOnly(dashboard))
			.sortBy('useDate')
			.reverse()
			.slice(0, 6)
			.value();
	}

	static getEmptyListHTML(localeService: CxLocaleService): string {
		return `
		<div class="d-inline-block ph-16 pv-8 text-center dashboard-preview-tile text-0_875rem">
			<span class="${this.TAB_ICON}" aria-hidden="true"></span>

			<p>${localeService.getString('dashboard.startExploring')}</p>
		</div>`;
	}

	static getTabName(localeService: CxLocaleService): string {
		return localeService.getString('dashboard.dashboardRecent');
	}

	static getTabId(): string {
		return 'recentHeader';
	}

	static getContentId(): string {
		return 'recentContent';
	}

	static is(tab: DashboardListTab): boolean {
		return tab.pinned && (tab.name === RecentDashboardsTab.TAB_NAME);
	}
}
