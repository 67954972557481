import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DocumentExplorerEvents, Node, PillsNavigation } from '@app/modules/document-explorer/events/document-explorer-events';
import { DocViewPaneSettings } from '@app/modules/document-explorer/preferences/doc-view-pane-settings';
import { SentencePreviewActionEvent } from '@app/modules/document-explorer/sentence-preview/sentence-preview.component';
import { SentenceScrollHelper } from '@app/modules/document-explorer/sentence-scroll-helper';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { CssClasses } from '@app/util/css-classes';
import { RandomUtils } from '@app/util/random-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewSentence, SentenceScorecardTopic, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PillTuningEvent } from '@app/modules/pills/pill-tuning-event';
import { CSSUtils } from '@app/util/css-utils.class';

@Component({
	selector: 'twitter-message',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './twitter-message.component.html'
})
export class TwitterMessageComponent extends SelfCleaningComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() uniqueId: string;
	@Input() document: PreviewDocument;
	@Input() documentDate: Date;
	@Input() showSentiment: boolean;
	@Input() showTopics: boolean;
	@Input() formatters: IMetricFormatters;

	@Input() uiOptions: DocViewPaneSettings;
	@Input() auditMode: boolean;
	@Input() auditModeModels: IReportModel[];
	@Input() translate: boolean;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() actionColor: string;
	@Input() selectedModelsFilter: (modelId: number) => boolean;
	@Input() predefinedMetrics: Metric[];
	@Input() selectedScorecard: number;

	@ViewChild('selectedNodeStyle', {static: false}) selectedNodeStyle: ElementRef;
	@ViewChild('modelVisibilityStyle', {static: false}) modelVisibilityStyle: ElementRef;

	tweet;
	docDateFormat: string;
	sentenceTopics: SentenceTopic[];
	scorecardTopics: SentenceScorecardTopic[];
	hasProfanity: boolean;

	messageWidth: number;
	highlightTrigger: number = 0;

	styleId: string;
	visibilityClasses: CssClasses;

	private sentenceScrollHelper: SentenceScrollHelper;

	private element: JQuery;
	private readonly debouncedResize = new Subject<void>();

	constructor(
		private readonly locale: CxLocaleService,
		private readonly elementRef: ElementRef,
		private readonly ref: ChangeDetectorRef,
		private readonly profanityDisguiseService: ProfanityDisguiseService,
		@Inject('suggestionMenu') private readonly suggestionMenu: SuggestionMenu,
	) {
		super();
	}

	ngOnInit(): void {
		this.docDateFormat = this.locale.getString('date.defaultDate');
		this.initDoc(this.document);
		this.element = $(this.elementRef.nativeElement);
		this.styleId = `s${RandomUtils.randomString()}`; // used as id in html, so must begin with a letter
		this.updateWidth();
		if (!_.isEmpty(this.document.scorecards)) {
			this.element.find('#scorecard-style').remove();
			let css = ConversationStyleUtils.getScorecardVisibilityStyles(this.document.scorecards);
			let styleElement = `<style id="scorecard-style" type="text/css">${css}</style>`;
			this.element.append(styleElement);
		}
		this.updateScrollHelper();
		this.updateVisibilityClasses();
		let events: DocumentExplorerEvents = this.documentManager.events;
		this.addSubscription(events.getChangeSettingsObservable().subscribe(this.settingsChangeHandler));
		this.addSubscription(events.getHighlightModelObservable().subscribe(this.processModelHighlight));
		this.addSubscription(events.getHighlightWorldAwarenessObservable().subscribe(this.processWorldAwarenessHighlight));
		this.addSubscription(events.getClearHighlightingObservable().subscribe(this.processClearHighlight));
		this.addSubscription(events.getRedrawTopicsObservable().subscribe(this.processModelVisibility));
	}

	ngOnChanges(changes: SimpleChanges<TwitterMessageComponent>): void {
		if (ChangeUtils.hasChange(changes.document)) {
			this.initDoc(this.document);
			this.updateScrollHelper();
			this.updateVisibilityClasses();
			this.refreshHighlighting();
			this.processModelVisibility();
			this.populateSelectedNodeStyle();
		}
		if (ChangeUtils.hasChange(changes.selectedScorecard)) {
			this.updateVisibilityClasses();
		}
		if (ChangeUtils.hasChange(changes.showSentiment)) {
			this.updateVisibilityClasses();
		}
	}

	ngAfterViewInit(): void {
		this.updateWidth();
		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.updateWidth()));
		let widthElement = this.elementRef.nativeElement.firstElementChild;
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(widthElement, () => this.debouncedResize.next()));
	}

	updateWidth = (): void => {
		this.messageWidth = Math.floor(this.element.find('.twitter-container').outerWidth());
		this.ref.markForCheck();
	}

	processModelHighlight = ($event: PillsNavigation): void => {
		const node = $event.node;
		this.highlightTopics(node);
		this.refreshHighlighting();
		this.populateSelectedNodeStyle(node);
		const targetSentence = this.sentenceScrollHelper.getNextTopicSentence(node, $event.direction);
		this.sentenceScrollHelper.scrollToSentence(targetSentence);
		if (this.documentManager.state?.highlightingTopicResolve) {
			this.documentManager.state.highlightingTopicResolve();
		}
	}

	processWorldAwarenessHighlight = ($event: PillsNavigation): void => {
		const node = $event.node;
		this.refreshHighlighting();
		this.populateSelectedNodeStyle(node);
		const targetSentence = this.sentenceScrollHelper.getNextWorldAwarenessSentence(node, $event.direction);
		this.sentenceScrollHelper.scrollToSentence(targetSentence);
	}

	processClearHighlight = (): void => {
		this.clearSelectedNodeStyle();
		this.refreshHighlighting();
	}

	processModelVisibility = (): void => {
		this.populateSentenceTopics();
	}

	private readonly updateScrollHelper = (): void => {
		this.sentenceScrollHelper = new SentenceScrollHelper(this.document.sentences, this.documentManager.widget?.id);
	}

	updateVisibilityClasses = (): void => {
		let result = {
			'use-pills': true,
			'hide-sentiment-formatting': !this.showSentiment,
			'hide-topics': this.uiOptions && !this.uiOptions.showTopics,
		};

		if (this.selectedScorecard) {
			result[`show-scorecard-${this.selectedScorecard}`] = this.showSentiment;
		}
		this.visibilityClasses = result;
		this.ref.markForCheck();
	}

	settingsChangeHandler = (): void => {
		this.updateVisibilityClasses();
	}

	onRemoveTopic = (topic: string): void => {
		for (let sentence of this.tweet.verbatim.sentences) {
			if (sentence.sentenceTopics?.length && _.find(sentence.sentenceTopics, sentenceTopic =>
					this.getTopicFullPath(sentenceTopic as SentenceTopic) === topic)) {
				this.suggestionMenu.suggestTopicRemoval({sentenceId: sentence.id, topic}, this.documentManager);
				break;
			}
		}
	}

	onTuneEnrichment = ($event: PillTuningEvent): void => {
		let pill = $event.pill;
		if (this.auditMode) {
			this.suggestionMenu.openSuggestionMenuFromDocumentPane(
				$event.event,
				pill.sentence,
				this.documentManager,
				this.uiOptions?.leafOnly,
				pill)
				.then(() => this.refreshHighlighting());
		}
	}

	openSuggestionMenu = ({event, sentence}: SentencePreviewActionEvent): void => {
		if (this.auditMode && event) {
			this.suggestionMenu.openSuggestionMenuFromDocumentPane(event, sentence, this.documentManager, this.uiOptions?.leafOnly)
			.then(() => this.refreshHighlighting());
		}
	}

	hasBadges = (): boolean => this.showSentiment;

	hasTopics = (): boolean => {
		return !_.isEmpty(this.sentenceTopics);
	}

	private readonly getTopicFullPath = (topic: SentenceTopic): string => {
		return `${topic.path.join(' > ')} > ${topic.name}`;
	}

	private readonly clearSelectedNodeStyle = (): void => {
		this.selectedNodeStyle.nativeElement.replaceChildren();
		this.ref.detectChanges();
	}

	private readonly populateSelectedNodeStyle = (node?: Node): void => {
		if (!this.selectedNodeStyle?.nativeElement) {
			return;
		}
		if (node) {
			let nodeIdentifierClass: string = this.getNodeIdentifierClass(node);
			let styleTag = this.getHighlightStyle(nodeIdentifierClass);
			this.selectedNodeStyle.nativeElement.replaceChildren(styleTag);
		} else {
			this.selectedNodeStyle.nativeElement.replaceChildren();
		}
		this.ref.detectChanges();
	}

	private readonly getNodeIdentifierClass = (node: Node): string => {
		return node.id ? `topic-${node.id}` : `enrichment-${node.name}`;
	}

	private readonly getHighlightStyle = (nodeIdentifierClass: string): HTMLStyleElement => {
		return CSSUtils.getStyleTag(`#${this.styleId} .${nodeIdentifierClass} {
					border-color: ${this.actionColor} !important;
				}`);
	}

	private readonly highlightTopics = (node: Node): void => {
		_.chain(this.tweet.verbatim.sentences)
			.map((dataItem: PreviewSentence): void => {
				let topic = _.findWhere(dataItem.sentenceTopics, {id: node.id});
				if (topic) {
					topic.selected = true;
				}
			});
	}

	private readonly initDoc = (doc: PreviewDocument): void => {
		this.tweet = {};
		this.tweet.verbatim = doc.verbatims[0];

		let nameAttr = _.find(doc.attributes, (attribute) => {
			return attribute.name.toLowerCase() === 'user_name';
		});
		this.tweet.userName = nameAttr ? nameAttr.value : '';

		this.tweet.userDisplayName = this.tweet.userName;

		let imageAttr = _.find(doc.attributes, (attribute) => {
			return attribute.name.toLowerCase() === 'profile_image_url';
		});
		this.tweet.imageUrl = imageAttr ? imageAttr.value : '';

		this.tweet.date = new Date(doc.documentDate);

		this.tweet.retweets = 0;
		this.tweet.favorites = 0;

		this.tweet.media = null;

		if (doc.tweet) {
			this.tweet.userName = doc.tweet.user.screen_name;
			this.tweet.userDisplayName = doc.tweet.user.name;
			this.tweet.imageUrl = doc.tweet.user.profile_image_url.replace('http:', '');

			let dateTime = this.parseDate(doc.tweet.created_at);
			if (!isNaN(dateTime)) {
				this.tweet.date = new Date(dateTime);
			}
			let status = doc.tweet.retweeted_status ? doc.tweet.retweeted_status : doc.tweet;
			this.tweet.retweets = status.retweet_count;
			this.tweet.favorites = status.favorite_count;

			if (doc.tweet.entities.media)
				this.tweet.media = doc.tweet.entities.media[0];
		}

		this.populateSentenceTopics();
		this.populateScorecardTopics();
	}

	private populateScorecardTopics(): void {
		this.scorecardTopics = _.chain(this.tweet.verbatim?.sentences || [] as PreviewSentence[])
			.map((sentence: PreviewSentence) => Object.values(sentence.scorecardTopics || {}))
			.flatten()
			.value();
	}

	private populateSentenceTopics(): void {
		let deselectedModelsIds: number[] =
			PillsUtils.getDeselectedModelsIds(this.documentManager.availableModels, this.selectedModelsFilter);

		let topics = [];
		this.hasProfanity = false;
		if (this.tweet.verbatim?.sentences?.length) {
			this.tweet.verbatim.sentences.forEach(sentence => {
				if (sentence.sentenceTopics?.length) {
					topics.pushAll(sentence.sentenceTopics);
				}
				this.hasProfanity = this.hasProfanity
						|| this.profanityDisguiseService.needToMaskSentence(sentence);
			});
		}

		let uniqueTopics = _.chain(topics)
			.uniq(topic => topic.id)
			.value();
		this.sentenceTopics = PillsUtils.getFilteredSentenceTopics(uniqueTopics, deselectedModelsIds, this.uiOptions?.leafOnly);

		this.ref.detectChanges();
	}

	private parseDate(rawDate: string): number {
		// ie can't parse the format where the year comes last, and moment cant fix it for some reason
		// so we need to move the timezone offset to the end
		let timezoneOffset = /([\+\-][0-9]{2}:?[0-9]*)/.exec(rawDate);
		if (timezoneOffset) {
			rawDate = rawDate.replace(timezoneOffset[0], '');
			rawDate = `${rawDate} ${timezoneOffset[0]}`;
		}

		return Date.parse(rawDate);
	}

	private refreshHighlighting() {
		this.highlightTrigger++;
		this.ref.markForCheck();
	}

	getVerbatimText(): string {
		return _.map(this.tweet.verbatim.sentences, sentence => sentence.text).join(' ');
	}
}

app.directive('twitterMessage', downgradeComponent({component: TwitterMessageComponent}) as angular.IDirectiveFactory);
