import * as Highcharts from 'highcharts';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { ReportScopeUtils } from '@cxstudio/reports/utils/report-scope-utils.service';
import { ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';

app.directive('highchartsNetwork', (
	reportUtils: ReportUtils,
	tableService,
	highchartsDefinitionFactory,
	reportDefinitionInitializers,
	pointSelectionUtils: PointSelectionUtils,
	reportScopeUtils: ReportScopeUtils,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils
) => {
	return {
		restrict: 'A',
		replace: true,
		template: '<div>Error</div>',
		scope: {
			options: '<',
			dataObject: '<data',
			demo: '<',
			view: '<',
			trigger: '<',
			utils: '<',
			handleClick: '<',
			handleRightClick: '<'
		},
		link: ($scope: ReportDefinitionScope, element) => {
			$scope.selectedPoint = null;
			reportUtils.initDestroyListener($scope);

			if (!tableService.processIfNoData(element, $scope.dataObject, undefined, $scope.utils.widgetType, $scope.utils)) {
				return;
			}

			if (!$scope.demo) {
				reportScopeUtils.emitContextMenuHandling($scope, element);
			}

			// resize handler
			reportUtils.initResizeHandler($scope, element, reportUtils.chartResizeHandler($scope));

			$scope.$watch('trigger', reportUtils.chartTriggerHandler($scope, renderChart));

			renderChart();

			function renderChart(): void {
				// destroy chart if it already exists
				if ($scope.chart && $scope.chart.container)
					$scope.chart.destroy();

				reportDefinitionInitializers.initializeSize($scope);
				let definition = highchartsDefinitionFactory.get($scope, element);
				if (!definition)
					return;

				let chartOptions = definition.getChartOptions();

				$scope.chart = new Highcharts.Chart(chartOptions, networkChartCallback);

				let destroyChart = $scope.$on('$destroy', () => {
					// destroy chart if it has been rendered
					if ($scope.chart.container)
						$scope.chart.destroy();
					destroyChart();
				});
			}

			function networkChartCallback(chart: Highcharts.Chart): void {
				_.each(chart.series, (serie: any) => {
					if (serie.userOptions.isNetworkLegend) {
						serie.legendItem.on('mouseover', () => {
							let node: Highcharts.Point = _.findWhere($scope.chart.series[0].nodes, {id: serie.name});
							if (node) {
								node.onMouseOver();
							}
						}).on('mouseout', () => {
							let node: Highcharts.Point = _.findWhere($scope.chart.series[0].nodes, {id: serie.name});
							if (node) {
								node.onMouseOut();
							}
						});
						$(serie.legendItem.element).addClass('highcharts-tracker');
					}
				});

				highchartsAccessibilityUtils.applyLegendBorder(chart);

				let highlightFunc = pointSelectionUtils.highlightSelectedPoints($scope.utils.containerId, $scope.utils.widgetId,
					$scope.utils.getGroupings(), $scope.demo);
				if (highlightFunc) {
					highlightFunc(chart);
				}
			}

		}
	};
});
