import { OnInit, SimpleChanges, EventEmitter, Output, OnChanges, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils } from '@app/util/change-utils';
import { UserEditFormData } from '../../editor/user-edit-form-data';
import { MasterAccountUserPermission } from '../../editor/user-edit-data';


@Component({
	selector: 'user-create-permissions-step',
	templateUrl: './user-create-permissions-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreatePermissionsStepComponent implements OnInit, OnChanges {
	@Input() last: boolean;
	@Input() finishEnabled: boolean;

	@Input() permissions: MasterAccountUserPermission[];
	@Input() selectedPermissions: MasterAccountUserPermission[];
	@Input() license: any;
	@Input() user: UserEditFormData;

	@Output() permissionsChange = new EventEmitter<MasterAccountUserPermission[]>();

	constructor(
		private readonly wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.wizardStep.markAsLast(this.last);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.last)) {
			let last: boolean = changes.last.currentValue;
			this.wizardStep.markAsLast(last);

			if (last) {
				this.wizardStep.setValid(this.finishEnabled);
			} else {
				this.wizardStep.setValid(true);
			}
		}
	}

}
