<div>
	<div>
		<label>{{'widget.limitTo'|i18n}}</label>
		<div class="d-flex w-100-percent flex-direction-row flex-nowrap mb-8">
			<div>
				<simple-dropdown class="mr-8 flex-fixed"
					[options]="filteringModeOptions"
					[disabled]="inheritAttributeValues"
					[value]="wordsFilteringMode"
					(valueChange)="wordsFilteringModeChange.emit($event)"
					[disableSort]="true"
				></simple-dropdown>
			</div>

			<tags-input class="flex-fill"
				[minLength]="1"
				[disabled]="inheritAttributeValues"
				(onTagAdded)="tagChanged()"
				(onTagRemoved)="tagChanged()"
				[(ngModel)]="wordsList"
				(ngModelChange)="tagChanged()"
				[tagsSource]="filterQuery"
				[formatTag]="formatTag">
			</tags-input>
		</div>
	</div>
	<div *ngIf="isTextAttribute() || isWordsOrAssociatedWordsGrouping()">
		<div class="labeled-checkbox">
			<input id="inheritAttributeValues"
				type="checkbox"
				[ngModel]="inheritAttributeValues"
				(ngModelChange)="inheritAttributeValuesChange.emit($event); applyAttributeDefaults($event)">
			<label for="inheritAttributeValues">
				{{(isTextAttribute() ? 'widget.inheritAttributeValues' : 'widget.inheritProjectDefaults') | i18n}}
			</label>
		</div>
	</div>
</div>
