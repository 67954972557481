<div class="w-100-percent h-100-percent d-flex flex-direction-column">
	<div class="flex-fill d-flex flex-direction-row border-0 border-t-2 border-b-2 border-solid border-gray-400">
		<div class="w-320 h-100-percent bg-main">
			<ng-container *ngFor="let step of steps; let i = index">
				<div class="wizard-nav-step pv-16 ph-8 d-flex justify-between"
					[class.active]="step.active"
					[class.invalid]="!step.valid"
					(click)="activateStep(step)">
					<strong>{{'common.stepN' | i18n:{index: i + 1} }}: {{ step.getHeader() }}</strong>

					<cx-wizard-tag *ngIf="step.tagType"
						[type]="step.tagType"
					></cx-wizard-tag>

				</div>
			</ng-container>
		</div>
		<div class="flex-fill h-100-percent bg-white"
			[class.overlay]="isViewMode()"
			[blockKbNavigation]="isViewMode()">
			<ng-content></ng-content>
		</div>
	</div>
	<div class="flex-fixed d-flex flex-direction-row justify-between bg-white pv-8 ph-16">
		<div class="d-flex">
			<button
				id="cx-btn-back"
				type="button"
				class="btn btn-secondary d-flex align-center"
				(click)="back()"
				[disabled]="!isBackAllowed()">
				<span class="q-icon-triangle-left"></span>
				{{'common.back'|i18n}}
			</button>
			<button
				id="cx-btn-next"
				type="button"
				class="btn btn-secondary d-flex align-center"
				(click)="next()"
				[disabled]="!isNextAllowed()">
				{{'common.next'|i18n}}
				<span class="q-icon-triangle-right"></span>
			</button>
		</div>
		<div class="d-flex">
			<button id="cx-btn-cancel"
				type="button"
				class="btn btn-secondary"
				(click)="onCancel()">
				{{cancelLabel}}
			</button>
			<button id="cx-btn-additional-one" *ngIf="!!showAdditionalOneButton"
				type="button"
				class="btn btn-secondary"
				(click)="onButtonOne()"
				[disabled]="!additionalButtonOneEnabled">
				{{additionalButtonOneLabel}}
			</button>
			<button id="cx-btn-additional-two" *ngIf="!!showAdditionalTwoButton"
				type="button"
				class="btn btn-secondary"
				(click)="onButtonTwo()"
				[disabled]="!additionalButtonTwoEnabled">
				{{additionalButtonTwoLabel}}
			</button>
			<button id="cx-btn-finish" *ngIf="!!showSaveButton"
				type="button"
				class="btn btn-primary"
				(click)="onFinish()"
				[disabled]="!isFinishAllowed()">
				{{finishLabel}}
			</button>

			<button *ngIf="showDoneButton"
					type="button"
					class="btn btn-primary"
					(click)="onDone()"
					[disabled]="!stepsBeforeLastComplete()">
				{{'common.done'|i18n}}
			</button>
		</div>
	</div>
</div>
