import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import OAuthExternalProvider from '../oauth-external-provider';

@Injectable()
export class OAuthExternalProvidersApiService {

	readonly BASE_URL: string = 'rest/oauth/external-providers';

	constructor(
		private cxHttp: CxHttpService) {
	}

	getExternalProviders = (): Promise<OAuthExternalProvider[]> => {
		return this.cxHttp.get(this.BASE_URL);
	}

	getExternalQualtricsProviders = (type?: string): Promise<OAuthExternalProvider[]> => {
		return this.cxHttp.get(`${this.BASE_URL}/qualtrics`);
	}

	createExternalProvider = (externalProvider: OAuthExternalProvider): Promise<void> => {
		return this.cxHttp.post(this.BASE_URL, externalProvider);
	}

	updateExternalProvider = (externalProvider: OAuthExternalProvider): Promise<void> => {
		return this.cxHttp.put(this.BASE_URL, externalProvider);
	}

	removeExternalProvider = (aliasName: string): Promise<void> => {
		return this.cxHttp.delete(`${this.BASE_URL}/alias/${aliasName}`);
	}

	getLinkedMasterAccounts = (aliasName: string): Promise<any> => {
		return this.cxHttp.get(`${this.BASE_URL}/alias/${aliasName}/master-accounts`);
	}
}