import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { SamlServiceProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-service-providers/saml-service-providers.component';
import { SamlServiceProvidersListActions } from '@app/modules/system-administration/saml-settings/saml-service-providers/miscellaneous/saml-service-providers-list-actions.service';

@Injectable()
export class SamlServiceProvidersListMenu extends BaseContextMenuUtils {

	constructor(private locale: CxLocaleService, private serviceProvidersListActions: SamlServiceProvidersListActions) {
		super();
	}

	private getOptions(controller: SamlServiceProvidersComponent): {[name: string]: ContextMenuItem<SamlServiceProvider>} {
		return {
			EDIT: {
				name: 'edit',
				text: this.locale.getString('common.edit'),
				func: (serviceProvider: SamlServiceProvider) => this.serviceProvidersListActions.edit(serviceProvider, controller)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (serviceProvider: SamlServiceProvider) => this.serviceProvidersListActions.delete(serviceProvider)
			}
		};
	}

	getContextMenu(item: SamlServiceProvider, controller: SamlServiceProvidersComponent): Array<ContextMenuItem<SamlServiceProvider>> {
		const OPTIONS = this.getOptions(controller);
		return [OPTIONS.EDIT, OPTIONS.DELETE];
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}