import { NgModule } from '@angular/core';
import { ScorecardFiltersManagementService } from '@app/modules/scorecards/filters/scorecard-filters-management.service';
import { ScorecardFiltersService } from '@app/modules/scorecards/filters/scorecard-filters.service';
import { SharedModule } from '@app/shared/shared.module';
import { ReportScorecardFiltersService } from '@app/modules/scorecards/filters/report-scorecard-filters.service';
import { ScorecardCriteriaSorting } from './scorecard-criteria-sort.service';

@NgModule({
	imports: [ SharedModule ],
	providers: [
		ScorecardCriteriaSorting,
		ScorecardFiltersManagementService,
		ScorecardFiltersService,
		ReportScorecardFiltersService,
	]
})

export class ScorecardModule {}
