
/**
 * Adds "cancelled" field to the wrapped promise object and gives ability to track it afterwards.
 */
export class CancellableDeferred<T> implements ng.IDeferred<T> {

	promise: any;

	constructor(private originalDeferred: ng.IDeferred<any>) {
		this.promise = originalDeferred.promise;
		this.promise.cancelled = false;
	}

	resolve(value?: T): void {
		this.originalDeferred.resolve(value);
	}

	reject(reason?: any): void {
		this.originalDeferred.reject(reason);
	}

	notify(state?: any): void {
		this.originalDeferred.notify(state);
	}

	cancel(value?: T): void {
		this.resolve(value);
		this.promise.cancelled = true;
	}

}
