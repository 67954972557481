import { ContentChild, OnDestroy, AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { NgbDropdownAnchor } from '@ng-bootstrap/ng-bootstrap';

@Directive({
	selector: '[autofitHorizontally]'
})
export class AutofitHorizontallyDirective implements AfterViewInit, OnDestroy {

	directionThreshold = .75;

	@ContentChild(NgbDropdownAnchor, {static: false}) protected dropdownAnchor: NgbDropdownAnchor;

	constructor(
		private readonly el: ElementRef
	) { }

	ngAfterViewInit(): void {
		$(this.dropdownAnchor.nativeElement).on('mousedown keydown', (event: JQuery.KeyDownEvent | JQuery.MouseDownEvent) => {
			let currentX = event.pageX;
			if (KeyboardUtils.isKeyDown(event) && KeyboardUtils.isEventKey(event as any, Key.ENTER)) {
				let rect = event.target.getBoundingClientRect();
				currentX = rect.width / 2 + rect.x;
			}
			let width = window.innerWidth;
			let threshold = this.directionThreshold * width;
			let alignRight = currentX > threshold;
			let alwaysLeft = false;

			if (alignRight) {
				alwaysLeft = $(event.target).parents('.br-gridster-zoom').length > 0;
			}

			this.el.nativeElement.classList.add(alignRight && !alwaysLeft ? 'bottom-right' : 'bottom-left');
		});
	}

	ngOnDestroy(): void {
		$(this.dropdownAnchor.nativeElement).off('mousedown keydown');
	}
}
