
import * as _ from 'underscore';

export default class LinkedFilterExport {
	parentPositionId: number;
	point: string; // serialized json 
	constructor(positionId: number, point: string) {
		this.parentPositionId = positionId;
		this.point = point;
	}
}
