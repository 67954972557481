import * as _ from 'underscore';
import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

export function logoutEndpointValidator(): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {

		if (_.isEmpty(control.controls)) {
			return null;
		}

		let url: string = _.isUndefined(control.controls['logoutEndpointUrl']) ? null : control.controls['logoutEndpointUrl'].value;
		let user: string = _.isUndefined(control.controls['logoutEndpointUser']) ? null : control.controls['logoutEndpointUser'].value;
		let password: string = _.isUndefined(control.controls['logoutEndpointPassword']) ?
			null : control.controls['logoutEndpointPassword'].value;

		if (!url) {
			if (user || password) {
				return { logountEndpointUrlAbsent: true };
			}
		}

		if ((user && !password) || (!user && password)) {
			return { userOrPasswordAbsent: true };
		}

		return null;
	};
}
