
import { OAuthAuthorityRole } from './oauth-authority-roles.factory';
import OAuthScope from '@app/modules/system-administration/oauth/oauth-scope';

export interface UnifiedLink {
	url: string;
	name: string;
}

export default class OAuthClientDetails {
	clientId: string;
	clientSecret: string;
	legacyClientId: string;

	applicationKind: string;

	accessTokenValiditySeconds: number;
	refreshTokenValiditySeconds: number;

	scope: OAuthScope[];
	autoApproveScopes: string[];

	authorities: OAuthAuthorityRole[];

	authorizedGrantTypes: string[];
	registeredRedirectUri: string[];
	additionalInformation: any;
	unifiedLinksVisibility: Map<number, Array<number>>;

	broken: boolean;

	disabled: boolean;
	description: string;
}
