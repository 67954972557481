import {
	AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList
} from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';

@Component({
	selector: 'cx-wizard',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cx-wizard.component.html'
})
export class CxWizardComponent implements OnChanges, AfterContentInit {

	@Input()
	loadingPromise: Promise<any>;
	@Input()
	activeStepIndex?: number = 0;
	@Input()
	private mode: CxWizardMode = CxWizardMode.EDIT;
	@Input()
	private header: string;
	@Input()
	private showCancelIcon: boolean = true;
	@Input()
	private finishText: string;

	@Output()
	private stepChange = new EventEmitter<number>();
	@Output()
	private onCancel = new EventEmitter<void>();
	@Output()
	private onFinish = new EventEmitter<void>();
	@Output()
	private onConfirm = new EventEmitter<void>();

	@ContentChildren(CxWizardStepComponent) steps: QueryList<CxWizardStepComponent>;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) { }

	ngOnChanges(changes: SimpleChanges<CxWizardComponent>): void {
		if (ChangeUtils.hasChange(changes.activeStepIndex)) {
			this.setActiveStep(changes.activeStepIndex.currentValue);
		}
	}

	ngAfterContentInit(): void {
		this.steps.forEach(step => step.stateChange.subscribe(() => this.changeDetectorRef.detectChanges()));
		this.setActiveStep(this.activeStepIndex ?? 0);
	}

	cancel = (): void => {
		this.onCancel.emit();
	}

	confirm = (): void => {
		this.onConfirm.emit();
	}

	finish = (): void => {
		this.onFinish.emit();
	}

	isShowingCancelIcon = (): boolean => {
		return this.showCancelIcon;
	}

	back = (): void => {
		if (this.getActiveStep()?.back) {
			this.getActiveStep().back();
		} else {
			this.setActiveStep(--this.activeStepIndex);
		}
	}

	next = (): void => {
		if (this.getActiveStep()?.next) {
			this.getActiveStep().next();
		} else {
			this.setActiveStep(++this.activeStepIndex);
		}
	}

	isShowingBackButton = (): boolean => {
		return this.isNavigationAllowed() && this.hasPreviousStep();
	}

	isShowingNextButton = (): boolean => {
		return !this.getActiveStep()?.isMarkedAsLast() && this.isNavigationAllowed() && this.hasNextStep();
	}

	getHeader = (): string => {
		return this.getActiveStep()?.getHeader() || this.header || '';
	}

	isViewMode = (): boolean => {
		return this.mode === CxWizardMode.VIEW || this.mode === CxWizardMode.VIEW_LAST_PAGE_ONLY;
	}

	isShowingFinishButton = (): boolean => {
		return this.getActiveStep()?.isMarkedAsLast() || (!this.isViewMode() && !this.hasNextStep());
	}

	isFinishAllowed = (): boolean => {
		return this.isShowingFinishButton() && this.isActiveStepValid();
	}

	getFinishText = (): string => {
		return this.finishText || this.locale.getString('common.finish');
	}

	getCancelText = (): string => {
		let key = this.isViewMode() ? 'common.close' : 'common.cancel';
		return this.locale.getString(key);
	}

	isNextAllowed = (): boolean => {
		return this.isActiveStepValid();
	}

	isConfirmStep = (): boolean => {
		return this.getActiveStep()?.isConfirmationStep();
	}

	isConfirmAllowed = (): boolean => {
		return this.getActiveStep()?.isLoaded();
	}

	detectChanges = (): void => {
		this.changeDetectorRef.detectChanges();
	}

	private isActiveStepValid = (): boolean => {
		return this.getActiveStep()?.isValid();
	}

	private hasPreviousStep = (): boolean => {
		return this.activeStepIndex > 0;
	}

	private hasNextStep = (): boolean => {
		return this.activeStepIndex < this.steps.length - 1;
	}

	private isNavigationAllowed = (): boolean => {
		return this.mode !== CxWizardMode.VIEW_LAST_PAGE_ONLY;
	}

	activateStep = (step: CxWizardStepComponent): void => {
		this.setActiveStep(this.steps.toArray().indexOf(step));
	}

	private setActiveStep = (index: number): void => {
		this.steps.forEach(step => step.setActive(false));
		this.steps.toArray()[index].setActive(true);

		this.activeStepIndex = index;
		this.stepChange.emit(this.activeStepIndex);

		this.detectChanges();
	}

	private getActiveStep = (): CxWizardStepComponent => {
		return this.steps.toArray()[this.activeStepIndex];
	}

}
