export type CxEvent = WidgetEvent | WidgetEditEvent | WidgetLocalEvent | GridsterEvent | DashboardEvent
	| DashboardExportEvent | FullscreenEvent | URLEvent | GridEvent | AlertEvent
	| DrillFilterEvent | EmbedDashboardEvent | BookEvent | SecurityEvent;

// these events are passed with additional parameters, e.g. widgetId, dashboardId
export enum WidgetEvent {
	RELOAD = 'widget:reload',
	REFRESH_FINISHED_GLOBAL = 'widgetRefreshFinishedEvent',
}

export enum WidgetEditEvent {
	WORKSPACE_PROJECT_UPDATE = 'workspaceProjectUpdate',
}

// these events live within a single widget event bus
export enum WidgetLocalEvent {
	REFRESH = 'widget:refresh',
	HARD_REFRESH = 'widget:hard-refresh',
	REFRESH_FINISHED = 'widget:refresh-finished',
	RESIZED = 'widget:resized'
}

export enum GridsterEvent {
	RESET_DRAG = 'gridster:reset-draggable',
	WIDGET_RESIZED = 'gridster:widget-resized',
}

export enum GridEvent {
	GRID_LIST_UPDATED = 'gridListUpdated',
	FOCUS_GRID_CELL = 'focusGridCell',
}

export enum DashboardEvent {
	SAVE = 'dashboardSaveEvent',
	RESET_WIDGETS = 'dashboardResetWidgets',
	SHARE = 'sharingPerformed',
	REFRESH = 'dashboardRefreshEvent',
	HARD_REFRESH = 'dashboardHardRefreshEvent',
	RELOAD = 'dashboardReloadEvent',
	APPLY_HISTORY_STATE = 'dashboard:applyHistoryState',
	CREATE_FROM_WIDGETS = 'createDashboardFromWidgetsEvent',
}

export enum URLEvent {
	SCOPE_LOCATION_CHANGE_START = '$locationChangeStart'
}

export enum DashboardExportEvent {
	PDF_DOWNLOAD = 'dashboardExportPdfDownloadEvent',
	PDF_EMAIL = 'dashboardExportPdfSendEvent',
	XLS = 'dashboardExportEvent'
}

export enum FullscreenEvent {
	APPLICATION_OUTDATED = 'applicationOutdatedEvent'
}

// Drill filter set
export enum DrillFilterEvent {
	DRILL_FILTER_SET = 'filter:set',
	DRILL_FILTERS_RESET = 'filter:reset',
}

export enum EmbedDashboardEvent {
	RELOAD = 'embed-dashboard:reload',
}

export enum AlertEvent {
	CLEAR = 'alerts:clear',
	RELOAD = 'alerts:reload',
	CREATE = 'alerts:create',
}

export enum BookEvent {
	SCROLL_TO_DASHBOARD = 'scrollToId',
}

export enum SecurityEvent {
	USER_UPDATED = 'user:updated',
}
