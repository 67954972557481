import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';


@Directive({
	selector: 'filter-management-tab'
})

export class FilterManagementTabUpgrade extends UpgradeComponent {
	@Input() widget: Widget;
	@Input() props: WidgetProperties;
	@Input() projectSelection: any;
	@Input() projectTimezone: any;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('filterManagementTab', elementRef, injector);
	}
}
