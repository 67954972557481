import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { BorderOptions, BorderValue, BackgroundType } from '@cxstudio/reports/entities/content-widget-properties';
import { TextWidgetBuilder } from '@app/modules/widget-settings/entities/widgets/text-widget-builder';
import { MetricWidget } from '@app/modules/widget-settings/entities/widgets/metric-widget.class';
import { TableWidget } from '@app/modules/widget-settings/entities/widgets/table-widget.class';
import { FeedbackWidget } from '@app/modules/widget-settings/entities/widgets/feedback-widget.class';
import { MobileWidgetConfiguration, MobileScreenConfiguration, MobileApplicationDashboardUtils, MobileWidgetConfigurationType }
	from '@app/modules/mobile/mobile-application-dashboard-utils';
import { CxLocaleService } from '@app/core';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import SimpleGroupingMobileScreenSettings from '@cxstudio/mobile/simple-grouping-mobile-screen-settings.interface';
import FeedbackViewMobileScreenSettings  from '@cxstudio/mobile/feedback-view-mobile-screen-settings.interface';
import { PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import MobileDateRange from '@cxstudio/mobile/mobile-date-range.interface';
import { FeedbackViewMode } from '@cxstudio/mobile/feedback-view-mode.enum';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { MobileApplicationHelperService } from './mobile-application-helper.service';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';

@Injectable()
export class MobileApplicationWidgetBuilderService {
	readonly TEXT_PLACEHOLDER = 'placeholder';
	readonly TEXT_PATTERN = `<p style="text-align: center"><span class="font-medium-scale"><strong>${this.TEXT_PLACEHOLDER}</strong></span></p>`;

	constructor(
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
		@Inject('mobileUtils') private mobileUtils: MobileUtils,
		@Inject('previewPredefinedColumns') private previewPredefinedColumns: PreviewPredefinedColumns,
		private mobileApplicationHelperService: MobileApplicationHelperService
	) {}

	getWidgetsFromMobileSettings = (settings: MobileAppSettings, models: ModelTree[]): Widget[] => {
		return [
			...this.getFirstScreenWidgets(settings, models),
			...this.getSecondScreenWidgets(settings, models),
			...this.getThirdScreenWidgets(settings),
			...this.getFeedbackScreenWidgets(settings)
		];
	}

	getFirstScreenWidgets = (settings: MobileAppSettings, models: ModelTree[]): Widget[] => {
		const screenConfiguration: MobileScreenConfiguration = MobileApplicationDashboardUtils.FirstScreen;

		const contentWidget = this.getTextWidget(screenConfiguration);
		const metricWidget = this.getMetricWidget(settings, screenConfiguration, models);
		const tableWidget = this.getTableWidget(settings, screenConfiguration);

		return [contentWidget, metricWidget, tableWidget];
	}

	getSecondScreenWidgets = (settings: MobileAppSettings, models: ModelTree[]): Widget[] => {
		const screenConfiguration: MobileScreenConfiguration = MobileApplicationDashboardUtils.SecondScreen;

		const contentWidget = this.getTextWidget(screenConfiguration);
		const metricWidget = this.getMetricWidget(settings, screenConfiguration, models);
		const tableWidget = this.getTableWidget(settings, screenConfiguration);

		return [contentWidget, metricWidget, tableWidget];
	}

	getThirdScreenWidgets = (settings: MobileAppSettings): Widget[] => {
		if (!this.mobileUtils.isThirdScreenConfigured(settings)) return [];

		const screenConfiguration: MobileScreenConfiguration = MobileApplicationDashboardUtils.ThirdScreen;

		const contentWidget = this.getTextWidget(screenConfiguration);
		const tableWidget = this.getTableWidget(settings, screenConfiguration);

		return [contentWidget, tableWidget];
	}

	getFeedbackScreenWidgets = (settings: MobileAppSettings): Widget[] => {
		const screenConfiguration: MobileScreenConfiguration = this.mobileUtils.isThirdScreenConfigured(settings)
				? MobileApplicationDashboardUtils.FeedbackScreen
				: MobileApplicationDashboardUtils.FeedbackScreenWide;

		const contentWidget = this.getTextWidget(screenConfiguration);
		const feedbackWidget = this.getFeedbackWidget(settings, screenConfiguration);

		return [contentWidget, feedbackWidget];
	}

	private getTextWidget = (screenConfiguration: MobileScreenConfiguration): Widget => {
		const configuration: MobileWidgetConfiguration = screenConfiguration.widgetConfigurations.TITLE;
		const widgetText = this.locale.getString(configuration.title).toUpperCase();

		return TextWidgetBuilder.get()
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withText(this.TEXT_PATTERN.replace(this.TEXT_PLACEHOLDER, widgetText))
			.withBorder(BorderOptions.BOTTOM, BorderValue.ON)
			.withBackground(BackgroundType.NONE)
			.withRunAs(this.security.getEmail())
			.withId(configuration.id);
	}

	private getMetricWidget = (settings: MobileAppSettings, screenConfiguration: MobileScreenConfiguration, models?: ModelTree[]): Widget => {
		const configuration: MobileWidgetConfiguration = screenConfiguration.widgetConfigurations.CALCULATIONS;
		const screen: SimpleGroupingMobileScreenSettings =
				(settings.screens[screenConfiguration.screenIndex]) as SimpleGroupingMobileScreenSettings;

		let customFilter: AdhocFilter = this.mobileApplicationHelperService.getCustomFilter(settings.customFilter, screen.grouping, models);

		return MetricWidget.get()
			.withProjectSelection(this.getProjectSelection(settings))
			.withPeriods(this.getDateFilter(settings))
			.withCalculations(screen.calculations)
			.withAdHocFilters(customFilter)
			.withAppliedFilters(settings.savedFilter)
			.withRunAs(this.security.getEmail())
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString(configuration.title))
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withId(configuration.id)
			.withLinkedWidgets(configuration.linkedWidgets);
	}

	private getTableWidget = (settings: MobileAppSettings, screenConfiguration: MobileScreenConfiguration): Widget => {
		const configuration: MobileWidgetConfiguration = screenConfiguration.widgetConfigurations.GROUPINGS;
		const screen: SimpleGroupingMobileScreenSettings =
				(settings.screens[screenConfiguration.screenIndex]) as SimpleGroupingMobileScreenSettings;

		return TableWidget.get()
			.withProjectSelection(this.getProjectSelection(settings))
			.withPeriods(this.getDateFilter(settings))
			.withCalculations([screen.groupingCalculation])
			.withAdHocFilters(settings.customFilter)
			.withAppliedFilters(settings.savedFilter)
			.withGroupings([screen.grouping])
			.withRunAs(this.security.getEmail())
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString(configuration.title))
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withId(configuration.id)
			.withLinkedWidgets(configuration.linkedWidgets);
	}

	private getFeedbackWidget = (settings: MobileAppSettings, screenConfiguration: MobileScreenConfiguration): Widget => {
		const configuration: MobileWidgetConfiguration = screenConfiguration.widgetConfigurations.FEEDBACK_LIST;
		const screen: FeedbackViewMobileScreenSettings = (settings.screens[screenConfiguration.screenIndex]) as FeedbackViewMobileScreenSettings;

		const columns: AttributeGrouping[] = [
			this.previewPredefinedColumns.get(PreviewColumn.SOURCE),
			this.previewPredefinedColumns.get(PreviewColumn.DOC_DATE),
			this.previewPredefinedColumns.get(PreviewColumn.SENTENCE)
		];

		if (screen.displaySettings.effortBadgeEnabled) {
			columns.push(this.previewPredefinedColumns.get(PreviewColumn.EFFORT));
		}

		if (screen.displaySettings.emotionalIntensityBadgeEnabled) {
			columns.push(this.previewPredefinedColumns.get(PreviewColumn.EMOTION));
		}

		if (screen.displaySettings.sentimentBadgeEnabled) {
			columns.push(this.previewPredefinedColumns.get(PreviewColumn.SENTIMENT));
		}

		return FeedbackWidget.get()
			.withPreviewMode(this.getPreviewMode(screen.mode))
			.withColumns(columns)
			.withBubbleColumns(1)
			.withProjectSelection(this.getProjectSelection(settings))
			.withPeriods(this.getDateFilter(settings))
			.withAdHocFilters(settings.customFilter)
			.withAppliedFilters(settings.savedFilter)
			.withRunAs(this.security.getEmail())
			.withAutoTitle(false)
			.withAutoDescription(true)
			.withDisplayName(this.locale.getString(configuration.title))
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withId(configuration.id)
			.withLinkedWidgets(configuration.linkedWidgets);
	}

	private getProjectSelection = (settings: MobileAppSettings): IProjectSelection => {
		return {
			contentProviderId: settings.contentProviderId,
			accountId: settings.accountId,
			projectId: settings.projectId
		};
	}

	private getDateFilter = (settings: MobileAppSettings): DateFilter => {
		const defaultDateRange: MobileDateRange = _.find(settings.dateRanges, (dateRange) => {
			return dateRange.selectedAsDefault;
		});

		return {
			dateFilterMode: defaultDateRange.value
		};
	}

	private getPreviewMode = (feedbackViewMode: FeedbackViewMode): PreviewMode => {
		switch (feedbackViewMode) {
			case FeedbackViewMode.SENTENCE:
				return PreviewMode.SENTENCES;

			case FeedbackViewMode.SENTENCE_WITH_CONTEXT:
				return PreviewMode.SENTENCES_WITH_CONTEXT;

			case FeedbackViewMode.VERBATIM:
				return PreviewMode.VERBATIM;
		}
	}
}

app.service('mobileApplicationWidgetBuilderService', downgradeInjectable(MobileApplicationWidgetBuilderService));
