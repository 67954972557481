import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, HostBinding } from '@angular/core';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';

@Component({
	selector: 'user-linked-accounts',
	templateUrl: './user-linked-accounts.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLinkedAccountsComponent {

	loading: Promise<any>;

	@HostBinding('class.w-100-percent') fullWidth = true;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly userSessionApiService: UserSessionApiService,
		@Inject('security') private security: Security,
		@Inject('securityApiService') private securityApiService: SecurityApiService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) {}

	isQualtricsAccountLinked(): boolean {
		return !!this.security.getUser()?.xmAccountId;
	}

	openQualtricsLoginPopup(event): void {
		event.stopPropagation();
		event.preventDefault();
		let linkingPromise = PromiseUtils.wrap(this.securityApiService.getQualtricsAccountLinkingUrl());

		let callback = (): void => {
			this.userSessionApiService.getContext().then((response: any) => {
				this.security.setXmAccountId(response.data.user.xmAccountId);
				this.ref.markForCheck();
			});
		};

		this.loading = linkingPromise.then(url => {
			return this.environmentService.openPopupLogin(url, this.environmentService.LOGIN_POPUP_NAME, callback);
		});
	}

	getXmAccountId(): string {
		return this.security.getUser()?.xmAccountId;
	}

}
