import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HighchartsClosureUtils, HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { AnalyticHierarchyData } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { IValueFormatter } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { Color } from '@cxstudio/reports/utils/color-utils.service';
import { HighchartsTooltipFormatterUtil } from '@cxstudio/reports/utils/highchart/highcharts-tooltip-formatter-util.service';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { PatternObject } from 'highcharts';
import * as cloneDeep from 'lodash.clonedeep';

export interface StudioHighchartsSeries extends Partial<Highcharts.SeriesOptions> {
	dashStyle?: string;
	center?: string[];
	innerSize?: string;
	size?: string;
	dataLabels?: Highcharts.PlotSeriesDataLabelsOptions | any;
	tooltip?: Highcharts.SeriesTooltipOptionsObject | any;
	isPrimary?: boolean;
	color?: string | PatternObject;
	marker?: any;
	data?: any[];
	metric?: string;
	showInLegend?: boolean;
	object?: any;
	category?: any;
	linkedTo?: any;
}

type IPointMetricsMap = {[key in 'y' | 'x' | 'z']: string | ((row: IDataPointObject) => number)};

type IPointMetrics = ((row: IDataPointObject) => number) | string | IPointMetricsMap;

interface IDataProperties {
	metricFormatter?: any;
	isObjectBasedColor?: boolean;
	stackedGroup?: ReportGrouping;
	isStacked?: boolean;
	useParentColor?: boolean;
	colorType?: ColorTypes;
	size?: (item) => number;
	globalOtherExplorer?: boolean;
	points?: boolean;
	pointColor?: (item, index?) => (string | Highcharts.PatternObject);
	isPrimary?: boolean;
	color?: (item, index?) => (string | Highcharts.PatternObject);
	type?: ChartType | VisualizationType;

}

interface IPieDataProperties {
	formatter: (value: number) => string;
	metric: string;
	donut: boolean;
	sideBySide: boolean;
}

export interface IHighchartsCategory {
	name: string;
	categories: (string | IHighchartsCategory)[];
}

@Injectable({
	providedIn: 'root'
})
export class HighchartsAnalyticUtils {
	constructor(
		@Inject('highchartsUtils') private readonly highchartsUtils: HighchartsUtilsService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('highchartsTooltipFormatterUtil') private readonly highchartsTooltipFormatterUtil: HighchartsTooltipFormatterUtil,
		@Inject('reportNumberFormatUtils') private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
	) {}

	getSeries(data: AnalyticHierarchyData[], processors: any[]): StudioHighchartsSeries[] {
		if (!processors.length) {
			return [];
		}
		let result = cloneDeep(data);
		processors.forEach((processor) => {
			result = processor(result);
		});
		return result;
	}

	getHierarchyCategories(data: AnalyticHierarchyData[], groups: ReportGrouping[], requiresEmptyCategories: boolean,
		formatters: {[identifier: string]: (item) => string}): (string | IHighchartsCategory)[] {
		if (!groups.length) {
			return [];
		}
		let recursiveMap = (node, level): string | IHighchartsCategory => {
			let group = groups[level];
			let itemName = this.getCategoryItemName(group, node);

			if (formatters && formatters[group.identifier]) {
				itemName = formatters[group.identifier](itemName);
			}

			if (level + 1 < groups.length) {
				let children = node._children;
				let containsChildren = children && children.length > 0;
				let categories = containsChildren
					? _.map(children, (child) => {
						return recursiveMap(child, level + 1);
					})
					: ( requiresEmptyCategories ? [ '' ] : [] );

				return {
					name: itemName,
					categories
				};
			} else {
				return itemName;
			}
		};
		return _.map(data, (node) => {
			return recursiveMap(node, 0);
		});
	}

	getCategoryItemName(group: ReportGrouping, dataPoint: IDataPointObject, fallbackKey?: string): string {
		if (group.metricType === AnalyticMetricType.TIME) {
			let metadataFieldName = '_metadata_' + group.identifier;
			if (dataPoint[metadataFieldName]) {
				return dataPoint[metadataFieldName].displayName;
			}
		}

		if (group.identifier && dataPoint[group.identifier]) {
			return dataPoint[group.identifier];
		}

		if (fallbackKey && dataPoint[fallbackKey]) {
			return dataPoint[fallbackKey];
		}

		return null;
	}

	// input: [{_name:'seriesName', _children:[treeNodesArray]}, {},...]
	seriesValuesProcessor(metrics: IPointMetrics, ignoreNulls: boolean,
		parent?: string): (series: AnalyticHierarchyData[]) => StudioHighchartsSeries[] {
		return (seriesNodes: AnalyticHierarchyData[]) => {
			return _.map(seriesNodes, (node) => {
				let points = _.map(node._children, this.getPointFunction(metrics));
				if (ignoreNulls) {
					points = _.filter(points, point => !!point);
				}

				let seriesName = node._name;

				if (parent) {
					seriesName = `${seriesName} - ${parent}`;
				}
				return {
					name: seriesName,
					id: node._uniqName,
					_uniqName: node._uniqName,
					_isStatic: node._isStatic,
					_group: node._group,
					data: points
				} as StudioHighchartsSeries;
			});
		};
	}

	// metrics - function, string or object with function/string values
	private getPointFunction(metrics: IPointMetrics): any {
		let metricsMap: IPointMetricsMap;
		if (_.isFunction(metrics) || _.isString(metrics)) {
			metricsMap = {y: metrics} as IPointMetricsMap;
		} else {
			metricsMap = metrics as IPointMetricsMap;
		}
		let fieldsMapper = _.mapObject(metricsMap, (value) => {
			return this.getMetricFunction(value);
		});
		return (row) => {
			if (!row) {
				return null;
			}
			let obj = {
				name: row._name,
				object: row
			};
			let hasNaN = false;
			_.each(fieldsMapper, (func, field) => {
				obj[field] = func(row);
				if (isNaN(obj[field])) {
					hasNaN = true;
				}
			});
			return hasNaN ? null : obj;
		};
	}

	private getMetricFunction(fieldOrFunction: string | ((row: IDataPointObject) => number)): (row: IDataPointObject) => number {
		if (_.isFunction(fieldOrFunction)) {
			return fieldOrFunction;
		}
		else {
			let field = fieldOrFunction;
			return (row) => {
				if (!row) {
					return undefined;
				}
				if (row[field] === 'NaN') {
					return null; // data exists, but no value
				}
				return row[field];
			};
		}
	}

	// input: [{_name:'stackName', _children:[treeNodesArray]}, {},...]
	seriesStackValuesProcessor(metric: string, axisPrefix: string): (series: AnalyticHierarchyData[]) => StudioHighchartsSeries[] {
		return (stackNodes: AnalyticHierarchyData[]) => {
			return _.chain(stackNodes).map((stackNode) => {
				let stackName = stackNode._name;

				let sumMap = {};
				let valueMap = {};
				// calculate sum of volume for each category
				_.each(stackNode._children, (pointNode) => {
					if (pointNode) {
						sumMap[pointNode._id] = _.reduce(pointNode._children, (memo, leaf) => {
							return memo + (leaf && leaf.volume || 0);
						}, 0);
						valueMap[pointNode._id] = pointNode[metric];
					}
				});

				// convert category->values for each series into series->points for each category
				let stackSeries = this.dataStackTransformer()(stackNode._children);
				// excract
				let suffix = stackNodes.length > 1 ? stackName : '';
				let result = this.seriesValuesProcessor(this.getStackedMetricFunction(sumMap, valueMap), false, suffix)(stackSeries);
				_.each(result, (series) => {
					series.stack = axisPrefix + stackName;
					series.metric = metric;
				});
				return result;
			}).flatten()
				.value();
		};
	}

	private getStackedMetricFunction(sumMap, valueMap): (item: IDataPointObject) => number {
		return (row) => {
			if (!row || isNaN(valueMap[row._parentId]))
				return undefined;
			row.st_y = valueMap[row._parentId];
			return valueMap[row._parentId] * row.volume / sumMap[row._parentId];
		};
	}

	dataRootTransformer(name?: string): (data: AnalyticHierarchyData[]) => AnalyticHierarchyData[] {
		return (data) => {
			let volume = this.metricConstants.get().VOLUME;

			return [{
				_name: name || volume.displayName,
				_uniqName: `${volume.metricType}_${volume.name}`,
				_children: data
			} as AnalyticHierarchyData];
		};
	}

	// input: [treeNodesArray]
	dataDrillTransformer(displayName: string, requiresEmptyCategories = false): (data: AnalyticHierarchyData[]) => AnalyticHierarchyData[] {
		return (data) => {
			let children = data
				.reduce((previous, current) => {
					let currentChildren = current._children;
					let hasChildren = currentChildren && currentChildren.length > 0;
					if (requiresEmptyCategories && !hasChildren) {
						currentChildren = [ current ];
					}

					let result = previous.slice();
					result.pushAll(currentChildren);
					return result;
				}, []);

			return [{
				_name: displayName,
				_children: children
			} as AnalyticHierarchyData];
		};
	}

	// input: [treeNodesArray]
	dataStackTransformer(withoutStack = false): (data: AnalyticHierarchyData[]) => AnalyticHierarchyData[] {
		return (data: AnalyticHierarchyData[]) => {
			let seriesNodes: AnalyticHierarchyData[] = [];
			let seriesMap: {[key: string]: Partial<AnalyticHierarchyData>} = {};
			_.each(data, (categoryNode, index) => { // first level contains categories
				if (categoryNode) {
					_.each(categoryNode._children, (pointNode) => { // second level is for series, which can be same among categories
						let identifier = pointNode._uniqName;
						let series = seriesMap[identifier];
						if (!series) {
							series = seriesMap[identifier] = {
								id: pointNode._uniqName,
								_name: pointNode._name,
								_uniqName: pointNode._uniqName,
								_isStatic: pointNode._isStatic,
								_children: _.map(new Array(data.length), () => null),
								_group: pointNode._group
							};
							seriesNodes.push(series as AnalyticHierarchyData);
						}
						pointNode._name = categoryNode._name; // need to switch names, as we are rotating tree structure
						if (withoutStack) {
							pointNode._group = categoryNode._group;
						}
						series._children[index] = pointNode;
					});
				}
			});
			return seriesNodes;
		};
	}

	// input: [treeNodesArray]
	dataLevelTransformer(names): (data: AnalyticHierarchyData[]) => AnalyticHierarchyData[] {
		return data => {
			return this.getLevelSeries(data, names);
		};
	}

	private getLevelSeries(topLevelNodes: AnalyticHierarchyData[], names: string[]): AnalyticHierarchyData[] {
		let series = [];
		let levelNodes = topLevelNodes || [];
		_.each(names, (name) => {
			let oneSeries = {
				_name: name,
				_children: levelNodes
			};
			series.push(oneSeries);
			levelNodes = this.getNextLevel(levelNodes);
		});
		return series;
	}

	private getNextLevel(levelNodes: AnalyticHierarchyData[]): AnalyticHierarchyData[] {
		return _.chain(levelNodes)
			.filter((node) => {
				return node && !node.leaf;
			}).map('_children')
			.flatten()
			.value();
	}

	applyAdditionalProperties(series: StudioHighchartsSeries[], seriesProps?: Array<(series, index?) => void>,
		pointProps?: Array<(point, index?, series?) => void>): void {
		_.each(series, (oneSeries, index) => {
			_.each(seriesProps, (applyFunc) => {
				if (applyFunc) {
					applyFunc(oneSeries, index);
				}
			});
			_.each(oneSeries.data, (point, pointIndex) => {
				_.each(pointProps, (applyFunc) => {
					if (applyFunc) {
						applyFunc(point, pointIndex, oneSeries);
					}
				});
			});
		});
	}

	applyDataProperties(series: StudioHighchartsSeries[], props: IDataProperties): void {
		series.forEach((oneSeries: StudioHighchartsSeries, index: number) => {
			oneSeries.type = props.type && props.type.toLowerCase();
			let oneSeriesColor = props.color ? props.color(oneSeries, index) : undefined;
			oneSeries.color = oneSeries.type === 'spline' && _.isObject(oneSeriesColor)
				? oneSeriesColor.pattern.color
				: oneSeriesColor;
			oneSeries.isPrimary = props.isPrimary;
			if (props.type === VisualizationType.BUBBLE) {
				oneSeries.marker = {
					lineWidth: 2,
					lineColor: '#ffffff'
				};
			}
			let seriesZIndex = this.highchartsUtils.getZIndexByChartType(props.type);
			if (seriesZIndex)
				oneSeries.zIndex = seriesZIndex;

			let color = props.color;
			// point color function is null for "inherit", so we use parent color function
			if (props.type === VisualizationType.SPLINE || props.type === VisualizationType.AREA) {
				if (props.pointColor !== null) {
					color = props.pointColor;
				} else if (props.color) {
					color = () => props.color({}, index); // pass single color string instead of function
				}
			}

			if (props.points !== undefined) {
				oneSeries.marker = {
					symbol: 'circle',
					enabled: props.points && !props.globalOtherExplorer
				};
			}

			oneSeries.data.forEach((point, pointIndex) => {
				if (!point) {
					if (props.size) {
						oneSeries.data[pointIndex] = {
							y: null,
							z: null
						};
					}
					return;
				}

				point.z = null;
				if (point.object) {
					point.object.colorType = props.colorType;
					point.z = props.size ? props.size(point.object) : null;
					if (color) {
						let useIndexPosition = props.useParentColor ||
								(props.isStacked && props.type !== VisualizationType.SPLINE
									&& props.stackedGroup.name !== StandardMetricName.POP);
						if (props.type === VisualizationType.SPLINE || props.type === VisualizationType.AREA) {
							point.color = color(point.object, pointIndex);
						} else if (props.isObjectBasedColor) {
							point.color = color(point.object, pointIndex);
						} else {
							let suggestedColor = color(point.object, useIndexPosition ? index : pointIndex);
							point.color = suggestedColor;
						}
					}
				}
				if (oneSeries.type === 'spline' && _.isObject(oneSeriesColor)) {
					point.color = point.color || oneSeriesColor;
				}
				point.isPrimary = props.isPrimary;
			});

			this.initializeFormatters(props, oneSeries);
		});
	}

	private initializeFormatters(props: IDataProperties, oneSeries: StudioHighchartsSeries): void {
		if (!props.metricFormatter) {
			return;
		}

		if (props.isStacked) {
			this.initializeStackedFormatters(props, oneSeries);
			return;
		}

		this.initializeDefaultFormatters(props, oneSeries);
	}

	private initializeStackedFormatters(props: IDataProperties, oneSeries: StudioHighchartsSeries): void {
		let stackedTooltipFormatter = this.highchartsTooltipFormatterUtil.getStackedTooltipFormatter(props);

		oneSeries.tooltip = {
			headerFormat: '',
			formatter: HighchartsClosureUtils.closureWrapper((scope) => {
				return stackedTooltipFormatter(scope);
			}),
			pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
				return stackedTooltipFormatter(scope);
			})
		};

		oneSeries.dataLabels = {
			formatter: HighchartsClosureUtils.closureWrapper((scope) => {
				let volume = scope.point.object.volume;
				if ((isEmpty(volume) || volume === 0)) {
					return null;
				}

				return oneSeries.metric === StandardMetricName.VOLUME
					? props.metricFormatter(scope.point.object.volume)
					: this.reportNumberFormatUtils.formatAny(volume);
			})
		};

		// if value is a percentage measurement, show the percentage as the data label
		if (this.isBarOrColumn(props) && this.isPercentMetric(oneSeries)) {
			oneSeries.dataLabels.formatter = HighchartsClosureUtils.closureWrapper((scope) => {
				return props.metricFormatter(scope.point.object[oneSeries.metric]);
			});
		}
	}

	private initializeDefaultFormatters(props: IDataProperties, oneSeries: StudioHighchartsSeries): void {
		let defaultTooltipFormatter = this.highchartsTooltipFormatterUtil.getDefaultTooltipFormatter(props);

		oneSeries.tooltip = {
			headerFormat: '',
			formatter: HighchartsClosureUtils.closureWrapper((scope) => {
				return defaultTooltipFormatter(scope);
			}),
			pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
				return defaultTooltipFormatter(scope);
			})
		};

		oneSeries.dataLabels = {
			formatter: HighchartsClosureUtils.closureWrapper((scope) => {
				return props.metricFormatter(scope.y);
			})
		};
	}

	private isBarOrColumn(props: IDataProperties): boolean {
		return (props.type === VisualizationType.BAR || props.type === VisualizationType.COLUMN);
	}

	private isPercentMetric(series: StudioHighchartsSeries): boolean {
		return (series.metric === StandardMetricName.PARENT_PERCENT || series.metric === StandardMetricName.PERCENT_OF_TOTAL);
	}

	applyColorProperties(series: StudioHighchartsSeries[], colorFunction: (item, index) => Color, reuseColors: boolean = false): void {
		let realIndex = 0; // need to use additional index to not skip colors, and to mix colors more (do not start with first every time)
		series.forEach((oneSeries) => {
			let colorMap = {};
			oneSeries.data.forEach((point, pointIndex) => {
				if (!reuseColors) {
					point.color = colorFunction ? colorFunction(point.object, pointIndex) : null;
				} else {
					if (colorMap[point.name]) {
						point.color = colorMap[point.name];
					} else {
						point.color = colorFunction ? colorFunction(point.object, realIndex) : null;
						colorMap[point.name] = point.color;
						realIndex++;
					}
				}
			});
		});
	}

	applyPieTooltipProperties(series: StudioHighchartsSeries[], props: IPieDataProperties, metric: ReportCalculation): void {
		if (props.formatter) {
			series.forEach((oneSeries) => {
				let defaultTooltip = (data: Partial<HighchartsFunctionScope>) => {
					let dataValue = data.rawValue !== undefined ? data.rawValue : data.y;
					let value = props.formatter(dataValue);

					return `<span style="font-size:10px">${data.name}</span><br/>`
						+ `<span style="color:${data.color ? data.color : data.series.color}">\u25CF</span>`
						+ ` ${this.getDisplayName(props.metric, metric)}:${value}`;
				};
				oneSeries.tooltip = {
					headerFormat: '',
					formatter: HighchartsClosureUtils.closureWrapper(scope => {
						return defaultTooltip(scope);
					}),
					pointFormatter: HighchartsClosureUtils.closureWrapper(scope => {
						return defaultTooltip(scope);
					})
				};
			});
		}
	}

	applyDataLabelsProperties(series: StudioHighchartsSeries[], showNames: boolean, showValues: boolean = false,
		dataFormatter?: IValueFormatter, displayField = 'name'): void {
		const SINGLE_NAME_LIMIT = 30;
		const NAME_VALUE_LIMIT = 25;
		if (showNames || (showValues && dataFormatter)) {
			let labelFormatter = (scope: Partial<HighchartsFunctionScope>) => {
				let labels = [];
				let displayValue = scope.point.rawValue !== undefined ? scope.point.rawValue : scope.y;

				if (showNames) {
					labels.push(formatName(scope.point[displayField], showValues ? NAME_VALUE_LIMIT : SINGLE_NAME_LIMIT));
				}
				if (showValues) {
					labels.push(dataFormatter(displayValue));
				}
				return labels.join(': ');
			};
			series.forEach((oneSeries) => {
				oneSeries.dataLabels = {
					formatter: HighchartsClosureUtils.closureWrapper(scope => labelFormatter(scope)),
					distance: showNames ? 10 : 30,
					enabled: true
				};
			});
		}
		function formatName(name: string, limit: number): string {
			if (name && name.length > limit) {
				return name.substring(0, limit - 3) + '...';
			} else {
				return name;
			}
		}
	}

	private getDisplayName(metricName: string, metric: ReportCalculation): string {
		if (metric && metric.name === metricName) {
			return metric.displayName;
		}
		return metricName;
	}

	applySizeProperties(series: StudioHighchartsSeries[], levelsCount: number, pieCount: number,
		donut: boolean = false, smaller: boolean = false): void {
		let areaSize = 1 / pieCount;

		let coef = smaller ? 0.3 : 0.2;

		series.forEach((oneSeries, ringIndex) => {

			let x = 1 - coef * (levelsCount - ringIndex);
			let size = areaSize * x * 100;
			let innerSize = ringIndex > 0 || donut ?
				(1 - coef / x) * 100 : 0;

			oneSeries.size = Math.round(size) + '%';
			oneSeries.innerSize = Math.round(innerSize) + '%';
		});
	}

	applyPositionProperties(series: StudioHighchartsSeries[], pieCount: number, groupIndex: number): void {
		series.forEach((oneSeries) => {
			let position = (50 / pieCount) + groupIndex * 100 / pieCount;
			oneSeries.center = [position + '%', '50%'];
		});
	}
}
app.service('highchartsAnalyticUtils', downgradeInjectable(HighchartsAnalyticUtils));
