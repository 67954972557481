
export const ConversationAttributes = {
	CB_CONV_DURATION: 'cb_conv_duration',
	CB_CONV_TOTAL_SILENCE: 'cb_conv_total_silence',
	CB_CONV_TOTAL_DEAD_AIR: 'cb_conv_total_dead_air',
	CB_CONV_TOTAL_HESITATION: 'cb_conv_total_hesitation',
	CB_CONV_TOTAL_OVERTALK: 'cb_conv_total_overtalk',
	CB_CONV_PERCENT_SILENCE: 'cb_conv_percent_silence',
	CB_CONV_PERCENT_AGENT: 'cb_conv_percent_agent',

	CB_CONV_PARTICIPANT_ID: 'cb_conv_participant_id',
	CB_CONV_PARTICIPANT_TYPE: 'cb_conv_participant_type',
	CB_CONV_PARTICIPANT_KIND: 'cb_conv_participant_kind',
	CB_CONV_SENTENCE_START_TIME_MS: 'cb_conv_sentence_start_time_ms',
	CB_CONV_SENTENCE_DURATION_MS: 'cb_conv_sentence_duration_ms',

	CB_SENTENCE_WORD_COUNT: 'cb_sentence_word_count',
	CB_INTERACTION_TYPE: 'cb_interaction_type',

	CB_CONV_LONGEST_SILENCE: 'cb_conv_longest_silence',

	CB_VTT_FILE_ID: 'cb_vtt_file_id'
};


