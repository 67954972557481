import { NgModule } from '@angular/core';
import { KeepAliveDirective } from '@app/modules/user/keep-alive.directive';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';

import { SharedModule } from '@app/shared/shared.module';
import { UserCustomFieldComponent } from './user-custom-field.component';
import { NewPasswordFormComponent } from './new-password-form/new-password-form.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { LoginModule } from '../login/login.module';
import { LoginBannerComponent } from './login-banner.component';
import { UserEmailSearchComponent } from './user-email-search/user-email-search.component';
import { IconModule } from '@app/modules/icon/icon.module';

@NgModule({
	imports: [
		SharedModule,
		LoginModule,
		IconModule
	],
	exports: [
		KeepAliveDirective,
		UserCustomFieldComponent,
		NewPasswordFormComponent,
		ResetPasswordFormComponent,
		LoginBannerComponent,
	],
	declarations: [
		KeepAliveDirective,
		UserCustomFieldComponent,
		NewPasswordFormComponent,
		ResetPasswordFormComponent,
		PasswordStrengthComponent,
		LoginBannerComponent,
		UserEmailSearchComponent,
	],
	providers: [
		UserSessionApiService,
		UserPropertiesApiService
	],
})
export class UserModule {}
