import EngagorCaseDate from './engagor-case-date';

export enum EngagorActionType {
	CASE_CREATED = 'case_created',
	STATUS = 'status',
	ASSIGN = 'assign',
	PROPERTY = 'property',
	MARK_TODO = 'mark_todo'
}

export enum EngagorStatus {
	DONE = 'done',
	NEW = 'new',
}

export default class EngagorCaseAction {
	type: EngagorActionType;
	description: string;
	detail: string;
	date: EngagorCaseDate;
	metadata: any;
}
