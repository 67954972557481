
export default class OAuthScope {
	name: string;

	explicit: boolean;
	extendable: boolean;

	accessTokenValiditySeconds: number;
	refreshTokenValiditySeconds: number;

	authorizedGrantTypes: string[];
}
