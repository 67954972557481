import * as _ from 'underscore';
import { PausingWrapper } from '@cxstudio/dashboards/optimization/pausing-wrapper';


export class DashboardOptimization {

	private paused: boolean;

	constructor(

	) {

	}

	private isPaused = (): boolean => this.paused;

	pauseWatchers = () => this.paused = true;
	resumeWatchers = () => this.paused = false;

	wrapSupplier = (supplier: () => any): () => any => {
		return PausingWrapper.wrap(supplier, this.isPaused);
	}

}

app.service('dashboardOptimization', DashboardOptimization);
