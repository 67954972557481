import { SpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { LinkSharingSettings } from '@cxstudio/master-accounts/settings-components/link-sharing-settings';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { EngagorSettings } from '@cxstudio/system-administration/entities/engagor-settings.class';
import { ConnectorsSettings } from '@cxstudio/system-administration/master-accounts/integrations/connectors-settings';
import { PredictionSettings } from '@cxstudio/system-administration/master-accounts/integrations/prediction-settings';
import { ExternalAuthSettings } from '@cxstudio/system-administration/master-accounts/integrations/external-auth-settings';
import { VoiceSettings } from '@cxstudio/system-administration/master-accounts/integrations/voice-settings';
import { SMTPServerSettings } from '@cxstudio/system-administration/master-accounts/integrations/smtp-server-settings';
import { WidgetCacheSettings } from '@app/modules/account-administration/settings/widget-cache-settings/widget-cache-settings.component';
import { ScreeningSettings } from '@app/modules/account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { QualtricsApiIntegration, QualtricsIntegration } from '@cxstudio/system-administration/master-accounts/integrations/qualtrics-integration';
import { QualtricsEmbeddingSettings } from '@app/modules/account-administration/properties/qualtrics-embedding-panel/qualtrics-embedding-settings-panel.component';

export type TuningSuggestionsSettings = {[key in SuggestionType]: boolean};

export class MasterAccountProperties {
	dashboardDiscussions: boolean;
	dashboardTemplates: boolean;
	dashboardRating: boolean;
	ideasForum: boolean;
	defaultDashboardOptimization: boolean;
	customBranding: boolean;
	membershipDomainRestricted: boolean;
	externalRedirectDomainRestricted: boolean;
	quickTranslate: boolean;
	accountOwnerManageObjects: boolean;
	customField: string;
	slaForCases: any;
	membershipDomainNames: string[];
	externalRedirectDomainNames: string[];
	externalAuthSettings: ExternalAuthSettings;

	engagorSettings: EngagorSettings;
	connectorsSettings: ConnectorsSettings;
	smtpServerSettings: SMTPServerSettings;
	predictionSettings: PredictionSettings;
	voiceSettings: VoiceSettings;

	defaultPalette: string;
	linkSharingSettings: LinkSharingSettings;
	spineSettings: SpineSettings;
	widgetCacheSettings: WidgetCacheSettings;
	unitIds: number[];
	maxCustomerAdmins: number;
	tuningSuggestionsSettings: TuningSuggestionsSettings;
	screeningSettings: ScreeningSettings;
	widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings;
	qualtricsIntegrationSettings: QualtricsIntegration;
	qualtricsApiIntegrationSettings: QualtricsApiIntegration;
	qualtricsEmbeddingSettings: QualtricsEmbeddingSettings;
	exportUsersAndGroups: boolean;
}

export class ExternalRedirectDomains {
	enabled: boolean;
	domains: string[];
}
