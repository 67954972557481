export interface UserUploadPreview {
	columns: UserUploadPreviewColumn[];
	rows: UserUploadPreviewRow[];
	taskId?: string;
}

export interface UserUploadPreviewColumn {
	field: string;
	label?: string;
	filterable?: boolean;
}

export interface UserUploadPreviewRow {
	user: UserUploadEntity;
	groups: string[];
	validationStatus: UserUploadValidationStatus;
	messages?: string[];
	errorFields?: UserUploadErrorField[];
	rowNumber?: number;
}

export interface UserUploadEntity {
	emailAddress: string;
	licenseTypeName: string;
	firstName: string;
	lastName: string;
	password: string;
	authUniqueId: string;
	customField: string;
	xmAccountId: string;
	xmGlobalUserId: string;
	toBeDisabled: string;
	groups?: string[];
}

export enum UserUploadErrorField {
	EMAIL = 'email',
	GROUP_NAME = 'groupName',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	LICENSE_TYPE = 'licenseType',
	AUTH_UNIQUE_ID = 'authUniqueId',
	XM_ACCOUNT_ID = 'xmAccountId',
	XM_GLOBAL_USER_ID = 'xmGlobalUserId',
	PASSWORD = 'password',
	DISABLE = 'disable'
}


export enum UserUploadValidationStatus {
	VALID = 'VALID',
	INVALID = 'INVALID',
	DUPLICATE = 'DUPLICATE'
}
