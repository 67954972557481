<table class="table br-list-box">
	<thead>
		<tr>
			<th *ngFor="let column of columns" class="table-column-header" >
				{{column}}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let document of documents" class="document-case-row">
			<td title="{{document.naturalId }}">{{document.naturalId}}</td>
			<td *ngIf="!xflowTemplateSelected" title="{{ topic.name }}">{{ topic.name }}</td>
			<td *ngIf="!xflowTemplateSelected">
				<input class="w-100-percent case-creation-bulk-title"
					[disabled]="isTitleLocked"
					[(ngModel)]="document.caseTitle.text"
					[mention]="caseTitleVariables"
					[mentionConfig]="titleVariableMentioConfig"
					[maxlength]="CASE_TITLE_LIMIT">
			</td>

			<td *ngIf="!xflowTemplateSelected">
				<alert-assignee-selector
					[assignees]="engagorAssignees"
					[assignee]="document.caseAssignee"
					[nonclickable]="isAssigneeLocked"
					[withHierarchyAssignee]="withHierarchyAssignee"
					(onSelectAssignee)="setAssignee($event.assignee, document)"
					(onClearAssignee)="clearAssignee(document)">
				</alert-assignee-selector>
			</td>

			<td *ngIf="!xflowTemplateSelected">
				<div *ngIf="isPriorityLocked">
					<span class="priority" [ngStyle]="{color: nonEditablePriority.iconColor}">
						<span class="priority-base q-icon-priority-4"></span>
						<span class="priority-level q-icon-priority-{{nonEditablePriority.level}}"></span>
					</span>{{nonEditablePriority.displayName}}
				</div>
				<div *ngIf="!isPriorityLocked" class="case-creation-bulk-priority">
					<priority-selection [(model)]="document.casePriority"></priority-selection>
				</div>
			</td>

			<td>
				<input class="case-creation-bulk-notes" [(ngModel)]="document.notes"  [maxlength]="MAX_LENGTH">
			</td>
		</tr>
	</tbody>
</table>
