import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { DialogStyle } from '../dialog-style';

@Component({
	selector: 'confirm-dialog',
	template: `
		<simple-dialog
			[header]="header"
			[value]="value"
			[okBtnName]="okBtnName"
			[dialogStyle]="dialogStyle"
			[cancelBtnName]="cancelBtnName">
			<div>{{value}}</div>
		</simple-dialog>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {
	@Input() header: string;
	@Input() value: string;
	@Input() dialogStyle: DialogStyle = DialogStyle.REGULAR;

	okBtnName: string;
	cancelBtnName: string;

	constructor(private locale: CxLocaleService) { }

	ngOnInit(): void {
		this.okBtnName = this.locale.getString('common.ok');
		this.cancelBtnName = null;
	}

}
