<collapsing-panel [ngBusy]="loading">
	<panel-heading>
		<h3 [i18n]="'userDialog.qualtricsAccountConnectHeader'"></h3>
	</panel-heading>
	<panel-body>
		<p class="pb-16" [i18n]="'userDialog.qualtricsAccountConnectInstruction'"></p>
		<div class="d-flex justify-between align-items-center">
			<div class="d-flex align-items-center mr-16">
				<label for="qualtricsAccountConnect" class="control-label mr-8 font-bold">{{'userDialog.qualtricsAccountConnectButtonLabel'|i18n}}:</label>
				<button *ngIf="!isQualtricsAccountLinked()"
					id="qualtricsAccountConnect"
					class="btn btn-secondary"
					(click)="openQualtricsLoginPopup($event)">
					{{'userDialog.qualtricsAccountConnectButtonText'|i18n}}
				</button>
				<span *ngIf="isQualtricsAccountLinked()">{{getXmAccountId()}}</span>
			</div>

			<div>
				<div
					*ngIf="isQualtricsAccountLinked()"
					class="text-success-400 border-success-400 border-2 border-solid p-4">
					<strong class="d-flex align-items-center">
						<icon class="lh-1 mr-4" name="check"></icon>
						{{'userDialog.qualtricsAccountConnectVerified'|i18n}}
					</strong>
				</div>
				<div
					*ngIf="!isQualtricsAccountLinked()"
					class="lh-1 border-2 border-solid p-4">
					<strong [i18n]="'userDialog.qualtricsAccountConnectUnverified'"></strong>
				</div>
			</div>
		</div>
	</panel-body>
</collapsing-panel>
