import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CxLocaleService } from '@app/core';
import { EmbedDashboardEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { HttpPromise } from '@app/core/http-promise';
import { HTTPStatusCode } from '@app/core/http-status-code.enum';
import { EmbedTabMetadata } from '@app/modules/book/book-view-tab';
import { QualtricsApiService } from '@app/modules/widget-visualizations/qualtrics/qualtrics-api.service';
import { QualtricsEmbedData, QualtricsUserInfo } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'embed-dashboard',
	template: `
	<div #iframeContainer
		class="flex-fill w-100-percent h-100-percent border-0 border-t-1 border-solid border-color-10"
		[ngBusy]="loading">
		<div class="iframe-focus-bumber" tabindex="0"></div>
		<iframe *ngIf="!!dashboardUrl && !hasError()"
			width="100%"
			height="100%"
			[src]="dashboardUrl"
			[title]="name"
			frameborder="0">
		</iframe>
		<qualtrics-auth-button *ngIf="showAuthButton"
			class="d-flex align-center justify-center h-80-percent"
			[message]="'login.signIntoXmEngageMessage' | i18n"
			(reload)="reloadAfterAuthentication()">
		</qualtrics-auth-button>
		<qualtrics-auth-error *ngIf="hasError()"
			class="d-flex align-center justify-center h-80-percent"
			[message]="errorMessage"
			[userInfo]="userInfo"
			(reload)="performLogoutAndReload()">
		</qualtrics-auth-error>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedDashboardComponent extends SelfCleaningComponent implements OnInit {

	@Input() bookId: number;
	@Input() active: boolean;
	@Input() name: string;
	@Input() metadata: EmbedTabMetadata;
	@Output() embedDataLoaded = new EventEmitter<QualtricsEmbedData>();
	@ViewChild('iframeContainer') iframeContainer: ElementRef<HTMLElement>;

	loading: PromiseLike<any>;
	dashboardUrl: SafeResourceUrl;
	errorMessage: string;
	userInfo: QualtricsUserInfo;
	showAuthButton: boolean;

	constructor(
		private readonly qualtricsApi: QualtricsApiService,
		private readonly domSanitizer: DomSanitizer,
		private readonly ref: ChangeDetectorRef,
		private readonly eventBus: GlobalEventBus,
		private readonly locale: CxLocaleService,
		@Inject('security') private security: Security,
	) {
		super();
	}

	ngOnInit(): void {
		this.addListener(this.eventBus.subscribe(EmbedDashboardEvent.RELOAD, (event, embedDashboardId: string) => {
			if (this.active && embedDashboardId === this.metadata.embedDashboardId) {
				this.reload();
			}
		}));
		this.reload();
	}

	private reload(): void {
		delete this.showAuthButton;
		delete this.errorMessage;

		if (!this.security.isQualtricsDashboardEmbeddingEnabled()) {
			this.errorMessage = this.locale.getString('dashboard.qualtricsEmbeddingDisabled');
			this.ref.detectChanges();
			return;
		}

		this.loading = this.qualtricsApi.getQualtricsEmbedDashboardData(this.bookId, this.metadata.embedDashboardId).then(response => {
			this.dashboardUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(response.data.embedUrl);
			this.embedDataLoaded.emit(response.data);
			this.ref.markForCheck();
		}, (reason) => {
			if (reason.status === HTTPStatusCode.NEED_AUTHENTICATION) {
				this.showAuthButton = true;
			} else if (reason.status === HTTPStatusCode.FORBIDDEN){
				this.errorMessage = this.locale.getString('dashboard.noPermissionEmbeddingThisContent');

				this.loading = this.qualtricsApi.getUserInfo().then(userData => {
					this.userInfo = userData;
					this.ref.markForCheck();
				}, () => this.handleUnknownUser());
			} else if (reason.status === HTTPStatusCode.NO_IMPERSONATION) {
				this.errorMessage = this.locale.getString('dashboard.embeddingNotAvailableInImpersonate');
			} else {
				this.errorMessage = reason.data;

				this.loading = this.qualtricsApi.getUserInfo().then(userData => {
					this.userInfo = userData;
					this.ref.markForCheck();
				}, () => this.handleUnknownUser());
			}

			this.ref.markForCheck();
		});
		this.ref.detectChanges();
	}

	hasError(): boolean {
		return !!this.errorMessage;
	}

	reloadAfterAuthentication(): void {
		this.reload();
	}

	performLogoutAndReload(): void {
		this.qualtricsApi.performUserLogout().then(() => {
			this.reload();
		});
	}

	private handleUnknownUser(): void {
		this.userInfo = {
			firstName: this.locale.getString('error.unknown'),
			lastName: '',
			brandId: this.locale.getString('error.unknown'),
		} as QualtricsUserInfo;
		this.ref.markForCheck();
	}
}
