
interface IFolderState {
	collapsed: boolean;
}

export class DashboardFoldersState {

	private folderState: { [folderId: number]: IFolderState } = {};

	saveFolderState = (folderId: number, collapsed: boolean) => {
		this.folderState[folderId] = {
			collapsed
		};
	}

	getFolderState = (folderId) => {
		return this.folderState[folderId];
	}
}

app.service('dashboardFoldersState', DashboardFoldersState);
