import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CanvasUtils {
	private canvas: HTMLCanvasElement;

	constructor() {
		this.canvas = document.createElement('canvas');
	}

	getTextWidth = (text: string, font: string, letterSpacing: number = 0): number => {
		let context: CanvasRenderingContext2D = this.canvas.getContext('2d');
		context.font = font;
		let metrics = context.measureText(text);
		return metrics.width + letterSpacing * text.length;
	}
}
