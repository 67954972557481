import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountVoiceStatus } from './master-account-voice-status';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { VoiceStatusApiService } from '@app/modules/system-administration/status/integration-status/voice/voice-status-api.service';
import { VoiceStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/voice/voice-status-table-columns.service';

@Injectable({providedIn: 'root'})
export class VoiceStatusService implements IntegrationStatusService<MasterAccountVoiceStatus> {
	constructor(
		private voiceStatusApiService: VoiceStatusApiService,
		private voiceStatusTableColumnsService: VoiceStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.voice';
	}

	getColumns(): TableColumn<MasterAccountVoiceStatus>[] {
		return this.voiceStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountVoiceStatus[]> {
		return this.voiceStatusApiService.getVoiceStatuses();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.voiceStatusApiService.export(search);
	}
}
