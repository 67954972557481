import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { Pill, PillType } from '@app/modules/pills/pill';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { ParticipantEnrichmentTypes, SpineLane, SpineLaneStyle } from '@cxstudio/conversation/entities/spine-lane.class';
import { OvertalkOptions } from '@cxstudio/conversation/overtalk-options.class';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ConversationTooltipUtils } from '@cxstudio/reports/document-explorer/conversations/conversation-tooltip-utils.class';
import { PreviewSentence, SentenceScorecardTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import * as cloneDeep from 'lodash.clonedeep';
import { GroupColorTypeService, PredefinedMetricColorTypeDefinition } from '@cxstudio/reports/utils/color/group-color-type.service';

@Injectable({
	providedIn: 'root'
})
export class ConversationEnrichmentUtils {

	constructor(
		private locale: CxLocaleService,
		@Inject('GroupColorType') private GroupColorType: GroupColorTypeService,
	) {}

	getNLPEnrichment(lane: SpineLane, worldAwareness: any[], attributes: any[]): ConversationEnrichment {
		const attributeMatch = _.find(worldAwareness, {name: lane.definition.name})
			|| _.find(attributes, {name: lane.definition.name});

		const inclusionList = lane.definition.inclusionList;

		const attributeName: string =  attributeMatch ? (attributeMatch as any).displayName : lane.definition.name;
		const getValue = data => {
			const values = data?.attributes?.[lane.definition.name];
			return _.isEmpty(inclusionList) ? values : _.intersection(inclusionList, values);
		};
		const tooltipFormatter = lane.definition.type === SpineLaneStyle.emoticon
			? (data) => {
				const valueList = getValue(data);
				const textSizeClass = 'emoticon-pill';
				return valueList ? valueList.map(value => ConversationTooltipUtils.simpleEnrichmentText(value, textSizeClass)).join('') : '';
			} : ConversationTooltipUtils.iconTooltipFormatter(
				() => lane.definition.customColor,
				(data) => {
					const valueList = getValue(data);
					return `${attributeName}: ${valueList.join(', ')}`;
				},
				this.getHeaderIconFromLaneDefinition(lane));
		return {
			hasValue: data => {
				return this.attrHasValues(data, lane) &&
					!!(_.isEmpty(inclusionList) || _.intersection(inclusionList, data?.attributes?.[lane.definition.name]).length);
			},
			getValue,
			tooltipFormatter,
			getName: () => attributeName,
			getHeaderIcon: this.getHeaderIconFromLaneDefinition(lane),
			getColorArray: () => [lane.definition.customColor],
			getColorIndex: () => 0 // only solid color
		};
	}

	getMetricEnrichment(lane: SpineLane, predefinedMetrics: Metric[]): ConversationEnrichment {
		let type = lane.definition.name;
		let fiveBand = this.isFiveBandBreakdown(lane);
		switch (type) {
			case SingleLaneEnrichmentTypes.SENTIMENT:
			case ParticipantEnrichmentTypes.SENTIMENT: {
				let colorType = fiveBand ? this.GroupColorType.types.SENTIMENT_5 : this.GroupColorType.types.SENTIMENT_3;
				let sentimentMetric = _.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.SENTIMENT});
				return this.buildMetricEnrichment(sentimentMetric, 'dScore', colorType, 'q-icon-thumb-up', lane.definition.inclusionList);
			}
			case SingleLaneEnrichmentTypes.EFFORT:
			case ParticipantEnrichmentTypes.EFFORT: {
				let colorType = fiveBand ? this.GroupColorType.types.EASE_5 : this.GroupColorType.types.EASE_3;
				let effortMetric = _.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.EASE_SCORE});
				return this.buildMetricEnrichment(effortMetric, 'easeScore', colorType, 'q-icon-effort', lane.definition.inclusionList);

			}
			case SingleLaneEnrichmentTypes.EMOTIONAL_INTENSITY:
			case ParticipantEnrichmentTypes.EMOTION: {
				let emotionMetric = _.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.EMOTION});
				return this.buildMetricEnrichment(emotionMetric, 'emotionIntensity', this.GroupColorType.types.EMOTION, 'q-icon-intensity',
					lane.definition.inclusionList);
			}
			default: return undefined;
		}
	}

	generateOvertalkEnrichment(): Omit<ConversationEnrichment, 'getName' | 'getHeaderIcon'> {
		return {
			hasValue: data => data.isOvertalk,
			tooltipFormatter: ConversationTooltipUtils.iconTooltipFormatter(
				() => OvertalkOptions.OVERTALK_COLOR,
				() => this.locale.getString('docExplorer.overtalk'),
				() => 'q-icon-bubbles'),
		};
	}

	private isFiveBandBreakdown = (lane: SpineLane): boolean => {
		return lane.definition?.bandColor?.contains(PredefinedMetricConstants.SUBTYPE_5);
	}

	private buildMetricEnrichment(metric: Metric, field: string, groupType: any, headerIcon: string,
			include?: string[]): ConversationEnrichment {
		if (!metric)
			return undefined;
		metric = cloneDeep(metric);
		metric.name = field; // sentence fields are different

		let byNumber = true;
		let categoryFunction = groupType.getCategoryFunction(metric, byNumber);
		let textFunction = data => categoryFunction(data).name;
		return {
			hasValue: data => data[metric.name] !== undefined
				&& (_.isEmpty(include) || include.contains(categoryFunction(data).id)),
			tooltipFormatter: ConversationTooltipUtils.iconTooltipFormatter(
				groupType.getColorFunction(metric, byNumber),
				textFunction,
				groupType.getIconFunction(metric)),
			getColorArray: () => groupType.getColorArray(metric),
			getColorIndex: data => categoryFunction(data).order,
			getName: () => this.locale.getString(metric.displayName),
			getHeaderIcon: () => headerIcon,
			useFiveBandPalette: () => groupType.getColorArray(metric).length === 5,
		};
	}

	private getHeaderIconFromLaneDefinition(lane: SpineLane): () => string {
		return () => {
			if (lane.definition.type === SpineLaneStyle.emoticon)
				return 'q-icon-sentiment-positive';
			else return (lane.definition.icon === SecondaryTrackRendererType.DIAMOND) ?
				'q-icon-diamond' :
				'q-icon-circle';
		};
	}

	private attrHasValues(data, lane): boolean {
		return !!(data?.attributes?.[lane.definition.name]);
	}

	getAllPills(sentences: PreviewSentence[], predefinedMetrics: Metric[]): Pill[] {
		let allPills: Pill[] = _.union(
			this.getSentimentPills(sentences, this.getPredefinedMetric(predefinedMetrics, PredefinedMetricConstants.SENTIMENT)),
			this.getEaseScorePills(sentences, this.getPredefinedMetric(predefinedMetrics, PredefinedMetricConstants.EASE_SCORE)),
			this.getEmotionPills(sentences, this.getPredefinedMetric(predefinedMetrics, PredefinedMetricConstants.EMOTION)),
			this.getScorecardPills(sentences));

		return _.uniq(allPills, (pill) => {
			if (pill.type === PillType.SCORECARD) {
				return `${pill.type}_${pill.value.id}_${pill.name}`;
			} else {
				return `${pill.type}_${pill.name}`;
			}
		});
	}

	private getPredefinedMetric(predefinedMetrics: Metric[], name: PredefinedMetricConstants): Metric {
		let predefinedMetric = _.find(predefinedMetrics, {name});

		if (!predefinedMetric) {
			return;
		}

		predefinedMetric = cloneDeep(predefinedMetric);

		// sentence sentiment value field
		if (name === PredefinedMetricConstants.SENTIMENT) {
			predefinedMetric.name = 'dScore';
		}

		return predefinedMetric;
	}

	private getSentimentPills(sentences: PreviewSentence[], sentimentMetric: Metric): Pill[] {
		if (_.isUndefined(sentimentMetric)) return [];

		let isNeutralFunction = this.GroupColorType.types.SENTIMENT_5.getNeutralFunction(sentimentMetric);
		let categoryFunction = (this.GroupColorType.types.SENTIMENT_5 as PredefinedMetricColorTypeDefinition)
			.getCategoryFunction(sentimentMetric, true);
		let colorFunction = (this.GroupColorType.types.SENTIMENT_5 as PredefinedMetricColorTypeDefinition)
			.getColorFunction(sentimentMetric, true);
		let iconFunction = this.GroupColorType.types.SENTIMENT_5.getIconFunction(sentimentMetric);

		return _.chain(sentences)
			.filter((sentence: ConversationSentence) => {
				return !_.isUndefined(sentence.dScore) && !isNeutralFunction(sentence);
			})
			.map((sentence: ConversationSentence) => {
				let name = categoryFunction(sentence).name;
				const title = `${name} ${this.locale.getString(sentimentMetric.displayName)}`;
				let color = colorFunction(sentence);
				let icon = iconFunction(sentence);

				return this.createPill(name, title, PillType.SENTIMENT, color, icon, '', '', sentence);
			})
			.value();
	}

	private getEaseScorePills(sentences: PreviewSentence[], easeScoreMetric: Metric): Pill[] {
		if (_.isUndefined(easeScoreMetric)) return [];

		return this.getBreakdownMetricPills(sentences, PillType.EASE_SCORE,
			this.GroupColorType.types.EASE_3, easeScoreMetric, (sentence) => sentence.easeScore);
	}

	private getEmotionPills(sentences: PreviewSentence[], emotionMetric: Metric): Pill[] {
		if (_.isUndefined(emotionMetric)) return [];

		return this.getBreakdownMetricPills(sentences, PillType.EMOTION,
			this.GroupColorType.types.EMOTION, emotionMetric, (sentence) => sentence.emotionIntensity);
	}

	private getScorecardPills(sentences: PreviewSentence[]): Pill[] {
		return _.chain(sentences)
			.map(sentence => {
				return sentence.scorecardTopics || {};
			})
			.map(topicMap => Object.values(topicMap))
			.flatten()
			.sortBy((topic: SentenceScorecardTopic) => -topic.weight)
			.uniq(true, (topic: SentenceScorecardTopic) => topic.id)
			.map((topic: SentenceScorecardTopic) => {
				let name = topic.rebutted
					? this.locale.getString('docExplorer.rebuttedResultTopic', {topic: topic.name})
					: topic.name;
				let color = ScorecardDocExplorerUtils.getTopicColor(topic);
				let icon = ScorecardDocExplorerUtils.getTopicIcon(topic);
				let additionalClass = `scorecard-sentence-pill scorecard-${topic.scorecardId}`;
				return this.createPill(name, name, PillType.SCORECARD, color, icon, topic, additionalClass,
					sentences[0]); // this is only used in audit mode, where there is 1 sentence per item
			})
			.value();
	}

	private getBreakdownMetricPills(sentences: PreviewSentence[], type: PillType, metricType, metric: Metric, valueFunction): Pill[] {
		let colorFunction = metricType.getColorFunction(metric, true);
		let iconFunction = metricType.getIconFunction(metric);
		let categoryFunction = metricType.getCategoryFunction(metric, true);

		return _.chain(sentences)
			.filter((sentence: ConversationSentence) => {
				let value = valueFunction(sentence);
				return !_.isUndefined(value) && value !== 'NaN';
			})
			.map((sentence: ConversationSentence) => {
				let name = categoryFunction(sentence, valueFunction).name;
				const title = `${name} ${this.locale.getString(metric.displayName)}`;
				let color = colorFunction(sentence, valueFunction);
				let icon = iconFunction(sentence);

				return this.createPill(name, title, type, color, icon, null, '', sentence);
			})
			.value();
	}

	private createPill(name: string, title: string, type: PillType, color: string, icon: string,
		value?: any, additionalClass?: string, sentence?: ConversationSentence): Pill {
		return {
			name,
			type,
			title,
			value,
			color,
			icon,
			additionalClass,
			sentence
		};
	}
}

app.service('conversationEnrichmentUtils', downgradeInjectable(ConversationEnrichmentUtils));
