import { Pipe, PipeTransform } from '@angular/core';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { AgGridNestable } from '../types/ag-grid-nestable.interface';
import { AgGridNested } from '../types/ag-grid-nested.interface';

@Pipe({
	name: 'nestedObjects'
})
export class NestedObjectsPipe<T extends AgGridNestable> implements PipeTransform {

	transform(flatTree: T[]): T[] & AgGridNested[] {
		let itemMap: {[key: string]: T & AgGridNested} = {};
		_.each(flatTree, item => itemMap[item.id] = item as T & AgGridNested);
		_.each(flatTree, (item: T & AgGridNested) => item.path = this.getItemPath(item, itemMap));
		return flatTree as (T & AgGridNested)[];
	}

	private getItemPath(item: T & AgGridNested, itemMap: {[key: string]: T & AgGridNested}): string[] {
		if (!item)
			return [];
		let path = [this.getPathKey(item)];
		if (item.parentId === 0) {
			return path;
		} else if (!_.isUndefined(item.parentId)) {
			return this.getItemPath(itemMap[item.parentId], itemMap).concat(path);
		} else if (!_.isUndefined(TreeListTransformUtils.getParentKey(item))) {
			item.parentId = TreeListTransformUtils.getParentKey(item);
			return this.getItemPath(itemMap[item.parentId], itemMap).concat(path);
		} else {
			return path;
		}
	}

	private getPathKey(item: AgGridNestable): string {
		return `item-${item.id}`;
	}
}
