import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { CxLocaleService } from '@app/core';
import { HierarchyComponentInput } from '../hierarchy-component-input';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { HierarchyPublicationState } from '@cxstudio/organizations/hierarchy-publication-state';
import { HierarchyWizardUtils } from '../hierarchy-wizard-utils';

@Component({
	templateUrl: './hierarchy-upload-wizard-submit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyUploadWizardSubmitDialogComponent
	implements OnInit, IModalComponent<HierarchyComponentInput> {

	@Input() input: HierarchyComponentInput;

	loading: Promise<any>;
	publishConfiguration: any;

	constructor(
		private organizationApiService: OrganizationApiService,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		// defaults
		this.publishConfiguration = {
			includingEmptyRecords: false,
			incrementalMode: true,
			runDeltaClassification: false
		};
	}

	private hierarchyHasModel(): boolean {
		return HierarchyWizardUtils.hierarchyHasModel(this.input.hierarchy);
	}

	isUpdatingPublishedHierarchy(): boolean {
		return this.input.hierarchy.hasUpdate && this.hierarchyHasModel();
	}
	
	cancel(): void {
		this.modal.dismiss();
	}

	submit(): void {
		if (this.input.hierarchy.hasUpdate) {
			this.organizationApiService.applyUpdate(this.input.hierarchy.id, this.publishConfiguration);
		} else {
			this.organizationApiService.publishHierarchy(this.input.hierarchy.id, this.publishConfiguration);
		}

		this.modal.close();
	}

	getHeader(): string {
		if (this.input.hierarchy.hasUpdate) {
			return this.locale.getString('organization.readyToSubmit');
		}
		return this.locale.getString('organization.publishingDialogHeader');
	}

}
