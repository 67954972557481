import { Component, OnInit, ChangeDetectionStrategy, Input, Output, Inject, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UpgradeComponentState, UpgradeSession, UpgradeState } from '@app/modules/system-administration/upgrade/upgrade-session/upgrade-session';
import { UpgradeApiService } from '../upgrade-api.service';

@Component({
	selector: 'upgrade-session',
	templateUrl: './upgrade-session.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeSessionComponent implements OnInit {
	@Input() session: UpgradeSession;
	@Input() upgradingNow: boolean;
	@Output() stateChange = new EventEmitter<void>();

	constructor(
		private upgradeApiService: UpgradeApiService,
	) { }

	ngOnInit(): void {
	}

	buildStateClassObject = (state: UpgradeState) => {
		return {
			'alert-danger': state === UpgradeState.FAIL
				|| state === UpgradeState.INTERRUPTED,
			'alert-success': state === UpgradeState.SUCCESS
				|| state === UpgradeState.SKIPPED,
			'alert-warning': state === UpgradeState.RUNNING,
			'alert-info': state === UpgradeState.REJECTED
		};
	}

	buildComponentStateLabel = (componentState: UpgradeComponentState) => {
		return componentState.state === UpgradeState.RUNNING
			? `${componentState.state}: ${(componentState.progress * 100).toFixed(2)}%`
			: componentState.state;
	}

	canReject = (componentState: UpgradeComponentState): boolean => {
		return (componentState.state === UpgradeState.SUCCESS || componentState.state === UpgradeState.SKIPPED)
			&& !this.upgradingNow;
	}

	rejectComponent = (sessionId: number, componentId: number) => {
		this.upgradeApiService.rejectComponent(sessionId, componentId)
			.then(() => this.stateChange.emit());
	}
}

app.directive('upgradeSession', downgradeComponent({component: UpgradeSessionComponent}) as angular.IDirectiveFactory);
