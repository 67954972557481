import { Injectable } from '@angular/core';
import { JobSet } from './entities/job-set';
import { CxLocaleService } from '@app/core';
import { JobsApi, JobRequestParams } from './jobs.api.service';

@Injectable({
	providedIn: 'root'
})
export class JobsService {

	constructor(
		private jobsApi: JobsApi,
		private locale: CxLocaleService
	) {}

	getJobData = (status: JobSet, params?: JobRequestParams) => {
		let jobsCall;

		if (status === JobSet.SCHEDULED) {
			jobsCall = this.jobsApi.getScheduledJobs;
		} else if (status === JobSet.JOB_RUN) {
			jobsCall = this.jobsApi.getCompletedJobs;
		} else if (status === JobSet.OPTIONAL) {
			jobsCall = this.jobsApi.getOptionalJobs;
		} else return;

		return jobsCall(params).then((jobs) => {
			jobs.data.map(this.addJobIds);
			return {
				jobs: jobs.data,
				total: jobs.totalCount
			};
		});
	}

	getGroups = (status: JobSet) => {
		let groupsCall;

		if (status === JobSet.SCHEDULED) {
			groupsCall = this.jobsApi.getScheduledJobGroups;
		} else if (status === JobSet.JOB_RUN) {
			groupsCall = this.jobsApi.getCompletedJobGroups;
		} else return;

		return groupsCall().then(this.formatGroupData);
	}

	runNow = (job) => {
		this.jobsApi.runScheduledJobNow(job);
	}

	private formatGroupData = (groups: string[]) => {
		let returnGroups = groups.map((group) => {
			return {displayName: this.getGroupName(group), value: group};
		});
		returnGroups.unshift({displayName: this.locale.getString('dashboard.all'), value: ''});

		return returnGroups;
	}

	scheduleOptionalJob = (job): Promise<void> => {
		return this.jobsApi.scheduleOptionalJob(job.identity);
	}

	unscheduleOptionalJob = (job): Promise<void> => {
		return this.jobsApi.unscheduleOptionalJob(job.identity);
	}

	runOptionalJobNow = (job): Promise<void> => {
		return this.jobsApi.runOptionalJobNow(job.identity);
	}

	// used to protect against unknown group causing errors with localization
	// unknown group will just return a generic string
	private getGroupName = (group: string): string => {
		switch (group) {
		case 'dashboardRefreshGroup':
		case 'dashboardPdfDistrGroup':
		case 'dashboardPdfImmDistrGroup':
		case 'pdfUsersDistributionGroup':
		case 'application':
		case 'metricAlertGroup':
			return this.locale.getString('administration.' + group);
		default:
			return this.locale.getString('administration.dashboardJobUnknown');
		}
	}

	private addJobIds = (jobConfiguration) => {
		jobConfiguration.id = jobConfiguration.id
			|| jobConfiguration.identity
			|| jobConfiguration.triggerGroup + '_' + jobConfiguration.triggerName;
	}
}
