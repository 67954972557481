import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import TemplatesGridDefinition from '@app/modules/dashboard-templates/services/templates-grid-definition.service';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		TemplatesGridDefinition
	]
})
export class DashboardTemplatesModule {}
