import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SnoozeNotificationsComponent } from './snooze-notifications/snooze-notifications.component';
import { NotificationsApiService } from '@app/modules/notification/services/notifications-api.service';

@NgModule({
	imports: [
		SharedModule
	],
	exports: [
		SnoozeNotificationsComponent
	],
	declarations: [
		SnoozeNotificationsComponent
	],
	providers: [
		NotificationsApiService
	],
	schemas: []
})
export class NotificationModule {}
