import * as _ from 'underscore';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import CachedHttpFactory from '@cxstudio/common/cache/cached-http.factory';
import { HttpResponse } from '@cxstudio/common/http-response';
import { IFolderApi } from '@cxstudio/common/folders/folder-api.interface';

export class BaseFolderApi<T extends IFolderItem> implements IFolderApi<T> {

	protected folderUrl;
	constructor(
		protected baseUrl: string,
		protected $http: ng.IHttpService,
		protected treeService: TreeService,
		protected cache: CachedHttpFactory
	) {
		this.folderUrl = `${baseUrl}/folders`;
	}

	createFolder = (folderData: T): PromiseLike<T> => {
		return this.$http.post<T>(`${this.folderUrl}`, angular.toJson(this.treeService.copyItem(folderData)))
			.then(this.responseHandler) as PromiseLike<T>;
	}

	deleteFolder = (folderId: number): PromiseLike<void> => {
		return this.$http.delete(`${this.folderUrl}/${folderId}`)
			.then(this.invalidateCacheHandler) as PromiseLike<void>;
	}

	moveToFolder = (item: ITreeItem, folderId: number): PromiseLike<void> => {
		if (this.isFolder(item)) {
			return this.$http.put(`${this.folderUrl}/${item.id}/move-to/${folderId}`, null)
				.then(this.invalidateCacheHandler) as PromiseLike<void>;
		} else {
			return this.$http.put(`${this.baseUrl}/${item.id}/move-to/${folderId}`, null)
				.then(this.invalidateCacheHandler) as PromiseLike<void>;
		}
	}

	updateFolder = (folderId: number, folderData: T): PromiseLike<T> => {
		return this.$http.put<T>(`${this.folderUrl}/${folderId}`, angular.toJson(this.treeService.copyItem(folderData)))
			.then(this.responseHandler) as PromiseLike<T>;
	}

	protected invalidateCacheHandler = (): void => {
		if (this.cache) this.cache.invalidate();
	}

	protected responseHandler = <R>(response: HttpResponse<R>): R => {
		this.invalidateCacheHandler();
		return response?.data;
	}

	protected isFolder(item: ITreeItem): boolean {
		return /.*folder.*/i.test(item.type);
	}

}
