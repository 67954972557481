import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpPromise } from '@app/core/http-promise';
import { UserUploadPreviewColumn } from '@app/modules/user-administration/user-upload/user-upload-page/user-upload-preview';
import { UserBulkProcessApiService } from './user-update-api.service';

@Injectable({providedIn: 'root'})
export class UserUploadApiService implements UserBulkProcessApiService {

	constructor(
		private http: CxHttpService
	) {}

	downloadTemplateFile = (): HttpPromise<any> => {
		return this.http.rawGet('rest/users/upload/file/template', { responseType: 'arraybuffer' });
	}

	processFileUpload(taskId: string): Promise<any> {
		return this.http.post(`rest/users/upload/file/process/${taskId}`);
	}

	getColumns(): Promise<UserUploadPreviewColumn[]> {
		return this.http.get('rest/users/upload/file/columns');
	}
}
