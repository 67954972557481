import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'snapshot-date',
	template: `
<div [ngbTooltip]="getTooltip()" placement="bottom" class="d-flex flex-nowrap nowrap align-center ph-8">
	<span class='q-icon-calendar mr-4'></span>
	<span [i18n]="'dashboard.lastPublished'"></span>
	&nbsp;
	<span [title]="getDateTime()">{{getDate()}}</span>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnapshotDateComponent {
	@Input() date: string;

	constructor(
		@Inject('dateService') private readonly dateService: DateService,
		private readonly locale: CxLocaleService
	) { }

	getDateTime(): string {
		return this.dateService.format(moment(this.date).toDate(), DateTimeFormat.BASIC_DATE_TIME);
	}

	getDate(): string {
		return this.dateService.format(moment(this.date).toDate(), DateTimeFormat.BASIC_DATE);
	}

	getNextRunTimestamp(): string {
		let currentDate = new Date();
		let nextRunDate = currentDate.setDate(currentDate.getDate() + (7-currentDate.getDay()));
		return this.dateService.format(nextRunDate, DateTimeFormat.BASIC_DATE);
	}

	getTooltip(): string {
		return this.locale.getString('dashboard.snapshotLastUpdatedTooltip', {
			lastRun: this.getDate(),
			nextRun: this.getNextRunTimestamp()
		});
	}

}
