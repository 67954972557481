import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocationService } from '@app/core/cx-location.service';
import * as _ from 'underscore';

interface IUnloadHandler {
	name: string;
	onLeave: () => Promise<void>; // navigate away within studio
}

@Injectable({
	providedIn: 'root'
})
export class GlobalUnloadService {
	private handlers: {[name: string]: IUnloadHandler} = {};
	private lastPath: string; // used to avoid triggering the same handling after calling "location.url(path)"

	constructor(
		private location: CxLocationService,
	) {}

	onLocationChange(event: ng.IAngularEvent): void {
		const path = this.location.url();
		if (!this.hasLeaveHandlers() || this.lastPath === path) {
			this.lastPath = path;
			return;
		}
		if (event) {
			event.preventDefault();
		}
		this.getLeavePromises().then(() => {
			this.lastPath = path;
			this.location.url(path);
		}, _.noop);
	}

	addHandler(name: string, leaveHandler: () => Promise<void>): void {
		this.handlers[name] = {
			name,
			onLeave: leaveHandler,
		};
	}

	removeHandler(name: string): void {
		delete this.handlers[name];
	}

	private hasLeaveHandlers(): boolean {
		return !_.isEmpty(this.handlers);
	}

	getLeavePromises(): Promise<any> {
		return this.hasLeaveHandlers() ? Promise.all(_.map(this.handlers, handler => handler.onLeave())) : Promise.resolve();
	}
}

app.service('globalUnloadService', downgradeInjectable(GlobalUnloadService));

