import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetMetricConfigurationOptions } from '../constants/widget-metric-configuration-options.constant';
import { StackType } from '../constants/stack-types';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import WidgetSettingsService, { IWidgetSettingsConfig } from '../services/widget-settings.service';
import HierarchySettingsService from '../services/hierarchy-settings.service';
import { EventEmitterService, IHandlerCallback } from '@cxstudio/services/event/event-emitter.service';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { IWidgetSettingsScope } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import EventType from '@cxstudio/services/event/event-type.enum';
import { WidgetSettingsUtils } from '@app/modules/widget-settings/settings/widget-settings-utils.class';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { IPropsWithGrouping, SelectedReportGroupings } from '@app/modules/widget-settings/services/selected-report-groupings';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { ObjectUtils } from '@app/util/object-utils';

export class AnalyticDefinitionCommonController {
	selectedReportGroupings = new SelectedReportGroupings(this.$scope.props as IPropsWithGrouping);
	private timeGroupingStatsSubscription: IHandlerCallback;

	constructor(
		private $scope: IWidgetSettingsScope & ng.IScope,
		private locale: ILocale,
		private timePrimaryGroups: TimePrimaryGroupsService,
		private $uibModal: ng.ui.bootstrap.IModalService,
		private $q: ng.IQService,
		private DateRange,
		private tagService: TagsService,
		private hierarchyService,
		private metricUtils,
		private optionsTemplatesService: OptionsTemplatesService,
		private metricConstants: MetricConstants,
		private datePeriodUtils: DatePeriodUtils,
		private widgetSettingsService: WidgetSettingsService,
		private hierarchySettingsService: HierarchySettingsService,
		private adminProjectsService: AdminProjectsService,
		private eventEmitterService: EventEmitterService,
		private reportDataApiService: ReportDataApiService
	) {
		this.$scope.isPoPEnabled = this.isPoPEnabled;
		this.$scope.isTimeGrouping = this.isTimeGrouping;
		this.$scope.isUsingTimeGrouping = this.isUsingTimeGrouping;
		this.$scope.hasOnlyTimeGrouping = this.hasOnlyTimeGrouping;
		this.$scope.isHierarchyApplied = this.isHierarchyApplied;
		this.$scope.isDriversGrouping = this.isDriversGrouping;
		this.$scope.isPeerReport = this.isPeerReport;
		this.$scope.showPeerReportWarning = this.showPeerReportWarning;
		this.$scope.initializePeriods = this.initializePeriods;
		this.$scope.updateCustomDateFilters = this.updateCustomDateFilters;
		this.$scope.setPrimaryTimeGrouping = this.setPrimaryTimeGrouping;
		this.$scope.configure = this.configure;
		this.$scope.setProperMetricValue = this.setProperMetricValue;
		this.$scope.getStaticDataModifiers = this.getStaticDataModifiers;
		this.$scope.processSelections = this.processSelections;
		this.$scope.hasHierarchyMetrics = this.hasHierarchyMetrics;
		this.$scope.processDynamicCalculations = this.processDynamicCalculations;
		this.$scope.checkAndRemoveCalculations = this.checkAndRemoveCalculations;
		this.$scope.populateCurrentHierarchyCalculations = this.populateCurrentHierarchyCalculations;
		this.$scope.removeHierarchyCalculationsAndOptions = this.removeHierarchyCalculationsAndOptions;
		this.$scope.removeSelectedMetric = this.removeSelectedMetric;
		this.$scope.requiresAtLeastOneMetric = this.requiresAtLeastOneMetric;
		this.$scope.reloadCommonSettings = this.reloadCommonSettings;
		this.$scope.sizeMetricsFilter = this.sizeMetricsFilter;
		this.$scope.noTopBox = this.noTopBox;
		this.$scope.noBottomBox = this.noBottomBox;
		this.$scope.noSatScore = this.noSatScore;
		this.$scope.hasGroupingConfig = this.hasGroupingConfig;
		this.$scope.isStudioAdminProject = this.isStudioAdminProject;
		this.$scope.periodOptionsFilter = this.periodOptionsFilter;
		this.$scope.loadTimeGroupingStatistics = this.loadTimeGroupingStatistics;
		this.$scope.filterSelectedAttributesForGrouping = this.filterSelectedAttributesForGrouping;

		this.$scope.$on('$destroy', () => {
			if (this.timeGroupingStatsSubscription)
				this.timeGroupingStatsSubscription.unsubscribe();
		});
	}

	isPoPEnabled = (): boolean => !!this.$scope.props.useHistoricPeriod;

	isTimeGrouping = (item): boolean => AnalyticMetricTypes.isTime(item);

	isUsingTimeGrouping = (): boolean => this.$scope.props && !!this.$scope.props.primaryTimeGrouping;

	hasOnlyTimeGrouping = (): boolean =>
		this.$scope.isUsingTimeGrouping()
			&& this.$scope.props.selectedAttributes && this.$scope.props.selectedAttributes.length === 1

	isHierarchyApplied = (): boolean =>
		this.$scope.dashboardFilters && this.$scope.dashboardFilters.personalization
			&& this.$scope.dashboardFilters.personalization.isHierarchySelectable()

	isDriversGrouping = (): boolean => !!_.find(this.$scope.props.selectedAttributes, AnalyticMetricTypes.isDrivers);

	isPeerReport = (): boolean => {
		let personalization = this.$scope.dashboardFilters && this.$scope.dashboardFilters.personalization;
		return this.hierarchyService.isPeerReport(personalization, this.$scope.props);
	}

	showPeerReportWarning = () => this.$scope.isPeerReport() && !this.hierarchyService.isInheritedReport(this.$scope.props);

	initializePeriods = (): void => {
		this.widgetSettingsService.initializePeriods(this.$scope.props, this.$scope.ui.periods, this.$scope.widget.template);
		if (this.timeGroupingStatsSubscription)
			this.timeGroupingStatsSubscription.unsubscribe();
		this.timeGroupingStatsSubscription =
			this.eventEmitterService.subscribe(EventType.UPDATE_DATE_RANGE, () => this.$scope.loadTimeGroupingStatistics());
	}

	updateCustomDateFilters = (dateFilters): void => {
		if (!this.$scope.ui.periodOptions) {
			this.$scope.ui.periodOptions = {};
		}

		let usedDateFilters = _.chain([this.$scope.props.dateRangeP1, this.$scope.props.dateRangeP2])
			.map(val => val && val.dateFilterMode)
			.filter(val => !!val)
			.value();
		this.$scope.ui.periodOptions.dateFilters = this.datePeriodUtils.processDateFilters(dateFilters, usedDateFilters);
	}

	setPrimaryTimeGrouping = (item): void => {
		this.$scope.props.primaryTimeGrouping = item;

		if (TimePrimaryGroups.isYear(item.timeName)) {
			this.$scope.changePop(false);
			this.$scope.ui.popSelectionEnabled = false;
		} else {
			let historicDateRangeValues = this.timePrimaryGroups.getHistoricOptions(item.timeName);
			if (TimePrimaryGroups.isMonth(item.timeName)) {
				historicDateRangeValues.push(this.DateRange.historicOptions.RUNNING_3_MONTHS.value);
			}

			let resultFilter = (historicOption) => historicDateRangeValues.indexOf(historicOption.value) > -1;
			this.$scope.ui.periodOptions.historic.filter = resultFilter;
		}

		this.loadTimeGroupingStatistics();
	}

	filterSelectedAttributesForGrouping = (items: any[]): any[] => {
		return this.hierarchyService.filterSelectedAttributesForGrouping(items,
			this.$scope.dashboardFilters?.personalization?.isHierarchyEnabled() || this.$scope.personalization?.isHierarchyEnabled(),
			this.$scope.dashboardFilters?.personalization?.getHierarchyId() || this.$scope.personalization?.getHierarchyId());
	}

	configure = (item, listType, configurationOptions?,
		templateUrl = 'partials/widgets/settings/cb/definitions/metric-configuration-modal.html'): ng.IPromise<void> => {
		// prevent opening attribute setting until lists are loaded
		// otherwise changes could not be applied to attribute
		if (item === undefined || this.$scope.listsInitialized === false) return this.$q.reject();
		if (configurationOptions === undefined) configurationOptions = [];
		let config = this.$uibModal.open({
			templateUrl,
			controller: 'widgetMetricConfigController',
			backdrop: 'static',
			resolve: {
				modalSettings: () => {
					let metric;
					let studioMetrics;
					let metrics: any = _.find(this.$scope.options.additionalMetrics, {name: 'metrics'});

					if (item.definition) {
						if (metrics) {
							metric = SearchableHierarchyUtils.deepSearchByNameAndType(
								metrics.children, item.name, item.type);
						}
					}
					studioMetrics = metrics && metrics.children;
					let selectedMetrics = this.$scope.props.selectedMetrics;

					return {
						configItem: item,
						listType,
						sortOptions: ObjectUtils.copy(this.$scope.options.cogSortByMetrics),
						props: this.$scope.props,
						visualProps: this.$scope.visualProps,
						configurationOptions,
						metric,
						studioMetrics,
						selectedMetrics,
						isWidgetAssetConfig: true
					};
				}
			}
		});

		return config.result.then((result) => {
			if (result.wordsList) {
				let wordsSelection = this.tagService.tagObjectsToStringArray(result.wordsList);
				result.wordsList = wordsSelection;
			}

			item = $.extend(item, result);

			// some properties may be deleted after upgrade
			['dataType', 'decimalDelimiter', 'thousandsDelimiter'].map((deletedProp) => {
				if (!result[deletedProp])
					delete item[deletedProp];
			});

			if (configurationOptions && (configurationOptions.constructor === Array)
					&& (configurationOptions.contains(WidgetMetricConfigurationOptions.STACKED_GROUPING)
						|| configurationOptions.contains(WidgetMetricConfigurationOptions.SERIES_GROUPING))) {
				this.$scope.visualProps.lockAxisLimits = item.stackType === StackType.HUNDRED_PERCENT;
			}

			this.selectedReportGroupings.setTopicSubtopic(result);

			if (this.$scope.onWidgetMetricConfigured) {
				this.$scope.onWidgetMetricConfigured(item, listType, configurationOptions);
			}

		});
	}

	setProperMetricValue = (row, metric, valueModifier): void => {
		let field;
		switch (metric) {
		case 'volume': field = 'metric1'; break;
		case 'sentiment': field = 'metric2'; break;
		default: field = 'metric3'; break;
		}
		let modifier = valueModifier && valueModifier[field] || angular.identity;

		row[metric] = modifier(row[field]);
	}

	getStaticDataModifiers = (data, visualProps) => {
		// see tests for documention
		let result = [];
		if (visualProps.yAxis) {
			result[0] = this.getModifiers(data, visualProps, 'yAxis');
		}
		if (visualProps.xAxis) {
			result[1] = this.getModifiers(data, visualProps, 'xAxis');
		}
		if (visualProps.secondaryYAxis) {
			// both X and secondaryY are on index=1
			result[1] = this.getModifiers(data, visualProps, 'secondaryYAxis');
		}
		return result;
	}

	private getModifiers = (data, visualProps, axis): { metric1?: number, metric2?: number, metric3?: number } => {
		let result = {};
		_.each(['metric1', 'metric2', 'metric3'], (field) => {
			let propsRange = this.getAxisRange(visualProps, axis);
			let range = this.getDataRange(data, field);
			let scale, shift;

			if (propsRange.min !== undefined && propsRange.max !== undefined) {
				scale = (propsRange.max - propsRange.min) / (range.max - range.min);
				shift = propsRange.min - range.min;
			} else if (propsRange.min !== undefined) {
				scale = 1.0;
				shift = propsRange.min - range.min;
			} else if (propsRange.max !== undefined) {
				scale = 1.0;
				shift = propsRange.max - range.max;
			} else {
				scale = undefined;
				shift = undefined;
			}
			if (scale !== undefined && shift !== undefined)
				result[field] = this.normalizeFunction(scale, shift, range.min);
		});

		return result;
	}

	private getAxisRange = (visualProps, axis): {min: number, max: number} => {
		return {
			min: visualProps[axis + 'AutoMin'] === false ? Number(visualProps[axis + 'Min']) : undefined,
			max: visualProps[axis + 'AutoMax'] === false ? Number(visualProps[axis + 'Max']) : undefined
		};
	}

	private getDataRange = (data, field): {min: number, max: number} => {
		let minRow = _.min(data, field);
		let maxRow = _.max(data, field);
		return {
			min: minRow && minRow[field],
			max: maxRow && maxRow[field]
		};
	}

	private normalizeFunction = (scale, shift, minValue): (val) => number => {
		return (value) => {
			if (value === undefined || value === null || isNaN(value)
				|| !isFinite(scale) || !isFinite(shift))
				return value;
			return (value - minValue) * scale + minValue + shift;
		};
	}

	processSelections = (): void => {
		if (!this.$scope.isUsingTimeGrouping()) {
			this.$scope.ui.popSelectionEnabled = true;
		}
	}

	hasHierarchyMetrics = () =>
		this.$scope.props && this.$scope.props.selectedMetrics &&
			this.$scope.props.selectedMetrics.filter(this.metricUtils.isHierarchyMetric).length > 0

	processDynamicCalculations = (grouping) => {
		if (AnalyticMetricTypes.isHierarchyModel(grouping)) {
			this.processDynamicHierarchyCalculations(grouping);
		} else {
			this.$scope.checkAndRemoveCalculations();
		}
	}

	checkAndRemoveCalculations = () => {
		if (!this.hasHierarchyGroupings())
			this.$scope.removeHierarchyCalculationsAndOptions();
	}

	private hasHierarchyGroupings = (): boolean =>
		this.$scope.props && this.$scope.props.selectedAttributes &&
			this.$scope.props.selectedAttributes.filter(AnalyticMetricTypes.isHierarchyModel).length > 0

	private processDynamicHierarchyCalculations(hierarchyGrouping): void {
		this.hierarchySettingsService.getOrganizationCalculations(hierarchyGrouping).then((calculations) => {
			this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, hierarchyGrouping, calculations);
			this.$scope.options.calculationSeries = ObjectUtils.copy(this.$scope.options.additionalMetrics);
		});
	}

	private findGroupingInHierarchyModels = (hierarchyId, hierarchyModels): any[] =>
		_.find(hierarchyModels, (model: any) => model.id === hierarchyId)

	populateCurrentHierarchyCalculations = (metrics, hierarchyGrouping, calculations, availableHierarchyModels) => {
		if (!hierarchyGrouping) return;

		this.removeNotMatchingHierarchyCalculations(hierarchyGrouping);
		if (!this.hasAnyHierarchyCalculations(calculations)) {
			this.removeHierarchyCalculationOptions();
		} else {
			let hierarchyId = parseInt(hierarchyGrouping.name, 10);

			if (!availableHierarchyModels || this.findGroupingInHierarchyModels(hierarchyId, availableHierarchyModels)) {
				let allOptions = this.metricUtils.buildOrgHierarchyMetricOptions(hierarchyId, calculations);

				let hierarchyMetricsGroup = this.optionsTemplatesService.buildHierarchyMetricsGroup(allOptions);

				HierarchyUtils.replaceOrAddGroup(metrics, OptionsConstant.HIERARCHY_CALCULATIONS, hierarchyMetricsGroup);
			}
		}
	}

	private removeHierarchyCalculationOptions = (): void => {
		if (this.$scope.options && this.$scope.options.additionalMetrics) {
			HierarchyUtils.removeGroup(this.$scope.options.additionalMetrics, OptionsConstant.HIERARCHY_CALCULATIONS);
			HierarchyUtils.removeGroup(this.$scope.options.calculationSeries, OptionsConstant.HIERARCHY_CALCULATIONS);
		}
	}

	private hasAnyHierarchyCalculations = (calculations): boolean => !isEmpty(calculations.enrichment) || !isEmpty(calculations.custom);

	private removeNotMatchingHierarchyCalculations = (hierarchyGrouping): void => {
		let hierarchyId = parseInt(hierarchyGrouping.name, 10);
		this.$scope.props.selectedMetrics
			.filter((metric) => {
				let differentHierarchyEnrichmentProperty = AnalyticMetricTypes.isHierarchyEnrichmentProperty(metric)
					&& (metric as any).hierarchyId !== hierarchyId;
				let differentHierarchyCustomCalculation = this.metricUtils.isHierarchyCustomMetric(metric)
					&& !this.metricUtils.isSpecificHierarchyCustomMetric(metric, hierarchyId);

				return differentHierarchyEnrichmentProperty || differentHierarchyCustomCalculation;
			})
			.forEach(this.removeMetricAndProcess);
	}

	removeHierarchyCalculationsAndOptions = () => {
		this.removeHierarchyCalculationOptions();

		if (this.$scope.props.selectedMetrics) {
			this.$scope.props.selectedMetrics
				.filter(this.metricUtils.isHierarchyMetric)
				.forEach(this.removeMetricAndProcess);
		}
	}

	private removeMetricAndProcess = (metric): void => {
		if (this.$scope.removeMetric)
			this.$scope.removeMetric(metric);

		this.$scope.removeSelectedMetric(metric);
		this.$scope.processSelections();
	}

	removeSelectedMetric = (metric) => {
		let metrics = this.$scope.props.selectedMetrics;
		if (!metrics) return;

		for (let i = 0; i < metrics.length; i++) {
			let selectedMetric = metrics[i];
			if (selectedMetric.name === metric.name) {
				metrics.splice(i, 1);
			}
		}

		if (this.$scope.props.selectedMetrics.length === 0 && this.$scope.requiresAtLeastOneMetric()) {
			this.$scope.props.selectedMetrics.push(this.metricConstants.get().VOLUME);
		}
	}

	requiresAtLeastOneMetric = () =>  true;

	reloadCommonSettings = (config: IWidgetSettingsConfig) => {
		let props = this.$scope.props;
		let projectIdentifier = new ProjectIdentifier(props.contentProviderId, props.accountId, props.project);
		let resultPromise = InternalProjectTypes.isStudioAdminProject(projectIdentifier.projectId)
			? this.adminProjectsService.getWidgetSettings(projectIdentifier)
			: this.widgetSettingsService.getWidgetSettings(this.$scope.props, config);
		this.$scope.addLoadingPromise(resultPromise);
		return resultPromise;
	}

	// filter to remove Sentence Quartile and Sentence Word Count from sizing options
	sizeMetricsFilter = (metric) => (metric.name !== 'cb_sentence_word_count') && (metric.name !== 'cb_sentence_quartile');

	noTopBox = (metric) => !metric.definition || (metric.definition.type !== MetricType.TOP_BOX);

	noBottomBox = (metric) => !metric.definition || (metric.definition.type !== MetricType.BOTTOM_BOX);

	noSatScore = (metric) => !metric.definition || (metric.definition.type !== MetricType.SATISFACTION);

	hasGroupingConfig = (grouping): boolean => WidgetSettingsUtils.hasGroupingConfig(this.$scope.props, grouping);

	isStudioAdminProject = () => InternalProjectTypes.isStudioAdminProject(this.$scope.props.project);

	isAdminProject = () => InternalProjectTypes.isAdminProject(this.$scope.props.project);

	periodOptionsFilter = (filter): boolean => {
		if (!this.isStudioAdminProject()) {
			return true;
		}
		return !this.DateRange.isCustomDateRange(filter.value)
			&& _.some(this.DateRange.adminProjectOptions, (option: any) => option.value === filter.value);
	}

	loadTimeGroupingStatistics = (): void => {
		delete this.$scope.groupingDataPointsError;

		if (this.isAdminProject() || !AnalyticMetricTypes.isTime(this.$scope.props.primaryTimeGrouping)) {
			return;
		}

		let properties = this.getTimeGroupingStatisticsProperties();

		this.reportDataApiService.getTimeGroupingStatistics(properties).then(statistics => {
			if (statistics && statistics.filtersLimit && statistics.filtersLimit === statistics.amountOfFilters) {
				this.$scope.groupingDataPointsError =
					this.locale.getString('widget.dataPointsLimitExceeded', { limit: statistics.filtersLimit });
			}
		});
	}

	private getTimeGroupingStatisticsProperties(): Widget {
		let widgetSettings = ObjectUtils.copy(this.$scope.widget);
		widgetSettings.properties = ObjectUtils.copy(this.$scope.props) as WidgetProperties;

		if (widgetSettings.properties.adhocFilter) {
			widgetSettings.properties.adhocFilter.filterRules = [];
		}

		if (widgetSettings.properties.appliedFilters) {
			widgetSettings.properties.appliedFilters.filters = [];
		}

		delete widgetSettings.visualProperties;

		return widgetSettings;
	}
}

app.controller('AnalyticDefinitionCommonCtrl', AnalyticDefinitionCommonController as any);
