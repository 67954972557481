import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { Component, OnInit, Inject, Input, ChangeDetectionStrategy } from '@angular/core';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { SpineLane, SpineLaneType, UISpineLane, ParticipantEnrichmentTypes } from '@cxstudio/conversation/entities/spine-lane.class';
import { UISpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { CxLocaleService } from '@app/core';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ChannelTypes } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversationSettingsEntry } from '../conversation-settings.entity';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { SpineLaneCategory, SpineLaneCategoryUtils } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/spine-lane-category-utils';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { ConversationSpineDefaults } from '@app/modules/conversation/conversation-spine-defaults';

export interface ConversationSettingsEditorDialogInput {
	settingsEntry: ConversationSettingsEntry;
	predefinedMetrics: Metric[];
	modelsAndAttributes: IProjectSettings;
}

interface ILaneOption<T> {
	name: string;
	value: T;
}

@Component({
	selector: 'conversation-settings',
	templateUrl: './conversation-settings-modal.component.html',
	styles: [`
		.enrichment-option {
			align-items: center;
			display: flex;
		}

		.metric-option { width: 240px; }

		fieldset simple-dropdown { display: inline-flex; }

		.custom-labels { white-space: nowrap; }
		.channel-label-input { width: 250px; }

		.row-with-buttons {
			width: calc(100% - 96px);
			margin-left: 8px;
		}

		.channel-names label {
			min-width: 80px;
		}
		.btn-icon[disabled] {
			pointer-events: auto;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ConversationSettingsModalComponent implements OnInit {

	readonly LANE_LIMIT = 4; // 3 custom + scorecard

	participantOptions: ILaneOption<ParticipantEnrichmentTypes>[];
	singleLaneOptions: INode[];

	loading: ng.IPromise<void>;

	readonly CUSTOM_LABEL_LIMIT: number = 20;

	settings: UISpineSettings;
	@Input() input: ConversationSettingsEditorDialogInput;

	predefinedMetrics: Metric[];

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		private applicationThemeService: ApplicationThemeService,
		private readonly conversationSpineDefaults: ConversationSpineDefaults,
		private optionsTemplatesService: OptionsTemplatesService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private locale: CxLocaleService,
		private modal: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.predefinedMetrics = this.input.predefinedMetrics;
		this.initOptions();

		if (!(this.input?.settingsEntry?.settings && Object.keys(this.input.settingsEntry.settings).length)) {
			this.settings = cloneDeep(this.conversationSpineDefaults.get()) as UISpineSettings;
		} else {
			this.settings = cloneDeep(this.input.settingsEntry.settings) as UISpineSettings;
			if (!this.settings.labels) {
				this.settings.labels = {} as ConversationChannelLabels;
			}
			this.initLabels();
			this.initLaneProperties(this.settings.participantLane as UISpineLane);
		}

		_.each(this.settings.singleLanes, lane => this.initLaneProperties(lane));
	}

	initLaneProperties(lane: UISpineLane): void {
		if (this.getLaneType(lane) === SpineLaneCategory.metric) {
			if (!lane.definition.bandColor) {
				let metricName = lane.definition.name === SingleLaneEnrichmentTypes.EFFORT
					? PredefinedMetricConstants.EASE_SCORE : lane.definition.name;
				lane.definition.bandColor = this.conversationSpineDefaults.get3BandColor(metricName);
			} else {
				let metricName = lane.definition.bandColor.replace(ConversationSpineDefaults.GROUP_COLOR_PREFIX, '');
				lane.grouping = SearchableHierarchyUtils.findMetricNameInHierarchy(this.singleLaneOptions, metricName);
			}
		} else {
			lane.grouping = SearchableHierarchyUtils.findMetricNameInHierarchy(this.singleLaneOptions, lane.definition.name);

			// preserve solid color definition for attributes and models
			if (![SpineLaneCategory.attribute, SpineLaneCategory.model].contains(this.getLaneType(lane)))
				delete lane.definition.customColor;
		}
	}

	getLaneMetric(spineLane: SpineLane): Metric {
		let metricName = spineLane.definition.name === SingleLaneEnrichmentTypes.EFFORT
			? PredefinedMetricConstants.EASE_SCORE : spineLane.definition.name;
		return _.findWhere(this.predefinedMetrics, { name: metricName });
	}

	selectGrouping(lane: UISpineLane, node: any): void {
		lane.grouping = node as AttributeGrouping;
		lane.definition.laneType = lane.grouping?.model ? SpineLaneType.TOPIC : SpineLaneType.DEFAULT;
		if (lane.grouping.model || (lane.grouping.metricType === AnalyticMetricType.ATTRIBUTE)) {
			lane.definition.name = lane.grouping.name as any;
			lane.definition.customColor = this.applicationThemeService.getAppBrandingColors().COLOR_4;
			delete lane.definition.inclusionList;
			return;
		} else {
			lane.definition.name = node.rawName === PredefinedMetricConstants.EASE_SCORE
				? SingleLaneEnrichmentTypes.EFFORT : node.rawName;
		}

		if (node.rawName === SingleLaneEnrichmentTypes.SENTENCE_TYPE) {
			lane.definition.icon = SecondaryTrackRendererType.DIAMOND;
			delete lane.definition.bandColor;
		} else {
			lane.definition.bandColor = ConversationSpineDefaults.GROUP_COLOR_PREFIX + node.name;
		}

		if (lane.definition.inclusionList)
			delete lane.definition.inclusionList;
	}

	resetLaneProperties(lane: SpineLane): void {
		delete lane.definition.bandColor;
		this.initLaneProperties(lane as UISpineLane);
	}

	private initOptions(): void {
		this.singleLaneOptions = this.getLaneOptions();
		this.singleLaneOptions.insert(0, {
			name: SingleLaneEnrichmentTypes.SENTENCE_TYPE,
			rawName: SingleLaneEnrichmentTypes.SENTENCE_TYPE,
			displayName: this.locale.getString('appearance.cbRecommended')
		} as any);

		this.participantOptions = [
			{ name: this.locale.getString('docExplorer.nonNeutralSentiment'), value: ParticipantEnrichmentTypes.SENTIMENT },
			{ name: this.locale.getString('docExplorer.nonNeutralEffort'), value: ParticipantEnrichmentTypes.EFFORT },
			{ name: this.locale.getString('docExplorer.emotionalIntensity'), value: ParticipantEnrichmentTypes.EMOTION }
		];
	}

	private getLaneOptions(): INode[] {
		return this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
			.withTemplate(this.optionsTemplatesService.getConversationSpineTemplate())
			.withAttributes(this.input.modelsAndAttributes?.attributes, MetricFilters.NOT_DATE)
			.withWordAttributes(this.input.modelsAndAttributes?.wordAttributes)
			.withModels(this.input.modelsAndAttributes?.models)
			.withPredefinedGroups(this.predefinedMetrics)
			.build();
	}

	getLaneType(lane: UISpineLane): SpineLaneCategory {
		return SpineLaneCategoryUtils.getLaneCategory(lane.definition);
	}

	isScorecardingEnabled(): boolean {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	}

	toggleAutoTitle(autoTitleEnabled: boolean, channelName: string): void {
		if (autoTitleEnabled) {
			//if auto-title enabled, delete custom title
			delete this.settings.labels[channelName];
		} else {
			//if auto-title turned off, set to predefined value
			this.settings.labels[channelName] = this.locale.getString(`docExplorer.${channelName}Label`);
		}
	}

	private initLabels(): void {
		[ChannelTypes.AGENT, ChannelTypes.CLIENT, ChannelTypes.BOT, ChannelTypes.UNKNOWN].forEach((channelType: ChannelTypes) => {
			let autoTitleField = channelType + 'AutoTitle';
			this.settings.labels[autoTitleField] = _.isUndefined(this.settings.labels[channelType]);
		});
	}

	private cleanSettings(): void {
		[ChannelTypes.AGENT, ChannelTypes.CLIENT, ChannelTypes.BOT].forEach(type => {
			if (this.settings.labels[type])
				this.settings.labels[type] = this.settings.labels[type].trim();
		});
	}

	hasCustomLanes(): boolean {
		return _.some(this.settings.singleLanes, lane => !this.isScorecardLane(lane));
	}

	isScorecardLane(lane: UISpineLane): boolean {
		return lane.definition.name === SingleLaneEnrichmentTypes.SCORECARD;
	}

	isLaneLimitReached(): boolean {
		return this.settings.singleLanes.length >= this.LANE_LIMIT;
	}

	removeLane(lane: UISpineLane): void {
		this.settings.singleLanes.remove(lane);
	}

	addLane(index: number): void {
		if (this.isLaneLimitReached()) {
			return;
		}

		let lane = this.conversationSpineDefaults.getDefaultLane() as UISpineLane;
		this.initLaneProperties(lane);
		this.settings.singleLanes.insert(index, lane);
	}

	onDrop(event: CdkDragDrop<UISpineLane[]>) {
		event.container.data.move(event.previousIndex, event.currentIndex);
	}

	save(): void {
		this.cleanSettings();
		this.input.settingsEntry.settings = this.settings;
		this.modal.close(this.input.settingsEntry);
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
