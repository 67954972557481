<div class="d-flex flex-direction-column h-100-percent overflow-hidden p-relative bg-secondary p-24">
	<quick-insights-search
		class="mb-16"
		[homePage]="homePage"
		[focusOnInit]="true"
		[(searchValue)]="searchValue"
		(search)="changeSearch($event)">
	</quick-insights-search>
	<div class="font-bold">{{'homePage.searchTitle' | i18n}}: <span class="ml-8">{{currentSearch?.displayName}}</span>
		<quick-insights-clear
			(click)="changeSearch(null)"
			(keydown.enter)="changeSearch(null)"
		></quick-insights-clear>
	</div>
	<div class="h-50-percent d-flex justify-content-start"
		id="container-quickInsights"
		[ngBusy]="loadingPromise">
		<ng-container *ngIf="loaded && hasData === true">
			<div *ngFor="let widget of widgets; index as widgetIndex"
				class="w-33-percent p-relative mh-8"
				[attr.id]="'widget-' + widget.id">
				<widget-container
					[widget]="widget"
					[widgetMode]="widgetMode"
					[editMode]=false
					[dashboardData]="dashboardData"
					[layout]="{}"
					class="br-widget w-100-percent h-100-percent">
				</widget-container>
			</div>
		</ng-container>
		<ng-container *ngIf="loaded && hasData === false">
			<div class="text-1_25rem mt-24">{{'homePage.noData' | i18n}}</div>
		</ng-container>
	</div>
	<div>
		<span class="font-bold" [i18n]="'homePage.itemSearchTitle'" [i18nParams]="{count: filteredDashboards.length}"></span>:
		<span class="ml-8 font-bold">{{currentSearch?.displayName}}</span>
		<quick-insights-clear
			(click)="changeSearch(null)"
			(keydown.enter)="changeSearch(null)"
		></quick-insights-clear>
	</div>
	<div class="h-50-percent d-flex mb-16">
		<item-grid
			class="h-100-percent w-100-percent br-grid"
			[treeData]="filteredDashboards"
			[gridOptions]="gridOptions"
			[gridMode]="gridMode"
			[gridType]="gridType"
			[gridChange]="filteredDashboards"
			[scrollable]="true"
			[controller]="this">
		</item-grid>
	</div>
</div>
