import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ChangeDetectorRef, Input } from '@angular/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { AgGridDashboardFolderActionsService } from '@app/modules/dashboard-list/context-menu/actions/ag-grid-dashboard-folder-actions.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { ContextMenuTextItem, IContextMenuItem } from '@app/shared/components/kebab-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';

@Component({
	selector: 'dashboards-table-toolbar',
	templateUrl: './dashboards-table-toolbar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardsTableToolbarComponent extends SelfCleaningComponent implements OnInit {
	@Input() filterManager: TableFilterManager;
	@Output() search = new EventEmitter<string>();

	searchValue: string;
	showLoading: boolean = false;

	tableMenuItems: IContextMenuItem[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly cxLocation: CxLocationService,
		private locale: CxLocaleService,
		private readonly agGridDashboardFolderActionsService: AgGridDashboardFolderActionsService,
		private readonly dashboardListService: DashboardListService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardTemplateService') private readonly dashboardTemplateService: DashboardTemplateService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('redirectService') private readonly redirectService: RedirectService,
	) {
		super();
	}

	ngOnInit(): void {
		this.initTableMenu();
		this.dashboardListService.getLoadingChange().subscribe(loading => {
			this.showLoading = loading;
			this.ref.markForCheck();
		});
		this.addSubscription(this.filterManager.getFilterChangeObservable().subscribe(() => {
			this.initTableMenu();
			this.ref.detectChanges();
		}));
	}

	onSearchChange(): void {
		this.filterManager.setFilterText(this.searchValue);
		this.search.emit(this.searchValue);
	}

	private initTableMenu(): void {
		let toggleVisibility = this.getToggleHiddenOption();
		this.tableMenuItems = [ toggleVisibility ];
	}

	private getToggleHiddenOption(): ContextMenuTextItem {
		const isShowingHiddenItems = this.filterManager.isShowHidden();
		let toggleHiddenLabel = isShowingHiddenItems
			? this.locale.getString('dashboard.hideAllHidden')
			: this.locale.getString('dashboard.showAllHidden');

		const testId = isShowingHiddenItems
			? 'hide-hidden-items'
			: 'show-hidden-items';
		return new ContextMenuTextItem('toggleHidden', toggleHiddenLabel,
			() => this.filterManager.toggleHidden(), testId);
	}

	canCreateDashboard(): boolean {
		return this.security.has('create_dashboard');
	}

	createDashboard(): void {
		// TODO move this into service once context menu is migrated
		this.dashboardTemplateService.templatesEnabled().then((enabled) => {
			if (enabled) {
				this.cxLocation.path('/templates');
				return;
			}

			this.dashboardService.createDashboard(undefined, undefined, undefined).then((dashboard) => {
				this.navigationService.changeDashboard(dashboard, true);
			});
		});
	}

	createBook(): void {
		this.redirectService.goToNewBook();
	}

	createFolder(): void { // folder is null when clicking button "Add folder"
		this.agGridDashboardFolderActionsService.createFolder();
	}
}
