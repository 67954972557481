import { BackgroundType, BorderOptions, BorderValue, ContentWidgetProperties, TextVAlign } from '@cxstudio/reports/entities/content-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import ContentWidgetBuilder from './content-widget-builder';

export class TextWidgetBuilder extends ContentWidgetBuilder {

	properties: ContentWidgetProperties;
	visualProperties: VisualProperties;

	static get(): TextWidgetBuilder {
		return new TextWidgetBuilder();
	}

	constructor() {
		super();
		this.properties = {} as ContentWidgetProperties;
		this.visualProperties = new VisualProperties();
		this.name = WidgetType.TEXT;
		this.visualProperties.visualization = WidgetVisualization.TEXT;
		this.properties.widgetType = WidgetType.TEXT;
		this.properties.newTextFormat = true;
	}

	withWidth(width: number): TextWidgetBuilder {
		this.width = width;
		return this;
	}

	withHeight(height: number): TextWidgetBuilder {
		this.height = height;
		return this;
	}

	withPosition(posX, posY): TextWidgetBuilder {
		this.posX = posX;
		this.posY = posY;
		return this;
	}

	withText(text): TextWidgetBuilder {
		this.properties.text = text;
		return this;
	}

	withBorder(option: BorderOptions, value: BorderValue): TextWidgetBuilder {
		this.properties[option] = value;
		return this;
	}

	withBackground(backgroundType: BackgroundType): TextWidgetBuilder {
		this.properties.background = backgroundType;
		return this;
	}

	withVerticalAlign(align: TextVAlign): TextWidgetBuilder {
		this.properties.valign = align;
		return this;
	}
}
