
import { SlickHeaderUtils } from '@app/modules/item-grid/slick-header-utils.class';

const PIN_HEADER_CLASS = 'pin-slick-header';

/*
 * Has NG twin
 */
app.directive('stickySlickHeader', () => {
	return {
		restrict: 'A',
		link: (scope: ng.IScope, element: ng.IAugmentedJQuery) => {
			scope.$watch(() => SlickHeaderUtils.pinSlickHeader(), (shouldPin: boolean) => {
				if (shouldPin) {
					element.addClass(PIN_HEADER_CLASS);
				} else {
					element.removeClass(PIN_HEADER_CLASS);
				}
			});
		}
	};
});
