import { OnInit } from '@angular/core';
import { HostListener, Directive, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

@Directive({
	selector: '[cx-keyboard-scroll]'
})
export class CxKeyboardScrollDirective implements OnInit {

	@Output() selectionChanged = new EventEmitter<any>();
	@Input() focusedIndex: number;
	@Input() maintainFocus: boolean = false;
		// true = don't allow the directive to change focused element
		// useful if you want to navigate a searchable list, but keep the cursor in the search bar

	constructor(
		private listElement: ElementRef
	) { }

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const currentFocusableItems = this.getFocusableListItems();		

		if (_.contains([Key.DOWN, Key.UP, Key.PAGEUP, Key.PAGEDOWN, Key.END, Key.HOME], event.key)
				&& currentFocusableItems.length) {
			event.preventDefault();
			this.adjustCurrentIndexForChanges();

			if (KeyboardUtils.isEventKey(event, Key.DOWN) && (this.focusedIndex < currentFocusableItems.length - 1)) {
				this.focusedIndex++;
				this.adjustListPosition(this.focusedIndex);
			}

			if (KeyboardUtils.isEventKey(event, Key.UP) && (this.focusedIndex > 0)) {
				this.focusedIndex--;
				this.adjustListPosition(this.focusedIndex);
			}

			if (KeyboardUtils.isEventKey(event, Key.PAGEUP)) {
				this.scrollPage(-1);
			}

			if (KeyboardUtils.isEventKey(event, Key.PAGEDOWN)) {
				this.scrollPage(1);
			}

			if (KeyboardUtils.isEventKey(event, Key.HOME)) {
				this.adjustListPosition(0);
			}

			if (KeyboardUtils.isEventKey(event, Key.END)) {
				this.adjustListPosition(currentFocusableItems.length - 1);
			}
		}
	}

	ngOnInit(): void {
		this.focusedIndex = 0;
	}

	/**
	 * Update focused index in case submenus have been opened or closed
	 */
	adjustCurrentIndexForChanges(): void {
		this.focusedIndex = this.getFocusableListItems().index(document.activeElement);
	}

	private scrollPage(direction: number): void {
		let list = this.getScrollList().get(0) as HTMLElement;
		let visibleTop = list.scrollTop;

		if (direction === -1) {
			if ((visibleTop - list.offsetHeight) <= 0) {
				this.focusedIndex = 0;
			} else {
				this.focusedIndex = this.findTopVisibleItem(visibleTop - list.offsetHeight);
			}
		} else if (direction === 1) {
			if ((visibleTop + list.offsetHeight) >= list.scrollHeight) {
				this.focusedIndex = this.getFocusableListItems().length - 1;
			} else {
				this.focusedIndex = this.findTopVisibleItem(visibleTop + list.offsetHeight);
			}
		}

		this.adjustListPosition(this.focusedIndex);
	}

	private getScrollList(): JQuery {
		// might be the same element
		return $(this.listElement.nativeElement).attr('scroll-list') !== undefined ?
			$(this.listElement.nativeElement) :
			$(this.listElement.nativeElement).find('[scroll-list]');
	}

	private getFocusableListItems(): JQuery {
		return this.getScrollList().find(':focusable');
	}

	private findTopVisibleItem(whenScrolledTo: number): number {
		let options: JQuery = this.getScrollList();
		let i;
		for (i = 0; i < (options.length - 1); i++) {
			if ((options[i] as HTMLElement).offsetTop < whenScrolledTo && (options[i + 1] as HTMLElement).offsetTop >= whenScrolledTo)
				break;
		}

		// wherever we stopped is the last hidden item, so we need to add 1 more
		return (options.length > i + 1 ) ? (i + 1) : (options.length - 1);
	}



	private adjustListPosition(selectedIndex: number): void {
		this.selectionChanged.emit(this.focusedIndex);
		let list = this.listElement.nativeElement.querySelector('[scroll-list]');
		let selectedListItem: HTMLElement = this.getFocusableListItems()[selectedIndex] as HTMLElement;

		if (!this.maintainFocus) {
			selectedListItem.focus();
		}
		let visibleTop = list.scrollTop;
		let visibleBottom = visibleTop + list.offsetHeight;

		// we always want to make sure the FULL list item is in view
		let bottomOfSelectedItem = selectedListItem.offsetTop + selectedListItem.offsetHeight;
		if (bottomOfSelectedItem > visibleBottom) {
			// item selected by keyboard is below current view: scroll down
			list.scrollTop = selectedListItem.offsetTop;
		} else if (selectedListItem.offsetTop < visibleTop) {
			// item selected by keyboard is above current view: scroll up
			list.scrollTop = (bottomOfSelectedItem - list.offsetHeight) >= 0 ?
				bottomOfSelectedItem - list.offsetHeight :
				0;
		}
	}
}
