import * as _ from 'underscore';

import {Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';

import { Security } from '@cxstudio/auth/security-service';
import { UserEditFormData } from '@app/modules/user-administration/editor/user-edit-form-data';

@Component({
	selector: 'admin-org-permissions',
	templateUrl: './admin-org-permissions.component.html'
})
export class AdminOrgPermissionsComponent implements OnInit {
	
	@Input() user: UserEditFormData;

	@Output() platformApiCallback = new EventEmitter<void>();
	@Output() onChange = new EventEmitter<UserEditFormData>();

	constructor(
		@Inject('security') private security: Security
	) {}

	ngOnInit(): void {}

	isSysAdmin = () => this.security.isSysAdmin();

	changeHandler = (): void => {
		this.onChange.emit(this.user);
	}

	platformApiChangeHandler = (): void => {
		this.changeHandler();
		this.platformApiCallback.emit();
	}
}

app.directive('adminOrgPermissions', downgradeComponent({component: AdminOrgPermissionsComponent}));