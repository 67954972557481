import { User } from '@cxstudio/user-administration/users/entities/user';


export default class BaseMasterAccount {
	accountId: number;
	accountName: string;

	tags: string[];
	brandingColors: {[key: string]: string};
	owners: User[];
}
