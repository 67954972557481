import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'document-navigation',
	template: `
<div class="d-flex">
	<button class="btn btn-secondary previous-btn d-flex align-items-center"
		[disabled]="!hasPrevious"
		(click)="previous.emit()"
		[attr.aria-label]="getPreviousLabel()">
		<i class="mr-4 q-icon-triangle-left text-0_875rem"
			aria-hidden="true"></i>
		{{getPreviousLabel()}}
	</button>

	<button class="btn btn-secondary next-btn d-flex align-items-center"
		[disabled]="!hasNext"
		(click)="next.emit()"
		[attr.aria-label]="getNextLabel()">
		{{getNextLabel()}}
		<i class="ml-4 q-icon-triangle-right text-0_875rem"
			aria-hidden="true"></i>
	</button>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentNavigationComponent {

	@Input() hasNext: boolean;
	@Input() hasPrevious: boolean;
	@Input() verbatimMode: boolean;

	@Output() next = new EventEmitter<void>();
	@Output() previous = new EventEmitter<void>();

	constructor(private readonly locale: CxLocaleService) {}

	getPreviousLabel(): string {
		return this.locale.getString(this.verbatimMode ? 'preview.previousVerbatim' : 'preview.previousDocument');
	}

	getNextLabel(): string {
		return this.locale.getString(this.verbatimMode ? 'preview.nextVerbatim' : 'preview.nextDocument');
	}
}

app.directive('documentNavigation', downgradeComponent({component: DocumentNavigationComponent}) as angular.IDirectiveFactory);
