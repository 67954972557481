export class SlickgridOptions {
	onContextMenu?: (event: Event | JQuery.MouseEventBase, row) => void;
	onClick?: (event: Event | JQuery.MouseDownEvent, row) => void;
	onDblClick?: (event: Event | JQuery.MouseEventBase, row) => void;
	onMouseEnter?: (event: Event | JQuery.MouseEnterEvent, row) => void;
	onMouseLeave?: (event: Event | JQuery.MouseLeaveEvent, row, local?) => void;
	onKeyDown?: (event: Event | JQuery.KeyDownEvent, row) => void;
	onSort?: (event: Event, row) => void;
	autoHeight?: boolean;
	disableInitialSort?: boolean;
}
