import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { SessionConsumers } from './session-consumers.enum';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	readonly TIMEOUT = 5000;
	state = [];
	currentDashboardId = null;

	// make sure we don't have multiple timers
	inProgress: boolean = false;

	constructor(
		@Inject('$log') private readonly $log: ng.ILogService,
		private userSessionApiService: UserSessionApiService
	) {}

	private continueSession = (): void => {
		if (this.inProgress)
			return;
		this.inProgress = true;
		setTimeout(this.sessionRepeater, this.TIMEOUT);
	}

	private sessionRepeater = (): void => {
		if (!this.state.length) {
			this.inProgress = false;
			return;
		}
		this.userSessionApiService.updateAccessTime().then(() => {
			setTimeout(this.sessionRepeater, this.TIMEOUT);
		}, () => {
			this.inProgress = false;
			this.$log.debug('Error on server call to continue session');
		});
	}

	private addConsumer = (consumer): void => {
		if (this.state.indexOf(consumer) === -1) {
			this.state.push(consumer);
		}
		this.continueSession(); // starting timer, if not started (or failed by error)
	}

	private removeConsumer = (consumer): void => {
		this.state.remove(consumer);
	}

	startSessionKeepalive = (consumerType: SessionConsumers): void => {
		this.addConsumer(consumerType);
	}

	stopSessionKeepalive = (consumerType: SessionConsumers): void => {
		this.$log.debug('Stopping fullscreen timer');
		this.removeConsumer(consumerType);
	}
}

app.service('sessionService', downgradeInjectable(SessionService));
