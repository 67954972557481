import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ListOption } from '../forms/list-option';
import { ObjectListSort } from './object-list-sort';


@Component({
	selector: 'object-list-sort',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="objects-sort">
			<div ngbDropdown
				placement="bottom-right bottom bottom-left"
				autoClose="outside"
				(openChange)="onDropdownStateChange($event)">
				<button type="button"
					class="btn btn-selected"
					[ngClass]="{'btn-selected' : active, 'btn-secondary' : !active}"
					ngbDropdownToggle>
					{{'common.sort'|i18n}}
				</button>
				<div ngbDropdownMenu class="ph-8 filter-dropdown">
					<sort-lanes
						[maxLanes]="maxLanes"
						[columns]="columns"
						[defaultSort]="defaultSort"
						(sortsChange)="onSortsChange($event)"
						(forceApply)="applyChanges()"
					></sort-lanes>
				</div>
			</div>
		</div>
	`
})
export class ObjectListSortComponent implements OnInit {
	@Input() maxLanes?: number;
	@Input() columns: Array<ListOption<string>>;
	@Input() defaultSort: ObjectListSort;
	@Output() apply = new EventEmitter<ObjectListSort[]>();

	active: boolean = true;

	private initialSorts: ObjectListSort[];
	private sorts: ObjectListSort[];

	constructor() {}

	ngOnInit(): void {

	}

	onDropdownStateChange = (isOpen: boolean): void => {
		if (isOpen) {
			this.saveState();
		}
	}

	applyChanges = (): void => {
		this.apply.emit(this.sorts.filter(this.isActiveSort));
	}

	private hasChanges = (): boolean => {
		return !_.isEqual(this.sorts, this.initialSorts);
	}

	private saveState = (): void => {
		this.initialSorts = this.sorts.map(x => Object.assign({}, x));
	}

	onSortsChange = (sorts: ObjectListSort[]): void => {
		this.sorts = sorts;
		this.active = !this.sorts.filter(this.isActiveSort).isEmpty();

		if (this.hasChanges()) {
			this.saveState();
			this.applyChanges();
		}
	}

	private isActiveSort = (sort: ObjectListSort): boolean => {
		return !!sort.field && !!sort.direction;
	}
}
