import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { Security } from '@cxstudio/auth/security-service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { CxHeadersUtilService } from '@app/core/http/cx-headers-util.service';
import { FormattedTableColumnsService } from '@app/shared/components/table/formatted-table-columns.service';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { FileUploaderService } from '@cxstudio/services/file-upload.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';

export class UploadBulkUsersWizardComponent extends ModalBindings<any> {

	private uploader;
	private file;
	private fileReady;
	private defer;
	private loadingItem;
	private result;
	private uploadSuccess: boolean;
	private uploadWarnings: boolean;
	private timer;
	private uploadPromise;
	private forceRegister: false;
	private errorTableColumns: Array<TableColumn<any>>;

	private userValidationErrors: DetailedValidationError[];
	constructor(
		private userApiService: UserApiService,
		private exportUtils: ExportUtils,
		private urlService: UrlService,
		private fileUploadService: FileUploaderService,
		private errorDialogService: ErrorDialogService,
		private locale: ILocale,
		private $timeout,
		private $q: ng.IQService,
		private headersUtil: CxHeadersUtilService,
		private security: Security,
		private formattedTableColumnsService: FormattedTableColumnsService
	) {
		super();
	}

	$onInit(): void {
		// tslint:disable-next-line:no-bitwise
		let MAX_FILE_SIZE = 5 * 2 << 20; //10Mb
		let url = this.urlService.getAPIUrl('rest/users/upload/file/upload/');
		this.forceRegister = false;
		this.uploadPromise = this.$q.when();
		this.uploader = this.fileUploadService.getUploader({
			url,
			filters: [{
				name: 'xls',
				fn: (item) => {
					return item.name
						&& (item.name.indexOf('.xls', item.name.length - '.xls'.length) !== -1
							|| item.name.indexOf('.xlsx', item.name.length - '.xlsx'.length) !== -1);
				}
			}]
		});
		this.uploader.removeAfterUpload = true;

		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.fileReady = false;
			this.file = null;
			if (headers.error) {
				this.userValidationErrors = _.sortBy(response, 'errorRow');
			} else if (headers.eid) {
				let erroMessage = this.locale.getString('error.internal_error');
				let codeRef = this.locale.getString('error.codeReference', { rid: headers.eid });
				this.userValidationErrors = [{ errorMessage: `${erroMessage} ${codeRef}` }] ;
			} else if (this.isUnknownErrorResponse(response)) {
				this.userValidationErrors = [{ errorMessage: this.locale.getString('common.contactAdmin') }] ;
			} else {
				this.userValidationErrors = [{ errorMessage: this.locale.getString('common.contactAdmin') }] ;
			}
			this.uploadSuccess = false;
			this.defer.reject();
		};

		this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
			this.errorDialogService.error(item.size <= MAX_FILE_SIZE
					? this.locale.getString('common.unsupportedFileFormat')
					: this.locale.getString('organization.exceedMaxSize'));
		};

		this.uploader.onAfterAddingFile = (fileItem) => {
			this.loadingItem = {
				progress: 0,
				name: fileItem.file.name
			};
			this.file = fileItem;
			this.fileReady = true;
		};

		this.uploader.onProgressItem = (item, progress) => {
			if (this.loadingItem) this.loadingItem.progress = progress;
		};
		this.uploader.onBeforeUploadItem = (fileItem: any) =>  {
			fileItem.formData.push({forceRegister: this.forceRegister});
			fileItem.headers = $.extend(fileItem.headers, this.headersUtil.getCustomHeaders());
			fileItem.headers = $.extend(fileItem.headers, {filename: encodeURI(fileItem.file.name)}); // for non-ascii filenames

		};

		this.uploader.onSuccessItem = (item, response, status, headers) => {
			this.loadingItem = null;
			this.fileReady = true;
			if (response.length) {
				this.userValidationErrors = response;
				this.uploadWarnings = true;
			} else {
				this.userValidationErrors = null;
				this.uploadWarnings = false;
			}
			this.result = response;
			this.uploadSuccess = true;
			this.defer.resolve();
		};

		this.errorTableColumns = this.formattedTableColumnsService.getValidateErrorColumns();
	}

	private isUnknownErrorResponse(response): boolean {
		return typeof response === 'string' && response.indexOf('<!DOCTYPE') === 0;
	}

	cancel(): void {
		this.dismiss({$value: 'cancel'});
	}

	downloadTemplateFile = () => {
		this.userApiService.downloadTemplateFile().then((response: any ) => this.exportUtils.downloadXLSX(response));
	}

	passClickEvent = (event: any) => {
		let inputElement = (event.target.tagName.toUpperCase() === 'BUTTON')
			? event.target.getElementsByTagName('input')
			: event.target.nextElementSibling;

		this.timer = this.$timeout(() => {
			angular.element(inputElement).trigger('click');
			this.$timeout.cancel(this.timer);
		}, 0, false);
	}
	uploadFile = () => {
		if (this.file) {
			this.defer = this.$q.defer();
			this.uploadPromise = this.defer.promise;
			this.file.upload();
		}
	}

	isFileReady = (): boolean => {
		return this.fileReady === true;
	}

	uploadSucceeded = (): boolean => {
		return this.uploadSuccess;
	}
	forceRegisterDisabled = (): boolean => {
		return this.security.isForcedExternalAuthentication();
	}

}

app.component('uploadBulkUsersWizard', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	templateUrl: 'partials/user-administration/users/bulk/upload-bulk-users-wizard.component.html',
	controller: UploadBulkUsersWizardComponent

});
