import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { MetricConverter } from '@cxstudio/metrics/metric-converter.service';

import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { MetricDependenciesApiService } from './metric-dependencies-api-service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { AbstractDependencyTypesProvider } from '@app/modules/asset-management/services/abstract-dependency-types-provider';


@Injectable()
export class MetricDependencyTypesProvider extends AbstractDependencyTypesProvider<StudioAsset> {

	dependencyTypes: Array<AssetObjectType<StudioAsset, AssetDependency>>;

	constructor(
		locale: CxLocaleService,
		@Inject('metricConverter') metricConverter: MetricConverter,
		private metricDependenciesApiService: MetricDependenciesApiService
	) {
		super(locale, metricConverter);

		this.dependencyTypes = [
			this.withHandler(ObjectType.DASHBOARD, this.metricDependenciesApiService.getDashboards),
			this.withMetricsHandler(this.metricDependenciesApiService.getMetrics),
			this.withHandler(ObjectType.ALERT, this.metricDependenciesApiService.getAlerts)
		];

		this.title = locale.getString('metrics.metric');
		this.exportHandler = this.metricDependenciesApiService;
	}
}

app.service('metricDependencyTypesProvider', downgradeInjectable(MetricDependencyTypesProvider));
