import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TuningSuggestionSummaryComponent } from './create-case-modal/suggestion/tuning-suggestion-summary.component';
import { ValueChangeSummaryComponent } from './create-case-modal/value-change-summary/value-change-summary.component';
import { ConversationModule } from '../conversation/conversation.module';
import { PillsModule } from '@app/modules/pills/pills.module';
import { DocumentExplorerModule } from '../document-explorer/document-explorer.module';
import { CaseApiService } from '@app/modules/case-management/services/case-api-service.service';

@NgModule({
	imports: [
		SharedModule,
		ConversationModule,
		PillsModule,
		DocumentExplorerModule
	],
	declarations: [
		TuningSuggestionSummaryComponent,
		ValueChangeSummaryComponent,
	],
	providers: [
		CaseApiService,
	],
})

export class CaseManagementModule {}
