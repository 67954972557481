import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ISearchableHierarchyItem } from '@app/modules/utils/searchable-hierarchy-utils.service';

export enum IDrillMenuOptionGroup {
	drill = 'drill',
	action = 'action',
	alert = 'alert',
	recolor = 'recolor',
	dashboardDrill = 'dashboardDrill'
}

export interface IDrillMenuOption extends ContextMenuItem<any> {
	group?: IDrillMenuOptionGroup;
	priority?: number;
	text: string;
	tooltip?: string;
	items?: IDrillMenuOption[] | (() => angular.IPromise<IDrillMenuOption[]>);
	disabledItems?: ISearchableHierarchyItem[];
	name: string;
	obj?: any;
	searchBox?: boolean; // whether to display search for submenu
	selected?: boolean; // preselected option, will automatically show its children
	default?: boolean; // default action when clicking root, but not automatically selected in submenu
	asyncFunc?: (point: DrillPoint, obj: any) => ng.IPromise<any>;
	func?: (point?: DrillPoint, obj?: any, models?: any, event?) => void | ng.IPromise<any>; // event is used only for Rating
	tree?: boolean;
	selectable?: boolean;
	isExpandable?: boolean;
	models?: any[];
	disabled?: boolean;
	classes?: string[];
	noDefaultAction?: boolean;
	defaultAction?: (point: DrillPoint) => ng.IPromise<any>;
	levels?: number; // number of possible nested levels of submenus
	label?: string;
	ref?: string;

	focusSubmenuOption?: boolean; // used to focus the whole menu option instead of inner focusable link it contains
}
