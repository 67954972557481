import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { Component, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import OAuthExternalProvider from '../oauth-external-provider';
import { Subscription } from 'rxjs';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { OauthClientDetailsApplicationKind } from '@app/modules/system-administration/oauth/oauth-client-details-application-kind';

export interface OAuthExternalProviderEditDialogInput {
	externalProvider: OAuthExternalProvider;
}

@Component({
	selector: 'oauth-external-provider-edit-dialog',
	templateUrl: './oauth-external-provider-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OAuthExternalProviderEditDialogComponent implements OnInit, AfterViewInit {
	@Input() input: OAuthExternalProviderEditDialogInput;
	@ViewChild('externalProviderForm') public externalProviderForm: NgForm;

	invalidFields: any[] = [];
	externalProvider: OAuthExternalProvider;

	private externalProviderFormStatus$: Subscription;

	applicationKinds: any[] =[];

	constructor(
		private ref: ChangeDetectorRef,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
	) {
	}

	ngOnInit(): void {
		this.externalProvider = cloneDeep(this.input.externalProvider);
		this.applicationKinds = [
			{ name: this.locale.getString('administration.externalProvider'), value: OauthClientDetailsApplicationKind.EXTERNAL },
			{ name: this.locale.getString('administration.qualtricsProvider'), value: OauthClientDetailsApplicationKind.QUALTRICS }];
	}

	ngAfterViewInit(): void {
		this.externalProviderFormStatus$ = this.externalProviderForm.statusChanges.subscribe(() => {
			let controls = this. externalProviderForm.controls;
			this.invalidFields = Object.keys(controls)
				.filter(key => (!controls[key].valid && controls[key].status !== 'DISABLED'));
		});
		this.ref.markForCheck();
	}

	cancel = (): void => {
		this.modal.dismiss();
	}

	save = () => {
		this.modal.close(this.externalProvider);
	}

	isSettingsValid = () => {
		return this.invalidFields.length === 0;
	}
}