<div class="col-sm-4 d-flex align-items-center">
	<select-from-tree class="flex-fill"
		[ngModel]="lane.definition?.name"
		[hierarchyList]="options"
		(onNodeClick)="groupingChange.emit($event.node)">
	</select-from-tree>
	<button type="button" data-testid="attr-configure" class="btn btn-icon"
		(click)="configureModel()"
		title="{{'widget.cog'|i18n}}">
		<span class="q-icon q-icon-cog" [attr.aria-label]="'common.options'|i18n"></span>
	</button>
</div>
<div class="col-sm-4 d-flex">
	<simple-dropdown class="flex-fill mr-8"
		[options]="laneStyles"
		[disabled]="true"
		[(value)]="lane.definition.type">
	</simple-dropdown>
	<simple-dropdown
		class="font-icons"
		[options]="laneIcons"
		[(value)]="lane.definition.icon">
	</simple-dropdown>
</div>

<div class="col-sm-4 d-flex">
	<color-dropdown class="h-24 w-24"
		[(ngModel)]="lane.definition.customColor">
	</color-dropdown>
</div>
