import { NgModule } from '@angular/core';
import { AutofitHorizontallyDirective } from './autofit-horizontally.directive';

@NgModule({
	declarations: [
		AutofitHorizontallyDirective
	],
	exports: [
		AutofitHorizontallyDirective
	]
})
export class MenuDirectivesModule { }
