import ILocale from '@cxstudio/interfaces/locale-interface';
import { LocalizedConstants } from '@app/util/localized-constants.class';
import { RestUserData } from '@app/modules/user-administration/entities/rest-user-data';

export interface IAccessExpirationState {
	value: string;
	message: (user?: RestUserData) => string;
}

export class AccessExpirationState extends LocalizedConstants<IAccessExpirationState> {

	// alias for method from base class
	findState = this.findByValue;

	constructor(
		private readonly locale: ILocale,
		private readonly $filter
	) {
		super();

		this.options = {
			EXPIRED: {
				value: 'EXPIRED',
				message: user => this.locale.getString('administration.internalUserExpired')
			},
			NOT_EXPIRED: {
				value: 'NOT_EXPIRED',
				message: user => this.$filter('internalUserTimeFormat')(user.accessExpirationDate)
			},
			NEVER_EXPIRE: {
				value: 'NEVER_EXPIRE',
				message: user => this.locale.getString('administration.internalUserNeverExpire')
			}
		};
	}

}
app.service('accessExpirationState', AccessExpirationState);
