import * as _ from 'underscore';

export class ExportBuilder {

	readonly DEFAULT_START_END_SYMBOL = '"';
	readonly DEFAULT_COLUMN_DELIMETER = '","';
	readonly DEFAULT_ROW_DELIMETER = '\r\n';

	constructor(private exportData: any[]) {

	}

	joinColumns = (
		columns: any[] | string,
		delimiter = this.DEFAULT_COLUMN_DELIMETER,
		startEndSymbol = this.DEFAULT_START_END_SYMBOL
	): ExportBuilder => {
		if (!_.isEmpty(columns)) {
			let text = startEndSymbol;
			if (_.isArray(columns)) {
				text += columns.join(delimiter);
			} else if (_.isString(columns)) {
				text += columns;
			}
			text += startEndSymbol;
			this.exportData.push(text);
		}
		return this;
	}

	joinRows = (delimiter = this.DEFAULT_ROW_DELIMETER): string => {
		if (!_.isEmpty(this.exportData)) {
			let text = '';
			if (this.exportData.constructor === Array) {
				text += this.exportData.join(delimiter);
			}
			return text;
		}
		return '';
	}

	escapeComma = (text: string) => {
		return text.replace(/,/g, '/,');
	}

	addEmptyRows = (rowCount = 1, startEndSymbol = this.DEFAULT_START_END_SYMBOL): ExportBuilder => {

		for (let i = 0; i < rowCount; i++) {
			this.exportData.push(`${startEndSymbol} ${startEndSymbol}`);
		}
		return this;
	}
}
