<form [formGroup]="properties">
	<div>
		<div class="form-group">
			<label class="control-label col-md-3" [i18n]="'common.name'"></label>
			<div class="col-md-8">
				<input name="accountName" class="form-control"
					formControlName="accountName">
				<span *ngIf="accountName?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ formUtils.getErrorMessage('common.name') }}
				</span>
			</div>
		</div>
		<div class="form-group" *ngIf="masterAccountId">
			<label class="control-label col-md-3" [i18n]="'mAccount.accountOwners'"></label>
			<div class="col-md-8">
				<account-owners-input class="w-100-percent no-horiz-resize account-owners-tags-input"
					[masterAccountId]="masterAccountId"
					formControlName="owners">
				</account-owners-input>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-md-3" [i18n]="'mAccount.tags'"></label>
			<div class="col-md-8">
				<tags-input
					class="w-100-percent"
					name="accountTags"
					[maxTags]="5"
					formControlName="tags"
					[addOnBlur]="false"
					[addOnEnter]="false"
					[tagsSource]="getTagsSuggestion">
				</tags-input>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-md-3" [i18n]="'mAccount.maxReportingWidgets'"></label>
			<div class="col-md-2">
				<input type="number" class="form-control" name="maxReportingWidgets"
					formControlName="maxReportingWidgets">
				<span *ngIf="maxReportingWidgets?.invalid" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.limitInvalid'|i18n }}
				</span>
			</div>

			<label class="control-label col-md-4" [i18n]="'mAccount.maxNonReportingWidgets'"></label>
			<div class="col-md-2">
				<input type="number" class="form-control" name="maxNonReportingWidgets"
					formControlName="maxNonReportingWidgets">
				<span *ngIf="maxNonReportingWidgets?.invalid" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.limitInvalid'|i18n }}
				</span>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-md-3" [i18n]="'mAccount.maxParallelRequests'"></label>
			<div class="col-md-2">
				<input type="number" class="form-control" name="maxParallelRequests"
					formControlName="maxParallelRequests">
				<span *ngIf="maxParallelRequests?.invalid" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.disableableLimitInvalid'|i18n: {max : 100} }}
				</span>
			</div>
			<label class="control-label col-md-4" [i18n]="'mAccount.customerAdmins'"></label>
			<div class="col-md-2">
				<input type="number" class="form-control" name="maxCustomerAdmins"
					formControlName="maxCustomerAdmins">
				<span *ngIf="maxCustomerAdmins?.invalid" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.disableableLimitInvalid'|i18n: {max : 10} }}
				</span>
				<span *ngIf="maxCustomerAdmins?.errors?.min" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.lowMaxCustomerAdminsError'|i18n: {count: customerAdminsCount} }}
				</span>
			</div>
		</div>
		<div class="form-group">
			<div class="col-md-9 col-md-offset-3 mt-8">
				<div class="labeled-checkbox">
					<checkbox-button
						class="mr-0"
						id="useHsmHostname"
						name="useHsmHostname"
						label="{{'mAccount.useHsmHostname'|i18n}}"
						formControlName="useHsmHostname"
						[buttonClass]="'btn-sm'">
					</checkbox-button>
				</div>
				<div class="labeled-checkbox">
					<checkbox-button
						class="mr-0"
						id="isAdminOrg"
						name="isAdminOrg"
						label="{{'administration.adminOrganization'|i18n}}"
						formControlName="isAdminOrg"
						[buttonClass]="'btn-sm'">
					</checkbox-button>
				</div>
				<span [hidden]="!hasNotAllowedAdminOrgChange()" class="text-danger">
					<input class="d-none" type="text" formControlName="adminOrgAllowed"/>
					<span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'administration.adminOrgWarning'|i18n }}
				</span>

				<div class="labeled-checkbox">
					<checkbox-button
						class="mr-0"
						id="dashboardScheduling"
						name="dashboardScheduling"
						label="{{'administration.dashboardScheduling'|i18n}}"
						formControlName="dashboardScheduling"
						[buttonClass]="'btn-sm'">
					</checkbox-button>
				</div>
			</div>
		</div>
	</div>
</form>
