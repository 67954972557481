import { MetricWidgetVisualizationOptions } from './metric-widget-rendering.service';

export enum MetricWidgetOrientation {
	HORIZONTAL = 'horizontal',
	VERTICAL = 'vertical'
}

export default class MetricWidgetSizeUtils {
	static readonly WIDESCREEN_RATIOS = [1.33, 1.78, 1.85, 2.39];
	static readonly FULL_WIDTH = 320;
	static readonly SHORT_WIDTH = 200;
	static readonly FULL_HEIGHT = 320;
	static readonly PREVIOUS_PERIOD_HEIGHT = 240;
	static readonly GOAL_HEIGHT = 200;
	static readonly SHORT_HEIGHT = 150;


	static resetBoxSize = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>): void => {
		let size = MetricWidgetSizeUtils.getBoxSize(options);

		element.find('svg').attr('viewBox', `0 0 ${size.width} ${size.height}`);
	}

	static getWidgetSizeClass = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>): string => {
		const breakpoints = {
			horizontal: [
				{ val: 150, className: 'widget-xs' },
				{ val: 300, className: 'widget-sm' }
			],
			vertical: [
				{ val: 150, className: 'widget-xs' },
				{ val: 300, className: 'widget-sm' }
			]
		};
		let orientation = MetricWidgetSizeUtils.isHorizontalOrientation(element, options) ?
			MetricWidgetOrientation.HORIZONTAL :
			MetricWidgetOrientation.VERTICAL;

		let minDimension = element.height() >= element.width() ?
			element.width() :
			element.height();

		for (let size of breakpoints[orientation]) {
			if (minDimension <= size.val)
				return size.className;
		}

		return '';
	}

	static getHorizontalBoxSize  = (element: JQuery): { width: number, height: number} => {
		let height = MetricWidgetSizeUtils.FULL_HEIGHT;
		let width = height * MetricWidgetSizeUtils.getViewRatio(element);

		return { width, height };
	}

	private static getBoxSize(options: Partial<MetricWidgetVisualizationOptions>): { width: number, height: number} {
		return {
			width: MetricWidgetSizeUtils.getBoxWidth(options),
			height: MetricWidgetSizeUtils.getBoxHeight(options)
		};
	}

	private static getBoxWidth(options: Partial<MetricWidgetVisualizationOptions>): number {
		return options.trendArrow ? this.FULL_WIDTH : this.SHORT_WIDTH;
	}

	private static getBoxHeight(options: Partial<MetricWidgetVisualizationOptions>): number {
		let isFull = options.trendArrow || (options.previousPeriod && options.goal);
		if (isFull) {
			return MetricWidgetSizeUtils.FULL_HEIGHT;
		} else if (options.previousPeriod) {
			return MetricWidgetSizeUtils.PREVIOUS_PERIOD_HEIGHT;
		} else if (options.goal) {
			return MetricWidgetSizeUtils.GOAL_HEIGHT;
		}
		return MetricWidgetSizeUtils.SHORT_HEIGHT;
	}

	static getOrientationClasses = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>,
			forceVertical: boolean = false): string[] => {
		if (forceVertical) {
			return [MetricWidgetOrientation.VERTICAL as string];
		}
		let calculatedRatio = String(MetricWidgetSizeUtils.getViewRatio(element)).replace('.', '_');
		let ratioClass = `view-ratio-${calculatedRatio}`;

		if (!MetricWidgetSizeUtils.getOrientation(element, options)) return [ratioClass];
		if (!MetricWidgetSizeUtils.isHorizontalOrientation(element, options)) return [MetricWidgetOrientation.VERTICAL as string];

		let classes = [MetricWidgetOrientation.HORIZONTAL as string];
		classes.push(ratioClass);

		return classes;
	}


	/**
	 * If there is no change graph or previous period, don't set any orientation
	 */
	static isNoOrientation = (options: Partial<MetricWidgetVisualizationOptions>): boolean => {
		return !(options.previousPeriod || options.trendArrow);
	}

	static getOrientation = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>): MetricWidgetOrientation => {
		if (MetricWidgetSizeUtils.isNoOrientation(options)) return;

		return (element.width() / element.height()) >= MetricWidgetSizeUtils.WIDESCREEN_RATIOS[0] ?
			MetricWidgetOrientation.HORIZONTAL :
			MetricWidgetOrientation.VERTICAL;
	}

	/**
	 * Returns true if there is no content orientation (no change graph or previous period)
	 * but the available space is oriented vertically (more tall than wide)
	 */
	static isNoOrientationVertical = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>): boolean => {
		if (MetricWidgetSizeUtils.isNoOrientation(options)) {
			return (element.width() / element.height()) <= MetricWidgetSizeUtils.WIDESCREEN_RATIOS[0];
		}
	}

	static isHorizontalOrientation = (element: JQuery, options: Partial<MetricWidgetVisualizationOptions>): boolean => {
		return MetricWidgetSizeUtils.getOrientation(element, options) === MetricWidgetOrientation.HORIZONTAL;
	}

	private static getViewRatio = (element: JQuery): number => {
		let ratio = (element.width() / element.height());

		return _.find([...MetricWidgetSizeUtils.WIDESCREEN_RATIOS].reverse(), item => ratio >= item) ||
			MetricWidgetSizeUtils.WIDESCREEN_RATIOS[0];
	}
}
