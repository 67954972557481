import { IDirectiveFactory, INgModelController } from 'angular';

// will strictly enforce a maxium numerical value onto a field
// values entered > max will be automatically updated to reflect the max attr
//
// example : <input type="number" max=100 strict-max ng-model="sample.value">

const strictMax = () => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: ($scope, element, attr, ctrl: INgModelController) => {
			if (!attr.max) return;

			let forceMax = Number(attr.max);

			let forceFunc = (val) => {
				if (Number(val) > forceMax) {
					ctrl.$setViewValue(forceMax);
					ctrl.$render();
					return forceMax;
				}
				return Number(val);
			};

			ctrl.$parsers.unshift(forceFunc);
			ctrl.$formatters.unshift(forceFunc);
		}
	};
};

app.directive('strictMax', strictMax as IDirectiveFactory);
