<form [formGroup]="externalAuthSettings">
	<div class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button id="enableExternalAuth"
					name="enableExternalAuth"
					label="{{'mAccount.enableExternalAuthIntegration'|i18n}}"
					formControlName="enabled">
				</checkbox-button>
			</div>
		</div>

		<div class="rounded-grouping mt-16" formGroupName="oauthSettings">
			<div class="rounded-grouping-label">
				<div class="labeled-checkbox">
					<checkbox-button id="enableExternalOAuth"
						name="enableExternalOAuth"
						label="{{'mAccount.enableOAuthIntegration'|i18n}}"
						formControlName="enabled">
					</checkbox-button>
				</div>
			</div>
			<div class="form-group">
				<label for="externalProvider" class="control-label col-md-3">{{'mAccount.authenticationProvider'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown [ngBusy]="oauthProviderLoading"
						class="form-control p-0 border-0"
						id="externalProvider"
						[options]="oauthProviders"
						formControlName="providerId"
						displayField="aliasName"
						valueField="id"
						sortField="id">
					</simple-dropdown>
					<span *ngIf="oauthSettingsProvider?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.authenticationProvider') }}
					</span>
				</div>
			</div>
		</div>


		<div class="rounded-grouping" formGroupName="samlSettings">
			<div class="rounded-grouping-label">
				<div class="labeled-checkbox">
					<checkbox-button id="enableExternalSaml"
						name="enableExternalSaml"
						label="{{'mAccount.enableSamlIntegration'|i18n}}"
						formControlName="enabled">
					</checkbox-button>
				</div>
			</div>
			<div class="form-group">
				<label for="externalProvider" class="control-label col-md-3">{{'mAccount.samlProvider'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown [ngBusy]="samlProvidersLoading"
						class="form-control border-0 p-0"
						id="externalIdpProvider"
						[options]="samlProviders"
						formControlName="aliasName"
						displayField="aliasName"
						valueField="aliasName"
						sortField="aliasName"
						(onChange)="updateSamlProvider($event)">
					</simple-dropdown>
					<span *ngIf="samlSettingsProvider?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.samlProvider') }}
					</span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-8 col-md-offset-1">
				<div class="labeled-checkbox">
					<cb-inline-help>
						<help-body [innerHTML]="'mAccount.exclusiveIdpAuthenticationHelp'|i18n"></help-body>
					</cb-inline-help>
					<checkbox-button
						id="exclusiveIDP"
						name="exclusiveIDP"
						label="{{'mAccount.exclusiveIdpAuthentication'|i18n}}"
						formControlName="forcedExternalAuthentication">
					</checkbox-button>
				</div>
			</div>
		</div>
	</div>
	<span *ngIf="externalAuthSettings?.errors?.atLeastOneValid" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
		{{ formUtils.getErrorMessage('mAccount.atLeastOneValid') }}
	</span>
</form>
