<widget-title-settings class="d-flex mb-16"
	[(ngModel)]="widget.displayName"
	ngDefaultControl
	[props]="widget.properties"
	[visualProperties]="widget.visualProperties"
	(updateAutoTitle)="updateAutoTitle()">
</widget-title-settings>
<collapsing-panel
	[collapsed]="false">
	<panel-heading>
		<strong>{{'widgetSettings.embedConfiguration'|i18n}}</strong>
	</panel-heading>
	<panel-body class="d-flex flex-direction-column">
		<label><strong>{{'widgetSettings.embedWidgetId'|i18n}}</strong></label>
		<label class="m-0" for="embedCode">{{'widgetSettings.embedWidgetIdSubtitle'|i18n}}</label>
		<input
			id="embedCode"
			[(ngModel)]="widget.properties.embedCode"
			placeholder="{{'widgetSettings.embedWidgetCodePlaceholder' | i18n}}"
			[pattern]="EMBED_CODE_PATTERN"
			#embedCode="ngModel"
			class="form-control">
		<label class="mb-0 mt-8">{{'widgetSettings.embedWidgetDescription'|i18n}}</label>
		<textarea
			name="description"
			class="text-area form-control"
			rows="4"
			maxlength="256"
			[(ngModel)]="widget.description"></textarea>
		<div *ngIf="embedCode.errors?.pattern">
			<alert class="mt-8" type="warning" text="{{'widgetSettings.invalidEmbedCode' | i18n}}"></alert>
		</div>
	</panel-body>
</collapsing-panel>
