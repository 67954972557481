<div class="form-horizontal" [ngBusy]="projectsLoading">
	<simple-dialog
		header="{{'common.selectProject'|i18n}}"
		okBtnName="{{'common.ok'|i18n}}"
		cancelBtnName="{{'common.cancel'|i18n}}"
		[value]="value">

		<ng-container *ngIf="!isWorkspaceEnabled">
			<project-selector
				[projectSelection]="projectPreselection"
				(projectSelectionChange)="onProjectSelectionChange($event)"
				(loading)="onProjectsLoading($event)"
				(errorsChange)="onCpErrorsChange($event)"
			></project-selector>
			<alert type="danger" *ngFor="let cperror of contentProviderErrors">
				<span>{{ cperror }}</span>
			</alert>
		</ng-container>

		<workspace-project-selector
			*ngIf="isWorkspaceEnabled"
			labelPosition="left"
			[help]="true"
			(workspaceChange)="onWorkspaceChange($event)"
			(valueChange)="onProjectChange($event)"
			(loading)="onProjectsLoading($event)"
		></workspace-project-selector>
	</simple-dialog>
</div>
