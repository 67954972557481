import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemGridUpgradeDirective } from '@app/modules/item-grid/item-grid-upgrade.directive';
import { StickySlickHeaderDirective } from '@app/modules/item-grid/sticky-slick-header.directive';
import { GridInputFormatterService } from '@app/modules/item-grid/grid-input-formatter.service';

@NgModule({
	declarations: [
		ItemGridUpgradeDirective,
		StickySlickHeaderDirective
	],
	exports: [
		ItemGridUpgradeDirective,
		StickySlickHeaderDirective
	],
	providers: [
		GridInputFormatterService
	],
	imports: [
		CommonModule
	]
})
export class ItemGridModule { }
