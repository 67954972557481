import { ITreeSelectorGroup } from './items-tree-group';

export class CommonOptionsBuilder<T> {

	private template: ITreeSelectorGroup[];
	private availableOptions: T[];

	constructor(template: ITreeSelectorGroup[]) {
		this.template = template;
		this.availableOptions = [];
	}

	withOptions(options: T[]): CommonOptionsBuilder<T> {
		this.availableOptions.pushAll(options);
		return this;
	}

	build(): ITreeSelectorGroup[] {
		return this.fillTemplate(this.template);
	}

	private fillTemplate = (template: ITreeSelectorGroup[]): ITreeSelectorGroup[] => {
		return template.map(templateGroup => {
			let group = angular.copy(templateGroup);
			group.children.pushAll(this.availableOptions.filter(templateGroup.filter));
			return group;
		});
	}
}
