<div [ngBusy]="projectsLoading">

	<span class="d-block pb-16"
		[i18n]="'administration.selectProjects'"
		[i18nParams]="{email: user.email}">
	</span>

	<div *ngIf="!!accessModel.cp" class="mb-16">
		<simple-dropdown class="mr-8" *ngIf="isLinkedToMultipleContentProviders()"
			[options]="contentProvidersWithAccounts"
			valueField="id"
			[(value)]="dropdownsSelection.cpId"
			(valueChange)="onCpChange()"
			displayField="name"
		></simple-dropdown>
		<simple-dropdown [disabled]="!isLinkedToMultipleAccountsInContentProvider(accessModel.cp)"
			[options]="accessModel.cp.accounts"
			valueField="accountId"
			[(value)]="dropdownsSelection.accountId"
			(valueChange)="onAccountChange()"
			displayField="accountName"
		></simple-dropdown>
	</div>

	<alert type="danger" *ngIf="!!getError()">{{getError()}}</alert>

	<div *ngIf="!getError()">
		<div *ngIf="user.licenseTypeId == 1">
			<div class="labeled-radio">
				<input
					id="grantAccountAdmin"
					name="grantAccountAdmin"
					type="radio"
					class="checkbox"
					[value]="true"
					[checked]="accessModel.accountAccessData.accountAdmin"
					[disabled]="!isEditorAccountAdmin()"
					(click)="grantAccountAdmin(true)">
				<label for="grantAccountAdmin" [i18n]="'administration.grantAccountAdmin'"></label>
			</div>
			<br>
			<div class="labeled-radio">
				<input
					id="grantSelectedProjects"
					name="grantSelectedProjects"
					type="radio"
					[value]="false"
					[checked]="!accessModel.accountAccessData.accountAdmin"
					[disabled]="!isEditorAccountAdmin()"
					(click)="grantAccountAdmin(false)">
				<label for="grantSelectedProjects" [i18n]="'administration.grantSelectedProjects'"></label>
			</div>
		</div>

		<div class="cx-project-access-all-block">
			<div>
				<a style="text-decoration: none;" *ngIf="hasMarkAllAdmin()"
					(click)="markAllAdmin()" [i18n]="'administration.markAllAdmin'">
				</a>
			</div>
			<div>
				<a style="text-decoration: none;"
					(click)="markAllReadOnly()" [i18n]="'administration.markAllReadOnly'">
				</a>
			</div>
			<div>
				<a style="text-decoration: none;"
					(click)="markAllNoAccess()" [i18n]="'administration.markAllNoAccess'">
				</a>
			</div>
		</div>

		<div class="cx-project-access">
			<table class="table">
				<thead>
					<tr>
						<th [i18n]="'common.project'"></th>
						<th [i18n]="'administration.access'"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let projectData of accessModel.accountAccessData.projects">
						<td>{{projectData.project.name}}</td>
						<td>
							<select *ngIf="!isDisabledOption(projectData.project.id, projectData.accessLevel)"
								class="form-control"
								[(ngModel)]="projectData.accessLevel"
								(change)="projectAccessChanged()"
								[disabled]="accessModel.accountAccessData.accountAdmin">
								<option
									*ngFor="let projectAccess of getActiveAccessValues(projectData.project.id)"
									value="{{projectAccess.value}}">
									{{projectAccess.label}}
								</option>
							</select>
							<select *ngIf="isDisabledOption(projectData.project.id, projectData.accessLevel)"
								class="form-control"
								[(ngModel)]="projectData.accessLevel"
								(change)="projectAccessChanged()"
								[disabled]="true">
								<option
									*ngFor="let projectAccess of getDisabledAccessValues()"
									value="{{projectAccess.value}}">
									{{projectAccess.label}}
								</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
