import { CustomMathAdapter } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter.class';
import { FormulaSegment, SupportTextErrors } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { MathMetricToken } from '@app/modules/metric/definition/custom-math/editor/math-metric-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { CustomMathMetrics } from '../adapter/custom-math-metrics';

export class CustomMathFormula {
	private formula: string;
	private formulaSegments: FormulaSegment[];
	private tokens: MathMetricToken[];

	constructor(
		private customMathAdapter: CustomMathAdapter,
		private expressions: ExpressionItem[],
		private appliedMetrics: CustomMathMetrics
	) {
		let text = customMathAdapter.toFormula(this.expressions);
		this.setText(text, appliedMetrics);
	}

	private getTokensFromSegment(segment: FormulaSegment): MathMetricToken[] {
		if (!segment.textTokens) return [];
		let offset = segment.startOffset;
		return segment.textTokens.map(textToken => {
			let mathToken = Object.assign(textToken, { offset });
			offset += textToken.text.length;
			return mathToken;
		});
	}

	getTokensFromSegments(segments: FormulaSegment[]): MathMetricToken[] {
		// exposing this for getting tokens out of stored segments.
		return _.chain(segments)
			.map(this.getTokensFromSegment)
			.flatten()
			.value();
	}

	setText(text: string, appliedMetrics: CustomMathMetrics): void {
		this.formula = text;
		this.formulaSegments = this.customMathAdapter.toSegments(this.formula, appliedMetrics);
		this.tokens = this.getTokensFromSegments(this.formulaSegments);
	}

	getText(): string {
		return this.formula;
	}

	getSegments(): FormulaSegment[] {
		return this.formulaSegments;
	}

	getExpressions(): ExpressionItem[] {
		return this.formulaSegments.map(segment => segment.expression).filter(expression => !!expression);
	}

	isValid = (): boolean => {
		let hasSegmentErrors = this.hasErrors(this.formulaSegments);
		let hasTokenErrors = !this.formulaSegments.filter(segmemt => this.hasErrors(segmemt.textTokens)).isEmpty();
		return !hasSegmentErrors && !hasTokenErrors && this.formula.trim() !== '';
	}

	private hasErrors = (items: SupportTextErrors[]): boolean => {
		return !items.filter(item => item.errors).isEmpty();
	}

	getTokens(): MathMetricToken[] {
		return this.tokens;
	}

	/**
	 * Returns the preceding tokens in reverse order (R to L).
	 * @description 
	 * returnVal[0] is the token immediately before the current index
	 * returnVal[returnVal.length-1] is the first item in the formula, reading from L to R
	 */
	getPreviousTokens(startingFromOffset: number): MathMetricToken[] {
		let leadingTokens = this.tokens.filter(token => token.offset + token.text.length <= startingFromOffset);
		return leadingTokens.reverse();
	}

	getTokenIndex(offset: number): number {
		return this.tokens.findIndex(token => this.isTokenAtOffset(token, offset));
	}

	getTokenAtPosition(offset: number): MathMetricToken {
		let index = offset < this.formula.length ? this.getTokenIndex(offset) : -1;
		return index > -1 ? this.getToken(index) : undefined;
	}

	getToken(index: number): MathMetricToken {
		return this.tokens[index];
	}

	getPreviousToken(index: number): MathMetricToken {
		return index > 0 
			? this.tokens[index - 1]
			: undefined;
	}

	getNextToken(index: number): MathMetricToken {
		return index < this.tokens.length - 1 
			? this.tokens[index + 1]
			: undefined;
	}

	getLastToken(): MathMetricToken {
		return !this.tokens.isEmpty() 
			? this.tokens[this.tokens.length - 1] 
			: undefined;
	}

	private isTokenAtOffset(token: MathMetricToken, offset: number): boolean {
		return (token.offset <= offset && token.offset + token.text.length > offset)
			|| (token.offset === offset && token.text.length === 0);
	}
}