import { NgModule } from '@angular/core';
import { ObjectListModule } from '@app/modules/object-list/object-list.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { ProjectAssetsPageComponent } from './project-assets-page/project-assets-page.component';
import { ProjectsManagementUpgrade } from '@app/modules/project/project-assets-management/project-assets-page/projects-management.upgrade.directive';
import { ProjectAssetsListComponent } from './project-assets-list/project-assets-list.component';
import { UnitsModule } from '@app/modules/units/units.module';
import { ResourceTypeSelectorComponent } from './project-assets-list/resource-type-selector/resource-type-selector.component';
import { AssetVisibilityTabsComponent } from './project-assets-list/asset-visibility-tabs/asset-visibility-tabs.component';
import { AttributesTableComponent } from './project-assets-list/attributes-table/attributes-table.component';
import { ModelsTableComponent } from './project-assets-list/models-table/models-table.component';
import { ScorecardsTableComponent } from './project-assets-list/scorecards-table/scorecards-table.component';
import { HiddenAssetsTableComponent } from './project-assets-list/hidden-assets-table/hidden-assets-table.component';


@NgModule({
	imports: [
		SharedModule,
		I18nModule,
		ObjectListModule,
		UnitsModule,
	],
	declarations: [
		ProjectAssetsPageComponent,
		ProjectsManagementUpgrade,
		ProjectAssetsListComponent,
		ResourceTypeSelectorComponent,
		AssetVisibilityTabsComponent,
		AttributesTableComponent,
		ModelsTableComponent,
		ScorecardsTableComponent,
		HiddenAssetsTableComponent,
	],
})
export class ProjectAssetsManagementModule { }
