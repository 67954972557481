import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';


@Component({
	selector: 'master-account-hierarchy-settings',
	templateUrl: './master-account-hierarchy-settings.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterAccountHierarchySettingsComponent extends SelfCleaningComponent {
	hierarchySettings: FormGroup;

	constructor(
		readonly formUtils: CxFormUtils,
		private fb: FormBuilder,
	) {
		super();
		this.hierarchySettings = this.fb.group({
			customRestrictionsEnabled: [ false ],
			nodesMaxCount: [ { value: null, disabled: true } ],
			filterMaxLength: [ { value: null, disabled: true } ],
			parentRuleInheritanceLimit: [ { value: null, disabled: true } ],
		});

		this.addSubscription(this.hierarchySettings.get('customRestrictionsEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.nodesMaxCount, this.filterMaxLength, this.parentRuleInheritanceLimit);
			} else {
				this.formUtils.disableFields(this.nodesMaxCount, this.filterMaxLength, this.parentRuleInheritanceLimit);
			}
		}));
	}

	getGroup(): FormGroup {
		return this.hierarchySettings;
	}

	get nodesMaxCount(): FormControl {
		return this.hierarchySettings.controls.nodesMaxCount as FormControl;
	}

	get filterMaxLength(): FormControl {
		return this.hierarchySettings.controls.filterMaxLength as FormControl;
	}

	get parentRuleInheritanceLimit(): FormControl {
		return this.hierarchySettings.controls.parentRuleInheritanceLimit as FormControl;
	}
}
