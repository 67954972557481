import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'progress-bar-renderer',
	template: `
	<div class="cell-progress-bar h-100-percent w-100-percent d-flex flex-direction-row align-items-center">
		<div class="w-100-percent h-50-percent mr-16">
			<ngb-progressbar
				[value]="getValue()"
				[striped]="true"
				[type]="getProgressType()"
			></ngb-progressbar>
		</div>
		<span class="w-20-percent">{{ getValue() }}%</span>
	</div>`
})
export class ProgressBarRendererComponent implements ICellRendererAngularComp {
	public value: number;

	agInit(params: any): void {
		this.value = params.value;
	}

	refresh(params: any): boolean {
		this.value = params.value;
		return true;
	}

	getProgressType(): string {
		let roundedValue = this.getRoundedValue();

		if (roundedValue <= 50) {
			return 'success-500';
		} else if (roundedValue <= 70) {
			return 'warning-500';
		} else {
			return 'danger-600';
		}
	}

	getValue(): number {
		return Math.min(this.getRoundedValue(), 100);
	}

	private getRoundedValue(): number {
		return Math.floor(this.value);
	}
}
