import { ProjectFilterData } from '@cxstudio/angular-filters/project-filter-data';

// tslint:disable-next-line: only-arrow-functions & typedef
app.filter('projectFilter', function() {
	function hasProjectMetrics(items, projectId: number): any[] {
		return items.filter(
			(item) => {
				if (item.children && item.children.length > 0) {
					let childMatch = hasProjectMetrics(item.children, projectId);
					return childMatch.length > 0;
				} else {
					return item.projectId === projectId
						|| (_.isUndefined(item.projectId) && !item.hasAssets);
				}
			});
	}

	return (items, project: ProjectFilterData) => {
		if (project && project.projectId && !project.singleProject) {
			return hasProjectMetrics(items, project.projectId);
		}
		return [].concat(items);
	};
});
