<collapsing-panel>
	<panel-heading>{{ 'topicConversions.topicTranslationHeader' | i18n }}</panel-heading>
	<panel-body>
		<section>
			<p>{{ 'topicConversions.topicTranslationUploadDescription' | i18n }}</p>
			<file-upload
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.CSV]"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(removedUpload)="handleRemovedUpload()"
			>
			</file-upload>
		</section>
		<section *ngIf="uploadedTopicModelFile" class="mt-32">
			<p>{{ 'topicConversions.topicTranslationLanguagesDescription' | i18n }}</p>
			<ul class="p-0">
				<li *ngFor="let selectableLanguage of selectableLanguages">
					<checkbox-button
						[label]="selectableLanguage.name"
						(onChange)="toggleSelectableLanguage(selectableLanguage)"
					></checkbox-button>
				</li>
			</ul>
			<button
				class="btn btn-primary"
				[disabled]="isSubmitDisabled()"
				(click)="submit()"
			>
				{{ 'common.submit' | i18n }}
			</button>
		</section>
		<alert
			*ngIf="successMessage"
			type="success"
			class="mb-0 mt-16"
			[text]="successMessage"
			[dismissable]="false"
		>
		</alert>
	</panel-body>
</collapsing-panel>
