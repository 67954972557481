/**
 * Directive for bootstrap-like loading button
 * Has NG twin
 */
app.directive('btnLoading', () => {
	return {
		link: (scope, element, attrs) => {
			scope.$watch(() => scope.$eval(attrs.btnLoading),
				(value) => {
					if (value) {
						element.addClass('disabled').attr('disabled', 'disabled');
						element.data('resetText', element.html());
						element.html(element.data('loading'));
					} else {
						element.removeClass('disabled').removeAttr('disabled');
						element.html(element.data('resetText'));
					}
				});
		}
	};
});
