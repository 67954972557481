import { Injectable, TemplateRef, Type } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { AlternateConfirmDialogComponent } from '@app/modules/dialog/components/alternate-confirm-dialog/alternate-confirm-dialog.component';
import { CustomSaveDialogComponent } from '@app/modules/dialog/components/custom-save-dialog/custom-save-dialog.component';
import { SelectDialogComponent, SelectDialogOption } from '@app/modules/dialog/components/select-dialog.component';
import { UnsavedChangesDialogComponent } from '@app/modules/dialog/components/unsaved-changes/unsaved-changes-dialog.component';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { DialogStyle } from './dialog-style';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog.component';

export enum ModalSize {
	SMALL = 'sm',
	MEDIUM = 'md',
	LARGE = 'lg',
	EXTRA_LARGE = 'xl',
}

export interface ModalOptions {
	size?: ModalSize;
	keyboard?: boolean;
	class?: string;
	backdrop?: boolean | 'static';
	scrollable?: boolean | false;
	container?: string | HTMLElement;
}

@Injectable()
export class CxDialogService {
	readonly DEFAULT_WINDOW_CLASS = 'ng-modal-dialog';

	constructor(
		private readonly modalService: NgbModal,
		private readonly locale: CxLocaleService
	) { }

	generateNgbModelOptions(options?: ModalOptions): NgbModalOptions {
		let modalOptions: NgbModalOptions = {
			backdrop: 'static',
			windowClass: this.DEFAULT_WINDOW_CLASS,
			container: options?.container,
		};

		if (options?.class) {
			modalOptions.backdropClass = options.class;
			modalOptions.windowClass += ` ${options.class}`;
		}
		if (options?.size) modalOptions.size = options.size;
		if (options?.keyboard === false) modalOptions.keyboard = options.keyboard;
		if (!_.isUndefined(options?.backdrop)) modalOptions.backdrop = options.backdrop;

		return modalOptions;
	}

	openWizard(dialogComponent: any, input?: any, options?: ModalOptions): NgbModalRef {
		return this.openDialog(dialogComponent, input, options);
	}

	openDialog<T>(dialogComponent: Type<IModalComponent<T>>, input?: T, options?: ModalOptions): NgbModalRef;
	openDialog(dialogComponent: Type<any>, input?: undefined, options?: ModalOptions): NgbModalRef;
	openDialog<T = any>(dialogComponent: Type<IModalComponent<T>> | Type<any>,
		input?: T | undefined, options?: ModalOptions): NgbModalRef {
		let modalOptions = this.generateNgbModelOptions(options);

		let modalInstance = this.modalService.open(dialogComponent, modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.input = input;
		return modalInstance;
	}

	openSelectDialog(header: string, label: string, options: SelectDialogOption[], help?: string): any { //Promise<T>
		let modalOptions = this.generateNgbModelOptions();
		let modalInstance = this.modalService.open(SelectDialogComponent, modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.label = label;
		componentInstance.options = options;
		componentInstance.settings = { help };
		return modalInstance.result;
	}

	openAsyncSelectDialog(header: string, label: string, options: Promise<SelectDialogOption[]>, help?: string): any { //Promise<T>
		let modalOptions = this.generateNgbModelOptions();
		let modalInstance = this.modalService.open(SelectDialogComponent, modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.label = label;
		componentInstance.optionsPromise = options;
		componentInstance.settings = { help, search: true };
		return modalInstance.result;
	}

	notify = (header: string, msg: string, options?: ModalOptions): NgbModalRef => {
		let modalOptions = this.generateNgbModelOptions(options);
		let modalInstance = this.modalService.open(ConfirmDialogComponent,
			modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.value = msg;
		return modalInstance;
	}

	warning = (header: string, msg: string, options?: ModalOptions): NgbModalRef => {
		let modalOptions = this.generateNgbModelOptions(options);
		modalOptions.windowClass += ' br-warning-dialog';
		let modalInstance = this.modalService.open(ConfirmDialogComponent,
			modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.value = msg;
		return modalInstance;
	}

	/**
	 * Opens a modal confirming if you want to delete something
	 */
	delete(header: string, htmlBody: string, options?: ModalOptions): NgbModalRef {
		let modalOptions = this.generateNgbModelOptions(options);
		let modalInstance = this.modalService.open(DeleteConfirmDialogComponent,
			modalOptions);

		let componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.htmlBody = htmlBody;
		return modalInstance;
	}


	regularWithConfirm = (header: string, msg: string, okBtnName?: string, cancelBtnName?: string, options?: ModalOptions): NgbModalRef => {
		return this.openConfirmDialog(DialogStyle.REGULAR, header, msg, okBtnName, cancelBtnName, options);
	}

	warningWithConfirm = (header: string, msg: string, okBtnName?: string, cancelBtnName?: string, options?: ModalOptions): NgbModalRef => {
		return this.openConfirmDialog(DialogStyle.WARNING, header, msg, okBtnName, cancelBtnName, options);
	}

	dangerWithConfirm = (header: string, msg: string, okBtnName?: string, cancelBtnName?: string, options?: ModalOptions): NgbModalRef => {
		return this.openConfirmDialog(DialogStyle.DANGER, header, msg, okBtnName, cancelBtnName, options);
	}

	private openConfirmDialog = (
		dialogStyle: DialogStyle, header: string, msg: string,
		okBtnName?: string, cancelBtnName?: string, options?: ModalOptions
	): NgbModalRef => {
		const modalOptions = this.generateNgbModelOptions(options);
		const modalInstance = this.modalService.open(SimpleDialogComponent, modalOptions);
		const componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.text = msg;
		componentInstance.value = true;
		componentInstance.okBtnName = okBtnName || this.locale.getString('common.ok');
		componentInstance.okBtnClass = 'confirm-modal';
		componentInstance.cancelBtnName = cancelBtnName || this.locale.getString('common.cancel');
		componentInstance.dialogStyle = dialogStyle;
		return modalInstance;
	}

	openAlternateConfirmDialog = (
		header: string, msg: string,
		confirmBtnName?: string, altConfirmBtnName?: string, cancelBtnName?: string
	): NgbModalRef => {
		const modalOptions = this.generateNgbModelOptions();
		const modalInstance = this.modalService.open(AlternateConfirmDialogComponent, modalOptions);
		const componentInstance = modalInstance.componentInstance;
		componentInstance.header = header;
		componentInstance.text = msg;
		componentInstance.confirmBtnName = confirmBtnName || this.locale.getString('common.yes');
		componentInstance.altConfirmBtnName = altConfirmBtnName || this.locale.getString('common.no');
		componentInstance.cancelBtnName = cancelBtnName || this.locale.getString('common.cancel');
		componentInstance.dialogStyle = DialogStyle.WARNING;
		return modalInstance;
	}

	showUnsavedChangesDialog = (
		headerText: string,
		bodyText: string,
		hideRollbackOption?: boolean,
		disableSave?: boolean,
		saveText?: string,
		dontSaveText?: string,
		cancelText?: string
	): Promise<boolean> => {
		return this
			.openDialog(
				UnsavedChangesDialogComponent,
				{
					headerText,
					bodyText,
					hideRollbackOption,
					disableSave,
					saveText,
					dontSaveText,
					cancelText
				}
			)
			.result
		;
	}

	showUnsavedChangesDialogAndResolve = (
		confirmCallback: () => void,
		dontSaveCallback: () => void,
		headerText: string,
		bodyText: string,
		disableSave?: boolean,
		saveText?: string,
		dontSaveText?: string,
		cancelText?: string
	): Promise<void> => {
		return this
			.showUnsavedChangesDialog(
				headerText,
				bodyText,
				false,
				disableSave,
				saveText,
				dontSaveText,
				cancelText
			)
			.then(resp => resp === true ? confirmCallback() : dontSaveCallback())
		;
	}

	openCustomSaveDialog = <T>(
		header: string, template: TemplateRef<unknown>,
		saveBtnName?: string, cancelBtnName?: string
	): Promise<T> => {
		const modalOptions = this.generateNgbModelOptions();
		const modalInstance = this.modalService.open(CustomSaveDialogComponent, modalOptions);
		const componentInstance = modalInstance.componentInstance as CustomSaveDialogComponent;
		componentInstance.header = header;
		componentInstance.template = template;
		componentInstance.saveBtnName = saveBtnName || this.locale.getString('common.save');
		componentInstance.cancelBtnName = cancelBtnName || this.locale.getString('common.cancel');
		return modalInstance.result;
	}
}

app.service('cxDialogService', downgradeInjectable(CxDialogService));
