import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';

@Injectable({
	providedIn: 'root'
})
export class DashboardFiltersDragDropService {

	private readonly FILTER_HEIGHT: number = 62;
	private prevFilterIndex: number;

	constructor(
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService
	) {}

	dragStart = (filter): void => {
		if (filter) {
			filter.placeholder = true;
		}
	}

	dragStop = (filter): void => {
		if (filter) {
			filter.placeholder = false;
		}
	}

	dragMove = (filter: DashboardFilter, filterArray: DashboardFilter[]): void => {
		// this whole function works incorrectly - calculations and constants don't make any sense
		// hopefully we will replace this with angular drag&drop with no manual calculations
		let container = this.dashboardFiltersService.getContainer();
		let pointer = this.dashboardFiltersService.getPointer();
		let placeholder = this.dashboardFiltersService.getPlaceholder();

		let placeholderOffset = this.FILTER_HEIGHT - placeholder.height;
		let containerOffset = container.scroll.top + pointer.top - container.top;
		let pointerPosition = placeholderOffset + containerOffset;

		let filterIndex = Math.round(pointerPosition / this.FILTER_HEIGHT);
		if (!filterArray[0].isOrgHierarchy) {
			// when no OH - the position math above works incorrectly and indexes are not right
			if (filterIndex > 0)
				filterIndex -= 1;
		} else if (filterIndex === 0) {
			// orgFilter is at index 0
			filterIndex = 1;
		}

		if (this.prevFilterIndex !== filterIndex && filterIndex >= 0) {
			filterArray.remove(filter);
			filterArray.insert(filterIndex, filter);
			this.prevFilterIndex = filterIndex;
		}

		if (pointer.top < container.top || pointer.top > container.bottom) {
			this.adjustScrollPosition(container, pointer);
		}
	}

	adjustScrollPosition = (container, pointer): void => {
		let direction = pointer.top < container.top ? -1 : 1;
		let scrollDelta = this.FILTER_HEIGHT * direction / 2;

		container.element.scrollTop(container.scroll.top + scrollDelta);
	}
}

app.service('dashboardFiltersDragDrop', downgradeInjectable(DashboardFiltersDragDropService));
