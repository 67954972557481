import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ColorFunctionBuilder } from '@cxstudio/reports/coloring/color-function-builder';
import { ColorPalettesHelper } from '@cxstudio/reports/coloring/color-palettes-helper';
import { MetricComparisonType, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { PreviewVisualProperties } from '@cxstudio/reports/entities/preview-visual-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils, { IWidgetUtilsAssetFields, IWidgetUtilsBaseFields, IWidgetUtilsCaseFields, IWidgetUtilsColorFields,
	IWidgetUtilsFormatterFields, IWidgetUtilsGroupingFields, IWidgetUtilsMetadataFields, IWidgetUtilsPreviewFields,
	IWidgetUtilsScorecardFields,
	IWidgetUtilsSizeFields, IWidgetUtilsTableFields, LegendFunctions, MetricLegendFunction } from '@cxstudio/reports/entities/widget-utils';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { TuningSuggestions } from '@cxstudio/reports/preview/tuning-suggestions';
import { TableColumnService } from '@cxstudio/reports/providers/cb/services/table-column-service.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { WidgetUtilsLazyResources } from '@app/modules/reports/utils/widget-utils-resources.service';
import * as _ from 'underscore';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';

export class WidgetUtilsBuilder {
	properties: WidgetProperties;
	visualProperties: VisualProperties;
	dashboardProperties: DashboardProperties;
	feedbackSettings: {
		feedbackSelection: IAnalyticFeedbackSelection;
		tuningSuggestions: TuningSuggestions;
		translationEnabled: boolean;
	};

	constructor(
		private readonly widget: Widget,
		private readonly lazyResources: WidgetUtilsLazyResources,
		private readonly widgetUtilsService: WidgetUtilsService,
		private readonly tableColumnService: TableColumnService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly $q: ng.IQService,
	) {
		this.properties = widget.properties;
		this.visualProperties = widget.visualProperties;
	}

	withDashboardProperties(dashboardProperties: DashboardProperties): WidgetUtilsBuilder {
		this.dashboardProperties = dashboardProperties;
		return this;
	}

	withPreviewSettings(feedbackSelection: IAnalyticFeedbackSelection, tuningSuggestions: TuningSuggestions,
			translationEnabled: boolean): WidgetUtilsBuilder {
		this.feedbackSettings = {
			feedbackSelection,
			tuningSuggestions,
			translationEnabled,
		};
		return this;
	}

	build(): ng.IPromise<WidgetUtils> {
		// IWidgetUtilsMetadataFields
		let promises = [
			this.getBaseFields(),
			this.getMetadataFields(),
			this.requiresProject(() => this.getAssetFields()),
			this.requiresProject(() => this.getGroupingFields(), this.getDefaultGroupingFields()),
			this.requiresProject(() => this.getFormatterFields(), this.getDefaultFormatterFields()),
			this.requiresProject(() => this.getSizeFields(), this.getDefaultSizeFields()),
			this.requiresProject(() => this.getColorFields(), this.getDefaultColorFields()),
			this.requiresProject(() => this.getTableFields()),
			this.requiresProject(() => this.getScorecardFields()),
			this.requiresProject(() => this.getCaseFields())
		] as Array<ng.IPromise<any>>;

		if (this.properties.widgetType === WidgetType.PREVIEW) {
			promises.push(this.requiresProject(() => this.getPreviewFields()));
		}

		let utils = {} as WidgetUtils;
		return this.$q.all(promises.map(promise => promise.then(result => _.extend(utils, result))))
			.then(() => utils);
	}

	requiresProject(promiseFn: () => ng.IPromise<any>, defaultObject?: any): ng.IPromise<any> {
		const project = this.reportAssetUtilsService.getWidgetPropertiesProject(this.properties);
		return WorkspaceTransitionUtils.isProjectSelected(project)
			|| InternalProjectTypes.isStudioAdminProject(project.projectId)
			? promiseFn()
			: this.$q.when(_.isUndefined(defaultObject) ? {} : defaultObject);
	}

	private getBaseFields(): ng.IPromise<IWidgetUtilsBaseFields> {
		return this.$q.when({
			widgetId: this.widget.id,
			containerId: this.widget.containerId,
			widgetType: this.properties.widgetType
		});
	}

	private getMetadataFields(): ng.IPromise<IWidgetUtilsMetadataFields> {
		let periods: {period_1_: string, period_2_: string};
		if (this.properties.widgetType === WidgetType.BAR || this.properties.widgetType === WidgetType.LINE) {
			if (this.properties.dateRangeP1) {
				periods = this.widgetUtilsService.getPeriods(this.properties, this.widget.containerId);
			}
		}
		return this.$q.when({
			periods,
			selectedAttributes: this.properties.selectedAttributes
		});
	}

	private getAssetFields(): ng.IPromise<IWidgetUtilsAssetFields> {
		return this.$q.all([this.lazyResources.attributes(), this.lazyResources.metrics()]).then(results => ({
			attributes: results[0],
			metrics: results[1]
		}));
	}

	private getPreviewFields(): ng.IPromise<IWidgetUtilsPreviewFields> {
		let tuningSuggestionModelsPromise: ng.IPromise<IReportModel[]> = this.$q.when([]);
		if ((this.visualProperties as PreviewVisualProperties).tuningSuggestionsEnabled) {
			tuningSuggestionModelsPromise = this.lazyResources.tuningSuggestionModels();
		}
		return this.$q.all([this.lazyResources.projectTimezone(), tuningSuggestionModelsPromise])
			.then(([projectTimezone, tuningSuggestionModels]) => ({
				projectTimezone,
				filteringFeedbackSelection: this.feedbackSettings?.feedbackSelection,
				tuningSuggestionModels,
				auditMode: this.feedbackSettings?.tuningSuggestions?.isTuningSuggestionsMode(),
				translationEnabled: this.feedbackSettings?.translationEnabled,
			}));
	}

	private getGroupingFields(): ng.IPromise<IWidgetUtilsGroupingFields> {
		return this.lazyResources.metrics().then(metrics => ({
			getGroupingFormatters: this.widgetUtilsService.getGroupingFormattersFunc(this.properties, metrics),
			getGroupingFormatter: this.widgetUtilsService.getGroupingFormatterFunc(this.properties, metrics),
			getGroupings: this.widgetUtilsService.getGroupingsFunc(this.properties),
		}));
	}

	private getFormatterFields(): ng.IPromise<IWidgetUtilsFormatterFields> {
		let dateFiltersPromise = this.hasCustomDateFilters() ? this.lazyResources.dateFilters() : this.$q.resolve([]);
		return this.$q.all([this.lazyResources.attributes(), this.lazyResources.metrics(), dateFiltersPromise]).then(results => ({
			getMetricNames: this.widgetUtilsService.getMetricNamesFunc(this.properties),
			dataFormatter: this.widgetUtilsService.getDataFormatter(this.properties, this.visualProperties, results[1]),
			secondaryDataFormatter: this.widgetUtilsService.getSecondaryDataFormatter(this.properties, this.visualProperties, results[1]),
			dataFormatters: this.widgetUtilsService.getScatterDataFormatters(this.properties, this.visualProperties, results[1]),
			titleFormatter: this.widgetUtilsService.getTitleFormatter(results[0], [...results[1], ...this.properties.selectedMetrics as any || []]),
			periodFormatter: this.widgetUtilsService.getPeriodFormatter(results[2]),
		}));
	}

	private hasCustomDateFilters(): boolean {
		let dateFiltersModes = [
			this.properties.dateRangeP1?.dateFilterMode,
			this.properties.dateRangeP2?.dateFilterMode
		];
		if (this.properties.widgetType === WidgetType.METRIC) {
			dateFiltersModes.pushAll(_.chain((this.properties as MetricWidgetProperties).comparisons)
				.filter(comparison => comparison.type === MetricComparisonType.TIME)
				.map(comparison => comparison.value.dateFilterMode)
				.value());
		}
		return !!_.find(dateFiltersModes, mode => mode && DateRangeUtils.isCustomDateFilterMode(mode));
	}

	private getSizeFields(): ng.IPromise<IWidgetUtilsSizeFields> {
		return this.lazyResources.metrics().then(metrics => ({
			sizeFunction: this.widgetUtilsService.getSizeFunction(this.visualProperties, metrics, 'size'),
			secondarySizeFunction: this.widgetUtilsService.getSizeFunction(this.visualProperties, metrics, 'secondarySize'),
			getRawSize: this.widgetUtilsService.getSizeFunction(this.visualProperties, metrics, 'size', true),
			getRawSecondarySize: this.widgetUtilsService.getSizeFunction(this.visualProperties, metrics, 'secondarySize', true),
		}));
	}

	private getColorFields(): ng.IPromise<IWidgetUtilsColorFields> {
		return this.$q.all([this.lazyResources.metrics(), this.lazyResources.palettes()]).then(results => {
			const studioPalettes = results[1].studioPalettes;
			const providerColors = results[1].providerColors;
			const builder = this.widgetUtilsService.getColorFunctionBuilder(
				this.properties.selectedAttributes,
				results[0],
				studioPalettes,
				providerColors)
			.withWrapper(this.widgetUtilsService.getColorFunctionWrapper(
				this.visualProperties,
				this.getUserAlignmentColor(),
				this.widget));

			const visual = this.visualProperties;

			const colorFunction = visual.color
				? builder.buildColorFunction(visual.color, visual.customColor) : undefined;
			const popColorFunction = visual.popColor
				? builder.buildColorFunction(visual.popColor, visual.customPopColor, colorFunction) : undefined;
			const secondaryColorFunction = visual.secondaryColor
				? builder.buildColorFunction(visual.secondaryColor, visual.secondaryCustomColor) : undefined;
			const secondaryPopColorFunction = visual.secondaryPopColor
				? builder.buildColorFunction(visual.secondaryPopColor, visual.secondaryCustomPopColor, secondaryColorFunction) : undefined;
			const pointColorFunction = visual.points
				? builder.buildColorFunction(visual.pointColor, visual.pointCustomColor) : undefined;
			const secondaryPointColorFunction = visual.secondaryPoints
				? builder.buildColorFunction(visual.secondaryPointColor, visual.secondaryPointCustomColor) : undefined;

			const palettesHelper = new ColorPalettesHelper(studioPalettes, providerColors);

			return {
				colorFunction,
				popColorFunction,
				secondaryColorFunction,
				secondaryPopColorFunction,
				pointColorFunction,
				secondaryPointColorFunction,
				palettesHelper,
				metricLegendFunctions: this.getMetricLegendFunctions(builder),
				legendFunctions: this.getLegendFunctions(builder),
				hasObjectColor: this.hasObjectBasedColor()
			};
		});
	}

	private getUserAlignmentColor(): string {
		let isUserAlignmentEnabled = !isFalse(this.dashboardProperties?.userPlacementEnabled);
		let isPersonalizationEnabled = !isFalse(this.dashboardProperties?.allowPersonalization);
		return isUserAlignmentEnabled && isPersonalizationEnabled && this.requiresHierarchyColoring()
			? this.dashboardProperties.userPlacementColor
			: null;
	}

	private requiresHierarchyColoring(): boolean {
		return AnalyticMetricTypes.isHierarchyModel(_.last(this.properties.selectedAttributes));
	}

	private getMetricLegendFunctions(builder: ColorFunctionBuilder): MetricLegendFunction[] {
		return _.chain(ReportConstants.colorFields)
			.filter(colorField => ColorUtilsHelper.isColorApplicable(this.visualProperties, colorField))
			.map(colorField => builder.buildMetricLegendArray(this.visualProperties[colorField]))
			.filter(func => !!func)
			.value();
	}

	private getLegendFunctions(builder: ColorFunctionBuilder): LegendFunctions {
		let colorFields = ReportConstants.primaryColorFields;
		if (this.visualProperties.visualization === VisualizationType.DUAL && this.visualProperties.secondaryChartType)
			colorFields = ReportConstants.colorFields;
		let functions = _.chain(colorFields)
			.filter(colorField => ColorUtilsHelper.isObjectBasedColor(this.visualProperties, colorField))
			.map(colorField => {
				let colorName = this.visualProperties[colorField];
				return [colorField, builder.buildCategoryFunction(colorName)];
			})
			.value();
		// empty legend mapping is not expected here
		return _.isEmpty(functions) ? undefined : _.object(functions);
	}

	private hasObjectBasedColor(): boolean {
		let anyObjectColor = _.chain(ReportConstants.colorFields)
			.filter(field => ColorUtilsHelper.isObjectBasedColor(this.visualProperties, field))
			.any()
			.value();
		return !!anyObjectColor || false;
	}

	private getTableFields(): ng.IPromise<IWidgetUtilsTableFields> {
		if (this.properties.widgetType === WidgetType.TABLE || this.properties.widgetType === WidgetType.SCORECARD) {
			return this.$q.all([this.lazyResources.attributes(), this.lazyResources.metrics()])
				.then(results => ({
					allColumns: this.tableColumnService.getTableColumns(this.visualProperties, this.properties,
						results[0], results[1], this.widget.containerId)
				}));
		}
		if (this.properties.widgetType === WidgetType.METRIC) {
			return this.$q.when({
				allColumns: this.tableColumnService.getMetricTableColumns(this.visualProperties, this.properties, this.widget.containerId)
			});
		}
		if (this.properties.widgetType === WidgetType.OBJECT_VIEWER) {
			return this.$q.all([this.lazyResources.attributes(),
				this.lazyResources.scorecards(),
				this.lazyResources.scorecardModels(),
				this.lazyResources.formats()])
			.then(results => ({
				allColumns: this.tableColumnService.getObjectViewerColumns(this.visualProperties, this.properties,
					results[0], results[1], results[2], results[3])
			}));
		}

		return this.$q.when({});
	}

	private getScorecardFields(): ng.IPromise<IWidgetUtilsScorecardFields> {
		if (this.properties.widgetType === WidgetType.OBJECT_VIEWER) {
			return this.$q.all([
				this.lazyResources.scorecards(),
				this.lazyResources.formats()])
			.then(results => ({
				scorecards: results[0],
				formats: results[1]
			}));
		}
		return this.$q.when({});
	}

	private getCaseFields(): ng.IPromise<IWidgetUtilsCaseFields> {
		if (this.visualProperties.caseVisualizations?.enabled
				&& !_.isEmpty(this.visualProperties.caseVisualizations?.selectedCases)) {
			return this.lazyResources.engagorCases().then(engagorCases => {
				return {
					engagorCases
				};
			}, () => ({}));
		} else return this.$q.when({});
	}

	private getDefaultFormatterFields(): any {
		return {
			getMetricNames: () => {},
			dataFormatter: () => {},
			secondaryDataFormatter: () => {},
			dataFormatters: () => {},
			titleFormatter: () => {},
			periodFormatter: () => {}
		};
	}

	private getDefaultColorFields(): any {
		return {
			colorFunction: () => {},
			popColorFunction: () => {},
			secondaryColorFunction: () => {},
			secondaryPopColorFunction: () => {},
			pointColorFunction: () => {},
			secondaryPointColorFunction: () => {},
			palettesHelper: () => {},
			metricLegendFunctions: () => {},
			legendFunctions: () => {},
			hasObjectColor: () => {}
		};
	}

	private getDefaultSizeFields(): any {
		return {
			sizeFunction: () => {},
			secondarySizeFunction: () => {},
			getRawSize: () => {},
			getRawSecondarySize: () => {},
		};
	}

	private getDefaultGroupingFields(): any {
		return {
			getGroupingFormatters: () => {},
			getGroupingFormatter: () => {},
			getGroupings: () => []
		};
	}
}
