import { Inject } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { ApplicationThemeService } from '@app/core/application-theme.service';

@Component({
	selector: 'dashboard-preview-image',
	template: `
<div class="dashboard-preview">
	<p class="multiline-truncate text-0_875rem" *ngIf="description && dashboard.description">
		{{dashboard.description}}
	</p>
	<a href="{{dashboardUrl}}"
		(click)="$event.preventDefault()"
		[attr.aria-label]="dashboard.description">
		<!-- empty image has width 1, works like indicator for css styles-->
		<img
			class="mb-8 dashboard-preview-tile"
			alt=""
			src="{{generatePreviewUrl()}}"
			onerror="this.setAttribute('empty', true)"
			onload="this.setAttribute('empty', this.naturalWidth === 1)">
		<div class="br-dashboard-empty-image bg-dashboard mb-8 dashboard-preview-tile" [i18n]="'dashboard.empty'"></div>
		<div class="_t-dashboard-name text-default text-wrap multiline-truncate"
			*ngIf="!description">
			<span class="q-icon mr-4" [ngClass]="getIconClass(dashboard.type)" title="{{dashboard.name}}"></span>{{dashboard.name}}
		</div>
		<div class="mt-16 labels"
			*ngIf="description && dashboard.labels && dashboard.labels.length">
			<div *ngFor="let label of dashboard.labels">
				<div class="label-tags no-wrap tag-template mb-4 ph-4">
					<div class="tag-template-icon">
						<span class="q-icon q-icon-globe"></span>
					</div>
					<div class="text-ellipsis" title="{{label.text}}">
						<span>{{label.text}}</span>
					</div>
				</div>
			</div>
		</div>
	</a>
</div>`
})

export class DashboardPreviewImageComponent implements OnInit {
	@Input() dashboard: Dashboard;
	@Input() description: boolean;
	@Input() tabsLimit: number;

	dashboardUrl: string;
	apiUrl: string;
	icons: {[type in DashboardType]?: string};

	constructor(
		@Inject('urlService') private urlService: UrlService,
		private applicationThemeService: ApplicationThemeService) {}

	ngOnInit(): void {
		this.apiUrl = this.urlService.getAPIUrl('rest/image');

		this.icons = IconsMap.getDashboardIconsMap();

		let dashboardId: number = this.dashboard.id;

		this.dashboardUrl = this.dashboard.type === DashboardType.BOOK
			? this.urlService.getBookUrl(dashboardId)
			: this.urlService.getDashboardUrl(dashboardId);
	}

	generatePreviewUrl = () => {
		let theme = this.applicationThemeService.isShowingDashboardDarkTheme() ? ApplicationTheme.DARK : ApplicationTheme.DEFAULT;
		return `${this.apiUrl}/${this.dashboard.id}/?v=${this.dashboard.previewImageUpdatedDate || this.dashboard.modifiedDate}&theme=${theme}`;
	}

	getIconClass = (type: DashboardType) => {
		return this.icons[type];
	}
}
