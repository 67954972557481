import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { Observable, Subject } from 'rxjs';


export interface Node {
	id: number;
	name: string;
	selected: boolean;
	sentences: number[];
	value: any;
	displayName?: string;
}

export interface PillsNavigation {
	node: Node;
	direction: NavigationDirection;
}

export class DocumentExplorerEvents {
	private readonly changeSettingsSubject: Subject<void> = new Subject<void>();
	private readonly highlightModelSubject: Subject<PillsNavigation> = new Subject<PillsNavigation>();
	private readonly highlightWorldAwarenessSubject: Subject<PillsNavigation> = new Subject<PillsNavigation>();
	private readonly clearHighlightingSubject: Subject<void> = new Subject<void>();
	private readonly redrawTopicsSubject: Subject<void> = new Subject<void>();

	constructor() {
	}

	changeSettings = (): void => {
		this.changeSettingsSubject.next();
	}

	getChangeSettingsObservable = (): Observable<void> => {
		return this.changeSettingsSubject.asObservable();
	}

	highlightModel = (node: Node, direction: NavigationDirection): void => {
		this.highlightModelSubject.next({ node, direction });
	}

	getHighlightModelObservable = (): Observable<PillsNavigation> => {
		return this.highlightModelSubject.asObservable();
	}

	highlightWorldAwareness = (node: Node, direction: NavigationDirection): void => {
		this.highlightWorldAwarenessSubject.next({ node, direction });
	}

	getHighlightWorldAwarenessObservable = (): Observable<PillsNavigation> => {
		return this.highlightWorldAwarenessSubject.asObservable();
	}

	clearHighlighting = (): void => {
		this.clearHighlightingSubject.next();
	}

	getClearHighlightingObservable = (): Observable<void> => {
		return this.clearHighlightingSubject.asObservable();
	}

	redrawTopics = (): void => {
		this.redrawTopicsSubject.next();
	}

	getRedrawTopicsObservable = (): Observable<void> => {
		return this.redrawTopicsSubject.asObservable();
	}
}
