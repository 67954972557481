import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class TableDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let groupings = this.properties.selectedAttributes?.map(attr => attr.displayName);
		let calculations = this.properties.selectedMetrics?.map(metric => metric.displayName);
		if (groupings?.length && calculations?.length) {
			let total = this.utils.getTotal(this.visual, data);
			return this.locale.getString('widgetDescription.tableDescription', {
				groupings: groupings.join(', '),
				calculations: calculations.join(', '),
				total
			});
		}
		return '';	
	}
	
}
