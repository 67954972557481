import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SidebarNavDowngradeComponent } from './sidebar-nav-downgrade.component';
import { SidebarService } from './sidebar.service';
import { ToastComponent, ToastModule, HeaderComponent } from '@clarabridge/unified-angular-components';
import { SidebarEditorComponent } from './sidebar-editor/sidebar-editor.component';
import { SidebarPropertiesComponent } from './sidebar-properties/sidebar-properties.component';
import { SidebarSectionComponent } from './sidebar-section/sidebar-section.component';

@NgModule({
	imports: [
		SharedModule,
		ToastModule
	],
	exports: [
		SidebarNavDowngradeComponent,
		SidebarEditorComponent,
		SidebarSectionComponent,
	],
	declarations: [
		SidebarNavDowngradeComponent,
		SidebarEditorComponent,
		SidebarPropertiesComponent,
		SidebarSectionComponent,
	],
	providers: [
		SidebarService,
	],
})

export class LayoutModule {}
