import TopicSelectionNode from '@cxstudio/reports/providers/cb/definitions/topic-selection-node.interface';

export class TopicDrillUtils {
	static findInHierarchy(hierarchy: TopicSelectionNode[], field: string, value: any): TopicSelectionNode {
		for (let item of hierarchy) {
			if (item[field] === value) {
				return item;
			} else if (item.children && item.children.length > 0) {
				let childMatch = TopicDrillUtils.findInHierarchy(item.children, field, value);
				if (childMatch) return childMatch;
			}
		}
	}

	static getCheckNodeObject(node: TopicSelectionNode): TopicSelectionNode {
		return {
			id: node.id,
			level: node.level,
			children: (node.children && node.children.length) as any
		};
	}

	static checkNode(tree: TopicSelectionNode[], node: TopicSelectionNode, checked: TopicSelectionNode[]): void {
		checked.push(TopicDrillUtils.getCheckNodeObject(node));
		TopicDrillUtils.checkParent(tree, node, checked);
	}

	static checkParent(tree: TopicSelectionNode[], node: TopicSelectionNode, checked: TopicSelectionNode[]): void {
		if (node.parentId) {
			let parentNode = TopicDrillUtils.findInHierarchy(tree, 'id', node.parentId);
			if (parentNode && parentNode.level > 0 && !_.findWhere(checked, {id: parentNode.id})) {
				TopicDrillUtils.checkNode(tree, parentNode, checked);
			}
		}
	}
}