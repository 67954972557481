import { ChartPlotUtils } from '@app/modules/plot-lines/plot-lines/chart-plot-utils.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { HighchartsScatterUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-scatter/highcharts-scatter-utils.service';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { HighchartsAnalyticUtils, StudioHighchartsSeries } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import { HighchartsSeriesPropertiesService } from '@cxstudio/reports/utils/highchart/highcharts-serie-properties.service';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { IChartDefinitionFactory } from './chart-definition-factory.interface';
import { PlotSeriesDataLabelsOptions, SeriesOptionsType, XAxisOptions } from 'highcharts';

app.factory('HighchartsBubbleAnalyticDefinition', (
	highchartsScatterUtils: HighchartsScatterUtilsService, highchartsAnalyticUtils: HighchartsAnalyticUtils,
	highchartsSerieProperties: HighchartsSeriesPropertiesService, highchartsUtils: HighchartsUtilsService,
	chartPlotUtils: ChartPlotUtils, locale: ILocale, metricConstants: MetricConstants,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils) => {

	return class implements IChartDefinitionFactory {
		options;
		utils;
		constants;

		constructor(private reportDefinition, private element) {
			this.options = reportDefinition.options;
			this.utils = reportDefinition.utils;
			this.constants = metricConstants.get();
		}

		private initializeData(): void {
			if (!this.options.primaryGroup) {
				this.reportDefinition.hierarchyData = [];
				return;
			}
			let data = ObjectUtils.copy(this.reportDefinition.dataObject.data);
			let seriesNameFormatters = this.utils.getGroupingFormatters();
			let groups = GroupIdentifierHelper.getGroupings(this.utils.selectedAttributes);
			let hierarchyData = AnalyticDataUtils.getHierarchyData(data, groups, seriesNameFormatters);
			this.reportDefinition.hierarchyData = hierarchyData;
		}

		getChartOptions = (): Highcharts.Options => {
			let factoryScope = this; // tslint:disable-line:no-invalid-this no-this-assignment

			factoryScope.initializeData();
			let series = factoryScope.getSeries();
			let range = factoryScope.getRange(series);

			let opts = highchartsScatterUtils.getBaseOptions(factoryScope.reportDefinition, factoryScope.element, range) as Highcharts.Options;

			opts.series = series as unknown as SeriesOptionsType[];

			// tslint:disable:no-invalid-this
			opts.tooltip.formatter = function(): string {
				let y = factoryScope.utils.dataFormatters[0](this.point.y);
				let x = factoryScope.utils.dataFormatters[1](this.point.x);

				let tooltip = `<span style="color:{series.color}">\u25CF </span><span style="font-size:10px">${highchartsUtils.cleanStringForDisplay(this.series.name)}</span>`
					+ `<br/><span>${factoryScope.utils.titleFormatter(factoryScope.options.xAxis)}: ${x}</span>`
					+ `<br/><span>${factoryScope.utils.titleFormatter(factoryScope.options.yAxis)}: ${y}</span>`;

				let groupId = (this.point as any).object._group.identifier;
				let metadata = (this.point as any).object[`_metadata_${groupId}`];
				if (metadata?.impactRank) {
					let strength = (this.point as any).object[factoryScope.constants.STRENGTH.name];

					let rankName = strength >= 0
						? locale.getString('drivers.impactRank')
						: locale.getString('drivers.inverseImpactRank');

					tooltip += `<br/><span>${rankName}: ${metadata.impactRank}</span>`;
				}

				return tooltip;
			};

			(opts.plotOptions.series.dataLabels as PlotSeriesDataLabelsOptions).formatter = function(): string {
				let value = this.point && (this.point as any).object && (this.point as any).object[factoryScope.options.size];
				if (value !== undefined && factoryScope.utils.dataFormatters[2])
					value = factoryScope.utils.dataFormatters[2](value);
				return value;
			};

			opts.plotOptions.series.events.mouseOver = null;
			opts.plotOptions.series.point = $.extend(opts.plotOptions.series.point, {
				events: {
					mouseOver(): void {
						highchartsUtils.selectPoint(factoryScope.reportDefinition, this);
					},
					mouseOut(): void {
						highchartsUtils.cancelPointSelection(factoryScope.reportDefinition);
					}
				}
			});

			(opts.xAxis as XAxisOptions).labels.formatter = function(): string {
				return factoryScope.utils.dataFormatters[1](this.value);
			};

			opts.yAxis[0].labels.formatter = function(): string {
				return factoryScope.utils.dataFormatters[0](this.value);
			};
			// tslint:enable:no-invalid-this

			chartPlotUtils.processPlotLines(factoryScope.reportDefinition.options, factoryScope.reportDefinition.utils, opts);
			chartPlotUtils.processPlotBands(factoryScope.reportDefinition.options, factoryScope.reportDefinition.utils, opts, factoryScope.element);

			highchartsUtils.cleanArrayProperties(opts.series, 'name');
			highchartsUtils.cleanAxisCategories(opts.xAxis);
			highchartsUtils.cleanAxisCategories(opts.yAxis);
			highchartsAccessibilityUtils.applyBorder(opts);

			return opts;
		}

		private getSeries(): StudioHighchartsSeries[] {
			let factoryScope = this; // tslint:disable-line:no-invalid-this no-this-assignment

			let axisMap = {
				y: factoryScope.options.yAxis,
				x: factoryScope.options.xAxis,
				z: factoryScope.utils.sizeFunction
			};
			let processors = [
				highchartsAnalyticUtils.dataRootTransformer(),
				highchartsAnalyticUtils.dataStackTransformer(),
				highchartsAnalyticUtils.seriesValuesProcessor(axisMap, true)
			];
			let series = highchartsAnalyticUtils.getSeries(factoryScope.reportDefinition.hierarchyData, processors);
			_.each(series, (serie) => {
				let hasValues = serie.data.length && !_.find(serie.data, (point) => {
					return point.x === null || point.y === null || point.z === null;
				});
				serie.showInLegend = hasValues;
			});

			highchartsAnalyticUtils.applyAdditionalProperties(series,
				[highchartsSerieProperties.applyChartType(factoryScope.options.visualization),
					highchartsSerieProperties.applyColor(factoryScope.utils.colorFunction),
					highchartsSerieProperties.applyBubbleMarker(),
					highchartsSerieProperties.applyId()]);

			let filtered = _.filter(series, 'showInLegend'); // one invisible serie may broke everything

			if (factoryScope.reportDefinition.utils.legendFunctions && factoryScope.reportDefinition.utils.legendFunctions.color) {
				let mapping = {};
				filtered.forEach((serie) => {
					let point = ObjectUtils.copy(serie);
					point.object = serie.data[0].object;
					serie.category = ObjectUtils.copy(factoryScope.reportDefinition.utils.legendFunctions.color(serie.data[0].object)) || {};
					serie.category.name = ColorUtilsHelper.checkForRecolor(point as IDataPoint, factoryScope.reportDefinition.options,
						{ displayName: serie.category.name }, factoryScope.reportDefinition.utils.getGroupingFormatters());
					serie.category.id = ColorUtilsHelper.checkForRecolor(point as IDataPoint, factoryScope.reportDefinition.options,
						{ displayName: serie.category.id }, factoryScope.reportDefinition.utils.getGroupingFormatters());

					if (mapping[serie.category.id] !== undefined) {
						serie.linkedTo = mapping[serie.category.id];
						serie.showInLegend = false;
					} else {
						mapping[serie.category.id] = serie.id;
					}
				});

				filtered.sort((a, b) => a.category.order - b.category.order);
			}


			if (factoryScope.utils.colorFunction) {
				_.each(filtered, (filteredSeries, i) => {
					filteredSeries.color = factoryScope.utils.colorFunction(filteredSeries.data[0].object, i);
				});
			}

			return filtered.length ?
				filtered :
				series;
		}

		private getRange(series): {min: number, max: number} {
			let min = _.min(this.getPointValues(series, 'z'));
			let max = _.max(this.getPointValues(series, 'z'));
			return { min, max };
		}

		private getPointValues(series, field): any[] {
			return _.chain(series)
				.map(serie => serie.data && serie.data[0])
				.filter(point => point?.[field] !== undefined && point[field] !== null)
				.map(field)
				.value();
		}

	};
});
