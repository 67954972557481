import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { IChartDefinitionFactory } from './chart-definition-factory.interface';
import { HighchartsTreeReportDataBuilder } from './report-data-builders/highcharts-tree-report-data-builder.service';
import { CSSObject } from 'highcharts';

app.factory('HighchartsTreeAnalyticDefinition', (highchartsUtils: HighchartsUtilsService,
	highchartsTreeReportDataBuilder: HighchartsTreeReportDataBuilder, locale: ILocale, metricConstants: MetricConstants,
	applicationThemeService: ApplicationThemeService, chartAccessibilityService: ChartAccessibilityService,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils) => {

	return class implements IChartDefinitionFactory {
		constructor(
			private readonly reportDefinition,
			private readonly element
		) {}

		getChartOptions = () => {
			let factoryScope = this; // tslint:disable-line:no-this-assignment

			let metric = factoryScope.reportDefinition.options.size;
			if (factoryScope.reportDefinition.options.attributeSelections
				&& factoryScope.reportDefinition.options.attributeSelections.size) {
				metric = factoryScope.reportDefinition.options.attributeSelections.size.name;
			}

			let localizedSentiment = locale.getString('widget.sentiment');
			let localizedVolume = locale.getString('widget.volume');
			let formatter = factoryScope.reportDefinition.utils.dataFormatter;
			let selectedAttributes = factoryScope.reportDefinition.utils.selectedAttributes;

			let darkMode = applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER);
			let patternFilling = chartAccessibilityService.isPatternFillEnabled();

			let backgroundColor = darkMode ?
				ColorConstants.GRAY_900 :
				ColorConstants.DARK_MODE_GRAY_900;

			let chart = $.extend(highchartsUtils.getDefaultConfig(), {
				chart: {
					type: 'treemap',
					spacingBottom: highchartsUtils.getSpacingBottom(factoryScope.reportDefinition),
					renderTo: factoryScope.element[0],
					plotBackgroundColor: backgroundColor
				},
				legend: {
					enabled: factoryScope.reportDefinition.options.showLegend,
					symbolRadius: 0,
					itemStyle: {
						cursor: 'default'
					}
				},
				plotOptions: {
					series: {
						events: {
							afterAnimate(): void {
								// tslint:disable-next-line:no-invalid-this
								highchartsUtils.handleRenderedEvent(this, factoryScope.reportDefinition);
							}
						}
					}
				},
				series: [ {
					showHeaders: true, // our custom prop
					traverseUpButton: {
						text: locale.getString('widget.backButton'),
						position: {
							align: 'right',
							x: -10,
							y: 20
						},
						theme: {
							fill: 'white',
							'stroke-width': 1,
							stroke: '#babbc5',
							r: 5,
							states: {
								hover: {
									fill: '#bada55'
								}
							}
						}

					},
					type: 'treemap',
					layoutAlgorithm: factoryScope.reportDefinition.options.layout || 'squarified',
					layoutStartingDirection: factoryScope.reportDefinition.options.layoutOrientation || 'vertical',
					allowTraversingTree: 'custom',
					animationLimit: 1000,
					opacity: 0,
					interactByLeaf: true,
					tooltip: {
						pointFormatter(): string {
							// tslint:disable:no-invalid-this
							let tooltip = `<strong>${factoryScope.getDrillPath(this.node, this.series.points)}</strong><br/>`;
							tooltip += factoryScope.formatBoxSizeMetric(this, metric, factoryScope.reportDefinition, formatter);
							if (metric !== 'sentiment' && this.object.sentiment) {
								tooltip += `<br/>${localizedSentiment}: ${Number(this.object.sentiment).toFixed(2)}`;
							}
							if (metric !== 'volume' && this.object.volume) {
								tooltip += `<br/>${localizedVolume}: ${this.object.volume}`;
							}
							return tooltip;
							// tslint:enable:no-invalid-this
						}
					},
					dataLabels: {
						enabled: true,
						allowOverlap: true,
						padding: patternFilling ? 8 : 0,
						backgroundColor: patternFilling ? backgroundColor : null,
						useHTML: false,
						overflow: 'none',
						style: {
							fontWeight: 'normal',
							textOverflow: 'ellipsis',
							textShadow: 'none',
							textOutline: 'none'
						} as CSSObject,
						formatter(): string {
							// tslint:disable:no-invalid-this
							let point = this.point;
							let displayValue = !_.isUndefined(point.rawValue) ? point.rawValue : point.value;
							let name = factoryScope.reportDefinition.options.showLabels && point.name
								? point.name + ': ' + formatter(displayValue) : point.name;

							// remove span tags (else they are converted to <tspan> tags and result in a double ellipsis if the whole text does not fit)
							name = name.replace(/<\/?span[^>]*\>/g, '');
							return point.node.level >= factoryScope.getCurrentLevel(this.series)
								? name : '';
							// tslint:enable:no-invalid-this
						}
					},
					// its false due to highcharts bug https://github.com/highcharts/highcharts/issues/7233
					levelIsConstant: false,
					levels: factoryScope.getLevels(selectedAttributes.length),
					point: {
						events: {
							mouseOver(): void {
								let point = this; // tslint:disable-line:no-this-assignment no-invalid-this
								highchartsUtils.selectPoint(factoryScope.reportDefinition, point);

								if (point.node.level < selectedAttributes.length) {
									factoryScope.reportDefinition.selectedPoint.drillAction = factoryScope.drillDownFunction(point);
								}

								if (patternFilling && point.color.pattern) {
									if (darkMode) {
										point.color.pattern.originalColor = point.color.pattern.color;
										point.color.pattern.color = ColorUtilsHelper.darken(point.color.pattern.color);
									} else {
										point.color.pattern.opacity = 0.5;
									}
								}
							},
							mouseOut(): void {
								let point = this; // tslint:disable-line:no-this-assignment no-invalid-this
								highchartsUtils.cancelPointSelection(factoryScope.reportDefinition);

								if (patternFilling && point.color.pattern) {
									if (darkMode) {
										point.color.pattern.color = point.color.pattern.originalColor;
									} else {
										point.color.pattern.opacity = 1.0;
									}
								}
							}
						}
					},
					data: highchartsTreeReportDataBuilder.getAnalyticTreeData(factoryScope.reportDefinition)
				} ]
			});

			if (factoryScope.reportDefinition.options.showLegend) {
				factoryScope.addLegendSeries(chart.series, factoryScope.reportDefinition);
			}

			if (patternFilling && !darkMode) {
				chart.series[0].dataLabels.style.color = ColorConstants.CHARCOAL;
			}

			highchartsUtils.cleanArrayProperties(chart.series, 'name');
			highchartsUtils.cleanDataNames(chart);
			highchartsAccessibilityUtils.applyBorder(chart);
			return chart;
		}

		private formatBoxSizeMetric(node, metric: string, reportDefinition, formatter: (...args) => string): string {
			let factoryScope = this; // tslint:disable-line:no-this-assignment

			if (reportDefinition && factoryScope.reportDefinition?.options?.attributeSelections?.size) {
				let sizeMetric = factoryScope.reportDefinition.options.attributeSelections.size;

				// if it's constant size, don't include the size metric in the tooltip
				if (_.isMatch(sizeMetric, metricConstants.get().CONSTANT_SIZE)) return '';

				if (sizeMetric.name === metric) {
					return `${sizeMetric.displayName} : ${formatter(node.rawValue || node.value)}`;
				}
			}

			return '';
		}

		private getDrillPath(node, seriesPoints): string {
			let factoryScope = this; // tslint:disable-line:no-this-assignment

			if (!node.parent || node.parent === '') {
				return highchartsUtils.cleanStringForDisplay(node.name);
			} else {
				return factoryScope.getDrillPath(_.find(seriesPoints, (point) => {
					return point.id === node.parent ;
				} ), seriesPoints) + ' > ' + highchartsUtils.cleanStringForDisplay(node.name);
			}
		}

		private drillDownFunction(point): () => void {
			let series = point.series;
			let drillId = point.id;
			return () => {
				if (drillId) {
					point.setState(''); // Remove hover
					series.setRootNode(drillId);
				}
			};
		}

		private getLevels(count): any[] {
			let levels = [];
			for (let i = 0; i < count - 1; i++) {
				levels.push({
					level: i + 1,
					dataLabels: {
						enabled: true,
						align: 'left',
						verticalAlign: 'top',
						style: {
							fontSize: '12px',
							fontWeight: 'bold'
						}
					},
					borderWidth: 2
				});
			}
			levels.push({
				level: count,
				dataLabels: {
					color: null,
					enabled: true
				}
			});
			return levels;
		}

		private getCurrentLevel(series): number {
			let rootNode = series.rootNode;
			let node = series.nodeMap[rootNode];
			return node.level;
		}

		private addLegendSeries(series, reportDefinition): void {
			let factoryScope = this; // tslint:disable-line:no-this-assignment

			let data = series[0].data;
			let isObjectBased = factoryScope.isObjectBasedLegend(reportDefinition);
			let uniqueField = isObjectBased ? 'color' : 'name';
			let legendFunction = factoryScope.reportDefinition.utils?.legendFunctions?.color;

			let legends = _.chain(data).filter('leaf').uniq(false, uniqueField).value();
			let legendSeries = _.chain(legends).map((legendItem) => {
				let nameItem = isObjectBased ?
					legendFunction(legendItem.object) :
					legendItem;

				return {
					name: ColorUtilsHelper.checkForRecolor(legendItem, factoryScope.reportDefinition.options,
						{ displayName: nameItem.name },
						factoryScope.reportDefinition.utils.getGroupingFormatters()),
					color: legendItem.color,
					data: [],
					sortValue: isObjectBased ? nameItem.order : nameItem.name,
					showInLegend: true,
					events: {
						legendItemClick: () => false // disable default action
					}
				};
			}).sortBy('sortValue').value();
			series.pushAll(legendSeries);
		}

		private isObjectBasedLegend(reportDefinition): boolean {
			let factoryScope = this; // tslint:disable-line:no-this-assignment

			return ColorUtilsHelper.isObjectBasedColor(factoryScope.reportDefinition.options, ColorTypes.PRIMARY);
		}
	};
});
