import { SecurityContext } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { AssigneeType } from '@cxstudio/alert-subscription-templates/types/assignee-type';
import { EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { CxLocaleService } from '@app/core/cx-locale.service';

interface AssigneeOption {
	displayName: string;
	css: string;
	item: Assignee;
}

@Component({
	selector: 'alert-assignee-selector',
	templateUrl: './alert-assignee-selector.component.html'
})
export class AlertAssigneeSelectorComponent {
	options: AssigneeOption[];
	@Input() matchFunction: () => Assignee;
	@Input() assignee: Assignee;
	@Input() nonclickable: boolean;
	@Input() withHierarchyAssignee: boolean;

	@Output() onSelectAssignee = new EventEmitter<{assignee: Assignee}>();
	@Output() onClearAssignee = new EventEmitter<void>();

	constructor(
		private sanitizer: DomSanitizer,
		private locale: CxLocaleService
	) {}

	@Input()
	set assignees(assignees: EngagorAssignees) {
		if (assignees) {
			let userOptions: AssigneeOption[] = assignees.users
				.map(user => ({
					displayName: user.userEmail,
					css: 'user',
					item: {
						id: user.userId,
						userEmail: user.userEmail,
						type: AssigneeType.USER
					} })
				);

			let groupOptions: AssigneeOption[] = assignees.groups
				.map(group => ({
					displayName: group.name,
					css: 'group',
					item: {
						id: group.id,
						type: AssigneeType.GROUP
					}
				}));

			let hierarchies: AssigneeOption[] = this.withHierarchyAssignee ? assignees.hierarchies
				.map(hierarchy => ({
					displayName: this.locale.getString('common.organization'),
					css: 'hierarchy',
					item: {
						id: hierarchy.id,
						type: AssigneeType.HIERARCHY
					}
				})) : [];

			this.options = hierarchies.concat(groupOptions, userOptions);
		} else {
			this.options = [];
		}
	}

	matchAssignee = (): AssigneeOption => {
		let assignee = this.matchFunction ? this.matchFunction() : this.assignee;
		return this.findOptionByAssignee(assignee);
	}

	private findOptionByAssignee = (assignee: Assignee): AssigneeOption => {
		return assignee && this.options && this.options
			.filter(option => option.item.id === assignee.id && option.item.type === assignee.type)
			[0];
	}

	compileNodeTemplate = (option: AssigneeOption): SafeHtml => {
		let iconHtml = '';
		switch (option.item.type) {
			case AssigneeType.USER:
				iconHtml = '<i class="q-icon-user"></i>';
				break;
			case AssigneeType.GROUP:
				iconHtml = '<i class="q-icon-users"></i>';
				break;
			case AssigneeType.HIERARCHY:
				iconHtml = '<i class="q-icon-flow"></i>';
				break;
			default:
				break;
		}

		let displayNameHtml = option.displayName;

		return this.sanitizer.bypassSecurityTrustHtml(`${iconHtml} ${this.sanitizer.sanitize(SecurityContext.HTML, displayNameHtml)}`);
	}

}

app.directive('alertAssigneeSelector', downgradeComponent({component: AlertAssigneeSelectorComponent}) as angular.IDirectiveFactory);
