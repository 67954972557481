import { AfterContentInit, Inject, Component, EventEmitter, Output, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { URLListItem } from '@clarabridge/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { Observable } from 'rxjs';
import { SidebarService } from './sidebar.service';

declare let app: angular.IModule;

// wrapper so that cb-sidebar-nav can used in angularjs
@Component({
	selector: 'sidebar-nav-downgrade',
	template: `
<cb-sidebar-nav
	*ngIf="isOpen"
	[urlLinks]="getLinks$"
	[isOpen]="isOpen"
	[keyboardFocus]="openedWithKeyboard"
	(closeFn)="sidebarService.closeSidebarViaKeyboard($event)">
</cb-sidebar-nav>
`})

export class SidebarNavDowngradeComponent implements AfterContentInit {
	@Input() isOpen: boolean;
	@Input() openedWithKeyboard: boolean;

	keyboardFocus: boolean = false;

	getLinks$: Observable<URLListItem[]>;
	readonly SIDEBAR_TOGGLE_ID = 'sidebar-toggle-wrap';

	constructor(
		@Inject('security') private security: Security,
		public sidebarService: SidebarService) {
	} 

	ngAfterContentInit(): void {
		this.getLinks$ = this.security.isOAuthAuthentication() ?
			this.sidebarService.getOAuthLinks() :
			this.sidebarService.getNonOAuthLinks();

		this.getLinks$.subscribe((val) => {
			if (val?.length) {
				// render delay is not a good longterm solution. need to think about this more...

				let renderDelay = document.getElementById(this.SIDEBAR_TOGGLE_ID) ? 0 : 1000;
				setTimeout(() => {
					// unhide the sidebar toggle hamburger
					let toggle = document.getElementById(this.SIDEBAR_TOGGLE_ID);
					if (toggle)
						toggle.style.display = 'inline-block';
				}, renderDelay);
			}
		});
	}	
}

app.directive('sidebarNavDowngrade', downgradeComponent({component: SidebarNavDowngradeComponent}) as angular.IDirectiveFactory);