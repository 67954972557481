<div class="color-grade-container" [ngClass]="{'disabled': isDisabled}" style="min-height: 60px;">
	<div class="p-relative" style="margin: 50px; margin-top: 0;">

		<!-- COLOR BAR -->
		<div class="progress p-relative no-round d-flex">
			<color-dropdown *ngFor="let color of grades.colorPalette; index as $index"
				class="h-100-percent d-flex"
				[disabled]="!canChangeColor"
				[(ngModel)]="grades.colorPalette[$index]"
				(ngModelChange)="onChangeColor()"
				[ngStyle]="{'width': (calculatePercent($index)) + '%'}">
				<div class="flex-fill progress-bar"
					[style.background]="color">
				</div>
			</color-dropdown>
		</div>

		<!-- MIN VALUE INPUT BOX ON THE LEFT -->
		<div class="p-absolute gradient-endpoint left-endpoint">

			<!-- ARROW -->
			<div class="p-absolute cb-input-arrow" [style.border-right-color]="grades.colorPalette[0]"></div>

			<input type="text" class="text-center gradient-input border-h-0 border-radius-0 ph-8" name="minInput"
				*ngIf="displayLimits()"
				[disabled]="!options.areLimitsEditable"
				[(ngModel)]="min"
				[ngModelOptions]="{ updateOn: 'blur', debounce:0 }"
				(focus)="onFocus()"
				(focusout)="onChangeMinMax(true)"
				attr.aria-label="{{'common.min'|i18n}}"
				[limitedDecimal]="options.decimals">
		</div>

		<!-- MAX VALUE INPUT BOX ON THE RIGHT -->
		<div class="p-absolute gradient-endpoint right-endpoint">

			<!-- ARROW -->
			<div class="p-absolute cb-input-arrow" [style.border-left-color]="grades.colorPalette[grades.colorPalette.length-1]"></div>

			<input type="text" class="text-center gradient-input border-h-0 border-radius-0 ph-8" name="maxInput"
				*ngIf="displayLimits()"
				[disabled]="!options.areLimitsEditable"
				[(ngModel)]="max"
				[ngModelOptions]="{ updateOn: 'blur', debounce:0 }"
				(focus)="onFocus()"
				(focusout)="onChangeMinMax()"
				attr.aria-label="{{'common.max'|i18n}}"
				[limitedDecimal]="options.decimals">
		</div>

		<!-- GRADE HANDLES WITH INPUTS -->
		<div class="p-absolute grade-handler-wrap"
			*ngFor="let threshold of grades.thresholds; index as $index"
			[ngClass]="getDirectionClass(grades.thresholdRules[$index])"
			[ngStyle]="{'left': getHandlePositionStyle($index)}"
			[style.background-color]="getIndicatorColor($index, grades.thresholdRules[$index])">

				<!-- ARROW -->
				<div class="p-absolute cb-input-arrow" [style.border-bottom-color]="getIndicatorColor($index, grades.thresholdRules[$index])"></div>

				<!-- INPUT -->
				<div class="grade-handler-input">
					<input type="text" name="gradeHandler"
						[style.border-color]="getIndicatorColor($index, grades.thresholdRules[$index])"
						[disabled]="!options.areGradesEditable"
						[(ngModel)]="grades.thresholds[$index]"
						[ngModelOptions]="{ updateOn: 'blur', debounce:0 }"
						(ngModelChange)= "onChange($index)"
						(focus)="onFocus()"
						attr.aria-label="{{'metrics.threshold'|i18n}}"
						[limitedDecimal]="options.decimals">
				</div>

				<!-- DIVIDERS -->
				<div *ngIf="grades.dividers && grades.dividers[$index]" class="grade-divider"></div>
		</div>
	</div>

	<!-- COLOR GRADE LANES -->
	<color-grade-lanes
		[validateOnInit]="validateLanesOnInit"
		[grades]="grades"
		[min]="min"
		[max]="max"
		[options]="options"
		[format]="format"
		[gradesUpdate]="gradesUpdateSubject.asObservable()"
		(laneChange)="onLaneChange($event.index, $event.lane)"
		(addLane)="addColorGrade($event)"
		(removeLane)="deleteColorGrade($event)"
		(validityChange)="validityChangeHandler($event)"
		(colorChange)="onChangeColor()"
	></color-grade-lanes>
</div>
