import { Input } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'widget-title',
	template: `<h3 id="widget-header-{{widgetId}}" class="br-widget-title mb-0 pl-8 text-ellipsis font-light" title="{{ title }}">{{ title }}</h3>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTitleComponent {
	@Input() title: string;
	@Input() widgetId: string;
}

app.directive('widgetTitle', downgradeComponent({component: WidgetTitleComponent}) as angular.IDirectiveFactory);
