import { ErrorExtractor } from '@cxstudio/interceptors/error-extractor';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { HttpResponse } from './http-response';

export class HttpHandlers {

	static success = (response: HttpResponse<any>): any  => {
		return response.data;
	}

	constructor(
		private $q: angular.IQService,
		private errorExtractor: ErrorExtractor,
		private cachedHttpService: CachedHttpService
	) {}

	success = (response: HttpResponse<any>): any => HttpHandlers.success(response);

	error = (response: HttpResponse<any>): angular.IPromise<any> => {
		if (response.headers) {
			let error = this.errorExtractor.extract(response.headers());
			return this.$q.reject(error || response.data);
		}
		return this.$q.reject(response.data);
	}

	invalidateCacheHandler = (cacheName: Caches): <T>(response: T) => T => {
		return <T> (response: T): T => {
			this.cachedHttpService.cache(cacheName).invalidate();
			return response;
		};
	}
}

app.service('httpHandlers', HttpHandlers);
