export enum WarningSeverity {
	LOW = 'low',
	HIGH = 'high'
}
export enum WarningType {
	VOLUME = 'volumeWarning',
	TARGET = 'outcomeWarning',
	ATTRIBUTES = 'attributesWarning'
}

export class DatasetWarning {
	severity: WarningSeverity;
	type: WarningType;
}