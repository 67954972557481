import * as _ from 'underscore';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';


export class CalculationSeriesSelectorController implements ng.IController {

	selectedItems: any[];
	hierarchyList: any[];
	filteredItems: any[];
	notRecommendedItems: any[];
	project: WidgetProject;
	onConfigure: (param: {node: any}) => void;
	onUpdate: (param: {selectedItems: any[]}) => void;
	max: number;
	isReorderable: () => boolean;
	onDragDrop: () => void;

	disabledItems: any[];

	constructor(
		private $scope: ISimpleScope,
		private reportSettingsService: ReportSettingsService
	) {}

	$onInit(): void {
		this.disabledItems = this.selectedItems.concat(this.filteredItems);
		this.$scope.$watchCollection(() => this.filteredItems, this.updateDisabledItems);
		this.$scope.$watchCollection(() => this.selectedItems, this.updateDisabledItems);
	}

	showPlus = (index: number) => {
		return (this.selectedItems.length < this.max)
			&& (index === this.selectedItems.length - 1)
			&& (this.selectedItems[index]);
	}

	removeCalculation = (index: number) => {
		this.onUpdate({selectedItems: this.selectedItems.filter((item, i) => {
			return i !== index;
		})});
	}

	addCalculation = () => {
		this.onUpdate({selectedItems: this.selectedItems.concat(undefined)});
	}

	configure = (item) => {
		return this.onConfigure({node: item});
	}

	onNodeClick = (node, index: number) => {
		this.reportSettingsService.getCalculationSettings(this.project, node).then((settings) => {
			let item = _.extend({}, node, settings);
			this.selectedItems[index] = item;
			this.onUpdate({selectedItems: this.selectedItems});
		});
	}

	private updateDisabledItems = (oldValue, newValue) => {
		if (oldValue === newValue) return;

		this.disabledItems = this.selectedItems.concat(this.filteredItems);
	}

}

app.component('calculationSeriesSelector', {
	controller: CalculationSeriesSelectorController,
	templateUrl: 'partials/custom/calculation-series-selector-component.html',
	bindings: {
		selectedItems: '=',
		hierarchyList: '=',
		filteredItems: '=',
		notRecommendedItems: '<?',
		project: '=',
		onConfigure: '&',
		onUpdate: '&',
		max: '@',
		isReorderable: '&',
		onDragDrop: '&'
	},
});
