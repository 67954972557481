import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Unit } from '@app/modules/units/unit';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { PromiseUtils } from '@app/util/promise-utils';
import { AlertType } from '@cxstudio/alerts/entities/alert-type';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import * as _ from 'underscore';
import { AlertingApiService } from './api/alerting-api.service';
import { AlertEvent } from '@app/core/cx-event.enum';

interface AlertTab {
	id: string;
	type: AlertType;
	name: string;
}

export class AlertsPageController implements ng.IController {

	props: IProjectSelection;
	renewMetricAlertId: number;
	metricTabSelected: boolean;
	currentProjects: Project[];
	currentWorkspaceProjects: AccountOrWorkspaceProject[];
	loading: ProjectAssetsLoading = {};
	errors: ProjectAssetsErrors = {};
	alertTabs: AlertTab[];
	currentType: AlertType;
	metricAlertsEnabled: boolean;
	verbatimAlertsEnabled: boolean;
	ui = {};

	// workspace
	isWorkspaceEnabled: boolean;
	project: AccountOrWorkspaceProject;
	projectsAmount: OptionsAmount;

	metricAlertGridType: GridTypes = GridTypes.STUDIO_ALERT;
	verbatimAlertGridType: GridTypes = GridTypes.ALERT;

	constructor(
		private $scope: ng.IScope,
		private locale: ILocale,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private $location: ng.ILocationService,
		private alertingApiService: AlertingApiService,
		private security: Security,
		private authorization: Authorization,
		private betaFeaturesService: BetaFeaturesService,
		private redirectService,
		private globalNotificationService: GlobalNotificationService,
		private routeService
	) {
	}

	private renewExpiration = (): ng.IPromise<void> => {
		return this.alertingApiService.renewExpiration(this.renewMetricAlertId).then(
			response => {
				let metricAlertDisplayName = response.data.metricAlertDisplayName;
				let notificationMessage = (response.data.hasBeenRenewed)
					? this.locale.getString('alert.hasBeenRenewed', {name: metricAlertDisplayName})
					: this.locale.getString('alert.isAlreadyRenewed', {name: metricAlertDisplayName});
				this.globalNotificationService.addSuccessNotification(notificationMessage);
				this.$location.search('metricAlertId', null);
			},
			response => {
				if (response.status === CHANGE_MA_STATUS) {
					this.redirectService.saveCurrentMA({accountId: response.data});
					return;
				}
			});
	}

	private initConstructor = () => {
		// restrict this page to non-mobile
		this.security.preventMobileAccess();
		this.security.restrictPage(this.authorization.hasAlertManagementAccess);

		this.metricAlertsEnabled = this.security.has('manage_studio_alerts');
		this.verbatimAlertsEnabled = this.security.has('manage_designer_alerts')
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.VERBATIM_ALERTING);
	}

	$onInit(): void {
		this.initConstructor();
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.metricTabSelected = this.$location.search().type === AlertType.STUDIO.toLowerCase();
		this.renewMetricAlertId = this.$location.search().metricAlertId;

		let preloadAction = this.renewMetricAlertId ? this.renewExpiration() : this.$q.when();
		this.loading.promise = preloadAction;

		preloadAction.then(() => {
			this.props = {} as any;
			this.project = {} as AccountOrWorkspaceProject;

			this.currentType = !this.verbatimAlertsEnabled || this.metricTabSelected ? AlertType.STUDIO : AlertType.VERBATIM;
			this.alertTabs = [
				{id: 'metric', name: this.locale.getString('alert.metricAlert'), type: AlertType.STUDIO},
				{id: 'interaction', name: this.locale.getString('alert.verbatimAlert'), type: AlertType.VERBATIM}
			];

			this.$scope.$on('projects:loaded', (event, projects) => {
				this.currentProjects = _.map(projects, project => {
					project.id = project.id || project.projectId;
					return project;
				});
			});

			this.processSelectedTab();
			this.$scope.$on('$routeUpdate', (event) => this.processSelectedTab());
		});
	}

	workspaceProjectsLoaded = (currentWorkspaceProjects: AccountOrWorkspaceProject[]) => {
		this.currentWorkspaceProjects = currentWorkspaceProjects;
	}

	accountChanged = (newProps?: IProjectSelection): void => {
		if (newProps) {
			this.props = newProps;
			this.project = newProps;
		}
	}

	projectChanged = (newProps: IProjectSelection): void => {
		if (newProps) {
			this.props = newProps;
			this.project = newProps;
		}

		if (ValueUtils.isNotSelected(this.props.accountId) || this.errors.tooManyProjects) {
			this.$scope.$broadcast(AlertEvent.CLEAR);
		} else {
			this.$scope.$broadcast(AlertEvent.RELOAD, newProps);
		}
	}

	// Unit is previous workspace type
	workspaceChanged = (workspace: Unit) => {
		this.cleanProjectErrors();
		this.projectsAmount = undefined;
		if (workspace) {
			this.currentWorkspaceProjects = [] as AccountOrWorkspaceProject[];
			this.$scope.$broadcast(AlertEvent.CLEAR);
			this.props.contentProviderId = workspace.contentProviderId;
			this.props.accountId = workspace.accountId;
		} else {
			this.currentWorkspaceProjects = [] as AccountOrWorkspaceProject[];
			this.$scope.$broadcast(AlertEvent.CLEAR);
			this.props.contentProviderId = -1;
			this.props.accountId = -1;
		}
	}

	workspaceProjectChanged = (newProject?: WorkspaceProjectData) => {
		this.cleanProjectErrors();
		// selected some project and need to input
		if (newProject) {
			this.project = newProject;
			this.props.projectId = newProject.projectId;
			this.props.projectName = newProject.projectName;
		}

		if (this.props.projectId === null || this.props.projectId === -1) {
			this.props.projectId = undefined;
		}

		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.errors.tooManyProjects = !WorkspaceProjectUtils.isProjectSelected(newProject)
			&& this.projectsAmount === OptionsAmount.MANY_OPTIONS;
		if (this.errors.tooManyProjects) {
			return;
		}

		if (ValueUtils.isNotSelected(this.props.accountId) || this.errors.tooManyProjects) {
			this.$scope.$broadcast(AlertEvent.CLEAR);
		} else {
			this.$scope.$broadcast(AlertEvent.RELOAD, this.props);
		}
	}

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	}

	projectsLoaded = (amount: OptionsAmount): void => {
		this.projectsAmount = amount;
		this.workspaceProjectChanged();
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	}

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	}

	addAlert = () => {
		if (ValueUtils.isSelected(this.props.projectId))
			this.$scope.$broadcast(AlertEvent.CREATE);
		else this.errors.noProjectSelected = true;
	}

	isTabSelected = (type: AlertType) => {
		return this.currentType === type;
	}

	selectType = (type: AlertType) => {
		this.currentType = type;
		this.updatePath();
		this.$timeout(() => this.$scope.$broadcast('alerts:refresh'));
	}

	private processSelectedTab = (): void => {
		this.routeService.handleSelectedTab(this.alertTabs, (selectedTab) => {
			this.currentType = selectedTab.type;
			this.updatePath();
		}, () => {
			this.updatePath();
		});
	}

	private updatePath = (): void => {
		let selectedTab = _.find(this.alertTabs, { type: this.currentType });
		if (selectedTab) {
			this.$location.search({ tab: selectedTab.id });
		}
	}
}
app.component('alertsPage', {
	templateUrl: 'partials/alerts/alerts-page.html',
	controller: AlertsPageController
});
