import { NgModule } from '@angular/core';
import { BookPageComponent } from '@app/modules/book/book-page/book-page.component';
import { BookTabsEditorUpgradeDirective } from '@app/modules/book/upgrade/book-tabs-editor.upgrade.directive';
import { DashboardViewUpgradeDirective } from '@app/modules/book/upgrade/dashboard-view.upgrade.directive';
import { DashboardActionsModule } from '@app/modules/dashboard-actions/dashboard-actions.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { SharedModule } from '@app/shared/shared.module';
import { BookTabInfoLabelComponent } from './book-editor/book-tab-info-label/book-tab-info-label.component';
import { EmbedDashboardComponent } from './embed-dashboard/embed-dashboard.component';
import { BookSimpleTabComponent } from './book-simple-tab/book-simple-tab.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { BookEditorQualtricsEmbedComponent } from './book-editor/book-editor-qualtrics-embed.component';
import { BookTabsComponent } from './book-tabs/book-tabs.component';
import { ScrollCarouselModule } from '@app/modules/scroll-carousel/scroll-carousel.module';
import { QualtricsTabContentComponent } from './book-tabs/qualtrics-tab-content/qualtrics-tab-content.component';
import { SimpleTabContentComponent } from './book-tabs/simple-tab-content/simple-tab-content.component';
import { BookDashboardHeaderComponent } from './book-dashboard-header/book-dashboard-header.component';
import { BookDashboardViewComponent } from './book-dashboard-view/book-dashboard-view.component';
import { WidgetVisualizationsModule } from '@app/modules/widget-visualizations/widget-visualizations.module';
import { BookEditPageComponent } from '@app/modules/book/book-editor/book-edit-page.component';
import { BookDashboardsTableComponent } from './book-editor/book-dashboards-table/book-dashboards-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { ObjectListModule } from '@app/modules/object-list/object-list.module';
import { BookEditTooltipComponent } from '@app/modules/book/book-editor/book-edit-tooltip/book-edit-tooltip.component';
import { DashboardListModule } from '@app/modules/dashboard-list/dashboard-list.module';
import { BookListHelperService } from '@app/modules/book/book-list-helper.service';

@NgModule({
	imports: [
		SharedModule,
		DashboardModule,
		DashboardActionsModule,
		DashboardListModule,
		DialogModule,
		CxFormModule,
		ScrollCarouselModule,
		WidgetVisualizationsModule,
		AgGridModule,
		ObjectListModule,
	],
	declarations: [
		BookPageComponent,
		DashboardViewUpgradeDirective,
		BookTabsEditorUpgradeDirective,
		BookTabInfoLabelComponent,
		EmbedDashboardComponent,
		BookSimpleTabComponent,
		BookEditorQualtricsEmbedComponent,
		BookTabsComponent,
		QualtricsTabContentComponent,
		SimpleTabContentComponent,
		BookDashboardHeaderComponent,
		BookDashboardViewComponent,
		BookEditPageComponent,
		BookDashboardsTableComponent,
		BookEditTooltipComponent,
	],
	exports: [
		DashboardViewUpgradeDirective,
	],
	providers: [
		BookListHelperService,
	],
})
export class BookModule { }
