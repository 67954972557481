import * as _ from 'underscore';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';


export interface INode {
	_disabled?: boolean;
	id?: number;
	name?: string;
	type?: string;
	children?: INode[];
	_autoexpanded?: boolean;
	_expanded?: boolean;
	hidden?: boolean;
	hide?: boolean;
	level?: number;
}

export interface ISearchableHierarchyItem extends INode, Omit<AttributeGrouping, 'type'> {
	_hideExpansionToggle?: boolean;
	_notRecommended?: boolean;
	name: string;
}

//Defines common  for =  searchable hierarchy directive=> s
export class SearchableHierarchyUtils {

	static findMetricNameInHierarchy = (items: INode[], name) => {
		return SearchableHierarchyUtils.findMetricInHierarchy(items, { name });
	}

	static findMetricInHierarchy = (items: INode[], node) => {
		for (let item of items) {
			if (item) {
				if (SearchableHierarchyUtils.checkProperties(item, node)) {
					return item;
				}
				if (item.children) {
					let childrenResult = SearchableHierarchyUtils.findMetricInHierarchy(item.children, node);
					if (childrenResult) {
						return childrenResult;
					}
				}
			}
		}
		return undefined;
	}

	static checkProperties = (item: INode, node: INode): boolean => {
		//should ignore different between topics and topicLeaf
		if (AnalyticMetricTypes.isTopics(item as AttributeGrouping) && AnalyticMetricTypes.isTopics(node as AttributeGrouping)) {
			return item.name === node.name;
		}
		return item.name === node.name && (!node.type || node.type === item.type);
	}

	static isEmptyFolder = (node: INode) => {
		return SearchableHierarchyUtils.isFolder(node) && (!node.children || node.children.length < 1);
	}

	static isFolder = (node: INode) => {
		return node && /.*folder.*/i.test(node.type);
	}

	static showItem = (node: INode) => {
		return !SearchableHierarchyUtils.isEmptyFolder(node);
	}

	static toggleParent = (folder: INode, $event) => {

		if (folder._autoexpanded) {
			folder._autoexpanded = false;
			folder._expanded = true;
		}

		folder._expanded = !folder._expanded;
		$event.stopPropagation();
	}

	static expandFolders = (folder: INode): void => {
		if (folder.children && folder.children.length) {
			folder._autoexpanded = true;
			folder.children.forEach(SearchableHierarchyUtils.expandFolders);
		}
	}

	static collapseFolder = (node: INode): void => {
		if (node.children && node.children.length) {
			node._autoexpanded = false;
			node.children.forEach(SearchableHierarchyUtils.collapseFolder);
		}
	}

	static searchById = (list: INode[], value) => {
		return _.findWhere(list || [], {id: value});
	}

	static searchByName = (list: INode[], value) => {
		return _.findWhere(list || [], {name: value});
	}

	static deepSearchById = (list: INode[], value) => {
		return SearchableHierarchyUtils.deepSearchByPredicate(list || [], node => {
			return node.id === value;
		});
	}

	static deepSearchByName = (list: INode[], value) => {
		return SearchableHierarchyUtils.deepSearchByPredicate(list || [], node => {
			return node.name === value;
		});
	}

	static deepSearchByNameAndType = (list: INode[], name: string, type: any) => {
		return SearchableHierarchyUtils.deepSearchByPredicate(list || [], node => {
			return node.name === name && node.type === type;
		});
	}

	static deepSearchByPredicate = (list: INode[], predicate) => {
		for (let attribute of list) {

			if (predicate(attribute)) {
				return attribute;
			}

			if (attribute.children && attribute.children.length > 0) {
				let matchedAttribute = SearchableHierarchyUtils.deepSearchByPredicate(attribute.children,
					predicate);

				if (matchedAttribute) {
					return matchedAttribute;
				}
			}
		}
		return undefined;
	}
}
