import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import * as _ from 'underscore';
import { ScheduleUtilsService } from '../dashboard/schedule/schedule-utils.service';
import { JobType } from './jobs/job-type';
import { ScheduledJobTypes } from './jobs/scheduled-job-types.service';
import { Schedule } from './schedule';
import { ScheduleApiService } from './schedule-api-service';
import { ScheduleGridItem } from './schedule-grid-item';
import { ScheduleIdentity } from './schedule-identity';


interface ScopeProps {
	users: User[];
	schedulesList: ScheduleGridItem[];
	filteredSchedulesList: ScheduleGridItem[];
	currentUserId: number | string;
	searchSchedules?: string;
	lastChange?: any[];
	showUserSelector: () => boolean;
}
@Component({
	selector: 'schedule-management',
	templateUrl: './schedule-management.component.html'
})
export class ScheduleManagementComponent implements OnInit {

	readonly gridType: GridTypes = GridTypes.SCHEDULE;
	readonly gridNameField: string = 'dashboardName';
	gridOptions: SlickgridOptions;
	loading;
	userOptions: any[];
	ui: ScopeProps;

	constructor(
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('security') private readonly security: Security,
		@Inject('authorization') private readonly authorization: Authorization,
		private readonly dashboardScheduleService: DashboardScheduleService,
		private readonly gridUtils: GridUtilsService,
		private readonly locale: CxLocaleService,
		private readonly scheduledJobTypes: ScheduledJobTypes,
		private readonly scheduleUtilsService: ScheduleUtilsService,
		private readonly scheduleApiService: ScheduleApiService) {
	}

	ngOnInit(): void {

		this.security.restrictPage(() => {
			return this.authorization.hasCreateDashboardAccess() || this.authorization.hasManageSettingsAccess();
		});

		this.ui = {
			users: [],
			schedulesList: [],
			filteredSchedulesList: [],
			currentUserId: '',
			searchSchedules: '',
			showUserSelector: this.showUserSelector
		};

		this.gridOptions = { onClick: this.onClick };
		this.reloadSchedules();
	}

	getUserOptions = () => {
		return [ { userId: '', userEmail: this.locale.getString('common.allUsers') }, ...this.ui.users ];
	}

	userFilter = (schedule: Schedule) => {
		return !this.ui.currentUserId || schedule.ownerId === this.ui.currentUserId;
	}

	onClick = (event: Event, schedule: Schedule): void => {
		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, schedule, this.getContextMenu(schedule), 'dashboards', 360);
		} else if (this.gridUtils.isNameClick(event)) {
			this.modifySchedule(schedule);
		}
	}

	getContextMenu = (schedule: Schedule): Array<ContextMenuItem<Schedule>> => {
		let MENU_OPTIONS = {
			disable: {name: 'disable', text: this.locale.getString('administration.disable'), func: this.disableSchedule},
			enable: {name: 'enable', text: this.locale.getString('administration.enable'), func: this.enableSchedule},
			modify: {name: 'modify', text: this.locale.getString('schedule.modify'), func: this.modifySchedule},
			viewAllRuns: {name: 'viewAllRuns', text: this.locale.getString('schedule.viewAllRuns'), func: this.viewScheduleRuns},
			delete: {name: 'delete', text: this.locale.getString('administration.delete'), func: this.deleteSchedule}
		};
		if (schedule.active) {
			return [MENU_OPTIONS.disable, MENU_OPTIONS.modify, MENU_OPTIONS.viewAllRuns];
		} else {
			let menu = [MENU_OPTIONS.modify, MENU_OPTIONS.viewAllRuns, MenuDivider, MENU_OPTIONS.delete];
			if (!this.scheduleUtilsService.isExpired(schedule.endDate)) {
				menu.unshift(MENU_OPTIONS.enable);
			}
			return menu;
		}
	}

	showUserSelector = (): boolean => {
		return this.authorization.hasManageSettingsAccess();
	}

	disableSchedule = (schedule: Schedule) => {
		this.scheduleApiService.disableSchedule(schedule.triggerName, schedule.triggerGroup).then(this.reloadSchedules);
	}

	enableSchedule = (schedule: Schedule) => {
		this.scheduleApiService.enableSchedule(schedule.triggerName, schedule.triggerGroup).then(this.reloadSchedules);
	}

	deleteSchedule = (schedule: Schedule) => {
		this.scheduleApiService.deleteSchedule(schedule.dashboardId, schedule.triggerName, schedule.triggerGroup)
			.then(this.reloadSchedules);
	}

	modifySchedule = (schedule: Schedule) => {
		let dashboard = new Dashboard(schedule.dashboardId, schedule.dashboardName);
		let identity = new ScheduleIdentity(schedule.triggerName, schedule.triggerGroup);
		this.dashboardScheduleService.openScheduleDialog(dashboard, identity).then(this.reloadSchedules, _.noop);
	}

	viewScheduleRuns = (schedule: Schedule) => {
		let jobType = this.scheduledJobTypes.findType(schedule.jobType);
		let job = jobType.titleString;

		let description = this.scheduleUtilsService.getScheduleDescription(schedule.startDate, schedule.endDate,
			schedule.cronExpression, schedule.activeFor, schedule.timezoneOffset, schedule.jobType === JobType.pdf_distribution);

		let model = {
			identity: new ScheduleIdentity(schedule.triggerName, schedule.triggerGroup),
			dashboardName: schedule.dashboardName,
			scheduleName: job + description
		};
		this.cbDialogService.custom(
			this.locale.getString('schedule.allRuns'),
			'partials/schedules/schedule-runs-dialog.html',
			model,
			this.locale.getString('common.close'),
			null,
			'schedule-runs-dialog');
	}

	private processData = (schedules: Schedule[]): void => {
		let userMap: {[key: number]: User} = {};
		let gridItems = _.map(schedules, schedule => schedule as ScheduleGridItem);
		_.each(gridItems, (item) => {
			item.id = item.triggerGroup + '-' + item.triggerName;
			let name = item.ownerName;
			if (!name) {
				name = this.locale.getString('widget.na') + ' (id = ' + item.ownerId + ')';
			}
			if (item.ownerId !== -1) {
				// no users for deleted dashboard
				userMap[item.ownerId] = {userId: item.ownerId, userEmail: name};
			}
			item.level = 0;
			if (!item.active) {
				TaggingHelper.tag(item, TaggingHelper.tags.INACTIVE);
			}
		});
		this.ui.schedulesList = gridItems;
		this.ui.users = _.values(userMap);
		this.userOptions = this.getUserOptions();
		this.filterSchedules();
		this.refreshGrid(gridItems);
	}

	filterSchedules = (): void => {
		this.ui.filteredSchedulesList = this.ui.schedulesList.filter(this.userFilter);
	}

	private reloadSchedules = (): void => {
		let schedulesPromise: Promise<Schedule[]>;
		if (!this.authorization.hasManageSettingsAccess()) {
			schedulesPromise = this.scheduleApiService.getSchedulesForUser();
		} else {
			schedulesPromise = this.scheduleApiService.getSchedules();
		}
		this.loading = schedulesPromise.then((schedules) => {
			this.processData(schedules);
		});
	}

	private refreshGrid(items): void {
		this.ui.lastChange = [].concat(items);
	}
}

app.directive('scheduleManagement', downgradeComponent({component: ScheduleManagementComponent}) as angular.IDirectiveFactory);
