import { IDirectiveFactory, INgModelController } from 'angular';

const convertToNumber = () => {
	return {
		require: 'ngModel',
		link: (scope, element, attrs, ngModel: INgModelController) => {
			ngModel.$parsers.push((val) => {
				return parseInt(val, 10);
			});

			ngModel.$formatters.push((val) => {
				return '' + val;
			});
		}
	};
};

app.directive('convertToNumber', convertToNumber as IDirectiveFactory);
