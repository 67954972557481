import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { NullIncludeSettings } from '../settings.interfaces';


export class NullIncludeSettingsGroup implements ISettingsGroup<NullIncludeSettings> {

	static readonly DEFAULTS: NullIncludeSettings = {
		nullInclude: false
	};

	settings: NullIncludeSettings;
	timeMode: boolean;

	constructor(source: NullIncludeSettings, timeMode: boolean = false) {
		this.settings = source
			? _.pick(source, ['nullInclude'])
			: cloneDeep(NullIncludeSettingsGroup.DEFAULTS);

		this.timeMode = timeMode;
	}

	postProcessSettings(): NullIncludeSettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = cloneDeep(NullIncludeSettingsGroup.DEFAULTS);
	}

}
