export class PlaybackIndicatorOptions {
	color: string;
	opacity: number;
	size: number;

	constructor() {
		return {
			color: '#67A0E6',
			size: 3,
			opacity: .9
		};
	}
	
}