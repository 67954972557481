import ILocale from '@cxstudio/interfaces/locale-interface';

export class ErrorExtractor {
	readonly delimiter = '. ';
	_locale;
	
	constructor(
		private $injector
	) {}	

	getLocale = (): ILocale => {
		if (!this._locale)
			this._locale = this.$injector.get('locale');
		return this._locale;
	}

	extract = (headers: any): string => {
		let error = headers.error;
		let localized = isTrue(headers.localization);
		if (localized) {
			let params = angular.fromJson(headers['localization-params'] || undefined);
			error = this.getLocale().getString('error.' + error, params);
		}
		if (headers.clientrequestid) {
			let codeReference = localized 
				? this.getLocale().getString('error.codeReference', {rid: headers.clientrequestid})
				: `Please reference this code: ${headers.clientrequestid} .`;
			error += this.delimiter + codeReference;
		}
		return error;
	}
}
app.service('errorExtractor', ErrorExtractor);