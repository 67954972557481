<collapsing-panel>
	<panel-heading class="align-items-center">
		<div class="d-flex align-items-center" style="height: 0">
			<cb-toggle
				class="ml-4 mr-8"
				(click)="$event.stopPropagation()"
				[(ngModel)]="homePage.quickInsights.enabled"
				(ngModelChange)="onChange.emit()">
			</cb-toggle>
			<b [i18n]="'homePage.showXMDiscoverSearch'" class="mt-2"></b>
		</div>
	</panel-heading>
	<panel-body>
		<div [ngBusy]="[projectsLoading]" class="quick-insights-section form-horizontal">
			<checkbox-button
				label="{{'homePage.inheritProjectSetting'|i18n}}"
				[(ngModel)]="homePage.quickInsights.inheritProjectSetting"
				(ngModelChange)="onInheritChange()">
			</checkbox-button>
			<div *ngIf="!homePage.quickInsights.inheritProjectSetting">
				<form #quickInsightsPropertiesForm="ngForm">
					<ng-container *ngIf="!isWorkspaceEnabled">
						<project-selector
							[disable]="homePage.quickInsights.inheritProjectSetting"
							[projectSelection]="properties"
							(projectSelectionChange)="onProjectSelectionChange($event)"
							(errorsChange)="onCpErrorsChange($event)"
							(loading)="onProjectsLoading($event)"
						>
						</project-selector>
						<div *ngIf="!homePage.quickInsights.inheritProjectSetting">
							<alert type="danger" *ngFor="let cperror of contentProviderErrors">
								<span>{{ cperror }}</span>
							</alert>
						</div>
					</ng-container>
					<workspace-project-selector
						*ngIf="isWorkspaceEnabled"
						[(value)]="homePage.quickInsights.workspaceProject"
						(valueChange)="onProjectChange()"
						labelPosition="left"
						(loading)="onProjectsLoading($event)"
					></workspace-project-selector>

					<div class="form-group mb-0" [ngClass]="{'has-error br-form-error': owner.errors?.required}">
						<label for="ownerSelector" class="control-label col-md-3">{{'common.owner'|i18n}}</label>
						<div class="col-sm-9">
							<input
								id="quickInsightsOwnerSelector"
								name="owner"
								#owner="ngModel"
								type="text"
								class="form-control mobile-owner-input"
								[disabled]="homePage.quickInsights.inheritProjectSetting"
								[ngClass]="{'shorter-from-right': searchingOwnerCandidates}"
								[required]="!homePage.quickInsights.inheritProjectSetting"
								[ngModel]="homePage.quickInsights.owner"
								(ngModelChange)="changeOwner($event)"
								[ngbTypeahead]="ownerCandidates"
								[editable]="false"
								(blur)="clearText()">
							<span *ngIf="searchingOwnerCandidates" class="ml-16 loading-spinner q-icon-spinner rotate-infinite"></span>
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-offset-3 col-sm-9">
							<span
								class="help-block text-danger mb-0"
								*ngIf="owner.errors?.required"
								[i18n]="'homePage.quickInsightsOwnerRequired'">
							</span>
						</div>
					</div>

					<alert type="danger" *ngIf="projectChanged" class="mt-16">
						<div [i18n]="'mobile.warningChangedProject'"></div>
					</alert>
				</form>
			</div>
		</div>
	</panel-body>
</collapsing-panel>
