<form class="form-horizontal" name="templateDialog" role="form">
	<!-- Name -->
	<div class="form-group flex-direction-column align-items-start">
		<label class="w-100-percent">
			<p class="mb-8 font-semibold text-left" [i18n]="'templates.name'"></p>
			<div class="w-100-percent" [ngClass]="{'has-error br-form-error': nameField?.errors?.required}">
				<input
					name="name"
					type="text"
					class="form-control"
					data-testid="template-name"
					placeholder="{{'templates.namePlaceholder'|i18n}}"
					[(ngModel)]="template.name"
					#nameField
					required
					maxlength="300">
				<span class="help-block" *ngIf="nameField.errors?.required">{{'templates.emptyTemplateNameWarning'|i18n}}</span>
			</div>
		</label>

	</div>

	<!-- Description -->
	<div class="form-group flex-direction-column align-items-start">
		<label class="control-label mb-8 font-semibold" [i18n]="'dashboard.description'"></label>
		<description class="w-100-percent"
					 name="description"
					 [(ngModel)]="template.description"
					 [placeholder]="''"
					 [rows]="1">
		</description>
	</div>

	<div class="form-group flex-direction-column align-items-start">
		<label class="control-label mb-8 font-semibold">{{'templates.parentFolder'|i18n}}
		</label>
		<div class="w-100-percent">
			<div class="br-folder-select w-100-percent">
				<simple-dropdown
					class="w-100-percent"
					[(value)]="template.parentId"
					displayField="name"
					valueField="id"
					[options]="folders"
					[disabled]="!folders.length">
				</simple-dropdown>
			</div>
		</div>
	</div>
</form>
