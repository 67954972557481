enum ExternalApplicationType {
	CONNECTORS = 'CONNECTORS',
	NFS = 'NFS',
	VTT = 'VTT',
	ENGAGE = 'ENGAGE',
	TUNING_SUGGESTION = 'TUNING_SUGGESTION',
	NARRATIVE = 'NARRATIVE',
	TICKETING = 'TICKETING'
}

export default ExternalApplicationType;