import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';


export interface ProjectAccessLevel {
	value: string;
	label: string;
}

@Injectable({
	providedIn: 'root'
})
export class ProjectAccessLevelsService {
	NONE: ProjectAccessLevel;
	VIEWER: ProjectAccessLevel;
	MANAGER: ProjectAccessLevel;
	CUSTOM: ProjectAccessLevel;

	options: ProjectAccessLevel[];

	constructor(
		locale: CxLocaleService
	) {
		this.MANAGER = {
			value: ProjectAccessLevelValue.MANAGER,
			label: locale.getString('contentProvider.adminRole')
		};
		this.VIEWER = {
			value: ProjectAccessLevelValue.VIEWER,
			label: locale.getString('contentProvider.readOnlyRole')
		};
		this.CUSTOM = {
			value: ProjectAccessLevelValue.CUSTOM,
			label: locale.getString('contentProvider.customRole')
		};
		this.NONE = {
			value: ProjectAccessLevelValue.NONE,
			label: locale.getString('contentProvider.noAccessRole')
		};

		this.options = [ this.MANAGER, this.VIEWER, this.CUSTOM, this.NONE ];
	}

	findByValue(value: string): ProjectAccessLevel | undefined {
		let matchedValue = this.options.filter((option) => option.value === value);
		return matchedValue.length ? matchedValue[0] : undefined;
	}
}

app.service('projectAccessLevelsService', ProjectAccessLevelsService);
