import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'projects-management'
})
export class ProjectsManagementUpgrade extends UpgradeComponent {
	constructor(elementRef: ElementRef, injector: Injector) {
		super('projectsManagement', elementRef, injector);
	}
}
