import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { TypeGuards } from '@app/util/typeguards.class';
import { Security } from '@cxstudio/auth/security-service';
import { Tag } from '@app/modules/account-administration/properties/tag';

@Injectable({
	providedIn: 'root'
})
export class TagsService {

	constructor(
		private readonly http: CxHttpService,
		@Inject('security') private readonly security: Security
	) { }

	predefinedToTagObjects = (stringArray: Array<string | object>, values: any[]): any[] => {
		let tagObjects = [];
		if (!_.isUndefined(stringArray)) {
			_.each(stringArray, (item) => {
				if (TypeGuards.isObject(item)) {
					tagObjects.push(item);
				} else {
					tagObjects.push(_.find(values, {value: item}));
				}
			});
		}
		return tagObjects;
	}

	stringToTagObject = (tag: string): Tag => {
		return {text: tag};
	}

	stringArrayToTagObjects = (stringArray: Array<string | object>): Array<{text: string}> => {
		let tagObjects = [];
		if (!_.isUndefined(stringArray)) {
			_.each(stringArray, (item) => {
				if (TypeGuards.isObject(item)) {
					tagObjects.push(item);
				} else {
					tagObjects.push(this.stringToTagObject(item));
				}
			});
		}
		return tagObjects;
	}

	tagObjectsToStringArray = (tagObjects: Array<string | {value?: string, text?: string}>): string[] => {
		let stringArray = [];
		if (!_.isUndefined(tagObjects)) {
			_.each(tagObjects, (tag) => {
				if (TypeGuards.isString(tag)) {
					stringArray.push(tag);
				} else if (tag.value) {
					stringArray.push(tag.value);
				} else {
					stringArray.push(tag.text);
				}
			});
		}
		return stringArray;
	}

	filterTagsSuggestionsByUserTags = (tags: string[]): string[] => {
		let currentUserTags = [];
		if (this.security.getUser()) {
			currentUserTags = this.security.getUser().tags;
		}
		return _.filter(tags, (tag) => {
			return currentUserTags.contains(tag);
		});
	}

	getDisableTagClass = (tag: Tag, additionalCheck?: (tag: Tag) => boolean): string => {
		let currentUserTags = [];
		if (this.security.getUser()) {
			currentUserTags = this.security.getUser().tags;
		}
		let disableTag = !currentUserTags.contains(tag.text);

		if (additionalCheck) {
			disableTag = disableTag && additionalCheck(tag);
		}
		return disableTag
			? 'disable-tag-removal'
			: '';
	}

	getTags = (tagFilter: string): Promise<string[]> => {
		let params = new HttpParams()
			.append('pageSize', '20')
			.append('filterText', tagFilter);
		return this.http.get('rest/tag', { params });
	}
}

app.service('tagService', downgradeInjectable(TagsService));
