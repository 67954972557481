import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import * as moment from 'moment';
import { ContractMode } from '@cxstudio/master-accounts/contracts/contract-mode';
import { ContractSecurityLevel } from '@cxstudio/master-accounts/contracts/contract-security-level';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { DateTimeFormat, DateService } from '@cxstudio/services/date-service.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

interface OptionItem {
	name: string;
	value: number;
}

export class ContractModalComponent {

	readonly DEFAULT_SECURITY_LEVEL = ContractSecurityLevel.DEFAULT_SECURITY_LEVEL;
	readonly COVERAGE_OPTIONS = [0, 25_000, 50_000, 75_000, 100_000, 125_000, 250_000, 300_000, 500_000, 750_000,
		1_000_000, 1_500_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000, 7_500_000, 10_000_000, 12_500_000,
		15_000_000, 20_000_000, 25_000_000, 30_000_000, 35_000_000, 40_000_000, 45_000_000, 50_000_000,
		60_000_000, 70_000_000, 75_000_000, 80_000_000, 90_000_000, 100_000_000, 125_000_000, 150_000_000];

	licenses: any;
	resolve: any;
	contract: Contract;
	mode: ContractMode;
	ui: { viewMode: boolean;
		title: any;
		closeButtonTitle: any;
		security: boolean;
		allCoverageOptions: OptionItem[];
		availableCoverageOptions?: OptionItem[];
		existingCoverage?: OptionItem;
		existingRealTimeAgentAssistCoverage?: OptionItem;
		existingAutomatedCallSummaryCoverage?: OptionItem;
	};
	showErrors: boolean;
	uniqueNameError: boolean;
	datePicker: {
		dateFormat: any;
		endDate: { options: { minDate: any } };
		startDate: { options: { maxDate: any } };
		open: (event, type) => void;
	};

	close: (result) => void;
	dismiss: (reason) => void;

	extendedSecurityLevel = ContractSecurityLevel.EXTENDED;

	isVoiceEnabled = this.security.isVoiceEnabled;
	isIntelligentScoringEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);

	constructor(
		private locale: ILocale,
		private security: Security,
		private dateService: DateService,
		private contractApiService: ContractApiService,
		private formatterBuilderUtils: FormatterBuilderUtilsService,
		private betaFeaturesService: BetaFeaturesService,
	) { }

	$onInit = (): void => {
		this.licenses = this.resolve.licenses;
		this.contract = angular.copy(this.resolve.contract);
		this.mode = this.resolve.mode;

		this.ui = {
			viewMode: this.mode === ContractMode.VIEW,
			title: this.locale.getString(`mAccount.${this.mode.toLowerCase()}`),
			closeButtonTitle: this.locale.getString('common.' + (this.mode === ContractMode.VIEW ? 'close' : 'cancel')),
			security: this.contract?.security === ContractSecurityLevel.EXTENDED,
			allCoverageOptions: _.map(this.COVERAGE_OPTIONS, (value) => ({ name: this.formatterBuilderUtils.formatAny(value), value }) as OptionItem)
		};

		this.showErrors = false;
		this.uniqueNameError = false;
		if (_.isUndefined(this.contract)) {
			let currentDateString = moment().format('YYYY-MM-DD');
			let nextDateString = moment().add(1, 'day').format('YYYY-MM-DD');
			this.contract = {
				masterAccountId: this.security.getMasterAccountId(),
				security: this.DEFAULT_SECURITY_LEVEL,
				startDate: currentDateString,
				endDate: nextDateString,
				licenses: {},
				verbatimCredits: 0,
				periods: 1,
				note: '',
				audioHours: 0,
				audioRetention: 0,
				quickTranslate: false,
				intelligentScoring: this.isIntelligentScoringEnabled,
				coverageVolume: 0,
				realTimeAgentAssist: false,
				realTimeAgentAssistCoverageVolume: 0,
				automatedCallSummary: false,
				automatedCallSummaryCoverageVolume: 0
			} as Contract;
			this.licenses.forEach((license) => {
				this.contract.licenses[license.licenseTypeId] = 0;
			});
		} else {
			if (_.isUndefined(this.contract.realTimeAgentAssistCoverageVolume)) {
				this.contract.realTimeAgentAssistCoverageVolume = 0;
			}
			if (_.isUndefined(this.contract.automatedCallSummaryCoverageVolume)) {
				this.contract.automatedCallSummaryCoverageVolume = 0;
			}
		}

		this.updateCoverageOptions();

		this.ui.existingCoverage = {name: this.formatterBuilderUtils.formatAny(this.contract.coverageVolume),
			value: this.contract.coverageVolume};

		this.ui.existingRealTimeAgentAssistCoverage = {
			name: this.formatterBuilderUtils.formatAny(this.contract.realTimeAgentAssistCoverageVolume),
			value: this.contract.realTimeAgentAssistCoverageVolume
		};

		this.ui.existingAutomatedCallSummaryCoverage = {
			name: this.formatterBuilderUtils.formatAny(this.contract.automatedCallSummaryCoverageVolume),
			value: this.contract.automatedCallSummaryCoverageVolume
		};

		this.contract.startDate = new Date(this.contract.startDate);
		this.contract.endDate = new Date(this.contract.endDate);
		this.contract.periods = this.contract.periods || 1;

		this.datePicker = {
			dateFormat: this.dateService.getUserDateFormat(DateTimeFormat.BASIC_DATE),
			endDate: {
				options: {
					minDate: this.contract.startDate
				}
			},
			startDate: {
				options: {
					maxDate: this.contract.endDate
				}
			},
			open: ($event, type): void => {
				$event.preventDefault();
				$event.stopPropagation();
				if (!this.datePicker[type]) {
					this.datePicker[type] = {};
				}
				this.datePicker[type].opened = true;
			}
		};
	}

	onEndDateClose = () => {
		this.datePicker.startDate.options.maxDate = this.contract.endDate;
	}

	onStartDateClose = () => {
		this.datePicker.endDate.options.minDate = this.contract.startDate;
	}

	save = () => {
		if (this.mode === ContractMode.VIEW) return;

		this.contractApiService.contractWithNameExists(this.contract).then((result) => {
			if (result && result.data && result.data.exist === true) {
				this.showErrors = true;
				this.uniqueNameError = true;
			} else {
				this.showErrors = false;
				this.uniqueNameError = false;
				this.contract.security =  this.ui.security
					? ContractSecurityLevel.EXTENDED
					: ContractSecurityLevel.DEFAULT_SECURITY_LEVEL;
				this.close({$value: this.contract});
			}
		});
	}

	cancel = () => {
		this.dismiss({$value: 'cancel'});
	}

	hasIntelligentScoringEnabled = (): boolean => {
		return this.isIntelligentScoringEnabled && this.contract.intelligentScoring;
	}

	hasCoverageExceededError = (): boolean => {
		return !this.ui.viewMode && this.hasAnyCoverageExceeded();
	}

	private hasAnyCoverageExceeded(): boolean {
		return this.hasCoverageVolumeExceeded()
			|| this.hasRealTimeAgentAssistCoverageVolumeExceeded()
			|| this.hasAutomatedCallSummaryCoverageVolumeExceeded();
	}

	private hasCoverageVolumeExceeded(): boolean {
		return this.hasIntelligentScoringEnabled() && this.contract.verbatimCredits < this.contract.coverageVolume;
	}

	private hasRealTimeAgentAssistCoverageVolumeExceeded(): boolean {
		return this.contract.realTimeAgentAssist && this.contract.verbatimCredits < this.contract.realTimeAgentAssistCoverageVolume;
	}

	private hasAutomatedCallSummaryCoverageVolumeExceeded(): boolean {
		return this.contract.automatedCallSummary && this.contract.verbatimCredits < this.contract.automatedCallSummaryCoverageVolume;
	}

	updateCoverageOptions = (): void => {
		this.ui.availableCoverageOptions = this.ui.allCoverageOptions.filter(option => option.value <= this.contract.verbatimCredits);
	}
}

app.component('contractModal', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	templateUrl: 'partials/master-accounts/contracts/contract-modal-component.html',
	controller: ContractModalComponent
});
