import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

export class WidgetKeyboardUtils {

	static readonly WIDGET_BOX_CLASS = 'br-widget-box';

	static processKeyboardEvent(event: KeyboardEvent, widgetId: number): void {
		if (event.key === Key.ESCAPE) {
			KeyboardUtils.intercept(event);
			WidgetKeyboardUtils.focusWidgetBox(widgetId);
		} else if (event.key === Key.ENTER) {
			event.stopPropagation();
		}
	}

	static focusWidgetMenuButton(widgetId: number): void {
		WidgetKeyboardUtils.focusWithinWidget('widget-menu', widgetId);
	}

	static widgetMenuTabHandler(event: JQuery.KeyDownEvent): void {
		if (event.key === Key.TAB) {
			$('.widget-dropdown-menu :focusable').first().trigger('focus');
		}
	}

	static focusWidgetBox(widgetId: number): void {
		WidgetKeyboardUtils.focusWithinWidget(`.${WidgetKeyboardUtils.WIDGET_BOX_CLASS}`, widgetId);
	}

	private static focusWithinWidget(selector: string, widgetId: number): void {
		setTimeout(() => {
			let widgetBoxSelector = '#widget-' + widgetId + ' ' + selector;
			let target = $(widgetBoxSelector);
			target.focus();
		});
	}
}
