<div [ngBusy]="groupsLoading">

	<div class="row form-group">
		<label class="control-label col-md-4">
			{{'administration.oauthClientUnifiedLinksAccountVisibility'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.oauthClientUnifiedLinksAccountVisibilityHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-4">
			<visibility-restriction-selector
				name="accountNames"
				[hierarchyList]="accountHierarchy"
				displayProperty="name"
				(onNodeClick)="handleAccountSelection($event.node)"
				[showNodeCheckbox]="showAccountNodeCheckbox"
				[displayName]="getAccountSelectorDisplayName()"
				[nodeIsChecked]="accountNodeIsChecked">
			</visibility-restriction-selector>
		</div>
	</div>

	<div class="row form-group">
		<label class="control-label col-md-4">
			{{'administration.oauthClientUnifiedLinksAccountGroupVisibility'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.oauthClientUnifiedLinksAccountGroupVisibilityHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-4">
			<visibility-restriction-selector
				name="accountGroups"
				[hierarchyList]="accountGroupHierarchy"
				displayProperty="name"
				folderClickIgnore="false"
				[nodeIsChecked]="groupNodeIsChecked"
				[showNodeCheckbox]="showGroupNodeCheckbox"
				[displayName]="getGroupSelectorDisplayName()"
				(onNodeClick)="handleGroupSelection($event.node)"
				[nodeIsMarked]="nodeIsMarked"
				[nodeCheckboxDisabled]="nodeCheckboxDisabled">
			</visibility-restriction-selector>
		</div>
	</div>

	<div class="row form-group" *ngIf="noAccountSelected()">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning" aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label class="text-danger">{{'administration.oauthClientUnifiedLinkVisibilityAccountNotSelected'|i18n}}</label>
		</div>
	</div>

	<div class="row form-group" *ngIf="accountWithoutGroupsSelected()">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning" aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label class="text-danger">{{'administration.oauthClientUnifiedLinkVisibilityAccountWithoutGroup'|i18n}}: {{getAccountWithoutGroupNames()}}</label>
		</div>
	</div>

	<div class="row form-group" *ngIf="noGroupSelected()">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning" aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label class="text-danger">{{'administration.oauthClientUnifiedLinkVisibilityGroupNotSelected'|i18n}}</label>
		</div>
	</div>
</div>
