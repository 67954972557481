import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';

export class HierarchyModelToFilter implements IPointToFilter {
	constructor(
		private readonly organizationApiService: OrganizationApiService
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: ReportGrouping): DrillFilter | null => {
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);

		const nodeName = point && point[identifier];
		if (_.isUndefined(nodeName)) return null;

		const hierarchyId = parseInt(groupBy.name, 10);
		const nodeId = point[identifier + '_hierarchyNodeId'];
		return {
			type: DrillType.HIERARCHY_MODEL,
			name: nodeId + '',
			values: [hierarchyId, nodeName]
		};
	}

	toFilterTypes = (parsers: DrillParsers): void => {
		parsers[OptionsConstant.HIERARCHY_MODEL] = this;
	}

	getRuleString = (filter: DrillFilter, widget?: Widget): Promise<string | void> => {
		const nodeId = parseInt(filter.name, 10);
		if (_.isNumber(nodeId) && !_.isNaN(nodeId) && filter.values && filter.values.length > 0) {
			const hierarchyId = filter.values[0];
			return this.organizationApiService.getOrgHierarchy(hierarchyId)
				.then((response) => {
					const organizationHierarchy = response.data;
					return `${organizationHierarchy.name}: ${filter.values[1]}`;
				});
		}

		return Promise.resolve();
	}
}

app.service('hierarchyModelToFilter', HierarchyModelToFilter);
