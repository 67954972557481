import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ContextMenuCellParams {
	isVisible: (data) => boolean;
}

@Component({
	selector: 'context-menu-renderer',
	template: `
	<button *ngIf="params.isVisible(data)"
		class="btn btn-secondary no-background no-border p-0 icon-btn q-icon-layer">
	</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuRendererComponent implements ICellRendererAngularComp {

	readonly defaultParams: ContextMenuCellParams = {
		isVisible: (data) => {
			if (data && data.type === DashboardType.FOLDER) {
				return data.id !== DashboardType.FEEDBACK_FOLDER;
			}
			return true;
		}
	};

	params: ContextMenuCellParams;
	data: any;

	refresh(params: ICellRendererParams): boolean {
		this.data = params.data;
		this.initParams(params.colDef.cellRendererParams);
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.data = params.data;
		this.initParams(params.colDef.cellRendererParams);
	}

	private initParams(params: Partial<ContextMenuCellParams>) {
		this.params = _.extend({}, this.defaultParams, params);
	}

}
