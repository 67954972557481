<div class="grid-list-tools" [ngBusy]="loading">
	<div class="tools">
		<div class="grid-filters flex-direction-column align-items-start">
			<div *ngIf="!isWorkspaceEnabled">
				<project-selector
					class="d-flex l-margin-offset-5"
					horizontal="true"
					[projectSelection]="projectProperties"
					(projectSelectionChange)="onProjectSelectionChange($event)"
					(errorsChange)="onCpErrorsChange($event)"
					(loading)="onProjectsLoading($event)"
					[clear]="clearDefaultsSubject.asObservable()"
				></project-selector>
				<alert type="danger" *ngFor="let cperror of showErrorsForCP">
					<span>{{ cperror }}</span>
				</alert>
			</div>
			<workspace-project-selector
				*ngIf="isWorkspaceEnabled"
				[(value)]="workspaceProject"
				labelPosition="top"
				[help]="true"
				[inline]="true"
				(workspaceChange)="onWorkspaceChange($event)"
				(valueChange)="onProjectChange($event)"
				(loading)="onProjectsLoading($event)"
			></workspace-project-selector>
		</div>
		<div></div>
		<div class="grid-buttons">
			<button
				class="btn btn-primary"
				[disabled]="disableCreation()"
				(click)="createSettings()">
				{{'appearance.newNarrative'|i18n}}
			</button>
		</div>
	</div>
</div>

<div [ngBusy]="loading">
	<item-grid
		class="h-100-percent w-100-percent br-grid"
		[treeData]="settingsArray"
		[gridOptions]="gridOptions"
		[gridType]="gridType"
		[uiOptions]="{autoHeight: true}"
		[gridChange]="changedItems">
	</item-grid>
</div>
