import { GaugeCssVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-css-variables';
import { GaugeLayoutOption, GaugeLayoutPicker } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-layout-picker';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';
import { WidgetSize } from '@app/modules/widget-visualizations/widget-size';
import { GaugeBlockSizeUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-block-size-utils';
import { GaugeDefaults } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-defaults';
import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { GaugeLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-layout';
import { CanvasUtils } from '@app/shared/util/canvas-utils.service';


export interface GaugeSettings {
	layout: GaugeLayout;
	cssVars: GaugeCssVariables;
}

interface GaugeLayoutSettings {
	layout: GaugeLayoutOption;
	sizes: GaugeVariables;
}

export class GaugeSettingsUtils {
	private static readonly ALLOWED_SIZES = [ 88, 80, 72, 64, 56, 48, 40, 32, 24 ];

	private readonly TWO_LINES_LABEL_CLASS = 'two-lines-label';

	private readonly FONT_FAMILY: string = 'var(--font-standard)';

	private readonly LETTER_SPACING: number = 0;

	constructor(private comparisons: ComparisonData[], private canvasUtils: CanvasUtils) {
	}

	getGaugeSettings(widgetSize: WidgetSize, smallAfixes: boolean): GaugeSettings {
		let layoutSettings = this.recalculateSizes(widgetSize, smallAfixes);
		let comparisonBlockWidth = this.adjustComparisonsWidth(widgetSize, layoutSettings);
		if (layoutSettings.layout.type !== GaugeLayout.CHART) {
			this.fitLabelsVertically(widgetSize, layoutSettings, comparisonBlockWidth);
		}
		return {
			layout: layoutSettings.layout.type,
			cssVars: this.getCssVars(layoutSettings.sizes, comparisonBlockWidth)
		};
	}

	adjustComparisonsWidth(widgetSize: WidgetSize, layoutSettings: GaugeLayoutSettings): number {
		let comparisonBlockWidth = GaugeBlockSizeUtils.getComparisonWidth(layoutSettings.sizes);
		if (this.comparisons.isEmpty()) {
			return comparisonBlockWidth;
		}
		let labels = this.comparisons.map(comparison => this.getLabelWidth(comparison.label));
		let maxLabel = _.max(labels);
		if (maxLabel > comparisonBlockWidth) {
			let takenWidth = layoutSettings.layout.object.getComparisonsRowWidth(layoutSettings.sizes, this.comparisons);
			let spareWidth = widgetSize.width - layoutSettings.sizes.containerMargin * 2 - takenWidth;
			let countInLine = layoutSettings.layout.object.isComparisonsStacked()
				? 1
				: this.comparisons.length;
			let spaceForBlock = spareWidth / countInLine;
			comparisonBlockWidth = Math.min(maxLabel, comparisonBlockWidth + spaceForBlock);
		}
		return comparisonBlockWidth;
	}

	fitLabelsVertically(
		widgetSize: WidgetSize, layoutSettings: GaugeLayoutSettings, comparisonBlockWidth: number
	): void {
		this.resetComparisonLabelClasses();

		let takenHeight = layoutSettings.layout.object.getComparisonsColumnHeight(layoutSettings.sizes, this.comparisons);
		let spareHeight = widgetSize.height - layoutSettings.sizes.containerMargin * 2 - takenHeight;

		for (let comparison of this.comparisons) {
			if (spareHeight < GaugeDefaults.labelFontSize) {
				break;
			}
			const labelWidth = this.getLabelWidth(comparison.label);
			if (labelWidth > comparisonBlockWidth) {
				comparison.labelClass = this.TWO_LINES_LABEL_CLASS;
				if (layoutSettings.layout.object.isComparisonsStacked()) {
					spareHeight -= GaugeDefaults.labelFontSize;
				}
			}
		}
	}

	private resetComparisonLabelClasses(): void {
		this.comparisons.forEach(comparison => {
			comparison.labelClass = '';
		});
	}

	private getLabelWidth(label: string): number {
		return this.canvasUtils.getTextWidth(label, `bold ${GaugeDefaults.labelFontSize}px ${this.FONT_FAMILY}`);
	}

	private recalculateSizes(widgetSize: WidgetSize, smallAfixes: boolean, sizeIndex: number = 0): GaugeLayoutSettings {
		let mainSize = GaugeSettingsUtils.ALLOWED_SIZES[sizeIndex];
		let gaugeSizes: GaugeVariables = this.calculateDynamicSizes(mainSize, smallAfixes);
		let layout = GaugeLayoutPicker.getBestLayout(widgetSize, gaugeSizes, this.comparisons);

		if (layout) {
			return {
				layout,
				sizes: gaugeSizes
			};
		}

		if (mainSize === GaugeSettingsUtils.ALLOWED_SIZES.last()) {
			gaugeSizes.containerMargin = GaugeDefaults.marginReduced;
			layout =  GaugeLayoutPicker.getDefaultLayout(this.comparisons);
			return {
				layout,
				sizes: gaugeSizes
			};
		}

		sizeIndex++;
		return this.recalculateSizes(widgetSize, smallAfixes, sizeIndex);
	}

	private getCssVars(gaugeSizes: GaugeVariables, comparisonWidth: number): GaugeCssVariables {
		const gaugeDiameter = GaugeBlockSizeUtils.getGaugeDiameter(gaugeSizes);
		const gaugeHeight = GaugeBlockSizeUtils.getGaugeHeight(gaugeSizes);
		const chartMetricTop = this.getChartMetricTop(gaugeSizes);
		const comparisonHeight = GaugeBlockSizeUtils.getComparisonHeight(gaugeSizes);
		const comparisonHeightExtended = comparisonHeight + GaugeDefaults.labelFontSize; //2 lines label
		const cssVariables: GaugeCssVariables = {
			...gaugeSizes,
			gaugeDiameter,
			gaugeHeight,
			chartMetricTop,
			comparisonWidth,
			comparisonHeight,
			comparisonHeightExtended,
			deltaSpacing: GaugeDefaults.deltaSpacing,
			labelFontSize: GaugeDefaults.labelFontSize,
			labelSpacing: GaugeDefaults.labelSpacing,
		};
		return cssVariables;
	}

	private getChartMetricTop(gaugeVars: GaugeVariables) {
		const gaugeDiameter = GaugeBlockSizeUtils.getGaugeDiameter(gaugeVars);
		return gaugeDiameter / 2 - GaugeBlockSizeUtils.getMetricBlockHeight(gaugeVars);
	}

	private calculateDynamicSizes(mainMetricSize: number, smallAfixes: boolean): GaugeVariables {
		const metricFontSize = mainMetricSize;
		const smallerMetricAffix = ( mainMetricSize - 32 < 16 ) ? 16 : mainMetricSize - 32;
		const metricAffixFontSize = smallAfixes ? smallerMetricAffix : mainMetricSize;
		//Comparison metric value font size is the next multiple of 8 above (Main metric font / 2)
		const comparisonFontSize = 8 * Math.ceil(mainMetricSize / 2 / 8);
		const smallerComparisonAffix = ( comparisonFontSize - 32 < 16 ) ? 14 : comparisonFontSize - 32;
		const comparisonAffixFontSize = smallAfixes ? smallerComparisonAffix : comparisonFontSize;
		//Delta value is 16px, unless comparison metrics become 16px. In that case delta is 14px
		const deltaFontSize = comparisonFontSize > 16 ? 16 : 14;
		//Vertical distance between gauge and comparisons or between comparisons is the immediate multiple of 8 below ((Main metric font-8)/ 2)
		const rawVerticalSpacing = 8 * Math.floor((mainMetricSize - 8) * 0.75 / 8);
		const verticalSpacing = rawVerticalSpacing > 24 ? rawVerticalSpacing : 24;
		//Horizontal between gauge and comparisons or between comparisons is the same height in pixels as main metric font
		const horizontalSpacing = mainMetricSize;
		const containerMargin = GaugeDefaults.margin;

		return {
			metricFontSize,
			metricAffixFontSize,
			comparisonFontSize,
			comparisonAffixFontSize,
			deltaFontSize,
			verticalSpacing,
			horizontalSpacing,
			containerMargin
		};
	}
}
