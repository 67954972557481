import { Dashboard } from './dashboard';
export class Book extends Dashboard {
	tabs: IBookTab[];
	ownerName: string;
}

export enum BookTabType {
	DASHBOARD = 'DASHBOARD',
	EMBED = 'EMBED',
}

export interface BookDashboardTab {
	dashboardId: number;
}
export interface BookEmbedTab {
	embedDashboardId: string;
}

export interface IBookTab {
	name: string;
	type: BookTabType;
	properties: BookDashboardTab | BookEmbedTab;
	active?: boolean;
}
