import { HttpParams } from '@angular/common/http';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { CxRequestConfig } from '@app/core/http/cx-request-config';
import { NotAny } from '@app/core/if-type.type';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { CacheOptions } from '@cxstudio/common/cache-options';

type BasicType = string | boolean | number;

// simple object is a 1-level
interface SimpleObject {
	[key: string]: BasicType;
}

type BasicTypeOrSimpleObject = BasicType | SimpleObject;

interface MaxTwoLevelObject {
	[key: string]: BasicTypeOrSimpleObject | BasicTypeOrSimpleObject[];
}

export class CxHttpUtils {
	static optionsToConfig<P extends HttpParams>(options?: CxHttpRequestOptions<P>): CxRequestConfig {
		if (!options) return undefined;
		let config: CxRequestConfig = {
			headers: options.headers,
			local: options.local,
			ignoreConnectionErrors: options.ignoreConnectionErrors,
		};
		if (!_.isUndefined(options.params)) {
			config.params = options.params;
			config.paramSerializer = params => params.toString();
		}
		if (!_.isUndefined(options.assetParams)) {
			config.assetParams = options.assetParams;
		}
		if (!_.isUndefined(options.responseType)) {
			config.responseType = options.responseType;
		}
		if (!_.isUndefined(options.transformResponse)) {
			config.transformResponse = options.transformResponse;
		}
		return config;
	}

	static getAssetRequestOptions(
		assetParams: RequestAssetParameters,
		cache = CacheOptions.CACHED,
	): CxHttpRequestOptions<HttpParams> {
		return { assetParams, local: true, cache };
	}

	/**
	 * This accepts basic types, simple objects, or objects with arrays of simple objects
	 * */
	static objectToParams<T extends MaxTwoLevelObject>(object: NotAny<T>): HttpParams {
		if (_.isEmpty(object))
			return new HttpParams();
		let paramsMap: {[key: string]: string | string[]} = {};
		Object.keys(object).forEach(key => {
			let value = object[key];
			if (_.isArray(value)) {
				paramsMap[key] = value.map(item => this.serializeObject(item));
			} else if (value !== null && value !== undefined) {
				paramsMap[key] = this.serializeObject(value);
			}
		});
		return new HttpParams({fromObject: paramsMap});
	}

	private static serializeObject(object: BasicTypeOrSimpleObject): string {
		if (_.isObject(object))
			return JSON.stringify(object);
		else return object.toString();
	}


}
