import { DependenciesDialogInput } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { MetricEditorDialogOutput } from '@app/modules/metric/editor/metric-editor-dialog-output.interface';
import { MetricEditorUtilsService } from '@app/modules/metric/editor/metric-editor-utils.service';
import { MetricDependencyTypesProvider } from '@app/modules/metric/services/metric-dependency-types-provider';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { BulkService } from '@app/shared/services/bulk.service';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { Security } from '@cxstudio/auth/security-service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { NameService } from '@cxstudio/common/name-service';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricFolderApi } from '@cxstudio/metrics/api/metric-folder-api.service';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { MetricManagementController } from '@cxstudio/metrics/metric-management.component';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { RenameService } from '@cxstudio/services/rename-service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { SharingParams, SharingService } from '@cxstudio/sharing/sharing-service.service';
import * as _ from 'underscore';
import { ScorecardMetric } from '../projects/scorecards/entities/scorecard-metric';
import { Metric } from './entities/metric.class';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ScorecardMetricsManagementService } from '@app/modules/scorecards/metrics/scorecard-metrics-management.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import { AlertLevel } from '@clarabridge/unified-angular-components';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';

export interface IMetricActionsService {
	renameFolder(folder: any): void;
	removeFolder(folder: any): void;
	renameMetric(metric: Metric): void;
	createMetric(metric?: Metric, folder?: IFolderItem): void;
	createFolder(folder: any): void;
	moveToFolder(metric: Metric, folderTo: IFolderItem): void;
	editPredefinedMetric(metric: Metric): void;
	viewMetric(metric: Metric): void;
	toggleHide(metric: Metric): void;
	editMetric(metric: Metric): void;
	saveMetricAsTemplate(metric: Metric): void;
	shareMetrics(metrics: Metric[]): any;
	deleteMetric(metric: Metric): void;
	duplicateMetric(metric: Metric): void;
	bulkDeleteMetrics(metrics: Metric[]): void;
	metricModal(metric: Metric, mode: MetricMode, folders?: any[], selectedFolder?: IFolderItem): any;
	showDependencies(metric: Metric): void;
	bulkTransferMetrics(metrics: Metric[], projectIdentifier: AccountOrWorkspaceProject): Promise<void>;
	bulkUpdateLabels(): NgbModalRef;
	toggleScorecardMetricState(scorecardMetric: ScorecardMetric, newState: boolean): void;
	updateLabelsRequest(updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any>;
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('MetricActionsService', function(
	FolderService,
	security: Security,
	metricApiService: MetricManagementApiService,
	metricFolderApi: MetricFolderApi,
	downgradeDialogService: DowngradeDialogService,
	sharingService: SharingService,
	locale: ILocale,
	renameService: RenameService,
	transferApiService: TransferApiService,
	gridUpdateService: GridUpdateService,
	cachedHttpService: CachedHttpService,
	nameService: NameService,
	treeService: TreeService,
	metricDependencyTypesProvider: MetricDependencyTypesProvider,
	globalNotificationService: GlobalNotificationService,
	securityApiService: SecurityApiService,
	scorecardMetricsManagementService: ScorecardMetricsManagementService,
	bulkService: BulkService,
	metricsService: MetricsService,
	metricEditorUtils: MetricEditorUtilsService,
	betaFeaturesService: BetaFeaturesService,
	assetTemplateApi: AssetTemplateApiService,
	downgradeToastService: DowngradeToastService
) {
	return class MetricActionsService implements IMetricActionsService {
		private scope: MetricManagementController;
		private folderSvc: IFolderService;

		constructor(scope: MetricManagementController) {
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.METRIC);
		}

		renameFolder(folder: any): void {
			this.folderSvc.renameFolder(folder, this.scope.metricList).then(() => {
				folder.displayName = folder.name;
				this.scope.refreshGrid(folder);
			}).catch(_.noop);
		}

		removeFolder(folder: any): void {
			this.folderSvc.removeFolder(folder, this.scope.metricList)
				.then((children) => this.scope.refreshGrid(children))
				.then(() => globalNotificationService.deleteFolderNotification(folder.name));
		}

		createFolder(folder: any): void {
			this.folderSvc.createFolder(folder,
				this.scope.metricList,
				WorkspaceTransitionUtils.getWorkspace(this.scope.project))
			.then((created) => {
				this.scope.refreshGrid([folder, created]);
			});
		}

		renameMetric(metric: any): void {
			let type = locale.getString('metrics.metric');
			let metricItem = {
				name: metric.displayName,
				description: metric.description,
				type: metric.type
			};
			let itemList = this.scope.metricList.filter((item) => !/.*folder.*/i.test(item.type) );
			let renameModal = renameService.modal(metricItem, itemList, type);

			renameModal.result.then((props) => {
				metric.displayName = props.name;
				metric.description = props.description;

				metricApiService.renameMetric(this.scope.metricList, metric).then(() => {
					this.scope.refreshGrid(metric);
				}).then(() => globalNotificationService.addItemSavedNotification(metric.displayName));
			}).catch(_.noop);
		}

		createMetric = (metric: Metric, folder?: IFolderItem): void => {
			let metricProject: AccountOrWorkspaceProjectData = this.scope.project;

			if (!metric && !WorkspaceTransitionUtils.isProjectSelected(metricProject)) {
				this.scope.errors.noProjectSelected = true;
				return;
			}

			if (metric) {
				metricProject = this.getMetricProject(metric);
			}

			const folders = this.getFoldersForMetric({});
			let metricModal = this.metricModal(metric, MetricMode.CREATE, folders, folder);
			metricModal.then((result) => {
				const projectData = WorkspaceTransitionUtils.getProjectData(metricProject);
				let metricValues = $.extend(result.result, projectData, { type: ReportAssetType.METRIC_STUDIO });

				if (metric) {
					metricApiService.duplicateMetric(metricValues, metric.id)
						.then(this.scope.addDuplicatedMetric);
				} else {
					metricApiService.createMetric(metricValues).then((newMetric) => {
						this.scope.addNewMetric(newMetric);
						if (result.doShare)
							this.shareMetrics([newMetric]);
					});
				}
			}, _.noop);
		}

		private getMetricProject(metric: Metric): AccountOrWorkspaceProjectData {
			if (betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
				return _.extend({}, metric.workspaceProject, metric.projectName) ;
			} else {
				return {
					contentProviderId: metric.contentProviderId,
					accountId: metric.accountId,
					projectId: metric.projectId,
					projectName: metric.projectName
				};
			}
		}

		private getFoldersForMetric = (metric: any): any => {
			return this.folderSvc.getFoldersForMove(this.scope.metricList, metric, _.noop, true).map((folderMeta) => {
				return folderMeta.obj;
			});
		}

		editPredefinedMetric(metric: any): void {
			if (!this.canEditPredefinedMetric()) return;

			let metricModal = this.metricModal(metric, MetricMode.EDIT_AND_SHARE);
			metricModal.then(response => {
				let metricValues = $.extend({}, metric, response.result);
				metricApiService.updatePredefinedMetric(metricValues).then(res => {
					let oldMetric = _.findIndex(this.scope.metricList, {id: metric.id});
					$.extend(metric, res.data);
					this.scope.metricTransformer(metric);
					if (oldMetric >= 0) {
						this.scope.metricList[oldMetric] = metric;
					} else {
						this.scope.metricList.push(metric);
					}
					this.scope.refreshGrid(metric);
				})
				.then(() => globalNotificationService.addItemSavedNotification(locale.getString(metric.displayName)));
			}, _.noop);
		}

		viewMetric(metric: any): void {
			this.metricModal(metric, MetricMode.VIEW).catch(_.noop);
		}

		moveToFolder(metric: any, folderTo: IFolderItem): void {
			if (!folderTo) {
				return;
			}

			let parent = metric.parent;
			metricFolderApi.moveToFolder(metric, folderTo.id as number).then(() => {
				if (!folderTo.id || folderTo.id === 0) {
					folderTo = null;
				}

				metric.parentId = folderTo ?
					folderTo.id :
					undefined;
				treeService.moveItem(this.scope.metricList, metric, folderTo);

				let refreshItems = [metric, folderTo, parent];
				refreshItems = refreshItems.concat(this.traverseFolderChain(parent));
				refreshItems = refreshItems.concat(this.traverseFolderChain(metric));

				this.scope.refreshGrid(refreshItems);
			})
			.then(() => globalNotificationService.addItemMovedNotification(metric.displayName, folderTo));
		}

		private traverseFolderChain = (item): any[] => {
			let levels = [];
			let currentLevel = item ? item.parent : undefined;
			while (currentLevel) {
				levels.push(currentLevel);
				currentLevel = currentLevel.parent;
			}
			return levels;
		}

		toggleHide(item: any): void {
			item.hide = !item.hide;
			let key = item.id.toString();
			securityApiService.hideObjectForUser(HiddenItemType.METRICS, item.hide, key, item.displayName)
				.then(() => globalNotificationService.addVisibilityChangedNotification(item.displayName, item.hide));
			if (item.hide) {
				//if it is being hidden, add tag to grey it out
				TaggingHelper.tag(item, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenMetrics[key] = true;
			} else {
				TaggingHelper.untag(item, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenMetrics[key] = false;
			}
			cachedHttpService.cache(Caches.METRICS).invalidate();
			this.scope.selectionUtils.clearSelections();
		}

		editMetric(metric: any): void {
			const folders = this.getFoldersForMetric(metric);
			let canEditMetric = (this.isOwner(metric) || this.canEditMetric(metric)) && this.canCreateMetric();
			if (!canEditMetric)
				return;

			let metricModal = this.metricModal(metric, MetricMode.EDIT_AND_SHARE, folders);
			metricModal.then((result) => {

				let metricValues = $.extend(result.result, {
					contentProviderId: metric.contentProviderId,
					accountId: metric.accountId,
					projectId: metric.projectId,
					projectName: metric.projectName,
					type: 'metricStudio'
				});

				metricApiService.updateMetric(metricValues).then((response) => {
					let oldMetric = _.findIndex(this.scope.metricList, {id: metric.id});
					let updatedMetric = response.data;
					angular.extend(metric, metricValues);
					metric = $.extend(metric, updatedMetric);
					this.scope.metricTransformer(metric);
					this.scope.metricList[oldMetric] = metric;
					const targetFolder = this.scope.metricList.find(f => f.id === metricValues.parentId);
					treeService.moveItem(this.scope.metricList, metric, targetFolder);
					this.scope.refreshGrid(metric);

					if (result.doShare) {
						this.shareMetrics([metric]);
					}
				})
				.then(() => globalNotificationService.addItemSavedNotification(metric.displayName));
			}, _.noop);
		}

		saveMetricAsTemplate(metric: Metric): void {
			downgradeDialogService.openMetricTemplateCreationModal(metric).then(result => {
				const templateName = result?.name;
				this.scope.loading.promise = PromiseUtils.old(assetTemplateApi.createTemplate(TemplateAssetType.METRIC,
					metric.id, result)).then(res => {
						downgradeToastService.addToast(locale.getString('common.itemAdded', { itemName: templateName }), AlertLevel.SUCCESS);
				});
			}, _.noop);
		}

		private canEditMetric = (metric: Metric): boolean => {
			return AssetPermission.EDIT === metric.permissionLevel;
		}

		bulkUpdateLabels = (): NgbModalRef => {
			return downgradeDialogService.openBulkUpdateLabelsModal({
				itemsName: locale.getString('object.metrics').toLowerCase()
			});
		}

		updateLabelsRequest = (updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any> => {
			return metricApiService.updateLabels(updateLabelsEntity);
		}

		shareMetrics(metricItems: any): ng.IPromise<void> {
			let metrics = Array.isArray(metricItems) ? metricItems : [metricItems];
			const isBulk = metrics.length > 1;
			let modalTitle = isBulk
				? locale.getString('metrics.bulkShareTitle')
				: locale.getString('metrics.shareTitle', {name: metrics[0].displayName});

			let params: SharingParams = {
				modalTitle,
				updateReshare: this.updateReshareFlag,
				share: metricApiService.shareMetrics,
				getAsset: (id) => metricApiService.getMetric(id, false).then(resp => resp.data),
				refreshGrid: (changed) => this.scope.refreshGrid(changed as Metric[]),
				component: 'metricSharing',
				objectTypePlural: 'metrics',
			};

			return sharingService.shareWithDialog(metrics, params);
		}

		deleteMetric(metric: any): void {
			let escapedMetricName: string = bulkService.getEscapedName(metric.displayName);
			let confirmationText = {
				title: locale.getString('common.deleteObjectTitle', {objectName: escapedMetricName}),
				warning: locale.getString('common.deleteObjectText', {objectName: escapedMetricName})
			};

			gridUpdateService.delete([].concat(metric), this.scope.metricList, metricApiService.deleteMetrics, confirmationText).then(() => {
				this.scope.refreshGrid([]);
			})
			.then(() => globalNotificationService.addDeletedNotification(escapedMetricName), () => {});
		}

		bulkDeleteMetrics(metrics: any[]): void {
			let confirmationText = {
				title: locale.getString('metrics.bulkDeleteTitle'),
				warning: locale.getString('metrics.bulkDeleteText',
				{
					count: metrics.length,
					namesList: bulkService.getNamesList(metrics, 'displayName')
				})
			};

			gridUpdateService.delete(metrics, this.scope.metricList, metricApiService.deleteMetrics, confirmationText).then(() => {
				metrics.forEach((metric) => {
					if (metric.parent) {
						metric.parent.selected = false;
					}
					globalNotificationService.addDeletedNotification(bulkService.getEscapedName(metric.displayName));
				});

				this.scope.refreshGrid(metrics);
			}, () => {});
		}

		duplicateMetric(metric: any): void {
			let newMetric = angular.copy(metric);
			newMetric.hide = false;
			delete newMetric.parent;
			delete newMetric.parentId;

			let promise = this.scope.removeUnownedFilters(newMetric);
			promise.then((hasUnownedFilters) => {
				this.updateToUniqueName(newMetric).then(updatedMetric => {
					if (hasUnownedFilters) {
						return this.createMetric(updatedMetric);
					} else {
						return metricApiService.duplicateMetric(updatedMetric, updatedMetric.id).then(result => {
							this.scope.addDuplicatedMetric(result);
						});
					}
				});
			});
		}

		metricModal(metric: Metric, mode: MetricMode, folders?: any[], selectedFolder?: IFolderItem): Promise<MetricEditorDialogOutput> {
			return metricEditorUtils.openMetricModal(
				this.scope.project, metric, mode, folders, selectedFolder, this.scope.metricEditors);
		}

		showDependencies(metric: any): void {
			let input: DependenciesDialogInput<StudioAsset> = {
				asset: {
					assetId: metric.id,
					name: metric.displayName
				} as any,
				dependencyTypesProvider: metricDependencyTypesProvider
			};

			downgradeDialogService.openDependenciesModal(input);
		}

		bulkTransferMetrics(transferredMetrics: any[], projectIdentifier: AccountOrWorkspaceProject): Promise<void> {
			return downgradeDialogService.openBulkTransferDialog({
				selectedObjects: transferredMetrics,
				mode: TransferGroup.METRICS,
				projectIdentifier
			}).result.then(result => {
				this.scope.loading.metricLoadingPromise = transferApiService.makeTransfer(result) as unknown as ng.IPromise<void>;
				if (!result.retainEditPermission) {
					this.removeTransferredMetrics(transferredMetrics);
				} else {
					this.updateTransferredMetrics(result, transferredMetrics);
				}
				this.scope.refreshGrid(this.scope.metricList);
			});
		}
		toggleScorecardMetricState(scorecardMetric: ScorecardMetric, newState: boolean): void {
			scorecardMetricsManagementService.updateDisableState(scorecardMetric, newState).then(() => {
				scorecardMetric.disabled = newState;
				this.scope.refreshGrid(scorecardMetric as unknown as Metric);
			});
		}

		private removeTransferredMetrics = (transferredMetrics) => {
			let transferredMetricsIds = _.pluck(transferredMetrics, 'id');
			this.scope.metricList = _.reject(this.scope.metricList, metric => {
				return _.contains(transferredMetricsIds, metric.id);
			});
		}

		private updateTransferredMetrics = (result, transferredMetrics) => {
			let masterAccountItems = result.transferItems;
			transferredMetrics.forEach(transferredMetric => {
				let currentMasterAccountFilters: any[] = masterAccountItems[transferredMetric.masterAccountId][TransferGroup.METRICS];
				let newOwnerId = _.findWhere(currentMasterAccountFilters, {id: transferredMetric.id}).selectedCandidateId;
				transferredMetric.ownerId = newOwnerId;
				transferredMetric.sharingStatus = 'SHARED';
				transferredMetric.permissionLevel = 'EDIT';
			});
		}

		private isOwner(metric): boolean {
			return !metric.ownerName || security.isCurrentUser(metric.ownerName);
		}

		private canCreateMetric(): boolean {
			return security.has(MasterAccountPermissionAction.CREATE_METRIC);
		}

		private canEditPredefinedMetric(): boolean {
			return security.has('manage_settings') && this.canCreateMetric();
		}

		private updateToUniqueName(metric: Metric): ng.IPromise<Metric> {
			const metricProject = this.getMetricProject(metric);
			const workspace = WorkspaceTransitionUtils.getWorkspace(metricProject);
			return PromiseUtils.old(metricsService.getMetricsForWorkspace(workspace))
				.then((result) => {
					let baseName = `${metric.displayName} - ${locale.getString('dashboard.copyName')}`;
					metric.displayName = nameService.uniqueName(baseName, result, 'displayName');
					return metric;
			});
		}

		private updateReshareFlag(metric: any, reshareAllowed: boolean): void {
			metric.reshareAllowed = reshareAllowed;
			metricApiService.updateMetricReshareFlag(metric.id, reshareAllowed);
		}

	};
});
