import { ConversationChartOptions } from './conversation-chart-options.class';
import { SizingStrategy } from './sizing/sizing-strategy.class';

export class PlaybackIndicator {
	private config: ConversationChartOptions;
	playbackIndicator;
	private width: number;
	private svg;

	constructor(config, width: number, rootSvg) {
		this.config = config;
		this.width = width;
		this.svg = rootSvg;

		this.playbackIndicator = this.svg.append('path')			
			.attr('stroke', this.config.playbackIndicator.color)
			.attr('stroke-width', this.config.playbackIndicator.size)
			.attr('opacity', this.config.playbackIndicator.opacity)
			.attr('d', `M 0 0 L ${this.width} 0`)
			.attr('z-index', -1);
	}

	update = (yPosition: number) => {
		this.playbackIndicator
			.transition(this.config.animationTransition)
			.attr('d', `M 0 ${yPosition} L ${this.width} ${yPosition}`);
	}
}