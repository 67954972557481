import { CxEvent, DashboardEvent, GridsterEvent, WidgetEvent, WidgetLocalEvent } from '@app/core/cx-event.enum';
import { CxLocalEvent } from '@app/core/local-event-bus.service';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { CloudNavigation } from '@app/modules/keyboard-navigation/cloud-navigation.service';
import { ContentWidgetKeyboardNavigationUtils } from '@app/modules/widget-container/content-widget-kb-navigation-utils.service';
import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { VisualizationResolverService } from '@app/modules/widget-visualizations/visualization-resolver.service';
import { DevToolsService } from '@app/shared/services/dev-tools.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import { TemplateWidgetUtils } from '@cxstudio/dashboard-templates/template-widget-utils';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { HighchartsKeyboardUtils } from '@cxstudio/reports/widget-utils/highcharts-keyboard-utils.class';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import { TextWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';



interface IWidgetScope {
	dashboardData: IDashboardData;
	widget: Widget;
	widgetMode: WidgetDisplayMode;
	widgetsEditService: WidgetsEditService;
	widgetActions: IWidgetActions;
}

// bypassing events go directly into nested components
const BYPASSING_EVENTS = [];

// dashboard events are filtered per dashboardId, and then passed down
const DASHBOARD_EVENTS: Array<{ dashboardEvent: CxEvent, widgetEvent: CxEvent }> = [
	{ dashboardEvent: DashboardEvent.REFRESH, widgetEvent: WidgetLocalEvent.REFRESH },
	{ dashboardEvent: DashboardEvent.HARD_REFRESH, widgetEvent: WidgetLocalEvent.HARD_REFRESH }
];
const WIDGET_EVENTS: Array<{ globalEvent: CxEvent, widgetEvent: CxEvent }> = [
	{ globalEvent: GridsterEvent.WIDGET_RESIZED, widgetEvent: WidgetLocalEvent.RESIZED },
];
const WIDGET_BUBBLE_EVENTS: Array<{ widgetEvent: CxEvent, globalEvent: CxEvent }> = [
	{ widgetEvent: WidgetLocalEvent.REFRESH_FINISHED, globalEvent: WidgetEvent.REFRESH_FINISHED_GLOBAL },
];

export class WidgetItemController implements ng.IController {
	widget: Widget;
	baseWidget: Widget;
	widgetMode: WidgetDisplayMode;
	editMode: boolean;
	itemInitialized: boolean;
	dashboardData: IDashboardData;
	layout: LayoutHelper;

	widgetActions: IWidgetActions;

	dropdownContainer: ng.IAugmentedJQuery;

	// this is a temporary "bridge" between angularjs and angular events
	// will be moved to the upper component once this is converted to angular
	private readonly eventBridgeSubject = new Subject<CxLocalEvent>();
	readonly eventBridge$ = this.eventBridgeSubject.asObservable();

	constructor(
		private $scope: IWidgetScope & ISimpleScope,
		private $element: ng.IAugmentedJQuery,
		private readonly widgetsEditService: WidgetsEditService,
		private readonly security: Security,
		private readonly locale: ILocale,
		private readonly cloudNavigation: CloudNavigation,
		private readonly keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper,
		private readonly devTools: DevToolsService,
		private readonly visualizationResolverService: VisualizationResolverService,
		private readonly eventEmitterService: EventEmitterService,
		private readonly widgetVisibilityMonitor: WidgetVisibilityMonitorService,
		private readonly betaFeaturesService: BetaFeaturesService
	) {}

	$onInit(): void {
		this.$scope.widget = this.widget;
		this.$scope.dashboardData = this.dashboardData;
		this.$scope.widgetsEditService = this.widgetsEditService;
		this.widgetActions = this.widgetActions || this.widgetsEditService.getDefaultWidgetActions(this.widget);
		this.$scope.widgetActions = this.widgetActions;
		this.$scope.widgetMode = this.widgetMode;

		this.dropdownContainer = this.$element.parents('.gridster-wrapper');
		if (this.dropdownContainer.length === 0) {
			this.dropdownContainer = angular.element('body');
		}

		if (this.shouldOverrideKeyboardNavigation()) {
			this.overrideKeyboardNavigation();
		}

		this.initEventBridge();
		this.widgetVisibilityMonitor.observeWidgets(this.widget);
	}

	onContentOrExternalWidgetFocus(): void {
		if (ContentWidgetKeyboardNavigationUtils.isContentNotPageBreakWidget(this.widget)) {
			this.eventEmitterService.emit(ContentWidgetKeyboardNavigationUtils.generateEventType(this.widget), true);
		}
	}

	onContentOrExternalWidgetBlur(event: FocusEvent): void {
		if (ContentWidgetKeyboardNavigationUtils.isContentNotPageBreakWidget(this.widget)) {
			this.eventEmitterService.emit(
				ContentWidgetKeyboardNavigationUtils.generateEventType(this.widget),
				ContentWidgetKeyboardNavigationUtils.showHeaderOnContainerBlur(event)
			);
		}
	}

	private initEventBridge(): void {
		_.each(BYPASSING_EVENTS, eventType => {
			this.$scope.$on(eventType, () => this.eventBridgeSubject.next({ type: eventType }));
		});

		_.each(DASHBOARD_EVENTS, eventData => {
			this.$scope.$on(eventData.dashboardEvent, (event, dashboardId: number, ...args: any[]) => {
				if (!dashboardId || this.widget.dashboardId === dashboardId) {
					this.eventBridgeSubject.next({ type: eventData.widgetEvent, args });
				}
			});
		});

		_.each(WIDGET_EVENTS, eventData => {
			this.$scope.$on(eventData.globalEvent, (event, widgetIds: number[], ...args: any[]) => {
				if (_.isEmpty(widgetIds) || _.contains(widgetIds, this.widget.id)) {
					this.eventBridgeSubject.next({ type: eventData.widgetEvent, args });
				}
			});
		});
	}

	onChildEmit = (event: CxLocalEvent): void => {
		let mapping = _.findWhere(WIDGET_BUBBLE_EVENTS, { widgetEvent: event.type });
		if (mapping) {
			this.$scope.$emit(mapping.globalEvent, this.widget);
		} else {
			this.$scope.$emit.apply(this.$scope, [event.type].concat(event.args));
		}
	}

	headerClick = (event: MouseEvent) => {
		if (this.devTools.isDevClickEvent(event)) {
			this.devTools.logWidgetDescription(this.widget);
		}
	}

	// Cloud is d3 widget
	private shouldOverrideKeyboardNavigation(): boolean {
		return this.keyboardNavigationDrillHelper.isHighchartsWidget(this.widget.name)
			&& !ReportConstants.isNetworkWidget(this.widget.name as WidgetType)	// will be supported after https://clarabridge.atlassian.net/browse/CB-17452
			&& !ReportConstants.isCloudWidget(this.widget.name as WidgetType); 	// will be supported later
	}

	// highcharts suppresses tabs and shift-tabs in bubbling phase
	private overrideKeyboardNavigation(): void {
		let keyboardNavigationFunction: (event: any) => void = (event: any) => {
			let targetClasses: DOMTokenList = event.target.classList;
			if (targetClasses.contains(HighchartsKeyboardUtils.HIGHCHARTS_POINT_CLASS)) {
				HighchartsKeyboardUtils.processNavigationFromPoint(event, this.widget.id, this.widget.name as WidgetType);
			} else if (targetClasses.contains(HighchartsKeyboardUtils.HIGHCHARTS_LEGEND_BUTTON_CLASS)) {
				HighchartsKeyboardUtils.processNavigationFromLegend(event, this.widget.id, this.processWidgetEnter);
			}
		};

		let widgetItemElement = this.$element[0];
		widgetItemElement.addEventListener('keydown', keyboardNavigationFunction, true);
		this.$scope.$on('$destroy', () => {
			widgetItemElement.removeEventListener('keydown', keyboardNavigationFunction, true);
		});
	}

	isRegularMode = (): boolean => {
		return this.widgetMode !== WidgetDisplayMode.EMBEDDED
			&& this.widgetMode !== WidgetDisplayMode.ADHOC
			&& this.widgetMode !== WidgetDisplayMode.HOME_PAGE;
	}

	getMenuClasses = (): string => {
		if (this.widgetMode === WidgetDisplayMode.HOME_PAGE)
			return 'home-page-widget';
		if (this.widgetMode === WidgetDisplayMode.ADHOC)
			return 'adhoc-widget';
		return '';
	}

	isEnterpriseUser = (): boolean => {
		return this.security.loggedUser.isEnterpriseUser;
	}

	isLegacyWidget = () => {
		return ReportConstants.isLegacyWidget(this.widget);
	}

	isTemplateWidget = () => {
		return TemplateWidgetUtils.isDisplayedAsTemplate(this.widget);
	}

	getFilterAriaLabel = (): string => {
		if (this.widget.name === WidgetType.PREVIEW) {
			let title = this.widget.displayName;
			return this.locale.getString('preview.filterMenuAriaLabel', { title });
		} else {
			return null;
		}
	}

	getWidgetAriaLabel = (): string => {
		if (this.widget.ariaLabel) {
			return this.widget.ariaLabel;
		}

		return this.locale.getString('widget.widgetAriaLabel', { title: this.widget.displayName });
	}

	isNgWidgetContent = (widget: Widget): boolean => {
		return this.visualizationResolverService.isNgWidgetContent(widget);
	}

	isLegacyWidgetContent = (widget: Widget): boolean => {
		return !!widget.visualProperties.visualization && !this.isNgWidgetContent(widget);
	}

	hasIntersection = (widget): boolean => {
		return isTrue(this.editMode) && isTrue(widget.intersectPageBreak);
	}

	processHitOnWidget = (event) => {
		if (event.key === Key.TAB) {
			this.processWidgetTab(event);
		} else if (event.key === Key.ENTER) {
			event.preventDefault();
			this.processWidgetEnter();
		}
	}

	isEmptyTextWidget = () => {
		if (this.widget.properties.widgetType === WidgetType.TEXT) {
			let props = this.widget.properties as TextWidgetProperties;
			return !props.text || props.text === '<p></p>';
		}
		return false;
	}

	getWidgetMenuClass = () => {
		if (!this.editMode) {
			return {'pointer-events-children-none': this.layout.zoomLevel};
		}
		return '';
	}

	private isCouldGoToNextWidget(target: ng.IAugmentedJQuery): boolean {
		return target.hasClass('.bottom-chart') || this.isWidgetWithoutHeader(target) || this.isLastWidgetMenu(target);
	}

	private isWidgetWithoutHeader(target: ng.IAugmentedJQuery): boolean {
		return target.hasClass('br-widget-box') && !target.find('.br-widget-header').length;
	}

	private isLastWidgetMenu(target: ng.IAugmentedJQuery): boolean {
		return target.hasClass('widget-header-menu')
			&& target[0] === $(`#widget-${this.widget.id} .br-widget-header .widget-header-menu`).last()[0];
	}

	private readonly processWidgetEnter = (): void => {
		let flipper = $(`#widget-${this.widget.id} .flipper`);
		if (flipper.length) {
			if (this.widget.name === WidgetType.CLOUD) {
				this.cloudNavigation.focusFirstWord(`#widget-${this.widget.id} .widget-main-content`);
			} else {
				(flipper.parent().find(`.flip>.back :focusable,:not(.flip)>.front :focusable`).first()[0] as HTMLElement).focus({
					preventScroll: true
				});
			}
		} else {
			($(`#widget-${this.widget.id} .widget-main-content :focusable`).first()[0] as HTMLElement).focus({
				preventScroll: true
			});
		}
	}

	private handleCloudFooterShiftTab(event: KeyboardEvent, elements, currentElement): void {
		let previousIndex = elements.index(currentElement) - 1;
		let previousElement = previousIndex > -1 && elements[previousIndex];
		if (this.widget.name === WidgetType.CLOUD && $(previousElement).hasClass('highcharts-exit-anchor')) {
			event.stopPropagation();
			event.preventDefault();
			this.cloudNavigation.focusFirstWord(`#widget-${this.widget.id} .widget-main-content`);
		}
	}

	private processWidgetTab(event: KeyboardEvent): void {
		let activeElement = document.activeElement;

		//widget global navigation (box and header)
		let widgetBox = $(`#widget-${this.widget.id} :tabbable`).first()[0];
		if (this.isCouldGoToNextWidget($(activeElement as HTMLElement)) && !event.shiftKey) {
			this.focusWidget(event, 1);
			return;
		} else if (activeElement === widgetBox && event.shiftKey) {
			this.focusWidget(event, -1);
			return;
		}

		//widget inner navigation (body and footer)
		if (WidgetTypeFilters.isContentWidget(this.widget)) {
			// for content we just use default behavior
			return;
		}
		let focusableWidgetElements = $(`#widget-${this.widget.id} .br-widget-box`)
			.children(':not(.br-widget-header)')
			.find(':tabbable');
		KeyboardUtils.cycleNavigation(event, focusableWidgetElements, activeElement);
		if (event.shiftKey) {
			this.handleCloudFooterShiftTab(event, focusableWidgetElements, activeElement);
		}
	}

	private focusWidget(event, dir: number): void {
		// this is bad. Need to emit event to a higher level, which has access to widgets and can easily identify next one
		let widgetId = this.widget.id;
		let widgets = $(this.getWidgetsSelector()).toArray().filter(element => {
			return !$(element).find('.ignore-widget-navigation').length;
		});
		if (!widgets.length) {
			return;
		}
		let getInt = (str) => {
			return parseInt(str.substring(0, str.length - 2), 10) || 0;
		};
		widgets.sort((w1node, w2node) => {
			let w1 = $(w1node);
			let w2 = $(w2node);
			let top1 = getInt(w1.css('top'));
			let top2 = getInt(w2.css('top'));
			if (top1 === top2) {
				return getInt(w1.css('left')) - getInt(w2.css('left'));
			}
			return top1 - top2;
		});
		let id = _.findIndex(widgets, widget => $(widget).attr('id') === ('widget-' + widgetId)) + dir;
		if (id >= widgets.length) {
			// skip widget content and use default (browser) tab handler
			$(`#widget-${this.widget.id} :focusable`).last().trigger('focus');
			return;
		} else if (id < 0) {
			return;
		}
		event.preventDefault();
		let pageBreakHeader = $(widgets[id]).find('.PAGE_BREAK .br-widget-header');
		pageBreakHeader.addClass('visible'); // page break doesn't have focusable content to make header visible
		$(widgets[id]).find(':focusable').first().trigger('focus');
		pageBreakHeader.removeClass('visible');
	}

	private getWidgetsSelector(): string {
		// this won't work on pages other than dashboard and home page (e.g. global other, embedded) - check
		if (this.widget.containerId === HomePageWidgetConstants.CONTAINER_ID) {
			return '.custom-widgets .custom-widget';
		}
		let DASHBOARD_WIDGETS_SELECTOR = '.gridster .gridster-item';
		let BOOK_WIDGETS_SELECTOR = '.dashboard-tab-view.active .gridster .gridster-item';
		return $('#active-dashboard-tab').length ? BOOK_WIDGETS_SELECTOR : DASHBOARD_WIDGETS_SELECTOR;
	}
}

app.component('widgetItem', {
	controller: WidgetItemController,
	templateUrl: 'partials/widgets/widget-item.component.html',
	bindings: {
		widget: '<',
		widgetMode: '@?',
		editMode: '<',
		dashboardData: '<',
		widgetActions: '<',
		itemInitialized: '<',
		layout: '<',
		baseWidget: '<'
	}
});
