<div class="grid-list-tools">
	<div class="tools">
		<div class="grid-filters justify-between">
			<label class="font-bold mb-0">
				{{'templates.findDashboardTemplates'|i18n}}
				<input
					type="text"
					class="grid-search-bar d-block"
					attr.aria-label="{{'templates.findDashboardTemplates'|i18n}}"
					[(ngModel)]="searchText"
					[debounce]="300">
			</label>
			<div class="grid-buttons">
				<button *ngIf="canTransferTemplates()"
					(click)="transferTemplates()"
					class="btn btn-primary">
					{{'templates.importExport'|i18n}}
				</button>
				<button
					class="btn btn-primary br-btn-add-work-space"
					*ngIf="canManageTemplates()"
					(click)="addFolder()">
					{{'dashboard.newFolder'|i18n}}
				</button>
			</div>
		</div>
	</div>
</div>

<div [ngBusy]="loading">
	<item-grid
		class="br-grid"
		[treeData]="templates"
		[gridOptions]="gridOptions"
		[gridType]="gridType"
		[gridFilter]="searchText"
		[gridChange]="changedItems"
	></item-grid>
</div>

