import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { IScorecardFilterListItem } from '@app/modules/scorecards/entities/scorecard-filter-list-item';
import { ReportScorecardFiltersApiService } from '@app/modules/scorecards/filters/report-scorecard-filters-api.service';
import { ScorecardFiltersService } from '@app/modules/scorecards/filters/scorecard-filters.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

@Injectable({
	providedIn: 'root'
})
export class ReportScorecardFiltersService {
	constructor(
		private readonly reportScorecardFiltersApi: ReportScorecardFiltersApiService,
		private readonly projectAssetsHelper: ProjectAssetsHelper,
		private readonly scorecardFiltersService: ScorecardFiltersService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	getDashboardScorecardFilters(dashboard: Dashboard): Promise<IScorecardFilterListItem[]> {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]);
		}
		return this.projectAssetsHelper.getDashboardProjectData<IScorecardFilterListItem[]>(
			dashboard,
			(project) => this.scorecardFiltersService.getScorecardFilters(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportScorecardFiltersApi.getFilters(viewParams),
				() => [])
		);
	}

	getWidgetScorecardFilters(widget: Widget): Promise<IScorecardFilterListItem[]> {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]);
		}
		return this.projectAssetsHelper.getWidgetProjectData<IScorecardFilterListItem[]>(
			widget,
			(project) => this.scorecardFiltersService.getScorecardFilters(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportScorecardFiltersApi.getFilters(requestParams),
				projectId => [])
		);
	}
}

app.service('reportScorecardFiltersService', downgradeInjectable(ReportScorecardFiltersService));
