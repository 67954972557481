import { DashboardFilterSelection } from './dashboard-filter-selection';
import { DashboardFilterValue } from './dashboard-filter-value';
import { DashboardFilterMultiValue } from './dashboard-filter-multi-value';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';

export class DashboardFilter {
	isOrgHierarchy?: boolean;
	isDrillToDashboardFilter?: boolean;
	drillableFilter?: DrillFilter;
	selectedAttribute?: DashboardFilterSelection;
	selectedAttributeValue?: DashboardFilterValue;
	node?: string;

	attrValues?: AttributeValueOption[];
	nestedList?: AttributeValueOption[];
	multiValues?: DashboardFilterMultiValue[];

	genericFilterFormat?: IFilterRule; // temporary, to support same UI as other filters // UPD now permanently :)

	locked?: boolean;
}

export class MetadataDashboardFilter extends DashboardFilter {
	ignored?: boolean;
}
