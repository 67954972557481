<modal-header (cancel)="cancel()" [modalTitle]="headerText | i18n"></modal-header>
<section class="modal-body">
	<div [innerHTML]="bodyText | i18n"></div>
</section>
<save-modal-footer
	[cancelText]="cancelText | i18n"
	(cancel)="cancel()"
	(save)="save()"
	(secondaryAction)="dontSave()"
	[saveText]="saveText | i18n"
	[secondaryBtnTxt]="dontSaveText | i18n"
	[isDisabled]="disableSave"
>
</save-modal-footer>
