
import * as uib from 'angular-ui-bootstrap';
import WizardStep from '@cxstudio/wizard/wizard-step';

export default abstract class WizardController {

	private currentStep: number = 0;

	constructor(
		private steps: WizardStep[],
		protected $scope: ng.IScope,
		private $uibModalInstance: uib.IModalInstanceService,
	) {
		this.$scope.getCurrentWizardStepTemplateUrl = (): string => {
			return this.steps[this.currentStep].getTemplateUrl();
		};

		this.$scope.isNextStepAllowed = (): boolean => {
			return this.steps[this.currentStep].isNextStepAllowed();
		};

		this.$scope.isPreviousStepAllowed = (): boolean => {
			return this.steps[this.currentStep].isPreviousStepAllowed();
		};

		this.$scope.next = (): void => {
			this.handleCurrentStep(++this.currentStep);
		};

		this.$scope.back = (): void => {
			this.currentStep--;
		};

		this.$scope.hasNextStep = (): boolean => {
			return this.currentStep < this.steps.length - 1;
		};

		this.$scope.hasPreviousStep = (): boolean => {
			return this.currentStep > 0;
		};

		this.$scope.cancel = (): void => {
			this.$uibModalInstance.dismiss('cancel');
		};

		this.$scope.finish = (): void => {
			this.$uibModalInstance.close();
		};
	}

	protected abstract handleCurrentStep = (index: number): void => { };

}
