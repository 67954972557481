
<div class="page-tab-content br-grid">
	<div class="col-md-12 row" [ngBusy]="loadingUsers">
		<h2 [i18n]="'administration.titleUserTab'"></h2>
		<div class="form-group form-inline align-items-end">
			<user-search-panel
				(onSearchChange)="onSearchChange($event)"
				[searchModel]="userQuery"
				[readyCallback]="initialize"
				[searchOptionFilter]="searchOptionsFilter">
			</user-search-panel>

			<button
				id="btn-export-user" type="button"
				[disabled]="pagedUserDetails.length < 1"
				class="btn btn-secondary"
				(click)="exportUserData()" [i18n]="'administration.exportUser'">
			</button>
		</div>

		<table class="table">
			<thead>
				<tr>
					<th *ngFor="let field of fields" class="cursor-pointer p-relative slick-header-column" (click)="sort(field.field)">
						<span class="slick-column-name">{{field.name}}</span>
						<span
							*ngIf="sortParams.sortField === field.field"
							class="slick-sort-indicator"
							[ngClass]="{
								'slick-sort-indicator-asc': sortParams.sortDirection,
								'slick-sort-indicator-desc': !sortParams.sortDirection
							}"
						></span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let user of pagedUserDetails"
					class="br-user-row" [ngClass]="{'user-disabled': userIsDisabled(user)}">
					<td scope="row"> <a class="br-user-disable-user"
						(click)="disableUserFunction(user)" [i18n]="'administration.disable'"></a></td>
					<td class="br-user-firstName">{{ user.firstName }}</td>
					<td class="br-user-lastName">{{ user.lastName }}</td>
					<td class="br-user-email">{{ user.userEmail }}</td>
					<td class="br-user-licenseTypeName">{{ user.licenseTypeName }}</td>
					<td class="br-user-admin">{{ getAdministratorValue(user) }}</td>
					<td class="br-user-default-ma">
						<div class="br-organization-panel br-defma-panel">
							<div class="br-organization-selector-panel br-defma-selector-panel">
								<simple-dropdown
									id="_t-admin-users-search"
									searchable="true"
									[displayField]="'accountName'"
									[valueField]="'accountId'"
									[(value)]="user.defaultMasterAccountId"
								 	[options]="user.linkedMasterAccounts"
									(onChange)="changeDefaultMA(user)"
								></simple-dropdown>
							</div>
						</div>
					</td>
					<td class="br-user-tags">{{ user.tags.join(', ') }}</td>
					<td class="br-user-xmAccountId">{{ user.xmAccountId }}</td>
					<td>{{ user.xmGlobalUserId }}</td>
				</tr>

			</tbody>
		</table>
		<pagination
			[pullRight]="true"
			[pagination]="pagination"
			(paginationChange)="pageChanged($event)"
		></pagination>
	</div>
</div>
