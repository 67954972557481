import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { ReportGrouping } from './report-grouping';
import { DisplayThreshold } from '@cxstudio/reports/entities/display-threshold';
import { ReportAsset } from './report-asset';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { DateAttributeType } from '../attributes/date-attribute-type';

export class AttributeGrouping extends ReportGrouping implements ReportAsset, INode {
	name: string;
	objectType?: AttributeObjectType;
	standardMetric?: boolean;
	dataType?: string;
	peerReportType?: PeerReportType;
	urlType?: AttributeUrlType;
	tableRowHeight?: number;
	isConfigurable?: boolean;
	capitalization?: CapitalizationType;
	definition?: MetricDefinition;
	displayThreshold?: DisplayThreshold;
	nullInclude?: boolean;
	emptyPeriodType?: EmptyPeriodType;
	_disabled?: boolean;
	wordsList?: string[];
	wordsFilteringMode?: WordsFilteringMode;
	model?: boolean;
	rootNodeId?: number;
	inheritAttributeValues?: boolean;
	hide?: boolean;
	dateAttributeType?: DateAttributeType;

	constructor(name: string) {
		super();
		this.name = name;
	}
}
