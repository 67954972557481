import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms plain text coaching tips by adding HTML tags for paragraphs and bulleted lists.
 * Input example:
 * The agent did well in resolving the customer's issue. However, the agent could have handled the call better by doing the following:
 * * The agent could have offered the customer a discount code for their next order.
 * * The agent could have offered to escalate the issue to a manager.
 */
@Pipe({
	name: 'coachingTips'
})
export class CoachingTipsPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return value;

		// Add an extra entry so that the list always gets terminated.
		const lines = [...value.split('\n'), ''];

		let previousLineWasListItem = false;
		const processedLines = [];

		for (const originalLine of lines) {
			const bulletedListItemRegex = /^(\s)*\*(\s)+/;
			const match = originalLine.match(bulletedListItemRegex);
			if (match) {
				if (!previousLineWasListItem) {
					processedLines.push('<ul class="bullet">');
				}
				processedLines.push(`<li>${originalLine.replace(match[0], '')}</li>`);
			}
			else {
				if (previousLineWasListItem) {
					processedLines.push('</ul>');
				}
				if (originalLine.length) {
					processedLines.push(`<p>${originalLine}</p>`);
				}
			}

			previousLineWasListItem = !!match;
		}

		return processedLines.join('\n');
	}
}
