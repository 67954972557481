import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { DashboardPermissions } from '@cxstudio/dashboards/entity/dashboard-permissions';
import { DashboardFilter } from '../dashboard-filters/dashboard-filter';
import { Label } from './label';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardSnapshotMetadata } from '@cxstudio/dashboards/entity/dashboard-snapshot';

export class Dashboard implements ITreeItem {
	id: number;
	name: string;
	description: string;
	type: DashboardType;
	permissions: DashboardPermissions;
	properties: DashboardProperties;
	workspaceProject: WorkspaceProject;
	ownerName: string;
	appliedFiltersArray: DashboardFilter[];
	userRating?: number;
	rating?: number;
	ratingCount?: number;

	ownerId: any;
	autoSaved: any;
	sharingStatus: any;
	scheduleCount: number;
	favorite: boolean;
	hide?: boolean;
	labels: Label[];
	createdByPinnedFeedback?: boolean;

	previewImageUpdatedDate?: number;
	modifiedDate?: number;

	views?: number;
	useDate?: number;

	selected?: boolean;

	tags?: string[];

	//ui
	parent?: ITreeItem;
	parentId?: number;

	snapshotMetadata?: DashboardSnapshotMetadata;

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}
}
