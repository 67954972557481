<div [ngBusy]="loading" class="pv-16 ph-32">

	<div *ngIf="!!hierarchy">

		<p>{{'organization.uploadFitlersWizardInstructionNew'|i18n}}</p>

		<div class="wrappable-buttons form-group">
			<button
				class="btn btn-secondary"
				title="{{'organization.downloadTemplate'|i18n}}"
				[attr.aria-label]="'organization.downloadTemplate'|i18n"
				(click)="downloadTemplateFile()">
				{{'organization.downloadTemplate'|i18n}}
			</button>
			<button *ngIf="showExistingStructureButton()"
				class="btn btn-secondary"
				title="{{'organization.downloadExistingFilters'|i18n}}"
				[attr.aria-label]="'organization.downloadExistingFilters'|i18n"
				(click)="downloadDataFile()">
				{{'organization.downloadExistingFilters'|i18n}}
			</button>
		</div>

		<div class="wrappable-buttons form-group">
			<file-upload
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.XLS, MediaType.CSV]"
				[uploadButtonLabel]="getUploadButtonLabel()"
				[customUploadFailedMessage]="'organization.wizardFileUploadFailed'|i18n"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(erroneousUpload)="handleFailedUpload($event)"
				(removedUpload)="handleRemovedUpload()"
			></file-upload>
		</div>

	</div>
	
	<upload-result 
		[result]="uploadResult">
	</upload-result>
	
</div>
