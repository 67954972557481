import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { CommonDrillService } from '../common-drill.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';

@Injectable({
	providedIn: 'root'
})
export class DrillToMetricProcessorService extends DefaultDrillProcessor {

	constructor(
		private readonly commonDrill: CommonDrillService
	) {
		super();

		this.addInheritedVisualProperties(CommonInherentProperties.visual);
	}


	drillTo(widget: Widget, drillToType, point: DrillPoint): Widget {
		//replace name and visual
		widget.name = WidgetType.METRIC;
		widget.properties.widgetType = WidgetType.METRIC;
		widget.visualProperties.visualization = WidgetVisualization.CB_AN_METRIC;
		widget.displayName += ' - ' + point.groupBy.displayName;

		//cb-an-table process
		this.commonDrill.processSelectedMetric(point, widget, _.noop);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);

		//add selectedAttributes
		widget.properties.selectedAttributes = [];
		widget.properties.selectedMetrics = [point.groupBy];

		widget = this.inheritPropertiesAndVisProps(widget);

		return widget;
	}
}
