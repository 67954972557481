import { DocumentExplorerEvents } from '@app/modules/document-explorer/events/document-explorer-events';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { IPromise } from 'angular';
import * as _ from 'underscore';


// fakes data for preview document view demo
export class PreviewDemo implements Partial<IDocumentPreviewerControls> {

	private staticData;
	private docIndex: number = 0;
	caseExistStatusLoading: boolean;
	caseExistStatus: boolean;


	switchDocumentHelper = {
		previous: () => {
			this.docIndex -= 1;
			this.loadDocument();
		},
		next: () => {
			this.docIndex += 1;
			this.loadDocument();
		},
		getSelectedIndex: _.noop,
		isPreviousEnabled: () => {
			return this.docIndex > 0;
		},
		isNextEnabled: () => {
			return this.docIndex < (this.sentenceList.length - 1);
		}
	};

	switchVerbatimHelper = this.switchDocumentHelper;

	pageChanged = _.noop;
	getData = _.noop;
	getTotalPages = _.noop;
	resetPagination = _.noop;
	curateDocument = _.noop;
	switchFeedbackSelection = _.noop;

	toggleShowTopics = _.noop;
	toggleExpandTopics = _.noop;

	documents: {} = {};
	selectedSentence: number;
	selectedDocument: number;
	sentenceList: any[];
	selectedAttributes;
	selectedWorldAwareness;
	availableModels;
	availableAttributes;
	narrativeEntries;
	narrativeEnabled: boolean;
	highlighter = {
		hoverModel: _.noop,
		highlightWorldAwareness: _.noop,
		highlightModel: _.noop
	};
	events = new DocumentExplorerEvents();

	constructor(staticData, narrativeEnabled) {
		this.sentenceList = staticData;
		this.narrativeEnabled = narrativeEnabled;
		this.documents = {};
		this.sentenceList.forEach((sentence) => {
			this.documents[sentence.documentId] = {
				id: sentence.documentId,
				sentences: [sentence],
				verbatims: [{
					sentences: [sentence],
					type: 'User post'
				}]
			};
		});
		this.loadDocument();
	}


	loadDocument = () => {
		this.selectedSentence = this.sentenceList[this.docIndex].documentId;
		this.addDocumentAttributes();
	}

	addDocumentAttributes = (): void => {
		this.selectedAttributes = [{
			name: 'restaurant_name',
			displayName: 'Restaurant Name',
			value: 'Pasha\'s House of Beef',
			isReportable: true
		}, {
			name: 'language_code',
			displayName: 'Language Code',
			value: 'en',
			isReportable: true
		}];

		this.selectedWorldAwareness = [{
			name: 'cb_document_word_count',
			displayName: 'CB Document Word Count',
			values: [{value: '80', sentences: []}]
		}, {
			name: 'cb_sentence_type',
			displayName: 'CB Sentence Type',
			values: [{ value: 'generic negative', sentences: []}]
		}];

		this.availableModels = [{
			modelName: 'Restaurants',
			nodes: [
				{ name: 'Feedback' },
				{ name: 'Praise' },
				{ name: 'Criticism' },
				{ name: 'Food' },
				{ name: 'Staff' },
			]
		}];
		if (this.narrativeEnabled) {
			this.narrativeEntries = [{
				attributeName: 'restaurant_name'
			}];
		}
	}

	getDocumentPreview = (template): void => {
		this.docIndex = _.findIndex(this.sentenceList, {documentId: template.documentId});
		this.loadDocument();
	}

	getSelectedVerbatim = () => {
		if (this.documents[this.selectedSentence]) {
			return this.documents[this.selectedSentence].verbatims[0];
		}
		return {};
	}

	isDemo = (): boolean => true;

	getPages = () => null;
	runReportPaginated = () => null;
	getWidgetWithFilter = (widget: Widget) => widget;
	resetSelected = () => {};
	autoSelect = () => null;
	isFeedbackSelectionEnabled = (): boolean => false;
	isDocumentCurated = (): boolean => false;
	isSentenceDocumentCurated = (): boolean => false;
	curateItem = (): void => {};
	getRelevantCuratedItemsCount = (): number => 0;
	getRelevantCuratedItemsCountLabel = (): string => '';
	isItemCurated = (): boolean => false;
	isSentenceItemCurated = (): boolean => false;
	getAllCuratedItemsCount = (): number => 0;
	getAllCuratedDocuments = (): PreviewDocument[] => [];
	getAllCuratedDocumentsCount = (): number => 0;
	hasAnyCuratedItems = (): boolean => false;
	isShowingCuratingControls = (): boolean => false;
	isFeedbackSharingAvailable = (): boolean => false;
	setAvailableAttributes = (availableAttributes: any[]) => null;
	setAvailableModels = (availableModels: any[]) => null;
	joinSelectedAttributes = () => null;
	canCopyLink = () => false;

	allowCaseCreation = (): boolean => false;

	getNoResultMessage = (): string => '';
	getProjectIdentifier = () => null;
	getWorkspaceProject = () => null;
	getAssetParameters = () => null;
	isFilteredByConfidentiality: () => null;
	postProcessProperties: (...args) => null;
}
