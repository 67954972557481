<div class="d-flex flex-direction-column mr-8 mb-8">
	<label>{{'common.show' | i18n}} </label>
	<radio-buttons
		[options]="sortOrderOptions"
		[disabled]="sortDisabled"
		[ngModel]="sortOrder"
		(ngModelChange)="sortOrderChange.emit($event)">
	</radio-buttons>
</div>
<ng-container *ngIf="!isCustomSortOrder()">
	<div class="d-flex flex-direction-column mr-8 mb-8">
		<label for="quantity">{{'common.quantity'|i18n}}</label>
		<input id="quantity"
			type="number"
			data-testid="settings-quantity"
			class="ph-8 w-120"
			[strictMin]="1"
			[strictMax]="maxSize"
			[ngModel]="size"
			(ngModelChange)="sizeChange.emit($event)"
			[disabled]="sizeDisabled">
	</div>
	<div class="flex-fill d-flex flex-direction-column mr-8 mb-8">
		<label>{{'widgetSettings.byCalculation'|i18n}}</label>
		<select-from-tree
			data-testid="metric-sort-by"
			class="w-100-percent"
			[appendToBody]="true"
			[ngModel]="sortBy"
			[hierarchyList]="sortByOptions"
			[disabled]="sortDisabled"
			(onNodeClick)="sortByChange.emit($event.node.name)">
		</select-from-tree>
	</div>
	<div class="d-flex flex-direction-column mb-8">
		<label for="minSentenceCount">{{getVolumeLabel()|i18n}}</label>
		<input id="minSentenceCount"
			type="number"
			class="ph-8 w-120"
			[strictMin]="0"
			[ngModel]="minDocCount"
			(ngModelChange)="minDocCountChange.emit($event)">
	</div>
	<p *ngIf="showMinVolumeWarning()" class="help-block text-danger">
		{{'widget.minVolumeWarning'|i18n}}
	</p>
</ng-container>

