import { Directive, ElementRef } from '@angular/core';
import { GridsterStateService } from '@app/modules/gridster/gridster-state.service';
@Directive({
	selector: '[gridsterDragHandle]'
})
export class GridsterDragHandleDirective {

	constructor(
		readonly gridsterState: GridsterStateService,
		readonly element: ElementRef<HTMLElement>,
	) {
		setTimeout(() => {
			element.nativeElement.setAttribute('gridster-drag-handle', '');
			this.gridsterState.refreshDragHandles();
		}, 1);
	}

}
