import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { Security } from '@cxstudio/auth/security-service';

export enum InteractionSettingsProperty {
	SENTIMENT_FORMATTING = 'interactionsSentimentFormatting',
	ENRICHMENT_BADGES = 'interactionsEnrichmentBadges'
}

export interface InteractionExplorerSettings {
	showSentimentFormatting?: boolean;
	showEnrichmentBadges?: boolean;
}

@Injectable()
export class InteractionSettingsService {

	constructor(
		private userPropertiesApiService: UserPropertiesApiService,
		@Inject('security') private security: Security,
	) { }

	getSettings(): InteractionExplorerSettings {
		return {
			showSentimentFormatting: !!this.security.getContext().interactionsSentimentFormatting,
			showEnrichmentBadges: !!this.security.getContext().interactionsEnrichmentBadges
		};
	}

	resetSettings() {
		this.security.getContext().interactionsSentimentFormatting = false;
		this.security.getContext().interactionsEnrichmentBadges = false;
		let properties = {};
		properties[InteractionSettingsProperty.SENTIMENT_FORMATTING] = false;
		properties[InteractionSettingsProperty.ENRICHMENT_BADGES] = false;
		this.userPropertiesApiService.updateProperties(properties);
	}

	saveSettings(property: InteractionSettingsProperty, value: boolean) {
		this.security.getContext()[property] = value;
		let properties = {};
		properties[property] = value;
		this.userPropertiesApiService.updateProperties(properties);
	}
}

app.service('interactionSettingsService', downgradeInjectable(InteractionSettingsService));