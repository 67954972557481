import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'i18nCut'
})
export class I18nCutPipe implements PipeTransform {
	transform(value: string, cutIndex: number): string {
		return value.split('|')[cutIndex];
	}
}
