import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'content-video-settings',
	template: `
	<p [i18n]="'widget.videoWidgetDescription'"></p>
	<label class="mandatory" for="webVideo">{{'widget.fromTheWeb'|i18n}}</label>
	<div>
		<input
			id="webVideo"
			[(ngModel)]="widget.properties.videoUrl"
			placeholder="http://"
			class="form-control">
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentVideoSettingsComponent implements OnInit {

	@Input() widget: ContentWidget;

	ngOnInit(): void {
		this.widget.visualProperties.visualization = WidgetVisualization.VIDEO;
	}

}
