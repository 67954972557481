import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { DashboardReportType } from '@app/modules/dashboard/entity/dashboard-report-type.enum';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { ListOption } from '@app/shared/components/forms/list-option';

@Component({
	selector: 'dashboard-report-type-selector',
	template: `
	<simple-dropdown
		id="dashboardType"
		data-testid="dashboard-report-type-dropdown"
		class="w-100-percent"
		[value]="innerValue"
		(valueChange)="onChange($event)"
		[disableSort]="true"
		[options]="dashboardReportTypes">
	</simple-dropdown>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DashboardReportTypeSelectorComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardReportTypeSelectorComponent extends BaseControlValueAccessor<DashboardReportType> implements OnInit {

	dashboardReportTypes: ListOption<DashboardReportType>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref, DashboardReportType.DEFAULT);
	}

	ngOnInit(): void {
		this.dashboardReportTypes = [
			{ name: this.locale.getString('common.default'), value: DashboardReportType.DEFAULT },
			{ name: this.locale.getString('dashboard.dashboardReportTypeFrontline'), value: DashboardReportType.FRONTLINE },
		];
	}
}

app.directive('dashboardReportTypeSelector', downgradeComponent({component: DashboardReportTypeSelectorComponent}));
