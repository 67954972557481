<div class="d-flex w-100-percent">
	<label class="pr-8 mb-0 flex-fixed align-self-center text-1rem" [innerHTML]="getWelcomeText() | sanitize | safeHTML"></label>
	<div class="flex-fill d-flex cb-input-group">
		<paging-multiselect
			#attributeValuesSearcher
			class="flex-fill d-flex"
			[project]="homePage.reportProperties"
			[userEmail]="homePage.reportProperties.owner"
			[attributes]="searchAttributes"
			[selection]="selection"
			(onSelectionChange)="onSelectionChange()"
			[externalSearch]="searchText"
			[useExternalSearch]=true
			[hideCheckbox]=true
			[hideSelections]=true
			[onlyExactMatch]=true
			[specialOptions]=specialOptions>
			<div class="chips-input border-r-0 border-r-radius-0 h-2_5rem">
				<div class="d-flex l-margin-offset-3 h-100-percent border-radius-inherit">
					<span class="chip mv-4 cursor-pointer" *ngFor="let chip of chips">
						<i class="mr-4" attr.aria-label="{{'common.remove'|i18n}}"
							(click)="removeChip(chip, $event)">&times;</i> {{chip.displayName}}</span>
					<input
						class="pv-2 ph-4 no-border text-default border-radius-inherit  outset-focus-indicator"
						(keyup.enter)="searchHandler()"
						(keyup.escape)="clearHandler()"
						[ngModel]="searchText"
						[debounce]="300"
						(debouncedChange)="onSearchTextChange($event)"
						[disabled]="isAttributeSearch()">
					<a *ngIf="showClear()"
						(click)="clearHandler()"
						(keydown.enter)="clearHandler()"
						tabindex="0"
						class="clear-btn btn btn-icon no-border btn-secondary cursor-pointer text-1rem mr-24"
						[attr.aria-label]="'common.clearValue'|i18n"
						title="{{'common.clearValue'|i18n}}">
						<span class="pr-8 clear-label">{{'common.clear'|i18n}}</span><i class="q-icon-delete v-middle" aria-ignore="true"></i>
					</a>
				</div>
			</div>
		</paging-multiselect>
		<button class="search-btn btn btn-primary q-icon-search flex-fixed border-l-radius-0 p-8"
				type="button"
				tabindex="0"
				(click)="searchHandler()"
				[attr.aria-label]="'common.search' | i18n"></button>

	</div>
</div>
