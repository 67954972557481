import * as _ from 'underscore';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';

import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { CxDialogComponent } from '@app/modules/dialog/cx-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let app: angular.IModule;

export interface BulkLicensingUpdateDialogInput {
	availableLicenses: LicenseTypeItem[];
	selectedCount: number;
}

export interface BulkLicensingUpdateDialogOutput {
	selectedLicense: LicenseTypeItem;
	inheritPermissions: boolean;
}

/* !!! DON'T COPY SUCH APPROACH
 * Mixing component and service looks very confusing
*/
@Component({
	selector: 'bulk-licensing',
	templateUrl: './bulk-licensing.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkLicensingUpdateDialogComponent extends CxDialogComponent<BulkLicensingUpdateDialogInput, BulkLicensingUpdateDialogOutput> {
	selectedLicense: LicenseTypeItem;
	availableLicenses: LicenseTypeItem[];
	loading = {} as any;

	constructor(
		private locale: CxLocaleService,
		modalService: NgbModal) {
			super(modalService);
		}

	initOutput(): void {
		this.output = {
			selectedLicense: undefined,
			inheritPermissions: true
		};
	}

	licenseSelected = (): boolean => {
		return this.output.selectedLicense !== null;
	}

	getLicenseTypeBodyText = (): string => {
		return this.locale.getString('administration.updateBulkLicenseTypeBody', {userCount: this.input.selectedCount});
	}

	changeLicenseType = (license: LicenseTypeItem): void => {
		this.output.selectedLicense = license;
	}

	protected getDialogClass(): any {
		return BulkLicensingUpdateDialogComponent;
	}
}

app.service('bulkLicensingUpdateDialog', downgradeInjectable( BulkLicensingUpdateDialogComponent));
