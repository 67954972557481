import { QualtricsColors } from '@clarabridge/unified-ui/src/utilities/typescript/qualtrics-colors.enum';
import { QualtricsColorsDarkTheme  } from '@clarabridge/unified-ui/src/utilities/typescript/qualtrics-colors.dark-theme.enum';

export enum CustomShadows {
	REFERENCE_LINE_LABEL_SHADOW = '0 0 1em #fffef4, 0 0 1em #fffef4, 0 0 1em #fffef4',
	DARK_MODE_REFERENCE_LINE_LABEL_SHADOW = '0 0 1em black, 0 0 1em black, 0 0 1em black',
}

export const ColorConstants = {
	WHITE: QualtricsColors.WHITE,
	BLACK: QualtricsColors.BLACK,
	CHARCOAL: QualtricsColors.GRAY_900,
	RED: QualtricsColors.NEG_SENTIMENT_800 ,
	LIGHT_RED: QualtricsColors.NEG_SENTIMENT_600,
	GREY: QualtricsColors.GRAY_600,
	LIGHT_GREEN: QualtricsColors.POS_SENTIMENT_600,
	GREEN: QualtricsColors.POS_SENTIMENT_800,
	LIGHT_ORANGE: QualtricsColors.EFFORT_HARD_600,
	LIGHT_BLUE: QualtricsColors.EFFORT_EASY_600,

	GRAY_100: QualtricsColors.GRAY_100,
	GRAY_200: QualtricsColors.GRAY_200,
	GRAY_300: QualtricsColors.GRAY_300,
	GRAY_400: QualtricsColors.GRAY_400,
	GRAY_500: QualtricsColors.GRAY_500,
	GRAY_600: QualtricsColors.GRAY_600,
	GRAY_700: QualtricsColors.GRAY_700,
	GRAY_800: QualtricsColors.GRAY_800,
	GRAY_900: QualtricsColors.GRAY_900,
	GRAY_1000: QualtricsColors.GRAY_1000,

	DARK_MODE_WHITE: QualtricsColorsDarkTheme.WHITE,
	DARK_MODE_BLACK: QualtricsColorsDarkTheme.BLACK,
	DARK_MODE_CHARCOAL: QualtricsColorsDarkTheme.GRAY_900,
	DARK_MODE_RED: QualtricsColorsDarkTheme.NEG_SENTIMENT_800 ,
	DARK_MODE_LIGHT_RED: QualtricsColorsDarkTheme.NEG_SENTIMENT_600,
	DARK_MODE_GREY: QualtricsColorsDarkTheme.GRAY_600,
	DARK_MODE_LIGHT_GREEN: QualtricsColorsDarkTheme.POS_SENTIMENT_600,
	DARK_MODE_GREEN: QualtricsColorsDarkTheme.POS_SENTIMENT_800,
	DARK_MODE_LIGHT_ORANGE: QualtricsColorsDarkTheme.EFFORT_HARD_600,
	DARK_MODE_LIGHT_BLUE: QualtricsColorsDarkTheme.EFFORT_EASY_600,

	DARK_MODE_GRAY_100: QualtricsColorsDarkTheme.GRAY_100,
	DARK_MODE_GRAY_200: QualtricsColorsDarkTheme.GRAY_200,
	DARK_MODE_GRAY_300: QualtricsColorsDarkTheme.GRAY_300,
	DARK_MODE_GRAY_400: QualtricsColorsDarkTheme.GRAY_400,
	DARK_MODE_GRAY_500: QualtricsColorsDarkTheme.GRAY_500,
	DARK_MODE_GRAY_600: QualtricsColorsDarkTheme.GRAY_600,
	DARK_MODE_GRAY_700: QualtricsColorsDarkTheme.GRAY_700,
	DARK_MODE_GRAY_800: QualtricsColorsDarkTheme.GRAY_800,
	DARK_MODE_GRAY_900: QualtricsColorsDarkTheme.GRAY_900,
	DARK_MODE_GRAY_1000: QualtricsColorsDarkTheme.GRAY_1000,

	DARK_MODE_WIDGET_BACKGROUND: QualtricsColorsDarkTheme.WHITE_100,

	DARK_MODE_SENTIMENT_STRONGLY_NEGATIVE: QualtricsColorsDarkTheme.NEG_SENTIMENT_700,
	DARK_MODE_SENTIMENT_NEGATIVE: QualtricsColorsDarkTheme.NEG_SENTIMENT_600,
	DARK_MODE_SENTIMENT_NEUTRAL: QualtricsColorsDarkTheme.GRAY_600,
	DARK_MODE_SENTIMENT_MIXED: QualtricsColorsDarkTheme.GRAY_1000,
	DARK_MODE_SENTIMENT_POSITIVE: QualtricsColorsDarkTheme.POS_SENTIMENT_600,
	DARK_MODE_SENTIMENT_STRONGLY_POSITIVE: QualtricsColorsDarkTheme.POS_SENTIMENT_700,

	DARK_MODE_EFFORT_VERY_HARD: QualtricsColorsDarkTheme.EFFORT_HARD_700,
	DARK_MODE_EFFORT_HARD: QualtricsColorsDarkTheme.EFFORT_HARD_600,
	DARK_MODE_EFFORT_NEUTRAL: QualtricsColorsDarkTheme.GRAY_600,
	DARK_MODE_EFFORT_EASY: QualtricsColorsDarkTheme.EFFORT_EASY_600,
	DARK_MODE_EFFORT_VERY_EASY: QualtricsColorsDarkTheme.EFFORT_EASY_700,

	DARK_MODE_EMOTION_LOW: QualtricsColorsDarkTheme.EMOTIONAL_INTENSITY_600,
	DARK_MODE_EMOTION_MEDIUM: QualtricsColorsDarkTheme.EMOTIONAL_INTENSITY_700,
	DARK_MODE_EMOTION_HIGH: QualtricsColorsDarkTheme.EMOTIONAL_INTENSITY_800,

	DARK_MODE_PRIMARY_BACKGROUND: QualtricsColorsDarkTheme.GRAY_200,
	DARK_MODE_TEXT_COLOR: QualtricsColors.WHITE,
	DARK_MODE_CHART_LINE_COLOR: QualtricsColors.WHITE,
};




export class ColorConstantsService {

	static getDarkModeColors = (isMlSentimentEnabled: boolean): {[key: string]: string[]} => {
		return {
			sentiment: ColorConstantsService.getDarkModeSentimentColors(isMlSentimentEnabled),
			easeScore: [
				ColorConstants.DARK_MODE_EFFORT_VERY_HARD,
				ColorConstants.DARK_MODE_EFFORT_HARD,
				ColorConstants.DARK_MODE_EFFORT_NEUTRAL,
				ColorConstants.DARK_MODE_EFFORT_EASY,
				ColorConstants.DARK_MODE_EFFORT_VERY_EASY,
			],
			emotion: [
				ColorConstants.DARK_MODE_EMOTION_LOW,
				ColorConstants.DARK_MODE_EMOTION_MEDIUM,
				ColorConstants.DARK_MODE_EMOTION_HIGH
			]
		};
	}

	static getDarkModeSentimentColors = (isMlSentimentEnabled: boolean): string[] => {
		if (isMlSentimentEnabled) {
			return [
				ColorConstants.DARK_MODE_SENTIMENT_STRONGLY_NEGATIVE,
				ColorConstants.DARK_MODE_SENTIMENT_NEGATIVE,
				ColorConstants.DARK_MODE_SENTIMENT_MIXED,
				ColorConstants.DARK_MODE_SENTIMENT_POSITIVE,
				ColorConstants.DARK_MODE_SENTIMENT_STRONGLY_POSITIVE
			];
		} else {
			return [
				ColorConstants.DARK_MODE_SENTIMENT_STRONGLY_NEGATIVE,
				ColorConstants.DARK_MODE_SENTIMENT_NEGATIVE,
				ColorConstants.DARK_MODE_SENTIMENT_NEUTRAL,
				ColorConstants.DARK_MODE_SENTIMENT_POSITIVE,
				ColorConstants.DARK_MODE_SENTIMENT_STRONGLY_POSITIVE
			];
		}
	}

	static getDefaultSentimentColors = (isMlSentimentEnabled: boolean): string[] => {
		if (isMlSentimentEnabled) {
			return [
				QualtricsColors.NEG_SENTIMENT_400,
				QualtricsColors.NEG_SENTIMENT_500,
				QualtricsColors.GRAY_100,
				QualtricsColors.POS_SENTIMENT_500,
				QualtricsColors.POS_SENTIMENT_400,
			];
		} else {
			return [
				ColorConstants.RED,
				ColorConstants.LIGHT_RED,
				ColorConstants.GREY,
				ColorConstants.LIGHT_GREEN,
				ColorConstants.GREEN
			];
		}
	}
}
