import { NgModule } from '@angular/core';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { SharedModule } from '@app/shared/shared.module';
import { EmbeddedDashboardContainerComponent } from './embedded-dashboard-container/embedded-dashboard-container.component';
import { EmbeddedErrorContainerComponent } from './embedded-error-container/embedded-error-container.component';
import { EmbeddedPageComponent } from './embedded-page/embedded-page.component';
import { EmbeddedWidgetContainerComponent } from './embedded-widget-container/embedded-widget-container.component';
import { DashboardActionsModule } from '@app/modules/dashboard-actions/dashboard-actions.module';
import { BookModule } from '@app/modules/book/book.module';
import { EmbeddedDashboardHeaderComponent } from './embedded-dashboard-header/embedded-dashboard-header.component';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { EmbeddedDashboardFooterComponent } from './embedded-dashboard-footer/embedded-dashboard-footer.component';

@NgModule({
	imports: [
		SharedModule,
		WidgetContainerModule,
		DashboardModule,
		DashboardActionsModule,
		BookModule,
	],
	declarations: [
		EmbeddedPageComponent,
		EmbeddedDashboardContainerComponent,
		EmbeddedErrorContainerComponent,
		EmbeddedWidgetContainerComponent,
		EmbeddedDashboardHeaderComponent,
		EmbeddedDashboardFooterComponent
	],
})
export class EmbedModule { }
