<modal-header
	(cancel)="dismiss()"
	[modalTitle]="'widget.advancedOptions' | i18n"
>
</modal-header>
<section class="modal-body">
	<div class="row">
		<div class="col-xs-12">
			<label for="input-visual-props-orientations">{{ 'widget.orientations' | i18n }}</label>
			<div class="mb-16">
				<input
					id="input-visual-props-orientations"
					type="number"
					[strictMin]="1"
					[strictMax]="4"
					[(ngModel)]="visualProps.orientations"
				/>
			</div>
			<label for="input-visual-props-minimum-font-size">{{ 'widget.minimumFontSize' | i18n }}</label>
			<div class="mb-16">
				<input
					id="input-visual-props-minimum-font-size"
					type="number"
					[strictMin]="4"
					[strictMax]="32"
					[(ngModel)]="visualProps.minimumFontSize">
			</div>
			<div *ngFor="let option of advancedOptionsToggles" class="mb-16">
				<label>{{option.label}}</label>
				<cb-push-buttons id="advanced-options"
					 [list]="option.options"
					 [(selectedValue)]="visualProps[option.field]">
				</cb-push-buttons>
			</div>
		</div>
	</div>
</section>
<save-modal-footer
	(save)="close(visualProps)"
	(cancel)="dismiss()"
>
</save-modal-footer>
