import * as _ from 'underscore';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { BaseSelectUtils } from '@app/modules/item-grid/selection/base-selection-utils';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';


declare interface IListScope {
	ui: {
		hideFilters: boolean;
		searchFilter: string;
	};
	tabFilterList: any[];
	refreshGrid: (itemsToRefresh: any[]) => void;
	getVisibleFilters: () => any;
	project: AccountOrWorkspaceProject;
}
// use SelectionUtils once filters controller is converted to TS
// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('FilterSelectUtils', function(GridMode) {
	return class FilterSelectUtils extends BaseSelectUtils {
		private currentScope: IListScope;

		constructor(scope: IListScope) {
			super();
			this.currentScope = scope;
		}

		getVisibleObjectsList = (): any => {
			return this.currentScope.tabFilterList || [];
		}

		getObjectsList = (): any => {
			return this.currentScope.tabFilterList || [];
		}

		isSupportedType = (item: any): boolean => {
			return _.contains([FilterTypes.CXSTUDIO, FilterTypes.CXANALYZE, FilterTypes.CXSCORECARD], item.type);
		}

		getSearchFilter = (): any => {
			return this.currentScope.ui && this.currentScope.ui.searchFilter;
		}

		refreshObjects = (items: any[]): void => {
			return this.currentScope.refreshGrid(items);
		}

		visibleObjFilter = (item: any): boolean => {
			let isHidingFilters = this.currentScope.ui && this.currentScope.ui.hideFilters;
			let isProjectSelected = this.currentScope.project.projectId;

			return (!item.hide || !isHidingFilters) && !item.hiddenViaFilter && this.isSupportedType(item)
				&& (!isProjectSelected || item.projectId === this.currentScope.project.projectId);
		}
	};
});
