import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { User } from '@cxstudio/user-administration/users/entities/user';

type UserSuggestion = {
	userId: number;
	userEmail: string;
};

@Component({
	selector: 'user-email-search',
	templateUrl: './user-email-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEmailSearchComponent {
	@Input() selectedUser?: User;
	@Input() disabled: boolean;

	// When you pass a list of fixed suggestions we wont do an API call to fetch users from the master account
	@Input() userSuggestions?: UserSuggestion[];
	@Output() userSelect: EventEmitter<User> = new EventEmitter();

	private readonly userSuggestionLimit = 20;

	constructor(@Inject('userApiService') private readonly userAPI: UserApiService) {}

	onUserSelect = async (event: NgbTypeaheadSelectItemEvent): Promise<void> => {
		this.userSelect.emit(event.item);
	}

	typeAheadUserSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(
				(search) => {
					search = search.toLowerCase();

					if (!this.userSuggestions?.length) {
						return Promise.resolve(
							this.userAPI.searchUsersByText(
								search,
								'userEmail',
								this.userSuggestionLimit
							)
						);
					}

					return Promise.resolve(
						this.userSuggestions
							.filter(
								userSuggestion => {
									return userSuggestion.userEmail.toLowerCase().includes(search);
								}
							)
							.sort(
								(userSuggestionA, userSuggestionB) => {
									return userSuggestionA.userEmail.localeCompare(
										userSuggestionB.userEmail,
										undefined,
										{ sensitivity: 'base' }
									);
								}
							)
							.slice(0, this.userSuggestionLimit)
					);
				}
			)
		);
	}

	formatUserForInput = (userSuggestion: UserSuggestion): string => {
		return userSuggestion.userEmail;
	}
}

app.directive(
	'userEmailSearch',
	downgradeComponent({ component: UserEmailSearchComponent }) as angular.IDirectiveFactory
);
