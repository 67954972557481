import { OAuthClientDialogMode, OAuthClientsComponent } from '../oauth-clients.component';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { OAuthDesignerClientDetailsEditDialogComponent } from './oauth-designer-client-details-edit-dialog.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { OAuthClientDetailsApiService } from '../service/oauth-client-details-api.service';
import { Inject } from '@angular/core';
import { OAuthAuthorizedGrantTypes } from '../oauth-authorized-grant-type.factory';
import OAuthClientDetails from '../oauth-client-details';
import { FormattedTableColumnsOauthClientsService } from '@app/shared/components/table/formatted-table-columns-oauth-clients.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { OAuthClientDetailsEditDialogInput } from '../oauth-client-details-edit-dialog.component';

@Component({
	selector: 'oauth-designer-internal-clients',
	templateUrl: '../oauth-internal-clients.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthDesignerInternalClientsComponent extends OAuthClientsComponent {

	constructor(
		protected oauthClientDetailsApiService: OAuthClientDetailsApiService,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected locale: CxLocaleService,
		protected formattedTableColumnsOauthClientsService: FormattedTableColumnsOauthClientsService,
		private cxDialogService: CxDialogService,
		@Inject('contextMenuTree') protected contextMenuTree: ContextMenuTree,
		protected ref: ChangeDetectorRef,
		@Inject('cbDialogService') protected cbDialogService: CBDialogService
	) {
		super(oauthClientDetailsApiService, oauthAuthorizedGrantTypes, contextMenuTree, locale, ref,
			OauthClientDetailsApplicationKind.DESIGNER, formattedTableColumnsOauthClientsService, cbDialogService);
	}

	protected getClientDetailsList(): Promise<OAuthClientDetails[]> {
		return this.oauthClientDetailsApiService.getDesignerClientDetailsList();
	}

	protected openClientEditDialog(clientDetails: OAuthClientDetails, mode: OAuthClientDialogMode): Promise<any> {
		const input: OAuthClientDetailsEditDialogInput = { clientDetails, masterAccounts: this.masterAccounts, mode };
		let options: ModalOptions = { size: ModalSize.LARGE };

		return this.cxDialogService.openDialog(OAuthDesignerClientDetailsEditDialogComponent, input, options).result;
	}

}
