import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';

@Component({
	selector: 'grouping-selection-settings',
	templateUrl: './grouping-selection-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupingSelectionSettingsComponent implements OnInit {

	@Input() sortOrder: GroupingSortOrder;
	@Output() sortOrderChange = new EventEmitter<GroupingSortOrder>();
	@Input() sortDisabled: boolean;
	@Input() withCustomOrder: boolean;

	@Input() size: number;
	@Output() sizeChange = new EventEmitter<number>();
	@Input() maxSize = 1000;
	@Input() sizeDisabled: boolean;
	@Input() sentenceLevel: boolean;

	@Input() minDocCount: number;
	@Output() minDocCountChange = new EventEmitter<number>();

	@Input() sortBy: string;
	@Output() sortByChange = new EventEmitter<string>();
	@Input() sortByOptions: INode[];

	sortOrderOptions: ListOption<GroupingSortOrder>[];
	sortByOption: INode[];

	constructor(
		private locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.sortOrderOptions = [
			{name: this.locale.getString('widget.top'), value: GroupingSortOrder.DESC},
			{name: this.locale.getString('widget.bottom'), value: GroupingSortOrder.ASC}
		];
		if (this.withCustomOrder) {
			this.sortOrderOptions.push({
				name: this.locale.getString('widget.custom'),
				value: GroupingSortOrder.CUSTOM
			});
		}

	}

	isCustomSortOrder(): boolean {
		return this.sortOrder === GroupingSortOrder.CUSTOM;
	}

	getVolumeLabel(): string {
		return this.sentenceLevel
			? this.locale.getString('widget.minimumSentenceCount')
			: this.locale.getString('widget.minimumVolume');
	}

	showMinVolumeWarning(): boolean {
		return this.sortBy === StandardMetricName.VOLUME && this.sortOrder === GroupingSortOrder.ASC;
	}
}
