<div [ngBusy]="loading">
	<span class="d-block pb-16"
		[i18n]="'administration.selectProjects'"
		[i18nParams]="{email: user.email}">
	</span>
	<div *ngIf="hasMultipleWorkspaces()" class="pb-16">
		<simple-dropdown
			[options]="workspaces"
			[(value)]="selectedWorkspaceId"
			(valueChange)="switchWorkspace()"
			displayField="name"
			valueField="id"
		></simple-dropdown>
	</div>

	<alert type="danger" *ngIf="getError()">{{getError()}}</alert>

	<div *ngIf="!getError()">
		<div *ngIf="isAdminLevelLicense()">
			<div class="labeled-radio">
				<input
					id="grantAccountAdmin"
					name="grantAccountAdmin"
					type="radio"
					[value]="true"
					[checked]="workspaceAccess.accountAdmin"
					[disabled]="!isEditorAccountAdmin()"
					(click)="grantAccountAdmin(true)">
				<label for="grantAccountAdmin" [i18n]="'administration.grantAccountAdmin'"></label>
			</div>
			<div class="labeled-radio">
				<input
					id="grantSelectedProjects"
					name="grantSelectedProjects"
					type="radio"
					[value]="false"
					[checked]="!workspaceAccess.accountAdmin"
					[disabled]="!isEditorAccountAdmin()"
					(click)="grantAccountAdmin(false)">
					<label for="grantSelectedProjects" [i18n]="'administration.grantSelectedProjects'"></label>
			</div>
		</div>

		<div *ngIf="hasMarkAllControls()" class="cx-project-access-all-block">
			<div> <a *ngIf="hasMarkAllAdmin()" (click)="markAllAdmin()"
					 [i18n]="'administration.markAllAdmin'"></a></div>
			<div> <a (click)="markAllReadOnly()"
					 [i18n]="'administration.markAllReadOnly'"></a></div>
			<div> <a (click)="markAllNoAccess()"
					 [i18n]="'administration.markAllNoAccess'"></a></div>
		</div>

		<div class="cx-project-access">
			<table class="table" attr.aria-label="{{'common.projects'|i18n}}">
				<thead>
					<tr>
						<th scope="col" [i18n]="'common.project'"></th>
						<th scope="col" [i18n]="'administration.access'"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let projectData of workspaceAccess.projects | orderBy:'project.name'">
						<td>{{projectData.project.name}}</td>
						<td>
							<simple-dropdown
								[options]="getAccessValues(projectData)"
								[(value)]="projectData.accessLevel"
								(valueChange)="onAccessLevelChange()"
								displayField="label"
								valueField="value"
								[disabled]="isProjectsAccessLocked() || isDisabledOption(projectData)"
								[appendToBody]="true"
							></simple-dropdown>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
