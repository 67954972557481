import {Component, Input, Output, EventEmitter} from '@angular/core';
import { UIOption } from '@clarabridge/unified-angular-components';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DirectionalOrientation } from '@cxstudio/common/orientation';

@Component({
	selector: 'orientation',
	template:
	`
		<cb-radio-group class="orientation"
						[options]="options"
						[inline]="true"
						[disabled]="disabled"
						[ngModel]="orientation"
						(ngModelChange)="change($event)">
		</cb-radio-group>
	`
})
export class OrientationComponent {

	@Input() disabled: boolean;
	@Input() orientation: DirectionalOrientation;
	@Output() orientationChange = new EventEmitter<any>();
	constructor(private locale: CxLocaleService) {
	}
	readonly options: UIOption<DirectionalOrientation>[] = [
		{value: DirectionalOrientation.HORIZONTAL, displayName: this.locale.getString('widget.horizontal')},
		{value: DirectionalOrientation.VERTICAL, displayName: this.locale.getString('widget.vertical')}
	];

	change($event: any) {
		this.orientationChange.emit($event);
	}
}

app.directive('orientation', downgradeComponent({component: OrientationComponent}) as angular.IDirectiveFactory);

