import { Component, OnInit, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';

@Component({
	selector: 'email-domains',
	template: `
	<table class="table">
		<thead>
			<th>{{'common.masterAccount'|i18n}}</th>
			<th>{{'administration.emailDomain'|i18n}}</th>
			<th>{{'administration.count'|i18n}}</th>
		</thead>
		<ng-container *ngFor="let ma of maDomains | orderBy:'name'">
			<tbody>
				<tr *ngFor="let domain of ma.domains | orderBy:'name'" [ngClass]="{'bg-warning-100':unmatchedDomain(ma, domain)}">
					<td>{{ma.name}}</td>
					<td>{{domain.name}}</td>
					<td>{{domain.count}}</td>
				</tr>
			</tbody>
		</ng-container>
	</table>`,
})

export class EmailDomainsComponent implements OnInit {

	@Input() masterAccounts: Array<{accountName: string, accountId: number}>;
	maDomains: Array<{id: number|string, name: string}>;

	constructor(
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
	) {}

	ngOnInit(): void {
		this.maDomains = [];
		this.systemAdminApiService.getEmailDomainsByMa().then(data => {
			this.maDomains = this.restructureData(data);
			this.matchNames();
		});
	}

	unmatchedDomain = (acct, domain) => {
		let accountName = acct.name.toLowerCase();
		let domainName = domain.name.toLowerCase();

		return (domainName.indexOf(accountName) < 0);
	}


	private matchNames = () => {
		this.maDomains.map((ma) => {
			let name = _.findWhere(this.masterAccounts, {accountId: Number(ma.id)});
			ma.name = name ? name.accountName : `Unrecognized Master Account - ${ma.id}`;
		});
	}

	// massage data to a more usable structure :(
	private restructureData = (data) => {
		data = data.data;
		let newData = [];
		for (let masterAccount in data) {
			if (data[masterAccount]) {
				let domains = [];
				for (let domain in data[masterAccount]) {
					if (data[masterAccount][domain])
						domains.push({name: domain, count: data[masterAccount][domain]});
				}
				newData.push({ id: masterAccount, domains });
			}
		}
		return newData;
	}
}
app.directive('emailDomains', downgradeComponent({component: EmailDomainsComponent}) as angular.IDirectiveFactory);
