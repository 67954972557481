import { Pipe, PipeTransform, Inject } from '@angular/core';
import * as moment from 'moment';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

@Pipe({
	name: 'userTimeFormat'
})
export class UserTimeFormatPipe implements PipeTransform {

	constructor(@Inject('dateService') private dateService) {}

	transform(dateStr): string {
		if (!dateStr)
			return '';
		let momentObj = moment.parseZone(dateStr);
		let date = new Date(momentObj.year(), momentObj.month(), momentObj.date(), momentObj.hour(), momentObj.minute(), momentObj.second());
		let offset = momentObj.utcOffset();
		let tz = 'GMT' + this.dateService.getTimezoneString(offset);
		return this.dateService.format(date, DateTimeFormat.BASIC_DATE_TIME) + ' ' + tz;
	}
}
