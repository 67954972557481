<div class="dashboard-filter-topic-selection hierarchy-select-wrapper">
	<div ngbDropdown #ngDropdown
		class="dropdown-input"
		autoClose="outside"
		[placement]="dropdownPlacement"
		[container]="getContainer()">
		<button ngbDropdownToggle #ngDropdownToggle
			class="kb-focusable-inset d-flex w-100-percent border-radius-4"
			aria-expanded="false" aria-haspopup="true"
			[disabled] = "disabled || null">
			<span title="{{getSelectedValueLabel()}}" class="button-text">{{getSelectedValueLabel()}}</span>
			<span class="caret"></span>
		</button>

		<div ngbDropdownMenu
			class="dropdown-menu dropdown-menu-hierarchy"
			role="menu"
			(click)="$event.stopPropagation()"
			(keyup.escape)="stopEvent($event); closePopup();"
			(keydown.escape)="stopEvent($event)">

			<tree-selection *ngIf="isOpen()"
				[treeSelection]="treeSelection"
				[root]="root"
				(onTreeSelectionChanged)="onTreeSelectionChanged($event)"
				[convertNodes]="convertNodes"
				[loadRootChildren]="loadTopics"
				[reloadWatch]="model && model.id"
				[highlightSelectedBackground]="highlightSelectedBackground"
				[showMultiselectButtons]="showMultiselectButtons"
				[selectEverythingText]="selectEverythingText"
				[selectNoneText]="selectNoneText"
				[searchLabel]="searchLabel"
				[treeName]="model.displayName"
				autoFocus="true"
				(onFocusMove)="focusMoveHandler($event)">
			</tree-selection>
		</div>
	</div>
</div>
