<div class="scorecard-scoring-dialog" [ngBusy]="[loading, loadingDrivers]">
	<form name="rubricsForm"  #rubricsForm="ngForm">
		<div class="form-group">
			<label>{{'scorecards.selectDriverLabel'|i18n}}
				<cb-inline-help>
					<help-title></help-title>
					<help-body>{{'scorecards.driverHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<div class="row">
				<div class="col-md-6 col-lg-4">
					<search-list [dropdown]="true"
						[nonclickable]="!hasDrivers()"
						[data]="modelContext.driver"
						[listOptions]="drivers"
						displayField="displayName"
						(onNodeSelection)="selectDriver($event.node)">
					</search-list>
				</div>
			</div>
		</div>
		<p [i18n]="'scorecards.assignWeightLabel'" [i18nParams]="{modelName: modelContext.model?.name}"></p>

		<div class="row">
			<div class="col-xs-10 col-xs-offset-1">
				<div class="standalone-scoring">
					<div class="scorecard-column-wrapper pr-32">
						<label class="scorecard-column scorecard-presence-column-wrapper" id="presenceColumnLabel">
							{{'scorecards.presenceColumnLabel'|i18n}}
							<cb-inline-help>
								<help-body>{{'scorecards.desiredResultTooltip'|i18n}}</help-body>
							</cb-inline-help>
						</label>
						<label class="scorecard-column-impact-rank" id="impactRankLabel" *ngIf="modelContext.driver">
							{{'scorecards.impactRankLabel'|i18n}}
							<cb-inline-help>
								<help-body>{{getImpactRankTooltip()}}</help-body>
							</cb-inline-help>
						</label>
						<label class="scorecard-column" id="autoFailLabel">
							{{'scorecards.autoFailLabel'|i18n}}
							<cb-inline-help>
								<help-body>{{'scorecards.autoFailTooltip'|i18n}}</help-body>
							</cb-inline-help>
						</label>
						<label class="scorecard-column" id="weightLabel">
							{{'scorecards.weightLabel'|i18n}}
							<cb-inline-help>
								<help-body>{{'scorecards.weightTooltip'|i18n}}</help-body>
							</cb-inline-help>
						</label>
						<label class="scorecard-column" id="weightNLabel">
							{{'scorecards.weightNLabel'|i18n}}
							<cb-inline-help>
								<help-body>{{'scorecards.weightNTooltip'|i18n}}</help-body>
							</cb-inline-help>
						</label>
					</div>
				</div>
				<ul class="pl-0 scoring-matrix-body pr-16">
					<ng-container *ngFor="let scoringEntry of modelContext.scoringEntries; let $index = index">
						<li *ngIf="isScoringEntryVisible(scoringEntry)"
							id="entry_{{$index}}"
							class="scoring-entry">
							<div id="nameElement_{{$index}}"
								class="scoring-matrix-name-column"
								[ngStyle]="{'padding-left':getTopicLevelPadding(scoringEntry)}">
								<span class="br-hierarchy-node-toggle cursor-pointer"
									[ngClass]="{'q-icon-add':(scoringEntry.children && !scoringEntry.expanded),
												'q-icon-minus':(scoringEntry.children && scoringEntry.expanded)}"
									(click)="toggleScoringEntry(scoringEntry)"
									*ngIf="scoringEntry.children && scoringEntry.parentId">
								</span>
								{{ scoringEntry.name }}
							</div>
							<div class="scorecard-column-wrapper" role="radiogroup" *ngIf="!scoringEntry.children">
								<div class="scorecard-presence-column-wrapper">
									<search-list [dropdown]="true"
										[data]="getPresenceObject(scoringEntry.topic)"
										[disableSearch]="true"
										[listOptions]="presenceOptions"
										(onNodeSelection)="selectPresence(scoringEntry.topic, $event.node)"
										[appendToBody]="true">
									</search-list>
								</div>
								<div class="scorecard-column-impact-rank" *ngIf="modelContext.driver">
									<label disabled="true">
										{{getImpactRankForNode(scoringEntry)}}
									</label>
								</div>

								<div class="scorecard-column">
									<input class="_t-autoFail"
										[value]="true"
										attr.aria-labelledby="autoFailLabel nameElement_{{$index}}"
										attr.name="scoreType-{{scoringEntry.id}}"
										name="scoreType-{{scoringEntry.id}}"
										type="radio"
										[checked]="!isWeighted(scoringEntry.topic)"
										(click)="setAutofail(scoringEntry.topic, true)"/>
								</div>
								<div class="scorecard-column">
									<input class="mr-8 _t-weight"
										[value]="false"
										attr.aria-labelledby="weightLabel nameElement_{{$index}}"
										attr.name="scoreType-{{scoringEntry.id}}"
										name="scoreType-{{scoringEntry.id}}"
										type="radio"
										[checked]="isWeighted(scoringEntry.topic)"
										(click)="setAutofail(scoringEntry.topic, false)"/>
									<input
										attr.name="weight-{{scoringEntry.id}}"
										name="weight-{{scoringEntry.id}}"
										attr.aria-label="{{getCheckboxTitleText(scoringEntry.name, 'weight')}}"
										[disabled]="!isWeighted(scoringEntry.topic)"
										type="number"
										min="0"
										step="any"
										class="scoring-matrix-numeric-input _t-weightScore"
										[(ngModel)]="scoringEntry.topic.topicWeight"
										(ngModelChange)="updateTotal()"/>
								</div>
								<div class="scorecard-column">
									<input
										attr.aria-label="{{getCheckboxTitleText(scoringEntry.name, 'weightN')}}"
										disabled
										class="scoring-matrix-numeric-input"
										[value]="getWeightN(scoringEntry.topic.topicWeight)"/>
								</div>
							</div>
							<div class="scorecard-column-wrapper" role="radiogroup" *ngIf="scoringEntry.children && scoringEntry.level > 0">
								<div class="scorecard-presence-column-wrapper d-flex align-center">
									{{'scorecards.parentNodePresenceLabel' | i18n}}
								</div>
								<div class="scorecard-column-impact-rank" *ngIf="modelContext.driver">
									<label disabled="true">
										{{getImpactRankForNode(scoringEntry)}}
									</label>
								</div>
								<div class="scorecard-column"></div>
								<div class="scorecard-column">
									<input
										name="sumWeight-{{scoringEntry.id}}"
										attr.aria-label="{{getNamedWeightLabel(scoringEntry.name, 'weight')}}"
										disabled
										type="text"
										class="scoring-matrix-numeric-input _t-weightScore"
										[(ngModel)]="scoringEntry.sumWeight"/>
								</div>
								<div class="scorecard-column">
									<input
										attr.aria-label="{{getNamedWeightLabel(scoringEntry.name, 'weightN')}}"
										disabled
										class="scoring-matrix-numeric-input"
										[value]="getWeightN(scoringEntry.sumWeight)"/>
								</div>
							</div>
						</li>
					</ng-container>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-10 col-xs-offset-1">
				<div class="pr-40">
					<div class="standalone-scoring">
						<div class="scoring-matrix-weight-footer-top scorecard-weight-footer">
							<label for="scoringTotal" class="mr-8">
								<cb-inline-help class="mr-8">
										<help-body>{{'scorecards.totalTooltip'|i18n}}</help-body>
								</cb-inline-help>
								{{'scorecards.totalLabel'|i18n}}
							</label>
							<input type="text" id="scoringTotal" name="scoringTotal" disabled
								class="scoring-matrix-numeric-input mr-8"
								[(ngModel)]="modelContext.total"/>
							<div class="scoring-matrix-numeric-input d-inline-block"></div>
						</div>
					</div>
					<div class="standalone-scoring">
						<div class="scoring-matrix-weight-columns scorecard-weight-footer">
							<label for="scoringTarget" class="mr-8">
								<cb-inline-help class="mr-8">
										<help-body>{{'scorecards.targetToolip'|i18n}}</help-body>
								</cb-inline-help>
								{{'scorecards.targetLabel'|i18n}}
							</label>
							<input type="number" name="thresholdRaw"
								class="scoring-matrix-numeric-input mr-8"
								[max]="modelContext.total"
								min="0" step="any"
								[(ngModel)]="modelContext.thresholdRaw"
								(ngModelChange)="updateThreshold()"
								pattern="[0-9]+(\.[0-9]{1,3})?"/>
							<div class="scoring-matrix-numeric-input d-inline-block"></div>
						</div>
					</div>
					<div class="standalone-scoring">
						<div class="scoring-matrix-weight-columns scorecard-weight-footer">
							<label for="scoringTarget" class="mr-8">
								<cb-inline-help class="mr-8">
										<help-body>{{'scorecards.targetNToolip'|i18n}}</help-body>
								</cb-inline-help>
								{{'scorecards.targetNLabel'|i18n}}
							</label>
							<div class="scoring-matrix-numeric-input d-inline-block mr-8"></div>
							<input  type="number" name="threshold"
								id="scoringTargetN"
								class="scoring-matrix-numeric-input"
								step="any" max="100" min="0"
								[(ngModel)]="modelContext.threshold"
								(ngModelChange)="thresholdChangeHandler()"
								pattern="[0-9]+(\.[0-9]{1,3})?"/>
						</div>
					</div>
					<div class="d-flex flex-justify-end mb-16">
						<button *ngIf="!isViewMode"
							type="button"
							id="test-scorecard"
							class="btn btn-secondary"
							(click)="test()"
							[disabled]="!valid"
							[i18n]="'common.spotCheck'">
						</button>
					</div>
				</div>
				<alert type="danger" *ngIf="autoFailAndWeightMissingError" text="{{'scorecards.autoFailAndWeightMissingError'|i18n}}"></alert>
				<alert type="danger" *ngIf="thresholdBlankError" text="{{'scorecards.thresholdBlankError'|i18n}}"></alert>
				<alert type="danger" *ngIf="thresholdOutOfRangeError" text="{{'scorecards.thresholdOutOfRangeError'|i18n}}"></alert>
				<alert type="danger" *ngIf="thresholdPrecisionError" text="{{'scorecards.thresholdPrecisionError'|i18n}}"></alert>
				<alert type="warning" *ngIf="showCaseUpdatesWarning" text="{{'scorecards.caseUpdatesWarning'|i18n}}" [dismissable]="false"></alert>
			</div>
		</div>
	</form>
</div>
