import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { ReportScopeUtils } from '@cxstudio/reports/utils/report-scope-utils.service';
import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';


@Injectable()
export class CloudNavigation {

	constructor(
		@Inject('reportScopeUtils') private reportScopeUtils: ReportScopeUtils,
		private keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper
	) { }

	focusFirstWord = (container: string): void => {
		this.focus(container, 0);
	}

	handleWidgetExit = (event: KeyboardEvent, widgetId: number | string): void => {
		let exitKey = KeyboardUtils.isEventKey(event, Key.ESCAPE) || KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT);
		if (exitKey && this.keyboardNavigationDrillHelper.isCloudWord(event.target as any)) {
			KeyboardUtils.intercept(event);
			$(event.target).closest(`#widget-${widgetId}`).find(':focusable').first().trigger('focus');
		}
	}

	handleNavigation = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.UP)) {
			KeyboardUtils.intercept(event);
			this.focus(event.currentTarget, this.getCurrentIndex(event) - 1);
		}
		if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			KeyboardUtils.intercept(event);
			this.focus(event.currentTarget, this.getCurrentIndex(event) + 1);
		}
	}

	getKeydownHandler = (scope: ISimpleScope) => {
		return (event: any): void => {
			if (this.keyboardNavigationDrillHelper.isDrillActionKey(event)) {
				event.chartPoint = this.reportScopeUtils.getProcessedPoint(scope);
				scope.$emit('cloudWidget:drill', event);
			} else {
				this.handleNavigation(event);
			}
		};
	}

	private getCurrentIndex = (event: KeyboardEvent): number => {
		return Number($(event.target).attr('index')) || 0;
	}

	private focus = (container: any, index: number): void => {
		$(container).find(`.cloud-word[index=${index}]`).trigger('focus');
	}

}

app.service('cloudNavigation', downgradeInjectable(CloudNavigation));
