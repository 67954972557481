<div class="pl-16">
	<div class="favoritable-item">
		<h4 class="display-name font-bold text-base d-inline">{{attribute.displayName}}</h4>
		<favorite-item-icon
			[favoriteId]="favoriteId"
			[favoriteDisplayName]="attribute.displayName"
			[favoriteAttributes]="favoriteAttributes"
			[disableFavorites]="disableFavorites"
			[favoritesPanel]="favoritesPanel"
			(onClick)="toggleFavorite()"></favorite-item-icon>
	</div>
	<p class="value mb-0 white-space-pre-wrap"
	   (click)="getLinkAndRedirect(attribute, $event)"
	   [innerHTML]="formatValue()"></p>
</div>

