import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { NarrativeSettingsListActions } from './narrative-settings-list-actions.service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { NarrativeSettingEntry } from './narrative-settings-list.component';
import { CxLocaleService } from '@app/core';


@Injectable()
export class NarrativeSettingsContextMenu extends BaseContextMenuUtils {

	private readonly OPTIONS: {[name: string]: ContextMenuItem<NarrativeSettingEntry>} = {
		EDIT: {
			name: 'edit',
			text: this.locale.getString('common.edit'),
			func: (settings: NarrativeSettingEntry) => this.narrativeSettingsListActions.edit(settings)
		},

		DELETE: {
			name: 'delete',
			text: this.locale.getString('common.delete'),
			func: (settings: NarrativeSettingEntry) => this.narrativeSettingsListActions.delete(settings)
		}
	};

	constructor(private locale: CxLocaleService, private narrativeSettingsListActions: NarrativeSettingsListActions) {
		super();
	}

	getContextMenu(item: NarrativeSettingEntry): Array<ContextMenuItem<NarrativeSettingEntry>> {
		return [this.OPTIONS.EDIT, this.OPTIONS.DELETE];
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}
