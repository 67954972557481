<div class="sentence-container kb-focusable-inset" [attr.tabindex]="focusable ? 0 : null">
	<span class="show-participant action-hover-text">{{'common.show'|i18n}} {{getParticipantLabel()}}</span>
	<div class="participant-label">
		<b>{{getParticipantLabel()}}</b>
		<span class="sentence-icon speaker-icon"
			[ngClass]="getIconClass()"
			[style.color]="getIconColor()"></span>
	</div>
	<span class="timestamp text-gray-900">{{chatMessage.timestamp | secondsToDuration}}</span>
	<p class="sentence-text-container action-border pr-8">
		<voice-sentence *ngFor="let sentence of chatMessage.sentences"
			id="voice-sentence-{{sentence.id}}"
			(click)="onSelect.emit({event: $event, sentence: sentence})"
			[class.hover]="sentence.id === hoverSentenceId"
			[sentence]="sentence"
			[sentimentColorFunc]="formatters.sentimentColor">
		</voice-sentence>
	</p>

	<ng-container *ngIf="auditMode && !compact">
		<ng-container *ngFor="let sentence of chatMessage.sentences">
			<sentence-pills class="sentence-topics lh-1"
				(mouseover)="hoverSentenceId = sentence.id"
				(mouseleave)="hoverSentenceId = null"
				[topics]="getFilteredChatSentenceTopics(sentence.id)"
				[auditMode]="auditMode"
				[enabledModels]="auditModeModels"
				[width]="messageWidth"
				[highlightTrigger]="highlightTrigger"
				[showAll]="expandTopics"
				[hasProfanity]="hasProfanity(sentence)"
				[focusable]="focusable"
				[sentences]="[sentence]"
				[predefinedMetrics]="predefinedMetrics"
				[showTopics]="showTopics"
				[showEnrichments]="showEnrichments"
				(removeTopic)="removeTopic.emit({topic: $event, sentence: sentence})"
				(tuneEnrichment)="tuneEnrichment.emit({pill: $event.pill, event: $event.event, sentence: sentence})">
			</sentence-pills>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!auditMode">
		<sentence-pills class="sentence-topics lh-1"
			[topics]="getFilteredSentenceTopics(chatMessage.sentenceTopics)"
			[auditMode]="auditMode"
			[width]="messageWidth"
			[highlightTrigger]="highlightTrigger"
			[focusable]="focusable"
			[sentences]="chatMessage.sentences"
			[predefinedMetrics]="predefinedMetrics"
			[showTopics]="showTopics"
			[showEnrichments]="showEnrichments"
			[showAll]="expandTopics">
		</sentence-pills>
	</ng-container>

	<translated-text *ngIf="translate"
		class="d-flex mt-8"
		[cacheKey]="uniqueId"
		[text]="getChatText()">
	</translated-text>
</div>
