<section *ngIf="tabsEnabled"
	class="dashboard-thumbnails w-100-percent"
	[attr.aria-label]="'dashboard.carouselSection'|i18n">
	<div class="br-list-box recent-favorite-dashboard-tabs p-0" *ngIf="dashboards.length>0">
		<ul class="nav nav-tab-bar d-flex-inline mt-0 mb-16 ph-32 pt-16" role="tablist">
			<li *ngFor="let tab of tabConfig; index as $index"
				id="{{getTabId(tab)}}"
				[ngClass]="{'active': activeTab===$index}"
				[attr.aria-controls]="'_dash_panel_' + $index"
				[attr.aria-selected]="activeTab === $index"
				role="tab"
				(click)="activeTab = $index">
				<a href="javascript:void(0)" data-toggle="tab" class="nav-link">
					<i class="mr-4 {{getTabIcon(tab)}}"></i>
					{{getTabName(tab)}}
				</a>
			</li>
		</ul>
		<div class="tab-content">
			<scroll-carousel *ngFor="let tab of tabConfig; index as $index"
				[labels]="{previous: ('dashboard.showPrevious'|i18n), next: ('dashboard.showNext'|i18n)}"
				[scrollOffset]=40
				id="{{getContentId(tab)}}"
				class="tab-pane dashboard-preview-list"
				[active]="activeTab === $index"
				[class.active]="activeTab === $index"
				[class.empty]="getDashboardsForTab(tab).length === 0">
				<section
					id="_dash_panel_{{$index}}"
					class="scroll-carousel-target fade-container d-flex">
					<dashboard-preview-image
						*ngFor="let dash of getDashboardsForTab(tab)"
						(click)="changeDashboard(dash)"
						[tabsLimit]="7"
						[dashboard]="dash">
					</dashboard-preview-image>
					<div *ngIf="getDashboardsForTab(tab).length === 0" [innerHTML]=getEmptyText(tab)></div>
				</section>
			</scroll-carousel>
		</div>
	</div>
</section>
