import { Injectable, Inject } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpPromise } from '@app/core/http-promise';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { HttpParams } from '@angular/common/http';
import { EmbeddedWidget } from '@app/modules/embed/embedded-widget-container/embedded-widget';
import { EmbeddedDashboard } from '@app/modules/embed/embedded-dashboard-container/embedded-dashboard';

@Injectable({
	providedIn: 'root'
})
export class EmbedApiService {

	constructor(
		private readonly http: CxHttpService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) { }

	validateDashboardAccess(dashboardId: number, widget?: string): HttpPromise<void> {
		let params = new HttpParams();
		if (widget) {
			params = params.append('widget', widget);
		}
		return this.http.getResponse(`rest/embed/dashboard/${dashboardId}/validate`, {
			local: true,
			params,
		});
	}

	getEmbeddedDashboard(dashboardId: number): Promise<EmbeddedDashboard> {
		return this.http.get(`rest/embed/dashboard/${dashboardId}`, {
			headers: {
				embeddedHost: this.environmentService.getFrameLocationHost()
			},
		});
	}

	getEmbeddedWidget(dashboardId: number, identifier: string): Promise<EmbeddedWidget> {
		return this.http.get(`rest/embed/dashboard/${dashboardId}/widget/${identifier}`, {
			headers: {
				embeddedHost: this.environmentService.getFrameLocationHost()
			},
		});
	}
}
