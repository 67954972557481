import { Pagination } from '@app/shared/components/pagination/pagination';

export class PaginationQueryParams {
	isPaginated: boolean;
	pageSize: number;
	currentPage: number;

	constructor(pagination: Pagination) {
		this.isPaginated = pagination.isPaginated;
		this.currentPage = pagination.currentPage;
		this.pageSize = pagination.pageSize;
	}
}