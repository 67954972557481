import * as cloneDeep from 'lodash.clonedeep';
import {
	Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output,
	ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, Inject
} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { UnifiedLink } from '../oauth-client-details';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { UnifiedLinksVisibilityChangedEvent } from './link-visibility-restriction/unified-link-visibility.component';

export interface OAuthClientDetailsUnifiedLinksChangedEvent {
	unifiedLinks?: UnifiedLink[];
	unifiedLinksEnabled?: boolean;
	unifiedLinksValid: boolean;
	unifiedLinksVisibility?: Map<number, Array<number>>;
}

export interface OAuthClientDetailsParentFolderChangedEvent {
	parentFolderEnabled: boolean;
	parentFolderName: string;
}

@Component({
	selector: 'oauth-client-details-unified-links',
	templateUrl: './oauth-client-details-unified-links.component.html',
	styles: [`
	.cdk-drag-preview {
		z-index: 1100 !important;
	  }
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OauthClientDetailsUnifiedLinksComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() unifiedLinksUpdated = new EventEmitter<OAuthClientDetailsUnifiedLinksChangedEvent>();
	@Input() additionalInformation: any;
	@Input() isLegacySuiteApplication: boolean;
	@Input() masterAccounts: Array<MasterAccount>;
	@ViewChild('unifiedLinksForm') public unifiedLinksForm: NgForm;


	private readonly DEFAULT_ROOT_LINK: UnifiedLink = {
		name: 'Application Name', url: '/'
	};

	private readonly DEFAULT_CHILD_LINK: UnifiedLink = {
		name: 'Child Page', url: '/child_page'
	};

	linksChangedSubscription: Subscription;
	processLinkAddedRemoved: boolean = false;

	constructor(
		private ref: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		if (!this.additionalInformation.unifiedLinks) {
			this.additionalInformation.unifiedLinks = [];
		}

		this.assignUnifiedLinksIds();
	}

	ngOnDestroy(): void {
		this.linksChangedSubscription.unsubscribe();
	}

	// after form is rendered, register additional handlers:
	ngAfterViewInit(): void {
		this.linksChangedSubscription = this.unifiedLinksForm.valueChanges.subscribe(this.linkAddedRemovedObserver);
	}

	private linkAddedRemovedObserver = (): void => {
		//handler for added or removed links
		if (!this.processLinkAddedRemoved) {
			return;
		}

		//Check form and model for consistency. Render might be incomplete.
		let expectedInputFieldsNumer = this.additionalInformation.unifiedLinks.length * 2; //2 inputs per link
		let scopeInputsNumber = _.filter(Object.keys(this.unifiedLinksForm.controls),
		(scope: string) => scope.startsWith('linkUrl_') || scope.startsWith('linkName_')).length;
		if (scopeInputsNumber !== expectedInputFieldsNumer) {
			return;
		}

		for (let i: number = 0; i < this.additionalInformation.unifiedLinks.length; i++) {
			let item: UnifiedLink = this.additionalInformation.unifiedLinks[i];
			let nameControl: FormControl = this.unifiedLinksForm.form.get('linkName_' + i) as FormControl;
			let urlControl: FormControl = this.unifiedLinksForm.form.get('linkUrl_' + i) as FormControl;
			if (_.isUndefined(nameControl) || _.isNull(nameControl) || _.isUndefined(urlControl) || _.isNull(urlControl)) {
				return;
			}
			if (nameControl.value !== item.name || urlControl.value !== item.url) {
				return;
			}

			nameControl.markAsDirty();
			nameControl.markAsTouched();
			urlControl.markAsDirty();
			urlControl.markAsTouched();
		}

		this.processLinkAddedRemoved = false;
		this.ref.markForCheck();
		this.updateUnifiedLinks();
	}

	private assignUnifiedLinksIds(): void {
		if (_.isUndefined(this.additionalInformation.unifiedLinks)) {
			return;
		}
		this.additionalInformation.unifiedLinks.forEach((link: any, index: number) => {
			link.id = index;
		});
	}

	addLink = (index: number): void => {
		if (_.isEmpty(this.additionalInformation.unifiedLinks)) {
			this.additionalInformation.unifiedLinks = [this.DEFAULT_ROOT_LINK];
		} else {
			this.additionalInformation.unifiedLinks.splice(index + 1, 0, cloneDeep(this.DEFAULT_CHILD_LINK));
		}
		this.assignUnifiedLinksIds();
		this.processLinkAddedRemoved = true;
	}

	removeLink = (index: number): void => {
		this.additionalInformation.unifiedLinks.splice(index, 1);
		this.assignUnifiedLinksIds();
		this.processLinkAddedRemoved = true;
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.additionalInformation.unifiedLinks, event.previousIndex, event.currentIndex);
		this.assignUnifiedLinksIds();
		this.processLinkAddedRemoved = true;
	}

	showUnifiedLinksSelector = (): boolean => {
		return !this.isLegacySuiteApplication && this.additionalInformation.enableUnifiedLinks;
	}

	showAddFirstLinkButton = (): boolean => {
		return this.additionalInformation.enableUnifiedLinks
		 && (_.isUndefined(this.additionalInformation.unifiedLinks) ||
		 	this.additionalInformation.unifiedLinks.length === 0);
	}

	trackUnifiedLink = (index: number, link: any): string => {
		return link.id;
	}

	updateUnifiedLinksVisibility = (visibilityEvent:UnifiedLinksVisibilityChangedEvent): void => {
		this.updateUnifiedLinks(visibilityEvent);
	}

	isParentFolderEnabled = (): boolean => {
		return this.additionalInformation.parentFolderEnabled;
	}

	updateUnifiedLinks = (visibilityEvent?:UnifiedLinksVisibilityChangedEvent): void => {
		let event: OAuthClientDetailsUnifiedLinksChangedEvent = {
			unifiedLinksValid: this.unifiedLinksValid(),
		};

		if (!_.isUndefined(this.additionalInformation.unifiedLinksVisibility)
				&& !_.isEmpty(this.additionalInformation.unifiedLinksVisibility)) {
			event.unifiedLinksVisibility = cloneDeep(this.additionalInformation.unifiedLinksVisibility);
		}

		if (!_.isEmpty(this.additionalInformation.unifiedLinks)) {
			event.unifiedLinks = cloneDeep(this.additionalInformation.unifiedLinks).map((link: any) => {
				delete link.id;
				return link;
			});
			if (this.additionalInformation.enableUnifiedLinks) {
				event.unifiedLinksEnabled = true;
			}
		}

		if (!_.isUndefined(visibilityEvent)){
			event.unifiedLinksVisibility = cloneDeep(visibilityEvent.unifiedLinksVisibility);
			event.unifiedLinksValid = event.unifiedLinksValid && visibilityEvent.unifiedLinkVisibilityValid;
		}

		this.unifiedLinksUpdated.emit(event);
	}

	private unifiedLinksValid = (): boolean => {
		if (_.isUndefined(this.unifiedLinksForm)) {
			//not rendered;
			return true;
		}
		if (!this.unifiedLinksForm.valid) {
			return false;
		}

		return this.unifiedLinksPopulated();
	}

	unifiedLinksPopulated = (): boolean => {
		if (!this.additionalInformation.enableUnifiedLinks) {
			return true;
		}
		return !_.isUndefined(this.additionalInformation.unifiedLinks) &&
			this.additionalInformation.unifiedLinks.length > 0;
	}

}

app.directive('oauthClientDetailsUnifiedLinksComponent',
	downgradeComponent({ component: OauthClientDetailsUnifiedLinksComponent }) as angular.IDirectiveFactory);
