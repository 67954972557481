import { Pipe, PipeTransform } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Pipe({
	name: 'i18n'
})
export class I18nPipe implements PipeTransform {

	constructor(
		private locale: CxLocaleService
	) {}

	transform(value: string, args?: any, fallback?: string): string {
		return this.locale.getString(value, args, fallback);
	}
}
