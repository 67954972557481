import LinkedFilterExport from '@cxstudio/dashboards/widgets/linked-filter-export';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';
import * as _ from 'underscore';
import PDFExportUtils from './pdf-export.utils';
import Widget from '@cxstudio/dashboards/widgets/widget';

export default class LinkedFilterContainer {
	private container: {[widgetId: number]: LinkedFilter[]} = {};

	static initWidgetLinking(widgets: Widget[]): void {
		let widgetMap: {[widgetId: number]: Widget} = {};
		_.each(widgets, (widget) => {
			widgetMap[widget.id] = widget;
			delete widget.linkedTo;
		});

		_.each(widgets, (widget) => {
			let existingWidgets = [];
			_.each(widget.linkedWidgets, linkedId => {
				let linkedWidget = widgetMap[linkedId];
				if (linkedWidget) {
					existingWidgets.push(linkedId);
					linkedWidget.linkedTo = linkedWidget.linkedTo || [];
					if (!linkedWidget.linkedTo.contains(widget.id))
						linkedWidget.linkedTo.push(widget.id);
				}
			});
			if (existingWidgets.length)
				widget.linkedWidgets = existingWidgets;
			else delete widget.linkedWidgets;
		});
	}

	getAllFilters(): {[widgetId: number]: LinkedFilter[]} {
		return this.container;
	}
	getFilters(widgetId: number): LinkedFilter[] {
		return this.container[widgetId] || [];
	}
	setFilters(widgetId: number, filters: LinkedFilter[]): void {
		this.container[widgetId] = filters;
	}
	reset(): void {
		this.container = {};
	}

	toExport(widgets: any[]): {[widgetId: number]: LinkedFilterExport[]} {
		return PDFExportUtils.toExport(
			Object.keys(this.container), widgets,
			widgetId => _.map(this.getFilters(widgetId), (filter) => {
				return new LinkedFilterExport(
					PDFExportUtils.convertIdToPosition(filter.id, widgets),
					JSON.stringify(_.omit(filter.point, ['_element'])), // element is too big for serialization
				);
			})
		);
	}

	setFromExport(widgetLinkingParams: {[key: number]: LinkedFilterExport[]}, widgets: any[]): void {
		_.each(widgetLinkingParams, (filters: LinkedFilterExport[], widgetPositionId: string) => {
			if (_.isUndefined(widgetPositionId)) {
				return;
			}

			let linkedFilters = _.chain(filters)
			.map((filter: LinkedFilterExport) => {
				let parent = PDFExportUtils.findWidgetByPositionId(filter.parentPositionId, widgets);
				return new LinkedFilter(
					parent && parent.id,
					parent,
					JSON.parse(filter.point),
				);
			}).filter((filter) => {
				return !!filter.widget;
			}).value();

			let widget = PDFExportUtils.findWidgetByPositionId(Number(widgetPositionId), widgets);
			if (widget) {
				this.setFilters(widget.id, linkedFilters);
			}
		});
	}
}
