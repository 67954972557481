interface PropertyState<T> {
	initial: T;
	current?: T;
}

export interface PropertyValue<T> {
	id: number;
	value: T;
}

export class PropertyChanges<T> {
	private changesMap: {[id: number]: PropertyState<T>};

	constructor() {
		this.changesMap = {};
	}

	addChange(id: number, currentValue: T, newValue: T): void {
		if (_.isUndefined(this.changesMap[id])) {
			this.changesMap[id] = { initial: currentValue };
		}
		this.changesMap[id].current = newValue;
	}

	hasChanges(): boolean {
		return !_.isEmpty(this.getChanges());
	}

	getChanges(): PropertyValue<T>[] {
		let changesList = _.mapObject(this.changesMap, (val, key) => {
			if (val.current === val.initial) return;
			return {
				id: +key,
				value: val.current
			};
		});
		return _.filter(changesList, (change) => !!change);
	}

	getInitial(): PropertyValue<T>[] {
		let changesList = _.mapObject(this.changesMap, (val, key) => {
			if (val.current === val.initial) return;
			return {
				id: +key,
				value: val.initial
			};
		});
		return _.filter(changesList, (change) => !!change);
	}
}