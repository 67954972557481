import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { SpineLaneDefinition, SpineLaneType } from '@cxstudio/conversation/entities/spine-lane.class';

export enum SpineLaneCategory {
	scorecard = 'scorecard',
	metric = 'metric',
	recommended = 'recommended',
	model = 'model',
	attribute = 'attribute',
}

export class SpineLaneCategoryUtils {
	static getLaneCategory(laneDefinition: SpineLaneDefinition): SpineLaneCategory {
		if (laneDefinition.name.startsWith('cb_'))
			return SpineLaneCategory.attribute;
		if (laneDefinition.name === SingleLaneEnrichmentTypes.SCORECARD)
			return SpineLaneCategory.scorecard;
		if (laneDefinition.name === SingleLaneEnrichmentTypes.SENTENCE_TYPE)
			return SpineLaneCategory.recommended;
		if (laneDefinition.laneType === SpineLaneType.TOPIC)
			return SpineLaneCategory.model;
		else return SpineLaneCategory.metric;
	}
}
