export enum DashboardChangeType {
	MOVED = 'MOVED', // LAYOUT
	RESIZED = 'RESIZED', // LAYOUT
	ADDED = 'ADDED', // CREATE + LAYOUT
	UPDATED = 'UPDATED', // UPDATE + LINKING
	RENAMED = 'RENAMED',
	DELETED = 'DELETED', // DELETE + LINKING + LAYOUT
	BULK_ADDED = 'BULK_ADDED', // BULK_CREATE + LAYOUT
	BULK_MOVED = 'BULK_MOVED', // LAYOUT
	BULK_DELETED = 'BULK_DELETED', // BULK_DELETE + LINKING + LAYOUT
	LINKED = 'LINKED', // LINKING
	RECOLORED = 'RECOLORED', // UPDATE
	RENAME = 'RENAME',
	OWNERSHIP_CHANGED = 'OWNERSHIP_CHANGED', // OWNERSHIP_CHANGED
	PROPERTIES_CHANGED = 'PROPERTIES_CHANGED'
}
