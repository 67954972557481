import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { ISettingsGroup } from './settings-group.interface';
import { ShowQuantitySettings } from '../settings.interfaces';


export class ShowQuantitySettingsGroup implements ISettingsGroup<ShowQuantitySettings> {

	static readonly DEFAULTS: ShowQuantitySettings = {
		sortOrder: 'desc',
		size: 10,
		sortBy: 'volume'
	};

	settings: ShowQuantitySettings;
	sortOptions;
	sentenceLevel: boolean;

	constructor(
		source: ShowQuantitySettings,
		sortOptions,
		sentenceLevel: boolean = false
	) {
		this.settings = source
			? _.pick(source, ['sortOrder', 'size', 'minDocCount', 'sortBy'])
			: {} as ShowQuantitySettings;

		this.settings = _.defaults(this.settings, ShowQuantitySettingsGroup.DEFAULTS);
		this.sortOptions = sortOptions;
		this.sentenceLevel = sentenceLevel;
	}

	postProcessSettings(): ShowQuantitySettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = cloneDeep(ShowQuantitySettingsGroup.DEFAULTS);
	}

}
