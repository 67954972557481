import * as cloneDeep from 'lodash.clonedeep';
import ReferenceLine from './reference-line';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { ColorConstants, CustomShadows } from '@cxstudio/reports/utils/color/color-constants';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import TimeReferenceLine from './time-reference-line';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';

@Injectable({
	providedIn: 'root'
})
export class ReferenceLinesLabelsUtils {

	constructor(
		private readonly applicationThemeService: ApplicationThemeService
	) {}

	// Metric reference lines
	getMetricRefLineLabel = (
		refLine: ReferenceLine, isHorizontalBar: boolean, isScatter: boolean
	): Highcharts.AxisPlotLinesLabelOptions => {
		if (!refLine.label)
			return null;
		let align;
		let verticalAlign;
		let x;
		let y;

		if (refLine.axis === PlotLineAxis.primary) {
			verticalAlign = 'bottom';
			align = isHorizontalBar ? 'right' : 'left';
			if (isHorizontalBar) {
				x = 5;
				y = -10;
			} else {
				x = 10;
				y = -5;
			}
		} else {
			verticalAlign = isScatter ? 'bottom' : 'top';
			align = isHorizontalBar ? 'left' : 'right';
			if (isScatter) {
				x = 5;
				y = -10;
			} else if (isHorizontalBar) {
				x = 5;
				y = 10;
			} else {
				x = -10;
				y = -5;
			}
		}

		let label: Highcharts.AxisPlotLinesLabelOptions = { x, y, text: refLine.label, align, verticalAlign};
		this.applyLabelStyle(label);
		if (isHorizontalBar) {
			this.adjustHorizontalMetricRefLineLabelPosition(label);
		}

		return label;
	}

	private adjustHorizontalMetricRefLineLabelPosition(label: Highcharts.AxisPlotLinesLabelOptions): void {
		label.rotation = 270;
		label.verticalAlign = 'bottom';
		label.textAlign = 'left';
		label.x = -5;
	}

	// Time Reference lines - Events
	getEventLabel = (timeRefLine: TimeReferenceLine, isHorizontalBar: boolean,
			spansCreated: boolean): Highcharts.AxisPlotLinesLabelOptions => {
		let label: Highcharts.AxisPlotLinesLabelOptions =  this.getTimeReferenceDefaultLabel(timeRefLine, isHorizontalBar);
		this.adjustEventLabelPosition(label, isHorizontalBar, spansCreated);
		return label;
	}

	private adjustEventLabelPosition(
		label: Highcharts.AxisPlotLinesLabelOptions, isHorizontalBar: boolean, spansCreated: boolean
	): void {
		if (!isHorizontalBar) {
			this.adjustVerticalEventLabel(label);
		}
		this.setAxisIndentForEventLabel(label, spansCreated, isHorizontalBar);
	}

	private adjustVerticalEventLabel(label: Highcharts.AxisPlotLinesLabelOptions): void {
		label.rotation = 270;
		label.verticalAlign = 'top';
		label.textAlign = 'right';
		label.x = -5;
	}

	private setAxisIndentForEventLabel(
		label: Highcharts.AxisPlotLinesLabelOptions, spansCreated: boolean, isHorizontalBar: boolean
	): void {
		if (spansCreated) {
			if (isHorizontalBar) {
				label.x = -25;
			} else {
				label.y = 30;
			}
		}
	}

	// Time Reference lines - Spans
	getSpanLabel = (timeRefLine: TimeReferenceLine, isHorizontalBar: boolean): Highcharts.AxisPlotLinesLabelOptions => {
		let label: Highcharts.AxisPlotLinesLabelOptions = this.getTimeReferenceDefaultLabel(timeRefLine, isHorizontalBar);
		this.adjustSpanLabelPosition(label, isHorizontalBar);
		return label;
	}

	private adjustSpanLabelPosition(label: Highcharts.AxisPlotLinesLabelOptions, isHorizontalBar: boolean): void {
		if (isHorizontalBar) {
			this.adjustHorizontalSpanLabel(label);
		} else {
			label.y = 20;
		}
	}

	private adjustHorizontalSpanLabel(label: Highcharts.AxisPlotLinesLabelOptions): void {
		label.rotation = 270;
		label.textAlign = 'center';
		label.x = -5;
		label.y = 0;
	}

	private getTimeReferenceDefaultLabel(
		timeRefLine: TimeReferenceLine, isHorizontalBar: boolean
	): Highcharts.AxisPlotLinesLabelOptions {
		let label: Highcharts.AxisPlotLinesLabelOptions = {
			text: timeRefLine.displayLabel
		};

		if (isHorizontalBar) {
			label.x = -10;
			label.align = 'right';
		} else {
			label.y = 10;
		}

		this.applyLabelStyle(label);
		return label;
	}

	private applyLabelStyle = (label: Highcharts.AxisPlotLinesLabelOptions): void => {
		if (this.applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER)) {
			label.style = {
				color: ColorConstants.DARK_MODE_TEXT_COLOR,
				textShadow: CustomShadows.DARK_MODE_REFERENCE_LINE_LABEL_SHADOW
			} as any;
		} else {
			label.style = {
				color: ColorConstants.CHARCOAL,
				textShadow: CustomShadows.REFERENCE_LINE_LABEL_SHADOW
			} as any;
		}
	}

	createLabelLine = (referenceLine: TimeReferenceLine | ReferenceLine): TimeReferenceLine | ReferenceLine => {
		let line: TimeReferenceLine | ReferenceLine = cloneDeep(referenceLine);
		line.labelLine = true;
		line.displayLabel = line.label;
		line.label = '';
		line.color = 'transparent';
		return line;
	}
}
