import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BookEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { BookEditTooltipComponent } from '@app/modules/book/book-editor/book-edit-tooltip/book-edit-tooltip.component';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { ColumnField, ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { Security } from '@cxstudio/auth/security-service';
import { BookListHelperService } from '@app/modules/book/book-list-helper.service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { ColDef, GridApi, GridReadyEvent, RowClickedEvent, RowNode } from 'ag-grid-community';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { DashboardColumnField } from '@app/modules/dashboard-list/home/dashboards-table/dashboards-table.component';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';

@Component({
	selector: 'book-dashboards-table',
	template: `
<default-objects-table class="fill-container"
	tableName="bookDashboards"
	[suppressRowDeselection]="true"
	[columnDefs]="columnDefs"
	[defaultColDef]="defaultColDef"
	[rowData]="dashboardTree"
	[getNameIcon]="getNameIcon"
	[rowClassRules]="{'text-gray-300': isDisallowedForSelection}"
	[quickFilterText]="textFilter"
	(gridReady)="onGridReady($event)"
	(rowClicked)="onRowClicked($event)">
</default-objects-table>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookDashboardsTableComponent extends SelfCleaningComponent implements OnInit, OnChanges {

	@Input() book: Book;
	@Input() selectedDashboard?: Dashboard;
	@Input() textFilter: string;
	@Output() selectedDashboardChange = new EventEmitter<Dashboard>();

	dashboardTree: Dashboard[];
	columnDefs: ColDef[];
	defaultColDef: ColDef;
	gridApi: GridApi;

	constructor(
		private readonly ref: ChangeDetectorRef,
		protected readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		private readonly objectListColumns: ObjectListColumnsService,
		private readonly eventBus: GlobalEventBus,
		@Inject('security') private readonly security: Security,
	) {
		super(locale);
	}

	ngOnInit(): void {
		this.columnDefs = [
			this.objectListColumns.labelsColumn(),
			this.objectListColumns.dateColumn(ColumnField.MODIFIED_DATE, this.locale.getString('dashboard.modifiedDate')),
			this.objectListColumns.ownerColumn(ColumnField.OWNER, this.locale.getString('common.owner')),
			this.objectListColumns.sharingStatusRendererColumn(),
			this.objectListColumns.numberColumn(DashboardColumnField.VIEWS, this.locale.getString('dashboard.views')),
		];

		this.defaultColDef = {
			tooltipComponent: BookEditTooltipComponent,
		};

		this.addSubscription(this.dashboardListService.getDashboards().subscribe(dashboardTree => {
			this.dashboardTree = dashboardTree;
			this.gridApi?.setRowData(this.dashboardTree);
			this.ref.markForCheck();
		}));

		this.addListener(this.eventBus.subscribe(BookEvent.SCROLL_TO_DASHBOARD, (event, dashboardId) => this.markSelectedDashboard(dashboardId)));

		this.dashboardListService.reloadDashboards();
	}

	ngOnChanges(change: SimpleChanges<BookDashboardsTableComponent>): void {
		if (ChangeUtils.hasChange(change.selectedDashboard)) {
			this.markSelectedDashboard(change.selectedDashboard.currentValue?.id);
		}
	}

	private markSelectedDashboard(dashboardId: number) {
		if (!this.gridApi)
			return;
		if (dashboardId) {
			this.gridApi.forEachLeafNode(node => {
				if (node.data.id === dashboardId) {
					node.setSelected(true);
					this.gridApi.ensureNodeVisible(node);
				}
			});
		} else {
			this.gridApi.deselectAll();
		}
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.markSelectedDashboard(this.selectedDashboard?.id);
	}

	onRowClicked(event: RowClickedEvent): void {
		let dashboard = event.data as Dashboard;
		if (BookListHelperService.isBookAllowedItem(this.book, dashboard, this.security.getEmail())) {
			this.selectedDashboardChange.emit(dashboard);
		}
	}

	isDisallowedForSelection = (node: RowNode): boolean => {
		let dashboard = node.data as Dashboard;
		return !(dashboard.type === DashboardType.FOLDER
	 			|| BookListHelperService.isBookAllowedItem(this.book, dashboard, this.security.getEmail()));
	}

	getNameIcon = (data: Dashboard): string => {
		return IconsMap.dashboardIconsMap[data.type];
	}
}

app.directive('bookDashboardsTable', downgradeComponent({component: BookDashboardsTableComponent}) as angular.IDirectiveFactory);
