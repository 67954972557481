<div class="ph-16">
	<label class="d-block mb-4"><strong>{{'packages.findPackage'|i18n}}</strong>
	</label>
	<input
		type="search"
		class="grid-search-bar mb-16"
		attr.aria-label="{{'common.find'|i18n}}"
		[(ngModel)]="packageSearch">

	<div [ngBusy]="loadingTemplates" class="ph-16 d-flex">
		<table class="table row-height">
			<caption>{{'packages.availablePackages'|i18n}}</caption>
			<thead>
				<tr>
					<th></th>
					<th>{{'packages.packageType'|i18n}}</th>
					<th>{{'packages.packageDesc'|i18n}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="row-height" *ngFor="let package of packages | filterBy:['name']:packageSearch" (click)=rowButton.click()>
					<td class="text-center lh-1">
						<div class="d-flex align-center justify-center">
							<input class="btn-radio" name="packageSelected" #rowButton type="radio" (click)="onSelect(package)">
						</div>
					</td>
					<td>{{package.name}}</td>
					<td>{{package.description}}</td>
				</tr>
			</tbody>
		</table>

		<aside class="p-16 ml-16 w-33-percent border-l-2 border-gray-300 border-l-solid">
			<label class="d-block mb-4"><strong>{{'packages.aboutPackage'|i18n}}</strong>
			</label>
			<br>
			<div *ngIf="isPackageSelected()">
				<label class="d-block mb-4"><strong>{{'packages.nameLabel'|i18n}}</strong>
				</label>
				<p>{{selectedPackage.name}}</p>
				<label class="d-block mb-4"><strong>{{'packages.useCaseLabel'|i18n}}</strong>
				</label>
				<label [i18n]="'packages.useCaseDesc'" [i18nParams]="{name: selectedPackage.name}"></label>
			</div>
			<div *ngIf="!isPackageSelected()">
				<p>{{'packages.defaultPackageMessage'|i18n}}</p>
			</div>
		</aside>
	</div>
	<button [ngBusy]="loadingTemplates" type="button"
			class="btn btn-primary float-right"
			(click)=runPackage()
			[i18n]="'packages.runPackage'"
			[disabled]="!isPackageSelected()">
	</button>
</div>
