import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';

export default class Authorization {

	constructor(
		private security: Security
	) {}

	hasSystemAccess = (): boolean => {
		return this.security.isSysAdmin() || this.security.isLiteAdmin();
	}

	hasUserAdministrationAccess = (): boolean => {
		return this.security.has('manage_users')
			|| this.security.has('manage_groups')
			|| this.security.isUserGroupOwner();
	}

	hasMasterAccountManagementAccess = (): boolean => {
		return this.security.has('manage_settings') ||
		this.security.has('conduct_security_audit') ||
		this.hasManageOrganizationsAccess();
	}

	hasAdminAccess = (): boolean => {
		return this.hasUserAdministrationAccess()
			|| this.hasMasterAccountManagementAccess()
			|| this.hasSystemAccess();
	}

	hasRecycleBinAccess = (): boolean => {
		return this.security.has('delete_dashboard')
			|| this.security.has(MasterAccountPermissionAction.CREATE_FILTER)
			|| this.security.has(MasterAccountPermissionAction.CREATE_METRIC);
	}

	hasProjectsManagementAccess = (): boolean => {
		return this.security.has('content_provider')
			&& this.security.has('manage_projects');
	}

	hasViewProjectsAccess = (): boolean => {
		return this.security.has('content_provider');
	}

	hasMetricManagementAccess = (): boolean => {
		return this.security.has('content_provider');
	}

	hasFilterManagementAccess = (): boolean => {
		return this.security.has('content_provider');
	}

	hasLexiconSuggestionsAccess = (): boolean => {
		return this.security.has('content_provider');
	}

	linkedToCP = (): boolean => {
		return this.security.has('content_provider');
	}

	hasAlertManagementAccess = (): boolean => {
		return this.security.has('manage_designer_alerts')
			|| this.security.has('manage_studio_alerts');
	}

	hasInboxTemplateManagementAccess = (): boolean => {
		return this.security.has('manage_alert_templates');
	}

	hasDriversManagementAccess = (): boolean => {
		return this.security.has('content_provider')
			&& this.security.has('manage_drivers');
	}

	hasManageSettingsAccess = (): boolean => {
		return this.security.has('manage_settings');
	}

	hasCreateDashboardAccess = (): boolean => {
		return this.security.has('create_dashboard')
			|| this.security.has('edit_dashboard');
	}

	hasScorecardsAccess = (): boolean => {
		return this.security.has('manage_scorecards');
	}

	hasManageOrganizationsAccess = (): boolean => {
		return this.security.has('manage_organizations');
	}

	hasInteractionExplorerAccess = (): boolean => {
		return this.security.has('view_interaction_explorer');
	}

	hasSampledAuditsAccess = (): boolean => {
		return this.security.has('sampled_audits');
	}

	hasStudioAdminProjectAccess = (): boolean => {
		return this.security.isAnyAdmin()
			|| this.security.has('account_owner')
			|| (this.security.has('conduct_security_audit')
				&& (this.security.isAdminOrgUser() || this.security.has('customer_admin')));
	}

	hasManageTemplatePermission = (): boolean => {
		return this.security.has(ApplicationPermissionAction.MANAGE_INTERNAL_TEMPLATES);
	}

	hasShareTemplatePermission = (): boolean => {
		return this.security.has(ApplicationPermissionAction.SHARE_INTERNAL_TEMPLATES);
	}

	hasCreateTemplatePermission = (): boolean => {
		return this.security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES);
	}
}

app.service('authorization', Authorization);
