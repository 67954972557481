<div class="panel panel-default" id="password-policy">
    <div class="panel-heading">{{'mAccount.passwordSettings'|i18n}}</div>
    <div class="panel-body clearfix">
        <form class="form-horizontal" name="passwordPolicyForm" #passwordPolicyForm="ngForm" *ngIf="passwordPolicy">
            <div class="row">
                <div class="columns-3 mb-8">
	                <div class="form-group" show-errors>
                           <div class="col-lg-11">
                               <input type="text" [required]="true" id="_t-password-min-length" name="minLength"
                                   [max]="100" [min]="0" #minLength="ngModel" [pattern]="NUMBER_PATTERN" required 
                                   class="form-control" [(ngModel)]="passwordPolicy.minLength">
                               <h3 class="control-label">
                                   {{'mAccount.passwordMinLength'|i18n}}
                               </h3>
                               <p class="help-block">
                                   {{'mAccount.passwordMinLengthDescr'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="minLength.errors?.required">
                                   {{'mAccount.shouldHaveValue'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="minLength.errors?.pattern">
                                   {{'mAccount.allowedOnlyDigits'|i18n}}
                               </p> 
                               <p class="help-block" *ngIf="minLength.errors?.max 
                                                                    || minLength.errors?.min">
                                   {{'mAccount.shouldBeInRange'|i18n}}
                                   
                               </p>
                           </div>
	                </div>
	                <div class="form-group" show-errors>
                           <div class="col-lg-11">
                               <input type="text" required id="_t-password-max-age" name="maxAgeInDays"
                                   [max]="100" [min]="0" #maxAgeInDays="ngModel" [pattern]="NUMBER_PATTERN" required
                                   class="form-control" [(ngModel)]="passwordPolicy.maxAgeInDays">
                               <h3 class="control-label">
                                   {{'mAccount.passwordMaxAge'|i18n}}
                               </h3>
                               <p class="help-block">
                                   {{'mAccount.passwordMaxAgeDescr'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="maxAgeInDays.errors?.required">
                                   {{'mAccount.shouldHaveValue'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="maxAgeInDays.errors?.pattern">
                                   {{'mAccount.allowedOnlyDigits'|i18n}}
                               </p> 
                               <p class="help-block" *ngIf="maxAgeInDays.errors?.max 
                                                                    || maxAgeInDays.errors?.min">
                                   {{'mAccount.shouldBeInRange'|i18n}}
                                   
                               </p>
                           </div>
	                </div>
	                <div class="form-group">
                           <div class="col-lg-11">
                               <input type="checkbox" name="requireSpecialChars"
                                   class="form-control" id="_t-password-special-chars" [(ngModel)]="passwordPolicy.requireSpecialChars">
                               <h3 class="control-label">
                                   {{'mAccount.passwordSpecialChars'|i18n}}
                               </h3>
                               <p class="help-block">
                                   {{'mAccount.passwordSpecialCharsDescr'|i18n}}
                               </p>
                           </div>
	                </div>
	                <div class="form-group" show-errors>
                           <div class="col-lg-11">
                               <input type="text" id="_t-password-history" name="historySize"
                                   [max]="100" [min]="0" #historySize="ngModel" [pattern]="NUMBER_PATTERN" required
                                   class="form-control" [(ngModel)]="passwordPolicy.historySize">
                               <h3 class="control-label">
                                   {{'mAccount.passwordHistory'|i18n}}
                               </h3>
                               <p class="help-block">
                                   {{'mAccount.passwordHistoryDescr'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="historySize.errors?.required">
                                   {{'mAccount.shouldHaveValue'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="historySize.errors?.pattern">
                                   {{'mAccount.allowedOnlyDigits'|i18n}}
                               </p> 
                               <p class="help-block" *ngIf="historySize.errors?.max 
                                                                    || historySize.errors?.min">
                                   {{'mAccount.shouldBeInRange'|i18n}}
                                   
                               </p>
                           </div>
	                </div>
	                <div class="form-group" show-errors>
                           <div class="col-lg-11">
                               <input id="_t-password-lockout-threshold" name="lockoutThreshold" type="text" 
                                   [max]="100" [min]="0" #lockoutThreshold="ngModel" [pattern]="NUMBER_PATTERN" required
                                   class="form-control" [(ngModel)]="passwordPolicy.lockoutThreshold">
                               <h3 class="control-label">
                                   {{'mAccount.passwordLockoutThreshold'|i18n}}
                               </h3>
                               <p class="help-block">
                                   {{'mAccount.passwordLockoutThresholdDescr'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="lockoutThreshold.errors?.required">
                                   {{'mAccount.shouldHaveValue'|i18n}}
                               </p>
                               <p class="help-block" *ngIf="lockoutThreshold.errors?.pattern">
                                   {{'mAccount.allowedOnlyDigits'|i18n}}
                               </p> 
                               <p class="help-block" *ngIf="lockoutThreshold.errors?.max 
                                                                    || lockoutThreshold.errors?.min">
                                   {{'mAccount.shouldBeInRange'|i18n}}
                                   
                               </p>
                           </div>
	                </div>
	                <div class="form-group" show-errors>
						<div class="col-lg-11">
							<input type="text" id="_t-password-lockout-duration" name="lockoutDuration"
								[max]="100" [min]="0" #lockoutDuration="ngModel" [pattern]="NUMBER_PATTERN" required
								class="form-control" [(ngModel)]="passwordPolicy.lockoutDurationInMinutes">
							<h3 class="control-label">
								{{'mAccount.passwordLockoutDuration'|i18n}}
							</h3>
							<p class="help-block">
								{{'mAccount.passwordLockoutDurationDescr'|i18n}}
							</p>
							<p class="help-block" *ngIf="lockoutDuration.errors?.required">
								{{'mAccount.shouldHaveValue'|i18n}}
							</p>
							<p class="help-block" *ngIf="lockoutDuration.errors?.pattern">
								{{'mAccount.allowedOnlyDigits'|i18n}}
							</p> 
							<p class="help-block" *ngIf="lockoutDuration.errors?.max 
																	|| lockoutDuration.errors?.min">
								{{'mAccount.shouldBeInRange'|i18n}}								
							</p>
						</div>
					</div>
                </div>
            </div>
            <button id="btn-password-policy-save" type="submit" class="btn btn-primary" 
                (click)="savePasswordPolicy()" [disabled]="passwordPolicyForm.invalid">{{'common.save'|i18n}}</button>
        </form>
    </div>
</div>