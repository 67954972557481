import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ListOption } from '@app/shared/components/forms/list-option';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

@Component({
	selector: 'object-viewer-type-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<radio-buttons [options]="types"
		[ngModel]="value"
		(onChange)="valueChange.emit($event)">
	</radio-buttons>`
})
export class ObjectViewerTypeSelectorComponent implements OnInit {

	@Input() value: WidgetType;
	@Output() valueChange = new EventEmitter<WidgetType>();
	types: ListOption<WidgetType>[];

	constructor(private locale: CxLocaleService) {}

	ngOnInit(): void {
		this.types = [
			{ value: WidgetType.OBJECT_VIEWER, name: this.locale.getString('widgetSettings.viewRubric') },
			{ value: WidgetType.MODEL_VIEWER, name: this.locale.getString('widgetSettings.viewModel') },
		];
	}

}

app.directive('objectViewerTypeSelector', downgradeComponent({component: ObjectViewerTypeSelectorComponent}) as angular.IDirectiveFactory);
