import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import * as _ from 'underscore';

export class InfiniteListSearcher {

	static findNextPage(dataFn: (page: number) => Promise<any[]>, 
		fieldName: string, currentId: number, currentPage: number, pageChange: number, reverse?: boolean): Promise<number> {
		let newPage = currentPage + pageChange * (reverse ? -1 : 1);
		if (newPage < 0) {
			if (Math.abs(pageChange) > 1)
				return this.findNextPage(dataFn, fieldName, currentId, currentPage, pageChange / 2, reverse);
			else return Promise.resolve(currentPage);
		}
		return dataFn(newPage).then(data => {
			if (_.isEmpty(data)) {
				if (Math.abs(pageChange) > 1)
					return this.findNextPage(dataFn, fieldName, currentId, currentPage, pageChange / 2);
				else return currentPage;
			}
			if (this.foundNewDocument(data, fieldName, currentId, pageChange))
				return newPage;
			if (this.needGoFurther(data, fieldName, currentId))
				return this.findNextPage(dataFn, fieldName, currentId, newPage, pageChange * 2);
			if (this.needGoBack(data, fieldName, currentId)) {
				if (Math.abs(pageChange) > 1)
					return this.findNextPage(dataFn, fieldName, currentId, newPage, pageChange / 2, true);
				else return newPage;
			}
		});
	}

	static foundNewDocument(data: PreviewSentence[], compareField: string, currentId: number, direction: number): boolean {
		return direction > 0
			? data[0][compareField] === currentId && data.last()[compareField] !== currentId
			: data.last()[compareField] === currentId && data[0][compareField] !== currentId;
	}

	static needGoFurther(data: PreviewSentence[], compareField: string, currentId: number): boolean {
		return data[0][compareField] === currentId && data.last()[compareField] === currentId;
	}

	static needGoBack(data: PreviewSentence[], compareField: string, currentId: number): boolean {
		return data[0][compareField] !== currentId && data.last()[compareField] !== currentId;
	}

}