import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SchedulePeriod } from '@app/modules/dashboard/schedule/schedule-period';
import { ScheduleRecurrence } from '@app/modules/dashboard/schedule/schedule-recurrence';
import { ScheduleUtilsService } from '@app/modules/dashboard/schedule/schedule-utils.service';
import { WeekdayOption } from '@app/modules/dashboard/schedule/weekday-option';
import { ListOption } from '@app/shared/components/forms/list-option';
import { TimezoneService } from '@app/shared/services/timezone.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { UIOption } from '@clarabridge/unified-angular-components';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { MAX_ACTIVE_ALERTS, StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { NameService } from '@cxstudio/common/name-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

@Component({
	selector: 'alert-general-settings',
	templateUrl: './alert-general-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertGeneralSettingsComponent implements OnInit {

	@Input() alert: StudioAlert;
	@Input() project: IProjectSelection;
	@Output() validityChange = new EventEmitter<boolean>();

	allAlerts: StudioAlert[];
	enabledAlertLimitReached: boolean;
	zoneOffsetOptions: UIOption<string>[];
	frequencyOptions: ListOption<SchedulePeriod>[];
	weekDaysOptions: WeekdayOption[];
	recurrence: ScheduleRecurrence;

	loading: Promise<unknown>;

	SchedulePeriod = SchedulePeriod;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private timezoneService: TimezoneService,
		private scheduleUtilsService: ScheduleUtilsService,
		@Inject('alertingApiService') private readonly alertingApiService: AlertingApiService,
		@Inject('nameService') private readonly nameService: NameService,
	) { }

	ngOnInit(): void {
		this.populateGeneralAlertSettings();

		this.alert.trigger.scheduleType = this.scheduleUtilsService.getScheduleType(
			this.alert.cronExpression, this.frequencyOptions);

		this.loading = PromiseUtils.wrap(this.alertingApiService.getStudioAlerts(
			this.project.contentProviderId,
			this.project.accountId,
			this.project.projectId))
		.then(alerts => {
			this.allAlerts = alerts;
			this.enabledAlertLimitReached = this.getEnabledAlertsCount(alerts) >= MAX_ACTIVE_ALERTS;
			if (this.isNewAlert()) {
				this.alert.name = this.nameService.uniqueName('Alert', alerts, 'name');
				this.alert.enabled = !this.enabledAlertLimitReached;
			}
			this.ref.markForCheck();
		});
	}

	private populateGeneralAlertSettings(): void {
		this.zoneOffsetOptions = this.timezoneService.getUTCOffsets();
		if (this.alert.cronExpression) {
			let result = this.scheduleUtilsService.getScheduleInitialState(this.alert.cronExpression);
			this.recurrence = result.recurrence;
			this.weekDaysOptions = result.weekDaysOptions;
		} else {
			this.recurrence = {day: 1, week: 1, month: 1, dayOfMonth: 1};
			this.weekDaysOptions = this.scheduleUtilsService.getWeekDaysOptions();
		}
		this.frequencyOptions = [{
			name: this.locale.getString('alert.daily'),
			value: SchedulePeriod.DAILY,
		}, {
			name: this.locale.getString('alert.weekly'),
			value: SchedulePeriod.WEEKLY,
		}, {
			name: this.locale.getString('alert.monthly'),
			value: SchedulePeriod.MONTHLY,
		}];
	}

	private getEnabledAlertsCount(alerts: StudioAlert[]): number {
		let userEnabledAlertsCount: any = _.chain(alerts)
				.filter((alert) => alert.ownerId === this.alert.ownerId)
				.countBy('enabled')
				.value();

		return userEnabledAlertsCount.true || 0;
	}

	private isNewAlert(): boolean {
		return !this.alert.id;
	}

	updateWeekSelection(newDay): void {
		this.weekDaysOptions.forEach((day) => { day.value = false; });
		newDay.value = true;
		this.processCron();
	}

	processCron(): void {
		// need to get rid of this eventually
		const time = this.scheduleUtilsService.getDefaultTimeObject(this.alert.startDate, this.alert.timezoneOffset);

		let cronProcessingResult = this.scheduleUtilsService.processCron(
			this.alert.startDate,
			this.alert.endDate,
			this.alert.trigger.scheduleType,
			undefined,
			time,
			this.recurrence,
			DateTimeFormat.BASIC_DATE,
			this.weekDaysOptions,
			false);
		this.alert.cronExpression = cronProcessingResult.cronExpression;
	}

	setValid(valid: boolean): void {
		this.validityChange.emit(valid);
	}
}
