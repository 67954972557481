<div
	class="br-versions-panel docked-panel"
	[ngClass]="{
		'popped-out': opened,
		'top-floating-widget': opened
	}"
	[style.right]="layoutOffset + 'px'"
>
	<div
		class="docked-trigger hidden-xs"
		(click)="mainToggleHandler()"
		[style.left]="(opened ? 0 : -1 * layoutOffset) + 'px'"
		dockable-handle
	>
		<ng-content select="[trigger-handle]"></ng-content>
	</div>
	<ng-content></ng-content>
</div>
