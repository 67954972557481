import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'sharing-status-renderer',
	template: `<span *ngIf="!!value" [ngClass]="getIconClass()" [attr.title]="getIconTitle()"></span>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharingStatusRendererComponent implements ICellRendererAngularComp {

	value: SharingStatus;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
	) { }

	refresh(params: ICellRendererParams): boolean {
		this.agInit(params);
		this.ref.markForCheck();
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.value = this.getSharingStatus(params.value, params.data);
	}

	getIconClass(): string {
		switch (this.value) {
			case SharingStatus.PUBLIC: return 'br-public q-icon-globe';
			case SharingStatus.SHARED: return 'br-shared q-icon-users';
			case SharingStatus.PRIVATE: return 'br-private q-icon-user';
			default: return '';
		}
	}

	getIconTitle(): string {
		switch (this.value) {
			case SharingStatus.PUBLIC: return this.locale.getString('dashboard.public');
			case SharingStatus.SHARED: return this.locale.getString('dashboard.shared');
			case SharingStatus.PRIVATE: return this.locale.getString('dashboard.private');
			default: return '';
		}
	}

	private getSharingStatus(status: SharingStatus, data): SharingStatus {
		if (ObjectListUtils.isFolder(data)) return undefined;
		if (ObjectListUtils.isGeneratedAsset(data)) {
			return SharingStatus.PUBLIC;
		} else {
			return status || SharingStatus.PRIVATE;
		}
	}
}
