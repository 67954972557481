export enum MasterAccountPermissionAction {

	// Objects
	CREATE_DASHBOARD = 'create_dashboard',
	CREATE_METRIC = 'create_metric',
	CREATE_FILTER = 'create_filter',


	MANAGE_PROJECTS = 'manage_projects',
}
