<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.cpDetails' | i18n">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<form class="form-horizontal clearfix" name="cpDialog"  #cpDialog="ngForm"
		(submit)=save() role="form">
		<ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
			<li ngbNavItem>
				<a ngbNavLink
					[ngClass]="{'text-danger' : showErrorsForCP.length > 0 || versionError.length > 0}">
					{{'administration.contentProviderSettingsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<div class="row">
						<div class="col-xs-12">
							<div class="form-group">
								<label for="cpName" class="control-label col-md-3" [i18n]="'administration.name'"></label>
								<div class="col-md-9">
									<input id="cpName" name="cpname" focus-on-render class="form-control" [(ngModel)]=cp.name>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="endpoint" class="control-label col-md-3" [i18n]="'administration.endPoint'"></label>
								<div class="col-md-9">
									<input id="endpoint" name="endpoint" class="form-control" [(ngModel)]=cp.endPoint>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<div class="col-md-offset-3">
									<checkbox-button
										class="use-api-oauth"
										name="apiOauthEnabled"
										label="{{'administration.oauthApiAuth'|i18n}}"
										[(ngModel)]="cp.apiOauthEnabled">
									</checkbox-button>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="username" class="control-label col-md-3" [i18n]="'administration.username'"></label>
								<div class="col-md-9">
									<input id="username" name="username"
										class="form-control"
										[(ngModel)]=cp.username
										[disabled]="cp.apiOauthEnabled">
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="password" class="control-label col-md-3" [i18n]="'administration.password'"></label>
								<div class="col-md-9">
									<input id="password" name="password"
										type="password" class="form-control"
										[(ngModel)]=cp.password
										[disabled]="cp.apiOauthEnabled">
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="description" class="control-label col-md-3" [i18n]="'administration.description'"></label>
								<div class="col-md-9">
									<input id="description" name="description" class="form-control" [(ngModel)]=cp.description>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="type" class="control-label col-md-3" [i18n]="'administration.type'"></label>
								<div class="col-md-9">
									<input id="type" name="type" class="form-control" [ngModel]=cp.type disabled>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="version" class="control-label col-md-3"  [i18n]="'administration.version'"></label>
								<div class="col-md-9">
									<input id="version" name="version" class="form-control" [(ngModel)]=cp.cpVersion disabled>
								</div>
							</div>
							<div class="row help-block">
								<p class="text-danger col-xs-12 col-md-9 col-md-offset-3">{{versionError}}</p>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="apiVersion" class="control-label col-md-3"  [i18n]="'administration.apiVersion'"></label>
								<div class="col-md-9">
									<input id="apiVersion" name="apiVersion" class="form-control" [(ngModel)]=cp.apiVersion disabled>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="featureVersion" class="control-label col-md-3"  [i18n]="'administration.featureVersion'"></label>
								<div class="col-md-9">
									<input id="featureVersion" name="featureVersion" class="form-control" [(ngModel)]=cp.featureVersion disabled>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="kafkaBroker" class="control-label col-md-3"
									[i18n]="'administration.kafkaBroker'"></label>
								<div class="col-md-9">
									<input id="kafkaBroker" name="kafkaBroker"
										disabled
										class="form-control"
										[ngModel]=cp.kafkaBroker>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="voiceStorage" class="control-label col-md-3"
									[i18n]="'mAccount.fileStorageVociContentProviderName'"></label>
								<div class="col-md-9">
									<input id="voiceStorage" name="voiceStorage" class="form-control" [(ngModel)]=cp.voiceStorage>
								</div>
							</div>
							<div class="row help-block">
								<p *ngFor="let cperror of showErrorsForCP" class="col-md-9 text-danger col-md-offset-3">
									{{ cperror }}
								</p>
							</div>
						</div>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink
					[ngClass]="{'text-danger' : timeoutErrors.length > 0}">
					{{'administration.contentProviderTimeoutsTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<div>
						<div class="row">
							<div class="col-xs-12">
								<h3>{{'administration.contentProviderConfigTimeouts'|i18n }} {{'administration.timeoutMilliseconds'|i18n }}</h3>
								<div *ngFor="let timeout of requestConfigTimeouts; index as i" class="form-group">
									<label attr.for="config_{{i}}" class="control-label col-sm-5 col-md-3 timeout-label">{{timeout}}</label>
									<div class="col-sm-7 col-md-9">
										<input attr.id="config_{{i}}"
											name="{{timeout}}" attr.name="{{timeout}}"
											class="form-control"
											[(ngModel)]="cp.timeouts.configTimeouts[timeout]" type="number"
											min="1000" max="1800000">
									</div>
								</div>
							</div>

							<div class="col-xs-12">
								<h3>{{'administration.contentProviderMethodTimeouts'|i18n }} {{'administration.timeoutMilliseconds'|i18n }}</h3>
								<div *ngFor="let timeout of requestMethodTimeouts; index as i" class="form-group">
									<label attr.for="method_{{i}}" class="control-label col-sm-5 col-md-3 timeout-label">{{timeout}}</label>
									<div class="col-sm-7 col-md-9">
										<input attr.id="method_{{i}}"
											name="{{timeout}}" attr.name="{{timeout}}"
										 	class="form-control"
											[(ngModel)]="cp.timeouts.methodTimeouts[timeout]" type="number"
											min="1000" max="1800000">
									</div>
								</div>
							</div>

							<div class="col-xs-12">
								<h3>{{'administration.contentProviderDomainTimeouts'|i18n }} {{'administration.timeoutMilliseconds'|i18n }}</h3>
								<div *ngFor="let timeout of requestDomainTimeouts; index as i" class="form-group">
									<label attr.for="domain_{{i}}" class="control-label col-sm-5 col-md-3 timeout-label">{{timeout}}</label>
									<div class="col-sm-7 col-md-9">
										<input attr.id="domain_{{i}}"
											name="{{timeout}}" attr.name="{{timeout}}"
											 class="form-control"
											[(ngModel)]="cp.timeouts.domainTimeouts[timeout]" type="number"
											 min="1000" max="1800000">
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<p *ngFor="let timeoutError of timeoutErrors" class="text-danger help-block col-xs-12">
								{{ timeoutError }}
							</p>
						</div>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink
					[ngClass]="{'text-danger' : !cpDialog.valid}">
					{{'administration.contentProviderAuthTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<div class="row">
						<div class="col-xs-12">
							<div class="labeled-checkbox">
								<input name="enabled" id="enabled" type="checkbox" [(ngModel)]="cp.authSettings.enabled" (ngModelChange)="validate()">
								<label for="enabled" [i18n]="'common.enabled'"></label>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="clientId" class="control-label col-md-3"
									[attr.disabled]="!cp.authSettings.enabled ? true : null"
									[i18n]="'administration.contentProviderClientId'"></label>
								<div class="col-md-9">
									<input id="clientId" name="clientId"
										focus-on-render class="form-control"
										[(ngModel)]="cp.authSettings.clientId"
										[disabled]="!cp.authSettings.enabled">
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="form-group">
								<label for="redirectUri" class="control-label col-md-3"
									[attr.disabled]="!cp.authSettings.enabled ? true : null"
									[i18n]="'administration.contentProviderRedirectUri'"></label>
								<div class="col-md-9">
									<input id="redirectUri" name="redirectUri"
										class="form-control"
										[(ngModel)]="cp.authSettings.redirectUri"
										[disabled]="!cp.authSettings.enabled">
								</div>
							</div>
						</div>

						<div class="col-xs-12">
							<div class="form-group">
								<label for="scope" class="control-label col-md-3"
									[attr.disabled]="!cp.authSettings.enabled ? true : null"
									[i18n]="'administration.contentProviderScope'"></label>
								<div class="col-md-9">
									<tags-input
										class="w-100-percent"
										name="tags"
										[attr.disabled]="!cp.authSettings.enabled ? true : null"
										[(ngModel)]="cp.authSettings.scope"
										[addOnBlur]="true"
										[addOnEnter]="true"
										(ngModelChange)="validate()">
									</tags-input>
								</div>
							</div>
						</div>
						<div class="col-xs-12">
							<div class="row help-block">
								<p *ngIf="cpDialog.form.controls['tags']?.errors?.noScopes" class="col-md-9 text-danger col-md-offset-3">
									{{'administration.oauthScopeHasToBeAdded'|i18n}}
								</p>
							</div>
						</div>

						<div class="col-xs-12">
							<a class="br-delete" [attr.disabled]="!cp.authSettings.enabled ? true : null"
								(click)="cp.authSettings.enabled && importSettings()"
								[i18n]="'administration.contentProviderImportSettings'"></a>
						</div>

					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink
					[ngClass]="{'text-danger' : !ddaFormValid}">
					{{'administration.ddaIntegration'|i18n}}
				</a>
				<ng-template ngbNavContent>
					<discover-designer-api-integration 
						[cp]="cp"
						(validityChange)="updateDdaFormValidity($event)">
					</discover-designer-api-integration>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</form>
</div>

<div class="modal-footer">
	<button id="btn-cp-cancel" type="button" class="btn btn-secondary"
		(click)=cancel() [i18n]="'administration.cancel'"></button>
	<button id="btn-cp-save" type="button" class="btn btn-primary"
		(click)=save() [i18n]="'administration.save'" [disabled]="!cpDialog.valid || !ddaFormValid"></button>
</div>
