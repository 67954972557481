import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import ContentWidgetBuilder from './content-widget-builder';

export class ImageWidgetBuilder extends ContentWidgetBuilder {
	properties: ContentWidgetProperties;
	visualProperties: VisualProperties;

	static get(): ImageWidgetBuilder {
		return new ImageWidgetBuilder();
	}

	constructor() {
		super();
		this.properties = {} as ContentWidgetProperties;
		this.visualProperties = new VisualProperties();
		this.name = WidgetType.IMAGE;
		this.visualProperties.visualization = WidgetVisualization.IMAGE;
		this.properties.widgetType = WidgetType.IMAGE;
		this.properties.newTextFormat = true;
	}

	withWidth(width: number): ImageWidgetBuilder {
		this.width = width;
		return this;
	}

	withHeight(height: number): ImageWidgetBuilder {
		this.height = height;
		return this;
	}

	withPosition(posX, posY): ImageWidgetBuilder {
		this.posX = posX;
		this.posY = posY;
		return this;
	}

	withImageUrl(imageUrl: string): ImageWidgetBuilder {
		this.properties.imageUrl = imageUrl;
		return this;
	}
}
