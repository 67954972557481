import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';

export class ChatMessage {
	id: number; // first sentence id
	timestamp: number;
	channel: string;
	sentences: ConversationSentence[];
	groupedIds: number[];

	isSilence?: boolean;
	text?: string; // only for silence
	isSameChannelAsLast?: boolean;
	sentenceTopics: SentenceTopic[];
	participantIndex?: number;
}
