import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TimeToRunUtils } from '@app/modules/widget-container/widget-stats/time-to-run/time-to-run-utils';
import { Timeline } from '@app/modules/widget-container/widget-stats/timeline';
import { WidgetTiming } from '@cxstudio/reports/timing/widget-timing';

@Component({
	selector: 'time-to-run',
	template: `
<div class="time-to-run">
	<div class="time-to-run-value">{{formatValue(getTotal())}}</div>
	<div *ngFor="let item of getParts()">
		<div class="time-to-run-part-value">{{formatValue(item.total)}}</div>
		<div class="time-to-run-part-name">{{'widget.timelineType_' + item.type | i18n}}</div>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
	.time-to-run {
		.time-to-run-value {
			border-bottom: 1px solid;
		}
		.time-to-run-value, .time-to-run-part-value {
			width: 75px;
			text-align: right;
			margin-right: 10px;
		}

		.time-to-run-part-value, .time-to-run-part-name {
			display: inline-block;
		}
	}
	`]
})
export class TimeToRunComponent {
	@Input() widgetTiming: WidgetTiming;

	constructor(
		private locale: CxLocaleService,
	) { }


	getTotal(): number {
		return TimeToRunUtils.getTotal(this.widgetTiming);
	}

	getParts(): Timeline[] {
		return TimeToRunUtils.getParts(this.widgetTiming);
	}

	formatValue = (value: number): string => {
		return `${value} ${this.locale.getString('common.millisecondsPostfix')}`;
	}

}
