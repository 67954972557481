import * as _ from 'underscore';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import * as Highcharts from 'highcharts';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { Color } from '@cxstudio/reports/utils/color-utils.service';
import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ObjectUtils } from '@app/util/object-utils';

export interface IDataPointColorCacheEntry {
	pattern?: Highcharts.PatternObject;
	color: string;			// color associated with datapoint
}

export class ColorsCache {

	// track the last used color in each palette
	private paletteCaches: {
		[containerId: string]: {
			[paletteKey: string]: number;
		}
	};

	// track the last used color in the preview palette. Also, Real Data Preview should be off.
	private staticPaletteCaches: {
		[containerId: string]: {
			[paletteKey: string]: number;
		}
	};

	private caches: {
		[containerId: string]: {
			[key: string]: IDataPointColorCacheEntry;
		}
	};

	private patternCaches: {
		[containerId: string]: Highcharts.PatternObject[];
	};

	constructor(
		private chartAccessibilityService: ChartAccessibilityService,
		private applicationThemeService: ApplicationThemeService,
	) {
		this.caches = {};
		this.paletteCaches = {};
		this.staticPaletteCaches = {};
		this.patternCaches = {};
	}

	clear = (containerId: string): void => {
		delete this.caches[containerId];
		delete this.paletteCaches[containerId];
		delete this.staticPaletteCaches[containerId];
	}

	getCachedColor = (containerId: string, palette: WidgetColorPalette, groupName: string,
					itemName: string, isStatic: boolean = false): Color => {
		let cacheEntry = ObjectUtils.copy(this.getCachedEntry(containerId, palette, groupName, itemName, isStatic));
		return this.isPatternFillEnabled() ? cacheEntry.pattern : cacheEntry.color;
	}

	getCachedPattern = (containerId: string, color: string, index: number): Color => {
		if (!color || !this.isPatternFillEnabled()) return color;
		let patternsCache = this.getPatternsCache(containerId);
		let pattern = _.find(patternsCache, (item) => item.pattern.color === color);
		if (!pattern) {
			pattern = this.getPattern(index);
			pattern.pattern.color = color;
			patternsCache.push(pattern);
		}
		return pattern;
	}

	private isPatternFillEnabled = (): boolean => {
		return this.chartAccessibilityService.isPatternFillEnabled();
	}

	private getPatternsCache = (containerId: string): Highcharts.PatternObject[] => {
		if (!this.patternCaches[containerId])
			this.patternCaches[containerId] = [];
		return this.patternCaches[containerId];
	}

	private getCachedEntry = (containerId: string, palette: WidgetColorPalette,
		groupName: string, itemName: string, isStatic: boolean): IDataPointColorCacheEntry => {

		let cache = this.getContainerCache(containerId);
		let key = this.getCacheKey(this.getPaletteName(palette), groupName, itemName);

		if (_.isUndefined(cache[key])) {
			let index = this.getPaletteIndex(palette, containerId, groupName, isStatic);

			let color = palette.colors[index];
			let pattern = this.getPattern(index);
			pattern.pattern.color = color;

			cache[key] = {
				color,
				pattern,
			};
		}

		return cache[key];
	}

	private getPattern(index: number): Highcharts.PatternObject {
		let hc = Highcharts as any;
		let pattern = ObjectUtils.copy(hc.patterns[index % 10]);
		return { pattern };
	}

	private getPaletteIndex(palette: WidgetColorPalette, containerId: string, groupName: string, isStatic: boolean): number {
		let cache = isStatic ?
			this.getStaticPaletteContainerCache(containerId) : this.getPaletteContainerCache(containerId);

		let paletteKey = this.getPaletteKey(palette, groupName);
		if (cache[paletteKey] !== undefined) {
			cache[paletteKey]++;
			if (cache[paletteKey] > (palette.colors.length - 1)) {
				cache[paletteKey] = 0;
			}
		} else {
			cache[paletteKey] = 0;
		}

		return cache[paletteKey];
	}

	private getContainerCache(containerId: string): {[key: string]: IDataPointColorCacheEntry} {
		if (!this.caches[containerId])
			this.caches[containerId] = {};
		return this.caches[containerId];
	}

	private getPaletteContainerCache(containerId: string): {[key: string]: number} {
		if (!this.paletteCaches[containerId])
			this.paletteCaches[containerId] = {};
		return this.paletteCaches[containerId];
	}

	private getStaticPaletteContainerCache(containerId: string): {[key: string]: number} {
		if (!this.staticPaletteCaches[containerId])
			this.staticPaletteCaches[containerId] = {};
		return this.staticPaletteCaches[containerId];
	}

	private getPaletteName(palette: WidgetColorPalette): string {
		// temporary workaround to support a rare case when there are multiple different provider's palettes
		// if provider's palette, we use colors as a key, i.e. if multiple accounts have the same colors, they will behave
		// like it's the same palette
		// %%PROVIDER_PALETTE remove once implemented provider's palette

		let name = palette.name === ColorPaletteNames.PROVIDER_PALETTE
			? palette.colors.join('-')
			: palette.name;
		return this.applicationThemeService.isShowingDashboardDarkTheme() ? `${name}-dark` : name;
	}

	private getPaletteKey(palette: WidgetColorPalette, groupName: string): string {
		let paletteName = this.getPaletteName(palette);
		return `${paletteName}_${groupName}`;
	}

	private getCacheKey(paletteName: string, groupName: string, itemName: string): string {
		return `${paletteName}_${groupName}_${itemName}`;
	}
}

app.service('colorsCache', ColorsCache);
