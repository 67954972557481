import { SpellCheckError, SpellCheckResult } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spell-check-result';

export interface SpellCheckChunk {
	text: string;
	error: boolean;
}

export class SpellCheckUtils {
	static getChunks(result: SpellCheckResult): SpellCheckChunk[] {
		let chunks: SpellCheckChunk[] = [];
		let lastIndex = 0;
		result.errors.forEach((error: SpellCheckError) => {
			if (lastIndex !== error.start) {
				chunks.push({ text: result.text.slice(lastIndex, error.start), error: false });
			}
			chunks.push({ text: result.text.slice(error.start, error.end), error: true });
			lastIndex = error.end;
		});
		if (lastIndex !== result.text.length) {
			chunks.push({ text: result.text.slice(lastIndex), error: false });
		}
		return chunks;
	}
}
