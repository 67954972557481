// should match backend enum
export enum TemplatePlaceholderType {
	NUMERIC_ATTRIBUTE = 'NUMERIC_ATTRIBUTE',
	TEXT_ATTRIBUTE = 'TEXT_ATTRIBUTE',
	DATE_ATTRIBUTE = 'DATE_ATTRIBUTE',
	CUSTOM_METRIC = 'CUSTOM_METRIC',
	GROUP_METRIC = 'GROUP_METRIC',
	CALCULATION_METRIC = 'CALCULATION_METRIC',
	MODEL = 'MODEL',
	WORDS = 'WORDS',
	DRIVERS = 'DRIVERS',
	HIERARCHY = 'HIERARCHY',
	HIERARCHY_ENRICHMENT = 'HIERARCHY_ENRICHMENT',
	SCORECARD_METRIC = 'SCORECARD_METRIC',
}

export enum TemplateResourceType {
	ATTRIBUTE = 'ATTRIBUTE',
	METRIC = 'METRIC',
	SCORECARD_METRIC = 'SCORECARD_METRIC',
	MODEL = 'MODEL',
	WORDS = 'WORDS',
	HIERARCHY = 'HIERARCHY',
	HIERARCHY_ENRICHMENT = 'HIERARCHY_ENRICHMENT',
	DRIVERS = 'DRIVERS',
}

export interface TemplatePlaceholderKey {
	type: TemplatePlaceholderType;
	resourceType?: TemplateResourceType; // readonly property, ignored on backend
	identifier: string;
	assetIdentifier: string;
}
