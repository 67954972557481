import * as cloneDeep from 'lodash.clonedeep';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CaseEvent, CaseVisualization } from '@app/modules/widget-settings/case-viz-settings/case-visualization';
import { CaseAssigneeEventMetadata, CaseEventData, CasePriorityEventMetadata, HighchartsCase } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case';
import { CasePriorityTypesService } from '@app/modules/alert-subscription-template/services/case-priority-types.service';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import EngagorCaseAction, { EngagorActionType, EngagorStatus } from '@cxstudio/engagor/engagor-case-action';
import EngagorCaseDate from '@cxstudio/engagor/engagor-case-date';
import { ColorPaletteConstants } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { DateInterval } from '@app/modules/plot-lines/reference-lines/point-metadata-utils';
import * as moment from 'moment-timezone';
import { HighchartsCaseBucket } from './highcharts-case-bucket';
import { Color, ColorFunction, ColorUtils } from '@cxstudio/reports/utils/color-utils.service';

export interface ICaseEventPoint {
	caseItem: HighchartsCase;
	events: CaseEventData[];
	dateIndex: number;
	dateName: string;
}

export interface XRangeData {
	x: number;
	x2: number;
	y: number;
	object: HighchartsCase;
	color: Color;
}

export interface BubbleData {
	x: number;
	y: number;
	name: string;
	object: ICaseEventPoint;
	color: string;
}

@Injectable({
	providedIn: 'root'
})
export class HighchartsCaseUtilsService {

	constructor(
		private locale: CxLocaleService,
		private readonly casePriorityTypes: CasePriorityTypesService,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils
	) {}

	getXRangeData(caseRows: HighchartsCase[][], dateRanges: DateInterval[],
		colorFunction: ColorFunction = (color) => color): XRangeData[] {
		return _.chain(caseRows)
		.map((row, index) => row.map(caseItem => {
			let indexes = this.getCaseIndexes(caseItem, dateRanges);
			let color = colorFunction(caseItem.color, index);

			// utils colorFn does not honor the selected color for cases
			if (this.colorUtils.isColorPattern(color)) {
				color.pattern.color = caseItem.color;
			} else {
				color = caseItem.color;
			}

			return {
				x: indexes.startIndex,
				x2: indexes.endIndex,
				y: index,
				object: caseItem,
				color
			};
		}))
		.flatten()
		.value();
	}

	getBubblesSeriesData(caseRows: HighchartsCase[][], dateRanges: DateInterval[]): BubbleData[][] {
		let bubblesRowsData: BubbleData[][] = this.getBubblesRowsData(caseRows, dateRanges);
		return this.groupBubblesDataByCases(bubblesRowsData, dateRanges);
	}

	private groupBubblesDataByCases(bubblesDataRows: BubbleData[][], dateRanges: DateInterval[]) {
		return _.values(
			_.chain(bubblesDataRows)
				.flatten()
				.filter(bubbleData => bubbleData.object.dateIndex > -1
					&& bubbleData.object.dateIndex < dateRanges.length)
				.groupBy((bubbleData) => bubbleData.object.caseItem.uiId)
				.value()
		);
	}

	// build bubbles data grouped by rows
	private getBubblesRowsData(caseRows: HighchartsCase[][], dateRanges: DateInterval[]): BubbleData[][] {
		return _.chain(caseRows)
			.map(rowCases => _.flatten(_.map(rowCases,
				caseItem => this.getCaseEventPoints(caseItem, dateRanges))) as ICaseEventPoint[])
			.map((rowPoints, index) => _.map(rowPoints, rowPoint => ({
				y: index,
				x: rowPoint.dateIndex,
				name: rowPoint.caseItem.title,
				object: rowPoint,
				color: rowPoint.caseItem.color,
				rangeName: dateRanges[index]?.name
			})))
			.value();
	}

	getCaseLanes(cases: HighchartsCase[], dateRanges: DateInterval[]): HighchartsCase[][] {
		if (_.isEmpty(cases))
			return [];

		let casesBuckets: HighchartsCaseBucket[] = this.getMergedCaseBuckets(cloneDeep(cases), dateRanges);

		let rows: HighchartsCase[][] = [];
		let currentRow: HighchartsCase[] = [];
		let endIndex = null;

		while(casesBuckets.length > 0) {
			if (endIndex == null) {
				rows.push([]);
				currentRow = rows.last();
				let caseBucket = casesBuckets.shift();
				endIndex = this.getDateIndex(caseBucket.getBucketEndDate(), dateRanges);
				currentRow.pushAll(caseBucket.cases);
			} else {
				let fittingCaseBucket = _.find(casesBuckets, caseItem =>
						this.getDateIndex(caseItem.getBucketStartDate(), dateRanges) > endIndex);

				if (fittingCaseBucket) {
					casesBuckets.remove(fittingCaseBucket);
					currentRow.pushAll(fittingCaseBucket.cases);
					endIndex = this.getDateIndex(fittingCaseBucket.getBucketEndDate(), dateRanges);
				} else {
					endIndex = null;
				}
			}
		}

		return rows;
	}

	private getMergedCaseBuckets(cases: HighchartsCase[], dateRanges: DateInterval[]): HighchartsCaseBucket[] {
		let groupedCases = _.groupBy(cases, 'title');

		let caseBuckets = _.map(Object.keys(groupedCases), (groupName) => {
			let caseBucket: HighchartsCaseBucket = new HighchartsCaseBucket();
			let remainingCases = _.sortBy(groupedCases[groupName], 'startDate');
			let endIndex = null;

			while (remainingCases.length > 0) {
				if (endIndex === null) {
					let firstCase = remainingCases.shift();
					endIndex = this.getDateIndex(firstCase.endDate, dateRanges);
					caseBucket.cases.push(firstCase);
				} else {
					let mergingCase = _.find(remainingCases, caseItem => this.getDateIndex(caseItem.startDate, dateRanges) === endIndex);
					if (mergingCase) {
						remainingCases.remove(mergingCase);
						let previousCase = caseBucket.cases.last();
						previousCase.endDate = mergingCase.endDate;
						previousCase.events.pushAll(mergingCase.events);
						endIndex = this.getDateIndex(mergingCase.endDate, dateRanges);
					} else {
						endIndex = null;
					}
				}
			}

			return caseBucket;
		});

		return _.sortBy(caseBuckets, caseBucket => caseBucket.getBucketStartDate());
	}

	getCaseIndexes(caseItem: HighchartsCase, dateRanges: DateInterval[]): {startIndex: number, endIndex: number} {
		return {
			startIndex: this.getDateIndex(caseItem.startDate, dateRanges),
			endIndex: caseItem.endDate ? this.getDateIndex(caseItem.endDate, dateRanges) : dateRanges.length,
		};
	}

	private getDateIndex(date: Date, dateRanges: DateInterval[]): number {
		if (!date) {
			return dateRanges.length;
		}

		let momentDate = moment(date);
		if (momentDate.isBefore(dateRanges[0].from))
			return -1;
		for (let i = 0; i < dateRanges.length; i++)
			if (momentDate.isSameOrBefore(dateRanges[i].to))
				return i;
		return dateRanges.length;
	}

	getCaseEventPoints(caseItem: HighchartsCase, dateRanges: DateInterval[]): ICaseEventPoint[] {
		let caseGroups = _.groupBy(caseItem.events, event => this.getDateIndex(event.eventDate, dateRanges));
		return _.map(caseGroups, (events, index) => ({
			caseItem,
			events,
			dateIndex: Number(index),
			dateName: Number(index) > -1 && Number(index) < dateRanges.length
				? dateRanges[Number(index)].name : undefined
		}));
	}

	getEventsHtml(point: ICaseEventPoint): string {
		let events = _.map(point.events, event => `<span class="mt-8">${this.formatCaseEvent(event)}</span>`);
		return `<div class="d-flex flex-direction-column p-8">
			<span class="text-0_875rem bold mb-8">${point.caseItem.title}</span>
			${events.join('')}
		</div>`;
	}

	private formatCaseEvent(event: CaseEventData): string {
		switch (event.type) {
			case CaseEvent.PRIORITY_CHANGE: {
				let metadata = event.metadata as CasePriorityEventMetadata;
				let previousPriority = this.casePriorityTypes.getPriorityByValue(metadata.previousPriority);
				let newPriority = this.casePriorityTypes.getPriorityByValue(metadata.newPriority);
				return `<i class="q-icon-priority-${newPriority.level} mr-4" style="color: ${newPriority.iconColor}"></i>
					${this.locale.getString('cases.priorityChangedEvent', [previousPriority?.displayName, newPriority.displayName])}`;
			}
			case CaseEvent.ASSIGNED: {
				let metadata = event.metadata as CaseAssigneeEventMetadata;
				return `<i class="q-icon-assign mr-4"></i>${this.locale.getString('cases.assignedEvent', {name: metadata.assignee})}`;
			}
			case CaseEvent.TO_DO_COMPLETED: {
				return `<i class="q-icon-check mr-4"></i>${this.locale.getString('cases.todoResolved')}`;
			}
			case CaseEvent.OPEN: return this.locale.getString('cases.caseOpened');
			case CaseEvent.REOPENED: return this.locale.getString('cases.caseReopened');
			case CaseEvent.CLOSED: return this.locale.getString('cases.caseClosed');
			default: throw new Error('Unknown event: ' + event.type);
		}
	}

	// generates fake cases
	generateCases(dateRanges: DateInterval[]): HighchartsCase[] {
		if (_.isEmpty(dateRanges))
			return [];

		function getIndexFromPercentage(percentage: number): number {
			return Math.round((dateRanges.length - 1) * percentage);
		}
		function caseEvent(type: CaseEvent, percentageOrDate: number | Date,
				metadata?: CasePriorityEventMetadata | CaseAssigneeEventMetadata): CaseEventData {
			let date = _.isNumber(percentageOrDate)
				? dateRanges[getIndexFromPercentage(percentageOrDate)].from.toDate()
				: percentageOrDate;
			return {
				type,
				eventDate: date,
				metadata
			};
		}
		let colors = ColorPaletteConstants.getPalette1();
		let caseStartBefore: HighchartsCase = {
			title: '#1 Case one',
			permalink: 'case1',
			color: colors[0],
			startDate: dateRanges[0].from.clone().add(-2, 'month').toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.2)].to.toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, dateRanges[0].from.clone().add(-2, 'month').toDate()),
				caseEvent(CaseEvent.PRIORITY_CHANGE, 0.4, {previousPriority: CasePriority.LOW, newPriority: CasePriority.CRITICAL}),
				caseEvent(CaseEvent.ASSIGNED, 0.0, {assignee: 'Milan Gamble'}),
				caseEvent(CaseEvent.CLOSED, 0.2),
			]
		};
		let caseSameLane: HighchartsCase = {
			title: '#2 Case two',
			permalink: 'case2',
			color: colors[1],
			startDate: dateRanges[getIndexFromPercentage(0.4)].from.toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.8)].to.toDate(),
			events: [
				caseEvent(CaseEvent.REOPENED, 0.4),
				caseEvent(CaseEvent.ASSIGNED, 0.4, {assignee: 'Rafael Gomez, Milan Gamble'}),
				caseEvent(CaseEvent.PRIORITY_CHANGE, 0.4, {previousPriority: CasePriority.LOW, newPriority: CasePriority.MEDIUM}),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.6),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.8),
				caseEvent(CaseEvent.CLOSED, 0.8),
			]
		};
		let caseWholeLane: HighchartsCase = {
			title: '#3 Case three',
			permalink: 'case3',
			color: colors[2],
			startDate: dateRanges[0].from.clone().add(-5, 'month').toDate(),
			endDate: dateRanges.last().to.clone().add(3, 'month').toDate(),
			events: [
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.1),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.3),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.5),
				caseEvent(CaseEvent.ASSIGNED, 0.5, {assignee: 'Yuri M'}),
				caseEvent(CaseEvent.PRIORITY_CHANGE, 0.5, {previousPriority: CasePriority.LOW, newPriority: CasePriority.HIGH}),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 1.0),
			]
		};
		let caseInBetween: HighchartsCase = {
			title: '#4 Case four',
			permalink: 'case4',
			color: colors[3],
			startDate: dateRanges[getIndexFromPercentage(0.2)].from.toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.7)].to.toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, 0.2),
				caseEvent(CaseEvent.PRIORITY_CHANGE, 0.2, {previousPriority: CasePriority.LOW, newPriority: CasePriority.CRITICAL}),
				caseEvent(CaseEvent.ASSIGNED, 0.2, {assignee: 'Sonal Singh'}),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.5),
				caseEvent(CaseEvent.CLOSED, 0.7)
			]
		};
		let caseEndAfterInBeetween: HighchartsCase = {
			title: '#5 Case five',
			permalink: 'case5',
			color: colors[4],
			startDate: dateRanges[getIndexFromPercentage(0.2)].from.toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.4)].to.toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, 0.2),
				caseEvent(CaseEvent.ASSIGNED, 0.2, {assignee: 'Sonal Singh, Rafael Gomez'}),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 0.4),
			]
		};
		let caseEndAfter: HighchartsCase = {
			title: '#5 Case five',
			permalink: 'case5',
			color: colors[4],
			startDate: dateRanges[getIndexFromPercentage(0.8)].from.toDate(),
			endDate: dateRanges.last().to.clone().add(3, 'month').toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, 0.8),
				caseEvent(CaseEvent.ASSIGNED, 0.8, {assignee: 'Milan Gamble'}),
				caseEvent(CaseEvent.TO_DO_COMPLETED, 1),
			]
		};
		let caseMergeFirstPart: HighchartsCase = {
			title: '#6 Case six',
			permalink: 'case6',
			color: colors[6],
			startDate: dateRanges[0].from.clone().add(-5, 'month').toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.2)].to.toDate(),
			events: [
				caseEvent(CaseEvent.CLOSED, 0.2),

			]
		};
		let caseMergeSecond: HighchartsCase = {
			title: '#6 Case six',
			permalink: 'case6',
			color: colors[6],
			startDate: dateRanges[getIndexFromPercentage(0.2)].from.toDate(),
			endDate: dateRanges[getIndexFromPercentage(0.4)].to.toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, 0.2),
				caseEvent(CaseEvent.CLOSED, 0.4)
			]
		};
		let caseMergeThird: HighchartsCase = {
			title: '#6 Case six',
			permalink: 'case6',
			color: colors[6],
			startDate: dateRanges[getIndexFromPercentage(0.4)].from.toDate(),
			endDate: dateRanges.last().to.clone().add(3, 'month').toDate(),
			events: [
				caseEvent(CaseEvent.REOPENED, 0.4)
			]
		};
		let caseDot: HighchartsCase = {
			title: '#7 Case seven',
			permalink: 'case7',
			color: colors[7],
			startDate: dateRanges[getIndexFromPercentage(1)].from.toDate(),
			endDate: dateRanges[getIndexFromPercentage(1)].to.toDate(),
			events: [
				caseEvent(CaseEvent.OPEN, 1),
				caseEvent(CaseEvent.CLOSED, 1)
			]
		};

		return [
			caseStartBefore,
			caseSameLane,
			caseWholeLane,
			caseInBetween,
			caseEndAfterInBeetween,
			caseEndAfter,
			caseMergeFirstPart,
			caseMergeSecond,
			caseMergeThird,
			caseDot
		];
	}

	convertEngagorCase(engagorCase: EngagorCase, caseConfig: CaseVisualization): HighchartsCase[] {
		let actions = _.chain(engagorCase.actions)
			.map(action => this.convertEngagorActions(action))
			.filter(action => !!action)
			.sortBy(action => this.getActionSortKey(action))
			.value();

		if (_.isEmpty(actions))
			return []; // sometimes there is no "case_created" event, so ignoring such cases

		let eventSets = [];
		let currentSet = [];

		_.each(actions, action => {
			if (action.type !== CaseEvent.CLOSED) {
				currentSet.push(action);
			} else {
				currentSet.push(action);
				eventSets.push(currentSet);
				currentSet = [];
			}
		});

		if (currentSet.length) {
			eventSets.push(currentSet);
		}

		return _.map(eventSets, events => this.getHighchartsCase(engagorCase, caseConfig, events));
	}

	private convertEngagorActions(action: EngagorCaseAction): CaseEventData {
		switch (action.type) {
			case EngagorActionType.CASE_CREATED: return {type: CaseEvent.OPEN, eventDate: this.toDate(action.date)};
			case EngagorActionType.MARK_TODO: return {type: CaseEvent.TO_DO_COMPLETED, eventDate: this.toDate(action.date)};
			case EngagorActionType.PROPERTY: return {type: CaseEvent.PRIORITY_CHANGE, eventDate: this.toDate(action.date),
				metadata: {newPriority: action.metadata.priority, previousPriority: action.metadata.previous_priority}};
			case EngagorActionType.ASSIGN: return {type: CaseEvent.ASSIGNED, eventDate: this.toDate(action.date),
				metadata: {assignee: this.getAllAssignees(action.metadata.assignees)}};
			case EngagorActionType.STATUS: {
				if (action.metadata.status === EngagorStatus.DONE) {
					return {type: CaseEvent.CLOSED, eventDate: this.toDate(action.date)};
				} else if (action.metadata.status === EngagorStatus.NEW) {
					return {type: CaseEvent.REOPENED, eventDate: this.toDate(action.date)};
				}
				return null;
			}
			default: return null;
		}
	}

	private getAllAssignees(assignees:any[]) {
		return assignees.map(assignee => assignee.display_name).join(', ');
	}

	private getHighchartsCase(engagorCase: EngagorCase, caseConfig: CaseVisualization, actions: CaseEventData[]): HighchartsCase {
		let highchartsCase: HighchartsCase =  {
			title: `#${caseConfig.sourceId}: ${caseConfig.displayName}`,
			permalink: engagorCase.permalink,
			color: caseConfig.color,
			startDate: actions[0].eventDate,
			endDate: actions.last().type === CaseEvent.CLOSED ? actions.last().eventDate : null,
			events: _.chain(actions).filter(action => _.contains(caseConfig.events, action.type))
				.map(action => ({
					type: action.type as CaseEvent,
					eventDate: action.eventDate,
					metadata: action.metadata,
				})).value()
		};
		return highchartsCase;
	}

	private toDate(date: EngagorCaseDate): Date {
		return new Date(date.added * 1000);
	}

	private getActionSortKey(action: CaseEventData): string {
		// sort by date, but always place open/reopen at the beginning and closed at the end of same date
		return `${moment(action.eventDate).toISOString()}-${this.getCaseEventOrder(action.type)}`;
	}

	private getCaseEventOrder(event: CaseEvent): number {
		switch (event) {
			case CaseEvent.OPEN: return 1;
			case CaseEvent.REOPENED: return 2;
			case CaseEvent.CLOSED: return 9;
			default: return 5;
		}
	}
}

app.service('highchartsCaseUtils', downgradeInjectable(HighchartsCaseUtilsService));
