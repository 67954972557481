<widget-box
	[widget]="widget"
	[layout]="layout"
	[showHeader]="isEditMode()"
	[headerDelay]="0"
	[fixedHeader]="true"
	[editable]="false"
	[menu]="menu"
	[other]="other">
	<widget-content
		data-testid="widget-content"
		class="h-100-percent w-100-percent widget-main-content"
		[view]="widget.visualProperties.visualization"
		[trigger]="trigger"
		[widget]="widget"
		[data]="processedProperties"
		[utils]="utils">
	</widget-content>
</widget-box>
<ng-template #menu let-menuActions>
	<div *ngIf="!widget.multiSelection && !layout.isZoomModeOn()">
		<copy-text></copy-text>

		<li (click)="widgetActions.emit(WidgetAction.OPEN_SETTINGS)">
			<a class="option settings" [i18n]="'common.edit'" href="javascript:void(0)" role="menuitem"></a>
		</li>
		<li (click)="widgetActions.emit(WidgetAction.COPY)">
			<a class="option copy" [i18n]="'common.makeCopy'" href="javascript:void(0)" role="menuitem"></a>
		</li>
		<copy-submenu
			[dashboard]="dashboardData.dashboard"
			label="{{'widget.copyTo' | i18n}}"
			[widgets]="[widget]"
			(targetSelected)="menuActions.closeMenu()">
		</copy-submenu>
		<li *ngIf="(widget.name === 'image')">
			<a class="option ratio" (click)="toggleIgnoreImageRatio()" href="javascript:void(0)" role="menuitem">
				{{(widget.properties.ignoreRatio ? 'widget.preserveAR' : 'widget.disregardAR') | i18n}}
			</a>
		</li>
		<li *ngIf="(widget.name === 'image')" (click)="editAltText()">
			<a class="option alt-text" [i18n]="'widget.editAltText'" href="javascript:void(0)" role="menuitem"></a>
		</li>
	</div>
	<div *ngIf="!widget.multiSelection">
		<push-widget-menu
			[widgetActions]="widgetActions">
		</push-widget-menu>
		<div *ngIf="!layout.isZoomModeOn()">
			<li class="divider" role="separator"></li>
			<li *ngIf="!layout.isZoomModeOn()"
				(click)="widgetActions.emit(WidgetAction.DELETE)"
				class="border-b-radius-4 overflow-hidden">
				<a class="option danger-menu-item delete d-flex justify-between" href="javascript:void(0)" role="menuitem">
					<span [i18n]="'dashboard.delete'"></span>
					<i class="q-icon q-icon-trash"></i>
				</a>
			</li>
		</div>
	</div>
	<bulk-widget-menu *ngIf="widget.multiSelection"
		[dashboard]="dashboardData.dashboard"
		(closeMenu)="menuActions.closeMenu()"
		(bulkAction)="bulkAction.emit($event)">
	</bulk-widget-menu>
</ng-template>
<ng-template #other let-expanded="expanded">
	<div *ngIf="isMobile()"
		class="br-widget-caption p-8"
		[class.down]="expanded">
		<p class="text p-0 m-0">{{getCaptionText()}}</p>
	</div>
</ng-template>
