import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { INumberFormatBuilder } from '@app/modules/widget-visualizations/formatters/number-format-builder.interface';
import { PopFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/pop-formatter-builder.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FormatDataType } from '@cxstudio/reports/formatting/format-data-type.enum';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import * as Highcharts from 'highcharts';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';

export interface IFormatterBuilder {
	getBuilder(): () => {format: (value: any) => string};
}

export class ReportNumberFormatUtils {

	builders: {[key in FormatDataType]?: INumberFormatBuilder} = {
		number: this.numberFormatterBuilder,
		number_delimiter: this.numberDelimiterFormatterBuilder,
		percent: this.percentFormatterBuilder,
		percent_100: this.percent100FormatterBuilder,
		percent_100_none: this.percent100NoneFormatterBuilder
	};
	constructor(
		private locale: ILocale,
		private numberFormatterBuilder: INumberFormatBuilder,
		private numberDelimiterFormatterBuilder: INumberFormatBuilder,
		private percentFormatterBuilder: INumberFormatBuilder,
		private percent100FormatterBuilder: INumberFormatBuilder,
		private percent100NoneFormatterBuilder: INumberFormatBuilder,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private popFormatterBuilderService: PopFormatterBuilderService,
		private formatterBuilderUtils: FormatterBuilderUtilsService
	) {

	}

	private checkNA(value): string|boolean {
		if (value === 'NaN' || value === null || value === undefined) {
			return this.locale.getString('widget.na');
		}

		return false;
	}

	formatMetric = (metric, value) => {
		return metric === 'volume' ? Highcharts.numberFormat(value, 0, '.', ',') :
			value;
	}

	formatPercent = (value) => {
		return '' + value + '%';
	}

	formatPercentSpaceBetween = (value: number) => {
		return `${value} %`;
	}

	formatAny = (value, thousandsChar?) => {
		let stringValue = value.toString();
		if (stringValue === '') {
			return '0';
		}
		return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsChar || ',');
	}

	tableNumberFormatter = (row, cell, value) => {
		return this.formatAny(value);
	}

	tableIntegerFormatter = (row, cell, value) => {
		return this.formatAny(Math.floor(value));
	}


	tableFixDecimalFormatterPercent = (row, cell, value) => {
		value = this.checkNA(value) || value.toFixed(2);
		return this.rightAlighCell(value + '%');
	}

	tableRightAlignNumberFormatter = (row, cell, value) => {
		return (this.checkNA(value) || this.rightAlighCell(value)) as string;
	}

	tableRightAlignNumberDecimalFormatter = (row, cell, value) => {
		value = this.checkNA(value) || value.toFixed(2);
		return this.rightAlighCell(value);
	}

	private rightAlighCell(value: string): string {
		return `<span class="slick-align-right">${value}</span>`;
	}

	commaFormat = (num) => {
		return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	toMaxDigits = (value, maxDigits) => {
		if (value === null || isNaN(value)) {
			return value;
		}

		// format to max digits
		let result = parseFloat(value).toFixed(maxDigits);

		// remove trailing zeroes after initial formatting (i.e. after formatting '6.000005' -> '6.00')
		result = parseFloat(result).toString();

		return this.formatAny(result);
	}

	getFormatterBuilder = (metric: CalculationWithFormat, allMetrics?): IFormatBuilder => {
		let builder: IFormatBuilder;
		if (metric.dataType) {
			builder = this.builders[metric.dataType].getBuilder();
			if (!builder)
				builder = this.numberDelimiterFormatterBuilder.getBuilder();
		} else {
			builder = this.defaultDataFormatterBuilder.getDefaultFormatterBuilder(metric, allMetrics);
		}

		return builder;
	}

	getFormatterBuilderWrapper = (metric: CalculationWithFormat, builder: IFormatBuilder) => {
		//add additional format
		if (metric.isPopMetric) {
			if (metric.name.startsWith(PopDiffPrefix.DELTA)) {
				return this.popFormatterBuilderService.getDeltaBuilder(metric, builder);
			} else if (metric.name.startsWith(PopDiffPrefix.PERCENT_CHANGE)) {
				return this.popFormatterBuilderService.getPercentChangeBuilder(metric, builder);
			} else if (metric.name.startsWith(PopDiffPrefix.SIGNIFICANCE)) {
				builder = this.popFormatterBuilderService.getSignificanceBuilder();
			}
		}

		return this.defaultDataFormatterBuilder.wrapper(builder);
	}

	getPercentChangeFormatter = (metric, options) => {
		return this.getFormatter(metric, options, this.popFormatterBuilderService.getPercentChangeBuilder);
	}

	getDeltaFormatter = (metric, options) => {
		return this.getFormatter(metric, options, this.popFormatterBuilderService.getDeltaBuilder);
	}

	getNumberTypeFormatter = (metric, options, metricForFormatting?) => {
		return this.getFormatter(metric, options, this.getFormatterBuilderWrapper, metricForFormatting);
	}

	private getFormatter(metric, options, formatterBuilder, wrappedMetricForFormatting?): any {
		let builder = this.getFormatterBuilder(metric);
		let builderWrapper = formatterBuilder(metric, builder);

		return (row, cell, value, columnDef, dataContext, forExport, metricForFormat) => {
			if (!metricForFormat && options.definition && wrappedMetricForFormatting)
				metricForFormat = wrappedMetricForFormatting;

			return builderWrapper.format(row, cell, value, columnDef, dataContext, options, forExport, metricForFormat);
		};
	}

	wrapFormatterForShowTotal = (wrappedFormatter, visualProps) => {
		if (!wrappedFormatter || !visualProps.showTotal)
			return wrappedFormatter;

		return (row, cell, value, columnDef, dataContext, forExport, metricForFormat) => {
			return row === 0 && this.formatterBuilderUtils.isNA(value)
				? ''
				: wrappedFormatter(row, cell, value, columnDef, dataContext, forExport, metricForFormat);
		};
	}
}

app.service('reportNumberFormatUtils', ReportNumberFormatUtils);

