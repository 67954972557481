import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';

export class SentenceFiltersHelper {

	static getPredefinedMetricFilter(type: PredefinedMetricConstants, mode: PredefinedFilterValue): DrillFilter {
		return {
			type: DrillType.METRIC_PREDEFINED,
			name: type,
			values: [mode, PredefinedMetricConstants.SUBTYPE_3]
		};
	}

}
