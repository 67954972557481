<div [ngBusy]="loading">
	<div *ngIf="attributes">
		<copy-submenu
			class="standalone drivers-copy-to float-right ml-16"
			label="{{'drivers.copyToDashboard' | i18n}}"
			[dashboard]="dashboard"
			[getWidgets]="getDriversWidget"
			[originDetails]="originDetails">
		</copy-submenu>

		<p><strong>{{'drivers.outcome'|i18n}}</strong></p>
		<p *ngFor="let rule of driversItem.target.filters.filterRules; let isLast = last"
			[ngClass]="{'mb-16': isLast}">
			<span
				[ngbTooltip]="getRuleString(rule)"
				placement="bottom"
				container="body"
				tooltipClass="cb-tooltip tooltip-bottom">
				{{getRuleString(rule)}}
			</span>
		</p>

		<p><strong>{{'drivers.scope'|i18n}}</strong></p>
		<p *ngFor="let filter of getAppliedFilters(); let isLast = last"
			[ngClass]="{'mb-16': isLast}">
			<span
				[ngbTooltip]="getFilterTooltip(filter)"
				placement="bottom"
				container="body"
				tooltipClass="cb-tooltip tooltip-bottom">
				{{getFilterTooltip(filter)}}
			</span>
		</p>

		<div class="drivers-results-table">
			<div class="row" *ngIf="!!reportDrivers">
				<div class="col-sm-2 font-bold  pt-32">{{'drivers.driversName'|i18n}}</div>
				<div class="col-sm-10" *ngIf="reportDrivers.length > 0">
					<table> <!-- TODO refactor this table -->
						<thead>
							<tr>
								<th class="col-sm-5"></th>
								<th class="col-sm-1"></th>
								<th [i18n]="'drivers.impactRank'" class="text-center col-sm-3"></th>
								<th [i18n]="'widget.volume'" class="text-right col-sm-3"></th>
							</tr>
						</thead>
						<tbody>

							<tr *ngFor="let item of getPositiveDrivers() | orderBy:'impactRank'"
								[ngClass]="{'font-bold': isBold(item.strength)}"
								class="hover-parent">
								<td class="col-sm-5 cursor-pointer"
									(click)="openAnDocumentExplorer(item)"
									[ngbTooltip]="getFieldTooltip(item)"
									placement="bottom"
									container="body"
									tooltipClass="cb-tooltip">
									{{ item.displayName }}
								</td>
								<td class="col-sm-1" (click)="$event.stopPropagation()">
									<div *ngIf="isDriverEditable()">
										<span class="q-icon q-icon-delete show-on-parent-hover cursor-pointer"
											title="{{'drivers.hideDriver' | i18n}}"
											(click)="hide(item)">
										</span>
									</div>
								</td>
								<td class="text-center col-sm-3"
									[ngbTooltip]="getStrengthTooltip(item)"
									placement="bottom"
									container="body"
									tooltipClass="cb-tooltip">
									{{ item.impactRank }}
								</td>
								<td class="text-right col-sm-3">{{ item.volume | number }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="reportDrivers.length === 0" class="col-sm-10 mt-2" [i18n]="'drivers.noVisibleDrivers'"></div>
			</div>

			<div class="row lh-1 mt-16">
				<div class="col-sm-offset-2 col-sm-10"> <!-- using nested divs to have the same position as 2nd table column -->
					<div class="pl-0">
						<div *ngIf="reportDrivers.length > 0 && !showInverseDrivers && hasNegativeDrivers()">
							<a class="option show-inverse-drivers" (click)="showInverseDrivers = true">
								{{'drivers.showInverseDrivers'|i18n}}
							</a>
							<cb-inline-help>
								<help-body>{{'drivers.showInverseDriversTooltip'|i18n}}</help-body>
							</cb-inline-help>
						</div>
						<div *ngIf="!hasNegativeDrivers()" class="italic">
							{{'drivers.noInverseDrivers' | i18n}}
						</div>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="showInverseDrivers && hasNegativeDrivers()">
				<div class="col-sm-2 font-bold pt-32">{{'drivers.inverseDriversName'|i18n}}</div>
				<div class="col-sm-10">
					<table>
						<thead>
							<tr>
								<th class="col-sm-5"></th>
								<th class="col-sm-1"></th>
								<th [i18n]="'drivers.impactRank'" class="text-center col-sm-3"></th>
								<th [i18n]="'widget.volume'" class="text-right col-sm-3"></th>
							</tr>
						</thead>
						<tbody>

							<tr *ngFor="let item of getNegativeDrivers() | orderBy:'impactRank'"
								class="hover-parent">
								<td class="col-sm-5 cursor-pointer"
									(click)="openAnDocumentExplorer(item)"
									[ngbTooltip]="getFieldTooltip(item)"
									placement="bottom"
									container="body"
									tooltipClass="cb-tooltip">
									{{ item.displayName }}
								</td>
								<td class="col-sm-1" (click)="$event.stopPropagation()">
									<div *ngIf="isDriverEditable()">
										<span class="q-icon q-icon-delete show-on-parent-hover cursor-pointer"
											title="{{'drivers.hideDriver' | i18n}}"
											(click)="hide(item)">
										</span>
									</div>
								</td>
								<td class="text-center col-sm-3"
									[ngbTooltip]="getStrengthTooltip(item)"
									placement="bottom"
									container="body"
									tooltipClass="cb-tooltip">
									{{ item.impactRank }}
								</td>
								<td class="text-right col-sm-3">{{ item.volume | number }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="separator"></div>
		</div>
	</div>
</div>
