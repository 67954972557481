import { TemplatePlaceholderKey } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-key';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';

export enum PlaceholderDisplayType {
	SELECTION = 'SELECTION',
	GROUPING = 'GROUPING',
	CALCULATION = 'CALCULATION',
	FILTER_RULE = 'FILTER_RULE',
}
export enum PlaceholderUsageType {
	DASHBOARD_FILTER = 'DASHBOARD_FILTER',
	WIDGET = 'WIDGET',
	MATH_COMPONENT = 'MATH_COMPONENT',
	FILTER_RULE = 'FILTER_RULE',
	DATE_FILTER_RULE = 'DATE_FILTER_RULE',
}

export enum PlaceholderUsageLocation {
	GROUPING = 'GROUPING',
	CALCULATION = 'CALCULATION',
	COLOR_CALCULATION = 'COLOR_CALCULATION',
	GROUPING_SORT = 'GROUPING_SORT',
	AGGREGATION  = 'AGGREGATION',
	FILTER_CONDITION  = 'FILTER_CONDITION',
}

export type PlaceholderUsageMetadata = WidgetUsageMetadata
	| MetricUsageMetadata
	| FilterRuleUsageMetadata
	| DateFilterRuleUsageMetadata;

export interface PlaceholderUsage {
	type: PlaceholderUsageType;
	location: PlaceholderUsageLocation;
	usageMetadata: PlaceholderUsageMetadata;
}

export interface WidgetUsageMetadata {
	widgetName: string;
}

export interface MetricUsageMetadata {
	operator: CalculationFunction;
}

export interface FilterRuleUsageMetadata {
	type: FilterRuleType;
	exists: boolean;
	matchMode: FilterMatchModeValue;
}

export interface DateFilterRuleUsageMetadata {
	dateFilterMode: DateFilterMode;
}

export interface TemplatePlaceholderMetadata {
	key: TemplatePlaceholderKey;
	type: PlaceholderDisplayType;
	usages: PlaceholderUsage[];
	description: string;
	originalDisplayName: string;
}
