import { HttpHandlers } from '@cxstudio/common/http-handlers';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Caches } from '@cxstudio/common/caches';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { AccountAccess } from '@cxstudio/user-administration/users/project-access/account-access-class';
import { RestUserData } from '@app/modules/user-administration/entities/rest-user-data';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';
import { User } from '@cxstudio/user-administration/users/entities/user';

export interface ExistResponse {
	exist: boolean;
}

export interface UserDataAccess {
	cpAccounts: ContenProviderAccess[];
}

export interface ContenProviderAccess {
	id: number;
	accounts: AccountAccess[];
}

export interface UserLinkingInfo {
	userId: number;
	initialLicenseTypeId: number;
	currentLicenseTypeId: number;
	addedAccounts: {[key: number]: number[]};
	removedAccounts: {[key: number]: number[]};
	remainedAccounts: {[key: number]: number[]};
	projectsAccess: UserDataAccess;
}

export interface UserLinkingOptions {
	showDialog?: boolean;
	forceUpdate?: boolean;
}

export class UserApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private $q: ng.IQService,
		private locale: ILocale,
		private cachedHttpService: CachedHttpService,
		private cbDialogService: CBDialogService
	) {}

	// add a new user
	addUser = (user): ng.IHttpPromise<void> => {
		return this.$http.post('rest/users', angular.toJson(user));
	}

	updateUser = (userId: number, updateData: RestUserData): ng.IPromise<void> => {
		this.cachedHttpService.cache(Caches.PERMISSIONS).invalidate();
		return this.$http.put(`rest/users/${userId}`, angular.toJson(updateData))
			.then(this.httpHandlers.success);
	}

	addUserToMasterAccount = (userId: number, updateData: RestUserData): ng.IPromise<void> => {
		this.cachedHttpService.cache(Caches.PERMISSIONS).invalidate();
		return this.$http.post(`rest/users/${userId}/master-account`, angular.toJson(updateData))
			.then(this.httpHandlers.success);
	}

	addInternalUserToMasterAccount = (userId: number, userData: RestUserData): ng.IPromise<void> => {
		return this.$http.put(`rest/users/internal/${userId}`, angular.toJson(userData))
			.then(this.httpHandlers.success);
	}

	extendUserMasterAccountAccess = (userId: number, accessExpirationDate: Date): ng.IPromise<void> => {
		return this.$http.post(`rest/users/extend-access/${userId}`, angular.toJson(accessExpirationDate))
			.then(this.httpHandlers.success);
	}

	addAccountOwnersToMasterAccount = (masterAccountId: number, usersData: RestUserData[]): ng.IPromise<void> => {
		return this.$http.put(`rest/users/master-account/${masterAccountId}/account-owners`, angular.toJson(usersData))
			.then(this.httpHandlers.success);
	}

	resetPassword = (user): ng.IPromise<string> => {
		return this.$http.post('rest/users/resetpassword', $.param(user), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(this.httpHandlers.success);
	}

	changeDefaultMA = (userId: number, newDefaultMaId: number): ng.IPromise<void> => {
		return this.$http.put(`rest/users/${userId}/change-default-ma`, newDefaultMaId)
			.then(this.httpHandlers.success);
	}

	getLinkedCpAccounts = (): ng.IHttpPromise<any> => {
		return this.$http.get('rest/users/cp_accounts');
	}

	addUserToContentProvider = (linkingInfo: UserLinkingInfo): ng.IPromise<any> => {
		return this.$http.post('rest/masteraccount/cp_accounts',
			angular.toJson(linkingInfo)).then(this.httpHandlers.success);
	}

	updateUserInContentProvider = (linkingInfo: UserLinkingInfo): ng.IPromise<any> => {
		return this.$http.post('rest/masteraccount/updateCPUser',
			angular.toJson(linkingInfo)).then(this.httpHandlers.success);
	}

	searchUsers = (emails: string[], filterQueryParams): ng.IPromise<any[]> => {
		return this.$http.post('rest/users/search', emails, { params: filterQueryParams })
			.then(this.httpHandlers.success);
	}

	searchSingleUser = (email: string): ng.IPromise<any> => {
		return this.searchUsers([ email ], {})
			.then((users) => {
				return users[0];
			});
	}

	searchUsersByText = (search: string, searchBy: string = 'everything', limit: number = 20): ng.IPromise<any> => {
		return this.$http.get('rest/users/search/text', {
			params: {
				search,
				searchBy,
				limit
			}
		}).then(this.httpHandlers.success);
	}

	syncUserInContentProviders = (linkingInfo: UserLinkingInfo, options: UserLinkingOptions): ng.IPromise<any> => {
		let deferred = this.$q.defer();
		let allErrors = [];
		this.updateUserCP(linkingInfo, options).
			then(( updateErrors ) => {
				allErrors = allErrors.concat(updateErrors);
				return this.addUserToCP(linkingInfo, options);
			})
			.then(( addRemoveErrors ) => {
				allErrors = allErrors.concat(addRemoveErrors);
				deferred.resolve(allErrors);
			});

		return deferred.promise;
	}

	private addUserToCP = (linkingInfo: UserLinkingInfo, options: UserLinkingOptions): ng.IPromise<any[]> => {
		let isAddRemoveCP = (!$.isEmptyObject(linkingInfo.addedAccounts)
				|| !$.isEmptyObject(linkingInfo.removedAccounts));
		if (!isAddRemoveCP) {
			return this.$q.when([]);
		}

		let model = {
			loadingMessage: this.locale.getString('common.spinnerCreateUserInCP'),
			loading: null,
			done: false,
			errors: null
		};

		if (options.showDialog) {
			this.cbDialogService.custom(
				this.locale.getString('administration.createUserInAcc'),
				'partials/user-administration/add-user-cp-dialog.html',
				model
			);
		}

		model.loading = this.addUserToContentProvider(linkingInfo).
			then((errors) => {
				model.done = true;
				model.errors = errors;
				return errors;
			});

		return model.loading;
	}

	private updateUserCP = (linkingInfo: UserLinkingInfo, options: UserLinkingOptions): ng.IPromise<any[]> => {
		let hasRemainedCP = (!$.isEmptyObject(linkingInfo.remainedAccounts));
		let changedProjectAccess =
				linkingInfo.projectsAccess.cpAccounts.length > 0;
		let changedLicense =
			linkingInfo.currentLicenseTypeId !== linkingInfo.initialLicenseTypeId;
		let isUpdateCP = hasRemainedCP &&
				(options.forceUpdate || changedLicense || changedProjectAccess);
		if (!isUpdateCP) {
			return this.$q.when([]);
		}

		let model = {
			loadingMessage: this.locale.getString('common.spinnerUpdateUserInCP'),
			loading: null,
			done: false,
			errors: null
		};

		if (options.showDialog) {
			this.cbDialogService.custom(
				this.locale.getString('administration.updateUserInAcc'),
				'partials/user-administration/users/update-user-cp-dialog.html',
				model
			);
		}

		model.loading = this.updateUserInContentProvider(linkingInfo).
			then((errorsResponse) => {
				model.done = true;
				let errors = errorsResponse.errors;
				if (errors) {
					let modified = [];
					for (let i = 0; i < errors.length; i++) {
						let error = errors[i];
						if (error.errorCode === 'notLocalized') {
							modified[i] = error.parameters.message;
						} else {
							modified[i] = this.locale.getString(
								'contentProvider.' + error.errorCode);
						}
					}
					errors = modified;
				}

				model.errors = errors;
				return errors;
			});

		return model.loading;
	}

	checkUniqueIdExists = (uniqueId: string): ng.IPromise<ExistResponse> => {
		return this.$http.post('rest/users/validator/uniqueId', { uniqueId })
			.then(this.httpHandlers.success);
	}

	checkXmAccountIdExists = (xmAccountId: string): ng.IPromise<ExistResponse> => {
		return this.$http.post('rest/users/validator/xm-account-exists', { xmAccountId })
			.then(this.httpHandlers.success);
	}

	checkEmailExists = (userEmail: string): ng.IPromise<ExistResponse> => {
		return this.$http.post(`rest/users/validator/email-exists`, { userEmail })
			.then(this.httpHandlers.success);
	}

	checkEmailDetails = (userEmail: string): ng.IPromise<any> => {
		return this.$http.post(`rest/users/validator/email-details`, { userEmail })
			.then(this.httpHandlers.success);
	}

	getAllowedDomains = (): ng.IPromise<AllowedDomains> => {
		return this.$http.get('rest/master_account/allowed_domains')
			.then(this.httpHandlers.success);
	}

	updateAccessExpirationDate = (userId: number, expirationDate: Date): ng.IHttpPromise<void> => {
		return this.$http.put(`rest/users/${userId}/accessExpiration`, expirationDate);
	}

	downloadTemplateFile = (): ng.IHttpPromise<any> => {
		return this.$http.get('rest/users/upload/file/template', { responseType: 'arraybuffer' });
	}

	clearLastApplicationState = (userId: number): ng.IPromise<string[]> => {
		return this.$http.delete(`rest/users/${userId}/last_application_state`)
			.then(this.httpHandlers.success);
	}
}

app.service('userApiService', UserApiService);
