import ReportDefinition, { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import { HighchartsTreeReportDataBuilder } from '@cxstudio/reports/visualizations/definitions/highcharts/report-data-builders/highcharts-tree-report-data-builder.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SeriesNetworkgraphNodesOptions } from 'highcharts';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { IChartDefinitionFactory } from './chart-definition-factory.interface';

interface INetworkChartLink {
	from: string;
	to: string;
	cooccurrence: number;
}

interface INetworkData {
	nodes: Highcharts.SeriesNetworkgraphNodesOptions[];
	links: INetworkChartLink[];
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('HighchartsNetworkAnalyticDefinition', function(
	$rootScope: ng.IRootScopeService,
	locale: ILocale,
	highchartsAnalyticUtils: HighchartsAnalyticUtils,
	highchartsUtils: HighchartsUtilsService,
	highchartsTreeReportDataBuilder: HighchartsTreeReportDataBuilder,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils) {

	return class HighchartsNetworkAnalyticDefinition implements IChartDefinitionFactory {

		options: VisualProperties;
		utils: WidgetUtils;

		readonly MIN_RADIUS = 8;
		readonly MAX_RADIUS = 28;

		constructor(private reportDefinition: ReportDefinition, private element) {
			this.options = reportDefinition.options;
			this.utils = reportDefinition.utils;
		}

		getChartOptions = (): Highcharts.Options => {
			let reportDefinition = this.reportDefinition;
			let networkData = this.initializeData();

			let chartOptions = $.extend(highchartsUtils.getDefaultConfig(), {
				chart: {
					type: 'networkgraph',
					spacingTop: 30,
					spacingBottom: highchartsUtils.getSpacingBottom(this.reportDefinition),
					renderTo: this.element[0]
				},
				xAxis: {
					visible: false
				},
				yAxis: {
					visible: false
				},
				legend: {
					enabled: !!this.options.showLegend,
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'middle',
					itemMarginTop: 5,
					itemMarginBottom: 5,
					borderWidth: 0
				},
				plotOptions: {
					networkgraph: {
						cursor: 'pointer',
						layoutAlgorithm: {
							enableSimulation: this.options.showAnimation && !$rootScope.pdfToken,
							maxIterations: 300,
							integration: 'verlet',
							// custom fields
							customForces: true,
							cooccurrenceRange: {
								max: (_.max(networkData.links, (link: INetworkChartLink) => link.cooccurrence) as INetworkChartLink).cooccurrence || 0,
								min: (_.min(networkData.links, (link: INetworkChartLink) => link.cooccurrence) as INetworkChartLink).cooccurrence || 0,
							},
							linksMap: this.getLinksMap(networkData.links)
						},
						link: {
							color: 'var(--gray-500)',
							width: 1
						},
						dataLabels: {
							enabled: !!this.options.showLabels || !!this.options.showNodeNames,
							verticalAlign: 'middle',
							linkFormat: ''
						}
					},
					series: {
						events: {
							afterAnimate(): void { // tslint:disable-line:only-arrow-functions
								highchartsUtils.handleRenderedEvent(this, reportDefinition); // tslint:disable-line:no-invalid-this no-this-assignment
							},
							legendItemClick(event): void {
								let legendItem = this; // tslint:disable-line:no-invalid-this no-this-assignment
								let nodeName = legendItem.name;
								let nodeId = legendItem.userOptions.id;
								if (!nodeName) {
									event.preventDefault();
									return;
								}
								let networkSerie = legendItem.chart.series[0];
								if (!legendItem.visible) {
									let node: SeriesNetworkgraphNodesOptions = _.findWhere(networkData.nodes, {id: nodeId});
									networkSerie.options.nodes.push(node);
									let newLinks = _.filter(networkData.links, (link: any) =>
										(link.from === nodeId && _.findWhere(networkSerie.options.nodes, {id: link.to}))
										|| (link.to === nodeId &&  _.findWhere(networkSerie.options.nodes, {id: link.from})));
									let newData = _.union(networkSerie.options.data, newLinks);
									networkSerie.setData(newData, true);
								} else {
									let node: Highcharts.Point = _.findWhere(networkSerie.nodes, {id: nodeId});
									if (node) {
										node.remove();
									}
								}
							}
						}
					}
				},
				series: [{
					type: 'networkgraph',
					marker: {
						radius: this.MIN_RADIUS,
						lineWidth: 2,
						lineColor: '#FFFFFF',
						states: {
							hover: {
								radiusPlus: 6
							}
						}
					},
					tooltip: {
						headerFormat: '',
						nodeFormat: `<span style=\"color:{point.marker.fillColor}\">\u25CF </span><span>{point.displayName}</span><br/>
							<span>${locale.getString('widget.volume')}: {point.volume}</span>`,
						linkFormat: `<span>{point.displayName}</span><br/><span>${locale.getString('widget.coOccurrence')}: {point.cooccurrence}</span>`
					},
					point: {
						events: {
							mouseOver(): void {
								let point = this; // tslint:disable-line:no-invalid-this no-this-assignment
								highchartsUtils.selectPoint(reportDefinition, point);
							},
							mouseOut(): void {
								highchartsUtils.cancelPointSelection(reportDefinition);
							}
						}
					},
					data: angular.copy(networkData.links),
					nodes: angular.copy(networkData.nodes)
				}]
			});

			highchartsAnalyticUtils.applyDataLabelsProperties(
				chartOptions.series,
				this.options.showNodeNames,
				this.options.showLabels,
				this.utils.dataFormatter,
				'displayName');

			if (this.options.showLegend) {
				chartOptions.series = _.union(chartOptions.series, this.generateLegendItems(chartOptions.series[0]));
			}

			highchartsAccessibilityUtils.applyBorder(chartOptions);

			return chartOptions;
		}

		private initializeData = (): INetworkData => {
			let networkData: INetworkData = {
				links: [],
				nodes: []
			};
			if (!this.options.primaryGroup) {
				return networkData;
			}
			let data = highchartsTreeReportDataBuilder.getAnalyticTreeData(this.reportDefinition);
			networkData.nodes = this.getProcessedNodes(data);
			networkData.links = this.getProcessedLinks(data);
			return networkData;
		}

		private getProcessedLinks = (data: IDataPoint[]): INetworkChartLink[] => {
			let links = this.reportDefinition.dataObject?.networkData?.links || [];
			return _.chain(links)
				.map(link => {
					let source = _.findWhere(data, {_uniqName: link.source});
					let target = _.findWhere(data, {_uniqName: link.target});
					if (!source || !target)
						return;
					return {
						from: link.source,
						fromText: source.displayName,
						to: link.target,
						toText: target.displayName,
						cooccurrence: link.value,
						displayName: `${source.displayName} <--> ${target.displayName}`,
						object: {
							_selectionId: `${link.source}_linkedTo_${link.target}`
						}
					};
				}).filter(link => !!link)
				.value();
		}

		private getProcessedNodes = (data: IDataPoint[]): Highcharts.SeriesNetworkgraphNodesOptions[] => {
			let nodes = data || [];
			let range = {
				max: (_.max(nodes, (node: IDataPoint) => node.value) as IDataPoint).value,
				min: (_.min(nodes, (node: IDataPoint) => node.value) as IDataPoint).value
			};

			return nodes.map((node, idx) => {
				return {
					id: node._uniqName,
					displayName: node.displayName,
					rawValue: node.rawValue,
					volume: node.object.volume,
					mass: 1,
					marker: {
						fillColor: node.object.color,
						radius: this.getRadius(node.value, range)
					},
					object: node.object
				};
			});
		}

		private getLinksMap = (links): any => {
			let linksMap = {};
			_.each(links, (link: any) => {
				let fromNode = link.from;
				let toNode = link.to;
				if (!linksMap[fromNode]) {
					linksMap[fromNode] = {};
				}
				linksMap[fromNode][toNode] = link.cooccurrence;
			});
			return linksMap;
		}

		private getRadius = (nodeSize, range): number => {
			if (this.options.size === '') {
				let scale = this.options.scale || 2;
				return this.MIN_RADIUS + scale * 2;
			} else if (range.max === range.min) {
				return this.MIN_RADIUS;
			} else {
				let offset = Math.round(((nodeSize - range.min) / (range.max - range.min)) * (this.MAX_RADIUS - this.MIN_RADIUS));
				return this.MIN_RADIUS + offset;
			}
		}

		private generateLegendItems = (serie): any[] => {
			return _.map(serie.nodes, (node: IDataPoint) => {
				return {
					name: node.displayName,
					id: node.id,
					isNetworkLegend: true,
					type: 'column',
					data: [],
					showInLegend: true,
					color: node.object.color
				};
			});
		}
	};

});
