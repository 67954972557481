import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminWorkspace } from '@app/modules/system-administration/platform/workspace/admin-workspace';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UniqueNameValidator } from '@app/modules/asset-management/validation/unique-name-validator';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import WorkspaceManagementApiService from '@app/modules/system-administration/platform/workspace/workspace-management/workspace-management-api.service';
import { CxLocaleService } from '@app/core';

export interface WorkspaceEditDialogInput {
	workspace?: AdminWorkspace;
	workspaces: AdminWorkspace[];
}

@Component({
	selector: 'workspace-edit-dialog',
	templateUrl: './workspace-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceEditDialogComponent implements OnInit {
	@Input() input: WorkspaceEditDialogInput;

	editForm : FormGroup;
	loading: Promise<any>;
	contentProviders: ContentProvider[] = [];
	cpAccounts: ContentProviderAccount[] = [];
	modalTitle: string;
	saveButtonTitle: string;
	existError: boolean;
	createMode: boolean;

	constructor(
		private readonly modal: NgbActiveModal,
		private readonly formBuilder: FormBuilder,
		private readonly contentProviderManagementApiService: ContentProviderManagementApiService,
		private readonly workspaceManagementApi: WorkspaceManagementApiService,
		private readonly locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.createMode = !this.input.workspace;
		this.input.workspace = this.input.workspace || {} as AdminWorkspace;
		this.modalTitle = this.createMode
			? this.locale.getString('administration.createWorkspace')
			: this.locale.getString('administration.editWorkspace');
		this.saveButtonTitle = this.createMode
			? this.locale.getString('common.save')
			: this.locale.getString('common.confirm');
		let workspace = this.input.workspace;
		if (!this.createMode && this.input.workspace?.contentProviderId) {
			this.reloadCpAccouts();
		} else {
			this.loading = this.contentProviderManagementApiService.getAllContentProviders()
				.then(response => {
					this.contentProviders = response;
				});
		}

		this.editForm = this.formBuilder.group({
			workspaceName: [
				workspace.name,
				[
					Validators.required,
					Validators.maxLength(100),
					new UniqueNameValidator(workspace, this.input.workspaces, { strict: true }).get()
				]
			],
			contentProviderName: [
				{ value: workspace.contentProviderName, disabled: true }, [ Validators.required ]
			],
			accountName: [
				{ value: workspace.accountName, disabled: true }, [ Validators.required ]
			],
		});
	}

	get workspaceName() {
		return this.editForm.get('workspaceName');
	}

	save(): void {
		let checkPromise = this.createMode
			? this.workspaceManagementApi.isExist(this.input.workspace.contentProviderId, this.input.workspace.accountId)
				.then(exist => {
					this.existError = !isFalse(exist);
					return this.existError;
				})
			: Promise.resolve(false);
		this.loading = checkPromise.then((error) => {
			if (error) {
				return;
			}
			let result = {
				...this.input.workspace,
				name: this.editForm.value.workspaceName
			};
			this.modal.close(result);
		});
	}

	isInvalid(): boolean {
		return this.editForm.invalid || !this.input.workspace.contentProviderId || this.input.workspace.accountId === null;
	}

	cancel(): void {
		this.modal.dismiss();
	}

	onContentProviderChange(contentProvider: ContentProvider): void {
		this.input.workspace.accountId = null;
		this.existError = false;
		this.setAccountName(null);
		if (!contentProvider) {
			this.setContentProviderName(null);
		} else {
			this.setContentProviderName(contentProvider.name);
			this.reloadCpAccouts();
		}
	}

	reloadCpAccouts(): void {
		const workspace = this.input.workspace;
		this.loading = this.contentProviderManagementApiService.getCpAccounts(workspace.contentProviderId)
			.then(response => {
				this.cpAccounts = response.data;
				const account = _.findWhere(this.cpAccounts, {accountId: workspace.accountId});
				if (account) {
					this.editForm.get('accountName').setValue(account.accountName);
				}
			});
	}

	onAccountChange(cpAccount: ContentProviderAccount): void {
		this.setAccountName(cpAccount?.accountName);
		this.existError = false;
	}

	private setAccountName(name: string): void {
		this.input.workspace.accountName = name;
		this.editForm.get('accountName').setValue(name);
	}

	private setContentProviderName(name: string): void {
		this.input.workspace.contentProviderName = name;
		this.editForm.get('contentProviderName').setValue(name);
	}
}
