import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { AbstractAssetParameters } from '@app/modules/asset-management/access/asset-parameters/abstract-asset-parameters';

export class DashboardAssetParameters extends AbstractAssetParameters {
	static fromDashboard(dashboard: Dashboard): DashboardAssetParameters {
		let assetParams = new DashboardAssetParameters(dashboard.id);
		assetParams.setRunAsEmail(dashboard.ownerName);
		return assetParams;
	}

	constructor(
		private dashboardId: number
	) {
		super(AssetParametersType.DASHBOARD);

	}

	getHttpParams(): {[key: string]: string} {
		let params = { dashboardId: this.dashboardId.toString() };
		return _.extend(super.getHttpParams(), params);
	}
}
