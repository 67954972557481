<div class="br-login-history-list" [ngBusy]="loading">
	<table attr.aria-label="{{'administration.loginHistoryHeaders'|i18n}}" class="table slick-viewport login-history-header">
		<thead class="slick-header-columns">
			<tr>
				<th id="_t-togSelectHead" style="cursor: pointer;width:40%;" class="slick-column-name"
					(click)="toggleSortOrder()">
					{{'header.dateTime'|i18n}}
					<span *ngIf="sortDescending" class="q-icon q-icon-angle-bottom"></span>
					<span *ngIf="!sortDescending" class="q-icon q-icon-angle-top"></span>
				</th>
				<th style="cursor: pointer;width:20%;" class="slick-column-name">
					{{'header.ip'|i18n}}
				</th>
				<th style="cursor: pointer;width:40%;" class="slick-column-name">
					{{'header.location'|i18n}}
				</th>
			</tr>
		</thead>
	</table>
	<div class="table-scroll">
		<table attr.aria-label="{{'administration.loginHistory'|i18n}}" class="table slick-viewport login-history-table">
			<tbody class="grid-canvas">
				<tr class="slick-row br-login-item" *ngFor="let loginItem of loginItems; even as isEven;"
					[class.even]="isEven">
					<td style="width:40%;" class="br-datetime">{{loginItem.userTime | userTimeFormat}}</td>
					<td style="width:20%;" class="br-ip">{{loginItem.ipAddress}}</td>
					<td style="width:40%;" class="br-location">{{getLocation(loginItem.geoLocation)}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
