import { ITreeSVG } from './tree-renderer.interface';
import { ExtendedHierarchyNode } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';
import { BaseTreeRenderer, TreeNodeSelection, TreeTransition } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/base-tree-renderer';
import { HierarchyTreeOptions } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/hierarchy-tree-options.class';
import { TreeRendererUtils } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer-utils';

export class HorizontalTreeRenderer extends BaseTreeRenderer {

	readonly NODE_HEIGHT = BaseTreeRenderer.MAX_BUBBLE_DIAMETER;
	// initially it uses container size for viewBox, but after 1st render it asjust it based on results to avoid truncation
	private fullWidth: number;
	private leftOffset: number;

	constructor(
		options: HierarchyTreeOptions,
		private container: ng.IAugmentedJQuery,
		private root: ExtendedHierarchyNode,
		private svg: ITreeSVG,
		private gNode: ITreeSVG,
		private gLink: ITreeSVG,
		private gPatterns: ITreeSVG,
		usePatternFills: boolean = false,
		isDarkMode: boolean = false
	) {
		super(options, usePatternFills, isDarkMode);
	}

	private processTreeLayout(): void {
		let dy = this.container[0].clientWidth / (this.root.height + 1);
		this.root.x0 = dy / 2;
		this.root.y0 = 0;
		let tree = d3.tree().nodeSize([this.NODE_HEIGHT, dy]);
		tree(this.root);
		this.root.y += dy / 3; // move root to the right to reduce white space
		let leaves = this.root.leaves();
		let maxDepth = (_.max(leaves, leaf => leaf.depth) as ExtendedHierarchyNode).depth;
		// move right edge leaves to the left
		leaves.forEach(leaf => leaf.y -= leaf.depth === maxDepth ? dy / 3 : 0);
	}

	private initializeSize(): void {
		let boundaries = this.getMinMax(this.root);
		let containerWidth = this.container[0].clientWidth;
		let margin = {
			left: containerWidth / (this.root.height + 1) / 2,
			top: this.NODE_HEIGHT,
			bottom: this.NODE_HEIGHT
		};
		let top = boundaries.min - margin.top;
		let height = boundaries.max - boundaries.min + margin.top + margin.bottom;
		let actualWidth = Math.max(this.fullWidth || 0, containerWidth);
		$(this.svg.node()).width(actualWidth);
		this.initTransition(this.svg, [this.leftOffset || -margin.left, top, actualWidth, height]);
	}

	protected drawShape(node: TreeNodeSelection): TreeNodeSelection {
		return node.append('circle')
			.attr('r', d => d.size)
			.attr('fill', (d) => this.getFill(d.color, this.gPatterns))
			.attr('stroke-width', () => this.getStrokeWidth(10))
			.attr('stroke', () => this.getStrokeColor());
	}

	protected drawText(node: TreeNodeSelection): TreeNodeSelection {
		return node.append('text')
			.attr('dy', '0.31em')
			.attr('x', d => TreeRendererUtils.isLeaf(d) ? 16 : -16)
			.attr('text-anchor', d => TreeRendererUtils.isLeaf(d) ? 'start' : 'end')
			.text(d => d.data.displayName);
	}

	protected updateText(node: TreeTransition): TreeTransition {
		return node.text(d => d.data.displayName);
	}

	renderNode(source: ExtendedHierarchyNode): void {
		const nodes = this.root.descendants().reverse();
		const links = this.root.links();

		// Compute the new tree layout.
		this.processTreeLayout();
		this.initializeSize();

		this.renderNodes(this.gNode, nodes,
			[source.y0, source.x0],
			d => [d.y, d.x],
			[source.y, source.x]);

		this.renderLinks(this.gLink, links,
			d3.linkHorizontal().x(d => d[1]).y(d => d[0]),
			[source.x0, source.y0],
			d => [d.x, d.y],
			[source.x, source.y]);

		// Stash the old positions for transition.
		this.root.eachBefore(d => {
			d.x0 = d.x;
			d.y0 = d.y;
		});

		this.transition.end().then(() => this.recalculateSize());
	}

	scrollTo(node: ExtendedHierarchyNode): void {
		// root is at 0, but viewBox is shifted
		this.transition.end().then(() => {
			this.container.scrollTop(-this.getMinMax(this.root).min + node.x - this.container.height() / 2);
		});
	}

	recalculateSize(): void {
		let bbox = (this.gNode.node() as SVGGraphicsElement).getBBox();
		this.fullWidth = bbox.width;
		this.leftOffset = bbox.x;
		this.initializeSize();
	}
}
