<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.samlSPSettingsTitle' | i18n">
</modal-header>
<div class="modal-body">
	<form #serviceProviderForm="ngForm" class="form-horizontal">
		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.spAliasName'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.spAliasNameHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input name="aliasName" class="form-control"
					[(ngModel)]="serviceProvider.aliasName"
					[required]="true"
					[pattern]="ALIAS_NAME_PATTERN"
					autocomplete="new-password"
					required
					#aliasName="ngModel"
					(ngModelChange)="onAliasNameInput()"/>
				<p *ngIf="aliasName.invalid && aliasName.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'">
				</p>

				<p *ngIf="aliasName.errors?.exist && (aliasName.dirty || aliasName.touched)"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.spAliasNameExist'">
				</p>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.spEntityId'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.spEntityIdHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input name="entityId" class="form-control"
					[(ngModel)]="serviceProvider.entityId"
					[required]="true"
					[pattern]="ENTITY_ID_PATTERN"
					autocomplete="new-password"
					required
					#entityId="ngModel"
					(ngModelChange)="onEntityIdInput()"/>
				<p *ngIf="entityId.invalid && entityId.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'">
				</p>

				<p *ngIf="entityId.errors?.exist && (entityId.dirty || entityId.touched)"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.spEntityIdExists'">
				</p>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.entityBaseURL'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.entityBaseURLHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input name="entityBaseURL" class="form-control"
					[(ngModel)]="serviceProvider.entityBaseURL"
					[required]="true"
					required
					autocomplete="new-password"
					#entityBaseURL="ngModel"/>
					<p *ngIf="entityBaseURL.invalid && entityBaseURL.errors.required"
						class="help-block text-danger mt-4"
						[i18n]="'administration.requiredField'">
					</p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'common.description'|i18n}}</label>
			<div class="col-md-8">
				<textarea class="form-control"
					autocomplete="nope"
					name="description"
					[maxlength]="500"
					[minlength]="10"
					required
					[(ngModel)]="serviceProvider.description"
					#description="ngModel">
				</textarea>
				<div *ngIf="description.invalid">
					<p *ngIf="description.errors.required" class="help-block text-danger mt-4"
						[i18n]="'administration.requiredField'"></p>
					<p *ngIf="description.errors.minlength" class="help-block text-danger mt-4"
						[i18n]="'mAccount.descriptionLengthError'" [i18nParams]="description.errors.minlength"></p>
				</div>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelKeyPairConfig'|i18n}}</label>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.keyPairFile'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.keyPairFileHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-2">
				<button class="btn br-upload-button-wrapper btn-secondary"
						(click)=keyPairUploaderButton.click()>

					<span>{{'common.upload'|i18n}}</span>
					<input name="keyPairFile" class="br-upload-file"
						type="file"
						#keyPairUploaderButton
						filters="p12"
						[required]="!serviceProvider.keyPair.filename"
						[(ngModel)]="keyPairFile"
						(change)="doKeyPairUpload($event)"/>
				</button>
			</div>
			<p class="col-md-6" [ngClass]="{'text-danger': keyPairUploadError}">
				{{ keyPairUploadError ? keyPairUploadError : serviceProvider.keyPair.filename }}
			</p>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.keyPairPassword'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.keyPairPasswordHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input name="keyPairPassword" class="form-control" type="password"
					[(ngModel)]="serviceProvider.keyPair.password"
					[required]="true"
					required
					autocomplete="new-password"
					#keyPairPassword="ngModel"/>
				<p *ngIf="keyPairPassword.invalid && keyPairPassword.errors.required"
						class="help-block text-danger mt-4"
						[i18n]="'administration.requiredField'">
				</p>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelSigningAndSecurityConfig'|i18n}}</label>
			</div>
		</div>

		<div class="form-group mb-0">
			<div class="col-md-8 col-md-offset-3">
				<div class="labeled-checkbox mb-4">
					<checkbox-button
						name="signMetadata"
						id="signMetadata"
						buttonClass="btn-sm"
						label="{{'mAccount.signMetadata'|i18n}}"
						inlineHelp="{{'mAccount.signMetadataHelp'|i18n}}"
						[(ngModel)]="serviceProvider.signMetadata">
					</checkbox-button>
					<radio-buttons
						id="signingAlgorithmGroup"
						name="signingAlgorithmGroup"
						class="col-md-8"
						[(ngModel)]="serviceProvider.signingAlgorithm"
						[options]="signingAlgorithms"
						[disabled]="!serviceProvider.signMetadata">
					</radio-buttons>
				</div>

				<div class="labeled-checkbox">
					<checkbox-button
						name="requestSigned"
						id="requestSigned"
						buttonClass="btn-sm"
						label="{{'mAccount.requestSigned'|i18n}}"
						inlineHelp="{{'mAccount.requestSignedHelp'|i18n}}"
						[(ngModel)]="serviceProvider.requestSigned">
					</checkbox-button>
				</div>

				<div class="labeled-checkbox">
					<checkbox-button
						name="wantAssertionSigned"
						id="wantAssertionSigned"
						buttonClass="btn-sm"
						label="{{'mAccount.wantAssertionSigned'|i18n}}"
						inlineHelp="{{'mAccount.wantAssertionSignedHelp'|i18n}}"
						[(ngModel)]="serviceProvider.wantAssertionSigned">
					</checkbox-button>
				</div>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.logoutProcessConfig'|i18n}}</label>
			</div>
		</div>

		<div class="form-group mb-0">
			<div class="col-md-8 col-md-offset-3">
				<div class="labeled-checkbox">
					<checkbox-button
						name="requireLogoutRequestSigned"
						id="requireLogoutRequestSigned"
						buttonClass="btn-sm"
						label="{{'mAccount.requireLogoutRequestSigned'|i18n}}"
						inlineHelp="{{'mAccount.requireLogoutRequestSignedHelp'|i18n}}"
						[(ngModel)]="serviceProvider.requireLogoutRequestSigned">
					</checkbox-button>
				</div>

				<div class="labeled-checkbox">
					<checkbox-button
						name="requireLogoutResponseSigned"
						id="requireLogoutResponseSigned"
						buttonClass="btn-sm"
						label="{{'mAccount.requireLogoutResponseSigned'|i18n}}"
						inlineHelp="{{'mAccount.requireLogoutResponseSignedHelp'|i18n}}"
						[(ngModel)]="serviceProvider.requireLogoutResponseSigned">
					</checkbox-button>
				</div>
			</div>
		</div>
	</form>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="!isSettingsValid()"
></save-modal-footer>
