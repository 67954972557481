import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AnyGrouping } from '@cxstudio/reports/entities/any-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { GroupingUtils } from '@cxstudio/reports/utils/analytic/grouping-utils.class';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';

@Component({
	selector: 'report-grouping-properties',
	templateUrl: './report-grouping-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGroupingPropertiesComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Input('grouping') originalGrouping: ReportGrouping;
	@Output() groupingChange = new EventEmitter<ReportGrouping>();
	@Output() validityChange = new EventEmitter<boolean>();

	@Input() documentLevelOnly: boolean;
	@Input() sentenceLevel: boolean;

	@Input() peerHierarchyGrouping: boolean;
	@Input() useHistoricPeriod: boolean;
	@Input() selectedMetrics: ReportCalculation[];
	@Input() widgetType: WidgetType;

	@Input() filterRemovalWarning: boolean = false; // for selector widget with custom order
	@Input() geography: boolean = false; // for map widget
	@Input() customMultiselect: boolean = false; // for selector
	@Input() stackedGroup: boolean; // whether to show stack type, bar/line only

	WidgetType = WidgetType;

	loading: Promise<any>;
	grouping: AnyGrouping;
	calculations: INode[];

	constructor(
		private ref: ChangeDetectorRef,
		private attributesService: AttributesService,
		private metricsService: MetricsService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
	) { }

	ngOnInit(): void {
		this.grouping = ObjectUtils.copy(this.originalGrouping) as AnyGrouping;
		this.groupingChange.emit(this.grouping);

		let attributesPromise = this.attributesService.getAttributes(this.project);
		let predefinedMetricsPromise = this.metricsService.getDynamicPredefinedMetrics(this.project, true);
		let metricsPromise = this.metricsService.getMetrics(this.project);

		this.loading = Promise.all([
			attributesPromise,
			predefinedMetricsPromise,
			metricsPromise,
		]).then((resultArray) => {
			this.calculations = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations())
				.withAttributes(resultArray[0], MetricFilters.CALCULATION)
				.withPredefinedMetrics(resultArray[1])
				.withMetrics(resultArray[2], this.project.projectId)
				.withDocumentLevelOnly(this.documentLevelOnly)
				.build();
			this.ref.markForCheck();
		});

	}

	showSelectionSettings(): boolean {
		return !this.isPredefinedMetric()
			&& !this.isTimeGrouping()
			&& !this.isDriversGrouping()
			&& !this.isMultiLevelTopic();
	}

	isTimeGrouping(): boolean {
		return AnalyticMetricTypes.isTime(this.grouping);
	}

	private isDriversGrouping(): boolean {
		return AnalyticMetricTypes.isDrivers(this.grouping);
	}

	private isMultiLevelTopic(): boolean {
		return AnalyticMetricTypes.isTopics(this.grouping) && !!this.grouping.selectedLevels;
	}

	private isPredefinedMetric(): boolean {
		return AnalyticMetricTypes.isPredefinedGroup(this.grouping);
	}

	isTopicGrouping(): boolean {
		return this.grouping.type === ReportAssetType.TOPICS || this.grouping.type === ReportAssetType.TOPIC_LEAF;
	}

	showNullIncludeSettings = () => {
		return !this.isTopicGrouping()
			&& !this.isHierarchyModel()
			&& !AnalyticMetricTypes.isWordsOrAssociatedWordsGrouping(this.grouping)
			&& !this.isNumericBreakdownGrouping();
	}

	isHierarchyModel(): boolean {
		return AnalyticMetricTypes.isHierarchyModel(this.grouping);
	}

	isNumericBreakdownGrouping(): boolean {
		return AnalyticMetricTypes.isPredefinedMetric(this.grouping)
			&& this.grouping.definition.type === MetricType.NUMERIC_BREAKDOWN;
	}

	showAttributeInclusion(): boolean {
		return this.wordsSelectionAllowed() && !this.grouping.standardMetric;
	}

	private wordsSelectionAllowed(): boolean {
		if (AnalyticMetricTypes.isAttribute(this.grouping)) {
			return (this.grouping.type === ReportAssetType.TEXT) ||
				(this.grouping.type === ReportAssetType.NUMBER && this.isCustomSortOrder());
		} else {
			return AnalyticMetricTypes.isWordsOrAssociatedWordsGrouping(this.grouping) || this.isPredefinedMetric();
		}
	}

	private isCustomSortOrder(): boolean {
		return this.grouping.sortOrder === GroupingSortOrder.CUSTOM;
	}

	showThresholds(): boolean {
		return !this.isPredefinedMetric()
			&& !this.isTimeGrouping()
			&& this.selectedMetrics?.length >= 1
			&& !this.isCustomSortOrder()
			&& !this.isMultiLevelTopic();
	}

	showUrlType(): boolean {
		return this.widgetType === WidgetType.TABLE
			&& AnalyticMetricTypes.isAttribute(this.grouping)
			&& ((this.grouping.type === ReportAssetType.TEXT) || (this.grouping.type === ReportAssetType.DIMENSIONAL));
	}

	showCapitalization(): boolean {
		return !AnalyticMetricTypes.isNumber(this.grouping)
			&& !GroupingUtils.isHashtag(this.grouping)
			&& !this.isUrl()
			&& !InternalProjectTypes.isAdminProject(this.project.projectId);
	}

	private isUrl(): boolean {
		return this.grouping.urlType
			&& (this.widgetType === WidgetType.TABLE || this.widgetType === WidgetType.SCORECARD);
	}


	isAssetsLoaded(): boolean {
		return !!this.calculations;
	}

	onLevelChanged(): void {
		if (this.grouping.selectedLevel === TopicReportGrouping.LEAF_LEVEL) {
			this.grouping.type = ReportAssetType.TOPIC_LEAF;
		} else {
			this.grouping.type = ReportAssetType.TOPICS;
		}
	}

	getModelId(): number {
		return parseInt(this.grouping.name, 10);
	}

	getHierarchyId(): number {
		return parseInt(this.grouping.name, 10);
	}

	showHierarchyInclusion(): boolean {
		return this.isHierarchyModel()
			&& [PeerReportType.PARENT_PEER_REPORT, PeerReportType.INHERITED_REPORT].contains(this.grouping.peerReportType);
	}

}
