<div [ngBusy]="loading" class="pv-16 ph-32">
	<div class="d-flex flex-direction-column mb-16">
		<label for="">{{ 'alert.timePeriod' | i18n }}</label>
		<date-filters
			(onFilterChange)="setAlertPeriod($event.dateFilterMode, $event.dateFilterRange)"
			[historicOptions]="false"
			[options]="dateOptions"
			[timezone]="projectTimezone"
			[dateFilterMode]="alert.trigger.period"
			[dateFilterRange]="alert.trigger.periodRange"
			[dateOptionsFilter]="periodOptionsFilter"
			[autoClose]="true">
		</date-filters>

	</div>
	<label>{{ 'alert.addCalculations' | i18n }}</label>
	<div class="d-flex flex-direction-column">
		<div class="d-flex flex-direction-row flex-nowrap">
			<label class="w-25-percent mr-8" for="calculation-selector">{{ 'metrics.calculation' | i18n }}</label>
			<label class="w-25-percent mr-8" for="criteria">{{ 'alert.alertCriteria' | i18n }}</label>
			<label class="w-10-percent mr-8" for="threshold">{{ 'metrics.threshold' | i18n }}</label>
			<label class="w-10-percent mr-8" for=""></label>
			<label class="w-20-percent mr-8" for="comparison">{{ 'alert.alertComparisonPeriod' | i18n }}</label>
			<label class="w-10-percent" for=""></label>
		</div>
		<div *ngFor="let condition of alert.trigger.conditions; let $index = index"
			class="d-flex flex-direction-row flex-nowrap border-t-2 border-t-solid border-gray-300 pv-8">
			<div class="w-25-percent d-flex flex-direction-column mr-8">
				<select-from-tree id="calculation-selector"
					class="flex-fill"
					data-testid="calculation"
					[appendToBody]="true"
					[ngModel]="condition.calculation?.name"
					[hierarchyList]="calculationOptions"
					(onNodeClick)="setCalculation(condition, $event.node)">
				</select-from-tree>
			</div>
			<div class="w-25-percent d-flex flex-direction-column mr-8">
				<simple-dropdown id="criteria"
					[(value)]="condition.criteria"
					(onChange)="updateThresholdType(condition)"
					[noMinWidth]="true"
					displayField="displayName"
					[options]="getCriteriaOptions(condition)">
				</simple-dropdown>
			</div>
			<div class="w-10-percent d-flex flex-direction-column mr-8" >
				<input *ngIf="!isSignificanceSelected(condition)"
					id="threshold"
					class="d-flex"
					type="number"
					[disabled]="isThresholdLocked(condition)"
					[title]="getThresholdLockedMessage(condition)"
					[(ngModel)]="condition.threshold">
			</div>
			<div class="w-10-percent d-flex flex-direction-column justify-end mr-8">
				<cb-push-buttons *ngIf="requiresComparisonPeriod(condition)"
					[list]="thresholdTypeOptions"
					[(selectedValue)]="condition.thresholdType">
				</cb-push-buttons>
			</div>
			<div class="w-20-percent d-flex flex-direction-column mr-8">
				<date-filters *ngIf="requiresComparisonPeriod(condition)"
					id="comparison"
					(onFilterChange)="setAlertComparisonPeriod(condition, $event.dateFilterMode, $event.dateFilterRange)"
					[historicOptions]="true"
					[options]="dateOptions"
					[timezone]="projectTimezone"
					[dateFilterMode]="condition.comparisonPeriod"
					[dateFilterRange]="condition.comparisonPeriodRange"
					[dateOptionsFilter]="compareOptionsFilter"
					[autoClose]="true">
				</date-filters>
			</div>
			<div class="w-10-percent">
				<button *ngIf="$index >= 1"
					class="btn btn-icon"
					title="{{'common.remove'|i18n}}"
					[attr.aria-label]="'common.remove'|i18n"
					(click)="removeCondition($index)">
					<span aria-hidden="true" class="q-icon q-icon-trash"></span>
				</button>
			</div>
		</div>
	</div>
	<form-error *ngIf="calculationError" class="mt-8" text="{{'alert.popMetricNotSupported' | i18n}}"></form-error>
	<button class="btn btn-secondary d-flex align-center mb-16"
		[disabled]="alert.trigger.conditions.length >= 2"
		(click)="addCondition()">
		<span aria-hidden="true" class="q-icon q-icon-add-3 mr-4"></span>
		{{ 'alert.addTriggerCriteria' | i18n }}
	</button>

	<labeled-toggle-switch
		class="d-flex mb-8"
		[label]="'alert.enableSegments' | i18n"
		[(value)]="groupingEnabled"
		(valueChange)="onGroupingClear()"
		[title]="'alert.segmentsSubtitle' | i18n">
	</labeled-toggle-switch>
	<div *ngIf="groupingEnabled" class="d-flex flex-direction-column mb-8">
		<label for="grouping-selector">{{ 'alert.checkCriteriaFor' | i18n }}</label>
		<div class="d-flex flex-direction-row w-100-percent">
			<report-grouping-selector
				id="grouping-selector"
				class="flex-fill"
				[project]="project"
				[cogTemplate]="cogTemplate"
				[(selection)]="alert.trigger.grouping"
				(selectionChange)="onGroupingChange($event)">
			</report-grouping-selector>
			<ng-template #cogTemplate>
				<button
					type="button"
					class="btn btn-icon grouping-config ml-8 d-flex"
					[disabled]="!alert.trigger.grouping"
					(click)="openGroupingProperties(groupingPropertiesTemplate)">
					{{'common.settings' | i18n}}
					<span class="ml-8 q-icon q-icon-cog"></span>
				</button>
				<button *ngIf="alert.trigger.grouping"
					type="button"
					class="btn btn-icon"
					(click)="onGroupingClear()"
					title="{{'common.clear' | i18n}}">
					<span class="q-icon q-icon-trash" aria-hidden="true"></span>
				</button>
			</ng-template>
			<ng-template #groupingPropertiesTemplate let-dialog>
				<report-grouping-properties
					[project]="project"
					[grouping]="alert.trigger.grouping"
					(groupingChange)="dialog.setEntity($event)"
					(validityChange)="dialog.setValid($event)"
					[sentenceLevel]="isSentenceLevelGrouping()"
					[selectedMetrics]="getSelectedCalculations()">
				</report-grouping-properties>
			</ng-template>
		</div>
	</div>
	<div *ngIf="groupingEnabled" class="d-flex flex-direction-column mb-8">
		<label for="minVolume">{{ 'alert.skipAlertIfBelow' | i18n }}</label>
		<input id="minVolume"
			class="d-flex"
			type="number"
			[strictMin]="0"
			[(ngModel)]="alert.trigger.minVolume">
	</div>
</div>
