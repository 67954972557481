<dashboard-filters-panel
	*ngIf="dashboard && dashboardHistory"
	class="flex-fixed"
	[toggle]="toggle"
	[dashboard]="dashboard"
	[dashboardHistory]="dashboardHistory"
	[personalization]="personalization"
	[projectTimezone]="filtersPanel?.projectTimezone"
	[isEmbeddedInternally]="isEmbeddedInternally"
	(stateChange)="changePopupState($event.active)"
	[embedded]="embedded"
	[truncate]="truncate">
</dashboard-filters-panel>

<!--
	careful with this - it may stop working if moved into another div. It's used for popup positioning
-->
<div id="dashboard-filters-popup"
	class="dashboard-view-filters"
	[ngbPopover]="popContent"
	#popover="ngbPopover"
	container="body"
	[autoClose]="false"
	popoverClass="dashboard-filters-pop"
	placement="bottom-left">
</div>

<ng-template #popContent>
	<dashboard-filters-edit
		class="min-width-2 p-8"
		id="dashboard-filters-popup"
		[embedded]="embedded"
		[dashboard]="dashboard"
		[dashboardHistory]="dashboardHistory"
		[personalization]="personalization"
		[isVisible]="true"
		[editMode]="false"
		[projectAttributes]="filtersPanel.projectAttributesFiltered"
		[projectTimezone]="filtersPanel?.projectTimezone"
		[projectDateFilters]="filtersPanel.projectDateFilters"
		(applyFilters)="filtersPanel.applyFilters()"
		(onFilterChange)="filtersPanel.addFilterChange($event.$action, $event.$filter)"
		(closePopup)="popover.close()">
	</dashboard-filters-edit>
</ng-template>
