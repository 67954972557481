import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { BaseSelectUtils } from '@app/modules/item-grid/selection/base-selection-utils';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { GridMode } from '@cxstudio/grids/grid-mode';


declare interface IListScope {
	ui: {
		hideDashboards: boolean;
		searchFilter: string;
	};
	visibleList: any[];
	refreshGrid: (itemsToRefresh: any[]) => void;
	gridMode: string;
	tabsEditor: { enabled: boolean };
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('DashboardSelectUtils', function(dashboardListService: DashboardListService) {
	return class DashboardSelectUtils extends BaseSelectUtils {
		private currentScope: IListScope;

		constructor(scope: IListScope) {
			super();
			this.currentScope = scope;
		}

		getVisibleObjectsList = (): any => {
			return this.currentScope.visibleList;
		}

		getObjectsList = (): any => {
			return dashboardListService.getCurrentDashboardsList();
		}

		isSupportedType = (item: any): boolean => {
			return item.type === GridContext.DASHBOARD || item.type === GridContext.DASHBOARD_SET;
		}

		getSearchFilter = (): any => {
			return this.currentScope.ui && this.currentScope.ui.searchFilter;
		}

		refreshObjects = (items: any[]): void => {
			return this.currentScope.refreshGrid(items);
		}

		// find the dashboards/books that are visible
		visibleObjFilter = (item: any): boolean => {
			let isHidingDashboards = this.currentScope.ui && this.currentScope.ui.hideDashboards;
			return (!item.hide || !isHidingDashboards) && !item.hiddenViaFilter && this.isSupportedType(item);
		}

		showSelectAll = (): boolean => {
			return (this.currentScope.gridMode === GridMode.EDIT)
				&& !this.currentScope.tabsEditor.enabled
				&& this.someObjectsVisible();
		}
	};
});
