export enum FolderTypes {
	FILTER = 'filterFolder',
	DASHBOARD = 'folder',
	METRIC = 'metricFolder',
	ATTRIBUTE = 'attributeFolder',
	DRIVERS = 'driversFolder',
	DASHBOARD_TEMPLATES = 'templateFolder',
	FILTER_TEMPLATES = 'filterTemplateFolder',
	UNIFIED_TEMPLATES = 'unifiedTemplateFolder',
}


