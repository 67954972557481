import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { WidgetError } from './widget-error.class';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'widget-error',
	template: `
		<div class="d-flex justify-center align-center h-100-percent w-100-percent italic" *ngIf="!widgetError || widgetError.isSimpleMessage()">
			<ng-container *ngIf="!demo">
				<span *ngIf="widgetError" data-testid="widget-error-message" class="text-center" [i18n]="widgetError.message"></span>

				<!-- transclude content if no widgetError object is provided -->
				<ng-content *ngIf="!widgetError"></ng-content>
			</ng-container>
		</div>

		<div *ngIf="widgetError && !widgetError.isSimpleMessage()"
			class="d-flex justify-center flex-direction-column align-center h-100-percent w-100-percent text-center mb-24">
			<ng-container *ngIf="!demo">
				<span class="q-icon text-lg-6 text-gray-800" aria-hidden="true" [ngClass]="widgetError.icon"></span>
				<p data-testid="widget-error-message" class="text-base font-semibold mb-0" [i18n]="widgetError.message"></p>
				<p data-testid="widget-error-description" class="text-sm-1 font-normal text-gray-800" [i18n]="widgetError.description"></p>
			</ng-container>
		</div>
	`,
	styles: [`
		:host { white-space: pre-wrap; }

		div {
			font-size: var(--font-size-default);
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetErrorComponent {
	@Input() widgetError: WidgetError;
	@Input() demo: boolean;
}

app.directive('widgetError', downgradeComponent({ component: WidgetErrorComponent }) as angular.IDirectiveFactory);
