import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	ElementRef,
	AfterViewInit,
	OnDestroy
} from '@angular/core';
import { TokenSuggestion } from '../custom-math-suggestion.class';
import { CustomMathSuggestionsService } from '../custom-math-suggestions.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { MathMetricTokenType } from '../math-metric-token';
import * as uuid from 'uuid';

export enum DropdownSelector {
	EDITOR = '.math-editor-dropdown',
	BUTTON_BAR = '.button-bar-dropdown'
}

@Component({
	selector: 'numeric-aggregations-submenu',
	templateUrl: './numeric-aggregations-submenu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericAggregationsSubmenuComponent implements AfterViewInit, OnDestroy {

	@Input() dropdownSelector: string;

	@Output() submenuItemClick = new EventEmitter<TokenSuggestion>();

	aggregations: TokenSuggestion[];
	highlightedAggregationIndex: number;
	id: string;

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		private customMathSuggestionsService: CustomMathSuggestionsService,
		private elementRef: ElementRef
	) {
		this.aggregations = this.customMathSuggestionsService.getNumericAggregations();
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT)) {
			this.aggregations.push(this.customMathSuggestionsService.getCountDistinctAggregation(MathMetricTokenType.NUMERIC_AGGREGATION));
		}

		this.highlightedAggregationIndex = 0;
		this.id = uuid.v4();
	}

	getTop(): string {
		let listItem: HTMLElement = this.elementRef.nativeElement.closest('.suggestion-list-item');
		if (listItem === null) {
			listItem =  this.elementRef.nativeElement.closest('li'); // for lists other than suggestion list in custom-math-editor suggestion dropdown
		}
		let dropdown: HTMLElement = listItem?.closest(this.dropdownSelector);

		let attributeListItemTop: number = listItem?.getBoundingClientRect().top;
		let dropdownTop: number = dropdown?.getBoundingClientRect().top;
		const INDENTATION: number = this.dropdownSelector === DropdownSelector.EDITOR ? 5 : 2;

		return attributeListItemTop - dropdownTop - INDENTATION + 'px';
	}

	onAggregationKeydown(event: KeyboardEvent, aggregation: TokenSuggestion, aggregationIndex: number): void {
		KeyboardUtils.intercept(event);

		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.submenuItemClick.emit(aggregation);
			return;
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE) || KeyboardUtils.isEventKey(event, Key.LEFT)) {
			this.closeSubmenuAndFocusActiveItem();
			return;
		}

		this.processArrows(event, aggregationIndex);
	}

	private closeSubmenuAndFocusActiveItem(): void {
		let activeItem: JQuery = $(this.elementRef.nativeElement).closest('li');
		activeItem.trigger('click');
		if (this.dropdownSelector === DropdownSelector.EDITOR) {
			$('.formula-input').trigger('focus');
		} else {
			activeItem.find('a').first().trigger('focus');
		}
	}

	private processArrows(event: KeyboardEvent, aggregationIndex: number): void {
		let nextAggregationIndex: number = this.customMathSuggestionsService.getNextAggregationIndex(event, aggregationIndex);
		if (nextAggregationIndex !== -1) {
			this.highlightedAggregationIndex = nextAggregationIndex;
			($(this.elementRef.nativeElement).find('a.math-aggregation').get(this.highlightedAggregationIndex) as HTMLElement).focus();
		}
	}

	ngAfterViewInit(): void {
		const dropdownMenu = $(this.elementRef.nativeElement).closest('.dropdown-menu');
		$(this.elementRef.nativeElement).appendTo(dropdownMenu);
	}

	ngOnDestroy() {
		setTimeout(() => {
			$(`#${this.id}`).remove();
		}, 0);
	}
}
