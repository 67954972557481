// lower cased version of SortDirection for sentence/document requests
export enum AnSortDirection {
	ASC = 'asc',
	DESC = 'desc'
}

export enum GroupingSortOrder {
	ASC = 'asc',
	DESC = 'desc',
	CUSTOM = 'custom',
}
