import { CustomDateFilter, DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';

export class DateRangeUtils {
	public static readonly CUSTOM_DATE_FILTER_PREFIX: string = 'customFilter:';

	static isCustomDateFilterMode(dateFilterMode: DateFilterMode | CustomDateFilter): boolean {
		return dateFilterMode && dateFilterMode.startsWith(DateRangeUtils.CUSTOM_DATE_FILTER_PREFIX);
	}

	static isCustomDateFilter = (dateFilter: DateFilter): boolean => {
		return dateFilter && DateRangeUtils.isCustomDateFilterMode(dateFilter.dateFilterMode);
	}

	static getCustomDateFilterId = (dateFilterMode: DateFilterMode | CustomDateFilter): number => {
		return parseInt(dateFilterMode.replace(DateRangeUtils.CUSTOM_DATE_FILTER_PREFIX, ''), 10);
	}
}
