import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { DocumentExplorerEvents } from '@app/modules/document-explorer/events/document-explorer-events';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ProjectSettingsMap, ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { IWidgetState } from '@app/modules/widget-container/widget-state.interface';
import { ErrorMessageProcessingService } from '@app/modules/widget-visualizations/utilities/error-message-processing.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ScorecardInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { IContextPaneAttribute } from '@cxstudio/reports/document-explorer/context-pane-attribute.interface';
import { ConversationAttributesService } from '@app/modules/document-explorer/conversations/conversation-attributes.service';
import { ConversationDocument, IDocumentClassification, IDocumentClassificationNode } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { DocExplorerQidsService } from '@cxstudio/reports/document-explorer/doc-explorer-qids.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { IExplorerHighlighter } from '@cxstudio/reports/document-explorer/explorer-highlighter-factory';
import { WorldAwarenessService } from '@cxstudio/reports/document-explorer/world-awareness-attributes.service';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { Model } from '@cxstudio/reports/entities/model';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { PreviewSentence, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { DocumentSharingApiService } from '@cxstudio/services/data-services/document-sharing-api-service';
import { IHiddenObject, UserObjectsService } from '@cxstudio/services/user-objects.service';
import * as _ from 'underscore';
import { ConversationAttributes } from '../conversations/conversation-attributes.constant';
import { ConversationChannelService } from '../conversations/conversation-channel.service';
import { EnrichmentAttributeSettings } from '../doc-explorer-attribute-settings.interface';
import { DocumentCacheService } from '../document-cache.service';
import { EnrichmentAttributesService } from '../enrichment-attributes.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { WidgetError } from '@app/modules/widget-visualizations/common/widget-error/widget-error.class';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { DocExplorerFavorites, IPreviewWidgetFavorites } from '@app/modules/document-explorer/context-pane/doc-explorer-favorites.class';

interface IDocumentPreviewScope extends ISimpleScope {
	loading: ng.IPromise<any>;
	selectedAttributes: any[];
	selectedWorldAwareness: any[];
	documentLoadInProgress: boolean; // this doesn't work =/
	documentMetadataLoading: ng.IPromise<any>;
}

export class WidgetDocumentPreview implements ng.IComponentController {
	isDocExplorer: boolean;
	dashboard: Dashboard;
	widget: Widget;
	private voiceEnabled: boolean;
	private engagorEnabled: boolean;
	documentManager: IDocumentPreviewerControls;
	initDocumentManager: (documentManager: IDocumentPreviewerControls) => void;
	private constants;
	filters;
	preferences;
	private highlighter: IExplorerHighlighter;
	private events: DocumentExplorerEvents;
	private accountLvlHiddenModelsAndAttrs: IHiddenObject[];
	private availableAttributes: IReportAttribute[];
	availableModels: Model[];
	private docExplorerFavorites: IPreviewWidgetFavorites;
	private filteringFeedbackSelection: IAnalyticFeedbackSelection;
	sharingFeedbackSelection: IAnalyticFeedbackSelection;
	private widgetState: IWidgetState;
	projectUniqueId: string;
	private reloadStatistics: () => void;
	private selectedScorecard?: number;
	auditMode: boolean;
	rebuttalMode: boolean;
	widgetError: WidgetError;
	private showContext;
	private showExportData: () => boolean;
	private isStatsEnabled: () => boolean;

	private projectSettingsMap: ProjectSettingsMap;
	predefinedMetrics;

	constructor(
			private $element,
			private $log: ng.ILogService,
			private $q: ng.IQService,
			private $rootScope: ng.IRootScopeService,
			private $scope: IDocumentPreviewScope,
			private cbDialogService: CBDialogService,
			private contentProviderOptionsService: ContentProviderOptionsService,
			private readonly reportAttributesService: ReportAttributesService,
			private readonly reportModelsService: ReportModelsService,
			private docExplorerQids: DocExplorerQidsService,
			private documentCacheService: DocumentCacheService,
			private ExplorerDataControls,
			private ExplorerHighlighter,
			private locale: ILocale,
			private metricConstants: MetricConstants,
			private security: Security,
			private conversationChannelService: ConversationChannelService,
			private worldAwarenessService: WorldAwarenessService,
			private reportUtils: ReportUtils,
			private errorMessageProcessing: ErrorMessageProcessingService,
			private readonly reportAssetUtilsService: ReportAssetUtilsService,
			private readonly reportSettingsService: ReportSettingsService,
			private userObjectsService: UserObjectsService,
			private documentSharingApiService: DocumentSharingApiService,
			private conversationAttributesService: ConversationAttributesService,
			private readonly dashboardAccessService: DashboardAccessService,
			private enrichmentAttributesService: EnrichmentAttributesService,
			private profanityDisguiseService: ProfanityDisguiseService,
			private metricsService: MetricsService,
			private readonly customFilterService: CustomFilterService,
			private readonly downgradeDialogService: DowngradeDialogService,
			private readonly currentWidgets: ICurrentWidgets,
	) {}

	$onInit = () => {
		this.constants = this.metricConstants.get();
		this.accountLvlHiddenModelsAndAttrs = this.accountLvlHiddenModelsAndAttrs || [];

		this.$scope.$on('DocumentPreviewRefresh', (event, args) => this.loadDocumentExplorerData(args && args.fullReload));

		if (this.widget) {
			this.projectUniqueId = `${this.widget.properties.contentProviderId}/${this.widget.properties.accountId}/${this.widget.properties.project}`;
			let settingsPromise = PromiseUtils.old(this.reportSettingsService.getWidgetProjectSettings(this.widget.properties));
			let hiddenPromise = this.userObjectsService.getHiddenAttributesAndModels(this.widget, this.isDocExplorer);
			const project = this.reportAssetUtilsService.getWidgetProject(this.widget);
			let predefinedMetricsPromise = PromiseUtils.old(this.metricsService.getPredefinedMetrics(project));
			this.$q.all([settingsPromise, hiddenPromise, predefinedMetricsPromise]).then((results) => {
				this.projectSettingsMap = results[0];
				this.accountLvlHiddenModelsAndAttrs.pushAll(results[1]);
				this.predefinedMetrics = results[2];

				if (this.widget.selectivePreview) {
					this.$scope.loading = this.validateUserAccess(this.widget.properties).then(() => {
						this.loadDocumentExplorerData();
					}, (errors) => {
						this.cbDialogService.warning(this.locale.getString('common.warning'), errors.join('<br>'));
					});
				} else {
					this.loadDocumentExplorerData();
				}

			});

			this.reloadStatistics = this.reloadStatistics || _.noop;
		}

		// if doc exp favorites is passed in, use it, otherwise create new
		this.docExplorerFavorites = this.docExplorerFavorites
			|| new DocExplorerFavorites(this.preferences, this.widget.properties.contentProviderId,
				this.widget.properties.accountId, this.widget.properties.project);

		this.events = new DocumentExplorerEvents();
		this.highlighter = new this.ExplorerHighlighter(this.$element, undefined, (event, node, direction) => {
			this.$scope.$broadcast(event, node, direction);
		}, this.events);

		this.documentManager = new this.ExplorerDataControls(
			this.widget, this.filters, this.get, this.accountLvlHiddenModelsAndAttrs, this.$scope.selectedAttributes,
			this.$scope.selectedWorldAwareness, this.highlighter, this.events,
			this.sharingFeedbackSelection, this.filteringFeedbackSelection, this.preferences, this.isDocExplorer);
		if (this.initDocumentManager) {
			// its purpose is to pass document manager to doc explorer scope, which uses it actively
			// this is so wrong, but requires too much refactoring to make it right (i.e. rerwite doc explorer ctrl)
			this.initDocumentManager(this.documentManager);
		}

		// pass documentManager and preferences to reportCtrl. Not ideal, need to be refactored in the future.
		this.$scope.$emit('widget:passReferences', {documentManager: this.documentManager, preferences: this.preferences});
		this.documentManager.favoriteScorecard = this.docExplorerFavorites.getFavoriteScorecardId();
		if (this.selectedScorecard) {
			this.documentManager.selectedScorecard = this.selectedScorecard;
			this.preferences.settings.paneCollapsedState = {
				worldAwarenessCollapsed: true,
				engagorCollapsed: true,
				scorecardCollapsed: false,
				automatedSummariesCollapsed: false,
				complianceManagementCollapsed: false,
				attrCollapsed: true,
				topicsCollapsed: true,
				favoriteAttributesCollapsed: true,
				favoritesTextFilter: '',
				attrTextFilter: '',
				worldAwarenessSearch: '',
				topicSearch: ''
			};
		} else if (this.documentManager.favoriteScorecard) {
			this.documentManager.selectedScorecard = this.documentManager.favoriteScorecard;
		}



		this.$scope.$on('feedbackSelection:editStarted',
			() => this.documentManager.switchFeedbackSelection(this.filteringFeedbackSelection));
		this.$scope.$on('feedbackSelection:editFinished',
			() => this.documentManager.switchFeedbackSelection(this.sharingFeedbackSelection));

		this.voiceEnabled = this.security.getCurrentMasterAccount().vociEnabled;
		this.engagorEnabled = this.security.getCurrentMasterAccount().engagorEnabled;

		this.documentManager.resetSelected();
		this.$scope.documentLoadInProgress = false;

		this.documentManager.documents = {};

		this.$scope.$on('widget:openInExplorerEvent', this.showInExplorer);
	}

	hasNoResult = (): boolean => {
		return !((this.documentManager.state && this.documentManager.state.loadingSentencePanel !== false)
			|| (this.documentManager.sentenceList && this.documentManager.sentenceList.length > 0));
	}

	validateUserAccess = (properties) => {
		let deferred = this.$q.defer();
		let errors = [];

		if (!this.security.has('drill_to_feedback_in_view')) {
			errors.push(this.locale.getString('docExplorer.noPermissions'));
		}

		if (properties.encodedDescriptor) {
			this.verifyDocumentLink(properties, errors, deferred);
		} else {
			this.verifyProjectAccess(properties, errors, deferred);
		}

		return deferred.promise;
	}

	private verifyDocumentLink = (properties, errors, deferred): void => {
		this.documentSharingApiService.verifyDocumentLink(properties.encodedDescriptor).then(
			() => {
				if (errors.length) {
					deferred.reject(errors);
				} else {
					deferred.resolve();
				}
			},
			() => {
				errors.push(this.locale.getString('docExplorer.noAccess'));
				deferred.reject(errors);
			});
	}

	private verifyProjectAccess = (properties, errors, deferred): void => {
		this.contentProviderOptionsService
			.getProjectsAccessLevel(properties.contentProviderId, properties.accountId,
				this.security.getUser().userId, false)
			.then((response) => {
				let accounts = response.data.data;

				if (!_.contains(this.getProjectList(accounts), properties.project)) {
					errors.push(this.locale.getString('docExplorer.noAccess'));
				}

				if (errors.length === 0) {
					deferred.resolve();
				} else {
					deferred.reject(errors);
				}
			});
	}

	getProjectList = (accounts) => {
		let projects = [];

		accounts.forEach((account) => {
			account.projects.forEach((project) => {
				projects.push(project.project.id);
			});
		});

		return projects;
	}

	initializeModelSearch = () => {
		let modelSearchInitialized = this.preferences?.settings?.modelSearch && !!this.preferences?.settings?.modelSearch[this.projectUniqueId];
		if ((this.isDocExplorer || this.widget.name === WidgetType.PREVIEW) && !modelSearchInitialized) {
			this.preferences.settings.modelSearch = this.preferences.settings.modelSearch || {};
			this.preferences.settings.modelSearch[this.projectUniqueId] = {};
			let visibleModels = _.filter(this.availableModels, this.isModelVisibleToUser).map(model => model.id);
			_.each(visibleModels, (modelId) => {
				this.preferences.settings.modelSearch[this.projectUniqueId][modelId] = true;
			});
			this.preferences.storeUpdates();
		}
	}

	loadDocumentExplorerData = (fullReload?: boolean) => {
		if (this.documentManager.state.initialLoad) {
			return;
		}
		let widget = this.widget;
		let properties = widget.properties;

		// clean previous documents, TODO make it per cp+project
		let widgetId: number = widget.parentWidget ? widget.parentWidget.id : widget.id;
		this.documentCacheService.init(properties.contentProviderId, properties.accountId, properties.project, widgetId);

		this.reportModelsService.getWidgetModelsWithoutHierarchies(widget).then((data) => {
			this.availableModels = _.filter(data, this.isModelVisibleToUser);
			this.documentManager.setAvailableModels(this.availableModels);
			this.initializeModelSearch();
		});

		this.reportAttributesService.getWidgetAttributesOrEmpty(widget).then((attributes) => {
			this.availableAttributes = attributes;
			this.documentManager.setAvailableAttributes(this.availableAttributes);
		});

		this.loadInitialData(fullReload);
		this.reloadStatistics();
	}

	selectSentence = (sentence): void => {
		this.documentManager.selectedSentence = sentence.id;
		this.documentManager.selectedDocument = sentence.documentId;
		this.highlighter.update(this.documentManager.documents[sentence.documentId]);
	}

	tryCachedDocument = (sentenceId: number, documentId: number): boolean => {
		if (!_.isUndefined(this.documentManager.documents[documentId]))
			return true;

		return false;
	}

	get = (sentence, onDocChange?: () => void): ng.IPromise<any> => {
		let sentenceId = sentence.id;
		let documentId = sentence.documentId;
		let isCached: boolean = this.tryCachedDocument(sentenceId, documentId);
		if (isCached) {
			if (documentId !== this.documentManager.selectedDocument && onDocChange) {
				this.highlighter.clearHighlightedNode(); // clear the highlighted topic to hide topic navigation bar
				// we have to change documents
				onDocChange();
			}
			this.selectSentence(sentence);
			this.setAttributes();
			this.documentManager.caseExistStatusLoading = true;
			this.documentManager.loadEngagorCases(documentId);
			this.filterScorecards(this.documentManager.documents[documentId]);
			return this.$q.when();
		}

		this.highlighter.clearHighlightedNode(); // clear the highlighted topic to hide topic navigation bar

		this.widget.properties.audioDocument = (this.security.getCurrentMasterAccount().vociEnabled && this.voiceEnabled);
		this.widget.properties.includeSentenceAttributes = true;

		let loadDocument = this.populateWidgetProperties(this.widget).then((widgetSettings) => {
			return this.getDocument(documentId, widgetSettings);
		});

		this.$scope.$emit('documentLoading', loadDocument);

		this.$scope.documentLoadInProgress = true;
		this.documentManager.caseExistStatusLoading = true;
		this.documentManager.state.loadingDocPanel = loadDocument.then((dataObject: ConversationDocument): ng.IPromise<any> => {
			this.conversationChannelService.setUnknownParticipantType(dataObject.sentences);

			this.documentManager.state.previewDocumentMessage = '';
			this.documentManager.documents[documentId] = dataObject;
			this.docExplorerQids.documentQid = dataObject.metadata && dataObject.metadata.qid;

			if (DocumentTypeUtils.isConversation(dataObject)) {
				this.conversationAttributesService.populateVoiceMetrics(dataObject);
			}

			this.profanityDisguiseService.maskProfanityInDocuments([dataObject]);

			this.selectSentence(sentence);
			this.documentManager.loadEngagorCases(documentId);
			this.processVisibleModels(dataObject);
			this.processSentenceTopics(dataObject);
			this.processHiddenScorecards(dataObject);
			this.populateScorecards(dataObject);
			this.processSentenceScorecardTopics(dataObject);

			if (DocumentTypeUtils.isConversation(dataObject)) {
				this.extractNonConversationVerbatim(dataObject);
			}
			if (this.showContext) {
				this.filterScorecards(dataObject);
			}
			if (DocumentTypeUtils.isConversation(dataObject)) {
				this.sortSentences(dataObject.sentences);
			}

			this.setAttributes();
			return this.loadDocumentMetadata(dataObject).finally(() => {
				this.$scope.documentLoadInProgress = false;
				this.$scope.$emit('documentLoaded');
			});
		}, (reason) => {
			this.$scope.documentLoadInProgress = false;
			this.$log.error('Error while getting document for preview:', reason);
			this.documentManager.state.previewDocumentMessage = this.locale.getString('widget.dataError');
			return this.$q.reject(reason);
		});
		return this.documentManager.state.loadingDocPanel;
	}

	private populateWidgetProperties = (widgetSettings: Widget) => {
		let filtersProvider = this.currentWidgets.getDashboardHistory(widgetSettings.containerId);
		return this.customFilterService.postprocessWidgetProperties(ObjectUtils.copy(widgetSettings), filtersProvider,
			this.filteringFeedbackSelection?.isEnabled());
	}

	private populateScorecards(document: ConversationDocument): void {
		document.scorecards.forEach(scorecard => {
			let attributeName = ScorecardUtils.getScorecardAttributeName(scorecard);
			scorecard.settings = this.getAttributeSettings(attributeName);
		});

		this.documentManager.availableScorecards = document.scorecards;
	}

	private processHiddenScorecards(document: ConversationDocument): void {
		document.scorecards = !document.scorecards ? [] :
			document.scorecards.filter((scorecard: ScorecardInDocView) => {
				return !this.isAttributeHiddenInAccount(ScorecardUtils.getScorecardAttributeName(scorecard));
			});
	}

	private isItemViewable(name: string): boolean {
		let contextItems = this.widget.properties.selectedAttributes;
		return this.isDocExplorer || !_.isUndefined(_.find(contextItems,
			item => item && item.name && item.name.toLowerCase() === name.toLowerCase()));
	}

	private isAttributeHiddenInAccount(name: string): boolean {
		return !_.isUndefined(_.find(this.accountLvlHiddenModelsAndAttrs,
			item => item.name.toLowerCase() === name.toLowerCase()));
	}

	private isDocExplorerAttributeHiddenInAccount(name: string): boolean {
		return this.isDocExplorer && this.isAttributeHiddenInAccount(name);
	}

	private isModelHiddenInAccount(modelId: number): boolean {
		return !_.isUndefined(_.find(this.accountLvlHiddenModelsAndAttrs,
			{id: modelId, model: true}));
	}

	isModelVisibleToUser = (model): boolean => {
		let modelId = _.isUndefined(model.modelId) ? model.id : model.modelId;
		return this.isItemViewable(modelId + '') && !this.isModelHiddenInAccount(modelId);
	}

	// add scorecard topics to sentences
	processSentenceScorecardTopics = (document: ConversationDocument) => {
		let sentenceTopics = this.getSentenceTopics(document.classification);
		let scorecardTopics: {[topicId: number]:
			{scorecardId: number, topicResult: ScorecardTopicResult, weight: number, rebutted: boolean}} = {};
		_.each(document.scorecards, (scorecard: ScorecardInDocView) => {
			_.each(scorecard.topics, scorecardTopic => {
				scorecardTopics[scorecardTopic.nodeId] = {
					scorecardId: scorecard.id,
					topicResult: scorecardTopic.result,
					weight: ScorecardDocExplorerUtils.getTopicWeight(scorecardTopic),
					rebutted: scorecardTopic.rebutted
				};
			});
		});
		_.each(document.sentences, sentence => {
			sentence.scorecardTopics = _.chain(sentenceTopics[sentence.id])
				.filter(topic => !!scorecardTopics[topic.id])
				.map(topic => ({
					id: topic.id,
					name: topic.name,
					scorecardId: scorecardTopics[topic.id].scorecardId,
					result: scorecardTopics[topic.id].topicResult,
					weight: scorecardTopics[topic.id].weight,
					rebutted: scorecardTopics[topic.id].rebutted,
				})).groupBy('scorecardId')
				.value();
		});
	}

	private filterScorecards = (document: CbDocument) => {
		if (!_.isEmpty(document.scorecards)) {
			this.documentManager.availableScorecards = document.scorecards.filter(scorecard => this.isItemViewable(scorecard.name));

			if (!this.documentManager.availableScorecards.isEmpty()) {
				this.documentManager.selectedScorecard = _.sortBy(this.documentManager.availableScorecards, 'name')[0].id;
			}
		} else {
			this.documentManager.availableScorecards = [];
		}
	}

	// populate data for sentence topics
	processSentenceTopics = (document: ConversationDocument) => {
		let sentenceTopics = this.getSentenceTopics(document.classification);
		_.each(document.sentences, sentence => {
			sentence.sentenceTopics = sentenceTopics[sentence.id];
		});
	}

	getScorecardModels = (selectedClassifications, allClassifications): any[] => {
		return _.chain(this.documentManager.availableScorecards)
			.uniq(scorecard => scorecard.modelId)
			.map((scorecardModel: any) =>
				_.find(allClassifications, (model: any) => model.modelId === scorecardModel.modelId))
			.filter(model => !!model)
			.value();
	}

	processVisibleModels = (document: ConversationDocument) => {
		let visibleSelectedModels = _.filter(document.classification, this.isModelVisibleToUser);
		let scorecardOnlyModels = this.getScorecardModels(visibleSelectedModels, document.classification);
		document.classification = _.union(visibleSelectedModels, scorecardOnlyModels);
		document.classification.forEach((model: any) => {
			// if model is only used for scorecard and not selected for display
			// we shouldnt show it in topic list for feedback widget
			if (!_.find(visibleSelectedModels, {modelId: model.modelId}))
				model.hidden = true;
		});

		_.each(document.classification, model => {
			model.searchString = _.chain(model.nodes)
				.map(node => node.name)
				.join(';')
				.value();
		});
	}

	private getSentenceTopics(classificationData: IDocumentClassification[]): {[sentenceId: number]: SentenceTopic[]} {

		let sentenceTopics: {[sentenceId: number]: SentenceTopic[]} = {};
		_.each(classificationData, (model: IDocumentClassification) => {
			_.each(model.nodes, (node: IDocumentClassificationNode) => {
				_.each(node.sentences, (sentenceId: number) => {
					sentenceTopics[sentenceId] = sentenceTopics[sentenceId] || [];
					let path = this.getTopicPath(model, node);
					sentenceTopics[sentenceId].push({id: node.id, name: node.name, modelId: model.modelId,
						path, isLeaf: node.isLeaf, modelIdPath: node.modelIdPath});
				});
			});
		});
		return sentenceTopics;
	}

	// calculate the path to a topic node
	getTopicPath = (model: IDocumentClassification, node: IDocumentClassificationNode) => {
		let computedPath: string[] = [model.modelName];
		_.each(node.modelIdPath, (pathStep) => {
			if (pathStep !== model.modelId) {
				let nodeMatch: any = _.find(model.nodes, {id: pathStep});
				if (nodeMatch) computedPath.push(nodeMatch.name);
			}
		});

		return computedPath;
	}

	// sort sentences by timestamp, as they may not be in correct order
	sortSentences = (sentences: ConversationSentence[]) => {

		// if it's audio, we need to weave the sentences back together in the right chronological order until AN updates
		sentences.sort((sentence1, sentence2) => {
			// if somehow two sentnces have the same timestamp, sort them by ID instead
			return (sentence1.timestamp - sentence2.timestamp === 0)
				? (sentence1.id - sentence2.id)
				: sentence1.timestamp - sentence2.timestamp;
		});
	}

	extractNonConversationVerbatim = (document: ConversationDocument) => {
		let hasConversationSentences: boolean = !!_.find(document.sentences, this.isConversationSentence);
		let hasNonConversationSentences: boolean = !!_.find(document.sentences, _.negate(this.isConversationSentence));

		// only separate non-conversation sentences from conversation(chat or audio) ones
		if (hasConversationSentences && hasNonConversationSentences) {
			let additionalVerbatim: ConversationSentence[] = document.sentences.filter(_.negate(this.isConversationSentence));

			let uniqueVerbatimIds = _.chain(additionalVerbatim)
				.uniq(verbatim => verbatim.verbatimId)
				.pluck('verbatimId')
				.value();

			document.additionalVerbatim = uniqueVerbatimIds.map((id) => {
				let sentences = _.filter(additionalVerbatim, verbatim => verbatim.verbatimId === id);
				return { id, sentences, verbatimType: sentences[0].verbatimType };
			}).sort((v1, v2) => v1.id - v2.id);

			document.sentences = document.sentences.filter(this.isConversationSentence);
		}
	}

	isConversationSentence = (sentence) => {
		return !_.isUndefined(sentence.timestamp) && this.conversationChannelService.hasChannel(sentence);
	}

	getDocument = (documentId, runWidget) => {
		return this.documentCacheService.getAnDocument(documentId, runWidget, this.isDocExplorer);
	}

	setAttributes = (): void => {
		if (this.documentManager.selectedSentence === -1) {
			return;
		}

		let attributes = this.documentManager.documents[this.documentManager.selectedDocument].attributes;
		_.each(attributes, (attr: IContextPaneAttribute) => {
			attr.isReportable = this.isItemViewable(attr.name) && !this.isDocExplorerAttributeHiddenInAccount(attr.name);
			attr.settings = this.getAttributeSettings(attr.name);
		});

		this.documentManager.selectedAttributes = _.filter(attributes,
			(val) => val.name.toLowerCase().indexOf('cb_') < 0);
		this.documentManager.joinSelectedAttributes();
		this.documentManager.selectedWorldAwareness = this.getWorldAwarenessAttributes();
		this.docExplorerFavorites.sync();
		this.docExplorerFavorites.update(this.documentManager.selectedAttributes, this.documentManager.selectedWorldAwareness,
			this.documentManager.documents[this.documentManager.selectedDocument].classification);
	}

	private getAttributeSettings = (attributeName: string): EnrichmentAttributeSettings => {
		let attribute = this.getAttribute(attributeName);
		return attribute ? this.enrichmentAttributesService.getAttributeSettings(attribute, this.projectSettingsMap) : {};
	}

	private getAttribute = (attributeName: string): IReportAttribute => {
		return _.find(this.availableAttributes, attr => attr.name.toLowerCase() === attributeName.toLowerCase());
	}

	private getDocumentAttributeValue(document: ConversationDocument, attributeName: string): string {
		let attribute = _.findWhere(document?.attributes, { name: attributeName });
		return attribute?.value;
	}

	loadDocumentMetadata = (returnedDocument): ng.IPromise<void> => {
		let fileId = this.getDocumentAttributeValue(returnedDocument, ConversationAttributes.CB_VTT_FILE_ID);

		if (!returnedDocument.natural_id && !fileId) {
			return this.$q.when();
		}

		const project = ProjectIdentifier.fromWidgetProperties(this.widget.properties);
		let documentId = returnedDocument.id;
		let naturalId = returnedDocument.natural_id;

		this.$scope.documentMetadataLoading =
			this.documentCacheService.getDocumentMetadata(
					project,
					this.widget.properties.workspaceProject,
					documentId, naturalId, fileId,
					this.widget.properties.encodedDescriptor,
					this.widget.properties.runAs)
				.then((response: any) => {
					let metadata = response.data.metadata;
					let matchedDocument = this.documentManager.documents[returnedDocument.id];

					if (matchedDocument) {
						_.extend(matchedDocument, _.pick(metadata, ['audioFileDescriptor', 'textFileDescriptor', 'transcriptionFileDescriptor', 'documentDescriptor', 'documentUrl']));
					}

					this.$scope.documentMetadataLoading = null;
				}, () => {
					this.$scope.documentMetadataLoading = null;
				});

		return this.$scope.documentMetadataLoading;
	}

	getWorldAwarenessAttributes = () => {
		let documentAttributes = [];
		let documentId = this.documentManager.selectedDocument;
		let worldAwarenessAttributes: any[] = this.worldAwarenessService.getWorldAwarenessAttributes();
		worldAwarenessAttributes = _.filter(worldAwarenessAttributes, (attribute) => {
			return this.isItemViewable(attribute.name) && !this.isDocExplorerAttributeHiddenInAccount(attribute.name);
		});

		_.each(this.documentManager.documents[documentId].sentences, (sentence: PreviewSentence) => {
			if (!sentence || !sentence.attributes) {
				return;
			}

			Object.keys(sentence.attributes).forEach((attributeName: string) => {
				if (!_.isUndefined(_.findWhere(worldAwarenessAttributes, { name: attributeName }))) {
					let documentAttribute = _.findWhere(documentAttributes, { name: attributeName });
					let availableAttribute = _.findWhere(this.availableAttributes, { name: attributeName });

					let getSentencesWithAttrValue;
					let getSentencesCustomLogic;

					if (attributeName === ClarabridgeAttributeName.CB_PROFANITY) {
						getSentencesCustomLogic = () => {
							let doc = this.documentManager.documents[documentId];
							return this.profanityDisguiseService.getProfanityInstancesBySentiment(this.predefinedMetrics, doc);
						};
					} else {
						getSentencesWithAttrValue = (val) => {
							let doc = this.documentManager.documents[documentId];
							return {
								value: val,
								sentences: _.chain(doc.sentences)
									.filter((s: any) => s?.attributes?.[attributeName]?.contains(val))
									.map((s: any) => s.id)
									.value()
							};
						};
					}


				// check against any value in the list
					let getSentencesWithAnyAttrValue = (): any => {
						return getSentencesCustomLogic ?
							getSentencesCustomLogic() :
							_.map(sentence.attributes[attributeName], getSentencesWithAttrValue);
					};

					let objectType = !_.isUndefined(availableAttribute) ? availableAttribute.objectType : undefined;
					if (_.isUndefined(documentAttribute)) {
						documentAttributes.push({
							name: attributeName,
							objectType,
							values: getSentencesWithAnyAttrValue(),
							displayName: !_.isUndefined(availableAttribute) ? availableAttribute.displayName : attributeName,
							settings: this.getAttributeSettings(attributeName),
						});
					} else if (attributeName !== ClarabridgeAttributeName.CB_PROFANITY) {
						// profanity processes all sentences at once
						_.map(sentence.attributes[attributeName], (val) => {
							if (!_.find(documentAttribute.values, (valueEntry: any) => valueEntry.value === val))
								documentAttribute.values.push(getSentencesWithAttrValue(val));
						});
					}
				}
			});
		});

		return documentAttributes;
	}

	loadInitialData = (fullReload?: boolean): void => {
		let deferred = this.$q.defer();
		if (this.widgetState) {
			deferred.promise.finally(() => this.widgetState.loading = null);
			this.$q.resolve(this.widgetState.loading).finally(() => this.widgetState.loading = deferred.promise);
			this.widgetState.message = '';
		}
		if (fullReload) {
			this.documentManager.documents = {};
		}
		delete this.documentManager.state.previewDocumentMessage;
		this.sharingFeedbackSelection.resetItems();
		this.documentManager.state.initialLoad = this.validateCP(this.widget)
			.then(() => this.documentManager.runReportPaginated(
				this.documentManager.getWidgetWithFilter(this.widget), fullReload))
			.then(() => this.documentManager.autoSelect(this.widget))
			.then(() => this.setAttributes())
			.catch(reason => {
				this.$log.error('Error in report:', reason);
				this.documentManager.state.message = this.locale.getString('widget.dataError');
				this.documentManager.state.previewDocumentMessage = this.errorMessageProcessing.processGeneralReportError(
					reason, this.widget);
				this.documentManager.state.previewDocumentMessageClass = this.errorMessageProcessing
					.getAdditionalMessageClass(this.documentManager.state.previewDocumentMessage);
			})
			.finally(() => {
				this.widgetError = null;

				if (this.documentManager.isFilteredByConfidentiality()) {
					this.widgetError = WidgetError.PREVIEW_DATA_RESTRICTED;
				}
				deferred.resolve();
				this.documentManager.state.initialLoad = null;
				this.registerRenderedEvent();
			});
	}

	// Making attributes call to make sure CP is accessible (or to correctly populate error)
	private validateCP(widget: Widget): ng.IPromise<void> {
		return this.reportAttributesService.getWidgetAttributes(widget)
			.then(_.noop) as unknown as ng.IPromise<void>;
	}

	private registerRenderedEvent(): void {
		if (!_.isUndefined(this.widget.id)) {
			this.reportUtils.handleWidgetRenderedEvent(this.widget.id, this.widget.name, this.widget.containerId);
			this.$rootScope.$broadcast('widgetRefreshFinishedEvent', this.widget);
		}
	}

	private showInExplorer = (): void => {
		if (!this.dashboardAccessService.canDrillToFeedbackInView(this.dashboard)) {
			return;
		}

		let widgetSettings = angular.copy(this.widget);
		(widgetSettings as any).documentExplorerName = this.locale.getString('widget.docExplorer');
		widgetSettings.parentWidget = this.widget;
		widgetSettings.properties.initialSentence = this.documentManager.selectedSentence;
		if (this.filteringFeedbackSelection?.isEnabled() && this.widget.properties.analyticFeedbackSelection) {
			this.customFilterService.processAnalyticFeedbackSelectionFilters(widgetSettings);
			delete widgetSettings.properties.analyticFeedbackSelection;
		}

		let pages = this.documentManager.getPages();
		let pagesForPagination = new Pagination(pages.itemsPerPage, pages.current);
		pagesForPagination.totalItems = pages.total;
		this.downgradeDialogService.openDocumentExplorerModal({
			widget: widgetSettings,
			dashboard: this.dashboard,
			paging: {
				pages: pagesForPagination
			}
		});
	}
}

app.component('widgetDocumentPreview', {
	bindings: {
		dashboard: '<',
		widget: '<',
		showSentiment: '<',
		showSentences: '<',
		showContext: '<',
		panels: '<',
		filters: '<',
		documentManager: '<',
		initDocumentManager: '<', // change to output on conversion
		preferences: '<',
		docExplorerFavorites: '<?',
		sharingFeedbackSelection: '<',
		filteringFeedbackSelection: '<',
		createDashboardCallback: '<', // change to output on conversion
		isDocExplorer: '<',
		widgetState: '<',
		reloadStatistics: '<', // change to output on conversion
		auditMode: '<',
		rebuttalMode: '<',
		selectedScorecard: '<?',
		showExportData: '<',
		isStatsEnabled: '<',
		showEmptyAttributes: '<'
	},
	controller: WidgetDocumentPreview,
	template: `<document-previewer
		class="border-b-radius-widget-default"
		ng-if="!$ctrl.widgetError"
		widget="$ctrl.widget"
		filters="$ctrl.filters"
		favorites="$ctrl.docExplorerFavorites"
		show-sentiment="$ctrl.showSentiment"
		show-sentences="$ctrl.showSentences"
		show-context="$ctrl.showContext"
		panels="$ctrl.panels"
		available-models="$ctrl.availableModels"
		document-manager="$ctrl.documentManager"
		voice-enabled="$ctrl.voiceEnabled"
		engagor-enabled="$ctrl.engagorEnabled"
		preferences="$ctrl.preferences"
		no-results="$ctrl.hasNoResult()"
		create-dashboard-callback="$ctrl.createDashboardCallback"
		is-doc-explorer="$ctrl.isDocExplorer"
		project-unique-id="$ctrl.projectUniqueId"
		audit-mode="$ctrl.auditMode"
		rebuttal-mode="$ctrl.rebuttalMode"
		predefined-metrics="$ctrl.predefinedMetrics"
		is-stats-enabled="$ctrl.isStatsEnabled"
		show-export-data="$ctrl.showExportData"
		show-empty-attributes="$ctrl.showEmptyAttributes">
	</document-previewer>
	<widget-error ng-if="$ctrl.widgetError" [widget-error]="$ctrl.widgetError"></widget-error>
	`
});
