import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { StandardMetricName } from '../constants/standard-metrics-names';
import { CBSettingsService, IWidgetSettingsScope } from '../services/cb-settings-service.service';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { RealDataPreviewWidget } from './real-data-preview-widget.class';
import HierarchySettingsService from '../services/hierarchy-settings.service';

export class PieDefinitionCtrl extends RealDataPreviewWidget {
	widgetType: WidgetType;

	loaded = false;
	constants;

	// temporarily prevent multi-level single pie
	// either set to true or (ideally) remove related code when multi-level pie is fixed
	readonly isSideBySideRequired = true;

	constructor(
		protected $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected metricConstants: MetricConstants,
		protected realDataPreviewService: RealDataPreviewService,
		protected reportSettingsService: ReportSettingsService,
		protected defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private cbSettingsService: CBSettingsService,
		private sampleDataApiService: SampleDataAPIServiceClass,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private $controller,
		private betaFeaturesService: BetaFeaturesService,
		private hierarchySettingsService: HierarchySettingsService
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		super.$onInit();
		this.widgetType = this.$scope.props.widgetType;

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this.$scope,
			selectionConfiguration: {
				multiAttributeSelection: true
			},
			customCallback: {
				removeGroup: this.removeGroup
			}
		});

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.$scope.staticData.ready = false;

		this.$scope.ui = this.$scope.ui || {};

		this.$scope.listTypes = KeyMetricListTypes;

		this.$scope.sideBySideMode = this.sideBySideMode;
		this.$scope.isSideBySideAvailable = this.isSideBySideAvailable;
		this.$scope.allowThirdGrouping = this.allowThirdGrouping;
		this.$scope.addPieGroup = this.addPieGroup;
		this.$scope.selectMetricInternal = this.selectMetricInternal;

		this.initProps();
		this.initialize();
	}

	initialize = (): void => {
		this.$scope.addLoadingPromise(this.sampleDataApiService.getHierarchyData('bar').then((resp) => {
			let data = resp.data;
			this.$scope.staticData.data = data;
			this.$scope.staticData.data.data = _.filter(
				this.$scope.staticData.data.data, { _pop: ReportPeriods.CURRENT });
			this.$scope.staticData.totalCount = 157;
			this.$scope.visualProps.visualization = WidgetVisualization.STACKEDPIE;

			if (_.isUndefined(this.$scope.visualProps.showLabels)
				|| this.$scope.visualProps.showLabels === null) {
				this.$scope.visualProps.showLabels = false;
			}
			if (isEmpty(this.$scope.visualProps.showSampleSize)) {
				this.$scope.visualProps.showSampleSize = true;
			}

			this.checkPreviewDataAndApplyVisualChanges();
		}));
	}

	initProps = (): void => {
		if (_.isEmpty(this.$scope.props.selectedAttributes)) {
			this.$scope.props.selectedAttributes = [undefined];
		}

		if (!this.$scope.visualProps.attributeSelections) {
			this.$scope.visualProps.attributeSelections = {};
		}
		if (!this.$scope.props.selectedMetrics) {
			this.$scope.props.selectedMetrics = [];
			this.$scope.selectMetricAndRefreshView(this.getAvailableCalculation(StandardMetricName.PARENT_PERCENT));
		}

		if (_.isUndefined(this.$scope.visualProps.sideBySide)) {
			this.$scope.visualProps.sideBySide = false;
		}

		this.$scope.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		}];
		this.$scope.initializePeriods();
	}

	getAvailableCalculation = (name): any => {
		let calculations = this.metricConstants.getStandardCalculations(this.$scope.props.project);
		return _.findWhere(calculations, { name }) || calculations[0];
	}

	reloadSettings = (_event, callback): void => {
		this.initProps();

		let config = { withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING) };

		this.$scope.reloadCommonSettings(config).then((result) => {
			this.$scope.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this.$scope, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.$scope.options.availableAttributes = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(result.models)
				.withAttributes(result.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(result.wordAttributes)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withOrgHierarchyModels(result.models, result.hierarchyModels)
				.withPredefinedGroups(ObjectUtils.copy(result.predefinedMetrics))
				.build();

			this.$scope.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations(this.$scope.props.project))
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withScorecardMetrics(result.scorecardMetrics)
				.filterAvailableOptions(this.$scope.sizeMetricsFilter)
				.build();

			this.$scope.options.predefinedMetrics = ObjectUtils.copy(result.predefinedMetrics);
			this.$scope.options.studioMetrics = _.filter(result.metrics, { projectId: this.$scope.props.project });

			this.$scope.options.cogSortByMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations())
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.build();

			this.cbSettingsService.initDefaultProperties(this.$scope.options.availableAttributes);
			this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, result.hierarchyGrouping,
				result.organizationCalculations, result.hierarchyModels);
			const options = this.filterSelectedAttributesForGrouping(this.$scope.options.availableAttributes);
			this.$scope.options.groupingOptions = [
				options,
				ObjectUtils.copy(options),
				ObjectUtils.copy(options)
			];
			this.$scope.initAttributesSelectionPanel();

			this.$scope.updateCustomDateFilters(result.dateFilters);
			this.checkPreviewDataAndApplyVisualChanges();
			callback(true);
		});
	}

	resetSelections = (): void => {
		this.$scope.props.selectedMetrics = [];
		this.$scope.props.selectedAttributes = [undefined];
		this.selectMetricInternal(this.constants.VOLUME);
	}

	removeGroup = (): void => {
		if (!this.$scope.isSideBySideAvailable()) {
			this.$scope.visualProps.sideBySide = false;
		}
	}

	sideBySideMode = (enable): void => {
		this.$scope.visualProps.sideBySide = enable;
		this.checkPreviewDataAndApplyVisualChanges();
	}

	isSideBySideAvailable = (): boolean => {
		return this.$scope.props.selectedAttributes?.length > 1
			&& !_.isUndefined(this.$scope.props.selectedAttributes[1]);
	}

	allowThirdGrouping = ($index): boolean => {
		return this.isSideBySideRequired ? ($index < 1) : true;
	}

	addPieGroup = (): void => {
		if (this.isSideBySideRequired)
			this.$scope.visualProps.sideBySide = true;
	}

	selectMetricInternal = (node): void => {
		this.$scope.selectMetric(node).then(() => {
			this.checkPreviewDataAndApplyVisualChanges();
		});
	}

	filterSelectedAttributesForGrouping = (items: any[]): any[] => {
		const isHierarchyEnabled = this.$scope.dashboardFilters?.personalization?.isHierarchyEnabled();
		const appliedHierarchyId = this.$scope.dashboardFilters?.personalization?.getHierarchyId();
		return this.hierarchySettingsService.filterSelectedAttributesForGrouping(items, isHierarchyEnabled, appliedHierarchyId);
	}
}
app.controller('CBPieChartCtrl', PieDefinitionCtrl);
