<collapsing-panel
	[disableCollapse]="!hasEmbedPermission()"
	[collapsed]="!hasEmbedPermission() || undefined">
	<panel-heading>
		<strong>{{'widgetSettings.embedConfiguration'|i18n}}</strong>
		<cb-inline-help right>
			<help-body>
				<p>{{'widgetSettings.embedHeaderTooltip'|i18n}}</p>
			</help-body>
		</cb-inline-help>
	</panel-heading>
	<panel-body>
		<div>
			<checkbox-button
				class="_t-embed-checkbox"
				buttonClass="btn-sm"
				label="{{'widgetSettings.embedCheckboxText'|i18n}}"
				(ngModelChange)="initIdentifier()"
				[(ngModel)]="embedConfig.enabled"
			></checkbox-button>
		</div>
		<div class="pl-16">
			<label>{{'widgetSettings.embedCheckboxSubtitle'|i18n}}</label>
		</div>
		<div *ngIf="embedConfig.enabled && showEnterpriseViewersOption()">
			<div>
				<checkbox-button
					class="_t-embed-checkbox"
					buttonClass="btn-sm"
					label="{{'widgetSettings.embedEnterpriseViewersCheckboxText'|i18n}}"
					[(ngModel)]="embedConfig.enterpriseViewersAllowed"
				></checkbox-button>
			</div>
			<div class="pl-16">
				<label>{{'widgetSettings.embedEnterpriseViewersCheckboxSubtitle'|i18n}}</label>
			</div>
		</div>
		<div class="pl-16 mt-16" *ngIf="embedConfig.enabled">
			<label id="embedCopyLabel">{{'widgetSettings.embedCopyText'|i18n}}</label>
			<div class="input-group align-items-stretch">
				<div contenteditable="false"
					type="text"
					class="form-control _t-embed-link"
					aria-labelledby="embedCopyLabel">
					{{ getEmbedWidgetCode() }}
				</div>
				<button
					class="btn btn-secondary q-icon-clipboard"
					type="button"
					ngxClipboard
					[cbContent]="getEmbedWidgetCode()"
					[attr.title]="'common.copyToClipboard' | i18n"
					[amplitudeEvent]="copyEvent"
					[amplitudeEventData]="eventData"
					[amplitudeGroup]="eventGroup">
				</button>
			</div>
		</div>
		<div class="mt-16" *ngIf="embedConfig.enabled">
			<checkbox-button
				class="_t-dashboard-link-checkbox"
				buttonClass="btn-sm"
				label="{{'widgetSettings.embedDashboardLink'|i18n}}"
				[(ngModel)]="embedConfig.showDashboardLink"
			></checkbox-button>
		</div>
	</panel-body>
</collapsing-panel>
