import * as _ from 'underscore';

export class ItemListController implements ng.IController {
	items: any[];
	displayProperty: string;
	onRemove: (params: {$item: any}) => void;

	constructor(

	) {}

	$onInit(): void {
		if (!this.displayProperty)
			this.displayProperty = 'displayName';
	}

	removeItem = (item) => {
		if (this.onRemove)
			this.onRemove({$item: item});
	}
}

app.component('itemList', {
	controller: ItemListController,
	bindings: {
		items: '<',
		displayProperty: '@',
		onRemove: '&',
	},
	template: `
		<div class="d-flex flex-direction-column">
			<div ng-repeat="item in $ctrl.items"
				class="d-flex flex-direction-row justify-between hover-parent p-2">
				<span>{{ item[$ctrl.displayProperty] }}</span>
				<a class="cursor-pointer show-on-parent-hover" ng-click="$ctrl.removeItem(item)">
					<span class="q-icon q-icon-delete text-gray-900"></span>
				</a>
			</div>
		</div>
	`
});
