<modal-header
	[modalTitle]="'common.selectProject' | i18n"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body" [ngBusy]="projectsLoading">
	<p>{{'organization.selectProjectForOH'|i18n}}</p>
	<div class="form-group grid-provider-selectors" *ngIf="!useWorkspaces">
		<project-selector
				(projectSelectionChange)="onProjectSelectionChange($event)"
				(loading)="onProjectsLoading($event)"
				(errorsChange)="onCpErrorsChange($event)">
			</project-selector>
			<alert type="danger" *ngFor="let cperror of contentProviderErrors">
				<span>{{ cperror }}</span>
			</alert>
	</div>

	<workspace-project-selector
		*ngIf="useWorkspaces"
		labelPosition="left"
		[help]="true"
		(workspaceChange)="onWorkspaceChange($event)"
		(valueChange)="onProjectChange($event)"
		(loading)="onProjectsLoading($event)"
	></workspace-project-selector>
</div>

<save-modal-footer
	(cancel)="cancel()"
	(save)="downloadTemplate()"
	[saveText]="'organization.downloadTemplate' | i18n"
	[cancelText]="'organization.cancel' | i18n"
	[isDisabled]="!downloadEnabled()">
</save-modal-footer>

