import { Pipe, PipeTransform } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Pipe({
	name: 'truncateNamesList'
})
export class TruncateNamesListPipe implements PipeTransform {

	constructor(private locale: CxLocaleService) {
	}

	transform(names: string[], numberToDisplay: number): string {

		let namesList: string = _.chain(names)
			.first(numberToDisplay)
			.join(', ').value();

		let numberOfOthers: number = names.length - numberToDisplay;

		if (numberOfOthers <= 0) {
			return namesList;
		}

		return this.locale.getString('administration.truncateNamesListAddOthers', 
			{
				namesList,
				numberOfOthers
			});
	}

}
