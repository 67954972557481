import * as _ from 'underscore';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';

export class MetricFilters {

	static CALCULATION = (attribute) => {
		return attribute.type === FilterAttributeTypes.NUMBER;
	}

	static DATE = (attribute) => {
		return attribute.type === FilterAttributeTypes.DATE;
	}

	static GROUP_FILTER = (attribute) => {
		return attribute.type !== FilterAttributeTypes.SAT;
	}

	static NOT_DATE = (attribute) => {
		return attribute.type !== FilterAttributeTypes.DATE;
	}

	static NOT_DOCUMENT_DATE = (attribute) => {
		return attribute.name !== ClarabridgeMetricName.DOCUMENT_DATE && attribute.name !== ClarabridgeMetricName.SERVER_TIME;
	}

	static objectTypeFilter = (type: AttributeObjectType, excludeDate?: boolean) => {
		return (attribute) => {
			return attribute.objectType === type && (!excludeDate || MetricFilters.NOT_DATE(attribute));
		};
	}

	static DRIVER_ATTRIBUTES = (attribute) => {
		return attribute.name !== ClarabridgeAttributeName.CB_SENTENCE_WORD_COUNT &&
			attribute.name !== 'NATURAL_ID' &&
			MetricFilters.NOT_DATE(attribute);
	}

	static SEARCH_FILTER = (attribute): boolean => {
		return attribute.type !== FilterAttributeTypes.SAT;
	}

}


