import * as _ from 'underscore';
import { DualAxisTypes } from './dual-axis-types';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { IWidgetSettingsConfig } from '../services/widget-settings.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { CBSettingsService, IWidgetSettingsScope } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { TrendBy } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import { BubbleConfigurationService } from '@app/modules/widget-settings/bubble-configuration.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { VisualizationOptionsService } from '@cxstudio/reports/settings/visualization-options.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { SelectionPlaceholders } from '@app/modules/unified-templates/dashboard-templates/selection-placeholders.class';
import { IPropsWithGrouping, SelectedReportGroupings } from '@app/modules/widget-settings/services/selected-report-groupings';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { RealDataPreviewWidget, ISpecialGrouping } from './real-data-preview-widget.class';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
import HierarchySettingsService from '../services/hierarchy-settings.service';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';

export class AnalyticDualController extends RealDataPreviewWidget {
	widgetType: WidgetType;

	private loaded: boolean;
	protected periodOverPeriodItem: ISpecialGrouping;
	private calculationSeriesItem: ISpecialGrouping;
	private selectedReportGroupings;
	private metricProps: string[];
	private attributeProps: string[];
	private ignoredAttributes: string[];
	private filteredSelectedAttributes: any[];
	private filteredExtendedAttributes: any[];
	private filteredStackedAttributes: any[];

	constructor(
		protected $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected metricConstants: MetricConstants,
		protected realDataPreviewService: RealDataPreviewService,
		protected reportSettingsService: ReportSettingsService,
		protected defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected colorUtils: ColorUtils,
		private $controller: any,
		private locale: ILocale,
		private cbSettingsService: CBSettingsService,
		private visualizationOptionsService: VisualizationOptionsService,
		private sampleDataApiService: SampleDataAPIServiceClass,
		private highchartsUtils: HighchartsUtilsService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private bubbleConfigurationService: BubbleConfigurationService,
		private groupColorService: GroupColorService,
		private groupIdentifierHelper,
		private $q: ng.IQService,
		private betaFeaturesService: BetaFeaturesService,
		private hierarchySettingsService: HierarchySettingsService

	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		super.$onInit();
		this.widgetType = this.$scope.props.widgetType;
		this.$controller('AnalyticDefinitionCommonCtrl', { $scope: this.$scope });

		this.loaded = false;

		this.periodOverPeriodItem = {
			name: '_pop',
			hidden: true,
			displayName: this.locale.getString('widget.period_over_period')
		};

		this.calculationSeriesItem = {
			name: '_calculation_series',
			hidden: true,
			displayName: this.locale.getString('widget.calculationSeries')
		};

		this.initialize();
		this.selectedReportGroupings = new SelectedReportGroupings(this.$scope.props as IPropsWithGrouping);

		// first 2 should be Y metrics for demo data adjustments
		// this.metricProps = ['yAxis', 'secondaryYAxis', 'size', 'color', 'secondaryColor',
		// 	'secondaryPointColor', 'sortBy', 'secondarySize'];
		// this.attributeProps = ['primaryGroup', 'secondaryGroup', 'stackedGroup'];
		this.ignoredAttributes = [this.periodOverPeriodItem.name, this.calculationSeriesItem.name];

		this.$scope.changePop = this.changePop;
		this.$scope.openAdvancedOptions = this.openAdvancedOptions;
		this.$scope.showStackedSortBy = this.showStackedSortBy;
		this.$scope.getStackedDisplayName = this.getStackedDisplayName;
		this.$scope.removeMetric = this.removeMetric;
		this.$scope.reorderCalculations = this.reorderCalculations;
		this.$scope.isReorderableCalculation = this.isReorderableCalculation;
		this.$scope.isReorderableYAxis = this.isReorderableYAxis;
		this.$scope.isReorderableGroups = this.isReorderableGroups;
		this.$scope.swapGroups = this.swapGroups;
		this.$scope.showRemoveGroup = this.showRemoveGroup;
		this.$scope.clearGroup = this.clearGroup;
		this.$scope.changeColor = this.changeColor;
		this.$scope.processColorChange = this.processColorChange;
		this.$scope.showLegendButton = this.showLegendButton;
		this.$scope.showNormalizeGroupsButton = this.showNormalizeGroupsButton;
		this.$scope.isForceNormalization = this.isForceNormalization;
		this.$scope.showClusterButton = this.showClusterButton;
		this.$scope.showTotalCount = this.showTotalCount;
		this.$scope.isPoP = this.isPoP;
		this.$scope.isAnySelectionPoP = this.isAnySelectionPoP;
		this.$scope.changePrimaryChartType = this.changePrimaryChartType;
		this.$scope.changeSecondaryChartType = this.changeSecondaryChartType;
		this.$scope.addSecondaryChartType = this.addSecondaryChartType;
		this.$scope.removeSecondaryChartType = this.removeSecondaryChartType;
		this.$scope.onWidgetMetricConfigured = this.onWidgetMetricConfigured;
		this.$scope.updateCalculationSeries = this.updateCalculationSeries;
		this.$scope.showCalculationSeries = this.showCalculationSeries;
		this.$scope.showCalculationSeriesPlusSign = this.showCalculationSeriesPlusSign;
		this.$scope.configureBubble = this.configureBubble;
		this.$scope.setGroupProperty = this.setGroupProperty;
		this.$scope.setCalculationProperty = this.setCalculationPropertyInternal;
		this.$scope.setStacking = this.setStacking;
		this.$scope.isSecondaryAxisDisabled = this.isSecondaryAxisDisabled;
		this.$scope.filterSelectedAttributesForGrouping = this.filterSelectedAttributesForGrouping;
		this.$scope.applyVisualChangesInternal = this.applyVisualChangesInternal;

		this.initProps();
	}

	private initialize = () => {
		this.$scope.ui = this.$scope.ui || {};
		this.$scope.ui.listTypes = KeyMetricListTypes;
		this.$scope.ui.popSelectionEnabled = true;
		let betaVisualizations = [];

		if (this.$scope.props.widgetType === WidgetType.BAR) {
			const barSubtypes = [ChartType.COLUMN, ChartType.BAR, ChartType.PARETO];
			if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.BAR_LINE_SUBTYPES)) {
				betaVisualizations = [ChartType.POLAR];
			}

			this.$scope.ui.dualVisualizations = this.visualizationOptionsService.buildDualVisualizations(
				barSubtypes.concat(betaVisualizations));
			if (this.$scope.visualProps.subChartType === ChartType.PARETO) {
				this.$scope.secondaryVisualizations = [];
			} else if (this.$scope.visualProps.subChartType === ChartType.BAR) {
				this.$scope.secondaryVisualizations = this.visualizationOptionsService.buildDualVisualizations(
					[ChartType.BAR, ChartType.SPLINE, ChartType.BUBBLE]);
			} else {
				this.$scope.secondaryVisualizations = this.visualizationOptionsService.buildDualVisualizations(
					[ChartType.COLUMN, ChartType.SPLINE, ChartType.BUBBLE]);
			}
		} else {
			const lineSubtypes = [ChartType.SPLINE, ChartType.BUBBLE];
			if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.BAR_LINE_SUBTYPES)) {
				betaVisualizations = [ChartType.RADAR];
			}

			this.$scope.ui.dualVisualizations = this.visualizationOptionsService.buildDualVisualizations(
				lineSubtypes.concat(betaVisualizations));
			this.$scope.secondaryVisualizations = this.visualizationOptionsService.buildDualVisualizations(
				[ChartType.COLUMN, ChartType.SPLINE, ChartType.BUBBLE]);
		}

		this.$scope.ui.secondaryGroupVisible = !!this.$scope.visualProps.secondaryGroup;

		let sampleDataPromise = this.sampleDataApiService.getHierarchyData(
			this.$scope.props.widgetType === WidgetType.BAR ? 'bar' : undefined);

		this.$scope.addLoadingPromise(sampleDataPromise.then((resp) => {
			let data = resp.data;

			this.$scope.staticData.data = data;
			this.$scope.staticData.ui = {};
			this.$scope.staticData.totalCount = 157;
			if (!this.$scope.visualProps.visualization && this.$scope.props.widgetType === WidgetType.BAR) {
				this.$scope.visualProps.visualization = WidgetVisualization.DUAL;
				this.$scope.visualProps.subChartType = ChartType.COLUMN;
			} else if (!this.$scope.visualProps.visualization) {
				this.$scope.visualProps.visualization = WidgetVisualization.DUAL;
				this.$scope.visualProps.subChartType = ChartType.SPLINE;
			}
			if (_.isUndefined(this.$scope.visualProps.points)) {
				this.$scope.visualProps.points = true;
			}
			if (!this.$scope.visualProps.pointColor) {
				this.$scope.visualProps.pointColor = ColorPaletteNames.INHERIT;
			}

			if (!this.$scope.visualProps.secondaryPointColor)
				this.$scope.visualProps.secondaryPointColor = ColorPaletteNames.INHERIT;

			if (!this.$scope.visualProps.popColor)
				this.$scope.visualProps.popColor = ColorPaletteNames.LIGHTEN;

			if (!this.$scope.visualProps.secondaryPopColor)
				this.$scope.visualProps.secondaryPopColor = ColorPaletteNames.LIGHTEN;

			// if legend is not already explicitly turned off or no, turn it on unless it is a pre-existing widget with two defined groupings
			if ((_.isUndefined(this.$scope.visualProps.showLegend) || this.$scope.visualProps.showLegend === null))
				this.$scope.visualProps.showLegend = !this.$scope.visualProps.secondaryGroup;

			if (this.$scope.widget.created)
				this.$scope.visualProps.cluster = true;

			if (_.isUndefined(this.$scope.visualProps.showLabels) || this.$scope.visualProps.showLabels === null) {
				this.$scope.visualProps.showLabels = false;
			}
			if (isEmpty(this.$scope.visualProps.showSampleSize)) {
				this.$scope.visualProps.showSampleSize = true;
			}
			if (_.isUndefined(this.$scope.visualProps.secondaryPoints)) {
				this.$scope.visualProps.secondaryPoints = false;
			}

			if (!this.$scope.visualProps.attributeSelections) {
				this.$scope.visualProps.attributeSelections = {};
			}
			if (!this.$scope.visualProps.yAxis) {
				this.setPropertyInternal('yAxis', this.constants.VOLUME);
			}
			if (!this.$scope.visualProps.size) {
				this.setPropertyInternal('size', this.constants.CONSTANT_SIZE);
			}
			if (!this.$scope.visualProps.secondarySize) {
				this.setPropertyInternal('secondarySize', this.constants.CONSTANT_SIZE);
			}
			if (!this.$scope.visualProps.calculationSeries) {
				this.$scope.visualProps.calculationSeries = [];
			}

			// initialize metric that we need just for coloring purposes
			// initialization is necessary in dual, because there are multiple color options (line, point, pop, secondary, etc...)
			let metricsForColoring = this.getColoringMetrics(this.$scope.visualProps, this.$scope.options);

			if (metricsForColoring.length)
				this.processAttributesAndMetrics();

			this.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY);
		}, _.noop));

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.$scope.isBar = () => {
			return this.$scope.props?.widgetType === WidgetType.BAR;
		};

		this.$scope.isPareto = () => DualAxisTypes.isParetoVisualization(this.$scope.visualProps);

		this.$scope.isCalculationSeries = this.isCalculationSeries;

		this.$scope.showGroupCog = this.showGroupCog;
	}

	private initProps = () => {
		if (!this.$scope.props.selectedAttributes) {
			this.$scope.props.selectedAttributes = [];
		}
		if (!this.$scope.props.selectedMetrics) {
			this.$scope.props.selectedMetrics = [this.constants.VOLUME]; // volumen selected
		}

		if (!this.$scope.visualProps.periodLabel) {
			this.$scope.visualProps.periodLabel = {};
		}
		if (!this.$scope.visualProps.sortBy) this.$scope.visualProps.sortBy = StandardMetricName.VOLUME;
		if (!this.$scope.visualProps.direction) this.$scope.visualProps.direction = SortDirection.DESC;

		this.$scope.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		}, {
			field: DatePeriodField.PERIOD2,
			name: DatePeriodName.PERIOD2
		}];

		this.$scope.ui.periodOptions = {};
		this.$scope.ui.periodOptions.historic = {};
		if (this.$scope.props.primaryTimeGrouping) {
			this.$scope.setPrimaryTimeGrouping(this.$scope.props.primaryTimeGrouping);
		}
		this.$scope.initializePeriods();
	}

	reloadSettings = (event, callback) => {
		this.initProps();

		let config: IWidgetSettingsConfig = {
			withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
		};

		this.$scope.reloadCommonSettings(config).then(result => {
			this.$scope.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this.$scope, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.$scope.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
			this.$scope.options.studioMetrics = _.filter(result.metrics as Array<{ projectId: number }>,
				metric => metric.projectId === this.$scope.props.project);

			this.$scope.options.primaryGroupingOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(result.models)
				.withTime(this.$scope.options.attributes)
				.withAttributes(this.$scope.options.attributes, MetricFilters.GROUP_FILTER)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withOrgHierarchyModels(result.models, result.hierarchyModels)
				.withPredefinedGroups(angular.copy(result.predefinedMetrics))
				.withWordAttributes(result.wordAttributes)
				.build();
			this.toggleTimeGroupingVisibility(!DualAxisTypes.isParetoVisualization(this.$scope.visualProps));

			this.cbSettingsService.initDefaultProperties(this.$scope.options.primaryGroupingOptions);
			this.$scope.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations(this.$scope.props.project))
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withScorecardMetrics(result.scorecardMetrics)
				.build();
			this.$scope.options.additionalMetrics.push({ ...this.constants.CUMULATIVE_PERCENT_TOTAL, hide: true });
			this.$scope.options.sortMetrics = _.clone(this.$scope.options.additionalMetrics);
			this.$scope.options.sortMetrics.push(this.constants.ALPHANUMERIC);
			this.$scope.options.cogSortByMetrics = angular.copy(this.$scope.options.additionalMetrics);

			this.$scope.options.sizeMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics([this.constants.VOLUME, this.constants.CONSTANT_SIZE])
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.filterAvailableOptions(this.$scope.sizeMetricsFilter)
				.build();
			this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, result.hierarchyGrouping,
				result.organizationCalculations, result.hierarchyModels);
			this.$scope.options.calculationSeries = angular.copy(this.$scope.options.additionalMetrics);
			this.$scope.processSelections();

			this.$scope.options.extendedAttributes = [this.periodOverPeriodItem, this.calculationSeriesItem];
			this.$scope.options.extendedAttributes.pushAll(angular.copy(this.$scope.options.primaryGroupingOptions).filter((group) => {
				return group.name !== OptionsConstant.TIME;
			}));

			if (this.$scope.props.useHistoricPeriod) {
				this.periodOverPeriodItem.hidden = false;
				this.$scope.ui.periodOptions.hideMissDate = true;

				if (this.$scope.visualProps.secondaryGroup === this.periodOverPeriodItem.name) {
					this.$scope.visualProps.attributeSelections.secondaryGroup = this.periodOverPeriodItem;
					this.periodOverPeriodItem.hidden = true;
				}
				if (this.$scope.visualProps.stackedGroup === this.periodOverPeriodItem.name) {
					this.$scope.visualProps.attributeSelections.stackedGroup = this.periodOverPeriodItem;
					this.periodOverPeriodItem.hidden = true;
				}
			} else {
				this.$scope.ui.periodOptions.hideMissDate = false;
			}

			this.$scope.updateCustomDateFilters(result.dateFilters);
			this.calculationSeriesItem.hidden = !(this.isLineChart() || DualAxisTypes.isBubbleChart(this.$scope.props, this.$scope.visualProps));

			this.initGroupingSelection();

			this.$scope.filteredSelectedAttributes = this.filterSelectedAttributesForGrouping(this.$scope?.options?.primaryGroupingOptions);
			this.$scope.filteredExtendedAttributes = this.filterSelectedAttributesForGrouping(this.$scope?.options?.extendedAttributes);
			this.$scope.options.stackedAttributes = angular.copy(this.$scope.filteredExtendedAttributes);
			this.$scope.options.stackedAttributes[0] = this.periodOverPeriodItem;
			this.$scope.options.stackedAttributes[1] = this.calculationSeriesItem;

			this.initDefaultGroupingSelections().then(() => {
				this.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY);
				callback(true);

				this.realDataPreviewService.setPrevWidgetProps(this.$scope.props);
				this.realDataPreviewService.triggerReportRun();
			});
		});
	}

	private initDefaultGroupingSelections = (): ng.IPromise<void> => {
		if (this.isLineChart() && !this.reportSettingsService.hasPrimaryGrouping(this.$scope.visualProps)) {
			let documentDateMonthGrouping = HierarchyUtils.findItem(this.$scope.options.primaryGroupingOptions, [
				{ name: OptionsConstant.TIME },
				{ name: '_doc_date' },
				{ trendBy: TrendBy.MONTH }
			]);
			if (documentDateMonthGrouping) {
				return this.$scope.setGroupProperty(
					SelectionPlaceholders.values.groupings.PRIMARY_GROUP, documentDateMonthGrouping, undefined, PreviewChartRefreshType.UI_ONLY);
			}
		}
		return this.$q.when();
	}


	private isLineChart = (): boolean => {
		return DualAxisTypes.isLineChart(this.$scope.props, this.$scope.visualProps);
	}

	changePop = (value: boolean) => {
		this.$scope.props.useHistoricPeriod = value;
		let refreshType: PreviewChartRefreshType = value
			? PreviewChartRefreshType.DATA_REQUEST
			: PreviewChartRefreshType.UI_ONLY;
		if (value) {
			this.periodOverPeriodItem.hidden = false;
			this.$scope.setGroupProperty('secondaryGroup', this.periodOverPeriodItem, undefined, refreshType).then(() => {
				this.$scope.ui.secondaryGroupVisible = true;
				this.$scope.ui.periodOptions.hideMissDate = true;
				this.$scope.visualProps.cluster = true;
			});
		} else {
			let promise;
			if (this.$scope.visualProps.stackedGroup === this.periodOverPeriodItem.name) {
				promise = this.$scope.setGroupProperty('stackedGroup', this.constants.NONE, undefined, refreshType);
			} else if (this.$scope.visualProps.secondaryGroup === this.periodOverPeriodItem.name) {
				promise = this.$scope.setGroupProperty('secondaryGroup', this.constants.NONE, undefined, refreshType)
					.then(() => this.$scope.ui.secondaryGroupVisible = false);
			} else {
				if (this.$scope.showRealDataPreview()) {
					// redraw chart on pop remove even if it's not used as secondary/stacked grouping
					this.$scope.updateChartSettings();
				}
				promise = this.$q.when();
			}
			promise.then(() => {
				this.periodOverPeriodItem.hidden = true;
				this.$scope.ui.periodOptions.hideMissDate = false;
			});
		}
	}

	changePrimaryChartType = (subChartType: ChartType) => {
		this.$scope.visualProps.visualization = WidgetVisualization.DUAL;
		this.$scope.staticData.ready = false;
		let promise: ng.IPromise<any> = this.$q.when();
		let previousSubChartType = this.$scope.visualProps.subChartType;
		if (subChartType && subChartType !== previousSubChartType) {
			this.$scope.visualProps.subChartType = subChartType;
			if (this.$scope.props.widgetType === WidgetType.BAR) {
				if (subChartType === ChartType.PARETO) {
					this.adjustForPareto();
					let cleanups = [
						this.$scope.clearGroup('secondaryGroup'),
						this.$scope.clearGroup('stackedGroup')
					];
					if (this.isPrimaryGroupingTime()) {
						cleanups.push(this.$scope.clearGroup('primaryGroup'));
					}
					promise = this.$q.all(cleanups);
				} else if ([ChartType.POLAR, ChartType.RADAR].includes(subChartType)) {
					this.$scope.removeSecondaryChartType(PreviewChartRefreshType.DATA_REQUEST);
					let cleanups = [ this.$scope.clearGroup('secondaryGroup')];
					promise = this.$q.all(cleanups);
				} else {
					if (previousSubChartType === ChartType.PARETO) {
						this.toggleTimeGroupingVisibility(true);
						this.$scope.removeSecondaryChartType(PreviewChartRefreshType.DATA_REQUEST);
					}
					if (subChartType === ChartType.BAR) {
						if (this.$scope.visualProps.secondaryChartType === ChartType.COLUMN) {
							this.$scope.visualProps.secondaryChartType = ChartType.BAR;
						}
						this.$scope.secondaryVisualizations = this.visualizationOptionsService.buildDualVisualizations(
							[ChartType.BAR, ChartType.SPLINE, ChartType.BUBBLE]);
					} else {
						if (this.$scope.visualProps.secondaryChartType === ChartType.BAR) {
							this.$scope.visualProps.secondaryChartType = ChartType.COLUMN;
						}
						this.$scope.secondaryVisualizations = this.visualizationOptionsService.buildDualVisualizations(
							[ChartType.COLUMN, ChartType.SPLINE, ChartType.BUBBLE]);
					}
				}
			} else {

				this.calculationSeriesItem.hidden = !(this.isLineChart() || DualAxisTypes.isBubbleChart(this.$scope.props, this.$scope.visualProps));
				if (this.isCalculationSeries() && this.calculationSeriesItem.hidden) {
					promise = this.$scope.clearGroup('secondaryGroup');
				}

				if (this.$scope.visualProps.subChartType === ChartType.SPLINE
					&& ColorUtilsHelper.isObjectBasedColor(this.$scope.visualProps, ColorTypes.PRIMARY)) {
					this.$scope.visualProps.color = null;
				}

				// if we're changing to a bubble, we dont care about point color anymore
				if (DualAxisTypes.isBubbleVisualization(this.$scope.visualProps)) {
					delete this.$scope.visualProps.pointColor;
				}
			}


		}
		promise.then(() => this.applyVisualChangesInternal(PreviewChartRefreshType.DATA_REQUEST));
	}

	private adjustForPareto = (): void => {
		if (this.$scope.visualProps.primaryGroup) {
			let primaryGroup = this.$scope.visualProps.attributeSelections.primaryGroup;
			primaryGroup.sortBy = StandardMetricName.VOLUME;
			primaryGroup.sortOrder = GroupingSortOrder.DESC;
		}
		this.$scope.visualProps.secondaryChartType = ChartType.SPLINE;
		this.$scope.secondaryVisualizations = [];
		this.setPropertyInternal('secondaryYAxis', this.constants.CUMULATIVE_PERCENT_TOTAL);
		this.$scope.visualProps.secondaryAxisAutoMin = false;
		this.$scope.visualProps.secondaryAxisMin = '0';
		this.$scope.visualProps.secondaryAxisAutoMax = false;
		this.$scope.visualProps.secondaryAxisMax = '1';
		this.$scope.visualProps.secondaryPoints = true;
		this.toggleTimeGroupingVisibility(false);
		this.setPropertyInternal('sortBy', this.constants.VOLUME);
		this.$scope.visualProps.direction = SortDirection.DESC;
		this.$scope.ui.secondaryGroupVisible = false;
		if (this.$scope.props.useHistoricPeriod) {
			this.changePop(false);
		}
	}

	private toggleTimeGroupingVisibility = (visible: boolean): void => {
		let timeGroup: any = _.findWhere(this.$scope.options.primaryGroupingOptions, { name: OptionsConstant.TIME });
		if (timeGroup) {
			timeGroup.hide = !visible;
		}
	}

	changeSecondaryChartType = (secondaryChartType): void => {
		if (secondaryChartType === ChartType.COLUMN || secondaryChartType === ChartType.BAR) {
			ColorUtilsHelper.convertSolidToCustom(this.$scope.visualProps, ColorTypes.SECONDARY);
		} else if (secondaryChartType === ChartType.SPLINE || secondaryChartType === ChartType.BUBBLE) {
			this.$scope.visualProps.secondaryColor = null;
		}
		this.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY);
	}

	addSecondaryChartType = (secondaryChartType): void => {
		this.setPropertyInternal('secondaryYAxis', this.constants.VOLUME);
		this.setPropertyInternal('secondarySize', this.constants.CONSTANT_SIZE);
		this.$scope.visualProps.secondaryChartType = secondaryChartType;
		this.processAttributesAndMetrics();
		this.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY);
	}

	removeSecondaryChartType = (refreshType: PreviewChartRefreshType): void => {
		delete this.$scope.visualProps.secondaryAxisAutoMin;
		delete this.$scope.visualProps.secondaryAxisMin;
		delete this.$scope.visualProps.secondaryAxisAutoMax;
		delete this.$scope.visualProps.secondaryAxisMax;
		delete this.$scope.visualProps.secondaryYAxis;
		delete this.$scope.visualProps.secondarySize;
		delete this.$scope.visualProps.alignTicks;
		delete this.$scope.visualProps.attributeSelections.secondaryYAxis;
		delete this.$scope.visualProps.attributeSelections.secondarySize;
		this.$scope.visualProps.secondaryChartType = undefined;
		this.processAttributesAndMetrics();
		this.applyVisualChangesInternal(refreshType);
	}

	onWidgetMetricConfigured = (item, listType, configurationOptions) => {
		this.processAttributesAndMetrics();
		this.applyVisualChangesInternal(PreviewChartRefreshType.DATA_REQUEST);
	}

	updateCalculationSeries = (selectedItems) => {
		if (selectedItems) {
			this.$scope.visualProps.calculationSeries = selectedItems;
		} else {
			this.$scope.visualProps.calculationSeries = [undefined];
		}

		let promise = this.$q.when();
		if (this.$scope.visualProps.calculationSeries.length) {
			if (!this.hasSecondaryGroup()) {
				promise = this.$scope.setGroupProperty('secondaryGroup', this.calculationSeriesItem);
			} else {
				this.processAttributesAndMetrics();
			}
		} else {
			promise = this.$scope.clearGroup('secondaryGroup');
		}

		promise.then(() => this.applyVisualChangesInternal());
	}

	showCalculationSeries = () => {
		return (this.isLineChart() || DualAxisTypes.isBubbleChart(this.$scope.props, this.$scope.visualProps))
			&& this.isCalculationSeries()
			&& !!this.$scope.visualProps.calculationSeries?.length;
	}

	showCalculationSeriesPlusSign = () => {
		return (this.isLineChart() || DualAxisTypes.isBubbleChart(this.$scope.props, this.$scope.visualProps))
			&& (this.isCalculationSeries() || !this.hasSecondaryGroup())
			&& !this.$scope.visualProps.calculationSeries?.length;
	}

	configureBubble = (isSecondary) => {
		let map = {
			scale: 'scale'
		};

		if (isSecondary) {
			for (let key in map) {
				if (map.hasOwnProperty(key)) {
					map[key] = 'secondary' + map[key].capitalize();
				}
			}
		}

		this.bubbleConfigurationService.configure(map, this.$scope.visualProps).then((result) => {
			$.extend(this.$scope.visualProps, result);
			this.applyVisualChangesInternal();
		});
	}

	processAttributesAndMetrics = () => {

		this.$scope.visualProps.attributeSelections = this.$scope.visualProps.attributeSelections || {};

		if (!this.isCalculationSeries()) {
			this.$scope.visualProps.calculationSeries = [];
		} else {
			this.$scope.visualProps.calculationSeries = this.$scope.visualProps.calculationSeries || [];
		}

		this.$scope.props.selectedMetrics = this.getAllRequiredMetrics(this.$scope.visualProps, this.$scope.options);
		this.$scope.props.selectedAttributes =
			this.getAllRequiredAttributes(this.$scope.visualProps, this.$scope.props.selectedAttributes, this.ignoredAttributes);

		this.selectedReportGroupings.populateTopicFields();
		this.groupIdentifierHelper.populateIdentifier(this.$scope.props.selectedAttributes);

		this.$scope.ui.popSelectionEnabled = true;

		// make sure periodOptions is initialized if it has not already been
		this.$scope.ui.periodOptions = this.$scope.ui.periodOptions || { historic: {} };
		this.$scope.ui.periodOptions.historic.filter = undefined;

		this.$scope.props.primaryTimeGrouping = undefined;
		if (this.isPrimaryGroupingTime()) {
			this.$scope.setPrimaryTimeGrouping(this.$scope.visualProps.attributeSelections.primaryGroup);
		} else {
			delete this.$scope.visualProps.caseVisualizations;
		}
		if (!this.showNormalizeGroupsButton()) {
			this.$scope.props.normalizeGroups = undefined;
		} else if (this.isForceNormalization()) {
			this.$scope.props.normalizeGroups = this.getForcedNormalizeGroupsValue();
		} else if (this.$scope.props.normalizeGroups == null) {
			this.$scope.props.normalizeGroups = this.isPrimaryGroupingTime();
		}

		this.$scope.checkAndRemoveCalculations();
	}

	isPrimaryGroupingTime = (): boolean => {
		if (this.$scope.visualProps.primaryGroup) {
			let primaryGroup = this.$scope.visualProps.attributeSelections.primaryGroup;
			return AnalyticMetricTypes.isTime(primaryGroup);
		}
		return false;
	}

	postSetPropertyInternal = (refreshType: PreviewChartRefreshType): void => {
		this.processAttributesAndMetrics();

		this.applyVisualChangesInternal(refreshType);

		this.$scope.processSelections();
	}

	setGroupProperty = (
		name, node, previous?, refreshType: PreviewChartRefreshType = PreviewChartRefreshType.DATA_REQUEST
	): ng.IPromise<void> => {
		previous = previous || this.$scope.visualProps.attributeSelections[name];
		if (previous?.name === this.periodOverPeriodItem.name) {
			this.periodOverPeriodItem.hidden = false;
		}
		if (node.name === this.periodOverPeriodItem.name) {
			this.periodOverPeriodItem.hidden = true;
		}
		let groupingDefaultsPromise = PromiseUtils.old(this.reportSettingsService.populateGroupingProjectDefaults(this.$scope.props, node))
			.then(enrichedNode => this.updateGroupProperty(name, enrichedNode, previous, refreshType));

		this.$scope.addLoadingPromise(groupingDefaultsPromise);

		return groupingDefaultsPromise;
	}

	private moveGroupProperty = (name, node) => {
		let previous = this.$scope.visualProps.attributeSelections[name];
		this.updateGroupProperty(name, node, previous, PreviewChartRefreshType.DATA_REQUEST);
	}

	private updateGroupProperty = (name, node, previous?, refreshType?: PreviewChartRefreshType) => {
		this.processGroupingSelection(name, node, previous);
		this.postSetProperty(name, node);
		this.postSetPropertyInternal(refreshType);
	}

	// TODO:
	setCalculationPropertyInternal = (name, node): ng.IPromise<void> => {
		return this.setCalculationProperty(name, node).then((extendedNode) => {
			this.postSetPropertyInternal(this.getRefreshTypeForCalculationUpdate(extendedNode));
		});
	}

	setStacking = (node): ng.IPromise<void> => {
		return this.$scope.setGroupProperty('stackedGroup', node).then(() => {
			if (this.$scope.showStackedSortBy()) {
				if (!this.$scope.visualProps.stackedDirection)
					this.$scope.visualProps.stackedDirection = SortDirection.DESC;
			} else {
				this.$scope.visualProps.stackedDirection = '';
			}
		});
	}

	initGroupingSelection = () => {
		_.forEach(['stackedGroup', 'secondaryGroup', 'primaryGroup'], (groupingName) => {
			this.processGroupingSelection(groupingName, this.$scope.visualProps.attributeSelections[groupingName], undefined, true);
		});
	}

	processGroupingSelection = (name, node, previousNode, init?): void => {
		if (!this.$scope.options || !node) {
			return;
		}

		if (DualAxisTypes.isParetoVisualization(this.$scope.visualProps)) {
			node.sortOrder = GroupingSortOrder.DESC;
		}

		let groups;
		if (name === 'primaryGroup') {
			groups = _.union(this.$scope.options.extendedAttributes,
				this.$scope.options.stackedAttributes);
			if (this.$scope.visualProps.timeReferenceLines
				&& node.metricType !== AnalyticMetricType.TIME) {
				this.$scope.visualProps.timeReferenceLines = null;
			}
		} else if (name === 'secondaryGroup') {
			groups = _.union(this.$scope.options.primaryGroupingOptions,
				this.$scope.options.stackedAttributes);
		} else if (name === 'stackedGroup') {
			groups = _.union(this.$scope.options.primaryGroupingOptions,
				this.$scope.options.extendedAttributes);
		}

		if (groups) {
			AnalyticsDefinitionUtils.toggleAllowedSelections(
				groups, node, previousNode);
		}

		if (!init && groups) {
			this.adjustColorByGroup(previousNode, node);
		}

		this.$scope.processDynamicCalculations(node);
	}

	adjustColorByGroup = (previousNode, currentNode) => {
		if (this.groupColorService.isGroupColorSupported(previousNode)) {

			ColorUtilsHelper.getColorFields().forEach((color) => {
				if (this.groupColorService.isGroupColor(this.$scope.visualProps[color])) {
					this.$scope.visualProps[color] = null;
				}
			});

			ColorUtilsHelper.getPointColorFields().forEach((color) => {
				if (this.groupColorService.isGroupColor(this.$scope.visualProps[color])) {
					this.$scope.visualProps[color] = ColorPaletteNames.INHERIT;
				}
			});
		}

		if (this.groupColorService.isGroupColorSupported(currentNode)) {
			let groupColor = this.groupColorService.GROUP_COLOR_PREFIX + currentNode.name;
			if (!this.isLineChart()) {
				this.$scope.visualProps.color = groupColor;
			} else {
				this.$scope.visualProps.pointColor = groupColor;
			}

			if (this.$scope.visualProps.secondaryChartType) {
				if (!DualAxisTypes.isSecondaryLineChart(this.$scope.visualProps)) {
					this.$scope.visualProps.secondaryColor = groupColor;
				} else {
					this.$scope.visualProps.secondaryPointColor = groupColor;
				}
			}
		}
	}

	clearGroup = (groupName): ng.IPromise<void> => {
		return this.$scope.setGroupProperty(groupName, this.constants.NONE);
	}

	changeColor = (dataRequestNeeded: boolean) => {
		if (this.$scope.visualProps.attributeSelections) {
			delete this.$scope.visualProps.attributeSelections.color;
		}
		if (ColorUtilsHelper.isSentimentColorPresent(ReportConstants.colorFields, this.$scope.visualProps)) {
			this.$scope.visualProps.attributeSelections.color = this.constants.SENTIMENT;
		} 
		
		this.processAttributesAndMetrics();

		if (!dataRequestNeeded) {
			this.$scope.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY);
		} else {
			this.$scope.applyVisualChangesInternal(PreviewChartRefreshType.DATA_REQUEST);
		}
		
	}

	processColorChange = (field: ColorTypes, dataRequestNeeded: boolean): void => {
		let checks = [
			ColorUtilsHelper.isSentimentColor,
			ColorUtilsHelper.isEaseColor
		];
		let selectedColor = this.$scope.visualProps[field];
		checks.forEach((checkFn) => {
			if (checkFn(this.$scope.visualProps, field)) {
				dataRequestNeeded = true;
				_.each(ReportConstants.colorFields, colorName => {
					if (checkFn(this.$scope.visualProps, colorName)) {
						this.$scope.visualProps[colorName] = selectedColor;
					}
				});
			}
		});

		this.$scope.changeColor(dataRequestNeeded);
		
	}

	resetColors = () => {
		this.$scope.visualProps.pointColor = ColorPaletteNames.INHERIT;
		this.$scope.visualProps.color = null;
		this.$scope.visualProps.popColor = ColorPaletteNames.LIGHTEN;
		this.$scope.visualProps.secondaryPopColor = ColorPaletteNames.LIGHTEN;
		this.$scope.visualProps.secondaryPointColor = ColorPaletteNames.INHERIT;

		_.each(ReportConstants.colorFields, (field) => this.$scope.processColorChange(field, true));
		_.each(ReportConstants.secondaryColorFields, (field) => this.$scope.processColorChange(field, true));
		this.$scope.changeColor();
	}

	resetSelections = () => {
		this.$scope.visualProps.attributeSelections = {};
		this.$scope.visualProps.calculationSeries = [];
		this.setPropertyInternal('yAxis', this.constants.VOLUME);
		this.setPropertyInternal('size', this.constants.CONSTANT_SIZE);

		this.setPropertyInternal('secondarySize', this.constants.CONSTANT_SIZE);
		this.setPropertyInternal('secondaryYAxis', this.constants.VOLUME);

		this.setPropertyInternal('sortBy', this.constants.VOLUME);

		this.resetColors();

		if (!_.isUndefined(this.attributeProps)) {
			this.attributeProps.forEach((field) => {
				delete this.$scope.visualProps[field];
			});
		}
		this.processAttributesAndMetrics();
		this.applyVisualChangesInternal();

		this.$scope.clearGroup('primaryGroup');
		this.$scope.clearGroup('secondaryGroup');
		this.$scope.ui.secondaryGroupVisible = false;
	}

	setPropertyInternal = (name, node) => { // doesn't support metric=> s
		this.$scope.visualProps.attributeSelections[name] = node;
		this.$scope.visualProps[name] = node.name;
	}

	applyVisualChangesInternal = (refreshType?: PreviewChartRefreshType) => {
		this.$scope.settingsRefreshTrigger = !this.$scope.settingsRefreshTrigger;
		this.checkPreviewDataAndApplyVisualChanges(refreshType);
		this.updateLockAxisLimit();
	}

	updateLockAxisLimit = () => {
		let stackedGroup = this.$scope.visualProps.attributeSelections.stackedGroup;
		this.$scope.visualProps.lockAxisLimits = stackedGroup && stackedGroup.stackType === StackType.HUNDRED_PERCENT;
	}

	showNormalizeGroupsButton = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.VALUE_NORMALIZATION)
			&& (!!this.$scope.visualProps.secondaryGroup || !!this.$scope.visualProps.stackedGroup)
			&& this.$scope.props.widgetType === WidgetType.BAR
			&& AnalyticMetricTypes.hasCustomGroups(this.$scope.props.selectedAttributes);
	}

	isForceNormalization = () => {
		return this.isForceDisabledNormalization() || this.isForceEnabledNormalization();
	}

	getForcedNormalizeGroupsValue = () => {
		if (this.isForceDisabledNormalization()) {
			return false;
		}
		return true;
	}

	isForceEnabledNormalization = () => {
		return AnalyticMetricTypes.isForcedGroupNormalization(this.$scope.props.selectedAttributes);
	}

	isForceDisabledNormalization = () => {
		return AnalyticMetricTypes.isForcedDisabledGroupNormalization(this.$scope.props.selectedAttributes);
	}

	showLegendButton = () => {
		if (this.$scope.props.widgetType === WidgetType.BAR) {
			let colors = this.$scope.visualProps.secondaryChartType ? ReportConstants.colorFields : ReportConstants.primaryColorFields;
			return !!(this.$scope.visualProps.stackedGroup
				|| this.$scope.visualProps.secondaryGroup
				|| ColorUtilsHelper.isObjectBasedColorPresent(colors, this.$scope.visualProps));
		} else return !!this.$scope.visualProps.secondaryGroup;
	}

	showClusterButton = () => {
		if (this.$scope.props.widgetType !== WidgetType.BAR) return false;

		return this.$scope.visualProps.secondaryGroup && !this.$scope.visualProps.stackedGroup;
	}

	showTotalCount = () => {
		return this.$scope.visualProps.primaryGroup && !isEmpty(this.$scope.staticData.totalCount) && this.$scope.visualProps.showSampleSize;
	}

	isPoP = (stacked) => {
		let value = stacked ? this.$scope.visualProps.stackedGroup : this.$scope.visualProps.secondaryGroup;
		return value === this.periodOverPeriodItem.name;
	}

	isAnySelectionPoP = (): boolean => {
		return (this.$scope.visualProps.stackedGroup === this.periodOverPeriodItem.name) ||
			(this.$scope.visualProps.secondaryGroup === this.periodOverPeriodItem.name);
	}

	isCalculationSeries = () => {
		return this.$scope.visualProps.secondaryGroup === this.calculationSeriesItem.name;
	}

	hasSecondaryGroup = () => {
		return !!this.$scope.visualProps.secondaryGroup;
	}

	showGroupCog = () => {
		return this.$scope.visualProps.secondaryGroup &&
			!this.$scope.isPoP() && !this.isCalculationSeries();
	}

	openAdvancedOptions = () => {
		this.highchartsUtils.showAdvancedChartOptionsDialog(
			this.$scope.widget,
			!!this.$scope.visualProps.secondaryChartType,
			'secondary',
			`${this.locale.getString('widget.primary')} ${this.locale.getString('widget.axisOptions')}`,
			`${this.locale.getString('widget.secondary')} ${this.locale.getString('widget.axisOptions')}`,
			this.isCalculationSeries()
		).then(() => this.applyVisualChangesInternal(PreviewChartRefreshType.UI_ONLY));
	}

	showStackedSortBy = () => {
		if (!this.$scope.visualProps.stackedGroup)
			return false;
		return AnalyticsDefinitionUtils.isSupportStackSorting(this.$scope.visualProps.attributeSelections.stackedGroup);
	}

	getStackedDisplayName = () => {
		return this.$scope.visualProps.attributeSelections.stackedGroup && this.$scope.visualProps.attributeSelections.stackedGroup.displayName;
	}

	removeMetric = (metric) => {
		this.resetMetricIfMatching('yAxis', metric);
		this.resetMetricIfMatching('secondaryYAxis', metric);

		this.$scope.visualProps.calculationSeries = _.without(this.$scope.visualProps.calculationSeries,
			_.findWhere(this.$scope.visualProps.calculationSeries, { name: metric.name }));
	}

	resetMetricIfMatching = (field, metric) => {
		if (metric.name === this.$scope.visualProps[field])
			this.setPropertyInternal(field, this.constants.VOLUME);
	}


	reorderCalculations = (data, newIndex) => {

		// put all calculations (up to 3) in an array for easy manipulation
		let allCalculationsArray: any[] = [this.$scope.visualProps.attributeSelections.yAxis].concat(this.$scope.visualProps.calculationSeries);

		let originalIndex = (typeof data === 'string') ? 0 : _.indexOf(allCalculationsArray, data);

		allCalculationsArray.move(originalIndex, newIndex);

		// redistribute the array items back to yaxis and calculations arrays
		this.$scope.visualProps.yAxis = allCalculationsArray[0].name;
		this.$scope.visualProps.attributeSelections.yAxis = allCalculationsArray[0];
		this.$scope.visualProps.calculationSeries = allCalculationsArray.splice(1);
	}

	isReorderableCalculation = (index) => {
		return !_.isUndefined(this.$scope.visualProps.calculationSeries[index]);
	}

	isReorderableYAxis = () => {
		return this.$scope.showCalculationSeries() && (this.$scope.isReorderableCalculation(0) || this.$scope.isReorderableCalculation(1));
	}

	isReorderableGroups = () => {
		return this.$scope.isBar() && (this.$scope.visualProps.primaryGroup
			&& this.$scope.visualProps.secondaryGroup) && !this.$scope.isPoP() && !this.isPrimaryGroupingTime();
	}

	// only two groups, so we dont need to know which one was dragged where, we can just swap them
	swapGroups = () => {
		let tempPrimary = angular.copy(this.$scope.visualProps.attributeSelections.primaryGroup);
		let tempSecondary = angular.copy(this.$scope.visualProps.attributeSelections.secondaryGroup);
		this.$scope.clearGroup('secondaryGroup').then(() => {
			this.moveGroupProperty('primaryGroup', tempSecondary);
			this.moveGroupProperty('secondaryGroup', tempPrimary);
		});
	}

	showRemoveGroup = () => {
		return this.$scope.visualProps.secondaryGroup || this.$scope.isBar();
	}

	/**
	 * Check if secondary axis is disallowed on a chart type
	 */
	isSecondaryAxisDisabled = (): boolean => {
		return [ChartType.RADAR, ChartType.POLAR].includes(this.$scope.visualProps.subChartType);
	}

	filterSelectedAttributesForGrouping = (items: any[]): any[] => {
		const isHierarchyEnabled = this.$scope.dashboardFilters?.personalization?.isHierarchyEnabled();
		const appliedHierarchyId = this.$scope.dashboardFilters?.personalization?.getHierarchyId();
		return this.hierarchySettingsService.filterSelectedAttributesForGrouping(items, isHierarchyEnabled, appliedHierarchyId);
	}

}

app.controller('CBAnalyticDualChartCtrl', AnalyticDualController);
