<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.samlIDPSettingsTitle' | i18n">
</modal-header>
<div class="modal-body">
	<form #identityProviderForm="ngForm" class="form-horizontal">
		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.idpAliasName'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.samlIdpAliasNameHelp'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-8">
				<input name="aliasName" class="form-control"
					[(ngModel)]="identityProvider.aliasName"
					required
					pattern="^[_a-zA-Z0-9-]+$"
					#aliasName="ngModel"
					(ngModelChange)="onAliasNameInput()"
					[disabled]="isAliasNameDisabled()"
					[title]="getAliasNameTitle()"/>
				<p *ngIf="aliasName.errors?.required && (aliasName.dirty || aliasName.touched)"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.aliasNameInvalid'">
				</p>

				<p *ngIf="aliasName.errors?.exist && (aliasName.dirty || aliasName.touched)"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.aliasNameExist'">
				</p>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.idpEntityId'|i18n}}</label>
			<div class="col-md-8">
				<input name="entityId" class="form-control"
					readonly
					[(ngModel)]="identityProvider.entityId"
					#entityId="ngModel"/>
				<p *ngIf="identityProviderAlreadyExists"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.entityIdExists'">
				</p>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.idpMetadataFile'|i18n}}</label>
			<file-upload
				class="col-md-8"
				[previousFilename]="getPreviouslyUploadedFile()"
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.XML]"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(erroneousUpload)="handleFailedUpload()"
				(removedUpload)="handleRemovedUpload()"
			></file-upload>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.labelSignatureAlgorithmTitle'|i18n}}</label>
			<radio-buttons
				name="signatureAlgorithmGroup"
				id="signatureAlgorithmGroup"
				[(ngModel)]="identityProvider.signatureAlgorithm"
				[options]="signatureAlgorithms"
				required
				class="col-md-8">
			</radio-buttons>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.labelLogoutMechanism'|i18n}}</label>
			<radio-buttons
				name="logoutGroup"
				id="logoutGroup"
				[(ngModel)]="identityProvider.logoutMechanism"
				[options]="logoutOptions"
				required
				class="col-md-8">
			</radio-buttons>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3 break-word">{{'mAccount.labelAllowedQueryParams'|i18n}}</label>
			<tags-input class="col-md-8" name="oauthScopes"
				[(ngModel)]="identityProvider.allowedQueryParams"
				[addOnBlur]="true"
				[addOnEnter]="true">
			</tags-input>
		</div>

		<div class="form-group">
			<div class="col-md-8 col-md-offset-3 form-inline">
				<div class="labeled-checkbox mb-0 labeled-checkbox-inline">
					<checkbox-button
						name="authenticationAgeCheckRequired"
						id="authenticationAgeCheckRequired"
						label="{{'mAccount.labelMaxAge'|i18n}}"
						[(ngModel)]="identityProvider.authenticationAgeCheckRequired">
					</checkbox-button>
				</div>
				<div class="d-flex align-items-center">
					<input type="number"
						name="authenticationAgeSeconds"
						min="1" max="3600000" pattern="^\d*"
						[required]="identityProvider.authenticationAgeCheckRequired"
						[(ngModel)]="identityProvider.authenticationAgeSeconds"
						[disabled]="!identityProvider.authenticationAgeCheckRequired"/>
					<label class="col-md-3">{{'mAccount.labelMaxAgeUnits'|i18n}}</label>
				</div>
			</div>
		</div>

		<div class="form-group">
			<div class="col-md-8 col-md-offset-3 form-inline">
				<div class="labeled-checkbox mb-0 labeled-checkbox-inline">
					<checkbox-button
						name="signatureCheckRequired"
						id="signatureCheckRequired"
						label="{{'mAccount.labelRequireSignature'|i18n}}"
						[(ngModel)]="identityProvider.signatureCheckRequired">
					</checkbox-button>
				</div>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelIdentityConfig'|i18n}}</label>
			</div>
		</div>

		<div class="form-group">
			<div class="col-md-8 col-md-offset-3">
				<div class="labeled-checkbox">
					<checkbox-button id="identifyUserByNameId"
						name="identifyUserByNameId"
						label="{{'mAccount.identifyUserByNameId'|i18n}}"
						[(ngModel)]="identityProvider.userIdentity.identifyUserByNameId">
					</checkbox-button>
				</div>

				<div class="labeled-checkbox">
					<checkbox-button
						id="sendNameIdPolicyInRequest"
						name="sendNameIdPolicyInRequest"
						label="{{'mAccount.sendNameIdPolicy'|i18n}}"
						[disabled]="!identityProvider.userIdentity.identifyUserByNameId"
						[(ngModel)]="identityProvider.userIdentity.sendNameIdPolicyInRequest">
					</checkbox-button>
				</div>

				<div class="labeled-checkbox">
					<checkbox-button
						id="allowEnterpriseViewer"
						name="allowEnterpriseViewer"
						label="{{'mAccount.allowEnterpriseViewer'|i18n}}"
						[(ngModel)]="identityProvider.userIdentity.allowEnterpriseViewer">
					</checkbox-button>
				</div>
			</div>
		</div>

		<div class="form-group" show-errors>
			<label for="identityAttribute" class="control-label col-md-3">{{'mAccount.idpIdentityAttribute'|i18n}}</label>
			<div class="col-md-8">
				<input id="identityAttributeName"
					name="identityAttributeName"
					#identityAttributeName="ngModel"
					class="form-control"
					[(ngModel)]="identityProvider.userIdentity.identityAttributeName"
					[disabled]="identityProvider.userIdentity.identifyUserByNameId"
					[required]="!identityProvider.userIdentity.identifyUserByNameId"
					pattern=".*" />

				<p *ngIf="identityAttributeName.errors?.pattern || identityAttributeName.errors?.required  && (identityAttributeName.dirty || identityAttributeName.touched)"
					class="help-block text-danger mt-4"
					[i18n]="'mAccount.identityAttributeInvalid'">
				</p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'mAccount.labelLdapSearchFilter'|i18n}}</label>
			<radio-buttons
				inline="true"
				name="ldapSearchFilterGroup"
				id="ldapSearchFilterGroup"
				[(ngModel)]="identityProvider.userIdentity.ldapSearchFilter"
				[options]="ldapSearchFilters"
				class="col-md-8">
			</radio-buttons>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelOAuthIntegrationSettings'|i18n}}</label>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.labelDefaultOAuthClientId'|i18n}}</label>
			<div class="col-md-3">
				<simple-dropdown class="form-control p-0 border-0"
					id="clientId"
					[options]="oauthClientDetails"
					[(value)]="identityProvider.authSettings.defaultClientId"
					displayField="clientId"
					valueField="clientId">
				</simple-dropdown>
			</div>
		</div>

		<div class="labeled-rule text-center">
			<hr/>
			<div>
				<label>{{'mAccount.labelSPInitiateIntegrationSettings'|i18n}}</label>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.labelServiceProvider'|i18n}}
				<cb-inline-help>
					<help-body [innerHTML]="'mAccount.labelSPInitiateIntegrationSettingsHelper'|i18n"></help-body>
				</cb-inline-help>
			</label>
			<div class="col-md-3">
				<simple-dropdown class="form-control p-0 border-0"
					id="serviceProvider"
					[options]="serviceProviders"
					[(value)]="identityProvider.spAliasName"
					displayField="aliasName"
					valueField="aliasName">
				</simple-dropdown>
			</div>
		</div>

	</form>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="!isSettingsValid()"
></save-modal-footer>
