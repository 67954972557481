import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { Component, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import Group from '@cxstudio/user-administration/groups/Group';
import { AlertSubscription } from './alert-subscription';
import { Alert } from './alert.class';
import { AlertSubscriptionApiService } from './alert-subscription-api.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';


export interface IHierarchySelection {
	hierarchyId: number;
	levels: number[];
}

export interface AlertHierarchySelectionEditDialogInput {
	group: Group;
	alertSubscription: AlertSubscription;
}

@Component({
	selector: 'alert-hierarchy-selection-edit-modal',
	templateUrl: './alert-hierarchy-selection-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertHierarchySelectionEditDialogComponent implements OnInit {

	@Input() input: AlertHierarchySelectionEditDialogInput;

	alertSubscription: AlertSubscription;
	group: Group;

	selectNoneText: string;
	selectEverythingText: string;
	highlightSelectedBackground: boolean;
	showMultiselectButtons: boolean;
	hideSearch: boolean;

	loadingPromise: any;

	levels: number;
	hierarchyLevelTree: any[];
	hierarchySelection: IHierarchySelection;

	constructor(
		private readonly modal: NgbActiveModal,
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		private readonly alertSubscriptionApiService: AlertSubscriptionApiService,
		@Inject('organizationApiService') private readonly organizationApiService: OrganizationApiService,
		@Inject('hierarchyService') private readonly hierarchyService: HierarchyService,
	) {}

	ngOnInit(): void {
		this.alertSubscription = this.input.alertSubscription;
		let alert: Alert = this.alertSubscription.alert;

		this.group = this.input.group;
		let groupId: number = this.group.groupId;

		this.levels = 0;
		this.hierarchyLevelTree = [];

		this.hierarchySelection = alert.hierarchySelections[groupId]
			? cloneDeep(alert.hierarchySelections[groupId])
			: {levels: []};

		this.selectNoneText = this.locale.getString('administration.selectNone');
		this.selectEverythingText = this.locale.getString('common.selectAll');
		this.highlightSelectedBackground = false;
		this.showMultiselectButtons = false;
		this.hideSearch = true;

		let contentProviderId = alert.contentProviderId;
		let projectId = alert.projectId;

		let hierarchyPromise = this.organizationApiService.getGroupHierarchies(contentProviderId, projectId, groupId).then((hierarchies) => {
			return _.where(hierarchies, {active: true});
		});

		this.loadingPromise = hierarchyPromise.then((hierarchies) => {
			if (hierarchies && hierarchies[0] && hierarchies[0].id) {
				this.hierarchySelection.hierarchyId = hierarchies[0].id;
				this.organizationApiService.getHierarchyLevels(hierarchies[0].id).then((hierarchyLevels) => {
					this.levels = hierarchyLevels.length;
					this.hierarchyLevelTree = this.hierarchyService.getOrgHierarchyLevelTree(hierarchyLevels);
					this.ref.detectChanges();
				});
			}
		});
	}

	save = (): void => {
		let alertSubscriptionAlert: Alert = this.alertSubscription.alert;

		let alertHierarchySelectionSavingEntity = {
			identity: {
				masterAccountId: alertSubscriptionAlert.masterAccountId,
				contentProviderId: alertSubscriptionAlert.contentProviderId,
				accountId: alertSubscriptionAlert.accountId,
				projectId: alertSubscriptionAlert.projectId,
				alertId: alertSubscriptionAlert.alertId
			},
			groupId: this.group.groupId,
			hierarchySelection: this.hierarchySelection
		};

		this.loadingPromise = this.alertSubscriptionApiService.saveHierarchySelection(alertHierarchySelectionSavingEntity)
			.then(() => {
				alertSubscriptionAlert.hierarchySelections[this.group.groupId] = this.hierarchySelection;
				this.modal.close(this.alertSubscription);
			});
	}

	cancel = (): void => {
		this.modal.dismiss('cancel');
	}

	handleNodeClick = (object: {node: any}): void => {
		this.hierarchySelection.levels = this.isNodeChecked(object) ? [] : _.range(object.node.level, this.levels + 1);
	}

	isNodeChecked = (object: {node: any}): boolean => {
		return !_.isEmpty(this.hierarchySelection.levels) && object.node.level >= _.min(this.hierarchySelection.levels);
	}

	showNodeCheckbox = (): boolean => {
		return true;
	}
}
