<div ngbDropdown class="font-weight cursor-default" container="body">
	<span class="ml-8 cursor-pointer q-icon q-icon-filter"
		ngbDropdownToggle
		[title]="getFilterTitle()"
		[class.text-primary-color-500]="isFilterApplied()"
		aria-hidden="true"></span>
	<span class="ml-8 cursor-pointer q-icon q-icon-sort-both"
		ngbDropdownToggle
		[title]="getSortTitle()"
		[class.text-primary-color-500]="isSortApplied()"
		aria-hidden="true"></span>
	<div ngbDropdownMenu class="pt-8 cursor-default">
		<header class="dropdown-header">{{'common.filter'|i18n}}</header>
		<div class="p-8">
			<div class="d-flex filter-statement p-8" (click)="$event.stopPropagation()">
				<select-from-tree
					hideSearch="true"
					[hierarchyList]="filterCategories"
					[matchFunction]="getSelectedFilterCategory"
					(onNodeClick)="selectFilterCategory($event.node.value)"
				></select-from-tree>

				<select-from-tree
					hideSearch="true"
					class="ml-8"
					[hierarchyList]="filterOptions[selectedValue.filterCategory]"
					[matchFunction]="getSelectedFilter"
					(onNodeClick)="setFilter($event.node.value)"
				></select-from-tree>
			</div>
		</div>
		<header class="dropdown-header">{{'common.sort'|i18n}}</header>
		<div class="p-8">
			<div class="d-flex filter-statement p-8" (click)="$event.stopPropagation()">
				<select-from-tree
					hideSearch="true"
					[hierarchyList]="sortCategories"
					[matchFunction]="getSelectedSortCategory"
					(onNodeClick)="selectSortCategory($event.node.value)"
				></select-from-tree>

				<select-from-tree
					hideSearch="true"
					class="ml-8"
					[hierarchyList]="sortOptions[selectedValue.sortCategory]"
					(onNodeClick)="setSort($event.node.value)"
					[matchFunction]="getSelectedSort"
				></select-from-tree>
			</div>
		</div>
	</div>
</div>
