<aside>
	<div class="filters-bar-compact font-size-3 cursor-pointer kb-focusable-inset font-bold"
		[ngClass]="{'kb-focusable-inset': !isButtonToggle()}"
		*ngIf="showFiltersBar()"
		(keydown.enter)="onFilterPanelKbAction($event)"
		[attr.tabindex]="!isButtonToggle() ? 0 : null">
		<span (click)="panelClickHandler()" class="action-hover-wrapper">
			<button *ngIf="toggle === 'icon'"
				id="filters-popover-toggle"
				class="no-border btn btn-icon q-icon-filter cursor-pointer filters-popup-toggle"
				[ngClass]="{'action-hover-text':!active, 'action-text':active}"
				[attr.aria-label]="getAriaLabel()"
				aria-haspopup="true"
				[attr.aria-expanded]="active">
			</button>
			<div *ngIf="toggle === 'button'" class="dropdown-input">
				<button
					id="filters-popover-toggle"
					class="btn btn-icon width-auto no-border no-background pr-32 pl-0 dropdown-toggle text-base"
					title="{{'widget.headerFilters'|i18n}}"
					(click)="clickHandler()"
					(keydown.enter)="onToggleKbAction($event)">
					<span class="q-icon-filter p-8"></span>
					<span class="button-text">{{'widget.headerFilters'|i18n}}</span>
					<span class="caret" aria-hidden="true"></span>
				</button>
			</div>
			<ng-container *ngIf="!embedded || isEmbeddedInternally">
				<strong *ngIf="!toggle">{{'widget.headerFilters'|i18n}}:</strong> &nbsp;
				<applied-dashboard-filters-list
					class="text-ellipsis pv-8"
					[embedded]="embedded"
					[truncate]="truncate"
					[hasHierarchy]="hasHierarchy()"
					[hierarchyLabel]="getHierarchyLabel()"
					[appliedFiltersWithValue]="getAppliedFiltersWithValue()"
					[projectTimezone]="projectTimezone"
				></applied-dashboard-filters-list>
			</ng-container>
		</span>
	</div>
</aside>
