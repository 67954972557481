import { Directive, Input, Output, ElementRef, Injector, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { User } from '@cxstudio/user-administration/users/entities/user';

@Directive({
	selector: 'dashboard-list-tools'
})

export class DashboardListToolsUpgrade extends UpgradeComponent {
	@Input() ui;
	@Input() actionsService;
	@Input() selectionUtils;
	@Input() permissions;
	@Input() isTablet;
	@Input() loading;
	@Input() isCurrentUserSelected;
	@Input() getGridMenuItems;
	@Input() gridMode;
	@Input() selectedUser: User;
	@Output() selectedUserChange = new EventEmitter<User>();

	constructor(elementRef: ElementRef, injector: Injector) {
		super('dashboardListTools', elementRef, injector);
	}
}
