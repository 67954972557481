import { IDirectiveFactory, INgModelController } from 'angular';

const ngEnter = () => {
	let ENTER_KEY = 13;
	return {
		require: '?ngModel',
		restrict: 'A',
		link: (scope, element, attrs, ngModelCtrl: INgModelController) => {
			element.bind('keydown keypress', (event) => {
				if (event.which === ENTER_KEY) {
					if (element.get(0).type === 'text'
							|| element.get(0).type === 'search') {
						//to be used with debounce on text input
						ngModelCtrl.$setViewValue(element.val(), 'enter');
					}
					scope.$apply(() => {
						scope.$eval(attrs.ngEnter);
					});
					event.preventDefault();
				}
			});
		}
	};
};


const ngGlobalEnter = ($window) => {
	let ENTER_KEY = 13;
	return (scope, element, attrs) => {
		let handler = (event) => {
			if (event.which === ENTER_KEY) {
				scope.$apply(() => {
					scope.$eval(attrs.ngGlobalEnter);
				});

				event.preventDefault();
			}
		};
		angular.element($window).bind('keydown keypress', handler);
		scope.$on('$destroy', () => {
			angular.element($window).off('keydown keypress', handler);
		});
	};
};

/**
 * Directive for handling enter key in elements
 */
app.directive('ngEnter', ngEnter as IDirectiveFactory);


// responds to enter key anywhere, not just while the item is in focus
app.directive('ngGlobalEnter', ngGlobalEnter as IDirectiveFactory);
