import { Input, Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { URLSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { UIOption } from '@clarabridge/unified-angular-components';
import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import { HorizontalAlignment } from '@cxstudio/reports/providers/cb/definitions/horizontal-alignment.enum';
import { VerticalAlignment } from '@cxstudio/reports/providers/cb/definitions/vertial-alignment.enum';

@Component({
	selector: 'url-type',
	templateUrl: './url-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlTypeComponent implements OnInit {

	@Input() attribute: Partial<URLSettings>;

	readonly DEFAULT_IMAGE_HEIGHT = 300;

	urlTypes: UIOption<AttributeUrlType>[] = [
		{ displayName: this.locale.getString('widget.optionNone'), value: AttributeUrlType.NONE },
		{ displayName: this.locale.getString('widget.image'), value: AttributeUrlType.IMAGE },
		{ displayName: this.locale.getString('widget.link'), value: AttributeUrlType.LINK }
	];

	verticalAlignOptions: UIOption<string>[];
	horizontalAlignOptions: UIOption<string>[];

	constructor(private readonly locale: CxLocaleService) {}

	ngOnInit(): void {
		if (this.attribute) {

			if (_.isUndefined(this.attribute.urlType)) {
				this.attribute.urlType = AttributeUrlType.NONE;
			}

			if (_.isUndefined(this.attribute.tableRowHeight)) {
				this.attribute.tableRowHeight = this.DEFAULT_IMAGE_HEIGHT;
			}

			if (_.isUndefined(this.attribute.scale)) {
				this.attribute.scale = 'fit';
			}

			if (_.isUndefined(this.attribute.horizontalAlign)) {
				this.attribute.horizontalAlign = HorizontalAlignment.LEFT;
			}

			if (_.isUndefined(this.attribute.verticalAlign)) {
				this.attribute.verticalAlign = VerticalAlignment.TOP;
			}
		}

		this.verticalAlignOptions = [
			{
				class: this.getAlignmentIcon(VerticalAlignment.TOP),
				value: VerticalAlignment.TOP,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${VerticalAlignment.TOP.capitalizeFirstLetter()}`)
			},
			{
				class: this.getAlignmentIcon(VerticalAlignment.MIDDLE),
				value: VerticalAlignment.MIDDLE,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${VerticalAlignment.MIDDLE.capitalizeFirstLetter()}`)
			},
			{
				class: this.getAlignmentIcon(VerticalAlignment.BOTTOM),
				value: VerticalAlignment.BOTTOM,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${VerticalAlignment.BOTTOM.capitalizeFirstLetter()}`)
			}
		];

		this.horizontalAlignOptions = [
			{
				class: this.getAlignmentIcon(HorizontalAlignment.LEFT),
				value: HorizontalAlignment.LEFT,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${HorizontalAlignment.LEFT.capitalizeFirstLetter()}`)
			},
			{
				class: this.getAlignmentIcon(HorizontalAlignment.CENTER),
				value: HorizontalAlignment.CENTER,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${HorizontalAlignment.CENTER.capitalizeFirstLetter()}`)
			},
			{
				class: this.getAlignmentIcon(HorizontalAlignment.RIGHT),
				value: HorizontalAlignment.RIGHT,
				displayName: ''
				// tooltip: this.locale.getString(`widget.align${HorizontalAlignment.RIGHT.capitalizeFirstLetter()}`)
			}
		];
	}

	private getAlignmentIcon = (type: string): string => {
		return `q-icon q-icon-align-image-${type}`;
	}
}

app.directive('urlType', downgradeComponent({ component: UrlTypeComponent }));
