import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { SearchTermsResult, SearchTermsOptions } from '@cxstudio/dashboards/attribute-value-searcher.service';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

export enum PagingSearchState {
	FIRST_SEARCH = 'FIRST_SEARCH',
	FIRST_LOAD_MORE = 'FIRST_LOAD_MORE',
	REGULAR_LOAD_MORE = 'REGULAR_LOAD_MORE',
	NO_MORE_DATA = 'NO_MORE_DATA'
}

export interface IPagingSearchSupport {
	doQuery: (options: any) => ng.IPromise<any>;
	buildTypeSpecificOptions: (query: string) => any;
	populateValues: (result: SearchTermsResult, searchState: PagingSearchState) => void;
}

export interface IPagingSearch {
	searchState: PagingSearchState;
	currentOffset: number;
	currentSearchLimit: number;
	firstSearchingPromise: ng.IPromise<SearchTermsResult>;
	showFirstSearchSpinner: boolean;
	pagingSearchingPromise: ng.IPromise<SearchTermsResult>;
	hasDottedItems: boolean;
	emptyResult: boolean;

	loadMore: (filter: string) => void;
	resetSearch: () => void;
	init: () => void;
	setHasDottedItems: (value: boolean) => void;

	stateChanged$: Observable<void>;
}


export class PagingSearchFactory implements IPagingSearch {
	private readonly FIRST_SEARCH_START = 0;
	private readonly FIRST_SEARCH_LIMIT = 20;
	private readonly FIRST_LOAD_MORE_START = 0;
	private readonly FIRST_LOAD_MORE_LIMIT = 40;
	private readonly REGULAR_LOAD_MORE_LIMIT = 20;

	lastRequestPromise: any;
	attributeValueSearcher: any;
	spinnerDelay: any;
	selection: any;
	onlyExactMatch: any;
	searchState: PagingSearchState;
	currentOffset: number;
	currentSearchLimit: number;
	firstSearchingPromise: ng.IPromise<any>;
	showFirstSearchSpinner: boolean;
	pagingSearchingPromise: ng.IPromise<any>;
	hasDottedItems: boolean;
	emptyResult: boolean;
	loadMore: (filter: string) => void;

	private stateChangeSource = new Subject<void>();
	stateChanged$ = this.stateChangeSource.asObservable();

	constructor(private supportMethods: IPagingSearchSupport) {
		this.init();
		return this;
	}

	init(): void {
		this.currentSearchLimit = null;
		this.currentOffset = null;
		this.firstSearchingPromise = null;
		this.showFirstSearchSpinner = false;
		this.pagingSearchingPromise = null;
		this.searchState = PagingSearchState.FIRST_SEARCH;
		this.loadMore = this.searchTerms;
		this.hasDottedItems = false;
		this.emptyResult = false;
		this.stateChangeSource.next();
	}

	searchTerms<T>(query: string): ng.IPromise<T> {
		let options: any = this.buildSearchTermsOptions(query);
		this.currentOffset = options.start;
		this.currentSearchLimit = options.limit;

		let promise = this.lastRequestPromise = this.supportMethods.doQuery(options).then(result => {
			if (this.lastRequestPromise !== promise)
				return;
			this.supportMethods.populateValues(result, this.searchState);
			this.updateSearchState(result);
			this.firstSearchingPromise = null;
			this.pagingSearchingPromise = null;
			return result;
		});

		if (this.spinnerDelay) {
			clearTimeout(this.spinnerDelay);
			delete this.spinnerDelay;
		}
		if (this.searchState === PagingSearchState.FIRST_SEARCH) {
			this.showFirstSearchSpinner = false;
			this.firstSearchingPromise = promise;
			this.spinnerDelay = setTimeout(() => {
				this.showFirstSearchSpinner = true;
				this.stateChangeSource.next();
			}, 200); // 300 debounce + 200 delay = show spinner after 0.5s
		} else {
			this.pagingSearchingPromise = promise;
		}

		this.stateChangeSource.next();
		return promise;
	}

	markDottedItemsOld<T>(result: T[], newValues: T[]): void {
		// 1. add dot to all new values
		newValues.forEach((value: any) => value.newValue = true);

		let i: number;
		// 2. find last old item; set update text depending on whether any new item exist before last old item
		let lastNewItemIndex: number = -1;
		let lastOldItemIndex: number = -1;
		for (i = 0; i < result.length; i++) {
			if ((result[i] as any).newValue) {
				lastNewItemIndex = i;
			} else {
				lastOldItemIndex = i;
				if (lastNewItemIndex >= 0 && lastOldItemIndex > lastNewItemIndex) {
					this.setHasDottedItems(true);
				}
			}
		}

		// 4. disable dot for new items appended to the end
		for (i = lastOldItemIndex; i < result.length; i++) {
			(result[i] as any).newValue = false;
		}
	}

	markDottedItems(existingValues: AttributeValueOption[], newValues: AttributeValueOption[]): void {
		// case when all values are new is ignored, as there is no sense of showing dots there
		let existingNames = {};
		existingValues.forEach(value => existingNames[value.displayName] = true);
		let foundExisting = false;
		// mark newValue = false for everything below existing values
		// mark newValue = true once we found existing item, for values which don't exist in existing
		[].concat(newValues).reverse().forEach(value => {
			if (!foundExisting) {
				foundExisting = !!existingNames[value.displayName];
			}
			if (foundExisting) {
				value.newValue = !existingNames[value.displayName];
				if (value.newValue) {
					this.setHasDottedItems(true);
				}
			} else {
				value.newValue = false;
			}
		});
	}

	setHasDottedItems = (hasDottedItems: boolean): void => {
		this.hasDottedItems = hasDottedItems;
	}

	private updateSearchState = (result: SearchTermsResult): void => {
		if (this.searchState !== PagingSearchState.FIRST_SEARCH
			&& (!result.data.length || result.data.length < this.currentSearchLimit)) {
			this.emptyResult = !result.data.length;
			this.searchState = PagingSearchState.NO_MORE_DATA;
		} else if (this.searchState === PagingSearchState.FIRST_SEARCH) {
			this.searchState = PagingSearchState.FIRST_LOAD_MORE;
			this.emptyResult = !result.data.length;
		} else if (this.searchState === PagingSearchState.FIRST_LOAD_MORE) {
			this.searchState = PagingSearchState.REGULAR_LOAD_MORE;
		}

		this.currentOffset = this.currentOffset + result.data.length;
		this.stateChangeSource.next();
	}

	private buildSearchTermsOptions = (query: string): SearchTermsOptions => {
		let options = this.supportMethods.buildTypeSpecificOptions(query);

		if (this.searchState === PagingSearchState.FIRST_SEARCH) {
			options.start = this.FIRST_SEARCH_START;
			options.limit = this.FIRST_SEARCH_LIMIT;
			options.cacheOption = AnalyticCacheOptions.Normal;
		} else if (this.searchState === PagingSearchState.FIRST_LOAD_MORE) {
			options.start = this.FIRST_LOAD_MORE_START;
			options.limit = this.FIRST_LOAD_MORE_LIMIT;
			options.cacheOption = AnalyticCacheOptions.Refresh;
		} else if (this.searchState === PagingSearchState.REGULAR_LOAD_MORE) {
			options.start = this.currentOffset;
			options.limit = this.REGULAR_LOAD_MORE_LIMIT;
			options.cacheOption = AnalyticCacheOptions.Normal;
		}

		return options;
	}

	resetSearch = (): void => {
		this.searchState = PagingSearchState.FIRST_SEARCH;
		this.currentOffset = 0;
		this.stateChangeSource.next();
	}
}
