import WidgetUtils from '@cxstudio/reports/entities/widget-utils';

type VisualMapping = (utils?: WidgetUtils) => string | void;

export class VisualizationService {

	private static types: {[name: string]: VisualMapping} = {
		PIE: () => 'highcharts-pie',
		STACKEDPIE: () => 'highcharts-pie',
		DUAL: VisualizationService.throwAngularError,
		BUBBLE: () => 'highcharts-bubble',
		CLOUD: () => 'd3-cloud',
		CLOUDWORDSTABLE: () => 'cloud-exclude-words',
		HEATMAP: () => 'highcharts-treemap',
		IMAGE: VisualizationService.throwAngularError,
		TEXT: VisualizationService.throwAngularError,
		LABEL: VisualizationService.throwAngularError,
		VIDEO: VisualizationService.throwAngularError,
		DEFINITION_TABLE: () => 'cb-definition-table',
		CB_AN_TABLE: () => 'cb-an-table',
		'AN-PREVIEW-TABLE': VisualizationService.throwAngularError,
		SELECTOR_BUTTON: () => 'cb-selector',
		SELECTOR_DROPLIST: () => 'cb-selector',
		SELECTOR_SEARCH: () => 'cb-selector-search',
		MAP: () => 'mapbox-map',
		CB_AN_METRIC: (utils: WidgetUtils) => {
			let metrics = utils.getMetricNames();
			if (!metrics) {
				return null;
			}

			return metrics.length >= 2
				? 'slick-table'
				: 'cb-an-metric';
		},
		'PREVIEW-BUBBLES': VisualizationService.throwAngularError,
		NETWORK: () => 'highcharts-network',
		HIERARCHY_TREE: () => 'd3-hierarchy-tree',

	};

	static getView(visualization: string, utils?: WidgetUtils): string | void {
		let viewMapper = this.types[visualization];
		if (viewMapper) {
			return viewMapper(utils);
		}
		return null;
	}

	static throwAngularError() {
		throw new Error('use angular visualization');
	}
}
