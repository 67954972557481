import { ConversationChartOptions } from './conversation-chart-options.class';
import { IChartSVG } from '@cxstudio/conversation/conversation-chart.class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import * as _ from 'underscore';

interface IOvertalkItem {
	yPosition: number;
	height: number;
}

export class OvertalkIndicatorGroup {
	
	constructor(dataSet: ConversationDataPoint[], config: ConversationChartOptions, rootSvg: IChartSVG) {
		// overtalk gets inserted, not appended, to layer it behind conversation
		rootSvg.insert('g', '#spine-conversation')
			.attr('id', 'spine-overtalk')
			.selectAll('rect')
			.data(this.getOvertalkItems(dataSet, config))
			.enter()
			.append('rect')
			.attr('fill', config.basicChart.overtalk.color)
			.attr('rx', config.basicChart.overtalk.radius)
			.attr('ry', config.basicChart.overtalk.radius)
			.attr('width', config.basicChart.width - (2 * config.basicChart.padding))
			.attr('height', d => d.height)
			.attr('y', d => d.yPosition)
			.attr('x', config.basicChart.padding)
			.attr('opacity', config.basicChart.overtalk.opacity);
	}

	private getOvertalkItems(dataSet: ConversationDataPoint[], config: ConversationChartOptions): IOvertalkItem[] {
		let overtalkGroups: ConversationDataPoint[][] = [];
		let currentGroup;
		dataSet.filter(data => data.isOvertalk) 
		.forEach((data, index) => {
			if (data.isOvertalkStart) {
				if (currentGroup)
					overtalkGroups.push(currentGroup);
				currentGroup = [data];
			} else {
				
				if (currentGroup) {
					currentGroup.push(data);
				} else {
					// if we have applied a filter, it's possible that an item that is not isOvertalkStart
					// may still be the first item in that group that we encounter
					currentGroup = [data];
				}
			}
		});
		if (currentGroup)
			overtalkGroups.push(currentGroup);
		
		return _.map(overtalkGroups, group => {
			return {
				yPosition: group[0].yPosition,
				// overtalk items are always from opposite sides, so always have spacing between them
				height: group.reduce((total, point) => total += point.height, 0)
					+ config.basicChart.bubbleSpacing * (group.length - 1)
			};
		});
	}
}