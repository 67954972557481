import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { EmbeddedWidgetManagementApi } from './embedded-widget-management-api.service';
import { EmbeddedWidgetUtils } from './embedded-widget-utils.service';
import { WidgetManagementItem } from './widget-management-item.class';

export interface GridController {
	hideEnterpriseView: boolean;
}

@Component({
	selector: 'embedded-widget-management',
	templateUrl: './embedded-widget-management.component.html'
})
export class EmbeddedWidgetManagementComponent implements OnInit {
	gridOptions: SlickgridOptions;
	gridType = GridTypes.EMBEDDED_WIDGET_MANAGEMENT;
	gridNameField = 'displayName';

	changedItems: any[];
	loading: Promise<any>;
	nameSearch: string;
	embeddedWidgets: WidgetManagementItem[];
	gridController: GridController;

	constructor(
		private embeddedWidgetManagementApi: EmbeddedWidgetManagementApi,
		private locale: CxLocaleService,
		private clipboardService: ClipboardService,
		private ref: ChangeDetectorRef,
		private embeddedWidgetUtils: EmbeddedWidgetUtils,
		private gridUtils: GridUtilsService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService
	) { }

	ngOnInit(): void {
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};
		this.reloadEmbeddedWidgets();
	}

	private reloadEmbeddedWidgets(): void {
		this.loading = this.embeddedWidgetManagementApi.getEmbeddedWidgets().then(widgets => {
			this.embeddedWidgets = widgets;
			this.refreshGrid();
		});
	}

	private refreshGrid(): void {
		this.changedItems = this.embeddedWidgets;
	}

	private onItemClick(event, object: WidgetManagementItem): void {
		if (this.gridUtils.isToggleClick(event)) {
			if (this.isEmbeddedToggle(event)) {
				this.embeddedWidgetManagementApi.enableEmbeddedWidget(object.id, !object.embeddedEnabled)
					.then(this.whenSuccess(object, true));
				object.embeddedEnabled = !object.embeddedEnabled;
				object.enterpriseViewEnabled = object.embeddedEnabled && object.enterpriseViewEnabled;
			} else if (object.embeddedEnabled && object.widgetType.toLocaleLowerCase() !== WidgetType.PREVIEW) {
				this.embeddedWidgetManagementApi.enableEmbeddedWidgetEnterpriseView(object.id, !object.enterpriseViewEnabled)
					.then(this.whenSuccess(object, false));
				object.enterpriseViewEnabled = !object.enterpriseViewEnabled;
			}
			this.changedItems = [object];
			this.ref.markForCheck();
		} else if (this.isCopyLinkClicked(event)) {
			let iFrameLink = this.embeddedWidgetUtils.getEmbedWidgetCode(object, object.embeddedIdentifier);
			let target = event.delegateTarget || event.currentTarget;
			this.clipboardService.copyToClipboard(iFrameLink, target);
			this.globalNotificationService.addSuccessNotification(this.locale.getString('mAccount.embeddedLinkCopied'));
		}
	}

	private isCopyLinkClicked(event) {
		let target = $(event.target);
		return target.parents('.copy-link-button').length > 0;
	}

	private whenSuccess(object: WidgetManagementItem, embeddedToggle: boolean): (response: boolean) => void {
		return (response: boolean) => {
			if (response === true) {
				let message;
				if (embeddedToggle) {
					message = object.embeddedEnabled ? 'mAccount.embeddedOn' : 'mAccount.embeddedOff';
				} else {
					message = object.enterpriseViewEnabled ? 'mAccount.enterpriseViewOn' : 'mAccount.enterpriseViewOff';
				}

				this.globalNotificationService.addSuccessNotification(this.locale.getString(message, { widgetName: object.displayName }));
			} else {
				this.globalNotificationService.showWarning(this.locale.getString('mAccount.embeddedEnabledFailed', { widgetName: object.displayName }));
			}
		};
	}

	private isEmbeddedToggle(event): boolean {
		let target = $(event.target);
		return target.parents('.br-embedded-enabled').length > 0;
	}

}
