

import * as _ from 'underscore';


export class DocExplorerPanel implements ng.IController {

	collapsed: boolean;
	private hiddenItems: any[];

	constructor() {}

	$onInit(): void {
		this.collapsed = false;
	}

	hideAttribute(attribute: any): boolean {
		return !_.findWhere(this.hiddenItems, {id: attribute.id, name: attribute.name, type: attribute.type});
	}
}

app.component('docExplorerPanel', {
	bindings: {
		heading: '@',
		maxHeight: '@',
		iconClass: '@',
		hiddenItems: '<'
	},
	templateUrl: 'partials/document-explorer/doc-explorer-panel-component.html',
	transclude: true,
	controller: DocExplorerPanel
});
