import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IShareEntity } from '@cxstudio/sharing/sharing-service.service';

@Directive({
	selector: 'simple-share-table'
})
export class SimpleShareTableUpgrade extends UpgradeComponent {

	@Input() items: IShareEntity[];
	@Input() searchText: string;
	@Output() onRemove: EventEmitter<void>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('simpleShareTable', elementRef, injector);
	}
}
