import Widget from '@cxstudio/dashboards/widgets/widget';
import { IReportWidgetContext } from '@cxstudio/reports/widgets/report-widget.class';
import * as cloneDeep from 'lodash.clonedeep';

export class WidgetPropertyService {
	static inherit = (widget: Widget, property: string, context: IReportWidgetContext, defaultValue?: any) => {
		let value = WidgetPropertyService.getValue(property, context, defaultValue);
		if (value !== undefined) {
			WidgetPropertyService.populate(widget, property, value);
		}
	}

	static getValue = (property: string, context: IReportWidgetContext, defaultValue?: any): any => {
		let replaceProperty = WidgetPropertyService.resolve(context.replace, property);
		if (!_.isUndefined(replaceProperty)) {
			return replaceProperty;
		}
		let sourceProperty = WidgetPropertyService.resolve(context.source, property);
		if (!_.isUndefined(sourceProperty)) {
			return sourceProperty;
		}
		return cloneDeep(defaultValue);
	}

	static resolve = (obj: any, path: string): any => {
		return path.split('.').reduce((prev, curr) => {
			return prev ? prev[curr] : undefined;
		}, obj);
	}

	static populate = (obj: any, path: string, value: any) => {
		let currentObj = obj;

		let pathElements = path.split('.');
		for (let i = 0; i < pathElements.length - 1; i++) {
			let pathElement = pathElements[i];
			if (!currentObj[pathElement]) {
				currentObj[pathElement] = {};
			}
			currentObj = currentObj[pathElement];
		}

		let lastPathElement = pathElements[pathElements.length - 1];
		currentObj[lastPathElement] = value;
	}
}
