import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class ScorecardMetricExpressionItem extends ExpressionItem {
	name: string;
	scorecardId: number;
	nodeId?: number;
	criteriaLevel: boolean;
	passing: boolean;

	constructor(
		name: string,
		displayName: string
	) {
		super(ExpressionPieces.SCORECARD_STUDIO_METRIC, displayName);
		this.name = name;
	}
}
