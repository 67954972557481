import { DragDropContainerScroller } from '@app/modules/scroll-carousel/scrollers/drag-drop-container-scroller.class';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { BookValidationService } from '@cxstudio/dashboards/books/book-validation.service';
import { IBookTab } from '@cxstudio/dashboards/entity/book';
import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';


interface IDraggableTab extends IBookTab {
	placeholder: boolean;
}

export class BookTabsEditorController implements ng.IController {
	private readonly LEFT_SCROLL_WIDTH = 40;
	private readonly RIGHT_SCROLL_WIDTH = 60;
	private readonly TAB_LIMIT = 20;

	tabs: IBookTab[];
	onChange: ({$tab: IBookTab}) => void;
	onTabClick: ({$tab: IBookTab}) => void;

	scroller: DragDropContainerScroller;

	constructor(
		private $scope: ng.IScope,
		private locale: ILocale,
		private $window: ng.IWindowService,
		private $timeout: ng.ITimeoutService,
		private bookValidationService: BookValidationService
	) {
	}

	$onInit(): void {
		this.scroller = new DragDropContainerScroller(this.$timeout);
		if (!_.isEmpty(this.tabs)) {
			if (!this.getCurrentTab())
				this.selectTab(this.tabs[0]);
			else this.scrollToTab(this.getCurrentTab());
		}

		this.$scope.$on('book:scrollToActiveTab', () => this.scrollToTab(this.getCurrentTab()));
	}

	getCurrentTab = (): IBookTab => {
		return _.find(this.tabs, {active: true});
	}

	tabNotLinked = (tab: IBookTab) => {
		return this.bookValidationService.tabEmpty(tab);
	}

	getTabWarning = (tab: IBookTab): string => {
		if (this.bookValidationService.tabNameInvalid(tab))
			return this.locale.getString('dashboard.emptyTabName');
		if (this.bookValidationService.tabEmpty(tab))
			return this.locale.getString('dashboard.tabEmpty');
		return '';
	}

	addNewTab = () => {
		if (this.isAddDisabled())
			return;
		let index = this.tabs.length;
		let tab = {
			name: this.locale.getString('dashboard.tab', {index: index + 1})
		} as IBookTab;
		this.tabs.push(tab);
		this.selectTab(tab);
	}

	addNewTabKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.addNewTab();
		}
	}

	isAddDisabled = (): boolean => {
		return this.tabs.length >= this.TAB_LIMIT;
	}

	removeTab = (tab: IBookTab) => {
		let index = this.tabs.indexOf(tab);
		this.tabs.remove(tab);
		if (_.isEmpty(this.tabs)) {
			this.addNewTab();
		} else {
			if (tab.active) // select next to it
				this.selectTab(this.tabs[Math.min(index, this.tabs.length - 1)]);
		}
	}

	selectTab = (tab: IBookTab) => {
		let previous = this.getCurrentTab();
		if (previous)
			previous.active = false;
		tab.active = true;
		this.onChange({$tab: tab});
		this.scrollToTab(tab);
	}

	private scrollToTab(tab: IBookTab): void {
		this.$timeout(() => this.scroller.scrollToItem(this.tabs.indexOf(tab)));
	}

	dragStop = (tab: IDraggableTab) => {
		if (tab) // sometimes is null
			tab.placeholder = false;
		this.scroller.stopScroll();
	}

	dragStart = (tab: IDraggableTab) => {
		this.selectTab(tab);
	}

	dragMove = (tab: IDraggableTab, event) => {
		if (event.tx !== 0 || event.ty !== 0) // to avoid flickering on click
			tab.placeholder = true;

		if (this.isInScrollArea(event.x)) {
			this.performScroll(event.x);
		} else {
			this.scroller.stopScroll();
		}

		// finds a proper place to insert current tab
		// logic is based on dragging over the center of an element,
		// i.e. if left/right border reaches the center of another tab, it's inserted into that place
		let clone = $('#tab-clone');
		let centerX = clone.position().left - this.LEFT_SCROLL_WIDTH + clone.width() / 2;
		let closestTab = this.findDropCandidate(centerX, event.element);
		let index = closestTab.index();

		if (index === -1) // current tab or outside
			return;

		let direction = index - this.tabs.indexOf(tab);
		let needChange = false;
		let targetCenter = closestTab.position().left + closestTab.width() / 2;

		let sourceWidth = event.dragOffset.width; // event.element.width() is not suitable because it's hidden while dragging
		if (direction > 0) {
			let rightBorder = centerX + sourceWidth / 2;
			needChange = rightBorder > targetCenter;
		} else {
			let leftBorder = centerX - sourceWidth / 2;
			needChange = leftBorder < targetCenter;
		}
		if (needChange) {
			this.tabs.remove(tab);
			this.tabs.insert(index, tab);
		}

	}

	private isInScrollArea(x: number): boolean {
		return x < this.LEFT_SCROLL_WIDTH || x > this.$window.innerWidth - this.RIGHT_SCROLL_WIDTH;
	}

	private performScroll(x: number): void {
		if (x < this.LEFT_SCROLL_WIDTH) {
			let speed = this.scroller.calculateSpeed(this.LEFT_SCROLL_WIDTH - x,
				this.LEFT_SCROLL_WIDTH);
			this.scroller.startSmoothScroll(-speed);
		} else {
			let speed = this.scroller.calculateSpeed(x - (this.$window.innerWidth - this.RIGHT_SCROLL_WIDTH),
				this.RIGHT_SCROLL_WIDTH);
			this.scroller.startSmoothScroll(speed);
		}
	}

	private findDropCandidate(centerX, draggingElement): JQuery {
		let container = $('#book-tabs-container');
		return container.find('.dashboard-tab').filter((index, element) => {
			if ($(element).is(draggingElement)) // current dragging item
				return false;
			let left = $(element).position().left;
			let right = left + $(element).width();
			return left <= centerX && right >= centerX;
		});
	}
}

app.component('bookTabsEditor', {
	controller: BookTabsEditorController,
	templateUrl: 'partials/dashboards/tabs/book-tabs-editor.component.html',
	bindings: {
		tabs: '<',
		onChange: '&',
		onTabClick: '&',
	}
});
