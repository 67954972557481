<li *ngIf="isVisible()"
	role="none"
	(click)="triggerClick($event)"
	[ngClass]="{'hierarchy-folder': isFolderType(),
		'hierarchy-leaf': !node.children,
		'selected': isNodeSelected()}">
	<div class="hierarchy-item"
			[ngClass]="getHierarchyItemClass()"
			[title]="getTooltip()"
			(click)="!folderClickIgnore && isFolder() && toggleFolder($event)"
			(keydown.arrowleft)="handleArrowLeft($event)"
			(keydown.arrowright)="isFolder() && expandFolder($event)"
			(keydown.arrowup)="moveFocus($event, TreeFocusMoveDirection.UP)"
			(keydown.arrowdown)="moveFocus($event, TreeFocusMoveDirection.DOWN)"
			(keydown.tab)="moveFocus($event, TreeFocusMoveDirection.FORWARD)"
			(keydown.shift.tab)="moveFocus($event, TreeFocusMoveDirection.BACKWARD)">
		<input type="checkbox"
			#checkbox
			role="treeitem"
			name="{{'inclusion-checkbox-node-' + node.id}}"
			*ngIf="showCheckboxes && showNodeCheckbox(node)"
			[attr.aria-owns]="getSubtreeId()"
			[attr.aria-expanded]="!!node._expanded || !!node._autoexpanded || (!hasChildren() && undefined)"
			[attr.aria-label]="node[displayProperty]"
			[attr.aria-checked]="isNodeChecked(node)"
			[disabled]="isNodeCheckboxDisabled(node)"
			[checkboxIndeterminate]="nodeIndeterminate(node)"
			[checked]="isNodeChecked(node)"
			indeterminateFollowedByUncheck="true"
			(click)="triggerClick($event)"
			(keydown.enter)="triggerClick($event)"/>
		<span *ngIf="isNodeMarked(node) && !isNodeChecked(node)"> *</span>
		<span class="br-hierarchy-node-toggle cursor-pointer"
				*ngIf="showExpansionToggle()"
				aria-hidden="true"
				[ngClass]="{'q-icon-add':(node.children && (!node._expanded && !node._autoexpanded)),
							'q-icon-minus':(node.children && (node._expanded || node._autoexpanded))}"
				(click)="folderClickIgnore && toggleFolder($event)">
		</span>

		<tree-item-template
			[item]="node"
			[showCheckboxes]="showCheckboxes"
			[displayProperty]="displayProperty"
			[limitedWidth]="limitedWidth"
			(onNodeClick)="triggerClick($event)"
			[itemTemplate]="itemTemplate">
		</tree-item-template>
		<cb-inline-help fixed="true" class="float-right" *ngIf="isNodeDisabled() && showHelper">
			<help-body>
				<p>{{'widget.unavailableAttributes'|i18n}}</p>
			</help-body>
		</cb-inline-help>
	</div>

	<subtree *ngIf="(node._expanded || node._autoexpanded) && hasChildren()"
		[groupId]="getSubtreeId()"
		[node]="node"
		[search]="search"
		[displayProperty]="displayProperty"
		[forcedOrder]="forcedOrder"
		[isDisabled]="isDisabled"
		[isSelected]="isSelected"
		[isNotRecommended]="isNotRecommended"
		[limitedWidth]="limitedWidth"
		[folderClickIgnore]="folderClickIgnore"
		[showNotRecommendedPrompt]="showNotRecommendedPrompt"
		[isVisibleOverrided]="isVisibleOverrided"
		[customValidation]="customValidation"
		(onNodeClick)="nodeClickHandler($event)"
		(onFolderExpand)="folderExpandHandler($event)"
		[showCheckboxes]="showCheckboxes"
		[showNodeCheckbox]="showNodeCheckbox"
		[isNodeCheckboxDisabled]="isNodeCheckboxDisabled"
		[nodeIndeterminate]="nodeIndeterminate"
		[isNodeChecked]="isNodeChecked"
		[isNodeMarked]="isNodeMarked"
		[getNodeTooltip]="getNodeTooltip"
		[isNodeHighlighted]="isNodeHighlighted"
		[setFocus]="setChildFocusSubject.asObservable()"
		(onFocusMove)="focusMoveHandler($event)"
		[optionClassFormatter]="optionClassFormatter"
		[itemTemplate]="itemTemplate"
	></subtree>
</li>
