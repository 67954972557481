<div [ngBusy]="loadingPromise">
	<form [formGroup]="qualtricsIntegration">
		<div class="rounded-grouping">
			<div class="rounded-grouping-label">
				<div class="labeled-checkbox">
					<checkbox-button id="enableQualtricsIntegration"
						formControlName="enabled"
						label="{{'mAccount.enableQualtricsIntegration'|i18n}}">
					</checkbox-button>
				</div>
			</div>

			<div class="form-group">
				<label for="aliasName" class="control-label col-md-3">{{'mAccount.qualtricsBrandAlias'|i18n}}</label>
				<div class="col-md-8">
					<input id="brandAliasName" type="text" class="form-control"
						formControlName="aliasName" placeholder="">
					<span *ngIf="aliasName?.errors?.required && aliasName?.touched" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsBrandAlias') }}
					</span>
				</div>
			</div>


			<div class="form-group">
				<label for="brandId" class="control-label col-md-3">{{'mAccount.qualtricsBrandId'|i18n}}</label>
				<div class="col-md-8">
					<input id="brandId"
						type="text"
						class="form-control"
						formControlName="brandId"
						autocomplete="off"
						(change)="onInputChanged.next($event)">
					<span class="help-block">{{'common.fieldCaseSensitive'|i18n}}</span>
					<span *ngIf="brandId?.errors?.required && brandId?.touched" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsBrandId') }}
					</span>
					<span *ngIf="!isBrandIdValid()" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{'mAccount.qualtricsBrandIdExists'|i18n}}
					</span>
				</div>
			</div>

			<div class="form-group">
				<label for="datacenter" class="control-label col-md-3">{{'mAccount.qualtricsDataCenter'|i18n}}</label>
				<div class="col-md-8">
					<input id="datacenter" type="text" class="form-control"
						formControlName="datacenter" placeholder="">
					<span *ngIf="datacenter?.errors?.required && datacenter?.touched" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsDataCenter') }}
					</span>
				</div>
			</div>

			<div class="form-group">
				<label for="loginUrl" class="control-label col-md-3">{{'mAccount.qualtricsLoginUrl'|i18n}}</label>
				<div class="col-md-8">
					<input id="loginUrl" type="text" class="form-control"
						formControlName="loginUrl" placeholder="">
					<span *ngIf="loginUrl?.errors?.required && loginUrl?.touched" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsLoginUrl') }}
					</span>
				</div>
			</div>
			<div class="labeled-rule text-center">
				<hr/>
				<div>
					<label>{{'mAccount.qualtricsTicketing'|i18n}}</label>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-md-3">{{'mAccount.enableQualtricsTicketing'|i18n}}</label>
				<div class="col-md-8">
					<div class="labeled-checkbox mb-0">
						<input
							type="checkbox"
							id="enableQualtricsTicketing"
							name="enableQualtricsTicketing"
							formControlName="ticketingEnabled"/>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="ticketingApplication" class="control-label col-md-3">{{'mAccount.qualtricsTicketingApplication'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown
						class="form-control border-0 p-0"
						id="ticketingApplication"
						[options]="ticketingApplications"
						formControlName="ticketingApplicationId"
						displayField="name"
						valueField="id"
						sortField="name">
					</simple-dropdown>
					<span *ngIf="ticketingApplicationId?.errors?.required" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsTicketingApplication') }}
					</span>
				</div>
			</div>

			<div class="labeled-rule text-center">
				<hr/>
				<div>
					<label>{{'mAccount.qualtricsAuthSettings'|i18n}}</label>
				</div>
			</div>

			<div formGroupName="authSettings">
				<div class="form-group" [class.has-error]="ssoProviderName?.errors?.required">
					<label for="ssoExternalProvider" class="control-label col-md-3">{{'mAccount.qualtricsSSOProvider'|i18n}}</label>
					<div class="col-md-3">
						<simple-dropdown
							class="form-control p-0 border-0"
							id="ssoExternalProvider"
							[options]="ssoExternalProviders"
							formControlName="ssoProviderName"
							displayField="aliasName"
							valueField="aliasName"
							sortField="aliasName">
						</simple-dropdown>
						<span *ngIf="ssoProviderName?.errors?.required" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
							{{ formUtils.getErrorMessage('mAccount.qualtricsSSOProvider') }}
						</span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="enforceXmAuthentication" class="control-label col-md-3">{{'mAccount.enforceXmAuthentication'|i18n}}</label>
				<div class="col-md-8">
					<div class="labeled-checkbox mb-0">
						<input
							type="checkbox"
							id="enforceXmAuthentication"
							name="enforceXmAuthentication"
							formControlName="enforceXmAuthenticationEnabled"/>
					</div>
				</div>
			</div>

			<div class="labeled-rule text-center">
				<hr/>
				<div>
					<label>{{'mAccount.userSync'|i18n}}</label>
				</div>
			</div>


			<div class="form-group">
				<label for="enableUserSync" class="control-label col-md-3">{{'mAccount.enableUserSync'|i18n}}</label>
				<div class="col-md-8">
					<div class="labeled-checkbox mb-0">
						<input
							type="checkbox"
							id="enableUserSync"
							name="enableUserSync"
							formControlName="userSyncEnabled"/>
					</div>
				</div>
			</div>

		</div>
	</form>
	<form [formGroup]="qualtricsApiIntegration">
		<div class="rounded-grouping">
			<div class="rounded-grouping-label">
				<div class="labeled-checkbox">
					<checkbox-button id="enableQualtricsApiIntegration"
						formControlName="enabled"
						label="{{'mAccount.qualtricsApiEnableIntegration'|i18n}}">
					</checkbox-button>
				</div>
			</div>
			<div class="form-group" [class.has-error]="providerId?.errors?.required">
				<label for="apiProvider" class="control-label col-md-3">{{'mAccount.qualtricsApiProvider'|i18n}}</label>
				<div class="col-md-3">
					<simple-dropdown
						class="form-control p-0 border-0"
						id="apiProvider"
						[options]="apiProviders"
						formControlName="providerId"
						displayField="aliasName"
						valueField="id"
						sortField="aliasName">
					</simple-dropdown>
					<span *ngIf="providerId?.errors?.required" class="text-danger d-flex align-center"><span class="mr-4 q-icon-warning" aria-hidden="true"></span>
						{{ formUtils.getErrorMessage('mAccount.qualtricsApiProvider') }}
					</span>
				</div>
			</div>
		</div>
	</form>
	<form [formGroup]="qualtricsIntegration" *ngIf="qualtricsIntegration.enabled || qualtricsApiIntegration.enabled">
		<div class="form-group">
			<label for="ticketLink" class="control-label col-md-3">{{'mAccount.ticketLink'|i18n}}</label>
			<div class="col-md-8">
				<input id="ticketLink" type="text" class="form-control"
					   formControlName="ticketLink">
				<span *ngIf="integrationTicketLink?.errors?.required && integrationTicketLink?.touched" class="text-danger"><icon name="warning"></icon>
					{{ formUtils.getErrorMessage('widget.link') }}
					</span>
			</div>
		</div>
	</form>
</div>
