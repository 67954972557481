import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ContentProviderFeature } from '@app/modules/system-administration/content-provider/content-provider-feature';
import { ContentProviderApi } from '@app/modules/system-administration/content-provider/content-provider.api.service';

@Injectable({ providedIn: 'root' })
export class ContentProviderFeatureService {

	constructor(
		private contentProviderApi: ContentProviderApi,
	) {}

	isFeatureSupported(contentProviderId: number, feature: ContentProviderFeature): Promise<boolean> {
		return this.contentProviderApi.getContentProviderFeatureVersion(contentProviderId).then(featureVersion => {
			return this.getVersionNumber(featureVersion) >= feature;
		});
	}

	private getVersionNumber(featureVersion: string): number {
		let versionRegexp = /^v(\d+)$/;
		return Number(versionRegexp.exec(featureVersion)[1]);
	}
}

app.service('contentProviderFeatureService', downgradeInjectable(ContentProviderFeatureService));
