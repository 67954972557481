import * as Highcharts from 'highcharts';
import { BubbleUtils } from '../settings/bubble-utils.class';
import { HighchartsZoomUtil } from '../utils/highchart/highcharts-zoom-util';
import { BubbleLegendProcessor } from '../utils/highchart/legend/bubble-legend-processor';

/**
 * Function should be named in camelCase
 * params - required services (auto-injected)
 */
app.directive('highchartsBubble', (tableService,
	reportUtils, reportScopeUtils, highchartsDefinitionFactory, reportDefinitionInitializers,
	pointSelectionUtils) => {
	return {
		restrict: 'A',
		replace: true,
		template: '<div>Error</div>',
		scope: {
			options: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},
		link: (scope, element) => {
			scope.selectedPoint = null;
			reportUtils.initDestroyListener(scope);
			if (_.isUndefined(scope.utils)) {
				scope.utils = {widgetType: 'undefined'};
			}

			let renderChart = () => {
				// destroy chart if it already exists
				if (scope.chart && scope.chart.container)
					scope.chart.destroy();

				reportDefinitionInitializers.initializeSize(scope);
				let definition = highchartsDefinitionFactory.get(scope, element);
				if (!definition) {
					return;
				}

				let chartOptions = definition.getChartOptions();

				// rescales bubble charts ONLY if applicable and necessary -- no need to precheck if applicable
				BubbleUtils.adjustScale(chartOptions, scope.options);

				scope.chart = new Highcharts.Chart(chartOptions,
					pointSelectionUtils.highlightSelectedPoints(scope.utils.containerId, scope.utils.widgetId,
						scope.utils.getGroupings(), scope.demo));

				let destroyChart = scope.$on('$destroy', () => {
					// destroy chart if it has been rendered
					if (scope.chart.container) {
						scope.chart.destroy();
					}
					destroyChart();
				});
			};
			scope.$watch('trigger', reportUtils.chartTriggerHandler(scope, renderChart));

			// we need to watch 'trigger' before this return.
			if (!tableService.processIfNoData(element, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			if (_.isUndefined(scope.options.xAxis)) {
				scope.options.xAxis = 'volume';
			}
			if (_.isUndefined(scope.options.yAxis)) {
				scope.options.yAxis = 'sentiment';
			}

			scope.options.decimal = true;

			renderChart();

			reportUtils.initResizeHandler(scope, element, reportUtils.chartResizeHandler(scope));

			if (!scope.demo) {
				reportScopeUtils.emitContextMenuHandling(scope, element);
				HighchartsZoomUtil.applyZoom(scope.chart, scope.options);
				if (scope.options.zoom) {
					BubbleLegendProcessor.hideOuterLegends(scope, scope.options.zoom);
				}

				BubbleLegendProcessor.hideSavedLegends(scope);
			}
		} // link end
	};
});
