import { Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import IndeterminateTopicSelection from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.interface';
import { IndeterminateTopicSelectionImpl } from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.class';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { ModelGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { TagsService } from '../properties/tags.service';
import { ModelsService } from '@app/modules/project/model/models.service';


export interface ISpineMetricSettingsModalInput {
	grouping: TopicReportGrouping;
	projectSelection: IProjectSelection;
}

@Component({
	selector: 'spine-metric-settings-modal',
	template: `
	<modal-header
		(cancel)="cancel()"
		[modalTitle]="modalTitle">
	</modal-header>
	<div class="modal-body">
		<div class="row">
			<div class="col-sm-12 form-group" *ngIf="!isTopic()">
				<label>{{'widget.limitTo'|i18n}}</label>
				<words-selection
					[project]="input.projectSelection"
					[item]="input.grouping"
					[includeOnly]="true">
				</words-selection>
			</div>
		</div>
		<div *ngIf="isTopic()">
			<div class="row d-flex" [ngBusy]=loadingTree>
				<div class="ml-16">
					<label for="selectedLevel">{{'widget.level'|i18n}}</label>
					<div class="mb-16 p-8">
						<simple-dropdown
							[(value)]="input.grouping.selectedLevel"
							id="selectedLevel"
							[options]="levels"
							[disabled]="input.grouping.inheritTopics"
							displayField="displayName"
							noMinWidth=true
							(valueChange)="levelChanged()">
						</simple-dropdown>
					</div>
				</div>
				<div class="ml-16 flex-fill">
					<label class="pl-8">{{'widget.inclusionList'|i18n}}</label>
					<a data-testid="deselect-all" class="pull-right" (click)="topicSelection.deselectAll()" *ngIf="!input.grouping.inheritTopics">{{'administration.selectNone'|i18n|lowercase}}</a>
					<a data-testid="select-all" class="pull-right block" (click)="topicSelection.selectAll()" *ngIf="!input.grouping.inheritTopics">{{'common.selectAll'|i18n|lowercase}}</a>
					<searchable-tree
						name="inclusion-list"
						class="topic-inclusion-list"
						[hierarchyList]="[modelTree]"
						(onNodeClick)="topicSelection.handleNodeClick($event.node)"
						placeholder="{{'common.search'|i18n}}"
						displayProperty="name"
						[showNodeCheckbox]="topicSelection.isNodeClickable"
						[nodeIsChecked]="topicSelection.isNodeChecked"
						[nodeIndeterminate]="topicSelection.isIndeterminateNode"
						[nodeIsHighlighted]="topicSelection.isNodeHighlighted"
						folderClickIgnore="true"
						[nodeCheckboxDisabled]="isNodeCheckboxDisabled">
					</searchable-tree>
				</div>
			</div>
			<div class="row mt-16">
				<checkbox-button
					label="{{'widget.inheritTopics'|i18n}}"
					[(ngModel)]="input.grouping.inheritTopics"
					(ngModelChange)="onInheritTopicsChange()">
				</checkbox-button>
			</div>
		</div>
	</div>
	<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
		[saveText]="'common.update' | i18n"
		[isDisabled]="hasNoSelection() && !isTopic()"
	></save-modal-footer>`
})
export class SpineMetricSettingsModalComponent implements OnInit {

	@Input() input: ISpineMetricSettingsModalInput;
	modalTitle: string;

	//for topic
	loadingTree: Promise<any>;
	modelTree: any;
	levels: any[];
	topicSelection: IndeterminateTopicSelection;
	checkedNodes: any[] = [];

	constructor(
		private tagService: TagsService,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		private reportSettingsService: ReportSettingsService,
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
		private modelsService: ModelsService
	) {}

	ngOnInit(): void {
		this.modalTitle = `${this.input.grouping.displayName}: ${this.locale.getString('common.settings')}`;
		if (this.isTopic()) {
			this.initTopicSettings();
		}
	}

	hasNoSelection(): boolean {
		return _.isEmpty(this.input.grouping.wordsList);
	}

	save(): void {
		if (this.isTopic()) {
			this.input.grouping.selectedNodes = _.map(this.checkedNodes, (node) => node.id);
			this.modal.close(this.input.grouping);
			return;
		}
		this.modal.close(this.tagService.tagObjectsToStringArray(this.input.grouping.wordsList));
	}

	cancel(): void {
		this.modal.dismiss();
	}

	initTopicSettings(): void {
		let topicGrouping = this.input.grouping;
		this.levels = undefined;
		this.loadingTree = this.getModelTree().then(tree => {
			this.levels = [];
			this.modelTree = tree.root;
			let maxLevels = this.hierarchyService.getDepth(tree.root);
			for (let i = 1; i <= maxLevels; i++) {
				this.levels.push({value: i, displayName: i});
			}
			this.levels.push({value: TopicReportGrouping.LEAF_LEVEL, displayName: this.locale.getString('widget.leaf')});
			this.applyModelDefaults();
		});

		this.topicSelection = new IndeterminateTopicSelectionImpl({
			getSelectedNodes: () => topicGrouping.selectedNodes,
			setSelectedNodes: (selectedNodes) => {
				topicGrouping.selectedNodes = selectedNodes;
			},
			setCurrentSelection: (selection) => {
				this.checkedNodes = selection;
			},
			getCurrentSelection: () => this.checkedNodes,
			getModelTree: () => this.modelTree,
			getSelectedLevels: () => [topicGrouping.selectedLevel],
			isTopicLeafEnabled: () => false,
			isIndeterminateDisabled: () => false
		});
	}

	isTopic(): boolean {
		return this.input.grouping?.model;
	}

	isNodeCheckboxDisabled = (node) => {
		let topicGrouping = this.input.grouping;
		return !!topicGrouping.inheritTopics;
	}

	getModelTree(): Promise<any> {
		return this.modelsService.getModelTree(this.input.projectSelection, parseInt(this.input.grouping.name, 10));
	}

	levelChanged = () => {
		this.topicSelection.updateSelectedLevel();
	}

	private checkDefaultSelectedLevel = () => {
		let topicGrouping = this.input.grouping;
		topicGrouping.selectedLevel = (topicGrouping.selectedLevel as any !== 'all')
			? topicGrouping.selectedLevel
			: 1;
	}

	applyModelDefaults = () => {
		let topicGrouping = this.input.grouping;
		if (topicGrouping.inheritTopics) {
			let widgetProject = {
				contentProviderId: this.input.projectSelection.contentProviderId,
				accountId: this.input.projectSelection.accountId,
				project: this.input.projectSelection.projectId
			} as WidgetProject;
			this.loadingTree = this.reportSettingsService.getGroupingSettings(widgetProject, this.input.grouping).then((settings) => {
				const modelSettings = settings as ModelGroupingSettings;
				topicGrouping.selectedLevel = modelSettings ? modelSettings.selectedLevel : 1;
				topicGrouping.selectedNodes = modelSettings ? modelSettings.selectedNodes : undefined;

				this.levelChanged();
				this.checkDefaultSelectedLevel();
				this.topicSelection.processInclusionList();
			});
		} else {
			topicGrouping.selectedLevel = topicGrouping.selectedLevel || 1;
			topicGrouping.selectedNodes = topicGrouping.selectedNodes || undefined;
			this.topicSelection.processInclusionList();
		}
	}

	onInheritTopicsChange = () => {
		this.applyModelDefaults();
	}
}
