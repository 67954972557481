<div class="panel panel-default">
	<div class="panel-heading">
		<span class="alert alert-sm mr-16"
			[ngClass]="buildStateClassObject(session.state)">{{session.state}}</span>
		<strong>{{'upgrade.session'|i18n}} {{session.id}}</strong>.&nbsp;
			<span *ngIf="!session.performedAutomatically">{{'upgrade.performedBy'|i18n}}&nbsp;{{session.performer}}</span>
			<span *ngIf="session.performedAutomatically">{{'upgrade.performedAutomatically'|i18n}}</span>
			&nbsp;{{'upgrade.atTime'|i18n}} {{session.creationDate}}
	</div>
	<div class="panel-body">
		<div *ngFor="let componentState of session.upgradeComponentStates"
			class="d-flex align-items-center pv-8">
			<span class="alert alert-sm mr-16"
				[ngClass]="buildStateClassObject(componentState.state)">
				{{buildComponentStateLabel(componentState)}}
			</span>
			{{componentState.componentId}}
			<span *ngIf="componentState.owner"
				class="text-gray-600 ml-8 white-space-pre-wrap">
				(by <strong>{{componentState.owner}}</strong>)
			</span>
			<span *ngIf="componentState.message"
				class="text-gray-600 ml-16 white-space-pre-wrap"
				[innerHTML]="componentState.message">
			</span>

			<button *ngIf="canReject(componentState)"
				class="btn btn-primary btn-sm ml-24"
				(click)="rejectComponent(componentState.sessionId, componentState.componentId)">
				<span class="q-icon-delete"></span> {{'upgrade.rejectComponent'|i18n}}
			</button>
		</div>
	</div>
</div>
