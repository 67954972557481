import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class AttributeExpressionItem extends ExpressionItem {
	name: string;
	operator: string;

	constructor(
		name: string,
		operator: string,
		displayName: string
	) {
		super(ExpressionPieces.ATTRIBUTE, displayName);
		this.name = name;
		this.operator = operator;
	}

}
