<div class="d-flex mt-16" [ngClass]="getWrapperClasses()" data-testid="project-selector">
	<div *ngIf="!workspace.hasSingleOption()" [ngClass]="getGroupClasses()">
		<label *ngIf="hasLabel()" class="control-label" [ngClass]="getLabelClasses()">{{'common.workspace'|i18n}}
			<cb-inline-help *ngIf="help" right>
				<help-body>{{'dashboard.providerHelp'|i18n}}</help-body>
			</cb-inline-help>
		</label>
		<div [ngClass]="getSelectorClasses()" [class.pl-8]="isLeft()">
			<simple-dropdown
				[ngClass]="getSelectorClasses()"
				data-testid="workspaces-list"
				[options]="workspace.getOptions()"
				[value]="workspace.getSelection()"
				(valueChange)="workspace.changeSelection($event)"
				displayField="name"
				valueField="id"
				role="listbox"
				[attr.aria-label]="'common.workspace'|i18n"
				[selectPrompt]="getWorkspacePrompt()"
			></simple-dropdown>
		</div>
	</div>
	<div class="d-flex" [ngClass]="getGroupClasses()">
		<label *ngIf="hasLabel()" class="control-label" [ngClass]="getLabelClasses()">{{'common.project'|i18n}}
			<cb-inline-help *ngIf="help" right>
				<help-body>{{'dashboard.projectHelp'|i18n}}</help-body>
			</cb-inline-help>
		</label>
		<div *ngIf="customProjectPromptLabel" class="mb-8 text-gray-800" [i18n]="customProjectPromptLabel"></div>
		<div [ngClass]="getSelectorClasses()" [class.pl-8]="isLeft()">
			<simple-dropdown
				data-testid="projects-list"
				[ngClass]="getSelectorClasses()"
				[options]="project.getOptions()"
				[value]="project.getSelection()"
				(valueChange)="project.changeSelection($event || -1)"
				displayField="name"
				valueField="id"
				[disableSort]="true"
				[disabled]="!workspace.isSelected() || !project.hasOptions()"
				[selectPrompt]="getProjectPrompt()"
				[allowClear]="allProjects"
				role="listbox"
				[attr.aria-label]="'common.project'|i18n"
			></simple-dropdown>
		</div>
	</div>
</div>
<div *ngIf="!externalErrors">
	<alert *ngFor="let error of errors" type="danger">
		<span>{{ error }}</span>
	</alert>
</div>
