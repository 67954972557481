import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import * as cloneDeep from 'lodash.clonedeep';
import { PredefinedMetricConstants } from './predefined/predefined-metric-constants';
import { MetricCalculationsTypeClass } from './metric-calculations-type.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MathMetricDefinition } from '@app/modules/metric/definition/math-metric-definition';
import { MetricDefinition } from './entities/metric-definition';
import { PredefinedMetricDefinition } from '@app/modules/metric/definition/predefined-metric-definition';
import { MetricCalculation } from '@app/modules/metric/definition/color-metric-definition';
import { BoxMetricDefinition } from '@app/modules/metric/definition/box-metric-definition';

export enum MetricDefinitionTypes {
	TOP_BOX = 'TOP_BOX',
	BOTTOM_BOX = 'BOTTOM_BOX',
	SATISFACTION = 'SATISFACTION',
	FILTER_METRIC = 'FILTER_METRIC',
	VARIABLE = 'VARIABLE',
	CUSTOM_MATH = 'CUSTOM_MATH',
	EASE_SCORE = 'EASE_SCORE',
	SENTIMENT = 'SENTIMENT',
	EMOTION  = 'EMOTION'
}
export class MetricCalculationsTypeService {

	TOP_BOX: MetricCalculationsTypeClass;
	BOTTOM_BOX: MetricCalculationsTypeClass;
	SATISFACTION: MetricCalculationsTypeClass;
	FILTER_METRIC: MetricCalculationsTypeClass;
	VARIABLE: MetricCalculationsTypeClass;
	CUSTOM_MATH: MetricCalculationsTypeClass;
	EASE_SCORE: MetricCalculationsTypeClass;
	SENTIMENT: MetricCalculationsTypeClass;
	EMOTION: MetricCalculationsTypeClass;


	constructor(
		private readonly locale: ILocale
	) {
		this.TOP_BOX = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.topBoxDefaultTitle'),
			displayName: this.locale.getString('metrics.topBoxType'),
			is: MetricCalculationsTypeClass.isType([MetricType.TOP_BOX]) as (m: any) => m is MetricType.TOP_BOX,
			returnObject: (displayName, attribute, options) => {
				if (!options.data || options.data.thresholds.length !== 1
					|| options.data.colorPalette.length !== 2) {
					return;
				}

				return {
					displayName,
					definition: {
						name: attribute.name,
						topDisplayName: options.topBoxName,
						topThreshold: options.data.thresholds[0],
						topColor: options.data.colorPalette[1],
						otherDisplayName: options.otherBoxName,
						otherColor: options.data.colorPalette[0],
						min: options.min,
						max: options.max,
						type: MetricType.TOP_BOX,
						calculation: options.calculation
					}

				};
			},
			getData: (definition: BoxMetricDefinition): Partial<MetricCalculation> => {
				return {
					thresholds: [definition.topThreshold],
					colorPalette: [definition.otherColor, definition.topColor]
				};
			},
			getCalculation: this.getDefaultCalculation
		});

		this.BOTTOM_BOX = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.bottomBoxDefaultTitle'),
			displayName: this.locale.getString('metrics.bottomBoxType'),
			is: MetricCalculationsTypeClass.isType([MetricType.BOTTOM_BOX]) as (m: any) => m is MetricType.BOTTOM_BOX,
			returnObject: (displayName, attribute, options) => {
				if (!options.data || options.data.thresholds.length !== 1
					|| options.data.colorPalette.length !== 2) {
					return;
				}

				return {
					displayName,
					definition: {
						name: attribute.name,
						bottomDisplayName: options.bottomBoxName,
						bottomThreshold: options.data.thresholds[0],
						bottomColor: options.data.colorPalette[0],
						otherDisplayName: options.otherBoxName,
						otherColor: options.data.colorPalette[1],
						min: options.min,
						max: options.max,
						type: MetricType.BOTTOM_BOX,
						calculation: options.calculation
					}

				};
			},
			getData: (definition): Partial<MetricCalculation> => {
				return {
					thresholds: [definition.bottomThreshold],
					colorPalette: [definition.bottomColor, definition.otherColor]
				};
			},
			getCalculation: (definition: MetricDefinition) => {
				if (!definition || !MetricCalculationsTypeClass.validateCalculation(definition.calculation)) {
					return {
						thresholds: cloneDeep(MetricCalculationsTypeClass.defaultThresholds),
						colorPalette: [ColorConstants.GREY, ColorConstants.RED],
						min: 0,
						max: 1
					};
				} else {
					return definition.calculation;
				}
			}
		});

		this.SATISFACTION = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.satisfactionDefaultTitle'),
			displayName: this.locale.getString('metrics.satisfactionType'),
			is: MetricCalculationsTypeClass.isType([MetricType.SATISFACTION]) as (m: any) => m is MetricType.SATISFACTION,
			calculationMin: -1,
			returnObject: (displayName, attribute, options) => {
				if (!options.data || options.data.thresholds.length !== 2
					|| options.data.colorPalette.length !== 3) {
					return;
				}
				return {
					displayName,
					definition: {
						name: attribute.name,
						bottomDisplayName: options.bottomBoxName,
						bottomThreshold: options.data.thresholds[0],
						bottomColor: options.data.colorPalette[0],
						middleDisplayName: options.neutralBoxName,
						middleColor: options.data.colorPalette[1],
						topDisplayName: options.topBoxName,
						topThreshold: options.data.thresholds[1],
						topColor: options.data.colorPalette[2],
						min: options.min,
						max: options.max,
						type: MetricType.SATISFACTION,
						calculation: options.calculation
					}
				};
			},
			getData: (definition: BoxMetricDefinition): Partial<MetricCalculation> => {
				return {
					thresholds: [definition.bottomThreshold, definition.topThreshold],
					colorPalette: [definition.bottomColor, definition.middleColor, definition.topColor]
				};
			},
			getCalculation: (definition: MetricDefinition) => {
				if (!definition || !MetricCalculationsTypeClass.validateCalculation(definition.calculation)) {
					return {
						thresholds: cloneDeep(MetricCalculationsTypeClass.defaultNPSThresholds),
						colorPalette: [ColorConstants.RED, ColorConstants.GREY, ColorConstants.GREEN],
						min: -1,
						max: 1
					};
				} else {
					return definition.calculation;
				}
			}
		});

		this.FILTER_METRIC = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.filterMetric'),
			displayName: this.locale.getString('metrics.filterMetric'),
			calculationMin: 0,
			is: MetricCalculationsTypeClass.isType([MetricType.FILTER]) as (m: any) => m is MetricType.FILTER,
			returnObject: (displayName, attribute, options) => {
				//TODO: add this when add calcualtion color to percent in list
				/*if (!options.data || options.data.thresholds.length !== 2
					|| options.data.colorPalette.length !== 3){
					return;
				}*/
				if (!options.filterObject || !options.filterObject.filterRules) {
					return;
				}

				let definition: any = MetricCalculationsTypeClass.filterObjectToDefinition(options);
				if (options.calculations) {
					definition.calculationName = options.calculations.name;
					definition.calculationOperator = options.calculationOperator;
				}
				definition.calculation = options.calculation;

				return {
					displayName,
					definition,
					format: options.format
				};
			},
			getCalculation: this.getDefaultCalculation
		});

		this.VARIABLE = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.variable'),
			displayName: this.locale.getString('metrics.variable'),
			is: MetricCalculationsTypeClass.isType([MetricType.VARIABLE]) as (m: any) => m is MetricType.VARIABLE,
			returnObject: (displayName, attribute, options) => {
				return {
					displayName,
					definition: {
						type: MetricType.VARIABLE,
						defaultValue: options.defaultValue
					},
					format: options.format
				};
			}
		});

		this.CUSTOM_MATH = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.customMetricDefaultName'),
			displayName: this.locale.getString('metrics.custom'),
			is: MetricCalculationsTypeClass.isType([MetricType.CUSTOM_MATH]) as (m: any) => m is MetricType.CUSTOM_MATH,
			returnObject: (displayName, attribute, options) => {
				if (!options.expressionArray || !options.expressionArray.length) {
					return;
				}

				let definition = {
					mathComponents: options.expressionArray,
					type: MetricType.CUSTOM_MATH,
					calculation: options.calculation,
					replaceNullsWithZeroes: options.replaceNullsWithZeroes
				};

				return {
					displayName,
					definition,
					format: options.format
				};
			},
			getExpressionArray: (definition: MathMetricDefinition) => definition.mathComponents,
			getCalculation: this.getDefaultCalculation
		});

		this.EASE_SCORE = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.easeScore'),
			displayName: this.locale.getString('metrics.easeScore'),
			is: MetricCalculationsTypeClass.isType([MetricType.EASE_SCORE]) as (m: any) => m is MetricType.EASE_SCORE,
			disabled: true,
			returnObject: (displayName, attribute, options) => {
				let definition = {
					name: PredefinedMetricConstants.EASE_SCORE,
					thresholds: options.data.thresholds,
					colorPalette: options.data.colorPalette,
					type: MetricType.EASE_SCORE,
					calculation: options.calculation
				};

				return {definition};
			},
			getData: this.getDefaultData,
			getCalculation: (definition: MetricDefinition) => {
				let calculation = cloneDeep(definition.calculation);
				return _.extend(calculation, {dividers: [false, true, true, false]});
			}
		});

		this.SENTIMENT = new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString('metrics.sentiment'),
			displayName: this.locale.getString('metrics.sentiment'),
			is: MetricCalculationsTypeClass.isType([MetricType.SENTIMENT]) as (m: any) => m is MetricType.SENTIMENT,
			disabled: true,
			returnObject: (displayName, attribute, options) => {
				let definition = {
					name: PredefinedMetricConstants.SENTIMENT,
					thresholds: options.data.thresholds,
					colorPalette: options.data.colorPalette,
					type: MetricType.SENTIMENT,
					calculation: options.calculation
				};

				return {definition};
			},
			getData: this.getDefaultData,
			getCalculation: (definition: MetricDefinition) => {
				let calculation = cloneDeep(definition.calculation);
				return _.extend(calculation, {dividers: [false, true, true, false]});
			}
		});

		this.EMOTION = this.getNumericBreakdownType(PredefinedMetricConstants.EMOTION);
	}



	private getNumericBreakdownType = (name) => {
		return new MetricCalculationsTypeClass({
			defaultTitle: this.locale.getString(`metrics.${name}`),
			displayName: this.locale.getString(`metrics.${name}`),
			is: MetricCalculationsTypeClass.isNumericType(name),
			disabled: true,
			returnObject: (displayName, attribute, options) => {
				let definition = {
					name,
					thresholds: options.data.thresholds,
					colorPalette: options.data.colorPalette,
					type: MetricType.NUMERIC_BREAKDOWN,
					calculation: options.calculation
				};

				return { definition };
			},
			getData: (definition: PredefinedMetricDefinition) => {
				let displayNames = _.map(definition.displayNames, (innerName) => {
					return `metrics.${definition.name}_${innerName}`;
				});
				return {
					thresholds: definition.thresholds,
					dividers: [true, true],
					colorPalette: definition.colorPalette,
					displayNames
				};
			},
			getCalculation: (definition: MetricDefinition) => {
				let calculation = cloneDeep(definition.calculation);
				return _.extend(calculation, {dividers: [false, false]});
			}
		});
	}


	private readonly getDefaultCalculation = (definition: MetricDefinition) => {
		if (!definition || !MetricCalculationsTypeClass.validateCalculation(definition.calculation)) {
			return {
				thresholds: cloneDeep(MetricCalculationsTypeClass.defaultThresholds),
				colorPalette: [ColorConstants.GREY, ColorConstants.GREEN],
				min: 0,
				max: 1
			};
		} else {
			return definition.calculation;
		}
	}

	private readonly getDefaultData = (definition: PredefinedMetricDefinition) => {
		let displayNames = _.map(definition.displayNames, (name) => {
			return `metrics.${definition.name}_${name}`;
		});
		return {
			thresholds: definition.thresholds,
			dividers: [false, true, true, false],
			colorPalette: definition.colorPalette,
			displayNames
		};
	}
}


app.service('MetricCalculationsType', MetricCalculationsTypeService);
