import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { Security } from '@cxstudio/auth/security-service';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { SigningAlgorithm } from '@app/modules/system-administration/saml-settings/entities/saml-signinig-algorithms';

export interface SamlServiceProviderDialogInput {
	serviceProvider: SamlServiceProvider;
	allServiceProviders: SamlServiceProvider[];
}

@Component({
	selector: 'saml-service-provider-dialog',
	templateUrl: './saml-service-provider-dialog.component.html'
})

export class SamlServiceProviderDialogComponent implements OnInit, AfterViewInit {
	@Input() input: SamlServiceProviderDialogInput;

	@ViewChild('serviceProviderForm', {static: false}) serviceProviderForm: NgForm;
	@ViewChild('keyPairUploaderButton', {static: true}) keyPairUploaderButton: ElementRef;

	private serviceProviderFormStatus$: Subscription;

	oauthClientDetails: OAuthClientDetails[];

	keyPairFile: File;
	keyPairUploader: FileUploader;
	keyPairUploadError: string;

	serviceProvider: SamlServiceProvider;
	invalidFields: any[] = [];
	serviceProviderAlreadyExists: boolean = false;

	readonly ALIAS_NAME_PATTERN = /^[_a-zA-Z0-9-]+$/;
	readonly ENTITY_ID_PATTERN = /^[_a-zA-Z0-9-:/]+$/;

	signingAlgorithms: ListOption<string>[] = [
		{ value: SigningAlgorithm.SHA_1, name: this.locale.getString('mAccount.labelSignatureAlgorithmSHA1') },
		{ value: SigningAlgorithm.SHA_256, name: this.locale.getString('mAccount.labelSignatureAlgorithmSHA256') },
		{ value: SigningAlgorithm.SHA_512, name: this.locale.getString('mAccount.labelSignatureAlgorithmSHA512') }
	];

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('urlService') private urlService: UrlService,
		@Inject('security') private security: Security
	) {
	}

	onAliasNameInput = _.debounce(() => this.validateAliasName(), 300);
	onEntityIdInput =  _.debounce(() => this.validateEntityId(), 300);

	ngOnInit(): void {
		this.serviceProvider = cloneDeep(this.input.serviceProvider);

		this.keyPairUploader = new FileUploader({
			url: this.urlService.getAPIUrl(`rest/saml/service-provider/key-pair/upload`),
			itemAlias: 'file',
			filters: [{
				name: 'p12',
				fn: (item, options) => item?.name?.indexOf('.p12', item.name.length - '.p12'.length) !== -1
			}],
			headers: [{ name: 'ma-id', value: this.security.getMasterAccountId().toString() }]
		});

		this.keyPairUploader.onSuccessItem = (item, response: string, status, headers) => {
			this.serviceProvider.keyPair.filename = response;
			this.keyPairUploadError = '';
		};

		this.keyPairUploader.onWhenAddingFileFailed = (item, filter, options) => {
			this.keyPairUploadError = this.locale.getString('common.unsupportedFileFormat');
			this.serviceProvider.keyPair.filename = '';
			delete this.keyPairFile;
		};

		this.keyPairUploader.onErrorItem = (fileItem, response, status, headers) => {
			this.keyPairUploadError = this.locale.getString('common.uploadFailed');
			this.serviceProvider.keyPair.filename = '';
			delete this.keyPairFile;
		};
	}

	ngAfterViewInit(): void {
		this.serviceProviderFormStatus$ = this.serviceProviderForm.statusChanges.subscribe(() => {
			let controls = this.serviceProviderForm.controls;
			this.invalidFields = Object.keys(controls)
				.filter(key => (!controls[key].valid && controls[key].status !== 'DISABLED'));
		});
	}

	doKeyPairUpload(evt): void {
		this.keyPairUploader.addToQueue(evt.target.files);
		this.keyPairUploader.uploadAll();
	}

	validateAliasName(): void {
		let availableServiceProvider = _.findWhere(this.input.allServiceProviders, { aliasName: this.serviceProvider.aliasName});
		if (!_.isUndefined(availableServiceProvider)) {
			this.serviceProviderForm.controls['aliasName'].setErrors({exist: true});
		}
	}

	validateEntityId(): void {
		let availableServiceProvider = _.findWhere(this.input.allServiceProviders, { entityId: this.serviceProvider.entityId});
		if (!_.isUndefined(availableServiceProvider)) {
			this.serviceProviderForm.controls['entityId'].setErrors({exist: true});
		}
	}

	isSettingsValid(): boolean {
		return !_.isEmpty(this.serviceProvider.aliasName)
			&& !this.serviceProviderAlreadyExists
			&& this.invalidFields.length === 0;
	}

	save(): void {
		this.input.serviceProvider = this.serviceProvider;
		this.modal.close(this.input.serviceProvider);
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
