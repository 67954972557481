import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IReportPagination } from '@cxstudio/reports/report-scope';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AmplitudeDocumentSource } from '../amplitude-document-source.enum';

export interface IDocumentExplorerInput {
	widget: Widget;
	dashboard?: Dashboard; // only needed for dashboard creation from document
	paging?: Pick<IReportPagination, 'pages'>;
	selectedScorecard?: number;
}

@Component({
	selector: 'document-explorer-modal',
	template: `
<document-explorer-container
	tabindex="0"
	[header]="header"
	[widget]="input.widget"
	[paging]="input.paging"
	[dashboard]="input.dashboard"
	[selectedScorecard]="input.selectedScorecard"
	[isFullPage]="false">
</document-explorer-container>
<ng-template #header let-parts>
	<bypass-modal-block></bypass-modal-block>
	<h2 class="modal-header flex-fixed d-flex align-items-center justify-between mb-0">
		<div class="d-flex align-items-center">
			<ng-container *ngTemplateOutlet="parts.filters"></ng-container>
			<div *ngTemplateOutlet="parts.title" class="modal-title"></div>
		</div>

		<div class="d-flex align-items-center">
			<ng-container *ngTemplateOutlet="parts.menu"></ng-container>
			<cb-modal-close
				class="hide-fullscreen kb-focusable mt-0"
				(click)="dismiss()">
			</cb-modal-close>
		</div>
	</h2>
</ng-template>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerModalComponent {

	@Input() input: IDocumentExplorerInput;

	constructor(
		private readonly activeModal: NgbActiveModal
	) { }

	dismiss(): void {
		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DOCEXPLORER_CLOSE,
			{},
			{ source: AmplitudeDocumentSource.DOC_EXPLORER }
		);
		this.activeModal.dismiss();
	}
}
