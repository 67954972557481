import { NgModule } from '@angular/core';
import { EmptyContentComponent } from '@app/modules/widget-visualizations/content-widgets/empty-content/empty-content.component';
import { ContentVideoComponent } from '@app/modules/widget-visualizations/content-widgets/content-video/content-video.component';
import { ContentImageComponent } from '@app/modules/widget-visualizations/content-widgets/content-image/content-image.component';
import { ContentTextComponent } from '@app/modules/widget-visualizations/content-widgets/content-text/content-text.component';
import { ContentLabelComponent } from '@app/modules/widget-visualizations/content-widgets/content-label/content-label.component';
import { SharedModule } from '@app/shared/shared.module';
import { ContentButtonComponent } from './content-button/content-button.component';


@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		EmptyContentComponent,
		ContentVideoComponent,
		ContentImageComponent,
		ContentTextComponent,
		ContentLabelComponent,
		ContentButtonComponent,
	],
})
export class ContentWidgetsModule { }
