import { IDirectiveFactory, INgModelController } from 'angular';

const hourField = () => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: (scope, element, attrs, ngModel: INgModelController) => {
			ngModel.$parsers.push((val) => {
				if (Number(val) > 12) {
					val = val % 12;
				}

				if (Number(val) < 1) {
					val = 1;
				}

				ngModel.$setViewValue(val);
				ngModel.$render();

				return val;
			});
		}
	};
};

const minuteField = () => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: (scope, element, attrs, ngModel: INgModelController) => {
			ngModel.$parsers.push((val) => {
				if (Number(val) > 59) {
					val = 59;
				}

				if (Number(val) < 0) {
					val = 0;
				}

				ngModel.$setViewValue(val);
				ngModel.$render();
				return val;
			});
		}
	};
};

app.directive('hourField', hourField as IDirectiveFactory);
app.directive('minuteField', minuteField as IDirectiveFactory);
