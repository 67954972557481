import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { TimePrimaryGroup, TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import Highcharts from 'highcharts';
import { TableRowHeights } from './table-row-heights.constant';

export class CbAnSlickTableDefinition {

	constructor(
		private readonly $rootScope,
		private readonly locale: ILocale,
		private readonly analyticsDataFormatting: AnalyticsDataFormattingService,
		private readonly highchartsUtils: HighchartsUtilsService
	) {	}

	private getChartOptions = (cellNode, reportDefinition?): Highcharts.Options => {
		let serviceScope = this; // tslint:disable-line:no-this-assignment no-invalid-this

		return {
			chart: {
				type: 'bar',
				backgroundColor: 'rgba(255, 255, 255, 0.1)',
				height: cellNode.offsetHeight,
				width: cellNode.offsetWidth,
				spacingTop: 0,
				spacingBottom: 0,
				spacingLeft: 0,
				spacingRight: 10,
				renderTo: cellNode
			},
			title: {
				text: ''
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			xAxis: {
				categories: [ '' ],
				lineWidth: 0,
				tickColor: 'transparent',
				labels: {
					enabled: false
				}
			},
			yAxis: {
				gridLineColor: 'transparent',
				title: {
					text: null
				},
				labels: {
					enabled: false
				}
			},
			plotOptions: {
				series: {
					stacking: 'percent',
					borderWidth: 0,
					events: {
						afterAnimate: (event): void => {
							serviceScope.highchartsUtils.handleRenderedEvent(this, reportDefinition);
						}
					}
				}
			},
			tooltip: {
				formatter(): string {
					// tslint:disable:no-invalid-this
					return `<div style="border: 1px solid ${(this.series as any).color}`
						+ '; z-index: 1000 !important;" class="cb_multi_metric_chart_tooltip">'
						+ `${this.series.name}: ${this.point.y} (${Math.round(this.percentage)}%)</div>`;
					// tslint:enable:no-invalid-this
				},
				useHTML: true,
				style: {
					fontSize: '12px',
					padding: '0px'
				},
				borderWidth: 0,
				shadow: false,
				hideDelay: 0,
				backgroundColor: 'transparent'
			}
		};
	}

	hasCustomHeight(utils): boolean {
		return !!_.findWhere(utils && utils.selectedAttributes, {urlType: AttributeUrlType.IMAGE});
	}

	getTableRowHeight(options, utils): number {
		let customRowHeight = _.chain(utils && utils.selectedAttributes)
			.filter({urlType: AttributeUrlType.IMAGE})
			.map('tableRowHeight')
			.max()
			.value();
		if (customRowHeight > 0)
			return customRowHeight;
		return options.layout === 'compact' ?
			TableRowHeights.COMPACT :
			TableRowHeights.DEFAULT;
	}

	getPostRenderers = (utils): {sentimentStackedBarRenderer: (...args) => Highcharts.Chart} => {
		return {
			sentimentStackedBarRenderer: (cellNode, row, dataContext, colDef) => {
				let container = document.createElement('DIV');
				container.style.height = !this.$rootScope.isMobile ? '100%' : '40px';
				if (this.$rootScope.isMobile) {
					container.style.display = 'inline-block';
					container.style.width = ($(cellNode).width() - $(cellNode).find('span').width() - 10) + 'px';
				}
				cellNode.appendChild(container);
				let chartOptions = this.getChartOptions(container);
				chartOptions.series = [{
					name: this.locale.getString('preview.stronglyNegative'),
					data: [{y: dataContext.sentiment_strongly_negative} ],
					index: 5,
					color: utils.colorFunction(null, 0)
				}, {
					name: this.locale.getString('preview.negative'),
					data: [{y: dataContext.sentiment_negative} ],
					index: 4,
					color: utils.colorFunction(null, 1)
				}, {
					name: this.locale.getString('preview.neutral'),
					data: [{y: dataContext.sentiment_neutral} ],
					index: 3,
					color: utils.colorFunction(null, 2)
				}, {
					name: this.locale.getString('preview.positive'),
					data: [{y: dataContext.sentiment_positive} ],
					index: 2,
					color: utils.colorFunction(null, 3)
				}, {
					name: this.locale.getString('preview.stronglyPositive'),
					data: [{y: dataContext.sentiment_strongly_positive} ],
					index: 1,
					color: utils.colorFunction(null, 4)
				}, {
					name: this.locale.getString('preview.noSentiment'),
					data: [{y: dataContext.sentiment_none} ],
					index: 0,
					color: '#FFFFFF'
				}] as Highcharts.SeriesOptionsType[];
				return new Highcharts.Chart(chartOptions);
			}
		};
	}

	getCustomFormatters = (utils, metadata): {week: (...args) => string} => {
		return {
			week: (row, cell, value, columnDef, dataContext) => {
				let timeName = metadata.isWeekDescription ?
					TimePrimaryGroup.WEEK_WITH_DES :
					TimePrimaryGroup.WEEK;
				return this.analyticsDataFormatting.formatTimeLabel(value,
					timeName);
			}
		};
	}

	getCustomSorters = (utils) => {
		let customSorters = {};

		if (_.isUndefined(utils.selectedAttributes)) {
			return customSorters;
		}

		utils.selectedAttributes.forEach((attribute) => {
			let timeName = attribute.timeName;

			if (TimePrimaryGroups.isDay(timeName) ||
				TimePrimaryGroups.isQuarter(timeName) ||
				TimePrimaryGroups.isMonth(timeName) ||
				TimePrimaryGroups.isWeek(timeName)) {
				let name = attribute.name;
				let metadataField = `_metadata_${name}`;

				customSorters[name] = (left, right) => {
					let leftTimeObject = left[metadataField];
					let rightTimeObject = right[metadataField];

					if (!leftTimeObject || !rightTimeObject) {
						return new Date(left[name]).getTime() - new Date(right[name]).getTime();
					} else {
						return new Date(leftTimeObject.sortValue).getTime() - new Date(rightTimeObject.sortValue).getTime();
					}
				};
			}
		});

		return customSorters;
	}

	getMetadata = (oldMetadata, dataView): (...args) => any => {
		return (row) => {
			let item = dataView.getItem(row);
			let meta = oldMetadata(row) || {};

			if (item) {
				meta.cssClasses = meta.cssClasses || '';

				if (item.leaf) {
					meta.cssClasses += ' slick-leaf-row';
				} else if (!isEmpty(item.level)) {
					meta.cssClasses += ` slick-parent-row slick-row-level-${item.level}`;
				}

				if (item.rowClass) {
					let myClass = ' ' + item.rowClass;
					meta.cssClasses += myClass;
				}
			}

			return meta;
		};
	}

	getDataView = (dataObject, options) => {
		let dataView = new Slick.Data.DataView();

		let getItem = dataView.getItem;
		let getLength = dataView.getLength;
		let getMetadata = dataView.getItemMetadata;
		let getRowById = dataView.getRowById;

		dataView.getRowById = (id) => {
			let row = getRowById(id);
			return options.showTotal ? row + 1 : row;
		};

		dataView.getItem = (index) => {
			if (options.showTotal) {
				return index === 0 ? dataObject.total : getItem(index - 1);
			} else {
				return getItem(index);
			}
		};
		dataView.getLength = () => {
			return getLength() + (options.showTotal ? 1 : 0);
		};
		dataView.getItemMetadata = (row) => {
			let metadata = getMetadata(row) || {};
			if (options.showTotal && row === 0) {
				metadata.cssClasses = metadata.cssClasses || '';
				metadata.cssClasses += ' slick-row-total';
				metadata.columns = metadata.columns || {};
				metadata.columns[0] = {
					formatter: () => this.locale.getString('widget.total')
				};
			}
			return metadata;
		};
		return dataView;
	}

	getTableOptions = (options, utils) => {
		return {
			editable: false,
			enableAddRow: false,
			enableColumnReorder: false,
			enableCellNavigation: true,
			forceFitColumns: true,
			rowHeight: this.getTableRowHeight(options, utils),
			asyncEditorLoading: false,
			multiColumnSort: true,
			enableAsyncPostRender: true,
			rowClasses: 'br-table-report-widget-row',
			localization: {
				getSortByColumnPrompt: (columnName) => this.locale.getString('common.clickToSortByColumnName', {columnName}),
				getColumnSortedTitle: (columnName, isAscending: boolean = false) => {
					return isAscending ?
						this.locale.getString('common.tableSortedByColumnNameAscending', {columnName}) :
						this.locale.getString('common.tableSortedByColumnNameDescending', {columnName});
				}
			}
		};
	}
}

app.service('cbAnTableSlickDefinition', CbAnSlickTableDefinition);
