<div class="dropdown br-color-dropdown" [ngClass]="dropdownClass" dropdown-position="auto">
	<select-from-tree
		[disabled]="dropdownDisabled"
		searchPlaceholder="{{'widget.findPalette' | i18n}}"
		[ngModel]="selectedPalette?.name"
		[hierarchyList]="validPaletteOptions"
		(onNodeClick)="selectPalette($event.node)"
		[itemTemplate]="paletteTemplate"
		[selectedItemTemplate]="selectedPaletteTemplate"
	></select-from-tree>
</div>
<color-input *ngIf="showColorInput()"
	class="br-color-input d-flex w-100-percent mt-8"
	[(ngModel)]="customColor"
	(ngModelChange)="setCustomColor($event)"
	[showSwatch]="false">
</color-input>

<ng-template #paletteTemplate let-palette>
	<color-swatch [displayName]="palette.displayName" [colors]="palette.getColors()"></color-swatch>
</ng-template>

<ng-template #selectedPaletteTemplate let-palette>
	<selected-color-swatch [displayName]="palette.displayName" [colors]="palette.getColors()"></selected-color-swatch>
</ng-template>
