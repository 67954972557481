import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MathMetricDefinition } from '@app/modules/metric/definition/math-metric-definition';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { IMetricAssets } from '../editor/metric-assets.interface';

@Component({
	selector: 'math-metric-definition',
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
	template: `
	<fieldset ngModelGroup="mathMetricDefinition">
		<custom-math-editor
			class="p-relative mb-8"
			[(expressions)]="definition.mathComponents"
			(expressionsChange)="changeHandler()"
			(validityChange)="validityChangeHandler($event)"
			[attributes]="attributes"
			[project]="project">
		</custom-math-editor>
		<collapsing-section [collapsed]="true">
			<panel-heading class="font-bold">{{'widget.advancedOptions'|i18n}}</panel-heading>
			<panel-body>
				<div class="labeled-checkbox">
					<input id="replaceNulls" name="replaceNulls"
						type="checkbox" [(ngModel)]="definition.replaceNullsWithZeroes">
					<label for="replaceNulls">
						{{'metrics.replaceNullsWithZeroes'|i18n}}
					</label>
				</div>
			</panel-body>
		</collapsing-section>
	</fieldset>
	`,
	styles: [`fieldset { min-inline-size: auto; }`]
})
export class MathMetricDefinitionComponent implements OnInit {
	@Input() project: AccountOrWorkspaceProject;
	@Input() assets: IMetricAssets;

	@Input() definition: MathMetricDefinition;
	@Output() definitionChange = new EventEmitter<MathMetricDefinition>();
	@Output() validityChange = new EventEmitter<boolean>();

	attributes: IReportAttribute[];

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService
	) {}

	ngOnInit(): void {
		if (!this.definition) {
			this.definition = this.getDefaults();
		}

		if (this.assets.attributes) {
			let countDistinctEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT);
			let attributesGroup = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.metricDefaults())
				.withAttributes(this.assets.attributes, countDistinctEnabled
					? MetricFilters.NOT_DATE
					: MetricFilters.CALCULATION)
				.build();
			this.attributes = this.filterHiddenAttributes(attributesGroup[0].children);
		}
	}

	private filterHiddenAttributes(attributes: IReportAttribute[]): IReportAttribute[] {
		return _.filter(attributes, attribute => !attribute.hide);
	}

	private getDefaults(): MathMetricDefinition {
		return {
			type: MetricType.CUSTOM_MATH,
			mathComponents: [],
			replaceNullsWithZeroes: false
		};
	}

	changeHandler = (): void => {
		this.definitionChange.emit(this.definition);
	}

	validityChangeHandler = (valid: boolean): void => {
		this.validityChange.emit(valid);
	}
}
