<aside class="d-flex flex-direction-column h-max-100-percent w-100-percent overflow-hidden">
	<div>
		<strong class="float-left mb-16">{{'preview.availableModels'|i18n}}</strong>
		<a  class="float-right"
			href="javascript:void(0)"
			(click)="$event.stopPropagation();selectAllModels();"
			[i18n]="'common.selectAll'">
		</a>
	</div>
	<div>
		<strong class="float-left mb-16">{{'preview.chooseModels'|i18n}}</strong>
	</div>
	<ul class="topic-hierarchy-ul overflow-auto">
		<li class="pr-16 break-word" *ngFor="let model of usableModels | orderBy:'name' | filterBy:['name']:filterText">
			<a role="checkbox"
				[attr.aria-label]="model.name"
				[attr.aria-checked]="userPreferences.settings.modelSearch[projUniqueId][model.id] !== false"
				class="labeled-checkbox cursor-pointer"
				href="javascript:void(0)">
				<input type="checkbox" tabindex="-1"
					(click)="toggleModel(model.id);$event.stopPropagation();"
					[checked]="userPreferences.settings.modelSearch[projUniqueId][model.id] !== false"
					[attr.id]="model.name">
				<label
					class="text-gray-1000"
					[attr.for]="model.name"
					[title]="model.name"
					(click)="$event.stopPropagation()">
					{{model.name}}
				</label>
			</a>
		</li>
	</ul>
	<div *ngIf="showDeselectButton()">
		<button
			type="button"
			class="btn btn-danger w-100-percent mv-16"
			(click)="$event.stopPropagation(); deselectAllModels();"
			[i18n]="'common.deselectAll'">
		</button>
	</div>
</aside>
