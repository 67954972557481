<modal-header
	(cancel)="cancel()"
	[modalTitle]="'mAccount.expirationDialogTitle' | i18n">
</modal-header>

<div class="modal-body remove-user-modal">
	<div class="mb-16">
		<cb-radio-group
			id="masterAccountExpiration"
			[ngModel]="expirationMode"
			(ngModelChange)="changeHandler($event)"
			[options]="expirationOptions">
		</cb-radio-group>
	</div>
	<date-picker-popup *ngIf="isExpireOnDateSelected()"
		[(value)]="expirationDate"
	></date-picker-popup>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="hasWrongSelection()"
></save-modal-footer>
