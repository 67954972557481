import { FavoriteType, FavoriteId, FavoriteAttribute } from '@cxstudio/reports/document-explorer/favorite-attribute';

export class DocExplorerFavoriteUtils {
	
	static getFavoriteId(favoriteItem: FavoriteAttribute, type: FavoriteType): FavoriteId {
		let findItem: FavoriteId = { type };
		if (type === FavoriteType.MODEL) {
			findItem.modelId = favoriteItem.modelId;
		} else if (type === FavoriteType.SCORECARDS) {
			findItem.scorecardId = favoriteItem.scorecardId;
		} else {
			findItem.name = (favoriteItem.name || '').toLowerCase();
		}
		return findItem;
	}
}
