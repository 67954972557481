import { Inject, Injectable } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { DashboardViewMode } from './dashboard-view-mode.enum';
import { UserDashboardContext } from './user-dashboard-context';
import { PersonalizationState, ForbiddenPersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardViewService {

	private mode: DashboardViewMode = DashboardViewMode.NORMAL;
	private viewAsUser?: UserDashboardContext;
	private data: any; //restore state
	private dashboardId: number;

	constructor(
		@Inject('dashboardPersonalizationProvider') private dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		private dashboardAccessService: DashboardAccessService
	) {}

	isPreviewAsAllowed(dashboard: Dashboard): boolean {
		return this.dashboardAccessService.canShare(dashboard);
	}

	enterViewAsMode(user: UserDashboardContext, data: any, dashboardId: number): void {
		this.mode = DashboardViewMode.VIEW_AS;
		this.viewAsUser = user;
		this.data = data;
		this.dashboardId = dashboardId;
	}

	exitViewAsMode(): any {
		this.mode = DashboardViewMode.NORMAL;
		this.viewAsUser = null;
		this.dashboardId = null;
		return this.data;
	}

	isPreviewAsMode(): boolean {
		return this.mode === DashboardViewMode.VIEW_AS && !!this.viewAsUser;
	}

	getViewAsUser(): UserDashboardContext {
		return this.viewAsUser;
	}

	getDashboardId(): number {
		return this.dashboardId;
	}

	//for now only can view as OH
	getPersonalization(dashboard: Dashboard, containerId: string): Partial<PersonalizationState> {
		if (this.isPreviewAsMode()) {
			if (!this.isPreviewAsAllowed(dashboard)) {
				return new ForbiddenPersonalizationState();
			}
			return this.dashboardPersonalizationProvider.getViewAsInstance(dashboard, this.viewAsUser.email);
		}
		return this.dashboardPersonalizationProvider.getInstance(dashboard, containerId);
	}
}

app.service('dashboardViewService', downgradeInjectable(DashboardViewService));
