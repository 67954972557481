<modal-header (keydown.escape)="closeModal($event)"
	(cancel)="cancel()"
	[modalTitle]="'widget.text_editImage' | i18n">
</modal-header>
<div class="modal-body" (keydown.escape)="closeModal($event)">
	<div class="form-group">
		<label [attr.aria-label]="('common.altText' | i18n) + ' ' + ('dashboard.charactersLimit' | i18n:{count: maxLength})"
			for="image-alt-text">{{'common.altText'|i18n}}</label>
		<textarea
			id="image-alt-text"
			rows="3"
			class="w-100-percent"
			[maxlength]="maxLength"
			[(ngModel)]="input.altText"
			(ngModelChange)="updateLength()"
			trim
		></textarea>
		<characters-left-counter
			[maxLength]="maxLength"
			[textLength]="textLength"
		></characters-left-counter>
	</div>
	<div class="form-group">
		<label>{{'widget.text_fontSize'|i18n}}</label>
		<div>
			<div class="input-group col-sm-3 pull-left mr-8">
				<input type="number"
					class="form-control"
					[(ngModel)]="input.imageWidth"
					(ngModelChange)="syncHeight()"
					min="1"
					pattern="^\d*"/>
				<span class="p-4">px</span>
			</div>
			<div class="input-group col-sm-3 pull-left mr-8">
				<input type="number"
					class="form-control"
					[(ngModel)]="input.imageHeight"
					(ngModelChange)="syncWidth()"
					min="1"
					pattern="^\d*"/>
				<span class="p-4">px</span>
			</div>
			<button class="btn btn-secondary btn-link btn-icon" type="button"
				[ngClass]="{'q-icon-unlock': !isSizeLocked, 'q-icon-lock': isSizeLocked }"
				[disabled]="true"
				(click)="toggleSizeLock()">
			</button>
		</div>

	</div>
</div>
<footer class="modal-footer">
	<button
		type="button"
		class="btn btn-secondary"
		[i18n]="'common.cancel'"
		(click)="cancel()">
	</button>
	<button
		type="button"
		class="btn btn-primary"
		[i18n]="'common.save'"
		(click)="save()">
	</button>
</footer>
