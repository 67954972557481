import { ParticipantEnrichmentTypes } from '@cxstudio/conversation/entities/spine-lane.class';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';


export interface ConversationEnrichment {
	enrichmentType?: SingleLaneEnrichmentTypes;
	iconType?: SecondaryTrackRendererType;

	/** Logic to determine if an enrichment should be shown for a sentence */
	hasValue(data: ConversationDataPoint): boolean;
	tooltipFormatter(data: ConversationDataPoint): string;

	/** Returns an array of the colors available to color the enrichment */
	getColorArray?(): string[];

	/** Returns the index of the color from getColorArray to use for the point color */
	getColorIndex?(data: ConversationDataPoint): number;
	getName(): string;
	getHeaderIcon(): string;
	onClick?(event: Event): void;
	getValue?(data: ConversationDataPoint): any;
	getWarning?(data: ConversationDataPoint): string;
	useFiveBandPalette?(): boolean;
}

export interface ConversationHeader extends Pick<ConversationEnrichment, 'getHeaderIcon' | 'getName'> {}

export class ParticipantEnrichments {
	primary: ConversationEnrichment;
	overtalk: ConversationEnrichment;
	agentDetection: ConversationHeader;
	clientDetection: ConversationHeader;
	botDetection: ConversationHeader;
	unknownDetection: ConversationHeader;
}
