import * as _ from 'underscore';
import { WizardComponentController } from '@cxstudio/components/wizard.component';


export class WizardStepComponentController implements ng.IController {

	active: boolean;
	header: string;
	nextButtonText: string;
	wizardCtrl: WizardComponentController;
	isValid: () => boolean;
	preventBack: () => boolean;
	onNext: () => boolean;
	showFinish: () => boolean;
	showNext: () => boolean;
	goNextAutomatically: () => boolean;

	constructor() {}

	$onInit(): void {
		this.active = false;
		this.wizardCtrl.addStep(this);
	}

	setActive = (isActive): void => {
		this.active = isActive;
	}

	getHeader = (): string => {
		return this.header;
	}

	getNextButtonText = (): string => {
		return this.nextButtonText;
	}
}

app.component('wizardStep', {
	controller: WizardStepComponentController,
	bindings: {
		isValid: '&?',
		preventBack: '&?',
		onNext: '&?',
		showFinish: '&?',
		showNext: '&?',
		header: '@?',
		nextButtonText: '@?',
		goNextAutomatically: '&?'
	},
	require: {
		wizardCtrl: '^wizard',
	},
	transclude: true,
	template: `<div class="cx-wizard-step" ng-show="$ctrl.active" ng-transclude></div>`,
});
