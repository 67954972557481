import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { WidgetEvent } from '@app/core/cx-event.enum';
import { HomePageWidgetUtilsService } from '@app/modules/home-page/home-page-common/home-page-widget-utils.service';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';

@Component({
	selector: 'home-page-widgets-panel',
	templateUrl: './home-page-widgets-panel.component.html',
	styles: [`
		.custom-widgets {
			height: 342px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageWidgetsPanelComponent extends SelfCleaningComponent implements OnInit, OnDestroy {

	widgetsEnabled: boolean;
	dashboardData: IDashboardData;
	widgets: Widget[];

	loaded = false;
	widgetMode = WidgetDisplayMode.HOME_PAGE;

	constructor(
		private ref: ChangeDetectorRef,
		private eventBus: GlobalEventBus,
		private userHomePageService: UserHomePageService,
		private currentObjects: CurrentObjectsService,
		private homePageWidgetUtils: HomePageWidgetUtilsService,
		private widgetVisibilityMonitor: WidgetVisibilityMonitorService,
		private applicationThemeService: ApplicationThemeService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets
	) {
		super();
	}

	ngOnInit(): void {
		this.applicationThemeService.setDashboardTheme(this.applicationThemeService.getAppliedApplicationTheme());
		this.widgetVisibilityMonitor.reset();

		this.addSubscription(this.userHomePageService.getHomePage().subscribe(homePage => {
			this.widgetsEnabled = homePage.widgetsConfig.enabled;
			if (this.widgetsEnabled) {
				this.userHomePageService.getCustomWidgets(homePage.id).then(widgets => {
					this.widgets = widgets;
					this.homePageWidgetUtils.getDashboardData(homePage).then(dashboardData => {
						this.dashboardData = dashboardData;
						this.currentObjects.setEditMode(false);
						this.currentWidgets.setWidgets(HomePageWidgetConstants.CONTAINER_ID, this.widgets, this.dashboardData.dashboardHistory);
						this.loaded = true;
						this.ref.markForCheck();
					});
				});
			} else {
				delete this.dashboardData;
				delete this.widgets;
				this.loaded = false;
			}
			this.ref.markForCheck();
		}));
	}

	ngOnDestroy(): void {
		this.widgetVisibilityMonitor.disconnect();
	}

	hierarchyNodeChange(node: IHierarchyNode): void {
		this.dashboardData.dashboardHistory.applyHierarchyNode(node);
		this.eventBus.broadcast(WidgetEvent.RELOAD, this.widgets.map(widget => widget.id));
	}
}
