<form [formGroup]="voiceSettings">
	<div class="rounded-grouping clearfix">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button id="enableVoice"
					name="enableVoice"
					label="{{'mAccount.fileStorageEnableVoci'|i18n}}"
					formControlName="vociEnabled">
				</checkbox-button>
			</div>
		</div>
		<div class="col-xs-12 row mb-16">
			<div class="col-xs-6">
				<label class="control-label mr-8">{{'mAccount.nfsServer'|i18n}}</label>
				<simple-dropdown
					[options]="nfsApplications"
					formControlName="voiceNfsApplicationId"
					valueField="id">
				</simple-dropdown>
			</div>
		</div>

		<div class="col-xs-12 row mb-16 form-group">
			<div class="col-xs-6">
				<label class="control-label mr-8">{{'mAccount.vttServer'|i18n}}</label>
				<simple-dropdown
					[options]="vttApplications"
					formControlName="voiceVttApplicationId"
					valueField="id">
				</simple-dropdown>
			</div>
		</div>

		<div class="col-xs-12 row mb-16">
			<div class="col-xs-6">
				<label class="control-label mr-8">{{'mAccount.transcriptionService'|i18n}}</label>
				<simple-dropdown
					[options]="providers"
					formControlName="transcriptionService"
					valueField="id">
				</simple-dropdown>
			</div>
		</div>
		<div class="mb-16 row col-lg-12 form-group">
				<label class="meaningful-silence-message mr-8">{{'mAccount.meaningfulSilenceLabel'|i18n}}</label>
				<div>
					<input name="meaningfulSilence" id="meaningfulSilence"
						class="form-control " type="number"
						formControlName="meaningfulSilenceThreshold"/>
				</div>
				<label class="control-label meaningful-silence-message">{{'mAccount.meaningfulSilenceMessage'|i18n}}</label>
		</div>
		<div class="col-xs-12 mb-16">
			<checkbox-button
				class="mr-16"
				name="allowAudioDownload"
				label="{{'mAccount.allowAudioDownload'|i18n}}"
				formControlName="audioDownloadEnabled">
			</checkbox-button>

			<checkbox-button
				class="mr-16"
				name="allowTranscriptDownload"
				label="{{'mAccount.allowTranscriptDownload'|i18n}}"
				formControlName="transcriptDownloadEnabled">
			</checkbox-button>
		</div>
	</div>
</form>
