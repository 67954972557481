import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { MetricTypesService } from '@app/modules/metric/editor/metric-types.service';
import { MetricDefinition } from '@app/modules/metric/entities/metric-definition';
import { MetricProperties } from '@app/modules/metric/entities/metric-properties';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ListOption } from '@app/shared/components/forms/list-option';
import { Security } from '@cxstudio/auth/security-service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { Label } from '@cxstudio/dashboards/entity/label';
import { Folders } from '@cxstudio/folders/folders-constant';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { LabelsApiService } from '@cxstudio/services/data-services/labels-api.service';
import { Subscription } from 'rxjs';
import { IMetricAssets } from './metric-assets.interface';

@Component({
	selector: 'metric-definition-tab',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './metric-definition-tab.component.html'
})
export class MetricDefinitionTabComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() mode: MetricMode;

	@Input() properties: MetricProperties;
	@Output() propertiesChange = new EventEmitter<MetricProperties>();
	@Input() definition: MetricDefinition;
	@Output() definitionChange = new EventEmitter<MetricDefinition>();

	@Input() project: AccountOrWorkspaceProject;
	@Input() assets: IMetricAssets;
	@Input() selectedFolder: IFolderItem;
	@Input() folders: IFolderItem[];

	@Output() formatChange = new EventEmitter<NumberFormatSettings>();
	@Output() onNameChange = new EventEmitter<string>();
	@Output() onValidityChange = new EventEmitter<boolean>();
	@Output() restoreDefaults = new EventEmitter<void>();

	targetFolder: IFolderItem;
	ui: {
		metricTypes: ListOption<MetricType>[];
		folders: IFolderItem[];
	};
	attributes: IReportAttribute[];

	@ViewChild('definitionForm', {static: false}) definitionForm: NgForm;

	private formStatus$: Subscription;
	private definitionValid: boolean;

	constructor(
		private locale: CxLocaleService,
		private metricTypesService: MetricTypesService,
		@Inject('security') private security: Security,
		@Inject('labelsApiService') private labelsApiService: LabelsApiService,
	) {}

	ngOnInit(): void {
		this.definitionValid = true;
		this.ui = {
			metricTypes: this.getMetricTypes(),
			folders: this.folders || []
		};

		let rootFolder = Folders.ROOT_FOLDER as any;
		this.targetFolder = this.selectedFolder || rootFolder as IFolderItem;

		if (_.isEmpty(this.properties)) {
			this.properties = this.getDefaultProperties();
			this.propertiesChangeHandler();
		} else if (this.isPredefinedMetric()) {
			this.properties.displayName = this.locale.getString(this.properties.displayName);
		}

		if (!this.definition) {
			this.updateType(this.ui.metricTypes[0].value);
		}
	}

	ngAfterViewInit(): void {
		this.formStatus$ = this.definitionForm.statusChanges
			.subscribe(status => {
				if (status !== 'DISABLED') {
					this.validityChangeHandler();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.formStatus$)
			this.formStatus$.unsubscribe();
	}

	private getDefaultProperties(): MetricProperties {
		let currentUser = this.security.getEmail().toLowerCase();
		return {
			displayName: '',
			ownerName: currentUser,
			labels: [],
			parentId: this.targetFolder.id as number
		};
	}

	propertiesChangeHandler = () => {
		this.propertiesChange.emit(this.properties);
	}

	nameWasChanged = (): void => {
		if (!this.isPredefinedMetric()) {
			this.onNameChange.emit(this.properties.displayName);
			this.propertiesChangeHandler();
		}
	}

	definitionChangeHandler = (): void => {
		this.definitionChange.emit(this.definition);
	}

	restoreDefaultsHandler = (): void => {
		this.restoreDefaults.emit();
	}

	formatChangeHandler = (format: NumberFormatSettings): void => {
		this.formatChange.emit(format);
	}

	validityChangeHandler = (): void => {
		this.onValidityChange.emit(this.definitionForm?.valid && this.definitionValid);
	}

	definitionValidityChangeHandler = (valid: boolean): void => {
		this.definitionValid = valid;
		this.validityChangeHandler();
	}

	metricTypeChangeHandler = (type: MetricType): void => {
		if (this.definition.type !== type) {
			this.updateType(type);
		}
	}

	updateType = (type: MetricType): void => {
		this.definition = this.metricTypesService.getDefaultDefinition(type);
		this.definitionChange.emit(this.definition);
		this.definitionValidityChangeHandler(true);

		if (!this.definitionForm?.controls.name.dirty) {
			this.properties.displayName = this.metricTypesService.getDefaultMetricDisplayName(type);
			this.nameWasChanged();
		}
		this.restoreDefaults.emit();
	}

	isPredefinedMetric = (metricType?: MetricType): boolean => {
		let type = metricType || this.definition?.type;
		return type === MetricType.EASE_SCORE
			|| type === MetricType.SENTIMENT
			|| type === MetricType.NUMERIC_BREAKDOWN;
	}

	getMetricTypes = (): ListOption<MetricType>[] => {
		return this.metricTypesService.getUserMetricTypes()
			.map(option => {
				option.disabled = this.isTypeDisabled(option.value);
				return option;
			});
	}

	isTypeDisabled = (type: MetricType): boolean => {
		if (this.mode === MetricMode.EDIT_AND_SHARE || this.mode === MetricMode.EDIT) {
			return !this.isBoxMetric() || !this.isBoxMetric(type);
		}
		return this.mode !== MetricMode.CREATE;
	}

	isBoxMetric = (metricType?: MetricType): boolean => {
		let type = metricType || this.definition?.type;
		return type === MetricType.TOP_BOX
			|| type === MetricType.BOTTOM_BOX
			|| type === MetricType.SATISFACTION;
	}

	isFilterMetric = (metricType?: MetricType): boolean => {
		let type = metricType || this.definition?.type;
		return type === MetricType.FILTER;
	}

	isValueMetric = (): boolean => {
		let type = this.definition.type;
		return type === MetricType.VARIABLE;
	}

	isMathMetric = (metricType?: MetricType): boolean => {
		let type = metricType || this.definition?.type;
		return type === MetricType.CUSTOM_MATH;
	}

	isMovableToFolder = (): boolean => {
		return this.ui?.folders.length > 0;
	}

	selectFolder = (folder: IFolderItem): void => {
		this.targetFolder = folder;
		this.properties.parentId = this.targetFolder?.id ? this.targetFolder.id as number : null;
		this.propertiesChangeHandler();
	}

	labelAdded = (label: Label): void => {
		this.properties.labels = this.properties.labels || [];
		this.properties.labels.push(label);
		this.propertiesChangeHandler();
	}

	labelRemoved = (label: Label): void => {
		this.properties.labels = _.reject(this.properties.labels, label);
		this.propertiesChangeHandler();
	}

	loadLabels = (query: string): Promise<Label[]> => {
		return Promise.resolve(this.labelsApiService.getLabels(query) as any);
	}
}
