<aside tabindex="-1"
	#palette
	(click)=hidePalette()
	cx-keyboard-scroll
	[maintainFocus]="true"
	[focusedIndex]="focusedIndex"
	(selectionChanged)="changeSelection($event)"
	role="menu"
	class="align-items-stretch d-flex flex-direction-column">
	<div class="text-pewter-color-900 p-relative" (click)=$event.stopPropagation()>
		<span class="p-absolute lh-1 text-0_75rem command-palette__key-hint text-menu-color" aria-hidden="true">
			{{getPlatformKeyCombo()}}
		</span>
		<input #searchInput
			[(ngModel)]="commandSearch"
			class="pr-48 w-100-percent"
			placeholder="{{'common.find'|i18n}}"
			title="{{'commandPalette.findObjectsAndPages'|i18n}}"
			(focus)="onFocus()"
			(ngModelChange)="onSearchChange()">
		<div class="command-palette__list-wrapper ph-0 pb-8 mb-0 mt-4 p-absolute popover-base bg-menu-color text-menu-color"
			[class.pt-8]="!isRecents()"
			*ngIf="paletteVisible">
			<div *ngIf="isRecents()"
				class="popup-header popup-header p-8">
				{{ 'header.recents' | i18n }}
			</div>
			<ul #options scroll-list class="m-0 p-0 overflow-y-auto h-max-40-vh w-40-vw">
				<li tabindex="0" *ngFor="let item of getFilteredSortedList() | slice:0:15; index as $index;"
					class="cursor-pointer ph-16 pv-4 hover-bg-cxs-brand-200 command-palette__list-item m-2"
					(mousedown)="handleClick(item, $event)"
					[ngClass]="{'soft-selection bg-cxs-brand-primary-500 text-white hover:text-gray-1000': $index === focusedIndex}">
					<span role="link" class="d-inline-flex w-16 justify-content-center mr-8" [ngClass]="item.icon ? item.icon : ''" aria-hidden="true"></span>
					<!-- <span role="link" *ngIf="item.ctrlShift" class="ctrl-shift-name" [innerHTML]="item.ctrlShift.name | escapeHtml | highlightMatch:commandSearch"></span> -->
					<span role="link" *ngIf="item.shift" class="shift-name" [innerHTML]="item.shift.name | escapeHtml | highlightMatch:commandSearch"></span>
					<span role="link" *ngIf="item.ctrl" class="ctrl-name" [innerHTML]="item.ctrl.name | escapeHtml | highlightMatch:commandSearch"></span>
					<span role="link" class="basic-name" [innerHTML]="item.name | escapeHtml | highlightMatch:commandSearch"></span>
				</li>
				<li *ngIf="getFilteredSortedList().length < 1" class="ph-16">
					<i *ngIf="!isRecents()" >{{'common.noMatchedItems'|i18n}}</i>
					<i *ngIf="isRecents()" class="d-block pt-8" >{{'header.noNavigationHistory'|i18n}}</i>
				</li>
			</ul>
		</div>
	</div>
</aside>
