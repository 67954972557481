import { ConversationChartOptions } from './conversation-chart-options.class';
import { BubbleRoundingOptions } from './bubble-rounding-options.enum';

export class ChartPathUtils {
	config: ConversationChartOptions;

	readonly r: number;
	readonly TOP_RIGHT: string;
	readonly BOTTOM_RIGHT: string;
	readonly BOTTOM_LEFT: string;
	readonly TOP_LEFT: string;
	
	constructor(config: ConversationChartOptions) {
		this.config = config;
		this.r = this.config.basicChart.radius;
		this.TOP_RIGHT = `a${this.r},${this.r} 0 0 1 ${this.r},${this.r}`;
		this.BOTTOM_RIGHT = `a${this.r},${this.r} 0 0 1 -${this.r},${this.r}`;
		this.BOTTOM_LEFT = `a${this.r},${this.r} 0 0 1 -${this.r},-${this.r}`;
		this.TOP_LEFT = `a${this.r},${this.r} 0 0 1 ${this.r},-${this.r}`;
	}

	getPathD = (xPos: number, yPos: number, height: number, width: number, rounding?: string): string => {
		let pathRoundingMethod: (height, width) => string = this.getSquaredPath;
		if (rounding === BubbleRoundingOptions.ALL) {
			pathRoundingMethod = this.getFullRoundedPath;
			xPos += this.r;
		} else if (rounding === BubbleRoundingOptions.TOP) {
			pathRoundingMethod = this.getRoundedTopPath;
			xPos += this.r;
		} else if (rounding === BubbleRoundingOptions.BOTTOM) {
			pathRoundingMethod = this.getRoundedBottomPath;			
		}

		let path = `M ${xPos},${yPos} ${pathRoundingMethod(height, width)}`;
		return path;
	}

	getFullRoundedPath = (height: number, width: number): string => {
		return `h${width - (2 * this.r)} ${this.TOP_RIGHT} v${height - (2 * this.r)} ${this.BOTTOM_RIGHT} h${-1 * (width - (2 * this.r))} ${this.BOTTOM_LEFT} v${-1 * (height - (2 * this.r))} ${this.TOP_LEFT}z`;
	}

	getRoundedTopPath = (height: number, width: number): string => {
		return `h${width - (2 * this.r)} ${this.TOP_RIGHT} v${height - this.r} h${-1 * width} v${-1 * (height - this.r)} ${this.TOP_LEFT}z`;
	}

	getRoundedBottomPath = (height: number, width: number): string => {
		return `h${width} v${height - this.r} ${this.BOTTOM_RIGHT} h${-1 * (width - (2 * this.r))} ${this.BOTTOM_LEFT} v${-1 * (height - this.r)}z`;
	}

	getSquaredPath = (height: number, width: number): string => {
		return `h${width} v${height} h${(width * -1)} v${height * -1}z`;
	}

	getLine = (x1: number, y1: number, x2: number, y2: number): string => {
		return `M ${x1},${y1} L ${x2},${y2}`;
	}
}