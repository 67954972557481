import { Component, Input, ElementRef, ViewChild} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

/* usage:
	<text-with-help help-text="{{::'lang.myTooltip'|i18n}}">
		<label>Some label</label>
	<text-with-help>
*/

@Component({
	selector: 'text-with-help',
	template: `
<ng-content #textContent></ng-content>
<cb-inline-help right>
	<help-body>
		<p>{{helpText}}</p>
	</help-body>
</cb-inline-help>
`})

export class TextWithHelpComponent {

	@ViewChild('textContent', {static: false}) text: ElementRef;
	@Input() helpText: string;

	constructor() { }
}

app.directive('textWithHelp', downgradeComponent({component: TextWithHelpComponent}));
