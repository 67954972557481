import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'logo-upload-button'
})

export class LogoUploadButtonDirective extends UpgradeComponent {
	constructor(elementRef: ElementRef, injector: Injector) {
		super('logoUploadButton', elementRef, injector);
	}
}
