import * as _ from 'underscore';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { RandomUtils } from '@app/util/random-utils.class';


//This directive is used for the hierarchies for dashboard filters
export class SelectFromHierarchyForAttrsController implements ng.IController {

	hierarchyList: INode[];
	isLoading: boolean;
	displayProperty: string;
	placeholder: string;
	searchPlaceholder: string;
	searchLabel: string;
	onNodeClick: (params: {node: INode, previous: INode}) => void;
	onSearch: (params: {query: string}) => void;
	label: string;
	ngDisabled: () => boolean;
	fixedPosition: any;

	model: ng.INgModelController;

	selectedValue: string;
	selectedNode: any;
	selectedItem: INode;
	menuId = `select-from-hierarchy-${RandomUtils.randomString()}`;

	constructor(
		private $scope: ISimpleScope,
		private $timeout: ng.ITimeoutService
	) {}

	$onInit(): void {

		this.selectedValue = this.placeholder;

		// initialize the select once we have our hierarchy list available
		this.$scope.$watch(() => this.hierarchyList, this.onHierarchyListChange);

		// handle model-driven changes after init
		this.model.$formatters.push(this.parseModel);
	}

	searchTextUpdated = (query: string) => {
		if (this.onSearch)
			this.onSearch({query});
	}

	private parseModel = (value) => {
		if (!isEmpty(value)) {
			if (this.onSearch) {
				this.selectedValue = (value.constructor === Array)
					? value[0][this.displayProperty]
					: value[this.displayProperty];
			} else 	if (this.hierarchyList) {
				this.selectedNode = SearchableHierarchyUtils.findMetricNameInHierarchy(this.hierarchyList, value);
			}
		}
	}


	dropdownButtonClick = () => {
		if (this.onSearch) {
			if (!this.hierarchyList) {
				this.onSearch({query: ''});
			}
		}
	}

	getDirection = () => {
		return this.fixedPosition ? 'right-fixed' : 'auto';
	}

	onHierarchyListChange = (newVal) => {
		if (newVal !== undefined) {
			this.parseModel(this.model.$modelValue);
		}
	}

	updateValue = (node: INode) => {
		if (node.children) return;
		let previous = angular.copy(this.selectedItem);
		this.selectedItem = node;
		this.model.$setViewValue(node.name);
		this.onNodeClick({node, previous});
	}

	onSelectFromHierarchyButtonKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				event.target.click();
				$('.searchable-hierarchy :focusable').first().trigger('focus');
			});
		}
	}

}

app.component('selectFromHierarchyForAttrs', {
	controller: SelectFromHierarchyForAttrsController,
	templateUrl: 'partials/custom/select-from-hierarchy-for-attrs.html',
	bindings: {
		hierarchyList: '<',
		isLoading: '=',
		displayProperty: '@',
		placeholder: '@',
		searchPlaceholder: '@',
		searchLabel: '@',
		onNodeClick: '&',
		onSearch: '&',
		label: '@',
		ngDisabled: '&',
		fixedPosition: '<?'
	},
	require: {
		model: 'ngModel'
	},
});
