import { Injectable, Inject } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardTemplate, DashboardTemplatesManagementComponent } from './dashboard-templates-management.component';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import Authorization from '@cxstudio/auth/authorization-service';
import { FolderContextMenuUtils, IFolderActions } from '@cxstudio/folders/folder-context-menu-utils.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardTemplatesListActions } from './dashboard-templates-list-actions.service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { AssetSpecificUtilsService } from '../../common-templates/asset-specific-utils.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardTemplatesContextMenu extends BaseContextMenuUtils {
	private folderService;

	private readonly OPTIONS: {[name: string]: ContextMenuItem<DashboardTemplate>} = {
		CREATE: {
			name: 'create',
			text: this.locale.getString('templates.createFromTemplate'),
			func: (template: DashboardTemplate) => this.actionsService.createFromTemplate(template)
		},
		SHARE_TEMPLATE: {
			name: 'shareTemplate',
			text: this.locale.getString('templates.shareTemplate'),
			func: (template: DashboardTemplate) => this.actionsService.shareTemplate(template)
		},
		UNSHARE_TEMPLATE: {
			name: 'unshareTemplate',
			text: this.locale.getString('templates.unshareTemplate'),
			func: (template: DashboardTemplate) => this.actionsService.unshareTemplate(template)
		},
		DELETE_TEMPLATE: {
			name: 'deleteTemplate',
			text: this.locale.getString('templates.deleteTemplate'),
			func: (template: DashboardTemplate) => this.actionsService.confirmDeleteTemplate(template)
		},
		EDIT_PROPERTIES: {
			text: this.locale.getString('templates.editProperties'),
			name: 'editProperties',
			func: (template: DashboardTemplate) => this.actionsService.editProperties(template)
		},
		EDIT_MAPPINGS: {
			text: this.locale.getString('templates.editMappings'),
			name: 'editMappings',
			func: (template: DashboardTemplate) => this.actionsService.editMappings(template)
		},
		EXPORT: {
			text: this.locale.getString('common.export'),
			name: 'export',
			func: (template: DashboardTemplate) => this.actionsService.exportTemplate(template)
		},
		UPGRADE_TEMPLATE: {
			text: this.locale.getString('templates.upgradeTemplate'),
			name: 'upgradeTemplate',
			func: (template: DashboardTemplate) => this.actionsService.upgradeTemplate(template)
		}
	};

	constructor(
		private readonly locale: CxLocaleService,
		private readonly actionsService: DashboardTemplatesListActions,
		private readonly assetSpecificUtils: AssetSpecificUtilsService,
		@Inject('security') private readonly security: Security,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('FolderService') private readonly FolderService,
		@Inject('folderContextMenuUtils') private readonly folderContextMenuUtils: FolderContextMenuUtils,
		private betaFeaturesService: BetaFeaturesService,
	) {
		super();
		this.folderService = new this.FolderService(FolderTypes.DASHBOARD_TEMPLATES);
	}

	private getFolderOptions(component: DashboardTemplatesManagementComponent): IFolderActions {
		let templates: DashboardTemplate[] = component.templates;

		return {
			CREATE_SUBFOLDER: {
				text: this.locale.getString('dashboard.addSubfolder'),
				name: 'add_folder',
				func: (folder: IFolderItem) => this.actionsService.createSubFolder(folder, templates)
			},
			RENAME_FOLDER: {
				name: 'rename',
				text: this.locale.getString('common.rename'),
				func: (folder: IFolderItem) => this.actionsService.renameFolder(folder, templates)
			},
			MOVE: {
				text: this.locale.getString('dashboard.move'),
				name: 'move',
				searchBox: true,
				isExpandable: true
			},
			REMOVE_SUBFOLDER: {
				text: this.locale.getString('dashboard.menuDeleteSubfolder'),
				name: 'delete',
				func: (folder: IFolderItem) => this.actionsService.removeFolder(folder, templates)
			},
			REMOVE_FOLDER: {
				text: this.locale.getString('common.delete'),
				name: 'delete',
				func: (folder: IFolderItem) => this.actionsService.removeFolder(folder, templates)
			}
		};
	}

	getContextMenu(template: DashboardTemplate,
		component: DashboardTemplatesManagementComponent): Array<ContextMenuItem<DashboardTemplate | IFolderItem>> {
		if (template.type === FolderTypes.DASHBOARD_TEMPLATES) {
			if (this.authorization.hasManageTemplatePermission()) {
				let folderOptions = this.getFolderOptions(component);
				return this.folderContextMenuUtils.getFolderMenuOptions(template as any,
					component.templates, folderOptions, (item: DashboardTemplate | IFolderItem, folder: IFolderItem) => {
						this.actionsService.moveToFolder(item, folder, component.templates);
					});
			}
			else return [];
		}

		return this.getTemplateMenuOptions(template, component);
	}

	isVisibleObject(item: any): boolean {
		return true;
	}

	private getTemplateMenuOptions(template: DashboardTemplate,
		component: DashboardTemplatesManagementComponent): Array<ContextMenuItem<DashboardTemplate>> {
		let folderOptions = this.getFolderOptions(component);

		let options: Array<ContextMenuItem<DashboardTemplate>> = [this.OPTIONS.CREATE];

		if (this.assetSpecificUtils.canShareTemplate(template)) {
			let sharingOption = template.publicStatus === SharingStatus.PRIVATE
				? this.OPTIONS.SHARE_TEMPLATE
				: this.OPTIONS.UNSHARE_TEMPLATE;

			options.push(sharingOption);
		}

		if (this.authorization.hasManageTemplatePermission() || this.authorization.hasCreateTemplatePermission()) {
			if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES)) {
				options.push(this.OPTIONS.UPGRADE_TEMPLATE);
			}
			options.push(this.OPTIONS.EDIT_PROPERTIES, this.OPTIONS.EDIT_MAPPINGS);

			if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATES_IMPORT_EXPORT)
					&& this.security.isSysAdmin()) {
				options.push(this.OPTIONS.EXPORT);
			}

			let moveToItems = this.folderService.getFoldersForMove(component.templates, template,
				(itemToMove, folderTo) => this.actionsService.moveToFolder(itemToMove, folderTo, component.templates));

			if (moveToItems && moveToItems.length > 0) {
				options.push(this.extend(folderOptions.MOVE, {items: moveToItems}));
			}

			if (template.ownerName === this.security.getEmail() || this.authorization.hasManageTemplatePermission()) {
				options.push(BaseContextMenuUtils.MENU_DIVIDER);
				options.push(this.OPTIONS.DELETE_TEMPLATE);
			}
		}

		return options;
	}
}
