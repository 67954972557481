import { EventEmitter, Input, SimpleChanges, Output, OnInit, OnChanges, ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountsMap } from '@app/modules/user-administration/user-create/user-create-wizard.component';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils } from '@app/util/change-utils';
import { ContentProviderAccess } from '@cxstudio/user-administration/users/project-access/content-provider-access-class';
import { UserEditFormData } from '../../editor/user-edit-form-data';
import { WorkspaceAccess } from '../../editor/workspaces-projects-access/workspace-access';


@Component({
	selector: 'user-create-projects-access-step',
	templateUrl: './user-create-projects-access-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateProjectsAccessStepComponent implements OnInit, OnChanges {
	@Input() finishEnabled: boolean;
	@Input() isWorkspaceEnabled: boolean;
	@Input() user: UserEditFormData;
	@Input() license: any;
	@Input() licenses: any;

	@Input() contentProvidersWithAccounts: AccountsMap;
	@Input() sourceUserId: number;

	@Output() changeProjectAccess = new EventEmitter<ContentProviderAccess[]>();
	@Output() accessChange = new EventEmitter<WorkspaceAccess[]>();

	constructor(
		private wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.wizardStep.markAsLast(true);
		this.wizardStep.setValid(this.finishEnabled);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.finishEnabled)) {
			this.wizardStep.setValid(changes.finishEnabled.currentValue);
		}
	}
}
