import { AlertAutofillUtils } from '@app/modules/alert/alert-autofill-utils';
import { InboxTemplateTitleUtils } from '@app/modules/inbox-template/inbox-template-title-utils.class';
import { InclusionListItem } from '@app/shared/components/inclusions-list/inclusion-list.component';
import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import { UIOption } from '@clarabridge/unified-angular-components';
import IAlertSubscriptionTemplate, { AlertSubscriptionTemplateType } from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { CasePriorityTypesService } from '@app/modules/alert-subscription-template/services/case-priority-types.service';
import { Security } from '@cxstudio/auth/security-service';
import { IListOption } from '@cxstudio/drivers/editor/list-option.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import {
	ASTInclusionListUtilsService
} from '@app/modules/alert-subscription-template/services/ast-inclusion-list-utils.service';
import { Assignee } from './types/assignee';
import { CaseTitleMode } from './types/case-title-mode.enum';
import { SubscriptionTemplateTitleVariable } from './types/subscription-template-title-variable';
import { PromiseUtils } from '@app/util/promise-utils';

export class ASTCaseInformation implements ng.IComponentController {

	readonly CASE_TITLE_LIMIT: number = 150;

	selectionCallback: () => void;
	caseTitleModeOptions: Array<UIOption<CaseTitleMode>>;
	caseTitleModes = CaseTitleMode;

	titleRequiredError: boolean;
	titleLengthError: boolean;
	showWithHierarchyAssignee: boolean;
	priorityOptions;
	template: Partial<IAlertSubscriptionTemplate>;
	titleVariables;

	attributeLimit: number;
	modelLimit: number;

	errors: {
		topicNotSelected: boolean;
		titleRequired: boolean;
	};

	callbacks = {
		attributes: {
			available: this.astInclusionListUtils.availableAttributesLabelCallback,
			selected: this.astInclusionListUtils.selectedAttributesLabelCallback
		},
		models: {
			available: this.astInclusionListUtils.availableModelsLabelCallback,
			selected: this.astInclusionListUtils.selectedModelsLabelCallback
		}
	};

	isPastItemLimit = this.astInclusionListUtils.isPastItemLimit;
	getAutofillOptions = AlertAutofillUtils.getAutofillOptions;

	currentModelTree: SimpleTopicSelection;
	includedModels: any[];
	selectedModel: any;
	modelLoading: ng.IPromise<any>;

	pinnedCaseMetadataSelection: InclusionListItem[] = [];

	constructor(
		private locale: ILocale,
		private security: Security,
		private casePriorityTypes: CasePriorityTypesService,
		private astInclusionListUtils: ASTInclusionListUtilsService) {
	}

	$onInit = () => {
		this.caseTitleModeOptions = [
			{ value: CaseTitleMode.DEFAULT, displayName: this.locale.getString('alertTemplates.caseTitleModeDefault') },
			{ value: CaseTitleMode.CUSTOM, displayName: this.locale.getString('alertTemplates.caseTitleModeCustom'), labelClasses: 'nowrap' }
		];

		this.priorityOptions = this.casePriorityTypes.getPriorityTypes();
		this.priorityOptions.forEach(option => {
			option.htmlLabel = `<span class="priority" style="color: ${option.iconColor}">
				<span class="priority-base q-icon-priority-4"></span>
				<span class="priority-level q-icon-priority-${option.level}"></span>
			</span>${option.displayName}`;
		});

		if (!this.isMetricTemplate()) {
			this.includedModels = angular.copy(this.template.caseMetadata.models);
		}

		if (this.isScorecardTemplate()) {
			this.pinnedCaseMetadataSelection = [{
				name: '',
				displayName: this.locale.getString('alertTemplates.scorecardResults')
			}];
		}

		if (this.isProductFeedbackTemplate()) {
			this.pinnedCaseMetadataSelection = [{
				name: '',
				displayName: this.locale.getString('alertTemplates.feedbackContext')
			}];
		}

		this.showWithHierarchyAssignee = this.isScorecardTemplate() || this.isNewVerbatimTemplate();
	}

	updateSelectedAttributes = (attributes: IListOption[]): void => {
		this.template.caseMetadata.attributes = attributes;
		this.selectionCallback();
	}

	updateSelectedModels = (models: IListOption[]): void => {
		this.includedModels = models;
		this.updateTemplateModel();
		this.selectionCallback();
	}

	showLock = (): boolean => {
		return !this.isMetricTemplate();
	}

	onTopicChanged = (): void => {
		this.errors.topicNotSelected = false;
	}

	isProductFeedbackTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.PRODUCT_FEEDBACK;
	}

	isScorecardTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.SCORECARD;
	}

	isMetricTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.METRIC;
	}

	isNewVerbatimTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.NEW_VERBATIM;
	}

	isEngagorIntegrationEnabled = (): boolean => this.security.getCurrentMasterAccount().engagorEnabled;

	showAssigneeControls = (): boolean => {
		return this.isEngagorIntegrationEnabled();
	}

	changeTitleMode = (mode): void => {
		this.template.caseSettings.titleMode = mode;
		this.checkTitle();
	}

	getTitleHelp = (): string => {
		return this.getHelp(this.locale.getString('alertTemplates.metricCaseTitleHelp'), this.locale.getString('alertTemplates.interactionCaseTitleHelp'));
	}

	getPriorityHelp = (): string => {
		return this.getHelp(this.locale.getString('alertTemplates.priorityTooltip'), this.locale.getString('alertTemplates.interactionPriorityTooltip'));
	}

	getAssigneeHelp = (): string => {
		return this.getHelp(this.locale.getString('alertTemplates.assigneeHelp'),
			this.locale.getString('alertTemplates.interactionAssigneeHelp'));
	}

	private getHelp = (metricTemplateHelpHelp, interactionTemplateHelp) => {
		return this.template.type === AlertSubscriptionTemplateType.METRIC
			? metricTemplateHelpHelp
			: interactionTemplateHelp;
	}

	checkTitle = (): void => {
		if (this.template.caseSettings.titleMode === CaseTitleMode.CUSTOM) {
			this.errors.titleRequired = !this.template.caseSettings.customTitle;
			this.titleLengthError = this.template.caseSettings.customTitle?.length > this.CASE_TITLE_LIMIT;
		} else {
			this.titleLengthError = false;
			this.errors.titleRequired = false;
		}

		this.selectionCallback();
	}

	getTitleVariables = (): SubscriptionTemplateTitleVariable[] => this.titleVariables;

	getVariableOutput = (variable: SubscriptionTemplateTitleVariable): string => {
		return InboxTemplateTitleUtils.getVariableDisplayText(variable);
	}

	onModelSelectionChanged = (models: any[]): void => {
		_.each(models, model => {
			if (!model.selected && this.currentModelTree?.getRoot().modelId === model.id)
				this.currentModelTree = undefined;
		});
	}

	onModelSelected = (models: any[]): void => {
		let modelId = _.size(models) === 1 ? models[0].id : undefined;
		if (!modelId) {
			this.currentModelTree = undefined;
			return;
		}
		if (this.currentModelTree?.getRoot().modelId !== modelId) {
			let props = this.astInclusionListUtils.getProjectSelection(this.template);
			this.selectedModel = _.findWhere(this.template.caseMetadata.models, {id: modelId});
			this.modelLoading = PromiseUtils.old(
				this.astInclusionListUtils.getCurrentModelTree(modelId, props, this.selectedModel)
			)
				.then((result: SimpleTopicSelection) => {
					this.currentModelTree = result;
				});
		}
	}

	updateTemplateModel = (): void => {
		this.template.caseMetadata.models = this.includedModels.map(model => {
			let existingModel = _.findWhere(this.template.caseMetadata.models, {id: model.id});
			model.nodeIds = existingModel ? existingModel.nodeIds : [];
			return model;
		});
	}

	getCaseMetadataHelp = (): string => {
		let help = this.isEngagorIntegrationEnabled()
			? this.locale.getString('alertTemplates.caseMetadataHelp', {
				attributeLimit: this.attributeLimit,
				modelLimit: this.modelLimit})
			: this.locale.getString('alertTemplates.caseMetadataAlertNotificationHelp', {
				attributeLimit: this.attributeLimit,
			});

		if (this.isScorecardTemplate()) {
			help += ' ' + this.locale.getString('alertTemplates.caseMetadataScorecardHelpNote');
		}

		return help;
	}

	getAssignee = (): any => {
		return this.template.caseSettings.assigneeObject || null;
	}

	setAssignee = (assignee: Assignee): void => {
		this.template.caseSettings.assigneeObject = assignee;
	}

	clearAssignee = (): void => {
		this.template.caseSettings.assigneeObject = null;
	}

	getAutofillText = (item: {label: string}): string => {
		return `{${item.label}}`;
	}

	getPanelTitle = (): string => {
		return this.locale.getString('alertTemplates.caseInformation');
	}

	getCaseTitle = (): string => {
		return this.locale.getString('alertTemplates.caseTitle');
	}

	getAssigneeLabel = (): string => {
		return this.locale.getString('cases.assignee');
	}

}

app.component('astCaseInformation', {
	bindings: {
		template: '<',
		titleVariables: '<',
		caseMetadata: '=',
		modelLimit: '<',
		attributeLimit: '<',
		selectionCallback: '<',
		engagorTopics: '<',
		engagorAssignees: '<',
		errors: '=',
	},
	controller: ASTCaseInformation,
	templateUrl: 'partials/alert-subscription-templates/ast-case-information.component.html'
});
