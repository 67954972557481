
import MobileAppSettingsApiService from '@cxstudio/mobile/mobile-app-settings-api.service';
import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MobileAppContextMenu } from './mobile-app-context-menu';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import InvocationCacheService from '@app/core/invocation-cache-service.class';
import { MobileSettingsActionService } from './mobile-settings-action.service';
import { MobileAppSettingsList } from './mobile-app-settings-list';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';

export default class MobileAppSettingsTableController implements ng.IComponentController {

	settingsListLoading: ng.IPromise<any>;
	settingsUpdating: ng.IPromise<any>;

	visibleSettingsList: MobileAppSettingsListModel[] = [];

	gridType: GridTypes = GridTypes.MOBILE_SETTINGS;
	gridOptions: SlickgridOptions;
	gridChange: MobileAppSettingsListModel[] = [];

	search: string = '';
	widgetSettingsCache: CachedInvocation;

	private settingsList: MobileAppSettingsList;

	constructor(
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private $filter: ng.IFilterService,
		private gridUtils: GridUtilsService,
		private globalNotificationService: GlobalNotificationService,
		private locale: ILocale,
		private mobileAppContextMenu: MobileAppContextMenu,
		private contextMenuTree: ContextMenuTree,
		private mobileSettingsActionService: MobileSettingsActionService
	) {}

	$onInit(): void {
		this.initializeWidgetSettingsCache();
		this.reloadSettingsList();
		this.initGridOptions();
	}

	createSettings = (): void => {
		this.mobileSettingsActionService.createSettings(this.settingsList, this.widgetSettingsCache)
			.then(() => this.reloadSettingsList());
	}

	onSearch = (): void => {
		let items = this.settingsList.items;
		items = this.$filter('filter')(items, { name: this.search });
		items = this.$filter('orderBy')(items, 'name');

		this.visibleSettingsList = items;
		this.refreshItemsInGrid(this.visibleSettingsList);
	}

	canSwitchEnabledToggle = (item: MobileAppSettingsListModel): boolean => {
		if (!this.mobileSettingsActionService.canEdit(item)) {
			return false;
		} else if (item.internal) {
			return true;
		} else {
			return item.enabled || !this.settingsList.regularEnabledAppsLimitReached;
		}
	}

	getDisabledSettingsMessage = (item: MobileAppSettingsListModel): string => {
		if (this.settingsList.regularEnabledAppsLimitReached) {
			return this.locale.getString('mobile.errorEnabledSettingsLimitReached');
		} else if (!this.mobileSettingsActionService.canEdit(item)) {
			return this.locale.getString('mobile.errorCanNotEditSettings');
		}
	}

	private reloadSettingsList = (): void => {
		this.settingsListLoading = this.mobileAppSettingsApiService.listSettings()
			.then(settingsList => {
				this.settingsList = settingsList;
				this.onSearch();
			});
	}

	private initGridOptions = (): void => {
		this.gridOptions = {
			onClick: this.onSettingsRowClick
		};
	}

	private onSettingsRowClick = (event: Event, settings: MobileAppSettingsListModel): void => {
		if (this.gridUtils.isMenuClick(event)) {
			this.showSettingsContextMenu(event, settings);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.toggleSettingsEnabledState(settings);
		}
	}

	private showSettingsContextMenu = (event: Event, settings: MobileAppSettingsListModel): void => {
		const contextMenu = this.mobileAppContextMenu.build(settings, this.settingsList, this.reloadSettingsList, this.widgetSettingsCache);
		this.contextMenuTree.showObjectListMenu(event, settings, contextMenu, 'mobileSettings', 360);
	}

	private toggleSettingsEnabledState = (settings: MobileAppSettingsListModel): void => {
		if (!settings.enabled && !this.canSwitchEnabledToggle(settings)) {
			return;
		}

		settings.enabled = !settings.enabled;
		this.settingsUpdating = this.mobileAppSettingsApiService.updateEnabledState(settings.id, settings.enabled)
			.then(() => {
				const changedStateNotificationMessage = settings.enabled
					? this.locale.getString('mobile.notificationEnabledApplication', { name: settings.name })
					: this.locale.getString('mobile.notificationDisabledApplication', { name: settings.name });
				this.globalNotificationService.addSuccessNotification(changedStateNotificationMessage);

				this.reloadSettingsList();
			});
	}

	private refreshItemsInGrid = (items?: MobileAppSettingsListModel[]): void => {
		this.gridChange = [].concat(items);
	}

	private initializeWidgetSettingsCache = (): void => {
		this.widgetSettingsCache = InvocationCacheService.createCachedInvocation();
	}

}

app.component('mobileAppSettingsTable', {
	controller: MobileAppSettingsTableController,
	templateUrl: 'partials/mobile/mobile-app-settings-table.html'
});
