import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class NetworkDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let description = '';
		let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		let grouping =  selectedAttribute?.displayName;
		let cooccurrence = this.properties.documentLevelOnly ? this.locale.getString('widget.interaction') : this.locale.getString('widget.sentence');
		if (grouping) {
			let total = this.utils.getTotal(this.visual, data);
			let palette = this.utils.getPaletteDescription('networkPalette', this.visual.color);
			description = this.locale.getString('widgetDescription.networkDescription', {
				grouping,
				cooccurrence,
				total,
				palette
			});
		}
		let topItems = this.utils.getTopItems(data, selectedAttribute, this.visual.size);
		if (topItems.length >= 2) {
			let metrics = this.properties.selectedMetrics || [];
			let topNodes = this.locale.getString('widgetDescription.topNodes', {
				node1: topItems[0],
				node2: topItems[1],
				size: this.utils.getMetricDisplayName(metrics, this.visual.size)
			});
			description = this.utils.joinMessageParts([description, topNodes]);
		}
		return description;
	}
	
}
