<collapsing-panel [collapsed]="false">
	<panel-heading>
		{{'mAccount.accountTuning' | i18n}}
		<cb-inline-help right>
			<help-body>{{'mAccount.accountTuningTooltip' | i18n}}</help-body>
		</cb-inline-help>
	</panel-heading>
	<panel-body>
		<toggle-list [headers]="headers" [data]="accountFeatures" (toggle)="toggleFeature($event)"></toggle-list>
		<alert *ngIf="showTopicsBanner && !isTopicsEnabled()"
			class="p-8 m-0"
			type="warning"
			[fullWidth]="true"
			text="{{'mAccount.topicsDisabledBanner'|i18n}}"></alert>
		<topics-tuning-suggestions
			class="d-block p-8"
			[disabled]="!isTopicsEnabled()"
		></topics-tuning-suggestions>
	</panel-body>
</collapsing-panel>
<collapsing-panel [collapsed]="false">
	<panel-heading>
		{{'mAccount.clarabridgeSystemTuning' | i18n}}
		<cb-inline-help right>
			<help-body>{{'mAccount.clarabridgeSystemTuningTooltip' | i18n}}</help-body>
		</cb-inline-help>
	</panel-heading>
	<panel-body>
		<toggle-list [headers]="headers" [data]="systemFeatures" (toggle)="toggleFeature($event)"></toggle-list>
	</panel-body>
</collapsing-panel>
