import { Injectable } from '@angular/core';
import { ISettingsEditor } from '@app/modules/asset-management/entities/settings-editor.interface';
import { AggregationSettings, AggregationSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/aggregation-settings-group';
import { NumberFormatSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/number-format-settings-group';
import { SettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.enum';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';


@Injectable()
export class NumericAttributeCalculationSettingsEditor implements ISettingsEditor<NumberFormatSettings> {

	private groups: {[key: string]: ISettingsGroup<any>};

	constructor(
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
	) {}

	setSettings(source: NumberFormatSettings): Promise<void> {
		let groups = {};

		groups[SettingsGroup.NUMBER_FORMAT] = new NumberFormatSettingsGroup(
			source, this.defaultDataFormatterBuilder.defaultFormatterSettings.ATTRIBUTE);

		groups[SettingsGroup.AGGREGATION] = new AggregationSettingsGroup(source as AggregationSettings);

		this.groups = groups;

		return Promise.resolve();
	}

	getGroups(): {[key: string]: ISettingsGroup<any>} {
		return this.groups;
	}

	getSettings(): NumberFormatSettings {
		let tabResult = {};

		for (const group of Object.keys(this.groups)) {
			tabResult = _.extend(tabResult, this.groups[group].postProcessSettings());
		}

		return tabResult as NumberFormatSettings;
	}
}
