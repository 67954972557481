export enum CustomMathErrorType {
	HANGING_OPERATOR = 'HANGING_OPERATOR',
	CONSECUTIVE_OPERATOR = 'CONSECUTIVE_OPERATOR',
	CONSECUTIVE_VARIABLE = 'CONSECUTIVE_VARIABLE',
	EMPTY_PARENTHESES = 'EMPTY_PARENTHESES',
	IMPLIED_MULTIPLICATION = 'IMPLIED_MULTIPLICATION',
	INVALID_TEXT = 'INVALID_TEXT', 
	MISSED_REFERENCE = 'MISSED_REFERENCE',
	ATTRIBUTE_MISMATCH = 'ATTRIBUTE_MISMATCH',
	NOT_COMPLETED_FUNCTION = 'NOT_COMPLETED_FUNCTION',
	NOT_COMPLETED_OPERATORS = 'NOT_COMPLETED_OPERATORS',
	MAX_VARIABLES_IN_EXPRESSION = 'MAX_VARIABLES_IN_EXPRESSION',
	NOT_SUPPORTED_AGGREGATION = 'NOT_SUPPORTED_AGGREGATION'
}

export interface CustomMathError {
	type: CustomMathErrorType;
	startOffset: number;
	text: string;
}

export enum CustomMathWarningType {
	DUPLICATE_NAME_WITH_OWNER = 'DUPLICATE_NAME_WITH_OWNER',
	DUPLICATE_NAME_WITH_PATH = 'DUPLICATE_NAME_WITH_PATH'
}

export interface CustomMathWarning {
	type: CustomMathWarningType;
	params: {[key: string]: string}; 
}

export enum WarningAssetType {
	METRIC = 'custom metric',
	SCORECARD_METRIC = 'scorecard metric'
}