import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { INode, ISearchableHierarchyItem } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { ObjectUtils } from '@app/util/object-utils';
import { TopicDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/topic-drill-utils.class';

@Component({
	selector: 'hierarchy-model-settings',
	templateUrl: './hierarchy-model-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyModelSettingsComponent implements OnInit {

	@Input() hierarchyId: number;
	@Input() peerReportType: PeerReportType;
	@Input() selectedLevel: number;
	@Output() selectedLevelChange = new EventEmitter<number>();
	@Input() checkedInclusionNodes: number[];
	@Output() checkedInclusionNodesChange = new EventEmitter<number[]>();

	PeerReportType = PeerReportType;
	loadingHierarchy: Promise<unknown>;

	selectedLevelNode: ISearchableHierarchyItem;

	hierarchyModelTree: ISearchableHierarchyItem[];
	hierarchyModelInclusionTree: ISearchableHierarchyItem[];

	constructor(
		private ref: ChangeDetectorRef,
		private organizationApiService: OrganizationApiService,
		@Inject('hierarchyService') private readonly hierarchyService: HierarchyService,
	) { }

	ngOnInit(): void {
		this.loadingHierarchy = this.organizationApiService.getHierarchyLevels(this.hierarchyId).then((levels) => {
			const selectedLevel = this.selectedLevel && this.selectedLevel <= levels.length
				? this.selectedLevel
				: 1;
			if (selectedLevel !== this.selectedLevel) {
				this.selectedLevel = selectedLevel;
				this.selectedLevelChange.emit(selectedLevel);
			}

			this.hierarchyModelTree = this.hierarchyService.getOrgHierarchyLevelTree(levels);
			this.hierarchyModelInclusionTree = ObjectUtils.copy(this.hierarchyModelTree);
			this.selectedLevelNode = TopicDrillUtils.findInHierarchy(this.hierarchyModelTree,
				'level', this.selectedLevel) as ISearchableHierarchyItem;

			let updatedCheckedInclusionNodes;
			if (this.checkedInclusionNodes) {
				updatedCheckedInclusionNodes = this.checkedInclusionNodes.filter(level => level <= levels.length);
			} else {
				updatedCheckedInclusionNodes = _.map(levels, item => item.level);
			}
			if (!_.isEqual(updatedCheckedInclusionNodes, this.checkedInclusionNodes)) {
				this.checkedInclusionNodes = updatedCheckedInclusionNodes;
				this.checkedInclusionNodesChange.emit(updatedCheckedInclusionNodes);
			}

			this.ref.markForCheck();
		});
	}

	onLevelNodeClick(node: ISearchableHierarchyItem): void {
		if (node && node.level) {
			this.selectedLevelChange.emit(node.level);
			this.selectedLevelNode = node;
		}
	}

	isInclusionNodeChecked = (params: {node: INode}): boolean => {
		return _.indexOf(this.checkedInclusionNodes, params.node.level) >= 0;
	}

	handleInclusionNodeCheck(node: ISearchableHierarchyItem): void {
		let itemIndex = _.indexOf(this.checkedInclusionNodes, node.level);

		if (itemIndex >= 0) {
			this.checkedInclusionNodes.removeAt(itemIndex);
		} else {
			this.checkedInclusionNodes.push(node.level);
		}
		this.checkedInclusionNodesChange.emit(this.checkedInclusionNodes);
	}

	isShowCheckbox = () => {
		return true;
	}

}
