import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { PreviewUpdateEntity, SidebarEditorService } from '@app/modules/layout/sidebar-editor/sidebar-editor.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';

@Component({
	selector: 'widget-preview',
	templateUrl: './widget-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPreviewComponent extends SelfCleaningComponent implements OnInit {
	loading: Promise<any>;

	//to pass
	widget: Widget;

	redrawTrigger: number;
	utils: WidgetUtils;

	constructor(
		private ref: ChangeDetectorRef,
		private sidebarEditor: SidebarEditorService,
		private realDataPreviewService: RealDataPreviewService,
		@Inject('widgetUtilsService') private widgetUtilsService: WidgetUtilsService,
	) {
		super();
	}

	ngOnInit(): void {
		this.widget = this.sidebarEditor.getEntity();
		this.loading = this.reloadUtils();

		this.addSubscription(this.sidebarEditor.getPreviewUpdateObserver().subscribe((previewUpdateEntity: PreviewUpdateEntity) => {
			this.widget = previewUpdateEntity.entity;
			this.updateChartSettings(previewUpdateEntity.refreshType);
		}));
	}

	private reloadUtils(): Promise<void> {
		return PromiseUtils.wrap(this.widgetUtilsService.getBuilder(this.widget).build()).then((utils) => {
			this.utils = utils;
			this.ref.detectChanges();
		});
	}

	onDataLoading(loading: Promise<any>): void {
		this.loading = loading;
	}

	showTotalCount(): boolean {
		return this.widget.visualProperties.showSampleSize;
	}

	updateChartSettings(refreshType?: PreviewChartRefreshType) {
		this.reloadUtils().then(() => {
			if (refreshType !== PreviewChartRefreshType.UI_ONLY) {
				this.realDataPreviewService.checkChangesForPreview(this.widget);
			}

			if (!this.realDataPreviewService.hasPreviewChanges()) {
				this.redrawTrigger = _.isUndefined(this.redrawTrigger) ? 1 : this.redrawTrigger + 1;
				this.ref.detectChanges();
			}
		});
	}
}
