<div>
	<ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
		<li ngbNavItem>
			<a ngbNavLink>{{'metrics.definition'|i18n}}</a>
			<ng-template ngbNavContent>
				<metric-definition-tab
					[mode]="mode"
					[properties]="properties"
					(propertiesChange)="propertiesChangeHandler($event)"
					[definition]="definition"
					(definitionChange)="definitionChangeHandler($event)"
					(formatChange)="formatChangeHandler($event)"
					(restoreDefaults)="restoreDefaultsHandler()"
					[project]="project"
					[assets]="assets"
					[selectedFolder]="selectedFolder"
					[folders]="folders"
					(onNameChange)="nameChangeHandler($event)"
					(onValidityChange)="validityChangeHandler('metricDefinitionTab', $event)"
				></metric-definition-tab>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>{{'metrics.format'|i18n}}</a>
			<ng-template ngbNavContent>
				<metric-format-tab
					[format]="formatObject"
					(formatChange)="formatChangeHandler($event)"
					[metric]="metric"
					[defaultsRestored]="defaultsRestoredSubject.asObservable()"
					(definitionChange)="definitionChangeHandler($event)"
					(onValidityChange)="validityChangeHandler('metricFormatTab', $event)"
				></metric-format-tab>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
