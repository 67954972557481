import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';

export default class ReferenceLine extends PlotLineStyle {
	value: number;
	label: string;
	displayLabel: string;
	labelLine: boolean;
	axis: PlotLineAxis;
	selectedBaseLineStyle: {type: any};
	selectedType: any;
	selectedPlotLineWidthNumber: boolean;

	constructor(value: number, style: PlotLineStyle) {
		super(style.type, style.width, style.color);
		this.value = value;
		this.displayLabel = '';
		this.labelLine = false;
	}
}
