import { HttpHandlers } from '@cxstudio/common/http-handlers';
import MobileAppSettings from './mobile-app-settings.interface';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { MobileAppSettingsSave } from './mobile-app-settings-save.interface';
import { MobileAppSettingsList } from './mobile-app-settings-list';


export default class MobileAppSettingsApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers
	) {
	}

	createSettings = (settings: MobileAppSettings): ng.IPromise<MobileAppSettings> => {
		return this.$http.post(`rest/mobile/settings`, settings).then(this.httpHandlers.success);
	}

	getNewSettings = (): ng.IPromise<MobileAppSettings> => {
		return this.$http.get('rest/mobile/settings/new').then(this.httpHandlers.success);
	}

	getNewMobileAppAccess = (): ng.IPromise<any> => {
		return this.$http.get(`rest/mobile/settings/access/new`).then(this.httpHandlers.success);
	}

	getSettings = (id: number): ng.IPromise<MobileAppSettings> => {
		return this.$http.get(`rest/mobile/settings/${id}`).then(this.httpHandlers.success);
	}

	listSettings = (): ng.IPromise<MobileAppSettingsList> => {
		return this.$http.get('rest/mobile/settings').then(this.httpHandlers.success);
	}

	saveSettings = (settingsSave: MobileAppSettingsSave): ng.IPromise<void> => {
		return this.$http.put(`rest/mobile/settings/${settingsSave.settings.id}`, settingsSave).then(this.httpHandlers.success);
	}

	duplicateSettings = (id: number, name: string): ng.IPromise<void> => {
		return this.$http.post(`rest/mobile/settings/${id}/duplicate`, null, { params: { name } }).then(this.httpHandlers.success);
	}

	deleteSettings = (id: number): ng.IPromise<void> => {
		return this.$http.delete(`rest/mobile/settings/${id}`).then(this.httpHandlers.success);
	}

	updateEnabledState = (settingsId: number, state: boolean): ng.IPromise<void> => {
		return this.$http.put(`rest/mobile/settings/${settingsId}/state`, state).then(this.httpHandlers.success);
	}

	saveMobileAppAccess = (settingsId: number, accessUpdate): ng.IPromise<void> => {
		return this.$http.put(`rest/mobile/settings/${settingsId}/access`, accessUpdate).then(this.httpHandlers.success);
	}

	getMobileAppAccess = (settingsId: number): ng.IPromise<any> => {
		return this.$http.get(`rest/mobile/settings/${settingsId}/access`).then(this.httpHandlers.success);
	}

	getWrongTopicReportRecipientsSuggestion = (emailSearch: string): ng.IPromise<User[]> => {
		let url = 'rest/mobile/settings/wrong-topic-report-recipients-suggestion';
		let params = {} as any;
		if (emailSearch) params.emailSearch = emailSearch;
		return this.$http.get(url, { params }).then(this.httpHandlers.success);
	}

	getOwnerCandidates = (search: string): ng.IPromise<string[]> => {
		const params = {
			search,
			limit: 10
		};

		return this.$http.get('rest/mobile/settings/owner-candidates', { params }).then(this.httpHandlers.success);
	}

}

app.service('mobileAppSettingsApiService', MobileAppSettingsApiService);
