import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Pill } from '@app/modules/pills/pill';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';

@Component({
	selector: 'enrichment-pill',
	template: `
	<button *ngIf="pill"
		class="enrichment-pill label-bubble h-32 border-radius-16 font-standard ph-8 mr-8 mb-8 no-border d-flex align-center"
		(click)="pillClick.emit($event)"
		[style.background]="pill.color"
		[attr.title]="pill.title"
		[ngClass]="classes">
		<span class="enrichment-pill-icon icon-highlight bg-none mr-4"
			[class.text-white]="!useContrastTextColor"
			[style.color]="getTextColor()">
			<i [ngClass]="pill.icon"></i>
		</span>
		<span class="font-bold"
			[class.text-white]="!useContrastTextColor"
			[style.color]="getTextColor()">{{ pill.name }}</span>
		<span *ngIf="removable"
			class="q-icon q-icon-delete p-0 ml-4 pill-remove color-inherit no-background"
			[style.color]="getContrastColor(pill.color)"
			title="{{'common.edit'|i18n}}">
		</span>
	</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrichmentPillComponent {
	@Input() pill: Pill;
	@Input() useContrastTextColor: boolean;
	@Input() classes: string[];
	@Input() removable = false;
	@Output() pillClick = new EventEmitter<MouseEvent>();

	getContrastColor(color: string): string {
		return ColorUtilsHelper.pickContrastTextColor(color);
	}

	getTextColor(): string {
		return this.useContrastTextColor ? this.getContrastColor(this.pill.color) : null;
	}
}
