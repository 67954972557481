import { CombinedFiltersService, FiltersGroup } from '@app/modules/filter/services/combined-filters.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { IScorecardFilterListItem } from '@app/modules/scorecards/entities/scorecard-filter-list-item';
import { ReportScorecardFiltersService } from '@app/modules/scorecards/filters/report-scorecard-filters.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { IgnoredDashboardFilterTag } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-tag';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DEFAULT_DATE_FILTER_MODE } from '@cxstudio/reports/entities/date-filter-mode';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import ProjectSettingsService from '@cxstudio/services/data-services/project-settings.service';
import WidgetService from '@cxstudio/services/widget-service';
import * as _ from 'underscore';
import { FilterUiUtilService } from '@app/modules/reports/filters/filter-ui-util.service';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { CbValidationService } from './services/cb-validation.service';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { ObjectUtils } from '@app/util/object-utils';
import { TypeGuards } from '@app/util/typeguards.class';

export class FilterManagementTabComponent implements ng.IComponentController {

	readonly maxFilterLevels = 5;

	itemList = [];
	newAppliedFilters = {
		type: FilterTypes.AND,
		filters: [ObjectUtils.copy(FilterEmptyObject.FILTER)]
	};

	newAdhocFilter: AdhocFilter = {
		type: FilterTypes.AND,
		filterRules: []
	};
	datepickerMode = {toDate: 'day', fromDate: 'day'};
	datepickerOptions = { from: {}, to: {} };

	availableMultiFiltersList = [];
	availableDateFilterTypes = [];

	dateRangeError = false;
	filtersOverloaded = false;
	filtersOverloadedSaved = false;
	filterRulesError = false;
	filterSaveSuccess = false;
	lockFiltersMessage: string = undefined;
	selectedFolder?;
	filterUsersChoice: string;
	attributes: IReportAttribute[];
	models: IReportModel[];
	filterItems?;
	noFiltersAvailable = false;
	lastSavedFilter?: string;
	savedFilterName?: string;
	saveFilter = false;

	filterSubmissionPromise;
	props: WidgetProperties;
	parsedDrillFilters;
	parsedLinkedFilters;
	widget: Widget;
	disableESQuery = true;
	dateFilterConfig: any = {};
	folderList;
	dateFilter;
	parsedModelGroupingFilters;

	projectSelection: IProjectSelection;
	loadingPromise: ng.IPromise<any>;
	filtersLoaded: boolean;

	sidebarEditorEnabled = false;

	constructor(
		private readonly $scope: ISimpleScope,
		private readonly $q: ng.IQService,
		private readonly $rootScope: ng.IRootScopeService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly reportScorecardFiltersService: ReportScorecardFiltersService,
		private readonly filterManagementApiService: FilterManagementApiService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly combinedFiltersService: CombinedFiltersService,
		private readonly DateRange,
		private readonly customFilterService: CustomFilterService,
		private readonly locale: ILocale,
		private readonly driversApi: DriversApi,
		private readonly filterValidationService: FilterValidationService,
		private readonly security: Security,
		private readonly cbValidationService: CbValidationService,
		private readonly currentWidgets: ICurrentWidgets,
		private readonly projectSettingsService: ProjectSettingsService,
		private readonly optionsBuilderProvider: OptionsBuilderProvider,
		private readonly optionsTemplatesService: OptionsTemplatesService,
		private readonly datePeriodUtils: DatePeriodUtils,
		private readonly dashboardFiltersService: DashboardFiltersService,
		private readonly filterUiUtilService: FilterUiUtilService,
		private readonly widgetService: WidgetService,
		private readonly ignoredDashboardFilterService: IgnoredDashboardFilterService,
		private readonly reportProcessingService: ReportProcessingService,
		private readonly widgetsEditService: WidgetsEditService,
		private betaFeaturesService: BetaFeaturesService,
	) {}

	$onInit = () => {
		if (this.requireDateFilters()) {
			if (this.widget.created || !this.props.dateRangeP1) {
				this.props.dateRangeP1 = {dateFilterMode: DEFAULT_DATE_FILTER_MODE};
			}

			this.dateFilter = {
				dateFilterMode: this.props.dateRangeP1.dateFilterMode,
				dateFilterRange: {
					from: this.props.dateRangeP1.from,
					to: this.props.dateRangeP1.to
				}
			};
		}

		this.filterUsersChoice = this.props.includeQualtricsEmployees ? 'YES' : 'NO';
		this.$scope.$on('clearSettings', () => {
			this.availableMultiFiltersList = [];
			this.clearFilters();
		});
		this.$scope.$on('clearFilters', this.clearFilters);

		this.$scope.$watchCollection(() => this.props.selectedAttributes, this.reloadGroupingFilters);
		this.$scope.$watch(() => this.props.lockFilters, this.refreshLockedFilters);
		this.$scope.$watch(() => this.projectSelection, () => this.initialize());

		this.sidebarEditorEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WIDGET_EDIT_OVERHAUL) &&
			this.props.widgetType === WidgetType.CLOUD;
	}

	private initialize(): void {
		this.availableMultiFiltersList = [];
		this.loadingPromise = this.initializeFiltersUI().then(() => this.reloadFilters());
	}

	private clearFilters = (): void => {
		this.newAppliedFilters.filters = [ObjectUtils.copy(FilterEmptyObject.FILTER)];
		this.resetFilterSavingSettings();
	}

	private validateFilterSettings = () => {
		this.dateRangeError = !this.cbValidationService.validateDateFilterRange(this.props);
	}

	// this depends on initializeFiltersUI() attributes/models loading
	private reloadFilters(): ng.IPromise<any> {
		if (this.props.documentLevelOnly) {
			return this.reloadScorecardFilters();
		}

		const project = this.reportAssetUtilsService.getWidgetProject(this.widget);
		let reportFiltersPromise = PromiseUtils.old(this.combinedFiltersService.getProjectFilters(project));
		let foldersPromise = this.filterManagementApiService.getFilterFolders(
			this.props.contentProviderId, this.props.accountId);
		let dateFiltersPromise;
		if (ReportConstants.isAnalyticWidgetNotSelector(this.props.widgetType)) {
			dateFiltersPromise = this.$q.when();
		} else {
			dateFiltersPromise = PromiseUtils.old(this.reportFiltersService.getWidgetDateFilters(this.widget));
		}

		delete this.filtersLoaded;

		return this.$q.all([reportFiltersPromise, dateFiltersPromise, foldersPromise])
			.then((result) => {
				this.processFilters(result[0]);
				if (result[1]) {
					this.dateFilterConfig.dateFilters = this.datePeriodUtils.processDateFilters(result[1]);
				}
				this.folderList = result[2].data;
				this.filtersLoaded = true;
			});
	}

	private initializeFiltersUI(): ng.IPromise<any> {
		return this.projectSettingsService.getSettings(this.projectSelection).then(settings => {
			const SUPPRESS_TEXT_FILTER = this.props.documentLevelOnly
				|| InternalProjectTypes.isStudioAdminProject(this.projectSelection.projectId);
			this.filterItems = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.filterItemsWithESQuery(
					SUPPRESS_TEXT_FILTER))
				.withModels(settings.models)
				.withAttributes(ObjectUtils.copy(settings.attributes), MetricFilters.NOT_DOCUMENT_DATE)
				.withDocumentLevelOnly(this.props.documentLevelOnly)
				.withWordAttributes(settings.wordAttributes)
				.build();

			this.attributes = settings.attributes;
			this.models = settings.models;
		});
	}

	private processFilters = (filterFolders: FiltersGroup[]): void => {
		this.availableMultiFiltersList = this.processVisibleFilters(filterFolders);

		if (this.props.appliedFilters) {
			this.newAppliedFilters = this.props.appliedFilters;
		} else {
			this.props.appliedFilters = this.newAppliedFilters;
		}

		if (this.props.adhocFilter) {
			this.newAdhocFilter = this.props.adhocFilter;
		} else {
			this.props.adhocFilter = this.newAdhocFilter;
		}

		if (this.props.documentLevelOnly) {
			this.removeSentenceLevelReferences();
		} else {
			this.customFilterService.updateAppliedFilters(this.availableMultiFiltersList, this.newAppliedFilters);
		}

		if (this.newAppliedFilters.filters.length === 0) {
			this.newAppliedFilters.filters = [ObjectUtils.copy(FilterEmptyObject.FILTER)];
		}
		if (this.newAdhocFilter.filterRules.length === 0) {
			this.newAdhocFilter.filterRules = [ObjectUtils.copy(FilterEmptyObject.RULE)];
		}

		for (let filter of this.availableMultiFiltersList) {
			if (filter && filter.list) {
				this.itemList = this.itemList.concat(filter.list);
			}
		}

		if (!isEmpty(this.props)) {
			this.reportProcessingService.getDrillFilters(this.props.drillFilters, this.widget).then((data) => {
				this.parsedDrillFilters = data;
			});

			let linkedFilters = this.currentWidgets.getLinkedFiltersProcessed(this.widget.containerId, this.widget.id, this.props.documentLevelOnly);
			this.reportProcessingService.getParsedLinkedFilters(linkedFilters, this.widget).then((data) => {
				this.parsedLinkedFilters = data;
			});

			this.reloadGroupingFilters();
		}
	}

	selectInternalChoice = (): void => {
		if (this.filterUsersChoice === 'NO') {
			this.props.includeQualtricsEmployees = false;
		} else if (this.filterUsersChoice === 'YES') {
			this.props.includeQualtricsEmployees = true;
		}
	}

	processVisibleFilters = (filterFolders: FiltersGroup[]): FiltersGroup[] => {
		let appliedFilters = this.props.appliedFilters ? this.props.appliedFilters.filters : [];

		_.each(filterFolders, (folder: any) => {
			if (!folder) {
				return;
			}
			folder.list = _.filter(folder.list, (filter: any) => {
				// all filters here have string "filterId"
				return !filter.hide
					|| !!_.findWhere(appliedFilters, { filterId: filter.filterId, type: filter.type });
			});
		});
		return filterFolders;
	}

	removeSentenceLevelReferences = (): void => {
		this.newAppliedFilters.filters = _.filter(this.newAppliedFilters.filters, filter => filter.type === FilterTypes.SCORECARD);
		this.newAdhocFilter.filterRules = _.filter(this.newAdhocFilter.filterRules, filterRule => {
			switch (filterRule.type) {
				case FilterRuleType.topicEquality:
					return true;
				case FilterRuleType.esQueryRule:
				case FilterRuleType.text:
					return false;
				default:
					return SearchableHierarchyUtils.findMetricNameInHierarchy(this.filterItems, filterRule.attributeName);
			}
		});
		this.props.ignoreDashboardTextFilter = true;
	}

	reloadGroupingFilters = (): void => {
		if (isEmpty(this.props))
			return;

		this.props.groupingFilters = this.props.groupingFilters || {};
		this.parsedModelGroupingFilters = [];
		this.reloadModelGroupingFilters();

		let driversGroup = this.getDriversGrouping();
		if (!!driversGroup) {
			this.lockFiltersMessage = this.locale.getString('drivers.lockFiltersMessage', {name: driversGroup.displayName});
		} else {
			this.lockFiltersMessage = undefined;
			this.props.lockFilters = false;
		}
	}

	toggleModelGroupingFilter = (groupingFilter): void => {
		this.props.groupingFilters[groupingFilter.identity] = groupingFilter.enabled;
	}

	reloadModelGroupingFilters = (): void => {
		this.customFilterService.getModelGroupingFilters(this.widget).then((filters) => {
			this.parsedModelGroupingFilters = filters;
			if (this.props.documentLevelOnly) {
				_.each(this.parsedModelGroupingFilters, (filter: any) => {
					filter.enabled = false;
					this.toggleModelGroupingFilter(filter);
				});
			}
		});
	}

	getDriversGrouping = (): any => _.find(this.props.selectedAttributes, AnalyticMetricTypes.isDrivers);

	refreshLockedFilters = (locked: boolean): void => {
		if (!locked)
			return;
		let driversGroup = this.getDriversGrouping();
		if (driversGroup) {
			// TODO move date filter part to vis tab
			this.loadingPromise = PromiseUtils.old(this.driversApi.getDriversFilters(driversGroup.id, true)).then((filters) => {
				let dateFilter = {
					dateFilterMode: this.DateRange.options.CUSTOM.value,
					from: filters.dateRange.fromDate,
					to: filters.dateRange.toDate
				};
				angular.extend(this.props.dateRangeP1, dateFilter); // if period selector hasn't initialized yet
				this.$rootScope.$broadcast('date-filters:refresh', dateFilter);
				this.applyDriversFilters(filters.additionalFilters);
			}, (reason) => {
				this.props.lockFilters = false;
				this.lockFiltersMessage = undefined;
				return this.$q.when();
			}).then(() => {
				// emitting event from here to make sure the event above is finished
				this.$scope.$emit('widget:updateRecommendedItems');
			});
		}
	}

	private reloadScorecardFilters(): ng.IPromise<any> {
		//only used for scorecard widget
		return PromiseUtils.old(this.reportScorecardFiltersService.getWidgetScorecardFilters(this.widget))
			.then(scorecardFilters => this.processScorecardFilters(scorecardFilters))
			.then(this.processFilters);
	}

	private processScorecardFilters(scorecardFilters: IScorecardFilterListItem[]): FiltersGroup[] {
		return [{
			label: this.locale.getString('filter.scorecardFilters'),
			list: this.combinedFiltersService.convertScorecardFilters(scorecardFilters)
		}];
	}

	private applyDriversFilters = (newAdhocFilter): void => {
		// replace all filters
		if (newAdhocFilter && !_.isEmpty(newAdhocFilter.filterRules)) {
			this.newAdhocFilter = newAdhocFilter;
		} else {
			this.newAdhocFilter.filterRules = [ObjectUtils.copy(FilterEmptyObject.RULE)];
		}
		this.newAppliedFilters.filters = [ObjectUtils.copy(FilterEmptyObject.FILTER)];

		this.props.appliedFilters = this.newAppliedFilters;
		this.props.adhocFilter = this.newAdhocFilter;
	}

	filterLayerCanBeRemoved = (filterItem): boolean => this.filterLayerIsDefined(filterItem) || this.newAppliedFilters.filters.length > 1;

	filterLayerIsDefined = (filterLayer): boolean => {
		return ( Boolean(filterLayer.type) && filterLayer.type !== FilterTypes.EMPTY && Boolean(filterLayer.name) );
	}

	filterLayersAvailable = (): boolean => this.newAppliedFilters.filters.length < this.maxFilterLevels;

	filtersAvailableForApplication = (): boolean => {
		for (let filter of this.availableMultiFiltersList) {
			if (filter && filter.list && filter.list.length > 0)
				return true;
		}
		return false;
	}

	showNoFiltersAvailableWarning = (): boolean => {
		return !this.filtersAvailableForApplication() && !(this.isStudioAdminProject());
	}

	isNeedIncludeQualtrics = (): boolean => {
		return this.isStudioAdminProject() && !this.security.isAdminOrgAccount();
	}

	isStudioAdminProject = (): boolean => {
		return InternalProjectTypes.isStudioAdminProject(this.props.project);
	}

	enableAddLayer = (): boolean => (this.filtersAvailableForApplication()
		&& this.filterLayersAvailable()
		&& (this.findEmptyLayer() === -1))

	isNewAppliedFilter = (filter): boolean => {
		return this.newAppliedFilters.filters.length > 1
			&& angular.equals(filter, FilterEmptyObject.FILTER);
	}

	/**
	 * If the only one layer exists, it needs to be cleared/reset, not removed
	 */
	private layerRequiresReset(): boolean {
		return this.newAppliedFilters.filters.length === 1;
	}

	removeFilterLayer = (index: number): void => {
		this.filtersOverloaded = false;
		if (this.filterLayerCanBeRemoved(this.newAppliedFilters.filters[index]) && !this.layerRequiresReset()) {
			this.newAppliedFilters.filters.splice(index, 1);
		} else {
			this.newAppliedFilters.filters[0] = ObjectUtils.copy(FilterEmptyObject.FILTER);
		}
	}

	applyFilterLayer = (filter, priorFilter): void => {
		angular.copy(filter, priorFilter);
	}

	applyRuleLayer = (rule, priorRule): void => {
		angular.copy(rule, priorRule);
	}

	isTextSelected = () => _.find(this.newAdhocFilter.filterRules, {type: FilterRuleType.text});

	findEmptyLayer = () => _.findIndex(this.newAppliedFilters.filters,
		(val) => angular.equals(val.type, FilterEmptyObject.FILTER.type))

	addFilterLayer = (filter): void => {
		let emptyLayerIndex = this.findEmptyLayer();
		if (filter === undefined) {
			if (!this.filtersAvailableForApplication()) {
				this.noFiltersAvailable = true;
				return;
			}
			if (emptyLayerIndex !== -1) return;
		}

		this.noFiltersAvailable = false;

		filter = filter || ObjectUtils.copy(FilterEmptyObject.FILTER);

		if (this.filterLayersAvailable() || (!angular.equals(filter, FilterEmptyObject.FILTER) && (emptyLayerIndex > -1))) {
			if (emptyLayerIndex > -1 && !angular.equals(filter, FilterEmptyObject.FILTER)) {
				this.newAppliedFilters.filters[emptyLayerIndex] = filter;
			} else {
				this.newAppliedFilters.filters.push(filter);

				if (angular.equals(filter, FilterEmptyObject.FILTER)) {
					this.filterUiUtilService.focusNewAppliedFilter();
				}
			}

			if (this.filterLayerIsDefined(filter)) {
				this.filterSaveSuccess = true;
				this.loadingPromise = this.reloadFilters();
			}
			if (!this.filterLayersAvailable()) {
				this.filtersOverloaded = true;
			}
		} else {
			this.filterSaveSuccess = false;
			if (this.filterLayerIsDefined(filter)) {
				this.filtersOverloadedSaved = true;
				this.loadingPromise = this.reloadFilters();

			} else {
				this.filtersOverloaded = true;
			}
		}
	}

	validateFilter = (rule) => {
		let validation = this.filterValidationService.validateFilterRules(rule);
		this.filterRulesError = !validation;
		return validation;
	}

	saveNewFilter = (filter): void => {
		if (this.filterIsValid(filter) && this.validateFilter(filter.rule)) {
			const filterDefinition = this.formatFilterForAPI(filter);
			if (filterDefinition) {
				this.filterSubmissionPromise = this.filterManagementApiService.createFilter(filterDefinition).then((savedFilter) => {
					this.addFilterLayer({type: FilterTypes.STUDIO, filterId: savedFilter.id.toString(), name: savedFilter.name});
					this.resetFilterSavingSettings();
				});
			}
		}
	}

	// need to flesh out validation more thoroughly...
	private filterIsValid = (filter): boolean => (filter.name && filter.name.length > 0);

	private customFilterIsValid = (filter): boolean => (this.filterIsValid(filter) && filter.rule);

	private resetFilterSavingSettings = (): void => {
		this.lastSavedFilter = this.savedFilterName;
		this.savedFilterName = '';
		this.saveFilter = false;
		this.selectedFolder = undefined;
		this.newAdhocFilter.filterRules = [ObjectUtils.copy(FilterEmptyObject.RULE)];
	}

	private formatFilterForAPI = (filter) => {
		let definition;

		if (this.customFilterIsValid(filter)) {
			definition = {
				contentProviderId: this.props.contentProviderId,
				accountId: this.props.accountId,
				projectId: this.props.project,
				projectName: this.props.projectName,
				name: filter.name,
				rule: filter.rule
			};
			if (filter.description) {
				definition.description = filter.description;
			}
			if (this.selectedFolder) {
				definition.parentId = this.selectedFolder;
			}
			if (this.props.workspaceProject) {
				definition.workspaceProject = this.props.workspaceProject;
			}

			this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(definition.rule.filterRules);
		}
		return definition;
	}

	private isDashboardDateFilterPresent = (): boolean => this.dashboardFiltersService.isDashboardDateFilterApplied(
		this.widgetsEditService.getDashboard())

	private isIgnoringDashboardDateRangeFilter = (): boolean => {
		let ignoredFilters = this.props.ignoredDashboardFilters;

		return this.ignoredDashboardFilterService.hasDateTag(ignoredFilters);
	}

	isInheritingDashboardDateFilter = (): boolean =>
		this.isDashboardDateFilterPresent() && !this.isIgnoringDashboardDateRangeFilter()

	dateModeSelection = (dateFilterMode, dateFilterRange, dateDisplayName) => {
		let period = this.props.dateRangeP1 = this.props.dateRangeP1 || {} as DateFilter;
		if (dateDisplayName) {
			period.dateDisplayName = dateDisplayName;
		}
		period.dateFilterMode = dateFilterMode;
		if (dateFilterRange) {
			period.from = dateFilterRange.from;
			period.to = dateFilterRange.to;
		}
		this.validateFilterSettings();
	}

	removeDrilledFilterRule = (index): void => {
		this.props.drillFilters.splice(index, 1);
		this.parsedDrillFilters.splice(index, 1);
	}

	isFilterCreator = (): boolean => this.security.has(MasterAccountPermissionAction.CREATE_FILTER);

	requireDateFilters = (): boolean => this.widgetService.requireDateFilters(this.props.widgetType);

	loadDashboardFilterTags = (query: string = ''): Promise<any> => {
		query = !query ? '' : query;
		let tags: IgnoredDashboardFilterTag[] = this.props.documentLevelOnly
			? this.ignoredDashboardFilterService.buildDocumentLevelOnlyTags()
			: this.ignoredDashboardFilterService.buildTags(this.attributes, this.models);

		return Promise.resolve(
			tags.filter((tag) => tag.text.toLowerCase().contains(query.toLowerCase()))
		);
	}

	// in ng-tags-input (angular tags-input), the suggestions.filter returns IgnoredDashboardFilterTag
	formatTag = (tag: string | IgnoredDashboardFilterTag): string => {
		if (TypeGuards.isString(tag)) {
			return tag;
		} else if (TypeGuards.isObject(tag)) {
			return tag.text;
		}
		return '';
	}

}

app.component('filterManagementTab', {
	bindings: {
		props: '<',
		projectSelection: '<',
		widget: '<',
		projectTimezone: '<'
	},
	controller: FilterManagementTabComponent,
	templateUrl: 'partials/widgets/settings/cb/filter-management-tab.component.html'
});
