import { Injectable } from '@angular/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { ITemplatePinnedAttribute } from '@cxstudio/alert-subscription-templates/templates-editor-modal.component';
import { IPromise } from 'angular';

@Injectable({
	providedIn: 'root'
})
export class CaseApiService {
	constructor(private http: CxHttpService) { }

	getCaseAttributes(project: IProjectSelection): IPromise<ITemplatePinnedAttribute[]> {
		const request = this.http.get<ITemplatePinnedAttribute[]>(
			`rest/cases/attributes/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`
		);

		return PromiseUtils.old(request).then(response => {
			return _.filter(response, attr => !attr.pinned);
		});
	}

	getWorkspaceCaseAttributes(workspaceProject: WorkspaceProject): IPromise<ITemplatePinnedAttribute[]> {
		const request = this.http.get<ITemplatePinnedAttribute[]>(
			`rest/cases/attributes/ws/${workspaceProject.workspaceId}/project/${workspaceProject.projectId}`
		);

		return PromiseUtils.old(request).then(response => {
			return _.filter(response, attr => !attr.pinned);
		});
	}
}

app.service('caseApiService', downgradeInjectable(CaseApiService));
