import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AbstractAssetParameters } from '@app/modules/asset-management/access/asset-parameters/abstract-asset-parameters';

export class HomePageCustomWidgetAssetParameters extends AbstractAssetParameters {
	static fromWidget(widget: Widget): HomePageCustomWidgetAssetParameters {
		return new HomePageCustomWidgetAssetParameters(widget.id);
	}

	constructor(
		private widgetId: number
	) {
		super(AssetParametersType.HOME_PAGE_CUSTOM_WIDGET);
	}

	getCacheIgnoreFields(): string[] {
		return ['widgetId'];
	}

	getHttpParams(): {[key: string]: string} {
		let params = { widgetId: this.widgetId.toString() };
		return _.extend(super.getHttpParams(), params);
	}
}
