import { GridsterEvent } from '@app/core/cx-event.enum';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetGridsterSelectors } from '@cxstudio/dashboards/components/widget-gridster-selectors.constant';
import { LayoutHelper, ZoomLevel } from '@cxstudio/dashboards/layout-helper.service';
import { DashboardOptimization } from '@cxstudio/dashboards/optimization/dashboard-optimization.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import GridsterConfigurer, { GridsterWidgetMapping } from '@cxstudio/home/gridster-configurer';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';
import { IGridsterWidget, WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetLinkingService } from '@cxstudio/reports/utils/analytic/widget-linking-service';
import * as _ from 'underscore';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';

declare let app: ng.IModule;

export interface IDashboardGridsterScope extends ISimpleScope {
	dashboardContainer: IDashboardData;
	layout: LayoutHelper;
	placeholder: IGridsterWidget;
	panels: any;
	getWidgetMapping: (widget: Widget) => GridsterWidgetMapping;
	gridsterOptions: any;
	editMode: boolean;
	getWidgets: () => Widget[];
	canCopySelected: () => boolean;
	getZoomClasses: () => string;

	initialClickEvent: JQuery.MouseDownEvent;
	initialClickX: number;
	initialClickY: number;

	widgetMouseDown: (e, widget: Widget) => void;
	widgetMouseUp: (e, widget: Widget) => void;
	toggleWidgetSelection: (e, widget: Widget) => void;
	templates: any[];
	onDrop: (e, widget: Widget) => void;
	onOver: (e, ui) => void;
	onOut: (e, ui) => void;
	orderReports: (report: any) => number;
	changeZoomLevel: (zoomLevel: ZoomLevel) => void;
	isWidgetSelected: (widget: Widget) => boolean;
	isWidgetVisible: (widget: Widget) => boolean;
	mouseEnterWidget: (widget: Widget) => void;
	mouseLeaveWidget: () => void;
	clearWidgetSelection: (event: JQuery.MouseDownEvent) => void;
}
/**
 * Gridster related functionality
 */
// tslint:disable-next-line: only-arrow-functions & typedef
app.controller('DashboardGridsterCtrl', function(
	$scope: IDashboardGridsterScope,
	$timeout: ng.ITimeoutService,
	$window: ng.IWindowService,
	security: Security,
	sessionPreferences: SessionPreferencesService,
	widgetsEditService: WidgetsEditService,
	dashboardOptimization: DashboardOptimization,
	widgetLinkingService: WidgetLinkingService,
	layoutHelperService: LayoutHelper,
	gridsterConfigurer: GridsterConfigurer,
	betaFeaturesService: BetaFeaturesService,
	widgetVisibilityMonitor: WidgetVisibilityMonitorService
) {

	let DRAG_THRESHOLD = 50;

	initWatchers();
	dashboardOptimization.resumeWatchers();

	$scope.canCopySelected = widgetsEditService.canCopySelected;
	$scope.clearWidgetSelection = widgetsEditService.clearWidgetSelection;
	$scope.getZoomClasses = () => getZoomClasses();

	// helper to make zoom and pushing available across scopes
	$scope.layout = layoutHelperService;
	$scope.layout.reset();

	$scope.placeholder = null;

	$scope.getWidgetMapping = (widget: Widget): GridsterWidgetMapping => {
		return gridsterConfigurer.getWidgetMapping(widget);
	};

	let gridster = $(gridsterConfigurer.getGridsterSelector()).get(0) as HTMLElement;

	$scope.gridsterOptions = gridsterConfigurer.getDefaultGridsterOptions({
		widgetsProvider: () => widgetsEditService.getWidgets(),
		editModeValueProvider: () => $scope.editMode
	});

	$scope.gridsterOptions.draggable = {
		start: widgetUpdateStart('dragging', true),
		drag: () => widgetsEditService.updateGridsterHeight(),
		stop: widgetUpdateStop('dragging'),
		container: gridsterConfigurer.getGridsterSelector(),
		handle: '[gridster-drag-handle]'
	};

	$scope.gridsterOptions.resizable = {
		start: widgetUpdateStart('resizing'),
		resize: () => widgetsEditService.updateGridsterHeight(),
		stop: widgetUpdateStop('resizing'),
		handles: ['se', 'ne', 'nw', 'sw']
	};

	initLinkingUI();

	function widgetUpdateStart(eventProperty: 'dragging' | 'resizing', pauseWatchers?: boolean): (...args) => void {
		return (event, $element, widgetMapping: GridsterWidgetMapping, item) => {
			if (pauseWatchers)
				dashboardOptimization.pauseWatchers();
			$scope.layout[eventProperty] = true;
		};
	}

	function widgetUpdateStop(eventProperty: 'dragging' | 'resizing'): (...args) => void {
		return (event, $element, widgetMapping: GridsterWidgetMapping, item) => {
			dashboardOptimization.resumeWatchers();
			$scope.layout[eventProperty] = false;
			widgetsEditService.checkLayoutChanges().then(layoutAction => {
				widgetsEditService.applyDashboardChanges($scope.dashboardContainer.dashboard.id + '', layoutAction).then(() => {
					let type = eventProperty === 'dragging'
						? DashboardChangeType.MOVED
						: DashboardChangeType.RESIZED;
					widgetsEditService.addDashboardHistoryState(type, [layoutAction]);
				});
			}, _.noop);

			$timeout(recalculateScrollbar, 200);
			$timeout(() => widgetsEditService.updatePageBreakDelimiters(!$scope.editMode), 250);
			if (eventProperty === 'resizing') {
				$timeout(() => $scope.$broadcast(GridsterEvent.WIDGET_RESIZED, [widgetMapping.idProvider()]));
			}
		};
	}

	function initWatchers(): void {
		$scope.$watch('editMode', (value) => {
			$scope.gridsterOptions.resizable.enabled = value;
			$scope.gridsterOptions.draggable.enabled = value;

			let pushOnDrop = sessionPreferences.getProperty('dashboardEditor', 'pushOnDrop');
			changePushProperty(!pushOnDrop);

			$scope.changeZoomLevel('');
			if (widgetsEditService.getSelectedWidgetsCount() > 0) {
				widgetsEditService.deselectAllWidgets();
			}

			let viewMode = !value;
			$timeout(() => widgetsEditService.updatePageBreakDelimiters(viewMode), 250);

			$scope.layout.linkingSource = undefined;
		});

		$scope.$on(GridsterEvent.RESET_DRAG, () => {
			// if drag handle is not rendered present at initialization, gridster will treat the whole element as draggable
			// this off-on approach resets gridster state
			if ($scope.editMode) {
				$timeout(() => {
					$scope.gridsterOptions.draggable.enabled = false;
					$timeout(() => {
						$scope.gridsterOptions.draggable.enabled = true;
					}, 0);
				}, 0);
			}
		});

		$scope.widgetMouseDown = ($event, widget) => {
			$scope.initialClickEvent = $event;
			$scope.initialClickX = $event.screenX;
			$scope.initialClickY = $event.screenY;
		};
		$scope.widgetMouseUp = ($event, widget) => {
			let diffX = Math.abs($event.screenX - $scope.initialClickX);
			let diffY = Math.abs($event.screenY - $scope.initialClickY);
			if (diffX + diffY <= DRAG_THRESHOLD && $event.which === 1) {
				$timeout(() => {
					$scope.toggleWidgetSelection($event, widget);

				}, 100);
			}
		};

		$scope.$on('refreshSelectedWidgets', refreshSelectedWidgets);
		$scope.$watch('layout.pushOnDrop', changePushOnDropProperty);
		$scope.$watch('layout.zoomLevel', changeZoomLevelInternal);


		widgetVisibilityMonitor.reset();
	}

	function refreshSelectedWidgets(): void {
		let selectedWidgets = widgetsEditService.getSelectedWidgets();
		let selectedWidgetIds = _.pluck(selectedWidgets, 'id');
		$scope.$broadcast('widgetsHardRefreshEvent', selectedWidgetIds);
	}



	$scope.onDrop = (e, widget: Widget) => {
		let report = extractReportType(widget);
		if (!report)
			return;
		$timeout(() => { // let gridster update coordinates of placeholder
			addWidgetInternal(report, $scope.placeholder);
			$scope.placeholder = null;
		});
	};

	function extractReportType(widget: any): Widget {
		if (widget.helper[0].canceled || !widget.draggable)
			return;
		let reportName = $(widget.draggable).attr('data-name');
		if (!reportName)
			return;
		let report = _.findWhere($scope.templates, {name: reportName});
		return report;
	}

	function getNewWidgetPosition(): IGridsterWidget {
		let sizeX = gridsterConfigurer.getGridsterItemWidth();
		let sizeY = gridsterConfigurer.getGridsterItemHeight();

		let col = 0;
		let row = 0;
		let widgets = widgetsEditService.getWidgets();
		if (widgets && widgets.length) {
			// calculate new place for widget
			let bottom = _.max(widgets, w => w.posY + w.height) as Widget;
			row = bottom.posY + bottom.height;
			let bottomItems = _.sortBy(_.filter(widgets, w => (w.posY + w.height) === row), w => w.posX);

			if (bottomItems[0].posX >= sizeX) { // free space on left
				col = 0;
			} else {
				let foundPlace = false;
				// check if there is space between widgets
				for (let i = 0; i < bottomItems.length - 1; i++) {
					if (bottomItems[i + 1].posX - bottomItems[i].posX - bottomItems[i].width >= sizeX) {
						col = bottomItems[i].posX + bottomItems[i].width;
						foundPlace = true;
						break;
					}
				}
				if (!foundPlace) { // check right side
					let last = bottomItems[bottomItems.length - 1];
					if (gridsterConfigurer.getGridsterColumns() - last.posX - last.width >= sizeX) {
						col = last.posX + last.width;
					}
				}
			}
		}
		return {
			sizeX,
			sizeY,
			col,
			row
		};
	}

	$scope.$on('addNewWidget', (event, report: Widget) => {
		addWidgetInternal(report, getNewWidgetPosition());
	});

	function addWidgetInternal(report: Widget, gridsterItem: IGridsterWidget): void {
		let created = {
			type: report.type,
			name: report.name,
			displayName: report.displayName,
			properties: {},
			visualProperties: {}
		} as any as Widget;
		created.dashboardId = $scope.dashboardContainer.dashboard.id;
		created.containerId = created.dashboardId + '';
		created.width = gridsterItem.sizeX;
		created.height = gridsterItem.sizeY;
		created.posX = isNaN(gridsterItem.col) ? 0 : gridsterItem.col;
		created.posY = isNaN(gridsterItem.row) ? 0 : gridsterItem.row;
		created.properties.widgetType = created.name as WidgetType;
		created.properties.runAs = security.getEmail();
		created.properties.altTextFromTitle = true;
		created.properties.altText = created.displayName;
		created.properties.autoDescription = report.properties?.autoDescription !== false;

		let type = report.type.toLowerCase();
		if (betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			if (WidgetTypeFilters.isReportWidget(report)) {
				created.properties.workspaceProject = $scope.dashboardContainer.dashboard.workspaceProject;
			}
		}
		//our settings calls still relies on cp+account
		if ($scope.dashboardContainer.dashboard.properties[type + 'ContentProvider'] >= 0) {
			created.properties.contentProviderId = $scope.dashboardContainer.dashboard.properties[type + 'ContentProvider'];
		}
		if ($scope.dashboardContainer.dashboard.properties[type + 'Account'] >= 0) {
			created.properties.accountId = $scope.dashboardContainer.dashboard.properties[type + 'Account'];
		}
		if (WidgetTypeFilters.isReportWidget(report)) {
			if ($scope.dashboardContainer.dashboard.properties.project) {
				created.properties.project = $scope.dashboardContainer.dashboard.properties.project;
			}
			created.visualProperties.showSampleSize = $scope.dashboardContainer.dashboard.properties.defaultShowTotal !== false;
		}

		if (WidgetTypeFilters.isPageBreak(report)) {
			created.width = 24;
			created.height = 1;
			created.properties.pageBreakV2 = true;
		}

		if (created.name === WidgetType.PAGE_BREAK) {
			widgetsEditService.createAndSaveWidget(created);
		} else {
			created.created = true;
			if (widgetsEditService.canAddWidget(created)) {
				widgetsEditService.openSettings(created).then(widget => {
					_.extend(created, widget);
					widgetsEditService.saveNewWidget(created);
				}, _.noop);
			}
		}
	}

	$scope.onOver = (e, ui) => {
		let unitPx = $window.innerWidth / gridsterConfigurer.getGridsterColumns();
		if (!$scope.placeholder && !ui.helper[0].id) {
			let created = {} as IGridsterWidget;
			let template = extractReportType(ui);
			if (template && template.width) {
				// widget has default size
				created.sizeX = template.width;
				created.sizeY = template.height;
			} else {
				created.sizeX = gridsterConfigurer.getGridsterItemWidth();
				created.sizeY = gridsterConfigurer.getGridsterItemHeight();
			}
			if (!$scope.layout.pushOnDrop) {
				created.row = 0;
				created.col = 0;
			} else {
				if (!_.isEmpty(widgetsEditService.getWidgets())) {
					let maxWidget = _.max(widgetsEditService.getWidgets(), (widget) => {
						return widget.posY + widget.height;
					}) as Widget;
					// place new widget to the bottom to not move existing
					created.row = maxWidget.posY + maxWidget.height;
				} else {
					created.row = 0;
				}
				created.col = 0;
			}
			$scope.placeholder = created;
			$scope.$apply(); // need to apply because called by jquery
			// emulating native gridster dragging
			$timeout(() => {
				let placeholderElm = $('.br-widget-placeholder');
				if (!placeholderElm.length) // if we drop it faster than 100ms, e.g. in e2e
					return;
				// emulating dragging of widget's top center point
				let x = placeholderElm.offset().left + placeholderElm.width() / 2;
				let y = placeholderElm.offset().top;
				startDragging(x, y);
			}, 100);
		}

		// DOMMouseScroll for FF
		$(ui.helper).bind('DOMMouseScroll mousewheel', (event: JQuery.ScrollEvent) => {
			if (!gridster)
				return;
			if ((event.originalEvent as any).wheelDelta > 0) {
				gridster.scrollTop = gridster.scrollTop - unitPx;
			} else {
				gridster.scrollTop = gridster.scrollTop + unitPx;
			}
		});
		$scope.$apply();
	};

	function startDragging(x: number, y: number): void {
		let elem = $(WidgetGridsterSelectors.PLACEHOLDER_SELECTOR)[0];
		if (!elem)
			return;
		let event;

		event = new MouseEvent('mousedown', {
			clientX: x,
			clientY: y
		});
		elem.dispatchEvent(event);
	}

	$scope.onOut = (e, ui) => {
		//$(ui.helper).off('mousemove');
		$(ui.helper).off('mousewheel');
		$(ui.helper).off('DOMMouseScroll');

		$scope.placeholder = null;
		$scope.$apply();
	};

	$scope.orderReports = (report) => DashboardUtils.orderReports(report);

	function changeZoomLevelInternal(newZoomLevel): void {
		switch (newZoomLevel) {
		case 50: $scope.gridsterOptions.margins = [10, 10]; break;
		case 75: $scope.gridsterOptions.margins = [15, 15]; break;
		default: $scope.gridsterOptions.margins = [20, 20]; break;
		}
		$($window).triggerHandler('resize');
		$timeout(recalculateScrollbar, 500);
		$timeout(() => widgetsEditService.updatePageBreakDelimiters(!$scope.editMode), 500);
	}

	$scope.changeZoomLevel = (level: ZoomLevel) => {
		$scope.layout.changeZoomLevel(level, changeZoomLevelInternal);
	};

	$scope.isWidgetSelected = (widget: Widget) => widgetsEditService.isSelected(widget);

	$scope.isWidgetVisible = (widget: Widget) => {
		if (widget.name === WidgetType.PAGE_BREAK) {
			return $scope.editMode;
		}

		return true;
	};

	$scope.toggleWidgetSelection = (event, widget: Widget) =>
		widgetsEditService.toggleWidgetSelection(event, widget, $scope.editMode);

	function changePushProperty(updatedPushVal: boolean): void {
		$scope.gridsterOptions.pushOnDrop = !updatedPushVal;
		$scope.layout.pushOnDrop = !updatedPushVal;

		sessionPreferences.setProperty('dashboardEditor', 'pushOnDrop', !updatedPushVal);
	}

	function changePushOnDropProperty(updatedVal: boolean): void {
		changePushProperty(!updatedVal);
	}

	function recalculateScrollbar(): void {
		if (!gridster)
			return;
		$scope.layout.scrollbarOffset = gridster.offsetWidth - gridster.clientWidth;
		if ($scope.layout.heightLimit === null)
			initHeightHandler();
		widgetsEditService.updateGridsterHeight();

	}

	$scope.$on('gridster-resized', () => {
		recalculateScrollbar();
		$timeout(() => widgetsEditService.updatePageBreakDelimiters(!$scope.editMode), 250);
	});

	function initHeightHandler(): void {
		let gridsterScope = widgetsEditService.getGridsterScope();
		if (gridsterScope) {
			gridsterScope.$watch('gridster.curRowHeight', (height) => {
				if (height > 0) {
					$scope.layout.heightLimit = height * $scope.gridsterOptions.maxRows + $scope.gridsterOptions.margins[1] * 2;
					if ($scope.layout.heightLimit < 1000)
						$scope.layout.heightLimit = 0;
				} else {
					$scope.layout.heightLimit = 0;
				}
			});
		}
		$scope.layout.heightLimit = 0;
	}


	function initLinkingUI(): void {
		$scope.mouseEnterWidget = (widget: Widget) => {
			clearLinkedWidgets();
			widgetLinkingService.highlightLinkedWidgets(widget, addParentClass, addChildClass);
		};

		$scope.mouseLeaveWidget = () => {
			clearLinkedWidgets();
		};

		function clearLinkedWidgets(): void {
			$('.linked-widget-parent,.linked-widget-child').removeClass('linked-widget-parent linked-widget-child');
		}

		function addParentClass(id): void {
			$('#widget-' + id).addClass('linked-widget-parent');
		}

		function addChildClass(id): void {
			$('#widget-' + id).addClass('linked-widget-child');
		}
	}

	function getZoomClasses(): string {
		return $scope.layout.isZoomModeOn() ? 'bg-gray-500' : 'bg-dashboard';
	}

});
