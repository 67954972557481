import * as _ from 'underscore';
import ConversionWidgetProperty from './conversion-widget-property.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';

export const OBJECT_VIEWER_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('showSampleSize')
];
const MODEL_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('showLabels'), new ConversionWidgetProperty('orientation'),
	new ConversionWidgetProperty('size')
];

export class ModelViewerWidget extends SimpleReportWidget {

	constructor() {
		super(WidgetType.MODEL_VIEWER);
	}

	initFrom(source: Widget, replace: Replacement, previousSource: Widget): void {
		let context = super.getContext(source, replace);
		let previousStateContext = super.getContext(previousSource, replace);

		super.initSimpleReport(previousSource ? previousStateContext : context);

		super.initProperties(OBJECT_VIEWER_VISUAL_PROPERTIES, context, 'visualProperties');
		if (previousSource) {
			super.initProperties(MODEL_VISUAL_PROPERTIES, previousStateContext, 'visualProperties');
			this.widget.properties.selectedAttributes = WidgetPropertyService.getValue('properties.selectedAttributes',
				previousStateContext, []);
			this.widget.properties.selectedMetrics = WidgetPropertyService.getValue('properties.selectedMetrics',
				previousStateContext, []);
			super.withColors(previousStateContext);
		}
	}
}
