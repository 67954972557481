<div [ngBusy]="loading" class="pv-16 ph-32">

	<div *ngIf="!!hierarchy">
		<p>{{'organization.uploadUsersWizardInstructionNew'|i18n}}</p>

		<div class="wrappable-buttons form-group">
			<button
				class="btn btn-secondary"
				title="{{'organization.downloadTemplate'|i18n}}"
				[attr.aria-label]="'organization.downloadTemplate'|i18n"
				(click)="downloadTemplateFile()">
				{{'organization.downloadTemplate'|i18n}}
			</button>
			<button *ngIf="showExistingStructureButton()"
				class="btn btn-secondary"
				title="{{'organization.downloadExistingUsers'|i18n}}"
				[attr.aria-label]="'organization.downloadExistingUsers'|i18n"
				(click)="downloadDataFile()">
				{{'organization.downloadExistingUsers'|i18n}}
			</button>
		</div>

		<div class="wrappable-buttons form-group">
			<checkbox-button
				data-testid="generate-groups-wizard"
				class="mb-8"
				label="{{'organization.skipNonExistUsers'|i18n}}"
				inlineHelp="{{'organization.skipNonExistUsersHelper'|i18n}}"
				[(ngModel)]="skipNonExistUsers">
			</checkbox-button>
		</div>
			
		<div class="wrappable-buttons form-group" *ngIf="!isDynamicFilteringHierarchy()">
			<checkbox-button
				data-testid="generate-groups-wizard"
				class="mb-8"
				label="{{'organization.autoGenerateGroups'|i18n}}"
				[(ngModel)]="generateGroups">
			</checkbox-button>
		</div>

		<div class="wrappable-buttons form-group">
			<file-upload
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.XLS, MediaType.CSV]"
				[uploadButtonLabel]="getUploadButtonLabel()"
				[customUploadFailedMessage]="'organization.wizardFileUploadFailed'|i18n"
				[uploadMetadata]="getFileUploadMetadata()"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(erroneousUpload)="handleFailedUpload($event)"
				(removedUpload)="handleRemovedUpload()"
			></file-upload>
		</div>

	</div>
		
	<upload-result 
		[result]="uploadResult">
	</upload-result>

	<div *ngIf="userNonExistence">
		<table class="table-bordered scrollable-table hierarchy-error-users">
			<thead>
				<tr>
					<th class="p-4">{{'organization.path'|i18n}}</th>
					<th class="p-4">{{'administration.emailAddress'|i18n}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let node of userNonExistenceList">
					<td class="p-4" >{{node.nodeName}}</td>
					<td class="p-4" >{{getUsersForNode(node)}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	
</div>
