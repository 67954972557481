import Widget from '@cxstudio/dashboards/widgets/widget';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { DualAxisWidget } from '@cxstudio/reports/widgets/dual-axis-widget.class';
import { IReportWidgetContext, Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export class LineWidget extends DualAxisWidget {

	private readonly CALCULATION_SERIES_GROUPING = {name: '_calculation_series'};

	private readonly GROUPING_TYPES = [
		{ name: 'primaryGroup', index: 0, handler: (grouping) => this.setPrimaryGrouping(grouping) },
		{ name: 'secondaryGroup', index: 1, handler: (grouping) => this.setSeriesGrouping(grouping) }
	];

	constructor(metricConstants: MetricConstants) {
		super(WidgetType.LINE, metricConstants);
		this.exceptionalGroupings.push(this.CALCULATION_SERIES_GROUPING);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);
		this.exceptionsPreprocess(context);

		super.initDualAxis(context);

		this.widget.visualProperties.calculationSeries = [];

		if (source.properties.widgetType === WidgetType.LINE) {
			super.inherit('visualProperties.subChartType', context, ChartType.SPLINE);
			super.initPoints(context);
		} else {
			this.widget.visualProperties.subChartType = ChartType.SPLINE;
		}

		this.initGroupings(context);

		this.exceptionsPostprocess();
	}

	private setSeriesGrouping(grouping: ReportGrouping): void {
		if (grouping && grouping.name !== StandardMetricName.NONE) {
			let secondaryGroupingName = 'secondary';
			super.setGrouping(grouping, secondaryGroupingName);
		}
	}

	private exceptionsPreprocess(context: IReportWidgetContext): void {
		if (!_.isEmpty(context.replace)) return;
		// for line point color used as color in bar (CXS-109)
		if (context.source.properties.widgetType === WidgetType.BAR) {
			let secondaryGroup = WidgetPropertyService.getValue(
				'visualProperties.attributeSelections.secondaryGroup', context);
			let stackedGroup = WidgetPropertyService.getValue(
				'visualProperties.attributeSelections.stackedGroup', context);
			if (this.isEmptyGroup(secondaryGroup)
					&& !this.isEmptyGroup(stackedGroup)) {
				let visual = context.source.visualProperties;
				visual.attributeSelections.secondaryGroup = stackedGroup;
			}
		}
		if (context.source.properties.widgetType === WidgetType.TABLE && context.source.properties.useHistoricPeriod) {
		//if table widget and PoP, need to add PoP for secondary or stacked grouping
			context.source.visualProperties.attributeSelections = {secondaryGroup: this.POP_GROUPING};
		}
		//There is no 'BAR' secondary option for line widget, so convert to column
		let secondaryType = WidgetPropertyService.getValue(
			'visualProperties.secondaryChartType', context, 'BUBBLE');
		if (secondaryType === 'BAR') {
			context.source.visualProperties.secondaryChartType = 'COLUMN';
		}
	}

	private exceptionsPostprocess(): void {
		//line support sentiment or metric colors only for points
		//"_calculation_color._studio_metric_4",
		let color = this.widget.visualProperties.color;
		if (color && color.startsWith('_calculation_color')) {
			this.widget.visualProperties.color = null;
			this.widget.visualProperties.pointColor = color;
		}
	}

	private isCalculationSeriesGrouping(grouping: ReportGrouping): boolean {
		return grouping.name === this.CALCULATION_SERIES_GROUPING.name;
	}

	protected withGroupingDependencies(grouping: ReportGrouping, context: IReportWidgetContext): void {
		if (this.isCalculationSeriesGrouping(grouping)) {
			let calculationSeries = WidgetPropertyService.getValue(
				'visualProperties.calculationSeries', context, []) as ReportCalculation[];
			this.widget.visualProperties.calculationSeries = calculationSeries;
			_.each(calculationSeries, calculation => {
				super.addCalculation(calculation);
			});
		}
		super.withGroupingDependencies(grouping, context);
	}

	private initGroupings(context: IReportWidgetContext): void {
		let groupings = WidgetPropertyService.getValue(
			'properties.selectedAttributes', context, []);
		let selections = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections', context, {});

		this.GROUPING_TYPES.forEach(groupingType => {
			let grouping = selections[groupingType.name];
			grouping = !_.isEmpty(grouping)
				? grouping : groupings[groupingType.index];
			if (grouping) {
				groupingType.handler(grouping);
				this.withGroupingDependencies(grouping, context);
			}
		});
	}
}
