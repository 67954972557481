import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from '@app/modules/breadcrumb/breadcrumbs.component';
import { IconModule } from '@app/modules/icon/icon.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	imports: [CommonModule, IconModule, SharedModule],
	declarations: [BreadcrumbsComponent],
	exports: [BreadcrumbsComponent],
})
export class BreadcrumbModule {
}
