import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { FilterRuleType, FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { NLPAttributes } from '@cxstudio/reports/settings/options/nlp-attributes';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import * as cloneDeep from 'lodash.clonedeep';

@Injectable({
	providedIn: 'root'
})
export class OptionsTemplatesService {

	constants;
	metricFolderAttributes;
	nlpAttributes;
	moveToCalculationMetrics;

	constructor(
		@Inject('metricConstants') private readonly metricConstants,
		private readonly locale: CxLocaleService,
		@Inject('MetricCalculationsType') private readonly MetricCalculationsType: MetricCalculationsTypeService
	) {
		this.constants = this.metricConstants.get();

		this.nlpAttributes = new NLPAttributes(this.metricConstants); // replace with "new NLPAttributes(...)"
		this.metricFolderAttributes = [
			this.constants.CB_SENTENCE_WORD_COUNT.name,
			this.constants.CB_SENTENCE_QUARTILE.name,
			this.constants.CB_DOCUMENT_WORD_COUNT.name,
			this.constants.CB_LOYALTY_TENURE.name,
			this.constants.NLP_CB_CONV_OUTCOME.name,
			this.constants.NLP_CB_AGENT_OUTCOME.name,
			this.constants.NLP_CB_CLIENT_OUTCOME.name
		];

		this.moveToCalculationMetrics = this.metricFolderAttributes;
	}


	private getTextFilter = (name: FilterRuleType | string = FilterRuleType.text): any => {
		return {
			name,
			displayName: this.locale.getString('filter.textFilter'),
			type: FilterRuleType.text,
			objectType: AttributeObjectType.SENTENCE
		};
	}

	private getStandardGroup = (): any => {
		return {
			name: OptionsConstant.STANDARD,
			displayName: this.locale.getString('widget.groupStandard'),
			children: [],
			filter: (item) => item.standardMetric
		};
	}

	private getTopicsGroup = (): any => {
		return {
			name: OptionsConstant.TOPICS,
			displayName: this.locale.getString('widget.topics'),
			children: [],
			filter: (item) => item.type === OptionsConstant.TOPICS
		};
	}

	private getHierarchyModelsGroup = (): any => {
		return {
			name: OptionsConstant.HIERARCHY_MODEL,
			displayName: this.locale.getString('widget.hierarchyModel'),
			children: [],
			objectType: AttributeObjectType.SENTENCE,
			filter: (item) => AnalyticMetricTypes.isHierarchyModel(item)
		};
	}

	private getDriversGroup = (): any => {
		return {
			name: OptionsConstant.DRIVERS,
			displayName: this.locale.getString('widget.groupDrivers'),
			forcedOrder: true,
			children: [],
			filter: (item) => AnalyticMetricTypes.isDrivers(item)
		};
	}

	private getTopicLeafGroup = (): any => {
		return {
			name: OptionsConstant.TOPIC_LEAF,
			displayName: this.locale.getString('widget.topicLeaf'),
			children: [],
			filter: (item) => item.type === OptionsConstant.TOPIC_LEAF
		};
	}

	private getDateRange = (timezone): any => {
		return {
			name: OptionsConstant.DATE_RANGE,
			type: FilterRuleType.dateRange,
			timezone,
			displayName: this.locale.getString('filter.dateRange')
		};
	}

	private getPinnedDateRange = (timezone): any => {
		return {
			name: OptionsConstant.PINNED_DATE_RANGE,
			type: FilterRuleType.dateRange,
			timezone,
			displayName: this.locale.getString('metrics.pinnedDateRange'),
			help: this.locale.getString('metrics.pinnedDateRangeHelp')
		};
	}

	// check if grouping item is moved from default location
	private groupingItemIsMoved(item): boolean {
		let checkCollections = [ this.nlpAttributes.words,
			this.nlpAttributes.enrichment,
			this.nlpAttributes.language,
			this.nlpAttributes.conversation,
			this.metricFolderAttributes,
			this.nlpAttributes.root ];

		for (let collection of checkCollections) {
			if (collection.indexOf(item.name) > -1) return true;
		}
		return false;
	}

	private isNlpWordAttribute = (item): boolean => {
		if (item.name === this.constants.WORDS.name
			|| item.name === this.constants.WORDS_MTOKEN.name
			|| item.name === this.constants.HASHTAG.name)
			return true;

		return item.group === OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			&& this.nlpAttributes.words.indexOf(item.name) > -1;
	}

	private isNlpEnrichmentAttribute = (item): boolean => {
		return item.group === OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			&& this.nlpAttributes.enrichment.indexOf(item.name) > -1;
	}

	private isNlpLanguageAttribute = (item): boolean => {
		return item.group === OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			&& this.nlpAttributes.language.indexOf(item.name) > -1;
	}

	private isNlpConversationAttribute = (item): boolean => {
		return item.group === OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			&& this.nlpAttributes.conversation.indexOf(item.name) > -1;
	}

	private isNotNlpAttribute = (item): boolean => {
		return this.isItemAttribute(item) && !this.isNlpWordAttribute(item) && !this.isNlpEnrichmentAttribute(item)
			&& !this.isNlpLanguageAttribute(item) && !this.isNlpConversationAttribute(item);
	}

	private isItemAttribute = (item): boolean => {
		return item.metricType === AnalyticMetricType.ATTRIBUTE;
	}

	private getAttributesGroup = (filterFunction?: (...args) => boolean): any => {
		if (!filterFunction) {
			filterFunction = this.isItemAttribute;
		}
		return {
			name: OptionsConstant.ATTRIBUTES,
			displayName: this.locale.getString('widget.attributes'),
			children: [],
			filter: filterFunction
		};
	}

	filterItemsDefault = (options: any = {}) => {
		options = _.defaults(options || {}, {
			esQuery: false
		});
		let items = [];
		if (options.text) {
			let textName = options.text === 'input' ? FilterRuleTypes.TEXT_FILTER_WITH_INPUT : FilterRuleType.text;
			items.push(this.getTextFilter(textName));
		}
		items.pushAll([
			this.getTopicsGroup(),
			this.getAttributesGroup(this.isNotNlpAttribute)
		]);
		items.push(options.esQuery ? this.getNLPGroupWithESQuery() : this.getNLPGroup());
		return items;
	}

	topics = () => {
		return [
			this.getTopicsGroup()
		];
	}

	metricDefaults = () => {
		return [
			this.getTopicsGroup(),
			this.getAttributesGroup(),
		];
	}

	hierarchyModels = () => {
		return [
			this.getHierarchyModelsGroup()
		];
	}

	drivers = () => {
		return [
			this.getDriversGroup()
		];
	}

	filterItemsWithESQuery = (omitTextFilter: boolean = false): any[] => {
		let returnArray: any[] = [
			this.getTopicsGroup(),
			this.getAttributesGroup(this.isNotNlpAttribute),
			this.getNLPGroupWithESQuery()
		];

		if (!omitTextFilter)
			returnArray.unshift(this.getTextFilter());

		return returnArray;
	}

	filterItems = (options): any[] => {
		let filter = options.filter;
		let returnArray: any[] = [
			this.getTopicsGroup(),
			this.getAttributesGroup(this.isNotNlpAttribute)
		];

		if (!options.omitNlpGroup) {
			returnArray.push(this.getNLPGroupWithESQuery());
		}

		if (options.withDateRange) {
			let dateRange = this.getDateRange(options.timezone);
			if (!filter || !Object.keys(filter).length) {
				returnArray.unshift(dateRange);
			} else if (filter.dateFilter) {
				return [dateRange];
			}
		}
		return returnArray;
	}

	filterItemsFilterMetric = (timezone) => {
		return [
			this.getDateRange(timezone),
			this.getPinnedDateRange(timezone),
			this.getTopicsGroup(),
			this.getAttributesGroup(),
			{
				name: OptionsConstant.FILTERS,
				displayName: this.locale.getString('widget.savedFilters'),
				children: [],
				filter: (item) => {
					return item.type === OptionsConstant.FILTERS
						|| item.type === FilterTypes.PREDEFINED
						|| item.type === OptionsConstant.SCORECARD_FILTERS;
				}
			}
		];
	}

	previewItems = () => {
		return [
			this.getNLPGroupWithoutWords(),
			{
				name: OptionsConstant.ATTRIBUTES,
				displayName: this.locale.getString('widget.attributes'),
				children: [],
				filter: (item) =>
					item.metricType === AnalyticMetricType.ATTRIBUTE
						&& item.group !== OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			},
			this.getDerivedAttributesGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				children: [],
				filter: this.nestedMetricFilter(this.metricFilter)
			}
		];
	}

	documentPreviewItems = (showScorecards) => {
		let options = [
			this.getNLPGroupWithoutWords(),
			{
				name: OptionsConstant.ATTRIBUTES,
				displayName: this.locale.getString('widget.attributes'),
				children: [],
				filter: (item) =>
					item.metricType === AnalyticMetricType.ATTRIBUTE
						&& item.group !== OptionsConstant.DERIVED_ATTRIBUTES_VALUE
			},
			this.getDerivedAttributesGroup(),
			this.getTopicsGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				children: [],
				filter: this.nestedMetricFilter(this.metricFilter)
			}
		];

		if (showScorecards) {
			options.push({
				name: OptionsConstant.SCORECARDS,
				displayName: this.locale.getString('widget.groupScorecardMetrics'),
				forcedOrder: true,
				children: [],
				filter: (item) => item.type === OptionsConstant.SCORECARDS
			});
		}

		return options;
	}

	getProjectAssetsAccessTemplate = (attributeMapper, modelMapper) => {
		let topicsGroup: any = this.getTopicsGroup();
		topicsGroup.displayName = this.locale.getString('common.models');
		topicsGroup.map = modelMapper;

		let attributeGroup: any = this.getAttributesGroup();
		attributeGroup.map = attributeMapper;

		return [topicsGroup, attributeGroup];
	}

	getProjectAssetsAccessSelectedTemplate = () => {
		return [{
			name: 'selected_models',
			displayName: this.locale.getString('common.models'),
			children: [],
			filter: (item) => item.type === 'MODEL'
		}, {
			name: 'selected_attributes',
			displayName: this.locale.getString('common.attributes'),
			children: [],
			filter: (item) => item.type === 'ATTRIBUTE'
		}];
	}

	getMobileDocumentDisplayedItemsDialogTemplate = (
			createTopicOptionFunction,
			createAttributeOptionFunction) => {

		let topicsGroup: any = this.getTopicsGroup();
		topicsGroup.map = createTopicOptionFunction;

		let attributeGroups = [
			this.getNLPGroupWithoutWords(),
			{
				name: OptionsConstant.TIME,
				displayName: this.locale.getString('mobile.timeAttributes'),
				children: [],
				filter: (item) =>
					item.metricType === AnalyticMetricType.ATTRIBUTE && item.type === FilterAttributeTypes.DATE
			},
			this.getGroupByAttributesGroup(),
			this.getDerivedAttributesGroup(this.locale.getString('mobile.derivedAttributes')),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				children: [],
				filter: this.isMetricFolderAttribute
			}
		];
		attributeGroups.forEach((attributeGroup: any) => {
			attributeGroup.map = createAttributeOptionFunction;
		});

		let template = [];
		template.push(topicsGroup);
		template.pushAll(attributeGroups);
		return template;
	}

	getMobileDocumentDisplayedItemsSelectedTemplate = () => {
		return [{
			name: 'selected_models',
			displayName: this.locale.getString('mobile.topics'),
			children: [],
			filter: (item) => item.type === 'MODEL'
		}, {
			name: 'selected_attributes',
			displayName: this.locale.getString('mobile.allAttributes'),
			children: [],
			filter: (item) => item.type === 'ATTRIBUTE'
		}];
	}

	getGroupByDefault = (_nlpFilter?) => {
		return [
			this.getTopicsGroup(),
			this.getTopicLeafGroup(),
			this.getNLPGroup([
				cloneDeep(this.constants.WORDS)
			], null, _nlpFilter),
			this.getTimeAttributesGroup(),
			this.getGroupByAttributesGroup(),
			this.getDerivedAttributesGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				children: [],
				filter: this.nestedMetricFilter(this.metricFilter)
			},
			this.getDriversGroup(),
			this.getHierarchyModelsGroup()
		];
	}

	getAdminProjectDefault = () => {
		return [
			this.getStandardGroup(),
			this.getTimeAttributesGroup(),
			this.getAttributesGroup()
		];
	}

	private nestedMetricFilter(filter): (...args) => boolean {
		let filterFunction = (item) => {
			if (item.type === FolderTypes.METRIC && item.children && item.children.length) {
				let children = _.filter(item.children, filter);
				_.each(children, filterFunction);
				item.children = children;
				return children && children.length;
			} else {
				return filter(item);
			}
			//TODO: remove this after implement percent in list as group by
		};
		return filterFunction;
	}


	private metricFilter = (item): boolean => {
		return this.isNPSGroup(item) || this.isMetricFolderAttribute(item) || AnalyticMetricTypes.isPredefinedGroup(item);
	}

	private metricFilterForFiltered = (item): boolean => {
		return this.isNPSGroup(item) || this.isMetricFolderAttribute(item);
	}

	private isNPSGroup = (item): boolean => {
		return item.metricType === AnalyticMetricType.CUSTOM
			&& !this.MetricCalculationsType.FILTER_METRIC.is(item)
			&& !this.MetricCalculationsType.VARIABLE.is(item)
			&& !this.MetricCalculationsType.CUSTOM_MATH.is(item);
	}

	private isMetricFolderAttribute = (item): boolean => {
		return this.metricFolderAttributes.indexOf(item.name) > -1;
	}

	// check if grouping item is moved from default location
	private calculationItemIsMoved(item): boolean {
		let checkCollections = [ this.moveToCalculationMetrics ];

		for (let collection of checkCollections) {
			if (collection.indexOf(item.name) > -1) return true;
		}
		return false;
	}

	private notMovedAttributeFilter = (item): boolean => {
		return this.isItemAttribute(item) && !this.calculationItemIsMoved(item);
	}

	getCalculationDefault = () => {
		return [
			this.getStandardGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				children: [],
				filter: (item) =>
					item.metricType === AnalyticMetricType.CUSTOM || this.moveToCalculationMetrics.indexOf(item.name) > -1
			},
			this.getAttributesGroup(this.notMovedAttributeFilter),
			{
				name: OptionsConstant.SCORECARDS,
				displayName: this.locale.getString('widget.groupScorecardMetrics'),
				forcedOrder: true,
				children: [],
				filter: (item) =>
					AnalyticMetricTypes.isScorecardMetric(item)
			}
		];
	}

	getCalculationMetricsForMetricEdit = () => {
		return [
			this.getStandardGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				children: [],
				filter: this.nestedMetricFilter(this.metricFilterForFiltered)
			},
			this.getAttributesGroup(this.notMovedAttributeFilter)
		];
	}

	getConversationSpineTemplate = () => {
		return [
			this.getTopicsGroup(),
			{
				name: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				forcedOrder: true,
				_expanded: true,
				children: [],
				filter: this.nestedMetricFilter((item) => {
					return this.isNPSGroup(item) || AnalyticMetricTypes.isPredefinedGroup(item);
				})
			},
			this.getNLPGroup(undefined, undefined, (item) =>
				(this.isNlpWordAttribute(item) || this.isNlpEnrichmentAttribute(item))
					&& (item.objectType === AttributeObjectType.SENTENCE))
		];
	}

	buildHierarchyMetricsGroup = (options) => {
		let group = this.getHierarchyMetricsGroup();
		group.children = options;

		return group;
	}

	private getHierarchyMetricsGroup(): any {
		return {
			name: OptionsConstant.HIERARCHY_CALCULATIONS,
			displayName: this.locale.getString('widget.hierarchyCalculations'),
			children: [],
			filter: AnalyticMetricTypes.isHierarchyEnrichmentProperty
		};
	}

	private getNLPGroup = (wordsChildren?, customDisplayName?: string, nlpFilter = (...args) => true): any => {
		let displayName = customDisplayName || this.locale.getString('widget.nlp');

		if (!wordsChildren) {
			wordsChildren = [
				cloneDeep(this.constants.WORDS_MTOKEN)
			];
		}

		let options = [];
		options.push({
			name: OptionsConstant.WORDS,
			displayName: this.locale.getString('widget.words'),
			children: wordsChildren.filter(nlpFilter),
			filter: (item) => this.isNlpWordAttribute(item) && nlpFilter(item)
		});
		if (nlpFilter(this.constants.LC)) {
			options.push(cloneDeep(this.constants.LC));
		}

		if (nlpFilter(this.constants.HASHTAG)) {
			options.push(cloneDeep(this.constants.HASHTAG));
		}
		options.push({
			name: OptionsConstant.ENRICHMENT,
			displayName: this.locale.getString('widget.enrichment'),
			children: [],
			filter: (item) => {
				return this.isNlpEnrichmentAttribute(item) && nlpFilter(item);
			}
		});

		options.push({
			name: OptionsConstant.LANGUAGE,
			displayName: this.locale.getString('widget.language'),
			children: [],
			filter: (item) => this.isNlpLanguageAttribute(item) && nlpFilter(item)
		});

		options.push({
			name: OptionsConstant.CONVERSATION,
			displayName: this.locale.getString('widget.conversation'),
			children: [],
			filter: (item) => this.isNlpConversationAttribute(item) && nlpFilter(item)
		});

		return {
			name: OptionsConstant.NLP,
			displayName,
			forcedOrder: true,
			objectType: AttributeObjectType.SENTENCE,
			children: options,
			filter: (item) => (item.name === this.constants.LC.nam)
					&& nlpFilter(item)
		};
	}

	private getNLPGroupWithESQuery = (wordsChildren?): any => {
		let group = this.getNLPGroup(wordsChildren);
		group.children.push(cloneDeep(this.constants.ES_QUERY));

		return group;
	}

	private getNLPGroupWithoutWords = (): any => {
		let group = this.getNLPGroup([]);
		this.removeOption(group.children, this.constants.LC);
		this.removeOption(group.children, this.constants.HASHTAG);
		return group;
	}

	private removeOption = (options, option): void => {
		options.splice(_.findIndex(options, { name: option.name }), 1);
	}

	private getTimeAttributesGroup = (customDisplayName?: string): any => {
		let displayName = customDisplayName || this.locale.getString('widget.groupTimeOptions');

		return {
			name: OptionsConstant.TIME,
			displayName,
			children: [],
			forcedOrder: true,
			filter: (item) => item.metricType === AnalyticMetricType.TIME
		};
	}

	private getGroupByAttributesGroup = (): any => {
		return {
			name: OptionsConstant.ATTRIBUTES,
			displayName: this.locale.getString('widget.attributes'),
			children: [],
			filter: (item) => item.metricType === AnalyticMetricType.ATTRIBUTE
					&& item.group !== OptionsConstant.DERIVED_ATTRIBUTES_VALUE
					&& item.type !== FilterAttributeTypes.DATE
		};
	}

	private getDerivedAttributesGroup(customDisplayName?: string): any {
		let displayName = customDisplayName || this.locale.getString('widget.derivedAttributes');

		return {
			name: OptionsConstant.DERIVED_ATTRIBUTES,
			displayName,
			children: [],
			filter: (item) => item.group === OptionsConstant.DERIVED_ATTRIBUTES_VALUE && !this.groupingItemIsMoved(item)
		};
	}
}

app.service('optionsTemplatesService', OptionsTemplatesService);
