<modal-header
	(cancel)="close()"
	[modalTitle]="getTitle()"
></modal-header>

<div class="modal-body dependencies-modal" [ngBusy]="loading">
	<div class="mb-32" *ngIf="showSelection()">
		<select
			class="form-control dependencies-type-selector"
			[(ngModel)]="dependencyType"
			(change)="dependencyTypeChanged()">
			<option *ngFor="let type of dependencyTypes" [ngValue]="type">
					{{type.displayName}}
			</option>
		</select>
	</div>

	<div>
		<formatted-table
			[rows]="rows"
			[columns]="columns"
			view="compact"
		></formatted-table>
	</div>

	<div class="clearfix">
		<pagination
			class="pull-right"
			[pagination]="pagination"
			(paginationChange)="pageChanged($event)"
		></pagination>
	</div>
</div>

<div class="modal-footer">
	<button id="btn-dependencies-ok"
		type="button"
		class="btn br-btn-yes btn-secondary"
		(click)="close()"
		[i18n]="'common.ok'"
	></button>
	<button id="btn-dependencies-export"
		type="button"
		class="btn btn-primary"
		(click)="export()"
		[i18n]="'common.export'"
	></button>
</div>
