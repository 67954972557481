import { PropertyValue, PropertyChanges } from '@app/modules/project/state-changes/property-changes';


export class PropertiesChanges {
	private changesMap: {[property: string]: PropertyChanges<any>};

	constructor() {
		this.reset();
	}

	reset(): void {
		this.changesMap = {};
	}

	addChange(object, property: string, newValue): void {
		let propertyChanges = this.changesMap[property];
		if (!propertyChanges) {
			propertyChanges = new PropertyChanges();
			this.changesMap[property] = propertyChanges;
		}
		let id = object.id;
		let currentValue = object[property];
		propertyChanges.addChange(id, currentValue, newValue);
	}

	hasChanges(): boolean {
		return _.keys(this.changesMap)
			.map(property => this.hasPropertyChanges(property))
			.contains(true);
	}

	hasPropertyChanges(property: string): boolean {
		return !_.isEmpty(this.getPropertyChanges(property));
	}

	getPropertyChanges<T>(property: string): PropertyValue<T>[] {
		let propertyChanges = this.changesMap[property];
		return propertyChanges ? propertyChanges.getChanges() : [];
	}

	getPropertyInitial<T>(property: string): PropertyValue<T>[] {
		let propertyChanges = this.changesMap[property];
		return propertyChanges ? propertyChanges.getInitial() : [];
	}
}