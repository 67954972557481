import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportTypes } from '@cxstudio/master-accounts/export-types.constant';

@Component({
	selector: 'system-audit-export',
	template: `
<div>
	<export-selector [type]="reportTypes.SYSTEM_AUDIT" (cancel)="cancel()"></export-selector>
</div>
`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemAuditExportComponent implements OnInit {
	readonly reportTypes = ExportTypes;

	constructor(
		public activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
	}

	cancel(): void {
		this.activeModal.dismiss('cancel');
	}

}