import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';

@Injectable({
	providedIn: 'root'
})
export class WidgetDashboardDateFilterProcessorService extends WidgetDashboardFilterProcessor {

	constructor(
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('ignoredDashboardFilterService') private ignoredDashboardFilterService: IgnoredDashboardFilterService
	) {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		let widgetSettings = filterApplication.widgetSettings;

		if (widgetSettings.ignoreDateRangeFilters) {
			// drilled from PoP
			return PromiseUtils.old(Promise.resolve());
		}

		let dashboardDateFilter = this.dashboardFiltersService.getDashboardDateFilter(
			filterApplication.filtersProvider.getAppliedFilters());
		if (dashboardDateFilter && !this.ignoredDashboardFilterService.isIgnoredDashboardFilter(widgetSettings.properties,
				filterApplication.filtersProvider.getDashboard().properties, dashboardDateFilter)) {
			//use p1 as date filter instead of dashboard filter to make pop widget work correctly
			let properties = widgetSettings.properties;
			if (!properties.dateRangeP1) {
				properties.dateRangeP1 = {} as DateFilter;
			}

			properties.dateRangeP1.dateFilterMode = dashboardDateFilter.selectedAttributeValue.dateFilterMode;
			properties.dateRangeP1.from = dashboardDateFilter.selectedAttributeValue.dateFilterRange.from;
			properties.dateRangeP1.to = dashboardDateFilter.selectedAttributeValue.dateFilterRange.to;

			// CES-4886. When "Missing Dates" dashboard filter is applied
			if (properties.useHistoricPeriod && properties.dateRangeP1.dateFilterMode === DateFilterMode.MISS_DATE) {
				properties.useHistoricPeriod = false;
			}
		}

		return PromiseUtils.old(Promise.resolve());
	}

}
app.service('widgetDashboardDateFilterProcessor', downgradeInjectable(WidgetDashboardDateFilterProcessorService));