import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ConversationChannelService } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ConversationParticipantService } from '@app/modules/document-explorer/conversation-participant.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { SafeHtml } from '@angular/platform-browser';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { OnChanges, SimpleChanges } from '@angular/core';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { ChangeUtils } from '@app/util/change-utils';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { SentenceEnrichmentTuningEvent } from '@app/modules/pills/pill-tuning-event';

@Component({
	selector: 'voice-message',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './voice-message.component.html',
})
export class VoiceMessageComponent implements OnInit, OnChanges {
	@Input() transcript: ConversationSentence;
	@Input() channelLabels: ConversationChannelLabels;
	@Input() formatters: IMetricFormatters;
	@Input() focusable: boolean;
	@Input() auditMode: boolean;
	@Input() auditModeModels: IReportModel[];
	@Input() messageWidth: number;
	@Input() highlightTrigger: number;
	@Input() expandTopics: boolean;
	@Input() leafOnly: boolean;
	@Input() predefinedMetrics: Metric[];
	@Input() deselectedModelsIds: number[];
	@Input() compact: boolean;
	@Input() redrawTrigger: boolean;
	@Input() translate: boolean;
	@Input() uniqueId: string;
	@Input() showTopics: boolean;
	@Input() showEnrichments: boolean;

	@Output() removeTopic = new EventEmitter<string>();
	@Output() tuneEnrichment = new EventEmitter<SentenceEnrichmentTuningEvent>();
	@Output() onPlay = new EventEmitter<void>();
	@Output() onPause = new EventEmitter<void>();
	@Output() onSelect = new EventEmitter<MouseEvent>();

	iconColor: string;
	sentimentHTML: SafeHtml;
	easeHTML: SafeHtml;
	emotionHTML: SafeHtml;

	filteredSentenceTopics: SentenceTopic[];
	hasProfanity: boolean;

	constructor(
		private readonly profanityDisguiseService: ProfanityDisguiseService,
		@Inject('conversationChannelService') private conversationChannelService: ConversationChannelService,
		@Inject('conversationParticipantService') private conversationParticipantService: ConversationParticipantService,
	) {}

	ngOnInit(): void {
		this.iconColor = this.conversationParticipantService.getIconColor(this.transcript.channel, this.transcript.participantIndex);

		this.initTopics();
		this.hasProfanity = this.profanityDisguiseService.needToMaskSentence(this.transcript);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.deselectedModelsIds) || ChangeUtils.hasChange(changes.leafOnly)) {
			this.initTopics();
		}
	}

	private initTopics(): void {
		this.filteredSentenceTopics = PillsUtils.getFilteredSentenceTopics(this.transcript.sentenceTopics,
			this.deselectedModelsIds, this.leafOnly);
	}
	getSpeakerLabel(): string {
		return this.conversationChannelService.getChannelLabel(this.transcript.channel, this.channelLabels);
	}

	getParticipantLabel(): string {
		let channelLabel: string = this.getSpeakerLabel();
		return this.conversationParticipantService.getParticipantLabel(
			channelLabel, this.transcript.participantIndex, this.transcript.isSameChannelAsLast);
	}

	getIconClass(): string {
		return this.conversationChannelService.getIconClass(this.transcript.channel);
	}

	getIconColor(): string {
		return this.iconColor ? this.iconColor : '';
	}

	showTimestamp(): boolean {
		return !_.isUndefined(this.transcript.timestamp) && !this.transcript.isSameChannelAsLast;
	}
}

app.directive('voiceMessage', downgradeComponent({component: VoiceMessageComponent}) as angular.IDirectiveFactory);
