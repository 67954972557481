export class DriversModel {
	id: number;
	drivers: IDriversModelItem[];
	modelMetrics: IDriversModelMetrics;
}

export interface IDriversModelItem {
	strength: number;
	field: string;
	value: number;
	identifier: string;
}

export interface IDriversModelMetrics {
	roc: number;
	prc: number;
	fScores: {
		mcc: number;
		f0_5: number;
		f1: number;
		f2: number;
	};
}