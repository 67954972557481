import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { UIOption } from '@clarabridge/unified-angular-components';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';

@Component({
	selector: 'null-values-settings',
	template: `
<div>
	<label>{{'widget.nullValues'|i18n}}</label>
	<cb-push-buttons
		[list]="nullIncludeOptions"
		[selectedValue]="innerValue"
		(selectedValueChange)="onChange($event)">
	</cb-push-buttons>
</div>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NullValuesSettingsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NullValuesSettingsComponent extends BaseControlValueAccessor<EmptyPeriodType> implements OnInit {

	nullIncludeOptions: UIOption<boolean>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref);
	 }

	ngOnInit(): void {
		this.nullIncludeOptions = [
			{displayName: this.locale.getString('metrics.include'), value: true},
			{displayName: this.locale.getString('metrics.exclude'), value: false}
		];

	}

}
