import { Directive, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { UniqueNameValidator } from '@app/modules/asset-management/validation/unique-name-validator';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';

@Directive({
	selector: '[uniqueName]',
	providers: [{ provide: NG_VALIDATORS, useExisting:  forwardRef(() => UniqueNameDirective), multi: true}]
})
export class UniqueNameDirective implements Validator, OnInit, OnChanges {
	@Input() initialItem: any;
	@Input() itemList: any[];
	@Input() folder: boolean;
	@Input() nameField: string;

	private validatorFn: ValidatorFn;

	onValidationCallback: () => void = _.noop;

	ngOnInit(): void {
		this.initialItem = this.initialItem || {};
		this.nameField = this.nameField || 'name';
		this.folder = this.folder || false;
		this.itemList = this.itemList || [];

		this.initValidator();
	}

	ngOnChanges(changes: SimpleChanges<UniqueNameDirective>): void {
		if (ChangeUtils.hasChange(changes.folder) ||
			ChangeUtils.hasChange(changes.initialItem) ||
			ChangeUtils.hasChange(changes.nameField) ||
			ChangeUtils.hasChange(changes.itemList)) {
			this.initValidator();
		}
	}

	private initValidator(): void {
		this.validatorFn = new UniqueNameValidator(this.initialItem, this.itemList,
			{ nameField: this.nameField, folder: this.folder }).get();
	}

	validate(control: AbstractControl): { [key: string]: any } {
		return this.validatorFn(control);
	}
}
