<form [formGroup]="licensing">
	<div class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					class="mr-0"
					id="licensingEnabled"
					name="licensingEnabled"
					label="{{'administration.licenseCX'|i18n}}"
					formControlName="licensingEnabled"
					[buttonClass]="'btn-sm'">
				</checkbox-button>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">
				<checkbox-button
					name="licenseExpiration"
					[buttonClass]="'btn-sm'"
					label="{{'administration.licenseExpiration'|i18n}}"
					formControlName="licenseEmails">
				</checkbox-button>
			</label>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'administration.timezone'|i18n}}</label>
			<radio-buttons
				id="contractTimezone"
				name="contractTimezone"
				formControlName="contractTimezone"
				[options]="contractTimezones"
				class="col-md-8">
			</radio-buttons>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3" [i18n]="'mAccount.contractCredits'"></label>
			<div class="col-md-8">
				<input type="number" class="form-control" name="contractCredits"
					formControlName="contractCredits">
			</div>
		</div>

		<div class="form-group" formGroupName="contractLicenses">
			<div class="col-md-6 mb-16" *ngFor="let license of licenses">
				<label class="control-label col-md-6" [i18n]="'mAccount.contractUsers'"
					[i18nParams]="{license: license.licenseTypeName}"></label>
				<div class="col-md-4 mb-16">
					<input type="number" class="form-control"
						name="contractUsers{{license.licenseTypeId}}"
						[formControlName]="license.licenseTypeId">
				</div>
			</div>
		</div>
	</div>
</form>