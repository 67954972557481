import { AsyncTaskItemStatus } from '@app/modules/user-administration/bulk/async-task-item-status';

export class AsyncTaskUtils {
	static getStatusLabelKey(status: AsyncTaskItemStatus): string | undefined {
		switch (status) {
			case AsyncTaskItemStatus.PENDING:
				return 'common.pending';
			case AsyncTaskItemStatus.SUBMITTED:
				return 'common.submitted';
			case AsyncTaskItemStatus.SUCCESS:
				return 'common.success';
			case AsyncTaskItemStatus.FAIL:
				return 'common.failed';
		}
	}
}
