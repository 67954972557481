import * as _ from 'underscore';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { URLSettings } from '../settings.interfaces';


export class URLSettingsGroup implements ISettingsGroup<URLSettings> {

	settings: URLSettings;

	constructor(source: URLSettings) {
		this.settings = source
			? _.pick(source, ['urlType', 'tableRowHeight']) as URLSettings
			: {} as URLSettings;
	}

	postProcessSettings(): URLSettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = {} as URLSettings;
	}

}
