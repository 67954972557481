import * as _ from 'underscore';

import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Caches } from '@cxstudio/common/caches';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { MetricData } from '@app/modules/metric/entities/metric-data';


export class MetricManagementApiService {

	constructor(
		private $http: ng.IHttpService,
		private treeService,
		private cachedHttpService: CachedHttpService,
		private httpHandlers: HttpHandlers,
	) { }

	getPredefinedMetricDefaults = (name: string): ng.IPromise<Metric> => {
		return this.cachedHttpService.cache(Caches.METRICS)
			.get(`rest/metric/predefined/defaults/${name}`, {cache: CacheOptions.CACHED})
			.then(this.httpHandlers.success);
	}

	createMetric = (metricData: Metric | MetricData): ng.IPromise<Metric> => {
		return this.$http.post('rest/metric/create/metricStudio', angular.toJson(this.treeService.copyItem(metricData)))
			.then(this.httpHandlers.success).then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	duplicateMetric = (newMetric: Metric | MetricData, oldMetricId: number): ng.IPromise<Metric> => {
		return this.$http.post(`rest/metric/duplicate/metricStudio/${oldMetricId}`, angular.toJson(newMetric))
			.then(this.httpHandlers.success).then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	createPredefinedMetric = (metricData) => {
		return this.$http.post('rest/metric/create/predefined', angular.toJson(this.treeService.copyItem(metricData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	updatePredefinedMetric = (metricData) => {
		delete metricData.parentId;
		if (metricData.id < 0 || _.isUndefined(metricData.id))
			return this.createPredefinedMetric(metricData);
		return this.$http.put('rest/metric/update/predefined', angular.toJson(this.treeService.copyItem(metricData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	renameMetric = (metricList, metric): ng.IPromise<void> => {
		return this.$http.put(`rest/metric/rename/metricStudio/${metric.id}`, angular.toJson(this.treeService.copyItem(metric)))
			.then(() => {
				let oldMetric = _.findIndex(metricList, {id: metric.id});

				if (oldMetric >= 0) metricList[oldMetric] = metric;
			}).then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	updateMetric = (metric: Metric | MetricData) => {
		return this.$http.put(`rest/metric/update/metricStudio/${metric.id}`, angular.toJson(metric))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	updateLabels = (updateLabelsEntity: BulkUpdateLabelsEntity) => {
		return this.$http.put(`rest/metric/labels`, angular.toJson(updateLabelsEntity))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	updateMetricReshareFlag = (metricId: number, value: boolean) => {
		return this.$http.put(`rest/metric/update/metricStudio/${metricId}/allow_reshare/${value}`, {})
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	deleteMetrics = (metrics: Metric[]): ng.IPromise<void> => {
		let queryParam = {
			metricId: _.map(metrics, (metric) => metric.id)
		};

		return this.$http.delete('rest/metric/delete/metricStudio', { params: queryParam })
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	shareMetrics = (metrics: Metric[], shareData) => {
		let queryParam = {
			metricId: _.map(metrics, (metric) => metric.id)
		};

		return this.$http.post('rest/metric/users', angular.toJson(shareData), { params: queryParam })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS));
	}

	getSharedUsersAndGroups = (metricId) => {
		return this.$http.get(`rest/metric/users/${metricId}`).then(this.httpHandlers.success);
	}

	getMetric = (metricId, cache: boolean = true) => {
		return this.$http.get(`rest/metric/${metricId}`, {cache});
	}

	getMetricDefinition = (metricId: number, cache: boolean = true) => {
		return this.$http.get(`rest/metric/${metricId}/definition`, {cache});
	}

	metricWithNameExists = (contentProviderId, accountId, projectId, metricName): ng.IPromise<boolean> => {
		return this.$http.post(`rest/metric/cp/${contentProviderId}/account/${accountId}/project/${projectId}/name`, metricName)
			.then(this.httpHandlers.success);
	}

	getHierarchyCustomMetrics = (hierarchyId: number): ng.IHttpPromise<any[]> => {
		return this.$http.get(`rest/metric/hierarchyCustomMetrics/${hierarchyId}`);
	}

	verifyMetricsAccess = (metrics): ng.IPromise<any> => {
		let queryParam = {
			metricId: _.map(metrics, (metric: any) => metric.id)
		};

		return this.$http.get('rest/metric/verify-access', { params: queryParam })
			.then(this.httpHandlers.success);
	}
}

app.service('metricApiService', MetricManagementApiService);
