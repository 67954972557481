

export enum MetricMode {
	CREATE = 'CREATE_METRIC',
	VIEW = 'VIEW_METRIC',
	DUPLICATE = 'DUPLICATE_METRIC',
	EDIT = 'EDIT_METRIC',
	EDIT_AND_SHARE = 'EDIT_AND_SHARE'
}


