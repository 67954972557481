<div class="d-flex flex-direction-row justify-between">
	<div class="d-flex flex-direction-column grid-search-bar">
		<label for="dashboard-search" class="mr-8 font-bold">{{'dashboard.searchDashboards'|i18n}}</label>
		<div class="d-flex flex-direction-row">
			<input
				type="search"
				id="dashboard-search"
				class="br-dash-search-bar w-100-percent"
				aria-autocomplete="list"
				autocomplete="off"
				(keydown.escape)="search.emit()"
				placeholder="{{'dashboard.findDashboardsAndBooks'|i18n}}"
				[(ngModel)]="searchValue"
				(ngModelChange)="onSearchChange()">
			<div class="d-flex align-center ml-8"
				[class.hidden]="!showLoading">
				<span class="q-icon q-icon-spinner rotate-infinite" aria-hidden="true"></span>
			</div>
		</div>
	</div>

	<div class="grid-buttons align-self-end">
		<button
			id="btn-new-folder"
			class="btn btn-secondary"
			(click)="createFolder()"
			[attr.aria-label]="'dashboard.newFolderAreaLabel'|i18n">
			{{'dashboard.newFolder'|i18n}}
		</button>
		<button *ngIf="canCreateDashboard()"
			id="btn-new-book"
			class="btn btn-secondary"
			(click)="createBook()"
			[attr.aria-label]="'dashboard.createANewBook'|i18n">
			{{'dashboard.newTabs'|i18n}}
		</button>
		<button *ngIf="canCreateDashboard()"
			id="btn-new-dashboard"
			class="btn btn-primary"
			(click)="createDashboard()"
			[attr.aria-label]="'dashboard.createANewDashboard'|i18n">
			{{'dashboard.newDashboard'|i18n}}
		</button>
		<kebab-menu
			class="ml-8"
			[menuItems]="tableMenuItems">
		</kebab-menu>
	</div>
</div>
