import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from '@app/shared/providers/scope-provider';

@Directive({
	selector: 'color-selector'
})

export class ColorSelectorUpgrade extends UpgradeComponent {
	@Input() dropdownClass;
	@Input() visualProps;
	@Input() properties;
	@Input() field;
	@Input() ignoredGroup;
	@Input() filter;
	@Input() studioMetrics;
	@Input() predefinedMetrics;
	@Input() palettes;
	@Input() providerColors;
	@Input() parent;
	@Input() onChange;
	@Input() defaultColor;
	@Input() validPaletteOptions;
	@Input() selectedPalette;
	@Input() customField;
	@Input() $scope;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('colorSelector', elementRef, injector);
	}
}
