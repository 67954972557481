import { PercentilesMap } from '@app/modules/project/scorecard/editor/preview-result/modal/scorecard-preview-result-modal.component';

export class ScorecardPreviewResult {
	averageScore: number;
	minScore: number;
	maxScore: number;
	sampleSize: number;
	hitsBelowThreshold: number;
	percentBelowTarget: number;
	percentiles: PercentilesMap;

	potentialCoverage: number;
}