import { Metric } from '@app/modules/metric/entities/metric';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { ProjectId } from '@cxstudio/generic-types';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IOrganizationCalculations } from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { OptionsConstant } from './options-constant';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { HierarchyEnrichmentProperty } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';

export interface IOptionsBuilder {
	withTemplate: (...args) => IOptionsBuilder;
	withModels: (...args) => IOptionsBuilder;
	withOrgHierarchyModels: (...args) => IOptionsBuilder;
	withOrgHierarchyMetrics: (...args) => IOptionsBuilder;
	withAttributes: (...args) => IOptionsBuilder;
	withWordAttributes: (...args) => IOptionsBuilder;
	withFilters: (...args) => IOptionsBuilder;
	withScorecardFilters: (...args) => IOptionsBuilder;
	filterAvailableOptions: (...args) => IOptionsBuilder;
	applyNlpFilter: (...args) => IOptionsBuilder;
	withMetrics: (...args) => IOptionsBuilder;
	withTime: (...args) => IOptionsBuilder;
	withPredefinedGroups: (...args) => IOptionsBuilder;
	withPredefinedMetrics: (...args) => IOptionsBuilder;
	withStandardMetrics: (...args) => IOptionsBuilder;
	withDrivers: (...args) => IOptionsBuilder;
	withDocumentLevelOnly: (...args) => IOptionsBuilder;
	withScorecardMetrics: (...args) => IOptionsBuilder;
	withScorecards: (...args) => IOptionsBuilder;
	build: (...args) => any[];
}

export class OptionsBuilder {

	_template;
	_availableOptions = [];
	_document_level_only;
	_wordAttributes = [];
	_orgHierarchyMetrics = [];
	_nlpAttributes = [];
	_nlpFilter;
	constants = this.metricConstants.get();

	constructor(
		private readonly _type: OptionsConstant,
		private readonly optionsTemplatesService: OptionsTemplatesService,
		private readonly metricUtils: MetricUtils,
		private readonly metricConstants: MetricConstants
	) {}

	private isDocumentLevelNode = (node): boolean => {
		return node
			&& node.objectType !== AttributeObjectType.SENTENCE
			&& node.type !== ReportAssetType.METRIC_PREDEFINED
			&& node.name !== PredefinedMetricConstants.SENTIMENT
			&& node.type !== ReportAssetType.METRIC_STUDIO;
	}

	withTemplate = (template): IOptionsBuilder => {
		this._template = template;
		return this;
	}

	withModels = (models: Model[]): IOptionsBuilder => {
		let _models = AssetVisibilityUtils.getVisibleModels(models);
		let _groups = this.metricUtils.buildModelOptions(_models);
		this._availableOptions.pushAll(_groups.topicModels);
		return this;
	}

	withOrgHierarchyModels = (models, hierarchyModels): IOptionsBuilder => {
		let _models = AssetVisibilityUtils.getHiddenModels(models);
		let _hierarchyModels = this.getAvailableHierarchyModels(_models, hierarchyModels);
		let _groups = this.metricUtils.buildOrgHierarchyModelOptions(_hierarchyModels);
		this._availableOptions.pushAll(_groups);
		return this;
	}

	withOrgHierarchyMetrics = (hierarchyId: number, calculations: IOrganizationCalculations): IOptionsBuilder => {
		if (hierarchyId && calculations) {
			this._orgHierarchyMetrics = this.metricUtils.buildOrgHierarchyMetricOptions(hierarchyId, calculations);
		}
		return this;
	}

	withOrgHierarchyEnrichments = (hierarchies: ReportableHierarchy[]): IOptionsBuilder => {
		if (hierarchies) {
			this._orgHierarchyMetrics = _.chain(hierarchies)
				.map(hierarchy => {
					let options = this.metricUtils.buildOrgHierarchyMetricOptions(hierarchy.id, {
						enrichment: _.map(hierarchy.properties,
							property => ({propertyName: property.name} as HierarchyEnrichmentProperty)),
						custom: []
					});
					return {
						displayName: hierarchy.name,
						name: `hierarchy-${hierarchy.id}`,
						children: options,
					};
				})
				.value();
		}
		return this;
	}

	withAttributes = (attributes, filter): IOptionsBuilder => {
		this._nlpAttributes = _.chain(attributes).map((attribute) => {
			return this.metricUtils.getNlpStudioMetrics(attribute, this.metricConstants.getNlpStudioMetricAttributeMap());
		}).filter((metric) => {
			return !_.isEmpty(metric);
		}).flatten().value();
		let _attributes = this.metricUtils.buildAttributeOptions(attributes, filter);
		this._availableOptions.pushAll(_attributes);
		return this;
	}

	withWordAttributes = (attributes): IOptionsBuilder => {
		this._wordAttributes = attributes;
		return this;
	}

	withFilters = (filters): IOptionsBuilder => {
		let processedFilters = _.map(filters, (filter) => {
			if (filter.type === FilterTypes.PREDEFINED) {
				let f = angular.copy(filter);
				f.displayName = f.name;
				return f;
			}
			return {
				name: filter.name,
				displayName: filter.displayName ? filter.displayName : filter.name,
				id: _.isUndefined(filter.filterId) ? filter.id : filter.filterId,
				filterType: filter.type,
				type: OptionsConstant.FILTERS
			};

		});
		this._availableOptions.pushAll(processedFilters);
		return this;
	}

	withScorecardFilters = (filters): IOptionsBuilder => {
		let scorecardFilters = _.map(filters, (filter) => {
			return {
				name: filter.name,
				displayName: filter.displayName ? filter.displayName : filter.name,
				id: filter.uniqueId,
				scorecardId: filter.scorecardId,
				passing: filter.passing,
				filterType: filter.type,
				type: OptionsConstant.SCORECARD_FILTERS
			};
		});
		this._availableOptions.pushAll(scorecardFilters);
		return this;
	}

	filterAvailableOptions = (filter): IOptionsBuilder => {
		if (_.isUndefined(filter)) {
			throw Error('you must pass a filter to this function');
		}
		let filtered = _.filter(this._availableOptions, filter);
		this._availableOptions = filtered;

		return this;
	}

	applyNlpFilter = (filter): IOptionsBuilder => {
		this._nlpFilter = filter;
		return this;
	}

	withMetrics = (metrics: Metric[], projectId?: ProjectId): IOptionsBuilder => {
		let _metrics = this.metricUtils.getCustomMetricsGroup(metrics, projectId);
		this._availableOptions.pushAll(_metrics || []);
		return this;
	}

	withTime = (attributes, originalName?): IOptionsBuilder => {
		let _time = this.metricUtils.getTimeGroup(attributes, originalName);
		this._availableOptions.pushAll(_time ? _time.children : []);
		return this;
	}

	withPredefinedGroups = (metrics: Metric[]): IOptionsBuilder => {
		if (this._type !== OptionsConstant.GROUP_BY || _.isEmpty(metrics)) {
			return this;
		}

		let array = this.metricUtils.toPredefinedGroupBy(metrics);

		this._availableOptions.insertAll(0, array);
		return this;
	}

	withPredefinedMetrics = (metrics: Metric[]): IOptionsBuilder => {
		if (this._type === OptionsConstant.GROUP_BY) {
			return this;
		}

		let array = this.metricUtils.toPredefinedCalculation(metrics);

		this._availableOptions.pushAll(array);
		return this;
	}

	withStandardMetrics = (metrics: Metric[]): IOptionsBuilder => {
		this._availableOptions.pushAll(metrics);
		return this;
	}

	withDrivers = (drivers: DriversItem[], projectId?: ProjectId): IOptionsBuilder => {
		let _drivers = this.metricUtils.getDriversGroup(drivers, projectId);
		this._availableOptions.pushAll(_drivers || []);
		return this;
	}

	withDocumentLevelOnly = (documentLevelOnly): IOptionsBuilder => {
		this._document_level_only = documentLevelOnly;
		return this;
	}

	withScorecardMetrics = (socrecardMetrics): IOptionsBuilder => {
		let metrics = socrecardMetrics || [];
		_.each(metrics, (metric) => {
			metric.hide = metric.hide || metric.disabled;
		});
		this._availableOptions.pushAll(metrics);
		return this;
	}

	withScorecards = (scorecards, attributes): IOptionsBuilder => {
		let scorecardsOption = _.chain(scorecards)
			.filter((scorecard) => {
				let attr = _.findWhere(attributes, {name: ScorecardUtils.getScorecardAttributeName(scorecard)});
				return attr && !attr.hide;
			}).map((scorecard) => {
				return {
					id: scorecard.id,
					name: scorecard.name,
					displayName: scorecard.displayName ? scorecard.displayName : scorecard.name,
					modelId: scorecard.modelId,
					type: OptionsConstant.SCORECARDS
				};
			}).value();

		this._availableOptions.pushAll(scorecardsOption);
		return this;
	}

	build = (): any[] => {
		//get template
		if (!this._template) {
			this._template = this._type === OptionsConstant.GROUP_BY
				? this.optionsTemplatesService.getGroupByDefault(this._nlpFilter)
				: this.optionsTemplatesService.getCalculationDefault();
		}

		if (this._type === OptionsConstant.CALCULATION && !_.isEmpty(this._nlpAttributes)) {
			this._availableOptions.pushAll(this._nlpAttributes);
		}

		let nlpNode = _.findWhere(this._template, {name: OptionsConstant.NLP});
		if (this._wordAttributes) {
			this.populateHideFlagForWordOptions(this._wordAttributes, nlpNode?.children);
		} else {
			this._template.remove(nlpNode);
		}

		//filling leaf node
		this.populateTemplateGroupsChildren(this._template);
		if (this._document_level_only) {
			this.removeSentenceLevelOptionsFolder(this._template);
		}

		if (!isEmpty(this._orgHierarchyMetrics)) {
			let hierarchyMetricsGroup = this.optionsTemplatesService.buildHierarchyMetricsGroup(this._orgHierarchyMetrics);
			this._template.push(hierarchyMetricsGroup);
		}

		return this.metricUtils.skipEmptyRecursive(this._template);
	}

	private populateHideFlagForWordOptions(wordAttributes, nodes: any[]): void {
		if (!_.isUndefined(nodes)) {
			_.forEach(nodes, (node) => {
				if (!isEmpty(node.children)) {
					this.populateHideFlagForWordOptions(wordAttributes, node.children);
				}

				let nodeName = node.name === this.constants.WORDS_MTOKEN.name
					? this.constants.WORDS.name
					: node.name;

				let nlpAttribute = _.findWhere(wordAttributes, {name: nodeName});
				if (!_.isUndefined(nlpAttribute)) {
					node.hide = nlpAttribute.hide;
				}
			});
		}
	}

	private populateTemplateGroupsChildren(nodes: any[], customMapFunction?): void {
		_.forEach(nodes, (node) => {
			if (node.children?.length > 0) {
				// "map" function should be applied to the whole subhierarchy
				this.populateTemplateGroupsChildren(node.children, node.map);
			}

			if (node.filter) {
				let filtered = _.filter(this._availableOptions, node.filter);
				if (this._document_level_only) {
					filtered = _.filter(filtered, this.isDocumentLevelNode);
				}
				if (filtered?.length)
					node.children.pushAll(filtered);
			}

			let mapFunction = customMapFunction || node.map;
			if (mapFunction && node.children) {
				// apply "map" function only to leaf nodes (e.g. attributes themselves)
				node.children = node.children.map((child) => {
					return child.children ? child : mapFunction(child);
				});
			}
		});
	}

	private getAvailableHierarchyModels(models: Model[], hierarchyModels): Model[] {
		if (isEmpty(hierarchyModels) || isEmpty(models)) {
			return [];
		}

		return _.filter(hierarchyModels, (hierarchyModel) => {
			if (hierarchyModel.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING) {
				return true;
			}
			let model = _.findWhere(models, {id: hierarchyModel.publications[0].modelId});
			return model?.classified;
		});
	}

	private removeSentenceLevelOptionsFolder(nodes: any[]): void {
		_.forEach(nodes, (node) => {

			if (!this.isDocumentLevelNode(node) && node.filter) {
				node.children = [];
			}

			if (node.children?.length > 0) {
				this.removeSentenceLevelOptionsFolder(node.children);
			}
		});
	}
}

