import { Injectable } from '@angular/core';
import {
	SuggestionMenuSettings
} from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import {
	IDocumentClassification, IDocumentClassificationNode
} from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { TopicChickletsService } from '@app/modules/document-explorer/topic-chicklet.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ObjectUtils } from '@app/util/object-utils';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';

@Injectable({
	providedIn: 'root'
})
export class SuggestionMenuOptionsUtils {
	constructor(
		private readonly topicChickletsService: TopicChickletsService,
		private readonly reportModelsService: ReportModelsService,
		private readonly locale: CxLocaleService,
	) {
	}

	getClassifiedModelsOptions(settings: SuggestionMenuSettings): any {
		const availableModels: IReportModel[] = settings.availableModels.filter(
			(model: IReportModel) => !model.hidden
		);

		return settings.classification.map((documentClassification: IDocumentClassification) =>
			this.getClassifiedModelsForDocumentClassification(documentClassification, settings, availableModels))
			.filter(item => !!item);
	}

	getModelsOptions(settings: SuggestionMenuSettings, callback: any): any {
		return settings.availableModels.map((model: IReportModel) => {
			return {
				text: model.name,
				name: 'model-' + model.id,
				obj: model,
				selected: false,
				noMargin: true,
				searchBox: false,
				skipExpandable: true,
				items: this.getModelOptionsPromise(settings, model, callback)
			};
		});
	}

	getTopicFullPath(topicId: number, models: IDocumentClassification[]): string {
		for (const model of models) {
			const node: IDocumentClassificationNode = model.nodes.find(
				(modelNode: IDocumentClassificationNode) => modelNode.id === topicId
			);

			if (node) {
				return this.topicChickletsService.getPath(node, model);
			}
		}

		return '';
	}

	private getModelOptionsPromise(settings: SuggestionMenuSettings, model: any, callback: any): any {
		return () => {
			const sentenceId: number = settings.sentence.id;

			const filteredClassificationModelsById = settings.classification
				.filter((classificationModel:IDocumentClassification) => classificationModel.modelId === model.id);

			// Flatmap the nodes out of the document classification into a new array
			const classificationNodes: IDocumentClassificationNode[] = filteredClassificationModelsById
				 .reduce(
					 (accumulator, classification: IDocumentClassification) => accumulator.concat(classification.nodes), []
				 );

			const disabledItems: IDocumentClassificationNode[] = classificationNodes
				.filter((node: IDocumentClassificationNode) => node.sentences.contains(sentenceId));

			return this.reportModelsService.getWidgetModelTree(settings.widget, model.id)
				.then((modelTree: ModelTree) => {
					return [{
						text: modelTree.name,
						name: 'model-tree-' + modelTree.id,
						noMargin: true,
						searchBox: true,
						skipSelection: true,
						skipExpandable: true,
						tree: [modelTree.root],
						disabledItems,
						disabledItemTooltip: this.locale.getString('docExplorer.existingTopic'),
						func: callback
					}];
				});
		};
	}

	private getClassifiedModelsForDocumentClassification(
		documentClassification: IDocumentClassification,
		settings: SuggestionMenuSettings,
		availableModels: IReportModel[]
	) {
		const sentenceId: number = settings.sentence.id;
		const isSentenceClassifiedByModel: boolean = documentClassification.nodes
			.some((node: IDocumentClassificationNode) => node.sentences.contains(sentenceId));

		if (!isSentenceClassifiedByModel) {
			return;
		}

		const model: IReportModel = availableModels.find(
			(availableModel: IReportModel) => availableModel.id === documentClassification.modelId
		);

		if (!model) {
			return;
		}

		return this.buildModelTree(settings, model, documentClassification);
	}

	private buildModelTree(settings: SuggestionMenuSettings, model: any, classification: IDocumentClassification): any {
		const classificationNodesCopy: IDocumentClassificationNode[] = ObjectUtils.copy(classification.nodes);

		const sentenceNodes = classificationNodesCopy
			.filter((node: IDocumentClassificationNode) => node.sentences.contains(settings.sentence.id))
			.map((node: IDocumentClassificationNode) => {
				return {
					id: node.id,
					name: node.name,
					parentId: node.modelIdPath.last(),
					level: node.modelIdPath.length,
					modelId: node.modelId,
					children: [],
					path: this.topicChickletsService.getPath(node, classification)
				};
			});

		const sentenceNodeLevels: number[] = sentenceNodes.map(node => node.level);
		const maxDepth: number = sentenceNodeLevels.length > 0 ? Math.max(...sentenceNodeLevels) : -Infinity;

		const rootNode = {
			id: model.rootNodeId,
			level: 0,
			depth: maxDepth,
			modelId: model.id,
			name: model.name,
			children: []
		};

		sentenceNodes.forEach(sentenceNode => {
			if (model.rootNodeId !== sentenceNode.parentId) {
				const parentNode = sentenceNodes.find(
					node => node.id === sentenceNode.parentId
				);

				if (parentNode) {
					parentNode.children.push(sentenceNode);
				}
			} else {
				rootNode.children.push(sentenceNode);
			}
		});

		return rootNode;
	}
}

app.service('suggestionMenuOptionsUtils', downgradeInjectable(SuggestionMenuOptionsUtils));
