import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import {
	CustomTemplatesComponent
} from '@cxstudio/dashboard-templates/custom-templates.component';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { Security } from '@cxstudio/auth/security-service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export default class TemplatesGridDefinition implements IGridDefinition<CustomTemplatesComponent> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('security') private readonly security: Security,
		private readonly gridUtils: GridUtilsService
	) {}

	private templateHamburgerFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (this.gridUtils.isFolder(dataContext) && !this.security.has('manage_internal_templates')) {
			return '';
		}

		return this.gridFormatterService.HamburgerFormatter(row, cell, value, columnDef, dataContext);
	}

	init = (): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'hamburger',
			field: 'name',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center cursor-pointer',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.templateHamburgerFormatter,
			resizable: false
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('templates.templates'),
			field: 'name',
			sortable: true,
			minWidth: 200,
			width: 400,
			defaultSortColumn: SortDirection.ASC,
			headerCssClass: 'cell-template-name',
			cssClass: 'cell-title br-dashboard-template',
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.TEMPLATES),
			searchable: true,
			isObjectNameColumn: true
		}, {
			id: 'description',
			name: this.locale.getString('templates.description'),
			field: 'description',
			sortable: true,
			minWidth: 200,
			width: 400,
			searchable: true
		}, {
			id: 'createdDate',
			name: this.locale.getString('dashboard.createdDate'),
			field: 'createdDate',
			sortable: true,
			minWidth: 100,
			width: 180,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'createdBy',
			name: this.locale.getString('dashboard.author'),
			field: 'ownerName',
			sortable: true,
			minWidth: 140,
			width: 240,
			searchable: true,
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'lastModified',
			name: this.locale.getString('templates.lastModified'),
			field: 'modifiedDate',
			sortable: true,
			minWidth: 100,
			width: 180,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'lastModifiedBy',
			name: this.locale.getString('templates.lastModifiedBy'),
			field: 'editorName',
			sortable: true,
			minWidth: 140,
			width: 200,
			searchable: true,
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'publicStatus',
			name: this.locale.getString('dashboard.status'),
			field: 'publicStatus',
			sortable: true,
			minWidth: 60,
			width: 60,
			formatter: this.gridFormatterService.StatusFormatter,
			cssClass: 'cell-status text-center',
			headerCssClass: 'text-center'
		}];

		return Promise.resolve(columns);
	}
}

app.service('templatesGridDefinition', downgradeInjectable(TemplatesGridDefinition));
