<div [ngBusy]="loading">
	<div class="panel panel-default">
		<div class="panel-heading">{{'mAccount.unitsIntegration'|i18n}}</div>
		<div class="panel-body">
			<p [i18n]="'mAccount.workspaceEditNotice'" [i18nParams]="{masterAccountName : masterAccountName}"></p>
			<div class="mb-8 workspace-selector">
				<inclusion-list
					[allItems]="availableWorkspaces"
					[selectedItems]="selectedWorkspaces"
					(selectedItemsChange)="updateSelectedWorkspaces($event)"
					[selectedItemsLabelCallback]="getSelectedWorkspacesTextCallback"
					[availableItemsLabelCallback]="getAvailableWorkspacesTextCallback"
				></inclusion-list>
			</div>
		</div>
	</div>
</div>
