export class IdMapper {
	private idMap: {[oldId: number]: number} = {};

	addIdMapping(oldId: number, newId: number): void {
		_.each(this.idMap, (value, key) => {
			// update transitive mapping, e.g. when replacing multiple times
			if (oldId === value)
				this.idMap[key] = newId;
		});
		this.idMap[oldId] = newId;
	}

	getCurrentId(originalId: number) {
		if (!_.isUndefined(this.idMap[originalId])) {
			// id was changed after history entry created, e.g. stored id of moved widget, but it was deleted and then recreated back
			return this.idMap[originalId];
		} else {
			// id was not changed
			return originalId;
		}
	}
}
