import * as _ from 'underscore';
import ConversionWidgetProperty from './conversion-widget-property.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { OBJECT_VIEWER_VISUAL_PROPERTIES } from '@cxstudio/reports/widgets/model-viewer-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

const RUBRIC_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('backgroundColor'),
	new ConversionWidgetProperty('showTooltips'),
];

export class RubricViewerWidget extends ReportWidget {

	constructor() {
		super(WidgetType.OBJECT_VIEWER);
	}

	initFrom(source: Widget, replace: Replacement, previousSource: Widget): void {
		let context = super.getContext(source, replace);
		let previousStateContext = super.getContext(previousSource, replace);

		super.initFromContext(previousSource ? previousStateContext : context);
		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = WidgetPropertyService.getValue('properties.selectedAttributes',
			previousStateContext, []);
		super.initProperties(OBJECT_VIEWER_VISUAL_PROPERTIES, context, 'visualProperties');
		super.initProperties(RUBRIC_VISUAL_PROPERTIES, previousStateContext, 'visualProperties');
	}
}
