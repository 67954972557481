import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { CxLocaleService } from '@app/core';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';

export class AssetObjectTypes<T extends StudioAsset, R> {

	items: {[key in ObjectType]: AssetObjectType<T, R>};

	constructor(
		private locale: CxLocaleService
	) {
		this.items = {
			dashboard: new AssetObjectType(
				ObjectType.DASHBOARD,
				this.locale.getString('dashboard.dashboards')
			),
			filter: new AssetObjectType(
				ObjectType.FILTER,
				this.locale.getString('common.filters')
			),
			metric: new AssetObjectType(
				ObjectType.METRIC,
				this.locale.getString('metrics.metrics')
			),
			model: new AssetObjectType(
				ObjectType.MODEL,
				this.locale.getString('common.models')
			),
			attribute: new AssetObjectType(
				ObjectType.ATTRIBUTE,
				this.locale.getString('common.attributes')
			),
			alert: new AssetObjectType(
				ObjectType.ALERT,
				this.locale.getString('header.alerts')
			),
			scorecard: new AssetObjectType(
				ObjectType.SCORECARD,
				this.locale.getString('alert.scorecard'))
		};
	}
}
