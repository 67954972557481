import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { RedirectService } from '@cxstudio/services/redirect-service';

@Component({
	selector: 'prepackaged-templates',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<section class="templates-list-wrap col-xs-12 mb-16" [ngBusy]="createDashboard">
		<div class="br-list-box col-xs-12">
			<h2>{{'templates.selectATemplate'|i18n}}</h2>
			<p class="help-block">{{'templates.generalTemplatePrompt'|i18n}}</p>

			<div class="row">
				<template-tiles></template-tiles>
			</div>
		</div>
	</section>`
})
export class PrepackagedTemplatesComponent implements OnInit {

	constructor(
		private locale: CxLocaleService,
		@Inject('maPropertiesService') private maPropertiesService: MAPropertiesService,
		@Inject('templateCommonService') private templateCommonService,
		@Inject('security') private security: Security,
		@Inject('redirectService') private redirectService: RedirectService
	) {}

	ngOnInit(): void {
		if (!(this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_TEMPLATES) || this.security.isAdminOrgUser())) {
			this.redirectService.goToDashboardList();
		}
	}

	createDashboard = (template: any) => this.templateCommonService.createDashboard(template);


}

app.directive('prepackagedTemplates', downgradeComponent({component: PrepackagedTemplatesComponent}) as angular.IDirectiveFactory);
