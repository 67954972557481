import { Component, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

@Component({
	selector: 'embedded-dashboard-footer',
	templateUrl: './embedded-dashboard-footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		.embed-footer {
			position: fixed;
			left: 0;
			bottom: 0;
			z-index: 2;
		}
	`]
})
export class EmbeddedDashboardFooterComponent {

	@Input() dashboard: Dashboard;

	constructor(
		private readonly ref: ChangeDetectorRef,
		@Inject('urlService') private readonly urlService: UrlService,
	) {}

	linkToDashboard(): string {
		return this.urlService.getDashboardUrl(this.dashboard.id);
	}
}
