import { Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'packaged-solutions',
	template: `
	<div class="page-tab-content">
		<ul ngbNav #nav="ngbNav" class="nav-tabs">
			<li ngbNavItem>
				<a ngbNavLink>{{'packages.catalog'|i18n}}</a>
				<ng-template ngbNavContent>
					<catalog-tab></catalog-tab>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagedSolutionsComponent {}

app.directive('packagedSolutions', downgradeComponent({component: PackagedSolutionsComponent}) as angular.IDirectiveFactory);