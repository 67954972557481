import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { Inject } from '@angular/core';
import { FormattedTableColumnsOauthClientsService } from '@app/shared/components/table/formatted-table-columns-oauth-clients.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { OAuthExternalProviderEditDialogComponent, OAuthExternalProviderEditDialogInput } from './oauth-external-provider-edit-dialog.component';
import OAuthExternalProvider from '../oauth-external-provider';
import { OAuthExternalProvidersApiService } from '../service/oauth-external-providers-api-service';

@Component({
	selector: 'oauth-external-providers',
	templateUrl: './oauth-external-providers.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthExternalProvidersComponent implements OnInit {
	loadingPromise: Promise<any>;
	externalProviders: OAuthExternalProvider[];
	rows: OAuthExternalProvider[];
	columns: Array<TableColumn<OAuthExternalProvider>>;

	private redirectUri = CONFIG.oauth.redirectUri;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private formattedTableColumnsOauthClientsService: FormattedTableColumnsOauthClientsService,
		private oauthExternalProvidersApiService: OAuthExternalProvidersApiService,
		@Inject('contextMenuTree') protected contextMenuTree: ContextMenuTree,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService
	) {
	}

	ngOnInit(): void {
		this.reloadExternalProviders();
		this.columns = this.getColumns();
	}

	reloadExternalProviders = () => {
		this.loadingPromise = this.oauthExternalProvidersApiService.getExternalProviders()
			.then(externalProviders => {
				this.externalProviders = _.forEach(externalProviders, (externalProvider) => {
					externalProvider.landingPage = this.redirectUri + '/' + externalProvider.aliasName;
					this.oauthExternalProvidersApiService.getLinkedMasterAccounts(externalProvider.aliasName).then((linkedMasterAccounts) => {
						externalProvider.linkedMasterAccounts = _.values(linkedMasterAccounts).join(', ');
						this.rows = this.getRows(externalProviders);
						this.ref.markForCheck();
					});
				});
			});
	}

	getColumns = (): Array<TableColumn<OAuthExternalProvider>> => {
		let columns = [];
		columns.push(this.formattedTableColumnsOauthClientsService.getHamburgerColumn(this.onMenuClick));
		columns.push({
			name: 'aliasName',
			displayName: this.locale.getString('administration.oauthAliasName'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'linkedMasterAccounts',
			displayName: this.locale.getString('administration.oauthLinkedMasterAccounts'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.2
		});
		columns.push({
			name: 'clientId',
			displayName: this.locale.getString('administration.oauthClientId'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.3
		});
		columns.push({
			name: 'applicationKind',
			displayName: this.locale.getString('administration.oauthApplicationKind'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'landingPage',
			displayName: this.locale.getString('administration.oauthProviderLandingPage'),
			formatter: this.tableFormattersService.linkFormatter,
			width: 0.3
		});
		return columns;
	}

	getRows = (clientDetailsList: OAuthExternalProvider[]): OAuthExternalProvider[] => {
		return _.sortBy(clientDetailsList, 'aiasName');
	}

	addProvider = (): void => {
		this.loadingPromise = this.openExternalProviderEditDialog(new OAuthExternalProvider())
			.then((clientDetailsData) => this.oauthExternalProvidersApiService.createExternalProvider(clientDetailsData))
			.then(() => this.reloadExternalProviders());
	}

	editProvider = (externalProviderTableItem: OAuthExternalProvider): void => {
		let externalProvider: OAuthExternalProvider = _.find(this.externalProviders, { clientId: externalProviderTableItem.clientId });
		this.loadingPromise = this.openExternalProviderEditDialog(externalProvider)
			.then((clientDetailsData) => this.oauthExternalProvidersApiService.updateExternalProvider(clientDetailsData))
			.then(() => this.reloadExternalProviders());
	}

	removeProvider = (externalProviderTableItem: OAuthExternalProvider): void => {
		let externalProvider: OAuthExternalProvider = _.find(this.externalProviders, { clientId: externalProviderTableItem.clientId });
		this.loadingPromise = this.oauthExternalProvidersApiService.removeExternalProvider(externalProvider.aliasName)
			.then(() => this.reloadExternalProviders());
	}

	protected openExternalProviderEditDialog(externalProvider: OAuthExternalProvider): Promise<any> {
		const input: OAuthExternalProviderEditDialogInput = { externalProvider };
		let options: ModalOptions = { size: ModalSize.LARGE };

		return this.cxDialogService.openDialog(OAuthExternalProviderEditDialogComponent, input, options).result;
	}

	protected onMenuClick = (object: OAuthExternalProvider, event: any): void => {
		this.contextMenuTree.showObjectListMenu(event, object, this.getContextMenu(), 'oauth-external-providers', 360);
	}

	private getContextMenu(): Array<ContextMenuItem<OAuthExternalProvider>> {
		const OPTIONS = this.getOptions();

		let menuOptions: Array<ContextMenuItem<OAuthExternalProvider>> = [
			OPTIONS.EDIT
		];
		menuOptions.push(OPTIONS.DELETE);

		return menuOptions;
	}

	private getOptions(): {[name: string]: ContextMenuItem<OAuthExternalProvider>} {
		return {
			EDIT: {
				text: this.locale.getString('common.edit'),
				name: 'edit',
				func: (provider: OAuthExternalProvider) => this.editProvider(provider)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (provider: OAuthExternalProvider) => this.removeProvider(provider)
			}
		};
	}
}
