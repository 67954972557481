import { PipeTransform, Pipe, Inject } from '@angular/core';
import { IDocumentClassification } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';

@Pipe({
	name: 'explorerModelFilter'
})
export class ExplorerModelFilterPipe implements PipeTransform {

	constructor(@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService) {}

	transform(models: IDocumentClassification[], isDocExplorer: boolean, 
		projectModels: {[modelName: string]: boolean}): IDocumentClassification[] {

		if (!models) return [];

		return _.filter(models, model => this.enrichmentAttributesService.isModelVisible(model, isDocExplorer, projectModels));
	}
}