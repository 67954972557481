import { NgModule } from '@angular/core';
import { DashboardActionsComponent } from '@app/modules/dashboard-actions/dashboard-actions/dashboard-actions.component';
import { JumpLinkComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/jump-link.component';
import { JumpLinksComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/jump-links.component';
import { PillsSelectorComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/pills-selector.component';
import { SpellCheckResultsComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spell-check-results.component';
import { SpotCheckModalComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-modal.component';
import { SpotCheckResultItemComponent } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-item.component';
import { ExperimentalUIToggleComponent } from '@app/modules/dashboard-actions/experimental-ui-toggle.component';
import { FullscreenButtonComponent } from '@app/modules/dashboard-actions/fullscreen-button/fullscreen-button.component';
import { PatternFillsToggleComponent } from '@app/modules/dashboard-actions/pattern-fills-toggle.component';
import { SnapshotDateComponent } from '@app/modules/dashboard-actions/snapshot-date/snapshot-date.component';
import { UndoControlsComponent } from '@app/modules/dashboard-actions/undo/undo-controls/undo-controls.component';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { CardModule } from '@clarabridge/unified-angular-components';
import { NgPipesModule } from 'ngx-pipes';
import { DialogModule } from '../dialog/dialog.module';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { DashboardDarkMenuToggleComponent } from './dashboard-theme-toggle.component';
import { DashboardTroubleshootingModalComponent } from './dashboard-troubleshooting-modal/dashboard-troubleshooting-modal.component';
import { WidgetMovementOptionsComponent } from './dashboard-widget-options.component';
import { DashboardZoomComponent } from './dashboard-zoom.component';
import { EmbedDashboardService } from './embed-dashboard.service';
import { NoPublishedReportComponent } from '@app/modules/dashboard-actions/no-published-report/no-published-report.component';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		DialogModule,
		CardModule,
		I18nModule,
	],
	exports: [
		ActionsMenuComponent,
		PatternFillsToggleComponent,
		FullscreenButtonComponent,
		SnapshotDateComponent,
		NoPublishedReportComponent,
	],
	declarations: [
		PatternFillsToggleComponent,
		ExperimentalUIToggleComponent,
		ActionsMenuComponent,
		DashboardDarkMenuToggleComponent,
		DashboardZoomComponent,
		WidgetMovementOptionsComponent,
		UndoControlsComponent,
		DashboardActionsComponent,
		FullscreenButtonComponent,
		SpotCheckModalComponent,
		JumpLinksComponent,
		JumpLinkComponent,
		PillsSelectorComponent,
		SpotCheckResultItemComponent,
		SpellCheckResultsComponent,
		DashboardTroubleshootingModalComponent,
		SnapshotDateComponent,
		NoPublishedReportComponent,
	],
	providers: [
		EmbedDashboardService
	]
})
export class DashboardActionsModule {}
