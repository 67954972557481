import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ChannelTypes } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ConversationParticipants } from '@app/modules/document-explorer/conversation-participants.class';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import * as d3 from 'd3';

@Component({
	selector: 'participant-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div #dropdown class="dropdown" ngbDropdown [autoClose]="'outside'">
	<button ngbDropdownToggle class="kb-focusable pr-32 btn-secondary" (keydown)="focusOnOpen($event)">{{'docExplorer.allSpeakers'|i18n}}<span class="caret" aria-hidden="true"></span></button>
	<div class="dropdown-menu" ngbDropdownMenu (keydown)="closeOnEsc($event)">
		<li *ngIf="hasAdditionalVerbatim">
			<a href="javascript:void(0);" (click)="toggleAdditionalVerbatim()">{{additionalVerbatimPrompt}}</a>
		</li>
		<li *ngIf="hasAdditionalVerbatim" class="divider" role="separator"></li>
		<ng-container *ngFor="let group of PARTICIPANT_GROUPS; index as groupIndex">
			<ng-container *ngIf="participants[group] && participants[group].length">
				<li *ngIf="isNotFirstPopulatedGroup(group, groupIndex)" class="divider" role="separator"></li>
				<li *ngFor="let member of participants[group]; index as memberIndex">
					<a (click)="textClick($event, member)" href="javascript:void(0)"
						(keydown)="toggleParticipant($event, member)"
						class="dropdown-item d-flex align-items-center justify-between"
						[ngClass]="{'disabled': showingAdditionalVerbatim}">
						<span>{{getLabel(group)}} {{(memberIndex+1).pad(2)}}</span>
						<cb-toggle [disabled]="showingAdditionalVerbatim"
							(ngModelChange)="switchClick(member)"
							[attr.aria-label]="getAriaLabel(group, memberIndex+1)"
							[(ngModel)]="member.enabled"></cb-toggle>
					</a>
				</li>
			</ng-container>
		</ng-container>
	</div>
</div>
`})

export class ParticipantSelectorComponent implements OnInit, OnChanges {
	@Input() participants: ConversationParticipants;
	@Input() channelLabels: ConversationChannelLabels;
	@Input() hasAdditionalVerbatim?: boolean;
	@ViewChild(NgbDropdown) dropdown: NgbDropdown;
	@ViewChild('dropdown') dropdownEl: ElementRef;
	@Output() selectionChange = new EventEmitter<{item: any} | void>();
	@Output() showVerbatim = new EventEmitter<void>();
	@Output() hideVerbatim = new EventEmitter<void>();

	useDashboardColor: boolean;
	additionalVerbatimPrompt: string = this.locale.getString('docExplorer.viewRelatedFeedback');
	showingAdditionalVerbatim: boolean = false;

	readonly PARTICIPANT_GROUPS = [ChannelTypes.AGENT, ChannelTypes.CLIENT, ChannelTypes.BOT, ChannelTypes.UNKNOWN];

	constructor(
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.useDashboardColor = d3.select('.document-preview-modal-window').empty();
	}

	ngOnChanges(): void {
		// if the doc changes, reset showingAdditionalVerbatim
		this.showingAdditionalVerbatim = false;
	}

	textClick = (event, item): void => {
		event.preventDefault();
		if (this.showingAdditionalVerbatim) return;

		// text click needs to manually toggle item enablement
		item.enabled = !item.enabled;
		this.selectionChange.emit();
	}

	switchClick = (member) => {
		this.selectionChange.emit({item: member});
	}

	getLabel = (groupName: ChannelTypes): string => {
		// use custom labels if we have them
		if (this.channelLabels && this.channelLabels[groupName])
			return this.channelLabels[groupName];

		return this.locale.getString(`docExplorer.${groupName}Label`);
	}

	getAriaLabel = (groupName: ChannelTypes, index: number): string => {
		let groupLabel = `${this.getLabel(groupName)} ${String(index).padStart(2, '0')}`;
		return this.locale.getString('docExplorer.participantSelectorGroupLabel', {groupLabel});
	}

	toggleAdditionalVerbatim = (): void => {
		this.showingAdditionalVerbatim = !this.showingAdditionalVerbatim;
		if (this.showingAdditionalVerbatim) {
			this.additionalVerbatimPrompt = this.locale.getString('docExplorer.viewConversation');
			this.showVerbatim.emit();
		} else {
			this.additionalVerbatimPrompt = this.locale.getString('docExplorer.viewRelatedFeedback');
			this.hideVerbatim.emit();
		}
	}

	toggleParticipant = (event: KeyboardEvent, item) => {
		if (KeyboardUtils.isEventKeyOneOf(event, [Key.ENTER, Key.SPACE]) && this.dropdown.isOpen()) {
			this.textClick(event, item);
		}
	}

	closeOnEsc = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) && this.dropdown.isOpen()) {
			event.stopPropagation();
			this.dropdown.close();
			$(this.dropdownEl.nativeElement).find('button').first().trigger('focus');
		}
	}

	focusOnOpen = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && !this.dropdown.isOpen()) {
			setTimeout(() => $(this.dropdownEl.nativeElement).find('.dropdown-menu :focusable').first().trigger('focus'));
		}
	}

	/**
	 *  Ensure that at least one prior group in the list has participants in this conversation
	*/
	private somePriorGroupHasParticipants(currentGroupIndex: number): boolean {
		for (let i = currentGroupIndex - 1; i >= 0; i--) {
			if (this.participants[this.PARTICIPANT_GROUPS[i]].length) return true;
		}
		return false;
	}

	isNotFirstPopulatedGroup = (group: ChannelTypes, groupIndex: number): boolean => {
		return !!((groupIndex > 0) &&
			this.participants[group].length &&
			this.somePriorGroupHasParticipants(groupIndex));
	}
}

app.directive('participantSelector', downgradeComponent({component: ParticipantSelectorComponent}) as angular.IDirectiveFactory);
