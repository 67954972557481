<modal-header
	(cancel)="dismiss()"
	[modalTitle]="modalTitle">
</modal-header>
<div class="modal-body">
	<p [i18n]="'common.embedPrompt'" [i18nParams]="{objectType: input.objectType}"></p>
	<div class="mv-16 d-flex justify-end align-items-center">
		<label class="mb-0">{{'common.includeIframe'|i18n}}</label>
		<cb-toggle [(ngModel)]="showEnhancedEmbed"></cb-toggle>
	</div>

	<section class="d-flex flex-direction-column" *ngIf="!showEnhancedEmbed">
		<label>{{'common.embedLink'|i18n}}</label>
		<input readonly type="text" [(ngModel)]="embedCodeSimple" class="cursor-default w-100-percent">
	</section>

	<section class="d-flex flex-direction-column" *ngIf="showEnhancedEmbed">
		<label>{{'common.embedCode'|i18n}}</label>
		<textarea
			class="cursor-default white-space-normal"
			readonly
			rows="5"
		>{{embedCodeWithIframe}}</textarea>
	</section>
</div>
<save-modal-footer
	(cancel)="dismiss()"
	(save)="copyToClipboard()"
	[cancelText]="'common.done'|i18n"
	[saveText]="(showEnhancedEmbed ? 'common.copyCode' : 'common.copyLink')|i18n">
</save-modal-footer>
