import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';

export default class MasterAccountDisableData {
	masterAccount: MasterAccount;
	userDefaultMasterAccountMap: Record<number, number>;

	constructor(masterAccount: MasterAccount, userMasterAccountMap: Record<number, number>) {
		this.masterAccount = masterAccount;
		this.userDefaultMasterAccountMap = userMasterAccountMap;
	}
}