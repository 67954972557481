import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IDocumentClassification, IDocumentClassificationNode } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';

@Injectable()
export class TopicChickletsService {
	
	constructor(
	) { }

	getTopicDisplayName = (name: string): string => {
		return (name && name.length) ? name.replace('-->', ' > ') : '';
	}

	getPath = (node: IDocumentClassificationNode, model: IDocumentClassification) => {
		// potentially populate these paths during initialization to avoid updates on mouseover
		let pathParts = node.modelIdPath.map((pathLevel) => {
			let pathName: any = _.find(model.nodes, {id: pathLevel});
			return pathName ? pathName.name : '';
		});
		pathParts.unshift(this.getTopicDisplayName(model.modelName));
		pathParts.push(node.name);

		return pathParts.filter((val) => !!val).join(' > ');
	}

	getTopicChickletDisplayName = (node: IDocumentClassificationNode) => {
		return `(${node.sentences.length}) ${node.name}`;
	}

}

app.service('topicChickletsService', downgradeInjectable(TopicChickletsService));