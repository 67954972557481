export class AttributesStats {
	total: number;
	attributes: AttributeStatsItem[];
	models: ModelStatsItem[];
}

export interface BaseStatsItem {
	distinctValuesCount: number;
	populatedPercent: number;
}
export interface AttributeStatsItem extends BaseStatsItem {
	name: string;
}
export interface ModelStatsItem extends BaseStatsItem {
	id: number;
}