

export enum HierarchyPublicationState {
	NOT_PUBLISHED = 'NOT_PUBLISHED',
	NOT_SYNCHRONIZED = 'NOT_SYNCHRONIZED',
	UNPUBLISHING = 'UNPUBLISHING',
	PUBLISHING = 'PUBLISHING',
	PUBLISHED = 'PUBLISHED',
	CLASSIFYING = 'CLASSIFYING',
	CLASSIFIED = 'CLASSIFIED',
	REPLACEMENT_FAILED = 'REPLACEMENT_FAILED',
	REPLACED = 'REPLACED'
}


export enum OrgHierarchyUIStatus {
	NOT_READY,
	PROCESSING,
	READY,
	READY_PUBLISHED,
	READY_UNPUBLISHED
}

