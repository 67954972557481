import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { IContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { IStudioAlertsContextMenuScope } from '@cxstudio/alerts/studio-alert-context-menu-utils';
import { IStudioAlertActionsService } from '@cxstudio/alerts/studio-alert-actions-service';
import { IStudioAlertActionsServiceScope } from '@cxstudio/alerts/studio-alert-actions-service';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { SelectionControllerBase } from '@app/modules/item-grid/selection/selection-controller-base';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class StudioAlertServiceFactory {
	constructor(
		@Inject('StudioAlertActionsService') private StudioAlertActionsService,
		@Inject('StudioAlertContextMenuUtils') private StudioAlertContextMenuUtils
	) {}

	createActionsService(scope: IStudioAlertActionsServiceScope): IStudioAlertActionsService {
		return new this.StudioAlertActionsService(scope);
	}

	createContextMenuUtils(scope: IStudioAlertsContextMenuScope): IContextMenuUtils<StudioAlert> {
		return new this.StudioAlertContextMenuUtils(scope);
	}

	createSelectionUtils(alertsController: SelectionControllerBase<StudioAlert>): GenericSelectUtils<StudioAlert> {
		return new GenericSelectUtils<StudioAlert>(alertsController);
	}
}

// using factory for type checking and easier mocking
app.service('studioAlertServiceFactory', downgradeInjectable(StudioAlertServiceFactory));
