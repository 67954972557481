import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import * as _ from 'underscore';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
export class DocumentPreviewer implements ng.IComponentController {
	private widget: Widget;
	private projectUniqueId: string;
	private preferences: DocViewPreferences;
	private showSentences: boolean;
	private showContext: boolean;
	private projectTimezone;
	private documentManager: IDocumentPreviewerControls;
	private isDocExplorer: boolean;
	auditMode: boolean;
	rebuttalMode: boolean;
	private showExportData: () => boolean;
	private isStatsEnabled: () => boolean;
	//for testing
	fullPageBeta: boolean;

	private availableModels: any[];
	private isTwitter: (doc: any) => boolean;
	private loading;

	formatters: IMetricFormatters = {
		time: _.noop,
		date: _.noop,
		ease: _.noop,
		emotion: _.noop,
		sentiment: _.noop,
		sentimentColor: _.noop
	} as any;


	constructor(
		private $scope: ISimpleScope,
		private analyticPreviewFormatting: AnalyticPreviewFormatting,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}


	$onInit = () => {
		//for testing
		this.fullPageBeta = true;
		this.isTwitter = DocumentTypeUtils.isTwitter;
		if (this.widget && this.isProjectSelected()) {
			this.analyticPreviewFormatting.getWidgetSentenceFormatters(this.widget, this.isDocExplorer)
				.then(formatters => _.extend(this.formatters, formatters));
		}
	}

	private isProjectSelected(): boolean {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return WorkspaceProjectUtils.isProjectSelected(this.widget.properties.workspaceProject);
		} else {
			return !_.isUndefined(this.widget.properties.project);
		}
	}

	getVisibleSidePanesCount = (): number => {
		return _.countBy([this.showSentences, this.showContext]).true || 0;
	}

	getPermalink = (document) => {
		if (_.isUndefined(document) || _.isUndefined(document.attributes)) {
			return '';
		}

		for (let attribute of document.attributes) {
			if (attribute.name === 'permalink') {
				return attribute.value;
			}
		}
		return '';
	}

	redrawModelTopics = (modelId: number) => {
		this.$scope.$broadcast('redraw-topics');
		this.documentManager.events.redrawTopics();
	}
}


app.component('documentPreviewer', {
	bindings: {
		demo: '<?',
		showSentiment: '<',
		showSentences: '<',
		showContext: '<',
		panels: '<',
		preferences: '=',
		documentManager: '<',
		widget: '<',
		filters: '<',
		sortBy: '<',
		current: '<',
		voiceEnabled: '<',
		engagorEnabled: '<',
		availableModels: '<',
		favorites: '<',
		noResults: '<',
		createDashboardCallback: '<',
		isDocExplorer: '<',
		projectUniqueId: '<',
		auditMode: '<',
		rebuttalMode: '<',
		predefinedMetrics: '<',
		showExportData: '<',
		isStatsEnabled: '<',
		showEmptyAttributes: '<'
	},
	controller: DocumentPreviewer,
	templateUrl: 'partials/document-explorer/document-previewer-component.html'
});
