import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { DrillActionFunction } from '@app/modules/reports/utils/context-menu/drill/widget-drill-actions.service';

// TODO refactor into class hierarchy
export class DrillPoint implements Partial<IDataPointObject> {
	id: number | string;
	name: string;
	uniqueName: string;
	color: string;
	displayName?: string;
	volume?: number;

	fullPath?: string; // 1.0
	category?: string; // 1.0
	sentType?: string; // 1.0
	catName?: string; // 1.0
	pieName?: string; // 1.0?

	_element?: any; // for highlighting in widget linking
	_highlightPoint?: () => void;
	_unhighlightPoint?: () => void;
	isWeekDescription?: boolean;
	P1StartDate?: string; // ISO string with timezone
	P1EndDate?: string;
	dateRangeP2?: any; // p2 range for charts
	P2StartDate?: string; // p2 start/end date for table
	P2EndDate?: string;
	_pop?: string; // current or historic point
	popName?: string;
	idPath?: string;
	attributeName?: string;

	_custom_filter?: DrillFilter;

	selectedMetricName?: string;
	level?: number;

	isModelNodeLeaf?: boolean;
	modelNodeId?: number;

	groupBy?: any;
	// _metadata_[group_name]?: CustomGroupMetadata;
	_group: ReportGrouping;

	_selectionId?: any; // custom generated id for highlighting

	_uniqName?: any;

	nonReporting?: boolean; // when it doesn't support report drilling, e.g. only some UI actions like toggle
	supportUseAsFilter?: boolean; // currently only model viewer

	link?: {
		from: DrillPoint;
		to: DrillPoint;
	};
	seriesType?: PlotLineAxis;

	_calculation_series?: string;

	//key terms / outliers
	rootCause_attributeName?: string;

	verbatimId?: number;
	documentId?: number;
	drillAction?: DrillActionFunction;
}
