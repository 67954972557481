export enum AttributeType {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	DATE = 'DATE',
	DIMENSIONAL = 'DIMENSIONAL',
	RANGE = 'RANGE',
	SATSCORE = 'SATSCORE',
	SENTIMENT_TRENDING = 'SENTIMENT_TRENDING',
	DERIVED_FROM_CATEGORY = 'DERIVED_FROM_CATEGORY' //special value, actually stored with type TEXT
}
