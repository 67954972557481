<modal-header
	(cancel)="close()"
	[modalTitle]="'dashboardTroubleshooting.troubleshootingGuides' | i18n"
></modal-header>

<div class="modal-body p-16">
	<p class="text-lg-1">{{'dashboardTroubleshooting.guidesDescription'|i18n}}</p>
	<hr>
	<collapsing-panel [collapsed]="activeSection !== sections.SLOWNESS" (collapsedChange)="changeActivePanel(sections.SLOWNESS, $event)">
		<panel-heading>
			<strong>{{'dashboardTroubleshooting.slownessPanelTitle'|i18n}}</strong>
		</panel-heading>
		<panel-body>
			<p>
				<support-post-link #supportLink></support-post-link>
				{{ 'dashboardTroubleshooting.slownessPanelContent'|i18n }}
				<a href="javascript:void(0)" (click)="supportLink.postRedirect()">
					<span class="lowercase" [i18n]="'dashboard.here'"></span>.
				</a>
			</p>

			<p>{{'dashboardTroubleshooting.performanceChecklistTitle'|i18n}}</p>
			<p><strong>{{'dashboardTroubleshooting.performanceCheckFollowing'|i18n}}:</strong></p>
			<ol>
				<li>
					<strong>{{'dashboardTroubleshooting.networkSpeed'|i18n}}</strong>
					<span [innerHtml]="'dashboardTroubleshooting.networkSpeedExplanation'|i18n"></span>
				</li>
				<li>
					<strong>{{'dashboardTroubleshooting.networkSecurity'|i18n}}</strong>
					{{'dashboardTroubleshooting.networkSecurityExplanation'|i18n}}
				</li>
				<li>
					<strong>{{'dashboardTroubleshooting.computerResources'|i18n}}</strong>
					{{'dashboardTroubleshooting.computerResourcesExplanation'|i18n}}
				</li>
				<li>
					<strong>{{'dashboardTroubleshooting.numberOfWidgets'|i18n}}</strong>
					{{'dashboardTroubleshooting.numberOfWidgetsExplanation'|i18n:widgetsUsage}}
				</li>
			</ol>
			<p>{{'dashboardTroubleshooting.slownessWhatYouCanDoListTitle'|i18n}}</p>
			<ol>
				<li><strong>{{'dashboardTroubleshooting.turnOnOptimization'|i18n}}</strong> {{'dashboardTroubleshooting.turnOnOptimizationExplanation'|i18n}}</li>
				<li><strong>{{'dashboardTroubleshooting.limitHeavyWidgets'|i18n}}</strong> {{'dashboardTroubleshooting.limitHeavyWidgetsExplanation'|i18n}}</li>
				<li><strong>{{'dashboardTroubleshooting.useSelectorWidgets'|i18n}}</strong> {{'dashboardTroubleshooting.useSelectorWidgetsExplanation'|i18n}}</li>
			</ol>
		</panel-body>
	</collapsing-panel>
	<collapsing-panel [collapsed]="activeSection !== sections.UNAVAILABLE" (collapsedChange)="changeActivePanel(sections.UNAVAILABLE, $event)">
		<panel-heading>
			<strong>{{'dashboardTroubleshooting.dataUnavailablePanelTitle'|i18n}}</strong>
		</panel-heading>
		<panel-body>
			<p>{{'dashboardTroubleshooting.dataUnavailableListTitle'|i18n}}</p>
			<ol>
				<li>{{'dashboardTroubleshooting.dataUnavailableNotClassified'|i18n}}</li>
				<li>{{'dashboardTroubleshooting.dataUnavailableSpecifiedPeriod'|i18n}}</li>
				<li>{{'dashboardTroubleshooting.dataUnavailableRestrictiveFilters'|i18n}}</li>
				<li>{{'dashboardTroubleshooting.dataUnavailableAllZero'|i18n}}</li>
			</ol>
		</panel-body>
	</collapsing-panel>
	<hr>
	<h3 class="text-lg-2">{{'dashboardTroubleshooting.helpSectionTitle'|i18n}}</h3>
	<div class="grid-2-col">
		<cb-action-card>
			<card-title>{{'dashboardTroubleshooting.accessibilityCardTitle' | i18n}}</card-title>
			<card-description>{{'dashboardTroubleshooting.accessibilityCardDescription' | i18n}}</card-description>
			<card-action>
				<a class="btn btn-secondary d-flex-inline align-center"
					[attr.aria-label]="getReadMoreAriaLabel('dashboardTroubleshooting.accessibilityCardTitle' | i18n)"
					href="https://www.qualtrics.com/support/xm-discover/studio/getting-started-studio/studio-accessibility/accessible-dashboard-design-tips-studio/?utm_medium=product&utm_source=studio"
					target="_blank">
					{{'dashboardTroubleshooting.readMore' | i18n}}
				</a>
			</card-action>
		</cb-action-card>
		<cb-action-card>
			<card-title>{{'dashboardTroubleshooting.dashboardEditingCardTitle' | i18n}}</card-title>
			<card-description>{{'dashboardTroubleshooting.dashboardEditingCardDescription' | i18n}}</card-description>
			<card-action>
				<a class="btn btn-secondary d-flex-inline align-center"
					[attr.aria-label]="getReadMoreAriaLabel('dashboardTroubleshooting.dashboardEditingCardTitle' | i18n)"
					href="https://www.qualtrics.com/support/xm-discover/studio/studio-dashboards/managing-studio-dashboards/editing-dashboards-studio/?utm_medium=product&utm_source=studio"
					target="_blank">
					{{'dashboardTroubleshooting.readMore' | i18n}}
				</a>
			</card-action>
		</cb-action-card>
		<cb-action-card>
			<card-title>{{'dashboardTroubleshooting.widgetEditingCardTitle' | i18n}}</card-title>
			<card-description>{{'dashboardTroubleshooting.widgetEditingCardDescription' | i18n}}</card-description>
			<card-action>
				<a class="btn btn-secondary d-flex-inline align-center"
					[attr.aria-label]="getReadMoreAriaLabel('dashboardTroubleshooting.widgetEditingCardTitle' | i18n)"
					href="https://www.qualtrics.com/support/xm-discover/studio/studio-dashboards/studio-widgets/widgets-basic-overview-studio/?utm_medium=product&utm_source=studio"
					target="_blank">
					{{'dashboardTroubleshooting.readMore' | i18n}}
				</a>
			</card-action>
		</cb-action-card>
		<cb-action-card>
			<card-title>{{'dashboardTroubleshooting.allTopicsCardTitle' | i18n}}</card-title>
			<card-description>{{'dashboardTroubleshooting.allTopicsCardDescription' | i18n}}</card-description>
			<card-action>
				<a class="btn btn-secondary d-flex-inline align-center"
					[attr.aria-label]="'dashboardTroubleshooting.exploreAllTopics' | i18n"
					href="https://www.qualtrics.com/support/xm-discover/studio/getting-started-studio/studio-basic-overview/?utm_medium=product&utm_source=studio"
					target="_blank">
					{{'dashboardTroubleshooting.exploreAllTopics' | i18n}}
				</a>
			</card-action>
		</cb-action-card>
	</div>
</div>

<ok-modal-footer
	(cancel)="close()"
	[okText]="'common.close' | i18n">
</ok-modal-footer>
