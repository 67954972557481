import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import * as cloneDeep from 'lodash.clonedeep';

export class DashboardPropertiesChangeAction implements DashboardChangeAction {

	// before state
	propertiesBefore: {[key: string]: any};

	// after state
	propertiesAfter: Dashboard;
	isReverse: boolean = false;
	treeItem: Dashboard;
	currentProperty: any;

	constructor(treeItem: Dashboard, propertiesBefore: {[key: string]: any}, propertiesAfter: any,
		private dashboardPropsService: DashboardPropsService,
		private dashboardService: DashboardService, isReverse?: boolean) {
		this.propertiesBefore =  cloneDeep(propertiesBefore);
		this.propertiesAfter = cloneDeep(propertiesAfter);
		this.isReverse = isReverse;
		this.treeItem = treeItem;
		this.currentProperty = propertiesAfter;
	}

	reverse(): DashboardChangeAction {
		return new DashboardPropertiesChangeAction(this.treeItem, this.propertiesAfter,
			this.propertiesBefore, this.dashboardPropsService, this.dashboardService, !this.isReverse);
	}

	apply(): Promise<void> {
		this.dashboardPropsService.setRedoUndo(true);
		return PromiseUtils.wrap(this.dashboardService.applyUpdates(this.treeItem, this.currentProperty, true, false)
			.then((data) => {
				this.dashboardPropsService.updateDashboardProps(data);
				this.dashboardPropsService.updateInitialDashboardProps(this.currentProperty);
			}));
	}
}


