import ILocale from '@cxstudio/interfaces/locale-interface';
import { CapitalizationUtils, StringFormatter } from '@cxstudio/services/capitalization-utils.class';
import { CapitalizationType } from './constants/capitalization-type.enum';
import * as _ from 'underscore';

export class CapitalizationService {
	readonly  SPLIT_REGEX = /(&gt;|&lt;|&quot;|&amp;)/;

	reflect = CapitalizationUtils.reflect;

	constructor(
		private locale: ILocale,
	) {}


	getOptions(): Array<{name: string, value: CapitalizationType}> {
		return [
			{ name: this.locale.getString('widget.defaultCapitalization'), value: CapitalizationType.DEFAULT },
			{ name: this.locale.getString('widget.lowercase'), value: CapitalizationType.LOWERCASE },
			{ name: this.locale.getString('widget.uppercase'), value: CapitalizationType.UPPERCASE },
			{ name: this.locale.getString('widget.sentenceCase'), value: CapitalizationType.SENTENCE_CASE },
			{ name: this.locale.getString('widget.firstLetter'), value: CapitalizationType.FIRST_LETTER }
		];
	}

	getWrappedFormatter(type: CapitalizationType): StringFormatter {
		return CapitalizationUtils.getWrappedFormatter(type);
	}
}

app.service('capitalization', CapitalizationService);
