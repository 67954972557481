import ILocale from '@cxstudio/interfaces/locale-interface';
export class UniqueNameService {

	constructor(
		private locale: ILocale
	) {
	}

	resolveCopyName(names: string[], itemName: string): string {
		return this.resolveName(names, itemName, 'common.copiedItemNamePattern', 'common.indexedCopiedItemNamePattern');
	}

	resolveUniqueName(names: string[], itemName: string): string {
		return this.resolveName(names, itemName, 'common.newNamePattern', 'common.indexedNewItemNamePattern');
	}

	private resolveName(names: string[], itemName: string, copiedItemNamePattern: string, indexedCopiedItemNamePattern: string): string {
		const originalName = itemName;
		let copyIndex = 0;
		
		while (names.contains(itemName)) {
			copyIndex++;
			itemName = copyIndex === 1
				? this.locale.getString(copiedItemNamePattern, { originalName })
				: this.locale.getString(indexedCopiedItemNamePattern, { originalName, index: copyIndex });
		}

		return itemName;
	}

}

app.service('uniqueNameService', UniqueNameService);