<div>
	<div class="twitter-container" id="{{styleId}}" [ngClass]="visibilityClasses">
		<div class="clearfix mb-16">
			<div class="block">
				<img [attr.aria-label]="tweet.userDisplayName" class="twitter-profile-image pull-left mr-16" [src]="tweet.imageUrl"
					onerror="if (this.src.indexOf('default_profile_images') == -1) this.src='//a0.twimg.com/sticky/default_profile_images/default_profile_6_normal.png'">
				<div class="pull-left">
					<div>
						<span class="twitter-display-name">{{tweet.userDisplayName}}</span>
						<i class="text-twitter-color twitter-logo q-icon-twitter lh-1"></i>
					</div>
					<div class="twitter-creator">@{{tweet.userName}}</div>
				</div>
			</div>
			<div *ngIf="documentDate" class="pull-right mr-8">
				<date-label
					[documentDate]="documentDate"
					[formatters]="formatters">
				</date-label>
			</div>
		</div>
		<ng-container *ngIf="tweet.verbatim">
			<div class="twitter-comment"
				*ngFor="let sentence of tweet.verbatim.sentences">
				<sentence-preview
					[sentence]="sentence"
					[showSentiment]="showSentiment"
					[sentimentColorFunc]="formatters.sentimentColor"
					[document]="true"
					[highlightTrigger]="highlightTrigger"
					(sentenceClick)="openSuggestionMenu($event)">
				</sentence-preview>
				&nbsp;
			</div>
			<sentence-pills *ngIf="hasTopics() || hasBadges()"
				class="sentence-topics lh-1 d-block mv-4"
				[topics]="sentenceTopics"
				[auditMode]="auditMode"
				[width]="messageWidth"
				[highlightTrigger]="highlightTrigger"
				[showAll]="uiOptions?.expandTopics"
				[enabledModels]="auditModeModels"
				[hasProfanity]="hasProfanity"
				[sentences]="tweet.verbatim.sentences"
				[predefinedMetrics]="predefinedMetrics"
				[showTopics]="showTopics"
				[showEnrichments]="showSentiment"
				(tuneEnrichment)="onTuneEnrichment($event)"
				(removeTopic)="onRemoveTopic($event)">
			</sentence-pills>
		</ng-container>
		<div *ngIf="tweet.media"
			class="twitter-media">
			<a *ngIf="tweet.media.type == 'photo'"
				class="twitter-photo"
				href="{{tweet.media.display_url}}">
				<img class="twitter-photo-image" [src]="tweet.media.media_url.replace('http:', '')">
			</a>
		</div>
		<div class="twitter-bottom">
			<span class="twitter-timestamp" *ngIf="!documentDate">{{tweet.date | date:docDateFormat}}</span>
		</div>
		<div *ngIf="tweet.retweets || tweet.favorites"
			class="twitter-metadata w-100-percent mv-16">
			<span class="twitter-retweets">{{tweet.retweets}}</span> {{ 'docExplorer.retweets'|i18n }}
			<span class="twitter-favorites">{{tweet.favorites}}</span> {{ 'docExplorer.favorites'|i18n }}
		</div>
	</div>
	<translated-text *ngIf="translate"
		class="d-flex mt-8"
		[cacheKey]="uniqueId"
		[text]="getVerbatimText()">
	</translated-text>
	<div #selectedNodeStyle></div>
</div>
