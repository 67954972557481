import { Injectable, Inject, EventEmitter } from '@angular/core';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { DashboardTemplate } from './dashboard-templates-management.component';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { DashboardTemplatesFolderApi } from '@cxstudio/dashboard-templates/api/dashboard-templates-folder-api.service';
import { TemplateCommonService } from '@cxstudio/dashboard-templates/template-common-service.service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';

@Injectable()
export class DashboardTemplatesListActions {

	onChange = new EventEmitter<DashboardTemplate[] | IFolderItem[]>();
	onReload = new EventEmitter<void>();

	private folderService;

	constructor(
		private locale: CxLocaleService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('FolderService') private readonly FolderService,
		@Inject('treeService') private readonly treeService: TreeService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('dashboardTemplatesFolderApi') private readonly dashboardTemplatesFolderApi: DashboardTemplatesFolderApi,
		private readonly dashboardTemplateService: DashboardTemplateService,
		@Inject('templateCommonService') private readonly templateCommonService: TemplateCommonService,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils,
	) {
		this.folderService = new this.FolderService(FolderTypes.DASHBOARD_TEMPLATES);
	}

	createFromTemplate(template: DashboardTemplate): void {
		this.templateCommonService.createDashboard(template);
	}

	editProperties = (template: DashboardTemplate) => {
		this.dashboardTemplateService.editDashboardTemplate(template).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	editMappings = (template: DashboardTemplate) => {
		this.dashboardTemplateService.editTemplateMappings(template).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	shareTemplate = (template: DashboardTemplate) => {
		this.dashboardTemplatesApiService.shareTemplate(template.id).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	unshareTemplate = (template: DashboardTemplate) => {
		this.dashboardTemplatesApiService.unshareTemplate(template.id).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	confirmDeleteTemplate = (template: DashboardTemplate) => {
		let deleteTemplatePromise = this.cbDialogService.danger(
			this.locale.getString('templates.deleteTemplateHeader', {templateName: template.name}),
			this.locale.getString('templates.deleteTemplateConfirmationMsg', {templateName: template.name})
		);
		deleteTemplatePromise.result.then(() => this.deleteTemplate(template), _.noop);
	}

	deleteTemplate = (template: DashboardTemplate) => {
		this.dashboardTemplatesApiService.deleteTemplate(template.id).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	exportTemplate = (template: DashboardTemplate) => {
		this.dashboardTemplatesApiService.exportTemplate(template.id as number).then((response) => {
			let fileName = response.headers('file-name');
			this.exportUtils.exportText(fileName, response.data);
		}, _.noop);
	}

	upgradeTemplate = (template: DashboardTemplate) => {
		this.dashboardTemplatesApiService.upgradeTemplate(template.id).then(() => {
			this.onReload.emit();
		}, _.noop);
	}

	createFolder = (templates: DashboardTemplate[]): void => {
		this.folderService.createFolder(undefined, templates).then((createdFolder: IFolderItem) => {
			this.globalNotificationService.addCreatedNotification(createdFolder.name);
			this.onChange.emit();
		}, _.noop);
	}

	createSubFolder = (parentFolder: IFolderItem, templates: DashboardTemplate[]): void => {
		this.folderService.createFolder(parentFolder, templates).then((createdFolder: IFolderItem) => {
			this.globalNotificationService.addCreatedNotification(createdFolder.name);
			this.onChange.emit();
		}, _.noop);
	}

	renameFolder = (folder: IFolderItem, templates: DashboardTemplate[]): void => {
		this.folderService.renameFolder(folder, templates).then(() => {
			this.onChange.emit([ folder ]);
		}, _.noop);
	}

	removeFolder = (folder: IFolderItem, templates: DashboardTemplate[]): void => {
		this.folderService.removeFolder(folder, templates).then(() => {
			this.globalNotificationService.deleteFolderNotification(folder.name);
			this.onChange.emit([ folder ]);
		}, _.noop);
	}

	moveToFolder = (item: DashboardTemplate | IFolderItem, folderTo: IFolderItem, templates: DashboardTemplate[]): void => {
		if (!folderTo) {
			return;
		}

		this.dashboardTemplatesFolderApi.moveToFolder(item, folderTo.id as number).then(() => {
			if (!folderTo.id || folderTo.id === 0) {
				folderTo = null;
			}

			this.treeService.moveItem(templates, item, folderTo);

			this.globalNotificationService.addItemMovedNotification(item.name, folderTo);
			this.onChange.emit();
		}, _.noop);
	}
}
