import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { AttributeUrlType } from '../constants/attribute-url-type.constant';

export class CBAnalyticTableViewCtrl implements ng.IController {
	previewActual: boolean;

	constructor(
		private readonly $scope
	) {}

	$onInit(): void {
		this.previewActual = false;
		this.$scope.visualProps.visualization = WidgetVisualization.CB_AN_TABLE;
		this.$scope.staticData.data = {};
		this.$scope.staticData.totalCount = 157;
		this.$scope.updateChartSettings();
		this.previewActual = true;

		this.$scope.$watch('currentSettings', (newValue, oldValue) => {
			if (newValue === oldValue) return;

			if (newValue === 'visual' && !this.previewActual) {
				this.$scope.updateChartSettings();
				this.previewActual = true;
			}
		});

		this.$scope.$on('clearSettings', () => {
			this.$scope.props.selectedAttributes = [];
			this.$scope.props.selectedMetrics = [];
			delete this.$scope.props.primaryTimeGrouping;
		});

		this.$scope.$watchCollection('props.selectedAttributes', (newValue, oldValue) => {
			if (newValue === oldValue) return;
			this.previewActual = false;
		});

		this.$scope.$watchCollection('props.selectedMetrics', (newValue, oldValue) => {
			if (newValue === oldValue) return;
			this.previewActual = false;
		});

		this.$scope.$on('redrawDemoTable', () => {
			this.previewActual = false;
		});

		this.$scope.selectLayout = this.selectLayout;
		this.$scope.showTotalCount = this.showTotalCount;
		this.$scope.isLayoutDisabled = this.isLayoutDisabled;
	}

	selectLayout = (layout): void => {
		this.$scope.visualProps.layout = layout.name;
		this.$scope.staticData.ready = false;
		this.$scope.updateChartSettings();
	}

	showTotalCount = (): boolean => {
		return !isEmpty(this.$scope.staticData.totalCount)
			&& this.$scope.props?.selectedAttributes?.length
			&& this.$scope.visualProps.showSampleSize;
	}

	isLayoutDisabled = (): boolean => {
		return _.findWhere(this.$scope.props.selectedAttributes, {urlType: AttributeUrlType.IMAGE});
	}
}

app.controller('CBAnalyticTableViewCtrl', CBAnalyticTableViewCtrl);
