import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from './date-period';

export type CustomDateFilter = string; // `customFilter:${number}`; - works since TS 4.1

export class DateFilter {
	dateFilterMode: DateFilterMode | CustomDateFilter;
	from?: string;
	to?: string;
	query?: string; // likely redundant
	dateDisplayName?: string;
	dateFilterRange?: IDateRange; // used only on UI
}
