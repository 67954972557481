import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { HierarchyMetricExpressionItem } from '@cxstudio/metrics/custom-math/hierarchy-metric-expression-item.class';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';


@Injectable({providedIn: 'root'})
export class HierarchyMetricAdapter implements CustomMathExpressionAdapter {

	static HIERARCHY_PARAMETER_DELIMITER: string = ' >> ';

	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let hierarchy: ReportableHierarchy;
		let valueValid = false;
		let metricName: string;
		if (token.value) {
			let names = token.value.split(`${HierarchyMetricAdapter.HIERARCHY_PARAMETER_DELIMITER}`, 2);
			if (names.length === 2) {
				let hierarchyName = names[0];
				hierarchy = this.findHierarchyByName(hierarchyName, assets);
			}
			metricName = names[1];
			valueValid = !!(hierarchy && hierarchy.properties.find(prop => prop.name === metricName));
		}

		let textToken: TextToken = CustomMathAdapterUtils.getItemNameToken(token, TextTokenType.HIERARCHY_METRIC, !valueValid);
		if (!valueValid && textToken) {
			textToken.errors = [CustomMathErrorType.MISSED_REFERENCE];
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: CustomMathAdapterUtils.getSegmentTextTokens('hierarchy', TextTokenType.PREFIX, textToken)
		};

		if (valueValid) {
			let display = metricName;
			let identityName = `hierarchy_calculation_${metricName}`;
			segment.expression = new HierarchyMetricExpressionItem(hierarchy.id, display, identityName, display);
		} else {
			segment.expression = new ExpressionItem(ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC, token.value);
		}
		return segment;
	}

	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let metricExpression = expression as HierarchyMetricExpressionItem;
		let hierarchy = this.findHierarchy(metricExpression.hierarchyId, assets);
		let displayName = metricExpression.displayName;
		if (hierarchy) {
			displayName = `${hierarchy.name}${HierarchyMetricAdapter.HIERARCHY_PARAMETER_DELIMITER}${metricExpression.name}`;
		}
		displayName = CustomMathAdapterUtils.escapeSpecialChars(displayName);
		return `hierarchy[${displayName}]`;
	}

	private findHierarchy = (id: number, assets: CustomMathAssets): ReportableHierarchy => {
		return _.find(assets.hierarchies, (hierarchy) => hierarchy.id === id);
	}

	private findHierarchyByName = (name: string, assets: CustomMathAssets): ReportableHierarchy => {
		return _.find(assets.hierarchies, (hierarchy) => hierarchy.name === name);
	}
}
