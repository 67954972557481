<collapsing-panel (collapsedChange)="onCollapseChange($event)">
	<panel-heading class="align-items-center">
		<div class="d-flex align-items-center" style="height: 0">
			<cb-toggle
				class="ml-4 mr-8"
				(click)="$event.stopPropagation()"
				[(ngModel)]="homePage.widgetsConfig.enabled"
				(ngModelChange)="onChange.emit(false)">
			</cb-toggle>
			<b [i18n]="'homePage.whatToWatchInsights'"></b>
		</div>
	</panel-heading>
	<panel-body>
		<div class="d-flex flex-direction-column align-items-start mb-16">
			<label class="font-bold">
				{{'homePage.layoutOptions'|i18n}}
			</label>
			<label class="d-block" [i18n]="'homePage.layoutOptionsDescription'"></label>
			<simple-dropdown
				[options]="layoutOptions"
				[(value)]="homePage.widgetsConfig.layout"
				disableSort="true"
				(onChange)="onLayoutChange()">
			</simple-dropdown>
		</div>
		<div class="widgets-layout d-flex p-relative"
			[ngBusy]="loading"
			*ngIf="loaded"
			cdkDropList
			cdkDropListOrientation="horizontal"
			(cdkDropListDropped)="onDrop($event)">
			<div *ngFor="let widget of homePage.widgetsConfig.widgets | slice:0:homePage.widgetsConfig.layout; index as $index"
				class="drag-container p-relative mh-8"
				cdkDrag
				cdkDragLockAxis="x">
				<widget-placeholder *ngIf="!widget"
					class="h-100-percent w-100-percent"
					(onSelect)="createWidget($index, $event)">
				</widget-placeholder>
				<widget-container *ngIf="!!widget"
					id="widget-{{widget.id}}"
					[widget]="widget"
					[widgetMode]="widgetMode"
					[editMode]=true
					[dashboardData]="dashboardData"
					[layout]="{}"
					[widgetActions]="getWidgetActions(widget)"
					class="br-widget w-100-percent h-100-percent home-page-widget">
				</widget-container>
			</div>
		</div>
	</panel-body>
</collapsing-panel>
