import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { AnyDateFilterMode, DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { DatePickerMode } from '@cxstudio/reports/settings/date-point-constants';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { FilterParsingService } from '@cxstudio/services/filter-parsing-service';
import PopoverUtils from '@cxstudio/services/popover-utils.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DateUtilsService } from '@app/modules/utils/dates/date-utils.service';


interface IDateFilterType {
	value: AnyDateFilterMode;
	displayName: string;
	css?: string;
	tooltipText?: string;
}

export interface DateFiltersOptions {
	hideMissDate?: boolean;
	dateFilters?: any[];
}

export interface DateFiltersSelectorProperties {
	filter?: any;
}

interface TimeObject extends moment.MomentObjectOutput {
	amPm: string;
}

export interface CustomDateRange {
	fromDate: string;
	toDate: string;
	fromTime: TimeObject;
	toTime: TimeObject;
}

export class DateFiltersController implements ng.IController {

	onFilterChange: (params: {dateFilterMode, dateFilterRange, dateDisplayName}) => void;
	historicOptions: boolean;
	options: DateFiltersOptions;
	timezone: string;
	selectorProperties: DateFiltersSelectorProperties;
	autoClose: boolean;
	fitParentWidth: boolean;
	dateFilterModeArgument: AnyDateFilterMode;
	dateFilterRangeArgument: {from: string, to: string};
	dateFilterName: string;
	currentPeriodForHistoric: DateFilterMode;
	onNodeSelection: () => void;
	ngDisabled: () => boolean;
	fixedPosition: string;
	appendToBody: boolean;
	dateOptionsFilter: (item) => boolean;

	dateFilterMode: AnyDateFilterMode;
	dateFilterRange: IDateRange;
	datepickerMode: {
		toDate: DatePickerMode;
		fromDate: DatePickerMode
	};
	dateFilterTypes: IDateFilterType[];
	availableDateFilterTypes: IDateFilterType[];
	customOption: IDateFilterType;

	showCalendar: boolean;

	startOfToday: moment.Moment;
	amPm: string[];
	customDateSpecified: boolean;
	customDateRange: CustomDateRange;
	datepickerOptions: {
		from: any;
		to: any;
	};
	readonly DATE_PICKER_FOCUSABLE_SELECTOR = '#dateCustomPicker :focusable';

	uniqueId: string;

	constructor(
		private $scope: ISimpleScope,
		private $timeout: ng.ITimeoutService,
		private dateFilterService: DateFilterService,
		private filterManagementApiService: FilterManagementApiService,
		private DateRange,
		private filterParsingService: FilterParsingService,
		private popoverUtils: PopoverUtils,
		private locale: ILocale
	) {}

	$onInit(): void {
		this.uniqueId = Math.random().toString(36).substring(2, 9);
		//filter mode selection
		this.dateFilterMode = this.dateFilterModeArgument;
		this.datepickerMode = {toDate: DatePickerMode.DAY, fromDate: DatePickerMode.DAY};

		this.initDateFilter();

		//TODO: change to use $onChanges/$doCheck after upgrade angular to 1.5.8
		this.$scope.$watch(() => this.options?.hideMissDate, this.periodOptionsChanged);
		this.$scope.$watch(() => this.selectorProperties?.filter, this.periodOptionsChanged);
		this.$scope.$watch(() => this.currentPeriodForHistoric, this.periodOptionsChanged);
		this.$scope.$watch(() => this.timezone, (newObj, oldObj) => {
			if (newObj !== oldObj)
				this.changeCustomDisplayName(this.dateFilterRange);
			this.periodOptionsChanged(newObj, oldObj);
		});
		this.$scope.$watch(() => this.options?.dateFilters, this.periodOptionsChanged);
		this.$scope.$on('date-filters:refresh', (event, dateFilter) => {
			if (this.historicOptions)
				return;
			if (dateFilter.from && dateFilter.to) {
				this.dateFilterRangeArgument = {
					from: dateFilter.from,
					to: dateFilter.to
				};
			}
			this.dateFilterModeArgument = dateFilter.dateFilterMode;
			this.initializeCustomDate();
			this.dateFilterModeSelection(this.DateRange.valueOf(this.dateFilterModeArgument));
		});

		this.$scope.$on('date-filters:reload', this.initDateFilter);
	}

	private initDateFilter = () => {
		this.dateFilterMode = this.dateFilterModeArgument;

		this.dateFilterTypes = [];
		this.availableDateFilterTypes = [];

		if (this.historicOptions) {
			this.dateFilterTypes.pushAll(this.dateFilterService.getDateFilterHistoricOptions());
		}
		this.dateFilterTypes.pushAll(this.dateFilterService.getDateFilterOptions() as IDateFilterType[]);

		if (this.dateOptionsFilter) {
			this.dateFilterTypes = this.dateFilterTypes.filter(this.dateOptionsFilter);
		}

		this.updateAvailableDateFilterTypes().then(() => {
			this.customOption = _.findWhere(this.dateFilterTypes, {value: DateFilterMode.CUSTOM});
			//custom range
			this.showCalendar = false;
			this.initializeCustomDate();

			if (this.autoClose) {
				this.$scope.$watch(this.getDateWatcher('fromDate', 'fromTime'), newVal => {
					if (!newVal)
						return;
					this.updateDate('from', 'fromDate', 'fromTime');
					this.filterChangedCallback();
				});
				this.$scope.$watch(this.getDateWatcher('toDate', 'toTime'), newVal => {
					if (!newVal)
						return;
					this.updateDate('to', 'toDate', 'toTime');
					this.filterChangedCallback();
				});
			}

			this.postInit();
		});
	}

	private periodOptionsChanged = (newObj, oldObj) => {
		if (newObj === oldObj)
			return;
		this.updateAvailableDateFilterTypes();
	}

	disableToTodayButton = () => {
		if (!this.customDateRange)
			return;
		let startOfFromDate = moment(this.customDateRange.fromDate).startOf('day');
		return startOfFromDate.isAfter(this.startOfToday);
	}

	customDateRangeChanged = () => {
		if (!this.customDateRange)
			return;
		this.updateDateLimits();
		this.filterParsingService.validateAndModifyDateRange(this.customDateRange, 'fromTime', 'toTime');
	}

	private postInit = () => {

	}

	private updateAvailableDateFilterTypes = () => {
		return this.filterManagementApiService.getPresetDateFiltersStates().then(presetDateFiltersStates => {
			if (this.dateOptionsFilter) {
				this.dateFilterTypes = this.dateFilterTypes.filter(this.dateOptionsFilter);
			}
			let result = this.dateFilterTypes;
			this.populateDateFiltersStates(result, presetDateFiltersStates);

			if (this.options?.dateFilters) {
				let sortedFilters = _.sortBy(this.options.dateFilters, filter => {
					return filter.displayName.toLowerCase();
				});
				result = result.concat(sortedFilters);
			}

			if (this.options?.hideMissDate) {
				result = result.filter(this.notMissDate);
				if (this.dateFilterMode === this.DateRange.options.MISS_DATE.value) {
					this.dateFilterMode = this.DateRange.defaultDateRange.value;
					this.filterChangedCallback();
				}
			}

			result = this.filterAvailableOptions(result, this.selectorProperties && this.selectorProperties.filter);

			if (this.historicOptions && this.currentPeriodForHistoric) {
				let selected = this.DateRange.valueOf(this.currentPeriodForHistoric);
				if (!selected) {
					return;
				}
				result = this.filterAvailableOptions(result, filter => {
					return !selected.disabledHistoricPeriods || !_.contains(selected.disabledHistoricPeriods, filter.value);
				});
				if (DateUtilsService.isToDateOption(selected.value)) {
					//remove previous period
					result = _.reject(result, dateFilter => {
						return dateFilter.value === this.DateRange.historicOptions.PREVIOUS_PERIOD.value;
					});

					if (this.dateFilterMode === this.DateRange.historicOptions.PREVIOUS_PERIOD.value) {
						let newValue = DateUtilsService.convertToDateToSameLast(selected.value);
						this.dateFilterModeSelection(this.DateRange.historicOptions[newValue]);
					}
				}
			}

			// display selected item, even if it's not in the list
			if (this.dateFilterName && !_.findWhere(result, {value: this.dateFilterMode})) {
				result.push({
					value: this.dateFilterMode,
					displayName: this.dateFilterName,
					css: 'not-shared'
				});
			}

			this.availableDateFilterTypes = result;
		});
	}

	private populateDateFiltersStates = (dateFilters, dateFiltersStates) => {
		dateFilters.forEach(dateFilter => {
			dateFilter.enabled = dateFiltersStates[dateFilter.id];
		});
	}

	private filterAvailableOptions = (result, filter) => {

		if (filter) {
			result = result.filter(filter);
		} else {
			result = result.filter(this.noRunning3m);
		}

		let filteredValues = result.map(option => {
			return option.value;
		});
		if (filteredValues.indexOf(this.dateFilterMode) === -1 && !this.DateRange.isCustomDateRange(this.dateFilterMode)) {
			this.dateFilterMode = filteredValues[0];
			this.filterChangedCallback();
		}
		return result;
	}

	private notMissDate = (type) => {
		return type.value !== this.DateRange.options.MISS_DATE.value;
	}

	private noRunning3m = (type) => {
		return type.value !== this.DateRange.historicOptions.RUNNING_3_MONTHS.value;
	}

	dateFilterModeSelection = (dateFilterModeObject) => {
		this.dateFilterMode = dateFilterModeObject.value;
		if (dateFilterModeObject.value === this.DateRange.options.CUSTOM.value && !this.isCustomDateSpecified()) {
			this.showCalendarPopup();
		}
		let customName = this.DateRange.isCustomDateRange(this.dateFilterMode) ? dateFilterModeObject.displayName : undefined;
		this.filterChangedCallback(customName);
	}

	private filterChangedCallback = (dateDisplayName?: string) => {
		if (this.onFilterChange) {
			this.onFilterChange({
				dateFilterMode: this.dateFilterMode,
				dateFilterRange: this.dateFilterRange,
				dateDisplayName
			});
		}
	}

	matchDate = (date) => {
		return this.dateFilterService.matchDate(date, this.availableDateFilterTypes);
	}

	isDateFilterVisible = (item) => {
		let selectedValue = this.dateFilterMode;

		if (item && item.id && this.DateRange.isStored(item.id)) {
			return item.enabled || item.value === selectedValue;
		} else {
			return true;
		}
	}

	private updateDateLimits = () => {
		this.datepickerOptions.to.minDate = new Date(this.customDateRange.fromDate);
		this.datepickerOptions.from.maxDate = new Date(this.customDateRange.toDate);
	}

	private showCalendarPopup = () => {
		this.showCalendar = true;
		if (this.autoClose) {
			this.popoverUtils.initOutsideClickHandler('#dateCustomPicker', this.getClickHandlerName, () => this.saveDate());
		}
	}

	private closeCalendarPopup = () => {
		this.showCalendar = false;
		$(document).off(this.getClickHandlerName());
	}

	private getClickHandlerName = () => {
		return 'click.dateCustomPicker' + this.uniqueId; // using random id to support multiple pickets, see CES-3768
	}

	private initializeCustomDate = () => {
		this.startOfToday = moment().startOf('day');
		this.amPm = ['AM', 'PM'];

		let dateFilterRangeArgument = this.dateFilterRangeArgument;

		const needResetDateRange = this.dateFilterModeArgument !== DateFilterMode.CUSTOM;
		this.customDateSpecified = false;
		if (dateFilterRangeArgument && dateFilterRangeArgument.from && !needResetDateRange) {
			this.customDateSpecified = true;
		}

		this.dateFilterRange = this.initializeDateRangeFilter(dateFilterRangeArgument, needResetDateRange);
		this.customDateRange = this.getDateFilter(this.dateFilterRange);
		this.changeCustomDisplayName(this.dateFilterRange);

		this.datepickerOptions = {
			from: {showWeeks: false},
			to: {showWeeks: false}
		};
		this.updateDateLimits();
	}

	isNeedCalendar = () => {
		return this.isCustomDateSpecified()
			&& this.dateFilterMode === this.DateRange.options.CUSTOM.value;
	}

	private isCustomDateSpecified = () => {
		return this.customDateSpecified;
	}

	setAsToday = (model) => {
		this.customDateRange[model] = new Date();
		this.filterParsingService.validateAndModifyDateRange(this.customDateRange, 'fromTime', 'toTime');
		this.datepickerMode[model] = 'day';
		this.customDateRangeChanged();
	}

	saveDate = (event?: MouseEvent) => {
		if (event)
			event.stopPropagation();
		this.updateDate('from', 'fromDate', 'fromTime');
		this.updateDate('to', 'toDate', 'toTime');

		this.closeCalendarPopup();
		this.customDateSpecified = true;

		this.changeCustomDisplayName(this.dateFilterRange);
		this.filterChangedCallback();
	}

	private updateDate = (dest, sourceDate, sourceTime) => {
		//fromDate get date yyyy mm dd info, and replace with hh mm in fromTime
		let newDate = moment.parseZone(this.customDateRange[sourceDate]).toObject();
		newDate.hours = this.convertHoursBack(
			this.customDateRange[sourceTime].hours,
			this.customDateRange[sourceTime].amPm
		);
		newDate.minutes = this.customDateRange[sourceTime].minutes;
		newDate.seconds = dest === 'to' ? 59 : 0;
		this.dateFilterRange[dest] = moment(newDate).format();
	}

	private getDateWatcher = (dateField, timeField) => {
		return () => {
			let date = this.customDateRange[dateField] && this.customDateRange[dateField].getTime && this.customDateRange[dateField].getTime();
			let timeObj = this.customDateRange[timeField];
			let time = timeObj ? (timeObj.hours + '-' + timeObj.minutes + '-' + timeObj.amPm) : undefined;
			return date && (date + ':' + time);
		};
	}

	private changeCustomDisplayName = (dateFilterRange) => {
		if (this.customOption && dateFilterRange && dateFilterRange.from && dateFilterRange.to && this.customDateSpecified) {
			this.customOption.displayName = this.locale.getString('dateRange.custom') + ': '
				+ this.dateFilterService.getCustomRangeLabel(dateFilterRange, this.timezone);
			this.customOption.tooltipText = this.dateFilterService.formatDateFilter(dateFilterRange, this.timezone);
		}
	}

	private convertHours = (hours) => {
		if (hours >= 13) {
			return hours - 12;
		}
		if (hours < 1) {
			return 12;
		}
		return hours;
	}

	private convertHoursBack = (hours, amPm) => {
		if (amPm === 'AM') {
			return hours === 12 ? 0 : hours;
		}
		return hours === 12 ? 12 : hours + 12;
	}

	private initializeDateRangeFilter = (initialValue, resetDates: boolean) => {
		let dateFilterRange = initialValue || {};
		if (!initialValue || !initialValue.from || !initialValue.to || resetDates) {
			let startOfToday = moment().startOf('day').format();
			let endOfToday = moment().endOf('day').format();
			dateFilterRange.from = startOfToday;
			dateFilterRange.to = endOfToday;
		}
		return dateFilterRange;
	}

	private getDateFilter = (dateFilterRange: IDateRange): CustomDateRange => {
		//client time zone.
		let customDateRange = {
			fromDate: dateFilterRange.from, //used for date info: yyyy mm dd
			toDate: dateFilterRange.to,
			fromTime: moment.parseZone(dateFilterRange.from).toObject(), //used for hours and min: hh mm.
			toTime: moment.parseZone(dateFilterRange.to).toObject()
		} as CustomDateRange;

		//handle 24 -> 12 formats
		customDateRange.fromTime.amPm =
			customDateRange.fromTime.hours > 11 ? 'PM' : 'AM';
		customDateRange.toTime.amPm =
			customDateRange.toTime.hours > 11 ? 'PM' : 'AM';
		customDateRange.fromTime.hours =
			this.convertHours(customDateRange.fromTime.hours);
		customDateRange.toTime.hours =
			this.convertHours(customDateRange.toTime.hours);

		return customDateRange;
	}

	onCalendarPopupKeydown = (event: any): void => {
		if (!this.ngDisabled() && KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.showCalendarPopup();
			this.$timeout(() => {
				$(this.DATE_PICKER_FOCUSABLE_SELECTOR).first().trigger('focus');
			});
		}
	}

	onCalendarContentKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeCalendarPopup();
				$('#calendar-popup-icon').trigger('focus');
			});
		} else if (KeyboardUtils.isEventKey(event, Key.ENTER) && !this.showCalendar && this.isOkButton(event.target)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				$('#calendar-popup-icon').trigger('focus');
			});
		}
	}

	refocusOnModeChange = (event: KeyboardEvent, className: string): void => {
		// date picker loses focus on mode switch
		if (KeyboardUtils.isEventKey(event, Key.UP, KeyModifier.CTRL)
			|| KeyboardUtils.isEventKey(event, Key.DOWN, KeyModifier.CTRL)
			|| KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.$timeout(() => $(`#dateCustomPicker .${className} [uib-datepicker] :focusable`).first().trigger('focus'));
		}
	}

	onCalendarKeydown = (event: KeyboardEvent): void => {
		// prevent tabbing out
		if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)
				&& document.activeElement === $(this.DATE_PICKER_FOCUSABLE_SELECTOR).first()[0]) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (KeyboardUtils.isEventKey(event, Key.TAB)
				&& document.activeElement === $(this.DATE_PICKER_FOCUSABLE_SELECTOR).last()[0]) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	private isOkButton = (target: any): boolean => {
		return target.type === 'button' && target.classList.contains('btn-ok');
	}

	getSearchListClasses(): string[] {
		let searchListClasses: string[] = [];
		if (this.showCalendar) {
			searchListClasses.push('calendar-open');
		}
		if (this.isNeedCalendar()) {
			searchListClasses.push('with-calendar');
		}
		if (this.appendToBody) {
			searchListClasses.push('overflow-hidden');
		}
		if (this.fitParentWidth) {
			let widthClass = this.isNeedCalendar()
				? 'w-fit-parent'
				: 'w-100-percent';
			searchListClasses.push(widthClass);
		}

		return searchListClasses;
	}
}

app.component('dateFilters', {
	controller: DateFiltersController,
	templateUrl: 'partials/filters/date-filters-component.html',
	bindings: {
		onFilterChange: '&',
		historicOptions: '<',
		options: '<?',
		timezone: '<',
		selectorProperties: '<?',
		autoClose: '<',
		fitParentWidth: '<',
		dateFilterModeArgument: '< dateFilterMode',
		dateFilterRangeArgument: '< dateFilterRange',
		dateFilterName: '<',
		currentPeriodForHistoric: '<',
		onNodeSelection: '&',
		ngDisabled: '&',
		fixedPosition: '<?',
		appendToBody: '<?',
		dateOptionsFilter: '<?'
	},
});
