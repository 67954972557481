import { Component, OnInit, Input, EventEmitter, Output, ViewChild,
	OnChanges, SimpleChanges, OnDestroy, ElementRef, forwardRef, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as _ from 'underscore';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { RandomUtils } from '@app/util/random-utils.class';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IPagingSearch } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';

export interface AttributeValueOption {
	name?: string;
	displayName: string;
	attributeName?: string;
	attributeDisplayName?: string;
	object?: any;
	css?: string;
	isAllValues?: boolean;
	existMatch?: boolean;
	special?: boolean;
	matchMode?: FilterMatchModeValue;
	selected?: boolean;
	newValue?: boolean;
	children?: AttributeValueOption[];
}

export interface IMultiselectConfiguration {
	showSelectAll: boolean;
	showClearAll: boolean;
	showSearch: boolean;
	showAttributeName: boolean;
	externalSearch: string;
	useExternalSearch: boolean;
	hideCheckbox: boolean;
	hideSelections: boolean;
	highlightContains: boolean;
}

export interface SearchEvent {
	$search: string;
}

export interface NodeEvent {
	node: any;
}

export interface ChangeAllEvent {
	select: boolean;
}

/*
	Array of filtered items should be passed to as "ngModel".
	Each "ngModel" item should contain "name" field for item comparison on item selection
	(can be primitive or object).
*/
@Component({
	selector: 'multiselect',
	templateUrl: './multiselect.component.html',
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiselectComponent), multi: true}
	]
})
export class MultiselectComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
	@Input() config: IMultiselectConfiguration;
	@Input() limit: number;
	@Input() selectedAttribute: any;
	@Input() nestedList: any[];

	@Input() disableSelection: (item, modelValue) => boolean;
	@Input() equalFunction: (a, b) => boolean;

	@Output() onChange = new EventEmitter<void>();
	@Output() updateSearch = new EventEmitter<SearchEvent>();
	@Output() onNodeClick = new EventEmitter<NodeEvent>();
	@Output() onChangeAllClick = new EventEmitter<ChangeAllEvent>();

	@Input() isDisabled: () => boolean;
	@Input() useChips?: boolean;
	@Input() color?: string;
	@Input() externalSearchValue?: string;
	@Input() externalOpenValue?: boolean;
	@Input() selectedOption: DashboardFilterSelection;

	filterText: string;
	uiOptions: IMultiselectConfiguration;
	styleId: string;
	@Input() searchLabel: string;

	@Input() pagingSearch: IPagingSearch;
	@Input() dashboardFilter: boolean;

	@Input() autoFocus: boolean;
	@Input() dropdownContainer: string;
	@Input() dropdownPlacement: string;

	@Input() optimized?: boolean;

	private element: JQuery;
	@ViewChild(NgbDropdown, {static: false}) ngDropdown: NgbDropdown;
	@ViewChild('searchInput', {static: false}) private searchInput: ElementRef;

	setButtonFocus = new Subject<void>();

	private value;
	private stateChangedSubscription: Subscription;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	constructor(
		private elementRef: ElementRef,
		private ref: ChangeDetectorRef,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
	) { }

	ngOnInit(): void {
		this.element = $(this.elementRef.nativeElement);
		this.filterText = '';
		this.nestedList = this.nestedList || [];
		let defaultConfiguration: Partial<IMultiselectConfiguration> = {
			showSelectAll: true,
			showClearAll: true,
			showSearch: true,
			hideCheckbox: false,
			hideSelections: false
		};
		this.limit = this.limit || 100;
		this.dropdownPlacement = this.dropdownPlacement || 'bottom-left bottom-right top-left top-right';

		this.config = this.config || {} as any;
		this.uiOptions = $.extend(defaultConfiguration, this.config);
		this.styleId = `s-${RandomUtils.randomString()}`;
		this.addStyles();

		if (this.hasPagingSearch()) {
			this.stateChangedSubscription = this.pagingSearch.stateChanged$.subscribe(() => this.ref.detectChanges());
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.externalSearchValue?.previousValue !== changes.externalSearchValue?.currentValue) {
			if (this.externalSearchValue !== undefined) {
				if (!this.externalSearchValue?.length) {
					if (this.ngDropdown?.isOpen()) this.ngDropdown.close();
				} else {
					this.updateSearch.emit({$search: this.externalSearchValue});
				}
			}
		}
		if (changes.externalOpenValue?.previousValue !== changes.externalOpenValue?.currentValue) {
			if (this.externalOpenValue) {
				this.ngDropdown.open();
			} else {
				this.ngDropdown.close();
			}
		}
	}

	ngOnDestroy(): void {
		this.stateChangedSubscription?.unsubscribe();
	}

	isDropdownOpen(): boolean {
		return this.ngDropdown?.isOpen() || false;
	}

	openChangeHandler = (open: boolean): void => {
		if (open && this.autoFocus) {
			setTimeout(() => { this.searchInput.nativeElement.focus(); }, 0);
		}
		if (!open && this.hasPagingSearch()) {
			this.pagingSearch.resetSearch();
		}
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.value) {
			this.value = value;
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	private addStyles(): void {
		if (this.color) {
			let opacity = 55;
			let background = ColorUtilsHelper.lighten(this.color);
			let fontColor = ColorUtilsHelper.pickContrastTextColor(background);
			let style = `<style type="text/css">
				#${this.styleId} .multiselect-item > a:hover {
					color: ${fontColor} !important;
					background-color: ${this.color}${opacity} !important;
				}
			</style>`;
			this.element.append($(style));
		}
	}

	handleChange = (): void => {
		this.onChangeCallback(this.value);
		this.onTouchedCallback();
		this.onChange.emit();
	}

	handleSearchChange = (filterText: string): void => {
		this.updateSearch.emit({$search: filterText});
	}

	disable = (item): boolean => {
		if (item.existMatch) return false;
		if (this.disableSelection) return this.disableSelection(item, this.value);

		return !item.selected && (this.getSelectedOptionsCount() >= this.limit);
	}

	showChangeAll = (): boolean => {
		return !this.useChips && (this.showSelectAll() || this.showClearAll());
	}

	showClearAll = (): boolean => {
		return !!(this.value && this.uiOptions.showClearAll && this.getSelectedOptionsCount());
	}

	showSelectAll = (): boolean => {
		//Need to fix this to calculate actual nested options length
		return !!(this.value && this.uiOptions.showSelectAll
			&& (this.dashboardFiltersService.getNestedMultiValueFilters(this.value).length < this.limit));
	}

	private changeAllClicked = (select: boolean): void => {
		this.onChangeAllClick.emit({select});
	}

	selectAll = (): void => {
		this.setNestedSelection(true);
		this.handleChange();
		this.changeAllClicked(true);
	}

	deselectAll = (): void => {
		this.setNestedSelection(false);
		this.handleChange();

		this.updateSearch.emit({$search: this.getSearchText()});
		this.changeAllClicked(false);
	}

	setNestedSelection = (value): void => {
		this.nestedList.forEach((opt) => {
			if (opt.children) {
				opt.children.forEach((child) => {
					child.selected = value;
					this.populateModelValueItemSelection(child);
				});
			} else {
				opt.selected = value;
				this.populateModelValueItemSelection(opt);
			}
		});
	}

	clickItem = (item, itemType?): void => {
		if (this.disable(item)) {
			return;
		}

		if (item.children) {
			if (itemType === 'selected') {
				item.expandedSelected = !item.expandedSelected;
			} else {
				item.expandedUnselected = !item.expandedUnselected;
			}
			return;
		}

		if (item.existMatch) {
			let selected = item.selected;
			this.deselectAll();
			item.selected = selected;
		}

		item.selected = !item.selected;
		this.populateModelValueItemSelection(item);
		this.handleChange();

		this.onNodeClick.emit({node: item});
	}

	getSelectedOptionsCount = (): number => {
		return this.value
			? this.dashboardFiltersService.getNestedSelectedChildren(this.value).length
			: 0;
	}


	populateModelValueItemSelection = (item): void => {
		let modelValue = this.value || [];
		this.value = modelValue;

		if (!item.selected) {
			for (let i = 0; i < modelValue.length; i++) {
				let modelItem = modelValue[i];
				if (this.isEqual(item, modelItem)) {
					modelValue.splice(i, 1);
					break;
				}
			}
		} else {
			modelValue.push(item);
		}
	}

	isEqual = (item1, item2): boolean => {
		if (item1.isAllValues || item2.isAllValues) {
			return item1.isAllValues === item2.isAllValues;
		}
		return this.isNamesEqual(item1.name, item2.name);
	}

	isNamesEqual = (item1, item2): boolean => {
		let equalFunc = this.equalFunction || _.isEqual;
		return equalFunc(item1, item2);
	}

	hasPagingSearch = (): boolean => {
		return !!this.pagingSearch;
	}

	getFirstSearchPromise = (): ng.IPromise<any> => {
		if (this.hasPagingSearch() && this.pagingSearch.showFirstSearchSpinner)
			return this.pagingSearch.firstSearchingPromise;
	}

	getElementWidth = (): number => {
		return this.element.width();
	}

	getSearchText = (): string => {
		if (!this.uiOptions.showSearch) {
			return this.externalSearchValue;
		}

		return this.filterText;
	}

	stopEvent = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();
	}

	closePopup = (): void => {
		this.ngDropdown.close();
		setTimeout(() => { this.setButtonFocus.next(); }, 0);
	}

	onSelectAllKeydown = (event: any): void => {
		event.preventDefault();
		event.stopPropagation();
		this.selectAll();
		this.closePopup();
	}

	onDeselectAllKeydown = (event: any): void => {
		event.preventDefault();
		event.stopPropagation();
		this.deselectAll();
		this.closePopup();
	}

	onSearchKeydown = (event: any): void => {
		event.preventDefault();
		event.stopPropagation();
		this.closePopup();
	}

	focusMoveHandler = (): void => {
		if (!this.hasPagingSearch()) {
			this.closePopup();
		}
	}
}

app.directive('multiselect', downgradeComponent({component: MultiselectComponent}) as angular.IDirectiveFactory);
