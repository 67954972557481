<modal-header
	(cancel)="cancel()"
	[modalTitle]="getModalTitle()">
</modal-header>
<div class="modal-body">
	<form [formGroup]="masterAccountForm" class="form-horizontal">
		<!-- fake login/password inputs to prevent chrome autofill -->
		<input class="d-none" type="text" name="fakeusernameremembered"/>
		<input class="d-none" type="password" name="fakepasswordremembered"/>
		<nav ngbNav #editNav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
			<ng-container ngbNavItem>
				<a ngbNavLink class="text-uppercase">{{"administration.properties"|i18n}}
					<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!!propertiesForm?.invalid"></span>
				</a>
				<ng-template ngbNavContent>
					<master-account-admin-properties
						[masterAccountId]="masterAccountId">
					</master-account-admin-properties>
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem domId="contract-info-tab">
				<a ngbNavLink class="text-uppercase br-tab-contract-info">{{"administration.contractInfo"|i18n}}
					<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!!licensingForm?.invalid"></span>
				</a>
				<ng-template ngbNavContent>
					<master-account-licensing [licenses]=licenses></master-account-licensing>
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem domId="beta-features-tab">
				<a ngbNavLink class="text-uppercase br-tab-beta-features">{{"mAccount.betaFeatures"|i18n}}</a>
				<ng-template ngbNavContent>
					<beta-features-checklist formControlName="betaFeatures">
					</beta-features-checklist>
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem domId="integrations-tab">
				<a ngbNavLink class="text-uppercase br-tab-integration">{{"mAccount.integrations"|i18n}}
					<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!!integrationForm?.invalid"></span>
				</a>
				<ng-template ngbNavContent>
					<master-account-integration
						[masterAccountId]="masterAccountId"
						[masterAccountName]="properties?.accountName.value"
						[initialAliasName]="initIdpAliasName"
						[(upload)]="upload">
					</master-account-integration>
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem domId="hierarchies-tab">
				<a ngbNavLink class="text-uppercase br-tab-contract-info">{{"mAccount.organizationHierarchy"|i18n}}
					<span class="text-danger q-icon-warning" aria-hidden="true" *ngIf="!!hierarchySettingsForm?.invalid"></span>
				</a>
				<ng-template ngbNavContent>
					<master-account-hierarchy-settings></master-account-hierarchy-settings>
				</ng-template>
			</ng-container>
		</nav>
		<div [ngbNavOutlet]="editNav" class="mt-2"></div>
		<form-error *ngIf="!!showError && masterAccountForm?.invalid" [text]="getErrorMessage()">
		</form-error>
	</form>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="disableSaveButton()"
></save-modal-footer>
