import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { BetaFeatureTracker } from '@app/modules/context/beta-features/beta-feature-tracker-class';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { SortDirection } from '@cxstudio/common/sort-direction';

@Injectable()
export class BetaFeaturesChecklistGridDefinition implements IGridDefinition<BetaFeatureTracker> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {
		let columns = [{
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 30,
			width: 30,
			searchable: false,
			formatter: this.gridFormatterService.buildToggleSwitchFormatter(),
			cssClass: 'text-center h-64 pv-8',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder h-64 pv-8',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: true,
			minWidth: 100,
			width: 100,
			cssClass: 'cell-name text-wrap h-64 pv-8',
			searchable: true,
			isObjectNameColumn: true,
			defaultSortColumn: SortDirection.ASC
		}, {
			id: 'description',
			name: this.locale.getString('common.description'),
			field: 'description',
			sortable: false,
			minWidth: 300,
			width: 300,
			cssClass: 'cell-description text-wrap h-64 pv-8',
			searchable: false,
		}];

		return Promise.resolve(columns);
	}
}

app.service('betaFeaturesChecklistGridDefinition', downgradeInjectable(BetaFeaturesChecklistGridDefinition));
