import { SortMetric } from '@cxstudio/reports/entities/sort-metric';
export class PageProperties {
	start?: number;
	lookAheadLimit?: number;
	limit?: number;
	sortMetric?: SortMetric;

	constructor(start: number, limit: number) {
		this.start = start;
		this.lookAheadLimit = limit;
	}
}