<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.qualtricsApiEditProviderDialogTitle'|i18n">
</modal-header>

<div class="modal-body edit-api-provider-modal">
	<form class="form-horizontal" #apiProviderForm="ngForm">
		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAliasName'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="aliasName"
					#aliasName="ngModel"
					required
					[(ngModel)]="apiProvider.aliasName">

				<p *ngIf="aliasName.invalid && aliasName.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthClientId'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="clientId"
					#clientId="ngModel"
					required
					[(ngModel)]="apiProvider.clientId">

				<p *ngIf="clientId.invalid && clientId.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthClientSecret'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="clientSecret"
					#clientSecret="ngModel"
					required
					[(ngModel)]="apiProvider.clientSecret">

				<p *ngIf="clientSecret.invalid && clientSecret.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthScope'|i18n}}</label>
			<div class="col-md-8">
				<tags-input class="w-100-percent" name="scope"
					[(ngModel)]="apiProvider.scope"
					[minLength]="1"
					[addOnBlur]="true"
					[addOnEnter]="true">
				</tags-input>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAuthorizationCodeUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="authorizationCodeUri"
					#authorizationCodeUri="ngModel"
					required
					[(ngModel)]="apiProvider.authorizationCodeUri">

				<p *ngIf="authorizationCodeUri.invalid && authorizationCodeUri.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthCreateTokenUri'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control"
					autocomplete="off"
					name="createTokenUri"
					#createTokenUri="ngModel"
					required
					[(ngModel)]="apiProvider.createTokenUri">

				<p *ngIf="createTokenUri.invalid && createTokenUri.errors.required"
					class="help-block text-danger mt-4"
					[i18n]="'administration.requiredField'"></p>
			</div>
		</div>
	</form>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="!isSettingsValid()">
</save-modal-footer>
