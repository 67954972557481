import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';

type INodePredicate<T extends INode> = (node: T) => boolean;
type INodeConsumer<T extends INode> = (node: T) => void;
type INodeFunction<T extends INode> = (node: T) => any;

export class HierarchyUtils {

	static disableOptions<T extends INode>(hierarchy: INode, filter?: INodePredicate<T>): void {
		HierarchyUtils.applyRecursively(hierarchy, node => node._disabled = true, filter);
	}

	static enableOptions<T extends INode>(hierarchy: INode, filter?: INodePredicate<T>): void {
		HierarchyUtils.applyRecursively(hierarchy, node => node._disabled = false, filter);
	}

	static applyRecursively<T extends INode>(item: T, fn: INodeConsumer<T>, filter?: INodePredicate<T>): T {
		if (!item) return item;
		if (!filter)
			filter = () => true;

		if (item.children && item.children.length) {
			for (let i = 0; i < item.children.length; i++) {
				item.children[i] = HierarchyUtils.applyRecursively(item.children[i], fn, filter);
			}
		} else if (filter(item)) {
			fn(item);
		}

		return item;
	}

	static removeIf<T extends INode>(items: INode[], filter: INodePredicate<T>, childrenFieldName = 'children'): INode[] {
		if (!items) return items;

		for (let item of items) {
			if (item[childrenFieldName] && item[childrenFieldName].length) {
				item[childrenFieldName] = HierarchyUtils.removeIf(item[childrenFieldName], filter, childrenFieldName);
			}
		}

		return items.filter(filter);
	}

	static replaceOrAddGroup(items: INode[], name: string, group: any): void {
		let replacedItemIndex = HierarchyUtils.findGroupIndexByName(items, name);

		if (replacedItemIndex > -1) {
			items[replacedItemIndex] = group;
		} else {
			items.push(group);
		}
	}

	static removeGroup(items: INode[], name: string): void {
		let removedItemIndex = HierarchyUtils.findGroupIndexByName(items, name);
		if (removedItemIndex > -1) {
			items.removeAt(removedItemIndex);
		}
	}

	static findGroup(items: INode[], name: string): INode {
		let index = HierarchyUtils.findGroupIndexByName(items, name);
		return items[index];
	}

	private static findGroupIndexByName(items: INode[], name: string): number {
		return _.findIndex(items, item => item.name === name);
	}

	static findItem(items: INode[], matchers: any[]): INode {
		let currentRoot = { children: items } as INode;
		for (let matcher of matchers) {
			currentRoot = _.findWhere(currentRoot.children, matcher);

			if (!currentRoot)
				return null;
		}

		return currentRoot;
	}

	static findItems<T extends INode>(item: T, predicate: INodePredicate<T>, array?: T[]): T[] {
		array = array || [] as T[];

		if (!item.children)
			return array;

		const items = item.children as T[];
		items.forEach(child => {
			if (predicate(child))
				array.push(child);
			HierarchyUtils.findItems(child, predicate, array);
		});

		return array;
	}

	static removeFromHierarchy<T extends INode>(hierarchy: T, item: T): void {
		if (!hierarchy.children)
			return;

		for (let child of hierarchy.children) {
			if (child === item) {
				hierarchy.children.remove(child);
				return;
			} else {
				HierarchyUtils.removeFromHierarchy(child, item);
			}
		}
	}

	/**
	 * @argument mapper: mapper to apply to original item before folder filter evaluation
	 */
	static addToHierarchy<T extends INode>(hierarchy: T, item: T, mapper?: INodeFunction<T>): void {
		if (!mapper)
			mapper = node => node;

		if (!hierarchy.children)
			return;

		let folderFilter = (hierarchy as any).filter;
		if (folderFilter && folderFilter(mapper(item))) {
			hierarchy.children.push(item);
			return;
		}
		hierarchy.children.forEach(child => HierarchyUtils.addToHierarchy(child, item, mapper));
	}
}
