import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { HeatmapSubtype } from '../providers/cb/constants/heatmap-visualizations.service';
import { WidgetIcons } from '@app/modules/dashboard/widget-templates.service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { IVisualization, SubChartTypes } from '@app/modules/widget-settings/report-visualization-selector.component';

export class VisualizationOptionsService {

	dualTypes: {[key in SubChartTypes]?: IVisualization} = {};
	heatmapTypes: {[key in SubChartTypes]?: IVisualization} = {};

	constructor(private locale: ILocale) {
		this.initDualTypes();
		this.initHeatmapTypes();
	}

	private buildVis(type: SubChartTypes, icon: string, title: string, additionalClass?: string): IVisualization {
		return {type, icon, title, additionalClass};
	}

	private initDualTypes = (): void => {
		this.dualTypes[ChartType.BAR] = this.buildVis(ChartType.BAR, `${WidgetIcons.cb_an_bar} icon-rotate-90`,
			this.locale.getString('widget.barChart'));

		this.dualTypes[ChartType.COLUMN] = this.buildVis(ChartType.COLUMN, WidgetIcons.cb_an_bar,
			this.locale.getString('widget.columnChart'));

		this.dualTypes[ChartType.PARETO] =  this.buildVis(ChartType.PARETO, WidgetIcons.pareto,
			this.locale.getString('widget.paretoChart'));

		this.dualTypes[ChartType.SPLINE] =  this.buildVis(ChartType.SPLINE, WidgetIcons.cb_an_line,
			this.locale.getString('widget.lineChart'));

		this.dualTypes[ChartType.BUBBLE] =  this.buildVis(ChartType.BUBBLE, WidgetIcons.cb_an_bubble,
			this.locale.getString('widget.bubbleChart'));

		this.dualTypes[ChartType.POLAR] =  this.buildVis(ChartType.POLAR, WidgetIcons.polar,
			this.locale.getString('widget.polarChart'));

		this.dualTypes[ChartType.RADAR] =  this.buildVis(ChartType.RADAR, WidgetIcons.radar,
			this.locale.getString('widget.radarChart'));
	}

	private initHeatmapTypes = (): void => {
		this.heatmapTypes[HeatmapSubtype.DEFAULT] = this.buildVis(HeatmapSubtype.DEFAULT, WidgetIcons.cb_an_heatmap,
			this.locale.getString('widget.treemapChart'));
		this.heatmapTypes[HeatmapSubtype.ROW] = this.buildVis(HeatmapSubtype.ROW, WidgetIcons.cb_an_heatmap_horizontal,
			this.locale.getString('widget.treemapSingleRow'));
		this.heatmapTypes[HeatmapSubtype.COLUMN] = this.buildVis(HeatmapSubtype.COLUMN, `${WidgetIcons.cb_an_heatmap_horizontal} icon-rotate-90`,
			this.locale.getString('widget.treemapSingleColumn'));
	}

	buildDualVisualizations = (types: ChartType[]): IVisualization[] => {
		return _.map(types, (type: ChartType) => this.dualTypes[type]);
	}

	buildMetricVisualization = (type: WidgetVisualization, icon: WidgetIcons, label: string) => {
		return this.buildVis(type, icon, label);
	}

	buildHeatmapVisualizations = (types: HeatmapSubtype[]) => {
		return _.map(types, (type: HeatmapSubtype) => this.heatmapTypes[type]);
	}
}

app.service('visualizationOptionsService', VisualizationOptionsService);
