import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { PagedResponse } from '@cxstudio/paged-response';

import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { DependenciesColumnsProvider } from '@app/modules/asset-management/services/columns-providers/dependencies-columns-provider.service';
import { AbstractDependencyTypesProvider } from '@app/modules/asset-management/services/abstract-dependency-types-provider';
import { downgradeComponent } from '@angular/upgrade/static';


export interface DependenciesDialogInput<T extends StudioAsset> {
	asset: T;
	dependencyTypesProvider: AbstractDependencyTypesProvider<T>;
}

@Component({
	selector: 'dependencies-modal',
	templateUrl: './dependencies-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DependenciesModalComponent<T extends StudioAsset> implements OnInit {

	@Input() input: DependenciesDialogInput<T>;

	loading: Promise<PagedResponse<AssetDependency>>;
	dependencyTypes: Array<AssetObjectType<T, AssetDependency>>;
	dependencyType: AssetObjectType<T, AssetDependency>;

	pagination: Pagination;
	columns: Array<TableColumn<any>>;
	rows: any[];

	constructor(
		private readonly locale: CxLocaleService,
		private readonly modal: NgbActiveModal,
		private readonly cxDialogService: CxDialogService,
		private readonly dependenciesColumnsProvider: DependenciesColumnsProvider,
	) {}

	ngOnInit(): void {
		this.pagination = new Pagination(20);
		this.initDependencyTypes();
	}

	private initDependencyTypes = (): void => {
		this.dependencyTypes = this.input.dependencyTypesProvider.dependencyTypes;
		this.dependencyType = this.dependencyTypes[0];
		this.reload();
	}

	getTitle = (): string => {
		return this.locale.getString('common.dependenciesTitle', {object: this.input.asset.name});
	}

	dependencyTypeChanged = (): void => {
		this.pagination.currentPage = 1;
		this.reload();
	}

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reload();
	}

	reload = (): void => {
		this.loading = this.dependencyType.handler(
			this.input.asset,
			this.pagination
		);

		this.loading.then((response) => {
			this.rows = response.data;
			this.columns = this.dependenciesColumnsProvider.getColumns(this.dependencyType.type);
			this.pagination.totalItems = response.totalCount;
		});
	}

	export = (): void => {
		this.input.dependencyTypesProvider.exportHandler.export(this.input.asset).then(() => {
			let dialogMessage = this.locale.getString('administration.exportToExcelMessage');

			let dialogTitle = this.locale.getString('common.exportDependenciesTitle',
				{ type: this.input.dependencyTypesProvider.title, object: this.input.asset.name });

			this.cxDialogService.notify(dialogTitle, dialogMessage);
		});
	}

	showSelection = (): boolean => {
		return this.dependencyTypes && this.dependencyTypes.length > 1;
	}

	close = (): void => {
		this.modal.close();
	}
}
