import { DependentMetric } from '@cxstudio/asset-management/dependencies/dependent-metric';
import { PagedResponse } from '@cxstudio/paged-response';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { DependentDashboard } from '@cxstudio/asset-management/dependencies/dependent-dashboard';

import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { AbstractDependenciesApiService } from '@app/modules/asset-management/services/abstract-dependencies-api-service';


@Injectable()
export class HierarchyDependenciesApiService extends AbstractDependenciesApiService<StudioAsset> {

	constructor(
		cxHttp: CxHttpService,
	) {
		super(cxHttp);
	}

	getDashboards = (asset: StudioAsset, pagination: Pagination): Promise<PagedResponse<DependentDashboard>> => {
		return this.getDependencies(asset, 'dashboards', pagination);
	}

	getMetrics = (asset: StudioAsset, pagination: Pagination): Promise<PagedResponse<DependentMetric>> => {
		return this.getDependencies(asset, 'metrics', pagination);
	}

	protected getBaseUrl = (asset: StudioAsset): string => {
		return `rest/hierarchy/${ asset.assetId }/dependencies`;
	}
}
