import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConversationEnrichmentUtils } from '@app/modules/conversation/enrichments/conversation-enrichment-utils.service';
import { SentenceConcatenationUtils } from '@app/modules/document-explorer/feedback-sentence/sentence-concatenation-utils';
import { SentencePreviewActionEvent } from '@app/modules/document-explorer/sentence-preview/sentence-preview.component';
import { Pill } from '@app/modules/pills/pill';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { TopicRemoveEvent } from '@cxstudio/reports/document-explorer/components/primary-pane-component';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { PreviewSentence, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { SentenceEnrichmentTuningEvent, PillTuningEvent } from '@app/modules/pills/pill-tuning-event';

interface SentenceGroup {
	sentences: PreviewSentence[];
	pills: Pill[];
	topics: SentenceTopic[];
	hasProfanity: boolean;
}

@Component({
	selector: 'feedback-sentences',
	templateUrl: './feedback-sentences.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackSentencesComponent implements OnInit, OnChanges {
	@Input() sentences: PreviewSentence[];
	@Input() selectedSentence: number;
	@Input() showEnrichment: boolean;
	@Input() sentimentColorFunc;
	@Input() formatters: IMetricFormatters;

	@Input() auditMode: boolean;
	@Input() auditModeModels: IReportModel[];
	@Input() messageWidth: number;
	@Input() highlightTrigger: number;
	@Input() showTopics: boolean;
	@Input() expandTopics: boolean;
	@Input() leafOnly: boolean;
	@Input() predefinedMetrics: Metric[];
	@Input() deselectedModelsIds: number[];
	@Output() tuneEnrichment = new EventEmitter<SentenceEnrichmentTuningEvent>();
	@Output() sentenceClick = new EventEmitter<SentencePreviewActionEvent>();
	@Output() removeTopic = new EventEmitter<TopicRemoveEvent>();

	sentenceGroups: SentenceGroup[];

	constructor(
		private readonly conversationEnrichmentUtils: ConversationEnrichmentUtils,
		private readonly profanityDisguiseService: ProfanityDisguiseService
	) {}

	ngOnInit(): void {
		this.initGroups();
	}

	ngOnChanges(changes: SimpleChanges<FeedbackSentencesComponent>): void {
		if (ChangeUtils.hasChange(changes.deselectedModelsIds)
			|| ChangeUtils.hasChange(changes.leafOnly)
			|| ChangeUtils.hasChange(changes.showEnrichment)
			|| ChangeUtils.hasChange(changes.showTopics)
			|| ChangeUtils.hasChange(changes.auditMode)
			|| ChangeUtils.hasChange(changes.sentences)) {
			this.initGroups();
		}
	}

	private initGroups(): void {
		let groups = [];
		if (_.isEmpty(this.sentences))
			return;
		let currentGroup: SentenceGroup = null;
		for (let i = this.sentences.length - 1; i >= 0; i--) {
			// going from the end, because sentences are concatenated to the latest, not to the earliest
			let sentence = this.sentences[i];
			let topics = this.showTopics ? this.getTopics(sentence) : [];
			let pills = this.showEnrichment ? this.conversationEnrichmentUtils.getAllPills([sentence], this.predefinedMetrics) : [];
			if (currentGroup) {
				if (!this.auditMode // in audit mode all sentences should be separate
					&& SentenceConcatenationUtils.areTopicsSame(topics, currentGroup.topics)
					&& SentenceConcatenationUtils.canConcatenatePills(pills, currentGroup.pills)) {
					currentGroup.sentences.insert(0, sentence);
					currentGroup.pills = _.uniq(pills.concat(currentGroup.pills), (pill) => `${pill.type}_${pill.name}`);
					currentGroup.hasProfanity = currentGroup.hasProfanity || this.hasProfanity(sentence);
				} else {
					currentGroup = null;
				}
			}
			if (!currentGroup) {
				currentGroup = {
					sentences: [sentence],
					topics, // topics are equal - no need to union them
					hasProfanity: this.hasProfanity(sentence),
					pills,
				};
				groups.push(currentGroup);
			}
		}
		this.sentenceGroups = groups.reverse();
	}

	private hasProfanity(sentence: PreviewSentence): boolean {
		return this.profanityDisguiseService.needToMaskSentence(sentence);
	}

	private getTopics(sentence: PreviewSentence): SentenceTopic[] {
		return PillsUtils.getFilteredSentenceTopics(sentence.sentenceTopics, this.deselectedModelsIds, this.leafOnly);
	}

	onTopicRemove(group: SentenceGroup, topic: string): void {
		// in audit mode each group has only 1 sentence
		this.removeTopic.emit({sentenceId: group.sentences[0].id, topic});
	}

	onTuneEnrichment($event: PillTuningEvent, group: SentenceGroup): void {
		// in audit mode each group has only 1 sentence
		this.tuneEnrichment.emit({ sentence: group.sentences[0], event: $event.event, pill: $event.pill});
	}
}
