import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface DocumentExplorerStatsData {
	sentenceQid: string;
	easeQid: string;
	sentimentQid: string;
	documentQid: string;
}

@Component({
	selector: 'document-explorer-stats-modal',
	template: `
<modal-header
	(cancel)="activeModal.dismiss()"
	modalTitle="{{'docExplorer.documentExplorerQIDs' | i18n }}">
</modal-header>
<div class="modal-body">
	<div class="row mb-8">
		<span class="col-xs-4 col-xs-offset-1 text-right">{{ 'docExplorer.sentimentQid'|i18n }}:</span>
		<span class="col-xs-6">{{input.sentimentQid }}</span>
	</div>
	<div class="row mb-8">
		<span class="col-xs-4 col-xs-offset-1 text-right">{{ 'docExplorer.easeQid'|i18n }}:</span>
		<span class="col-xs-6">{{input.easeQid }}</span>
	</div>
	<div class="row mb-8">
		<span class="col-xs-4 col-xs-offset-1 text-right">{{ 'docExplorer.sentenceQid'|i18n }}:</span>
		<span class="col-xs-6">{{input.sentenceQid }}</span>
	</div>
	<div class="row mb-8">
		<span class="col-xs-4 col-xs-offset-1 text-right">{{ 'docExplorer.documentQid'|i18n }}:</span>
		<span class="col-xs-6">{{input.documentQid}}</span>
	</div>
</div>
<ok-modal-footer
	(cancel)="activeModal.dismiss()">
</ok-modal-footer>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerStatsModalComponent {

	@Input() input: DocumentExplorerStatsData;

	constructor(
		public readonly activeModal: NgbActiveModal,
	) { }
}
