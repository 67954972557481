import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';
import { Component, Input, ViewChild, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { downgradeComponent } from '@angular/upgrade/static';
import { ColorDropdownComponent } from '@app/shared/components/color-picker/color-dropdown/color-dropdown.component';
import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';
import { PlotLineConstants } from '@app/modules/plot-lines/plot-line-dropdown/plot-line-constants';


@Component({
	selector: 'plot-line-dropdown',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './plot-line-dropdown.component.html'
})
export class PlotLineDropdownComponent implements OnInit {
	@Input() plotLine: PlotLineStyle;
	@Input() emptyOption: boolean;
	@Input() isDisabled: boolean;
	@Input() dropdownDirection: string;

	@ViewChild('ngDropdown', {static: false}) private dropdownElement: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;

	@ViewChild(ColorDropdownComponent, {static: false}) private colorDropdown: ColorDropdownComponent;

	lineStyles: PlotLineStyle[];
	lineWidthStyles: PlotLineStyle[];

	DEFAULT_WIDTH: number = 3;

	constructor(
		private readonly renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.lineStyles = PlotLineConstants.getLineStyles();
		this.lineWidthStyles = PlotLineConstants.getLineWidthStyles();

		// autoClose="'outside" closes main dropdown on color-dropodwn click. event.stopPropagation() doesn't help to avoid it
		// we use autoClose="false" and added a custom way to handle outside click below
		this.renderer.listen('window', 'click', (clickEvent: Event) => {
			let dropdownNativeElement: any = this.dropdownElement?.nativeElement;
			let clickTarget: EventTarget = clickEvent.target;
			if (dropdownNativeElement !== clickTarget && !dropdownNativeElement?.contains(clickTarget)) {
				this.closeDropdown();
			}
		});
	}

	closeDropdown = (): void => {
		if (this.colorDropdown?.isDropdownOpened()) {
			this.colorDropdown.closeDropdown();
		}

		this.ngDropdown.close();
	}

	changeLineType = (type: PlotLineType) => {
		this.plotLine.width = this.plotLine.width === 0 ? this.DEFAULT_WIDTH : this.plotLine.width;
		_.extend(this.plotLine, new PlotLineStyle(type, this.plotLine.width, this.plotLine.color));
	}

	changeLineWidth = (width: number) => {
		_.extend(this.plotLine, new PlotLineStyle(this.plotLine.type, width, this.plotLine.color));
	}

	isNoLine(): boolean {
		return this.plotLine.type === PlotLineType.NoLine;
	}

}

app.directive('plotLineDropdown', downgradeComponent({component: PlotLineDropdownComponent}));
