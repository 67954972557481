import { DisplayThreshold } from '@cxstudio/reports/entities/display-threshold';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';

export class ReportGrouping {
	id?: number;
	type: ReportAssetType;
	metricType?: AnalyticMetricType;
	name: string;
	displayName?: string;
	identifier?: string;
	isReportable?: boolean;
	showLevel?: boolean;
	subtopicsOnly?: boolean;
	displayThreshold?: DisplayThreshold;
	sortBy?: string;
	sortOrder?: GroupingSortOrder;
	minDocCount?: number;
	withHidden?: boolean;
	size?: number;
	lockSize?: boolean;
	stackType?: StackType;
	parentId?: number;
}
