import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';


@Injectable()
export class KeyboardNavigationDrillHelper {

	readonly HIGHCHARTS_WIDGETS: string[] = [
		WidgetType.BAR, WidgetType.LINE,
		WidgetType.SCATTER, WidgetType.HEATMAP,
		WidgetType.NETWORK, WidgetType.CLOUD,
		WidgetType.PIE];

	readonly HIGHCHARTS_POINT_CLASS = 'highcharts-point-hover';
	readonly TABLE_CELL_CLASS = 'slick-cell';

	readonly FOCUSABLE_DRILL_MENU_ITEMS_SELECTOR = '#dsh-context-menu :focusable';

	readonly SUBMENU_LEVEL_CLASS_PREFIX = 'submenu-level-';

	readonly KEYDOWN_EVENT_TYPE = 'keydown';

	isKeydownEvent(event: any): boolean {
		return event.type === this.KEYDOWN_EVENT_TYPE || this.isOriginalEventKeydown(event);
	}

	private isOriginalEventKeydown(event: any): boolean {
		if (!event.originalEvent) {
			return event.type === this.KEYDOWN_EVENT_TYPE;
		}

		return this.isOriginalEventKeydown(event.originalEvent);
	}

	focusDrillMenu(): void {
		this.focus(this.FOCUSABLE_DRILL_MENU_ITEMS_SELECTOR, 100);
	}

	focusActiveDataPoint(): void {
		$(`.${this.HIGHCHARTS_POINT_CLASS}`).first().trigger('focus');
	}

	private focus(selector: string, delay: number = 1): void {
		setTimeout(() => {
			$(selector).first().trigger('focus');
		}, delay);
	}

	isHighchartsWidget(widgetName: string): boolean {
		return this.HIGHCHARTS_WIDGETS.contains(widgetName);
	}

	isCloudWord(target: Element): boolean {
		return target.classList.contains('cloud-word');
	}

	isWidgetUsedAsFilter(linkedWidgets: any[]): boolean {
		return !!linkedWidgets && linkedWidgets.length > 0;
	}

	onWidgetKeyboardEvent(event: any, widgetName: string, isWidgetUsedAsFilter: boolean,
			handleClick: (point: any, event: any, isRightClick: boolean) => void): void {
		if (this.isHighchartsPoint(event.target, widgetName) || this.isCloudWord(event.target)) {
			let point = this.isCloudWord(event.target)
				? event.chartPoint
				: event.target.chart.highlightedPoint.object;
			if (point)
				this.onDataPointKeyboard(event, point, isWidgetUsedAsFilter, handleClick);
		}
	}

	private isHighchartsPoint(targetElement: Element, widgetName: string): boolean {
		return this.HIGHCHARTS_WIDGETS.contains(widgetName) && targetElement.classList.contains(this.HIGHCHARTS_POINT_CLASS);
	}

	onDataPointKeyboard(event: any, point: any, isWidgetUsedAsFilter: boolean,
			handleClick: (point: any, event: any, isRightClick: boolean) => void): void {
		if (this.isPrimaryActionKey(event)) {
			KeyboardUtils.intercept(event);
			if (!isWidgetUsedAsFilter) {
				this.specifyMenuPosition(event);
			}
			this.keepCloudFocusState(event);
			handleClick(point, event, false);
		} else if (this.isSecondaryActionKey(event)) {
			KeyboardUtils.intercept(event);
			this.specifyMenuPosition(event);
			this.keepCloudFocusState(event);
			handleClick(point, event, isWidgetUsedAsFilter);
		}
	}

	private keepCloudFocusState(event: Event): void {
		if (this.isCloudWord(event.target as any)) {
			$(event.target).addClass(this.HIGHCHARTS_POINT_CLASS);
		}
	}

	clearCloudFocusState(): void {
		$(`.cloud-word.${this.HIGHCHARTS_POINT_CLASS}`).removeClass(this.HIGHCHARTS_POINT_CLASS);
	}

	isDrillActionKey(event: KeyboardEvent): boolean {
		return this.isPrimaryActionKey(event) || this.isSecondaryActionKey(event);
	}

	private isPrimaryActionKey(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE);
	}

	private isSecondaryActionKey(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.F10, KeyModifier.SHIFT);
	}

	// top left corner of the menu is at the middle of the data point
	private specifyMenuPosition(event: any): void {
		let jqueryTarget = $(event.target);
		let targetOffset = jqueryTarget.offset();
		let middleX = jqueryTarget.width() / 2;
		let middleY = jqueryTarget.height() / 2;
		event.pageX = targetOffset.left + middleX;
		event.pageY = targetOffset.top + middleY;
	}

	isTableCell(targetElement: Element): boolean {
		return targetElement.classList.contains(this.TABLE_CELL_CLASS);
	}

	focusNextItem(target: any, items: any[]): void {
		let currentItemIndex = _.indexOf(items, target);
		if (currentItemIndex === items.length - 1) {
			items[0].focus();
		} else {
			items[currentItemIndex + 1].focus();
		}
	}

	focusPreviousItem(target: any, items: any[]): void {
		let currentItemIndex = _.indexOf(items, target);
		if (currentItemIndex === 0) {
			items[items.length - 1].focus();
		} else {
			items[currentItemIndex - 1].focus();
		}
	}

	getActiveMainMenuItem(): JQuery {
		let activeDrillLink = $('.drill-main-menu-item a.active');
		return activeDrillLink.length ? activeDrillLink : $('.drill-main-menu-item.active a').first();
	}

	getDrillMainMenuItems(): any[] {
		let drillMainMenuItems = $('.drill-main-menu-item > a');
		return _.flatten(drillMainMenuItems);
	}

	findSubmenuLevelClass(classList: string[]): string {
		return _.filter(classList, (clazz) => {
			return clazz.startsWith(this.SUBMENU_LEVEL_CLASS_PREFIX);
		})[0];
	}

	getSubmenuItems(submenuLevelClass: string): any[] {
		let input: any = $(`div.${submenuLevelClass} > input`).toArray();
		let items: any[] = $(`li.${submenuLevelClass} > a`).toArray();
		return input.length ? [].concat(input).concat(items) : items;
	}

	getSubmenuLevelClass(level: number): string {
		return `${this.SUBMENU_LEVEL_CLASS_PREFIX}${level}`;
	}

	getSubmenuLevel(classList: string[]): number {
		let submenuLevelClass = this.findSubmenuLevelClass(classList);
		if (submenuLevelClass) {
			return +submenuLevelClass.slice(this.SUBMENU_LEVEL_CLASS_PREFIX.length);
		}
	}

	focusPreviousSubmenu(target: JQuery, submenuLevelClass: string): void {
		target
			.closest(`.${submenuLevelClass}`)
			.find(':focusable')
			.first()
			.trigger('focus');
	}

}

app.service('keyboardNavigationDrillHelper', downgradeInjectable(KeyboardNavigationDrillHelper));
