import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnifiedTemplateTreeItem } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';

@Component({
	selector: 'template-properties',
	templateUrl: './template-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePropertiesComponent {

	@Input() template: UnifiedTemplateTreeItem;
	@Input() folders: IFolderItem[];

	isValid(): boolean {
		return !!this.template.name?.trim();
	}

}
