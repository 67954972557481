<!-- Put default template as raw html, as "ng-include" impacts performance -->
<span *ngIf="!showCheckboxes" class="hierarchy-item-template">
	<div *ngIf="itemTemplate">
		<ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
	</div>
	<span *ngIf="!itemTemplate" class="hierarchy-item-text">
		<span class="br-hierarchy-node-name" [ngClass]="{'text-ellipsis':limitedWidth}">{{item[displayProperty]}}</span>
	</span>
</span>
<span *ngIf="showCheckboxes"
	(click)="triggerClick($event)"
	class="hierarchy-item-template">
	<div *ngIf="itemTemplate">
		<ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
	</div>
	<span *ngIf="!itemTemplate" class="hierarchy-item-text">
		<span class="br-hierarchy-node-name" [ngClass]="{'text-ellipsis':limitedWidth}">{{item[displayProperty]}}</span>
	</span>
</span>
