<div class="d-flex flex-direction-column p-16 colorpicker__main" [ngStyle]="verticalLimit">

	<div class="colorpicker__colordiv--height">
		<strong class="mb-8 d-inline-block">{{'colors.colors' | i18n}}</strong>
		<cb-color-sketch [(valueColor)]="valueColor">
		</cb-color-sketch>
	</div>

	<div class="d-flex flex-direction-column flex-fill overflow-hidden colorpicker__arraydiv--height">
		<div *ngIf="recentColors.length" class="recent-colors mb-24">
			<strong class="d-flex mb-8">{{'colors.recent' | i18n}}</strong>
			<color-array
				[selectedColor]="valueColor.toHexString()"
				[colors]="recentColors"
				(colorSelect)="onColorSelected($event)">
			</color-array>
		</div>
		<div class="d-flex flex-direction-column mb-16 flex-fill overflow-hidden">
			<ul #nav="ngbNav" ngbNav class="nav-tabs mb-16">
				<li ngbNavItem>
					<a ngbNavLink href="javascript:void(0);">{{'colors.standardPalettes'|i18n}}</a>
					<ng-template ngbNavContent>
						<color-picker-colors
							[selectedColor]="valueColor.toHexString()"
							(colorSelect)="onColorSelected($event)">
						</color-picker-colors>
					</ng-template>
				</li>
				<li ngbNavItem *ngIf="!!palettes">
					<a ngbNavLink href="javascript:void(0);">{{'colors.brandPalettes'|i18n}}</a>
					<ng-template ngbNavContent>
						<color-picker-palettes
							class="d-flex flex-fill overflow-hidden"
							[palettes]="palettes"
							[selectedColor]="valueColor.toHexString()"
							(colorSelect)="onColorSelected($event)">
						</color-picker-palettes>
					</ng-template>
				</li>
			</ul>
			<div class="overflow-y-auto mb-16" [ngbNavOutlet]="nav"></div>

		</div>
	</div>

	<div class="d-block colorpicker__buttons--height">
		<div *ngIf="showClear" class="d-flex justify-end mb-8">
			<button class="btn-link p-0 no-border"
				(click)="clear.emit()">
				{{'common.remove' | i18n}}
			</button>
		</div>
		<div class="d-flex flex-direction-row align justify-end">
			<button class="btn btn-primary"
				(click)="onChange()">
				{{'common.apply'|i18n}}
			</button>
			<button class="btn btn-secondary"
				(click)="cancel.emit()">
				{{'common.cancel'|i18n}}
			</button>
		</div>
	</div>

</div>
