import { AttributeType } from '@app/modules/project/attribute/attribute-type';

export interface FavoriteId {
	type: FavoriteType;
	name?: string;
	modelId?: number;
	scorecardId?: number;
}
export class FavoriteAttribute {
	modelName?: string;
	displayName?: string;
	type: AttributeType;
	favoriteType: FavoriteType;
	name?: string;
	modelId?: number;
	scorecardId?: number;

	constructor(name: string | number, type: FavoriteType) {
		this.favoriteType = type;
		if (type === FavoriteType.MODEL) {
			this.modelId = parseInt(name as string, 10);
		} else if (type === FavoriteType.SCORECARDS) {
			this.scorecardId = name as number;
		} else {
			this.name = name as string;
		}
	}
}

export enum FavoriteType {
	MODEL = 'model',
	ATTRIBUTE = 'attribute',
	WORLD_AWARENESS = 'worldAwareness',
	SYSTEM = 'system',
	SCORECARDS = 'scorecards',
	ENGAGE = 'engage',
	NARRATIVE = 'narrative',
	AUTOMATED_SUMMARIES = 'automatedCallSummary',
	COMPLIANCE_MANAGEMENT = 'complianceManagement'
}
