import * as moment from 'moment';
import * as _ from 'underscore';
import { ConversationEnrichmentUtils } from '@app/modules/conversation/enrichments/conversation-enrichment-utils.service';
import { AudioDocumentFormat } from '@app/modules/conversation/voice-message/audio-document-format.class';
import { ConversationParticipantService } from '@app/modules/document-explorer/conversation-participant.service';
import { ConversationParticipants, IParticipantOption } from '@app/modules/document-explorer/conversation-participants.class';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { IReportModel } from '@app/modules/project/model/report-model';
import { DevToolsService } from '@app/shared/services/dev-tools.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { RandomUtils } from '@app/util/random-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { ConversationType, SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { ConversationChartUtils } from '@cxstudio/conversation/conversation-chart-utils.class';
import { ConversationChart } from '@cxstudio/conversation/conversation-chart.class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { ConversationTooltipService } from '@app/modules/conversation/tooltip/conversation-tooltip.service';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ParticipantEnrichmentTypes, SpineLane, SpineLaneStyle, SpineLaneType, TopicSpineLaneDefinition } from '@cxstudio/conversation/entities/spine-lane.class';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';
import { ReasonTrack } from '@cxstudio/conversation/secondary-tracks/reason-track.class';
import { ScorecardTrack } from '@cxstudio/conversation/secondary-tracks/scorecard-track.class';
import { TopicTrack } from '@cxstudio/conversation/secondary-tracks/topic-track.class';
import { AccountId, ContentProviderId, ProjectId } from '@cxstudio/generic-types';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ChatMessage } from '@cxstudio/reports/document-explorer/chat/chat-message.class';
import { ChannelTypes, ConversationChannelService } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ConversationEnrichment, ConversationHeader, ParticipantEnrichments } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { VoiceConstants } from '@app/modules/conversation/voice-message/voice-constants';
import { VoiceDurationService } from '@app/modules/conversation/voice-message/services/voice-duration.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { TranscriptPiece } from '@cxstudio/reports/entities/transcript-piece';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { AttachmentsService } from '@app/modules/document-explorer/attachments.service';
import { ConversationPartialRendering, ElementsRange, UIElementsRange } from './conversation-partial-rendering.factory';
import { DocViewPaneSettings } from '@app/modules/document-explorer/preferences/doc-view-pane-settings';
import { SentenceScrollHelper } from '@app/modules/document-explorer/sentence-scroll-helper';
import { ISpineHeaderConfiguration } from '@app/shared/components/spine/spine-header.component';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ConversationUtils } from '@app/modules/conversation/conversation-utils.service';
import { RedirectDestinationValues } from '@cxstudio/services/redirect-destination';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ConversationSettingsApi } from '@app/modules/conversation/conversation-settings-api.service';
import { ObjectUtils } from '@app/util/object-utils';
import { SessionConsumers } from '@app/core/sessions/session-consumers.enum';
import { SessionService } from '@app/core/sessions/session.service';
import { Pill } from '@app/modules/pills/pill';
import { ConversationPlaybackProgressService } from '@app/modules/conversation/preferences/conversation-playback-progress.service';
import { PlaybackSettings } from '@app/modules/conversation/preferences/conversation-settings.class';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { CustomDomSharedStylesHost } from '@app/core/CSP/shared-styles-host.service';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeDocumentSource } from '@app/modules/document-explorer/amplitude-document-source.enum';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';

declare let Popcorn: any;

interface IOvertalkState {
	isLastItemSilence: boolean;
	isOvertalkOpen: boolean;
	maxEndTimestamp: number;
}

interface IUiOptions extends DocViewPaneSettings {
	skipSilence: boolean;
	playbackRate: number;
}

export enum TimeUnit {
	SECONDS = 'seconds',
	MILLISECONDS = 'milliseconds'
}

export interface IMetricFormatters {
	date: (date: string) => string;
	time: (date: string) => string;
	sentimentColor: (sentence: PreviewSentence) => string;
	sentiment: (sentence: PreviewSentence, field?: string, options?: any) => string;
	ease: (sentence: PreviewSentence) => string;
	emotion: (sentence: PreviewSentence) => string;
}

export interface IAccountLevelSpineSettings {
	participantDefinition: SpineLane;
	participantEnrichment: ParticipantEnrichmentTypes;
	singleLanes: SpineLane[];
	labels: ConversationChannelLabels;
}

export interface IAudioPlayer {
	pause(): void;
	play(): void;
	currentTime(): number;
	currentTime(newTime: number): IAudioPlayer;
	duration(): number;
	on(event: string, callback: () => void): void;
	playbackRate(playbackRate: number): void;
	destroy(): void;
	cue(time: number, callback: () => void): void; // execute callback when reached the time
}

interface TopicsCountMessage {
	show: boolean;
	message?: string;
}

export class ConversationComponent implements ng.IController {
	conversationOptions: any[];
	conversationFilter: string;
	uniquePrefix: string;
	document: ConversationDocument;
	predefinedMetrics: Metric[];
	uiOptions: IUiOptions;
	showSentiment: boolean;
	downloadAudio: any;
	downloadTranscript: (document: any) => void;
	audioUrl: string;
	// private, but required public for tests
	audioPlayer: IAudioPlayer;
	conversationChart: ConversationChart;
	lastScrollBandId: string;
	lastHighlightedModel: any;
	participants: ConversationParticipants;
	auditMode: boolean;
	auditModeModels: IReportModel[];
	translate: boolean;
	documentManager: IDocumentPreviewerControls;

	partialRendering: ConversationPartialRendering;
	messageProcessingPromise: ng.IPromise<any>;

	readonly PARTIAL_RENDERING_THRESHOLD = 50;
	readonly SAVE_PLAYBACK_INTERVAL = 5_000;

	private properties: {
		contentProviderId: ContentProviderId,
		accountId: AccountId,
		project: ProjectId,
		workspaceProject: WorkspaceProject
	};
	private selectedSentence: number;
	private autoScrollActive: boolean;
	private savePreferences: () => void;
	selectedScorecard: number;
	private onSelectScorecard: (params: {$scorecardId: number}) => void;
	private singleLaneEnrichments: SingleLaneEnrichmentTypes[];
	private transcriptFocusable: boolean = false;
	private sentenceScrollHelper: SentenceScrollHelper;

	showText: boolean = true;
	showTopics: boolean = true;
	showEase: boolean = true;
	showJumpToTop: boolean;
	lastPlotBandId: string;
	private widgetId: number;
	isDocExplorer: boolean;
	private isAudioFileExpired: boolean;
	isAudioPlaying: boolean;
	metricFormatters: IMetricFormatters;
	selectedModelsFilter: (modelId: number) => boolean;

	spineHeaderConfig: ISpineHeaderConfiguration;
	channelLabels: ConversationChannelLabels;

	private countTopicsAbove: number = 0;
	private countTopicsBelow: number = 0;
	private topicPositionMap: {
		rowTop: number[],
		rowBottom: number[]
	};

	readonly SILENCE_BUFFER = 0.2;
	readonly MIN_PLAYBACK_RATE = 0.5;
	readonly MAX_PLAYBACK_RATE = 2;
	readonly PLAYBACK_RATE_INCREMENT = 0.25;

	readonly HIDDEN_TRANSCRIPT_CLASS = 'hidden-transcript';

	styleId: string;
	isChartInitializing: any;
	loading;
	scrollCallback;
	worldAwareness;

	aboveTopicsCount: TopicsCountMessage;
	belowTopicsCount: TopicsCountMessage;
	resizeHandler: any;
	messageWidth: number;
	highlightTopicTrigger: number = 0;

	deselectedModelsIds: number[];

	private lastSentenceToScroll: ConversationSentence;
	private updatingDocument: ng.IDeferred<any>;

	constructor(
		private locale: ILocale,
		private $timeout: ng.ITimeoutService,
		private exportApiService: ExportApiService,
		private $scope: ISimpleScope,
		private redirectService: RedirectService,
		private conversationChannelService: ConversationChannelService,
		private betaFeaturesService: BetaFeaturesService,
		private attachmentsService: AttachmentsService,
		private $rootScope: ng.IRootScopeService,
		private reportMetricsService: ReportMetricsService,
		private security: Security,
		private contractApiService: ContractApiService,
		private contextMenuTree: ContextMenuTree,
		private conversationTooltip: ConversationTooltipService,
		private conversationSettingsApi: ConversationSettingsApi,
		private applicationThemeService: ApplicationThemeService,
		private $element: JQuery,
		private conversationParticipantService: ConversationParticipantService,
		private conversationPlaybackProgressService: ConversationPlaybackProgressService,
		private $q: ng.IQService,
		private $log: ng.ILogService,
		private devTools: DevToolsService,
		private suggestionMenu: SuggestionMenu,
		private conversationEnrichmentUtils: ConversationEnrichmentUtils,
		private conversationUtils: ConversationUtils,
		private sessionService: SessionService,
		private $sanitize,
		private customDomSharedStylesHost: CustomDomSharedStylesHost,
	) { }

	$onInit(): void {
		this.styleId = `s${RandomUtils.randomString()}`; // used as id in html, so must begin with a letter
		this.aboveTopicsCount = {show: false};
		this.belowTopicsCount = {show: false};

		let promises: Array<ng.IPromise<any>> =
			[PromiseUtils.old(this.conversationSettingsApi.getSpineSettings(this.getProject())),
				this.conversationParticipantService.refreshDefaultColorPalette()];

		this.loading = this.$q.all(promises).then(responses => {
				let spineConfig = responses[0];
				if (spineConfig.labels) {
					this.channelLabels = spineConfig.labels;
				}
				delete this.loading;
		});

		this.$scope.$watch(() => this.document, (newDocument: any, oldDocument: any) => {
			if (!this.document || !newDocument || (newDocument.id === (oldDocument && oldDocument.id))) return;
			this.pausePlayback();
			this.updatingDocument = this.$q.defer();
			this.document.initializedUiSilence = false;
			this.initializeDoc();
		});

		this.$scope.$watch(() => this.selectedSentence, (newSentenceId: number, oldSentenceId: number) => {
			if (!this.document || newSentenceId === oldSentenceId) return;
			let promise = this.updatingDocument?.promise || this.$q.resolve();
			promise.then(() => {
					this.scrollToSelectedSentence(newSentenceId);
			});
		});

		this.$scope.$on('hoverModel', this.processModelHover);
		this.$scope.$on('highlightModel', this.processModelHighlight);
		this.$scope.$on('highlightWorldAwareness', this.processWorldAwarenessHighlight);

		this.$scope.$watch(() => this.selectedScorecard, (newScorecardId: any, oldScorecardId: any) => {
			if (newScorecardId && newScorecardId !== oldScorecardId) {
				this.renderChart();
			}
		});

		let resizeCallback = _.debounce(() => {
			let newWidth = this.getMessageWidth();
			if (newWidth && newWidth !== this.messageWidth) {
				this.messageWidth = Math.floor(newWidth);
			}
		}, 150);

		let sentenceRenderWatcher = this.$scope.$watch(this.getMessageWidth, (width) => {
			if (width) {
				sentenceRenderWatcher();
				this.messageWidth = Math.floor(width);
				let resizeObserver = ResizeHandlerUtils.addResizeHandler(this.$element[0], resizeCallback);
				this.$scope.$on('$destroy', () => {
					ResizeHandlerUtils.removeResizeHandler(resizeObserver);
				});
			}
		});

		this.getFakeScroll().on('scroll', _.throttle((event: any) => {
			this.onFakeScroll();
		}, 400, { leading: false }));
		this.getTranscriptWrapper().on('scroll', _.throttle((event: Event) => {
			this.onScroll(event.target as HTMLElement);
		}, 400));
		this.getTranscriptWrapper().on('wheel', (event: any) => {
			this.disableAutoScroll();
		});
		this.getTranscriptWrapper().on('mousedown', (event: any) => {
			if (event.target.className === 'audio-transcript') {
				this.disableAutoScroll();
			}
		});

		angular.element(this.getSpineWrapper()).on('scroll', _.throttle(((event: any) => {
			this.updateSpineScrollIndicators(event.target);
		}), 150));


		this.downloadAudio = (document: any): void => {
			if (this.allowAudioDownload()) {
				this.attachmentsService.downloadAudio(document);
			}
		};

		this.downloadTranscript = (document: any): void => {
			if (this.allowTranscriptDownload()) {
				this.attachmentsService.downloadTranscription(document);
			}
		};

		this.deselectedModelsIds = PillsUtils.getDeselectedModelsIds(this.documentManager.availableModels, this.selectedModelsFilter);

		this.$timeout(() => this.processModelVisibility());
		this.$scope.$on('redraw-topics', () => {
			this.processModelVisibility();
		});

		this.$scope.$watch(() => this.auditMode, (newVal: boolean, oldVal: boolean) => {
			if (!this.document.isChat) {
				if (newVal && !oldVal) {
					// use unmergedSentences
					this.document.sentences = this.document.originalSentences;
				} else if (oldVal && !newVal) {
					// use merged sentences
					this.document.sentences = this.document.mergedSentences;
				}
				this.renderChart();
			}
		});

		this.$scope.$watch(() => {
			return this.attachmentsService.streamAudioUrl(this.document);
		}, (newVal) => {
			if (newVal) {
				this.audioUrl = newVal;
				this.document = ObjectUtils.copy(this.document);
				this.initPlayerElement();
				this.initializeAudioPlayer();
				this.initializeSilenceInPlayback();
			} else {
				this.audioUrl = undefined;
				this.destroyPlayer();
			}

			this.initializeDoc();
			if (this.document?.sentences && newVal) {
				let loadComplete = this.loading ? this.loading : this.$q.when();
				loadComplete.then(() => {
					let highlightSentence: ConversationSentence;

					if (this.selectedSentence) {
						highlightSentence = _.find(this.document.sentences, (sentence) => {
							return sentence.id === this.selectedSentence || _.contains(sentence.mergedSentences || [], this.selectedSentence);
						});
					}
					if (!highlightSentence) {
						highlightSentence = this.document.sentences[0];
					}

					this.$timeout(() => {
						this.highlightTranscriptPiece(highlightSentence);
						this.highlightAdjacentSentences([highlightSentence]);
						this.handlePlaybackProgress();
					}, 500);
				});
			}
		});

		this.autoScrollActive = true;

		this.uiOptions = this.uiOptions || {} as IUiOptions;
		if (_.isUndefined(this.uiOptions.showSentences)) this.uiOptions.showSentences = true;
		if (_.isUndefined(this.uiOptions.showTopics)) this.uiOptions.showTopics = true;
		if (_.isUndefined(this.uiOptions.skipSilence)) this.uiOptions.skipSilence = false;
		if (_.isUndefined(this.uiOptions.playbackRate)) this.uiOptions.playbackRate = 1;

		this.documentManager.conversationMethods = {
			allowAudioDownload: this.allowAudioDownload,
			allowTranscriptDownload: this.allowTranscriptDownload,
			downloadAudio: this.downloadAudio,
			downloadTranscript: this.downloadTranscript,
			toggleShowSentences: this.toggleShowSentences,
			isAudioPlayable: this.isAudioPlayable,
			changePlaybackRateKb: this.changePlaybackRateKb,
			decreasePlaybackRate: this.decreasePlaybackRate,
			isRateDecreaseEnabled: this.isRateDecreaseEnabled,
			increasePlaybackRate: this.increasePlaybackRate,
			isRateIncreaseEnabled: this.isRateIncreaseEnabled,
		};
	}

	$onDestroy(): void {
		// make sure chart is destroyed and events removed
		if (this.conversationChart?.destroy)
			this.conversationChart.destroy();

		this.getTranscriptWrapper().off('scroll');
		this.getTranscriptWrapper().off('wheel');
		this.getTranscriptWrapper().off('mousedown');
		this.getFakeScroll().off('scroll');
		angular.element(this.getChartWrapper()).off('scroll');
		this.destroyPlayer();
		this.$timeout.cancel(this.scrollCallback);

		if (this.partialRendering) {
			this.partialRendering.destroy();
		}

		this.removeAppendedElements();

		this.unbindEventsFromSentences();
	}

	private getProject(): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE) && this.properties.workspaceProject
			? this.properties.workspaceProject
			: ProjectIdentifier.fromWidgetProperties(this.properties);
	}

	private removeAppendedElements(): void {
		this.getTranscriptWrapper().find('#scorecard-style').remove();
		$(`${this.getChartWrapper()} #audio-placeholder`).empty();
	}

	private disableAutoScroll = (): void => {
		let needApply = this.autoScrollActive !== false;
		this.autoScrollActive = false;
		if (needApply)
			this.$rootScope.safeApply();
	}

	initializeDoc = (): void => {
		if (!this.document) {
			return;
		}

		this.validateAudioRetention();
		this.initializeFilterOptions();
		this.initializeSilenceInUI();

		this.participants = this.getParticipantCounts(this.document);
		this.conversationParticipantService.populateChatMessagesParticipantsIndexes(this.document.chatMessages, this.participants);
		this.conversationParticipantService.populateSentencesParticipantsIndexes(this.document.sentences, this.participants);

		if (!_.isEmpty(this.document.scorecards)) {
			this.getTranscriptWrapper().find('#scorecard-style').remove();
			let css = ConversationStyleUtils.getScorecardVisibilityStyles(this.document.scorecards);
			let styleElement = `<style ${this.customDomSharedStylesHost.getCSPNonceAttribute()} id="scorecard-style" type="text/css">${css}</style>`;
			this.getTranscriptWrapper().append(styleElement);
		}

		let predefinedMetricCall = PromiseUtils.old(this.reportMetricsService.getWidgetDynamicPredefinedMetrics(
			this.documentManager.widget, this.isDocExplorer));
		predefinedMetricCall.then(this.processMap);
	}

	getAgentLabel = (): string => {
		return this.channelLabels && this.channelLabels.agent ?
			this.channelLabels.agent :
			this.locale.getString('preview.agentMetric');
	}

	getParticipantCounts = (doc: ConversationDocument): ConversationParticipants => {
		let uniqueParticipants = this.getUniqueParticipants(doc.sentences);

		let enableParticipants = (s: IParticipantOption) => {
			s.enabled = true;
			return s;
		};

		let returnValue: Partial<ConversationParticipants> = {};
		if (uniqueParticipants.length) {
			returnValue[ChannelTypes.AGENT] = uniqueParticipants.filter(s => s.type === ChannelTypes.AGENT).map(enableParticipants);
			returnValue[ChannelTypes.CLIENT] = uniqueParticipants.filter(s => s.type === ChannelTypes.CLIENT).map(enableParticipants);
			returnValue[ChannelTypes.BOT] = uniqueParticipants.filter(s => s.type === ChannelTypes.BOT).map(enableParticipants);
			returnValue[ChannelTypes.UNKNOWN] = uniqueParticipants.filter(s => s.type === ChannelTypes.UNKNOWN).map(enableParticipants);
		} else {
			// if old doc format, we don't distinguish between multiple agents, clients, bots
			returnValue[ChannelTypes.AGENT] = this.hasAgentSentences() ? [{ id: -1, type: ChannelTypes.AGENT, enabled: true}] : undefined;
			returnValue[ChannelTypes.CLIENT] = this.hasClientSentences() ? [{ id: -1, type: ChannelTypes.CLIENT, enabled: true}] : undefined;
			returnValue[ChannelTypes.BOT] = this.hasBotSentences() ? [{ id: -1, type: ChannelTypes.BOT, enabled: true}] : undefined;
			returnValue[ChannelTypes.UNKNOWN] = this.hasUnknownSentences() ? [{ id: -1, type: ChannelTypes.UNKNOWN, enabled: true}] : undefined;
		}

		return returnValue as ConversationParticipants;
	}

	private getUniqueParticipants = (sentences: ConversationSentence[]): Array<{id: number, type: string}> => {
		return _.chain(sentences)
			.map(ConversationChannelService.getParticipant)
			.uniq(s => s.id + s.type)
			.filter(s => s.id !== -1 && !_.isUndefined(s.type))
			.value();
	}

	showParticipant = (sentenceObject): void => {
		let targetSentence = this.isChatMessage(sentenceObject) ? sentenceObject.sentences[0] : sentenceObject;
		let toggledParticipant = ConversationChannelService.getParticipant(targetSentence);

		let selectionListEntry: IParticipantOption = _.findWhere(this.participants[toggledParticipant.type], {id: toggledParticipant.id});
		if (selectionListEntry) {
			if (selectionListEntry.enabled) return; // no change to filtering, can stop here

			selectionListEntry.enabled = true;
			// update onPush
			this.participants = angular.copy(this.participants);
		}
		this.participantSelectionChange();
	}

	participantSelectionChange = (): void => {
		this.showMessageProcessingSpinner(() => {
			this.renderChart();
			this.processHiddenMessages();
		});
	}

	private showMessageProcessingSpinner = (operation: () => void): void => {
		let delayedAction = this.$timeout(operation, 0); // need delay to broadcast participant changes
		if (this.isPartialRenderingEnabled()) {
			this.messageProcessingPromise = delayedAction;
		}
	}

	private processHiddenMessages = (): void => {
		const allItemsSelector = '.conversation-segment';
		let hiddenItemsSelector = this.getHiddenParticipantClasses()
			.map(clazz => '.' + clazz)
			.join(',');

		this.getTranscriptWrapper().find(allItemsSelector).removeClass(this.HIDDEN_TRANSCRIPT_CLASS);
		this.getTranscriptWrapper().find(hiddenItemsSelector).addClass(this.HIDDEN_TRANSCRIPT_CLASS);
	}

	private getHiddenParticipantClasses = (): string[] => {
		return this.getHiddenParticipants()
			.map(participant => `${ConversationStyleUtils.CONVERSATION_PREFIX}${participant.type}-${participant.id}`);
	}

	private processSelectedSentence = (selectedSentenceId: number): void => {
		this.getTranscriptWrapper().find('.selected-sentence').removeClass('selected-sentence');
		if (!_.isUndefined(selectedSentenceId)) {
			let selectedSelector = this.getSentenceSelector(selectedSentenceId);
			this.getTranscriptWrapper().find(selectedSelector).addClass('selected-sentence');
		}
	}

	private getSentenceLineClass = (sentenceId: number): string => {
		return `transcript-line-${sentenceId}`;
	}

	private getSentenceSelector(sentenceId: number): string {
		return  `.${this.getSentenceLineClass(sentenceId)}`;
	}

	validateAudioRetention = (): void => {
		this.contractApiService.getLongestAudioRetention().then(audioRetention => {
			if (audioRetention) {
				let diff = moment().diff(moment(this.document.documentDate), 'days');
				this.isAudioFileExpired = diff > (audioRetention + 1);
			} else {
				this.isAudioFileExpired = false;
			}
		});
	}

	initializeFilterOptions = (): void => {
		this.conversationOptions = [];

		if (this.hasAgentSentences()) {
			this.conversationOptions.push({ name: 'agent', displayName: this.locale.getString('docExplorer.showAgent') });
		}

		if (this.hasClientSentences()) {
			this.conversationOptions.push({ name: 'client', displayName: this.locale.getString('docExplorer.showClient') });
		}

		// if we dont have both agent and client options, we don't need the combined option
		// if we only have unknown channels, need a way to see them
		if (this.conversationOptions.length === 2 || this.conversationOptions.length === 0) {
			this.conversationOptions.insert(0, { name: 'showAll', displayName: this.locale.getString('docExplorer.showAgentAndClient') });
		}

		if (this.document.additionalVerbatim && this.document.additionalVerbatim.length) {
			this.conversationOptions.push({
				name: 'other', displayName: this.locale.getString('docExplorer.showOtherVerbatim')
			});
		}

		this.conversationFilter = this.conversationOptions[0].name;
	}

	initializeSilenceInPlayback = (): void => {
		if (!this.document || !this.document.sentences) {
			return;
		}

		let meaningfulSilenceThreshold: number = this.getMeaningfulSilenceThreshold();

		let audioSentences = this.document.sentences.filter(sentence => !sentence.isSilence) as any[];
		let stateTracking = { maxEndTimestamp: audioSentences[0]?.timestamp || 0 };
		audioSentences.forEach((transcriptLine: any, index: number, transcript: TranscriptPiece[]) => {
			let silenceDuration = VoiceDurationService.getDurationOfLeadingSilence(transcriptLine, stateTracking.maxEndTimestamp);
			stateTracking.maxEndTimestamp = Math.max(stateTracking.maxEndTimestamp, transcriptLine.endTimestamp);
			if (transcript[index - 1] && silenceDuration >= meaningfulSilenceThreshold && this.isAudioAvailable()) {
				this.audioPlayer.cue(transcript[index - 1].endTimestamp, () => {
					// only skip if the flag is on when the silence is encountered
					if (this.uiOptions.skipSilence) {
						let buffer: number = (meaningfulSilenceThreshold >= this.SILENCE_BUFFER) ? this.SILENCE_BUFFER : 0;
						this.goToTime(transcript[index].timestamp - buffer);
					}
				});
			}
		});
	}

	initializeSilenceInUI = (): void => {
		if (!this.document || !this.document.sentences || this.document.initializedUiSilence) {
			return;
		}

		// this method can be called twice under some circumstances, but it should only be run once per doc
		this.document.initializedUiSilence = true;

		if (this.document.isChat) {
			this.conversationUtils.populateChatSentences(this.document);
		} else {
			this.processAudioSentences();
		}
	}

	private processAudioSentences(): void {
		let newSentences = [];
		let stateTracking: IOvertalkState = {
			isLastItemSilence: false,
			isOvertalkOpen: false,
			maxEndTimestamp: this.document.sentences[0]?.timestamp || 0
		};
		let meaningfulSilenceThreshold: number = this.getMeaningfulSilenceThreshold();
		let transcriptPieces: TranscriptPiece[] = this.document.sentences as any[]; // TODO need to merge 2 classes
		let silenceSentenceId = -1;

		transcriptPieces.forEach((transcriptLine: any, index: number, transcript: TranscriptPiece[]) => {
			this.checkOvertalk(stateTracking, transcriptLine, transcript, index, newSentences);

			let silenceDuration = VoiceDurationService.getDurationOfLeadingSilence(transcriptLine, stateTracking.maxEndTimestamp);
			if (silenceDuration >= meaningfulSilenceThreshold) {
				stateTracking.isLastItemSilence = true;
				newSentences.push({
					isSilence: true,
					id: silenceSentenceId,
					duration: silenceDuration,
					timestamp: stateTracking.maxEndTimestamp,
					endTimestamp: transcriptLine.timestamp,
					text: this.formatSilenceText(silenceDuration, TimeUnit.SECONDS, 'docExplorer.silence')
				});
				silenceSentenceId -= 1;
			} else {
				stateTracking.isLastItemSilence = false;
			}
			stateTracking.maxEndTimestamp = Math.max(stateTracking.maxEndTimestamp, transcriptLine.endTimestamp);
			let isSameChannelAsLast = false;
			if (transcript[index - 1] && !stateTracking.isLastItemSilence) {
				isSameChannelAsLast = ConversationChannelService.isSameChannel(transcript[index], transcript[index - 1]);
			}
			transcriptLine.isSameChannelAsLast = isSameChannelAsLast;
			newSentences.push(transcriptLine);
		});

		let mergedSentences = AudioDocumentFormat.combineSentences(newSentences);
		this.document.originalSentences = newSentences;
		this.document.mergedSentences = mergedSentences;
		this.document.sentences = this.auditMode ? this.document.originalSentences : this.document.mergedSentences;
	}

	private isOvertalkSentences = (firstSentence: TranscriptPiece, secondSentence: TranscriptPiece): boolean => {
		return secondSentence.timestamp < firstSentence.endTimestamp;
	}

	private checkOvertalk = (stateTracking: IOvertalkState, transcriptLine, fullTranscript, currentIndex, newSentences) => {
		if (fullTranscript[currentIndex + 1] && this.isOvertalkSentences(transcriptLine, fullTranscript[currentIndex + 1])) {
			if (!stateTracking.isOvertalkOpen) {
				stateTracking.isOvertalkOpen = true;
				transcriptLine.isOvertalkStart = true;
			}
			transcriptLine.isOvertalk = true;
			fullTranscript[currentIndex + 1].isOvertalk = true;
		} else {
			// if not overtalk, but overtalk is open, we gotta close it
			if (stateTracking.isOvertalkOpen) {
				newSentences[currentIndex - 1].isOvertalkEnd = true;
				stateTracking.isOvertalkOpen = false;
			}
		}
	}

	hasAgentSentences = (): boolean => {
		return this.document && this.document.sentences
			? _.some(this.document.sentences, this.conversationChannelService.isAgentSentence)
			: false;
	}

	hasClientSentences = (): boolean => {
		return this.document && this.document.sentences
			? _.some(this.document.sentences, this.conversationChannelService.isClientSentence)
			: false;
	}

	hasBotSentences = (): boolean => {
		return this.document && this.document.sentences
			? _.some(this.document.sentences, this.conversationChannelService.isBotSentence)
			: false;
	}

	hasUnknownSentences = (): boolean => {
		return this.document && this.document.sentences
			? _.some(this.document.sentences, this.conversationChannelService.isUnknownSentence)
			: false;
	}

	toggleShowSentences = (): void => {
		if (!this.uiOptions.showSentences && !this.uiOptions.showTopics) {
			this.uiOptions.showTopics = true;
		}

		this.persistPreferences();
	}

	persistPreferences = (): void => {
		this.savePreferences();
	}

	getMeaningfulSilenceThreshold = (): number => {
		let currentMasterAccount = this.security.getCurrentMasterAccount();
		if (!currentMasterAccount || !currentMasterAccount.meaningfulSilenceThreshold) {
			return VoiceConstants.defaultMeaningfulSilenceThresholdInSeconds;
		}
		//If they select a value less than 1 second, we still will hide silences less than 1 second.
		let silenceThreshold =
			currentMasterAccount.meaningfulSilenceThreshold >= VoiceConstants.minimumMeaningfulSilenceThreshold
			? currentMasterAccount.meaningfulSilenceThreshold : VoiceConstants.minimumMeaningfulSilenceThreshold;
		return silenceThreshold / 1000;
	}

	// sentence silence in seconds
	// document.silence.max in milliseconds
	formatSilenceText = (silenceDuration: number, timeUnit: TimeUnit, pattern: string): string => {
		let unitsPerSecond: number = this.getUnitsPerSecond(timeUnit);

		let minutes: number = Math.floor(silenceDuration / (60 * unitsPerSecond));
		let seconds: string = this.calculateSilenceSecondsPart(silenceDuration, unitsPerSecond);

		if (seconds.length === 1) {
			seconds = '0' + seconds;
		} else if (seconds === '60') {
			minutes++;
			seconds = '00';
		}

		return this.locale.getString(pattern, {min: minutes, sec: seconds});
	}

	private getUnitsPerSecond = (timeUnit: TimeUnit): number => {
		let unitsPerSecond: number = 1;
		if (timeUnit === TimeUnit.MILLISECONDS) {
			unitsPerSecond = 1000;
		}

		return unitsPerSecond;
	}

	calculateSilenceSecondsPart = (silenceDuration: number, unitsPerSecond: number): string => {
		let seconds: string;
		if (unitsPerSecond === 1) {
			seconds = Math.round(silenceDuration % 60) + '';
		} else {
			let unitsPerMinute = unitsPerSecond * 60;
			seconds = Math.round((silenceDuration % unitsPerMinute) / unitsPerSecond) + '';
		}
		return seconds;
	}

	private destroyPlayer = (): void => {
		if (this.audioPlayer) {
			this.audioPlayer.destroy();
		}

		if (this.isAudioPlaying) {
			this.sessionService.stopSessionKeepalive(SessionConsumers.AUDIO_PLAYBACK);
		}
		this.isAudioPlaying = false;

		/**
		 * We need to remove previously injected player in the audio tag.
		 */
		$(`${this.getChartWrapper()} #audio-placeholder`)
			.empty()
			.append(`<audio class="document-audio-player" src="${this.audioUrl}" type="audio/mp3" preload="none" controls="controls"></audio>`);
	}

	initializeAudioPlayer = (): void => {
		if (!this.allowAudioPlayback() || !this.audioUrl) {
			return;
		}

		this.destroyPlayer();

		this.audioPlayer = new Popcorn(`${this.getWrapperLocator()} audio.document-audio-player`);

		this.audioPlayer.on('play', () => {
			// make the playback rate is up to date
			this.isAudioPlaying = true;
			this.sessionService.startSessionKeepalive(SessionConsumers.AUDIO_PLAYBACK);
			this.audioPlayer.playbackRate(this.uiOptions.playbackRate);
			const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.isDocExplorer);

			AmplitudeAnalyticsService.trackEvent(
				AmplitudeEvent.DOCEXPLORER_CALL_PLAY,
				{ documentId },
				{ source, documentType, playbackSpeed: this.uiOptions.playbackRate }
			);
			this.$rootScope.safeApply();
		});

		this.audioPlayer.on('pause', () => {
			this.isAudioPlaying = false;
			this.sessionService.stopSessionKeepalive(SessionConsumers.AUDIO_PLAYBACK);
			this.savePlaybackProgress();
			this.$rootScope.safeApply();
		});

		this.audioPlayer.on('seeking', () => {
			this.highlightOnSeek();
			this.savePlaybackProgress();
			this.$rootScope.safeApply();
		});

		this.audioPlayer.on('ended', () => {
			this.isAudioPlaying = false;
			this.sessionService.stopSessionKeepalive(SessionConsumers.AUDIO_PLAYBACK);
		});

		let savePlaybackProgressThrottled = _.throttle(this.savePlaybackProgress, this.SAVE_PLAYBACK_INTERVAL);

		this.audioPlayer.on('timeupdate', () => {
			if (this.conversationChart)
				this.conversationChart.updatePlayback(this.audioPlayer.currentTime());

			if (this.isAudioPlaying)
				savePlaybackProgressThrottled();
		});
	}

	private savePlaybackProgress = (): void => {
		this.conversationPlaybackProgressService.savePlaybackProgress({
			projectId: this.properties.project,
			documentId: this.document.id
		}, this.audioPlayer.currentTime(), this.audioPlayer.duration());
	}

	private handlePlaybackProgress = (): void => {
		let playbackSettings: PlaybackSettings = this.getPlaybackSettings();

		if (playbackSettings && this.shouldScrollToLastListenedTime(this.getParentWidgetPreviewMode())) {
			this.goToTime(playbackSettings.progress);
			this.pausePlayback();
		}
	}

	private getPlaybackSettings(): PlaybackSettings {
		return this.conversationPlaybackProgressService.getPlaybackSettings({
			projectId: this.properties.project,
			documentId: this.document.id
		});
	}

	getParentWidgetPreviewMode(): PreviewMode | undefined {
		return this.documentManager.widget?.parentWidget?.properties?.previewMode;
	}

	shouldScrollToLastListenedTime(mode: PreviewMode): boolean {
		return mode !== PreviewMode.SENTENCES && mode !== PreviewMode.SENTENCES_WITH_CONTEXT;
	}

	getConversationItems = (container: HTMLElement): HTMLElement[] => {
		return angular.element(container).find(`.conversation-item:not(.${this.HIDDEN_TRANSCRIPT_CLASS})`).toArray() as HTMLElement[];
	}

	onFakeScroll = (): void => {
		let fakeScrollTargetSentence = this.partialRendering.onFakeScroll();
		if (fakeScrollTargetSentence) {
			this.scrollToTranscriptPiece(fakeScrollTargetSentence, true);
			this.disableAutoScroll();
		}
	}

	onScroll = (scrollElement: Element): void => {
		if (!scrollElement)
			return;

		if (this.partialRendering?.isScrollLocked())
			return;

		let dataSet: any[] = this.getCurrentDataSet();
		if (_.isEmpty(dataSet))
			return;

		let visibleRange = this.recalculateVisibleElementsRange();
		this.updateSpineScroll(dataSet, visibleRange);

		if (this.isPartialRenderingEnabled() && this.partialRendering.needsVisibleRangeUpdate()) {
			this.partialRendering.renderPartByIndex(visibleRange.startIndex, true);

			// workaround for scroll getting stuck on top:
			// if this is not the top of the document (aka first sentence),
			// scroll to that sentence after new rendered document part is evaluated
			if (visibleRange.offsetTop === 0 && visibleRange.startIndex !== 0) {
				let startSentence = dataSet[visibleRange.startIndex];
				this.$log.info('Scroll up workaround, scheduling to scroll to ' + startSentence.id);

				this.partialRendering.lockScrolling(startSentence, () => {
					this.scrollToSentenceImmediately(startSentence);
					this.onScroll(scrollElement);
				}, 0);
			}
		}
	}

	private updateSpineScroll = (dataSet: any[], visibleRange: ElementsRange): void => {
		this.$log.info('Updating spine scroll top index ' + visibleRange.startIndex);

		if (visibleRange.startIndex >= dataSet.length) {
			visibleRange.startIndex = dataSet.length - 1;
		}
		if (visibleRange.endIndex >= dataSet.length) {
			visibleRange.endIndex = dataSet.length - 1;
		}

		let startIndex = _.findIndex(this.document.sentences, { id: dataSet[visibleRange.startIndex].id });
		let endIndex = _.findIndex(this.document.sentences, { id: dataSet[visibleRange.endIndex].id });

		// find the last piece, that has the same endtimestamp within the same group
		let endPiece = dataSet[visibleRange.endIndex];
		if (endPiece.groupedIds) {
			let endSentence = this.document.sentences[endIndex];
			let adjustedEndSentence = endSentence;
			while (endIndex < this.document.sentences.length - 1
					&& endPiece.groupedIds.contains(adjustedEndSentence.id)
					&& adjustedEndSentence.endTimestamp === endSentence.endTimestamp) {
				endIndex++;
				adjustedEndSentence = this.document.sentences[endIndex];
			}
		}

		if (startIndex >= 0 && endIndex >= 0) {
			this.conversationChart.updateScrollArea(startIndex, endIndex);
		}
	}

	private scrollToSentenceImmediately = (sentence: ConversationSentence): void => {
		this.$log.info(`Scrolling immediately to ${sentence.id}`);

		let scrollTo = this.getSentenceScrollVisibleTop(sentence);
		if (scrollTo !== undefined) {
			$(this.getTranscriptWrapper()).scrollTop(scrollTo);
		}
	}

	private recalculateVisibleElementsRange = (): UIElementsRange => {
		let scrollElement = this.getTranscriptWrapper().get(0) as HTMLElement;

		let visibleTop: number = scrollElement.scrollTop;
		let visibleBottom: number = visibleTop + scrollElement.offsetHeight;

		let sentenceElements: HTMLElement[] = this.getConversationItems(scrollElement);

		let firstVisibleSentenceIndex: number = _.findIndex(sentenceElements, (child: any) => {
			return ((child.children.length && child.children[0].offsetTop + child.children[0].offsetHeight) >= visibleTop);
		});

		let lastVisibleSentenceIndex: number = _.findIndex(sentenceElements, (child: any, index) => {
			return index >= firstVisibleSentenceIndex &&
				(child.offsetTop <= visibleBottom) &&
				((index === sentenceElements.length - 1) ||
					(sentenceElements[index + 1].offsetTop > visibleBottom));
		});

		let offset = this.isPartialRenderingEnabled() ? this.partialRendering.getRenderedRange().startIndex : 0;
		let result = {
			startIndex: offset + firstVisibleSentenceIndex,
			endIndex: offset + lastVisibleSentenceIndex,
			offsetTop: visibleTop,
			offsetBottom: visibleBottom
		};

		if (this.isPartialRenderingEnabled()) {
			this.partialRendering.setVisibleRange(result);
		}
		return result;
	}

	private getCurrentDataSet = (): any[] => {
		return this.document.isChat ? this.document.chatMessages : this.document.sentences;
	}

	private bindSentenceEvents = (oneSentence: any) => {
		delete oneSentence.highlight;
		delete oneSentence.highlightAdjacent;

		if (!_.isUndefined(this.audioPlayer)) {
			this.audioPlayer.cue(oneSentence.timestamp, this.wrapHighlightFn(oneSentence.id));
		}

		// add the click event for use in the chart visualization
		oneSentence.events = {
			click: () => {
				const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.isDocExplorer);

				AmplitudeAnalyticsService.trackEvent(
					AmplitudeEvent.DOCEXPLORER_SPINE_CLICK,
					{ documentId },
					{ documentType, source }
				);
				this.selectTranscriptPiece(null, oneSentence);

				// update UI immediately
				this.$rootScope.safeApply();
			}
		};
	}

	private bindEventsToSentences(): void {
		if (this.document.isChat) {
			_.forEach(this.document.sentences, this.bindSentenceEvents);
		} else {
			_.forEach(this.document.originalSentences, this.bindSentenceEvents);
			_.forEach(this.document.mergedSentences, this.bindSentenceEvents);
		}
	}

	private unbindEventsFromSentences(): void {
		if (this.document.isChat) {
			_.forEach(this.document.sentences, (s) => delete s.events);
		} else {
			_.forEach(this.document.originalSentences, (s) => delete s.events);
			_.forEach(this.document.mergedSentences, (s) => delete s.events);
		}
	}

	processMap = (metricsResponse): void => {
		this.predefinedMetrics = metricsResponse;

		if (this.security.getCurrentMasterAccount().vociEnabled && DocumentTypeUtils.isVoice(this.document)) {
			this.renderMessages();
			this.bindEventsToSentences();
			this.highlightAdjacentSentences([this.document.sentences[0]]);
		} else {
			this.renderMessages();
			if (this.document.isChat) {
				_.forEach(this.document.chatMessages, (chatMessage: ChatMessage) => {
					_.forEach(chatMessage.sentences, (oneSentence: ConversationSentence) => {
						oneSentence.events = {
							click: () => {
								this.selectTranscriptPiece(null, oneSentence);

								// update UI immediately
								this.$rootScope.safeApply();
							}
						};
					});
				});
			}
		}

		// if we have no sentences....not sure what to do here :)
		if (this.document.sentences.length) {
			this.renderChart();
			this.manualPositionUpdate();
		}
		this.updatingDocument?.resolve();
	}

	private renderMessages = (): void => {
		this.partialRendering = new ConversationPartialRendering(
			this.$log,
			this.$timeout,
			this.document,
			`${this.getWrapperLocator()} .audio-transcript`,
			`${this.getChartWrapper()} .fake-scroll`,
			this.onScrollLockRelesed,
			this.onHighlightSentence,
			{
				getHiddenParticipantClasses: this.getHiddenParticipantClasses,
				isSentenceHidden: this.isHiddenSentence
			});

		if (this.isPartialRenderingEnabled()) {
			this.partialRendering.init();
		}
	}

	private onScrollLockRelesed = (): void => {
		let visibleRange = this.recalculateVisibleElementsRange();
		let dataSet: any[] = this.getCurrentDataSet();
		if (!dataSet.length)
			return;
		this.updateSpineScroll(dataSet, visibleRange);
	}

	private onHighlightSentence = (sentence: ConversationSentence): void => {
		this.unhighlightOldItems();
		this.highlightSentenceWithAdjacent(sentence);
	}

	private findSentenceIndex = (sentenceId: number): number => {
		let allMessages = this.getCurrentDataSet();
		return this.document.isChat
			? _.findIndex(allMessages, message => message.groupedIds ? message.groupedIds.contains(sentenceId) : message.id === sentenceId)
			: _.findIndex(allMessages, { id: sentenceId });
	}

	isShowingFakeScroll = (): boolean => {
		return this.partialRendering?.isShowingFakeScroll();
	}

	private getFakeScroll = (): JQuery => {
		return angular.element(`${this.getWrapperLocator()} .fake-scroll`);
	}

	private isPartialRenderingEnabled = (): boolean => {
		return this.document.sentences.length > this.PARTIAL_RENDERING_THRESHOLD;
	}

	getFilterByModelFunction = (node): (dataItem: ConversationSentence) => boolean => {
		if (node === undefined) return (dataItem: ConversationSentence) => false;

		return (dataItem: ConversationSentence): boolean => {
			return dataItem && dataItem.sentenceTopics && !!_.find(dataItem.sentenceTopics, {id: node && node.id});
		};
	}

	processModelVisibility = (init: boolean = false): void => {
		if (!init) {
			this.deselectedModelsIds = PillsUtils.getDeselectedModelsIds(this.documentManager.availableModels, this.selectedModelsFilter);
		}
	}
	private setDynamicStyleBlock(style?: string): void {
		ConversationStyleUtils.setDynamicStyleBlock(
			this.$element.find('.selected-topic-style'),
			style,
			this.customDomSharedStylesHost.getCSPNonceAttribute()
		);
	}
	processModelHighlight = (event, node, direction: NavigationDirection = NavigationDirection.NEXT): void => {
		let filterFunction = this.getFilterByModelFunction(node);

		this.highlightHighchartsSentences(node, filterFunction, true);
		this.clearSentenceTopicSelection();
		// highlight topics
		this.highLightTopics(node, filterFunction);
		let targetSentenceId = this.sentenceScrollHelper.getNextTopicSentence(node, direction);
		this.highlightConversationSentences(targetSentenceId);
		let style;

		if (node) {
			let topicId = node.id;
			let color = this.getPrimaryColor();
			style = `
				#${this.styleId} .topic-${topicId} {
					border-color: var(--blue-700) !important;
				}

				#${this.styleId} .has-topic-${topicId} {
					--topic-highlight-color: var(--blue-300);
				}`;
		}
		this.setDynamicStyleBlock(style);

		if (this.documentManager.state?.highlightingTopicResolve) {
			this.documentManager.state.highlightingTopicResolve();
		}
	}

	private clearSentenceTopicSelection(): void {
		_.each(this.document.sentences, (sentence: ConversationSentence) => {
			_.each(sentence.sentenceTopics, (topic) => {
				delete topic.selected;
			});
		});
	}

	processModelHover = (event, node): void => {
		if (_.isUndefined(node)) {
			this.conversationChart.conversation.clearHover();
			this.conversationChart.rowIndicators.clearHover();
			return;
		}

		let filterFunction = this.getFilterByModelFunction(node);
		this.conversationChart.conversation.hoverHighlight(filterFunction);
		this.conversationChart.rowIndicators.hoverHighlight(filterFunction);
	}

	processWorldAwarenessHighlight = (event, node, direction): void => {
		let filterFunction = (dataItem: ConversationSentence): boolean => {
			return dataItem?.attributes?.[node.name]?.indexOf(node.value) > -1;
		};

		this.highlightHighchartsSentences(node, filterFunction);

		let style;
		if (node) {
			style = `${node.sentences.map(val => this.getSentenceSelector(val)).join(',')} {
				--topic-highlight-color: var(--blue-300);
			}`;
		}

		this.setDynamicStyleBlock(style);

		this.clearSentenceTopicSelection();
		let targetSentenceId = this.sentenceScrollHelper.getNextWorldAwarenessSentence(node, direction);
		this.highlightConversationSentences(targetSentenceId);
	}

	highlightHighchartsSentences = (node: any, filterFn: (VoiceSentence) => boolean, topicHighlight: boolean = false): void => {
		if (!this.chartFullyInitialized() || this.lastHighlightedModel === node) {
			return;
		}

		this.lastHighlightedModel = node;

		this.unhighlightChartSentences();

		if (!node) {
			this.resumeAutoScrolling();
			return;
		}

		if (topicHighlight) {
			this.conversationChart.rowIndicators.topicHighlight(filterFn);
			this.conversationChart.rowIndicators.updateTopicClasses(filterFn, node);
			this.conversationChart.conversation.topicHighlight(filterFn);
		} else {
			this.conversationChart.conversation.highlight(filterFn);
			this.conversationChart.rowIndicators.highlight(filterFn);
		}
	}

	unhighlightChartSentences = (): void => {
		this.conversationChart.conversation.clearHighlight();
		this.conversationChart.conversation.clearTopicHighlight();
		this.conversationChart.tooltipManager.hideTooltip();
		this.conversationChart.rowIndicators.clearSelected();
		this.conversationChart.rowIndicators.clearTopicHighlight();

		delete this.topicPositionMap;
	}

	highlightConversationSentences = (targetSentenceId?: number): void => {
		if (!this.chartFullyInitialized() || _.isUndefined(targetSentenceId)) {
			this.hideSpineScrollIndicators();
			return;
		}

		let selectedSentence: ConversationSentence = _.findWhere(this.document.sentences, { id: targetSentenceId });
		if (selectedSentence) {
			this.scrollToTranscriptPiece(selectedSentence);
			this.autoScrollActive = false;
			this.conversationChart.rowIndicators.selectSentence(selectedSentence);
		}

		let elementId = `${this.getChartWrapper()} #__${selectedSentence.id}.cb-spine-row`;
		let eventElement = document.querySelector(elementId);

		let pointTop: number = parseFloat($(eventElement).attr('y'));
		let pointBottom: number = pointTop + $(eventElement).height();
		let spineScrollWrapper = $(`${this.getChartWrapper()} #audio-visualization`);
		let scrollDefinition;

		if (spineScrollWrapper.scrollTop() + spineScrollWrapper.height() < pointBottom) {
			// out of view below the fold
			scrollDefinition = {scrollTop : pointBottom - spineScrollWrapper.height() + 50};
		} else if (spineScrollWrapper.scrollTop() > pointTop) {
			// out of view above the fold
			scrollDefinition = {scrollTop : pointTop - 50};
		}

		if (scrollDefinition) {
			spineScrollWrapper.animate(scrollDefinition, 200, () => {
				this.conversationChart.conversation.showTooltipForPoint(selectedSentence as ConversationDataPoint, eventElement);
				this.updateSpineScrollIndicators(this.getSpineWrapper().get(0) as HTMLElement);
			});
		} else {
			this.conversationChart.conversation.showTooltipForPoint(selectedSentence as ConversationDataPoint, eventElement);
			this.updateSpineScrollIndicators(this.getSpineWrapper().get(0) as HTMLElement);
		}
	}

	highLightTopics = (node, filterFn): void => {
		_.chain(this.document.sentences)
			.filter(filterFn)
			.map((dataItem: ConversationSentence): void => {
				let topic = _.findWhere(dataItem.sentenceTopics, {id: node.id});
				if (topic)
					topic.selected = true;
			});
		this.highlightTopicTrigger++;
	}

	manualPositionUpdate = (): void => {
		// do not scroll to the first sentence on open
		if (this.isPartialRenderingEnabled() && this.getPlaybackSettings()?.progress > 0) {
			return;
		}

		if (this.chartFullyInitialized()) {
			// make sure sentences have rerendered so our calculations are correct
			this.scrollCallback = this.$timeout(() => {
				this.onScroll(this.getTranscriptWrapper()[0]);
				this.scrollToSelectedSentence(this.selectedSentence);
			}, 100);
		}
	}

	// providers a unique locator to target only elements a specific player
	// prevents interference between multiple preview widgets
	private getWrapperLocator = (): string => {
		return this.isDocExplorer ? `.an-document-explorer` : `#widget-${this.widgetId}`;
	}

	// prevent spine from rendering in wrong doc
	private getChartWrapper = (): string => {
		let documentIdentifier = `#convo-document-${this.document.id}`;
		return `${this.getWrapperLocator()} ${documentIdentifier}`;
	}

	private getTranscriptWrapper = (): JQuery => {
		return angular.element(`${this.getWrapperLocator()} .audio-transcript`);
	}

	private getSpineWrapper = (): JQuery => {
		return angular.element(`${this.getWrapperLocator()} #audio-visualization`);
	}

	renderChart(): void {
		let sentimentMetric = _.findWhere(this.predefinedMetrics, {name: PredefinedMetricConstants.SENTIMENT});
		sentimentMetric = angular.copy(sentimentMetric);
		if (sentimentMetric) {
			//during init metrics may absent
			sentimentMetric.name = 'dScore'; // sentence field for sentiment value
		}
		let targetElement: string = `${this.getChartWrapper()} #audio-visualization`;


		if (!this.chartFullyInitialized() && !this.isChartInitializing) {
			this.redrawChart(targetElement).then(() => this.manualPositionUpdate());
		} else {
			// if chart is currently initializing we should just wait
			if (!this.isChartInitializing) {
				this.redrawChart(targetElement);
			}
		}

		delete this.lastHighlightedModel;
		this.updateSentenceScrollHelper();
	}

	updateSentenceScrollHelper(): void {
		this.sentenceScrollHelper = new SentenceScrollHelper(this.document.sentences);
	}

	private redrawChart(targetElement: string): ng.IPromise<void> {
		this.isChartInitializing = true;

		return this.getProjectLevelSpineSettings().then((accountConfig: IAccountLevelSpineSettings) => {
			delete this.isChartInitializing;
			if (!document.querySelector(targetElement)) {
				// document has been unloaded since call was made
				return this.$q.reject();
			}
			if (this.conversationChart)
				this.conversationChart.clear();
			let chartUtils = new ConversationChartUtils();
			let isChat = this.document.isChat;

			let participantEnrichments = this.getParticipantEnrichments(accountConfig);
			let singleLaneEnrichments = this.getSingleLaneEnrichments(accountConfig);
			this.singleLaneEnrichments = _.map(accountConfig.singleLanes, lane => lane.definition.name as SingleLaneEnrichmentTypes);
			this.populateChannelEnrichments(participantEnrichments, accountConfig.labels);
			this.spineHeaderConfig = this.getHeaderConfig(participantEnrichments, accountConfig.participantEnrichment,
				singleLaneEnrichments);

			this.conversationChart = new ConversationChart(document.querySelector(targetElement), {
				chartType: isChat ? ConversationType.CHAT : ConversationType.AUDIO,
				participantEnrichments: participantEnrichments as ParticipantEnrichments,
				primaryColor: this.getPrimaryColor(),
				getTooltip: this.conversationTooltip.getWrappedTooltipGenerator(isChat, accountConfig.labels),
				getHeaderTooltip: this.conversationTooltip.getHeaderTooltip,
				sameClusterPredicate: isChat ? chartUtils.isSameChannelAndTimestamp : chartUtils.isSameChannel,
				participantEnrichment: accountConfig.participantEnrichment,
				singleLaneEnrichments,
				theme: this.getConversationTheme()
			});
			let participantFilter = this.hideParticipantFunction();
			this.document.sentences.forEach(sentence => sentence.isFiltered = participantFilter(sentence));
			this.conversationChart.renderData(this.document.sentences);
		});
	}



	private getPrimaryColor(): any {
		return this.isDocExplorer
			? this.applicationThemeService.getAppBrandingColors().COLOR_4
			: this.applicationThemeService.getDashboardBrandingColors().COLOR_4;
	}

	private getConversationTheme(): ApplicationTheme {
		let showingDarkTheme = this.isDocExplorer // should use app theme in doc explorer
			? this.applicationThemeService.isShowingDarkTheme()
			: this.applicationThemeService.isShowingDashboardDarkTheme();
		return showingDarkTheme ? ApplicationTheme.DARK : ApplicationTheme.DEFAULT;
	}

	private getProjectLevelSpineSettings(): ng.IPromise<IAccountLevelSpineSettings> {
		return PromiseUtils.old(this.conversationSettingsApi.getSpineSettings(this.getProject())).then(settings => {
			let accountConfig = {} as IAccountLevelSpineSettings;
			accountConfig.participantEnrichment = settings.participantLane.enabled ?
				settings.participantLane.definition.name as ParticipantEnrichmentTypes :
				ParticipantEnrichmentTypes.NONE;

			accountConfig.labels = settings.labels;

			accountConfig.participantDefinition = settings.participantLane;
			accountConfig.singleLanes = _.filter(settings.singleLanes, lane => lane.enabled);

			return accountConfig;
		});
	}

	private getHeaderConfig(participantEnrichments: ParticipantEnrichments, participantEnrichmentType: ParticipantEnrichmentTypes,
		singleLaneEnrichments: ConversationEnrichment[]): ISpineHeaderConfiguration {
		let singleLanes = _.chain(singleLaneEnrichments)
			.map(enrichment => ({
				icon: enrichment.getHeaderIcon(),
				tooltipContent: this.conversationTooltip.getHeaderTooltip([enrichment]),
				onClick: enrichment.onClick
			})).value();

		let participantEnrichment = participantEnrichments.primary;
		let getHeaderHtml = () => {
			if (participantEnrichmentType === ParticipantEnrichmentTypes.NONE)
				return this.locale.getString('appearance.participants');
			let icon = `<span class="${participantEnrichment.getHeaderIcon()} mr-8"></span>`;
			let name = `<span class="text-ellipsis">${participantEnrichment.getName()}</span>`;
			return `${icon}${name}`;
		};

		let config: ISpineHeaderConfiguration = {
			participantLane: {
				getHeaderHtml,
				tooltipContent: this.conversationTooltip.getHeaderTooltip([participantEnrichment,
					participantEnrichments.clientDetection, participantEnrichments.agentDetection], true)
			},
			singleLanes
		};

		return config;
	}

	isClient = (transcript: ConversationSentence): boolean => this.conversationChannelService.isClient(transcript.channel);
	isAgent = (transcript: ConversationSentence): boolean => this.conversationChannelService.isAgent(transcript.channel);
	isBot = (transcript: ConversationSentence): boolean => this.conversationChannelService.isBot(transcript.channel);
	isUnknown = (transcript: ConversationSentence): boolean => this.conversationChannelService.isUnknown(transcript.channel);

	hideParticipantFunction(): (conversationSentence: ConversationSentence | ChatMessage) => boolean {
		let excludedParticipants = this.getHiddenParticipants();
		return (conversationSentence: ConversationDataPoint | ChatMessage): boolean => {
			// new version will show anything not in array
			if (_.isEmpty(excludedParticipants)) return false;
			let participant = ConversationChannelService.getParticipant(this.getFirstSentence(conversationSentence));

			return !!(_.findWhere(excludedParticipants, {type: conversationSentence.channel, id: -1}) ||
				_.findWhere(excludedParticipants, participant));
		};
	}

	private getHiddenParticipants(): IParticipantOption[] {
		return _.chain(this.participants)
			.values()
			.flatten()
			.filter(participant => !!participant)
			.filter((participant: IParticipantOption) => !participant.enabled)
			.value();
	}

	filterTo(filterValue: string): any {
		return (voiceSentence: ConversationSentence): boolean => {
			if (filterValue === 'showAll') return true;

			return (this.conversationChannelService.getString(voiceSentence.channel) === filterValue);
		};
	}

	initPlayerElement(): void {
		this.$timeout(() => {
			// splitting this into two lines is a hacky way to pass TS validation
			let tag: any = $(`${this.getWrapperLocator()} audio`);
			tag.mediaelementplayer();
		}, 0);
	}

	goToTime = (time?: number): void => {
		if (!isEmpty(time)) {
			if (this.isAudioPlayable()) {
				this.audioPlayer.currentTime(time).play();
			}

			this.highlightOnSeekByTime(time);
		}
	}

	pausePlayback = () => {
		this.audioPlayer?.pause();
		this.isAudioPlaying = false;
	}

	togglePlayback = (event: KeyboardEvent, time?: number): void => {
		if (!KeyboardUtils.isEventKey(event, Key.ENTER) || !this.transcriptFocusable) {
			return;
		}
		event.stopPropagation();
		if (this.isAudioPlaying) {
			this.pausePlayback();
		} else {
			this.goToTime(time);
		}

	}

	handleTranscriptKeydown = (event: KeyboardEvent): void => {
		let activeElement = $(document.activeElement);
		let containerFocused = activeElement.attr('id') === `transcript-${this.styleId}`;
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && containerFocused) {
			event.stopPropagation();
			this.transcriptFocusable = true;
			setTimeout(() => activeElement.find(':focusable').first().trigger('focus'));
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE) && !containerFocused) {
			event.stopPropagation();
			$(`#transcript-${this.styleId}`).first().trigger('focus');
			this.transcriptFocusable = false;
		}
	}

	scrollTop(): void {
		this.getTranscriptWrapper().scrollTop(0);
	}

	scrollToTranscriptPiece(sentence: ConversationSentence, blockFakeScroll?: boolean, animationTimeout?: number): void {
		this.$log.info('Scrolling to sentence ' + sentence.id);

		this.lastSentenceToScroll = sentence;
		if (this.isPartialRenderingEnabled()) {
			this.partialRendering.checkAndCancelScroll(sentence);
		}

		let targetSentenceCss: string = this.getSentenceElementCss(sentence);
		let targetElement = $(targetSentenceCss);

		if (targetElement.length && targetElement.offset()) {
			let scrollTo = this.getSentenceScrollVisibleTop(sentence);
			animationTimeout = !_.isUndefined(animationTimeout) ? animationTimeout : 500;
			let wrapper = $(this.getTranscriptWrapper());
			if (animationTimeout > 0) {
				wrapper.animate({scrollTop : scrollTo}, {
					duration: animationTimeout,
					queue: false
				});
			} else {
				wrapper.scrollTop(scrollTo);
			}

			if (this.isPartialRenderingEnabled()) {
				if (!blockFakeScroll) {
					this.partialRendering.updateFakeScrollOffset(sentence);
				}

				this.partialRendering.lockScrolling(sentence, () => this.scrollToSentenceImmediately(sentence), animationTimeout);
			}
		} else if (this.isPartialRenderingEnabled()) {
			this.$log.info(`Sentence ${sentence.id} is out of viewport`);

			this.partialRendering.renderPartBySentenceId(sentence.id);
			// 100ms delay to address issue with it jumping between first and selected sentence
			this.$timeout(() => {
				if (sentence === this.lastSentenceToScroll) {
					this.scrollToTranscriptPiece(sentence, blockFakeScroll, 0);
				}
			}, 100);
		}
	}

	private getSentenceScrollVisibleTop = (sentence: ConversationSentence): number | undefined => {
		let targetSentenceCss: string = this.getSentenceElementCss(sentence);
		let lineElement: HTMLElement = document.querySelector(targetSentenceCss);

		if (!lineElement) {
			return undefined;
		}

		if (lineElement.classList.contains(this.HIDDEN_TRANSCRIPT_CLASS) && lineElement.clientHeight === 0) {
			let previousSentenceIndex = this.findSentenceIndex(sentence.id) - 1;
			if (previousSentenceIndex >= 0) {
				return this.getSentenceScrollVisibleTop(this.getCurrentDataSet()[previousSentenceIndex]);
			}
		}

		const BUFFER = 32; // leave a small visual buffer above scrolled block
		let topOffset = (!this.document.isChat && sentence.isSameChannelAsLast) ? 30 : 0; // subsequent merged voice sentences don't have top padding
		return lineElement.offsetTop - topOffset - BUFFER;
	}

	private getSentenceElementCss = (sentence: ConversationSentence): string => {
		return `${this.getWrapperLocator()} ${this.getSentenceSelector(sentence.id)}`;
	}

	scrollToSelectedSentence = (sentenceId: number): void => {
		let selectedSentence: ConversationSentence = _.find(this.document.sentences, (sentence) => {
			return sentence.id === sentenceId || _.contains(sentence.mergedSentences || [], sentenceId);
		});

		if (selectedSentence) {
			this.scrollToTranscriptPiece(selectedSentence);
			//DISCCSI-3525, we always disable auto scroll when a document is loaded. Commented out the line below.
			//this.autoScrollActive = false;
			this.$timeout( () => this.processSelectedSentence(selectedSentence.id), 0); // wait for sentence to render
		}
	}

	isAudioAvailable = (): boolean => !_.isUndefined(this.audioUrl) && !_.isUndefined(this.audioPlayer);
	isTranscriptAvailable = (): boolean => this.attachmentsService.documentHasTranscript(this.document);

	highlightOnSeek = () => {
		if (!this.isAudioPlayable()) return;

		let seekTime = this.audioPlayer.currentTime();
		this.highlightOnSeekByTime(seekTime);
		this.conversationChart.updatePlayback(seekTime);
	}

	unhighlightOldItems = (): void => {
		if (this.isPartialRenderingEnabled()) {
			this.partialRendering.setHighlightTarget(null);
		}

		this.getTranscriptWrapper().find('.is-adjacent,.is-highlighted').removeClass('is-adjacent is-highlighted');
	}

	private highlightAdjacentSentences = (targetSentences: ConversationSentence[]): void => {
		let adjacentSentences = this.getAdjacentSentences(targetSentences);

		let adjacentSelector = _.map(adjacentSentences, sentence => this.getSentenceSelector(sentence.id))
			.join(',');
		this.getTranscriptWrapper().find(adjacentSelector).addClass('is-adjacent');

		adjacentSentences.forEach(sentence => sentence.highlightAdjacent = true);
		if (this.audioPlayer) {
			this.audioPlayer.cue((_.max(adjacentSentences, sentence => sentence.endTimestamp) as ConversationSentence).endTimestamp, () => {
				adjacentSentences.forEach(sentence => delete sentence.highlightAdjacent);
				this.getTranscriptWrapper().find(adjacentSelector).removeClass('is-adjacent');
			});
		}
	}

	highlightOnSeekByTime = (seekTime: number): void => {
		this.unhighlightOldItems();

		let matchedSentences =
			_.filter(this.document.sentences, (sentence) => this.timeFallsWithinSentence(sentence, seekTime));

		if (!_.isEmpty(matchedSentences)) {
			this.highlightAdjacentSentences(matchedSentences);
			matchedSentences.forEach(sentence => this.highlightTranscriptPiece(sentence));
			this.scrollToTranscriptPiece(matchedSentences.last());
		}
	}

	private getAdjacentSentences = (playingSentences: ConversationSentence[]): ConversationSentence[] => {
		let adjacentSentences: ConversationSentence[] = [];
		_.map(playingSentences, (sentence: ConversationSentence) => {
			let playingIndex = _.findIndex(this.document.sentences, { id: sentence.id });
			let checkIndex: number;
			let newSpeakerFound: boolean = false;

			if (sentence.isSameChannelAsLast) {
				checkIndex = playingIndex - 1;
				while (checkIndex > 0 && !newSpeakerFound) {
					if (this.document.sentences[checkIndex].channel === sentence.channel) {
						adjacentSentences.push(this.document.sentences[checkIndex]);
						checkIndex--;
					} else {
						newSpeakerFound = true;
					}
				}
			}

			checkIndex = playingIndex + 1;
			newSpeakerFound = false;
			while (checkIndex < this.document.sentences.length && !newSpeakerFound) {
				if (this.document.sentences[checkIndex].channel === sentence.channel) {
					adjacentSentences.push(this.document.sentences[checkIndex]);
					checkIndex++;
				} else {
					newSpeakerFound = true;
				}
			}
		});

		return _.uniq(adjacentSentences, (sentence) => sentence.id);
	}

	selectTranscriptPiece = ($event, sentence: ConversationSentence): void => {
		if (this.isAuditModeEvent($event)) {
			this.suggestionMenu.openSuggestionMenuFromDocumentPane($event, sentence, this.documentManager, this.uiOptions.leafOnly)
				.then(() => this.highlightTopicTrigger++);
		}

		if (this.devTools.tryLogSentenceMetadata($event, sentence)) return;

		if (this.isAudioPlaying) {
			this.goToTime(sentence.timestamp);
			return;
		}
		this.unhighlightOldItems();
		this.highlightSentenceWithAdjacent(sentence);
		if (!this.isAuditModeEvent($event)) {
			this.scrollToTranscriptPiece(sentence);
		}
		this.conversationChart.updatePlayback(sentence.timestamp);
	}

	private isAuditModeEvent = ($event): boolean => {
		return this.auditMode && $event;
	}

	highlightTranscriptPiece = (highlightDoc?: ConversationSentence): void => {
		this.$rootScope.safeApply(() => {
			this.highlightSentenceWithAdjacent(highlightDoc);
		});

		if (this.isAudioAvailable()) {
			this.audioPlayer.cue(highlightDoc.endTimestamp, () => {
				this.$rootScope.safeApply(() => {
					this.unhighlightSentence(highlightDoc);
				});
			});

			if (this.autoScrollActive) {
				// timeout to make sure that new doc has rendered
				this.$timeout(() => { this.scrollToTranscriptPiece(highlightDoc); });
			}
		}
	}

	private highlightSentenceWithAdjacent(sentence: ConversationSentence): void {
		this.highlightSentence(sentence);
		this.highlightAdjacentSentences([sentence]);
	}

	private highlightSentence(sentence: ConversationSentence): void {
		if (this.isPartialRenderingEnabled()) {
			this.partialRendering.setHighlightTarget(sentence);
		}

		this.getTranscriptWrapper().find(this.getSentenceSelector(sentence.id)).addClass('is-highlighted');
	}

	private unhighlightSentence(sentence: ConversationSentence): void {
		if (this.isPartialRenderingEnabled() && sentence === this.partialRendering.getHighlightTarget()) {
			this.partialRendering.setHighlightTarget(null);
		}

		this.getTranscriptWrapper().find(this.getSentenceSelector(sentence.id)).removeClass('is-highlighted');
	}

	sentenceIsCurrent = (sentence: ConversationSentence): boolean => {
		if (!this.isAudioPlayable()) return false;

		let time: number = this.audioPlayer.currentTime();
		return this.timeFallsWithinSentence(sentence, time);
	}

	timeFallsWithinSentence = (sentence: ConversationSentence, time: number): boolean => {
		return (time >= sentence.timestamp) && (time < sentence.endTimestamp);
	}

	wrapHighlightFn(id: number): () => void {
		return () => {
			let highlightDoc: ConversationSentence = _.find(this.document.sentences, { id });
			if (!highlightDoc) return;

			if (highlightDoc.timestamp === 0) {
				this.highlightTranscriptPiece(highlightDoc);
			} else {
				this.$rootScope.safeApply(() => { this.highlightTranscriptPiece(highlightDoc); });
			}
		};
	}

	downloadDocumentFile(descriptor, exportCallback): void {
		let url = this.redirectService.getRedirectionUrlWithParams(
			RedirectDestinationValues.DOWNLOAD_FILE, {descriptor});

		this.exportApiService.downloadVociFile(url).then((response) => {
			let headers = response.headers;
			let data = response.data;
			let filename = headers('file-name');
			exportCallback(filename, data);
		});
	}

	private isChatMessage = (sentenceObject: ConversationSentence | ChatMessage): sentenceObject is ChatMessage => {
		return _.has(sentenceObject, 'sentences');
	}

	private isAudioDocument = (): boolean => this.document?.voiceMetrics && !this.document.isChat;

	allowAudioPlayback = (): boolean =>
		this.conversationFilter !== 'other'
			&& !this.isAudioFileExpired
			&& this.isAudioDocument()

	allowAudioDownload = (): boolean => {
		return this.isAudioAvailable()
			&& this.allowAudioPlayback()
			&& this.security.has('download_audio_file');
	}

	allowTranscriptDownload = (): boolean => {
		return this.isTranscriptAvailable()
			&& this.isAudioAvailable()			// temporary. Currently we don't have a way to know if the transcript is available
			&& this.security.has('download_transcript_file');
	}

	resumeAutoScrolling = () =>  {
		this.autoScrollActive = true;
		this.highlightOnSeek();
	}

	showResumeScrolling = (): boolean => !this.autoScrollActive && this.isAudioPlayable() && this.isAudioPlaying;

	showMetrics = (): boolean => this.isAudioDocument() && (this.hasAgentSentences() || this.hasClientSentences());

	getSilencePercent = (): string => {
		let silencePercent = this.document.voiceMetrics?.silence?.percent || 0;
		return silencePercent.toFixed(1) + '%';
	}

	getAgentPercent = (): string => {
		let agentPercent = this.document.voiceMetrics?.channels?.agent?.percent || 0;
		return agentPercent.toFixed(1) + '%';
	}

	getLongestSilence = (): string => {
		let maxSilence = this.document.voiceMetrics?.silence?.max || 0;
		let timeUnit = DocumentTypeUtils.isRVFVoiceDocument(this.document) ? TimeUnit.MILLISECONDS : TimeUnit.SECONDS;
		return this.formatSilenceText(maxSilence, timeUnit, 'docExplorer.silenceMetric');
	}

	scrollToLongestSilence = (): void => {
		if (!this.document || !this.document.sentences || !this.document.voiceMetrics.silence.sentenceIdBeforeMax) {
			return;
		}

		let sentence: any = _.findWhere(this.document.sentences, {id: this.document.voiceMetrics.silence.sentenceIdBeforeMax});
		this.goToTime(sentence.timestamp);
	}

	setPlaybackRate = (rate: number): void => {
		if ((rate >= this.MIN_PLAYBACK_RATE) && (rate <= this.MAX_PLAYBACK_RATE)) {
			this.audioPlayer.playbackRate(rate);
		}
	}

	increasePlaybackRate = (event: Event): void => {
		event.stopPropagation();
		if (this.isRateIncreaseEnabled()) {
			this.uiOptions.playbackRate += this.PLAYBACK_RATE_INCREMENT;
			this.setPlaybackRate(this.uiOptions.playbackRate);
			this.persistPreferences();
		}
	}

	decreasePlaybackRate = (event: Event): void => {
		event.stopPropagation();
		if (this.isRateDecreaseEnabled()) {
			this.uiOptions.playbackRate -= this.PLAYBACK_RATE_INCREMENT;
			this.setPlaybackRate(this.uiOptions.playbackRate);
			this.persistPreferences();
		}
	}

	changePlaybackRateKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			this.increasePlaybackRate(event);
		} else if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
			this.decreasePlaybackRate(event);
		} else if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	isRateDecreaseEnabled = (): boolean => this.uiOptions.playbackRate > this.MIN_PLAYBACK_RATE;

	isRateIncreaseEnabled = (): boolean => this.uiOptions.playbackRate < this.MAX_PLAYBACK_RATE;

	showAudioOutOfDateRange = (): boolean => this.isAudioFileExpired && this.isAudioDocument();

	showAudioUnavailable = (): boolean => !this.isAudioFileExpired && !this.isAudioAvailable() && this.isAudioDocument();

	private isAudioPlayable = (): boolean => this.isAudioAvailable() && this.allowAudioPlayback();

	getConversationStaticClasses = (sentenceObject: ConversationDataPoint | ChatMessage): string => {
		// these classes should not change after rendering
		let classes = [];
		if (this.isClient(sentenceObject)) classes.push('client-transcript');
		if (this.isAgent(sentenceObject)) classes.push('agent-transcript');
		if (this.isBot(sentenceObject)) classes.push('bot-transcript');
		if (this.isUnknown(sentenceObject)) classes.push('unknown-transcript');
		if ((sentenceObject as ConversationSentence).isSameChannelAsLast) classes.push('same-channel-as-last');
		if ((sentenceObject as ConversationDataPoint).isOvertalk) classes.push('is-overtalk');
		if ((sentenceObject as ConversationDataPoint).isOvertalkStart) classes.push('is-overtalk-start');
		if ((sentenceObject as ConversationDataPoint).isOvertalkEnd) classes.push('is-overtalk-end');

		// transcript-line-X classes are used as selectors in multiple places
		if ((sentenceObject as ChatMessage).groupedIds?.length) {
			classes.push((sentenceObject as ChatMessage).groupedIds.map(this.getSentenceLineClass).join(' '));
		} else {
			classes.push(`${this.getSentenceLineClass((sentenceObject as ConversationSentence).id)}`);
		}
		classes.pushAll(this.getParticipantClasses(sentenceObject));
		return classes.join(' ');
	}

	private getParticipantClasses = (sentenceObject: ConversationDataPoint | ChatMessage): string[] => {
		let classes = [];
		let participant = ConversationChannelService.getParticipant(this.getFirstSentence(sentenceObject));
		let prefix = ConversationStyleUtils.CONVERSATION_PREFIX;

		let participantClass = participant.id !== -1
			? `${prefix}${participant.type}-${participant.id}`
			: `${prefix}${sentenceObject.channel}--1`;
		classes.push(participantClass);

		let hiddenParticipantClasses = this.getHiddenParticipantClasses();
		if (hiddenParticipantClasses.contains(participantClass)) {
			classes.push(this.HIDDEN_TRANSCRIPT_CLASS);
		}

		return classes;
	}

	private isHiddenSentence = (hiddenParticipantClasses: string[], sentenceObject): boolean => {
		let participant = ConversationChannelService.getParticipant(this.getFirstSentence(sentenceObject));
		let participantClass = this.getParticipantClass(participant, sentenceObject);
		return hiddenParticipantClasses.contains(participantClass);
	}

	private getParticipantClass = (participant, sentenceObject): string => {
		let prefix = ConversationStyleUtils.CONVERSATION_PREFIX;
		return participant.id !== -1
			? `${prefix}${participant.type}-${participant.id}`
			: `${prefix}${sentenceObject.channel}--1`;
	}

	private getFirstSentence(sentenceObject: ConversationDataPoint | ChatMessage): ConversationSentence {
		return this.isChatMessage(sentenceObject) ? sentenceObject.sentences[0] : sentenceObject;
	}

	private getSimpleSpeakerEnrichment(name: string, icon: string): ConversationHeader {
		return {
			getName: () => name,
			getHeaderIcon: () => icon
		};
	}

	private populateChannelEnrichments(participantEnrichments: Partial<ParticipantEnrichments>,
			customLabels: Partial<ConversationChannelLabels> = {}): void {
		participantEnrichments.botDetection =
			this.getSimpleSpeakerEnrichment(customLabels[ChannelTypes.BOT] || this.locale.getString('docExplorer.botLabel'), 'q-icon-robot');
		participantEnrichments.agentDetection =
			this.getSimpleSpeakerEnrichment(customLabels[ChannelTypes.AGENT] || this.locale.getString('docExplorer.agentLabel'), 'q-icon-conversation-agent');
		participantEnrichments.clientDetection =
			this.getSimpleSpeakerEnrichment(customLabels[ChannelTypes.CLIENT] || this.locale.getString('docExplorer.clientLabel'), 'q-icon-conversation-customer');
		participantEnrichments.unknownDetection =
			this.getSimpleSpeakerEnrichment(customLabels[ChannelTypes.UNKNOWN] || this.locale.getString('docExplorer.unknownLabel'), 'q-icon-help');
	}

	private getSingleLaneEnrichments(accountConfig: IAccountLevelSpineSettings): ConversationEnrichment[] {
		return accountConfig.singleLanes.map(lane => {
			if (lane.definition.name === SingleLaneEnrichmentTypes.SCORECARD) {
				let isBetaFeaturesEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);

				if (!isBetaFeaturesEnabled || _.isEmpty(this.document.scorecards) || _.isEmpty(this.documentManager.availableScorecards)) {
					return null;
				}

				let docScorecard = _.findWhere(this.document.scorecards, {id: this.selectedScorecard});
				let scorecardName = docScorecard?.name || this.locale.getString('widget.na');
				return ScorecardTrack.generateScorecardEnrichment(scorecardName, this.selectedScorecard,
					this.openScorecardMenu);

			} else if (lane.definition.name === SingleLaneEnrichmentTypes.SENTENCE_TYPE) {
				return ReasonTrack.generateReasonDetectionEnrichment(this.locale.getString('appearance.cbRecommended'));
			} else if (lane.definition.laneType === SpineLaneType.TOPIC) {
				let definition = lane.definition as TopicSpineLaneDefinition;
				let topic = _.findWhere(this.document.classification, { modelId: parseInt(definition.name, 10)});
				if (topic) {
					return TopicTrack.generateTopicEnrichment(definition, topic);
				} else {
					return null;
				}
			} else {
				let enrichment: ConversationEnrichment;
				if (lane.definition.name.startsWith('cb_')) {
					enrichment = this.conversationEnrichmentUtils.getNLPEnrichment(lane,
						this.worldAwareness, this.document.attributes);
				} else {
					enrichment = this.conversationEnrichmentUtils.getMetricEnrichment(lane, this.predefinedMetrics);
					if (!enrichment) // e.g. metric under beta
						return null;
				}
				enrichment.iconType = this.getAttributeIcon(lane);
				enrichment.enrichmentType = lane.definition.name as SingleLaneEnrichmentTypes;
				return enrichment;
			}
		}).filter(lane => !!lane);
	}

	private getParticipantEnrichments(accountConfig: IAccountLevelSpineSettings): ParticipantEnrichments {
		let result: ParticipantEnrichments = {} as ParticipantEnrichments;
		result.overtalk = this.conversationEnrichmentUtils.generateOvertalkEnrichment() as ConversationEnrichment;

		if (accountConfig.participantDefinition.definition.name === PredefinedMetricConstants.SENTIMENT) {
			accountConfig.participantDefinition.definition.inclusionList = [
				'Neg_Strong_Sent', 'Neg_Sent', 'Positive_Sent', 'Positive_Strong_Sent'];
		}
		result.primary = this.conversationEnrichmentUtils.getMetricEnrichment(accountConfig.participantDefinition,
			this.predefinedMetrics);
		return result;
	}

	private getAttributeIcon(lane: SpineLane): SecondaryTrackRendererType {
		if (lane.definition.type === SpineLaneStyle.emoticon) {
			return SecondaryTrackRendererType.EMOTICON;
		}

		return lane.definition.icon as SecondaryTrackRendererType;
	}

	private openScorecardMenu = (event: MouseEvent): void => {
		let css = ['metric-identifier', 'text-ellipsis'];

		let availableScorecards = this.documentManager.availableScorecards
			? this.documentManager.availableScorecards
			: this.document.scorecards;

		let options: ContextMenuItem<Scorecard>[] = _.map(availableScorecards, scorecard => {
			let iconClass = this.selectedScorecard === scorecard.id ?
				'q-icon-check' :
				'q-icon-circle';

			return {
				name: scorecard.id,
				text: scorecard.name,
				classes: css,
				formattedOptionName: `
				<div class="d-flex align-items-center">
					<span class="mr-4 q-icon ${iconClass}" aria-hidden="true"></span> ${this.$sanitize(scorecard.name)}
				</div>`,
				func: () => {
					this.onSelectScorecard({$scorecardId: scorecard.id});
				}
			};
		});
		this.contextMenuTree.showScorecardSelectMenu(event, null, options);
	}

	chartFullyInitialized(): boolean {
		return !!this.conversationChart;
	}

	getVisibilityClasses = (): any => {
		let showingFakeScroll = this.isPartialRenderingEnabled() && this.isShowingFakeScroll();

		let result = {
			'use-pills': true,
			'hide-sentiment-formatting': !this.showSentiment,
			'hide-sentence-text': !this.uiOptions.showSentences,
			'hide-ease': !this.singleLaneEnrichments?.contains(SingleLaneEnrichmentTypes.EFFORT),
			'hide-topics': !this.uiOptions.showTopics,
			'audio-playing': this.isAudioPlaying,
			'hide-scroll': showingFakeScroll
		};
		if (this.selectedScorecard)
			result[`show-scorecard-${this.selectedScorecard}`] = this.showSentiment;
		return result;
	}

	private topicsSelectionActive(): boolean {
		return !_.isUndefined(this.lastHighlightedModel);
	}
	showTopicsBelowIndicator = (): boolean => {
		return this.topicsSelectionActive() && this.countTopicsBelow > 0;
	}
	showTopicsAboveIndicator = (): boolean => {
		return this.topicsSelectionActive() && this.countTopicsAbove > 0;
	}

	private generateTopicPositionMap = (scrollElement: HTMLElement): void => {
		let topicSelectorClass = '.topic-row-' + this.lastHighlightedModel.id;
		let spineRowsWithTopic = angular.element(scrollElement).find(topicSelectorClass);

		this.topicPositionMap = {rowTop: [], rowBottom: []};

		_.each(spineRowsWithTopic, (spineRow: HTMLElement) => {
			let rowTop = parseFloat($(spineRow).attr('y'));
			this.topicPositionMap.rowTop.push(rowTop);
			this.topicPositionMap.rowBottom.push(rowTop + $(spineRow).height());
		});
	}

	updateSpineScrollIndicators = (scrollElement: HTMLElement): void => {
		let countTopicsAbove = 0;
		let countTopicsBelow = 0;
		if (_.isUndefined(scrollElement)) {
			return;
		}

		if (_.isUndefined(this.lastHighlightedModel)) {
			delete this.topicPositionMap;
			return;
		}

		if (_.isUndefined(this.topicPositionMap)) {
			this.generateTopicPositionMap(scrollElement);
		}
		let visibleTop: number = scrollElement.scrollTop;
		let visibleBottom: number = visibleTop + scrollElement.offsetHeight;

		countTopicsAbove = _.filter(this.topicPositionMap.rowBottom, (rowBottom) => rowBottom <= visibleTop).length;
		countTopicsBelow = _.filter(this.topicPositionMap.rowTop, (rowTop) => rowTop >= visibleBottom).length;

		this.countTopicsBelow = countTopicsBelow;
		this.countTopicsAbove = countTopicsAbove;
		this.updateCountMessages();
	}

	hideSpineScrollIndicators = (): void => {
		this.countTopicsBelow = 0;
		this.countTopicsAbove = 0;
		this.updateCountMessages();
	}

	getLanesCountClass = (): string | undefined => {
		if (this.singleLaneEnrichments !== undefined) {
			return `secondary-tracks-${this.singleLaneEnrichments.length}`;
		}
	}

	updateCountMessages = (): void => {
		if (_.isUndefined(this.conversationChart)) {
			return;
		}
		this.aboveTopicsCount = {
			show: this.showTopicsAboveIndicator(),
			message: this.getMoreTopicsMessage(this.countTopicsAbove)
		};
		this.belowTopicsCount = {
			show: this.showTopicsBelowIndicator(),
			message: this.getMoreTopicsMessage(this.countTopicsBelow)
		};
	}

	getMoreTopicsMessage = (count: number): string => {
		return count > 1
			? this.locale.getString('docExplorer.moreTopicsMultiple', {count})
			: this.locale.getString('docExplorer.moreTopicsSingle');
	}

	topicRemovalHandler = (sentenceId: number, topic: string): void => {
		this.suggestionMenu.suggestTopicRemoval({sentenceId, topic}, this.documentManager);
	}

	tuneEnrichment = (sentence: PreviewSentence, pill: Pill, event: MouseEvent): void => {
		this.suggestionMenu.openSuggestionMenuFromDocumentPane(event, sentence, this.documentManager,
			this.uiOptions.leafOnly, pill);
	}

	hideAdditionalVerbatim = () => {
		this.conversationFilter = '';
	}

	showAdditionalVerbatim = () => {
		this.conversationFilter = 'other';
	}

	getMessageWidth = (): number => {
		return this.$element.find('.sentence-text-container:visible').first().outerWidth();
	}
}

app.component('conversation', {
	bindings: {
		uniquePrefix: '<',
		document: '<',
		showSentiment: '<',
		properties: '<',
		selectedSentence: '<',
		uiOptions: '<',
		savePreferences: '&',
		selectedScorecard: '<',
		onSelectScorecard: '&',
		selectedModelsFilter: '<',
		playbackRate: '<',
		widgetId: '<',
		isDocExplorer: '<',
		metricFormatters: '<',
		worldAwareness: '<',
		auditMode: '<',
		auditModeModels: '<',
		translate: '<',
		documentManager: '<',
	},
	templateUrl: 'partials/document-explorer/conversation.component.html',
	controller: ConversationComponent,
});
