import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BasicWidgetDescriptionBuilder, WidgetDescriptionBuilder } from './basic-widget-description-builder';
import { BarDescriptionBuilder } from './bar-description-builder';
import { WidgetDescriptionUtils } from './widget-description-utils';
import { LineDescriptionBuilder } from './line-description-builder';
import { CloudDescriptionBuilder } from './cloud-description-builder';
import { PieDescriptionBuilder } from './pie-description-builder';
import { ScatterDescriptionBuilder } from './scatter-description-builder';
import { HeatmapDescriptionBuilder } from './heatmap-description-builder';
import { TableDescriptionBuilder } from './table-description-builder';
import { PreviewDescriptionBuilder } from './preview-description-builder';
import { MapDescriptionBuilder } from './map-description-builder';
import { MetricDescriptionBuilder } from './metric-description-builder';
import { ModelDescriptionBuilder } from './model-description-builder';
import { NetworkDescriptionBuilder } from './network-description-builder';
import { RubricDescriptionBuilder } from './rubric-description-builder';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { GaugeDescriptionBuilder } from './gauge-description-builder';

@Injectable({
	providedIn: 'root'
})
export class WidgetDescriptionService {
	constructor(
		private readonly utils: WidgetDescriptionUtils,
	) { }

	generateDescription(widget: Widget, data?: any): Promise<string> {
		let builder = this.getBuilder(widget);
		return Promise.resolve(builder.getDescription(data));
	}

	generateWidgetAriaLabel(widget: Widget, data?: any): Promise<string> {
		let builder = this.getBuilder(widget);
		return builder.getAriaLabel(widget, data);
	}

	private getBuilder(widget: Widget): WidgetDescriptionBuilder {
		if (widget.visualProperties.visualization === WidgetVisualization.GAUGE) {
			return new GaugeDescriptionBuilder(widget, this.utils);
		}

		switch (widget.properties.widgetType) {
			case WidgetType.BAR:
				return new BarDescriptionBuilder(widget, this.utils);
			case WidgetType.LINE:
				return new LineDescriptionBuilder(widget, this.utils);
			case WidgetType.CLOUD:
				return new CloudDescriptionBuilder(widget, this.utils);
			case WidgetType.PIE:
				return new PieDescriptionBuilder(widget, this.utils);
			case WidgetType.SCATTER:
				return new ScatterDescriptionBuilder(widget, this.utils);
			case WidgetType.HEATMAP:
				return new HeatmapDescriptionBuilder(widget, this.utils);
			case WidgetType.TABLE:
				return new TableDescriptionBuilder(widget, this.utils);
			case WidgetType.PREVIEW:
				return new PreviewDescriptionBuilder(widget, this.utils);
			case WidgetType.METRIC:
				return new MetricDescriptionBuilder(widget, this.utils);
			case WidgetType.MAP:
				return new MapDescriptionBuilder(widget, this.utils);
			case WidgetType.NETWORK:
				return new NetworkDescriptionBuilder(widget, this.utils);
			case WidgetType.OBJECT_VIEWER:
				return new RubricDescriptionBuilder(widget, this.utils);
			case WidgetType.MODEL_VIEWER:
				return new ModelDescriptionBuilder(widget, this.utils);
			default:
				return new BasicWidgetDescriptionBuilder(widget, this.utils);
		}
	}

}

app.service('widgetDescriptionService', downgradeInjectable(WidgetDescriptionService));
