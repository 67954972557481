import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DocumentLinkService } from '@cxstudio/reports/document-explorer/document-link.service';

export class GlobalHeaderComponent implements ng.IComponentController {

	globalWarning;

	constructor(
		private globalNotificationService: GlobalNotificationService,
		private locale: ILocale,
		private documentLinkService: DocumentLinkService,
	) {}

	$onInit = () => {
		this.documentLinkService.processLocationAttributes();
	}

	getGlobalWarningMessage = (): string => this.globalWarning.visible && this.globalWarning.message.content;

	errorMsgCopied = (): void => {
		if (this.globalWarning.message.isError()) {
			this.globalNotificationService.addSuccessNotification(this.locale.getString('common.copied'));
		}
	}
}

app.component('globalHeader', {
	bindings: {
		globalWarning: '<'
	},
	controller: GlobalHeaderComponent,
	templateUrl: 'partials/header/global-header.component.html'
});
