import { HierarchyEnrichmentProperty } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyPublicationState } from './hierarchy-publication-state';
import { HierarchyMode } from './hierarchy-mode';

export interface HierarchyProject {
	contentProviderId: number;
	projectId: number;
	workspaceId?: number;
	projectName?: string;
}

export default class Hierarchy {
	id?: number;
	name?: string;
	hierarchyMode?: HierarchyMode;

	publicationState?: HierarchyPublicationState;
	projects?: HierarchyProject[];

	structureFileName?: string;
	filterFileName?: string;
	userFileName?: string;
	enrichmentFileName?: string;
	active?: boolean;
	originalHierarchyId?: number;
	editable?: boolean;
	hasUpdate?: boolean;
	hasUpdateReady?: boolean;
	hierarchicalFiltering?: boolean;
	skipNonExistUsers?: boolean;
	reportableEnrichmentProperties?: HierarchyEnrichmentProperty[];

	updateStructureFileName?: string;
	updateFilterFileName?: string;
	updateUserFileName?: string;
	updateEnrichmentFileName?: string;
	groupsProcessingInProgress?: boolean;
}
