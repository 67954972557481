import { ChangeDetectionStrategy, Component, Inject, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { HierarchyUploadWizardPageBaseComponent } from '../hierarchy-upload-wizard-base.component';
import { FileUploadResult } from '../../upload/upload-result/file-upload-result';
import { WizardTagMode } from '@app/modules/wizard/cx-wizard-tag/cx-wizard-tag.component';
import { CxLocaleService } from '@app/core';
import { FileUploadFailureDetails } from '@app/modules/cx-form/file-upload/file-upload.component';
import { ChangeUtils } from '@app/util/change-utils';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';


@Component({
	selector: 'hierarchy-users-wizard-page',
	templateUrl: './hierarchy-users-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyUsersWizardPageComponent extends HierarchyUploadWizardPageBaseComponent implements OnChanges {

	skipNonExistUsers: boolean = false;
	generateGroups: boolean = false;
	private hasChanges: boolean = false;

	customUploadFailedMessage = this.getDefaultUploadFailedMessage();
	userNonExistence: boolean = false;
	userNonExistenceList: any[];

	constructor(
		ref: ChangeDetectorRef,
		protected locale: CxLocaleService,
		private organizationApiService: OrganizationApiService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('urlService') private urlService: UrlService,
	) {
		super (ref, locale);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.hierarchy)) {
			this.tagChange.emit(this.getStepStatus());
			this.skipNonExistUsers = this.hierarchy?.skipNonExistUsers;
		}
	}

	private getDefaultUploadFailedMessage(): string {
		return this.locale.getString('organization.wizardFileUploadFailed');
	}

	getFileName(): string {
		return this.hierarchy.userFileName;
	}

	getUpdateFileName(): string {
		return this.hierarchy.updateUserFileName;
	}

	downloadDataFile(): void {
		this.loading = this.organizationApiService.getHierarchyUserData(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	downloadTemplateFile(): void {
		this.loading = this.organizationApiService.getHierarchyTemplate(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	getFileUploadUrl(): string {
		return this.urlService.getAPIUrl(
			`rest/organization/users/file/upload/${this.hierarchy.id}`);
	}

	getFileUploadMetadata(): { generateGroups: boolean, skipNonExistUsers: boolean } {
		return {
			generateGroups: !!this.generateGroups,
			skipNonExistUsers: !!this.skipNonExistUsers
		};
	}

	getStepStatus(): WizardTagMode {
		if (!this.hierarchy) {
			return;
		}

		if (this.firstTimeHierarchyEdit()) {
			return this.hasChanges? WizardTagMode.COMPLETED : WizardTagMode.NO_CHANGES;
		}

		if (!this.hierarchy.hasUpdate) {
			if (this.getFileName()) {
				return WizardTagMode.COMPLETED;
			} else {
				return WizardTagMode.UPDATE_REQIURED;
			}
		} else {
			if (this.getUpdateFileName()) {
				return WizardTagMode.COMPLETED;
			} else {
				return WizardTagMode.UPDATE_REQIURED;
			}
		}
	}

	handleFailedUpload(event: FileUploadFailureDetails): void {
		this.uploadResult = FileUploadResult.failed(event.headers, event.response, this.locale);
		if (event?.response?.errorCode === 'userNonExistence') {
			this.customUploadFailedMessage = this.locale.getString('organization.userNonExistence');
			this.userNonExistence = true;
			this.userNonExistenceList = event.response.userNonExistenceList;
		}
		this.tagChange.emit(WizardTagMode.ERROR);
		this.ref.detectChanges();
	}

	handleRemovedUpload(): void {
		this.uploadResult = FileUploadResult.empty();
		this.userNonExistence = false;
		this.customUploadFailedMessage = this.getDefaultUploadFailedMessage();
		this.tagChange.emit(this.getStepStatus());
		this.ref.detectChanges();
	}

	handleSuccessfulUpload(event: any): void {
		// updating users does not create an update (pending changes)
		// need to set "completed" tag separately
		this.uploadResult = FileUploadResult.empty();
		this.hierarchyUpdated.emit();
		this.hasChanges = true;
		this.userNonExistence = false;
		this.customUploadFailedMessage = this.getDefaultUploadFailedMessage();
		this.tagChange.emit(WizardTagMode.COMPLETED);
		this.ref.detectChanges();
	}

	getUsersForNode(node: any): string {
		return node.users.join(', ');
	}

	isDynamicFilteringHierarchy = () => {
		return HierarchyMode.DYNAMIC_FILTERING === this.hierarchy?.hierarchyMode;
	}

}

