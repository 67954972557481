import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class CloudDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let selections = this.visual.attributeSelections;
		let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		let grouping = new Grouping(selectedAttribute?.displayName,
			this.utils.getGroupingType(selectedAttribute?.type));
		let chartName = this.locale.getString('widgetDescription.cloud');
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('cloudPalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.cloudDescription', {
			chartName,
			calculation: selections?.size?.displayName,
			grouping,
			total,
			palette
		});
		let topItems = this.utils.getTopItems(data, selectedAttribute, selections?.size?.name);
		if (topItems.length >= 3) {
			let topWords = this.locale.getString('widgetDescription.topWords', {
				word1: topItems[0],
				word2: topItems[1],
				word3: topItems[2]
			});
			description = this.utils.joinMessageParts([description, topWords]);
		}
		return description;
	}

}
