import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { DashboardListTabsEditorComponent } from '@app/modules/home-page/home-page-management/dashboard-list-tabs-editor/dashboard-list-tabs-editor.component';
import { HomePageGridDefinition } from '@app/modules/home-page/home-page-management/home-page-grid-definition.service';
import { SharedModule } from '@app/shared/shared.module';
import { HomePageManagementComponent } from './home-page-management/home-page-management.component';
import { HomePageEditComponent } from './home-page-edit/home-page-edit.component';
import { HomeGeneralSettingsComponent } from './home-page-edit/home-general-settings/home-general-settings.component';
import { HomeDashboardCarouselComponent } from './home-page-edit/home-dashboard-carousel/home-dashboard-carousel.component';
import { HomeQuickInsightsComponent } from './home-page-edit/home-quick-insights-settings/home-quick-insights-settings.component';
import { HomePageSharingComponent } from './home-page-sharing/home-page-sharing.component';
import { AssetSharingModule } from '@app/modules/asset-sharing/asset-sharing.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { HomeReportingSettingsComponent } from './home-page-edit/home-general-settings/home-reporting-settings/home-reporting-settings.component';
import { HomePageWidgetsConfigComponent } from './home-page-edit/home-page-widgets-config/home-page-widgets-config.component';
import { WidgetPlaceholderComponent } from './home-page-edit/home-page-widgets-config/widget-placeholder/widget-placeholder.component';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { HomePageCommonModule } from '@app/modules/home-page/home-page-common/home-page-common.module';
import { UnitsModule } from '@app/modules/units/units.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';

@NgModule({
	imports: [
		SharedModule,
		DragDropModule,
		AssetSharingModule,
		ItemGridModule,
		WidgetContainerModule,
		HomePageCommonModule,
		UnitsModule,
		DialogModule,
	],
	exports: [
		DashboardListTabsEditorComponent,
		HomePageManagementComponent,
	],
	declarations: [
		DashboardListTabsEditorComponent,
		HomePageManagementComponent,
		HomePageEditComponent,
		HomeGeneralSettingsComponent,
		HomeDashboardCarouselComponent,
		HomeQuickInsightsComponent,
		HomePageSharingComponent,
		HomeReportingSettingsComponent,
		HomePageWidgetsConfigComponent,
		WidgetPlaceholderComponent,
	],
	providers: [
		HomePageGridDefinition,
	],
})
export class HomePageManagementModule { }
