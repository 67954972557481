
import * as _ from 'underscore';
import * as uib from 'angular-ui-bootstrap';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import WidgetSettingsService, { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';

export default abstract class AssetSelectorComponentController implements ng.IComponentController {

	asset: any;
	projectIdentifier: ProjectIdentifier;
	onAssetChange: (change: { $asset: any }) => void;
	onAssetClear: () => void;
	onUpdateSortBy: (change: { $sortBy: any }) => void;
	showLabel: boolean;
	screenIndex?: number;
	private hideCapitalizationOption: boolean;

	mainOptions: any;
	additionalMetrics;
	linkedGrouping?: ReportGrouping;
	private standardMetrics;
	private widgetSettingsCache: CachedInvocation;

	constructor(
		private $scope: ng.IScope,
		private widgetSettingsService: WidgetSettingsService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private $uibModal: uib.IModalService,
		private tagService: TagsService,

		private cbSettingsService,
		private reportMetricService: ReportMetricService,
		private grouping: boolean
	) { }

	$onInit(): void {
		this.initializeStandardMetrics();
		this.reloadOptions(this.projectIdentifier);

		this.$scope.$on('settings.projectChanged', this.onProjectChanged);
		this.updateAsset();
		this.init();
	}

	protected abstract init(): void;

	private onProjectChanged = (event, projectSelection) => {
		let promise = this.reloadOptions(projectSelection);
		if (this.asset && promise) {
			promise.then(() => {
				let nodes = this.getLeafNodes(this.grouping ? this.mainOptions : this.additionalMetrics);
				this.asset = _.findWhere(nodes, {name: this.asset.name}) || null;
				this.updateAsset();
			});
		}
	}

	private getLeafNodes = (parentNodes: any[]): any[] => {
		let result = [];
		parentNodes.forEach(node => {
			if (node.children === undefined) {
				result.push(node);
			} else {
				result.push(...this.getLeafNodes(node.children));
			}
		});
		return result;
	}

	isShowingLabel(): boolean {
		return !!this.showLabel;
	}

	updateAsset(): void {
		this.onAssetChange({ $asset: this.asset });
	}

	clearAsset(): void {
		if (this.onAssetClear) {
			this.onAssetClear();
		}
	}

	private initializeStandardMetrics = (): void => {
		this.standardMetrics = this.reportMetricService.getStandardMetrics();
	}

	private reloadOptions = (projectSelection: IProjectSelection | ProjectIdentifier): any => {
		if (ProjectIdentifier.isProjectSelected(projectSelection)) {
			let widgetProperties = this.propertiesForProjectSelection(projectSelection);

			let getWidgetSettings = this.widgetSettingsService.getWidgetSettings;
			if (this.widgetSettingsCache) {
				getWidgetSettings = this.widgetSettingsCache.get(getWidgetSettings);
			}

			return getWidgetSettings(widgetProperties)
				.then(settings => this.populateOptions(projectSelection, settings));
		}
	}

	private propertiesForProjectSelection = (projectSelection: IProjectSelection | ProjectIdentifier): WidgetProperties => {
		return {
			contentProviderId: projectSelection.contentProviderId,
			accountId: projectSelection.accountId,
			project: projectSelection.projectId,
			selectedAttributes: this.linkedGrouping ? [this.linkedGrouping] : []
		};
	}

	private populateOptions = (projectSelection: IProjectSelection | ProjectIdentifier, settings: IWidgetSettings) => {
		this.mainOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
			.withModels(settings.models)
			.withTime(settings.attributes)
			.withAttributes(settings.attributes, MetricFilters.GROUP_FILTER)
			.withWordAttributes(settings.wordAttributes)
			.withMetrics(settings.metrics, projectSelection.projectId)
			.withOrgHierarchyModels(settings.models, settings.hierarchyModels)
			.withPredefinedGroups(angular.copy(settings.predefinedMetrics))
			.build();
		this.cbSettingsService.initDefaultProperties(this.mainOptions);

		let hierarchyId;
		if (this.linkedGrouping) {
			hierarchyId = parseInt(this.linkedGrouping.name, 10);
		}
		this.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(angular.copy(this.standardMetrics))
			.withPredefinedMetrics(settings.predefinedMetrics)
			.withAttributes(settings.attributes, MetricFilters.CALCULATION)
			.withMetrics(settings.metrics, this.projectIdentifier.projectId)
			.withOrgHierarchyMetrics(hierarchyId, settings.organizationCalculations)
			.build();
	}

	configure = (configItem, listType, configurationOptions): ng.IPromise<void> => {
		let config = this.$uibModal.open({
			templateUrl: 'partials/widgets/settings/cb/definitions/metric-configuration-modal.html',
			controller: 'widgetMetricConfigController',
			backdrop: 'static',
			resolve: {
				modalSettings: () => {
					let metricConfiguration = this.getMetricConfiguration(configItem);
					let properties: WidgetProperties = {
						contentProviderId: this.projectIdentifier.contentProviderId,
						accountId: this.projectIdentifier.accountId,
						project: this.projectIdentifier.projectId
					};

					return {
						configItem,
						listType,
						sortOptions: angular.copy(this.additionalMetrics),
						props: properties,
						configurationOptions,
						metric: metricConfiguration.metric,
						studioMetrics: metricConfiguration.studioMetrics,
						mobile: true,
						hideCapitalizationOption: this.hideCapitalizationOption,
						showUpdateInclusionFilterCheckbox: this.screenIndex && this.screenIndex > 0,
						isWidgetAssetConfig: false
					};
				}
			}
		});

		return config.result.then((result) => {
			if (result.wordsList) {
				let wordsSelection = this.tagService.tagObjectsToStringArray(result.wordsList);
				result.wordsList = wordsSelection;
			}

			configItem = $.extend(configItem, result);

			// some properties may be deleted after upgrade
			['dataType', 'decimalDelimiter', 'thousandsDelimiter'].map(deletedProp => {
				if (!result[deletedProp])
					delete configItem[deletedProp];
			});

			this.updateAsset();

			let sortBy: string = this.asset.sortBy;
			if (sortBy) {
				let sortCalculation = SearchableHierarchyUtils.deepSearchByName(this.additionalMetrics, sortBy);
				if (sortCalculation && this.onUpdateSortBy) {
					this.onUpdateSortBy({$sortBy: sortCalculation});
				}
			}
		});
	}

	private getMetricConfiguration = (configItem): { metric: any, studioMetrics: any } => {
		let metric;
		let metrics: { children: any } = _.find(this.additionalMetrics, { name: 'metrics' });

		if (configItem.definition) {
			if (metrics) {
				metric = SearchableHierarchyUtils.deepSearchByNameAndType(
					metrics.children, configItem.name, configItem.type);
			}
		}
		let studioMetrics = metrics && metrics.children;

		return {
			metric,
			studioMetrics
		};
	}

}
