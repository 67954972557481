import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core/cx-http.service';
import { HttpParams } from '@angular/common/http';
import { ConcurrentEditItem } from '@app/modules/dashboard/concurrent-edit-alert/concurrent-edit-alert.component';

@Injectable({
	providedIn: 'root'
})
export class ConcurrentEditApiService {

	constructor(private cxHttp: CxHttpService) { }

	markEdited(dashboardId: number, editingUniqueId: string, initialEdit?: boolean): Promise<void> {
		let params = initialEdit ? new HttpParams().append('initialEdit', initialEdit + '') : undefined;
		return this.cxHttp.put(`rest/dashboard/${dashboardId}/edit-mark/${editingUniqueId}`, null, { params });
	}

	unmarkEdited(dashboardId: number, editingUniqueId: string): Promise<void> {
		return this.cxHttp.delete(`rest/dashboard/${dashboardId}/edit-mark/${editingUniqueId}`);
	}

	getConcurrentEditInfo(dashboardId: number, editingUniqueId: string): Promise<ConcurrentEditItem[]> {
		let params = new HttpParams().append('editingUniqueId', editingUniqueId);
		return this.cxHttp.get(`rest/dashboard/${dashboardId}/concurrent-edit-info`, {params});
	}

}
