import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';


@Injectable({providedIn: 'root'})
export class NumberAdapter implements CustomMathExpressionAdapter {
	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let textToken: TextToken = {
			text: token.text, 
			type: TextTokenType.NUMBER
		}; 
		let display = token.value;
		return {
			text: token.text,
			startOffset: token.offset,
			textTokens: [textToken],
			expression: new ExpressionItem(ExpressionPieces.NUMBER, display, token.value)
		};
	}
	
	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		return expression.value;
	}
}