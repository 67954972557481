<cx-wizard
	class="data-overview-dialog"
	header="{{'packages.runPackage' | i18n}}"
	finishText="{{'common.create' | i18n}}"
	[loadingPromise]="loading"
	[activeStepIndex]="currentStep"
	[mode]="mode"
	(onFinish)="save()"
	(onCancel)="cancel()">

	<cx-wizard-step>
		<data-overview-content-provider-step
			[(projectSelection)]="projectSelection"
		>
		</data-overview-content-provider-step>
	</cx-wizard-step>

	<cx-wizard-step>
		<data-overview-settings-step
			[projectSelection]="projectSelection"
			[fixedAdditionalMetrics]="fixedAdditionalMetrics"
			[(grouping)]="grouping"
			[(calculation)]="calculation"
			[(additionalMetrics)]="additionalMetrics"
			(onSettingsLoaded)="widgetAssetsLoaded($event)"
		>
		</data-overview-settings-step>
	</cx-wizard-step>
</cx-wizard>
