import { Component, ChangeDetectionStrategy, Input, Inject, EventEmitter, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DriversResultItem } from '@cxstudio/drivers/entities/drivers-result-item';
import ManageDriversService from '@cxstudio/drivers/manage-drivers.service';


@Component({
	selector: 'drivers-hidden-tab',
	templateUrl: './drivers-hidden-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriversHiddenTabComponent {

	@Input() driversItem: DriversItem;
	@Input() hiddenDrivers: DriversResultItem[];

	@Output() unhideDriversItem = new EventEmitter<DriversResultItem>();

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('manageDriversService') private readonly manageDriversService: ManageDriversService
	) {}

	unhide = (driversItem: DriversResultItem): void => {
		this.unhideDriversItem.emit(driversItem);
	}

	getHiddenTooltip = (driver: DriversResultItem): string => {
		if (driver.nodePath) {
			return driver.nodePath;
		} else if (this.isMissingDriver(driver)) {
			return this.locale.getString('drivers.missingDriverTooltip');
		}
	}

	isMissingDriver = (driver: DriversResultItem): boolean => {
		return !driver.impactRank;
	}

	isDriverEditable = (): boolean => {
		return this.manageDriversService.canEdit(this.driversItem);
	}
}
