import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';

@Injectable()
export class SystemPermissionsService {
	constructor(
		@Inject('security') private security: Security,
	) {}

	hasCustomerPermissions = (): boolean => {
		return this.hasCustomerAdmin();
	}

	private hasCustomerAdmin = (): boolean => {
		let hasLimit = this.security.getCurrentMasterAccount().maxCustomerAdmins > 0;
		return hasLimit && this.canChangeCustomerAdmin();
	}

	private canChangeCustomerAdmin = (): boolean => {
		return this.security.isAnyAdmin()
			|| this.security.has('account_owner')
			|| this.security.has('customer_admin');
	}
}

app.service('systemPermissionsService', downgradeInjectable(SystemPermissionsService));
