<div>
	<alert type="info" *ngIf="showCountNote()">
		<span [i18n]="'reportFilters.conditionCountNote'" [i18nParams]="{filterMaxCount: maxFilterRules}"></span>
	</alert>
	<div *ngFor="let rule of filterRules; index as $index"
		class="w-100-percent mb-8">
		<div class="filter-statement w-100-percent d-flex justify-between align-center"
			[ngClass]="getClasses(rule)">
			<filter-rule class="flex-fill d-flex"
				[ngClass]="displayVertical ? 'flex-direction-column w-max-260' : 'align-items-center'"
				[rule]="rule"
				[filterableFields]="filterableFields"
				[allowDateRange]="allowDateRange"
				[projectSelection]="projectSelection"
				[opened]="shouldOpen(rule)"
				[displayVertical]="displayVertical"
				(ruleChange)="onRuleChange($event)"
				[filterFieldsRefresh]="filterFieldsRefresh"
				[appendToBody]="appendToBody">
			</filter-rule >
			<div class="filter-tools d-inline-block" [ngClass]="{'align-self-start ml-8': displayVertical}">
				<button
					type="button"
					class="btn btn-icon remove-filter-button"
					(click)="removeFilterRule($index)"
					title="{{'widget.minus'|i18n}}"
					*ngIf="filterRuleCanBeRemoved(rule)"
				>
					<icon name="trash"></icon>
				</button>
				<button
					*ngIf="!displayVertical"
					type="button" class="btn btn-icon add-filter-button ml-0"
					[disabled]="!filterRuleCanBeAdded()"
					(click)="addFilterRule()" title="{{'widget.plus'|i18n}}">
					<icon name="add"></icon>
				</button>
			</div>

			<div class="filter-disabled-overlay" *ngIf="ngDisabled"></div>
		</div>
	</div>
	<button *ngIf="displayVertical"
			(click)="addFilterRule()"
			type="button"
			[disabled]="!filterRuleCanBeAdded()"
			title="{{'widget.plus'|i18n}}"
			class="btn btn-secondary w-100-percent d-flex justify-center align-items-center mt-16">
		<icon name="add" class="mr-8"></icon>
		{{'dashboard.addFilter'|i18n}}</button>
</div>
