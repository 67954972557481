import { downgradeInjectable } from '@angular/upgrade/static';
import { CommonDrillService } from '../common-drill.service';
import { DrillHelperService } from './drill-helper.service';
import { DrillToBarProcessorService } from './drill-to-bar-processor.service';
import { DrillToCloudProcessorService } from './drill-to-cloud-processor.service';
import { DrillToDocumentExplorerProcessorService } from './drill-to-document-explorer-processor.service';
import { DrillToHeatmapProcessorService } from './drill-to-heatmap-processor.service';
import { DrillToLineProcessorService } from './drill-to-line-processor.service';
import { DrillToMetricProcessorService } from './drill-to-metric-processor.service';
import { DrillToPieProcessorService } from './drill-to-pie-processor.service';
import { DrillToScatterProcessorService } from './drill-to-scatter-processor.service';
import { DrillToSentenceProcessorService } from './drill-to-sentence-processor.service';
import { DrillToTableProcessorService } from './drill-to-table-processor.service';

export class DrillServiceDowngrades {}

app.service('commonDrill', downgradeInjectable(CommonDrillService));
app.service('drillToHelper', downgradeInjectable(DrillHelperService));
app.service('drillToBarProcessor', downgradeInjectable(DrillToBarProcessorService));
app.service('drillToLineProcessor', downgradeInjectable(DrillToLineProcessorService));
app.service('drillToCloudProcessor', downgradeInjectable(DrillToCloudProcessorService));
app.service('drillToTableProcessor', downgradeInjectable(DrillToTableProcessorService));
app.service('drillToHeatmapProcessor', downgradeInjectable(DrillToHeatmapProcessorService));
app.service('drillToMetricProcessor', downgradeInjectable(DrillToMetricProcessorService));
app.service('drillToScatterProcessor', downgradeInjectable(DrillToScatterProcessorService));
app.service('drillToPieProcessor', downgradeInjectable(DrillToPieProcessorService));
app.service('drillToSentenceProcessor', downgradeInjectable(DrillToSentenceProcessorService));
app.service('anDocExplorerProcessor', downgradeInjectable(DrillToDocumentExplorerProcessorService));
