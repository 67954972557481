import { DrillFilterEvent } from '@app/core/cx-event.enum';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { DashboardViewService } from '@app/modules/dashboard/dashboard-view/dashboard-view.service';
import { DocumentLinkCopyService } from '@app/modules/document-explorer/document-link-copy.service';
import { CombinedFiltersService } from '@app/modules/filter/services/combined-filters.service';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { CloudNavigation } from '@app/modules/keyboard-navigation/cloud-navigation.service';
import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { RequestSourceType } from '@app/modules/reports/real-data-preview/request-source-type';
import { ReportRunHelperService } from '@app/modules/reports/utils/report-run-helper/report-run-helper.service';
import { ReportRunPreparationService } from '@app/modules/reports/utils/report-run-preparation.service';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { KeyTermsUtils } from '@app/modules/utils/key-terms-utils';
import { FeedbackUtils } from '@app/modules/widget-container/feedback-utils.service';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { ReportPaginationService } from '@app/modules/widget-visualizations/report-pagination.service';
import { ErrorMessageProcessingService } from '@app/modules/widget-visualizations/utilities/error-message-processing.service';
import { WidgetContentEvent, WidgetContentEventType } from '@app/modules/widget-visualizations/visualization-component.interface';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { Key } from '@app/shared/util/keyboard-utils.class';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { TemplateWidgetUtils } from '@cxstudio/dashboard-templates/template-widget-utils';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { ColorsCache } from '@cxstudio/dashboards/widgets/colors-cache.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';
import Widget, { IFilterUi, WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { WidgetAction, WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { CloudWordSelection } from '@cxstudio/reports/cloud-word-selection.service';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { IContextMenuActions, WidgetDrillActionsService } from '@app/modules/reports/utils/context-menu/drill/widget-drill-actions.service';
import { WidgetDrillMenuService } from '@app/modules/reports/utils/context-menu/drill/widget-drill-menu.service';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { ReportRun } from '@cxstudio/reports/entities/report-run.interface';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { PreviewAnalyticFeedbackSelection } from '@cxstudio/reports/preview/preview-analytic-feedback-selection.class';
import { PreviewChunkService } from '@app/modules/document-explorer/preview-chunk.service';
import { PreviewHelper } from '@cxstudio/reports/preview/preview-helper-service';
import { PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { TuningSuggestions } from '@cxstudio/reports/preview/tuning-suggestions';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { ReportScope } from '@cxstudio/reports/report-scope';
import { ReportResponsiveness } from '@cxstudio/reports/responsiveness/report-responsiveness';
import { ResponsiveReportService } from '@cxstudio/reports/responsiveness/responsive-report-service';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { WidgetLinkingService } from '@cxstudio/reports/utils/analytic/widget-linking-service';
import { AppliedFiltersFactory } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';
import { WidgetKeyboardUtils } from '@cxstudio/reports/widget-utils/widget-keyboard-utils';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { WidgetDataServiceFactory } from '@cxstudio/services/data-services/widget-data-service.factory';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import WidgetService from '@cxstudio/services/widget-service';
import { IAugmentedJQuery } from 'angular';
import * as moment from 'moment';
import { WidgetVisualization } from './entities/widget-visualization';
import {DowngradeDialogService} from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { WidgetConfidentialityService } from '@app/modules/widget-visualizations/common/widget-confidentiality/widget-confidentiality.service';
import { IntelligentScoring } from './preview/intelligent-scoring';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';
import { WidgetError } from '@app/modules/widget-visualizations/common/widget-error/widget-error.class';
import { IntelligentScoringService } from '@app/modules/document-explorer/intelligent-scoring/intelligent-scoring.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ReportExportUtilsService } from '@app/modules/widget-container/widget-menu/export/report-export-utils.service';
import { PreviewExportService } from '@app/modules/widget-container/widget-menu/export/preview-export.service';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';

interface IPageData extends Partial<ReportDataObject> {
	countInfo: any;
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.controller('ReportCtrl', function(
	$scope: ReportScope,
	$q: ng.IQService,
	$element: IAugmentedJQuery,
	$timeout: ng.ITimeoutService,
	$rootScope: ng.IRootScopeService,
	$log: ng.ILogService,
	locale: ILocale,
	widgetService: WidgetService,
	widgetDataServiceFactory: WidgetDataServiceFactory,
	widgetsEditService: WidgetsEditService,
	customFilterService: CustomFilterService,
	previewChunkService: PreviewChunkService,
	widgetUtilsService: WidgetUtilsService,
	reportExportUtils: ReportExportUtilsService,
	reportNumberFormatUtils: ReportNumberFormatUtils,
	reportUtils: ReportUtils,
	currentObjects: CurrentObjectsService,
	currentWidgets: ICurrentWidgets,
	reportProcessingService: ReportProcessingService,
	widgetLinkingService: WidgetLinkingService,
	reportRunPreparationService: ReportRunPreparationService,
	errorMessageProcessing: ErrorMessageProcessingService,
	keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper,
	appliedFiltersFactory: AppliedFiltersFactory,
	security: Security,
	previewHelper: PreviewHelper,
	pointSelectionUtils: PointSelectionUtils,
	hierarchyService: HierarchyService,
	widgetTimingService: WidgetTimingService,
	previewExportService: PreviewExportService,
	colorsCache: ColorsCache,
	applicationThemeService: ApplicationThemeService,
	cloudNavigation: CloudNavigation,
	responsiveReportService: ResponsiveReportService,
	dateService: DateService,
	betaFeaturesService: BetaFeaturesService,
	cloudWordSelection: CloudWordSelection,
	previewPredefinedColumns: PreviewPredefinedColumns,
	widgetDrillActions: WidgetDrillActionsService,
	reportAssetUtilsService: ReportAssetUtilsService,
	projectAccessService: ProjectAccessService,
	widgetDrillMenu: WidgetDrillMenuService,
	documentLinkCopyService: DocumentLinkCopyService,
	dashboardService: DashboardService,
	profanityDisguiseService: ProfanityDisguiseService,
	widgetDescriptionService: WidgetDescriptionService,
	versionsHeaderService: VersionsHeaderService,
	dashboardViewService: DashboardViewService,
	reportRunHelperService: ReportRunHelperService,
	combinedFiltersService: CombinedFiltersService,
	downgradeDialogService: DowngradeDialogService,
	widgetConfidentialityService: WidgetConfidentialityService,
	intelligentScoringService: IntelligentScoringService
) {
	initializeControllers();

	function initializeControllers(): void {
		$scope.wordsSelection = cloudWordSelection.getAndInitWordsSelection($scope);
		if (previewHelper.isAnalyticsPreview($scope.widget.properties && $scope.widget.properties.widgetType)) {
			$scope.filteringFeedbackSelection = new PreviewAnalyticFeedbackSelection($scope,
				previewPredefinedColumns, widgetsEditService, locale);
		}
		$scope.tuningSuggestions = new TuningSuggestions();
		$scope.intelligentScoring = new IntelligentScoring();
		reloadTuningSuggestions();
	}

	$scope.isWidgetLinkingEnabled = (widget: Widget) => widgetLinkingService.isWidgetLinkingEnabled(widget);

	$scope.showDataPointLimitWarning = false;
	$scope.userHasAdminDataAccess = false;

	$scope.widgetConfidentialityWarning = {
		enabled: false,
		message: ''
	};

	$scope.widgetCurrentPage = currentWidgets.getPagination($scope.widget.containerId, $scope.widget.id);
	$scope.widgetTotalItems = currentWidgets.getPaginationTotal($scope.widget.containerId, $scope.widget.id);

	if (intelligentScoringService.isRebuttalAvailable()) {
		const projectIdentifier = ProjectIdentifier.fromWidgetProperties($scope.widget.properties);
		projectAccessService.hasAdminProjectAccess(projectIdentifier).then(hasAdminAccess => {
			$scope.userHasAdminDataAccess = hasAdminAccess;
		});
	}

	function initializePagination(): void {
		let pages = new Pagination(0, $scope.widgetCurrentPage || 1);
		pages.totalItems = $scope.widgetTotalItems || 0;

		let paginationDefaults = {
			pages,
			totalItemsFormatted: 0,
			maxSize: 5,
			state: {
				startBound: 0,
				endBound: 0,
				storedStart: 0,
				storedEnd: 0,
				pagesPerCall: 5,
				globalDataObject: null
			}
		};
		$scope.pagination = _.extend($scope.pagination || {}, paginationDefaults);
	}
	initializePagination();

	// bypassing global letiables
	$scope.pdfToken = $rootScope.pdfToken;
	$scope.pdf = $rootScope.pdf;

	$scope.widget.responsiveState = responsiveReportService.createResponsiveState($scope.widget);

	$scope.state = {
		// view group
		loading: null,
		message: '',
		view: {
			// data : null,
			visualization: null,
			trigger: 0
		},
		statsMode: false,
	};
	$scope.menuState = {
		position: {},
		open: false,
		shareOpen: false,
		dropdownDirection: 'left',
	};

	$scope.isLoading = () => {
		const loading = !!$scope.state.loading;
		const loadingResponsiveReport = responsiveReportService.isLoadingResponsiveReport($scope.widget);

		return loading && loadingResponsiveReport;
	};

	$scope.getLoadingPromise = () => {
		return $scope.isLoading() && $scope.state.loading;
	};

	$scope.noData = false;

	$scope.isRubricDefinitionReport = () => {
		return ReportConstants.isObjectViewerWidget($scope.widget.properties.widgetType);
	};

	$scope.onNormalizedChange = (): void => {
		const widgetCopy = angular.copy($scope.widget);
		widgetCopy.visualProperties.normalized = $scope.state.view.normalized;
		$scope.reloadUtils(widgetCopy).then(redraw);
	};

	$scope.onAuditModeChange = (enabled: boolean): void => {
		$scope.tuningSuggestions.changeMode(enabled);
		$scope.reloadUtils($scope.widget).then(redraw);
	};

	$scope.onRebuttalModeChange = (enabled: boolean): void => {
		$scope.intelligentScoring.changeMode(enabled);
		$scope.reloadUtils($scope.widget).then(redraw);
	};

	$scope.onTranslateToggle = (): void => {
		$scope.translationEnabled = !$scope.translationEnabled;
		$scope.reloadUtils($scope.widget).then(redraw);
	};

	$scope.editFeedbackSelection = (): void => {
		$scope.translationEnabled = false;
		$scope.filteringFeedbackSelection.editFeedbackSelection();
	};

	function getMetadataValue(key: string): string {
		return $scope.state.view?.data?.metadata
			? $scope.state.view.data.metadata[key]
			: '';
	}

	$scope.getLastModified = (): string => {
		const timestamp = getMetadataValue('lastModified');
		return timestamp ? dateService.format(new Date(timestamp), DateTimeFormat.BASIC_DATE) : '';
	};

	$scope.getSummary = (): string | undefined => {
		if ($scope.isRubricDefinitionReport() && !_.isEmpty($scope.widget.properties.selectedAttributes)
				&& $scope.utils) {
			const totalKey = $scope.state.view.normalized ? 'totalN' : 'total';
			const targetKey = $scope.state.view.normalized ? 'targetN' : 'target';
			const parts = [];
			const scorecardId = $scope.widget.properties.selectedAttributes[0].id;
			const scorecard = _.findWhere($scope.utils.scorecards, { id: scorecardId});
			const decimal = ScorecardUtils.getScorecardDecimal(scorecard,
				$scope.utils.formats, $scope.utils.attributes);
			if (getMetadataValue(totalKey)) {
				const totalLabel = locale.getString(`scorecards.${totalKey}Label`);
				const total = `<b>${totalLabel}:</b> ${Number(getMetadataValue(totalKey)).toFixed(decimal)}`;
				parts.push(total);
			}
			if (getMetadataValue(targetKey)) {
				const targetLabel = locale.getString(`scorecards.${targetKey}Label`);
				const target = `<b>${targetLabel}:</b> ${Number(getMetadataValue(targetKey)).toFixed(decimal)}`;
				parts.push(target);
			}
			return parts.join(' ');
		}
	};

	$scope.showTotalCount = (): boolean => {
		return !!$scope.widget.details
			&& !isEmpty($scope.widget.details.totalCount)
			&& !$scope.state.statsMode
			&& !$scope.state.descriptionMode
			&& $scope.widget.visualProperties.showSampleSize !== false; // undefined means default(true)
	};

	$scope.isFilteredByConfidentiality = (): boolean => {
		return $scope.state.view.data?.metadata?.filteredByConfidentiality
			|| $scope.documentManager?.isFilteredByConfidentiality();
	};

	$scope.showEditButton = (): boolean => {
		return betaFeaturesService.isFeatureEnabled(BetaFeature.WIDGET_EDIT_OVERHAUL)
			&& currentObjects.isEditMode()
			&& !$scope.showFeedbackSelection()
			&& $scope.widget.visualProperties.visualization !== WidgetVisualization.CLOUDWORDSTABLE
			&& !$scope.intelligentScoring.isIntelligentScoringMode()
			&& !$scope.tuningSuggestions.isTuningSuggestionsMode();
	};

	$scope.showTuningSuggestionButton = (): boolean => {
		return !$scope.intelligentScoring.isIntelligentScoringMode()
			&& $scope.tuningSuggestions.hasTuningSuggestions($scope.widget)
			&& !$scope.filteringFeedbackSelection.isEnabled()
			&& !$scope.isStatsPanelActive()
			&& !$scope.isFilteredByConfidentiality();
	};

	$scope.showIntelligentScoringButton = (): boolean => {
		return intelligentScoringService.isRebuttalAvailable()
			&& !$scope.tuningSuggestions.isTuningSuggestionsMode()
			&& !$scope.isFilteredByConfidentiality()
			&& $scope.isPreviewPaneView($scope.widget)
			&& $scope.userHasAdminDataAccess;
	};

	$scope.showFeedbackSelection = (): boolean => {
		return $scope.filteringFeedbackSelection?.isEnabled() && !$scope.isStatsPanelActive();
	};

	$scope.isPreviewPaneView = (widget: Widget) => {
		return widget.properties.previewMode === PreviewMode.DOCUMENT
			&& widget.properties.widgetType === WidgetType.PREVIEW;
	};

	$scope.fireEvent = (action, widget, broadcast) => {
		if (broadcast) {
			$scope.$broadcast(action);
		} else {
			$scope.$emit(action, widget);
		}
	};

	$scope.canEditWidgets = () => {
		return $scope.widgetActions.canEditDashboard($scope.dashboardData);
	};

	$scope.canViewStats = () => {
		if ($scope.dashboardData.isBook) {
			return dashboardService.canEditDashboard($scope.dashboardData.dashboard);
		} else {
			return $scope.canEditWidgets();
		}
	};

	$scope.canExportData = () => {
		if (!security.has('export_widget_data')) {
			return false;
		}

		if ($scope.widget.properties.widgetType === WidgetType.SELECTOR
			|| $scope.widget.properties.widgetType === WidgetType.NETWORK) {
			return false;
		}

		return $scope.widgetMode === WidgetDisplayMode.EMBEDDED
			? isLicensedUserWithViewPermission()
			: true;
	};

	$scope.canExportImage = () => {
		if ($scope.widget.properties.widgetType === WidgetType.SELECTOR) return false;

		return $scope.widgetMode === WidgetDisplayMode.EMBEDDED
			? isLicensedUserWithViewPermission()
			: true;
	};

	$scope.showConversationDownload = () => {
		return $scope.isConversation()
			&& $scope.isPreviewPaneView($scope.widget)
			&& !currentObjects.isEditMode();
	};

	function isEnterpriseUser(): boolean {
		return security.loggedUser.isEnterpriseUser;
	}

	function userHasDashboardViewPermission(): boolean {
		return $scope.dashboardData.dashboard.permissions.VIEW;
	}

	function isLicensedUserWithViewPermission(): boolean {
		return !isEnterpriseUser() && userHasDashboardViewPermission();
	}

	$scope.removeSelection = () => {
		if (ReportConstants.isSelectorWidget($scope.widget.properties.widgetType)) {
			$scope.clearSelections();
		} else {
			const linkedWidgetInfo = getLinkedWidgetInfo($scope.widget);
			$rootScope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, $scope.widget, linkedWidgetInfo.point);
			removeWrongSelectionWarning();
		}
	};

	$scope.handleRightClick = (event: MouseEvent) => {
		if (!event) return;

		$scope.menuState.open = true;
		$scope.menuState.position = {
			visibility: 'hidden' // to prevent blinking
		};

		let menuElement = $(event.target).closest('widget-item').find('widget-menu');
		// in suggestion mode, the menu items don't exist so this will fail
		if (!menuElement || menuElement.length === 0) {
			$scope.menuState.open = false;
			return;
		}

		let left = event.pageX - menuElement.offset().left - menuElement.width();
		let top = event.pageY - menuElement.offset().top - menuElement.height();

		const newPosition = {
			visibility: null,
			left: `${left}px`,
			top: `${top}px`
		};

		$scope.menuState.position = newPosition;
		$scope.menuState.openedViaRightClick = true;
	};

	$scope.showReportRightClickMenu = () => {
		return $scope.state.message || $scope.state.statsMode || $scope.noData || $scope.state.descriptionMode;
	};

	$scope.getWidgetAriaLabel = () => {
		if ($scope.widget.properties.widgetType === WidgetType.METRIC
			&& $scope.widget.properties.selectedMetrics?.length < 2) {
			return '';
		}
		return $scope.widget.properties.altText || $scope.widget.displayName;
	};

	function initListeners(): void {
		$scope.$on('displayFreshData', () => {
			responsiveReportService.displayFreshData($scope.widget);
		});

		$scope.$on('changeWidgetsOwnerEvent', (event, oldOwnerEmail, newOwnerEmail) => {
			changeWidgetOwner(oldOwnerEmail, newOwnerEmail);
		});

		function dashboardRefreshFunc(cacheOption: CacheOptions): (event, dashboardId, config) => void {
			return (event, dashboardId, config) => {
				if (dashboardId && $scope.widget.dashboardId !== dashboardId) {
					return; // ignore refresh from a different dashboards
				}

				colorsCache.clear(dashboardId);
				refreshWidgetInternal({
					cacheDynamicProperties: cacheOption,
					config
				});
			};
		}

		$scope.$on('dashboardRefreshEvent', dashboardRefreshFunc(CacheOptions.CACHED));

		$scope.$on('dashboardHardRefreshEvent', dashboardRefreshFunc(CacheOptions.NOT_CACHED));

		$scope.$on(DrillFilterEvent.DRILL_FILTER_SET, updateWidgetFilters);
		$scope.$on(DrillFilterEvent.DRILL_FILTERS_RESET, () => resetWidgetFilters());

		$scope.$on('widgetsRefreshEvent', (event, selectedWidgetIds) => {
			if (selectedWidgetIds.contains($scope.widget.id)) {
				refreshWidgetInternal({
					cacheDynamicProperties: CacheOptions.CACHED
				});
			}
		});

		$scope.$on('widgetRefreshEvent', () => {
			refreshWidgetInternal({
				cacheDynamicProperties: CacheOptions.CACHED
			});
		});

		$scope.$on('widgetsHardRefreshEvent', (event, selectedWidgetIds: number[]) => {
			if (selectedWidgetIds.contains($scope.widget.id)) {
				refreshWidgetInternal({
					cacheDynamicProperties: CacheOptions.NOT_CACHED
				});
			}
		});

		$scope.$on('selector:updateClearSelections', (event, showClearSelections) => {
			$scope.state.showClearSelections = showClearSelections;
		});

		$scope.$on('widget:passReferences', (e, args) => {
			$scope.documentManager = args.documentManager;
			$scope.preferences = args.preferences;
		});

		$scope.$on('documentLoading', (event, loading) => {
			$scope.state.documentLoading = loading;
		});

		$scope.$on('documentLoaded', (event) => {
			$scope.state.documentLoading = null;
		});
	}
	initListeners();

	$scope.viewAsTableDisabled = () => {
		return !!$scope.state.loading || (FeedbackUtils.isDocumentMode($scope.widget) && !!$scope.state.documentLoading);
	};

	function getCurrentDocument(): PreviewDocument {
		return $scope.documentManager && $scope.documentManager.documents[$scope.documentManager.selectedDocument];
	}

	$scope.isConversation = () => {
		return DocumentTypeUtils.hasConversationMetrics(getCurrentDocument());
	};

	$scope.downloadAudio = () => {
		$scope.documentManager.conversationMethods.downloadAudio(getCurrentDocument());
	};

	$scope.downloadTranscript = () => {
		$scope.documentManager.conversationMethods.downloadTranscript(getCurrentDocument());
	};

	function isCurrentItemCurated(): boolean {
		let currentItem = $scope.preferences?.settings?.singleVerbatimMode
			? $scope.documentManager.getSelectedVerbatim()
			: getCurrentDocument();
		return !!(currentItem && $scope.documentManager.isItemCurated(currentItem));
	}

	$scope.copyDocumentsLink = (event): void => {
		let documents = $scope.documentManager.getAllCuratedDocuments();
		if (!isCurrentItemCurated()) {
			documents.push(getCurrentDocument());
		}
		let documentIds = _.map(documents, (document: any) => document.id);
		documentLinkCopyService.copyDocumentsLink(event, documentIds,
			$scope.documentManager.getProjectIdentifier(),
			$scope.documentManager.getWorkspaceProject(),
			$scope.documentManager.widget.properties.runAs,
			$scope.documentManager.widget.properties.encodedDescriptor);
	};

	// return promise, if widget is being saved
	function getWidgetId(): number {
		return $scope.widget.id;
	}

	$scope.isTableWidget = () => {
		return ReportConstants.isTableWidget($scope.widget.properties.widgetType);
	};

	$scope.isChartWidget = () => {
		return ReportConstants.isChartWidget($scope.widget.properties.widgetType);
	};

	$scope.isSimplePreview = () => {
		let vis = $scope.widget.visualProperties?.visualization;
		return vis === WidgetVisualization.PREVIEW_BUBBLES || vis === WidgetVisualization.PREVIEW_TABLE;
	};

	$scope.openAdditionalInformation = (): void => {
		if (currentObjects.isEditMode()) {
			$scope.toggleStatsMode();
		} else {
			$scope.state.descriptionMode = !$scope.state.descriptionMode;
		}
	};

	$scope.getWidgetClasses = (widget: Widget): Array<string | {}> => {
		return [
			`PRIMARY-${widget.visualProperties.subChartType}`,
			widget.type,
			widget.visualProperties.visualization,
			widget.visualProperties.subChartType,
			widget.visualProperties.secondaryChartType,
			{
				'header-hidden': widget.visualProperties.hideTitle === true,
				'footer-hidden': isFooterHidden(widget),
				'bg-none': widget.visualProperties.backgroundColor === 'none',
				'br-widget-warning': isTrue(currentObjects.isEditMode()) && isTrue(widget.intersectPageBreak),
			}
		];
	};

	function isFooterHidden(widget: Widget): boolean {
		if (widget.properties.widgetType === WidgetType.SELECTOR) {
			return true;
		}

		return widget.visualProperties.showSampleSize === false
				&& $scope.widgetConfidentialityWarning.enabled === false
				&& widget.name !== WidgetType.OBJECT_VIEWER;
	}

	$scope.getHeaderColor = (widget: Widget) => {
		if (ReportConstants.isSelectorWidget(widget.properties.widgetType)
				&& widget.visualProperties.backgroundColor === 'none'
				&& !applicationThemeService.isShowingDashboardDarkTheme()) {
			return widgetService.getWidgetContrastColor(widget);
		} else {
			return {};
		}
	};

	$scope.processCommonKb = (event) => {
		WidgetKeyboardUtils.processKeyboardEvent(event, $scope.widget.id);
	};

	$scope.stopPropagationOnEnter = (event) => {
		if (event.key === Key.ENTER) {
			event.stopPropagation();
		}
	};

	$scope.clearSelections = () => {
		$scope.$broadcast('selector:clearSelections');
		removeWrongSelectionWarning();
	};

	function removeWrongSelectionWarning(): void {
		pointSelectionUtils.enableWrongSelectionWarning($scope.widget.containerId, $scope.widget.id, false);
	}

	$scope.init = () => {
		$scope.widget.template = TemplateWidgetUtils.isDisplayedAsTemplate($scope.widget);
		$scope.widget.filterUi = $scope.widget.filterUi || {} as IFilterUi;

		if ($scope.isRubricDefinitionReport()) {
			// legacy widgets are non-normalized by default, see CB-6971
			$scope.state.view.normalized = !!$scope.widget.visualProperties.normalized;
		}

		addKeyboardNavigationForDrilling();

		onLoaded();
	};

	const widgetsCache = currentWidgets.getWidgetsCache($scope.widget.containerId);

	$scope.handleClick = reportClickHandler;

	function reportClickHandler(point: DrillPoint, event, isRightClick = false): void {
		if (isRightClick) {
			$scope.menuState.open = false;
			$scope.menuState.shareOpen = false;
		}

		if ($scope.widgetMode === WidgetDisplayMode.EMBEDDED && !userHasDashboardViewPermission()) {
			return;
		}

		if (versionsHeaderService.isEnabled()
				|| dashboardViewService.isPreviewAsMode()
				|| $scope.state.menuLoading
				|| isEnterpriseUser()
				|| $scope.widget.containerId === 'homePageEdit') {
			return;
		}


		if (!_.isEmpty($scope.widget.linkedWidgets) && !isRightClick && !$scope.tuningSuggestions.isTuningSuggestionsMode()) {
			$rootScope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, $scope.widget, point);
			return;
		}

		if (!point) {
			return;
		}

		$scope.state.menuLoading = true;
		const project = reportAssetUtilsService.getWidgetProject($scope.widget);
		projectAccessService.hasProjectAccess(project).then((hasAccess) => {
			$scope.widget.hasDataAccess = hasAccess;
			const contextMenuActions = widgetDrillActions.getContextMenuActions($scope, $scope.widget);
			const widget = angular.copy($scope.widget);
			if ($scope.filteringFeedbackSelection?.isEnabled() && widget.properties.analyticFeedbackSelection) {
				customFilterService.processAnalyticFeedbackSelectionFilters(widget);
				delete widget.properties.analyticFeedbackSelection;
			}
			showDrillMenu(contextMenuActions, widget, point, event);
		},
		() => {
			$scope.state.menuLoading = false;
		});
	}

	function showDrillMenu(contextMenuActions: IContextMenuActions, widget: Widget, point: DrillPoint, event): void {
		$scope.state.menuLoading = true;
		widgetDrillMenu.showDrillMenu(point, widget, $scope.dashboardData.dashboard.properties,
			contextMenuActions, currentObjects.isEditMode(), event, $scope.handleRightClick)
			.finally(() => {
				$scope.state.menuLoading = false;

				if (keyboardNavigationDrillHelper.isKeydownEvent(event)) {
					keyboardNavigationDrillHelper.focusDrillMenu();
				}
			});
	}

	$scope.prepareDynamicReportProperties = (widget: Widget): ng.IPromise<WidgetUtils> => {
		responsiveReportService.resetResponsiveState(widget);
		widgetTimingService.startPreparation(widget);

		return $scope.reloadUtils(widget).then((utils) => {
			if (widget.properties && !widget.properties.isCustomTitle) {
				const name = widgetService.updateAutoTitle(widget.properties, utils);
				if (name) {
					widget.displayName = name;
				}
			}
			return utils;
		});
	};

	$scope.getData = (widgetSettings: Widget) => {
		$scope.lastReportrequestTimestamp = moment.utc();

		if (widgetService.isDataNotRequired(widgetSettings)) {
			return $q.when(angular.copy(widgetSettings.properties)); // returning same properties - we do not need to request data from server
		}

		const runWidgetSettings = angular.copy(widgetSettings);

		$scope.lastWidgetSettings = runWidgetSettings;

		reportRunHelperService.populateGetDataProperties(runWidgetSettings,
			$scope.lastReportrequestTimestamp, RequestSourceType.DASHBOARD);

		// if it's a template or the configuration is invalid, don't pull data
		if (widgetSettings.template) {
			return $q.when(widgetSettings.properties);
		}

		// if preview 2.0 widget in document mode, that has its own data handling
		if ($scope.isPreviewPaneView(widgetSettings)) {
			return $q.when();
		}

		if ($scope.dashboardData) {
			reportRunPreparationService.applyPropertiesFromDashboard($scope.dashboardData.dashboard, runWidgetSettings);
		}

		const postProcessSettings =
			reportRunHelperService.postProcessSettings(runWidgetSettings, getDashboardHistory(), $scope.filteringFeedbackSelection?.isEnabled());

		return PromiseUtils.old(postProcessSettings.then((updatedSettings: Widget) => {
			const retrieveDataCall: ng.IPromise<any> = widgetDataServiceFactory.getReportData(updatedSettings);

			return retrieveDataCall.then((data) => {
				return postProcessData(updatedSettings, data);
			});
		}));
	};

	function postProcessData(updatedSettings: Widget, data: any): any {
		$scope.dataObject = data.data;

		profanityDisguiseService.maskProfanityInSentences($scope.dataObject);
		$scope.widgetConfidentialityWarning =
				widgetConfidentialityService.getConfidentialityWarning(updatedSettings, data);

		if (data.metadata && moment(data.metadata.timeStampMark).isBefore($scope.lastReportrequestTimestamp)) {
			return $q.reject('outdated report');
		}

		if (!data.total || data.total.volume === 0 || !data.data.length) {
			$scope.noData = true;
		}

		widgetDescriptionService.generateWidgetAriaLabel(updatedSettings, data).then((description) => {
			$scope.widget.ariaLabel = description;
		});

		if (updatedSettings.properties.autoDescription) {
			widgetDescriptionService.generateDescription(updatedSettings, data).then((description) => {
				$scope.widget.description = description;
			});
		}

		return data;
	}

	$scope.totalsDisabled = (): boolean => {
		const selectedGroupings = $scope.utils?.selectedAttributes;
		const data = $scope.dataObject;
		let totalsDisabled = false;

		// disabled when one of the secondary groupings have no data at all
		if (data && data.length > 0 && selectedGroupings && selectedGroupings.length > 1) {
			const secondaryGroupingsIdentifiers = getSecondaryGroupingsIdentifiers();
			for (const identifier of secondaryGroupingsIdentifiers) {
				if (isSecondaryGroupingDataAbsent(data, identifier)) {
					totalsDisabled = true;
					break;
				}
			}
		}

		return totalsDisabled;

		function getSecondaryGroupingsIdentifiers(): string[] {
			const secondaryGroupings = $scope.utils.selectedAttributes.slice(1);
			return _.map(secondaryGroupings, (grouping) => {
				return grouping.identifier;
			});
		}

		function isSecondaryGroupingDataAbsent(array, identifier): boolean {
			return !_.find(array, (point) => {
				return !_.isUndefined(point[identifier]);
			});
		}
	};

	// populates appliedFilters, which is used by filters dropdown
	function reloadWidgetFilters(widget: Widget): ng.IPromise<void> {
		let properties = widget?.properties;
		if (!properties) {
			return $q.reject();
		}

		if (betaFeaturesService.isFeatureEnabled(BetaFeature.RETRIEVE_REPORT_ASSETS)
			&& !$scope.isPreviewPaneView(widget)
			&& !InternalProjectTypes.isAdminProject(widget.properties.project)) {
			return $q.resolve();
		}

		return reloadWidgetFiltersInternal($scope.widget).then(initializeAppliedFilters);
	}

	function initializeAppliedFilters(widgetMetadata): void {
		$scope.appliedFilters = !!widgetMetadata
				? appliedFiltersFactory.getAppliedFilters(widgetMetadata, true, false)
				: undefined;
	}

	function reloadWidgetFiltersInternal(widget: Widget): ng.IPromise<WidgetFiltersMetadata> {
		if (widgetService.isDataNotRequired($scope.widget)
			|| $scope.widget.name === WidgetType.OBJECT_VIEWER) {
			return $q.when(undefined);
		}

		return PromiseUtils.old(reportProcessingService.getWidgetFiltersMetadata(widget, getDashboardHistory()));
	}

	$scope.exportData = (widget: Widget): void => {
		if (!$scope.state.loading) {
			$scope.state.loading = PromiseUtils.old(reportProcessingService.getWidgetFiltersMetadata(widget, getDashboardHistory()))
				.then(widgetMetadata => {
					return reportExportUtils.exportToExcel($scope.state.view.data, widget, getDashboardHistory(),
						$scope.utils, widgetMetadata).then(() => $scope.state.loading = null);
				});
		}
	};

	$scope.exportPreviewData = (widget: Widget) => {
		const copiedWidget = ObjectUtils.copy(widget);
		updatePageProperties(copiedWidget);
		reportRunPreparationService.populateBrowserProperties(copiedWidget);
		copiedWidget.properties.timeStampMark = $scope.lastReportrequestTimestamp;
		copiedWidget.properties.exportAttributes = copiedWidget.properties.favoriteAttributes;
		const postProcessSettings = customFilterService.postprocessWidgetProperties(copiedWidget, getDashboardHistory(),
			$scope.filteringFeedbackSelection?.isEnabled());

		$scope.state.loading = postProcessSettings.then((widgetSettings) => {
			const leafOnly: boolean = $scope.preferences?.settings?.leafOnly ?? false;

			return PromiseUtils.old(reportProcessingService.getWidgetFiltersMetadata(widget, getDashboardHistory())).then(widgetMetadata => {
				return previewExportService.useScheduledExport(widgetSettings)
					? schedulePreviewDataExport(widgetSettings, widgetMetadata, leafOnly)
					: runPreviewDataExport(widgetSettings, widgetMetadata, leafOnly);
			});

		}).then(() => $scope.state.loading = null);
	};

	function schedulePreviewDataExport(widgetSettings, widgetMetadata, leafOnly): ng.IPromise<any> {
		let viewFilterChanged = getDashboardHistory().isViewFilterChanged();

		return PromiseUtils.old(
			previewExportService.schedulePreviewDataExport(widgetSettings, widgetMetadata, viewFilterChanged, leafOnly));
	}

	function runPreviewDataExport(widgetSettings, widgetMetadata, leafOnly): ng.IPromise<any> {
		return PromiseUtils.old(previewExportService.getPreviewExport(widgetSettings, false, leafOnly)).then((exportData) => {
			return reportExportUtils.exportToExcel(exportData, widgetSettings, getDashboardHistory(), $scope.utils, widgetMetadata);
		});
	}

	$scope.getQid = (): string => {
		let qid = getMetadataValue('qid');
		if (!qid && $scope.state.message.contains('Please reference this code')) {
			//It is error, so extract request id from message to put in stats
			const indexOfRequestID = $scope.state.message.indexOf('this code: ') + 10;
			qid = $scope.state.message.substring(indexOfRequestID, $scope.state.message.length - 1);
		}
		return qid;
	};

	$scope.openRecolors = (widget: Widget): void => {
		downgradeDialogService.showRecolorManageDialog({
			props: widget.properties,
			visualProps: widget.visualProperties,
			utils: $scope.utils}).result
			.then((recolors) => {
				let changeAction = WidgetUpdateAction.wrapChange(() => $scope.widget, () => {
					widget.visualProperties.recolors = recolors;
				});
				$scope.widgetActions.emit(WidgetAction.UPDATE, changeAction);
				redraw();
			}).catch(() => {});
	};

	// $scope.toggleTotals = (widget: Widget): void => {
	// 	const toggleDisabled = $scope.isLoading() || $scope.totalsDisabled();
	// 	if (!toggleDisabled) {
	// 		widget.visualProperties.showTotal = !widget.visualProperties.showTotal;
	// 		redraw();
	// 	}
	// };

	$scope.handleWidgetContentEvent = (contentEvent: WidgetContentEvent): void => {
		switch (contentEvent.type) {
			case WidgetContentEventType.OPEN_ADVANCED_SETTINGS: {
				$scope.widgetActions.emit(WidgetAction.OPEN_SETTINGS);
				setTimeout(() => {
					$('#advanced-options-cog').trigger('click');
				}, 1000);
				return;
			}
			case WidgetContentEventType.DISABLE_CASES_VISUALIZATION: {
				disableCasesVisualization();
				return;
			}
			case WidgetContentEventType.LOAD_FULL_VERBATIM: {
				const verbatimId = contentEvent.args[0] as number;
				const callback = contentEvent.args[1] as (data: ReportDataObject) => void;
				const widget = ObjectUtils.copy($scope.widget);
				widget.properties.verbatimId = verbatimId;
				$scope.getData(widget).then((response) => {
					callback(response);
				});
				return;
			}
			case WidgetContentEventType.RESIZE_TABLE_COLUMNS: {
				let resizedColumns = contentEvent.args[0] as TableColumn<PreviewSentence>[];
				let changeAction = WidgetUpdateAction.wrapChange(() => $scope.widget, () => {
					// don't see a better way to bubble resize info up to here
					// consider refactoring in case of other modifications, which should propagate to properties
					_.each($scope.widget.visualProperties.columns, (column, i) => {
						column.width = resizedColumns[i].width;
					});
				});
				$scope.widgetActions.emit(WidgetAction.UPDATE, changeAction);
				return;
			}
		}

		function disableCasesVisualization(): void {
			let changeAction = WidgetUpdateAction.wrapChange(() => $scope.widget, () => {
				$scope.widget.visualProperties.caseVisualizations.enabled = false;
			});
			$scope.widgetActions.emit(WidgetAction.UPDATE, changeAction);
			redraw();
		}
	};

	function reloadTuningSuggestions(): void {
		if ($scope.tuningSuggestions.hasTuningSuggestions($scope.widget)) {
			previewHelper.getTuningSuggestionsAvailability($scope.widget).then((hasTuningSuggestionTemplates) => {
				$scope.tuningSuggestions.hasTuningSuggestionTemplates = hasTuningSuggestionTemplates;
			});
		}
	}

	//Does not cache dynamic visual properties by default
	$scope.refreshWidget = (reportRunProperties) => {
		refreshWidgetInternal(reportRunProperties);
	};

	function refreshWidgetInternal(reportRunProperties: {cacheDynamicProperties: CacheOptions; config?: any}): void {
		reportRunProperties = reportRunProperties || {} as any;
		if (!reportRunProperties.cacheDynamicProperties) {
			reportRunProperties.cacheDynamicProperties = CacheOptions.NOT_CACHED;
		}

		if ($scope.loadingInProgress && reportRunProperties.cacheDynamicProperties === CacheOptions.NOT_CACHED) {
			//If already loading and refresh button pressed, don't send request
			return;
		}

		responsiveReportService.resetResponsiveState($scope.widget);

		$scope.widget.firstPass = false;
		const widget = angular.copy($scope.widget);
		widget.properties.refreshRequired = true;
		widget.responsiveState = $scope.widget.responsiveState;
		if (!_.isUndefined($scope.state.view.normalized)) {
			widget.visualProperties.normalized = $scope.state.view.normalized;
		}

		const dontShowSpinner = reportRunProperties.config && reportRunProperties.config.skipSpinner === true;

		const responsiveness = reportRunProperties.cacheDynamicProperties === CacheOptions.NOT_CACHED
			? ReportResponsiveness.NO_CACHE
			: ReportResponsiveness.RESPONSIVE;

		const runReportPromise = $scope.prepareDynamicReportProperties(widget, reportRunProperties).then((utils) => {
			return reloadWidgetFilters(widget)
				.then(() => {
					if ($scope.isPreviewPaneView(widget)) {
						$scope.$broadcast('DocumentPreviewRefresh');
					} else {
						runReportInternal({
							widget,
							utils,
							dontShowSpinner,
							responsiveness
						});
					}
				}, errorHandler);
		}, errorHandler);

		if (!dontShowSpinner) {
			$scope.state.loading = runReportPromise;
		}

		$scope.widget.template = TemplateWidgetUtils.isDisplayedAsTemplate($scope.widget);
	}

	$scope.$watch(() => currentObjects.isEditMode(), () => {
		const filteringFeedbackSelection = $scope.filteringFeedbackSelection;
		if (filteringFeedbackSelection?.isEnabled()) {
			$scope.filteringFeedbackSelection.saveFeedbackSelection();
		}

		if (currentObjects.isEditMode() && $scope.widget.drillPath) {
			$scope.$emit('drill:reset');
			return;
		}

		if ($scope.wordsSelection.isEnabled()) {
			$scope.wordsSelection.setEnabled(false);
			runReport();
		}

		disableStatsMode();
	});

	function runReport(): void {
		if ($scope.widget?.visualProperties) {
			$scope.state.loading = $scope.prepareDynamicReportProperties($scope.widget).then(() => {
				runReportInternal({
					widget: $scope.widget,
					utils: $scope.utils,
					responsiveness: ReportResponsiveness.RESPONSIVE
				});
			});
		}
	}

	function onLoaded(): void {
		const widget = $scope.widget;

		if (!widget.properties) {
			widget.properties = {};
		}

		let visualProperties = widget.visualProperties;
		if (!visualProperties) {
			widget.visualProperties = {};
		}

		$scope.state.loading = $scope.prepareDynamicReportProperties($scope.widget).then((utils) => {
			return reloadWidgetFilters(widget).then(() => {
				runReportInternal({
					widget,
					utils,
					responsiveness: ReportResponsiveness.RESPONSIVE
				});
			}, errorHandler);
		}, errorHandler);

		// can be executed multiple times, e.g. when switching feedback Document Pane and Table views
		// TODO need to get rid of this
		if (!$scope.propertiesWatcher) {
			$scope.propertiesWatcher = $scope.$watch('widget.properties', (newValue, oldValue) => {
				if (newValue === oldValue) {
					return;
				}

				$scope.state.loading = $scope.prepareDynamicReportProperties($scope.widget).then((utils) => {
					runReportInternal({
						widget,
						utils,
						responsiveness: ReportResponsiveness.RESPONSIVE
					});
				});
			});
		}
	}

	function addKeyboardNavigationForDrilling(): void {
		const widgetName = $scope.widget.name;
		if (keyboardNavigationDrillHelper.isHighchartsWidget(widgetName)) {
			const isCloud = ReportConstants.isCloudWidget(widgetName as WidgetType);
			const keyboardNavigationFunction = (event) => {
				const isWidgetUsedAsFilter = keyboardNavigationDrillHelper.isWidgetUsedAsFilter($scope.widget.linkedWidgets);
				keyboardNavigationDrillHelper.onWidgetKeyboardEvent(event, widgetName, isWidgetUsedAsFilter,
					$scope.handleClick);
				if (isCloud) {
					cloudNavigation.handleWidgetExit(event, $scope.widget.id);
				}
			};
			$element[0].addEventListener('keydown', keyboardNavigationFunction, true);
			$scope.$on('$destroy', () => {
				$element[0].removeEventListener('keydown', keyboardNavigationFunction, true);
			});
			if (isCloud) {
				// highcharts suppress all bubbling keydown events
				$scope.$on('cloudWidget:drill', (e, event) => {
					keyboardNavigationFunction(event);
				});
			}
		}
	}

	$scope.pageChanged = (paginationPages) => {
		let pg = paginationPages.currentPage;
		if ($scope.pagination.state.globalDataObject === null) {
			return;
		}
		$scope.pagination.pages.currentPage = pg;
		currentWidgets.setPagination($scope.widget.containerId, $scope.widget.id, pg);
		$scope.widgetCurrentPage = pg;
		ReportPaginationService.fillPageBounds($scope.pagination);
		if (($scope.pagination.state.endBound + $scope.pagination.pages.pageSize * 2) >= 10000) {
			$('li.pagination-next').addClass('disabled');
			$('li.pagination-next>a').attr('disabled', 'disabled');
		}

		let pageData = {data: null, countInfo: null} as IPageData;

		pageData.countInfo = $scope.pagination.state.globalDataObject.countInfo;
		pageData.data = $scope.pagination.state.globalDataObject.data.slice(
			$scope.pagination.state.startBound - $scope.pagination.state.storedStart,
			$scope.pagination.state.endBound - $scope.pagination.state.storedStart);
		pageData.metadata = $scope.pagination.state.globalDataObject.metadata;

		if ($scope.pagination.state.endBound > $scope.pagination.state.storedEnd
			|| $scope.pagination.state.startBound < $scope.pagination.state.storedStart) {
			$scope.translationEnabled = false;
			$scope.state.loading = $scope.prepareDynamicReportProperties($scope.widget).then(() => {
				$scope.runReportPaginated({
					widget: $scope.widget,
					utils: $scope.utils
				});
			});
		} else {
			ReportPaginationService.populatePagingMetadata(pageData, $scope.pagination);

			$scope.state.view.data = pageData as any;
			if ($scope.translationEnabled) {
				$scope.translationEnabled = false;
				$scope.reloadUtils($scope.widget).then(redraw);
			} else {
				redraw();
			}
		}
	};

	function updatePageProperties(widgetSettings: PreviewWidget): void {
		$scope.pagination.pages.pageSize = widgetSettings.visualProperties.itemsPerPage;

		let limit = $scope.pagination.state.pagesPerCall * $scope.pagination.pages.pageSize;
		let start = 0;

		if ($scope.widgetCurrentPage) {
			$scope.pagination.pages.currentPage = $scope.widgetCurrentPage;
			ReportPaginationService.fillPageBounds($scope.pagination);
		}

		if ($scope.pagination.state.globalDataObject !== null || $scope.widgetCurrentPage) {
			start = (Math.ceil($scope.pagination.pages.currentPage / $scope.pagination.state.pagesPerCall) - 1)
				* $scope.pagination.state.pagesPerCall * $scope.pagination.pages.pageSize;
			limit = $scope.pagination.state.pagesPerCall * $scope.pagination.pages.pageSize;
		} else {
			$scope.pagination.state.startBound = 0;
			$scope.pagination.state.endBound = $scope.pagination.pages.pageSize;
		}

		$scope.pagination.state.storedStart = start;
		$scope.pagination.state.storedEnd = start + limit;

		if (_.isUndefined(widgetSettings.properties.page)) {
			const page = {start, limit};
			widgetSettings.properties.page = page;
		} else {
			widgetSettings.properties.page.limit = limit;
			widgetSettings.properties.page.start = start;
		}
		if (previewHelper.isAnalyticsPreview(widgetSettings.properties.widgetType)) {
			widgetSettings.properties.page.lookAheadLimit = widgetSettings.properties.page.limit;
		}
	}

	/**
	 * @param reportRun { widget, visualization, utils, visualProperties, dontShowSpinner, responsiveness }
	 */
	$scope.runReportPaginated = (reportRun) => {
		let widget = reportRun.widget;
		disableStatsMode();
		$scope.loadingInProgress = true;

		// all further manipulations on properties should not be persisted!
		widget = reportRunHelperService.getWidgetCopyForReportRun(widget);

		updatePageProperties(widget);

		getAndDisplayData(reportRun, widget, displayWidgetPagedData);
	};

	function displayWidgetPagedData(data, reportRun): ng.IPromise<any> {
		$scope.state.loading = data;

		widgetsCache.addLoading(getWidgetId(), true);

		return data.then((dataObject) => {
			$scope.pagination.state.globalDataObject = dataObject;

			let pageData: IPageData = (!dataObject.data)
				? {data: null, countInfo: null}
				: {data: [], countInfo: {count: 0, entitiesCount: 0}};

			if (!dataObject.countInfo && dataObject.total && dataObject.total.volume) {
				dataObject.countInfo = {
					count: dataObject.total.volume,
					entitiesCount: dataObject.total.entitiesVolume
				};
			}

			const reportContainsData = !_.isUndefined(dataObject.data)
				&& !_.isUndefined(dataObject.countInfo)
				&& dataObject.countInfo.count > 0;

			if (reportContainsData) {
				ReportPaginationService.populatePagingMetadata(pageData, $scope.pagination);
				previewChunkService.processSentenceChunks(dataObject.data);

				pageData.countInfo = dataObject.countInfo;

				ReportPaginationService.ensureStartEnd($scope.pagination, dataObject);

				pageData.data = $scope.pagination.state.globalDataObject.data.slice(
					$scope.pagination.state.startBound - $scope.pagination.state.storedStart,
					$scope.pagination.state.endBound - $scope.pagination.state.storedStart);
				pageData.metadata = dataObject.metadata;

				if (pageData.metadata) {
					pageData.metadata.totalCount = dataObject.total && dataObject.total.volume;
				}

				$scope.pagination.pages.totalItems = dataObject.countInfo.entitiesCount || dataObject.countInfo.count;
				currentWidgets.setPaginationTotal($scope.widget.containerId, $scope.widget.id, $scope.pagination.pages.totalItems);
				$scope.state.showPager = true;
				initPaginationObserver();

				if (dataObject.data.isEmpty()) {
					$scope.pagination.pages.checkAvailablePages();
				}

			} else {
				$scope.state.showPager = false;
			}

			$scope.pagination.totalItemsFormatted = reportNumberFormatUtils.formatMetric('volume',
				reportContainsData ? dataObject.countInfo.count : 0);


			$scope.state.view.data = pageData as ReportDataObject;

			$scope.widget.details = angular.copy(dataObject.metadata);

			redraw();
			widgetsCache.addData(getWidgetId(), dataObject);
			if ($scope.utils?.allColumns) {
				widgetsCache.addColumns(getWidgetId(), $scope.utils.allColumns);
			}
			$scope.state.loading = null;
			$scope.loadingInProgress = false;
			$scope.state.message = '';

			if (dataObject.metadata.reportExecutionAssets) {
				processReportExecutionAssets(reportRun.widget, dataObject);
			} else {
				$rootScope.$broadcast('widgetRefreshFinishedEvent', $scope.widget);
			}


			return dataObject;
		}, errorHandler);
	}

	function errorHandler(reason): void {
		if (reason && reason.data) {
			reason = reason.data;
		}

		if (reason === 'outdated report') {
			if ($scope.loadingInProgress) {
				$scope.state.loading = $q.defer().promise;
			}
			return;
		}
		if ($scope.widget && $scope.widget.details) {
			delete $scope.widget.details.totalCount;
		}

		$log.error('Error in report:', reason);
		$scope.state.message = errorMessageProcessing.processGeneralReportError(reason, $scope.widget);
		$scope.state.messageClass = errorMessageProcessing.getAdditionalMessageClass($scope.state.message);
		$scope.state.widgetError = new WidgetError($scope.state.message);

		$scope.state.loading = null;
		$scope.loadingInProgress = false;
		$scope.state.view.data = [] as any;
		widgetsCache.addLoading(getWidgetId(), false);
		widgetsCache.addData(getWidgetId(), []);
		responsiveReportService.setDisplayingFreshData($scope.widget);

		reportUtils.handleWidgetRenderedEvent($scope.widget.id, $scope.widget.name, $scope.widget.containerId);

		$rootScope.$broadcast('widgetRefreshFinishedEvent', $scope.widget);
	}

	function initPaginationObserver(): void {
		let paginationResizeObserver;

		if (!paginationResizeObserver && previewHelper.isAnalyticsPreview($scope.widget.properties.widgetType)) {
			let paginationContainer = getPaginationContainer();
			paginationResizeObserver = ResizeHandlerUtils.addResizeHandler(paginationContainer,
				_.throttle(onPaginationContainerResize, 400));
			$scope.$on('$destroy', () => {
				ResizeHandlerUtils.removeResizeHandler(paginationResizeObserver);
			});
			onPaginationContainerResize();
		}

		function onPaginationContainerResize(): void {
			let containerWidth = getPaginationContainerWidth();
			let sampleSizeWidth =  $scope.widget.visualProperties.showSampleSize !== false
				? getSampleSizeWidth()
				: 0;
			const fullPaginationWidth = 560;
			$scope.state.useCompactPager = containerWidth - sampleSizeWidth < fullPaginationWidth;
		}

		function getPaginationContainer(): HTMLElement {
			const paginationContainerSelector = '.br-widget-footer';
			//element itself refers to comment
			return $element.parent().find(paginationContainerSelector).get(0) as HTMLElement;
		}

		function getPaginationContainerWidth(): number {
			let paginationContainer = getPaginationContainer();
			return paginationContainer.clientWidth;
		}

		function getSampleSizeWidth(): number {
			const paginationContainerSelector = '.br-widget-total';
			let sampleSizeEl = $element.parent().find(paginationContainerSelector).get(0) as HTMLElement;
			return sampleSizeEl.clientWidth;
		}
	}

	/**
	 * @param reportRun { widget, visualization, utils, visualProperties, dontShowSpinner, responsiveness }
	 */
	$scope.runReport = (reportRun: ReportRun) => {
		let widget: Widget = reportRun.widget;
		const viewUtils: WidgetUtils = reportRun.utils;

		disableStatsMode();
		$scope.loadingInProgress = true;

		if (widgetService.isDataNotRequired(widget)) {
			$scope.state.loading = null;
		} else {
			// these modifications will be persisted for whole widget lifetime
			reportRunHelperService.updateInitialWidget(widget, viewUtils.metrics);
			// after copying modifications won't be persisted
			widget = reportRunHelperService.getWidgetCopyForReportRun(widget);
		}

		reportRunHelperService.updateWidgetForReportRun(widget, viewUtils.attributes, viewUtils.metrics).then(() => {
			getAndDisplayData(reportRun, widget, displayWidgetData);
		});
	};

	function displayWidgetData(data, reportRun): void {
		const widgetSettings = reportRun.widget;
		const viewUtils = reportRun.utils;

		const skipSpinner = reportRun.dontShowSpinner || widgetService.isDataNotRequired(widgetSettings);
		if (!skipSpinner) {
			$scope.state.loading = data;
		}

		widgetsCache.addLoading(getWidgetId(), true);

		return data.then((responses) => {
			if (KeyTermsUtils.isKeyTermsWidget(widgetSettings.properties.selectedAttributes)) {
				responses.data = KeyTermsUtils.getDataWithRanks(responses.data);
			}

			const dataObject = responses;

			$scope.state.message = '';

			$scope.state.view.data = dataObject;
			if (dataObject.total) {
				dataObject.metadata.totalCount = dataObject.total.volume;
			}
			$scope.widget.details = angular.copy(dataObject.metadata);

			$scope.showDataPointLimitWarning = $scope.widget.details
				? $scope.widget.details.isCustomGroupLimitExceeded
				: false;

			redraw();
			widgetsCache.addData(getWidgetId(), $scope.state.view.data);
			if (viewUtils?.allColumns) {
				widgetsCache.addColumns(getWidgetId(), viewUtils.allColumns);
			}

			$scope.state.loading = null;
			$scope.loadingInProgress = false;
			$scope.state.message = '';

			if (dataObject.metadata.reportExecutionAssets) {
				processReportExecutionAssets(widgetSettings, dataObject);
			} else {
				$rootScope.$broadcast('widgetRefreshFinishedEvent', $scope.widget);
			}

			responsiveReportService.updateCacheTimestamp($scope.widget, dataObject);

			return dataObject;
		}, errorHandler);
	}

	function getAndDisplayData(reportRun: ReportRun, widget: Widget, displayDataCallback): void {
		responsiveReportService.getAndDisplayData({
			reportRun,
			widget,
			dashboard: $scope.dashboardData?.dashboard,
			getData: (widgetSettings) => {
				return $scope.getData(widgetSettings);
			},
			displayData: displayDataCallback
		});
	}

	function runReportInternal(reportRunContext): void {
		if (_.isUndefined(reportRunContext)
				|| _.isUndefined(reportRunContext.widget)
				|| _.isUndefined(reportRunContext.utils)) {
			return;
		}

		const isPdfExport = !_.isUndefined($scope.pdfToken)
			&& !_.isUndefined($scope.pdf)
			&& !_.isUndefined($scope.clientTimezone);

		const widget = reportRunContext.widget;
		if (previewHelper.isDocumentPreview(widget) && !isPdfExport && (!$scope.widgetCurrentPage || $scope.widgetCurrentPage === 1)) {
			$scope.$broadcast('DocumentPreviewRefresh', {fullReload: true});
			return;
		}

		$scope.selectAndRunReport(reportRunContext);
	}

	function processReportExecutionAssets(widget, dataObject): void {
		let assets = dataObject.metadata.reportExecutionAssets;

		let filterList = combinedFiltersService.convertAndGroupFilters(
			assets.designerFilters, assets.appliedFilters, assets.scorecardFilters, $scope.widget.properties.project);

		reportProcessingService.getWidgetFiltersMetadata(widget, getDashboardHistory(), filterList).then(widgetMetadata => {
			initializeAppliedFilters(widgetMetadata);
			$rootScope.$broadcast('widgetRefreshFinishedEvent', $scope.widget);
		});
	}

	$scope.selectAndRunReport = (reportRunContext) => {
		if (previewHelper.isDocumentPreview(reportRunContext.widget)) {
			return;
		} else {
			if (previewHelper.isPaginated(reportRunContext.widget)) {
				$scope.runReportPaginated(reportRunContext);
			} else {
				$scope.runReport(reportRunContext);
			}
		}
	};

	function changeWidgetOwner(oldOwnerEmail, newOwnerEmail): void {
		if (oldOwnerEmail === $scope.widget?.properties?.runAs) {
			$scope.widget.properties.runAs = newOwnerEmail;
		}
	}

	function isPeerReporting(): boolean {
		const personalization = getDashboardHistory().getPersonalization();
		return hierarchyService.isPeerReport(personalization, $scope.widget.properties);
	}

	function isUsingPlacementColor(): boolean {
		return isPeerReporting() && $scope.dashboardData.dashboard.properties.userPlacementEnabled;
	}

	$scope.isRecolorSupported = () => {
		return ReportConstants.isAnalyticWidgetAndSupport($scope.widget.properties.widgetType,
				{color: true}, isUsingPlacementColor());
	};

	$scope.isStatsPanelActive = () => {
		return $scope.state.showStatsPanel;
	};

	$scope.isStatsPanelVisible = () => { // need to keep it visible till animation ends
		return $scope.state.statsMode || $scope.state.descriptionMode;
	};

	$scope.toggleStatsMode = (descriptionOnly: boolean = false) => {
		const ANIMATION_DELAY = 0.6;
		if ($scope.state.showStatsPanel) {
			$scope.state.showStatsPanel = false;
			$timeout(() => {
				$scope.state.statsMode = false;
				$scope.state.descriptionMode = false;
			}, ANIMATION_DELAY);
		} else {
			$scope.state.statsMode = !descriptionOnly;
			$scope.state.descriptionMode = !!descriptionOnly;
			$scope.state.showStatsPanel = true;
		}
	};

	function disableStatsMode(): void {
		$scope.state.showStatsPanel = false;
		$scope.state.statsMode = false;
		$scope.state.descriptionMode = false;
	}

	$scope.enterLinkingMode = (widget, layout: LayoutHelper) => {
		layout.linkingState = WidgetActionUtils.getLinkingData(widgetsEditService.getWidgets());
		layout.linkingSource = widget;
		if (ReportConstants.isSelectorWidget(widget.properties.widgetType)) {
			$scope.clearSelections();
		} else {
			$rootScope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, widget, null);
		}
	};

	function resetWidgetFilters(): void {
		const needRefresh = widgetLinkingService.resetWidgetFiltering($scope.widget);

		if (needRefresh) {
			refreshWidgetInternal({
				cacheDynamicProperties: CacheOptions.CACHED
			});
		}
	}

	let refreshDelay;

	function updateWidgetFilters(event, parent, point): void {
		if (!_.contains($scope.widget.linkedTo, parent.id) || $scope.widget.containerId !== parent.containerId) {
			return;
		}
		const isSelectorWidget = ReportConstants.isSelectorWidget(parent.properties.widgetType);

		const needRefresh = isSelectorWidget
			? widgetLinkingService.applySelectorWidgetFiltering($scope.widget, parent, point, parent.visualProperties.multiselect)
			: widgetLinkingService.applyWidgetFiltering($scope.widget, parent, point);

		const needDelay = point && isSelectorWidget && parent.visualProperties.multiselect;

		if (needRefresh) {
			delayRefresh(needDelay, 1000);
		}
	}

	function delayRefresh(isDelay, length: number): void {
		if (isDelay) {
			$timeout.cancel(refreshDelay);
			refreshDelay = $timeout(() => {
				refreshWidgetInternal({
					cacheDynamicProperties: CacheOptions.CACHED
				});
			}, length);
		} else {
			refreshWidgetInternal({
				cacheDynamicProperties: CacheOptions.CACHED
			});
		}
	}

	function getDashboardHistory(): IDashboardHistoryInstance {
		return $scope.dashboardData?.dashboardHistory;
	}

	$scope.forbiddenToCopy = () => {
		const dashboard = $scope.dashboardData.dashboard;
		const isHomePageWidgetCopyAllowed = HomePageWidgetConstants.isHomePageWidget($scope.widget)
			&& (dashboard.permissions.EDIT || dashboard.permissions.OWN)
			&& !HomePageWidgetConstants.isTrendWidget($scope.widget);
		const isCopyAllowed = currentObjects.isEditMode() || isHomePageWidgetCopyAllowed;
		return !isCopyAllowed
			|| $scope.widget.name === WidgetType.SCORECARD;
	};

	$scope.forbiddenToRefresh = (widget: Widget): boolean => {
		return isEnterpriseUser() && widgetService.isEnterpriseViewersAllowed(widget);
	};

	$scope.isSnapshotView = (): boolean => {
		return currentObjects.isSnapshotView() && $scope.isFrontlineWidget();
	};

	function getLinkedWidgetInfo(widget: Widget): LinkedFilter {
		const filters = currentWidgets.getAllLinkedFilters(widget.containerId);

		for (const linkedFilters of _.values(filters)) {
			const linkedWidget = _.findWhere(linkedFilters, {id: widget.id});

			if (linkedWidget) {
				return linkedWidget;
			}
		}

		return null;
	}

	$scope.reloadUtils = (widget: Widget): ng.IPromise<WidgetUtils> => {
		return widgetUtilsService.getBuilder(widget)
			.withDashboardProperties($scope.dashboardData.dashboard?.properties)
			.withPreviewSettings($scope.filteringFeedbackSelection, $scope.tuningSuggestions, $scope.translationEnabled)
			.build()
			.then(utils => {
				$scope.utils = utils;
				return utils;
			});
	};

	$scope.redraw = redraw;
	function redraw(): void {
		$scope.state.view.trigger++;
	}

	$scope.isWidgetOwner = (): boolean => {
		return security.isCurrentUser($scope.widget.properties.runAs);
	};

	$scope.isFrontlineWidget = (): boolean => {
		return FrontlineDashboardUtils.isFrontlineDashboard($scope.dashboardData?.dashboard);
	};

	$scope.isWidgetExportable = (zoomMode: boolean): boolean => {
		return !$scope.widget.multiSelection &&
			!zoomMode &&
			($scope.canExportData() || $scope.canExportImage());
	};

	$scope.isModernLookAndFeelEnabled = (): boolean => {
		return $scope.dashboardData?.dashboard.properties.modernLookAndFeelEnabled || false;
	};

	$scope.titleShouldFillHeader = (): boolean => {
		return (!$scope.appliedFilters || !$scope.appliedFilters.showFilterDropdown())
		&& (!$scope.showTotalCount() || !$scope.isModernLookAndFeelEnabled());
	};

	$scope.isFooterHidden = (warningEnabled = false): boolean => {
		return !$scope.isRubricDefinitionReport() && !$scope.isStatsPanelVisible() &&
		(($scope.showTotalCount() && $scope.isModernLookAndFeelEnabled()) && !warningEnabled);
	};

});
