import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FullScreenAutoRefreshService } from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { FullscreenService } from './fullscreen.service';
import { SessionService } from '@app/core/sessions/session.service';
import { SessionConsumers } from '@app/core/sessions/session-consumers.enum';

@Component({
	selector: 'fullscreen-button',
	template: `
<button class="btn btn-icon no-border btn-margin-left"
	id="toggle-full-screen"
	(click)="toggleFullscreen()"
	[attr.aria-label]="getAriaLabel()"
	[disabled]="isFullscreenDisabled()"
	[ngClass]="isFullscreen() ? 'q-icon-minimise' : 'q-icon-maximise'">
</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenButtonComponent implements OnInit, OnDestroy {
	readonly DEFAULT_REFRESH_INTERVAL = 10; // default amount of minutes is 10
	@Input() private ariaName: string;

	constructor(
		private readonly fullscreenAutorefresh: FullScreenAutoRefreshService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
		private readonly sessionService: SessionService,
		public fullscreen: FullscreenService,
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService
	) {
	}

	@HostListener('window:keydown.f11', ['$event'])
	onF11(event: KeyboardEvent) {
		event.preventDefault();
		this.toggleFullscreen();
	}

	@HostListener('keydown.enter', ['$event'])
	toggleFullscreenKb(event: KeyboardEvent) {
		event.preventDefault();
		this.toggleFullscreen();
	}

	ngOnInit(): void {
		this.fullscreen.on('change', this.onChange);
	}

	ngOnDestroy(): void {
		this.fullscreen.off('change', this.onChange);
	}

	/**
	 * Is fullscreen even allowed in the browser
	 */
	isFullscreenDisabled = (): boolean => !this.fullscreen.isEnabled;

	/**
	 * Is fullscreen mode active
	 */
	isFullscreen = (): boolean => this.fullscreen.isFullscreen;

	getAriaLabel = (): string => {
		return this.isFullscreen()
			? this.locale.getString('dashboard.exitFullscreenLabel')
			: this.locale.getString('dashboard.fullscreenLabel', { name: this.ariaName || '' });
	}

	/**
	 * Callback after every browser fullscreen toggle event
	 */
	onChange = (): void => {
		this.fullscreen.isFullscreen = !this.fullscreen.isFullscreen;
		if (this.fullscreen.isFullscreen) {
			this.sessionService.startSessionKeepalive(SessionConsumers.FULLSCREEN);
		} else {
			this.sessionService.stopSessionKeepalive(SessionConsumers.FULLSCREEN);

			if (this.environmentService.isApplicationOutdated()) {
				this.environmentService.disregardChangesAndRefresh();
			}
		}

		this.ref.markForCheck();
	}

	/**
	 * Toggles the fullscreen view
	 */
	toggleFullscreen = (): void => {
		if (this.fullscreen.isFullscreen) {
			this.fullscreenAutorefresh.stop();
			this.fullscreen.exit();
		} else {
			this.fullscreenAutorefresh.start(this.DEFAULT_REFRESH_INTERVAL);
			this.fullscreen.enter();
		}
	}
}
