import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ExternalApplicationsApiService {

	constructor(
		private cxHttp: CxHttpService
	) {}

	getApplications = (): Promise<ExternalApplication[]> => {
		return this.cxHttp.get('rest/system_admin/external_application/list');
	}

	getLegacySuiteApplications = (): Promise<ExternalApplication[]> => {
		return this.cxHttp.get('rest/system_admin/external_application/list/legacy_suite');
	}

	getApplicationsByType = (type: ExternalApplicationType): Promise<ExternalApplication[]> => {
		let params = new HttpParams().append('type', type);
		return this.cxHttp.get('rest/system_admin/external_application/list', { params });
	}

	createApplication = (application: ExternalApplication): Promise<void> => {
		return this.cxHttp.post('rest/system_admin/external_application', application);
	}

	getApplicationById = (id: number): Promise<ExternalApplication> => {
		return this.cxHttp.get('rest/system_admin/external_application/' + id);
	}

	updateApplication = (id: number, application: ExternalApplication): Promise<void> => {
		return this.cxHttp.put('rest/system_admin/external_application/' + id, application);
	}

	removeApplication = (id: number): Promise<void> => {
		return this.cxHttp.delete('rest/system_admin/external_application/' + id);
	}

}

app.service('externalApplicationsApiService', downgradeInjectable(ExternalApplicationsApiService));
