export class WidgetsCache {
	data: {[widgetId: number]: any} = {};
	loading: {[widgetId: number]: boolean} = {};
	columns: {[widgetId: number]: any} = {};

	getData(widgetId: number): any {
		return this.data[widgetId];
	}
	getColumns(widgetId: number): any {
		return this.columns[widgetId];
	}
	isLoading(widgetId: number): boolean {
		return this.loading[widgetId];
	}
	addData(widgetId: number, data: any): void {
		this.data[widgetId] = data;
		this.loading[widgetId] = false;
	}
	addColumns(widgetId: number, data: any): void {
		this.columns[widgetId] = data;
	}

	addLoading(widgetId: number, value: boolean): void {
		this.loading[widgetId] = value;
	}
}
