import { ScreeningSettings } from '@app/modules/account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import BetaFeatureState from '@app/modules/context/beta-features/beta-feature-state';
import { TuningSuggestionsSettings } from '@cxstudio/master-accounts/master-account-properties.class';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { LinkSharingSettings } from '@cxstudio/master-accounts/settings-components/link-sharing-settings';
import BaseMasterAccount from '@cxstudio/system-administration/master-accounts/base-master-account';
import { QualtricsIntegration } from '@cxstudio/system-administration/master-accounts/integrations/qualtrics-integration';
import { TuningSuggestionIntegration } from '@cxstudio/system-administration/master-accounts/integrations/tuning-suggestion-integration';


export class CurrentMasterAccount extends BaseMasterAccount {
	disabled: boolean;
	expired: boolean;
	samlEnabled: boolean;
	adminOrg: boolean;
	meaningfulSilenceThreshold: number;
	vociEnabled: boolean;
	engagorEnabled: boolean;
	engagorExternalEngagementEnabled: boolean;
	connectorsEnabled: boolean;
	customField: string;
	audioDownloadEnabled: boolean;
	transcriptDownloadEnabled: boolean;
	maxReportingWidgets: number;
	maxNonReportingWidgets: number;
	maxParallelRequests: number;
	maxCustomerAdmins: number;
	logoUrl: string;
	betaFeatures: BetaFeatureState[];
	customLogoEnabled: boolean;
	customBrandingEnabled: boolean;
	darkModeAvailable: boolean;
	publicProperties: {[key in MasterAccountProperty]: boolean};
	predictionProviderDefault: boolean;
	predictionEnabled: boolean;
	linkSharingSettings: LinkSharingSettings;
	tuningSuggestionsSettings: TuningSuggestionsSettings;
	tuningSuggestionIntegration: TuningSuggestionIntegration;
	screeningSettings: ScreeningSettings;
	widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings;
	useMoreAccessibleColor: boolean;
	qualtricsWidgetEmbeddingEnabled: boolean;
	qualtricsDashboardEmbeddingEnabled: boolean;
	qualtricsIntegrationEnabled: boolean;
	qualtricsBrandId: string;
}
