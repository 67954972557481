import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[scrollingModal]'
})
export class ScrollingModalDirective implements OnInit {

	@Input() scrollingModal;

	constructor(
		private readonly el: ElementRef,
		private readonly renderer: Renderer2
	) {}

	ngOnInit(): void {
		let margin = Number.parseInt(this.scrollingModal, 10) || 200;
		let maxHeight = window.innerHeight - margin;
		this.renderer.setStyle(this.el.nativeElement, 'max-height', `${maxHeight}px`);
		this.renderer.setStyle(this.el.nativeElement, 'overflow-y', 'auto');
	}
}
