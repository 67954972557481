import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { DashboardAssetParameters } from '@app/modules/asset-management/access/asset-parameters/dashboard-asset-parameters';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

export class RequestAssetParametersFactory {
	static fromWidget(widget: Widget, withRunAs = true): RequestAssetParameters {
		let accountProject = widget.properties.contentProviderId
			? ProjectIdentifier.fromWidgetProperties(widget.properties)
			: undefined;
		return new RequestAssetParameters(
			AssetParametersFactory.fromWidget(widget, withRunAs),
			accountProject,
			widget.properties.workspaceProject
		);
	}

	static fromDashboard(dashboard: Dashboard): RequestAssetParameters {
		let accountProject = dashboard.properties.cbContentProvider
			? ProjectIdentifier.fromDashboardProperties(dashboard.properties)
			: undefined;
		return new RequestAssetParameters(
			DashboardAssetParameters.fromDashboard(dashboard),
			accountProject,
			dashboard.workspaceProject
		);
	}
}
