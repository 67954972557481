import * as cloneDeep from 'lodash.clonedeep';

import { Injectable, Inject, EventEmitter } from '@angular/core';
import {
	ConversationSettingsEditorDialogInput,
	ConversationSettingsModalComponent
} from './conversation-settings-modal/conversation-settings-modal.component';
import { ConversationSettingsEntry } from './conversation-settings.entity';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import ProjectSettingsService from '@cxstudio/services/data-services/project-settings.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ConversationSettingsApi } from '@app/modules/conversation/conversation-settings-api.service';
import { ConversationSpineDefaults } from '@app/modules/conversation/conversation-spine-defaults';

@Injectable()
export class ConversationSettingsListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private cxDialogService: CxDialogService,
		private readonly conversationSpineDefaults: ConversationSpineDefaults,
		private readonly conversationSettingsApi: ConversationSettingsApi,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		private readonly metricsService: MetricsService,
		private locale: CxLocaleService,
		@Inject('projectSettingsService') private projectSettingsService: ProjectSettingsService
	) { }

	edit(settingsEntry: ConversationSettingsEntry): void {
		let predefinedMetricsPromise = settingsEntry.projectId
			? this.metricsService.getDynamicPredefinedMetrics(settingsEntry, true)
			: this.metricsService.getPredefinedMetricDefaultsForMasterAccount(true);
		let modelsAndAttributes = settingsEntry.projectId
			? this.projectSettingsService.getSettings(settingsEntry)
			: of(undefined);

		forkJoin([predefinedMetricsPromise, modelsAndAttributes])
			.subscribe((response) => {
				let dialogInput = {
					settingsEntry,
					predefinedMetrics: response[0],
					modelsAndAttributes: response[1]
				} as ConversationSettingsEditorDialogInput;
				this.cxDialogService.openDialog(ConversationSettingsModalComponent, dialogInput, {size: ModalSize.MEDIUM}).result
					.then(this.saveSettings)
					.catch(() => {});
			});
	}

	resetDefault(settingsEntry: ConversationSettingsEntry): void {
		settingsEntry.settings = cloneDeep(this.conversationSpineDefaults.get());
		this.saveSettings(settingsEntry);
	}

	/** Upgrade enabled flag to explicit true/false */
	private setEnabledToBoolean(settingsEntry: ConversationSettingsEntry): void {
		if (_.isUndefined(settingsEntry.enabled))
			settingsEntry.enabled = true;
	}

	private saveSettings = (settingsEntry: ConversationSettingsEntry, customMessage?: string): void => {
		this.setEnabledToBoolean(settingsEntry);
		this.conversationSettingsApi.saveSpineSettings(settingsEntry).then(() => {
			this.globalNotificationService.addSuccessNotification(customMessage || this.locale.getString('appearance.convoSettingsSavedToast'));
			this.onChange.emit();
		});
	}

	delete(settings: ConversationSettingsEntry): void {
		this.cbDialogService.danger(this.locale.getString('administration.delete'),
			this.locale.getString('appearance.deleteCustomDisplayWarning', {projectName: settings.name})).result
			.then((result) => {
				this.conversationSettingsApi.deleteCustomSpineSettings(settings).then(() => {
					this.globalNotificationService.addSuccessNotification(
						this.locale.getString('appearance.convoSettingsDeletedToast', {projectName: settings.name}));
					this.onChange.emit();
				});
			});

	}

	toggle(settings: ConversationSettingsEntry): void {
		this.setEnabledToBoolean(settings);
		settings.enabled = !settings.enabled;
		let confirmationMessage = settings.enabled ?
			this.locale.getString('common.itemEnabled', {itemName: settings.displayName}) :
			this.locale.getString('common.itemDisabled', {itemName: settings.displayName});

		return this.saveSettings(settings, confirmationMessage);
	}
}
