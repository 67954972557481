import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { DocumentExplorerModule } from '../document-explorer/document-explorer.module';
import { InteractionActionsService } from './interaction-actions.service';
import { InteractionApiService } from './interaction-api.service';
import { InteractionExplorerGridDefinition } from './interaction-explorer-grid-definition.service';
import { InteractionFilterComponent } from './interaction-filter.component';
import { FilterBuilderModule } from '../filter-builder/filter-builder.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InteractionSettingsService } from '@app/modules/interaction-explorer/interaction-settings.service';
import { DeleteDocumentsDialogComponent } from '@app/modules/interaction-explorer/delete-documents-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		DocumentExplorerModule,
		NgPipesModule,
		FilterBuilderModule,
		NgbModule
	],
	providers: [
		InteractionActionsService,
		InteractionExplorerGridDefinition,
		InteractionApiService,
		InteractionSettingsService
	],
	exports: [
		InteractionFilterComponent
	],
	declarations: [
		InteractionFilterComponent,
		DeleteDocumentsDialogComponent,
	],
})
export class InteractionsModule {}
