import { NgModule } from '@angular/core';
import { ɵDomSharedStylesHost } from '@angular/platform-browser';
import { CustomDomSharedStylesHost } from './shared-styles-host.service';

@NgModule({
	providers: [
		{ provide: 'cspMetaSelector', useValue: 'meta[name="CSP-NONCE"]' },
		{ provide: ɵDomSharedStylesHost, useClass: CustomDomSharedStylesHost }
	]
})
export class InlineStylesCSPModule {}
