import { BookValidationService } from '@cxstudio/dashboards/books/book-validation.service';
import * as _ from 'underscore';
import { BookTabType, IBookTab } from '../entity/book';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { Security } from '@cxstudio/auth/security-service';


export class BookEditTabController implements ng.IController {
	static MAX_TAB_LENGTH = 50;

	tab: IBookTab;
	onDelete: () => void;
	onSelect: () => void;
	onTabClick: () => void;

	maxTabLength = BookEditTabController.MAX_TAB_LENGTH;

	constructor(
		private security: Security,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService,
		private bookValidationService: BookValidationService) {}

	$onInit(): void {}

	onTabSelect = (): void => {
		if (this.onSelect)
			this.onSelect();
		this.$timeout(() => {
			this.$element.find('input').trigger('focus');
		});
	}

	onTabSelectKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.onTabSelect();
		}
	}

	isNameEmpty = (): boolean => {
		return this.bookValidationService.tabNameInvalid(this.tab);
	}

	tabNotLinked = () => {
		return this.bookValidationService.tabEmpty(this.tab);
	}

	onTabClickKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.onTabClick();
		}
	}

	onDeleteKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.onDelete();
		}
	}

	allowTabEditing = (): boolean => {
		if (this.tab.active && this.tab.type === BookTabType.EMBED) {
			return this.security.isQualtricsDashboardEmbeddingEnabled();
		}

		return this.tab.active;
	}
}

app.component('bookEditTab', {
	controller: BookEditTabController,
	templateUrl: 'partials/dashboards/tabs/book-edit-tab.html',
	bindings: {
		tab: '<',
		onDelete: '&',
		onSelect: '&',
		onTabClick: '&',
	}
});
