import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';



@Component({
	selector: 'widget-filters-errors',
	template: `
		<alert type="warning" *ngIf="showNoFiltersAvailableWarning">
			<span [i18n]="'reportFilters.noFiltersAvailable'"></span>
		</alert>
		<alert type="success" *ngIf="filterSaveSuccess" [text]="getLastSavedFilterText()">
		</alert>

		<alert type="warning" *ngIf="filtersOverloadedSaved" [text]="getFiltersOverloadedSavedText()">
		</alert>

		<alert type="info" *ngIf="filtersOverloaded" [text]="getFiltersOverloadedText()">
		</alert>

	`
})

export class WidgetFiltersErrorsComponent {
	@Input() showNoFiltersAvailableWarning = false;
	@Input() filterSaveSuccess = false;
	@Input() lastSavedFilter = '';
	@Input() filtersOverloadedSaved = false;
	@Input() maxFilterLevels = 5;
	@Input() filtersOverloaded = false;

	constructor(private readonly locale: CxLocaleService) {
	}

	getLastSavedFilterText = () => this.locale.getString('reportFilters.filterSaveSuccess',
		{ filterName: this.lastSavedFilter})
	getFiltersOverloadedSavedText = () => this.locale.getString('reportFilters.filterCountWarning',
		{ filterName: this.lastSavedFilter, filterMaxCount: this.maxFilterLevels })
	getFiltersOverloadedText = () => this.locale.getString('reportFilters.filterCountNote',
		{ filterMaxCount: this.maxFilterLevels })
}

app.directive('widgetFiltersErrors', downgradeComponent({component: WidgetFiltersErrorsComponent}) as angular.IDirectiveFactory);

