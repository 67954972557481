import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { SentenceFiltersHelper } from '@app/modules/document-explorer/sentence-filters-helper';
import { CombinedFiltersService, FiltersGroup } from '@app/modules/filter/services/combined-filters.service';
import { CommonDrillService } from '@app/modules/reports/utils/context-menu/drill/common-drill.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardTextFilterProcessorService } from '@app/modules/dashboard/widget-dashboard-text-filter-processor.service';
import { WidgetDashboardCommonFiltersProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-dashboard-common-filters-processor.service';
import { WidgetDashboardDateFilterProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-dashboard-date-filter-processor.service';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { WidgetDashboardPersonalizationProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-dashboard-personalization-processor.service';
import { WidgetDrillableDashboardFilterProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-drillable-dashboard-filter-processor.service';
import { WidgetSavedDashboardFiltersProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-saved-dashboard-filters-processor.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewVisualProperties } from '@cxstudio/reports/entities/preview-visual-properties';
import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { QuickFilter, QuickFilterService } from '@app/modules/reports/filters/quick-filter.service';
import { WidgetModelGroupingFiltersService } from '@app/modules/dashboard/dashboard-filter-processors/widget-model-grouping-filters.service';
import { WidgetTextFilterProcessor } from '@app/modules/reports/filters/widget-text-filter-processor.service';
import { WidgetGroupingFilter } from '@app/modules/reports/filters/WidgetGroupingFilter';
import { AnalyticFeedbackSelectionUtils } from '@cxstudio/reports/preview/analytic-feedback-selection-utils.service';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewService } from '@cxstudio/reports/preview/preview-service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import * as _ from 'underscore';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { AlertPreviewService } from '@app/modules/alert/services/alert-preview.service';

export class CustomFilterService {

	constructor(
		private readonly $q: ng.IQService,
		private readonly locale: ILocale,
		private readonly commonDrill: CommonDrillService,
		private readonly currentWidgets: ICurrentWidgets,
		private readonly combinedFiltersService: CombinedFiltersService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly alertPreviewService: AlertPreviewService,
		private readonly previewService: PreviewService,
		private readonly widgetModelGroupingFilters: WidgetModelGroupingFiltersService,
		private readonly widgetDashboardDateFilterProcessor: WidgetDashboardDateFilterProcessorService,
		private readonly widgetSavedDashboardFiltersProcessor: WidgetSavedDashboardFiltersProcessorService,
		private readonly widgetDashboardCommonFiltersProcessor: WidgetDashboardCommonFiltersProcessorService,
		private readonly widgetDashboardPersonalizationProcessor: WidgetDashboardPersonalizationProcessorService,
		private readonly widgetDrillableDashboardFilterProcessor: WidgetDrillableDashboardFilterProcessorService,
		private readonly widgetDashboardTextFilterProcessor: WidgetDashboardTextFilterProcessorService,
		private readonly widgetTextFilterProcessor: WidgetTextFilterProcessor,
		private readonly filterValidationService: FilterValidationService,
		private readonly quickFilterService: QuickFilterService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly narrativeSettingsApi: NarrativeSettingsApi
	) {}

	//changes will saved in mongo
	preprocessWidgetProperties = (widgetSettings: Widget): void => {
		let props = widgetSettings.properties;
		if (props.appliedFilters) {
			props.appliedFilters.filters = props.appliedFilters.filters.filter(f => {
				return f.type !== FilterTypes.EMPTY;
			});
		}

		if (props.adhocFilter) {
			props.adhocFilter.filterRules = props.adhocFilter.filterRules.filter(f => {
				return f.type !== FilterRuleType.empty;
			}).filter(f => {
				return this.filterValidationService.validateFilterRuleDefinition(f) === true;
			});
		}

		this.removeDisabledAttributes(widgetSettings);
	}

	private removeDisabledAttributes(widgetSettings: Widget): void {
		if (widgetSettings.name === WidgetType.PREVIEW) {
			let visualProps = widgetSettings.visualProperties as PreviewVisualProperties;
			let props = widgetSettings.properties as PreviewWidgetProperties;
			let selectedAttributes = props.selectedAttributes;

			let isDisabled = (column: TableColumn<any>): boolean => {
				if (column.disabled) return true;

				if (!visualProps.contextPaneEnabled && props.previewMode === PreviewMode.DOCUMENT) {
					return column.type !== ReportAssetType.TOPICS && column.name !== PreviewColumn.SENTENCE;
				}

				if (visualProps.visualization === WidgetVisualization.PREVIEW_BUBBLES) {
					return column.type !== PreviewDataType.SYS || column.name === PreviewColumn.TOPICS;
				}

				return false;
			};

			_.each(visualProps.columns, (column: TableColumn<any>) => {
				if (isDisabled(column)) {
					selectedAttributes.remove(_.findWhere(selectedAttributes, { name: column.name }));
					if (column.name === PreviewColumn.TOPICS) {
						props.includeTopics = false;
						props.selectedModels = [];
					}
				}
			});

			visualProps.columns = _.filter(visualProps.columns, (column) => !isDisabled(column));
		}
	}

	//changes on the fly, no save in mongo
	// transforms widget filters and applies dashboard filters
	postprocessWidgetProperties = (widget: Widget, filtersProvider?: IDashboardHistoryInstance,
		skipFeedbackSelection = false): ng.IPromise<Widget> => {

		return this.processDashboardFiltersPromise(widget, filtersProvider)
			.then(this.processWidgetFilters)
			.then(this.processLinkedFilters)
			.then(this.widgetTextFilterProcessor.process as any)
			.then(widgetSettings => this.$q.when(skipFeedbackSelection
				? widgetSettings
				: this.processAnalyticFeedbackSelectionFilters(widgetSettings as any)))
			.then(this.processPreviewQuickFilters)
			.then(this.processNarrativeAttributes);
	}

	private processDashboardFiltersPromise(widget: Widget,
		filtersProvider?: IDashboardHistoryInstance): ng.IPromise<Widget> {
		return this.processDashboardFilters(widget, filtersProvider).then(() => widget);
	}

	private isWidgetEligableForDashboardFilters(widgetSettings): boolean {
		return !this.alertPreviewService.isAlertPreviewWidget(widgetSettings)
				&& !this.previewService.isSelectivePreview(widgetSettings);
	}

	processWidgetFilters = (widgetSettings: Widget): ng.IPromise<Widget> => {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.RETRIEVE_REPORT_ASSETS)) {
			return this.$q.when(widgetSettings);
		}

		if (widgetSettings.properties.appliedFilters && !_.isEmpty(widgetSettings.properties.appliedFilters.filters)) {
			let project = this.reportAssetUtilsService.getWidgetProject(widgetSettings);
			let promise = widgetSettings.id || widgetSettings.parentWidget?.id
				? PromiseUtils.old(this.combinedFiltersService.getWidgetFilters(widgetSettings))
				: PromiseUtils.old(this.combinedFiltersService.getProjectFilters(project));
			return promise.then(allFilters => {
				this.updateAppliedFilters(allFilters, widgetSettings.properties.appliedFilters);
				if (this.checkAppliedFiltersForUnavailableFilters(widgetSettings.properties.appliedFilters)) {
					return this.$q.reject('applied filters unavailable');
				}
				return this.$q.when(widgetSettings);
			});
		} else {
			return this.$q.when(widgetSettings);
		}
	}

	processNarrativeAttributes = (widgetSettings: Widget): ng.IPromise<Widget> => {
		let props = widgetSettings.properties as PreviewWidgetProperties;
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES) && props.exportAttributes) {
			const project = this.reportAssetUtilsService.getWidgetProject(widgetSettings);
			return PromiseUtils.old(this.narrativeSettingsApi.getNarrativeSettings(project))
				.then(response => {
					let narrativeAttributes = _.chain(response)
						.filter(entry => !!entry.enabled)
						.map(entry => ({
							name: entry.attributeName.toLowerCase(),
							type: FavoriteType.ATTRIBUTE
						}))
						.value();
					props.exportAttributes = props.exportAttributes.concat(narrativeAttributes);
					props.narrativeAttributes = narrativeAttributes;
					return this.$q.when(widgetSettings);
				});
		}
		return this.$q.when(widgetSettings);
	}

	processAnalyticFeedbackSelectionFilters = (widgetSettings: Widget): Widget => {

		if (!widgetSettings.properties.drillFilters)
			widgetSettings.properties.drillFilters = [];

		if (widgetSettings.properties.analyticFeedbackSelection) {
			let drillFilter = AnalyticFeedbackSelectionUtils.getDrillFilter(widgetSettings,
				AnalyticFeedbackSelectionUtils.getCuratedItemType(widgetSettings),
				this.locale.getString('preview.feedbackSelection'));
			if (drillFilter)
				widgetSettings.properties.drillFilters.push(drillFilter);
		}

		return widgetSettings;
	}

	private processPreviewQuickFilters = (widgetSettings: Widget):
		ng.IPromise<Widget> => {

		if (widgetSettings.name !== WidgetType.PREVIEW)
			return this.$q.when(widgetSettings);

		let quickFilter: QuickFilter = this.quickFilterService.getAppliedFilter(widgetSettings);
		if (!quickFilter)
			return this.$q.when(widgetSettings);

		if (!widgetSettings.properties.drillFilters)
			widgetSettings.properties.drillFilters = [];

		let type = quickFilter.type;
		let mode = quickFilter.value;
		widgetSettings.properties.drillFilters.push(
			SentenceFiltersHelper.getPredefinedMetricFilter(type, mode));

		return this.$q.when(widgetSettings);

	}

	private processLinkedFilters = (widgetSettings: Widget): ng.IPromise<Widget> => {
		let linkedFilters = this.currentWidgets.getLinkedFilters(widgetSettings.containerId, widgetSettings.id);
		if (!_.isEmpty(linkedFilters)) {
			_.each(linkedFilters, filter => {
				this.commonDrill.addFiltersFromPoint(filter.widget, filter.point, widgetSettings, true);
			});
			this.processTopicLinkedFilters(widgetSettings, linkedFilters);
		}
		return this.$q.when(widgetSettings);
	}

	private processTopicLinkedFilters(widgetSettings: Widget, linkedFilters: LinkedFilter[]): void {
		_.chain(widgetSettings.properties.selectedAttributes)
			.filter(this.isTopicGrouping)
			.map(grouping => grouping as TopicReportGrouping)
			.filter(grouping => grouping.updateOnParentFilter)
			.each(grouping => {
				let modelId = grouping.name + '';
				let level = grouping.selectedLevel;
				let nodes = this.getApplicableNodes(linkedFilters, modelId, level, grouping);
				if (!_.isEmpty(nodes))
					grouping.filterNodes = nodes;
			});
	}

	private isTopicGrouping(grouping: AttributeGrouping): grouping is TopicReportGrouping {
		return grouping.type === ReportAssetType.TOPICS
			|| grouping.type === ReportAssetType.TOPIC_LEAF;
	}

	private getApplicableParentGroupings(widget: Widget, modelId: string): TopicReportGrouping  {
		return _.find(widget.properties.selectedAttributes, item => {
			return this.isTopicGrouping(item) && (item.name + '') === modelId;
		});
	}

	private getApplicableNodes(linkedFilters: LinkedFilter[], modelId: string, targetLevel, grouping: AttributeGrouping): number[][] {
		let grouppedNodes = _.chain(linkedFilters)
			.filter(filter => {
				// has required grouping
				return !!GroupIdentifierHelper.getGroupingField(filter.point, grouping,
					ReportConstants.FULL_PATH);
			})
			.map(filter => {
				return {
					widgetId: filter.widget.id,
					grouping: this.getApplicableParentGroupings(filter.widget, modelId),
					node: GroupIdentifierHelper.getGroupingField(filter.point, grouping,
						ReportConstants.FULL_PATH)
				};
			})
			.filter(groupWithNode => {
				return !!groupWithNode.grouping;
			})
			.filter(groupWithNode => {
				// same level or parent. Undefined === 1st level, i.e. always match
				return _.isUndefined(groupWithNode.grouping.selectedLevel)
					|| groupWithNode.grouping.selectedLevel <= targetLevel;
			})
			.map(groupWithNode => {
				return {
					widgetId: groupWithNode.widgetId,
					nodeId: parseInt(_.last(groupWithNode.node.split('/')), 10)
				};
			})
			.groupBy('widgetId')
			.value();

		return _.map(grouppedNodes, (entry) => _.pluck(entry, 'nodeId'));
	}

	updateAppliedFilters = (filtersList: any[], appliedFilters: any): void => {

		let filtersMap = {};
		filtersMap[FilterTypes.STUDIO] = this.getFilters(filtersList, FilterTypes.STUDIO);
		filtersMap[FilterTypes.CMP] = this.getFilters(filtersList, FilterTypes.CMP);
		filtersMap[FilterTypes.PREDEFINED] = this.getFilters(filtersList, FilterTypes.PREDEFINED);
		filtersMap[FilterTypes.SCORECARD] = this.getFilters(filtersList, FilterTypes.SCORECARD);

		if (!appliedFilters || !appliedFilters.filters) return;

		appliedFilters.filters.forEach(filter => {
			let existingFilter;
			if (filter.type === FilterTypes.PREDEFINED) {
				existingFilter = _.findWhere(filtersMap[filter.type], {subtype: filter.subtype, value: filter.value});
			} else if (filter.type === FilterTypes.SCORECARD) {
				existingFilter = _.findWhere(filtersMap[filter.type], {uniqueId: filter.uniqueId});
				if (!existingFilter) {
					//Handle existing scorecard filter, should be removed when remove Scorecard widget
					existingFilter = _.findWhere(filtersMap[filter.type], {scorecardId: filter.scorecardId, passing: filter.passing});
				}
			} else if (filter.type === FilterTypes.EMPTY) {
				existingFilter = filter;
			} else {
				existingFilter = _.findWhere(filtersMap[filter.type], {filterId: filter.filterId.toString()});
			}

			if (existingFilter) {
				filter.name = existingFilter.name;
				filter.error = false;
			} else {
				filter.error = true;
			}
		});
	}

	private getFilters = (filtersList: FiltersGroup[], type: FilterTypes): any[] => {
		let filtersItem = _.find(filtersList, (item) => {
			return item && item.list && item.list[0] && item.list[0].type === type;
		});
		return filtersItem ? filtersItem.list : [];
	}

	private checkAppliedFiltersForUnavailableFilters(appliedFilters): boolean {
		return !!_.findWhere(appliedFilters && appliedFilters.filters, {error: true});
	}

	processDashboardFilters = (widgetSettings: Widget, filtersProvider?: IDashboardHistoryInstance): ng.IPromise<void> => {
		if (!this.isWidgetEligableForDashboardFilters(widgetSettings) || !filtersProvider)
			return this.$q.when();

		let processors = this.getProcessors(widgetSettings);
		let dashboardFilterApplication = new WidgetDashboardFilterApplication(widgetSettings, filtersProvider);

		let processingPromise = this.$q.when();
		processors.forEach(processor => {
			processingPromise = processingPromise.then(() => {
				return processor.process(dashboardFilterApplication);
			});
		});

		return processingPromise;
	}

	private getProcessors(widgetSettings: Widget): WidgetDashboardFilterProcessor[] {
		if (InternalProjectTypes.isStudioAdminProject(widgetSettings.properties.project)) {
			return [
				this.widgetDashboardDateFilterProcessor,
				this.widgetSavedDashboardFiltersProcessor,
				this.widgetDashboardCommonFiltersProcessor
			];
		}
		return [
			this.widgetDashboardDateFilterProcessor,
			this.widgetSavedDashboardFiltersProcessor,
			this.widgetDashboardCommonFiltersProcessor,
			this.widgetDrillableDashboardFilterProcessor,
			this.widgetDashboardPersonalizationProcessor,
			this.widgetDashboardTextFilterProcessor
		];
	}

	getModelGroupingFilters = (widget: Widget): ng.IPromise<WidgetGroupingFilter[]> => {
		let withDisabled = true;
		return this.widgetModelGroupingFilters.getFilters(widget, withDisabled);
	}

}

app.service('customFilterService', CustomFilterService);

