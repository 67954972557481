import { Pill, PillType } from '@app/modules/pills/pill';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';

export class SentenceConcatenationUtils {
	static areTopicsSame(topics1: SentenceTopic[] = [], topics2: SentenceTopic[] = []): boolean {
		if (_.size(topics1) !== _.size(topics2))
			return false;
		if (!!_.find(topics1, topic => !_.findWhere(topics2, {id: topic.id})))
			return false;
		return true;
	}

	// should not have conflicting enrichments of the same type, and should have same scorecard pills
	static canConcatenatePills(pills1: Pill[], pills2: Pill[]): boolean {
		return this.hasNoConflictingEnrichments(pills1, pills2) && this.hasSameScorecardPills(pills1, pills2);
	}

	private static hasNoConflictingEnrichments(pills1: Pill[], pills2: Pill[]): boolean {
		return !this.hasDifferentEnrichments(pills1, pills2) && !this.hasDifferentEnrichments(pills2, pills1);
	}

	private static hasDifferentEnrichments(first: Pill[], second: Pill[]): boolean {
		return !!_.find(first, pill => this.isMetricEnrichment(pill) && !!_.find(second, other =>
			other.type === pill.type && other.name !== pill.name));
	}

	private static isMetricEnrichment(pill: Pill): boolean {
		return pill.type === PillType.SENTIMENT || pill.type === PillType.EASE_SCORE || pill.type === PillType.EMOTION;
	}

	private static hasSameScorecardPills(pills1: Pill[], pills2: Pill[]): boolean {
		let counts: {[key: number]: number} = {};
		let countFn = (pill: Pill) => {
			if (pill.type === PillType.SCORECARD)
				counts[pill.value.id] = ++counts[pill.value.id] || 1; // increment or set 1
		};
		_.each(pills1, countFn);
		_.each(pills2, countFn);
		return _.every(counts, (count, key) => count === 2); // each topic should be mentioned twice
	}
}
