import { ChannelTypes } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';

type ChannelTypesMap = Record<ChannelTypes, string>;

export class ConversationChannelLabels implements ChannelTypesMap {
	agent: string;
	client: string;
	bot: string;
	unknown: string;
	agentAutoTitle: boolean;
	clientAutoTitle: boolean;
	botAutoTitle: boolean;
	unknownAutoTitle: boolean;
}