import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { UrlService } from '@cxstudio/common/url-service.service';
import { SamlIdentityProviderApiService } from '@app/modules/system-administration/saml-settings/saml-identity-provider-api.service';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';

interface IEmbeddedWidget {
	width: number;
	height: number;
	dashboardId: number;
	displayName: string;
}

@Injectable({
	providedIn: 'root'
})
export class EmbeddedWidgetUtils {
	constructor(
		@Inject('security') private security: Security,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject(Window) private readonly window: Window,
		private identityProviderApi: SamlIdentityProviderApiService,
		private masterAccountManageApi: MasterAccountManageApi
	) {
	}

	isEnterpriseViewersAvailable = (): Promise<boolean> => {
		let allowEnterpriseViewer = false;
		let masterAccountSamlLink = [];

		this.masterAccountManageApi.getExternalSamlEnabledMasterAccounts().then((links) => {
			masterAccountSamlLink = links;
			return Promise.resolve(this.identityProviderApi.getIdentityProviders().then((identityProviders) => {
				identityProviders.forEach((identityProvider) => {
					let link = _.findWhere(masterAccountSamlLink, { aliasName: identityProvider.aliasName });
					if (link && link.linkedMasterAccounts.contains(this.security.getCurrentMasterAccount().accountName)) {
						allowEnterpriseViewer = identityProvider.userIdentity.allowEnterpriseViewer;
					}
				});
				return this.security.isSamlForMAEnabled() && allowEnterpriseViewer;
			}) as PromiseLike<boolean>);
		});
		return Promise.resolve(false);
	}

	getEmbedWidgetCode(widget: IEmbeddedWidget, identifier: string): string {
		let link = this.urlService.getEmbedWidgetUrl(widget.dashboardId, identifier);
		let size = this.getWidgetSize(widget);
		return `<iframe width="${size.width}" height="${size.height}" src="${link}"`
			+ ` title="${HtmlUtils.escapeHtml(widget.displayName)}"></iframe>`;
	}

	private getWidgetSize(widget: IEmbeddedWidget): { width: number, height: number } {
		let unitSize = this.window.innerWidth / GridsterConfigurer.GRIDSTER_COLUMNS; // don't need to be precise, so ignoring margins
		return {
			width: Math.round(widget.width * unitSize),
			height: Math.round(widget.height / 2 * unitSize) // height unit = 1/2 width unit
		};
	}
}
