/*
	Capitalize every word in a string
	Ex: "CAPITALIZE eVERy word in a string" -> "Capitalize Every Word In A String"
*/
app.filter('capitalize', () => {
	return (x) => {
		if (!x) return x;

		let capitalizedWords = [];
		let words = x.split(' ');

		words.map((word) => {
			let lc = word.toLowerCase();
			capitalizedWords.push(lc[0].toUpperCase() + lc.substring(1));
		});

		return capitalizedWords.join(' ');
	};
});
