import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpPromise } from '@app/core/http-promise';
import { QualtricsEmbedData, QualtricsUserInfo } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';

@Injectable({
	providedIn: 'root'
})
export class QualtricsApiService {
	constructor(
		private http: CxHttpService,
	) { }

	getQualtricsEmbedWidgetData(widgetId: number): HttpPromise<QualtricsEmbedData> {
		return this.http.getResponse(`rest/external/qualtrics/widget/${widgetId}`, {
			local: true,
		});
	}

	getQualtricsEmbedDashboardData(bookId: number, dashboardId: string): HttpPromise<QualtricsEmbedData> {
		return this.http.getResponse(`rest/external/qualtrics/book/${bookId}/tab/${dashboardId}`, {
			local: true
		});
	}

	getUserInfo(): Promise<QualtricsUserInfo> {
		return this.http.get(`rest/external/qualtrics/user-info`, {
			local: true
		});
	}

	performUserLogout(): Promise<void> {
		return this.http.delete(`rest/external/qualtrics/token`, {
			local: true
		});
	}
}
