import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
	Inject, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Pill, PillType } from '@app/modules/pills/pill';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { CanvasUtils } from '@app/shared/util/canvas-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import * as _ from 'underscore';
import { PillTuningEvent } from '@app/modules/pills/pill-tuning-event';

@Component({
	selector: 'collapsible-pills',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './collapsible-pills.component.html'
})
export class CollapsiblePillsComponent implements OnInit, OnChanges {
	_pills: Pill[];
	@Input() set pills(unsortedPills: Pill[]) {
		this._pills = _.chain(unsortedPills)
			.sortBy((pill) => pill.sortValue || pill.name)
			.sortBy((pill) => this.isEnrichmentPill(pill) ? Object.values(PillType).indexOf(pill.type) : Number.POSITIVE_INFINITY)
			.value();
	}
	@Input() itemDisplayName: string;
	@Input() classFunc?: (pill: Pill) => string;
	@Input() width: number;
	@Input() highlightTrigger: number;
	@Input() showAll: boolean;
	@Input() useContrastTextColor: boolean;
	@Input() allowTuning: boolean;
	@Input() inert: boolean;
	@Output() tune = new EventEmitter<PillTuningEvent>();
	@Output() pillClick = new EventEmitter<Pill>();
	@Output() pillMouseover = new EventEmitter<Pill>();
	@Output() pillMouseout = new EventEmitter<Pill>();
	collapsed: boolean = true;
	showToggle: boolean;
	lastVisiblePill: number;
	PillType = PillType;

	// pills.component.html and pills.component.scss
	readonly FONT: string = '16px "72", "Helvetica Neue", Helvetica, Arial, sans-serif';
	readonly LETTER_SPACING: number = 0.3;
	readonly PADDING: number = 26; // padding + border of the pill + margin between pills
	readonly BUTTON_WIDTH: number = 20; // remove button + margin
	readonly ICON_WIDTH: number = 24; // enrichment icon

	constructor(
		private ref: ChangeDetectorRef,
		private canvasUtils: CanvasUtils,
		private locale: CxLocaleService,
		@Inject('security') private security: Security
	) {}

	ngOnInit(): void {
		this.updatePillsVisibility();
		if (this.isHighlightedPillHidden()) {
			this.toggle();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		let pillsChanges = changes.pills;
		if (pillsChanges && !pillsChanges.firstChange && pillsChanges.currentValue !== pillsChanges.previousValue) {
			this.updatePillsVisibility();
			this.ref.markForCheck();
		}
		let widthChange = changes.width;
		if (widthChange && !widthChange.firstChange && this._pills
			&& widthChange.currentValue !== widthChange.previousValue) {
			this.updatePillsVisibility();
			this.ref.markForCheck();
		}
		let highlight = changes.highlightTrigger;
		if (highlight && !highlight.firstChange && this.isHighlightedPillHidden()) {
			this.toggle();
		}
		let showAllPills = changes.showAll;
		if (showAllPills && !showAllPills.firstChange && showAllPills.currentValue !== showAllPills.previousValue) {
			if (!this.showAll) {
				this.collapsed = true;
			}
			this.updatePillsVisibility();
			this.ref.markForCheck();
		}
	}

	tunePill = (pill: Pill, event: MouseEvent): void => {
		if (this.isTuningAllowed(pill)) {
			this.stopPropagation(event);
			this.tune.emit({pill, event});
		}
	}

	clickHandler = (pill: Pill, event?: Event): void => {
		this.stopPropagation(event);
		this.pillClick.emit(pill.value);
		this.ref.markForCheck();
	}

	mouseoverHandler = (pill: Pill): void => {
		this.pillMouseover.emit(pill.value);
	}

	mouseoutHandler = (pill: Pill): void => {
		this.pillMouseout.emit(pill.value);
	}

	getToggleText = (): string => {
		let key = this.collapsed ? 'common.expandPills' : 'common.collapsePills';
		return this.locale.getString(key, { count: this._pills.length - this.lastVisiblePill - 1 });
	}

	getToggleAriaLabel = (): string => {
		let toggleText: string = this.getToggleText();
		return this.itemDisplayName ? `${toggleText} - ${this.itemDisplayName}` : toggleText;
	}

	toggle = (event?: Event): void => {
		this.stopPropagation(event);
		this.collapsed = !this.collapsed;
		this.ref.detectChanges();
	}

	private stopPropagation = (event?: Event): void => {
		if (event) {
			event.stopPropagation();
		}
	}

	private expandAll = (): void => {
		this.collapsed = false;
		this.showToggle = false;
		this.lastVisiblePill = this._pills.length - 1;
	}

	private isHighlightedPillHidden = (): boolean => {
		return this.showToggle && this.collapsed && _.findIndex(this._pills, (pill) => pill.value?.selected) > this.lastVisiblePill;
	}

	private updatePillsVisibility = (): void => {
		if (this.showAll) {
			this.expandAll();
			return;
		}
		this.lastVisiblePill = this.getLastVisiblePill();
		this.showToggle = this._pills.length - this.lastVisiblePill > 1;
	}

	private getTextWidth = (text: string): number => {
		return this.canvasUtils.getTextWidth(text, this.FONT, this.LETTER_SPACING);
	}

	getLastVisiblePill = (): number => {
		let lastPillIndex: number = 0;
		let currentWidth: number = 0;
		let firstRow: boolean = true;
		for (let index = 0; index < this._pills.length; index++) {
			let pill: Pill = this._pills[index];
			let pillWidth: number = this.calculatePillWidth(pill);
			let nextWidth: number = currentWidth + pillWidth;
			if (nextWidth > this.width) {
				if (firstRow) {
					firstRow = false;
					lastPillIndex = index;
					currentWidth = pillWidth;
				} else {
					let widthRequiredForLeftPills: number = this.getWidthForLeftPills(index);
					let leftPillsTakeOneRow: boolean = widthRequiredForLeftPills <= this.width;

					if (leftPillsTakeOneRow) {
						lastPillIndex = this._pills.length - 1;
						currentWidth = widthRequiredForLeftPills;
					}
					break;
				}
			} else {
				lastPillIndex = index;
				currentWidth = nextWidth;
			}
		}
		return lastPillIndex;
	}

	private calculatePillWidth = (pill: Pill): number => {
		let pillWidth: number = this.getTextWidth(pill.html || pill.name) + this.PADDING;
		if (this.isTuningAllowed(pill)) {
			pillWidth += this.BUTTON_WIDTH;
		} else if (this.isEnrichmentPill(pill)) {
			pillWidth += this.ICON_WIDTH;
		}
		return pillWidth;
	}

	private getWidthForLeftPills = (nextRowPillIndex: number): number => {
		let pillsLeft: Pill[] = this._pills.slice(nextRowPillIndex);
		return _.chain(pillsLeft)
			.map(this.calculatePillWidth)
			.reduce((memo: number, width: number) =>  memo + width)
			.value();
	}

	private isEnrichmentPill = (pill: Pill): boolean => {
		return [PillType.SENTIMENT, PillType.EMOTION, PillType.EASE_SCORE, PillType.SCORECARD].contains(pill.type);
	}

	getClasses(pill: Pill): string {
		return `${pill.additionalClass || ''} ${this.classFunc?.(pill) || ''}`;
	}

	isTuningAllowed(pill: Pill): boolean {
		return this.allowTuning
			&& !pill.disabled
			&& PillsUtils.isPillTunable(pill.type)
			&& this.security.getCurrentMasterAccount()
				.tuningSuggestionsSettings[PillsUtils.pillTypeToSuggestionType(pill.type)];
	}
}


app.directive('collapsiblePills', downgradeComponent({component: CollapsiblePillsComponent}) as angular.IDirectiveFactory);
