import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

/**
 * Prevent non-integer input
 */
app.directive('integer', ($window) => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: (scope, element, attrs) => {
			// only support
			if (!(element.prop('tagName') === 'INPUT' && attrs.type === 'number')) return;

			let handler = (event) => {
				// only allow numbers, minus sign, or navigation keys
				if (!(/[0-9-]/.test((event as KeyboardEvent).key) ||
						KeyboardUtils.isNavigationKey(event) ||
						KeyboardUtils.isDeletionKey(event))) {
					event.preventDefault();
					return;
				}
			};

			angular.element($window).bind('keydown keypress', handler);
			scope.$on('$destroy', () => {
				angular.element($window).off('keydown keypress', handler);
			});
		}
	};
});
