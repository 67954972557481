import { Component, Input, ViewChild, ElementRef, HostListener, ChangeDetectorRef, OnDestroy, HostBinding } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { fromEvent, Subscription } from 'rxjs';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { FilterByPipe, OrderByPipe } from 'ngx-pipes';
import { BetaFeatureTracker } from '@app/modules/context/beta-features/beta-feature-tracker-class';

declare let app: angular.IModule;

@Component({
	selector: 'cb-command-palette',
	styles: [`
		.ctrl-shift-name, .ctrl-name, .shift-name { display: none; }

		.ctrl-down:not(.shift-down) .ctrl-name ~ .basic-name,
		.shift-down:not(.ctrl-down) .shift-name ~ .basic-name,
		.ctrl-down.shift-down .ctrl-shift-name ~ .basic-name { display: none; }

		.ctrl-down:not(.shift-down) .ctrl-name { display: inline-block; }
		.shift-down:not(.ctrl-down) .shift-name { display: inline-block; }
		.ctrl-down.shift-down .ctrl-shift-name { display: inline-block; }
	`],
	templateUrl: './cb-command-palette.component.html',
	providers: [OrderByPipe, FilterByPipe],
})

export class CbCommandPaletteComponent implements OnDestroy {
	palette: ElementRef;
	optionsList: ElementRef;

	@ViewChild('palette', {static: false}) set getPalette(el: ElementRef) {
		if (el)
			this.palette = el;
	}
	@ViewChild('options', {static: false}) set getOptionsList(el: ElementRef) {
		if (el)
			this.optionsList = el;
	}
	@ViewChild('searchInput', {static: false}) private searchInput: ElementRef;

	@Input() getOptions: () => CbCommandPaletteOption[];

	menuItems: CbCommandPaletteOption[];
	@HostBinding('class.d-block') paletteVisible: boolean = false;
	commandSearch: string = '';
	focusedIndex: number = 0;
	keyDownWatcher: Subscription;
	keyUpWatcher: Subscription;
	outsideClickWatcher: Subscription;
	tabWatcher: Subscription;

	constructor(
		private ref: ChangeDetectorRef,
		private orderBy: OrderByPipe,
		private filterBy: FilterByPipe
	) { }

	// Add event listeners for the platform-specific key combo
	@HostListener('window:keydown.control.k', ['$event'])
	@HostListener('window:keydown.meta.k', ['$event'])
	onKeyCombinationPressed(event: KeyboardEvent) {
		// If the key combo was pressed, open the command palette
		if (KeyboardUtils.isWinCtrlOrMacCmd(event)) {
			this.openPalette(event);
		}
	}

	getPlatformKeyCombo(): string {
		return KeyboardUtils.getKbShortcutLabel('K');
	}

	private isModalOpen(): boolean {
		return document.body.className.indexOf('modal-open') !== -1;
	}

	private openPalette(event?: KeyboardEvent) {
		if (this.isModalOpen()) return;

		if (event)
			event.preventDefault();	// prevent opening of browser location bar

		if (!this.paletteVisible) {
			this.menuItems = this.getOptions();
			this.showPalette();
			this.ref.detectChanges();
			this.initSoftSelection();
			this.initCloseTriggers();

			// if this was triggered by text input, we don't need to set focus
			if (event) {
				setTimeout(() => {
					this.searchInput.nativeElement.focus();
					this.searchInput.nativeElement.select();
				}, 10);
			}
		}
	}

	ngOnDestroy(): void {
		this.keyUpWatcher?.unsubscribe();
		this.keyDownWatcher?.unsubscribe();
	}

	changeSelection = (selectionIndex: number): void => {
		this.focusedIndex = selectionIndex;
	}

	getFilteredSortedList = (): CbCommandPaletteOption[] => {
		if (this.commandSearch) {
			return this.orderBy.transform(this.filterBy.transform(this.menuItems, ['name'], this.commandSearch), 'name');
		} else {
			// '' will return anything which has the attribute
			return this.orderBy.transform(this.filterBy.transform(this.menuItems, ['lastUsedTime'], ''), '-lastUsedTime');
		}
	}

	private initCloseTriggers = (): void => {
		// close on click outside of dropdown
		this.outsideClickWatcher = fromEvent(window, 'click').subscribe(
			(event: MouseEvent) => {
				if (!$(event.target).closest('cb-command-palette').length) {
					event.preventDefault();
					event.stopPropagation();
					this.hidePalette();
				}
			});

		// close on tab
		this.tabWatcher = fromEvent(window, 'keydown').subscribe(
			(event: KeyboardEvent) => {
				if (event.key === Key.TAB) {
					this.hidePalette();
				}
			});
	}

	private initSoftSelection = (): void => {
		this.keyDownWatcher = fromEvent(window, 'keydown').subscribe(
			(event: KeyboardEvent) => {
				if (event.key === Key.ENTER) {
					event.stopPropagation();
					event.preventDefault();
					this.handleEnterKey(event);
				}

				if (event.ctrlKey) {
					this.palette.nativeElement.classList.add('ctrl-down');
				}

				if (event.shiftKey) {
					this.palette.nativeElement.classList.add('shift-down');
				}

				if (event.key === Key.ESCAPE && this.paletteVisible) {
					this.hidePalette();
				}
			});

		this.keyUpWatcher = fromEvent(this.palette.nativeElement, 'keyup').subscribe(
			(event: KeyboardEvent) => {
				if (!event.ctrlKey) {
					this.palette.nativeElement.classList.remove('ctrl-down');
				}

				if (!event.shiftKey) {
					this.palette.nativeElement.classList.remove('shift-down');
				}
			});
	}

	handleClick = (menuItem: CbCommandPaletteOption, event: KeyboardEvent): void => {
		this.hidePalette();
		this.searchInput.nativeElement.blur();
		if (event.ctrlKey && menuItem.ctrl) {
			menuItem.ctrl.action();
		} else if (event.shiftKey && menuItem.shift) {
			menuItem.shift.action();
		} else {
			menuItem.action();
		}
	}

	private handleEnterKey = (event): void => {
		let filteredSortedList = this.getFilteredSortedList();
		if (filteredSortedList.length && (this.focusedIndex < filteredSortedList.length)) {
			let selectedMenuItem = filteredSortedList[this.focusedIndex];
			this.handleClick(selectedMenuItem, event);
		}
	}

	private showPalette = (): void => {
		this.palette.nativeElement.classList.remove('ctrl-down', 'shift-down');
		this.paletteVisible = true;
		this.focusedIndex = 0;
	}

	// hide palette and stop watching in-component keyboard events
	hidePalette = (): void => {
		this.paletteVisible = false;
		this.commandSearch = '';
		this.keyUpWatcher.unsubscribe();
		this.keyDownWatcher.unsubscribe();
		this.outsideClickWatcher.unsubscribe();
		this.tabWatcher.unsubscribe();
	}

	// reset selected item and open dropdown if necessary
	onSearchChange = (): void => {
		this.focusedIndex = 0;
	}

	onFocus(): void {
		if (!this.paletteVisible)
			this.openPalette();
	}

	isRecents(): boolean {
		return !this.commandSearch;
	}
}

export class CbCommandPaletteSecondaryAction {
	name: string;
	action: () => void;
}

export class CbCommandPaletteOption {
	name: string;
	ctrl: CbCommandPaletteSecondaryAction;
	shift: CbCommandPaletteSecondaryAction;
	//ctrlShift: CbCommandPaletteSecondaryAction; // not supported yet
	action: () => void;
	icon?: string;
	lastUsedTime?: number;
	betaFeature?: BetaFeatureTracker;
	showCondition?: () => boolean;
}

app.directive('cbCommandPalette', downgradeComponent({component: CbCommandPaletteComponent}) as angular.IDirectiveFactory);
