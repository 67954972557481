import {
	Component,
	Input,
	OnInit,
	ElementRef,
	ChangeDetectorRef,
	AfterViewInit,
	ChangeDetectionStrategy,
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { IScroller } from '@app/modules/scroll-carousel/scrollers/scroller.interface';
import { SimpleContainerScroller } from '@app/modules/scroll-carousel/scrollers/simple-container-scroller.class';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

/**
 * usage:
<scroll-carousel [scroller]="scrollerImpl">
	<div class="scroll-carousel-target fade-container">
		<li>item 1</li>
		<li>item 2</li>
		<li>item 3</li>
	</div>
</scroll-carousel>

 * "scroll-carousel-target" - marker for scroll container which contain elements
 * "fade-container" - usually the same element. Used as a target for fading effects near buttons
 * */

@Component({
	selector: 'scroll-carousel',
	templateUrl: './scroll-carousel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollCarouselComponent extends SelfCleaningComponent implements OnInit, AfterViewInit {
	@Input() scroller: IScroller;
	@Input() scrollOffset: number;
	@Input() labels: {previous: string, next: string};
	@Input() active: boolean;

	scrollContainer: JQuery;

	constructor(
		private elementRef: ElementRef,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		super();
	}

	ngOnInit(): void {
		if (!this.scroller) {
			// required to call change detection within OnPush parent when we hold mouse on button
			let wrappedTimeout = (handler: any, timeout: number) => {
				return setTimeout(() => {
					handler();
					this.changeDetectorRef.markForCheck();
				}, timeout);
			};
			this.scroller = new SimpleContainerScroller(wrappedTimeout, this.scrollOffset);
		}

		this.scrollContainer = $(this.elementRef.nativeElement).find('.scroll-carousel-target');
		this.scroller.init(this.scrollContainer);
	}

	ngAfterViewInit(): void {
		// Force change detection when children initialized to properly update buttons disabled state
		this.changeDetectorRef.markForCheck();

		this.addResizeObserver(
			ResizeHandlerUtils.addResizeHandler(
				this.scrollContainer[0],
				(): void => {
					this.changeDetectorRef.markForCheck();
				}
			)
		);
	}
}

app.directive('scrollCarousel', downgradeComponent({component: ScrollCarouselComponent}));
