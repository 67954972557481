import { NgModule } from '@angular/core';
import { TranslationModule } from '@app/modules/translation/translation.module';
import { WidgetVisualizationsCommonModule } from '@app/modules/widget-visualizations/common/widget-visualizations-common.module';
import { SpeechBubbleComponent } from '@app/modules/widget-visualizations/feedback/speech-bubble/speech-bubble.component';
import { SharedModule } from '@app/shared/shared.module';
import { PreviewBubblesComponent } from './preview-bubbles/preview-bubbles.component';
import { PreviewTableComponent } from './preview-table/preview-table.component';
import { ColResizableDirective } from './col-resizable.directive';
import { VerbatimTextComponent } from './verbatim-text/verbatim-text.component';



@NgModule({
	imports: [
		SharedModule,
		WidgetVisualizationsCommonModule,
		TranslationModule,
	],
	declarations: [
		SpeechBubbleComponent,
		PreviewBubblesComponent,
		PreviewTableComponent,
		ColResizableDirective,
		VerbatimTextComponent,
	],
})
export class FeedbackVisualizationModule { }
