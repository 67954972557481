import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Inject } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { PromiseUtils } from '@app/util/promise-utils';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Package } from '../packages/package.interface';

export class RunPackageEntry {
	name: string;
	projectProperties: IProjectSelection;
	workspaceProject?: WorkspaceProject;
	colorPalette: any;
	providerColors: any;
	defaultShowTotal: boolean;
}

export class RunPackageInput {
	dashboard: Dashboard;
	package: Package;
}

@Component({
	selector: 'run-package-modal',
	templateUrl: './run-package-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunPackageModalComponent implements OnInit {
	@Input() input: RunPackageInput;
	entry: RunPackageEntry;
	isWorkspaceEnabled: boolean;
	showErrorsForCP: string[];
	loading: Promise<any>;
	clearDefaultsSubject: Subject<void> = new Subject<void>();

	constructor(
		private ref: ChangeDetectorRef,
		private modal: NgbActiveModal,
		@Inject('colorPalettes') private colorPalettes: ColorPalettes,
		private readonly betaFeaturesService: BetaFeaturesService
	) { }

	ngOnInit(): void {
		this.entry = {
			name: this.input.dashboard.name,
			projectProperties: {
				contentProviderId: -1,
				projectId: -1,
				accountId: -1
			},
			colorPalette: {},
			providerColors: {},
			defaultShowTotal: this.input.dashboard.properties.defaultShowTotal
		};

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading = loadingPromise;
		this.ref.markForCheck();
	}

	onProjectSelectionChange = (projectSelection: IProjectPreselection): void => {
		this.entry.projectProperties.contentProviderId = projectSelection.cbContentProvider;
		this.entry.projectProperties.accountId = projectSelection.cbAccount;
		this.entry.projectProperties.projectId = projectSelection.project;
		this.entry.projectProperties.projectName = projectSelection.projectName;
		this.input.dashboard.properties.cbAccount = this.entry.projectProperties.accountId;
		this.input.dashboard.properties.cbContentProvider = this.entry.projectProperties.contentProviderId;
		this.input.dashboard.properties.project = this.entry.projectProperties.projectId;
		if (this.entry.projectProperties.accountId > -1) {
			this.loadColorPalette();
		}
	}

	onWorkspaceChange = (workspace: Unit): void => {
		if (workspace) {
			this.entry.projectProperties.contentProviderId = workspace.contentProviderId;
			this.entry.projectProperties.accountId = workspace.accountId;
		} else {
			this.entry.projectProperties.contentProviderId = -1;
			this.entry.projectProperties.accountId = -1;
		}
	}

	onProjectChange = (workspaceProject: WorkspaceProjectData): void => {
		this.entry.workspaceProject = workspaceProject;
		this.entry.projectProperties.projectId = workspaceProject.projectId;
		this.entry.projectProperties.projectName = workspaceProject.projectName;
	}

	loadColorPalette = () => {
		let palettePromise = PromiseUtils.wrap(this.colorPalettes.getDashboardDesignerPalette(this.input.dashboard));
		let palettesPromise = PromiseUtils.wrap(this.colorPalettes.getWidgetPalettes());
		this.loading = Promise.all([palettesPromise, palettePromise]).then(
			(data: any[]) => {
				this.entry.colorPalette = data[0];
				this.entry.providerColors = data[1];
			});
	}

	showColorSelector = (): boolean => this.input.dashboard.properties.project > -1 && this.entry.providerColors;

	onCpErrorsChange = (errors: string[]) => {
		this.showErrorsForCP = errors;
	}

	save = (): void => {
		this.modal.close(this.entry);
	}

	cancel = () => this.modal.dismiss();

	closeModal = (event: UIEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		this.cancel();
	}

	hasNoSelection(): boolean {
		return _.isEmpty(this.entry.name)
			|| this.entry.projectProperties.contentProviderId === -1
			|| this.entry.projectProperties.projectId === -1;
	}

}
