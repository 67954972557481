import { Injectable } from '@angular/core';
import { CxHttpService, CxLocaleService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { HttpPromise } from '@app/core/http-promise';

@Injectable({
	providedIn: 'root'
})

export class SamlServiceProviderApiService {
	readonly SERVICE_PROVIDER_ENDPOINT = 'rest/saml/service-provider';

	constructor(
		private cxHttp: CxHttpService,
		private locale: CxLocaleService,
	) {}

	getServiceProviders(): Promise<SamlServiceProvider[]> {
		return this.cxHttp.get(`${this.SERVICE_PROVIDER_ENDPOINT}/list`);
	}

	createServiceProvider(serviceProvider: SamlServiceProvider): Promise<void> {
		return this.cxHttp.post(this.SERVICE_PROVIDER_ENDPOINT, serviceProvider);
	}

	updateServiceProvider(serviceProvider: SamlServiceProvider): Promise<void> {
		let config = this.getEntityIdConfig(serviceProvider.entityId);
		return this.cxHttp.put(`${this.SERVICE_PROVIDER_ENDPOINT}`, serviceProvider, config);
	}

	deleteServiceProvider(entityId: string): Promise<void> {
		let config = this.getEntityIdConfig(entityId);
		return this.cxHttp.delete(this.SERVICE_PROVIDER_ENDPOINT, config);
	}

	downloadKeyPairFile(entityId: string): HttpPromise<any> {
		let config = this.getFileRequestConfig(entityId);
		return this.cxHttp.getResponse('rest/saml/service-provider/key-pair/download', config);
	}

	private getEntityIdConfig(entityId: string): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams()
				.append('entityId', entityId)
		};
	}

	private getFileRequestConfig(entityId: string): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams({fromObject: {entityId}}),
			responseType: 'arraybuffer'
		};
	}
}
