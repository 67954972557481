

export enum ExpressionPieces {
	NUMBER = 'number',
	METRIC = 'metric',
	SYSTEM_METRIC = 'system',
	SCORECARD_STUDIO_METRIC = 'scorecard_studio_metric',
	SCORECARD_NODE_METRIC = 'scorecard_node_metric',
	ATTRIBUTE = 'attribute',
	SYMBOL = 'symbol',
	ABSOLUTE = 'absolute',
	EXPONENT = 'exponent',
	ORGANIZATION_HIERARCHY_METRIC = 'organization_hierarchy_metric'
}
