import { DocumentExplorerStatsData } from '@app/modules/document-explorer/document-explorer-stats-modal/document-explorer-stats-modal.component';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';



export class DocExplorerQidsService {
	sentenceQid: string;
	easeQid: string;
	sentimentQid: string;
	documentQid: string;

	constructor(
		private readonly downgradeDialogService: DowngradeDialogService,
	) {}

	openStatsDialog(): void {
		this.downgradeDialogService.openDocumentExplorerStats(this.getQIDs());
	}

	private getQIDs(): DocumentExplorerStatsData {
		return {
			sentenceQid: this.sentenceQid,
			easeQid: this.easeQid,
			sentimentQid: this.sentimentQid,
			documentQid: this.documentQid,
		};
	}
}

// persist QIDs across components
app.service('docExplorerQids', DocExplorerQidsService);
