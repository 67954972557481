import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, Inject,
	Input, Output, EventEmitter, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SpotCheckTopic } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-topic';
import { ListOption } from '@app/shared/components/forms/list-option';
import { SpotCheckResultItem } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-item.component';
import { SpotCheckResultStatus } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-status';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { SpotCheckService } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check.service';

@Component({
	selector: 'spot-check-modal',
	styles: [`
		:host {
			cursor: default;
		}
		.spot-check-header cb-modal-close {
			float: right;
			margin-top: -5px;
		}
		.spot-check-body { height: calc(100% - 90px); }
		.spot-check-results { height: calc(100% - 16px); }
		.w-180 {
			width: 180px;
		}
	`],
	templateUrl: './spot-check-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotCheckModalComponent implements OnInit, AfterViewInit {
	@Input() dashboardId: number;
	@Output() closeDialog = new EventEmitter<boolean>();

	@ViewChild('spellCheckResults') private spellCheckResultsTemplate: TemplateRef<any>;
	private templatesMap: {[name: string]: TemplateRef<any>};

	topics: ListOption<SpotCheckTopic>[];

	selectedTopics: SpotCheckTopic[];
	actionItemsCount: number = 0;
	maxSpellingErrors: number = 10;
	summaryMessage: string;
	statusCounts: {[key in SpotCheckResultStatus]?: number} = {};
	displayResults: {[key in SpotCheckResultStatus]?: SpotCheckResultItem[]} = {};

	loading: Promise<any>;
	private results: SpotCheckResultItem[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private spotCheckService: SpotCheckService,
		@Inject('dashboardPropsService') private readonly dashboardPropsService: DashboardPropsService,
		@Inject('elementUtils') private readonly elementUtils: ElementUtils,
	) {}

	ngOnInit(): void {
		this.topics = [
			{ value: SpotCheckTopic.ACCESSIBILITY, name: this.locale.getString('dashboardSpotCheck.accessibility') },
			{ value: SpotCheckTopic.SPELLING, name: this.locale.getString('dashboardSpotCheck.spellingAndStyle') },
			{ value: SpotCheckTopic.PERFORMANCE, name: this.locale.getString('dashboardSpotCheck.performance') }
		];

		this.summaryMessage = this.locale.getString('dashboardSpotCheck.spotCheckDialogSummaryZero');
	}

	ngAfterViewInit(): void {
		this.templatesMap = {
			'spellCheck': this.spellCheckResultsTemplate
		};
		this.validate();
	}

	validate(): void {
		this.loading = this.spotCheckService.performSpotCheck(
			this.dashboardId,
			{ dashboardName: this.dashboardPropsService.current.name }
		).then(response => {
			this.results = response;
			this.processTemplates();
			this.processSpelling();
			this.processState();
			this.processResultsList();
			this.ref.detectChanges();
		});
		this.ref.detectChanges();
	}

	private processSpelling(): void {
		const spellingErrors = this.results.find(r => r.identifier === 'spellingErrors');
		const index = this.results.findIndex(r => r.identifier === 'spellingErrors');
		let data = spellingErrors?.template?.data;

		const hideSpelling = data ? data[0].errors.find(r => r.token === 'hideSpell') : '';
		if (!!hideSpelling) {
			this.results.splice(index, 1);
			return;
		}

		if (data?.length > this.maxSpellingErrors) {
			//last element for info to be excluded.
			data.splice(this.maxSpellingErrors, (data.length - 1) - this.maxSpellingErrors);
		}
	}

	private processTemplates(): void {
		this.results
			.filter(result => !!result.template)
			.forEach(result => {
				let templateName = result.template.name;
				result.template.ref = this.templatesMap[templateName];
			});
	}


	private processState(): void {
		this.results.forEach(spotItem => this.spotCheckService.populateState(spotItem));
	}

	private processResultsList(): void {
		let topics = this.selectedTopics || [];
		this.prepareDisplayResults(topics);
		this.actionItemsCount = this.statusCounts[SpotCheckResultStatus.ISSUE] || 0;
		this.updateSummaryMessage();
	}

	toggleIgnored(spotItem: SpotCheckResultItem): void {
		this.spotCheckService.toggleIgnored(spotItem);
		this.processResultsList();
	}

	private prepareDisplayResults(topics: SpotCheckTopic[]): void {
		let filtered = this.results.filter(item => topics.isEmpty() || topics.contains(item.topic));
		this.displayResults = _.groupBy(filtered, item => item.state.currentStatus);
		this.statusCounts = _.countBy(filtered, item => item.state.currentStatus);
	}

	onTopicsChange(selectedTopics: SpotCheckTopic[]): void {
		this.selectedTopics = selectedTopics;
		this.prepareDisplayResults(selectedTopics);
	}

	scrollToSection(status: SpotCheckResultStatus): void {
		this.elementUtils.scrollTo(`spot-check-${status.toLowerCase()}-results`);
	}

	onClose(viaKeyboard?: boolean): void {
		this.closeDialog.emit(viaKeyboard);
	}

	onCloseViaKeyboard(event: KeyboardEvent): void {
		KeyboardUtils.intercept(event);
		this.onClose(true);
	}

	updateSummaryMessage(): void {
		if (this.actionItemsCount === 1) {
			this.summaryMessage = this.locale.getString('dashboardSpotCheck.spotCheckDialogSummarySingle');
		} else if (this.actionItemsCount > 1) {
			this.summaryMessage = this.locale.getString('dashboardSpotCheck.spotCheckDialogSummaryMultiple',
				{ count: this.actionItemsCount });
		} else {
			this.summaryMessage = this.locale.getString('dashboardSpotCheck.spotCheckDialogSummaryZero');
		}
	}
}
