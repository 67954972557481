import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';

@Directive({
	selector: '[keepAlive]'
})
export class KeepAliveDirective implements OnInit, OnDestroy {
	private static readonly INTERVAL = 60_000;
	private throttledHandler;

	constructor( 
		private userSessionApi: UserSessionApiService,
		@Inject('security') private security: Security,
		@Inject('environmentService') private environmentService: EnvironmentService,
	) {}

	ngOnInit(): void {
		if (!this.environmentService.isUnderTest()) {
			this.throttledHandler = _.throttle(this.onUserAction, KeepAliveDirective.INTERVAL);
			window.addEventListener('click', this.throttledHandler, true);
			window.addEventListener('scroll', this.throttledHandler, true);
			window.addEventListener('keydown', this.throttledHandler, true);
		}
		
	}

	ngOnDestroy(): void {
		if (!this.environmentService.isUnderTest()) {
			window.removeEventListener('click', this.throttledHandler, true);
			window.removeEventListener('scroll', this.throttledHandler, true);
			window.removeEventListener('keydown', this.throttledHandler, true);
		}
	}

	private onUserAction = () => {
		if (this.security.isLoggedIn())
			this.userSessionApi.updateAccessTime();
	}
}