import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccessibilityStatementDialogComponent } from '../accessibility-statement-dialog/accessibility-statement-dialog.component';
import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})
export class CxNavigationService {

	constructor(
		private cxDialogService: CxDialogService
	) { }

	showAccessibilityStatement = () => {
		this.cxDialogService.openDialog(AccessibilityStatementDialogComponent, undefined, {backdrop: true});
	}

	showAboutStudio = () => {
		this.cxDialogService.openDialog(AboutDialogComponent, undefined, {class: 'br-about-dialog', backdrop: true});
	}
}
app.service('cxNavigationService', downgradeInjectable(CxNavigationService));