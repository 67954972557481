import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QualtricsEmbedData } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';

@Component({
	selector: 'qualtrics-tab-content',
	template: `
	<div class="d-flex align-items-center flex-nowrap">
		<span *ngIf="!hasSource()" class="mr-8 q-icon q-icon-qualtrics-xm" aria-hidden="true"></span>
		<a *ngIf="hasSource()"
			class="kb-focusable-inset qualtrics-tab d-flex w-16 h-16 overflow-hidden mr-8"
			[title]="'dashboard.openInNewTab' | i18n:{url: getDashboardLink()}"
			[href]="getDashboardLink()"
			target="_blank">
			<img [src]="getSourceImage()">
		</a>
		<span>{{ name }}</span>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsTabContentComponent {

	@Input() name: string;
	@Input() embedData?: QualtricsEmbedData;

	hasSource(): boolean {
		return !!this.embedData?.source;
	}

	getDashboardLink(): string {
		return this.embedData.dashboardUrl;
	}

	getSourceImage(): string {
		return `/dashboard/img/xm-embed/xm-logo-${this.embedData.source.toLowerCase()}.svg`;
	}

}
