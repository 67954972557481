<div class="branding-preview standard-border w-100-percent d-flex flex-direction-column">
	<div class="d-flex flex-singleline justify-between" [style.background]="(brandingColors$ | async).COLOR_1">
		<div class="d-flex flex-singleline">
			<div class="p-8 m-16" [style.background]="(brandingColors$ | async).COLOR_6"></div>
			<div class="pv-4 ph-48 mv-8" [style.background]="(brandingColors$ | async).COLOR_6"></div>
		</div>
		<div class="d-flex flex-singleline">
			<div class="p-8 m-16" [style.background]="(brandingColors$ | async).COLOR_6"></div>
			<div class="pv-8 ph-24 mv-16" [style.background]="(brandingColors$ | async).COLOR_6"></div>
			<div class="p-8 m-16" [style.background]="(brandingColors$ | async).COLOR_6"></div>
			<div class="p-8 mv-16 mr-16 border-radius-8" [style.background]="(brandingColors$ | async).COLOR_6"></div>
		</div>
	</div>
	<div class="d-flex" [style.background]="(brandingColors$ | async).COLOR_3">
		<div class="pv-8 ph-48 m-16 flex-fixed bg-gray-900"></div>
	</div>
	<div class="p-16 flex-fill" [style.background]="(brandingColors$ | async).COLOR_10">
		<div class="p-24 d-flex flex-direction-column justify-end h-100-percent" [style.background]="(brandingColors$ | async).COLOR_3">
			<div class="d-flex justify-end flex-fixed">
				<div class="branding-preview-button pv-8 ph-24 btn btn-primary"
					[style.background]="(brandingColors$ | async).COLOR_4"
					[style.border-color]="(brandingColors$ | async).COLOR_4"></div>
				<div class="branding-preview-button pv-8 ph-24 btn btn-secondary"></div>
			</div>
		</div>
	</div>
</div>
