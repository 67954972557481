import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'schedule-renderer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<span *ngIf="isVisible()"
		class="q-icon q-icon-clock"
		[attr.aria-label]="getAriaLabel()">
	</span>`
})
export class ScheduleRendererComponent implements ICellRendererAngularComp {
	value: number;

	constructor(
		private readonly locale: CxLocaleService
	) { }

	refresh(params: ICellRendererParams): boolean {
		this.agInit(params);
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.value = parseInt(params.value, 10) || 0;
	}

	isVisible(): boolean {
		return this.value > 0;
	}

	getAriaLabel(): string {
		return this.locale.getString('dashboard.scheduledActions', {count: this.value });
	}
}
