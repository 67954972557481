
import { ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';

export enum CheckboxState {
	SELECTED = 2, 
	INDETERMINATE = 1, 
	NOT_SELECTED = 0
}

export abstract class TopicSelectionStrategy {
	abstract getRoot: () => ModelTreeNode;

	abstract getAllNodeIds: () => number[];

	abstract isNodeChecked: (node: ModelTreeNode) => boolean;

	abstract isNodeCheckboxVisible: (node: ModelTreeNode) => boolean;

	abstract isNodeIndeterminate: (node: ModelTreeNode) => boolean;

	abstract handleNodeClick: (node: ModelTreeNode) => void;

	abstract resetDisabledItems: () => void;

	abstract refresh: () => void; // for changes outside this object
}