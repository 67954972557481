import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { ObjectUtils } from '@app/util/object-utils';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget, { IWidgetBox } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';

@Injectable({
	providedIn: 'root'
})
export class WidgetModificationService {

	constructor(
		private readonly widgetApiService: WidgetApiService,
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
	) {}

	updateDashboard(dashboardId: number, dashboard: Dashboard): Promise<void> {
		return this.dashboardApiService.updateDashboard(dashboardId, dashboard);
	}

	createWidgets(widget: Widget): Promise<Widget>;
	createWidgets(...widgets: Widget[]): Promise<Widget[]>;
	createWidgets(...widgets: Widget[]): Promise<Widget | Widget[]> {
		if (widgets.length === 1) {
			let widget = widgets[0];
			this.cleanupProperties(widget);
			return this.widgetApiService.addWidget(widget.dashboardId, this.removeNonPersistentProperties(widget)).then(widgetId => {
				widget.id = widgetId;
				return widget;
			});
		} else {
			_.each(widgets, widget => this.cleanupProperties(widget));
			let widgetsData = _.map(widgets, widget => this.removeNonPersistentProperties(widget));
			let dashboardId = widgets[0].dashboardId;
			return this.widgetApiService.addWidgetsBulk(dashboardId, widgetsData).then(widgetIds => {
				widgets.forEach((widget, i) => widget.id = widgetIds[i]);
				return widgets;
			});
		}
	}

	deleteWidgets(...widgets: Widget[]): Promise<void> {
		if (widgets.length === 1) {
			let widget = widgets[0];
			return this.widgetApiService.deleteWidget(widget.dashboardId, widget);
		} else {
			let dashboardId = widgets[0].dashboardId;
			let widgetIds = _.map(widgets, widget => widget.id); // should not be null ids or we are doomed
			return this.widgetApiService.deleteWidgetsBulk(dashboardId, widgetIds);
		}
	}

	updateWidget(widget: Widget): Promise<void> {
		return this.widgetApiService.updateWidget(widget.dashboardId, this.preprocessWidgetBeforeSave(widget));
	}

	updateWidgetOwnership(dashboardId: number, widgetId: number, newOwnerEmail: string): Promise<void> {
		return this.widgetApiService.updateWidgetOwnership(dashboardId, widgetId, newOwnerEmail);
	}

	updateWidgetsLayout(dashboardId: number, widgetLayoutData: {[widgetId: number]: IWidgetBox}): Promise<void> {
		return this.widgetApiService.updateLayout(dashboardId, widgetLayoutData);
	}

	updateWidgetLinking(dashboardId: number, widgetLinkingMap: {[widgetId: number]: number[]}): Promise<void> {
		return this.widgetApiService.updateLinking(dashboardId, widgetLinkingMap);
	}

	preprocessWidgetBeforeSave(widget: Widget): Widget {
		this.cleanupProperties(widget);
		return this.removeNonPersistentProperties(widget);
	}

	private cleanupProperties(widget: Widget): void {
		if (widget.visualProperties.visualization === WidgetVisualization.CLOUDWORDSTABLE) {
			widget.visualProperties.visualization = WidgetVisualization.CLOUD;
		}

		if (widget.visualProperties.visualization === WidgetVisualization.CB_AN_TABLE && widget.properties.selectedAttributes) {
			widget.properties.selectedAttributes.forEach((attr: any) => {
				delete attr.rows;
				delete attr.hidden;
			});

			widget.properties.selectedMetrics.forEach((attr: any) => {
				delete attr.rows;
				delete attr.hidden;
				delete attr.parent;
			});
		}
		delete widget.drillPath;

	}

	private removeNonPersistentProperties(widget: Widget): Widget {
		let widgetCopy = ObjectUtils.copy(widget);
		if (widgetCopy.name === WidgetType.PREVIEW) {
			if (!_.isUndefined(widgetCopy.properties.quickFilter)) {
				delete widgetCopy.properties.quickFilter;
			}
		}
		delete widgetCopy.properties.initialPageNumber;

		return widgetCopy;
	}

}

app.service('widgetModificationService', downgradeInjectable(WidgetModificationService));
