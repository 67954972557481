import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { ColumnFormatter } from '@app/modules/item-grid/slick-grid-formatter.service';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { NumericFormats } from '@app/modules/project/attribute/attribute-settings.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { MetricWidgetPOPService } from '@cxstudio/reports/providers/cb/services/metric-widget-pop.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { PredefinedFormatterBuilder } from '@cxstudio/reports/utils/formatter-builders/predefined-formatter-builder.service';
import { FormattersUtils } from '@cxstudio/reports/utils/formatters-utils.service';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import * as _ from 'underscore';

export interface ITableColumn extends IGridColumn {
	exportType?: string;
	historicMetric?: boolean;
	parentId?: string;
	attributeMetric?: boolean;
	attributeName?: string;
	type?: string;
}

export enum ExportType {
	NUMBER = 'number',
	PERCENT = 'percent',
	DATE = 'date',
	STRING = 'string'
}

export class TableColumnService {

	constructor(
		private reportNumberFormatUtils: ReportNumberFormatUtils,
		private formattersUtils: FormattersUtils,
		private metricWidgetPOPService: MetricWidgetPOPService,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private locale: ILocale,
		private predefinedFormatterBuilder: PredefinedFormatterBuilder,
		private currentWidgets: ICurrentWidgets,
		private metricConstants: MetricConstants,
	) {}

	getTableColumns(visualProps: VisualProperties, props: WidgetProperties,
			attributes: IReportAttribute[], metrics: Metric[], containerId: string): ITableColumn[] {
		let columns = [];
		if (props.selectedAttributes && props.selectedAttributes.length > 0) {
			props.selectedAttributes.forEach(attribute => {
				let isAdminProject = InternalProjectTypes.isAdminProject(props.project);
				let attributeColumn = this.getAttributeColumn(attribute, attributes, metrics, containerId, isAdminProject);
				attributeColumn.exportType = ExportType.STRING;
				this.pushColumnIfAbsent(attributeColumn, columns);
			});

			if (_.findWhere(props.selectedAttributes, {name: ClarabridgeMetricName.KEY_TERMS})) {
				props.selectedMetrics.unshift(this.metricConstants.get().KEY_TERMS_RANK);
			}

			//add selected metrics
			if (props.selectedMetrics && props.selectedMetrics.length > 0) {
				props.selectedMetrics.forEach(metric => {
					let metricColumn = this.getMetricColumn(metric, metrics, visualProps, props.useHistoricPeriod);
					metricColumn.exportType = ExportType.NUMBER;
					this.pushColumnIfAbsent(metricColumn, columns);
				});
			}
		}

		return columns;
	}

	getObjectViewerColumns(visualProps: VisualProperties, props: WidgetProperties, attributes: IReportAttribute[],
			scorecards: Scorecard[], scorecardModels: Model[], formats: NumericFormats): ITableColumn[] {
		if (_.isEmpty(props.selectedAttributes))
			return [];
		let scorecardId = props.selectedAttributes[0].id;
		let scorecard = _.findWhere(scorecards, { id: scorecardId });
		let decimal = ScorecardUtils.getScorecardDecimal(scorecard, formats, attributes);
		let model = _.findWhere(scorecardModels, { id: scorecard.modelId });
		let columns = [{
			id: 'nodeName',
			name: model.name,
			field: 'nodeName',
			exportType: ExportType.STRING,
			width: 100,
			minWidth: 50,
			formatter: (row, cell, value, columnDef, dataContext) => {
				let hasChildren = dataContext.children && dataContext.children.length;
				let spacer = '<span style="display:inline-block; height:1px; width:' + 24 * dataContext.level + 'px"></span>';
				if (hasChildren) {
					let icon = dataContext._collapsed ? 'q-icon-add' : 'q-icon-minus';
					return spacer + '<span class="ph-4 toggle cursor-pointer ' + icon + '"></span>' + value;
				} else {
					return spacer + value;
				}
			}
		}, {
			id: 'present',
			name: this.locale.getString('scorecards.presenceColumnLabel'),
			field: 'present',
			exportType: ExportType.STRING,
			width: 50,
			minWidth: 50,
			formatter: (row, cell, value, columnDef, dataContext) => {
				if (!dataContext.leaf) return this.locale.getString('scorecards.parentNodePresenceLabel');
				return value
					? this.locale.getString('scorecards.presentLabel')
					: this.locale.getString('scorecards.absentLabel');
			}
		}];
		let weightField = visualProps.normalized ? 'weightN' : 'weight';
		let weightTitle = visualProps.normalized
			? this.locale.getString('scorecards.weightNLabel')
			: this.locale.getString('scorecards.weightLabel');
		columns.push({
			id: weightField,
			name: weightTitle,
			field: weightField,
			exportType: ExportType.STRING,
			width: 50,
			minWidth: 50,
			formatter: (row, cell, value, columnDef, dataContext) => {
				if (dataContext.autoFail) return this.locale.getString('scorecards.autoFailLabel');
				return Number(value).toFixed(decimal);
			}
		});
		return columns;
	}

	private getHistoricValueName(visualProps: VisualProperties, props: WidgetProperties, containerId: string,
			comparison: MetricWidgetComparison): string {
		if (comparison) {
			return comparison.label || this.getDefaultComparisonLabel(comparison.type);
		}

		return this.periodOverPeriodMetricService.getPeriodLabel(2, visualProps, props, containerId)
			|| this.locale.getString('widget.historic_period');
	}

	private getDefaultComparisonLabel(comparisonType: MetricComparisonType): string {
		if (comparisonType === MetricComparisonType.GOAL) {
			return this.locale.getString('widget.goal');
		}

		return this.locale.getString('widget.historic_period');
	}

	getMetricTableColumns(visualProps: VisualProperties, props: WidgetProperties, containerId: string): ITableColumn[] {
		if (visualProps?.visualization === WidgetVisualization.GAUGE) {
			return this.getGaugeMetricTableColumns(visualProps, props, containerId);
		}

		return this.getCommonMetricTableColumns(visualProps, props, containerId);
	}

	private getCommonMetricTableColumns(visualProps: VisualProperties, props: WidgetProperties, containerId: string): ITableColumn[] {
		let columns = [];

		let selectedMetrics: ReportCalculation[] = props.selectedMetrics;

		this.pushColumnIfAbsent(this.getMetricCalculationColumn(), columns);

		let volumnColumnName: string = this.periodOverPeriodMetricService.getPeriodLabel(1, visualProps, props, containerId)
			|| this.locale.getString('widget.current_period');
		this.pushColumnIfAbsent(this.getMetricVolumeColumn(volumnColumnName, selectedMetrics), columns);

		let comparisons = (props as MetricWidgetProperties).comparisons;
		let comparison = comparisons && comparisons[0];

		if (visualProps.previousPeriod) {
			this.pushColumnIfAbsent({
				id: 'historicValue',
				name: this.getHistoricValueName(visualProps, props, containerId, comparison),
				field: 'historicValue',
				exportType: ExportType.NUMBER,
				headerCssClass: 'slick-align-right',
				width: 100,
				minWidth: 50,
				sortable: true,
				formatter: this.getPreviousPeriodFormatter(props, comparison, comparison?.type === MetricComparisonType.GOAL)
			}, columns);
		}

		let historic = visualProps.previousPeriod || visualProps.trendArrow;
		let single = selectedMetrics?.length === 1;
		//multi historic trendArrow = delta
		let multiPoPField = visualProps.trendArrow ? 'delta' : undefined;
		let popField = single
			? this.metricWidgetPOPService.getMetricPoPField(selectedMetrics[0])
			: multiPoPField;

		if (historic && popField) {
			let formatterFactory = (metric: Metric, options) => {
				options = angular.copy(options);
				options.fields = {
					current: 'value',
					historic: 'historicValue'
				};
				if (popField === 'percentChange') {
					return this.reportNumberFormatUtils.getPercentChangeFormatter(metric, options);
				} else {
					//could just delete this property, but better to be explicit
					options.simpleDeltaFormat = false;
					return this.reportNumberFormatUtils.getDeltaFormatter(metric, options);
				}
			};
			this.pushColumnIfAbsent({
				id: popField,
				name: this.getPoPColumnName(popField),
				field: popField,
				exportType: ExportType.NUMBER,
				headerCssClass: 'slick-align-right',
				width: 100,
				minWidth: 50,
				sortable: true,
				formatter: this.getMetricFormatter(selectedMetrics, formatterFactory)
			}, columns);
		}

		return columns;
	}

	private getGaugeMetricTableColumns(visualProps: VisualProperties, props: WidgetProperties, containerId: string): ITableColumn[] {
		let columns = [];

		let selectedMetrics: ReportCalculation[] = props.selectedMetrics;

		this.pushColumnIfAbsent(this.getMetricCalculationColumn(), columns);

		let volumeColumn: ITableColumn = this.getMetricVolumeColumn(this.locale.getString('common.value'), selectedMetrics);
		this.pushColumnIfAbsent(volumeColumn, columns);

		let metricDisplayName = selectedMetrics[0].displayName;
		let metricLabel = this.periodOverPeriodMetricService.getPeriodLabel(1, visualProps, props, containerId);

		if ((props as MetricWidgetProperties).comparisons?.length) {
			this.pushColumnIfAbsent({
				id: 'diff',
				name: this.locale.getString('widget.metricMainCalculationDifference', {metricDisplayName, metricLabel}),
				field: 'diff',
				exportType: ExportType.NUMBER,
				headerCssClass: 'slick-align-right',
				width: 100,
				minWidth: 50,
				sortable: true
			}, columns);
		}
		return columns;
	}

	getMetricCalculationColumn(): ITableColumn {
		return {
			id: 'calculation',
			name: this.locale.getString('widget.calculation'),
			field: 'displayName',
			exportType: ExportType.STRING,
			width: 100,
			minWidth: 50,
			sortable: true
		};
	}

	getMetricCurrentPeriodColumn(customName?: string): ITableColumn {
		return {
			id: 'value',
			name: customName ? customName :  this.locale.getString('widget.current_period'),
			field: 'value',
			exportType: ExportType.NUMBER,
			headerCssClass: 'slick-align-right',
			width: 100,
			minWidth: 50,
			sortable: true,
		};
	}

	private getMetricVolumeColumn(name: string, selectedMetrics: ReportCalculation[]): ITableColumn {
		return _.extend(this.getMetricCurrentPeriodColumn(name), {
			formatter: this.getMetricFormatter(selectedMetrics, this.reportNumberFormatUtils.getNumberTypeFormatter)
		});
	}

	private getAttributeColumn(attribute: AttributeGrouping, attributes: IReportAttribute[], metrics: Metric[],
			containerId: string, isAdminProject: boolean): ITableColumn {
		let attrDisplayName, attrType, formatter, formatterName;

		let group = GroupIdentifierHelper.getGroup(attribute);
		let field = !isAdminProject && AnalyticMetricTypes.isTime(attribute) ? '_metadata_' + group.identifier : group.identifier;
		let objectField = !isAdminProject && AnalyticMetricTypes.isTime(attribute) ? 'displayName' : undefined;

		let dashboardHistory = this.currentWidgets.getDashboardHistory(containerId);
		if (AnalyticMetricTypes.isTime(attribute)) {
			let primaryTimeGrouping = angular.copy(attribute) as any;
			attrDisplayName = primaryTimeGrouping.displayName;
			attrType = 'TEXT';
			if (TimePrimaryGroups.isWeek(primaryTimeGrouping.timeName)) {
				formatterName = 'week';
			} else {
				formatter = this.formattersUtils.buildTimeGroupingFormatter(primaryTimeGrouping);
			}

		} else if (AnalyticMetricTypes.isStudioMetric(attribute)) {
			let attrMetric = this.getStudioMetric(attribute, metrics);
			let metricInfo = attrMetric ? attrMetric : attribute;
			attrDisplayName = metricInfo.displayName;
			attrType = metricInfo.definition.type;
			formatter = this.formattersUtils.buildStudioMetricFormatter(
				metricInfo.definition);
		} else if (AnalyticMetricTypes.isHierarchyModel(attribute)) {
			let topicAttribute = attribute as TopicReportGrouping;
			let level = topicAttribute.selectedLevel || 1;

			let postfix = null;
			if (PeerReportType.PARENT_PEER_REPORT === attribute.peerReportType) {
				postfix = this.locale.getString('widget.peerReportHierarchyTreePostfix');
			} else {
				let personalization = dashboardHistory.getPersonalization();
				level = personalization ? personalization.currentHierarchyNode.level : level;
				let levelObject = _.find(topicAttribute.levels, {level});
				postfix = levelObject && levelObject.type;
			}

			attrDisplayName = attribute.displayName + (postfix ? ' - ' + postfix : '');
			attrType = attribute.type;
		} else if (AnalyticMetricTypes.isPredefinedGroup(attribute)) {
			attrDisplayName = attribute.displayName;
			attrType = attribute.type;
			let predefinedMetric = this.getPredefinedMetric(attribute, metrics);
			formatter = this.formattersUtils.buildPredefinedMetricTableFormatter(predefinedMetric);
		} else if (attribute.objectType === AttributeObjectType.STUDIO_GROUPING) {
			attrDisplayName = attribute.displayName;
			attrType = attribute.type;
		} else {
			let attr = _.find(attributes, {
				id: attribute.id
			});
			attrDisplayName = attr ? attr.displayName : attribute.displayName;
			attrType = attr ? attr.type : attribute.type;
			if (AnalyticMetricTypes.isNumber(attribute)) {
				formatter = this.formattersUtils.buildNumberGroupFormatter();
			}
		}

		if (attribute.urlType) {
			formatter = this.formattersUtils.buildUrlFormatter(attribute.urlType, attribute);
			attrType = attribute.urlType;
		} else {
			formatter = this.formattersUtils.escapeHtmlFormatter(formatter);
			formatter = this.formattersUtils.addCapitalization(attribute.capitalization, formatter);
		}

		let column = {
			id: group.identifier,
			name: attrDisplayName,
			field,
			objectField,
			attributeName: group.name,
			_group: group,
			width: 100,
			minWidth: 50,
			sortable: true,
			type: attrType,
			attribute: true,
			formatter,
			formatterName
		};
		return column;
	}

	private getMetricColumn(metric: ReportCalculation, metrics: Metric[],
			visualProps: VisualProperties, isPop: boolean): ITableColumn {
		let metricName: string;
		let metricDisplayName: string;
		let metricType: ReportAssetType | MetricType;
		let columnMetricType: AnalyticMetricType;

		if (AnalyticMetricTypes.isStudioMetric(metric)) {
			let studioMetric = this.getStudioMetric(metric, metrics);
			if (metric.isPopMetric) {
				let parentStudioMetric = _.findWhere(metrics, {id: metric.id});
				if (parentStudioMetric) {
					metric.parentMetricDisplayName = parentStudioMetric.displayName;
					studioMetric = parentStudioMetric;
				}
			}
			let skipPoPMetricUpdate = false;
			if (!studioMetric) {
				studioMetric = metric as Metric;
				skipPoPMetricUpdate = metric.displayName !== metric.parentMetricDisplayName;
			} else if (isPop) {
				metric.displayName = studioMetric.displayName;
			}

			if (metric.isPopMetric && !skipPoPMetricUpdate) {
				metricName = metric.name;
				metricDisplayName = this.periodOverPeriodMetricService.getActualPeriodOverPeriodMetricDisplayName(
					metric, studioMetric.displayName);
			} else {
				metricName = studioMetric.name;
				metricDisplayName = studioMetric.displayName;
			}
			metricType = studioMetric.definition.type;
		} else if (AnalyticMetricTypes.isScorecardMetric(metric)) {
			metricName = metric.name;
			metricDisplayName = metric.displayName;
			metricType = metric.definition.type;
		} else {
			metricName = metric.name;

			if (metricName === 'easeScore') {
				metricDisplayName = this.locale.getString('metrics.easeScore');
			} else {
				if (metric.isPopMetric) {
					metricDisplayName = this.periodOverPeriodMetricService.getActualPeriodOverPeriodMetricDisplayName(
						metric, metric.parentMetricDisplayName);
				} else {
					metricDisplayName = metric.displayName;
				}
			}

			metricType = metric.type;
		}
		columnMetricType = metric.metricType;

		if (isPop) {
			metric = angular.copy(metric);
			metric.displayName = metricDisplayName;
			metricDisplayName = this.periodOverPeriodMetricService.getMetricDisplayNameWithPeriodPrefix(metric, visualProps);
		}

		let column = {
			id: metricName,
			name: metricDisplayName,
			field: metricName,
			attributeName: metricName,
			width: 100,
			minWidth: 50,
			sortable: true,
			type: metricType,
			headerCssClass: 'slick-align-right',
			attributeMetric: columnMetricType === AnalyticMetricType.ATTRIBUTE,
			historicMetric: metric.isPopMetric,
			parentId: metric.parentMetricName
		} as ITableColumn;

		if (AnalyticMetricTypes.isPredefinedMetric(metric)
			|| /sentiment/.test(metric.name)) { // matches pure sentiment and PoP letiations
			let rawName = metric.parentMetricName || metric.name;
			let builder = this.reportNumberFormatUtils.getNumberTypeFormatter(metric, metric);
			if (metric.name.startsWith(PopDiffPrefix.DELTA) || metric.name.startsWith(PopDiffPrefix.PERCENT_CHANGE)) {
				// delta and % have their own formatters
				column.formatter = builder;
			} else {
				let dbMetric = _.find(metrics, {name: rawName}) as ReportGrouping;
				dbMetric = dbMetric || metric;
				column.formatter = this.predefinedFormatterBuilder.getBuilder(dbMetric, builder, metric.name);
			}
		} else if (metricName === ClarabridgeMetricName.KEY_TERM_RANK) {
			column.formatter = this.reportNumberFormatUtils.tableRightAlignNumberFormatter;
		} else {
			let metricForFormatting;
			if (AnalyticMetricTypes.isScorecardMetric(metric)) {
				metricForFormatting = metric;
			} else {
				metricForFormatting = _.find(metrics, {id: metric.id});
			}
			column.formatter = this.reportNumberFormatUtils.getNumberTypeFormatter(metric, metric, metricForFormatting);
		}

		return column;
	}

	private getPoPColumnName(popField: string): string {
		let popMetricType = this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByPrefix(popField + '_');
		return popMetricType ? popMetricType.getDisplayName(this.locale.getString('widget.calculationValue')) : undefined;
	}

	private getMetricFormatter(selectedMetrics: ReportCalculation[], formatterFactory: any): ColumnFormatter<any, any> {
		return (row, cell, value, columnDef, dataContext) => {
			let metric = this.getMetricByName(selectedMetrics, dataContext.name);
			if (!metric) return value;
			let metricFormatter = formatterFactory(metric, metric);
			return metricFormatter(row, cell, value, columnDef, dataContext, undefined, metric);
		};
	}

	private getPreviousPeriodFormatter(
		props: WidgetProperties, comparison: MetricWidgetComparison, goalMetric: boolean): ColumnFormatter<any, any> {
		return goalMetric && !comparison.inheritFormatFromMetric
			? this.getGoalFormatter(props.selectedMetrics, comparison.format)
			: this.getMetricFormatter(props.selectedMetrics, this.reportNumberFormatUtils.getNumberTypeFormatter);
	}

	private getGoalFormatter(selectedMetrics: ReportCalculation[], format: NumberFormatSettings): ColumnFormatter<any, any> {
		return (row, cell, value, columnDef, dataContext) => {
			let formatter = this.reportNumberFormatUtils.getNumberTypeFormatter(format, format);
			return formatter(row, cell, value, columnDef, dataContext, undefined, format);
		};
	}

	private getMetricByName(metrics: ReportCalculation[], name: string): ReportCalculation {
		return _.findWhere(metrics, {name});
	}

	private pushColumnIfAbsent(column: ITableColumn, columns: ITableColumn[], index?: number): void {
		let present = false;
		for (let col of columns) {
			if (col.id === column.id) {
				col.name = column.name;
				present = true;
				break;
			}
		}
		if (!present) {
			if (index === undefined) {
				columns.push(column);
			} else {
				columns.insert(index, column);
			}
		}
	}

	private getStudioMetric(metric: any, metrics: Metric[]): Metric {
		return _.findWhere(metrics, {id: metric.id});
	}

	private getPredefinedMetric(metric: any, metrics: Metric[]): Metric {
		return _.findWhere(metrics, {name: metric.rawName}) || metric;
	}

}

app.service('tableColumnService', TableColumnService);

