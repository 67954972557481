<div *ngIf="isText()"><label for="user-search" class="font-bold">{{'common.findUsers'|i18n}}</label></div>
<div class="form-group">
	<date-range-picker-popup *ngIf="isDate()"
		class="br-user-date-filter"
		[(fromValue)]="model.date.from"
		[(toValue)]="model.date.to"
		(onChange)="onChange()">
	</date-range-picker-popup>

	<div class="input-group mr-0 h-2_5rem">
		<input *ngIf="isText()" type="text"
			id="user-search"
			class="br-user-filter"
			[(ngModel)]="model.text"
			[ngbTypeahead]="textSuggestions"
			(keydown.enter)="onChange()"
			(keydown.escape)="model.text = ''; onChange()">

		<simple-dropdown *ngIf="isStatus()"
			class="br-user-filter"
			[options]="statusOptions"
			[(value)]="model.status"
			(onChange)="onChange()">
		</simple-dropdown>

		<simple-dropdown *ngIf="isType()"
			class="br-user-filter"
			[options]="typeOptions"
			[(value)]="model.type"
			(onChange)="onChange()">
		</simple-dropdown>

		<simple-dropdown *ngIf="isLicenseType()"
			class="br-user-filter"
			[options]="licenseTypes"
			valueField='licenseTypeId'
			displayField="licenseTypeName"
			[(value)]="model.license"
			allowClear="true"
			selectPrompt=" "
			(onChange)="onChange()">
		</simple-dropdown>

		<simple-dropdown *ngIf="isDefaultMasterAccount()"
			class="br-user-filter"
			[options]="masterAccounts"
			valueField='accountId'
			displayField="accountName"
			sortField="accountName"
			[(value)]="model.defaultMasterAccount"
			searchable="true"
			allowClear="true"
			selectPrompt="{{'administration.noDefaultSelected'|i18n}}"
			(onChange)="onChange()">
		</simple-dropdown>

		<search-list [dropdown]="true"
			class="br-user-filter-type border-0"
			disableSearch="true"
			disableSort="true"
			displayField="name"
			[data]="model.userQueryTypeSelection"
			[listOptions]="userQueryByOptions"
			(onNodeSelection)="onUserQueryByChange($event.node)">
		</search-list>

		<button
			id="btn-search-user" type="button"
			class="btn btn-primary q-icon q-icon-search"
			title="{{'common.search'|i18n}}"
			[attr.aria-label]="'common.search'|i18n"
			(click)=onChange()>
		</button>
	</div>
</div>
