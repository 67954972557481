import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';

import { SortDirection } from '@cxstudio/common/sort-direction';
import { SortUtils } from '@app/shared/util/sort-utils';
import ReportDefinition, { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { HeatmapVisualizationsService } from '@cxstudio/reports/providers/cb/constants/heatmap-visualizations.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import * as _ from 'underscore';

export class HighchartsTreeReportDataBuilder {
	readonly CHILDREN_FOR_SORT: string = '_childrenForSort';
	sortIndex: number = 0;

	constructor(
		private groupColorService: GroupColorService,
		private calculationColorService: CalculationColorService,
		private HeatmapVisualizations: HeatmapVisualizationsService) {
	}

	getTreeData = (reportDefinition): any[] => {
		let data = reportDefinition.dataObject.data;
		let res = [];

		for (let i = 0; i < data.length; i++) {
			let size = reportDefinition.utils.sizeFunction(data[i]);
			let rawSize = reportDefinition.utils.getRawSize(data[i]);
			if (size) {
				let color = reportDefinition.utils.colorFunction ? reportDefinition.utils
					.colorFunction(data[
						i], i) : null;
				res.push({
					id: data[i].id,
					name: data[i].name,
					displayName: data[i].name,
					fullPath: data[i].fullPath,
					value: size,
					color,
					rawSize,
					idPath: data[i].idPath,
					attributeName: data[i].attributeName
				});
			}
		}
		return res;
	}

	private getWrappedComparator = (sortBy: string, direction: SortDirection, alphanumericSort?) => {
		return SortUtils.getComparator(sortBy, direction, alphanumericSort);
	}

	private sortData = (reportDefinition, data): any[] => {
		// in some cases (at least 'cb_an_model_viewer') there is no props in the reportDefinition,
		// will be used 'widgetType' from utils then
		let widgetType = reportDefinition?.props?.widgetType || reportDefinition?.utils?.widgetType;

		if (!this.HeatmapVisualizations.isSortable(reportDefinition.options.subChartType)
			&& widgetType !== WidgetType.SELECTOR
			&& widgetType !== WidgetType.MODEL_VIEWER) {
			return data;
		}

		let options = reportDefinition.options;
		let comparatorArray = [];

		reportDefinition.utils.selectedAttributes.forEach((attribute) => {
			if (options.sortBy === StandardMetricName.ALPHANUMERIC) {
				comparatorArray.push(this.getWrappedComparator(GroupIdentifierHelper.getIdentifier(attribute), options.direction, true));
			} else {
				comparatorArray.push(this.getWrappedComparator(options.sortBy, options.direction));
			}
		});

		return AnalyticDataUtils.recursiveSort(data, comparatorArray, this.CHILDREN_FOR_SORT);
	}


	getAnalyticTreeData = (reportDefinition: ReportDefinition): IDataPoint[] => {
		let groupings = GroupIdentifierHelper.getGroupings(reportDefinition.utils.selectedAttributes);
		if (!groupings.length)
			return [];
		let data = angular.copy(reportDefinition.dataObject.data);

		// recursive sort requires hierarchical data, so we put things into a hierarchy for sorting purposes
		data = this.groupData(groupings, data);
		data = this.sortData(reportDefinition, data);
		data = this.ungroupData(data);

		let seriesNameFormatters = reportDefinition.utils.getGroupingFormatters();
		let hierarchyData = AnalyticDataUtils.getHierarchyDataV2(
			data, groupings, seriesNameFormatters);

		let result = [];
		_.each(hierarchyData, (node) => {
			this.processNode(node, null, reportDefinition.utils.sizeFunction, result, reportDefinition.utils.getRawSize);
		});

		let colorMap = {};
		let colorIndex = 0;
		_.each(result, (item) => {
			if (!item.leaf)
				return;
			let color;
			if (this.groupColorService.isGroupColor(reportDefinition.options.color)
				|| this.calculationColorService.isCalculationColor(reportDefinition.options.color)) {
				color = reportDefinition.utils.colorFunction(item.object, colorIndex++);
			} else {
				if (!colorMap[item.name]) {
					colorMap[item.name] = reportDefinition.utils.colorFunction
						? reportDefinition.utils.colorFunction(item.object, colorIndex++) : null;
				}
				color = colorMap[item.name];
			}
			item.color = item.object.color = color;
		});

		return result;
	}

	private getId = (node): string => {
		return node ? `${node._id}_${node.name}` : null;
	}


	private getSortIndex = () => this.sortIndex++;

	processNode = (node, parent, sizeFunction, result: IDataPoint[], rawSizeFn): void => {
		let size = sizeFunction(node.row);
		let rawSize = rawSizeFn ? rawSizeFn(node.row) : node.row;
		if (!size || size < 0 || isNaN(size))
			return;
		let point: IDataPoint = {
			id: this.getId(node),
			name: node.name,
			_uniqName: node._uniqName,
			displayName: node.displayName,
			value: size,
			object: node.row,
			leaf: node.leaf,
			parent: this.getId(parent),
			sortIndex: this.getSortIndex(),
			rawValue: rawSize
		};
		if (point.object) {
			point.object._uniqName = point._uniqName;
		}
		result.push(point);
		_.each(node._children, (child) => {
			this.processNode(child, node, sizeFunction, result, rawSizeFn);
		});
	}


	groupData = (attributes, data, thisAttrIndex = 0) => {
		if (thisAttrIndex >= attributes.length - 1) return data;

		let thisAttr = attributes[thisAttrIndex].identifier;
		let nextAttr = attributes[thisAttrIndex + 1].identifier;

		let parents: any[] = _.filter(data, (dataPoint) => {
			return !_.isUndefined(dataPoint[thisAttr]) && !!_.isUndefined(dataPoint[nextAttr]);
		});

		if (!parents.length) return data;

		// filter function moved outside of for loop to avoid jshint error
		let getChildFilter = (p) => {
			return (dataPoint) => {
				return !_.isUndefined(dataPoint[thisAttr])
					&& (dataPoint[thisAttr] === parents[p][thisAttr])
					&& !_.isUndefined(dataPoint[nextAttr]);
			};
		};

		for (let p = 0; p < parents.length; p++) {
			let childFilter = getChildFilter(p);
			let children = _.filter(data, childFilter);
			parents[p]._childrenForSort = this.groupData(attributes, children, thisAttrIndex + 1);
		}

		return parents;
	}

	ungroupData = (data) => {
		let isChanges = true;

		while (isChanges) {
			isChanges = false;
			for (let i = data.length - 1; i > -1; i--) {
				if (data[i]._childrenForSort && data[i]._childrenForSort.length) {
					isChanges = true;
					data.insertAll(i + 1, data[i]._childrenForSort);
					delete data[i]._childrenForSort;
				}
			}
		}

		return data;
	}
}

app.service('highchartsTreeReportDataBuilder', HighchartsTreeReportDataBuilder);
