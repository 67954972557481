import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import ILocale from '@cxstudio/interfaces/locale-interface';

/**
 * Extract error from header, handle common cases (pdf export, local)
 */
app.factory('errorInterceptor', ($log, $q, $injector, $rootScope, errorExtractor) => {

	let _locale;

	function getLocale(): ILocale {
		if (!_locale)
			_locale = $injector.get('locale');
		return _locale;
	}

	function extractError(response): void {
		if (response.headers && response.headers().error && response.headers().validation) {
			let message = `${getLocale().getString('common.validationErrorMessage')}<br>`;

			response.data.forEach((validationMessage) => {
				message += `${validationMessage.field}: ${validationMessage.message}<br>`;
			});

			response.data = message;
			return;
		}

		if (response.headers && response.headers().error) {
			response.data = errorExtractor.extract(response.headers());
			if (response.data[0] === '[') { // if array
				try {
					response.data = JSON.parse(response.data);
				} catch (e) {}
			}
		} else if (response.status === 504) {
			// this should never happen
			// if happens - either nginx timeout is smaller than report timeout, or some request stuck
			response.data = 'Connection timed out.'; // not i18n, as it should be the same as hardcoded error on backend
		} else if (typeof response.data === 'string' && response.data.indexOf('<!DOCTYPE') === 0) {
			response.data = getLocale().getString('common.contactAdmin');
		}
	}

	return {
		responseError: (response): any => {
			if ($rootScope.pdfToken) { // do not show errors in pdf export
				return $q.reject(response);
			}

			extractError(response);

			let factory = $injector.get('errorHandlerFactory');
			if (factory.isHandledLocally(response)) {
				return $q.reject(response);
			}

			let handler = factory.getHandler(response.status);
			if (handler) {
				return handler.handleError(response);
			} else {
				let errorDialogService: ErrorDialogService = $injector.get('errorDialogService');
				errorDialogService.error(getLocale().getString('common.unknownError'));
				$log.error(response);
				$log.error(response && response.data);
				$injector.get('analyticsService').trackNetworkEvent('unknown-error', response);
				return $q.reject(response);
			}
		}
	};
});
