import { Inject, Injectable } from '@angular/core';
import { DateService } from '@cxstudio/services/date-service.service';
import {
	ConversationDataPoint
} from '@cxstudio/conversation/conversation-data-point.class';
import {
	ChannelTypes
} from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import {
	ConversationEnrichment,
	ConversationHeader
} from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import * as _ from 'underscore';
import {
	ConversationChannelLabels
} from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ConversationParticipantService } from '@app/modules/document-explorer/conversation-participant.service';

@Injectable({
	providedIn: 'root'
})
export class ConversationTooltipService {
	constructor(
		private readonly locale: CxLocaleService,
		private conversationParticipantService: ConversationParticipantService,
		@Inject('dateService') private readonly dateService: DateService,
	) {
	}

	formatDuration = (duration: number): string => {
		const intDuration = Math.round(duration);

		let value;
		let localeKey;

		if (intDuration < 55 * 60) {
			value = Math.round(intDuration / 60);
			localeKey = 'widget.minuteShort';
		} else if (intDuration < 1410 * 60) {
			value = Math.round(intDuration / 60 / 60);
			localeKey = 'widget.hourShort';
		} else {
			value = Math.round(intDuration / 24 / 60 / 60);
			localeKey = 'widget.dayShort';
		}

		const translation = this.locale.getString(localeKey, [value]);

		return translation;
	}

	getHeaderTooltip = (enrichments: ConversationHeader[], isPrimary?: boolean): string => {
		const headerLabel = isPrimary ?
			this.locale.getString('docExplorer.spineMainColumnName') :
			this.locale.getString('docExplorer.spineAdditionalColumnName');

		return `<h3>${headerLabel}</h3> ${this.getHeaderEnrichments(enrichments)}`;
	}


	// return a function to generate the tooltips with chat formatting and custom labels
	getWrappedTooltipGenerator = (isChat: boolean, customLabels?: ConversationChannelLabels): any => {
		const getChannelName = (data: ConversationDataPoint): string => {
			if (data.channel && [ChannelTypes.AGENT, ChannelTypes.CLIENT, ChannelTypes.BOT,
				ChannelTypes.UNKNOWN].indexOf(data.channel as ChannelTypes) > -1) {
				if (customLabels && customLabels[data.channel])
					return customLabels[data.channel];

				return this.locale.getString(`docExplorer.${data.channel}Label`);
			}

			if (data.isSilence) {
				return isChat ? this.locale.getString('docExplorer.responseTime')
					: this.locale.getString('preview.silenceMetric');
			}

			if (data.isOvertalk) {
				return this.locale.getString('docExplorer.overtalk');
			}
		};

		return (data: ConversationDataPoint, enrichments: ConversationEnrichment[]): string => {
			const participantLabel = this.conversationParticipantService.getParticipantLabel(getChannelName(data), data.participantIndex);

			let tooltip = `
				<div class="tooltip-header">
					${this.getIconElement(data, isChat)}
					<span class="tooltip-channel">${participantLabel}</span>
					<span class="no-wrap">${this.getTimestamp(data, isChat)}</span>
				</div>`;

			if (!data.isSilence) {
				tooltip += `
					<div class="tooltip-content ${this.getChannelColorClass(data)}">
						<span class="tooltip-text font-size-4">${data.text}</span>
						${this.getEnrichments(data, enrichments)}
					</div>`;
			}

			const warnings = this.getWarnings(data, enrichments);

			if (warnings) {
				tooltip += `
					<div class="pt-4">
						${warnings}
					</div>
				`;
			}

			return tooltip;
		};
	}

	private getIconClass = (data: ConversationDataPoint, chat: boolean): string | undefined => {
		if (data.isSilence)
			return chat ? 'q-icon-bubble-clock' : 'q-icon-conversation-silence';

		if (data.channel === ChannelTypes.BOT) {
			return 'q-icon-robot';
		}

		if (data.channel === ChannelTypes.UNKNOWN) {
			return 'q-icon-help';
		}

		if (data.channel) {
			return (data.channel === ChannelTypes.AGENT) ?
				'q-icon-conversation-agent-circle' :
				'q-icon-conversation-customer-circle';
		}
	}

	private getChannelColorClass = (data: ConversationDataPoint): string | undefined => {
		if (data.channel) {
			return `channel-${data.channel}`;
		}
	}

	private getIconElement = (data: ConversationDataPoint, chat: boolean): string => {
		const iconClass: string = this.getIconClass(data, chat);
		const iconColorClass: string = this.getChannelColorClass(data);
		const dynamicIconColor = this.conversationParticipantService.getIconColor(data.channel, data.participantIndex);
		return `<span class='call-icon ${iconColorClass}'><i class="${iconClass}" style="color: ${dynamicIconColor}"></i></span>`;
	}

	private getTimestamp = (data: ConversationDataPoint, chat: boolean): string => {
		if (chat) {
			return data.isSilence ?
				data.text :
				this.dateService.getElapsedTime(data.timestamp);
		} else {
			const startTime = this.dateService.secondsToTime(data.timestamp);
			const endTime = data.endTimestamp ? this.dateService.secondsToTime(data.endTimestamp) : '';
			return endTime ? `${startTime}-${endTime}` : startTime;
		}
	}

	private getEnrichments = (data: ConversationDataPoint, enrichments: ConversationEnrichment[]): string => {
		if (data.channel === ChannelTypes.BOT) {
			return '';
		}

		const enrichmentsData = _.chain(enrichments)
			.filter(enrichment => enrichment?.hasValue && enrichment?.hasValue(data))
			.map(enrichment => enrichment.tooltipFormatter(data))
			.filter(str => !!str)
			.join('\n')
			.value() || '';

		return `<div class="tooltip-enrichments d-flex flex-multiline text-1rem">${enrichmentsData}</div>`;
	}

	private getWarnings = (data: ConversationDataPoint, enrichments: ConversationEnrichment[]): string => {
		return _.chain(enrichments)
			.filter(enrichment => !!enrichment?.getWarning)
			.map(enrichment => enrichment.getWarning(data))
			.filter(str => !!str)
			.map(warning => `
				<div class="alert alert-warning h-100-percent w-100-percent mb-0">
					<i class="alert-icon q-icon-warning"></i>
					<span>${this.locale.getString(warning)}</span>
				</div>
			`)
			.join('')
			.value() || '';
	}

	private formatHeaderEnrichment = (enrichment: ConversationHeader): string | undefined => {
		if (!enrichment) return;

		return `<div class="metric-identifier"><span class="${enrichment.getHeaderIcon()}"></span><span class="text-ellipsis">${enrichment.getName()}</span></div>`;
	}

	private getHeaderEnrichments = (enrichments: ConversationHeader[]): string => {
		return _.chain(enrichments)
			.map(enrichment => this.formatHeaderEnrichment(enrichment))
			.filter(str => !!str)
			.join('\n')
			.value() || '';
	}
}

app.service('conversationTooltip', downgradeInjectable(ConversationTooltipService));
