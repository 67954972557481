import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Unit } from '@app/modules/units/unit';
import { UserWorkspacesService } from '@app/modules/units/workspace-project-selector/user-workspaces.service';
import { ProjectSelectorData } from '@app/shared/components/project-selector/project-selector-data';

@Component({
	selector: 'workspace-selector',
	template: `
		<div *ngIf="!workspace.hasSingleOption()" class="d-flex" [ngClass]="getGroupClasses()">
			<label class="control-label" [ngClass]="getLabelClasses()">{{'common.workspace'|i18n}}</label>
			<div [ngClass]="getSelectorClasses()">
				<simple-dropdown
					[options]="workspace.getOptions()"
					[value]="workspace.getSelection()"
					(valueChange)="workspace.changeSelection($event)"
					displayField="name"
					valueField="id"
					[selectPrompt]="getWorkspacePrompt()"
				></simple-dropdown>
			</div>
		</div>

	`,
	styles: [
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceSelectorComponent implements OnInit {
	@Output() workspaceChange = new EventEmitter<Unit>();
	@Output() loading = new EventEmitter<Promise<void>>();

	workspace: ProjectSelectorData<Unit>;
	workspaces: Unit[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly userWorkspacesService: UserWorkspacesService
	) { }

	ngOnInit(): void {
		this.workspace = new ProjectSelectorData('id');
		this.workspace.onChange.subscribe(this.onWorkspaceChange);

		this.loading.emit(this.loadWorkspaces());
	}

	loadWorkspaces(): Promise<void> {
		return this.userWorkspacesService.getUserWorkspaces()
			.then(workspaces => {
				workspaces.forEach(ws => ws.name = ws.name || '');
				this.workspace.setOptions(workspaces);
				this.ref.detectChanges();
			});
	}

	hasMultipleWorkspaces(): boolean {
		return this.workspaces?.length > 1;
	}

	getGroupClasses(): string[] {
		let classes = [];
		classes.push('mb-16');
		classes.push('flex-direction-row');
		return classes;
	}

	getLabelClasses(): string[] {
		let classes = [];
		classes.push('pr-8');
		return classes;
	}

	getSelectorClasses(): string {
		return 'pl-8';
	}


	onWorkspaceChange = (): void => {
		this.workspaceChange.emit(this.workspace.getSelectedObject());
	}

	getWorkspacePrompt = (): string => {
		return this.locale.getString('common.selectPrompt');
	}

}
