import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

export interface DateFormatters {
	date: (dateObject: Date) => string;
	time: (dateObject: Date) => string;
}

@Component({
	selector: 'date-label',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<span title="{{getFormattedDateTime()}}">{{getFormattedDate()}}</span>`
	
})
export class DateLabelComponent implements OnInit {
	@Input() documentDate: Date;
	@Input() formatters: DateFormatters;

	formattedTime: string;
	formattedDate: string;

	constructor() { }

	ngOnInit(): void {
	}

	getFormattedDate = (): string => {
		return this.formatters.date(this.documentDate);
	}

	getFormattedDateTime = (): string => {
		return this.formatters.time(this.documentDate);
	}
}

app.directive('dateLabel', downgradeComponent({component: DateLabelComponent}) as angular.IDirectiveFactory);