import { Injectable, Inject } from '@angular/core';
import { GridsterEvent, WidgetEvent } from './cx-event.enum';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { DarkBrandingColors } from '@cxstudio/master-accounts/dark-branding-colors.enum';
import { HighchartsConfig } from '@cxstudio/reports/visualizations/highcharts-config.service';
import { CxHttpService } from './cx-http.service';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TypeGuards } from '@app/util/typeguards.class';


@Injectable({
	providedIn: 'root'
})

export class ApplicationThemeService {

	readonly APP_THEME_QUERY_PARAM = 'theme';
	readonly APP_THEME_CLASS = 'application-dark-mode';

	readonly DASHBOARD_THEME_QUERY_PARAM = 'dashboardTheme';
	readonly DASHBOARD_THEME_CLASS = 'dashboard-dark-mode';

	private currentDashboardTheme: ApplicationTheme;
	private forcedLightTheme: boolean;

	constructor(
		@Inject('urlService') private urlService: UrlService,
		@Inject('security') private security: Security,
		@Inject('highchartsConfig') private highchartsConfig: HighchartsConfig,
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService,
		@Inject(DOCUMENT) private readonly document: Document,
		private http: CxHttpService
	) {}

	getDashboardBrandingColors = () => {
		if (this.isShowingDashboardDarkTheme()) {
			return DarkBrandingColors;
		}
		return this.security.getActiveMasterAccountColors();
	}

	getAppBrandingColors = () => {
		if (this.isShowingDarkTheme()) {
			return DarkBrandingColors;
		}
		return this.security.getActiveMasterAccountColors();
	}

	applySelectedThemes = (forceApplication: boolean = false): void => {
		let maId = this.security.getMasterAccountId();
		if (maId > 0) {
			this.applyApplicationTheme(forceApplication);
		}
	}

	applyApplicationTheme(forceApplication: boolean = false): void {
		this.applyTheme(this.getAppliedApplicationTheme(), forceApplication);
	}

	applyTheme(theme: ApplicationTheme, forceApplication: boolean = false): void {
		const scopeTagId = 'app-branding';
		let maId = this.security.getMasterAccountId();
		if (maId < 0) {
			return;
		}
		let params = $.param({ t: new Date().getTime(), theme });
		let cssUrl = this.urlService.getAPIUrl(`rest/css/masterAccount/${maId}/brand-variables.css?${params}`);

		let themeStylesLink = this.document.getElementById(scopeTagId);
		if (!!themeStylesLink && TypeGuards.isLinkElement(themeStylesLink)) {
			if (!forceApplication && this.isRequestedThemeApplied(theme, themeStylesLink)) return;

			themeStylesLink.href = cssUrl;
		} else {
			const linkEl = document.createElement('link');
			linkEl.rel = 'stylesheet';
			linkEl.href = cssUrl;
			linkEl.id = scopeTagId;
			this.document.querySelector('body').append(linkEl);
		}
	}

	isRequestedThemeApplied(requestedTheme: ApplicationTheme, themeStylesLink: HTMLLinkElement): boolean {
		const currentTheme = /theme=([A-Z]+)/.exec(themeStylesLink.href)?.[1];
		return currentTheme === requestedTheme;
	}

	applyDashboardDarkThemeForPreview(): void {
		this.applyTheme(ApplicationTheme.DARK);
		this.highchartsConfig.initOptions(ApplicationTheme.DARK);
		this.notifyGridster();
	}

	private notifyGridster(): void {
		this.$rootScope.$broadcast(GridsterEvent.RESET_DRAG);
		this.$rootScope.$broadcast(WidgetEvent.RELOAD);
	}

	isShowingDarkTheme = (): boolean => {
		return ApplicationTheme.DARK === this.getAppliedApplicationTheme();
	}

	isShowingDashboardDarkTheme = (): boolean => {
		return ApplicationTheme.DARK === this.getAppliedDashboardTheme();
	}

	isDarkMode = (themeScope: ApplicationThemeScope): boolean => {
		switch (themeScope) {
			case ApplicationThemeScope.APPLICATION:
			case ApplicationThemeScope.DOC_EXPLORER:
				return this.isShowingDarkTheme();
			case ApplicationThemeScope.DASHBOARD_CONTAINER:
			case ApplicationThemeScope.CHART_DEMO:
				return this.isShowingDashboardDarkTheme();
			default: return false;
		}
	}

	/**
	 * Resets dashboard theme to match application theme
	 */
	resetDashboardTheme(): void {
		this.setDashboardTheme(this.getAppliedApplicationTheme());
	}

	setDashboardTheme = (dashboardTheme: ApplicationTheme): void => {
		this.applyTheme(dashboardTheme);

		this.highchartsConfig.initOptions(dashboardTheme);
		this.currentDashboardTheme = dashboardTheme;

		this.notifyGridster();
	}

	setForcedLightTheme(): void {
		this.forcedLightTheme = true;
	}

	/**
	 * @returns only returns 'DEFAULT' or 'DARK'; 'AUTOMATIC' gets resolved in one of those options
	 */
	getAppliedApplicationTheme = (): ApplicationTheme => {
		if (!this.isDarkModeAvailable() || this.isExportingPdf() || this.forcedLightTheme) {
			return ApplicationTheme.DEFAULT;
		} else {
			let selectedThemeOption = this.security.loggedUser?.applicationTheme;

			if (selectedThemeOption === ApplicationTheme.AUTOMATIC) {
				return this.resolveOperatingSystemTheme();
			} else {
				return selectedThemeOption;
			}
		}
	}

	getAppliedDashboardTheme = (): ApplicationTheme => {
		if (!this.isDarkModeAvailable() || this.isExportingPdf()) {
			return ApplicationTheme.DEFAULT;
		} else {
			return this.currentDashboardTheme || this.getAppliedApplicationTheme();
		}
	}

	private isDarkModeAvailable = (): boolean => {
		return this.security.getCurrentMasterAccount().darkModeAvailable;
	}

	resolveOperatingSystemTheme = (): ApplicationTheme => {
		return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
			? ApplicationTheme.DARK
			: ApplicationTheme.DEFAULT;
	}

	private isExportingPdf = (): boolean => {
		return this.$rootScope.pdfToken;
	}

	getActionColor = (dashboard: boolean = false): string => {
		return dashboard
			? this.getDashboardBrandingColors().COLOR_4
			: this.getAppBrandingColors().COLOR_4;
	}


	/**
	 * Pass a hex code and get back a contrasting color
	 */
	getContrastColor(hexcode: string): Promise<{ contrast: string}> {
		const params = new HttpParams({fromObject: {hexcode}});
		return this.http.get(`rest/css/contrast`, { params });
	}

}

app.service('applicationThemeService', downgradeInjectable(ApplicationThemeService));
