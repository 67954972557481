<ng-container *ngIf="isAutomatedSummariesEnabled">
  <ngb-accordion #acc="ngbAccordion" class="w-100-percent">
    <ngb-panel class="w-100-percent" id="coachingAssistantPanel">
      <ng-template ngbPanelHeader class="w-100-percent pl-0">
        <button *ngIf="!coachingTips$; else activeButton" class="btn btn-link no-hover pl-0" disabled>
          <div class="d-flex overflow-hidden justify-start align-items-center">
            <icon name="angle-right" class="mr-16"></icon>
            <p class="text-ellipsis mb-0 p-4">{{'preview.coachingAssistant'|i18n}}</p>
          </div>
        </button>
        <ng-template #activeButton>
          <button ngbPanelToggle *ngIf="coachingTips$" class="btn btn-link no-hover pl-0">
            <div class="d-flex overflow-hidden justify-start align-items-center">
              <icon *ngIf="acc.isExpanded('coachingAssistantPanel')" name="angle-bottom" class="mr-16"></icon>
              <icon *ngIf="!acc.isExpanded('coachingAssistantPanel')" name="angle-right" class="mr-16"></icon>
              <p class="text-ellipsis mb-0 p-4">{{'preview.coachingAssistant'|i18n}}</p>
            </div>
          </button>
        </ng-template>
        <ng-container *ngIf="!coachingTips$; else copyButton">
          <button
            ngbPanelToggle
            type="button"
            class="btn btn-secondary"
            (click)="generateCoachingTips()"
          >
            {{'preview.generate'|i18n}}
          </button>
        </ng-container>
        <ng-template #copyButton>
          <button
            class="btn btn-icon"
            (click)="copyCoachingTips()"
          >
            <icon name="copy"></icon>
          </button>
        </ng-template>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="coaching-assistant-panel d-flex h-100-percent flex-direction-column justify-center align-items-center">
          <ng-container *ngTemplateOutlet="coachingTipsUI"></ng-container>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-container>

<div *ngIf="!isAutomatedSummariesEnabled" class="coaching-assistant-panel d-flex h-100-percent flex-direction-column justify-center align-items-center">
	<ng-container *ngIf="!coachingTips$">
		<button
			type="button"
			class="btn btn-secondary mb-16 d-flex flex-direction-row align-items-center"
			(click)="generateCoachingTips()"
		>
			<p class="mb-0">{{'preview.generateTip'|i18n}}</p>
		</button>
	</ng-container>
  <ng-container *ngTemplateOutlet="coachingTipsUI"></ng-container>
</div>

<ng-template #coachingTipsUI>
  <ng-container *ngIf="coachingTips$" [ngBusy]="coachingTipsSub">
    <div class="d-flex flex-direction-column w-100-percent h-100-percent overflow-auto mb-16">
      <div *ngIf="isLoading" class="h-min-160"></div>
      <p *ngIf="!isLoading" class="text-1rem m-0 text-gray-1000 font-normal" [innerHTML]="coachingTips$ | async | coachingTips"></p>
    </div>
    <div *ngIf="!isLoading" class="border-t-1 border-t-solid border-gray-500 d-flex flex-direction-column w-100-percent mb-16">
      <ng-container *ngIf="!feedbackGiven; else feedbackGivenSection">
        <div class="d-flex flex-direction-row justify-between align-items-center w-100-percent h-100-percent pt-4">
          <div class="d-flex align-items-center w-100-percent">
            <p class="m-0 font-normal text-gray-900 text-0_875rem">{{'preview.leaveFeedback'|i18n}}</p>
          </div>
          <div class="thumb-section d-flex flex-direction-row align-items-center justify-end w-100-percent h-100-percent">
            <button [attr.aria-label]="'preview.thumbsUp'|i18n" class="btn btn-icon" (click)="sendCoachingFeedback(1)">
              <icon name="thumb-up"></icon>
            </button>
            <button [attr.aria-label]="'preview.thumbsDown'|i18n" class="btn btn-icon" (click)="sendCoachingFeedback(0)">
              <icon name="thumb-down"></icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #feedbackGivenSection>
        <div class="d-flex justify-center align-items-center w-100-percent h-100-percent pv-4">
          <p class="mv-8 font-normal text-gray-900 text-0_875rem pt-8">{{'preview.thankYou'|i18n}}</p>
          <button title="{{'preview.regenerateTip'|i18n}}" [attr.aria-label]="'preview.regenerateTip'|i18n" class="btn btn-icon ml-4" (click)="regenerateCoachingTips()">
            <icon name="refresh"></icon>
          </button>
        </div>
      </ng-template>
      <div class="d-flex flex-direction-row align-items-center">
        <p class="m-0 font-normal text-gray-900 text-0_875rem">{{'preview.qualtricsAIStatement'|i18n}}</p>
        <icon
          class="ml-4"
          name='info'
          [ngbTooltip]="qualtricsAiTooltip"
          [closeDelay]="1000"
          placement="top"
          tooltipClass="text-wrap cb-tooltip w-25-percent"
          container="body"
        >
          <ng-template #qualtricsAiTooltip>
            <p class="p-8 font-normal text-0_875rem" [i18n]="'preview.qualtricsAITooltipMessage'"></p>
          </ng-template>
        </icon>
      </div>
    </div>
  </ng-container>
</ng-template>
