import { PipeTransform, Pipe, Inject } from '@angular/core';
import { AttributeValues } from '@app/modules/project/attribute/attribute-values';

@Pipe({
	name: 'excludeNarrativeAttributeFilter'
})
export class ExcludeNarrativeAttributeFilterPipe implements PipeTransform {

	constructor() {}

	transform(attributes: AttributeValues[], narrativeEntries: string[]): AttributeValues[] {
		if (!attributes) {
			return [];
		} else if (_.isEmpty(narrativeEntries)) {
			return attributes;
		}

		return _.filter(attributes, attribute => !_.some(narrativeEntries, (name) => name === attribute?.name.toLowerCase()));
	}
}
