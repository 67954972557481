import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CxLocaleService, ILocaleInstance, SupportedLocale } from '@app/core';
import { SystemDictionaries } from '@app/modules/system-administration/platform/system-dictionary/system-dictionaries';
import {
	SystemDictionaryApi
} from '@app/modules/system-administration/platform/system-dictionary/system-dictionary-api.service';

interface ILanguage {
	id: SupportedLocale;
	name: string;
}

@Component({
	selector: 'dictionary-tab',
	templateUrl: './system-dictionary-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemDictionaryTabComponent implements OnInit {
	locales: ILanguage[];

	selectedLocale: SupportedLocale;
	currentDictionaryAsString: string;

	initialDictionaries: SystemDictionaries;

	isEditing: boolean = false;

	loading: Promise<unknown> | undefined;

	constructor(
		private locale: CxLocaleService,
		private readonly api: SystemDictionaryApi
	) {}

	ngOnInit() {
		const locales: ILocaleInstance[] = this.locale.getSupportedLocales();

		this.locales = locales.map(
			(locale) => {
				return {
					id: locale.id,
					name: this.locale.getString(locale.label),
				};
			}
		);

		this.reloadDictionaries().then(
			() => {
				this.selectedLocale = Object.keys(this.initialDictionaries)[0] as SupportedLocale;
				this.currentDictionaryAsString = this.initialDictionaries[this.selectedLocale].join(',');
			}
		);
	}

	resetCurrentDictionary = (updatedLocale: string ) => {
		this.currentDictionaryAsString = this.initialDictionaries[updatedLocale as SupportedLocale].join(',');
	}

	updateCurrentDictionaryAsString = (updatedDictionaryAsString: string) => {
		this.currentDictionaryAsString = updatedDictionaryAsString;
	}

	toggleEditing = (): void => {
		this.isEditing = !this.isEditing;

		if (!this.isEditing) {
			this.resetCurrentDictionary(this.selectedLocale);
		}
	}

	saveChanges = (): void => {
		const currentDictionaryAsArray = this.currentDictionaryAsString
			.split(',')
			.map((word) => word.toLowerCase().trim())
			.filter((word) => word !== ',' && word !== '');

		this.loading = this.api
			.updateDictionary(this.selectedLocale, [... new Set(currentDictionaryAsArray)])
			.then(this.reloadDictionaries)
			.then(() => {
				this.isEditing = false;
				this.resetCurrentDictionary(this.selectedLocale);
			});
	}

	private reloadDictionaries = (): Promise<void> => {
		const getDictionariesPromise = this.api.getDictionaries().then(
			(dictionaries) => {
				this.locales.forEach(
					(supportedLocale) => {
						// If a dictionary does not exist yet in the backend, initialise with an empty array
						if (!dictionaries[supportedLocale.id]) {
							dictionaries[supportedLocale.id] = [];
						}
					}
				);

				this.initialDictionaries = dictionaries;
			}
		);

		this.loading = getDictionariesPromise;

		return getDictionariesPromise;
	}
}
