import { SafeHtml } from '@angular/platform-browser';

export enum TooltipType {
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info-tooltip'
}

export interface TooltipPosition {
	top: string;
	left: string;
}

export interface TokenTooltip {
	visible?: boolean;
	text?: SafeHtml;
	position?: TooltipPosition;
	focusFormulaOnHide?: boolean;
}