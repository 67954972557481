import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import * as _ from 'underscore';

export class AlertAutofillUtils {

	private static readonly AUTOFILL_DASHBOARD_LABEL = { label: 'alertDashboard' };
	private static readonly AUTOFILL_ALERT_NAME = { label: 'alertName' };
	private static readonly AUTOFILL_ALERT_TRIGGER = { label: 'alertTrigger' };
	private static readonly AUTOFILL_ALERT_CALCULATION = { label: 'alertCalculation' };
	private static readonly AUTOFILL_ALERT_CRITERIA = { label: 'alertCriteria' };
	private static readonly AUTOFILL_ALERT_THRESHOLD = { label: 'alertThreshold' };
	private static readonly AUTOFILL_ALERT_GROUP_VALUES = { label: 'alertGroupValues' };

	private static autofillOptions: Array<{label: string}> = [
		AlertAutofillUtils.AUTOFILL_ALERT_NAME,
		AlertAutofillUtils.AUTOFILL_DASHBOARD_LABEL,
		AlertAutofillUtils.AUTOFILL_ALERT_TRIGGER,
		AlertAutofillUtils.AUTOFILL_ALERT_CALCULATION,
		AlertAutofillUtils.AUTOFILL_ALERT_CRITERIA,
		AlertAutofillUtils.AUTOFILL_ALERT_THRESHOLD,
		AlertAutofillUtils.AUTOFILL_ALERT_GROUP_VALUES
	];

	private static noDashboardAutofill: Array<{label: string}> =
		_.filter(AlertAutofillUtils.autofillOptions, (option) => option !== AlertAutofillUtils.AUTOFILL_DASHBOARD_LABEL);

	static getAutofillText = (item: {label: string}): string => {
		return `{${item.label}}`;
	}

	static getAutofillOptions(alert?: StudioAlert) {
		if (!alert) {
			return this.noDashboardAutofill;
		}

		// if they don't have dashboard selected, no reason to offer up the autofill option for dashboard name
		return AlertAutofillUtils.isDashboardSelected(alert) ? this.autofillOptions : this.noDashboardAutofill;
	}

	static isDashboardSelected = (alert: StudioAlert): boolean => {
		return !_.isUndefined(alert.notification) && !isEmpty(alert.notification.linkDashboard);
	}

}
