import * as _ from 'underscore';

export class ShareUtilitiesService {

	constructor() {
	}

	$onInit = () => {
		// required for typescript validation
	}

	findExistingEntity = (allEntities, entity) => {
		if (entity) {
			let entityName = entity.entity ? entity._name : entity;
			return _.find(allEntities, (item: any) => {
				return item._name.toLowerCase() === entityName.toLowerCase();
			});
		}
	}
}

app.service('shareUtilities', ShareUtilitiesService);
