import { DatasetWarning } from '@cxstudio/drivers/entities/dataset-warning';

export class DatasetStats {
	volume: number;
	targetPerc: number;
	attributesAndModelsCount?: number;
	warnings?: DatasetWarning[];
	targetVolume?: number;
	targetPercText?: string;
}
