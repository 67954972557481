import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import Listener from '@cxstudio/common/listener';

@Component({
	selector: 'home-general-settings',
	templateUrl: './home-general-settings.component.html',
})
export class HomeGeneralSettingsComponent implements OnInit {
	readonly TITLE_LIMIT = 50;

	@Input() homePage: HomePage;
	@Input() saveListener: Listener;
	@Output() onChange = new EventEmitter<void>();

	constructor() { }

	ngOnInit(): void {
	}
}
