/* Expand group's users to show them in share dialog table */
app.filter('shareTree', () => {

	return (usersAndGroups) => {
		if (!usersAndGroups)
			return [];

		let array = [];
		usersAndGroups.forEach((item) => {
			array.push(item);

			if (item.type === 'group' && !item._collapsed && item._children) {
				item._children.forEach((innerItem) => {
					innerItem.permission = item.permission;
					array.push(innerItem);
				});
			}
		});
		return array;
	};
});
