import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root',
})
export class FilterUiUtilService {
	readonly FILTER_TYPE_CLASS = {
		APPLIED: 'br-applied-filters',
		ADDITIONAL: 'widget-create-filters',
	};
	constructor() {}

	focusNewAppliedFilter = () => {
		this.focusFilterInput(this.FILTER_TYPE_CLASS.APPLIED);
	}

	private focusFilterInput(filterTypeClass: string): void {
		setTimeout(() => {
			const filterStatements = document.querySelectorAll('.' + filterTypeClass + ' .filter-statement');

			if (filterStatements?.length > 0) {
				const targetFilter = filterStatements[filterStatements.length - 1];
				(targetFilter.querySelector('input') as HTMLInputElement).focus();
			}
		}, 0);
	}
}

app.service('filterUiUtilService', downgradeInjectable(FilterUiUtilService));
