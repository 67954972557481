import { AttributesService, IAttributeValuesParams } from '@app/modules/project/attribute/attributes.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import * as _ from 'underscore';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';

export class WordsSelectionController implements ng.IController {

	//one of
	project: AccountOrWorkspaceProject;
	props: WidgetProperties;

	item: AttributeGrouping;
	tagChanged: () => void;
	ngDisabled: boolean;
	includeOnly: boolean;

	private values: any[];
	minTags: number;
	tagsPlaceholder: string;

	constructor(
		private tagService: TagsService,
		private locale: ILocale,
		private metricUtils,
		private attributesService: AttributesService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
	) {}

	$onInit(): void {

		this.values = [];
		if (_.isUndefined(this.item.wordsFilteringMode)) {
			this.item.wordsFilteringMode = WordsFilteringMode.EXCLUDE;
		}

		if (AnalyticMetricTypes.isPredefinedGroup(this.item)) {
			this.values = this.getPredefinedValues(this.item);
		}

		let wordObjects = AnalyticMetricTypes.isPredefinedGroup(this.item)
			? this.tagService.predefinedToTagObjects(this.item.wordsList, this.values)
			: this.tagService.stringArrayToTagObjects(this.item.wordsList);
		this.item.wordsList = wordObjects;

		this.minTags = this.item.wordsFilteringMode ? 1 : 0;
		this.tagsPlaceholder = this.item.wordsFilteringMode
			|| (this.item.wordsList && this.item.wordsList.length) ? ''
			: this.locale.getString('common.search');
	}

	private getPredefinedValues(item): any[] {
		let metricValues = this.metricUtils.getPredefinedMetricValues(item);
		let displayNames = this.metricUtils.getPredefinedMetricDisplayNames(item);
		return _.map(displayNames, (displayName, index) => {
			return {
				text: displayName,
				value: metricValues[index]
			};
		});

	}

	private getValuesByQuery(query: string): any[] {
		return _.filter(this.values, value => {
			return value.text.toLowerCase().indexOf(query.toLowerCase()) > -1;
		});
	}

	filterQuery = (query: string) => {
		let cleanedQuery = query.replace('}', '');
		if (AnalyticMetricTypes.isPredefinedGroup(this.item)) {
			return this.getValuesByQuery(cleanedQuery);
		}

		let project = this.project || this.reportAssetUtilsService.getWidgetPropertiesProject(this.props);
		let params: Partial<IAttributeValuesParams> = {
			project,
			attributeNames: [this.item.name],
			filter: cleanedQuery
		};
		return this.attributesService.getAttributeValues(params).then(attributes => (attributes as any) === '' ? [] : attributes);
	}

}

app.component('wordsSelection', {
	controller: WordsSelectionController,
	templateUrl: 'partials/custom/words-selection.html',
	bindings: {
		props: '<',
		project: '<',
		item: '<',
		tagChanged: '&',
		ngDisabled: '<',
		includeOnly: '<'
	}
});
