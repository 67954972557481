<div class="w-100-percent d-flex align-items-center">
	<select-from-tree class="flex-fill overflow-hidden"
		[appendToBody]="true"
		[disabled]="disabled"
		[ngModel]="selection?.name"
		[hierarchyList]="filterSelectedAttributesForGrouping(options)"
		(onNodeClick)="selectOption($event.node)">
	</select-from-tree>
	<ng-container *ngIf="cogTemplate">
		<ng-container *ngTemplateOutlet="cogTemplate"></ng-container>
	</ng-container>
	<button *ngIf="!cogTemplate"
		type="button"
		class="btn btn-icon grouping-config ml-8"
		[disabled]="disabled || !selection"
		(click)="openProperties.emit(selection)">
		<span class="q-icon q-icon-cog"></span>
	</button>
</div>


