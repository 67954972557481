import { SingleLaneEnrichmentTypes } from '../conversation-chart-options.class';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';

export enum ParticipantEnrichmentTypes {
	SENTIMENT = 'sentiment',
	EMOTION = 'emotion',
	EFFORT = 'effort',
	NONE = 'none'
}

export enum SpineLaneType {
	TOPIC = 'TOPIC',
	DEFAULT = 'DEFAULT'
}

export enum SpineLaneStyle {
	icon = 'icon',
	emoticon = 'emoticon'
}

export interface SpineLane {
	enabled: boolean;
	definition: SpineLaneDefinition;
}

export interface SpineLaneDefinition {
	name: SingleLaneEnrichmentTypes | ParticipantEnrichmentTypes | string;
	type?: SpineLaneStyle;
	laneType?: SpineLaneType;
	icon?: string;
	bandColor?: string;
	customColor?: string;
	inclusionList?: string[];
}

export interface TopicSpineLaneDefinition extends SpineLaneDefinition {
	selectedLevel: number;
	selectedNodes: number[];
	inheritTopics: boolean;
	leaf: boolean;
}

export interface UISpineLane extends SpineLane {
	grouping?: AttributeGrouping;
}
