import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'show-more',
	template: `
<span class="show-more">
	<p [innerHTML]="visibleText"></p>
	<a class="ml-8" *ngIf="showButton" (click)="toggle($event)" [attr.aria-expanded]="!!showFull">{{getButtonText()}}</a>
</span>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreComponent implements OnInit {
	@Input() text?: string;
	@Input() size?: number;
	@Input() showFull: boolean;
	@Output() showMoreChange = new EventEmitter<any>();

	visibleText: string;
	showButton: boolean;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		if (!this.text)
			return;
		if (!this.size)
			this.size = 80;
		if (this.text.length < this.size) {
			this.showButton = false;
			this.showFull = true;
			this.visibleText = this.text;
		} else {
			this.showButton = true;
			this.visibleText = this.showFull ? this.text : (this.text.substring(0, this.size) + '...');
		}
	}

	toggle(evt: MouseEvent): void {
		evt.stopPropagation();
		this.showFull = !this.showFull;
		this.visibleText = this.showFull ? this.text : (this.text.substring(0, this.size) + '...');
		if (this.showMoreChange)
			this.showMoreChange.emit({showFull: this.showFull});
	}

	getButtonText(): string {
		return this.locale.getString(this.showFull ? 'common.showLess' : 'common.showMore');
	}

}

app.directive('showMore', downgradeComponent({component: ShowMoreComponent}) as angular.IDirectiveFactory);