import { StatisticalMetric } from './statistical-metric';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';

export class PValueMetric extends StatisticalMetric {
	constructor(
		private locale: ILocale,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
	) {
		super();
	}

	getType = (): PeriodOverPeriodMetricType => {
		return PeriodOverPeriodMetricType.P_VALUE;
	}
	getNamePrefix(): string {
		return 'pValue_';
	}
	getDisplayName = (parentMetricDisplayName: string): string => {
		return parentMetricDisplayName + ' ' + this.locale.getString('widget.pValue');
	}
	applyFormatting = (metric: ReportCalculation): ReportCalculation => {
		angular.extend(metric, this.defaultDataFormatterBuilder.pValueSettings);
		return metric;
	}
}

app.service('pValueMetric', PValueMetric);
