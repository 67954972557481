import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { IAccountSelection } from '@app/modules/account/account-selection.interface';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';
import { AssetPromisePlatformProject, AssetPromiseAnyProject } from '@app/modules/units/project-assets-helper.service';
import { DateRangeResolveContext } from '@app/modules/filter/entities/date-range-resolve-context';
import { CxHttpService } from '@app/core';
import { DateRange } from '@app/modules/filter/entities/date-range';

@Injectable({
	providedIn: 'root'
})
export default class FiltersApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxHttp: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.FILTERS);
	}

	getFiltersWithFolders = (
		project: IProjectSelection, includeDateFilters = false, cache = CacheOptions.CACHED, local = false
	): AssetPromiseAnyProject<IFilter[]> => {
		let params = new HttpParams();
		if (includeDateFilters) {
			params = params.append('includeDateFilters', 'true');
		}
		let config = { cache, local, params };
		let url = this.getBasePath(project) + '/filters';
		return this.cachedHttp.get(url, config) as AssetPromiseAnyProject<IFilter[]>;
	}

	getFiltersForAccount = (
		account: IAccountSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromiseAnyProject<IFilter[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBaseAccountPath(account) + '/filters';
		return this.cachedHttp.get(url, options) as AssetPromiseAnyProject<IFilter[]>;
	}

	getPlatformFilters = (
		project: IProjectSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<IPlatformFilter[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/platform-filters';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<IPlatformFilter[]>;
	}

	getStudioFilters = (
		project: IProjectSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromiseAnyProject<IFilter[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/studio-filters';
		return this.cachedHttp.get(url, config) as AssetPromiseAnyProject<IFilter[]>;
	}

	getStudioDateFilters = (
		project: IProjectSelection, daysLimit?: number, cache = CacheOptions.CACHED, local = false
	): Promise<IFilter[]> => {
		let params = new HttpParams();
		if (daysLimit) {
			params = params.append('daysLimit', daysLimit.toString());
		}
		const config = { cache, local, params };
		const url = this.getBasePath(project) + '/date-filters';
		return this.cachedHttp.get(url, config);
	}

	resolveDateRange = (
		project: IProjectSelection, resolveData: DateRangeResolveContext
	): Promise<DateRange> => {
		const url = this.getBasePath(project) + '/date-range/resolve';
		return this.cxHttp.post(url, resolveData);
	}

	private getBaseAccountPath = (account: IAccountSelection): string => {
		return `rest/resources/cp/${account.contentProviderId}/account/${account.accountId}`;
	}

	private getBasePath = (project: IProjectSelection): string => {
		return `rest/resources/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	}
}
