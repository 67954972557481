import { Injectable } from '@angular/core';
import { OrganizationEnrichmentService } from '@app/modules/hierarchy/enrichment/organization-enrichment.service';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ScorecardMetricsManagementService } from '@app/modules/scorecards/metrics/scorecard-metrics-management.service';
import { TemplatePlaceholderKey, TemplateResourceType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-key';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { PromiseUtils } from '@app/util/promise-utils';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CustomMathUtils } from '@app/modules/metric/definition/custom-math/editor/custom-math-utils.service';
import { Observable, Subject } from 'rxjs';
import { Metric } from '@app/modules/metric/entities/metric';
import { ProjectAccessApiService } from '@app/modules/project/access/project-access-api.service';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DriversService } from '@app/modules/drivers/services/drivers.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { CacheOptions } from '@cxstudio/common/cache-options';

export interface AssetTemplateLazyResources {
	models: () => Promise<IReportModel[]>;
	metrics: () => Promise<Metric[]>;
	attributes: () => Promise<IReportAttribute[]>;
	words: () => Promise<IReportAttribute[]>;
	scorecardMetrics: () => Promise<ScorecardMetric[]>;
	hierarchies: () => Promise<ReportableHierarchy[]>;
	hierarchyModels: () => Promise<IReportModel[]>;
	drivers: () => Promise<DriversItem[]>;
	loadingChange: Observable<Promise<any>>;
}


export interface AssetResolver {
	getAssetDisplayName(key: TemplatePlaceholderKey): Promise<string>;
}

@Injectable({
	providedIn: 'root'
})
export class AssetTemplateResourcesService {

	constructor(
		private attributesService: AttributesService,
		private metricsService: MetricsService,
		private modelsService: ModelsService,
		private scorecardMetricsManagementService: ScorecardMetricsManagementService,
		private organizationApiService: OrganizationApiService,
		private organizationEnrichmentService: OrganizationEnrichmentService,
		private projectAccessApi: ProjectAccessApiService,
		private driversService: DriversService,
	) { }

	getAssetResolver(project: WorkspaceProject): AssetResolver {
		let resources = this.getLazyResources(project);
		return {
			getAssetDisplayName: (key: TemplatePlaceholderKey) => this.getAssetDisplayName(key, resources),
		};
	}

	getLazyResources(project: WorkspaceProject): AssetTemplateLazyResources {
		let loadingChange = new Subject<Promise<any>>();
		let loadingWrapper = <T> (fn: () => Promise<T[]>) => {
			return PromiseUtils.cacheWrapper(() => {
				let promise = this.projectAccessApi.hasProjectAccess(project, CacheOptions.CACHED, true)
					.then(hasAccess => hasAccess ? fn() : [], () => []);
				loadingChange.next(promise);
				return promise;
			});
		};
		return {
			attributes: loadingWrapper(() => this.attributesService.getAttributes(project)),
			metrics: loadingWrapper(() => this.metricsService.getMetrics(project) as Promise<Metric[]>),
			models: loadingWrapper(() => this.modelsService.getModelsWithHierarchies(project)),
			words: loadingWrapper(() => this.attributesService.getWordAttributes(project)),
			scorecardMetrics: loadingWrapper(() => this.scorecardMetricsManagementService.getScorecardMetrics(project)),
			hierarchies: loadingWrapper(() => this.organizationEnrichmentService.getReportableHierarchies(project)),
			hierarchyModels: loadingWrapper(() => this.organizationApiService.getOrgHierarchyModels(project)),
			drivers: loadingWrapper(() => this.driversService.getReportableDrivers(project)),
			loadingChange,
		};
	}

	private getAssetDisplayName(key: TemplatePlaceholderKey, resources: AssetTemplateLazyResources): Promise<string> {
		switch (key.resourceType) {
			case TemplateResourceType.ATTRIBUTE: return resources.attributes()
				.then(attributes => _.find(attributes,
					attribute => attribute.name.toLowerCase() === key.assetIdentifier.toLowerCase())?.displayName);
			case TemplateResourceType.METRIC: return resources.metrics()
				.then(metrics => _.find(metrics, {id: Number(key.assetIdentifier)})?.displayName);
			case TemplateResourceType.MODEL: return resources.models()
				.then(models => _.find(models, {id: Number(key.assetIdentifier)})?.name);
			case TemplateResourceType.WORDS: return resources.words()
				.then(wordAttributes => _.find(wordAttributes, {name: key.assetIdentifier})?.displayName);
			case TemplateResourceType.SCORECARD_METRIC: return resources.scorecardMetrics()
				.then(scorecardMetrics => _.find(scorecardMetrics, {name: key.assetIdentifier})?.displayName);
			case TemplateResourceType.HIERARCHY: return resources.hierarchies()
				.then(hierarchies => _.find(hierarchies, {id: Number(key.assetIdentifier)})?.name);
			case TemplateResourceType.HIERARCHY_ENRICHMENT: return resources.hierarchies()
				.then(hierarchies => this.getHierarcyEnrichmentPlaceholderDisplayName(key, hierarchies));
			case TemplateResourceType.DRIVERS: return resources.drivers()
				.then(drivers => _.find(drivers, {id: Number(key.assetIdentifier)})?.displayName);
			default: return Promise.resolve(undefined);
		}
	}

	private getHierarcyEnrichmentPlaceholderDisplayName(key: TemplatePlaceholderKey, hierarchies: ReportableHierarchy[]): string {
		let keyParts = /(\d+)-(.*)/.exec(key.identifier);
		let hierarchyId = Number(key.assetIdentifier);
		let enrichmentName = keyParts[2];
		let hierarchyName = _.findWhere(hierarchies, {id: hierarchyId})?.name;
		return `${hierarchyName} ${CustomMathUtils.ORGANIZATION_HIERARCHY_FORMULA_DELIMETER} ${enrichmentName}`;

	}
}
