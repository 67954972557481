export enum QualtricsSource {
	CX = 'CX',
	EX = 'EX',
	BX = 'BX',
	PX = 'PX',
	DX = 'DX',
	CoreXM = 'CoreXM',
}

export interface QualtricsEmbedData {
	embedUrl: string;
	dashboardUrl: string;
	source: QualtricsSource;
}

export interface QualtricsUserInfo {
	userName: string;
	firstName: string;
	lastName: string;
	email: string;
	brandId: string;
	datacenter: string;
}
