import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'empty-content',
	template: `<i class="empty q-icon" [ngClass]="icon"></i>`,
	styles: [`
		.q-icon {font-size: 54px;}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyContentComponent {
	@HostBinding('class') class = 'w-100-percent h-100-percent d-flex align-center justify-center';
	@Input() icon: string;
}
