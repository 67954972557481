<div class="col-md-12 page-tab-content saml-tab-content" [ngBusy]="loadingPromise">
	<div class="d-flex justify-end">
		<div class="form-group">
			<button type="button" class="btn btn-primary" (click)="addClient()">
				{{'administration.oauthAddClient'|i18n}}
			</button>
		</div>
	</div>
	<div class="row col-sm-12">
		<formatted-table
			[rows]="clientDetailsList"
			[columns]="columns"
		></formatted-table>
	</div>
</div>
