import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import OAuthExternalProvider from '@app/modules/system-administration/oauth/oauth-external-provider';
import { OAuthExternalProvidersApiService } from '@app/modules/system-administration/oauth/service/oauth-external-providers-api-service';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { QualtricsApiProvider } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-provider';
import { QualtricsApiProvidersApiService } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-providers-api.service';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import {
	QualtricsIntegrationConfirmationDialogComponent
} from '@app/modules/system-administration/master-account/integration/qualtrics-integration/qualtrics-integration-confirmation-dialog.component';
import { debounceTime, distinctUntilChanged, switchMap, tap, first } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';

@Component({
	selector: 'qualtrics-integration',
	templateUrl: './qualtrics-integration.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class QualtricsIntegrationComponent extends SelfCleaningComponent implements OnInit {
	qualtricsIntegration: FormGroup;
	qualtricsApiIntegration: FormGroup;

	loadingPromise: Promise<void>;
	ssoExternalProviders: OAuthExternalProvider[];
	apiProviders: QualtricsApiProvider[];
	ticketingApplications: ExternalApplication[];

	onInputChanged: Subject<Event> = new Subject<Event>();
	private brandChecking: boolean;

	constructor(
		private fb: FormBuilder,
		readonly ref: ChangeDetectorRef,
		readonly formUtils: CxFormUtils,
		private readonly oauthExternalProvidersApiService: OAuthExternalProvidersApiService,
		private readonly qualtricsApiProvidersApiService: QualtricsApiProvidersApiService,
		private readonly externalApplicationsApiService: ExternalApplicationsApiService,
		private readonly cxDialogService: CxDialogService,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
	) {
		super();
		this.createGroup();
		this.addSubscription(this.qualtricsIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.aliasName, this.brandId, this.datacenter, this.loginUrl,
					this.ssoProviderName, this.userSyncEnabled, this.enforceXmAuthentication, this.ticketingEnabled);
				if (this.ticketingEnabled.value) {
					this.formUtils.enableFields(this.ticketingApplicationId);
				}
				this.handleFormEditAndTicket(this.qualtricsIntegration, this.isQualtricsApiIntegrationEnabled);
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.aliasName, this.brandId, this.datacenter, this.loginUrl,
					this.ssoProviderName, this.ticketingEnabled, this.userSyncEnabled, this.enforceXmAuthentication,
					this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
				if (this.didUserEditForm(this.qualtricsIntegration) && !this.isQualtricsApiIntegrationEnabled()) {
					this.disableLegalTicket();
				}
			}
		}));

		this.addSubscription(this.qualtricsApiIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.providerId);
				this.handleFormEditAndTicket(this.qualtricsApiIntegration, this.isQualtricsIntegrationEnabled);
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.providerId);
				if (this.didUserEditForm(this.qualtricsApiIntegration) && !this.isQualtricsIntegrationEnabled()) {
					this.disableLegalTicket();
				}
				this.qualtricsIntegration.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.ticketingEnabled.valueChanges.subscribe(value => {
			if (value) {
				this.formUtils.enableFields(this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.onInputChanged.pipe(
			tap(() => this.brandChecking = true),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap(event => this.validateBrandId((event.target as HTMLInputElement).value))
		).subscribe((exist) => this.existPostCheck(exist)));
	}

	existPostCheck(exist: boolean): void {
		if (exist) {
			this.brandId.setErrors({ brandIdExists: true });
		}
		this.brandChecking = false;
		this.ref.markForCheck();
	}

	ngOnInit(): void {
		this.reloadExternalProviders();
		this.reloadApiProviders();
		this.reloadTicketingApplications();
	}

	reloadExternalProviders = (): void => {
		this.oauthExternalProvidersApiService.getExternalQualtricsProviders()
			.then(providers => {
				this.ssoExternalProviders = providers;
				this.ref.markForCheck();
			});
	}

	reloadApiProviders = (): void => {
		this.qualtricsApiProvidersApiService.getApiProviders()
			.then(providers => {
				this.apiProviders = providers;
				this.ref.markForCheck();
			});
	}

	validateBrandId = (value: string): Observable<boolean> => {
		if (this.isBrandIdValid() && value.length !== 0) {
			return from(this.systemAdminApiService.isQualtricsBrandIdExisting(value)
				.then(response => response.data.exist));
		}
		return of(false);
	}

	isBrandIdValid = (): boolean => {
		return this.brandId?.pristine
			|| !this.brandId?.errors?.brandIdExists;
	}

	private reloadTicketingApplications = (): void => {
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.TICKETING)
			.then(applications => {
				this.ticketingApplications = applications;
				this.ref.markForCheck();
			});
	}

	private createGroup(): void {
		this.qualtricsIntegration = this.fb.group({
			enabled: [ false, [ Validators.required ]],
			aliasName: [{ value: '', disabled: true }, [ Validators.required ]],
			brandId: [{ value: '', disabled: true }, [ Validators.required ]],
			datacenter: [{ value: '', disabled: true }, [ Validators.required ]],
			loginUrl: [{ value: '', disabled: true }, [ Validators.required ]],
			authSettings: this.fb.group({
				ssoProviderName: [{ value: '', disabled: true }, [ Validators.required ]],
			}),
			userSyncEnabled: [ { value: false, disabled: true} ],
			enforceXmAuthenticationEnabled: [ { value: false, disabled: true} ],
			ticketingEnabled: [ { value: false, disabled: true } ],
			ticketingApplicationId: [{ value: null, disabled: true }, [ Validators.required ]],
			ticketLink: [ { value: '', disabled: true }, [ Validators.required ]]
		});
		this.qualtricsApiIntegration = this.fb.group({
			enabled: [ false, [ Validators.required ]],
			providerId: [{ value: '', disabled: true }, [ Validators.required ]],
		});
	}

	private handleFormEditAndTicket(form: FormGroup, otherFormCheckerFn: () => boolean): void {
		if (!this.didUserEditForm(form)) {
			this.formUtils.enableFields(this.integrationTicketLink);
		} else {
			if (!otherFormCheckerFn.call(this)) {
				this.enableLegalTicket(form);
			}
		}
	}

	private enableLegalTicket(form: FormGroup): void {
		this.cxDialogService.openDialog(QualtricsIntegrationConfirmationDialogComponent).result.then((result: string) => {
			this.qualtricsIntegration.patchValue( {
				ticketLink: result
			});
			this.formUtils.enableFields(this.integrationTicketLink);
		}).catch((fail: any) => {
			// cancel the action if the user clicks on cancel
			form.patchValue({
				enabled: false
			});
		});
	}

	private disableLegalTicket(): void {
		this.formUtils.disableFields(this.integrationTicketLink);
		this.qualtricsIntegration.patchValue({
			ticketLink: null
		});
	}

	private isQualtricsIntegrationEnabled(): boolean {
		return this.qualtricsIntegration.get('enabled').value;
	}

	private isQualtricsApiIntegrationEnabled(): boolean {
		return this.qualtricsApiIntegration.get('enabled').value;
	}

	private didUserEditForm(form: FormGroup): boolean {
		return form.get('enabled').dirty;
	}


	getApiGroup(): FormGroup {
		return this.qualtricsApiIntegration;
	}

	getGroup(): FormGroup {
		return this.qualtricsIntegration;
	}

	get enabled(): FormControl {
		return this.qualtricsIntegration.controls.enabled as FormControl;
	}
	get aliasName(): FormControl {
		return this.qualtricsIntegration.controls.aliasName as FormControl;
	}
	get brandId(): FormControl {
		return this.qualtricsIntegration.controls.brandId as FormControl;
	}
	get datacenter(): FormControl {
		return this.qualtricsIntegration.controls.datacenter as FormControl;
	}
	get loginUrl(): FormControl {
		return this.qualtricsIntegration.controls.loginUrl as FormControl;
	}
	get ticketingEnabled(): FormControl {
		return this.qualtricsIntegration.controls.ticketingEnabled as FormControl;
	}
	get userSyncEnabled(): FormControl {
		return this.qualtricsIntegration.controls.userSyncEnabled as FormControl;
	}
	get enforceXmAuthentication(): FormControl {
		return this.qualtricsIntegration.controls.enforceXmAuthenticationEnabled as FormControl;
	}
	get ticketingApplicationId(): FormControl {
		return this.qualtricsIntegration.controls.ticketingApplicationId as FormControl;
	}
	get ssoProviderName(): FormControl {
		return this.qualtricsIntegration.get('authSettings.ssoProviderName') as FormControl;
	}
	get providerId(): FormControl {
		return this.qualtricsApiIntegration.controls.providerId as FormControl;
	}
	get integrationTicketLink(): FormControl {
		return this.qualtricsIntegration.controls.ticketLink as FormControl;
	}
}
