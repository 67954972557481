import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AmplitudeToggleEvent } from '../dropdown-toggle-option/dropdown-toggle-option.component';
import { AmplitudeTrackedToggle } from '../amplitude-tracked-toggle.directive';
import { DropdownMenuGroupComponent } from '@app/modules/document-explorer/document-explorer-menu/dropdown-menu-group/dropdown-menu-group.component';

@Component({
	selector: 'dropdown-collapsible-toggle-option',
	template: `
	<li (click)="$event.stopPropagation()" class="d-flex">
		<button *ngIf="value"
			[attr.aria-label]="getCollapseExpandBtnAriaLabel()"
			[ngClass]="collapsed ? 'q-icon-angle-right' : 'q-icon-angle-bottom'"
			class="btn ph-8 w-32"
			(click)="toggleCollapse()"
			(keydown.enter)="toggleKeyCollapse($event)"
		></button>
		<labeled-toggle-switch class="flex-fill"
			[small]="small"
			[value]="value"
			(valueChange)="valueChange.emit($event)"
			[label]="label"
			[groupLabel]="groupLabel"
			[dropdownItem]="true"
			[disabled]="disabled"></labeled-toggle-switch>
	</li>
	<ul role="menu" *ngIf="value" [hidden]="collapsed" class="pl-24 m-0">
		<ng-content></ng-content>
	</ul>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownCollapsibleToggleOptionComponent extends AmplitudeTrackedToggle {

	@Input() value: boolean;
	@Output() valueChange = new EventEmitter<boolean>();
	@Input() label: string;
	@Input() groupLabel: string;
	@Input() disabled: boolean = false;
	@Input() small: boolean = false;
	@Input() amplitudeEvent?: AmplitudeToggleEvent;
	collapsed: boolean = false;

	constructor(
		private readonly locale: CxLocaleService,
		@Optional() protected readonly _parentComponent?: DropdownMenuGroupComponent
	) {
		super(_parentComponent);
	}

	toggleCollapse(): void {
		this.collapsed = !this.collapsed;
	}

	toggleKeyCollapse(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.preventDefault();
		this.toggleCollapse();
	}

	getCollapseExpandBtnAriaLabel(): string {
		return this.collapsed
			? this.locale.getString('preview.expandPanel', { panelName: this.label })
			: this.locale.getString('preview.collapsePanel', { panelName: this.label });
	}
}
