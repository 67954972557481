import { SentenceScorecardTopic, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';

export class TranscriptPiece {
	attributes: any;
	channel: string;
	chunks: any[];
	dScore: number;
	endTimestamp: number;
	id: number;
	isSameChannelAsLast: boolean;
	isOvertalk: boolean;
	text: string;
	timestamp: number;
	verbatimId: number;
	verbatimType: string;
	isSilence?: boolean;
	sentenceTopics: SentenceTopic[];
	scorecardTopics: {[scorecardId: number]: SentenceScorecardTopic[]};
	mergedSentences?: number[];
}