import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { Hideable } from '@cxstudio/common/entities/hideable.interface';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { MetricFormat } from './metric-format.class';
import { SharableAsset } from '@cxstudio/sharing/sharing-service.service';
import { Label } from '@cxstudio/dashboards/entity/label';
import { IManageableAsset } from '@cxstudio/asset-management/management/abstract-manage-asset.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export class Metric implements Hideable, SharableAsset, IManageableAsset {
	id: number;
	name: string;
	displayName: string;
	type: string;
	definition?: MetricDefinition;
	description?: string;
	masterAccountId: number;
	contentProviderId: number;
	accountId: number;
	projectId: number;
	projectName: string;
	workspaceProject: WorkspaceProject;
	ownerId: number;
	ownerName: string;
	//populated fields
	hide: boolean;
	//for metric management ui
	typeDisplayName?: string;
	permissionLevel: AssetPermission;
	parentId?: number;
	reshareAllowed?: boolean;
	format?: MetricFormat;
	useDefaultFormat?: boolean;
	selected?: boolean;
	disabled?: boolean;
	labels?: Label[];
}
