import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';

@Component({
	selector: 'playback-menu-item',
	template: `
<li class="dropdown-header font-bold text-base">{{'preview.playbackPrefs'|i18n}}</li>
<li>
	<a class="dropdown-item cursor-no-selection playback-options d-flex align-items-center justify-between kb-focusable"
		role="menuitem"
		[attr.aria-label]="getItemAriaLabel()"
		(click)=$event.stopPropagation()
		(keydown)="documentManager.conversationMethods.changePlaybackRateKb($event)"
		href="javascript:void(0)">
		<span>{{'preview.playbackSpeed'|i18n}}</span>
		<div class="text-default ml-8">
			<button class="btn btn-icon btn-xs p-0 no-border q-icon-triangle-left"
				type="button"
				(click)="documentManager.conversationMethods.decreasePlaybackRate($event)"
				[disabled]=!documentManager.conversationMethods.isRateDecreaseEnabled()
				tabindex="-1"
			></button>
			<span class="text-center playback-speed mh-4">{{preferences.settings.playbackRate|number:'1.2-2'}}</span>
			<button class="btn btn-icon btn-xs p-0 no-border q-icon-triangle-right"
				type="button"
				(click)="documentManager.conversationMethods.increasePlaybackRate($event)"
				[disabled]=!documentManager.conversationMethods.isRateIncreaseEnabled()
				tabindex="-1"
			></button>
		</div>
	</a>
</li>
<dropdown-toggle-option
	[(value)]="preferences.settings.skipSilence"
	(valueChange)="preferences.storeUpdates()"
	label="{{'preview.menuSkipSilences'|i18n}}"
	groupLabel="{{'preview.playbackPrefs'|i18n}}"
	[small]="true">
</dropdown-toggle-option>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaybackMenuItemComponent {

	@Input() preferences: DocViewPreferences;
	@Input() documentManager: IDocumentPreviewerControls;

	constructor(
		private readonly locale: CxLocaleService,
	) {}

	getItemAriaLabel(): string {
		return `${this.locale.getString('preview.playbackPrefs')}. ${this.locale.getString('preview.playbackSpeed')}`;
	}

}

app.directive('playbackMenuItem', downgradeComponent({component: PlaybackMenuItemComponent}) as angular.IDirectiveFactory);
