import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { EmbedApiService } from '@app/modules/embed/embed-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { EmbeddedWidget } from '@app/modules/embed/embedded-widget-container/embedded-widget';
import { IRouteParams } from '@app/shared/providers/route-params-provider';

@Component({
	selector: 'embedded-widget-container',
	templateUrl: './embedded-widget-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedWidgetContainerComponent implements OnInit {

	loading: Promise<any>;
	embeddedWidget: EmbeddedWidget;
	dashboardData: IDashboardData;
	errorMessage: string;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly embedApi: EmbedApiService,
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('$routeParams') private readonly $routeParams: IRouteParams,
		@Inject('dashboardPersonalizationProvider') private readonly dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		@Inject('DashboardHistory') private readonly DashboardHistory,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
	) { }

	ngOnInit(): void {
		this.dashboardData = {} as IDashboardData;
		this.reloadWidget();
	}

	reloadWidget(): void {
		delete this.embeddedWidget;
		delete this.errorMessage;
		this.loading = this.loadEmbedConfiguration()
			.then(() => this.processDashboardFilters())
			.then(() => this.initWidget())
			.finally(() => this.ref.markForCheck());
	}

	private loadEmbedConfiguration(): Promise<void> {
		return this.embedApi.getEmbeddedWidget(this.getDashboardId(), this.$routeParams.identifier).then(embeddedWidget => {
			this.embeddedWidget = embeddedWidget;
			this.dashboardData.dashboard = embeddedWidget.dashboard;
			if (!embeddedWidget.embedConfig?.enabled) {
				this.errorMessage = this.locale.getString('widget.embedWidgetDisabled');
				return Promise.reject();
			}
		});
	}

	private initWidget(): void {
		let widgets = [this.embeddedWidget.widget];
		this.currentWidgets.setWidgets(this.getContainerId(), widgets, this.dashboardData.dashboardHistory);
	}

	private getContainerId(): string {
		return this.dashboardData.dashboard.id + '';
	}

	private processDashboardFilters = (): Promise<void> => {
		let personalization = this.dashboardPersonalizationProvider.getInstance(this.dashboardData.dashboard, this.getContainerId());
		let dashboardHistory: IDashboardHistoryInstance = new this.DashboardHistory();
		dashboardHistory.init(this.dashboardData.dashboard);
		return personalization.init().then(() => {
			dashboardHistory.applyFilterUpdates(this.dashboardData.dashboard.appliedFiltersArray);
			dashboardHistory.setPersonalization(personalization);
			this.dashboardData.dashboardHistory = dashboardHistory;
		}, (errorStatus: HierarchyLoadStatus) => {
			if (errorStatus === HierarchyLoadStatus.DEACTIVATED) {
				this.errorMessage = this.locale.getString('widget.hierarchyDeactivated');
			} else if (errorStatus === HierarchyLoadStatus.NO_ACCESS) {
				this.errorMessage = this.locale.getString('widget.hierarchyNoAccess');
			}
			return Promise.resolve();
		});
	}

	forbiddenToRefresh(): boolean {
		return this.security.getContext().isEnterpriseUser;
	}

	isShowDashboardLink(): boolean {
		return this.embeddedWidget.embedConfig.showDashboardLink
			&& !this.security.getContext().isEnterpriseUser
			&& this.embeddedWidget.dashboard?.permissions.VIEW;
	}

	getDashboardLink(): string {
		return this.urlService.getDashboardUrl(this.getDashboardId());
	}

	private getDashboardId(): number {
		return Number(this.$routeParams.dashboardId);
	}

}
