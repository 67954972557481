import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'custom-templates'
})
export class CustomTemplatesUpgrade extends UpgradeComponent {
	constructor(elementRef: ElementRef, injector: Injector) {
		super('customTemplates', elementRef, injector);
	}
}
