import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';

export class FileUploadResult {
	uploadSuccess?: boolean;
	validationMessage: string;
	validationWarning: string;
	validationErrors: DetailedValidationError[];

	customUploadSuccessMessage: string;
	customUploadfailedMessage: string;

	static empty(): FileUploadResult {
		return new FileUploadResult();
	}

	static success(): FileUploadResult {
		let result = new FileUploadResult();
		result.uploadSuccess = true;
		return result;
	}

	static warning(message: string): FileUploadResult {
		let result = new FileUploadResult();
		result.uploadSuccess = true;
		result.validationWarning = message;
		return result;
	}

	static error(message: string): FileUploadResult {
		let result = new FileUploadResult();
		result.uploadSuccess = false;
		result.validationMessage = message;
		return result;
	}

	static failed(headers: {[key: string]: string}, response: any, locale: ILocale): FileUploadResult {
		let result = new FileUploadResult();
		result.parseErrorResponse(headers, response, locale);
		return result;
	}

	parseErrorResponse(headers: {[key: string]: string}, response: any, locale: ILocale): void {
		this.uploadSuccess = false;
		if (headers.eid) {
			let erroMessage = locale.getString('error.internal_error');
			let codeRef = locale.getString('error.codeReference', { rid: headers.eid });
			this.validationMessage = `${erroMessage} ${codeRef}`;
		} else if (this.isUnknownErrorResponse(response)) {
			this.validationMessage = locale.getString('common.contactAdmin');
		} else if (headers.error === 'true') {
			if (typeof response === 'string') {
				this.validationMessage = response;
			} else {
				this.validationErrors = _.sortBy(response, 'errorRow');
			}
		} else if (headers.warning === 'true') {
			this.validationWarning = response[0].errorMessage;
		}
	}

	private isUnknownErrorResponse(response): boolean {
		return typeof response === 'string' && response.indexOf('<!DOCTYPE') === 0;
	}
}
