import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';

@Directive({
	selector: 'context-menu-option'
})
export class ContextMenuOptionUpgrade extends UpgradeComponent {
	@Input() option: IDrillMenuOption;
	@Input() object: any;
	@Input() level: number;
	@Input() active: boolean;
	@Input() bounded: boolean;
	@Output() onSelect: EventEmitter<IDrillMenuOption>;
	@Output() onAction: EventEmitter<void>;
	@Output() onKeyAction: EventEmitter<void>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('contextMenuOption', elementRef, injector);
	}
}