<div class="d-flex flex-direction-column fill-container">
	<label class="mb-24">
		{{totalExpencesTitle}}
		<span class="font-bold ml-8">{{totalExpences | currency}}</span>
	</label>
	<div class="grid-list-tools" *ngIf="showSearchPanel() || allowExport">
		<div class="tools">
			<div class="grid-filters justify-between" *ngIf="showSearchPanel()">
				<label class="font-bold mb-0">
					{{searchLabel}}
					<input
						type="text"
						class="grid-search-bar d-block"
						attr.aria-label="{{searchLabel}}"
						[(ngModel)]="searchText"
						[debounce]="300">
				</label>
			</div>
			<ng-container *ngIf="allowExport">
				<button
					type="button"
					class="btn btn-secondary"
					(click)="exportData()"
					[i18n]="'administration.exportToExcel'">
				</button>
			</ng-container>
		</div>
	</div>

	<div class="flex-fill">
		<default-objects-table
			tableName="consumptionStats"
			[columnDefs]="columnDefs"
			[gridOptions]="gridOptions"
			[rowData]="consumptionStatistics"
			[withFolders]="false"
			[quickFilterText]="searchText"
		></default-objects-table>
	</div>
</div>
