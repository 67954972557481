import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ContentVisualizationBaseComponent } from '@app/modules/widget-visualizations/content-widgets/content-visualization-base';
import { MarginType, TextRotation } from '@cxstudio/reports/entities/content-widget-properties';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';

@Component({
	selector: 'content-text',
	template: `
		<div #outerContainer
			class="view-text font-x-small-scale kb-focusable-inset w-100-percent h-100-percent"
			[attr.tabindex]="empty ? -1 : 0"
			[class.newTextFormat]="isNewTextFormat()"
			[ngClass]="getPaddingClass()"
			[ngStyle]="getBorderStyles()"
			[style.background]="widget.properties.background">
			<ng-container *ngIf="isNewTextFormat(); else oldFormat">
				<div *ngIf="!!html"
					#textWrapper
					class="text-wrapper"
					[ngClass]="getWrapperClasses()"
					[style.overflow-y]="widget.properties.rotation ? 'hidden' : undefined">
					<div class="text-container" #textContainer [innerHTML]="html | sanitize | safeHTML"></div>
				</div>
			</ng-container>
			<ng-template #oldFormat>
				<div #oldTextContainer [innerHTML]="html | sanitize | safeHTML"></div>
			</ng-template>
		</div>
	`,
	styles: [`
		.view-text {
			overflow: auto;
			word-break: break-word;
			border-style: solid;
			border-width: 1px;
			border-radius: var(--widget-border-radius);
		}
		.view-text, .view-text.padding-normal { padding: 20px; }
		.view-text.padding-narrow {
			padding: 10px 20px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTextComponent extends ContentVisualizationBaseComponent implements OnInit, AfterViewInit {

	private readonly EMPTY_TEXT = '<p></p>';

	html: string;

	@ViewChild('outerContainer') outerContainer: ElementRef<HTMLElement>;
	@ViewChild('textWrapper') textWrapper: ElementRef<HTMLElement>;
	@ViewChild('textContainer') textContainer: ElementRef<HTMLElement>;
	@ViewChild('oldTextContainer') oldTextContainer: ElementRef<HTMLElement>;

	constructor(
		readonly ref: ChangeDetectorRef,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
		 private readonly textWidgetUtils: TextWidgetUtilsService,
	) {
		super(ref, reportUtils);
	}

	ngOnInit(): void {
		let props = this.widget.properties;
		if (!this.dataObject.text || this.dataObject.text === this.EMPTY_TEXT) {
			this.showEmpty();
		} else {
			if (!props.margins) props.margins = MarginType.NORMAL;
			this.html = this.dataObject.text;
		}
	}

	ngAfterViewInit(): void {
		if (this.isNewTextFormat()) {
			this.updateSize();
			this.initResizer(this.outerContainer, () => this.updateSize());
		} else {
			this.processLinks(this.oldTextContainer.nativeElement);
			this.textWidgetUtils.processFontSize(this.oldTextContainer.nativeElement);
		}
		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
	}

	isNewTextFormat(): boolean {
		return this.widget.properties.newTextFormat;
	}

	getPaddingClass(): string {
		let val = this.widget.properties.margins || MarginType.NORMAL;
		return `padding-${val}`;
	}

	getWrapperClasses(): string {
		let props = this.widget.properties;
		let rotationClass = props.rotation || '';
		let valignClass = props.valign || '';
		return `${rotationClass} ${valignClass}`;
	}

	getBorderStyles(): any {
		return this.textWidgetUtils.getBorderStyles(this.widget.properties);
	}

	private processLinks(element: HTMLElement): void { // transform links to open them in new tab
		$(element).find('a').attr('target', '_blank');
	}

	private updateSize(): void {
		let props = this.widget.properties;
		if (!props.newTextFormat || !props.rotation) return;
		let elemPadding = 40;
		let elem = $(this.outerContainer.nativeElement);
		let height = $(elem).innerHeight() - elemPadding;
		let wrapper = $(this.textWrapper.nativeElement);
		let container = $(this.textContainer.nativeElement);
		container.css('width', height + 'px');
		wrapper.css('height', height + 'px');
		if (props.rotation === TextRotation.DOWN || props.rotation === TextRotation.UP) {
			wrapper.css('width', container.height()); // container is rotated
		} else {
			wrapper.css('width', container.width());
		}
		if (props.rotation === TextRotation.DOWN) {
			$(elem).scrollLeft($(elem).get(0).scrollWidth);
		}
	}

}
