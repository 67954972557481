import { CxLocaleService } from '@app/core/cx-locale.service';
import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { Inject, Injectable } from '@angular/core';


export enum OAuthAuthorizedGrantType {
	AUTHORIZATION_CODE = 'authorization_code',
	CLIENT_CREDENTIALS = 'client_credentials',
	REFRESH_TOKEN = 'refresh_token',
	EXTERNAL_TOKEN = 'external_token',
	PASSWORD = 'password',
	IMPLICIT = 'implicit',
	MAGIC_LINK = 'magic_link'
}

export enum OAuthAuthorizedCustomGrantType {
	DESIGNER_PROXY = 'designer_proxy'
}

export interface IOAuthAuthorizedGrantType {
	type: OAuthAuthorizedGrantType | OAuthAuthorizedCustomGrantType;
	displayName: string;
}

@Injectable({
	providedIn: 'root'
})
export class OAuthAuthorizedGrantTypes {
	grantTypes: IOAuthAuthorizedGrantType[] = [];
	customGrantTypes: IOAuthAuthorizedGrantType[] = [];
	allGrantTypes: IOAuthAuthorizedGrantType[] = [];

	constructor(locale: CxLocaleService) {
		this.grantTypes = [{
			type: OAuthAuthorizedGrantType.AUTHORIZATION_CODE,
			displayName: locale.getString('administration.oauthAuthorizationCode')
		}, {
			type: OAuthAuthorizedGrantType.CLIENT_CREDENTIALS,
			displayName: locale.getString('administration.oauthClientCredentials')
		}, {
			type: OAuthAuthorizedGrantType.REFRESH_TOKEN,
			displayName: locale.getString('administration.oauthRefreshToken')
		}, {
			type: OAuthAuthorizedGrantType.EXTERNAL_TOKEN,
			displayName: locale.getString('administration.oauthExternalToken')
		}, {
			type: OAuthAuthorizedGrantType.PASSWORD,
			displayName: locale.getString('administration.oauthPassword')
		}, {
			type: OAuthAuthorizedGrantType.IMPLICIT,
			displayName: locale.getString('administration.oauthImplicit')
		}, {
			type: OAuthAuthorizedGrantType.MAGIC_LINK,
			displayName: locale.getString('administration.oauthMagicLink')
		}];

		this.customGrantTypes = [{
			type: OAuthAuthorizedCustomGrantType.DESIGNER_PROXY,
			displayName: locale.getString('administration.oauthDesignerProxy')
		}];

		this.allGrantTypes = this.getAllGrantTypes();
	}

	getGrantTypes = (): IOAuthAuthorizedGrantType[] => {
		return this.grantTypes;
	}

	getGrantType = (grantType: OAuthAuthorizedGrantType): IOAuthAuthorizedGrantType => {
		return _.findWhere(this.allGrantTypes, {type: grantType});
	}

	getAllGrantTypes = () => {
		let allGrantTypes = cloneDeep(this.grantTypes);
		allGrantTypes.pushAll(this.customGrantTypes);
		return allGrantTypes;
	}
}
