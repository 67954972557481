<div class="pv-16 overflow-hidden bg-gray-200 border-gray-900 border-0 border-b-1 border-solid"
	[ngBusy]="promiseLoading">
	<div class="br-image-url col-sm-7">
		<label for="webImage" class="mandatory">{{'widget.fromTheWeb'|i18n}}</label>
		<input
			class="w-100-percent"
			id="webImage"
			placeholder="http://"
			[(ngModel)]="properties.imageUrl"
			(ngChange)="properties.imageName=''">
	</div>

	<div class="br-image-upload col-sm-5 v-middle-wrapper">
		<div class="v-middle">
			<div>
				<label for="uploadImage" class="mandatory" [i18n]="'widget.orUploadImage'"></label>
			</div>
			<button id="uploadImage"
				class="btn br-upload-button-wrapper btn-secondary"
				(click)="fileInput.click()">
				<span>{{'widget.chooseFile'|i18n}}</span>
				<input name="fileInput"
					class="br-upload-file"
					type="file"
					ng2FileSelect
					[uploader]="fileUploader"
					#fileInput
					filters="img"/>
			</button>
			<span class="ml-8">(.gif, .jpg, .jpeg, .png {{'common.or' | i18n}} .svg)</span>
		</div>
	</div>
</div>
<div class="br-image-list d-flex flex-wrap overflow-y-auto"
	ng2FileDrop
	[uploader]="fileUploader">
	<div *ngIf="loadingItem"
		class="br-image-item image-progress-bar btn btn-default m-16 p-16 overflow-hidden p-relative hover-parent p-relative d-flex flex-direction-column justify-between">
		<ngb-progressbar
			class="br-image-progress mb-16"
			[max]="100"
			[value]="loadingItem.progress">
		</ngb-progressbar>
		<div class="p-4 w-100-percent overflow-hidden text-ellipsis nowrap"
			 data-testid="image-name"
			title="{{loadingItem.name}}">
			<strong>{{loadingItem.name}}</strong>
		</div>
	</div>

	<div *ngFor="let image of images"
		class="br-image-item btn btn-default border-transparent-soft action-hover-border m-16 p-16 overflow-hidden p-relative hover-parent p-relative d-flex flex-direction-column justify-between"
		[ngClass]="{'selected bg-action-200 border-action-400': (image === properties.imageName)}"
		(click)="selectImage(image)">
			<img class="border-gray-600 border-1 border-solid" [attr.alt]="image" [src]="getImageUrl(image)">
			<div class="p-4 w-100-percent overflow-hidden text-ellipsis nowrap"
				 data-testid="image-name"
				title="{{image}}">
				<strong>{{image}}</strong>
			</div>
			<a class="br-image-remove q-icon-delete show-on-parent-hover"
				(click)="removeImage(image); $event.stopPropagation();">
			</a>
	</div>
</div>
