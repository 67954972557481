
import { Component, OnInit, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { CxLocaleService } from '@app/core';
import { RequestAccessService, RequestAccessReason, RequestAccessResult } from '@app/modules/access-management/services/request-access-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';

@Component({
	selector: 'grant-access-page',
	template: `<div class="w-100-percent h-100-percent" [ngBusy]="loading"></div>`
})
export class GrantAccessPageComponent implements OnInit {
	@Input() objectType: ObjectType;
	@Input() objectId: number;
	@Input() userId: number;

	loading: Promise<any>;

	constructor(
		@Inject('redirectService') private redirectService: RedirectService,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private requestAccessService: RequestAccessService,
	) {}

	ngOnInit(): void {
		let objectType = this.objectType;
		let objectId = this.objectId;
		let userId = this.userId;
		if (objectType && objectId) {
			this.loading = this.requestAccessService.handleGrantAccess(objectType, objectId, userId)
			.then((result: RequestAccessResult) => {
				if (result === RequestAccessResult.redirectToPreview) {
					return;
				} else {
					this.redirectToHome();
				}
			}, (reason) => {
				switch (reason) {
					case RequestAccessReason.alreadyShared: return this.processAlreadyShared();
					default: return this.showError(reason);
				}
			});
		} else {
			this.redirectToHome();
		}
	}

	private showError = (reason: string) => {
		let objectType = this.objectType;
		this.cxDialogService.warning(this.requestAccessService.getDialogTitle(objectType),
			reason,
			{keyboard: true}
		).result.then(this.redirectToHome);
	}

	private redirectToHome = () => {
		this.redirectService.goToDashboardList();
	}

	protected processAlreadyShared(): void {
		let objectType = this.objectType;
		this.cxDialogService.notify(this.requestAccessService.getDialogTitle(objectType),
			this.locale.getString('sharing.objectShared'),
			{keyboard: true}
		).result.then(this.redirectToHome);
	}

}

app.directive('grantAccessPage', downgradeComponent({component: GrantAccessPageComponent}) as angular.IDirectiveFactory);
