interface IModalCallbackResult {
	$value;
}

export abstract class ModalBindings<T> implements ng.IController {
	resolve: T;
	close: (result?: IModalCallbackResult) => void;
	dismiss: (result?: IModalCallbackResult) => void;

	abstract $onInit(): void;
}