<ng-template #spellCheckResults let-templateData>
	<spell-check-results [results]="templateData"></spell-check-results>
</ng-template>

<div class="spot-check-header p-16 border-t-radius-2">
	<cb-modal-close [label]="'dashboardSpotCheck.closeSpotCheck' | i18n" (click)="onClose()" (keydown.enter)="onCloseViaKeyboard($event)"></cb-modal-close>
	<h2 class="modal-title font-semibold text-1_25rem">{{'dashboardSpotCheck.spotCheckDialogTitle' | i18n}}</h2>
	<div class="pt-16" [innerHTML]="summaryMessage | safeHTML"></div>
</div>
<div class="spot-check-body mt-16 border-b-1 border-b-solid" [ngBusy]="loading">
	<div class="d-flex spot-check-results">
		<div class="w-180 flex-shrink-0 ml-16 mr-32">
			<div>
				<jump-links
					[statusCounts]="statusCounts"
					(selectionChange)="scrollToSection($event)"
				></jump-links>
			</div>
			<div class="mt-24">
				<label class="font-semibold" [i18n]="'dashboardSpotCheck.filterByTopic'"></label>
				<pills-selector
					[unselected]="'dashboardSpotCheck.allTypes' | i18n"
					[options]="topics"
					(selectionChange)="onTopicsChange($event)"
				></pills-selector>
			</div>
		</div>
		<div class="pr-16 h-100-percent overflow-y-auto flex-fill">
			<div *ngFor="let resultType of ['ISSUE', 'TIP', 'PASSED', 'IGNORED']"
				id="spot-check-{{resultType.toLowerCase()}}-results">
				<spot-check-result-item *ngFor="let resultItem of displayResults[resultType]"
					[result]="resultItem"
					(ignoredChange)="toggleIgnored($event)"
				></spot-check-result-item>
			</div>
		</div>
	</div>
</div>
