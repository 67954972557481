import * as _ from 'underscore';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';

export class FeedbackSelectionComponent implements ng.IComponentController {

	feedbackSelector: IAnalyticFeedbackSelection;
	cancel: () => void;

	constructor(private $scope: ISimpleScope) {}

	$onInit = () => {
		// eventually hope to move the cancel fn here and make it self-contained
		this.$scope.$on('dashboard:cancel', this.cancel);
	}

	clear = (): void => {
		this.feedbackSelector.clearSelection();
		this.$scope.$broadcast('feedbackSelection:editFinished');
	}
}

app.component('feedbackSelection', {
	bindings: {
		feedbackSelector: '<',
		cancel: '&',
		save: '&'
	},
	controller: FeedbackSelectionComponent,
	template: `
<div class="ml-24 d-flex align-items-center">
	<p class="mb-0 mr-24 no-wrap">{{$ctrl.feedbackSelector.getLabel()}}</p>

	<div class="form-group form-inline mb-0 mr-24">
		<label class="font-bold">{{::'preview.excludeFeedbackSelection'|i18n}}</label>
		<cb-toggle
			class="excluding-sentences-toggle"
			ng-model="$ctrl.feedbackSelector.processedResult.excluding"
			ng-change="$ctrl.feedbackSelector.applyVisualChanges()">
		</cb-toggle>
	</div>

	<button class="btn btn-sm btn-secondary br-clear-feedback-selection gridster-no-drag"
		ng-click="$ctrl.clear()">
		{{::'preview.clearAll'|i18n}}
	</button>

	<button class="btn btn-sm btn-primary br-submit-feedback-selection gridster-no-drag ml-32"
		ng-click="$ctrl.save()">
		{{::'common.done'|i18n}}
	</button>

	<button class="btn btn-sm btn-secondary br-cancel-feedback-selection gridster-no-drag"
		ng-click="$ctrl.cancel()">
		{{::'common.cancel'|i18n}}
	</button>
</div>`
});
