import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';


@Component({
	selector: 'table-menu',
	templateUrl: './table-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent {
	@Input() selectedCount: number;
	@Input() menuItems: Array<ContextMenuItem<any>>;
	@Output() onCancelSelection = new EventEmitter<void>();

	activeItem: ContextMenuItem<any>;

	constructor(private locale: CxLocaleService) {}

	getSelectedItemsLabel = (): string => {
		return this.locale.getString('common.numberOfItemsSelected', { count: this.selectedCount });
	}

	getCancelLabel = (): string => {
		return this.locale.getString('common.cancel');
	}

	toggleItem = (menuItem: ContextMenuItem<any>) => {
		if (this.activeItem === menuItem) {
			this.closeMenu();
		} else {
			this.activeItem = menuItem;
		}
	}

	closeMenu = (): void => {
		this.activeItem = undefined;
	}
}

app.directive('tableMenu', downgradeComponent({component: TableMenuComponent}) as angular.IDirectiveFactory);
