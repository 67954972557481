import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter,
	Inject, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FilterRuleTypes, FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule, IDateRuleDefinition, IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { SearchedAttribute } from '@cxstudio/dashboards/attribute-value-searcher.service';
import { DashboardFilterMultiValue } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-multi-value';
import { FilterCreatorService } from '@cxstudio/report-filters/filter-creator.service';
import { ITreeSelection, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { UIOption } from '@clarabridge/unified-angular-components';
import { PresetDateFiltersState, FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { IFilterMatchMode } from '@cxstudio/report-filters/constants/filter-match-modes.service';

@Component({
	selector: 'filter-rule',
	templateUrl: './filter-rule.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class FilterRuleComponent implements OnInit, OnChanges {
	@Input() filterableFields: INode[];
	@Input() allowDateRange: boolean;
	@Input() rule: IFilterRule;
	@Input() projectSelection: AccountOrWorkspaceProject;
	@Input() opened: boolean;
	@Input() appendToBody: boolean;
	@Input() pinned: boolean;
	@Input() filterFieldsRefresh: number; //temporary till builder converted
	@Input() displayVertical = false;

	@Output() ruleChange = new EventEmitter<IFilterRule>();
	@Output() selectedAttributeChange = new EventEmitter<SearchedAttribute>();

	filterFieldOptions: INode[];
	dateOptions: UIOption<string>[];

	selectedAttribute: SearchedAttribute;
	selection: {multiValues: DashboardFilterMultiValue[]};	//attributes
	modelTree: ITreeSelection; //topics
	tags: string[]; // admin project

	// methods from filterCreatorService bound to controller
	matchModeOptions;
	matchMatchMode;
	showMatchMode: (rule: IFilterRule) => boolean;
	showModelSelect: (rule: IFilterRule) => boolean;
	showMultiselect: (rule: IFilterRule) => boolean;
	isAdminProjectAttribute: (rule: IFilterRule) => boolean;
	isExistMatch: (rule: IFilterRule) => boolean;

	dateFiltersPromise: Promise<void>;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('metricConstants') private metricConstants: MetricConstants,
		private filterRuleService: FilterRuleService,
		private attributesService: AttributesService,
		@Inject('filterCreatorService') private filterCreatorService: FilterCreatorService,
		@Inject('dateFilterService') private dateFilterService: DateFilterService,
		@Inject('filterManagementApiService') private filterManagementApiService: FilterManagementApiService,
		private readonly filtersService: FiltersService,
		@Inject('datePeriodUtils') private datePeriodUtils: DatePeriodUtils,
		@Inject('DateRange') private DateRange,
	) { }

	ngOnInit(): void {
		this.matchModeOptions = this.filterCreatorService.getMatchModeOptions;
		this.matchMatchMode = this.filterCreatorService.getAppliedMatchMode;
		this.showMatchMode = this.filterCreatorService.showMatchMode;
		this.showModelSelect = this.filterCreatorService.showModelSelect;
		this.showMultiselect = (rule: IFilterRule) => {
			return this.filterCreatorService.showMultiselect(rule, this.projectSelection?.projectId)
				&& !this.filterCreatorService.isAdminProjectAttribute(rule, this.projectSelection?.projectId);
		};
		this.isAdminProjectAttribute = (rule: IFilterRule) => this.filterCreatorService.isAdminProjectAttribute(rule,
			this.projectSelection?.projectId);
		this.isExistMatch = (rule: IFilterRule) => this.filterCreatorService.isExistMatch(rule);

		this.populateMultiselection(this.rule);
		this.populateTags();
		this.populateFilterFieldOptions();

		this.reloadDateOptions();

		if (this.rule.attributeName) {
			this.selectedAttribute = SearchableHierarchyUtils.deepSearchByName(this.filterableFields, this.rule.attributeName);
		}

		this.convertModelsForUI();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filterFieldsRefresh?.previousValue !== changes.filterFieldsRefresh?.currentValue
				&& !changes.filterFieldsRefresh?.isFirstChange()) {
			this.populateFilterFieldOptions();
		}
	}

	/**
	 * Convert data to one supported by tree selection component
	 */
	 private convertModelsForUI = (): void => {
		if (!this.showModelSelect(this.rule)) return;

		this.rule.values.forEach((model: IFilterRuleValue, index: number, nodes: any[]) => {
			model.modelId = this.rule.topicId;
			model.id = Number.parseInt(model.idPath.split('/').last(), 10);
			if (!model.path && model.text) {
				// widgets store text field
				model.path = model.text;
				model.name = model.text.split(' > ').last();
			}
			// pare down to just the properties we need
			nodes[index] = _.pick(model, ['modelId', 'path', 'name', 'id', 'idPath']);
		});

		this.updateModelTree();
	}

	private updateModelTree = (): void => {
		if (!this.showModelSelect(this.rule)) return;
		this.modelTree = {
			strategy: (this.rule.values.length > 0) ? TreeSelectionStrategy.SUBSET : TreeSelectionStrategy.NONE,
			nodes: this.rule.values
		};
	}

	selectModelValue = (treeSelection: ITreeSelection) => {
		this.rule.values = treeSelection.nodes;
		this.updateModelTree();
		this.emitRuleChange();
	}

	private populateMultiselection = (rule: IFilterRule) => {
		if (FilterRuleTypes.isAttributeRule(rule)) {
			this.selection = this.selection || {} as any;
			_.extend(this.selection, {
				multiValues: (rule.values || []).map((value) => {
					return { name: value.text, displayName: value.text, selected: true };
				})
			});
		}
	}

	populateTags = (): void => {
		if (this.isAdminProjectAttribute(this.rule)) {
			this.tags = this.rule.values.map(val => val.text);
		}
	}

	updateRuleFromTags = (tags: string[]): void => {
		this.rule.values = tags.map(tag => ({text: tag}));
		this.emitRuleChange();
	}

	private populateFilterFieldOptions = () => {
		this.filterFieldOptions = this.filterableFields;
		if (!this.allowDateRange) {
			this.filterFieldOptions = this.filterOptions(this.filterableFields);
		}
	}

	private filterOptions(fieldOptions: INode[]): INode[] {
		let filteredResult = [];
		for (let option  of fieldOptions) {
			if (FilterRuleTypes.isDocumentDateRule(option as unknown as IFilterRule) ) {
				if (this.rule.type === FilterRuleType.dateRange) {
					filteredResult.push(option as unknown as IFilterRule);
				}
			} else {
				filteredResult.push(option as unknown as IFilterRule);
			}
		}
		return filteredResult as unknown as INode[];
	}


	populateMultiselectionValues = (): void => {
		this.rule.values = (this.selection.multiValues || []).map((value) => ({text: value.name}));
		this.emitRuleChange();
	}

	showTextInput = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.isTextWithInput(rule);
	}

	showNumericInput = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.numericOpenRange && !rule.existMatch;
	}

	showNumericRange = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.numericRange && !rule.existMatch;
	}

	getPattern = (rule: IFilterRule) => {
		return this.metricConstants.isIntNumberAttribute(rule.attributeName) ? '^-?\\d*' : '';
	}

	showDateRange = (rule: IFilterRule): rule is IDateRuleDefinition => {
		return rule.type === FilterRuleType.dateRange;
	}

	isDocumentDateRange = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.isDocumentDateRule(rule);
	}

	isCustomDateRange = (rule: IDateRuleDefinition): boolean => {
		// document date filters don't have mode
		return !rule.dateFilterMode || rule.dateFilterMode === this.DateRange.options.CUSTOM.value;
	}

	getTimezone = (): string => {
		let dateOption = _.find(this.filterableFields, {type: FilterRuleType.dateRange});
		return dateOption && (dateOption as any).timezone;
	}

	isEsQuery = (rule: IFilterRule): boolean => {
		return this.metricConstants.isESQueryAttribute(rule.name);
	}

	getHelp = (): string => {
		let item = this.matchItem();
		return item?.help;
	}

	selectItem = (item: IFilterRule | any): void => {
		if (item.children || (item.name && item.name === this.getMatchName(this.rule))) {
			return;
		}

		this.selectedAttribute = item;

		let newRule: IFilterRule = cloneDeep(this.filterRuleService.generateNewRule(item, this.rule));
		newRule.values = (newRule.modelMatch || newRule.existMatch) ? newRule.values : [];
		newRule.value = FilterRuleTypes.isPredefinedRule(newRule) ? newRule.value : undefined;
		newRule.from = undefined;
		newRule.to = undefined;
		newRule.isThreshold = this.rule.isThreshold;

		_.extend(this.rule, newRule);
		this.populateMultiselection(this.rule);
		this.updateModelTree();
		this.reloadDateOptions();
		this.ref.detectChanges();
		this.selectedAttributeChange.emit(this.selectedAttribute);
		this.emitRuleChange();
	}

	selectMatchMode = (mode: IFilterMatchMode, priorRule: IFilterRule): void => {
		this.filterCreatorService.onMatchModeSelection(mode, priorRule);
		this.populateMultiselection(priorRule);
		this.updateModelTree();
		this.emitRuleChange();
	}

	private getMatchName = (rule: IFilterRule): string => {
		if (FilterRuleTypes.isTopicRule(rule)) {
			return rule.topicId?.toString();
		} else if (FilterRuleTypes.isAttributeRule(rule)) {
			return rule.attributeName;
		} else if (FilterRuleTypes.isDocumentDateRule(rule)) {
			return rule.name || OptionsConstant.DATE_RANGE;
		} else if (FilterRuleTypes.isSavedFilterRule(rule)) {
			return rule.name;
		} else if (FilterRuleTypes.isDateRangeRule(rule)) {
			return rule.name;
		} else if (FilterRuleTypes.isText(rule)) {
			return rule.name || FilterRuleType.text;
		}
	}

	matchItem = (): IFilterRule | undefined => {
		if (this.rule.type === FilterRuleType.empty && !this.rule.displayName) return;
		if (!this.filterableFields) return this.rule;
		let name = this.getMatchName(this.rule);
		if (!name) {
			return this.rule;
		}
		return SearchableHierarchyUtils.deepSearchByName(this.filterableFields, name) || this.rule;
	}

	attributeTagsFilterQuery = (rule: IFilterRule, query: string): Promise<string[]> => {
		let params = {
			project: this.projectSelection,
			attributeNames: [rule.attributeName],
			isNumeric: FilterRuleTypes.isNumericAttributeRule(rule),
			filter: query
		};
		return this.attributesService.getAttributeValues(params);
	}

	loadTags = (query: string): Promise<string[]> => {
		if (FilterRuleTypes.isAttributeRule(this.rule)) { // we still need this for studio admin project
			return this.attributeTagsFilterQuery(this.rule, query);
		}
		return Promise.resolve([]);
	}

	private reloadDateOptions(): void {
		if (!FilterRuleTypes.isDateRangeRule(this.rule))
			return;
		let docDateRule = FilterRuleTypes.isDocumentDateAttributeRule(this.rule);
		let presetFiltersPromise: Promise<PresetDateFiltersState> =
			this.filterManagementApiService.getPresetDateFiltersStates() as unknown as Promise<PresetDateFiltersState>;
		let studioFiltersPromise: Promise<any[]> = Promise.resolve([]);
		if (docDateRule) {
			studioFiltersPromise = this.filtersService.getStudioDateFilters(this.projectSelection);
		}

		this.dateFiltersPromise = Promise.all([presetFiltersPromise, studioFiltersPromise]).then(results => {
			let presetFilterStates = results[0];
			let hourOptions = [this.DateRange.options.LAST_4_HOURS.value, this.DateRange.options.LAST_24_HOURS.value];
			let presetOptions = _.filter(this.dateFilterService.getDateFilterOptions(), option => {
				let visible = presetFilterStates[option.id] !== false;
				if (!docDateRule)
					visible = visible && !_.contains(hourOptions, option.value);
				if (!docDateRule)
					visible = visible && option.value !== this.DateRange.options.MISS_DATE.value;
				return visible;
			});

			let customOption = _.findWhere(presetOptions, {value: this.DateRange.options.CUSTOM.value});
			presetOptions.remove(customOption);
			this.dateOptions = [customOption];
			this.dateOptions.pushAll(presetOptions);
			this.dateOptions.pushAll(this.datePeriodUtils.processDateFilters(results[1]));
		});
	}

	emitRuleChange(): void {
		this.ruleChange.emit(this.rule);
	}
}

app.directive('filterRule', downgradeComponent({component: FilterRuleComponent}));
