<div class="mb-8" *ngIf="properties.useHistoricPeriod">
	<input
		type="text"
		class="w-100-percent br-period-label"
		[attr.aria-label]="'widget.customName'|i18n"
		placeholder="{{'widget.customName'|i18n}}"
		maxlength="30"
		[(ngModel)]="visualProperties.periodLabel[period.name]"
		(ngModelChange)="selectionChange.emit()">
</div>

<div>
	<select *ngIf="isInheritingDashboardDateFilter()" disabled class="dropdown chicklet option-chicklet w-100-percent" [attr.aria-label]="'reportFilters.inherited'|i18n">
		<option selected>{{'reportFilters.inherited'|i18n}}</option>
	</select>
	<date-filters
		*ngIf="!isInheritingDashboardDateFilter()"
		[ngDisabled]="disableDateFilters"
		[autoClose]="true"
		[fitParentWidth]="true"
		[dateFilterMode]="period.dateFilterMode"
		[dateFilterRange]="period.dateFilterRange"
		[timezone]="projectTimezone"
		[options]="periodOptions || {}"
		(onFilterChange)="onFilterChange($event)"
		(onNodeSelection)="selectionChange.emit()"
		[dateOptionsFilter]="periodOptionsFilter"
		[historicOptions]="historicOptions"
		[selectorProperties]="selectorProperties || {}"
		[currentPeriodForHistoric]="currentPeriodForHistoric">
	</date-filters>
</div>
