import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'availablePlaceholders'
})
export class AvailablePlaceholdersPipe implements PipeTransform {

	transform(items: any[]): any[] {
		return items.filter((item: any) => !(item.hidden || item.system));
	}

}
