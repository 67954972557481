<div class="ph-16">
	<label class="mb-16"><b>{{'common.findFeature'|i18n}}</b>
		<input
		type="text"
		class="grid-search-bar mt-4 mb-0 d-block"
		attr.aria-label="{{'common.find'|i18n}}"
		[(ngModel)]="betaSearch">
	</label>
	<collapsing-panel class="_t-beta-feature-panel" *ngFor="let group of featureGroups">
		<panel-heading><strong>{{group.title}}</strong></panel-heading>
		<panel-body>
			<item-grid
				class="br-grid beta-features-grid"
				[treeData]="group.rows"
				[gridOptions]="gridOptions"
				[gridFilter]="betaSearch"
				[gridType]="gridType"
				[gridChange]="changedItems"
				[uiOptions]="{autoHeight: true, rowHeight: 50}">
			</item-grid>
		</panel-body>
	</collapsing-panel>
</div>
