import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

@Component({
	selector: 'number-format-preview',
	template: `
		<div class="rounded-grouping">
			<div class="rounded-grouping-label font-bold">
				{{'widget.preview'|i18n}}
			</div>
			<div class="ph-8 pv-4 bg-pewter-200">
				<simple-number-format-preview
					[format]="format"
					[previewValue]="previewValue"
					[defaultFormat]="defaultFormat">
				</simple-number-format-preview>
			</div>
		</div>
	`
})
export class NumberFormatPreviewComponent {
	readonly FALLBACK_DEMO_NUMBER = 1000654.321;

	@Input() format: NumberFormatSettings;
	@Input() previewValue: number | string; // should be a number but we'll convert it if necessary
	@Input() defaultFormat: NumberFormatSettings;
}

app.directive('numberFormatPreview', downgradeComponent({component: NumberFormatPreviewComponent}));
