<div class="an-document-explorer document-preview no-background d-flex flex-direction-column overflow-hidden h-100-percent w-100-percent border-radius-8"
	role="dialog"
	(keydown)="processExplorerKeydown($event)">
	<ng-container *ngTemplateOutlet="header,context:{$implicit:{menu: menu, filters: filtersDropdown, title: title}}"></ng-container>
	<ng-template #filtersDropdown>
		<filter-applied-dropdown *ngIf="!!appliedFilters"
			class="mr-8"
			[appliedFilters]="appliedFilters"
			[textFilter]="widget.filterUi?.textFilter"
			[isDocExplorer]="true"
			[customAriaLabel]="'preview.filterMenuAriaLabel' | i18n:{title: getDocumentExplorerName()}">
		</filter-applied-dropdown>
	</ng-template>
	<ng-template #title>
		<span class="modal-title">{{widget.documentExplorerName}}</span>
	</ng-template>
	<ng-template #menu>
		<document-explorer-menu-button
			class="flex-fixed"
			[documentExplorerName]="getDocumentExplorerName()"
			[auditMode]="auditMode"
			[preferences]="preferences"
			[documentManager]="documentManager"
			[sharingFeedbackSelection]="sharingFeedbackSelection"
			[panels]="panels"
			[exportDataEnabled]="showExportButton()">
		</document-explorer-menu-button>
	</ng-template>

	<div class="flex-fill d-flex flex-direction-column overflow-hidden" [ngBusy]="creatingNewDashboard">
		<div class="p-relative flex-fixed"><!-- container for ngBusy -->
			<document-explorer-toolbar [ngBusy]="loadingStatistics"
				[sentimentFilters]="sentimentFilters"
				[easeScoreFilters]="easeScoreFilters"
				[sortBy]="filters.sortBy"
				[sortDirection]="sortDirection"
				(toggleSort)="toggleSort($event)"
				[widget]="widget"
				[sentimentValue]="filters.additionalFilter.sentiment"
				[easeScoreValue]="filters.additionalFilter.easeScore"
				(toggleFilter)="togglePredefinedFilter($event.type, $event.value)"
				[(auditMode)]="auditMode"
				(auditModeChange)="handleTuningSuggestionsModeChange($event)"
				[(rebuttalMode)]="rebuttalMode"
				(rebuttalModeChange)="handleIntelligentScoringModeChange($event)"
				(searchQueryChange)="searchDocuments($event)"
				[searchQuery]="searchValue"
				[isFullPage]="isFullPage"
				(fullPageClick)="goFullPage()">
			</document-explorer-toolbar>
		</div>

		<div class="document-preview-container flex-fill overflow-hidden">
			<widget-document-preview
				[isDocExplorer]="true"
				[widget]="widget"
				[filters]="filters"
				[showSentiment]="preferences.settings.showSentiment"
				[showSentences]="preferences.settings.visiblePanes.sentence"
				[showContext]="preferences.settings.visiblePanes.context"
				[showEmptyAttributes]="preferences.settings.showEmptyAttributes"
				[documentManager]="documentManager"
				[initDocumentManager]="initDocumentManager"
				[selectedScorecard]="selectedScorecard"
				[preferences]="preferences"
				[panels]="panels"
				[sharingFeedbackSelection]="sharingFeedbackSelection"
				[reloadStatistics]="reloadStatistics"
				[createDashboardCallback]="createDashboardFromCurrentSelection"
				[auditMode]="auditMode"
				[rebuttalMode]="rebuttalMode"
				[isStatsEnabled]="isStatsEnabled"
				[showExportData]="showExportButton">
			</widget-document-preview>
		</div>
	</div>
</div>
