import { Component, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter, HostListener, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

@Component({
	selector: 'book-dashboard-header',
	templateUrl: './book-dashboard-header.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class BookDashboardHeaderComponent {
	@Input() containerId: string;
	@Input() dashboard: Dashboard;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() personalization: PersonalizationState;
	@Input() shadow: boolean;

	@Output() editDashboard = new EventEmitter<Dashboard>();

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.ref.detectChanges();
	}

	constructor(
		private locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		@Inject('dashboardService') private dashboardService: DashboardService,
	) { }

	getContainerWidth = () => {
		let dashboardContainer = $(`#container-${this.containerId} #dsh-widget-container`)[0];
		return dashboardContainer && dashboardContainer.clientWidth;
	}

	canEditDashboard = () => {
		return this.dashboardService.canEditDashboard(this.dashboard);
	}

	editDashboardKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.editDashboard.emit(this.dashboard);
		}
	}

	getEditDashboardLabel = () => {
		return this.locale.getString('dashboard.editAriaLabel', { name: this.dashboard.name });
	}

	isFrontlineDashboard = (): boolean => {
		return FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard);
	}
}

app.directive('bookDashboardHeader', downgradeComponent({ component: BookDashboardHeaderComponent }));
