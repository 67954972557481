import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
	ChangeDetectorRef, 
	Inject} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Errors } from '@cxstudio/common/errors';
import { ProjectSelectorData } from '@app/shared/components/project-selector/project-selector-data';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { UserWorkspacesService } from '@app/modules/units/workspace-project-selector/user-workspaces.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { MobileAppDialogTab } from '@cxstudio/mobile/mobile-app-dialog-tab.enum';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import { CxLocaleService } from '@app/core';

export interface MobileWorkspaceSelectorData {
	hierarchyId: number;
	workspaceProject: WorkspaceProject;
	projectSelection?: IProjectSelection; // required for backwards compatibility
}

@Component({
	selector: 'mobile-workspace-selector',
	templateUrl: './mobile-workspace-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileWorkspaceSelectorComponent implements OnInit {
	@Input() settings: MobileAppSettings;
	@Output() selectorDataChange = new EventEmitter<MobileWorkspaceSelectorData>();

	@Input() errors: Errors;
	@Output() errorsChange = new EventEmitter<Errors>();
	@Output() loading = new EventEmitter<Promise<void>>();

	workspace: ProjectSelectorData<Unit>;
	project: ProjectSelectorData<Project>;
	hierarchy: ProjectSelectorData<Hierarchy>;

	preselectedHierarchy: Hierarchy;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private userWorkspacesService: UserWorkspacesService,
		private organizationApiService: OrganizationApiService,
	) { }

	ngOnInit(): void {
		this.workspace = new ProjectSelectorData('id', this.settings.workspaceProject?.workspaceId);
		this.workspace.onChange.subscribe(this.onWorkspaceChange);

		this.project = new ProjectSelectorData('id', this.settings.workspaceProject?.projectId);
		this.project.onChange.subscribe(this.onProjectChange);

		this.hierarchy = new ProjectSelectorData('id', this.settings.hierarchyId);
		this.hierarchy.onChange.subscribe(this.onHierarchyChange);

		this.loading.emit(this.loadWorkspaces());

		this.errors.addStateListener(() => this.ref.detectChanges());
	}

	onSelectorDataChange(): void {
		let selectedWorkspace = this.workspace.getSelectedObject();

		this.selectorDataChange.emit({
			workspaceProject: {
				workspaceId: this.workspace.getSelection(),
				projectId: this.project.getSelection()
			},
			projectSelection: {
				contentProviderId: selectedWorkspace.contentProviderId,
				accountId: selectedWorkspace.accountId,
				projectId: this.project.getSelection()
			},
			hierarchyId: this.hierarchy.getSelection()
		});
	}

	onWorkspaceChange = (): void => {
		this.project.clear();
		this.onSelectorDataChange();
		if (this.workspace.isSelected()) {
			this.loading.emit(this.loadProjects());
		}
	}
	
	onProjectChange = (): void => {
		this.onSelectorDataChange();
		if (this.project.isSelected() && !this.hierarchy.hasOptions()) {
			this.loading.emit(this.loadHierarchies());
		}
	}

	onHierarchyChange = (): void => {
		this.onSelectorDataChange();
		this.setInactiveHierarchyError(false);
	}

	private loadWorkspaces(): Promise<void> {
		return this.userWorkspacesService.getUserWorkspaces()
			.then(units => {
				units.forEach(unit => unit.name = unit.name || '');
				this.workspace.setOptions(units);
				this.verifySelections();
				if (this.workspace.hasOptions()) {
					if (this.workspace.isSelected()) {
						return this.loadProjects();
					}
				} else {
					this.project.clear();
				}
				this.ref.detectChanges();
			}, error => {
				this.workspace.clear();
				this.ref.detectChanges();
			});
	}

	private loadProjects(): Promise<void> {
		return this.userWorkspacesService.getWorkspaceProjects(this.workspace.getSelection())
			.then(workspaceProjects => {
				this.project.setOptions(workspaceProjects);
				this.verifySelections();
				if (this.project.hasOptions()) {
					if (this.project.isSelected() && !this.hierarchy.hasOptions()) {
						return this.loadHierarchies();
					}
				}
				this.ref.detectChanges();
			}, error => {
				this.project.clear();
				this.ref.detectChanges();
			});
	}

	private loadHierarchies(): Promise<void> {
		return this.organizationApiService.getOrganizationList()
			.then(response => {
				let hierarchies = response.data;
				let initialHierarchyId = this.settings.hierarchyId;

				this.hierarchy.setOptions(hierarchies);

				if (initialHierarchyId > 0 && !this.hierarchy.isSelected()) {
					this.organizationApiService.getOrgHierarchyIgnoreErrors(initialHierarchyId)
						.then((hierarchyResponse) => {
							this.preselectedHierarchy = {
								id: initialHierarchyId,
								name: this.locale.getString('common.disabledOrgHierarchy', {
									name: hierarchyResponse.data.name
								})
							};
							this.ref.detectChanges();
						}, () => {
							this.preselectedHierarchy = {
								id: initialHierarchyId,
								name: this.locale.getString('common.deletedOrgHierarchy')
							};
							this.ref.detectChanges();
						});

					this.setInactiveHierarchyError(true);
				}

				this.ref.detectChanges();
			}, error => {
				this.hierarchy.clear();
				this.ref.detectChanges();
			});
	}

	private setInactiveHierarchyError(value: boolean): void {
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'inactive-hierarchy-error', !value);
		this.errorsChange.emit(this.errors);
	}

	private verifySelections(): void {
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'select-workspace', this.workspace.isSelected());
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'select-project', this.project.isSelected());
		this.errorsChange.emit(this.errors);
	}
}

app.directive('mobileWorkspaceSelector', downgradeComponent({component: MobileWorkspaceSelectorComponent}));