export enum UpgradeState {
	WAITING = 'WAITING',
	RUNNING = 'RUNNING',
	FAIL = 'FAIL',
	SUCCESS = 'SUCCESS',
	REJECTED = 'REJECTED',
	SKIPPED = 'SKIPPED',
	INTERRUPTED = 'INTERRUPTED'
}

export interface UpgradeComponentState {
	sessionId: number;
	componentId: string;
	progress: number;
	state: UpgradeState;
	version: string;
	message: string;
	owner: string;
}

export interface UpgradeSession {
	id: number;
	creationDate: string; //date
	performer: string;
	state: UpgradeState;
	performedAutomatically: boolean;
	clusterNodeId: string;
	upgradeComponentStates: UpgradeComponentState[];
}
