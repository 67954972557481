import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class SystemExpressionItem extends ExpressionItem {
	name: string;

	constructor(
		name: string,
		displayName: string
	) {
		super(ExpressionPieces.SYSTEM_METRIC, displayName);
		this.name = name;
	}
}