export enum DefaultBrandingColors {

	// keep all the colors 6 digits hex or adjust
	// this.colorUtils.isValidHexCode otherwise
	COLOR_1 = '#444444',
	COLOR_3 = '#ffffff',
	COLOR_4 = '#0768DD',
	COLOR_10 = '#ebebeb',

	// deprecated
	COLOR_2 = '#ffffff',
	COLOR_5 = '#ebebee',
	COLOR_6 = '#babbc5',
	COLOR_7 = '#d3d4da',
	COLOR_8 = '#d3d4da',
	COLOR_9 = '#43444f',
	COLOR_11 = '#e3e6e7'
}
