import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PageProperties } from '@cxstudio/reports/entities/page-properties';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { PreviewVisualProperties } from '@cxstudio/reports/entities/preview-visual-properties';
import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import { SortMetric } from '@cxstudio/reports/entities/sort-metric';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { PreviewSortAttributes } from '../entities/preview-sort-attributes';

export class AnalyticPreviewDefaultsInitializer {

	PAGES_PER_CALL = 5;

	constructor(
		private previewPredefinedColumns: PreviewPredefinedColumns) {
		}

	initializeWidget(properties: PreviewWidgetProperties, visualProperties: PreviewVisualProperties): void {
		properties.selectedAttributes = properties.selectedAttributes || [];
		properties.previewMode = properties.previewMode || PreviewMode.SENTENCES;
		properties.itemsPerPage = properties.itemsPerPage || 20;
		visualProperties.itemsPerPage = visualProperties.itemsPerPage || 20;
		properties.page = properties.page || new PageProperties(0, visualProperties.itemsPerPage * this.PAGES_PER_CALL);
		properties.page.sortMetric = properties.page.sortMetric || new SortMetric(PreviewSortAttributes.DOC_DATE, AnSortDirection.DESC);
		properties.selectedModels = properties.selectedModels || [];
		properties.favoriteAttributes = properties.favoriteAttributes || [];

		visualProperties.bubbleColumns = visualProperties.bubbleColumns || 3;
		visualProperties.visualization = visualProperties.visualization || WidgetVisualization.PREVIEW_BUBBLES;

		visualProperties.sentencePaneEnabled = _.isUndefined(visualProperties.sentencePaneEnabled)
			? false
			: visualProperties.sentencePaneEnabled;
		visualProperties.contextPaneEnabled = _.isUndefined(visualProperties.contextPaneEnabled)
			? false
			: visualProperties.contextPaneEnabled;
		visualProperties.sections = visualProperties.sections || {};

		if (_.isUndefined(visualProperties.sentimentHighlightingEnabled))
			visualProperties.sentimentHighlightingEnabled = true;
	}

	addDefaultColumns(visualProperties: PreviewVisualProperties, singleVerbatimMode: boolean): void {
		visualProperties.columns = [
			this.createColumn(this.previewPredefinedColumns.get(PreviewColumn.SENTENCE, singleVerbatimMode))
		];
	}

	addColumns(visualProperties: PreviewVisualProperties, selectedAttributes: any[]): void {
		if (!selectedAttributes) {
			return;
		}
		selectedAttributes.forEach(selectedAttr => {
			let column = {
				name: selectedAttr.name,
				displayName: selectedAttr.displayName,
				type: selectedAttr.type
			};
			visualProperties.columns.push(column);
		});
	}

	private createColumn(item: AttributeGrouping): TableColumn<PreviewSentence> {
		return {
			name: item.name,
			type: item.type,
			displayName: item.displayName
		};
	}

}
app.service('analyticPreviewDefaultsInitializer', AnalyticPreviewDefaultsInitializer);
