import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { StudioAlertsListController } from '@cxstudio/alerts/studio-alerts-list.component';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';

@Injectable({
	providedIn: 'root'
})
export default class StudioAlertGridDefinition implements IGridDefinition<StudioAlertsListController> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter
	) {}

	init = (_gridMode: GridMode, controller: StudioAlertsListController): Promise<IGridColumn[]> => {

		let columns = [{
			id: 'checkbox',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-checkbox text-center',
			name: '<span></span>',
			cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.SelectedRowFormatter,
			resizable: false
		}, {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: true,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.getMetricAlertToggleSwitchFormatter(controller.isEnabledAlertLimitReached),
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.STUDIO_ALERT),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'ownerName',
			name: this.locale.getString('common.owner'),
			field: 'ownerName',
			sortable: true,
			optional: true,
			minWidth: 80,
			width: 230,
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'modifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'sharedStatus',
			name: this.locale.getString('dashboard.status'),
			field: 'status',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 120,
			formatter: this.gridFormatterService.SimpleStatusFormatter,
			cssClass: 'cell-status text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'unsubscribedCount',
			name: this.locale.getString('alert.optOuts'),
			field: 'unsubscribedCount',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'text-right pr-16 cursor-pointer',
			headerCssClass: 'text-right'
		}, {
			id: 'notificationsCount',
			name: this.locale.getString('alert.notificationsThisMonth'),
			field: 'notificationsCount',
			sortable: true,
			optional: true,
			minWidth: 80,
			width: 200,
			cssClass: 'text-right pr-16',
			headerCssClass: 'text-right'
		}, {
			id: 'mostRecentNotificationDate',
			name: this.locale.getString('alert.mostRecentAlert'),
			field: 'mostRecentNotificationDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}];

		return Promise.resolve(columns);
	}

	getMetricAlertToggleSwitchFormatter = (checkMaxEnabled: (ownerName: string) => boolean): IRowFormatter => {
		return (_row, _cell, value, _columnDef, dataContext: StudioAlert) => {
			const disabled = checkMaxEnabled(dataContext.ownerName) && !value;
			const checked = value;
			const disabledMsg = !disabled ? '' :
				this.locale.getString('alert.maximumAlertsEnabled');
			const getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
			return getSwitch(checked, disabled, disabledMsg);
		};
	}
}

app.service('studioAlertGridDefinition', downgradeInjectable(StudioAlertGridDefinition));
