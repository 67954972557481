import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Input, HostBinding } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { EmbedApiService } from '@app/modules/embed/embed-api.service';
import { EmbeddedDashboard } from '@app/modules/embed/embedded-dashboard-container/embedded-dashboard';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DashboardExportEvent } from '@app/core/cx-event.enum';
import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';

@Component({
	selector: 'embedded-dashboard-container',
	templateUrl: './embedded-dashboard-container.component.html',
	styles: [`
		:host ::ng-deep .br-home-gridster {
			top: 48px;
		}

		:host(.external-embed) ::ng-deep  .br-home-gridster {
			bottom: 24px;
			top: 80px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedDashboardContainerComponent implements OnInit {
	loading: PromiseLike<any>;
	loaded: boolean;

	containerId: string;
	embeddedDashboard: EmbeddedDashboard;
	dashboardHistory: IDashboardHistoryInstance;
	personalization: PersonalizationState;

	@Input() isEmbeddedInternally: boolean;
	@HostBinding('class.external-embed') isExternalEmbed: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly embedApi: EmbedApiService,
		private readonly locale: CxLocaleService,
		private eventBus: GlobalEventBus,
		private cxDialogService: CxDialogService,
		@Inject('$routeParams') private readonly $routeParams: IRouteParams,
		@Inject('DashboardHistory') private readonly DashboardHistory,
		@Inject('dashboardPersonalizationProvider') private dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		@Inject('errorDialogService') private errorDialogService: ErrorDialogService,
		@Inject('dashboardExportService') private dashboardExportService: DashboardExportService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		private readonly toastService: ToastService,
	) { }

	ngOnInit(): void {
		this.containerId = `${this.getDashboardId()}_${0}`;
		this.isExternalEmbed = !this.isEmbeddedInternally;

		this.loading = this.embedApi.getEmbeddedDashboard(this.getDashboardId()).then(embeddedData => {
			this.embeddedDashboard = embeddedData;

			if (FrontlineDashboardUtils.isFrontlineDashboard(embeddedData.dashboard)) {
				if (embeddedData.dashboardSnapshot) {
					const dashboard = this.embeddedDashboard.dashboard;
					FrontlineDashboardUtils.mergeDashboardSnapshot(dashboard, embeddedData.dashboardSnapshot);
				} else {
					this.toastService.addToast(
						this.locale.getString('dashboard.dashboardNoPublishedReport'),
						AlertLevel.WARNING
					);
				}
			}

			this.dashboardHistory = new this.DashboardHistory();
			this.dashboardHistory.init(this.embeddedDashboard.dashboard);
			this.personalization = this.dashboardPersonalizationProvider.getInstance(embeddedData.dashboard, this.containerId);

			return this.personalization.init()
				.then(this.postPersonalizationInit, this.postPersonalizationInit);
		});
	}

	private getDashboardId(): number {
		return Number(this.$routeParams.dashboardId);
	}

	private postPersonalizationInit = (status: HierarchyLoadStatus) => {
		this.loaded = true;
		if (HierarchyLoadStatus.DEACTIVATED === status) {
			this.errorDialogService.error(this.locale.getString('widget.hierarchyDeactivated'));
		} else if (HierarchyLoadStatus.NO_ACCESS === status) {
			this.errorDialogService.error(this.locale.getString('widget.hierarchyNoAccess'));
		}
		this.dashboardHistory.setPersonalization(this.personalization);

		this.initEventHandlers();
		this.ref.markForCheck();
	}

	private initEventHandlers(): void {
		this.eventBus.subscribe(DashboardExportEvent.PDF_EMAIL, () => {
			this.dashboardExportService.exportPDF(
				this.embeddedDashboard.dashboard, this.embeddedDashboard.widgets, this.getDashboardViewOptions());
		});

		this.eventBus.subscribe(DashboardExportEvent.PDF_DOWNLOAD, () => {
			let exportConfig = this.dashboardExportService.getExportConfig(this.embeddedDashboard.widgets);
			let hasIntersection = this.dashboardExportService.checkWidgetsForIntersection(
				exportConfig, this.embeddedDashboard.widgets);

			let performExport = () => {
				this.loading = PromiseUtils.wrap(this.dashboardExportService.exportPDFDownload(
					this.embeddedDashboard.dashboard, this.embeddedDashboard.widgets, this.getDashboardViewOptions()));
			};
			if (!hasIntersection) {
				performExport();
				return;
			}

			let confirmation = this.cxDialogService.regularWithConfirm(this.locale.getString('dashboard.exportData'),
				this.locale.getString('dashboard.hasIntersectionWithPageBreak'),
				this.locale.getString('common.continue'), this.locale.getString('common.cancel'));

			return confirmation.result.then(performExport);
		});

		this.eventBus.subscribe(DashboardExportEvent.XLS, () => {
			let options = {
				allWidgets: true,
				hasChanges: this.dashboardHistory.isViewFilterChanged()
			};
			this.dashboardExportService.exportDashboard(this.embeddedDashboard.dashboard.name,
				this.currentWidgets.getDashboardContainer(this.containerId),
				null, options);
		});
	}

	private getDashboardViewOptions(): any {
		return _.extend({
			personalization: this.personalization,
			dashboardHistory: this.dashboardHistory,
		}, this.currentWidgets.getExportParams(this.containerId));
	}


}
