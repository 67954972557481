import {ProjectAccessLevel} from './project-access-level-class';

//WORKSPACE could be deleted after GA
export class ProjectAccessLevelItems {

	MANAGER: ProjectAccessLevel = {
		value: 'MANAGER',
		level: 1
	};
	VIEWER: ProjectAccessLevel = {
		value: 'VIEWER',
		level: 2
	};
	CUSTOM: ProjectAccessLevel = {
		value: 'CUSTOM',
		level: 3
	};
	NONE: ProjectAccessLevel = {
		value: 'NONE',
		level: 4
	};
	NO_CHANGE: ProjectAccessLevel = {
		value: 'NO_CHANGE',
		level: 5
	};

	constructor(private locale) {
		this.MANAGER.label = this.locale.getString('contentProvider.adminRole');
		this.VIEWER.label = this.locale.getString('contentProvider.readOnlyRole');
		this.CUSTOM.label = this.locale.getString('contentProvider.customRole');
		this.NONE.label = this.locale.getString('contentProvider.noAccessRole');
		this.NO_CHANGE.label = this.locale.getString('contentProvider.noChange');
	}

	values(): ProjectAccessLevel[] {
		return [ this.MANAGER, this.VIEWER, this.CUSTOM, this.NO_CHANGE, this.NONE];
	}

	valuesAsMap(): {[value: string]: ProjectAccessLevel} {
		let map: {[value: string]: ProjectAccessLevel} = {};
		this.values().forEach((item: ProjectAccessLevel) => {
			map[item.value] = item;
		});
		return map;
	}

	findByValue(value: string): ProjectAccessLevel {
		let matchedValue = this.values().filter((option) => option.value === value);
		return matchedValue.length ? matchedValue[0] : undefined;
	}
}

app.service('ProjectAccessLevels', ProjectAccessLevelItems);
