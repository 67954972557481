<div class="d-flex mb-8">
	<simple-dropdown
		class="flex-fill dropdown-selector _t-comparison-type"
		[options]="options"
		[value]="comparison.type"
		[noMinWidth]="true"
		(onChange)="onComparisonChanged($event)"
		displayField="displayName"
		valueField="type"
		[appendToBody]=true>
	</simple-dropdown>
	<button class="btn btn-icon no-border color-inherit ml-8 cb-comparison-config"
		*ngIf="comparison"
		(click)="openComparisonConfig()" title="{{'widget.cog'|i18n}}">
		<span class="q-icon q-icon-cog" [attr.aria-label]="'widget.cog'|i18n"></span>
	</button>
	<ng-content></ng-content>
</div>
<date-filters *ngIf="comparison.type === 'TIME'"
	[dateFilterMode]="comparison.value.dateFilterMode"
	[dateFilterRange]="comparison.value"
	[historicOptions]="true"
	[options]="periodOptions"
	[timezone]="projectTimezone"
	[selectorProperties]="periodOptions.historic"
	[autoClose]="true"
	[appendToBody]="true"
	[currentPeriodForHistoric]="primaryDateFilterMode"
	(onFilterChange)="selectComparisonDateFilter($event.dateFilterMode, $event.dateFilterRange)"
	(onNodeSelection)="onChange()">
</date-filters>
<div *ngIf="comparison.type === 'GOAL'">
	<label class="ml-8" for="goal-value">{{'widget.customValue'|i18n}}</label>
	<input
		id="goal-value"
		type="number"
		class="w-100-percent"
		placeholder="{{'widget.customValue'|i18n}}"
		maxlength="30"
		[(ngModel)]="comparison.value.goal"
		[debounce]="500"
		(debouncedChange)="onChange()">
</div>
<div *ngIf="comparison.type === 'HIERARCHY_ENRICHMENT'">
	<search-list [dropdown]="true"
		[displayField]="'displayName'"
		[data]="comparison.value"
		[listOptions]="hierarchyEnrichments"
		[searchLabel]="'widget.searchHierarchyEnrichmentProperties' | i18n"
		(onNodeSelection)="selectComparisonEnrichment($event)"
		[appendToBody]="true">
	</search-list>
</div>
