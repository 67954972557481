<div class="wrong-topic-reporting form-group pt-8">
	<label class="font-bold">{{'mobile.selectReportRecipients'|i18n}}</label>
	<div class="d-flex align-items-center">
		<input
			type="text"
			class="flex-fill no-ms-clear"
			attr.aria-label="{{'dashboard.inviteMessage'|i18n}}"
			autocomplete="off"
			[(ngModel)]="search"
			[ngbTypeahead]="userSuggestions"
			[editable]="false"
			(selectItem)="addRecipient($event.item)">
		<span *ngIf="searchingUsers" class="ml-4 loading-spinner q-icon q-icon-spinner rotate-infinite"></span>
	</div>
	<table class="recipients-table">
		<tbody>
			<tr *ngFor="let recipient of recipients">
				<td>{{recipient}}</td>
				<td class="remove-recipient-icon">
					<a class="btn-icon"
						tabindex="0"
						attr.aria-label="{{'common.remove'|i18n}}"
						(keydown.enter)="removeRecipient(recipient)"
						(click)="removeRecipient(recipient)">
						<span class="q-icon q-icon-delete"></span>
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
