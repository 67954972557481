<modal-header (keydown.escape)="closeModal($event)"
	(cancel)="cancel()"
	[modalTitle]="'packages.runPackage' | i18n">
</modal-header>
<div class="modal-body" [ngBusy]="loading">
	<div class="form-group">
		<div class="form-group">
			<label for="nameInput" class="d-block mb-4">{{'packages.nameLabel'|i18n}}
			</label>
			<input class="d-block w-100-percent"
					id='nameInput'
					required
					[(ngModel)]="entry.name"/>
		</div>
		<div *ngIf="!isWorkspaceEnabled">
			<project-selector
				class="d-flex l-margin-offset-5"
				horizontal="true"
				[projectSelection]="entry.projectProperties"
				(projectSelectionChange)="onProjectSelectionChange($event)"
				(errorsChange)="onCpErrorsChange($event)"
				(loading)="onProjectsLoading($event)"
				[clear]="clearDefaultsSubject.asObservable()"
			></project-selector>
			<alert type="danger" *ngFor="let cperror of showErrorsForCP">
				<span>{{ cperror }}</span>
			</alert>
		</div>
		<workspace-project-selector
			*ngIf="isWorkspaceEnabled"
			[(value)]="entry.workspaceProject"
			labelPosition="left"
			[help]="true"
			(workspaceChange)="onWorkspaceChange($event)"
			(valueChange)="onProjectChange($event)"
			(loading)="onProjectsLoading($event)"
		></workspace-project-selector>
		<div class="form-group d-flex" *ngIf="showColorSelector()">
			<label class="control-label col-sm-3 help-label">
				{{'dashboard.colorPalette'|i18n}}
				<cb-inline-help right>
					<help-body>{{'dashboard.paletteHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<color-selector class="col-sm-9"
				[visualProps]="input.dashboard.properties"
				[filter]="['palette', 'provider', 'solid']"
				[palettes]="entry.colorPalette"
				[providerColors]="entry.providerColors"></color-selector>
		</div>
		<div class="form-group d-flex">
			<label for="defaultShowTotal" class="control-label col-sm-3 help-label">
				n=
				<cb-inline-help right>
					<help-body>
					{{'dashboard.showTotalHelp'|i18n}}
					</help-body>
				</cb-inline-help>
			</label>
			<input type="checkbox"
				id="defaultShowTotal"
				name="requireSpecialChars"
				class="mt-0"
				[(ngModel)]="entry.defaultShowTotal">
		</div>
	</div>
</div>
<save-modal-footer
	[saveText]="'common.run'|i18n"
	(save)="save()"
	[isDisabled]="hasNoSelection()"
	(cancel)="cancel()">
</save-modal-footer>
