import { Inject, Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';

@Injectable({ providedIn: 'root' })
export class ColorPalettesApi {
	readonly BASE_URL = 'rest/color-palettes';

	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly http: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		@Inject('treeService') private readonly treeService: TreeService
	) {
		this.cachedHttp = this.cxCachedHttpService.cache(Caches.PALETTES);
	}

	getWidgetPalettes = (): Promise<WidgetColorPalette[]> => {
		return this.cachedHttp.get(`${this.BASE_URL}/widget-palettes`, { cache: CacheOptions.CACHED });
	}

	getPalettes = (): Promise<ColorPalette[]> => {
		return this.http.get(`${this.BASE_URL}`);
	}

	createPalette = (palette: ColorPalette): Promise<ColorPalette> => {
		return this.http
			.post(`${this.BASE_URL}`, JSON.stringify(this.treeService.copyItem(palette)))
			.then((response: ColorPalette): ColorPalette => {
				this.cachedHttp.invalidate();

				return response;
			});
	}

	replacePalette = (paletteName: string, replaceWith: string): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteName}/replace/${replaceWith}`)
			.then((): void => this.cachedHttp.invalidate());
	}

	deleteAndReplacePalette = (paletteName: string, replaceWith: string): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteName}/delete-and-replace/${replaceWith}`)
			.then((): void => this.cachedHttp.invalidate());
	}

	updatePalette = (paletteData: ColorPalette): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteData.id}`, JSON.stringify(this.treeService.copyItem(paletteData)))
			.then((): void => this.cachedHttp.invalidate());
	}

	enablePalette = (paletteName: string): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteName}/enable`)
			.then((): void => this.cachedHttp.invalidate());
	}

	disablePalette = (paletteName: string): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteName}/disable`)
			.then((): void => this.cachedHttp.invalidate());
	}

	makeDefault = (paletteName: string): Promise<void> => {
		return this.http
			.put(`${this.BASE_URL}/${paletteName}/make-default`)
			.then((): void => this.cachedHttp.invalidate());
	}
}

app.service('colorPalettesApi', downgradeInjectable(ColorPalettesApi));
