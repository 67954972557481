import { ConversationDocument } from './conversation-document.class';
import { ConversationSentence } from './conversation-sentence.class';

export interface ElementsRange {
	startIndex: number;
	endIndex: number;
}

export interface UIElementsRange extends ElementsRange {
	offsetTop: number;
	offsetBottom: number;
}

export interface HiddenSentenceUtil {
	getHiddenParticipantClasses: () => string[];
	isSentenceHidden: (hiddenParticipantClasses: string[], sentence) => boolean;
}

export class ConversationPartialRendering {

	private readonly PARTIAL_RENDERING_SCROLL_THRESHOLD = 300;
	private readonly PARTIAL_RENDERING_MIN_CHUNK = 10;
	// render that much sentences per that much pixels
	readonly PARTIAL_RENDERING_CHUNK_PER_UNIT = 5;
	readonly PARTIAL_RENDERING_CHUNK_UNIT_VIEWSIZE = 150;

	readonly FAKE_SCROLL_HEIGHT_PER_SENTENCE = 80;
	readonly FAKE_SCROLL_MAX_HEIGHT = 10000;

	private renderedRange: ElementsRange;
	private visibleRange: UIElementsRange;

	private scrollLockSentenceId: number;
	private scrollLock: ng.IPromise<void>;

	private showingFakeScroll: boolean;
	private autoFakeScrollOffset: number;

	private highlightTarget: ConversationSentence;

	constructor(
		private $log: ng.ILogService,
		private $timeout: ng.ITimeoutService,
		private document: ConversationDocument,
		private mainScrollSelector: string,
		private fakeScrollSelector: string,
		private onScrollLockReleased: () => void,
		private onHighlightSentence: (sentence: ConversationSentence) => void,
		private hiddenSentenceUtil: HiddenSentenceUtil
	) {
		this.renderedRange = {
			startIndex: 0,
			endIndex: this.getCurrentDataSet().length - 1
		};

		this.visibleRange = null;
		this.showingFakeScroll = null;
	}

	init = (): void => {
		this.renderPartByIndex(0);
		this.calculateFakeScrollHeight();
	}

	needsVisibleRangeUpdate = (): boolean => {
		this.$log.info(`Visible range: ${this.visibleRange.startIndex}:${this.renderedRange.startIndex}; ${this.renderedRange.endIndex}:${this.visibleRange.endIndex}`);
		return this.visibleRange.startIndex - this.renderedRange.startIndex <= this.PARTIAL_RENDERING_SCROLL_THRESHOLD
			|| this.renderedRange.endIndex - this.visibleRange.endIndex <= this.PARTIAL_RENDERING_SCROLL_THRESHOLD;
	}

	isScrollLocked = (): boolean => {
		return !!this.scrollLock;
	}

	destroy = (): void => {
		this.$timeout.cancel(this.scrollLock);
	}

	onFakeScroll = (): ConversationSentence => {
		let fakeScrollElement: HTMLElement = this.getFakeScrollElement();

		// In case the document has been changed, but we are trying to work with a component that has already been destroyed
		if (!fakeScrollElement) {
			return;
		}

		// ceiling scrollTop for cases, when browser is zoomed
		let scrollTop: number = Math.ceil(fakeScrollElement.scrollTop);
		let clientHeight: number = fakeScrollElement.clientHeight;
		let scrollBottom: number = scrollTop + clientHeight;
		
		if (this.isSentenceInFieldOfView(scrollTop, scrollBottom)) {
			this.$log.info(`On fake scroll blocked for scroll ${scrollTop}`);
			this.autoFakeScrollOffset = null;
			return null;
		}

		let scrollHeight: number = fakeScrollElement.scrollHeight;
		this.$log.info(`On fake scroll is triggered at ${scrollTop} of ${scrollHeight}`);

		let dataSet: ConversationSentence[] = this.getCurrentDataSet();

		let sentenceIndex: number;
		if (scrollBottom === scrollHeight) {
			// if the scroll is at the very bottom, show the most bottom part
			sentenceIndex = dataSet.length - 1;
		} else {
			// otherwise calculate the sentence index relatively to conversation size
			let fraction: number = scrollTop / scrollHeight;
			sentenceIndex = Math.floor(fraction * dataSet.length);
		}

		return dataSet[sentenceIndex];
	}

	private isSentenceInFieldOfView = (scrollTop: number, scrollBottom: number): boolean => {
		return this.autoFakeScrollOffset !== null && this.autoFakeScrollOffset >= scrollTop && this.autoFakeScrollOffset < scrollBottom;
	}

	/**
	 * Lock conversation scrolling for partially rendered part, when we are scrolling to manually selected sentence,
	 * to ensure, that additional partial renders are NOT triggered
	 */
	lockScrolling = (sentence: ConversationSentence, operation: () => void, timeout: number) => {
		this.$log.info(`Locking scrolling for sentence ${sentence.id} for ${timeout}ms`);

		this.scrollLockSentenceId = sentence.id;
		this.scrollLock = this.$timeout(() => {
			// unlock the scroll preemptively so it can be locked again in operation
			this.unlockScroll();

			if (operation)
				operation();

			// if lock was not locked again in operation, reevaluate spine range
			if (!this.scrollLock) {
				this.onScrollLockReleased();
			}
		}, timeout);
	}

	/**
	 * Making sure, that multiple ensured scrolls for different sentences do not intersect each other.
	 */
	checkAndCancelScroll = (sentence: ConversationSentence): void => {
		if (this.scrollLock && sentence.id !== this.scrollLockSentenceId) {
			this.$log.info('Cancelling existing block timeout');
			this.$timeout.cancel(this.scrollLock);
			this.unlockScroll();
		}
	}

	updateFakeScrollOffset = (sentence: ConversationSentence): void => {
		let sentenceIndex = this.findSentenceIndex(sentence.id);
		this.updateFakeScrollOffsetByIndex(sentenceIndex);
	}

	private findSentenceIndex = (sentenceId: number): number => {
		let allMessages: ConversationSentence[] = this.getCurrentDataSet();
		return this.document.isChat
			? _.findIndex(allMessages, message => message.groupedIds ? message.groupedIds.contains(sentenceId) : message.id === sentenceId)
			: _.findIndex(allMessages, { id: sentenceId });
	}

	updateFakeScrollOffsetByIndex = (sentenceOffsetBaseIndex: number): void => {
		let fakeScrollJQuery = this.getFakeScrollJQuery();
		let fakeScrollElement = fakeScrollJQuery[0];
		let datasetLength = this.getCurrentDataSet().length;

		if (!fakeScrollElement) {
			return;
		}

		if (this.visibleRange?.endIndex === datasetLength - 1) {
			sentenceOffsetBaseIndex = datasetLength - 1;
		}

		let fraction = sentenceOffsetBaseIndex / datasetLength;
		let scrollTop = Math.floor(fraction * fakeScrollElement.scrollHeight);

		this.$log.info(`Updating fake scroll offset to ${scrollTop}`);
		fakeScrollJQuery.scrollTop(scrollTop);

		this.$log.info(`Blocking next fake scroll event after auto update for scroll ${scrollTop}`);
		this.autoFakeScrollOffset = scrollTop;
	}

	private unlockScroll = (): void => {
		this.$log.info('Unlocking scroll');
		this.scrollLockSentenceId = null;
		this.scrollLock = null;
	}

	renderPartBySentenceId = (sentenceId: number): void => {
		this.$log.info(`Rendering part for sentence id ${sentenceId}`);
		this.renderPartByIndex(this.findSentenceIndex(sentenceId));
	}

	renderPartByIndex = (topMessageIndex: number, adjustFakeScrollPosition?: boolean): void => {
		this.$log.info(`Rendering part for sentence index ${topMessageIndex}`);
		let allMessages = this.getCurrentDataSet();
		let renderedRange = this.getPartialRenderingTargetRange(allMessages, topMessageIndex);
		renderedRange = this.adjustRenderedRangeByVisibleSentences(renderedRange);

		this.$log.info(`Set rendered range to ${renderedRange.startIndex}:${renderedRange.endIndex}`);
		this.renderedRange = renderedRange;

		let highlightedSentence = this.highlightTarget;

		this.$timeout(() => {
			if (highlightedSentence) {
				this.$log.info(`Rehighlight sentence after new part is rendered ${highlightedSentence.id}`);
				this.onHighlightSentence(highlightedSentence);
			}

			this.calculateFakeScrollVisibility();

			if (adjustFakeScrollPosition) {
				this.updateFakeScrollOffsetByIndex(topMessageIndex);
			}
		}, 0);
	}

	/**
	 * Adjusts rendered range for cases, when participants are hidden to show at least "getPartialRenderingChunkSize" elements
	 */
	private adjustRenderedRangeByVisibleSentences = (renderedRange: ElementsRange): ElementsRange => {
		let acceptableChunkSize = this.getPartialRenderingChunkSize();
		let hiddenParticipantClasses = this.hiddenSentenceUtil.getHiddenParticipantClasses();
		let dataset = this.getCurrentDataSet();

		// find new start index, while ensuring minimal amount of rendered pieces
		let adjustedStartIndex = renderedRange.startIndex;
		let visiblePiecesCount = 0;
		for (let i = renderedRange.endIndex; i > 0 && visiblePiecesCount < acceptableChunkSize; i--) {
			let sentence = dataset[i];
			if (this.isRenderedPiece(hiddenParticipantClasses, sentence)) {
				visiblePiecesCount++;
				if (i < adjustedStartIndex) {
					adjustedStartIndex = i;
				}
			}
		}

		// ensure all hidden pieces of current endIndex participant are included in rendered range
		let adjustedEndIndex = renderedRange.endIndex;
		if (this.hiddenSentenceUtil.isSentenceHidden(hiddenParticipantClasses, dataset[renderedRange.endIndex])) {
			for (let i = renderedRange.endIndex; i < dataset.length; i++) {
				if (i === dataset.length - 1) {
					adjustedEndIndex = i;
				} else {
					let sentence = dataset[i];
					if (this.isRenderedPiece(hiddenParticipantClasses, sentence)) {
						adjustedEndIndex = i - 1;
						break;
					}
				}
			}
		}

		return {
			startIndex: adjustedStartIndex,
			endIndex: adjustedEndIndex
		};
	}

	private isRenderedPiece = (hiddenParticipantClasses: string[], sentence: ConversationSentence): boolean => {
		return !(this.hiddenSentenceUtil.isSentenceHidden(hiddenParticipantClasses, sentence) && sentence.isSameChannelAsLast);
	}

	getRenderedRange = (): ElementsRange => {
		return this.renderedRange;
	}

	setVisibleRange = (range: UIElementsRange): void => {
		this.visibleRange = range;
	}

	isShowingFakeScroll = (): boolean => {
		return this.showingFakeScroll;
	}

	setHighlightTarget = (sentence: ConversationSentence): void => {
		this.highlightTarget = sentence;
	}

	getHighlightTarget = (): ConversationSentence => {
		return this.highlightTarget;
	}

	private calculateFakeScrollHeight = (): void => {
		let fakeScrollHeight = this.getCurrentDataSet().length * this.FAKE_SCROLL_HEIGHT_PER_SENTENCE;
		this.getFakeScrollInnerBlock().height(Math.min(fakeScrollHeight, this.FAKE_SCROLL_MAX_HEIGHT));
	}

	private getPartialRenderingTargetRange = (messages: ConversationSentence[], targetMessageIndex: number): ElementsRange => {
		if (this.visibleRange) {
			return this.getPartialRenderingTargetRangeByDisplayedRange(messages, targetMessageIndex, this.visibleRange);
		} else {
			return this.getApproximateTargetRenderingRange(messages, targetMessageIndex);
		}
	}

	private getPartialRenderingTargetRangeByDisplayedRange = (messages: ConversationSentence[],
			targetMessageIndex: number, lastDisplayedRange: ElementsRange): ElementsRange => {
		let chunkSize = Math.max(lastDisplayedRange.endIndex - lastDisplayedRange.startIndex + 1, this.PARTIAL_RENDERING_MIN_CHUNK);
		return {
			startIndex: Math.max(0, targetMessageIndex - Math.floor(chunkSize / 2)),
			endIndex: Math.min(messages.length - 1, targetMessageIndex + chunkSize + Math.floor(chunkSize / 2))
		};
	}

	private getApproximateTargetRenderingRange = (messages: ConversationSentence[], targetMessageIndex: number): ElementsRange => {
		let chunkSize = this.getPartialRenderingChunkSize();

		let startIndex = Math.max(0, targetMessageIndex - Math.floor(chunkSize / 2));
		let endIndex = Math.min(messages.length - 1, startIndex + chunkSize);

		return {
			startIndex,
			endIndex
		};
	}

	private calculateFakeScrollVisibility = (): void => {
		if (this.showingFakeScroll === null && this.getConversationElements().length > 0) {
			let conversationElement = this.getMainScrollElement();
			let visible = conversationElement.scrollHeight > conversationElement.clientHeight;
			this.showingFakeScroll = visible;
		}
	}

	private getPartialRenderingChunkSize = (): number => {
		let scrollHeight = this.getMainScrollElement().offsetHeight;
		let calculatedChunk = Math.ceil(scrollHeight / this.PARTIAL_RENDERING_CHUNK_UNIT_VIEWSIZE) * this.PARTIAL_RENDERING_CHUNK_PER_UNIT;
		return Math.max(this.PARTIAL_RENDERING_MIN_CHUNK, calculatedChunk);
	}

	private getCurrentDataSet = (): ConversationSentence[] => {
		return this.document.isChat ? this.document.chatMessages : this.document.sentences;
	}

	private getFakeScrollJQuery = (): JQuery => {
		return angular.element(this.fakeScrollSelector);
	}

	private getFakeScrollElement = (): HTMLElement => {
		return this.getFakeScrollJQuery().get(0) as HTMLElement;
	}

	private getMainScrollJQuery = (): JQuery => {
		return angular.element(this.mainScrollSelector);
	}

	private getMainScrollElement = (): HTMLElement => {
		return this.getMainScrollJQuery().get(0) as HTMLElement;
	}

	private getFakeScrollInnerBlock = (): JQuery => {
		return angular.element(`${this.fakeScrollSelector} .inner-block`);
	}

	private getConversationElements = (): HTMLElement[] => {
		return angular.element(this.mainScrollSelector).find('.conversation-item:not(.hidden-transcript)').toArray() as HTMLElement[];
	}

}
