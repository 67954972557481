import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { from, Observable } from 'rxjs';

export interface ApplicationSuiteConfig {
	cxEngage: ApplicationSuiteItemConfig;
	socialConnect: ApplicationSuiteItemConfig;
}

export interface ApplicationSuiteItemConfig {
	enabled: boolean;
	location: string;
}

@Injectable({
	providedIn: 'root'
})
export class ApplicationSuiteApiService {

	constructor(
		private readonly http: CxHttpService
	) {}

	getSuiteConfig = (): Observable<ApplicationSuiteConfig> => {
		return from(this.http.get<ApplicationSuiteConfig>(`rest/application-suite`));
	}
}

