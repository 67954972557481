import * as _ from 'underscore';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';

export class NLPAttributes {
	enrichment: string[];
	language: string[];
	conversation: string[];
	words: string[];
	drillWords: string[];
	drillEnrichment: string[];
	drillLanguageAndOther: string[];
	root: string[];

	constructor(metricConstants: MetricConstants) {
		let constants = metricConstants.get();
		this.enrichment = _.map([
			constants.CONTENT_TYPE,
			constants.CONTENT_SUBTYPE,
			constants.DETECTED_FEATURES,
			constants.CB_EMOTION,
			constants.CB_CHAPTERS,
			constants.CB_STATED_DURATION,
			constants.CB_SENTENCE_TYPE,
			constants.REASON_DETECTION,
			constants.CB_CONCLUDING_EVENT,
			constants.CB_CONV_OUTCOME,
			constants.CB_PARTICIPANT_OUTCOME,
			constants.CB_PARTICIPANT_EMPATHY_SCORE,
			constants.CB_MEDICAL_CONDITION,
			constants.CB_MEDICAL_PROCEDURE,
			constants.CB_RX,
			constants.CB_INTERACTION_HIGHLIGHTS
		], 'name');
		this.language = _.map([
			constants.PROCESSED_LANGUAGE,
			constants.DETECTED_LANGUAGE
		], 'name');
		this.conversation = _.map([
			constants.CB_CONV_PARTICIPANT_TYPE,
			constants.CB_CONV_PARTICIPANT_KIND,
			constants.CB_CONV_DURATION,
			constants.CB_CONV_PERCENT_SILENCE,
			constants.CB_CONV_SENTENCE_DURATION_MS,
			constants.CB_CONV_SENTENCE_START_TIME_MS,
			constants.CB_CONV_TOTAL_DEAD_AIR,
			constants.CB_CONV_TOTAL_HESITATION,
			constants.CB_CONV_TOTAL_OVERTALK,
			constants.CB_CONV_TOTAL_SILENCE
		], 'name');
		this.words = _.map([
			constants.CB_BRAND,
			constants.CB_COMPANY,
			constants.CB_EMAIL,
			constants.CB_EMOTICON,
			constants.CB_EVENT,
			constants.CB_PERSON,
			constants.CB_PHONE,
			constants.CB_PRODUCT,
			constants.CB_INDUSTRY,
			constants.CB_CURRENCY,
			constants.CB_PROFANITY,
			constants.WORDS
		], 'name');
		this.root = _.map([
			constants.LC,
			constants.HASHTAG
		], 'name');

		this.drillWords = _.map([
			constants.WORDS
		], 'name');
		this.drillEnrichment = _.map([
			constants.CB_BRAND,
			constants.CB_COMPANY,
			constants.DETECTED_FEATURES,
			constants.CB_EMAIL,
			constants.CB_EMOTICON,
			constants.CB_EMOTION,
			constants.CB_CHAPTERS,
			constants.CB_STATED_DURATION,
			constants.CB_EVENT,
			constants.CB_INDUSTRY,
			constants.CB_PERSON,
			constants.CB_PHONE,
			constants.CB_PRODUCT,
			constants.REASON_DETECTION,
			constants.CB_SENTENCE_TYPE,
			constants.CB_PARTICIPANT_TYPE,
			constants.CB_CONCLUDING_EVENT,
			constants.CB_CONV_OUTCOME,
			constants.CB_PARTICIPANT_OUTCOME
		], 'name');
		this.drillLanguageAndOther = _.map([
			constants.LANGUAGE,
			constants.DETECTED_LANGUAGE,
			constants.PROCESSED_LANGUAGE,
			constants.CONTENT_SUBTYPE,
			constants.CONTENT_TYPE
		], 'name');
	}
}
