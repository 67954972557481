import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { WidgetKeyboardUtils } from '@cxstudio/reports/widget-utils/widget-keyboard-utils';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

interface MouseEventWrapper extends MouseEvent {
	originalEvent?: any;
}

export enum KeyCode {
	TAB = 9,
	LEFT_ARROW = 37,
	RIGHT_ARROW = 39
}

// follows MouseEvent API
export enum MouseButtonCode {
	MAIN_BUTTON = 0,
	AUX_BUTTON = 1,
	SECONDARY_BUTTON = 2,
	FOURTH_BUTTON = 3,
	FIFTH_BUTTON = 4
}
export class HighchartsKeyboardUtils {

	static readonly HIGHCHARTS_POINT_CLASS = 'highcharts-point';
	static readonly HIGHCHARTS_LEGEND_BUTTON_CLASS = 'highcharts-a11y-proxy-button';

	private static readonly HIGHCHARTS_POINT_WRAPPER_ELEMENT = 'g.highcharts-series-group';


	static processNavigationFromPoint(event: KeyboardEvent, widgetId: number, widgetType: WidgetType): void {
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			let widgetPoints: HTMLElement[] = HighchartsKeyboardUtils.getPoints(event.target, widgetId, widgetType);
			let widgetLegends: HTMLElement[] = HighchartsKeyboardUtils.getLegends(widgetId);

			let targetPointIndex: number = widgetPoints.indexOf(event.target as HTMLElement);
			let lastPoint: boolean = HighchartsKeyboardUtils.isLastPoint(targetPointIndex, widgetPoints.length);

			if (!lastPoint || (lastPoint && widgetLegends.length === 0)) {
				// imitate right arrow; go to the next point
				KeyboardUtils.intercept(event);
				this.dispatchKeydownEvent(event.target, KeyCode.RIGHT_ARROW);
			}
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.intercept(event);

			let widgetPoints: HTMLElement[] = HighchartsKeyboardUtils.getPoints(event.target, widgetId, widgetType);
			let widgetLegends: HTMLElement[] = HighchartsKeyboardUtils.getLegends(widgetId);

			let targetPointIndex: number = widgetPoints.indexOf(event.target as HTMLElement);

			if (targetPointIndex === 0 && widgetLegends.length !== 0) {
				HighchartsKeyboardUtils.focusLastLegend(event.target, targetPointIndex, widgetPoints, widgetId);
			} else if (widgetPoints.length > 1) {
				// imitate left arrow; go to the prevous point
				this.dispatchKeydownEvent(event.target, KeyCode.LEFT_ARROW);
			}
		} else if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			// simulate a left click
			KeyboardUtils.intercept(event);
			this.dispatchMouseEvent(event, MouseButtonCode.MAIN_BUTTON);
		} else if (KeyboardUtils.isEventKey(event, Key.F10, KeyModifier.SHIFT)) {
			// simulate a right click
			KeyboardUtils.intercept(event);
			this.dispatchMouseEvent(event, MouseButtonCode.SECONDARY_BUTTON);
		}
	}

	private static focusLastLegend(targetPoint: any, targetPointIndex: number, widgetPoints: HTMLElement[], widgetId: number): void {
		if (widgetPoints.length > 1) {
			this.dispatchKeydownEvent(targetPoint, KeyCode.LEFT_ARROW);
			this.dispatchKeydownEvent(widgetPoints[widgetPoints.length - 1], KeyCode.TAB);
		} else {
			this.dispatchKeydownEvent(widgetPoints[targetPointIndex], KeyCode.TAB);
		}

		let legends: HTMLElement[] = HighchartsKeyboardUtils.getLegends(widgetId);
		if (legends.length > 1) {
			let firstLegend: HTMLElement = legends[0];
			this.dispatchKeydownEvent(firstLegend, KeyCode.LEFT_ARROW);
		}
	}

	private static isLastPoint(targetPointIndex: number, pointsAmount: number): boolean {
		return targetPointIndex === pointsAmount - 1;
	}

	static processNavigationFromLegend(event: KeyboardEvent, widgetId: number, widgetEnterFunc: () => void): void {
		let widgetLegends: HTMLElement[] = HighchartsKeyboardUtils.getLegends(widgetId);
		let targetLegendIndex: number = widgetLegends.indexOf(event.target as HTMLElement);
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			KeyboardUtils.intercept(event);

			if (targetLegendIndex === widgetLegends.length - 1) {
				// imiate tab keydown to inform highcharts the focus is gone; go to the whole widget and then shift to the first point
				HighchartsKeyboardUtils.focusFirstPoint(event, widgetId, widgetEnterFunc);
			} else {
				// imitate right arrow keydown; go to the next legend
				this.dispatchKeydownEvent(event.target, KeyCode.RIGHT_ARROW);
			}
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			if (targetLegendIndex !== 0) {
				// imitate left arrow keydown; go to the last point
				KeyboardUtils.intercept(event);
				this.dispatchKeydownEvent(event.target, KeyCode.LEFT_ARROW);
			}
		}
	}

	private static focusFirstPoint(event: any, widgetId: number, widgetEnterFunc: () => void): void {
		this.dispatchKeydownEvent(event.target, KeyCode.TAB);
		setTimeout(() => {
			WidgetKeyboardUtils.focusWidgetBox(widgetId);
			$(`#widget-${widgetId} .${WidgetKeyboardUtils.WIDGET_BOX_CLASS}`).blur();
			setTimeout(() => {
				widgetEnterFunc();
			});
		});
	}

	private static getPoints(targetPoint: any, widgetId: number, widgetType: WidgetType): HTMLElement[] {
		if (widgetType === WidgetType.SCATTER) {
			return $(`#widget-${widgetId} .highcharts-series .${HighchartsKeyboardUtils.HIGHCHARTS_POINT_CLASS}`).toArray() as HTMLElement[];
		}

		return $(targetPoint).closest(HighchartsKeyboardUtils.HIGHCHARTS_POINT_WRAPPER_ELEMENT).find(`.${HighchartsKeyboardUtils.HIGHCHARTS_POINT_CLASS}`).toArray() as HTMLElement[];
	}

	private static getLegends(widgetId: number): HTMLElement[] {
		return $(`#widget-${widgetId} .${HighchartsKeyboardUtils.HIGHCHARTS_LEGEND_BUTTON_CLASS}`).toArray() as HTMLElement[];
	}

	private static dispatchKeydownEvent(target: any, keyCode: number, shiftKey?: boolean): void {
		let focusNextPointEvent = new window.KeyboardEvent('keydown', {
			bubbles: true,
			cancelable: true,
			shiftKey
		});
		Object.defineProperty(focusNextPointEvent, 'keyCode', { value: keyCode });

		target.dispatchEvent(focusNextPointEvent);
	}

	private static dispatchMouseEvent(event: KeyboardEvent, mouseType: number): void {
		let target: any = event.target;

		if (mouseType === 0) {
			let clickPointEventLeft: MouseEventWrapper = new window.MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: true,
				button: mouseType,
				buttons: mouseType,
				clientX: target.getBoundingClientRect().x,
				clientY: target.getBoundingClientRect().y
			});
			clickPointEventLeft.originalEvent = event;

			target.dispatchEvent(clickPointEventLeft);
		} else {
			let clickPointEventRight: MouseEventWrapper = new window.MouseEvent('contextmenu', {
				view: window,
				bubbles: true,
				cancelable: true,
				button: mouseType,
				buttons: mouseType,
				clientX: target.getBoundingClientRect().x,
				clientY: target.getBoundingClientRect().y
			});
			clickPointEventRight.originalEvent = event;

			target.dispatchEvent(clickPointEventRight);
		}
	}
}
