
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

export class RequestAssetParameters {
	constructor(
		private assetParameters: AssetParameters,
		private accountProject?: IProjectSelection,
		private workspaceProject?: WorkspaceProject
	) {}

	getProjectId(): number {
		if (this.accountProject)
			return this.accountProject.projectId;
		if (this.workspaceProject)
			return this.workspaceProject.projectId;
		return;
	}

	getHttpParams(): {[key: string]: string} {
		let result = this.assetParameters?.getHttpParams() || {};
		return this.addProjectParams(result);
	}

	getCacheParams(): {[key: string]: string} {
		let result = this.assetParameters?.getCacheParams() || {};
		return this.addProjectParams(result);
	}

	private addProjectParams(params: {[key: string]: string}): {[key: string]: string} {
		if (this.accountProject && this.accountProject.contentProviderId) {
			params['contentProviderId'] = this.accountProject.contentProviderId.toString();
			params['accountId'] = this.accountProject.accountId.toString();
			params['projectId'] = this.accountProject.projectId.toString();
		}
		if (this.workspaceProject) {
			params['workspaceId'] = this.workspaceProject.workspaceId.toString();
			params['workspaceProjectId'] = this.workspaceProject.projectId.toString();
		}
		return params;
	}
}
