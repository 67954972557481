import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';


@Component({
	selector: 'widget-total-count',
	template: `
	<div class="br-widget-total lh-1 bg-gray-200 rounded-4 p-4 h-fit-content w-fit-content">
		<span *ngIf="!isModernLook">n=</span>
		<ng-content></ng-content>
	</div>`,
	styles: [
		`
			.br-widget-total {
				color: var(--default-text-color);
				margin: 0;
				outline: 4px solid var(--white);
			}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WidgetTotalCountComponent implements OnInit {
	isModernLook = false;

	constructor(
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService
	) {}

	ngOnInit(): void {
		this.isModernLook = this.widgetsEditService.getDashboard()?.properties?.modernLookAndFeelEnabled || false;
	}

}

app.directive('widgetTotalCount', downgradeComponent({component: WidgetTotalCountComponent}) as angular.IDirectiveFactory);
