<modal-header class="danger-modal"
	(cancel)="cancel()"
	[modalTitle]="getTitle()">
</modal-header>
<div class="modal-body remove-user-modal" [ngBusy]="loading">
	<div>
		<div>
			<p>{{note}}</p>
		</div>
		<span class="d-block pb-16">{{getMessage()}}</span>
		<div *ngIf="!input.isSystemAdminPage || this.showAssetTransfer">
			<table *ngIf="initialized && hasTransferItems()" class="table">
				<tbody>
					<td class="w-40-percent">
						<div class="d-flex align-center">
							<span>{{'administration.manageEverything'|i18n}}</span>
							<div *ngIf="!input.isSystemAdminPage">
								<cb-inline-help>
									<help-body>{{'administration.manageEverythingTooltip'|i18n}}</help-body>
								</cb-inline-help>
								<input type="checkbox" class="mt-0 ml-8" [(ngModel)]="manageEverything" (ngModelChange)="updateNewOwners()" />
							</div>
						</div>
					</td>
					<td class="w-50-percent">
						<simple-dropdown
							[(value)]="selectedPowerCandidateId"
							[options]="powerCandidates"
							valueField="id"
							displayField="email"
							[appendToBody]="false"
							[noMinWidth]="true"
							[searchable]="true"
							[disabled]="isDropdownEnabled()"
							(onChange)="updateNewOwners()">
						</simple-dropdown>
					</td>
				</tbody>
			</table>
			<div *ngFor="let accountId of getAccountIds()">
				<p *ngIf="showMasterAccountName()">
					<span class="master-account-name"> {{'common.masterAccount'|i18n}}: {{getAccountName(accountId)}} </span>
				</p>
				<div class="cx-new-owner-selection">
					<div *ngFor="let group of getGroups()">
						<ng-container *ngIf="hasGroupItems(group, accountId)">
							<table class="table" [ngClass]="group" style="table-layout: fixed">
								<thead>
								<tr>
									<th class="w-40-percent">{{getGroupHeader(group)}}</th>
									<th class="w-50-percent" i18n="mAccount.newOwner"></th>
								</tr>
								</thead>
								<tbody>
								<tr *ngFor="let transferItem of getGroupItems(group, accountId)" class="transfer-item">
									<td>{{transferItem.name}}</td>
									<td title="{{getNewOwnerTooltip()}}">
										<simple-dropdown
											[(value)]="transferItem.selectedCandidateId"
											[options]="transferItem.candidates"
											valueField="id"
											displayField="email"
											[appendToBody]="true"
											[noMinWidth]="true"
											[searchable]="true"
											[disabled]="!isDropdownEnabled()"
										></simple-dropdown>
									</td>
								</tr>
								</tbody>
							</table>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button
		id="btn-remove-user-cancel"
		type="button"
		class="btn btn-secondary"
		[i18n]="'common.cancel'"
		(click)="cancel()">
	</button>
	<button
		*ngIf="input.isSystemAdminPage && !showAssetTransfer"
		id="btn-remove-user-without-transfer-confirm"
		type="button"
		class="btn br-btn-yes btn-danger"
		(click)="disableUserWithoutTransfer()"
		[disabled]="false">
		{{disableUserWithoutTransferButton()}}
	</button>
	<button
		*ngIf="input.isSystemAdminPage && !showAssetTransfer"
		id="btn-remove-user-with-transfer-confirm"
		type="button"
		class="btn br-btn-yes btn-danger"
		(click)="showTransferCandidates()"
		[disabled]="false">
		{{disableUserAndTransferButton()}}
	</button>
	<button
		*ngIf="!input.isSystemAdminPage || showAssetTransfer"
		id="btn-remove-user-confirm"
		type="button"
		class="btn br-btn-yes btn-danger"
		(click)="disableUserAndTransfer()"
		[disabled]="!isFinishEnabled()">
		{{disableUserAndTransferButton()}}
	</button>
</div>
