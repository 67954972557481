import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, Type, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { WidgetContentEvent, VisualizationComponent } from '@app/modules/widget-visualizations/visualization-component.interface';
import { VisualizationResolverService } from '@app/modules/widget-visualizations/visualization-resolver.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'widget-content',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<template #viewContainer></template>`
})
export class WidgetContentComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() view: WidgetVisualization;
	@Input() trigger: boolean;
	@Input() demo?: boolean;
	@Input() data: any;
	@Input() widget: Widget;
	//@Input() linkedWidgets: number[];
	@Input() utils: WidgetUtils;

	@Output() handleClick = new EventEmitter<{ event: MouseEvent, point: DrillPoint, isRightClick?: boolean }>();
	@Output() handleRightClick = new EventEmitter<{ event: MouseEvent }>();

	//@Input() exportingImage: boolean;

	@Output() contentEvent = new EventEmitter<WidgetContentEvent>();

	@ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer: ViewContainerRef;
	private componentRef: ComponentRef<VisualizationComponent<any>>;

	constructor(
		private resolver: ComponentFactoryResolver,
		private visualizationResolverService: VisualizationResolverService,
		private ref: ChangeDetectorRef,
	) { }

	ngAfterViewInit(): void {
		this.initComponent();
		this.updateBindings();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.trigger && this.viewContainer) {
			this.initComponent(); // fully recreate component
		}
		this.updateBindings();
	}

	private initComponent() {
		if (this.view && this.data) {
			let componentClass = this.visualizationResolverService.resolveVisualization(this.view,
				this.widget, this.utils);
			this.createComponent(componentClass);
		}
	}

	createComponent(component: Type<VisualizationComponent<any>>) {
		if (this.componentRef) {
			this.componentRef.destroy();
		}

		this.viewContainer.clear();

		let factory = this.resolver.resolveComponentFactory(component);
		this.componentRef = this.viewContainer.createComponent(factory);

		// bypass click events from children - check
		this.componentRef.instance.handleClick.subscribe(event => this.handleClick.emit(event));
		this.componentRef.instance.handleRightClick.subscribe(event => this.handleRightClick.emit(event));

		this.componentRef.instance.contentEvent?.subscribe((event: WidgetContentEvent) => this.contentEvent.emit(event));
	}

	private updateBindings() {
		if (!this.componentRef) {
			return;
		}

		let instance = this.componentRef.instance;
		instance.widget = this.widget;
		instance.dataObject = this.data;
		instance.utils = this.utils;
		instance.demo = this.demo;

		this.ref.detectChanges();
	}

	ngOnDestroy() {
		this.componentRef?.destroy();
	}
}

app.directive('widgetContent', downgradeComponent({ component: WidgetContentComponent }) as angular.IDirectiveFactory);
