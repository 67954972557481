import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import AlertSubscriptionTemplateGridDefinition
	from '@app/modules/alert-subscription-template/services/alert-subscription-template-grid-definition.service';
import { ManageAlertTemplatesService }
	from '@app/modules/alert-subscription-template/services/manage-alert-subscription-templates.service';
import {
	CasePriorityTypesService
} from '@app/modules/alert-subscription-template/services/case-priority-types.service';
import {
	ASTInclusionListUtilsService
} from '@app/modules/alert-subscription-template/services/ast-inclusion-list-utils.service';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		AlertSubscriptionTemplateGridDefinition,
		ManageAlertTemplatesService,
		CasePriorityTypesService,
		ASTInclusionListUtilsService
	]
})
export class AlertSubscriptionTemplateModule {}
