import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { SortDirection } from '@cxstudio/common/sort-direction';

import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { ReportFilterManagement } from '@cxstudio/report-filters/report-filters-management.component';

@Injectable({
	providedIn: 'root'
})
export default class FilterGridDefinitionService implements IGridDefinition<ReportFilterManagement> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree,
		@Inject('commonGridColumns') private commonGridColumns: CommonGridColumns,
		@Inject('DateRange') private DateRange) {}

	init = (gridMode: GridMode, controller: ReportFilterManagement) => {
		let columns = [
		{
			id: 'checkbox',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-checkbox text-center',
			name: '<span></span>',
			cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.SelectedRowFormatter,
			resizable: false
			// no name property, as we'll add the checkbox in the html
		},
		{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.filterHamburgerFormatter,
			resizable: false,

			onClick: (event, object) => {
				let showHide = {
					name: 'hideToggle',
					text: controller.ui.hideFilters
						? this.locale.getString('dashboard.showAllHidden')
						: this.locale.getString('dashboard.hideAllHidden'),
					func: () => { controller.ui.hideFilters = !controller.ui.hideFilters; },
					classes: [controller.ui.hideFilters ? 'items-hidden' : 'items-visible']
				};

				this.contextMenuTree.show(event, object, [showHide], 'header-menu', 360);
			}
		},
		this.commonGridColumns.getLabelsColumn(this.locale.getString('object.filter')),
		{
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		},
		{
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: true,
			minWidth: 240,
			width: 660,
			defaultSortColumn: SortDirection.ASC,
			cssClass: 'cell-title',
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.FILTER),
			isObjectNameColumn: true
		}, {
			id: 'created',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'ownerName',
			name: this.locale.getString('common.owner'),
			field: 'ownerName',
			sortable: true,
			optional: true,
			minWidth: 80,
			width: 260,
			formatter: this.gridFormatterService.getAuthorFormatter()
		},
		{
			id: 'type',
			name: this.locale.getString('reportFilters.type'),
			field: 'type',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 120,
			formatter: this.gridFormatterService.FilterTypeFormatter
		}, {
			id: 'sharingStatus',
			name: this.locale.getString('dashboard.status'),
			field: 'sharingStatus',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 100,
			headerCssClass: 'text-center',
			formatter: this.FilterStatusFormatter,
			cssClass: 'cell-status text-center'
		}, {
			id: 'project',
			name: this.locale.getString('reportFilters.project'),
			field: 'projectName',
			sortable: true,
			optional: true,
			minWidth: 80,
			width: 200,
			formatter: (row, cell, value, columnDef, dataContext) => {
				if (dataContext.type === FilterTypes.PREDEFINED) {
					return this.locale.getString('metrics.allProjects');
				}
				return value;
			}
		}];

		return Promise.resolve(columns);
	}

	filterHamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (this.ignoreHamburger(dataContext))
			return '';
		return this.gridFormatterService.HamburgerFormatter(row, cell, value, columnDef, dataContext);
	}

	FilterStatusFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (dataContext.type === 'studioDateFilter') {
			return this.StatusPresetDateFilterFormatter(row, cell, value, columnDef, dataContext);
		} else if (dataContext.type === FilterTypes.CXSCORECARD) {
			return this.gridFormatterService.StatusScorecardDisabledFormatter(row, cell, value, columnDef, dataContext);
		} else {
			return this.gridFormatterService.StatusFormatter(row, cell, value, columnDef, dataContext);
		}
	}

	StatusPresetDateFilterFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
		return getSwitch(dataContext.enabled, dataContext.value === this.DateRange.defaultDateRange.value);
	}

	private ignoreHamburger(filter): boolean {
		return FilterTypes.CXDATE === filter.type
			|| FilterTypes.CXDATE === filter.id
			|| this.isScorecardFolder(filter)
			|| this.ignorePredefinedHamburger(filter)
			|| filter.generatedFolderType === GeneratedFolderType.CUSTOM;
	}

	private ignorePredefinedHamburger(filter): boolean {
		// don't show menu for sentiment filter if no project selected
		return filter.type === FilterTypes.PREDEFINED
			&& filter.subtype === PredefinedMetricConstants.SENTIMENT
			&& !filter.projectName;
	}

	private isScorecardFolder(filter): boolean {
		return filter.type === FolderTypes.FILTER
			&& filter.generatedFolderType === GeneratedFolderType.SYSTEM;
	}

}
app.service('filterGridDefinition', downgradeInjectable(FilterGridDefinitionService));
