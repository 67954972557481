<div class="pl-16">
	<div class="favoritable-item">
		<h4 class="display-name font-bold text-base d-inline">{{attribute.displayName}}</h4>
		<favorite-item-icon
			[favoriteId]="favoriteId"
			[favoriteDisplayName]="attribute.displayName"
			[favoriteAttributes]="favoriteAttributes"
			[disableFavorites]="disableFavorites"
			[favoritesPanel]="favoritesPanel"
			(onClick)="toggleFavorite()"></favorite-item-icon>
	</div>
	<collapsible-pills
		*ngIf="width && pills?.length"
		[pills]="pills"
		[width]="width"
		[classFunc]="getPillClass"
		(pillClick)="pillClick($event)"
		[useContrastTextColor]="true">
	</collapsible-pills>
</div>
