import { HighchartsVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization.interface';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { TableService } from '@cxstudio/services/table-service';
import * as Highcharts from 'highcharts';
import { ColorConstants } from '../utils/color/color-constants';
import HighchartsAccessibilityUtils from '../utils/highchart/highcharts-accessibility-utils';
import { ReportScopeUtils } from '../utils/report-scope-utils.service';
import { ReportUtils } from '../utils/visualization/report-utils.service';
import { HighchartsDefinitionFactory } from './definitions/highcharts/highcharts-definition.factory';


/**
 * Function should be named in camelCase
 * params - required services (auto-injected)
 */
app.directive('highchartsPie', (
	tableService: TableService,
	highchartsDefinitionFactory: HighchartsDefinitionFactory,
	reportUtils: ReportUtils,
	reportScopeUtils: ReportScopeUtils,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils,
	applicationThemeService: ApplicationThemeService
) => {

	return {
		restrict: 'A',
		replace: true,
		template: '<div>Error</div>',
		scope: {
			options: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},
		link: (scope, element, attrs) => {
			scope.selectedPoint = null;
			reportUtils.initDestroyListener(scope);
			if (_.isUndefined(scope.utils)) {
				scope.utils = {widgetType: 'undefined'};
			}

			if (!tableService.processIfNoData(element, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			if (_.isUndefined(scope.options.yAxis)) {
				scope.options.yAxis = 'volume';
			}

			let pieChartCallback = (chart): void => {
				highchartsAccessibilityUtils.applyLegendBorder(chart);
			};

			let renderChart = (): void => {
				// destroy chart if it already exists
				if (scope.chart?.container)
					scope.chart.destroy();

				let chartOptions = highchartsDefinitionFactory.get(scope, element).getChartOptions();

				scope.chart = new Highcharts.Chart(chartOptions, pieChartCallback);
				drawNamesIfNotStacked();

				let destroyChart = scope.$on('$destroy', () => {
					// destroy chart if it has been rendered
					if (scope.chart.container)
						scope.chart.destroy();
					destroyChart();
				});
			};

			function drawNamesIfNotStacked(): void {
				if (showNames()) {
					$(element[0]).find('text[cx-type=label]').remove();
					drawNames();
				}
			}

			function showNames(): boolean {
				let groups = scope.utils.selectedAttributes.length || 0;
				if (!_.isUndefined(scope.options.sideBySide)) {
					return (scope.options.sideBySide && groups > 1);
				}
				if (_.isUndefined(scope.options.stackedType)) {
					return true;
				}
				return false;
			}

			renderChart();
			reportUtils.initResizeHandler(scope, element, reportUtils.chartResizeHandler(scope as HighchartsVisualization));

			if (!scope.demo) {
				reportScopeUtils.emitContextMenuHandling(scope, element);
			}

			scope.$watch('trigger', reportUtils.chartTriggerHandler(scope, renderChart));

			function drawNames(): void {
				if (!scope.chart.series) return;
				let top = scope.chart.plotTop;
				let left = scope.chart.plotLeft;
				let _height = scope.chart.chartHeight;
				let _center, _size, _r, _name;
				let _prevCenter = [];
				let labelColor = ColorConstants.CHARCOAL;
				if (applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER)) {
					labelColor = ColorConstants.WHITE;
				}
				scope.chart.series.forEach((series) => {
					_center = series.center;
					if (_center[0] === _prevCenter[0]) {
						return;
					}
					_prevCenter = _center;
					_size = series.options.size;
					_r = _height * parseFloat(_size) / 200;
					_name = series.name;
					if (_name.length > 12) {
						_name = _name.substring(0, 10) + '...';
					}

					scope.chart.renderer.text(
						_name, _center[0] + left - 20, _center[1] + top + _r + 40)
						.attr({
							zIndex: 5,
							'cx-type': 'label',
							fill: labelColor
						})
						.add();
				});
			}
		}
	};
});
