<form #definitionForm="ngForm">
	<div class="form-group">
		<label for="metric-name">{{'common.name'|i18n}}</label>
		<input id="metric-name"
			class="form-control"
			name="name"
			type="text"
			[(ngModel)]="properties.displayName"
			(ngModelChange)="nameWasChanged()"
			[disabled]="isPredefinedMetric()"
			size="60"
			maxlength="150"
			required>
	</div>

	<div class="form-group" *ngIf="!isPredefinedMetric()">
		<label>{{'metrics.metricType'|i18n}}</label>
		<radio-buttons
			name="metricType"
			[options]="ui.metricTypes"
			[ngModel]="definition.type"
			(ngModelChange)="metricTypeChangeHandler($event)"
		></radio-buttons>
	</div>

	<collapsing-panel>
		<panel-heading class="font-bold">{{'metrics.definition'|i18n}}</panel-heading>
		<panel-body>
			<div *ngIf="assets">
				<predefined-metric-definition *ngIf="isPredefinedMetric()"
					[(definition)]="definition"
					(definitionChange)="definitionChangeHandler()"
					(restoreDefaults)="restoreDefaultsHandler()"
					(validityChange)="definitionValidityChangeHandler($event)">
				</predefined-metric-definition>
				<box-metric-definition *ngIf="isBoxMetric()"
					[attributes]="assets.attributes"
					[(definition)]="definition"
					(definitionChange)="definitionChangeHandler()"
					(validityChange)="definitionValidityChangeHandler($event)">
				</box-metric-definition>
				<filter-metric-definition *ngIf="isFilterMetric()"
					[project]="project"
					[assets]="assets"
					[properties]="properties"
					[(definition)]="definition"
					(definitionChange)="definitionChangeHandler()"
					(formatChange)="formatChangeHandler($event)"
					(validityChange)="definitionValidityChangeHandler($event)">
				</filter-metric-definition>
				<value-metric-definition *ngIf="isValueMetric()"
					[(definition)]="definition"
					(definitionChange)="definitionChangeHandler()">
				</value-metric-definition>
				<math-metric-definition *ngIf="isMathMetric()"
					[project]="project"
					[assets]="assets"
					[(definition)]="definition"
					(definitionChange)="definitionChangeHandler()"
					(validityChange)="definitionValidityChangeHandler($event)">
				</math-metric-definition>
			</div>
		</panel-body>
	</collapsing-panel>

	<collapsing-panel [collapsed]="true" *ngIf="!isPredefinedMetric()">
		<panel-heading class="font-bold">{{'common.properties'|i18n}}</panel-heading>
		<panel-body>
			<div class="form-group">
				<label for="metric-owner">{{'common.owner'|i18n}}</label>
				<input id="metric-owner"
					name="owner"
					disabled
					type="text"
					class="form-control"
					required
					autocomplete="off"
					[(ngModel)]="properties.ownerName">
			</div>

			<div class="form-group" *ngIf="isMovableToFolder()">
				<label class="w-100-percent">{{'metrics.saveTo'|i18n}}</label>
				<search-list [dropdown]="true"
					id="_t-folders-dropdown"
					[data]="targetFolder"
					displayField="nameWithPath"
					sortField="nameWithPath"
					[listOptions]="ui.folders"
					(onNodeSelection)="selectFolder($event.node)"
				></search-list>
			</div>

			<div class="form-group">
				<label class="control-label">{{'common.description'|i18n}}</label>
				<textarea
					name="description"
					rows="4"
					class="form-control"
					maxlength="256"
					[(ngModel)]="properties.description"
					(ngModelChange)="propertiesChangeHandler()" ></textarea>
			</div>

			<div class="form-group">
				<label class="control-label">{{'common.labels'|i18n}}</label>
				<labels-input
					[labels]="properties.labels"
					[loadLabels]="loadLabels"
					(onLabelAdded)="labelAdded($event)"
					(onLabelRemoved)="labelRemoved($event)"
				></labels-input>
			</div>
		</panel-body>
	</collapsing-panel>
</form>
