import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import AnalyticWidget from './analytic-widget.class';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';

export class BarWidget extends AnalyticWidget {
	visualProperties: VisualProperties;

	static get(): BarWidget {
		return new BarWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new VisualProperties();
		this.name = WidgetType.BAR;
		this.properties.widgetType = WidgetType.BAR;
		this.visualProperties.visualization = WidgetVisualization.DUAL;
		this.visualProperties.subChartType = ChartType.BAR;
		this.visualProperties.attributeSelections = {
			primaryGroup: {} as ReportCalculation,
			yAxis: {} as ReportCalculation,
			color: {} as ReportCalculation
		};
	}

	withCalculations(calculations: ReportCalculation[]): BarWidget {
		this.properties.selectedMetrics = calculations;

		let primary = calculations[0];
		this.visualProperties.yAxis = primary.name;
		this.visualProperties.attributeSelections.yAxis = primary;

		if (calculations?.length === 2) {
			let secondary = calculations[1];
			this.visualProperties.attributeSelections.color = secondary;
		}

		return this;
	}

	withGrouping(grouping: any): BarWidget {
		super.withGroupings([grouping]);
		this.visualProperties.primaryGroup = grouping.name;
		this.visualProperties.attributeSelections.primaryGroup = grouping;
		return this;
	}

	withColors(primaryColor: string, secondaryColor: string = ''): BarWidget {
		this.visualProperties.color = primaryColor;
		this.visualProperties.secondaryColor = secondaryColor;
		return this;
	}

	withLegend(showLegend: boolean): BarWidget {
		this.visualProperties.showLegend = showLegend;
		return this;
	}
}
