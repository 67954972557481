import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import { AbstractAssetParameters } from '@app/modules/asset-management/access/asset-parameters/abstract-asset-parameters';

export class DocumentAssetParameters extends AbstractAssetParameters {
	static fromWidget(widget: PreviewWidget): DocumentAssetParameters {
		return new DocumentAssetParameters(widget.properties.encodedDescriptor);
	}

	constructor(
		private encodedDescriptor: string
	) {
		super(AssetParametersType.DOCUMENT);
	}

	getHttpParams(): {[key: string]: string} {
		let params = { encodedDescriptor: this.encodedDescriptor };
		return _.extend(super.getHttpParams(), params);
	}

	getCacheParams(): {[key: string]: string} {
		let cachePrams: {[key: string]: string} = {};
		cachePrams.encodedDescriptor = this.encodedDescriptor;
		return cachePrams;
	}
}
