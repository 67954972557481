import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { ITableColumnFormatter, TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { MasterAccountWorkspaceStatus } from './master-account-workspace-status';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceStatusTableColumnsService {

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		let workspacesFormatter: ITableColumnFormatter<MasterAccountWorkspaceStatus> = (item) => {
			if (!item.workspaces) return '';
			return item.workspaces
				.map(ws => `${ws.contentProviderName}:${ws.workspaceName}`)
				.join(', ');
		};
		return [{
			name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.3
		},
		{
			name: 'workspaces',
			displayName: this.locale.getString('mAccount.unitsIntegration'),
			formatter: workspacesFormatter,
			width: 0.7
		}];
	}
}
