import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import {
	AlertSubscriptionTemplatesList
} from '@cxstudio/alert-subscription-templates/alert-subscription-templates-list.component';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SortDirection } from '@cxstudio/common/sort-direction';
import {
	AlertSubscriptionTemplateType
} from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export default class AlertSubscriptionTemplateGridDefinition implements IGridDefinition<AlertSubscriptionTemplatesList> {

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter
	) {}

	init = (_gridMode: GridMode, controller: AlertSubscriptionTemplatesList): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: false,
			formatter: this.getActiveSwitcherFormatter(),
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'name',
			name: this.locale.getString('alertTemplates.name'),
			field: 'name',
			sortable: true,
			minWidth: 280,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.ALERT_SUBSCRIPTION_TEMPLATE),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'type',
			name: this.locale.getString('common.type'),
			field: 'type',
			sortable: true,
			minWidth: 100,
			width: 150,
			cssClass: 'cell-type',
			searchable: false,
			formatter: (_row, _cell, value) => {
				switch (value) {
					case AlertSubscriptionTemplateType.SCORECARD: return this.locale.getString('alert.scorecard');
					case AlertSubscriptionTemplateType.METRIC: return this.locale.getString('alertTemplates.metric');
					case AlertSubscriptionTemplateType.PRODUCT_FEEDBACK: return this.locale.getString('alertTemplates.tuningSuggestions');
					default: return this.locale.getString('alert.newVerbatim');
				}
			}
		}, {
			id: 'modifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'updatedTime',
			sortable: true,
			optional: true,
			minWidth: 200,
			width: 200,
			cssClass: 'cell-creation-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'owner',
			name: this.locale.getString('alertTemplates.owner'),
			field: 'owner',
			sortable: true,
			optional: true,
			minWidth: 250,
			width: 250,
			cssClass: 'cell-owner',
			searchable: true,
			formatter: (_row, _cell, value, _columnDef, _dataContext) => {
				if (value) {
					return value;
				}

				return this.locale.getString('alertTemplates.defaultOwner');
			},
			rowClassFunction: (data) =>
				controller.canEditTemplate(data) ? '' : 'disabled'
		}, {
			id: 'subscriptions',
			name: this.locale.getString('alertTemplates.subscriptions'),
			field: 'subscriptionsCount',
			sortable: true,
			minWidth: 130,
			width: 130,
			cssClass: 'cell-subscriptions',
			searchable: false
		}];

		return Promise.resolve(columns);
	}

	private getActiveSwitcherFormatter = (): IRowFormatter => {
		return (_row, _cell, value, _columnDef, dataContext) => {
			const editable = true;
			const getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
			return getSwitch(value, !editable);
		};
	}
}

app.service('alertSubscriptionTemplateGridDefinition', downgradeInjectable(AlertSubscriptionTemplateGridDefinition));
