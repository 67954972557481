import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Output, EventEmitter, Input,
	ChangeDetectorRef, SimpleChanges  } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Unit } from '@app/modules/units/unit';
import { UserEditFormData } from '@app/modules/user-administration/editor/user-edit-form-data';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { WorkspaceAccess } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access';
import { WorkspaceAccessEditorState } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access-editor-state.class';
import { WorkspaceAccessManager } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access-manager.class';
import { LicenseLevel } from '@cxstudio/common/license-levels';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { ProjectAccessLevel, ProjectAccessLevelsService } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-levels.service';
import { ChangeUtils } from '@app/util/change-utils';
import { UnitsApi } from '@app/modules/units/units.api.service';


//projects-access already exist in old angular, data- has special meaning for old angular
@Component({
	selector: 'workspaces-projects-access',
	templateUrl: './workspaces-projects-access.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspacesProjectsAccessComponent implements OnInit, OnChanges {
	@Input() user: UserEditFormData;
	@Input() license: LicenseTypeItem;
	@Input() sourceUserId?: number;

	@Output() accessChange = new EventEmitter<WorkspaceAccess[]>();

	selectedWorkspaceId: number;
	workspaces: Unit[];
	workspaceAccess: WorkspaceAccess;
	private editorState: WorkspaceAccessEditorState;

	loading: Promise<any>;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly workspaceAccessManager: WorkspaceAccessManager,
		private readonly projectAccessLevelsService: ProjectAccessLevelsService,
		private unitsApi: UnitsApi,
	) { }

	ngOnInit(): void {
		this.workspaceAccess = {
			workspaceId: undefined,
			accountAdmin: false,
			liteUser: false,
			projects: []
		};
		const initialLicense = this.license;

		this.loading = this.unitsApi.getCurrentMasterAccountUnits()
			.then(units => {
				this.workspaces = units;
				return this.workspaceAccessManager.init(
					this.workspaces, this.user.userId, initialLicense, this.sourceUserId);
			} )
			.then(() => {
				this.preselectWorkspace();
				if (this.sourceUserId && !this.user.userId) {
					this.accessChange.emit(this.workspaceAccessManager.getTargerAccessUpdates());
				}
				this.ref.detectChanges();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.license)) {
			this.workspaceAccessManager.changeLicense(changes.license.currentValue);
			this.accessChange.emit(this.workspaceAccessManager.getTargerAccessUpdates());
		}
	}

	preselectWorkspace(): void {
		if (_.isEmpty(this.workspaces)) {
			return;
		}

		let workspace = this.workspaces[0];
		this.selectedWorkspaceId = workspace.id;
		this.loading = this.switchWorkspace();
	}

	hasMultipleWorkspaces(): boolean {
		return this.workspaces?.length > 1;
	}

	isAdminLevelLicense(): boolean {
		return this.editorState?.isAdminLevelLicense();
	}

	hasMarkAllAdmin(): boolean {
		return this.isAdminLevelLicense();
	}

	markAllAdmin(): void {
		if (!this.isAdminLevelLicense()) {
			return;
		}
		this.editorState.markAll(ProjectAccessLevelValue.MANAGER);
		this.onAccessLevelChange();
	}

	markAllReadOnly(): void {
		this.editorState.markAll(ProjectAccessLevelValue.VIEWER);
		this.onAccessLevelChange();
	}

	markAllNoAccess(): void {
		this.editorState.markAll(ProjectAccessLevelValue.NONE);
		this.onAccessLevelChange();
	}

	isEditorAccountAdmin(): boolean {
		return this.editorState && this.editorState.isEditorAdmin();
	}

	switchWorkspace(): Promise<void> {
		this.loading = this.workspaceAccessManager.getWorkspaceAccess(this.selectedWorkspaceId)
			.then(workspaceAccessState => {
				this.editorState = workspaceAccessState;
				this.workspaceAccess = this.editorState.getTargetState();
				this.ref.detectChanges();
			});
		return this.loading;
	}

	grantAccountAdmin(value: boolean): void {
		this.workspaceAccess.accountAdmin = value;
		if (this.workspaceAccess.accountAdmin) {
			this.editorState.setAll(ProjectAccessLevelValue.MANAGER);
		}
		this.onAccessLevelChange();
	}

	onAccessLevelChange(): void {
		this.workspaceAccessManager.update(this.workspaceAccess);
		this.accessChange.emit(this.workspaceAccessManager.getTargerAccessUpdates());
	}

	getAccessValues(projectData: ProjectAccess): ProjectAccessLevel[] {
		return this.editorState.getAccessOptions(projectData)
			.map(val => this.projectAccessLevelsService.findByValue(val));
	}

	hasMarkAllControls(): boolean {
		return this.license.licenseLevel !== LicenseLevel.VIEWER;
	}

	isProjectsAccessLocked(): boolean {
		return this.workspaceAccess.accountAdmin
			|| this.license.licenseLevel === LicenseLevel.VIEWER;
	}

	isDisabledOption(projectData: ProjectAccess): boolean {
		return this.editorState.isDisabledOption(projectData.project.id, projectData.accessLevel);
	}

	getError(): string {
		return this.editorState?.getError();
	}
}

app.directive('workspacesProjectsAccess', downgradeComponent({component: WorkspacesProjectsAccessComponent}));
