export enum HeatmapSubtype {
	DEFAULT = 'DEFAULT',
	ROW = 'ROW',
	COLUMN = 'COLUMN'
}

export class HeatmapVisualizationsService {

	definitions = {};

	// can get rid of these once all files can support enum
	DEFAULT = HeatmapSubtype.DEFAULT;
	ROW = HeatmapSubtype.ROW;
	COLUMN = HeatmapSubtype.COLUMN;

	constructor() {
		this.definitions[HeatmapSubtype.DEFAULT] = {
			apply: (visualProps) => {
				visualProps.subChartType = HeatmapSubtype.DEFAULT;
				visualProps.layout = 'squarified';
				visualProps.layoutOrientation = 'vertical';
			}
		};

		this.definitions[HeatmapSubtype.ROW] = {
			apply: (visualProps) => {
				visualProps.subChartType = HeatmapSubtype.ROW;
				visualProps.layout = 'stripes';
				visualProps.layoutOrientation = 'vertical';
			}
		};

		this.definitions[HeatmapSubtype.COLUMN] = {
			apply: (visualProps) => {
				visualProps.subChartType = HeatmapSubtype.COLUMN;
				visualProps.layout = 'stripes';
				visualProps.layoutOrientation = 'horizontal';
			}
		};
	}

	get = (type) => {
		return this.definitions[type] || this.definitions[HeatmapSubtype.DEFAULT];
	}

	isSortable = (visualization) => {
		return (visualization === HeatmapSubtype.ROW) || (visualization === HeatmapSubtype.COLUMN);
	}
}

app.service('HeatmapVisualizations', HeatmapVisualizationsService);
