import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { IPagingSearch, PagingSearchState } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';
import { Subscription } from 'rxjs';

@Component({
	selector: 'multiselect-footer',
	templateUrl: './multiselect-footer.component.html'
})
export class MultiselectFooterComponent implements OnInit, OnDestroy {
	@Input() pagingSearch: IPagingSearch;
	@Input() filterText: string;
	@Output() onFocusMove = new EventEmitter<void>();

	private stateChangedSubscription: Subscription;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.stateChangedSubscription = this.pagingSearch.stateChanged$.subscribe(() => this.ref.detectChanges());
	}

	ngOnDestroy(): void {
		this.stateChangedSubscription?.unsubscribe();
	}

	loadMore = (): void => {
		this.pagingSearch.loadMore(this.filterText);
	}

	showLoadMore = (): boolean => {
		return this.pagingSearch.searchState !== PagingSearchState.FIRST_SEARCH;
	}

	getLoadMoreText = (): string => {
		if (this.pagingSearch.searchState === PagingSearchState.FIRST_LOAD_MORE && this.pagingSearch.emptyResult)
			return this.locale.getString('common.refresh');
		else return this.locale.getString('common.loadMore');
	}

	canLoadMore = (): boolean => {
		return this.pagingSearch.searchState !== PagingSearchState.NO_MORE_DATA
			&& !this.pagingSearch.firstSearchingPromise
			&& !this.pagingSearch.pagingSearchingPromise;
	}

	getUpdatedText = (): string => {
		if (this.pagingSearch.searchState === PagingSearchState.FIRST_SEARCH
				|| this.pagingSearch.firstSearchingPromise
				|| this.pagingSearch.pagingSearchingPromise) {
			return '';
		} else if (this.pagingSearch.searchState === PagingSearchState.NO_MORE_DATA) {
			if (this.pagingSearch.emptyResult) {
				return this.locale.getString('filter.noMoreData');
			} else {
				return this.getUpdatedTextByNewItems();
			}
		} else if (this.pagingSearch.searchState !== PagingSearchState.FIRST_LOAD_MORE) {
			return this.getUpdatedTextByNewItems();
		} else {
			return '';
		}
	}

	private getUpdatedTextByNewItems = (): string => {
		if (this.pagingSearch.hasDottedItems) {
			return this.locale.getString('filter.updatedLabel');
		} else {
			return this.locale.getString('filter.loadedLabel');
		}
	}

}

app.directive('multiselectFooter', downgradeComponent({component: MultiselectFooterComponent}) as angular.IDirectiveFactory);
