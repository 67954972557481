import { WidgetLinkingItem } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-linking-action';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { ObjectUtils } from '@app/util/object-utils';

export class WidgetActionUtils {

	static findWidgetIndexById(widgets: Widget[], originalId: number, idMapper: IdMapper): number {
		let index = _.findIndex(widgets, {id: idMapper.getCurrentId(originalId)});
		if (index === -1)
			throw new Error(`Cannot find widget with id ${originalId} (or ${idMapper.getCurrentId(originalId)})`);
		return index;
	}

	static getLinkingData(allWidgets: Widget[]): WidgetLinkingItem[] {
		return allWidgets.map(widget => {
			return {
				id: widget.id,
				linkedWidgets: _.clone(widget.linkedWidgets), // shallow copy is enough here for number[]
			};
		});
	}

	static getWidgetWithLatestId(widget: Widget, idMapper: IdMapper): Widget {
		let copy = ObjectUtils.copy(widget);
		copy.id = idMapper.getCurrentId(widget.id);
		return copy;
	}
}
