import { Component, ChangeDetectionStrategy } from '@angular/core';
import { QualtricsStatusService } from '@app/modules/system-administration/status/integration-status/qualtrics/qualtrics-status.service';

@Component({
	selector: 'qualtrics-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsStatusComponent {

	constructor(
		public service: QualtricsStatusService,
	) { }
}
