import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { GridApi, MenuItemDef, RowNode } from 'ag-grid-community';
import { AgGridBookActionsService } from '../actions/ag-grid-book-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { MoveToOptionService } from '../options/move-to-option.service';
import { ShowHideOptionService } from '../options/show-hide-option.service';
import { CommonOptionsProviderService } from './common-options-provider.service';


@Injectable({
	providedIn: 'root'
})
export class BookMenuOptionsProviderService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly commonOptionsProviderService: CommonOptionsProviderService,
		private readonly agGridBookActionsService: AgGridBookActionsService,
		private readonly moveToOptionService: MoveToOptionService,
		private readonly showHideOptionService: ShowHideOptionService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('environmentService') private environmentService: EnvironmentService,
	) {}

	getOptions(gridApi: GridApi, node: RowNode, filterManager: TableFilterManager): (string | MenuItemDef)[] {
		let book: Book = node.data;

		let items: any[] = [];

		items.push(this.commonOptionsProviderService.getDashboardViewOption(book));

		if (this.security.has('edit_dashboard') && book.permissions.EDIT) {
			items.push(this.getEditOption(node));
			items.push(this.getRenameOption(node));
		}

		items.push(this.getBookCopyLinkOption(book));

		if (this.dashboardService.canShare(book)) {
			items.push(this.commonOptionsProviderService.getDashboardShareOption(node));
		}

		items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);

		let moveToSubmenuOptions: (string | MenuItemDef)[] = this.moveToOptionService.getSubMenu(book);
		if (moveToSubmenuOptions && moveToSubmenuOptions.length > 0) {
			items.push(this.moveToOptionService.get(moveToSubmenuOptions));
		}

		if (this.security.has('create_dashboard') && !this.environmentService.isTablet()) {
			items.push(this.commonOptionsProviderService.getDashboardCopyOption(book));
		}

		if (book.permissions.EDIT) {
			items.push(this.getShowComponentsOption(book));
		}

		let showHideSubMenuOptions = this.showHideOptionService.getSubmenu(gridApi, book, filterManager);
		items.push(this.showHideOptionService.get(showHideSubMenuOptions));

		items.push(this.commonOptionsProviderService.getDashboardFavoriteOption(node));

		if (book.permissions.OWN) {
			items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
			items.push(this.commonOptionsProviderService.getDashboardRemoveOption(book));
			items.push(this.commonOptionsProviderService.geTransferOption([book]));
		}

		return items;
	}

	private getEditOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('common.edit'),
			action: () => this.agGridBookActionsService.editBook(node)
		};
	}

	getRenameOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('common.rename'),
			action: () => this.agGridBookActionsService.renameBook(node)
		};
	}

	getBookCopyLinkOption(book: Book): MenuItemDef {
		return {
			name: this.locale.getString('common.copyLink'),
			action: () => this.agGridBookActionsService.copyLink(book)
		};
	}

	getShowComponentsOption(book: Book): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.bookComponents'),
			action: () => this.agGridBookActionsService.showComponents(book)
		};
	}

}
