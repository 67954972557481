import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';


@Component({
	selector: 'narrative-integration',
	template: `
<form [formGroup]="narrativeIntegration">
	<div class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					id="enableNarrativeIntegration"
					formControlName="enabled"
					label="{{'mAccount.enableNarrativeIntegration'|i18n}}">
				</checkbox-button>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.application'|i18n}}</label>
			<div class="col-md-8">
				<simple-dropdown
					[options]="narrativeApplications"
					formControlName="applicationId"
					valueField="id"
					displayField="name"
					allowClear="true">
				</simple-dropdown>
				<span *ngIf="applicationId?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ formUtils.getErrorMessage('mAccount.application') }}
				</span>
				<span *ngIf="applicationId?.errors?.min" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ 'mAccount.positiveNumberInvalid'|i18n }}
				</span>
			</div>
		</div>
	</div>
</form>`,
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NarrativeIntegrationComponent extends SelfCleaningComponent implements OnInit {

	narrativeApplications: ExternalApplication[];
	narrativeIntegration: FormGroup;

	constructor(
		private externalApplicationsApiService: ExternalApplicationsApiService,
		private fb: FormBuilder,
		readonly formUtils: CxFormUtils,
		private ref: ChangeDetectorRef
	) {
		super();
		this.narrativeIntegration = this.fb.group({
			enabled: [ false, [ Validators.required ]],
			applicationId: [{ value: undefined, disabled: true }, [ Validators.required, Validators.min(1) ]],
		});

		this.addSubscription(this.narrativeIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.applicationId);
				this.narrativeIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.applicationId);
				this.narrativeIntegration.updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.NARRATIVE).then(response => {
			this.narrativeApplications = response;
			this.ref.markForCheck();
		});
	}

	getGroup(): FormGroup {
		return this.narrativeIntegration;
	}

	get applicationId(): FormControl {
		return this.narrativeIntegration.controls.applicationId as FormControl;
	}

}
