import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DashboardListTab } from '@app/modules/dashboard-list/classes/dashboard-list-tab.class';
import { FavoriteDashboardsTab } from '@app/modules/dashboard-list/classes/favorite-dashboards-tab.class';
import { RecentDashboardsTab } from '@app/modules/dashboard-list/classes/recent-dashboards-tab.class';
import { Label } from '@cxstudio/dashboards/entity/label';
import { CxLocaleService } from '@app/core';
import { DashboardListTabTypes } from '@app/modules/dashboard-list/dashboard-list-tab-types.service';

@Component({
	selector: 'dashboard-list-tabs-editor',
	template: `
<div class="filter-statement d-flex align-items-center"
	cdkDropList
	cdkDropListOrientation="horizontal"
	(cdkDropListDropped)="reorder($event)">
	<div class="tab-definition border mr-8 ph-8 bg-white"
		*ngFor="let tab of tabs; index as $index"
		[ngClass]="{'tab-required': !isRemovable(tab), 'tab-defined': !isUndefined(tab)}"
		cdkDrag>
		<cb-drag-handle [altText]=getDragTitle(tab) class="mr-8" cdkDragHandle></cb-drag-handle>
		<span class="text-gray-900">{{getName(tab)}}</span>

		<select-from-tree class="flex-fill"
			placeholder="{{'appearance.selectALabel'|i18n}}"
			*ngIf="isRemovable(tab)"
			[ngModel]="tab.name"
			[hierarchyList]="labels"
			(onNodeClick)="selectLabel(tab, $event.node)">
		</select-from-tree>

		<i
			class="btn btn-secondary btn-icon ml-8 action-hover-text q-icon-trash cursor-pointer"
			(click)="remove($index)"
			title="{{getRemoveTitle(tab)}}"></i>
	</div>
	<i class="btn btn-secondary btn-icon ml-8 q-icon-add cursor-pointer text-default action-hover-text"
		(click)="addLabel()"></i>
</div>`,
})
export class DashboardListTabsEditorComponent implements OnInit {

	@Input() tabs: DashboardListTab[];
	@Input() labels: Label[];
	@Output() onChange = new EventEmitter<void>();

	constructor(
		private locale: CxLocaleService,
	) { }

	ngOnInit(): void {
	}

	getDragTitle(tab: DashboardListTab): string {
		return this.locale.getString('appearance.reorderTabByName', {name: this.getName(tab)});
	}

	getRemoveTitle(tab: DashboardListTab): string {
		return this.locale.getString('appearance.removeTabByName', {name: this.getName(tab)});
	}

	getName(tab: DashboardListTab): string {
		return DashboardListTabTypes.getTabType(tab).getTabName(this.locale, tab.name);
	}

	isRemovable(tab: DashboardListTab): boolean {
		return !(FavoriteDashboardsTab.is(tab) || RecentDashboardsTab.is(tab));
	}

	isUndefined(tab: DashboardListTab): boolean {
		return this.isRemovable(tab) && !tab.name;
	}

	addLabel(): void {
		this.tabs.push({name: ''});
		this.onChange.emit();
	}

	remove(tabIndex: number): void {
		if (!this.isRemovable(this.tabs[tabIndex])) return;

		this.tabs.removeAt(tabIndex);
		this.onChange.emit();
	}

	reorder(event: CdkDragDrop<DashboardListTab[]>): void {
		moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
		this.onChange.emit();
	}

	selectLabel(tab: DashboardListTab, selection: Label): void {
		tab.name = selection.text;
		this.onChange.emit();
	}
}
