import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'clearable-input',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClearableInputComponent), multi: true},
	],
	template: `
<div class="p-relative border-radius-inherit">
	<input
		[disabled]="disabled"
		[placeholder]="placeholder || ''"
		tabindex="0"
		class="form-control border-radius-inherit"
		[class.labeled-clearable-input]="showClearLabel"
		[ngModel]="value"
		[attr.aria-label]="ariaLabel"
		aria-autocomplete="list"
		autocomplete="off"
		(ngModelChange)="valueChangeHandler($event)">
	<a *ngIf="isValueSet()"
		(click)="clear()"
		(keydown.enter)="clear()"
		tabindex="0"
		id="clear-input-btn"
		class="clear-btn btn btn-icon no-border btn-secondary cursor-pointer text-1rem bg-none"
		[class.disabled]="disabled"
		[class.labeled-clear-btn]="showClearLabel"
		[attr.aria-label]="'common.clearValue'|i18n"
		title="{{'common.clearValue'|i18n}}">
		<span *ngIf="showClearLabel" class="pr-8 clear-label">{{'common.clear'|i18n}}</span><i class="q-icon-delete v-middle"></i>
	</a>
</div>
	`,
	styles: [`
input {
	padding-right: 30px !important;
}

.labeled-clearable-input {
	padding-right: 75px !important;
}

.labeled-clear-btn {
	width: 75px !important;
}

a {
	position: absolute;
	right: 0;
	top: 0;
}`]
})
export class ClearableInputComponent implements ControlValueAccessor {

	@Input() disabled: boolean;
	@Input() placeholder: string;
	@Input() ariaLabel: string;
	@Input() maxlength: number;
	@Input() showClearLabel: boolean;

	@Output() onClear = new EventEmitter<void>();

	value: string;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	isValueSet = (): boolean => {
		return !!this.value;
	}

	clear = (): void => {
		if (!this.disabled) {
			this.valueChangeHandler();
			this.onClear.emit();
		}
	}

	valueChangeHandler(value?: string): void {
		this.value = value;
		this.onChangeCallback(value);
		this.onTouchedCallback();
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.value) {
			this.value = value;
			this.onChangeCallback(value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
