export class GaugeDefaults {
	static readonly labelFontSize = 14;
	static readonly labelSpacing = 8;
	static readonly deltaSpacing = 8;
	static readonly rangeFontSize = 14;
	static readonly rangeSpacing = 8;
	static readonly margin = 16;
	static readonly marginReduced = 8;
	static readonly targetNumberOfDigits = 7;		// target number of characters, excluding comma/decimal
	static readonly separatorWidthRatio = .1;		// ratio of comma/decimal width to font height
	static readonly targetNumberOfSeparators = 1;

	static getRangeLabelPosition(): number {
		const labelHeight = GaugeDefaults.rangeFontSize * 0.8; //digit is 11px for font 14px
		return labelHeight + GaugeDefaults.rangeSpacing;
	}
}
