import * as _ from 'underscore';
import { CustomMathTokenizerService } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-tokenizer.service';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { CustomMathValidationService } from '@cxstudio/metrics/custom-math/custom-math-validation.service';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathExpressionAdapter } from './custom-math-expression-adapter';
import { CustomMathTokenType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { FormulaSegment } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathMetrics } from './custom-math-metrics';


export class CustomMathAdapter {
	private expressionToTokenType: Map<ExpressionPieces, CustomMathTokenType>;

	constructor(
		private assets: CustomMathAssets,
		private adapters: Map<CustomMathTokenType, CustomMathExpressionAdapter>,
		private customMathTokenizerService: CustomMathTokenizerService,
		private customMathValidationService: CustomMathValidationService
	) {
		this.populateMapping();
	}

	toFormula = (expressions: ExpressionItem[]): string => {
		return expressions.map(expression => {
			let type = this.expressionToTokenType.get(expression.type);
			let adapter = this.adapters.get(type);
			return adapter.toString(expression, this.assets);
		}).join(' ');
	}

	toSegments = (formula: string, appliedMetrics?: CustomMathMetrics): FormulaSegment[] => {
		formula = formula.replace(new RegExp('\xA0', 'g'), ' ');
		let tokenized = this.customMathTokenizerService.tokenize(formula);

		let result: FormulaSegment[] = [];
		result.pushAll(tokenized.errors.map(error => ({
				text: error.text,
				startOffset: error.startOffset,
				textTokens: [{text: error.text}],
				errors: [error.type]
		})));

		let segments = tokenized.tokens.map(mathToken => {
			let adapter = this.adapters.get(mathToken.type);
			return adapter.parseToken(mathToken, this.assets, appliedMetrics);
		});

		//whitespaces
		let noExpressionSegments = segments.filter(segment => !segment.expression);
		result.pushAll(noExpressionSegments);

		let expressionSegments = segments.filter(segment => !!segment.expression);
		let expressions = expressionSegments.map(segment => segment.expression);
		this.customMathValidationService.getInvalidExpressions(expressions, appliedMetrics.customMetrics).forEach(invalidExpression => {
			let error = CustomMathErrorType[invalidExpression.error];
			let segment = expressionSegments[invalidExpression.index];
			segment.errors = segment.errors || [];
			if (!segment.errors.contains(error)) {
				segment.errors.push(error);
			}
		});
		result.pushAll(expressionSegments);

		result = _.sortBy(result, segment => segment.startOffset);
		return result;
	}

	private populateMapping(): void {
		this.expressionToTokenType = new Map();
		this.expressionToTokenType.set(ExpressionPieces.NUMBER, CustomMathTokenType.NUMBER);
		this.expressionToTokenType.set(ExpressionPieces.SYMBOL, CustomMathTokenType.SYMBOL);
		this.expressionToTokenType.set(ExpressionPieces.ATTRIBUTE, CustomMathTokenType.ATTRIBUTE);
		this.expressionToTokenType.set(ExpressionPieces.SYSTEM_METRIC, CustomMathTokenType.SYSTEM);
		this.expressionToTokenType.set(ExpressionPieces.METRIC, CustomMathTokenType.METRIC);
		this.expressionToTokenType.set(ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC, CustomMathTokenType.HIERARCHY_METRIC);
		this.expressionToTokenType.set(ExpressionPieces.SCORECARD_STUDIO_METRIC, CustomMathTokenType.SCORECARD_METRIC);
		this.expressionToTokenType.set(ExpressionPieces.SCORECARD_NODE_METRIC, CustomMathTokenType.SCORECARD_NODE_METRIC);
	}

}
