import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricTreeItem } from '@cxstudio/metrics/metric-tree-item';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { CBSettingsService } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';

interface GroupingAssets {
	attributes: IReportAttribute[];
	wordAttributes?: IReportAttribute[];
	models: IReportModel[];
	predefinedMetrics: Metric[];
	metrics: MetricTreeItem[];
	hierarchyModels: Model[];
}

@Component({
	selector: 'report-grouping-selector',
	templateUrl: './report-grouping-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGroupingSelectorComponent implements OnInit, OnChanges {
	@Input() project: AccountOrWorkspaceProject;
	@Input() selection: ReportGrouping;
	@Input() personalizationEnabled = false;
	@Input() appliedOrgHierarchyId = null;
	@Output() selectionChange = new EventEmitter<ReportGrouping>();

	@Input() defaultSelectionPath: Partial<ReportGrouping>[];
	@Input() customDefaults: Partial<ReportGrouping>;

	@Input() cogTemplate: TemplateRef<unknown>;

	@Output() openProperties = new EventEmitter<ReportGrouping>();

	loading: Promise<any>;
	options: INode[];
	disabled: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private reportSettingsService: ReportSettingsService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private attributesService: AttributesService,
		private modelsService: ModelsService,
		private metricsService: MetricsService,
		private organizationApiService: OrganizationApiService,
		@Inject('cbSettingsService') private cbSettingsService: CBSettingsService,
		@Inject('hierarchySettingsService') private hierarchySettingsService: HierarchySettingsService
	) { }

	ngOnInit(): void {
		this.initOptions();
	}

	ngOnChanges(changes: SimpleChanges<ReportGroupingSelectorComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.initOptions();
		}
	}

	private initOptions() {
		this.options = [];
		this.disabled = true;
		this.loading = this.loadAssets().then(assets => {
			this.options = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(assets.models)
				.withAttributes(assets.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(assets.wordAttributes)
				.withMetrics(assets.metrics, this.project.projectId)
				.withOrgHierarchyModels(assets.models, assets.hierarchyModels)
				.withPredefinedGroups(ObjectUtils.copy(assets.predefinedMetrics))
				.build();
			this.cbSettingsService.initDefaultProperties(this.options, this.customDefaults);
			if (!this.selection && this.defaultSelectionPath) {
				let defaultOption = HierarchyUtils.findItem(this.options, this.defaultSelectionPath) as ReportGrouping;
				if (defaultOption) {
					this.selectOption(defaultOption);
				}
			}
			this.selectionChange.emit(this.selection);
		}).then(() => this.enable());
	}

	private loadAssets(): Promise<GroupingAssets> {
		let attributesPromise = this.attributesService.getAttributes(this.project, CacheOptions.CACHED);
		let wordAttributesPromise = this.attributesService.getWordAttributes(this.project, CacheOptions.CACHED);
		//here we need all models for now, as we compare published hierarchies to hidden models
		//see optionsBuilderProvider
		let modelsPromise = this.modelsService.getModelsWithHierarchies(this.project, CacheOptions.CACHED);
		let predefinedMetricsPromise = this.metricsService.getDynamicPredefinedMetrics(this.project, true);
		let metricsPromise = this.metricsService.getMetrics(this.project, CacheOptions.CACHED);
		let hierarchyModelsPromise = this.organizationApiService.getOrgHierarchyModels(this.project, CacheOptions.CACHED);

		return Promise.all([
			attributesPromise,
			modelsPromise,
			wordAttributesPromise,
			predefinedMetricsPromise,
			metricsPromise,
			hierarchyModelsPromise
		]).then((resultArray: any[]): GroupingAssets => {
			return {
				attributes: resultArray[0],
				models: resultArray[1],
				wordAttributes: !_.isEmpty(resultArray[2]) ? resultArray[2] : undefined,
				predefinedMetrics: resultArray[3],
				metrics: resultArray[4],
				hierarchyModels: resultArray[5]
			};
		});
	}

	selectOption(node: ReportGrouping): void {
		this.disabled = true;
		this.loading = this.reportSettingsService.populateGroupingDefaults(this.project, node)
			.then((grouping) => this.updateGrouping(grouping))
			.then(() => this.enable());
	}

	private updateGrouping(grouping: ReportGrouping): void {
		this.selection = grouping;
		this.selectionChange.emit(grouping);
	}

	private enable() {
		this.disabled = false;
		this.ref.detectChanges();
	}

	filterSelectedAttributesForGrouping(items: any): any[] {
		return this.hierarchySettingsService.filterSelectedAttributesForGrouping(items, this.personalizationEnabled, this.appliedOrgHierarchyId);
	}

}
