import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ConversationTooltipService } from '@app/modules/conversation/tooltip/conversation-tooltip.service';
import { ChatMessage } from '@cxstudio/reports/document-explorer/chat/chat-message.class';
import { ConversationChannelService } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { TranscriptPiece } from '@cxstudio/reports/entities/transcript-piece';

@Injectable({
	providedIn: 'root'
})
export class ConversationUtils {
	readonly CHAT_SILENCE_THRESHOLD_SECONDS = 600;

	constructor(
		private readonly conversationTooltip: ConversationTooltipService,
	) {}

	populateChatSentences(document: ConversationDocument): void {
		let newSentences = [];
		let chatMessages: ChatMessage[] = [];
		let transcriptPieces: TranscriptPiece[] = document.sentences as any[];

		transcriptPieces
			.filter((transcriptLine: TranscriptPiece) => !transcriptLine.isSilence)
			.forEach((transcriptLine: TranscriptPiece, index: number, transcript: TranscriptPiece[]) => {
				let isSameChannelAsLast = false;
				if (transcript[index - 1]) {
					isSameChannelAsLast = ConversationChannelService.isSameChannel(transcript[index], transcript[index - 1])
						&& !chatMessages.last().isSilence;
				}

				transcriptLine.isSameChannelAsLast = isSameChannelAsLast;

				if (isSameChannelAsLast && transcript[index].timestamp === transcript[index - 1].timestamp) {
					let lastMessage = chatMessages.last();
					lastMessage.sentences.push(transcriptLine);
					lastMessage.groupedIds.push(transcriptLine.id);
					lastMessage.sentenceTopics.pushAll(transcriptLine.sentenceTopics);
					lastMessage.sentenceTopics = _.uniq(lastMessage.sentenceTopics, false, 'id');
				} else {
					chatMessages.push({
						id: transcriptLine.id,
						groupedIds: [transcriptLine.id],
						channel: transcriptLine.channel,
						timestamp: transcriptLine.timestamp,
						sentences: [transcriptLine],
						isSameChannelAsLast: transcriptLine.isSameChannelAsLast,
						sentenceTopics: _.extend([], transcriptLine.sentenceTopics),
					});
				}
				newSentences.push(transcriptLine);
				if (transcript[index + 1]) {
					let silenceDuration = transcript[index + 1].timestamp - transcript[index].timestamp;
					if (silenceDuration >= this.CHAT_SILENCE_THRESHOLD_SECONDS) {
						let silence = {
							isSilence: true,
							timestamp: transcript[index].timestamp,
							text: this.conversationTooltip.formatDuration(silenceDuration)
						};
						newSentences.push(silence);
						chatMessages.push(silence as ChatMessage);
					}
				}
			});

		document.sentences = newSentences;
		document.chatMessages = chatMessages;
	}
}

app.service('conversationUtils', downgradeInjectable(ConversationUtils));
