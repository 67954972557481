import { Pipe, PipeTransform } from '@angular/core';
import { TokenSuggestion } from '@app/modules/metric/definition/custom-math/editor/custom-math-suggestion.class';

@Pipe({
	name: 'tokenType'
})

/**
 * Filters a list of custom metric tokens allowing only certain types
 */
export class TokenTypePipe implements PipeTransform {
	transform(items: TokenSuggestion[], allowedTypes: string[]): TokenSuggestion[] {
		if (!items || items.length < 1) return [];
		if (!allowedTypes || allowedTypes.length === 0) return items;
		
		return items.filter(item => allowedTypes.contains(item.tokenType));
	}
}