<collapsing-panel *ngIf="betaEnabled">
	<panel-heading>{{'cases.caseVisualizationHeading'|i18n}}</panel-heading>
	<panel-body [ngBusy]="loadingPromise">
		<div class="d-flex align-items-center justify-between mb-16">
			<div class="labeled-radio labeled-radio-inline">
				<input name="showCaseViz" type="checkbox" id="showCaseViz"
					[(ngModel)]="config.enabled"
					(ngModelChange)="onUpdate()">
				<label for="showCaseViz" class="cursor-pointer">{{'cases.showCaseVisualization'|i18n}}</label>
			</div>
			<button
				(click)="addCase()"
				class="btn btn-primary _t-add-case-button"
				type="button"
				[disabled]="showCaseLimitWarning()">{{'cases.addCase'|i18n}}</button>
		</div>

		<!-- <div class="mb-16 d-flex flex-direction-column w-280 d-none">
			<label class="ml-8">{{'cases.globalColor'|i18n}}</label>
			<input type="text">
		</div> -->

		<header *ngIf="config.selectedCases.length">
			<strong class="d-inline-block pl-8 mh-8 case-viz-id">{{'cases.headerCase'|i18n}}</strong>
			<strong class="d-inline-block pl-8 mr-8 case-viz-name">{{'cases.headerName'|i18n}}</strong>
			<strong class="d-inline-block pl-8 mr-8 case-viz-events">{{'cases.headerEvents'|i18n}}</strong>
			<strong class="d-inline-block pl-8 mr-8 case-viz-color">{{'cases.headerColor'|i18n}}</strong>
			<strong class="d-inline-block pl-8 mr-8 case-viz-date">{{'cases.headerDate'|i18n}}</strong>
		</header>
		<ng-template #caseItem let-item="result">
			<ul class="pl-0 mb-0">
				<li class="d-flex align-items-center ph-8 cursor-pointer justify-between">
					<div>
						<span class="q-icon q-icon-clipboard mr-4" aria-hidden="true"></span>
						{{getCaseOptionTitle(item)}}
					</div>
					<div>
						<a href="{{item.permalink}}" title="{{'cases.openPermalink'|i18n}}"
							class="q-icon q-icon-link ml-8" target="_blank"></a>
					</div>
				</li>
			</ul>
		</ng-template>
		<div *ngFor="let case of config.selectedCases; index as $index"
			class="_t-case-configuration-row border-1 border-solid border-gray-400 border-radius-2 mb-8 d-flex p-8 align-items-center">
			<input class="border-1 border-solid border-gray-400 case-viz-id d-flex flex-fixed mr-8 align-items-center text-ellipsis overflow-hidden border-radius-2"
				id="typeahead-template" type="search"
				placeholder="{{'cases.caseTypeahead'|i18n}}"
				(focus)="onFocus($event)"
				[ngModel]="config.selectedCases[$index]"
				(ngModelChange)="setCase(case, $event)"
				[inputFormatter]="getCaseTitle()"
				[ngbTypeahead]="loadEngagorCases" [resultTemplate]="caseItem"
				[editable]='false'/>

			<input name="name" class="case-viz-name mr-8" type="text" [(ngModel)]="case.displayName" placeholder="{{'cases.headerName'|i18n}}" aria-autocomplete="none">

			<checkbox-dropdown
				class="h-32 case-viz-events mr-8"
				[selection]="case.events"
				(selectionChange)="updateEvents(case, $event)"
				[options]="availableEvents"
				[truncateSelections]="true">
			</checkbox-dropdown>

			<color-input
				ngDefaultControl
				class="case-viz-color mr-8"
				[(ngModel)]="case.color">
			</color-input>

			<div class="d-flex align-items-center mr-8">
				<input
					name="fromDate"
					type="text"
					class="text-1rem case-viz-date"
					placeholder="{{'reportFilters.fromDateLabel'|i18n}}"
					value="{{case.date.from|date:'shortDate'}}"
					readonly>
				<span class="mh-8">{{'reportFilters.to'|i18n}}</span>
				<input
					name="toDate"
					type="text"
					placeholder="{{'reportFilters.toDateLabel'|i18n}}"
					class="text-1rem case-viz-date"
					value="{{case.date.to|date:'shortDate'}}"
					*ngIf="case.date.to !== null"
					readonly>
				<input
					name="toDate"
					class="text-1rem case-viz-date"
					*ngIf="case.date.to === null"
					value="{{'schedule.now'|i18n}}"
					readonly>

			</div>

			<button
				(click)="removeCase($index)"
				type="button"
				class="btn btn-link btn-icon btn-secondary _t-remove-case-button">
				<span [attr.aria-label]="'common.remove'|i18n" class="q-icon q-icon-trash"></span>
			</button>
		</div>

		<alert type="warning" *ngIf="showCaseLimitWarning()" text="{{'cases.caseLimitReached'|i18n}}"></alert>

	</panel-body>
</collapsing-panel>
