import { PropertiesChanges } from '@app/modules/project/state-changes/properties-changes.class';
import { PropertyValue } from '@app/modules/project/state-changes/property-changes';

export enum SettingsProperty {
	USE_IN_CLARABRIDGE_SEARCH = 'useInClarabridgeSearch',
	SHOW_IN_DOC_EXPLORER =  'showInDocExp'
}

// delete after  NEW_PROJECTS_PAGE goes GA
export class SettingsState {
	static readonly ENABLED_FOR_SEARCH_LIMIT: number = 10;

	private settingsChanges: PropertiesChanges;
	private enabledForSearchCount: {
		initial: number;
		current: number;
	};

	constructor() {
		this.settingsChanges = new PropertiesChanges();
		this.init(0);
	}

	init(enabledForSearch: number) {
		this.enabledForSearchCount = { initial: enabledForSearch, current: enabledForSearch };
	}

	addChange(object, property: SettingsProperty, newValue): void {
		this.settingsChanges.addChange(object, property, newValue);
		if (property === SettingsProperty.USE_IN_CLARABRIDGE_SEARCH) {
			let diff = newValue ? 1 : -1;
			this.enabledForSearchCount.current += diff;
		}
	}

	getPropertyChanges<T>(property: SettingsProperty): PropertyValue<T>[] {
		return this.settingsChanges.getPropertyChanges<T>(property);
	}

	getPropertyInitial<T>(property: SettingsProperty): PropertyValue<T>[] {
		return this.settingsChanges.getPropertyInitial<T>(property);
	}

	canAddEnabledForSearch(): boolean {
		return this.enabledForSearchCount.current < SettingsState.ENABLED_FOR_SEARCH_LIMIT;
	}

	getEnabledForSearchCount(): number {
		return this.enabledForSearchCount.current;
	}

	hasChanges(): boolean {
		return this.settingsChanges.hasChanges();
	}

	reset(): void {
		this.settingsChanges.reset();
		this.enabledForSearchCount.current = this.enabledForSearchCount.initial;
	}

	save(): void {
		this.enabledForSearchCount.initial = this.enabledForSearchCount.current;
		this.settingsChanges.reset();
	}
}
