import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';
import { ContentVisualizationBaseComponent } from '@app/modules/widget-visualizations/content-widgets/content-visualization-base';
import { MarginType } from '@cxstudio/reports/entities/content-widget-properties';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';

@Component({
	selector: 'content-label',
	template: `
		<div class="view-label font-x-small-scale kb-focusable-border w-100-percent h-100-percent"
			[class.newTextFormat]="widget.properties.newTextFormat"
			[style.background]="getBackground()"
			[ngStyle]="getBorderStyles()"
			tabindex="0">
			<div *ngIf="!!html" #textContainer [innerHTML]="html | sanitize | safeHTML"></div>
			<empty-content *ngIf="empty" icon="q-icon-text-2"></empty-content>
		</div>
	`,
	styles: [`
		.view-label {
			border-style: solid;
			border-width: 1px;
		}
		.view-label p {
			padding: 10px 20px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentLabelComponent extends ContentVisualizationBaseComponent implements OnInit, AfterViewInit {

	html: string;

	@ViewChild('textContainer') textContainer: ElementRef<HTMLElement>;

	constructor(
		readonly ref: ChangeDetectorRef,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
		private readonly textWidgetUtils: TextWidgetUtilsService,
		private applicationThemeService: ApplicationThemeService,
	) {
		super(ref, reportUtils);
	}

	ngOnInit(): void {
		let props = this.widget.properties;
		if (!this.dataObject.text) {
			this.showEmpty();
		} else {
			if (!props.margins) props.margins = MarginType.NORMAL;
			this.html = this.dataObject.text;
		}
	}

	ngAfterViewInit(): void {
		if (!this.isNewTextFormat()) {
			this.textWidgetUtils.processFontSize(this.textContainer.nativeElement);
		}
		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
	}

	isNewTextFormat(): boolean {
		return this.widget.properties.newTextFormat;
	}

	getBackground(): string {
		let props = this.widget.properties;
		let background = props.background ? props.background : 'none';
		if (background === '#ffffff' && this.applicationThemeService.isShowingDashboardDarkTheme()) {
			background = '';
		}
		return background;
	}

	getBorderStyles(): any {
		return this.textWidgetUtils.getBorderStyles(this.widget.properties);
	}
}
