app.factory('localeInterceptor', ($cacheFactory) => {
	return {
		request: (config) => {
			if (isLocaleCall(config.url)) {
				config.cache = $cacheFactory.get('locale');
			}

			return config;
		}
	};

	function isLocaleCall(url): boolean {
		return url.endsWith('.lang.json');
	}
});
