import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, Directive } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, NgForm } from '@angular/forms';
import { EXCLUDED_PROPERTY_NAMES, OAuthClientDetailsAdditionalInfoChangedEvent } from './partials/oauth-client-details-additional-information.component';
import { IOAuthAuthorizedGrantType, OAuthAuthorizedGrantTypes } from './oauth-authorized-grant-type.factory';
import { OAuthAuthority, OAuthAuthorityRole, OAuthAuthorityRoles } from './oauth-authority-roles.factory';
import { Subscription } from 'rxjs';
import { OAuthClientDetailsDesignerScopeChangedEvent } from './partials/oauth-client-details-designer-scope.component';
import OAuthClientDetails from './oauth-client-details';
import OAuthScope from './oauth-scope';
import { OauthClientDetailsApplicationKind } from './oauth-client-details-application-kind';
import { CxLocaleService } from '@app/core';
import { OAuthClientDetailsUnifiedLinksChangedEvent } from './partials/oauth-client-details-unified-links.component';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';


export enum OAuthClientDialogMode {
	CREATE = 'CREATE',
	EDIT = 'EDIT'
}

const OPENID_SCOPE: string = 'openid';

export interface OAuthClientDetailsEditDialogInput {
	clientDetails: OAuthClientDetails;
	masterAccounts: Array<MasterAccount>;
	mode: OAuthClientDialogMode;
}

@Directive()
export abstract class OAuthClientDetailsEditDialogComponent implements OnInit, OnDestroy {

	private readonly DEFAULT_TOKEN_TTL = 3500;
	private readonly DEFAULT_REFRESH_TTL = 2592000;

	@Input() input: OAuthClientDetailsEditDialogInput;
	@ViewChild('clientDetailsDialog') public clientDetailsDialog: NgForm;

	clientDetails: OAuthClientDetails;
	masterAccounts: Array<MasterAccount>;
	mode: OAuthClientDialogMode;

	grantTypes: IOAuthAuthorizedGrantType[];
	selectedGrantTypes: any;

	authorityOptions: OAuthAuthority[];
	selectedAuthorities: Map<OAuthAuthorityRole, boolean>;

	additionalInformationValid: boolean = true;
	designerScopeValid: boolean = true;
	unifiedLinksValid: boolean = true;

	oauthScopes: string[];
	registeredRedirectUri: string[];

	openIdCheckSubscription: Subscription;

	supportDesignerScope: boolean;
	applicationKind: OauthClientDetailsApplicationKind;

	loading: Promise<any>;

	protected abstract disableSave(): void;
	protected abstract save(): void;
	protected abstract init(): void;

	constructor(protected modal: NgbActiveModal,
		protected ref: ChangeDetectorRef,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected oauthAuthorityRoles: OAuthAuthorityRoles,
		private readonly locale: CxLocaleService,
		applicationKind: OauthClientDetailsApplicationKind
	) {
		this.applicationKind = applicationKind;
	}

	ngOnDestroy(): void {
		if (!_.isUndefined(this.openIdCheckSubscription)) {
			this.openIdCheckSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		this.clientDetails = cloneDeep(this.input.clientDetails);
		this.masterAccounts = this.input.masterAccounts;
		this.mode = this.input.mode;
		this.clientDetails.additionalInformation = this.clientDetails.additionalInformation || {} as any;
		this.clientDetails.applicationKind = this.applicationKind;
		this.clientDetails.accessTokenValiditySeconds = _.isUndefined(this.clientDetails.accessTokenValiditySeconds) ?
			this.DEFAULT_TOKEN_TTL : this.clientDetails.accessTokenValiditySeconds;
		this.clientDetails.refreshTokenValiditySeconds = _.isUndefined(this.clientDetails.refreshTokenValiditySeconds) ?
			this.DEFAULT_REFRESH_TTL : this.clientDetails.refreshTokenValiditySeconds;
		this.init();
	}

	protected initAuthorities(): void {
		this.authorityOptions = this.oauthAuthorityRoles.getRoles();
		this.selectedAuthorities = new Map<OAuthAuthorityRole, boolean>();
		this.clientDetails.authorities?.forEach(authority => {
			this.selectedAuthorities[authority] = true;
		});
	}

	protected processSelectedAuthoritiesOnSave = (): void => {
		if (_.isUndefined(this.selectedAuthorities)) {
			return;
		}
		this.clientDetails.authorities = [];
		Object.keys(this.selectedAuthorities).forEach((key) => {
			if (this.selectedAuthorities[key]) {
				this.clientDetails.authorities.push(key as OAuthAuthorityRole);
			}
		});
	}

	protected initGrantTypes = (): void => {
		this.grantTypes = this.oauthAuthorizedGrantTypes.getAllGrantTypes();
		this.selectedGrantTypes = {};
		(this.clientDetails.authorizedGrantTypes || []).forEach(grantType => {
			this.selectedGrantTypes[grantType] = true;
		});
	}

	updateUnifiedLinks = (event: OAuthClientDetailsUnifiedLinksChangedEvent): void => {
		if (event.unifiedLinksValid) {
			this.clientDetails.additionalInformation.enableUnifiedLinks = event.unifiedLinksEnabled;
			this.clientDetails.additionalInformation.unifiedLinks = event.unifiedLinks;
		}
		this.clientDetails.additionalInformation.unifiedLinksVisibility = event.unifiedLinksVisibility;
		this.unifiedLinksValid = event.unifiedLinksValid;
	}

	updateDesignerScope = (event: OAuthClientDetailsDesignerScopeChangedEvent): void => {
		if (event.designerScopeValid) {
			// checking if initial configuration is openid
			const isOpenIdClientBeforeChange: boolean = this.isOpenidClient();
			this.clientDetails.scope = event.scope;
			this.clientDetails.autoApproveScopes = event.autoApprovedScope;
			// checking if updated configuration is openid
			const isOpenIdClientAfterChange: boolean = this.isOpenidClient();
			// if openid removed, do some cleanup
			if (isOpenIdClientBeforeChange && !isOpenIdClientAfterChange) {
				this.handleRemovedOpenIdScope();
			}
		}
		this.designerScopeValid = event.designerScopeValid;
	}

	updateAdditionalInformation = (event: OAuthClientDetailsAdditionalInfoChangedEvent): void => {
		// we need to be sure that additionalProperties NOT managed by OauthClientDetailsAdditionalInformationComponent
		// are preserved
		EXCLUDED_PROPERTY_NAMES.forEach((propertyName: string) => {
			if (!_.isUndefined(this.clientDetails.additionalInformation)) {
				event.additionalInformation[propertyName] = this.clientDetails.additionalInformation[propertyName];
			}
		});

		this.clientDetails.additionalInformation = event.additionalInformation;
		this.additionalInformationValid = event.additionalInformationValid;
	}

	isEditMode = (): boolean => {
		return this.mode === OAuthClientDialogMode.EDIT;
	}

	cancel = (): void => {
		this.modal.dismiss();
	}

	clearJwtSecret = (): void => {
		if (!this.isOpenidClient() && this.clientDetails.additionalInformation.jwtSecretKey) {
			delete this.clientDetails.additionalInformation.jwtSecretKey;
		}
	}

	// handling openid "designer scope" removed (designer scope component)
	private readonly handleRemovedOpenIdScope = (): void => {
		this.openIdCheckSubscription
			= this.clientDetailsDialog.valueChanges.subscribe(this.openIdTagRemovedObserver);
	}

	// handling openid tag removed (tag input component)
	checkRemovedTag = (tag: string): void => {
		if (tag === OPENID_SCOPE) {
			this.openIdCheckSubscription
				= this.clientDetailsDialog.valueChanges.subscribe(this.openIdTagRemovedObserver);
		}
	}

	// clear jwtSecretKey input validation if openid tag removed.
	private readonly openIdTagRemovedObserver = (value: any): void => {
		let input: FormControl = this.clientDetailsDialog.form.get('jwtSecretKey') as FormControl;
		if (_.isNull(input)) {
			this.openIdCheckSubscription.unsubscribe();
			this.ref.markForCheck();
		}
	}

	protected processSelectedGrantTypesOnSave = (): void => {
		this.clientDetails.authorizedGrantTypes = [];
		Object.keys(this.selectedGrantTypes).forEach((key) => {
			if (this.selectedGrantTypes[key]) {
				this.clientDetails.authorizedGrantTypes.push(key);
			}
		});
	}

	protected processScopeOnSave = (): void => {
		if (this.supportDesignerScope) {
			return;
		}
		this.clientDetails.scope = [];
		this.oauthScopes.forEach(scopeName => {
			const newScope = new OAuthScope();
			newScope.name = scopeName;
			newScope.authorizedGrantTypes = [];
			this.clientDetails.scope.push(newScope);
		});
		this.clientDetails.autoApproveScopes = this.oauthScopes;
	}

	scopeSelected(): boolean {
		if (this.supportDesignerScope) {
			return !_.isUndefined(this.clientDetails.scope) && this.clientDetails.scope.length > 0;
		}
		return this.oauthScopes.length > 0;
	}

	grantTypeSelected = (): boolean => {
		return !_.isEmpty(_.filter(Object.values(this.selectedGrantTypes), (grantType: any) => grantType));
	}

	isOpenidClient = (): boolean => {
		if (this.supportDesignerScope) {
			return _.some(this.clientDetails.scope, (scope: OAuthScope) => {
				return scope.name === OPENID_SCOPE;
			});
		}
		return _.some(this.oauthScopes, (scope: string) => {
			return scope === OPENID_SCOPE;
		});
	}

	protected disableSaveCommon = (): boolean => {
		if (_.isUndefined(this.clientDetailsDialog)) {
			return true;
		}

		if (this.supportDesignerScope && !this.designerScopeValid) {
			return true;
		}

		if (this.clientDetails.additionalInformation.parentFolderEnabled &&
			!this.clientDetails.additionalInformation.parentFolderName) {
				return true;
		}

		return !this.grantTypeSelected() || !this.scopeSelected() || !this.clientDetailsDialog.valid
			|| !this.additionalInformationValid || !this.unifiedLinksValid;
	}

	protected saveCommon = (): void => {
		this.clientDetails.applicationKind = this.applicationKind;
		this.processSelectedGrantTypesOnSave();
		this.processSelectedAuthoritiesOnSave();
		this.clearJwtSecret();
		this.processScopeOnSave();
	}

	getModalTitle = (): string => {
		switch (this.applicationKind) {
			case OauthClientDetailsApplicationKind.DESIGNER: {
				return this.locale.getString('administration.oauthEditDesignerClientDetailsDialogTitle');
			}
			case OauthClientDetailsApplicationKind.CB_LINK: {
				return this.locale.getString('administration.oauthEditLinkClientDetailsDialogTitle');
			}
			case OauthClientDetailsApplicationKind.STUDIO: {
				return this.locale.getString('administration.oauthEditStudioClientDetailsDialogTitle');
			}
			case OauthClientDetailsApplicationKind.CX_SUITE: {
				return this.locale.getString('administration.oauthEditSuiteClientDetailsDialogTitle');
			}
			default: {
				return this.locale.getString('administration.edit');
			}
		}
	}

	showLegacyClientId = (): boolean => {
		return this.applicationKind === OauthClientDetailsApplicationKind.DESIGNER;
	}

	showRegisteredRedirectUri = (): boolean => {
		return this.applicationKind !== OauthClientDetailsApplicationKind.CB_LINK;
	}

	registeredRedirectUriRequired = (): boolean => {
		return this.applicationKind !== OauthClientDetailsApplicationKind.CX_SUITE;
	}

	showOauthAdminAccess = (): boolean => {
		return this.isStudioClient();
	}

	showAuthorities = (): boolean => {
		return this.applicationKind !== OauthClientDetailsApplicationKind.CB_LINK;
	}

	showUnifiedLinks = (): boolean => {
		return this.applicationKind === OauthClientDetailsApplicationKind.CX_SUITE;
	}

	isLegacySuiteApplication = (): boolean => {
		return false;
	}

	isStudioClient = (): boolean => {
		return this.applicationKind === OauthClientDetailsApplicationKind.STUDIO;
	}

}
