import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';

export class HeatmapWidget extends SimpleReportWidget {

	constructor(private metricConstants: MetricConstants) {
		super(WidgetType.HEATMAP);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initSimpleReport(context);

		this.widget.visualProperties.visualization = WidgetVisualization.HEATMAP;

		super.withLegendOptions(context, [
			{name: 'showLabels', default: true},
			{name: 'showLegend', default: false}
		]);

		super.initGroupings(context, 3, false);
		super.initSingleCalculation(context, this.metricConstants.get().VOLUME);
	}

}
