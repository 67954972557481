export class IntelligentScoring {
	private intelligentScoringMode: boolean;

	changeMode = (enabled: boolean) => {
		this.intelligentScoringMode = enabled;
	}

	isIntelligentScoringMode = (): boolean => {
		return this.intelligentScoringMode;
	}
}