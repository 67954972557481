import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { OrderByPipe } from 'ngx-pipes';

@Injectable({
	providedIn: 'root'
})
export class GridUtilsService {

	constructor(
		private readonly orderBy: OrderByPipe
	) {}

	private getTargets = (event: Event) => {
		let target = $(event.target);
		let child = target.children().first();
		return { target, child };
	}

	replaceHTML = (value: string): string | undefined => {
		return value && value.replace(/'/g, '&#39;').replace(/"/g, '&quot;')
			.replace(/>/g, '&gt;').replace(/</g, '&lt;');
	}

	processItemsTree = (items: any[], sort = true, sortField = 'name'): any[] => {
		let tree = TreeListTransformUtils.tree(items);
		if (sort) {
			tree = this.orderBy.transform(tree, sortField);
		}
		return TreeListTransformUtils.flat(tree);
	}

	isFolder = (item: ITreeItem): boolean => {
		return /.*folder.*/i.test(item.type);
	}

	isMenuClick = (event: Event): boolean => {
		let { target, child } = this.getTargets(event);
		return target.hasClass('q-icon-layer') || target.hasClass('burger-wrap') || child.hasClass('burger-wrap');
	}

	isNameClick = (event: Event): boolean => {
		let target = $(event.target);
		return target.hasClass('IS_NAME_COLUMN') || target.hasClass('br-item-name');
	}

	isToggleClick = (event: Event): boolean => {
		let target = $(event.target);
		return target.parents('.toggle-switch').length > 0;
	}

	isToggleDisabled = (event: Event): boolean => {
		let target = $(event.target);
		return target.parents('.toggle-switch.disabled').length > 0;
	}

	isColumnClick = (event: Event, className: string): boolean => {
		const selector = `.${className}`;
		const target = $(event.target);
		return target.parent(selector).length > 0;
	}

	isDocumentClick = (event: Event): boolean => {
		let target = $(event.target);
		return target.hasClass('q-icon-document') || target.hasClass('auditRecordDetails')
			|| target.children('.q-icon-document').length > 0;
	}

	isBulkCheckbox = (event: Event): boolean => {
		let { target, child } = this.getTargets(event);
		return target.hasClass('br-bulk-actions-check') || child.hasClass('br-bulk-actions-check');
	}

	isFavoriteStar = (event: Event): boolean => {
		let { target, child } = this.getTargets(event);
		return target.hasClass('br-favorite-star') || child.hasClass('br-favorite-star');
	}

	isRating = (event: Event): boolean => {
		let { target, child } = this.getTargets(event);
		return target.hasClass('br-rating-bar') || target.hasClass('br-rating-star') || child.hasClass('br-rating-bar');
	}

	isHeaderCheckbox = (event: Event): boolean => {
		return $(event.target).hasClass('header-checkbox');
	}

	isCheckboxClick = (event: Event, className: string): boolean => {
		let target = $(event.target);
		return target.parent().hasClass(className);
	}

	isElementClick = (event: Event, className: string): boolean => {
		let target = $(event.target);
		return target.hasClass(className) || target.parents(`.${className}`).length > 0;
	}

	isTargetHasChild = (event: Event, className: string): boolean => {
		return !!((event.target as Element).querySelector(`.${className}`));
	}

	isColumnHidden = (column, selectedColumns): boolean => {
		if (!column.optional || !selectedColumns?.length) return false;

		const hiddenColumns = _.filter(selectedColumns, (item) => !item.selected);

		return !!_.findWhere(hiddenColumns, {name: column.persistedName || column.field});
	}
}

app.service('gridUtils', downgradeInjectable(GridUtilsService));
