import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '@app/shared/shared.module';
import { AdditionalDrill } from '@app/modules/reports/utils/context-menu/drill/drill-options/additional-drill.service';
import { WordsDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/words-drill.service';
import { FormsModule } from '@angular/forms';
import { StatsTableService } from './utils/stats-table.service';
import { CommonDrillService } from './utils/context-menu/drill/common-drill.service';
import { DrillToBarProcessorService } from './utils/context-menu/drill/drill-to/drill-to-bar-processor.service';
import { DrillToCloudProcessorService } from './utils/context-menu/drill/drill-to/drill-to-cloud-processor.service';
import { DrillToHeatmapProcessorService } from './utils/context-menu/drill/drill-to/drill-to-heatmap-processor.service';
import { DrillToLineProcessorService } from './utils/context-menu/drill/drill-to/drill-to-line-processor.service';
import { DrillToMetricProcessorService } from './utils/context-menu/drill/drill-to/drill-to-metric-processor.service';
import { DrillToPieProcessorService } from './utils/context-menu/drill/drill-to/drill-to-pie-processor.service';
import { DrillToScatterProcessorService } from './utils/context-menu/drill/drill-to/drill-to-scatter-processor.service';
import { DrillToTableProcessorService } from './utils/context-menu/drill/drill-to/drill-to-table-processor.service';
import { DrillServiceDowngrades } from './utils/context-menu/drill/drill-to/drill-service-downgrades';
import { RealDataPreviewService } from './real-data-preview/real-data-preview.service';
import { ReportRunHelperService } from './utils/report-run-helper/report-run-helper.service';
import { WidgetVisualizationsModule } from '../widget-visualizations/widget-visualizations.module';
import { MetricRealDataPreviewComponent } from './real-data-preview/metric-real-data-preview/metric-real-data-preview.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { WidgetSettingsModule } from '@app/modules/widget-settings/widget-settings.module';
import { PlotLinesModule } from '@app/modules/plot-lines/plot-line.module';
import { TopicOrTermsToFilterService } from './utils/context-menu/drill/group-by/topic-or-terms-to-filter/topic-or-terms-to-filter.service';
import { AttributeToFilterService } from '@app/modules/reports/utils/context-menu/drill/point-to-filter/attribute-to-filter.service';
import { CustomGroupToFilterService } from '@app/modules/reports/utils/context-menu/drill/point-to-filter/custom-group-to-filter.service';
import { WidgetTextFilterProcessor } from '@app/modules/reports/filters/widget-text-filter-processor.service';
import { FilterUiUtilService } from './filters/filter-ui-util.service';
import { PreviewDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/preview-drill.service';
import {
	KeyTermsDrillService
} from '@app/modules/reports/utils/context-menu/drill/drill-options/key-terms-drill.service';
import {
	HierarchyChildrenDrillService
} from '@app/modules/reports/utils/context-menu/drill/drill-options/hierarchy-children-drill.service';
import {
	DashboardDrillService
} from '@app/modules/reports/utils/context-menu/drill/drill-options/dashboard-drill.service';
import {
	MetricAlertDrillService
} from '@app/modules/reports/utils/context-menu/drill/drill-options/metric-alert-drill.service';
import { RecolorDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/recolor-drill.service';
import { WidgetUtilsResourcesService } from './utils/widget-utils-resources.service';
import { WidgetDrillActionsService } from './utils/context-menu/drill/widget-drill-actions.service';
import { QuickFilterService } from './filters/quick-filter.service';
import { WidgetDrillMenuService } from './utils/context-menu/drill/widget-drill-menu.service';
import { HighchartsStackedUtilService } from '@app/modules/reports/utils/highcharts-stacked-util.service';
import { MapboxUtilsService } from '@app/modules/reports/visualizations/definitions/mapbox/mapbox-utils.service';
import { MapboxBuilderService } from '@app/modules/reports/visualizations/definitions/mapbox/mapbox-builder.service';
import { StatsModeService } from '@app/modules/reports/utils/stats-mode.service';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { PredefinedMetricToFilterService } from './utils/context-menu/drill/point-to-filter/predefined-metric-to-filter.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		NgPipesModule,
		FormsModule,
		WidgetVisualizationsModule,
		DialogModule,
		WidgetSettingsModule,
		PlotLinesModule,
		WidgetContainerModule
	],
	providers: [
		AdditionalDrill,
		ReportRunHelperService,
		RealDataPreviewService,
		StatsTableService,
		CommonDrillService,
		DrillToBarProcessorService,
		DrillToLineProcessorService,
		DrillToCloudProcessorService,
		DrillToTableProcessorService,
		DrillToHeatmapProcessorService,
		DrillToMetricProcessorService,
		DrillToScatterProcessorService,
		DrillToPieProcessorService,
		DrillServiceDowngrades,
		TopicOrTermsToFilterService,
		AttributeToFilterService,
		CustomGroupToFilterService,
		WordsDrillService,
		WidgetUtilsResourcesService,
		WidgetTextFilterProcessor,
		FilterUiUtilService,
		PreviewDrillService,
		WidgetDrillActionsService,
		MetricAlertDrillService,
		KeyTermsDrillService,
		HierarchyChildrenDrillService,
		RecolorDrillService,
		DashboardDrillService,
		QuickFilterService,
		WidgetDrillMenuService,
		HighchartsStackedUtilService,
		MapboxUtilsService,
		MapboxBuilderService,
		StatsModeService,
		PredefinedMetricToFilterService
	],
	declarations: [
		MetricRealDataPreviewComponent,
	],
})

export class ReportsModule {}
