import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SharingRequestStatus } from '@app/modules/access-management/entities/sharing-request-status';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { SharingService, SharingParams } from '@cxstudio/sharing/sharing-service.service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { SharingUser } from '@cxstudio/sharing/access-management/entities/sharing-user';
import { SharingRequestApi } from '@app/modules/access-management/api/sharing-request-api.service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import RequestAccessMessageRequest from '@app/modules/access-management/services/requests/request-access-message-request';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { PromiseUtils } from '@app/util/promise-utils';

export enum RequestAccessReason {
	alreadyShared = 'alreadyShared',
}

export enum RequestAccessResult {
	redirectToPreview = 'redirectToPreview'
}

@Injectable({
	providedIn: 'root'
})
export class RequestAccessService {

	constructor(
		private sharingRequestApi: SharingRequestApi,
		@Inject('dashboardService') private dashboardService: DashboardService,
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
		@Inject('sharingService') private sharingService: SharingService,
		@Inject('filterManagementApiService') private filterManagementApiService: FilterManagementApiService,
		private locale: CxLocaleService
	) {}

	handleRequestAccess(objectType: ObjectType, objectId: number, messageToSend?: string): Promise<any> {
		return this.sharingRequestApi.getRequestAccessData(objectType, objectId).then(hasAccess => {
			if (hasAccess) {
				return Promise.reject(RequestAccessReason.alreadyShared);
			}

			return this.sendRequest(objectType, objectId, messageToSend);
		}, (response: ng.IHttpPromiseCallbackArg<string>) => {
			return this.processSharingStatus(response, objectType);
		});
	}

	private sendRequest(objectType: ObjectType, objectId: number, message?: string): Promise<void> {
		const request: RequestAccessMessageRequest = {
			requestAccessMessage: message
		};

		return this.sharingRequestApi.sendSharingRequest(objectType, objectId, request);
	}

	protected processSharingStatus(response: ng.IHttpPromiseCallbackArg<string>, objectType: ObjectType): Promise<any> {
		switch (response.status) {
			case SharingRequestStatus.ALREADY_SHARED: {
				return Promise.reject(RequestAccessReason.alreadyShared);
			}
			case SharingRequestStatus.FORBIDDEN: {
				return Promise.reject(this.locale.getString('sharing.requestAccessForbidden', {
					objectType: this.getObjectTypeLabel(objectType)
				}));
			}
			case SharingRequestStatus.NOT_EXIST: {
				return Promise.reject(this.locale.getString('sharing.notExist', {
					objectType: this.getObjectTypeLabel(objectType)
				}));
			}
			case SharingRequestStatus.INVALID: {
				return Promise.reject(this.locale.getString('sharing.' + response.data));
			}
			default: {
				return Promise.reject(this.locale.getString('error.internal_error'));
			}
		}
	}

	getDialogTitle(objectType: string): string {
		return this.locale.getString('sharing.requestAccessToObjectHeader', {
			objectType: this.getObjectTypeLabel(objectType).toLowerCase()
		});
	}

	getObjectTypeLabel(objectType: string): string {
		if (objectType === DashboardType.BOOK)
			objectType = 'book';
		return this.locale.getString(`object.${objectType}`);
	}

	getRequestedAccessOfOwnerMessage(objectType: string): string {
		return this.locale.getString('sharing.requestedAccessOfOwner', {
			objectType: this.getObjectTypeLabel(objectType).toLowerCase()
		});
	}

	handleGrantAccess(objectType: ObjectType, objectId: number, userId: number): Promise<RequestAccessResult | void> {
		return this.sharingRequestApi.getGrantAccessData(objectType, objectId, userId).then(data => {
			return this.shareObject(objectType, data.object, data.user);
		}, (response: ng.IHttpPromiseCallbackArg<string>) => {
			return this.processSharingStatus(response, objectType);
		});
	}

	private shareObject(objectType: ObjectType, object: any, user: SharingUser): Promise<RequestAccessResult | void> {
		switch (objectType) {
			case ObjectType.DASHBOARD: return PromiseUtils.wrap(this.dashboardService.shareDashboardToUser(object, user,
				modal => modal.close({$value: RequestAccessResult.redirectToPreview})));
			case ObjectType.METRIC: return this.shareMetric(object, user);
			case ObjectType.FILTER: return this.shareFilter(object, user);
		}

	}

	private shareMetric(metric: any, user: SharingUser): Promise<void> {
		let metrics = [metric];
		let modalTitle = this.locale.getString('metrics.shareTitle', {name: metric.displayName});

		let params: SharingParams = {
			modalTitle,
			updateReshare: (object: any, reshareAllowed: boolean) => {
				object.reshareAllowed = reshareAllowed;
				this.metricApiService.updateMetricReshareFlag(object.id, reshareAllowed);
			},
			share: this.metricApiService.shareMetrics,
			getAsset: (id) => this.metricApiService.getMetric(id, false).then(resp => resp.data),
			refreshGrid: _.noop,
			shareToUser: user,
			component: 'metricSharing',
			objectTypePlural: 'metrics',
		};

		return Promise.resolve(this.sharingService.shareWithDialog(metrics, params) as any); // ignore type until we convert service
	}

	private shareFilter(filter: any, user: SharingUser): Promise<void> {
		let filters = [filter];
		let modalTitle = this.locale.getString('reportFilters.shareTitle', {name: filter.name});

		let params: SharingParams = {
			modalTitle,
			updateReshare: (object: any, reshareAllowed: boolean) => {
				object.reshareAllowed = reshareAllowed;
				this.filterManagementApiService.updateFilterReshareFlag(object.id, reshareAllowed);
			},
			share: this.filterManagementApiService.shareFilters,
			getAsset: (id) => this.filterManagementApiService.getFilter(id),
			refreshGrid: _.noop,
			shareToUser: user,
			component: 'filterSharing',
			objectTypePlural: 'filters',
		};

		return Promise.resolve(this.sharingService.shareWithDialog(filters, params) as any); // ignore type until we convert service
	}
}

app.factory('requestAccessService', downgradeInjectable(RequestAccessService));
