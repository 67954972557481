import { Pagination } from '@app/shared/components/pagination/pagination';
import { HttpParams } from '@angular/common/http';
import { PaginationHttpParams } from '@app/shared/components/pagination/pagination-http-params';
import MasterAccountSearch from '@app/modules/system-administration/master-account/entities/master-account-search';

export class MasterAccountQuery {
	filterText: string = '';
	filterField: string = '';
	
	constructor(search: MasterAccountSearch, private pagination: Pagination) {
		this.filterText = search.filterText;
		this.filterField = search.filterField;
	}

	toHttpParams(): HttpParams {
		let params: HttpParams = PaginationHttpParams.of(this.pagination);

		params = params.set('filterField', this.filterField);
		params = params.set('filterText', this.filterText);

		return params;
	}
}