<table class="table br-list-box">
	<thead>
		<tr>
			<th *ngFor="let column of userColumns">
				<user-column
					[column]="column"
					(onChange)="onSearchChange()"
				></user-column>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let user of visibleUserData" class="br-user-row">
			<td scope="row" class="br-user-actions">
				<div class="d-flex align-items-center">
					<input
						type="checkbox"
						[hidden]="!showHamburgerMenu()"
						[checked]="user.selected"
						(click)="toggleSelection(user)"
						class="br-user-select">
					<a class="burger-wrap action-hover-border q-icon q-icon-layer action-hover-text" [hidden]="!showHamburgerMenu()" (click)="onMenuClick.emit({$user:user, $event: $event})"></a>
				</div>
			</td>
			<td class="br-user-firstName" title="{{ user.firstName }}">{{ user.firstName }}</td>
			<td class="br-user-lastName" title="{{ user.lastName }}">{{ user.lastName }}</td>
			<td class="br-user-email" title="{{ user.userEmail }}">{{ user.userEmail }}</td>
			<td class="br-user-loginDate">{{ user.lastLoginDate | userTimeFormat }}</td>
			<td class="br-user-accessExpiration"
				*ngIf="showAccessExpiration">
				{{ getAccessExpirationMessage(user) }}
			</td>
			<td *ngIf="showAccessExpiration">
				<div class="br-user-expiration-menu d-inline-block">
					<a href="javascript:void(0)" *ngIf="userCanExpire(user)" class="br-dashboard-burger-wrap item-owner" (click)="showUserExpirationMenu($event, user)">
						<i class="q-icon q-icon-layer br-dashboard-burger br-menu-burger" (click)="showUserExpirationMenu($event, user)"></i>
					</a>
				</div>
			</td>

			<td class="br-user-licenseTypeName">{{ user.licenseTypeName }}</td>
			<td class="br-user-custom-field" *ngIf="showCustomField">{{ user.customField }}</td>
		</tr>
	</tbody>
</table>
