<div class="multiselect">
	<div ngbDropdown #ngDropdown
		class="dropdown fake-select"
		autoClose="outside"
		[attr.disabled]="isDisabled && isDisabled()"
		[placement]="dropdownPlacement"
		[container]="dropdownContainer"
		(openChange)="openChangeHandler($event)">
		<dropdown-toggle-wrapper ngbDropdownAnchor #ngDropdownToggle class="h-100-percent">
			<ng-content></ng-content></dropdown-toggle-wrapper>

		<ul ngbDropdownMenu
			id="{{styleId}}"
			class="multiselect-dropdown"
			role="group"
			[attr.aria-label]="selectedOption?.displayName"
			[class.paging]="hasPagingSearch()"
			[class.dashboard-filter]="dashboardFilter"
			(click)="$event.stopPropagation()"
			(keyup.escape)="stopEvent($event); closePopup();"
			(keydown.escape)="stopEvent($event)">

			<div *ngIf="isDropdownOpen()"
				class="options-wrapper d-flex flex-direction-column"
				[style.width]="getElementWidth()">
				<li *ngIf="uiOptions.showSearch" class="searchable-hierarchy p-8">
					<label *ngIf="searchLabel" for="input-search-{{styleId}}">{{searchLabel}}</label>
					<div class="input-group">
						<input #searchInput
								id="input-search-{{styleId}}"
								aria-autocomplete="list"
								autocomplete="off"
								class="search-box"
								[(ngModel)]="filterText"
								[debounce]="300"
								(debouncedChange)="handleSearchChange(filterText)"
								placeholder="{{'common.find'|i18n}}"
								(keydown.shift.tab)="onSearchKeydown($event)">
						<button *ngIf="hasPagingSearch()"
								role="button"
								attr.aria-label="{{'dashboard.applySearchAria'|i18n}}"
								class="btn btn-primary q-icon-search ignore-dropdown-style"
								(click)="handleSearchChange(filterText)"></button>
					</div>
				</li>
				<div class="options-list d-flex scroll-container" [ngBusy]="getFirstSearchPromise()">
					<div class="d-flex flex-direction-column w-100-percent h-100-percent"
						detectScrollOptions [useOverlay]="true">
						<li class="multiselect-clear-show" *ngIf="showChangeAll()">
							<span *ngIf="showSelectAll()"
								(click)="selectAll()"
								(keydown.enter)="onSelectAllKeydown($event)"
								class="pv-2 ph-8 action-text cursor-pointer pull-left kb-focusable-inset"
								role="button"
								tabindex="0">
								{{'common.selectAll'|i18n}}
							</span>
							<span *ngIf="showClearAll()"
								(click)="deselectAll()"
								(keydown.enter)="onDeselectAllKeydown($event)"
								class="pv-2 ph-8 action-text cursor-pointer pull-right kb-focusable-inset"
								role="button"
								tabindex="0">
								{{'common.clear'|i18n}}
							</span>
						</li>
						<multiselect-options
							id="multiselect-options-{{styleId}}"
							attr.id="multiselect-options-{{styleId}}"
							[ngClass]="{'hide-checkbox': uiOptions.hideCheckbox, 'hide-selections': uiOptions.hideSelections}"
							[allOptions]="nestedList"
							[itemDisabled]="disable"
							[filterText]="getSearchText()"
							[isPagingSearch]="hasPagingSearch()"
							[showSelected]="!useChips"
							[showAttributeName]="uiOptions.showAttributeName"
							[optimized]="optimized"
							[highlightContains]="uiOptions.highlightContains"
							(onClick)="clickItem($event.$item, $event.$itemType)"
							(onFocusMove)="focusMoveHandler()"
						></multiselect-options>
					</div>
				</div>

				<multiselect-footer
					*ngIf="hasPagingSearch()"
					[pagingSearch]="pagingSearch"
					[filterText]="getSearchText()"
					(onFocusMove)="closePopup()"
				></multiselect-footer>
			</div>
		</ul>
	</div>
</div>
