import { GaugeDefaults } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-defaults';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';

export class GaugeBlockSizeUtils {
	static getGaugeWidth(cssVars: GaugeVariables): number {
		return this.getGaugeDiameter(cssVars);
	}

	static getGaugeHeight(cssVars: GaugeVariables): number {
		return this.getGaugeDiameter(cssVars) / 2 + GaugeDefaults.getRangeLabelPosition();
	}

	static getGaugeDiameter(cssVars: GaugeVariables): number {
		return 0.6 * GaugeDefaults.targetNumberOfDigits * cssVars.metricFontSize / 0.68;
	}

	static getComparisonWidth(cssVars: GaugeVariables): number {
		let comparisonMetricWidth = 0.6 * GaugeDefaults.targetNumberOfDigits * cssVars.comparisonFontSize;
		let deltaWidth = 0.6 * GaugeDefaults.targetNumberOfDigits * cssVars.deltaFontSize + 20;
		return Math.max(comparisonMetricWidth, deltaWidth);
	}

	static getComparisonHeight(cssVars: GaugeVariables): number {
		return GaugeDefaults.labelFontSize + GaugeDefaults.labelSpacing
			+ cssVars.comparisonFontSize
			+ GaugeDefaults.deltaSpacing + cssVars.deltaFontSize;
	}

	static getMetricBlockHeight(cssVars: GaugeVariables): number {
		return GaugeDefaults.labelFontSize
			+ GaugeDefaults.labelSpacing
			+ cssVars.metricFontSize;
	}
}
