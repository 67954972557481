<multiselect #multiselect
	class="flex-fill"
	[(ngModel)]="selection.multiValues"
	[nestedList]="selection.nestedList"
	(onChange)="onChangeInternal()"
	(onNodeClick)="onNodeClickInternal($event.node)"
	(updateSearch)="onUpdateSearchInternal($event.$search)"
	[config]="multiselectConfig"
	[equalFunction]="filtersEqual"
	[autoFocus]="autoFocus"
	[dropdownContainer]="dropdownContainer"
	[dropdownPlacement]="dropdownPlacement"
	[pagingSearch]="pagingSearch"
	[dashboardFilter]="dashboardFilter"
	[disableSelection]="disableSelection"
	[useChips]="useChips"
	[color]="color"
	[externalOpenValue]="externalOpen"
	[externalSearchValue]="externalSearch"
	searchLabel="{{searchLabel}}"
	[selectedOption]="selection.selectedAttribute">
	<ng-content></ng-content>
</multiselect>
