import { Input, EventEmitter, Output, OnInit, Component, Inject, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { CxLocaleService } from '@app/core';
import EventType from '@cxstudio/services/event/event-type.enum';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import { SearchModel, INodeParams } from '@app/shared/components/forms/tree/searchable-tree.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { TreeFocusMoveDirection, FocusTarget, ITreeFocusMoveParams, TreeFocusUtils } from '@app/shared/components/forms/tree/focus';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { RandomUtils } from '@app/util/random-utils.class';

@Component({
	selector: 'tree-node',
	templateUrl: './tree-node.component.html'
})
export class TreeNodeComponent implements OnInit, OnDestroy {

	readonly ATTRIBUTE_STATS_TIMEOUT = 1000;
	readonly TreeFocusMoveDirection = TreeFocusMoveDirection;
	readonly SUBTREE_ID = `subtree-${RandomUtils.randomString()}`;

	@Input() node: TreeNode;
	@Input() search: SearchModel;

	@Input() displayProperty: string;
	@Input() forcedOrder: boolean;
	@Input() isDisabled: (item: TreeNode) => boolean;
	@Input() isSelected: (item: TreeNode) => boolean;
	@Input() isNotRecommended: (item: TreeNode) => boolean;
	@Input() optionClassFormatter: (item) => string;

	@Input() showEmpty: boolean;
	@Input() limitedWidth: boolean;
	@Input() folderClickIgnore: boolean;
	@Input() showNotRecommendedPrompt: boolean;
	@Input() showHelper: boolean;
	@Input() itemTemplate: TemplateRef<any>;

	@Input() isVisibleOverrided: (node: INode) => boolean;
	@Input() customValidation: (node: TreeNode) => boolean;


	@Output() onNodeClick = new EventEmitter<INodeParams>();
	@Output() onFolderExpand = new EventEmitter<INodeParams>();
	@Output() onFolderCollapse = new EventEmitter<INodeParams>();


	@Input() showCheckboxes: boolean;
	@Input() showNodeCheckbox: (item: TreeNode) => boolean;
	@Input() isNodeCheckboxDisabled: (item: TreeNode) => boolean;
	@Input() isNodeChecked: (item: TreeNode) => boolean;
	@Input() isNodeMarked: (item: TreeNode) => boolean;
	@Input() getNodeTooltip: (item: TreeNode) => string;
	@Input() nodeIndeterminate: (item: TreeNode) => boolean;
	@Input() isNodeHighlighted: (item: TreeNode) => boolean;

	@ViewChild('checkbox', {static: false}) checkbox: ElementRef;
	@ViewChild('toggle', {static: false}) toggle: ElementRef;

	@Input() setFocus: Observable<FocusTarget>;
	@Output() onFocusMove = new EventEmitter<ITreeFocusMoveParams>();
	setChildFocusSubject: Subject<FocusTarget> = new Subject<FocusTarget>();
	private focusSubscription$: Subscription;

	constructor(
		private locale: CxLocaleService,
		@Inject('eventEmitterService') private eventEmitterService: EventEmitterService
	) {}

	ngOnInit(): void {
		if (this.setFocus) {
			this.focusSubscription$ = this.setFocus.subscribe(this.onFocus);
		}
	}

	ngOnDestroy(): void {
		if (this.focusSubscription$) {
			this.focusSubscription$.unsubscribe();
		}
	}

	getUniqueId = (): string => {
		return _.uniqueId();
	}

	isVisible = () => {
		if (!this.showEmpty && this.isEmptyFolder()) return false;
		if (this.isVisibleOverrided)
			return this.isVisibleOverrided(this.node);
		//item.hidden: special flag to indicate this is a organization hiearchy model
		//item.hide: visible or not
		return this.node && ((!this.node.hidden && !this.node.hide) || this.isNodeSelected()); // if selected, don't hide
	}

	isNodeDisabled = (): boolean => {
		if (this.node._disabled) {
			return true;
		}
		return this.isDisabled(this.node);
	}

	triggerClick = ($event: MouseEvent) => {
		$event.stopPropagation();
		this.validate().then(() => {
			if (!this.isNodeDisabled()) {
				this.onNodeClick.emit({node: this.node, $event});
			}
		});
	}

	validate = (): Promise<boolean> => {
		let result = this.customValidation
			? this.customValidation(this.node)
			: true;
		return Promise.resolve(result);
	}

	getTooltip = (): string => {
		if (this.getNodeTooltip) {
			return this.getNodeTooltip(this.node);
		} else if (this.showNotRecommendedPrompt && this.isNodeNotRecommended()) {
			return this.locale.getString('widget.notRecommended');
		} else if (this.limitedWidth) {
			return this.node[this.displayProperty];
		} else {
			return '';
		}
	}

	getHierarchyItemClass = () => {
		let iconClass: string[] = [];

		if (this.isNodeDisabled()) {
			iconClass.push('disabled');
		}

		if (this.isNodeNotRecommended()) {
			iconClass.push('grey-out');
		}

		if (this.isNodeSelected() || this.isNodeHighlightedFn()) {
			iconClass.push('selected');
		}

		if (this.optionClassFormatter) {
			iconClass.push(this.optionClassFormatter(this.node));
		}

		return iconClass.join(' ');
	}

	isFolder = (): boolean => {
		return !!this.node?.children?.length || this.isFolderType();
	}

	isFolderType = () => SearchableHierarchyUtils.isFolder(this.node);

	isEmptyFolder = () => SearchableHierarchyUtils.isEmptyFolder(this.node);

	showExpansionToggle = (): boolean => {
		return this.node && this.node.children
			&& this.isFolder()
			&& !this.node._hideExpansionToggle;
	}

	toggleFolder = ($event: MouseEvent) => {
		if (this.node._expanded) {
			this.collapseFolder($event);
		} else {
			this.expandFolder($event);
		}
	}

	collapseFolder = ($event: MouseEvent) => {
		if (this.isNodeDisabled()) return;
		$event.stopPropagation();
		$event.preventDefault();
		this.node._expanded = false;
		this.node._autoexpanded = false;
		this.onFolderCollapse.emit({node: this.node, $event});
	}

	expandFolder = ($event: UIEvent) => {
		if (this.isNodeDisabled()) return;
		$event.stopPropagation();
		$event.preventDefault();

		this.node._expanded = true;
		this.onFolderExpand.emit({node: this.node, $event});
		setTimeout(() => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
		}, this.ATTRIBUTE_STATS_TIMEOUT);
		this.node._autoexpanded = false;
	}

	nodeClickHandler = ($event: INodeParams) => {
		this.onNodeClick.emit($event);
	}

	folderExpandHandler = ($event: INodeParams) => {
		this.onFolderExpand.emit($event);
	}

	isNodeSelected = (): boolean => {
		return this.isSelected(this.node);
	}

	isNodeNotRecommended = (): boolean => {
		if (this.node && this.node._notRecommended) {
			return true;
		}
		return this.node && !this.node.children && !this.isNodeDisabled() && this.isNotRecommended(this.node);
	}

	isNodeHighlightedFn = (): boolean => {
		if (this.isNodeHighlighted) {
			return this.isNodeHighlighted(this.node);
		}
		return false;
	}

	moveFocus = ($event: UIEvent, direction: TreeFocusMoveDirection) => {
		if (TreeFocusUtils.isArrow(direction)) {
			$event.stopPropagation();
			$event.preventDefault();
		}

		if (TreeFocusUtils.isDown(direction) && this.node._expanded) {
			if (direction === TreeFocusMoveDirection.FORWARD) return;
			this.setChildFocusSubject.next(FocusTarget.FIRST);
		} else {
			this.onFocusMove.emit({node: this.node, $event, direction});
		}
	}

	handleArrowLeft = (event: UIEvent) => {
		if (this.isFolder() && (this.node._expanded || this.node._autoexpanded) && !this.isNodeDisabled()) {
			this.collapseFolder(event as any);
		} else {
			KeyboardUtils.intercept(event as KeyboardEvent);
			this.moveFocus(event, TreeFocusMoveDirection.PARENT);
		}
	}

	getSubtreeId = (): string => {
		return this.hasChildren() ? this.SUBTREE_ID : undefined;
	}

	hasChildren = (): boolean => {
		return !!this.node.children?.length;
	}

	private onFocus = (target: FocusTarget): void => {
		if (target === FocusTarget.LAST && this.node._expanded) {
			this.setChildFocusSubject.next(target);
		} else {
			this.checkbox.nativeElement.focus();
		}
	}

	focusMoveHandler = ($event: ITreeFocusMoveParams) => {
		if ($event.direction === TreeFocusMoveDirection.UP || $event.direction === TreeFocusMoveDirection.PARENT) {
			this.checkbox.nativeElement.focus();
		} else {
			$event.node = this.node;
			this.onFocusMove.emit($event);
		}
	}
}
