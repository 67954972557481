enum ChartType {
	// dual types
	BAR = 'BAR',
	COLUMN = 'COLUMN',
	PARETO = 'PARETO',
	SPLINE = 'SPLINE',
	BUBBLE = 'BUBBLE',
	// heatmap types
	DEFAULT = 'DEFAULT',
	ROW = 'ROW',
	POLAR = 'POLAR',
	RADAR = 'RADAR'
}
export default ChartType;
