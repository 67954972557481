import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';


@Directive({
	selector: 'alert-notification'
})
export class AlertNotificationUpgrade extends UpgradeComponent {
	@Input() alert: StudioAlert;
	@Input() ui: { notificationSettingsError: boolean };
	@Input() createCase: boolean;
	@Output('testAlert') testAlertCallback: EventEmitter<void>;
	@Output('validityChange') validationCallback: EventEmitter<{$valid: boolean}>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('alertNotification', elementRef, injector);
	}
}
