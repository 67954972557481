import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
/**
 * Adds "has-scroll", "can-scroll-up" and "can-scroll-down" when scrolling is an option
 * (currently only for vertical case)
 */
//has NG sibling
app.directive('detectScrollOptions', ($window) => {
	return {
		link: (scope: ISimpleScope, elem: ng.IAugmentedJQuery, attrs) => {
			const SCROLL_UP_CLASS = 'can-scroll-up';
			const SCROLL_DOWN_CLASS = 'can-scroll-down';
			const HAS_SCROLL = 'has-scroll';

			let scrollActive = false;
			let domElm = elem[0];
			let useOverlay = !_.isUndefined(elem.attr('use-overlay'));
			let topShadow;
			let bottomShadow;
			let resizeObserver, contentHeightObserver;

			if (useOverlay) {
				topShadow = document.createElement('div');
				bottomShadow = document.createElement('div');
				topShadow.classList.add('scroll-up-shadow');
				bottomShadow.classList.add('scroll-down-shadow');
				elem.after(bottomShadow);
				elem.after(topShadow);
			}

			let clientHeight: number;
			let contentHeight: number;
			let checkScroll = _.throttle(checkScrollFn, 300);
			resetHeights();

			addResizeHandler();

			scope.$on('$destroy', () => {
				removeResizeHandler();
				elem.off('scroll');
				if (topShadow)
					topShadow.remove();
				if (bottomShadow)
					bottomShadow.remove();
			});

			elem.on('scroll', _.throttle((e => {
				if (!scrollActive)
					return;
				updateScrollOptions();
			}), 300));

			function resetHeights(): void {
				clientHeight = domElm.clientHeight;
				contentHeight = domElm.firstElementChild?.clientHeight;
				if (contentHeight && !contentHeightObserver) {
					contentHeightObserver = ResizeHandlerUtils.addResizeHandler(domElm.firstElementChild, checkScroll);
				}
			}

			function checkScrollFn(): void {
				resetHeights();
				scrollActive = contentHeight > clientHeight;
				if (!scrollActive)
					elem.removeClass(`${SCROLL_UP_CLASS} ${SCROLL_DOWN_CLASS} ${HAS_SCROLL}`);
				else updateScrollOptions();
			}

			function updateScrollOptions(): void {
				let scrollTop = domElm.scrollTop;
				applyClass(HAS_SCROLL, true);
				applyClass(SCROLL_UP_CLASS, scrollTop > 0);
				applyClass(SCROLL_DOWN_CLASS, Math.ceil(scrollTop + clientHeight) < contentHeight);
			}

			function applyClass(clazz: string, condition: boolean): void {
				if (condition)
					elem.addClass(clazz);
				else elem.removeClass(clazz);
			}

			function addResizeHandler(): void {
				resizeObserver = ResizeHandlerUtils.addResizeHandler(domElm, checkScroll);
			}

			function removeResizeHandler(): void {
				ResizeHandlerUtils.removeResizeHandler(resizeObserver);
				ResizeHandlerUtils.removeResizeHandler(contentHeightObserver);
			}
		}
	};
});
