import { Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'topic-conversions',
	templateUrl: './topic-conversions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicConversionsComponent {}

app.directive(
	'topicConversions',
	downgradeComponent({ component: TopicConversionsComponent }) as angular.IDirectiveFactory
);
