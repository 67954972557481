<scroll-carousel [labels]="scrollLabels">
	<div class="mb-16 overflow-hidden p-relative">
		<ul #nav="ngbNav" ngbNav [(activeId)]="activeTabId"
			class="scroll-carousel-target fade-container d-flex nav-tabs bg-white flex-basis-0 mb-0"
			id="context-pane-{{idPostfix}}" (click)="onTabSelection($event)">
			<li attr.data-testid="{{subpanels.KEY_INFO}}"
				id="{{subpanels.KEY_INFO}}" [ngbNavItem]=subpanels.KEY_INFO
				class="font-size-default b-0"
				*ngIf="showKeyInfoPanel()"
				role="tab"
				[class.active]="activeTabId === subpanels.KEY_INFO"
				[attr.aria-label]="'docExplorer.keyInfo'|i18n">
				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap" href="javascript:void(0);">{{'docExplorer.keyInfo'|i18n}}</a>

				<ng-template ngbNavContent>
					<div attr.data-testid="{{subpanels.KEY_INFO}}-content" class="overflow-y-auto h-max-100-percent">
						<div>
							<label for="input-search-favorite-{{idPostfix}}" class="mb-0">{{'preview.searchKeyInfo'|i18n}}</label>
							<input class="form-control br-favorites-search mb-8 h-32"
								[attr.aria-label]="'preview.searchKeyInfo'|i18n"
								role="search"
								id="input-search-favorite-{{idPostfix}}"
								clear-input
								type="text"
								aria-autocomplete="list"
								autocomplete="off"
								placeholder="{{'common.search'|i18n}}"
								[(ngModel)]="componentUI.favoritesTextFilter"/>
						</div>

						<div *ngFor="let attribute of favorites.favoriteAttributesDisplay
								| orderBy:'displayName'
								| explorerAttributeFilter:isDocExplorer:isEmptyAttrsVisible()
								| filterBy:['favoriteType']:'attribute':true
								| filterBy:['displayName', 'value']:componentUI.favoritesTextFilter;
								let idx = index"
							[attr.aria-label]="getAttributeAriaLabel(attribute)"
							[class.first]="idx === 0"
							class="br-document-preview-attribute pv-4 pl-24 pr-8 kb-focusable-inset"
							[attr.id]="attribute.id"
							tabindex="0">
							<explorer-attribute
								[disableFavorites]="!isDocExplorer"
								[attribute]="attribute"
								[favoriteAttributes]="favorites?.favoriteAttributes"
								(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
								favoritesPanel="true"
								[document]="document"
								[widget]="documentManager.widget">
							</explorer-attribute>
						</div>

						<div class="panel-subsection-wrapper"
							*ngFor="let attribute of favorites.favoriteAttributesDisplay
								| orderBy:'displayName'
								| filterBy:['favoriteType']:'worldAwareness':true
								| filterBy:['displayName', 'values']:componentUI.favoritesTextFilter"
							[title]="attribute.displayName">
							<explorer-world-awareness
								[disableFavorites]="!isDocExplorer"
								[attribute]="attribute"
								[favoriteAttributes]="favorites?.favoriteAttributes"
								(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
								favoritesPanel="true"
								[highlightItem]="highlighter.highlightItem"
								[selectionChangeTrigger]="selectionChangeTrigger"
								(highlightFn)="highlightWorldAwareness($event)"
							></explorer-world-awareness>
						</div>

						<div class="dex-models-list-group panel-subsection-wrapper"
							*ngFor="let model of favorites.favoriteAttributesDisplay
								| orderBy:'modelName'
								| filterBy:['favoriteType']:'model':true
								| explorerModelFilter:isDocExplorer:getModelSelection()
								| filterBy:['searchString']:componentUI.favoritesTextFilter">
							<topic-chicklets
								[model]="model"
								(chickletClick)="highlighter.highlightModel($event)"
								favoritesPanel="true"
								[disableFavorites]="!isDocExplorer"
								[leafOnly]="preferences.settings.leafOnly"
								[favoriteAttributes]="favorites?.favoriteAttributes"
								(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
								[selectionChangeTrigger]="selectionChangeTrigger"
							></topic-chicklets>
						</div>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.NARRATIVES}}"
				id="{{subpanels.NARRATIVES}}" [ngbNavItem]=subpanels.NARRATIVES
				class="b-0"
				*ngIf="isNarrativePanelVisible()"
				role="tab"
				[class.active]="activeTabId === subpanels.NARRATIVES"
				[attr.aria-label]="'appearance.automatedNarratives'|i18n">

				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">{{'appearance.automatedNarratives'|i18n}}</a>

				<ng-template ngbNavContent>
					<div  attr.data-testid="{{subpanels.NARRATIVES}}-content" class="overflow-y-auto h-max-100-percent" *ngIf="narrativeEntries?.length">
						<div *ngFor="let narrativeEntry of narrativeEntries"
							tabindex="0"
							class="kb-focusable"
							role="text"
							[attr.aria-label]="narrativeEntry.attributeDisplayName">
							<p *ngIf="getNarrative(narrativeEntry)">
								<ng-container *ngFor="let narrativeLine of getNarrativeLines(narrativeEntry)">
									{{narrativeLine}}<br>
								</ng-container>
							</p>
						</div>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.INBOX}}"
				id="{{subpanels.INBOX}}" [ngbNavItem]=subpanels.INBOX *ngIf="isEngagorPanelVisible()"
				class="text-default b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.INBOX"
				[attr.aria-label]="'preview.engagorCases'|i18n">

				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">{{'preview.engagorCases'|i18n}}</a>

				<ng-template ngbNavContent>
					<div attr.data-testid="{{subpanels.INBOX}}-content" class="overflow-y-auto h-max-100-percent" *ngIf="engagorCases?.length">
						<explorer-engagor-case
							*ngFor="let case of engagorCases"
							[case]="case">
						</explorer-engagor-case>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.SCORECARDS}}"
				id="{{subpanels.SCORECARDS}}" [ngbNavItem]=subpanels.SCORECARDS *ngIf="isScorecardPanelVisible()"
				class="text-default b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.SCORECARDS"
				[attr.aria-label]="'preview.scorecards'|i18n">


				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">
					{{'preview.scorecards'|i18n}}
				</a>

				<ng-template ngbNavContent>
					<div attr.data-testid="{{subpanels.SCORECARDS}}-content" class="overflow-y-auto h-max-100-percent">
						<explorer-scorecards-section *ngIf="scorecards?.length"
							(onTopicClick)="highlightByScorecardTopic($event)"
							(onExpandScorecard)="onExpandScorecard($event)"
							(onFavoriteScorecard)="onFavoriteScorecard($event)"
							[scorecards]="scorecards"
							[selectedScorecard]="documentManager.selectedScorecard"
							[favoriteScorecard]="documentManager.favoriteScorecard"
							[selectedDocument]="documentManager.selectedDocument"
							[projectSelection]="documentManager.getProjectIdentifier()"
							[documentType]="documentType"
							[isDocExplorer]="isDocExplorer"
							[rebuttalMode]="rebuttalMode">
						</explorer-scorecards-section>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.ATTRIBUTES}}"
				id="{{subpanels.ATTRIBUTES}}" [ngbNavItem]=subpanels.ATTRIBUTES *ngIf="isAttributesPanelVisible()"
				class="text-default b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.ATTRIBUTES"
				[attr.aria-label]="'preview.attribute'|i18n">

				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">{{'preview.attribute'|i18n}}</a>
				<ng-template ngbNavContent>
					<div attr.data-testid="{{subpanels.ATTRIBUTES}}-content" class="h-max-100-percent d-flex flex-direction-column">
						<panel-ribbon>
							<div class="align-items-center justify-between w-max-100-percent">
								<label for="input-search-attribute-{{idPostfix}}" class="mb-0">{{'preview.searchFind'|i18n}}</label>
								<clearable-input class="w-100-percent br-attribute-search m-8 mr-0 border-radius-4"
									[ariaLabel]="'preview.searchFindAttributes'|i18n"
									id="input-search-attribute-{{idPostfix}}"
									[(ngModel)]="componentUI.attrTextFilter">
								</clearable-input>
							</div>
						</panel-ribbon>

						<div class="attr-scroll-wrap mr-0">
							<div>
								<div *ngFor="let attribute of attributes
										| orderBy:'displayName'
										| explorerAttributeFilter:isDocExplorer:isEmptyAttrsVisible()
										| excludeNarrativeAttributeFilter:getNarrativeNames()
										| filterBy:['displayName', 'value']:componentUI.attrTextFilter;
										let idx = index; let $even = even"
									[attr.aria-label]="getAttributeAriaLabel(attribute)"
									[class.first]="idx === 0"
									[class.bg-gray-100]="$even"
									class="br-document-preview-attribute pv-4 pl-24 pr-8 kb-focusable-inset"
									tabindex="0"
									[attr.id]="attribute.id">
									<explorer-attribute
										class="p-relative attribute_explorer-attribute_space"
										[disableFavorites]="!isDocExplorer"
										[attribute]="attribute"
										[favoriteAttributes]="favorites?.favoriteAttributes"
										(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
										[document]="document"
										[widget]="documentManager.widget">
									</explorer-attribute>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.ENRICHMENTS}}"
				id="{{subpanels.ENRICHMENTS}}" [ngbNavItem]=subpanels.ENRICHMENTS *ngIf="isEnrichmentsPanelVisible()"
				class="text-1rem b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.ENRICHMENTS"
				[attr.aria-label]="'preview.worldAwareness'|i18n">

				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">{{'preview.worldAwareness'|i18n}}</a>

				<ng-template ngbNavContent>
					<div attr.data-testid="{{subpanels.ENRICHMENTS}}-content" class="h-max-100-percent d-flex flex-direction-column">
						<!-- Render when pill sentences are searched for, w/beta flag -->
						<panel-ribbon *ngIf="(highlighter.currentHighlight?.type === ContextHighlightType.WORLD_AWARENESS) && !showWASearch">
							<div class="align-items-center justify-between w-max-100-percent">
								<label for="input-search-enrichment-{{idPostfix}}" class="mb-0">{{'preview.searchFind'|i18n}}</label>
								<context-navigation
									[nodeName]="getWorldAwarenessHighlightName(highlighter.currentHighlight?.node)"
									[currentIndex]="highlighter.highlightSentenceIndex"
									[total]="highlighter.currentHighlight?.node?.sentences.length"
									(closeClick)="clearEnrichmentHighlighting()"
									(arrowClick)="switchSentence($event, true)">
								</context-navigation>
							</div>
						</panel-ribbon>
						<!-- Render when search, w/beta flag -->
						<panel-ribbon *ngIf="showWASearch">
							<div class="align-items-center justify-between w-max-100-percent">
								<label for="input-search-enrichment-{{idPostfix}}" class="mb-0">{{'preview.searchFind'|i18n}}</label>
								<clearable-input class="w-100-percent br-world-awareness-search m-8 mr-0 border-radius-4"
									[ariaLabel]="'preview.searchFindEnrichments'|i18n"
									id="input-search-enrichment-{{idPostfix}}"
									[(ngModel)]="componentUI.worldAwarenessSearch"
									(onClear)="clearHighlighting()">
								</clearable-input>
							</div>
						</panel-ribbon>

						<div class="chicklet-scroll-wrap mr-0">
							<div
								*ngFor="let attribute of worldAwareness
									| orderBy:'displayName'
									| excludeNarrativeAttributeFilter:getNarrativeNames()
									| filterBy:['displayName', 'values']:componentUI.worldAwarenessSearch"
								class="panel-subsection-wrapper"
								[title]="attribute.displayName">
								<explorer-world-awareness
									[disableFavorites]="!isDocExplorer"
									[attribute]="attribute"
									[favoriteAttributes]="favorites?.favoriteAttributes"
									(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
									[highlightItem]="highlighter.highlightItem"
									[selectionChangeTrigger]="selectionChangeTrigger"
									(highlightFn)="highlightWorldAwareness($event)">
								</explorer-world-awareness>
							</div>
						</div>
					</div>
				</ng-template>
			</li>

			<li attr.data-testid="{{subpanels.TOPICS}}"
				id="{{subpanels.TOPICS}}" [ngbNavItem]=subpanels.TOPICS *ngIf="isTopicsPanelVisible()"
				class="text-1rem b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.TOPICS"
				[attr.aria-label]="'preview.model'|i18n">

				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">{{'preview.model'|i18n}}</a>

				<ng-template ngbNavContent>
					<!-- TOPICS PANEL PROBABLY NEEDS CLEANUP/REWORK, BUT THERE ARE SO MANY BETA FEATURES AND
						UNANSWERED QUESTIONS, I DONT KNOW WHERE TO START, AND THE DESIGN DOESNT COVER THIS -->

					<div attr.data-testid="{{subpanels.TOPICS}}-content" class="h-max-100-percent d-flex flex-direction-column">
						<div class="d-flex align-items-center">
							<button class="q-icon q-icon-models btn btn-icon mr-8 h-32 d-flex align-items-center"
									*ngIf="isDocExplorer"
									(click)="showTopicFilters = !showTopicFilters"
									(keydown)="focusTopicFilterMenu($event)"
									title="{{'docExplorer.filterTopics'|i18n}}"
									[attr.aria-label]="'preview.selectTopicModelsToDisplay'|i18n"
								></button>


							<!-- Render when pill sentences are searched for, w/beta flag -->
							<panel-ribbon class="flex-1" *ngIf="(highlighter.currentHighlight?.type === ContextHighlightType.TOPIC)">
								<div class="align-items-center justify-between w-max-100-percent">
									<label for="input-search-topic-{{idPostfix}}" class="mb-0">{{'preview.searchFind'|i18n}}</label>
									<context-navigation
										[nodeName]="highlighter.currentHighlight?.node?.name"
										[currentIndex]="highlighter.highlightSentenceIndex"
										[total]="highlighter.currentHighlight?.node?.sentences.length"
										(closeClick)="clearHighlighting()"
										(arrowClick)="switchSentence($event)">
									</context-navigation>
								</div>
							</panel-ribbon>

<!--							 Render when search, w/beta flag-->
							<panel-ribbon class="flex-1" *ngIf="(highlighter.currentHighlight?.type !== ContextHighlightType.TOPIC)">
								<div class="align-items-center justify-between w-max-100-percent">
									<label for="input-search-topic-{{idPostfix}}" class="mb-0">{{'preview.searchFind'|i18n}}</label>
									<clearable-input class="w-100-percent br-topic-search m-8 mr-0 border-radius-4"
										[ariaLabel]="'preview.searchFindTopics'|i18n"
										id="input-search-topic-{{idPostfix}}"
										[(ngModel)]="componentUI.topicSearch"
										(onClear)="clearHighlighting()">
									</clearable-input>
								</div>
							</panel-ribbon>
						</div>

						<topic-checkboxes
							*ngIf="isDocExplorer && showTopicFilters"
							class="d-flex overflow-hidden"
							(keydown)="focusTopicFilterOnEsc($event)"
							[filterText]="componentUI.modelSearch"
							[usableModels]="availableModels"
							[userPreferences]="preferences"
							[projUniqueId]="projectUniqueId"
							(selectionChange)="changeModelsSelection()">
						</topic-checkboxes>

						<div *ngIf="!showTopicFilters" class="chicklet-scroll-wrap mr-0">
							<div
								class="dex-models-list-group panel-subsection-wrapper"
								*ngFor="let model of document?.classification
									| orderBy:'modelName'
									| explorerModelFilter:isDocExplorer:getModelSelection()
									| filterBy:['searchString']:componentUI.topicSearch">
								<topic-chicklets
									[model]="model"
									(chickletHover)="highlighter.hoverModel($event)"
									(chickletClick)="highlightTopic($event)"
									[disableFavorites]="!isDocExplorer"
									[leafOnly]="preferences.settings.leafOnly"
									[favoriteAttributes]="favorites?.favoriteAttributes"
									(onToggleFavorite)="favorites.toggleFavorite($event.attribute, $event.type, documentManager.selectedDocument, documentType)"
									[selectionChangeTrigger]="selectionChangeTrigger"></topic-chicklets>
							</div>
						</div>
					</div>
				</ng-template>
			</li>
			<li
				*ngIf="isAutomatedSummariesPanelVisible()"
				id="{{subpanels.AUTOMATED_SUMMARIES}}"
				[ngbNavItem]=subpanels.AUTOMATED_SUMMARIES
				class="text-1rem panel-automated-call-summary b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.AUTOMATED_SUMMARIES"
				[attr.aria-label]="'preview.automatedCallSummary'|i18n"
			>
				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">
					{{'preview.automatedCallSummary'|i18n}}
				</a>
				<ng-template ngbNavContent>
					<div class="h-100-percent w-100-percent d-flex flex-direction-column overflow-y-auto ">
						<explorer-automated-call-summary
							*ngIf="isAutomatedSummariesEnabled()"
							[document]="document"
							[ngClass]="[!isCoachingAssistantEnabled() ? 'h-100-percent' : '', isCoachingAssistantEnabled() ? 'mb-2' : '']"
						>
						</explorer-automated-call-summary>
						<explorer-coaching-assistant
							*ngIf="isCoachingAssistantEnabled()"
							[document]="document"
							[project]="documentManager.getProjectIdentifier()"
							[ngClass]="[!isAutomatedSummariesEnabled() ? 'h-100-percent' : '', isAutomatedSummariesEnabled() ? 'mb-8' : '']"
						>
						</explorer-coaching-assistant>
					</div>
				</ng-template>
			</li>
			<li
				*ngIf="isComplianceManagementPanelVisible()"
				id="{{subpanels.COMPLIANCE_MANAGEMENT}}"
				[ngbNavItem]=subpanels.COMPLIANCE_MANAGEMENT
				class="text-1rem panel-compliance-management b-0"
				role="tab"
				[class.active]="activeTabId === subpanels.COMPLIANCE_MANAGEMENT"
				[attr.aria-label]="'preview.complianceManagement'|i18n"
			>
				<a ngbNavLink class="m-0 p-8 pb-0 border-radius-0 h-3_125 no-wrap">
					{{'preview.complianceManagement'|i18n}} 
				</a>
				<ng-template ngbNavContent>
					<explorer-compliance-management
						[document]="document"
					>
					</explorer-compliance-management>
				</ng-template>
			</li>
		</ul>
	</div>
</scroll-carousel>

<div [ngbNavOutlet]="nav" class="d-flex flex-direction-column overflow-hidden flex-1"></div>
