import { Pipe, PipeTransform } from '@angular/core';

export enum FilterMatchMode {
	EXACT = 0,
	CASE_INSENSITIVE = 1,
	PARTIAL = 2
}

@Pipe({
	name: 'weightedFilter'
})

/**
 * Filters to match a search string to an object field, ordering as follows:
 * exact matches (case sensitive),
 * exact matches (case insensitive),
 * beginning of string matches (case insensitive),
 * matches any part of string
 * 
 */
export class WeightedFilterPipe<T> implements PipeTransform {
	transform(items: T[], searchField: string | ((item: T) => string), searchText: string, matchMode = FilterMatchMode.PARTIAL): T[] {
		let sortFn = (itemA: T, itemB: T): number => {
			if (this.getValue(itemA, searchField)?.toLowerCase() === this.getValue(itemB, searchField)?.toLowerCase()) return 0;			
			return (this.getValue(itemA, searchField)?.toLowerCase() < this.getValue(itemB, searchField)?.toLowerCase()) ? -1 : 1;
		};

		if (!items || items.length < 1) return [];
		if (!searchField || (typeof searchField === 'string' && searchField.length < 1)) return items;
		if (!searchText || searchText.length < 1) return items.filter(item => this.getValue(item, searchField)).sort(sortFn);

		let exactMatches = items
			.filter(item => this.getValue(item, searchField) === searchText)
			.sort(sortFn);
		let caseInsensitiveMatches = [];
		if (matchMode >= FilterMatchMode.CASE_INSENSITIVE) {
			caseInsensitiveMatches = items
				.filter(item => this.getValue(item, searchField)?.toLowerCase() === searchText.toLowerCase())
				.sort(sortFn);
		}
		let startsWithMatches = [];
		let otherMatches = [];
		if (matchMode >= FilterMatchMode.PARTIAL) {
			startsWithMatches = items
				.filter(item => this.getValue(item, searchField)?.toLowerCase().indexOf(searchText.toLowerCase()) === 0)
				.sort(sortFn);
			otherMatches = items
				.filter(item => this.getValue(item, searchField)?.toLowerCase().indexOf(searchText.toLowerCase()) > 0)
				.sort(sortFn);
		}

		return _.uniq([...exactMatches, ...caseInsensitiveMatches, ...startsWithMatches, ...otherMatches]);
	}

	private getValue(item: T, field: string | ((item: T) => string)): string {
		return typeof field === 'string'
			? item[field]
			: field(item);
	}
}