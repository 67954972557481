export class ScorecardTopic {
	id?: number;
	scoreCardId?: number;
	nodeId?: number;
	topicWeight?: number;
	autoFail?: boolean;
	presence?: boolean;
	idPath?: string;

	// Transient
	node?: any;

	constructor(scorecardTopic?: any) {
		if (scorecardTopic) {
			this.id = scorecardTopic.id;
			this.scoreCardId = scorecardTopic.scoreCardId;
			this.nodeId = scorecardTopic.nodeId;
			this.topicWeight = scorecardTopic.topicWeight;
			this.presence = scorecardTopic.presence;
			this.autoFail = scorecardTopic.autoFail;
			this.node = scorecardTopic.node;
		}
	}
}
