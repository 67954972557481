<form #designerScopeForm="ngForm" class="form-horizontal" duplicateNameCheck="scopeName_">

	<div class="labeled-rule text-center">
		<hr/>
		<div>
			<label [i18n]="'administration.oauthScope'"></label>
		</div>
	</div>

	<div class="row form-group">
		<label class="control-label col-md-3">{{'administration.addNewDesignerScope'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.oauthScopeHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-1">
			<button type="button" class="btn btn-primary" (click)="addDesignerScope()">
				<span class="q-icon q-icon-add" aria-hidden="true"></span>
			</button>
		</div>

		<div class="col-md-5" *ngIf="!scopeSelected()">
			<label class="control-label col-md-3 text-danger">
				<span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>
			<label class="text-danger">{{'administration.oauthScopeHasToBeAdded'|i18n}}</label>
		</div>

	</div>

	<div class="row form-group" *ngIf="designerScopeForm.errors?.duplicateName && designerScopeForm.dirty">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning"aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label [ngStyle]="{'color':'red'}">{{'administration.duplicateOauthDesignerClientScopes'|i18n}}:
				{{designerScopeForm.errors.duplicateName.value}}</label>
		</div>
	</div>

	<div class="rounded-grouping"
		 *ngFor="let scopeDescription of designerScopes; index as $index; trackBy:trackDesignerScope">

		<div class="rounded-grouping-label">
			<button type="button" class="btn btn-primary" (click)="removeDesignerScope($index)">
				<span class="q-icon q-icon-delete" aria-hidden="true"></span>
			</button>
		</div>

		<div class="row form-group" show-errors>
			<label
				class="control-label col-md-3">{{'administration.oauthClientScopeName'|i18n}}</label>
			<div class="col-md-6">
				<input class="form-control" autocomplete="nope"
					   [(ngModel)]="scopeDescription.scope.name"
					   placeholder="{{'administration.oauthClientScopeName'|i18n}}" required
					   (change)="updateDesignerScope()" name="scopeName_{{$index}}">
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAccessTokenValiditySeconds'|i18n}}
				({{'administration.optional'|i18n}})</label>
			<div class="col-md-2">
				<input type="number" class="form-control" min="-1" max="47304000"
					   pattern="^-?\d*"
					   [(ngModel)]="scopeDescription.scope.accessTokenValiditySeconds"
					   name="scopeAccessTokenValiditySeconds_{{$index}}"
					   (change)="updateDesignerScope()">
			</div>
			<label class="col-md-1">{{'administration.oauthTokenValidityUnit'|i18n}}</label>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthRefreshTokenValiditySeconds'|i18n}}
				({{'administration.optional'|i18n}})</label>
			<div class="col-md-2">
				<input type="number" class="form-control" min="-1" max="47304000"
					   pattern="^-?\d*"
					   [(ngModel)]="scopeDescription.scope.refreshTokenValiditySeconds"
					   name="scopeRefreshTokenValiditySeconds_{{$index}}"
					   (change)="updateDesignerScope()">
			</div>
			<label class="col-md-1">{{'administration.oauthTokenValidityUnit'|i18n}}</label>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthClientScopeProperties'|i18n}}</label>
			<div class="columns-3">
				<div class="labeled-checkbox">
					<input id="autoApproveScope_{{$index}}" type="checkbox"
						   class="checkbox ng-not-empty"
						   [(ngModel)]="scopeDescription.autoApprove"
						   name="autoApproveScope_{{$index}}"
						   (change)="updateDesignerScope()">
					<label for="autoApproveScope_{{$index}}">{{'administration.oauthClientScopeAutoApproved'|i18n}}</label>
				</div>
				<div class="labeled-checkbox">
					<input id="extendableScope_{{$index}}" type="checkbox"
						   class="checkbox ng-not-empty"
						   [(ngModel)]="scopeDescription.scope.extendable"
						   name="extendableScope_{{$index}}"
						   (change)="updateDesignerScope()">
					<label for="extendableScope_{{$index}}">{{'administration.oauthClientScopeExtendable'|i18n}}</label>
				</div>
				<div class="labeled-checkbox">
					<input id="explicitScope_{{$index}}" type="checkbox"
						   class="checkbox ng-not-empty"
						   [(ngModel)]="scopeDescription.scope.explicit"
						   name="explicitScope_{{$index}}"
						   (change)="updateDesignerScope()">
					<label for="explicitScope_{{$index}}">{{'administration.oauthClientScopeExplicit'|i18n}}</label>
				</div>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthAuthorizedGrantTypes'|i18n}}</label>
			<div class="columns-3">
				<div class="labeled-checkbox"
					 *ngFor="let grantType of grantTypes; index as $childIndex">
					<input id="grantTypeScope_{{$index}}_{{$childIndex}}" type="checkbox"
						   class="checkbox ng-not-empty"
						   [(ngModel)]="scopeDescription.authorizedGrantTypes[grantType.type]"
						   name="grantTypeScope_{{$index}}_{{$childIndex}}"
						   (change)="updateDesignerScope()">
					<label for="grantTypeScope_{{$index}}_{{$childIndex}}">{{grantType.displayName}}</label>
				</div>
			</div>
		</div>
	</div>

</form>
