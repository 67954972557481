import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';

@Component({
	selector: 'alert-sharing-settings',
	templateUrl: './alert-sharing-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertSharingSettingsComponent implements OnInit {

	@Input() alert: StudioAlert;

	/** Array for entities with the changed access */
	addedEntities: any[] = [];
	/** Array for previously shared entities */
	sharedEntities: any[] = [];

	changeTracker: {
		updates: {
			changedEntities?: any[];
			sharedEntities?: any[];
		};
	} = { updates: {}};

	constructor() { }

	ngOnInit(): void {
	}

	isNewAlert(): boolean {
		return !this.alert.id;
	}

}
