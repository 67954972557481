import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface IDashboardOwnerChangeParams {
	showCheckbox: boolean;
	bodyText: string;
}

@Component({
	selector: 'dashboard-change-owner-dialog',
	templateUrl: './dashboard-change-owner-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardChangeOwnerDialogComponent extends ModalBindings<IDashboardOwnerChangeParams> implements OnInit {
	retainEditPermission: boolean;
	bodyText: string;
	showCheckbox: boolean;

	constructor(modal: NgbActiveModal) {
		super(modal);
	}

	ngOnInit(): void {
		this.retainEditPermission = true;
		this.bodyText = this.input.bodyText;
		this.showCheckbox = this.input.showCheckbox;
	}
}
