<div [ngBusy]="[ promises.rootChildrenLoading, promises.childLoading ]">
	<searchable-tree
			placeholder="{{'common.search'|i18n}}"
			[hierarchyList]="root.children"
			[hierarchyName]="treeName"
			displayProperty="name"
			[forcedOrder]="forcedOrder"
			[showNodeCheckbox]="isShowingNodeCheckbox"
			[nodeCheckboxDisabled]="isNodeCheckboxDisabled"
			(onNodeClick)="handleNodeCheck($event.node, $event.$event)"
			(onFolderExpand)="expandFolder($event.node)"
			[isChildLoaded]="isChildLoaded"
			[nodeIsChecked]="isNodeChecked"
			[nodeIsMarked]="isNodeMarked"
			[getNodeTooltip]="getNodeTooltip"
			folderClickIgnore="true"
			[hideSearch]="hideSearch"
			[searchLabel]="searchLabel"
			[optionClassFormatter]="optionClassFormatter"
			[autoFocus]="autoFocus"
			(onFocusMove)="focusMoveHandler($event)">
	</searchable-tree>
</div>
