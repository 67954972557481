import { IPromise } from 'angular';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

export interface ProjectAssetsErrors { // errors are reset automatically by project-selector component
	noContentProviders?: boolean;
	noProjects?: boolean;
	noAccounts?: boolean;
	noProjectSelected?: boolean;
	noProjectAttributes?: boolean;
	tooManyProjects?: boolean;
	messages?: string[];
}

export interface ProjectAssetsLoading {
	accounts?: boolean;
	projects?: boolean;
	promise?: IPromise<any>;
	statsPromise?: IPromise<any>;
	columnsPromise?: Promise<any>;
}

@Component({
	selector: 'project-selection-error',
	templateUrl: './project-selection-error.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ProjectSelectionErrorComponent {
	@Input() errors: ProjectAssetsErrors;
	@Input() loading: ProjectAssetsLoading;
	@Input() objectTypeLabel?: string;

	constructor(private readonly locale: CxLocaleService) {}

	hasErrors = (): boolean => {
		const somethingLoading = this.loading?.projects || this.loading?.accounts;
		const hasAnyErrors = this.errors.noProjects
			|| this.errors.noAccounts
			|| this.errors.noProjectSelected
			|| this.errors.noProjectAttributes
			|| this.errors.tooManyProjects
			|| !_.isEmpty(this.errors.messages);

		return !somethingLoading && hasAnyErrors;
	}

	getTooManyProjectsMessage = (): string => {
		return this.locale.getString('metrics.tooManyProjects', {objectType: this.objectTypeLabel});
	}
}

app.directive('projectSelectionError', downgradeComponent({component: ProjectSelectionErrorComponent}) as angular.IDirectiveFactory);
