import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import Authorization from '@cxstudio/auth/authorization-service';


class UnifiedTemplateUI {
	showMetricTemplate: boolean;
	showDashboardTemplate: boolean;
	showFilterTemplate: boolean;
}


@Component({
	selector: 'unified-templates-management',
	templateUrl: './unified-templates-management.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnifiedTemplatesManagementComponent {

	TemplateAssetType = TemplateAssetType;

	ui: UnifiedTemplateUI;

	constructor(
		@Inject('authorization') private readonly authorization: Authorization,
		private readonly betaFeaturesService: BetaFeaturesService
	) {

		this.ui = {
			showMetricTemplate: this.betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_TEMPLATES),
			showDashboardTemplate: this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES),
			showFilterTemplate: this.betaFeaturesService.isFeatureEnabled(BetaFeature.FILTER_TEMPLATES),
		};
	}

	hasUnifiedTemplatesManagementAccess(): boolean {
		let templateBetas = [
			BetaFeature.METRIC_TEMPLATES,
			BetaFeature.FILTER_TEMPLATES,
			BetaFeature.NEW_DASHBOARD_TEMPLATES];

		return this.betaFeaturesService.isAnyFeatureEnabled(...templateBetas)
			&& (this.authorization.hasManageTemplatePermission() || this.authorization.hasCreateTemplatePermission());
	}
}

app.directive('unifiedTemplatesManagement',
		downgradeComponent({component: UnifiedTemplatesManagementComponent}) as angular.IDirectiveFactory);
