import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { SamlIdentityProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-identity-providers/saml-identity-providers.component';
import { SamlIdentityProvidersListActions } from '@app/modules/system-administration/saml-settings/saml-identity-providers/miscellaneous/saml-identity-providers-list-actions.service';

@Injectable()
export class SamlIdentityProvidersListMenu extends BaseContextMenuUtils {

	constructor(private locale: CxLocaleService, private identityProvidersListActions: SamlIdentityProvidersListActions) {
		super();
	}

	private getOptions(controller: SamlIdentityProvidersComponent): {[name: string]: ContextMenuItem<SamlIdentityProvider>} {
		return {
			EDIT: {
				name: 'edit',
				text: this.locale.getString('common.edit'),
				func: (identityProvider: SamlIdentityProvider) => this.identityProvidersListActions.edit(identityProvider, controller)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (identityProvider: SamlIdentityProvider) => this.identityProvidersListActions.delete(identityProvider)
			}
		};
	}

	getContextMenu(item: SamlIdentityProvider, controller: SamlIdentityProvidersComponent): Array<ContextMenuItem<SamlIdentityProvider>> {
		const OPTIONS = this.getOptions(controller);
		return [OPTIONS.EDIT, OPTIONS.DELETE];
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}