import { EnrichmentAttributeSettings } from './doc-explorer-attribute-settings.interface';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { FormatterBuilderUtilsService, DecimalInteger }
	from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { AttributeValues } from '@app/modules/project/attribute/attribute-values';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { ProjectSettingsMap } from '@app/modules/project/settings/report-settings.service';
import { CapitalizationUtils } from '@cxstudio/services/capitalization-utils.class';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

export class EnrichmentAttributesService {

	constructor(
		private urlService,
		private formatterBuilderUtils: FormatterBuilderUtilsService
	) {
	}

	formatAttributeValue(
		value: any,
		type: AttributeType,
		settings?: EnrichmentAttributeSettings,
		omitHref: boolean = false,
	): string {
		if (_.isUndefined(value)) {
			return value;
		}
		if (!isNaN(value)) {
			value = String(value);
		}

		if (type === AttributeType.DATE) {
			return this.formatterBuilderUtils.formatDate(value);
		}

		if (type === AttributeType.NUMBER && !isNaN(value)) {
			//if !Not a number, it is a number
			let parsedValue: DecimalInteger = this.formatterBuilderUtils.splitDecimalAndInteger(value);
			return settings && settings.numeric && parsedValue.decimal
				? this.formatterBuilderUtils.formatDecimals(value, settings.numeric.decimals)
				: this.formatterBuilderUtils.formatNumber('' + value);
		}

		let capitalization = (settings && settings.text && settings.text.capitalization)
			|| CapitalizationType.DEFAULT;
		let capitalizer = CapitalizationUtils.getWrappedFormatter(capitalization);
		let result: string;
		if (this.urlService.isUrl(value)) {
			result = this.urlService.replaceUrlWithLink(value, null, omitHref);
		} else {
			result = HtmlUtils.escapeHtml(value);
		}
		return capitalizer(result);
	}

	attributeHasValue(attr: AttributeValues): boolean {
		return !_.isUndefined(attr.value);
	}

	isAttributeVisible(attribute: AttributeValues, isDocExplorer: boolean, showEmpty: boolean): boolean {
		if (isDocExplorer) {
			return showEmpty || this.attributeHasValue(attribute);
		} else {
			return attribute.isReportable && (showEmpty || this.attributeHasValue(attribute));
		}
	}

	isModelVisible(model: any, isDocExplorer: boolean, projectModels: {[modelId: number]: boolean}): boolean {
		if (!isDocExplorer) {
			return !model.hidden;
		}
		if (!projectModels) {
			return false;
		} else {
			return projectModels[model.modelId] !== false;
		}
	}

	getAttributeSettings = (attribute: IReportAttribute, projectSettingsMap: ProjectSettingsMap): EnrichmentAttributeSettings => {
		let settings = {} as EnrichmentAttributeSettings;

		let capitalization = this.getCapitalization(attribute, projectSettingsMap);
		if (capitalization)
			settings.text = { capitalization };

		let numberFormat = this.getNumberFormat(attribute, projectSettingsMap);
		if (numberFormat)
			settings.numeric = numberFormat;

		return settings;
	}

	private getCapitalization = (attribute: IReportAttribute, projectSettingsMap: ProjectSettingsMap): CapitalizationType => {
		return attribute && projectSettingsMap.textAttributes[attribute.id]?.capitalization;
	}

	private getNumberFormat = (attribute: IReportAttribute, projectSettingsMap: ProjectSettingsMap): NumberFormatSettings => {
		return attribute
			&& projectSettingsMap.numericAttributes[attribute.id];
	}

}

app.service('enrichmentAttributesService', EnrichmentAttributesService);
