import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { RefinementBehavior } from '@app/modules/reports/refinement-behavior-type';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { NumberFormatHelperService } from '@app/modules/widget-visualizations/formatters/number-format-helper.service';
import { ObjectUtils } from '@app/util/object-utils';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { MetricConfigurationRules } from '@cxstudio/reports/providers/cb/definitions/metric-configuration-rules.factory';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { MetricWidgetPOPService } from '@cxstudio/reports/providers/cb/services/metric-widget-pop.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class ReportRunPreparationService {

	// no metrics in PoP colors, so not checking them
	private readonly COLOR_FIELDS = [ColorTypes.PRIMARY, ColorTypes.SECONDARY, ColorTypes.POINT, ColorTypes.SECONDARY_POINT];

	constructor(
		@Inject('reportMetricService') private readonly reportMetricService: ReportMetricService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private readonly numberFormatHelper: NumberFormatHelperService,
		@Inject('metricWidgetPOPService') private readonly metricWidgetPOPService: MetricWidgetPOPService,
		@Inject('periodOverPeriodMetricService') private readonly periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) {}

	private processGrouping(metricGetter: (item: ReportGrouping) => string,
		widgetSettings: Widget, attributes: IReportAttribute[], studioMetrics: Metric[]): void {

		if (!widgetSettings || !widgetSettings.properties) {
			return;
		}

		if (!_.isEmpty(widgetSettings.properties.selectedAttributes)) {
			let selectedAttributes = widgetSettings.properties.selectedAttributes;
			widgetSettings.properties.selectedMetrics = widgetSettings.properties.selectedMetrics || [];
			let selectedMetrics = widgetSettings.properties.selectedMetrics;
			let historicalOptions = this.getSelectedMetricsHistoricalOptions(selectedMetrics);
			let allMetrics = _.union(this.reportMetricService.getStandardMetrics() as any[], attributes, studioMetrics, historicalOptions);
			selectedAttributes.filter(grouping => !AnalyticMetricTypes.isTime(grouping))
				.forEach(grouping => {
					let metricName = metricGetter(grouping);
					if (!SearchableHierarchyUtils.findMetricNameInHierarchy(selectedMetrics, metricName)) {
						let metric = SearchableHierarchyUtils.findMetricNameInHierarchy(allMetrics, metricName);
						if (metric && AnalyticMetricTypes.isAttribute(metric)) { // attribute metrics need newer platform version
							selectedMetrics.push(metric);
						}
					}
				});
		}
	}

	private getSelectedMetricsHistoricalOptions(selectedMetrics: ReportCalculation[]): ReportCalculation[] {
		return _.chain(selectedMetrics)
			.filter(calculation => !calculation.isPopMetric)
			.map(calculation => this.periodOverPeriodMetricService.getAllHistoricalMetrics(calculation))
			.flatten()
			.value();
	}

	processGroupThreshold(widgetSettings: Widget, attributes: IReportAttribute[], studioMetrics: Metric[]): void {
		this.processGrouping(grouping => grouping.displayThreshold?.metricName,
			widgetSettings, attributes, studioMetrics);
	}

	processGroupSortBy(widgetSettings: Widget, attributes: IReportAttribute[], studioMetrics: Metric[]): void {
		this.processGrouping(grouping => grouping.sortBy,
			widgetSettings, attributes, studioMetrics);
	}

	processWidgetCalculationsDefaultProperties(widgetSettings: Widget, studioMetrics: Metric[]): void {
		if (!widgetSettings || !widgetSettings.properties || !widgetSettings.visualProperties) return;
		if (!ReportConstants.isAnalyticWidget(widgetSettings.properties.widgetType)) return;

		this.processCalculationsDefaultProperties(widgetSettings.properties.selectedMetrics, widgetSettings, studioMetrics);
	}

	private processCalculationsDefaultProperties(calculations: ReportCalculation[], widgetSettings: Widget, studioMetrics: Metric[]): void {
		if (!calculations) return;

		calculations.forEach(calculation =>
			this.processCalculationDefaultProperties(calculation, widgetSettings.properties, widgetSettings.visualProperties, studioMetrics));
	}

	private processCalculationDefaultProperties(calculation: ReportCalculation, properties: WidgetProperties,
		visualProperties: VisualProperties, studioMetrics: Metric[]): void {

		if (this.isCalculationDefaultsProcessed(calculation)) return;

		let calculationCopy = ObjectUtils.copy(calculation);
		let calculationDefaults = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(calculation as CalculationWithFormat,
			studioMetrics);

		if (calculation.definition) {
			let metric = _.findWhere(studioMetrics, {id: calculation.id});
			calculation.useDefaultFormat = this.numberFormatHelper.itemHasDefaultFormat(metric)
				&& (calculation.useDefaultFormat || !this.numberFormatHelper.hasFormattingOptions(calculation));
		}

		let uiRules = new MetricConfigurationRules(
			calculationCopy, properties, visualProperties, [], calculation, KeyMetricListTypes.CALCULATION);
		if (uiRules.isCalculationSeries()) {
			calculationDefaults.conversion = MetricMultiplierType.NONE;
		}

		// make sure we add everything we need, without overwriting any customizations
		$.extend(calculationDefaults, calculation);
		$.extend(calculation, calculationDefaults);
	}

	private isCalculationDefaultsProcessed(calculation: ReportCalculation): boolean {
		return !!calculation.dataType;
	}

	addMetricWidgetPopMetrics(props: MetricWidgetProperties): void {
		if (props.widgetType === WidgetType.METRIC && props.useHistoricPeriod) {
			if (props.comparisons && props.comparisons.length) {
				// comparisons are handled in widget preprocessors on backend
				return;
			}
			// this should be redundant now as all PoP metric widgets should have comparisons
			let popMetrics = [];
			let popField = this.metricWidgetPOPService.getPoPField(props.selectedMetrics) || PeriodOverPeriodMetricType.DELTA;
			props.selectedMetrics.forEach((metric) => {
				popMetrics.push(this.periodOverPeriodMetricService.createMetric(metric, PeriodOverPeriodMetricType.HISTORICAL));
				popMetrics.push(this.periodOverPeriodMetricService.createMetric(metric, popField));
			});
			props.selectedMetrics.pushAll(popMetrics);
		}
	}

	applyPropertiesFromDashboard(dashboard: Dashboard, widgetSettings: Widget): void {
		let refinementBehavior = RefinementBehavior.REFINED;
		if (!_.isUndefined(dashboard?.properties?.optimization)) {
			refinementBehavior = dashboard.properties.optimization ? RefinementBehavior.OPTIMIZED : RefinementBehavior.REFINED;
		}
		widgetSettings.properties.refinementBehavior = refinementBehavior;
		if (dashboard.snapshotMetadata) {
			widgetSettings.properties.dashboardSnapshotId = dashboard.snapshotMetadata.id;
		}
		// this will trigger comparison of backend widget processing vs frontend to make sure properties match
		// we should eventually move this feature into e2e
		if ((window as any).reportSnapshotValidation) {
			widgetSettings.properties.reportSnapshotValidation = true;
		}
	}

	populateBrowserProperties(widgetSettings: Widget): void {
		widgetSettings.properties.timezoneOffset = this.$rootScope.clientTimezone
			? this.$rootScope.clientTimezone
			: new Date().getTimezoneOffset();
		widgetSettings.properties.timezoneName = this.$rootScope.clientTimezoneName
			? this.$rootScope.clientTimezoneName
			: moment.tz.guess();
		if (this.environmentService.isIframe()) {
			widgetSettings.properties.referrerUrl = this.environmentService.getFrameLocationHost();
		}
	}
}

app.service('reportRunPreparationService', downgradeInjectable(ReportRunPreparationService));
