import { IGridColumn } from '@cxstudio/grids/grid-column';
import { Inject, Injectable } from '@angular/core';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

export type ColumnFormatter<V, T> = (row: number, cell: number, value: V, columnDef: IGridColumn, dataContext: T) => string;

@Injectable({
	providedIn: 'root'
})
export class SlickGridFormatter<T> {

	constructor(
	) {}

	getSimpleNameFormatter = (gridType: GridTypes): ColumnFormatter<string, T> => {
		return (row, cell, value: string, columnDef, dataContext: T) => {
			let gridTypeClass = `br-${gridType}`;
			value = HtmlUtils.escapeHtml(value);
			return `<span class='${gridTypeClass} br-item-name'>${value}</span>`;
		};
	}
}