import { Component, OnInit, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { RedirectDestinationValues } from '@cxstudio/services/redirect-destination';
import { CasePriorityTypesService } from '@app/modules/alert-subscription-template/services/case-priority-types.service';

@Component({
	selector: 'explorer-engagor-case',
	templateUrl: './explorer-engagor-case.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ExplorerEngagorCaseComponent implements OnInit {

	readonly RESOLVED_STATE: string = 'done';

	@Input() case: EngagorCase;

	caseRedirectionLink: string;

	constructor(
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('security') private security: Security,
		private locale: CxLocaleService,
		private casePriorityTypes: CasePriorityTypesService
	) {}

	ngOnInit(): void {
		// required for validation
		this.caseRedirectionLink = this.generateEngagorRedirectionUrl();
	}

	getCaseId = (): string => {
		return this.case.source.id;
	}

	getCaseStatus = (): string => {
		return this.case.status === this.RESOLVED_STATE
			? this.locale.getString('common.done')
			: this.locale.getString('common.open');
	}

	getCasePriorityText = (): string => this.casePriorityTypes.getPriorityByEngagorLevel(this.case.priority.id).displayName;

	getCaseStatusIcon = (): string => this.case.status === this.RESOLVED_STATE ? 'action-text' : 'text-gray-900';

	getCaseAssignment = (): string => {
		if (this.case.assignment.user_ids.length || this.case.assignment.team_ids.length)
			return this.locale.getString('common.assigned');
	}

	generateEngagorRedirectionUrl = (): string => {
		let caseLink = this.case.permalink;
		let caseLinkIndex = caseLink.indexOf('/messages');
		let redirectPartialLink = caseLink.substring(caseLinkIndex + 1);

		let parameters = {
			masterAccountId: this.security.getMasterAccountId(),
			redirect: redirectPartialLink
		};

		return this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.ENGAGOR, parameters);
	}

	hasCaseAccess = (): boolean => {
		return this.security.has('case_management_access');
	}

	getCaseAriaLabel(): string {
		return this.locale.getString('preview.caseAriaLabel', {caseId: this.getCaseId(), caseTitle: this.case.message?.title || ''});
	}

	getCaseRedirectionAriaLabel(): string {
		return this.locale.getString('preview.caseRedirectionAriaLabel', {caseId: this.getCaseId()});
	}
}
