

export class WidgetError {
	static NO_DATA: WidgetError = WidgetError.withMessage(
		'widget.noData'
	);

	static DATA_ERROR: WidgetError = WidgetError.withMessage(
		'widget.dataError'
	);

	static NEGATIVE_SIZE_METRIC: WidgetError = WidgetError.withMessage(
		'widget.negativeSizeMetric'
	);

	static NULL_INCLUDES: WidgetError = WidgetError.withMessage(
		'widget.nullIncludes'
	);

	static EMBEDDING_NOT_AVAILABLE_IN_IMPERSONATE = WidgetError.withMessage(
		'dashboard.embeddingNotAvailableInImpersonate'
	);

	static QUALTRICS_EMBEDDING_DISABLED = WidgetError.withMessage(
		'widget.qualtricsEmbeddingDisabled'
	);

	static EMBED_NOT_LINKED = WidgetError.withMessage(
		'widget.embedNotLinked'
	);

	static DATA_RESTRICTED: WidgetError = new WidgetError(
		'widget.dataRestricted',
		'widget.dataRestrictedDetails',
		'q-icon-security'
	);

	static PREVIEW_DATA_RESTRICTED: WidgetError = new WidgetError(
		'widget.previewDataRestricted',
		'widget.previewDataRestrictedDetails',
		'q-icon-security'
	);

	message: string;
	description?: string;
	icon?: string;

	constructor(message: string, description?: string, icon?: string) {
		this.message = message;
		this.description = description;
		this.icon = icon;
	}

	static withMessage(message: string): WidgetError {
		return new WidgetError(message);
	}

	public isSimpleMessage() {
		return !this.description;
	}
}