<div class="d-flex flex-direction-column flex-fill overflow-hidden">
	<div class="d-flex flex-direction-column flex-fill overflow-y-auto">
		<div *ngFor="let palette of palettes; let $last = last"
			class="d-flex flex-direction-column">
			<span class="nowrap mb-8" title="{{palette.displayName}}">
				{{palette.displayName | middleEllipsis:30:20}}
			</span>
			<color-array
				[class.mb-8]="!$last"
				[selectedColor]="selectedColor"
				[colors]="palette.colors"
				(colorSelect)="colorSelect.emit($event)">
			</color-array>
		</div>
	</div>
</div>
