import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { ObjectUtils } from '@app/util/object-utils';
import { Security } from '@cxstudio/auth/security-service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { AccountId, ContentProviderId, ProjectId } from '@cxstudio/generic-types';

export class ContentProviderOptionsService {
	constructor(
		private readonly $http,
		private readonly security: Security,
		private readonly cachedHttpService: CachedHttpService,
		private readonly httpHandlers: HttpHandlers,
		private readonly contentProviderManagementApiService: ContentProviderManagementApiService
	) {}


	getContentProviders = () => {
		return this.$http.get('rest/common/contentproviders', {
			cache: false
		}).then(this.transformResponse);
	}

	getAccounts = (contentProvider) => {
		let result;
		if (this.security.isSysAdmin()) {
			result = this.contentProviderManagementApiService.getCpAccounts(contentProvider);
		} else {
			result = this.getUserAccounts(contentProvider);
		}

		return result.then(accountData => accountData.data);
	}


	getUserAccounts = (contentProvider, params?): ng.IHttpPromise<any> => {
		return this.$http.get('rest/common/accounts', {
			params: { contentProvider, params },
			cache: true
		}).then(this.transformResponse);
	}

	getProjects = (contentProviderId: ContentProviderId, accountId: AccountId) => {
		let parameters = {
			contentProviderId,
			accountId
		};

		return this.$http.get('rest/common/projects', {
			params: parameters,
			cache: true
		}).then(this.transformResponse);
	}

	getAccountProjects = (cpId: ContentProviderId, accountIds: AccountId[]) => {
		let parameters = {contentProviderId: cpId, accountId: accountIds};
		return this.$http.get('rest/common/account_projects', {
			params: parameters,
			cache: false
		});
	}


	/**
	 * @description Shortcut to check if user can read data from project
	 * @deprecated, use "projectAccessService" instead
	 */
	hasProjectDataAccess = (cpId: ContentProviderId, accountIds: AccountId | AccountId[], projectId: ProjectId,
		targetUserId, cache = CacheOptions.CACHED) => {

		return this.getProjectsAccessLevel(cpId, accountIds, targetUserId, undefined, cache).then((response) => {
			if (!(response?.data?.data?.length)) return false;

			let projects = response.data.data[0].projects;
			let targetProject = _.find(projects, proj => proj.project.id === projectId);
			return !!(targetProject && targetProject.accessLevel !== ProjectAccessLevelValue.NONE);
		});
	}


	/**
	 * @deprecated use "projectAccessService" instead
	 */
	getProjectsAccessLevel = (cpId: ContentProviderId, accountIds: AccountId | AccountId[], targetUserId: number,
		allowDisabled: boolean, cache: CacheOptions = CacheOptions.CACHED) => {
		let parameters = {
			contentProviderId: cpId,
			accountId: accountIds,
			targetUserId,
			allowDisabled
		};
		return this.cachedHttpService.cache(Caches.PERMISSIONS).get('rest/common/projects_access', {
			params: parameters,
			cache
		});
	}

	getScorecardModels = (contentProviderId: ContentProviderId, accountId: AccountId, projectId: ProjectId,
		user, cache: CacheOptions = CacheOptions.CACHED) => {

		let parameters = {
			contentProviderId,
			accountId,
			projectId,
			user: user || this.security.getEmail()
		};

		return this.$http.post('rest/common/scorecard_models', parameters, { cache, local: false })
			.then(this.transformResponse, this.httpHandlers.error)
			.then(res => res.data);
	}

	// local - for ignoring global error handling
	// only "projects" type is left here
	getOptions = (contentProviderId: ContentProviderId, type, params, local, cache: CacheOptions = CacheOptions.CACHED) => {

		return this.$http.get('rest/common/options', {
			params: {contentProviderId, type, params},
			cache,
			local
		}).then(this.transformResponse, local ? this.httpHandlers.error : undefined);
	}

	validateCP = (cpData, action): ng.IPromise<any> => {
		return this.$http.post(`rest/system-admin/content-providers/validate/${action}`, angular.toJson(cpData))
			.then(this.transformResponse);
	}

	getCaseSensitiveUrlValue = (settings) => {
		let parameters = {
			contentProviderId: settings.contentProviderId,
			accountId: settings.accountId,
			projectId: settings.projectId,
			userEmail: settings.runAs
		};

		let queryParameters = {
			documentId: settings.documentId,
			attributeName: settings.attributeName
		};

		return this.$http.post('rest/common/case-sensitive-url', parameters, { params: queryParameters })
			.then(this.transformResponse, this.httpHandlers.error);
	}

	private transformResponse(response): any {
		return response && ObjectUtils.copy(response.data);
	}
}


app.service('contentProviderOptionsService', ContentProviderOptionsService);
