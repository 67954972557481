import { MetricType } from '@app/modules/metric/entities/metric-type';
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';


export class MetricConverter {

	constructor(
		private locale: ILocale
	) {

	}

	codeToString = (code: MetricType): string => {
		switch (code) {
		case MetricType.TOP_BOX:
			return this.locale.getString('metrics.topBoxType');
		case MetricType.BOTTOM_BOX:
			return this.locale.getString('metrics.bottomBoxType');
		case MetricType.SATISFACTION:
			return this.locale.getString('metrics.satisfactionType');
		case MetricType.FILTER:
			return this.locale.getString('metrics.filterMetric');
		case MetricType.VARIABLE:
			return this.locale.getString('metrics.variable');
		case MetricType.CUSTOM_MATH:
			return this.locale.getString('metrics.custom');
		default:
			return this.locale.getString('metrics.predefined');
		}
	}
}

app.service('metricConverter', MetricConverter);
