import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';

@Directive({
	selector: 'words-selection'
})

export class WordsSelectionUpgrade extends UpgradeComponent {
	@Input() props: WidgetProperties;
	@Input() project: AccountOrWorkspaceProject;
	@Input() item: AttributeGrouping;
	@Input() tagChanged: () => void;
	@Input() ngDisabled: boolean;
	@Input() includeOnly: boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('wordsSelection', elementRef, injector);
	}
}
