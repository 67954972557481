import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { HiddenGroupAssetListComponent } from '@app/modules/project/hidden-group-asset/hidden-group-asset-list.component';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable()
export class HiddenGroupAssetsGridDefinition implements IGridDefinition<HiddenGroupAssetListComponent> {

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter
	) {}

	init = (): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'displayName',
			sortable: true,
			searchable: true,
			minWidth: 80,
			width: 100,
			cssClass: 'cell-title',
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.HIDDEN_GROUP_ASSETS)
		}, {
			id: 'myGroups',
			name: this.locale.getString('administration.myGroups'),
			field: 'groupsWithUser',
			sortable: true,
			minWidth: 120,
			width: 180,
			cssClass: 'cell-my-groups'
		}, {
			id: 'otherGroups',
			name: this.locale.getString('administration.otherGroups'),
			field: 'groupsWithoutUser',
			sortable: true,
			minWidth: 120,
			width: 180,
			cssClass: 'cell-other-groups',
		}];
		return Promise.resolve(columns);
	}
}

app.service('hiddenGroupAssetsGridDefinition', downgradeInjectable(HiddenGroupAssetsGridDefinition));
