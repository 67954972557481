import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { FullscreenEvent } from '@app/core/cx-event.enum';
import { FullscreenService } from '@app/modules/dashboard-actions/fullscreen-button/fullscreen.service';
import { FullScreenAutoRefreshService } from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh.service';
import { Interval } from '@cxstudio/interval/interval.service';
import { OverlayUtils } from '@app/core/ui/overlay-utils.class';

@Component({
	selector: 'fullscreen-autorefresh-panel',
	templateUrl: './fullscreen-autorefresh-panel.component.html'
})
export class FullscreenAutorefreshPanelComponent implements OnInit, OnDestroy {

	refreshState: any;
	autoRefreshTimes: number[];
	commandCenterApplicationOutdated: boolean;

	private subscriptionCancel: () => void;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly eventBus: GlobalEventBus,
		private readonly fullscreen: FullscreenService,
		private readonly fullscreenAutorefresh: FullScreenAutoRefreshService,
		@Inject('interval') private readonly interval: Interval
	) {}

	ngOnInit(): void {
		this.refreshState = this.fullscreenAutorefresh.getRefreshState();
		this.autoRefreshTimes = [5, 10, 30, 60];
		this.subscriptionCancel = this.eventBus.subscribe(FullscreenEvent.APPLICATION_OUTDATED, () => {
			if (this.fullscreen.isFullscreen) {
				this.fullscreenAutorefresh.stop();
				this.interval.stopAllIntervals();
				this.commandCenterApplicationOutdated = true;

				OverlayUtils.addToWidgets();
			}
		});
		this.fullscreen.on('change', this.fullscreenHandler);
	}

	private fullscreenHandler = () => setTimeout(() => this.ref.markForCheck());

	ngOnDestroy(): void {
		this.fullscreen.off('change', this.fullscreenHandler);
		this.fullscreenAutorefresh.stop(true);
		this.fullscreenAutorefresh.destroy();
		if (this.subscriptionCancel)
			this.subscriptionCancel();
	}

	isFullscreen() {
		return this.fullscreen.isFullscreen;
	}

	refreshDashboard() {
		this.refreshState.refreshingDashboard = true;
		this.fullscreenAutorefresh.refreshDashboard();
	}

	selectAutoRefreshTime(autorefreshTime: number) {
		this.fullscreenAutorefresh.start(autorefreshTime);
	}
}

app.directive('fullscreenAutorefreshPanel',
	downgradeComponent({component: FullscreenAutorefreshPanelComponent}) as angular.IDirectiveFactory);
