import { Component, ChangeDetectionStrategy } from '@angular/core';
import { PredictiveStatusService } from '@app/modules/system-administration/status/integration-status/predictive/predictive-status.service';

@Component({
	selector: 'predictive-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredictiveStatusTabComponent {
	constructor(
		public service: PredictiveStatusService,
	) {}
}
