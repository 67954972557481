import * as _ from 'underscore';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SimpleChanges, ChangeUtils } from '@app/util/change-utils';

@Component({
	selector: 'user-bulk-update-table',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<item-grid
	*ngIf="items"
	class="h-100-percent w-100-percent cx-admin-grid br-grid"
	[gridType]="gridType"
	[treeData]="items"
	[gridMode]="gridMode"
	[gridOptions]="gridOptions"
	[gridFilter]="itemFilter"
	[gridHeaderButton]="true"
	[gridHeaderButtonFunction]="selectAll"
	[gridDataChange]="userAdded"
	[gridChange]="lastChange"
	[nameField]="gridNameField"
	[idField]="gridIdField"
	[controller]="this">
</item-grid>`
})
export class UserBulkUpdateTableComponent implements OnInit, OnChanges {
	@Input() gridType: GridTypes;
	@Input() gridNameField: string;
	@Input() gridIdField: string;
	@Input() items: any[];
	@Input() itemFilter: string;
	@Input() updateTrigger: boolean;
	@Output() update = new EventEmitter<any>();

	existingItems: any[];
	gridMode = GridMode.EDIT;
	gridOptions;
	lastChange: any[];

	ngOnInit(): void {
		this.gridOptions = {
			onClick: this.onClick
		};
	}

	ngOnChanges(changes: SimpleChanges<UserBulkUpdateTableComponent>): void {
		if (ChangeUtils.hasChange(changes.updateTrigger)) {
			this.lastChange = [].concat(this.items);
		}
	}

	private onClick = (event, item, args) => {
		let target = $(event.target);
		if (event.target.type === 'radio') {
			let newValue = this.getUpdateValue(item, target);
			let clearSelectAllFlag = this.needClearSelectAll(item, newValue);
			item.updateValue = newValue;

			if (clearSelectAllFlag) {
				let grid = args.grid;
				_.chain(grid.getColumns())
					.filter((column: IGridColumn) => column.selectAll)
					.each((column: IGridColumn) => {
						column.selectAll = false;
						column.header.buttons[0].cssClass = this.getRadioButtonClass(false);
						grid.updateColumnHeader(column.id);
					});
			}
			this.update.emit(item);
		}
	}

	private getRadioButtonClass(selectAll: boolean): string {
		return 'action-color '
			+ (selectAll ? 'q-icon-check' : 'q-icon-circle');
	}

	private getUpdateValue(object, target): BulkUpdateValue {
		for (let value in BulkUpdateValue) {
			//check if has css class
			if (target.parents('.' + BulkUpdateValue[value]).length) {
				if (BulkUpdateValue[value] === BulkUpdateValue.ADD && object.disableAdd) {
					//return original if disable add
					return object.updateValue;
				}
				return BulkUpdateValue[value];
			}
		}
	}

	private needClearSelectAll(object, newValue: BulkUpdateValue): boolean {
		let changed = object.updateValue !== newValue;
		if (newValue === BulkUpdateValue.NONE) {
			//no change undefined is equal to BulkUpdateValue.NONE
			return !_.isUndefined(object.updateValue) && changed;
		}
		return changed;
	}

	selectAll = (command, column) => {
		if (column.selectAll) {
			_.chain(this.items)
				.filter(item =>
					_.isEmpty(this.itemFilter) || item.displayName.toLowerCase().indexOf(this.itemFilter.toLowerCase()) > -1)
				.filter(item => !item.disableAdd || column.id !== BulkUpdateValue.ADD)
				.each(item => item.updateValue = column.id);
		}
	}

	userAdded = (grid) => {
		let selectAllColumn = _.find(grid.getColumns(), (column: IGridColumn) => column.selectAll);
		if (selectAllColumn) {
			this.selectAll(undefined, selectAllColumn);
		}
	}
}

app.directive('userBulkUpdateTable', downgradeComponent({ component: UserBulkUpdateTableComponent }));
