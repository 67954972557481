import Widget from '@cxstudio/dashboards/widgets/widget';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { AmplitudeEventData } from '@app/modules/analytics/amplitude/amplitude-event-data';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { TypeGuards } from '@app/util/typeguards.class';
import { DashboardTemplateTypes } from '@cxstudio/dashboard-templates/dashboard-template-types';
import { CreatedDashboardBase } from '@cxstudio/dashboards/dashboard-service';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { DashboardTemplate } from '@app/modules/unified-templates/unified-templates-management/dashboard-templates-management/dashboard-templates-management.component';
import { AmplitudeWidgetEventData } from './amplitude-widget-event-data.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { AmplitudeDocumentType, DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { AmplitudeDocumentSource } from '@app/modules/document-explorer/amplitude-document-source.enum';
import { ContextPaneSubpanels } from '@app/modules/document-explorer/context-pane/context-pane-subpanels.enum';
import { AmplitudeExplorerTabName } from '@app/modules/document-explorer/context-pane/tabbed-context-pane/amplitude-explorer-tab-name.enum';
import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';

export enum CreatedDashboardType {
	NEW = 'homepage',
	DUPLICATE = 'duplicate'
}

export interface DocumentViewEvent {
	source: AmplitudeDocumentSource;
	documentId: number;
	documentType: AmplitudeDocumentType;
}

export const AmplitudeOtherTemplates = 'Other Templates';

export class AmplitudeEventUtils {

	static dashboardViewData(dashboard: Dashboard, widgets: Widget[], source: 'dashboard' | 'book' | 'embedded'): AmplitudeEventData {
		let appliedFilters = dashboard.appliedFiltersArray ?? [];
		let dateRange = _.find(appliedFilters, filter => DashboardFilterSelection.isDateRange(filter?.selectedAttribute));
		// this is draft version, in CB-24353 need to carefully calculate filter count:
		// need to discuss whether to calculate: org filter, text filter, saved filter (as 1 or as selected amount), date filter
		// currently skipping only org filter
		return {
			source,
			dashboardId: dashboard.id,
			widgetCount: widgets.length,
			filterCount: appliedFilters.filter(filter => !filter?.isOrgHierarchy).length,
			dateRange: dateRange?.selectedAttributeValue.dateFilterMode, // need to format this
			intelligentScoreData: AmplitudeEventUtils.hasIntelligentScoringData(widgets)
		};
	}

	static dashboardCreateData(sourceObject?: CreatedDashboardBase): AmplitudeEventData {
		let eventData;
		if (sourceObject) {
			if (TypeGuards.isDashboard(sourceObject)) {
				return { type: CreatedDashboardType.DUPLICATE };
			} else {
				eventData = {
					type: CreatedDashboardType.NEW,
					templateType: this.getDashboardTemplateType(sourceObject),
					templateOtherName: sourceObject.name,
					// TODO: this was requested but not currently available
					// templateOtherFolder,
				};
			}
		} else {
			return { type: CreatedDashboardType.NEW };
		}

		return eventData;
	}

	/**
	 * If source object is an OG internal template, give the name, otherwise just show "Other templates"
	 */
	private static getDashboardTemplateType(sourceObject: UnifiedTemplate | DashboardTemplate) {
		if (!TypeGuards.isUnifiedDashboardTemplate(sourceObject) && sourceObject.type === DashboardTemplateTypes.SYSTEM) {
			return sourceObject.name;
		} else {
			// this is only for analytics, so should probably not be localized
			return AmplitudeOtherTemplates;
		}
	}

	private static hasIntelligentScoringData(widgets): boolean {
		for (let w of widgets) {
			if (AmplitudeWidgetEventData.hasIntelligentScoringPane(w) === 'Enabled') {
				return true;
			} else if (AmplitudeWidgetEventData.hasIntelligentScoringPane(w) === 'Disabled') {
				// it's a preview widget, but has intelligent scoring disabled
				continue;
			}

			if (!!w.properties.selectedMetrics?.find(m => m.type === MetricType.SCORECARD)) {
				return true;
			}
		}

		return false;
	}

	static getBaseDocumentViewEvent(document: CbDocument, isDocExplorer: boolean): DocumentViewEvent {
		const documentId = (document ?? {}).id;
		const documentType = DocumentTypeUtils.getAmplitudeDocumentType(document);
		const source = isDocExplorer ?
			AmplitudeDocumentSource.DOC_EXPLORER:
			AmplitudeDocumentSource.FEEDBACK_WIDGET;
		return { source, documentId, documentType };
	}

	/**
	 * Return english-only explorer tab name for amplitude tracking
	 */
	static getAmplitudeTabName(tabId: ContextPaneSubpanels | FavoriteType): AmplitudeExplorerTabName {
		switch (tabId) {
			case ContextPaneSubpanels.ATTRIBUTES:
			case FavoriteType.ATTRIBUTE:
				return AmplitudeExplorerTabName.ATTRIBUTES;
			case ContextPaneSubpanels.ENRICHMENTS:
			case FavoriteType.WORLD_AWARENESS:
				return AmplitudeExplorerTabName.ENRICHMENTS;
			case ContextPaneSubpanels.TOPICS:
			case FavoriteType.MODEL:
				return AmplitudeExplorerTabName.TOPICS;
			case ContextPaneSubpanels.SCORECARDS:
			case FavoriteType.SCORECARDS:
				return AmplitudeExplorerTabName.SCORECARDS;
			case ContextPaneSubpanels.AUTOMATED_SUMMARIES:
			case FavoriteType.AUTOMATED_SUMMARIES:
				return AmplitudeExplorerTabName.AUTOMATED_SUMMARIES;
			case ContextPaneSubpanels.INBOX:
			case FavoriteType.ENGAGE:
				return AmplitudeExplorerTabName.INBOX;
			case ContextPaneSubpanels.KEY_INFO:
				return AmplitudeExplorerTabName.KEY_INFO;
			case ContextPaneSubpanels.COMPLIANCE_MANAGEMENT:
				return AmplitudeExplorerTabName.COMPLIANCE_MANAGEMENT;
		}
	}

}
