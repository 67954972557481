<!-- deprecated, need to move to unified template ui -->
<ng-template #popContent let-placeholder="placeholder">
	<aside class="text-0_875rem p-8">
		<p *ngIf="placeholder.dashboardFilters?.length" class="m-0"><b>{{'templates.dashboardFilterPlaceholder'|i18n}}</b></p>
		<p *ngIf="placeholder.widgets?.length" class="m-0"><b>{{'templates.placeholderUsedInWidgets'|i18n}}:</b></p>
		<p *ngFor="let widget of placeholder.widgets" class="ml-8 mb-0">- {{getPlacementDescription(widget)}}</p>
	</aside>
</ng-template>

<div class="form-group template-placeholder-group-title">
	<label class="mandatory col-sm-5">{{groupTitle}}</label>
	<label class="mandatory col-sm-7" [i18n]="'templates.placeholderDescription'"></label>
</div>
<div class="template-placeholder-list">
	<div *ngFor="let placeholder of placeholders | availablePlaceholders" class="form-group template-placeholder-item">
		<ng-container *ngIf="showPlacement">
			<span class="col-sm-5">
				<span [ngbPopover]="popContent" #p1=ngbPopover
						triggers="manual"
						(mouseenter)="showPopover(placeholder, p1)"
						(mouseleave)="hidePopover(p1)"
						popoverClass="wide-popover"
						container="body">
					<i class="q-icon q-icon-info template-placeholder-item-info-icon cxs-brand-primary ml-8"></i>
				</span>
				{{placeholder.originalDisplayName}}
			</span>
			<span class="col-sm-7">
				<input type="text" [(ngModel)]="placeholder.displayName"
						[ngbPopover]="popContent" #p2=ngbPopover
						popoverClass="wide-popover"
						triggers="manual"
						(focus)="showPopover(placeholder, p2)"
						(blur)="hidePopover(p2)"
						container="body"
						placeholder="{{placeholder.originalDisplayName}}" class="template-placeholder-display-name-input">
			</span>
		</ng-container>
		<ng-container *ngIf="!showPlacement">
			<span class="col-sm-5">
				<span class="ml-8">
					{{placeholder.originalDisplayName}}
				</span>
			</span>
			<span class="col-sm-7">
				<input class="template-placeholder-display-name-input"
					[(ngModel)]="placeholder.displayName"
					placeholder="{{placeholder.originalDisplayName}}">
			</span>
		</ng-container>
	</div>
</div>
