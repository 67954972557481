import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { MetricEditorDialogComponent } from '@app/modules/metric/editor/metric-editor-dialog.component';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { ScorecardFiltersManagementService } from '@app/modules/scorecards/filters/scorecard-filters-management.service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { IScorecardFilter } from '@app/modules/scorecards/entities/scorecard-filter';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectUtils } from '@app/util/object-utils';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { MetricEditorDialogOutput } from './metric-editor-dialog-output.interface';
import { MetricEditorDialogInput } from './metric-editor-dialog-input.interface';
import { IMetricAssets } from './metric-assets.interface';

@Injectable({
	providedIn: 'root'
})
export class MetricEditorUtilsService {

	constructor(
		private readonly filtersService: FiltersService,
		private readonly metricsService: MetricsService,
		private readonly projectContextService: ProjectContextService,
		private readonly cxDialogService: CxDialogService,
		@Inject('projectSettingsService') private readonly projectSettingsService: ProjectSettingsService,
		private readonly scorecardFiltersManagementService: ScorecardFiltersManagementService,
	) { }

	openMetricModal(
		projectSelection: AccountOrWorkspaceProject, metric: Metric, mode: MetricMode,
		folders?: any[], selectedFolder?: IFolderItem, editors?: any[]
	): Promise<MetricEditorDialogOutput> {
		if (!selectedFolder && metric && metric.parentId) {
			selectedFolder = _.findWhere(folders, {id: metric.parentId});
		}
		let project = ObjectUtils.copy(projectSelection);
		if (ValueUtils.isNotSelected(project.projectId) && ValueUtils.isSelected(metric.projectId)) {
			project.projectId = metric.projectId;
		}

		let input: MetricEditorDialogInput = {
			metric: ObjectUtils.copy(metric) as any,
			selectedFolder,
			folders,
			project,
			mode,
		};

		return this.cxDialogService.openDialog(MetricEditorDialogComponent, input,
			{size: ModalSize.MEDIUM, keyboard: false}).result;
	}

	getMetricEditorAssets(project: AccountOrWorkspaceProject): Promise<IMetricAssets> {
		// TODO move all data requests to nested components when new metric editor is GA
		let promises: Promise<any>[] = [
			PromiseUtils.wrap(this.projectSettingsService.getSettings(project, CacheOptions.CACHED)),
			this.filtersService.getStudioFiltersWithPredefined(project),
			this.scorecardFiltersManagementService.getScorecardFilters(project),
			this.metricsService.getMetrics(project),
			this.metricsService.getPredefinedMetrics(project),
			this.projectContextService.getProjectTimezone(project),
		];

		return Promise.all(promises).then((data) => {
			let projectSettings = data[0] as IProjectSettings;
			return {
				attributes: projectSettings.attributes,
				wordAttributes: projectSettings.wordAttributes,
				models: projectSettings.models,
				filters: _.filter(data[1] as IFilter[], (item: any) => !item.hide),
				scorecardFilters: _.filter(data[2] as IScorecardFilter[], (item: any) => !item.hide && !item.disabled),
				metrics: data[3] as Metric[],
				predefinedMetrics: data[4] as Metric[],
				projectTimezone: data[5] as string,
			};
		});
	}
}

app.service('metricEditorUtils', downgradeInjectable(MetricEditorUtilsService));
