import { DrillPoint } from './../../entities/drill-point';
import Widget from '@cxstudio/dashboards/widgets/widget';
import {FULL_PATH, GroupIdentifierHelper} from './group-identifier-helper';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';

export class PointSelectionUtils {
	private allSelections: {[containerId: string]: {[widgetId: number]: string | string[]}} = {};

	enablePointSelection(containerId: string, widgetId: number, enabled: boolean): void {
		let widget = this.getContainer(containerId).find('#widget-' + widgetId);
		if (enabled) {
			widget.addClass('point-selection');
		} else {
			widget.removeClass('point-selection');
			delete this.getSelections(containerId)[widgetId];
		}
	}

	isPointSelectionEnabled(containerId: string, widgetId: number): boolean {
		let widget = this.getContainer(containerId).find('#widget-' + widgetId);
		return widget.hasClass('point-selection');
	}

	enableWrongSelectionWarning(containerId: string, widgetId: number, enabled: boolean): void {
		let widget = this.getContainer(containerId).find('#widget-' + widgetId);
		if (enabled) {
			widget.addClass('point-selection-warning');
		} else {
			widget.removeClass('point-selection-warning');
		}
	}

	selectPoint(containerId: string, widget: Widget, point: DrillPoint, isDemo: boolean = false): void {
		// to support selector pdf export
		if (ReportConstants.isSelectorWidget(widget.properties?.widgetType)) {
			this.selectPointInSelector(containerId, widget.id, point);
			return;
		}
		this.getContainer(containerId).find('#widget-' + widget.id + ' [selected]').removeAttr('selected');
		if (widget.properties) {

			let groups = GroupIdentifierHelper.getGroupings(widget.properties.selectedAttributes);
			this.getSelections(containerId)[widget.id] = this.generatePointId(point, groups);
			if (point._element) {
				if (point._element.chart) { // default highcharts
					this.highlightSelectedPoints(containerId, widget.id, groups, isDemo)(point._element.chart);
				} else { // cloud, table, preview
					this.highlightElement(point._element);
				}
			} else if (point._highlightPoint) {
				point._highlightPoint();
			}
		}
	}

	unselectPoint(containerId: string, widget: Widget, point: DrillPoint, isDemo: boolean): void {
		if (point._unhighlightPoint) {
			point._unhighlightPoint();
		}
	}

	selectPointInSelector(containerId: string, widgetId: number, point: DrillPoint): void {
		let selectedPoints = this.getSelections(containerId)[widgetId] as string[];
		if (!selectedPoints) {
			selectedPoints = this.getSelections(containerId)[widgetId] = [];
		}
		if (!_.contains(selectedPoints, point._uniqName)) {
			selectedPoints.push(point._uniqName);
		}
	}

	setPointsInSelector(containerId: string, widgetId: number, pointIds: string[]): void {
		this.getSelections(containerId)[widgetId] = pointIds;
	}

	generatePointId(point: DrillPoint, groups: ReportGrouping[]): string {
		if (!_.isUndefined(point._selectionId))
			return point._selectionId;
		let values = angular.copy(point);
		//get path as unique key
		let allGroups = _.chain(groups)
			.filter((group: ReportGrouping): boolean => {
				return group && group.name !== '_pop';
			}).map((group: ReportGrouping): string => {
				if (group.type === ReportAssetType.TOPICS || group.type === ReportAssetType.TOPIC_LEAF) {
					return group.identifier + FULL_PATH;
				} else if (group.type === ReportAssetType.HIERARCHY_MODEL) {
					return group.identifier + '_hierarchyNodeId';
				}
				return group.identifier;
			}).value();
		return _.chain(values).pick(allGroups).toArray().value().join('___');
	}

	highlightElement(element): void {
		$(element).attr('selected', 'true');
	}

	// only highcharts, table and cloud are handled separately
	highlightSelectedPoints(containerId: string, widgetId: number, groups, isDemo: boolean = false): (chart) => void {
		if (isDemo) {
			return chart => {};
		}
		this.enableWrongSelectionWarning(containerId, widgetId, false);

		let selection = this.getSelections(containerId)[widgetId];
		if (!selection)
			return chart => {};

		this.enablePointSelection(containerId, widgetId, true);

		return (chart) => {
			let pointId = selection;
			let hasSelections: boolean = false;

			let findPoint = (point) => {
				if (pointId.includes('_linkedTo_')) {
					let nodes = (pointId as string).split('_linkedTo_');
					return point.object._selectionId === pointId || nodes.contains(point.object._uniqName);
				}
				return point && point.object && this.generatePointId(point.object, groups) === pointId;
			};

			function getElement(point): any {
				return point && point.graphic && point.graphic.element;
			}

			for (let oneSeries of chart.series) {
				let points = oneSeries.data;
				if (oneSeries.type === 'networkgraph') {
					points = points.concat(oneSeries.nodes);
				}
				let selectedSeries: any[] = _.chain(points)
					.filter(findPoint)
					.map(getElement)
					.each(this.highlightElement)
					.value();

				hasSelections = hasSelections || selectedSeries.length > 0;
			}

			this.enableWrongSelectionWarning(containerId, widgetId, !hasSelections);
		};
	}

	getSelectedPoint(containerId: string, widgetId: number): string | string[] {
		return this.getSelections(containerId)[widgetId];
	}

	resetSelections(containerId: string): void {
		this.allSelections[containerId] = {};
	}

	private getContainer(containerId: string): JQuery {
		return $('#container-' + containerId);
	}

	getSelections(containerId: string): {[widgetId: number]: string | string[]} {
		let selections = this.allSelections[containerId];
		if (!selections) {
			selections = this.allSelections[containerId] = {};
		}
		return selections;
	}

	deleteSelections(containerId: string, widgetId: number): void {
		let selections = this.getSelections(containerId);
		delete selections[widgetId];
	}
}


app.service('pointSelectionUtils', PointSelectionUtils);

