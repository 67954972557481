import { PagedResponse } from '@cxstudio/paged-response';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { AssetComponent } from '@cxstudio/asset-management/components/asset-component';

import { CxLocaleService } from '@app/core';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { IExportHandler } from '@app/modules/asset-management/entities/export-handler.interface';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { AssetObjectTypes } from '@app/modules/asset-management/entities/asset-object-types';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';


type ComponentTypeHandler = (asset: ProjectAsset, pagination: Pagination) => Promise<PagedResponse<AssetComponent>>;


@Injectable()
export class ComponentTypesProvider {

	componentTypes: Array<AssetObjectType<ProjectAsset, AssetComponent>>;

	title: string;
	exportHandler: IExportHandler<StudioAsset>;
	private allTypes: AssetObjectTypes<ProjectAsset, AssetComponent>;

	constructor(
		private locale: CxLocaleService,
		private dashboardComponentsApiService: DashboardComponentsApiService,
	) {
		this.allTypes = new AssetObjectTypes(this.locale);

		this.componentTypes = [
			this.withHandler(ObjectType.MODEL, this.dashboardComponentsApiService.getModels),
			this.withHandler(ObjectType.ATTRIBUTE, this.dashboardComponentsApiService.getAttributes),
			this.withHandler(ObjectType.FILTER, this.dashboardComponentsApiService.getFilters),
			this.withHandlerAndNote(ObjectType.METRIC, this.dashboardComponentsApiService.getMetrics,
				this.locale.getString('common.onlyStudioNote', {pluralObjectType: this.locale.getString('metrics.metrics')}))
		];
		this.title = locale.getString('dashboard.dashboard');
		this.exportHandler = this.dashboardComponentsApiService;
	}

	private withHandler = (objectType: ObjectType, handler: ComponentTypeHandler):
			AssetObjectType<ProjectAsset, AssetComponent> => {
		let type = this.allTypes.items[objectType];
		type.handler = handler;
		return type;
	}

	private withHandlerAndNote = (objectType: ObjectType, handler: ComponentTypeHandler, note: string):
			AssetObjectType<ProjectAsset, AssetComponent> => {
		let type = this.allTypes.items[objectType];
		type.handler = handler;
		type.note = note;
		return type;
	}
}

app.service('componentTypesProvider', downgradeInjectable(ComponentTypesProvider));
