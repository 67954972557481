
import * as _ from 'underscore';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { Model } from '@cxstudio/reports/entities/model';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ModelsService } from '@app/modules/project/model/models.service';


export class AttributeLoader {

	private static readonly ID_SOURCE = -2000;

	static noSpecialAttributes = (item) => {
		return item.id > 0 || item.id === AttributeLoader.ID_SOURCE;
	}

	constructor(
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
		private $filter: ng.IFilterService,
		private $q: ng.IQService,
		private $log: ng.ILogService ) {  }


	loadAttributes = (provider, account, project, requiresModels): ng.IPromise<void> => {
		let projectIdentifier = {
			contentProviderId: provider.id,
			accountId: account.id,
			projectId: project.id,
		};
		let attributesPromise = PromiseUtils.old(this.attributesService.getAttributes(projectIdentifier));
		let promises: Array<ng.IPromise<any>> = [attributesPromise];

		if (requiresModels) {
			let modelsPromise = PromiseUtils.old(this.modelsService.getModels(projectIdentifier));
			promises.push(modelsPromise);
		}

		return this.$q.all(promises).then((response: any) => {
			let attributes = response[0];

			if (requiresModels) {
				attributes = _.union(this.processAttributes(attributes), this.processModels(response[1]));
			}

			provider.attributes = this.$filter('filter')(attributes, AttributeLoader.noSpecialAttributes);

			this.$log.log('Loading attributes: ', provider.attributes);
		});
	}

	processModels = (models: Model[]): any[] => {
		return _.map(models, (model) => {
			return _.extend(model, {displayName: model.name, model: true});
		});
	}

	processAttributes = (attributes: AttributeGrouping[]): any[] => {
		return _.map(attributes, (attribute) => {
			return _.extend(attribute, { model: false});
		});
	}
}


app.service('attributeLoader', AttributeLoader);
