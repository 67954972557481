import { AfterViewInit, ChangeDetectorRef, ElementRef,
	Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Pill, PillType } from '@app/modules/pills/pill';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { AuditSuggestion, AuditSuggestionEnrichment } from '@cxstudio/reports/document-explorer/audit-suggestion';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { ConversationUtils } from '@app/modules/conversation/conversation-utils.service';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { Security } from '@cxstudio/auth/security-service';


@Component({
	selector: 'tuning-suggestion-summary',
	template: `
<collapsing-panel>
	<panel-heading>{{'preview.tuningSummaryHeading'|i18n}}</panel-heading>
	<panel-body>
		<p *ngIf="!docTypeUtils.isConversation(document)">
			<sentence-preview
				[sentence]="getVoiceSentence()"
				[showSentiment]="isSentiment()"
				[sentimentColorFunc]="formatters.sentimentColor">
			</sentence-preview>
		</p>
		<chat-message
			*ngIf="docTypeUtils.isChat(document)"
			[formatters]="formatters"
			[chatMessage]="getChatMessage()"
			[channelLabels]="channelLabels"
			[messageWidth]="messageWidth"
			[expandTopics]="true"
			[compact]="true"
			[ngClass]="{'hide-sentiment-formatting': !isSentiment()}"
			class="conversation-segment clearfix pointer-events-none">
		</chat-message>

		<voice-message
			*ngIf="docTypeUtils.isConversation(document) && !docTypeUtils.isChat(document)"
			[formatters]="formatters"
			[transcript]="getVoiceSentence()"
			[channelLabels]="channelLabels"
			[messageWidth]="messageWidth"
			[expandTopics]="true"
			[compact]="true"
			[ngClass]="{'hide-sentiment-formatting': !isSentiment()}"
			class="conversation-segment clearfix pointer-events-none">
		</voice-message>
		<div *ngIf="isTopic()">
			<div class="text-0_75rem pv-8">
				<span><b>{{ (isAddingTopic() ? 'cases.addTopic' : 'cases.removeTopic') | i18n}}</b></span>
			</div>
			<collapsible-pills
				[pills]="getPills()"
				[useContrastTextColor]="true"
			></collapsible-pills>
		</div>
		<value-change-summary *ngIf="!isTopic()">
			<current-value [innerHTML]="getCurrent() | safeHTML"></current-value>
			<suggested-value [innerHTML]="getSuggested() | safeHTML"></suggested-value>
		</value-change-summary>
	</panel-body>
</collapsing-panel>`,
	styles: [
		`.pointer-events-none { pointer-events: none }`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TuningSuggestionSummaryComponent implements OnInit, AfterViewInit {

	@Input() document: CbDocument | ConversationDocument;
	@Input() auditSuggestion: AuditSuggestion;
	@Input() formatters;
	@Input() channelLabels: ConversationChannelLabels;
	messageWidth: number;
	docTypeUtils;

	readonly NONE_TEXT = this.locale.getString('common.none');

	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
		private elementRef: ElementRef,
		private conversationUtils: ConversationUtils,
		@Inject('security') private readonly security: Security,
	) { }

	ngOnInit(): void {
		this.docTypeUtils = {
			isConversation: DocumentTypeUtils.isConversation,
			isChat: (doc) => DocumentTypeUtils.isChat(doc, this.security.isVoiceEnabled()),
		};

		if (DocumentTypeUtils.isChat(this.document, this.security.isVoiceEnabled())) {
			this.conversationUtils.populateChatSentences(this.document as ConversationDocument);
		}
	}

	ngAfterViewInit(): void {
		let width = $(this.elementRef.nativeElement).find('.sentence-text-container').first().outerWidth();
		this.messageWidth = width && Math.floor(width);
		this.ref.markForCheck();
	}

	getCurrent(): string {
		return this.auditSuggestion?.currentOption?.name ?
				this.auditSuggestion.currentOption.text :
				this.NONE_TEXT;
	}

	getSuggested(): string {
		return this.auditSuggestion?.newOption?.name ?
				this.auditSuggestion.newOption.text :
				this.NONE_TEXT;
	}

	getVoiceSentence(): any {
		return _.find(this.document.sentences, {id: this.auditSuggestion.sentenceId});
	}

	getChatMessage(): any {
		return _.find((this.document as ConversationDocument).chatMessages,
			(chatMsg) => chatMsg.groupedIds.contains(this.auditSuggestion.sentenceId));
	}

	isTopic(): boolean {
		return this.auditSuggestion?.enrichment === AuditSuggestionEnrichment.TOPIC;
	}

	isSentiment(): boolean {
		return this.auditSuggestion?.enrichment === AuditSuggestionEnrichment.SENTIMENT;
	}

	private getTopic(): string {
		if (this.isTopic()) {
			return this.auditSuggestion.currentValue ?
				this.auditSuggestion.currentValue :
				this.auditSuggestion.newValue;
		}
		return this.NONE_TEXT;
	}

	isAddingTopic(): boolean {
		return this.isTopic() && !this.auditSuggestion.currentValue;
	}

	getPills(): Pill[] {
		let topic = this.getTopic();
		return [{ name: topic, type: PillType.TOPIC, title: topic, value: topic}];
 	}

}

app.directive('tuningSuggestionSummary', downgradeComponent({ component: TuningSuggestionSummaryComponent}) as angular.IDirectiveFactory);
