import { Input, Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, HostBinding } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { TuningSuggestionsService } from '@app/modules/document-explorer/tuning-suggestions/tuning-suggestions.service';

@Component({
	selector: 'tuning-suggestions-button',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<button class="btn btn-icon interaction-audit-toggle no-border"
		*ngIf="isAuditModeAvailable()"
		[class.enabled]="isAuditModeEnabled()"
		[ngClass]="isAuditModeEnabled() ? 'q-icon-delete' : 'q-icon-flag'"
		[disabled]="!hasTemplates || !!disabled"
		(click)="toggleAuditMode()"
		attr.aria-label="{{getAuditModeToggleTooltip()}}"
		[ngbTooltip]="getAuditModeToggleTooltip()"
		[placement]="tooltipPlacement"
		tooltipClass="interaction-audit-mode-tooltip">
	</button>
	`
})
export class TuningSuggestionsButtonComponent implements OnInit {

	@Output() stateChange = new EventEmitter<boolean>();
	@Input() hasTemplates: boolean;
	@Input() tooltipPlacement: string = 'bottom';
	@Input() disabled: boolean;

	private auditModeEnabled = false;

	constructor(
		private locale: CxLocaleService,
		private tuningSuggestionsService: TuningSuggestionsService
	) { }

	ngOnInit(): void {
	}

	isAuditModeAvailable = (): boolean => {
		return this.tuningSuggestionsService.isAvailable();
	}

	toggleAuditMode = () => {
		this.auditModeEnabled = !this.auditModeEnabled;
		this.stateChange.emit(this.auditModeEnabled);
	}

	getAuditModeToggleTooltip = (): string => {
		if (this.isAuditModeEnabled()) {
			return this.locale.getString('widget.exit');
		}
		return this.locale.getString('widget.submitSuggestion');
	}

	@HostBinding('title')
	get getDisabledMessage(): string {
		if (!this.hasTemplates) {
			return this.locale.getString('widget.suggestionModeUnavailabe');
		}
		return '';
	}

	isAuditModeEnabled = (): boolean => {
		return this.auditModeEnabled;
	}
}

app.directive('tuningSuggestionsButton', downgradeComponent({component: TuningSuggestionsButtonComponent}) as angular.IDirectiveFactory);
