import { ISelectableItem } from '@cxstudio/common/entities/selectable-item';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SelectAttributeModalTypes } from '@app/modules/hierarchy/attributes/select-attribute-modal-types';
import {
	SelectAttributeModalTypesService
} from '@app/modules/hierarchy/attributes/select-attribute-modal-types-service';

enum Operation {
	ADD = 'add',
	REMOVE = 'remove',
	UP = 'up',
	DOWN = 'down'
}
export class AttributesModalCtrl implements ng.IController {


	constructor(
		private readonly $scope,
		private readonly $filter: ng.IFilterService,
		private readonly $uibModalInstance,
		private readonly contentProvider,
		private readonly locale: ILocale,
		private readonly maxAmountOfAttributes: number,
		private readonly modalType: SelectAttributeModalTypes,
		private readonly selectAttributeModalTypesService: SelectAttributeModalTypesService,
		private readonly $sce
	) {}

	$onInit(): void {
		this.$scope.contentProvider = this.contentProvider;
		this.$scope.maxAttributes = this.maxAmountOfAttributes;

		this.$scope.orderByField = this.selectAttributeModalTypesService.getOrderByField();
		this.$scope.selectAttrsForDisplayText = this.selectAttributeModalTypesService.getSelectAttrsForDisplayText(this.modalType,
			this.maxAmountOfAttributes);
		this.$scope.objectAvailableDescriptor = this.selectAttributeModalTypesService.getObjectAvailableDescriptor(this.modalType);
		this.$scope.objectSelectedDescriptor = this.selectAttributeModalTypesService.getObjectSelectedDescriptor(this.modalType);

		this.$scope.showAddAllAttributesButton = this.showAddAllAttributesButton;
		this.$scope.deselectAllAttributes = this.deselectAllAttributes;
		this.$scope.selectAllAttributes = this.selectAllAttributes;
		this.$scope.getSelectedAttributes = this.getSelectedAttributes;
		this.$scope.unselectedItems = this.unselectedItems;
		this.$scope.selectedItems = this.selectedItems;
		this.$scope.removeButtonTxt = this.removeButtonTxt;
		this.$scope.addButtonTxt = this.addButtonTxt;
		this.$scope.getLastOrderIndex = this.getLastOrderIndex;
		this.$scope.getNextOrderIndex = this.getNextOrderIndex;
		this.$scope.changeAttributeOrder = this.changeAttributeOrder;
		this.$scope.toggleSelections = this.toggleSelections;
		this.$scope.getAttributeByOrderIndex = this.getAttributeByOrderIndex;
		this.$scope.moveAttribute = this.moveAttribute;
		this.$scope.allowMoveAttribute = this.allowMoveAttribute;
		this.$scope.dismiss = this.dismiss;
		this.$scope.save = this.save;
		this.$scope.allowSelectAttributes = this.allowSelectAttributes;
		this.$scope.allowDeselectAttributes = this.allowDeselectAttributes;
		this.$scope.getMaxNumberOfAttributes = this.getMaxNumberOfAttributes;
		this.$scope.getModalTitle = this.getModalTitle;

		this.$scope.Direction = {
			UP: Operation.UP,
			DOWN: Operation.DOWN
		};
	}

	filteredAttributes = (): any[] => {
		return this.$filter('filter')(this.contentProvider.attributes, {
			displayName: this.$scope.attributeFilter,
			selected: '!true'
		});
	}

	limitOfSelection = (): number => {
		if (this.maxAmountOfAttributes) {
			return this.maxAmountOfAttributes - this.getSelectedAttributes(this.contentProvider).length;
		} else {
			return 1000;
		}
	}

	showAddAllAttributesButton = (): boolean =>
		(this.filteredAttributes().length && this.filteredAttributes().length < this.limitOfSelection() )

	deselectAllAttributes = (cp, attributeFilter): void => {
		this.$filter('filter')(cp.attributes, { displayName: attributeFilter}).forEach((attribute: ISelectableItem) => {
			attribute.selected = false;
		});
	}

	selectAllAttributes = (): void => {
		this.$filter('limitTo')(this.filteredAttributes(), this.limitOfSelection(), 0).forEach((attribute: ISelectableItem) => {
			attribute.selected = true;
		});
	}

	getSelectedAttributes = (cp): any[] => cp.attributes.filter(this.$scope.selectedItems);

	unselectedItems = (item: ISelectableItem): boolean => !item.selected;

	selectedItems = (item: ISelectableItem): boolean => item.selected;

	removeButtonTxt = (filter): string => {
		return (filter?.length) ?
			this.locale.getString('common.hideAllMatches') :
			this.locale.getString('common.hideAll');
	}

	addButtonTxt = (filter): string => {
		return (filter?.length) ?
			this.locale.getString('common.addAllMatches') :
			this.locale.getString('common.addAll');
	}

	getLastOrderIndex = (provider): number => {
		let lastOrderIndex = 0;
		let attributes = this.getSelectedAttributes(provider);

		for (let attr of attributes) {
			let orderIndex = attr.orderIndex;
			if (lastOrderIndex < orderIndex) {
				lastOrderIndex = orderIndex;
			}
		}

		return lastOrderIndex;
	}

	getNextOrderIndex = (provider): number => {
		return this.getLastOrderIndex(provider) + 1;
	}

	changeAttributeOrder = (provider, attribute, operation: Operation): void => {
		if (operation === Operation.ADD) {
			attribute.orderIndex = this.$scope.getNextOrderIndex(provider);
		}

		if (operation === Operation.REMOVE) {
			this.getSelectedAttributes(provider).forEach((iterAttribute) => {
				if (iterAttribute.orderIndex > attribute.orderIndex) {
					iterAttribute.orderIndex = iterAttribute.orderIndex - 1;
				}
			});

			delete attribute.orderIndex;
		}

		if (operation === Operation.UP) {
			let upperAttribute = this.$scope.getAttributeByOrderIndex(provider, attribute.orderIndex - 1);
			upperAttribute.orderIndex = upperAttribute.orderIndex + 1;
			attribute.orderIndex = attribute.orderIndex - 1;
		}

		if (operation === Operation.DOWN) {
			let lowerAttribute = this.$scope.getAttributeByOrderIndex(provider, attribute.orderIndex + 1);
			lowerAttribute.orderIndex = lowerAttribute.orderIndex - 1;
			attribute.orderIndex = attribute.orderIndex + 1;
		}
	}

	toggleSelections = (provider, selections): void => {
		if (_.isUndefined(selections)) {
			return;
		}

		selections.forEach((attribute) => {

			attribute.selected = !attribute.selected;

			let operation = (attribute.selected) ? Operation.ADD : Operation.REMOVE;
			this.$scope.changeAttributeOrder(provider, attribute, operation);
		});
	}

	getAttributeByOrderIndex = (provider, orderIndex: number) => {
		return provider.attributes.filter(attribute => attribute.orderIndex === orderIndex).pop();
	}

	moveAttribute = (provider, selections, direction): void => {
		let attributes = selections.slice();

		if (direction === Operation.DOWN) {
			attributes.reverse();
		}

		attributes.forEach((attribute) => {
			this.$scope.changeAttributeOrder(provider, attribute, direction);
		});
	}

	allowMoveAttribute = (provider, selections, direction): boolean => {
		if (_.isUndefined(selections)) {
			return false;
		}

		if (direction === Operation.DOWN) {
			return !_.findWhere(selections, {orderIndex: this.getLastOrderIndex(provider)});
		} else {
			return !_.findWhere(selections, {orderIndex: 0});
		}
	}

	dismiss = (): void => {
		this.$uibModalInstance.dismiss('cancel');
	}

	save = (contentProvider): void => {
		this.$uibModalInstance.close(this.getSelectedAttributes(contentProvider));
	}

	allowSelectAttributes = (contentProvider): boolean => {
		if (!this.maxAmountOfAttributes) {
			return true;
		}
		let selectedAmount = (!_.isUndefined(contentProvider.attributes))
			? this.getSelectedAttributes(contentProvider).length : 0;

		let markedAmount = !_.isUndefined(this.$scope.availableAttr)
			? this.$scope.availableAttr.length : 0;

		if (selectedAmount === this.maxAmountOfAttributes) {
			return false;
		}

		return selectedAmount + markedAmount <= this.maxAmountOfAttributes;
	}

	allowDeselectAttributes = (contentProvider): boolean => {
		if (!contentProvider.attributes) return false;
		return this.getSelectedAttributes(contentProvider).length > 0;
	}

	getMaxNumberOfAttributes = (): number => this.maxAmountOfAttributes;

	getModalTitle = (): string => {
		if (this.contentProvider.customModalTitle) {
			return this.contentProvider.customModalTitle;
		}
		return this.$sce.getTrustedHtml(this.contentProvider.name + ' &rsaquo; ' + this.contentProvider.selectedAccount.accountName
			+ ' &rsaquo; ' + this.contentProvider.selectedProject.name);
	}

	supportsReordering = (): boolean => this.$scope.getOrderByField === '\'orderIndex\'';
}

app.controller('attributesModalCtrl', AttributesModalCtrl);
