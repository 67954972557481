import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetBulkCreateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-bulk-create-action';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class WidgetBulkDeleteAction implements DashboardChangeAction {
	private deletedWidgets: Widget[];

	constructor(widgets: Widget[]) {
		this.deletedWidgets = ObjectUtils.copy(widgets);
	}

	reverse(): DashboardChangeAction {
		return new WidgetBulkCreateAction(this.deletedWidgets);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		_.forEach(this.deletedWidgets, widget => {
			widgets.removeAt(WidgetActionUtils.findWidgetIndexById(widgets, widget.id, idMapper));
		});
		return api.deleteWidgets(...this.deletedWidgets.map(widget => WidgetActionUtils.getWidgetWithLatestId(widget, idMapper)));
	}
}
