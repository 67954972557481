import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridsterStateService } from '@app/modules/gridster/gridster-state.service';
import { GridsterDragHandleDirective } from './gridster-drag-handle.directive';

@NgModule({
	declarations: [
		GridsterDragHandleDirective
	],
	exports: [
		GridsterDragHandleDirective,
	],
	providers: [
		GridsterStateService,
	],
	imports: [
		CommonModule
	]
})
export class GridsterModule { }
