import { PagedResponse } from '@cxstudio/paged-response';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';

export class AssetObjectType<T extends StudioAsset, R> {
	type: ObjectType;
	displayName: string;
	handler: (asset: T, pagination: Pagination) => Promise<PagedResponse<R>>;
	note?: string;

	constructor(
		type: ObjectType,
		displayName: string,
	) {
		this.type = type;
		this.displayName = displayName;
	}
}
