<div *ngIf="dropdown" ngbDropdown #ngDropdown
	class="search-list-dropdown dropdown-input flex-fill chicklet option-chicklet"
	[container]="getContainer()"
	[display]="getDisplay()"
	[ngClass]="{'w-100-percent': fullWidth, 'overflow-hidden': appendToBody}"
	(click)="dropdownClick()"
	(keydown)="onDropdownKeydown($event)">
	<a ngbDropdownToggle
		class="search-list-selected-option d-block align-items-center text-default lh-1_25 bg-transparent h-100-percent border-radius-4"
		href="javascript:void(0)"
		role="button"
		aria-expanded="false"
		aria-haspopup="true"
		[attr.tabindex]="nonclickable ? -1 : null"
		[title]="getToggleTooltip()"
		[ngClass]="getToggleClasses()">
		{{getToggleText()}} <span class="caret"></span>
	</a>
	<div class="searchable-list dropdown-menu" ngbDropdownMenu role="menu"
		[ngClass]="{'dropdown-fixed': fixedPosition, 'w-100-percent': matchToggleWidth}"
		[ngStyle]="menuStyle" [style.min-width.px]="minWidth">
		<ng-container *ngTemplateOutlet="searchList"></ng-container>
	</div>
</div>
<div *ngIf="!dropdown">
	<ng-container *ngTemplateOutlet="searchList"></ng-container>
</div>

<ng-template #searchList>
	<div *ngIf="!multi" #listItems (keydown)="handleKeyboardNavigation($event)">
		<ul class="pl-0 mb-0 scrolling-list" (keyup)="onListItemsKeyup($event)" role="menu">
			<li *ngIf="!disableSearch && listOptions?.length"
				class="searchable-list-search-box"
				role="menuitem"
				(click)="$event.stopPropagation();"
				(keydown)="onSearchKeydown($event)">
				<label *ngIf="searchLabel" class="d-block" for="input-search-{{styleId}}">{{searchLabel}}</label>
				<input #searchInput
					id="input-search-{{styleId}}"
					type="text"
					aria-autocomplete="list"
					autocomplete="off"
					[placeholder]="searchPrompt"
					class="w-100-percent"
					[(ngModel)]="ui.searchTerm">
			</li>
			<li *ngIf="allowClear" class="searchable-list-prompt-item" role="menuitem">
				<a (click)="clearSelection()"
					[title]="selectPrompt">
					<i>{{selectPrompt}}</i>
				</a>
			</li>
			<ng-container *ngIf="getFilteredOptions(listOptions)?.length; else noOptions">
				<ng-container *ngFor="let node of getFilteredOptions(listOptions)">
					<li *ngIf="isNodeVisible(node)"
						class="searchable-list-item"
						role="menuitem"
						[ngClass]="node?.css || ''">
						<a ngbDropdownItem
							href="javascript:void(0)"
							(keyup)="onListItemsKeyup($event)"
							(keydown)="onDropdownItemKeydown($event)"
							(click)="nodeClick(node, $event)"
							[ngClass]="getNodeClasses(node, true)"
							[title]="getNodeTitle(node)"
							[innerHTML]="getNodeTemplate(node)">
						</a>
						<span *ngIf="node.hasProjectSeparator">----------------------------------</span>
					</li>
				</ng-container>
			</ng-container>
			<ng-template #noOptions>
				<li *ngIf="!allowClear" class="no-wrap searchable-list-empty" role="menuitem">
					<i>{{(listOptions?.length > 0
						? 'reportFilters.noMatchedOptions'
						: 'reportFilters.noOptionsRemaining')|i18n}}
					</i>
				</li>
			</ng-template>
		</ul>
	</div>
	<div *ngIf="multi" #listItems (keydown)="handleKeyboardNavigation($event)">
		<ul class="pl-0 mb-0" [ngClass]="{'scrolling-list': dropdown}" (keyup)="onListItemsKeyup($event)" role="menu">
			<li *ngIf="!disableSearch && multiListOptions?.length"
				class="mt-0 searchable-list-search-box"
				role="menuitem"
				(click)="$event.stopPropagation();"
				(keydown)="onSearchKeydown($event)">
				<label *ngIf="searchLabel" class="d-block" for="input-search-{{styleId}}">{{searchLabel}}</label>
				<input id="input-search-{{styleId}}" type="text" [placeholder]="searchPrompt"
					class="w-100-percent search-box" [(ngModel)]="ui.searchTerm">
			</li>
			<div [ngClass]="{'scrolling-list': !dropdown}">
				<div class="pl-0" *ngFor="let optionsList of getOrderedMultiList()">
					<li *ngIf="optionsList.list" class="dropdown-header" role="menuitem">{{optionsList.label}}</li>
					<ng-container *ngIf="getFilteredOptions(optionsList.list)?.length; else noOptions">
						<div *ngFor="let node of getFilteredOptions(optionsList.list)">
							<li *ngIf="isNodeVisible(node)"
								class="searchable-list-item"
								role="menuitem"
								[ngClass]="node?.css || ''">
								<a ngbDropdownItem
									href="javascript:void(0)"
									(keydown)="onDropdownItemKeydown($event)"
									(click)="nodeClick(node, $event)"
									[ngClass]="getNodeClasses(node, false)"
									[title]="getNodeTitle(node)"
									[innerHTML]="getNodeTemplate(node)">
								</a>
							</li>
						</div>
					</ng-container>
					<ng-template #noOptions>
						<li class="searchable-list-empty" role="menuitem">
							<i>{{(optionsList.list?.length > 0
								? 'reportFilters.noMatchedOptions'
								: 'reportFilters.noOptionsRemaining')|i18n}}
							</i>
						</li>
					</ng-template>
				</div>
			</div>
		</ul>
	</div>
</ng-template>
