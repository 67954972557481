import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { AccountAccess } from '@cxstudio/user-administration/users/project-access/account-access-class';
import { ContentProviderAccess } from '@cxstudio/user-administration/users/project-access/content-provider-access-class';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { SortUtils } from '@app/shared/util/sort-utils';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { ProjectAccessLevel } from '@cxstudio/user-administration/users/project-access/project-access-level-class';
import { ProjectAccessLevelValue } from '../editor/workspaces-projects-access/project-access-level-value.enum';
import { IProjectsAccess } from './project-access-interface';


export class ProjectAccessObject implements IProjectsAccess {

	private contentProviders: ContentProviderAccess[];
	private lite: boolean;
	private accessLevels: {[value: string]: ProjectAccessLevel};

	constructor(private ProjectAccessLevels: ProjectAccessLevelItems) {
		this.accessLevels = this.ProjectAccessLevels.valuesAsMap();
	}

	withContentProvidersMap(cpsMap: {[cpId: number]: AccountAccess[]}, forLite: boolean): void {
		this.lite = forLite;
		this.contentProviders = this.convertContentProvidersAccountsMapToList(cpsMap);
		//no change flag
		this.resetAccessLevelToDefault();
	}

	withAccessMap(cpsMap: {[cpId: number]: AccountAccess[]}): void {
		this.contentProviders = this.convertContentProvidersAccountsMapToList(cpsMap);
	}

	setLite(value: boolean): void {
		this.lite = value;
		if (value) {
			this.resetAccessLevelToLite();
		} else {
			this.resetAccessLevelToFull();
		}
	}

	getData(): ContentProviderAccess[] {
		return this.contentProviders;
	}

	getAccountData(cpId: number, accountId: number): AccountAccess {
		let cp: ContentProviderAccess = _.findWhere(this.contentProviders, {
			id: cpId
		});
		let account: AccountAccess = _.findWhere(cp.accounts, {
			accountId
		});
		return account;
	}

	getProjectData(cpId: number, accountId: number, projectId: number): ProjectAccess {
		let accountData: AccountAccess = this.getAccountData(cpId, accountId);
		let projectData: ProjectAccess = _.find(accountData.projects, (item: ProjectAccess) => {
			return item.project.id === projectId;
		});
		return projectData;
	}

	merge(cpsMap: {[cpId: number]: AccountAccess[]}): void {
		let cpsList = this.convertContentProvidersAccountsMapToList(cpsMap);
		this.iterateAllAccounts(this.mergeAccount, cpsList);
	}


	private convertContentProvidersAccountsMapToList(
		cpsAccounts: {[cpId: number]: AccountAccess[]}): ContentProviderAccess[] {

		let allProjects = [];
		cpsAccounts = cloneDeep(cpsAccounts);
		let keys = Object.keys(cpsAccounts);
		for (const key of keys) {
			let cpId = parseInt(key, 10);
			let cpProjects: ContentProviderAccess = {
				id: cpId,
				accounts: cpsAccounts[cpId]
			};
			this.sortProjectsAlphanumerically(cpProjects);
			allProjects.push(cpProjects);
		}
		return allProjects;
	}

	private sortProjectsAlphanumerically(cpProjects: ContentProviderAccess): void {
		let accounts: AccountAccess[] = cpProjects.accounts;
		let projectNameSortBy = (row: ProjectAccess) => {
			return row.project.name;
		};
		for (const account of accounts) {
			account.projects = SortUtils.mergeSort(account.projects,
				SortUtils.getAlphanumericComparator(projectNameSortBy, SortDirection.ASC));
		}
	}

	private iterateAllAccounts(
		callback: (cp: ContentProviderAccess, account: AccountAccess) => void,
		cps?: ContentProviderAccess[]): void {

		cps = cps || this.contentProviders;
		cps.map((cp: ContentProviderAccess) => {
			cp.accounts.map((account: AccountAccess) => {
				callback(cp, account);
			});
		});
	}

	private resetAccessLevelToDefault(): void {
		this.iterateAllAccounts(this.resetAccountAccessLevelToDefault);
	}

	private resetAccountAccessLevelToDefault = (cp: ContentProviderAccess, accountAccessData: AccountAccess): void => {
		accountAccessData.accountAdmin = false;
		accountAccessData.liteUser = this.lite;
		accountAccessData.projects.map((project: ProjectAccess) => {
			let currentLevel: ProjectAccessLevel = this.ProjectAccessLevels.findByValue(project.accessLevel);
			if (currentLevel.level <= this.accessLevels.VIEWER.level) {
				project.accessLevel = ProjectAccessLevelValue.VIEWER;
			} else {
				project.accessLevel = ProjectAccessLevelValue.NONE;
			}
		});
	}

	private resetAccessLevelToLite(): void {
		this.iterateAllAccounts(this.resetAccountAccessLevelToLite);
	}

	private resetAccountAccessLevelToLite = (cp: ContentProviderAccess, accountAccessData: AccountAccess): void => {
		accountAccessData.accountAdmin = false;
		accountAccessData.liteUser = true;
		accountAccessData.projects.map((project: ProjectAccess) => {
			if (project.accessLevel === ProjectAccessLevelValue.MANAGER) {
				project.accessLevel = ProjectAccessLevelValue.VIEWER;
			}
		});
		accountAccessData.changed = true;
	}

	private resetAccessLevelToFull(): void {
		this.iterateAllAccounts(this.resetAccountAccessLevelToFull);
	}

	private resetAccountAccessLevelToFull = (cp: ContentProviderAccess, accountAccessData: AccountAccess): void => {
		accountAccessData.liteUser = false;
		accountAccessData.changed = true;
	}

	private mergeAccount = (targetCP: ContentProviderAccess, targetAccount: AccountAccess): void => {
		let data: AccountAccess = this.getAccountData(targetCP.id, targetAccount.accountId);
		if (!this.lite) {
			data.accountAdmin = targetAccount.accountAdmin;
		}
		data.projects.map((projectAccess: ProjectAccess) => {
			let targetProjectAccess: ProjectAccess = _.find(targetAccount.projects, (item) => {
				return item.project.id === projectAccess.project.id;
			});
			if (targetProjectAccess) {
				projectAccess.accessLevel = targetProjectAccess.accessLevel;
			}
		});
	}

}
