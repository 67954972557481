<div class="panel panel-default link-sharing-settings">
    <div class="panel-heading">{{"mAccount.propRestrictExternalRedirect"|i18n}}</div>
    <div class="panel-body clearfix">
        <div class="d-flex align-items-center mb-16 br-domain-switch">
            <cb-toggle [(ngModel)]="isEnabled" (ngModelChange)="switchExternalRedirectDomainStatus($event)">
            </cb-toggle>
        </div>
        <div *ngIf="isEnabled">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label
                            for="in-external-redirect-domain">{{"mAccount.propRestrictExternalRedirectToDomains"|i18n}}</label>
                        <div class="input-group">
                            <input class="br-domain-name border-r-0"
							   	size="40" type="text"
							   	required
							   	id="in-external-redirect-domain"
						   		[maxLength]="60"
						   		[(ngModel)]="externalRedirectDomain"
                                (ngModelChange)="onDomainNameChange()" />
                            <button id="btn-domain-save"
								type="button"
								class="btn btn-primary"
                                (click)="addToExternalRedirectDomains(externalRedirectDomain)"
                                [disabled]="!isDomainValid(externalRedirectDomain)">
                                {{"mAccount.propRestrictExternalRedirectAddDomainBtn"|i18n}}</button>
                        </div>
                        <cb-notice *ngIf="hasValidErrors" class="d-inline-block mt-8 mr-2" type="danger"
                            [dismissable]="false">
                            <span>{{"mAccount.propRestrictExternalRedirectDomainError"|i18n}} </span>
                        </cb-notice>
                    </div>
                    <div class="mt-16">
                        <ul class="pl-8">
                            <li *ngFor="let domain of defaultAllowedDomains">{{domain}}</li>
                            <li *ngFor="let domain of allowedDomains" class="d-flex align-items-center">
                                <span>{{domain}}</span>
                                <a data-testid="btn-remove-domain"
									class="btn-icon ml-4"
									href="javascript:void(0)"
									attr.aria-label="{{'common.remove'|i18n}}"
									title="{{'common.remove'|i18n}}"
                                    (click)="deleteFromExternalRedirectDomains(domain)">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
