import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import * as _ from 'underscore';
import { AsyncTaskData, IPermissionsModification, AsyncTaskDataType, IUserGroupsModification, IUserGroupData,
	IDataAccessModification, ILicenseTypeModification, IUserDeletionData, IGroupUsersModification, IGroupUserData } from '../bulk/async-task-data';

import { AsyncTaskItemData } from '@app/modules/user-administration/bulk/async-task-item-data';
import { AsyncTaskUtils } from '@app/modules/user-administration/bulk/async-task-utils';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';
import { CxLocaleService } from '@app/core';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { AsyncTask } from '../bulk/async-task';
import { AsyncTaskApiService } from '../bulk/async-task-api.service';
import { AsyncTaskItemStatus } from '../bulk/async-task-item-status';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from '@app/shared/components/pagination/pagination';

interface IAsyncTaskStatusParams {
	taskId: string;
}

interface ITaskDescriptionGroup {
	name: string;
	text: string;
}

@Component({
	selector: 'async-task-status-modal',
	templateUrl: './async-task-status-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AsyncTaskStatusModalComponent extends ModalBindings<IAsyncTaskStatusParams> implements OnInit {
	loading: Promise<any>;
	task: AsyncTask<AsyncTaskItemData>;
	pagination: Pagination;
	taskDescriptionGroups: ITaskDescriptionGroup[];

	constructor(
		private locale: CxLocaleService,
		private asyncTaskApiService: AsyncTaskApiService,
		private permissionsService: PermissionsService,
		modal: NgbActiveModal,
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.pagination = new Pagination(10);
		this.updateTaskStatus();
	}

	getTitle(): string {
		if (!this.task) {
			return this.locale.getString('administration.bulkStatusModalTitle');
		}
		return this.isGroupTask(this.task)
			? this.locale.getString('administration.bulkGroupsStatusModalTitle')
			: this.locale.getString(this.getUserTitleKey());
	}

	private getUserTitleKey(): string {
		if (this.isCreateTask(this.task)) {
			return 'administration.bulkUsersUploadStatusModalTitle';
		}
		if (this.isUserRemoval(this.task.data)) {
			return 'administration.bulkUsersRemovalStatusModalTitle';
		}
		return 'administration.bulkUsersStatusModalTitle';
	}

	getTargetColumn(): string {
		return this.isGroupTask(this.task)
			? this.locale.getString('administration.groupName')
			: this.locale.getString('common.email');
	}

	getNote = (): string => {
		if (this.task) {
			if (this.isUserRemoval(this.task.data)) {
				let user = this.task.data.userInfo;
				let noteKey = this.task.data.candidateEmail
					? 'administration.userBulkRemovalModalNoteTransfer'
					: 'administration.userBulkRemovalModalNoteDelete';
				return this.locale.getString(noteKey, {
					fname: user.firstName,
					lname: user.lastName,
					count: this.task.items.length,
					email: this.task.data.candidateEmail || ''
				});
			}
			let key = this.getNoteKey();
			let userData = this.task.data.userInfo;
			return this.locale.getString(key, {
				fname: userData.firstName,
				lname: userData.lastName,
				count: this.task.items.length }) + ':';
		} else {
			return this.locale.getString('administration.bulkStatusModalUnavailableNote');
		}
	}

	private getNoteKey(): string {
		if (this.isGroupTask(this.task)) {
			return 'administration.bulkGroupsStatusModalNote';
		} else {
			return this.isCreateTask(this.task)
				? 'administration.bulkUsersUploadStatusModalNote'
				: 'administration.bulkUsersStatusModalNote';
		}
	}

	refresh = (): void => {
		this.updateTaskStatus();
	}

	hasPendingItems = (): boolean => {
		return this.task && _.any(this.task.items, (taskItem) => taskItem.status === AsyncTaskItemStatus.PENDING);
	}

	getStatusLabel = (status: AsyncTaskItemStatus): string => {
		return this.locale.getString(AsyncTaskUtils.getStatusLabelKey(status));
	}

	private updateTaskStatus = (): void => {
		this.loading = this.asyncTaskApiService.getTaskStatus(this.input.taskId).then((task) => {
			this.task = task;
			this.pagination.totalItems = this.task.items.length;
			this.taskDescriptionGroups = this.getTaskDescriptionGroups();
		});
	}

	private getTaskDescriptionGroups = (): ITaskDescriptionGroup[] => {
		let groups = [];
		let taskData = this.task.data;
		if (this.isPermissionsModification(taskData)) {
			if (!isEmpty(taskData.addedPermissions)) {
				let name = this.locale.getString('administration.permissionsAdded');
				let text = this.getPermissionsText(taskData.addedPermissions);
				groups.push({name, text});
			}
			if (!isEmpty(taskData.removedPermissions)) {
				let name = this.locale.getString('administration.permissionsRemoved');
				let text = this.getPermissionsText(taskData.removedPermissions);
				groups.push({name, text});
			}
		}
		if (this.isUserGroupsModification(taskData)) {
			if (!isEmpty(taskData.addedToGroups)) {
				let name = this.locale.getString('group.addToGroup');
				let text = this.getGroupsText(taskData.addedToGroups);
				groups.push({name, text});
			}
			if (!isEmpty(taskData.removedFromGroups)) {
				let name = this.locale.getString('group.removeFromGroup');
				let text = this.getGroupsText(taskData.removedFromGroups);
				groups.push({name, text});
			}
		}

		if (this.isGroupUsersModification(taskData)) {
			if (!isEmpty(taskData.addedUsers)) {
				let name = this.locale.getString('group.addUsers');
				let text = this.getUsersText(taskData.addedUsers);
				groups.push({name, text});
			}
			if (!isEmpty(taskData.removedUsers)) {
				let name = this.locale.getString('group.removeUsers');
				let text = this.getUsersText(taskData.removedUsers);
				groups.push({name, text});
			}
		}

		if (this.isLicenseTypeModification(taskData)) {
			let name = this.locale.getString('administration.newLicenseType');
			let newLicenseType = taskData.newLicenseType;
			let text = newLicenseType.licenseTypeName;
			groups.push({name, text});
		}
		if (this.isDataAccessModification(taskData)) {
			let name = this.locale.getString('administration.dataAccessUpdated');
			let projects = taskData.projects;
			let text = this.getProjectsText(projects);
			groups.push({name, text});
		}

		return groups;
	}

	private getPermissionsText = (permissions: MasterAccountPermission[]): string => {
		let getPermissionText = this.permissionsService.getPermissionText;
		return _.chain(permissions)
			.map(permission => getPermissionText(permission))
			.join(', ')
			.value();
	}

	private getGroupsText = (groups: IUserGroupData[]): string => {
		return _.chain(groups)
			.map(group => group.name)
			.join(', ')
			.value();
	}

	private getUsersText = (users: IGroupUserData[]): string => {
		return _.chain(users)
			.map(user => user.userEmail)
			.join(', ')
			.value();
	}

	private getProjectsText = (projects: Project[]): string => {
		return _.chain(projects)
			.map(project => project.name)
			.join(', ')
			.value();
	}

	private isPermissionsModification = (taskData: AsyncTaskData): taskData is IPermissionsModification => {
		return taskData.type === AsyncTaskDataType.PERMISSIONS_MODIFICATION
			|| taskData.type === AsyncTaskDataType.GROUP_PERMISSIONS_MODIFICATION;
	}

	private isUserGroupsModification = (taskData: AsyncTaskData): taskData is IUserGroupsModification => {
		return taskData.type === AsyncTaskDataType.USER_GROUPS_MODIFICATION;
	}

	private isGroupUsersModification = (taskData: AsyncTaskData): taskData is IGroupUsersModification => {
		return taskData.type === AsyncTaskDataType.GROUP_USERS_MODIFICATION;
	}

	private isDataAccessModification = (taskData: AsyncTaskData): taskData is IDataAccessModification => {
		return taskData.type === AsyncTaskDataType.DATA_ACCESS_MODIFICATION;
	}

	private isLicenseTypeModification = (taskData: AsyncTaskData): taskData is ILicenseTypeModification => {
		return taskData.type === AsyncTaskDataType.LICENSE_TYPE_MODIFICATION;
	}

	private isUserRemoval = (taskData: AsyncTaskData): taskData is IUserDeletionData => {
		return taskData.type === AsyncTaskDataType.USER_DELETION;
	}

	private isCreateTask(task: AsyncTask<AsyncTaskItemData>): boolean {
		let type = task?.data?.type;
		return [AsyncTaskDataType.USER_CREATION].contains(type);
	}

	private isGroupTask(task: AsyncTask<AsyncTaskItemData>): boolean {
		let type = task?.data?.type;
		return [
			AsyncTaskDataType.GROUP_PERMISSIONS_MODIFICATION,
			AsyncTaskDataType.GROUP_USERS_MODIFICATION,
			AsyncTaskDataType.USER_GROUPS_MODIFICATION
		].contains(type);
	}
}
