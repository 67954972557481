import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { NumericAttributeCalculationSettingsEditor } from '../numeric-attribute-calculation-settings-editor';


@Injectable()
export class NumericAttributeCalculationSettingsEditorProvider {

	constructor(
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService
	) {}

	getInstance(): NumericAttributeCalculationSettingsEditor {
		return new NumericAttributeCalculationSettingsEditor(this.defaultDataFormatterBuilder);
	}
}

app.service('numericAttributeCalculationSettingsEditorProvider', downgradeInjectable(NumericAttributeCalculationSettingsEditorProvider));
