import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { Metric } from '../../metrics/entities/metric.class';
import { MetricConstants } from '../providers/cb/constants/metric-constants.service';

export class ReportMetricService {

	private constants;

	constructor(
		private locale: ILocale,
		private metricConstants: MetricConstants
	) {
		this.constants = this.metricConstants.get();
	}

	getStandardMetrics = (): Metric[] => {
		return [
			this.constants.VOLUME,
			this.constants.PERCENT_OF_TOTAL,
			this.constants.PARENT_PERCENT
		];
	}

	getStandardMetricsCategory = () => {
		return {
			name: 'standard',
			displayName: this.locale.getString('widget.groupStandard'),
			children: this.getStandardMetrics()
		};
	}

	getFormattedMetricsInProject = (metrics: Metric[], project, storeAs): Metric[] => {
		return this.getMetricCategory(this.addMetricDisplayName(this.getMetricsInProject(metrics, project)), storeAs);
	}

	getMetricsInProject = (metrics: Metric[], project): Metric[] => {
		return this.filterMetrics(metrics, (filter) => filter.projectId === project || /.*folder.*/i.test(filter.type));
	}

	filterMetricsByType = (metrics: Metric[], type): Metric[] => {
		return this.filterMetrics(metrics, (filter) => filter.type === type);
	}

	filterMetrics = (metrics: Metric[], filter): Metric[] => {
		return metrics.filter(filter);
	}

	addMetricDisplayName = (metrics: Metric[]) => {
		return metrics.map((metric) => {
			metric.displayName = metric.name;
			return metric;
		});
	}

	getMetricCategory = (metrics: any, storeAs: boolean = false): any => {
		if (metrics && metrics.filter((item) => !/.*folder.*/i.test(item.type)).length) {
			let formattedMetrics;

			let metricRoot = {
				id: -1,
				displayName: this.locale.getString('widget.groupMetrics'),
				name: 'metrics'
			};

			for (let metric of metrics) {
				metric.parentId = metric.parentId || -1;
			}

			metrics.unshift(metricRoot);

			formattedMetrics = TreeListTransformUtils.tree(metrics)[0];

			let removeParent = (node) => {
				delete node.parent;
				if (node.children) {
					node.children.map(removeParent);
				}
			};

			formattedMetrics.children.map(removeParent);

			if (storeAs) {
				storeAs = formattedMetrics;
			}

			return formattedMetrics;
		}
	}

	getTopicLevelString = (item): string => {
		if (AnalyticMetricTypes.isTopics(item) && item.showLevel) {
			let level = AnalyticMetricTypes.isTopicLeaf(item)
				? this.locale.getString('widget.leaves')
				: this.locale.getString('widget.level') + ' ' + (item.selectedLevel || 1);
			return item.displayName + ' (' + level + ')';
		}
		return;
	}
}

app.service('reportMetricService', ReportMetricService);
