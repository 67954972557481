<ng-container *ngIf="!isVirtualScrollActive()">
	<ng-container *ngIf="showSelected">
		<li *ngFor="let option of getFilteredOptions(allOptions, selectedOptionsFilter, SpecialOrder.SPECIAL_FIRST); index as $index"
		class="multiselect-item kb-focusable active"
			[ngClass]="getOptionCssClasses(option)"
			(click)="onItemClick(option, 'selected'); $event.stopPropagation();"
			(keydown)="onItemKeydown($event, option, 'selected'); $event.stopPropagation();">
			<a *ngIf="!option.children" class="labeled-checkbox">
				<input id="selected_{{$index}}"
					type="checkbox"
					[checked]="option.selected">
				<label for="selected_{{$index}}"
					class="multiselect-item-name"
					[ngClass]="{'italic':option.special}"
					title="{{getTooltip(option)}}"
					[textContent]="option.displayName"></label>
			</a>
			<div *ngIf="option.children && option.children.length"
				class="br-hierarchy-node-toggle d-flex align-items-center">
				<span class="cursor-pointer mr-8 q-icon"
					[ngClass]="{'q-icon-add':(option.children && !option.expandedSelected ), 'q-icon-minus':(option.children && option.expandedSelected )}"
					tabindex="0"
				></span>
				<span class="multiselect-item-name"
					[ngClass]="{'italic':option.special}"
					title="{{getTooltip(option)}}"
					[textContent]="option.displayName"></span>
			</div>

			<ng-container *ngIf="option.children && option.expandedSelected">
				<div *ngFor="let childItem of getFilteredOptions(option.children, selectedOptionsFilter, SpecialOrder.SPECIAL_LAST)"
				class="multiselect-item" [ngClass]="{'active':childItem.selected, 'disabled':itemDisabled(childItem)}"
					(click)="onItemClick(childItem, 'selected'); $event.stopPropagation();"
					(keydown)="onItemKeydown($event, childItem, 'selected'); $event.stopPropagation();">
				<a class="filter-child d-flex align-items-center">
						<input type="checkbox" class="mr-8"
							class="mr-8"
							[checked]="childItem.selected"
							(click)="onItemClick(childItem, 'selected'); $event.stopPropagation();">
						<span class="multiselect-item-name" title="{{getTooltip(childItem)}}" [textContent]="childItem.displayName"></span>
					</a>
				</div>
			</ng-container>
		</li>
		<li class="divider" *ngIf="hasSelectedOptions()" role="separator"></li>
	</ng-container>
	<li *ngFor="let option of getFilteredOptions(allOptions, unselectedOptionsFilter, SpecialOrder.SPECIAL_FIRST); index as $index"
		class="multiselect-item _t-unselected"
		[ngClass]="getOptionCssClasses(option)"
		(click)="onItemClick(option, 'unselected'); $event.stopPropagation();"
		(keydown)="onItemKeydown($event, option, 'unselected'); $event.stopPropagation();">
	<a *ngIf="!option.children"
		class="labeled-checkbox multiselect-item-name-wrap"
			[ngClass]="{'new-value': isPagingSearch && option.newValue}">
			<input *ngIf="showSelected"
				id="item_{{$index}}"
			class="mr-8"
			[disabled]="itemDisabled(option)"
			type="checkbox"
			[checked]="option.selected"
			(click)="onItemClick(option); $event.stopPropagation();">
			<label for="item_{{$index}}"
				class="multiselect-item-name"
				[ngClass]="{'italic':option.special}"
				title="{{getTooltip(option)}}"
				[innerHTML]="highlightText(option.displayName)">
			</label>
			<span *ngIf="showAttributeName" class="pl-16 italic text-gray-900">{{option.attributeDisplayName}}</span>
			<div *ngIf="isPagingSearch && option.newValue" class="new-value-dot"></div>
		</a>
		<div *ngIf="option.children && option.children.length" class="br-hierarchy-node-toggle d-flex align-items-center">
			<span class="cursor-pointer mr-8 q-icon"
				[ngClass]="{'q-icon-add':(option.children && !option.expandedUnselected ), 'q-icon-minus':(option.children && option.expandedUnselected )}"
				tabindex="0"
			></span>
			<span class="multiselect-item-name"
				[ngClass]="{'italic':option.special}"
					title="{{getTooltip(option)}}"
					[textContent]="option.displayName"></span>
		</div>

		<ng-container *ngIf="option.children && option.expandedUnselected">
			<div *ngFor="let childItem of getFilteredOptions(option.children, unselectedOptionsFilter, SpecialOrder.SPECIAL_LAST)"
			class="multiselect-item"
			[ngClass]="{'active':childItem.selected, 'disabled':itemDisabled(childItem)}"
				(click)="onItemClick(childItem); $event.stopPropagation();"
				(keydown)="onItemKeydown($event, childItem); $event.stopPropagation();">
				<a class="filter-child">
					<input type="checkbox"
						class="mr-8"
						[disabled]="itemDisabled(childItem)"
						[checked]="childItem.selected"
						(click)="onItemClick(childItem); $event.stopPropagation();">
					<span class="multiselect-item-name" title="{{getTooltip(childItem)}}" [textContent]="childItem.displayName"></span>
				</a>
			</div>
		</ng-container>
	</li>
</ng-container>

<ng-container *ngIf="isVirtualScrollActive()">
	<cdk-virtual-scroll-viewport autosize>
		<div *cdkVirtualFor="let option of filteredOptions; index as $index">
			<li *ngIf="showSelected && showOption(option, $index, true)"
				class="multiselect-item kb-focusable active action-background"
				[ngClass]="getOptionCssClasses(option)"
				(click)="onItemClick(option, 'selected'); $event.stopPropagation();"
				(keydown)="onItemKeydown($event, option, 'selected'); $event.stopPropagation();">
				<a *ngIf="!option.children" class="labeled-checkbox">
					<input id="selected_{{$index}}"
						type="checkbox"
						[checked]="option.selected">
					<label for="selected_{{$index}}"
						class="multiselect-item-name"
						[ngClass]="{'italic':option.special}"
						title="{{getTooltip(option)}}"
						[textContent]="option.displayName"></label>
				</a>
				<div *ngIf="option.children && option.children.length"
					class="br-hierarchy-node-toggle d-flex align-items-center">
					<span class="cursor-pointer mr-8 q-icon"
						[ngClass]="{'q-icon-add':(option.children && !option.expandedSelected ), 'q-icon-minus':(option.children && option.expandedSelected )}"
						tabindex="0"
					></span>
					<span class="multiselect-item-name"
						[ngClass]="{'italic':option.special}"
						title="{{getTooltip(option)}}"
						[textContent]="option.displayName"></span>
				</div>

				<ng-container *ngIf="option.children && option.expandedSelected">
					<div *ngFor="let childItem of getFilteredOptions(option.children, selectedOptionsFilter, SpecialOrder.SPECIAL_LAST)"
						class="multiselect-item" [ngClass]="{'active':childItem.selected, 'disabled':itemDisabled(childItem)}"
						(click)="onItemClick(childItem, 'selected'); $event.stopPropagation();"
						(keydown)="onItemKeydown($event, childItem, 'selected'); $event.stopPropagation();">
						<a class="filter-child">
							<input type="checkbox"
								[checked]="childItem.selected"
								(click)="onItemClick(childItem, 'selected'); $event.stopPropagation();">
							<span class="multiselect-item-name" title="{{getTooltip(childItem)}}" [textContent]="childItem.displayName"></span>
						</a>
					</div>
				</ng-container>
			</li>
			<li *ngIf="option.isDivider" class="divider" role="separator"></li>
			<li *ngIf="!option.isDivider && showOption(option, $index, false)"
				class="multiselect-item _t-unselected"
				[ngClass]="getOptionCssClasses(option)"
				(click)="onItemClick(option, 'unselected'); $event.stopPropagation();"
				(keydown)="onItemKeydown($event, option, 'unselected'); $event.stopPropagation();">
				<a *ngIf="!option.children"
					class="labeled-checkbox multiselect-item-name-wrap"
					[ngClass]="{'new-value': isPagingSearch && option.newValue}">
					<input *ngIf="showSelected"
						id="item_{{$index}}"
						class="mr-8"
						[disabled]="itemDisabled(option)"
						type="checkbox"
						[checked]="option.selected"
						(click)="onItemClick(option); $event.stopPropagation();">
					<label for="item_{{$index}}"
						class="multiselect-item-name"
						[ngClass]="{'italic':option.special}"
						title="{{getTooltip(option)}}"
						[innerHTML]="highlightText(option.displayName)"></label>
					<span *ngIf="showAttributeName" class="pl-16 italic text-gray-900">{{option.attributeDisplayName}}</span>
					<div *ngIf="isPagingSearch && option.newValue" class="new-value-dot"></div>
				</a>
				<span class="br-hierarchy-node-toggle cursor-pointer"
					*ngIf="option.children && option.children.length"
					[ngClass]="{'q-icon-add':(option.children && !option.expandedUnselected ), 'q-icon-minus':(option.children && option.expandedUnselected )}"
					tabindex="0"
				> &nbsp;
					<span class="multiselect-item-name"
						[ngClass]="{'italic':option.special}"
						title="{{getTooltip(option)}}"
						[textContent]="option.displayName"></span>
				</span>

				<ng-container *ngIf="option.children && option.expandedUnselected">
					<div *ngFor="let childItem of getFilteredOptions(option.children, unselectedOptionsFilter, SpecialOrder.SPECIAL_LAST)"
						class="multiselect-item"
						[ngClass]="{'active':childItem.selected, 'disabled':itemDisabled(childItem)}"
						(click)="onItemClick(childItem); $event.stopPropagation();"
						(keydown)="onItemKeydown($event, childItem); $event.stopPropagation();">
						<a class="filter-child">
							<input type="checkbox"
								class="mr-8"
								[disabled]="itemDisabled(childItem)"
								[checked]="childItem.selected"
								(click)="onItemClick(childItem); $event.stopPropagation();">
							<span class="multiselect-item-name" title="{{getTooltip(childItem)}}" [textContent]="childItem.displayName"></span>
						</a>
					</div>
				</ng-container>
			</li>
		</div>
	</cdk-virtual-scroll-viewport>
</ng-container>
