export enum PredefinedMetricConstants {
	SENTIMENT = 'sentiment',
	SENTIMENT_3 = 'sentimentBreakdown3',
	SENTIMENT_5 = 'sentimentBreakdown5',
	EASE_SCORE = 'easeScore',
	EMOTION = 'emotion',
	EMOTION_3 = 'emotionBreakdown3',
	EASE_3 = 'easeScoreBreakdown3',
	EASE_5 = 'easeScoreBreakdown5',
	SUBTYPE_3 = 'Breakdown3',
	SUBTYPE_5 = 'Breakdown5'
}