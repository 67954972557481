import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/modules/icon/icon.component';

@NgModule({
	imports: [CommonModule],
	declarations: [IconComponent],
	exports: [IconComponent],
})
export class IconModule { }
