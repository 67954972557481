import { Inject, Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import TranslatableLanguage
	from '@app/modules/account-administration/topic-conversions/topic-translation/translatable-language';
import { UrlService } from '@cxstudio/common/url-service.service';

@Injectable({
	providedIn: 'root'
})
export class TopicTranslationService {
	constructor(private readonly http: CxHttpService, @Inject('urlService') private urlService: UrlService) {}

	public getUploadUrl(): string {
		return this.urlService.getAPIUrl('rest/topic-conversions/translations/upload');
	}

	public startTranslation(topicModel: string, selectedLanguages: TranslatableLanguage[]): Promise<void> {
		return this.http.post(
			this.urlService.getAPIUrl('rest/topic-conversions/translations/start'),
			{
				topicModel,
				languages: selectedLanguages.map(selectedLanguage => selectedLanguage.locale)
			}
		);
	}
}
