import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';

import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';


@Injectable({
	providedIn: 'root'
})
export class SocialCasesLogTableColumnsService {

	private columns: Array<TableColumn<any>>;

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {
		this.initColumns();
	}

	getColumns = (): Array<TableColumn<any>> => {
		return this.columns;
	}

	private initColumns = (): void => {
		this.columns = [];
		this.columns.push({
			name: 'masterAccountName',
			path: 'message.masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.07
		});
		this.columns.push({
			name: 'messageType',
			path: 'message.messageType',
			displayName: this.locale.getString('cases.messageType'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		this.columns.push({
			name: 'reason',
			path: 'message.reason',
			displayName: this.locale.getString('cases.reason'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		this.columns.push({
			name: 'date',
			path: 'metadata.date',
			displayName: this.locale.getString('administration.date'),
			formatter: this.tableFormattersService.dateFormatter,
			width: 0.1
		});
		this.columns.push({
			name: 'alertName',
			path: 'message.alertName',
			displayName: this.locale.getString('administration.alertName'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		});
		this.columns.push({
			name: 'assigneeEmail',
			path: 'message.assigneeEmail',
			displayName: this.locale.getString('cases.assignee'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		this.columns.push({
			name: 'inboxTemplate',
			path: 'message.subscriptionTemplateName',
			displayName: this.locale.getString('cases.inboxTemplate'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		});
		this.columns.push({
			name: 'title',
			path: 'message.title',
			displayName: this.locale.getString('administration.title'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.15
		});
		this.columns.push({
			name: 'documentId',
			path: 'message.documentId',
			displayName: this.locale.getString('administration.document'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		});
		this.columns.push({
			name: 'cluster-node',
			path: 'metadata.node',
			displayName: this.locale.getString('common.node'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		});
		this.columns.push({
			name: 'version',
			path: 'metadata.version',
			displayName: this.locale.getString('administration.version'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		});
		this.columns.push({
			name: 'destination',
			path: 'metadata.destination',
			displayName: this.locale.getString('administration.destination'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.15
		});
	}
}
