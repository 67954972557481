import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';
import { GaugeBaseLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-base-layout';
import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';

export class GaugeTwoColumnsLayout extends GaugeBaseLayout {

	getNeededWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		let comparisonSpace = this.getComparisonWidth(cssVars) + cssVars.horizontalSpacing;
		return this.getGaugeWidth(cssVars) + comparisonSpace;
	}

	getNeededHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		let comparisonsSpace = this.getComparisonsHeight(cssVars, comparisons);
		return Math.max(this.getGaugeHeight(cssVars), comparisonsSpace);
	}

	private getComparisonsHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		let comparsionsCount = this.getComparisonsCount(comparisons);
		return this.getComparisonHeight(cssVars) * comparsionsCount
			+ cssVars.verticalSpacing * (comparsionsCount - 1);
	}

	getComparisonsColumnHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getComparisonsHeight(cssVars, comparisons);
	}

	getComparisonsRowWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getNeededWidth(cssVars, comparisons);
	}

	isComparisonsStacked(): boolean {
		return true;
	}
}
