import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountWorkspaceStatus } from './master-account-workspace-status';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { WorkspaceStatusApi } from '@app/modules/system-administration/status/integration-status/workspace/workspace-status-api.service';
import { WorkspaceStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/workspace/workspace-status-table-columns.service';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';

@Injectable({providedIn: 'root'})
export class WorkspaceStatusService implements IntegrationStatusService<MasterAccountWorkspaceStatus> {
	constructor(
		private workspaceStatusApiService: WorkspaceStatusApi,
		private workspaceStatusTableColumnsService: WorkspaceStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.unitsIntegration';
	}

	getColumns(): TableColumn<MasterAccountWorkspaceStatus>[] {
		return this.workspaceStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountWorkspaceStatus[]> {
		return this.workspaceStatusApiService.getWorspaceStatus();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.workspaceStatusApiService.export(search);
	}
}
