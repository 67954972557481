import Widget from '@cxstudio/dashboards/widgets/widget';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import * as cloneDeep from 'lodash.clonedeep';
import { FeedbackUtils } from '../../feedback-utils.service';

export class CurrentSentencesUtils {

	static getCurrentSentencesAsReportData(widget: Widget, documentManager: IDocumentPreviewerControls): ReportDataObject {
		let currentSentences = FeedbackUtils.isSingleVerbatimMode(widget)
			? CurrentSentencesUtils.getCurrentVerbatimSentences(documentManager)
			: CurrentSentencesUtils.getCurrentDocumentSentences(documentManager);

		return {
			data: currentSentences,
			metadata: documentManager.data.metadata
		} as any;
	}

	private static getCurrentDocumentSentences = (documentManager: IDocumentPreviewerControls): PreviewSentence[] => {
		// PreviewDocument or ConversationDocument
		let currentDocument: PreviewDocument = documentManager && documentManager.documents[documentManager.selectedDocument];

		let currentDocumentSentences: PreviewSentence[] = cloneDeep(currentDocument.sentences);

		currentDocumentSentences.forEach(sentence => {
			sentence.documentId = currentDocument.id;
			sentence.documentDate = currentDocument.documentDate;
			CurrentSentencesUtils.populateSourceId(sentence, currentDocument);
		});

		return currentDocumentSentences;
	}

	private static populateSourceId(sentence: PreviewSentence, currentDocument: any): void {
		if (currentDocument.sm_service) {
			sentence.smService = currentDocument.sm_service;
		} else {
			const SOURCE_ID_KEY = '_id_source';

			// override not used attributes
			let attributes: {[name: string]: any} = {};
			attributes[SOURCE_ID_KEY] = currentDocument[SOURCE_ID_KEY];

			sentence.attributes = attributes;
		}
	}

	private static getCurrentVerbatimSentences = (documentManager: IDocumentPreviewerControls): PreviewSentence[]=> {
		let currentDocumentSentences = CurrentSentencesUtils.getCurrentDocumentSentences(documentManager);
		let currentSentence = _.findWhere(currentDocumentSentences, { id: documentManager.selectedSentence });
		return _.where(currentDocumentSentences, {verbatimId: currentSentence.verbatimId});
	}
}
