<div [ngBusy]="{busy: loading, message: loadingMessage}" class="br-error-container h-min-48">
	<div *ngIf="done">
		<div *ngIf="errors.length">
			<p>{{'administration.userCreatedWithErrors'|i18n}}</p>
			<ol>
				<li *ngFor="let error of errors">
					{{error}}
				</li>
			</ol>
		</div>
		<div *ngIf="!errors.length">
			<p>{{'administration.userCreatedSuccess'|i18n}}</p>
		</div>
	</div>
</div>