<section class="panel panel-default br-smtp-details" id="smtpPanel">
	<div class="panel-heading">{{'mAccount.propUseCustomSMTP'|i18n}}</div>
	<div class="panel-body clearfix">
		<div class="mb-16">
			<cb-toggle
				data-testid="smtp-switch"
				[(ngModel)]="settings.enabled"
				(ngModelChange)="switchEmailStatus(settings.enabled)">
			</cb-toggle>
		</div>
		<div *ngIf="settings.enabled" class="w-50-percent">
			<div class="form-group">
				<div class="clearfix">
					<div class="block">
						<label for="in-host" class="mandatory">{{'mAccount.propSMTPHost'|i18n}}</label>
						<div>
							<input class="form-control" data-testid="smtp-host" size="80" type="text"
								id="in-host" [(ngModel)]="settings.host" name="host" [debounce]="300" (debouncedChange)="validateSMTP()">
						</div>
					</div>
					<div class="block">
						<label for="in-port" class="mandatory">{{'mAccount.propSMTPPort'|i18n}}</label>
						<div>
							<input class="form-control" data-testid="smtp-port" size="6" type="text"
								id="in-port" [(ngModel)]="settings.port" name="port" [debounce]="300" (debouncedChange)="validateSMTP()">
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="clearfix">
					<div class="block">
						<label for="in-username" class="mandatory">{{'mAccount.propSMTPUsername'|i18n}}</label>
						<div>
							<input class="form-control" data-testid="smtp-username" type="text"
								size="40" id="in-username" name="userName"
								[(ngModel)]="settings.username" [debounce]="300" (debouncedChange)="validateSMTP()">
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="clearfix">
					<div class="block">
						<label for="in-password" class="mandatory">{{'mAccount.propSMTPPassword'|i18n}}</label>
						<div>
							<input class="form-control" data-testid="smtp-password" type="password"
								size="40" id="in-password" name="password"
								autocomplete="off"
								[(ngModel)]="settings.password" (ngModelChange)="validateSMTP()">
						</div>
					</div>
				</div>
			</div>
			<br>
			<div class="row mb-16" *ngIf="smtpSubmissionSuccess">
				<div class="col-sm-4">{{'mAccount.propSMTPSavedSuccess'|i18n}}</div>
			</div>
			<button id="btn-smtp-save"
				type="submit"
				class="btn btn-primary" [btnLoading]="loading"
				loadingText="{{'common.saving'|i18n}}"
				(click)="saveSMTP()"
				[i18n]="'common.save'"
				[disabled]="smtpIncomplete || smtpUnchanged"></button>
		</div>
	</div>
</section>
