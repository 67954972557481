export class RandomUtils {

	/**
	 * Returns random 9-symbol string with lowercase letters and numbers
	 */
	static randomString(): string {
		return Math.random().toString(36).substr(2, 9);
	}

	/**
	 * Returns random integer in a given interval, inclusive
	 */
	 static randomIntInclusive(min: number, max: number): number {
		let minValue = Math.ceil(min);
		let maxValue = Math.floor(max);
		return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
	}
}