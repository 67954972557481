import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef, ChangeDetectorRef, Output, EventEmitter, Type, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { Deferred, PromiseUtils } from '@app/util/promise-utils';

@Component({
	selector: 'sidebar-properties',
	templateUrl: './sidebar-properties.component.html',
	styles: [`
		:host {
			width: 100%;
			height: 100%;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarPropertiesComponent<T> implements OnInit {

	@ViewChild('propertiesContent', { read: ViewContainerRef }) propertiesContent: ViewContainerRef;

	@Input() template: TemplateRef<unknown>;
	@Input() header: string;

	private defer: Deferred<T> = PromiseUtils.defer();
	private valid: boolean = true;
	private entity: T;

	constructor(
		private ref: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
	}

	setValid(valid: boolean): void {
		this.valid = valid;
		this.ref.markForCheck();
	}

	isValid(): boolean {
		return this.valid;
	}

	setEntity(entity: T): void {
		this.entity = entity;
	}

	onApply(): void {
		if (!this.isValid())
			return;

		this.defer.resolve(this.entity);
	}

	onDiscard(): void {
		this.defer.reject();
	}

	getPromise(): Promise<T> {
		return this.defer.promise;
	}

}
