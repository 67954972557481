import * as _ from 'underscore';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

// Do not reuse this, mixing component and service together is very confusing
export abstract class CxDialogComponent<I, O> {

	protected modalInstance: NgbModalRef;
	input: I;
	output: O;

	constructor(
		protected modalService: NgbModal
	) { }

	open(input: I): NgbModalRef {
		let modalInstance = this.modalService.open(this.getDialogClass(), {
			backdrop: 'static',
			windowClass: 'ng-modal-dialog',
		});

		let componentInstance: CxDialogComponent<I, O> = modalInstance.componentInstance;
		componentInstance.modalInstance = modalInstance;
		componentInstance.input = input;

		componentInstance.initOutput();

		return modalInstance;
	}

	cancel(): void {
		this.modalInstance.dismiss('cancel');
	}

	save(): void {
		this.modalInstance.close(this.output);
	}

	protected abstract getDialogClass(): any;

	protected initOutput(): void {}

}
