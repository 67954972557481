import { BasicConversationChartOptions } from './basic-conversation-chart-options.class';
import { PlaybackIndicatorOptions } from './playback-indicator-options.class';
import { ScrollIndicatorOptions } from './scroll-indicator-options.class';
import { ParticipantEnrichments, ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationClusterPredicate } from '@cxstudio/conversation/conversation-chart-utils.class';
import { ParticipantEnrichmentTypes } from './entities/spine-lane.class';
import { ApplicationTheme } from '@cxstudio/header/application-theme';

export enum ConversationType {
	AUDIO = 'AUDIO',
	CHAT = 'CHAT'
}

export enum SingleLaneEnrichmentTypes {
	SENTIMENT = 'sentiment',
	EMOTIONAL_INTENSITY = 'emotion',
	EFFORT = 'effort',
	SENTENCE_TYPE = 'sentence_type',
	TOPIC = 'topic',
	SCORECARD = 'scorecard'
}

export interface ExternalChartConfig {
	chartType: ConversationType;
	participantEnrichments: ParticipantEnrichments;
	singleLaneEnrichments: ConversationEnrichment[];
	participantEnrichment: ParticipantEnrichmentTypes;
	primaryColor: string;
	getTooltip: (d: ConversationDataPoint, enrichments: ConversationEnrichment[]) => string;
	getHeaderTooltip: (enrichments: ConversationEnrichment[], isPrimary?: boolean) => string;
	sameClusterPredicate: ConversationClusterPredicate;
	theme: ApplicationTheme;
}

export class ConversationChartOptions {	
	type: ConversationType;
	basicChart?: BasicConversationChartOptions;
	animationTransition?: number;
	playbackIndicator?: PlaybackIndicatorOptions;
	scrollIndicator?: ScrollIndicatorOptions;
	hideEmptyTracks?: boolean;
	singleLaneEnrichments: ConversationEnrichment[];
	getTooltip: (d: ConversationDataPoint, enrichments?: ConversationEnrichment[]) => string;
	getHeaderTooltip: (enrichments: ConversationEnrichment[], isPrimary?: boolean) => string;
	sameClusterPredicate: ConversationClusterPredicate;
	theme?: ApplicationTheme;
	primaryColor?: string;

	constructor(externalConfig: Partial<ExternalChartConfig>) {
		let enrichments = externalConfig.participantEnrichments;

		let appliedEnrichments = [enrichments.primary];
		appliedEnrichments = appliedEnrichments.concat(externalConfig.singleLaneEnrichments);
		appliedEnrichments = appliedEnrichments.concat([
			enrichments.overtalk, 
			enrichments.agentDetection, 
			enrichments.clientDetection, 
			enrichments.botDetection, 
			enrichments.unknownDetection] as any);

		let defaults: ConversationChartOptions = {
			type: externalConfig.chartType,
			basicChart: new BasicConversationChartOptions(enrichments, externalConfig.primaryColor,
				externalConfig.participantEnrichment || ParticipantEnrichmentTypes.SENTIMENT, externalConfig.theme),
			animationTransition: 200,
			playbackIndicator: new PlaybackIndicatorOptions(),
			scrollIndicator: new ScrollIndicatorOptions(externalConfig.theme),
			singleLaneEnrichments: externalConfig.singleLaneEnrichments,
			hideEmptyTracks: false,
			getTooltip: (data, tooltipEnrichments?) => {
				return externalConfig.getTooltip(data, tooltipEnrichments || appliedEnrichments);
			},
			getHeaderTooltip: externalConfig.getHeaderTooltip,
			sameClusterPredicate: externalConfig.sameClusterPredicate,
			theme: externalConfig.theme,
			primaryColor: externalConfig.primaryColor
		};

		// deep extend the default options with the custom
		return defaults;
	}
}