
import { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { ConversationParticipantService } from '@app/modules/document-explorer/conversation-participant.service';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ChangeUtils } from '@app/util/change-utils';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { ChatMessage } from '@cxstudio/reports/document-explorer/chat/chat-message.class';
import { ConversationChannelService } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { SentenceEnrichmentTuningEvent } from '@app/modules/pills/pill-tuning-event';

@Component({
	selector: 'chat-message',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './chat-message.component.html',
})
export class ChatMessageComponent implements OnInit, OnChanges {
	@Input() chatMessage: ChatMessage;
	@Input() channelLabels: ConversationChannelLabels;
	@Input() formatters: IMetricFormatters;
	@Input() auditMode: boolean;
	@Input() auditModeModels: IReportModel[];
	@Input() focusable: boolean;
	@Input() messageWidth: number;
	@Input() highlightTrigger: number;
	@Input() expandTopics: boolean;
	@Input() leafOnly: boolean;
	@Input() predefinedMetrics: Metric[];
	@Input() deselectedModelsIds: number[];
	@Input() compact: boolean;
	@Input() translate: boolean;
	@Input() uniqueId: string;
	@Input() showTopics: boolean;
	@Input() showEnrichments: boolean;

	@Output() onSelect = new EventEmitter<{event: MouseEvent, sentence: ConversationSentence}>();
	@Output() removeTopic = new EventEmitter<{topic: string, sentence: ConversationSentence}>();
	@Output() tuneEnrichment = new EventEmitter<SentenceEnrichmentTuningEvent>();

	icons: {
		sentiment: SafeHtml[],
		ease: SafeHtml[],
		emotion: SafeHtml[]
	};

	defaultColorPalette: WidgetColorPalette;
	iconColor: string;
	private sentenceTopicsMap: { sentence: number, topics: SentenceTopic[] }[];

	hoverSentenceId: number;

	constructor(
		private readonly profanityDisguiseService: ProfanityDisguiseService,
		@Inject('conversationChannelService') private conversationChannelService: ConversationChannelService,
		@Inject('conversationParticipantService') private conversationParticipantService: ConversationParticipantService,
	) {}

	ngOnInit(): void {
		this.iconColor = this.conversationParticipantService.getIconColor(this.chatMessage.channel, this.chatMessage.participantIndex);

		this.initSentenceTopicsMap();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.auditMode && !this.compact && (ChangeUtils.hasChange(changes.deselectedModelsIds) || ChangeUtils.hasChange(changes.leafOnly))) {
				this.initSentenceTopicsMap();
		}
	}

	getFilteredChatSentenceTopics = (id: number) => {
		return _.findWhere(this.sentenceTopicsMap, { sentence: id })?.topics || [];
	}

	getFilteredSentenceTopics = (sentenceTopics: SentenceTopic[]): SentenceTopic[] => {
		return PillsUtils.getFilteredSentenceTopics(sentenceTopics, this.deselectedModelsIds, this.leafOnly);
	}

	private initSentenceTopicsMap = () => {
		this.sentenceTopicsMap = this.chatMessage.sentences.map((sentence) => {
			return {
				sentence: sentence.id,
				topics: PillsUtils.getFilteredSentenceTopics(sentence.sentenceTopics, this.deselectedModelsIds, this.leafOnly)
			};
		});
	}

	getParticipantLabel(): string {
		let channelLabel: string = this.getChannelLabel();
		return this.conversationParticipantService.getParticipantLabel(
			channelLabel, this.chatMessage.participantIndex, this.chatMessage.isSameChannelAsLast);
	}

	getChannelLabel(): string {
		return this.conversationChannelService.getChannelLabel(this.chatMessage.channel, this.channelLabels);
	}

	getIconClass(): string {
		return this.conversationChannelService.getIconClass(this.chatMessage.channel);
	}

	getIconColor(): string {
		return this.iconColor ? this.iconColor : '';
	}

	hasProfanity(sentence: ConversationSentence): boolean {
		return this.profanityDisguiseService.needToMaskSentence(sentence);
	}

	getChatText(): string {
		return _.map(this.chatMessage.sentences, sentence => sentence.text).join(' ');
	}
}

app.directive('chatMessage', downgradeComponent({component: ChatMessageComponent}) as angular.IDirectiveFactory);
