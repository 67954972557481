import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import {LayoutHelper} from '@cxstudio/dashboards/layout-helper.service';

export enum BulkWidgetAction {
	refresh = 'refreshSelectedWidgets'
}

@Component({
	selector: 'bulk-widget-menu',
	templateUrl: './bulk-widget-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkWidgetMenuComponent  {

	@Input() dashboard: Dashboard;
	@Output() closeMenu = new EventEmitter<void>();
	@Output() bulkAction = new EventEmitter<BulkWidgetAction>();

	constructor(
		@Inject('widgetsEditService') public widgetsEditService: WidgetsEditService,
		@Inject('layoutHelperService') public layoutHelperService: LayoutHelper
	) { }

	refreshAll(): void {
		this.bulkAction.emit(BulkWidgetAction.refresh);
	}

	forbiddenToCopy(): boolean {
		let selectedWidgets = this.widgetsEditService.getSelectedWidgets();
		let containsScorecardWidgets = !!_.find(selectedWidgets, selectedWidget => {
			return selectedWidget.name === WidgetType.SCORECARD;
		});
		return containsScorecardWidgets;
	}
}

app.directive('bulkWidgetMenu', downgradeComponent({component: BulkWidgetMenuComponent}) as angular.IDirectiveFactory);
