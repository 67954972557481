<section class="d-flex">
	<div class="col-sm-6">
		<label for="in-logo-file">{{'mAccount.propBrandingChangeLogo'|i18n}}</label>
		<div class="d-flex mb-8">
			<logo-upload-button></logo-upload-button>
		</div>
		<p [i18n]="'appearance.recommendedLogoSize'" class="text-0_875rem"></p>
		<p [i18n]="'appearance.brandingLogoFormatsSupported'" class="text-0_875rem"></p>
	</div>
	<div class="col-sm-6">
		<label>{{'appearance.logoPreview'|i18n}}</label>
		<logo-preview [brandingColors]="brandingColors$"></logo-preview>

		<div class="d-flex d-flex-inline">
			<p class="text-0_875rem text-ellipsis preview-logo-name">
				{{logoManagement.getLogoDescription()}}
			</p>
			<button
				type="button"
				class="btn btn-icon btn-secondary btn-branding p-8"
				(click)="logoManagement.deleteInPreviewMode()"
				[disabled]="logoManagement.isDefaultLogo()"
				title="{{'appearance.deleteLogo'|i18n}}">
				<icon name="trash"></icon>
			</button>
		</div>
	</div>
</section>
