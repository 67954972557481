<cx-wizard
	header="{{input.modalTitle}}"
	finishText="{{'common.save'|i18n}}"
	[loadingPromise]="loading"

	(onFinish)="save()"
	(onCancel)="cancel()">

	<cx-wizard-step #propertiesStep>
		<!-- There was issue when properties component sometimes is not rendered without wrapper -->
		<div data-testid="properties-step">
			<template #assetPropertiesContainer></template>
		</div>
	</cx-wizard-step>

	<cx-wizard-step *ngIf="replacements?.length > 0"
		#replacementsStep>
		<template-replacements-list *ngIf="isProjectSelected()"
			[project]="workspaceProject"
			[replacements]="replacements"
			[replacementsLabel]="input.replacementsLabel"
			(update)="validateReplacements()"
		></template-replacements-list>
	</cx-wizard-step>

</cx-wizard>
