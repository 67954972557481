<div class="panel panel-default hierarchy-api-settings" [ngBusy]="loading">
	<div class="panel-heading">{{'mAccount.hierarchyApiSettingsPanelHeader'|i18n}}</div>
	<div class="panel-body clearfix">
		<div class="d-flex align-items-center">
			<button type="button" class="btn btn-primary" (click)="generateToken($event)">
			{{'mAccount.hierarchyApiGenerateToken'|i18n}}</button>
			<cb-inline-help>
				<help-body>{{'mAccount.hierarchyApiGenerateTokenHint'|i18n}}</help-body>
			</cb-inline-help>
		</div>
		<div class="mt-8 d-flex align-items-center">
			<button type="button" class="btn btn-primary" (click)="revokeTokens()">
				{{'mAccount.hierarchyApiRevokeTokens'|i18n}}</button>
			<cb-inline-help>
				<help-body>{{'mAccount.hierarchyApiRevokeTokensHint'|i18n}}</help-body>
			</cb-inline-help>
		</div>
	</div>
</div>
