import { Label } from '@cxstudio/dashboards/entity/label';

export class LabelsController implements ng.IController {

	labels: Label[];
	labelAdded: (label: Label) => void;
	labelRemoved: (label: Label) => void;
	loadLabels: (query: string) => Label[];
	$onInit(): void {
		this.labels = this.labels || [];
		if (!this.loadLabels) {
			this.loadLabels = (query: string): Label[] => {
				return [];
			};
		}
		// required for typescript valid implementation of IController
	}
}

app.component('labels', {

	controller: LabelsController,
	templateUrl: 'partials/components/labels-component.html',
	bindings: {
		labels: '<',
		labelAdded: '<',
		labelRemoved: '<',
		loadLabels: '<'
	}
});
