import { Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';


@Component({
	selector: 'app-logo',
	template: `
<div class="form-group image-container">
	<a href="https://www.qualtrics.com/clarabridge/" target="_blank" title="{{'common.qualtricsLogo' | i18n}}">
		<img src="img/QualtricsXM-Logo.png" class="img-responsive" alt="{{'common.qualtricsLogo' | i18n}}">
	</a>
</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLogoComponent {}

app.directive('appLogo', downgradeComponent({component: AppLogoComponent}));
