import { AmplitudeGroups } from '@app/modules/analytics/amplitude/amplitude-groups';

export class AmplitudeGroupsUtils {
	static dashboardGroup(dashboardId: number | string): AmplitudeGroups {
		return {dashboardId};
	}

	static bookGroup(bookId: number): AmplitudeGroups {
		return { bookId };
	}
}
