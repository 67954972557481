<div [ngClass]="getCssClass()">
	{{column.displayName}}
	<span ngbDropdown
		class="user-column-dropdown"
		*ngIf="isFilterable"
		autoClose="outside"
		[ngClass]="{'transparent': !showFilterIcon() && !isDropdownOpened()}"
		(click)="onToggle($event)">
		<a href="javascript:void(0)" ngbDropdownToggle>
			<span class="q-icon q-icon-filter" title="{{'common.filter'|i18n}}"></span>
		</a>
		<div class="user-column dropdown-menu" ngbDropdownMenu (click)="$event.stopPropagation()">
			<div class="input-group" *ngIf="isText()">
				<input class="br-user-filter"
					type="search"
					[(ngModel)]="filterText"
					placeholder="{{'common.search'|i18n}}"
					[ngbTypeahead]="suggestions"
					(keydown.enter)="onApply()"
					(keydown.escape)="filterText = ''; onApply()">
				<button id="btn-column-search-user"
					type="button"
					class="btn btn-primary"
					(click)="onApply()">
					{{'common.apply'|i18n}}
				</button>
			</div>
			<div *ngIf="isSelect()">
				<simple-dropdown
					[options]="licenseTypes"
					displayField="licenseTypeName"
					valueField="licenseTypeId"
					[(value)]="filterText"
					(onChange)="onApply()">
				</simple-dropdown>
			</div>
			<date-picker
				[hidden]="!isDate()"
				class="br-user-date-filter w-max-320"
				[dateRange]="userQueryDateRange"
				(onChange)="onApply()">
			</date-picker>
			<div class="user-column-clear-show">
				<span (click)="onClear()"
					class="action-text cursor-pointer pull-right">{{'common.clear'|i18n}}</span>
			</div>
		</div>
	</span>
</div>
