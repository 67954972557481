import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { FeatureToggleBaseComponent } from '@app/modules/account-administration/properties/feature-toggle-base';
import { ToastService } from '@clarabridge/unified-angular-components';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';

@Component({
	selector: 'quick-translate-toggle',
	template: `
	<simple-feature-toggle *ngIf="showToggle"
		[(featureEnabled)]="featureEnabled"
		(featureEnabledChange)="toggleFeature($event)"
		[label]="label">
	</simple-feature-toggle>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickTranslateToggleComponent extends FeatureToggleBaseComponent implements OnInit {

	feature = MasterAccountProperty.QUICK_TRANSLATE;
	label = this.locale.getString('mAccount.propQuickTranslate');

	showToggle: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		toastService: ToastService,
		locale: CxLocaleService,
		@Inject('maPropertiesService') maPropertiesService: MAPropertiesService,
		@Inject('contractApiService') private contractApiService: ContractApiService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
	) {
		super(maPropertiesService, toastService, locale);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.contractApiService.isQuickTranslateAvailable().then(available => {
			this.showToggle = available;
			this.ref.markForCheck();
		});
	}

	toggleFeature(enabled: boolean): void {
		if (enabled) {
			this.cbDialogService.confirm(
				this.locale.getString('common.pleaseConfirm'),
				this.locale.getString('mAccount.translateConfirm'),
				this.locale.getString('mAccount.translateOk'),
				this.locale.getString('mAccount.translateCancel'),
				'modal-lg no-capitalize').result.then(
					() => super.toggleFeature(enabled),
					() => { this.featureEnabled = false; this.ref.markForCheck(); }, // cancel change
				);
		} else {
			super.toggleFeature(enabled);
		}
	}
}

app.directive('quickTranslateToggle', downgradeComponent({component: QuickTranslateToggleComponent}) as angular.IDirectiveFactory);
