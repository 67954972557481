import { Inject, Injectable } from '@angular/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { DashboardListService } from '../../dashboard-list.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';

@Injectable({
	providedIn: 'root'
})
export class AgGridDashboardFolderActionsService {

	private folderService: IFolderService;

	constructor(
		private readonly dashboardListService: DashboardListService,
		private readonly location: CxLocationService,
		private readonly dashboardTemplateService: DashboardTemplateService,
		@Inject('FolderService') private readonly FolderService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('redirectService') private readonly redirectService: RedirectService,

	) {
		this.folderService = new this.FolderService(FolderTypes.DASHBOARD);
	}

	createDashboard(folder: any): void {
		this.dashboardTemplateService.templatesEnabled().then((enabled) => {
			if (enabled) {
				if (folder) {
					this.location.path('/templates').search({selectedFolderId: folder.id});
				} else {
					this.location.path('/templates');
				}
				return;
			}

			this.dashboardService.createDashboard(undefined, undefined, folder).then((dashboard) => {
				this.navigationService.changeDashboard(dashboard, true);
			}, () => {});
		});
	}

	createBook(folder: IFolderItem): void {
		this.redirectService.goToNewBook(folder.id as number);
	}

	createFolder(folder?: any): void {
		this.dashboardListService.setLoading(this.folderService.createFolder(folder,
			this.dashboardListService.getCurrentDashboardsList(), null, true))
				.then((createdFolder) => {
					this.dashboardListService.addDashboards([createdFolder]);
				});
	}

	renameFolder(folder: IFolderItem): Promise<IFolderItem> {
		return PromiseUtils.wrap(this.folderService.renameFolder(folder, this.dashboardListService.getCurrentDashboardsList(), true));
	}

	moveToFolder(folder: IFolderItem, folderTo: IFolderItem): void {
		this.dashboardListService.setLoading(
			this.folderService.moveToFolder(folder, folderTo, this.dashboardListService.getCurrentDashboardsList(), true)).then(() => {
				this.dashboardListService.updateDashboards(); // updating all as it's hard to track all affected elements
			});
	}

	removeFolder(folder: IFolderItem): void {
		this.dashboardListService.setLoading(
			this.folderService.removeFolder(folder, this.dashboardListService.getCurrentDashboardsList(), true))
		.then(() => {
			this.dashboardListService.removeDashboards([folder], true);
		});
	}

}
