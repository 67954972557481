export class DisplayThreshold {
	metricName?: string;
	criteria?: {
		gte?: number;
		gt?: number;
		lte?: number;
		lt?: number;
		is?: number;
		isnot?: number;
	};
	notInBetween?: boolean;
}