import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageDashboard } from '@app/modules/home-page/home-page-common/entities/home-page-dashboard';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import ReportRunService from '@cxstudio/reports/report-run.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import * as cloneDeep from 'lodash.clonedeep';
import { ReportRunPreparationService } from '@app/modules/reports/utils/report-run-preparation.service';


@Injectable({
	providedIn: 'root'
})
export class HomePageWidgetUtilsService {
	readonly CHECK_POSTFIX = 'CountCheck';

	constructor(
		private locale: CxLocaleService,
		private homePageService: UserHomePageService,
		private cxDialogService: CxDialogService,
		@Inject('authorization') private authorization: Authorization,
		@Inject('security') private security: Security,
		@Inject('DashboardHistory') private DashboardHistory,
		@Inject('dashboardPersonalizationProvider') private dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		private reportRunPreparationService: ReportRunPreparationService,
		@Inject('customFilterService') private customFilterService: CustomFilterService,
		@Inject('reportRunService') private reportRunService: ReportRunService,
	) { }

	getDashboardData(homePage: HomePage, edit = false): Promise<IDashboardData> {
		let reportProperties = homePage.reportProperties;
		let dashboardData = {} as IDashboardData;
		dashboardData.dashboard = this.getDashboard(homePage);
		dashboardData.dashboardHistory = this.getDashboardHistory(dashboardData.dashboard as HomePageDashboard);
		if (!edit) {
			return this.homePageService.getHomePagePersonalization(homePage).then(personalization => {
				dashboardData.dashboardHistory.setPersonalization(personalization);
			}, () => {}).then(() => dashboardData);
		} else {
			let personalization = this.dashboardPersonalizationProvider.getInstance(reportProperties, HomePageWidgetConstants.CONTAINER_ID);
			return personalization.init().then(() => {
				dashboardData.dashboardHistory.setPersonalization(personalization);
				return dashboardData;
			}, (errorStatus: HierarchyLoadStatus) => {
				if (errorStatus === HierarchyLoadStatus.DEACTIVATED) {
					this.cxDialogService.warning(this.locale.getString('common.error'),
						this.locale.getString('widget.hierarchyDeactivated'));
				} else if (errorStatus === HierarchyLoadStatus.NO_ACCESS) {
					this.cxDialogService.warning(this.locale.getString('common.error'),
						this.locale.getString('widget.hierarchyNoAccess'));
				}
				return Promise.reject();
			});
		}
	}

	private getDashboard(homePage: HomePage): HomePageDashboard {
		let reportProperties = homePage.reportProperties;
		let isOwner = this.security.getEmail() === reportProperties.owner;
		let hasEditPermission = this.authorization.hasCreateDashboardAccess();
		let dashboard = new HomePageDashboard(HomePageWidgetConstants.HOME_PAGE_DASHBOARD_ID, 'Home Page Generated Dashboard');
		dashboard.permissions = {
			OWN: isOwner,
			EDIT: hasEditPermission
		};
		dashboard.properties = new DashboardProperties();
		dashboard.properties.cbContentProvider = reportProperties.contentProviderId;
		dashboard.properties.cbAccount = reportProperties.accountId;
		dashboard.properties.project = reportProperties.projectId;
		dashboard.properties.hierarchyId = reportProperties.hierarchyId;
		dashboard.properties.allowPersonalization = reportProperties.hierarchyId > 0;
		dashboard.homePageId = homePage.id;
		return dashboard;
	}

	private getDashboardHistory(dashboard: HomePageDashboard): IDashboardHistoryInstance {
		let dashboardHistory: IDashboardHistoryInstance = new this.DashboardHistory();
		dashboardHistory.init(dashboard);
		let textFilter: DashboardFilter = {
			selectedAttribute: {
				displayName: this.locale.getString('filter.textFilter'),
				name: DashboardFilterTypes.TEXT,
				filterType: DashboardFilterTypes.TEXT,
				matchMode: FilterMatchModeValue.IS,
			}
		};
		textFilter.genericFilterFormat = this.dashboardFiltersService.buildDashboardFilterRule(textFilter);
		dashboardHistory.setAppliedFilters([{isOrgHierarchy: true}, textFilter]);
		return dashboardHistory;
	}

	checkReportCount(widgetBase: Widget, dashboardData: IDashboardData): Promise<void> {
		let widget = cloneDeep(widgetBase);
		widget.containerId += this.CHECK_POSTFIX;

		this.reportRunPreparationService.populateBrowserProperties(widget);
		return Promise.resolve(this.customFilterService.postprocessWidgetProperties(widget, dashboardData.dashboardHistory)
			.then(updatedSettings => this.reportRunService.runReport(widget, updatedSettings, -1))
			.then(data => {
				if (data.total?.volume > 0 && data.data?.length > 0)
					return Promise.resolve() as any;
				else return Promise.reject();
			}) as any);
	}
}
