import { Injectable } from '@angular/core';
import { CxEvent } from '@app/core/cx-event.enum';
import { Subject, Subscription } from 'rxjs';

export interface CxLocalEvent {
	type: CxEvent;
	args?: any[];
}

@Injectable() // user as a prototype instance per component and its children
export class LocalEventBus {

	private readonly broadcastSubject = new Subject<CxLocalEvent>();
	private readonly broadcast$ = this.broadcastSubject.asObservable();

	private readonly emitSubject = new Subject<CxLocalEvent>();
	private readonly emit$ = this.emitSubject.asObservable();

	constructor() {}

	/**
	 * Used to broadcast the event to the component and all its children
	 * @param event event type to broadcast
	 * @param args optional arguments
	 */
	broadcast(event: CxEvent, ...args: any[]) {
		this.broadcastSubject.next({type: event, args});
	}

	/**
	 * Used to emit the event to the parent container
	 * @param event event type to emit
	 * @param args optional arguments
	 */
	emitOutside(event: CxEvent, ...args: any[]) {
		this.emitSubject.next({type: event, args});
	}

	passThrough(event: CxLocalEvent) {
		// used by event bridge between angularjs and agular
		this.broadcastSubject.next(event);
	}

	subscribe(eventType: CxEvent, callback: (...args: any[]) => void): Subscription {
		return this.broadcast$.subscribe(event => event.type === eventType ? callback.apply(null, event.args) : _.noop());
	}

	/**
	 * This should not be used for event listening
	 */
	subscribeToEmit(callback: (event: CxLocalEvent) => void): Subscription {
		return this.emit$.subscribe(callback);
	}

	destroy(): void {
		this.broadcastSubject.unsubscribe();
		this.emitSubject.unsubscribe();
	}
}
