import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DashboardExportEvent, EmbedDashboardEvent } from '@app/core/cx-event.enum';
import { IActionsMenuItem } from '@app/modules/dashboard-actions/actions-menu/actions-menu.component';
import { Security } from '@cxstudio/auth/security-service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { DashboardBookStateService } from '@cxstudio/dashboards/books/dashboard-book-state.service';
import { DashboardRunService } from '../dashboard/dashboard-run.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { BookViewTab } from '@app/modules/book/book-view-tab';
import { MenuUtils } from '@app/modules/dashboard-actions/actions-menu/menu-utils';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

@Injectable({
	providedIn: 'root'
})
export class BookActionsService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly eventBus: GlobalEventBus,
		@Inject('$location') private readonly $location: ng.ILocationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('dashboardBookState') private readonly dashboardBookState: DashboardBookStateService,
		@Inject('redirectService') private readonly redirectService: RedirectService,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		@Inject('dashboardRunService') private readonly dashboardRunService: DashboardRunService,
		@Inject('security') private readonly security: Security,
		private readonly betaFeaturesService: BetaFeaturesService,
	) { }

	getBookMenuItems(book: Book, currentTab: BookViewTab): IActionsMenuItem<Book>[] {
		let menu: IActionsMenuItem<Book>[] = [];

		if (!book) {
			return [];
		}

		if (this.canEdit(book)) {
			menu.push({
				text: this.locale.getString('dashboard.editProps'),
				name: 'edit_properties',
				onClick: () => {
					this.dashboardService.editBookProperties(book);
				}
			});

			menu.push(MenuUtils.getDivider());
		}

		menu.push({
			text: this.locale.getString('dashboard.refreshBook'),
			name: 'refresh',
			disabled: this.isInaccessibleTab(currentTab) || this.isFrontlineTab(currentTab),
			onClick: () => {
				if (currentTab.isDashboardTab()) {
					this.dashboardService.hardRefreshDashboard(currentTab.metadata.dashboardId);
				} else if (currentTab.isEmbedTab()) {
					this.eventBus.broadcast(EmbedDashboardEvent.RELOAD, currentTab.metadata.embedDashboardId);
				}
			}
		});

		menu.push({
			text: this.locale.getString('common.copyLink'),
			name: 'copy_link',
			disabled: this.isInaccessibleTab(currentTab),
			onClick: this.dashboardService.copyBookLink,
		});

		if (this.canCreate()) {
			menu.push({
				text: this.locale.getString('dashboard.duplicate'),
				name: 'copy',
				onClick: () => this.copyAndShowBook(book)
			});

			menu.push({
				text: this.locale.getString('dashboard.newBook'),
				name: 'create',

				onClick: () => {
					if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES)) {
						this.redirectService.goToNewBook();
					} else {
						this.dashboardBookState.isCreateBook = true;
						this.$location.url(this.$location.path());
						this.redirectService.goToDashboardList();
					}
				}
			});
		}

		menu.push(MenuUtils.getDivider());

		if (this.canShare(book)) {
			menu.push({
				text: this.locale.getString('dashboard.dashboardShare'),
				name: 'share',
				onClick: () => this.shareBook(book)
			});
		}

		if (currentTab.isDashboardTab()) {
			if (this.security.has('export_widget_data')) {
				menu.push({
					text: this.locale.getString('dashboard.exportTabData'),
					name: 'export_tab_data',
					disabled: this.isInaccessibleTab(currentTab),
					onClick: () => this.exportCurrentTabData()
				});
			}

			let pdfMenu = {
				text: this.locale.getString('dashboard.exportTab'),
				name: 'export_pdf_menu',
				disabled: this.isInaccessibleTab(currentTab),
				onClick: (item, event) => event.stopPropagation(),
				items: [{
					text: this.locale.getString('dashboard.downloadPdf'),
					name: 'export_pdf',
					onClick: () => this.exportCurrentTabPDF()
				}, {
					text: this.locale.getString('dashboard.ReceiveByEmail'),
					name: 'export_pdf_email',
					onClick: () => this.exportCurrentTabPDFEmail()
				}]
			};

			menu.push(pdfMenu);

			menu.push(MenuUtils.getDivider());
			menu.push({
				component: true,
				name: 'dark-mode-toggle',
				disabled: this.isInaccessibleTab(currentTab),
				onClick: MenuUtils.ignoreClick
			});

			menu.push({
				component: true,
				name: 'pattern-fills',
				disabled: this.isInaccessibleTab(currentTab),
				onClick: MenuUtils.ignoreClick
			});
		}

		if (this.canDelete(book)) {
			menu.push(MenuUtils.getDivider());

			menu.push({
				text: this.locale.getString('dashboard.deleteBook'),
				name: 'delete',
				onClick: () => {
					this.dashboardService.removeDashboard(book).then(() => {
						this.$location.url(this.$location.path());
						this.redirectService.goToDashboardList();
					});
				}
			});
		}
		return menu;
	}

	private isInaccessibleTab(tab: BookViewTab): boolean {
		if (tab.isDashboardTab()) {
			let metadata = tab.metadata;
			return metadata.removed || !metadata.viewAllowed;
		}
		return false;
	}

	private isFrontlineTab(tab: BookViewTab): boolean {
		return tab.isDashboardTab() && tab.metadata.frontline;
	}

	canEdit(book: Book): boolean {
		if (!book || !this.security.has('edit_dashboard')) {
			return false;
		}

		let permissions = book.permissions;
		return permissions.OWN || permissions.EDIT;
	}

	canShare(book: Book): boolean {
		return this.dashboardService.canShare(book);
	}

	shareBook(book: Book): void {
		this.dashboardService.shareDashboard(book);
	}

	private copyAndShowBook(book): void {
		this.dashboardService.copyDashboard(book).then((newBook) => {
			this.dashboardBookState.resetEditedFromBook();
			this.$location.path('/dashboard/' + newBook.id);
		});
	}
	private canCreate(): boolean {
		return this.security.has('edit_dashboard');
	}

	private canDelete(book: Book): boolean {
		return book.permissions.OWN;
	}

	private exportCurrentTabPDF(): void {
		this.eventBus.broadcast(DashboardExportEvent.PDF_DOWNLOAD);
	}

	private exportCurrentTabPDFEmail(): void {
		this.eventBus.broadcast(DashboardExportEvent.PDF_EMAIL);
	}

	private exportCurrentTabData(): void {
		this.eventBus.broadcast(DashboardExportEvent.XLS);
	}

	editBook(book: Book): void {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES)) {
			this.redirectService.goToBookEditor(book.id, true);
		} else {
			this.dashboardBookState.isEditBook = true;
			this.dashboardBookState.isBookPage = true;
			this.dashboardBookState.setBookId(book.id);

			this.$location.url(this.$location.path());
			this.redirectService.goToDashboardList();
		}
	}

	editDashboardFromBook(book: Book, dashboard: Dashboard): void {
		this.dashboardBookState.isBookPage = true;
		this.dashboardBookState.setBookId(book.id);

		if (dashboard) {
			this.dashboardBookState.setEditedFromBook(book.id);
			this.dashboardBookState.setEditedDashboard(this.$location.search().tab);
			this.$location.path('/home/' + dashboard.id).search('edit', true);
		}
	}

	getInitialTab(forRequest?: boolean): string {
		return this.$rootScope.preselectedTabId
			|| this.$location.search().tab
			|| (forRequest ? this.dashboardRunService.getBookRunNotSpecifiedTabId() : undefined);
	}
}
