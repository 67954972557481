import Widget from '@cxstudio/dashboards/widgets/widget';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';
import ConverstionWidgetProperty from './conversion-widget-property.class';

const PREVIEW_PROPERTIES: ConverstionWidgetProperty[] = [ new ConverstionWidgetProperty('previewMode'),
	new ConverstionWidgetProperty('snippets'), new ConverstionWidgetProperty('itemsPerPage'),
	new ConverstionWidgetProperty('page'), new ConverstionWidgetProperty('includeTopics')
];
const PREVIEW_VISUAL_PROPERTIES: ConverstionWidgetProperty[] = [
	new ConverstionWidgetProperty('visualization'), new ConverstionWidgetProperty('itemsPerPage'),
	new ConverstionWidgetProperty('sentimentHighlightingEnabled'), new ConverstionWidgetProperty('sentencePaneEnabled'),
	new ConverstionWidgetProperty('contextPaneEnabled'), new ConverstionWidgetProperty('bubbleColumns')
];

export class PreviewWidget extends ReportWidget {

	constructor() {
		super(WidgetType.PREVIEW);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initFromContext(context);
		super.withFilters(context);
		super.withShowSampleSize(context);
		super.withDateRange(context);
		super.initProperties(PREVIEW_PROPERTIES, context, 'properties');
		super.initProperties(PREVIEW_VISUAL_PROPERTIES, context, 'visualProperties');

		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedModels = [];
		this.initGroupings(context);
	}

	private initGroupings = (context): void => {
		let groupings = WidgetPropertyService.getValue('properties.selectedAttributes', context, []);
		this.widget.properties.selectedAttributes = groupings;

		let columns = WidgetPropertyService.getValue('visualProperties.columns', context, []);
		columns = _.filter(columns, (column: TableColumn<PreviewSentence>) => column.type === PreviewDataType.SYS);
		this.widget.visualProperties.columns = columns.concat(groupings);
	}
}
