import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageCarousel } from '@app/modules/home-page/home-page-common/entities/home-page-carousel';
import { Label } from '@cxstudio/dashboards/entity/label';
import { LabelsApiService } from '@cxstudio/services/data-services/labels-api.service';

@Component({
	selector: 'home-dashboard-carousel',
	templateUrl: './home-dashboard-carousel.component.html',
})
export class HomeDashboardCarouselComponent implements OnInit {
	
	@Input() carousel: HomePageCarousel;
	@Output() onChange = new EventEmitter<void>();
	allLabels: Label[];
	loading: ng.IPromise<any>;

	constructor(
		@Inject('labelsApiService') private labelsApiService: LabelsApiService,
	) { }

	ngOnInit(): void {
		
		this.loading = this.labelsApiService.getAllLabels().then((labels: Label[]) => {
			this.allLabels = _.sortBy(labels, 'text');
			this.allLabels.forEach((label: any) => {
				label.displayName = label.text;
				label.name = label.text;
			});
		});
	}

}
