import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'characters-left-counter',
	template: `
	<label class="characters-left"
		[attr.role]="highlightText() ? 'alert' : 'region'"
		[attr.aria-live]="highlightText() ? 'assertive' : 'polite'"
		[ngClass]="labelClasses"
		[class.text-danger]="highlightText()"
		[i18n]="'dashboard.charactersLeft'"
		[i18nParams]="{count: getCharactersLeftAmount()}">
	</label>
	`
})
export class CharactersLeftCounterComponent {
	@Input() maxLength: number;
	@Input() textLength: number;
	@Input() labelClasses: string;

	highlightText(): boolean {
		return this.maxLength - this.getTextLength() <= 0;
	}

	getCharactersLeftAmount(): number {
		return this.maxLength - this.getTextLength();
	}

	private getTextLength(): number {
		return this.textLength || 0;
	}
}

app.directive('charactersLeftCounter', downgradeComponent({component: CharactersLeftCounterComponent}));
