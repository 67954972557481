import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Inject, Output, EventEmitter } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { SentencePredefinedFilter } from '@app/modules/document-explorer/sentence-predefined-filter';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { downgradeComponent } from '@angular/upgrade/static';
import { GroupColorTypeService, PredefinedMetricColorTypeDefinition } from '@cxstudio/reports/utils/color/group-color-type.service';

interface IFilterGroup {
	type: PredefinedMetricConstants;
	filtersData: SentencePredefinedFilter;
	getSelectedValue: () => PredefinedFilterValue;
	buttons: IFilterButton[];

}

interface IFilterButton {
	value: PredefinedFilterValue;
	countValue: PredefinedFilterValue;
	icon: string;
	title: string;
	ariaPatternKey: string;
	css: string;
	selectedClass?: string;
	selectedColor?: string;
}


@Component({
	selector: 'document-explorer-metric-filters',
	template: `
<div *ngIf="buttonGroups" class="d-flex">
	<div *ngFor="let buttonGroup of buttonGroups" class="btn-group">
		<button *ngFor="let filter of buttonGroup.buttons"
			class="btn {{filter.css}}"
			[disabled]="buttonGroup.filtersData.getCount(filter.countValue) === 0"
			(click)="toggleFilter(buttonGroup.type, filter.value)"
			[ngClass]="(buttonGroup.getSelectedValue() === filter.value) ? filter.selectedClass : ''"
			[style.color]="(buttonGroup.getSelectedValue() === filter.value) ? filter.selectedColor : ''"
			[attr.aria-pressed]="buttonGroup.getSelectedValue() === filter.value"
			[attr.aria-label]="filter.ariaPatternKey | i18n:{count: buttonGroup.filtersData.getFormattedCount(filter.countValue)}"
			[title]="filter.title">
			{{ buttonGroup.filtersData.getFormattedCount(filter.countValue) }}
			<span [ngClass]="filter.icon" aria-ignore="true"></span>
		</button>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerMetricFiltersComponent extends SelfCleaningComponent implements OnInit {

	@Input() widget: Widget;
	@Input() sentimentValue: PredefinedFilterValue;
	@Input() easeScoreValue: PredefinedFilterValue;
	@Input() sentimentFilters: SentencePredefinedFilter;
	@Input() easeScoreFilters: SentencePredefinedFilter;
	@Output() toggle = new EventEmitter<{type: PredefinedMetricConstants, value: PredefinedFilterValue}>();

	buttonGroups: IFilterGroup[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly reportMetricsService: ReportMetricsService,
		@Inject('GroupColorType') private readonly GroupColorType: GroupColorTypeService,
		private betaFeaturesService: BetaFeaturesService,
	) {
		super();
	}

	ngOnInit(): void {
		let predefinedMetricLoading = this.reportMetricsService.getWidgetDynamicPredefinedMetrics(this.widget, true);
		predefinedMetricLoading.then(predefinedMetrics => {
			const NEGATIVE_COLOR_INDEX = 0;
			const POSITIVE_COLOR_INDEX = 2;
			let sentimentMetric = _.find(predefinedMetrics, {name: PredefinedMetricConstants.SENTIMENT});
			let sentimentColorArray = (this.GroupColorType.types.SENTIMENT_3 as PredefinedMetricColorTypeDefinition)
				.getColorArray(sentimentMetric);
			const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
			const neutralFilterTranslationKey = isMlSentimentEnabled ? 'docExplorer.mixedSentiment' : 'docExplorer.neutralSentiment';
			const neutralMetricArialLabelKey = isMlSentimentEnabled ? 'docExplorer.ariaLabelPatternMixedSentiment' : 'docExplorer.ariaLabelPatternNeutralSentiment';
			const neutralMetricCountValue = isMlSentimentEnabled ? PredefinedFilterValue.MIXED : PredefinedFilterValue.NEUTRAL;
			let sentimentFilterButtons: IFilterButton[] = [{
				value: PredefinedFilterValue.ALL,
				countValue: PredefinedFilterValue.ALL,
				icon: 'q-icon-bubbles',
				title: this.locale.getString('docExplorer.hasSentiment'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternDetectedSentiment',
				css: 'br-sentiment-all',
				selectedClass: 'btn-selected'
			}, {
				value: PredefinedFilterValue.NEGATIVE,
				countValue: PredefinedFilterValue.NEGATIVE,
				icon: 'q-icon-thumb-down',
				title: this.locale.getString('docExplorer.negativeSentiment'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternNegativeSentiment',
				css: 'br-sentiment-neg',
				selectedColor: sentimentColorArray[NEGATIVE_COLOR_INDEX],
			}, {
				value: PredefinedFilterValue.POSITIVE,
				countValue: PredefinedFilterValue.POSITIVE,
				icon: 'q-icon-thumb-up',
				title: this.locale.getString('docExplorer.positiveSentiment'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternPositiveSentiment',
				css: 'br-sentiment-pos',
				selectedColor: sentimentColorArray[POSITIVE_COLOR_INDEX],
			}, {
				value: PredefinedFilterValue.NEUTRAL,
				countValue: neutralMetricCountValue,
				icon: 'q-icon-minus-2',
				title: this.locale.getString(neutralFilterTranslationKey),
				ariaPatternKey: neutralMetricArialLabelKey,
				css: 'br-sentiment-neutral',
				selectedClass: 'action-color'
			}];

			let easeMetric = _.find(predefinedMetrics, {name: PredefinedMetricConstants.EASE_SCORE});
			let easeColorArray = (this.GroupColorType.types.EASE_3 as PredefinedMetricColorTypeDefinition)
				.getColorArray(easeMetric);
			let effortFilterButtons: IFilterButton[] = [{
				value: PredefinedFilterValue.ALL,
				countValue: PredefinedFilterValue.ALL,
				icon: 'q-icon-bubbles',
				title: this.locale.getString('docExplorer.hasEffort'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternDetectedEffort',
				css: 'br-ease-all',
				selectedClass: 'btn-selected'
			}, {
				value: PredefinedFilterValue.NEGATIVE,
				countValue: PredefinedFilterValue.NEGATIVE,
				icon: 'q-icon-effort-negative',
				title: this.locale.getString('docExplorer.hardEffort'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternHardEffort',
				css: 'br-ease-hard',
				selectedColor: easeColorArray[NEGATIVE_COLOR_INDEX],
			}, {
				value: PredefinedFilterValue.POSITIVE,
				countValue: PredefinedFilterValue.POSITIVE,
				icon: 'q-icon-effort-positive',
				title: this.locale.getString('docExplorer.easyEffort'),
				ariaPatternKey: 'docExplorer.ariaLabelPatternEasyEffort',
				css: 'br-ease-easy',
				selectedColor: easeColorArray[POSITIVE_COLOR_INDEX],
			}];

			this.buttonGroups = [{
				type: PredefinedMetricConstants.SENTIMENT,
				filtersData: this.sentimentFilters,
				getSelectedValue: () => this.sentimentValue,
				buttons: sentimentFilterButtons,
			}, {
				type: PredefinedMetricConstants.EASE_SCORE,
				filtersData: this.easeScoreFilters,
				getSelectedValue: () => this.easeScoreValue,
				buttons: effortFilterButtons,
			}];
			this.ref.markForCheck();
		});
		this.addSubscription(this.sentimentFilters.getChangeObserver().subscribe(() => this.ref.markForCheck()));
		this.addSubscription(this.easeScoreFilters.getChangeObserver().subscribe(() => this.ref.markForCheck()));

	}

	toggleFilter(type: PredefinedMetricConstants, value: PredefinedFilterValue): void {
		this.toggle.emit({type, value});
	}
}
