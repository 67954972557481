import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { HierarchyPublicationState } from '@cxstudio/organizations/hierarchy-publication-state';

export class HierarchyWizardUtils {

	static hierarchyHasModel(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.PUBLISHED ||
			hierarchy.publicationState === HierarchyPublicationState.CLASSIFYING ||
			hierarchy.publicationState === HierarchyPublicationState.CLASSIFIED;
	}
}
