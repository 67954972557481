import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';

@Directive({
	selector: 'report-view-wrapper'
})
export class ReportViewWrapperUpgrade extends UpgradeComponent {
	@Input() visualization: WidgetVisualization;
	@Input() trigger: boolean;
	@Input() data: ReportDataObject;
	@Input() demo: boolean;
	@Input() widget: Widget;
	@Input() utils: WidgetUtils;
	@Input() handleClick;
	@Input() handleRightClick;
	@Input() exportingImage: boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('reportViewWrapper', elementRef, injector);
	}
}
