export enum DialogStyle {
	REGULAR = 'primary',
	DANGER = 'danger',
	WARNING = 'warning'
}

export class DialogStyleUtils {
	static getButtonClass(style: DialogStyle): string {
		switch (style) {
			case DialogStyle.DANGER:
				return 'btn-danger';
			case DialogStyle.WARNING:
				return 'btn-warning';
			default:
				return 'btn-primary';
		}
	}
}
