import { DashboardsByLabelTab } from './classes/dashboards-by-label-tab.class';
import { FavoriteDashboardsTab } from './classes/favorite-dashboards-tab.class';
import { RecentDashboardsTab } from './classes/recent-dashboards-tab.class';
import { DashboardListTab } from './classes/dashboard-list-tab.class';
import { DashboardListTabType } from './classes/dashboard-list-tab-type.class';

export class DashboardListTabTypes {

	static getTabType(tab: DashboardListTab): DashboardListTabType {
		if (FavoriteDashboardsTab.is(tab)) return FavoriteDashboardsTab;
		if (RecentDashboardsTab.is(tab)) return RecentDashboardsTab;
		
		return DashboardsByLabelTab;
	}
}