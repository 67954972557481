import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { HomePageManagementComponent } from '@app/modules/home-page/home-page-management/home-page-management/home-page-management.component';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable()
export class HomePageGridDefinition implements IGridDefinition<HomePageManagementComponent> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [ {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'rank',
			name: this.locale.getString('homePage.rank'),
			field: 'rank',
			sortable: false,
			optional: true,
			minWidth: 60,
			width: 120,
			formatter: this.rankFormatter,
			cssClass: 'cell-rank text-center',
			headerCssClass: 'text-center',
			defaultSortColumn: SortDirection.ASC
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: false,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.getNameFormatter(),
			isObjectNameColumn: true
		}, {
			id: 'modifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: false,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'owner',
			name: this.locale.getString('homePage.lastModifiedBy'),
			field: 'lastModifiedBy',
			sortable: false,
			optional: true,
			minWidth: 80,
			width: 260,
			cssClass: 'cell-owner-name',
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'sharingStatus',
			name: this.locale.getString('common.status'),
			field: 'sharingStatus',
			sortable: false,
			optional: true,
			minWidth: 60,
			width: 120,
			formatter: this.statusFormatter,
			cssClass: 'cell-status text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'views',
			name: this.locale.getString('homePage.views'),
			field: 'views',
			sortable: false,
			optional: true,
			minWidth: 60,
			width: 120,
			cssClass: 'cell-views text-center',
			headerCssClass: 'text-center'
		}];

		return Promise.resolve(columns);
	}

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: HomePage) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
		if (dataContext.default) {
			return getSwitch(value, true, this.locale.getString('homePage.disableDefaultHomePage'));
		} else {
			return getSwitch(value, dataContext.activeLimitReached && !dataContext.enabled,
				this.locale.getString('homePage.activePagesLimitReached'));
		}
	}

	private statusFormatter: IRowFormatter = (row, cell, value: SharingStatus, columnDef, dataContext: HomePage) => {
		if (value)
			return this.gridFormatterService.StatusFormatter(row, cell, value, columnDef, dataContext);
		else return '';
	}

	private rankFormatter: IRowFormatter = (row, cell, value: number, columnDef, dataContext: HomePage) => {
		if (!_.isUndefined(value))
			return '' + (value + 1); // 0-based
		else return '';
	}

	private getNameFormatter = (): IRowFormatter => {
		let formatter = this.gridFormatterService.getNameFormatter(GridTypes.HOME_PAGES);
		return (row, cell, value: string, columnDef, dataContext: HomePage) => {
			return dataContext.default
				? formatter(row, cell, `${value} (${this.locale.getString('common.default')})`, columnDef, dataContext)
				: formatter(row, cell, value, columnDef, dataContext);
		};
	}
}

app.service('homePageGridDefinition', downgradeInjectable(HomePageGridDefinition));
