
<sidebar-section [header]="'widgetSettings.widgetSettings' | i18n">
	<div class="form-group">
		<labeled-toggle-switch class="d-flex mb-8"
			[value]="!widget.properties.isCustomTitle"
			(valueChange)="updateAutoTitle($event)"
			[disabled]="!isProjectSelected()"
			[label]="'docExplorer.useAutoTitle'|i18n"
		></labeled-toggle-switch>
		<label for="widget-title">{{'widget.title'|i18n}}</label>
		<input id="widget-title"
			type="text"
			class="edit-title w-100-percent mb-8"
			[disabled]="!widget.properties.isCustomTitle"
			[(ngModel)]="widget.displayName"
			(ngModelChange)="updateAltText()">
	</div>
	<div class="form-group">
		<labeled-toggle-switch class="d-flex mb-8"
			[(value)]="widget.properties.autoDescription"
			(valueChange)="updateAutoDescription()"
			[label]="'widgetSettings.inheritDescription'|i18n"
		></labeled-toggle-switch>
		<label for="sel-description">{{'dashboard.modalDesc'|i18n}}</label>
		<description id="sel-description"
			[(ngModel)]="widget.description"
			[disabled]="widget.properties.autoDescription"
			[maxLength]="1000">
		</description>
	</div>
	<div class="form-group">
		<label>{{'common.owner'|i18n}}</label>
		<input class="w-100-percent"
			disabled
			[ngModel]="widget.properties.runAs">
	</div>
</sidebar-section>
<sidebar-section [header]="'widgetSettings.dataSource' | i18n">
	<workspace-project-selector
		class="d-block max-width-3 mandatory"
		(workspaceChange)="onWorkspaceChange($event)"
		[value]="widget.properties.workspaceProject"
		(valueChange)="onProjectChange($event)"
		[labelPosition]="LabelPosition.TOP"
		(loading)="loading.emit($event)"
		(ready)="onProjectInit()"
	></workspace-project-selector>
</sidebar-section>
<sidebar-section *ngIf="isChartWidget()" [header]="'widgetSettings.accessibility' | i18n">
	<labeled-toggle-switch class="d-flex mb-8"
		[(value)]="widget.properties.altTextFromTitle"
		(valueChange)="updateAltText()"
		[label]="'common.inheritAltText'|i18n"
	></labeled-toggle-switch>
	<label for="sel-alt-text">{{'common.altText'|i18n}}</label>
	<input id="sel-alt-text"
		class="w-100-percent"
		maxlength="150"
		[disabled]="widget.properties.altTextFromTitle"
		[(ngModel)]="widget.properties.altText">
</sidebar-section>
<sidebar-section *ngIf="hasEmbedPermission()"
	[toggleable]="true"
	[(enabled)]="widget.embedConfig.enabled"
	[header]="'widgetSettings.externalEmbedding' | i18n">
	<external-embedding-settings *ngIf="widget.embedConfig.enabled"
		[widget]="widget"
		[embedConfig]="widget.embedConfig">
	</external-embedding-settings>
</sidebar-section>
