import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DashboardCreationOriginDetails } from '@app/modules/dashboard/dashboard-creation-origin-details';
import DashboardCreationOrigin from '@app/modules/dashboard/dashboard-creation-origin.enum';
import { DocumentExplorerDialogService } from '@app/modules/document-explorer/document-explorer-dialog.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ObjectUtils } from '@app/util/object-utils';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { IDriversModelItem } from '@cxstudio/drivers/entities/drivers-model';
import { DriversResultItem } from '@cxstudio/drivers/entities/drivers-result-item';
import ManageDriversService from '@cxstudio/drivers/manage-drivers.service';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { AnalyticDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/analytic-drill-utils';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { FilterParsingService } from '@cxstudio/services/filter-parsing-service';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';

@Component({
	selector: 'drivers-results-tab',
	templateUrl: './drivers-results-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriversResultsTabComponent implements OnInit {

	@Input() driversItem: DriversItem;
	@Input() reportDrivers: DriversResultItem[];
	@Input() predictiveDrivers: IDriversModelItem[];

	@Output() hideDriversItem = new EventEmitter<DriversResultItem>();

	loading: Promise<void>;
	showInverseDrivers: boolean;
	attributes: IReportAttribute[];

	private defaultColorPalette: WidgetColorPalette;

	dashboard: Dashboard;
	originDetails: DashboardCreationOriginDetails;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly attributesService: AttributesService,
		private readonly documentExplorerDialogService: DocumentExplorerDialogService,
		@Inject('filterParsingService') private readonly filterParsingService: FilterParsingService,
		@Inject('filterMatchModes') private readonly filterMatchModes: FilterMatchModes,
		@Inject('dateFilterService') private readonly dateFilterService: DateFilterService,
		@Inject('gridsterConfigurer') private readonly gridsterConfigurer: GridsterConfigurer,
		@Inject('analyticDrillUtils') private readonly analyticDrillUtils: AnalyticDrillUtils,
		@Inject('driversUtils') private readonly driversUtils: DriversUtils,
		@Inject('colorPalettes') private readonly colorPalettes: ColorPalettes,
		@Inject('manageDriversService') private readonly manageDriversService: ManageDriversService
	) {}

	ngOnInit(): void {
		this.originDetails = {
			type:  DashboardCreationOrigin.DRIVER,
			name: this.driversItem.displayName
		};

		this.dashboard = {
			name: this.driversItem.displayName,
			type: DashboardType.DASHBOARD,
			properties: {
				cbContentProvider: this.driversItem.contentProviderId,
				cbAccount: this.driversItem.accountId,
				project: this.driversItem.projectId
			} as DashboardProperties
		} as Dashboard;	

		let project = {
			contentProviderId: this.driversItem.contentProviderId,
			accountId: this.driversItem.accountId,
			projectId: this.driversItem.projectId,
		};
		this.loading = this.attributesService.getAttributes(project)
			.then((result) => { this.attributes = result; });

		this.colorPalettes.getDefaultPalette().then(palette => {
			this.defaultColorPalette = palette;
		});
	}

	getRuleString = (rule: IFilterRule, isTooltip: boolean = false): string => {
		let filterRule = ObjectUtils.copy(rule);
		if (!filterRule.displayName) {
			let attribute = _.findWhere(this.attributes, { name: rule.attributeName });
			filterRule.displayName = attribute ? attribute.displayName : '';
		}
		if (filterRule.values && filterRule.values.length > 3 && !isTooltip) {
			let matchMode = this.filterMatchModes.valueOf(filterRule);
			return filterRule.displayName + ' '
				+ matchMode.displayName + ' '
				+ this.locale.getString('filter.firstAndMoreCount', {
					first: filterRule.values[0].text,
					moreCount: filterRule.values.length - 1
				});
		} else {
			return this.filterParsingService.getRuleString(filterRule);
		}
	}

	getOutcomeFilters = (): IFilterRule[] => {
		return this.driversItem.target.filters.filterRules;
	}

	getAppliedFilters = (): any[] => {
		let filters = [];
		filters.push(this.driversItem.definition.dateRange);
		filters = filters.concat(this.driversItem.definition.additionalFilters.filterRules);
		return filters;
	}

	getDateFilterLabel = (filter): string => {
		let dateRange = {
			from: filter.fromDate,
			to: filter.toDate
		} as IDateRange;
		let timezone = this.driversItem.definition.timezone && this.driversItem.definition.timezone.name || '';
		return this.dateFilterService.getCustomRangeLabel(dateRange, timezone);
	}

	getFilterLabel = (filter): string => {
		if (filter.type === FilterRuleType.dateRange) {
			return this.getDateFilterLabel(filter);
		}
		return this.getRuleString(filter);
	}

	getFilterTooltip = (filter): string => {
		if (filter.type === FilterRuleType.dateRange) {
			return this.getDateFilterLabel(filter);
		}
		return this.getRuleString(filter, true);
	}

	isBold = (strength: number): boolean => {
		return Math.abs(strength) > 0.7;
	}

	getStrengthTooltip = (item: DriversResultItem): string => {
		return item.strength + '';
	}

	getFieldTooltip = (item): string => {
		return item.nodePath;
	}

	getDriversWidget = (dashboard?: Dashboard): [Widget] => {
		let widget = this.driversUtils.getReportSettings(this.driversItem);
		this.addVisualProperties(dashboard, widget);
		this.driversUtils.processAxisRange(this.predictiveDrivers, widget.visualProperties);
		widget.properties.altTextFromTitle = true;
		widget.properties.altText = widget.displayName;

		return [widget];
	}

	getPositiveDrivers = (): DriversResultItem[] => {
		return _.filter(this.reportDrivers, this.filterPositiveDrivers);
	}

	private filterPositiveDrivers = (item): boolean => {
		return item.rawStrength >= 0;
	}

	getNegativeDrivers = (): DriversResultItem[] => {
		return _.filter(this.reportDrivers, this.filterNegativeDrivers);
	}

	private filterNegativeDrivers = (item): boolean => {
		return item.rawStrength < 0;
	}

	addVisualProperties = (dashboard: Dashboard, widget: Widget): void => {
		widget.visualProperties = {} as VisualProperties;
		widget.visualProperties.visualization = WidgetVisualization.BUBBLE;
		widget.visualProperties.subChartType = undefined;
		widget.visualProperties.primaryGroup = this.driversItem.name;
		widget.visualProperties.xAxis = StandardMetricName.VOLUME;
		widget.visualProperties.yAxis = StandardMetricName.STRENGTH;
		widget.visualProperties.yAxisAutoMax = false;
		widget.visualProperties.yAxisAutoMin = false;
		widget.visualProperties.yAxisMax = '1';
		widget.visualProperties.yAxisMin = '0';
		widget.visualProperties.showLegend = true;
		widget.visualProperties.size = '';

		widget.visualProperties.color = (dashboard && dashboard.properties && dashboard.properties.color)
			? dashboard.properties.color
			: this.defaultColorPalette.name;

		widget.properties.lockFilters = true;
		widget.properties.autoDescription = true;
		widget.width = this.gridsterConfigurer.getGridsterItemWidth();
		widget.height = this.gridsterConfigurer.getGridsterItemHeight();
	}

	openAnDocumentExplorer = (item: DriversResultItem): void => {
		let widget = this.analyticDrillUtils.createDrillWidget(this.driversUtils.getReportSettings(this.driversItem),
			item, 'an_doc_explorer') as Widget;

		widget.selectivePreview = true;

		widget.properties.objectName = DrillWidgetType.SELECTIVE_PREVIEW;

		let paging = {
			pages: new Pagination(20, 1),
		};

		this.documentExplorerDialogService.open({ widget, paging });
	}

	hide = (driversItem: DriversResultItem): void => {
		this.hideDriversItem.emit(driversItem);
	}

	isDriverEditable = (): boolean => {
		return this.manageDriversService.canEdit(this.driversItem);
	}

	hasNegativeDrivers = (): boolean => {
		return _.any(this.reportDrivers, this.filterNegativeDrivers);
	}

}
