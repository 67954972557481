import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'selected-color-swatch',
	template: `
	<span class="d-flex flex-wrap">
		<span class="display-name mr-8">{{displayName}}</span>
		<div class="color-swatch-bar">
			<div class="color-swatch"
				*ngFor="let color of colors"
				[style.background-color]="color">
			</div>
		</div>
	</span>	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectedColorSwatchComponent {
	@Input() displayName: string;
	@Input() colors: string[];
}
