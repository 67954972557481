import { Inject, Injectable } from '@angular/core';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { DualDefinitionUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-utils.class';
import { DualLegendSeriesService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/dual-legend-series.service';
import { LegendLinkingUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/legend-linking-utils.service';
import { LegendSeriesRulesService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/legend-series-rules.service';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';
import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';

export type LegendFunction = (object: IDataPointObject) => {name: string, order?: number};
export type LegendFunctions = {[key in ColorTypes]?: LegendFunction};
@Injectable({
	providedIn: 'root'
})
export class DualLegendService {

	constructor(
		private legendLinkingUtils: LegendLinkingUtilsService,
		private legendSeriesRules: LegendSeriesRulesService,
		private dualLegendSeries: DualLegendSeriesService,
		private chartAccessibilityService: ChartAccessibilityService
	) { }

	processLegend(opts: Highcharts.Options, 
			primarySeries: any[], 
			secondarySeries: any[], 
			colorFields: ColorTypes[],
			dualUtils: DualDefinitionUtils,
			options: VisualProperties,
			utils: WidgetUtils,
	): void {
		let isObjectBasedColor = ColorUtilsHelper.isObjectBasedColorUsed(colorFields, options);
		let isSimpleLegend = !isObjectBasedColor;

		let legendFunctions = this.getLegendFunctions(utils, isObjectBasedColor);
		if (!legendFunctions) return;

		if (isObjectBasedColor) {
			opts.legend.enabled = options.showLegend;

			if (dualUtils.isPrimaryAndSecondarySameColor() && !dualUtils.hasAnyRecolor()) {
				primarySeries.forEach((oneSeries) => this.linkSeriesWithMatchingColor(oneSeries, secondarySeries));
			}
			let keepOriginalLegendItems = dualUtils.isPrimaryAndSecondarySameColor() && !dualUtils.isMetricColor('color');
			this.createCustomLegendItems(opts, legendFunctions, options, utils, isSimpleLegend, keepOriginalLegendItems);
		} else {
			// hide series if custom color
			if (!dualUtils.hasPop()) {
				this.hideCustomColors(primarySeries, options.color);
			}

			if (secondarySeries)
				this.hideCustomColors(secondarySeries, options.secondaryColor);

			if (dualUtils.isPrimaryAndSecondarySameColor() && !dualUtils.hasAnyRecolor()) {
				primarySeries.forEach((oneSeries) => this.linkSeriesWithMatchingColor(oneSeries, secondarySeries));
			} else {
				ColorUtilsHelper.checkForStackedRecolors(options, primarySeries, secondarySeries);

				if (options.recolors && options.recolors.length) {
					this.createCustomLegendItems(opts, legendFunctions, options, utils, isSimpleLegend);
				}
				//apply custom colors to legend items
				if (dualUtils.isLine()) {
					if (dualUtils.isLineChart() && this.legendSeriesRules.isCustomColor(options.pointColor)) {
						_.each(primarySeries, (oneSeries) => {
							oneSeries.marker.fillColor = options.pointCustomColor;
						});
					}
					if (dualUtils.isLineChartSecondary() && this.legendSeriesRules.isCustomColor(options.secondaryPointColor)) {
						_.each(secondarySeries, (oneSeries) => {
							oneSeries.marker.fillColor = options.secondaryPointCustomColor;
						});
					}
				}
			}
		}

		if (dualUtils.getChartType() === ChartType.COLUMN
				&& dualUtils.hasSecondaryGroup() 
				&& !dualUtils.applyClustering() 
				&& !dualUtils.isPop()) {
			opts.legend.margin = -50;
		}
	}

	private linkSeriesWithMatchingColor(oneSeries, secondarySeries: any[]) {
		let matchingSecondary = _.find(secondarySeries, {id: oneSeries.id});

		if (matchingSecondary) {
			oneSeries.events = {legendItemClick: this.legendLinkingUtils.createLegendSeriesLink([oneSeries, matchingSecondary])};
			matchingSecondary.showInLegend = false;
		}
	}


	private hideCustomColors(series: any[], color: string) {
		if (this.legendSeriesRules.isCustomColor(color))
			series.forEach(this.legendLinkingUtils.hideFromLegend);
	}

	// we no longer care if the data exists in the returned report
	// and always want to show all colors for selected metric/sentiment in legend
	private getMetricLegends(utils: WidgetUtils, opts: Highcharts.Options): {sentiment?: any[], metric?: any[]} {
		let legendsMap = _.groupBy(utils.metricLegendFunctions.filter(metricDefinition => !!metricDefinition),
			metricDefinition => AnalyticsDefinitionUtils.isAnySentiment(metricDefinition.name) ? 'sentiment' : 'metric');

		let result = {};
		_.each(legendsMap, (metricDefinitions: any[], key: 'sentiment' | 'metric') => {
			result[key] = _.chain(metricDefinitions)
				.map(metricDefinition => {
					let metricName = metricDefinition.name;
					let groupingFormatters = utils.getGroupingFormatters();
					let groupingFormatter = groupingFormatters[metricName];
					let values = metricDefinition.values as any[];
					if (groupingFormatter) {
						_.each(values, value => {
							value.name.name = groupingFormatter(value.name.name);
						});
					}
					return _.map(values, value => this.createLegendItem(value));
				})
				.flatten()
				.filter(this.removeInvalidColumns)
				.uniq(false, this.getLegendItemIdentifier)
				.sortBy('order')
				.value();
		});
		return result;
	}

	private processSimpleLegend = (object: IDataPointObject) => {
		return {name: object[object._group.identifier]};
	}


	private getLegendFunctions(utils: WidgetUtils, isObjectBasedLegend: boolean): LegendFunctions {
		let defaultColorFunctions = {
			color: this.processSimpleLegend,
			secondaryColor: this.processSimpleLegend
		};
		
		return (!isObjectBasedLegend || !utils.legendFunctions)
			? defaultColorFunctions
			: _.extend(defaultColorFunctions, utils.legendFunctions);
	}

	private createCustomLegendItems(opts: Highcharts.Options, legendFunctions: LegendFunctions, 
		options: VisualProperties, utils: WidgetUtils, isSimpleLegend: boolean, keepOriginalSeries?: boolean) {
		let legendItems = [];
		if (!keepOriginalSeries) {
			_.each(opts.series, (oneSeries) => {
				oneSeries.showInLegend = false;
				legendItems = legendItems.concat(this.dualLegendSeries.processSeriesData(oneSeries, legendFunctions,
					options, utils, isSimpleLegend));
			});

			// start with normal legend items, then add sentiment and metric items on the end
			legendItems = _.chain(legendItems)
				.filter(this.removeInvalidColumns)
				.uniq(false, this.getLegendItemIdentifier)
				.sortBy('order')
				.value();
		}

		let metricLegends = this.getMetricLegends(utils, opts);

		if (DualDefinitionHelper.isPop(options)) {
			opts.series.pushAll(metricLegends.sentiment);
			opts.series.pushAll(metricLegends.metric);
			opts.series.pushAll(legendItems);
		} else {
			opts.series.pushAll(legendItems);
			opts.series.pushAll(metricLegends.sentiment);
			opts.series.pushAll(metricLegends.metric);
		}
	}

	private removeInvalidColumns = (item): boolean => {
		return !(item.type === 'column' && item.color === 'none');
	}

	private getLegendItemIdentifier(legendObject): string {
		return [legendObject.id, legendObject.identifier, legendObject.marker && legendObject.marker.enabled].join();
	}

	private createLegendItem(singleThreshold) {
		let metricName = singleThreshold.name.name;
		let metricColor = singleThreshold.color;

		return {
			identifier: singleThreshold.name.id, 
			name: metricName,
			color: metricColor,
			data: [], 
			grouping: false, 
			showInLegend: true, 
			type: 'column', 
			events: {legendItemClick: () => false} 
		};
	}
}
