<div class="row">
	<div class="col-sm-12">
		<label for="minDocCount">{{'drivers.minimumVolume'|i18n}}
			<cb-inline-help right>
				<help-body>
					<p>{{'drivers.minimumVolumeTooltip'|i18n}}</p>
				</help-body>
			</cb-inline-help>
		</label>
		<div>
			<input
				id="minDocCount"
				type="number"
				[strictMin]="0"
				[(ngModel)]="driversItem.minVolume"
				[disabled]="!isDriverEditable()">
		</div>
	</div>
</div>

<div class="row mt-16" *ngIf="hiddenDrivers.length > 0">
	<div class="col-sm-12 mb-4">{{'drivers.manuallyHiddenDrivers'|i18n}}</div>
	<div class="col-sm-12">
		<table class="w-100-percent">
			<thead>
				<tr>
					<th [i18n]="'drivers.impactScore'" class="text-right pr-24"></th>
					<th [i18n]="'drivers.driversName'" class=""></th>
					<th class=""></th>
					<th [i18n]="'widget.volume'" class="text-right "></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of hiddenDrivers | orderBy: '-strength'"
					class="hover-parent"
					[ngClass]="{'italic': isMissingDriver(item)}">
					<td class="text-right pr-24">{{ item.strength }}</td>
					<td class=""
						[ngbTooltip]="getHiddenTooltip(item)"
						placement="bottom"
						container="body"
						tooltipClass="cb-tooltip tooltip-top">
						{{ item.displayName }}
					</td>
					<td class="">
						<div *ngIf="isDriverEditable()">
							<span class="q-icon-add-3 cursor-pointer show-on-parent-hover"
								title="{{'drivers.unhideDriver' | i18n}}"
								(click)="unhide(item)">
							</span>
						</div>
					</td>
					<td class="text-right ">{{ item.volume | number }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
