
import { SharableComponent } from './sharable-component';
import { ComponentPermission } from './component-permission';
import { AssetComponent } from '../asset-component';
import { IShareEntity } from '@cxstudio/sharing/sharing-service.service';

export class SharableComponentGroup<T extends AssetComponent> {

	private components: Array<T & SharableComponent>;

	constructor(
		rawComponents: T[],
		private getTypeLabel: () => string,
		private hasViewPermission: () => boolean,
		private hasEditPermission: () => boolean,
		private share: (sharedComponents: Array<T & SharableComponent>, sharedRecipients: _.Dictionary<IShareEntity[]>) => any
	) {
		this.components = this.populateDefaultAccessOption(rawComponents);
	}

	isVisible(): boolean {
		return !isEmpty(this.components) && this.hasAnyPermission();
	}

	private hasAnyPermission(): boolean {
		return this.hasViewPermission() || this.hasEditPermission();
	}

	shareTo(recipients: IShareEntity[]): ng.IPromise<any> | null {
		let sharedComponents = this.components.filter(component => component.selectedAccess === ComponentPermission.GRANT_ACCESS);
		if (!isEmpty(sharedComponents)) {
			let sharedRecipients = this.groupRecipients(recipients);
			return this.share(sharedComponents, sharedRecipients);
		} else {
			return null;
		}
	}

	private groupRecipients(sharedEntities: IShareEntity[]): _.Dictionary<IShareEntity[]> {
		let recipients = angular.copy(sharedEntities);

		if (!this.hasEditPermission()) {
			recipients.forEach(entity => entity.permission = 'VIEW');
		}

		return _.groupBy(recipients, 'type');
	}

	private populateDefaultAccessOption(availableItems: T[]): Array<T & SharableComponent> {
		let result: Array<T & SharableComponent> = [];
		availableItems.forEach(item => {
			result.push({ ...item, selectedAccess: ComponentPermission.GRANT_ACCESS });
		});

		return result;
	}

}
