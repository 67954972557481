<div *ngIf="!hasError()"
	#tableContainer
	class="cb-preview-table-container text-default"
	[class.cursor-loading]="menuLoading"
	(contextmenu)="handleRightClick.emit($event)"
	(keydown)="onKeydown($event)">
	<table class="cb-preview-table table-header"
		[colResizable]="tableOptions"
		[style.width]="getHeaderWidth()">
		<thead>
			<tr class="nowrap border-t-0">
				<th *ngFor="let column of columns"
					class="kb-focusable-inset"
					tabindex="0"
					[style.text-align]="column.align"
					[style.width]="column.width * 100 + '%'"
					title="{{column.displayName}}"
					role="columnheader">
					<span class="preview-column-header pv-4 pl-8 pr-0">{{ column.displayName }}</span>
				</th>
			</tr>
		</thead>
	</table>
	<div #tableBodyContainer class="table-body-container">
		<table
			class="cb-preview-table table-body"
			[ngClass]="[getSentimentColumnClass(), getEaseScoreColumnClass(), getEmotionColumnClass() ]">
			<thead>
				<tr class="sizing-header">
					<!-- fake header, which specifies column widths -->
					<th *ngFor="let column of columns"
						class="p-0"
						[style.text-align]="column.align"
						[style.width]="column.width * 100 + '%'"
						role="columnheader">
					</th>
				</tr>
			</thead>
			<tbody>
				<tr class="cb-table-row"
					*ngFor="let row of rows; odd as isOdd; even as isEven"
					[attr.id]="getRowId(row)"
					(click)="handleRowClick(row, $event)"
					(keydown.enter)="handleRowEnter(row, $event)"
					[class.primary]="isOdd"
					[class.alternate]="isEven"
					[attr.selected]="row.selected || false"
					[attr.aria-selected]="!!row.selected"
					role="row">
					<td *ngFor="let column of columns"
						role="cell"
						class="cb-table-cell pv-2 pl-8 pr-0 kb-focusable-inset"
						tabindex="0"
						(click)="handleCellClick(row, column, $event)"
						(keydown.enter)="handleCellClick(row, column, $event)"
						[ngClass]="'column-' + column.name"
						[style.text-align]="column.align">
						<ng-container [ngSwitch]="column.name">
							<ng-container *ngSwitchCase="PreviewColumn.SENTENCE">
								<ng-container *ngIf="utils.translationEnabled; then translateCell else sentenceCell">
								</ng-container>
							</ng-container>
							<span *ngSwitchDefault [innerHTML]="getHtml(column, row)"></span>
						</ng-container>
						<ng-template #translateCell>
							<translated-text
								[cacheKey]="getUniqueId(row)"
								[showTitle]="false"
								[text]="getTranslationText(row)">
							</translated-text>
						</ng-template>
						<ng-template #sentenceCell>
							<verbatim-text *ngIf="isVerbatimView()"
								[verbatim]="row"
								[capitalization]="column.capitalization"
								(showMore)="showMore(row, $event)">
							</verbatim-text>
							<span *ngIf="!isVerbatimView()"
								[innerHTML]="getHtml(column, row)">
							</span>
						</ng-template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<widget-error *ngIf="hasError()" [widgetError]="widgetError" [demo]="demo"></widget-error>
