import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { SamlIdentityProvidersGridDefinition } from '@app/modules/system-administration/saml-settings/saml-identity-providers/miscellaneous/saml-identity-providers-grid-definition.service';
import { SamlServiceProvidersGridDefinition } from '@app/modules/system-administration/saml-settings/saml-service-providers/miscellaneous/saml-service-providers-grid-definition.service';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { SamlIdentityProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-identity-providers/saml-identity-providers.component';
import { SamlServiceProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-service-providers/saml-service-providers.component';
import { SamlIdentityProviderDialogComponent } from '@app/modules/system-administration/saml-settings/saml-identity-provider-dialog/saml-identity-provider-dialog.component';
import { SamlServiceProviderDialogComponent } from '@app/modules/system-administration/saml-settings/saml-service-provider-dialog/saml-service-provider-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		ItemGridModule,
		CxFormModule,
	],
	exports: [
		SamlIdentityProvidersComponent,
		SamlServiceProvidersComponent,
		SamlIdentityProviderDialogComponent,
		SamlServiceProviderDialogComponent
	],
	declarations: [
		SamlIdentityProvidersComponent,
		SamlServiceProvidersComponent,
		SamlIdentityProviderDialogComponent,
		SamlServiceProviderDialogComponent
	],
	providers: [
		SamlIdentityProvidersGridDefinition,
		SamlServiceProvidersGridDefinition
	],
})

export class SamlSettingsModule { }
