import { NgModule } from '@angular/core';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardReportTypeSelectorComponent } from './dashboard-report-type-selector/dashboard-report-type-selector.component';

@NgModule({
	imports: [
		SharedModule,
		I18nModule,
	],
	declarations: [
		DashboardReportTypeSelectorComponent
	],
})
export class DashboardPropsModule { }
