import { IMetricBounds } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/metric-bounds-utils.service';
import { MetricCalculation } from '@cxstudio/metrics/entities/metric-calculation';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { ColorPalettesHelper } from '@cxstudio/reports/coloring/color-palettes-helper';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';

export class GaugeColorPaletteHelper {
	private metrics: Metric[];
	private colorPalettesHelper: ColorPalettesHelper;

	constructor(
		private readonly calculationColorService: CalculationColorService,
		private readonly calculationColorType,
		private readonly metricBandsService,
		private readonly isDarkMode: boolean,
	) {}

	withMetrics(metrics: Metric[]): GaugeColorPaletteHelper {
		this.metrics = metrics;
		return this;
	}

	withPalettes(palettesHelper: ColorPalettesHelper): GaugeColorPaletteHelper {
		this.colorPalettesHelper = palettesHelper;
		return this;
	}

	getMetricCalculation(visualProps: VisualProperties, bounds: IMetricBounds): MetricCalculation {
		const colorName = visualProps.color;
		if (CalculationColorUtils.isCalculationColor(colorName)) {
			const metric: Metric = this.calculationColorService.getMetricByCalculationColor(colorName, this.metrics);

			// we have a single metric for sentiment so we need to adjust calculation for 3 bands
			let name: string = CalculationColorUtils.getCalculationColorAttributeName(colorName);
			let calculation: MetricCalculation = metric.definition?.calculation;
			if (calculation && (name === PredefinedMetricConstants.SENTIMENT_3 ||
				name === PredefinedMetricConstants.EASE_3)) {
				calculation.colorPalette = this.calculationColorType.typeOf(name).getColorArray(metric);
				calculation.thresholds = this.metricBandsService.removeOuterThresholds([...calculation.thresholds]);
			}

			return calculation;
		} else if (colorName === ColorPaletteNames.CUSTOM) {
			return this.getPaletteCalculation(bounds, [visualProps.customColor]);
		} else {
			const colors = this.colorPalettesHelper.getPaletteColors(colorName, this.isDarkMode);
			return this.getPaletteCalculation(bounds, colors);
		}
	}

	getMetricAttributeName(visualProps: VisualProperties): string {
		const colorName = visualProps.color;
		if (CalculationColorUtils.isCalculationColor(colorName)) {
			return this.calculationColorService.getMetricByCalculationColor(colorName, this.metrics).name;
		}
	}

	private getPaletteCalculation(bounds: IMetricBounds, colors: string[]): MetricCalculation {
		const sectorSize = (bounds.max - bounds.min) / colors.length;
		const thresholds = _.times(colors.length - 1, (index) => {
			return bounds.min + sectorSize * (index + 1);
		});
		return {
			min: bounds.min,
			max: bounds.max,
			thresholds,
			colorPalette: colors
		};
	}
}
