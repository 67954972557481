import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';

export class QuickInsightsQuery implements AttributeValueOption {

	static fromQueryParams(search: any): QuickInsightsQuery {
		return search ? new QuickInsightsQuery(search.name, search.displayName, search.attributeName,
			search.attributeDisplayName, search.special) : null;
	}

	static toQueryParams(value: AttributeValueOption): QuickInsightsQuery {
		return new QuickInsightsQuery(value.name, value.displayName, value.attributeName,
			value.attributeDisplayName, value.special);
	}

	constructor(
		public readonly name: string, // attribute name
		public readonly displayName: string, // value
		public readonly attributeName: string,
		public readonly attributeDisplayName: string,
		public readonly special: boolean,
	) {}
}
