import { EventEmitter, Output } from '@angular/core';
import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'searchable-hierarchy'
})

export class SearchableHierarchyUpgradeDirective extends UpgradeComponent {
	@Output() showNodeCheckbox: EventEmitter<{node: any}>;
	@Output() onNodeClick: EventEmitter<{node: any}>;
	@Output() nodeIsChecked: EventEmitter<{node: any}>;
	@Output() nodeIsHighlighted: EventEmitter<any>;
	@Output() nodeIndeterminate: EventEmitter<any>;
	@Output() tooltip: EventEmitter<any>;
	@Output() isIndeterminateFollowedByUncheck: EventEmitter<any>;
	@Output() nodeIsMarkedFn: EventEmitter<any>;
	@Output() folderExpandHandler: EventEmitter<any>;
	@Output() isChildLoadedFn: EventEmitter<any>;
	@Output() nodeCheckboxDisabledFn: EventEmitter<{node: any}>;

	@Input() ngNodeIsChecked: (passedNode: {node: any}) => boolean;
	@Input() ngShowNodeCheckbox: (passedNode: {node: any}) => boolean;
	@Input() ngNodeIsHighlighted: (passedNode: {node: any}) => boolean;
	@Input() ngNodeIndeterminate: (passedNode: {node: any}) => boolean;
	@Input() ngIsIndeterminateFollowedByUncheck: (passedNode: {node: any}) => boolean;
	@Input() ngNodeCheckboxDisabled: (passedNode: {node: any}) => boolean;

	@Input() hierarchyList;
	@Input() displayProperty;
	@Input() placeholder;
	@Input() noResultsText;
	@Input() selectedItem;
	@Input() hideSearch;
	@Input() folderClickIgnore;
	@Input() disabledItems;
	@Input() disabledItemTooltip;
	@Input() itemTemplate;
	@Input() highlightSelectedBackground;
	@Input() showClearButton;
	@Input() selectEverythingText;
	@Input() selectNoneText;
	@Input() selectEverything;
	@Input() selectNone;
	@Input() notRecommendedItems;
	@Input() showMultiselectButtons;
	@Input() showHelper;
	@Input() componentDisabled;
	@Input() showNotRecommendedPrompt;
	@Input() selectedLevel;
	@Input() limitedWidth;
	@Input() externalModel;
	@Input() isVisibleOverrided;
	@Input() intersectionObserver;
	@Input() customValidation;
	@Input() keepOnSelection;
	@Input() searchLabel;
	@Input() optionClassFormatter;
	@Input() buttonStyleId;
	@Input() skipSelection;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('searchableHierarchy', elementRef, injector);
	}
}
