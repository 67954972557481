
<div class="d-flex flex-direction-column h-100-percent pt-16" [ngBusy]="loading">

	<div *ngIf="showLastCachedTimestamp()"
		class="d-flex w-100-percent justify-end ph-24"
		[hidden]="visibility !== ProjectTabType.AVAILABLE">
		<span *ngIf="loadingStats" class="mr-8 rotate-infinite q-icon-spinner"></span>
		<span class="h6 italic">{{ lastCachedTimestamp }}</span>
	</div>
	<default-objects-table class="flex-fill overflow-hidden"
		tableName="projectAttributes"
		[hidden]="visibility !== ProjectTabType.AVAILABLE"
		[columnDefs]="columnDefs"
		[rowData]="attributes | nestedObjects"
		nameField="displayName"
		[getNameIcon]="getNameIcon"
		[quickFilterText]="searchText"
		[tableFilterManager]="filterManager"
		[getContextMenuItems]="getContextMenuItems"
		(gridReady)="onGridReady($event)"
	></default-objects-table>

	<hidden-assets-table class="flex-fill overflow-hidden"
		[hidden]="visibility !== ProjectTabType.HIDDEN"
		[project]="project"
		[resourceType]="AssetType.ATTRIBUTE"
		[searchText]="searchText">
	</hidden-assets-table>

</div>
