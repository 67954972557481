import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { LicenseType } from '@cxstudio/common/license-types';
import { License } from '../permissions/license.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Caches } from '@cxstudio/common/caches';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';

@Injectable({
	providedIn: 'root'
})
export class LicenseApiService {
	private cachedHttp: CxCachedHttp;

	private readonly LICENSE_TYPES_ORDER = [
		LicenseType.CX_STUDIO_BASIC,
		LicenseType.CX_STUDIO,
		LicenseType.CX_STUDIO_AND_CM,
		LicenseType.ANALYZE
	];

	constructor(
		private locale: CxLocaleService,
		cachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp = cachedHttpService.cache(Caches.LICENSE_TYPES);
	}

	getLicenseTypes = (): Promise<License[]> => {
		return this.cachedHttp.get('rest/license_type', {cache: CacheOptions.CACHED})
			.then((licenses: License[]) => {
				return _.sortBy(licenses, (license) => this.LICENSE_TYPES_ORDER.indexOf(license.licenseTypeId));
			})
			.then((licenses: License[]) => {
				return licenses.map(this.localizeLicenseTypeName);
			});
	}

	localizeLicenseTypeName = <T extends {licenseTypeId: LicenseType, licenseTypeName: string} = License>(licensedObj: T): T => {
		return {
			...licensedObj,
			licenseTypeName: this.getLocalizedLicenseName(licensedObj.licenseTypeId) ?? licensedObj.licenseTypeName
		};
	}

	/**
	 * Overwrite license name from backend with localized version
	 */
	private getLocalizedLicenseName(licenseTypeId: LicenseType): string {
		switch (licenseTypeId) {
			case LicenseType.CX_STUDIO_BASIC:
				return this.locale.getString('userAdministration.licenseConsumer');
			case LicenseType.CX_STUDIO:
				return this.locale.getString('userAdministration.licenseCreator');
			case LicenseType.CX_STUDIO_AND_CM:
				return this.locale.getString('userAdministration.licenseActivationLead');
			case LicenseType.ANALYZE:
				return this.locale.getString('userAdministration.licenseConfigurationAnalyst');
		}
	}
}

app.service('licenseApiService', downgradeInjectable(LicenseApiService));
