import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { ScorecardMetricsManagementService } from '@app/modules/scorecards/metrics/scorecard-metrics-management.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';


interface CalculationAssets {
	predefinedMetrics: Metric[];
	attributes: IReportAttribute[];
	metrics: Metric[];
	scorecardMetrics: ScorecardMetric[];
}

@Component({
	selector: 'report-calculation-selector',
	templateUrl: './report-calculation-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCalculationSelectorComponent implements OnInit, OnChanges {

	@Input() project: WorkspaceProject;
	@Input() selection: ReportCalculation;
	@Input() hierarchyGrouping: ReportGrouping | null;
	@Output() selectionChange = new EventEmitter<ReportCalculation>();

	@Output() openProperties = new EventEmitter<ReportGrouping>();

	options: any[];
	projectMetrics: Metric[];

	loading: Promise<any>;
	disabled: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly metricsService: MetricsService,
		private readonly attributesService: AttributesService,
		private readonly reportSettingsService: ReportSettingsService,
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private readonly scorecardMetricsService: ScorecardMetricsManagementService,
		private readonly organizationApiService: OrganizationApiService,
		private readonly optionsTemplatesService: OptionsTemplatesService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
		@Inject('metricUtils') private metricUtils: MetricUtils
	) {}

	ngOnInit(): void {
		this.initOptions();
	}

	ngOnChanges(changes: SimpleChanges<ReportCalculationSelectorComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.initOptions();
		}
		if (ChangeUtils.hasChange(changes.hierarchyGrouping)) {
			this.orgHierarchyChanged(changes.hierarchyGrouping.currentValue);
		}
	}

	private initOptions() {
		this.options = [];
		this.disabled = true;
		this.loading = this.loadAssets().then(assets => {

			this.options = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(_.union(
					this.metricConstants.getStandardCalculations(this.project.projectId),
					 [this.metricConstants.get().CONSTANT_SIZE]
				))
				.withPredefinedMetrics(assets.predefinedMetrics)
				.withAttributes(assets.attributes, MetricFilters.CALCULATION)
				.withMetrics(assets.metrics, this.project.projectId)
				.withScorecardMetrics(assets.scorecardMetrics)
				.filterAvailableOptions(this.sizeMetricsFilter)
				.build();

			if (!this.selection) {
				this.select(this.metricConstants.get().VOLUME);
			}
		}).then(() => this.enable());
	}

	// filter to remove Sentence Quartile and Sentence Word Count from sizing options
	sizeMetricsFilter = (metric) => {
		return (metric.name !== ClarabridgeAttributeName.CB_SENTENCE_WORD_COUNT)
			&& (metric.name !== ClarabridgeAttributeName.CB_SENTENCE_QUARTILE);
	}

	private loadAssets(): Promise<CalculationAssets> {
		let predefinedMetricsPromise = this.metricsService.getDynamicPredefinedMetrics(this.project, true);
		let attributesPromise = this.attributesService.getAttributes(this.project, CacheOptions.CACHED);
		let metricsPromise = this.metricsService.getMetrics(this.project, CacheOptions.CACHED);
		let scorecardMetricsPromise = this.scorecardMetricsService.getScorecardMetrics(this.project);

		return Promise.all([
			predefinedMetricsPromise,
			attributesPromise,
			metricsPromise,
			scorecardMetricsPromise
		]).then((result): CalculationAssets => {
			this.projectMetrics = result[2];

			return {
				predefinedMetrics: result[0],
				attributes: result[1],
				metrics: result[2],
				scorecardMetrics: result[3],
			};
		});
	}

	select(reportCalculation: ReportCalculation): void {
		if (this.selection?.name === reportCalculation.name) {
			return;
		}

		this.disabled = true;
		let calculation = ObjectUtils.copy(reportCalculation);

		this.loading = this.reportSettingsService.getCalculationSettingsByProject(this.project, calculation)
			.then((customFormatSettings) => {
				this.updateCalculation(calculation, customFormatSettings);
				this.enable();
			});
	}

	// TODO race condition here
	orgHierarchyChanged(grouping: ReportGrouping | null) {
		if (grouping === null) {
			this.initOptions();
			return;
		}
		const hierarchyId = parseInt(grouping.name, 10);
		const orgCalculationPromise = this.organizationApiService.getOrganizationCalculations(hierarchyId,
				{ reportableOnly: true, numericOnly: false });
		const hierarchyCustomMetricsPromise = PromiseUtils.wrap(this.metricApiService.getHierarchyCustomMetrics(hierarchyId));
		Promise.all([orgCalculationPromise, hierarchyCustomMetricsPromise]).then(responses => {
			const calculations = {
				enrichment: responses[0],
				custom: responses[1].data
			};
			let allOptions = this.metricUtils.buildOrgHierarchyMetricOptions(hierarchyId, calculations);
			let hierarchyMetricsGroup = this.optionsTemplatesService.buildHierarchyMetricsGroup(allOptions);
			HierarchyUtils.replaceOrAddGroup(this.options, OptionsConstant.HIERARCHY_CALCULATIONS, hierarchyMetricsGroup);
			this.options = [...this.options];
			this.ref.markForCheck();
		});
	}

	private updateCalculation(calculation: ReportCalculation, customFormatSettings: NumberFormatSettings): void {
		this.updateFormatSettings(calculation, customFormatSettings);

		this.selection = calculation;
		this.selectionChange.emit(calculation);
	}

	private updateFormatSettings(calculation: ReportCalculation, customFormatSettings: NumberFormatSettings): void {
		let defaultFormatSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(
			calculation as CalculationWithFormat,
			this.projectMetrics
		);

		_.extend(calculation, defaultFormatSettings, customFormatSettings);
	}

	private enable() {
		this.disabled = false;
		this.ref.detectChanges();
	}

}
