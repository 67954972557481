import { NgModule } from '@angular/core';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { SharedModule } from '@app/shared/shared.module';
import { PlotBandsConfigurerComponent } from '@app/modules/plot-lines/plot-bands/plot-bands-configurer/plot-bands-configurer.component';
import { PlotLineDropdownComponent } from '@app/modules/plot-lines/plot-line-dropdown/plot-line-dropdown.component';
import { PlotLinePreviewComponent } from '@app/modules/plot-lines/plot-line-dropdown/plot-line-preview.component';
import { PlotBandItemComponent } from './plot-bands/plot-band-item.component';
import { ReferenceLineBuilderComponent } from './reference-lines/reference-line-builder/reference-line-builder.component';
import { TimeReferenceLineBuilderComponent } from './reference-lines/time-reference-line-builder/time-reference-line-builder.component';

@NgModule({
	imports: [
		SharedModule,
		AssetManagementModule,
	],
	exports: [
		PlotLineDropdownComponent,
		PlotBandsConfigurerComponent,
		ReferenceLineBuilderComponent,
		TimeReferenceLineBuilderComponent
	],
	declarations: [
		PlotLinePreviewComponent,
		PlotLineDropdownComponent,
		PlotBandItemComponent,
		PlotBandsConfigurerComponent,
		ReferenceLineBuilderComponent,
		TimeReferenceLineBuilderComponent
	],
})
export class PlotLinesModule {}
