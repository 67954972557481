import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { PromiseUtils } from '@app/util/promise-utils';
import { CxLocaleService } from '@app/core';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { Security } from '@cxstudio/auth/security-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

const SAVE = true;
const ROLLBACK = false;

@Injectable({
	providedIn: 'root'
})
export class DashboardUnsavedChangesService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardPropsService') private readonly dashboardPropsService: DashboardPropsService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		private readonly cxDialogService: CxDialogService,
	) { }

	showUnsavedChangesDialog(bodyText: string = 'dashboard.unsavedChanges', hideRollbackOption = false): Promise<boolean> {
		// if dashboard name is empty or not unique, do not prompt for save
		if (!this.isDashboardNameValid()) {
			return this.getDashboardNameWarning().then(() => ROLLBACK);
		}

		return this
			.cxDialogService
			.showUnsavedChangesDialog(
				'dashboard.unsavedChangesHeader',
				bodyText,
				hideRollbackOption
			)
		;
	}

	isDashboardNameUnique(): boolean {
		let ownedDashboardNames = this.dashboardListService.getCurrentDashboardsList().filter((item) => {
			return item.type === DashboardType.DASHBOARD && this.security.isCurrentUser(item.ownerName);
		}).map(dashboard => dashboard.name);
		let trimmedName = this.dashboardPropsService.current.name.trim();
		return ownedDashboardNames.indexOf(trimmedName) === -1 || trimmedName === this.dashboardPropsService.initial.name;
	}

	private isDashboardNameEmpty(): boolean {
		return this.dashboardPropsService.current.name.trim().length < 1;
	}

	// return a warning for an empty or non-unique dashboard name
	private getDashboardNameWarning(): Promise<void> {
		let confirmMsg = this.isDashboardNameEmpty() ?
			this.locale.getString('dashboard.cannotSaveEmptyName') :
			this.locale.getString('dashboard.cannotSaveDuplicateName');

		return PromiseUtils.wrap(this.cbDialogService.confirm(
			this.locale.getString('dashboard.dashboardCannotBeSaved'),
			confirmMsg,
			this.locale.getString('common.continueWithoutSaving'),
			this.locale.getString('common.cancel')).result);
	}


	private isDashboardNameValid(): boolean {
		if (this.isDashboardNameEmpty() || !this.isDashboardNameUnique()) {
			return false;
		} else {
			return true;
		}
	}
}

app.service('dashboardUnsavedChanges', downgradeInjectable(DashboardUnsavedChangesService));
