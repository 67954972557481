import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { BubbleUtils } from '@cxstudio/reports/settings/bubble-utils.class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';

export interface BubbleConfigInput {
	configItem: any;
	propertyMap: VisualProperties;
}

@Component({
	selector: 'bubble-configuration-modal',
	templateUrl: './bubble-configuration-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleConfigurationModalComponent implements OnInit {
	@Input() input: BubbleConfigInput;

	propertyMap: {[key: string]: any};
	model: {[key: string]: any};
	scaleOptions: number[];

	constructor(
		private modal: NgbActiveModal
	) { }

	ngOnInit(): void {
		this.propertyMap = this.input.propertyMap;
		this.model = cloneDeep(this.input.configItem);
		this.model[this.propertyMap.scale] = this.model[this.propertyMap.scale] || BubbleUtils.DEFAULT_BUBBLE_SIZE;
		this.scaleOptions = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
	}

	save(): void {
		this.modal.close(this.model);
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
