import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { AlertLevel, GravatarFallbackOption, ToastService } from '@clarabridge/unified-angular-components';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CxNavigationService } from '../services/cx-navigation.service';
@Component({
	selector: 'user-menu',
	templateUrl: './user-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {

	@ViewChild(NgbDropdown) userMenuDropdown: NgbDropdown;
	user: any;
	isMobile: boolean;
	ideasForumFeatureEnabled: boolean;

	readonly MYSTERY_PERSON = GravatarFallbackOption.MYSTERY_PERSON;

	logout: () => void;

	about = this.cxNavigationService.showAboutStudio;
	accessibility = this.cxNavigationService.showAccessibilityStatement;

	getUserVoiceRedirectionURL = this.navigationService.getUserVoiceRedirectionURL;

	hasUserAdministrationAccess = this.authorization.hasUserAdministrationAccess;
	hasMasterAccountManagementAccess = this.authorization.hasMasterAccountManagementAccess;
	hasAdminAccess = this.authorization.hasAdminAccess;
	showSystemAdministration: boolean;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxNavigationService: CxNavigationService,
		private readonly globalUnloadService: GlobalUnloadService,
		private readonly toastService: ToastService,
		@Inject('security') private readonly security: Security,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) {}

	ngOnInit(): void {
		this.ideasForumFeatureEnabled = this.security.getContext().ideasForumEnabled;
		this.isMobile = this.environmentService.isMobile();
		this.user = {
			firstName: this.security.getUser().firstName,
			lastName: this.security.getUser().lastName,
			email :this.security.getUser().userEmail
		};

		this.showSystemAdministration = this.authorization.hasSystemAccess() && !this.security.isImpersonateMode();
	}

	checkedLogout = (): void => {
		this.globalUnloadService.getLeavePromises().then(() => this.security.logout());
	}

	showIdeasForumLink = (): boolean => this.ideasForumFeatureEnabled;

	showSettings = (): boolean => this.hasAdminAccess() || this.hasOrgHierarchyAccess();

	getCurrentUserFullName = () => `${this.security.getUser().firstName} ${this.security.getUser().lastName}`;

	hasAccessToken = (): boolean => !!this.security.getAccessToken();

	openProps(): void {
		this.navigationService.showUserPreferences().then(() => {
			const itemName = this.locale.getString('header.userPreferences');

			this.toastService.addToast(this.locale.getString('common.itemUpdated', {itemName}), AlertLevel.CONFIRM);
			setTimeout(
				() => {
					$('#profile-button').first().trigger('focus');
				});
		}, ({usingEscapeKey}) => {
			if (usingEscapeKey) {
				// userMenuDropdown.open does not work here. need to figure out why...
				$('user-menu :focusable').first().trigger('click');
				setTimeout(() => {
					$('[data-testid="user-menu-user-preferences"]').trigger('focus');
				});
			}
		});
	}

	onUserPreferencesKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.openProps();
			this.userMenuDropdown.close();
			setTimeout(() => {
				$('#user-preferences-tabs :focusable').first().trigger('focus');
			});
		}
	}

	onPageTemplatesKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			setTimeout(() => {
				$('#sidebar-toggle').first().trigger('focus');
			});
		}
	}

	onUserMenuToggleKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.toggleDropdownMenu();
		}
	}

	onUserMenuToggle = (event: MouseEvent): void => {
		this.toggleDropdownMenu();
	}

	toggleDropdownMenu = () => {
		setTimeout(() => {
			if (this.userMenuDropdown.isOpen()) {
				$('#profile-menu :focusable').first().trigger('focus');
			}
		});
	}

	onUserMenuKeydown = (event: any): void => {
		// close on tab or esc
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) || KeyboardUtils.isEventKey(event, Key.TAB)) {
			this.userMenuDropdown.close();
			$('user-menu :focusable').first().trigger('focus');
		}
	}

	getUserPreferencesAreaLabel = (): string => {
		const firstName = this.security.getUser().firstName;
		const lastName = this.security.getUser().lastName;

		return this.locale.getString('common.userPreferencesAriaLabel', { firstName, lastName });
	}

	hasOrgHierarchyAccess = (): boolean => this.authorization.hasMasterAccountManagementAccess();

}

app.directive('userMenu', downgradeComponent({component: UserMenuComponent}));
