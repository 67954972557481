import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class HighchartsStackedUtilService {
	public gridSingleSorter<T>(sortBy: keyof T, isAsc: boolean, gridData: T[]): void {
		const sign = isAsc ? 1 : -1;
		const field = sortBy;

		gridData.sort((dataRow1, dataRow2) => {
			const value1 = dataRow1[field];
			const value2 = dataRow2[field];

			if (value1 === value2) {
				return 0;
			}

			if (value1 === undefined || value2 === undefined) {
				return value1 === undefined ? 1 : -1; // always to the end
			}

			return (value1 > value2 ? 1 : -1) * sign;
		});
	}
}

app.service('highchartsStackedUtil', downgradeInjectable(HighchartsStackedUtilService));
