import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { DrillToBarProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-bar-processor.service';
import { DrillToCloudProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-cloud-processor.service';
import { DrillToHeatmapProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-heatmap-processor.service';
import { DrillToLineProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-line-processor.service';
import { DrillToMetricProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-metric-processor.service';
import { DrillToPieProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-pie-processor.service';
import { DrillToScatterProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-scatter-processor.service';
import { DrillToSentenceProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-sentence-processor.service';
import { DrillToTableProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-table-processor.service';
import { DrillToDocumentExplorerProcessorService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-to-document-explorer-processor.service';
import { GroupIdentifierHelper } from '../../analytic/group-identifier-helper';
import { ReportPeriods } from '../../analytic/report-periods';
import { IBasicDrillProcessor, IRedirectDrillProcessor } from './drill-to/drill-processor.class';
import { CommonDrillService } from '@app/modules/reports/utils/context-menu/drill/common-drill.service';

export class AnalyticDrillUtils {

	drillToProcessors: {[key: string]: IBasicDrillProcessor | IRedirectDrillProcessor} = {
		bar: this.drillToBarProcessor,
		column: this.drillToBarProcessor,
		line: this.drillToLineProcessor,
		dot: this.drillToLineProcessor,
		pie: this.drillToPieProcessor,
		cloud: this.drillToCloudProcessor,
		table: this.drillToTableProcessor,
		key_terms: this.drillToTableProcessor,
		heatmap: this.drillToHeatmapProcessor,
		metric: this.drillToMetricProcessor,
		an_preview: this.drillToSentenceProcessor,
		an_doc_explorer: this.anDocExplorerProcessor,
		scatter: this.drillToScatterProcessor,
		full_page_doc_explorer: this.anDocExplorerProcessor
	};

	constructor(
		private readonly commonDrill: CommonDrillService,
		private readonly highchartsAnalyticUtils: HighchartsAnalyticUtils,
		private readonly drillToBarProcessor: DrillToBarProcessorService,
		private readonly drillToLineProcessor: DrillToLineProcessorService,
		private readonly drillToCloudProcessor: DrillToCloudProcessorService,
		private readonly drillToTableProcessor: DrillToTableProcessorService,
		private readonly drillToHeatmapProcessor: DrillToHeatmapProcessorService,
		private readonly drillToMetricProcessor: DrillToMetricProcessorService,
		private readonly drillToScatterProcessor: DrillToScatterProcessorService,
		private readonly drillToPieProcessor: DrillToPieProcessorService,
		private readonly locale: ILocale,
		private readonly currentWidgets,
		private readonly anDocExplorerProcessor: DrillToDocumentExplorerProcessorService,
		private readonly drillToSentenceProcessor: DrillToSentenceProcessorService,
		private readonly metricConstants: MetricConstants
	) { }


	createDrillWidget(widget: Widget, point, type, applyLinkedFilters?, defaultColor?: IColorSelectorPalette): Widget | void {
		widget.firstPass = false;
		let isDocExplorer = type.endsWith('doc_explorer');
		let keepOriginalRunAs = isDocExplorer;
		let parentId = widget.id;
		let newWidget = this.commonDrill.drillFrom(widget, keepOriginalRunAs, defaultColor);
		this.commonDrill.adjustMaxGroupingSize(newWidget, type);
		if (isDocExplorer) {
			newWidget.parentWidget = widget;
			delete point._custom_filter;
		}

		if (type === 'key_terms') {
			point.groupBy = this.metricConstants.get().KEY_TERMS;
			newWidget.visualProperties.sortBy = this.metricConstants.get().KEY_TERMS_RANK.name;
			newWidget.visualProperties.direction = SortDirection.ASC;
		}
		this.commonDrill.addFiltersFromPoint(newWidget, point);

		if (applyLinkedFilters) {
			let filterObjects = this.currentWidgets.getLinkedFilters(widget.containerId, parentId);
			_.each(filterObjects, (filter) => {
				this.commonDrill.addFiltersFromPoint(filter.widget, filter.point, newWidget, true);
			});
			delete newWidget.linkedTo;
		}
		let drillToProcessor = this.drillToProcessors[type];
		return drillToProcessor.drillTo(newWidget, type, point, defaultColor);
	}

	convertDrillObject(widget: Widget, point: IDataPointObject, type): any {
		let drillObject: any = { type };
		let baseFields = [
			'groupBy',
			'_group',
			'_pop',
			'isWeekDescription',
			'_calculation_series',
			'P1StartDate',
			'P1EndDate',
			'P2StartDate',
			'P2EndDate',
			'name',
			'displayName',
			'dateRangeP2',
			'colorType',
			'seriesType',
			'rootCause_attributeName',
			'_custom_filter',
			'link',
			'selectedMetricName'
		];
		let grouping = /^_grouping_/;
		let metadata = /^_metadata_/;
		let groupings = GroupIdentifierHelper.getGroupings(widget.properties.selectedAttributes);
		let groupFields = _.filter(Object.keys(point), (key) => {
				return !!(grouping.test(key) || metadata.test(key) || _.findWhere(groupings, {identifier: key}));
			});
		let allFields = baseFields.concat(groupFields);
		drillObject.point = _.pick(point, allFields);

		if (point._group) {
			if (point._group.metricType === AnalyticMetricType.TIME) {
				drillObject.point.displayName = this.highchartsAnalyticUtils.getCategoryItemName(point._group, point);
			}

			if (!drillObject.point.displayName) {
				drillObject.point.displayName = point[point._group.identifier] || point.name;
			}

			if (widget.properties.widgetType === WidgetType.CLOUD) {
				drillObject.point.displayName = drillObject.point.displayName.entitiesToBrackets();
			}
		}

		if (point._pop && point._pop !== ReportPeriods.CURRENT) {
			drillObject.point.popName = (widget.visualProperties.periodLabel && widget.visualProperties.periodLabel[point._pop]) ?
				widget.visualProperties.periodLabel[point._pop] :
				this.locale.getString('widget.historic_period');
		}

		return drillObject;
	}
}

app.service('analyticDrillUtils', AnalyticDrillUtils);


