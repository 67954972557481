import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { SamlServiceProviderApiService } from '@app/modules/system-administration/saml-settings/saml-service-provider-api.service';
import { SamlServiceProvidersListActions } from '@app/modules/system-administration/saml-settings/saml-service-providers/miscellaneous/saml-service-providers-list-actions.service';
import { SamlServiceProvidersListMenu } from '@app/modules/system-administration/saml-settings/saml-service-providers/miscellaneous/saml-service-providers-list-menu.service';
import { SamlServiceProviderDialogComponent, SamlServiceProviderDialogInput } from '@app/modules/system-administration/saml-settings/saml-service-provider-dialog/saml-service-provider-dialog.component';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';

@Component({
	selector: 'saml-service-providers',
	templateUrl: './saml-service-providers.component.html',
	providers: [SamlServiceProvidersListActions, SamlServiceProvidersListMenu]
})

export class SamlServiceProvidersComponent implements OnInit {
	readonly ALIAS_NAME_PATTERN = 'studio-v';
	readonly ENTITY_ID_PATTERN = 'net:clarabridge:secure:saml:sp:v';

	readonly KEY_PAIR_CLASS: string = 'br-keyPairFile';

	gridOptions: SlickgridOptions;
	gridType = GridTypes.SERVICE_PROVIDERS;

	changedItems: any[];
	loading: Promise<any>;
	nameSearch: string;
	serviceProviders: SamlServiceProvider[];

	constructor(
		private serviceProviderApi: SamlServiceProviderApiService,
		private actionsService: SamlServiceProvidersListActions,
		private contextMenuUtils: SamlServiceProvidersListMenu,
		private cxDialogService: CxDialogService,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree
	) {

	}

	ngOnInit(): void {
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};

		this.reloadServiceProviders();
		this.actionsService.onChange.subscribe(() => this.reloadServiceProviders());
	}

	private onItemClick(event, object: SamlServiceProvider): void {
		if (this.gridUtils.isNameClick(event)) {
			this.editServiceProvider(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object, this), 'service-providers', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.toggle(object);
		} else if (this.gridUtils.isColumnClick(event, this.KEY_PAIR_CLASS)) {
			this.actionsService.downloadKeyPairFile(object);
		}
	}

	private reloadServiceProviders(): void {
		this.loading = this.serviceProviderApi.getServiceProviders().then(serviceProviders => {
			this.serviceProviders = serviceProviders;

			// We have to populate 'id' because SlickGrid expects this field in each object
			this.serviceProviders.forEach((serviceProvider) => {
				serviceProvider.id = serviceProvider.entityId;
				return serviceProvider;
			});

			this.refreshGrid();
		});
	}

	private refreshGrid(): void {
		this.changedItems = this.serviceProviders;
	}

	createServiceProvider(): void {
		let dialogInput = {
			serviceProvider: this.createNewServiceProvider(),
			allServiceProviders: this.serviceProviders
		} as SamlServiceProviderDialogInput;

		this.cxDialogService.openDialog(SamlServiceProviderDialogComponent, dialogInput, {size: ModalSize.MEDIUM}).result
		.then(serviceProvider => {
			this.actionsService.create(serviceProvider);
		}).catch(() => {});
	}

	editServiceProvider(samlServiceProvider: SamlServiceProvider) {
		let dialogInput = {
			serviceProvider: samlServiceProvider,
			allServiceProviders: this.serviceProviders
		} as SamlServiceProviderDialogInput;

		this.cxDialogService.openDialog(SamlServiceProviderDialogComponent, dialogInput, {size: ModalSize.MEDIUM}).result
		.then(serviceProvider => {
			this.actionsService.update(serviceProvider);
		}).catch(() => {});
	}

	private createNewServiceProvider(): SamlServiceProvider {
		let serviceProvider = new SamlServiceProvider();

		serviceProvider.aliasName = this.generateAliasName();
		serviceProvider.entityId = this.generateEntityId();
		serviceProvider.entityBaseURL = CONFIG.sso.authServerBaseUrl;

		return serviceProvider;
	}

	private generateAliasName(): string {
		return this.ALIAS_NAME_PATTERN + this.getNextId(this.ALIAS_NAME_PATTERN, 'aliasName');
	}

	private generateEntityId(): string {
		return this.ENTITY_ID_PATTERN + this.getNextId(this.ENTITY_ID_PATTERN, 'entityId');
	}

	private getNextId(pattern: string, fieldName: string): number {
		let elements = _.chain(this.serviceProviders)
			.pluck(fieldName)
			.filter(element => element && new RegExp(pattern).test(element))
			.map(element => element.substring(pattern.length, element.length))
			.filter(element => !isNaN(element))
			.value();

		if (elements.length === 0) {
			return 1;
		}

		let previousId = _.chain(elements)
			.map(elementId => parseInt(elementId, 10))
			.max()
			.value();

		return previousId + 1;
	}
}

app.directive('samlServiceProviders', downgradeComponent({component: SamlServiceProvidersComponent}) as angular.IDirectiveFactory);
