
import MobileScreenSettings from './mobile-screen-settings.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import MobileAppSettings from './mobile-app-settings.interface';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';

export default class MobileScreenConfigurationController implements ng.IComponentController {

	screen: MobileScreenSettings;
	screenIndex: number;
	projectIdentifier: ProjectIdentifier;
	widgetSettingsCache: CachedInvocation;
	settings: MobileAppSettings;
	promises: MobileAppConfigurationPromises;

	constructor(
		private $scope: ng.IScope
	) {
	}

	$onInit(): void {
		// required for typescript validation of IController
	}

}

app.component('mobileScreenConfiguration', {
	controller: MobileScreenConfigurationController,
	templateUrl: 'partials/mobile/mobile-screen-configuration.html',
	bindings: {
		screen: '=',
		screenIndex: '=',
		projectIdentifier: '<',
		widgetSettingsCache: '=?',
		settings: '=',
		promises: '='
	}
});
