import { GaugeBaseLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-base-layout';
import { GaugeChartLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-chart-layout';
import { GaugeHorizontalLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-horizontal-layout';
import { GaugeLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-layout';
import { GaugeTwoColumnsLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-two-columns-layout';
import { GaugeTwoLinesLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-two-lines-layout';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';
import { GaugeVerticalLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-vertical-layout';
import { WidgetSize } from '@app/modules/widget-visualizations/widget-size';
import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';

export interface GaugeLayoutOption {
	type: GaugeLayout;
	object: GaugeBaseLayout;
}

export class GaugeLayoutPicker {
	private static layoutOptions: GaugeLayoutOption[] = [{
		type: GaugeLayout.HORIZONTAL,
		object: new GaugeHorizontalLayout(),
	}, {
		type: GaugeLayout.TWO_COLUMNS,
		object: new GaugeTwoColumnsLayout(),
	}, {
		type: GaugeLayout.TWO_LINES,
		object: new GaugeTwoLinesLayout(),
	}, {
		type: GaugeLayout.VERTICAL,
		object: new GaugeVerticalLayout(),
	}];

	private static chartLayout = {
		type: GaugeLayout.CHART,
		object: new GaugeChartLayout(),
	};

	constructor() {
	}

	static getBestLayout(
		box: WidgetSize, cssVars: GaugeVariables, comparisons: ComparisonData[]
	): GaugeLayoutOption | undefined {
		if (_.isEmpty(comparisons)) {
			return this.chartLayout.object.isFit(box, cssVars, comparisons)
				? this.chartLayout
				: undefined;
		}

		let bestOption = GaugeLayoutPicker.layoutOptions.find(
			option => option.object.isFit(box, cssVars, comparisons));
		return bestOption;
	}

	static getDefaultLayout(comparisons: ComparisonData[]): GaugeLayoutOption {
		return _.isEmpty(comparisons)
			? this.chartLayout
			: this.layoutOptions[0];
	}
}
