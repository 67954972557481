<div [ngBusy]="loading">
	<ul ngbNav
		[destroyOnHide]="false"
		class="nav-tabs mb-0"
		#nav="ngbNav"
		[(activeId)]="activeTabId">
		<li [ngbNavItem]="widgetSettingTab.END_POINT_TAB">
			<a ngbNavLink>{{'widget.headerProps'|i18n}}</a>
			<ng-template ngbNavContent>
				<widget-properties-tab
					id="widget-properties-tab"
					[refreshTrigger]="activeTabId"
					[widget]="widget"
					(loading)="loading = $event"
					(projectChange)="onProjectUpdate($event, false)"
					(projectReset)="onProjectUpdate($event, true)">
				</widget-properties-tab>
			</ng-template>
		</li>
		<li [ngbNavItem]="widgetSettingTab.FILTERS_TAB" [disabled]="projectSelection === undefined">
			<a ngbNavLink>{{'widget.headerFilters'|i18n}}</a>
			<ng-template ngbNavContent>
				<filter-management-tab *ngIf="isProjectSelected()"
					id="widget-filters-tab"
					[widget]="widget"
					[props]="widget.properties"
					[projectSelection]="projectSelection"
					[projectTimezone]="projectTimezone">
				</filter-management-tab>
			</ng-template>
		</li>
		<li [ngbNavItem]="widgetSettingTab.VISUAL_TAB" [disabled]="projectSelection === undefined">
			<a ngbNavLink>{{'widget.headerVisualization'|i18n}}</a>
			<ng-template ngbNavContent>
				<ng-content></ng-content>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
