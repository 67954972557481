export class InternalUser {
	userId?: number;
	userEmail?: string;

	systemAdmin?: boolean;
	liteAdmin?: boolean;
	customerAdmin?: boolean;

	studioApiUser?: boolean;
	platformApiUser?: boolean;

	manageInternalTemplates?: boolean;
	shareInternalTemplates?: boolean;
	createInternalTemplates?: boolean;

	downloadTrainingData?: boolean;
}
