<div [ngBusy]="busy">
	<social-cases-log-filter
		class="grid-search-filter"
		(apply)="applyFilters($event)"
	></social-cases-log-filter>
	<div class="row col-sm-12">
		<formatted-table
			[rows]="rows"
			[columns]="columns"
		></formatted-table>
	</div>

	<pagination
		class="row col-sm-12"
		[pagination]="pagination"
		(paginationChange)="pageChanged($event)"
	></pagination>
</div>
