import { Component, forwardRef, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { IBetaFeatureRow } from '@app/modules/account-administration/beta-features-tab/beta-features-tab.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


interface BetaSelections {
	[key: string]: boolean;
}

@Component({
	selector: 'beta-features-checklist',
	templateUrl: './beta-features-checklist.component.html',
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BetaFeaturesChecklistComponent), multi: true},
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetaFeaturesChecklistComponent implements ControlValueAccessor, OnInit {
	@Input() selections: BetaSelections = {};
	@Output() selectionChange = new EventEmitter<BetaSelections>();


	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: BetaSelections) => void = _.noop;

	gridOptions: any;
	betaSearch: string;
	gridType = GridTypes.BETA_FEATURES_CHECKLIST;
	changedItems: any[];

	betaFeatures: Array<Partial<IBetaFeatureRow>>;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private gridUtils: GridUtilsService) { }

	ngOnInit(): void {
		this.loadFeatures();

		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};
	}

	private onItemClick(event, feature: Partial<IBetaFeatureRow>): void {
		if (this.gridUtils.isToggleClick(event)) {
			feature.enabled = !feature.enabled;

			this.selections[feature.id] = feature.enabled;
			this.onChange();
			this.refreshGrid([feature]);
		}
	}

	private refreshGrid(features: Array<Partial<IBetaFeatureRow>>): void {
		this.changedItems = [];
		this.changedItems = features;
		this.ref.markForCheck();
	}

	private loadFeatures(): void {
		this.betaFeatures = BetaFeature.values()
			.map(betaFeature => ({
				id: betaFeature.id,
				enabled: this.selections[betaFeature.id],
				name: this.locale.getString('betaFeatureNames.' + betaFeature.id),
				description: this.locale.getString('betaFeatureDescriptions.' + betaFeature.id)
			}));

		this.refreshGrid(this.betaFeatures);
	}

	selectAll(): void {
		BetaFeature.values().forEach(feature => {
			this.selections[feature.id] = true;
		});
		this.loadFeatures();
		this.onChange();
	}

	deselectAll(): void {
		BetaFeature.values().forEach(feature => {
			this.selections[feature.id] = false;
		});
		this.loadFeatures();
		this.onChange();
	}

	private onChange() {
		this.selectionChange.emit(this.selections);
		this.onChangeCallback(this.selections);
		this.onTouchedCallback();
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.selections) {
			this.selections = value;
			this.loadFeatures();
			this.ref.markForCheck();
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}

