import { MetricDefinition } from './metric-definition';
import { MetricType } from '@app/modules/metric/entities/metric-type';

export class FilterMetricDefinition extends MetricDefinition {
	rule: any;
	calculationName: string;
	calculationOperator: string;
	aggrLevel: string;
	nullInclude: boolean;

	constructor() {
		super(MetricType.FILTER);
	}
}
