import Widget from '@cxstudio/dashboards/widgets/widget';
import ReportRunService from '@cxstudio/reports/report-run.service';
import { ReportDataApiService } from './report-data-api.service';
import { WidgetDataService } from './widget-data-service.class';

export class WidgetDataServiceFactory {

	constructor(
		private reportDataApiService: ReportDataApiService,
		private reportRunService: ReportRunService,
	) {}

	create(widget?: Widget): WidgetDataService {
		return new WidgetDataService(this.reportDataApiService,
			this.reportRunService,
			widget);
	}

	getReportData(widget: Widget): ng.IPromise<any> {
		return this.create(widget).getReportData(widget);
	}
}

app.service('widgetDataServiceFactory', WidgetDataServiceFactory);
