import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';
import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';
import PlotBand from '@app/modules/plot-lines/plot-bands/plot-band';

export enum PlotBandsType {
	QUADRANTS = 'QUADRANTS' // not used yet
}

type PlotBandLines = {
	[axis in PlotLineAxis]: number[];
};

export default class PlotBandsOptions {
	static readonly DEFAULT_FONT_SIZE = 30;
	static readonly PRIMARY_LINE_DEFAULT = 0;
	static readonly SECONDARY_LINE_DEFAULT = 0;

	type: PlotBandsType;
	bands: PlotBand[];
	lines: PlotBandLines;
	fontSize: number;
	lineStyle: PlotLineStyle;

	static quadrantBands(): PlotBandsOptions {
		let plotBands = new PlotBandsOptions();
		plotBands.type = PlotBandsType.QUADRANTS;
		plotBands.fontSize = PlotBandsOptions.DEFAULT_FONT_SIZE;
		plotBands.lineStyle = new PlotLineStyle(PlotLineType.NoLine, 0, '#868898');
		plotBands.bands = [
			PlotBand.defaultOne(),
			PlotBand.defaultTwo(),
			PlotBand.defaultTwo(),
			PlotBand.defaultOne()
		];
		plotBands.lines = {
			primary: [PlotBandsOptions.PRIMARY_LINE_DEFAULT],
			secondary: [PlotBandsOptions.SECONDARY_LINE_DEFAULT]
		};
		return plotBands;
	}
}
