import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';

export class PredefinedMetricRanges {

	private static readonly KNOWN_METRICS: string[] = [
		StandardMetricName.PERCENT_OF_TOTAL,
		StandardMetricName.PARENT_PERCENT,
		PredefinedMetricConstants.SENTIMENT,
		PredefinedMetricConstants.EASE_SCORE,
		PredefinedMetricConstants.EMOTION,
	];

	static isKnownMetric(name: string): boolean {
		return PredefinedMetricRanges.KNOWN_METRICS.contains(name);
	}

	static getMetricMin(name: string): number {
		if (name === StandardMetricName.VOLUME
				|| name === StandardMetricName.PERCENT_OF_TOTAL
				|| name === StandardMetricName.PARENT_PERCENT) {
			return 0;
		}
		if (name === PredefinedMetricConstants.SENTIMENT || name === PredefinedMetricConstants.EASE_SCORE)
			return -5;
		if (name === PredefinedMetricConstants.EMOTION)
			return 1;
		return undefined;
	}

	static getMetricMax(name: string): number {
		if (name === StandardMetricName.PERCENT_OF_TOTAL
				|| name === StandardMetricName.PARENT_PERCENT) {
			return 1;
		}
		if (name === PredefinedMetricConstants.SENTIMENT || name === PredefinedMetricConstants.EASE_SCORE)
			return 5;
		if (name === PredefinedMetricConstants.EMOTION)
			return 5;
		return undefined;
	}
}
