import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { ObjectUtils } from '@app/util/object-utils';
import { FilterMetricDefinition } from '@cxstudio/metrics/entities/filter-metric-definition.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { Alignment } from '@cxstudio/reports/formatting/alignment.enum';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { FormatDataType } from '@cxstudio/reports/formatting/format-data-type.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { NumberDelimiterFormatterBuilderService } from './number-delimiter-formatter-builder.service';
import { Percent100FormatterBuilderService } from './percent-100-formatter-builder.service';
import { Percent100NoneFormatterBuilderService } from './percent-100-none-formatter-builder.service';

@Injectable({
	providedIn: 'root'
})
export class DefaultDataFormatterBuilderService {

	defaultFormatterSettings: {[key: string]: NumberFormatSettings};

	private volumeSetting: NumberFormatSettings;
	private percentSetting: NumberFormatSettings;
	private sentimentSetting: NumberFormatSettings;
	private npsSetting: NumberFormatSettings;
	private attributeSetting: NumberFormatSettings;
	private numericBreakdownSettings: NumberFormatSettings;
	pValueSettings: NumberFormatSettings;

	private defaultFormatterBuilder: {[key: string]: IFormatBuilder};
	private volumeFormatterBuilder: IFormatBuilder;
	private volumePercentFormatterBuilder: IFormatBuilder;
	private sentimentFormatterBuilder: IFormatBuilder;
	private npsFormatterBuilder: IFormatBuilder;
	private attributeFormatterBuilder: IFormatBuilder;
	private numericBreakdownFormatterBuilder: IFormatBuilder;


	constructor(
		readonly numberDelimiterFormatterBuilder: NumberDelimiterFormatterBuilderService,
		readonly percent100FormatterBuilder: Percent100FormatterBuilderService,
		readonly percent100NoneFormatterBuilder: Percent100NoneFormatterBuilderService,
		@Inject('MetricCalculationsType') private MetricCalculationsType: MetricCalculationsTypeService,
	) {
		this.volumeSetting = {
			decimals: Decimals.NONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			dataType: FormatDataType.NUMBER_DELIMITER,
			conversion: MetricMultiplierType.NONE
		};

		this.percentSetting = {
			suffix: '%',
			decimals: Decimals.ONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			dataType: FormatDataType.PERCENT_100,
			conversion: MetricMultiplierType.TIMES_HUNDRED
		};

		this.sentimentSetting = {
			decimals: Decimals.TWO,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			dataType: FormatDataType.NUMBER_DELIMITER,
			conversion: MetricMultiplierType.NONE
		};

		this.npsSetting = {
			decimals: Decimals.NONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			dataType: FormatDataType.PERCENT_100_NONE,
			conversion: MetricMultiplierType.TIMES_HUNDRED
		};

		this.attributeSetting = {
			decimals: Decimals.ONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			calculationType: CalculationFunction.AVG,
			dataType: FormatDataType.NUMBER_DELIMITER,
			conversion: MetricMultiplierType.NONE
		};

		this.numericBreakdownSettings = {
			decimals: Decimals.TWO,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			calculationType: CalculationFunction.AVG,
			dataType: FormatDataType.NUMBER_DELIMITER,
			conversion: MetricMultiplierType.NONE
		};

		this.pValueSettings = {
			decimals: Decimals.THREE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			conversion: MetricMultiplierType.NONE
		};

		this.volumeFormatterBuilder = numberDelimiterFormatterBuilder
			.getBuilder(this.volumeSetting);

		this.volumePercentFormatterBuilder = percent100FormatterBuilder
			.getBuilder(this.percentSetting);

		this.sentimentFormatterBuilder = numberDelimiterFormatterBuilder
			.getBuilder(this.sentimentSetting);

		this.npsFormatterBuilder = percent100NoneFormatterBuilder
			.getBuilder(this.npsSetting);

		this.attributeFormatterBuilder = numberDelimiterFormatterBuilder
			.getBuilder(this.attributeSetting);

		this.numericBreakdownFormatterBuilder = numberDelimiterFormatterBuilder
			.getBuilder(this.numericBreakdownSettings);

		this.defaultFormatterSettings = {
			VOLUME: this.volumeSetting,
			PERCENT_OF_VOLUME: this.percentSetting,
			VOLUME_PERCENT_TOTAL: this.percentSetting,
			SENTIMENT: this.sentimentSetting,
			TB: this.percentSetting,
			BB: this.percentSetting,
			NPS: this.npsSetting,
			ATTRIBUTE: this.attributeSetting,
			NUMERIC_BREAKDOWN: this.numericBreakdownSettings,
		};

		this.defaultFormatterBuilder = {
			VOLUME: this.volumeFormatterBuilder,
			PERCENT_OF_VOLUME: this.volumePercentFormatterBuilder,
			VOLUME_PERCENT_TOTAL: this.volumePercentFormatterBuilder,
			SENTIMENT: this.sentimentFormatterBuilder,
			TB: this.volumePercentFormatterBuilder,
			BB: this.volumePercentFormatterBuilder,
			NPS: this.npsFormatterBuilder,
			ATTRIBUTE: this.attributeFormatterBuilder,
			NUMERIC_BREAKDOWN: this.numericBreakdownFormatterBuilder
		};
	}

	getDefaultFormatterBuilder = (metric: CalculationWithFormat, allMetrics?: Metric[]): IFormatBuilder => {
		return this.getDefaultConfiguration(metric, this.defaultFormatterBuilder, allMetrics);
	}

	getDefaultFormatterSettings = (metric: CalculationWithFormat, allMetrics?: Metric[]): NumberFormatSettings => {
		return this.getDefaultConfiguration(metric, this.defaultFormatterSettings, allMetrics);
	}

	wrapper = (builder: IFormatBuilder): any => {
		return {
			format: (row, cell, value, columnDef, dataContext, options, forExport: boolean, metricForFormat: Metric) => {
				return builder.format(value, options, metricForFormat?.format, {ignoreAlignment: forExport});
			}
		};
	}

	private getDefaultConfiguration<T>(metric: CalculationWithFormat, defaults: {[key: string]: T}, allMetrics?: Metric[]): T {
		let res;
		if (metric.metricType === AnalyticMetricType.STANDARD && metric.name.toLowerCase().indexOf('percent') !== -1 ) {
			res = defaults.PERCENT_OF_VOLUME;
		} else if (metric.metricType === AnalyticMetricType.STANDARD && (metric.name.toLowerCase() === 'volume'
			|| metric.name.toLowerCase() === 'period_2_volume')) {
			res = defaults.VOLUME;
		} else if (metric.metricType === AnalyticMetricType.STANDARD && (metric.name.toLowerCase() === 'sentiment' ||
				metric.name.toLowerCase() === 'period_2_sentiment')) {
			res = defaults.SENTIMENT;
		} else if (metric.type === ReportAssetType.METRIC_STUDIO &&
				(metric.definition.type === MetricType.TOP_BOX || metric.definition.type === MetricType.BOTTOM_BOX )) {
			res = defaults.TB;
		} else if (this.MetricCalculationsType.SATISFACTION.is(metric)) {
			res = this.choosePercentOrDefaultFormatter(metric, defaults, defaults.NPS);
		} else if (AnalyticMetricTypes.isPredefinedMetric(metric)) {
			res = this.choosePercentOrDefaultFormatter(metric, defaults, defaults.NUMERIC_BREAKDOWN);
		} else if (this.MetricCalculationsType.FILTER_METRIC.is(metric) && !this.isPercentChangeMetric(metric)) {
			let definition = metric.definition as FilterMetricDefinition;
			let calculationName = definition.calculationName;
			if (!calculationName && (definition as any).calculations) // legacy "calculations" field?
				calculationName = (definition as any).calculations[0]?.name;
			if (allMetrics && allMetrics.length && calculationName && calculationName.indexOf('_studio_metric_') > -1) {
				let baseMetric = _.find(allMetrics, {name: calculationName});
				if (baseMetric)
					return this.getDefaultConfiguration(baseMetric as CalculationWithFormat, defaults, allMetrics);
			}

			if (calculationName === 'volume') {
				res = defaults.VOLUME;
			} else if (calculationName === 'sentiment') {
				res = defaults.SENTIMENT;
			} else if (/percent/i.test(calculationName)) {
				res = defaults.PERCENT_OF_VOLUME;
			} else {
				res = defaults.ATTRIBUTE;
			}
		} else if (this.isPercentChangeMetric(metric)) {
			res = defaults.PERCENT_OF_VOLUME;
		} else {
			res = defaults.ATTRIBUTE;
		}

		// return a copy so we can be sure we don't overwrite defaults
		return ObjectUtils.copy(res);
	}

	private isPercentChangeMetric = (metric): boolean => {
		return metric?.name?.startsWith('percentChange_');
	}

	private choosePercentOrDefaultFormatter = (metric, defaults, defaultFormat) => {
		return (this.isPercentChangeMetric(metric)) ? defaults.PERCENT_OF_VOLUME : defaultFormat;
	}
}

app.service('defaultDataFormatterBuilder', downgradeInjectable(DefaultDataFormatterBuilderService));
