import { Directive, Input, Output, ElementRef, Injector, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

@Directive({
	selector: 'dashboard-filters-edit'
})
export class DashboardFiltersEditUpgrade extends UpgradeComponent {
	@Input() dashboard: Dashboard;
	@Input() containerId: string;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() isVisible: () => boolean;
	@Input() editMode: boolean;
	@Input() embedded = false;
	@Input() personalization: PersonalizationState;
	@Input() projectAttributes: any[];
	@Input() projectDateFilters: {
		dateFilters: Array<{value: string, displayName: string}>;
	};
	@Input() projectTimezone: string;

	@Output() applyFilters: EventEmitter<void>;
	@Output() onFilterChange: EventEmitter<{$action: string, $filter: any}>;
	@Output() closePopup: EventEmitter<void>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('dashboardFiltersEdit', elementRef, injector);
	}
}
