export class IgnoredDashboardFilterTag {
	text: string;
	attributeMatcher: any;

	constructor(
		text: string, attributeMatcher: any) {
		this.text = text;
		this.attributeMatcher = attributeMatcher;
	}
}
