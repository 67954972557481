
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardListTabType } from './dashboard-list-tab-type.class';
import { DashboardListTab } from './dashboard-list-tab.class';

export class FavoriteDashboardsTab extends DashboardListTabType {
	static readonly TAB_NAME = 'favorites';
	static readonly TAB_ICON = 'q-icon-star';
	constructor() {
		super();
	}

	static getList(dashboards: Dashboard[]): Dashboard[] {
		return _.chain(dashboards)
			.filter(dashboard => dashboard.favorite && this.dashboardsOnly(dashboard))
			.sortBy('name')
			.value();
	}

	static getEmptyListHTML(localeService: CxLocaleService): string {
		return `<div class="d-inline-block ph-16 pv-8 text-center dashboard-preview-tile text-0_875rem">
			<span class="${this.TAB_ICON}" aria-hidden="true"></span>

			<p>${localeService.getString('dashboard.noFavoritesSelected')}</p>
		</div>`;
	}

	static getTabName(localeService: CxLocaleService): string {
		return localeService.getString('dashboard.favorites');
	}

	static getTabId(): string {
		return 'favoritesHeader';
	}

	static getContentId(): string {
		return 'favoritesContent';
	}

	static is(tab: DashboardListTab): boolean {
		return tab.pinned && (tab.name === FavoriteDashboardsTab.TAB_NAME);
	}
}
