import { Component, Input, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { Swimlanes } from '@app/modules/filter-builder/es-query/swimlanes';

@Component({
	selector: 'es-query',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './es-query.component.html'
})
export class EsQueryComponent implements OnInit {
	@Input() esQueryObject: Swimlanes;
	@Output() esQueryObjectChange = new EventEmitter<Swimlanes>();
	@Input() isFilterRule: boolean;
	maxlength: number;

	constructor(
		private esQueryService: EsQueryService
	) {}

	ngOnInit(): void {
		this.maxlength = this.esQueryService.getMaxLength(this.isFilterRule);
	}

	onChange = (): void => {
		this.esQueryObjectChange.emit(this.esQueryObject);
	}
}