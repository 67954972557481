import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface IPaletteReplaceParams {
	palette: ColorPalette;
	itemList: ColorPalette[];
	isDelete: boolean;
}

interface IPaletteReplaceUI {
	modalTitle: string;
	bodyText: string;
	selectorLabel: string;
	buttonText: string;
}

@Component({
	selector: 'color-palette-replace',
	templateUrl: './color-palette-replace.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPaletteReplaceComponent extends ModalBindings<IPaletteReplaceParams> implements OnInit {
	@Input() input: IPaletteReplaceParams;

	public ui: IPaletteReplaceUI;
	public itemList: ColorPalette[];
	public selected: {
		color?: string;
	};

	private palette: ColorPalette;
	private isDelete: boolean;

	constructor(
		modal: NgbActiveModal,
		private readonly locale: CxLocaleService
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.palette = this.input.palette;
		this.itemList = this.input.itemList.filter(palette => palette.name !== this.palette.name);
		this.isDelete = this.input.isDelete || false;
		this.selected = {};
		this.ui = this.getUIText(this.palette.displayName);
	}

	save = (): void => {
		const selectedItem = this.itemList.find((palette) => palette.name === this.selected.color);

		this.close(selectedItem);
	}

	getUIText = (itemName: string): IPaletteReplaceUI => {
		if (this.isDelete) {
			return {
				modalTitle: this.locale.getString('common.delete'),
				bodyText: this.locale.getString('colors.paletteDeleteBody', { itemName }),
				selectorLabel: this.locale.getString('colors.paletteDeleteSelectorTitle'),
				buttonText: this.locale.getString('common.delete')
			};
		}

		return {
			modalTitle: this.locale.getString('common.replace'),
			bodyText: this.locale.getString('colors.paletteReplaceBody', { itemName }),
			selectorLabel: this.locale.getString('colors.paletteReplaceSelectorTitle'),
			buttonText: this.locale.getString('common.replace')
		};
	}
}
