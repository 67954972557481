import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { CloudD3UtilsService } from '@app/modules/widget-visualizations/utilities/cloud-d3-utils.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { CBSettingsService, IWidgetSettingsScope } from '../services/cb-settings-service.service';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { RealDataPreviewWidget } from './real-data-preview-widget.class';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';

export class CloudDefinitionCtrl extends RealDataPreviewWidget {
	widgetType: WidgetType;

	loaded;

	constructor(
		protected readonly $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly metricConstants: MetricConstants,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private readonly cbSettingsService: CBSettingsService,
		private readonly sampleDataApiService: SampleDataAPIServiceClass,
		private readonly optionsBuilderProvider: OptionsBuilderProvider,
		private readonly $controller,
		private readonly calculationColorService: CalculationColorService,
		private readonly highchartsUtils: HighchartsUtilsService,
		private readonly cloudD3Utils: CloudD3UtilsService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly hierarchySettingsService: HierarchySettingsService,
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		super.$onInit();
		this.widgetType = this.$scope.props.widgetType;

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this.$scope,
			selectionConfiguration: {
				multiAttributeSelection: false
			},
			customCallback: {}
		});

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);
		this.$scope.staticData.ready = false;
		this.loaded = false;

		this.$scope.ui = this.$scope.ui || {};
		this.$scope.listTypes = KeyMetricListTypes;
		this.$scope.isUsingTimeGrouping = () => false;
		this.$scope.openAdvancedOptions = this.openAdvancedOptions;
		this.$scope.selectMetricInternal = this.selectMetricInternal;

		this.initProps();
		this.initialize();
	}

	initialize = (): void => {
		this.$scope.addLoadingPromise(this.sampleDataApiService.getCloudData().then((resp) => {
			this.$scope.staticData.data = resp.data;
			this.$scope.staticData.totalCount = 157;
			this.$scope.visualProps.visualization = WidgetVisualization.CLOUD;
			this.cloudD3Utils.populateDefaultOptions(this.$scope.visualProps);
			if (!this.$scope.visualProps.color) {
				this.$scope.visualProps.color = this.getDefaultColor();
			}
			if (isEmpty(this.$scope.visualProps.showSampleSize)) {
				this.$scope.visualProps.showSampleSize = true;
			}
		}, _.noop));

	}

	initProps = (): void => {
		if (!this.$scope.props.selectedAttributes) {
			this.$scope.props.selectedAttributes = [];
		}

		if (this.$scope.widget.created || !this.$scope.visualProps.color) {
			this.$scope.visualProps.color = this.getDefaultColor();
		}

		if (!this.$scope.visualProps.attributeSelections) {
			this.$scope.visualProps.attributeSelections = {};
		}

		if (!this.$scope.props.selectedMetrics) {
			this.$scope.props.selectedMetrics = [];
			this.$scope.selectMetric(this.constants.VOLUME).then(() => {
				this.checkPreviewDataAndApplyVisualChanges();
			}); // volume selected
		}

		if (!this.$scope.visualProps.periodLabel) {
			this.$scope.visualProps.periodLabel = {};
		}

		this.$scope.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		}];
		this.$scope.initializePeriods();
	}

	private getDefaultColor(): string {
		return InternalProjectTypes.isStudioAdminProject(this.$scope.props.project) ?
			undefined :
			this.calculationColorService.SENTIMENT5;
	}

	reloadSettings = (_event, callback): void => {
		this.initProps();

		let config = { withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING) };

		this.$scope.reloadCommonSettings(config).then((result) => {
			this.$scope.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this.$scope, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.$scope.options.primaryGroupingOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(result.models)
				.withAttributes(result.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(result.wordAttributes)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withOrgHierarchyModels(result.models, result.hierarchyModels)
				.withPredefinedGroups(angular.copy(result.predefinedMetrics))
				.build();
			this.$scope.options.primaryGroupingOptions = this.hierarchySettingsService.filterSelectedAttributesForGrouping(
				this.$scope.options.primaryGroupingOptions,
				this.$scope.dashboardFilters?.personalization?.isHierarchyEnabled(),
				this.$scope.dashboardFilters?.personalization?.getHierarchyId()
			);

			this.cbSettingsService.initDefaultProperties(this.$scope.options.primaryGroupingOptions, { size: 100 });

			this.$scope.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(_.union(this.metricConstants.getStandardCalculations(this.$scope.props.project), [this.constants.CONSTANT_SIZE]))
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withScorecardMetrics(result.scorecardMetrics)
				.filterAvailableOptions(this.$scope.sizeMetricsFilter)
				.build();
			this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, result.hierarchyGrouping,
				result.organizationCalculations, result.hierarchyModels);
			this.$scope.processSelections();

			this.$scope.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
			this.$scope.options.studioMetrics = _.filter(result.metrics, { projectId: this.$scope.props.project });

			this.$scope.options.cogSortByMetrics = angular.copy(this.$scope.options.additionalMetrics);

			this.$scope.updateCustomDateFilters(result.dateFilters);
			this.initDefaultGroupings();
			this.checkPreviewDataAndApplyVisualChanges();
			callback(true);
		});
	}

	private initDefaultGroupings(): void {
		if (!this.hasPrimaryGrouping()) {
			let allWordsGrouping = HierarchyUtils.findItem(this.$scope.options.primaryGroupingOptions, [
				{ name: OptionsConstant.NLP },
				{ name: OptionsConstant.WORDS },
				{ name: this.metricConstants.get().WORDS.name }
			]);

			if (allWordsGrouping)
				this.$scope.addAttribute(allWordsGrouping, 0).then(() => {
					this.realDataPreviewService.setPrevWidgetProps(this.$scope.props);
					this.realDataPreviewService.triggerReportRun();
				});
		}
	}

	private hasPrimaryGrouping(): boolean {
		return this.$scope.props?.selectedAttributes?.length > 0;
	}

	selectMetricInternal = (node): void => {
		this.$scope.selectMetric(node).then(() => {
			this.checkPreviewDataAndApplyVisualChanges();
		});
	}

	private resetSelections(): void {
		this.$scope.props.selectedMetrics = [];
		this.$scope.props.selectedAttributes = [];
		this.$scope.selectMetric(this.constants.VOLUME);
		this.$scope.visualProps.color = this.calculationColorService.SENTIMENT5;

		delete this.$scope.visualProps['primaryGroup'];

		this.checkPreviewDataAndApplyVisualChanges();
	}

	private updateVisualProps(newProps): void {
		this.$scope.widget.visualProperties = newProps;
		this.$scope.visualProps = newProps;
		this.checkPreviewDataAndApplyVisualChanges();
	}

	openAdvancedOptions = (): void => {
		this.highchartsUtils.showCloudAdvancedOptionsDialog(this.$scope.visualProps)
			.then((newProps) => {
				this.updateVisualProps(newProps);
			});
	}
}

app.controller('CBCloudChartCtrl', CloudDefinitionCtrl);
