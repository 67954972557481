import { Injectable } from '@angular/core';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';


// wrapper for screenfull so we can mock it easily in tests
@Injectable({
	providedIn: 'root'
})
export class FullscreenService {
	isFullscreen: boolean = false;
	isEnabled: boolean = (screenfull as Screenfull).isEnabled;
	constructor() { }

	on: (...args) => void = (screenfull as Screenfull).on;
	off: (...args) => void = (screenfull as Screenfull).off;	
	exit: () => void = (screenfull as Screenfull).exit;
	enter: () => void = (screenfull as Screenfull).request;
}
