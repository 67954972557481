import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AttributesTableComponent } from '@app/modules/project/project-assets-management/project-assets-list/attributes-table/attributes-table.component';
import { EditableAssetsTable } from '@app/modules/project/project-assets-management/project-assets-list/editable-assets-table.interface';
import { ModelsTableComponent } from '@app/modules/project/project-assets-management/project-assets-list/models-table/models-table.component';
import { ScorecardsTableComponent } from '@app/modules/project/project-assets-management/project-assets-list/scorecards-table/scorecards-table.component';
import { ProjectAssetsErrors } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { Unit } from '@app/modules/units/unit';
import { LabelPosition } from '@app/modules/units/workspace-project-selector/label-position';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ObjectUtils } from '@app/util/object-utils';
import { Security } from '@cxstudio/auth/security-service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectTabType } from '@cxstudio/projects/project-tab-type';

@Component({
	selector: 'project-assets-list',
	templateUrl: './project-assets-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectAssetsListComponent {

	@ViewChild(AttributesTableComponent) attributesTable: AttributesTableComponent;
	@ViewChild(ModelsTableComponent) modelsTable: ModelsTableComponent;
	@ViewChild(ScorecardsTableComponent) scorecardTable: ScorecardsTableComponent;


	visibilityTab: ProjectTabType;
	resourceType: ObjectType;

	project: WorkspaceProjectData;
	accountProject: IProjectSelection = new ProjectIdentifier(-1, -1, -1); // used for hide key generation

	loading: Promise<unknown>;
	errors: ProjectAssetsErrors = {};

	editMode: boolean;

	searchText: string;

	ObjectType = ObjectType;
	LabelPosition = LabelPosition;
	ProjectTabType = ProjectTabType;


	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private dialogService: CxDialogService,
		@Inject('security') private security: Security,
	) { }

	errorsChanged(messages: string[]): void {
		this.errors = ObjectUtils.copy(this.errors);
		this.errors.messages = messages;
	}

	workspaceChanged(workspace: Unit): void {
		if (workspace) {
			this.accountProject.contentProviderId = workspace.contentProviderId;
			this.accountProject.accountId = workspace.accountId;
		} else {
			this.accountProject.contentProviderId = -1;
			this.accountProject.accountId = -1;
		}
	}

	workspaceProjectChanged(newProject?: WorkspaceProjectData): void {
		if (newProject) {
			this.project = newProject;
			this.accountProject.projectId = newProject.projectId;
			this.accountProject.projectName = newProject.projectName;
		}
	}


	getResourceTypeLabel(): string {
		switch (this.resourceType) {
			case ObjectType.ATTRIBUTE : return this.locale.getString('common.attributes');
			case ObjectType.MODEL : return this.locale.getString('common.models');
			case ObjectType.SCORECARD : return this.locale.getString('scorecards.scorecards');
		}
	}

	canEdit(): boolean {
		return this.hasManagerAccess() && this.security.has(MasterAccountPermissionAction.MANAGE_PROJECTS);
	}

	private hasManagerAccess(): boolean {
		return this.project?.accessLevel === ProjectAccessLevelValue.MANAGER;
	}

	private getCurrentEditingTable(): EditableAssetsTable {
		return this.resourceType === ObjectType.ATTRIBUTE ? this.attributesTable : this.modelsTable;
	}

	saveChanges(): void {
		this.loading = this.getCurrentEditingTable().saveChanges().then(() => {
			this.editMode = false;
			this.ref.markForCheck();
		}, _.noop);
	}

	cancelChanges(): void {
		this.loading = this.getCurrentEditingTable().cancelChanges().then(() => {
			this.editMode = false;
			this.ref.markForCheck();
		}, _.noop);
	}

	newScorecard(): void {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.errors.noProjectSelected = true;
			return;
		}
		this.scorecardTable.newScorecard();
	}

	exportData(): void {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.errors.noProjectSelected = true;
			return;
		}
		this.getCurrentEditingTable().export().then(() => {
			let titleKey = 'administration.exportDialogTitle';
			if (this.visibilityTab === ProjectTabType.HIDDEN) {
				titleKey = 'administration.exportDialogTitleForHidden';
			}
			const resourceName = this.resourceType === ObjectType.ATTRIBUTE
				? this.locale.getString('common.attributes')
				: this.locale.getString('common.models');
			let dialogMessage = this.locale.getString('administration.exportToExcelMessage');
			let dialogTitle = this.locale.getString(titleKey, {
				projectName: this.project.projectName,
				resourceName
			});
			this.dialogService.notify(dialogTitle, dialogMessage);
		});
	}

}
