
export class ANPreviewExportColumn {
	constructor(
		public name: string,
		public field: string,
		public formatFunc: PreviewExportFormatter,
	) {}
}

export type PreviewExportFormatter = (value: any, index?: number, row?: any) => string;
