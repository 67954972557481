// wraps some heavy watcher to be able to pause it (and return last non-paused value)
export class PausingWrapper {
	
	private lastValue: any;

	static wrap = (valueSupplier: () => any, isPaused: () => boolean): () => any => {
		return new PausingWrapper(isPaused).wrapper(valueSupplier);
	}

	constructor(private isPaused: () => boolean) {}

	private wrapper = (valueSupplier: () => any): () => any => {
		return () => {
			if (!this.isPaused()) {
				this.lastValue = valueSupplier();
			}
			return this.lastValue;
		};
	}
}

