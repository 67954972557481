<modal-header
	(cancel)="cancel()"
	[modalTitle]="'appearance.conversationDisplayHeading' | i18n">
</modal-header>
<section class="modal-body" [ngBusy]="loading">
	<h3 class="d-flex">{{'appearance.participants'|i18n}}
		<cb-inline-help>
			<help-body class="help-icon">{{'appearance.participantsHelpText'|i18n}}</help-body>
		</cb-inline-help>
	</h3>
	<label class="d-flex">{{'common.displayNames'|i18n}}
		<cb-inline-help>
			<help-body><span [i18n]="'appearance.customLabels'" [i18nParams]="{limit : CUSTOM_LABEL_LIMIT}"></span></help-body>
		</cb-inline-help>
	</label>
	<div class="form-group form-inline"
		*ngFor="let channelType of ['agent', 'client', 'bot', 'unknown']">
		<div class="d-flex align-items-center channel-names">
			<label for="{{channelType}}Label">
				{{'docExplorer.' + channelType + 'Label'|i18n}}
			</label>
			<div class="channel-label-input">
				<input *ngIf="!settings.labels[channelType + 'AutoTitle']"
					name="{{channelType}}Label"
					id="{{channelType}}Label"
					maxlength="{{CUSTOM_LABEL_LIMIT}}"
					type="text"
					[(ngModel)]="settings.labels[channelType]">
				<span class="p-8" *ngIf="settings.labels[channelType + 'AutoTitle']">
					{{'docExplorer.' + channelType + 'Label'|i18n}}
				</span>
			</div>
			<input name="{{channelType}}AutoTitle"
				id="{{channelType}}AutoTitle"
				type="checkbox"
				class="mt-0"
				[(ngModel)]="settings.labels[channelType + 'AutoTitle']"
				(ngModelChange)="toggleAutoTitle($event, channelType)">
			<label class="ml-8" for="{{channelType}}AutoTitle">{{'docExplorer.useAutoTitle'|i18n}}</label>
		</div>
	</div>
	<div class="form-group mb-32">
		<div class="d-flex align-items-center">
			<div class="d-flex flex-direction-column">
				<label class="d-flex">{{'appearance.participantLane'|i18n}}
					<cb-inline-help>
						<help-body>{{'appearance.participantLaneHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div class="d-flex align-items-center enrichment-option participant-option">
					<cb-toggle
						class="mr-8 lh-1"
						[(ngModel)]="settings.participantLane.enabled">
					</cb-toggle>
					<fieldset [disabled]="!settings.participantLane.enabled">
						<simple-dropdown
							class="metric-option"
							noMinWidth="true"
							[options]="participantOptions"
							[(value)]="settings.participantLane.definition.name"
							(onChange)="resetLaneProperties(settings.participantLane)">
						</simple-dropdown>
					</fieldset>
				</div>
			</div>
			<div class="d-flex flex-direction-column ml-16">
				<label class="d-flex">{{'appearance.displayColorLabel'|i18n}}
					<cb-inline-help>
						<help-body>{{'appearance.displayColorHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<fieldset [disabled]="!settings.participantLane.enabled">
					<color-band-selector
						[disabled]="!settings.participantLane.enabled"
						[metric]="getLaneMetric(settings.participantLane)"
						[(selectedValue)]="settings.participantLane.definition.bandColor">
					</color-band-selector>
				</fieldset>
			</div>
		</div>
	</div>

	<collapsing-panel>
		<panel-heading>
			<b>{{'appearance.lanes'|i18n}}</b>
		</panel-heading>
		<panel-body>
			<div class="d-flex align-items-center row-with-buttons">
				<h3 class="d-flex col-sm-4">{{'appearance.grouping'|i18n}}
					<cb-inline-help>
						<help-body>{{'appearance.singleLanesHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</h3>

				<label class="col-sm-4">{{'appearance.laneStyleLabel'|i18n}}</label>

				<label class="col-sm-4 d-flex">{{'appearance.displayColorLabel'|i18n}}
					<cb-inline-help>
						<help-body>{{'appearance.displayColorHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
			</div>
			<div cdkDropList
				[cdkDropListData]="settings.singleLanes"
				(cdkDropListDropped)="onDrop($event)">
				<div *ngFor="let lane of settings.singleLanes; let $index = index"
					class="lane-definition d-flex align-items-center mb-16 p-relative"
					cdkDrag
					[ngSwitch]="getLaneType(lane)">
					<ng-container *ngSwitchCase="'scorecard'">
						<cb-drag-handle class="p-absolute" cdkDragHandle *ngIf="isScorecardingEnabled()"></cb-drag-handle>
						<div class="enrichment-option col-sm-12 ml-24"
							*ngIf="isScorecardingEnabled()">
							<cb-toggle
								class="mr-8"
								[(ngModel)]="lane.enabled">
							</cb-toggle>
							<label>{{'mAccount.intelligentScoring' | i18n}}</label>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'model'">
						<cb-drag-handle class="p-absolute" cdkDragHandle></cb-drag-handle>
						<topic-spine-item class="enrichment-option row-with-buttons"
							[lane]="lane"
							[options]="singleLaneOptions"
							[projectSelection]="input?.settingsEntry"
							(groupingChange)="selectGrouping(lane, $event)">
						</topic-spine-item>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<cb-drag-handle class="p-absolute" cdkDragHandle></cb-drag-handle>
						<metric-attribute-spine-item class="enrichment-option row-with-buttons"
							[lane]="lane"
							[type]="getLaneType(lane)"
							[options]="singleLaneOptions"
							[projectSelection]="input?.settingsEntry"
							[predefinedMetrics]="predefinedMetrics"
							(groupingChange)="selectGrouping(lane, $event)">
						</metric-attribute-spine-item>
					</ng-container>
					<div class="" *ngIf="!isScorecardLane(lane)">
						<button	class="btn btn-icon"
							(click)="removeLane(lane)"
							title="{{'common.delete'|i18n}}">
							<span class="q-icon q-icon-minus" aria-hidden="true"></span>
						</button>
						<button	class="btn btn-icon"
							[disabled]="isLaneLimitReached()"
							(click)="addLane($index + 1)"
							title="{{(isLaneLimitReached() ? 'appearance.laneLimitReached' : 'common.add') | i18n}}">
							<span class="q-icon q-icon-add" aria-hidden="true"></span>
						</button>
					</div>
				</div>
			</div>
			<div class="d-flex justify-between col-sm-12" *ngIf="!hasCustomLanes()">
				<div ><i>{{'appearance.noLanes' | i18n}}</i></div>
				<button	class="btn btn-icon"
					(click)="addLane(0)"
					title="{{'common.add' | i18n}}">
					<span class="q-icon q-icon-add" aria-hidden="true"></span>
				</button>
			</div>
		</panel-body>
	</collapsing-panel>
</section>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
></save-modal-footer>
