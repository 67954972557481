export interface IConversionWidgetProperty {
	name: string;
	sourceName?: string;
	default?: any;
}
export default class ConversionWidgetProperty implements ConversionWidgetProperty {
	name: string;

	constructor(name: string) {
		this.name = name;
	}
}