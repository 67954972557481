import { NgModule } from '@angular/core';

import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '@app/shared/shared.module';
import { CustomMathEditorComponent } from '@app/modules/metric/definition/custom-math/editor/custom-math-editor.component';
import { MetricEditorComponent } from '@app/modules/metric/editor/metric-editor.component';
import { MetricDefinitionTabComponent } from '@app/modules/metric/editor/metric-definition-tab.component';
import { MetricFormatTabComponent } from '@app/modules/metric/editor/metric-format-tab.component';
import { MetricEditorDialogComponent } from '@app/modules/metric/editor/metric-editor-dialog.component';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { ValueMetricDefinitionComponent } from '@app/modules/metric/definition/value-metric-definition.component';
import { MathMetricDefinitionComponent } from '@app/modules/metric/definition/math-metric-definition.component';
import { TokenTypePipe } from '@app/modules/metric/definition/custom-math/editor/token-type.pipe';
import { FilterMetricDefinitionComponent } from '@app/modules/metric/definition/filter-metric-definition.component';
import { BoxMetricDefinitionComponent } from '@app/modules/metric/definition/box-metric-definition.component';
import { PredefinedMetricDefinitionComponent } from '@app/modules/metric/definition/predefined-metric-definition.component';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { CbColorGradeBarComponent } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { ColorGradeLanesComponent } from '@app/modules/metric/color-grades/color-grade-lanes.component';
import { ColorGradeLaneComponent } from '@app/modules/metric/color-grades/color-grade-lane.component';
import { CustomMathFunctionHelper } from './definition/custom-math/editor/custom-math-function-helper.service';
import { CustomMathButtonBarComponent } from '@app/modules/metric/definition/custom-math/editor/custom-math-button-bar/custom-math-button-bar.component';
import { CustomMathSearchListComponent } from './definition/custom-math/editor/custom-math-search-list/custom-math-search-list.component';
import { NumericAggregationsSubmenuComponent } from './definition/custom-math/editor/numeric-aggregations-submenu/numeric-aggregations-submenu.component';
import { DialogModule } from '../dialog/dialog.module';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { MetricDependencyTypesProvider } from './services/metric-dependency-types-provider';
import { MetricDependenciesApiService } from './services/metric-dependencies-api-service';
import { CustomMetricExpressionProvider } from '@app/modules/metric/definition/custom-math/custom-metric-expression.provider';
import { IconModule } from '../icon/icon.module';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
	imports: [
		SharedModule,
		AssetManagementModule,
		NgPipesModule,
		FilterBuilderModule,
		DialogModule,
		IconModule,
		ScrollingModule
	],
	exports: [
		CustomMathEditorComponent,
		CustomMathButtonBarComponent,
		TokenTypePipe
	],
	declarations: [
		CustomMathEditorComponent,
		CustomMathButtonBarComponent,
		CustomMathSearchListComponent,
		NumericAggregationsSubmenuComponent,
		FilterMetricDefinitionComponent,
		MathMetricDefinitionComponent,
		MetricEditorComponent,
		MetricEditorDialogComponent,
		MetricDefinitionTabComponent,
		MetricFormatTabComponent,
		ValueMetricDefinitionComponent,
		BoxMetricDefinitionComponent,
		PredefinedMetricDefinitionComponent,
		CbColorGradeBarComponent,
		ColorGradeLanesComponent,
		ColorGradeLaneComponent,
		TokenTypePipe
	],
	providers: [
		CustomMathFunctionHelper,
		MetricsService,
		ReportMetricsService,
		TokenTypePipe,
		MetricDependenciesApiService,
		MetricDependencyTypesProvider,
		CustomMetricExpressionProvider,
	]
})
export class MetricModule {}
