<div *ngIf="pagination.getTotalPages() > 1" class="pagination-container d-flex align-items-center"
	[class.remove-boundary]="isRemoveBoundary()"
	[class.disabled-last]="isPageLimitReached()">
	<ngb-pagination
		[(page)]="pagination.currentPage"
		(pageChange)="pageChanged()"
		[pageSize]="pagination.pageSize"
		[collectionSize]="getCollectionSize()"
		[maxSize]="compact ? 1 : 5"
		[ellipses]="!compact"
		[boundaryLinks]="!compact"
		[ngClass]="{'pull-right': pullRight}">
		<ng-template ngbPaginationFirst><span class="q-icon-triangle-start pagination-first text-1_25rem"></span></ng-template>
		<ng-template ngbPaginationPrevious><span class="q-icon-angle-left pagination-previous"></span></ng-template>
		<ng-template ngbPaginationNext><span class="q-icon-angle-right pagination-next"></span></ng-template>
		<ng-template ngbPaginationLast><span class="q-icon-triangle-end pagination-last 1_25rem"></span></ng-template>
		<ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
	</ngb-pagination>
	<pagination-custom-last-button *ngIf="isPageLimitReached()"></pagination-custom-last-button>
</div>
