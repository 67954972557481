import { Inject, Injectable } from '@angular/core';
import { LinkSharingSettings } from '@cxstudio/master-accounts/settings-components/link-sharing-settings';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Caches } from '@cxstudio/common/caches';

@Injectable()
export class MasterAccountLinkSharingService {
	constructor(
		@Inject('$http') private readonly $http: ng.IHttpService,
		@Inject('httpHandlers') private readonly httpHandlers: HttpHandlers,
	) {
	}

	saveLinkSharingSettingsDowngrade = (linkSharingSettings: LinkSharingSettings): Promise<void> => {
		return this.processResponse(this.$http.put(`rest/master_account/link_sharing_settings`, linkSharingSettings))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.PERMISSIONS));
	}


	private processResponse(httpPromise: ng.IHttpPromise<any>): Promise<any> {
			return Promise.resolve(httpPromise.then(this.httpHandlers.success));
	}

}
app.service('masterAccountLinkSharingService', downgradeInjectable(MasterAccountLinkSharingService));
