import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { GlobalOtherExplorerOptions } from '@app/modules/project/global-other-explorer-modal/global-other-explorer-modal.component';

@Directive({
	selector: 'global-other-explorer'
})
export class GlobalOtherExplorerUpgradeDirective extends UpgradeComponent {
	@Input() resolve: GlobalOtherExplorerOptions;
	@Output() dismiss: EventEmitter<void>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('globalOtherExplorer', elementRef, injector);
	}
}
