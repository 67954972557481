import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HighchartsDualDefinitionService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/highcharts-dual-definition.service';
import { HighchartsRendererService } from '@app/modules/widget-visualizations/highcharts/highcharts-renderer.service';
import { HighchartsSingleVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-single-visualization.class';
import { VisualizationDataService } from '@app/modules/widget-visualizations/visualization-data.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ChartValidator } from '@cxstudio/reports/visualizations/chart-validator.service';

@Component({
	selector: 'highcharts-dual',
	template: `
	<figure [hidden]="hasError()" #chartContainer class="w-100-percent h-100-percent border-radius-widget-default"></figure>
	<widget-error *ngIf="hasError()" [widgetError]="widgetError" [demo]="demo"></widget-error>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighchartsDualComponent extends HighchartsSingleVisualization
	implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('chartContainer') chartContainer: ElementRef;

	errorMessage: string;
	chartOptions: Highcharts.Options;

	constructor(
		ref: ChangeDetectorRef,
		private visualizationDataService: VisualizationDataService,
		private highchartsDualDefinition: HighchartsDualDefinitionService,
		private highchartsRenderer: HighchartsRendererService,
		@Inject('reportUtils') reportUtils: ReportUtils,
		@Inject('chartValidator') private chartValidator: ChartValidator,
	) {
		super(ref, reportUtils);
	}

	ngOnInit() {
		this.prepareChartOptions();
	}

	ngAfterViewInit(): void {
		this.initResizeHandler(this.chartContainer);
		this.renderChart();
	}

	private prepareChartOptions(): void {
		if (this.checkError(this.visualizationDataService.validateData(this.dataObject, this.widget.name)))
			return;
		let chartOptions = this.highchartsDualDefinition.getChartOptions(this.dataObject, this.widget.visualProperties,
			this.utils, this, this.demo);

		let validationMessage = this.chartValidator.getValidationMessage(chartOptions.series as any,
			this.dataObject.data, this.widget.visualProperties);
		if (this.checkError(validationMessage))
			return;
		this.chartOptions = chartOptions;
	}

	private renderChart() {
		this.clearChart();
		if (this.chartOptions) {
			this.chart = this.highchartsRenderer
				.renderChart(this.chartOptions as unknown, this.chartContainer.nativeElement,
					this.highchartsRenderer.pointHighlightingCallback(this.utils, this.demo)) as unknown as Highcharts.Chart;
		}
	}

}
