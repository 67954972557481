import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from '@app/shared/providers/scope-provider';

@Directive({
	selector: 'mobile-dashboard-list'
})

export class MobileDashboardListUpgrade extends UpgradeComponent {
	@Input() dashboards;
	@Input() changeDashboard;
	@Input() isCurrentUserSelected;
	@Input() dashboardBurgerClick;
	@Input() showDashboardRating;
	@Input() search;
	@Input() changeFavoriteForDashboard;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('mobileDashboardList', elementRef, injector);
	}
}
