<div class="ph-32 pv-16">
	<collapsing-panel [disableCollapse]="true" [collapsed]="false">
		<panel-heading>
			<b>{{'alert.notificationStepHeading'|i18n}}</b>
		</panel-heading>
		<panel-body>
			<alert-notification
				[alert]="alert"
				[ui]="errorContainer"
				[createCase]="this.alert.caseSettings?.createCase"
				(testAlert)="testAlert.emit()"
				(validityChange)="validateAndPopulateErrors($event.$valid)">
			</alert-notification>
		</panel-body>
	</collapsing-panel>
</div>
