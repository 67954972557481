import { MetricType } from '@app/modules/metric/entities/metric-type';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { Alignment } from '@cxstudio/reports/formatting/alignment.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { QualtricsColors } from '@clarabridge/unified-ui/src/utilities/typescript/qualtrics-colors.enum';

export const CATALOG_METRICS = [
	{
		displayName: 'PS test - top box',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.TOP_BOX,
			min: 0,
			max: 100,
			topThreshold: 50,
			topColor: '#007338',
			otherColor: QualtricsColors.GRAY_600,
			topDisplayName: 'Promoters',
			otherDisplayName: 'Other',
			thresholds: [],
			colorPalette: [],
			calculation: {
				thresholds: [
					0.66
				],
				colorPalette: [
					QualtricsColors.GRAY_600,
					'#007338'
				],
				min: 0,
				max: 1,
			},
			name:  ClarabridgeAttributeName.CB_LOYALTY_TENURE
		},
		format: {
			suffix: '%',
			decimals: 1,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT ,
			conversion: MetricMultiplierType.TIMES_HUNDRED,
		}
	},
	{
		displayName: 'PS test - satisfaction',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.SATISFACTION,
			min: 0,
			max: 100,
			bottomThreshold: 10,
			topThreshold: 30,
			topColor: '#007338',
			bottomColor: '#b91b1f',
			middleColor: QualtricsColors.GRAY_600,
			topDisplayName: 'Promoters',
			bottomDisplayName: 'Detractors',
			middleDisplayName: 'Neutrals',
			calculation: {
				thresholds: [
					-0.33,
					0.33
				],
				colorPalette: [
					'#b91b1f',
					QualtricsColors.GRAY_600,
					'#007338'
				],
				min: -1,
				max: 1
			},
			name: ClarabridgeAttributeName.CB_LOYALTY_TENURE
		},
		format: {
			decimals: 0,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT ,
			conversion: MetricMultiplierType.TIMES_HUNDRED,
		},
	},
	{
		displayName: 'PS test - bottom box',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.BOTTOM_BOX, //'bb',
			min: 0,
			max: 100,
			bottomThreshold: 50,
			bottomColor: '#b91b1f',
			otherColor: QualtricsColors.GRAY_600,
			bottomDisplayName: 'Detractors',
			otherDisplayName: 'Other',
			calculation: {
				thresholds: [
					0.66
				],
				colorPalette: [
					QualtricsColors.GRAY_600,
					'#b91b1f'
				],
				min: 0,
				max: 1
			},
			name:  ClarabridgeAttributeName.CB_LOYALTY_TENURE
		},
		format: {
			suffix: '%',
			decimals: 1,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT ,
			conversion: MetricMultiplierType.TIMES_HUNDRED,
			customFormatting: true
		}
	},
	{
		displayName: 'PS test - filtered',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.FILTER ,
			rule: {
				type: FilterTypes.AND,
				filterRules: [
					{
						type: FilterRuleType.stringEquality,
						attributeName: ClarabridgeAttributeName.DETECTED_LANGUAGE,
						displayName: 'CB Auto-detected Language',
						matchMode: 'IS',
						values: [
							{
								text: 'english'
							}
						]
					}
				]
			},
			calculation: {
				thresholds: [
					0.66
				],
				colorPalette: [
					QualtricsColors.GRAY_600,
					'#007338'
				],
				min: 0,
				max: 1
			},
			calculationName: 'volume',
			calculationOperator: 'sys',
			aggrLevel: 'group'
		},
		format: {
			decimals: 0,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			conversion: MetricMultiplierType.NONE,
			customFormatting: true
		},
	},
	{
		displayName: 'TestValue',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.VARIABLE,
			defaultValue: 100
		},
		format: {
			decimals: 1,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			calculationType: CalculationFunction.AVG,
			conversion: MetricMultiplierType.NONE,
			customFormatting: true
		}
	},
	{
		displayName: 'TestCustomExpression',
		labels: [],
		parentId: 0,
		definition: {
			type: MetricType.CUSTOM_MATH,
			mathComponents: [
				{
					type: 'system',
					displayName: '[Sentiment]',
					name: SuggestionType.SENTIMENT
				},
				{
					type: 'symbol',
					displayName: 'X',
					value: '*'
				},
				{
					type: 'system',
					displayName: '[Effort]',
					name: SuggestionType.EFFORT
				},
				{
					type: 'symbol',
					displayName: 'X',
					value: '*'
				},
				{
					type: 'system',
					displayName: '[Emotional Intensity]',
					name: SuggestionType.EMOTION
				}
			],
			replaceNullsWithZeroes: false,
			calculation: {
				thresholds: [
					0.66
				],
				colorPalette: [
					QualtricsColors.GRAY_600,
					'#007338'
				],
				min: 0,
				max: 1
			}
		},
		format: {
			decimals: 1,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			calculationType: CalculationFunction.AVG,
			conversion: MetricMultiplierType.NONE,
			customFormatting: true
		}
	}
];
