import * as _ from 'underscore';
import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';


export function duplicateNameValidator(duplicateCheckPropertyNamePrefix: string): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {

		if (!duplicateCheckPropertyNamePrefix) {
			return null;
		}

		let allPropertyNames: string[] = [];
		Object.keys(control.controls).forEach((controlName: string) => {
			if (controlName.startsWith(duplicateCheckPropertyNamePrefix)) {
				// not checking for null or empty values
				if (!!control.controls[controlName].value) {
					allPropertyNames.push(control.controls[controlName].value);
				}
			}
		});

		let duplicates = allPropertyNames.filter((name: string, index: number) => allPropertyNames.indexOf(name) !== index);
		return duplicates.length > 0 ? { duplicateName: { value: duplicates.join(', ') } } : null;
	};
}