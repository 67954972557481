import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';

export interface DashboardComponents {
	filters: any[];
	metrics: any[];
}

@Injectable()
export class DashboardComponentsSharingUtil {

	constructor(
		private dashboardComponentsApiService: DashboardComponentsApiService,
	) {}

	getSharableDashboardComponents = (dashboardWithProject: ProjectAsset): Promise<DashboardComponents> => {
		return this.dashboardComponentsApiService.getSharableComponents(dashboardWithProject);
	}
}

app.service('dashboardComponentsSharingUtil', downgradeInjectable(DashboardComponentsSharingUtil));
