import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter,
	ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IColorGradeLaneItem } from './color-grade-lane-item';
import { IColorGrades, IColorGradeOptions } from './cb-color-grade-bar.component';
import { ThresholdRule } from '@cxstudio/reports/utils/color/metric-threshold-rules.service';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

@Component({
	selector: 'color-grade-lanes',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div [ngClass]="{'show-buttons': showButtons}">
			<div class="d-flex justify-between color-lane-header">
				<label class="font-bold name-label">{{'common.name'|i18n}}</label>
				<label class="font-bold threshold-label">{{'metrics.threshold'|i18n}}</label>
				<label class="font-bold color-label">{{'common.color'|i18n}}</label>
			</div>
			<div *ngFor="let lane of lanes; index as $index; first as isFirst; last as isLast"
				class="lane-container d-flex align-items-center justify-between flex-nowrap">

				<color-grade-lane
					class="w-max-100-percent flex-fill"
					[laneItem]="lane"
					[isFirst]="isFirst"
					[isLast]="isLast"
					[options]="options"
					[format]="format"
					(laneItemChange)="onLaneChange($index, $event)">
				</color-grade-lane>

				<div *ngIf="showButtons" class="ml-16 flex-fixed">
					<button
						type="button"
						class="btn btn-secondary btn-link btn-icon"
						[disabled]="!canRemoveLane(isLast)"
						(click)="removeExistingLane($index)">
						<span class="q-icon q-icon-minus" attr.aria-label="{{'common.remove'|i18n}}"></span>
					</button>
					<button
						type="button"
						class="btn btn-secondary btn-link btn-icon ml-0"
						[disabled]="!canAddLane()"
						(click)="addNewLane($index)">
						<span class="q-icon q-icon-add" attr.aria-label="{{'common.add'|i18n}}"></span>
					</button>
				</div>
			</div>
			<alert type="danger" *ngIf="hasThreholdError()">{{'metrics.thresholdInvalid'|i18n}}</alert>
		</div>
	`
})
export class ColorGradeLanesComponent implements OnInit, OnChanges, OnDestroy {

	@Input() validateOnInit: boolean;
	@Input() grades: IColorGrades;
	@Input() min: number;
	@Input() max: number;
	@Input() options: IColorGradeOptions;
	@Input() format: NumberFormatSettings;
	@Input() gradesUpdate: Observable<void>;

	@Output() laneChange = new EventEmitter<{index: number, lane: IColorGradeLaneItem}>();
	@Output() addLane = new EventEmitter<number>();
	@Output() removeLane = new EventEmitter<number>();
	@Output() validityChange = new EventEmitter<boolean>();
	@Output() colorChange = new EventEmitter<void>();

	lanes: IColorGradeLaneItem[] = [];
	showButtons: boolean;

	private gradesUpdateSubscription$: Subscription;

	constructor() {}

	ngOnInit(): void {
		this.showButtons = this.options.areGradesEditable && this.options.canAddRemoveGrades;

		this.updateLanes();

		this.gradesUpdateSubscription$ = this.gradesUpdate.subscribe(this.updateLanes);

		if (this.validateOnInit) {
			this.validateLanes();
		}
	}

	ngOnDestroy(): void {
		this.gradesUpdateSubscription$?.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.grades) {
			this.updateLanes();
		}
	}

	addNewLane(colorIndex: number): void {
		this.addLane.emit(colorIndex);
		this.validateLanes();
	}

	removeExistingLane(colorIndex: number): void {
		this.removeLane.emit(colorIndex);
		this.validateLanes();
	}

	updateLanes = (): void => {
		this.lanes = this.getLanes();
	}

	private getLanes = (): IColorGradeLaneItem[] => {
		return _.map(this.grades.colorPalette, (color, idx) => {
			let lane = {} as IColorGradeLaneItem;
			if (idx === 0) {
				lane.leftThreshold = this.min;
				lane.rightThreshold = this.grades.thresholds[idx];
				lane.leftThresholdRule = ThresholdRule.RIGHT_INCLUSIVE;
				lane.rightThresholdRule = this.grades.thresholdRules[idx];
			} else if (idx === this.grades.colorPalette.length - 1) {
				lane.leftThreshold = this.grades.thresholds[idx - 1];
				lane.rightThreshold = this.max;
				lane.leftThresholdRule = this.grades.thresholdRules[idx - 1];
				lane.rightThresholdRule = ThresholdRule.LEFT_INCLUSIVE;
			} else {
				lane.leftThreshold = this.grades.thresholds[idx - 1];
				lane.rightThreshold = this.grades.thresholds[idx];
				lane.leftThresholdRule = this.grades.thresholdRules[idx - 1];
				lane.rightThresholdRule = this.grades.thresholdRules[idx];
			}
			lane.name = this.grades.displayNames?.[idx];
			lane.defaultName = this.grades.defaultNames?.[idx];
			lane.color = color;
			return lane;
		});
	}

	canRemoveLane = (isLast: boolean): boolean => {
		return !isLast && this.lanes.length > this.options.minGrades;
	}

	canAddLane = (): boolean => {
		return this.lanes.length < this.options.maxGrades && !this.hasInvalidThresholds();
	}

	private hasInvalidThresholds(): boolean {
		return !!_.findWhere(this.lanes, {rightThresholdInvalid: true}) || !!_.findWhere(this.lanes, {leftThresholdInvalid: true});
	}

	onLaneChange = (changedLaneIndex: number, changedLane: IColorGradeLaneItem): void => {
		if (this.thresholdsChanged(changedLaneIndex, changedLane)) {
			this.lanes[changedLaneIndex] = changedLane;
			this.updateThresholds();
			this.validateLanes();
		} else if (this.colorChanged(changedLaneIndex, changedLane)) {
			this.grades.colorPalette[changedLaneIndex] = changedLane.color;
			this.colorChange.emit();
		} else if (this.nameChanged(changedLaneIndex, changedLane)) {
			this.validateLanes();
		}
	}

	nameChanged(chandeLaneIndex: number, changedLane: IColorGradeLaneItem): boolean {
		return this.grades.displayNames?.[chandeLaneIndex] !== changedLane.name;
	}

	colorChanged(changedLaneIndex: number, changedlane: IColorGradeLaneItem): boolean {
		return this.grades.colorPalette[changedLaneIndex] !== changedlane.color;
	}

	thresholdsChanged(index: number, changedlane: IColorGradeLaneItem): boolean {
		return (this.hasNextLane(index) && changedlane.rightThreshold !== this.lanes[index + 1].leftThreshold)
			|| (this.hasPreviousLane(index) && changedlane.leftThreshold !== this.lanes[index - 1].rightThreshold);
	}

	private updateThresholds = (): void => {
		_.each(this.lanes, (lane, index) => {
			this.updateThreshold(index, lane);
		});
	}

	private validateLanes = (): void => {
		_.each(this.lanes, (lane, index) => {
			this.validateLane(index, lane);
		});
	}

	private updateThreshold = (index: number, lane: IColorGradeLaneItem): void => {
		this.updateRightThreshold(index, lane);
		this.updateLeftThreshold(index, lane);
	}

	private updateRightThreshold = (index: number, lane: IColorGradeLaneItem): void => {
		if (index !== 0 && lane.leftThresholdRule === ThresholdRule.RIGHT_INCLUSIVE) {
			this.lanes[index - 1].rightThreshold = lane.leftThreshold;
		}
	}

	private updateLeftThreshold = (index: number, lane: IColorGradeLaneItem): void => {
		if (this.hasNextLane(index) && lane.rightThresholdRule === ThresholdRule.LEFT_INCLUSIVE) {
			this.lanes[index + 1].leftThreshold = lane.rightThreshold;
		}
	}

	private validateLane = (index: number, lane: IColorGradeLaneItem): void => {
		// left threshold validation
		if (index !== 0 && lane.leftThresholdRule === ThresholdRule.RIGHT_INCLUSIVE) {
			if (lane.leftThreshold < this.lanes[index - 1].leftThreshold
				|| lane.leftThreshold > lane.rightThreshold) {
				this.lanes[index].leftThresholdInvalid = true;
				this.validityChange.emit(!this.hasThreholdError());
				return;
			}
			this.lanes[index].leftThresholdInvalid = false;
		}

		// right threshold validation
		if (this.hasNextLane(index) && lane.rightThresholdRule === ThresholdRule.LEFT_INCLUSIVE) {
			if (lane.rightThreshold >= this.lanes[index + 1].rightThreshold
				|| lane.rightThreshold <= lane.leftThreshold) {
				this.lanes[index].rightThresholdInvalid = true;
				this.validityChange.emit(!this.hasThreholdError());
				return;
			}
			this.lanes[index].rightThresholdInvalid = false;
		}

		// send no errors callback
		if (this.isLaneThresholdsValid(this.lanes[index])) {
			this.laneChange.emit({index, lane});
			this.validityChange.emit(!this.hasThreholdError());
		}
	}

	private hasNextLane = (index: number): boolean => {
		return index !== this.lanes.length - 1;
	}

	private hasPreviousLane = (index: number): boolean => {
		return index !== 0;
	}

	hasThreholdError = (): boolean => _.any(this.lanes, lane => !this.isLaneThresholdsValid(lane));

	private isLaneThresholdsValid = (lane: IColorGradeLaneItem): boolean => !lane.leftThresholdInvalid && !lane.rightThresholdInvalid;
}
