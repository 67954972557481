import { HighchartsCase } from './highcharts-case';

export class HighchartsCaseBucket {
	cases: HighchartsCase[];

	constructor() {
		this.cases = [];
	}

	public getBucketStartDate(): Date {
		return _.first(this.cases).startDate;
	}

	public getBucketEndDate(): Date {
		return _.last(this.cases).endDate;
	}
}