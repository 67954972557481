import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'document-explorer-full-page-toggle',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<button class="btn btn-secondary btn-link">
	<span class="q-icon q-icon-maximise mr-8" aria-hidden="true"></span>{{'docExplorer.goFullPage'|i18n}}
</button>`
})
export class DocumentExplorerFullPageToggleComponent { }
