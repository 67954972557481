export enum MetricsStatus {
	OK = 'OK',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED'
}

export interface MetricsState<K, T> {
	status: MetricsStatus;
	nodeId: string;
	timestamp: string;
	type: string;
	key: K;
	currentMetrics: T;
	previousMetrics: T;
}