enum PlotLineType {
	// from highcharts api
	NoLine = 'NoLine',
	Solid = 'Solid',
	ShortDash = 'ShortDash',
	ShortDot = 'ShortDot',
	ShortDashDot = 'ShortDashDot',
	ShortDashDotDot = 'ShortDashDotDot',
	Dot = 'Dot',
	Dash = 'Dash',
	LongDash = 'LongDash',
	DashDot = 'DashDot',
	LongDashDot = 'LongDashDot',
	LongDashDotDot = 'LongDashDotDot'
}

export default PlotLineType;
