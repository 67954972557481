import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@cxstudio/services/date-service.service';
// converts seconds count to mm:ss
// ex. 100.5 -> 1:40, 601 -> 10:01 
@Pipe({
	name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
	
	constructor(@Inject('dateService') private dateService: DateService) {}

	transform(seconds: number): string {
		return this.dateService.secondsToTime(seconds);
	}
}