import { WidgetCategory } from '@app/modules/widget-settings/widget-category.enum';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';

export class AmplitudeWidgetEventData {
	widgetCategory: WidgetCategory;
	widgetType: WidgetType;
	dashboardId: number | string;
	pxSize?: {
		height: number;
		width: number;
	};
	size: {
		height: number;
		width: number;
	};
	containerId: string;
	intelligentScoringPane?: 'Enabled' | 'Disabled';

	constructor(widget: Widget, widgetElement?: HTMLElement) {
		return {
			widgetCategory: AmplitudeWidgetEventData.getWidgetCategory(widget),
			widgetType: widget.properties.widgetType,
			dashboardId: widget.dashboardId,
			containerId: widget.containerId,
			size: {
				height: widget.height,
				width: widget.width
			},
			pxSize: !widgetElement ?
				undefined :
				{
					height: widgetElement.offsetHeight,
					width: widgetElement.offsetWidth,
				},
			intelligentScoringPane: AmplitudeWidgetEventData.hasIntelligentScoringPane(widget)
		};
	}

	static getWidgetCategory(widget: Widget): WidgetCategory {
		const dataWidgets = [
			WidgetType.LINE,
			WidgetType.BAR,
			WidgetType.METRIC,
			WidgetType.HEATMAP,
			WidgetType.CLOUD,
			WidgetType.PIE,
			WidgetType.TABLE,
			WidgetType.SCATTER,
			WidgetType.DOCUMENT_EXPLORER,
			WidgetType.PREVIEW,
			WidgetType.PREVIEW_EXPORT,
			WidgetType.ATTR_STATS,
			WidgetType.SCORECARD,
			WidgetType.MAP,
			WidgetType.NETWORK,
			WidgetType.OBJECT_VIEWER,
			WidgetType.MODEL_VIEWER,
			WidgetType.QUALTRICS,
		];

		return dataWidgets.contains(widget.properties.widgetType) ?
			WidgetCategory.DATA :
			WidgetCategory.CONTENT;
	}

	static hasIntelligentScoringPane(widget: Widget): 'Enabled' | 'Disabled' {
		const ENABLED = 'Enabled';
		const DISABLED = 'Disabled';

		if (widget?.properties?.widgetType === WidgetType.PREVIEW &&
				widget.visualProperties?.contextPaneEnabled &&
				widget.properties.previewMode === PreviewMode.DOCUMENT) {
			if (widget.visualProperties.sections?.[FavoriteType.SCORECARDS]?.visible !== false) {
				return ENABLED;		// intelligent scoring pane is enabled
			} else {
				return DISABLED;	// intelligent scoring pane is relevant to widget config, but disabled
			}
		}
		return undefined;	// intelligent scoring pane is irrelevant due to widget type/configuration
	}
}



