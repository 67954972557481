<div class="d-flex flex-singleline justify-between">
	<div *ngIf="homePage.default">
		<p [i18n]="'homePage.defaultHomePageEditTitle'" class="font-bold"></p>
		<p [i18n]="'homePage.defaultHomePageEditDescription'"></p>
	</div>
	<div *ngIf="!homePage.default">
		<p [i18n]="'homePage.homePageEditTitle'" [i18nParams]="{name: originalHomePage.name}" class="font-bold"></p>
		<p [i18n]="'homePage.homePageEditDescription'"></p>
	</div>

	<div class="grid-buttons">
		<button
			class="btn btn-primary"
			[disabled]="!hasChanges()"
			(click)="save()">
			{{'common.save'|i18n}}
		</button>
		<button
			class="btn btn-secondary"
			(click)="cancel()">
			{{'common.cancel'|i18n}}
		</button>
	</div>
</div>
<home-general-settings [homePage]="homePage" [saveListener]="saveListener"></home-general-settings>
<home-quick-insights-settings
	[homePage]="homePage"></home-quick-insights-settings>
<home-page-widgets-config
	[homePage]="homePage"
	[reportProperties]="homePage.reportProperties"
	(onChange)="widgetsChanged($event)"></home-page-widgets-config>
<home-dashboard-carousel [carousel]="homePage.carousel"></home-dashboard-carousel>
<div class="d-flex flex-singleline justify-end">
	<div class="grid-buttons">
		<button
			class="btn btn-primary"
			[disabled]="!hasChanges()"
			(click)="save()">
			{{'common.save'|i18n}}
		</button>
		<button
			class="btn btn-secondary"
			(click)="cancel()">
			{{'common.cancel'|i18n}}
		</button>
	</div>
</div>
