<div class="flex-fill d-flex flex-direction-column mh-24 mb-24 overflow-hidden">
	<collapsing-panel [disableCollapse]="true">
		<panel-heading>
			<span class="font-bold">{{'dashboard.qualtricsDashboardEmbedding'|i18n}}</span>
		</panel-heading>
		<panel-body>
			<form class="form-horizontal" name="embedDashboardForm" #embedDashboardForm="ngForm">
				<label for="qualtrics-dashboard-id">
					<strong>{{ 'dashboard.xmEngageEmbedId'|i18n}}</strong>
				</label>
				<div>{{ 'dashboard.xmEngageEmbedIdSubtitle' | i18n }}:</div>
				<input id="qualtrics-dashboard-id"
					class="form-control"
					[disabled]="viewState === 'modifyState'"
					type="text"
					required
					name="dashboardId"
					#dashboardId="ngModel"
					pattern="[a-zA-Z0-9]+"
					[(ngModel)]="embedDashboardId">
				<form-error *ngIf="dashboardId.errors?.pattern"
					text="{{'dashboard.qualtricsEmbedIdInvalid'|i18n}}">
				</form-error>
				<div class="d-flex align-items-center pull-right mt-16">
					<button
						*ngIf="viewState === 'modifyState'"
						type="button"
						class="btn btn-primary btn-ok"
						(click)="modifyDashboardId()">
						{{"common.modify"|i18n}}
					</button>
					<button
						*ngIf="viewState === 'saveCancelState'"
						type="button"
						class="btn btn-primary btn-ok mr-8"
						[disabled]="!embedDashboardId || embedDashboardForm?.invalid"
						(click)="saveXMDashboardId()">
						{{"common.ok"|i18n}}
					</button>
					<button
						*ngIf="(viewState === 'saveCancelState') && initialEmbedDashboardId"
						type="button"
						class="btn btn-secondary btn-ok"
						(click)="cancelDashboardId()">
						{{"common.cancel"|i18n}}
					</button>
				</div>
			</form>
		</panel-body>
	</collapsing-panel>
</div>
