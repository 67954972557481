import { OnInit, Component, OnChanges, AfterViewInit, ViewEncapsulation, ChangeDetectorRef,
	ElementRef, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { DocExplorerPreferences, DocExplorerPreferencesProvider } from '../../preferences/doc-explorer-preferences.provider';
import { ContextPaneSubpanels } from '../context-pane-subpanels.enum';
import { ContextPaneComponent } from '../context-pane.component';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { CxLocaleService } from '@app/core';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { DocViewPreferences } from '../../preferences/doc-view-preferences.class';
import { AmplitudeDocumentType } from '../../document-type-utils.class';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'tabbed-context-pane',
	templateUrl: './tabbed-context-pane.component.html',
	providers: [DocExplorerPreferencesProvider],
	encapsulation: ViewEncapsulation.None,
	styles: [`
		tabbed-context-pane {
			border-bottom-right-radius: var(--widget-border-radius);
		}
		tabbed-context-pane clearable-input input {
			height: 2rem !important;
		}
		tabbed-context-pane .scroll-carousel-button {
			height: 3.125rem;
			display: flex;
			align-items: center;
		}

		.h-3_125 {
			height: 3.125rem !important;
		}`]
	// using onpush breaks panel selection. Refactor to use NgRx and then we can enable onpush
})
export class TabbedContextPaneComponent extends ContextPaneComponent implements OnInit, OnChanges, AfterViewInit {
	activeTabId: ContextPaneSubpanels;
	subpanels = ContextPaneSubpanels;
	showTopicFilters: boolean;
	documentType: AmplitudeDocumentType;

	scrollLabels = {
		previous: this.locale.getString('common.previous'),
		next: this.locale.getString('common.next')
	};

	// @input from parent
	preferences: DocExplorerPreferences;

	constructor(
		@Inject('enrichmentAttributesService') protected readonly enrichmentAttributesService: EnrichmentAttributesService,
		@Inject('engagorService') protected readonly engagorService: EngagorService,
		@Inject(DocViewPreferences) protected readonly docExplorerPreferencesProvider,
		@Inject('security') protected readonly security: Security,
		protected readonly betaFeaturesService: BetaFeaturesService,
		protected readonly ref: ChangeDetectorRef,
		protected readonly locale: CxLocaleService,
		protected readonly el: ElementRef,
	) {
		super(enrichmentAttributesService, engagorService, docExplorerPreferencesProvider, security, betaFeaturesService, ref, locale, el);
	}

	ngOnInit(): void {
		this.activeTabId = this.preferences.settings.paneCollapsedState.currentTab as ContextPaneSubpanels;
		this.showTopicFilters = false;

		super.ngOnInit();
	}

	ngAfterViewInit(): void {
		this.ensureTabVisible(this.activeTabId);
		this.showWASearch = true;
	}

	ngOnChanges(changes: SimpleChanges<TabbedContextPaneComponent>): void {
		if (ChangeUtils.hasAnyChange(changes.activeTabId)) {
			let tabId = changes.activeTabId ?
				changes.activeTabId.currentValue :
				this.activeTabId;

			if (tabId) {
				this.ensureTabVisible(tabId);
			}
		}

		if (changes.loading && ChangeUtils.hasAnyChange(changes.loading)) {
			Promise.all(this.loading).then(() => {
				// in case key info has been added...
				this.ensureTabVisible(this.activeTabId);
			});
		}

		if (ChangeUtils.hasAnyChange(changes.document)) {
			this.documentType = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.isDocExplorer).documentType;
		}
	}

	onTabSelection(event: PointerEvent): void {
		// don't do anything if they click something that is not a tab
		if ((event.target as HTMLElement).classList.contains('nav-link')) {
			this.ensureTabVisible(this.activeTabId);
			this.updateLastTab();

			const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.isDocExplorer);

			AmplitudeAnalyticsService.trackEvent(
				AmplitudeEvent.DOCEXPLORER_CONTEXT_PANE_TAB_NAVIGATE,
				{ documentId },
				{
					tab: AmplitudeEventUtils.getAmplitudeTabName(this.activeTabId),
					documentType,
					source
				}
			);
		}
	}

	updateLastTab(): void {
		this.preferences.settings.paneCollapsedState.currentTab = this.activeTabId;
		this.preferences.storeUpdates();
	}

	private ensureTabVisible(tabId: string): void {
		const buffer = 10; // small buffer to force scroll if tab is fully visible but just barely

		if (!tabId) {
			tabId = this.getFirstVisibleTab();

			if (!tabId) {
				return;
			}
		}

		let tab = $(`#${tabId}`);
		if (!tab.length) {
			return;
		}

		let leftTabBoundary = tab.offset().left;
		let rightTabBoundary = leftTabBoundary + tab.width();

		let carousel = $('tabbed-context-pane .scroll-carousel-target');
		let carouselLeftBoundary = carousel.offset().left;
		let carouselRightBoundary = carouselLeftBoundary + carousel.width();

		let scrollAdjustment;

		if (rightTabBoundary > (carouselRightBoundary - buffer)) {
			scrollAdjustment = carousel.scrollLeft() + (rightTabBoundary - carouselRightBoundary) + buffer;
		} else if (leftTabBoundary < (carouselLeftBoundary + buffer)) {
			scrollAdjustment = carousel.scrollLeft() + (leftTabBoundary - (carouselLeftBoundary + buffer));
		}

		if (scrollAdjustment) {
			carousel.animate({scrollLeft: scrollAdjustment}, 200);
		}
	}

	private getFirstVisibleTab(): string {
		const orderedPanels = [
			{ key: ContextPaneSubpanels.KEY_INFO, visible: this.showKeyInfoPanel },
			{ key: ContextPaneSubpanels.NARRATIVES, visible: this.isNarrativePanelVisible },
			{ key: ContextPaneSubpanels.INBOX, visible: this.isEngagorPanelVisible },
			{ key: ContextPaneSubpanels.SCORECARDS, visible: this.isScorecardPanelVisible },
			{ key: ContextPaneSubpanels.ATTRIBUTES, visible: this.isAttributesPanelVisible },
			{ key: ContextPaneSubpanels.ENRICHMENTS, visible: this.isEnrichmentsPanelVisible },
			{ key: ContextPaneSubpanels.TOPICS, visible: this.isTopicsPanelVisible },
			{ key: ContextPaneSubpanels.AUTOMATED_SUMMARIES, visible: this.isAutomatedSummariesPanelVisible },
			{ key: ContextPaneSubpanels.COMPLIANCE_MANAGEMENT, visible: this.isComplianceManagementPanelVisible },
		];
		let firstVisible = orderedPanels.find(p => p.visible());

		return firstVisible ?
			firstVisible.key :
			undefined;
	}

}

app.directive('tabbedContextPane', downgradeComponent({component: TabbedContextPaneComponent}));
