
import { NgModule } from '@angular/core';
import { GridsterModule } from '@app/modules/gridster/gridster.module';
import { ContentWidgetComponent } from '@app/modules/widget-container/simple-widgets/content-widget/content-widget.component';
import { ExternalWidgetComponent } from '@app/modules/widget-container/simple-widgets/external-widget/external-widget.component';
import { PageBreakWidgetComponent } from '@app/modules/widget-container/simple-widgets/page-break-widget/page-break-widget.component';
import { WidgetContainerUpgradeDirective } from '@app/modules/widget-container/widget-container.upgrade.directive';
import { ReportExportUtilsService } from '@app/modules/widget-container/widget-menu/export/report-export-utils.service';
import { WidgetVisualizationsModule } from '@app/modules/widget-visualizations/widget-visualizations.module';
import { MenuDirectivesModule } from '@app/shared/menu/menu-directives.module';
import { SharedModule } from '@app/shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { ChartAccessibilityService } from './chart-accessibility.service';
import { DashboardFiltersSectionComponent } from './dashboard-filters-section/dashboard-filters-section.component';
import { DrillBreadcrumbsComponent } from './drill-breadcrumbs.component';
import { TemplateWidgetComponent } from './template-widget/template-widget.component';
import { WidgetBoxComponent } from './widget-box/widget-box.component';
import { WidgetDescriptionService } from './widget-description/widget-description.service';
import { BulkWidgetMenuComponent } from './widget-menu/bulk-widget-menu/bulk-widget-menu.component';
import { CopySubmenuComponent } from './widget-menu/copy-submenu/copy-submenu.component';
import { CopyTextComponent } from './widget-menu/copy-text.component';
import { DescriptionMenuItemComponent } from './widget-menu/description-menu-item/description-menu-item.component';
import { OpenDocExplorerComponent } from './widget-menu/open-doc-explorer/open-doc-explorer.component';
import { StatsMenuItemComponent } from './widget-menu/stats-menu-item/stats-menu-item.component';
import { WidgetImageExportComponent } from './widget-menu/widget-image-export/widget-image-export.component';
import { WidgetMenuComponent } from './widget-menu/widget-menu.component';
import { CurrentSentencesTableComponent } from './widget-stats/current-sentences-table/current-sentences-table.component';
import { StatsTableComponent } from './widget-stats/stats-table/stats-table.component';
import { TimeToRunComponent } from './widget-stats/time-to-run/time-to-run.component';
import { WidgetStatsComponent } from './widget-stats/widget-stats.component';
import { WidgetTitleComponent } from './widget-title/widget-title.component';
import { LegacyWidgetComponent } from './legacy-widget/legacy-widget.component';
import { EditableWidgetTitleComponent } from './editable-widget-title/editable-widget-title.component';
import { EditWidgetButtonComponent } from './edit-widget-button/edit-widget-button.component';
import { WidgetTitleContainerComponent } from './widget-title-container/widget-title-container.component';
import { ExpandingInputModule } from '@clarabridge/unified-angular-components';
import { PushWidgetMenuComponent } from './widget-menu/push-widget-menu/push-widget-menu.component';
import { IconModule } from '../icon/icon.module';
import { SubmenuComponent } from './widget-menu/submenu/submenu.component';
import { WidgetTotalCountComponent } from '@app/modules/widget-container/widget-total-count.component';
import { PreviewExportService } from './widget-menu/export/preview-export.service';

@NgModule({
	imports: [
		SharedModule,
		ClipboardModule,
		MenuDirectivesModule,
		WidgetVisualizationsModule,
		GridsterModule,
		ExpandingInputModule,
		IconModule
	],
	providers: [
		ChartAccessibilityService,
		ReportExportUtilsService,
		PreviewExportService,
		WidgetDescriptionService,
	],
	exports: [
		DrillBreadcrumbsComponent,
		WidgetContainerUpgradeDirective,
		TemplateWidgetComponent,
		WidgetContainerUpgradeDirective,
		WidgetTitleComponent,
		WidgetTitleContainerComponent,
		EditWidgetButtonComponent,
		WidgetMenuComponent,
		WidgetStatsComponent,
		DashboardFiltersSectionComponent,
		CopySubmenuComponent,
		WidgetTotalCountComponent
	],
	declarations: [
		DrillBreadcrumbsComponent,
		WidgetContainerUpgradeDirective,
		CopySubmenuComponent,
		OpenDocExplorerComponent,
		WidgetMenuComponent,
		TemplateWidgetComponent,
		CopyTextComponent,
		WidgetTitleComponent,
		EditableWidgetTitleComponent,
		WidgetTitleContainerComponent,
		EditWidgetButtonComponent,
		DescriptionMenuItemComponent,
		WidgetStatsComponent,
		CurrentSentencesTableComponent,
		StatsTableComponent,
		TimeToRunComponent,
		BulkWidgetMenuComponent,
		StatsMenuItemComponent,
		WidgetImageExportComponent,
		DashboardFiltersSectionComponent,
		ContentWidgetComponent,
		PageBreakWidgetComponent,
		WidgetBoxComponent,
		ExternalWidgetComponent,
		LegacyWidgetComponent,
		EditWidgetButtonComponent,
		WidgetTitleContainerComponent,
		PushWidgetMenuComponent,
		SubmenuComponent,
		WidgetTotalCountComponent
	],

})
export class WidgetContainerModule {}
