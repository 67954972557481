import * as _ from 'underscore';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { TemplateOptionsService } from '@cxstudio/dashboard-templates/template-options-service.service';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-type.enum';


export class TemplatePlaceholderReplacementSelectorController implements ng.IController {

	placeholder: any;
	type: string;
	templateOptions: any;

	hidden: boolean;
	optionsHierarchy: any[];

	constructor(
		private $scope: ISimpleScope,
		private templateOptionsService: TemplateOptionsService,
	) {}

	$onInit(): void {
		this.hidden = false; // for predefined attributes, e.g. nlp

		this.$scope.$watch(() => this.templateOptions, () => {
			if (this.templateOptions) {
				this.optionsHierarchy = this.getOptionsByPlaceholderType(this.placeholder.key) || [];
				let matcher = this.getMatcher(this.placeholder);
				this.placeholder.replacement = this.findSimilarReplacement(this.optionsHierarchy, matcher);
			} else {
				this.optionsHierarchy = undefined;
				this.placeholder.replacement = undefined;
			}
		});
	}


	private getOptionsByPlaceholderType = (key) => {
		let options = this.type === TemplatePlaceholderType.CALCULATION
			? this.getCalculationOptions() : this.getGroupingOptions(key);
		options = this.removeEmptyOptions(options);

		return options;
	}

	private getCalculationOptions = () => {
		let calculationOptions = this.templateOptionsService.buildCalculationOptions(this.templateOptions);
		return [
			this.findOptionsGroup(calculationOptions, OptionsConstant.ATTRIBUTES),
			this.findOptionsGroup(calculationOptions, OptionsConstant.METRICS)
		];
	}

	private getGroupingOptions = (key) => {
		let groupingOptions = this.templateOptionsService.buildGroupingOptions(this.templateOptions);

		if (AnalyticMetricTypes.isTime(key)) {
			return this.findOptionsGroupChildren(groupingOptions, OptionsConstant.TIME);
		} else if (AnalyticMetricTypes.isHierarchyModel(key)) {
			return this.findOptionsGroupChildren(groupingOptions, OptionsConstant.HIERARCHY_MODEL);
		} else if (AnalyticMetricTypes.isClarabridge(key)) {
			return [
				this.findOptionsGroup(groupingOptions, OptionsConstant.TOPICS),
				this.findOptionsGroup(groupingOptions, OptionsConstant.TOPIC_LEAF)
			];
		} else if (AnalyticMetricTypes.isAttribute(key)) {
			return [
				this.findOptionsGroup(groupingOptions, OptionsConstant.NLP),
				this.findOptionsGroup(groupingOptions, OptionsConstant.ATTRIBUTES),
				this.findOptionsGroup(groupingOptions, OptionsConstant.DERIVED_ATTRIBUTES)
			];
		} else if (AnalyticMetricTypes.isCustom(key)) {
			return this.findOptionsGroupChildren(groupingOptions, OptionsConstant.METRICS);
		}

		// fallback to empty option list for unknown grouping type
		return [];
	}

	private findOptionsGroup = (options: any[], name) => {
		return _.findWhere(options, { name });
	}

	private findOptionsGroupChildren = (options: any[], name) => {
		let group = this.findOptionsGroup(options, name);
		return group && group.children;
	}

	private findSimilarReplacement = (options, matchFunction) => {
		for (let option of options) {
			if (!option) continue;

			if (matchFunction(option)) return option;

			if (!option.children) continue;
			let childMatch = this.findSimilarReplacement(option.children, matchFunction);
			if (childMatch) return childMatch;
		}

		return null;
	}

	private removeEmptyOptions = (options) => {
		if (!options) return [];
		options = options.filter((option) => {
			return !!option;
		});

		options.forEach((option) => {
			this.removeEmptyChildren(option);
		});

		return options;
	}

	private removeEmptyChildren = (option) => {
		if (!option.children) return;

		option.children = option.children.filter((child) => {
			return !!child;
		});

		option.children.forEach(this.removeEmptyChildren);
	}

	private getMatcher = (placeholder) => {
		if (placeholder.hidden)
			return this.nameMatcher(placeholder.key.name);
		return this.displayNameMatcher(placeholder.displayName);
	}

	private nameMatcher = (originalName) => {
		return (option) => {
			return option.name === originalName;
		};
	}

	private displayNameMatcher = (displayName) => {
		return (option) => {
			return displayName === option.displayName;
		};
	}
}

app.component('templatePlaceholderReplacementSelector', {
	controller: TemplatePlaceholderReplacementSelectorController,
	templateUrl: 'partials/dashboard-templates/template-placeholder-replacement-selector.html',
	bindings: {
		placeholder: '<',
		templateOptions: '<',
		type: '@'
	},
});
