import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetBulkDeleteAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-bulk-delete-action';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';

export class WidgetBulkCreateAction implements DashboardChangeAction {
	private createdWidgets: Widget[];

	constructor(widgets: Widget[]) {
		this.createdWidgets = ObjectUtils.copy(widgets);
	}

	reverse(): DashboardChangeAction {
		return new WidgetBulkDeleteAction(this.createdWidgets);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let copies = ObjectUtils.copy(this.createdWidgets);
		return api.createWidgets(...copies).then((created) => {
			widgets.pushAll(created);
			DashboardUtils.sortWidgets(widgets);
			this.createdWidgets.forEach((widget, i) => {
				if (!_.isUndefined(widget.id)) {
					idMapper.addIdMapping(widget.id, created[i].id);
				}
				widget.id = created[i].id;
			});
		});
	}
}
