import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { ArchiveItem } from '@app/modules/archive/archive-page/archive-item';
import { RestoreConfig } from '@app/modules/archive/archive-page/restore-config';
import { TransferCandidate } from '@app/modules/user-administration/transfer/transfer-candidate';

@Injectable({
	providedIn: 'root'
})
export class ArchiveApi {
	constructor(
		private cxHttp: CxHttpService
	) {}

	getArchive = (): Promise<ArchiveItem[]> => {
		return this.cxHttp.get(`rest/archive`);
	}

	restore = (archiveItem: ArchiveItem, config: RestoreConfig): Promise<void> => {
		return this.cxHttp.put(`rest/archive/${archiveItem.id}/restore`, config);
	}

	getRestoreParams = (archiveItem: ArchiveItem): Promise<string[]> => {
		return this.cxHttp.get(`rest/archive/${archiveItem.id}/params`);
	}

	getRestoreToOptions = (archiveItem: ArchiveItem): Promise<TransferCandidate[]> => {
		return this.cxHttp.get(`rest/archive/${archiveItem.id}/restore-to/options`);
	}

	restoreTo = (archiveItem: ArchiveItem, userId: number): Promise<void> => {
		return this.cxHttp.put(`rest/archive/${archiveItem.id}/restore-to/${userId}`);
	}
}
