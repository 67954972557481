import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { FeatureToggleBaseComponent } from '@app/modules/account-administration/properties/feature-toggle-base';
import { ToastService } from '@clarabridge/unified-angular-components';
import Authorization from '@cxstudio/auth/authorization-service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

@Component({
	selector: 'account-owners-toggle',
	template: `
	<simple-feature-toggle
		[(featureEnabled)]="featureEnabled"
		(featureEnabledChange)="toggleFeature($event)"
		[label]="label"
		[disabled]="isDisabled()">
	</simple-feature-toggle>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountOwnersToggleComponent extends FeatureToggleBaseComponent implements OnInit {

	feature = MasterAccountProperty.ACCOUNT_OWNER_MANAGE_OBJECTS;
	label = this.locale.getString('mAccount.accountOwnerManageObjects');

	constructor(
		private ref: ChangeDetectorRef,
		toastService: ToastService,
		locale: CxLocaleService,
		@Inject('maPropertiesService') maPropertiesService: MAPropertiesService,
		@Inject('authorization') private authorization: Authorization,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
	) {
		super(maPropertiesService, toastService, locale);
	}

	isDisabled(): boolean {
		return !this.authorization.hasSystemAccess();
	}

	toggleFeature(enabled: boolean): void {
		if (enabled) {
			let accountOwnersPromise = this.masterAccountApiService.getMasterAccountOwners();
			accountOwnersPromise.then((result) => {
				let accountOwners = _.pluck(result, 'userEmail').join(', ');
				return this.cbDialogService.confirm(
					this.locale.getString('common.pleaseConfirm'),
					this.locale.getString('mAccount.accountOwnerManageObjectsConfirm', { accountOwners }),
					this.locale.getString('common.confirm'),
					this.locale.getString('common.cancel'),
					'modal-lg no-capitalize').result.then(
						() => super.toggleFeature(enabled),
						() => { this.featureEnabled = false; this.ref.markForCheck(); }, // cancel change
					);
			});
		} else {
			super.toggleFeature(enabled);
		}
	}
}

app.directive('accountOwnersToggle', downgradeComponent({component: AccountOwnersToggleComponent}) as angular.IDirectiveFactory);
