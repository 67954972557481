import * as _ from 'underscore';
import { PredefinedMetricValue } from '@cxstudio/metrics/predefined/predefined-metric-value';
import ILocale from '@cxstudio/interfaces/locale-interface';


export class DriversFormatting {

	readonly TOPIC_BAND_REGEX = /{{(sentiment|easeScore):(.*)}}/;

	constructor(
		private locale: ILocale
	) { }

	getDriverFormatter = (originalFormatter?: (value: string) => string): (value: string) => string => {
		return (value: string) => {
			value = this.getDriverDisplayName(value);
			return originalFormatter ? originalFormatter(value) : value;
		};
	}

	getDriverDisplayName = (field: string): string => {
		if (this.TOPIC_BAND_REGEX.test(field)) {
			return field.replace(this.TOPIC_BAND_REGEX, (fullStr, metricName, metricValue) => {
				return this.getBandName(metricName, metricValue);
			});
		}
		return field;
	}

	private getBandName(metric: string, value: PredefinedMetricValue): string {
		if (value === PredefinedMetricValue.NEGATIVE_STRONG)
			value = PredefinedMetricValue.NEGATIVE;
		else if (value === PredefinedMetricValue.POSITIVE_STRONG)
			value = PredefinedMetricValue.POSITIVE;
		return this.locale.getString(`metrics.${metric}_filter_${value}`);
	}

}

app.service('driversFormatting', DriversFormatting);
