import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { NgPipesModule } from 'ngx-pipes';
import { BulkLicensingUpdateDialogComponent } from '@app/modules/user-bulk/bulk-licensing/bulk-licensing.component';
import { UserBulkUpdateTableComponent } from '@app/modules/user-bulk/user-bulk-update-table/user-bulk-update-table.component';
import GroupsUserGridDefinitionService from '@app/modules/user-bulk/grid-definitions/groups-user-grid-definition.service';
import UsersBulkDataAccessGridDefinition from '@app/modules/user-bulk/grid-definitions/user-bulk-data-access-grid-definition.service';
import UsersGroupGridDefinitionService from '@app/modules/user-bulk/grid-definitions/users-group-grid-definition.service';
import UsersBulkPermissionsGridDefinitionService from '@app/modules/user-bulk/grid-definitions/users-bulk-permissions-grid-definition.service';
import { BulkPermissionsComponent } from './bulk-permissions/bulk-permissions.component';
import { UserBulkDataAccessComponent } from './user-bulk-data-access/user-bulk-data-access.component';
import { UserModificationApiService } from './user-modification/user-modification-api.service';
import { BulkCustomFieldComponent } from './bulk-custom-field/bulk-custom-field.component';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		DialogModule,
		ItemGridModule,
	],
	declarations: [
		BulkLicensingUpdateDialogComponent,
		UserBulkUpdateTableComponent,
		BulkPermissionsComponent,
		UserBulkDataAccessComponent,
		BulkCustomFieldComponent,
	],
	providers: [
		BulkLicensingUpdateDialogComponent,
		GroupsUserGridDefinitionService,
		UsersBulkDataAccessGridDefinition,
		UsersGroupGridDefinitionService,
		UsersBulkPermissionsGridDefinitionService,
		UserModificationApiService
	]
})
export class UserBulkModule { }
