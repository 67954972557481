import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { ContentWidgetsModule } from '@app/modules/widget-visualizations/content-widgets/content-widgets.module';
import { HighchartsCaseUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case-utils.service';
import { MetricValueComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/metric-value/metric-value.component';
import { HighchartsScatterUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-scatter/highcharts-scatter-utils.service';
import { ReportViewWrapperUpgrade } from '@app/modules/widget-visualizations/report-view-wrapper.upgrade.directive';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { VisualizationResolverService } from '@app/modules/widget-visualizations/visualization-resolver.service';
import { WidgetContentComponent } from '@app/modules/widget-visualizations/widget-content/widget-content.component';
import { SharedModule } from '@app/shared/shared.module';
import { DefaultDataFormatterBuilderService } from './formatters/default-data-formatter-builder.service';
import { FormatterBuilderUtilsService } from './formatters/formatter-builder-utils.service';
import { GenericFormatterService } from './formatters/generic-formatter.service';
import { MetricCustomFormatUtilsService } from './formatters/metric-custom-format-utils.service';
import { MetricMultipliersService } from './formatters/metric-multipliers.service';
import { NumberDelimiterFormatterBuilderService } from './formatters/number-delimiter-formatter-builder.service';
import { NumberFormatHelperService } from './formatters/number-format-helper.service';
import { NumberFormatterBuilderService } from './formatters/number-formatter-builder.service';
import { Percent100FormatterBuilderService } from './formatters/percent-100-formatter-builder.service';
import { Percent100NoneFormatterBuilderService } from './formatters/percent-100-none-formatter-builder.service';
import { PercentFormatterBuilderService } from './formatters/percent-formatter-builder.service';
import { PopFormatterBuilderService } from './formatters/pop-formatter-builder.service';
import { HighchartsDualWithCasesComponent } from './highcharts/highcharts-dual-with-cases/highcharts-dual-with-cases.component';
import { HighchartsDualComponent } from './highcharts/highcharts-dual/highcharts-dual.component';
import { HighchartsGaugeComponent } from './highcharts/highcharts-gauge/highcharts-gauge.component';
import { MetricLabelComponent } from './highcharts/highcharts-gauge/metric-label/metric-label.component';
import { MetricWidgetRenderingService } from './metric-widget/metric-widget-rendering.service';
import { MetricWidgetComponent } from './metric-widget/metric-widget.component';
import { QualtricsAuthButtonComponent } from './qualtrics/qualtrics-auth-button/qualtrics-auth-button.component';
import { QualtricsAuthErrorComponent } from './qualtrics/qualtrics-auth-error/qualtrics-auth-error.component';
import { QualtricsWidgetComponent } from './qualtrics/qualtrics-widget.component';
import { CloudD3UtilsService } from './utilities/cloud-d3-utils.service';
import { CloudProcessorService } from './utilities/cloud-processor.service';
import { ErrorMessageProcessingService } from './utilities/error-message-processing.service';
import { ReportErrorMessagesService } from './utilities/report-error-messages.service';
import { SpeechBubbleComponent } from './feedback/speech-bubble/speech-bubble.component';
import {
	RecolorManageDialogComponent
} from '@app/modules/widget-visualizations/recolor-manage-dialog/recolor-manage-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { WidgetVisualizationsCommonModule } from '@app/modules/widget-visualizations/common/widget-visualizations-common.module';
import { FeedbackVisualizationModule } from '@app/modules/widget-visualizations/feedback/feedback-visualization.module';


@NgModule({
	imports: [
		SharedModule,
		DragDropModule,
		ContentWidgetsModule,
		DialogModule,
		WidgetVisualizationsCommonModule,
		FeedbackVisualizationModule,
	],
	declarations: [
		HighchartsDualComponent,
		WidgetContentComponent,
		HighchartsGaugeComponent,
		MetricLabelComponent,
		MetricValueComponent,
		HighchartsDualWithCasesComponent,
		MetricWidgetComponent,
		QualtricsWidgetComponent,
		QualtricsAuthButtonComponent,
		QualtricsAuthErrorComponent,
		ReportViewWrapperUpgrade,
		RecolorManageDialogComponent,
	],
	exports: [
		WidgetContentComponent,
		QualtricsAuthButtonComponent,
		QualtricsAuthErrorComponent,
		ReportViewWrapperUpgrade,
	],
	providers: [
		HighchartsCaseUtilsService,
		MetricWidgetRenderingService,
		FormatterBuilderUtilsService,
		Percent100FormatterBuilderService,
		Percent100NoneFormatterBuilderService,
		NumberDelimiterFormatterBuilderService,
		PopFormatterBuilderService,
		NumberFormatterBuilderService,
		FormatterBuilderUtilsService,
		MetricMultipliersService,
		GenericFormatterService,
		NumberFormatHelperService,
		PercentFormatterBuilderService,
		DefaultDataFormatterBuilderService,
		VisualizationResolverService,
		CloudProcessorService,
		AnalyticsDataFormattingService,
		HighchartsAnalyticUtils,
		ErrorMessageProcessingService,
		ReportErrorMessagesService,
		CloudD3UtilsService,
		MetricCustomFormatUtilsService,
		HighchartsScatterUtilsService,
	],
})
export class WidgetVisualizationsModule { }
