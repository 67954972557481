import { Metric } from '@app/modules/metric/entities/metric';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ITreeSelectorItem } from '@cxstudio/common/options-builder/items-tree-group';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { PredefinedMetricValue } from '@cxstudio/metrics/predefined/predefined-metric-value';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DateAttributeType } from '@cxstudio/reports/attributes/date-attribute-type';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IOrganizationCalculations } from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { OptionsPrefixes } from '@cxstudio/reports/settings/options/options-prefixes';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OrderableListItemType } from '@cxstudio/services/orderable-list.class';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';


export class MetricUtils {
	private readonly GROUPS = {
		TIME_OPTIONS: OptionsConstant.TIME,
		CLARABRIDGE: OptionsConstant.CLARABRIDGE,
		METRICS: OptionsConstant.METRICS
	};

	constructor(
		private readonly locale: ILocale,
		private readonly metricConstants: MetricConstants,
		private readonly timePrimaryGroups: TimePrimaryGroupsService,
		private readonly $filter,
		private readonly driversUtils: DriversUtils,
	) {}


	skipEmptyRecursive = (groups: ITreeSelectorItem[]) => {
		return _.filter(groups, (group) => {
			if (group && group.children) {
				if (group.children.length) {
					group.children = this.skipEmptyRecursive(group.children);
				}
				return !!group.children.length;
			} else if (group && !group.filter) {
				return true;
			}

			return false;
		});
	}

	buildModelOptions = (models: Model[]) => {
		const constants = this.metricConstants.get();
		const _topicModels = _.map(models, (model) => {
			return {
				displayName: model.name,
				type: constants.TOPICS.type,
				name: model.id + '',
				hide: model.hide,
				metricType: AnalyticMetricType.CLARABRIDGE,
				rootNodeId: model.rootNodeId,
				model: true
			};
		});

		return {
			topicModels: _topicModels
		};
	}

	buildOrgHierarchyModelOptions = (hierarchyModels: any[]) => {
		return _.map(hierarchyModels, (model) => {
			return {
				displayName: model.name,
				type: OptionsConstant.HIERARCHY_MODEL,
				name: model.id + '',
				metricType: AnalyticMetricType.HIERARCHY_MODEL,
				levels: model.levels,
				rootNodeId: model.rootNodeId,
				hierarchyMode: model.hierarchyMode
			};
		});
	}

	buildOrgHierarchyMetricOptions = (hierarchyId: number, calculations: IOrganizationCalculations) => {
		const hierarchyMetrics = calculations || {} as IOrganizationCalculations;
		const enrichmentOptions = this.buildEnrichmentPropertiesOptions(hierarchyId, hierarchyMetrics.enrichment || []);
		const customOptions = this.buildHierarchyCustomCalculationOptions(hierarchyMetrics.custom || []);
		return enrichmentOptions.concat(customOptions);
	}

	private readonly buildEnrichmentPropertiesOptions = (hierarchyId: number, enrichmentProperties) => {
		return enrichmentProperties.map((property) => {
			return {
				displayName: property.propertyName,
				type: OptionsConstant.HIERARCHY_ENRICHMENT_PROPERTY,
				name: OptionsPrefixes.HIERARCHY_CALCULATION_PREFIX + property.propertyName,
				metricType: AnalyticMetricType.HIERARCHY_ENRICHMENT_PROPERTY,
				hierarchyId
			};
		});
	}

	private readonly buildHierarchyCustomCalculationOptions = (customCalculations) => {
		return customCalculations.map((calculation) => {
			return _.extend(calculation, {
				metricType: AnalyticMetricType.CUSTOM
			});
		});
	}

	buildAttributeOptions = (attributes: IReportAttribute[], filter: (item) => boolean): any | undefined => {
		if (_.isUndefined(filter)) {
			throw new Error('you must pass a filter to this');
		}
		const filtered = _.filter(attributes, filter);
		if (!filtered || !filtered.length) {
			return;
		}

		return _.each(filtered, (item: any) => {
			item.metricType = AnalyticMetricType.ATTRIBUTE;
		});
	}

	getAttributesGroup = (attributes: IReportAttribute[], filter: (item) => boolean): any | undefined => {
		if (_.isUndefined(filter)) {
			throw new Error('you must pass a filter to this');
		}
		const filtered = _.filter(attributes, filter);
		if (!filtered || !filtered.length) {
			return;
		}

		return this.hierarchyOfType(AnalyticMetricType.ATTRIBUTE, {
			name: 'attributes',
			displayName: this.locale.getString('widget.groupAttributes'),
			children: filtered
		});
	}

	getTimeGroup = (attributes: IReportAttribute[], originalName: boolean = false) => {
		const children = attributes
			.filter(MetricFilters.DATE)
			.map((attribute) => {
				return {
					name: attribute.name.toLowerCase(),
					displayName: attribute.displayName,
					metricType: AnalyticMetricType.TIME,
					forcedOrder: true,
					hide: attribute.hide,
					children: this.buildTimeAttributeChildren(attribute, originalName)
				};
			});

		return this.hierarchyOfType(AnalyticMetricType.TIME, {
			name: this.GROUPS.TIME_OPTIONS,
			displayName: this.locale.getString('widget.groupTimeOptions'),
			children,
			forcedOrder: true
		});
	}

	private readonly buildTimeAttributeChildren = (attribute: IReportAttribute, originalName: boolean = false) => {
		const timeGroupingAttributeProperties =
			this.buildTimeGroupingAttributeProperties(attribute);

		return this.timePrimaryGroups.values.filter((timePrimaryGroup) => {
			return timeGroupingAttributeProperties.attributeType === DateAttributeType.DATE
				? timePrimaryGroup.name !== this.timePrimaryGroups.HOUR.name
				: true;
		}).map((timePrimaryGroup) => {
			return this.buildTimeGrouping(timePrimaryGroup, attribute, originalName);
		});
	}

	buildTimeGrouping = (timePrimaryGroup, attribute: IReportAttribute, originalName: boolean = false) => {
		const timeGroupingAttributeProperties =
				this.buildTimeGroupingAttributeProperties(attribute);

		const resultGroup = angular.copy(timePrimaryGroup);
		resultGroup.timeName = resultGroup.name;

		// making name unique for each attribute
		resultGroup.name = `${timeGroupingAttributeProperties.attributeName}.${resultGroup.name}`;
		resultGroup.attributeName = timeGroupingAttributeProperties.attributeName;
		resultGroup.dateAttributeType = timeGroupingAttributeProperties.attributeType;
		resultGroup.selectionType = OrderableListItemType.BOUND_TO_TOP;
		if (!originalName) {
			resultGroup.displayName = `${resultGroup.displayName} (${attribute.displayName})`;
		}
		return resultGroup;
	}

	private readonly buildTimeGroupingAttributeProperties = (attribute: IReportAttribute) => {
		const docDate = attribute.name === '_doc_date';

		const resultAttributeName = docDate ? '_doc_time' : attribute.name.toLowerCase();
		const resultDateAttributeType = docDate
			? DateAttributeType.DATE_TIME
			: DateAttributeType.DATE;

		return {
			attributeName: resultAttributeName,
			attributeType: resultDateAttributeType
		};
	}

	getCustomMetricsGroup = (metrics: Metric[], projectId: number): any | undefined => {
		let processedMetrics = this.getItemsForProject(metrics, projectId);
		if (!processedMetrics || !processedMetrics.length) {
			return;
		}

		processedMetrics = processedMetrics.filter(this.isNotHierarchyMetric);

		const metricRoot = {
			id: -1,
			displayName: this.locale.getString('widget.groupMetrics'),
			name: 'metrics'
		};

		processedMetrics.forEach((metric) => {
			metric.parentId = metric.parentId || -1;
			metric.metricType = AnalyticMetricType.CUSTOM;
		});

		processedMetrics.unshift(metricRoot);

		const root = TreeListTransformUtils.tree(processedMetrics)[0];

		processedMetrics.forEach(this.removeParent);
		return root.children;
	}

	hierarchyOfType = (metricType: AnalyticMetricType, group: any) => {
		return HierarchyUtils.applyRecursively(group, (item) => {
			item.metricType = metricType;
		});
	}

	private readonly isItemFolder = (treeItem): boolean => {
		return /.*folder.*/i.test(treeItem.type);
	}

	private readonly removeParent = (node): void => {
		delete node.parent;
		if (node.children) {
			node.children.forEach(this.removeParent);
		}
	}

	private readonly getItemsForProject = (treeItems: any[], projectId?: number) => {
		const items = ValueUtils.isSelected(projectId)
			? _.filter(treeItems, (treeItem) =>  treeItem.projectId === projectId)
			: _.filter(treeItems, treeItem => !this.isItemFolder(treeItem));

		const foldersMap = {};
		_.chain(treeItems)
			.filter((treeItem) => {
				return this.isItemFolder(treeItem);
			})
			.map((treeItem) => {
				const item = angular.copy(treeItem); // copy folders to not share their open/close state
				item.displayName = item.name;
				return item;
			})
			.each((folder) => {
				foldersMap[folder.id] = folder;
			});

		const folders = [];
		_.chain(items)
			.pluck('parentId')
			.each((parentId) => {
				this.populateParents(folders, parentId, foldersMap);
			});

		return [].concat(folders).concat(items);
	}

	private readonly populateParents = (result, parentId: number, foldersMap): void => {
		const parent = foldersMap[parentId];
		if (parent) {
			result.push(parent);
			if (parent.parentId) {
				this.populateParents(result, parent.parentId, foldersMap);
			}
			delete foldersMap[parentId];
		}
	}

	getDriversGroup = (drivers: DriversItem[], projectId?: number): any | undefined => {
		let processedDrivers = this.getItemsForProject(drivers, projectId);
		if (_.isEmpty(processedDrivers)) {
			return;
		}
		const driversRoot = {
			id: -1,
			displayName: this.locale.getString('widget.groupDrivers'),
			name: 'drivers'
		};
		processedDrivers = _.map(processedDrivers, (driver) => {
			const grouping = this.driversUtils.getDriverGrouping(driver);
			grouping.parentId = driver.parentId || -1;
			return grouping;
		});

		processedDrivers.unshift(driversRoot);

		const root = TreeListTransformUtils.tree(processedDrivers)[0];

		processedDrivers.forEach(this.removeParent);
		return root.children;
	}

	toPredefinedGroupBy = (metrics: any[]) => {
		const array = [];
		_.each(metrics, (metric) => {
			_.each(metric.definition.subtypes, (subtype) => {
				const m = angular.copy(metric);
				m.subtype = subtype;
				m.displayName = this.locale.getString(m.displayName + subtype);
				m.rawName = m.name;
				m.name = m.name + subtype;
				m.metricType = AnalyticMetricType.METRIC_PREDEFINED;
				array.push(m);
			});
		});
		return array;
	}

	toPredefinedCalculation = (metrics: any[]) => {
		const array = [];
		_.each(metrics, (metric) => {
			const m = angular.copy(metric);
			m.displayName = this.locale.getString(metric.displayName);
			m.standardMetric = true;
			array.push(m);
		});
		return array;
	}

	isHierarchyMetric = (metric): boolean => {
		return this.isHierarchyCustomMetric(metric) || AnalyticMetricTypes.isHierarchyEnrichmentProperty(metric);
	}

	isNotHierarchyMetric = (metric): boolean => {
		return !this.isHierarchyMetric(metric);
	}

	isHierarchyCustomMetric = (metric): boolean => {
		return metric.type === ReportAssetType.METRIC_STUDIO && this.hasMathComponent(metric, {
			type: ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC
		});
	}

	isSpecificHierarchyCustomMetric = (metric, hierarchyId: number): boolean => {
		return metric.type === ReportAssetType.METRIC_STUDIO && this.hasMathComponent(metric, {
			type: ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC,
			hierarchyId
		});
	}

	private readonly hasMathComponent = (metric, mathComponentProperties): boolean => {
		return metric.definition && metric.definition.mathComponents
			&& !!_.findWhere(metric.definition.mathComponents, mathComponentProperties);
	}

	getPredefinedMetricValues = (metric): PredefinedMetricValue[] => {
		const values = angular.copy([PredefinedMetricValue.NEGATIVE_STRONG, PredefinedMetricValue.NEGATIVE,
			PredefinedMetricValue.NEUTRAL, PredefinedMetricValue.POSITIVE,
			PredefinedMetricValue.POSITIVE_STRONG]);
		if (metric.name === PredefinedMetricConstants.EASE_3
			|| metric.name === PredefinedMetricConstants.SENTIMENT_3
			|| metric.definition.type === MetricType.NUMERIC_BREAKDOWN) {
			values.removeAt(4);
			values.removeAt(0);
		}
		return values;
	}

	getPredefinedMetricDisplayNames = (metric): string[] => {
		const values = this.getPredefinedMetricValues(metric);
		let type = metric.rawName;
		if (metric.name.indexOf(PredefinedMetricConstants.SENTIMENT) === 0) {
			type = PredefinedMetricConstants.SENTIMENT;
		}
		return _.map(values, (value) => {
			return this.locale.getString(`metrics.${type}_${value}`);
		});
	}

	//include sentiment
	isPredefinedMetric = (metric: AttributeGrouping): boolean => {
		return AnalyticMetricTypes.isPredefinedMetric(metric)
			|| metric.name === PredefinedMetricConstants.SENTIMENT_3
			|| metric.name === PredefinedMetricConstants.SENTIMENT_5;
	}

	getNlpStudioMetrics = (metric, nlpStudioMetricAttributeMap) => {
		return !metric.hide && nlpStudioMetricAttributeMap[metric.name];
	}
}

app.service('metricUtils', MetricUtils);
