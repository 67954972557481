import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { PagingSearchFactory } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';

export class BasePagingMultiselect implements ng.IComponentController {

	pagingSearch: PagingSearchFactory;
	externalSearch?: string;
	externalOpen?: boolean;

	onSelectionChange: () => void;
	onNodeClick: ({node}) => void;

	constructor(private filterUtils: FilterUtils) {

	}

	$onInit(): void { }

	onChangeInternal = (): void => {
		if (this.onSelectionChange) this.onSelectionChange();
	}

	onNodeClickInternal = (node): void => {
		if (this.onNodeClick) this.onNodeClick({ node });
	}

	onUpdateSearchInternal = (search: string): void => {
		this.externalOpen = undefined;
		this.pagingSearch.resetSearch();
		this.pagingSearch.searchTerms(search);
	}

	filtersEqual = (filter1, filter2) => {
		return this.filterUtils.filtersEqual(filter1, filter2);
	}
}
