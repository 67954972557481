import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'pagination-custom-last-button',
	template: `
		<button
			tabindex="0"
			aria-disabled="true"
			aria-label=""
			class="btn btn-icon q-icon-triangle-end text-gray-600 disabled-last-page-button cursor-not-allowed no-hover hover:bg-none no-border no-background"
			[title]="'docExplorer.lastPageTooltip' | i18n"
		></button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationCustomLastButtonComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}

app.directive('paginationCustomLastButton',
	downgradeComponent({component: PaginationCustomLastButtonComponent}) as angular.IDirectiveFactory);
