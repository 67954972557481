import { NgModule } from '@angular/core';
import { ChatMessageComponent } from '@app/modules/conversation/chat-message/chat-message.component';
import { ConversationEnrichmentUtils } from '@app/modules/conversation/enrichments/conversation-enrichment-utils.service';
import { SecondsToDurationPipe } from '@app/modules/conversation/seconds-to-duration.pipe';
import { SecondsToTimePipe } from '@app/modules/conversation/seconds-to-time.pipe';
import { SentenceScorecardsComponent } from '@app/modules/conversation/sentence-scorecards.component';
import { SentencePillsComponent } from '@app/modules/conversation/sentence-pills.component';
import { EnrichmentPillsComponent } from '@app/modules/conversation/enrichment-pills/enrichment-pills.component';
import { VoiceMessageComponent } from '@app/modules/conversation/voice-message/voice-message.component';
import { VoiceSentenceComponent } from '@app/modules/conversation/voice-sentence.component';
import { PillsModule } from '@app/modules/pills/pills.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { TranslationModule } from '@app/modules/translation/translation.module';
import { ConversationUtils } from './conversation-utils.service';
import { ConversationPlaybackProgressService } from './preferences/conversation-playback-progress.service';
import { ConversationSettingsService } from './preferences/conversation-settings.service';
import { ConversationPlaybackSettingsService } from './preferences/conversation-playback-settings.service';
import { ConversationTooltipService } from '@app/modules/conversation/tooltip/conversation-tooltip.service';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		PillsModule,
		TranslationModule,
	],
	exports: [
		VoiceMessageComponent,
		ChatMessageComponent,
		SentenceScorecardsComponent,
		SentencePillsComponent,
		EnrichmentPillsComponent
	],
	declarations: [
		SentenceScorecardsComponent,
		SentencePillsComponent,
		EnrichmentPillsComponent,
		VoiceSentenceComponent,
		SecondsToTimePipe,
		SecondsToDurationPipe,
		VoiceMessageComponent,
		ChatMessageComponent
	],
	providers: [
		ConversationPlaybackProgressService,
		ConversationPlaybackSettingsService,
		ConversationSettingsService,
		ConversationEnrichmentUtils,
		ConversationUtils,
		ConversationTooltipService
	],
})
export class ConversationModule {}
