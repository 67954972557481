import { ScorecardTopicInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { ScorecardRebuttedNode } from '@cxstudio/projects/scorecards/entities/scorecard-rebutted-document';
import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import { ScorecardRebuttedNodeCollector } from './scorecard-rebutted-node-collector.class';

interface RebuttalTreeNodePayload {
	rebutted?: boolean;
	initialState: ScorecardTopicResult;
	previousState: ScorecardTopicResult;
	updatedState: ScorecardTopicResult;
	calulatedState?: ScorecardTopicResult;
}

interface RebuttalTreeNode {
	nodeId: number;
	parentId?: number;
	children: RebuttalTreeNode[];
	payload?: RebuttalTreeNodePayload;
}

class RebuttalTreeNodeUtils {
	static changed(node: RebuttalTreeNode): boolean {
		return node.payload && node.payload.initialState !== node.payload.updatedState;
	}

	static rebutted(node: RebuttalTreeNode): boolean {
		return node.payload.rebutted;
	}

	static leafNode(node: RebuttalTreeNode): boolean {
		return node.children.length === 0;
	}

	static becomeConsidered(node: RebuttalTreeNode): boolean {
		return node.payload.initialState === ScorecardTopicResult.NOT_APPLICABLE
			&& node.payload.updatedState !== ScorecardTopicResult.NOT_APPLICABLE;
	}

	static becomeNotConsidered(node: RebuttalTreeNode): boolean {
		return node.payload.initialState !== ScorecardTopicResult.NOT_APPLICABLE
			&& node.payload.updatedState === ScorecardTopicResult.NOT_APPLICABLE;
	}
}

export class ScorecardRebuttalTree {
	rootNode: RebuttalTreeNode;

	constructor(topics: ScorecardTopicInDocView[]) {
		this.initTree(topics);
	}

	private initTree(topics: ScorecardTopicInDocView[]): void {
		for (let topic of topics) {
			let nodeIdArray: number[] = _.map(topic.idPath.split('/'), nodeId => parseInt(nodeId, 10));

			let modelId = nodeIdArray.shift();

			if (!this.rootNode) {
				this.rootNode = this.createNode(modelId);
			}

			let currentChilds: RebuttalTreeNode[] = this.rootNode.children;
			let previousNode: RebuttalTreeNode = this.rootNode;
			for (let nodeId of nodeIdArray) {
				let currentNode: RebuttalTreeNode = currentChilds.find(node => node.nodeId === nodeId);

				if (!currentNode) {
					currentNode = this.createNode(nodeId, previousNode.nodeId);

					if (topic.nodeId === nodeId) {
						currentNode.payload = {
							rebutted: topic.rebutted,
							initialState: topic.result,
							updatedState: topic.result,
							previousState: undefined
						};

						if (topic.result === ScorecardTopicResult.NOT_APPLICABLE || topic.originalResult === ScorecardTopicResult.NOT_APPLICABLE) {
							previousNode.payload = currentNode.payload;
						}
					}

					currentChilds.push(currentNode);
				}

				previousNode = currentNode;
				currentChilds = currentNode.children;
			}
		}
	}

	private createNode(nodeId: number, parentId?: number): RebuttalTreeNode {
		return {
			nodeId,
			parentId,
			payload: {
				initialState: undefined,
				updatedState: undefined,
				previousState: undefined
			},
			children: []
		};
	}

	private getNodeById(id: number, currentNode: RebuttalTreeNode = this.rootNode): RebuttalTreeNode | null {
		if (currentNode.nodeId === id) {
			return currentNode;
		}
	
		for (let child of currentNode.children) {
			let foundNode = this.getNodeById(id, child);
			if (foundNode) {
				return foundNode;
			}
		}
		
		return null;
	}

	getNodeData(nodeId: number): RebuttalTreeNodePayload {
		let node: RebuttalTreeNode = this.getNodeById(nodeId);
		return node.payload;
	}

	updateNodeState(nodeId: number, currentState: ScorecardTopicResult): void {
		let node: RebuttalTreeNode = this.getNodeById(nodeId);
		this.changeNodeState(node, currentState);

		let previousState: ScorecardTopicResult = node.payload.previousState;

		if (currentState === ScorecardTopicResult.NOT_APPLICABLE
			|| previousState === ScorecardTopicResult.NOT_APPLICABLE) {

			let parentNode: RebuttalTreeNode = this.getNodeById(node.parentId);
			if (parentNode.children.length > 0) {
				this.traverseWithAction(sibling => { this.changeNodeState(sibling, currentState); }, parentNode);
			}
		}
	}

	getNodesForRebuttal(): ScorecardRebuttedNode[] {
		let nodesCollector: ScorecardRebuttedNodeCollector = new ScorecardRebuttedNodeCollector();

		let checkChanges = (node: RebuttalTreeNode): void => {
			if (!RebuttalTreeNodeUtils.changed(node) && !RebuttalTreeNodeUtils.rebutted(node)) {
				return;
			}

			let currentState = node.payload.updatedState;
			let becomeConsidered = RebuttalTreeNodeUtils.becomeConsidered(node);
			let leafNode = RebuttalTreeNodeUtils.leafNode(node);
			
			let newNode = {
				nodeId: node.nodeId,
				present: (!leafNode && becomeConsidered) || currentState === ScorecardTopicResult.PASSED,
				children: []
			};

			nodesCollector.addRebuttedNode(newNode, node.parentId);
		};

		this.traverseWithAction(checkChanges);

		return nodesCollector.getRebuttedNode();
	}

	private changeNodeState(node: RebuttalTreeNode, currentState: ScorecardTopicResult): void {
		node.payload.previousState = node.payload.updatedState;
		node.payload.updatedState = currentState;
	}

	private traverseWithAction(action: (node: RebuttalTreeNode) => void, node: RebuttalTreeNode = this.rootNode): void {
		action(node);
	
		for (let childNode of node.children) {
			this.traverseWithAction(action, childNode);
		}
	}

	hasChanges(): boolean {
		let changesExist = false;
	
		let checkChanges = (node: RebuttalTreeNode): void => {
			if (RebuttalTreeNodeUtils.changed(node)) {
				changesExist = true;
			}
		};
	
		this.traverseWithAction(checkChanges);
	
		return changesExist;
	}

	resetState(): void {
		this.traverseWithAction(node => node.payload.updatedState = node.payload.initialState);
	}
}