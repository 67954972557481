import { downgradeComponent } from '@angular/upgrade/static';
import { Component, OnInit, Input } from '@angular/core';
import { ApplicationTheme } from '@cxstudio/header/application-theme';

export interface ISpineHeaderConfiguration {
	participantLane: ILaneHeaderConfiguration;
	singleLanes: ILaneHeaderConfiguration[];
}

export interface ILaneHeaderConfiguration {
	tooltipContent: string;
	icon?: string;
	getHeaderHtml?: () => string;
	onClick?: (event: Event) => void;
}

@Component({
	selector: 'spine-header',
	template: `
<div class="participant-lane" 
	(mouseover)="hover(laneDefinitions.participantLane, $event)" 
	(mouseout)="hoverEnd()" 
	[innerHTML]="getParticipantHeader()">
</div>
<div *ngFor="let lane of laneDefinitions.singleLanes" 
	class="single-lane"
	[class.clickable]="isClickable(lane)"
	(click)="onClick(lane, $event)"
	(mouseover)="hover(lane, $event)" 
	(mouseout)="hoverEnd()">
	<i class="{{lane.icon}}"></i>
</div>
`})

export class SpineHeaderComponent implements OnInit {
	@Input() laneDefinitions: Partial<ISpineHeaderConfiguration>;
	@Input() theme: ApplicationTheme;

	readonly TOOLTIP_ELEMENT: string = '.spine-tooltip';

	ngOnInit() {
		this.laneDefinitions = this.laneDefinitions || {};
	}

	hover(lane, event: JQuery.MouseOverEvent) {
		d3.select(this.TOOLTIP_ELEMENT).classed('dark-spine-tooltip', this.theme === ApplicationTheme.DARK);
		$(this.TOOLTIP_ELEMENT)
			.html(lane.tooltipContent)
			.css({
				opacity: 1,
				display: 'block',
				left: event.clientX + 15,
				top: event.clientY
			});
	}

	hoverEnd() {
		$(this.TOOLTIP_ELEMENT).css({ opacity: 0, display: 'none'});
	}

	isClickable(lane: ILaneHeaderConfiguration): boolean {
		return !!lane.onClick;
	}

	onClick(lane: ILaneHeaderConfiguration, event: Event): void {
		if (lane.onClick)
			lane.onClick(event);
	}

	getParticipantHeader = () => this.laneDefinitions?.participantLane?.getHeaderHtml();
}

app.directive('spineHeader', downgradeComponent({component: SpineHeaderComponent}));
