import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class PreviewDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		if (!this.properties.previewMode || !this.properties.page?.sortMetric) {
			return '';
		}
		let view = this.locale.getString(`widgetDescription.${this.properties.previewMode}`);
		if (this.properties.previewMode === PreviewMode.DOCUMENT && this.visual.visualization === WidgetVisualization.VERBATIM_PREVIEW) {
			view = this.locale.getString('widgetDescription.verbatim');
		}
		let type = '';
		if (this.visual.visualization === WidgetVisualization.PREVIEW_BUBBLES) {
			type = this.locale.getString('widgetDescription.bubbleType');
		} else if (this.visual.visualization ===  WidgetVisualization.PREVIEW_TABLE) {
			type = this.locale.getString('widgetDescription.tableType');
		} else if (this.visual.visualization === WidgetVisualization.DOCUMENT_PREVIEW
				|| this.visual.visualization === WidgetVisualization.VERBATIM_PREVIEW) {
			type = this.locale.getString('widgetDescription.paneType');
		}
		let total = this.utils.getTotal(this.visual, data);
		let sort = this.utils.getPreviewSort(this.properties.page?.sortMetric);
		let result = this.locale.getString('widgetDescription.feedbackDescription', {
				view,
				type,
				sort,
				total
			});
		if (this.visual.tuningSuggestionsEnabled) {
			return `${result} ${this.locale.getString('widgetDescription.tuningEnabled')}`;
		}
		return result;
	}
	
}
