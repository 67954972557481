import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DialogStyle} from '../../dialog-style';

@Component({
	selector: 'simple-dialog',
	templateUrl: './simple-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent implements OnInit {
	@Input() header: string;
	@Input() text: string;
	@Input() okBtnName: string;
	@Input() okBtnClass: string = '';
	@Input() cancelBtnName: string;
	@Input() value: any;
	@Input() dialogStyle: DialogStyle = DialogStyle.REGULAR;

	warningDialogStyle = DialogStyle.WARNING;

	constructor(
		private modal: NgbActiveModal
	) {}

	ngOnInit(): void {}

	save = (): void => {
		this.modal.close(this.value);
	}

	cancel = (): void => {
		this.modal.dismiss();
	}

	getSaveBtnStyle = (): string => {
		switch (this.dialogStyle) {
			case DialogStyle.WARNING:
				return `${this.okBtnClass} btn btn-warning`;
			case DialogStyle.DANGER:
				return `${this.okBtnClass} btn btn-danger`;
			default:
				return `${this.okBtnClass} btn btn-primary`;
		}
	}
}
