import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CombinedFiltersService, FiltersGroup } from '@app/modules/filter/services/combined-filters.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardFilter, MetadataDashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WidgetModelGroupingFiltersService } from '@app/modules/dashboard/dashboard-filter-processors/widget-model-grouping-filters.service';
import { AnalyticFeedbackSelectionUtils } from '@cxstudio/reports/preview/analytic-feedback-selection-utils.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { DocExplorerFilter } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { WidgetDashboardHierarchyFiltersService } from '@app/modules/reporting/widget-dashboard-hierarchy-filters.service';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { CommonDrillService } from '../reports/utils/context-menu/drill/common-drill.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { BetaFeaturesService } from '../context/beta-features/beta-features-service';
import { BetaFeature } from '../context/beta-features/beta-feature';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { ReportFiltersService } from '../filter/services/report-filters.service';
import IFilter from '@cxstudio/report-filters/entity/filter';

interface ILinkedFilter extends DrillFilter {
	parentWidgetId: number;
}

interface ILinkedFilterValue {
	parentWidgetId: number;
	name: string;
	value: string;
}

export interface IParsedFilter {
	id?: number;
	name: string;
	displayName: string;
	subtype?: string;
	periodIdProperty?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ReportProcessingService {

	constructor(
		private readonly driversApi: DriversApi,
		@Inject('driversUtils') private readonly driversUtils: DriversUtils,
		@Inject('customFilterService') private readonly customFilterService: CustomFilterService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		@Inject('DateRange') private readonly DateRange,
		@Inject('dateFilterService') private readonly dateFilterService: DateFilterService,
		@Inject('widgetModelGroupingFilters') private readonly widgetModelGroupingFilters: WidgetModelGroupingFiltersService,
		@Inject('ignoredDashboardFilterService') private readonly ignoredDashboardFilterService: IgnoredDashboardFilterService,
		@Inject('dashboardFiltersService') private readonly dashboardFiltersService: DashboardFiltersService,
		private readonly commonDrill: CommonDrillService,
		private readonly widgetDashboardHierarchyFilters: WidgetDashboardHierarchyFiltersService,
		private readonly combinedFiltersService: CombinedFiltersService,
		private readonly reportAttributesService: ReportAttributesService,
		private readonly reportProjectContextService: ReportProjectContextService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly locale: CxLocaleService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly reportFiltersService: ReportFiltersService,
	) { }

	/**
	 * dashboardFiltersProvider can be null (e.g. alerts, interaction explorer)
	 * */
	getWidgetFiltersMetadata(widget: Widget,
		dashboardFiltersProvider: IDashboardHistoryInstance | null, widgetFilters: FiltersGroup[] = []): Promise<WidgetFiltersMetadata> {

		if (!this.isFilterableProjectSelected(this.reportAssetUtilsService.getWidgetProject(widget)))
			return Promise.reject();
		let linkedFilters = this.currentWidgets.getLinkedFiltersProcessed(widget.containerId, widget.id, widget.properties.documentLevelOnly);
		return PromiseUtils.all([
			this.getUpdatedProperties(widget),
			this.getWidgetFilters(widget, widgetFilters),
			this.reportAttributesService.getWidgetAttributes(widget),
			this.reportProjectContextService.getWidgetProjectTimezone(widget),
			this.getAllDrillFilters(widget),
			this.getParsedLinkedFilters(linkedFilters, widget),
			PromiseUtils.wrap(this.widgetModelGroupingFilters.getFilters(widget, false)),
			this.widgetDashboardHierarchyFilters.getPeerReportingMetadata(widget.properties, dashboardFiltersProvider),
			this.reportFiltersService.getWidgetDateFilters(widget)
		]).then(results => {
			let properties = results[0];
			let filterList = results[1];
			let attributes = results[2];
			let projectTimezone = results[3];
			let parsedDrillFilters = results[4];
			let parsedLinkedFilters = results[5];
			let parsedModelGroupingFilters = results[6];
			let peerReportingMetadata = results[7];
			let widgetDateFilters = results[8];

			this.customFilterService.updateAppliedFilters(filterList, properties.appliedFilters);

			if (properties.adhocFilter)
				this.processAttributeDisplayNames(properties.adhocFilter.filterRules, attributes);

			let dashboardFilters: MetadataDashboardFilter[] =
				this.getProcessedDashboardFilters(widget, dashboardFiltersProvider);

			let metadata: WidgetFiltersMetadata = {
				appliedFilters: properties.appliedFilters,
				adhocFilter: properties.adhocFilter,
				dashboardFilters: !_.isEmpty(dashboardFilters) ? _.filter(dashboardFilters, dashFilter => {
					return !dashFilter.isDrillToDashboardFilter;
				}) : undefined,
				drillToDashboardFilters: !_.isEmpty(dashboardFilters) ? dashboardFilters.filter((dashFilter) => {
					return dashFilter.isDrillToDashboardFilter;
				}) : undefined,
				textFilter: widget.filterUi?.textFilter,
				widgetDateFilter: this.getWidgetDateFilter(widget.properties, projectTimezone,
					dashboardFiltersProvider?.getDashboard(), dashboardFilters, widgetDateFilters),
				projectTimezone,
				parsedDrillFilters,
				parsedLinkedFilters,
				parsedModelGroupingFilters,
				personalization: dashboardFiltersProvider?.getPersonalization(),
				ignorePersonalization: peerReportingMetadata.ignorePersonalization,
				organizationFilterNode: peerReportingMetadata.organizationFilterNode,
			};
			return metadata;
		});
	}

	private getWidgetFilters(widget: Widget, reportWidgetFilters: FiltersGroup[]): Promise<FiltersGroup[]> {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.RETRIEVE_REPORT_ASSETS)
			? Promise.resolve(reportWidgetFilters)
			: this.combinedFiltersService.getWidgetFilters(widget);
	}

	setDocExplorerFilters(widgetMetadata: WidgetFiltersMetadata, additionalFilter: DocExplorerFilter): void {
		widgetMetadata.docExplorerFilters = [];
		if (!additionalFilter) {
			return;
		}
		if (additionalFilter.sentiment) {
			let sentimentFilter = ReportConstants.docExplorerSentimentFilterMap[additionalFilter.sentiment];
			if (sentimentFilter) {
				widgetMetadata.docExplorerFilters.push(this.locale.getString(sentimentFilter));
			}
			//doc 2.0
			widgetMetadata.docExplorerFilters.push(this.getDocExplorerFilter('metrics.sentiment', additionalFilter.sentiment));
		}
		if (additionalFilter.easeScore) {
			widgetMetadata.docExplorerFilters.push(this.getDocExplorerFilter('metrics.easeScore', additionalFilter.easeScore));
		}
		if (additionalFilter.emotion) {
			widgetMetadata.docExplorerFilters.push(this.getDocExplorerFilter('metrics.emotion', additionalFilter.emotion));
		}
		if (additionalFilter.words) {
			widgetMetadata.docExplorerFilters.push(`${this.locale.getString('docExplorer.searchFilter')}: ${additionalFilter.words}`);
		}
	}

	private getDocExplorerFilter(i18nBase: string, value: PredefinedFilterValue): string {
		return `${this.locale.getString(i18nBase)}: ${this.locale.getString(`${i18nBase}_${value}`)}`;
	}

	private getProcessedDashboardFilters(widgetSettings: Widget,
		filtersProvider: IDashboardHistoryInstance): MetadataDashboardFilter[] {

		let dashboardFilters = ObjectUtils.copy(filtersProvider?.getAppliedFilters() || []);
		return dashboardFilters.filter(dashboardFilter => {
			if (DashboardFilterSelection.isText(dashboardFilter.selectedAttribute) && !filtersProvider.getTextFilter()) {
				return false;
			}
			if (DashboardFilterSelection.isDateRange(dashboardFilter.selectedAttribute) && widgetSettings.ignoreDateRangeFilters) {
				//ignore dashboard's filters for widget, that has been drilled from pop widget
				return false;
			}
			return true;
		}).map(dashboardFilter => {
			let metadataDashboardFilter = dashboardFilter as MetadataDashboardFilter;
			if (this.ignoredDashboardFilterService.isIgnoredDashboardFilter(widgetSettings.properties,
					filtersProvider.getDashboard().properties, dashboardFilter)) {
				metadataDashboardFilter.ignored = true;
			}
			if (DashboardFilterSelection.isSavedFilter(dashboardFilter.selectedAttribute)) {
				if (widgetSettings.properties.documentLevelOnly) {
					dashboardFilter.multiValues = _.filter(dashboardFilter.multiValues,
						filterValue => filterValue.object?.type === FilterTypes.CXSCORECARD);
				}
			}
			return metadataDashboardFilter;
		}).filter(filter => !!filter);
	}

	private getWidgetDateFilter(properties: WidgetProperties, projectTimezone: string,
		dashboard: Dashboard, dashboardFilters: DashboardFilter[], widgetDateFilters: IFilter[]): string {
		let dashboardDateFilter = this.dashboardFiltersService.getDashboardDateFilter(dashboardFilters);
		if (dashboardDateFilter && !this.ignoredDashboardFilterService.isIgnoredDashboardFilter(properties,
				dashboard.properties, dashboardDateFilter)) {
			return '';
		} else if (!properties.dateRangeP1) {
			return '';
		} else if (DateRangeUtils.isCustomDateFilterMode(properties.dateRangeP1.dateFilterMode)) {

			let filterId = DateRangeUtils.getCustomDateFilterId(properties.dateRangeP1.dateFilterMode);
			let foundDateFilter = _.findWhere(widgetDateFilters, {id: filterId});
			if (foundDateFilter) {
				properties.dateRangeP1.dateDisplayName = foundDateFilter.name;
			}
			return properties.dateRangeP1.dateDisplayName;
		} else if (properties.dateRangeP1.dateFilterMode !== DateFilterMode.CUSTOM) {
			let dateRange = this.DateRange.valueOf(properties.dateRangeP1.dateFilterMode);
			return dateRange?.displayName;
		} else {
			let dateRangeFilter = {
				from: properties.dateRangeP1.from,
				to: properties.dateRangeP1.to
			};
			return this.dateFilterService.formatDateFilter(dateRangeFilter, projectTimezone);
		}
	}

	private getAllDrillFilters(widget: Widget): Promise<string[]> {
		return this.getDrillFilters(widget.properties.drillFilters, widget).then(drillFilters => {
			let feedbackSelectionFilter = AnalyticFeedbackSelectionUtils.getDrillFilter(widget,
				AnalyticFeedbackSelectionUtils.getCuratedItemType(widget),
				'');
			if (feedbackSelectionFilter)
				drillFilters.push(this.locale.getString('preview.feedbackSelection'));
			return drillFilters;
		});
	}

	getDrillFilters(drillFilters: DrillFilter[], widget: Widget): Promise<string[]> {
		if (!_.isEmpty(drillFilters)) {
			let drillFiltersPromise = [];
			let result = [];
			for (let drillFilter of drillFilters) {
				drillFiltersPromise.push(this.commonDrill.getRuleString(drillFilter, widget));
			}
			return Promise.all(drillFiltersPromise).then((drillFilterStrings) => {
				result.pushAll(drillFilterStrings);
				return result;
			});
		}

		return Promise.resolve([]);
	}

	private isFilterableProjectSelected(projectSelection: AccountOrWorkspaceProject): boolean {
		return WorkspaceTransitionUtils.isProjectSelected(projectSelection)
			|| InternalProjectTypes.isStudioAdminProject(projectSelection.projectId);
	}

	private getUpdatedProperties(widget: Widget): Promise<WidgetProperties> {
		let properties = ObjectUtils.copy(widget.properties);
		if (!properties.lockFilters)
			return Promise.resolve(properties);
		let driversGroup = _.find(properties.selectedAttributes, AnalyticMetricTypes.isDrivers);
		if (driversGroup) {
			return this.driversApi.getDriversFilters(driversGroup.id, true).then((filters) => {
				this.driversUtils.applyDriversFilters(properties, filters);
				return properties;
			});
		}
		return Promise.resolve(properties);
	}

	getParsedLinkedFilters = (filters: ILinkedFilter[], widget: Widget): Promise<string[]> => {
		let promises: Promise<ILinkedFilterValue>[] = [];
		_.each(filters, (filter) => {
			let promise = this.commonDrill.getRuleString(filter, widget).then((filterString: string): ILinkedFilterValue => {
				return {
					parentWidgetId: filter.parentWidgetId,
					name: filter.name,
					value: filterString
				};
			});
			promises.push(promise);
		});
		return Promise.all(promises).then((result: ILinkedFilterValue[]) => {
			let parts = _.partition(result, (item) => {
				return !_.isUndefined(item.parentWidgetId);
			});
			let filterStringGroups = _.toArray<_.Dictionary<ILinkedFilterValue[]>>(_.groupBy(parts[0], (filter) => {
				return `${filter.parentWidgetId}_${filter.name}`; // treat filters for each attribute as a separate items
			}));
			let parsedFiltersString = _.map(filterStringGroups, (group) => {
				if (group.length > 1) {
					return _.reduce(group, (prev, curr, index) => {
						let toAppend = curr.value || '';
						if (index !== 0) {
							toAppend = ', ' + toAppend.split(': ')[1];
						}
						return prev + toAppend;
					}, '');
				}
				return group[0].value;
			});
			parsedFiltersString.pushAll(_.map(parts[1], (item) => {
				return item.value;
			}));
			return _.uniq(parsedFiltersString);
		});
	}

	private processAttributeDisplayNames = (filterRules: IFilterRule[], attributes: IReportAttribute[]): void => {
		_.each(filterRules, (filterRule) => {
			let name;
			if (FilterRuleTypes.isAttributeRule(filterRule)) name = filterRule.attributeName;
			if (FilterRuleTypes.isDateRangeRule(filterRule)) name = filterRule.name;
			if (name && (!filterRule.attributeDisplayName || !filterRule.displayName)) {
				filterRule.displayName = filterRule.attributeDisplayName = this.findDisplayName(name, attributes);
			}
		});
	}

	private findDisplayName(name: string, attributes: IReportAttribute[]): string {
		let attr;
		if (this.metricConstants.isWordAttribute(name)) {
			return this.metricConstants.getWordDisplayName(name);
		}
		if (!_.isUndefined(attributes)) {
			attr = _.findWhere(attributes, { name });
		}
		return attr ? attr.displayName : name;
	}


}

app.service('reportProcessingService', downgradeInjectable(ReportProcessingService));
