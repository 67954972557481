import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from '@cxstudio/mobile/mobile-app-dialog-tab.enum';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';

@Component({
	selector: 'hierarchy-selector',
	template: `
<label class="font-bold">
    {{'mobile.organizationHierarchy'|i18n}}
    <cb-inline-help>
        <help-body>{{'mobile.inlineHelpHierarchy'|i18n}}</help-body>
    </cb-inline-help>
</label>
<search-list [dropdown]="true"
    class="w-100-percent d-flex"
    disableSearch="true"
    [listOptions]="hierarchies"
    [data]="hierarchy"
    displayField="name"
    sortField="name"
    optionValueField="id"
    (onNodeSelection)="selectHierarchy($event.node)"
    allowClear="true"
    (onClearSelection)="clearSelection()"
    selectPrompt="{{'mobile.noHierarchy'|i18n}}">
</search-list>
	`,
})
export class HierarchySelectorComponent implements OnInit {

	@Input() errors: Errors;
	@Output() errorsChange = new EventEmitter<Errors>();
	@Input() loading: any;
	@Output() loadingChange = new EventEmitter<any>();
	@Input() hierarchyId?: number;
	@Output() hierarchyIdChange = new EventEmitter<number>();
	hierarchies: Hierarchy[] = [];
	hierarchy?: Hierarchy;

	constructor(
		private organizationApiService: OrganizationApiService,
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.loadHierarchies();
	}

	private loadHierarchies = (): void => {
		this.loading.promise = this.organizationApiService.getOrganizationList()
			.then(response => this.hierarchies = response.data)
			.then(this.initSelectedHierarchy);
		this.loadingChange.emit(this.loading);
	}

	public initSelectedHierarchy = (): void => {
		this.hierarchy = _.findWhere(this.hierarchies, {id: this.hierarchyId});
		if (this.hierarchyId > 0 && !this.hierarchy) {
			this.organizationApiService.getOrgHierarchyIgnoreErrors(this.hierarchyId)
				.then((response) => {
					this.hierarchy = {
						id: this.hierarchyId,
						name: this.locale.getString('common.disabledOrgHierarchy', {name: response.data.name})
					};
				}, () => {
					this.hierarchy = {
						id: this.hierarchyId,
						name: this.locale.getString('common.deletedOrgHierarchy')
					};
				});
			this.setInactiveHierarchyError(true);
		}
	}

	private setInactiveHierarchyError = (value: boolean): void => {
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'inactive-hierarchy-error', !value);
		this.errorsChange.emit(this.errors);
	}

	selectHierarchy = (node: Hierarchy) => {
		this.hierarchy = node;
		this.hierarchyIdChange.emit(this.hierarchy.id);
		this.setInactiveHierarchyError(false);
	}

	clearSelection = (): void => {
		this.hierarchy = null;
		this.hierarchyIdChange.emit(null);
		this.setInactiveHierarchyError(false);
	}
}

app.directive('hierarchySelector', downgradeComponent({component: HierarchySelectorComponent}) as angular.IDirectiveFactory);
