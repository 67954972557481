import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

type Constructor<T> = new (...args: any[]) => T;

export function SelfCleaningComponentMixin<T extends Constructor<{}>>(SuperClass: T) {
	return class extends SuperClass {
		private subscriptions: Subscription[] = [];
		private listeners: Array<() => void> = [];
		private resizeObservers: ResizeObserver[] = [];

		protected addSubscription(subscription: Subscription): void {
			this.subscriptions.push(subscription);
		}
		protected addResizeObserver(resizeObserver: ResizeObserver): void {
			this.resizeObservers.push(resizeObserver);
		}
		protected addListener(listener: () => void): void {
			this.listeners.push(listener);
		}

		ngOnDestroy(): void {
			_.each(this.subscriptions, subscription => subscription?.unsubscribe());
			delete this.subscriptions;
			_.each(this.listeners, listener => listener && listener());
			delete this.listeners;
			_.each(this.resizeObservers, resizeObserver => resizeObserver?.disconnect());
			delete this.resizeObservers;

		}
	};
}

export abstract class SelfCleaningComponent extends SelfCleaningComponentMixin(Object) implements OnDestroy {}
