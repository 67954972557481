<div [ngBusy]="loading">
	<custom-math-button-bar
		[formula]="formula"
		[attributesSuggestions]="attributesSuggestions"
		[metricSuggestions]="metricSuggestions"
		[hierarchyMetricsSuggestions]="hierarchyMetricsSuggestions"
		[placeholderShown]="placeholderShown"
		(onButtonBarClick)="hideSuggestions()"
		(onChange)="onChange($event)"
		(onCaretPositionChange)="setCaretPosition($event)"
		(onTextAdd)="addTextToContent($event)"
		(onAfterInsert)="onAfterInsert($event)"
		(removePlaceholder)="removePlaceholder()"
		(updateAppliedMetrics)="updateAppliedMetrics($event)"
		[hasSubmenuArrow]="hasSubmenuArrow"
		(applyAggregationSuggestion)="applySuggestion($event)"
		(undo)="undoChanges($event)"
		(redo)="redoChanges($event)"
	></custom-math-button-bar>
	<div cx-keyboard-scroll
		(selectionChanged)="changeSelection($event)">
		<div #editor
			class="formula-input p-16 mb-16"
			contenteditable="true"
			spellcheck="false"
			(mousedown)="processMousedown($event)"
			(input)="onInput()"
			(keydown)="processEditorKeydown($event)"
			(keydown.enter)="$event.preventDefault()"
			(keydown.tab)="$event.preventDefault()">
		</div>
		<div *ngIf="tokenTooltip.visible"
			class="p-absolute"
			[style.top]="tokenTooltip.position.top"
			[style.left]="tokenTooltip.position.left">
			<div class="math-object-tooltip"
				[innerHTML]="tokenTooltip.text">
			</div>
		</div>
		<div #suggestions
			[style.top]="suggestionsY"
			[style.left]="suggestionsX"
			class="math-editor-dropdown dropdown-menu" [class.offscreen]="!showSuggestions"
			[style.display]="showSuggestions ? tokenSuggestionDisplayStyle : 'block'">
			<div class="d-flex flex-direction-column"
				(keydown)="handleSuggestionsKeydown($event)">
				<div class="ph-8 pb-8" [hidden]="!showSuggestionSearch">
					<input #suggestionSearchInput
						[(ngModel)]="suggestionSearch"
						(ngModelChange)="resetAttributeSuggestion()"
						placeholder="{{'common.search'|i18n}}"
						class="search-box flex-fixed font-standard">
				</div>
				<cdk-virtual-scroll-viewport itemSize="{{itemSize}}"
					class="flex-fill overflow-x-auto overflow-y-auto p-0 mb-0"
					[attr.data-height]="setScrollHeight((getFilteredSuggestions() | filterBy:['displayName']:suggestionSearch).length)"
					scroll-list role="list"
					(scroll)="onSuggestionsDropdownScroll($event)">
					<div *cdkVirtualFor="let suggestion of getFilteredSuggestions() | filterBy:['displayName']:suggestionSearch; index as suggestionIndex"
						[class.active]="suggestionIndex === highlightedSuggestionIndex"
						class="suggestion-list-item" role="listitem"
						(click)="handleSuggestionClick(suggestion, suggestionIndex, true)">
						<a class="d-flex align-items-center justify-between lh-1 pl-16 pv-8 "
						   [ngClass]="getSuggestionClasses(suggestion)"
						   href="javascript:void(0)">
							<div data-testid="suggestion-content">
								<icon class="mr-8" [name]="getSuggestionIcon(suggestion)"></icon>{{suggestion.displayName}}
							</div>
							<div class="justify-content-end">
								<ng-container *ngFor="let inlineHelpElement of suggestion.inlineHelpElements">
									<cb-inline-help
										*ngIf="inlineHelpElement.visible"
										fixed="true">
										<icon [name]="inlineHelpElement.icon"></icon>
										<help-body>{{inlineHelpElement.text}}</help-body>
									</cb-inline-help>
								</ng-container>
							</div>
							<span
								*ngIf="hasSubmenuArrow(suggestion)"
								class="submenu-arrow q-icon q-icon-triangle-right pl-8 pr-8"
								aria-hidden="true"
							></span>
						</a>
						<numeric-aggregations-submenu
							*ngIf="showAggregationsSubmenu(suggestion)"
							dropdownSelector=".math-editor-dropdown"
							(submenuItemClick)="onSubmenuItemClick($event)"
						></numeric-aggregations-submenu>
					</div>
				</cdk-virtual-scroll-viewport>
			</div>
		</div>
	</div>
	<alert type="danger" [dismissable]="false" [fullWidth]="true" *ngFor="let error of customMathErrors" text="{{error}}"></alert>
	<alert type="info" [fullWidth]="true" *ngFor="let warning of customMathWarnings" text="{{warning}}" (dismiss)="onWarningDismissed($event)"></alert>
</div>
