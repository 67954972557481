/**
 * on initialization, this will check which extensions apply and apply them...
 */
export class BarChartExtended {
	currentCategory;
	extensions;
	maxColumns: any;
	distanceBetweenColumns: number;
	sum: number;
	categoriesWidth: number;
	categoriesOffset: number;
	categories: any;
	numberVar: number;
	currentCategoryNum: number;

	constructor(
		chartOptions: Highcharts.Options,
		chartUtilities: { applyClustering(): boolean }
	) {
		this.extensions = [];

		let extentionThis = this; // tslint:disable-line:no-invalid-this no-this-assignment

		// add extension application rules and application directions to extensions collection
		this.extensions.push({
			isApplicable: () => chartUtilities.applyClustering(),
			apply: () => {
				chartOptions.chart.events = chartOptions.chart.events || {};
				chartOptions.chart.events.load = function(): void {
					let chartThis = this;	// tslint:disable-line:no-invalid-this no-this-assignment
					extentionThis.centerColumns(chartThis);
				};

				chartOptions.chart.events.redraw = function(): void {
					let chartThis = this;	// tslint:disable-line:no-invalid-this no-this-assignment
					extentionThis.centerColumns(chartThis);
				};
				chartOptions.chart.animation = false;
			}
		});

		this.apply();
	}

	// iterate over extensions collection and apply any applicable extensions
	apply = (): void => {
		for (let extension of this.extensions) {
			if (extension.isApplicable()) {
				extension.apply();
			}
		}
	}

	// center columns in a column chart grouping
	centerColumns = (chart: Highcharts.Chart) => {
		this.categoriesWidth = (chart as any).plotSizeX / (1 + chart.xAxis[0].max - chart.xAxis[0].min);
		this.categoriesOffset = this.categoriesWidth * Math.abs(chart.xAxis[0].min);

		this.categories = chart.xAxis[0].categories;

		let columnsPerGrouping = [];

		this.distanceBetweenColumns = 0;

		for (let category of this.categories) {
			this.currentCategory = category;
			this.sum = 0;
			_.each(chart.series, (series) => this.countVisibleCategories(series));
			columnsPerGrouping.push({visible: this.sum, hidden: chart.series.length - this.sum});
		}

		this.maxColumns = _.max(columnsPerGrouping, group => group.visible);

		for (let i = 0; i < this.categories.length; i++) {
			this.currentCategory = this.categories[i];
			this.distanceBetweenColumns = this.categoriesWidth / (columnsPerGrouping[i].visible + 1);
			this.numberVar = 1;
			this.currentCategoryNum = i;
			_.each(chart.series, this.repositionColumns);
		}
	}

	private isVisibleColumnOrBar = (data): boolean => {
		return data.visible && data.userOptions.type === 'column' || data.userOptions.type === 'bar';
	}


	// recenter label to account for the fact that labels have been moved and resized
	private repositionLabels = (seriesObj, type): void => {
		if (!seriesObj.dataLabel || !type) return;

		let middleOfBar = seriesObj.graphic.element.x.baseVal.value + (seriesObj.pointWidth / 2);

		if (type === 'bar' && seriesObj.dataLabel.ySetter) {
			let halfHeightOfLabel = (seriesObj.dataLabel.height / 2);

			seriesObj.dataLabel.ySetter(middleOfBar - halfHeightOfLabel);
			seriesObj.dataLabel.alignAttr.y = middleOfBar - halfHeightOfLabel;
		} else if (type === 'column' && seriesObj.dataLabel.xSetter) {
			let halfWidthOfLabel = (seriesObj.dataLabel.width / 2);

			seriesObj.dataLabel.xSetter(middleOfBar - halfWidthOfLabel);
			seriesObj.dataLabel.alignAttr.x = middleOfBar - halfWidthOfLabel;
		}
	}

	private countVisibleCategories = (series): void => {
		if (this.isVisibleColumnOrBar(series)) {
			_.each(series.data, (seriesDataObj) => {
				if (seriesDataObj.category === this.currentCategory && seriesDataObj.graphic) this.sum++;
			});
		}
	}

	private repositionColumns = (series, k): void => {
		if (this.isVisibleColumnOrBar(series)) {
			_.each(series.data, (seriesDataObj) => {
				if (seriesDataObj.category === this.currentCategory && seriesDataObj.graphic) {

					// adjust this to add/remove space between columns
					let paddingPerColumn = 0.5;

					seriesDataObj.pointWidth = ((this.categoriesWidth) / (this.maxColumns.visible + 1)) - paddingPerColumn;
					seriesDataObj.graphic.element.width.baseVal.value = seriesDataObj.pointWidth;

					seriesDataObj.graphic.element.x.baseVal.value = this.currentCategoryNum * this.categoriesWidth
						+ this.distanceBetweenColumns * this.numberVar - seriesDataObj.pointWidth / 2 + this.categoriesOffset;

					this.repositionLabels(seriesDataObj, series.options.type);

					// if we're using bar, we need to adjust to treat the top of the chart as point 0 on the x axis
					// otherwise, the data gets flipped and sorts from bottom to top
					if (series.options.type === 'bar') {
						seriesDataObj.graphic.element.x.baseVal.value =
							(series.chart.xAxis[0].len - seriesDataObj.pointWidth) - seriesDataObj.graphic.element.x.baseVal.value;
					}

					this.numberVar++;
				}
			});
		}
	}
}
