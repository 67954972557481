import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { TextAttributeGroupingSettingsEditor } from '../text-attribute-grouping-settings-editor';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';


@Injectable()
export class TextAttributeGroupingSettingsEditorProvider {

	constructor(
		private readonly sortByOptionsService: SortByOptionsService,
		private readonly tagService: TagsService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
	) {}

	getInstance(): TextAttributeGroupingSettingsEditor {
		return new TextAttributeGroupingSettingsEditor(
			this.optionsBuilderProvider,
			this.tagService,
			this.sortByOptionsService,
			this.metricConstants
		);
	}

}

app.service('textAttributeGroupingSettingsEditorProvider', downgradeInjectable(TextAttributeGroupingSettingsEditorProvider));
