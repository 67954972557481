import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core/cx-http.service';

// TODO: Add service spec
@Injectable()
export class DashboardHistoryApiService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) { }

	getDashboardVersions = (dashboardId) => {
		return this.cxHttp.get('rest/dashboard/' + dashboardId + '/versions');
	}


	getWidgets = (dashboardId, versionId) => {
		return this.cxHttp.get('rest/widgets/' + dashboardId + '/history/' + versionId);
	}


	revertWidgets = (dashboardId, versionId) => {
		return this.cxHttp.post('rest/widgets/' + dashboardId + '/history/' + versionId);
	}
}

app.service('dashboardHistoryApiService', downgradeInjectable(DashboardHistoryApiService));
