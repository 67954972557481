import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'item-grid'
})

export class ItemGridUpgradeDirective extends UpgradeComponent {
	@Input() gridType;
	@Input() gridMode;
	@Input() gridOptions;
	@Input() uiOptions;
	@Input() gridFilter;
	@Input() gridFilterFunction;
	@Input() gridFilterCriteria;
	@Input() gridAutoTooltip;
	@Input() gridHeaderButton;
	@Input() gridHeaderButtonFunction;
	@Input() treeData;
	@Input() nameField;
	@Input() gridChange;
	@Input() scrollable;
	@Input() controller;
	@Input() hideItems;
	@Input() gridColumns;
	@Input() idField;
	@Input() gridDataChange;
	@Input() pagingOptions;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('itemGrid', elementRef, injector);
	}
}
