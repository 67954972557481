<sidebar-section [header]="'widgetSettings.formatting'|i18n">
	<label *ngIf="!!defaultFormatSettings" class="mb-16">
		<input type="checkbox" [(ngModel)]="calculation.useDefaultFormat"> {{'metrics.useMetricFormat'|i18n}}
	</label>
	<number-format *ngIf="!isLoading()"
		[mustInherit]="inheritFormat"
		[formatObject]="calculation"
		[defaultFormat]="defaultFormatSettings"
		[readOnly]="calculation.useDefaultFormat">
	</number-format>
</sidebar-section>
<sidebar-section *ngIf="isCalculationTypeAvailable()" [header]="'widget.calculation'|i18n">
	<calculation-type-selector
		[(ngModel)]="calculation.calculationType">
	</calculation-type-selector>
</sidebar-section>
<sidebar-section *ngIf="showDirectionalColor" [header]="'widget.style'|i18n">
	<direction-color
		class="mt-16"
		[calculation]="calculation"
		[(ngModel)]="calculation.colorDirection">
	</direction-color>
</sidebar-section>
