import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import EventType from '@cxstudio/services/event/event-type.enum';

export class ContentWidgetKeyboardNavigationUtils {

	private static readonly PAGE_BREAK_WIDGET_NAME = 'page_break';

	private static readonly CONTAINER_SELECTOR = '.br-widget-box';
	private static readonly MENU_BUTTON_SELECTOR = '.widget-header-menu';

	static generateEventType(widget: Widget): EventType {
		return `${EventType.UPDATE_CONTENT_WIDGET_HEADER_VISIBILITY}-${widget.id}` as EventType;
	}

	static isContentNotPageBreakWidget(widget: Widget): boolean {
		return widget.type === WidgetDisplayType.CONTENT && widget.name !== ContentWidgetKeyboardNavigationUtils.PAGE_BREAK_WIDGET_NAME;
	}

	static showHeaderOnContainerBlur = (event: FocusEvent): boolean => {
		let container: EventTarget = event.target;
		let menuButton: JQuery = $(container).find(ContentWidgetKeyboardNavigationUtils.MENU_BUTTON_SELECTOR);

		return ContentWidgetKeyboardNavigationUtils.showHeaderOnBlur(event.relatedTarget, menuButton);
	}

	static showHeaderOnMenuButtonBlur = (event: FocusEvent): boolean => {
		let menuButton: HTMLElement = event.target as HTMLElement;
		let container: JQuery = $(menuButton).closest(ContentWidgetKeyboardNavigationUtils.CONTAINER_SELECTOR);

		return ContentWidgetKeyboardNavigationUtils.showHeaderOnBlur(event.relatedTarget, container);
	}

	private static showHeaderOnBlur = (nextFocusedItem: EventTarget, comparisonItem: JQuery): boolean => {
		return comparisonItem.length === 1 && comparisonItem[0] === nextFocusedItem;
	}
}
