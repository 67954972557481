<div class="w-100-percent h-100-percent d-flex align-center justify-center bg-white p-relative">
	<ng-container *ngIf="showRequestMessageForm">
		<div [ngBusy]="loading">
			<header class="mb-80 text-center">
				<span class="q-icon q-icon-security text-lg-6 text-gray-800" aria-hidden="true"></span>
				<h2 class="text-lg-2 font-semibold">{{requestAccessHeaderMessage}}</h2>
				<p class="text-base font-normal text-gray-800">{{'sharing.requestAccessSubheader'|i18n}}</p>
			</header>
			<div class="mb-16">
				<div>
					<strong class="float-left pr-2">{{'sharing.requestAccessOptionalMessage'|i18n}}</strong>
					<characters-left-counter
						[maxLength]="1000"
						[textLength]="requestAccessMessageToSend.length">
					</characters-left-counter>
				</div>
				<textarea
					rows="4"
					name="requestAccessMessage"
					id="requestAccessMessage"
					class="w-100-percent no-resize"
					[(ngModel)]="requestAccessMessageToSend"
					maxlength="1000">
			</textarea>
			</div>
			<footer class="w-100-percent">
				<button
					type="button"
					class="btn btn-primary"
					data-testid="request-access"
					(click)="requestAccess()"
				>{{'common.requestAccess'|i18n}}</button>
			</footer>
		</div>
	</ng-container>
	<ng-container *ngIf="showRequestMessageSend">
		<div class="text-center">
			<span class="q-icon q-icon-check text-lg-6 text-gray-800" aria-hidden="true"></span>
			<h2 class="text-lg-2 font-semibold">{{'sharing.requestedAccessHeader'|i18n}}</h2>
			<p class="text-base font-normal text-gray-800">{{requestedAccessOfOwnerMessage}}</p>
			<button
				*ngIf="showRedirectToHome"
				type="button"
				class="btn btn-primary"
				(click)="close()"
			>{{'common.returnToHome'|i18n}}</button>
		</div>
	</ng-container>
</div>
