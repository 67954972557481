<div [ngBusy]="loading" class="col-sm-12 page-tab-content">
	<div class="panel panel-default mt-16">
		<div class="panel-heading">{{ "mAccount.accountReports" | i18n }}</div>
		<div class="panel-body clearfix">
			<div class="wrappable-buttons">
				<button id="_t-dashInfoReport" type="button" class="btn btn-secondary" (click)="getDashInfoReport()"
					[i18n]="'administration.dashInfoReport'"></button>
				<button id="_t-widgetVersionReport" type="button" class="btn btn-secondary"
					(click)="getWidgetVersionReport()" [i18n]="'administration.widgetVersionReport'"></button>
				<button id="_t-embeddedWidgetsReport" type="button" class="btn btn-secondary"
					(click)="getEmbeddedWidgetsReport()" [i18n]="'administration.embeddedWidgetsReport'"></button>
				<button id="_t-requestMetricSharingReport" type="button" class="btn btn-secondary"
					(click)="createMetricSharingReport()" [i18n]="'administration.requestMetricSharingReport'"></button>
				<button id="_t-requestFilterSharingReport" type="button" class="btn btn-secondary"
					(click)="createFilterSharingReport()" [i18n]="'administration.requestFilterSharingReport'"></button>
				<button type="button" class="btn btn-secondary"
					(click)="showAlertSubscriptionReportDialog()" [i18n]="'administration.requestAlertSubscriptionReport'"></button>
			</div>
		</div>
	</div>

	<div class="panel panel-default mt-16">
		<div class="panel-heading">{{ "mAccount.userReports" | i18n }}</div>
		<div class="panel-body clearfix">
			<div class="wrappable-buttons">
				<button id="_t-permissionMappingReport" type="button" class="btn btn-secondary"
					(click)="getPermissionMappingReport()" [i18n]="'administration.permissionMappingReport'"></button>
				<button id="_t-dataAccessReport" type="button" class="btn btn-secondary" (click)="getDataAccessReport()"
					[i18n]="'administration.dataAccessReport'"></button>
				<button id="_t-requestModelAccessReport" type="button" class="btn btn-secondary"
					(click)="showModelAccessReportDialog()" [i18n]="'administration.requestModelAccessReport'"></button>
			</div>
		</div>
	</div>

	<div class="panel panel-default mt-16" id="_t-lexicalPanel" *ngIf="showLexiconSuggestionsButton()">
		<div class="panel-heading">{{ "mAccount.advancedReports" | i18n }}</div>
		<div class="panel-body clearfix">
			<div class="mb-16">
				<button id="_t-lexiconSuggestion" type="button" class="btn btn-secondary"
					(click)="getLexiconSuggestions()" [i18n]="'administration.lexiconSuggestion'"></button>
			</div>
		</div>
	</div>

	<div class="panel panel-default mt-16">
		<div class="panel-heading">{{ "mAccount.embeddedWidgets" | i18n }}</div>
		<div class="panel-body clearfix">
			<embedded-widget-management></embedded-widget-management>
		</div>
	</div>

	<collapsing-panel class="pr-16 pl-16" [collapsed]="false" *ngIf="showTranslateUsage()">
		<panel-heading>{{'administration.translateConsumption' | i18n}}</panel-heading>
		<panel-body>
			<consumption-statistics-table
				[assetType]="TrackingAssetType.TRANSLATE"
				[refinementMode]="StatisticRefinementMode.CURRENT_MASTER_ACCOUNT"
				[searchLabel]="'administration.masterAccountsSearchPlaceholder'|i18n"
				[consumedColumnTitle]="'administration.translateConsumed'|i18n"
				[remainingColumnTitle]="'administration.translateRemaining'|i18n"
				[totalExpencesTitle]="'administration.translateCost'|i18n"
			>
			</consumption-statistics-table>
		</panel-body>
	</collapsing-panel>
</div>
