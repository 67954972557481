import { ContractSecurityLevel } from '@cxstudio/master-accounts/contracts/contract-security-level';

export class Contract {
	id: number;
	name: string;
	masterAccountId: number;
	security: ContractSecurityLevel;
	startDate: Date | string;
	endDate: Date | string;
	licenses: any;
	verbatimCredits: number;
	periods: number;
	note: string;
	audioHours: number;
	audioRetention: number;
	quickTranslate: boolean;
	active: boolean;
	intelligentScoring: boolean;
	coverage?: number;
	coverageVolume?: number;
	realTimeAgentAssist: boolean;
	realTimeAgentAssistCoverageVolume: number;
	automatedCallSummary: boolean;
	automatedCallSummaryCoverageVolume: number;
}
