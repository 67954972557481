import { ISelectableAsset } from './selectable-asset';
import { Model } from '@cxstudio/reports/entities/model';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ITreeSelectorGroup } from '@cxstudio/common/options-builder/items-tree-group';
import { CommonOptionsBuilder } from '@cxstudio/common/options-builder/common-options-builder';
import { CxLocaleService } from '@app/core';
import { AssetType } from './asset-type';

export class AssetAccessOptionsBuilder {

	private optionsBuilder: CommonOptionsBuilder<ISelectableAsset>;

	constructor(
		private locale: CxLocaleService,
	) {
		this.optionsBuilder = new CommonOptionsBuilder(this.getTemplate());
	}

	getTemplate = (): ITreeSelectorGroup[] => {
		return [{
			name: 'models',
			displayName: this.locale.getString('common.models'),
			children: [],
			filter: (item: ISelectableAsset) => item.assetType === 'MODEL'
		}, {
			name: 'attributes',
			displayName: this.locale.getString('common.attributes'),
			children: [],
			filter: (item: ISelectableAsset) => item.assetType === 'ATTRIBUTE'
		}];
	}

	withModels = (models: Model[], selectedIds: number[]): AssetAccessOptionsBuilder => {
		this.optionsBuilder.withOptions(models.map(item => this.modelMapper(item, selectedIds)));
		return this;
	}

	private modelMapper = (model: Model, selectedIds: number[]): ISelectableAsset => {
		return {
			assetType: AssetType.MODEL,
			assetId: model.id,
			displayName: model.name,
			selected: selectedIds.contains(model.id),
		} as ISelectableAsset;
	}

	withAttributes = (attributes: IReportAttribute[], selectedIds: number[]): AssetAccessOptionsBuilder => {
		this.optionsBuilder.withOptions(attributes.map(item => this.attributeMapper(item, selectedIds)));
		return this;
	}

	private attributeMapper = (attribute: IReportAttribute, selectedIds: number[]): ISelectableAsset => {
		return {
			assetType: AssetType.ATTRIBUTE,
			assetId: attribute.id,
			displayName: attribute.displayName,
			selected: selectedIds.contains(attribute.id),
		} as ISelectableAsset;
	}

	build = (): ITreeSelectorGroup[] => {
		return this.optionsBuilder.build();
	}
}
