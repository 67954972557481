import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { GaugeBaseLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-base-layout';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';

export class GaugeHorizontalLayout extends GaugeBaseLayout {

	getNeededWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		let comparisonSpace = this.getComparisonWidth(cssVars) + cssVars.horizontalSpacing;
		return this.getGaugeWidth(cssVars) + this.getComparisonsCount(comparisons) * comparisonSpace;
	}

	getNeededHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return Math.max(this.getGaugeHeight(cssVars), this.getComparisonHeight(cssVars));
	}

	getComparisonsColumnHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getComparisonHeight(cssVars);
	}

	getComparisonsRowWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getNeededWidth(cssVars, comparisons);
	}

	isComparisonsStacked(): boolean {
		return false;
	}
}
