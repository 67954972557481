import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportAsset } from '@cxstudio/reports/entities/report-asset';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { AnyGrouping } from '@cxstudio/reports/entities/any-grouping';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';
import { HierarchyModelGrouping } from '@cxstudio/reports/entities/hierarchy-model-grouping';


export enum AnalyticMetricType {
	ATTRIBUTE = 'ATTRIBUTE',
	TIME = 'TIME',
	STANDARD = 'STANDARD',
	CLARABRIDGE = 'CLARABRIDGE',
	CUSTOM = 'CUSTOM',
	HIERARCHY_MODEL = 'ORG_HIERARCHY_MODEL',
	HIERARCHY_ENRICHMENT_PROPERTY = 'HIERARCHY_ENRICHMENT_PROPERTY',
	METRIC_PREDEFINED = 'metricPredefined',
	DRIVERS = 'DRIVERS',
	//Object definition report
	SCORECARD = 'SCORECARD'
}

export class AnalyticMetricTypes {

	static readonly MAX_GROUPS_WITHOUT_NORMALIZATION = 2;
	static readonly MAX_CUSTOM_GROUOS_WITH_NORMALIZATION = 1;

	static isTime = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.TIME;
	}

	static isAttribute = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.ATTRIBUTE
			&& metric.objectType !== AttributeObjectType.STUDIO_GROUPING;
	}

	static isClarabridge = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.CLARABRIDGE;
	}

	static isCustom = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.CUSTOM;
	}

	static isStudioMetric = (metric: ReportAsset): boolean => {
		return metric?.type === ReportAssetType.METRIC_STUDIO;
	}

	static isPredefinedMetric = (metric: ReportAsset): boolean => {
		return metric?.type === ReportAssetType.METRIC_PREDEFINED;
	}

	static isNlpStudioMetric = (metric: ReportAsset): boolean => {
		return metric?.type === ReportAssetType.METRIC_NLP;
	}

	static isScorecardMetric = (metric: ReportAsset): boolean => {
		return metric?.type === ReportAssetType.SCORECARD;
	}

	static isPredefinedGroup = (group): boolean => {
		return group?.type === ReportAssetType.METRIC_PREDEFINED;
	}

	static isSentimentGroup = (group): boolean => {
		return group?.name === PredefinedMetricConstants.SENTIMENT_3
			|| group?.name === PredefinedMetricConstants.SENTIMENT_5;
	}

	static isEffortGroup = (group): boolean => {
		return group?.name === PredefinedMetricConstants.EASE_3
			|| group?.name === PredefinedMetricConstants.EASE_5;
	}

	static isHierarchyModel = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.HIERARCHY_MODEL;
	}

	static isTopics = (metric: ReportAsset): metric is TopicReportGrouping => {
		return metric?.type === ReportAssetType.TOPICS || metric?.type === ReportAssetType.TOPIC_LEAF;
	}

	static isTopicLeaf = (metric: ReportAsset): boolean => {
		return metric?.type === ReportAssetType.TOPIC_LEAF;
	}

	static isNumber = (metric: ReportAsset | IReportAttribute): boolean => {
		return metric?.type === ReportAssetType.NUMBER;
	}

	static isDrivers = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.DRIVERS;
	}

	static isDynamicFilteringHierarchy = (metric: HierarchyModelGrouping): boolean => {
		return metric?.metricType === AnalyticMetricType.HIERARCHY_MODEL
			&& metric?.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING;
	}

	static isHierarchyEnrichmentProperty = (metric: ReportAsset): boolean => {
		return metric?.metricType === AnalyticMetricType.HIERARCHY_ENRICHMENT_PROPERTY;
	}

	static isDocumentLevel = (item: ReportAsset): boolean => {
		return item?.objectType !== AttributeObjectType.SENTENCE
			&& item?.type !== ReportAssetType.METRIC_PREDEFINED
			&& item?.type !== ReportAssetType.METRIC_STUDIO;
	}

	static hasCustomGroups = (items: ReportAsset[]): boolean => {
		return _.some(items, item => AnalyticMetricTypes.isDrivers(item) || AnalyticMetricTypes.isTime(item)
			|| AnalyticMetricTypes.isDynamicFilteringHierarchy(item));
	}

	static countCustomGroups = (items: ReportAsset[]): number => {
		return _.filter(items, item => AnalyticMetricTypes.isDrivers(item) || AnalyticMetricTypes.isTime(item)
			|| AnalyticMetricTypes.isDynamicFilteringHierarchy(item)).length;
	}

	static isForcedGroupNormalization = (groupings: ReportAsset[]): boolean => {
		return groupings?.length > AnalyticMetricTypes.MAX_GROUPS_WITHOUT_NORMALIZATION
			&& AnalyticMetricTypes.hasCustomGroups(groupings);
	}

	static isForcedDisabledGroupNormalization = (groupings: ReportAsset[]): boolean => {
		return AnalyticMetricTypes.countCustomGroups(groupings) > AnalyticMetricTypes.MAX_CUSTOM_GROUOS_WITH_NORMALIZATION;
	}

	static isWordsOrAssociatedWordsGrouping = (grouping: ReportGrouping): boolean => {
		return grouping.name === ClarabridgeMetricName.WORDS
			|| grouping.name === ClarabridgeMetricName.HASHTAG
			|| grouping.name === ClarabridgeMetricName.LC;
	}

	static isSentenceLevelGrouping(grouping: AnyGrouping): boolean {
		return grouping.objectType !== AttributeObjectType.DOCUMENT
			&& (grouping.objectType === AttributeObjectType.SENTENCE
				|| grouping.metricType === AnalyticMetricType.CLARABRIDGE);
	}

	static isSentenceLevel(grouping: ReportGrouping, allGroupings: ReportGrouping[]): boolean {
		if (!allGroupings)
			return false;

		let index = allGroupings.indexOf(grouping);
		for (let i = 0; i <= index; i++) {
			if (this.isSentenceLevelGrouping(allGroupings[i] as AnyGrouping)) {
				return true;
			}
		}
		return false;
	}
}


export const MetricTypePlaceholderProperties  = {
	[AnalyticMetricType.STANDARD]: [ 'name' ],
	[AnalyticMetricType.CUSTOM]: [ 'id' ],
	[AnalyticMetricType.ATTRIBUTE]: [ 'type', 'name' ],
	[AnalyticMetricType.CLARABRIDGE]: [ 'name' ],
	[AnalyticMetricType.HIERARCHY_MODEL]: [ 'name' ],
	[AnalyticMetricType.TIME]: [ 'attributeName', 'timeName', 'name' ],
	[AnalyticMetricType.METRIC_PREDEFINED]: [ 'name' ],
};
