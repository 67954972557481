import { Inject, Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { CommonDrillService } from '../common-drill.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';

@Injectable({
	providedIn: 'root'
})
export class DrillToCloudProcessorService extends DefaultDrillProcessor {

	constants = this.metricConstants.get();

	constructor(
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		private readonly commonDrill: CommonDrillService,
		@Inject('calculationColorService') private readonly calculationColorService: CalculationColorService
	) {
		super();
		this.addInheritedVisualProperties(['attributeSelections', 'primaryGroup'].concat(CommonInherentProperties.highchartsVisual));
	}

	drillTo(widget: Widget, drillToType, point: DrillPoint): Widget {
		//replace name and visual
		widget.name = WidgetType.CLOUD;
		widget.properties.widgetType = WidgetType.CLOUD;
		widget.visualProperties.subChartType = undefined;
		widget.visualProperties.visualization = WidgetVisualization.CLOUD;
		widget.displayName += '-' + point.groupBy.displayName;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy), {size: 100});

		//add selectedAttributes
		widget.properties.selectedAttributes = [point.groupBy];
		widget.visualProperties.primaryGroup = point.groupBy.name;
		if (!widget.visualProperties.attributeSelections) {
			widget.visualProperties.attributeSelections = {};
		} else {
			widget.visualProperties.attributeSelections =
				_.pick(widget.visualProperties.attributeSelections, this.getInheritedVisualProperties());
		}

		this.commonDrill.processSelectedMetric(point, widget, this.setCalculation);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);
		this.commonDrill.processHierarchyMetrics(widget);

		this.initializeDefaultProperties(widget, point);

		widget = this.inheritPropertiesAndVisProps(widget);

		return widget;
	}

	private setCalculation(calculationMetric, widget): void {
		if (!calculationMetric)
			calculationMetric = this.constants.VOLUME;
		widget.visualProperties.attributeSelections.size = calculationMetric;
	}

	private initializeDefaultProperties(widget, point): void {
		let visualProps = widget.visualProperties;

		if (!visualProps.attributeSelections.size || visualProps.attributeSelections.size.name === '') {
			let metricName = (point.seriesType && point.seriesType === 'secondary')
				? widget.visualProperties.secondaryYAxis
				: widget.visualProperties.yAxis;

			let calculationAttribute = this.commonDrill.getSelectedMetric(widget, metricName);

			visualProps.attributeSelections.size = calculationAttribute;
			widget.properties.selectedMetrics = [calculationAttribute];
		}
		if (!AnalyticMetricTypes.isHierarchyModel(widget.properties.selectedAttributes[0])) {
			if (AnalyticMetricTypes.isHierarchyEnrichmentProperty(widget.visualProperties.attributeSelections.size)) {
				this.setCalculation(this.constants.VOLUME, widget);
			}
		}

		if (this.isWords(widget)) {
			visualProps.color = this.calculationColorService.SENTIMENT5;
			visualProps.attributeSelections.color = this.constants.SENTIMENT;
			widget.properties.selectedMetrics.push(this.constants.SENTIMENT);
			widget.properties.selectedMetrics = _.uniq(widget.properties.selectedMetrics, false, 'name'); //if first metric was sentiment
		}
	}

	private isWords(widget): boolean {
		let group = widget.visualProperties.primaryGroup;
		return group === this.constants.WORDS.name
			|| group === this.constants.HASHTAG.name
			|| group === this.constants.LC.name;
	}
}
