<div class="panel panel-default">
	<div class="panel-heading d-flex justify-between">
		<div class="mt-auto mb-auto">{{'appearance.customBranding' | i18n}}</div>
		<cb-toggle
			data-testid="branding-switch"
			[(ngModel)]="showBranding"
			(ngModelChange)="updateBranding($event)"
		></cb-toggle>
	</div>
	<div class="panel-body p-relative" *ngIf="showBranding"><custom-branding></custom-branding></div>
	<dark-mode-settings></dark-mode-settings>
</div>
<collapsing-panel *ngIf="showCalendarFormat">
	<panel-heading>{{'calendar.calendarFormat' | i18n}}</panel-heading>
	<panel-body class="p-relative">
		<calendar-format></calendar-format>
	</panel-body>
</collapsing-panel>
<collapsing-panel>
	<panel-heading>{{'appearance.conversationDisplayHeading' | i18n}}</panel-heading>
	<panel-body class="p-relative">
		<conversation-settings-list></conversation-settings-list>
	</panel-body>
</collapsing-panel>
<collapsing-panel *ngIf="automatedNarrativesBetaEnabled">
	<panel-heading>{{'appearance.automatedNarratives' | i18n}}</panel-heading>
	<panel-body class="p-relative">
		<narrative-settings-list></narrative-settings-list>
	</panel-body>
</collapsing-panel>
<div class="panel panel-default">
	<div class="panel-heading">{{'appearance.colorPalettes' | i18n}}</div>
	<div class="panel-body p-0 p-relative">
		<color-palettes></color-palettes>
	</div>
</div>
