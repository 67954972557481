import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class WidgetUpdateAction implements DashboardChangeAction {

	static wrapChange(widgetProvider: () => Widget, change: () => void): WidgetUpdateAction {
		let before = ObjectUtils.copy(widgetProvider());
		change();
		let after = ObjectUtils.copy(widgetProvider());
		return new WidgetUpdateAction(before, after);
	}

	constructor(private before: Widget, private after: Widget, private initialChange = true) {}

	reverse(): DashboardChangeAction {
		return new WidgetUpdateAction(this.after, this.before, false);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let widget = WidgetActionUtils.getWidgetWithLatestId(this.after, idMapper);
		return api.updateWidget(widget).then(() => {
			if (!this.initialChange) {
				let index = WidgetActionUtils.findWidgetIndexById(widgets, widget.id, idMapper);
				widgets[index] = widget;
			}
			this.initialChange = false;
		});
	}

	getBefore(): Widget {
		return this.before;
	}

	getAfter(): Widget {
		return this.after;
	}

	setAfter(widget: Widget) {
		this.after = widget;
	}
}
