import { Input } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'cb-modal-close',
	template: `
	<button type="button" class="border-0 btn btn-icon">
		<span class="q-icon q-icon-delete" [attr.aria-label]="label"></span>
	</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CbModalCloseComponent {
	@Input() label: string = this.locale.getString('common.close');

	constructor(private locale: CxLocaleService) {}
}

app.directive('cbModalClose', downgradeComponent({component: CbModalCloseComponent}));
