import { Injectable, Inject } from '@angular/core';

export const DOC_EXPLORER_PAGE_PATH = '/document-explorer';
const INTERACTION_EXPLORER_PAGE_PATH = '/interactions';
const DASHBOARD_PAGE_PATH_START = '/home/';
const BOOK_PAGE_PATH_START = '/dashboard/';

export type ILocationSearch<T extends string> = Record<T, string>;

@Injectable({
	providedIn: 'root'
})
export class CxLocationService {

	constructor(
		@Inject('$location') private $location: ng.ILocationService
	) {
	}

	search(): any;
	search(params: any): CxLocationService;
	search(params?: any): any {
		return params? this.$location.search(params) : this.$location.search();
	}

	path(): string;
	path(path: string): CxLocationService;
	path(path?: string): any {
		return this.$location.path(path);
	}

	url(): string;
	url(url: string): CxLocationService;
	url(url?: string): any {
		return this.$location.url(url);
	}

	absUrl(): string {
		return this.$location.absUrl();
	}

	isDashboardOrBook(): boolean {
		let path = this.path();
		return path.startsWith(DASHBOARD_PAGE_PATH_START) || path.startsWith(BOOK_PAGE_PATH_START);
	}

	isDocExplorerPage(): boolean {
		return this.path() === DOC_EXPLORER_PAGE_PATH;
	}

	isInteractionExplorerPage(): boolean {
		return this.path() === INTERACTION_EXPLORER_PAGE_PATH;
	}

	host(): string {
		return this.$location.host();
	}
}
