import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';

export class TreeListTransformUtils {

	static flat<T extends ITreeItem>(treeItems: T[]): T[] {
		let out = [];
		let processed = {};
		TreeListTransformUtils.expand<T>(treeItems, out, processed);
		return out;
	}

	private static expand<T extends ITreeItem>(array: T[], out: T[], processed: any, level: number = 0): void {
		for (let treeItem of array) {
			if (processed[treeItem.id]) {
				// throw Error(`Cyclic item tree: ${array}`);
				// this throws a ton of errors in E2E. Probably need to fix those before enabling.
				continue;
			}

			treeItem.level = level;
			out.push(treeItem);
			processed[treeItem.id] = true;

			if (isTreeItemFolder(treeItem)) {
				TreeListTransformUtils.expand((treeItem as IFolderItem).children, out, processed, level + 1);
			}
		}
	}


	private static setLevelRecursive(children: Array<ITreeItem | IFolderItem>, level: number): void {
		children.forEach((item) => {
			item.level = level;
			if (isTreeItemFolder(item)) {
				TreeListTransformUtils.setLevelRecursive(item.children, level + 1);
			}
		});
	}

	static getParentKey(treeItem): any {
		if (FilterTypes.CXDATE === treeItem.type) {
			return FilterTypes.CXDATE;
		} else if (treeItem.dateFilter) {
			return FilterTypes.CXSTUDIO;
		} else if (treeItem.createdByPinnedFeedback) {
			return DashboardType.FEEDBACK_FOLDER;
		} else if (treeItem.type === FilterTypes.PREDEFINED) {
			return FilterTypes.PREDEFINED;
		} else if (AnalyticMetricTypes.isPredefinedMetric(treeItem) && treeItem.name !== 'sentiment') {
			return AnalyticMetricType.METRIC_PREDEFINED;
		} else {
			return treeItem.parentId;
		}
	}

	static tree<T extends ITreeItem>(treeItems: T[]): T[] {
		let tree = [];
		let map = {};
		treeItems.forEach(treeItem => {
			// create a map of id<->dashboard
			map[treeItem.id] = treeItem;
			// delete fields if they are already populated, currently only a case in book edit with AG grid beta
			// can remove this once book edit table is converted to ag-grid
			delete (treeItem as any).children;
			delete treeItem.parent;
		});
		// create tree structure
		treeItems.forEach((treeItem) => {
			if (!TreeListTransformUtils.getParentKey(treeItem)) {
				tree.push(treeItem);
			} else {
				let parent = map[TreeListTransformUtils.getParentKey(treeItem)];
				if (!parent) {
					// throw Error('Incorrect parent');
					// this throws a ton of errors in E2E. Probably need to fix those before enabling.
					return;
				}
				treeItem.parent = parent;
				if (!parent.children) {
					parent.children = [];
				}
				parent.children.push(treeItem);
			}
		});
		TreeListTransformUtils.setLevelRecursive(tree, 0);
		return tree;
	}
}

export const isTreeItemFolder = (item: any): item is IFolderItem => {
	return !!(item.children);
};
