import { HttpParams } from '@angular/common/http';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { PromiseUtils } from '@app/util/promise-utils';
import CachedHttpFactory from '@cxstudio/common/cache/cached-http.factory';
import { HttpHandlers } from '@cxstudio/common/http-handlers';

export class CxCachedHttp {

	constructor(
		private readonly cachedHttpFactory: CachedHttpFactory,
		private readonly httpHandlers: HttpHandlers,
	) {}

	get = (url: string, options?: CxHttpRequestOptions<HttpParams>): Promise<any> => {
		let result = this.cachedHttpFactory.get(url, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error);
		return PromiseUtils.wrap(result);
	}

	post = (url: string, payload: any, options?: CxHttpRequestOptions<HttpParams>): Promise<any> => {
		let result = this.cachedHttpFactory.post(url, payload, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error);
		return PromiseUtils.wrap(result);
	}

	invalidate = (): void => {
		this.cachedHttpFactory.invalidate();
	}
}
