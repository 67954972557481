<div class="page-tab-content cp-tab-content">
	<div [ngBusy]="loading">
		<div class="justify">
			<div class="form-group">
				<div class="input-group">
					<input class="br-cp-filter" type="search" [(ngModel)]="cpQuery"
						   placeholder="{{'common.search'|i18n}}" (keydown.enter)="onSearchChange()">
					<button id="btn-search-cp-content-provider-tab" type="button"
							class="btn btn-primary q-icon-search" (click)=onSearchChange()></button>
				</div>
			</div>
			<div class="form-group">
				<button type="button"
						class="btn btn-primary br-btn-add-content-provider"
						(click)=addCPFunction()>{{'administration.addCp'|i18n}}</button>
			</div>
		</div>

		<table class="table">
			<thead>
				<tr>
					<th>#</th>
					<th>{{'administration.cpName'|i18n}}</th>
					<th>{{'administration.cpEndPoint'|i18n}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let cp of pagedCps | orderBy:'name'" class="br-cp" attr.br-cp-name="{{cp.name}}">
					<td scope="row">
						<a class="br-edit" (click)=editCpFunction(cp.id)>{{'administration.edit'|i18n}}</a> |
						<a class="br-delete" (click)=deleteCpFunction(cp.id)>{{'administration.remove'|i18n}}</a>
					</td>
					<td class="br-cp-name">{{ cp.name }}</td>
					<td class="br-cp-endpoint">{{ cp.endPoint }}</td>
				</tr>
			</tbody>
		</table>

		<pagination class="_t-cp-pagination" [hidden]="!(pagination.totalItems > 10)"
			[pagination]="pagination"
			[pullRight]="true"
			(paginationChange)="pageChanged($event)"
		></pagination>

	</div>
</div>
