import { Metric } from '@cxstudio/metrics/entities/metric.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PreviewSentence, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { ITableColumnFormatter } from '@cxstudio/reports/entities/table-column';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';

import * as _ from 'underscore';
import { SentenceChunk } from '@cxstudio/reports/preview/sentence-chunk-class';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { SentenceMetadataService } from '@cxstudio/reports/preview/sentence-metadata.service';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { SentencePreviewElementBuildersService } from '@app/modules/reports/utils/sentence-preview-element-builders.service';
import { FormattersUtils } from '@cxstudio/reports/utils/formatters-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { Pill, PillType } from '@app/modules/pills/pill';
import { EnrichmentIcon } from '@cxstudio/reports/preview/enrichment-icon.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { CapitalizationUtils } from '@cxstudio/services/capitalization-utils.class';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

export interface PreviewFormatterUIOptions {
	backgroundHighlight?: boolean;
	asPill?: boolean;
}

export interface FormattingOptions {
	hideNeutral?: boolean;
	iconWithBorder?: boolean;
}

export class SocialMediaIcon {
	iconClass: string;
	backgroundClass: string;
}

export type EnrichmentPillFunction = (sentence: PreviewSentence) => Pill;

export class AnalyticPreviewFormatting {
	readonly SENTENCE_TEXT_SOFT_LIMIT = 1200;
	readonly TEXT_ALLOWANCE = 100;

	constructor(
		private sentencePreviewElementBuilders: SentencePreviewElementBuildersService,
		private dateFilterService: DateFilterService,
		private sentenceMetadataService: SentenceMetadataService,
		private locale: ILocale,
		private GroupColorType,
		private urlService: UrlService,
		private formatterBuilderUtils: FormatterBuilderUtilsService,
		private formattersUtils: FormattersUtils,
		private $rootScope: ng.IScope,
		private metricsService: MetricsService,
		private $q: ng.IQService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly projectContextService: ProjectContextService,
		private readonly profanityDisguiseService: ProfanityDisguiseService,
	) {
	}

	feedbackSelectionFormatter = (analyticFeedbackSelection: IAnalyticFeedbackSelection): ITableColumnFormatter<PreviewSentence> => {
		return (sentence: PreviewSentence): string => {
			let isChecked: boolean = analyticFeedbackSelection && !!analyticFeedbackSelection.isItemCurated(sentence);
			let iconToggledClass = isChecked ? 'q-icon-check toggled' : 'q-icon-circle';
			return `<div class="display-size-sm pl-8">
				<i class="analytic-feedback-selection-flag ${iconToggledClass}" role="checkbox" aria-checked="${isChecked}"></i>
			</div>`;
		};
	}

	sourceFormatter = (selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);
		return (sentence: PreviewSentence): string => {
			if (sentence.smService) {
				let icon: SocialMediaIcon = this.getSocialMediaIcon(sentence.smService);
				return `<div class="icon-highlight feedback-preview-icon feedback-preview-source-icon text-white ${icon.backgroundClass}">
						<i class="${icon.iconClass}" aria-label="${sentence.smService}"></i>
					</div>`;
			} else if (sentence.attributes._id_source) {
				return capitalizer(sentence.attributes._id_source);
			}
			return capitalizer(this.locale.getString('widget.na'));
		};
	}

	private getSocialMediaIcon = (smService: string): SocialMediaIcon => {
		switch (smService) {
			case 'bazaarvoice': {
				// Bazaarvoice has a peculiar style for an icon, STUDIO-96
				return {
					iconClass: 'q-icon-third-party-bazaarvoice',
					backgroundClass: 'bg-bazaarvoice-color'
				};
			}
			default: {
				// covers Tripadvisor, Twitter, Facebook, and everything else.
				return {
					iconClass: 'q-icon-' + smService,
					backgroundClass: 'bg-' + smService + '-color'
				};
			}
		}
	}

	sentimentPillFunction = (sentimentMetric: Metric): EnrichmentPillFunction => {
		sentimentMetric = angular.copy(sentimentMetric);
		sentimentMetric.name = 'dScore'; // sentence sentiment value field
		let byNumber = true;
		let colorFunction = this.GroupColorType.types.SENTIMENT_5.getColorFunction(sentimentMetric, byNumber);
		let iconFunction = this.GroupColorType.types.SENTIMENT_5.getIconFunction(sentimentMetric);
		let categoryFunction = this.GroupColorType.types.SENTIMENT_5.getCategoryFunction(sentimentMetric, byNumber);
		return (sentence: PreviewSentence): Pill => {
			let color = colorFunction(sentence);
			let icon = iconFunction(sentence);
			let name = categoryFunction(sentence).name;
			const title = `${name} ${this.locale.getString(sentimentMetric.displayName)}`;
			return {
				name,
				type: PillType.SENTIMENT,
				title,
				icon,
				color,
				sentence
			};
		};
	}

	private metricPillFunction = (metric, type, pillType: PillType, getValueFunction): EnrichmentPillFunction => {
		let byNumber = true;
		// for doc explorer and preview we use ease3 breakdown
		let colorFunction = type.getColorFunction(metric, byNumber);
		let iconFunction = type.getIconFunction(metric);
		let categoryFunction = type.getCategoryFunction(metric, byNumber);

		return (sentence: PreviewSentence): Pill => {
			let value = getValueFunction(sentence);
			if (_.isUndefined(value) || value  === 'NaN')
				return null;
			let color = colorFunction(sentence, getValueFunction);
			let icon = iconFunction(sentence);
			let name = categoryFunction(sentence, getValueFunction).name;
			const title = `${name} ${this.locale.getString(metric.displayName)}`;
			return {
				name,
				type: pillType,
				title,
				icon,
				color,
			};
		};
	}

	sentimentFormatter = (sentimentMetric: Metric, uiOptions?: PreviewFormatterUIOptions,
							withRemove = false): ITableColumnFormatter<PreviewSentence> => {
		uiOptions = $.extend({wrap: false, backgroundHighlight: true}, uiOptions);
		let pillFunction = this.sentimentPillFunction(sentimentMetric);

		let isNeutralFunction = this.GroupColorType.types.SENTIMENT_5.getNeutralFunction(sentimentMetric);
		return (sentence: PreviewSentence, field?: string, options?: FormattingOptions): string => {
			if (_.isUndefined(sentence.dScore) || (options && options.hideNeutral && isNeutralFunction(sentence))) // dScore check for verbatims
				return '';
			let pill = pillFunction(sentence);

			const showIconWithBorder = options?.iconWithBorder || false;
			const baseEnrichmentIcon = this.getBaseEnrichmentIcon(pill.name, pill.title, pill.icon, pill.color);

			return uiOptions.asPill && !showIconWithBorder
				? baseEnrichmentIcon
					.withClasses(`m-0 ${PillType.SENTIMENT}`)
					.asPill(withRemove && !this.profanityDisguiseService.needToMaskSentence(sentence))
				: baseEnrichmentIcon
					.withHighlight(uiOptions.backgroundHighlight)
					.withClasses('feedback-preview-sentiment-icon')
					.withBorder(showIconWithBorder)
					.asIcon();
		};
	}

	private getBaseEnrichmentIcon(name: string, title: string, icon: string, color: string): EnrichmentIcon {
		return new EnrichmentIcon()
			.withDisplayName(name)
			.withTitle(title)
			.withIcon(icon)
			.withColor(color);
	}

	private breakdownMetricFormatter = (
			metric: Metric,
			type,
			pillType: PillType,
			getValueFunction: (sentence: PreviewSentence) => number,
			uiOptions: PreviewFormatterUIOptions,
			withRemove: boolean)
		: ITableColumnFormatter<PreviewSentence> => {

		uiOptions = $.extend({backgroundHighlight: true}, uiOptions);
		let pillFunction = this.metricPillFunction(metric, type, pillType, getValueFunction);
		if (!metric) {
			return;
		}

		return (sentence: PreviewSentence, field?: string, options?: FormattingOptions): string => {
			let pill = pillFunction(sentence);
			if (!pill) {
				return '';
			}
			const showIconWithBorder = options?.iconWithBorder || false;
			const baseEnrichmentIcon = this.getBaseEnrichmentIcon(pill.name, pill.title, pill.icon, pill.color);

			// should have pill type as one of the classes
			return uiOptions.asPill && !showIconWithBorder
				? baseEnrichmentIcon
					.withClasses(`m-0 ${pillType}`)
					.asPill(withRemove && !this.profanityDisguiseService.needToMaskSentence(sentence))
				: baseEnrichmentIcon
					.withHighlight(uiOptions.backgroundHighlight)
					.withBorder(showIconWithBorder)
					.asIcon();
		};
	}

	easeScore = (metric, uiOptions?: PreviewFormatterUIOptions, withRemove = false): ITableColumnFormatter<PreviewSentence> => {
		return this.breakdownMetricFormatter(metric, this.GroupColorType.types.EASE_3, PillType.EASE_SCORE,
			(sentence) => sentence.easeScore, uiOptions, withRemove);
	}

	emotion = (metric, uiOptions?: PreviewFormatterUIOptions, withRemove = false): ITableColumnFormatter<PreviewSentence> => {
		return this.breakdownMetricFormatter(metric, this.GroupColorType.types.EMOTION, PillType.EMOTION,
			this.sentenceMetadataService.getEmotion, uiOptions, withRemove);
	}

	verbatimFormatter = (selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);

		return (verbatim: PreviewSentence): string => {
			if (verbatim.chunks) {
				verbatim.chunks.forEach((chunk: SentenceChunk): void => {
					chunk.text = capitalizer(chunk.text);
				});
			}

			let spans = this.sentencePreviewElementBuilders.buildSentenceText(verbatim, false, null);

			let outerSpan = document.createElement('span');
			outerSpan.classList.add('verbatim-content');
			_.forEach(spans, child => {
				child.classList.add('primary-sentence');
				outerSpan.appendChild(child);
			});
			return outerSpan.outerHTML;
		};
	}

	sentenceFormatter = (sentimentHighlightingEnabled: boolean, sentimentMetric,
			selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);
		let categoryColorFunction = this.GroupColorType.types.SENTIMENT_5.getColorFunction(sentimentMetric);

		return (sentence: PreviewSentence): string => {
			if (sentence.chunks) {
				sentence.chunks.forEach((chunk: SentenceChunk): void => {
					chunk.text = capitalizer(chunk.text);
				});
			}

			let spans = this.sentencePreviewElementBuilders.buildSentenceText(sentence, sentimentHighlightingEnabled,
				categoryColorFunction);
			if (sentence.text && sentence.text.length > (this.SENTENCE_TEXT_SOFT_LIMIT + this.TEXT_ALLOWANCE)) {
				sentence.text = capitalizer(sentence.text);
				spans = this.limitSentenceLength(spans);
			}
			let mainSentence = _.chain(spans).forEach(span => span.classList.add('primary-sentence')).pluck('outerHTML').join('').value();
			let result = '';
			let context = sentence.sentenceContext || { previous: undefined, next: undefined };
			let previous = context.previous?.text ?
				capitalizer(context.previous.text) :
				undefined;
			let next = context.next?.text ?
				capitalizer(context.next.text) :
				undefined;
			if (previous)
				result += this.createContextSentence(previous) + '&nbsp;';
			result += mainSentence;
			if (next)
				result += '&nbsp;' + this.createContextSentence(next);
			return result;
		};
	}

	private limitSentenceLength(spans: Element[]): Element[] {
		let totalTextLength: number = 0;
		let truncatePosition: number = -1;
		let result: Element[] = [];
		let remains: Element[] = [];
		let spanIndex: number = 0;

		while (spanIndex < spans.length && totalTextLength <= this.SENTENCE_TEXT_SOFT_LIMIT) {
			let nextSpan = spans[spanIndex];

			totalTextLength += nextSpan.textContent.length;
			if (totalTextLength <= this.SENTENCE_TEXT_SOFT_LIMIT) {
				// if we can add this chunk without exceeding the soft limit, add it and move on to next
				result.push(nextSpan);
				spanIndex++;
			}
		}

		// if we go over the soft limit, check to see how much more text is left
		if (spanIndex <= (spans.length - 1)) {
			let remainingStringLength: number = 0;

			for (let tempIndex = spanIndex; tempIndex < spans.length; tempIndex++)
				remainingStringLength += spans[tempIndex].textContent.length;

			for (let tempIndex = spanIndex; tempIndex < spans.length; tempIndex++) {
				let nextSpan = spans[tempIndex];
				if (remainingStringLength <= this.TEXT_ALLOWANCE) {
					// if the remaining text fits within the overflow allowance, just push it
					result.push(nextSpan);
				} else {
					// otherwise we'll truncate back down to the soft limit
					if (truncatePosition === -1) {
						let startOfElement = totalTextLength - nextSpan.textContent.length;
						truncatePosition = this.SENTENCE_TEXT_SOFT_LIMIT - startOfElement;
					}
					remains.push(nextSpan);
				}
			}
		}

		if (truncatePosition > -1) {
			let clone = remains[0].cloneNode(true) as Element;
			this.changeTextContent(clone, clone.textContent.substr(0, truncatePosition));
			this.changeTextContent(remains[0], remains[0].textContent.substr(truncatePosition));
			result.push(clone);
		}
		if (truncatePosition > -1) {
			result.push(this.createEllipsisElement(remains));
		}
		return result;
	}

	private changeTextContent(span: Element, text: string): void {
		let textNode = span.hasChildNodes() ? span.firstChild : span; // for ngramCenter sentence nodes
		textNode.textContent = text;
	}

	private createEllipsisElement(others: Element[]): Element {
		let ellipsis = document.createElement('span');
		ellipsis.className = 'sentence-show-more';
		let button = document.createElement('a');
		button.href = 'javascript:void(0)';
		button.setAttribute('role', 'button');
		button.setAttribute('aria-expanded', 'false');
		button.textContent = this.$rootScope.pdf
			? this.locale.getString('preview.moreFeedback')
			: this.locale.getString('common.readMore');
		ellipsis.appendChild(button);
		_.each(others, span => ellipsis.appendChild(span));
		return ellipsis;
	}

	private createContextSentence(text: string): string {
		let span = document.createElement('span');
		span.className = 'sentence-context';
		span.textContent = text;
		return span.outerHTML;
	}

	verbatimTypeFormatter = (selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);

		return (sentence: PreviewSentence): string => {
			let value = sentence.verbatimType;
			if (_.isUndefined(value) || value === null || value.length === 0)
				return '';
			return capitalizer(value);
		};
	}

	topicsFormatter = (auditMode: boolean, auditModeModels: IReportModel[],
			selectedCapitalization: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		const nameFormatter = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);
		if (!auditMode) {
			return sentence => this.formatTopicPills(sentence.topics, false, () => false, nameFormatter);
		} else {
			const allowedModelIds = _.map(auditModeModels, model => model.id);
			const disabledFunc = (topic: SentenceTopic) => !_.contains(allowedModelIds, topic.modelId);
			return sentence => this.formatTopicPills(sentence.topics,
				!this.profanityDisguiseService.needToMaskSentence(sentence), disabledFunc, nameFormatter);
		}
	}
	private readonly formatTopicPills = (topics: SentenceTopic[], showRemove: boolean,
			disabledFunction: (topic: SentenceTopic) => boolean, nameFormatter: (name: string) => string): string => {
		if (!topics || !topics.length) {
			return '';
		}
		return _.chain(topics)
			.map(topic => {
				const disabled = disabledFunction(topic);
				const title = disabled ? this.locale.getString('preview.topicSuggestionDisabled') : topic.name;
				let topicRemove = '';
				let topicId = '';
				let classes = disabled ? 'disabled' : '';
				if (showRemove && topic.id > -1 && !disabled) {
					const removeTitle = `title="${this.locale.getString('common.remove')}"`;
					topicRemove = `<span class="text-lg-1 p-0 ml-4 color-inherit no-background">` +
						`<i class="q-icon q-icon-delete" ${removeTitle}></i></span>`;
					topicId =  `data-topic-id="${topic.id}"`;
					classes += ' pill-remove';
				}
				return `<button class="label-bubble ellipsis h-32 border-radius-16 ph-8 pv-4 m-4 border-solid border-1 d-flex-inline ${classes}"
					title="${title}" ${topicId}
				>${nameFormatter(topic.name)}${topicRemove}</button>`;
			})
			.join('')
			.value();
	}

	private formatPills = (values: string[], capitalizer: any): string => {
		if (!values || !values.length) {
			return '';
		}
		return _.chain(values)
			.map(value => `<div class="label-bubble ellipsis h-32 border-radius-16 ph-8 pv-4 m-4 border-solid border-1">${capitalizer(value)}</div>`)
			.join('')
			.value();
	}

	documentDateFormatter = (projectTimezone: string|number): ITableColumnFormatter<PreviewSentence> => {
		return (sentence: PreviewSentence): string => {
			let utcDateStr = sentence.documentDate;
			if (!utcDateStr)
				return '';
			let dateTime = this.dateFilterService.formatDocDatePoint(utcDateStr, projectTimezone, true, DateTimeFormat.BASIC_DATE_TIME);
			let date = this.dateFilterService.formatDocDatePoint(utcDateStr, projectTimezone, false, DateTimeFormat.BASIC_DATE);
			return `<span title="${dateTime}">${date}</span>`;
		};
	}

	dateAttributeFormatter = (): ITableColumnFormatter<PreviewSentence> => {
		return (sentence: PreviewSentence, field: string): string => {
			let value = this.getAttributeValue(sentence, field) as string;
			if (!value)
				return '';
			let formatted =	this.formatterBuilderUtils.formatDate(value);
			return `<span title="${formatted}">${formatted}</span>`;
		};
	}

	nlpAttributeFormatter = (selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);

		return (sentence: PreviewSentence, field: string): string => {
			let value = sentence.attributes[field.toLowerCase()] as string|string[];
			if (_.isUndefined(value) || value === null || value.length === 0)
				return '';
			if (!_.isArray(value))
				value = [value as string];
			return this.formatPills(value as string[], capitalizer);
		};
	}

	numberAttributeFormatter = (): ITableColumnFormatter<PreviewSentence> => {
		return (sentence: PreviewSentence, field: string): string => {
			let fieldValue = this.getAttributeValue(sentence, field);
			let values = _.isArray(fieldValue) ? fieldValue : [fieldValue];
			let formattedValues = values.map((value) => {
				if (!isNaN(value as any)) {
					return this.formatterBuilderUtils.formatNumber(String(value));
				} else {
					return this.locale.getString('widget.na');
				}
			});
			return formattedValues.join(', ');
		};
	}

	attributeFormatter = (selectedCapitalization?: CapitalizationType): ITableColumnFormatter<PreviewSentence> => {
		let capitalizer = CapitalizationUtils.getWrappedFormatter(selectedCapitalization);

		return (sentence: PreviewSentence, field: string): string => {
			let value = this.getAttributeValue(sentence, field);
			if (_.isUndefined(value) || value === null || value.length === 0)
				return capitalizer(this.locale.getString('widget.na'));
			if (_.isArray(value))
				return capitalizer((value as string[]).join(', '));
			if (!_.isString(value))
				return capitalizer(String(value));

			let arrayVal: string[] =
				(value as string).split(' ').map((stringPiece: string) => {
					if (this.urlService.isUrl(stringPiece)) {
						return this.urlService.replaceUrlWithLink(stringPiece, capitalizer);
					} else {
						let escaped = HtmlUtils.escapeHtml(stringPiece);
						return capitalizer(escaped);
					}
				});
			return arrayVal.join(' ');
		};
	}

	urlFormatter = (column): ITableColumnFormatter<PreviewSentence> => {
		let slickFormatter = this.formattersUtils.buildUrlFormatter(column.urlType as any, column);
		return (sentence: PreviewSentence, field: string): string => {
			let value = this.getAttributeValue(sentence, field);
			return slickFormatter(undefined, undefined, value);
		};
	}

	private getAttributeValue = (sentence: PreviewSentence, field: string): string | string[] => {
		let topLevelField = PreviewSentence.TOP_LEVEL_ATTRIBUTES[field.toUpperCase()];
		return topLevelField ? sentence[topLevelField] : sentence.attributes[field.toLowerCase()];
	}

	sentimentColorFunction = (sentimentMetric: Metric): ITableColumnFormatter<PreviewSentence> => {
		sentimentMetric = angular.copy(sentimentMetric);
		sentimentMetric.name = 'dScore'; // sentence sentiment value field
		let byNumber = true;
		return this.GroupColorType.types.SENTIMENT_5.getColorFunction(sentimentMetric, byNumber);
	}

	getProjectSentenceFormatters(
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject,
		applicationThemeScope: boolean
	): ng.IPromise<IMetricFormatters> {
		return this.getAssetSentenceFormatters(projectIdentifier, workspaceProject, applicationThemeScope);
	}

	getWidgetSentenceFormatters(
		widget: Widget, applicationThemeScope: boolean
	): ng.IPromise<IMetricFormatters> {
		const projectIdentifier = ProjectIdentifier.fromWidgetProperties(widget.properties);
		return this.getAssetSentenceFormatters(projectIdentifier, widget.properties.workspaceProject, applicationThemeScope);
	}

	getAssetSentenceFormatters(
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject, applicationThemeScope: boolean
	): ng.IPromise<IMetricFormatters> {
		const project = this.getProject(projectIdentifier, workspaceProject);
		const timezoneCall = PromiseUtils.old(this.projectContextService.getProjectTimezone(project));
		const predefinedMetricsCall = PromiseUtils.old(
			this.metricsService.getDynamicPredefinedMetrics(project, applicationThemeScope));
		return this.$q.all([timezoneCall, predefinedMetricsCall]).then(result => {
			const sentimentMetric = _.find(result[1], {name: PredefinedMetricConstants.SENTIMENT});
			const uiOptions: PreviewFormatterUIOptions = {
				backgroundHighlight: false,
			};
			return {
				time: (date: string): string => {
					return this.dateFilterService.formatDocDatePoint(date, result[0], true, DateTimeFormat.BASIC_DATE_TIME);
				},
				date: (date: string): string => {
					return this.dateFilterService.formatDocDatePoint(date, result[0], false, DateTimeFormat.BASIC_DATE);
				},
				ease: this.easeScore(_.find(result[1], {name: PredefinedMetricConstants.EASE_SCORE}), uiOptions),
				emotion: this.emotion(_.find(result[1], {name: PredefinedMetricConstants.EMOTION}), uiOptions),
				sentiment: this.sentimentFormatter(sentimentMetric, uiOptions),
				sentimentColor: this.GroupColorType.types.SENTIMENT_5.getColorFunction(sentimentMetric)
			};
		});
	}

	private getProject(project: IProjectSelection, workspaceProject: WorkspaceProject): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE) && workspaceProject
				? workspaceProject
				: project;
	}
}

app.service('analyticPreviewFormatting', AnalyticPreviewFormatting);
