import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { TimePrimaryGroup, TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { TimeGrouping } from '@cxstudio/reports/groupings/time-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';

export class TimeToFilter implements IPointToFilter {
	constructor(
		private readonly reportAttributesService: ReportAttributesService,
		private	readonly highchartsUtils: HighchartsUtilsService,
		private readonly analyticsDataFormatting: AnalyticsDataFormattingService,
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: TimeGrouping): DrillFilter | null => {
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);
		if (InternalProjectTypes.isStudioAdminProject(widget.properties.project)) {
			const value = point && point[identifier];
			return {
				type: DrillType.TIME,
				name: groupBy.attributeName,
				values: [value, groupBy.trendBy]
			};
		}

		const timeName = TimePrimaryGroups.isWeek(groupBy.timeName) && point.isWeekDescription
			? TimePrimaryGroup.WEEK_WITH_DES
			: groupBy.timeName;

		const dateFilterMode = (point._pop === ReportPeriods.HISTORIC)
							&& widget.properties.dateRangeP2
							&& widget.properties.dateRangeP2.dateFilterMode;

		const metadata = point._pop === ReportPeriods.HISTORIC && widget.properties.widgetType === WidgetType.TABLE
			? point[`_metadata_period_2_${identifier}`]
			: point[`_metadata_${identifier}`];
		if (metadata) {
			const displayName = this.analyticsDataFormatting.formatTimeLabel(metadata.displayName,
				timeName as TimePrimaryGroup, dateFilterMode as DateFilterMode);
			const rawQuery = metadata.rawQuery;
			return {
				type: DrillType.TIME,
				name: groupBy.name,
				values: [displayName, rawQuery]
			};
		}
		return null;
	}

	toFilterTypes = (parsers: DrillParsers): void => {
		[DrillType.TIME]
			.forEach((type) => {
				parsers[type] = this;
			});
	}

	getRuleString = (filter: DrillFilter, widget: Widget): Promise<string | void> => {
		const value = filter.values[0];
		const trendBy = filter.values[1];
		if (InternalProjectTypes.isStudioAdminProject(widget.properties.project)) {
			return this.reportAttributesService.getWidgetAttributes(widget).then(attributes => {
				const attr = _.find(attributes, {name: filter.name});
				const formattedLabel = this.highchartsUtils.formatTrendLabel(value, trendBy);
				return Promise.resolve(`${attr.displayName}: ${formattedLabel}`);
			});
		}
		if (filter.values && filter.values.length === 2)
			return Promise.resolve(value);
		return Promise.resolve();
	}
}

app.service('timeToFilter', TimeToFilter);
