import { ConversationChartOptions } from '../conversation-chart-options.class';
import { ConversationDataPoint } from '../conversation-data-point.class';
import * as _ from 'underscore';

export interface IVerticalChartItem {
	yPosition: number;
	height: number;
}

export abstract class SizingStrategy {
	chartHeight: number;
	config: ConversationChartOptions;

	constructor(chartHeight: number, config: ConversationChartOptions) {
		this.chartHeight = chartHeight;
		this.config = config;
	}

	abstract initialize(dataSet: ConversationDataPoint[]): void;

	abstract getPointFromTimestamp(timestamp: number, dataSet: ConversationDataPoint[]): ConversationDataPoint;

	protected abstract getDatapointHeight(dataPoint: ConversationDataPoint): number;
}