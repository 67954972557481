<div>
	<page-content-header [title]="'templates.templatePageTitle' | i18n" class="mb-24"></page-content-header>
	<prepackaged-templates></prepackaged-templates>
	<div *ngIf="!showNewTemplates" class="bg-secondary p-24 d-flex flex-direction-column fill-container">
		<custom-templates></custom-templates>
	</div>
	<div *ngIf="showNewTemplates" class="bg-secondary p-24 d-flex flex-direction-column fill-container">
		<ul ngbNav #nav="ngbNav" class="nav-tabs">
			<li ngbNavItem>
				<a ngbNavLink>{{'templates.legacyDashboards'|i18n}}</a>
				<ng-template ngbNavContent>
					<custom-templates></custom-templates>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink data-testid="dashboard-templates-tab">{{'object.dashboards'|i18n}}</a>
				<ng-template ngbNavContent>
					<asset-templates-table
						class="fill-container"
						[searchLabel]="'templates.findDashboardTemplates'|i18n"
						[assetType]="TemplateAssetType.DASHBOARD">
					</asset-templates-table>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2 flex-fill tab-content-fill-container"></div>
	</div>
</div>
