import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';

export interface IUpgradeComponent {
	version: string;
	name: string;
	fullName: string;
	optional: boolean;
	owner: string;
	forced?: boolean;
	skipped?: boolean;
}

export interface IUpgradeState {
	currentUpgradeVersion: string;
	applicationVersion: string;
	currentUpgradeSessionId: number;
	upgradeSessions: any[];
}

@Injectable({
	providedIn: 'root'
})

export class UpgradeApiService {

	constructor(
		private cxHttp: CxHttpService,
	) { }

	upgrade = () => {
		return this.cxHttp.post('rest/system_admin/upgrade/from-current', null);
	}

	upgradeFromVersion = (version: string) => {
		return this.cxHttp.post('rest/system_admin/upgrade/from-version/' + version, null);
	}

	upgradeFromVersionPayloaded = (version: string, upgradePayload) => {
		return this.cxHttp.post(`rest/system_admin/upgrade/from-version/${version}/selective`, upgradePayload);
	}

	getUnexecutedComponents = (version: string): Promise<IUpgradeComponent[]> => {
		return this.cxHttp.get('rest/system_admin/upgrade/components/' + version);
	}

	getState = (limit: number): Promise<IUpgradeState> => {
		return this.cxHttp.get('rest/system_admin/upgrade/state/sessions/' + limit);
	}

	rejectComponent = (sessionId: number, componentId: number) => {
		return this.cxHttp.post(`rest/system_admin/upgrade/reject/session/${sessionId}/component/${componentId}`, null);
	}

}
