import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { ListOption } from '@app/shared/components/forms/list-option';
import { CapitalizationService } from '@cxstudio/services/capitalization.service';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';

@Component({
	selector: 'capitalization-settings',
	template: `
<div>
	<label for="capitalization-selector">{{'widget.capitalization'|i18n}}</label>
	<simple-dropdown
		id="capitalization-selector"
		class="w-100-percent"
		[options]="capitalizationOptions"
		[value]="innerValue"
		(valueChange)="onChange($event)"
		[disableSort]="true"
	></simple-dropdown>
</div>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CapitalizationSettingsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapitalizationSettingsComponent extends BaseControlValueAccessor<CapitalizationType> implements OnInit {

	capitalizationOptions: ListOption<CapitalizationType>[];

	constructor(
		ref: ChangeDetectorRef,
		@Inject('capitalization') private capitalization: CapitalizationService,
	) {
		super(ref, CapitalizationType.DEFAULT);
	 }

	ngOnInit(): void {
		this.capitalizationOptions = this.capitalization.getOptions();
	}

}
