import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ReportCalculation } from '../calculations/report-calculation';
import { MetricConstants } from '../constants/metric-constants.service';
import { IWidgetSettingsScope } from '../services/cb-settings-service.service';
import { DualAxisTypes } from './dual-axis-types';


export interface ISpecialGrouping {
	name: string;
	displayName: string;
	hidden: boolean;
}

export abstract class RealDataPreviewWidget implements ng.IController {

	abstract widgetType: WidgetType;
	protected periodOverPeriodItem?: ISpecialGrouping;
	protected constants;
	readonly METRIC_PROPS = ['yAxis', 'secondaryYAxis', 'size', 'color', 'secondaryColor',
		'secondaryPointColor', 'sortBy', 'secondarySize'];
	readonly ATTRIBUTE_PROPS = ['primaryGroup', 'secondaryGroup', 'stackedGroup'];

	constructor(
		protected $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected realDataPreviewService: RealDataPreviewService,
		protected metricConstants: MetricConstants,
		protected reportSettingsService: ReportSettingsService,
		protected defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected colorUtils: ColorUtils
	) { }

	$onInit(): void {
		this.constants = this.metricConstants.get();

		// for new Line widget we auto populate grouping so initial data will be set later
		if (!DualAxisTypes.isAnyLine(this.$scope.props) || this.reportSettingsService.hasPrimaryGrouping(this.$scope.visualProps)) {
			let initialWidgetProps = ObjectUtils.copy(this.$scope.props);
			this.realDataPreviewService.setPrevWidgetProps(initialWidgetProps);
		}

		this.$scope.onPeriodChange = this.onPeriodChange;
		this.$scope.onNormalizeGroupsChange = this.onNormalizeGroupsChange;

		if (this.realDataPreviewService.showRealDataPreview(this.widgetType)) {
			this.realDataPreviewService.setPreviewHasChanges(false);
		}
	}

	onPeriodChange = (): void => {
		this.$scope.updateChartSettings();
	}

	onNormalizeGroupsChange = (): void => {
		if (this.realDataPreviewService.showRealDataPreview(this.widgetType)) {
			this.checkPreviewDataAndApplyVisualChanges(PreviewChartRefreshType.DATA_REQUEST);
		} else {
			this.$scope.updateChartSettings();
		}
	}

	getRefreshTypeForCalculationUpdate = (node): PreviewChartRefreshType => {
		let isRemoval: boolean = node.name === '';

		return this.realDataPreviewService.showRealDataPreview(this.widgetType) && !isRemoval
			? PreviewChartRefreshType.DATA_REQUEST
			: PreviewChartRefreshType.UI_ONLY;
	}

	/**
	 * Retrieve calculation settings and apply to property [name]
	 */
	setCalculationProperty = (name: string, node): ng.IPromise<void> => {
		return PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.$scope.props, node)).then((customSettings) => {
			let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(node, this.$scope.options.studioMetrics);
			_.extend(node, defaultSettings, customSettings);
			this.postSetProperty(name, node);
			return node;
		});
	}

	/**
	 * Apply changes to widget object
	 */
	protected postSetProperty = (name, node): void => {
		let selectedNode = this.metricCustomFormatUtils.initCalculationFormat({ ...node });

		this.$scope.visualProps[name] = selectedNode.name;
		this.$scope.visualProps.attributeSelections[name] = selectedNode;
	}

	checkPreviewDataAndApplyVisualChanges = (refreshType?: PreviewChartRefreshType) => {
		let dataRequestRequired: boolean = refreshType === PreviewChartRefreshType.DATA_REQUEST;
		let selectedAttributes: AttributeGrouping[] = this.$scope.widget?.properties?.selectedAttributes;
		let shouldCheckChanges = this.realDataPreviewService.showRealDataPreview(this.widgetType) && selectedAttributes?.length > 0;
		if (shouldCheckChanges && dataRequestRequired) {
			this.realDataPreviewService.checkChangesForPreview(this.$scope.widget);
		}

		this.updateVisualPreviewData();

		if (!this.$scope.props.isCustomTitle && this.$scope.updateAutoTitle) {
			this.$scope.updateAutoTitle();
		}

		if (this.$scope.staticData?.data?.data || this.realDataPreviewService.showRealDataPreview(this.widgetType)) {
			this.$scope.updateChartSettings(refreshType);
		}
	}

	updateVisualPreviewData = () => {
		if (!this.$scope.staticData?.data?.data) return;

		if (!this.$scope.staticData.ui) {
			this.$scope.staticData.ui = {};
		}
		this.$scope.staticData.ui.data = ObjectUtils.copy(this.$scope.staticData.data.data);
		this.$scope.staticData.ui.data.forEach((row) => {
			let groupings = [];

			if (this.$scope.props.selectedAttributes) {
				groupings = GroupIdentifierHelper.getGroupings(this.$scope.props.selectedAttributes);
			}

			let index = 0;
			groupings.forEach((group) => {
				row[group.identifier] = row['group' + (index % groupings.length + 1)]; // group1, group2, ... groupN
				index++;
			});

			let popItemName = this.periodOverPeriodItem?.name;

			if (popItemName && this.$scope.visualProps.secondaryGroup === popItemName
				&& this.$scope.props.widgetType !== WidgetType.BAR) {
				row[popItemName] = row.group2 !== 'b' ? ReportPeriods.CURRENT : ReportPeriods.HISTORIC;
			}

			if (this.$scope.props.selectedMetrics) {
				this.$scope.props.selectedMetrics.forEach((metric) => {
					let name = metric.name;
					if (this.$scope.setProperMetricValue && name !== this.constants.CUMULATIVE_PERCENT_TOTAL.name) {
						this.$scope.setProperMetricValue(row, name);
					}
				});
			}
		});
	}

	getAllRequiredMetrics = (visualProps: VisualProperties, options: IWidgetSettingsScope['options']) => {
		return _.chain(this.METRIC_PROPS)
			.map((field) => visualProps.attributeSelections[field])
			.union(visualProps.calculationSeries)
			.union(_.values(this.getColoringMetrics(visualProps, options)))
			.filter((metric) => metric?.name)
			.uniq((metric) => metric.name)
			.value();
	}

	getColoringMetrics(visualProps: VisualProperties, options: IWidgetSettingsScope['options']): ReportCalculation[] {
		let metrics = [].concat(options.studioMetrics || []).concat(options.predefinedMetrics || []);
		return this.colorUtils.getColoringMetrics(visualProps, metrics);
	}


	getAllRequiredAttributes = (visualProps: VisualProperties, selectedAttributes = [], ignoredAttributes = []) => {
		const baseIgnoredAttributes = [this.constants.NONE.name, this.constants.CONSTANT_SIZE.name];
		let allIgnoredAttributes = ignoredAttributes.concat(baseIgnoredAttributes);

		return _.chain(this.ATTRIBUTE_PROPS)
			.map((field) => visualProps.attributeSelections[field])
			.filter((metric) => {
				return metric && allIgnoredAttributes.indexOf(metric.name) === -1;
			})
			.map(metric => {
				if (selectedAttributes?.length > 1) {
					metric.subtopicsOnly = _.some(selectedAttributes, attr => attr.subtopicsOnly !== false);
				}
				return metric;
			})
			.value();
	}
}
