import { Verbatim } from '../entities/verbatim';

export interface AuditSuggestion {
	sentenceId: number;
	sentenceText: string;
	verbatimId: number;
	verbatimText: string;
	tokenText?: string;
	enrichment: AuditSuggestionEnrichment;
	currentValue: string;
	newValue: AuditSuggestionValue;
	verbatim: Verbatim;
	currentOption?;
	newOption?;
}

export enum AuditSuggestionEnrichment {
	EFFORT = 'easeScore',
	TOPIC = 'topic',
	EMOTION = 'emotion',
	SENTIMENT = 'sentiment'
}

export enum AuditSuggestionValue {
	STRONG_NEGATIVE = 'Neg_Strong_Sent',
	NEGATIVE = 'Neg_Sent',
	NEUTRAL = 'Neutral_Sent',
	POSITIVE = 'Positive_Sent',
	STRONG_POSITIVE = 'Positive_Strong_Sent'
}
