<modal-header
	(cancel)="dismiss()"
	[modalTitle]="'drivers.driverResultsTitle' | i18n">
</modal-header>
<div class="modal-body"
	[ngBusy]="loading">

	<ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
		<li ngbNavItem>
			<a ngbNavLink role="tab">{{'drivers.driversName'|i18n}}</a>
			<ng-template ngbNavContent>
				<drivers-results-tab
					*ngIf="reportDrivers"
					[driversItem]="driversItem"
					[reportDrivers]="reportDrivers"
					[predictiveDrivers]="predictiveDrivers"
					(hideDriversItem)="onDriversItemHide($event)">
			</drivers-results-tab>
			</ng-template>
		</li>
		<li *ngIf="showMetrics()" ngbNavItem>
			<a ngbNavLink>{{'drivers.performanceTab'|i18n}}</a>
			<ng-template ngbNavContent>
				<drivers-metrics-tab
					[modelMetrics]="modelMetrics"
				></drivers-metrics-tab>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink role="tab">{{'drivers.statsTab'|i18n}}</a>
			<ng-template ngbNavContent>
				<textarea
					class="stats-text-area"
					[(ngModel)]="jsonData">
				</textarea>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink role="tab">{{'administration.hidden'|i18n}}</a>
			<ng-template ngbNavContent>
				<drivers-hidden-tab
					[driversItem]="driversItem"
					[hiddenDrivers]="hiddenDrivers"
					(unhideDriversItem)="onDriversItemUnhide($event)">
				</drivers-hidden-tab>
			</ng-template>
		</li>
	</ul>
	<section [ngbNavOutlet]="nav"></section>
</div>
<ok-modal-footer
	*ngIf="!hasChanges()"
	(cancel)="dismiss()"
	[okText]="'common.close' | i18n"
></ok-modal-footer>
<save-modal-footer
	*ngIf="hasChanges()"
	(save)="close()"
	(cancel)="dismiss()"
	[isDisabled]="!predictiveDrivers"
></save-modal-footer>
