import { Injectable } from '@angular/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class MetricValidationApiService {
	constructor(
		private readonly http: CxHttpService,
	) {}

	validateName(project: WorkspaceProject, name: string): AssetPromisePlatformProject<boolean> {
		const url = `rest/manage/ws/${project.workspaceId}/project/${project.projectId}/metrics/validation/name`;
		return this.http.post(url, name) as AssetPromisePlatformProject<boolean>;
	}
}
