import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { ReportScorecardFiltersService } from '@app/modules/scorecards/filters/report-scorecard-filters.service';

@Injectable({
	providedIn: 'root'
})
export class MetadataPreloaderService {
	readonly PRELOAD_LIMIT = 3;

	private queue: Array<() => any>;
	private started = false; // only preload on initial studio load

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		private readonly reportAttributesService: ReportAttributesService,
		private readonly reportModelsService: ReportModelsService,
		private readonly reportMetricsService: ReportMetricsService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly reportScorecardFiltersService: ReportScorecardFiltersService,
		private readonly reportProjectContextService: ReportProjectContextService,
		private readonly reportSettingsService: ReportSettingsService,
		@Inject('colorPalettes') private colorPalettes: ColorPalettes,
	) {
		this.queue = [];
	}

	startPreloading(dashboards: Dashboard[]): void {
		if (this.started)
			return;
		this.getRecentDashboards(dashboards).forEach(dashboard => {
			this.queue.pushAll(this.getLoadingSteps(dashboard));
		});
		this.started = true;
		this.processQueue();
	}

	private getRecentDashboards(dashboards: Dashboard[]): Dashboard[] {
		return _.chain(dashboards)
			.filter(dashboard => !!dashboard.useDate && dashboard.type === DashboardType.DASHBOARD)
			.filter(dashboard => ProjectIdentifier.isProjectSelected(ProjectIdentifier.fromDashboardProperties(dashboard.properties)))
			.sortBy('useDate')
			.reverse()
			.slice(0, this.PRELOAD_LIMIT)
			.value();
	}

	stopPreloading(): void {
		this.queue = [];
	}

	private processQueue(): void {
		let fn = this.queue.shift();
		if (fn) {
			PromiseUtils.wrap(fn()).then(
				() => this.processQueue(),
				() => this.processQueue()
			);
		}
	}

	private getLoadingSteps(dashboard: Dashboard): Array<() => Promise<any>> {
		return [
			// first - load data for widget utils, as it's called first
			() => this.getAttributes(dashboard),
			() => this.getMetrics(dashboard),
			() => this.getPalettes(dashboard),
			() => this.getProjectTimezone(dashboard),
			// filters are required for validation and called just before report data
			// also required by dashboard filters
			() => this.getStudioFilters(dashboard),
			() => this.getCmpFilters(dashboard),
			() => this.getScorecardFilters(dashboard),
			// models and project settings are required for dashboard filters, so widgets can load without them
			() => this.getModels(dashboard),
			() => this.getProjectSettings(dashboard),
		];
	}

	private getAttributes(dashboard: Dashboard) {
		const projectId = dashboard.properties.project;
		if (InternalProjectTypes.isAdminProject(projectId)) {
			return null;
		} else {
			return this.reportAttributesService.getDashboardAttributesOrEmpty(dashboard);
		}
	}

	private getMetrics(dashboard: Dashboard) {
		const projectId = dashboard.properties.project;
		if (InternalProjectTypes.isAdminProject(projectId)) {
			return null;
		}

		let metricsPromise = this.reportMetricsService.getDashboardMetrics(dashboard);
		let predefinedMetricsPromise = this.reportMetricsService.getDashboardDynamicPredefinedMetrics(dashboard, false);

		return Promise.all([metricsPromise, predefinedMetricsPromise]);
	}

	private getPalettes(dashboard: Dashboard) {
		let designerPalettePromise = this.reportProjectContextService.getDashboardDesignerPalette(dashboard);
		return Promise.all([
			PromiseUtils.wrap(this.colorPalettes.getWidgetPalettes()),
			designerPalettePromise
		]);
	}

	private getProjectTimezone(dashboard: Dashboard) {
		return this.reportProjectContextService.getDashboardProjectTimezone(dashboard);
	}

	private getModels(dashboard: Dashboard) {
		return this.reportModelsService.getDashboardModels(dashboard);
	}

	private getProjectSettings(dashboard: Dashboard) {
		return this.reportSettingsService.getDashboardProjectSettings(dashboard);
	}

	private getStudioFilters(dashboard: Dashboard) {
		return this.reportFiltersService.getDashboardStudioFilters(dashboard);
	}

	private getCmpFilters(dashboard: Dashboard) {
		const projectId = dashboard.properties.project;
		if (InternalProjectTypes.isAdminProject(projectId))
			return null;
		return this.reportFiltersService.getDashboardPlatformFilters(dashboard);
	}

	private getScorecardFilters(dashboard: Dashboard) {
		const projectId = dashboard.properties.project;
		if (InternalProjectTypes.isAdminProject(projectId)
			|| !this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING))
			return null;
		return this.reportScorecardFiltersService.getDashboardScorecardFilters(dashboard);
	}
}

app.service('metadataPreloaderService', downgradeInjectable(MetadataPreloaderService));
