import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { CxWizardComponent } from '@app/modules/wizard/cx-wizard/cx-wizard.component';
import { SharedModule } from '@app/shared/shared.module';
import { SidebarWizardComponent } from './sidebar-wizard/sidebar-wizard.component';
import { WizardTagComponent } from './cx-wizard-tag/cx-wizard-tag.component';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		FormsModule,
	],
	declarations: [
		CxWizardComponent,
		CxWizardStepComponent,
		SidebarWizardComponent,
		WizardTagComponent,
	],
	exports: [
		CxWizardComponent,
		CxWizardStepComponent,
		SidebarWizardComponent,
	],
})
export class WizardModule { }
