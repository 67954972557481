import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { JobsService } from './jobs.service';
import { JobRequestParams } from './jobs.api.service';
import { JobRunStatus } from '@app/modules/system-administration/status/schedules-status/entities/job-run-status';
import { JobRun } from '@app/modules/system-administration/status/schedules-status/entities/job-run';
import { ScheduledJob } from '@app/modules/system-administration/status/schedules-status/entities/scheduled-job';
import { OptionalJob } from '@app/modules/system-administration/status/schedules-status/entities/optional-job';
import { JobSet } from '@app/modules/system-administration/status/schedules-status/entities/job-set';
import { Pagination } from '@app/shared/components/pagination/pagination';

interface JobsState<T> {
	columns: Array<TableColumn<T>> ;
	data: T[];
	pagination: Pagination;
	groups?: any[];
	group?: string;
}

@Component({
	selector: 'schedules-status-tab',
	templateUrl: './schedules-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulesStatusTabComponent implements OnInit {
	readonly JOBS_PER_PAGE: number = 10;

	scheduledJobs: JobsState<ScheduledJob>;
	completedJobs: JobsState<JobRun>;
	optionalJobs: JobsState<OptionalJob>;

	loading: Promise<any>;

	constructor(
		private ref: ChangeDetectorRef,
		private jobsService: JobsService,
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService
	) {}

	ngOnInit(): void {
		this.scheduledJobs = {
			columns: this.getScheduledJobsColumns(),
			pagination: new Pagination(this.JOBS_PER_PAGE),
			data: []
		};
		this.completedJobs = {
			columns: this.getCompletedJobsColumns(),
			pagination: new Pagination(this.JOBS_PER_PAGE),
			data: []
		};
		this.optionalJobs = {
			columns: this.getOptionalJobsColumns(),
			pagination: new Pagination(this.JOBS_PER_PAGE),
			data: []
		};

		const isInit = true;
		this.loading = Promise.all([
			this.refreshScheduledJobs(isInit),
			this.refreshCompletedJobs(isInit),
			this.refreshOptionalJobs()
		]);
	}

	refreshScheduledJobs = (isInit: boolean = false) => {
		let queryParams: JobRequestParams = {
			pagination: this.scheduledJobs.pagination
		};

		if (!isInit && this.scheduledJobs.group)
			queryParams.group = this.scheduledJobs.group;

		let jobs = this.jobsService.getJobData(JobSet.SCHEDULED, queryParams);
		let groups = this.jobsService.getGroups(JobSet.SCHEDULED);

		return Promise.all([jobs, groups]).then((results) => {
			this.scheduledJobs.data = results[0].jobs;
			this.scheduledJobs.pagination.totalItems = results[0].total;
			this.scheduledJobs.groups = results[1];
			if (this.scheduledJobs.group  === undefined) {
				this.scheduledJobs.group = this.scheduledJobs.groups[0].value;
			}
			this.ref.markForCheck();
		});
	}

	scheduledJobsPageChanged = (pagination: Pagination): void => {
		this.scheduledJobs.pagination = pagination;
		this.refreshScheduledJobs();
	}

	refreshCompletedJobs = (isInit: boolean = false) => {
		let queryParams: JobRequestParams = {
			pagination: this.completedJobs.pagination
		};

		if (!isInit && this.completedJobs.group)
			queryParams.group = this.completedJobs.group;

		let jobs = this.jobsService.getJobData(JobSet.JOB_RUN, queryParams);
		let groups = this.jobsService.getGroups(JobSet.JOB_RUN);

		return Promise.all([jobs, groups]).then((results) => {
			this.completedJobs.data = results[0].jobs;
			this.completedJobs.pagination.totalItems = results[0].total;
			this.completedJobs.groups = results[1];
			if (this.completedJobs.group === undefined) {
				this.completedJobs.group = this.completedJobs.groups[0].value;
			}
			this.ref.markForCheck();
		});
	}

	completedJobsPageChanged = (pagination: Pagination): void => {
		this.completedJobs.pagination = pagination;
		this.refreshCompletedJobs();
	}

	refreshOptionalJobs = () => {
		return this.jobsService.getJobData(JobSet.OPTIONAL).then((response) => {
			this.optionalJobs.data = response.jobs;
			this.ref.markForCheck();
		});
	}

	private getScheduledJobsColumns = (): Array<TableColumn<ScheduledJob>> => {
		return [{
			name: 'triggerName',
			displayName: this.locale.getString('administration.description'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.35
		}, {
			name: 'runNow',
			formatter: (job) => job.allowInstantRun
				? `<span class="q-icon-triangle-right btn btn-secondary"
						title="${this.locale.getString('schedule.runNow')}"></span>`
				: '',
			action: this.runNow,
			width: 0.05
		}, {
			name: 'lastModifiedDate',
			displayName: this.locale.getString('schedule.lastModifiedDate'),
			formatter: this.tableFormattersService.dateWithTZFormatter,
			width: 0.2
		}, {
			name: 'previousRun',
			displayName: this.locale.getString('schedule.timeToRun'),
			formatter: this.tableFormattersService.dateWithTZFormatter,
			width: 0.2
		}, {
			name: 'nextRun',
			displayName: this.locale.getString('schedule.nextRun'),
			formatter: this.tableFormattersService.dateWithTZFormatter,
			width: 0.2
		}];
	}

	private getCompletedJobsColumns = (): Array<TableColumn<JobRun>> => {
		const ms = this.locale.getString('common.millisecondsPostfix');
		return [{
			name: 'identity',
			displayName: this.locale.getString('administration.description'),
			formatter: (completedJob) => completedJob.identity.key,
			width: 0.35
		}, {
			name: 'details',
			displayName: this.locale.getString('administration.details'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.2
		}, {
			name: 'fireTime',
			displayName: this.locale.getString('schedule.fireTime'),
			formatter: this.tableFormattersService.dateWithTZFormatter,
			width: 0.2
		}, {
			name: 'timeToRun',
			displayName: this.locale.getString('schedule.lastRun'),
			formatter: (completedJob) => `${completedJob.timeToRun}  ${ms}`,
			width: 0.05
		}, {
			name: 'jobStatus',
			displayName: this.locale.getString('schedule.result'),
			formatter: this.jobStatusFormatter,
			width: 0.1
		}];
	}

	private getOptionalJobsColumns = (): Array<TableColumn<any>> => {
		let optionalJobColumns = [{
			name: 'description',
			displayName: this.locale.getString('administration.description'),
			formatter: (optionalJob) => optionalJob.identity.key,
			width: 0.35
		}, {
			name: 'action',
			displayName: this.locale.getString('schedule.action'),
			formatter: (optionalJob) => this.optionalJobActionsFormatter(optionalJob),
			action: (optionalJob) => optionalJob.scheduled ? this.unscheduleOptionalJob(optionalJob) : this.scheduleOptionalJob(optionalJob),
			width: 0.05
		}, {
			name: 'runNow',
			displayName: this.locale.getString('schedule.runNow'),
			formatter: (optionalJob) => this.optionalJobRunNowFormatter(optionalJob),
			action: (optionalJob) => this.runOptionalJobNow(optionalJob),
			width: 0.05
		}, {
			name: 'status',
			displayName: this.locale.getString('common.status'),
			formatter: this.optionalJobStatusFormatter,
			width: 0.05
		}];

		return optionalJobColumns;
	}

	private jobStatusFormatter = (jobRun: JobRun): string => {
		let statusClassMap: {[key in JobRunStatus]: string} = {
			IN_PROGRESS: 'alert-info',
			COMPLETED: 'alert-success',
			FAILED: 'alert-danger'
		};
		const status = jobRun.jobStatus;
		const alertClass = statusClassMap[status];
		return `<span class="alert ${alertClass} p-8 mb-0"><b>${status}</b></span>`;
	}

	private optionalJobStatusFormatter = (optionalJob): string => {
		const status = optionalJob.scheduled ? this.locale.getString('schedule.scheduled') : this.locale.getString('schedule.notScheduled');
		const alertClass = optionalJob.scheduled ? 'alert-success' : 'alert-info';

		return `<span class="alert ${alertClass} p-8 mb-0"><b>${status}</b></span>`;
	}

	private optionalJobActionsFormatter = (optionalJob): string => {
		if (optionalJob.scheduled) {
			return `<span class="q-icon q-icon-square btn btn-danger" title="${this.locale.getString('schedule.unschedule')}"></span>`;
		} else {
			return `<span class="q-icon-triangle-right btn btn-success" title="${this.locale.getString('schedule.schedule')}"></span>`;
		}
	}

	private optionalJobRunNowFormatter = (optionalJob): string => {
		const disabled = optionalJob.scheduled ? '' : 'disabled';
		return `<span class="q-icon-triangle-right btn btn-secondary ${disabled}"
				title="${this.locale.getString('schedule.runNow')}"></span>`;
	}

	private runNow = (job: ScheduledJob) => {
		if (job.allowInstantRun) {
			this.jobsService.runNow(job);
			job.allowInstantRun = false;
		}
	}

	private scheduleOptionalJob = (job) => {
		this.jobsService.scheduleOptionalJob(job).then(() => {
			job.scheduled = !job.scheduled;
			this.updateOptionalJobsData(job);
		});
	}

	private unscheduleOptionalJob = (job) => {
		this.jobsService.unscheduleOptionalJob(job).then(() => {
			job.scheduled = !job.scheduled;
			this.updateOptionalJobsData(job);
		});
	}

	private runOptionalJobNow = (job) => {
		if (!job.scheduled)
			return;
		this.jobsService.runOptionalJobNow(job);
	}

	private updateOptionalJobsData = (job) => {
		this.optionalJobs.data = _.reject(this.optionalJobs.data, (optionalJob) => {
			return optionalJob.identity === job.identity;
		});

		this.optionalJobs.data.push(job);
		this.ref.markForCheck();
	}
}
