import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
	selector: '[strictMax]',
	providers: [{provide: NG_VALIDATORS, useExisting: StrictMaxDirective, multi: true}]
})
export class StrictMaxDirective implements Validator {
	@Input() strictMax: number;

	private onChange: () => void;

	validate(control: AbstractControl): ValidationErrors | null {
		let errors: ValidationErrors = Validators.max(this.strictMax)(control);

		if (errors !== null) {
			control.setValue(this.strictMax);
		}
		
		// never highlight with red
		return null;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onChange = fn;
	}
}
