import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { BookTabData } from '@app/modules/book/book-tab-data';
import { EnvironmentService } from '@cxstudio/services/environment-service';

@Injectable({
	providedIn: 'root'
})
export class BookApiService {

	constructor(
		private readonly http: CxHttpService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) { }

	getBookData(bookId: number, params: HttpParams): Promise<BookTabData[]> {
		let options = {
			headers: {} as any,
			params
		};
		if (!!this.environmentService.getPdfToken()) {
			options.headers = { pdf: this.environmentService.getPdfToken() };
		}
		return this.http.get('rest/widgets/book/' + bookId, options);
	}
}
