import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

declare let app: angular.IModule;

@Component({
	selector: 'dashboard-schedule-tooltip',
	template: `
<div id="schedule-tooltip" role="tooltip" class="br-dash-tooltip text-center">
	<div class="br-dash-preview">
		<p>
			<b *ngIf="dashboard.scheduleCount > 1"
				[i18n]="'dashboard.scheduledActions'" 
				[i18nParams]="{count: dashboard.scheduleCount}"></b>
		</p>
		<p>
			<b *ngIf="dashboard.scheduleCount === 1" [i18n]="'dashboard.scheduledAction'"></b>
		</p>
		<div class="br-arrow"></div>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardScheduleTooltipComponent {
	@Input() dashboard: Dashboard;
}