import { ConversationChartOptions } from './conversation-chart-options.class';
import { EnrichmentIconRenderer } from './shapes/enrichment-icon-renderer.class';
import { SecondaryTrackConfig } from './shapes/secondary-track-config.class';
import { IChartSVG } from '@cxstudio/conversation/conversation-chart.class';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';

export class SecondaryMetricRenderer {

	static METRIC_STROKE_COLOR: string = 'white';
	static SEPARATOR_STROKE_WIDTH: number = 1;
	static SEPARATOR_STROKE_COLOR: string = '#ebebee';
	static TRACK_WIDTH: number = 21;

	static render(
		dataSet: ConversationDataPoint[],
		config: ConversationChartOptions,
		rootSvg: IChartSVG,
		trackConfig: SecondaryTrackConfig,
		trackIndex: number): void {

		let leftBorderX = config.basicChart.width - config.basicChart.padding + (trackIndex - 1) * this.TRACK_WIDTH;

		trackConfig.leftBoundary = leftBorderX;

		let filteredData = dataSet.filter(trackConfig.dataFilter);

		if (!filteredData.length && config.hideEmptyTracks) return;

		let metricChart = rootSvg.append('g')
			.attr('id', `spine-secondary-metric__${trackIndex}`)
			.attr('class', ConversationStyleUtils.getSecondaryTrackRootClass(trackIndex));
		metricChart.append('style')
			.text(ConversationStyleUtils.getSecondaryTrackStyle(trackConfig.enrichment.getColorArray(), trackIndex, config.theme));

		EnrichmentIconRenderer.drawPoints(filteredData, trackConfig, metricChart);
	}
}
