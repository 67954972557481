<ng-container *ngIf="parent">
	<div *ngIf="parent.useChips"
		class="multiselect-chips chips-input p-4"
		(click)="toggleDropdown()"
		[attr.disabled]="isDisabled">
		<div class="d-flex flex-multiline l-margin-offset-3">
			<span class="chip cursor-pointer" *ngFor="let chip of getChips()">
				<i  class="mr-4" attr.aria-label="{{'common.remove'|i18n}}"
					(click)="remove(chip, $event)">&times;</i> {{chip.displayName}}</span>
			<input
				#toggleInput
				class="pv-2 ph-0 no-border text-default outset-focus-indicator"
				(click)="checkInputClick($event)"
				[(ngModel)]="parent.filterText"
				[debounce]="300"
				(debouncedChange)="checkInputChange(); parent.updateSearch.emit({$search: parent.filterText})">
		</div>
	</div>
	<button *ngIf="!parent.useChips"
		#toggleButton
		id="multiselect-dropdown-button"
		class="kb-focusable-inset dropdown-toggle"
		(click)="toggleDropdown()"
		title="{{getInputText()}}"
		[disabled]="isDisabled">
		<span class="button-text">{{getInputText()}}</span>
		<span class="caret" aria-hidden="true"></span>
	</button>
</ng-container>
