import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export interface IActionsMenuItem<T> {
	name: string;
	text?: string;
	onClick: (obj: T, event: Event) => void;
	isDivider?: boolean;
	open?: boolean;
	items?: IActionsMenuItem<T>[];
	disabled?: boolean;
	component?: boolean;
}
@Component({
	selector: 'actions-menu',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './actions-menu.component.html'
})
export class ActionsMenuComponent {

	@Input() menuItems: IActionsMenuItem<any>[];
	@Input() dashboard: Dashboard;
	@Input() zoom?: any;
	@Input() widgetMovementOption?: any;
	@Output() onWidgetMovementChange = new EventEmitter<any>();
	@ViewChild('ngDropdownToggle', {static: false}) dropdownToggle: ElementRef;
	@ViewChild('ngDropdown', {static: false}) dropdown: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;
	private readonly SUBMENU_OPTION_CLASS = 'submenu-option';
	private readonly SUBMENU_CLASS = 'has-submenu';

	constructor(
		private locale: CxLocaleService
	) { }

	isOpen = (): boolean => {
		return this.ngDropdown?.isOpen();
	}

	getAriaLabel = (): string => {
		return this.locale.getString('dashboard.actionsMenuLabel', { name: this.dashboard?.name });
	}

	widgetMovementChange = (option: any): void => {
		this.onWidgetMovementChange.emit(option);
	}

	onItemClick = (menuItem: any, event: Event): void => {
		if (menuItem.items) {
			menuItem.open = !menuItem.open;
		}

		menuItem.onClick(this.dashboard, event);
		if (!event.cancelBubble) {
			this.closeOnClick(event);
		}
	}

	closeOnClick = (event: any): void => {
		event.preventDefault();
		event.stopPropagation();
		this.ngDropdown.close();
	}

	private getFocusableMenuItems(): JQuery {
		return $(this.dropdown.nativeElement).find('ul :focusable:not(.disabled)');
	}

	private focusDropdownOnTab = (event: any) => {
		if (KeyboardUtils.isEventKey(event, Key.TAB) || KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			let focusableItems = this.getFocusableMenuItems();
			if (focusableItems.index($(document.activeElement as HTMLElement)) === -1) {
				event.preventDefault();
				event.stopPropagation();
				focusableItems.first().trigger('focus');
			}
		}
	}

	onMenuToggle = (open: boolean): void => {
		if (open) {
			$(window).on('keydown', this.focusDropdownOnTab);
		} else {
			$(window).off('keydown', this.focusDropdownOnTab);
			this.menuItems.forEach((item) => {
				if (item.open) {
					item.open = false;
				}
			});
		}
	}

	onMenuToggleKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			setTimeout(() => {
				this.getFocusableMenuItems().first().trigger('focus');
			});
		}
	}

	// CB-8922 - close on tab, close submenu on left arrow, open submenu on right arrow
	onMenuKeydown = (menuItem: any, event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) ||
				KeyboardUtils.isEventKey(event, Key.TAB) ||
				KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			event.preventDefault();
			this.ngDropdown.close();
			this.dropdownToggle.nativeElement.focus();
			return;
		}

		if (_.contains([Key.DOWN, Key.UP, Key.PAGEDOWN, Key.PAGEUP, Key.LEFT, Key.RIGHT], event.key)) {
			event.stopPropagation();
			event.preventDefault();

			let focusableItems = this.getFocusableMenuItems();
			let focusedMenuItemIndex = focusableItems.index($(document.activeElement as HTMLElement));

			if (KeyboardUtils.isEventKey(event, Key.RIGHT)
					&& menuItem.items && !menuItem.open
					&& focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_CLASS)) {
				menuItem.open = true;
				return;
			}

			if (KeyboardUtils.isEventKey(event, Key.LEFT) && menuItem.items && menuItem.open) {
				if (focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_OPTION_CLASS)) {
					let submenuToggle = focusableItems.eq(focusedMenuItemIndex).closest('.dropdown-submenu').find(':focusable').first();
					submenuToggle.trigger('focus');
					focusedMenuItemIndex = focusableItems.index(submenuToggle);
					menuItem.open = false;
				} else if (focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_CLASS)) {
					menuItem.open = false;
				}
				return;
			}

			if (KeyboardUtils.isEventKey(event, Key.PAGEDOWN))
				focusedMenuItemIndex = focusableItems.length - 1;

			if (KeyboardUtils.isEventKey(event, Key.PAGEUP))
				focusedMenuItemIndex = 0;

			if (KeyboardUtils.isEventKey(event, Key.DOWN) && focusedMenuItemIndex < focusableItems.length - 1) {
				focusedMenuItemIndex++;
			}

			if (KeyboardUtils.isEventKey(event, Key.UP) && focusedMenuItemIndex > 0) {
				focusedMenuItemIndex--;
			}

			(focusableItems.get(focusedMenuItemIndex) as HTMLElement).focus();
		}
	}

}

app.directive('actionsMenu', downgradeComponent({component: ActionsMenuComponent}) as angular.IDirectiveFactory);
