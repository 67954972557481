import { ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { IChartSVG } from '@cxstudio/conversation/conversation-chart.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { SentenceScorecardTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackConfig } from './secondary-track-config.class';

export class ScorecardIconGenerator {
	private static readonly MAX_ICONS = 3;
	private static readonly SPACING = 1 / 6;
	private static readonly ICON_SIZE = 10;

	static draw(dataArray: ConversationDataPoint[], shapeConfig: SecondaryTrackConfig, targetSvg: IChartSVG): void {

		let spaceX = shapeConfig.trackWidth * this.SPACING; // space between left edge of items, elon approved this

		targetSvg.selectAll('g')
			.data(dataArray)
			.enter()
			.append('g')
			.attr('class', shapeConfig.getPointClass)
			.attr('transform', data => {
				let x = shapeConfig.leftBoundary + this.getXOffset(shapeConfig.enrichment.getValue(data).length, shapeConfig.trackWidth);
				return `translate(${x},${data.yPosition + data.height + 0.5})`; // with 0.5 it looks more centered
			})
			.on('mouseover', shapeConfig.mouseover)
			.on('mouseout', shapeConfig.mouseout)
			.on('click', shapeConfig.click)
			.selectAll('text')
			.data(data => this.getFirstElementsReversed(shapeConfig.enrichment.getValue(data)))
			.enter()
			.append('text')
			.attr('x', (topic, index) => -index * spaceX) // move in back direction
			.html((topic: SentenceScorecardTopic) => String.fromCodePoint(ScorecardDocExplorerUtils.getTopicIconCode(topic)))
			.style('font-family', 'var(--qualtrics-icons)')
			.attr('font-size', this.ICON_SIZE)
			.style('stroke-width', 0.5)
			.attr('class', (topic: SentenceScorecardTopic) =>
				ConversationStyleUtils.getSecondaryTrackClass(ScorecardDocExplorerUtils.getColorIndex(topic)));
	}

	private static getXOffset(topicsCount: number, trackWidth: number): number {
		if (topicsCount > this.MAX_ICONS)
			topicsCount = this.MAX_ICONS;
		let fontSize = this.ICON_SIZE;
		let spaceX = trackWidth * this.SPACING;
		let initialOffset = (topicsCount - 1) * spaceX;
		let iconsWidth = fontSize + initialOffset;
		return (trackWidth - iconsWidth) / 2 + initialOffset; // items renders from right to left, so need initialOffset

	}

	private static getFirstElementsReversed(topics: SentenceScorecardTopic[]): SentenceScorecardTopic[] {
		let topTopics = _.sortBy(topics, 'weight').reverse();
		return _.first(topTopics, ScorecardIconGenerator.MAX_ICONS).reverse();
	}
}
