import { PasswordPolicy } from '@app/modules/account-administration/password-policy/entities/password-policy';
import { MasterAccountId, DashboardId } from '@cxstudio/generic-types';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { IPromise } from 'angular';

export interface TokenResponse {
	token: string;
}

export interface AccessTokenResponse extends TokenResponse {
	expiresIn?: number;
	samlSession: boolean;
	externalOAuthSession: boolean;
	studioStartupAppUrl?: string;
	userEmail?: string;
}

export interface SSOTokenLoginResponse extends TokenResponse {
	accessToken?: string;
	idpLandingPage?: string; //Studio SAML
}

export interface ForcedRedirectResponse {
	url: string;
}

export interface BasicPasswordSettings {
	oldPassword: string;
	newPassword: string;
}

export interface AdvancedPasswordSettings extends BasicPasswordSettings {
	firstName: string;
	lastName: string;
	forceRegistered: boolean;
}

export interface ExternalAuthStatusResponse {
	enabled: boolean;
	redirectTo?: string;
}

export interface ExternalRedirectUrlResponse {
	type: string;
	url: string;
}

export class SecurityApiService {
	constructor(private $http, private httpHandlers) {}

	hideObjectForUser = (objectType, hideStatus, objectId, objectName): ng.IHttpPromise<void> => {
		objectName = objectName ? objectName : 'none';
		let restObject = {
			objectType: objectType.toUpperCase(),
			objectId,
			hideStatus,
			objectName
		};

		return this.$http.put('rest/security/hide', angular.toJson(restObject));
	}

	createTokenForEngagor = (): ng.IHttpPromise<TokenResponse> =>
		this.$http.post('rest/security/engagor/token/create')

	loginBySSOToken = (token): ng.IHttpPromise<SSOTokenLoginResponse> =>
		this.$http.post('rest/security/analyze/token/login', token)

	getStudioToken = (): ng.IHttpPromise<TokenResponse> => {
		let config = {local: {unauthorized: true}};

		return this.$http.get('rest/security/token', config);
	}

	getAccessToken = (redirect?: boolean): ng.IHttpPromise<AccessTokenResponse> => {
		let config: any = {
			local: {
				unauthorized: true
			},
			params: {
			}
		};

		if (!_.isUndefined(redirect)) {
			config.params.redirect = redirect;
		}

		return this.$http.get('rest/security/oauth/token', config);
	}

	getExternalAuthStatus = (dashboardId: DashboardId, redirectTo): ng.IPromise<ExternalAuthStatusResponse> => {
		return this.$http.get(`rest/security/external-auth/dashboard/${dashboardId}`, {
			params: { redirectTo }
		}).then(this.httpHandlers.success);
	}

	// passwords
	getPasswordPolicy = (masterAccountId: MasterAccountId): ng.IHttpPromise<PasswordPolicy> =>
		this.$http.get(`rest/security/password_policy/${masterAccountId}`)

	setPassword = (password: BasicPasswordSettings): ng.IHttpPromise<string> =>
		this.$http.put('rest/security/password', angular.toJson(password))

	// impersonation
	beginImpersonation = (impersonatedUser): ng.IHttpPromise<TokenResponse> =>
		this.$http.put('rest/security/impersonate/on', impersonatedUser)

	endImpersonation = (): ng.IHttpPromise<TokenResponse> =>
		this.$http.put('rest/security/impersonate/off')

	getExternalRedirectUrl = (url: string): ng.IHttpPromise<ExternalRedirectUrlResponse> => {
		return this.$http.get('rest/security/redirect/external-redirect-url', {
			params: { url },
			local: true,
			cache: false
		});
	}

	getForcedExternalRedirectUrl = (): IPromise<ForcedRedirectResponse> => {
		return this.$http.get('rest/security/redirect/force-redirect', {
			params: {},
			local: true,
			cache: false
		}).then(this.httpHandlers.success);
	}

	getQualtricsAccountLinkingUrl = (): IPromise<string> => {
		return this.$http.get('rest/security/oauth/qualtrics/linking').then(this.httpHandlers.success);
	}

	getAccessibleMasterAccounts = (userId: number, includeExpired: boolean): ng.IHttpPromise<MasterAccount[]> => {
		return this.$http.get(`rest/security/accessibleMasterAccounts/${userId}?includeExpired=${!!includeExpired}`);
	}

}

// API calls for user administration
app.service('securityApiService', SecurityApiService);
