import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';

export class DrillToDashboardService {
	private filters: any[];
	private targetDashboardId: number;
	private sourceUrl: string;

	constructor() {
	}

	setSourceUrl = (sourceUrl: string): void => {
		this.sourceUrl = sourceUrl;
	}

	getSourceUrl = (): string => {
		return this.sourceUrl;
	}

	isFromBook = (): boolean => {
		return /tab=/.test(this.sourceUrl);
	}

	setTargetDashboardId = (targetDashboardId: number): void => {
		this.targetDashboardId = targetDashboardId;
	}

	getTargetDashboardId = (): number => {
		return this.targetDashboardId;
	}

	setFilters = (filterRules): void => {
		this.filters = filterRules;
	}

	addFilter = (filter): void => {
		this.filters = this.filters || [];
		this.filters.push(filter);
	}

	clear = (): void => {
		this.filters = null;
		this.targetDashboardId = null;
	}

	getFilters = (targetDashboardId: number): DashboardFilter[] => {
		return targetDashboardId === this.targetDashboardId ? this.filters : [];
	}

	hasFilters = (targetDashboardId: number): boolean => {
		return !isEmpty(this.filters) && this.targetDashboardId === targetDashboardId;
	}
}

app.service('drillToDashboardService', DrillToDashboardService);