<cx-wizard
	*ngIf="initialScorecard"
	class="scorecard-dialog"
	header="{{modalTitle}}"
	finishText="{{'common.save'|i18n}}"
	[loadingPromise]="loading"
	[activeStepIndex]="currentStep"
	[mode]="mode"
	(onFinish)="save()"
	(onCancel)="cancel()">

	<cx-wizard-step>
		<scorecard-nodes-step
			[isNew]="isNew"
			[props]="props"
			[scorecard]="scorecard"
			[models]="models"
			[validationDeferred]="validationDeferred"
			[driverTrigger]="driverTrigger"
			(onLoading)="loadingHandler($event)"
		></scorecard-nodes-step>
	</cx-wizard-step>

	<cx-wizard-step>
		<scorecard-definition-step
			[isNew]="isNew"
			[isViewMode]="isViewMode"
			[props]="props"
			[scorecard]="scorecard"
			[hasTopicsChanged]="hasTopicsChanged"
			(onTest)="test()"
			(onDriverSelected)="driverSelected()"
		></scorecard-definition-step>
	</cx-wizard-step>

</cx-wizard>
