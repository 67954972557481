import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HttpPromise } from '@app/core/http-promise';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface TemplateTransferModalInput {
	type?: TemplateAssetType | null; // null for legacy
}

@Component({
	selector: 'template-transfer-modal',
	templateUrl: './template-transfer-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateTransferModalComponent extends ModalBindings<TemplateTransferModalInput> {

	loading: PromiseLike<any>;
	showUpload = false;

	MediaType = MediaType;

	constructor(
		ngbModal: NgbActiveModal,
		private locale: CxLocaleService,
		private toastService: ToastService,
		private assetTemplateApi: AssetTemplateApiService,
		@Inject('dashboardTemplatesApiService') private dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('urlService') private urlService: UrlService,
	) {
		super(ngbModal);
	}

	downloadTemplateData(): void {
		let exportPromise = this.input.type ? this.assetTemplateApi.exportTemplates(this.input.type)
			: this.dashboardTemplatesApiService.exportTemplates() as HttpPromise<string>;
		this.loading = exportPromise.then((response) => {
			let fileName = response.headers('file-name');
			this.exportUtils.exportText(fileName, response.data);
		});
	}

	public getImportUrl(): string {
		if (this.input.type == null) {
			return this.urlService.getAPIUrl(this.dashboardTemplatesApiService.getImportTemplatesUrl());
		} else {
			return this.urlService.getAPIUrl(this.assetTemplateApi.getImportTemplatesUrl(this.input.type));
		}
	}

	handleSuccessfulUpload(count: number): void {
		this.toastService.addToast(this.locale.getString('templates.templatesImportSuccess', {count}),
			AlertLevel.SUCCESS);
	}

}
