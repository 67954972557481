import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { Input, EventEmitter, Output, OnInit, Component, SimpleChanges, forwardRef, ViewChild, AfterViewInit, OnChanges, TemplateRef } from '@angular/core';

import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from '@angular/core';

interface SelectFromTreeParams {
	node: INode;
	previous: INode;
}

@Component({
	selector: 'select-from-tree',
	templateUrl: './select-from-tree.component.html',
	styles: [`.dropdown-toggle[disabled] { pointer-events: none; cursor: default; }`],
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectFromTreeComponent), multi: true}
	]
})
export class SelectFromTreeComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {

	@Input() hierarchyList: INode[];
	@Input() displayProperty: string;
	@Input() placeholder: string;
	@Input() searchPlaceholder: string;
	@Input() label: string;
	@Input() disabled: boolean;
	//@Input() selectedItem: INode;
	selectedItem: INode;
	value: any;
	@Input() skipToProperty: boolean;
	@Input() keepRevokedItem: boolean;
	@Input() opened: boolean;
	@Input() disabledItems: INode[];
	@Input() appendToBody: boolean;
	@Input() menuClass: string;
	@Input() notRecommendedItems: INode[];
	@Input() hideSearch: boolean;
	@Input() customValidation: (node: INode) => boolean;
	@Input() matchFunction: () => INode;
	@Input() lazy: boolean;
	@Input() itemTemplate: TemplateRef<any>;
	@Input() selectedItemTemplate: TemplateRef<any>;

	@Output() onNodeClick = new EventEmitter<SelectFromTreeParams>();
	@Output() onClear = new EventEmitter<void>();

	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;

	displayDefault: string;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.displayDefault = this.placeholder || this.locale.getString('common.selectPrompt');

		if (!this.searchPlaceholder) {
			this.searchPlaceholder = this.locale.getString('common.find');
		}
		if (!this.displayProperty) {
			this.displayProperty = 'displayName';
		}
		if (!this.matchFunction) {
			this.matchFunction = () => this.selectedItem;
		}
	}

	ngAfterViewInit(): void {
		if (this.opened) {
			this.dropdown.open();
			this.ref.detectChanges();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// initialize the select once we have our hierarchy list available
		if (changes.hierarchyList) {
			this.onHierarchyListChange(changes.hierarchyList.currentValue);
		}
	}

	getContainer = (): string => {
		return this.appendToBody ? 'body' : null;
	}

	private parseModel = (value) => {
		if (value === undefined || value === null) {
			delete this.selectedItem;
		} else if (this.hierarchyList) {
			if (this.skipToProperty) {
				this.selectedItem = SearchableHierarchyUtils.findMetricInHierarchy(this.hierarchyList, value);
			} else {
				this.selectedItem = SearchableHierarchyUtils.findMetricNameInHierarchy(this.hierarchyList, value);
			}

			if (this.keepRevokedItem && this.skipToProperty && !this.selectedItem) {
				this.selectedItem = value;
			}

			if (!this.selectedItem && this.onClear) {
				this.onClear.emit();
			}
		}

		this.ref.markForCheck();
	}


	hasSelection = (): boolean => {
		return !!(this.matchFunction());
	}

	getDisplayName = (stripTags?: boolean): string => {
		let matchedData = this.matchFunction();

		if (!matchedData) {
			return stripTags ?
				this.stripSimpleHTMLTags(this.displayDefault) :
				this.displayDefault;
		}
		return stripTags ?
			this.stripSimpleHTMLTags(matchedData[this.displayProperty]) :
			matchedData[this.displayProperty];
	}

	private stripSimpleHTMLTags(tagString: string): string {
		// this doesnt cover every possible tag but it should cover the ones we'd be most likely to see here
		return tagString.replace(/<\/?(b|a|i|u|s)>/gi, '');
	}

	onHierarchyListChange = (newVal) => {
		if (newVal !== undefined) {
			this.parseModel(this.value);
		}
	}

	updateValue = (node: INode) => {
		if (node.children) return;
		let previous = cloneDeep(this.selectedItem);
		this.selectedItem = node;
		this.onNodeClick.emit({node, previous});
		this.onChangeCallback(this.skipToProperty ? node : node.name);
		this.onTouchedCallback();
		this.dropdown.close();
	}

	isOpen(): boolean {
		return this.dropdown?.isOpen();
	}

	//ControlValueAccessor
	writeValue(value): void {
		this.value = value;
		this.parseModel(value);
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}

app.directive('selectFromTree', downgradeComponent({component: SelectFromTreeComponent}) as angular.IDirectiveFactory);
