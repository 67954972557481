<form [formGroup]="predictionSettings">
	<div class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					class="mr-0"
					id="enablePredictive"
					name="enablePredictive"
					label="{{'mAccount.enablePredictiveIntegration'|i18n}}"
					formControlName="enabled">
				</checkbox-button>
			</div>
		</div>
		<div class="form-group">
			<label for="predictiveProvider" class="control-label col-md-3">{{'mAccount.trainingService'|i18n}}</label>
			<div class="col-md-3">
				<simple-dropdown id="predictiveProvider"
					[options]="providers"
					formControlName="provider"
					valueField="id">
				</simple-dropdown>
			</div>
		</div>
	</div>

</form>
