<ng-container *ngIf="loaded">
	<div class="form-group">
		<label for="dashboard-name">{{'common.name'|i18n}}</label>
		<input id="dashboard-name"
			class="form-control"
			data-testid="name"
			name="name"
			type="text"
			[(ngModel)]="dashboard.name"
			(debouncedChange)="checkName()"
			[debounce]="500"
			size="60"
			maxlength="150"
			required>
		<form-error *ngIf="uniqueNameError" text="{{'dashboard.dashboardUniqueWarning'|i18n}}"></form-error>
		<form-error *ngIf="invalidNameError" text="{{'dashboard.dashboardNameEmptyWarning'|i18n}}"></form-error>
	</div>
	<hr/>
	<div class="mb-16" [i18n]="'templates.dashboardProjectLabel'"></div>
	<workspace-project-selector
		[(value)]="dashboard.workspaceProject"
		(valueChange)="onProjectChange()"
		labelPosition="left"
		[hideInternalProjects]="!adminProjectOnly"
		[internalProjectsOnly]="adminProjectOnly"
	></workspace-project-selector>
</ng-container>
