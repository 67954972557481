import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { ContentProviderId, AccountId, ProjectId } from '@cxstudio/generic-types';

export class CBProject {
	cpId: ContentProviderId;
	accountId: AccountId;
	projectId: ProjectId;
	name: string;
	settingsPromises = {};

	constructor(
		cpId: ContentProviderId,
		accountId: AccountId,
		projectObject: AccountProject
	) {
		this.cpId = cpId;
		this.accountId = accountId;
		this.projectId = projectObject.projectId;
		this.name = projectObject.name;
	}
}
