<filter-rule *ngIf="options"
	class="flex-fill d-flex align-items-center"
	data-testid="replacement-filter-rule"
	[rule]="rule"
	[filterableFields]="options"
	[allowDateRange]="false"
	[projectSelection]="project"
	(ruleChange)="onRuleChange($event)"
	(selectedAttributeChange)="onSelectedAttributeChange($event)"
	[appendToBody]="true">
</filter-rule>
