import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'dropdown-menu-group',
	template: `
<span class="dropdown-header font-bold text-base">{{label}}</span>
<ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuGroupComponent {
	@Input() label: string;
	@Input() amplitudeCategoryProperties?: {[key: string]: any};
}
