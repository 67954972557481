import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AccountIdentifier } from '@app/modules/account/account-identifier';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ContentProviderApi } from '@app/modules/system-administration/content-provider/content-provider.api.service';
import { Unit } from '@app/modules/units/unit';
import { UnitsApi } from '@app/modules/units/units.api.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { ProjectAccessDataHelperService } from '@app/modules/user-administration/projects-access/project-access-data-helper.service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { CxLocaleService } from '@app/core';



interface ISlickGridProject extends AccountProject {
	level: number;
	displayName: string;
	id: number;
	updateValue?: BulkUpdateValue;
}

enum BulkDataAccessChange {
	ADMIN = 'admin',
	READ_ONLY = 'readOnly',
	NO_ACCESS = 'noAccess',
	NONE = 'none',
}

export type BulkDataAccessUpdateGroups = {[key in BulkDataAccessChange]?: AccountProject[]};

export interface BulkDataAccessUpdate extends BulkDataAccessUpdateGroups {
	accountIdentifier: AccountIdentifier;
}

@Component({
	selector: 'user-bulk-data-access',
	templateUrl: './user-bulk-data-access.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBulkDataAccessComponent implements OnInit {

	projects: ISlickGridProject[];
	gridType = GridTypes.USERS_BULK_DATA_ACCESS;
	accountIdentifier: IProjectSelection;
	loading: Promise<any>;
	projectsAccess: any;
	contentProviderErrors: string[];

	accessMap: {[key in BulkUpdateValue]?: string[]} = {
		[BulkUpdateValue.READ_ONLY]: [this.ProjectAccessLevels.MANAGER.value, this.ProjectAccessLevels.VIEWER.value],
		[BulkUpdateValue.ADMIN]: [this.ProjectAccessLevels.MANAGER.value]
	};

	workspaces: Unit[];

	constructor(
		private readonly locale: CxLocaleService,
		private readonly modal: NgbActiveModal,
		private readonly ref: ChangeDetectorRef,
		private readonly dialogService: CxDialogService,
		private readonly unitsApi: UnitsApi,
		private readonly contentProviderApiService: ContentProviderApi,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly projectAccessDataHelperService: ProjectAccessDataHelperService,
		@Inject('ProjectAccessLevels') private readonly ProjectAccessLevels: ProjectAccessLevelItems,
	) { }

	ngOnInit(): void {
		if (this.isWorkspaceEnabled()) {
			this.loading = this.unitsApi.getCurrentMasterAccountUnits().then(units => {
				this.workspaces = units;
				if (units.length === 1) {
					this.switchWorkspace(units[0]);
				}
				this.ref.markForCheck();
			});
		}
	}

	private processProjects(identifier: IProjectSelection): Promise<void> {
		return this.contentProviderApiService.getAccountProjects(identifier.contentProviderId, identifier.accountId)
			.then(projects => {
				const projectAccess = this.projectAccessDataHelperService.
					getCurrentUserProjectAccess(identifier.contentProviderId, [].concat(identifier.accountId));
				Promise.resolve(projectAccess).then(allProjectsWithAccessLevel => {
					this.projectsAccess = allProjectsWithAccessLevel.data.data[0].projects;
				});
				this.projects = _.map(projects, project => _.extend({}, project, {
					level: 0,
					displayName: project.name,
					id: project.projectId,
				}));
				this.ref.markForCheck();
			}, _.noop);
	}

	save(): void {

		let result: BulkDataAccessUpdate = _.groupBy(this.projects, (project: ISlickGridProject): keyof BulkDataAccessUpdate => {
			if (this.invalidProjectAccess(project)) {
				this.dialogService.warning(this.locale.getString('administration.invalidProjectAccess'),
					this.locale.getString('administration.invalidModifyPermissions')).result.then(() => {
						this.modal.dismiss();
				});
				project.updateValue = BulkUpdateValue.NONE;
			}
			switch (project.updateValue) {
				case BulkUpdateValue.ADMIN:
					return BulkDataAccessChange.ADMIN;
				case BulkUpdateValue.READ_ONLY:
					return BulkDataAccessChange.READ_ONLY;
				case BulkUpdateValue.NO_ACCESS:
					return BulkDataAccessChange.NO_ACCESS;
				default:
					return BulkDataAccessChange.NONE;
			}
		}) as any;
		result.accountIdentifier = this.accountIdentifier;
		this.modal.close(result);
	}

	invalidProjectAccess(project: ISlickGridProject): boolean {
		if ([BulkUpdateValue.READ_ONLY, BulkUpdateValue.ADMIN].includes(project.updateValue)) {
			const projectAccess = this.projectsAccess.find(pa => pa.project.id === project.projectId);

			if (projectAccess && !this.hasCorrectAccessLevel(project.updateValue, projectAccess.accessLevel)) {
				return true;
			}
		}
		return false;
	}

	hasCorrectAccessLevel(updateValue: BulkUpdateValue, accessLevel: any): boolean {
		return this.accessMap[updateValue].contains(accessLevel);
	}

	cancel(): void {
		if (!this.isDataChanged()) {
			this.modal.dismiss();

			return;
		}

		this
			.dialogService
			.showUnsavedChangesDialogAndResolve(
				this.save,
				this.modal.dismiss,
				'common.unsavedChangesHeader',
				'common.unsavedChanges',
				false
			)
			.catch(() => {})
		;
	}

	private isDataChanged(): boolean {
		return _.some(this.projects, (project: ISlickGridProject) => {
			return project.updateValue && project.updateValue !== BulkUpdateValue.NONE;
		});
	}

	onProjectSelected(selection: IProjectPreselection): void {
		this.onAccountChange({
			contentProviderId: selection.cbContentProvider,
			accountId: selection.cbAccount
		} as IProjectSelection);
	}

	private onAccountChange(selected: IProjectSelection) {
		this.projects = [];
		this.accountIdentifier = selected;
		this.reloadData();
	}

	isWorkspaceEnabled(): boolean {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	hasMultipleWorkspaces(): boolean {
		return this.workspaces?.length > 1;
	}

	switchWorkspace = (selectedWorkspace: Unit): void => {
		this.onAccountChange({
			contentProviderId: selectedWorkspace.contentProviderId,
			accountId: selectedWorkspace.accountId,
			projectId: undefined
		});
	}

	private reloadData = (): void => {
		if (ProjectIdentifier.isAccountSelected(this.accountIdentifier)) {
			this.loading = this.processProjects(this.accountIdentifier);
		}
	}
}
