export enum BoundaryField {
	COUNTRY_NAME = 'COUNTRY_NAME',
	COUNTRY_THREE_LETTER_CODE = 'COUNTRY_THREE_LETTER_CODE',
	COUNTRY_TWO_LETTER_CODE = 'COUNTRY_TWO_LETTER_CODE',

	STATE_NAME = 'STATE_NAME',
	STATE_FOUR_LETTER_CODE = 'STATE_FOUR_LETTER_CODE',
	STATE_POSTAL_CODE = 'STATE_POSTAL_CODE',
	STATE_FIPS_CODE = 'STATE_FIPS_CODE',

	US_COUNTY_NAME = 'US_COUNTY_NAME',
	US_COUNTY_FIPS_CODE = 'US_COUNTY_FIPS_CODE',

	POSTAL_ZIP = 'POSTAL_ZIP'
}
