import { IDirectiveFactory } from 'angular';

const ngEscape = () => {
	return (scope, element, attrs) => {
		element.bind('keydown keypress', (event) => {
			if (event.which === 27) {
				scope.$apply(() => {
					scope.$eval(attrs.ngEscape);
				});

				event.preventDefault();
			}
		});
	};
};

const ngGlobalEscape = ($window) => {
	return (scope, element, attrs) => {
		let handler = (event) => {
			if (event.which === 27) {
				scope.$apply(() => {
					scope.$eval(attrs.ngGlobalEscape);
				});

				event.preventDefault();
			}
		};
		angular.element($window).bind('keydown keypress', handler);
		scope.$on('$destroy', () => {
			angular.element($window).off('keydown keypress', handler);
		});
	};
};

/**
 * Directive for handling escape key in elements
 */
app.directive('ngEscape', ngEscape as IDirectiveFactory);


// responds to escape key anywhere, not just while the item is in focus
app.directive('ngGlobalEscape', ngGlobalEscape as IDirectiveFactory);
