<div class="p-relative w-100-percent h-100-percent">
	<div class="sidebar-editor d-flex p-absolute w-100-percent">
		<div class="sidebar-panel bg-white d-flex flex-direction-column">
			<div class="overflow-x-hidden overflow-y-auto flex-fill">
				<ng-content></ng-content>
			</div>
			<div class="sidebar-panel-footer d-flex justify-between ph-16 pv-8 border-t-1 border-t-solid">
				<button
					id="btn-editor-discard"
					type="button"
					class="btn btn-secondary"
					(click)="onDiscard()">
					{{'common.discard'|i18n}}</button>
				<button
					id="btn-editor-apply"
					type="button"
					class="btn btn-primary"
					(click)="onApply()">
					{{'common.apply'|i18n}}</button>
			</div>
		</div>
		<div class="preview-panel d-flex flex-fill align-center justify-center p-32 backdrop-mask"
			[style.width.px]="previewContainerWidth">
			<div class="preview-content" [ngStyle]="previewTransform" [class.not-scaled]="!previewTransform.transform">
				<ng-content></ng-content>
			</div>
		</div>
		<div [hidden]="!isPropertiesVisible()"
			class="p-absolute w-100-percent h-100-percent backdrop-mask z-index-1">
			<div class="sidebar-properties-panel p-absolute bg-white d-flex">
				<template #propertiesContainer></template>
			</div>
		</div>
	</div>
</div>
