<div>
	<div class="row pull-right block">
		<a data-testid="reset-default-settings" (click)="resetDefaultSettings()">
			{{'common.resetDefault'|i18n}}
		</a>
	</div>

	<div class="row" *ngIf="hasGroup(SettingsGroup.SHOW_QUANTITY)">
		<div class="col-sm-6 form-group">
			<label>{{'common.show'|i18n}}</label>
			<div>
				<div class="btn-group">
					<button *ngFor="let option of options.sortOrder"
						class="btn btn-secondary"
						[ngClass]="{'btn-selected': groups[SettingsGroup.SHOW_QUANTITY].settings.sortOrder === option.value}"
						(click)="groups[SettingsGroup.SHOW_QUANTITY].settings.sortOrder = option.value">
						{{option.name}}
					</button>
				</div>
			</div>
		</div>

		<div class="col-sm-3 form-group">
			<label for="quantity">{{'common.quantity'|i18n}}</label>
			<div class="metric-rows">
				<input type="number"
					id="quantity"
					data-testid="settings-quantity"
					class="ph-8 text-right"
					[valueRequired]
					[strictMin]="1"
					[strictMax]="1000"
					[(ngModel)]="groups[SettingsGroup.SHOW_QUANTITY].settings.size">
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6 form-group" *ngIf="hasGroup(SettingsGroup.SHOW_QUANTITY)">
			<label for="minDocCount">{{getVolumeLabel()|i18n}}</label>
			<div class="metric-min-volume">
				<input id="minDocCount"
					type="number"
					[strictMin]="0"
					[(ngModel)]="groups[SettingsGroup.SHOW_QUANTITY].settings.minDocCount">
			</div>
		</div>
	</div>

	<div class="row" *ngIf="hasGroup(SettingsGroup.SHOW_QUANTITY)">
		<div class="col-sm-12 form-group">
			<label>{{'widget.labelBy'|i18n}}</label>
			<div>
				<select-from-tree
					data-testid="metric-sort-by"
					class="w-100-percent attribute-selector"
					[ngModel]="groups[SettingsGroup.SHOW_QUANTITY].settings.sortBy"
					[hierarchyList]="options.sortBy"
					(onNodeClick)="changeAttrSort($event.node)"
				></select-from-tree>
			</div>
		</div>
	</div>

	<hr *ngIf="hasGroup(SettingsGroup.SHOW_QUANTITY)"/>

	<div class="row" *ngIf="hasGroup(SettingsGroup.INCLUSION_LIST)">
		<div class="col-sm-2 form-group">
			<label for="selectedLevel">{{'widget.level'|i18n}}</label>
			<div class="form-group level-selector">
				<select id="selectedLevel"
					[(ngModel)]="selectedLevel"
					(change)="levelChanged()">
					<option *ngFor="let level of options.levels" [ngValue]="level.value">{{level.name}}</option>
				</select>
			</div>
		</div>
		<div class="col-sm-10">
			<div class="form-group">
				<label>{{'widget.inclusionList'|i18n}}</label>
				<a data-testid="deselect-all" class="pull-right" (click)="deselectAll()">{{'administration.selectNone'|i18n}}</a>
				<a data-testid="select-all" class="pull-right block" (click)="selectAll()">{{'common.selectAll'|i18n}}</a>
				<div>
					<searchable-hierarchy
						class="topic-inclusion-list"
						placeholder="{{'common.search'|i18n}}"
						[hierarchyList]="[modelTree]"
						displayProperty="name"
						[ngShowNodeCheckbox]="isNodeClickable"
						[ngNodeIsChecked]="isNodeChecked"
						[ngNodeIsHighlighted]="isNodeHighlighted"
						[ngNodeIndeterminate]="isIndeterminateNode"
						[ngIsIndeterminateFollowedByUncheck]="isIndeterminateFollowedByUncheck"
						(onNodeClick)="handleNodeClick($event.node)"
						folderClickIgnore="true">
					</searchable-hierarchy>
				</div>
			</div>
		</div>
	</div>

	<div id="custom-options">
		<div class="row" *ngIf="hasGroup(SettingsGroup.NULL_INCLUDE)">
			<div class="col-sm-6 form-group">
				<label *ngIf="!groups[SettingsGroup.NULL_INCLUDE].timeMode">{{'widget.nullValues'|i18n}}</label>
				<label *ngIf="groups[SettingsGroup.NULL_INCLUDE].timeMode">{{'widget.emptyPeriods'|i18n}}</label>
				<div>
					<div class="btn-group">
						<button
							*ngFor="let option of options.nullInclude"
							class="btn btn-secondary"
							[ngClass]="{'btn-selected': groups[SettingsGroup.NULL_INCLUDE].settings.nullInclude === option.value}"
							(click)="groups[SettingsGroup.NULL_INCLUDE].settings.nullInclude = option.value">
							{{option.name}}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="row" *ngIf="hasGroup(SettingsGroup.LIMIT_TO)">
			<div class="col-sm-12 form-group">
				<label>{{'widget.limitTo'|i18n}}</label>
				<words-selection
					[project]="groups[SettingsGroup.LIMIT_TO].project"
					[item]="groups[SettingsGroup.LIMIT_TO].settings"
					[includeOnly]="groups[SettingsGroup.LIMIT_TO].settings.sortOrder === 'custom'"
				></words-selection>
			</div>
		</div>

		<div class="row" *ngIf="hasGroup(SettingsGroup.URL)">
			<div class="col-sm-6 form-group">
				<label>{{'widget.url'|i18n}}</label>
				<div>
					<div class="btn-group url-type-selector">
						<button
							*ngFor="let option of options.urlTypes"
							class="btn btn-secondary"
							[ngClass]="{'btn-selected': groups[SettingsGroup.URL].settings.urlType === option.value}"
							(click)="groups[SettingsGroup.URL].settings.urlType = option.value">
							{{option.name}}
						</button>
					</div>
				</div>
			</div>
			<div class="col-sm-6" *ngIf="groups[SettingsGroup.URL].settings.urlType === 'image'">
				<div class="form-group">
					<label for="tableRowHeight">{{'widget.tableRowHeight'|i18n}}</label>
					<div>
						<div class="btn-group">
							<input id="tableRowHeight"
								class="row-height-input"
								type="number"
								[(ngModel)]="groups[SettingsGroup.URL].settings.tableRowHeight"
								[valueRequired]
								[strictMin]="1"
								[strictMax]="300">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row" *ngIf="hasGroup(SettingsGroup.CAPITALIZATION)">
			<div class="col-sm-12 form-group">
				<label for="capitalization">{{'widget.capitalization'|i18n}}</label>
				<div>
					<select id="capitalization"
						[(ngModel)]="groups[SettingsGroup.CAPITALIZATION].settings.capitalization">
						<option *ngFor="let option of options.capitalization" [ngValue]="option.value">
							{{option.name}}
						</option>
					</select>
				</div>
			</div>
		</div>

		<div *ngIf="hasGroup(SettingsGroup.NUMBER_FORMAT)">
			<number-format
				[formatObject]="groups[SettingsGroup.NUMBER_FORMAT].settings"
				[mustInherit]="groups[SettingsGroup.NUMBER_FORMAT].config.isInherited">
			</number-format>
		</div>

		<calculation-type-selector
			class="mt-16"
			*ngIf="hasGroup(SettingsGroup.AGGREGATION)"
			[(ngModel)]="groups[SettingsGroup.AGGREGATION].settings.calculationType">
		</calculation-type-selector>
	</div>
</div>
