import { Component, OnInit, ChangeDetectionStrategy, Inject, Output, EventEmitter, Input } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ConversionOption, WidgetConversionsService } from '@cxstudio/reports/providers/cb/widget-conversions.service';

@Component({
	selector: 'widget-placeholder',
	template: `
<div class="d-flex flex-direction-column align-center justify-center h-100-percent border-pewter-color-400"
	[style.border-style]="'dashed'">
	<div class="d-flex">
		<button *ngFor="let type of widgetTypes"
			class="btn btn-secondary btn-icon"
			[ngClass]="type.icon"
			[title]="type.title"
			(click)="selectType(type)"></button>
	</div>
	<span class="mt-8" [i18n]="'homePage.chooseWidgetType'"></span>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPlaceholderComponent implements OnInit {
	@Output() onSelect = new EventEmitter<Widget>();

	widgetTypes: ConversionOption[];

	constructor(
		@Inject('widgetConversions') private widgetConversions: WidgetConversionsService,
	) { }

	ngOnInit(): void {
		this.widgetTypes = this.widgetConversions.getSublist([
			WidgetType.METRIC,
			WidgetType.BAR,
			WidgetType.LINE,
			WidgetType.PIE]);
	}

	selectType(type: ConversionOption): void {
		let emptyWidget = {properties: {}, visualProperties: {}} as Widget;
		type.targetWidget.initFrom(emptyWidget);
		let widget = type.targetWidget.getWidget();
		this.onSelect.emit(widget);
	}

}
