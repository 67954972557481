import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'dashboard-templates-page',
	templateUrl: './dashboard-templates-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTemplatesPageComponent {
	showNewTemplates: boolean;

	TemplateAssetType = TemplateAssetType;

	constructor(
		betaFeaturesService: BetaFeaturesService,
		@Inject('security') security: Security
	) {
		this.showNewTemplates = betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES)
			&& security.isAdminOrgUser();
	}
}

app.directive('dashboardTemplatesPage', downgradeComponent({component: DashboardTemplatesPageComponent}));
