<form #additionalInformationForm="ngForm" class="form-horizontal" duplicateNameCheck="propertyName_"
	forbiddenNameCheck validateLogoutEndpoint>

	<div class="labeled-rule text-center">
		<hr/>
		<div>
			<label>{{'administration.oauthClientDetailsAdditionalInformation'|i18n}}</label>
		</div>
	</div>

	<div class="row form-group">
		<label class="control-label col-md-3">{{'administration.oauthSaveClientState'|i18n}}</label>
		<div class="col-md-8">
			<input type="checkbox" class="checkbox" [(ngModel)]="saveClientState" name="saveClientState"
				(change)="updateAdditionalInformation()">
		</div>
	</div>

	<div class="row form-group" *ngIf="!masterAccountIdValid()">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning" aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label class="text-danger">{{'administration.masterAccountIdHasToBeSelected'|i18n}}</label>
		</div>
	</div>

	<div class="form-group">
		<label class="col-md-3 control-label">{{'administration.addMasterAccountId'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.additionalInformationMasterAccountIdHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-5">
			<search-list
				[dropdown]="true"
				[listOptions]="masterAccounts"
				[data]="masterAccount"
				(onNodeSelection)="onMasterAccountIdChange($event.node)"
				(onClearSelection)="clearMasterAccountId()"
				fullWidth="true"
				matchSize="true"
				displayField="accountName"
				sortField="accountName"
				selectPrompt="{{'common.none'|i18n}}"
				[allowClear]="!masterAccountIdRequired">
			</search-list>
		</div>
	</div>

	<div class="rounded-grouping">

		<div class="row form-group"
			*ngIf="additionalInformationForm.errors?.logountEndpointUrlAbsent && additionalInformationForm.dirty">
		 	<label class="control-label col-md-5" [ngStyle]="{'color':'red'}">
				 <span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>

			<div class="col-md-6">
				<label [ngStyle]="{'color':'red'}">
					{{'administration.oauthLogoutEndpointUrlAbsent'|i18n}}
				</label>
			</div>
		</div>

		<div class="row form-group"
			*ngIf="additionalInformationForm.errors?.userOrPasswordAbsent && additionalInformationForm.dirty">
		 	<label class="control-label col-md-5" [ngStyle]="{'color':'red'}">
				 <span class="q-icon q-icon-warning" aria-hidden="true"></span>
			</label>

			<div class="col-md-6">
				<label [ngStyle]="{'color':'red'}">
					{{'administration.oauthLogoutEndpointUsernamePasswordAbsent'|i18n}}
				</label>
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthLogoutEndpointUrl'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control" [(ngModel)]="logoutEndpoint.url" name="logoutEndpointUrl"
					(change)="updateAdditionalInformation()">
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthLogoutEndpointUser'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control" [(ngModel)]="logoutEndpoint.user" name="logoutEndpointUser"
					(change)="updateAdditionalInformation()">
			</div>
		</div>

		<div class="row form-group">
			<label class="control-label col-md-3">{{'administration.oauthLogoutEndpointPassword'|i18n}}</label>
			<div class="col-md-8">
				<input class="form-control" type="password" autocomplete="new-password"
						[(ngModel)]="logoutEndpoint.password" name="logoutEndpointPassword"
						(change)="updateAdditionalInformation()">
			</div>
		</div>
	</div>

	<div class="row form-group">
		<label
			class="control-label col-md-3">{{'administration.oauthClientDetailsAdditionalInformationAddNew'|i18n}}</label>
		<div class="col-md-6">
			<button type="button" class="btn btn-primary" (click)="addInfoBoolean()">
				<span class="q-icon q-icon-boolean" aria-hidden="true"></span>
			</button>
			<button type="button" class="btn btn-primary" (click)="addInfoString()">
				<span class="q-icon q-icon-string" aria-hidden="true"></span>
			</button>
			<button type="button" class="btn btn-primary" (click)="addInfoNumber()">
				<span class="q-icon q-icon-number" aria-hidden="true"></span>
			</button>
		</div>
	</div>

	<div class="row form-group"
		 *ngIf="additionalInformationForm.errors?.duplicateName && additionalInformationForm.dirty">
		 <label class="control-label col-md-5" [ngStyle]="{'color':'red'}"><span class="q-icon q-icon-warning"
			aria-hidden="true"></span></label>
		<div class="col-md-6">
			<label [ngStyle]="{'color':'red'}">
				{{'administration.duplicateOauthClientAdditionalInformationProperties'|i18n}}:
				{{additionalInformationForm.errors.duplicateName.value}}
			</label>
		</div>
	</div>

	<div class="row form-group"
		 *ngIf="additionalInformationForm.errors?.forbiddenName && additionalInformationForm.dirty">
		 <label class="control-label col-md-5 text-danger"><span class="q-icon q-icon-warning text-danger"
			aria-hidden="true"></span></label>
		<div class="col-md-6">
			<label [ngStyle]="{'color':'red'}">
				{{'administration.oauthForbiddenAdditionalInformationNames'|i18n}}:
				{{additionalInformationForm.errors.forbiddenName.value}}
			</label>
		</div>
	</div>

	<div *ngFor="let entry of additionalInformation; index as $index; trackBy:trackAdditionalInformationProperties"
		class="row form-group" show-errors>

		<div class="col-md-5">
			<input class="form-control" autocomplete="nope" [(ngModel)]="entry.name"
				   placeholder="{{'administration.oauthClientDetailsPropertyName'|i18n}}" required
				   (change)="updateAdditionalInformation()" name="propertyName_{{$index}}">
		</div>

		<div *ngIf="entry.type === 'string'" class="col-md-6">
			<input type="text" class="form-control" autocomplete="nope" [(ngModel)]="entry.value"
				   placeholder="{{'administration.oauthClientDetailsPropertyValue'|i18n}}"
				   name="propertyValue_{{$index}}"
				   required (change)="updateAdditionalInformation()">
		</div>

		<div *ngIf="entry.type === 'number'" class="col-md-6">
			<input type="number" class="form-control" autocomplete="nope" [(ngModel)]="entry.value"
				   name="propertyValue_{{$index}}" required pattern="^[-]?[0-9]+(\.[0-9]*)?$"
				   (change)="updateAdditionalInformation()">
		</div>

		<div *ngIf="entry.type === 'boolean'" class="col-md-6">
			<input type="checkbox" class="checkbox" [(ngModel)]="entry.value"
				   name="propertyValue_{{$index}}"
				   (change)="updateAdditionalInformation()">
		</div>

		<button type="button" class="btn btn-primary" (click)="removeInfo($index)"><span
			class="q-icon q-icon-delete"
			aria-hidden="true"></span></button>
	</div>

</form>
