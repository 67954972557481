import * as _ from 'underscore';
import * as uib from 'angular-ui-bootstrap';
import Group from '@cxstudio/user-administration/groups/Group';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { ExportService } from '@cxstudio/services/export-service.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { GroupFilterOption } from './group-filter-options.enum';
import { GroupTypeFilter } from './group-type-filter.enum';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { UserSelectionMethod } from '@cxstudio/user-administration/users/entities/user-selection-method.enum';
import { UserBulkActionMode } from '@cxstudio/user-administration/users/entities/user-bulk-action-mode';
import { GroupActionsService } from '@app/modules/user-administration/groups/group-actions.service';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IAssetAccess } from '@app/modules/access-management/groups/asset-access';
import { GroupQueryParams } from '@cxstudio/user-administration/groups/group-query-params';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { PromiseUtils } from '@app/util/promise-utils';

export enum QueryMode {
	IS = 'IS',
	GTE = 'GTE',
	LTE = 'LTE'
}

export class GroupsComponent implements ng.IComponentController {

	currentMasterAccountName: string;
	groupPagination: {
		currentPage: number;
		totalItems: number;
		pageSize: number;
	} = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 10
	};

	ui: { showAddGroupBtn: () => boolean };

	promises;
	filteredGroups = [];
	groupQueryBy: GroupFilterOption = GroupFilterOption.EVERYTHING;
	onGroupQueryByChange;
	onGroupQueryChange;
	groupQuery;
	groups: Group[];
	selectedGroups = [];
	selectedGroup = {group: '' };
	permissions;
	type: string = GroupTypeFilter.CUSTOM;
	groupQueryMode: QueryMode = QueryMode.IS;

	selection: {
		method: UserSelectionMethod,
		totalItems: number;
	} = {
		method: UserSelectionMethod.EXACT_SELECTION,
		totalItems: 0
	};
	bulkActionMode: UserBulkActionMode;

	reloadPermissions: () => void;
	reloadGroups: () => void;

	allowExportOfGroups: boolean = false;

	constructor(
		private readonly $scope: ng.IScope,
		private readonly $log: ng.ILogService,
		private readonly $q: ng.IQService,
		private readonly security: Security,
		private readonly $uibModal: uib.IModalService,
		private readonly cbDialogService: CBDialogService,
		private readonly locale: ILocale,
		private readonly usersGroupsApiService: UsersGroupsApiService,
		private readonly userModificationService: UserModificationApiService,
		private readonly httpHandlers: HttpHandlers,
		private readonly exportService: ExportService,
		private readonly assetAccessApiService: AssetAccessApiService,
		private readonly contextMenuTree: ContextMenuTree,
		private readonly groupActionsService: GroupActionsService,
		private readonly maPropertiesService: MAPropertiesService
	) { }

	$onInit = () => {
		this.currentMasterAccountName = this.security.getCurrentMasterAccount().accountName;
		this.promises = this.promises || {};
		this.promises.loadingGroup = null;
		this.resetQuery();
		this.allowExportOfGroups = this.maPropertiesService.isFeatureEnabled(
			MasterAccountProperty.EXPORT_USERS_AND_GROUPS
		);

		this.$scope.$watch('$ctrl.groups', this.filterGroups);

		this.onGroupQueryByChange = this.resetQuery;
		this.onGroupQueryChange = this.filterGroups;

		this.reloadGroups();

		this.ui = {
			showAddGroupBtn: (): boolean => this.security.has('manage_groups')
		};
	}

	private resetQuery = (): void => {
		this.groupQuery = undefined;
		this.type = GroupTypeFilter.CUSTOM;
		this.filterGroups();
	}

	private filterGroups = (): void => {
		this.filteredGroups = _.chain(this.groups)
			.filter(this.getFilterFunction())
			.sortBy(group => group.groupName.toLowerCase())
			.value();
		this.selection.method = UserSelectionMethod.EXACT_SELECTION;
		this.selection.totalItems = this.filteredGroups.length;
		this.updateGroupPaginationTotalCount();
	}

	private getFilterFunction(): (group: Group) => boolean {
		let filterFunction: (group: Group) => boolean;
		if (this.isFilteredByGroupType()) {
			if (this.type === GroupTypeFilter.HIERARCHY_DERIEVED) {
				filterFunction = (group: Group): boolean => {
					return this.isHierarchyDerivedGroup(group);
				};
			} else if (this.type === GroupTypeFilter.CUSTOM) {
				filterFunction = (group: Group): boolean => {
					return !this.isHierarchyDerivedGroup(group);
				};
			}
			this.groupQuery = this.type;
		} else if (_.isUndefined(this.groupQuery) || this.groupQuery.length === 0) {
			filterFunction = (): boolean => true;
		} else if (this.groupQueryBy === GroupFilterOption.EVERYTHING) {
			filterFunction = (group: Group): boolean => {
				return this.containsIgnoreCase(group.groupName, this.groupQuery)
					|| this.containsIgnoreCase(group.description, this.groupQuery)
					|| this.containsIgnoreCase(group.ownerEmail, this.groupQuery);
			};
		} else if (this.isFilteredByMembers() && _.isFinite(this.groupQuery)) {
			if (this.groupQueryMode === QueryMode.IS) {
				filterFunction = (group: Group): boolean => {
					// To avoid conversion to number
					return group.usersCount === parseInt(this.groupQuery, 10);
				};
			} else if (this.groupQueryMode === QueryMode.GTE) {
				filterFunction = (group: Group): boolean => {
					return group.usersCount >= parseInt(this.groupQuery, 10);
				};
			} else if (this.groupQueryMode === QueryMode.LTE) {
				filterFunction = (group: Group): boolean => {
					return group.usersCount <= parseInt(this.groupQuery, 10);
				};
			}
		} else {
			filterFunction = (group: Group): boolean => {
				return this.containsIgnoreCase(group[this.groupQueryBy], this.groupQuery);
			};
		}
		return filterFunction;
	}

	private containsIgnoreCase = (whereToSearch, whatToSearch) => {
		return _.isString(whereToSearch) && _.isString(whatToSearch)
			&& whereToSearch.toLowerCase().contains(whatToSearch.toLowerCase());
	}

	private updateGroupPaginationTotalCount = () => {
		this.groupPagination.totalItems = this.filteredGroups.length;
	}

	private isGroupSelected = (group: Group): boolean => _.findWhere(this.selectedGroups, {groupId: group.groupId});

	clickOnHamburgerMenu = (group: Group, event: JQuery.Event) => {
		let CONTEXT_MENU_CLASS_NAME = 'dashboards';
		let CONTEXT_MENU_MAX_WIDTH = 360;

		this.contextMenuTree.showObjectListMenu(event, group, this.getContextMenuOptions(group), CONTEXT_MENU_CLASS_NAME, CONTEXT_MENU_MAX_WIDTH);
	}

	private getContextMenuOptions = (group: Group): any[] => {
		if (this.selectedGroups.length > 1) {
			return BaseContextMenuUtils.enforceDividerRules(this.getBulkMenuOptions());
		} else {
			return BaseContextMenuUtils.enforceDividerRules(this.getSingleGroupMenuOptions(group));
		}
	}

	private getBulkMenuOptions = (): any[] => {
		let options: any[] = [];
		options.push({
			text: this.locale.getString('permission.updatePermissions'),
			name: 'updatePermissions',
			func: this.updatePermissionsInBulk
		});
		if (!_.some(this.selectedGroups, this.isHierarchyDerivedGroup)) {
			options.push({
				text: this.locale.getString('administration.updateUserMembership'),
				name: 'updateUserMembership',
				func: this.updateMembershipInBulk
			});
			options.push(BaseContextMenuUtils.MENU_DIVIDER);
			options.push({
				text: this.locale.getString('common.remove'),
				name: 'delete',
				func: this.removeGroupsInBulk
			});
		}

		return options;
	}

	private updatePermissionsInBulk = (group: Group): void => {
		this.promises.loadingGroup = PromiseUtils.old(
			this.groupActionsService.updatePermissionsInBulk(
				this.selectedGroups,
				this.buildSelectionQueryParameters()
			)
		).then(this.finishAction);
	}

	private updateMembershipInBulk = (group: Group): void => {
		this.promises.loadingGroup = PromiseUtils.old(
			this.groupActionsService.updateMembershipInBulk(
				this.selectedGroups,
				this.buildSelectionQueryParameters()
			)
		).then(this.finishAction);
	}

	private removeGroupsInBulk = (group: Group) => {
		const masterAccountName: string = this.security.getCurrentMasterAccount().accountName;

		this.promises.loadingGroup = PromiseUtils.old(
			this.groupActionsService.removeGroupsInBulk(
				this.selectedGroups,
				masterAccountName,
				this.buildSelectionQueryParameters()
			)
		).then(this.finishAction);
	}

	private finishAction = (): void => {
		if (this.bulkActionMode !== UserBulkActionMode.ASYNC) {
			this.reloadGroups();
		}
		this.selectedGroups.removeAll();
	}

	private getSingleGroupMenuOptions = (group: Group): any[] => {
		let options: any[] = [
			{name: 'edit', func: this.editGroup, text: this.locale.getString('common.edit')}
		];
		options.push({
			text: this.locale.getString('permission.updatePermissions'),
			name: 'updatePermissions',
			func: this.updatePermissions
		});
		if (!this.isHierarchyDerivedGroup(group)) {
			options.push({
				text: this.locale.getString('administration.updateUserMembership'),
				name: 'updateUserMembership',
				func: this.updateMembership
			});
			options.push(BaseContextMenuUtils.MENU_DIVIDER);
			options.push({
				text: this.locale.getString('common.remove'),
				name: 'remove',
				func: this.removeGroup
			});
		}
		return options;
	}

	isCurrentPageChecked = (): boolean => {
		if (!this.filteredGroups) {
			return false;
		} else {
			return _.every(this.getCurrentPageIndexRange(), idx => this.isGroupSelected(this.filteredGroups[idx]));
		}
	}

	checkCurrentPage = (): void => {
		if (this.filteredGroups) {
			_.each(this.getCurrentPageIndexRange(), idx => {
				if (!this.isGroupSelected(this.filteredGroups[idx])) {
					this.selectedGroups.push(this.filteredGroups[idx]);
				}
			});
		}

		this.selection.method = UserSelectionMethod.EXACT_SELECTION;
	}

	private getCurrentPageIndexRange = (): number[] => {
		let startIdx = (this.groupPagination.currentPage - 1) * this.groupPagination.pageSize;
		let endIdx = Math.min(this.filteredGroups.length, startIdx + this.groupPagination.pageSize);
		return _.range(startIdx, endIdx);
	}

	checkAll = (): void => {
		this.selection.method = UserSelectionMethod.ALL_MATCHING_FILTERS;
		this.selectedGroups = [...this.filteredGroups];
	}

	uncheckAllGroups = (): void => {
		this.selectedGroups.removeAll();
		this.selection.method = UserSelectionMethod.EXACT_SELECTION;
	}

	verifySelectionMode = () => {
		if (!this.isCurrentPageChecked()) {
			this.selection.method = UserSelectionMethod.EXACT_SELECTION;
		}
		this.updateBulkActionMode();
	}

	buildSelectionQueryParameters = (): GroupQueryParams => {
		let queryParameters: GroupQueryParams = {
			method: this.selection.method,
			async: this.bulkActionMode === UserBulkActionMode.ASYNC
				|| this.isMatchAllMode()
		};

		queryParameters = _.extend(queryParameters, this.createQueryParameters());
		return queryParameters;
	}

	isMatchAllMode = (): boolean => {
		return UserSelectionMethod.ALL_MATCHING_FILTERS === this.selection.method;
	}

	updateBulkActionMode = () => {
		this.bulkActionMode = this.selectedGroups.length > this.groupPagination.pageSize
			? UserBulkActionMode.ASYNC
			: UserBulkActionMode.SYNC;
	}

	removeGroup = (group: Group) => {
		if (this.isHierarchyDerivedGroup(group))
			return;
		let dlg = this.cbDialogService.danger(
			this.locale.getString('common.confirmRemoval'),
			this.locale.getString('administration.deleteGroupFromMA', { masterAccount: this.currentMasterAccountName}),
			this.locale.getString('common.remove'),
			this.locale.getString('common.cancel'));
		dlg.result.then(() => {
			this.promises.loadingGroup = this.usersGroupsApiService.removeGroup(group.groupId)
				.then(() => {
					this.$log.debug('Group deleted:', group);
					this.reloadGroups();
				});
		});
	}

	addEditGroup = (groupPromise, action) => {
		let dlg = this.$uibModal.open({
			component: 'groupEditModal',
			windowClass: 'modal-xl',
			backdrop: 'static',
			resolve: {
				groupItem: () => groupPromise,
				permissionItems: () => this.permissions,
				action: () => action
			}
		});

		dlg.result.then((result) => {
			let item = result.group;
			let subscriptions = result.subscriptions;
			let assetsToHide = result.assetsAccess;

			let groupData: any = {
				groupName: item.groupName,
				description: item.description,
				ownerEmail: item.ownerEmail,
				type: item.type
			};

			groupData.masterAccountId = this.security.getMasterAccountId();
			groupData.masterAccountPermissions = item.permissions;

			// Need to add accountId in the each permission object
			if (groupData.masterAccountPermissions) {
				for (let permissions of groupData.masterAccountPermissions) {
					permissions.masterAccountId = groupData.masterAccountId;
				}
			}

			if (action === 'add') {
				this.usersGroupsApiService.addGroup(groupData)
					.then((resp) => {
						let response = resp.data;

						this.$log.debug('Group updated:', groupData);
						let groupId = response.groupId;
						this.updateSubscriptions(groupId, subscriptions);
						this.updateAssetsAccess(groupId, assetsToHide);
						if (!_.isEmpty(result.addedUsersIds)) {
							groupData.groupId = groupId;
							this.userModificationService.updateUsersInGroup(groupData, {
								added: result.addedUsersIds,
								removed: []
							});
						}
						this.reloadGroups();
						this.reloadPermissions();
					});
			} else {
				groupData.groupId = item.groupId;
				this.usersGroupsApiService.editGroup(groupData)
					.then(() => {
						this.$log.debug('Group updated:', groupData);
						this.updateSubscriptions(item.groupId, subscriptions);
						this.reloadGroups();
						this.reloadPermissions();
					});

				if (!_.isEmpty(result.addedUsersIds) || !_.isEmpty(result.removedUsersIds)) {
					this.userModificationService.updateUsersInGroup(groupData, {
						added: result.addedUsersIds,
						removed: result.removedUsersIds
					});
				}
			}
		});
	}

	updateSubscriptions = (groupId, subscriptions) => {
		if (subscriptions && subscriptions.length > 0) {
			this.usersGroupsApiService.updateGroupSubscriptions(groupId, subscriptions)
				.then(() => {
					this.$log.debug('Subscriptions updated:', subscriptions);
				});
		}
	}

	updateAssetsAccess = (groupId: number, assetsAccess: IAssetAccess[]) => {
		if (assetsAccess && assetsAccess.length > 0) {
			let items = _.reject(assetsAccess, item => _.isEmpty(item.hiddenAssets));
			this.assetAccessApiService.setHiddenAssetsForNewGroup(groupId, items);
		}
	}

	editGroup = (group, action?) => {
		let fullGroup = this.usersGroupsApiService.getFullGroup(group.groupId).then(this.httpHandlers.success);
		this.promises.loadingGroup = fullGroup;
		action = action || 'edit';
		this.addEditGroup(fullGroup, action);
	}

	updatePermissions = (group) => this.editGroup(group, 'permission');

	updateMembership = (group) => this.editGroup(group, 'member');

	addGroup = () => {
		let group = {
			groupName: '',
			description: '',
			ownerEmail: this.security.getEmail()
		};
		this.addEditGroup(this.$q.when(group), 'add');
	}

	exportGroupData = () => {
		let queryParams = this.createQueryParameters();
		this.exportService.exportGroupDataReport(queryParams).then(() => {
			this.cbDialogService.notify(this.locale.getString('administration.exportUserData'), this.locale.getString('administration.exportUserDataMessage'));
		});
	}

	private createQueryParameters = (): GroupQueryParams => {
		let properties: GroupQueryParams = {};
		properties.filterText = this.groupQuery;
		properties.filterField = this.groupQueryBy;
		properties.filterMode = this.groupQueryMode;
		return properties;
	}

	isHierarchyDerivedGroup = (group) => {
		return group && (group.type === GroupType.HIERARCHY || group.type === GroupType.WHOLE_HIERARCHY);
	}

	isWholeHierarchyGroup = (group) => {
		return group.type === GroupType.WHOLE_HIERARCHY;
	}

	isFilteredByGroupType = (): boolean => {
		return this.groupQueryBy === GroupFilterOption.TYPE;
	}

	isFilteredByMembers = (): boolean => {
		return this.groupQueryBy === GroupFilterOption.MEMBERS;
	}
}

app.component('groups', {
	bindings: {
		permissions: '<',
		reloadPermissions: '<',
		reloadGroups: '<',
		groups: '<',
		promises: '<',
	},
	controller: GroupsComponent,
	templateUrl: 'partials/user-administration/groups/groups.component.html'
});
