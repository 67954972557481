import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core';
import { AnalyticsHelperService } from '@app/modules/analytics/analytics-helper.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';

@Component({
	selector: 'site-intercept',
	template: `
<div *ngIf="isEnabled()"
	#container
	[innerHTML]="html">
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteInterceptComponent implements OnInit, AfterViewInit {
	readonly QSI = 'QSI';
	readonly QUALTRICS = 'Qualtrics';

	@ViewChild('container') container: ElementRef<HTMLElement>;

	html: SafeHtml;

	constructor(
		private readonly domSanitizer: DomSanitizer,
		private readonly locale: CxLocaleService,
		private readonly analyticsHelperService: AnalyticsHelperService,
		@Inject(Window) private window: Window,
		@Inject('security') private readonly security: Security,
		@Inject('environmentService') private environmentService: EnvironmentService,
		@Inject('maPropertiesService') private maPropertiesService: MAPropertiesService,
	) {}

	ngOnInit(): void {
		if (!this.isEnabled())
			return;
		const defaultAccountId = this.security.getUser().defaultMasterAccountId;
		const defaultAccountName = this.security.getMasterAccounts()
			.find(account => account.accountId === defaultAccountId)
			?.accountName;
		let qsiUserData = {
			id: this.security.getUser().userId,
			email: this.security.getUser().userEmail,
			firstName: this.security.getUser().firstName,
			lastName: this.security.getUser().lastName,
			xmAccountId: this.security.getUser().xmAccountId,
			defaultAccountId,
			defaultAccountName,
			clarabridgeEmployee: this.security.isQualtricsEmployee(),
			accountId: this.security.getCurrentMasterAccount().accountId,
			accountName: this.security.getCurrentMasterAccount().accountName,
			licenseType: this.security.getLicenseType(),
			locale: this.locale.getLocale(),
			dateFormat: this.security.getContext().dateFormat,
			theme: this.security.getContext().applicationTheme
		};

		let qsi = this.window[this.QSI] || {};
		qsi.config = qsi.config || {};
		qsi.config.externalReference = this.analyticsHelperService.getUserUniqueIdentifier();

		this.window[this.QSI] = qsi;
		this.window[this.QUALTRICS] = { user: qsiUserData };
		this.html = this.domSanitizer.bypassSecurityTrustHtml(`<!--BEGIN QUALTRICS WEBSITE FEEDBACK SNIPPET--><script type='text/javascript'>(function(){var g=function(e,h,f,g){this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};try{(new g(100,"r","QSI_S_ZN_1z7Nk7lriGyebiK","https://zn1z7nk7lrigyebik-qxm.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_1z7Nk7lriGyebiK")).start()}catch(i){}})();</script><div id='ZN_1z7Nk7lriGyebiK'><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div><!--END WEBSITE FEEDBACK SNIPPET-->`);
	}

	isEnabled(): boolean {
		return this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.QUALTRICS_SITE_INTERCEPT)
			&& !this.environmentService.isPdfExport() && !this.environmentService.isUnderTest();
	}

	ngAfterViewInit(): void {
		if (!this.isEnabled())
			return;
		// this html is provided in settings of qualtrics account which is responsible for feedback gathering
		setTimeout(() => { //wait for DOM
			let scripts = this.container.nativeElement.getElementsByTagName('script');
			for (let script of scripts) {
				// tslint:disable-next-line: no-eval
				eval(script.text);
			}
		});
	}
}

app.directive('siteIntercept', downgradeComponent({component: SiteInterceptComponent}) as angular.IDirectiveFactory);
