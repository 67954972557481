

export enum GridContext {
	DASHBOARD = 'dashboard',
	METRIC = 'metricStudio',
	METRIC_PREDEFINED = 'metricPredefined',
	METRIC_SCORECARD = 'metricScorecard',
	DASHBOARD_SET = 'dashboardSet',
	DASHBOARD_TEMPLATE = 'dashboardTemplate',
	DRIVERS = 'drivers',
	METRIC_TEMPLATE = 'metricTemplate'
}


