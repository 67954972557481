import { NgModule } from '@angular/core';
import { ArchiveGridDefinition } from '@app/modules/archive/archive-page/archive-grid-definition.service';
import { HeaderModule } from '@app/modules/header/header.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { SharedModule } from '@app/shared/shared.module';
import { ArchivePageComponent } from './archive-page/archive-page.component';

@NgModule({
	imports: [
		SharedModule,
		HeaderModule,
		ItemGridModule
	],
	exports: [
		ArchivePageComponent
	],
	declarations: [
		ArchivePageComponent
	],
	providers: [
		ArchiveGridDefinition
	],
})
export class ArchiveModule {}
