<div [ngBusy]="loadingPromise">
	<modal-header
		(cancel)="cancel()"
		[modalTitle]="'cases.alertHierarchySelectionModalTitle'|i18n"
	></modal-header>

	<div class="modal-body">
		<div class="alert-hierarchy-selection">
			<searchable-hierarchy
				[hierarchyList]="hierarchyLevelTree"
				displayProperty="displayName"
				[folderClickIgnore]="true"
				[ngShowNodeCheckbox]="showNodeCheckbox"
				[ngNodeIsChecked]="isNodeChecked"
				(onNodeClick)="handleNodeClick($event)"
				[selectEverythingText]="selectEverythingText"
				[selectNoneText]="selectNoneText"
				[highlightSelectedBackground]="highlightSelectedBackground"
				[showMultiselectButtons]="showMultiselectButtons"
				[hideSearch]="hideSearch"
			></searchable-hierarchy>
		</div>
	</div>

	<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
	></save-modal-footer>
</div>
