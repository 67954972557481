export interface JmsListenersState {
	emailListeners: EmailListenerState[];
}

export interface JmsListenerState {
	status: ListenerStatus;
}

export enum ListenerStatus {
	OK = 'OK',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED'
}

export interface EmailListenerState extends JmsListenerState {
	nodeId: string;
	timestamp: string;
	type: string;
	currentMetrics: EmailListenerMetrics;
	previousMetrics: EmailListenerMetrics;
}

export interface EmailListenerMetrics {
	queueSize: number;
	dequeueCount: number;
}
