<div class="d-flex flex-direction-column w-100-percent h-100-percent overflow-hidden">
	<header class="flex-fixed d-flex flex-direction-row justify-end w-100-percent pv-8">
		<menu ngbDropdown class="m-0">
			<button ngbDropdownToggle
				[attr.aria-label]="'common.menu'"
				aria-haspopup="true"
				class="q-icon-dots icon-rotate-90 btn btn-icon border-0"
				title="{{'common.openMenu'|i18n}}"></button>
			<ul ngbDropdownMenu role="menu">
				<li ngbDropdownItem
					(click)="reload.emit()"
					[i18n]="'dashboard.refresh'">
				</li>
			</ul>
		</menu>
	</header>
	<main class="flex-fill">
		<div class="h-100-percent justify-center pb-48 d-flex flex-direction-column align-center br-brand-primary-background">
			<ng-container *ngIf="isGenericError()">
				<span class="mb-8" [innerHTML]="message"></span>
			</ng-container>
		</div>
	</main>
</div>
