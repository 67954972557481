import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LogoSettingsServiceClass } from '../logo-settings/logo-settings.service';
import { LogoState } from './logo-state.enum';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'logo-preview',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<section>
	<div [style.background-color]="(brandingColors$ | async).COLOR_1" class="custom-logo logo-preview" *ngIf="(logoSettings.logoState$ | async) === LogoState.PREVIEW">
		<img [src]="logoUrl$ | async"/>
	</div>

	<div *ngIf="(logoSettings.logoState$ | async) === LogoState.DEFAULT"
		class="custom-logo pl-16 h2"
		[style.background-color]="(brandingColors$ | async).COLOR_1"
		[style.color]="(brandingColors$ | async).COLOR_6"
		[i18n]="'appearance.logoPreviewText'">
	</div>

	<div *ngIf="(logoSettings.logoState$ | async) === LogoState.APPLIED"
		class="custom-logo"
		[style.background-color]="(brandingColors$ | async).COLOR_1"
		[ngStyle]="getLogoStyle()"></div>
</section>`
})
export class LogoPreviewComponent extends SelfCleaningComponent implements OnInit {
	@Input('brandingColors') brandingColors$: Observable<{[key: string]: string}>;
	brandingColors: {[key: string]: string};

	private reader: FileReader;
	logoUrl$: Observable<string>;
	logoUrlSubject: Subject<string>;
	LogoState = LogoState;

	constructor(
		private readonly ref: ChangeDetectorRef,
		readonly logoSettings: LogoSettingsServiceClass
	) {
		super();
	}

	ngOnInit(): void {
		this.reader = new FileReader();
		this.reader.onload = (event) => this.onImageLoad(event);

		this.addSubscription(this.logoSettings.logoChange$.subscribe((newFile: File) => {
			this.reader.readAsDataURL(newFile);
		}));

		this.logoUrlSubject = new Subject();
		this.logoUrl$ = this.logoUrlSubject.asObservable();
		this.logoSettings.updateLogoState();
	}

	private onImageLoad(event): void {
		this.logoUrlSubject.next(event.target.result);
		this.ref.detectChanges();
	}

	getLogoStyle = (): { 'background-image': string } => {
		return {
			'background-image': `url(${this.logoSettings.getLogoUrl()})`,
		};
	}
}
