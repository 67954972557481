import { ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

export abstract class BaseControlValueAccessor<T> implements ControlValueAccessor {
	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	protected onTouchedCallback: () => void = _.noop;
	protected onChangeCallback: (val: T) => void = _.noop;

	innerValue: T;
	disabled: boolean;

	constructor(protected ref: ChangeDetectorRef, protected defaultValue?: T) {}

	onChange(value: any): void {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.onChangeCallback(this.innerValue);
			this.onTouchedCallback();
		}
	}

	//From ControlValueAccessor interface
	writeValue(value: any): void {
		if (value === null && !this.onChangeCallback) {
			// ControlValueAccessor is created with initial null value
			// https://github.com/angular/angular/issues/14988
			return;
		}
		if (value === undefined && this.defaultValue !== undefined) {
			this.onChange(this.defaultValue);
		} else {
			this.innerValue = value;
		}
		this.ref.markForCheck();
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	//From ControlValueAccessor interface
	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.ref.markForCheck();
	}
}
