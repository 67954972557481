import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { PlatformTabComponent } from './platform-tab/platform-tab.component';
import { ContentProviderVersionCheckComponent } from './content-provider-version-check/content-provider-version-check.component';
import { JmsFailedMessagesComponent } from './jms-failed-messages/jms-failed-messages.component';
import { NgPipesModule } from 'ngx-pipes';
import { WorkspaceManagementComponent } from './workspace/workspace-management/workspace-management.component';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { WorkspacesGridDefinition } from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-grid-definition.service';
import { WorkspaceEditDialogComponent } from './workspace/workspace-edit-dialog/workspace-edit-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { SysAdminContentProvidersComponent } from './content-provider/sys-admin-content-providers/sys-admin-content-providers.component';
import { SystemDictionaryTabComponent } from '@app/modules/system-administration/platform/system-dictionary/system-dictionary-tab.component';



@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		ItemGridModule,
		DialogModule,
		NgPipesModule,
		ReactiveFormsModule,
		CxFormModule,
	],
	exports: [
		PlatformTabComponent,
	],
	declarations: [
		PlatformTabComponent,
		ContentProviderVersionCheckComponent,
		JmsFailedMessagesComponent,
		WorkspaceManagementComponent,
		WorkspaceEditDialogComponent,
		SysAdminContentProvidersComponent,
		SystemDictionaryTabComponent,
	],
	providers: [
		WorkspacesGridDefinition,
	]

})
export class PlatformModule { }
