import { AttributeValues } from '@app/modules/project/attribute/attribute-values';
import { ScorecardInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { IDocumentClassification } from '../document-explorer/conversations/conversation-document.class';
import { PreviewSentence } from '../preview/preview-sentence-class';

export class CbDocument {
	id: number;
	documentDate: string;
	natural_id: string;
	classification: IDocumentClassification[];
	scorecards?: ScorecardInDocView[];
	attributes: AttributeValues[];
	sm_service?: string;	// could be an enum if we knew all the values
	sentences: PreviewSentence[];
}
