import { NgModule } from '@angular/core';
import { ScrollCarouselComponent } from '@app/modules/scroll-carousel/scroll-carousel/scroll-carousel.component';

@NgModule({
	exports: [
		ScrollCarouselComponent
	],
	declarations: [
		ScrollCarouselComponent
	],
})
export class ScrollCarouselModule {}
