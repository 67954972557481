import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'negate'
})

export class NegatePipe implements PipeTransform {
	transform(object: any): boolean {
		return !object;
	}
}