export enum Caches {
	FILTERS = 'filters',
	METRICS = 'metrics',
	DRIVERS = 'drivers',
	ATTRIBUTES = 'attributes',
	MODELS = 'models',
	PALETTES = 'palettes',
	SPINE_SETTINGS = 'spine_settings',
	GEOGRAPHIES = 'geographies',
	DASHBOARD_LIST_TABS = 'dashboard_list_tabs',
	ASSET_VISIBILITY = 'asset_visibility',
	PERMISSIONS = 'permissions',
	LICENSE_TYPES = 'license_types',
	ENGAGOR = 'engagor',
	NARRATIVES = 'narratives',
	WIDGET_TEMPLATES = 'widget_templates',
	SUBSCRIPTION_TEMPLATES = 'subscription_templates',
	PROJECT_CONTEXT = 'project_context',
	LOCATION = 'location',
	ORGANIZATIONS = 'organizations'
}


