import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Security } from '@cxstudio/auth/security-service';
import Authorization from '@cxstudio/auth/authorization-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

@Component({
	selector: 'project-assets-page',
	template: `
<div class="pt-0 h-100-percent w-100-percent d-flex flex-direction-column">
	<page-content-header [title]="'common.projects' | i18n" class="mb-24"></page-content-header>

	<projects-management *ngIf="!newProjectsPage" class="flex-fill"></projects-management>
	<project-assets-list *ngIf="newProjectsPage" class="flex-fill"></project-assets-list>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAssetsPageComponent implements OnInit {

	newProjectsPage: boolean;

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		@Inject('security') private security: Security,
		@Inject('authorization') private authorization: Authorization,
	) { }

	ngOnInit(): void {
		this.security.restrictPage(() => {
			return this.authorization.hasViewProjectsAccess();
		});
		this.newProjectsPage = this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_PROJECTS_PAGE);
	}

}

app.directive('projectAssetsPage', downgradeComponent({component: ProjectAssetsPageComponent}) as angular.IDirectiveFactory);
