import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIOption } from '@clarabridge/unified-angular-components';

@Component({
	selector: 'split-button-menu',
	templateUrl: './split-button-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonMenuComponent {

	@Input() collapsedAt: number = 1;
	@Input() options: UIOption<unknown>[] = [];
	@Output() menuClick = new EventEmitter<UIOption<unknown>>();

	clickMenu(menu: UIOption<unknown>): void {
		this.menuClick.emit(menu);
	}
}

app.directive('splitButtonMenu', downgradeComponent({component: SplitButtonMenuComponent}) as angular.IDirectiveFactory);
