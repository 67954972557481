import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { ObjectUtils } from '@app/util/object-utils';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ExportUtils, IExportAssets } from '@cxstudio/reports/utils/export/export-utils.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';

export interface IExportOptions {
	hasChanges?: boolean;
	statsMode?: boolean;
	showEnrichment?: boolean;
}

type AllExportOptions = IExportOptions & IExportAssets;

interface ICSVData {
	dashboardId: any;
	widgetId: any;
	filename: string;
	data: string;
	name: string;
}

@Injectable({
	providedIn: 'root'
})
export class ReportExportUtilsService {

	constructor(
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('exportApiService') private exportApiService: ExportApiService,
		@Inject('tableService') private tableService,
		@Inject('customFilterService') private customFilterService: CustomFilterService
	) { }

	exportToExcel(
		dataObject: ReportDataObject,
		widget: Widget,
		dashboardHistory: IDashboardHistoryInstance,
		utils: WidgetUtils,
		widgetMetadata: WidgetFiltersMetadata
	): ng.IPromise<void> {

		let widgetSettings = ObjectUtils.copy(widget);
		let data = this.prepareWidgetData(dataObject, widgetSettings);
		let exportOptions: IExportOptions = { hasChanges: dashboardHistory.isViewFilterChanged() };

		return this.prepareExportAssets(widgetSettings, data, exportOptions).then(allOptions => {
			const columnOptions = utils ? utils.allColumns : undefined;
			this.customFilterService.updateAppliedFilters(allOptions.options.newFilters, widgetMetadata.appliedFilters);
			return this.exportUtils.getWidgetDataForExport(widgetSettings, data, allOptions, columnOptions, widgetMetadata).then((csv) => {
				let exportData = {} as ICSVData;
				exportData.name = widget.displayName;
				exportData.filename = this.exportUtils.reportExportFilename(widget, 'xlsx', dataObject.metadata?.timeStampMark);
				exportData.widgetId = widget.id;
				exportData.dashboardId = widget.dashboardId;
				exportData.data = csv;
				return this.exportApiService.convertToExcel(exportData).then((resp) => {
					this.exportUtils.exportXLSX(exportData.filename, resp.data);
				});
			});
		});
	}

	prepareWidgetData(dataObject: ReportDataObject, widget: Widget): ReportDataObject {
		const data = ObjectUtils.copy(dataObject);
		if (data) {
			let popLevel;
			if (widget.properties.useHistoricPeriod) {
				popLevel = DualDefinitionHelper.getPopLevel(widget.visualProperties);
			}
			data.data = this.tableService.getProcessedTableData(data.data, widget.properties.selectedAttributes, popLevel);
		}
		return data;
	}

	prepareExportAssets(widget: Widget, data: ReportDataObject, exportOptions: IExportOptions): ng.IPromise<AllExportOptions> {
		return this.exportUtils.getCBExportOptions(widget, data).then((allOptions) => {
			return _.extend(allOptions, exportOptions);
		});
	}
}

app.service('reportExportUtils', downgradeInjectable(ReportExportUtilsService));
