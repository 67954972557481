import { TextToken } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';

export enum MathMetricTokenType {
	METRIC = 'metric',
	PREDEFINED_METRIC = 'predefined-metric',
	NUMERIC_ATTRIBUTE = 'attribute-number',
	TEXT_ATTRIBUTE = 'attribute-text',
	GENERIC_AGGREGATION = 'generic-function',
	NUMERIC_AGGREGATION = 'numeric-function',
	TEXT_AGGREGATION = 'text-function',
	SCORECARD_METRIC = 'scorecard-metric',
	HIERARCHY_METRIC = 'hierarchy-metric',
	RESERVED_WORD = 'reserved-word',
	PREFIX = 'prefix'
}

export interface MathMetricToken extends TextToken {
	offset: number;
}