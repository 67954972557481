import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import { SentenceScorecardTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import * as CBIcons from '@clarabridge/unified-icons/src/codepoints.json';
interface IResultIcon {
	css: string;
	code: number;
	key?: string;
}

export interface Icon {
	css: string;
	color: string;
}

interface ScorecardTopic {
	result: ScorecardTopicResult;
	weight: number;
	rebutted: boolean;
}

export class ScorecardDocExplorerUtils {

	private static readonly AUTO_FAIL_ICON = {css: 'q-icon-accessdenied', code: CBIcons.accessdenied, key: 'scorecards.autoFail'};
	private static readonly FAILING_ICON = {css: 'q-icon-delete', code: CBIcons.delete, key: 'scorecards.failed'};
	private static readonly PASSING_ICON = {css: 'q-icon-check', code: CBIcons.check, key: 'scorecards.passed'};
	private static readonly NOT_CONSIDERED_ICON = {css: 'q-icon-minus', code: CBIcons.minus, key: 'scorecards.notConsidered'};
	private static readonly REBUTTED_ICON: IResultIcon = {css: 'q-icon-warning', code: CBIcons.warning};

	private static readonly GREEN = 'var(--success-600)';
	private static readonly RED = 'var(--neg-sentiment-600)';
	private static readonly GREY = 'var(--gray-500)';
	private static readonly WARNING = 'var(--warning-600)';

	private static readonly REBUTTED_WEIGHT = Number.MAX_VALUE;
	private static readonly AUTOFAIL_WEIGHT = ScorecardDocExplorerUtils.REBUTTED_WEIGHT - 1;

	private static getTopicResult(result: ScorecardTopicResult): IResultIcon {
		switch (result) {
			case ScorecardTopicResult.PASSED:
				return this.PASSING_ICON;
			case ScorecardTopicResult.FAILED:
				return this.FAILING_ICON;
			case ScorecardTopicResult.AUTO_FAIL:
				return this.AUTO_FAIL_ICON;
			default:
				return this.NOT_CONSIDERED_ICON;
		}
	}

	static getTopicResultIcon(result: ScorecardTopicResult): string {
		return ScorecardDocExplorerUtils.getTopicResult(result).css;
	}
	static getTopicResultIconCode(result: ScorecardTopicResult): number {
		return ScorecardDocExplorerUtils.getTopicResult(result).code;
	}
	static getTopicResultIconKey(result: ScorecardTopicResult): string {
		return ScorecardDocExplorerUtils.getTopicResult(result).key;
	}

	static getTopicResultColor(result: ScorecardTopicResult): string {
		switch (result) {
			case ScorecardTopicResult.PASSED:
				return this.GREEN;
			case ScorecardTopicResult.FAILED:
			case ScorecardTopicResult.AUTO_FAIL:
				return this.RED;
			default:
				return this.GREY;
		}
	}

	static getColorArray(): string[] {
		return [this.GREY, this.GREEN, this.RED, this.WARNING];
	}

	static getColorIndex(topic: SentenceScorecardTopic): number {
		return topic.rebutted ? 3 : this.getTopicResultColorIndex(topic.result);
	}

	private static getTopicResultColorIndex(result: ScorecardTopicResult): number {
		switch (result) {
			case ScorecardTopicResult.PASSED:
				return 1;
			case ScorecardTopicResult.FAILED:
			case ScorecardTopicResult.AUTO_FAIL:
				return 2;
			default:
				return 0;
		}
	}

	static getTopicColor(topic: SentenceScorecardTopic): string {
		if (topic.rebutted) {
			return this.getRebuttedIcon().color;
		} else {
			return this.getTopicResultColor(topic.result);
		}
	}

	static getTopicIcon(topic: SentenceScorecardTopic): string {
		if (topic.rebutted) {
			return this.getRebuttedIcon().css;
		} else {
			return this.getTopicResultIcon(topic.result);
		}
	}

	static getTopicIconCode(topic: SentenceScorecardTopic): number {
		if (topic.rebutted) {
			return this.REBUTTED_ICON.code;
		} else {
			return this.getTopicResultIconCode(topic.result);
		}
	}

	static getRebuttedIcon(): Icon {
		return {
			css: this.REBUTTED_ICON.css,
			color: this.WARNING
		};
	}

	static getTopicWeight(scorecardTopic: ScorecardTopic): number {
		if (scorecardTopic.rebutted) return this.REBUTTED_WEIGHT;
		if (scorecardTopic.result === ScorecardTopicResult.AUTO_FAIL) return this.AUTOFAIL_WEIGHT;
		return scorecardTopic.weight;
	}
}
