import { SelectorDisplaySize } from '@app/modules/widget-settings/selector-display-size.class';
import { DirectionalOrientation } from '@cxstudio/common/orientation';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';

export class SelectorWidgetVisualProperties extends VisualProperties {
	visualization: SelectorWidgetNavigationType;
	navigationType: SelectorWidgetNavigationType;
	displaySize: SelectorDisplaySize;
	orientation: DirectionalOrientation;
	backgroundColor: WidgetBackgroundColor;
	font: string;
	multiselect?: boolean;
}
