<widget-settings>
	<div *ngIf="isProjectSelected()">
		<sidebar-section [header]="'common.settings'|i18n">
			<div>
				<label class="mb-8 font-semibold" for="widget-type">{{'object.widget'|i18n}}</label>
				<div class="w-100-percent">
					<button disabled class="btn border-1 border-solid border-gray-500 disabled d-flex
					align-items-center w-100-percent opacity-100 color-inherit bg-gray-300">
						<span class="q-icon-cloud mr-8"></span>
						<span>{{'widget.cb_an_cloud' | i18n }}</span>
					</button>
				</div>
			</div>
			<div class="mt-8">
				<label class="mb-8 font-semibold" for="date-range">{{'widget.dateRange' | i18n}}</label>
				<div class="w-100-percent">
					<report-period-selector
						[project]="project"
						[properties]="widget.properties"
						[visualProperties]="widget.visualProperties"
						[templateWidget]="widget.template"
						[periods]="periods"
						(selectionChange)="updateChart()">
					</report-period-selector>
				</div>
			</div>
		</sidebar-section>
		<sidebar-section [header]="'widget.terms'|i18n">
			<div>
				<label class="mb-8 font-semibold" for="group-by">{{'widget.groupBy'|i18n}}</label>
				<report-grouping-selector
					id="group-by"
					[project]="project"
					[personalizationEnabled]="isPersonalizationEnabled()"
					[appliedOrgHierarchyId]="getAppliedOrgHierarchyId()"
					[selection]="widget.properties.selectedAttributes[0]"
					(selectionChange)="onGroupingChange($event)"
					[defaultSelectionPath]="defaultSelectionPath"
					[customDefaults]="{ size: 100 }"
					(openProperties)="openProperties($event, groupingPropertiesTemplate)">
				</report-grouping-selector>
				<ng-template #groupingPropertiesTemplate let-popup>
					<report-grouping-properties
						[project]="project"
						[grouping]="widget.properties.selectedAttributes[0]"
						(groupingChange)="popup.setEntity($event)"
						(validityChange)="popup.setValid($event)"
						[documentLevelOnly]="widget.properties.documentLevelOnly"
						[sentenceLevel]="isSentenceLevel(widget.properties.selectedAttributes[0])"
						[peerHierarchyGrouping]="isPeerReportHierarchyGrouping(widget.properties.selectedAttributes[0])"
						[selectedMetrics]="widget.properties.selectedMetrics"
						[widgetType]="widget.properties.widgetType">
					</report-grouping-properties>
				</ng-template>
			</div>
			<div class="mt-8">
				<label class="mb-8 font-semibold" for="size-by">{{'widget.sizeBy'|i18n}}</label>
				<report-calculation-selector
					id="size-by"
					[hierarchyGrouping]="hierarchyGrouping"
					[project]="project"
					[selection]="widget.visualProperties.attributeSelections.size"
					(selectionChange)="onCalculationChange($event)"
					(openProperties)="openProperties($event, calculationPropertiesTemplate)"
				></report-calculation-selector>

				<ng-template #calculationPropertiesTemplate let-popup>
					<report-calculation-properties
						[project]="project"
						[calculation]="widget.visualProperties.attributeSelections.size"
						(calculationChange)="popup.setEntity($event)"
						(validityChange)="popup.setValid($event)">
					</report-calculation-properties>
				</ng-template>
			</div>
			<div class="mt-8 d-flex justify-between align-center">
				<label class="mb-0 font-semibold" for="sizing">{{'widget.cloudLinearRelativeSizing'|i18n}}</label>
				<cb-toggle id="sizing"
					[attr.aria-label]="'common.toggleObject' | i18n:{objectName: ('widget.cloudLinearRelativeSizing' | i18n)}"
					[ngModel]="widget.visualProperties.cloudSizing === CloudSizing.LINEAR"
					(ngModelChange)="onSizingToggle($event)"
				></cb-toggle>
			</div>
			<div class="mt-8">
				<label class="mb-8 font-semibold" for="color-by">{{'widget.colorBy'|i18n}}</label>
				<report-color-selector
					[project]="project"
					[selectedAttributes]="widget.properties.selectedAttributes"
					[selectedMetrics]="widget.properties.selectedMetrics"
					[documentLevelOnly]="widget.properties.documentLevelOnly"
					[filter]="colorSelectorFilter"
					(calculationChange)="onColorMetricChange($event)"
					[selection]="widget.visualProperties.color"
					(selectionChange)="updateColorSelection($event)"
					[customColor]="widget.visualProperties.customColor"
					(customColorChange)="onCustomColorChange($event)"
				></report-color-selector>
			</div>
		</sidebar-section>
		<sidebar-section [header]="'widget.display'|i18n">
			<div>
				<label class="mb-8 font-semibold" for="orientations">{{'widget.orientations'|i18n}}</label>
				<cb-push-buttons id="orientations"
					[list]="orientationOptions"
					[(selectedValue)]="widget.visualProperties.orientations"
					(selectedValueChange)="updateChartUI()"
				></cb-push-buttons>
			</div>
			<div class="mt-8">
				<label class="mb-8 font-semibold" for="shape">{{'widget.cloudShape'|i18n}}</label>
				<cb-push-buttons id="shape"
					[list]="shapeOptions"
					[(selectedValue)]="widget.visualProperties.cloudShape"
					(selectedValueChange)="updateChartUI()"
				></cb-push-buttons>
			</div>
			<div class="mt-8 d-flex justify-between align-center">
				<label class="mb-0 font-semibold" for="sample-size">{{'widget.showSampleSize'|i18n}}</label>
				<cb-toggle id="sample-size"
					[attr.aria-label]="'common.toggleObject' | i18n:{objectName: ('widget.showSampleSize' | i18n)}"
					[(ngModel)]="widget.visualProperties.showSampleSize"
					(ngModelChange)="updateChartUI()"
				></cb-toggle>
			</div>
			<div class="mt-8 d-flex justify-between align-center">
				<label class="mb-0 font-semibold" for="minimum-font-size">{{'widget.minimumFontSize'|i18n}}</label>
				<input id="minimum-font-size"
					class="w-64 ph-8 text-right"
					type="number"
					[strictMin]="4"
					[strictMax]="32"
					[(ngModel)]="widget.visualProperties.minimumFontSize"
					(ngModelChange)="updateChartUI()">
			</div>
		</sidebar-section>
	</div>
</widget-settings>
