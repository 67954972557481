import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { MenuOptionsUtils } from '@cxstudio/reports/utils/contextMenu/drill/drill-options/menu-options-utils.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import * as _ from 'underscore';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { ContextMenuItemType } from '@app/shared/components/kebab-menu/context-menu-item';
import { ContextMenuItem } from './context-menu-item';
import { MenuOptionType } from './drill-menu-option.component';


export class MenuOptionSubmenuController implements ng.IController {

	private readonly DEFAULT_INDENTATION = 8;
	private readonly SINGLE_LEVEL_INDENTATION = 18;
	private readonly NO_CHILDREN_INDENTATION = 11;

	option: IDrillMenuOption;
	bounded: boolean;
	object: DrillPoint;
	level: number;
	onAction: () => void;
	onSelect: ({$option}) => void;

	searchFilter: string;
	isSearchStringAvailable: boolean;
	activeChild: any;
	subMenuItems: IDrillMenuOption[];
	loadSubmenu: ng.IPromise<any>;
	applyOptionPromise: ng.IPromise<any>;
	expandableTree: any[];

	constructor(
		private $scope: ISimpleScope,
		private $q: ng.IQService,
		private contextMenuTree: ContextMenuTree,
		private menuOptionsUtils: MenuOptionsUtils,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService
	) {}

	$onInit(): void {
		this.$scope.$watch(() => this.isSearchStringAvailable, (newValue, oldValue) => {
			if (newValue === oldValue) {
				return;
			}
			this.refreshSubmenuOptions();
		});

		this.isSearchStringAvailable = false;

		this.nestedMenuHandler();
	}

	onSubmenuItemKeydown(event, item): void {
		if (item.focusSubmenuOption && !item.disabled && KeyboardUtils.isEventKey(event, Key.ENTER)) {
			KeyboardUtils.intercept(event);
			if (this.getSubmenuItemType(item) === MenuOptionType.ACTION) {
				$(event.target).find('li#actionOption').trigger('click');
			}
		}
	}

	// basically identical to DrillMenuOptionController.getOptionType
	// consider refactor
	private getSubmenuItemType = (item) => {
		if (item === null || item.isDivider) {
			return MenuOptionType.DIVIDER;
		}

		if (item.tree) {
			return MenuOptionType.TREE;
		}

		if (item.selectable) {
			return MenuOptionType.SELECTABLE;
		}

		if (item.items) {
			return MenuOptionType.NESTED;
		}

		return MenuOptionType.ACTION;
	}

	toggleSubmenuItem = (item, $event) => {
		this.activeChild = item;
		this.applyOptionPromise = this.menuOptionsUtils.applyNestedFunctions(this.object, item, $event);
	}
	nestedMenuHandler = () => {
		this.refreshSubmenuOptions();
		this.applyOptionPromise = this.menuOptionsUtils.applyNestedFunctions(this.object, this.option);
	}

	private checkSubmenuPosition(): void {
		let offset = this.$element.parent().offset();
		if (!offset)
			return;
		let elementY = offset.top;

		let menuHeight = this.$element.outerHeight();
		let containerHeight = this.contextMenuTree.getContainerHeight();

		if (elementY + menuHeight > containerHeight) {
			// doesn't fit downward
			// using bottom to avoid flickering
			let diff = containerHeight - elementY - this.contextMenuTree.OPTION_HEIGHT;
			this.$element.css('top', 'auto');
			this.$element.css('bottom', `${-diff}px`);
		} else {
			this.$element.css('top', '');
			this.$element.css('bottom', '');
		}
	}

	private adjustSubmenuPosition(): void {
		let elementTop = this.$element.offset().top;
		let menuHeight = this.$element.outerHeight();
		let containerHeight = this.contextMenuTree.getContainerHeight();

		if (elementTop + menuHeight > containerHeight) {
			if (typeof this.option.items === 'function') {
				this.option.items().then(menuItems => {
					let hasTree = _.some(menuItems, (item) => {
						return item !== null && !_.isUndefined(item.tree);
					});

					if (hasTree) {
						this.$element.css('top', 'auto');
						this.$element.css('bottom', '-10px');
						return;
					}
				});
			}

			let diff = containerHeight - elementTop - menuHeight - 10;
			this.$element.css('top', `${diff}px`);
		}
	}

	private isAsync(): boolean {
		return typeof this.option.items === 'function';
	}

	private selectDefault(): void {
		angular.forEach(this.subMenuItems, item => {
			if (item && item.selected) {
				this.activeChild = item;
			}
		});
	}

	getSubmenuOptions = () => {
		this.loadSubmenu = this.isAsync()
			? (this.option as any).items()
			// need to make a shallow copy of array, as it will be changed. Deep copy will break moving to folder
			: this.$q.when([].concat(this.option.items));
		return this.loadSubmenu.then(result => {
			if (!this.option.isExpandable) {
				return result;
			}
			if (!this.expandableTree) {
				this.expandableTree = [];
				this.buildExpandableTree(result || [], {}, this.expandableTree);
			}
			let displayableOptions = [];
			this.expandableTree.forEach(expandableItem => {
				this.populateDisplayableOptions(expandableItem, displayableOptions);
			});
			return displayableOptions;
		});
	}

	nestedMenuClass = () => {
		let classes = [];
		classes.push(`menu-level-${this.level}`);
		classes.push(`br-${this.option.name}-menu-level-${this.level}`);
		if (this.option.searchBox) {
			classes.push('dropdown-submenu-dynamic');
		}
		return classes.join(' ');
	}


	private buildExpandableTree(itemList, itemMap, expandableTree): void {
		if (!itemList || !itemList.length) {
			return;
		}
		let itemToProcess = itemList[0];
		if (!itemToProcess || !itemToProcess.obj) {
			return;
		}
		let parentId = itemToProcess.obj.parent && itemToProcess.obj.parent.id;
		if (!!_.isUndefined(parentId)) { // no parent, this must be a top level item
			itemToProcess.level = 0;
			expandableTree.push(itemToProcess);
		} else if (itemMap[parentId]) { // has parent, and the parent has already been processed.
			itemToProcess.parent = itemMap[parentId];
			if (!itemMap[parentId].children) { // this is the first time we're finding a child for the parent, so initialize props.
				itemMap[parentId].isExpanded = false;
				itemMap[parentId].children = [];
			}

			// not to populate a child again on the second opening of the menu
			let childrenObjects = _.pluck(itemMap[parentId].children, 'obj');
			if (!_.findWhere(childrenObjects, {id: itemToProcess.obj.id})) {
				itemToProcess.level = itemMap[parentId].level + 1;
				itemMap[parentId].children.push(itemToProcess);
			}

		} else { // has parent, and the parent has not been processed yet so move the parent to the front and keep building
			this.moveParentToFront(itemToProcess, itemList);
			this.buildExpandableTree(itemList, itemMap, expandableTree);
			return;
		}
		itemList.shift();
		itemMap[itemToProcess.obj.id] = itemToProcess;
		this.buildExpandableTree(itemList, itemMap, expandableTree);
	}

	private moveParentToFront(item: IDrillMenuOption, itemList: IDrillMenuOption[]): void {
		let parentIndex = _.findIndex(itemList, it => {
			return it.obj.id === item.obj.parent.id;
		});
		if (parentIndex >= 0) { // if parent is already in list, find it and move it to the front
			let parentInList = itemList[parentIndex];
			itemList.splice(parentIndex, 1);
			itemList.unshift(parentInList);
		} else { // otherwise, construct it from item's parent object and move it to front.
			let parentFromObject = this.getParentFromObject(item);
			itemList.unshift(parentFromObject);
		}
	}

	private getParentFromObject(item): any {
		let parentObj = item.obj.parent;
		let level = 0;
		let parentParent = parentObj.parent;
		while (parentParent) {
			level++;
			parentParent = parentParent.parent;
		}
		return {
			obj: parentObj,
			disabled: true, // since parent is not in list, it should be disabled.
			level,
			text: parentObj.name
		};
	}

	private populateDisplayableOptions(expandableItem, displayableOptions): void {
		if (!expandableItem) {
			return;
		}
		displayableOptions.push(expandableItem);
		if (!this.shouldNodeBeExpanded(expandableItem)) {
			return;
		}
		expandableItem.children.forEach(child => {
			this.populateDisplayableOptions(child, displayableOptions);
		});
	}

	private shouldNodeBeExpanded(expandableItem): boolean {
		let searchString = this.searchFilter && this.searchFilter.trim();
		return this.hasChildren(expandableItem) && (expandableItem.isExpanded || searchString);
	}

	getLevelMargin = (item) => {
		if (item.noMargin) {
			return 0;
		}

		let level = item.level || 0;
		if (level === 0) {
			return 0;
		}

		let itemLevelIndentation = level * this.SINGLE_LEVEL_INDENTATION;

		let noChildrenModifier = item.children || this.bounded ? 0 : this.NO_CHILDREN_INDENTATION;

		return (this.DEFAULT_INDENTATION + itemLevelIndentation + noChildrenModifier) + 'px';
	}

	isNodeExpanded = (expandableItem) => {
		if (this.searchFilter && this.searchFilter.trim()) {
			return true;
		}
		return expandableItem && expandableItem.isExpanded;
	}

	onSearchFilterChange = () => {
		this.isSearchStringAvailable = !!(this.searchFilter && this.searchFilter.trim());
	}

	hasChildren = (expandableItem) => {
		return expandableItem && expandableItem.children && expandableItem.children.length;
	}

	toggleExpanded = ($event, item) => {
		item.isExpanded = !item.isExpanded;
		this.refreshSubmenuOptions();
		$event.stopPropagation();
	}

	private refreshSubmenuOptions(): void {
		this.getSubmenuOptions().then(result => {
			this.subMenuItems = result;
			this.selectDefault();
			if (!this.bounded) {
				this.$timeout(() => this.checkSubmenuPosition());
			} else {
				this.$timeout(() => this.adjustSubmenuPosition());
			}});
	}

	private isAMatchForSearchText(option): boolean {
		return option === null || !this.searchFilter || (option.text &&
			option.text.toLowerCase().indexOf(
				this.searchFilter.toLowerCase()) !== -1);
	}

	private hasMatchingTextInHierarchy(option): boolean {
		let children = option.children || [];
		let parent = option.parent;
		let hasMatchingAncestor = false;
		while (parent) {
			if (this.isAMatchForSearchText(parent)) {
				hasMatchingAncestor = true;
				break;
			}
			parent = parent.parent;
		}
		return hasMatchingAncestor || this.isAMatchForSearchText(option) || children.some(child => {
			return this.hasMatchingTextInHierarchy(child);
		});
	}

	filterOptions = (option) => {
		if (!this.option.isExpandable) {
			return this.isAMatchForSearchText(option);
		}
		return this.hasMatchingTextInHierarchy(option);
	}

	visibleFilter = (option): boolean => {
		return !option || !option.obj || !option.obj.hide;
	}

	onKeyPress = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
			let focusedItem: JQuery = $(':focus');

			// click on the submenu item again to close it
			focusedItem.closest('menu-option-submenu')
				.closest('context-menu-option')
				.find(':focusable')
				.first()
				.trigger('click');
		}
	}

	getSubmenuLevelClass = (): string => {
		return `submenu-level-${this.level}`;
	}

}

app.component('menuOptionSubmenu', {
	controller: MenuOptionSubmenuController,
	templateUrl: 'partials/context-menu/menu-option-submenu.component.html',
	bindings: {
		option: '<',
		bounded: '<',
		object: '<',
		level: '<',
		onAction: '&',
		onSelect: '&'
	}
});
