import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';

interface TemplateEditInput {
	template: CustomTemplate;
}

@Component({
	selector: 'template-edit-dialog',
	templateUrl: './template-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateEditDialogComponent implements OnInit {
	@Input('input') input: TemplateEditInput;
	@ViewChild('templateDialogForm', {static: false}) templateDialogForm;

	loading = {
		save: null,
		loadingCustomTemplates: null
	};
	folders: any[] = [];
	model: {
		name: string;
		description: string;
		parentId: number;
	};

	constructor(
		private readonly modal: NgbActiveModal,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService,
		private readonly gridUtils: GridUtilsService,
		private readonly ref: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.model = {
			name: this.input.template.name,
			description: this.input.template.description,
			parentId: -1
		};
		this.loadCustomTemplatesFolders();
	}

	loadCustomTemplatesFolders = (): void => {
		this.loading.loadingCustomTemplates = this.dashboardTemplatesApiService.getTemplates()
			.then((response) => {
				let templatesFolders = [];
				_.each(response.data,(item: ITreeItem) => {
					if (this.gridUtils.isFolder(item)) {
						templatesFolders.push(item);
					}
				});
				this.folders = templatesFolders;
				let rootFolder = {id: -1, name: ''};
				this.folders.unshift(rootFolder);
				this.matchParentId();
			});
	}

	matchParentId = () => {
		if(this.input.template.parentId) {
			this.model.parentId = this.folders.find(folderItem => folderItem.id === this.input.template.parentId).id;
		} else {
			this.model.parentId = -1;
		}
		this.ref.detectChanges();
	}

	save(): void {
		let model = cloneDeep(this.model);
		this.loading.save = this.dashboardTemplatesApiService
			.updateDashboardTemplate(this.input.template.id as number, model)
			.then(() => this.modal.close());
	}

	cancel(): void {
		this.modal.dismiss('cancel');
	}
}
