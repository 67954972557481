import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DEFAULT_DATE_FILTER_MODE } from '@cxstudio/reports/entities/date-filter-mode';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { IConversionWidgetProperty } from '@cxstudio/reports/widgets/conversion-widget-property.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';
import { MetricColorName } from '@app/modules/metric/entities/metric-color-name.enum';

export type Replacement = {[key in keyof Widget]?: any};

export interface IReportWidgetContext {
	source: Widget;
	replace?: Replacement;
}

/*
	{
		"id" : NumberLong(909),
		"posX" : NumberInt(0),
		"posY" : NumberInt(0),
		"width" : NumberInt(12),
		"height" : NumberInt(12),
		"type" : "CB",
		"displayName" : "Line by Year (CHECKIN_DATE)",
		"template" : false,
		"drillDepth" : NumberInt(0),
		"properties" : {
			"runAs" : "anton.fedorenko@clarabridge.com",
			"isCustomTitle" : false,
			"contentProviderId" : NumberInt(7),
			"contentProviderName" : "Loc 7.1",
			"accountId" : NumberInt(100),
			"accountName" : "Belarus 71",
			"project" : NumberInt(18358),
			"projectName" : "Lodging"
		},
		"visualProperties" : {

		}
	}
	*/

const TOP_LEVEL_PROPERTIES: IConversionWidgetProperty[] = [
	{name: 'id'}, {name: 'dashboardId'}, {name: 'containerId'},
	{name: 'posX', default: 0}, {name: 'posY', default: 0},
	{name: 'width', default: 12}, {name: 'height', default: 12},
	{name: 'displayName'}, {name: 'template', default: false},
	{name: 'drillDepth', default: 0}, {name: 'linkedWidgets'}
];

const COMMON_WIDGET_PROPERTIES: IConversionWidgetProperty[] = [
	{name: 'runAs'}, {name: 'isCustomTitle'}, {name: 'autoDescription'}
];

const DATA_SOURCE_PROPERTIES: IConversionWidgetProperty[] = [
	{name: 'contentProviderId'}, {name: 'contentProviderName'},
	{name: 'accountId'}, {name: 'accountName'},
	{name: 'project'}, {name: 'projectName'},
	{name: 'workspaceProject'}
];

export const FILTERS_PROPERTIES: IConversionWidgetProperty[] = [
	{name: 'appliedFilters'},
	{name: 'adhocFilter'}, //additional conditions
	{name: 'drillFilters'},
	{name: 'ignoredDashboardFilters'},
	{name: 'groupingFilters'}
];

const WIDGET_PROPERTIES: IConversionWidgetProperty[] = COMMON_WIDGET_PROPERTIES
	.concat(DATA_SOURCE_PROPERTIES);

export const DEFAULT_DATE_RANGE: DateFilter = Object.freeze({
	dateFilterMode: DEFAULT_DATE_FILTER_MODE
});

const SINGLE_DATE_RANGE_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'dateRangeP1', default: DEFAULT_DATE_RANGE}
];

const DATE_RANGE_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'useHistoricPeriod', default: false},
	{name: 'dateRangeP1', default: DEFAULT_DATE_RANGE},
	{name: 'dateRangeP2', default: DEFAULT_DATE_RANGE}
];

const DATE_RANGE_VISUAL_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'periodLabel', default: {}}
];

export const COLOR_TYPES = {
	COLOR: {color: 'color', customColor: 'customColor'},
	POINT_COLOR: {color: 'pointColor', customColor: 'pointCustomColor'},
	POP_COLOR: {color: 'popColor', customColor: 'customPopColor'}
};

const LEGEND_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'showLabels', default: false},
	{name: 'showLegend', default: false}
];

const ADVANCED_AXIS_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'alignTicks'},
	{name: 'referenceLines'}
];

const TIME_REFERENCES_OPTION: IConversionWidgetProperty = {
	name: 'timeReferenceLines'
};

const MIN_MAX_OPTIONS: IConversionWidgetProperty[] = [
	{name: 'AxisAutoMax'}, {name: 'AxisMax'},
	{name: 'AxisAutoMin'}, {name: 'AxisMin'}
];

export abstract class ReportWidget {

	protected widget: Widget;
	protected widgetType: WidgetType;

	constructor(widgetType: WidgetType) {
		this.widgetType = widgetType;
	}

	abstract initFrom(widget: Widget, replace?: Replacement, lastSavedWidget?: Widget): void;

	getWidget(): Widget {
		return this.widget;
	}

	wrap(widget: Widget): void {
		this.widget = widget;
	}

	getWidgetType(): WidgetType {
		return this.widgetType;
	}

	protected isPoPCalculation(calculation: ReportCalculation): boolean {
		return Boolean(calculation.isPopMetric);
	}

	protected isTechnicalCalculation(calculation: ReportCalculation): boolean {
		return calculation.name === StandardMetricName.ALPHANUMERIC
			&& calculation.metricType === AnalyticMetricType.STANDARD;
	}

	protected isEmptyGroup(group: ReportGrouping): boolean {
		return _.isEmpty(group) || group.name === '';
	}

	protected getContext(source: Widget, replace: Replacement): IReportWidgetContext {
		source = source ? source : {} as Widget;
		replace = replace ? replace : {};
		return {
			source,
			replace
		};
	}

	protected addCalculation(calculation: ReportCalculation): void {
		if (!_.findWhere(this.widget.properties.selectedMetrics, {name: calculation.name})) {
			this.widget.properties.selectedMetrics.push(calculation);
		}
	}

	protected initFromContext(context: IReportWidgetContext): void {
		this.widget = this.getEmptyWidget();
		this.transformOldSentimentColors(context);
		this.initProperties(TOP_LEVEL_PROPERTIES, context);
		this.initProperties(WIDGET_PROPERTIES, context, 'properties');
		this.inherit('properties.altTextFromTitle', context);
		this.inherit('properties.altText', context);

		this.widget.name = this.widgetType;
		this.widget.properties.widgetType = this.widgetType;
	}

	protected withFilters(context: IReportWidgetContext): void {
		this.initProperties(FILTERS_PROPERTIES, context, 'properties');
	}

	protected inherit(property: string, context: IReportWidgetContext, defaultValue?: any): void {
		WidgetPropertyService.inherit(this.widget, property, context, defaultValue);
	}

	protected getEmptyWidget(): Widget {
		return {
			type: WidgetDisplayType.CB,
			properties: {},
			visualProperties: {} as VisualProperties
		} as Widget;
	}

	protected initProperties(properties: IConversionWidgetProperty[], context: IReportWidgetContext, prefix?: string): void {
		_.each(properties, (property) => {
			let sourceName = property.sourceName
				? property.sourceName : property.name;
			let propertyPath = prefix
				? `${prefix}.${sourceName}` : sourceName;
			let value = WidgetPropertyService.getValue(
				propertyPath, context, property.default);
			let propertyParent = prefix
				? WidgetPropertyService.getValue(prefix, {source: this.widget})
				: this.widget;
			propertyParent[property.name] = value;
		});
	}

	protected withDateRange(context: IReportWidgetContext): void {
		this.initProperties(SINGLE_DATE_RANGE_OPTIONS, context, 'properties');
	}

	protected withDateRanges(context: IReportWidgetContext, defaults?: any): void {
		defaults = defaults || { properties: [], visual: [] };
		let dateRangeOptions = this.mergeOptions(DATE_RANGE_OPTIONS, defaults.properties);
		this.initProperties(dateRangeOptions, context, 'properties');

		let dateRangeVisualOptions = this.mergeOptions(DATE_RANGE_VISUAL_OPTIONS, defaults.visual);
		this.initProperties(dateRangeVisualOptions, context, 'visualProperties');
	}

	private mergeOptions(base: any, overwrite: any): any {
		return _.uniq(overwrite.concat(base), false, (opt: IConversionWidgetProperty) => opt.name);
	}

	protected initColor(colorType, context: IReportWidgetContext): void {
		let color = WidgetPropertyService.getValue('visualProperties.' + colorType.color, context);
		if (!color) return;

		this.widget.visualProperties[colorType.color] = color;
		if (color === 'custom') {
			this.inherit('visualProperties.' + colorType.customColor, context);
		} else if (CalculationColorUtils.isCalculationColor(color)) {
			let calculationName = CalculationColorUtils.getCalculationColorAttributeName(color);
			let calculations = WidgetPropertyService.getValue(
				'properties.selectedMetrics', context, []) as ReportCalculation[];
			let calculationForColoring = _.findWhere(calculations, {name: calculationName});
			if (calculationForColoring) {
				this.addCalculation(calculationForColoring);
			}
		}
	}

	//Should be invoked after main calculation processing is finished
	protected withColors(context: IReportWidgetContext): void {
		this.initColor(COLOR_TYPES.COLOR, context);
		this.initProperties(
			[{name: 'recolors'}], context, 'visualProperties');
	}

	protected withShowSampleSize(context: IReportWidgetContext): void {
		this.initProperties(
			[{name: 'showSampleSize', default: true}], context, 'visualProperties');
	}

	protected withLegendOptions(context: IReportWidgetContext, defaults?): void {
		defaults = defaults || [];
		let legendOptions = this.mergeOptions(LEGEND_OPTIONS, defaults);
		this.initProperties(
			legendOptions, context, 'visualProperties');

		this.withShowSampleSize(context);
	}

	private getMinMaxOptions(prefix: string, sourcePrefix: string): any[] {
		return _.map(MIN_MAX_OPTIONS, opt => {
			let res = {} as IConversionWidgetProperty;
			res.name = prefix + opt.name;
			if (sourcePrefix) {
				res.sourceName = sourcePrefix + opt.name;
			}
			return res;
		});
	}

	protected withAdvancedAxisOptions(context: IReportWidgetContext, axisNames: IConversionWidgetProperty[],
		includeTimeReferences?: boolean): void {

		let advancedAxisOptions = [].concat(ADVANCED_AXIS_OPTIONS);

		if (includeTimeReferences) {
			advancedAxisOptions.push(TIME_REFERENCES_OPTION);
		}

		this.initProperties(
			advancedAxisOptions, context, 'visualProperties');

		axisNames.forEach(axis => {
			let options = this.getMinMaxOptions(axis.name, axis.sourceName);
			this.initProperties(
				options, context, 'visualProperties');
		});
	}

	//cloud widget has outdated format
	private transformOldSentimentColors(context: IReportWidgetContext): void {
		if (!context.source.visualProperties)
			return;
		let color = WidgetPropertyService.getValue(
			'visualProperties.color', context);
		if (color === 'sentiment3') {
			color = MetricColorName.SENTIMENT_3;
		}
		if (color === 'sentiment5') {
			color = MetricColorName.SENTIMENT_5;
		}
		context.source.visualProperties.color = color;
	}

}
