import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';

@Injectable()
export class SentenceStyleEditorService {

	readonly VOICE_SENTENCE_HOVER_CLASS = 'voice-sentence-hover';

	addVoiceSentenceHover(sentenceId: number, wrapperLocator: string): void {
		let sentenceElement = $(`${wrapperLocator}`).find(`#voice-sentence-${sentenceId}`);
		sentenceElement.addClass(this.VOICE_SENTENCE_HOVER_CLASS);
	}

	removeVoiceSentenceHover(): void {
		let sentenceElement = $(`.${this.VOICE_SENTENCE_HOVER_CLASS}`);
		sentenceElement.removeClass(this.VOICE_SENTENCE_HOVER_CLASS);
	}

}

app.service('sentenceStyleEditorService', downgradeInjectable(SentenceStyleEditorService));