import { NgModule } from '@angular/core';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { UnitsModule } from '@app/modules/units/units.module';
import { SharedModule } from '@app/shared/shared.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CalendarFormatComponent } from './calendar-format.component';
import { NgPipesModule } from 'ngx-pipes';
import { CalendarFormatGridDefinition } from './calendar-format-grid-definition.service';
import { CalendarFormatModalComponent } from './calendar-format-modal/calendar-format-modal.component';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		UnitsModule,
		ItemGridModule,
		NgPipesModule
	],
	declarations: [
		CalendarFormatComponent,
		CalendarFormatModalComponent
	],
	exports: [
		CalendarFormatComponent
	],
	providers: [
		CalendarFormatGridDefinition
	]
})

export class CalendarModule {}
