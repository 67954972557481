import * as _ from 'underscore';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { BaseFolderApi } from '@cxstudio/common/folders/base-folder-api.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';


export class DashboardTemplatesFolderApi extends BaseFolderApi<IFolderItem> {
	constructor(
		$http: ng.IHttpService,
		treeService: TreeService
	) {
		super('rest/dashboard-templates', $http, treeService, null);
	}
}

app.service('dashboardTemplatesFolderApi', DashboardTemplatesFolderApi);
