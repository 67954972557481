import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { GaugeBaseLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-base-layout';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';

export class GaugeChartLayout extends GaugeBaseLayout {

	getNeededWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getGaugeWidth(cssVars);
	}

	getNeededHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getGaugeHeight(cssVars);
	}

	getComparisonsColumnHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return 0;
	}

	getComparisonsRowWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return 0;
	}

	isComparisonsStacked(): boolean {
		return false;
	}
}
