import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import {
	DrillToDashboardService
} from '@cxstudio/services/drill-to-dashboard.service';
import { AppRoute } from '@cxstudio/route/app-route';
import { UrlService } from '@cxstudio/common/url-service.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardStateService {
	constructor(
		@Inject('drillToDashboardService') private readonly drillToDashboardService: DrillToDashboardService,
		@Inject('urlService') private urlService: UrlService
	) {
	}

	clear = (newRoute: AppRoute): void => {
		this.clearDrillToDashboardFilter(newRoute);
	}

	private clearDrillToDashboardFilter = (newRoute: AppRoute): void => {
		const dashboardId: string = this.urlService.getRouteParam(newRoute, 'dashboardId');
		const drillDashboardId: number = this.drillToDashboardService.getTargetDashboardId();

		if (drillDashboardId && dashboardId !== drillDashboardId.toString()) {
			this.drillToDashboardService.clear();
		}
	}
}

app.service('dashboardStateService', downgradeInjectable(DashboardStateService));
