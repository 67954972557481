import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, TemplateRef, Inject, ViewChild, ElementRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SpotCheckResultStatus } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-status';
import { SpotCheckTopic } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-topic';
import { SpotCheckUtils } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-utils.class';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { SpotCheckModalKeyboardUtils } from './spot-check-modal-keyboard-utils';
import { ValidatedWidget } from './spot-check-response';
import { SpotCheckResultItemKeyboardUtils } from './spot-check-result-item-keyboard-utils';

interface ExamplesTextBlock {
	title: string;
	items: string[];
}

export interface SpotCheckResultItem {
	identifier: string;
	title: string;
	topic: SpotCheckTopic;
	status: SpotCheckResultStatus;
	description?: string;
	examples?: ExamplesTextBlock;
	affectedWidgets?: ValidatedWidget[];
	template?: {
		name: string;
		ref: TemplateRef<any>;
		data?: any;
	};
	state: {
		currentStatus: SpotCheckResultStatus;
	};
}

@Component({
	selector: 'spot-check-result-item',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		* { line-height: 1.5; }
		h3 { line-height: 1.35; }
		.spot-check-badge { line-height: 1; }
		a.affected-widget { color: var(--action-600); text-decoration: underline; }
	`],
	template: `
	<div class="font-default mb-16" #spotCheckItem>
		<div class="d-flex justify-between">
			<div>
				<h3 class="text-1_25rem font-normal mb-0">{{result.title}}</h3>
				<div>{{getTopicLabel()}}</div>
			</div>
			<div class="d-flex justify-between align-items-center">
				<span class="h-32 d-flex align-items-center border-solid border-2 p-4 spot-check-badge rounded-4"
					[ngClass]="getBadgeClasses()">{{getBadgeLabel()}}</span>
				<span *ngIf="isIgnorable()"
					class="btn btn-icon q-icon q-icon-delete d-inline-block"
					(click)="onIgnore()"></span>
				<span *ngIf="isIgnored()"
					class="btn btn-icon q-icon q-icon-undo d-inline-block"
					(click)="onUndo()"></span>
			</div>
		</div>
		<div class="pt-16" *ngIf="result.description">
			{{result.description}}
		</div>
		<div class="pt-8" *ngIf="result.examples">
			{{result.examples.title}}:
		</div>
		<div *ngIf="result.examples">
			<ul class="bullet">
				<li *ngFor="let example of result.examples.items">{{example}}</li>
			</ul>
		</div>
		<div class="pt-8" *ngIf="result.template">
			<ng-container *ngTemplateOutlet="result.template.ref; context: {$implicit: result.template.data}"></ng-container>
		</div>
		<div #affectedWidgetsContainer
			class="affected-widgets-container mt-16 kb-focusable"
			*ngIf="result.affectedWidgets?.length"
			tabindex="0"
			(keydown)="processAffectedWidgetsContainerKeydown($event)">
			<collapsing-panel (keydown)="processAffectedWidgetsPanelKeydown($event)">
				<panel-heading>
					<strong>{{'dashboardSpotCheck.affectedWidgets' | i18n}}</strong>
				</panel-heading>
				<panel-body>
					<ul class="p-0 m-0">
						<div *ngFor="let widget of result.affectedWidgets" class="pb-8">
							<a class="affected-widget break-word text-action-700 font-semibold"
								href="javascript:void(0)"
								(click)="scrollToWidget(widget.id)"
								(keydown.enter)="processAffectedWidgetLinkEnter($event, widget.id)">
								{{widget.displayName}}
							</a>
						</div>
					</ul>
				</panel-body>
			</collapsing-panel>
		</div>
		<hr class="m-0 mt-16">
	</div>
	`
})
export class SpotCheckResultItemComponent {
	@Input() result: SpotCheckResultItem;
	@Output() ignoredChange: EventEmitter<SpotCheckResultItem> = new EventEmitter();
	@ViewChild('spotCheckItem') spotCheckItem: ElementRef;
	@ViewChild('affectedWidgetsContainer') affectedWidgetsContainer: ElementRef;

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('elementUtils') private readonly elementUtils: ElementUtils,
	) {}

	private getCurrentStatus(): SpotCheckResultStatus {
		return this.result.state.currentStatus;
	}

	getBadgeClasses(): string[] {
		return SpotCheckUtils.getBadgeClasses(this.getCurrentStatus());
	}

	getBadgeLabel(): string {
		return this.locale.getString(SpotCheckUtils.getStatusLabelKey(this.getCurrentStatus()));
	}

	getTopicLabel(): string {
		return this.locale.getString(SpotCheckUtils.getTopicLabelKey(this.result.topic));
	}

	onUndo(): void {
		this.result.state.currentStatus = this.result.status;
		this.ignoredChange.emit(this.result);
	}

	onIgnore(): void {
		this.result.state.currentStatus = SpotCheckResultStatus.IGNORED;
		this.ignoredChange.emit(this.result);
	}

	isIgnored(): boolean {
		return this.getCurrentStatus() === SpotCheckResultStatus.IGNORED;
	}

	isIgnorable(): boolean {
		return this.getCurrentStatus() !== SpotCheckResultStatus.PASSED && !this.isIgnored();
	}

	scrollToWidget(widgetId: number): void {
		this.elementUtils.scrollTo(`widget-${widgetId}`);
	}

	// keyboard navigation

	processAffectedWidgetsContainerKeydown(event: KeyboardEvent): void {
		SpotCheckResultItemKeyboardUtils.processAffectedWidgetsContainerKeydown(
			event, this.spotCheckItem.nativeElement, this.affectedWidgetsContainer.nativeElement);
	}

	processAffectedWidgetsPanelKeydown(event: KeyboardEvent): void {
		SpotCheckResultItemKeyboardUtils.processAffectedWidgetsPanelKeydown(event, this.spotCheckItem.nativeElement);
	}

	processAffectedWidgetLinkEnter(event: KeyboardEvent, widgetId: number): void {
		KeyboardUtils.intercept(event);
		SpotCheckModalKeyboardUtils.closeModal();

		this.scrollToWidget(widgetId);

		const WIDGET_SELECTOR: string = `#widget-${widgetId}`;
		$(`${WIDGET_SELECTOR} :focusable`).first().trigger('focus');
	}
}
