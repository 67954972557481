import { Injectable } from '@angular/core';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { DrillHelperService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class KeyTermsDrillService {
	constructor(
		private readonly drillToHelper: DrillHelperService
	) {}

	getOptions = (params: IDrillParams): IDrillMenuOption => {
		const drillToHelper = this.drillToHelper.create(params);

		return drillToHelper.getDrillToKeyTerms() as IDrillMenuOption;
	}
}

app.service('keyTermsDrill', downgradeInjectable(KeyTermsDrillService));
