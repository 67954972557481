import { Inject, Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { TRANSLATION_LANGUAGES } from '@app/modules/translation/translation-languages';
import { Security } from '@cxstudio/auth/security-service';
import * as _ from 'underscore';


export interface ITranslateData {
	items: string[];
	language: string;
}

export interface ITranslationDisplayData {
	from: string;
	to: string;
	text: string;
}

export interface ILanguage {
	id: string;
	name: string;
	nativeName: string;
}

@Injectable({
	providedIn: 'root'
})
export class TranslationApi {

	constructor(
		private readonly http: CxHttpService,
		@Inject('security') private readonly security: Security,
	) {}

	getSupportedLanguages(): ILanguage[] {
		return _.chain(TRANSLATION_LANGUAGES)
			.map((val: ILanguage, key: string) => {
				val.id = key;
				return val;
			}).sortBy('name')
			.value();
	}

	getLanguageDisplayName(code: string): string {
		return TRANSLATION_LANGUAGES[code]?.name || code;
	}

	getTargetLanguageDisplayName(): string {
		return this.getLanguageDisplayName(this.security.getTranslationLanguage());
	}

	translate(items: string[]): Promise<ITranslateData> {
		return this.http.post('rest/external/translate', { items, language: this.security.getTranslationLanguage()} );
	}

	checkLimitReached(): Promise<boolean> {
		return this.http.get('rest/external/translate/reach/limit');
	}

}

app.service('translationApi', TranslationApi);
