import * as _ from 'underscore';
import { IShareInviteUiBindings } from '@cxstudio/dashboards/dashboard-sharing/share-invite.component';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { SharingUser } from '@cxstudio/sharing/access-management/entities/sharing-user';
import Listener from '@cxstudio/common/listener';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ShareModificationUtilities } from './share-modification-utils.service';
import { UsersGroupsLogicService } from '@app/modules/user-administration/services/users-groups-logic.service';
import { SharingService, IShareEntity, IShareEntityType } from '@cxstudio/sharing/sharing-service.service';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { PermissionType } from '@app/core/authorization/all-permissions.service';


export interface IShareUiConfig {
	hideShareAll?: boolean;
	hideAllowEditorsToShare?: boolean;
	inviteLabel?: string;
}

export interface ISharePermissionNameGetter {
	shareView: () => string;
	shareEdit: () => string;
	edit: () => string;
	defaultSharePermission: () => string;
}

export class GenericShareComponent implements ng.IComponentController {

	readonly PUBLIC: IShareEntity = {
		type: IShareEntityType.PUBLIC,
		_name: 'PUBLIC',
		displayName: this.locale.getString('common.allUsers'),
		iconType: 'publicStatus',
		assetEditPermissions: [],
		entity: 'PUBLIC'
	};

	readonly CB_USERS: IShareEntity = {
		entity: 'CB_USERS',
		type: IShareEntityType.META_GROUP,
		iconType: 'cbUsers',
		_name: 'CB_USERS',
		displayName: this.locale.getString('common.clarabridgeUsers')
	};

	uiConfig: IShareUiConfig;
	onChange: () => void;
	changeTracker;

	saveFunction: (close: any, data: any, IS_COMPONENT: boolean) => void;
	resolve: {
		items: any[];
		data: any;
		bulkMode: boolean;
		modalTitle: string;
		objectTypePlural: string;
		saveFunction: (close: any, data: any, IS_COMPONENT: boolean) => void;
		shareToUser: SharingUser;
	};
	close: () => void;

	modalTitle: string;
	nameProperty: string;
	items: any[];
	item: any;
	editorsAllowedToShare: boolean;
	isBulkShare: boolean;
	objectTypeName: string;
	embedded: boolean;
	viewToNotify: boolean;

	dismiss: () => void;

	data: Partial<{
		preventReshare: boolean;
		loading: any;
		items;
		bulkMode;
		changedEntities;
		sharedEntities;
	}>;

	invite = {
		entity: '',
		dropdown: false,
		ignoreEnter: false,
		ignoreFocus: false
	};

	searchText: string = '';

	countShares: string = '';
	countNewShares: string = '';

	addedEntities: IShareEntity[] = [];
	sharedEntities: IShareEntity[];
	availableEntities: IShareEntity[] = [];
	ui: Partial<IShareInviteUiBindings> = {
		allowUserCreation: false,
		addPanelToggle: 0
	};
	assetEditPermissionType: AssetEditPermissionAction;
	singlePermissionMode: boolean;

	populateOwner: (user, items: any[]) => any;

	// passed from parent
	getSharedUsersAndGroups: (items: any[]) => any;
	getShareStatus: () => any;
	getPermissionName: ISharePermissionNameGetter;
	removeNewShare: (item: any) => void;

	entityMap;
	isSharedToAllUsers: boolean;
	wasInitiallySharedToAll: boolean;
	loadingShares: boolean;
	shareToAllText: string;
	setAllToViewText: string;
	setAllToViewHelpText: string;
	oneClickRemoval: boolean;
	saveListener: Listener;

	projectToCheck: IProjectSelection;

	constructor(
			private $scope: ISimpleScope,
			private sharingService: SharingService,
			private locale: ILocale,
			private security: Security,
			private shareModificationUtils: ShareModificationUtilities,
			private usersGroupsService: UsersGroupsLogicService) { }

	$onInit = () => {
		this.initializeData();

		this.isBulkShare = this.resolve.bulkMode;
		this.items = this.resolve.items;
		if (this.isNeedProjectAccessCheck()) {
			let sharingObject = this.items[0];
			this.projectToCheck = {
				contentProviderId: sharingObject.contentProviderId,
				accountId: sharingObject.accountId,
				projectId: sharingObject.projectId
			};
		}

		this.shareToAllText = this.isBulkShare ?
			this.locale.getString('common.manageShareToAll') :
			this.locale.getString('common.shareToAll');

		this.setAllToViewText = this.viewToNotify
			? this.locale.getString('alert.setAllToNotify')
			: this.locale.getString('common.setAllToView');
		this.setAllToViewHelpText = this.viewToNotify
			? this.locale.getString('alert.setAllToNotifyHelpText')
			: this.locale.getString('common.setAllToViewHelpText');

		this.sharedEntities = this.data.sharedEntities = [];

		this.data.preventReshare = this.isBulkShare || !this.items[0].reshareAllowed;

		this.loadUsersAndGroups();

		if (!this.isBulkShare) {
			this.item = this.resolve.items[0];
			this.editorsAllowedToShare = !this.data.preventReshare;
			this.loadPublicStatus();
		}

		this.modalTitle = this.resolve.modalTitle;
		this.saveFunction = this.resolve.saveFunction;
		this.nameProperty = _.isUndefined(this.nameProperty) ? 'name' : this.nameProperty;
		if (_.isUndefined(this.oneClickRemoval)) {
			this.oneClickRemoval = true;
		}

		if (this.saveListener) {
			this.saveListener.addListener(this.processChanges);
		}

		// required for typescript validation
		this.$scope.$watch('$ctrl.sharedEntities', this.onChangeInternal, true);
		this.$scope.$watchCollection('$ctrl.changedEntities', this.onChangeInternal);
		this.$scope.$watchCollection('$ctrl.addedEntities', this.onChangeInternal);

		this.PUBLIC.onRemove = () => {
			// if it's bulk share & change of permission, don't remove the public item from the list
			let isPermissionChange = this.isBulkShare;
			this.removeShareToAll(isPermissionChange);
		};

		this.removeNewShare = (item) => {
			if (item === this.PUBLIC) {
				this.removeShareToAll();
				return;
			}
			delete item.shared;
			this.addedEntities.remove(item);
		};

		this.ui.label = this.uiConfig?.inviteLabel;

		if (!this.security.has('share_to_all') || this.uiConfig?.hideShareAll) {
			this.ui.hideShareAll = true;
		}
	}

	private isNeedProjectAccessCheck(): boolean {
		let sharingObject = this.items[0];
		if (!sharingObject) return false;
		if (this.isBulkShare) return false;
		return InternalProjectTypes.isStudioAdminProject(sharingObject.projectId);
	}


	getAddLabel = (): string => {
		let baseLabel = this.isBulkShare ? 'common.updateCount' : 'common.addCount';
		let addCount = this.addedEntities ? this.addedEntities.length : 0;
		return this.locale.getString(baseLabel, { addCount });
	}

	private initializeData = (): void => {
		this.data = this.resolve.data || {};

		this.data.loading = {
			init: null,
			save: false,
			typeaheadSearch: null
		};
	}

	save = () => {
		if (this.saveFunction) {
			this.processChanges();
			const IS_COMPONENT: boolean = true;
			this.saveFunction({close: this.close}, this.data, IS_COMPONENT);
		} else {
			this.close();
		}
	}

	cancel = () => this.dismiss();

	private processChanges = (): any => {
		this.data.items = angular.copy(this.items);

		// repopulate changed entities array
		this.data.changedEntities = [].concat(this.addedEntities)
			.concat(_.filter(angular.copy(this.sharedEntities), (entity) => entity.action === ShareAction.DELETE));

		this.data.changedEntities.map((entity) => {
			// remove "permission" is only used in UI
			if (entity.permission === PermissionType.REMOVE)
				delete entity.permission;
		});

		if (this.publicStatusRemoved()) {
			delete this.PUBLIC.permission;
			this.PUBLIC.action = ShareAction.DELETE;
			this.data.changedEntities.push(this.PUBLIC);
		}

		return this.data;
	}

	private publicStatusRemoved = (): boolean => {
		return this.wasInitiallySharedToAll && !this.isSharedToAllUsers;
	}

	private openAddPanelOnFirstAdd(): void {
		// on first addition, open added entities panel
		if (this.addedEntities.length === 1)  {
			this.ui.addPanelToggle++;
		}
	}

	private loadUsersAndGroups = () => {
		if (this.isBulkShare) return;

		this.loadingShares = true;
		this.data.loading.init = this.getSharedUsersAndGroups(this.items)
			.then((assetEntities) => {
				return this.sharingService.loadUsersAndGroups(assetEntities, this.getAssetEditPermissionType(), {
					simpleGroupsOnly: false
				}).then((usersAndGroups) => {
					this.populateLoadedAvailableEntities(usersAndGroups);

					let sharingTableContent = this.sharingService.produceSharingTableContent(assetEntities, this.entityMap);
					sharingTableContent.users.forEach((user) => {
						this.sharedEntities.push(user);
						if (this.populateOwner) this.populateOwner(user, this.items);
						this.availableEntities.remove(user);
					});
					this.usersGroupsService.processGroupsForPersonalizedHierarchy(sharingTableContent.groups).forEach((group) => {
						this.sharedEntities.push(group);
						this.availableEntities.remove(group);
					});
					sharingTableContent.metaGroups.forEach((metaGroup) => {
						this.sharedEntities.push(metaGroup);
						this.availableEntities.remove(metaGroup);
					});

					this.onChangeInternal();
					this.loadingShares = false;

					// we only want to mark the shared items, not remove them from the list
					const FLAG_ONLY = true;
					this.sharingService.removeSharedEntities(this.availableEntities, this.sharedEntities, FLAG_ONLY);
				});
			});
	}

	private populateLoadedAvailableEntities = (usersAndGroups) => {
		this.entityMap = {};
		this.availableEntities = [];

		// users are split by priority and each set is sorted in alphanumeric order on API
		usersAndGroups.users.forEach(this.addAvailableEntity);

		this.usersGroupsService.processGroupsForPersonalizedHierarchy(usersAndGroups.groups).concat(this.CB_USERS)
			.sort((left, right) => left.displayName.localeCompare(right.displayName))
			.forEach(this.addAvailableEntity);

		if (!this.isBulkShare) this.loadPublicStatus();

		return this.availableEntities;
	}

	private addAvailableEntity = (entity) => {
		let entityKey = entity.type + entity._name;

		this.availableEntities.push(entity);
		this.entityMap[entityKey] = entity;
	}

	private onChangeInternal = () => {
		this.recalculateUsers();

		if (this.onChange) {
			this.onChange();
		}

		if (this.changeTracker) {
			this.changeTracker.updates = this.processChanges();
		}
	}

	private recalculateUsers = (): void => {
		this.countNewShares = this.sharingService.getNewCountMessage(this.addedEntities);
		this.countShares = this.sharingService.getCountMessage(this.sharedEntities, this.items[0].ownerName);
	}

	private loadPublicStatus = (): void => {
		if (this.getShareStatus() === SharingStatus.PUBLIC) {
			this.PUBLIC.permission = 'VIEW';
			if (!_.find(this.sharedEntities, this.PUBLIC)) {
				this.sharedEntities.push(this.PUBLIC);
			}
			this.isSharedToAllUsers = true;
			this.wasInitiallySharedToAll = true;
		}
		this.onChangeInternal();
	}

	togglePublicState = (): void => {
		// checkbox state has changed by the time we call this
		// so we need to know what the value WAS, not what it IS now
		let priorPublicState = !this.isSharedToAllUsers;

		if (priorPublicState) {
			this.removeShareToAll();
		} else {
			this.PUBLIC.permission = 'VIEW';
			if (this.wasInitiallySharedToAll) {
				// if they removed it but then added it back without ever submitting...
				this.sharedEntities.push(this.PUBLIC);
				delete this.PUBLIC.action;
			} else {
				this.addedEntities.push(this.PUBLIC);
				this.PUBLIC.action = ShareAction.ADD;
			}

			this.openAddPanelOnFirstAdd();
		}
	}

	isOwner = (item): boolean => item.displayName === this.getOwnerName();

	private getOwnerName = (): string => this.isBulkShare ? '' : this.items[0].ownerName;

	private canUpdateReshareFlag = (): boolean =>
		!this.isBulkShare && this.security.isCurrentUser(this.getOwnerName())

	showReshareFlag = (): boolean =>
		this.items && this.items[0] && !this.isAllowEditorsToShareDisabled() && this.canUpdateReshareFlag()

	private isAllowEditorsToShareDisabled = (): boolean => this.uiConfig && this.uiConfig.hideAllowEditorsToShare;

	private canModifyPermission = (entity): boolean => {
		let entityIsNotObjectOwner = entity.permission !== PermissionType.OWN;
		let isNotCurrentUser = this.shareModificationUtils.isNotCurrentUser(entity);

		return this.singlePermissionMode || isNotCurrentUser && entityIsNotObjectOwner;
	}

	// set all non-owners in target list to view permission
	setAllToView = (targetList: any[]) => {
		targetList = _.map(targetList, (entity) => {
			if (this.canModifyPermission(entity)) {
				entity.permission = PermissionType.VIEW;
				entity.action = ShareAction.UPDATE;

				if (entity._children) {
					entity._children.map((entityChild) => delete entityChild.action);
				}
			}
			return entity;
		});
	}

	private removeShareToAll = (isPermissionChange: boolean = false): void => {
		if (this.isBulkShare && isPermissionChange) return;

		this.isSharedToAllUsers = false;
		if (_.find(this.addedEntities, this.PUBLIC)) {
			this.addedEntities.remove(this.PUBLIC);
		} else {
			this.sharedEntities.remove(this.PUBLIC);
			this.PUBLIC.action = ShareAction.DELETE;
		}
	}

	removeAll = (targetList: any[]) => {
		// remove share to all right away, if it exists
		this.removeShareToAll();

		targetList = _.map(targetList, (entity) => {
			if (this.canModifyPermission(entity)) {
				entity.action = ShareAction.DELETE;
				entity.permission = PermissionType.REMOVE;

				// style any children (if it's a group) with the deleted style as well
				if (entity._children) {
					entity._children.map((entityChild) => entityChild.action = ShareAction.DELETE);
				}
			}
			return entity;
		});
	}




	getPreviouslySharedHeading = (): string => {
		let removeNum: number = _.filter(this.sharedEntities, (entity: IShareEntity) => entity.action === ShareAction.DELETE).length;
		let langObject = {
			sharedNum: this.sharedEntities.length,
			removeNum
		};

		return (removeNum > 0) ?
			this.locale.getString('common.previouslySharedAndRemove', langObject) :
			this.locale.getString('common.previouslyShared', langObject);
	}

	getBulkShareHeading = (): string => {
		return `${this.locale.getString(`object.${this.resolve.objectTypePlural}`)} (${this.items.length})`;
	}

	onAddNew = (entity) => {
		this.openAddPanelOnFirstAdd();
	}

	isModifiableShares = (): boolean => _.any(this.sharedEntities, this.canModifyPermission);

	disableShareButton = (): boolean => {
		return (this.isBulkShare && this.addedEntities.length < 1) || this.data.loading.save;
	}

	disableCancelButton = (): boolean => this.data.loading.save || this.loadingShares;

	populatePreventedFromSharingState = (): void => {
		this.data.preventReshare = !this.editorsAllowedToShare;
		this.items[0].reshareAllowed = this.editorsAllowedToShare;
	}

	getAssetEditPermissionType = (): AssetEditPermissionAction => this.assetEditPermissionType;
}

app.component('genericShare', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
		getPermissionName: '<',
		getSharedUsersAndGroups: '<',
		getShareStatus: '<',
		objectTypeName: '@',
		nameProperty: '@?',
		embedded: '<?',
		saveListener: '<?',
		oneClickRemoval: '<?',
		itemOwner: '<owner',
		uiConfig: '<',
		changeTracker: '<',
		onChange: '&?',
		addedEntities: '=?',
		validateInvite: '<customValidation',
		removeNewShare: '=?removeAdded',
		viewToNotify: '<?',
		assetEditPermissionType: '<',
		singlePermissionMode: '<?'
	},
	controller: GenericShareComponent,
	templateUrl: 'partials/sharing/generic-share.component.html'
});
