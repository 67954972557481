import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';

export enum ThresholdRule {
	LEFT_INCLUSIVE = 'left', // means that threshold value goes to left interval, e.g. "...1.0], (1.0..."
	RIGHT_INCLUSIVE = 'right' // "...1.0), [1.0..."
}

export class MetricThresholdRules {

	static calculateValueIndex(value: number, thresholds: number[], rules: ThresholdRule[]): number {
		if (thresholds.length !== rules.length)
			throw new Error('There should be the same amount of thresholds and rules');
		for (let i = 0; i < thresholds.length; i++) {
			if (MetricThresholdRules.matchesRule(value, thresholds[i], rules[i]))
				return i;
		}
		return thresholds.length;
	}

	/**
	 * Return n+1 values, that match each threshold range
	 */
	static getValuesMatchingAllThresholds(definition: MetricDefinition): number[] {
		let allBounds = [];
		allBounds.push(definition.min);
		allBounds.pushAll(definition.thresholds);
		allBounds.push(definition.max);

		let values = [];
		for (let i = 0; i < allBounds.length - 1; i++) {
			let leftValue = allBounds[i];
			let rightValue = allBounds[i + 1];
			values.push((leftValue + rightValue) / 2);
		}

		return values;
	}

	private static matchesRule(value: number, threshold: number, rule: ThresholdRule): boolean {
		if (rule === ThresholdRule.LEFT_INCLUSIVE)
			return value <= threshold;
		if (rule === ThresholdRule.RIGHT_INCLUSIVE)
			return value < threshold;
		throw new Error('Invalid threshold rule:' + rule);
	}

	static getEaseRules(): ThresholdRule[] {
		// same for group and calculation
		//[-5, -2.5) [-2.5, -1) [-1,1] (1, 2.5] (2.5, 5]
		return [ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.LEFT_INCLUSIVE, ThresholdRule.LEFT_INCLUSIVE];
	}

	static getSentimentCalculationRules(): ThresholdRule[] {
		// [-5 -1) [-1 -0.4) [-0.4 0.4) [0.4 1) [1 5]
		return [ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE];
	}

	static getSentimentGroupRules(): ThresholdRule[] {
		// [-5 -2.5) [-2.5 -1] (-1 1) [1 2.5] (2.5 5]
		return [ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.LEFT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.LEFT_INCLUSIVE];

	}

	static getNumericBreakdownGroupRules(): ThresholdRule[] {
		//[1, 2) [2, 4) [4, 5]
		return [ThresholdRule.RIGHT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE];
	}

	/*
	 *	See also NumericBreakdownMetricDefinition (Studio) and BreakdownMetricBuilder (PLAT)
	 */
	static getNumericBreakdownCalculationRules(): ThresholdRule[] {
		//[1, 2.1] (2.1, 3.8) [3.8, 5]
		return [ThresholdRule.LEFT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE];
	}

}
