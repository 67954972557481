import { InteractionActionsService, IInteractionActionsService } from '@app/modules/interaction-explorer/interaction-actions.service';
import { Security } from '@cxstudio/auth/security-service';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { InteractionExplorer } from '@cxstudio/interaction-explorer/interaction-explorer.component';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';

interface GridContextMenuOption<T> {
	name: string;
	text: string;
	type?: string;
	value?: T;
	func?: () => void;
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('interactionsContextMenuUtils', function(
	locale: ILocale,
	interactionActionsService: InteractionActionsService,
	security: Security,
	dashboardService
) {
	return class InteractionsContextMenuUtils extends BaseContextMenuUtils {
		private component: InteractionExplorer;
		private actionsService: IInteractionActionsService;

		constructor(component: InteractionExplorer) {
			super();
			this.component = component;
			this.actionsService = interactionActionsService.initialize(component);
		}

		// required for base class, but not currently used -- all objects are visible
		isVisibleObject(): boolean {
			return true;
		}

		getContextMenu(multipleDocuments: boolean): Array<ContextMenuItem<CbDocument>> {
			let allOptions = this.getContextMenuOptions();

			let menuOptions: Array<ContextMenuItem<CbDocument>> = [ allOptions.OPEN, allOptions.COPY_LINK ];

			if (security.has('manual_case_creation')) {
				menuOptions.insert(1, allOptions.CREATE_CASE);
			}

			menuOptions.push(allOptions.SEND_TO);

			if (security.has('export_feedback')) {
				menuOptions.push(allOptions.EXPORT);
			}

			if ((multipleDocuments && this.component.permissions?.deleteMultipleDocuments)
					|| (!multipleDocuments && this.component.permissions?.deleteSingleDocument)) {
				menuOptions.push(allOptions.DELETE);
			}

			if (menuOptions.length > 1) {
				this.insertDividerAfter(menuOptions, allOptions.OPEN);
			}

			return menuOptions;
		}

		private insertDividerAfter = (menu: Array<ContextMenuItem<CbDocument>>, option: ContextMenuItem<CbDocument>): void => {
			let optionIndex = _.findIndex(menu, { name: option.name });
			menu.splice(optionIndex + 1, 0, MenuDivider);
		}

		private getContextMenuOptions = () => {
			return {
				OPEN: {
					name: 'open',
					text: locale.getString('widget.openInExplorer'),
					func: (document: CbDocument) => this.actionsService.openDocuments(this.getSelectedDocuments(document))
				},

				CREATE_CASE: {
					name: 'create_case',
					text: locale.getString('docExplorer.createCase'),
					func: (document: CbDocument) => this.actionsService.createCase(this.getSelectedDocuments(document))
				},

				COPY_LINK: {
					name: 'copy_link',
					text: locale.getString('common.copyLink'),
					func: (document: CbDocument, _option: any, _models: any, event: Event) =>
						this.actionsService.copyLink(event, this.getSelectedDocuments(document))
				} as unknown as ContextMenuItem<CbDocument>,

				SEND_TO: {
					name: 'send_to',
					text: locale.getString('interactionExplorer.sendTo'),
					searchBox: true,
					tree: this.getDashboardOptions(),
					treeAsSubmenu: true
				},

				EXPORT: {
					name: 'export',
					text: locale.getString('common.export'),
					func: (document: CbDocument) => this.actionsService.exportDocuments(this.getSelectedDocuments(document))
				},

				DELETE: {
					name: 'delete_document',
					text: locale.getString('interactionExplorer.deleteDocument'),
					func: (document: CbDocument) => this.actionsService.deleteDocuments(this.getSelectedDocuments(document))
				}
			};
		}

		private getDashboardOptions = () => {
			let options = [];
			if (security.has('create_dashboard')) {
				options.push({
					name: locale.getString('dashboard.newDashboard'),
					displayName: locale.getString('dashboard.newDashboard'),
					func: (document: CbDocument) => this.actionsService.sendDocumentsToDashboard(this.getSelectedDocuments(document))
				});
				options.push(MenuDivider);
			}
			let dashboards = dashboardService.getEditableDashboards();
			dashboards = dashboards.map(dashboard => ({
				name: dashboard.name,
				displayName: dashboard.name,
				func: (document: CbDocument) => this.actionsService.sendDocumentsToDashboard(this.getSelectedDocuments(document), dashboard)
			}));
			options.pushAll(dashboards);
			return options;
		}

		private getSelectedDocuments = (clickedDocument?: CbDocument): CbDocument[] => {
			let utils = this.component.getUtils();
			return utils.areMultipleItemsSelected() ? this.component.getSelectedDocuments() : [ clickedDocument ];
		}

		getGridMenuItems = (): Array<GridContextMenuOption<any>> => {
			let allOptions = this.getGridMenuOptions();

			let sentimentOption = _.clone(allOptions.SENTIMENT_FORMATTING);
			sentimentOption.value = this.component.settings.showSentimentFormatting;
			sentimentOption.func = () => this.actionsService.toggleSentimentFormatting(sentimentOption.value);

			let enrichmentOption = _.clone(allOptions.ENRICHMENT_BADGES);
			enrichmentOption.value = this.component.settings.showEnrichmentBadges;
			enrichmentOption.func = () => this.actionsService.toggleEnrichmentBadges(enrichmentOption.value);
			return [sentimentOption, enrichmentOption];
		}

		private getGridMenuOptions = (): {[key: string]: GridContextMenuOption<any>} => {
			return {
				SENTIMENT_FORMATTING: {
					name: 'sentiment_formatting',
					text: locale.getString('interactionExplorer.displaySentimentTextFormatting'),
					type: 'switch',
				},
				ENRICHMENT_BADGES: {
					name: 'enrichment_badges',
					text: locale.getString('interactionExplorer.displayEnrichmentBadges'),
					type: 'switch',
				}
			};
		}
	};
});
