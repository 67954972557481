import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { RedirectInterceptService } from './redirect-intercept.service';

@Component({
	selector: 'redirect-intercept',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectInterceptComponent implements OnInit {
	constructor(
		private redirectInterceptService: RedirectInterceptService
	) { }

	ngOnInit(): void {
		window.addEventListener('click', (event) => this.redirectInterceptService.processClick(event));
	}
}

app.directive('redirectIntercept', downgradeComponent({ component: RedirectInterceptComponent }) as angular.IDirectiveFactory);
