import {Component, ChangeDetectionStrategy, OnInit, Input} from '@angular/core';

export interface AccountReportExportSettings {
	includeAdminOrganizations: boolean;
	includeAccountsWithoutContracts: boolean;
	includeDisabledAccounts: boolean;
}

interface HeaderInput {
	header: string;
}

@Component({
	selector: 'account-report-export-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<simple-dialog
			[header]="input.header"
			okBtnName="{{'common.ok'|i18n}}"
			cancelBtnName="{{'common.cancel'|i18n}}"
			[value]="value">
			<checkbox-button class="d-block" label="{{'administration.isIncludeAdminOrganizations'|i18n}}"
				[(ngModel)]="value.includeAdminOrganizations"></checkbox-button>
			<checkbox-button class="d-block" label="{{'administration.isIncludeWithoutContracts'|i18n}}"
				[(ngModel)]="value.includeAccountsWithoutContracts"></checkbox-button>
			<checkbox-button class="d-block" label="{{'administration.isIncludeDisabledAccounts'|i18n}}"
				[(ngModel)]="value.includeDisabledAccounts"></checkbox-button>
		</simple-dialog>
	`
})
export class AccountReportExportDialogComponent implements OnInit {
	value: AccountReportExportSettings;

	@Input() input: HeaderInput;

	ngOnInit(): void {
		this.value = {
			includeAdminOrganizations: false,
			includeAccountsWithoutContracts: false,
			includeDisabledAccounts: false
		};
	}
}
