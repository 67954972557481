import { Injectable } from '@angular/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class OrganizationEnrichmentApiService {
	constructor(
		private readonly http: CxHttpService,
	) {}

	getReportableHierarchies(project: WorkspaceProject): AssetPromisePlatformProject<ReportableHierarchy[]> {
		const url = `rest/organization/enrichment/reportable/ws/${project.workspaceId}/project/${project.projectId}`;
		return this.http.get(url) as AssetPromisePlatformProject<ReportableHierarchy[]>;
	}
}
