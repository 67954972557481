<aside
	cdkDropList
	cdkDropListOrientation="vertical"
	[cdkDropListData]="filter.items"
	(cdkDropListDropped)="moveFilterItem($event)">
	<div *ngFor="let item of filter.items; index as $index" class="w-100-percent mb-8 mixed-filter-builder-item">
		<div class="filter-statement d-flex justify-between align-center filter-grouping-statement" [ngClass]="item.type === 'FILTER_RULE' && getClasses(item.entity)" cdkDrag [cdkDragDisabled]="item.pinned">
			<cb-drag-handle *ngIf="!item.pinned" class="grip-cell" cdkDragHandle></cb-drag-handle>

			<filter-rule
				*ngIf="item.type === 'FILTER_RULE'"
				class="flex-fill d-flex align-items-center"
				[rule]="item.entity"
				[filterableFields]="filterableFields"
				[allowDateRange]="true"
				[projectSelection]="projectSelection"
				[opened]="shouldOpen(item.entity)"
				[appendToBody]="appendToBody"
				[pinned]="item.pinned"
				(ruleChange)="onRuleChange()">
			</filter-rule>

			<div *ngIf="item.type === 'SAVED_FILTER'" class="filter-grouping-statement flex-fill d-flex align-items-center">
				<label class="filter-name">{{'interactionExplorer.savedFilter'|i18n}}</label>
				<div class="grouping-filters">
					<search-list
						dropdown="true"
						[data]='item.entity'
						searchPrompt="{{'reportFilters.findMessage'|i18n}}"
						displayField="name"
						sortField="name"
						[multiListOptions]="savedFilterOptions"
						multi="true"
						selectorClass="br-filter-selector"
						[autoOpen]="false"
						[appendToBody]="true"
						(onNodeSelection)="changeSavedFilter($index, $event.node)">
					</search-list>
				</div>
			</div>

			<div *ngIf="item.type === 'HARDCODED_FILTER'" class="filter-grouping-statement flex-fill d-flex align-items-center">
				<div class="chicklet option-chicklet option-chicklet-static filter-rule-values">
					{{item.entity.displayName}}
				</div>
			</div>


			<div class="filter-tools d-inline-block">
				<button type="button" class="btn btn-icon remove-filter-button"
					[disabled]="!canRemoveItem(item)"
					(click)="removeItem($index)" title="{{'widget.minus'|i18n}}">
					<span class="q-icon q-icon-minus" aria-hidden="true"></span>
				</button>
				<button
					type="button" class="btn btn-icon add-filter-button ml-0"
					[disabled]="!canAddItem()"
					(click)="addItem()" title="{{'widget.plus'|i18n}}">
					<span class="q-icon q-icon-add" aria-hidden="true"></span>
				</button>
			</div>
		</div>
	</div>
</aside>
