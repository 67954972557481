import * as _ from 'underscore';
import { Map } from 'typescript';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';


/**
 * Important: remember to update ESQueryBuilderImpl logic and its tests on backend, whenever this logic is updated on Frontend
 */
const FILTER_MAX_LENGTH = 1000;
const OLD_PREVIEW_MAX_LENGTH = 10000;

@Injectable()
export class EsQueryService {
	constructor() {
	}

	generateEmptyEsQueryObject(): any {
		return {
			keyword: '',
			and1: '',
			and2: '',
			not: ''
		};
	}

	validate(esQueryObject: Map<string>): boolean {
		return this.validateRules(esQueryObject, FILTER_MAX_LENGTH);
	}

	validatePreview(esQueryObject: Map<string>): boolean {
		return this.validateRules(esQueryObject, OLD_PREVIEW_MAX_LENGTH);
	}

	private validateRules(esQueryObject: Map<string>, maxLength: number): boolean {
		let valid = false;

		for (let property in esQueryObject) {
			if (esQueryObject.hasOwnProperty(property)) {
				valid = valid || (esQueryObject[property].length > 0 && esQueryObject[property].length <= maxLength);
			}
		}

		return valid;
	}

	getMaxLength(isFilterRule: boolean): number {
		return isFilterRule
			? FILTER_MAX_LENGTH
			: OLD_PREVIEW_MAX_LENGTH;
	}

	getRuleString(esQueryObject: any): string {
		let andString = this.getAndString(esQueryObject);
		let displayValue = andString;
		if (displayValue.length && this.hasRule(esQueryObject, 'not')) {
			//If there is both AND condition and NOT condition, add the ; separator
			displayValue += '; ';
		}
		if (this.hasRule(esQueryObject, 'not')) {
			displayValue += 'Not: ' + esQueryObject.not;
		}
		return displayValue;

	}

	getAndString(esQueryObject: any): string {
		let andString = '';
		let ruleItems = [];
		if (this.hasRule(esQueryObject, 'keyword') || this.hasRule(esQueryObject, 'and1')
			|| this.hasRule(esQueryObject, 'and2')) {
			andString += 'NLP: ';
			this.checkRuleAndAdd(esQueryObject, 'keyword', ruleItems);
			this.checkRuleAndAdd(esQueryObject, 'and1', ruleItems);
			this.checkRuleAndAdd(esQueryObject, 'and2', ruleItems);
			andString += ruleItems.join(', ');
		}
		return andString;
	}

	checkRuleAndAdd(esQueryObject: any, keyword: string, ruleArray: string[]): void {
		if (this.hasRule(esQueryObject, keyword)) {
			ruleArray.push(esQueryObject[keyword]);
		}
	}

	hasRule(esQueryObject: any, keyword: string): boolean {
		return esQueryObject && esQueryObject[keyword] && esQueryObject[keyword].length > 0;
	}

	/**
	 * Important: remember to update ESQueryBuilderImpl logic and its tests on backend, whenever this logic is updated on Frontend
	 */
	processQuery = (query: string): string => {
		let sanitizedQuery = this.sanitizeSearchQuery(query);
		let splitTextFilter = sanitizedQuery.split(/[ ,]+(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/).filter(Boolean);
		return _.map(splitTextFilter, (keyword) => {
			if (keyword.length >= 3 && keyword[0] !== '"' && keyword[keyword.length - 1] !== '"') {
				return keyword + '*';
			}
			return keyword;
		}).join(', ');
	}

	sanitizeSearchQuery = (searchQuery?: string): string => {
		if (searchQuery === undefined || searchQuery === '') {
			return searchQuery;
		}
		let quotationIndices = [];
		let quoteIndex = searchQuery.indexOf('"');
		while (quoteIndex > -1) {
			quotationIndices.push(quoteIndex);
			quoteIndex = searchQuery.indexOf('"', quoteIndex + 1);
		}

		// Per the elastic search documentation,
		// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters
		// The following special characters are captured by this regex.
		// + - = > < ! ( ) { } [ ] ^ " ~ * ? : \ / && ||
		let specialCharsRegex = /(?:\+|-|=|>|<|!|\(|\)|{|}|\[|\]|\^|~|\*|\?|:|\/|\\|"|&&|\|\|)/g;

		return searchQuery.replace(specialCharsRegex, (match, offset) => {
			if (this.shouldBeRemoved(quotationIndices, offset, match)) {
				return '';
			}
			return match;
		});
	}

	// A " quote should be removed if it has no closing counter part.
	// Every other special character should be removed unless it's within a quoted phrase.
	private shouldBeRemoved = (quoteIndices, characterIndex, character): boolean => {
		if (character === '"') {
			// True if it's the last " without a closing "
			return (quoteIndices.length % 2 === 1) && (characterIndex === quoteIndices[quoteIndices.length - 1]);
		}
		let indexOfFirstQuoteAfterCharacter = -1;
		quoteIndices.some((quoteIndex, index) => {
			if (characterIndex < quoteIndex) {
				indexOfFirstQuoteAfterCharacter = index;
				return true;
			}
			return false;
		});
		return (indexOfFirstQuoteAfterCharacter === -1) || (indexOfFirstQuoteAfterCharacter % 2 === 0);
	}
}

app.service('esQueryService', downgradeInjectable(EsQueryService));