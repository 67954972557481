<modal-header
	(cancel)="cancel()"
	[modalTitle]="dialogHeader"
	[style]="styles.DANGER">
</modal-header>
<div class="modal-body">
	<div>
		<p class="pb-32 d-flex align-items-center">
			{{'interactionExplorer.selectReasonLabel' | i18n}}
			<simple-dropdown
				class="pl-8 flex-fill"
				[(value)]="reason"
				[options]="reasonOptions"
				displayField="displayName"
				selectPrompt="{{'interactionExplorer.selectReasonPrompt' | i18n}}"
				[appendToBody]="true"
				[noMinWidth]="true"
			></simple-dropdown>
		</p>
		<p [innerHTML]="confirmationMessage"></p>
	</div>
</div>
<save-modal-footer
	(save)="confirm()"
	(cancel)="cancel()"
	saveText="{{'common.delete' | i18n}}"
	[isDisabled]="!reason"
	[style]="styles.DANGER"
></save-modal-footer>
