import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, HostBinding } from '@angular/core';
import { DocumentTranslationService } from '@app/modules/document-explorer/translation/document-translation.service';
import { CxLocaleService } from '@app/core';
import { TranslationCacheService } from '@app/modules/translation/translation-cache.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'translate-button',
	template: `
		<button *ngIf="isTranslateVisible()"
			class="q-icon-translate btn btn-icon"
			[class.btn-selected]="active"
			[class.focus:text-white]="active"
			[class.no-border]="disabled"
			[disabled]="hasReachedTranslateServiceLimit() || disabled"
			(click)="translateCurrent()"
			[attr.aria-label]="getTranslationAriaLabel()">
		</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslateButtonComponent extends SelfCleaningComponent implements OnInit {

	@Input() active: boolean;
	@Input() disabled: boolean;
	@Input() disabledMessage: string;
	@Output() translate = new EventEmitter<void>();

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly translateCache: TranslationCacheService,
		private readonly documentTranslationService: DocumentTranslationService,
	) {
		super();
	 }

	ngOnInit(): void {
		this.addSubscription(this.documentTranslationService.getChangeObserver().subscribe(() => this.ref.markForCheck()));
		this.addSubscription(this.translateCache.getTranslateObserver().subscribe(() => this.ref.markForCheck()));
	}

	isTranslateVisible(): boolean {
		return this.documentTranslationService.isEnabled();
	}

	hasReachedTranslateServiceLimit(): boolean {
		return this.documentTranslationService.isReachedLimit();
	}

	translateCurrent(): void {
		if (this.documentTranslationService.isReachedLimit()) {
			return;
		}
		this.translate.emit();
	}

	// applying to parent because button won't show title when disabled
	@HostBinding('title')
	get getDisabledMessage(): string {
		if (this.disabled) {
			return this.disabledMessage;
		}
		if (this.hasReachedTranslateServiceLimit()) {
			return this.locale.getString('docExplorer.translateUnavailable');
		}
		if (this.active && !!this.translateCache.getLastTranslateLanguage()) {
			return this.locale.getString('docExplorer.translatedFromTo', {
				from: this.translateCache.getLastTranslateLanguage(),
				to: this.documentTranslationService.getCurrentTranslationLanguageDisplayName(),
			});
		}
		return this.locale.getString('docExplorer.translate');
	}

	getTranslationAriaLabel(): string {
		return this.locale.getString('docExplorer.translateTextTo', {
			language: this.documentTranslationService.getCurrentTranslationLanguageDisplayName()
		});
	}

}

app.directive('translateButton', downgradeComponent({component: TranslateButtonComponent}) as angular.IDirectiveFactory);
