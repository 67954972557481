import { INgModelController } from 'angular';

/**
 * Browser autofill fix, require firing event before reading properties, see LoginCtrl
 */
app.directive('autofill', () => {
	return {
		require: 'ngModel',
		link: (scope, element, attrs, ngModel) => {
			scope.$on('autofill:update', () => {
				(ngModel as INgModelController).$setViewValue(element.val());
			});
		}
	};
});
