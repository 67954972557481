<table>
	<tbody>
		<!-- Some of these abbreviations should not be translated, so no i18n-->
		<tr *ngIf="!!modelMetrics.fScores">
			<td class="pr-32 pb-32">
				<text-with-help helpText="{{'drivers.metricMCC'|i18n}}">
					MCC
				</text-with-help>
			</td>
			<td></td>
			<td class="pb-32">{{modelMetrics.fScores.mcc | number:'1.3-3'}}</td>
		</tr>
		<tr *ngIf="!!modelMetrics.fScores">
			<td class="pr-32 pb-32 v-top">
				<span [i18n]="'drivers.fScores'"></span>
			</td>
			<td class="pr-32 pb-32">
				<div class="d-flex flex-direction-column">
					<text-with-help helpText="{{'drivers.metricF1'|i18n}}" class="d-flex flex-direction-row justify-between">
						<span>F<sub>1</sub></span>
					</text-with-help>
					<text-with-help helpText="{{'drivers.metricF2'|i18n}}" class="d-flex flex-direction-row justify-between">
						<span>F<sub>2</sub></span>
					</text-with-help>
					<text-with-help helpText="{{'drivers.metricF0_5'|i18n}}" class="d-flex flex-direction-row justify-between">
						<span>F<sub>0.5</sub></span>
					</text-with-help>
				</div>
			</td>
			<td class="pb-32">
				<div class="d-flex flex-direction-column">
					<span>{{modelMetrics.fScores.f1 | number:'1.2-2'}}</span>
					<span>{{modelMetrics.fScores.f2 | number:'1.2-2'}}</span>
					<span>{{modelMetrics.fScores.f0_5 | number:'1.2-2'}}</span>
				</div>
			</td>
		</tr>
		<tr>
			<td class="pr-32 pb-32 v-top">
				<span>AUC</span>
			</td>
			<td class="pr-32 pb-32">
				<div class="d-flex flex-direction-column">
					<text-with-help helpText="{{'drivers.metricROC'|i18n}}" class="d-flex flex-direction-row justify-between">
						ROC
					</text-with-help>
					<text-with-help helpText="{{'drivers.metricPRC'|i18n}}" class="d-flex flex-direction-row justify-between">
						PRC
					</text-with-help>
				</div>
			</td>
			<td class="pb-32">
				<div class="d-flex flex-direction-column">
					<span>{{modelMetrics.roc | number:'1.2-2'}}</span>
					<span>{{modelMetrics.prc | number:'1.2-2'}}</span>
				</div>
			</td>
		</tr>
	</tbody>
</table>
