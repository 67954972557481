import { ScorecardTopic } from './entities/scorecard-topic';
import { Model } from '@cxstudio/reports/entities/model';
import { AbstractTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { ITreeSelection } from '@app/shared/components/tree-selection/tree-selection';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';


export interface IScorecardTreeNode extends AbstractTreeNode<IScorecardTreeNode> {
	parent?: IScorecardTreeNode;
	topic?: ScorecardTopic;
	hasUsedDescendants?: boolean;
	isInUsedSubtree?: boolean;
	sumWeight?: number;
}
/**
 * Object to hold all model specific selections in context of scorecard editor.
 */
export class ScorecardModelContext {
	model: Model;
	otherScorecardTopics: ScorecardTopic[];
	scorecardTopics: ScorecardTopic[];
	selection: ITreeSelection;
	threshold: number;
	thresholdRaw: number;
	rootNode: IScorecardTreeNode;
	total: number;
	scoringEntries: IScorecardTreeNode[];
	modelTree: IScorecardTreeNode;
	inUseTopics: any[];
	driverModel: {[idPath: string]: number};
	driver: DriversItem;
}
