import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { DriversItem, HiddenDriver } from '@cxstudio/drivers/entities/drivers-item';
import { DriversModel, IDriversModelItem, IDriversModelMetrics } from '@cxstudio/drivers/entities/drivers-model';
import { DriversResultItem } from '@cxstudio/drivers/entities/drivers-result-item';
import { DriversFormatting } from '@cxstudio/drivers/utils/drivers-formatting.service';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface DriversResultsModalInput {
	driversItem: DriversItem;
}

interface ICurrentState {
	hiddenDrivers: HiddenDriver[];
	minVolume: number;
}

@Component({
	selector: 'drivers-results-dialog',
	templateUrl: './drivers-results-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriversResultsDialogComponent implements OnInit {

	@Input() input: DriversResultsModalInput;

	driversItem: DriversItem;

	loading: Promise<any>;
	jsonData: string;
	predictiveDrivers: IDriversModelItem[]; // raw data from predictive
	reportDrivers: DriversResultItem[]; // populated report data
	hiddenDrivers: DriversResultItem[];

	modelMetrics: IDriversModelMetrics;

	private constants;
	private initialState: ICurrentState;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly modal: NgbActiveModal,
		private readonly driversApi: DriversApi,
		@Inject('reportDataApiService') private readonly reportDataApiService: ReportDataApiService,
		@Inject('driversUtils') private readonly driversUtils: DriversUtils,
		@Inject('driversFormatting') private readonly driversFormatting: DriversFormatting,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
	) {}

	ngOnInit(): void {
		this.driversItem = this.input.driversItem;
		this.constants = this.metricConstants.get();
		this.initialState = ObjectUtils.copy(this.getCurrentState());
		this.hiddenDrivers = [];

		this.loading = Promise.all([this.getPredictiveDrivers(), this.getReportDrivers()]).then(results => {
			this.jsonData = results[0].json;
			this.predictiveDrivers = results[0].drivers;
			this.modelMetrics = results[0].modelMetrics;

			let hiddenIdentifiers = _.map(this.driversItem.hiddenDrivers, hidden => hidden.identifier) || [];
			this.reportDrivers = _.filter(results[1], driver => !_.contains(hiddenIdentifiers, driver.identifier));
			let hiddenExisting = _.filter(results[1], driver => _.contains(hiddenIdentifiers, driver.identifier));

			let existingIdentifiers = _.map(hiddenExisting, driver => driver.identifier);
			let hiddenMissing = _.chain(this.driversItem.hiddenDrivers)
				.filter(hiddenDriver => !_.contains(existingIdentifiers, hiddenDriver.identifier))
				.map(hiddenDriver => ({
					identifier: hiddenDriver.identifier,
					displayName: `${hiddenDriver.displayName} (${this.locale.getString('drivers.missingDriver')})`
				}))
				.value() as DriversResultItem[];
			this.hiddenDrivers = _.union(hiddenExisting, hiddenMissing);
			this.updateRankings();
		});
	}

	private getPredictiveDrivers(): Promise<{
		drivers: IDriversModelItem[],
		json: string,
		modelMetrics: IDriversModelMetrics
	}> {
		return this.driversApi.getDriversModel(this.driversItem.id).then((result: DriversModel) => {
			let viewDrivers = _.each(ObjectUtils.copy(result.drivers), driver => delete driver.identifier);
			let json = JSON.stringify(viewDrivers, null, '\t');
			return {
				drivers: result.drivers,
				json,
				modelMetrics: result.modelMetrics
			};
		});
	}

	private getReportDrivers(): Promise<DriversResultItem[]> {
		let reportSettings = this.driversUtils.getReportSettings(this.driversItem, true);
		return PromiseUtils.wrap(this.reportDataApiService.getReportData(reportSettings)).then((result: ng.IHttpPromiseCallbackArg<any[]>) => {
			return _.map(result.data, (item: any) => {
				let driversResultItem = new DriversResultItem();
				driversResultItem.strength = parseFloat(item[this.constants.STRENGTH.name].toFixed(4));
				driversResultItem.rawStrength = item[this.constants.STRENGTH.name];
				driversResultItem.volume = item[this.constants.VOLUME.name];

				let fieldName = '_drivers_' + this.driversItem.id;

				let metadataField = '_metadata_' + fieldName;
				let metadata = item[metadataField];
				driversResultItem.displayName = this.driversFormatting.getDriverDisplayName(item[fieldName]);
				driversResultItem[metadataField] = metadata;
				driversResultItem.impactRank = metadata.impactRank;
				driversResultItem.identifier = metadata.identifier;

				if (metadata.nodeFullPath) {
					driversResultItem.nodePath = metadata.nodeFullPath;
				}

				return driversResultItem;
			});
		});
	}

	onDriversItemHide(driversItem: DriversResultItem): void {
		this.reportDrivers.remove(driversItem);
		this.hiddenDrivers.push(driversItem);

		if (!this.driversItem.hiddenDrivers)
			this.driversItem.hiddenDrivers = [];
		this.driversItem.hiddenDrivers.push({
			displayName: driversItem.displayName,
			identifier: driversItem.identifier
		});
		this.updateRankings();

		this.updateTabsViews();
	}

	onDriversItemUnhide(driversItem: DriversResultItem): void {
		this.hiddenDrivers.remove(driversItem);
		if (driversItem.impactRank) { // existing driver
			this.reportDrivers.push(driversItem);
		}

		this.driversItem.hiddenDrivers.removeAt(
			_.findIndex(this.driversItem.hiddenDrivers, {identifier: driversItem.identifier}));
		this.updateRankings();

		this.updateTabsViews();
	}

	private updateTabsViews(): void {
		this.reportDrivers = [...this.reportDrivers];
		this.hiddenDrivers = [...this.hiddenDrivers];
	}

	getCurrentState = (): ICurrentState => {
		return _.pick(this.driversItem, 'hiddenDrivers', 'minVolume') as ICurrentState;
	}

	hasChanges = (): boolean => {
		return !_.isEqual(this.initialState, this.getCurrentState());
	}

	private updateRankings(): void {
		let groups = _.groupBy(this.reportDrivers, driver => driver.rawStrength >= 0);
		this.updateRank(groups['true']);
		this.updateRank(groups['false']);
	}

	private updateRank(drivers: DriversResultItem[]): void {
		let sorted = _.sortBy(drivers, driver => Math.abs(driver.rawStrength)).reverse();
		_.each(sorted, (driver, index) => driver.impactRank = index + 1);
	}

	showMetrics = (): boolean => {
		return !_.isEmpty(this.modelMetrics);
	}

	dismiss(): void {
		this.modal.dismiss();
	}

	close(): void {
		let currentState: ICurrentState = this.getCurrentState();
		this.modal.close(currentState);
	}

}

app.directive('driversResultsDialog', downgradeComponent({component: DriversResultsDialogComponent}) as angular.IDirectiveFactory);
