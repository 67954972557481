import { ApplicationTheme } from '@cxstudio/header/application-theme';

interface ITooltipState {
	html: string;
	theme: ApplicationTheme;
	x: number;
	y: number;
	target: Element;
}

export class SpineTooltipManager {

	readonly ANIMATION_TRANSITION = 200;
	
	private tooltipDiv: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
	private tooltipState: ITooltipState;

	private debounceCheck: () => void;

	constructor() {
		if (!d3.select('.spine-tooltip').empty()) {
			this.tooltipDiv = d3.select('.spine-tooltip');
		} else {
			this.tooltipDiv = d3.select('body').append('div').attr('class', 'spine-tooltip').attr('id', 'conversation-tooltip');
		}
		this.tooltipDiv.style('opacity', 0).style('display', 'none');
		this.debounceCheck = _.debounce(this.checkTooltipState, 150);
	}

	// event is null when showing tooltip for specific element programmatically, e.g. topic highlighting
	showTooltip(html: string, theme: ApplicationTheme, event?: MouseEvent, targetElm?: Element, skipDebounce?: boolean): void {
		let x, y;
		if (targetElm) {
			let target = $(targetElm);
			y = (target.offset().top + (target.height() / 2));
			x = target.offset().left + target.width();
		} else {
			y = event.pageY;
			x = event.pageX;
		}
		this.tooltipState = { html, theme, x, y, target: targetElm};
		if (skipDebounce) {
			this.immediateCheck();
		} else {
			this.debounceCheck();
		}
	}
	
	hideTooltip(skipDebounce?: boolean): void {
		this.tooltipState = null;
		if (skipDebounce) {
			this.immediateCheck();
		} else {
			this.debounceCheck();
		}
	}

	private immediateCheck = () => {
		let db = this.debounceCheck as any;
		if (typeof db.cancel === 'function') {
			db.cancel();
		}
		this.checkTooltipState();
	}

	private checkTooltipState = () => {
		if (this.tooltipState) {
			this.fadeIn(this.tooltipState);
		} else {
			this.fadeOut();
		}
	}

	private fadeIn(state: ITooltipState): void {
		this.tooltipDiv.classed('dark-spine-tooltip', state.theme === ApplicationTheme.DARK);
		this.tooltipDiv.html(state.html);
		
		d3.select(state.target).raise();
		this.fadeInTooltip(state.x, state.y);
	}

	private fadeOut(): void {
		this.tooltipDiv.style('opacity', 0);
		this.tooltipDiv.style('display', 'none');
	}

	private fadeInTooltip(x: number, y: number): void {
		this.tooltipDiv.style('display', 'block');
		this.tooltipDiv.transition()
			.duration(this.ANIMATION_TRANSITION)
			.style('opacity', 1.0);

		let svgMaxY = window.innerHeight;
		
		let tooltipY, tooltipX;
		const ARROW_WIDTH = 15;
		const TOOLTIP_ARROW_OFFSET_TOP = 16;

		tooltipY = y - TOOLTIP_ARROW_OFFSET_TOP;
		tooltipX = x + ARROW_WIDTH;

		let tooltipHeight = this.tooltipDiv.node().offsetHeight;
		if (tooltipY + tooltipHeight > svgMaxY)
			tooltipY = svgMaxY - tooltipHeight;

		this.tooltipDiv
			.style('left', `${tooltipX}px`)
			.style('top', `${tooltipY}px`);	
	}

}