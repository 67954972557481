import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { WidgetsUsage } from '@cxstudio/home/widgets-edit.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core';
import { ModalBindings } from '@app/modules/modal/modal-bindings';

enum TroubleshootingSection {
	SLOWNESS, UNAVAILABLE
}

export interface DashboardTroubleshootingModalInput {
	widgetsUsage: WidgetsUsage;
}

export enum HelpTopic {
	DASHBOARD_TROUBLESHOOTING = 'dashboardTroubleshooting'
}

@Component({
	selector: 'dashboard-troubleshooting-modal',
	templateUrl: './dashboard-troubleshooting-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTroubleshootingModalComponent extends ModalBindings<DashboardTroubleshootingModalInput> implements OnInit {
	@Input() input: DashboardTroubleshootingModalInput;

	sections = TroubleshootingSection;
	activeSection: TroubleshootingSection = TroubleshootingSection.SLOWNESS;
	widgetsUsage: WidgetsUsage;

	safeUrl: SafeResourceUrl;

	constructor(
		private readonly locale: CxLocaleService,
		modal: NgbActiveModal,
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.widgetsUsage = this.input.widgetsUsage;
	}

	changeActivePanel(section: TroubleshootingSection, collapsed: boolean) {
		this.activeSection = collapsed ? undefined : section;
	}

	getReadMoreAriaLabel(topic: string): string {
		return this.locale.getString('dashboardTroubleshooting.readMoreAriaLabel', { topic });
	}
}
