<simple-dialog
	[header]="getTitle()"
	[value]="successfulUpload"
	[okBtnName]="'common.ok' | i18n"
	[cancelBtnName]="'common.cancel' | i18n"
>
	<div class="wrappable-buttons form-group">
		<button
			type="button"
			class="btn btn-secondary"
			(click)="downloadTemplateFile()"
			[i18n]="'organization.downloadTemplate'"
		></button>

		<button
			*ngIf="input.update"
			type="button"
			class="btn btn-secondary"
			(click)="downloadDataFile()"
			[i18n]="'organization.downloadExistingFilters'"
		></button>

		<div *ngIf="isEditable()" class="btn-margin-left d-flex align-items-center no-wrap">
			<file-upload
				[fileUploadUrl]="getFileUploadUrl()"
				[acceptedMediaTypes]="[MediaType.XLS, MediaType.CSV]"
				(successfulUpload)="handleSuccessfulUpload($event)"
				(removedUpload)="handleRemovedUpload()"
				(multipleErrors)="updateErrorTable($event)">
			</file-upload>
		</div>
	</div>

	<formatted-table
		*ngIf="validationErrors && validationErrors.length"
		[rows]="validationErrors"
		[columns]="errorTableColumns"
		view="comfort"
		[scrollable]="true"
	></formatted-table>

	<p *ngIf="!input.update && !(validationErrors && validationErrors.length)" [i18n]="'organization.uploadFiltersWizardInstruction'"></p>
	<p *ngIf="input.update && !(validationErrors && validationErrors.length)" [i18n]="'organization.editFiltersWizardInstruction'"></p>
</simple-dialog>
