import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { SpotCheckRequest } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-request';
import { SpotCheckResultItem } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-item.component';

@Injectable({providedIn: 'root'})
export class SpotCheckApiService {
	constructor(
		private cxHttp: CxHttpService
	) {}

	performSpotCheck = (dashboardId: number, spotCheckRequest: SpotCheckRequest): Promise<SpotCheckResultItem[]> => {
		return this.cxHttp.post(`rest/spot-check/${dashboardId}`, spotCheckRequest);
	}
}
