import { ChangeDetectionStrategy, Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'platform-tab',
	templateUrl: './platform-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformTabComponent {}

app.directive('platformTab', downgradeComponent({component: PlatformTabComponent}) as angular.IDirectiveFactory);
