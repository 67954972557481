import { MasterAccountExpirationMode } from '@app/modules/system-administration/master-account/entities/master-account-expiration-mode';

export default class MasterAccountDisableData {
	expirationMode: MasterAccountExpirationMode;
	expirationDate?: Date;

	constructor(expirationMode: MasterAccountExpirationMode, expirationDate?: Date) {
		this.expirationMode = expirationMode;
		this.expirationDate = expirationDate;
	}
}
