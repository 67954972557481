<div *ngIf="contentProvider && !contentProvider.hasSingleOption()"
	class="form-group"
	[class.mr-8]="horizontal">
	<label [ngClass]="{'w-25-percent control-label': !horizontal}">{{getPrefix()}}{{'common.contentProvider'|i18n}}</label>
	<div [ngClass]="{'w-75-percent ph-16': !horizontal}">
		<simple-dropdown
			class="br-content-provider-select"
			[options]="contentProvider.getOptions()"
			[value]="contentProvider.getSelection()"
			(valueChange)="contentProvider.changeSelection($event)"
			[appendToBody]="appendToBody"
			displayField="name"
			[disabled]="disable"
			role="listbox"
			[attr.aria-label]="'common.contentProvider'|i18n"
			valueField="id">
		</simple-dropdown>
	</div>
</div>
<div *ngIf="account?.hasMultipleOptions()"
	class="form-group"
	[class.mr-8]="horizontal">
	<label [ngClass]="{'w-25-percent control-label': !horizontal}">{{getPrefix()}}{{'common.account'|i18n}}</label>
	<div [ngClass]="{'w-75-percent ph-16': !horizontal}">
		<simple-dropdown
			class="br-account-select"
			[options]="account.getOptions()"
			[value]="account.getSelection()"
			(valueChange)="account.changeSelection($event)"
			[disabled]="disable || !contentProvider.isSelected()"
			[appendToBody]="appendToBody"
			displayField="accountName"
			role="listbox"
			[attr.aria-label]="'common.account'|i18n"
			valueField="accountId">
		</simple-dropdown>
	</div>
</div>
<div class="form-group" *ngIf="project && !hideProject">
	<label [ngClass]="{'w-25-percent control-label': !horizontal}">{{getPrefix()}}{{'common.project'|i18n}}</label>
	<div [ngClass]="{'w-75-percent ph-16': !horizontal}">
		<simple-dropdown
			class="br-project-select"
			[options]="project.getOptions()"
			[value]="project.getSelection()"
			(valueChange)="project.changeSelection($event)"
			[disabled]="disable || !account.isSelected()"
			[appendToBody]="appendToBody"
			displayField="name"
			role="listbox"
			[attr.aria-label]="'common.project'|i18n"
			valueField="projectId">
		</simple-dropdown>
	</div>
</div>
