<modal-header
	(cancel)="close()"
	[modalTitle]="getTitle()"
></modal-header>

<div class="modal-body" [ngBusy]="loading">
	<div class="mb-16">
		{{getNote()}}
	</div>
	<div *ngIf="task">
		<div class="mb-16" *ngFor="let descriptionGroup of taskDescriptionGroups">
			<div class="font-bold">{{descriptionGroup.name}}:</div>
			<div>{{descriptionGroup.text}}</div>
		</div>
		<div class="w-100-percent overflow-y-hidden">
			<div class="h-max-320">
				<table class="w-100-percent" attr.aria-label="{{getTitle()}}">
					<thead>
						<tr>
							<th>{{getTargetColumn()}}:</th>
							<th>{{'administration.status'|i18n}}:</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let taskItem of task.items | slice:pagination.getPageStart():pagination.getPageEnd()">
							<td>{{taskItem.data.name}}</td>
							<td>{{getStatusLabel(taskItem.status)}}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div *ngIf="task.items?.length > 10" class="d-flex justify-end">
				<pagination
					[(pagination)]="pagination">
				</pagination>
			</div>
		</div>

		<button *ngIf="hasPendingItems()"
			type="button"
			class="btn btn-primary mt-16"
			(click)=refresh()
			[i18n]="'common.refresh'"></button>
	</div>
</div>
<ok-modal-footer
	(cancel)="close()"
	okText="{{'common.ok' | i18n}}">
</ok-modal-footer>
