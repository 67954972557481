import { ChangeDetectionStrategy, Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { AlertTriggerCriteria } from '@app/modules/alert/alert-trigger-criteria';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';
import { AlertThresholdTypes } from '@cxstudio/alerts/entities/alert-threshold-types';
import { AlertTrigger } from '@cxstudio/alerts/entities/alert-trigger';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { Security } from '@cxstudio/auth/security-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DateFilterMode, HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import * as moment from 'moment';
import { ScheduleUtilsService } from '@app/modules/dashboard/schedule/schedule-utils.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { SidebarWizardComponent } from '@app/modules/wizard/sidebar-wizard/sidebar-wizard.component';
import { ToastService, AlertLevel } from '@clarabridge/unified-angular-components';
import { PromiseUtils } from '@app/util/promise-utils';

interface AlertWizardParams {
	contentProviderId?: string;
	accountId?: string;
	projectId?: string;
}

interface IRouteParams {
	alertId: string;
}

@Component({
	selector: 'alert-wizard-page',
	templateUrl: './alert-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertWizardPageComponent implements OnInit {

	alert: StudioAlert;
	project: IProjectSelection;

	loading: Promise<unknown>;

	@ViewChild(SidebarWizardComponent) wizard: SidebarWizardComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private cxLocation: CxLocationService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		private scheduleUtilsService: ScheduleUtilsService,
		private toastService: ToastService,
		@Inject('security') private security: Security,
		@Inject('alertingApiService') private readonly alertingApiService: AlertingApiService,
	) { }

	ngOnInit(): void {
		if (this.$routeParams.alertId) {
			const alertId = parseInt(this.$routeParams.alertId, 10);
			this.alertingApiService.getStudioAlert(alertId).then(alert => {
				alert.startDate = moment(alert.startDate).toDate();
				alert.endDate = moment(alert.endDate).toDate();
				this.alert = alert;
				this.project = new ProjectIdentifier(alert.contentProviderId, alert.accountId, alert.projectId);
				this.ref.markForCheck();
			}, () => this.redirectToAlerts());
		} else {
			const searchParams = this.cxLocation.search() as AlertWizardParams;
			this.project = {
				contentProviderId: parseInt(searchParams.contentProviderId, 10),
				accountId: parseInt(searchParams.accountId, 10),
				projectId: parseInt(searchParams.projectId, 10),
			};
			if (!ProjectIdentifier.isProjectSelected(this.project)) {
				this.redirectToAlerts();
				return;
			}
			this.alert = {
				name: this.locale.getString('alert.alert'),
				ownerId: this.security.getUser().userId,
				ownerName: this.security.getUser().userEmail,
				enabled: true,
				contentProviderId: this.project.contentProviderId,
				accountId: this.project.accountId,
				projectId: this.project.projectId,
				startDate: moment().startOf('day').add(1, 'day').add(5, 'minutes').toDate(),
				endDate: this.scheduleUtilsService.getScheduleExpirationDate(new Date()),
				timezoneOffset: String(moment().utcOffset()),
				cronExpression: '0 5 0 ? * 1',
				caseSettings: {
					createCase: false,
					multiCases: false,
					priority: CasePriority.LOW,
					title: this.locale.getString('alert.defaultEmailBody'),
					description: this.locale.getString('alert.defaultCaseCreateBody')
				}
			};
			let triggerDefaults: AlertTrigger = {
				minVolume: 100,
				filterRules: [],
				period: DateFilterMode.LAST_7_DAYS,
				conditions: [{
					criteria: AlertTriggerCriteria.LESS_THAN_OR_EQUAL,
					threshold: 1,
					thresholdType: AlertThresholdTypes.DELTA,
					comparisonPeriod: HistoricDateFilterMode.PREVIOUS_PERIOD,
				}],
			};

			this.alert.trigger = _.extend(triggerDefaults, this.alert.trigger);
		}
	}

	redirectToAlerts(): void {
		this.cxLocation.path('/alerts').search({tab: 'metric'});
	}

	testAlert(): void {
		if (!this.wizard.isFinishAllowed()) {
			return;
		}
		this.alertingApiService.testAlert(this.alert).then(() => {
			this.toastService.addToast(this.locale.getString('alert.testAlertSent'), AlertLevel.SUCCESS);
		});
	}

	saveAlert(): void {
		if (!this.alert.id) {
			this.loading = PromiseUtils.wrap(this.alertingApiService.createAlert(this.alert)).then(() => this.redirectToAlerts());
		} else {
			this.loading = PromiseUtils.wrap(this.alertingApiService.updateAlert(this.alert)).then(() => this.redirectToAlerts());
		}
	}

}

app.directive('alertWizardPage', downgradeComponent({component: AlertWizardPageComponent}) as angular.IDirectiveFactory);
