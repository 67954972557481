<menu #ngDropdown ngbDropdown
	[autoClose]="'outside'"
	[display]="'static'"
	(openChange)="onMenuToggle($event)"
	class="dropdown br-actions-dropdown d-flex pl-0 mv-0">
	<button #ngDropdownToggle
		ngbDropdownToggle
		(keydown.enter)="onMenuToggleKeydown($event)"
		[attr.aria-expanded]="isOpen()"
		[attr.aria-label]="getAriaLabel()"
		aria-haspopup="true"
		class="q-icon-dots icon-rotate-90 btn btn-icon border-0 kb-focusable text-gray-1000 hover:text-action-800 focus:text-action-900"
		title="{{'common.options'|i18n}}"></button>
	<ul ngbDropdownMenu class="dropdown-menu pull-right" role="menu">
		<li *ngFor="let menuItem of menuItems"
			(click)="onItemClick(menuItem, $event)"
			(keydown)="onMenuKeydown(menuItem, $event)"
			[ngClass]="{'dropdown-submenu': menuItem.items, 'on-click': menuItem.items, 'open-menu': menuItem.open, 'divider': isDivider(menuItem) }"
			[attr.role]="isDivider(menuItem) ? 'separator' : 'menuitem'">
			<a href="javascript:void(0)" class="option"
				[attr.aria-haspopup]="hasSubitems(menuItem)"
				*ngIf="isText(menuItem) || isSubmenu(menuItem)"
				[ngClass]="[menuItem.name]"
				[attr.data-testid]="menuItem.testId || null"
				[class.has-submenu]="hasSubitems(menuItem)">
				<span class="q-icon q-icon-angle-left menu-icon" *ngIf="hasSubitems(menuItem)" aria-hidden="true"></span>{{menuItem.text}}
			</a>
			<ul class="dropdown-menu dropdown-left" *ngIf="isSubmenu(menuItem)">
				<div class="menu-pointer"></div>
				<li *ngFor="let subMenuItem of menuItem.items"
					(click)="onItemClick(subMenuItem, $event)"
					(keydown.enter)="onMenuKeydown(subMenuItem, $event)">
					<a href="javascript:void(0)" class="option submenu-option" [attr.data-testid]="subMenuItem.testId || null" [ngClass]="[subMenuItem.name]">{{subMenuItem.text}}</a>
				</li>
			</ul>
			<ng-container *ngIf="isTemplate(menuItem)">
				<ng-container *ngTemplateOutlet="menuItem.template"></ng-container>
			</ng-container>
		</li>
	</ul>
</menu>
