import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { LayoutChangeAction, WidgetsLayoutState } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/layout-change-action';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class LayoutSaveAction implements DashboardChangeAction {
	private before: WidgetsLayoutState;
	private after: WidgetsLayoutState;

	constructor(
		before: WidgetsLayoutState,
		after: WidgetsLayoutState,
	) {
		this.before = ObjectUtils.copy(before);
		this.after = ObjectUtils.copy(after);
	}

	reverse(): DashboardChangeAction {
		// LayoutSaveAction is different from LayoutChangeAction by not applying any changes (they are already applied by gridster)
		let revert = new LayoutChangeAction(this.after, this.before);
		return revert;
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let widgetLayoutData: WidgetsLayoutState = {};
		DashboardUtils.sortWidgets(widgets);
		_.each(this.after, (widgetBox, originalId) => {
			let widgetId = idMapper.getCurrentId(Number(originalId));
			widgetLayoutData[widgetId] = widgetBox;
		});
		return api.updateWidgetsLayout(widgets[0].dashboardId, widgetLayoutData);
	}
}
