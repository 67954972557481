import { OnInit } from '@angular/core';
import { Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, Component } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils } from '@app/util/change-utils';

@Component({
	selector: 'user-create-finish-step',
	templateUrl: './user-create-finish-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateFinishStepComponent implements OnInit, OnChanges {
	@Input() loading: any;
	@Input() loadingMessage: string;
	@Input() done: boolean;
	@Input() errors: any;

	constructor(
		private wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.wizardStep.markAsConfirmationStep();
		this.wizardStep.setLoaded(false);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.done)) {
			this.wizardStep.setLoaded(changes.done.currentValue);
		}
	}
}