import { Injectable } from '@angular/core';
import { LimitedFeature } from '@app/modules/feature-limiter/limited-feature';
import { FeatureLimiterResult } from '@app/modules/feature-limiter/feature-limiter-result';
import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class FeatureLimiterService {
	constructor(private readonly cxHttpService: CxHttpService) {}

	useFeatureOnce = (feature: LimitedFeature): Promise<FeatureLimiterResult> => {
		return this.cxHttpService.post(`rest/feature_limit/use_feature/${feature}`, {});
	}
}

app.service('featureLimiterService', downgradeInjectable(FeatureLimiterService));
