import { ChangeDetectionStrategy, Component, forwardRef, OnInit, ChangeDetectorRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { ListOption } from '@app/shared/components/forms/list-option';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';

@Component({
	selector: 'calculation-type-selector',
	template: `
<label for="aggregation-selector">{{'widget.calculation'|i18n}}</label>
<simple-dropdown
	id="aggregation-selector"
	class="w-100-percent"
	[options]="calculationTypes"
	[value]="innerValue"
	(valueChange)="onChange($event)"
	[disableSort]="true"
></simple-dropdown>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CalculationTypeSelectorComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationTypeSelectorComponent extends BaseControlValueAccessor<CalculationFunction> implements OnInit {

	calculationTypes: ListOption<CalculationFunction>[];

	@HostBinding('class.d-block') displayBlock = true; // make component use display: block by default, without having to add <style>

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService,
	) {
		super(ref);
	}

	ngOnInit(): void {
		this.calculationTypes = [
			{ name: this.locale.getString('widget.calculationAverage'), value: CalculationFunction.AVG },
			{ name: this.locale.getString('widget.calculationCount'), value: CalculationFunction.COUNT },
			{ name: this.locale.getString('widget.calculationMax'), value: CalculationFunction.MAX },
			{ name: this.locale.getString('widget.calculationMin'), value: CalculationFunction.MIN },
			{ name: this.locale.getString('widget.calculationSum'), value: CalculationFunction.SUM },
			{ name: this.locale.getString('widget.calculationStandardDeviation'), value: CalculationFunction.STANDARD_DEVIATION },
			{ name: this.locale.getString('widget.calculationVariance'), value: CalculationFunction.VARIANCE },
			{ name: this.locale.getString('widget.calculationSumOfSquares'), value: CalculationFunction.SUM_OF_SQUARES }
		];
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT)) {
			this.calculationTypes.push({
				name: this.locale.getString('widget.calculationCountDistinct'),
				value: CalculationFunction.COUNT_DISTINCT }
			);
		}
	}
}

app.directive('calculationTypeSelector', downgradeComponent({component: CalculationTypeSelectorComponent}));
