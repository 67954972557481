
export class CloudD3Definition {
	credits = {
		enabled: false
	};

	chart = {
		spacingTop: 30,
		animation: false,
		renderTo: undefined
	};

	title = {
		text: null
	};

	toolTip = {
		enabled: false
	};

	legend = {
		enabled: false
	};

	exporting = {
		enabled: false
	};

	constructor(
		private readonly element: JQuery
	) {
		this.chart.renderTo = element[0];
		return this;
	}
}
