import { DashboardRunService } from '@app/modules/dashboard/dashboard-run.service';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';

/**
 * Modify rest url, add custom headers
 */
app.factory('httpInterceptor', ($injector, urlService: UrlService, security: Security, dashboardRunService: DashboardRunService) => {

	function fillToken(config): void {
		let tokenData = {};

		if (security.getToken()) {
			$.extend(tokenData, {
				'brooklyn-token': security.getToken()
			});
		}

		if (security.isOAuthAuthentication()) {
			$.extend(tokenData, {
				'access-token': security.getAccessToken()
			});
		}

		if (security.hasPassCode()) {
			$.extend(tokenData, {
				'pass-code': security.getPassCode()
			});
		}

		config.headers = $.extend(config.headers, tokenData);
	}

	function fillLocale(config): void {
		let locale = $injector.get('locale');
		let lang = {
			'Accept-Language': locale.getLocale()
		};
		config.headers = $.extend(config.headers, lang);
	}

	function fillMasterAccount(config): void {
		let maHeader = {
			'ma-id': security.getMasterAccountId()
		};
		config.headers = $.extend(config.headers, maHeader);
	}

	function fillEmbeddingToken(config): void {
		let embeddingToken = security.getEngageDashboardAccessToken();
		if (embeddingToken) {
			let engageDashboardAccessTokenHeader = {
				'studio-token-embedding': security.getEngageDashboardAccessToken()
			};
			config.headers = $.extend(config.headers, engageDashboardAccessTokenHeader);
		}
	}

	function fillOffset(config): void {
		let offset = {
			Offset: new Date().getTimezoneOffset()
		};
		config.headers = $.extend(config.headers, offset);
	}

	function processDashboardRunLogging(config): void {
		if (dashboardRunService.isDashboardRunLogRequest() && !dashboardRunService.hasDashboardRunRequestIdHeader(config.headers)) {
			config.headers.dashboardRunRequestId = dashboardRunService.getDashboardRunRequestId();
		}
	}

	return {
		request: (config) => {
			if (urlService.isCommonAPICall(config.url)) {
				config.url = urlService.getCommonAPIUrl(config.url);
			} else if (urlService.isAPICall(config.url)) {
				config.url = urlService.getAPIUrl(config.url);
			}

			if (urlService.isBackendCall(config.url)) {
				fillToken(config);
				fillLocale(config);
				fillMasterAccount(config);
				fillEmbeddingToken(config);
				fillOffset(config);

				processDashboardRunLogging(config);
			}

			return config;
		}
	};
});
