import { Inject, Injectable } from '@angular/core';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { AssetTemplateLazyResources } from '@app/modules/unified-templates/common-templates/asset-template-resources.service';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-key';
import { TemplateReplacementData } from '@app/modules/unified-templates/common-templates/dto/template-replacement-data';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAsset, ReportAssetTreeItem } from '@cxstudio/reports/entities/report-asset';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { TemplatePlaceholderReplacement } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-replacement';
import { PlaceholderUsageLocation } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';

@Injectable({
	providedIn: 'root'
})
export class TemplateReplacementUtilsService {

	constructor(
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
		@Inject('colorUtils') private colorUtils: ColorUtils,
	) {}

	convertNodeToReplacementData(node: ReportAsset): TemplateReplacementData {
		switch (node.metricType) {
			case AnalyticMetricType.ATTRIBUTE: return {
				name: node.name,
				displayName: node.displayName,
			};
			case AnalyticMetricType.CUSTOM: return {
				id: node.id,
				displayName: node.displayName,
			};
			case AnalyticMetricType.SCORECARD: return {
				name: node.name,
				nodeId: (node as any as ScorecardMetric).nodeId,
				passing: (node as any as ScorecardMetric).passing,
				scorecardId: (node as any as ScorecardMetric).scorecardId,
				displayName: node.displayName,
			};
			case AnalyticMetricType.HIERARCHY_ENRICHMENT_PROPERTY: return {
				hierarchyId: (node as IHierarchyNode).hierarchyId,
				name: node.displayName
			};
			default: throw new Error(`Invalid selection: ${node.metricType}`);
		}
	}

	findSimilarSelection(options: ReportAssetTreeItem[], displayName: string, assetIdentifier?: string): ReportAsset {
		let potentialMatch = SearchableHierarchyUtils.deepSearchByPredicate(options,
			(node) => node.displayName === displayName && !node.children);
		if (!potentialMatch && assetIdentifier) {
			potentialMatch = SearchableHierarchyUtils.deepSearchByPredicate(options,
				(node) => node.name === assetIdentifier && !node.children);
		}
		return potentialMatch;
	}

	getReplacementOptions(placeholder: TemplatePlaceholderReplacement, resources: AssetTemplateLazyResources) {
		const type = placeholder.placeholderKey.type;
		switch (type) {
			case TemplatePlaceholderType.NUMERIC_ATTRIBUTE: {
				return resources.attributes().then(attributes => this.optionsBuilderProvider.getBuilder()
					.withAttributes(attributes, MetricFilters.CALCULATION)
					.build());
			}
			case TemplatePlaceholderType.TEXT_ATTRIBUTE: {
				// text attribute also may include any numeric attribute
				return resources.attributes().then(attributes => this.optionsBuilderProvider.getBuilder()
					.withAttributes(attributes, MetricFilters.NOT_DATE)
					.build());
			}
			case TemplatePlaceholderType.DATE_ATTRIBUTE: {
				return resources.attributes().then(attributes => this.optionsBuilderProvider.getBuilder()
					.withAttributes(attributes, MetricFilters.DATE)
					.build());
			}
			case TemplatePlaceholderType.MODEL: {
				return resources.models().then(models => this.optionsBuilderProvider.getBuilder()
					.withTemplate(this.optionsTemplatesService.topics())
					.withModels(models)
					.build());
			}
			case TemplatePlaceholderType.WORDS: {
				return PromiseUtils.all([resources.attributes(), resources.words()]).then(([attributes, words]) => {
					return this.optionsBuilderProvider.getBuilder()
						.withTemplate(this.optionsTemplatesService.filterItemsDefault())
						.withAttributes(attributes, MetricFilters.NOT_DATE)
						.withWordAttributes(words)
						.build();
				});
			}
			case TemplatePlaceholderType.CUSTOM_METRIC: {
				return resources.metrics().then(metrics => this.optionsBuilderProvider.getBuilder()
					.withMetrics(metrics.filter(metric =>
						FolderUtils.isFolder(metric) || metric.definition?.type !== MetricType.CUSTOM_MATH))
					.build());
			}
			case TemplatePlaceholderType.GROUP_METRIC: {
				let groupingMetricTypes = [MetricType.TOP_BOX, MetricType.BOTTOM_BOX, MetricType.SATISFACTION];
				return resources.metrics().then(metrics => this.optionsBuilderProvider.getBuilder()
					.withMetrics(metrics.filter(metric =>
						FolderUtils.isFolder(metric) || groupingMetricTypes.includes(metric.definition?.type)))
					.build());
			}
			case TemplatePlaceholderType.CALCULATION_METRIC: {
				return resources.metrics().then(metrics => {
					if (placeholder.locations?.includes(PlaceholderUsageLocation.COLOR_CALCULATION)) {
						metrics = metrics.filter((metric) =>
							FolderUtils.isFolder(metric) || this.colorUtils.isColorableMetric(metric));
					}
					return this.optionsBuilderProvider.getBuilder()
						.withMetrics(metrics)
						.build();
				});
			}
			case TemplatePlaceholderType.SCORECARD_METRIC: {
				return resources.scorecardMetrics().then(scorecardMetrics => this.optionsBuilderProvider.getBuilder()
					.withScorecardMetrics(scorecardMetrics)
					.build());
			}
			case TemplatePlaceholderType.HIERARCHY: {
				return PromiseUtils.all([resources.models(), resources.hierarchyModels()])
					.then(results => this.optionsBuilderProvider.getBuilder()
						.withTemplate(this.optionsTemplatesService.hierarchyModels())
						.withOrgHierarchyModels(results[0], results[1])
						.build());
			}
			case TemplatePlaceholderType.HIERARCHY_ENRICHMENT: {
				return resources.hierarchies().then(hierarchies => this.optionsBuilderProvider.getBuilder()
					.withOrgHierarchyEnrichments(hierarchies)
					.build());
			}
			case TemplatePlaceholderType.DRIVERS: {
				return resources.drivers().then(drivers => this.optionsBuilderProvider.getBuilder()
					.withTemplate(this.optionsTemplatesService.drivers())
					.withDrivers(drivers)
					.build());
			}
		}
	}
}
