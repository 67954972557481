import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';

export abstract class ContextMenuBaseController implements ng.IController {

	options: IDrillMenuOption[];
	object: any;
	posX: number;
	posY: number;
	maxWidth: number;
	className: string;
	onMenuClose: () => void;
	onMenuInit: () => void;
	onKeyAction: () => void;

	containerWidth: number;
	containerStyle;
	positionStyle;
	activeItem: IDrillMenuOption;
	selectedOption;

	constructor(
		protected contextMenuTree: ContextMenuTree,
		protected $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService
	) { }

	$onInit(): void {
		this.activeItem = undefined;
		this.containerWidth = this.contextMenuTree.getContainerWidth();
		this.containerStyle = this.contextMenuTree.getContainerStyle();

		this.$timeout(() => {
			this.initPosition();
			if (this.onMenuInit) {
				this.onMenuInit();
			}
			if (!this.selectedOption) {
				setTimeout( () => this.setFocusOnOption(0), 0);
			}
		});

		$(window).on('keydown', this.checkKeyboardInput);
	}

	$postLink(): void {
		this.$timeout(() => {
			this.$element.find('#dsh-context-menu').trigger('focus');
		}, 1);
	}

	$onDestroy(): void {
		$(window).off('keydown', this.checkKeyboardInput);
	}

	private checkKeyboardInput = (event: JQuery.Event): void => {
		if (KeyboardUtils.isEventKey(event as unknown as KeyboardEvent, Key.TAB) && !this.selectedOption) {
			this.setFocusOnOption(0);
			return;
		}

		if (KeyboardUtils.isEventKey(event as unknown as KeyboardEvent, Key.ESCAPE))
			this.closeOnEsc(event as unknown as KeyboardEvent);

	}

	private getFocusableMenuOptions(): JQuery {
		return this.$element.find(':focusable');
	}

	private handleKeydown = (evt): void => {
		if (!(KeyboardUtils.isEventKey(evt, Key.DOWN) || KeyboardUtils.isEventKey(evt, Key.UP))) {
			return;
		}

		evt.stopPropagation();
		evt.preventDefault();
		let menuOptions = this.getFocusableMenuOptions();
		if (!_.isNumber(this.selectedOption)) {
			this.selectedOption = 0;
		}

		if (KeyboardUtils.isEventKey(evt, Key.DOWN)) {
			if (!_.isNumber(this.selectedOption)) {
				this.selectedOption = 0;
			} else {
				this.selectedOption = (this.selectedOption === menuOptions.length - 1) ?
					menuOptions.length - 1 :
					this.selectedOption + 1;
			}
		} else if (KeyboardUtils.isEventKey(evt, Key.UP)) {
			if (!_.isNumber(this.selectedOption)) {
				this.selectedOption = menuOptions.length - 1;
			} else {
				this.selectedOption = (this.selectedOption === 0) ?
					0 :
					this.selectedOption - 1;
			}
		}

		this.setFocusOnOption(this.selectedOption);
	}

	private setFocusOnOption(index: number): void {
		let menuOptions = this.getFocusableMenuOptions();
		this.selectedOption = index;
		(menuOptions.get(index) as HTMLElement)?.focus();
	}
	private initPosition(): void {
		this.positionStyle = this.contextMenuTree.getPositionStyle(
			this.getPositionElement(), this.posX, this.posY);
	}

	protected getPositionElement(): ng.IAugmentedJQuery {
		return this.$element.find('#dsh-context-menu');
	}

	toggleItem = (option: IDrillMenuOption, toggle: boolean = true) => {
		// if it's already open, close it and update the focus index
		if (this.activeItem === option && toggle) {
			this.activeItem = null;

			// tslint:disable-next-line
			let getText = function (): string { return this.innerText; };

			let optionNames = this.getFocusableMenuOptions().map(getText) as unknown as string[];
			this.selectedOption = _.indexOf(optionNames, option.text) || 0;
			this.setFocusOnOption(this.selectedOption);
		} else {
			this.activeItem = option;
			this.selectedOption++; // opening submenu will make first item of submenu the focus
		}
	}

	containerClass = () => {
		let classes = [];
		if (this.className) {
			classes.push(this.className);
		}
		if (this.posX > Math.max(this.containerWidth / 2, this.maxWidth ? this.containerWidth - this.maxWidth : 0)) {
			classes.push('left');
		}
		return classes.join(' ');
	}

	handleBackgroundClick = ($event: MouseEvent) => {
		if (($event.target as HTMLElement).classList.contains('dropdown')) {
			this.closeMenu();
		}
	}

	closeOnEsc = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) || KeyboardUtils.isEventKey(event, Key.TAB)) {
			if (this.onKeyAction) {
				this.onKeyAction();
			}
			this.closeMenu();
			event.stopPropagation();
		} else {
			this.handleKeydown(event);
		}
	}

	closeMenu = () => {
		if (this.onMenuClose) {
			this.onMenuClose();
		}
	}
}
