import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { EventHandlerUtils } from '@app/modules/widget-visualizations/highcharts/event-handler-utils';
import { HighchartsVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization.interface';
import { PointSelectionCallback } from '@app/modules/widget-visualizations/highcharts/point-selection-callback.interface';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { GenericVisualizationBaseComponent } from '../generic-visualization-base.class';


@Directive()
export abstract class HighchartsVisualizationBaseComponent extends GenericVisualizationBaseComponent
		implements HighchartsVisualization, PointSelectionCallback, OnDestroy {
	selectedPoint: DrillPoint;
	@Input() dataObject: ReportDataObject;

	protected initChartResizer(element: ElementRef, chartProvider: () => Highcharts.Chart): void {
		let chartResizeCallback = (newSize, oldSize) => this.onChartResize(chartProvider(), newSize, oldSize);
		this.initResizer(element, chartResizeCallback);
	}

	onSelect(point: DrillPoint): void {
		this.selectedPoint = point;
	}

	onDeselect(): void {
		delete this.selectedPoint;
	}

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		let point = EventHandlerUtils.getProcessedPoint(this);
		this.handleClick.emit({event, point});
	}

	@HostListener('contextmenu', ['$event'])
	onContextMenu(event: MouseEvent): void {
		event.preventDefault();
		let point = EventHandlerUtils.getProcessedPoint(this);
		if (!point) {
			this.handleRightClick.emit({event});
		} else {
			this.handleClick.emit({event, point, isRightClick: true});
		}
	}

	protected abstract clearChart(): void;

	ngOnDestroy(): void {
		this.clearChart();
		super.ngOnDestroy();
	}
}
