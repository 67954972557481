import { Directive, ElementRef, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
	selector: '[min]',
	providers: [{provide: NG_VALIDATORS, useExisting: MinDirective, multi: true}]
})
export class MinDirective implements Validator {
	@Input() min: number;

	private onChange: () => void;

	constructor(private el: ElementRef) { 
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return Validators.min(this.min)(control);
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onChange = fn;
	}
}