import * as _ from 'underscore';
import Listener from './listener';

export interface ScopedError {
	code: string;
	subscopes: string[];
}

/**
 * Class which declares validation scope.
 * The single instance of validation scope can be propagated through complicated structure of components 
 * to combine their validation in one object.
 * Each error can contain multiple subscopes to be navigated through the list of errors. 
 * 
 * Usage:
 * 1. create new Errors()
 * 2. on validation, add or remove errors using "errors.setValid([ 'some-scope', 'some-subscope' ], 'some-validation', false)"
 * 3. on any element, use the following syntax: "ng-class='{error:$ctrl.errors.has('some-scope')}'". 
 * Some other methods are provided for more specific checks.
 * 
 * Optionally can deactivate this Errors object, using "setActive(false);".
 * In this case Errors object will not return any errors, until activated again.
 */
export class Errors {

	private active: boolean = true;
	private errors: ScopedError[] = [];
	private stateListener: Listener = new Listener();

	/**
	 * Activates or deactivates this Errors object. If not active, does not return any errors.
	 */
	setActive = (active: boolean): void => {
		this.active = active;
		this.stateListener.invokeListeners();
	}

	isActive = (): boolean => {
		return this.active;
	}

	/**
	 * Sets validity flag for specified errorCode in specified subscopes.
	 * Provided subscopes become invalid.
	 */
	setValid = (subscopes: string[], errorCode: string, valid: boolean): void => {
		let scopedError = { code: errorCode, subscopes };
		let existingErrorIndex = this.findErrorIndex(scopedError);

		if (valid) {
			this.errors.removeAt(existingErrorIndex);
		} else if (existingErrorIndex === -1) {
			this.errors.push({ code: errorCode, subscopes });
		}
	}

	/**
	 * @returns true, if this Errors object contains provided error or subcope.
	 */
	has = (errorOrScope: string): boolean => {
		return this.active && this.hasErrorCode(errorOrScope) || this.hasScope(errorOrScope);
	}

	/**
	 * @returns true, if this Errors object contains any failed validations.
	 */
	hasAny = (): boolean => {
		return this.active && this.errors.length > 0;
	}

	/**
	 * @returns true, if this Errors object contains error in specific scope
	 */
	hasScoped = (scope: string, errorCode: string) => {
		if (!this.active)
			return false;

		for (let error of this.errors) {
			if (error.code === errorCode) {
				for (let errorSubscope of error.subscopes) {
					if (scope === errorSubscope) {
						return true;
					}
				}
			}
		}

		return false;
	}

	/**
	 * @returns true, if this Errors object contains any error in provided scope
	 */
	hasScope = (subscope: string): boolean => {
		if (!this.active)
			return false;

		for (let error of this.errors) {
			for (let errorSubscope of error.subscopes) {
				if (subscope === errorSubscope) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Removes all validation errors associated with the provided scope.
	 */
	removeScope = (scope: string): void => {
		this.errors = this.errors.filter(error => !error.subscopes.contains(scope));
	}

	private findErrorIndex = (error: ScopedError): number => {
		let errorIndex = -1;
		this.errors.forEach((item, index) => {
			if (item.code === error.code && _.isEqual(item.subscopes, error.subscopes)) {
				errorIndex = index;
			}
		});

		return errorIndex;
	}

	private hasErrorCode = (errorCode: string): boolean => {
		return _.findIndex(this.errors, { code: errorCode }) >= 0;
	}

	addStateListener = (listener: () => any): void => {
		this.stateListener.addListener(listener);
	}
}