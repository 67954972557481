import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DashboardChange } from '@app/modules/dashboard-actions/undo/dashboard-change';
import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { Observable, Subject } from 'rxjs';
import { MergeableWidgetAction } from './dashboard-change-actions/megeable-widget-action';

@Injectable({
	providedIn: 'root'
})
export class DashboardHistoryStateService {

	private readonly HISTORY_LIMIT = 10;

	private currentLength: number; // [0 ... size], represents current "cursor" over states
	private states: DashboardChange[];
	private changeSubject = new Subject<void>();
	private change$ = this.changeSubject.asObservable();

	constructor(
		private readonly locale: CxLocaleService,
	) {
		this.reset();
	}

	reset(): void {
		this.currentLength = 0;
		this.states = [];
	}

	getChangeObserver(): Observable<void> {
		return this.change$;
	}

	private addState(type: DashboardChangeType, description: string, changes: DashboardChangeAction[]): void {
		this.cutRedoStates();

		if (this.canMergeChanges(type, changes)) {
			this.merge(changes[0] as unknown as MergeableWidgetAction);
		} else {
			this.add(type, description, changes);
		}

		if (this.states.length > this.HISTORY_LIMIT) {
			this.states.shift();
		}

		this.currentLength = this.states.length;
		this.changeSubject.next();
	}

	private add(type: DashboardChangeType, description: string, changes: DashboardChangeAction[]): void {
		this.states.push({type, changes, description, timestamp: new Date().getTime()});
	}

	private canMergeChanges(type: DashboardChangeType, currentChanges: DashboardChangeAction[]): boolean {
		if (this.currentLength === 0) {
			return false;
		}

		let previousChanges: DashboardChangeAction[] = this.getPreviousChangeActions();

		return this.haveSingleAction(currentChanges, previousChanges)
			&& type === this.getPreviousState().type
			&& this.canMerge(currentChanges[0] as unknown as MergeableWidgetAction, previousChanges[0] as unknown as MergeableWidgetAction);
	}

	private getPreviousState(): DashboardChange {
		return this.states[this.states.length - 1];
	}

	private getPreviousChangeActions(): DashboardChangeAction[] {
		return this.getPreviousState().changes;
	}

	private haveSingleAction(currentChanges: DashboardChangeAction[], previousChanges: DashboardChangeAction[]): boolean {
		return currentChanges?.length === 1 && previousChanges?.length === 1;
	}

	private canMerge(currentAction: MergeableWidgetAction, previousAction: MergeableWidgetAction): boolean {
		return previousAction.canMerge && previousAction.canMerge(currentAction);
	}

	private merge(mergeFromAction: MergeableWidgetAction): void {
		let mergeToState: DashboardChange = this.getPreviousState();

		let mergeToAction: MergeableWidgetAction = mergeToState.changes[0] as any;
		mergeToAction.merge(mergeFromAction);

		mergeToState.timestamp = new Date().getTime();
	}

	addDashboardChange(type: DashboardChangeType, changes: DashboardChangeAction[]): void {
		this.addState(type, this.locale.getString(`dashboard.dashboard_${type.toLowerCase()}`), changes);
	}

	addSingleChange(type: DashboardChangeType, changes: DashboardChangeAction[]): void {
		this.addState(type, this.locale.getString(`dashboard.widget_${type.toLowerCase()}`), changes);
	}

	addBulkChange(type: DashboardChangeType, count: number, changes: DashboardChangeAction[]): void {
		this.addState(type, this.locale.getString(`dashboard.widget_${type.toLowerCase()}`, {count}), changes);
	}

	getActiveStates(): DashboardChange[] {
		return this.states.slice(0, this.currentLength);
	}

	private cutRedoStates(): void {
		if (this.canRedo()) {
			this.states = this.states.slice(0, this.currentLength);
		}
	}

	canUndo(): boolean {
		return this.currentLength > 0;
	}

	undo(): DashboardChangeAction[] {
		if (this.canUndo()) {
			return this.undoTo(this.currentLength - 1);
		}
		throw new Error('Invalid undo state');
	}

	undoTo(index: number): DashboardChangeAction[] {
		if (this.canUndo() && index < this.currentLength) {
			let rangeEnd = this.currentLength;
			this.currentLength = index;
			return _.flatten(_.range(index, rangeEnd)
				.map(i => this.states[i].changes.map(change => change.reverse()))).reverse();
		}
		throw new Error('Invalid undo state');
	}

	canRedo(): boolean {
		return this.currentLength < this.states.length;
	}

	getRedoState(): DashboardChange {
		return this.states[this.currentLength];
	}

	redo(): DashboardChangeAction[] {
		if (this.canRedo()) {
			let redoPosition = this.currentLength;
			this.currentLength++;
			return this.states[redoPosition].changes.map(change => change.reverse().reverse());
		}
		throw new Error('Invalid redo state');
	}

}

app.service('dashboardHistoryState', downgradeInjectable(DashboardHistoryStateService));
