import TimeReferenceType from './time-reference-line-type';
import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';

const DEFAULT_TYPE = PlotLineType.Dot;
const DEFAULT_WIDTH = 1;
const DEFAULT_COLOR = '#868898';
const DEFAULT_TIME_REFERENCE_TYPE = TimeReferenceType.EVENT;

export default class TimeReferenceLine extends PlotLineStyle {
	timeReferenceType: TimeReferenceType;
	labelLine: boolean;
	label: string;
	displayLabel: string;
	date?: string;
	from?: string;
	to?: string;

	constructor() {
		super(DEFAULT_TYPE, DEFAULT_WIDTH, DEFAULT_COLOR);
		this.timeReferenceType = DEFAULT_TIME_REFERENCE_TYPE;
		this.label = '';
		this.displayLabel = '';
		this.labelLine = false;
	}

}
