import { NgModule } from '@angular/core';
import { TranslatedTextComponent } from '@app/modules/translation/translated-text/translated-text.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateLabelComponent } from './translate-label/translate-label.component';

@NgModule({
	imports: [
		SharedModule
	],
	exports: [
		TranslatedTextComponent,
		TranslateLabelComponent,
	],
	declarations: [
		TranslatedTextComponent,
		TranslateLabelComponent,
	],
})
export class TranslationModule { }
