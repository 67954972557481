export enum Key {
	ENTER = 'Enter',
	ESCAPE = 'Escape',
	TAB = 'Tab',
	SPACE = ' ',
	BACKSPACE = 'Backspace',
	DELETE = 'Delete',
	UP = 'ArrowUp',
	DOWN = 'ArrowDown',
	RIGHT = 'ArrowRight',
	LEFT = 'ArrowLeft',
	F11 = 'F11',
	PAGEDOWN = 'PageDown',
	PAGEUP = 'PageUp',
	LEFT_BRACKET = '[',
	HOME = 'Home',
	END = 'End',
	F10 = 'F10',
	Z = 'z',
	Y = 'y'
}

export enum KeyModifier {
	SHIFT = 'Shift',
	CTRL = 'Control',
}

export enum KeyShortcut {
	CTRL = 'CTRL',
	CMD = 'CMD',
}

type EventHandler<T> = (event: T) => void;
type KeyEvent = KeyboardEvent | JQuery.KeyboardEventBase;

export class KeyboardUtils {
	static isMacEnvironment() {
		return navigator.platform.includes('Mac');
	}

	static getKbShortcutLabel(key: string): string {
		const modifier = KeyboardUtils.isMacEnvironment() ? KeyShortcut.CMD : KeyShortcut.CTRL;

		return `${modifier} + ${key}`;
	}

	static isWinCtrlOrMacCmd(event: KeyboardEvent): boolean {
		// Check if the event represents the correct key combo based on the platform
		return (!KeyboardUtils.isMacEnvironment() && event.ctrlKey) || KeyboardUtils.isCommandKeyPressed(event);
	}

	static isCommandKeyPressed(event: KeyEvent): boolean {
		return KeyboardUtils.isMacEnvironment() && event.metaKey;
	}

	static isEventKey(event: KeyEvent, key: Key, keyModifier?: KeyModifier): boolean {
		let keyMatches: boolean = event.key === key;

		if (!keyModifier) {
			return keyMatches && !event.shiftKey && !event.ctrlKey;
		} else {
			return this.isEventKeyWithModifier(event, keyMatches, keyModifier);
		}
	}

	static isEventKeyOneOf(event, keys: Key[]) {
		for (let key of keys) {
			if (this.isEventKey(event, key)) return true;
		}
		return false;
	}

	private static isEventKeyWithModifier(event: KeyEvent, keyMatches: boolean, keyModifier: KeyModifier): boolean {
		if (keyModifier === KeyModifier.SHIFT) {
			return keyMatches && event.shiftKey;
		} else if (keyModifier === KeyModifier.CTRL) {
			return keyMatches && event.ctrlKey;
		}
	}

	static isKeyDown(event: any): boolean {
		return event.type === 'keydown';
	}

	static isKeyUp(event: any): boolean {
		return event.type === 'keyup';
	}

	static keyHandler(event: KeyEvent, key: Key, handler: () => void): void {
		if (event.key === key) handler();
	}

	static handleUpDownNavigation(event: KeyEvent, containerSelector: any): void {
		let direction;
		if (this.isEventKey(event, Key.UP)) {
			direction = -1;
		} else if (this.isEventKey(event, Key.DOWN)) {
			direction = 1;
		} else return;
		event.preventDefault();
		event.stopPropagation();
		let items = $(containerSelector).find(':focusable');
		let currentIndex = items.index(document.activeElement);
		let nextIndex = currentIndex + direction;
		if (currentIndex > -1 && nextIndex > -1 && nextIndex < items.length) {
			(items.get(nextIndex) as HTMLElement).focus();
		}
	}

	static intercept(event: KeyboardEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	static focus(target: JQuery): void {
		setTimeout(() => target.trigger('focus'));
	}

	static isUndoEvent(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.Z, KeyModifier.CTRL) || this.isMacUndo(event);
	}

	// does not work for win+z
	private static isMacUndo(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.Z) && event.metaKey;
	}

	static isRedoEvent(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.Y, KeyModifier.CTRL) || this.isMacRedo(event);
	}

	// does not work for win+y
	private static isMacRedo(event: KeyboardEvent): boolean {
		return KeyboardUtils.isEventKey(event, Key.Y) && event.metaKey;
	}

	static isFocusForward(event: KeyboardEvent) {
		return KeyboardUtils.isEventKey(event, Key.TAB);
	}

	static isFocusBackward(event: KeyboardEvent) {
		return KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT);
	}

	static cycleNavigation(event: KeyboardEvent, focusableElements , activeElement) {
		let firstFocusableWidgetElement = focusableElements.first()[0];
		let lastFocusableWidgetElement = focusableElements.last()[0];
		if (activeElement === lastFocusableWidgetElement && !event.shiftKey) {
			KeyboardUtils.intercept(event);
			firstFocusableWidgetElement.focus();
		} else if (activeElement === firstFocusableWidgetElement && event.shiftKey) {
			KeyboardUtils.intercept(event);
			lastFocusableWidgetElement.focus();
		}
	}

	static isNavigationKey(event: KeyboardEvent): boolean {
		const navigationKeys = [
			Key.TAB, Key.UP, Key.DOWN, Key.RIGHT, Key.LEFT,
			Key.PAGEDOWN, Key.PAGEUP, Key.HOME, Key.END
		];

		return navigationKeys.some(key => key === event.key);
	}

	/**
	 * Returns true if the key pressed is backspace or delete
	 */
	static isDeletionKey(event: KeyboardEvent): boolean {
		return [Key.DELETE, Key.BACKSPACE].some(key => key === event.key);
	}
}
