<modal-header
	(cancel)="cancel()"
	modalTitle="{{'widget.bubbleSize'|i18n}}">
</modal-header>

<div id="settings" class='modal-body'>
	<select class="bubble-size-select" [(ngModel)]="model[propertyMap.scale]">
		<option *ngFor="let scaleOption of scaleOptions">{{scaleOption}}</option>
	</select>
</div>

	<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
		saveText="{{'common.update' | i18n}}">
	</save-modal-footer>