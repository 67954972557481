<modal-header
	(cancel)="cancel()"
	[modalTitle]="getProjectNameFormat()">
</modal-header>

<div class="modal-body">

	<div class="row mb-24">
		<div class="col-sm-12 form-group">
			<label>{{'calendar.calendarModalLabel'|i18n}}</label>
			<simple-dropdown
				displayField="displayName"
				[options]="calendarTypeOptions"
				valueField="value"
				[(value)]="model.type"
				(onChange)="selectCalendarType($event)">
			</simple-dropdown>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12 form-group">
			<label>{{'calendar.weekIdentifier'|i18n}}</label>
			<div class="form-group form-inline">
				<cb-radio-group
					[inline]="true"
					[options]="weekIdentiferOptions"
					[(ngModel)]="model.weekIdentifier">
				</cb-radio-group>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12 form-group">
			<label>{{'calendar.overlappingYears'|i18n}}</label>
			<p>{{'calendar.overlappingYearsDescription'|i18n}}</p>
			<div class="form-group form-inline">
				<cb-radio-group
					[inline]="true"
					[options]="overlappingYearsOptions"
					[(ngModel)]="model.overlappingYears">
				</cb-radio-group>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12 form-group">
			<button
				type="button"
				class="btn btn-secondary"
				(click)="resetToDefault()">{{'common.resetDefault'|i18n}}</button>
		</div>
	</div>

</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'common.save' | i18n"
></save-modal-footer>