import { Injectable } from '@angular/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { ModelTreeNode, ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { ModelUtils } from '@app/modules/project/model/model-utils';

@Injectable({
	providedIn: 'root'
})
export class ReportModelsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.MODELS);
	}

	getModels(
		assetParameters: RequestAssetParameters,
		withHierarchies = false,
		cache = CacheOptions.CACHED,
	): AssetPromisePlatformProject<IReportModel[]> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get('rest/report-assets/models', options)
			.then((models) => {
				if (!withHierarchies) {
					return AssetVisibilityUtils.getVisibleModels(models);
				}
				return models;
			}) as AssetPromisePlatformProject<IReportModel[]>;
	}

	getModelTree(
		assetParameters: RequestAssetParameters,
		modelId: number,
		cache = CacheOptions.CACHED,
	): AssetPromisePlatformProject<ModelTree> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get(`rest/report-assets/models/${modelId}`, options)
			.then(model => ModelUtils.populateNodesPath(model)) as AssetPromisePlatformProject<ModelTree>;
	}

	getNodeInfo(
		assetParameters: RequestAssetParameters,
		nodeId: number,
		cache = CacheOptions.CACHED,
	): AssetPromisePlatformProject<ModelTreeNode> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get(`rest/report-assets/models/nodes/${nodeId}`, options) as AssetPromisePlatformProject<ModelTreeNode>;
	}
}
