export interface CapsLockDetection {
	handler: (e, callback: (state: boolean) => void) => void;
}

export class CapsLockUtils {

	static onCapsLock(callback: (state: boolean) => void): CapsLockDetection {
		const handler = (e) => this.onButtonPressed(e, callback);
		$(window).on('keydown', handler);
		$(window).on('keyup', handler);

		return { handler };
	}

	static removeHandler = (detection: CapsLockDetection): void => {
		$(window).off('keydown', detection.handler);
		$(window).off('keyup', detection.handler);
	}

	private static onButtonPressed = (e, callback: (state: boolean) => void): void => {
		if (e.which === 20) {
			callback(e.originalEvent.getModifierState('CapsLock'));
		}
	}

}
