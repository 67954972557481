export enum VisualizationType {
	BAR = 'BAR',
	BUBBLE = 'BUBBLE',
	COLUMN = 'COLUMN',
	DUAL = 'DUAL',
	HEATMAP = 'HEATMAP',
	LINE = 'LINE',
	METRIC = 'METRIC',
	PIE = 'PIE',
	SPLINE = 'SPLINE',
	AREA = 'AREA',
	STACKED_PIE = 'STACKEDPIE',
	TABLE = 'TABLE',
	TREND = 'TREND',
	MAP = 'MAP',
	NETWORK = 'NETWORK'
}

