import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetContainerModule } from '../widget-container/widget-container.module';
import { NgPipesModule } from 'ngx-pipes';
import { DriversResultsTabComponent } from './drivers-results-tab/drivers-results-tab.component';
import { DocumentExplorerModule } from '../document-explorer/document-explorer.module';
import { DriversMetricsTabComponent } from './drivers-metrics-tab/drivers-metrics-tab.component';
import { DriversHiddenTabComponent } from './drivers-hidden-tab/drivers-hidden-tab.component';
import { DriversResultsDialogComponent } from './drivers-results-dialog/drivers-results-dialog.component';
import { DialogModule } from '../dialog/dialog.module';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { DriverResourcesProviderService } from './services/driver-resources-provider.service';
import DriversGridDefinition from '@app/modules/drivers/services/drivers-grid-definition.service';


@NgModule({
	exports: [
		DriversResultsDialogComponent
	],
	declarations: [
		DriversResultsTabComponent,
		DriversMetricsTabComponent,
		DriversHiddenTabComponent,
		DriversResultsDialogComponent
	],
	imports: [
		SharedModule,
		NgPipesModule,
		DialogModule,
		WidgetContainerModule,
		DocumentExplorerModule
	],
	providers: [
		DriversApi,
		DriverResourcesProviderService,
		DriversGridDefinition
	]
})
export class DriversModule {}
