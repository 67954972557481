import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';

export enum DocumentDisplayedItemType {
	MODEL = 'MODEL',
	ATTRIBUTE = 'ATTRIBUTE'
}

export class DocumentDisplayedItem {
	static ATTRIBUTE_ID_PREFIX = 'attribute:';
	static MODEL_ID_PREFIX = 'model:';

	id: string;
	itemId: number;
	name: string;
	type: DocumentDisplayedItemType;
	displayName: string;
	originalDisplayName: string;
	selected: boolean;
	originalItem: any;

	attributeType?: ReportAssetType;
	objectType?: AttributeObjectType;
}
