import { Component, ChangeDetectionStrategy } from '@angular/core';
import { WorkspaceStatusService } from '@app/modules/system-administration/status/integration-status/workspace/workspace-status.service';

@Component({
	selector: 'workspace-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceStatusTabComponent {
	constructor(
		public service: WorkspaceStatusService,
	) {}
}
