
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PeerReportType } from '../providers/cb/constants/peer-report-types';
import { WidgetProperties } from '../entities/widget-properties';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import GeographyReportService from '@cxstudio/attribute-geography/geography-report.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ProjectGeographies from '@cxstudio/attribute-geography/project-geographies';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { ReportAssetType } from '../entities/report-asset-type';

export default class TitleUtils {

	constructor(
		private locale: ILocale,
		private geographyOptionsService: GeographyOptionsService,
		private geographyReportService: GeographyReportService
	) {
	}

	generateTableReportTitle = (props: WidgetProperties, attributes: IReportAttribute[]): string => {
		let name = this.locale.getString('widget.cb_an_table');
		if (_.isUndefined(props.widgetType) || props.widgetType === 'cb_an_table') {
			if (props.selectedAttributes && props.selectedAttributes.length > 0 ) {
				let metric = this.updateDisplayName(props.selectedAttributes[0], attributes);
				return name + ' : ' + metric
					+ (props.selectedAttributes.length === 1 ? ''
						: ( ' & ' + (props.selectedAttributes.length - 1) + ' ' + this.locale.getString('widget.more')));
			}
		}
		return name;
	}

	generateScatterByDriversTitle = (driversItem: DriversItem): string => {
		return this.locale.getString('widget.scatter') + this.locale.getString('widget.by') + driversItem.displayName;
	}

	generateAnMetricReportTitle = (props: WidgetProperties, attributes: IReportAttribute[]): string => {
		if (props.selectedMetrics && props.selectedMetrics.length > 0) {
			let prefix = props.selectedMetrics.length >= 2
				? this.locale.getString('widget.multipleMetricsPrefix')
				: this.locale.getString('widget.singleMetricPrefix');
			let postfix = props.selectedMetrics.length >= 2
				? props.selectedMetrics
					.map(metric => {
						return this.updateDisplayName(metric, attributes);
					})
					.reduce((left, right) => {
						return left === null ? right : left + ', ' + right;
					}, null)
				: this.updateDisplayName(props.selectedMetrics[0], attributes);
			return prefix + ' : ' + postfix + (props.titleWithDateRange ? ' - ' + props.dateRangeP1.dateDisplayName : '');
		}

		return this.locale.getString('widget.cb_an_metric');
	}

	generateObjectViewerTitle = (props: WidgetProperties, scorecards: Scorecard[]): string => {
		let name = this.locale.getString('widget.cb_object_viewer');
		if (props.selectedAttributes && props.selectedAttributes[0]) {
			let grouping = props.selectedAttributes[0];
			if (scorecards) {
				let scorecard = _.find(scorecards, { id: grouping.id });
				return scorecard ? scorecard.name : name;
			} else return null; // use existing name
		}
		return name;
	}

	generateModelViewerTitle = (props: WidgetProperties): string => {
		let name = this.locale.getString('widget.cb_an_model_viewer');
		if (!_.isEmpty(props.selectedAttributes)) {
			return this.locale.getString('widget.objectStructure', {object: props.selectedAttributes[0].displayName});
		}
		return name;
	}

	generateAnalyticReportTitle = (props: WidgetProperties, attributes: IReportAttribute[]): string => {
		let type = props.widgetType;
		let name = this.locale.getString('widget.' + type);
		return name + this.generateGrouping(props, attributes);
	}

	private generateGrouping = (props: WidgetProperties, attributes: IReportAttribute[]): string => {
		let suffix = '';

		let attributeGroups = _.chain(props.selectedAttributes)
			.map(attr => {
				return attr ? this.updateDisplayName(attr, attributes) : '';
			}).filter(name => {
				return !!name;
			}).value();

		let groups = [];
		groups.pushAll(attributeGroups);

		if (groups && groups.length) {
			if (groups.length > 3) {
				suffix = this.locale.getString('widget.by') + groups.slice(0, 3).join(', ') + '...';
			} else {
				suffix = this.locale.getString('widget.by') + groups.join(', ');
			}
		}
		return suffix;
	}

	private getDeletedString = (): string => {
		return '<' + this.locale.getString('widget.deleted') + '>';
	}

	private updateDisplayName = (selectedAttribute, attributes: IReportAttribute[]): string => {
		if (!selectedAttribute)
			return '';

		//need to update cmp att, topic, metric
		if (selectedAttribute) {
			if (!_.isEmpty(attributes) && AnalyticMetricTypes.isAttribute(selectedAttribute)) {
				let attr;
				if (selectedAttribute.type === ReportAssetType.METRIC_NLP) {
					attr = selectedAttribute;
				} else {
					attr = _.find(attributes,  attribute => {
						if (attribute.type === selectedAttribute.type) {
							return attribute.name === selectedAttribute.name;
						}
						return false;
					});
				}
				return attr ? attr.displayName : this.getDeletedString();
			}
		}

		return this.getGroupingWidgetTitleDisplayName(selectedAttribute) || '';
	}

	private getGroupingWidgetTitleDisplayName = (grouping): string => {
		return AnalyticMetricTypes.isHierarchyModel(grouping) && PeerReportType.PARENT_PEER_REPORT === grouping.peerReportType
			? grouping.displayName + ' - ' + this.locale.getString('widget.peerReportHierarchyTreePostfix')
			: grouping.displayName;
	}

	generateAnMapTitle = (props: WidgetProperties, projectGeographies: ProjectGeographies): string => {
		let map = this.locale.getString('widget.cb_an_map');
		let grouping = props.selectedAttributes && props.selectedAttributes[0];
		if (!grouping || !grouping.name) {
			return map;
		}

		let mapBy = `${map}${this.locale.getString('widget.by')}`;

		let boundaryField = this.geographyReportService.getGroupingBoundaryField(grouping, projectGeographies);
		return boundaryField
			? mapBy + this.geographyOptionsService.getOptionDropdownName(boundaryField)
			: map;
	}

}

app.service('titleUtils', TitleUtils);
