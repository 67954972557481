import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardListTab } from '@app/modules/dashboard-list/classes/dashboard-list-tab.class';
import { DashboardListTabTypes } from '@app/modules/dashboard-list/dashboard-list-tab-types.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { NavigationService } from '@cxstudio/services/navigation.service';

@Component({
	selector: 'dashboards-by-label',
	templateUrl: './dashboards-by-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardsByLabelComponent extends SelfCleaningComponent implements OnInit {
	dashboards: Dashboard[] = [];
	activeTab: number = 0;
	tabsEnabled: boolean;
	tabConfig: DashboardListTab[];

	constructor(
		private readonly locale: CxLocaleService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly userHomePageService: UserHomePageService,
		private readonly dashboardListService: DashboardListService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		private readonly betaFeaturesService: BetaFeaturesService
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.userHomePageService.getHomePage().subscribe(homePage => {
			this.tabsEnabled = homePage.carousel.enabled;
			this.tabConfig = _.filter(homePage.carousel.tabs, tab => !!tab.name);
			this.changeDetectorRef.markForCheck();
		}));
		this.addSubscription(this.dashboardListService.getDashboards().subscribe(dashboards => {
			this.dashboards = dashboards;
			this.changeDetectorRef.markForCheck();
		}));
	}

	getTabId(tab: DashboardListTab): string {
		return DashboardListTabTypes.getTabType(tab).getTabId(tab.name);
	}

	getContentId(tab: DashboardListTab): string {
		return DashboardListTabTypes.getTabType(tab).getContentId(tab.name);
	}

	getTabIcon(tab: DashboardListTab): string {
		const icon = DashboardListTabTypes.getTabType(tab).TAB_ICON;
		return icon ? `mr-4 ${icon}` : '';
	}

	getTabName(tab: DashboardListTab): string {
		return DashboardListTabTypes.getTabType(tab).getTabName(this.locale, tab.name);
	}

	getDashboardsForTab(tab: DashboardListTab): Dashboard[] {
		return DashboardListTabTypes.getTabType(tab).getList(this.dashboards, tab.name);
	}

	getEmptyText(tab: DashboardListTab): string {
		return DashboardListTabTypes.getTabType(tab).getEmptyListHTML(this.locale);
	}

	changeDashboard(dashboard: Dashboard): void {
		this.navigationService.changeDashboard(dashboard, false);
	}

	@HostListener('window:resize')
	detectResize(): void {
		this.changeDetectorRef.markForCheck();
	}
}
