import { Node } from '@app/modules/document-explorer/events/document-explorer-events';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';

export class SentenceScrollHelper {
	private sentenceId: number;

	constructor(
		private readonly sentences: PreviewSentence[],
		private readonly widgetId?: number,
	) {}

	getNextWorldAwarenessSentence(node: Node, direction = NavigationDirection.NEXT): number {
		const filterFunction = (dataItem: PreviewSentence): boolean => {
			return dataItem?.attributes?.[node.name]?.indexOf(node.value) > -1;
		};
		return this.getNextSentence(node, direction, filterFunction);
	}

	getNextTopicSentence(node: Node, direction = NavigationDirection.NEXT): number {
		const filterFunction = (dataItem: PreviewSentence): boolean => {
			return dataItem?.sentenceTopics && !!_.find(dataItem.sentenceTopics, { id: node?.id });
		};
		return this.getNextSentence(node, direction, filterFunction);
	}

	private getNextSentence(
		node: Node, direction: NavigationDirection,
		filterFn: (sentence: PreviewSentence) => boolean,
	): number | undefined {
		let sentenceIds: number[];
		if (filterFn) {
			sentenceIds = this.sentences
				.filter(filterFn)
				.map((sentence) => sentence.id);
		}

		if (!node || _.isEmpty(sentenceIds)) {
			return;
		}

		let sentenceIndex = sentenceIds.indexOf(this.sentenceId || 0);
		if (direction === NavigationDirection.NEXT) {
			this.sentenceId = ++sentenceIndex < sentenceIds.length
				? sentenceIds[sentenceIndex]
				: sentenceIds[0];
		} else {
			this.sentenceId = --sentenceIndex > -1
				? sentenceIds[sentenceIndex]
				: sentenceIds[sentenceIds.length - 1];
		}
		return this.sentenceId;
	}

	scrollToSentence(targetSentenceId: number, animationTimeout: number = 500): void {
		let sentence = _.findWhere(this.sentences, { id: targetSentenceId });
		if (!sentence) {
			return;
		}
		let targetSentenceCss: string = this.getSentenceElementCss(sentence);
		let targetElement: JQuery = $(targetSentenceCss);
		if (targetElement.length && targetElement.offset()) {
			let scrollTo = this.getSentenceScrollVisibleTop(sentence);
			let wrapper = $(this.getDocumentWrapper());
			if (animationTimeout > 0) {
				wrapper.animate({scrollTop : scrollTo}, {
					duration: animationTimeout,
					queue: false
				});
			} else {
				wrapper.scrollTop(scrollTo);
			}
		}
	}

	private readonly getSentenceScrollVisibleTop = (sentence: PreviewSentence): number => {
		let targetSentenceCss: string = this.getSentenceElementCss(sentence);
		let lineElement: HTMLElement = document.querySelector(targetSentenceCss);

		const BUFFER = 32; // leave a small visual buffer above scrolled block
		let topOffset = 30;
		return lineElement.offsetTop - topOffset - BUFFER;
	}

	private readonly getDocumentWrapper = (): JQuery => {
		return $(this.getWrapperLocator());
	}

	private readonly getSentenceElementCss = (sentence: PreviewSentence): string => {
		return `${this.getWrapperLocator()} ${this.getSentenceSelector(sentence.id)}`;
	}

	private readonly getWrapperLocator = (): string => {
		const container = this.widgetId ? `#widget-${this.widgetId}` : `.an-document-explorer`;
		return `${container} #document-wrap`;
	}

	private getSentenceSelector(sentenceId: number): string {
		return  `#sentence-preview-preview-${sentenceId}`;
	}
}
