<table class="table cx-ma-contracts-table">
	<thead>
		<tr>
			<th rowspan="2" scope="col" [i18n]="'mAccount.contractName'"></th>
			<th rowspan="2" scope="col"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.startDate'"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.endDate'"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.period'"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.active'"></th>
			<th *ngIf="licenses && licenseCountWithSeats() > 0" [i18n]="'mAccount.licenses'" class="text-center"
				scope="colgroup" [colSpan]="licenseCountWithSeats()"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.verbatimCredits'"></th>
			<th *ngIf="isAdditionalSecuritySpecified()" rowspan="2" scope="col" [i18n]="'mAccount.additionalSecurity'"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.audio'"></th>
			<th *ngIf="isIntelligentScoringEnabled()" rowspan="2" scope="col" [i18n]="'mAccount.intelligentScoring'"></th>
			<th rowspan="2" scope="col" [i18n]="'mAccount.agentAssist'"></th>
		</tr>
		<tr>
			<ng-container *ngIf="licenses">
				<ng-container *ngFor="let license of licenses | orderBy:['-licenseLevel', 'licenseName']">
					<th  *ngIf="license | licenseHasContractWithSeats:contracts" scope="col">
						{{license.licenseTypeName}}
					</th>
				</ng-container>
			</ng-container>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let contract of contracts | orderBy:['startDate']" class="cx-contract hover-parent">
			<td class="cx-contract-name">{{ contract.name }}</td>
			<td class="cx-contract-menu">
				<a class="show-on-parent-hover show-border-on-parent-hover burger-wrap action-hover-border action-hover-text"
				   (click)="showContractMenu($event, contract)">
					<i class="q-icon q-icon-layer br-menu-burger" [attr.aria-label]="'common.options'|i18n"></i>
				</a>
			</td>
			<td class="cx-contract-start-date">{{ formatDate(contract.startDate) }}</td>
			<td class="cx-contract-end-date">{{ formatDate(contract.endDate) }}</td>
			<td class="cx-contract-period">{{ getPeriod(contract) }}</td>
			<td class="cx-contract-active">{{ getActiveState(contract) }}</td>
			<ng-container *ngIf="licenses">
				<ng-container *ngFor="let license of licenses | orderBy:['-licenseLevel', 'licenseName']">
					<td  *ngIf="license | licenseHasContractWithSeats:contracts" scope="col">
						{{getLicenseSeats(contract, license.licenseTypeId) | number}}
					</td>
				</ng-container>
			</ng-container>
			<td class="cx-contract-credits">{{ contract.verbatimCredits | number }}</td>
			<td *ngIf="isAdditionalSecuritySpecified()" class="cx-contract-securiy">{{ getSecurityLevel(contract) }}</td>
			<td class="cx-audio">{{ getAudioValue(contract) }}</td>
			<td *ngIf="isIntelligentScoringEnabled()" title="{{getCoverage(contract)}}">{{ getIntelligentScoring(contract) }}</td>
			<td class="cx-audio">{{ getAgentAssistValue(contract) }}</td>
		</tr>
	</tbody>
</table>
