import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { WorkspaceId, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceScorecardMetricsManagementApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.METRICS);
	}

	getMetrics = (
		project: WorkspaceProject, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<ScorecardMetric[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/scorecard-metrics';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ScorecardMetric[]>;
	}

	getMetricsForWorkspace = (
		workspace: WorkspaceId, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<ScorecardMetric[]> => {
		let config = { cache, local };
		let url = this.getBaseWorkspacePath(workspace) + '/scorecard-metrics';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ScorecardMetric[]>;
	}

	private getBaseWorkspacePath = (workspace: WorkspaceId): string => {
		return `rest/manage/ws/${workspace}/all-projects`;
	}

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/manage/ws/${project.workspaceId}/project/${project.projectId}`;
	}
}
