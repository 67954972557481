import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ConfigurationModalComponent, IConfigurationDialogInput } from '@app/modules/asset-management/configuration-modal/configuration-modal.component';
import { NumericAttributeCalculationSettingsEditorProvider } from '@app/modules/attribute/services/assets/settings-editors/providers/numeric-attribute-calculation-settings-editor.provider';
import { NumericAttributeGroupingSettingsEditorProvider } from '@app/modules/attribute/services/assets/settings-editors/providers/numeric-attribute-grouping-settings-editor.provider';
import { TextAttributeGroupingSettingsEditorProvider } from '@app/modules/attribute/services/assets/settings-editors/providers/text-attribute-grouping-settings-editor.provider';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ModelGroupingSettingsEditorProvider } from '@app/modules/model/services/model-grouping-settings-editor.provider';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { AttributeSettingsService } from '@app/modules/project/attribute/attribute-settings.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { ModelSettingsService } from '@app/modules/project/model/model-settings.service';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { PromiseUtils } from '@app/util/promise-utils';
import { ModelSettings } from '@cxstudio/asset-management/configuration/settings-data/model-data';
import { NumericAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/numeric-attribute-data';
import { TextAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/text-attribute-data';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { ModelIdentifier } from '@cxstudio/projects/model-identifier';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';

@Injectable({
	providedIn: 'root'
})
export class AssetDefaultsService {

	constructor(
		private locale: CxLocaleService,
		private textAttributeGroupingSettingsEditorProvider: TextAttributeGroupingSettingsEditorProvider,
		private numericAttributeGroupingSettingsEditorProvider: NumericAttributeGroupingSettingsEditorProvider,
		private numericAttributeCalculationSettingsEditorProvider: NumericAttributeCalculationSettingsEditorProvider,
		private modelGroupingSettingsEditorProvider: ModelGroupingSettingsEditorProvider,
		private attributeSettingsService: AttributeSettingsService,
		private modelSettingsService: ModelSettingsService,
		private cxDialogService: CxDialogService,
		@Inject('metricConstants') private metricConstants: MetricConstants,
	) { }

	editAttributeDefaults(attribute: IAdminAttribute, project: AccountOrWorkspaceProjectData): Promise<void> {
		let isWordAttribute = this.metricConstants.isWordAttribute(attribute.name);

		if (attribute.type === AttributeType.TEXT && !isWordAttribute) {
			return this.editTextAttributeDefaults(attribute, project);
		} else if (attribute.type === AttributeType.NUMBER) {
			return this.editNumericAttributeDefaults(attribute, project);
		} else if (isWordAttribute) {
			return this.editWordAttributeDefaults(attribute, project);
		}
	}

	private editTextAttributeDefaults(attribute: IAdminAttribute, project: AccountOrWorkspaceProjectData): Promise<void> {
		return this.attributeSettingsService.getTextAttributeSettings(
			project, attribute.id, CacheOptions.NOT_CACHED
		).then(savedSettings => {
			let groupingSettings = savedSettings ? savedSettings.grouping : undefined;
			let groupingEditor =  this.textAttributeGroupingSettingsEditorProvider.getInstance();
			let groupingPromise = groupingEditor.setSettings(attribute, groupingSettings, project);
			let settings = {
				defaults: true,
				itemName: attribute.displayName,
				tabs: [{
					key: 'grouping',
					editor: groupingEditor
				}]
			};
			groupingPromise.then(() => this.editDefaults<TextAttributeSettings>(settings).then(result => {
				this.attributeSettingsService.saveTextAttributeSettings(project, attribute.id, result);
			})).catch(() => _.noop);
		});
	}

	private editWordAttributeDefaults(attribute: IAdminAttribute, project: AccountOrWorkspaceProjectData): Promise<void> {
		return this.attributeSettingsService.getWordAttributeSettings(
			project, attribute.name, CacheOptions.NOT_CACHED
		).then(savedSettings => {
			let groupingSettings = savedSettings ? savedSettings.grouping : undefined;
			let groupingEditor =  this.textAttributeGroupingSettingsEditorProvider.getInstance();
			let groupingPromise = groupingEditor.setSettings(attribute, groupingSettings, project);
			let settings = {
				defaults: true,
				itemName: attribute.displayName,
				tabs: [{
					key: 'grouping',
					editor: groupingEditor
				}]
			};
			groupingPromise.then(() => this.editDefaults<TextAttributeSettings>(settings).then(result => {
				this.attributeSettingsService.saveWordAttributeSettings(project, attribute.name, result);
			})).catch(() => _.noop);
		});
	}

	private editNumericAttributeDefaults(attribute: IAdminAttribute, project: AccountOrWorkspaceProjectData): Promise<void> {
		return this.attributeSettingsService.getNumericAttributeSettings(
			project, attribute.id, CacheOptions.NOT_CACHED
		).then(savedSettings => {
			let groupingSettings = savedSettings ? savedSettings.grouping : undefined;
			let groupingEditor =  this.numericAttributeGroupingSettingsEditorProvider.getInstance();
			let groupingPromise = groupingEditor.setSettings(attribute, groupingSettings, project);

			let calculationSettings = savedSettings ? savedSettings.calculation : undefined;
			let calculationEditor =  this.numericAttributeCalculationSettingsEditorProvider.getInstance();
			let calculationPromise = calculationEditor.setSettings(calculationSettings);

			let settings = {
				defaults: true,
				itemName: attribute.displayName,
				tabs: [{
					key: 'grouping',
					name: this.locale.getString('widget.grouping'),
					editor: groupingEditor
				}, {
					key: 'calculation',
					name: this.locale.getString('widget.calculation'),
					editor: calculationEditor
				}]
			};
			PromiseUtils.all([groupingPromise, calculationPromise]).then(() => {
				return this.editDefaults<NumericAttributeSettings>(settings).then(result => {
					this.attributeSettingsService.saveNumericAttributeSettings(project, attribute.id, result);
				});
			});
		});
	}

	editModelDefaults(model: IAdminModel, project: AccountOrWorkspaceProjectData): Promise<void> {
		let modelParams: ModelIdentifier = {
			project,
			modelId: model.id
		};
		return this.modelSettingsService.getModelSettings(project, model.id, CacheOptions.NOT_CACHED).then(savedSettings => {
			let topicsSettings = savedSettings ? savedSettings.topics : undefined;
			let topicsEditor = this.modelGroupingSettingsEditorProvider.getInstance();
			let topicsPromise = topicsEditor.setSettings(topicsSettings, modelParams);

			let settings = {
				defaults: true,
				itemName: model.name,
				tabs: [{
					key: 'topics',
					name: this.locale.getString('widget.topicDefaults'),
					editor: topicsEditor
				}]
			};
			topicsPromise.then(() => this.editDefaults<ModelSettings>(settings).then(result => {
				this.modelSettingsService.saveModelSettings(project, model.id, result);
			})).catch(() => _.noop);
		});
	}

	private editDefaults<T>(modalSettings: IConfigurationDialogInput['modalSettings']): Promise<T> {
		let input: IConfigurationDialogInput = { modalSettings };
		return this.cxDialogService.openDialog(ConfigurationModalComponent, input).result;
	}
}

app.service('assetDefaultsService', downgradeInjectable(AssetDefaultsService));
