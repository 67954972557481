import { PredictionSettings } from './integrations/prediction-settings';
import { EngagorSettings } from '../entities/engagor-settings.class';
import { ExternalAuthSettings } from './integrations/external-auth-settings';
import { VoiceSettings } from './integrations/voice-settings';
import { ConnectorsSettings } from './integrations/connectors-settings';
import { TuningSuggestionIntegration } from './integrations/tuning-suggestion-integration';
import MasterAccountExpirationData from '@app/modules/system-administration/master-account/entities/master-account-expiration-data';
import { NarrativeIntegration } from './integrations/narrative-integration';
import { QualtricsApiIntegration, QualtricsIntegration } from './integrations/qualtrics-integration';
import BaseMasterAccount from '@cxstudio/system-administration/master-accounts/base-master-account';
import { MasterAccountHierarchySettings } from '@app/modules/system-administration/master-account/entities/master-account-hierarchy-settings';

export default class MasterAccount extends BaseMasterAccount {
	connectorsEnabled: boolean;

	connectorsSettings: ConnectorsSettings;
	voiceSettings: VoiceSettings;
	externalAuthSettings: ExternalAuthSettings;
	predictionSettings: PredictionSettings;
	engagorEnabled: boolean;
	engagorSettings: EngagorSettings;
	tuningSuggestionIntegration: TuningSuggestionIntegration;
	narrativeIntegration: NarrativeIntegration;
	qualtricsIntegration: QualtricsIntegration;
	qualtricsApiIntegration: QualtricsApiIntegration;
	unitIds?: number[];

	enabled: boolean;
	disabled: boolean;
	restricted: boolean;
	isAdminOrg: boolean;
	dashboardScheduling: boolean;
	licensingEnabled: boolean;
	licenseEmails: boolean;

	contractLicenses;
	contractTimezone: string;
	contractCredits?: number;

	maxCustomerAdmins?: number;
	maxReportingWidgets?: number;
	maxNonReportingWidgets?: number;
	maxParallelRequests?: number;
	betaFeatures?: {[key: string]: boolean};
	useHsmHostname?: boolean;

	expirationSettings: MasterAccountExpirationData;
	hierarchySettings: MasterAccountHierarchySettings;
}
