import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import { TopicSelectionStrategy } from '@app/shared/components/tree-selection/topic-selection-strategy.interface';
import { PromiseUtils } from '@app/util/promise-utils';
import { DriverResourcesContainer } from '@cxstudio/drivers/editor/driver-resources-container';
import { IDriversModelColumn } from '@cxstudio/drivers/entities/drivers-definition';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { Model } from '@cxstudio/reports/entities/model';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import * as _ from 'underscore';
import { DriversItem } from '../entities/drivers-item';
import { AttributeWarning } from './attribute-warning.enum';
import { DriversHelperService } from './drivers-helper.service';
import { IListOption } from './list-option.interface';

type TopicPredicate = (item?: ModelTreeNode) => boolean;
interface TreeSelectionMode {
	name?: string;
	predicate: TopicPredicate;
}

export class DriverDataComponent implements ng.IComponentController {

	//ui: IDriversDialogUI;
	driversItem: DriversItem;
	projectSelection: IProjectSelection;
	resourceContainer: DriverResourcesContainer;

	// UI
	showAnonymizeOption: boolean;
	modelLoading: ng.IPromise<any>;
	loadingStats: boolean;
	loaded: boolean;

	// main data
	selectedOptions: IListOption[];
	modelTreeSelections: {[modelId: number]: number[]};
	modelLevelSelection: {[modelId: number]: number};

	// other
	availableOptions: IListOption[];
	selectGroups: string[];
	currentModelTree: TopicSelectionStrategy;
	modelTreeHandlers: {[modelId: number]: TopicSelectionStrategy};
	topicSelectionModes: TreeSelectionMode[];

	lastStatsRequest: ng.IPromise<any>;

	// object used to pass search into searchable hierarchy to allow for modified UI
	searchObject: {hierarchySearch?: string};
	selectAll: TopicPredicate;
	selectNone: TopicPredicate;

	constructor(
		private locale: ILocale,
		private modelsService: ModelsService,
		private $scope: ISimpleScope,
		private driversHelperService: DriversHelperService,
		private filterMatchModes: FilterMatchModes
	) {}

	$onInit = () => {
		this.searchObject = {};
		this.showAnonymizeOption = this.driversHelperService.isCustomPredictionProvider();

		this.selectedOptions = [];
		this.modelTreeSelections = {};
		this.modelLevelSelection = {};

		this.availableOptions = [];
		this.modelTreeHandlers = {};

		this.selectGroups = [
			this.locale.getString('common.attributes'),
			this.locale.getString('common.models')
		];

		this.currentModelTree = undefined;
		this.resourceContainer.get().then(resources => {
			let availableAttributes = _.chain(resources.attributes)
				.filter(MetricFilters.DRIVER_ATTRIBUTES).each((attr: any) => {
					attr.tag = this.selectGroups[0];
					attr.optionType = ObjectType.ATTRIBUTE;
				}).value();
			let availableModels = _.map(resources.models, (model: Model) => {
				let modelItem = _.extend(model, {
					displayName: model.name,
					tag: this.selectGroups[1],
					optionType: ObjectType.MODEL
				});
				return modelItem;
			});
			this.availableOptions.pushAll(availableAttributes as any[]);
			this.availableOptions.pushAll(availableModels as any[]);
			this.processSelections();
			this.processDisabledItems();
			this.loaded = true;
		});
		this.$scope.$on('drivers:refresh-attributes-stats', this.refreshAttributesStats);
		this.$scope.$on('drivers:refresh-disabled-items', this.processDisabledItems);

		this.$scope.$watch('$ctrl.currentModelTree', () => {
			if (this.currentModelTree) {
				this.initTopicSelectionModes(this.currentModelTree.getRoot());
			}
		});
	}

	updateSelectedOptions = (options: IListOption[]): void => {
		this.selectedOptions = options;
	}

	getAvailableOptionsText = (): string => this.locale.getString('administration.available');

	getSelectedOptionsText = (): string => this.locale.getString('administration.selected');

	itemClassFormatter = (item: IListOption): string => {
		let iconClass = !!item.warning ? 'q-icon-warning' : '';
		if (item.warning === AttributeWarning.NO_VALUES)
			iconClass += ' icon-danger';
		return iconClass;
	}

	itemTooltipFormatter = (item: IListOption): string => !!item.warning ? this.locale.getString(`drivers.${item.warning}`) : '';

	removeHazardAttributes = () => {
		this.selectedOptions = _.filter(this.selectedOptions, option => !option.warning);
		this.updateDefinition();
	}

	hasWarnings = (): boolean => _.any(this.selectedOptions, option => !!option.warning);

	applyTopicSelection = (topicPredicate: TopicPredicate): void => {
		if (topicPredicate && this.currentModelTree) {
			let root = this.currentModelTree.getRoot();
			let selectedItems = HierarchyUtils.findItems(root, topicPredicate);
			this.modelTreeSelections[this.currentModelTree.getRoot().modelId] = _.pluck(selectedItems, 'id');
			this.initModelTreeHandler(root.modelId, root); // reset tree
			this.updateDefinition();
		}
		if (topicPredicate === this.selectAll || topicPredicate === this.selectNone)
			this.clearTopicLevelSelection();
	}

	topicLevelChanged = (levelSelection: any): void => {
		if (levelSelection && this.currentModelTree) {
			this.applyTopicSelection(levelSelection.predicate);
		}
	}

	clearTopicLevelSelection = (): void => {
		delete this.modelLevelSelection[this.currentModelTree.getRoot().modelId];
	}

	onSelectionChanged = (options: IListOption[]): void => {
		let models = _.filter(options, this.driversHelperService.isModel);
		_.each(models, model => {
			if (!model.selected && this.currentModelTree === this.modelTreeHandlers[model.id])
				this.currentModelTree = undefined;
		});

	}

	processSelections(): void {
		this.selectedOptions = _.filter(this.availableOptions, option => {
			return this.driversHelperService.isModel(option)
				? !!_.findWhere(this.driversItem.definition.models, {name: '' + option.id})
				: !!_.findWhere(this.driversItem.definition.attributes, {name: option.name});
		});

		this.modelTreeSelections = {};
		_.each(this.driversItem.definition.models, item => {
			this.modelTreeSelections[item.name] = angular.copy(item.nodeIds);
			this.modelLevelSelection[item.name] = angular.copy(item.topicLevel);
		});
		this.$scope.$watchCollection('$ctrl.selectedOptions', this.updateDefinition);
	}

	private updateDefinition = (): void => {
		let definition = this.driversItem.definition;
		definition.attributes = this.driversHelperService.getAvailableAttributes(this.selectedOptions).map(attr => {
			return {
				name: attr.name
			};
		});

		definition.models = this.driversHelperService.getAvailableModels(this.selectedOptions).map(model => {
			let item = {
				name: '' + model.id
			} as IDriversModelColumn;
			let nodeIds = this.modelTreeSelections[model.id];
			if (nodeIds) {
				item.nodeIds = nodeIds;
			}
			let topicLevel = this.modelLevelSelection[model.id];
			if (topicLevel) {
				item.topicLevel = topicLevel;
			}
			return item;
		});
		if (!this.hasAnyTopics()) {
			definition.includeSentiment = false;
			definition.includeEaseScore = false;
		}
	}

	onModelSelected = (options: IListOption[]): void => {
		let models = _.filter(options, this.driversHelperService.isModel);
		if (_.size(models) === 1)
			this.changeSelectedModel(models[0].id);
		else this.changeSelectedModel(undefined);
	}

	private changeSelectedModel(modelId?: number): void {
		if (!modelId) {
			this.currentModelTree = undefined;
			return;
		}

		this.currentModelTree = this.modelTreeHandlers[modelId];
		if (!this.currentModelTree) {
			this.loadModelTree(modelId);
		}
	}

	private loadModelTree(modelId?: number): void {
		let promise = this.modelLoading = PromiseUtils.old(this.modelsService.getModelTree(this.projectSelection, modelId));
		promise.then((modelTree: ModelTree) => {
			if (_.isUndefined(this.modelTreeSelections[modelId])) {
				let defaultSelection = new SimpleTopicSelection(modelTree.root, []);
				this.modelTreeSelections[modelId] = defaultSelection.getAllNodeIds();
			}
			this.initModelTreeHandler(modelId, modelTree.root);
			this.initTopicSelectionModes(modelTree.root);
		});
	}

	private initModelTreeHandler(modelId: number, root: ModelTreeNode): void {
		this.currentModelTree = new SimpleTopicSelection(root,
			this.modelTreeSelections[modelId], this.disabledTopicsProvider(modelId));
		this.modelTreeHandlers[modelId] = this.currentModelTree;
	}

	private initTopicSelectionModes(root: ModelTreeNode): void {
		let leaves = HierarchyUtils.findItems(root, item => !item.children) as ModelTreeNode[];
		let maxLevelNode = _.max(leaves, (leaf: ModelTreeNode) => leaf.level) as ModelTreeNode;
		let levelModes = _.range(1, maxLevelNode.level + 1).map(level => {
			return {id: level, name: '' + level, predicate: item => item.level === level};
		});
		let leafMode = {id: 100, name: this.locale.getString('widget.leaf'), predicate: item => !item.children};
		this.selectAll = item => item.id !== root.id;
		this.selectNone = () => false;

		this.topicSelectionModes = [];
		this.topicSelectionModes.pushAll(levelModes);
		this.topicSelectionModes.push(leafMode);
	}

	handleTopicClick = (node: ModelTreeNode): void => {
		this.currentModelTree.handleNodeClick(node);
		this.updateDefinition();
		this.clearTopicLevelSelection();
	}

	private disabledTopicsProvider(modelId: number): () => string[] {
		return () => {
			let nodePaths: string[] = _.chain(this.driversItem.target.filters.filterRules)
				.filter(rule => rule.type === FilterRuleType.topicEquality)
				.filter(rule => rule.topicId === modelId)
				.map(rule => rule.values)
				.flatten()
				.map(value => value.idPath)
				.value();
			if (_.isEmpty(nodePaths))
				return undefined;
			return nodePaths;
		};
	}

	private processDisabledItems = (): void => {
		this.processDisabledOptions();
		_.each(this.modelTreeHandlers, (handler: TopicSelectionStrategy) => {
			if (handler)
				handler.resetDisabledItems();
		});
	}

	private processDisabledOptions(): void {
		let disabledAttributes = {};
		let disabledModels = {};

		_.chain(this.driversItem.target.filters.filterRules)
			.filter(rule => !!rule.attributeName)
			.map(rule => rule.attributeName)
			.map(name => name.toLowerCase())
			.each(name => disabledAttributes[name] = true);

		_.chain(this.driversItem.target.filters.filterRules)
			.filter(rule => rule.type === FilterRuleType.topicEquality)
			.filter(rule => this.filterMatchModes.isModelMatch(rule))
			.map(rule => rule.topicId)
			.each(modelId => disabledModels[modelId] = true);

		_.each(this.availableOptions, option => {
			delete option.disabled;
			if (this.driversHelperService.isModel(option)) {
				if (disabledModels[option.id]) {
					option.disabled = true;
					this.selectedOptions.remove(option);
					delete this.modelTreeHandlers[option.id];
					this.changeSelectedModel(undefined);
				}
			} else {
				if (disabledAttributes[option.name.toLowerCase()]) {
					option.disabled = true;
					this.selectedOptions.remove(option);
				}
			}
		});
		this.updateDefinition();
	}

	private refreshAttributesStats = (): void => {
		this.clearHazards();
		this.loadingStats = true;

		let currentRequest = this.lastStatsRequest =
			this.driversHelperService.getAttributesWarnings(this.driversItem, this.availableOptions)
				.then(warnings => {
					if (currentRequest !== this.lastStatsRequest) {
						return; // not the latest
					}

					_.each(this.availableOptions, item => {
						let key;
						if (this.driversHelperService.isModel(item))
							key = item.id;
						else key = item.name.toLowerCase();
						if (warnings[key])
							item.warning = warnings[key];
					});

					// to trigger changes in AN9 inclusion-list
					this.availableOptions = [].concat(this.availableOptions);
				}).finally(() => this.loadingStats = false);
	}

	private clearHazards = () => {
		_.each(this.availableOptions, item => delete item.warning);
	}

	hasAnyTopics = (): boolean => {
		return !!_.any(this.driversItem.definition.models, model => {
			// undefined nodeIds means all nodes
			return !model.nodeIds || !_.isEmpty(model.nodeIds);
		});
	}
}

app.component('driverData', {
	bindings: {
		driversItem: '<driver',
		projectSelection: '<projectSelection',
		resourceContainer: '<resources'
	},
	controller: DriverDataComponent,
	templateUrl: 'partials/drivers/driver-data.component.html'
});
