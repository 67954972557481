import { OnInit, Component, Input, forwardRef, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'input-with-default',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputWithDefaultComponent), multi: true},
	],
	template: `
		<div>
			<div *ngIf="!isValueSet()">
				<input
					[disabled]="disabled"
					class="form-control default-value"
					(focus)="useDefaultValue()"
					title="{{'templates.genericButton'|i18n}}"
					[value]="getDefaultInputText()">
			</div>
			<div *ngIf="isValueSet()" class="p-relative">
				<input
					#valueInput
					[disabled]="disabled"
					class="form-control clearable-input"
					[ngModel]="value"
					(ngModelChange)="valueChangeHandler($event)"
					[maxlength]="maxlength">
				<i (click)="clear()"
					role="button"
					class="q-icon-delete btn-clear-input cursor-pointer"
					[class.disabled]="disabled"
					title="{{'common.clearValue'|i18n}}"></i>
			</div>
		</div>
	`
})
export class InputWithDefaultComponent implements OnInit, ControlValueAccessor {
	@Input() disabled: boolean;
	@Input() defaultValue: string;
	@Input() maxlength: number;
	@Output() onClear = new EventEmitter<void>();

	@ViewChild('valueInput', {static: false}) elementRef: ElementRef;

	value;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {}

	getDefaultInputText = (): string => {
		return `${this.defaultValue} (${this.locale.getString('common.default')})`;
	}

	isValueSet = (): boolean => {
		return this.value !== undefined;
	}

	useDefaultValue = (): void => {
		this.valueChangeHandler(this.defaultValue);
		setTimeout(() => { this.elementRef.nativeElement.focus(); }, 0);
	}

	clear = (): void => {
		if (!this.disabled) {
			this.clearValue();
			this.onClear.emit();
		}
	}

	// if no custom function is set, just clear out the value
	private clearValue(): void {
		this.valueChangeHandler();
	}

	valueChangeHandler(value?): void {
		this.value = value;
		this.onChangeCallback(value);
		this.onTouchedCallback();
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.value) {
			this.value = value;
			this.onChangeCallback(value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
