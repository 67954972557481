
export enum ReportAssetType {
	SYS = 'sys',
	NUMBER = 'NUMBER',
	TEXT = 'TEXT',
	DATE = 'DATE',
	DIMENSIONAL = 'DIMENSIONAL',
	SATSCORE = 'SATSCORE',
	METRIC_STUDIO = 'metricStudio',
	TOPICS = 'topics',
	TOPIC_LEAF = 'topicLeaf',
	METRIC_PREDEFINED = 'metricPredefined',
	GLOBAL_OTHER = 'metricGlobalOther',
	HIERARCHY_MODEL = 'hierarchyModel',
	SCORECARD = 'metricScorecard',
	METRIC_NLP = 'metricNLP',
	OBJECT_DEFINITION = 'objectDefinition',
	SCORECARDS = 'scorecards',
	DRIVERS = 'drivers'
}

