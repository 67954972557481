import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
export class ProjectIdentifier implements IProjectSelection {
	contentProviderId: number;
	accountId: number;
	projectId: number;
	contentProviderName?: string;
	accountName?: string;
	projectName?: string;

	static fromWidgetProperties(properties: WidgetProject): ProjectIdentifier {
		return {
			contentProviderId: properties.contentProviderId,
			accountId: properties.accountId,
			projectId: properties.project
		};
	}

	static fromDashboardProperties(properties: DashboardProperties): ProjectIdentifier {
		return {
			contentProviderId: properties.cbContentProvider,
			accountId: properties.cbAccount,
			projectId: properties.project
		};
	}

	static isProjectSelected(projectSelection: Partial<IProjectSelection>): boolean {
		return this.isAccountSelected(projectSelection) && projectSelection.projectId > -1;
	}

	static isAccountSelected(projectSelection: Partial<IProjectSelection>): boolean {
		return projectSelection
			&& projectSelection.contentProviderId > -1
			&& projectSelection.accountId > -1;
	}

	static isEqual(project1: IProjectSelection, project2: IProjectSelection): boolean {
		return project1.contentProviderId === project2.contentProviderId
			&& project1.accountId === project2.accountId
			&& project1.projectId === project2.projectId;
	}

	static toStringKey(projectSelection: IProjectSelection): string {
		return `${projectSelection.contentProviderId}_${projectSelection.accountId}_${projectSelection.projectId}`;
	}

	constructor(contentProviderId, accountId, projectId,
		contentProviderName = '', accountName = '', projectName = '') {
		this.contentProviderId = contentProviderId;
		this.accountId = accountId;
		this.projectId = projectId;
		this.contentProviderName = contentProviderName;
		this.accountName = accountName;
		this.projectName = projectName;
	}
}
