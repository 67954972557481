<modal-header modalTitle="{{filterModalTitle}}"
	(cancel)="dismiss()"></modal-header>
<div id="cb-modal-overlay" [hidden]="!viewMode"></div>
<div id="settings" class='clearfix modal-body' >
	<form #filterCreationForm="ngForm" class="filter-settings-form" [blockKbNavigation]="viewMode">
		<div class="widget-create-filters col-sm-12">
			<div class="mb-16" [ngClass]="{'has-error': isFilterNameValid()}">
				<label htmlFor="in-filter-name">{{'reportFilters.filterNameLabel'|i18n}}</label>
				<div>
					<input id="in-filter-name"
						[(ngModel)]="ui.savedFilterName"
						name="name"
						type="text"
						size="60"
						maxlength="150"
						required
						uniqueName
						[itemList]="itemList"
						[initialItem]="initialItem"
						[folder]="false">
				</div>
				<span class="help-block" [hidden]="!isFilterNameAbsent()">{{'reportFilters.propsNameEmptyWarning'|i18n}}</span>
				<span class="help-block" [hidden]="!isFilterNameNotUnique()">{{'reportFilters.propsNameUniqueWarning'|i18n}}</span>
			</div>

			<div class="mb-16" *ngIf="isMovableToFolder()">
					<div><label>{{'reportFilters.folderLabel'|i18n}}</label></div>
					<search-list [dropdown]="true"
						id="_t-folders-dropdown"
						[data]="getTargetFolder()"
						[listOptions]="getFolders()"
						sortField="nameWithPath"
						(onNodeSelection)="selectFolder($event.node)"
						displayField="nameWithPath"
						[nonclickable]="isDateFilter()"
					></search-list>
			</div>
			<div class="form-group d-flex flex-direction-column mb-16">
				<label class="control-label">
					{{'common.labels'|i18n}}
					<cb-inline-help right>
						<help-body>{{'reportFilters.labelHelp'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div>
					<labels-input
						[labels]="ui.labels"
						[loadLabels]="loadLabels"
						(onLabelAdded)="labelAdded($event)"
						(onLabelRemoved)="labelRemoved($event)"
					></labels-input>
				</div>
			</div>

			<div class="mb-16">
				<label>{{'reportFilters.filterConditions'|i18n}}</label>
				<filter-builder
					*ngIf="ui.filterReady"
					[filterableFields]="ui.filterItems"
					[ruleSet]="newAdhocFilter?.filterRules"
					[maxRules]="5"
					[projectProperties]="project"></filter-builder>
			</div>

			<alert type="warning" [hidden]="ui.filterRulesError ? false : true">{{ui.filterRulesError|i18n}}</alert>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button
		data-testid="btn-cancel-save-filter"
		type="button"
		(click)="dismiss()"
		class="btn btn-secondary">
		{{closeButtonTitle}}
	</button>
	<button
		data-testid="btn-save-and-share"
		type="button"
		(click)="submit(true)"
		*ngIf="!viewMode && canShare"
		class="btn btn-secondary"
		[i18n]="'common.saveAndShare'"
		[disabled]="!isFilterValid()">
	</button>
	<button
		data-testid="btn-save-filter"
		type="button"
		(click)="submit(false)"
		[hidden]="viewMode"
		class="btn btn-primary"
		[i18n]="'common.save'"
		[disabled]="!isFilterValid()">
	</button>
</div>
