//This filter will start at the root node, check all of its children (if they are in the master list, add them to new child list. if the child is in list of nodes needing expansion, call recursively)

import { ObjectUtils } from '@app/util/object-utils';

app.filter('addNestedChildren', () => {
	return (totalNodeList, nodeIdsToExpand, nestedHierarchy) => {
		let rawNodesMap = {};
		_.each(totalNodeList, (rawNode) => {
			rawNodesMap[rawNode.id] = rawNode;
		});

		function recurseNodes(focusNode, expandedNodeIdsList): any {
			if (focusNode.hasOwnProperty('children') && !focusNode.children.length) {
				delete focusNode.children;
				return;
			}
			if (!focusNode.children) return;

			let focusNodeIndex = _.indexOf(expandedNodeIdsList, focusNode.id);
			if (focusNodeIndex > -1) {
				focusNode.children = _.chain(focusNode.children)
					.filter(child => !!rawNodesMap[child.id])
					.map(child => ObjectUtils.copy(rawNodesMap[child.id]))
					.value();
			}
			for (let child of focusNode.children) {
				recurseNodes(child, expandedNodeIdsList);
			}
			if (focusNodeIndex > -1) {
				focusNode.expanded = true;
			}
		}

		let rootNode = {};
		if (nestedHierarchy?.length ) {
			rootNode = nestedHierarchy[0];
		}

		recurseNodes(rootNode, nodeIdsToExpand);
		return rootNode;

	};
});
