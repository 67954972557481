import { Injectable, Inject } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { WorkspacesListActions } from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-list-actions.service';
import { AdminWorkspace } from '@app/modules/system-administration/platform/workspace/admin-workspace';
import { WorkspaceManagementComponent } from '@app/modules/system-administration/platform/workspace/workspace-management/workspace-management.component';
import { Security } from '@cxstudio/auth/security-service';

@Injectable({
	providedIn: 'root'
})
export class WorkspacesListMenu extends BaseContextMenuUtils {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly workspacesListActions: WorkspacesListActions,
		@Inject('security') private readonly security: Security,
	) {
		super();
	}

	private getOptions(component: WorkspaceManagementComponent): {[name: string]: ContextMenuItem<AdminWorkspace>} {
		return {
			EDIT: {
				name: 'edit',
				text: this.locale.getString('common.edit'),
				func: (workspace: AdminWorkspace) => this.workspacesListActions.edit(workspace, component.workspaces)
			},
			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (workspace: AdminWorkspace) => this.workspacesListActions.delete(workspace)
			}
		};
	}

	getContextMenu(workspace: AdminWorkspace, component: WorkspaceManagementComponent): Array<ContextMenuItem<AdminWorkspace>> {
		const OPTIONS = this.getOptions(component);
		return this.security.isSysAdmin() ? [ OPTIONS.EDIT, OPTIONS.DELETE ] : []; //change check to isSysAdmin
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}
