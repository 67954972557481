import { ModelSettings } from '@cxstudio/asset-management/configuration/settings-data/model-data';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceModelSettingsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxHttp: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.MODELS);
	}

	getModelSettings = (project: WorkspaceProject, modelId: number, cache: CacheOptions): AssetPromisePlatformProject<ModelSettings> => {
		return this.cachedHttp.get(this.getModelUrl(project, modelId), { cache }) as AssetPromisePlatformProject<ModelSettings>;
	}

	saveModelSettings = (project: WorkspaceProject, modelId: number, settings: ModelSettings): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getModelUrl(project, modelId), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	}

	private getModelUrl = (project: WorkspaceProject, modelId: number): string => {
		return `rest/settings/ws/${project.workspaceId}/project/${project.projectId}/model/${modelId}`;
	}
}
