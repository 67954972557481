import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';

export class PreviewVisualProperties extends VisualProperties {
	bubbleColumns?: number;
	itemsPerPage?: number;
	sentimentHighlightingEnabled?: boolean;
	columns?: Array<TableColumn<PreviewSentence>>;
	sections?: {[panelName: string]: ExplorerSectionGroup};
	sentencePaneEnabled?: boolean;
	contextPaneEnabled?: boolean;
	caseCreationEnabled?: boolean;
	tuningSuggestionsEnabled?: boolean;
	preferences?: any;
	showEmptyAttributes?: boolean;
}
