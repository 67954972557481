import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Directive, Input } from '@angular/core';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';

@Directive()
export abstract class ModalBindings<T> implements IModalComponent<any> {
	@Input() input: T;

	constructor(private readonly modal: NgbActiveModal) {}

	public close<R>(result?: R): void {
		this.modal.close(result);
	}

	public dismiss(reason?: string): void {
		this.modal.dismiss(reason);
	}
}
