import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface IImageGalleryDialogParams {
	properties: ContentWidgetProperties;
	dashboardId: number;
}

@Component({
	selector: 'image-gallery-dialog',
	template: `
<modal-header
	[modalTitle]="'templates.imageButton' | i18n"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body p-0">
	<image-gallery
		[properties]="input.properties"
		[dashboardId]="input.dashboardId">
	</image-gallery>
</div>

<save-modal-footer
	[saveText]="'common.ok' | i18n"
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="isSaveDisabled()">
</save-modal-footer>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGalleryDialogComponent implements IModalComponent<IImageGalleryDialogParams> {

	@Input() input: IImageGalleryDialogParams;

	constructor(
		private modal: NgbActiveModal,
		@Inject('urlService') private urlService: UrlService,
	) { }

	isSaveDisabled(): boolean {
		return !this.input.properties.imageName && !this.input.properties.imageUrl;
	}

	save(): void {
		this.input.properties.imageUrl = this.urlService.ensureValidImageUrl(this.input.properties.imageUrl);
		this.modal.close(this.input.properties);
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
