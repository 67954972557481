import { Component, ChangeDetectionStrategy, OnInit} from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

@Component({
	selector: 'project-selection-dialog',
	templateUrl: './project-selection-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSelectionDialogComponent implements OnInit {
	value: IProjectSelection;

	projectPreselection: IProjectPreselection;
	projectsLoading: Promise<any>;
	contentProviderErrors: string[];

	isWorkspaceEnabled: boolean;

	constructor(
		private betaFeaturesService: BetaFeaturesService
	) {}

	ngOnInit(): void {
		this.projectPreselection = {cbContentProvider: -1, cbAccount: -1, project: -1, workspaceId: -1};
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	onProjectSelectionChange(projectPreselection: IProjectPreselection) {
		this.projectPreselection = projectPreselection;
		this.checkProjectSelection();
	}

	onProjectsLoading(loadingPromise: Promise<any>) {
		this.projectsLoading = loadingPromise;
	}

	onCpErrorsChange(errors: string[]) {
		this.contentProviderErrors = errors;
	}

	onWorkspaceChange(workspace: Unit): void {
		if (workspace) {
			this.projectPreselection.cbContentProvider = workspace.contentProviderId;
			this.projectPreselection.cbAccount = workspace.accountId;
			this.projectPreselection.workspaceId = workspace.id;
		} else {
			this.projectPreselection.cbContentProvider = -1;
			this.projectPreselection.cbAccount = -1;
			this.projectPreselection.workspaceId = -1;
		}
	}

	onProjectChange(workspaceProject: WorkspaceProjectData): void {
		this.projectPreselection.project = workspaceProject.projectId;
		this.projectPreselection.projectName = workspaceProject.projectName;
		this.checkProjectSelection();
	}

	private checkProjectSelection(): void {
		if (this.projectPreselection.project > -1) {
			this.value = {
				contentProviderId: this.projectPreselection.cbContentProvider,
				accountId: this.projectPreselection.cbAccount,
				projectId: this.projectPreselection.project,
				projectName: this.projectPreselection.projectName,
				workspaceId: this.projectPreselection.workspaceId
			};
		} else {
			this.value = undefined;
		}
	}
}
