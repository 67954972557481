import { Inject, Injectable } from '@angular/core';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectsApi } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-projects.api.service';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { WorkspaceAccess } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access';
import { WorkspaceAccessEditorState } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access-editor-state.class';
import { Security } from '@cxstudio/auth/security-service';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceAccessManager {
	private targetUserId: number;
	private currentLicense: LicenseTypeItem;
	private sourceUserId?: number;

	private workspaces: Unit[];
	private workspacesAccess: WorkspaceAccessEditorState[];

	constructor(
		@Inject('security') private readonly security: Security,
		private readonly workspaceProjectsApi: WorkspaceProjectsApi,
	) {}

	init(workspaces: Unit[], targetUserId: number, initiaLicense: LicenseTypeItem, sourceUserId?: number): Promise<void> {
		this.workspaces = workspaces;
		this.targetUserId = targetUserId;
		this.currentLicense = initiaLicense;
		this.sourceUserId = sourceUserId;

		this.workspacesAccess = [];
		let promises = this.workspaces.map(ws => this.getWorkspaceAccess(ws.id));
		return Promise.all(promises)
			.then(results => {
				this.workspacesAccess = results;
			});
	}

	changeLicense(license: LicenseTypeItem): void {
		this.workspacesAccess.forEach(wa => wa.setLicense(license));
	}

	getWorkspaceAccess(workspaceId: number): Promise<WorkspaceAccessEditorState> {
		let workspace = this.workspaces.find(ws => ws.id === workspaceId);
		let access = this.getLoadedWorkspaceAccess(workspaceId);
		if (access) {
			return Promise.resolve(access);
		}
		return Promise.all([this.getEditorAccess(workspaceId), this.getTargetAccess(workspaceId)])
			.then(results => {
				let editorAccess = results[0];
				let targetAccess = results[1];
				return new WorkspaceAccessEditorState(workspace, editorAccess, targetAccess,
					this.currentLicense, this.isUserCopy());
			}, (error: string) => {
				return new WorkspaceAccessEditorState(workspace, undefined, undefined,
					this.currentLicense, this.isUserCopy(), error);
			});
	}

	private getLoadedWorkspaceAccess(workspaceId: number): WorkspaceAccessEditorState | undefined {
		return this.workspacesAccess.find(wa => wa.getWorkspace().id === workspaceId);
	}

	private getEditorAccess(workspaceId: number): Promise<WorkspaceAccess> {
		if (this.canProvideAnyAccess()) {
			return this.workspaceProjectsApi.getProjects(workspaceId).then(projects => {
				let projectsAccess = projects.map((project) => {
					return {
						project,
						accessLevel: ProjectAccessLevelValue.MANAGER
					};
				});
				return {
					workspaceId,
					accountAdmin: true,
					liteUser: false,
					projects: projectsAccess
				};
			});
		} else {
			let currentUser = this.security.getUser();
			return this.workspaceProjectsApi.getProjectsAccess(workspaceId, currentUser.userId);
		}
	}

	private canProvideAnyAccess(): boolean {
		return this.security.isAnyAdmin() || this.security.isCustomerAdmin();
	}

	private isNewUser(): boolean {
		return !this.targetUserId;
	}

	private isUserCopy(): boolean {
		return this.isNewUser() && !_.isUndefined(this.sourceUserId);
	}

	private getTargetAccess(workspaceId: number): Promise<WorkspaceAccess> {
		if (this.isUserCopy()) {
			return this.workspaceProjectsApi.getProjectsAccess(workspaceId, this.sourceUserId);
		}
		if (this.isNewUser()) {
			return Promise.resolve({} as WorkspaceAccess);
		}
		return this.workspaceProjectsApi.getProjectsAccess(workspaceId, this.targetUserId);
	}

	getTargerAccessUpdates(): WorkspaceAccess[] {
		return this.workspacesAccess
			.filter(wa => wa.isChanged())
			.map(wa => wa.getTargetState());
	}

	update(workspaceAccess: WorkspaceAccess): void {
		let workspaceEditorState = this.getLoadedWorkspaceAccess(workspaceAccess.workspaceId);
		workspaceEditorState.setTargetState(workspaceAccess);
	}
}
