import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterMultiValue } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-multi-value';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';

@Injectable({
	providedIn: 'root'
})
export class WidgetSavedDashboardFiltersProcessorService extends WidgetDashboardFilterProcessor {

	constructor(
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('ignoredDashboardFilterService') private ignoredDashboardFilterService: IgnoredDashboardFilterService) {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		let widgetProperties = filterApplication.widgetSettings.properties;

		let savedFilter = _.find(filterApplication.filtersProvider.getAppliedFilters(), filter => {
			return DashboardFilterSelection.isSavedFilter(filter.selectedAttribute);
		}) as DashboardFilter;

		if (!savedFilter || _.isEmpty(savedFilter.multiValues)) {
			return PromiseUtils.old(Promise.resolve());
		}

		if (this.ignoredDashboardFilterService.isIgnoredDashboardFilter(widgetProperties,
				filterApplication.filtersProvider.getDashboard().properties, savedFilter)) {
			return PromiseUtils.old(Promise.resolve());
		}

		let multiValues = this.dashboardFiltersService.getNestedSelectedChildren(savedFilter.multiValues) as DashboardFilterMultiValue[];
		let widgetFilters = multiValues.filter(filterValue => {
			// when documentLevelOnly - only allow scorecard filters
			return !widgetProperties.documentLevelOnly || filterValue.object.type === FilterTypes.CXSCORECARD;
		}).map(filterValue => {
			let filterProps = filterValue.object;

			if (filterProps.type === FilterTypes.PREDEFINED) {
				return filterProps;
			} else if (filterProps.type === FilterTypes.CXSCORECARD) {
				let scorecardFilter = {
					type: FilterTypes.SCORECARD,
					name: filterProps.name,
					uniqueId: filterProps.uniqueId,
					scorecardId: filterProps.scorecardId,
					passing: filterProps.passing
				};
				return scorecardFilter;
			} else {
				let newType = filterProps.type === FilterTypes.CXSTUDIO ? FilterTypes.STUDIO : FilterTypes.CMP;
				if (filterProps.contentProviderId === widgetProperties.contentProviderId + ''
					&& filterProps.accountId === widgetProperties.accountId + ''
					&& filterProps.projectId === widgetProperties.project + '') {
					//CMP vs studio filters
					let filterId = filterProps.filterId ? filterProps.filterId : filterProps.id;
					return { type: newType, filterId: filterId + '', name: filterProps.name };
				} else {
					return undefined;
				}
			}
		}).filter(item => !!item);

		if (!widgetProperties.appliedFilters) {
			widgetProperties.appliedFilters = { type: FilterTypes.AND, filters: [] };
		}
		widgetProperties.appliedFilters.filters.pushAll(widgetFilters);

		return PromiseUtils.old(Promise.resolve());
	}
}
app.service('widgetSavedDashboardFiltersProcessor', downgradeInjectable(WidgetSavedDashboardFiltersProcessorService));
