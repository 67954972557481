import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { LocalEventBus } from '@app/core/local-event-bus.service';
import { SimpleWidgetBaseComponent } from '@app/modules/widget-container/simple-widgets/simple-widget-base';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'page-break-widget',
	templateUrl: './page-break-widget.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LocalEventBus],
})
export class PageBreakWidgetComponent extends SimpleWidgetBaseComponent implements OnInit  {
	@Input() widget: ContentWidget;
	@Input() widgetActions: IWidgetActions;

	WidgetAction = WidgetAction;

	constructor(
		private readonly ref: ChangeDetectorRef,
		readonly localEventBus: LocalEventBus,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		private readonly currentObjects: CurrentObjectsService,
	) {
		super(localEventBus);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.addSubscription(this.currentObjects.getEditModeChange().subscribe(() => this.ref.markForCheck()));
	}

	protected redrawWidget(): void {}

	isPdfExport(): boolean {
		return !!this.$rootScope.pdfToken;
	}

	isEditMode(): boolean {
		return this.currentObjects.isEditMode();
	}
}

app.directive('pageBreakWidget', downgradeComponent({component: PageBreakWidgetComponent}) as angular.IDirectiveFactory);

