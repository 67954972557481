import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackConfig } from './secondary-track-config.class';

export class CircleGenerator {
	static draw(dataArray: ConversationDataPoint[], shapeConfig: SecondaryTrackConfig, targetSvg): void {
		let centerX: number = shapeConfig.leftBoundary + (shapeConfig.trackWidth / 2);

		targetSvg.selectAll('circle')
			.data(dataArray)
			.enter()
			.append('circle')
			.attr('class', shapeConfig.getPointClass)
			.attr('r', (shapeConfig.shapeWidth / 2))
			.attr('cx', centerX)
			.attr('cy', data => data.yPosition + (data.height / 2))
			.on('mouseover', shapeConfig.mouseover)
			.on('mouseout', shapeConfig.mouseout)
			.on('click', shapeConfig.click);
	}
}