

export enum DashboardFilterTypes {
	ATTRIBUTE = 'attribute',
	METRIC = 'metric',
	DATE_RANGE = 'dateRange',
	SATSCORE = 'satScore',
	SAVED_FILTER = 'savedFilter',
	TOPIC = 'topic',
	TEXT = 'text'
}


