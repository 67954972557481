
export enum BrokerType {
	ACTIVEMQ = 'ACTIVEMQ', 
	KAFKA = 'KAFKA'
}

export interface Destination {
	type: string;
	name: string;
}

export interface ContentProviderMessageingConfig {
	implementation: BrokerType;
	connectionInfo;
	destinations: Destination[];
}

export interface ContentProviderServerContext {
	serverVersion: string;
	apiVersion: string;
	featureVersion?: string;
	messagingConfig: ContentProviderMessageingConfig;
}
