import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { PasswordPolicy } from '@app/modules/account-administration/password-policy/entities/password-policy';
import { PasswordState } from '@app/modules/user/new-password-form/password-state';
import { PasswordStrength, PasswordStrengthCalculator } from '@app/modules/user/password-strength/password-strength-calculator.class';
import { ChangeUtils } from '@app/util/change-utils';

@Component({
	selector: 'password-strength',
	templateUrl: './password-strength.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
	@Input() passwordState: PasswordState;
	@Input() passwordPolicy: PasswordPolicy;

	private strengthCalculator: PasswordStrengthCalculator;
	private strength: PasswordStrength;

	constructor(
		private readonly locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.strengthCalculator = new PasswordStrengthCalculator(this.passwordPolicy.requireSpecialChars);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.passwordState)) {
			this.strength = this.strengthCalculator.getStrength(this.passwordState);
		}
	}

	getStrengthText(): string {
		switch (this.strength) {
			case PasswordStrength.EXCELLENT: return this.locale.getString('administration.excellent');
			case PasswordStrength.GOOD: return this.locale.getString('administration.good');
			case PasswordStrength.FINE: return this.locale.getString('administration.fine');
			case PasswordStrength.NONE: return '';
			default: return this.locale.getString('administration.tooWeak');
		}
	}

	getStrengthLineColorClass(): string {
		const strength = this.strength;
		if (strength >= PasswordStrength.FINE) {
			return 'bg-success';
		} else if (strength >= PasswordStrength.VERY_WEAK) {
			return 'bg-danger';
		} else {
			return '';
		}
	}

	getStrengthLineWidth(): string {
		const maxStrength = PasswordStrength.EXCELLENT;
		return (this.strength / maxStrength * 100) + '%';
	}
}
