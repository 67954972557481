import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Unit } from '@app/modules/units/unit';
import { UnitsApi } from '@app/modules/units/units.api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


interface UnitOption extends Unit {
	displayName?: string;
}

@Component({
	selector: 'units-settings-integration',
	templateUrl: './units-settings-integration.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UnitsSettingsIntegrationComponent), multi: true},
	],
})
export class UnitsSettingsIntegrationComponent implements OnInit, ControlValueAccessor {

	@Input() masterAccountId: number;
	@Input() masterAccountName: string;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: number[]) => void = _.noop;

	loading: Promise<any>;

	availableWorkspaces: Unit[];
	selectedWorkspaces: Unit[];

	values: number[];

	constructor(
		private unitsApi: UnitsApi,
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.loadWorkspaces();
	}

	private loadWorkspaces(): void {
		let masterAccountsUnitsPromise = this.masterAccountId
			? this.unitsApi.getMasterAccountUnits(this.masterAccountId)
			: Promise.resolve([]);

		this.loading = Promise.all([
			this.unitsApi.getAllAvailableUnits(),
			masterAccountsUnitsPromise
		]).then(results => {
			this.availableWorkspaces = this.prepareWorkspaces(results[0]);
			this.selectedWorkspaces = this.prepareWorkspaces(results[1]);
		});
	}

	updateSelectedWorkspaces = (workspaces: Unit[]): void => {
		this.selectedWorkspaces = workspaces;
		this.values = _.pluck(this.selectedWorkspaces, 'id');
		this.onTouchedCallback();
		this.onChangeCallback(this.values);
	}

	getAvailableWorkspacesTextCallback = (): string => this.locale.getString('mAccount.findWorkspaces');

	getSelectedWorkspacesTextCallback = (): string => this.locale.getString('mAccount.findSelectedWorkspaces');

	private prepareWorkspaces = (workspaces: Unit[]): UnitOption[] => {
		return workspaces.map(workspace => {
			return {
				id: workspace.id,
				contentProviderId: workspace.contentProviderId,
				accountId: workspace.accountId,
				name: workspace.name,
				displayName: workspace.name
			} as UnitOption;
		});
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.values) {
			this.values = value;
			this.ref.markForCheck();
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
