import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MetricDisplayValue } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';

@Component({
	selector: 'metric-label',
	template: `
	<div class="d-flex flex-direction-column text-center">
		<div class="kb-focusable">
			<span class="metric-title">{{label}}</span>
			<metric-value class="metric-value nowrap" [metricValue]="value"></metric-value>
		</div>
		<span *ngIf="difference" class="metric-difference nowrap kb-focusable" [ngClass]="colorClass">
			<span *ngIf="directionSymbol" class="q-icon">{{directionSymbol}}</span>
			{{difference}}
		</span>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricLabelComponent {
	@Input() colorClass: string;
	@Input() directionSymbol: string;
	@Input() value: MetricDisplayValue;
	@Input() difference: string;
	@Input() label: string;
}
