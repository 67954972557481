import * as cloneDeep from 'lodash.clonedeep';

import { Injectable, Inject } from '@angular/core';
import { ModelIdentifier } from '@cxstudio/projects/model-identifier';
import { MetricWidget } from '@app/modules/widget-settings/entities/widgets/metric-widget.class';
import { MetricWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/metric-widget-visual-properties.class';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { CxLocaleService } from '@app/core';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { GlobalOtherMetric, GlobalOtherMetricName } from '@cxstudio/metrics/entities/global-other-filter-metric-definition.class';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { Security } from '@cxstudio/auth/security-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import AnalyticWidget from '@app/modules/widget-settings/entities/widgets/analytic-widget.class';
import { DualChartWidget } from '@app/modules/widget-settings/entities/widgets/dual-chart-widget.class';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';

@Injectable({
	providedIn: 'root'
})
export class WidgetBuilderService {

	readonly DOC_DATE_ATTRIBUTE = '_doc_time';

	constructor(
			private locale: CxLocaleService,
			@Inject('metricConstants') private metricConstants: MetricConstants,
			@Inject('DateRange') private DateRange,
			@Inject('timePrimaryGroups') private timePrimaryGroups: TimePrimaryGroupsService,
			@Inject('security') private security: Security) {}

	getGlobalOtherExplorerWidgets(modelIdentifier: ModelIdentifier, modelTree: ModelTree, hasRootNode: boolean): AnalyticWidget[] {
		let defaultDateRange = { dateFilterMode: this.DateRange.options.LAST_90_DAYS.value,
			dateDisplayName: this.locale.getString('dateRange.last90d') } as DateFilter;

		let defaultHistoricDateRange = { dateFilterMode: this.DateRange.historicOptions.SAME_LAST_YEAR.value,
			dateDisplayName: this.locale.getString('dateRange.sameLastYear') } as DateFilter;

		let globalOtherPercentMetric = {
			useDefaultFormat: true,
			name: 'globalOtherPercentMetric',
			displayName: this.locale.getString('widgetAttrs.globalOtherPercentMetric'),
			type: ReportAssetType.GLOBAL_OTHER,
			definition: new GlobalOtherMetric(GlobalOtherMetricName.GLOBAL_OTHER_PARENT_PERCENT, modelIdentifier.modelId)
		} as ReportCalculation;

		let globalOtherVolumeMetric = {
			useDefaultFormat: true,
			name: 'globalOtherVolumeMetric',
			displayName: this.locale.getString('widgetAttrs.globalOtherVolumeMetric'),
			type: ReportAssetType.GLOBAL_OTHER,
			definition: new GlobalOtherMetric(GlobalOtherMetricName.GLOBAL_OTHER_VOLUME, modelIdentifier.modelId)
		} as ReportCalculation;

		let metricWidget: AnalyticWidget = MetricWidget.get()
			.withId(-1)
			.withProjectSelection(modelIdentifier.project)
			.withPeriods(defaultDateRange)
			.withCalculations([globalOtherPercentMetric])
			.withRunAs(this.security.getEmail())
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString('widget.globalMetricTitle'))
			.withVisualProperties(new MetricWidgetVisualProperties());

		let chartWidget: AnalyticWidget = new DualChartWidget()
			.withCalculations([globalOtherPercentMetric, globalOtherVolumeMetric])
			.withTimeGrouping(this.getTimeGrouping(this.timePrimaryGroups.WEEK))
			.withId(-2)
			.withProjectSelection(modelIdentifier.project)
			.withPeriods(defaultDateRange, defaultHistoricDateRange)
			.withRunAs(this.security.getEmail())
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString('widget.trendLabel'));
		chartWidget.linkedWidgets = [-3];

		if (hasRootNode) {
			let modelRootNodeFilter: AdhocFilter = {
				type: FilterTypes.AND,
				filterRules: [{
					type: FilterRuleType.topicEquality,
					topicId: modelIdentifier.modelId,
					displayName: `${this.locale.getString('reportFilters.rootNodeFilter')}: ${modelIdentifier.modelName}`,
					modelMatch: true,
					matchMode: FilterMatchModeValue.IS,
					values: [{
						idPath: `${modelTree.root.id}`,
						nodeId: modelTree.root.id
					}]
				}]
			};

			metricWidget.properties.adhocFilter = modelRootNodeFilter;
			chartWidget.properties.adhocFilter = modelRootNodeFilter;
		}

		this.adjustChartVisualProperties(chartWidget);

		let tableWidget = this.getTableWidget(modelIdentifier, defaultDateRange, defaultHistoricDateRange);

		return [metricWidget, chartWidget, tableWidget];
	}

	adjustChartVisualProperties = (chartWidget: AnalyticWidget): void => {
		chartWidget.visualProperties.globalOtherExplorer = true;
		chartWidget.visualProperties.showSampleSize = false;
	}

	getTableWidget = (modelIdentifier: ModelIdentifier, dateRange: DateFilter, historicDateRange: DateFilter): AnalyticWidget => {
		let globalOtherFilter: AdhocFilter = {
			type: FilterTypes.AND,
			filterRules: [{
				type: FilterRuleType.topicGlobalOther,
				topicId: modelIdentifier.modelId,
				displayName: modelIdentifier.modelName
			}]
		};
		let widget = new AnalyticWidget();
		widget.name = WidgetType.TABLE;
		widget.properties = {} as WidgetProperties;
		widget.properties.widgetType = WidgetType.TABLE;
		widget.visualProperties = new VisualProperties();
		widget.visualProperties.visualization = WidgetVisualization.CB_AN_TABLE;
		widget
			.withId(-3)
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString('widget.globalTableTitle'))
			.withRunAs(this.security.getEmail())
			.withCalculations([this.metricConstants.get().VOLUME])
			.withGroupings([_.extend({ size: 50 }, this.metricConstants.get().WORDS)])
			.withProjectSelection(modelIdentifier.project)
			.withPeriods(dateRange, historicDateRange);
		widget.properties.adhocFilter = globalOtherFilter;
		widget.linkedTo = [-2];
		return widget;
	}

	getTimeGrouping = (timeGroup: any): any => {
		return _.extend(cloneDeep(timeGroup), {
			attributeName: this.DOC_DATE_ATTRIBUTE,
			timeName: timeGroup.name,
			name: `${this.DOC_DATE_ATTRIBUTE}.${timeGroup.name}`,
			identifier: `${this.DOC_DATE_ATTRIBUTE}.${timeGroup.name}`,
			emptyPeriodType: EmptyPeriodType.SHOW_ALL
		});
	}

}

app.service('widgetBuilderService', downgradeInjectable(WidgetBuilderService));
