import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import * as _ from 'underscore';
import { ContextMenuBaseController } from './context-menu-base-controller';


export class ScorecardSelectMenu extends ContextMenuBaseController {

	constructor(
		contextMenuTree: ContextMenuTree,
		$element: ng.IAugmentedJQuery,
		$timeout: ng.ITimeoutService
	) {
		super(contextMenuTree, $element, $timeout);
	}

}

app.component('scorecardSelectMenu', {
	controller: ScorecardSelectMenu,
	bindings: {
		options: '<',
		object: '<',
		posX: '<',
		posY: '<',
		onMenuClose: '&'
	},
	templateUrl: 'partials/context-menu/scorecard-select-menu.component.html'
});
