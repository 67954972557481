import { Injectable } from '@angular/core';
import { CxHttpService, CxLocaleService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { HttpPromise } from '@app/core/http-promise';

@Injectable({
	providedIn: 'root'
})

export class SamlIdentityProviderApiService {
	readonly IDENTITY_PROVIDER_ENDPOINT = 'rest/saml/identity-provider';

	constructor(
		private cxHttp: CxHttpService,
		private locale: CxLocaleService,
	) {}

	getIdentityProviders(): Promise<SamlIdentityProvider[]> {
		return this.cxHttp.get(`${this.IDENTITY_PROVIDER_ENDPOINT}/list`);
	}

	createIdentityProvider(identityProvider: SamlIdentityProvider): Promise<void> {
		return this.cxHttp.post(this.IDENTITY_PROVIDER_ENDPOINT, identityProvider);
	}

	updateIdentityProvider(identityProvider: SamlIdentityProvider): Promise<void> {
		let config = this.getEntityIdConfig(identityProvider.entityId);
		return this.cxHttp.put(`${this.IDENTITY_PROVIDER_ENDPOINT}`, identityProvider, config);
	}

	deleteIdentityProvider(entityId: string): Promise<void> {
		let config = this.getEntityIdConfig(entityId);
		return this.cxHttp.delete(this.IDENTITY_PROVIDER_ENDPOINT, config);
	}

	downloadMetadataFile(entityId: string): HttpPromise<any> {
		let config = this.getFileRequestConfig(entityId);
		return this.cxHttp.getResponse('rest/saml/identity-provider/metadata/download', config);
	}

	removeMetadataFile(filename: string): Promise<void> {
		let config = this.getMetadataFilenameConfig(filename);
		return this.cxHttp.delete('rest/saml/identity-provider/metadata', config);
	}

	private getMetadataFilenameConfig(filename: string): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams()
				.append('filename', filename)
		};
	}

	private getEntityIdConfig(entityId: string): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams()
				.append('entityId', entityId)
		};
	}

	private getFileRequestConfig(entityId: string): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams({fromObject: {entityId}}),
			responseType: 'arraybuffer'
		};
	}
}
