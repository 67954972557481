<ng-template #smallLoader><div class="loader small"></div></ng-template>
<div *ngIf="showLoadMore()"
	class="paging-footer d-flex justify-between pt-8">
	<div *ngIf="getUpdatedText()" class="col-sm-6 d-flex align-items-center">
		{{getUpdatedText()}}
	</div>
	<div class="col-sm-6" *ngIf="!getUpdatedText()">
		<span [ngBusy]="{ busy: pagingSearch.pagingSearchingPromise, template: smallLoader }"
			class="p-relative"></span>
	</div>
	<div class="col-sm-7 d-flex justify-end">
		<button class="btn btn-secondary ignore-dropdown-style"
			(click)="loadMore();"
			(keydown.tab)="onFocusMove.emit(); $event.stopPropagation();"
			[disabled]="!canLoadMore()">
			{{getLoadMoreText()}}
		</button>
	</div>
</div>
