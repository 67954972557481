import * as _ from 'underscore';


export interface IElementComposition {
	header?: ng.IAugmentedJQuery;
	body: ng.IAugmentedJQuery;
	footer?: ng.IAugmentedJQuery;
}

export class ElementUtils {

	constructor(
		private $timeout: ng.ITimeoutService
	) { }

	private appendIfPresent(element: ng.IAugmentedJQuery, addition: any): void {
		if (addition) {
			element.append(addition);
		}
	}

	compose = (element: ng.IAugmentedJQuery, composition: IElementComposition): void => {
		element.empty();
		this.appendIfPresent(element, composition.header);
		this.appendIfPresent(element, composition.body);
		this.appendIfPresent(element, composition.footer);
	}

	composeWithDefaultFooter = (element: ng.IAugmentedJQuery, composition: IElementComposition): void => {
		composition.footer = this.buildDefaultFooter();
		this.compose(element, composition);
	}

	private buildDefaultFooter(): ng.IAugmentedJQuery {
		return angular.element(
			'<div style="width:100%;height:30px;position:absolute;bottom:0px"></div>'
		);
	}

	sizeOf = (element: Element): string => {
		return element.clientWidth + 'x' + element.clientHeight;
	}

	scrollTo = (elementId: string, callback?: () => void): void => {
		this.scrollToSelector('#' + elementId, callback);
	}

	private scrollToSelector = (selector: string, callback?: () => void): void => {
		this.$timeout(() => {
			let toSearch = $(selector);
			if (toSearch.length > 0) {
				toSearch[0].scrollIntoView();
			}
			if (callback) {
				callback();
			}
		}, 50);
	}

}

app.service('elementUtils', ElementUtils);
