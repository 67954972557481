import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-type.enum';
import { TemplatePlaceholderPlacements } from '@cxstudio/dashboard-templates/template-placeholder-placements';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'template-create-placeholders-list',
	templateUrl: './template-create-placeholders-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCreatePlaceholdersListComponent {
	@Input() placeholders: any[];
	@Input() groupTitle: string;
	@Input() type: string;
	@Input() showPlacement: boolean;

	constructor(
		private readonly locale: CxLocaleService
	) {}

	getPlacementDescription(widget): string {
		let itemTypeDisplayName;

		if (TemplatePlaceholderPlacements.PRIMARY === widget.placement) {
			itemTypeDisplayName = this.getItemTypeDisplayName();
		} else if (TemplatePlaceholderPlacements.GROUPING_SORT_METRIC === widget.placement) {
			itemTypeDisplayName = this.locale.getString('templates.groupingSortMetric');
		} else if (TemplatePlaceholderPlacements.SELECTION === widget.placement) {
			itemTypeDisplayName = this.locale.getString(`templates.${widget.name}Selection`);
		}

		return this.locale.getString('templates.placeholderItemUsedAsInWidget', {
			itemTypeDisplayName,
			widgetDisplayName: widget.displayName
		});
	}

	private getItemTypeDisplayName(): string {
		return this.type === TemplatePlaceholderType.GROUPING
			? this.locale.getString('widget.grouping')
			: this.locale.getString('metrics.calculation');
	}

	showPopover(placeholder, popover: NgbPopover): void {
		popover.open({placeholder});
	}

	hidePopover(popover: NgbPopover): void {
		popover.close();
	}
}
