import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { NetworkErrorStatuses } from '../error_handlers/network-error-statuses.constant';

/**
 * Modify rest url, add custom headers
 */
app.factory('NetworkErrorHandler', (
	$q, $injector, RequestRepeater, globalNotificationService: GlobalNotificationService, interval) => {

	const safeRequests = {
		get: /.*/,
		put: /.*/,
		delete: /.*/,
		post: /.*common\/(reportData).*/
	};

	return class NetworkErrorHandler {
		handleError = (response): boolean => {
			let locale = $injector.get('locale');
			let errorDialogService: ErrorDialogService = $injector.get('errorDialogService');

			interval.stopAllIntervals();

			let method = response.config.method.toLowerCase();

			// check if we can retry it
			if (safeRequests[method] && response.config.url.match(safeRequests[method])) {
				if (!this.isIgnored(response))
					globalNotificationService.showWarning(locale.getString('common.networkError'), 0);
				if (!response.config.retryPolicy) {
					response.config.retryPolicy = new RequestRepeater(response.config);
				}
				let result = response.config.retryPolicy.retry();
				if (result !== false) {
					return result.then((newResponse) => {
						if (!this.isIgnored(newResponse))
							globalNotificationService.showWarning(locale.getString('common.networkRestored'), 5);
						interval.startAllIntervals();
						return $q.when(newResponse);
					}, $q.reject);
				} else {
					response.config.retryPolicy = new RequestRepeater(response.config, 120000, Number.POSITIVE_INFINITY);
					globalNotificationService.showWarning(locale.getString('common.networkError'));
					errorDialogService.error(locale.getString('common.networkCannotRestore'));
					return response.config.retryPolicy.retry().then($q.when, $q.reject);
				}
			} else {
				errorDialogService.error(locale.getString('common.networkFailedOperation'));
				return $q.reject(response);
			}
		}

		support = (status): boolean => _.contains(NetworkErrorStatuses, status);

		private isIgnored(response): boolean {
			return response.config.ignoreConnectionErrors === true;
		}
	};

});
