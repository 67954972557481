import { NgModule } from '@angular/core';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { BetaFeaturesChecklistComponent } from '@app/modules/system-administration/master-account/beta-features-checklist/beta-features-checklist.component';
import { AccountOwnersInputComponent } from '@app/modules/system-administration/master-account/inputs/accountOwners/account-owners-input.component';
import { MasterAccountDisableDialogComponent } from '@app/modules/system-administration/master-account/master-account-disable-dialog/master-account-disable-dialog.component';
import { MasterAccountEditDialogComponent } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-edit-dialog.component';
import { MasterAccountExpirationDialogComponent } from '@app/modules/system-administration/master-account/master-account-expiration-dialog/master-account-expiration-dialog.component';
import { MasterAccountsTableComponent } from '@app/modules/system-administration/master-account/master-accounts-table/master-accounts-table.component';
import { MasterAccountsGridDefinition } from '@app/modules/system-administration/master-account/master-accounts-table/miscellaneous/master-accounts-grid-definition.service';
import { SystemAdministrationModule } from '@app/modules/system-administration/system-administration.module';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { SharedModule } from '@app/shared/shared.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { NgPipesModule } from 'ngx-pipes';
import { BetaFeaturesChecklistGridDefinition } from './beta-features-checklist/beta-features-checklist-grid-definition.service';
import { EmailDomainsComponent } from './email-domains/email-domains.component';
import { MasterAccountAdminPropertiesComponent } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-admin-properties.component';
import { MasterAccountLicensingComponent } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-licensing.component';
import { MasterAccountFormDataConverter } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-form-data-converter.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MasterAccountHierarchySettingsComponent } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-hierarchy-settings.component';



@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		ItemGridModule,
		SystemAdministrationModule,
		FormsModule,
		ReactiveFormsModule,
		WizardModule,
		CxFormModule,
		NgPipesModule
	],
	exports: [
		AccountOwnersInputComponent,
		MasterAccountsTableComponent,
		MasterAccountEditDialogComponent,
		MasterAccountDisableDialogComponent,
		MasterAccountExpirationDialogComponent,
		EmailDomainsComponent
	],
	declarations: [
		AccountOwnersInputComponent,
		BetaFeaturesChecklistComponent,
		MasterAccountsTableComponent,
		MasterAccountEditDialogComponent,
		MasterAccountDisableDialogComponent,
		MasterAccountExpirationDialogComponent,
		EmailDomainsComponent,
		MasterAccountAdminPropertiesComponent,
		MasterAccountLicensingComponent,
		MasterAccountHierarchySettingsComponent,
	],
	providers: [
		MasterAccountsGridDefinition,
		BetaFeaturesChecklistGridDefinition,
		MasterAccountFormDataConverter,
	],
})

export class MasterAccountsModule { }
