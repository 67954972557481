<div class="d-flex flex-direction-column" [ngBusy]="loading" data-testid="replacements">
	<div class="mb-16 ph-16">{{replacementsLabel}}</div>
	<ng-container *ngFor="let group of replacements | groupBy:'placeholderKey.type' | keyvalue">
		<div class="h-56 d-flex align-items-center">
			<label class="col-sm-4 font-semibold text-gray-1000 mb-0">{{getOriginalLabel(group.key)}}</label>
			<label class="col-sm-8 font-semibold text-gray-1000 mb-0">{{getReplacementLabel(group.key)}}</label>
		</div>
		<div *ngIf="!!resources"
			class="d-flex flex-direction-column">
			<div *ngFor="let replacement of group.value"
				class="form-group d-flex">
				<span class="col-sm-4 d-flex align-items-center">
					{{replacement.placeholderDescription}}
				</span>
				<span class="col-sm-8 align-items-center">
					<template-replacement-selector *ngIf="isStandardReplacementSelector(replacement)"
						[resources]="resources"
						[replacement]="replacement"
						[fullNodeReplacement]="shouldUseFullNodeReplacement(replacement)"
						(replacementChange)="applyReplacement(replacement, $event)">
					</template-replacement-selector>
					<template-replacement-filter-rule *ngIf="replacement.displayType === PlaceholderDisplayType.FILTER_RULE"
						[resources]="resources"
						[project]="project"
						[replacement]="replacement"
						(replacementChange)="applyReplacement(replacement, $event)">
					</template-replacement-filter-rule>
				</span>
			</div>
		</div>
	</ng-container>
</div>
