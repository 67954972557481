import { Directive, HostListener, Input } from '@angular/core';
import { AmplitudeAnalyticsService } from './amplitude-analytics.service';
import { AmplitudeEvent } from './amplitude-event';
import { AmplitudeGroups } from './amplitude-groups';

@Directive({
	selector: '[amplitudeEvent]'
})
export class AmplitudeEventDirective {

	@Input() amplitudeEvent: AmplitudeEvent;
	@Input() amplitudeGroup?: AmplitudeGroups;
	@Input() amplitudeEventData?: any;

	constructor() {}

	@HostListener('click', ['$event'])
	sendAmplitudeEvent() {
		AmplitudeAnalyticsService.trackEvent(this.amplitudeEvent, this.amplitudeGroup, this.amplitudeEventData);
	}
}
