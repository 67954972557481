<modal-header
	(cancel)="cancel()"
	modalTitle="{{'templates.editMappingsTitle' | i18n }}">
</modal-header>

<div class="modal-body" [ngBusy]="loading.save">
	<form #templateDialogForm="ngForm" class="form-horizontal" name="templateDialog" role="form">
		<div *ngIf="hasPlaceholders()">
			<p [i18n]="'templates.providePlaceholderDescriptions'"></p>
			<template-create-placeholders-list
				*ngIf="(model.placeholders.groupings | availablePlaceholders).length > 0"
				[placeholders]="model.placeholders.groupings"
				groupTitle="{{'templates.placeholderOriginalGroupings'|i18n}}"
				[type]="TemplatePlaceholderType.GROUPING"
				[showPlacement]="true">
			</template-create-placeholders-list>

			<template-create-placeholders-list
				*ngIf="(model.placeholders.calculations | availablePlaceholders).length > 0"
				[placeholders]="model.placeholders.calculations"
				groupTitle="{{'templates.placeholderOriginalCalculations'|i18n}}"
				[type]="TemplatePlaceholderType.CALCULATION"
				[showPlacement]="true">
			</template-create-placeholders-list>
		</div>
		<p *ngIf="!hasPlaceholders()" [i18n]="'templates.noCurrentMappings'"></p>
	</form>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'administration.save' | i18n"
	[isDisabled]="templateDialogForm.invalid">
</save-modal-footer>