import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DialogStyle } from '@app/modules/dialog/dialog-style';

@Component({
	selector: 'modal-header',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<header class="modal-header">
		<cb-modal-close class="kb-focusable" (click)="cancel.emit()"></cb-modal-close>
		<h2 class="confirm-title modal-title">{{modalTitle}}</h2>
	</header>
	`
})

export class ModalHeaderComponent {
	@Input() modalTitle: string;
	@Input() style: DialogStyle;
	@Output() cancel: EventEmitter<string> = new EventEmitter();
}

app.directive('modalHeader', downgradeComponent({component: ModalHeaderComponent}) as angular.IDirectiveFactory);
