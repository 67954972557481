import * as _ from 'underscore';

export interface ISelectUtils {
	visibleObjFilter: (item: any) => boolean;
	someObjectsVisible: () => boolean;
	selectAllObjects: (selectList: any[], selectAllFilter?: (item: any) => boolean) => void;
	showClearSelections: () => boolean;
	showSomeSelected: () => boolean;
	showAllSelected: () => boolean;
	multipleObjectsSelected: () => boolean;
	clearSelections: () => void;
	showSelectAll: () => boolean;
	isSupportedType: (item: any) => boolean;
	refreshFolderHighlighting: (folders: any[]) => any[];
	//unit test exposure, not used outside
	isListFiltered: () => boolean;
	getVisibleObjectCount: () => number;
	getSelectedObjectCount: () => number;
	countObjects: (list: any[], conditionalCountFn?: (item: any) => boolean ) => number;
	anyChildSelected: (node: any) => boolean;
	handleCtrlClick: (object: any) => void;
	handleCheckboxClick: (object: any, lastChecked: any, shiftKey: boolean) => void;
}

export abstract class BaseSelectUtils implements ISelectUtils {
	abstract getVisibleObjectsList: () => any[];
	abstract getObjectsList: () => any[];

	abstract isSupportedType: (item: any) => boolean;
	abstract getSearchFilter: () => string;
	abstract refreshObjects: (list: any[]) => void;
	abstract visibleObjFilter: (item: any) => boolean;

	getCurrentContextList = (): any[] => {
		return this.getObjectsList();
	}

	isListFiltered = (): boolean => {
		let searchFilter = this.getSearchFilter();
		return searchFilter && searchFilter.length > 0;
	}

	showAllSelected = (): boolean => {
		return this.getSelectedObjectCount() >= this.getVisibleObjectCount();
	}

	someObjectsVisible = (): boolean => {
		return this.getVisibleObjectCount() > 0;
	}

	showClearSelections = (): boolean => {
		return this.getSelectedObjectCount() > 0;
	}

	showSelectAll = (): boolean => {
		return this.someObjectsVisible();
	}

	showSomeSelected = (): boolean => {
		let selectedCount = this.getSelectedObjectCount();
		return (selectedCount > 0) && (selectedCount < this.getVisibleObjectCount());
	}

	getVisibleObjectCount = (): number => {
		let checkList = this.isListFiltered() ? this.getVisibleObjectsList() : this.getObjectsList();
		return this.countObjects(checkList, this.visibleObjFilter);
	}

	getSelectedObjectCount = (): number => {
		let checkList = this.isListFiltered() ? this.getVisibleObjectsList() : this.getObjectsList();
		return this.countObjects(checkList);
	}

	countObjects = (list: any[], conditionalCountFn?: (item: any) => boolean ): number => {
		let defaultCheckFn = (item): boolean => {
			return (this.isSupportedType(item) && item.selected);
		};

		conditionalCountFn = conditionalCountFn || defaultCheckFn;

		return _.filter(list, conditionalCountFn).length;
	}

	anyChildSelected = (node: any): boolean => {
		let defaultCheckFn = (item): boolean => {
			return (this.isSupportedType(item) && item.selected);
		};

		return defaultCheckFn(node) || _.some(node.children, this.anyChildSelected);
	}

	multipleObjectsSelected = (): boolean => {
		return this.countObjects(this.getObjectsList()) > 1;
	}

	clearSelections = (): void => {
		this.setObjectsSelected(0, this.getCurrentContextList().length - 1, false);
	}

	selectAllObjects = (selectList: any[], selectAllFilter?: (item: any) => boolean ): void => {
		let parentFolderIds: number[] = [];
		if (!selectList) {
			selectList = this.isListFiltered() ? this.getVisibleObjectsList() : this.getObjectsList();
		}

		if (selectAllFilter) {
			selectList = _.filter(selectList, selectAllFilter);
		}

		_.filter(selectList, this.visibleObjFilter)
			.map((item: any) => {
				item.selected = true;
				if (!_.isUndefined(item.parentId)) {
					parentFolderIds.push(item.parentId);
				} else if (!_.isUndefined(item.parent)) {
					parentFolderIds.push(item.parent.id);
				}
			});

		_.filter(selectList, (item: any) => item.children && item.children.length)
			.map((folderItem) => {
				this.selectAllObjects(folderItem.children);
			});

		this.highlightFolders(parentFolderIds, true);
		this.refreshObjects(this.getObjectsList());
	}

	highlightFolders = (parentFolderIds: number[], selected: boolean): any[] => {
		let foldersToRefresh = [];

		while (parentFolderIds.length > 0) {
			let folderId = parentFolderIds.shift();
			let folder: any = _.find(this.getObjectsList(), {id: folderId});

			if (folder) {
				if (folder.parentId) {
					parentFolderIds.push(folder.parentId);
				}

				if (selected) {
					folder._collapsed = false;
					folder.selected = true;
				} else {
					//If a child is being unchecked, check folder's other children to see if they are selected
					folder.selected = this.anyChildSelected(folder);
				}

				foldersToRefresh.push(folder);
			}
		}
		return foldersToRefresh;
	}

	refreshFolderHighlighting  = (folders: any[]): any[] => {
		let foldersToRefresh = [];
		while (folders.length > 0) {
			let folderId = folders.shift();
			if (_.isUndefined(folderId) || folderId === null) {
				continue;
			}
			if (folderId.id) {
				folderId = folderId.id;
			}
			let folder: any = _.find(this.getObjectsList(), {id: folderId});

			if (folder) {
				if (folder.parentId) {
					folders.push(folder.parentId);
				}
				let selected = this.anyChildSelected(folder);
				if (selected) {
					folder._collapsed = false;
					folder.selected = true;
				} else {
					folder.selected = false;
				}

				foldersToRefresh.push(folder);
			}
		}
		return foldersToRefresh;
	}

	setObjectsSelected = (startIndex: number, endIndex: number, selected: boolean, modifiedList?: any[]): void => {
		let parentFolderIds = [];
		let itemsToRefresh = [];
		let objectsList = modifiedList || this.getCurrentContextList();

		for (let i = startIndex; i <= endIndex; i++) {
			let selectedObject = objectsList[i];

			if (!selectedObject) {
				return;
			}

			//remove selection and update grid
			if (this.isSupportedType(selectedObject) && !selectedObject.hiddenViaFilter) {
				selectedObject.selected = selected;
				itemsToRefresh.push(selectedObject);
			}

			if (selectedObject.parent && selectedObject.parent != null && selectedObject.parent.id) {
				parentFolderIds.push(selectedObject.parent.id);
			}
		}

		let parentFolders = this.highlightFolders(_.uniq(parentFolderIds), selected);
		itemsToRefresh = itemsToRefresh.concat(parentFolders);

		this.refreshObjects(itemsToRefresh);
	}

	handleCtrlClick = (object: any): void => {
		let currentCheckedIndex = this.getCurrentContextList().indexOf(object);
		this.setObjectsSelected(currentCheckedIndex, currentCheckedIndex, !object.selected);
	}

	handleCheckboxClick = (object: any, lastChecked: any, shiftKey: boolean): void => {
		let currentCheckedIndex = this.getCurrentContextList().indexOf(object);
		let lastCheckedIndex = this.getCurrentContextList().indexOf(lastChecked);
		if (shiftKey && lastChecked && lastCheckedIndex >= 0) {
			let start = Math.min(currentCheckedIndex, lastCheckedIndex);
			let end = Math.max(currentCheckedIndex, lastCheckedIndex);
			this.setObjectsSelected(start, end, lastChecked.selected);
		} else {
			this.setObjectsSelected(currentCheckedIndex, currentCheckedIndex, !object.selected);
		}
	}
}
