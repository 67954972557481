import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { DocExplorerFilter } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { DocExplorerPreferences } from '@app/modules/document-explorer/preferences/doc-explorer-preferences.provider';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { IWidgetState } from '@app/modules/widget-container/widget-state.interface';
import { IPreviewWidgetFavorites } from './context-pane/doc-explorer-favorites.class';


@Directive({
	selector: 'widget-document-preview'
})
export class WidgetDocumentPreviewUpgrade extends UpgradeComponent {
	@Input() dashboard: Dashboard;
	@Input() widget: DocumentExplorerWidget;
	@Input() showSentiment: boolean;
	@Input() showSentences: boolean;
	@Input() showContext: boolean;
	@Input() showEmptyAttributes: boolean;
	@Input() panels: {[panelName: string]: ExplorerSectionGroup};
	@Input() filters: DocExplorerFilter;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() initDocumentManager: (documentManager: IDocumentPreviewerControls) => void;
	@Input() preferences: DocExplorerPreferences;
	@Input() docExplorerFavorites: IPreviewWidgetFavorites;
	@Input() sharingFeedbackSelection: IAnalyticFeedbackSelection;
	@Input() filteringFeedbackSelection: IAnalyticFeedbackSelection;
	@Input() createDashboardCallback: () => void;
	@Input() isDocExplorer: boolean;
	@Input() widgetState: IWidgetState;
	@Input() reloadStatistics: () => void;
	@Input() auditMode: boolean;
	@Input() rebuttalMode: boolean;
	@Input() selectedScorecard: number;
	@Input() showExportData: () => boolean;
	@Input() isStatsEnabled: () => boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('widgetDocumentPreview', elementRef, injector);
	}
}
