import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { AccountOption } from '@clarabridge/unified-angular-components';
import { ContextMasterAccount } from '@cxstudio/auth/context';
import { Security } from '@cxstudio/auth/security-service';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { Observable, of } from 'rxjs';
import * as _ from 'underscore';

export class AccountSwitcherComponent implements ng.IComponentController {

	impersonateActive = this.impersonateUserService.isImpersonateActive();
	masterAccounts: ContextMasterAccount[];
	dropdownStatus: {
		masterAccountSearchText?: string;
	} = {};

	currentMasterAccountId: number;
	currentMasterAccountName: string;
	accounts$: Observable<AccountOption[]>;

	constructor(
		private readonly impersonateUserService: ImpersonateUserService,
		private readonly redirectService: RedirectService,
		private readonly $timeout: ng.ITimeoutService,
		private readonly security: Security,
	) {}

	$onInit = () => {
		this.currentMasterAccountId = this.security.getMasterAccountId();
		this.currentMasterAccountName = this.security.getCurrentMasterAccount().accountName;

		this.accounts$ = of(this.masterAccounts.map(acct => (
			{
				name: acct.accountName,
				id: acct.accountId,
				...acct
			})));
	}

	// should return undefined if MA not loaded yet
	hasMultipleAccounts = (): boolean => {
		if (!this.masterAccounts || !this.masterAccounts.length)
			return undefined;
		let count = 0;
		for (let account of this.masterAccounts) {
			if (!account.disabled) {
				if (++count > 1)
					return true;
			}
		}
		return false;
	}

	saveCurrentAccount = (newMasterAccount) => {
		if (newMasterAccount.accountId !== this.currentMasterAccountId) {
			this.redirectService.saveCurrentMA(newMasterAccount);
		}
	}

	onAccountSwitcherKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.$timeout(() => {
				$('#account-switcher-menu :focusable').first().trigger('focus');
			});
		}
	}
}

app.component('accountSwitcher', {
	bindings: {
		masterAccounts: '<',
	},
	controller: AccountSwitcherComponent,
	template: `
	<cb-account-switcher
		class="border-color-inherit"
		(account-selected)="$ctrl.saveCurrentAccount($event)"
		[options-list$]="$ctrl.accounts$"
		ng-if="$ctrl.hasMultipleAccounts()">
		{{::$ctrl.currentMasterAccountName}}
	</cb-account-switcher>
`});
