import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

export class CalculationColorUtils {
	static readonly CALCULATION_COLOR_PREFIX = '_calculation_color.';

	static isCalculationColor(colorName: string): boolean {
		return colorName && colorName.indexOf(CalculationColorUtils.CALCULATION_COLOR_PREFIX) !== -1;
	}
	static getCalculationColorAttributeName(colorName: string): string {
		return colorName && colorName.substring(CalculationColorUtils.CALCULATION_COLOR_PREFIX.length, colorName.length);
	}
	static isSameCalculationColor(colorName: string, calculation: ReportCalculation): boolean {
		if (!CalculationColorUtils.isCalculationColor(colorName) || !calculation)
			return false;
		let name = CalculationColorUtils.getCalculationColorAttributeName(colorName);
		if (name.contains(PredefinedMetricConstants.SUBTYPE_3) || name.contains(PredefinedMetricConstants.SUBTYPE_5))
			name = name.replace(PredefinedMetricConstants.SUBTYPE_3, '').replace(PredefinedMetricConstants.SUBTYPE_5, '');
		return name === calculation.name;
	}
}
