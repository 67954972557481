import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DriversTarget } from '@cxstudio/drivers/entities/drivers-target';
import { DriversDefinition } from '@cxstudio/drivers/entities/drivers-definition';
import {DriversType} from '@cxstudio/drivers/entities/drivers-type';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { SharableAsset } from '@cxstudio/sharing/sharing-service.service';
import { DriversTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { Hideable } from '@cxstudio/common/entities/hideable.interface';

export enum DriversStatus {
	defined = 'defined',
	pending = 'pending',
	preparing = 'preparing',
	training = 'training',
	reportable = 'reportable'
}
export interface HiddenDriver {
	displayName: string;
	identifier: string;
}

export class DriversItem implements Partial<IProjectSelection>, SharableAsset, DriversTreeItem, Hideable {
	id?: number;
	name?: string;
	displayName?: string;
	definition?: DriversDefinition;
	target?: DriversTarget;
	minVolume?: number;
	hiddenDrivers?: HiddenDriver[]; 
	hide?: boolean;

	status?: DriversStatus;
	errorDetails?: string;
	lastRunDate?: string;
	masterAccountId?: number;
	accountId?: number;
	contentProviderId?: number;
	projectId?: number;
	datasetId?: any;
	targetId?: any;
	jobId?: any;
	permissionLevel?: any;
	ownerId?: number;
	ownerName?: string;
	sharingStatus?: SharingStatus;
	type?: DriversType;
	projectName?: string;
	level?: number;
	parentId?: number;
}