import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { NgPipesModule } from 'ngx-pipes';
import { UnitsModule } from '@app/modules/units/units.module';
import { RUNNABLE_PACKAGE } from './packages/runnable-package.interface';
import { MetricCreationPackage } from './packages/metric-creation-package/metric-creation-package.class';
import { DataOverviewSettingsStepComponent } from './packages/data-overview-package/data-overview-wizard/data-overview-settings-step/data-overview-settings-step.component';
import { DataOverviewContentProviderStepComponent } from './packages/data-overview-package/data-overview-wizard/data-overview-content-provider-step/data-overview-content-provider-step.component';
import { DataOverviewWizardWizardComponent } from './packages/data-overview-package/data-overview-wizard/data-overview-wizard.component';
import { DataOverviewPackage } from './packages/data-overview-package/data-overview-package.class';
import { PackagedSolutionsComponent } from './packaged-solutions/packaged-solutions.component';
import { RunPackageModalComponent } from './run-package-modal/run-package-modal.component';
import { CatalogTabComponent } from './catalog-tab/catalog-tab.component';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { AdditionalMetricsBuilderComponent } from './additional-metrics-builder/additional-metrics-builder.component';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		NgPipesModule,
		UnitsModule,
		WizardModule
	],
	declarations: [
		PackagedSolutionsComponent,
		RunPackageModalComponent,
		CatalogTabComponent,
		DataOverviewWizardWizardComponent,
		DataOverviewContentProviderStepComponent,
		DataOverviewSettingsStepComponent,
		AdditionalMetricsBuilderComponent
	],
	providers: [
		{ provide: RUNNABLE_PACKAGE, useClass: MetricCreationPackage, multi: true },
		{ provide: RUNNABLE_PACKAGE, useClass: DataOverviewPackage, multi: true },
	],
})
export class PackageSolutionsModule { }
