import { Component, Input, forwardRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'checkbox-button',
	template: `
	<span [ngbTooltip]="tooltipText">
		<button
			class="btn btn-secondary btn-link btn-flex text-left"
			type="button"
			role="checkbox"
			[attr.aria-checked]="!!innerValue"
			[ngClass]="buttonClass"
			[disabled]="disabled"
			[attr.checked]="innerValue"
			(click)="toggleValue($event)">
			<icon *ngIf="innerValue" name="check" class="action-color flex-shrink"></icon>
			<icon *ngIf="!innerValue" name="circle" class="flex-shrink"></icon>
			<span>{{label}}</span>
			<cb-inline-help *ngIf="inlineHelp">
				<help-body>{{inlineHelp}}</help-body>
			</cb-inline-help>
		</button>
	</span>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxButtonComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.Default
})
export class CheckboxButtonComponent implements ControlValueAccessor {
	@Input() disabled: boolean;
	@Input() buttonClass: string;
	@Input() label: string;
	@Input() tooltipText: string;
	@Input() inlineHelp: string;
	@Input() innerValue: boolean;
	@Output() onChange = new EventEmitter<boolean>();

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: boolean) => void = _.noop;

	constructor(
		private ref: ChangeDetectorRef
	) {}

	toggleValue(event: Event): void {
		event.stopPropagation();
		event.preventDefault();
		this.innerValue = !this.innerValue;
		this.onChange.emit(this.innerValue);
		this.onChangeCallback(this.innerValue);
		this.onTouchedCallback();
	}

	//From ControlValueAccessor interface
	writeValue(value: any): void {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.ref.detectChanges();
		}
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.ref.markForCheck();
	}

}

app.directive('checkboxButton', downgradeComponent({component: CheckboxButtonComponent}));
