import { ITreeSVG } from './tree-renderer.interface';
import { ExtendedHierarchyNode } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';
import { BaseTreeRenderer, TreeNodeSelection, TreeTransition } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/base-tree-renderer';
import { HierarchyTreeOptions } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/hierarchy-tree-options.class';
import { TreeRendererUtils } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer-utils';

export class VerticalTreeRenderer extends BaseTreeRenderer {

	readonly BUBBLE_MAX_HEIGHT = BaseTreeRenderer.MAX_BUBBLE_DIAMETER;
	readonly NODE_WIDTH = 80;
	readonly PARENT_TEXT_LENGTH = 15;

	// initially it uses container size for viewBox, but after 1st render it asjust it based on results to avoid truncation
	private fullHeight: number;

	constructor(
		options: HierarchyTreeOptions,
		private container: ng.IAugmentedJQuery,
		private root: ExtendedHierarchyNode, 
		private svg: ITreeSVG,
		private gNode: ITreeSVG,
		private gLink: ITreeSVG,
		private gPatterns: ITreeSVG,
		usePatternFills: boolean = false,
		isDarkMode: boolean = false
	) {
		super(options, usePatternFills, isDarkMode);
		this.svg.attr('class', 'vertical-tree');
	}

	private processTreeLayout(): void {
		let dy = this.container[0].clientHeight / (this.root.height + 1);
		this.root.x0 = 0;
		this.root.y0 = 0;
		let tree = d3.tree().nodeSize([this.NODE_WIDTH, dy]);
		tree(this.root);
	}

	private initializeSize(): void {
		let boundaries = this.getMinMax(this.root);
		let containerHeight = this.container[0].clientHeight;
		let margin = {
			left: this.NODE_WIDTH / 2,
			right: this.NODE_WIDTH,
			top: this.BUBBLE_MAX_HEIGHT
		};
		let left = boundaries.min - margin.left;
		let width = boundaries.max - boundaries.min + margin.left + margin.right;
		let actualHeight = Math.max(this.fullHeight || 0, containerHeight);
		$(this.svg.node()).height(actualHeight);
		this.initTransition(this.svg, [left, -margin.top, width, actualHeight]);
	}
	
	protected drawShape(node: TreeNodeSelection): TreeNodeSelection {
		return node.append('circle')
			.attr('r', d => d.size)
			.attr('fill', d => this.getFill(d.color, this.gPatterns))
			.attr('stroke-width', () => this.getStrokeWidth(10))
			.attr('stroke', () => this.getStrokeColor());
	}

	protected drawText(node: TreeNodeSelection): TreeNodeSelection {
		return node.append('text')
			.attr('y', d => TreeRendererUtils.isLeaf(d) ? 0 : -this.BUBBLE_MAX_HEIGHT / 2)
			.attr('text-anchor', d => TreeRendererUtils.isLeaf(d) ? 'start' : 'middle')
			.attr('transform', d => TreeRendererUtils.isLeaf(d) ? `translate(-5,${8 + d.size})rotate(45)` : '')
			.text(d => d.data.displayName);
	}

	protected updateText(node: TreeTransition): TreeTransition {
		return node.text(d => TreeRendererUtils.isLeaf(d) ? d.data.displayName : this.getParentText(d));
	}

	private getParentText(item: ExtendedHierarchyNode): string {
		let displayName = item.data.displayName;
		if (!TreeRendererUtils.isCollapsed(item) || item === this.root)
			return displayName;
		else {
			if (displayName.length < this.PARENT_TEXT_LENGTH)
				return displayName;
			else return displayName.substring(0, this.PARENT_TEXT_LENGTH - 3) + '...';
		}
	}

	renderNode(source: ExtendedHierarchyNode): void {
		const nodes = this.root.descendants().reverse();
		const links = this.root.links();
	
		// Compute the new tree layout.
		this.processTreeLayout();
		this.initializeSize();

		this.renderNodes(this.gNode, nodes,
			[source.x0, source.y0],
			d => [d.x, d.y],
			[source.x, source.y]);
		
		this.renderLinks(this.gLink, links,
			d3.linkVertical().x(d => d[0]).y(d => d[1]),
			[source.x0, source.y0],
			d => [d.x, d.y],
			[source.x, source.y]);
	
		// Stash the old positions for transition.
		this.root.eachBefore(d => {
			d.x0 = d.x;
			d.y0 = d.y;
		});

		this.transition.end().then(() => this.recalculateSize());
	}

	scrollTo(node: ExtendedHierarchyNode): void {
		// root is at 0, but viewBox is shifted
		this.transition.end().then(() => {
			this.container.scrollLeft(-this.getMinMax(this.root).min + node.x - this.container.width() / 2);
		});
	}

	recalculateSize(): void {
		let bbox = (this.gNode.node() as SVGGraphicsElement).getBBox();
		this.fullHeight = bbox.height + 10; // 10px to compensate scrollbar, which truncates bottom text
		this.initializeSize();
	}
}
