<div class="row">
	<select-items
		[outerObject]="user"
		topDisplayText="{{'administration.addUserToGroupDescription'|i18n}} {{'administration.addUserToGroupNote'|i18n}}"
		objectAvailableDescriptor="{{'administration.groupsAvailable'|i18n}}"
		objectSelectedDescriptor="{{'administration.groupsSelected'|i18n}}"
		itemsField="groups"
		itemsFieldIdentifier="groupId"
		orderByField="groupName">
	</select-items>
</div>
