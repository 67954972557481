import { OnInit, Component, Input, EventEmitter, Output} from '@angular/core';


@Component({
	selector: 'tree-item-template',
	templateUrl: './tree-item-template.component.html'
})
export class TreeItemTemplateComponent implements OnInit {
	@Input() item: any;
	@Input() showCheckboxes: boolean;
	@Input() displayProperty: string;
	@Input() limitedWidth: boolean;
	@Input() itemTemplate: string;

	@Output() onNodeClick = new EventEmitter<MouseEvent>();

	constructor() {}

	ngOnInit(): void {}

	triggerClick = ($event: MouseEvent) => {
		$event.stopPropagation();
		this.onNodeClick.emit($event);
	}
}