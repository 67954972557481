import { ElementRef } from '@angular/core';
import { HighchartsVisualizationBaseComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization-base.class';

export class HighchartsSingleVisualization extends HighchartsVisualizationBaseComponent {

	chart: Highcharts.Chart;

	protected initResizeHandler(element: ElementRef): void {
		this.initChartResizer(element, () => this.chart);
	}

	protected clearChart(): void {
		if (this.chart?.container)
			this.chart.destroy();
		delete this.chart;
	}
}
