import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[limitedDecimal]'
})

export class LimitedDecimalDirective implements OnInit {

	readonly SPECIAL_KEYS = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

	@Input('limitedDecimal') decimals: number;

	private regex: RegExp;
	
	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		this.decimals = this.decimals || 0;
		this.regex = new RegExp('^\\-?\\d*\\.?\\d{0,' + this.decimals + '}$', 'g');
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (this.SPECIAL_KEYS.indexOf(event.key) !== -1) {
			return;
		}
		let current: string = this.el.nativeElement.value;
		let position = this.el.nativeElement.selectionStart;
		let next = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
		if (next && !String(next).match(this.regex)) {
			event.preventDefault();
		}
	}
}