import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IDriversModelMetrics } from '@cxstudio/drivers/entities/drivers-model';

@Component({
	selector: 'drivers-metrics-tab',
	templateUrl: './drivers-metrics-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriversMetricsTabComponent {
	@Input() modelMetrics: IDriversModelMetrics;
}
