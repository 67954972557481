import { NgModule } from '@angular/core';
import { ConversationSettingsModalComponent } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/conversation-settings-modal.component';
import { SpineMetricSettingsModalComponent } from '@app/modules/account-administration/conversation-settings/spine-metric-settings-modal.component';
import { ConversationSettingsListComponent } from '@app/modules/account-administration/conversation-settings/conversation-settings-list.component';
import { ConversationSettingsGridDefinition } from '@app/modules/account-administration/conversation-settings/conversation-settings-grid-definition.service';
import { ColorBandSelectorComponent } from '@app/modules/account-administration/conversation-settings/color-band-selector.component';
import { SharedModule } from '@app/shared/shared.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { NgPipesModule } from 'ngx-pipes';
import { WidgetSettingsModule } from '@app/modules/widget-settings/widget-settings.module';
import { MetricAttributeSpineItemComponent } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/metric-attribute-spine-item/metric-attribute-spine-item.component';
import { TopicSpineItemComponent } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/topic-spine-item/topic-spine-item.component';
import { UnitsModule } from '@app/modules/units/units.module';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		DragDropModule,
		ItemGridModule,
		NgPipesModule,
		WidgetSettingsModule, // for words-selection
		UnitsModule
	],
	declarations: [
		ColorBandSelectorComponent,
		ConversationSettingsModalComponent,
		ConversationSettingsListComponent,
		SpineMetricSettingsModalComponent,
		TopicSpineItemComponent,
		MetricAttributeSpineItemComponent,
	],
	exports: [
		ConversationSettingsListComponent,
	],
	providers: [
		ConversationSettingsGridDefinition,
	],
})
export class ConversationSettingsModule { }
