angular.module('uib/template/datepicker/day.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/datepicker/day.html',
		`<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
			<thead>
				<tr>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-left uib-left" ng-click="move(-1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-left"></i><span class="sr-only">previous</span></button></th>
					<th colspan="{{::5 + showWeeks}}"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="btn btn-secondary btn-link btn-sm uib-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><strong>{{title}}</strong></button></th>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-right uib-right" ng-click="move(1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-right"></i><span class="sr-only">next</span></button></th>
				</tr>
				<tr>
					<th ng-if="showWeeks" class="text-center"></th>
					<th ng-repeat="label in ::labels track by $index" class="text-center"><small aria-label="{{::label.full}}">{{::label.abbr}}</small></th>
				</tr>
			</thead>
			<tbody>
				<tr class="uib-weeks" ng-repeat="row in rows track by $index" role="row">
					<td ng-if="showWeeks" class="text-center h6"><em>{{ weekNumbers[$index] }}</em></td>
					<td ng-repeat="dt in row" class="uib-day text-center" role="gridcell"
					id="{{::dt.uid}}"
					ng-class="::dt.customClass">
						<button type="button" class="btn btn-secondary btn-sm"
							uib-is-class="
							'selected' for selectedDt,
							'active' for activeDt
							on dt"
							ng-click="select(dt.date)"
							ng-disabled="::dt.disabled"
							tabindex="-1"><span ng-class="::{'text-muted': dt.secondary}">{{::dt.label}}</span></button>
					</td>
				</tr>
			</tbody>
		</table>`);
}]);

angular.module('uib/template/datepicker/month.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/datepicker/month.html',
		`<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
			<thead>
				<tr>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-left uib-left" ng-click="move(-1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-left"></i><span class="sr-only">previous</span></button></th>
					<th colspan="{{::yearHeaderColspan}}"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="btn btn-secondary btn-link btn-sm uib-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><strong>{{title}}</strong></button></th>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-right uib-right" ng-click="move(1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-right"></i><span class="sr-only">next</span></i></button></th>
				</tr>
			</thead>
			<tbody>
				<tr class="uib-months" ng-repeat="row in rows track by $index" role="row">
					<td ng-repeat="dt in row" class="uib-month text-center" role="gridcell"
					id="{{::dt.uid}}"
					ng-class="::dt.customClass">
						<button type="button" class="btn btn-secondary"
							uib-is-class="
							'selected' for selectedDt,
							'active' for activeDt
							on dt"
							ng-click="select(dt.date)"
							ng-disabled="::dt.disabled"
							tabindex="-1"><span>{{::dt.label}}</span></button>
					</td>
				</tr>
			</tbody>
		</table>`);
}]);

angular.module('uib/template/datepicker/year.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/datepicker/year.html',
		`<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
			<thead>
				<tr>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-left uib-left" ng-click="move(-1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-left"></i><span class="sr-only">previous</span></button></th>
					<th colspan="{{::columns - 2}}"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="btn btn-secondary btn-link btn-sm uib-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><strong>{{title}}</strong></button></th>
					<th><button type="button" class="btn btn-secondary btn-link btn-sm pull-right uib-right" ng-click="move(1)" tabindex="-1"><i aria-hidden="true" class="q-icon q-icon-angle-right"></i><span class="sr-only">next</span></button></th>
				</tr>
			</thead>
			<tbody>
				<tr class="uib-years" ng-repeat="row in rows track by $index" role="row">
				<td ng-repeat="dt in row" class="uib-year text-center" role="gridcell"
					id="{{::dt.uid}}"
					ng-class="::dt.customClass">
						<button type="button" class="btn btn-secondary"
							uib-is-class="
							'selected' for selectedDt,
							'active' for activeDt
							on dt"
							ng-click="select(dt.date)"
							ng-disabled="::dt.disabled"
							tabindex="-1"><span>{{::dt.label}}</span></button>
					</td>
				</tr>
			</tbody>
		</table>`);
}]);


angular.module('uib/template/rating/rating.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/rating/rating.html',
	`<span ng-mouseleave="reset()" ng-keydown="onKeydown($event)" tabindex="0" role="slider" aria-valuemin="0" aria-valuemax="{{range.length}}" aria-valuenow="{{value}}" aria-valuetext="{{title}}">
		<span ng-repeat-start="r in range track by $index" class="sr-only">({{ $index < value ? '*' : ' ' }})</span>
		<i ng-repeat-end ng-mouseenter="enter($index + 1)" ng-click="rate($index + 1)" class="q-icon" ng-class="$index < value && (r.stateOn || 'q-icon-star') || (r.stateOff || 'q-icon-star-outline')" ng-attr-title="{{r.title}}"></i>
	</span>`);
}]);

angular.module('uib/template/timepicker/timepicker.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/timepicker/timepicker.html',
	`<table class="uib-timepicker">
		<tbody>
			<tr class="text-center" ng-show="::showSpinners">
				<td class="uib-increment hours"><a ng-click="incrementHours()" ng-class="{disabled: noIncrementHours()}" class="btn btn-link" ng-disabled="noIncrementHours()" tabindex="-1"><span class="q-icon q-icon-angle-top"></span></a></td>
				<td>&nbsp;</td>
				<td class="uib-increment minutes"><a ng-click="incrementMinutes()" ng-class="{disabled: noIncrementMinutes()}" class="btn btn-link" ng-disabled="noIncrementMinutes()" tabindex="-1"><span class="q-icon q-icon-angle-top"></span></a></td>
				<td ng-show="showSeconds">&nbsp;</td>
				<td ng-show="showSeconds" class="uib-increment seconds"><a ng-click="incrementSeconds()" ng-class="{disabled: noIncrementSeconds()}" class="btn btn-link" ng-disabled="noIncrementSeconds()" tabindex="-1"><span class="q-icon q-icon-angle-top"></span></a></td>
				<td ng-show="showMeridian"></td>
			</tr>
			<tr>
				<td class="form-group uib-time hours" ng-class="{'has-error': invalidHours}">
					<input type="text" placeholder="HH" ng-model="hours" ng-change="updateHours()" class="form-control text-center" ng-readonly="::readonlyInput" maxlength="2" tabindex="{{::tabindex}}" ng-disabled="noIncrementHours()" ng-blur="blur()">
				</td>
				<td class="uib-separator">:</td>
				<td class="form-group uib-time minutes" ng-class="{'has-error': invalidMinutes}">
					<input type="text" placeholder="MM" ng-model="minutes" ng-change="updateMinutes()" class="form-control text-center" ng-readonly="::readonlyInput" maxlength="2" tabindex="{{::tabindex}}" ng-disabled="noIncrementMinutes()" ng-blur="blur()">
				</td>
				<td ng-show="showSeconds" class="uib-separator">:</td>
				<td class="form-group uib-time seconds" ng-class="{'has-error': invalidSeconds}" ng-show="showSeconds">
					<input type="text" placeholder="SS" ng-model="seconds" ng-change="updateSeconds()" class="form-control text-center" ng-readonly="readonlyInput" maxlength="2" tabindex="{{::tabindex}}" ng-disabled="noIncrementSeconds()" ng-blur="blur()">
				</td>
				<td ng-show="showMeridian" class="uib-time am-pm"><button type="button" ng-class="{disabled: noToggleMeridian()}" class="btn btn-default text-center" ng-click="toggleMeridian()" ng-disabled="noToggleMeridian()" tabindex="{{::tabindex}}">{{meridian}}</button></td>
			</tr>
			<tr class="text-center" ng-show="::showSpinners">
				<td class="uib-decrement hours"><a ng-click="decrementHours()" ng-class="{disabled: noDecrementHours()}" class="btn btn-link" ng-disabled="noDecrementHours()" tabindex="-1"><span class="q-icon q-icon-angle-bottom"></span></a></td>
				<td>&nbsp;</td>
				<td class="uib-decrement minutes"><a ng-click="decrementMinutes()" ng-class="{disabled: noDecrementMinutes()}" class="btn btn-link" ng-disabled="noDecrementMinutes()" tabindex="-1"><span class="q-icon q-icon-angle-bottom"></span></a></td>
				<td ng-show="showSeconds">&nbsp;</td>
				<td ng-show="showSeconds" class="uib-decrement seconds"><a ng-click="decrementSeconds()" ng-class="{disabled: noDecrementSeconds()}" class="btn btn-link" ng-disabled="noDecrementSeconds()" tabindex="-1"><span class="q-icon q-icon-angle-bottom"></span></a></td>
				<td ng-show="showMeridian"></td>
			</tr>
		</tbody>
	</table>`);
}]);


angular.module('uib/template/carousel/carousel.html', []).run(['$templateCache', ($templateCache) => {
	$templateCache.put('uib/template/carousel/carousel.html',
	`<div class="carousel-inner" ng-transclude></div>
	<a role="button" href class="left carousel-control" ng-click="prev()" ng-class="{ disabled: isPrevDisabled() }" ng-show="slides.length > 1">
		<span aria-hidden="true" class="q-icon q-icon-angle-left"></span>
		<span class="sr-only">previous</span>
	</a>
	<a role="button" href class="right carousel-control" ng-click="next()" ng-class="{ disabled: isNextDisabled() }" ng-show="slides.length > 1">
		<span aria-hidden="true" class="q-icon q-icon-angle-right"></span>
		<span class="sr-only">next</span>
	</a>
	<ol class="carousel-indicators" ng-show="slides.length > 1">
		<li ng-repeat="slide in slides | orderBy:indexOfSlide track by $index" ng-class="{ active: isActive(slide) }" ng-click="select(slide)">
			<span class="sr-only">slide {{ $index + 1 }} of {{ slides.length }}<span ng-if="isActive(slide)">, currently active</span></span>
		</li>
	</ol>`);
}]);
