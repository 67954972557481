<div class="columns-3">
	<checkbox-button *ngIf="isSysAdmin()"
		class="_t-system-administrator"
		label="{{'administration.systemAdmin'|i18n}}"
		[(ngModel)]="user.systemAdministrator"
		(ngModelChange)="changeHandler()">
	</checkbox-button>
	<checkbox-button
		class="_t-lite-administrator"
		label="{{'administration.liteAdmin'|i18n}}"
		[(ngModel)]="user.liteAdmin"
		(ngModelChange)="changeHandler()">
	</checkbox-button>
	<checkbox-button
		class="_t-download-training-data"
		label="{{'administration.downloadTrainingData'|i18n}}"
		[(ngModel)]="user.downloadTrainingData"
		(ngModelChange)="changeHandler()">
	</checkbox-button>
	<checkbox-button
		class="_t-studio-api-user"
		label="{{'administration.studioApiUser'|i18n}}"
		[(ngModel)]="user.studioApiUser"
		(ngModelChange)="changeHandler()">
	</checkbox-button>
	<checkbox-button
		class="_t-platform-api-user"
		label="{{'administration.platformApiUser'|i18n}}"
		[(ngModel)]="user.platformApiUser"
		(ngModelChange)="platformApiChangeHandler()">
	</checkbox-button>
	<div class="no-split">
		<checkbox-button
			data-testid="manage-internal-templates"
			label="{{'administration.manageUnifiedTemplates'|i18n}}"
			[(ngModel)]="user.manageInternalTemplates"
			(ngModelChange)="changeHandler()">
		</checkbox-button>
		<checkbox-button
			data-testid="manage-internal-templates"
			label="{{'administration.shareUnifiedTemplates'|i18n}}"
			[(ngModel)]="user.shareInternalTemplates"
			(ngModelChange)="changeHandler()">
		</checkbox-button>
		<checkbox-button
			data-testid="manage-internal-templates"
			label="{{'administration.createUnifiedTemplates'|i18n}}"
			[(ngModel)]="user.createInternalTemplates"
			(ngModelChange)="changeHandler()">
		</checkbox-button>
	</div>
</div>
