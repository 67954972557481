import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountEngageStatus } from './master-account-engage-status';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { EngageStatusApiService } from '@app/modules/system-administration/status/integration-status/engage/engage-status-api.service';
import { EngageStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/engage/engage-status-table-columns.service';

@Injectable({providedIn: 'root'})
export class EngageStatusService implements IntegrationStatusService<MasterAccountEngageStatus> {
	constructor(
		private engageStatusApiService: EngageStatusApiService,
		private engageStatusTableColumnsService: EngageStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.engage';
	}

	getColumns(): TableColumn<MasterAccountEngageStatus>[] {
		return this.engageStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountEngageStatus[]> {
		return this.engageStatusApiService.getStatus();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.engageStatusApiService.export(search);
	}
}
