
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import ChartType from '@cxstudio/reports/entities/chart-type';

export class DualAxisTypes {

	static isAnyLine(props: WidgetProperties): boolean {
		return WidgetType.LINE === props.widgetType;
	}

	static isLineChart(props: WidgetProperties, visualProps: VisualProperties): boolean {
		return WidgetType.LINE === props.widgetType && ChartType.SPLINE === visualProps.subChartType;
	}

	static isSecondaryLineChart = (visualProps: VisualProperties): boolean => {
		return ChartType.SPLINE === visualProps.secondaryChartType;
	}

	static isBubbleVisualization = (visualProps: VisualProperties): boolean => {
		return ChartType.BUBBLE === visualProps.subChartType;
	}

	static isBubbleChart = (props: WidgetProperties, visualProps: VisualProperties): boolean => {
		return WidgetType.LINE === props.widgetType && DualAxisTypes.isBubbleVisualization(visualProps);
	}

	static isParetoVisualization = (visualProps: VisualProperties): boolean => {
		return ChartType.PARETO === visualProps?.subChartType;
	}
}


