import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModelIdentifier } from '@cxstudio/projects/model-identifier';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface GlobalOtherExplorerOptions {
	modelIdentifier: ModelIdentifier;
}

@Component({
	selector: 'global-other-explorer-modal',
	template: `<global-other-explorer [resolve]="input" (dismiss)="modal.dismiss()"></global-other-explorer>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalOtherExplorerModalComponent {
	@Input() input: GlobalOtherExplorerOptions;

	constructor(
		public modal: NgbActiveModal,
	) { }
}
