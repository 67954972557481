// should be converted to angular and refactored

/**
 * Error/warning service, show all messages in a single dialog (clear everything on "OK")
 */

interface IErrorDialogMessage {
	icon: string;
	type: string;
	text: string;
	count: number;
	trustHtml: boolean;
}

export class ErrorDialogService {
	messages: IErrorDialogMessage[] = [];
	instance = null;

	constructor(
		private readonly $uibModal: ng.ui.bootstrap.IModalService
	) {}

	error = (message: string, trustHtml: boolean = false): ng.ui.bootstrap.IModalInstanceService => {
		this.showDialog();
		let previous = _.findWhere(this.messages, {text: message});
		if (!previous) {
			this.messages.push(this.generateMessage('error', message, trustHtml));
		} else {
			previous.count++;
		}
		return this.instance;
	}

	info = (message: string, trustHtml: boolean = false): ng.ui.bootstrap.IModalInstanceService => {
		this.showDialog();
		this.messages.push(this.generateMessage('info', message, trustHtml));
		return this.instance;
	}

	private showDialog(): void {
		if (!this.instance) {
			this.instance = this.$uibModal.open({
				windowClass: 'error-dialog',
				templateUrl: 'partials/custom/error-dialog.html',
				backdrop: 'static',
				backdropClass: 'error-dialog-backdrop',
				controller: 'DialogCtrl'
			});

			this.instance.result.then(() => {
				// clear variables
				this.instance = null;
				this.messages.removeAll();
			}, () => {
				this.instance = null;
				this.messages.removeAll();
			});
		}
	}

	private generateMessage(type: 'error' | 'info', text: string, trustHtml: boolean = false): IErrorDialogMessage {
		let icon;

		switch (type) {
			case 'error':
				icon = 'q-icon-warning';
				break;
			case 'info':
				icon = 'q-icon-info';
				break;
		}
		return {
			icon,
			type,
			text,
			trustHtml,
			count: 1
		};
	}
}

app.service('errorDialogService', ErrorDialogService);



class DialogCtrl {
	constructor(
		private readonly $scope,
		private readonly $uibModalInstance,
		private readonly errorDialogService: ErrorDialogService
	) {		this.$scope.messages = errorDialogService.messages;

	}

	$onInit(): void {
		this.$scope.cancel = this.cancel;
		this.$scope.ok = this.close;
	}

	cancel = (): void => {
		this.$uibModalInstance.dismiss();
	}

	close = (): void => {
		this.$uibModalInstance.close();
	}


}
app.controller('DialogCtrl', DialogCtrl);
