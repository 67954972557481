
/**
 * Directive for selecting value of field on click
 */
app.directive('selectOnClickField', () => {
	return {
		restrict: 'A',
		link: (scope, element) => {
			element.on('click', () => element.trigger('select'));
		}
	};
});
