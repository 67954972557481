import { Inject, Injectable } from '@angular/core';
import { AnSortDirection, GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import * as cloneDeep from 'lodash.clonedeep';
import { DefaultDrillProcessor } from './default-drill-processor.class';
import { CommonDrillService } from '../common-drill.service';

@Injectable({
	providedIn: 'root'
})
export class DrillToLineProcessorService extends DefaultDrillProcessor {

	readonly INHERITED_VIS_PROPERTIES  = ['attributeSelections', 'primaryGroup', 'subChartType', 'size',
		'secondarySize', 'scale', 'secondaryScale'].concat(CommonInherentProperties.dualVisual);
	constants = this.metricConstants.get();

	constructor(
		private readonly commonDrill: CommonDrillService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants
	) {
		super();
		this.addInheritedProperties('primaryTimeGrouping');
		this.addInheritedVisualProperties(this.INHERITED_VIS_PROPERTIES);
	}

	private intializeDefaultProperties(widget: Widget, point: DrillPoint): void {
		if (!widget.visualProperties.attributeSelections.yAxis) {
			let calculationAttribute = this.commonDrill.getCalculationAttribute(widget, point.seriesType);
			widget.properties.selectedMetrics = [calculationAttribute];
			widget.visualProperties.attributeSelections.yAxis = calculationAttribute;
			widget.visualProperties.yAxis = calculationAttribute.name;
		}

		if (!AnalyticMetricTypes.isHierarchyModel(widget.properties.selectedAttributes[0])) {
			if (AnalyticMetricTypes.isHierarchyEnrichmentProperty(widget.visualProperties.attributeSelections.yAxis)) {
				this.setCalculation(this.constants.VOLUME, widget);
			}
			if (widget.visualProperties.attributeSelections.secondaryYAxis &&
				AnalyticMetricTypes.isHierarchyEnrichmentProperty(widget.visualProperties.attributeSelections.secondaryYAxis)) {
				this.setSecondaryCalculation(this.constants.VOLUME, widget);
			}
		}

		let groupBy = widget.properties.selectedAttributes[0];
		if (groupBy.metricType === AnalyticMetricType.TIME) {
			widget.properties.primaryTimeGrouping = widget.properties.selectedAttributes[0];
			groupBy.sortBy = 'time';
			groupBy.sortOrder = GroupingSortOrder.ASC;
		} else {
			delete widget.properties.primaryTimeGrouping;
		}
	}

	drillTo(widget: Widget, drillToType, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget {
		//replace name and visual
		widget.name = WidgetType.LINE;
		widget.properties.widgetType = WidgetType.LINE;
		widget.visualProperties.subChartType = (drillToType === 'line') ? ChartType.SPLINE : ChartType.BUBBLE;
		widget.visualProperties.visualization = WidgetVisualization.DUAL;
		widget.visualProperties.points = true;
		widget.visualProperties.pointColor = 'inherit';
		widget.displayName += ' - ' + point.groupBy.displayName;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy));

		//add selectedAttributes
		widget.properties.selectedAttributes = [point.groupBy];
		if (widget.visualProperties.attributeSelections) {
			widget.visualProperties.attributeSelections.primaryGroup = point.groupBy;
			widget.visualProperties.attributeSelections.secondaryGroup = undefined;
			widget.visualProperties.attributeSelections.stackedGroup = undefined;

		} else {
			widget.visualProperties.attributeSelections = {
				primaryGroup: point.groupBy
			};
		}

		if (widget.properties.dateRangeP1 && !widget.properties.dateRangeP2) {
			widget.properties.dateRangeP2 = cloneDeep(widget.properties.dateRangeP1);
		}

		if (widget.visualProperties.attributeSelections.size
			&& (widget.visualProperties.attributeSelections.size.metricType === AnalyticMetricType.STANDARD
			|| widget.visualProperties.attributeSelections.size.metricType === AnalyticMetricType.ATTRIBUTE)) {
			widget.visualProperties.size = widget.visualProperties.attributeSelections.size.name;
		}

		if (!widget.visualProperties.size) {
			widget.visualProperties.size = this.constants.CONSTANT_SIZE.name;
			widget.visualProperties.attributeSelections.size = this.constants.CONSTANT_SIZE;
		}

		widget.visualProperties.primaryGroup = point.groupBy.name;
		this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.PRIMARY, defaultColor);
		this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.SECONDARY, defaultColor);

		this.commonDrill.processSelectedMetric(point, widget, this.setCalculation);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);
		this.commonDrill.processHierarchyMetrics(widget);

		this.intializeDefaultProperties(widget, point);

		let caseVisualizations = widget.visualProperties.caseVisualizations;

		widget = this.inheritPropertiesAndVisProps(widget);

		if (AnalyticMetricTypes.isTime(widget.properties.selectedAttributes[0]) && caseVisualizations) {
			widget.visualProperties.caseVisualizations = caseVisualizations;
		}

		return widget;
	}

	private setCalculation(calculationMetric: ReportCalculation, widget: Widget): void {
		if (!calculationMetric) {
			calculationMetric = this.constants.VOLUME;
		}

		widget.visualProperties.attributeSelections.yAxis = calculationMetric;
		widget.visualProperties.yAxis = calculationMetric.name;
	}

	private setSecondaryCalculation(calculationMetric: ReportCalculation, widget: Widget): void {
		if (!calculationMetric) {
			calculationMetric = this.constants.VOLUME;
		}

		widget.visualProperties.attributeSelections.secondaryYAxis = calculationMetric;
		widget.visualProperties.secondaryYAxis = calculationMetric.name;
	}
}
