import { Injectable } from '@angular/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ReportMetricsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.METRICS);
	}

	getWidgetMetrics(
		assetParameters: RequestAssetParameters, cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<Metric[]> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get('rest/report-assets/metrics', options) as AssetPromisePlatformProject<Metric[]>;
	}
}
