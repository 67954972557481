import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { conditionalValidator } from '@app/modules/cx-form/validation/conditional-validator.directive';


@Component({
	selector: 'master-account-admin-properties',
	templateUrl: './master-account-admin-properties.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterAccountAdminPropertiesComponent implements OnInit {
	readonly NUMBER_PATTERN = /^\d*/;

	@Input() masterAccountId: number;

	properties: FormGroup;
	customerAdminsCount: number = 0;
	hasAdminUser: boolean;

	constructor(private locale: CxLocaleService,
		readonly formUtils: CxFormUtils,
		private fb: FormBuilder,
		private masterAccountManageApi: MasterAccountManageApi,
		private tagService: TagsService,
		) {
		this.properties = this.fb.group({
			accountName: [ null , [ Validators.required ]],
			owners: [ [] ],
			tags: [ [] ],
			maxReportingWidgets: [ 25,
				[ Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(this.NUMBER_PATTERN) ]],
			maxNonReportingWidgets: [ 50,
				[ Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(this.NUMBER_PATTERN) ]],
			maxParallelRequests: [ 8,
				[ Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(this.NUMBER_PATTERN) ]],
			maxCustomerAdmins: [ null,
				[ Validators.min(this.customerAdminsCount), Validators.max(10), Validators.pattern(this.NUMBER_PATTERN) ]],
			useHsmHostname: [ false ],
			isAdminOrg: [ false ],
			adminOrgAllowed: [ { value: '' },
				[ conditionalValidator(() => this.hasNotAllowedAdminOrgChange(), Validators.required) ] ],
			dashboardScheduling: [ true ],
		});
	}

	ngOnInit(): void {
		this.loadContext();
	}

	private loadContext(): void {
		if (this.masterAccountId) {
			this.masterAccountManageApi.getEditContext(this.masterAccountId).then((context) => {
				this.hasAdminUser = context.hasAdminUserAsDefault;
				this.customerAdminsCount = context.customerAdminsCount || 0;
			});
		}
	}

	hasNotAllowedAdminOrgChange = (): boolean => {
		return this.hasAdminUser && !this.isAdminOrg.value && this.isAdminOrg.dirty;
	}


	getGroup(): FormGroup {
		return this.properties;
	}

	getTagsSuggestion = (filterQuery: string): Promise<string[]> => {
		return this.tagService.getTags(filterQuery)
			.then((tags) => this.tagService.filterTagsSuggestionsByUserTags(tags));
	}

	get accountName(): FormControl {
		return this.properties.controls.accountName as FormControl;
	}

	get maxReportingWidgets(): FormControl {
		return this.properties.controls.maxReportingWidgets as FormControl;
	}

	get maxNonReportingWidgets(): FormControl {
		return this.properties.controls.maxNonReportingWidgets as FormControl;
	}

	get maxParallelRequests(): FormControl {
		return this.properties.controls.maxParallelRequests as FormControl;
	}

	get maxCustomerAdmins(): FormControl {
		return this.properties.controls.maxCustomerAdmins as FormControl;
	}

	get isAdminOrg(): FormControl {
		return this.properties.controls.isAdminOrg as FormControl;
	}
}
