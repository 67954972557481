import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { ProjectsManagementController } from '@cxstudio/projects/projects-management-component';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { CxLocaleService } from '@app/core';


@Injectable()
export class ScorecardsGridDefinitionService implements IGridDefinition<ProjectsManagementController> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
	) {}

	init = (gridMode: GridMode, controller: ProjectsManagementController): Promise<IGridColumn[]> => {
		let columns: IGridColumn[] = [{
			id: 'hamburger',
			field: 'name',
			sortable: false,
			minWidth: 40,
			width: 40,
			cssClass: 'cell-hamburger',
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			formatter: this.gridFormatterService.HamburgerFormatter
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'active',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: false,
			formatter: this.getActiveSwitcherFormatter(controller),
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		},  {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			defaultSortColumn: SortDirection.ASC,
			sortable: true,
			searchable: true,
			minWidth: 250,
			width: 400,
			cssClass: 'cell-title'
		}, {
			id: 'creator',
			name: this.locale.getString('scorecards.creator'),
			field: 'creatorText',
			sortable: true,
			searchable: true,
			optional: true,
			minWidth: 250,
			width: 400
		}, {
			id: 'lastModified',
			name: this.locale.getString('scorecards.lastModified'),
			field: 'lastModifiedDate',
			sortable: true,
			optional: true,
			minWidth: 150,
			width: 200,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'categoryModel',
			name: this.locale.getString('scorecards.categoryModel'),
			field: 'categoryModel',
			sortable: true,
			searchable: true,
			optional: true,
			minWidth: 250,
			width: 400
		}, {
			id: 'autoFailCriteria',
			name: this.locale.getString('scorecards.autoFailCriteria'),
			field: 'autoFailCriteria',
			sortable: true,
			searchable: true,
			optional: true,
			minWidth: 150,
			width: 200
		}, {
			id: 'scoredCriteria',
			name: this.locale.getString('scorecards.scoredCriteria'),
			field: 'scoredCriteria',
			sortable: true,
			searchable: true,
			optional: true,
			minWidth: 150,
			width: 200
		}, {
			id: 'threshold',
			name: this.locale.getString('scorecards.targetLabel'),
			field: 'threshold',
			sortable: true,
			searchable: true,
			minWidth: 150,
			width: 200
		}];
		return Promise.resolve(columns);
	}

	private getActiveSwitcherFormatter = (controller: ProjectsManagementController): any => {
		return (row, cell, value, columnDef, dataContext: Scorecard) => {
			let editable = controller.ui.hasManagerAccess();
			let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
			return getSwitch(value, !editable);
		};
	}
}

app.service('scorecardsGridDefinition', downgradeInjectable(ScorecardsGridDefinitionService));
