import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ListOption } from '@app/shared/components/forms/list-option';

@Component({
	selector: 'pills-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<button *ngIf="unselected"
		class="h-min-40 hover:bg-action-200 w-max-100-percent border-1 border-solid rounded-full font-standard ph-16
		mr-8 text-base text-ellipsis focus-outline-2 cursor-pointer focus-border-gray-800"
		[ngClass]="getUnselectedStateClasses()"
		(click)="onReset()">
		<label class="btn-text font-semibold m-0 cursor-pointer">
			{{unselected}}
		</label>
	</button>
	<hr class="m-0 mv-8">
	<button *ngFor="let option of options"
		class="h-min-40 hover:bg-action-200 w-max-175 border-1 border-solid rounded-full font-standard ph-16
		mr-8 mb-8 text-base focus-outline-2 cursor-pointer hover-border-action-200 focus-border-gray-800"
		[ngClass]="getStateClasses(option)"
		(click)="onOptionAction(option)">
		<label class="btn-text font-semibold m-0 cursor-pointer">
			{{option.name}}
		</label>
	</button>
	`,
	styles: [
		`
			.h-min-40 {
				min-height: 2.5rem;
			}
			.focus-outline-2:focus {
				outline: 2px solid var(--action-800);
				outline-offset: var(--default-outline-offset);
			}
			.btn-text {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				text-align: left;
				overflow: hidden;
				line-height: 1.5;
			}
			.hover-border-action-200:hover {
				border-color: var(--action-200) !important;
			}
			.focus-border-gray-800:focus,focus-border-gray-800:focus-visible {
				border-color: var(--gray-800) !important;
			}
			.w-max-180 {
				max-width: 180px;
			}
		`
	]
})
export class PillsSelectorComponent<T> {
	@Input() unselected: string;
	@Input() options: ListOption<T>[];
	@Output() selectionChange = new EventEmitter<T[]>();

	selection: T[] = [];

	onReset(): void {
		this.selection = [];
		this.selectionChange.emit(this.selection);
	}

	onOptionAction(option: ListOption<T>): void {
		if (this.selection.contains(option.value)) {
			this.selection.remove(option.value);
		} else {
			this.selection.push(option.value);
		}
		this.selectionChange.emit(this.selection);
	}

	getUnselectedStateClasses(): string[] {
		return this.selection.isEmpty()
			? this.getActiveClasses()
			: this.getInactiveClasses();
	}

	getStateClasses(option: ListOption<T>): string[] {
		return this.selection.contains(option.value)
			? this.getActiveClasses()
			: this.getInactiveClasses();
	}

	private getActiveClasses(): string[] {
		return ['bg-action-200', 'border-action-200', 'text-action-700'];
	}

	private getInactiveClasses(): string[] {
		return ['bg-white', 'border-gray-800', 'text-gray-900', 'hover-border-action-200'];
	}
}
