import * as _ from 'underscore';
import { ColorPalettes } from './color-palettes.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { ColorFunction, ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ColorPaletteNames } from './color-palette-constants.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ColorPalettesHelper } from '@cxstudio/reports/coloring/color-palettes-helper';
import { ApplicationThemeService } from '@app/core/application-theme.service';

export class ColorFunctionBuilder {
	groups: ReportGrouping[];
	providerPalette: string[];
	metrics: Metric[];
	wrapper: (fn: ColorFunction) => ColorFunction;

	private colorPalettesHelper: ColorPalettesHelper;

	constructor(
		private readonly colorUtils: ColorUtils,
		private readonly calculationColorService: CalculationColorService,
		private readonly groupColorService,
		private readonly CalculationColorType,
		private readonly GroupColorType,
		private readonly colorPalettes: ColorPalettes,
		private readonly applicationThemeService: ApplicationThemeService,
	) {

	}

	withGroups = (groups: ReportGrouping[]): ColorFunctionBuilder => {
		this.groups = groups;
		return this;
	}

	withMetrics = (metrics: Metric[]): ColorFunctionBuilder => {
		this.metrics = metrics;
		return this;
	}

	withPalettes = (palettes: WidgetColorPalette[], providerPalette: string[]): ColorFunctionBuilder => {
		this.colorPalettesHelper = new ColorPalettesHelper(palettes, providerPalette);
		return this;
	}

	withWrapper = (wrapper: (fn: ColorFunction) => ColorFunction): ColorFunctionBuilder => {
		this.wrapper = wrapper;
		return this;
	}

	private getCalculationMetric(colorName): Metric {
		let name = CalculationColorUtils.getCalculationColorAttributeName(colorName);
		if (name && !/_studio_metric_/.test(name)) {
			name = this.CalculationColorType.typeOf(name).rawName;
		}
		return _.findWhere(this.metrics, {name}) as Metric;
	}

	private getGroupMetric(colorName): Metric {
		let name = this.groupColorService.getGroupColorAttributeName(colorName);
		let originalName = name;
		let group;
		if (name && !/_studio_metric_/.test(name)) {
			name = this.GroupColorType.typeOf(name).rawName;
		} else {
			group = _.findWhere(this.groups, {name});
		}
		let metric = angular.copy(_.findWhere(this.metrics, {name}));

		return metric && angular.extend(metric, {name: originalName}, {identifier: group?.identifier}); // replace rawName with subtype for predefined
	}

	private getPaletteColors(name: string): string[] {
		return this.colorPalettesHelper.getPaletteColors(name, this.applicationThemeService.isShowingDashboardDarkTheme());
	}

	private getStudioColorFunction(name): ColorFunction {
		if (this.colorPalettes.isPaletteColor(name)) {
			return this.colorPalettes.getPaletteFunction(name, this.getPaletteColors(name));
		}
		if (CalculationColorUtils.isCalculationColor(name)) {
			return this.calculationColorService.getColorFunction(name, this.getCalculationMetric(name));
		}
		if (this.groupColorService.isGroupColor(name)) {
			return this.groupColorService.getColorFunction(name, this.getGroupMetric(name));
		}
		return undefined;
	}

	buildColorFunction = (name: string, customColor: string, parentFunction?: ColorFunction): ColorFunction => {
		if (!name)
			name = ColorPaletteNames.PALETTE_1;
		let studioColorFunction = this.getStudioColorFunction(name);
		if (!_.isUndefined(studioColorFunction)) {
			return this.wrap(studioColorFunction);
		}
		return this.wrap(this.colorUtils.getSimpleColorFunction(name, customColor, this.providerPalette, parentFunction));
	}

	private wrap(colorFunction: ColorFunction): ColorFunction {
		if (this.wrapper)
			return this.wrapper(colorFunction);
		return colorFunction;
	}

	buildMetricLegendArray = (name: string): any => {
		if (this.calculationColorService.isCalculationColor(name)) {
			return this.calculationColorService.getLegendArray(name, this.getCalculationMetric(name));
		}
		if (this.groupColorService.isGroupColor(name)) {
			return this.groupColorService.getLegendArray(name, this.getGroupMetric(name));
		}
		return undefined;
	}

	buildCategoryFunction = (name: string): any => {
		if (this.calculationColorService.isCalculationColor(name)) {
			return this.calculationColorService.getCategoryFunction(name, this.getCalculationMetric(name));
		}
		if (this.groupColorService.isGroupColor(name)) {
			return this.groupColorService.getCategoryFunction(name, this.getGroupMetric(name));
		}
		return undefined;
	}

	buildColorArray = (name: string): string[] => {
		if (this.colorPalettes.isPaletteColor(name)) {
			return this.getPaletteColors(name);
		}
		if (this.calculationColorService.isCalculationColor(name)) {
			return this.calculationColorService.getColorArray(name, this.getCalculationMetric(name));
		}
		if (this.groupColorService.isGroupColor(name)) {
			return this.groupColorService.getColorArray(name, this.getGroupMetric(name));
		}
		return undefined;
	}
}
