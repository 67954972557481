import { NgModule} from '@angular/core';
import { RequestAccessPageComponent } from '@app/modules/access-management/request-access-page/request-access-page.component';
import { GrantAccessPageComponent } from '@app/modules/access-management/grant-access-page/grant-access-page.component';
import { SharedModule } from '@app/shared/shared.module';
import { AssetsAccessComponent } from './groups/assets-access/assets-access.component';
import { AssetAccessApiService } from './api/asset-access-api.service';
import { UnitsModule } from '@app/modules/units/units.module';


@NgModule({
	imports: [
		SharedModule,
		UnitsModule
	],
	exports: [
		AssetsAccessComponent
	],
	declarations: [
		RequestAccessPageComponent,
		GrantAccessPageComponent,
		AssetsAccessComponent
	],
	providers: [
		AssetAccessApiService,
	],
})
export class AccessManagementModule {}
