import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class HeatmapDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let calculation = this.visual.attributeSelections?.size?.displayName;
		let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		if (!calculation || !selectedAttribute) {
			return '';
		}
		let grouping = new Grouping(selectedAttribute?.displayName,
			this.utils.getGroupingType(selectedAttribute?.type));
		let split = this.properties.selectedAttributes.length > 1
			? this.locale.getString('widgetDescription.splitBy', {grouping: this.properties.selectedAttributes[1].displayName})
			: '';
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('heatmapPalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.heatmapDescription', {
			calculation,
			grouping,
			split,
			total,
			palette
		});
		let topItems = this.utils.getTopItems(data, selectedAttribute, this.visual.attributeSelections?.size?.name);
		if (topItems.length >= 2) {
			let topBoxes = this.locale.getString('widgetDescription.topBoxes', {
				box1: topItems[0],
				box2: topItems[1]
			});
			description = this.utils.joinMessageParts([description, topBoxes]);
		}
		return description;
	}
	
}
