import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';

@Component({
	selector: 'admin-report',
	template: `
<div class="col-md-4">
	<div class="panel panel-default text-center">
		<div class="panel-heading"><p class="mb-0" >{{getTitleText()}}</p></div>
		<div class="panel-body">
			<button
					type="button"
					class="btn btn-primary btn-flex"
					(click)="report()">
				<span class="q-icon q-icon-mail flex-shrink" aria-hidden="true"></span>
				<span>{{getButtonText()}}</span>
			</button>
		</div>
	</div>
</div>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminReportComponent implements OnInit {

	@Input() titleKey: string;
	@Input() textKey: string;
	@Output() createReport = new EventEmitter<void>();

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
	}

	getButtonText(): string  {
		return this.locale.getString(this.textKey);
	}

	getTitleText(): string  {
		return this.locale.getString(this.titleKey);
	}

	report(): void {
		this.createReport.emit();
	}

}
