import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class WidgetOwnerChangeAction implements DashboardChangeAction {

	private previousOwner: string;
	private newOwner: string;
	private widgetId: number;

	constructor(
		previousOwner: string,
		newOwner: string,
		widgetId: number,
	) {
		this.previousOwner = previousOwner;
		this.newOwner = newOwner;
		this.widgetId = widgetId;
	}

	reverse(): DashboardChangeAction {
		return new WidgetOwnerChangeAction(this.newOwner, this.previousOwner, this.widgetId);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let index = WidgetActionUtils.findWidgetIndexById(widgets, this.widgetId, idMapper);
		let widget = widgets[index];
		widget.properties.runAs = this.newOwner;
		return api.updateWidgetOwnership(widget.dashboardId, widget.id, this.newOwner);
	}
}
