import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'object-viewer-widget-preview'
})
export class ObjectViewerWidgetPreviewUpgrade extends UpgradeComponent {
	@Input() visualProperties;
	@Input() sampleData;
	@Input() redrawTrigger;
	@Input() utils;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('objectViewerWidgetPreview', elementRef, injector);
	}
}