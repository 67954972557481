import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { ApplicationThemeService } from '@app/core/application-theme.service';

@Component({
	selector: 'appearance-settings',
	templateUrl: './appearance-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppearanceSettingsComponent implements OnInit {
	showBranding: boolean;
	automatedNarrativesBetaEnabled: boolean;
	showCalendarFormat: boolean;

	constructor(
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('security') private readonly security: Security,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
		private readonly applicationThemeService: ApplicationThemeService
	) {
	}

	ngOnInit(): void {
		this.showBranding = this.security.getCurrentMasterAccount().customBrandingEnabled;
		this.automatedNarrativesBetaEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES);
		this.showCalendarFormat = this.betaFeaturesService.isFeatureEnabled(BetaFeature.ADVANCED_CALENDARS);
	}

	updateBranding = (enabled: boolean): void => {
		this.masterAccountApiService
			.updateProperty('customBranding', enabled)
			.then(
				() => {
					const FORCE_RELOAD = true;
					this.security.getCurrentMasterAccount().customBrandingEnabled = this.showBranding;
					this.applicationThemeService.applySelectedThemes(FORCE_RELOAD);
				}
			)
		;
	}
}

app.directive(
	'appearance',
	downgradeComponent({ component: AppearanceSettingsComponent }) as angular.IDirectiveFactory
);
