import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetTemplateLazyResources } from '@app/modules/unified-templates/common-templates/asset-template-resources.service';
import { TemplateReplacementUtilsService } from '@app/modules/unified-templates/common-templates/asset/template-replacement-utils.service';
import { TemplatePlaceholderReplacement } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-replacement';
import { TemplateReplacementData } from '@app/modules/unified-templates/common-templates/dto/template-replacement-data';
import { ReportAsset, ReportAssetTreeItem } from '@cxstudio/reports/entities/report-asset';

@Component({
	selector: 'template-replacement-selector',
	templateUrl: './template-replacement-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateReplacementSelectorComponent implements OnInit {

	@Input() replacement: TemplatePlaceholderReplacement;
	@Input() resources: AssetTemplateLazyResources;
	@Input() fullNodeReplacement: boolean;
	@Output() replacementChange = new EventEmitter<TemplateReplacementData>();

	options: ReportAssetTreeItem[];
	selection: ReportAsset;

	constructor(
		private ref: ChangeDetectorRef,
		private templateReplacementUtils: TemplateReplacementUtilsService,
	) { }

	ngOnInit(): void {
		this.getOptions().then(options => {
			this.options = options;
			let potentialMatch = this.templateReplacementUtils.findSimilarSelection(options,
				this.replacement.placeholderDisplayName, this.replacement.placeholderKey.assetIdentifier);
			if (potentialMatch) {
				this.selectOption(potentialMatch);
			}
			this.ref.markForCheck();
		});
	}

	private getOptions(): Promise<ReportAsset[]> {
		return this.templateReplacementUtils.getReplacementOptions(this.replacement, this.resources);
	}

	selectOption(node: ReportAsset): void {
		this.selection = node;
		let replacementData = this.fullNodeReplacement
			? node
			: this.templateReplacementUtils.convertNodeToReplacementData(node);
		this.replacementChange.emit(replacementData);
	}
}
