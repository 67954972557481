import { SortDirection } from '@cxstudio/common/sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';
import { DualAxisWidget } from '@cxstudio/reports/widgets/dual-axis-widget.class';
import { IReportWidgetContext, Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export class BarWidget extends DualAxisWidget {


	private readonly GROUPING_TYPES = [
		{ name: 'primaryGroup', index: 0, handler: (grouping) => this.setPrimaryGrouping(grouping) },
		{ name: 'secondaryGroup', index: 1, handler: (grouping) => this.initSecondGrouping(grouping) },
		{ name: 'stackedGroup', index: 2, handler: (grouping) => this.setStackedGrouping(grouping) }
	];

	constructor(metricConstants: MetricConstants) {
		super(WidgetType.BAR, metricConstants);

	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);
		this.exceptionsPreprocess(context);

		super.initDualAxis(context);

		this.initSubChartType(context);
		this.initClustering(context);
		this.initGroupings(context);
	}

	private setSecondaryGrouping(grouping: ReportGrouping): void {
		if (grouping && grouping.name !== '_calculation_series' && grouping.name !== StandardMetricName.NONE) {
			let secondaryGroupingName = 'secondary';
			super.setGrouping(grouping, secondaryGroupingName);
		}
	}

	private setStackedGrouping(grouping: ReportGrouping): void  {
		super.setGrouping(grouping, 'stacked');
		if (AnalyticsDefinitionUtils.isSupportStackSorting(grouping)) {
			this.widget.visualProperties.stackedDirection = SortDirection.DESC;
		}
	}

	private exceptionsPreprocess(context: IReportWidgetContext): void  {
		if (!_.isEmpty(context.replace)) return;
		//for pie second grouping ahould be stacked in bar (CXS-109)
		if (context.source.properties.widgetType === 'cb_an_pie') {
			let groupings = WidgetPropertyService.getValue(
				'properties.selectedAttributes', context, []);
			if (groupings[1]) {
				groupings[1].stackType = StackType.NORMAL;
			}
		}
		// for line point color used as color in bar (CXS-109)
		if (context.source.properties.widgetType === 'cb_an_line') {
			let pointColor = WidgetPropertyService.getValue(
				'visualProperties.pointColor', context);
			let customColor = WidgetPropertyService.getValue(
				'visualProperties.pointCustomColor', context);
			if (pointColor && pointColor !== 'inherit') {
				context.source.visualProperties.color = pointColor;
				context.source.visualProperties.customColor = customColor;
			}
		}
		if (context.source.properties.widgetType === 'cb_an_table' && context.source.properties.useHistoricPeriod) {
		//if table widget and PoP, need to add PoP for secondary or stacked grouping
			let numGroupings = context.source.properties.selectedAttributes.length;
			context.source.visualProperties.attributeSelections = numGroupings < 2
				? { secondaryGroup: this.POP_GROUPING }
				: { stackedGroup: this.POP_GROUPING };
		}
	}

	private initSecondGrouping(grouping: ReportGrouping): void  {
		if (grouping.stackType && grouping.stackType !== StackType.NONE) {
			this.setStackedGrouping(grouping);
		} else {
			delete grouping.stackType;
			this.setSecondaryGrouping(grouping);
		}
	}

	private initSubChartType(context: IReportWidgetContext): void  {
		let source = context.source;

		if (source.properties.widgetType === WidgetType.BAR) {
			super.inherit('visualProperties.subChartType', context, 'COLUMN');
		} else {
			this.widget.visualProperties.subChartType = ChartType.COLUMN;
		}
	}

	private initClustering(context: IReportWidgetContext): void  {
		let source = context.source;
		if (source.name !== 'cb_an_bar') return;

		super.inherit('visualProperties.cluster', context, false);
	}

	private initGroupings(context: IReportWidgetContext): void  {
		let groupings = WidgetPropertyService.getValue(
			'properties.selectedAttributes', context, []);
		if (groupings.length > 1) {
			this.widget.properties.normalizeGroups = context.source.properties.normalizeGroups;
		}
		let selections = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections', context, {});

		this.GROUPING_TYPES.forEach(groupingType => {
			let grouping = selections[groupingType.name];
			grouping = !_.isEmpty(grouping)
				? grouping : groupings[groupingType.index];
			if (grouping) {
				groupingType.handler(grouping);
				super.withGroupingDependencies(grouping, context);
			}
		});
	}
}
