export enum DarkBrandingColors {
	COLOR_1 = '#868898',
	COLOR_2 = '#ffffff',
	COLOR_3 = '#43444f',
	COLOR_4 = '#ced2f7',
	COLOR_5 = '#868898',
	COLOR_6 = '#ffffff',
	COLOR_7 = '#d3d4da',
	COLOR_8 = '#d3d4da',
	COLOR_9 = '#43444f',
	COLOR_10 = '#292930',
	COLOR_11 = '#ebebee'
}
