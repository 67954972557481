import * as _ from 'underscore';

import {TranscriptPiece} from '@cxstudio/reports/entities/transcript-piece';

export class VoiceDurationService {
	static getDurationOfLeadingSilence = (transcriptLine: TranscriptPiece, maxEndTimestamp: number): number => {
		let currentItemStart = transcriptLine.timestamp;

		// won't show silence in UI before this line of transcript if it starts before any prior sentence ends.
		if (maxEndTimestamp > currentItemStart) {
			return;
		}

		return currentItemStart - maxEndTimestamp;
	}
}
