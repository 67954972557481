<div class="d-flex flex-direction-row flex-nowrap">
	<div [ngClass]="{'btn-group': options.length > 1}">
		<ng-container *ngFor="let button of options | slice:0:collapsedAt">
			<button class="btn btn-secondary"
				(click)="clickMenu(button)"
				[attr.aria-label]="button.displayName"
				[title]="button.tooltip"
				[disabled]="button.disabled">
				{{ button.displayName }}
			</button>
		</ng-container>
		<div ngbDropdown class="btn-group">
			<button ngbDropdownToggle
				class="btn btn-secondary q-icon q-icon-angle-bottom ph-8 border-r-radius-2"
				*ngIf="options.length > collapsedAt"
				[attr.aria-label]="'common.moreOptions' | i18n">
			</button>
			<div ngbDropdownMenu>
				<a *ngFor="let menu of options | slice:collapsedAt"
					ngbDropdownItem
					(click)="clickMenu(menu)"
					href="javascript:void(0)"
					class="d-flex flex-direction-column"
					[title]="menu.tooltip"
					[disabled]="menu.disabled">
					<span>{{ menu.displayName }}</span>
				</a>
			</div>
		</div>
	</div>
</div>
