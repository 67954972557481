import { DashboardType } from '../entity/dashboard-type';

export class ObjectShareTableComponent implements ng.IComponentController {

	private forceIconClass: string;
	nameProperty: string;
	objects: any[];

	$onInit = () => {
		this.nameProperty = _.isUndefined(this.nameProperty) ? 'name' : this.nameProperty;
	}

	getObjectIconClass = (objectType: any): string => {
		if (this.forceIconClass)
			return this.forceIconClass;

		switch (objectType.type) {
			case DashboardType.DASHBOARD:
				return 'q-icon-chart-5';
			case DashboardType.BOOK:
				return 'q-icon-book';
		}
	}

	isMultipleObjects = (): boolean => this.objects.length > 1;
}

app.component('objectShareTable', {
	bindings: {
		objects: '=',
		nameProperty: '@?',
		forceIconClass: '@?'
	},
	controller: ObjectShareTableComponent,
	template: `
<div ng-if="$ctrl.objects.length" class="share-row share-object-row no-border">
	<b class="name-column">{{::'common.name'|i18n}}</b>
	<b class="owner-column">{{::'common.owner'|i18n}}</b>
</div>
<div
	class="share-row share-object-row"
    ng-repeat="item in $ctrl.objects | orderBy: $ctrl.nameProperty">
	<div class="name-column">
		<i class="{{::$ctrl.getObjectIconClass(item)}}"></i>
		{{::item[$ctrl.nameProperty]}}
	</div>
	<div class="owner-column">
		<span>{{::item.ownerName}}</span>
		<div class="cursor-pointer ml-8" ng-if="$ctrl.isMultipleObjects()">
			<i class="q-icon-delete" ng-click="$ctrl.objects.remove(item)"></i>
		</div>
	</div>
</div>
`
});
