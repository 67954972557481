
enum EventType {
	UPDATE_RECOMMEND_ITEMS = 'widget:updateRecommendItems',
	UPDATE_CALCULATION_SELECTOR = 'selector:updateCalculation',
	UPDATE_DATE_RANGE = 'widget:updateDateRange',
	UPDATE_CONTENT_WIDGET_HEADER_VISIBILITY = 'contentWidget:headerVisibility'
}

export default EventType;


