<div ngbDropdown #ngDropdown="ngbDropdown"
	class="search-list-dropdown inline-help-dropdown dropdown-input flex-fill"
	[autoClose]="'outside'"
	(openChange)="onOpenChange($event)"
	(keydown)="onDropdownKeydown($event)">
	<a ngbDropdownToggle
		id="custom-math-search-list-dropdown-toggle"
		class="search-list-selected-option"
		href="javascript:void(0)"
		role="button"
		aria-haspopup="true">
		{{selectPrompt}} <span class="caret"></span>
	</a>
	<div ngbDropdownMenu
		role="menu"
		class="searchable-list dropdown-menu">
		<ng-container *ngIf="ngDropdown.isOpen()">
			<ng-container *ngTemplateOutlet="suggestionsSearchList"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #suggestionsSearchList>
	<div #listItems
		class="button-bar-dropdown"
		(keydown)="handleKeyboardNavigation($event)">
		<ul class="pl-0 mb-0 scrolling-list"
			(scroll)="onSuggestionsDropdownScroll($event)">
			<li *ngIf="suggestions?.length"
				class="searchable-list-search-box"
				(click)="$event.stopPropagation();"
				(keydown)="onSearchKeydown($event)">
				<input #searchInput
					type="text"
					autocomplete="off"
					placeholder="{{'common.search'|i18n}}"
					class="w-100-percent"
					[(ngModel)]="searchFilterText"
					(ngModelChange)="resetAttributeSuggestion()">
			</li>
			<ng-container *ngIf="getFilteredSuggestions(suggestions)?.length; else noSuggestions">
				<ng-container *ngFor="let suggestion of getFilteredSuggestions(suggestions); index as suggestionIndex">
					<li class="searchable-list-item"
						[class.active]="submenuOpenedFromSuggestion === suggestion"
						(click)="handleSuggestionClick(suggestion)">
						<a ngbDropdownItem
							class="d-flex align-items-center justify-between"
							[ngClass]="getSuggestionClasses(suggestion)"
							href="javascript:void(0)"
							(keydown)="onDropdownItemKeydown($event, suggestion)"
							[title]="getSuggestionTitle(suggestion)">
							<div data-testid="suggestion-content">
								<icon class="mr-8" [name]="getSuggestionIcon(suggestion)"></icon>{{suggestion.displayName}}
							</div>
							<div class="d-flex justify-content-end" *ngIf="suggestion.inlineHelpElements">
								<ng-container *ngFor="let inlineHelpElement of suggestion.inlineHelpElements">
									<cb-inline-help
										*ngIf="inlineHelpElement.visible"
										fixed="true">
										<icon [name]="inlineHelpElement.icon"></icon>
										<help-body>{{inlineHelpElement.text}}</help-body>
									</cb-inline-help>
								</ng-container>
							</div>
							<span
								*ngIf="showSubmenuArrow(suggestion)"
								class="submenu-arrow q-icon q-icon-triangle-right pl-8"
								aria-hidden="true"
							></span>
						</a>
						<numeric-aggregations-submenu
							*ngIf="showAggregationsSubmenu(suggestion)"
							dropdownSelector=".button-bar-dropdown"
							(submenuItemClick)="onSubmenuItemClick($event)"
					></numeric-aggregations-submenu>
					</li>
				</ng-container>
			</ng-container>
			<ng-template #noSuggestions>
				<li class="no-wrap searchable-list-empty">
					<i>{{(suggestions?.length > 0
						? 'reportFilters.noMatchedOptions'
						: 'reportFilters.noOptionsRemaining')|i18n}}
					</i>
				</li>
			</ng-template>
		</ul>
	</div>
</ng-template>
