import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HighchartsClosureUtils, HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { CaseEventData, HighchartsCase } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case';
import { HighchartsCaseUtilsService, ICaseEventPoint } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case-utils.service';
import { BubbleData, XRangeData } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case-utils.service';
import { DualDefinitionUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-utils.class';
import { HighchartsDualDefinitionService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/highcharts-dual-definition.service';
import { ChartCallback } from '@app/modules/widget-visualizations/highcharts/highcharts-renderer.service';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { TimeGrouping } from '@cxstudio/reports/groupings/time-grouping';
import { DateInterval, PointMetadataUtils } from '@app/modules/plot-lines/reference-lines/point-metadata-utils';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { HighchartsCaseLabelUtils } from '../highcharts-case-label-utils.class';

@Injectable({
	providedIn: 'root'
})
export class HighchartsCasesDefinitionService {
	private static readonly CROSSHAIR_ID = 'crosshair_plotline';

	constructor(
		private highchartsUtils: HighchartsUtilsService,
		private highchartsDualDefinition: HighchartsDualDefinitionService,
		private locale: CxLocaleService,
		private highchartsCaseUtils: HighchartsCaseUtilsService,
		@Inject('highchartsAccessibilityUtils') private readonly highchartsAccessibilityUtils: HighchartsAccessibilityUtils
	) {}

	getChartOptions(
		dataObject: ReportDataObject, visualProps: VisualProperties,
		utils: WidgetUtils, demo: boolean = false
	): Highcharts.Options {
		let cases = this.getCases(dataObject, visualProps, utils, demo);
		let dateRanges: DateInterval[] = demo ? this.getDemoDateRanges(cases) : this.getDataDateRanges(dataObject, visualProps, utils);
		let caseRows: HighchartsCase[][] = this.highchartsCaseUtils.getCaseLanes(cases, dateRanges);
		let trendBy = visualProps.attributeSelections.primaryGroup.trendBy;

		return Highcharts.merge(this.highchartsUtils.getDefaultConfig(), {
			chart: {
				type: 'xrange',
				marginTop: 3,
				marginBottom: 3,
				plotBorderWidth: 1,
				plotBorderColor: this.highchartsAccessibilityUtils.pickLightOrDarkColor(
						ColorConstants.GRAY_300, ColorConstants.WHITE),
				events: {
					load: HighchartsCaseLabelUtils.preventLabelOverflow,
					redraw: HighchartsCaseLabelUtils.preventLabelOverflow
				}
			},
			accessibility: {
				point: {
					descriptionFormatter: (point: Partial<HighchartsFunctionScope>) => {
						if (point.marker) {
							let pointObject = point.object as ICaseEventPoint;
							let eventsCount = pointObject.caseItem.events?.length;
							let timeFrame = this.highchartsUtils.formatTrendLabel(pointObject.dateName, trendBy);
							return this.locale.getString('cases.caseEventsAriaLabel', { eventsCount, timeFrame });
						} else {
							let pointObject = point.object as HighchartsCase;
							let caseName = pointObject.title;
							return this.locale.getString('cases.caseAriaLabel', { caseName });
						}
					}
				}
			},
			xAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				tickWidth: 0,
				categories: _.range(0, dateRanges.length).map(i => ''), // required to match trend chart ticks
				labels: {
					enabled: false
				},
				min: 0,
				max: dateRanges.length - 1
			},
			yAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				tickWidth: 0,
				title: {
					text: this.locale.getString('cases.caseMgtTitle'),
				},
				labels: {
					enabled: false
				},
				reversed: true,
				min: -0.5,
				tickInterval: 0.5,
				startOnTick: true,
				endOnTick: true
			},
			plotOptions: {
				xrange: {
					grouping: false, // to avoid vertical shifting of each series,
					dataLabels: {
						useHTML: true
					}
				}
			},
			tooltip: {
				useHTML: true,
			},
			series: [].concat(this.buildXRangeSeries(caseRows, dateRanges, utils))
				.concat(this.buildBubbleSeries(caseRows, dateRanges))
		} as Highcharts.Options);
	}

	getCases(dataObject: ReportDataObject, visualProps: VisualProperties, utils: WidgetUtils, demo: boolean): HighchartsCase[] {
		let cases: HighchartsCase[] = utils.engagorCases;

		if ((window as any).fakeCases && !demo) { // remove with CASE_VIZ_ON_TRENDS beta
			// keeping for test purposes until we get sufficient data on engagor
			cases = this.highchartsCaseUtils.generateCases(this.getDataDateRanges(dataObject, visualProps, utils));
		}

		if (!_.isEmpty(cases)) {
			cases.forEach(acase => acase.uiId = uuid.v4());
		}

		return cases;
	}

	getDataDateRanges(dataObject: ReportDataObject, visualProps: VisualProperties,
			utils: WidgetUtils): DateInterval[] {
		let dualUtils = new DualDefinitionUtils(visualProps, utils);
		let data = this.highchartsDualDefinition.getHierarchyData(dualUtils, dataObject, visualProps, utils, false);
		return PointMetadataUtils.extractDateIntervals(data, visualProps.attributeSelections.primaryGroup as TimeGrouping);
	}

	private getDemoDateRanges(cases: HighchartsCase[]): DateInterval[] {
		if (_.isEmpty(cases)) {
			return [];
		}

		let allEvents = _.chain(cases).map(caseItem => caseItem.events).flatten().value();

		let minEventDate = (_.min(allEvents, event => event.eventDate) as CaseEventData).eventDate;
		let maxEventDate = (_.max(allEvents, event => event.eventDate) as CaseEventData).eventDate;

		let minStartDate = (_.min(cases, caseItem => caseItem.startDate) as HighchartsCase).startDate;
		let maxStartDate = (_.max(cases, caseItem => caseItem.startDate) as HighchartsCase).startDate;
		let maxEndDate = (_.max(cases, caseItem => caseItem.endDate) as HighchartsCase).endDate;

		let fromDate = moment(_.min([minEventDate, minStartDate]));
		let toDate = moment(_.max([maxEventDate, maxStartDate, maxEndDate]));

		const steps = 4;
		let stepLength = toDate.diff(fromDate) / steps;

		return _.range(0, steps).map(i => ({
			name: `Demo range {i + 1}`,
			from: fromDate.clone().add(i * stepLength),
			to: fromDate.clone().add((i + 1) * stepLength)
		}));
	}

	buildXRangeSeries(caseRows: HighchartsCase[][], dateRanges: DateInterval[], utils: WidgetUtils): SeriesOptionsType[] {
		let xRangeData: XRangeData[] = this.highchartsCaseUtils.getXRangeData(caseRows, dateRanges, utils.colorFunction);
		xRangeData = this.getXRangeDataMatchingDateRanges(xRangeData, dateRanges);
		return xRangeData.map(xRangeDataPiece => this.buildXRangeSerie(xRangeDataPiece));
	}

	private buildXRangeSerie(xRangeDataPiece: XRangeData): SeriesOptionsType {
		return {
			type: 'xrange',
			name: '',
			showInLegend: false,
			pointWidth: 20,
			// requires highcharts upgrade to 9.x, this is required so bar doesn't end in the middle of bubble
			//pointPlacement: -0.5,
			dataLabels: {
				enabled: true,
				formatter: HighchartsCaseLabelUtils.getCaseLabelFormatter(),
				inside: true,
				align: 'left',
				crop: false,
				overflow: 'allow',
				y: -20,
				x: xRangeDataPiece.x === xRangeDataPiece.x2 ? -12 : undefined
			},
			tooltip: {
				headerFormat: '',
				pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
					return (scope.object as HighchartsCase).title;
				})
			} as any,
			data: [xRangeDataPiece]
		};
	}

	buildBubbleSeries(caseRows: HighchartsCase[][], dateRanges: DateInterval[]): any[] {
		let bubblesSeriesData: BubbleData[][] = this.highchartsCaseUtils.getBubblesSeriesData(caseRows, dateRanges);
		return bubblesSeriesData.map(bubblesSerieData => this.buildBubblesSerie(bubblesSerieData));
	}

	private buildBubblesSerie(bubblesSerieData: BubbleData[]): any {
		return {
			type: 'bubble',
			maxSize: '20px',
			showInLegend: false,
			dataLabels: {
				enabled: true,
				inside: true,
				formatter: HighchartsClosureUtils.closureWrapper((scope) => {
					return (scope.point.object as ICaseEventPoint).events.length;
				}),
				overflow: 'allow',
				crop: false,
				color: this.highchartsAccessibilityUtils.pickLightOrDarkColor(ColorConstants.WHITE, ColorConstants.CHARCOAL)
			},
			tooltip: {
				headerFormat: '',
				pointFormatter: HighchartsClosureUtils.closureWrapper((scope) => {
					return this.highchartsCaseUtils.getEventsHtml(scope.object);
				}),
			},
			marker: {
				lineWidth: 2,
				fillColor: ColorConstants.WHITE,
				fillOpacity: 1,
			},
			data: bubblesSerieData
		};
	}

	synchronizeCrosshairsCallback(dualChartProvider: () => Highcharts.Chart, caseChartProvider: () => Highcharts.Chart): ChartCallback {
		return (chart: Highcharts.Chart) => {
			let container = $(chart.container);
			let x1: number;
			let x2: number;
			let y: number;
			let casesContainer;
			let dualContainer;

			let moveCrosshair = (pos: { clientX: number, clientY: number}): void => {
				// TODO refactor this logic, currently copy-pasted from a prototype
				let caseChart = caseChartProvider();
				let dualChart = dualChartProvider();
				if (!caseChart || !dualChart)
					return;
				if (_.isEmpty(caseChart.series) || _.isEmpty(dualChart.series)) // no visible data on some chart
					return;
				if (!casesContainer || !dualContainer) {
					casesContainer = $(caseChart.container);
					dualContainer = $(dualChart.container);
				}

				x1 = pos.clientX - caseChart.plotLeft - casesContainer.offset().left;
				let xVal1 = caseChart.xAxis[0].toValue(x1, true);

				x2 = pos.clientX - dualChart.plotLeft - dualContainer.offset().left;
				let xVal2 = dualChart.xAxis[0].toValue(x2, true);

				let yAxis = chart.yAxis[0];
				y = pos.clientY - chart.plotTop - container.offset().top;
				let yVal = yAxis.toValue(y, true);

				//remove old crosshair and draw new crosshair on x-range chart
				let xAxis1 = caseChart.xAxis[0];
				xAxis1.removePlotLine(HighchartsCasesDefinitionService.CROSSHAIR_ID);
				xAxis1.addPlotLine(this.getPlotLineProps((caseChart.xAxis[0] as any).translate(x1, true)));
				_.each(caseChart.series[0].points, (point: Highcharts.XrangePointOptionsObject) => {
					if (point.x < xVal1 && point.x2 > xVal1 && chart === caseChart && point.y === yVal) {
						caseChart.tooltip.refresh(point as Highcharts.Point);
					}
				});

				//remove old crosshair and draw new crosshair for line chart
				let xAxis2 = dualChart.xAxis[0];
				let points2 = dualChart.series[0].points;
				xAxis2.removePlotLine(HighchartsCasesDefinitionService.CROSSHAIR_ID);
				xAxis2.addPlotLine(this.getPlotLineProps((dualChart.xAxis[0] as any).translate(x2, true)));
				_.each(points2, (point, i) => {
					if (i + 1 < points2.length && point.x <= xVal2 && points2[i + 1].x > xVal2) {
						//reset state
						point.setState();
						points2[i + 1].setState();

						if (xVal2 - point.x <= points2[i + 1].x - xVal2) {
							dualChart.tooltip.refresh(point);
							point.setState('hover');
						} else {
							dualChart.tooltip.refresh(points2[i + 1]);
							points2[i + 1].setState('hover');
						}
					}
				});
			};

			container.find('.highcharts-point').trigger('focus', (evt) => {
				const isCaseBar = $(evt.currentTarget).parents('.highcharts-xrange-series').length > 0;
				if (isCaseBar) {
					return;
				}
				const rect = evt.currentTarget.getBoundingClientRect();
				const clientX = rect.x + rect.width / 2;
				const clientY = rect.y + rect.height / 2;
				moveCrosshair({clientX, clientY});
			});
			container.trigger('mousemove', (evt) => {
				moveCrosshair(evt);
			});
		};
	}

	getDateRanges(dataObject: ReportDataObject, visualProps: VisualProperties, utils: WidgetUtils, demo: boolean): DateInterval[] {
		let cases = this.getCases(dataObject, visualProps, utils, demo);
		return demo ? this.getDemoDateRanges(cases) : this.getDataDateRanges(dataObject, visualProps, utils);
	}

	hasAvailableSelectedCases(dataObject: ReportDataObject, visualProps: VisualProperties, utils: WidgetUtils, demo: boolean): boolean {
		let cases = this.getCases(dataObject, visualProps, utils, demo);
		let dateRanges: DateInterval[] = demo ? this.getDemoDateRanges(cases) : this.getDataDateRanges(dataObject, visualProps, utils);
		let caseRows: HighchartsCase[][] = this.highchartsCaseUtils.getCaseLanes(cases, dateRanges);

		let xRangeData: XRangeData[] = this.highchartsCaseUtils.getXRangeData(caseRows, dateRanges);

		return this.getXRangeDataMatchingDateRanges(xRangeData, dateRanges).length > 0;
	}

	// filter cases whose dateFrom is after dateRanges OR dateTo is before dateRanges
	getXRangeDataMatchingDateRanges(xRangeData: XRangeData[], dateRanges: DateInterval[]): XRangeData[] {
		return _.filter(xRangeData, singleXrangeData => singleXrangeData.x !== dateRanges.length && singleXrangeData.x2 !== -1);
	}

	private getPlotLineProps(value: number): Highcharts.AxisPlotLinesOptions {
		return {
			value,
			width: 1,
			color: this.highchartsAccessibilityUtils.pickLightOrDarkColor(ColorConstants.BLACK, ColorConstants.WHITE),
			dashStyle: 'Dash',
			id: HighchartsCasesDefinitionService.CROSSHAIR_ID
		};
	}

}
