import EventType from './event-type.enum';

export interface IEventEmitter {
	subscribe(eventType: EventType, handler: (data?: any) => void): IHandlerCallback;
	emit(eventType: EventType, data?: any): void;
}

export interface IHandlerCallback {
	unsubscribe(): void;
}

export class EventEmitterService implements IEventEmitter {

	private subscriptions = {};

	subscribe(eventType: EventType, handler: (data?: any) => void): IHandlerCallback {
		if (!this.subscriptions[eventType]) {
			this.subscriptions[eventType] = [];
		}
		this.subscriptions[eventType].push(handler);

		let isSubscribed = true;
		return {
			unsubscribe: () => {
				if (!isSubscribed) {
					return;
				}
				isSubscribed = false;
				this.subscriptions[eventType] = this.subscriptions[eventType].filter(h => h !== handler);
			}
		};
	}

	emit(eventType: EventType, data?: any): void {
		if (this.subscriptions[eventType]) {
			this.subscriptions[eventType].forEach(h => h(data));
		}
	}
}


app.service('eventEmitterService', EventEmitterService);
