import * as _ from 'underscore';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MapBackground } from '@cxstudio/attribute-geography/map-background';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import ProjectGeographies from '@cxstudio/attribute-geography/project-geographies';
import ModelGeography from '@cxstudio/attribute-geography/model-geography';
import { IMapAlikeCommonSettings } from '@cxstudio/reports/providers/cb/definitions/map-alike-common-settings.factory';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { RealDataPreviewWidget } from './real-data-preview-widget.class';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';

interface MapBackgroundOption {
	name: MapBackground;
	displayName: string;
}

interface MapVisualProperties extends VisualProperties {
	mapBackground: MapBackgroundOption;
}

interface MapDefinitionControllerScope extends ng.IScope {
	props: WidgetProperties;
	visualProps: MapVisualProperties;
	mapBackgroundOptions: MapBackgroundOption[];
	options: any;
}

export class CBAnalyticMapChartCtrl extends RealDataPreviewWidget {
	widgetType: WidgetType;

	readonly PRIMARY_GROUPING_INDEX = 0;
	readonly COLOR_GROUPING_INDEX = 1;

	private mapAlikeCommonSettings: IMapAlikeCommonSettings;

	constructor(
		protected readonly $scope,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly metricConstants: MetricConstants,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,

		private $controller: ng.IControllerService,
		private MapAlikeCommonSettings,
		private locale: ILocale,
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit = () => {
		this.widgetType = this.$scope.props.widgetType;

		this.$scope.hasPreviewChanges = (): boolean => {
			return this.$scope.showRealDataPreview() && this.realDataPreviewService.hasPreviewChanges();
		};

		this.$scope.changeInputColor = (): void => {
			if (!this.$scope.hasPreviewChanges()) {
				this.$scope.updateChartSettings(PreviewChartRefreshType.UI_ONLY);
			}
		};

		this.$scope.onColorByAlternateGroupingRemove = (): void => {
			if (this.$scope.showRealDataPreview()) {
				this.$scope.props.selectedAttributes = [this.$scope.props.selectedAttributes[0]];
				this.realDataPreviewService.setPrevWidgetProps(this.$scope.props);
				this.realDataPreviewService.setPreviewHasChanges(true);
			} else {
				this.$scope.props.selectedAttributes[1] = undefined;
			}
		};

		super.$onInit();

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this.$scope,
			selectionConfiguration: {
				multiAttributeSelection: true
			},
			customCallback: {
				addGroup: this.addGrouping
			}
		});
		this.$scope.ui = this.$scope.ui || {};
		this.mapAlikeCommonSettings = new this.MapAlikeCommonSettings(this.$scope, VisualizationType.MAP);
		this.mapAlikeCommonSettings.initProps();
		this.initialize();
	}

	private initialize = () => {
		this.$scope.visualProps.visualization = WidgetVisualization.CB_AN_MAP;

		this.$scope.staticData.ready = false;
		this.initializeBackgroundOptions();

		this.$scope.options.additionalMetrics = undefined;
		this.$scope.$on('clearSettings', () => {
			this.mapAlikeCommonSettings.initProps();
			this.$scope.visualProps.mapBackground = _.findWhere(this.$scope.mapBackgroundOptions, { name: MapBackground.BASIC });
		});
		this.$scope.$on('reloadSettings', this.mapAlikeCommonSettings.reloadSettings);

		this.$scope.ui = this.$scope.ui || {};
		this.$scope.ui.listTypes = KeyMetricListTypes;

		this.mapAlikeCommonSettings.initialize();

		let constants = this.metricConstants.get();

		if (!this.$scope.props?.selectedMetrics || !this.$scope.props?.selectedMetrics[0]) {
			this.$scope.selectMetricAndRefreshView(constants.VOLUME);
			this.processAttributesAndMetrics();
		}

		this.$scope.onBackgroundChanged = this.onBackgroundChanged;
		this.$scope.getColorGroupingLabel = this.getColorGroupingLabel;
	}

	private processAttributesAndMetrics = (): void => {
		this.mapAlikeCommonSettings.processAttributesAndMetrics(['color', 'sortBy']);
	}

	private initializeBackgroundOptions = (): void => {
		this.$scope.mapBackgroundOptions = [
			{ name: MapBackground.NONE, displayName: this.locale.getString('common.none') },
			{ name: MapBackground.BASIC, displayName: this.locale.getString('widget.basicBackground') },
			{ name: MapBackground.STREET, displayName: this.locale.getString('widget.streetBackground') },
			{ name: MapBackground.SATELLITE, displayName: this.locale.getString('widget.satelliteBackground') }
		];
	}

	private addGrouping = (grouping: AttributeGrouping | TopicReportGrouping, index: number): void => {
		if (index === this.PRIMARY_GROUPING_INDEX) {
			let primaryGrouping = this.$scope.props.selectedAttributes[this.PRIMARY_GROUPING_INDEX];
			this.processPrimaryGroupingSelection(primaryGrouping);
		} else if (index === this.COLOR_GROUPING_INDEX) {
			let colorGrouping = this.$scope.props.selectedAttributes[this.COLOR_GROUPING_INDEX];
			this.processColorGroupingSelections(colorGrouping);
		}

		let loadingPromise = this.mapAlikeCommonSettings.populateSampleData()
			.then(() => this.$scope.applyVisualChanges());

		this.$scope.addLoadingPromise(loadingPromise);
	}

	private processPrimaryGroupingSelection = (grouping: AttributeGrouping): void => {
		if (grouping.metricType === AnalyticMetricType.CLARABRIDGE) {
			let projectGeographies: ProjectGeographies = this.$scope.options.projectGeographies;

			let geography = _.find(projectGeographies.modelGeographies,
				(modelGeography: ModelGeography) => modelGeography.modelId === Number.parseInt(grouping.name, 10));
			let keys = Object.keys(geography.boundaryFields).map(key => Number.parseInt(key, 10));
			keys.sort();

			let defaultSelectedLevel = keys[0] + 1;
			(grouping as TopicReportGrouping).selectedLevel = defaultSelectedLevel;
		}
	}

	private processColorGroupingSelections = (grouping: AttributeGrouping): void =>  {
		grouping.size = 1;
		grouping.lockSize = true;
	}

	private onBackgroundChanged = (): void => {
		this.$scope.applyVisualChanges();
	}

	private getColorGroupingLabel = (): string => {
		return this.locale.getString('widget.colorGroupingLabel', {
			groupingName: this.$scope.props.selectedAttributes[0]?.displayName
		});
	}
}

app.controller('CBAnalyticMapChartCtrl', CBAnalyticMapChartCtrl);
