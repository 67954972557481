

export enum ShareAction {
	CREATE = 'create',
	UPDATE = 'update',
	DELETE = 'delete',
	ADD = 'add'
}


