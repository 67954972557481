import VisualProperties from '@cxstudio/reports/entities/visual-properties';

export class CloudVisualProperties extends VisualProperties {
	cloudShape: CloudShape;
	cloudSizing: CloudSizing;
	orientations: number; //[1..4]
	minimumFontSize: number; //[4..32]
}

export enum CloudShape {
	CLOUD = 'cloud',
	RECTANGLE = 'rectangle'
}

export enum CloudSizing {
	DEFAULT = 'optionOne',
	LINEAR = 'optionTwo'
}
