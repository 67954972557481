import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { PlaceholderDisplayType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { IPlaceholderItem } from '@app/modules/unified-templates/common-templates/template/template-placeholders-list/template-placeholders-list.component';

@Component({
	selector: 'template-mappings',
	template: `
<p class="mb-16" [i18n]="'templates.templatePlaceholderDescription'"></p>
<ng-container *ngFor="let item of placeholders | keyvalue">
	<template-placeholders-list
		[placeholders]="item.value"
		[label]="getDisplayTypeLabel(item.key)">
	</template-placeholders-list>
</ng-container>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateMappingsComponent {

	@Input() placeholders: IPlaceholderItem[];

	constructor(
		private locale: CxLocaleService,
	) { }

	getDisplayTypeLabel(type: PlaceholderDisplayType): string {
		switch (type) {
			case PlaceholderDisplayType.FILTER_RULE: // same label as selection
			case PlaceholderDisplayType.SELECTION: return this.locale.getString('templates.placeholderOriginalSelection');
			case PlaceholderDisplayType.GROUPING: return this.locale.getString('templates.placeholderOriginalGroupings');
			case PlaceholderDisplayType.CALCULATION: return this.locale.getString('templates.placeholderOriginalCalculations');
		}
	}

}
