import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateCreatePlaceholdersListComponent } from './template-create-placeholders-list/template-create-placeholders-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DashboardTemplateCreateDialogComponent } from './dashboard-template-create-dialog/dashboard-template-create-dialog.component';
import { DashboardTemplateService } from './dashboard-template-service.service';
import { AvailablePlaceholdersPipe } from './available-placeholders.pipe';
import { TemplateEditDialogComponent } from './template-edit-dialog/template-edit-dialog.component';
import { TemplateEditMappingsDialogComponent } from './template-edit-mappings-dialog/template-edit-mappings-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CommonTemplatesModule } from '@app/modules/unified-templates/common-templates/common-templates.module';

@NgModule({
	declarations: [
		TemplateCreatePlaceholdersListComponent,
		DashboardTemplateCreateDialogComponent,
		AvailablePlaceholdersPipe,
		TemplateEditDialogComponent,
		TemplateEditMappingsDialogComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		DialogModule,
		CommonTemplatesModule,
	],
	providers: [
		DashboardTemplateService,
		AvailablePlaceholdersPipe
	],
	exports: [
		AvailablePlaceholdersPipe,
		TemplateCreatePlaceholdersListComponent
	]
})
export class DashboardTemplatesModule { }
