import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import * as _ from 'underscore';

export class RecolorUtils {

	static addWidgetRecolor(point: Partial<IDataPointObject>, color: string, visualProps: VisualProperties): void {
		if (!visualProps.recolors) {
			visualProps.recolors = [];
		}
		let recolorData = visualProps.recolors;
		let name = point[point._group.identifier];
		let item = _.find(recolorData, ColorUtilsHelper.findMatchedRecolor(point));

		if (item) {
			item.color = color;
		} else {
			recolorData.push({
				uniqueName: name,
				colorType: point.colorType || 'color',
				groupName: point._group.name,
				groupIdentifier: point._group.identifier,
				color,
				_pop: point._pop
			});
		}
	}

}
