import { ReportResponsiveness } from './report-responsiveness';
import { WidgetReportDisplayState } from './widget-report-display-state';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ResponsiveReportService } from './responsive-report-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Security } from '@cxstudio/auth/security-service';

export class ResponsiveStatusBarComponentController implements ng.IComponentController {

	readonly LABEL_UPDATE_THRESHOLD = 15_000;

	private widget: Widget;
	private viewingFreshData: boolean;
	private viewingFreshDataNoChange: boolean;
	private previousState: WidgetReportDisplayState;
	private currentState: WidgetReportDisplayState;

	constructor(
		private $rootScope: ng.IRootScopeService,
		private locale: ILocale,
		private responsiveReportService: ResponsiveReportService,
		private $timeout: ng.ITimeoutService,
		private security: Security,
	) {
	}

	$onInit(): void {
	}

	isLoadingFreshData(): boolean {
		let state = this.widget.responsiveState;

		return state.responsiveness === ReportResponsiveness.RESPONSIVE
			&& state.displayState === WidgetReportDisplayState.LOADING_FRESH_DATA;
	}

	getLoadingFreshDataLabel(): string {
		let startTime = this.widget.responsiveState?.freshReportResult?.startTime || Date.now();
		let timePassed = Date.now() - startTime;
		let key = timePassed > this.LABEL_UPDATE_THRESHOLD
			? 'widget.refreshingWidgetData2'
			: 'widget.refreshingWidgetData';
		return this.locale.getString(key);
	}

	isReadyToDisplayFreshData(): boolean {
		return this.responsiveReportService.isReadyToDisplayFreshData(this.widget)
			&& !this.security.loggedUser.isEnterpriseUser;
	}

	displayWidgetsFreshData(): void {
		this.$rootScope.$broadcast('displayFreshData');
	}

	$doCheck(): void {
		this.currentState = this.widget.responsiveState?.displayState;
		if (this.currentState !== this.previousState) {
			if ( this.currentState === WidgetReportDisplayState.DISPLAYING_FRESH_DATA
				&& this.previousState === WidgetReportDisplayState.DISPLAYING_CACHED_DATA) {
				this.viewingFreshData = true;
				this.$timeout(() => {
					this.viewingFreshData = false;
				}, 2000);
			}

			if ( this.currentState === WidgetReportDisplayState.DISPLAYING_FRESH_DATA_NO_CHANGE
				&& this.previousState === WidgetReportDisplayState.LOADING_FRESH_DATA) {
				this.viewingFreshDataNoChange = true;
				this.$timeout(() => {
					this.viewingFreshDataNoChange = false;
				}, 2000);
			}
			this.previousState = this.widget.responsiveState?.displayState;
		}
	}
}

app.component('responsiveStatusBar', {
	bindings: {
		widget: '<',
	},
	controller: ResponsiveStatusBarComponentController,
	templateUrl: 'partials/widgets/responsive-status-bar.component.html'
});
