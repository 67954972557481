<page-content-header [title]="'header.manageUnifiedTemplates' | i18n" class="mb-24"></page-content-header>
<div class="fill-container" *ngIf="hasUnifiedTemplatesManagementAccess()">
	<div class="bg-secondary p-24 d-flex flex-direction-column fill-container">
		<ul ngbNav #nav="ngbNav" class="nav-tabs">
			<li ngbNavItem *ngIf="ui.showDashboardTemplate">
				<a ngbNavLink>{{'templates.legacyDashboards'|i18n}}</a>
				<ng-template ngbNavContent>
					<dashboard-templates-management></dashboard-templates-management>
				</ng-template>
			</li>
			<li ngbNavItem *ngIf="ui.showDashboardTemplate">
				<a ngbNavLink data-testid="dashboard-templates-tab">{{'object.dashboards'|i18n}}</a>
				<ng-template ngbNavContent>
					<asset-templates-table
						class="fill-container"
						[searchLabel]="'templates.findDashboardTemplates'|i18n"
						[assetType]="TemplateAssetType.DASHBOARD">
					</asset-templates-table>
				</ng-template>
			</li>
			<li ngbNavItem *ngIf="ui.showMetricTemplate">
				<a ngbNavLink data-testid="metric-templates-tab">{{'object.metrics'|i18n}}</a>
				<ng-template ngbNavContent>
					<asset-templates-table
						class="fill-container"
						[searchLabel]="'templates.findMetricTemplates'|i18n"
						[assetType]="TemplateAssetType.METRIC">
					</asset-templates-table>
				</ng-template>
			</li>
			<li ngbNavItem *ngIf="ui.showFilterTemplate">
				<a ngbNavLink data-testid="filter-templates-tab">{{'object.filters'|i18n}}</a>
				<ng-template ngbNavContent>
					<asset-templates-table
						class="fill-container"
						[searchLabel]="'templates.findFilterTemplates'|i18n"
						[assetType]="TemplateAssetType.FILTER">
					</asset-templates-table>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2 flex-fill tab-content-fill-container"></div>
	</div>
</div>
