import { Injectable, Inject } from '@angular/core';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Security } from '@cxstudio/auth/security-service';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';

@Injectable({ providedIn: 'root' })
export class DriversService {
	constructor(
		private driversApi: DriversApi,
		@Inject('security') private security: Security,
	) {}

	getReportableDrivers(project: AccountOrWorkspaceProject): AssetPromise<DriversItem[]> {
		if (!this.security.has('manage_drivers')) {
			return Promise.resolve([]) as AssetPromise<DriversItem[]>;
		}
		return ProjectAssetsHelper.getAssetsForProject<DriversItem[]>(
			project,
			(accountProject) => this.driversApi.getAccountProjectReportableDrivers(accountProject),
			(workspaceProject) => this.driversApi.getWorkspaceProjectReportableDrivers(workspaceProject),
			(projectId) => []
		);
	}
}
