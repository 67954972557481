<modal-header
	(cancel)="cancel()"
	[modalTitle]="'mobile.transferAppDialogTitle' | i18n">
</modal-header>

<div class="modal-body">
	<p class="br-dialog-message" [innerHTML]="getPromptText()"></p>
	<p class="br-dialog-message font-bold">{{'mobile.transferAppDialogWarning'|i18n}}</p>

	<div class="labeled-checkbox mt-16">
		<input name="retainEditPermission" id="retainEditPermission" type="checkbox" [(ngModel)]="output.retainingEditPermission">
		<label for="retainEditPermission">{{'administration.retainShareEditAccess'|i18n}}</label>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="cancel()">{{'common.cancel'|i18n}}</button>
	<button type="button" class="btn btn-primary br-btn-confirm-owner-change" (click)="save()">{{'common.yes'|i18n}}</button>
</div>
