import { EventEmitter, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';
import { CalendarSettingsEntry } from '../calendar-settings.entity';
import { CalendarFormatApi } from './calendar-format-api-service';
import { CalendarFormatModalComponent } from './calendar-format-modal/calendar-format-modal.component';

@Injectable()
export class CalendarFormatListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private toastService: ToastService,
		private readonly calendarFormatApi: CalendarFormatApi
	) { }

	edit(settingsEntry: CalendarSettingsEntry): void {
				this.cxDialogService.openDialog(CalendarFormatModalComponent, settingsEntry, {size: ModalSize.SMALL}).result
					.then(this.saveSettings)
					.catch(() => {});
			}

	private saveSettings = (settingsEntry: CalendarSettingsEntry, customMessage?: string): void => {
		this.calendarFormatApi.saveCalendarFormatByProject(settingsEntry).then(() => {
			this.toastService.addToast(customMessage || this.locale.getString('calendar.calendarFormatSavedToast'), AlertLevel.SUCCESS);
			this.onChange.emit();
		});
	}

	toggle(settings: CalendarSettingsEntry): void {
		settings.enabled = !settings.enabled;
		let confirmationMessage = settings.enabled ?
			this.locale.getString('common.itemEnabled', {itemName: settings.projectName}) :
			this.locale.getString('common.itemDisabled', {itemName: settings.projectName});

		return this.saveSettings(settings, confirmationMessage);
	}
}
