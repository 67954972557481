<div class="login-wrapper">
	<login-banner
		[message]="getMessage()"
	></login-banner>
	<app-logo></app-logo>
	<div *ngIf="!submitted" class="login-form" [ngClass]="{ 'border-t-radius-0': !!getMessage() }">
		<div class="form-group email">
			<label for="in-username" [i18n]="'login.email'"></label>
			<input id="in-username"
				attr.aria-required="true"
				attr.aria-label="{{'login.emailField'|i18n}}"
				type="text"
				name="email"
				class="form-control"
				[(ngModel)]="email"
				autofocus="">
		</div>
		<div class="d-flex">
			<button
				id="btn-back"
				class="btn btn-secondary btn-flex btn-25"
				(click)="return()">
				<span [i18n]="'common.back'"></span>
			</button>
			<button id="btn-reset-password"
				class="btn btn-primary btn-flex btn-75"
				[disabled]="isResetDisabled()"
				(click)="resetPassword()">
				<span [i18n]="'login.sendPasswordReset'"></span>
			</button>
		</div>
	</div>
	<div *ngIf="submitted" class="login-form">
		<button id="btn-back"
			class="btn btn-primary w-100-percent"
			(click)="return()">
			<span [i18n]="'login.backToLogIn'"></span>
		</button>
	</div>
</div>
