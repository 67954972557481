import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'content-providers',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<content-provider-linking
			[accountMap]="accountMap"
			(accountMapUpdate)="accountMapUpdate.emit()"
		></content-provider-linking>
	`
})
export class ContentProvidersComponent {

	@Input() accountMap: {[cpId: number]: number[]};

	@Output() accountMapUpdate = new EventEmitter<void>();
}

app.directive('contentProviders', downgradeComponent({component: ContentProvidersComponent}) as angular.IDirectiveFactory);
