import { Input, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

@Component({
	selector: 'data-overview-content-provider-step',
	templateUrl: './data-overview-content-provider-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataOverviewContentProviderStepComponent implements OnInit {
	@Input() projectSelection: IProjectSelection;
	@Output() projectSelectionChange = new EventEmitter<IProjectSelection>();

	loading: Promise<any>;
	isWorkspaceEnabled: boolean;
	showErrorsForCP: string[];

	constructor(
		private ref: ChangeDetectorRef,
		private wizardStep: CxWizardStepComponent,
		private betaFeaturesService: BetaFeaturesService
	) {
	}

	ngOnInit(): void {
		this.wizardStep.setValid(false);
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}
	
	onProjectSelectionChange(projectSelection: IProjectPreselection): void {
		this.projectSelection.contentProviderId = projectSelection.cbContentProvider;
		this.projectSelection.accountId = projectSelection.cbAccount;
		this.projectSelection.projectId = projectSelection.project;
		this.projectSelectionChange.emit({
			contentProviderId: this.projectSelection.contentProviderId,
			accountId: this.projectSelection.accountId,
			projectId: this.projectSelection.projectId
		});
		this.updateStepValidity();
	}

	onCpErrorsChange(errors: string[]) {
		if (errors.length === 0) {
			return;
		}

		this.showErrorsForCP = errors;
		this.wizardStep.setValid(false);
	}

	onProjectsLoading(loadingPromise: Promise<any>) {
		this.loading = loadingPromise;
		this.ref.markForCheck();
	}

	onWorkspaceChange(workspace: Unit): void {
		if (workspace) {
			this.projectSelection.contentProviderId = workspace.contentProviderId;
			this.projectSelection.accountId = workspace.accountId;
		} else {
			this.projectSelection.contentProviderId = -1;
			this.projectSelection.accountId = -1;
		}
	}

	onProjectChange(workspaceProject: WorkspaceProjectData): void {
		this.projectSelection.projectId = workspaceProject.projectId;
		this.projectSelection.projectName = workspaceProject.projectName;
		this.projectSelectionChange.emit({
			contentProviderId: this.projectSelection.contentProviderId,
			accountId: this.projectSelection.accountId,
			projectId: this.projectSelection.projectId
		});
		this.updateStepValidity();
	}

	private updateStepValidity(): void {
		this.wizardStep.setValid(ProjectIdentifier.isProjectSelected(this.projectSelection));
	}
}