import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import { TextAttributeGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ListOption } from '@app/shared/components/forms/list-option';
import { UIOption } from '@clarabridge/unified-angular-components';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { CxLocaleService } from '@app/core';
import { AttributesService, IAttributeValuesParams } from '@app/modules/project/attribute/attributes.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';

@Component({
	selector: 'attribute-inclusion-settings',
	templateUrl: './attribute-inclusion-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeInclusionSettingsComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Input() grouping: ReportGrouping; // its dynamic fields should not be used here
	@Input() includeOnly: boolean;

	@Input() inheritAttributeValues: boolean;
	@Output() inheritAttributeValuesChange = new EventEmitter<boolean>();

	@Input() wordsFilteringMode: WordsFilteringMode;
	@Output() wordsFilteringModeChange = new EventEmitter<WordsFilteringMode>();

	@Input() wordsList: string[];
	@Output() wordsListChange = new EventEmitter<string[]>();

	filteringModeOptions: ListOption<WordsFilteringMode>[];
	staticValues: UIOption<string>[];

	loadingDefaults: Promise<any>;

	constructor(
		private locale: CxLocaleService,
		private attributesService: AttributesService,
		private reportSettingsService: ReportSettingsService,
		@Inject('metricUtils') private metricUtils: MetricUtils,
	) { }

	ngOnInit(): void {
		this.filteringModeOptions = [
			{name: this.locale.getString('metrics.include'), value: WordsFilteringMode.INCLUDE},
		];
		if (!this.includeOnly) {
			this.filteringModeOptions.push({name: this.locale.getString('metrics.exclude'), value: WordsFilteringMode.EXCLUDE});
		}
		if (!this.wordsFilteringMode) {
			this.wordsFilteringMode = WordsFilteringMode.EXCLUDE;
			this.wordsFilteringModeChange.emit(this.wordsFilteringMode);
		}
		if (AnalyticMetricTypes.isPredefinedGroup(this.grouping)) {
			this.staticValues = this.getPredefinedValues(this.grouping);
		}

	}

	applyAttributeDefaults(inherit: boolean): void {
		if (inherit) {
			this.loadingDefaults = this.reportSettingsService.getGroupingDefaults(this.project, this.grouping)
			.then((settings) => {
				if (settings) {
					const textSettings = settings as TextAttributeGroupingSettings;
					this.wordsFilteringMode = textSettings.wordsFilteringMode;
					this.wordsList = textSettings.wordsList as string[];
					this.wordsFilteringModeChange.emit(this.wordsFilteringMode);
					this.wordsListChange.emit(this.wordsList);
				}
			});
		}
	}

	isTextAttribute(): boolean {
		return this.grouping.metricType === AnalyticMetricType.ATTRIBUTE
			&& this.grouping.type === ReportAssetType.TEXT;
	}

	isWordsOrAssociatedWordsGrouping(): boolean {
		return AnalyticMetricTypes.isWordsOrAssociatedWordsGrouping(this.grouping);
	}

	tagChanged(): void {
		this.wordsListChange.emit(this.wordsList);
	}

	private getValuesByQuery(query: string): string[] {
		return this.staticValues.filter(obj => {
			return obj.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
		}).map(obj => obj.value);
	}

	formatTag = (tag: string): string => {
		if (this.staticValues) {
			return _.findWhere(this.staticValues, {displayName: tag})?.value ?? tag;
		} else {
			return tag;
		}
	}

	filterQuery = (query: string): Promise<string[]> => {
		let cleanedQuery = query.replace('}', '');
		if (this.staticValues) {
			return Promise.resolve(this.getValuesByQuery(cleanedQuery));
		}

		let params: Partial<IAttributeValuesParams> = {
			project: this.project,
			attributeNames: [this.grouping.name],
			filter: cleanedQuery
		};
		return this.attributesService.getAttributeValues(params)
			.then(attributes => (attributes as any) === '' ? [] : attributes);
	}

	private getPredefinedValues(item: ReportGrouping): UIOption<string>[] {
		let metricValues = this.metricUtils.getPredefinedMetricValues(item);
		let displayNames = this.metricUtils.getPredefinedMetricDisplayNames(item);
		return _.map(displayNames, (displayName, index) => {
			return {
				displayName,
				value: metricValues[index]
			};
		});
	}
}
