import { Injectable, Inject } from '@angular/core';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import ModelGeography from '@cxstudio/attribute-geography/model-geography';

@Injectable({
	providedIn: 'root'
})
export class GeographyService {

	constructor(
		@Inject('geographyApiService') private geographyApiService: GeographyApiService,
	) { }


	getGeographyModel(
		project: AccountOrWorkspaceProject,
		modelId: number,
	): AssetPromise<ModelGeography> {
		return ProjectAssetsHelper.getAssetsForProject<ModelGeography>(
			project,
			(accountProject) => this.geographyApiService.getModelGeography(accountProject, modelId),
			(workspaceProject) => this.geographyApiService.getModelGeography(workspaceProject, modelId),
			(projectId) => undefined
		) as AssetPromise<ModelGeography>;
	}
}

