import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardChangeAction } from './dashboard-change-action';
import { MergeableWidgetAction } from './megeable-widget-action';
import { WidgetUpdateAction } from './widget-update-action';

export class WidgetRenameAction extends WidgetUpdateAction implements MergeableWidgetAction {

	static wrapChange(widgetProvider: () => Widget, change: () => void): WidgetRenameAction {
		let before = ObjectUtils.copy(widgetProvider());
		change();
		let after = ObjectUtils.copy(widgetProvider());
		return new WidgetRenameAction(before, after);
	}

	constructor(before: Widget, after: Widget, initialChange = true) {
		super(before, after, initialChange);
	}

	reverse(): DashboardChangeAction {
		return new WidgetRenameAction(this.getAfter(), this.getBefore(), false);
	}

	canMerge(mergeFrom: WidgetRenameAction): boolean {
		return this.getBefore().id === mergeFrom.getBefore().id;
	}

	merge(mergeFrom: WidgetRenameAction): void {
		this.setAfter(mergeFrom.getAfter());
	}
}
