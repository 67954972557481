import { Injectable } from '@angular/core';
import { TextFilterService } from '@app/modules/filter-builder/text-filter.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as _ from 'underscore';

@Injectable({
	providedIn: 'root'
})
export class WidgetTextFilterProcessor {

	constructor(
		private textFilterService: TextFilterService
	) {}

	process = (widgetSettings: Widget): Promise<Widget> => {
		let widgetProperties = widgetSettings.properties;
		widgetProperties.adhocFilter = widgetProperties.adhocFilter || {
			type: FilterTypes.AND,
			filterRules: []
		};
		let filterUi = widgetSettings.filterUi;
		let textFilter = filterUi && filterUi.textFilter;
		if (textFilter)
			_.chain(widgetProperties.adhocFilter.filterRules)
				.filter(FilterRuleTypes.isText)
				.each( rule => {
					let filter = this.textFilterService.getFilterRule(textFilter);
					_.extend(rule, filter);
				});

		return Promise.resolve(widgetSettings);
	}

}

app.service('widgetTextFilterProcessor', downgradeInjectable(WidgetTextFilterProcessor));
