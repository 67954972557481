import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';
import { EnvironmentService } from './services/environment-service';

export class AppLoadingService {

	readonly APP_LEVEL_SELECTOR = '#app-level-spinner';
	readonly BODY_LEVEL_SELECTOR = '#body-level-spinner';

	constructor(
		private locale: ILocale,
		private environmentService: EnvironmentService
	) {

	}

	enterApplication = () => {
		if (!this.environmentService.canRunApplication()) {
			$('#body-level-message').text(this.locale.getString('login.3rdPartyCookiesError'));
			$(this.BODY_LEVEL_SELECTOR).addClass('show-message');
		} else {
			this.fade(this.BODY_LEVEL_SELECTOR, 300);
		}
	}

	hideAppSpinner = () => {
		this.fade(this.APP_LEVEL_SELECTOR, 1500);
	}

	showAppSpinner = () => {
		$(this.APP_LEVEL_SELECTOR).children().show();
		$(this.APP_LEVEL_SELECTOR).show();
	}

	showAppError = () => {
		$('#app-level-message').text(this.locale.getString('error.applicationDown'));
		$(this.APP_LEVEL_SELECTOR).addClass('show-message');
	}

	private fade(selector, period): void {
		if (this.environmentService.isUnderTest()) {
			$(selector).hide();
		} else {
			$(selector).children().fadeOut(period);
		}
	}

}

app.service('appLoading', AppLoadingService);
