
<tags-input #tagsInput
	class="w-100-percent"
	name="labels"
	[maxTags]="3"
	[(ngModel)]="values"
	[maxLength]="20"
	[addOnBlur]="true"
	[addOnEnter]="true"
	[tagsSource]="tagsSource"
	(onTagAdded)="labelAdded($event)"
	(onTagRemoved)="labelRemoved($event)">
</tags-input>


<!--
	template="labels-tags-template">
how to pass template
<script type="text/ng-template" id="labels-tags-template">
	<div class="tag-template label-tags">
		<div class="tag-template-icon">
			<i class="q-icon-globe lh-1"></i>
			<a class="remove-button" ng-click="$removeTag()">&#10006;</a>
		</div>
		<div>
			<span>{{$getDisplayText()}}</span>
		</div>
	</div>
</script>
-->