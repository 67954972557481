<!-- IMPORTANT
	PDF processing relies on this html layout (see CustomHeaderConstructor), 
	so be careful about changing rows or columns of the table below
-->
<div class="chrome-pdf-logo">
	<img [src]="generatePDFLogo()">
</div>
<div class="chrome-pdf-header">
	<table style="width: 100%; padding: 0; margin: 0; font-family: 'Open Sans', Arial, sans-serif;" 
		bgcolor="#ffffff" 
		cellspacing="0" cellpadding="0"><tbody>
		<tr>
			<td colspan="1"><!-- placeholder for logo --></td>
			<td colspan="3">
				<table cellspacing="0" cellpadding="0"><tbody>
					<tr><td>
						<h1 style="font-size: 16px; padding: 0; margin: 0; color: black;">
							{{dashboard.name}}
						</h1>
					</td></tr>
					<tr><td>
						<table style="font-size: 10.5px" cellspacing="0" cellpadding="0"><tbody>
							<tr>
								<td *ngIf="personalization.isHierarchySelectable()" style="vertical-align:top">
									<span style="color: #999"><b>{{'dashboard.organizationLevel'|i18n}}</b></span>
									<span style="color: black">{{ getOrganizationLevel() }}</span>
								</td>
								<td style="vertical-align:top;">
									<span style="color: #999">
										<b>{{'dashboard.exportedBy'|i18n}}</b>
									</span>
									<span style="color: black">{{user.firstName}} {{user.lastName}}</span>
								</td>
								<td style="vertical-align:top;">
									<span style="color: #999">
										<b>{{'dashboard.exportedOn'|i18n}}</b>
									</span>
									<span style="color: black">{{(getCurrentDate() || undefined)}}</span>
								</td>
							</tr>
						</tbody></table>
					</td></tr>
					<tr><td>
						<span style="font-size: 10.5px" *ngIf="getSelectedDashboardFilters().length > 0">
							<span *ngFor="let filter of getSelectedDashboardFilters() | slice:0:4; index as $index; last as isLast; trackBy: trackByFilterIndex" 
								style="float:left; padding-right:15px; white-space:normal; box-sizing:border-box">
								<span style="color: #999"><b>{{ getDashboardFilterName(filter) }}</b></span>
								<span style="color: black">&nbsp; {{ dashboardFilterLabels.getDashboardHeaderFilterLabel(filter, true) }}</span>
								<span *ngIf="!isLast">&nbsp;/&nbsp;</span>
							</span>
		
							<span *ngIf="getSelectedDashboardFilters().length > 4" 
								style="float:left; padding-right:15px; white-space:normal; box-sizing:border-box; color: black;">
								{{'common.and'|i18n}} {{(getSelectedDashboardFilters().length - 4)}} {{'widget.exportMoreFilters'|i18n}} ...
							</span>
		
							<span *ngIf="isPdfExportWithFiltersChangeInViewMode()" 
								style="float:left; padding-right:15px; white-space:normal; box-sizing:border-box; color: black; font-style: italic;">
								&nbsp;<span>* {{'widget.hasChangesInViewMode'|i18n}}</span>
							</span>
						</span>
					</td></tr>
				</tbody></table>
			</td>
		</tr>
	</tbody></table>
</div>