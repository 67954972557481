<modal-header
	[modalTitle]="'administration.updateDataAccess' | i18n"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body scroll-overflow" [ngBusy]="loading">
	<div class="mb-16">
		<label>{{'administration.bulkUpdateDataAccessBody' | i18n}}</label>
		<label class="italic">{{'administration.bulkUpdateDataAccessBodyNote' | i18n}}</label>
		<label class="italic">{{'administration.skipAccountAdminsNote' | i18n}}</label>
	</div>
	<div class="mb-16">
		<project-selector *ngIf="!isWorkspaceEnabled()"
			class="d-flex flex-direction-row"
			[projectSelection]="{}"
			(loading)="loading = $event"
			[horizontal]="true"
			[appendToBody]="true"
			(errorsChange)="contentProviderErrors = $event"
			[hideProject]="true"
			(projectSelectionChange)="onProjectSelected($event)"
		></project-selector>
		<alert type="danger" *ngFor="let cperror of contentProviderErrors">
			<span>{{ cperror }}</span>
		</alert>
		<div *ngIf="isWorkspaceEnabled() && hasMultipleWorkspaces()" class="pb-16">
			<simple-dropdown
				[options]="workspaces"
				(onChange)="switchWorkspace($event)"
				displayField="name"
				valueField="id"
				[appendToBody]="true"
			></simple-dropdown>
		</div>
	</div>
	<collapsing-panel *ngIf="projects?.length > 0"
		[collapsed]="false"
		[disableCollapse]="true">
		<panel-heading>
			<h3 [i18n]="'administration.availableProjects'" [i18nParams]="{projectCount: projects.length}"></h3>
		</panel-heading>
		<panel-body>
			<user-bulk-update-table
				[items]="projects"
				[gridType]="gridType">
			</user-bulk-update-table>
		</panel-body>
	</collapsing-panel>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()">
</save-modal-footer>

