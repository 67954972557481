import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { DocViewPaneSettings } from './doc-view-pane-settings';
import { DocViewPreferences } from './doc-view-preferences.class';

export const DocExplorerPreferencesProvider = {
	provide: DocViewPreferences,
	deps: [SessionPreferencesService],
	useFactory: (sessionPreferences: SessionPreferencesService) =>
		(settings: DocViewPaneSettings) => new DocExplorerPreferences(settings, sessionPreferences)
};

export class DocExplorerPreferences extends DocViewPreferences {

	constructor(settings: DocViewPaneSettings, private sessionPreferences: SessionPreferencesService) {
		super();
		this.settings = settings || this.sessionPreferences.get('docExplorerPreferences') as DocViewPaneSettings;
	}

	getSavedFavorites = (contentProviderId: number, accountId: number, projectId: number): any => {
		let existingData: any = this.settings.keyInfo && _.find(this.settings.keyInfo, {
				contentProviderId, accountId, projectId
			});

		if (existingData) {
			return existingData.favorites;
		} else {
			return this.initializeNewFavorites(contentProviderId, accountId, projectId).favorites;
		}
	}

	updateSavedFavorites = (contentProviderId: number, accountId: number, projectId: number, favorites: FavoriteId[]): void => {
		let data: any = this.settings.keyInfo && _.find(this.settings.keyInfo, {
				contentProviderId, accountId, projectId
			});

		if (!data) {
			data = this.initializeNewFavorites(contentProviderId, accountId, projectId);
		}
		data.favorites = favorites;
		this.storeUpdates();
	}

	private initializeNewFavorites = (contentProviderId, accountId, projectId) => {
		let newFavorites = {
			contentProviderId, accountId, projectId,
			favorites: []
		};

		this.settings.keyInfo = this.settings.keyInfo || [];
		this.settings.keyInfo.push(newFavorites);
		return newFavorites;
	}

	storeUpdates = (): void => {
		this.sessionPreferences.set('docExplorerPreferences', this.settings);
	}
}
