import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { Inject, Injectable } from '@angular/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import Authorization from '@cxstudio/auth/authorization-service';

@Injectable({ providedIn: 'root' })
export class ProjectAccessApiService {
	private readonly cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
		@Inject('authorization') private readonly authorization: Authorization,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.PERMISSIONS);
	}

	hasProjectAccess(
		project: WorkspaceProject, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<boolean> {
		if (InternalProjectTypes.isStudioAdminProject(project.projectId)) {
			return Promise.resolve(this.authorization.hasStudioAdminProjectAccess()) as
				AssetPromisePlatformProject<boolean>;
		}
		return this.getProjectsAccessLevel(project.workspaceId, cache, local).then((projects) => {
			const targetProject = _.find(projects, (proj) => proj.project.id === project.projectId);
			return !!(targetProject && targetProject.accessLevel !== 'NONE');
		}) as AssetPromisePlatformProject<boolean>;
	}

	getProjectsAccessLevel = (
		workspaceId: number, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<ProjectAccess[]> => {
		let config = { cache, local };
		const url = this.getBasePath(workspaceId);
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ProjectAccess[]>;
	}

	private getBasePath = (workspaceId: number): string => {
		return `rest/projects-access/ws/${workspaceId}`;
	}
}
