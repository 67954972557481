import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AbstractAssetParameters } from '@app/modules/asset-management/access/asset-parameters/abstract-asset-parameters';

export class WidgetAssetParameters extends AbstractAssetParameters {
	static fromWidget(widget: Widget): WidgetAssetParameters {
		return new WidgetAssetParameters(widget.id);
	}

	constructor(
		private widgetId: number
	) {
		super(AssetParametersType.WIDGET);
	}

	getHttpParams(): {[key: string]: string} {
		let params = { widgetId: this.widgetId.toString() };
		return _.extend(super.getHttpParams(), params);
	}
}
