export class CSSUtils {

	/**
	 * Generate a style element with provided style definitions
	 */
	static getStyleTag(styleDefinitions: string | string[]): HTMLStyleElement {
		const stl = document.createElement('style');
		stl.setAttribute('type', 'text/css');
		const styleArray: string[] = [].concat(styleDefinitions);
		const styles = document.createTextNode(styleArray.join(' '));

		stl.appendChild(styles);

		return stl;
	}
}
