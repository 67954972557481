import { LicenseDefaultPermissions } from './license-default-permissions.class';
import { LicenseType } from '@cxstudio/common/license-types';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';

export class MasterAccountDefaultPermissions {
	masterAccountId: number;
	studioBasic: LicenseDefaultPermissions;
	studioStandard: LicenseDefaultPermissions;
	studioDesigner: LicenseDefaultPermissions;
	studioEngagor: LicenseDefaultPermissions;
}

export class MasterAccountDefaultPermissionsUtils {
	static getLicenseDefaultPermissions(
		defaults: MasterAccountDefaultPermissions, licenseTypeId: number
	): MasterAccountPermission[] {
		switch (licenseTypeId) {
			case LicenseType.CX_STUDIO_BASIC:
				return [...defaults.studioBasic.defaultPermissions];
			case LicenseType.CX_STUDIO:
				return [...defaults.studioStandard.defaultPermissions];
			case LicenseType.ANALYZE:
				return [...defaults.studioDesigner.defaultPermissions];
			case LicenseType.CX_STUDIO_AND_CM:
				return [...defaults.studioEngagor.defaultPermissions];
			default:
				return null;
		}
	}
}
