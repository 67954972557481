import { DatasetStats } from '@cxstudio/drivers/entities/dataset-stats';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import * as _ from 'underscore';
import { DatasetWarning, WarningType, WarningSeverity } from '@cxstudio/drivers/entities/dataset-warning';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';


export class DriversDatasetStatsController implements ng.IController {

	driversItem: DriversItem;

	loading: ng.IPromise<any>;
	volume: number;
	targetVolume: number;
	targetPerc: number;
	targetPercText: string;
	attributesAndModelsCount: number;
	warnings: DatasetWarning[];

	// make WarningSeverity available in HTML partial
	WarningSeverity = WarningSeverity;

	constructor(
		private driversUtils: DriversUtils
	) {}

	$onInit(): void {
		this.volume = 0;
		this.targetPerc = 0;
		this.targetVolume = 0;
		this.targetPercText = '';
		this.attributesAndModelsCount = 0;
		this.loading = this.driversUtils.getDatasetStats(this.driversItem).then((datasetStatsFull: DatasetStats) => {
			this.volume = datasetStatsFull.volume;
			this.targetPerc = datasetStatsFull.targetPerc;
			this.targetVolume = datasetStatsFull.targetVolume;
			this.targetPercText = datasetStatsFull.targetPercText;
			this.attributesAndModelsCount = datasetStatsFull.attributesAndModelsCount;
			this.warnings = datasetStatsFull.warnings || [];
		});
	}

	getValueClassName = (type: WarningType): string => {
		let warning = _.findWhere(this.warnings, {type});
		return warning ? this.getWarningClassName(warning) : '';
	}

	getWarningClassName = (warning: DatasetWarning): string => {
		return 'warning-' + warning.severity;
	}
}

app.component('driversDatasetStats', {
	controller: DriversDatasetStatsController,
	templateUrl: 'partials/drivers/drivers-dataset-stats.html',
	bindings: {
		driversItem: '<'
	}
});
