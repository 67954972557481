import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { BubbleConfigurationService } from '@app/modules/widget-settings/bubble-configuration.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { CBSettingsService, IWidgetSettingsScope } from '../services/cb-settings-service.service';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { RealDataPreviewWidget } from './real-data-preview-widget.class';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';

export class ScatterChartSettingsCtrl extends RealDataPreviewWidget {
	widgetType: WidgetType;

	constants;
	loaded: boolean;
	selectYAxis: (...args) => ng.IPromise<void>;
	selectXAxis: (...args) => ng.IPromise<void>;

	constructor(
		protected readonly $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected readonly metricConstants: MetricConstants,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private readonly locale: ILocale,
		private readonly cbSettingsService: CBSettingsService,
		private readonly sampleDataApiService: SampleDataAPIServiceClass,
		private readonly highchartsUtils: HighchartsUtilsService,
		private readonly optionsBuilderProvider: OptionsBuilderProvider,
		private readonly $controller,
		private readonly bubbleConfigurationService: BubbleConfigurationService,
		private readonly cbDialogService: CBDialogService,
		private readonly driversUtils: DriversUtils,
		private readonly driversApi: DriversApi,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly hierarchySettingsService: HierarchySettingsService
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		super.$onInit();
		this.widgetType = this.$scope.props.widgetType;

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this.$scope,
			selectionConfiguration: {
				multiAttributeSelection: false
			},
			customCallback: {
				addGroup: this.addGroup
			}
		});

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.loaded = false;

		this.$scope.ui = this.$scope.ui || {};
		this.$scope.ui.listTypes = KeyMetricListTypes;

		this.selectYAxis = this.getAxisSelectionFunction('yAxis', 0);
		this.selectXAxis = this.getAxisSelectionFunction('xAxis', 1);

		this.$scope.selectYAxis = this.selectYAxis;
		this.$scope.selectXAxis = this.selectXAxis;
		this.$scope.selectSize = this.selectSize;
		this.$scope.openAdvancedOptions = this.openAdvancedOptions;
		this.$scope.configureBubble = this.configureBubble;
		this.$scope.isConversionVisible = this.isConversionVisible;
		this.$scope.removeMetric = this.removeMetric;

		this.initProps();
		this.initialize();
	}

	initialize = (): void => {
		this.$scope.options.additionalMetrics = undefined;

		this.$scope.addLoadingPromise(this.sampleDataApiService.getHierarchyData().then((response) => {
			this.$scope.staticData.data = response.data;
			this.$scope.staticData.totalCount = 157;
			this.$scope.visualProps.visualization = WidgetVisualization.BUBBLE;

			if (!this.$scope.visualProps.popColor)
				ColorUtilsHelper.convertSolidToCustom(this.$scope.visualProps, ColorTypes.PERIOD_OVER_PERIOD);

			if (_.isUndefined(this.$scope.visualProps.showLegend) || this.$scope.visualProps.showLegend === null)
				this.$scope.visualProps.showLegend = true;
			if (_.isUndefined(this.$scope.visualProps.showLabels) || this.$scope.visualProps.showLabels === null) {
				this.$scope.visualProps.showLabels = false;
			}
			if (isEmpty(this.$scope.visualProps.showSampleSize)) {
				this.$scope.visualProps.showSampleSize = true;
			}

			if (!this.$scope.visualProps.yAxis) {
				this.$scope.visualProps.yAxis = this.constants.SENTIMENT.name;
			}
			if (!this.$scope.visualProps.xAxis) {
				this.$scope.visualProps.xAxis = this.constants.VOLUME.name;
			}
			if (!this.$scope.visualProps.size) {
				this.$scope.visualProps.size = this.constants.CONSTANT_SIZE.name;
			}

			this.checkPreviewDataAndApplyVisualChanges();
		}));

	}

	initProps = (): void => {
		if (!this.$scope.props.selectedAttributes) {
			this.$scope.props.selectedAttributes = [];
		}
		if (!this.$scope.props.selectedMetrics) {
			this.$scope.props.selectedMetrics = [];
			this.selectYAxis(this.getAvailableCalculation(this.constants.SENTIMENT.name));
			this.selectXAxis(this.getAvailableCalculation(this.constants.VOLUME.name));
		}

		if (!this.$scope.visualProps.periodLabel) {
			this.$scope.visualProps.periodLabel = {};
		}

		this.$scope.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		}];

		this.$scope.initializePeriods();
	}

	getAvailableCalculation(name): any {
		let calculations = this.metricConstants.getStandardCalculations(this.$scope.props.project);
		return _.findWhere(calculations, { name }) || calculations[0];
	}

	reloadSettings = (_event, callback): void => {
		this.initProps();

		let config = {
			withDrivers: true,
			withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
		};

		this.$scope.reloadCommonSettings(config).then((result) => {
			this.$scope.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this.$scope, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.$scope.options.primaryGroupingOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(result.models)
				.withTime(result.attributes)
				.withAttributes(result.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(result.wordAttributes)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withDrivers(result.drivers, this.$scope.props.project)
				.withOrgHierarchyModels(result.models, result.hierarchyModels)
				.withPredefinedGroups(angular.copy(result.predefinedMetrics))
				.build();

			this.cbSettingsService.initDefaultProperties(this.$scope.options.primaryGroupingOptions);

			let primaryGroup = this.$scope.props.selectedAttributes[0];

			if (AnalyticMetricTypes.isDrivers(primaryGroup)) {
				this.constants.STRENGTH.hidden = false;
			} else {
				this.constants.STRENGTH.hidden = true;
			}
			this.$scope.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(_.union(this.metricConstants.getStandardCalculations(this.$scope.props.project), [this.constants.STRENGTH]))
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withScorecardMetrics(result.scorecardMetrics)
				.build();

			this.$scope.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
			this.$scope.options.studioMetrics = _.filter(result.metrics, { projectId: this.$scope.props.project });
			this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, result.hierarchyGrouping,
				result.organizationCalculations, result.hierarchyModels);
			this.$scope.processSelections();

			this.$scope.options.sizeMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics([angular.copy(this.constants.VOLUME), this.constants.CONSTANT_SIZE])
				.withPredefinedMetrics(result.predefinedMetrics)
				.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
				.withMetrics(result.metrics, this.$scope.props.project)
				.withScorecardMetrics(result.scorecardMetrics)
				.filterAvailableOptions(this.$scope.sizeMetricsFilter)
				.build();

			this.$scope.filteredSelectedAttributes = this.hierarchySettingsService.filterSelectedAttributesForGrouping(
				this.$scope.options.primaryGroupingOptions,
				this.$scope.dashboardFilters?.personalization?.isHierarchyEnabled(),
				this.$scope.dashboardFilters?.personalization?.getHierarchyId());

			this.$scope.options.cogSortByMetrics = angular.copy(this.$scope.options.additionalMetrics);
			this.$scope.updateCustomDateFilters(result.dateFilters);
			this.checkPreviewDataAndApplyVisualChanges();
			callback(true);
		});
	}

	setProperty = (name, node): void => {
		this.$scope.visualProps[name] = node.name;
		this.checkPreviewDataAndApplyVisualChanges();
	}

	resetSelections = (): void => {
		this.selectYAxis(this.constants.SENTIMENT);
		this.selectXAxis(this.constants.VOLUME);
		this.selectSize(this.constants.CONSTANT_SIZE);
		this.$scope.props.selectedAttributes = [];
		delete this.$scope.visualProps.primaryGroup;
		this.$scope.visualProps.color = null;

		this.checkPreviewDataAndApplyVisualChanges();
	}

	/**
	 * Similar to setMetric in AnalyticDefinitionSelectionController. May be combined later
	 */
	private getAxisSelectionFunction = (axis, arrayIndex): (...args) => ng.IPromise<void> => {
		return (node) => {
			// don't reselect same metric, otherwise formatting may get reset
			if (this.$scope.props.selectedMetrics[arrayIndex] && (node.name === this.$scope.props.selectedMetrics[arrayIndex].name)) {
				return;
			}
			this.checkAxisScale(axis, node, this.$scope.props.selectedMetrics[arrayIndex]);
			return PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.$scope.props, node)).then((customSettings) => {
				let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(node, this.$scope.options.studioMetrics);
				_.extend(node, defaultSettings, customSettings);

				let selectedNode = this.metricCustomFormatUtils.initCalculationFormat(node);
				this.$scope.props.selectedMetrics[arrayIndex] = selectedNode;
				this.setProperty(axis, selectedNode);
			});
		};
	}

	selectSize = (node): void => {
		PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.$scope.props, node)).then((customSettings) => {
			let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(node, this.$scope.options.studioMetrics);
			_.extend(node, defaultSettings, customSettings);

			let selectedNode = this.metricCustomFormatUtils.initCalculationFormat(node);
			if (selectedNode.name || selectedNode.name === '') {
				this.$scope.props.selectedMetrics[2] = selectedNode;
			} else {
				this.$scope.props.selectedMetrics.removeAt(2);
			}
			this.setProperty('size', selectedNode);
		});
	}

	checkAxisScale = (axisName, currentNode, previousNode): void => {
		if (this.isStrength(currentNode) && !this.isStrength(previousNode)) {
			this.$scope.visualProps[`${axisName}AutoMax`] = false;
			this.$scope.visualProps[`${axisName}AutoMin`] = false;
			this.$scope.visualProps[`${axisName}Max`] = '1';
			this.$scope.visualProps[`${axisName}Min`] = '0';
		}
		if (!this.isStrength(currentNode) && this.isStrength(previousNode)) {
			this.$scope.visualProps[`${axisName}AutoMax`] = true;
			this.$scope.visualProps[`${axisName}AutoMin`] = true;
			delete this.$scope.visualProps[`${axisName}Max`];
			delete this.$scope.visualProps[`${axisName}Min`];
		}

	}

	isStrength = (node): boolean => {
		return node?.name === this.constants.STRENGTH.name;
	}

	openAdvancedOptions = (): void => {
		this.highchartsUtils.showAdvancedChartOptionsDialog(
			this.$scope.widget,
			true,
			'x',
			this.locale.getString('widget.vertical') + ' ' + this.locale.getString('widget.axisOptions'),
			this.locale.getString('widget.horizontal') + ' ' + this.locale.getString('widget.axisOptions'),
			false)
			.then(this.checkPreviewDataAndApplyVisualChanges as any);
	}


	configureBubble = (isSecondary): void => {
		let map = {
			scale: 'scale'
		};

		if (isSecondary) {
			for (let key in map) {
				if (map.hasOwnProperty(key)) {
					map[key] = `secondary${map[key].capitalize()}`;
				}
			}
		}

		this.bubbleConfigurationService.configure(map, this.$scope.visualProps).then((result) => {
			$.extend(this.$scope.visualProps, result);
			this.checkPreviewDataAndApplyVisualChanges();
		});
	}

	addGroup = (node, _index, previousNode): void => {
		let currentDrivers = AnalyticMetricTypes.isDrivers(node);
		let previousDrivers = AnalyticMetricTypes.isDrivers(previousNode);

		if (currentDrivers && !previousDrivers) {
			this.setStrengthVisibility(true);
			this.selectYAxis(this.constants.STRENGTH);
		}
		if (previousDrivers && !currentDrivers) {
			this.setStrengthVisibility(false);
			if (this.$scope.visualProps.yAxis === this.constants.STRENGTH.name) {
				this.$scope.props.selectedMetrics[0] = this.constants.SENTIMENT;
				this.setProperty('yAxis', this.constants.SENTIMENT);
			}
			if (this.$scope.visualProps.xAxis === this.constants.STRENGTH.name) {
				this.$scope.props.selectedMetrics[1] = this.constants.VOLUME;
				this.setProperty('xAxis', this.constants.VOLUME);
			}
		}

		if (currentDrivers) {
			this.cbDialogService.confirm(
				this.locale.getString('drivers.applyFiltersTitle'),
				this.locale.getString('drivers.applyFiltersMessage')
			).result.then(() => {
				this.$scope.props.lockFilters = true;
				this.$scope.addLoadingPromise(PromiseUtils.old(this.driversApi.getDriversModel(node.id)).then((result) => {
					this.driversUtils.processAxisRange(result.drivers, this.$scope.visualProps);
				}));
			}, () => {
				this.$scope.props.lockFilters = false;
			});

		}
	}

	private setStrengthVisibility(visible): void {
		let metric = _.findWhere(this.$scope.options.additionalMetrics[0].children, { name: this.constants.STRENGTH.name });
		metric.hidden = !visible;
	}

	isConversionVisible = (): boolean => {
		return this.$scope && !AnalyticMetricTypes.isDrivers(this.$scope.props.selectedAttributes[0]);
	}

	removeMetric = (metric): void => {
		this.resetMetricIfMatching('yAxis', metric);
		this.resetMetricIfMatching('xAxis', metric);
	}

	private resetMetricIfMatching(field, metric): void {
		if (metric.name === this.$scope.visualProps[field])
			this.setProperty(field, this.constants.VOLUME);
	}

}

app.controller('CBAnalyticScatterChartCtrl', ScatterChartSettingsCtrl);
