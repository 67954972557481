import { Inject, Injectable } from '@angular/core';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';

import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { ChartHierarchyData, DualDataProcessingService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-data-processing.service';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { DualDefinitionUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-utils.class';
import { HighchartsDualUtilService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/highcharts-dual-util.service';
import { DualLegendService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/dual-legend.service';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { PointSelectionCallback } from '@app/modules/widget-visualizations/highcharts/point-selection-callback.interface';
import { SortUtils } from '@app/shared/util/sort-utils';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { BubbleUtils } from '@cxstudio/reports/settings/bubble-utils.class';
import { ChartPlotUtils } from '@app/modules/plot-lines/plot-lines/chart-plot-utils.service';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { HighchartsExtensions } from '@cxstudio/reports/visualizations/highcharts-extensions.service';
import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
@Injectable({
	providedIn: 'root'
})
export class HighchartsDualDefinitionService {

	constructor(
		private highchartsDualUtil: HighchartsDualUtilService,
		private dualLegend: DualLegendService,
		private highchartsUtils: HighchartsUtilsService,
		private dualDataProcessing: DualDataProcessingService,
		private highchartsAnalyticUtils: HighchartsAnalyticUtils,
		@Inject('reportNumberFormatUtils') private reportNumberFormatUtils: ReportNumberFormatUtils,
		private chartPlotUtils: ChartPlotUtils,
		@Inject('highchartsAccessibilityUtils') private highchartsAccessibilityUtils: HighchartsAccessibilityUtils
	) { }

	getHierarchyData(dualUtils: DualDefinitionUtils, dataObject: ReportDataObject, visualProps: VisualProperties,
			utils: WidgetUtils, demo: boolean): ChartHierarchyData {
		let data = this.dualDataProcessing.getInitialData(dataObject, dualUtils, demo);
		data = this.dualDataProcessing.processPopData(data, dualUtils, utils);
		data = this.dualDataProcessing.processCalculationSeriesData(data, dualUtils, visualProps, utils.metrics);
		let hierarchyData = this.dualDataProcessing.transformData(data, visualProps, utils);
		return this.dualDataProcessing.postProcessData(hierarchyData, dualUtils, visualProps);
	}

	getChartOptions(dataObject: ReportDataObject, options: VisualProperties, utils: WidgetUtils,
			pointCallback: PointSelectionCallback, demo: boolean) {
		let dualUtils = new DualDefinitionUtils(options, utils);
		let hierarchyData = this.getHierarchyData(dualUtils, dataObject, options, utils, demo);
		let categories;

		let groups = this.getGroups(dualUtils, options);
		let formatters = this.dualDataProcessing.getGroupingFormatters(options, utils, dataObject?.metadata?.isWeekDescription);

		categories = this.highchartsAnalyticUtils.getHierarchyCategories(
			hierarchyData, groups, dualUtils.isIncludingEmptyPoints(), formatters);
		if (categories) {
			this.addEmptyStringToInnerCategories(categories);
		}

		let opts = this.highchartsDualUtil.getBaseOptions(options, utils, pointCallback);

		opts.yAxis[0].labels.formatter = HighchartsClosureUtils.closureWrapper(scope => {
			return utils.dataFormatter ? utils.dataFormatter(scope.value)
				: this.reportNumberFormatUtils.formatMetric(options.yAxis, scope.value);
		});
		let xAxis = opts.xAxis as Highcharts.XAxisOptions;
		xAxis.categories = categories;
		xAxis.labels.formatter = HighchartsClosureUtils.closureWrapper((scope) => {
			if (!dualUtils.isBar()) {
				let value = typeof (scope.value) === 'object' ? scope.value.name : scope.value;
				return _.contains(xAxis.categories, value) ? scope.value : '';
			} else {
				return Number.isInteger(scope.value) ? '' : scope.value;
			}
		});
		xAxis.labels.autoRotation = this.getLabelRotation(options);

		let globalOtherExplorer = options.globalOtherExplorer;
		opts.plotOptions.bubble.maxSize = globalOtherExplorer ? '5%' : '20%';

		let primarySeries = this.getPrimarySeries(hierarchyData, dualUtils, options, utils) as Highcharts.SeriesOptionsType[];
		opts.series.pushAll(primarySeries);
		opts.legend.enabled = (primarySeries.length > 1
				|| !!options.secondaryChartType
				|| !!options.secondaryGroup
				|| !!options.stackedGroup)
			&& isTrue(options.showLegend);

		let secondarySeries;
		let colorFields = ReportConstants.primaryColorFields;
		if (options.secondaryChartType) {
			secondarySeries = this.addSecondarySeries(opts, hierarchyData, dualUtils, options, utils);
			colorFields = ReportConstants.colorFields;
		}

		this.dualLegend.processLegend(opts, primarySeries, secondarySeries, colorFields, dualUtils, options, utils);

		if (!dualUtils.applyClustering()) {
			if (groups.length > 1) {
				if (options.subChartType === ChartType.COLUMN) {
					xAxis.labels.x = 3;
					xAxis.labels.align = 'right';
					(xAxis.labels as any).groupedOptions = [{rotation: 0, y: 40, style: {textOverflow: 'ellipsis', width: '40px'}}];
					xAxis.labels.autoRotation = [-90];
				}
				if (options.subChartType === ChartType.BAR) {
					xAxis.labels.rotation = 0;
					xAxis.labels.x = -10;
					xAxis.labels.align = 'right';
					(xAxis.labels as any).groupedOptions = [{rotation: 0, style: {textOverflow: 'ellipsis', width: '100%'}}];
					xAxis.labels.style = {textOverflow: 'ellipsis', width: '100%' as any};
				}
			}
			(opts.xAxis as any).customField = true;
			(opts.yAxis as any).customField = true;

			let lastCategory = categories[categories.length - 1];
			opts.series.forEach((singleSeries: any) => {
				if (singleSeries.data) {
					this.addNullToSeries(singleSeries.data, categories);
					//we do not need space after last group of items
					if (lastCategory && lastCategory.categories) {
						singleSeries.data.pop();
					}
				}
			});

			if (lastCategory && lastCategory.categories) {
				//to align first level axis lebel for single bar (horizontal)
				let groupsCount = xAxis.categories.length;
				//remove empty category name
				if (groupsCount > 0) (xAxis.categories[groupsCount - 1] as any).categories.pop(); // what is this? o_O
				//order is important. should be removed after addNullToSeries
			}
		}

		if (dualUtils.isStacked()) {
			this.processStacking(opts, dualUtils, options, {
				primary: primarySeries,
				secondary: secondarySeries
			});
		}

		if (dualUtils.applyClustering()) {
			categories.forEach((category) => {
				if (category && category.categories) {
					delete category.categories;
				}
			});
		}

		this.checkYAxisInterval(options, opts.yAxis as Highcharts.YAxisOptions);

		// rescale bubble charts ONLY if applicable and necessary -- no need to check ahead to make sure this chart uses bubbles
		BubbleUtils.adjustScale(opts, options);
		this.chartPlotUtils.processPlotLines(options, utils, opts);
		if (!demo
			&& options.attributeSelections.primaryGroup
			&& AnalyticMetricTypes.isTime(options.attributeSelections.primaryGroup)) {
				this.chartPlotUtils.processTimeReferences(options, utils, opts, hierarchyData);
		}

		// apply any highcharts extensions
		HighchartsExtensions.apply(options, opts, dualUtils);

		this.highchartsUtils.cleanDataNames(opts);
		this.highchartsUtils.cleanAxisCategories(opts.xAxis as Highcharts.AxisOptions);
		this.highchartsUtils.cleanAxisCategories(opts.yAxis as Highcharts.AxisOptions);
		this.highchartsAccessibilityUtils.applyBorder(opts);
		return opts;
	}

	private checkYAxisInterval(options: VisualProperties, yAxis: Highcharts.YAxisOptions) {
		if (options.yAxis === StandardMetricName.VOLUME) {
			yAxis[0].allowDecimals = false;
		}

		if (yAxis[1] && options.secondaryYAxis === StandardMetricName.VOLUME) {
			yAxis[1].allowDecimals = false;
		}
	}

	private processStacking(chartOptions: Highcharts.Options, dualUtils: DualDefinitionUtils,
			options: VisualProperties, series) {
		this.setSeriesStacking(series.primary, 'normal');
		if (options.secondaryStacked) {
			this.setSeriesStacking(series.secondary, 'normal');
		}

		let stackedGroup = dualUtils.getStackingGroup();
		if (stackedGroup) {
			let stackType = stackedGroup.stackType;
			if (stackType && stackType === StackType.HUNDRED_PERCENT) {
				chartOptions.chart.alignTicks = false;
				this.setSeriesStacking(series.primary, 'percent');
				this.processHundredPercentStackingAxis(chartOptions.yAxis[0]);

				if (series.secondary && options.secondaryStacked) {
					this.setSeriesStacking(series.secondary, 'percent');
					this.processHundredPercentStackingAxis(chartOptions.yAxis[1]);
				}
			}
		}
	}

	private getLabelRotation(options: VisualProperties): number[] {
		return this.highchartsDualUtil.isPolarOrRadar(options) ? [] : [-90];
	}

	private setSeriesStacking(series: any[], stacking: string) {
		if (!series) return;
		series.forEach((oneSeries) => {
			oneSeries.stacking = stacking;
		});
	}

	private processHundredPercentStackingAxis(axis) {
		axis.min = 0;
		axis.max = 100;
		axis.tickInterval = 20;
		axis.endOnTick = false;

		if (!axis.labels) axis.labels = {};
		axis.labels.formatter = HighchartsClosureUtils.closureWrapper(scope => {
			return this.reportNumberFormatUtils.formatPercent(scope.value);
		});
	}

	private getGroups(dualUtils: DualDefinitionUtils, options: VisualProperties): ReportGrouping[] {
		let selections = options.attributeSelections;
		let groups = [];
		let primaryGroup = dualUtils.getGroup(selections.primaryGroup);
		if (primaryGroup) {
			groups.push(primaryGroup);
		}
		if (dualUtils.isBar() && dualUtils.hasSecondaryGroup() && !this.isPopOrClustering(dualUtils)) {
			groups.push(dualUtils.getGroup(selections.secondaryGroup));
		}
		return groups;
	}

	private getPrimarySeries(hierarchyData: ChartHierarchyData, dualUtils: DualDefinitionUtils,
			options: VisualProperties, utils: WidgetUtils) {

		let processors = this.getProcessors(PlotLineAxis.primary,
			options.yAxis, utils.titleFormatter(options.yAxis),
			dualUtils.isStacked() ? 'primary' : undefined, dualUtils, options);
		let series = this.highchartsAnalyticUtils.getSeries(hierarchyData, processors);

		this.highchartsAnalyticUtils.applyDataProperties(series, {
			type: dualUtils.getChartType(),
			size: utils.sizeFunction,
			points: dualUtils.isLineChart() ? options.points !== false : undefined,
			color: utils.colorFunction,
			colorType: dualUtils.isLineChart() ? ColorTypes.POINT : ColorTypes.PRIMARY,
			metricFormatter: utils.dataFormatter,
			isStacked: dualUtils.isStacked(),
			stackedGroup: dualUtils.getStackingGroup(),
			useParentColor: dualUtils.isUseParentColor('primary'),
			pointColor: utils.pointColorFunction,
			isObjectBasedColor: ColorUtilsHelper.isObjectBasedColor(options, ColorTypes.PRIMARY),
			isPrimary: true,
			globalOtherExplorer: options.globalOtherExplorer
		});

		if (dualUtils.hasPop()) {
			let baseIndex = Math.floor(series.length / 2);
			for (let i = 0; i < series.length - baseIndex; i++) {
				let k = i + baseIndex;
				series[k].color = utils.popColorFunction(series[k], i);

				if (options.dottedLine) {
					series[k].dashStyle = 'shortdot';
				}

				// Skip POP line recoloring
				if (dualUtils.isLineChart()) {
					continue;
				}

				for (let j = 0; j < series[k].data.length; j++) {
					let data = series[k].data[j];
					if (data) {
						if (data.object) data.object.colorType = 'popColor';
						data.color = utils.popColorFunction(data.object ? data.object : null,
							(dualUtils.isStacked() && !dualUtils.isPopStacked()) ? i : j);
					}
				}
			}
		}

		series = this.processStackSorting(series, options);

		if (dualUtils.hasPop() && !(dualUtils.isStacked() && dualUtils.getStackingGroup()?.name === '_pop')) {
			series.reverse(); // historical period will show before current period
		}

		return series;
	}


	private processStackSorting(series: any[], options: VisualProperties) {
		if (!options.stackedGroup || !options.stackedDirection
			|| !AnalyticsDefinitionUtils.isSupportStackSorting(options.attributeSelections.stackedGroup)) {
			return series;
		}
		let comparator = AnalyticDataUtils.getStackComparator('_uniqName', options.stackedDirection);
		return SortUtils.mergeSort(series, comparator);
	}
	private isPopOrClustering(dualUtils: DualDefinitionUtils) {
		return dualUtils.isPop() || dualUtils.applyClustering();
	}
	private getProcessors(axisPosition, yAxis, axisTitle, stackedPrefix, dualUtils: DualDefinitionUtils, options: VisualProperties) {
		let processors = [];
		if (!options.primaryGroup)
			return processors;
		if (dualUtils.isBar()) {
			if (dualUtils.hasSecondaryGroup(axisPosition)) {
				if (this.isPopOrClustering(dualUtils)) {// show PoP bars side by side
					processors.push(this.highchartsAnalyticUtils.dataStackTransformer(!stackedPrefix && dualUtils.isPop()));
				} else { // otherwise as hierarchy categories
					processors.push(this.highchartsAnalyticUtils.dataDrillTransformer(axisTitle, dualUtils.isIncludingEmptyPoints()));
				}
			} else { // convert initial data into processors format
				processors.push(this.highchartsAnalyticUtils.dataRootTransformer(axisTitle));
			}
			if (stackedPrefix) { // process subnodes as stacking
				processors.push(this.highchartsAnalyticUtils.seriesStackValuesProcessor(yAxis, stackedPrefix));
			} else { // process values, removing nulls
				processors.push(this.highchartsAnalyticUtils.seriesValuesProcessor(yAxis,
					PlotLineAxis.primary === axisPosition && !this.isPopOrClustering(dualUtils)));
			}
		} else {
			if (!stackedPrefix && dualUtils.hasSecondaryGroup(axisPosition)) {
				processors.push(this.highchartsAnalyticUtils.dataStackTransformer(dualUtils.isPop()));
			} else {
				processors.push(this.highchartsAnalyticUtils.dataRootTransformer(axisTitle));
			}
			if (stackedPrefix) {
				processors.push(this.highchartsAnalyticUtils.seriesStackValuesProcessor(yAxis, stackedPrefix));
			} else {
				processors.push(this.highchartsAnalyticUtils.seriesValuesProcessor(
					(PlotLineAxis.primary === axisPosition && dualUtils.isCalculationSeries())
						? '_calculation_series_value' : yAxis, false)
				);
			}
		}
		return processors;
	}

	private addSecondarySeries(chartOptions: Highcharts.Options, hierarchyData: ChartHierarchyData,
			dualUtils: DualDefinitionUtils, options: VisualProperties, utils: WidgetUtils) {
		let scaleSecondary = this.highchartsUtils.getAxisScale('secondary', options);
		let yAxis = {
			title: {
				text: utils.titleFormatter(options.secondaryYAxis)
			},
			opposite: true,
			gridLineWidth: 0,
			min: scaleSecondary.min !== null
				? scaleSecondary.min
				: DualDefinitionHelper.isZeroBased(options.secondaryYAxis) ? 0 : null,
			max: scaleSecondary.max,
			startOnTick: scaleSecondary.min === null,
			endOnTick: scaleSecondary.max === null
		} as Highcharts.YAxisOptions;
		if (utils.secondaryDataFormatter) {
			yAxis.labels = {
				formatter: HighchartsClosureUtils.closureWrapper(scope => {
					return utils.secondaryDataFormatter(scope.value);
				})
			};
		}
		(chartOptions.yAxis as any[]).push(yAxis);

		let processors = this.getProcessors(PlotLineAxis.secondary,
			options.secondaryYAxis, utils.titleFormatter(options.secondaryYAxis),
			dualUtils.isStacked() && options.secondaryStacked ? 'secondary' : undefined, dualUtils, options) ;
		let secondarySeries = this.highchartsAnalyticUtils.getSeries(hierarchyData, processors);
		if (dualUtils.hasPop() && !options.secondaryPop && secondarySeries.length === 2 && !dualUtils.isBar()) {
			secondarySeries.pop(); // remove second period, if not checked
		}
		if (dualUtils.hasPop() && !options.secondaryPop && secondarySeries.length > 1 && dualUtils.isBar()) {
			secondarySeries = secondarySeries.slice(0, secondarySeries.length / 2);
		}
		if (dualUtils.isBar() && this.isPopOrClustering(dualUtils) && !options.secondaryStacked) {
			secondarySeries.forEach((oneSeries) => {
				oneSeries.stack = 'secondary ' + oneSeries.name;
			});
		}

		this.highchartsAnalyticUtils.applyDataProperties(secondarySeries, {
			type: options.secondaryChartType as ChartType,
			size: utils.secondarySizeFunction,
			points: dualUtils.isLineChartSecondary() ? options.secondaryPoints : undefined,
			color: utils.secondaryColorFunction,
			colorType: dualUtils.isLineChartSecondary() ? ColorTypes.SECONDARY_POINT : ColorTypes.SECONDARY,
			pointColor: utils.secondaryPointColorFunction,
			metricFormatter: utils.secondaryDataFormatter,
			isObjectBasedColor: ColorUtilsHelper.isObjectBasedColor(options, ColorTypes.SECONDARY),
			isStacked: (dualUtils.isStacked() && options.secondaryStacked),
			stackedGroup: dualUtils.getStackingGroup(),
			useParentColor: dualUtils.isUseParentColor('secondary'),
		});

		secondarySeries.forEach((oneSeries) => {
			oneSeries.yAxis = 1;
		});

		if (dualUtils.hasPop() && options.secondaryPop && secondarySeries.length > 1) {
			for (let i = Math.floor(secondarySeries.length / 2); i < secondarySeries.length; i++) {
				secondarySeries[i].color = utils.secondaryPopColorFunction(secondarySeries[i],
					i - secondarySeries.length / 2);

				for (let k = 0; k < secondarySeries[i].data.length; k++) {
					let data = secondarySeries[i].data[k];
					if (data) {
						if (data.object) {
							data.object.colorType = 'secondaryPopColor';
						}

						if (!dualUtils.isLineChartSecondary()) {
							data.color = utils.secondaryPopColorFunction(data.object ? data.object : null, k);
						}
					}
				}

				if (options.secondaryDottedLine) {
					secondarySeries[i].dashStyle = 'shortdot';
				}
			}
		}
		// rearrange data for clustering
		secondarySeries = this.processStackSorting(secondarySeries, options);

		if (dualUtils.isPop()) {
			secondarySeries.reverse(); // historical period will show before current period
		}
		chartOptions.series.pushAll(secondarySeries as Highcharts.SeriesOptionsType[]);
		this.highchartsDualUtil.configureAxis(options, utils, chartOptions);

		return secondarySeries;
	}



	private addEmptyStringToInnerCategories(categories: any[]) {
		categories.forEach(category => {
			if (category && category.categories) {
				category.categories.push('');
			}
		});
	}
	private addNullToSeries(series, categories: any[]) {
		let spliceIndex = 0;
		categories.forEach(category => {
			if (category && category.categories) {
				let innerCategoryLength = category.categories.length;
				spliceIndex += innerCategoryLength - 1;
				series.splice(spliceIndex, 0, {y: null, z: null});
				spliceIndex += 1;
			}
		});
	}
}
