<div>
	<p [i18n]="'administration.masterAccountsTabTitle'" class="font-bold"></p>
	<p [i18n]="'administration.masterAccountsTabDescription'"></p>
	<div class="grid-list-tools" [ngBusy]="loading">
		<div class="tools">
			<div>
				<label class="font-bold mb-4" for="ma-search">{{'administration.masterAccountsSearchPlaceholder'|i18n}}</label>
				<div class="input-group">
					<input
						id="ma-search"
						type="text"
						[disabled]="!masterAccounts?.length && masterAccountSearch?.filterText?.length === 0"
						class="grid-search-bar"
						attr.aria-label="{{'administration.masterAccountsSearchPlaceholder'|i18n}}"
						[(ngModel)]="masterAccountSearch.filterText"
						[debounce]="300"
						(debouncedChange)="onSearchChange()">
					<simple-dropdown
						class="border-0"
						displayField="displayName"
						[options]="searchOptions"
						valueField="searchField"
						sortField="displayName"
						[(value)]="masterAccountSearch.filterField"
						(onChange)="onSearchChange()">
					</simple-dropdown>
				</div>
			</div>
			<div class="grid-buttons">
				<button
					class="btn btn-primary br-btn-add-master-account"
					(click)="createMasterAccount()" [i18n]="'administration.addMasterAccount'"></button>
			</div>
		</div>
	</div>

	<div [ngBusy]="loading">
		<item-grid
			class="h-100-percent w-100-percent br-master-account-grid br-grid"
			[treeData]="masterAccounts"
			[gridOptions]="gridOptions"
			[gridType]="gridType"
			[gridChange]="changedItems">
		</item-grid>

		<pagination
			class="emails-pagination row col-sm-12"
			[pagination]="pagination"
			[pullRight]="true"
			(paginationChange)="pageChanged($event)"
		></pagination>
	</div>
</div>
