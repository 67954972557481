import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { DashboardListTab } from './dashboard-list-tab.class';

export abstract class DashboardListTabType {
	readonly TAB_ICON: string | undefined;
	getList: (dashboards: Dashboard[], label?: string) => Dashboard[];
	getEmptyListHTML: (localeService: CxLocaleService) => string;
	getTabName: (localeService: CxLocaleService, label?: string) => string;
	getTabId: (label?: string) => string;
	getContentId: (label?: string) => string;

	is: (tab) => boolean;

	static dashboardsOnly(dashboardOrFolder: Dashboard): boolean {
		return dashboardOrFolder.type === DashboardType.DASHBOARD
			|| dashboardOrFolder.type === DashboardType.BOOK;
	}
}