import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { DocumentAssetParameters } from '@app/modules/asset-management/access/asset-parameters/document-asset-parameters';
import { HomePageCustomWidgetAssetParameters } from '@app/modules/asset-management/access/asset-parameters/home-page-custom-widget-asset-parameters';
import { QuickInsightsWidgetAssetParameters } from '@app/modules/asset-management/access/asset-parameters/quick-insights-widget-asset-parameters';
import { WidgetAssetParameters } from '@app/modules/asset-management/access/asset-parameters/widget-asset-parameters';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { HttpRequestConfig } from '@cxstudio/common/http-request-config';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class AssetParametersFactory {
	static fromWidget(widget: Widget, withRunAs = true): AssetParameters {
		let params = this.getBaseWidgetParameters(widget);
		if (params) {
			this.setCacheWidgetParameters(params, widget, withRunAs);
		}
		return params;
	}

	private static setCacheWidgetParameters(params: AssetParameters, widget: Widget, withRunAs: boolean): void {
		if (withRunAs) {
			let widgetSource = this.getWidgetSource(widget);
			params.setRunAsEmail(widgetSource.properties.runAs);
		}
	}

	private static getWidgetSource(widget: Widget): Widget {
		return _.isEmpty(widget.parentWidget) ? widget : widget.parentWidget;
	}

	private static getBaseWidgetParameters(widget: Widget): AssetParameters {
		let widgetSource = this.getWidgetSource(widget);
		if (!!widgetSource.id) { // 0 is also invalid widget id
			if (HomePageWidgetConstants.isHomePageWidget(widgetSource)) {
				if (HomePageWidgetConstants.isQuickInsightsWidget(widgetSource))
					return QuickInsightsWidgetAssetParameters.fromWidget(widgetSource);
				else return HomePageCustomWidgetAssetParameters.fromWidget(widgetSource);
			} else if (widgetSource.id > 0) {
				// global other widgets have ids: -1,-2,-3, but they don't require access check
				return WidgetAssetParameters.fromWidget(widgetSource);
			}
		} else if (widget.properties.encodedDescriptor) {
			return DocumentAssetParameters.fromWidget(widget);
		}
		return undefined;
	}

	// remove some fields which are required for security check, but break caching
	static processCacheParameters(config: HttpRequestConfig, payload?: any): any {
		let cachePrams = _.extend({}, payload, config.params);
		if (config.assetParams) {
			_.extend(cachePrams, config.assetParams.getCacheParams());
		}
		return cachePrams;
	}

	// remove fields, which are only used for caching, but not required on backend (e.g. userEmail)
	static processRequestParameters(config: HttpRequestConfig): any {
		if (config.assetParams) {
			const httpParams = config.assetParams.getHttpParams();
			return _.extend({}, config.params, httpParams);
		}
		return config.params;
	}
}
