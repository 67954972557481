import { INodeParams } from '@app/shared/components/forms/tree/searchable-tree.component';

export enum TreeFocusMoveDirection {
	FORWARD = 'FORWARD',
	BACKWARD = 'BACKWARD',
	UP = 'UP',
	DOWN = 'DOWN',
	PARENT = 'PARENT'
}

export enum FocusMoveDirection {
	FORWARD = 'FORWARD',
	BACKWARD = 'BACKWARD'
}

export enum FocusTarget {
	FIRST = 'FIRST',
	LAST = 'LAST'
}

export interface ITreeFocusMoveParams extends INodeParams {
	direction: TreeFocusMoveDirection;
}

export interface IFocusMoveParams {
	$event?: UIEvent;
	direction: FocusMoveDirection;
}

export class TreeFocusUtils {
	static isArrow(direction: TreeFocusMoveDirection): boolean {
		return direction === TreeFocusMoveDirection.UP || direction === TreeFocusMoveDirection.DOWN;
	}

	static isTab(direction: TreeFocusMoveDirection): boolean {
		return direction === TreeFocusMoveDirection.FORWARD || direction === TreeFocusMoveDirection.BACKWARD;
	}

	static isUp(direction: TreeFocusMoveDirection): boolean {
		return direction === TreeFocusMoveDirection.UP || direction === TreeFocusMoveDirection.BACKWARD;
	}

	static isDown(direction: TreeFocusMoveDirection): boolean {
		return direction === TreeFocusMoveDirection.DOWN || direction === TreeFocusMoveDirection.FORWARD;
	}
}