export class TableServiceUtils {
	static postRenderMobile = (element) => {
		setTimeout(() => {
			let sum = 0;
			element.find('.grid-canvas>.slick-row:not(.new-row)')
				// tslint:disable-next-line: only-arrow-functions
				.each(function(): void {
					let jqueryThis = this;  // tslint:disable-line:no-invalid-this no-this-assignment
					sum += $(jqueryThis).height() + 21;
				});
			element.parents('.br-widget-content').height(sum + 30);
		}, 100);
	}

	static postRenderRegular = (element) => {
		setTimeout(() => {
			element.parents('.br-widget-content').height('');
		}, 100);
	}
}
