<select-from-tree
	class="filter-statement-attribute filter-hierarchy-dropdown"
	[hierarchyList]="filterFieldOptions"
	[matchFunction]="matchItem"
	[disabled]="pinned"
	[opened]="opened"
	[lazy]="true"
	[appendToBody]="appendToBody"
	(onNodeClick)="selectItem($event.node)"
></select-from-tree>
<cb-inline-help *ngIf="getHelp()">
	<help-body>{{getHelp()}}</help-body>
</cb-inline-help>
<div class="ml-16 flex-fill" *ngIf="isEsQuery(rule)">
	<es-query
		[(esQueryObject)]="rule.esQueryObject"
		(esQueryObjectChange)="emitRuleChange()"
		[isFilterRule]=true
	></es-query>
</div>
<div [class.mh-8]="!displayVertical">
	<search-list [dropdown]="true" *ngIf='showMatchMode(rule)'
		class="d-flex filter-statement-match-mode"
		[class.mt-8]="displayVertical"
		[data]="rule"
		[matchFunction]="matchMatchMode"
		[listOptions]="matchModeOptions(rule, rule.attributeName)"
		[nonclickable]="matchModeOptions(rule, rule.attributeName).length < 2"
		[disableSearch]=true
		sortField="displayName"
		displayField="displayName"
		[appendToBody]="appendToBody"
		(onNodeSelection)="selectMatchMode($event.node, $event.priorNode)"
	></search-list>
</div>
<div>
	<div class="d-flex" [class.mt-8]="displayVertical" *ngIf="showModelSelect(rule)">
		<topic-multiselect
			class="flex-fill"
			[projectIdentifier]="projectSelection"
			[model]="{id: rule.topicId}"
			[tree]="modelTree"
			[showMultiselectButtons]="false"
			[appendToBody]="appendToBody"
			(onNodeClick)="selectModelValue($event)"
		></topic-multiselect>
	</div>
	<div class="d-flex" [class.mt-8]="displayVertical" *ngIf="isAdminProjectAttribute(rule) && !isExistMatch(rule)">
		<tags-input
			name="adminAttributeValues"
			class="filter-statement-tags-input"
			[ngModel]="tags"
			(ngModelChange)="updateRuleFromTags($event)"
			[tagsSource]="loadTags"
			[loadOnFocus]="true">
		</tags-input>
	</div>
	<div class="d-flex" [class.mt-8]="displayVertical" *ngIf="showMultiselect(rule)">
		<paging-multiselect
			class="flex-fill"
			[project]="projectSelection"
			[attributes]="[selectedAttribute]"
			[selection]="selection"
			(onSelectionChange)="populateMultiselectionValues()"
			[onlyExactMatch]="true"
			[useChips]="true"
			[dropdownContainer]="appendToBody ? 'body' : undefined">
			<multiselect-button
				class="d-flex"
				[values]="selection.multiValues">
			</multiselect-button>
		</paging-multiselect>
	</div>
	<div class="d-flex" [class.mt-8]="displayVertical" *ngIf="showTextInput(rule)">
		<input class="filter-statement-value-input"
			type="text"
			name="ruleValue"
			[(ngModel)]="rule.value"
			(ngModelChange)="emitRuleChange()">
	</div>
	<div class="d-flex" [class.mt-8]="displayVertical" *ngIf="showNumericInput(rule)">
		<input class="filter-statement-value-input"
			type="number"
			size="6"
			name="ruleValue"
			[(ngModel)]="rule.value"
			(ngModelChange)="emitRuleChange()"
			step="any"
			[pattern]="getPattern(rule)">
	</div>
	<div class="d-flex align-items-center" [class.mt-8]="displayVertical" *ngIf="showNumericRange(rule)">
		<input class="filter-statement-from-input w-120 mr-4"
			type="number"
			name="ruleFrom"
			[(ngModel)]="rule.from"
			(ngModelChange)="emitRuleChange()"
			step="any"
			[pattern]="getPattern(rule)">
		{{'reportFilters.to'|i18n}}
		<input class="filter-statement-to-input w-120 ml-4"
			type="number"
			name="ruleTo"
			[(ngModel)]="rule.to"
			(ngModelChange)="emitRuleChange()"
			step="any"
			[pattern]="getPattern(rule)">
	</div>
	<div *ngIf="showDateRange(rule)" [class.mt-8]="displayVertical" [ngBusy]="dateFiltersPromise">
		<div class="d-flex align-center" *ngIf="dateOptions">
			<simple-dropdown *ngIf="!isDocumentDateRange(rule)"
				class="mr-4 dropdown-autosize"
				[options]="dateOptions"
				valueField="value"
				displayField="displayName"
				[(value)]="rule.dateFilterMode"
				(valueChange)="emitRuleChange()"
				[appendToBody]="appendToBody">
			</simple-dropdown>
			<date-range *ngIf="isCustomDateRange(rule)"
				[(dateRange)]="rule"
				(dateRangeChange)="emitRuleChange()"
				[hideTime]="!isDocumentDateRange(rule)"
				[timezone]="getTimezone()"
				[projectSelection]="projectSelection">
			</date-range>
		</div>

	</div>
</div>
<ng-content class="d-flex"></ng-content>
