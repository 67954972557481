import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { HierarchyNode } from '@cxstudio/organizations/hierarchy-node';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';

export interface HierarchyDashboardFilter {

	type: string;
	// hierarchyNodeId. should be convertible to number
	name: string;
	/**
	 * values[0]: number - hierarchyId
	 * values[1]: string - hierarchyNodeName
	 */
	values: [number, string];
}

// populates orgFilter
@Injectable({
	providedIn: 'root'
})
export class WidgetDashboardPersonalizationProcessorService extends WidgetDashboardFilterProcessor {

	constructor() {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		let personalization = filterApplication.filtersProvider.getPersonalization();
		let widgetSettings = filterApplication.widgetSettings;

		if (!personalization) {
			return PromiseUtils.old(Promise.resolve());
		}

		// Add additional filters according to the dashboard personalization
		let hierarchyNode = personalization.currentHierarchyNode;

		if (!_.isEmpty(hierarchyNode)) {
			this.addOrgFilter(widgetSettings, hierarchyNode);

		} else {
			this.resetOrgFilter(widgetSettings);
		}

		return PromiseUtils.old(Promise.resolve());
	}

	private addOrgFilter(widgetSettings: any, hierarchyNode: HierarchyNode): void {
		if (!_.isArray(widgetSettings.properties.orgFilters)) {
			this.resetOrgFilter(widgetSettings);
		}

		let filter: HierarchyDashboardFilter = {
			type: OptionsConstant.HIERARCHY_MODEL,
			name: hierarchyNode.id + '',
			values: [hierarchyNode.hierarchyId, hierarchyNode.name]
		};

		widgetSettings.properties.orgFilters.push(filter);
	}

	private resetOrgFilter(widgetSettings: any): void {
		widgetSettings.properties.orgFilters = [];
	}
}
app.service('widgetDashboardPersonalizationProcessor', downgradeInjectable(WidgetDashboardPersonalizationProcessorService));
