import { DrillFilterEvent, WidgetEvent } from '@app/core/cx-event.enum';
import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetsLayoutState } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/layout-change-action';
import { LayoutSaveAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/layout-save-action';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetBulkCreateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-bulk-create-action';
import { WidgetBulkDeleteAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-bulk-delete-action';
import { WidgetCreateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-create-action';
import { WidgetDeleteAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-delete-action';
import { WidgetLinkingAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-linking-action';
import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { DashboardHistoryStateService } from '@app/modules/dashboard-actions/undo/dashboard-history-state.service';
import { SequentialPromiseQueue } from '@app/modules/dashboard-edit/promise-queue';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import { Security } from '@cxstudio/auth/security-service';
import { WidgetGridsterSelectors } from '@cxstudio/dashboards/components/widget-gridster-selectors.constant';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { WidgetToolbarConstants } from '@cxstudio/dashboards/widgets-toolbar/add-widget-toolbar-constants.constant';
import { AddWidgetToolbarPosition } from '@cxstudio/dashboards/widgets-toolbar/add-widget-toolbar-position.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { IWidgetBox } from '@cxstudio/dashboards/widgets/widget';
import { DashboardWidgetLimiter } from '@cxstudio/home/dashboard-widget-limiter.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetSettingsModalService } from '@app/modules/widget-settings/services/widget-settings-modal.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { WidgetLinkingService } from '@cxstudio/reports/utils/analytic/widget-linking-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import WidgetService from '@cxstudio/services/widget-service';
import { IModule } from 'angular';
import { Observable, Subject } from 'rxjs';
import * as _ from 'underscore';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetOwnerChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-owner-change-action';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeGroupsUtils } from '@app/modules/analytics/amplitude/amplitude-groups-utils';
import { AmplitudeWidgetEventData } from '@app/modules/analytics/amplitude/amplitude-widget-event-data.class';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';

declare let app: IModule;

export interface IGridsterWidget {
	sizeX?: number;
	sizeY?: number;
	row: number;
	col: number;
	visible?: boolean;
}

export enum WidgetAction {
	DELETE = 'DELETE',
	UPDATE = 'UPDATE',
	RENAME = 'RENAME',
	OPEN_SETTINGS = 'OPEN_SETTINGS',
	COPY = 'COPY',
	MOVE_TO_TOP = 'MOVE_TO_TOP',
	MOVE_TO_BOTTOM = 'MOVE_TO_BOTTOM',
}

export interface IWidgetActions {
	emit: ($event: WidgetAction, ...args: any[]) => void;
	canEditDashboard(dashboardData: IDashboardData): boolean;
}

export interface WidgetsUsage {
	accountLimit: number;
	widgetsCount: number;
}

/**
 * Service is used only for edit mode for a single dashboard, it doesn't support multiple dashboards like in books
 */
export class WidgetsEditService {

	private widgets: Widget[];
	private dashboard: Dashboard;
	private lastSavedState: WidgetsLayoutState;
	private historyIdMapper: IdMapper; // required to be able to find widgets after their ids changed (e.g. deleted + undo)
	private actionsQueue: SequentialPromiseQueue;
	private saveInProgress: boolean;
	private saveProgressSubject = new Subject<boolean>();

	constructor(
		private widgetModificationService: WidgetModificationService,
		private widgetService: WidgetService,
		private widgetLinkingService: WidgetLinkingService,
		private cbDialogService: CBDialogService,
		private currentWidgets: ICurrentWidgets,
		private environmentService: EnvironmentService,
		private layoutHelperService: LayoutHelper,
		private dashboardExportService: DashboardExportService,
		private addWidgetToolbarPosition: AddWidgetToolbarPosition,
		private $timeout: ng.ITimeoutService,
		private $rootScope: ng.IRootScopeService,
		private locale: ILocale,
		private dashboardWidgetLimiter: DashboardWidgetLimiter,
		private security: Security,
		private widgetSettingsModalService: WidgetSettingsModalService,
		private dashboardHistoryState: DashboardHistoryStateService,
		private pointSelectionUtils: PointSelectionUtils
	) {}

	initWidgets = (widgets: Widget[], dashboard: Dashboard): void => {
		this.widgets = widgets;
		this.dashboard = dashboard;
		this.historyIdMapper = new IdMapper();
		this.actionsQueue = new SequentialPromiseQueue();
		this.saveCurrentLayout();
	}

	reset(): void {
		this.widgets = [];
		delete this.dashboard;
		delete this.lastSavedState;
		this.historyIdMapper = new IdMapper();
		this.actionsQueue = new SequentialPromiseQueue();
	}

	getWidgets = (): Widget[] => {
		return this.widgets;
	}

	getDashboard = (): Dashboard => {
		return this.dashboard;
	}

	getWidgetsSaveData = (): Widget[] => {
		return _.map(this.widgets, widget => this.widgetModificationService.preprocessWidgetBeforeSave(widget));
	}

	isSaving = (): boolean => {
		return this.saveInProgress;
	}

	startSaving = (): void => {
		this.saveInProgress = true;
		this.saveProgressSubject.next(this.saveInProgress);
	}

	finishSaving = (): void => {
		this.saveInProgress = false;
		this.saveProgressSubject.next(this.saveInProgress);
	}

	getSavingObserver(): Observable<boolean> {
		return this.saveProgressSubject;
	}

	private getLayoutChanges(): WidgetsLayoutState {
		let result: WidgetsLayoutState = {};
		this.widgets.filter(widget => {
			let wbox = this.lastSavedState[widget.id];
			if (!wbox)
				return true;
			for (let key in wbox) {
				if (wbox[key] !== widget[key]) {
					return true;
				}
			}
			return false;
		}).forEach(widget => {
			result[widget.id] = this.getWidgetBox(widget);
		});
		return result;
	}

	saveCurrentLayout = (): void => {
		this.lastSavedState = {};
		_.each(this.widgets, widget => {
			this.lastSavedState[widget.id] = this.getWidgetBox(widget);
		});
	}

	private getWidgetBox = (widget: Widget): IWidgetBox => {
		return {
			posX: widget.posX,
			posY: widget.posY,
			width: widget.width,
			height: widget.height
		};
	}

	private hasLayoutChanges(): boolean {
		return !_.isEmpty(this.getLayoutChanges());
	}

	private getAndSaveLayoutState(): {before: WidgetsLayoutState, after: WidgetsLayoutState} {
		let changes = this.getLayoutChanges();
		let before = {};
		_.each(changes, (widgetBox, id) => before[id] = this.lastSavedState[id]);
		this.saveCurrentLayout();
		return {before, after: changes};
	}

	canCopySelected = (): boolean => {
		let selectedWidgets = this.getSelectedWidgets();
		if (_.isEmpty(selectedWidgets))
			return false;

		return this.dashboardWidgetLimiter.hasCapacityForWidgets(selectedWidgets, this.widgets);
	}

	copySelectedWidgets(): void {
		if (!this.canCopySelected()) return;

		let selectedWidgets = this.getSelectedWidgets();

		this.widgetService.copyWidgets(selectedWidgets).then((copiedWidgets) => {
			if (this.dashboardWidgetLimiter.canCreateWidgets(copiedWidgets, this.widgets)) {
				this.saveWidgetsBulk(copiedWidgets).then((actions) => {
					copiedWidgets.forEach(widget => this.selectWidget(widget, true));
					this.updateSelectionProperties();
					this.dashboardHistoryState.addBulkChange(DashboardChangeType.BULK_ADDED, selectedWidgets.length, actions);
				});
				selectedWidgets.forEach(widget => this.clearSelection(widget, true));
				this.updateSelectionProperties();
			}

		});
	}

	private saveWidgetsBulk(widgets: Widget[]): Promise<DashboardChangeAction[]> {
		let containerId = widgets[0].containerId;
		let createAction = new WidgetBulkCreateAction(ObjectUtils.copy(widgets));
		return this.applyDashboardChanges(containerId, createAction).then(() => {
			this.$timeout(() => this.updateGridsterHeight(), 250);
			this.$timeout(() => this.updatePageBreakDelimiters(), 250);
		}).then(() => {
			return this.checkLayoutChanges().then(layoutAction => {
				return this.applyDashboardChanges(containerId, layoutAction).then(() => [createAction, layoutAction]);
			}, () => [createAction]);
		});
	}

	checkLayoutChanges(): Promise<DashboardChangeAction> {
		return PromiseUtils.sleep(50).then(() => { // gridster has 30ms delay, so we need more
			if (this.hasLayoutChanges()) {
				let layoutChange = this.getAndSaveLayoutState();
				return new LayoutSaveAction(layoutChange.before, layoutChange.after);
			} else {
				return Promise.reject();
			}
		});
	}

	moveSelectedWidgetsToTop(): void {
		if (this.getSelectedWidgetsCount() < 1)
			return;
		let selectedWidgets = this.getSelectedWidgets();
		let minWidget = _.min(selectedWidgets, (widget) => {
			return widget.posY;
		}) as Widget;
		let min = minWidget && minWidget.posY;
		selectedWidgets.forEach(widget => {
			widget.posY = widget.posY - min;
		});


		DashboardUtils.sortWidgets(selectedWidgets);

		//height is required to push all widgets out to avoid collisions
		let height = selectedWidgets[0].height; // posY = 0 for first,
		let offset = 0; // removed free space between widgets
		selectedWidgets.forEach(widget => {
			widget.posY -= offset; // remove space between widgets
			if (widget.posY > height) {
				offset += height - widget.posY; // increase offset for future widgets
				widget.posY = height;
			}
			if (widget.posY + widget.height > height) {
				height = widget.posY + widget.height;
			}
		});
		// push all other widgets down
		this.widgets.forEach(widget => {
			if (!this.isSelected(widget)) {
				widget.posY += height;
			}

		});
		// resort widgets to handle them in proper order
		// without sorting some widgets may jump to the bottom, because they are handled last and their space has been taken.
		DashboardUtils.sortWidgets(this.widgets);

		this.checkLayoutChanges().then(layoutAction => {
			this.applyDashboardChanges(selectedWidgets[0].containerId, layoutAction).then(() => {
				this.dashboardHistoryState.addBulkChange(DashboardChangeType.BULK_MOVED, selectedWidgets.length,
					[layoutAction]);
			});
		}, _.noop);

	}

	moveSelectedWidgetsToBottom(): void {
		if (this.getSelectedWidgetsCount() < 1)
			return;
		let selectedWidgets = this.getSelectedWidgets();
		let maxWidget = _.max(this.widgets, widget => {
			return widget.posY + widget.height;
		}) as Widget;

		let minWidget = _.min(selectedWidgets, widget => {
			return widget.posY;
		}) as Widget;

		// moving selected widgets to the bottom, they fill fall "up" automatically
		if (maxWidget) {
			let maxY = maxWidget.posY + maxWidget.height;
			let minY = minWidget.posY;
			selectedWidgets.forEach(widget => {
				widget.posY = maxY + widget.posY - minY;
			});
		}
		this.checkLayoutChanges().then(layoutAction => {
			this.applyDashboardChanges(selectedWidgets[0].containerId, layoutAction).then(() => {
				this.dashboardHistoryState.addBulkChange(DashboardChangeType.BULK_MOVED, selectedWidgets.length,
					[layoutAction]);
			});
		}, _.noop);
	}

	getSelectedWidgets = (): Widget[] => {
		return this.widgets.filter(this.isSelected);
	}

	getSelectedWidgetsCount(): number {
		return this.getSelectedWidgets().length;
	}

	isSelected = (widget: Widget): boolean => {
		return widget.group === 1;
	}

	selectWidget(widget: Widget, skipMultiselectCheck?: boolean): void {
		widget.group = 1;
		if (!skipMultiselectCheck)
			this.updateSelectionProperties();
	}

	private clearSelection(widget: Widget, skipMultiselectCheck?: boolean): void {
		widget.group = 0;
		if (!skipMultiselectCheck)
			this.updateSelectionProperties();
	}

	updateSelectionProperties(): void {
		let count = this.getSelectedWidgetsCount();
		this.widgets.forEach((widget) => {
			widget.multiSelection = count > 1;
		});
	}

	moveToTop(widget: Widget): void {
		if (widget.posY !== 0) {
			widget.posY = 0;
			this.clearSelection(widget);
			this.checkLayoutChanges().then(layoutAction => {
				this.applyDashboardChanges(widget.containerId, layoutAction).then(() => {
					this.dashboardHistoryState.addSingleChange(DashboardChangeType.MOVED, [layoutAction]);
				});
			}, _.noop);
		}
	}

	moveToBottom(targetWidget: Widget): void {
		let bottomWidget = _.max(this.widgets, widget => {
			return widget.posY + widget.height;
		}) as Widget;
		if (bottomWidget) {
			let maxY = bottomWidget.posY + bottomWidget.height;
			if (targetWidget.posY !== maxY) {
				targetWidget.posY = maxY;
				this.clearSelection(targetWidget);
				this.checkLayoutChanges().then(layoutAction => {
					this.applyDashboardChanges(targetWidget.containerId, layoutAction).then(() => {
						this.dashboardHistoryState.addSingleChange(DashboardChangeType.MOVED, [layoutAction]);
					});
				}, _.noop);
			}
		}
	}

	clearWidgetSelection = ($event: JQuery.MouseDownEvent): void => {
		if (this.getSelectedWidgetsCount() === 0 || !$event)
			return;
		let shiftClick = $event.shiftKey;
		let target = $($event.target);
		let widgetClick = target.parents('.br-widget-box').length;
		let menuClick = target.hasClass('option');
		let otherIgnoredClick = (target.parents('.non-selecting-item').length || target.hasClass('non-selecting-item'));
		let ignoreClick = shiftClick || widgetClick || menuClick || otherIgnoredClick;
		if (!ignoreClick) {
			this.deselectAllWidgets();
		}
	}

	deselectAllWidgets = (): void => {
		this.widgets.forEach((widget) => this.clearSelection(widget, true));
		this.updateSelectionProperties();
	}

	toggleWidgetSelection = ($event: JQuery.MouseDownEvent, widget: Widget, editMode: boolean) => {
		if (editMode && ($event.shiftKey || this.checkTitleElementAndNotClickable($event))) {
			if (widget.name === WidgetType.PAGE_BREAK) {
				return;
			}

			if (this.isSelected(widget))
				this.clearSelection(widget);
			else
				this.selectWidget(widget);

			// DISC-36630 Case 2
			if ($(this.getWidgetSelector(widget)).find('editable-widget-title').length !== 0
				&& !$($event.target).is('input')) {
				$(this.getWidgetSelector(widget)).find('cb-expanding-input input').first().blur();
			}

			$event.preventDefault();
		}
		//prevent removing selection from widget by click on selected widget
		if (this.isSelected(widget) && !$($event.target).hasClass('dropdown-toggle')) {
			$event.stopPropagation();
		}
	}

	private getWidgetElementId = (widget: Widget): string => {
		return `widget-${widget.id}`;
	}

	getWidgetSelector = (widget: Widget): string => {
		const widgetIdStr = this.getWidgetElementId(widget);
		const widgetSelector = `#${widgetIdStr}`;
		const containerSelector = widget.containerId ?
			`#container-${widget.containerId}` :
			'';

		return `${containerSelector} ${widgetSelector}`;
	}

	removeSelectedWidgets(): void {
		let selectedWidgets = this.getSelectedWidgets();
		let embedWidgets = _.filter(selectedWidgets, (widget) => {
			return widget.embedConfig && widget.embedConfig.enabled;
		});
		let dialogResult: ng.IPromise<any>;
		if (_.isEmpty(embedWidgets)) {
			dialogResult = this.cbDialogService.danger(
				this.locale.getString('common.pleaseConfirm'),
				this.locale.getString('widget.deleteWidgetsNote')
			).result;
		} else {
			let DANGER = true;
			dialogResult = this.cbDialogService.confirmTable(
				this.locale.getString('common.pleaseConfirm'),
				this.locale.getString('widget.deleteEmbedWidgetBulkNote'),
				[{name: 'displayName', displayName: this.locale.getString('object.widget')}],
				embedWidgets,
				this.locale.getString('common.delete'),
				this.locale.getString('common.cancel'),
				DANGER
			).result;
		}
		dialogResult.then(() => {
			let containerId = selectedWidgets[0].containerId;
			selectedWidgets.forEach(widget => {
				this.clearSelection(widget, true);
			});
			this.updateSelectionProperties();

			let actions: DashboardChangeAction[] = [];
			let unlinkAction = this.unlinkWidgets(selectedWidgets);
			if (unlinkAction)
				actions.push(unlinkAction);
			actions.push(new WidgetBulkDeleteAction(selectedWidgets));
			this.applyDashboardChanges(containerId, ...actions).then(() => {
				this.$timeout(() => this.updatePageBreakDelimiters(), 250);
				return this.checkLayoutChanges().then(layoutAction => {
					return this.applyDashboardChanges(containerId, layoutAction).then(() => actions.concat(layoutAction));
				}, () => actions);
			}).then(allActions => this.dashboardHistoryState.addBulkChange(DashboardChangeType.BULK_DELETED,
				selectedWidgets.length, allActions));

		});
	}

	createAndSaveWidget(created: Widget): void {
		if (this.canAddWidget(created)) {
			this.saveNewWidget(created);
		}
	}

	getReportWidgetsUsage(): WidgetsUsage {
		return {
			accountLimit: this.dashboardWidgetLimiter.getReportWidgetsLimit(),
			widgetsCount: this.dashboardWidgetLimiter.getReportWidgetsCount(this.widgets)
		};
	}

	canAddWidget(created: Widget): boolean {
		if (!this.dashboardWidgetLimiter.canCreateWidget(created, this.widgets)) {
			let message = this.locale.getString('home.widgetLimit', {
				reportCount: this.dashboardWidgetLimiter.getReportWidgetsLimit(),
				nonreportCount: this.dashboardWidgetLimiter.getContentWidgetsLimit()
			});

			this.cbDialogService.warning(this.locale.getString('common.warning'), message);
			return false;
		}

		return true;
	}

	openSettings(widget: Widget): ng.IPromise<Widget> {
		return PromiseUtils.old(this.widgetSettingsModalService.openSettings(widget));
	}

	private wrapSaving(promise: Promise<any>): Promise<void> {
		this.startSaving();
		return promise.then(this.finishSaving, this.finishSaving);
	}

	saveNewWidget(widget: Widget): void {
		let containerId = widget.containerId;

		this.trackWidgetAction(widget, AmplitudeEvent.DASHBOARD_WIDGET_CREATE);

		let actions: DashboardChangeAction[] = [];
		actions.push(new WidgetCreateAction(widget));
		this.applyDashboardChanges(containerId, ...actions).then(() => {
			this.$timeout(() => {
				this.scrollToNewWidget(this.getLastWidgetId());
			}, 500);
			this.$timeout(() => this.updateGridsterHeight(), 250);
			this.$timeout(() => this.updatePageBreakDelimiters(), 250);
			return this.checkLayoutChanges().then(layoutAction => {
				return this.applyDashboardChanges(containerId, layoutAction).then(() => actions.concat(layoutAction));
			}, () => actions);
		}).then(allActions => this.dashboardHistoryState.addSingleChange(DashboardChangeType.ADDED, allActions));
	}

	private getLastWidgetId(): number {
		return _.chain(this.widgets)
			.map(widget => widget.id)
			.max()
			.value();
	}

	private unlinkWidgets(widgetsToDelete: Widget[]): WidgetLinkingAction {
		let currentLinking = WidgetActionUtils.getLinkingData(this.widgets);
		if (!_.isEmpty(currentLinking)) {
			widgetsToDelete.forEach(widget => {
				this.widgetLinkingService.deleteLinking(widget, this.widgets);
			});
			let deletedLinking = WidgetActionUtils.getLinkingData(this.widgets);
			return WidgetLinkingAction.fromDifference(currentLinking, deletedLinking);
		}
	}

	deleteWidget(widget: Widget): void {
		let actions: DashboardChangeAction[] = [];
		let unlinkAction = this.unlinkWidgets([widget]);
		if (unlinkAction)
			actions.push(unlinkAction);

		// If deleting a widget owned by another user, change ownership to the current user first.
		// Otherwise widget creation on deletion undo will fail on ownership check.
		if (!this.security.isCurrentUser(widget.properties.runAs)) {
			let previousOwner = widget.properties.runAs;
			let newOwner = this.security.getEmail();
			// Delete action will will be recorded for a widget with updated ownership.
			widget.properties.runAs = newOwner;
			actions.push(new WidgetOwnerChangeAction(previousOwner, newOwner, widget.id));
		}

		actions.push(new WidgetDeleteAction(widget));
		this.trackWidgetAction(widget, AmplitudeEvent.DASHBOARD_WIDGET_DELETE);
		this.applyDashboardChanges(widget.containerId, ...actions).then(() => {
			this.$timeout(() => this.updatePageBreakDelimiters(), 250);
			return this.checkLayoutChanges().then(layoutAction => {
				return this.applyDashboardChanges(widget.containerId, layoutAction).then(() => actions.concat(layoutAction));
			}, () => actions);
		}).then(allActions => this.dashboardHistoryState.addSingleChange(DashboardChangeType.DELETED, allActions));
	}

	private scrollToNewWidget(widgetId): void {
		let scrollToElement = $('#widget-' + widgetId);
		let offsetTop = (scrollToElement.get(0) as HTMLElement).offsetTop;
		let container = $('#dsh-widget-container');
		container.animate({scrollTop: offsetTop});
	}

	private checkTitleElementAndNotClickable($event: JQuery.MouseDownEvent): boolean {
		if (this.environmentService.isUnderTest()) {
			return;
		}
		const target = $($event.target);

		const isHeaderButton = !!target.parents('edit-widget-button, filter-applied-dropdown, widget-menu').length;

		if (isHeaderButton) {
			return false;
		}

		const isHeader = target.is('.br-widget-header') || !!target.closest('.br-widget-header').length;
		const isTitleOrChild = target.is('widget-title') || !!target.parents('widget-title').length;
		return isHeader || isTitleOrChild;
	}


	updatePageBreakDelimiters = (viewMode?: boolean): void => {
		this.removeAllDelimiters();

		if (viewMode || isEmpty(this.getGridsterScope()) || isEmpty(this.getGridsterScope().gridster)) {
			return;
		}
		let editMode = true;

		this.updateGridsterHeight();
		let verticalToolbarOffset = this.addWidgetToolbarPosition.isVertical() ? WidgetToolbarConstants.VERTICAL_TOOLBAR_WIDTH : 0;
		let exportConfig = this.dashboardExportService.getExportConfig(this.getWidgets(), verticalToolbarOffset);
		let pageBreakHeight = exportConfig.pdfExportPageHeight * exportConfig.pixelPerUnit;

		this.dashboardExportService.checkWidgetsForIntersection(exportConfig, this.getWidgets(), editMode, true);

		if (!exportConfig.pdfPageBreakEnabled) {
			return;
		}

		// Initial position, consider height of the Page Break widget
		let gridsterMargin = this.getGridsterScope().gridster.margins[0];
		let position = ((exportConfig.pdfExportPageHeight + 1) * exportConfig.pixelPerUnit) + gridsterMargin / 2;
		let amountOfDelimiters = Math.ceil(((exportConfig.dashboardHeight * exportConfig.pixelPerUnit) - position) / pageBreakHeight);

		if (!exportConfig.newPageBreak) {
			// Don't show the last delimiter
			_.times(amountOfDelimiters - 1, () => {
				this.addDelimiter(position += pageBreakHeight);
			});
		} else {
			let count = 1;
			let delimiterPosition = position + pageBreakHeight;
			let ignoreItems = _.filter(this.getGridsterScope().gridster.allItems, (item: IGridsterWidget) => {
				return item.sizeY > exportConfig.pdfExportPageHeight;
			});
			// Don't show the last delimiter
			while (count <= amountOfDelimiters - 1) {
				this.addDelimiter(delimiterPosition);
				let row = (count + 1) * exportConfig.pdfExportPageHeight + 1;
				// push widgets that overlap with page break divider down. sizeY is set to 1 to support overlapping check
				this.getGridsterScope().gridster.moveOverlappingItems({sizeX: 24, sizeY: 1, row, col: 0}, ignoreItems);

				count += 1;
				exportConfig = this.dashboardExportService.getExportConfig(this.getWidgets(), verticalToolbarOffset);
				amountOfDelimiters = Math.ceil(((exportConfig.dashboardHeight * exportConfig.pixelPerUnit) - position)
					/ pageBreakHeight);
				delimiterPosition += pageBreakHeight;
			}
			this.$timeout(() => this.updateGridsterHeight(), 250);
		}
	}

	private addDelimiter(position: number): void {
		$('#dsh-widget-container').append(
			$('<div>').addClass('br-widget-delimiter').attr('style', `top:${position}px`)
		);
	}

	private removeAllDelimiters(): void {
		$('div.br-widget-delimiter').remove();
	}

	updateGridsterHeight = (): void => {
		this.layoutHelperService.gridsterHeight = $('.gridster').height();
	}

	getGridsterScope(): any {
		let elem = $(WidgetGridsterSelectors.GRIDSTER_SCOPE_SELECTOR)[0] as any;
		return elem && elem.gridsterScope && elem.gridsterScope();
	}

	canEditDashboard(dashboardData: IDashboardData): boolean {
		if (!dashboardData.dashboard)
			return undefined; // one-time binding
		if (dashboardData.isBook)
			return false;
		if (!this.security.has('edit_dashboard'))
			return false;
		let p = dashboardData.dashboard.permissions;
		return p.OWN || p.EDIT;
	}

	copyWidget(widget: Widget): void {
		this.widgetService.copyWidget(widget).then((copiedWidget) => {
			if (!!copiedWidget) {
				this.createAndSaveWidget(copiedWidget);
			}
		});
		this.markDirty(widget.containerId);
	}

	private markDirty(containerId: string): void {
		let dashboardHistory = this.currentWidgets.getDashboardHistory(containerId);
		dashboardHistory?.markDirty();
	}

	private handleWidgetAction(action: WidgetAction, widget: Widget, ...args: any[]): void {
		switch (action) {
			case WidgetAction.DELETE: {
				if (widget.embedConfig?.enabled) {
					this.cbDialogService.danger(
						this.locale.getString('common.pleaseConfirm'),
						this.locale.getString('widget.deleteEmbedWidgetNote')
					).result.then(() => {
						this.deleteWidget(widget);
					});
				} else {
					this.deleteWidget(widget);
				}
				return;
			}
			case WidgetAction.UPDATE: {
				let updateAction = args[0] as DashboardChangeAction;
				this.applyDashboardChanges(widget.containerId, updateAction);
				this.dashboardHistoryState.addSingleChange(DashboardChangeType.UPDATED, [updateAction]);
				return;
			}
			case WidgetAction.RENAME: {
				let renamedAction = args[0] as DashboardChangeAction;
				this.applyDashboardChanges(widget.containerId, renamedAction);
				this.dashboardHistoryState.addSingleChange(DashboardChangeType.RENAMED, [renamedAction]);
				return;
			}
			case WidgetAction.COPY: return this.copyWidget(widget);
			case WidgetAction.OPEN_SETTINGS: {
				let before = ObjectUtils.copy(widget);
				this.openSettings(widget).then(updatedWidget => {
					let actions: DashboardChangeAction[] = [];
					if (!this.widgetLinkingService.isProjectEqual(widget.properties, updatedWidget.properties)) {
						let beforeUnlink = WidgetActionUtils.getLinkingData(this.widgets);
						this.widgetLinkingService.deleteLinking(updatedWidget, this.widgets);

						let unlinkAction = WidgetLinkingAction.fromDifference(
							beforeUnlink,
							WidgetActionUtils.getLinkingData(this.widgets));
						if (unlinkAction)
							actions.push(unlinkAction);
					}

					if (!angular.equals(widget.properties.selectedAttributes, updatedWidget.properties.selectedAttributes)) {
						this.pointSelectionUtils.deleteSelections(widget.containerId, widget.id);
						this.$rootScope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, widget, null);
					}

					_.extend(widget, updatedWidget);
					let after = ObjectUtils.copy(widget);

					// Widget ownership change. Comes before the widget properties update.
					// see WidgetSettingsModalService.openSettings() for the exact moment of ownership change.
					if (before.properties.runAs !== after.properties.runAs) {
						let previousOwner = before.properties.runAs;
						let newOwner = after.properties.runAs;
						// Syncronizing owners of widgets for previous properties and new properties.
						// Ownership will be handled separately by WidgetOwnerChangeAction.
						// The rest of widget properties will be handled by WidgetUpdateAction.
						before.properties.runAs = after.properties.runAs;
						actions.push(new WidgetOwnerChangeAction(previousOwner, newOwner, widget.id));
					}


					this.trackWidgetAction(widget, AmplitudeEvent.DASHBOARD_WIDGET_EDIT);
					actions.push(new WidgetUpdateAction(before, after));

					this.applyDashboardChanges(widget.containerId, ...actions);
					this.dashboardHistoryState.addSingleChange(DashboardChangeType.UPDATED, actions);

					if (widget.id)
						this.$rootScope.$broadcast(WidgetEvent.RELOAD, [widget.id]);
				}, _.noop);
				return;
			}
			case WidgetAction.MOVE_TO_TOP: return this.moveToTop(widget);
			case WidgetAction.MOVE_TO_BOTTOM: return this.moveToBottom(widget);
		}
	}

	/**
	 * Track a widget event in amplitude
	 */
	private trackWidgetAction(widget: Widget, event: AmplitudeEvent): void {
		AmplitudeAnalyticsService.trackEvent(event,
			AmplitudeGroupsUtils.dashboardGroup(widget.dashboardId),
			new AmplitudeWidgetEventData(widget)
		);
	}

	getDefaultWidgetActions(widget: Widget): IWidgetActions {
		return {
			emit: (event: WidgetAction, ...args: any[]) => this.handleWidgetAction(event, widget, ...args),
			canEditDashboard: current => this.canEditDashboard(current)
		};
	}

	addDashboardHistoryState(type: DashboardChangeType, actions: DashboardChangeAction[]): void {
		this.dashboardHistoryState.addSingleChange(type, actions);
	}


	applyDashboardChanges(containerId: string, ...actions: DashboardChangeAction[]): Promise<void> {
		let lastPromise = actions.map(action => this.actionsQueue.execute(
			() => action.apply(this.widgets, this.widgetModificationService, this.historyIdMapper)
		)).last();
		this.markDirty(containerId);
		return this.wrapSaving(lastPromise);
	}

}

app.service('widgetsEditService', WidgetsEditService);
