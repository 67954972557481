import { NgModule} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationService } from './services/authentication.service';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { CommonModule } from '@angular/common';
import { SessionDialogService } from './session-dialog/session-dialog.service';
import { SessionDialogComponent } from './session-dialog/session-dialog.component';

@NgModule({
	imports: [
		SharedModule, CommonModule, DialogModule,
	],
	declarations: [
		SessionDialogComponent,
	],
	providers: [
		AuthenticationService,
		SessionDialogService,
	],
})
export class AuthenticationModule {}
