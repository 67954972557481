import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { WorkspaceScorecardFiltersApiService } from '@app/modules/scorecards/filters/workspace-scorecard-filters-api.service';
import { IScorecardFilterListItem } from '@app/modules/scorecards/entities/scorecard-filter-list-item';
import { ScorecardFiltersApiService } from '@app/modules/scorecards/filters/scorecard-filters-api.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

@Injectable({
	providedIn: 'root'
})
export class ScorecardFiltersService {
	constructor(
		private readonly scorecardFiltersApi: ScorecardFiltersApiService,
		private readonly workspaceScorecardFiltersApi: WorkspaceScorecardFiltersApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	getScorecardFilters(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IScorecardFilterListItem[]> {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]) as AssetPromise<IScorecardFilterListItem[]>;
		}
		return ProjectAssetsHelper.getAssetsForProject<IScorecardFilterListItem[]>(
			project,
			(accountProject) => this.scorecardFiltersApi.getFilters(accountProject, cache),
			(workspaceProject) => this.workspaceScorecardFiltersApi.getFilters(workspaceProject, cache),
			(projectId) => []
		);
	}
}

app.service('scorecardFiltersService', downgradeInjectable(ScorecardFiltersService));
