import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'value-change-summary',
	template: `
	<figure class="d-flex align-items-end">
		<div class="d-flex flex-direction-column text-0_75rem">
			<p><b>{{'cases.currentValue'|i18n}}</b></p>
			<ng-content select="current-value"></ng-content>
		</div>
		<span class="mh-16 q-icon q-icon-arrow-right" aria-hidden="true"></span>
		<div class="d-flex flex-direction-column text-0_75rem">
			<p><b>{{'cases.suggestedValue'|i18n}}</b></p>
			<ng-content select="suggested-value"></ng-content>
		</div>
	</figure>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueChangeSummaryComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
