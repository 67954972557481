import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { WidgetColorPalette } from '../coloring/entities/widget-color-palette';
import * as _ from 'underscore';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { ColorsCache } from '@cxstudio/dashboards/widgets/colors-cache.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { PatternObject } from 'highcharts';
import { IDataPoint, IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ObjectUtils } from '@app/util/object-utils';
import { ColorPalettesHelper } from '@cxstudio/reports/coloring/color-palettes-helper';

export type Color = string | PatternObject;
export type ColorFunction = (item: any, index?: number) => Color;
export interface IColorFunction extends ColorFunction {
	palette?: WidgetColorPalette;
}
interface Recolor {
	color: string;
}

export enum PaletteType {
	PALETTE = 'palette',
	PROVIDER = 'provider',
	INHERIT = 'inherit',
	SOLID = 'solid',
	GROUP = 'group',
	CALCULATION = 'calculation',
	LIGHTEN = 'lighten',
	FOLDER = 'folder',
	// 1.0
	MULTIPLE = 'multiple',
	STACKED = 'stacked'
}

export interface IRGBColor {
	r: number;
	g: number;
	b: number;
}

export interface DashboardDefaultColorOptions {
	customFromPalette: boolean;
	providerColors?: {branding?: string[]};
}

export class ColorUtils {

	static readonly GROUP_COLOR_PREFIX = '_group_color.';
	readonly POP_GROUP: string = '_pop';

	private colorTypes: IColorSelectorPalette[] = [
		{name: 'custom', type: PaletteType.SOLID, displayName: this.locale.getString('preview.colSolidCustom'), order: 0},
		{name: 'inherit', type: PaletteType.INHERIT, displayName: this.locale.getString('preview.colSameAsLine'), order: 1},
		{name: 'clarabridge', type: PaletteType.PALETTE, displayName: this.locale.getString('preview.colClarabridge'), order: 2},
		{name: 'quantum', type: PaletteType.PALETTE, displayName: this.locale.getString('preview.colQuantum'), order: 10},
		{name: 'sunset', type: PaletteType.PALETTE, displayName: this.locale.getString('preview.colSunset'), order: 11},
		{name: 'provider', type: PaletteType.PALETTE, displayName: this.locale.getString('preview.colFromCP'), order: 12},
		{name: 'sentiment3', type: PaletteType.MULTIPLE, displayName: this.locale.getString('preview.col3sent'), order: 20},
		{name: 'sentiment5', type: PaletteType.MULTIPLE, displayName: this.locale.getString('preview.col5sent'), order: 21},
		{name: 'satscore1', type: PaletteType.MULTIPLE, displayName: this.locale.getString('preview.colSatisfaction1'), order: 22},
		{name: 'satscore2', type: PaletteType.MULTIPLE, displayName: this.locale.getString('preview.colSatisfaction2'), order: 23},
		{name: 'sentiment', type: PaletteType.STACKED, displayName: this.locale.getString('preview.colSent'), order: 30},
		{name: 'satscore', type: PaletteType.STACKED, displayName: this.locale.getString('preview.colSat'), order: 31},
		{name: 'lighten', type: PaletteType.LIGHTEN, displayName: this.locale.getString('preview.colLighten'), order: 80}
	];

	SOLID_COLOR = '#3F51B5';

	constructor(
		private locale: ILocale,
		private colorsCache: ColorsCache,
		private calculationColorService: CalculationColorService,
		private colorPalettes: ColorPalettes
	) {

	}

	getColorType = (color: string): PaletteType => {
		let obj = _.find(this.colorTypes, {name: color});
		return obj && obj.type;
	}

	getColorTypes = (): IColorSelectorPalette[] => {
		return this.colorTypes;
	}

	getColorNames = (filterType): string[] => {
		return _.chain(this.colorTypes)
			.filter({type: filterType} as any)
			.map(palette => palette.name)
			.value();
	}

	getMetricLegendArray = (metric): string[] => {
		return this.calculationColorService.getLegendArray(metric.name, metric);
	}

	// only for 2.0
	getSimpleColorFunction = (type, custom, providerPalette, parentFunction): IColorFunction => {
		switch (type) {
		case 'account':
			type = 'clarabridge';
			return this.colorPalettes.getPredefinedPaletteFunction(type);
		case 'clarabridge' :
		case 'quantum':
		case 'sunset':
		case 'studio':
			return this.colorPalettes.getPredefinedPaletteFunction(type);
		case 'provider': return this.colorPalettes.getPaletteFunction(ColorPaletteNames.PROVIDER_PALETTE, providerPalette);
		case 'custom': return () => {
			return custom && custom.trim() ? custom : ColorUtilsHelper.DEFAULT_CUSTOM_COLOR;
		};
		case 'lighten': return (item, index) => {
			let original = parentFunction ? parentFunction(item, index) : this.SOLID_COLOR;
			return this.lightenColor(original);
		};
		case 'inherit': return () => {
			return null;
		};
		default:
			return this.colorPalettes.getPredefinedPaletteFunction('clarabridge');
		}
	}

	getColorArray = (type, custom, parentArray): string[] => {
		switch (type) {
		case 'lighten': return _.map(parentArray, ColorUtilsHelper.lighten);
		case 'custom': return [custom || ColorUtilsHelper.DEFAULT_CUSTOM_COLOR];
		case 'inherit': return null; // use parent
		default:
			return this.colorPalettes.getPredefinedPaletteArray('clarabridge');
		}
	}

	getWrappedColorFunction = (visualProps, defaultColorFunction: IColorFunction, alignmentColor, widget): IColorFunction => {
		if (!defaultColorFunction) {
			defaultColorFunction = this.getBasicColorFunction();
		}
		return this.getANWidgetWrappedColorFunction(visualProps, defaultColorFunction, alignmentColor, widget);
	}

	private getANWidgetWrappedColorFunction(visualProps, defaultColorFunction: IColorFunction, alignmentColor, widget): IColorFunction {
		return (item, index) => {
			let alignedColor = this.getAlignedColor(item, alignmentColor);
			if (alignedColor) return this.applyPattern(widget, alignedColor, index);

			let itemColor = this.getItemColor(visualProps, defaultColorFunction, widget, item, index);
			itemColor = _.isString(itemColor) ? this.applyPattern(widget, itemColor, index) : itemColor;
			let itemRecolor = this.getItemRecolor(visualProps, item);
			if (itemRecolor) {
				if (this.isColorPattern(itemColor))
					itemColor.pattern.color = itemRecolor.color;
				else
					itemColor = itemRecolor.color;

			}
			return itemColor;
		};
	}

	private getAlignedColor(item, alignmentColor): string | undefined {
		if (item && !_.isUndefined(alignmentColor) && alignmentColor !== null
			&& !_.isUndefined(item.isAlignedHierarchyNode)) {
			if (item.isAlignedHierarchyNode) {
				return alignmentColor;
			} else {
				return ColorUtilsHelper.lighten(alignmentColor);
			}
		}
	}

	private getItemColor(visualProps, defaultColorFunction: IColorFunction, widget, item, index): Color {
		let simplePopColor = this.getSimplePoPColor(item, visualProps);
		if (_.isString(simplePopColor))
			return simplePopColor;

		if (this.useCachedColors(defaultColorFunction, item)) {
			let groupName = this.getItemGroupName(item, visualProps, widget);
			let uniqueName = this.getItemUniqueName(item, visualProps, index, widget);
			let returnColor: Color = this.colorsCache.getCachedColor(widget.containerId,
				defaultColorFunction.palette, groupName, uniqueName, item._isStatic);
			if (this.isPopLightenedStack(item, visualProps)) {
				returnColor = this.lightenColor(returnColor);
			}
			return returnColor;
		}

		return defaultColorFunction(item, index);
	}

	private getItemRecolor(visualProps: VisualProperties, item): Recolor | undefined {
		if (item && visualProps.recolors && visualProps.recolors.length > 0) {
			let recolor = _.find(visualProps.recolors, ColorUtilsHelper.findMatchedRecolor(item));
			if (recolor) return recolor;

			let uniqueName: string = (item.type === 'spline' && item.name) ? item.name : item._uniqName;

			let findWhere = {
				uniqueName,
				colorType: ColorTypes.POINT
			};

			return _.findWhere(visualProps.recolors, findWhere);
		}
	}

	private applyPattern(widget: Widget, color: string, index: number): Color {
		return this.colorsCache.getCachedPattern(widget.containerId, color, index);
	}

	private lightenColor(value: Color): Color {
		if (this.isColorPattern(value)) {
			let lightenPattern = ObjectUtils.copy(value);
			lightenPattern.pattern.color = ColorUtilsHelper.lighten(value.pattern.color);
			return lightenPattern;
		} else {
			return ColorUtilsHelper.lighten(value);
		}
	}

	isColorPattern(value: Color): value is PatternObject {
		return _.isObject(value);
	}

	private getItemGroupName(item, visualProps: VisualProperties, widget): string {
		return this.getItemGroup(item, visualProps, widget).name;
	}

	private getItemGroup(item, visualProps: VisualProperties, widget): any {
		if (this.hasPoPName(item) && this.isWidgetSupportsPoP(widget.name)) {
			if (this.isSecondaryGroupingPoP(visualProps) && this.hasStackedGrouping(visualProps)) {
				return visualProps.attributeSelections.stackedGroup;
			} else if (this.hasSecondaryGrouping(visualProps) && !this.isSecondaryGroupingPoP(visualProps)) {
				return visualProps.attributeSelections.secondaryGroup;
			} else {
				return visualProps.attributeSelections.primaryGroup;
			}
		}
		if (item._isStatic) {
			return {...item._group, name: item._group.name + widget.name};
		}
		return item._group;
	}

	private isWidgetSupportsPoP = (widgetType: WidgetType) => {
		return [WidgetType.BAR, WidgetType.LINE].contains(widgetType);
	}

	private hasSecondaryGrouping(visualProps: VisualProperties): boolean {
		return visualProps.attributeSelections.secondaryGroup && visualProps.attributeSelections.secondaryGroup.name;
	}

	private hasStackedGrouping(visualProps: VisualProperties): boolean {
		return visualProps.attributeSelections.stackedGroup && visualProps.attributeSelections.stackedGroup.name;
	}

	private isSecondaryGroupingPoP(visualProps: VisualProperties): boolean {
		return this.isPopGroup(visualProps.attributeSelections.secondaryGroup);
	}

	private isPopGroup(group): boolean {
		return group && group.name === this.POP_GROUP;
	}

	private getItemUniqueName(item, visualProps: VisualProperties, index: number, widget): string {
		return (this.hasPoPName(item) && this.isWidgetSupportsPoP(widget.name)) ?
				this.getUniqueNameFromPoP(visualProps, item, widget, index) : item._uniqName;
	}

	private useCachedColors(colorFunction, item): boolean {
		return !!(item && item._group && item._uniqName && colorFunction.palette);
	}

	private hasPoPName(item): boolean {
		return !!(item._pop || (item._group && item._group.identifier === this.POP_GROUP));
	}

	private getUniqueNameFromPoP(visualProps: VisualProperties, item, widget, index: number): string {
		let grouping = this.getItemGroup(item, visualProps, widget);
		let path = grouping.identifier + (grouping.type === 'topics' || grouping.type === 'topicLeaf' ? '_fullPath' : '');
		if (item.data && item.data[index] && item.data[index].object)
			return item.data[index].object[path];

		return item[path];
	}

	// if the color is solid/custom, or lightens a solid/custom
	private getSimplePoPColor(item, visualProps: VisualProperties): string | boolean {
		return this.getSolidPoPColor(item, visualProps) || this.getLightenCustomPoPColor(item, visualProps);
	}

	// if PoP color is lighten and the color to lighten is solid/custom...
	private getLightenCustomPoPColor(item, visualProps: VisualProperties): string | undefined {
		if (!this.isPoPLightensPrimaryAxisSolid(item, visualProps) && !this.isPoPLightensSecondaryAxisSolid(item, visualProps))
			return;

		return this.isPoPLightensPrimaryAxisSolid(item, visualProps) ?
			ColorUtilsHelper.lighten(visualProps.customColor) :
			ColorUtilsHelper.lighten(visualProps.secondaryCustomColor);
	}

	private isPoPLightensPrimaryAxisSolid(item, visualProps: VisualProperties): boolean {
		return this.isPoPItem(item) &&
			!!(item.colorType === ColorTypes.PRIMARY &&
				visualProps.popColor === ColorPaletteNames.LIGHTEN
				&& visualProps.color === ColorPaletteNames.CUSTOM);
	}

	private isPoPLightensSecondaryAxisSolid(item, visualProps: VisualProperties): boolean {
		return this.isPoPItem(item) &&
			!!(item.colorType === ColorTypes.SECONDARY &&
				visualProps.secondaryPopColor === ColorPaletteNames.LIGHTEN
				&& visualProps.secondaryColor === ColorPaletteNames.CUSTOM);
	}

	private getSolidPoPColor(item, visualProps: VisualProperties): string | undefined {
		if (!this.isSolidPrimaryPoP(item, visualProps) && !this.isSolidSecondaryPoP(item, visualProps))
			return;

		return this.isSolidPrimaryPoP(item, visualProps) ?
			visualProps.customPopColor :
			visualProps.secondaryCustomPopColor;
	}

	private isPoPItem(item): boolean {
		return item && item._uniqName === DatePeriodName.PERIOD2;
	}

	private isSolidPrimaryPoP(item, visualProps: VisualProperties): boolean {
		return this.isPoPItem(item) &&
			!!(item.colorType === ColorTypes.PRIMARY && visualProps.popColor === ColorPaletteNames.CUSTOM && visualProps.customPopColor);
	}

	private isSolidSecondaryPoP(item, visualProps: VisualProperties): boolean {
		return this.isPoPItem(item) &&
			!!(item.colorType === ColorTypes.SECONDARY
				&& visualProps.secondaryPopColor === ColorPaletteNames.CUSTOM
				&& visualProps.secondaryCustomPopColor);
	}

	private isPopLightenedStack(item, visualProps: VisualProperties): boolean {
		return visualProps.stackedGroup === this.POP_GROUP && item._uniqName === DatePeriodName.PERIOD2 &&
			((visualProps.popColor === ColorPaletteNames.LIGHTEN && item.colorType === ColorTypes.PRIMARY) ||
			(visualProps.secondaryPopColor === ColorPaletteNames.LIGHTEN && item.colorType === ColorTypes.SECONDARY));
	}

	private getBasicColorFunction(): IColorFunction {
		return () => {
			return undefined;
		};
	}

	/**
	 * @deprecated use ColorUtilsHelper
	 */
	isObjectBasedColor = (visualProps: VisualProperties, colorName: ColorTypes): boolean => {
		return ColorUtilsHelper.isObjectBasedColor(visualProps, colorName);
	}

	getSortFunction = () => {
		return (a: IColorSelectorPalette, b: IColorSelectorPalette) => {
			if (a.order && b.order) {
				let res = a.order - b.order;
				if (res !== 0)
					return res;
				else {
					return a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1;
				}
			}
			return a.order ? 1 : -1;
		};
	}

	getColoringMetrics(visualProps: VisualProperties, metrics: Metric[]): ReportCalculation[] {
		return _.chain(ReportConstants.colorFields)
			.filter(field => visualProps[field] && CalculationColorUtils.isCalculationColor(visualProps[field]))
			.map(field => {
				// find the metric by calculation color
				let metric = this.calculationColorService.getMetricByCalculationColor(visualProps[field], metrics) as ReportCalculation;

				// analyze metric , add displayname and metricType
				if (metric) {
					metric = angular.copy(metric);
					if (AnalyticMetricTypes.isPredefinedGroup(metric)) {
						metric.displayName = this.locale.getString(metric.displayName);
					}
					metric.metricType = AnalyticMetricType.CUSTOM;
					return metric;
				} else return null;
			}).filter(metric => !!metric)
			.value();
	}

	getColoringMetric(paletteName: string, metrics: Metric[]): ReportCalculation {
		if (!paletteName || !CalculationColorUtils.isCalculationColor(paletteName)) {
			return null;
		}

		let metric = this.calculationColorService.getMetricByCalculationColor(paletteName, metrics) as ReportCalculation;

		// analyze metric , add displayname and metricType
		if (metric) {
			metric = angular.copy(metric);
			if (AnalyticMetricTypes.isPredefinedGroup(metric)) {
				metric.displayName = this.locale.getString(metric.displayName);
			}
			metric.metricType = AnalyticMetricType.CUSTOM;
			return metric;
		} else {
			return null;
		}
	}

	isColorableMetric = (metric): boolean => {
		return Boolean(metric.definition && metric.definition.calculation && metric.definition.calculation.calculationColorEnabled !== false);
	}

	// does widget use color palettes?
	isColorableWidget = (widget): boolean => {
		return _.contains([WidgetType.LINE,
			WidgetType.BAR,
			WidgetType.HEATMAP,
			WidgetType.CLOUD,
			WidgetType.PIE,
			WidgetType.SCATTER], widget.name);
	}

	// for color selector
	getDefaultPalette = (palettes: WidgetColorPalette[], defaultColor: IColorSelectorPalette,
			colorProperty?: string, defaultValue?: string): string => {
		if (colorProperty === ColorTypes.POINT || colorProperty === ColorTypes.SECONDARY_POINT) {
			return ColorPaletteNames.INHERIT;
		} else if (defaultColor && defaultColor.name) {
			if (this.colorPalettes.isPaletteColor(defaultColor.name)) {
				return this.resolvePalette(palettes, defaultColor.name)?.name;
			} else {
				return defaultColor.name;
			}
		}
		return defaultValue;
	}

	resolvePalette = (palettes: WidgetColorPalette[], name: string): WidgetColorPalette => {
		let selected = _.findWhere(palettes, { name });
		if (selected?.deleted && selected.replacement) {
			selected = _.findWhere(palettes, { id: selected.replacement });
		}
		return selected;
	}

	// for drilling
	getDefaultColor = (defaultColor: IColorSelectorPalette): string => {
		if (defaultColor && defaultColor.name) {
			return defaultColor.name;
		}
		return ColorPaletteNames.PALETTE_1;
	}

	getDefaultCustomColor = (defaultColor: Partial<IColorSelectorPalette>): string => {
		if (defaultColor && defaultColor.customColor) {
			return defaultColor.customColor;
		}
		return ColorUtilsHelper.DEFAULT_CUSTOM_COLOR;
	}

	getDashboardDefaultColor = (properties: DashboardProperties): Partial<IColorSelectorPalette> => {
		if (properties && properties.color) {
			return {
				name: properties.color,
				customColor: properties.customColor
			};
		}
	}

	isValidHexCode(hex: string): boolean {
		return /^#[0-9A-FZa-f]{6}$/.test(hex);
	}
}

app.service('colorUtils', ColorUtils);
