<modal-header
	(cancel)="cancel()"
	modalTitle="{{'templates.propertiesDialogTitle' | i18n }}">
</modal-header>

<div class="modal-body" [ngBusy]="[loading.save, loading.loadingCustomTemplates]">
	<form #templateDialogForm="ngForm" class="form-horizontal" name="templateDialog" role="form">
		<!-- Name -->
		<div class="form-group">
			<label class="col-sm-3 control-label" [i18n]="'templates.name'"></label>
			<div class="col-sm-9" [ngClass]="{'has-error br-form-error': nameField?.errors?.required}">
				<input
					name="name"
					type="text"
					class="form-control"
					placeholder="{{'templates.namePlaceholder'|i18n}}"
					[(ngModel)]="model.name"
					#nameField="ngModel"
					required
					maxlength="300">
					<span class="help-block" *ngIf="nameField.errors?.required">{{'templates.emptyTemplateNameWarning'|i18n}}</span>
			</div>
		</div>

		<!-- Description -->
		<div class="form-group">
			<label class="col-sm-3 control-label" [i18n]="'templates.description'"></label>
			<description class="col-sm-9"
				name="description"
				[(ngModel)]="model.description"
				[placeholder]="'templates.descriptionPlaceholder' | i18n">
			</description>
		</div>

		<!-- Folders -->
		<div class="form-group">
			<label class="control-label col-sm-3 help-label">{{'templates.parentFolder'|i18n}}
				<cb-inline-help right>
					<help-body>{{'templates.assetFolderHelp'|i18n:{asset: 'object.template' | i18n | lowercase} }}</help-body>
				</cb-inline-help>
			</label>
			<div class="col-sm-9">
				<div class="br-folder-select">
					<simple-dropdown
						[(value)]="model.parentId"
						displayField="name"
						valueField="id"
						[options]="folders"
						[disabled]="!folders.length">
					</simple-dropdown>
				</div>
			</div>
		</div>
	</form>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'administration.save' | i18n"
	[isDisabled]="templateDialogForm.invalid">
</save-modal-footer>
