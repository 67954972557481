import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { FavoriteRendererComponent } from './cell-renderers/favorite-renderer/favorite-renderer.component';
import { LabelsRendererComponent } from './cell-renderers/labels-renderer/labels-renderer.component';
import { SharingStatusRendererComponent } from './cell-renderers/sharing-status-renderer/sharing-status-renderer.component';
import { NestedObjectsPipe } from './utilities/nested-objects.pipe';
import { NameRendererComponent } from './cell-renderers/name-renderer/name-renderer.component';
import { ContextMenuRendererComponent } from './cell-renderers/context-menu-renderer/context-menu-renderer.component';
import { DefaultObjectsTableComponent } from './default-objects-table/default-objects-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { ScheduleRendererComponent } from './cell-renderers/schedule-renderer/schedule-renderer.component';
import { ProgressBarRendererComponent } from './cell-renderers/progress-bar-renderer/progress-bar-renderer.component';
import { ToggleRendererComponent } from './cell-renderers/toggle-renderer/toggle-renderer.component';
import { GridUtilsService } from './utilities/grid-utils.service';
import { NgPipesModule, OrderByPipe } from 'ngx-pipes';
import { GridUpdateService } from './utilities/grid-update.service';

@NgModule({
	imports: [
		SharedModule,
		AgGridModule,
		NgPipesModule
	],
	declarations: [
		SharingStatusRendererComponent,
		FavoriteRendererComponent,
		LabelsRendererComponent,
		NestedObjectsPipe,
		NameRendererComponent,
		ContextMenuRendererComponent,
		DefaultObjectsTableComponent,
		ScheduleRendererComponent,
		ProgressBarRendererComponent,
		ToggleRendererComponent,
	],
	exports: [
		DefaultObjectsTableComponent,
		NestedObjectsPipe,
	],
	providers: [
		NestedObjectsPipe,
		GridUtilsService,
		GridUpdateService,
		OrderByPipe
	]
})
export class ObjectListModule { }
