import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

import * as _ from 'underscore';
import { Security } from '@cxstudio/auth/security-service';
import { DesignerAlertType } from './designer/designer-alert-type';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { AlertService } from '@app/modules/alert/alert.service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { SubscriptionsService } from '@app/modules/user-administration/groups/alert-subscription/subscriptions.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { AlertEvent } from '@app/core/cx-event.enum';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';



export interface VerbatimAlert {
	id: string;
	type: DesignerAlertType;
	enabled: boolean;
	creatorEmail: string;
	ownerName: string;
	alertName: string;
	projectId: number;
}


export class VerbatimAlertsListController implements ng.IController {

	active: boolean;
	projectSelection: IProjectSelection;
	projects: Project[];
	searchFilter: string;
	gridColumns: any[];
	gridType = GridTypes.ALERT;
	gridNameField = 'alertName';
	gridOptions: {
		onClick: (event, object) => void;
	};
	lastChange: any[];
	gridLoading: ng.IPromise<any>;

	alertList: VerbatimAlert[];
	visibleItems: VerbatimAlert[];

	constructor(
		private $scope: ng.IScope,
		private contextMenuTree: ContextMenuTree,
		private security: Security,
		private subscriptionsService: SubscriptionsService,
		private locale: ILocale,
		private $filter: ng.IFilterService,
		private treeService: TreeService,
		private gridUpdateService: GridUpdateService,
		private ProjectAccessLevels: ProjectAccessLevelItems,
		private gridUtils: GridUtilsService,
		private alertService: AlertService
	) {}

	$onInit(): void {

		this.$scope.$on(AlertEvent.RELOAD, (e, props: IProjectSelection) => this.reloadAlerts(props));
		this.$scope.$on(AlertEvent.CREATE, this.activeTabWrapper(this.addAlert));
		this.$scope.$on(AlertEvent.CLEAR, this.clear);

		this.alertList = [];
		this.gridOptions = {
			onClick: this.onClick
		};
		this.$scope.$watchCollection(() => this.alertList, this.updateVisibleItemsList);
	}

	private clear = (): void => {
		this.alertList = [];
		this.refreshAlerts();
	}

	private activeTabWrapper(fn: (...args) => any): () => void {
		return () => {
			if (this.active) fn();
		};
	}

	private onClick = (event, object: VerbatimAlert) => {
		if (!this.canManageAlert(object)) return;

		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.getContextMenu(object));
			return;
		} else if (this.gridUtils.isToggleClick(event)) {
			this.toggleAlert(object);
			return;
		} else if (this.gridUtils.isNameClick(event) && object.type === DesignerAlertType.NEW_VERBATIM) {
			this.editAlert(object);
		}
	}

	canManageAlert = (object: VerbatimAlert): boolean => {
		return this.isOwner(object) || this.hasManagerAccess(object.projectId);
	}

	private hasManagerAccess = (projectId: number): boolean => {
		let project = _.findWhere(this.projects, {id: projectId} as Partial<Project>);
		return project?.accessLevel === this.ProjectAccessLevels.MANAGER.value;
	}

	toggleAlert(alert): void {
		let promise = alert.enabled ?
			this.subscriptionsService.disableAlert(this.projectSelection.contentProviderId, alert) :
			this.subscriptionsService.enableAlert(this.projectSelection.contentProviderId, alert);

		this.gridLoading = PromiseUtils.old(promise).then(updatedAlert => {
			alert.enabled = updatedAlert.enabled;
			this.refreshGrid(alert);
		});
	}

	private isOwner(object: VerbatimAlert): boolean {
		return this.security.isCurrentUser(object.creatorEmail);
	}

	getContextMenu(object: VerbatimAlert): Array<ContextMenuItem<any>> {
		if (object.type === DesignerAlertType.SCORECARD) {
			return [{name: 'toggle', func: () => { this.toggleAlert(object); }, text: object.enabled
				? this.locale.getString('administration.disable')
				: this.locale.getString('administration.enable') }];
		} else {
			return [
				{name: 'edit', func: this.editAlert, text: this.locale.getString('common.edit') },
				MenuDivider,
				{name: 'copy', func: this.copyAlert, text: this.locale.getString('common.makeCopy') },
				{name: 'toggle', func: () => { this.toggleAlert(object); }, text: object.enabled
					? this.locale.getString('administration.disable')
					: this.locale.getString('administration.enable') },
				MenuDivider,
				{name: 'delete', func: this.deleteAlert, text: this.locale.getString('common.delete') }
			];
		}
	}

	private refreshGrid = (items?) => {
		this.lastChange = [].concat(items);
	}

	private reloadAlerts(newProps): void {
		let contentProviderId = newProps.contentProviderId;
		let accountId = newProps.accountId;
		if (ValueUtils.isNotSelected(contentProviderId) || ValueUtils.isNotSelected(accountId))
			return;

		let promise = newProps.projectId
			? this.subscriptionsService.getMasterAccountAlertsForProject(
				contentProviderId, accountId, newProps.projectId, true)
			: this.subscriptionsService.getMasterAccountAlertsForAccount(
				contentProviderId, accountId, true);

		this.gridLoading = PromiseUtils.old(promise).then(response => {
			let alertList = response.data as VerbatimAlert[];
			alertList.forEach(alert => {
				alert.id = this.alertService.getUniqueId(alert);
				if ('' === alert.creatorEmail.trim()) {
					alert.creatorEmail = alert.ownerName;
				}
			});

			let tree = TreeListTransformUtils.tree(alertList as unknown as ITreeItem[]);
			let sortedTree = this.$filter('orderBy')(tree, 'name');

			this.alertList = [];
			this.alertList.pushAll(sortedTree as unknown as VerbatimAlert[]);
			this.refreshAlerts();
		});
	}

	private updateVisibleItemsList = () => {
		this.visibleItems = [].concat(this.alertList);
	}

	refreshAlerts = () => {
		this.updateVisibleItemsList();
		this.refreshGrid(this.visibleItems);
	}

	addAlert = () => {
		this.gridLoading = this.alertService.addAlert(this.projectSelection).then(newAlert => {
			this.treeService.addItem(this.alertList, newAlert);
			this.refreshGrid(newAlert);
		}) as unknown as ng.IPromise<any>;
	}

	editAlert = (alert) => {
		this.gridLoading = this.alertService.editAlert(this.projectSelection, alert)
			.then(alertResult => {
				this.refreshGrid(alertResult);
			}) as unknown as ng.IPromise<any>;
	}

	private copyAlert = (alert): void => {
		this.gridLoading = PromiseUtils.old(this.gridUpdateService.copy(alert, this.alertList, this.copyAlertCallback, 'alertName'))
			.then(newAlert =>  {
				// add alert to UI
				newAlert.id = this.alertService.getUniqueId(newAlert);
				this.refreshGrid(newAlert);
			});
	}

	private copyAlertCallback = (alert): ng.IPromise<any> => {
		return PromiseUtils.old(this.subscriptionsService.getAlertDetail(this.projectSelection.contentProviderId, alert)
			.then(alertDetail =>  {
				alertDetail.alertName = alert.alertName;
				return this.subscriptionsService.addAlert(this.projectSelection.contentProviderId, alertDetail);
			}));
	}

	private deleteAlert = (alert): void => {
		let confirmationText = {
			title: this.locale.getString('alert.deletionConfirmHeader'),
			warning: this.locale.getString('alert.deletionConfirmText', {name: alert.alertName})
		};

		this.gridUpdateService.delete([alert], this.alertList, this.deleteAlertCallback, confirmationText).then(() => {
			// remove alert from UI
			this.refreshGrid(alert.parent);
		}, () => {});
	}

	private deleteAlertCallback = (alerts): ng.IPromise<any> | undefined => {
		if (!isEmpty(alerts)) {
			let alertDeletionPromise = PromiseUtils.old(this.subscriptionsService.deleteAlert(this.projectSelection.contentProviderId, alerts[0]));
			this.gridLoading = alertDeletionPromise;

			return alertDeletionPromise;
		}
	}
}
app.component('verbatimAlertsList', {
	bindings: {
		active: '<',
		projectSelection: '<',
		projects: '<',
		searchFilter: '<',
		gridColumns: '='
	},
	templateUrl: 'partials/alerts/generic-alerts-list.html',
	controller: VerbatimAlertsListController
});
