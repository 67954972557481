
import { Component, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { SecurityEvent } from '@app/core/cx-event.enum';
import { CxLocationService } from '@app/core/cx-location.service';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { UserPreferencesDialogComponent } from '@app/modules/header/user-properties/user-preferences-dialog.component';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { RedirectService } from '@cxstudio/services/redirect-service';

@Component({
	selector: 'user-preferences-page',
	template: `<div class="w-100-percent h-100-percent" [ngBusy]="loading"></div>`
})
export class UserPreferencesPageComponent extends SelfCleaningComponent implements OnInit {
	private readonly CONFIRM_EMAIL_CODE_PARAM: string = 'confirmCode';

	loading: Promise<any>;
	private code: string;

	constructor(
		@Inject('redirectService') private redirectService: RedirectService,
		private cxDialogService: CxDialogService,
		private location: CxLocationService,
		private userPropertiesApiService: UserPropertiesApiService,
		private toastService: ToastService,
		private eventBus: GlobalEventBus,
		@Inject('security') private security: Security,
		private locale: CxLocaleService,
	) {
		super();
	}

	ngOnInit(): void {
		this.code = this.location.search()[this.CONFIRM_EMAIL_CODE_PARAM];
		if (this.code) {
			this.addListener(this.eventBus.subscribe(
				SecurityEvent.USER_UPDATED, (event, user) => this.confirmEmail(user)));
			return;
		}
		this.cxDialogService.openDialog(UserPreferencesDialogComponent).result
			.then(this.redirectToHome, this.redirectToHome);


	}

	private confirmEmail(user): void {
		if (!user) {
			return;
		}
		this.loading = this.userPropertiesApiService.confirmPreferredEmail(this.code).then(() => {
			this.toastService.addToast(
				this.locale.getString('userDialog.preferredEmailConfirmed'), AlertLevel.CONFIRM);
			this.security.getContext().preferredEmailPending = null;
			this.redirectToHome();
		}, (reason) => {
			this.toastService.addToast(reason, AlertLevel.ERROR);
			this.redirectToHome();
		});
	}

	private redirectToHome = () => {
		this.redirectService.goToDashboardList();
	}
}

app.directive('userPreferencesPage', downgradeComponent({component: UserPreferencesPageComponent}) as angular.IDirectiveFactory);
