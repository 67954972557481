import { IAccountSelection } from '@app/modules/account/account-selection.interface';

export class AccountIdentifier implements IAccountSelection {
	contentProviderId: number;
	accountId: number;
	contentProviderName?: string;
	accountName?: string;

	static isAccountSelected(accountSelection: Partial<IAccountSelection>): boolean {
		return accountSelection
			&& accountSelection.contentProviderId > -1
			&& accountSelection.accountId > -1;
	}

	static toStringKey(accountSelection: IAccountSelection): string {
		return `${accountSelection.contentProviderId}_${accountSelection.accountId}`;
	}

	constructor(contentProviderId, accountId) {
		this.contentProviderId = contentProviderId;
		this.accountId = accountId;
	}
}