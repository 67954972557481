import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { ColorPalettesApi } from './api/color-palettes-api.service';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import ColorPaletteActions
	from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-actions.service';
import {
	ColorPaletteStoreService
} from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-store.service';
import {
	ColorPaletteContextMenu
} from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-context-menu.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'color-palettes',
	templateUrl: './color-palettes.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPalettesComponent extends SelfCleaningComponent implements OnInit {
	gridType: GridTypes = GridTypes.PALETTES;
	gridNameField: 'displayName';
	gridOptions: SlickgridOptions;
	loading: Promise<unknown>;

	searchPalettes: string;
	changedItems: ColorPalette[];

	errors = { limitReached: false };

	constructor(
		private ref: ChangeDetectorRef,
		private readonly colorPalettesApi: ColorPalettesApi,
		private readonly locale: CxLocaleService,
		private readonly actionsService: ColorPaletteActions,
		private readonly colorPaletteStore: ColorPaletteStoreService,
		private readonly contextMenu: ColorPaletteContextMenu,
		@Inject('security') private readonly security: Security,
		private readonly gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree
	) {
		super();
	}

	ngOnInit(): void {
		// restrict this page to non-mobile
		this.security.preventMobileAccess();

		this.gridOptions = {
			onClick: this.onClick
		};

		const refreshedSourceSubscription= this.colorPaletteStore.getRefreshObserver().subscribe(() => this.reloadPalettes());
		const updatedSourceSubscription = this.colorPaletteStore.getUpdateObserver().subscribe((updatedPalette: ColorPalette) => {
			this.refreshGrid([updatedPalette]);
		});
		const toggledSourceSubscription = this.colorPaletteStore.getToggleObserver().subscribe((toggledPalette: ColorPalette) => {
			this.refreshGrid([toggledPalette]);
		});

		this.addSubscription(refreshedSourceSubscription);
		this.addSubscription(updatedSourceSubscription);
		this.addSubscription(toggledSourceSubscription);

		this.reloadPalettes();
	}

	reloadPalettes = (): void => {
		this.errors.limitReached = false;
		this.loading = this.colorPalettesApi.getPalettes().then(
			(customPalettes): void => {
				this.colorPaletteStore.setColorPalettes(this.transformPalettes(customPalettes));
				this.refreshGrid(this.colorPaletteStore.getColorPalettes());
			}
		);
	}

	createPalette = (): void => {
		this.actionsService.createPalette();
	}

	getCreatePaletteTooltip = (): string => {
		if (this.limitReached()) {
			return this.locale.getString('appearance.palettesLimitExceeded');
		}

		return '';
	}

	limitReached = (): boolean => {
		return this.actionsService.limitReached(this.colorPaletteStore.getColorPalettes());
	}

	refreshGrid = (colorPalettes: ColorPalette[]): void => {
		this.changedItems = [];
		this.changedItems = colorPalettes;

		this.ref.markForCheck();
	}

	getColorPalettes = (): ColorPalette[] => {
		return this.colorPaletteStore.getColorPalettes();
	}

	private onClick = (event, object: ColorPalette): void => {
		if (this.gridUtils.isNameClick(event)) {
			this.actionsService.editPalette(object);

			return;
		}

		if (this.gridUtils.isMenuClick(event)) {
			const objectMenu = this.contextMenu.getContextMenu(object);

			this.contextMenuTree.showObjectListMenu(
				event,
				object,
				objectMenu,
				'palettes',
				360
			);

			return;
		}

		if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.togglePalette(object);

			return;
		}

		if (this.gridUtils.isElementClick(event, 'make-default')) {
			this.actionsService.makeDefault(object);
		}
	}

	private transformPalettes = (palettes: ColorPalette[]): ColorPalette[] => {
		_.chain(palettes)
			.filter(palette => palette.system)
			.each(palette => palette.displayName = this.locale.getString(palette.displayName));

		const validPalettes = palettes.filter(palette => !palette.deleted);
		let flatTree = [];
		flatTree.pushAll(this.gridUtils.processItemsTree(validPalettes, true, 'displayName'));
		return flatTree;
	}
}

app.directive('colorPalettes', downgradeComponent({ component: ColorPalettesComponent }) as angular.IDirectiveFactory);
