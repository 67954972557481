import * as cloneDeep from 'lodash.clonedeep';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, Output, EventEmitter, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AddNestedChildrenUtils } from '@app/modules/hierarchy/hierarchy-tree-selector/add-nested-children-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';

@Component({
	selector: 'home-page-personalization',
	templateUrl: './home-page-personalization.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class HomePagePersonalizationComponent extends SelfCleaningComponent implements OnInit, OnChanges {
	personalization: PersonalizationState;
	showOrganizationContext: boolean;
	public isLoading: boolean = false;

	@Input() homePage: HomePage;
	@Output() nodeChange = new EventEmitter<IHierarchyNode>();

	@ViewChild(NgbDropdown, { static: false }) private dropdown: NgbDropdown;

	constructor(
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
		private homePageService: UserHomePageService,
		private ref: ChangeDetectorRef,
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges<HomePagePersonalizationComponent>) {
		if (ChangeUtils.hasChange(changes.homePage)) {
			this.loadHomePagePersonalization();
		}
	}

	ngOnInit(): void {
		this.loadHomePagePersonalization();
	}

	loadHomePagePersonalization() {
		if (this.homePage) {
			this.isLoading = true;
			this.homePageService.getHomePagePersonalization(this.homePage).then(homePagePersonalization => {
				this.isLoading = false;
				this.personalization = homePagePersonalization;
				this.showOrganizationContext = this.homePage.reportProperties.showOrganizationContext;
				this.ref.markForCheck();
			}, () => {
				this.isLoading = false;
				delete this.personalization;
				delete this.showOrganizationContext;
				this.ref.markForCheck();
			});	
		}
	}

	getTitle = (): string => {
		return this.personalization?.currentHierarchyNode?.name;
	}

	toggleHierarchyDropdown = (open: boolean) => {
		if (this.personalization && this.personalization.currentHierarchyNode.id === ReportConstants.NOT_VALID_NODE_ID) {
			this.dropdown.close();
			return;
		}
		this.personalization.showHierarchyList = open;
		this.personalization.hierarchySearchText = '';

		if (this.personalization.showHierarchyList) {
			//Opening list, expand to selected node
			this.collapseTreeToRootLevel();
		} else {
			//Closing list, collapse all nodes but root
			this.collapseDescendants(this.personalization.hierarchyNodes[0]);
		}

		if (!this.personalization.hierarchyLoaded) {
			this.personalization.loadEntireHierarchy(this.personalization.hierarchyNodes[0].hierarchyId);
		}
	}

	onHierarchyToggleKeydown = (event: any): void => {
		setTimeout(() => {
			$('#hierarchy-selector-menu :focusable').first().trigger('focus');
		});
	}

	onSearchClear = (): void => {
		this.collapseTreeToRootLevel();
	}

	onNodeChange = (node: IHierarchyNode): void => {
		this.personalization.showHierarchyList = false;
		this.personalization.setHierarchyNode(node);
		this.dropdown.close();
		this.nodeChange.emit(node);
	}

	collapseDescendants = (rootNode) => {
		if (!rootNode.children) return;
		rootNode.expanded = false;
		for (let child of rootNode.children) {
			this.collapseDescendants(child);
		}
	}

	collapseTreeToRootLevel = () => {
		if (this.personalization.rawHierarchy && this.personalization.hierarchyNodes) {
			this.personalization.hierarchyNodes[0] = cloneDeep(this.personalization.rawHierarchy[0]);
			AddNestedChildrenUtils.transform(
				this.personalization.rawHierarchy,
				this.hierarchyService.getAncestorIdsFromNode(this.personalization.currentHierarchyNode),
				this.personalization.hierarchyNodes);
			this.personalization.hierarchyNodes[0].expanded = true;
		}
	}
}
