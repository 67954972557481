<div class="form-inline">
	<div class="datepicker-group-hidden">
		<div class="input-group">
			<input name="datepicker"
				class="form-control"
				ngbDatepicker
				#daterangepicker="ngbDatepicker"
				[autoClose]="'outside'"
				(dateSelect)="onDateSelection($event)"
				[displayMonths]="2"
				[dayTemplate]="t"
				outsideDays="hidden"
				[startDate]="adapter.fromModel(fromValue)">
			<ng-template #t let-date let-focused="focused">
			<span class="custom-day"
				[class.focused]="focused"
				[class.range]="isRange(date)"
				[class.faded]="isHovered(date) || isInside(date)"
				(mouseenter)="hoveredDate = date"
				(mouseleave)="hoveredDate = null">
				{{ date.day }}
			</span>
			</ng-template>
		</div>
	</div>
	<div class="input-group mr-8">
		<input #dpFromDate
			class="date-picker-input"
			placeholder="yyyy-mm-dd"
			name="dpFromDate"
			[value]="formatter.format(adapter.fromModel(fromValue))"
			readonly>
		<button class="btn btn-primary q-icon-calendar" (click)="daterangepicker.toggle()" type="button"></button>
	</div>
	<div class="input-group">
		<input #dpToDate
			class="date-picker-input"
			placeholder="yyyy-mm-dd"
			name="dpToDate"
			[value]="formatter.format(adapter.fromModel(toValue))"
			readonly>
		<button class="btn btn-primary q-icon-calendar" (click)="daterangepicker.toggle()" type="button"></button>
	</div>
</div>
