import { ContextPaneSubpanels } from '../context-pane/context-pane-subpanels.enum';

export interface ISentencePaneSettings {
	showSentiment: boolean;
	showSource: boolean;
}

export interface ContextPaneState {
	worldAwarenessCollapsed: boolean;
	narrativeCollapsed: boolean;
	engagorCollapsed: boolean;
	scorecardCollapsed: boolean;
	automatedSummariesCollapsed: boolean;
	complianceManagementCollapsed: boolean;
	attrCollapsed: boolean;
	topicsCollapsed: boolean;
	favoriteAttributesCollapsed: boolean;
	favoritesTextFilter: string;
	attrTextFilter: string;
	worldAwarenessSearch: string;
	topicSearch: string;
	modelSelection: {[modelId: number]: boolean};
	modelSearch: string;
	currentTab: ContextPaneSubpanels;
}

export class DocViewPaneSettings {

	singleVerbatimMode: boolean;
	modelSearch: {[projectIdentifier: string]: {[modelId: number]: boolean}};
	showEmptyAttributes: boolean;
	showSentences: boolean;
	showTopics: boolean;
	leafOnly: boolean;
	expandTopics: boolean;
	sentencePane?: ISentencePaneSettings;
	showSentiment: boolean;
	visiblePanes: {
		sentence: boolean;
		context: boolean;
	};
	skipSilence?: boolean;
	playbackRate?: number;

	// this needs to be renamed, not everything in this object pertains to collapsing/expanding
	paneCollapsedState?: ContextPaneState;
	contextSectionsVisibility?: any;
	keyInfo?: any[];

	static default(): DocViewPaneSettings {
		return {
			paneCollapsedState: {
				worldAwarenessCollapsed: false,
				narrativeCollapsed: true,
				engagorCollapsed: true,
				scorecardCollapsed: true,
				automatedSummariesCollapsed: false,
				complianceManagementCollapsed: false,
				attrCollapsed: false,
				topicsCollapsed: false,
				favoriteAttributesCollapsed: false,
				favoritesTextFilter: '',
				attrTextFilter: '',
				worldAwarenessSearch: '',
				topicSearch: '',
				currentTab: '' as ContextPaneSubpanels
			},
			showSentiment: true,
		} as DocViewPaneSettings;
	}
}
