import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { MetricThresholdRules, ThresholdRule } from '@cxstudio/reports/utils/color/metric-threshold-rules.service';
import { DocumentCaseService } from '@app/modules/document-explorer/document-cases/document-case.service';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { Security } from '@cxstudio/auth/security-service';
import {
	SuggestionMenuOptionsUtils
} from '@app/modules/document-explorer/conversations/suggestion-menu-options-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { IDocumentClassification } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DocumentCacheService, IDocumentWidget } from '@cxstudio/reports/document-explorer/document-cache.service';
import { CaseDocument } from '@cxstudio/reports/entities/case-document.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { SentenceStyleEditorService } from '@app/modules/document-explorer/sentence-style-editor.service';
import { TopicRemoveEvent } from '@cxstudio/reports/document-explorer/components/primary-pane-component';
import { AuditSuggestionEnrichment } from '@cxstudio/reports/document-explorer/audit-suggestion';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import { ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectUtils } from '@app/util/object-utils';
import { Pill, PillType } from '@app/modules/pills/pill';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';

export interface SuggestionMenuSettings {
	$event: Event;
	sentence: PreviewSentence;
	projectIdentifier: IProjectSelection;
	project: AccountOrWorkspaceProject;
	widget: Widget;
	availableModels: IReportModel[];
	leafOnly: boolean;
	classification: IDocumentClassification[];
	predefinedMetrics: Metric[];
	hiddenOptions?: {[key in SuggestionType]: boolean};
	onSubmitSuggestion: (enrichment: AuditSuggestionEnrichment, oldOption: {name: string}, newOption: {name: string}) => void;
}

interface SuggestionMetricSettings {
	menuSettings: SuggestionMenuSettings;
	metricName: PredefinedMetricConstants;
	groupColorType: any;
	rules: ThresholdRule[];
	currentRawValue: number;
	removalEnabled: boolean;
}

export class SuggestionMenu {

	constructor(
		private $sce,
		private $q: ng.IQService,
		private GroupColorType,
		private locale: ILocale,
		private contextMenuTree: ContextMenuTree,
		private security: Security,
		private suggestionMenuOptionsUtils: SuggestionMenuOptionsUtils,
		private documentCacheService: DocumentCacheService,
		private readonly reportMetricsService: ReportMetricsService,
		private masterAccountApiService: MasterAccountApiService,
		private documentCaseService: DocumentCaseService,
		private readonly downgradeToastService: DowngradeToastService,
		private sentenceStyleEditorService: SentenceStyleEditorService,
		private readonly profanityDisguiseService: ProfanityDisguiseService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private $sanitize
	) {
	}

	openSentenceSuggestionMenu(event: Event, sentence: PreviewSentence, widget: Widget,
			leafOnly: boolean, enrichmentType?: PillType): ng.IPromise<void> {
		// this is called when we click on a row for feedback widget in table view
		$(event.currentTarget).addClass('hover');
		let projectIdentifier = ProjectIdentifier.fromWidgetProperties(widget.properties);
		const project = this.reportAssetUtilsService.getWidgetProject(widget);
		let documentCall = this.documentCacheService.getAnDocument(sentence.documentId, widget as IDocumentWidget, false);
		let metricsCall = PromiseUtils.old(this.reportMetricsService.getWidgetDynamicPredefinedMetrics(widget, false));
		let modelsCall = this.masterAccountApiService.getTuningSuggestionsAvailableModels(
			projectIdentifier,
			widget.properties.workspaceProject,
			AssetParametersFactory.fromWidget(widget));
		return this.$q.all([documentCall, metricsCall, modelsCall]).then(results => {
			let doc = results[0] as CaseDocument;
			let availableModels = _.filter(results[2],
				(model: IReportModel) => _.contains(widget.properties.selectedModels, model.id));
			let settings: SuggestionMenuSettings = {
				$event: event,
				sentence,
				classification: doc.classification,
				predefinedMetrics: results[1],
				widget,
				projectIdentifier,
				project,
				availableModels,
				leafOnly,
				hiddenOptions: this.getHiddenOptions(widget),
				onSubmitSuggestion: (enrichment, oldOption, newOption) => {
					this.documentCaseService.createAuditSuggestionCase(
						doc, sentence, widget,
						enrichment, oldOption, newOption, AssetParametersFactory.fromWidget(widget)).then(() => {
							this.downgradeToastService.addToast(this.locale.getString('docExplorer.suggestionSubmitted'));
						});
				}
			};
			if (enrichmentType
				&& this.allowSuggestionPillsForUser(enrichmentType, settings)) {
				this.openSuggestionMenuForPill(enrichmentType, settings).then(() => {
					$(event.currentTarget).removeClass('hover');
				});
			} else this.openSuggestionMenu(settings).then(() => {
				$(event.currentTarget).removeClass('hover');
			});
		});
	}

	handleSentenceTopicRemoval(event: Event, sentence: PreviewSentence, widget: Widget): Promise<void> {
		let documentCall = this.documentCacheService.getAnDocument(sentence.documentId, widget as IDocumentWidget, false);
		return Promise.resolve(documentCall).then((doc: CaseDocument) => {
			let topicId = parseInt($(event.target).attr('data-topic-id') || $(event.target).parents('[data-topic-id]').attr('data-topic-id'), 10);
			let currentOption = {name: this.suggestionMenuOptionsUtils.getTopicFullPath(topicId, doc.classification)};
			this.documentCaseService.createAuditSuggestionCase(doc, sentence, widget,
				AuditSuggestionEnrichment.TOPIC, currentOption, null, AssetParametersFactory.fromWidget(widget));
		});
	}

	openSuggestionMenu(settings: SuggestionMenuSettings): ng.IPromise<void> {
		let menu: Array<ContextMenuItem<any>> = _.filter([{
			name: SuggestionType.EFFORT,
			text: this.locale.getString('metrics.easeScore'),
			items: this.getSuggestionOptions({
				menuSettings: settings,
				currentRawValue: settings.sentence.easeScore,
				groupColorType: this.GroupColorType.types.EASE_5,
				metricName: PredefinedMetricConstants.EASE_SCORE,
				removalEnabled: true,
				rules: MetricThresholdRules.getEaseRules()
			})
		}, {
			name: SuggestionType.EMOTION,
			text: this.locale.getString('metrics.emotion'),
			items: this.getSuggestionOptions({
				menuSettings: settings,
				currentRawValue: settings.sentence.emotionIntensity,
				groupColorType: this.GroupColorType.types.EMOTION,
				metricName: PredefinedMetricConstants.EMOTION,
				removalEnabled: true,
				rules: MetricThresholdRules.getNumericBreakdownGroupRules()
			})
		}, {
			name: SuggestionType.SENTIMENT,
			text: this.locale.getString('metrics.sentiment'),
			items: this.getSuggestionOptions({
				menuSettings: settings,
				currentRawValue: settings.sentence.dScore,
				groupColorType: this.GroupColorType.types.SENTIMENT_5,
				metricName: PredefinedMetricConstants.SENTIMENT,
				removalEnabled: false,
				rules: MetricThresholdRules.getSentimentCalculationRules()
			})
		}, {
			name: SuggestionType.TOPIC,
			text: this.locale.getString('widget.topics'),
			items: [
				this.getAddNewTopicOption(settings),
				this.getRemoveTopicOption(settings)
			]
		}], menuItem => this.security.getCurrentMasterAccount().tuningSuggestionsSettings[menuItem.name]
			&& !settings.hiddenOptions?.[menuItem.name]);

		return this.contextMenuTree.show(settings.$event, settings.sentence, menu, 'audit-suggestion-menu');
	}


	openSuggestionMenuForPill(pillType: PillType, settings: SuggestionMenuSettings): ng.IPromise<void> {
		if (this.allowSuggestionPillsForUser(pillType, settings)) {
			switch (pillType) {
				case PillType.SENTIMENT: {
					let sentimentOptions = this.getSuggestionOptions({
						menuSettings: settings,
						currentRawValue: settings.sentence.dScore,
						groupColorType: this.GroupColorType.types.SENTIMENT_5,
						metricName: PredefinedMetricConstants.SENTIMENT,
						removalEnabled: false,
						rules: MetricThresholdRules.getSentimentCalculationRules()
					});
					return this.contextMenuTree.show(settings.$event, settings.sentence, sentimentOptions, 'audit-suggestion-menu');
				}
				case PillType.EASE_SCORE: {
					let effortOptions = this.getSuggestionOptions({
						menuSettings: settings,
						currentRawValue: settings.sentence.easeScore,
						groupColorType: this.GroupColorType.types.EASE_5,
						metricName: PredefinedMetricConstants.EASE_SCORE,
						removalEnabled: true,
						rules: MetricThresholdRules.getEaseRules()
					});
					return this.contextMenuTree.show(settings.$event, settings.sentence, effortOptions, 'audit-suggestion-menu');
				}
				case PillType.EMOTION: {
					let emotionOptions = this.getSuggestionOptions({
						menuSettings: settings,
						currentRawValue: settings.sentence.emotionIntensity,
						groupColorType: this.GroupColorType.types.EMOTION,
						metricName: PredefinedMetricConstants.EMOTION,
						removalEnabled: true,
						rules: MetricThresholdRules.getNumericBreakdownGroupRules()
					});
					return this.contextMenuTree.show(settings.$event, settings.sentence, emotionOptions, 'audit-suggestion-menu');
				}
				default: throw new Error(`Invalid pill type: ${pillType}`);
			}

		}

		return this.contextMenuTree.show(settings.$event, settings.sentence, [] as any, 'audit-suggestion-menu');

	}

	private allowSuggestionPillsForUser(pillType: PillType, settings: SuggestionMenuSettings): boolean {
		let suggestionType = PillsUtils.pillTypeToSuggestionType(pillType);
		return (this.security.getCurrentMasterAccount().tuningSuggestionsSettings[suggestionType]
			&& !settings.hiddenOptions?.[suggestionType]);

	}

	private getSuggestionOptions(metricSettings: SuggestionMetricSettings): any[] {
		let metric = _.findWhere(metricSettings.menuSettings.predefinedMetrics, { name: metricSettings.metricName });

		let currentValueOptionName: string = !_.isUndefined(metricSettings.currentRawValue)
			? this.getOptionByValue(metric, metricSettings.groupColorType, metricSettings.rules, metricSettings.currentRawValue)
			: null;

		let values = MetricThresholdRules.getValuesMatchingAllThresholds(metric.definition);
		let options = values.map(value => this.getSuggestionOption(metric, metricSettings.groupColorType, value));
		if (metricSettings.removalEnabled) {
			options.push(this.getRemoveMetricOption());
		}

		let currentOption = _.findWhere(options, { name: currentValueOptionName });
		if (currentOption) {
			currentOption.hidden = true;
		}

		options.forEach(option => {
			option.func = () => {
				metricSettings.menuSettings.onSubmitSuggestion(metric.name as AuditSuggestionEnrichment, currentOption, option);
			};
		});

		return _.filter(options, opt => !opt.hidden);
	}

	private getOptionByValue(metric: Metric, groupColorType, rules: ThresholdRule[], currentRawValue: number): string {
		let categoryFunction = groupColorType.getCategoryFunction(metric, true);
		let category = categoryFunction(metric, () => currentRawValue);
		return category.id;
	}

	private getSuggestionOption(metric: Metric, groupColorType, value: number): any {
		let getValueFunction = () => value;
		let byNumber = true;

		let colorFunction = groupColorType.getColorFunction(metric, byNumber);
		let iconFunction = groupColorType.getIconFunction(metric);
		let categoryFunction = groupColorType.getCategoryFunction(metric, byNumber);

		let fillColor = colorFunction(metric, getValueFunction);
		let icon = iconFunction({ [metric.name]: value });
		let category = categoryFunction(metric, getValueFunction);

		let categoryId = category.id;
		let categoryName = category.name;

		let iconHtml = `<span title="${categoryName}" class="mr-8 ${icon}" style="color:${fillColor}" aria-hidden="true"></span>`;
		let optionTextHtml = this.$sanitize(`<span>${categoryName}</span>`);

		let html = `<div class="d-flex align-items-center">${iconHtml}${optionTextHtml}</div>`;

		return {
			name: categoryId,
			text: `${this.$sce.trustAsHtml(html)}`,
			displayName: categoryName,
			tooltip: categoryName,
			noMargin: true
		};
	}

	private getRemoveMetricOption(): any {
		let title = this.locale.getString('docExplorer.removeEnrichment');
		let icon = 'q-icon q-icon-trash';

		let iconHtml = `<span title="${title}" class="mr-8 ${icon}" aria-hidden="true"></span>`;
		let optionTextHtml = this.$sanitize(`<span>${title}</span>`);

		let html = `<div class="d-flex align-items-center">${iconHtml}${optionTextHtml}</div>`;

		return {
			name: null,
			text: `${this.$sce.trustAsHtml(html)}`,
			tooltip: title,
			noMargin: true,
			isRemoval: true
		};
	}

	private getAddNewTopicOption(settings: SuggestionMenuSettings): any {
		let icon = this.$sce.trustAsHtml('<span class="q-icon q-icon-add mr-8" aria-hidden="true"></span>');
		return {
			name: 'addNewTopic',
			text: this.locale.getString('docExplorer.addNew'),
			formattedOptionName: `
				<div class="d-flex align-items-center">
					${icon} ${this.locale.getString('docExplorer.addNew')}
				</div`,
			searchBox: true,
			noMargin: true,
			items: this.suggestionMenuOptionsUtils.getModelsOptions(settings, this.handleAddTopicSelection(settings))
		};
	}

	private getRemoveTopicOption(settings: SuggestionMenuSettings): any {
		let tree = this.suggestionMenuOptionsUtils.getClassifiedModelsOptions(settings);
		if (!tree.length) {
			return;
		}

		let icon = this.$sce.trustAsHtml('<span class="q-icon q-icon-trash mr-8" aria-hidden="true"></span>');

		return {
			name: 'removeTopic',
			text: this.locale.getString('docExplorer.removeExisting'),
			formattedOptionName: `
				<div class="d-flex align-items-center">
					${icon} ${this.locale.getString('docExplorer.removeExisting')}
				</div`,
			noMargin: true,
			items: [{
				noMargin: true,
				searchBox: true,
				skipExpandable: true,
				skipSelection: true,
				tree,
				func: this.handleTopicRemovalSelection(settings)
			}]
		};
	}

	private getHiddenOptions(widget: Widget): any {
		let columns = widget.visualProperties.columns || [];
		return _.chain(SuggestionType)
			.values()
			.map(val => {
				let isOptionDisabled = val === SuggestionType.TOPIC
					? this.isOptionDisabled(columns, PreviewColumn.TOPICS) || _.isEmpty(widget.properties.selectedModels)
					: this.isOptionDisabled(columns, val);
				return [val, isOptionDisabled];
			})
			.object()
			.value();
	}

	private isOptionDisabled(columns: any[], type: string): boolean {
		let column = _.findWhere(columns, {name: type});
		return !column || column.disabled;
	}

	private handleAddTopicSelection(settings: SuggestionMenuSettings): any {
		return (sentiment, tree, node: ModelTreeNode) => {
			if (node.level === 0) {
				return;
			}
			if (settings.leafOnly && !_.isEmpty(node.children)) {
				return;
			}

			this.contextMenuTree.closeMenu();
			settings.onSubmitSuggestion(AuditSuggestionEnrichment.TOPIC, null, {name: tree[0].name + ' > ' + node.path});
		};
	}

	private handleTopicRemovalSelection(settings: SuggestionMenuSettings): any {
		return (sentiment, tree, node: ModelTreeNode) => {
			if (node.level === 0) {
				return;
			}
			if (settings.leafOnly && !_.isEmpty(node.children)) {
				return;
			}

			this.contextMenuTree.closeMenu();
			settings.onSubmitSuggestion(AuditSuggestionEnrichment.TOPIC, {name: node.path}, null);
		};
	}

	openSuggestionMenuFromDocumentPane = ($event: UIEvent, sentence: PreviewSentence,
			documentManager: IDocumentPreviewerControls, leafOnly: boolean, pill?: Pill): ng.IPromise<any> => {


		if (this.profanityDisguiseService.needToMaskSentence(sentence)) {
			return this.$q.resolve();
		}

		this.addSentenceHoverClass(documentManager, sentence);
		let metricsPromise = PromiseUtils.old(this.reportMetricsService.getWidgetDynamicPredefinedMetrics(
			documentManager.widget,
			documentManager.isDocExplorer));
		let projectIdentifier = documentManager.getProjectIdentifier();
		const project = this.reportAssetUtilsService.getWidgetProject(documentManager.widget);
		let modelsPromise = this.masterAccountApiService.getTuningSuggestionsAvailableModels(
			projectIdentifier,
			documentManager.getWorkspaceProject(),
			documentManager.getAssetParameters());
		let document = this.getCurrentDocument(documentManager);
		let promise = documentManager.state.loadingDocPanel = this.$q.all([metricsPromise, modelsPromise]);
		return promise.then(([predefinedMetrics, tuningSuggestionModels]) => {
			let projectUniqueId = `${projectIdentifier.contentProviderId}/${projectIdentifier.accountId}/${projectIdentifier.projectId}`;
			let searchModels = (documentManager as any).preferences?.settings?.modelSearch[projectUniqueId] || {};
			let availableModels = _.filter(documentManager.availableModels,
				model => !!_.findWhere(tuningSuggestionModels, {id: model.id}) && searchModels[model.name] !== false);

			if (pill) {
				return this.openSuggestionMenuForPill(pill.type, {
					$event,
					sentence,
					projectIdentifier,
					project,
					widget: documentManager.widget,
					predefinedMetrics,
					classification: document.classification,
					availableModels,
					leafOnly,
					onSubmitSuggestion: (enrichment, currentOption, newOption) => {
						this.documentCaseService.createAuditSuggestionCase(
							document, sentence, documentManager.widget,
							enrichment, currentOption, newOption,
							documentManager.getAssetParameters()).then(() => this.afterCaseSubmitted(documentManager));
					}
				}).then(() => this.removeSentenceHoverClass(documentManager, sentence));
			}
			return this.openSuggestionMenu({
				$event,
				sentence,
				projectIdentifier,
				project,
				widget: documentManager.widget,
				predefinedMetrics,
				classification: document.classification,
				availableModels,
				leafOnly,
				onSubmitSuggestion: (enrichment, currentOption, newOption) => {
					this.documentCaseService.createAuditSuggestionCase(
						document, sentence, documentManager.widget,
						enrichment, currentOption, newOption,
						documentManager.getAssetParameters()).then(() => this.afterCaseSubmitted(documentManager));
				}
			}).then(() => this.removeSentenceHoverClass(documentManager, sentence));
		});
	}

	private addSentenceHoverClass = (documentManager: IDocumentPreviewerControls, sentence: ConversationSentence): void => {
		if (DocumentTypeUtils.isConversation(this.getCurrentDocument(documentManager))) {
			let wrapperLocator = documentManager.isDocExplorer ? `.an-document-explorer` : `#widget-${documentManager.widget?.id}`;
			this.sentenceStyleEditorService.addVoiceSentenceHover(sentence.id, wrapperLocator);
		} else {
			sentence.suggestionMenuOpened = true;
		}
	}

	private removeSentenceHoverClass = (documentManager: IDocumentPreviewerControls, sentence: ConversationSentence): void => {
		if (DocumentTypeUtils.isConversation(this.getCurrentDocument(documentManager))) {
			this.sentenceStyleEditorService.removeVoiceSentenceHover();
		} else {
			sentence.suggestionMenuOpened = false;
		}
	}

	getCurrentDocument = (documentManager: IDocumentPreviewerControls): PreviewDocument => {
		return documentManager?.documents[documentManager.selectedDocument];
	}

	suggestTopicRemoval = (event: TopicRemoveEvent, documentManager: IDocumentPreviewerControls): void => {
		let document = this.getCurrentDocument(documentManager);
		let caseDocument = ObjectUtils.copy(document) as any as CaseDocument;
		let sentence = _.findWhere(caseDocument.sentences, {id: event.sentenceId});

		this.documentCaseService.createAuditSuggestionCase(caseDocument, sentence,
			documentManager.widget,
			AuditSuggestionEnrichment.TOPIC, {name: event.topic}, null, documentManager.getAssetParameters()
		).then(() => this.afterCaseSubmitted(documentManager));
	}

	private afterCaseSubmitted = (documentManager: IDocumentPreviewerControls): void => {
		documentManager.loadEngagorCases(this.getCurrentDocument(documentManager)?.id);
		this.downgradeToastService.addToast(this.locale.getString('docExplorer.suggestionSubmitted'));
	}
}

app.service('suggestionMenu', SuggestionMenu);
