import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { BubbleConfigInput, BubbleConfigurationModalComponent } from './bubble-configuration/bubble-configuration-modal/bubble-configuration-modal.component';

@Injectable({
	providedIn: 'root'
})
export class BubbleConfigurationService {

	constructor(
		private cxDialogService: CxDialogService,
	) { }

	configure(propertyMap: any, options: VisualProperties): Promise<any> {
		let input: BubbleConfigInput = {
			configItem: options,
			propertyMap
		};

		// class created for e2e
		return this.cxDialogService
		.openDialog(BubbleConfigurationModalComponent, input, 
			{size: ModalSize.SMALL, class: 'modal-bubble-options'}
		)
		.result
		.catch(_.noop);
	}
}

app.service('bubbleConfigurationService', downgradeInjectable(BubbleConfigurationService));
