import * as _ from 'underscore';
import { CapitalizationType } from './constants/capitalization-type.enum';

export type StringFormatter = (input: string) => string;

export class CapitalizationUtils {
	static SPLIT_REGEX = /(&gt;|&lt;|&quot;|&amp;)/;

	static getWrappedFormatter(type: CapitalizationType): StringFormatter {
		return (inputString: any) => {
			if (typeof inputString !== 'string')
				return inputString;

			let capitalizationFn = this.getCapitalizationFunction(type);
			return this.capitalize(inputString, capitalizationFn);
		};
	}

	static reflect = (val) => val;

	private static capitalize(inputString: string, capitalizationFn: StringFormatter): string {
		let items = inputString.split(CapitalizationUtils.SPLIT_REGEX);
		
		// even indeces should be capitalized, odd indeces are split tokens,
		// that should be preserved
		let result = '';
		for (let i = 0; i < items.length; i++) {
			if (i % 2 === 0)
				result += capitalizationFn(items[i]);
			else
				result += items[i];
		}

		return result;
	}
	
	private static getCapitalizationFunction(type: CapitalizationType): StringFormatter {
		switch (type) {
			case CapitalizationType.LOWERCASE:
				return inputString => inputString.toLowerCase();
			case CapitalizationType.UPPERCASE:
				return inputString => inputString.toUpperCase();
			case CapitalizationType.SENTENCE_CASE:
				return inputString => inputString.capitalize();
			case CapitalizationType.FIRST_LETTER:
				return inputString => inputString.capitalizeEveryWord(['-', '_']);
			default:
				return inputString => inputString;
		}
	}
}
