import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { AssetManagementModule } from '../asset-management/asset-management.module';
import { ProjectModule } from '../project/project.module';
import { WidgetVisualizationsModule } from '../widget-visualizations/widget-visualizations.module';
import { AttributeDependenciesApiService } from './services/assets/attribute-dependencies-api-service';
import { AttributeDependencyTypesProvider } from './services/assets/attribute-dependency-types-provider';
import { NumericAttributeCalculationSettingsEditorProvider } from './services/assets/settings-editors/providers/numeric-attribute-calculation-settings-editor.provider';
import { NumericAttributeGroupingSettingsEditorProvider } from './services/assets/settings-editors/providers/numeric-attribute-grouping-settings-editor.provider';
import { TextAttributeGroupingSettingsEditorProvider } from './services/assets/settings-editors/providers/text-attribute-grouping-settings-editor.provider';


@NgModule({
	imports: [
		SharedModule,
		AssetManagementModule,
		WidgetVisualizationsModule,
		ProjectModule,
	],
	providers: [
		AttributeDependenciesApiService,
		AttributeDependencyTypesProvider,
		NumericAttributeCalculationSettingsEditorProvider,
		NumericAttributeGroupingSettingsEditorProvider,
		TextAttributeGroupingSettingsEditorProvider,
	]
})
export class AttributeModule { }
