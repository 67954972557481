import { Deferred, PromiseUtils } from '@app/util/promise-utils';

/**
 * Factory to create promise queues.
 * Promise queue ensures, that incoming promises will be executed in order they are submitted.
 * Only *limit* promises from the queue are executed at the same time. The default *limit* is 1.
 */

export interface PromiseQueueOptions {
	limit: number;
}

const DEFAULT_OPTIONS: PromiseQueueOptions = {
	limit: 1
};

interface PromiseQueueItem<T = any> {
	func: PromiseSupplier<T>;
	deferred: Deferred<T>;
	cancelled: boolean;
}

type PromiseSupplier<T> = () => Promise<T>;

export class PromiseQueue {

	private options: PromiseQueueOptions;

	private executing = 0;
	private stopped = false;
	private queue: PromiseQueueItem[] = [];

	constructor(options?: Partial<PromiseQueueOptions>) {
		this.options = _.extend({}, DEFAULT_OPTIONS, options);
	}

	execute<T>(requestFunction: PromiseSupplier<T>): Promise<T> {
		let item = this.createQueueItem(requestFunction);
		this.queue.push(item);
		this.pollAndExecute();
		return item.deferred.promise;
	}

	private createQueueItem<T>(func: PromiseSupplier<T>): PromiseQueueItem<T> {
		let deferred = PromiseUtils.defer<T>();
		return {
			func,
			deferred,
			cancelled: false,
		};
	}

	private pollAndExecute(): boolean {
		if (this.stopped || !this.hasSpace() || !this.hasItems()) return false;

		let call = this.queue.shift();
		call.func().then(
			this.finishExecution(call, true),
			this.finishExecution(call, false)
		);
		this.executing++;
		return true;
	}

	private finishExecution<T>(item: PromiseQueueItem<T>, success: boolean): (response: T) => void {
		return (response: T) => {
			this.executing--;
			if (!item.cancelled) {
				if (success) item.deferred.resolve(response);
				else item.deferred.reject(response);
			}

			this.pollAndExecute();
		};
	}

	isExecuting(): boolean {
		return this.executing > 0;
	}

	private hasSpace(): boolean {
		return this.executing < this.options.limit;
	}

	hasItems(): boolean {
		return this.queue.length !== 0;
	}

	clear(): void {
		this.queue.forEach((item) => {
			item.cancelled = true;
		});

		this.queue = [];
		this.executing = 0;
	}

	start(): void {
		this.stopped = false;

		let polled = true;
		do {
			polled = this.pollAndExecute();
		} while (polled);
	}

	stop(): void {
		this.stopped = true;
	}

	isStopped(): boolean {
		return this.stopped;
	}
}

export class SequentialPromiseQueue extends PromiseQueue {
	constructor() {
		super({limit: 1});
	}
}
