export class CustomDialogController {
	constructor(
		private readonly $scope,
		private readonly $uibModalInstance,
		private readonly data,
		private readonly $window,
		private readonly $controller,
		private readonly $timeout
	) {
		data.customButtons = data.customButtons || [];
		$scope.data = data;
		$scope.instance = $uibModalInstance;

		this.$scope.cancel = this.cancel;
		this.$scope.save = this.save;
		this.$scope.isSaveDisabled = this.isSaveDisabled;
		this.$scope.redirect = this.redirect;

		if (data.controller) {
			this.$controller(data.controller, { $scope: this.$scope });
		}

		if (data.confirmationDelay) {
			this.$scope.confirmationDelayed = true;
			this.$scope.confirmationDelayPromise = this.$timeout(() => {
				this.$scope.confirmationDelayed = false;
			}, data.confirmationDelay);
		} else {
			$scope.confirmationDelayed = false;
		}
	}

	$onInit(): void {
		// required for typescript validation
	}

	cancel = () => {
		if (this.data.cancel)
			this.data.cancel(this.$uibModalInstance, this.data);
		else this.$uibModalInstance.dismiss();
	}

	save = () => {
		if (this.data.save)
			this.data.save(this.$uibModalInstance, this.data, this.$scope);
		else this.$uibModalInstance.close(this.data);
	}

	isSaveDisabled = () => {
		if (this.data.isSaveDisabled) {
			return this.data.isSaveDisabled();
		} else {
			return false;
		}
	}

	redirect = () => {
		this.$window.location.href = '';
	}
}

app.controller('CustomDialogCtrl', CustomDialogController);
