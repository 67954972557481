<div class="panel panel-default widget-cache-settings" [ngBusy]="loading">
	<div class="panel-heading" [i18n]="'mAccount.widgetCacheSettingsPanelHeader'"></div>
	<div class="panel-body clearfix">
		<form class="form-horizontal" #widgetCacheSettingsForm="ngForm">
			<ng-container *ngIf="widgetCacheSettings">
				<div class="d-flex align-items-center mb-16" >
					<cb-toggle [(ngModel)]="widgetCacheSettings.enabled" (ngModelChange)="toggleWidgetCacheSettings()"
						name="widgetCacheSettingsStatus"></cb-toggle>
					<span class="ml-16" [i18n]="'mAccount.widgetCacheSettingsToggle'"></span>
				</div>
				<div [hidden]="!widgetCacheSettings.enabled">
					<span class="help-block" [i18n]="'mAccount.widgetCacheSettingsDescription'"></span>
					<div class="mb-16">
						<span [i18n]="'mAccount.acceptableAge1'"></span>
						<input type="text" [(ngModel)]="widgetCacheSettings.acceptableAgeMinutes"
							id="acceptableAgeMinutes" class="form-control  mh-4" name="acceptableAgeMinutes"
							[pattern]="NUMBER_PATTERN" [min]="0" [max]="options.maxAcceptableAgeMinutes"
							[maxlength]="5">

						<span [i18n]="'mAccount.acceptableAge2'"></span>
						<p class="help-block error-block"
							*ngIf="hasAcceptableAgeMinutesPatternError()">
							{{'mAccount.allowedOnlyDigits'|i18n}}
						</p>
						<p class="help-block error-block"
							*ngIf="hasAcceptableAgeMinutesRangeError()"
							[i18n]="'mAccount.rangeError'"
							[i18nParams]="{min: 0, max: options.maxAcceptableAgeMinutes}"></p>
					</div>
					<div>
						<span [i18n]="'mAccount.acceptableWaiting1'"></span>
						<input type="text" [(ngModel)]="widgetCacheSettings.acceptableWaitingSeconds"
							id="acceptableWaitingSeconds" class="form-control  mh-4" name="acceptableWaitingSeconds"
							[pattern]="NUMBER_PATTERN" [min]="0" [max]="options.maxAcceptableWaitingSeconds"
							[maxlength]="4">
						<span [i18n]="'mAccount.acceptableWaiting2'"></span>
						<p class="help-block error-block"
							*ngIf="hasAcceptableAgeSecondsPatternError()">
							{{'mAccount.allowedOnlyDigits'|i18n}}
						</p>
						<p class="help-block error-block"
							*ngIf="hasAcceptableAgeSecondsRangeError()"
							[i18n]="'mAccount.rangeError'"
							[i18nParams]="{min: 0, max: options.maxAcceptableWaitingSeconds}"></p>
					</div>
					<div>
						<button type="submit" class="btn btn-primary" (click)="saveWidgetCacheSettings()"
							[disabled]="!isWidgetCacheSettingsFormValid()">{{'common.save'|i18n}}</button>
					</div>
				</div>
			</ng-container>
		</form>
	</div>
</div>
