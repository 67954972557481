import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { DropdownMenuGroupComponent } from '@app/modules/document-explorer/document-explorer-menu/dropdown-menu-group/dropdown-menu-group.component';
import { AmplitudeTrackedToggle } from '../amplitude-tracked-toggle.directive';

export type AmplitudeToggleEvent = {
	event: AmplitudeEvent;
	group: {[key: string]: any};
	properties: {[key: string]: any};
};

@Component({
	selector: 'dropdown-toggle-option',
	template: `
	<li (click)="$event.stopPropagation()">
		<labeled-toggle-switch [value]="value"
			(valueChange)="valueChange.emit($event)"
			[label]="label"
			[groupLabel]="groupLabel"
			[dropdownItem]="true"
			[disabled]="disabled"
			[small]="small"
		></labeled-toggle-switch>
	</li>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownToggleOptionComponent extends AmplitudeTrackedToggle {

	@Input() value: boolean;
	@Output() valueChange = new EventEmitter<boolean>();
	@Input() label: string;
	@Input() groupLabel: string;
	@Input() disabled: boolean = false;
	@Input() small: boolean = false;
	@Input() amplitudeEvent?: AmplitudeToggleEvent;

	constructor(
		@Optional() protected readonly _parentComponent?: DropdownMenuGroupComponent
	) {
		super(_parentComponent);
	}
}

app.directive('dropdownToggleOption', downgradeComponent({component: DropdownToggleOptionComponent}));
