
<div class="d-flex flex-direction-column h-100-percent pt-16" [ngBusy]="loading">
	<default-objects-table class="flex-fill overflow-hidden"
		tableName="projectModels"
		[hidden]="visibility !== ProjectTabType.AVAILABLE"
		[columnDefs]="columnDefs"
		[rowData]="models"
		[withFolders]="false"
		[quickFilterText]="searchText"
		[tableFilterManager]="filterManager"
		[getContextMenuItems]="getContextMenuItems"
		(gridReady)="onGridReady($event)"
	></default-objects-table>

	<hidden-assets-table class="flex-fill overflow-hidden"
		[hidden]="visibility !== ProjectTabType.HIDDEN"
		[project]="project"
		[resourceType]="AssetType.MODEL"
		[searchText]="searchText">
	</hidden-assets-table>

</div>
