import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';


@Injectable({providedIn: 'root'})
export class CustomMathPlaceholderService {

	constructor(
		private locale: CxLocaleService
	) {}

	appendPlaceholder(editorContentWrapper: HTMLElement): void {
		let placeholder: Text = document.createTextNode(this.locale.getString('metrics.customMathEditorPlaceholder'));
		editorContentWrapper.appendChild(placeholder);
	}

	removePlaceholder(editorContentWrapperId: string): void {
		let editorContentWrapper: HTMLElement = document.getElementById(editorContentWrapperId);
		editorContentWrapper.textContent = '';
	}

}