import { VideoObject } from '@app/modules/widget-visualizations/content-widgets/video/video-object';
import { PromiseUtils } from '@app/util/promise-utils';

export class VimeoVideo implements VideoObject {
	readonly VIMEO_REGEXP =
		/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;

	readonly PREVIEW_VIMEO_API = 'http://vimeo.com/api/v2/video/{id}.json';

	constructor(private url: string) {}

	getThumbnail(client: (url: string) => Promise<any>): Promise<string> {
		if (!this.url)
			return Promise.reject();

		return PromiseUtils.wrap(client(`http://vimeo.com/api/v2/video/${this.getId()}.json`)).then((json: any) => {
			if (!json || !json.length)
				return;
			return json[0].thumbnail_large;
		});
	}

	getFullUrl(): string {
		return `https://player.vimeo.com/video/${this.getId()}?wmode=transparent`;
	}

	private getId(): string {
		if (!this.url)
			return;
		let match = this.url.match(this.VIMEO_REGEXP);
		return match ? match[3] : undefined;
	}
}
