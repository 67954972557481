<ng-template #loader><div class="d-flex align-items-center"><div class="loader"></div></div></ng-template>

<div class="col-sm-12 p-0">
	<div class="br-list-box p-32">
		<div class="grid-list-tools">
			<div class="tools">
				<div class="grid-filters">
					<label class="font-bold mb-0">
						{{'appearance.searchPalettes'|i18n}}
						<input
							(keydown.escape)="searchPalettes=''"
							type="search"
							[attr.disabled]="getColorPalettes()?.length ? null : true"
							class="grid-search-bar d-block"
							[attr.aria-label]="'appearance.searchPalettes'|i18n"
							[(ngModel)]="searchPalettes"
							data-testid="find-color-palettes"
						/>
					</label>
				</div>

				<div class="grid-buttons" [attr.title]="getCreatePaletteTooltip()">
					<button
						id="btn-new-palette"
						class="btn btn-primary"
						[attr.disabled]="limitReached() ? true : null"
						(click)="createPalette()">
						{{'appearance.createPalette'|i18n}}
					</button>
				</div>
			</div>
			<div *ngIf="errors.limitReached" class="flex-direction-row">
				<alert type="danger" text="{{'appearance.palettesLimitExceeded'|i18n}}"></alert>
			</div>
		</div>

		<div [ngBusy]="{ busy: loading, template: loader, minDuration: 500 }">
			<item-grid
				class="h-100-percent w-100-percent br-palettes-grid br-grid"
				[treeData]="getColorPalettes()"
				[gridFilter]="searchPalettes"
				[gridChange]="changedItems"
				[gridOptions]="gridOptions"
				[gridType]="gridType"
				[nameField]="gridNameField">
			</item-grid>
		</div>
	</div>
</div>
