import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export enum CalendarType {
	USA = 'USA',
	EUROPEAN = 'EUROPEAN'
}

export enum WeekIdentifier {
	WEEK_OF = 'weekOf',
	WEEK_DESCRIPTION = 'weekDescription'
}

export enum OverlappingYears {
	FOLLOWING_YEAR = 'followingYear',
	CURRENT_YEAR = 'currentYear'
}

export interface CalendarFormatOptions {
	type: CalendarType;
	weekIdentifier: WeekIdentifier;
	overlappingYears: OverlappingYears;
}

export class CalendarSettingsEntry {
	id?: number;
	projectId: number;
	enabled: boolean;
	projectName?: string;
	workspaceProject: WorkspaceProject;
	name: string;
	type: CalendarType;
	weekIdentifier: WeekIdentifier;
	overlappingYears: OverlappingYears;
	modifiedDate: Date;
}
