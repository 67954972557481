import * as _ from 'underscore';
import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import MobileAppSettingsApiService from './mobile-app-settings-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { UniqueNameService } from '@cxstudio/common/unique-name-service';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import { MobileSettingsActionService } from './mobile-settings-action.service';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { MobileAppSettingsList } from './mobile-app-settings-list';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';


export class MobileAppContextMenu {
	constructor(
		private locale: ILocale,
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private cbDialogService: CBDialogService,
		private globalNotificationService: GlobalNotificationService,
		private uniqueNameService: UniqueNameService,
		private mobileSettingsActionService: MobileSettingsActionService
	) {
	}

	build(item: MobileAppSettingsListModel, settingsList: MobileAppSettingsList,
			reloadSettingsListCallback: () => void, widgetSettingsCache: CachedInvocation): Array<ContextMenuItem<MobileAppSettingsListModel>> {

		const viewMenuItem = {
			text: this.locale.getString('common.view'),
			name: 'view',
			func: settings => this.mobileSettingsActionService.viewSettings(settingsList, settings, widgetSettingsCache)
				.then(reloadSettingsListCallback)
		};

		const editMenuItem = {
			text: this.locale.getString('common.edit'),
			name: 'edit',
			func: settings => this.mobileSettingsActionService.editSettings(settingsList, settings, widgetSettingsCache)
				.then(reloadSettingsListCallback)
		};

		const copyMenuItem = {
			text: this.locale.getString('common.makeCopy'),
			name: 'copy',
			func: settings => this.duplicateSettings(settings, settingsList).then(reloadSettingsListCallback)
		};

		const shareMenuItem = {
			text: this.locale.getString('common.share'),
			name: 'share',
			func: settings => this.mobileSettingsActionService.shareSettings(settings).then(reloadSettingsListCallback)
		};

		const deleteMenuItem = {
			text: this.locale.getString('common.delete'),
			name: 'delete',
			func: settings => this.deleteSettings(settings).then(reloadSettingsListCallback)
		};

		const createAsDashboard = {
			text: this.locale.getString('mobile.createAsDashboard'),
			name: 'createAsDashboard',
			func: settings => this.mobileSettingsActionService.createAsDashboard(settings)
		};

		const menuItems = [
			this.mobileSettingsActionService.canEdit(item) ? editMenuItem : viewMenuItem,
			this.mobileSettingsActionService.canShare(item) ? shareMenuItem : null,
			copyMenuItem,
			!item.defaultForMasterAccount && this.mobileSettingsActionService.isOwner(item) ? deleteMenuItem : null,
			this.mobileSettingsActionService.canEdit(item) ? MenuDivider : null,
			this.mobileSettingsActionService.canEdit(item) ? createAsDashboard : null
		]
		.filter(menuItem => (Boolean) (menuItem));

		return menuItems;
	}

	private duplicateSettings = (settings: MobileAppSettingsListModel, settingsList: MobileAppSettingsList): ng.IPromise<void> => {
		const copyName = this.uniqueNameService.resolveCopyName(settingsList.items.map(item => item.name), settings.name);

		return this.mobileAppSettingsApiService.duplicateSettings(settings.id, copyName)
			.then(() => this.globalNotificationService.addSuccessNotification(
				this.locale.getString('mobile.notificationApplicationCreated', { name: copyName })));
	}

	private deleteSettings = (settings: MobileAppSettingsListModel): ng.IPromise<void> => {
		return this.cbDialogService
			.confirm(
				this.locale.getString('mobile.confirmDeleteSettingsHeader', { name: settings.name }),
				this.locale.getString('mobile.confirmDeleteSettingsPrompt', { name: settings.name }))
			.result
			.then(() => this.mobileAppSettingsApiService.deleteSettings(settings.id))
			.then(() => this.globalNotificationService.addSuccessNotification(
				this.locale.getString('mobile.notificationApplicationDeleted', { name: settings.name })));
	}

}

app.service('mobileAppContextMenu', MobileAppContextMenu);
