import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import DashboardFilterBuildersService from './services/dashboard-filter-builders.service';
import { DashboardFiltersDragDropService } from './services/dashboard-filters-drag-drop.service';
import { DashboardLanguageSettingsComponent } from './properties-modal/dashboard-language-settings/dashboard-language-settings.component';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
	imports: [
		CommonModule,
		I18nModule,
		SharedModule
	],
	declarations: [
		DashboardLanguageSettingsComponent
	],
	providers: [
		WidgetApiService,
		WidgetModificationService,
		DashboardFilterBuildersService,
		DashboardFiltersDragDropService
	],
})
export class DashboardEditModule { }
