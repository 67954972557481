export enum ClarabridgeAttributeName {
	CONTENT_TYPE = 'cb_content_type',
	CONTENT_SUBTYPE = 'cb_content_subtype',
	DETECTED_FEATURES = 'cb_bc_detected',
	LANGUAGE = 'LANGUAGE',
	PROCESSED_LANGUAGE = '_language',
	DETECTED_LANGUAGE = '_languagedetected',

	CB_BRAND = 'cb_bc_brand',
	CB_COMPANY = 'cb_bc_company',
	CB_EMAIL = 'cb_bc_email',
	CB_EMOTICON = 'cb_bc_emoticon',
	CB_EMOTION = 'cb_emotion',
	CB_CHAPTERS = 'cb_conv_chapters',
	CB_STATED_DURATION = 'cb_stated_duration',
	CB_EVENT = 'cb_bc_event',
	CB_INDUSTRY = 'cb_bc_industry',
	CB_PERSON = 'cb_bc_person',
	CB_PHONE = 'cb_bc_phone',
	CB_CURRENCY = 'cb_bc_currency',
	CB_PROFANITY = 'cb_bc_profanity',
	CB_PRODUCT = 'cb_bc_product',
	CB_SENTENCE_TYPE = 'cb_sentence_type',
	CB_SENTENCE_QUARTILE = 'cb_sentence_quartile',
	CB_SENTENCE_WORD_COUNT = 'cb_sentence_word_count',
	CB_DOCUMENT_WORD_COUNT = 'cb_document_word_count',
	CB_LOYALTY_TENURE = 'cb_loyalty_tenure',
	REASON_DETECTION = 'cb_conv_sentence_reason',
	CB_CONV_DURATION = 'cb_conv_duration',
	CB_CONV_PARTICIPANT_TYPE = 'cb_conv_participant_type',
	CB_CONV_PARTICIPANT_KIND = 'cb_conv_participant_kind',
	CB_CONV_PERCENT_SILENCE = 'cb_conv_percent_silence',
	CB_CONV_SENTENCE_DURATION_MS = 'cb_conv_sentence_duration_ms',
	CB_CONV_SENTENCE_START_TIME_MS = 'cb_conv_sentence_start_time_ms',
	CB_CONV_TOTAL_DEAD_AIR = 'cb_conv_total_dead_air',
	CB_CONV_TOTAL_HESITATION = 'cb_conv_total_hesitation',
	CB_CONV_TOTAL_OVERTALK = 'cb_conv_total_overtalk',
	CB_CONV_TOTAL_SILENCE = 'cb_conv_total_silence',
	CB_CONCLUDING_EVENT = 'cb_concluding_event',
	CB_CONV_OUTCOME = 'cb_conv_outcome',
	CB_PARTICIPANT_OUTCOME = 'cb_participant_outcome',
	CB_PARTICIPANT_EMPATHY_SCORE = 'cb_participant_empathy_score',
	CB_MEDICAL_CONDITION = 'cb_medical_condition',
	CB_MEDICAL_PROCEDURE = 'cb_medical_procedure',
	CB_RX = 'cb_rx',
	CB_INTERACTION_TYPE = 'cb_interaction_type',
	CB_INTERACTION_HIGHLIGHTS = 'cb_interaction_highlights',
}
