import * as cloneDeep from 'lodash.clonedeep';
import { ColorPaletteConstants } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';

export class ColorPalettesHelper {
	private palettes: WidgetColorPalette[];

	constructor(palettes: WidgetColorPalette[], providerPalette: string[]) {
		this.palettes = cloneDeep(palettes);
		const providerDuplicate = _.find(this.palettes,
			(palette) => palette.designerPalette && !palette.deleted);
		if (providerDuplicate) {
			providerDuplicate.colors = providerPalette;
		}
		return this;
	}

	getPaletteColors(name: string, isDark?: boolean): string[] {
		const palette = this.getActivePaletteColor(name);
		// fallback to clarabridge palette if N/A (e.g. internal user added for normal user)
		return isDark && palette?.darkModeColors || palette?.colors || ColorPaletteConstants.getPalette1();
	}

	private getActivePaletteColor(name: string): WidgetColorPalette {
		let palette = _.findWhere(this.palettes, {name});
		if (palette?.deleted && palette.replacement) {
			palette = _.findWhere(this.palettes, {id: palette.replacement});
		}
		return palette;
	}
}
