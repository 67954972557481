<modal-header
	[modalTitle]="'administration.transferDashboard' | i18n"
	(cancel)="dismiss()">
</modal-header>
<section class="confirm-body modal-body">
	<div [innerHTML]="bodyText"></div>
	<div class="labeled-checkbox mt-16" *ngIf="showCheckbox">
		<input
			name="retainEditPermission"
			id="retainEditPermission"
			type="checkbox"
			[(ngModel)]="retainEditPermission"
		>
		<label for="retainEditPermission">
			{{'administration.retainShareEditAccess' | i18n }}
		</label>
	</div>
</section>
<save-modal-footer
	[cancelText]="'common.cancel' | i18n"
	(cancel)="dismiss()"
	(save)="close(retainEditPermission)"
	[saveText]="'common.yes' | i18n">
</save-modal-footer>
