import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';

export interface HasDirty<T> {
	_dirty?: {[column in keyof T]?: boolean};
}

export class ObjectListUtils {

	static isFolder(item: ITreeItem): boolean {
		return !!item && /.*folder.*/i.test(item.type);
	}

	static isGeneratedAsset(item: ITreeItem): boolean {
		return !!item && (item.type === FilterTypes.PREDEFINED
			|| item.type === GridContext.METRIC_PREDEFINED
			|| item.type === DriversType.drivers_template);
	}

	static getFieldChanges<T extends HasDirty<T>>(items: T[], field: keyof T): T[] {
		return items.filter(item => {
			return item._dirty?.[field];
		});
	}

	static markDirty<T extends HasDirty<T>>(item: T, field: keyof T): void {
		if (!item._dirty) {
			item._dirty = {};
		}
		item._dirty[field] = true;
	}

	static clearDirty<T extends HasDirty<T>>(items: T[]): void {
		return items.forEach(item => delete item._dirty);
	}

	static isDirty<T extends HasDirty<T>>(item: T, field: keyof T): boolean {
		return item._dirty?.[field];
	}
}
