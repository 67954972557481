import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnInit, OnDestroy, Input } from '@angular/core';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ApplicationTheme } from '@cxstudio/header/application-theme';

@Component({
	selector: 'dashboard-theme-toggle',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<div class="pv-4 dropdown-item no-hover font-semibold" (click)="$event.stopPropagation();">{{'dashboard.previewColorTheme'|i18n}}</div>
		<div id="dashboard-theme-toggle" class="pv-4 btn-group w-100-percent dropdown-item no-hover">
			<button class="btn btn-secondary btn-sm enable-default-theme"
				[ngClass]="{'btn-selected': !isDarkThemeSelected()}"
				[attr.aria-label]="('dashboard.previewColorTheme'|i18n) + ' ' + ('common.default' | i18n)"
				[disabled]="disabled"
				(click)="setDefaultTheme($event)">
				{{'common.default'|i18n}}
			</button>
			<button class="btn btn-secondary btn-sm enable-dark-theme"
				[ngClass]="{'btn-selected': isDarkThemeSelected()}"
				[attr.aria-label]="('dashboard.previewColorTheme'|i18n) + ' ' + ('common.dark' | i18n)"
				[disabled]="disabled"
				(click)="setDarkTheme($event)">
				{{'common.dark'|i18n}}
			</button>
		</div>`
})

export class DashboardDarkMenuToggleComponent implements OnInit {

	@Input() disabled: boolean;
	private dashboardTheme: ApplicationTheme;

	constructor(
		private ref: ChangeDetectorRef,
		private applicationThemeService: ApplicationThemeService
		) {}

	ngOnInit(): void {
		this.dashboardTheme = this.applicationThemeService.getAppliedDashboardTheme();
	}

	isDarkThemeSelected = (): boolean => {
		return this.dashboardTheme === ApplicationTheme.DARK;
	}

	setDarkTheme = (event: any): void => {
		event.stopPropagation();
		this.setDashboardTheme(ApplicationTheme.DARK);
	}

	setDefaultTheme = (event: any): void => {
		event.stopPropagation();
		this.setDashboardTheme(ApplicationTheme.DEFAULT);
	}

	private setDashboardTheme = (theme: ApplicationTheme): void => {
		this.dashboardTheme = theme;
		this.applicationThemeService.setDashboardTheme(theme);
		this.ref.detectChanges();
	}


}
