import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountPredictiveStatus } from './master-account-predictive-status';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { PredictiveStatusApi } from '@app/modules/system-administration/status/integration-status/predictive/predictive-status-api.service';
import { PredictiveStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/predictive/predictive-status-table-columns.service';

@Injectable({providedIn: 'root'})
export class PredictiveStatusService implements IntegrationStatusService<MasterAccountPredictiveStatus> {
	constructor(
		private predictiveStatusApiService: PredictiveStatusApi,
		private predictiveStatusTableColumnsService: PredictiveStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.predictiveIntegration';
	}

	getColumns(): TableColumn<MasterAccountPredictiveStatus>[] {
		return this.predictiveStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountPredictiveStatus[]> {
		return this.predictiveStatusApiService.getPredictiveStatuses();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.predictiveStatusApiService.export(search);
	}
}
