/* global app: true */

import { AppLoadingService } from '@cxstudio/app-loading';

window.app = angular.module('brooklyn.dashboard', [
	'templates', // cached html templates
	'ngRoute',
	'ngCookies', // to store language, token, etc
	'checklist-model',
	'ngDragDrop',
	'gridster',
	'ui.bootstrap.showErrors',
	'angularFileUpload',
	'ui.bootstrap', // modals, bootstrap elements, etc
	'cgBusy',
	'ngclipboard',
	'ngSanitize',
	'ui.select',
	'ngTagsInput',
	'FBAngular', // fullscreen
	'mentio',
	'ngDraggable'
]);

app.run((appLoading: AppLoadingService, $rootScope) => {
	appLoading.enterApplication();

	// tslint:disable-next-line:typedef
	$rootScope.safeApply = function(fn) {
		// tslint:disable-next-line:no-invalid-this
		let phase = this.$root.$$phase;
		if (phase === '$apply' || phase === '$digest') {
			if (fn && (typeof (fn) === 'function')) {
				fn();
			}
		} else {
			// tslint:disable-next-line:no-invalid-this
			this.$apply(fn);
		}
	};
});

