import * as _ from 'underscore';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { IMetricFolder } from '@cxstudio/metrics/entities/metric-folder';
import { BaseFolderApi } from '@cxstudio/common/folders/base-folder-api.service';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';


export class MetricFolderApi extends BaseFolderApi<IMetricFolder> {
	constructor(
		$http: ng.IHttpService,
		treeService: TreeService,
		cachedHttpService: CachedHttpService,
	) {
		super('rest/metric', $http, treeService, cachedHttpService.cache(Caches.METRICS));
	}
}

app.service('metricFolderApi', MetricFolderApi);
