<sidebar-section *ngIf="showSelectionSettings()" [header]="'widgetSettings.selection'|i18n">
	<grouping-selection-settings *ngIf="isAssetsLoaded()"
		class="d-flex flex-wrap"
		[ngBusy]="loading"
		[(sortOrder)]="grouping.sortOrder"
		[(size)]="grouping.size"
		[sentenceLevel]="sentenceLevel"
		[(minDocCount)]="grouping.minDocCount"
		[(sortBy)]="grouping.sortBy"
		[sortByOptions]="calculations"
	></grouping-selection-settings>
	<alert *ngIf="filterRemovalWarning"
		class="mt-8"
		type="warning"
		[text]="'selectorWidget.filterRemovalWarningText'|i18n"
		[dismissable]="false">
	</alert>
</sidebar-section>
<sidebar-section [header]="'widgetSettings.inclusionExclusion' | i18n">
	<peer-report-settings *ngIf="peerHierarchyGrouping"
		class="mb-16 d-flex"
		[(ngModel)]="grouping.peerReportType">
	</peer-report-settings>
	<time-grouping-settings *ngIf="isTimeGrouping()"
		class="mb-16 d-flex"
		[(ngModel)]="grouping.emptyPeriodType">
	</time-grouping-settings>
	<topic-inclusion-settings *ngIf="isTopicGrouping()"
		class="d-flex mb-8"
		[project]="project"
		[modelId]="getModelId()"
		[(selectedNodes)]="grouping.selectedNodes"
		[(inheritTopics)]="grouping.inheritTopics"
		[(selectedLevel)]="grouping.selectedLevel"
		(selectedLevelChange)="onLevelChanged()"
		[(selectedLevels)]="grouping.selectedLevels"
		[geography]="geography"
		[customMultiselect]="customMultiselect"
		[showLevel]="grouping.showLevel"
		[(subtopicsOnly)]="grouping.subtopicsOnly"
		[(updateOnParentFilter)]="grouping.updateOnParentFilter">
	</topic-inclusion-settings>

	<hierarchy-model-settings *ngIf="showHierarchyInclusion()"
		[hierarchyId]="getHierarchyId()"
		[peerReportType]="grouping.peerReportType"
		[(selectedLevel)]="grouping.selectedLevel"
		[(checkedInclusionNodes)]="grouping.checkedInclusionNodes">
	</hierarchy-model-settings>

	<null-values-settings *ngIf="showNullIncludeSettings()"
		class="mb-16 d-flex"
		[(ngModel)]="grouping.nullInclude">
	</null-values-settings>

	<bar-stack-settings *ngIf="stackedGroup && widgetType === WidgetType.BAR"
		class="mb-16 d-flex"
		[(ngModel)]="grouping.stackType">
	</bar-stack-settings>

	<line-stack-settings *ngIf="stackedGroup && widgetType === WidgetType.LINE"
		class="mb-16 d-flex"
		[(ngModel)]="grouping.stackType">
	</line-stack-settings>

	<attribute-inclusion-settings *ngIf="showAttributeInclusion()"
		class="mb-16 d-flex"
		[(inheritAttributeValues)]="grouping.inheritAttributeValues"
		[project]="project"
		[grouping]="grouping"
		[includeOnly]="grouping.sortOrder === 'custom'"
		[(inheritAttributeValues)]="grouping.inheritAttributeValues"
		[(wordsFilteringMode)]="grouping.wordsFilteringMode"
		[(wordsList)]="grouping.wordsList">
	</attribute-inclusion-settings>

	<threshold-configuration *ngIf="showThresholds() && isAssetsLoaded()"
		[ngBusy]="loading"
		[project]="project"
		[item]="grouping"
		[allMetrics]="calculations"
		[useHistoricPeriod]="useHistoricPeriod"
		[selectedMetrics]="selectedMetrics"
		(validityChange)="validityChange.emit($event)">
	</threshold-configuration>
</sidebar-section>
<sidebar-section [header]="'widgetSettings.formatting'|i18n">
	<url-type *ngIf="showUrlType()"
		class="mb-16"
		[attribute]="grouping">
	</url-type>

	<capitalization-settings *ngIf="showCapitalization()"
		class="mb-16"
		[(ngModel)]="grouping.capitalization">
	</capitalization-settings>
</sidebar-section>
