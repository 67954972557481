import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'qualtrics-auth-button',
	styles: [`
		.message-panel { width: 420px; }
	`],
	template: `
	<div class="d-flex flex-direction-column align-center text-center bg-white ph-48 pv-24" *ngIf="message">
		<div class="message-panel mb-16">
			{{message}}
		</div>
		<button class="btn btn-primary w-50-percent" (click)="signIn()">{{ 'login.signIntoXmEngage' | i18n }}</button>
	</div>
	<ng-container *ngIf="!message">
		<button class="btn btn-primary" (click)="signIn()">{{ 'login.signIntoXmEngage' | i18n }}</button>
	</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsAuthButtonComponent implements OnInit {
	@Input() message: string;
	@Output() reload = new EventEmitter<void>();

	constructor(
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('security') private security: Security,
	) { }

	ngOnInit(): void {
	}

	signIn(): void {
		let masterAccountId = this.security.getCurrentMasterAccount().accountId;
		let loginUrl = this.urlService.getAPIUrl(`rest/security/oauth/qualtrics-api/master-account/${masterAccountId}`);

		this.environmentService.openPopupLogin(loginUrl, this.environmentService.LOGIN_POPUP_NAME, () => this.reload.emit());
	}
}
