<div class="mobile-workspace-selector d-flex flex-wrap flex-direction-column">
	<div class="d-flex flex-wrap flex-direction-row">
		<div *ngIf="!workspace.hasSingleOption()" class="col-sm-6 pl-0 form-group d-flex flex-direction-column">
			<label class="control-label font-bold help-label">{{'common.workspace'|i18n}}
				<cb-inline-help right>
					<help-body>{{'dashboard.providerHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<simple-dropdown
				[options]="workspace.getOptions()"
				[value]="workspace.getSelection()"
				(valueChange)="workspace.changeSelection($event)"
				displayField="name"
				valueField="id"
			></simple-dropdown>
			<div *ngIf="errors.has('select-workspace')"
				class="col-sm-6 error-message"
				[i18n]="'mobile.errorWorkspaceRequired'">
			</div>
		</div>
		<div class="col-sm-6 form-group d-flex flex-direction-column"
			[ngClass]="workspace.hasSingleOption() ? 'pl-0' : 'pr-0'">
			<label class="control-label font-bold help-label">{{'common.project'|i18n}}
				<cb-inline-help right>
					<help-body>{{'dashboard.projectHelp'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<simple-dropdown
				[options]="project.getOptions()"
				[value]="project.getSelection()"
				(valueChange)="project.changeSelection($event)"
				displayField="name"
				valueField="id"
				[disableSort]="true"
				[disabled]="!workspace.isSelected()"
			></simple-dropdown>
			<div *ngIf="errors.has('select-project')"
				class="col-sm-6 error-message"
				[i18n]="'mobile.errorProjectRequired'">
			</div>
		</div>
		<div *ngIf="project.isSelected()"
			class="col-sm-6 form-group d-flex flex-direction-column"
			[ngClass]="workspace.hasSingleOption() ? 'pr-0' : 'pl-0'">
			<label class="control-label font-bold help-label">{{'mobile.organizationHierarchy'|i18n}}
				<cb-inline-help right>
					<help-body>{{'mobile.inlineHelpHierarchy'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<simple-dropdown
				[options]="hierarchy.getOptions()"
				[value]="hierarchy.getSelection()"
				(valueChange)="hierarchy.changeSelection($event)"
				displayField="name"
				valueField="id"
				[disableSort]="true"
				[allowClear]="true"
				[preselectedValue]="preselectedHierarchy"
				selectPrompt="{{'mobile.noHierarchy'|i18n}}"
			></simple-dropdown>
			<div *ngIf="errors.has('inactive-hierarchy-error')"
				class="col-sm-12 error-message"
				[i18n]="'common.inactiveHierarchyWarning'">
			</div>
		</div>
	</div>
</div>
