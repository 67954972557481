import { NgModule } from '@angular/core';
import { AgeService } from './age.service';
import { DatePeriodConstantsService } from './date-period-constants.service';
import { DateUtilsService } from '@app/modules/utils/dates/date-utils.service';


@NgModule({
	providers: [
		AgeService,
		DatePeriodConstantsService,
		DateUtilsService
	]
})

export class DateUtilsModule {}
