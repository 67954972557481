import * as _ from 'underscore';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Caches } from '@cxstudio/common/caches';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { FilterRuleType } from '../constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { DateRangeConstantService } from '@cxstudio/report-filters/constants/date-range-constant.service';

export interface PresetDateFiltersState {
	[id: number]: boolean;
}

export class FilterManagementApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private cachedHttpService: CachedHttpService,
		private betaFeaturesService: BetaFeaturesService,
		private DateRange: DateRangeConstantService,
	) { }

	getFilterFolders = (contentProviderId: number, accountId: number) =>
		this.cachedHttpService.cache(Caches.FILTERS)
			.get(`rest/filter/folders/cp/${contentProviderId}/account/${accountId}`, {
				local: null,
				cache: true
			})

	getFilter = (filterId: number): ng.IPromise<IFilter> =>
		this.$http.get(`rest/filter/${filterId}`).then(this.httpHandlers.success)

	deleteFilters = (filters): ng.IPromise<any> => {
		let queryParam = {
			filterId: _.map(filters, (filter: any) => filter.id)
		};
		return this.$http.delete('rest/filter/delete/studioFilter', { params: queryParam })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	duplicateFilter = (filterData: {name: string, id: number}) => {
		let newFilterName = {name: filterData.name};
		return this.$http.post(`rest/filter/duplicate/studioFilter/${filterData.id}`, angular.toJson(newFilterName))
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	createFilter = (filterData) => this.$http.post('rest/filter/create/studioFilter', angular.toJson(filterData))
		.then(this.httpHandlers.success)
		.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS))

	renameFilter = (filterList, filterData) => {
		let updatedFilter = angular.copy(filterData);

		if (updatedFilter.parent)
			delete updatedFilter.parent;

		return this.$http.put(`rest/filter/update/studioFilter/${updatedFilter.id}`, angular.toJson(updatedFilter)).then(() => {
			let oldFilter = _.findIndex(filterList, {id: updatedFilter.id});

			if (oldFilter >= 0)
				filterList[oldFilter] = filterData;
		}).then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	updateFilter = (filterId: number, filterData, retainEditPermission: boolean) => {
		let updatedFilter = angular.copy(filterData);

		if (updatedFilter.parent)
			delete updatedFilter.parent;

		return this.$http.put(`rest/filter/update/studioFilter/withRules/${filterId}`, angular.toJson(updatedFilter),
			{params: {retainEditPermission}})
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	updateLabels = (updateLabelsEntity: BulkUpdateLabelsEntity) => {
		return this.$http.put(`rest/filter/labels`, angular.toJson(updateLabelsEntity))
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	updatePresetDateFilterState = (filterId: string) => {
		return this.$http.put(`rest/filter/toggle/presetDateFilters/${filterId}`, {})
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	getPresetDateFiltersStates = (): ng.IPromise<PresetDateFiltersState> => {
		return this.cachedHttpService.cache(Caches.FILTERS).get('rest/filter/presetDateFilters', {
			cache: true
		}).then(this.httpHandlers.success)
		.then((states: PresetDateFiltersState) => {
			if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.PREVIOUS_WEEK_FILTERS)) {
				// hide these options if beta is off
				states[this.DateRange.options.PREVIOUS_4_WEEKS.id] = false;
				states[this.DateRange.options.PREVIOUS_12_WEEKS.id] = false;
			}
			return states;
		});
	}

	// sharing
	shareFilters = (filters, shareData) => {
		let queryParam = {
			filterId: _.map(filters, (filter: any) => filter.id)
		};

		return this.$http.post('rest/filter/users', angular.toJson(shareData), { params: queryParam })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.FILTERS));
	}

	verifyFiltersAccess = (filterIds: number[]): ng.IPromise<any> => {
		let queryParam = {
			filterId: filterIds
		};

		return this.$http.get('rest/filter/verify-access', { params: queryParam })
			.then(this.httpHandlers.success);
	}

	getSharedUsersAndGroups = (filterId: number) =>
		this.$http.get(`rest/filter/users/${filterId}`).then(this.httpHandlers.success)

	updateFilterReshareFlag = (filterId, value) => this.$http.put(`rest/filter/update/studioFilter/${filterId}/allow_reshare/${value}`, {});

	convertModelTreeSelectionRulesForBackend = (filterRules: IFilterRule[]): void => {
		if (!filterRules?.length) return;

		filterRules.forEach((filterRule) => {
			if (filterRule.type === FilterRuleType.topicEquality) {
				// add the text property back to value objects to support backend format
				filterRule.values.forEach((model: any) => {
					model.text = model.path;
				});
			}
		});
	}
}


app.service('filterManagementApiService', FilterManagementApiService);
