import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { PageTitleUtil } from '@app/core/page-title-util.class';
import { Caches } from '@cxstudio/common/caches';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';

@Component({
	selector: 'home-page-header',
	templateUrl: './home-page-header.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class HomePageHeaderComponent extends SelfCleaningComponent implements OnInit, OnDestroy {
	@Input() searchValue: AttributeValueOption;
	@Output() searchValueChange = new EventEmitter<AttributeValueOption>();
	@Output() nodeChange = new EventEmitter<IHierarchyNode>();

	homePage: HomePage;
	quickInsightsEnabled: boolean;
	hasPersonalization: boolean;
	private cachedHttp: CxCachedHttp;

	constructor(
		private userHomePageService: UserHomePageService,
		private homePageApi: HomePageApiService,
		private readonly locale: CxLocaleService,
		private ref: ChangeDetectorRef,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		super();
		this.cachedHttp = this.cxCachedHttpService.cache(Caches.ORGANIZATIONS);
	}

	ngOnInit(): void {
		this.addSubscription(this.userHomePageService.getHomePage().subscribe(homePage => {
			this.homePage = homePage;
			this.hasPersonalization = this.userHomePageService.hasPersonalization(homePage);
			this.quickInsightsEnabled = homePage.quickInsights.enabled;
			this.ref.markForCheck();
			this.homePageApi.logViewEvent(this.homePage.id);
			this.updatePageTitle();
		}));
	}

	ngOnDestroy(): void {
		this.cachedHttp.invalidate();
	}

	private updatePageTitle(): void {
		PageTitleUtil.setTitle(this.locale.getString('pageTitle.object', {
			objectName: this.userHomePageService.getHomePageName() || this.locale.getString('homePage.defaultName')
		}));
	}

	onSearch = (search: AttributeValueOption): void => {
		this.searchValue = search;
		this.searchValueChange.emit(this.searchValue);
	}

	onNodeChange = (node: IHierarchyNode): void => {
		this.nodeChange.emit(node);
	}
}
