import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { DashboardFiltersService } from './dashboard-filters/dashboard-filters-service';
import { DashboardFilterTypes } from './dashboard-filters/dashboard-filter-types-constant';
import { FormattersUtils } from '@cxstudio/reports/utils/formatters-utils.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { AttributesService, IAttributeValuesParams, ISearchValue } from '@app/modules/project/attribute/attributes.service';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { EnrichmentAttributeSettings } from '@cxstudio/reports/document-explorer/doc-explorer-attribute-settings.interface';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { PromiseUtils } from '@app/util/promise-utils';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';

export enum FilterValuesSearchType {
	SAVED_FILTER = 'SAVED_FILTER',
	ATTRIBUTE = 'ATTRIBUTE'
}

export interface SearchTermsResult {
	type: FilterValuesSearchType;
	data: AttributeValueOption[];
	folders?: any[];
}

export interface SearchTermsOptions {
	project: AccountOrWorkspaceProject;
	attributes: SearchedAttribute[];
	isNumeric: boolean;
	userEmail?: string;
	filter: string;

	start?: number;
	limit?: number;
	cacheOption?: AnalyticCacheOptions;
}

export interface SearchedAttribute {
	name: string;
	displayName: string;
	filterType: DashboardFilterTypes;
	type?: FilterAttributeTypes;
	settings?: EnrichmentAttributeSettings;
}

interface AttributeValueFormatter {
	name: (value: any) => string;
	displayName: (value: any) => string;
	attributeName: string;
	attributeDisplayName: string;
}

export class AttributeValueSearcher {

	constructor(
		private dashboardFiltersService: DashboardFiltersService,
		private formattersUtils: FormattersUtils,
		private attributesService: AttributesService,
		private locale: ILocale,
		private enrichmentAttributesService: EnrichmentAttributesService,
	) {
	}

	searchTerms = (options: SearchTermsOptions): ng.IPromise<SearchTermsResult> => {
		let params: IAttributeValuesParams = {
			project: options.project,
			attributeNames: _.pluck(options.attributes, 'name'),
			isNumeric: options.isNumeric,
			runAs: options.userEmail,
			filter: options.filter,
			start: options.start,
			limit: options.limit,
			cacheOption: options.cacheOption
		};

		return PromiseUtils.old(this.attributesService.getMultiAttributeValues(params)).then(items => {
			return {
				type: FilterValuesSearchType.ATTRIBUTE,
				data: this.getSearchedAttributeValues(options.attributes, items)
			};
		});
	}

	getSearchedFilters = (items: any[]): AttributeValueOption[] => {
		if (!items || !items.length) {
			return [];
		}
		let nameFormatter = item => {
			let delimiter = '-';
			if (item.type === FilterTypes.PREDEFINED) {
				return item.subtype + delimiter + item.value;
			} else if (item.type === FilterTypes.CXSCORECARD) {
				return item.type + delimiter + item.uniqueId;
			} else {
				return item.type + delimiter + item.id;
			}

		};

		return items.map(item => {
			let menuItem = {
				name: nameFormatter(item),
				displayName: item.name,
				object: item
			} as AttributeValueOption;
			if (item.css)
				menuItem.css = item.css;
			return menuItem;
		});
	}

	private getSearchedAttributeValues(attributes: SearchedAttribute[], items: ISearchValue[]): AttributeValueOption[] {
		if (!items || !items.length) {
			return [];
		}
		let multiAttribute = attributes.length > 1;

		let formatters: {[attrName: string]: AttributeValueFormatter} = {};
		_.each(attributes, attribute => {
			formatters[attribute.name.toLowerCase()] = this.getAttributeFormatters(attribute, multiAttribute);
		});

		let itemFormatter = (item: ISearchValue): AttributeValueFormatter => {
			if (!multiAttribute)
				return _.values(formatters)[0];
			else return formatters[item.attributeName.toLowerCase()];
		};

		return items.map(item => {
			let formatter = itemFormatter(item);
			let menuItem = {
				name: formatter.name(item),
				displayName: formatter.displayName(item)
			} as AttributeValueOption;
			if (formatter.attributeName) {
				menuItem.attributeName = formatter.attributeName;
				menuItem.attributeDisplayName = formatter.attributeDisplayName;
			}
			if (item.css)
				menuItem.css = item.css;
			return menuItem;
		});
	}

	private getAttributeFormatters(attribute: SearchedAttribute, multiAttribute: boolean): AttributeValueFormatter {

		let nameFormatter = item => item.term;
		let displayNameFormatter = item => item.term;

		if (this.dashboardFiltersService.getFilterType(attribute) === DashboardFilterTypes.SATSCORE) {
			let satScoreFormatter = this.formattersUtils.buildSatScoreFormatter() as any;
			displayNameFormatter = item => satScoreFormatter(item.term);
		}

		if (ScorecardUtils.isScorecardAttribute(attribute.name) && attribute.settings && attribute.settings.numeric) {
			displayNameFormatter = item =>
					this.enrichmentAttributesService.formatAttributeValue(item.term, AttributeType.NUMBER, attribute.settings);
		}

		return {
			name: nameFormatter,
			displayName: displayNameFormatter,
			attributeName: attribute.name,
			attributeDisplayName: attribute.displayName,
		};
	}

	updateSelectedList = (allItems: AttributeValueOption[], currentlySelectedItems: AttributeValueOption[], savedFilter: boolean) => {
		let pushItems = [];
		let match: AttributeValueOption;

		for (let item of currentlySelectedItems) {
			if (item) {
				if (savedFilter) {
					match = _.find(allItems, {displayName: item.displayName});
					if (match && match.object && match.object.id !== item.object.id) {
						match = null;
					}
				} else if (item.existMatch) {
					match = _.find(allItems, {name: item.name});
				} else if (item.isAllValues) {
					match = _.find(allItems, {isAllValues: true});
				} else {
					match = _.find(allItems, {displayName: item.displayName, name: item.name});
				}

				// Try to find by name
				if (!match) {
					match = _.find(allItems, {name: item.name});
				}

				if (match) {
					match.selected = true;
				} else {
					pushItems.push(item);
				}
			}
		}

		return allItems.concat(pushItems);
	}

	getDefaultAttributeOptions = (): AttributeValueOption[] => {
		return [
			this.getAttributeValueExistOption(),
			this.getAttributeValueNotExistOption()
		];
	}

	private getAttributeValueExistOption = (): AttributeValueOption => {
		return {
			name: 'exist',
			displayName: this.locale.getString('reportFilters.exist'),
			existMatch: true,
			special: true,
			matchMode: FilterMatchModeValue.IS
		};
	}

	private getAttributeValueNotExistOption = (): AttributeValueOption => {
		return {
			name: 'notExist',
			displayName: this.locale.getString('reportFilters.notExist'),
			existMatch: true,
			special: true,
			matchMode: FilterMatchModeValue.IS_NOT
		};
	}

}

app.service('attributeValueSearcher', AttributeValueSearcher);
