
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportGrouping } from './report-grouping';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ColorFunction } from '@cxstudio/reports/utils/color-utils.service';
import { ColorPalettesHelper } from '@cxstudio/reports/coloring/color-palettes-helper';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { IReportModel } from '@app/modules/project/model/report-model';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { CustomDateFilter } from '@cxstudio/reports/entities/date-filter';
import { HighchartsCase } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case';
import { NumericFormats } from '@app/modules/project/attribute/attribute-settings.service';

export interface IWidgetUtilsBaseFields {
	containerId: string;
	widgetId: number;
	widgetType: WidgetType;
}

export interface IWidgetUtilsPreviewFields {
	projectTimezone: string;
	filteringFeedbackSelection: IAnalyticFeedbackSelection;
	tuningSuggestionModels: IReportModel[];
	auditMode: boolean;
	translationEnabled: boolean;
}

export interface IWidgetUtilsAssetFields {
	attributes: IReportAttribute[];
	metrics: Metric[];
}

export interface IWidgetUtilsGroupingFields {
	getGroupingFormatters: () => {[field: string]: (item) => string};
	getGroupingFormatter: (index: number) => ((item) => string);
	getGroupings: () => ReportGrouping[];
}
export interface IWidgetUtilsFormatterFields {
	getMetricNames: () => string[];
	dataFormatter: (value) => string;
	secondaryDataFormatter?: (value) => string;
	dataFormatters?: Array<(value) => string>;
	titleFormatter: (name: string) => string;
	periodFormatter: (name: DateFilterMode | CustomDateFilter) => string;
}

export interface IWidgetUtilsSizeFields {
	sizeFunction: (item: any) => number;
	secondarySizeFunction: (item: any) => number;
	getRawSize: (item: any) => number;
	getRawSecondarySize: (item: any) => number;
}

export interface IWidgetUtilsMetadataFields {
	periods: {period_1_: string, period_2_: string};
	selectedAttributes: ReportGrouping[];
}

export interface IWidgetUtilsTableFields {
	allColumns?: any[];
}

export interface IWidgetUtilsScorecardFields {
	scorecards?: Scorecard[];
	formats?: NumericFormats;
}

export interface IWidgetUtilsColorFields {
	colorFunction: ColorFunction;
	secondaryColorFunction: ColorFunction;
	pointColorFunction: ColorFunction;
	popColorFunction: ColorFunction;
	secondaryPointColorFunction: ColorFunction;
	secondaryPopColorFunction: ColorFunction;

	palettesHelper: ColorPalettesHelper;
	metricLegendFunctions: MetricLegendFunction[];
	legendFunctions: LegendFunctions;
	hasObjectColor: boolean;
}

export interface IWidgetUtilsCaseFields {
	engagorCases?: HighchartsCase[];
}

export type LegendFunctions = {[key in ColorTypes]?: (item) => string};
export interface MetricLegendFunction {
	name: string;
	values: MetricLegendValue[];
}

interface MetricLegendValue {
	name: {
		name: string,
		id: number;
		value: string;
	};
	color: string;
}

export default class WidgetUtils implements IWidgetUtilsBaseFields, IWidgetUtilsPreviewFields,
		IWidgetUtilsAssetFields, IWidgetUtilsGroupingFields, IWidgetUtilsFormatterFields,
		IWidgetUtilsSizeFields, IWidgetUtilsMetadataFields, IWidgetUtilsColorFields, IWidgetUtilsTableFields,
		IWidgetUtilsScorecardFields, IWidgetUtilsCaseFields {

	containerId: string;
	widgetId: number;
	widgetType: WidgetType;

	projectTimezone: string;

	attributes: IReportAttribute[];
	metrics: Metric[];

	periods: {period_1_: DateFilterMode, period_2_: DateFilterMode};
	filteringFeedbackSelection: IAnalyticFeedbackSelection;
	auditMode: boolean;
	tuningSuggestionModels: IReportModel[];

	translationEnabled: boolean;

	selectedAttributes: ReportGrouping[];

	getGroupingFormatters: () => {[field: string]: (item) => string};
	getGroupingFormatter: (index: number) => ((item) => string);
	getGroupings: () => ReportGrouping[];
	getMetricNames: () => string[];

	dataFormatter: (value) => string; // simple charts
	secondaryDataFormatter: (value) => string; // dual
	dataFormatters: Array<(value) => string>; // scatter

	titleFormatter: (name: string) => string;
	periodFormatter: (name: DateFilterMode | CustomDateFilter) => string;

	sizeFunction: (item: any) => number;
	secondarySizeFunction: (item: any) => number;
	getRawSize: (item: any) => number;
	getRawSecondarySize: (item: any) => number;

	colorFunction: ColorFunction;
	secondaryColorFunction: ColorFunction;
	pointColorFunction: ColorFunction;
	popColorFunction: ColorFunction;
	secondaryPointColorFunction: ColorFunction;
	secondaryPopColorFunction: ColorFunction;

	palettesHelper: ColorPalettesHelper;
	metricLegendFunctions: MetricLegendFunction[];
	legendFunctions: LegendFunctions;
	hasObjectColor: boolean;

	allColumns: any[];

	scorecards: Scorecard[];
	formats: NumericFormats;

	engagorCases?: HighchartsCase[];
}
