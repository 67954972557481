import Widget from '@cxstudio/dashboards/widgets/widget';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

export class FeedbackUtils {

	static isFeedbackWidget(widget: Widget): boolean {
		return widget?.properties?.widgetType === WidgetType.PREVIEW;
	}

	static isSentencesPaneEnabled(widget: Widget): boolean {
		return widget?.visualProperties?.sentencePaneEnabled;
	}

	static isDocumentMode(widget: Widget): boolean {
		return widget?.properties?.previewMode === PreviewMode.DOCUMENT;
	}

	static isSingleVerbatimMode(widget: Widget): boolean {
		return widget?.visualProperties?.preferences?.settings?.singleVerbatimMode;
	}
}
