import { Inject, Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { CommonDrillService } from '../common-drill.service';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
@Injectable({
	providedIn: 'root'
})
export class DrillToTableProcessorService extends DefaultDrillProcessor {



	constructor(
		private readonly commonDrill: CommonDrillService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
	) {
		super();

		this.addInheritedProperties(['primaryTimeGrouping', 'useHistoricPeriod']);
		this.addInheritedVisualProperties(['layout', 'showTotal', 'periodLabel'].concat(CommonInherentProperties.visual));
	}

	drillTo = (widget: Widget, drillToType: DrillType, point: DrillPoint) => {
		//replace name and visual
		widget.name = WidgetType.TABLE;
		widget.properties.widgetType = WidgetType.TABLE;
		widget.visualProperties.visualization = WidgetVisualization.CB_AN_TABLE;
		widget.displayName = `${widget.displayName} - ${point.groupBy.displayName}`;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy));
		//add selectedAttributes
		widget.properties.selectedAttributes = [point.groupBy];

		this.commonDrill.processHierarchyMetrics(widget);

		this.initialDefaultProperties(widget);

		widget = this.inheritPropertiesAndVisProps(widget);

		this.filterMetrics(widget, drillToType);

		return widget;
	}

	private initialDefaultProperties(widget: Widget): void {
		if (!widget.visualProperties.layout) {
			widget.visualProperties.layout = 'compact';
		}
		let groupBy = widget.properties.selectedAttributes[0];
		if (groupBy.metricType === AnalyticMetricType.TIME) {
			widget.properties.primaryTimeGrouping = widget.properties.selectedAttributes[0];
			groupBy.sortBy = 'time';
			groupBy.sortOrder = GroupingSortOrder.DESC;
		} else {
			delete widget.properties.primaryTimeGrouping;
		}
	}

	private filterMetrics(widget: Widget, drillToType: DrillType): void {
		let metrics = widget.properties.selectedMetrics;
		metrics = _.filter(metrics, (metric) => {
			return !(metric.name === StandardMetricName.ALPHANUMERIC
				&& metric.metricType === AnalyticMetricType.STANDARD)
				&& metric.name !== StandardMetricName.STRENGTH
				&& metric.name !== StandardMetricName.CONSTANT_SIZE;
		});
		let constants = this.metricConstants.get();
		if (metrics.length === 0) {
			metrics = [ constants.VOLUME ];
		}
		if (drillToType === DrillType.KEY_TERMS) {
			metrics.unshift(constants.PERCENT_DELTA);
		}

		widget.properties.selectedMetrics = metrics;
	}
}
