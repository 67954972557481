import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';


export class ScorecardFormatterUtils {
	static readonly DEFAULT_PRECISION = Decimals.THREE;

	static normalizeThreshold(threshold: number): number {
		if (_.isUndefined(threshold)) return threshold;
		let multiplier = this.getMultiplier(this.DEFAULT_PRECISION);
		return Math.round(threshold * multiplier) / multiplier;
	}

	static isThresholdPrecisionValid(threshold: number): boolean {
		if (_.isUndefined(threshold)) return false;
		return this.getThresholdPrecision(threshold) <= this.DEFAULT_PRECISION;
	}

	static getThresholdPrecision(threshold: number): number {
		if (!_.isUndefined(threshold) && !_.isNull(threshold)) {
			let parts = threshold.toString().split('.');
			if (parts[1]) {
				return parts[1].length;
			}
		}

		return Decimals.NONE;
	}

	private static getMultiplier(precision: number): number {
		return Math.pow(10, precision);
	}
}