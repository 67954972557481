<div #mainWidget class="d-flex h-100-percent w-100-percent css-metric-widget justify-center border-radius-widget-default">
	<div *ngIf="!widgetError && renderPrepared && data && utils" class="d-flex h-100-percent flex-shrink metric-wrapper croppable kb-focusable" [ngClass]="getCustomClasses()"
		tabindex="0"
		[attr.aria-label]="getAriaLabel()">
		<article class="d-flex flex-shrink text-center croppable" aria-hidden="true">
			<section
				class="metric current-value d-flex align-items-center justify-center flex-direction-column croppable"
				attr.aria-label="{{labels.currentPeriodLabel}}: {{getCurrentValue()}}">
				<p class="mb-8 br-current-period-label" title="{{labels.currentPeriodLabel}}">{{labels.currentPeriodLabel}}</p>
				<h1 class="font-bold mb-0 nowrap croppable _t-current-value" title="{{getCurrentValue()}}">
					<metric-value [metricValue]="getCurrentValueParts()"></metric-value>
				</h1>

				<h2
					*ngIf="widget.visualProperties.previousPeriod"
					class="delta mb-0 font-bold nowrap croppable _t-difference"
					[ngClass]="getDirectionalColorClass(data.currentValue, data.lastValue)"
					title="{{getDiffSymbol(data.currentValue, data.lastValue)}} {{getDiff()}}">
					{{getDiffSymbol(data.currentValue, data.lastValue)}} <span class="_t-difference-value">{{getDiff()}}</span>
				</h2>
			</section>

			<section
				*ngIf="widget.visualProperties.previousPeriod"
				attr.aria-label="{{labels.previousPeriodLabel}}: {{getLastValue()}}"
				class="metric historic-value d-flex align-items-center justify-center flex-direction-column croppable">
				<p class="mb-8 br-previous-period-label" title="{{labels.previousPeriodLabel}}">{{labels.previousPeriodLabel}}</p>
				<h2 class="mb-0 nowrap croppable _t-last-value" title="{{getLastValue()}}">
					<metric-value [metricValue]="getLastValueParts()"></metric-value>
				</h2>
			</section>
		</article>

		<div class="svg-wrapper flex-shrink" *ngIf="widget.visualProperties.trendArrow && !isHorizontallyOriented" aria-hidden="true">
			<svg
				class="single-metric-chart h-100-percent"
				tabindex="0"
				viewBox="0 -30 100 260"
				[attr.aria-label]="getLabel()">
				<g class="step-chart">
					<g attr.transform="translate(0,{{i * 40}})" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
						<text text-anchor="start" x="0" y="0" dx="0" dy="-5" class="metric-change-labels">
							{{formatValue(data.max - (i * ((data.max - data.min) / 5)))}}
						</text>
						<line x1="0" y1="0" attr.x2="{{getChangeGraphWidth()}}" y2="0" class="metric-change-line"></line>
					</g>
					<g>
						<path class="change-graph-arrow" attr.d="{{getPath()}}"
							[ngClass]="getDirectionalColorClass(data.currentValue,data.lastValue)"></path>
					</g>
				</g>
			</svg>
		</div>
	</div>

	<widget-error *ngIf="widgetError" [widgetError]="widgetError" [demo]="demo"></widget-error>
</div>
