import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	TopicConversionsComponent
} from '@app/modules/account-administration/topic-conversions/topic-conversions.component';
import { SharedModule } from '@app/shared/shared.module';
import { TopicTranslationComponent } from './topic-translation/topic-translation.component';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { TopicTranslationConfirmationDialogComponent } from './topic-translation/topic-translation-confirmation-dialog/topic-translation-confirmation-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import {
	TopicTranslationService
} from '@app/modules/account-administration/topic-conversions/topic-translation/topic-translation.service';
import { IconModule } from '@app/modules/icon/icon.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		CxFormModule,
		DialogModule,
		IconModule
	],
	declarations: [
		TopicConversionsComponent,
		TopicTranslationComponent,
		TopicTranslationConfirmationDialogComponent
	],
	providers: [
		TopicTranslationService
	]
})
export class TopicConversionsModule { }
