import { Component, ChangeDetectionStrategy, Input, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import { CxLocaleService } from '@app/core';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { HierarchyEnrichmentProperty, OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { FormattedTableColumnsService } from '@app/shared/components/table/formatted-table-columns.service';
import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';

export interface OrganizationEnrichmentWizardUploadDialogInput {
	hierarchy: Hierarchy;
	update: boolean;
}

@Component({
	selector: 'organization-enrichment-wizard-upload-modal',
	templateUrl: './organization-enrichment-wizard-upload-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationEnrichmentWizardUploadModalComponent implements OnInit {
	public readonly MediaType = MediaType;
	@Input() input: OrganizationEnrichmentWizardUploadDialogInput;

	public successfulUpload = undefined;

	validationErrors: DetailedValidationError[];
	errorTableColumns: TableColumn<DetailedValidationError>[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly organizationApiService: OrganizationApiService,
		private readonly formattedTableColumnsService: FormattedTableColumnsService,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService
	) {}

	ngOnInit(): void {
		this.errorTableColumns = this.formattedTableColumnsService.getValidateErrorColumns();
	}

	public getFileUploadUrl(): string {
		return this.organizationApiService.getEnrichmentUploadUrl(this.input.hierarchy.id);
	}

	public handleSuccessfulUpload(_response: boolean): void {
		this.successfulUpload = {$value: true};
	}

	public handleRemovedUpload(): void {
		this.successfulUpload = undefined;
	}

	getTitle = (): string => {
		return this.locale.getString('organization.enrichmentWizardTitle', {
			hierarchyName: this.input.hierarchy.name
		});
	}

	isEditable = (): boolean => {
		return !!this.input.hierarchy.editable;
	}

	downloadDataFile = (): void => {
		this.organizationApiService.getOrganizationEnrichment(this.input.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	}

	editReportableParameters = (): void => {
		this.organizationApiService.getOrganizationCalculations(this.input.hierarchy.id, { numericOnly: true })
			.then((parameters: HierarchyEnrichmentProperty[]) => this.showAttributesModal(parameters));
	}

	downloadTemplateFile = (): void => {
		this.organizationApiService.getOrganizationEnrichmentTemplate(this.input.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	}

	updateErrorTable = (errors: DetailedValidationError[]): void => {
		this.validationErrors = errors;
		this.ref.markForCheck();
	}

	private showAttributesModal = (parameters: HierarchyEnrichmentProperty[]): void => {
		let paramsCopy: HierarchyEnrichmentProperty[] = [...parameters];
		paramsCopy = this.alterParametersFields(paramsCopy, 'propertyName', 'displayName');

		const provider: any = {
			attributes: this.alterParametersFields(paramsCopy, 'reportable', 'selected')
		};
		provider.customModalTitle = this.locale.getString('administration.editReportableParameters');

		this.cbDialogService.showAttributesModal(provider)
			.then((selectedAttributes) => {
				this.organizationApiService.setOrganizationReportableParameters(
					this.input.hierarchy.id,
					this.adjustSelectability(parameters, selectedAttributes)
				).then(() => {});
			})
			.catch(() => {});
	}

	private alterParametersFields = (
		parameters: HierarchyEnrichmentProperty[],
		source: string,
		destination: string
	): HierarchyEnrichmentProperty[] => {
		parameters.forEach((parameter: HierarchyEnrichmentProperty) => parameter[destination] = parameter[source]);
		return parameters;
	}

	private adjustSelectability = (
		existingList: HierarchyEnrichmentProperty[],
		selectedList: HierarchyEnrichmentProperty[]
	): HierarchyEnrichmentProperty[] => {
		existingList.forEach((param: HierarchyEnrichmentProperty) => {
			const foundProperty: HierarchyEnrichmentProperty = selectedList.find(
				(property: HierarchyEnrichmentProperty) => property.propertyName === param.propertyName
			);

			param.reportable = !!foundProperty;
		});
		return existingList;
	}
}
