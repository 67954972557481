import { MetricConstantValues } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';

export interface WidgetConfiguration {
	id: number;
	title?: string;
	description?: string;
	posX: number;
	posY: number;
	width: number;
	height: number;
	imageName?: string;
	linkedWidgets?: number[];
	grouping?: string;
}

export class DataOverviewDashboardConfig {
	static DataOverview: { [name: string]: WidgetConfiguration } = {
		TITLE: {
			id: 1000,
			posX: 0,
			posY: 0,
			width: 20,
			height: 5,
			title: 'packages.dataOverviewDashboardTitle',
			description: 'packages.dataOverviewDashboardDescription'
		},

		LOGO: {
			id: 1001,
			posX: 20, 
			posY: 0, 
			width: 4, 
			height: 5,
			title: '',
			imageName: 'QualtricsXM-Logo-padded.png'
		},

		SOURCE: {
			id: 1002,
			posX: 0,
			posY: 5,
			width: 5,
			height: 13,
			title: 'packages.dataOverviewSourceTitle'
		},

		TREND: {
			id: 1003,
			posX: 5,
			posY: 5,
			width: 19,
			height: 13,
			title: 'packages.dataOverviewTrendTitle'
		}
	};

	static KeyMetrics = {
		SELECTOR: {
			id: 2000,
			posX: 0,
			posY: 18,
			width: 24,
			height: 4,
			title: 'packages.dataOverviewSelectSource',
			linkedWidgets: [ 2001 ]
		},

		METRIC: {
			id: 2001,
			posX: 0,
			posY: 22,
			width: 24,
			height: 12,
			title: 'packages.dataOverviewKeyMetrics',
		}
	};

	static DataSegments = {
		TITLE: {
			id: 3000,
			posX: 0, 
			posY: 34, 
			width: 24, 
			height: 4, 
			title: 'packages.dataOverviewSegmentsTitle'
		},

		SELECTOR: {
			id: 3001,
			posX: 0,
			posY: 38,
			width: 24,
			height: 4,
			title: 'packages.dataOverviewSelectSource',
			linkedWidgets: [4001, 5001, 6001, 7001, 8001, 9001, 10001, 11001]
		},

		SEGMENTS: [{
			TITLE: {
				id: 4000,
				posX: 0,
				posY: 42,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewEmotionTitle'
			},

			BAR: {
				id: 4001,
				posX: 0,
				posY: 46,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_EMOTION.name
			}
		}, {
			TITLE: {
				id: 5000,
				posX: 6,
				posY: 42,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewProductTitle'
			},

			BAR: {
				id: 5001,
				posX: 6,
				posY: 46,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_PRODUCT.name
			}
		}, {
			TITLE: {
				id: 6000,
				posX: 12,
				posY: 42,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewSentenceTypeTitle'
			},

			BAR: {
				id: 6001,
				posX: 12,
				posY: 46,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_SENTENCE_TYPE.name
			}
		}, {
			TITLE: {
				id: 7000,
				posX: 18,
				posY: 42,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewLanguageTitle'
			},

			BAR: {
				id: 7001,
				posX: 18,
				posY: 46,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.DETECTED_LANGUAGE.name
			}
		}, {
			TITLE: {
				id: 8000,
				posX: 0,
				posY: 58,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewBrandTitle'
			},

			BAR: {
				id: 8001,
				posX: 0,
				posY: 62,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_BRAND.name
			}
		}, {
			TITLE: {
				id: 9000,
				posX: 6,
				posY: 58,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewIndustryTitle'
			},

			BAR: {
				id: 9001,
				posX: 6,
				posY: 62,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_INDUSTRY.name
			}
		}, {
			TITLE: {
				id: 10000,
				posX: 12,
				posY: 58,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewPersonTitle'
			},

			BAR: {
				id: 10001,
				posX: 12,
				posY: 62,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_PERSON.name
			}
		}, {
			TITLE: {
				id: 11000,
				posX: 18,
				posY: 58,
				width: 6,
				height: 4,
				title: 'packages.dataOverviewEventTitle'
			},

			BAR: {
				id: 11001,
				posX: 18,
				posY: 62,
				width: 6,
				height: 12,
				grouping: MetricConstantValues.CB_EVENT.name
			}
		}]
	};
}