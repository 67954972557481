import { IReportAttribute } from './report-attribute';
import AttributesApiService from './attributes-api.service';
import { StudioAdminProjectAPI } from '@app/modules/project/internal/studio-admin-project.api';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject } from '@angular/core';
import WorkspaceAttributesApiService from '@app/modules/project/attribute/workspace-attributes-api.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { AttributeValuesSearchParams } from '@app/modules/project/attribute/attribute-values-search-params';

export interface IAttributeValuesParams {
	project: AccountOrWorkspaceProject;
	attributeNames: string[];
	isNumeric: boolean; // to remove trailing zeros from response
	runAs: string;
	filter: string;
	start: number;
	limit: number;
	cacheOption: AnalyticCacheOptions;
}

export interface ISearchValue {
	attributeName: string;
	term: string;
	css?: string;
}

@Injectable({
	providedIn: 'root'
})
export class AttributesService {
	constructor(
		private attributesApi: AttributesApiService,
		private workspaceAttributesApi: WorkspaceAttributesApiService,
		@Inject('adminProjectsService') private adminProjectsService: AdminProjectsService,
		private studioAdminProjectAPI: StudioAdminProjectAPI,
		private readonly formatterBuilderUtils: FormatterBuilderUtilsService,
	) {}

	getAttributes(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IReportAttribute[]> {
		return ProjectAssetsHelper.getAssetsForProject<IReportAttribute[]>(
			project,
			(accountProject) => this.attributesApi.getAttributes(accountProject, cache),
			(workspaceProject) => this.workspaceAttributesApi.getAttributes(workspaceProject, cache),
			(projectId) => this.adminProjectsService.getProjectAttributes(projectId)
		);
	}

	getWordAttributes(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IReportAttribute[]> {
		return ProjectAssetsHelper.getAssetsForProject<IReportAttribute[]>(
			project,
			(accountProject) => this.attributesApi.getWordAttributes(accountProject, cache),
			(workspaceProject) => this.workspaceAttributesApi.getWordAttributes(workspaceProject, cache),
			() => []
		);
	}

	getAttributeValues = (params: Partial<IAttributeValuesParams>): Promise<string[]> => {
		return this.getMultiAttributeValues(params)
			.then(values => _.map(values, (value: ISearchValue) => value.term));
	}

	getMultiAttributeValues = (params: Partial<IAttributeValuesParams>): Promise<ISearchValue[]> => {
		if (_.isEmpty(_.filter(params.attributeNames, attr => !!attr))) {
			return Promise.resolve([]);
		}

		if (InternalProjectTypes.isStudioAdminProject(params.project.projectId)) {
			const attributeName = params.attributeNames[0];
			return this.studioAdminProjectAPI.getStudioAdminProjectSuggestions(params).then(values => values.map(value => {
				return {
					attributeName,
					term: value
				} as ISearchValue;
			}));
		} else {
			const searchParams = AttributeValuesSearchParams.fromSearchObject(params);
			return ProjectAssetsHelper.getAssetsForProject<ISearchValue[]>(
				params.project,
				(accountProject) => this.attributesApi.getAttributeValues(accountProject, searchParams),
				(workspaceProject) => this.workspaceAttributesApi.getAttributeValues(workspaceProject, searchParams),
				() => []
			).then(values => this.formatValues(values, params.isNumeric));
		}
	}

	private formatValues(values: ISearchValue[], numeric: boolean): ISearchValue[] {
		if (numeric && values) {
			_.each(values, (value) => {
				value.term = this.formatterBuilderUtils.formatNumberAsString(value.term);
			});
		}
		return values;
	}
}

app.service('attributesService', downgradeInjectable(AttributesService));
