<div *ngIf="!!modelRoot"
	class="h-min-240 w-100-percent"
	[ngBusy]="loading">
	<labeled-toggle-switch *ngIf="!isMultiLevel()"
		class="d-flex mb-8"
		[(value)]="inheritTopics"
		(valueChange)="inheritTopicsChange.emit($event); applyModelDefaults()"
		[label]="'widget.inheritTopics'|i18n"
	></labeled-toggle-switch>
	<div class="d-flex flex-direction-row flex-nowrap justify-between mb-8">
		<div class="d-flex flex-direction-column flex-fixed">
			<label for="selectedLevel">{{'widget.level'|i18n}}</label>
			<div class="mb-16">
				<simple-dropdown *ngIf="!isMultiLevel()"
					id="selectedLevel"
					class="w-100-percent"
					[options]="topicLevelsList"
					displayField="displayName"
					[(value)]="selectedLevel"
					(valueChange)="selectedLevelChange.emit($event); levelChanged()"
					[disableSort]="true"
					[disabled]="inheritTopics"
				></simple-dropdown>
				<checkbox-dropdown *ngIf="isMultiLevel()"
					[(selection)]="selectedLevels"
					(selectionChange)="selectedLevelsChange.emit($event)"
					[options]="topicLevelsList">
				</checkbox-dropdown>
			</div>
		</div>
		<div class="d-flex flex-direction-column flex-fill overflow-hidden">
			<div class="d-flex justify-between mh-8">
				<label>{{'widget.inclusionList'|i18n}}</label>
				<div class="d-flex">
					<a *ngIf="!inheritTopics"
						data-testid="select-all"
						class="mr-8"
						(click)="selectAll()">
						{{'common.selectAll'|i18n}}
					</a>
					<a *ngIf="!inheritTopics"
						data-testid="deselect-all"
						(click)="deselectAll()">
						{{'administration.selectNone'|i18n}}
					</a>
				</div>
			</div>
			<searchable-hierarchy
				class="topic-inclusion-list"
				placeholder="{{'common.search'|i18n}}"
				[hierarchyList]="[modelRoot]"
				displayProperty="name"
				[ngShowNodeCheckbox]="wrapPredicate(indeterminateTopicSelection.isNodeClickable)"
				[ngNodeIsChecked]="wrapPredicate(indeterminateTopicSelection.isNodeChecked)"
				[ngNodeIsHighlighted]="wrapPredicate(indeterminateTopicSelection.isNodeHighlighted)"
				(onNodeClick)="indeterminateTopicSelection.handleNodeClick($event.node); $event.$event.stopPropagation()"
				[ngNodeIndeterminate]="wrapPredicate(indeterminateTopicSelection.isIndeterminateNode)"
				[ngIsIndeterminateFollowedByUncheck]="isIndeterminateFollowedByUncheck"
				[folderClickIgnore]="true"
				[ngNodeCheckboxDisabled]="isNodeCheckboxDisabled">
			</searchable-hierarchy>
		</div>
	</div>

	<labeled-toggle-switch *ngIf="showLevel && isMultiLevel()"
		class="d-flex mb-8"
		[(value)]="subtopicsOnly"
		(valueChange)="subtopicsOnlyChange.emit($event)"
		[label]="'widget.subtopicsOnly'|i18n"
	></labeled-toggle-switch>
	<labeled-toggle-switch *ngIf="!isMultiLevel()"
		class="d-flex mb-8"
		[(value)]="updateOnParentFilter"
		(valueChange)="updateOnParentFilterChange.emit($event)"
		[label]="'widget.updateInclusionOnFilter'|i18n"
	></labeled-toggle-switch>
</div>

