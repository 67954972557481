import { DateFormat } from '@cxstudio/header/date-format';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { SessionFlow } from './session-flow';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { CurrentMasterAccount } from './entities/current-master-account';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { MasterAccountIdentity } from '@app/modules/system-administration/master-account/entities/master-account-identity';

export interface ContextMasterAccount extends MasterAccountIdentity {
	disabled: boolean;
	expired: boolean;
}

export interface QualtricsSettings {
	integrationEnabled: boolean;
	userSyncingEnabled: boolean;
	enforceXmAuthenticationEnabled: boolean;
	apiIntegrationEnabled: boolean;
	ticketingEnabled: boolean;
	providerAliasName: string;
	authLandingPage: string;
	ticketLink: string;
}

export class Context {
	masterAccountId: number;
	preferredEmail: string;
	preferredEmailPending?: boolean;
	user: User;
	permissions: string[];
	licenseType: string;
	licenseTypeId: number;
	isAdminOrg: boolean;
	isGroupOwner: boolean;
	lastDashboardId: number;
	lastLoginDate: string;
	favoriteMasterAccount: number;
	favoriteHomePage: number;
	homePage: HomePage; // initial value
	properties: FavoriteProperties;
	hiddenDashboards: any;
	hiddenFilters: any;
	hiddenMetrics: any;
	hiddenAttributes: any;
	hiddenModels: any;
	hiddenDrivers: any;
	currentMasterAccount: CurrentMasterAccount;
	masterAccounts: ContextMasterAccount[];
	impersonateMode: boolean;
	sessionFlow: SessionFlow;
	authLandingPage: string;

	ideasForumEnabled: boolean;
	isSamlEnabled: boolean;
	isForcedExternalAuthentication: boolean;
	isForcedExternalAuthenticationForUser: boolean;
	isAuthByUniqueId: boolean;
	isEnterpriseUser: boolean;
	allowViewProfanity: boolean;

	dateFormat: DateFormat;
	locale: string;
	translateLanguage: string;
	applicationTheme: ApplicationTheme;
	dashboardTheme: ApplicationTheme;

	patternFills: boolean;
	experimentalUI: boolean;
	interactionsSentimentFormatting?: boolean;
	interactionsEnrichmentBadges?: boolean;

	dataIsolationEnabled: boolean;

	qualtricsSettings: QualtricsSettings;
}
