import { Component, OnInit} from '@angular/core';

export interface HierarchyPublishingSettings {
	includingEmptyRecords: boolean;
	incrementalMode: boolean;
}

@Component({
	selector: 'hierarchy-publishing-dialog',
	template: `
		<simple-dialog 
			header="{{'organization.publishingDialogHeader'|i18n}}" 
			okBtnName="{{'common.ok'|i18n}}" 
			cancelBtnName="{{'common.cancel'|i18n}}" 
			[value]="value">
			<checkbox-button class="d-block" label="{{'organization.isIncludeEmptyRecords'|i18n}}"
				[(ngModel)]="value.includingEmptyRecords"></checkbox-button>
			<checkbox-button class="d-block" label="{{'organization.isUseIncrementalMode'|i18n}}"
				[(ngModel)]="value.incrementalMode"></checkbox-button>
		</simple-dialog>
	`
})

export class HierarchyPublishingDialogComponent implements OnInit {
	value: HierarchyPublishingSettings;

	constructor() {}

	ngOnInit(): void {
		this.value = {
			includingEmptyRecords: false,
			incrementalMode: true
		};
	}
}