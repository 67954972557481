<fieldset ngModelGroup="boxMetricDefinition">
	<div class="form-group">
		<label for="calculationName">{{'metrics.basedOn'|i18n}}</label>
		<div class="row">
			<select-from-tree
				name="calculationName"
				class="attribute-selector col-xs-12 col-md-6"
				[ngModel]="definition.name"
				[hierarchyList]="ui.calculations"
				(onNodeClick)="selectCalculationNode($event.node)">
			</select-from-tree>
		</div>
	</div>
	<div class="form-group">
		<div class="line-label bg-pewter-400">
			<label class="bg-white">{{'metrics.metricBands'|i18n}}</label>
		</div>
		<cb-color-grade-bar
			class="metric-color-grade"
			[grades]="colorGrades"
			(gradesChange)="colorGradesChangeHandler($event)"
			[options]="options"
			[min]="definition.min"
			[max]="definition.max"
			(minMaxChange)="minMaxChangeHandler($event.isMin, $event.value)"
			[allowMinMaxMatching]="true"
			(validityChange)="validityChangeHandler($event)">
		</cb-color-grade-bar>
	</div>
	<collapsing-section [collapsed]="true">
		<panel-heading class="font-bold">{{'widget.advancedOptions'|i18n}}</panel-heading>
		<panel-body>
			<div class="clearfix">
				<div class="block">
					<label class="mr-16">{{'metrics.nullValuesLabel'|i18n}}</label>

					<div>
						<div class="btn-group">
							<button class="btn"
								(click)="toggleNullInclude(true)"
								[ngClass]="{ 'btn-selected' : definition.nullInclude}">{{'metrics.include'|i18n}}
							</button>
							<button class="btn"
								(click)="toggleNullInclude(false)"
								[ngClass]="{ 'btn-selected' : !definition.nullInclude}">{{'metrics.exclude'|i18n}}
							</button>
						</div>
					</div>
				</div>
				<div class="block">
					<label for="excludeValues" class="mr-16">{{'metrics.excludeValuesLabel'|i18n}}</label>
					<div>
						<input type="text"
							id="excludeValues"
							name="excludeFilter"
							[pattern]="NUMBER_LIST_PATTERN"
							[(ngModel)]="ui.excludeFilter"
							(ngModelChange)="excludeFilterChangeHandler()"
							size="40">
					</div>
				</div>
			</div>
		</panel-body>
	</collapsing-section>
</fieldset>
