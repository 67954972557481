<modal-header (cancel)="dismiss()" [modalTitle]="modalTitle | i18n"></modal-header>
<section class="modal-body" [ngBusy]="loading">
	<formatted-table [rows]="rows" [columns]="columns" [view]="tableView"></formatted-table>
</section>
<save-modal-footer
	(cancel)="dismiss()"
	(save)="save()"
	(secondaryAction)="resumeAllAndSave()"
	[secondaryBtnTxt]="'alert.resumeAll' | i18n"
	[isDisabled]="loading"
>
</save-modal-footer>
