import { AnSortDirection } from '@cxstudio/common/an-sort-direction';

export const DefaultMetricConfig = {
	size: 10,
	nullInclude: false,
	sortBy: 'volume',
	sortOrder: AnSortDirection.DESC
};


