import * as _ from 'underscore';
import { INode, ISearchableHierarchyItem, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import EventType from '@cxstudio/services/event/event-type.enum';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { RandomUtils } from '@app/util/random-utils.class';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';


interface INodeParams {
	node: ISearchableHierarchyItem;
	$event?: ng.IAngularEvent;
}

export class SearchableHierarchyController implements ng.IController {

	readonly ATTRIBUTE_STATS_TIMEOUT = 1000;

	onNodeClick: (params: INodeParams) => void;
	hierarchyList: ISearchableHierarchyItem[];
	displayProperty: string;
	placeholder: string;
	noResultsText: string;
	selectedItem: ISearchableHierarchyItem;
	nodeIsChecked: (params: INodeParams) => boolean;
	nodeIsHighlighted: (params: INodeParams) => boolean;
	nodeIndeterminate: (params: INodeParams) => boolean;
	isIndeterminateFollowedByUncheck: (params: INodeParams) => boolean;
	nodeIsMarkedFn: (params: INodeParams) => boolean;
	folderExpandHandler: (params: INodeParams) => void;
	isChildLoadedFn: (params: INodeParams) => boolean;
	hideSearch: boolean;
	skipSelection: boolean;
	showNodeCheckbox: (...args) => boolean;
	nodeCheckboxDisabledFn: (params: INodeParams) => boolean;
	folderClickIgnore: boolean;
	disabledItems: ISearchableHierarchyItem[];
	disabledItemTooltip: string;
	itemTemplate: string;
	highlightSelectedBackground: boolean;
	showClearButton: boolean;
	selectEverythingText: string;
	selectNoneText: string;
	selectEverything: () => void;
	selectNone: () => void;
	notRecommendedItems: {
		models: any;
		attributes: any;
	};
	showMultiselectButtons: boolean;
	showHelper: boolean;
	componentDisabled: boolean;
	showNotRecommendedPrompt: boolean;
	selectedLevel: number;
	limitedWidth: boolean;
	externalModel: {hierarchySearch: string};
	isVisibleOverrided: (node: INode) => boolean;
	intersectionObserver: IntersectionObserver;
	customValidation: (node: ISearchableHierarchyItem) => boolean;
	optionClassFormatter: (item: ISearchableHierarchyItem) => string;
	tooltip: (item: ISearchableHierarchyItem) => string;

	buttonStyleId: string;

	scrollTimeout: ng.IPromise<void>;
	keyupTimeout: ng.IPromise<void>;
	models: { hierarchySearch: string; };
	showCheckboxes: boolean;
	keepOnSelection: boolean;
	styleId: string;
	searchLabel: string;

	// angular upgrade support
	ngShowNodeCheckbox: () => boolean;
	ngNodeIsChecked: () => boolean;
	ngNodeIsHighlighted: () => boolean;
	ngNodeIndeterminate: () => boolean;
	ngIsIndeterminateFollowedByUncheck: () => boolean;
	ngNodeCheckboxDisabled: () => boolean;

	constructor(
		private locale: ILocale,
		private $scope: ISimpleScope,
		private $element: JQuery,
		private eventEmitterService: EventEmitterService,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService
	) {}

	$onInit(): void {
		this.showNodeCheckbox = this.ngShowNodeCheckbox || this.showNodeCheckbox;
		this.nodeIsChecked = this.ngNodeIsChecked || this.nodeIsChecked;
		this.nodeIsHighlighted = this.ngNodeIsHighlighted || this.nodeIsHighlighted;
		this.nodeIndeterminate = this.ngNodeIndeterminate || this.nodeIndeterminate;
		this.isIndeterminateFollowedByUncheck = this.ngIsIndeterminateFollowedByUncheck || this.isIndeterminateFollowedByUncheck;
		this.nodeCheckboxDisabled = this.ngNodeCheckboxDisabled || this.nodeCheckboxDisabled;

		this.scrollTimeout = null;
		this.keyupTimeout = null;

		if (_.isUndefined(this.showMultiselectButtons)) {
			this.showMultiselectButtons = true;
		}
		if (this.showNotRecommendedPrompt === undefined) {
			this.showNotRecommendedPrompt = true;
		}

		// if externalModel is provided, use that value for searching instead...
		if (this.externalModel) {
			this.models = this.externalModel;
		} else {
			this.models = {hierarchySearch: '' };
		}

		this.showCheckboxes = !_.isUndefined(this.showNodeCheckbox)
			&& !_.isUndefined(this.nodeIsChecked)
			&& !_.isUndefined(this.onNodeClick);

		// for table and feedback
		this.$element.find('div>.searchable-hierarchy-list').on('scroll', this.onScroll);
		// for other widgets
		this.$element.find('div>.searchable-hierarchy-list>ul').on('scroll', this.onScroll);

		this.$scope.$watch(() => this.models?.hierarchySearch, (newVal, oldVal) => {
			if (newVal !== oldVal) {
				this.searchUpdated();
				this.updateAttributeStats();
			}
		});
		this.styleId = `s-${RandomUtils.randomString()}`;
	}

	isEmptyFolder = (node: INode) => SearchableHierarchyUtils.isEmptyFolder(node);

	isFolder = (node: INode) => SearchableHierarchyUtils.isFolder(node);

	showItem = (node: INode) => SearchableHierarchyUtils.showItem(node);

	toggleParent = (node: INode, $event: ng.IAngularEvent) => SearchableHierarchyUtils.toggleParent(node, $event);

	isVisible = (node: INode) => {
		if (this.isVisibleOverrided)
			return this.isVisibleOverrided(node);
		let name = this.selectedItem && this.selectedItem.name;
		//item.hidden: special flag to indicate this is a organization hiearchy model
		//item.hide: visible or not
		return node && ((!node.hidden && !node.hide) || name === node.name); // if selected, don't hide
	}

	uiNodeIndeterminate = (params: INodeParams) => this.showNodeCheckbox(params) && this.nodeIndeterminate && this.nodeIndeterminate(params);

	nodeIsMarked = (params: INodeParams) => this.nodeIsMarkedFn ? this.nodeIsMarkedFn(params) : false;

	nodeCheckboxDisabled = (params: INodeParams) => this.nodeCheckboxDisabledFn ? this.nodeCheckboxDisabledFn(params) : false;

	isChildLoaded = (params: INodeParams) => this.isChildLoadedFn ? this.isChildLoadedFn(params) : true;

	searchUpdated = () => {
		if (this.models.hierarchySearch.trim().length < 1) {
			this.hierarchyList.forEach(node => SearchableHierarchyUtils.collapseFolder(node));
		} else {
			this.hierarchyList.forEach((node) => {
				if (this.isChildLoaded({ node }) && !node._disabled) {
					SearchableHierarchyUtils.expandFolders(node);
				}
			});
		}
	}

	isNodeCheckboxDisabled = (item: ISearchableHierarchyItem): boolean => {
		return this.showCheckboxes && this.nodeCheckboxDisabled({node: item});
	}

	triggerClick = (item: ISearchableHierarchyItem, $event?: ng.IAngularEvent) => {
		this.validate(item).then(() => {
			if (!this.isDisabled(item) && !this.componentDisabled && !this.isNodeCheckboxDisabled(item)) {
				this.onNodeClick({
					node: item,
					$event
				});
			}
		});
	}

	expandFolder = (item: ISearchableHierarchyItem, $event?: ng.IAngularEvent) => {
		if (this.componentDisabled || item._disabled) return;

		if ($event) {
			$event.stopPropagation();
		}

		item._expanded = !item._expanded;
		if (item._expanded) {
			if (this.folderExpandHandler) {
				this.folderExpandHandler({ node: item });
			}

			this.$timeout(() => {
				this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
			}, this.ATTRIBUTE_STATS_TIMEOUT);
		}
		item._autoexpanded = false;
	}

	comparator = (item: AttributeGrouping) => {
		return item.standardMetric ? this.standardMetricComparator(item) : item[this.displayProperty];
	}

	standardMetricComparator = (metric: AttributeGrouping) => {
		return metric.name === StandardMetricName.VOLUME ? 0
			: AnalyticMetricTypes.isPredefinedMetric(metric) ? 1 : 2;
	}

	isDisabled = (item: ISearchableHierarchyItem): boolean => {
		if (item._disabled) {
			return true;
		}

		if (this.isSelected(item)) {
			return false;
		}

		return !!_.find(this.disabledItems, (disabledItem) => !!disabledItem && this.isMatch(item, disabledItem));
	}

	isSelected = (item: ISearchableHierarchyItem): boolean => {
		if (this.skipSelection) {
			return false;
		}

		if (item && this.selectedItem) {
			return this.isMatch(item, this.selectedItem);
		}

		return false;
	}

	private isMatch(itemA: ISearchableHierarchyItem, itemB: ISearchableHierarchyItem): boolean {
		if (this.hasNameAndType(itemA) || this.hasNameAndType(itemB)) {
			// name + type is a priority, as some items may have same id
			return itemA.name === itemB.name && itemA.type === itemB.type;
		} else if (itemA.id || itemB.id) {
			return itemA.id === itemB.id;
		} else if (itemA.name || itemB.name) {
			return itemA.name === itemB.name;
		} else {
			return itemA === itemB;
		}
	}

	private hasNameAndType(item: ISearchableHierarchyItem): boolean {
		return !!item.name && !!item.type;
	}

	markSelected = (item: ISearchableHierarchyItem): boolean => {
		return this.isSelected(item) || (this.highlightSelectedBackground && this.nodeIsChecked({node: item}));
	}

	showExpansionToggle = (item: ISearchableHierarchyItem): boolean => {
		return item && item.children && (item.children.length || this.isFolder(item)) && !item._hideExpansionToggle;
	}

	notRecommended = (item: ISearchableHierarchyItem): boolean => {
		if (item && item._notRecommended) {
			return true;
		}

		return item && !item.children && !this.isDisabled(item) && !!this.notRecommendedInternal(item);
	}

	notRecommendedInternal = (item: ISearchableHierarchyItem): boolean => {
		if (_.isEmpty(this.notRecommendedItems)) {
			return false;
		}

		if (!this.isModel(item) && !this.isAttribute(item)) {
			return false;
		}

		return this.isModel(item)
			? this.notRecommendedItems.models[item.name]
			: this.notRecommendedItems.attributes[item.name.toLowerCase()];
	}

	isModel = (item: any): boolean => {
		return !!item.model;
	}

	isAttribute = (item: ISearchableHierarchyItem): boolean => {
		return item.metricType === AnalyticMetricType.ATTRIBUTE;
	}

	getTooltip = (item: ISearchableHierarchyItem): string => {
		if (this.disabledItemTooltip && this.isDisabled(item)) {
			return this.disabledItemTooltip;
		}
		if (this.showNotRecommendedPrompt && this.notRecommended(item)) {
			return this.locale.getString('widget.notRecommended');
		}
		if (this.limitedWidth) {
			return item[this.displayProperty];
		}
		return '';
	}

	getNoResultsText = (): string => {
		return this.noResultsText ? this.noResultsText : this.locale.getString('common.noMatchedItems');
	}

	updateAttributeStats = () => {
		this.$timeout.cancel(this.keyupTimeout);
		this.keyupTimeout = this.$timeout(() => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
		}, this.ATTRIBUTE_STATS_TIMEOUT);
	}

	onScroll = () => {
		this.$timeout.cancel(this.scrollTimeout);
		this.scrollTimeout = this.$timeout(() => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
		}, this.ATTRIBUTE_STATS_TIMEOUT);
	}

	validate = (item: ISearchableHierarchyItem): ng.IPromise<boolean> => {
		let result = this.customValidation
			? this.customValidation(item)
			: true;
		return this.$q.resolve(result);
	}

	itemClick = (item: ISearchableHierarchyItem, $event: ng.IAngularEvent) => {
		if (this.keepOnSelection) {
			$event.stopPropagation();
		}

		this.triggerClick(item, $event);
	}

	getHierarchyItemClass = (item: ISearchableHierarchyItem) => {
		let iconClass: string[] = [];

		if (this.isDisabled(item)) {
			iconClass.push('disabled');
		}

		if (this.notRecommended(item)) {
			iconClass.push('grey-out');
		}

		if (this.nodeIsHighlighted && this.nodeIsHighlighted({node: item})) {
			iconClass.push('selected');
		}

		if (this.optionClassFormatter) {
			iconClass.push(this.optionClassFormatter(item));
		}

		return iconClass.join(' ');
	}

	getHierarchyItemTabIndex = (sectionId: number, index: number): number => {
		let hierarchyItemCheckbox = $(`#section_${sectionId}_node_${index}`);
		return this.showCheckboxes && hierarchyItemCheckbox.length > 0 ? -1 : 0;
	}

	onHierarchyItemKeydown = (event: any, item: ISearchableHierarchyItem): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.triggerClick(item);
		} else if (item.children && KeyboardUtils.isEventKey(event, Key.ENTER, KeyModifier.CTRL)) {
			event.preventDefault();
			event.stopPropagation();
			this.expandFolder(item);
		} else {
			KeyboardUtils.handleUpDownNavigation(event, `#hierarchy-list-${this.styleId}`);
		}
	}

	onSearchableHierarchyKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeAndFocusOnDropdown();
			});
		}
	}

	onSearchableHierarchyKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	private closeAndFocusOnDropdown = (): void => {
		let hierarchyDropdownButton: JQuery = $(`#${this.buttonStyleId}-hierarchy-button`);
		hierarchyDropdownButton.trigger('click');
		hierarchyDropdownButton.trigger('focus');
	}
}

app.component('searchableHierarchy', {
	controller: SearchableHierarchyController,
	templateUrl: 'partials/custom/searchable-hierarchy.html',
	bindings: {
		onNodeClick: '&?',
		hierarchyList: '=',
		displayProperty: '@',
		placeholder: '@',
		noResultsText: '@?',
		selectedItem: '=?',
		nodeIsChecked: '&?',
		nodeIsHighlighted: '&?',
		nodeIndeterminate: '&?',
		tooltip: '&?',
		isIndeterminateFollowedByUncheck: '&?',
		nodeIsMarkedFn: '&?nodeIsMarked',
		folderExpandHandler: '&?',
		isChildLoadedFn: '&?isChildLoaded',
		hideSearch: '<?',
		showNodeCheckbox: '&?',
		nodeCheckboxDisabledFn: '&?nodeCheckboxDisabled',
		folderClickIgnore: '<',
		disabledItems: '<',
		disabledItemTooltip: '<',
		itemTemplate: '@?',
		highlightSelectedBackground: '=',
		showClearButton: '<?',
		selectEverythingText: '=?',
		selectNoneText: '=?',
		selectEverything: '<?',
		selectNone: '<?',
		notRecommendedItems: '<?',
		showMultiselectButtons: '=?',
		showHelper: '<?',
		componentDisabled: '<ngDisabled',
		showNotRecommendedPrompt: '<?',
		selectedLevel: '<?',
		limitedWidth: '<?',
		externalModel: '<?',
		isVisibleOverrided: '<?',
		intersectionObserver: '<?',
		customValidation: '<?',
		keepOnSelection: '<?',
		searchLabel: '@?',
		optionClassFormatter: '<',
		buttonStyleId: '<?',
		skipSelection: '<?',

		// for angular upgrade support...
		ngShowNodeCheckbox: '=',
		ngNodeIsChecked: '=',
		ngNodeIsHighlighted: '=',
		ngNodeIndeterminate: '=',
		ngIsIndeterminateFollowedByUncheck: '=',
		ngNodeCheckboxDisabled: '=',
	}
});
