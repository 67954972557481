<div *ngFor="let group of groups" >
	<div *ngIf="group.key !== 'system'" class="cursor-no-selection d-flex align-items-center hover-bg-cxs-brand-100 preview-group-title" >
		<div class="break-word flex-fill column-name font-bold">{{('preview.' + group.key)| i18n}}</div>
		<div class="item-actions text-right">
			<div class="item-actions-wrap d-flex justify-end">
				<cb-toggle *ngIf="contextPaneEnabled" class="w-50-percent"
						   [(ngModel)]="group.visible"
						   (ngModelChange)="toggleSectionVisibleButton(group)">
				</cb-toggle>
				<cb-inline-help fixed="true" *ngIf="!contextPaneEnabled && group.key === 'model'">
					<help-body>
						<p>{{'preview.topicsHelper'|i18n}}</p>
					</help-body>
				</cb-inline-help>
			</div>
		</div>
	</div>
	<div *ngFor="let item of groupBySection(group) | orderBy: 'displayName'"
		 class="cursor-no-selection d-flex align-items-center hover-bg-cxs-brand-100 cx-preview-selected"
		 [ngClass]="{'disabled': isDisabled(item), 'cx-sentence-column': item.name === 'sentence'}">
		<div class="break-word flex-fill column-name" [ngClass]="{'pl-8': item.type !== 'sys'}">{{item.displayName}}</div>
		<div class="item-actions text-right">
			<div class="item-actions-wrap d-flex justify-end">
				<a *ngIf="hasOptions(item)" [title]="'common.options'|i18n"
				   class="btn btn-icon q-icon-cog" (click)="config.emit({item: item})"></a>
				<a *ngIf="hasFavorite(item, group.key)"
				   class="btn btn-icon ml-4 cursor-pointer"
				   [ngClass]="{'q-icon-star': isItemFavorited(item),
						'q-icon-star-outline': !isItemFavorited(item)}"
				   (click)="toggleFavorited(item)"></a>
				<a *ngIf="isRemovable(item)" [title]="'common.remove'|i18n"
				   class="btn btn-icon ml-4 column-remove q-icon-delete"
				   (click)="remove.emit({item: item})"></a>
				<cb-inline-help fixed="true" *ngIf="isDisabled(item)">
					<help-body>
						<p>{{'widget.unavailableAttributes'|i18n}}</p>
					</help-body>
				</cb-inline-help>
			</div>
		</div>
	</div>
</div>
