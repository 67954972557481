<div [ngBusy]="loading">
	<div class="row">
		<div class="col-md-6 col-lg-4">
			<div class="form-group">
				<label for="scorecardName" class="mandatory">{{'common.name'|i18n}}</label>
				<input
					autocomplete="off"
					id="scorecardName"
					type="text"
					maxlength="30"
					class="form-control"
					[(ngModel)]="scorecard.name"
					(ngModelChange)="nameChangeHandler()"
					placeholder="{{'scorecards.namePlaceholder'|i18n}}">
			</div>
		</div>
	</div>
	<alert type="danger" *ngIf="errors.nameIsBlankError" text="{{'scorecards.nameIsBlankError'|i18n}}"></alert>
	<alert type="danger" *ngIf="errors.nameExistsError" text="{{'scorecards.nameExistsError'|i18n}}"></alert>
	<alert type="danger" *ngIf="errors.topicsAlreadyUsedError" text="{{errors.topicsAlreadyUsedError}}"></alert>

	<div class="form-group">
		<label class="mandatory">{{'scorecards.selectedModelLabel'|i18n}}</label>
		<cb-inline-help>
			<help-body>{{'scorecards.modelHelp'|i18n}}</help-body>
		</cb-inline-help>
		<div *ngIf="models.length" class="row">
			<div class="col-md-6 col-lg-4 model-selector">
				<search-list [dropdown]="true"
					[data]="modelContext?.model"
					[listOptions]="models"
					(onNodeSelection)="modelChangeHandler($event.node)"
					[isItemDisabled]="isModelDisabled"
					[disabledItemTooltip]="getModelDisabledTooltip">
				</search-list>
			</div>
		</div>
	</div>

	<div class="hierarchy-select-wrapper">
		<tree-selection
			*ngIf="loaded && modelContext?.model"
			[treeSelection]="modelContext.selection"
			[root]="modelContext.rootNode"
			[forcedOrder]="true"
			(onTreeSelectionChanged)="onSelectionChanged($event)"
			[convertNodes]="convertNodes"
			[loadRootChildren]="loadTopics"
			[selectEverythingText]="selectEverythingText"
			[selectNoneText]="selectNoneText"
			[highlightSelectedBackground]="true"
			[nodeCheckboxDisabled]="isTopicInUsedSubtree"
			[ignoreDisabled]="true"
			[reloadWatch]="modelContext.model && modelContext.model.id"
			[refreshWatch]="driverTrigger"
			[optionClassFormatter]="itemClassFormatter"
			[getNodeTooltip]="getNodeTooltip">
		</tree-selection>
	</div>
</div>
