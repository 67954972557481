import { ColorFunctionBuilder } from '@cxstudio/reports/coloring/color-function-builder';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import * as _ from 'underscore';



export class ColorFunctionService {

	constructor(
		private colorUtils: ColorUtils,
		private calculationColorService: CalculationColorService,
		private groupColorService: GroupColorService,
		private CalculationColorType,
		private GroupColorType,
		private colorPalettes: ColorPalettes,
		private applicationThemeService: ApplicationThemeService,
	) {

	}

	getBuilder = (): ColorFunctionBuilder => {
		return new ColorFunctionBuilder(this.colorUtils, this.calculationColorService, this.groupColorService,
			this.CalculationColorType, this.GroupColorType, this.colorPalettes, this.applicationThemeService);
	}
}

app.service('colorFunctionService', ColorFunctionService);
