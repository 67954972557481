import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { DocumentExplorerPageParams } from '@app/modules/document-explorer/document-explorer-page/document-explorer-page-params';
import { DeleteDocumentsDialogComponent } from '@app/modules/interaction-explorer/delete-documents-dialog.component';
import { DeleteReason } from '@app/modules/interaction-explorer/delete-reason.enum';
import { InteractionSettingsProperty, InteractionSettingsService } from '@app/modules/interaction-explorer/interaction-settings.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InteractionExplorer } from '@cxstudio/interaction-explorer/interaction-explorer.component';
import { DashboardFromSelectionService, IDashboardCreationContext } from '@app/modules/document-explorer/dashboard-from-selection.service';
import { DocumentCaseService } from '@app/modules/document-explorer/document-cases/document-case.service';
import { CaseDocument } from '@cxstudio/reports/entities/case-document.class';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { CuratedItemType } from '@cxstudio/reports/preview/curated-item';
import { PreviewExportService } from '@app/modules/widget-container/widget-menu/export/preview-export.service';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import DashboardCreationOrigin from '../dashboard/dashboard-creation-origin.enum';
import { FullPageDocumentExplorerService } from '../document-explorer/document-explorer-page/full-page-document-explorer.service';
import { DocumentLinkCopyService } from '../document-explorer/document-link-copy.service';
import { InteractionApiService } from './interaction-api.service';
import { InteractionExplorerGridDefinition } from './interaction-explorer-grid-definition.service';

export interface IInteractionActionsService {
	createCase: (documents: CbDocument[]) => void;
	copyLink: (event: Event, documents: CbDocument[]) => void;
	openDocuments: (documents: CbDocument[]) => void;
	exportDocuments: (documents: CbDocument[]) => void;
	deleteDocuments: (documents: CbDocument[]) => Promise<void>;
	sendDocumentsToDashboard: (documents: CbDocument[], dashboard?: Dashboard) => void;
	toggleSentimentFormatting: (enable: boolean) => void;
	toggleEnrichmentBadges: (enable: boolean) => void;
}

export interface DeletionDocumentMetadata {
	id: number;
	documentDate: string;
}

@Injectable()
export class InteractionActionsService {

	constructor(
		private readonly documentCaseService: DocumentCaseService,
		private documentLinkCopyService: DocumentLinkCopyService,
		private interactionApiService: InteractionApiService,
		private interactionSettingsService: InteractionSettingsService,
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private widgetApiService: WidgetApiService,
		private dashboardFromSelection: DashboardFromSelectionService,
		private readonly reportProcessingService: ReportProcessingService,
		private readonly cxLocation: CxLocationService,
		private readonly fullPageDocExplorerService: FullPageDocumentExplorerService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('dashboardService') private dashboardService,
		@Inject('metricUtils') private metricUtils,
		@Inject('customFilterService') private customFilterService: CustomFilterService,
		@Inject('previewExportService') private previewExportService: PreviewExportService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('exportApiService') private exportApiService: ExportApiService
	) {}

	initialize = (component: InteractionExplorer): IInteractionActionsService => {

		return {
			createCase: (documents: CaseDocument[]): void => {
				component.loading.promise = PromiseUtils.old(this.documentCaseService.createRegularCase(documents, component.projectSelection));
			},

			copyLink: (event: Event, documents: CbDocument[]): void => {
				let documentIds = documents.map(document => document.id);
				this.documentLinkCopyService.copyDocumentsLink(event, documentIds,
					component.projectSelection, null);
			},

			openDocuments: (documents: CbDocument[]): void => {
				let params = new DocumentExplorerPageParams(
					component.projectSelection, _.pluck(documents, 'id'), this.cxLocation.url(), 0);
				this.fullPageDocExplorerService.openFullPageDocExplorerByParams(params);
			},

			exportDocuments: (documents: CbDocument[]): void => {
				component.loading.promise = PromiseUtils.old(this.createPreviewWidget(component, documents)
					.then(previewWidget => this.exportData(previewWidget) as any));
			},

			deleteDocuments: (documents: CbDocument[]): Promise<void> => {
				const contentProviderId = component.projectSelection.contentProviderId;
				const accountId = component.projectSelection.accountId;
				const projectId = component.projectSelection.projectId;
				const documentsMetadata = documents.map(document => {
					return {
						id: document.id,
						documentDate: document.documentDate
					} as DeletionDocumentMetadata;
				});

				let notificationMessage: string;

				if (documentsMetadata.length === 1) {
					const documentMetadata: DeletionDocumentMetadata = documentsMetadata[0];
					let documentId = documentMetadata.id;
					notificationMessage = this.locale.getString('interactionExplorer.deleteDocumentSuccessNotification', { documentId });
				} else {
					const count = documentsMetadata.length;
					notificationMessage = this.locale.getString('interactionExplorer.deleteDocumentsSuccessNotification', { count });
				}

				let deleteDocumentsDialog = this.cxDialogService.openDialog(
					DeleteDocumentsDialogComponent, { documentsMetadata });

				return deleteDocumentsDialog.result
					.then((reason: DeleteReason) => this.interactionApiService.deleteDocuments(
						contentProviderId, accountId, projectId, reason, documentsMetadata))
					.then(() => {
						this.globalNotificationService.addSuccessNotification(notificationMessage);
						component.clearAllSelections();
						component.updateDocuments(true);
					})
					.catch(_.noop);
			},

			sendDocumentsToDashboard: (documents: CbDocument[], dashboard?: Dashboard): void => {
				component.loading.promise = PromiseUtils.old(this.createPreviewWidget(component, documents)
					.then(previewWidget => this.sendToDashboard(documents, previewWidget, dashboard)));
			},

			toggleSentimentFormatting: (enable: boolean): void => {
				component.settings.showSentimentFormatting = enable;
				this.interactionSettingsService.saveSettings(InteractionSettingsProperty.SENTIMENT_FORMATTING, enable);
				component.refreshGrid(component.currentPageDocuments);
			},

			toggleEnrichmentBadges: (enable: boolean): void => {
				component.settings.showEnrichmentBadges = enable;
				this.interactionSettingsService.saveSettings(InteractionSettingsProperty.ENRICHMENT_BADGES, enable);
				component.refreshGrid(component.currentPageDocuments);
			}
		};
	}

	private createPreviewWidget = (component: InteractionExplorer, documents: CbDocument[]): Promise<Widget> => {
		let { contentProviderId, accountId, project, runAs, adhocFilter, appliedFilters } = component.widget.properties;

		let widget = {} as Widget;
		widget.posX = 1;
		widget.width = 22;
		widget.height = 20;

		widget.properties = {
			contentProviderId,
			accountId,
			project,
			runAs,
			adhocFilter,
			appliedFilters,
			isCustomTitle: true,
			selectedAttributes: this.getSelectedAttributes(component),
			objectName: DrillWidgetType.SELECTIVE_PREVIEW,
			analyticFeedbackSelection: {
				excluding: false,
				items: _.map(documents, document => ({type: CuratedItemType.DOCUMENT, documentId: document.id}))
			}
		} as WidgetProperties;


		widget.visualProperties = {
			visualization: WidgetVisualization.DOCUMENT_PREVIEW,
			sentencePaneEnabled: true,
			contextPaneEnabled: true
		} as VisualProperties;

		return this.dashboardFromSelection.buildPreviewWidget({
			currentWidget: widget
		} as IDashboardCreationContext);
	}

	private getSelectedAttributes = (component: InteractionExplorer) => {
		let models = [];
		let attributes = [];
		_.chain(component.interactionExplorerColumns)
			.filter(column => column.selected)
			.each(column => {
				let modelId = InteractionExplorerGridDefinition.getModelIdFromColumn(column.name);
				if (modelId !== -1) {
					let model = _.findWhere(component.projectAssets.models, {id: modelId});
					if (model) {
						models.push(model);
					}
				}
				let attrName = InteractionExplorerGridDefinition.getAttributeNameFromColumn(column.name);
				if (attrName) {
					let attribute = _.find(component.projectAssets.attributes, attr => attr.name.toLowerCase() === attrName.toLowerCase());
					if (attribute) {
						attributes.push(attribute);
					}
				}
			});
		let modelOptions = this.metricUtils.buildModelOptions(models).topicModels;
		let attributeOptions = this.metricUtils.buildAttributeOptions(_.uniq(attributes), () => true);
		return modelOptions.concat(attributeOptions);
	}

	private sendToDashboard = (documents: CbDocument[], widget: Widget, dashboard?: Dashboard): Promise<void> => {
		if (!dashboard) {
			return this.dashboardService.createPredefinedDashboard(
				widget, undefined, undefined, { type: DashboardCreationOrigin.INTERACTION_PAGE }
			).then((newDashboard) => {
				this.globalNotificationService.addSuccessNotification(this.getMessage(documents, newDashboard.name));
			});
		} else {
			return this.widgetApiService.copyWidgets(dashboard.id, [widget]).then(() => {
				this.globalNotificationService.addSuccessNotification(
					this.getMessage(documents, dashboard.name));
			});
		}
	}

	private getMessage(documents: CbDocument[], dashboardName: string): string {
		return documents.length === 1
			? this.locale.getString('interactionExplorer.singleSendToSuccess', { documentId: documents[0].id, dashboardName })
			: this.locale.getString('interactionExplorer.multipleSendToSuccess', { count: documents.length, dashboardName });
	}

	private exportData = (widget: Widget): Promise<void> => {
		widget.displayName = this.locale.getString('interactionExplorer.title');
		let props = widget.properties as PreviewWidgetProperties;
		props.exportAttributes = [...props.selectedAttributes];
		widget.visualProperties.sentencePaneEnabled = false;

		let postprocessWidgetPromise = PromiseUtils.wrap(this.customFilterService.postprocessWidgetProperties(widget, null));
		return postprocessWidgetPromise.then(processedWidget => {
			return this.reportProcessingService.getWidgetFiltersMetadata(widget, null).then(widgetMetadata => {
				return this.previewExportService.getPreviewExport(processedWidget, { widget: processedWidget } as any).then(data => {
					return PromiseUtils.wrap(this.exportUtils.getCBExportOptions(processedWidget, data)).then(allOptions => {
						return PromiseUtils.wrap(this.exportUtils.getWidgetDataForExport(processedWidget, data, allOptions, undefined, widgetMetadata))
							.then(csvData => {
								let exportData = {
									data: csvData,
									name: processedWidget.displayName,
									filename: this.exportUtils.reportExportFilename(processedWidget, 'xlsx'),
								};
								return PromiseUtils.wrap(this.exportApiService.convertToExcel(exportData)).then(resp => {
									return this.exportUtils.exportXLSX(exportData.filename, resp.data);
								});
							});
					});
				});
			});
		});
	}
}

app.service('interactionActionsService', downgradeInjectable(InteractionActionsService));
