import { Input, EventEmitter, Output, OnInit, Component, ViewChildren, QueryList, OnDestroy, TemplateRef } from '@angular/core';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { SearchModel, INodeParams } from '@app/shared/components/forms/tree/searchable-tree.component';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { TreeNodeComponent } from '@app/shared/components/forms/tree/tree-node.component';
import { TreeFocusMoveDirection, FocusTarget, ITreeFocusMoveParams, TreeFocusUtils } from '@app/shared/components/forms/tree/focus';
import { Observable, Subject, Subscription } from 'rxjs';


@Component({
	selector: 'subtree',
	templateUrl: './subtree.component.html'
})
export class SubtreeComponent implements OnInit, OnDestroy {

	@Input() node: TreeNode;
	@Input() itemTemplate: TemplateRef<any>;
	@Input() search: SearchModel;

	@Input() displayProperty: string;
	@Input() forcedOrder: boolean;
	@Input() isDisabled: (item: TreeNode) => boolean;
	@Input() isSelected: (item: TreeNode) => boolean;
	@Input() isNotRecommended: (item: TreeNode) => boolean;

	@Input() showEmpty: boolean;
	@Input() limitedWidth: boolean;
	@Input() folderClickIgnore: boolean;
	@Input() showNotRecommendedPrompt: boolean;

	@Input() isVisibleOverrided: (node: INode) => boolean;
	@Input() customValidation: (node: TreeNode) => boolean;

	@Output() onNodeClick = new EventEmitter<INodeParams>();
	@Output() onFolderExpand = new EventEmitter<INodeParams>();

	@Input() showCheckboxes: boolean;
	@Input() showNodeCheckbox: (item: TreeNode) => boolean;
	@Input() isNodeCheckboxDisabled: (item: TreeNode) => boolean;
	@Input() isNodeChecked: (item: TreeNode) => boolean;
	@Input() isNodeMarked: (item: TreeNode) => boolean;
	@Input() getNodeTooltip: (node: TreeNode) => string;
	@Input() nodeIndeterminate: (item: TreeNode) => boolean;
	@Input() isNodeHighlighted: (item: TreeNode) => boolean;
	@Input() optionClassFormatter: (item) => string;

	@Input() groupId: string;
	@Input() setFocus: Observable<FocusTarget>;
	@Output() onFocusMove = new EventEmitter<ITreeFocusMoveParams>();
	private nodeFocusSubjects: {[nodeId: number]: Subject<FocusTarget>} = {};
	private focusSubscription$: Subscription;

	@ViewChildren(TreeNodeComponent) subtreeNodes: QueryList<TreeNodeComponent>;

	constructor() {}

	ngOnInit(): void {
		if (this.setFocus) {
			this.focusSubscription$ = this.setFocus.subscribe(this.onFocus);
		}
	}

	ngOnDestroy(): void {
		if (this.focusSubscription$) {
			this.focusSubscription$.unsubscribe();
		}
	}

	nodeClickHandler = ($event: INodeParams) => {
		this.onNodeClick.emit($event);
	}

	folderExpandHandler = ($event: INodeParams) => {
		this.onFolderExpand.emit($event);
	}

	focusMoveHandler = ($event: ITreeFocusMoveParams) => {
		let nodes = this.subtreeNodes.map(subtreeNode => subtreeNode.node);
		let currentIndex = nodes.indexOf($event.node);
		let offset = TreeFocusUtils.isDown($event.direction) ? 1 : -1;
		let targetIndex = currentIndex + offset;
		if (targetIndex === -1 || targetIndex === this.subtreeNodes.length || $event.direction === TreeFocusMoveDirection.PARENT) {
			this.onFocusMove.emit($event);
			return;
		}
		if (TreeFocusUtils.isTab($event.direction))
			return;

		let targetNode = this.subtreeNodes.toArray()[currentIndex + offset];
		if (targetNode) {
			let focusTarget = $event.direction === TreeFocusMoveDirection.DOWN ? FocusTarget.FIRST : FocusTarget.LAST;
			this.nodeFocusSubjects[targetNode.node.id].next(focusTarget);
		}
	}

	getSetNodeFocusSubject = (node: TreeNode): Subject<FocusTarget> => {
		if (!this.nodeFocusSubjects[node.id]) {
			this.nodeFocusSubjects[node.id] = new Subject<FocusTarget>();
		}
		return this.nodeFocusSubjects[node.id];
	}

	private onFocus = (target: FocusTarget): void => {
		let nodeId;
		if (target === FocusTarget.LAST) {
			nodeId = this.subtreeNodes.last.node.id;

		} else {
			nodeId = this.subtreeNodes.first.node.id;
		}
		this.nodeFocusSubjects[nodeId].next(target);
	}
}
