import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardSnapshot } from '@cxstudio/dashboards/entity/dashboard-snapshot';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardReportType } from '@app/modules/dashboard/entity/dashboard-report-type.enum';

export class FrontlineDashboardUtils {

	static isFrontlineDashboard(dashboard: Dashboard): boolean {
		return dashboard?.properties?.reportType === DashboardReportType.FRONTLINE;
	}

	static isSnapshotView(dashboard: Dashboard): boolean {
		return !!dashboard.snapshotMetadata;
	}

	static addSnapshotMetadata(dashboard: Dashboard, snapshot: DashboardSnapshot): void {
		dashboard.snapshotMetadata = {
			id: snapshot.id,
			createdDate: snapshot.createdDate,
			dashboardVersionId: snapshot.dashboardVersionId,
		};
	}

	static mergeDashboardSnapshot(dashboard: Dashboard, snapshot: DashboardSnapshot): void {
		dashboard.appliedFiltersArray = FrontlineDashboardUtils.lockFilters(snapshot.dashboard.appliedFiltersArray);
		dashboard.properties = snapshot.dashboard.properties;
		dashboard.workspaceProject = snapshot.dashboard.workspaceProject;

		FrontlineDashboardUtils.addSnapshotMetadata(dashboard, snapshot);
	}

	static lockFilters(filters: DashboardFilter[]): DashboardFilter[] {
		filters.forEach(filter => filter.locked = true);
		return filters;
	}
}
