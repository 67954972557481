import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';

@Component({
	selector: 'favorite-renderer',
	template: `
	<button *ngIf="isVisible()"
		role="checkbox"
		class="_t-favorite-toggle btn btn-secondary no-background no-border p-0 icon-btn q-icon"
		[ngClass]="value ? 'q-icon-star' : 'q-icon-star-outline'"
		(click)="toggleFavorite()"
		[attr.aria-checked]="!!value"
		[attr.aria-label]="getAriaLabel()"
		[title]="getTitle()">
	</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteRendererComponent implements ICellRendererAngularComp {

	value: boolean;
	private dashboard: Dashboard;
	private params: ICellRendererParams;

	constructor(
		private ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
	) { }

	agInit(params: ICellRendererParams): void {
		this.value = params.value;
		this.dashboard = params.data;
		this.params = params;
	}

	refresh(params: ICellRendererParams): boolean {
		let modified = params.value !== this.value;
		this.agInit(params);
		if (modified) {
			this.updateDashboard();
			this.ref.detectChanges();
		}
		return true;
	}

	isVisible(): boolean {
		return !ObjectListUtils.isFolder(this.dashboard);
	}

	getAriaLabel(): string {
		return this.value
			? this.locale.getString('common.unfavoriteObject', { objectName: this.dashboard.name })
			: this.locale.getString('common.favoriteObject', { objectName: this.dashboard.name });
	}

	getTitle(): string {
		return this.value
			? this.locale.getString('common.selectedAsFavorite', { objectName: this.dashboard.name })
			: this.locale.getString('common.notSelectedAsFavorite', { objectName: this.dashboard.name });
	}

	toggleFavorite(): void {
		this.params.setValue(!this.value);
	}

	updateDashboard(): void {
		this.dashboardService.favoriteDashboard(this.dashboard);
		this.dashboardListService.updateDashboards([this.dashboard]);
	}
}
