import { Inject, Injectable } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WorkspaceScorecardsApiService } from '@app/modules/scorecards/services/workspace-scorecards-api.service';
import { AssetPromise, AssetPromisePlatformProject, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';

@Injectable({
	providedIn: 'root'
})
export class ScorecardsService {
	constructor(
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		private readonly workspaceScorecardsApiService: WorkspaceScorecardsApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	getScorecards(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<Scorecard[]> {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]) as AssetPromise<Scorecard[]>;
		}
		return ProjectAssetsHelper.getAssetsForProject<Scorecard[]>(
			project,
			(accountProject) => this.scorecardsApiService.getScorecards(
				accountProject.contentProviderId, accountProject.accountId, accountProject.projectId,
				undefined, cache) as AssetPromisePlatformProject<Scorecard[]>,
			(workspaceProject) => this.workspaceScorecardsApiService.getScorecards(workspaceProject, cache),
			(projectId) => []
		);
	}
}
