
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';

export interface ICasePriorityType {
	level: number;
	value: CasePriority;
	displayName: string;
	iconColor: string;
	engagorLevel: number;
}

@Injectable({
	providedIn: 'root'
})
export class CasePriorityTypesService {

	priorityTypes: ICasePriorityType[] = [];

	constructor(
		private readonly locale: CxLocaleService
	) {
		this.priorityTypes = [{
			level: 1,
			value: CasePriority.LOW,
			displayName: locale.getString('alertTemplates.priorityLow'),
			iconColor: '#74ba03',
			engagorLevel: 3
		}, {
			level: 2,
			value: CasePriority.MEDIUM,
			displayName: locale.getString('alertTemplates.priorityMedium'),
			iconColor: '#67a0e6',
			engagorLevel: 5
		}, {
			level: 3,
			value: CasePriority.HIGH,
			displayName: locale.getString('alertTemplates.priorityHigh'),
			iconColor: '#f78e17',
			engagorLevel: 7
		}, {
			level: 4,
			value: CasePriority.CRITICAL,
			displayName: locale.getString('alertTemplates.priorityCritical'),
			iconColor: '#ec2227',
			engagorLevel: 10
		}];
	}

	getPriorityTypes = (forTicketing: boolean = false): ICasePriorityType[] => {
		if (!forTicketing) {
			return this.priorityTypes;
		}
		return this.priorityTypes.slice(0, 3);
	}

	getPriorityByValue = (value: string): ICasePriorityType => {
		if (!value)
			return undefined;
		return _.find(this.priorityTypes, type => type.value.toLowerCase() === value.toLowerCase());
	}

	getPriorityByLevel = (priorityLevel: number): ICasePriorityType => {
		return _.findWhere(this.priorityTypes, {level: priorityLevel});
	}

	getPriorityByEngagorLevel = (priorityLevel: number): ICasePriorityType => {
		return _.findWhere(this.priorityTypes, {engagorLevel: priorityLevel});
	}

}

app.service('casePriorityTypes', downgradeInjectable(CasePriorityTypesService));

