<div class="d-flex flex-multiline l-margin-offset-6 stack-medium-and-smaller" *ngIf="loadingPromise || staticData.ready">
	<div class="flex-fill min-width-2 max-width-3">
		<div class="d-flex flex-direction-column">
			<label>{{'widget.dateRange' | i18n}}</label>
			<search-list [dropdown]="true" disabled
				class="block w-100-percent"
				selectPrompt="{{'widget.na' | i18n}}"
			></search-list>
		</div>
		<div class="d-flex flex-direction-column mb-16">
			<label>{{'common.view'|i18n}}</label>
			<object-viewer-type-selector
				value="cb_object_viewer"
				(valueChange)="changeView($event)"
			></object-viewer-type-selector>
		</div>
		<div class="d-flex flex-direction-column mb-16 p-relative">
			<label>{{'scorecards.scorecards'|i18n}}</label>
			<search-list [dropdown]="true"
				[listOptions]="options.scorecards"
				[data]="scorecard"
				(onNodeSelection)="onRubricChange($event.node)"
				fullWidth="true"
				matchSize="true"
			></search-list>
			<div [ngBusy]="loadingPromise"></div>
		</div>

		<div class="d-flex flex-direction-column">
			<label>{{'common.backgroundColor'|i18n}}</label>
			<div>
				<background-color
					[(ngModel)]="visualProps.backgroundColor"
					(ngModelChange)="onChange()"></background-color>
			</div>
		</div>
	</div>

	<div class="min-width-3 max-width-4">
		<div class="form-inline middle-panel-header">
			<checkbox-button
				class="_t-report-tooltips-checkbox"
				label="{{'widget.tooltips'|i18n}}"
				[(ngModel)]="visualProps.showTooltips"
				(ngModelChange)="onChange()">
			</checkbox-button>
			<checkbox-button
				class="_t-report-sampleSize-checkbox"
				label="{{'widget.sampleSize'|i18n}}"
				[(ngModel)]="visualProps.showSampleSize"
				(ngModelChange)="onChange()">
			</checkbox-button>
		</div>

		<div *ngIf="showPreview() && staticData.ready"
			id="chart-demo"
			class="d-flex mt-8 p-relative bg-dashboard standard-border w-100-percent h-min-320"
			[ngClass]="{'bg-none': visualProps.backgroundColor === backgroundOptions.NONE}">
			<object-viewer-widget-preview *ngIf="utils"
				class="h-100-percent w-100-percent"
				[visualProperties]="visualProps"
				[sampleData]="staticData"
				[utils]="utils"
				[redrawTrigger]="redrawTrigger">
			</object-viewer-widget-preview>
		</div>
	</div>
</div>
