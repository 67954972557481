import Widget from '@cxstudio/dashboards/widgets/widget';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { AnalyticFeedbackSelectionResult } from '@cxstudio/reports/preview/analytic-feedback-selection-result.interface';
import { CuratedItem, CuratedItemType, CuratedItemTypeUtil } from '@cxstudio/reports/preview/curated-item';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';

export class AnalyticFeedbackSelectionUtils {

	static getDrillFilter(widget: Widget, type: CuratedItemType, filterName: string): DrillFilter {

		let selection = widget.properties.analyticFeedbackSelection;
		if (!selection)
			return;
		let relevantItems = this.getRelevantItems(selection, type);
		if (relevantItems.length === 0)
			return;

		let filterString = this.buildItemsFilter(relevantItems, type, selection.excluding);
		return this.buildDrillFilter(filterName, filterString);
	}

	static getCuratedItemType(widget: PreviewWidget): CuratedItemType {
		return CuratedItemTypeUtil.fromPreviewMode(this.getPreviewMode(widget));
	}

	private static getRelevantItems(selection: AnalyticFeedbackSelectionResult, type: CuratedItemType): CuratedItem[] {
		return selection.items.filter(item => item.type === type);
	}

	private static buildItemsFilter(items: CuratedItem[], itemType: CuratedItemType, excluding: boolean): string {
		let filter = items
			.map(item => this.buildItemFilter(item, itemType))
			.reduce((left, right) => `${left} OR ${right}`);

		if (excluding) filter = `NOT (${filter})`;

		return filter;
	}

	private static buildItemFilter(item: CuratedItem, type: CuratedItemType): string {
		if (type === CuratedItemType.DOCUMENT) {
			return `(_id_document:${item.documentId})`;
		} else if (type === CuratedItemType.VERBATIM) {
			return `(_id_verbatim:${item.verbatimId})`;
		} else {
			return `(_id_sentence:${item.sentenceId})`;
		}
	}

	private static buildDrillFilter(filterName: string, filterString: string): DrillFilter {
		return {
			name: 'analytic_feedback_selection_filter',
			type: DrillType.CUSTOM_GROUP,
			values: [
				filterName,
				filterString
			]
		};
	}

	private static getPreviewMode(widget: PreviewWidget): PreviewMode {
		if (widget.properties.previewMode === PreviewMode.DOCUMENT) {
			return widget.visualProperties.preferences	&& widget.visualProperties.preferences.settings.singleVerbatimMode
				? PreviewMode.VERBATIM
				: PreviewMode.DOCUMENT;
		}
		return widget.properties.previewMode;
	}
}
