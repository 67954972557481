<div class="col-sm-4 d-flex align-items-center">
	<select-from-tree class="flex-fill"
		[ngModel]="lane.grouping?.name"
		[hierarchyList]="options"
		(onNodeClick)="onGroupingChange($event.node)">
	</select-from-tree>
	<button
		type="button"
		*ngIf="canChangeInclusionList()"
		data-testid="attr-configure"
		class="btn btn-icon"
		(click)="configureMetric()"
		title="{{'widget.cog'|i18n}}">
		<span class="q-icon q-icon-cog" [attr.aria-label]="'common.options'|i18n"></span>
	</button>
</div>
<div class="col-sm-4 d-flex">
	<simple-dropdown class="flex-fill"
		[options]="laneStyles"
		[disabled]="!canChangeLaneStyle()"
		[(value)]="lane.definition.type">
	</simple-dropdown>
	<simple-dropdown
		*ngIf="lane.definition.type === 'icon'"
		class="font-icons ml-8"
		[disabled]="!canChangeIcon()"
		[options]="laneIcons"
		[(value)]="lane.definition.icon">
	</simple-dropdown>
</div>
<ng-container [ngSwitch]="type" *ngIf="lane.definition.type === 'icon'">
	<color-band-selector *ngSwitchCase="'metric'"
		class="col-sm-4"
		[disabled]="true"
		[metric]="getPredefinedMetric()"
		[(selectedValue)]="lane.definition.bandColor">
	</color-band-selector>
	<div *ngSwitchCase="'attribute'"
		class="col-sm-4 d-flex">
		<color-dropdown class="h-24 w-24"
			[(ngModel)]="lane.definition.customColor">
		</color-dropdown>
	</div>
</ng-container>
