<div class="pills-container w-100-percent d-flex flex-wrap" [ngClass]="{'collapsed': collapsed}">
	<ng-container *ngFor="let pill of _pills | slice:0:(collapsed ? lastVisiblePill + 1 : undefined)">
		<sentence-pill *ngIf="pill.type === PillType.TOPIC"
			(pillClick)="tunePill(pill, $event)"
			[pill]="pill"
			[classes]="getClasses(pill)"
			[inert]="inert"
			[removable]="isTuningAllowed(pill)"
		></sentence-pill>
		<context-pill *ngIf="pill.type === PillType.ATTRIBUTE"
			(pillClick)="clickHandler(pill, $event)"
			(pillMouseOver)="mouseoverHandler(pill)"
			(pillMouseOut)="mouseoutHandler(pill)"
			[pill]="pill"
			[classes]="getClasses(pill)"
			[inert]="inert"
		></context-pill>
		<enrichment-pill *ngIf="pill.type !== PillType.ATTRIBUTE && pill.type !== PillType.TOPIC"
			[pill]="pill"
			(pillClick)="tunePill(pill, $event)"
			[removable]="isTuningAllowed(pill)"
			[classes]="getClasses(pill)"
			[useContrastTextColor]="useContrastTextColor">
		</enrichment-pill>
	</ng-container>
</div>

<a *ngIf="showToggle"
	href="javascript:void(0);"
	(click)="toggle($event)"
	[attr.tabindex]="inert ? -1 : null"
	[attr.aria-label]="getToggleAriaLabel()"
	class="d-flex pills-toggle flex-direction-row align-items-center no-wrap flex-nowrap">
	<hr class="w-100-percent mr-8">{{getToggleText()}}
	<span *ngIf="collapsed" class="pl-8 q-icon q-icon-angle-right" aria-hidden="true"></span>
	<span *ngIf="!collapsed" class="pl-8 q-icon q-icon-angle-top" aria-hidden="true"></span>
	<hr class="w-100-percent ml-8">
</a>
