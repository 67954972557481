import { ScorecardRebuttedNode } from '@cxstudio/projects/scorecards/entities/scorecard-rebutted-document';

export class ScorecardRebuttedNodeCollector {
	nodesForRebuttal: ScorecardRebuttedNode[] = [];

	constructor() {
	}

	addRebuttedNode(node: ScorecardRebuttedNode, parentId: number): void {
		let parentNode = this.findNodeById(parentId);
		if (parentNode) {
			parentNode.children.push(node);
		} else {
			this.nodesForRebuttal.push(node);
		}
	}

	getRebuttedNode(): ScorecardRebuttedNode[] {
		return this.nodesForRebuttal;
	}

	private findNodeById(nodeId: number): ScorecardRebuttedNode | null {
		for (let node of this.nodesForRebuttal) {
			const foundNode = this.findNode(nodeId, node);
			if (foundNode !== null) {
				return foundNode;
			}
		}
	
		return null;
	}

	private findNode(nodeId: number, node: ScorecardRebuttedNode): ScorecardRebuttedNode | null {
		if (nodeId === node.nodeId) {
			return node;
		}

		for (let childNode of node.children) {
			const foundNode = this.findNode(nodeId, childNode);
			if (foundNode !== null) {
				return foundNode;
			}
		}

		return null;
	}
}