import { LicenseType } from '@cxstudio/common/license-types';

export class User {
	userId?: number;
	userEmail?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	displayUsername?: string;
	currentMasterAccountId?: number;
	defaultMasterAccountId?: number;
	defaultMasterAccountName?: string;
	tags?: string[];
	systemAdmin?: boolean;
	liteAdmin?: boolean;
	customerAdmin?: boolean;
	masterAccountData?: any[];
	licenseTypeId?: LicenseType;
	licenseTypeName?: string;
	accessExpirationState?: string;
	createdTime?: Date | string;
	countryCode?: string;
	xmAccountId?: string;
	xmGlobalUserId?: string;
	customField?: string;
}
