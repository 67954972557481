<nav [attr.aria-label]="'common.breadcrumbs' | i18n">
	<ol class="list-unstyled m-0 d-flex-inline align-items-center">
		<ng-container *ngFor="let crumb of breadcrumbs; last as isLast">
			<li [attr.aria-current]="isLast ? 'page' : null">
				<ng-container *ngIf="!isLast && crumb.href; else elseBlock">
					<a [href]="getCrumbLocation(crumb)">{{crumb.display}}</a>
				</ng-container>
				<ng-template #elseBlock>
					{{crumb.display}}
				</ng-template>
			</li>
			<li *ngIf="!isLast" class="mh-4" aria-hidden="true"><icon name="angle-right"></icon></li>
		</ng-container>
	</ol>
</nav>
