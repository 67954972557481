import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import * as _ from 'underscore';
import { CxLocaleService } from '../cx-locale.service';

export enum PermissionType {
	OWN = 'OWN',
	EDIT = 'EDIT',
	VIEW = 'VIEW',
	REMOVE = 'REMOVE',
}

export interface IPermission {
	id: PermissionType;
	name: string;
}

type PermissionMapping = {[key in PermissionType]?: string};

@Injectable({
	providedIn: 'root'
})
export class AllPermissionsService {
	OWN: IPermission;
	EDIT: IPermission;
	VIEW: IPermission;
	REMOVE: IPermission;

	constructor(
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
	) {
		this.OWN = {id: PermissionType.OWN, name: this.locale.getString('common.owner')};
		this.EDIT = {id: PermissionType.EDIT, name: this.locale.getString('dashboard.canEdit')};
		this.VIEW = {id: PermissionType.VIEW, name: this.locale.getString('dashboard.canView')};
		this.REMOVE = {id: PermissionType.REMOVE, name: this.locale.getString('common.removeAccess')};
	}

	getPermissions(permissionsToCheck: PermissionMapping = {}, allowRevoke?: boolean): IPermission[] {
		permissionsToCheck.VIEW = permissionsToCheck.VIEW || 'share_view';
		permissionsToCheck.EDIT = permissionsToCheck.EDIT || 'share_edit';
		permissionsToCheck.REMOVE = permissionsToCheck.REMOVE || 'share_edit';

		let permissions = [];

		if (this.security.has(permissionsToCheck.VIEW)) {
			permissions.push(this.VIEW);
		}

		if (this.security.has(permissionsToCheck.EDIT)) {
			permissions.push(this.EDIT);
		}

		if (allowRevoke && this.security.has(permissionsToCheck.REMOVE)) {
			permissions.push(this.REMOVE);
		}

		return permissions;
	}

}

app.service('allPermissions', downgradeInjectable(AllPermissionsService));

