import { SlickHeaderUtils } from '@app/modules/item-grid/slick-header-utils.class';
import { Directive, HostBinding } from '@angular/core';

/*
 * Has AngularJS twin
 */
@Directive({
	selector: '[sticky-slick-header]'
})
export class StickySlickHeaderDirective {
	@HostBinding('class.pin-slick-header') get pinSlickHeaderClass(): boolean { return SlickHeaderUtils.pinSlickHeader(); }
}
