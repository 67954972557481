import { AttributeGrouping } from './attribute-grouping';

export class TopicReportGrouping extends AttributeGrouping {
	static readonly LEAF_LEVEL = Number.MAX_SHORT_INTEGER;
	selectedLevel?: number;
	showLevel?: boolean;
	subtopicsOnly?: boolean;
	selectedLevels?: number[];
	levels?: any[];
	inheritTopics?: boolean;
	selectedNodes?: any[];
	fullInclusion?: boolean;
	updateOnParentFilter?: boolean;
	filterNodes?: number[][];

}
