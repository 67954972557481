import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { IDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import * as Highcharts from 'highcharts';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { MetricBoundsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/metric-bounds-utils.service';
import { GaugeBackgroundUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/gauge-background-utils.class';
import { GaugeColorPaletteHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/gauge-color-palette-helper.class';
import { MetricWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/metric-widget-visual-properties.class';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { GaugeColorMode } from '@cxstudio/reports/entities/gauge-color-mode.enum';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { MetricDisplayValue, MetricWidgetRenderingService } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';
import { GaugeDefaults } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-defaults';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

export interface ComparisonData {
	value: MetricDisplayValue;
	difference: string;
	label: string;
	labelClass?: string;
	colorClass?: string;
	directionSymbol?: string;
}
export interface IMetricData {
	displayValue: MetricDisplayValue;
	label: string;
	comparisons: ComparisonData[];
}
@Injectable({
	providedIn: 'root'
})
export class HighchartsGaugeDefinitionService {
	public static readonly GAUGE_INNER_RADIUS_PERCENT = 0.75;
	public static readonly CHART_SPACING = 10;

	constructor(
		private readonly highchartsUtils: HighchartsUtilsService,
		private readonly metricBoundsUtils: MetricBoundsUtilsService,
		private readonly locale: CxLocaleService,
		private readonly metricWidgetRendering: MetricWidgetRenderingService,
		@Inject('periodOverPeriodMetricService') private readonly periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		@Inject('calculationColorService') private readonly calculationColorService: CalculationColorService,
		@Inject('CalculationColorType') private readonly calculationColorType,
		@Inject('metricBandsService') private readonly metricBandsService,
		private readonly applicationThemeService: ApplicationThemeService,
		@Inject('colorPalettes') private readonly colorPalettes: ColorPalettes
	) { }

	getChartOptions(
		dataObject: IDataObject<any>,
		props: MetricWidgetProperties,
		visualProps: MetricWidgetVisualProperties,
		utils: WidgetUtils,
		renderCallback: () => void,
	) {
		const metricName = props.selectedMetrics[0].name;
		const value = dataObject.data[0][metricName];

		const IS_DARK_MODE: boolean = this.applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER);

		const paletteHelper = new GaugeColorPaletteHelper(this.calculationColorService, this.calculationColorType,
				this.metricBandsService, this.applicationThemeService.isShowingDashboardDarkTheme())
			.withMetrics(utils.metrics)
			.withPalettes(utils.palettesHelper);
		const bounds = this.metricBoundsUtils.getMetricBounds(props, visualProps, value);
		let calculation = paletteHelper.getMetricCalculation(visualProps, bounds) as any;
		let calculationName = paletteHelper.getMetricAttributeName(visualProps);

		const dial = this.getDial(visualProps, value, IS_DARK_MODE);

		let backgroundColor;
		let stops;
		let plotBands;
		let seriesType = 'gauge';

		if (this.isDynamicColorMode(visualProps)) {
			if (this.colorPalettes.isPaletteColor(visualProps.color)) {
				// just use the first color
				calculation.colorPalette = [calculation.colorPalette[0]];
			}
			backgroundColor = ColorConstants.WHITE;
			stops = GaugeBackgroundUtils.getDynamicStops(calculation, calculationName, value, utils.colorFunction);
			plotBands = [];
		} else {
			plotBands = GaugeBackgroundUtils.getPlotBands(calculation, bounds, calculationName, utils.colorFunction);
		}

		if (this.isDynamicColorMode(visualProps) || isNaN(value)) {
			// required to fully hide dial
			seriesType = undefined;
		}

		const labelFontSize = `${GaugeDefaults.labelFontSize}px`;
		const labelPosition = GaugeDefaults.getRangeLabelPosition();

		return Highcharts.merge(this.highchartsUtils.getDefaultConfig(), {
			accessibility: {
				enabled: false
			},
			chart: {
				type: 'solidgauge',
				spacing: [0, HighchartsGaugeDefinitionService.CHART_SPACING, 0, HighchartsGaugeDefinitionService.CHART_SPACING],
			},
			title: null,
			pane: {
				center: ['50%', '50%'],
				size: '100%',
				startAngle: -90,
				endAngle: 90,
				background: {
					backgroundColor,
					innerRadius: `${HighchartsGaugeDefinitionService.GAUGE_INNER_RADIUS_PERCENT * 100}%`,
					outerRadius: '100%',
					shape: 'arc',
					borderWidth: IS_DARK_MODE ? 2 : 1,
					borderColor: ColorConstants.GRAY_300
				},
			},
			tooltip: {
				enabled: false
			},
			// the value axis
			yAxis: {
				lineWidth: 0,
				tickWidth: 0,
				minorTickInterval: null,
				tickAmount: 0,
				title: {
					enabled: false
				},
				labels: {
					enabled: true,
					y: labelPosition,
					distance: '87.5%',
					align: 'center',
					style: {
						fontSize: labelFontSize,
						fontWeight: 'bold',
						color: IS_DARK_MODE ? ColorConstants.WHITE : ColorConstants.CHARCOAL,
					},
					formatter: HighchartsClosureUtils.closureWrapper((scope) => {
						return utils.dataFormatter ? utils.dataFormatter(scope.value) : scope.value;
					})
				},
				min: bounds.min,
				max: bounds.max,
				tickPositioner: () => {
					return [bounds.min, bounds.max];
				},
				plotBands,
				stops
			},
			plotOptions: {
				solidgauge: { },
				series: {
					events: {
						afterAnimate: HighchartsClosureUtils.closureWrapper((scope) => {
							this.highchartsUtils.handleRenderedEvent(scope, utils);
							renderCallback();
						})
					}
				}
			},
			series: [{
				name: '',
				data: [value],
				dial,
				type: seriesType,
				pivot: {
					radius: 0,
				},
				dataLabels: {
					enabled: false,
				},
				innerRadius: `${HighchartsGaugeDefinitionService.GAUGE_INNER_RADIUS_PERCENT * 100}%`
			}]
		});
	}

	isDynamicColorMode(visualProps: MetricWidgetVisualProperties): boolean {
		return visualProps.colorMode === GaugeColorMode.DYNAMIC;
	}

	getDial(visualProps: MetricWidgetVisualProperties, value: number,
		isDarkMode: boolean = false): Highcharts.PlotGaugeDialOptions | undefined  {
		if (!this.isDynamicColorMode(visualProps) && !isNaN(value)) {
			return {
				baseLength: '100%',
				radius: '70%',
				rearLength: '-150%',
				baseWidth: 8,
				backgroundColor: ColorConstants.CHARCOAL,
				borderWidth: isDarkMode ? 1 : 0,
				borderColor: ColorConstants.WHITE // won't show in light mode due to borderWidth 0
			};
		} else {
			return;
		}

	}

	getData(dataObject: IDataObject<any>, props: MetricWidgetProperties, visualProps: MetricWidgetVisualProperties,
			utils: WidgetUtils): IMetricData {
		const metricName = props.selectedMetrics[0].name;
		const value = dataObject.data[0][metricName];
		const displayValue = this.metricWidgetRendering.getMainMetricDisplayValue(
			value, props.selectedMetrics[0], utils?.metrics);
		const label = this.periodOverPeriodMetricService.getPeriodLabel(1, visualProps, props, utils?.containerId)
			|| this.locale.getString('widget.current_period');
		const comparisons = this.getComparisons(dataObject, props, utils);
		return {
			displayValue,
			label,
			comparisons,
		};
	}

	private getComparisons(dataObject: IDataObject<any>, props: MetricWidgetProperties,
			utils: WidgetUtils): ComparisonData[] {
		return _.map(props.comparisons, comparison => this.getComparisonData(dataObject.data[0], comparison,
			props, utils)) || [];
	}

	private getComparisonData(dataRow: any, comparison: MetricWidgetComparison,
			props: MetricWidgetProperties, utils: WidgetUtils): ComparisonData {
		const label = this.metricWidgetRendering.getComparisonLabel(comparison, utils);
		const metric = props.selectedMetrics[0] as CalculationWithFormat;
		const metricName = metric.name;
		const currentvalue = dataRow[metricName];
		const comparisonValue = dataRow[`${metricName}_${comparison.identifier}_value`];
		const value = this.metricWidgetRendering.getComparisonMetricDisplayValue(
			comparisonValue, metric as CalculationWithFormat, comparison, utils.metrics);
		const difference = this.metricWidgetRendering.getComparisonDiffString(dataRow, metric, comparison, utils);
		const directionSymbol = this.metricWidgetRendering.getComparisonDirectionSymbol(comparison.calculation,
			currentvalue, comparisonValue);
		const colorClass = this.metricWidgetRendering.getDirectionalColorClass([metric],
			currentvalue, comparisonValue);
		return {
			label,
			value,
			difference,
			directionSymbol,
			colorClass,
		};
	}
}
