import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ObjectUtils } from '@app/util/object-utils';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { NameService } from '@cxstudio/common/name-service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { CATALOG_METRICS } from './catalog-metrics';
import { RunPackageEntry, RunPackageInput, RunPackageModalComponent } from '../../run-package-modal/run-package-modal.component';
import { RunnablePackage } from '../runnable-package.interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { PromiseUtils } from '@app/util/promise-utils';
import { DASHBOARD_PROPS, DASHBOARD_WIDGET } from './catalog-dashboard';

@Injectable()
export class MetricCreationPackage implements RunnablePackage {
	name: string;
	description: string;

	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private metricsService: MetricsService,
		@Inject('dashboardTemplatesApiService') private dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
		@Inject('nameService') private readonly nameService: NameService,
		@Inject('globalNotificationService') readonly globalNotificationService: GlobalNotificationService,
	) {
		this.name = this.locale.getString('packages.metricCreation');
		this.description = this.locale.getString('packages.metricCreationDescription');
	}

	runPackage(): Promise<void> {
		let input : RunPackageInput = {
			package: this,
			dashboard: {
				properties: {
					sampling: 'SAMPLE',
					viewOnlyDrill: true,
					defaultShowTotal: true,
					cbContentProvider: -1,
					cbAccount: -1,
					project: -1,
					customColor: '',
					color: '',
				},
				type: DashboardType.DASHBOARD,
				name: '',
				description: '',
				labels: [],
				appliedFiltersArray: []
			} as unknown as Dashboard
		};
		return this.cxDialogService.openDialog(RunPackageModalComponent, input).result
			.then((entry) => this.createMetricsAndDashboardForPackage(entry))
			.then((response) => {
				this.globalNotificationService
					.addSuccessNotification(this.locale.getString('packages.notificationDashboardCreated', { name: response.data.name }));
			}).catch(_.noop);
	}

	private createMetricsAndDashboardForPackage(entry: RunPackageEntry) {
		let metricPackageEntry = ObjectUtils.copy(CATALOG_METRICS) as any[];
		let projectData = entry.projectProperties;
		return this.metricsService.getMetrics(projectData).then((response)=> {
			let metricList = response as Metric[];
			metricPackageEntry.forEach((metric) => {
				let metricBaseName = this.getMetricBaseName (metric.definition.type);
				metric.displayName = this.nameService.uniqueName(metricBaseName, metricList, 'displayName');
				metric.accountId = projectData.accountId;
				metric.contentProviderId = projectData.contentProviderId;
				metric.projectId = projectData.projectId;
				metric.projectName = projectData.projectName;
			});
			let metricsPromise = metricPackageEntry.map(metric => PromiseUtils.wrap(this.metricApiService.createMetric(metric)));
			return Promise.all(metricsPromise).then((data) => {
				return this.createDashboardWithMetricWidgets(data, entry);
			});
		});
	}

	private getMetricBaseName(type: MetricType) {
		switch (type) {
			case MetricType.TOP_BOX:
				return 'PS test - top box';
			case MetricType.BOTTOM_BOX:
				return 'PS test - bottom box';
			case MetricType.SATISFACTION:
				return 'PS test - satisfaction';
			case MetricType.VARIABLE:
				return 'PS test - value';
			case MetricType.FILTER:
				return 'PS test - filtered';
			case MetricType.CUSTOM_MATH:
				return 'PS test - custom';
			default:
				return 'New Metric';
		}
	}

	private createDashboardWithMetricWidgets(metrics: Metric[], entry: RunPackageEntry): Promise<any> {
		let widgets = [];
		let x = 0;
		metrics.forEach((metric) => {
			let widget = ObjectUtils.copy(DASHBOARD_WIDGET);
			widget.properties.workspaceProject = metric.workspaceProject;
			widget.properties.selectedMetrics.push(metric);
			widget.displayName = `Metric : ${metric.displayName}`; //'Metric : '+ metric.displayName;
			widget.properties.altText = widget.displayName;
			widget.posX = x;
			x = x+4;
			widgets.push(widget);
		});
		let dashboardProps = ObjectUtils.copy(DASHBOARD_PROPS);
		dashboardProps.properties.defaultShowTotal = entry.defaultShowTotal;
		dashboardProps.properties.cbAccount = entry.projectProperties.accountId;
		dashboardProps.properties.cbContentProvider = entry.projectProperties.contentProviderId;
		dashboardProps.properties.project = entry.projectProperties.projectId;
		dashboardProps.name = entry.name;
		return PromiseUtils.wrap(this.dashboardTemplatesApiService.createDashboardFromTemplate({
			templateId: 1000,
			dashboard: dashboardProps,
			widgets
		}));
	}
}
