import * as _ from 'underscore';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { MultiselectUtils } from '@app/modules/filter-builder/attribute/multiselect/multiselect-utils';

export class MultiselectOptionsController implements ng.IController {

	itemDisabled: (item: AttributeValueOption) => boolean;
	allOptions: AttributeValueOption[];
	filterText: string;
	isPagingSearch: boolean;
	showAttributeName: boolean;
	onClick: (params: {$item: AttributeValueOption, $itemType: string}) => void;
	optionsId: string;
	readonly PREFIX = 'item_';
	readonly PREFIX_SELECTED = 'selected_';

	constructor() {}

	$onInit(): void {

	}

	getTooltip = (item: AttributeValueOption): string => {
		let prefix = this.showAttributeName ? item.attributeDisplayName + ': ' : '';
		return prefix + item.displayName;
	}

	selectedOptionsFilter = (item) => MultiselectUtils.optionSelected(item);

	unselectedOptionsFilter = (item) => MultiselectUtils.optionNotSelected(item);

	highlightText = (displayName: string, attributeDisplayName?: string) => {
		if (this.isPagingSearch) {
			let prefix = '';
			if (attributeDisplayName && displayName.startsWith(attributeDisplayName)) {
				prefix = `${attributeDisplayName}: `;
			}
			let value = displayName.startsWith(prefix) ? displayName.substring(prefix.length) : displayName;
			if (value.toLowerCase().startsWith(this.filterText.toLowerCase())) {
				return `${prefix}<b>${value.substring(0, this.filterText.length)}</b>${value.substring(this.filterText.length)}`;
			} else {
				return displayName;
			}
		} else {
			return displayName;
		}
	}

	onItemClick = (item: AttributeValueOption, itemType: string) => {
		this.onClick({$item: item, $itemType: itemType});
	}

	onItemKeydown = (event: any, item: AttributeValueOption, itemType: string): void => {
		let selected = itemType === 'selected';
		let prefix = selected ? this.PREFIX_SELECTED : this.PREFIX;
		let id = document.activeElement.id;
		let index = parseInt(id.substring(prefix.length), 10);
		let direction;

		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE)) {
			this.onItemClick(item, itemType);
			setTimeout(() => {
				let nextItem = $(`#${this.optionsId} #${id}:visible`);
				if (nextItem.length > 0) {
					nextItem.trigger('focus');
				} else {
					if (index > 0) {
						$(`#${this.optionsId} #${prefix + (index - 1)}`).trigger('focus');
					} else {
						$(`#${this.optionsId} #${selected ? this.PREFIX : this.PREFIX_SELECTED}0`).trigger('focus');
					}
				}
			});
		}

		if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			direction = 1;
		}
		if (KeyboardUtils.isEventKey(event, Key.UP)) {
			direction = -1;
		}
		if (direction !== undefined) {
			event.preventDefault();
			let nextItem = $(`#${this.optionsId} #${prefix + (index + direction)}:visible`);
			if (nextItem.length > 0) {
				nextItem.trigger('focus');
			} else {
				if (selected && direction > 0) {
					$(`#${this.optionsId} #${this.PREFIX}0`).trigger('focus');
				}
				if (!selected && direction < 0) {
					let lastSelected = _.filter(this.allOptions, this.selectedOptionsFilter).length - 1;
					$(`#${this.optionsId} #${this.PREFIX_SELECTED + lastSelected}`).trigger('focus');
				}
			}
		}
	}
}

app.component('oldMultiselectOptions', {
	controller: MultiselectOptionsController,
	templateUrl: 'partials/components/multiselect/old-multiselect-options.component.html',
	bindings: {
		itemDisabled: '<',
		allOptions: '<',
		filterText: '<',
		isPagingSearch: '<',
		showAttributeName: '<',
		onClick: '&',
		optionsId: '@id'
	}
});
