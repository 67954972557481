import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackConfig } from './secondary-track-config.class';

export class EmoticonGenerator {
	private static readonly FONT_SIZE = 10;
	private static readonly ICON_REAL_RADIUS = EmoticonGenerator.FONT_SIZE / 2 + 1; // for 10 it's ~6px

	static draw(dataArray: ConversationDataPoint[], shapeConfig: SecondaryTrackConfig, targetSvg): void {
		const centerX: number = shapeConfig.leftBoundary + (shapeConfig.trackWidth / 2);

		targetSvg.selectAll('g')
			.data(dataArray)
			.enter()
			.append('g')
			.attr('class', shapeConfig.getPointClass)
			.attr('transform', data => {
				let x = centerX - this.ICON_REAL_RADIUS;
				return `translate(${x},${data.yPosition + data.height})`;
			})
			.on('mouseover', shapeConfig.mouseover)
			.on('mouseout', shapeConfig.mouseout)
			.on('click', shapeConfig.click)
			.selectAll('text')
			.data(data => this.getEmoticons(shapeConfig.enrichment.getValue(data)))
			.enter()
			.append('text')
			.html((emoticon: string) => emoticon)
			.style('font-family', 'var(--qualtrics-icons)')
			.attr('font-size', this.FONT_SIZE)
			.style('stroke-width', 0.5);
	}

	private static getEmoticons(emoticons: string[]): string[] {
		return [_.first(emoticons)]; // for now only a single icon
	}

}
