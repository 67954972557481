<div class="cx-date-point-selector">

	<search-list
		class="cx-date-point-type pull-left"
		displayField="displayName"
		optionValueField="value"
		[dropdown]="true"
		[hidden]="selectorHidden"
		[disableSearch]="true"
		[data]="getSelectedMode()"
		[listOptions]="modesArray"
		(onNodeSelection)="selectMode($event.node)"
		[appendToBody]="appendToBody">
	</search-list>

	<button
		#pickerToggle
		class="btn btn-secondary filter-date-button"
		type="button"
		[name]="popupId"
		[hidden]="!isNeedCalendar()"
		title="{{customDateLabel.tooltipText}}"
		(click)="showCalendarPopup($event)"
		(keydown.enter)="focusPicker()"
		[disabled]="disableDate">
		<i class="q-icon-calendar"></i> {{customDateLabel.displayName}}
	</button>

	<div class="cx-date-point-controls"
		[hidden]="isNeedCalendar() || pointMode == 'today'">
		<input type="number"
			#amountInput
			style="width: 65px;"
			class="period-amount mr-4"
			[ngModel]="pointValue.amount"
			(ngModelChange)="onAmountChange($event)"
			(blur)="onAmountChange(null, true)">
		<search-list
			class="period-dropdown"
			displayField="displayName"
			optionValueField="value"
			[dropdown]="true"
			[disableSearch]="true"
			[data]="getSelectedPeriod()"
			[listOptions]="periods"
			(onNodeSelection)="selectPeriod($event.node)"
			[appendToBody]="appendToBody">
		</search-list>
	</div>

	<span class="filter-text-piece" [hidden]="isNeedCalendar() || pointMode == 'today'">{{'reportFilters.ago'|i18n}}</span>

	<div id="{{popupId}}" class="filter-date-picker"
		*ngIf="isNeedCalendar()"
		[hidden]="!showCalendar"
		(keydown.escape)="closeCalendarPopup(); focusToggle($event);">
		<div class="filter-date-wrap" #popup>
			<label>{{label}}</label>
			<ngb-datepicker
				class="well well-sm m-0 p-0 no-border d-block"
				[(ngModel)]="pickerDate"
				(dateSelect)="dateChange($event)"
				[firstDayOfWeek]=7
				[maxDate]="fromModel(datepickerOptions.maxDate)"
				[minDate]="fromModel(datepickerOptions.minDate)"
				[dayTemplate]="dayTemplate"
			></ngb-datepicker>


			<div class="d-flex align-items-center pull-right">
				<div *ngIf="!hideTime">
					<input type="number" length="2" placeholder="00"
						#hoursInput
						class="filter-time-text"
						[ngModel]="customDate.time.hours"
						(ngModelChange)="hoursChange($event)">
					<input type="number" length="2" placeholder="00"
						#minutesInput
						class="filter-time-text"
						[ngModel]="customDate.time.minutes"
						(ngModelChange)="minutesChange($event)">
					<select class="pt-0 form-control d-inline-block filter-time-selector"
						[(ngModel)]="customDate.time.amPm">
						<option>{{'schedule.am'|i18n}}</option>
						<option>{{'schedule.pm'|i18n}}</option>
					</select>
				</div>

				<button type="button" class="ml-16 btn btn-sm btn-secondary date-picker-today"
					[disabled]="!isTodayEnabled()"
					(click)="setAsToday()">
					{{'reportFilters.today'|i18n}}
				</button><button type="button" class="btn btn-sm btn-primary btn-ok"
					(click)="saveDate()" (keydown.enter)="saveDate(); focusToggle($event);">
					{{'common.ok'|i18n}}
				</button>
			</div>
		</div>
	</div>
</div>

<ng-template #dayTemplate let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
	<div class="date-point-day btn"
		[class.focused]="focused"
		[class.selected]="selected"
		[class.outside]="date.month !== currentMonth"
		[class.disabled]="disabled">
		{{ date.day }}
	</div>
</ng-template>
