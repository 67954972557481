import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import * as _ from 'underscore';


type ISlickFormatter = (row, cell, value, columnDef, dataContext, forExport?: boolean) => string;

export class PredefinedFormatterBuilder {

	constructor(
		private CalculationColorType,
		private $compile: ng.ICompileService
	) {

	}

	getBuilder = (metric: ReportGrouping, builder: ISlickFormatter, field?: string): ISlickFormatter => {
		if (/easeScore/.test(metric.name))
			return this.getBreakdownBuilder(metric, this.CalculationColorType.types.EASE_5, builder, field);
		if (/sentiment/.test(metric.name))
			return this.getSentimentBuilder(metric, builder, field);
		return this.getBreakdownBuilder(metric, this.CalculationColorType.types.EMOTION, builder, field);
	}

	private getSentimentBuilder = (metric: ReportGrouping, builder: ISlickFormatter, field?: string): ISlickFormatter => {
		let colorFunction = this.CalculationColorType.types.SENTIMENT_5.getColorFunction(metric, field);
		let sentimentIconFunction = this.CalculationColorType.types.SENTIMENT_5.getIconFunction(metric, field);
		let titleFunction = this.CalculationColorType.types.SENTIMENT_5.getCategoryFunction(metric, field);
		return (row, cell, value, columnDef, dataContext, forExport?: boolean) => {

			let fillColor = colorFunction(dataContext);
			let icon = sentimentIconFunction(dataContext);
			let title = titleFunction(dataContext);
			let result = builder(row, cell, value, columnDef, dataContext, forExport);

			if (forExport) return result;

			let html = `<div class="slick-align-right align-items-center d-flex">
				<span class="mr-4">${result}</span>
				<span class="icon-highlight icon-highlight-small" style="color:${fillColor}" title="${title.name}">
					<i class="q-icon ${icon}" aria-label="${title.name}"></i>
				</span>
				</div>`;
			let elem = this.$compile(html)({} as any);
			return elem.get(0).outerHTML;
		};
	}

	private getBreakdownBuilder = (metric: ReportGrouping, type, builder: ISlickFormatter, field?: string): ISlickFormatter => {
		let colorFunction = type.getColorFunction(metric, field);
		let iconFunction = type.getIconFunction(metric, field);
		let titleFunction = type.getCategoryFunction(metric, field);

		return (row, cell, value, columnDef, dataContext, forExport?: boolean) => {
			let formattedValue = builder(row, cell, value, columnDef, dataContext, forExport);
			if (forExport) return formattedValue;

			let na = value === 'NaN';
			let wrappedIcon;
			if (na) {
				wrappedIcon = `<span class="icon-highlight icon-highlight-small placeholder" aria-hidden="true"></span>`;
			} else {
				let icon = iconFunction(dataContext);
				let fillColor = colorFunction(dataContext);
				let title = titleFunction(dataContext);
				wrappedIcon = `<span class="icon-highlight icon-highlight-small" title="${title.name}">
					<span class="${icon}" style="color:${fillColor}" aria-label="${title.name}"></span>
				</span>`;
			}


			let html = `<div class="slick-align-right d-flex align-items-center">
				<span class="mr-4">${formattedValue}</span>
				${wrappedIcon}
				</div>`;

			let elem = this.$compile(html)({} as any);
			return elem.get(0).outerHTML;
		};
	}

}

app.service('predefinedFormatterBuilder', PredefinedFormatterBuilder);
