import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { Component, ChangeDetectionStrategy, OnInit, Input, OnChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IHiddenAssetGroupsInfo } from '@app/modules/access-management/groups/hidden-asset-groups-info';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';

@Component({
	selector: 'hidden-group-asset-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div [ngBusy]="gridLoading">
	<item-grid
		class="h-100-percent w-100-percent cx-admin-grid br-grid"
		[gridType]="gridType"
		[treeData]="allItems | filterBy:['type']:resourceType"
		[gridFilter]="searchText"
		[nameField]="gridNameField"
		[gridAutoTooltip]="gridAutoTooltip"
	></item-grid>
</div>`
})
export class HiddenGroupAssetListComponent implements OnInit, OnChanges {
	@Input() project: AccountOrWorkspaceProject;
	@Input() resourceType: string;
	@Input() searchText: string;

	gridType = GridTypes.HIDDEN_GROUP_ASSETS;
	gridNameField = 'displayName';
	gridAutoTooltip = true;
	gridLoading: Promise<any>;

	allItems: IHiddenAssetGroupsInfo[];

	constructor(
		private readonly assetAccessApiService: AssetAccessApiService
	) {}

	ngOnInit(): void {
		this.allItems = [];
	}

	ngOnChanges(changes: SimpleChanges<HiddenGroupAssetListComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.reload();
		}
	}

	reload = (): void => {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project))
			return;
		this.gridLoading = this.assetAccessApiService.getHiddenAssetGroupsInfo(this.project).then((result) => {
			this.allItems = cloneDeep(result);
		});
	}
}

app.directive('hiddenGroupAssetList', downgradeComponent({component: HiddenGroupAssetListComponent}) as angular.IDirectiveFactory);
