<modal-header
	[ngClass]="{'warning-modal warning-400': dialogStyle === warningDialogStyle}"
	(cancel)="cancel()"
	[modalTitle]="header">
</modal-header>
<div class="confirm-body modal-body">
	<div *ngIf="text" [innerHTML]="text"></div>
	<ng-content></ng-content>
</div>
<div class="modal-footer">
	<button
		*ngIf="cancelBtnName"
		id="btn-dialog-cancel"
		type="button"
		class="btn btn-secondary bg-active-gray-1000 text-active-white"
		(click)="cancel()"
	>{{cancelBtnName}}</button>
	<button
		ngbAutoFocus
		*ngIf="okBtnName"
		[disabled]="value === undefined"
		id="btn-dialog-save"
		type="button"
		class="{{getSaveBtnStyle()}}"
		(click)="save()"
	>{{okBtnName}}</button>
</div>
