import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { UrlService } from '@cxstudio/common/url-service.service';

export type Breadcrumb = { display: string; href?: string; };

@Component({
	selector: 'breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
	@Input() breadcrumbs: Breadcrumb[];

	constructor(@Inject('urlService') private readonly urlService: UrlService) {}

	getCrumbLocation(crumb: Breadcrumb): string {
		if (this.urlService.isUrl(crumb.href)) {
			return crumb.href;
		}

		return `#${crumb.href}`;
	}
}
