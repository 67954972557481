import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ChatMessage } from '@cxstudio/reports/document-explorer/chat/chat-message.class';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { Verbatim } from '@cxstudio/reports/entities/verbatim';


export interface IVoiceMetrics {
	totalSecond: number;
	silence?: {
		max: number;
		total: number;
		percent: number;
		sentenceIdBeforeMax: number;
	};
	channels?: {
		agent: {
			percent: number;
		},
		client: { // redundant
			percent: number;
		}
	};
}
export class ConversationDocument extends CbDocument {
	voiceMetrics: IVoiceMetrics;
	isChat?: boolean;
	sentences: ConversationSentence[];
	originalSentences: ConversationSentence[];
	mergedSentences: ConversationSentence[];
	chatMessages?: ChatMessage[];
	verbatims: Verbatim[];

	additionalVerbatim: any[];

	initializedUiSilence?: boolean;

	metadata: any;
}

export interface IDocumentClassificationNode {
	id: number;
	name: string;
	modelId: number;
	isRoot: boolean;
	isLeaf: boolean;
	modelIdPath: number[];
	sentences: number[];
}

export interface IDocumentClassification {
	modelId?: number;
	modelName?: string;
	rootNodeId?: number;
	nodes?: IDocumentClassificationNode[];
	searchString?: string;
}
