import { Pipe, PipeTransform } from '@angular/core';

// approximates angularJS view filter
@Pipe({
	name: 'filter'
})

export class FilterPipe implements PipeTransform {
	transform(items: any[], filterText: string, property: string = 'displayName'): any[] {
		if (!items || items.length < 1) return [];
		if (!filterText || filterText.length < 1 ) return items;

		return items.filter(item => {
			return item[property] && item[property].toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
		});
	}
}