import { PasswordState } from '@app/modules/user/new-password-form/password-state';

export enum PasswordStrength {
	EXCELLENT = 6,
	GOOD = 5,
	FINE = 4,
	ALMOST_FINE = 3,
	WEAK = 2,
	VERY_WEAK = 1,
	NONE = 0
}

export class PasswordStrengthCalculator {
	constructor(
		private readonly requireSeveralSets: boolean,
	) {}

	getStrength(passwordState: PasswordState): PasswordStrength {
		if (this.isExcellent(passwordState)) {
			return PasswordStrength.EXCELLENT;
		} else if (this.isGood(passwordState)) {
			return PasswordStrength.GOOD;
		} else if (this.isFine(passwordState)) {
			return PasswordStrength.FINE;
		} else if (this.isAlmostFine(passwordState)) {
			return PasswordStrength.ALMOST_FINE;
		} else if (this.isWeak(passwordState)) {
			return PasswordStrength.WEAK;
		} else if (this.isVeryWeak(passwordState)) {
			return PasswordStrength.VERY_WEAK;
		} else {
			return PasswordStrength.NONE;
		}
	}

	private isExcellent(passwordState: PasswordState): boolean {
		return passwordState.extraLength && passwordState.numberOfSets === 4;
	}

	private isGood(passwordState: PasswordState): boolean {
		return (passwordState.acceptedLength && passwordState.numberOfSets === 4)
			|| (!this.requireSeveralSets && passwordState.extraLength && passwordState.numberOfSets === 3);
	}

	private isFine(passwordState: PasswordState): boolean {
		return (this.requireSeveralSets && passwordState.acceptedLength && passwordState.numberOfSets === 3)
			|| (!this.requireSeveralSets && passwordState.acceptedLength && passwordState.numberOfSets >= 1);
	}

	private isAlmostFine(passwordState: PasswordState): boolean {
		return (this.requireSeveralSets && passwordState.acceptedLength && passwordState.numberOfSets === 2)
			|| (!passwordState.acceptedLength && passwordState.numberOfSets >= 3);
	}

	private isWeak(passwordState: PasswordState): boolean {
		return (this.requireSeveralSets && passwordState.acceptedLength && passwordState.numberOfSets === 1)
			|| (!passwordState.acceptedLength && passwordState.numberOfSets === 2);
	}

	private isVeryWeak(passwordState: PasswordState): boolean {
		return !passwordState.acceptedLength && passwordState.numberOfSets === 1;
	}
}
