import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CombinedFiltersService } from '@app/modules/filter/services/combined-filters.service';
import { FilterDependenciesApiService } from './services/filter-dependencies-api-service';
import { FilterDependencyTypesProvider } from './services/filter-dependency-types-provider';
import { SharedModule } from '@app/shared/shared.module';
import { AssetManagementModule } from '../asset-management/asset-management.module';
import ManageFilterService from './services/manage-filter.service';
import FilterGridDefinitionService from './services/filter-grid-definition.service';


@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		AssetManagementModule,
	],
	providers: [
		CombinedFiltersService,
		FilterDependenciesApiService,
		FilterDependencyTypesProvider,
		ManageFilterService,
		FilterGridDefinitionService
	],
})
export class FilterModule { }
