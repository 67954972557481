import MobileAppSettingsApiService from './mobile-app-settings-api.service';
import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import MobileAppSettings from './mobile-app-settings.interface';
import { UibTab } from '@cxstudio/common/uib-tab';
import ILocale from '@cxstudio/interfaces/locale-interface';
import MobileAppSettingsUI from './mobile-app-settings-ui.interface';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';
import Listener from '@cxstudio/common/listener';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from '@cxstudio/mobile/mobile-app-dialog-tab.enum';
import { Security } from '@cxstudio/auth/security-service';
import { MobileSettingsActionService } from './mobile-settings-action.service';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { MobileAppSettingsList } from './mobile-app-settings-list';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

interface MobileAppSettingsDialogScope extends ng.IScope {
	$ctrl: MobileAppSettingsDialogController;
	data: {
		settingsModel: MobileAppSettingsListModel,
		originalSettings: MobileAppSettings,
		settings: MobileAppSettings,
		items: MobileAppSettingsListModel[],
		widgetSettingsCache: CachedInvocation,
		saveListener: Listener;
		errors: Errors;
		settingsList: MobileAppSettingsList,
		promises: MobileAppConfigurationPromises
	};
}

export class MobileAppSettingsDialogController implements ng.IController {

	promises: MobileAppConfigurationPromises = {
		settings: { promise: null },
		projects: { promise: null },
		hierarchies: { promise: null },
		filters: { promise: null },
		savedFilters: { promise: null },
		feedback: { promise: null },
		dateRanges: { promise: null }
	};
	ui: MobileAppSettingsUI = {
		projectSelected: false,
		hasAccessToSelectedProject: true,
		projectsLoaded: false
	};

	settingsModel: MobileAppSettingsListModel;
	settings: MobileAppSettings;
	originalSettings: MobileAppSettings;
	dialogData: any;
	widgetSettingsCache: CachedInvocation;
	activeTabIndex: string;
	tabs: UibTab[];
	settingsList: MobileAppSettingsList;

	saveListener: Listener;
	errors: Errors;

	constructor(
		private $scope: MobileAppSettingsDialogScope,
		private locale: ILocale,
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private mobileUtils: MobileUtils,
		private $q: ng.IQService,
		private security: Security,
		private mobileSettingsActionService: MobileSettingsActionService,
		private $timeout: ng.ITimeoutService,
	) {
		this.$scope.$ctrl = this;
		this.dialogData = this.$scope.data;

		this.saveListener = this.$scope.data.saveListener;
		this.errors = this.$scope.data.errors;

		this.settings = this.$scope.data.settings;
		this.settingsModel = this.$scope.data.settingsModel || this.getCreationSettingsModel(this.settings);
		this.widgetSettingsCache = this.$scope.data.widgetSettingsCache;
		this.settingsList = this.$scope.data.settingsList;

		this.$scope.data.promises = this.promises;

		this.initializeTabs();
		this.loadSettings();
	}

	$onInit = () => {
	}

	onProjectChanged = (projectSelection: IProjectSelection): void => {
		if (!this.mobileUtils.matchesSettings(this.settings, projectSelection)) {
			this.cleanupFilterSelection();
			this.cleanupDocumentItemsSelection();
		}

		if (ProjectIdentifier.isProjectSelected(projectSelection)) {
			this.$scope.$broadcast('settings.projectChanged', projectSelection);
		}
	}

	jumpToErrors = (): void => {
		this.$scope.$broadcast('settings.jumpToErrors');

		for (let tab of this.tabs) {
			if (this.errors.hasScope(tab.index)) {
				this.activeTabIndex = tab.index;
				return;
			}
		}
	}

	canSwitchEnabledToggle = (): boolean => {
		if (this.settings.enabled || this.wasInitiallyEnabled() || this.settings.internal) {
			return true;
		} else {
			return !this.settingsList.regularEnabledAppsLimitReached;
		}
	}

	getToggleTitle = (): string => {
		return this.canSwitchEnabledToggle()
			? this.locale.getString('mobile.enableAppAccessTooltip')
			: this.locale.getString('mobile.errorEnabledSettingsLimitReached');
	}

	private wasInitiallyEnabled = (): boolean => {
		return this.originalSettings && this.originalSettings.enabled;
	}

	onUiCommit = (): void => {
		const canConfigureProject = this.canConfigureProject();

		this.setTabEnabled(MobileAppDialogTab.SCREEN_SETUP, canConfigureProject);
		this.setTabEnabled(MobileAppDialogTab.CUSTOMIZATIONS, canConfigureProject);
	}

	canConfigureProject = (): boolean => {
		return this.ui.projectSelected
			&& this.ui.hasAccessToSelectedProject;
	}

	private cleanupFilterSelection = (): void => {
		this.settings.filterId = null;
		this.settings.customFilter = null;
		this.settings.savedFilter = null;
	}

	private cleanupDocumentItemsSelection = (): void => {
		this.settings.documentViewConfiguration.selectedItems = [];
	}

	private loadSettings = (): void => {
		let loadSettingsPromise = this.isCreatingSettings()
			? this.$q.when(this.settings)
			: this.mobileAppSettingsApiService.getSettings(this.settingsModel.id);

		this.promises.settings.promise = loadSettingsPromise
			.then(settings => {
				this.settings = this.$scope.data.settings = settings;
				if (!this.settings.submitterEmail) {
					this.settings.submitterEmail = this.security.getEmail();
				}
				this.$scope.data.originalSettings = angular.copy(settings);
				this.originalSettings = angular.copy(this.$scope.data.originalSettings);
				this.updateOriginalSettingsWhenLoaded();
			});
	}

	private initializeTabs = (): void => {
		this.activeTabIndex = MobileAppDialogTab.PROPERTIES;
		this.tabs = [
			{ index: MobileAppDialogTab.PROPERTIES,
				heading: this.locale.getString('mobile.propertiesTabHeading'),
				template: 'partials/mobile/edit-dialog/mobile-app-properties-tab.html' },
			{ index: MobileAppDialogTab.USERS,
				heading: this.locale.getString('mobile.appUsersTabHeading'),
				template: 'partials/mobile/edit-dialog/mobile-app-users-tab.html' },
			{ index: MobileAppDialogTab.SCREEN_SETUP,
				heading: this.locale.getString('mobile.screenSetupTabHeading'),
				template: 'partials/mobile/edit-dialog/mobile-app-screen-setup-tab.html' },
			{ index: MobileAppDialogTab.CUSTOMIZATIONS,
				heading: this.locale.getString('mobile.customizationsTabHeading'),
				template: 'partials/mobile/edit-dialog/mobile-app-customizations-tab.html' }
		];
	}

	private updateOriginalSettingsWhenLoaded(): void {
		// This is not very reliable, but better than before.
		// Need to replace this with some kind of "(settings-changed)" event for each tab during conversion,
		// because comparing these settings is very fragile due to changes to data in nested components (which is a bad thing itself)
		// running "waitForPromises" twice because some components start loading assets after first set of components loaded theirs assets
		this.waitForPromises().then(() => this.waitForPromises()).then(() => this.waitForPromises()).then(() => {
			this.$timeout(() => {
				this.$scope.data.originalSettings = angular.copy(this.settings);
				this.originalSettings = angular.copy(this.$scope.data.originalSettings);
			});
		});
	}

	private waitForPromises(): ng.IPromise<any> {
		return this.$timeout().then(() => this.$q.all(
			Object.keys(this.promises).map(type => this.promises[type].promise).filter(promise => !!promise)));
	}

	private setTabEnabled = (index: MobileAppDialogTab, enabled: boolean): void => {
		this.getTab(index).disable = !enabled;
	}

	private getTab = (index: MobileAppDialogTab): UibTab => {
		return this.tabs.filter(tab => tab.index === index)[0];
	}

	isSharingAllowed = (): boolean => {
		return this.isCreatingSettings() || this.mobileSettingsActionService.canShare(this.settingsModel);
	}

	private isCreatingSettings = (): boolean => {
		return !this.settingsModel.id;
	}

	isViewMode = (): boolean => {
		return this.settingsModel && this.settingsModel.accessLevel === AssetPermission.VIEW;
	}

	private getCreationSettingsModel = (settings: MobileAppSettings): MobileAppSettingsListModel => {
		return {
			masterAccountId: settings.masterAccountId,
			name: settings.name,
			enabled: settings.enabled,
			internal: settings.internal,
			defaultForMasterAccount: false,
			submitterEmail: this.security.getEmail(),
			sharingStatus: SharingStatus.PRIVATE,
			accessLevel: AssetPermission.OWN
		};
	}

}

app.controller('MobileAppSettingsDialogCtrl', MobileAppSettingsDialogController);
