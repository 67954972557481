import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import { EnrichmentAttributeSettings } from '@cxstudio/reports/document-explorer/doc-explorer-attribute-settings.interface';

export class ScorecardInDocView {
	id: number;
	name: string;
	modelId: number;
	attributeId: number;
	topics: ScorecardTopicInDocView[];
	score: number;
	expanded: boolean;
	settings?: EnrichmentAttributeSettings;
	rebuttalInProgress?: boolean;
}

export interface ScorecardTopicInDocView {
	id: number;
	name: string;
	nodeId: number;
	classified: boolean;
	path: string;
	idPath: string;
	result: ScorecardTopicResult;
	weight: number;
	autoFail: boolean;
	rebutted: boolean;
	originalResult: ScorecardTopicResult;
}

export interface ScorecardEmitterParameter {
	nodeId: number;
	modelId: number;
}