import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ExternalUrlService {
	private httpPattern = '(http(s?):(\\/){2})\\S*';
	private httpValueRegExp = new RegExp('^' + this.httpPattern + '$');

	public isExternalUrl(allowedDomains: string[], urlToCheck: string): boolean {
		if (urlToCheck.startsWith('mailto:') || urlToCheck.startsWith('javascript:void(0)')) {
			return false;
		}

		let hostname = '';

		try {
			let redirectUrl = new URL(urlToCheck);

			// In older chromium version, patterns like '#' are valid URLs
			if (redirectUrl.origin === 'null') {
				return false;
			}

			if (!redirectUrl.href.match(this.httpValueRegExp)) {
				return false;
			}

			hostname = redirectUrl.hostname.toLowerCase();
		} catch (e) {
			return false;
		}

		if (hostname === location.hostname.toLowerCase()) {
			return false;
		}

		const isFromAllowedDomain = allowedDomains.filter((allowedDomain) => hostname.match(allowedDomain + '$'));

		return isFromAllowedDomain.length === 0;
	}
}
