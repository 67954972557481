
import { ShareComponentsModalController, ShareComponentsDialogParams } from './share-components-modal-component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Security } from '@cxstudio/auth/security-service';
import { SharingService, IShareEntity } from '@cxstudio/sharing/sharing-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SharableComponentGroup } from './sharable-component-group';
import { AssetComponent } from '../asset-component';
import { Book, BookTabType, BookDashboardTab } from '@cxstudio/dashboards/entity/book';
import { Dictionary } from 'underscore';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { BookComponentsSharingUtil } from '@app/modules/asset-management/services/book-components-sharing-util';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';


export interface BookShareComponentsDialogParams extends ShareComponentsDialogParams {
	book: Book;
	dashboards: Dashboard[];
}

export class BookShareComponentsModalController extends ShareComponentsModalController<BookShareComponentsDialogParams> {

	constructor(
		$q: ng.IQService,
		locale: ILocale,
		dashboardComponentsApiService: DashboardComponentsApiService,
		sharingService: SharingService,
		security: Security,
		filterManagementApiService: FilterManagementApiService,
		metricApiService: MetricManagementApiService,
		globalNotificationService: GlobalNotificationService,
		dashboardApiService: DashboardApiService
	) {
		super(
			$q,
			locale,
			dashboardComponentsApiService,
			sharingService,
			security,
			filterManagementApiService,
			metricApiService,
			globalNotificationService,
			dashboardApiService);
	}

	$onInit(): void {
		super.init();
	}

	protected loadComponentGroups(): ng.IPromise<Array<SharableComponentGroup<any>>> {
		let uniqueTabs = _.chain(this.resolve.book.tabs)
			.filter(tab => tab.type === BookTabType.DASHBOARD)
			.uniq(tab => (tab.properties as BookDashboardTab).dashboardId)
			.value();

		let dashboardComponents: AssetComponent[] = BookComponentsSharingUtil.getSharableBookDashboards(this.resolve.dashboards, uniqueTabs)
			.map(dashboard => {
				return {
					id: dashboard.id,
					name: dashboard.name,
					owner: dashboard.ownerName,
					usagesCount: 1
				};
			});

		let dashboardsGroup = new SharableComponentGroup(
			dashboardComponents,
			() => this.locale.getString('administration.transferDashboards'),
			() => this.security.has('share_view'),
			() => this.security.has('share_edit'),
			(components, recipients) => this.shareDashboards(components, recipients));

		return this.$q.when([ dashboardsGroup ]);
	}

	private shareDashboards(dashboardComponents: AssetComponent[], recipients: Dictionary<IShareEntity[]>): any {
		let dashboardIds = dashboardComponents.map(component => component.id);
		return this.dashboardApiService.shareDashboardsBulk(dashboardIds, recipients);
	}

	protected loadSharedEntities(): ng.IPromise<any> {
		return this.dashboardApiService.getDashboardUsersWithRawGroups(Number(this.resolve.asset.assetId));
	}

	protected getPublicStatus(): SharingStatus {
		return this.resolve.book.sharingStatus;
	}

	protected getTitle(): string {
		return this.locale.getString('dashboard.shareComponentsTitle', { dashboardName: this.resolve.asset.name });
	}

	protected getPrompt(): string {
		return this.locale.getString('dashboard.shareComponentsNote', { assetType: this.locale.getString('object.book').toLowerCase() });
	}

}

app.component('bookShareComponentsModal', {
	controller: BookShareComponentsModalController,
	templateUrl: 'partials/asset-management/share-components-modal-component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
