import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'cb-disabled-toggle',
	template:
		`<cb-toggle [(ngModel)]="value" [disabled]="true" [style.opacity]="0.5">
		</cb-toggle>
	`
})
export class DisabledToggleComponent {
	@Input() value = false;
}

app.directive('cbDisabledToggle', downgradeComponent({component: DisabledToggleComponent}) as angular.IDirectiveFactory);
