import { RefinementBehavior } from '@app/modules/reports/refinement-behavior-type';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IgnoredDashboardFilterTag } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-tag';
import { HierarchyDashboardFilter } from '@app/modules/dashboard/dashboard-filter-processors/widget-dashboard-personalization-processor.service';
import { AccountId, ContentProviderId, ProjectId } from '@cxstudio/generic-types';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { PageProperties } from '@cxstudio/reports/entities/page-properties';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { QuickFilter } from '@app/modules/reports/filters/quick-filter.service';
import { AnalyticFeedbackSelectionResult } from '@cxstudio/reports/preview/analytic-feedback-selection-result.interface';
import { ReportCalculation } from '../providers/cb/calculations/report-calculation';
import { RequestSourceType } from '@app/modules/reports/real-data-preview/request-source-type';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';

export enum SearchMode {
	VERBATIM = 'VERBATIM', SENTENCE = 'SENTENCE'
}

export interface SentenceSearch {
	searchMode: SearchMode;
	searchId: number;
}

export interface IWidgetFilterHistory {
	appliedFilters?: { type: FilterTypes, filters: any[] };
	adhocFilter?: AdhocFilter;
	drillFilters?: any[];
	groupingFilters?: {[key: string]: boolean};
	ignoredDashboardFilters?: any[];
	ignoreDashboardTextFilter?: boolean;
	dateRangeP1?: DateFilter;
	dateRangeP2?: DateFilter;
	useHistoricPeriod?: boolean;
	contentProviderId?: ContentProviderId;
	accountId?: AccountId;
	project?: ProjectId;
}

export interface AppliedFilters {
	type: FilterTypes;
	filters: any[];
}

export interface BaseWidgetProperties {
	widgetType?: WidgetType;
	runAs?: string;
}

export interface WidgetProperties extends WidgetProject, BaseWidgetProperties {
	accountId?: AccountId;
	accountName?: string;
	analyticFeedbackSelection?: AnalyticFeedbackSelectionResult;
	appliedFilters?: AppliedFilters;
	adhocFilter?: AdhocFilter;
	audioDocument?: boolean;
	autoDescription?: boolean;
	reportCacheOption?: AnalyticCacheOptions;
	cacheOption?: AnalyticCacheOptions;
	backgroundReport?: boolean;
	contentProviderId?: ContentProviderId;
	contentProviderName?: string;
	dateRangeP1?: DateFilter;
	dateRangeP2?: DateFilter;
	documentId?: number;
	documentExplorer?: boolean;
	drillFilters?: DrillFilter[];
	includeSentenceAttributes?: boolean;
	isCustomTitle?: boolean;
	titleWithDateRange?: boolean;
	previewMode?: PreviewMode;
	project?: ProjectId;
	projectName?: string;
	workspaceProject?: WorkspaceProject;
	quickFilter?: QuickFilter; // TODO move to temporary/transient filters (create new field), as well as filterUi.textFilter
	runAs?: string;
	includeQualtricsEmployees?: boolean;
	selectedAttributes?: AttributeGrouping[];
	primaryTimeGrouping?: AttributeGrouping;
	selectedMetrics?: ReportCalculation[];
	groupingFilters?: {[key: string]: boolean};
	ignoredDashboardFilters?: IgnoredDashboardFilterTag[];
	ignoreDashboardTextFilter?: boolean;
	snippets?: boolean;
	timezoneName?: string;
	timezoneOffset?: number;
	useHistoricPeriod?: boolean;
	favoriteAttributes?: AttributeGrouping[];
	initialSentence?: number; // used for doc exp support
	sentenceSearch?: SentenceSearch; // used for doc exp support
	initialPageNumber?: number; // used for doc exp support
	voiceFolder?: string;
	objectName?: string;
	orgFilters?: HierarchyDashboardFilter[];
	encodedDescriptor?: string;
	altText?: string;
	altTextFromTitle?: boolean;
	requestType?: RequestSourceType;
	refinementBehavior?: RefinementBehavior;
	dashboardSnapshotId?: string;
	reportSnapshotValidation?: boolean;

	dashboardAttributeFilters?: AdhocFilter;

	pageBreakV2?: boolean;

	//for drivers
	lockFilters?: boolean;

	page?: PageProperties;
	itemsPerPage?: number;

	// for network widget
	minCoOccurrence?: number;
	minCoOccurrenceMetric?: string;

	documentLevelOnly?: boolean;
	selectedModels?: number[];
	isCustomSelectorWidget?: boolean;

	homePageId?: number;

	verbatimId?: number; // for expanding partial verbatim
	timeStampMark?: moment.Moment;
	refreshRequired?: boolean;
	exportAttributes?: AttributeGrouping[];
	normalizeGroups?: boolean;

	referrerUrl?: string; // embedded view
}
