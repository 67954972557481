import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';

export interface WidgetConfidentialityWarning {
	enabled: boolean;
	message: string;
}

type GroupingsMapping = Map<string, string[]>;

@Injectable({
	providedIn: 'root'
})
export class WidgetConfidentialityService {
	constructor(
		private locale: CxLocaleService
	) {}

	getConfidentialityWarning(widget: Widget, dataObject: ReportDataObject): WidgetConfidentialityWarning {
		let groupings: ReportGrouping[] = GroupIdentifierHelper.getGroupings(widget.properties.selectedAttributes);
		let mapping: GroupingsMapping = this.getGroupingsMapping(groupings, dataObject);

		return {
			enabled: mapping.size > 0,
			message: this.generateWarningMessage(mapping)
		};
	}

	private generateWarningMessage(mapping: GroupingsMapping): string {
		let allGroupings: string[] = Array.from(mapping.keys());
		let allValues: string[] = _.uniq(_.flatten(Array.from(mapping.values())));

		// If there is only one grouping value hidden
		if (allValues.length === 1) {
			return this.locale.getString('widget.confidentialityWarningSingleValue', { value: allValues[0] });
		}

		// If there is more than one value hidden
		return this.locale.getString('widget.confidentialityWarningMultipleValue', {
			numberOfValues: allValues.length,
			groupings: allGroupings.join(', ')
		});
	}

	private getGroupingsMapping(groupings: ReportGrouping[], dataObject: ReportDataObject): GroupingsMapping {
		let mapping: GroupingsMapping = new Map<string, string[]>();

		for (let grouping of groupings) {
			let groupingDisplayName = grouping.displayName;
			for (let dataItem of dataObject.data) {
				if (dataItem.filteredByConfidentiality) {
					if (!mapping.has(groupingDisplayName)) {
						mapping.set(groupingDisplayName, []);
					}
					mapping.get(groupingDisplayName).push(dataItem[grouping.identifier]);
				}
			}
		}

		return mapping;
	}
}

app.service('widgetConfidentialityService', downgradeInjectable(WidgetConfidentialityService));