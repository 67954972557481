import { AlertAutofillUtils } from '@app/modules/alert/alert-autofill-utils';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { UIOption } from '@clarabridge/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';
import { AlertNotification } from '../entities/alert-notification';
import { StudioAlert } from '../entities/studio-alert';
import { NotificationOption } from '@app/modules/alert/alert-wizard-page/alert-actions-settings/alert-notification-settings/notification-option.enum';

export class AlertNotificationController implements ng.IComponentController {
	ui: { notificationSettingsError: boolean };
	createCase: boolean;
	testAlertCallback: () => void;
	validationCallback: ({$valid}) => void;

	private alert: StudioAlert;

	private linkableDashboards: Dashboard[];
	private messageCustomized: boolean;

	notificationType: NotificationOption;
	notificationForm: ng.IFormController;

	// make notification options available for use in partial
	NotificationOption = NotificationOption;

	notificationTypeOptions: UIOption<NotificationOption>[] = [
		{ value: NotificationOption.IN_APP, displayName: this.locale.getString('alert.notifyInApp'), htmlId: 'notifyInApp' },
		{ value: NotificationOption.EMAIL, displayName: this.locale.getString('alert.notifyByEmail'), htmlId: 'notifyByEmail' },
		{ value: NotificationOption.BOTH, displayName: this.locale.getString('alert.notifyBoth'), htmlId: 'notifyBoth'}
	];

	constructor(
		private dashboardListService: DashboardListService,
		private security: Security,
		private dashboardService,
		private locale: ILocale) { }

	$onInit = () => {
		this.linkableDashboards = [];
		this.dashboardListService.reloadDashboards().then(dashboards => {
			this.linkableDashboards = dashboards.filter(this.filterDashboards);
		});
		this.setDefaults();
		this.setAlertType();
	}

	private setDefaults = () => {
		let notificationDefaults: AlertNotification = {
			inApp: true,
			notifyByEmail: false,
			message: this.getNotificationPlaceholder(),
			emailSubject: this.getEmailSubjectPlaceholder(),
			emailBody: this.getEmailBodyPlaceholder(),
			linkDashboard: null,
			linkDashboardName: ''
		};

		if (this.alert.notification && this.alert.notification.message) {
			this.messageCustomized = true;
		}

		this.alert.notification = angular.extend(notificationDefaults, this.alert.notification);
	}

	setLinkedDashboard = (dashboard: Dashboard): void => {
		this.alert.notification.linkDashboard = dashboard.id;
		this.alert.notification.linkDashboardName = dashboard.name;

		// if message has not been customized, update it to use the
		if (!this.isMessageCustomized()) {
			this.alert.notification.message = this.getNotificationPlaceholder();
			this.alert.notification.emailBody = this.getEmailBodyPlaceholder();
			this.alert.notification.emailSubject = this.getEmailSubjectPlaceholder();
		}
		this.ui.notificationSettingsError = false;
	}

	clearLinkedDashboard = (): void => {
		this.alert.notification.linkDashboard = null;
		this.alert.notification.linkDashboardName = '';
		if (!this.isMessageCustomized()) {
			this.alert.notification.message = this.getNotificationPlaceholder();
			this.alert.notification.emailBody = this.getEmailBodyPlaceholder();
			this.alert.notification.emailSubject = this.getEmailSubjectPlaceholder();
		}
	}

	getLinkedDashboard = (): Dashboard => {
		let existingDashboard = _.find(this.linkableDashboards, { id: this.alert.notification.linkDashboard});
		if (!existingDashboard && this.alert.notification.linkDashboardName) {
			return {
				id: this.alert.notification.linkDashboard,
				name: this.alert.notification.linkDashboardName
			} as Dashboard;
		} else {
			return existingDashboard;
		}
	}

	getAutofillText = (item: {label: string}): string => {
		return `{${item.label}}`;
	}

	getAutofillOptions = () => {
		return AlertAutofillUtils.getAutofillOptions(this.alert);
	}

	private isDashboardSelected = (): boolean => {
		return AlertAutofillUtils.isDashboardSelected(this.alert);
	}

	getNotificationPlaceholder = () => {
		return this.isDashboardSelected()
			? this.locale.getString('alert.defaultMessageWithDashboard')
			: this.locale.getString('alert.defaultMessage');
	}

	getEmailSubjectPlaceholder = () => {
		return this.locale.getString('alert.defaultEmailSubject');
	}

	getEmailBodyPlaceholder = () => {
		return this.isDashboardSelected()
			? this.locale.getString('alert.defaultEmailBodyWithDashboard')
			: this.locale.getString('alert.defaultEmailBody');
	}

	private filterDashboards = (dashboard: Dashboard): boolean => {
		// remove folders, add anything that the current user owns
		if (dashboard.type === DashboardType.FOLDER || dashboard.hide) return false;

		return this.dashboardService.canShare(dashboard, this.security);
	}

	// minor pitfall for future consideration:
	// saving an alert with one of the default message options will still be flagged as
	// "customized" on future edit.
	isMessageCustomized = () => {
		return this.messageCustomized
			|| (this.notificationForm.message && this.notificationForm.message.$dirty)
			|| (this.notificationForm.emailSubject && this.notificationForm.emailSubject.$dirty)
			|| (this.notificationForm.emailBody && this.notificationForm.emailBody.$dirty);
	}

	isNewAlert = (): boolean => {
		return !this.alert.id;
	}

	setAlertType = (): void => {
		if (this.alert.notification.notifyByEmail && this.alert.notification.inApp) {
			this.notificationType = NotificationOption.BOTH;
		} else if (this.alert.notification.inApp) {
			this.notificationType = NotificationOption.IN_APP;
		} else if (this.alert.notification.notifyByEmail) {
			this.notificationType = NotificationOption.EMAIL;
		}
	}

	updateAlertType = (type: NotificationOption): void => {
		this.alert.notification.inApp = false;
		this.alert.notification.notifyByEmail = false;

		if (type === NotificationOption.IN_APP) {
			this.alert.notification.inApp = true;
		} else if (type === NotificationOption.EMAIL) {
			this.alert.notification.notifyByEmail = true;
		} else {
			this.alert.notification.notifyByEmail = true;
			this.alert.notification.inApp = true;
		}
	}

	testAlert = (): void => {
		this.testAlertCallback();
	}

	validateLinkedDashboard = (): boolean => {
		if (!this.notificationForm) {
			return true;
		}

		let valid: boolean;

		if (!this.createCase) {
			this.notificationForm.$setValidity('linkedDashboard', true, undefined);
			valid = true;
		} else {
			let validLinkedDashboard = !!this.alert.notification.linkDashboardName;
			this.notificationForm.$setValidity('linkedDashboard', validLinkedDashboard, undefined);
			valid = !this.notificationForm.$error.linkedDashboard;
		}
		this.validationCallback({$valid: this.notificationForm.$valid});

		return valid;
	}
}

app.component('alertNotification', {
	bindings: {
		alert: '<',
		ui: '<',
		createCase: '<',
		testAlertCallback: '&',
		validationCallback: '&'
	},
	controller: AlertNotificationController,
	templateUrl: 'partials/alerts/metric-alert-editor-notification.html'
});
