import { Security } from '@cxstudio/auth/security-service';
import { DriversItem } from './entities/drivers-item';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';

export default class ManageDriversService {

	constructor(
		private security: Security
	) {}

	isOwner = (driver: DriversItem): boolean => {
		return driver && driver.ownerId === this.security.getUser().userId;	
	}

	canEdit = (driver: DriversItem): boolean => {
		return this.isNew(driver) || this.isOwner(driver) || AssetPermission.EDIT === driver.permissionLevel;
	}

	private isNew = (driver: DriversItem): boolean => {
		return !driver || !driver.id;
	}
}

app.service('manageDriversService', ManageDriversService);