import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { AssetTemplateLazyResources } from '@app/modules/unified-templates/common-templates/asset-template-resources.service';
import { TemplateReplacementUtilsService } from '@app/modules/unified-templates/common-templates/asset/template-replacement-utils.service';
import { TemplatePlaceholderReplacement } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-replacement';
import { FilterReplacement, TemplateReplacementData } from '@app/modules/unified-templates/common-templates/dto/template-replacement-data';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectUtils } from '@app/util/object-utils';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { ReportAsset, ReportAssetTreeItem } from '@cxstudio/reports/entities/report-asset';

@Component({
	selector: 'template-replacement-filter-rule',
	templateUrl: './template-replacement-filter-rule.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateReplacementFilterRuleComponent implements OnInit {

	@Input() replacement: TemplatePlaceholderReplacement;
	@Input() resources: AssetTemplateLazyResources;
	@Input() project: AccountOrWorkspaceProject;
	@Output() replacementChange = new EventEmitter<TemplateReplacementData>();

	options: ReportAssetTreeItem[];
	selection: ReportAsset;
	rule: IFilterRule;

	constructor(
		private ref: ChangeDetectorRef,
		private templateReplacementUtils: TemplateReplacementUtilsService,
		private filterRuleService: FilterRuleService,
	) { }

	ngOnInit(): void {
		let replacementData = this.replacement.replacementData as FilterReplacement;
		this.getOptions().then(options => {
			this.options = options;
			let potentialMatch = this.templateReplacementUtils.findSimilarSelection(options,
				this.replacement.placeholderDisplayName, this.replacement.placeholderKey.assetIdentifier);
			if (potentialMatch) {
				this.rule = {
					type: replacementData.filterRule.type,
					matchMode: replacementData.filterRule.matchMode
				} as IFilterRule;
				this.selectOption(potentialMatch);
			} else {
				this.rule = {
					type: FilterRuleType.empty,
					matchMode: replacementData.filterRule.matchMode
				} as IFilterRule;
			}
			this.ref.markForCheck();
		});
	}

	private selectOption(item: ReportAsset): void {
		this.selection = item;
		let rule = ObjectUtils.copy(this.filterRuleService.getRuleObject(this.rule.type, item as any, this.rule));
		this.onRuleChange(rule);
	}

	onSelectedAttributeChange(selection: ReportAsset): void {
		this.selection = selection;
	}

	onRuleChange(rule: IFilterRule): void {
		this.rule = rule;
		this.replacementChange.emit({selection: this.selection, filterRule: this.rule});
	}

	private getOptions(): Promise<ReportAsset[]> {
		return this.templateReplacementUtils.getReplacementOptions(this.replacement, this.resources);
	}
}
