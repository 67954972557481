import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { ChangeDetectorRef,  ChangeDetectionStrategy, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';


@Component({
	selector: 'plot-line-preview',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div *ngIf="plotLine">
	<svg [attr.width]="previewWidth" version="1.1" xmlns="http://www.w3.org/2000/svg" class="d-block">
		<path *ngIf="!isNoLine()"
			fill="none"
			[attr.d]="getPathD()"
			[attr.stroke-dasharray]="plotLine.lineStyle"
			[attr.stroke-width]="plotLine.width"
			[attr.stroke]="plotLine.color"
		></path>
		<text y="14" style="fill: var(--gray-900)" *ngIf="isNoLine()">{{'widget.noLine'|i18n}}</text>
	</svg>
	</div>`
})
export class PlotLinePreviewComponent implements OnInit, DoCheck {
	@Input() plotLine: PlotLineStyle;
	@Input() previewWidth: number; // ui width
	differ: KeyValueDiffer<string, any>;

	constructor(
		private readonly differsService: KeyValueDiffers,
		private readonly ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.differ = this.differsService.find(this.plotLine).create();
	}

	ngDoCheck(): void {
		if (this.differ) {
			const changes = this.differ.diff(this.plotLine);
			if (changes) {
				this.ref.detectChanges();
			}
		}
	}

	isNoLine(): boolean {
		return this.plotLine.type === PlotLineType.NoLine;
	}

	getPathD(): string {
		return `M 0 10 L ${this.previewWidth} 10`;
	}
}
