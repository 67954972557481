export class PredictionSettings {
	enabled: boolean;
	provider: PredictionProvider;
	username: string;
	password: string;
}

export enum PredictionProvider {
	compellon = 'compellon',
	datarobot = 'datarobot'
}