import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';

export enum PillType {
	SENTIMENT = 'SENTIMENT',
	EASE_SCORE = 'EASE_SCORE',
	EMOTION = 'EMOTION',
	SCORECARD = 'SCORECARD',
	TOPIC = 'TOPIC',
	ATTRIBUTE = 'ATTRIBUTE',
}
export interface Pill {
	name: string;
	type: PillType;
	value?: any;
	sortValue?: string;
	title?: string;
	html?: string;
	color?: string;
	icon?: string;
	disabled?: boolean;
	additionalClass?: string;
	sentence?: ConversationSentence;
}
