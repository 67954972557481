import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { ReportScope } from '@cxstudio/reports/report-scope';

export interface IWordsSelection {
	isEnabled: () => boolean;
	setEnabled: (value: boolean) => void;
	canSelectWords(widget: Widget): boolean;
	showSelectionPanel(): void;
}

export class CloudWordSelection {

	constructor(
		private widgetsEditService: WidgetsEditService
	) {}

	getAndInitWordsSelection($scope: ReportScope): IWordsSelection {
		let wordsSelection = this.getWordsSelection($scope);
		$scope.$on('words-selection:save', (event, words) => {
			let action = WidgetUpdateAction.wrapChange(() => $scope.widget, () => {
				let grouping = $scope.widget.properties.selectedAttributes[0];
				if (words.length > 0) {
					grouping.wordsFilteringMode = WordsFilteringMode.INCLUDE;
					grouping.wordsList = words;
				} else {
					grouping.wordsFilteringMode = WordsFilteringMode.EXCLUDE;
					grouping.wordsList = [];
				}
			});
			this.widgetsEditService.applyDashboardChanges($scope.widget.containerId, action);
			this.widgetsEditService.addDashboardHistoryState(DashboardChangeType.UPDATED, [action]);
			this.closeAndRefresh($scope, wordsSelection);
		});

		$scope.$on('words-selection:cancel', () => {
			this.updateVisualization($scope, WidgetVisualization.CLOUD, true);
			wordsSelection.setEnabled(false);
		});
		return wordsSelection;
	}

	private getWordsSelection($scope: ReportScope): IWordsSelection {
		let enabled = false;
		return {
			isEnabled: () => enabled,
			setEnabled: (value: boolean) => enabled = value,
			canSelectWords: (widget: Widget) => {
				let props = widget.properties;
				let grouping = props.selectedAttributes && props.selectedAttributes[0];
				return grouping && (grouping.name === ClarabridgeMetricName.WORDS
						|| grouping.name === ClarabridgeMetricName.HASHTAG
						|| grouping.name === ClarabridgeMetricName.LC)
					&& widget.visualProperties.visualization === WidgetVisualization.CLOUD;
			},
			showSelectionPanel: () => {
				if ($scope.state.loading) return;
				let widget = $scope.widget;
				this.updateVisualization($scope, WidgetVisualization.CLOUDWORDSTABLE, false);
				enabled = true;
				$scope.state.loading = $scope.reloadUtils(widget).then((utils) => {
					$scope.runReport({
						widget,
						utils
					});
				});
			}
		};
	}


	private updateVisualization($scope: ReportScope, visualization: WidgetVisualization, fireTrigger: boolean): void {
		$scope.widget.visualProperties.visualization = visualization;
		if (fireTrigger)
			$scope.state.view.trigger++;
	}

	private closeAndRefresh($scope: ReportScope, wordsSelection: IWordsSelection): void {
		let widget = $scope.widget;
		this.updateVisualization($scope, WidgetVisualization.CLOUD, false);
		wordsSelection.setEnabled(false);
		$scope.state.loading = $scope.reloadUtils(widget).then((utils) => {
			$scope.runReport({
				widget,
				utils
			});
		});
	}

}

app.service('cloudWordSelection', CloudWordSelection);
