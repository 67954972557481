import { NgModule } from '@angular/core';
import {
	NarrativeSettingsListComponent
} from '@app/modules/account-administration/automated-narrative/narrative-settings-list.component';
import {
	NarrativeSettingsModalComponent
} from '@app/modules/account-administration/automated-narrative/narrative-settings-modal.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {
	NarrativeSettingsGridDefinition
} from '@app/modules/account-administration/automated-narrative/narrative-settings-grid-definition.service';
import {
	NarrativeSettingsApi
} from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';
import { UnitsModule } from '@app/modules/units/units.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		UnitsModule,
		ItemGridModule,
		DialogModule,
	],
	declarations: [
		NarrativeSettingsListComponent,
		NarrativeSettingsModalComponent,
	],
	exports: [
		NarrativeSettingsListComponent
	],
	providers: [
		NarrativeSettingsGridDefinition,
		NarrativeSettingsApi,
	]
})
export class AutomatedNarrativeModule { }
