import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, RendererStyleFlags2 } from '@angular/core';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ContentVisualizationBaseComponent } from '@app/modules/widget-visualizations/content-widgets/content-visualization-base';
import { ButtonTextPosition, ButtonWidgetVisualProperties, ButtonPosition } from '@cxstudio/reports/entities/content-widget-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';

@Component({
	selector: 'content-button',
	template: `
		<div #mainContainer
			class="d-flex w-100-percent h-100-percent bg-white p-8"
			[class.no-background]="buttonProps.backgroundColor === 'none'"
			[ngClass]="getPositionClass()">
			<div class="d-flex flex-direction-column overflow-auto"
				[class.flex-fill]="buttonProps.fullWidth">
				<div *ngIf="buttonProps.instructionalTextPosition === ButtonTextPosition.ABOVE"
					class="mb-8">
					<ng-container *ngTemplateOutlet="textInstruction"></ng-container>
				</div>
				<div class="d-flex p-4"
					[ngClass]="getPositionClass()"
					[class.w-100-percent]="buttonProps.fullWidth">
					<a class="btn btn-default normal-case d-flex align-center justify-center"
						[class.flex-fill]="buttonProps.fullWidth"
						[attr.href]="buttonProps.linkUrl || undefined"
						target="_blank">
						{{buttonProps.text}}
					</a>
				</div>
				<div *ngIf="buttonProps.instructionalTextPosition === ButtonTextPosition.BELOW"
					class="mt-8">
					<ng-container *ngTemplateOutlet="textInstruction"></ng-container>
				</div>
			</div>
		</div>
		<ng-template #textInstruction>
			<div *ngIf="buttonProps.instructionalTextEnabled"
				class="d-flex"
				[ngClass]="getPositionClass()">
				<span class="pre-wrap">{{buttonProps.instructionalText}}</span>
			</div>
		</ng-template>`,
	styles: [`
		a {
			background-color: var(--background-color);
			color: var(--text-color);
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentButtonComponent extends ContentVisualizationBaseComponent<WidgetProperties, ButtonWidgetVisualProperties>
	implements OnInit, AfterViewInit {

	@ViewChild('mainContainer', {static: false}) mainContainer: ElementRef<HTMLElement>;
	ButtonTextPosition = ButtonTextPosition;

	buttonProps: ButtonWidgetVisualProperties;

	constructor(
		readonly ref: ChangeDetectorRef,
		private renderer: Renderer2,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
	) {
		super(ref, reportUtils);
	}

	ngOnInit(): void {
		this.buttonProps = this.widget.visualProperties;
	}

	ngAfterViewInit(): void {
		this.renderer.setStyle(this.mainContainer.nativeElement, '--background-color',
			this.buttonProps.buttonColor, RendererStyleFlags2.DashCase);
		this.renderer.setStyle(this.mainContainer.nativeElement, '--text-color',
			ColorUtilsHelper.pickContrastTextColor(this.buttonProps.buttonColor), RendererStyleFlags2.DashCase);
		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
	}

	getPositionClass(): string {
		switch (this.buttonProps.position) {
			case ButtonPosition.LEFT: return 'justify-start text-left';
			case ButtonPosition.CENTER: return 'justify-center text-center';
			case ButtonPosition.RIGHT: return 'justify-end text-right';
		}
		return '';
	}
}
