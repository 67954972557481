import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';

//has NG twin
app.directive('blockKbNavigation', () => {
	return {
		restrict: 'A',
		link: (scope, element: ng.IAugmentedJQuery, attrs) => {
			const blockKb = scope.$eval(attrs.blockKbNavigation);
			if (blockKb) {
				const parent = element.parent();
				parent.on('keydown', (event: JQuery.KeyDownEvent) => {
					const isTabKey = KeyboardUtils.isEventKey(event as any, Key.TAB)
						|| KeyboardUtils.isEventKey(event as any, Key.TAB, KeyModifier.SHIFT);
					if (blockKb && isTabKey) {
						element.find(':focusable').attr('tabindex', -1);
					}
				});

				scope.$on('$destroy', () => {
					parent.off('keydown');
				});
			}
		}
	};
});
