import ILocale from '@cxstudio/interfaces/locale-interface';
import { IScheduledJobType } from './scheduled-job-type';
import { ScheduleSettings } from './../schedule-settings';
import { JobType } from './job-type';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export class PdfDistributionJob implements IScheduledJobType {
	displayName: string;
	titleString: string;
	value = JobType.pdf_distribution;
	// autosizing should be removed from this list once it is set up to persist
	// in the meantime it causes the UI to constantly think there are changes
	ignoreFields = ['message', 'notify', 'notifyApp', 'useAutosizing', 'type'];
	apiPreprocessor = (settings: ScheduleSettings, dashboard: Dashboard): void => {
		if (settings.useAutosizing) {
			let params = this.dashboardExportService.getExportParams(dashboard,
				this.currentWidgets.getAllWidgets('' + dashboard.id));
			settings.height = params.height;
			settings.width = params.width;
		}

		this.ignoreFields.forEach((field) => {
			delete settings[field];
		});
	}

	constructor(locale: ILocale | CxLocaleService, private dashboardExportService: any, private currentWidgets: ICurrentWidgets) {
		this.displayName = locale.getString('schedule.distributePdf');
		this.titleString = locale.getString('schedule.distributePdfTitle');
	}

}
