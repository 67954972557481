import { NgModule } from '@angular/core';
import { WidgetErrorComponent } from '@app/modules/widget-visualizations/common/widget-error/widget-error.component';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetConfidentialityService } from './widget-confidentiality/widget-confidentiality.service';

@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		WidgetErrorComponent,
	],
	exports: [
		WidgetErrorComponent,
	],
	providers: [
		WidgetConfidentialityService
	],
})
export class WidgetVisualizationsCommonModule { }
