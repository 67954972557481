import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridController } from './embedded-widget-management.component';
import { WidgetManagementItem } from './widget-management-item.class';
import { UrlService } from '@cxstudio/common/url-service.service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { SortDirection } from '@cxstudio/common/sort-direction';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { EmbeddedWidgetUtils } from './embedded-widget-utils.service';

@Injectable()
export class EmbeddedWidgetGridDefinition implements IGridDefinition<GridController> {

	constructor(
		private locale: CxLocaleService,
		private embeddedWidgetUtils: EmbeddedWidgetUtils,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('urlService') private urlService: UrlService) {}

	init = (gridMode: GridMode, controller: GridController): Promise<IGridColumn[]> => {

		let columns = [ {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'embeddedEnabled',
			name: this.locale.getString('mAccount.enabledLicensed'),
			field: 'embeddedEnabled',
			sortable: false,
			minWidth: 80,
			width: 120,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center br-embedded-enabled',
			headerCssClass: 'text-center'
		}, {
			id: 'enterpriseViewEnabled',
			name: this.locale.getString('mAccount.enabledEnterprise'),
			field: 'enterpriseViewEnabled',
			sortable: false,
			minWidth: 80,
			width: 120,
			searchable: false,
			formatter: this.enterpriseViewToggleFormatter,
			cssClass: 'text-center br-enterprise-view-enabled',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'copyLink',
			name: this.locale.getString('mAccount.copyLink'),
			minWidth: 60,
			width: 100,
			cssClass: 'cell-copy-link',
			formatter: this.copyLinkFormatter
		}, {
			id: 'displayName',
			name: this.locale.getString('object.widget'),
			field: 'displayName',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-display-name',
			defaultSortColumn: SortDirection.ASC
		}, {
			id: 'dashboardName',
			name: this.locale.getString('object.dashboard'),
			field: 'dashboardName',
			sortable: true,
			minWidth: 120,
			width: 200,
			cssClass: 'cell-dashboard-name',
			formatter: this.dashboardLinkFormatter,
		}, {
			id: 'projectName',
			name: this.locale.getString('widget.project'),
			field: 'projectName',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'cell-project-name',
		}, {
			id: 'owner',
			name: this.locale.getString('common.owner'),
			field: 'owner',
			sortable: true,
			minWidth: 80,
			width: 260,
			cssClass: 'cell-owner-name',
			formatter: this.gridFormatterService.getAuthorFormatter()
		}];

		return this.embeddedWidgetUtils.isEnterpriseViewersAvailable().then((enabled) => {
			if (enabled) {
				return Promise.resolve(columns);
			} else {
				return Promise.resolve(_.reject(columns, (column => column.id === 'enterpriseViewEnabled')));
			}
		});
	}

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: WidgetManagementItem) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.displayName);
		return getSwitch(value);
	}

	private enterpriseViewToggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: WidgetManagementItem) => {
		let disabled = !dataContext.embeddedEnabled || dataContext.widgetType.toLocaleLowerCase() === WidgetType.PREVIEW;
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.displayName);
		return getSwitch(value, disabled, this.locale.getString('mAccount.enterpriseViewDisabled'));
	}

	private dashboardLinkFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: WidgetManagementItem) => {
		let link = this.urlService.getDashboardUrl(dataContext.dashboardId);
		return `<a href=${link} target="_blank" class='br-item-link' role="link">${value}</a>`;
	}

	private copyLinkFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: WidgetManagementItem) => {
		return `<a class="copy-link-button"><i class="q-icon-link ml-8" role="button"></i><a>`;
	}
}

app.service('embeddedWidgetGridDefinition', downgradeInjectable(EmbeddedWidgetGridDefinition));
