import { Input, Directive } from '@angular/core';
import { GenericVisualizationBaseComponent } from '@app/modules/widget-visualizations/generic-visualization-base.class';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { BaseWidgetProperties } from '@cxstudio/reports/entities/widget-properties';

@Directive()
export class ContentVisualizationBaseComponent<P extends BaseWidgetProperties = ContentWidgetProperties, V = VisualProperties>
	extends GenericVisualizationBaseComponent<P> {
	@Input() widget: ContentWidget<P, V>;
	@Input() dataObject: P;

	empty: boolean;

	protected showEmpty(): void {
		this.empty = true;
		this.markRendered();
	}

	protected addImageLoadingHandler(imageElement: HTMLElement): void {
		$(imageElement)
			.one('load', () => this.markRendered())
			.one('error', () => this.markRendered());
	}
}
