import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';


@Injectable({
	providedIn: 'root'
})
export class ReportProjectContextService {
	constructor(
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly projectContextService: ProjectContextService,
	) {}

	getWidgetProjectTimezone(widget: Widget): Promise<string> {
		let project = this.reportAssetUtilsService.getWidgetProject(widget);
		return this.projectContextService.getProjectTimezone(project);
	}

	getDashboardProjectTimezone(dashboard: Dashboard): Promise<string> {
		let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
		return this.projectContextService.getProjectTimezone(project);
	}

	getWidgetDesignerPalette(widgetProperties: WidgetProperties): Promise<string[]> {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(widgetProperties);
		return this.projectContextService.getDesignerPalette(project);
	}

	getDashboardDesignerPalette(dashboard: Dashboard): Promise<string[]> {
		let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
		return this.projectContextService.getDesignerPalette(project);
	}
}

app.service('reportProjectContextService', downgradeInjectable(ReportProjectContextService));
