import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

declare let app: angular.IModule;

@Component({
	selector: 'dashboard-preview-tooltip',
	template: `
<div id="dash-tooltip" role="tooltip" class="br-dash-tooltip">
	<div class="br-dash-preview">
		<dashboard-preview-image 
			[dashboard]="dashboard"
			[tabsLimit]="5"
			[description]="true">
		</dashboard-preview-image>
		<div class="br-arrow"></div>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardPreviewTooltipComponent  {
	@Input() dashboard: Dashboard;
}

app.directive('dashboardPreviewTooltip', downgradeComponent({component: DashboardPreviewTooltipComponent}) as angular.IDirectiveFactory);