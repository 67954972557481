import * as _ from 'underscore';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { LimitToSettings } from '../settings.interfaces';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';


export class LimitToSettingsGroup implements ISettingsGroup<LimitToSettings> {

	settings: LimitToSettings;
	project: AccountOrWorkspaceProject;

	private fields = ['wordsFilteringMode', 'wordsList'];

	constructor(
		source: LimitToSettings,
		name: string,
		project: AccountOrWorkspaceProject,
		private tagService: TagsService
	) {
		this.settings = source
			? _.pick(source, this.fields) as LimitToSettings
			: {} as LimitToSettings;
		this.settings.name = name;
		this.project = project;
	}

	postProcessSettings(): LimitToSettings {
		if (this.settings.wordsList) {
			let wordsSelection = this.tagService.tagObjectsToStringArray(this.settings.wordsList);
			this.settings.wordsList = wordsSelection;
		}
		return _.pick(this.settings, this.fields) as LimitToSettings;
	}

	resetDefaultSettings(): void {
		this.settings.wordsFilteringMode = WordsFilteringMode.EXCLUDE;
		this.settings.wordsList = [];
	}

}
