import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { AssetResolver, AssetTemplateResourcesService } from '@app/modules/unified-templates/common-templates/asset-template-resources.service';
import { AssetTemplateData } from '@app/modules/unified-templates/common-templates/dto/asset-template-data';
import { TemplateAssetData } from '@app/modules/unified-templates/common-templates/dto/template-asset-data';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { PlaceholderDisplayType, PlaceholderUsageLocation, TemplatePlaceholderMetadata } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { IPlaceholderItem } from '@app/modules/unified-templates/common-templates/template/template-placeholders-list/template-placeholders-list.component';
import { TemplatePropertiesComponent } from '@app/modules/unified-templates/common-templates/template/template-properties/template-properties.component';
import { ObjectUtils } from '@app/util/object-utils';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface TemplateCreationData {
	type: TemplateAssetType;
	assetId?: number;
	templateId?: number;
	titleLabel: string;
	showProperties: boolean;
	showMappings: boolean;
}

@Component({
	selector: 'template-create-dialog',
	templateUrl: './template-create-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCreateDialogComponent implements OnInit, IModalComponent<TemplateCreationData> {

	@Input() input: TemplateCreationData;
	@ViewChild(TemplatePropertiesComponent, {static: false}) templateProperties: TemplatePropertiesComponent;

	loading: Promise<any>;

	template: AssetTemplateData;
	folders: IFolderItem[] = [];

	private placeholdersReady: boolean;
	placeholders: {[key in PlaceholderDisplayType]?: IPlaceholderItem[]};

	constructor(
		private ref: ChangeDetectorRef,
		private modal: NgbActiveModal,
		private assetTemplateApi: AssetTemplateApiService,
		private assetTemplateResources: AssetTemplateResourcesService,
	) { }

	ngOnInit(): void {
		this.loading = Promise.all([this.initAssetData(), this.initFolders()]).then(() => this.ref.markForCheck());
	}

	private getAssetData(): Promise<TemplateAssetData> {
		if (this.input.assetId) {
			return this.assetTemplateApi.getTemplateAssetData(this.input.type, this.input.assetId);
		} else if (this.input.templateId) {
			return this.assetTemplateApi.getTemplateAssetDataFromTemplate(this.input.type, this.input.templateId);
		}
	}

	private initAssetData(): Promise<any> {
		return this.getAssetData().then(assetData => {
			this.template = {
				name: assetData.name,
				description: assetData.description,
				placeholders: [],
				parentId: assetData.parentId,
			};
			let assetResolver = this.assetTemplateResources.getAssetResolver(assetData.project);
			return Promise.all(assetData.placeholders.map(metadata => this.getPlaceholder(assetResolver, metadata))).then(placeholders => {
				this.placeholders =  _.groupBy(placeholders, placeholder => placeholder.type);
				this.placeholdersReady = true;
			});
		});
	}

	private initFolders(): Promise<any> {
		return this.assetTemplateApi.getTemplatesFolders(this.input.type).then(folders => {
			this.folders = folders;
		});
	}

	private getPlaceholder(assetResolver: AssetResolver, metadata: TemplatePlaceholderMetadata): Promise<IPlaceholderItem> {
		return assetResolver.getAssetDisplayName(metadata.key).then(displayName => ({
			key: metadata.key,
			displayType: metadata.type,
			originalDisplayName: displayName || metadata.originalDisplayName,
			description: metadata.description,
			locations: this.getLocations(metadata)
		})).then(placeholder => ({
			type: metadata.type,
			usages: metadata.usages,
			placeholder,
		}));
	}

	private getLocations(metadata: TemplatePlaceholderMetadata): PlaceholderUsageLocation[] {
		return metadata.usages.map(usage => usage.location);
	}

	save(): void {
		let copy = ObjectUtils.copy(this.template);
		copy.placeholders = _.chain(_.values(this.placeholders))
			.flatten()
			.map(item => item.placeholder)
			.value();

		copy.placeholders.forEach(placeholder => {
			placeholder.description = placeholder.description || placeholder.originalDisplayName;
		});

		this.modal.close(copy);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	isValid(): boolean {
		return this.isPropertiesValid() && this.isMappingsValid();
	}

	private isPropertiesValid(): boolean {
		return !this.input.showProperties || this.templateProperties?.isValid();
	}

	private isMappingsValid(): boolean {
		return !this.input.showMappings || this.placeholdersReady;
	}


}
