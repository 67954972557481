import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportMetricsApiService } from '@app/modules/metric/services/report-metrics-api.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';

@Injectable({
	providedIn: 'root'
})
export class ReportMetricsService {
	constructor(
		private readonly reportMetricsApi: ReportMetricsApiService,
		private readonly projectAssetsHelper: ProjectAssetsHelper,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly metricsService: MetricsService
	) {}

	getWidgetMetrics(widget: Widget): Promise<Metric[]> {
		return this.projectAssetsHelper.getWidgetProjectData<Metric[]>(
			widget,
			(project) => this.metricsService.getMetrics(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportMetricsApi.getWidgetMetrics(viewParams),
				() => [])
		);
	}

	getDashboardMetrics(dashboard: Dashboard): Promise<Metric[]> {
		return this.projectAssetsHelper.getDashboardProjectData<Metric[]>(
			dashboard,
			(project) => this.metricsService.getMetrics(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportMetricsApi.getWidgetMetrics(viewParams),
				() => [])
		);
	}

	getWidgetPredefinedMetrics(widget: Widget): Promise<Metric[]> {
		let project = this.reportAssetUtilsService.getWidgetProject(widget);
		return this.metricsService.getPredefinedMetrics(project);
	}

	getWidgetDynamicPredefinedMetrics(widget: Widget, isApplicationScope: boolean): Promise<Metric[]> {
		let project = this.reportAssetUtilsService.getWidgetProject(widget);
		return this.metricsService.getDynamicPredefinedMetrics(project, isApplicationScope);
	}

	getDashboardDynamicPredefinedMetrics(dashboard: Dashboard, isApplicationScope: boolean): Promise<Metric[]> {
		let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
		return this.metricsService.getDynamicPredefinedMetrics(project, isApplicationScope);
	}

}

app.service('reportMetricsService', downgradeInjectable(ReportMetricsService));
