import { WidgetToolbarConstants } from './add-widget-toolbar-constants.constant';
import { ToolbarPosition, AddWidgetToolbarPosition } from './add-widget-toolbar-position.service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { DashboardWidgetLimiter } from '@cxstudio/home/dashboard-widget-limiter.service';
import { WidgetTemplate } from '@cxstudio/dashboards/widgets/widget-template.class';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';
import { Security } from '@cxstudio/auth/security-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { ElementMutationObserver } from '@cxstudio/dashboards/widgets-toolbar/ElementMutationObserver';

export enum WidgetToolBarNavigationDirection {
	UP = 'up',
	DOWN = 'down',
}

export class AddWidgetToolbarController implements ng.IController {
	private readonly LONG_CLICK_IN_MS = 1000;
	private readonly LONG_CLICK_ERROR_MARGIN_IN_PX = 5;
	private resizeHandlerAttached: boolean;
	private observer: ElementMutationObserver;

	widgetTemplates: WidgetTemplate[];
	onWidgetClick: (params: {$report: WidgetTemplate}) => void;

	orderBy: any;
	showDropAreas: boolean;
	toolbarDragged: boolean;
	arrowsEnabled: boolean;
	disableButtonUp: boolean;
	disableButtonDown: boolean;

	mouseDownTime: number;
	mouseDownPageX: number;
	mouseDownPageY: number;
	longPressClick: boolean;
	scrollElement: HTMLElement;
	contentElement: HTMLElement;
	widgetToolBarNavigationDirection = WidgetToolBarNavigationDirection;

	MAX_HEIGHT = 750;

	constructor(
		private security: Security,
		private addWidgetToolbarPosition: AddWidgetToolbarPosition,
		private widgetsEditService: WidgetsEditService,
		private dashboardWidgetLimiter: DashboardWidgetLimiter,
		private environmentService: EnvironmentService,
		private $element: ng.IAugmentedJQuery,
		private $window: ng.IWindowService,
	) {}

	$onInit(): void {
		this.showDropAreas = false;
		this.toolbarDragged = false;
		this.disableButtonUp = true;
		this.scrollElement = this.$element[0].querySelector('.template-tooltip-container');
		this.contentElement = document.querySelector('.br-home-gridster');

		this.addResizeHandlersWhenVertical();
	}

	$onDestroy(): void {
		this.$window.removeEventListener('resize', this.adjustToolbarPositionOnResize);
		this.disconnectObserver();
	}

	private observeElement(selector: string, callback: () => void): void {
		this.observer = new ElementMutationObserver(selector, callback);
		this.observer.observe();
	}

	private disconnectObserver(): void {
		if (this.observer) {
			this.observer.disconnect();
			this.observer = undefined;
		}
	}

	getToolbarHeight = (): string => {
		return this.addWidgetToolbarPosition.isVertical() ? '100%' : 'auto';
	}

	showDropArea = (position: ToolbarPosition): boolean => {
		return this.showDropAreas && !this.addWidgetToolbarPosition.isCurrent(position);
	}

	getVerticalDropAreaMargin = (): string => {
		if (!this.addWidgetToolbarPosition.isTop()) {
			return `${WidgetToolbarConstants.HORIZONTAL_TOOLBAR_HEIGHT}px 0 0 0`;
		}
	}

	getVerticalDropAreaHeight = (): string => {
		let toolbarsAndFilterHeight = WidgetToolbarConstants.HORIZONTAL_TOOLBAR_HEIGHT * 2;
		return `calc(100% - ${toolbarsAndFilterHeight}px)`;
	}

	dragStart = (): void => {
		this.showDropAreas = true;
		this.toolbarDragged = true;
	}

	dragStop = (event): void => {
		this.showDropAreas = false;
		this.toolbarDragged = false;

		let topArea = WidgetToolbarConstants.GLOBAL_HEADER_HEIGHT
			+ WidgetToolbarConstants.FILTER_BAR_HEIGHT
			+ WidgetToolbarConstants.HORIZONTAL_TOOLBAR_HEIGHT;

		// this looks wrong - `parseInt('2.75em') == 2`, so the calculation might be incorrect
		let toolbarSize = (this.environmentService.getRootFontSize() * parseInt(WidgetToolbarConstants.VERTICAL_TOOLBAR_WIDTH, 10));

		if (event.pageY < topArea) {
			this.addWidgetToolbarPosition.set(ToolbarPosition.TOP);
		} else if (event.pageY > event.view.innerHeight - toolbarSize) {
			this.addWidgetToolbarPosition.set(ToolbarPosition.BOTTOM);
		} else if (event.pageX < toolbarSize) {
			this.addWidgetToolbarPosition.set(ToolbarPosition.LEFT);
		} else if (event.pageX > event.view.innerWidth - toolbarSize) {
			this.addWidgetToolbarPosition.set(ToolbarPosition.RIGHT);
		}

		this.setToInitialState();
		this.addResizeHandlersWhenVertical();
	}

	setToInitialState = () => {
		this.arrowsEnabled = false;
		this.disableButtonUp = true;
		this.disableButtonDown = false;
		this.scrollElement.style.height = 'auto';
		this.scrollElement.style.overflowY = 'auto';
	}

	addResizeHandlersWhenVertical = () => {
		if (this.addWidgetToolbarPosition.isVertical()) {
			setTimeout(() => {
				this.adjustToolbarPositionOnResize();
			}, 100);
		}

		if (!this.addWidgetToolbarPosition.isVertical() && this.resizeHandlerAttached) {
			this.$window.removeEventListener('resize', this.adjustToolbarPositionOnResize);
			this.disconnectObserver();
			this.resizeHandlerAttached = false;

		} else if (this.addWidgetToolbarPosition.isVertical() && !this.resizeHandlerAttached) {
			this.$window.addEventListener('resize', this.adjustToolbarPositionOnResize);
			this.observeElement('dashboard-filters-panel', this.adjustToolbarPositionOnResize);
			this.resizeHandlerAttached = true;
		}
	}

	adjustToolbarPositionOnResize = () => {
		const numItems = this.scrollElement.querySelectorAll('.widget-tile').length || 0;
		const sidebarHeight = numItems * WidgetToolbarConstants.FILTER_TEMPLATE_BUTTON_HEIGHT;
		const windowHeight = window.innerHeight;

		if (sidebarHeight > this.MAX_HEIGHT || sidebarHeight + 40 > (windowHeight - this.contentElement.getBoundingClientRect().top)) {
			this.arrowsEnabled = true;
			this.scrollElement.style.height = `${windowHeight - this.contentElement.getBoundingClientRect().top - 120}px`;
			this.scrollElement.style.overflowY = 'hidden';
		} else {
			this.setToInitialState();
		}
	}

	updateScrollButtons = () => {
		this.disableButtonUp = this.scrollElement.scrollTop === 0;
		this.disableButtonDown = Math.abs(
			this.scrollElement.scrollTop - (this.scrollElement.scrollHeight - this.scrollElement.clientHeight)
		) <= 1;
	}

	navigateWidgetTemplates = (direction: WidgetToolBarNavigationDirection) => {
		this.scrollElement.scrollBy({
			top: (direction === WidgetToolBarNavigationDirection.UP) ?
				-WidgetToolbarConstants.FILTER_TEMPLATE_BUTTON_HEIGHT : WidgetToolbarConstants.FILTER_TEMPLATE_BUTTON_HEIGHT,
			behavior: 'auto'
		});
		this.updateScrollButtons();
	}

	calculateTimeAndMouseDownPosition = (event: JQuery.Event): void => {
		this.mouseDownTime = new Date().getTime();
		this.mouseDownPageX = event.pageX;
		this.mouseDownPageY = event.pageY;
	}

	compareCurrentTimeAndMousePositions = (event: JQuery.Event): void => {
		let currentTime: number = new Date().getTime();
		if (!(currentTime - this.mouseDownTime < this.LONG_CLICK_IN_MS)
			&& Math.abs(event.pageY - this.mouseDownPageY) < this.LONG_CLICK_ERROR_MARGIN_IN_PX
			&& Math.abs(event.pageX - this.mouseDownPageX) < this.LONG_CLICK_ERROR_MARGIN_IN_PX) {
			this.longPressClick = true;
		} else {
			this.longPressClick = false;
		}
	}

	ignoreClick = () => {
		return this.longPressClick || this.toolbarDragged;
	}

	handleWidgetClickByType = (report: WidgetTemplate): void => {
		if (!this.ignoreClick()) {
			this.onWidgetClick({$report: report});
		}
	}

	isWidgetAvailable = (report: WidgetTemplate): boolean => {
		if (!report) return;

		if (WidgetTypeFilters.isQualtricsWidget(report)) {
			return this.security.isQualtricsWidgetEmbeddingEnabled();
		}

		return this.dashboardWidgetLimiter.isShowWidgetTemplate(report, this.widgetsEditService.getWidgets());
	}

	isDuplicateDelimiter = (index: number): boolean => {
		if (index === 0) return false;

		let pointer = index - 1;
		while (pointer >= 0) {
			// reached another delimiter
			if (!this.widgetTemplates[pointer]) {
				return true;
			}
			// reached an enabled widget selector
			if (this.widgetTemplates[pointer] && this.isWidgetAvailable(this.widgetTemplates[pointer])) {
				return false;
			}
			pointer --;
		}
		return false;
	}

	isBoundaryDelimiter = (index: number): boolean => {
		// checking left end
		if (index === 0) return true;
		let pointer = index;
		let isLeftEnd = true;
		while (pointer >= 0) {
			// reached an enabled widget selector
			if (this.widgetTemplates[pointer] && this.isWidgetAvailable(this.widgetTemplates[pointer])) {
				isLeftEnd = false;
				break;
			} else {
				pointer--;
			}
		}

		//checking right end
		if (index === this.widgetTemplates.length - 1) return true;
		pointer = index;
		let isRightEnd = true;
		while (pointer <= this.widgetTemplates.length - 1) {
			// reached an enabled widget selector
			if (this.widgetTemplates[pointer] && this.isWidgetAvailable(this.widgetTemplates[pointer])) {
				isRightEnd =  false;
				break;
			} else {
				pointer++;
			}
		}
		return isLeftEnd || isRightEnd;
	}

	shouldDisplayDelimiter = (index: number): boolean => {
		return !this.isBoundaryDelimiter(index) && !this.isDuplicateDelimiter(index);
	}

	getTooltipPosition(): string {
		const toolbarPos = this.addWidgetToolbarPosition.get();
		switch (toolbarPos) {
			case ToolbarPosition.TOP:
				return 'bottom';
			case ToolbarPosition.BOTTOM:
				return 'top';
			case ToolbarPosition.LEFT:
				return 'right';
			case ToolbarPosition.RIGHT:
				return 'left';
		}
	}
}

app.component('addWidgetToolbar', {
	controller: AddWidgetToolbarController,
	templateUrl: 'partials/components/add-widget-toolbar.html',
	bindings: {
		widgetTemplates: '=',
		onWidgetClick: '&',
	}
});
