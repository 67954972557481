import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

export class CbValidationService {
	validation: { [key: string]: (props: WidgetProperties, visualProps?: VisualProperties) => boolean };
	readonly warningText: string;

	constructor(
		private readonly cbDialogService: CBDialogService,
		private readonly locale: ILocale,
		private readonly DateRange,
		private readonly filterValidationService: FilterValidationService,
	) {
		this.validation = {};
		this.initValidators();
		this.warningText = this.locale.getString('common.warning');
	}

	private initValidators(): void {
		this.validation[WidgetType.TABLE] = (props) => {
			if (!this.hasPrimaryGrouping(props)) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('widget.selectOneAttr'));
				return false;
			}
			if (InternalProjectTypes.isAdminProject(props.project) && !this.hasCalculation(props)) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('widget.selectOneCalculation'));
				return false;
			}
			return true;
		};

		this.validation[WidgetType.SELECTOR] = (props, visualProps) => {
			if (!this.hasPrimaryGrouping(props)) {
				let message = visualProps.visualization === SelectorWidgetNavigationType.SELECTOR_SEARCH
					? this.locale.getString('widget.selectOneAttr')
					: this.locale.getString('widget.selectAGrouping');
				this.cbDialogService.notify(this.warningText, message);
				return false;
			}
			return true;
		};

		this.validation[WidgetType.OBJECT_VIEWER] = (props) => {
			if (!this.hasPrimaryGrouping(props)) {
				let message = this.locale.getString('widget.selectAGrouping');
				this.cbDialogService.notify(this.warningText, message);
				return false;
			}
			return true;
		};

		this.validation[WidgetType.PREVIEW] = (props, visualProps) => {
			if (!this.isTopicModelSelected(props, visualProps)) {
				this.cbDialogService.notify(
					this.locale.getString('common.warning'), 'Please select at least one model to display topics');
				return false;
			}
			return true;
		};

		this.validation[WidgetType.MODEL_VIEWER] = (props) => {
			if (!this.hasPrimaryGrouping(props)) {
				let message = this.locale.getString('widget.selectAModel');
				this.cbDialogService.notify(this.warningText, message);
				return false;
			}
			return true;
		};

		this.validation[WidgetType.METRIC] = (props) => {
			if (_.isUndefined(props.selectedMetrics)
				|| props.selectedMetrics.length === 0) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('widget.select1metric'));
				return false;
			}

			return true;
		};

		this.validation.cb_analytic_default = (props) => {
			if (_.isUndefined(props.selectedMetrics) || props.selectedMetrics.length === 0) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('widget.select1metric'));
				return false;
			}

			if (!this.hasPrimaryGrouping(props)) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('widget.selectOneGrouping'));
				return false;
			}

			if (!props.selectedAttributes[props.selectedAttributes.length - 1]) {
				props.selectedAttributes.pop();
			}
			return true;
		};

		this.validation.filterTab = (props) => {
			let filterRules = props.adhocFilter.filterRules.filter((filter) => filter.type !== FilterRuleType.empty) || [];

			let valid = this.filterValidationService.validateFilterRules({ filterRules })
				|| filterRules.isEmpty();
			if (!valid) {
				this.cbDialogService.notify(this.warningText, this.locale.getString('reportFilters.incompleteRules'));
			}

			return !!valid;
		};
	}

	private isTopicModelSelected = (props: PreviewWidgetProperties, visualProps: VisualProperties): boolean => {
		let topic =  _.findWhere(visualProps.columns, { name: PreviewColumn.TOPICS});
		if (topic && visualProps.visualization === WidgetVisualization.PREVIEW_TABLE) {
			return !_.isEmpty(props.selectedModels);
		}
		return true;
	}

	private hasPrimaryGrouping(properties): boolean {
		let attributeGroupingSelected = !(_.isUndefined(properties.selectedAttributes)
			|| properties.selectedAttributes.length === 0
			|| properties.selectedAttributes[0] === undefined
			|| _.isUndefined(properties.selectedAttributes[0].name));
		let customPrimaryGroupSelected = !_.isUndefined(properties.primaryTimeGrouping);
		return attributeGroupingSelected || customPrimaryGroupSelected;
	}

	private hasCalculation(properties): boolean {
		return !(_.isUndefined(properties.selectedMetrics))
			&& properties.selectedMetrics.length > 0 && properties.selectedMetrics[0] !== undefined;
	}

	validateProperties = (props, visualProps) => {
		let wrongProject = (props.contentProviderId < 0 || props.accountId < 0 || props.project < 0)
			&& !InternalProjectTypes.isAdminProject(props.project);

		if (wrongProject) {
			this.cbDialogService.notify(this.warningText, this.locale.getString('widget.selectProject'));
			return false;
		}

		if (props.adhocFilter && !this.validation.filterTab(props)) {
			return;
		}

		if (this.validation[props.widgetType]) {
			return this.validation[props.widgetType](props, visualProps);
		}

		if (/^cb_an_/.test(props.widgetType)) {
			return this.validation.cb_analytic_default(props);
		}

		return true;
	}

	validateDateFilterRange = (properties) => {
		if (!properties.dateRangeP1)
			return;
		let dateFilter = this.DateRange.valueOf(properties.dateRangeP1.dateFilterMode);

		if (dateFilter && dateFilter.value === this.DateRange.options.CUSTOM.value) {
			let dateRangeFrom = new Date(properties.dateRangeP1.from);
			let dateRangeTo = new Date(properties.dateRangeP1.to);

			let amountOfDays = (dateRangeTo.getTime() - dateRangeFrom.getTime()) / (24 * 60 * 60 * 1000);

			return amountOfDays < 365;
		}

		return true;
	}
}

app.service('cbValidationService', CbValidationService);
