import { WidgetError } from '@app/modules/widget-visualizations/common/widget-error/widget-error.class';
import { ReportErrorMessagesService } from '@app/modules/widget-visualizations/utilities/report-error-messages.service';
import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import * as _ from 'underscore';

// small reusable service to validate whether we have series data for display
export class ChartValidator {

	constructor(
		private reportErrorMessages: ReportErrorMessagesService,
	) {}

	// @Deprecated, use getValidationMessage to put message to UI through angular instead of manually via html
	validate(series, rawData, element: JQuery, options?: VisualProperties): boolean {
		if (this.isFormattingError(rawData)) {
			this.reportErrorMessages.putMetricFormattingErrorMessage(element);
			return false;
		}

		let validation;
		if (options?.visualization === WidgetVisualization.STACKEDPIE)
			validation = this.getPieSeriesValidationMessage(series, rawData);
		else validation = this.getSeriesValidationMessage(series, rawData);
		if (validation) {
			this.reportErrorMessages.addErrorMessageToElement(element, validation);
			return false;
		}

		return true;
	}

	getValidationMessage(series: Highcharts.Series[], rawData: IDataPoint[], options: VisualProperties): WidgetError {
		return options?.visualization === WidgetVisualization.STACKEDPIE
			? this.getPieSeriesValidationMessage(series, rawData)
			: this.getSeriesValidationMessage(series, rawData);
	}

	private getSeriesValidationMessage(series: Highcharts.Series[], rawData: IDataPoint[]): WidgetError {
		if (!series.length || _.all(series, oneSeries => _.isEmpty(oneSeries.data))) {
			return rawData.length ? WidgetError.NULL_INCLUDES : WidgetError.NO_DATA;
		}

		return null;
	}

	private getPieSeriesValidationMessage(series: Highcharts.Series[], rawData: IDataPoint[]): WidgetError {
		let genericError = this.getSeriesValidationMessage(series, rawData);

		if (genericError) {
			return genericError;
		}

		if (this.hasNegativeValues(series)) {
			return WidgetError.NEGATIVE_SIZE_METRIC;
		}

		return null;
	}

	private hasNegativeValues(series: Highcharts.Series[]): boolean {
		let allValuesPositive = _.every(series, oneSeries => {
			return _.every(oneSeries.data, dataPoint => {
				// null is okay too, just not negative number
				return !_.isNumber(dataPoint.y) || dataPoint.y >= 0;
			});
		});

		return !allValuesPositive;
	}

	private isFormattingError(rawData): boolean { // seems this is never the case, but not sure
		return rawData && rawData.formatError === true;
	}

}

app.service('chartValidator', ChartValidator);
