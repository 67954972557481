import { TableService } from '@cxstudio/services/table-service';
import { WordsFilteringMode } from '../providers/cb/constants/words-filtering-mode';
import { GroupIdentifierHelper } from '../utils/analytic/group-identifier-helper';

/**
 * Table visualization
 */
export class CloudExcludeWordsComponent {

	// bindings;
	options;
	dataObject;
	view;
	trigger;
	utils;
	words: any[];

	constructor(
		private readonly $scope,
		private readonly tableService: TableService,
		private readonly $element
	) {}

	$onInit(): void {
		let grouping = this.utils.selectedAttributes[0];
		let wordsList;
		if (grouping && grouping.wordsFilteringMode === WordsFilteringMode.INCLUDE) {
			wordsList = grouping.wordsList;
		}
		// show words selection when we have inclusion words, even when there is no data
		if (_.isEmpty(wordsList) && !this.tableService.processIfNoData(this.$element, this.dataObject,
				undefined, this.utils.widgetType, this.utils)) {
			return;
		}

		if (!this.utils || !this.utils.selectedAttributes) {
			return;
		}

		let field = GroupIdentifierHelper.getIdentifier(this.utils.selectedAttributes[0]);

		this.words = _.map(this.dataObject.data, (word) => {
			return {
				selected: false,
				name: word[field],
				displayName: this.utils.getGroupingFormatter(0)(word[field])
			};
		});

		// need to show all inclusion words, even if they didn't show in report
		if (!_.isEmpty(wordsList)) {
			let currentWords = _.map(this.words, 'name');
			let extraWords = _.chain(wordsList)
				.filter((word) => !_.contains(currentWords, word))
				.map((word) => ({name: word, displayName: this.utils.getGroupingFormatter(0)(word) }))
				.value();
			this.words = this.words.concat(extraWords);
			_.each(this.words, (word) => {
				word.selected = true; // all included words are selected by default
			});
		}
	}

	onSelectAll = () => {
		_.each(this.words, (word) => {
			word.selected = true;
		});
	}

	onSelectNone = () => {
		_.each(this.words, (word) => {
			word.selected = false;
		});
	}

	onDone = () => {
		let words = _.chain(this.words)
			.filter('selected')
			.map('name')
			.value();
		this.$scope.$emit('words-selection:save', words);
	}

	onCancel = () => {
		this.$scope.$emit('words-selection:cancel');
	}
}


// remove `component` from selector when converting to angular: <cloud-exclude-words>
app.component('cloudExcludeWordsComponent', {
	templateUrl: 'partials/widgets/views/cloud-selection.html',
	bindings: {
		options: '<',
		dataObject: '<',
		view: '<',
		trigger: '<',
		utils: '<'
	},
	controller: CloudExcludeWordsComponent
});


app.directive('cloudExcludeWords', () => {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			options: '=',
			dataObject: '=data',
			view: '=',
			trigger: '=',
			utils: '='
		},
		template: `
<cloud-exclude-words-component
	dataObject=dataObject
	options=options
	view=view
	trigger=trigger
	utils=utils>
</cloud-exclude-words-component>`
};
});
