<div class="panel panel-default widget-editing-previews-settings">
	<div class="panel-heading" [i18n]="'mAccount.widgetEditingPreviewsHeader'"></div>
	<div class="panel-body clearfix">
		<div class="d-flex normal-line-height ph-16 align-items-center mb-16">
			<cb-radio-group
				[options]="previewModeOptions"
				[ngModel]="previewsSettings?.widgetEditingPreviewMode"
				(ngModelChange)="changePreviewMode($event)">
			</cb-radio-group>
		</div>
	</div>
</div>
