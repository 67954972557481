import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ContentVisualizationBaseComponent } from '@app/modules/widget-visualizations/content-widgets/content-visualization-base';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ImageWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';

@Component({
	selector: 'content-image',
	template: `
		<div class="view-image kb-focusable-border w-100-percent h-100-percent" tabindex="0">
			<img *ngIf="!!imageUrl"
				#imageElement
				class="kb-focusable-inset"
				width="100%" height="100%"
				[style.object-fit]="ignoreRatio ? '' : 'contain'"
				[src]="imageUrl"
				[attr.alt]="altText"
				[attr.title]="title">
			<empty-content class="border-radius-widget-default" *ngIf="empty" icon="q-icon-photo"></empty-content>
		</div>
	`,
	styles: [`
		.view-image {
			border: none;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentImageComponent extends ContentVisualizationBaseComponent<ImageWidgetProperties>
	implements OnInit, AfterViewInit {

	imageUrl: string;
	altText: string;
	title: string;
	ignoreRatio: boolean;

	@ViewChild('imageElement') imageElement: ElementRef<HTMLElement>;

	constructor(
		readonly ref: ChangeDetectorRef,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
		@Inject('urlService') private readonly urlService: UrlService,
	) {
		super(ref, reportUtils);
	}

	ngOnInit(): void {
		let url = this.getImageUrl();
		if (!url) {
			this.showEmpty();
		} else {
			this.imageUrl = url;
			this.altText = this.widget.properties.altText || '';
			this.title = this.widget.description || '';
			this.ignoreRatio = this.widget.properties.ignoreRatio;
		}
	}

	private getImageUrl(): string | null {
		if (this.widget.properties.imageUrl)
			return this.urlService.getImageUrl(this.widget.properties.imageUrl);
		if (this.widget.properties.imageName)
			return this.urlService.getDashboardImageUrl(this.widget.properties.imageName, this.widget.dashboardId);
		return null;
	}

	ngAfterViewInit(): void {
		if (this.imageUrl) {
			this.addImageLoadingHandler(this.imageElement.nativeElement);
		}
	}


}
