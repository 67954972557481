import * as _ from 'underscore';


export class TemplateFillPlaceholdersListController implements ng.IController {

	placeholders: any[];
	groupTitle: string;
	fillGroupTitle: string;
	templateOptions: any;
	type: string;

	constructor() {}

	$onInit(): void {

	}
}

app.component('templateFillPlaceholdersList', {
	controller: TemplateFillPlaceholdersListController,
	templateUrl: 'partials/dashboard-templates/template-fill-placeholders-list.html',
	bindings: {
		placeholders: '=',
		templateOptions: '<',
		groupTitle: '@',
		fillGroupTitle: '@',
		type: '@'
	},
});
