import Widget from '@cxstudio/dashboards/widgets/widget';
import * as _ from 'underscore';

export default class PDFExportUtils {

	static toExport<T>(keys: string[] | number[], widgets: any[], extractor: (widgetId: number) => T): {[widgetId: number]: T} {
		let result: {[widgetId: number]: T} = {};
		_.each(keys, (widgetIdStr) => {
			let widgetId = Number(widgetIdStr);
			// using widget position as a key because widgetId will be changed in snapshot
			let widgetPositionId = this.convertIdToPosition(widgetId, widgets);
			if (_.isUndefined(widgetPositionId))
				return;

			let item: T = extractor(widgetId);
			result[widgetPositionId] = item;
		});
		return _.isEmpty(result) ? undefined : result;
	}

	static convertIdToPosition(id: number, widgets: any[]): number {
		let widget = _.findWhere(widgets, {id: Number(id)});
		return widget && (widget.posY * 100 + widget.posX);
	}

	static findWidgetByPositionId(position: number, widgets: any[]): Widget {
		let posId = Number(position);
		let posY = Math.floor(posId / 100);
		let posX = posId % 100;

		let filteredWidgets = _.filter(widgets, (widget: Widget) => {
			return widget.name !== 'page_break';
		});

		return _.findWhere(filteredWidgets, {posX, posY});
	}

	private constructor(
	) {}
}
