import * as _ from 'underscore';

import { ChangeDetectionStrategy, Component, Inject, Input, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { ContentProviderWithAccounts, UserManagementHelperService }
	from '@app/modules/user-administration/user-management-helper.service';
import { LicenseType } from '@cxstudio/common/license-types';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { MasterAccountDefaultPermissions, MasterAccountDefaultPermissionsUtils }
	from '@app/modules/account-administration/license-permissions/master-account-default-permissions.class';
import { UserEditData } from '@app/modules/user-administration/editor/user-edit-data';
import { UserEditFormData } from '@app/modules/user-administration/editor/user-edit-form-data';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { UsersGroupsLogicService } from '@app/modules/user-administration/services/users-groups-logic.service';
import { WorkspaceProjectsApi } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-projects.api.service';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { ContentProviderAccess } from '@cxstudio/user-administration/users/project-access/content-provider-access-class';
import { WorkspaceAccess } from '../editor/workspaces-projects-access/workspace-access';
import Group from '@cxstudio/user-administration/groups/Group';
import { UserCreateWizardHelperService } from './user-create-wizard-helper.service';
import { LicenseService } from '../license.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { License } from '@app/modules/user-administration/permissions/license.class';
import { UserPermissionsService } from '@cxstudio/services/user-permissions-service.service';
import { ObjectUtils } from '@app/util/object-utils';
import { RestUserData } from '../entities/rest-user-data';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';

export interface UserCreateWizardInput {
	userData: UserEditData;
	permissionItems: any[];
	masterAccountDefaultPermissions: MasterAccountDefaultPermissions;
	passwordPolicy: any;
	allowedDomains: Promise<AllowedDomains>;
	licenseTypes: any[];
	licenseInfo: any;
	sourceUserId?: number;
}

export interface UserCreateOptions {
	permissions?: any[];
	customFieldName?: string;
}

export interface SelectedAccountsData {
	initialSelectedAccounts: AccountsMap;
	selectedAccounts: AccountsMap;
}

export interface AccountsMap {
	[cpId: number]: number[];
}

interface UserCreateStepBtnClickHandlers {
	next?: () => void;
	back?: () => void;
}

interface UserCreateDialogModel {
	loading: any;
	projectsLoading?: any;
	done: boolean;
	errors: any;
	loadingMessage: string;
	skipLinkUser: boolean;
	saving?: boolean;
}

@Component({
	selector: 'user-create-wizard',
	templateUrl: './user-create-wizard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateWizardComponent implements OnInit {

	readonly MAIN_STEP_INDEX = 0;
	readonly PERMISSIONS_STEP_INDEX = 1;
	readonly GROUPS_STEP_INDEX = 2;
	readonly LINKING_STEP_INDEX = 3;
	readonly PROJECT_ACCESS_STEP_INDEX = 4;
	readonly FINISH_STEP_INDEX = 5;

	@Input() input: UserCreateWizardInput;

	@ViewChild('userDialog', {static: true}) public userDialog: FormGroup;

	userDialogValid: boolean;

	currentStep: number;
	mode: CxWizardMode;
	model: UserCreateDialogModel;

	user: UserEditFormData;
	options: UserCreateOptions;

	currentMasterAccount: CurrentMasterAccount;

	initialGroups: Group[];

	selectedAccounts: AccountsMap;
	initialSelectedAccounts: AccountsMap;
	contentProvidersWithAccounts: ContentProviderWithAccounts[];

	contentProvidersAccountsAccess: ContentProviderAccess[];
	workspacesAccess: WorkspaceAccess[];

	isWorkspaceEnabled: boolean;

	selectedLicense: License;
	licenseTypes: License[];
	licenseInfo: any;

	permissionsStepHandlers: UserCreateStepBtnClickHandlers;
	groupsStepHandlers: UserCreateStepBtnClickHandlers;
	linkingStepHandlers: UserCreateStepBtnClickHandlers;
	projectsAccessStepHandlers: UserCreateStepBtnClickHandlers;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		private readonly modal: NgbActiveModal,
		private readonly workspaceProjectsApi: WorkspaceProjectsApi,
		private readonly userCreateWizardHelperService: UserCreateWizardHelperService,
		@Inject('security') private readonly security: Security,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly userManagementHelper: UserManagementHelperService,
		private readonly licenseService: LicenseService,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
		private readonly usersGroupsService: UsersGroupsLogicService,
		private readonly userModificationService: UserModificationApiService,
	) {}

	ngOnInit(): void {
		this.currentStep = this.MAIN_STEP_INDEX;
		this.mode = CxWizardMode.EDIT;
		this.initStepHandlers();
		this.initData();
	}

	updateActiveStepIndex = (index: number): void => {
		this.currentStep = index;
	}

	private initStepHandlers(): void {
		this.permissionsStepHandlers = {
			next: (): void => {
				if (this.isAddingToGroupAllowed()) {
					this.goToGroupsStep();
				} else {
					if (this.isWorkspaceEnabled) {
						this.currentStep = this.PROJECT_ACCESS_STEP_INDEX;
					} else {
						this.goToLinkingStep();
					}
				}
			}
		};

		this.groupsStepHandlers = {
			next: (): void => {
				if (this.isWorkspaceEnabled) {
					this.currentStep = this.PROJECT_ACCESS_STEP_INDEX;
				} else {
					this.goToLinkingStep();
				}
			}
		};

		this.linkingStepHandlers = {
			next: (): void => {
				this.goToProjectsStep();
			},
			back: (): void => {
				if (!this.isAddingToGroupAllowed()) {
					this.goToPermissionsStep();
				} else {
					this.currentStep--;
				}
			}
		};

		this.projectsAccessStepHandlers = {
			back: (): void => {
				if (this.isWorkspaceEnabled) {
					this.goToGroupsStep();
				} else {
					this.goToLinkingStep();
				}
			}
		};
	}

	goToPermissionsStep = (): void => {
		this.currentStep = this.PERMISSIONS_STEP_INDEX;
	}

	goToGroupsStep = (): void => {
		this.currentStep = this.GROUPS_STEP_INDEX;
	}

	goToLinkingStep = (): void => {
		if (this.security.has('system_administration')) {
			this.currentStep = this.LINKING_STEP_INDEX;
			return;
		}

		this.userApiService.getLinkedCpAccounts().then((response) => {
			let currentUserAccounts = response.data;
			if (this.userManagementHelper.isOnlyOneAccountSelected(currentUserAccounts)) {
				this.selectedAccounts = currentUserAccounts;
				this.goToProjectsStep();
			} else {
				this.currentStep = this.LINKING_STEP_INDEX;
			}
			this.ref.detectChanges();
		});
	}

	goToProjectsStep = (): void => {
		let diff = this.userManagementHelper.getLinkedAccountsChange(
			this.selectedAccounts, this.initialSelectedAccounts);

		this.model.projectsLoading = this.userManagementHelper.getLinkedToCurrentUserAccounts(this.selectedAccounts, diff);
		this.model.projectsLoading.then((contentProvidersWithAccounts: ContentProviderWithAccounts[]) => {
			if (contentProvidersWithAccounts.length > 0) {
				this.contentProvidersWithAccounts = contentProvidersWithAccounts;
				this.currentStep = this.PROJECT_ACCESS_STEP_INDEX;
				this.ref.detectChanges();
			} else {
				this.save();
			}
		});
	}

	private initData(): void {
		this.currentMasterAccount = this.security.getCurrentMasterAccount();
		this.selectedAccounts = {};

		for (let permissionItem of this.input.permissionItems) {
			delete permissionItem.$$hashKey;
		}

		this.licenseTypes = this.input.licenseTypes;
		this.licenseInfo = this.input.licenseInfo;

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.initialGroups = ObjectUtils.copy(this.input.userData.user.groups);
		this.initialGroups.forEach(group => group.selected = false);

		let applicationPermissions = this.input.userData.user.permissions.application;

		this.contentProvidersAccountsAccess = [];
		this.workspacesAccess = [];

		const inputUserLicense = this.input.userData.user.licenseTypeId;
		this.user = {
			firstName: this.input.userData.user.firstName === 'null' ? '' : this.input.userData.user.firstName,
			lastName: this.input.userData.user.lastName === 'null' ? '' : this.input.userData.user.lastName,
			email: this.input.userData.user.email,
			countryCode: '',
			customField: this.input.userData.user.customField,
			defaultMasterAccountId: this.input.userData.user.defaultMasterAccountId,
			linkedMasterAccounts: this.input.userData.linkedMasterAccounts,
			groups: this.input.userData.user.groups,
			permissions: this.input.userData.user.permissions.own,
			groupPermissions: this.input.userData.user.permissions.group,
			implicitPermissions: [],
			customerAdmin: this.input.userData.user.permissions.system?.customerAdmin || false,

			systemAdministrator: applicationPermissions.systemAdmin,
			liteAdmin: applicationPermissions.liteAdmin,
			studioApiUser: applicationPermissions.studioApiUser,
			platformApiUser: applicationPermissions.platformApiUser,
			manageInternalTemplates: applicationPermissions.manageInternalTemplates,
			shareInternalTemplates: applicationPermissions.shareInternalTemplates,
			createInternalTemplates: applicationPermissions.createInternalTemplates,
			downloadTrainingData: applicationPermissions.downloadTrainingData,
			licenseTypeId: inputUserLicense || this.selectDefaultLicenseId(),
			masterAccounts: [ this.security.getMasterAccountId() ],
		};

		this.initLicense(this.user.licenseTypeId);
		if (!inputUserLicense) {
			this.setInitialPermissions();
		}

		this.model = {
			loading: null,
			done: false,
			errors: null,
			loadingMessage: this.locale.getString('common.spinnerCreatingUser'),
			skipLinkUser: false
		};
	}

	private selectDefaultLicenseId = (): LicenseType => {
		if (!this.userCreateWizardHelperService.isNeedToCountLicenses(this.licenseInfo, this.user)) {
			return LicenseType.CX_STUDIO;
		}

		let studioPlacesAvailable = this.licenseService
			.getAvailableLicenses(this.licenseInfo, LicenseType.CX_STUDIO);

		let analyzePlacesAvailable = this.licenseService
			.getAvailableLicenses(this.licenseInfo, LicenseType.ANALYZE);

		if (studioPlacesAvailable <= 0 && analyzePlacesAvailable > 0) {
			return LicenseType.ANALYZE;
		}

		return LicenseType.CX_STUDIO;
	}

	ok = (): void => {
		this.modal.close();
	}

	cancel = (): void => {
		this.modal.dismiss('cancel');
	}

	isFinishEnabled = (): boolean => {
		if (this.licenseLimitExceeded() || this.model.saving) {
			return false;
		}

		if (this.user.licenseTypeId === undefined) {
			return false;
		}

		return (this.currentStep === this.LINKING_STEP_INDEX && this.model.skipLinkUser)
			|| (!this.isCPLinkingAllowed() && this.userDialogValid)
			|| this.currentStep === this.PROJECT_ACCESS_STEP_INDEX;
	}

	private licenseLimitExceeded = (): boolean => {
		return this.userCreateWizardHelperService.licenseLimitExceeded(this.licenseInfo, this.user);
	}

	goToFinishStep(): void {
		if (this.isCPLinkingAllowed() && !this.model.skipLinkUser) {
			this.currentStep = this.FINISH_STEP_INDEX;
		}
	}

	save = (): void => {
		if (!this.model.saving) {
			this.model.saving = true;
			if (this.userCreateWizardHelperService.isAccountOwner() && this.currentMasterAccount.customField !== this.options.customFieldName) {
				this.masterAccountApiService.updateCustomFieldName(this.options.customFieldName).then(() => {
					this.currentMasterAccount.customField = this.options.customFieldName;
					this.saveUser();
				});
			} else {
				this.saveUser();
			}
		}
	}

	private saveUser(): void {
		this.user.selectedAccounts = this.selectedAccounts;
		if (!_.isUndefined(this.user.userId)) {
			this.addUserToMasterAccount();
		} else {
			this.addUser();
		}
	}

	isCPLinkingAllowed = (): boolean => {
		let license = _.findWhere(this.licenseTypes, {
			licenseTypeId: this.user.licenseTypeId
		});

		return this.licenseService.isCPLinkingAllowed(license);
	}

	isAddingToGroupAllowed = (): boolean => {
		return this.user.groups.length > 0 && this.hasManageGroups();
	}

	private hasManageGroups = (): boolean => {
		return this.security.has('manage_groups');
	}

	private addUser = (): void => {
		let userUpdateData = this.getUserData();
		this.model.loading = this.userApiService.addUser(userUpdateData)
			.then(this.saveUserCallback, this.saveUserErrorCallback);
		this.goToFinishStep();
	}

	private addUserToMasterAccount = (): void => {
		let userUpdateData = this.getUserData();
		let userId = this.user.userId;
		this.model.loading = this.userApiService.addUserToMasterAccount(userId, userUpdateData)
			.then(this.saveUserCallback, this.saveUserErrorCallback);
		this.goToFinishStep();
	}

	private getUserData = (): RestUserData => {
		return this.userManagementHelper.getRestUserData(this.user);
	}

	private saveUserErrorCallback = (reason: any): void => {
		this.model.loading = false;
		this.model.done = true;
		this.model.saving = false;
		this.model.errors = reason;
	}

	private saveUserCallback = (response: any): void => {
		this.model.saving = false;
		if (response.status === 202 && response.data.message === 'License limit exceeded') {
			this.currentStep = 0; // going back to first page
			return;
		}

		if (this.initialGroups && this.user.groups && this.initialGroups !== this.user.groups) {
			let addedGroups = this.usersGroupsService.findAddedGroups(this.initialGroups, this.user.groups);
			let userId = response.updateUser ? response.userId : response.data.userId;
			if (addedGroups.length) {
				this.model.loading = this.userModificationService.updateGroupsForUser(userId, {
					added: addedGroups,
					removed: []
				});
			}
		}

		if (this.isWorkspaceEnabled) {
			if (this.user.licenseTypeId !== LicenseType.CX_STUDIO_BASIC) {
				let updateChainPromise = this.workspacesAccess.reduce((promise, workspace) => {
					return promise.then(() => this.workspaceProjectsApi
						.updateProjectsAccess(workspace.workspaceId, response.data.userId, workspace)
					);
				}, Promise.resolve());

				this.model.loading = updateChainPromise;

				updateChainPromise.then(() => {
					this.modal.close();
				});
			} else {
				this.modal.close();
			}
		} else {
			if (!this.model.skipLinkUser && this.isCPLinkingAllowed()) {
				const newUser = _.isUndefined(this.user.userId);
				let initialAccounts = newUser ? {} : this.initialSelectedAccounts;
				let diff = this.userManagementHelper.getLinkedAccountsChange(
					this.user.selectedAccounts, initialAccounts);

				let modifiedUserId = newUser ? response.data.userId : this.user.userId;
				let linkingInfo = {
					userId: modifiedUserId,
					currentLicenseTypeId: this.user.licenseTypeId,
					addedAccounts: diff.added,
					remainedAccounts: diff.remained,
					removedAccounts: diff.removed,
					projectsAccess: {
						cpAccounts: this.contentProvidersAccountsAccess
					}
				};

				if (!this.userManagementHelper.isEmptyLinkingAccounts(linkingInfo)) {
					this.syncCPUsers(linkingInfo);
				} else {
					this.modal.close();
				}
			} else {
				this.modal.close();
			}
		}
	}

	syncCPUsers = (linkingInfo: any) => {
		let options = {
			forceUpdate: true,
			showDialog: false
		};

		this.model.loading = this.userApiService
			.syncUserInContentProviders(linkingInfo, options).then((errorMessages) => {
				this.model.done = true;
				this.model.errors = errorMessages;
			});
	}

	// callbacks

	updateDialogValidity(valid: boolean): void {
		this.userDialogValid = valid;
	}

	updateOptions(options: UserCreateOptions): void {
		this.options = options;
	}

	initLicense(selectedLicenseId: number): void {
		let selectedLicense = _.findWhere(this.licenseTypes, {licenseTypeId: selectedLicenseId});
		this.selectedLicense = selectedLicense;
	}

	updateSelectedLicense(selectedLicenseId: number): void {
		this.initLicense(selectedLicenseId);
		this.setInitialPermissions();
	}

	setInitialPermissions(): void {
		let licenseDefaultPermissions = MasterAccountDefaultPermissionsUtils.getLicenseDefaultPermissions(
			this.input.masterAccountDefaultPermissions, this.user.licenseTypeId);
		UserPermissionsService.setToDefaultPermissions(this.user, this.licenseTypes, licenseDefaultPermissions);
		UserPermissionsService.grantLicensePermissions(this.user, this.licenseTypes, this.input.permissionItems);
	}

	updateAccounts(selectedAccountsData: SelectedAccountsData): void {
		this.initialSelectedAccounts = selectedAccountsData.initialSelectedAccounts;
		this.selectedAccounts = selectedAccountsData.selectedAccounts;
		if (!this.isWorkspaceEnabled && !this.model.skipLinkUser) {
			this.rerenderContentProviders();
		}
	}

	onAccountsReset(): void {
		this.updateAccounts({
			initialSelectedAccounts: ObjectUtils.copy(this.input.userData.linkedAccounts),
			selectedAccounts: ObjectUtils.copy(this.input.userData.linkedAccounts)
		});
	}

	private rerenderContentProviders(): void {
		this.model.skipLinkUser = true;
		setTimeout(() => {
			this.model.skipLinkUser = false;
		});
	}

	permissionsChangedCallback = (selectedPermissions) => {
		this.user.permissions = selectedPermissions && selectedPermissions.selectedPermissions;
	}

	updateSkipLinkUser(skipLinkUser: boolean): void {
		this.model.skipLinkUser = skipLinkUser;
	}

	changeProjectAccess = (updatedAccessLevel: ContentProviderAccess[]): void => {
		this.contentProvidersAccountsAccess = updatedAccessLevel;
	}

	updateWorkspacesAccess = (workspacesAccess: WorkspaceAccess[]): void => {
		this.workspacesAccess = workspacesAccess;
	}

}

app.directive('userCreateWizard', downgradeComponent({ component: UserCreateWizardComponent }) as angular.IDirectiveFactory);
