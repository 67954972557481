import { CxLocaleService } from '@app/core';
import { ColDef, GetDataPath } from 'ag-grid-community';
import { NameRendererComponent } from '../cell-renderers/name-renderer/name-renderer.component';
import { GenericObjectList } from './generic-object-list.class';
import { AgGridNested } from './ag-grid-nested.interface';
import { OnInit, Directive } from '@angular/core';
import { ColumnType } from '@app/modules/object-list/types/column-type';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';

@Directive()
export class ObjectListWithFolders extends GenericObjectList implements OnInit {
	defaultAutoGroupColumnDef: ColDef;

	constructor(
		protected readonly locale: CxLocaleService,
	) {
		super();
	}

	ngOnInit() {
		const headerName = this.getAutoGroupColumnName();
		this.defaultAutoGroupColumnDef = {
			type: ColumnType.TEXT,
			field: this.getNameField(),
			headerName,
			headerTooltip: headerName,
			headerCheckboxSelection: this.isCheckboxVisible(),
			cellClass: 'cursor-pointer',
			cellRenderer: 'agGroupCellRenderer',
			cellRendererParams: {
				innerRenderer: NameRendererComponent,
				innerRendererParams: {
					nameField: this.getNameField(),
					getNameCellIcon: (data) => this.getNameCellIcon(data),
				},
				checkbox: this.isCheckboxVisible(),

			},
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'notContains'
				],
				buttons: ['reset'],
			},
			tooltipField: this.getNameField(),
			getQuickFilterText: (params) => params.value,
		};
	}

	protected getAutoGroupColumnName() {
		return this.locale.getString('common.name');
	}

	protected getNameField(): string {
		return 'name';
	}

	protected getNameCellIcon(data: AgGridNestable): string {
		return undefined;
	}

	protected isCheckboxVisible(): boolean {
		return false;
	}

	getDataPath: GetDataPath = (data: AgGridNested) => data.path;
}
