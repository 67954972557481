import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { ChatMessage } from '@cxstudio/reports/document-explorer/chat/chat-message.class';
import { ConversationAttributes } from '@cxstudio/reports/document-explorer/conversations/conversation-attributes.constant';
import { ChannelTypes } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ConversationParticipants, IParticipantOption } from './conversation-participants.class';


@Injectable()
export class ConversationParticipantService {

	private defaultColorPalette: WidgetColorPalette;

	constructor(
		@Inject('colorPalettes') private colorPalettes: ColorPalettes
	) {}

	refreshDefaultColorPalette = (): ng.IPromise<void> => {
		return this.colorPalettes.getDefaultPalette().then(colorPalette => {
			this.defaultColorPalette = colorPalette;
		});
	}

	// populate index only if a participant for the previous chat message differs for the current one
	populateChatMessagesParticipantsIndexes = (chatMessages: ChatMessage[], participants: ConversationParticipants): void => {
		_.each(chatMessages, chatMessage => {
			if (!chatMessage.isSameChannelAsLast) {
				chatMessage.participantIndex = this.getChatMessageParticipantIndex(chatMessage, participants);
			}
		});
	}

	// return index if it's present otherwise -1
	private getChatMessageParticipantIndex = (chatMessage: ChatMessage, participants: ConversationParticipants): number => {
		let channelParticipants: IParticipantOption[] = participants[chatMessage.channel];
		if (!channelParticipants || channelParticipants.length < 2) {
			return -1;
		}

		let participantId = chatMessage?.sentences[0]?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_ID][0];
		let participantType = chatMessage?.sentences[0]?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_TYPE][0];
		return this.getParticipantIndex(channelParticipants, participantId, participantType);
	}

	private getParticipantIndex(
		channelParticipants: IParticipantOption[], participantId: number | string, participantType: string
	): number {
		return _.findIndex(channelParticipants, channelParticipant =>
			channelParticipant.id + '' === participantId + '' && channelParticipant.type === participantType);
	}

	populateSentencesParticipantsIndexes = (sentences: ConversationSentence[], participants: ConversationParticipants): void => {
		_.each(sentences, sentence => sentence.participantIndex = this.getSentenceParticipantIndex(sentence, participants));
	}

	// return index if it's present otherwise -1
	private getSentenceParticipantIndex = (sentence: ConversationSentence, participants: ConversationParticipants): number => {
		let channelParticipants: IParticipantOption[] = participants[sentence.channel];
		if (!channelParticipants || channelParticipants.length < 2) {
			return -1;
		}

		let participantId = sentence?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_ID][0];
		let participantType = sentence?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_TYPE][0];
		return this.getParticipantIndex(channelParticipants, participantId, participantType);
	}

	getIconColor = (channel: string, participantIndex: number): string => {
		if (!this.defaultColorPalette) {
			return '';
		}

		if (channel === ChannelTypes.UNKNOWN) {
			if (participantIndex >= 0) {
				let colorsAmount = this.defaultColorPalette.colors.length;
				return this.defaultColorPalette.colors[participantIndex % colorsAmount];
			} else {
				return this.defaultColorPalette.colors[0];
			}
		}
	}

	getParticipantLabel = (channelLabel: string, participantIndex: number, sameChannelAsLast: boolean = false): string => {
		if (sameChannelAsLast) {
			return '';
		}

		return participantIndex >= 0
			? channelLabel + ' ' + this.getDisplayParticipantIndex(participantIndex)
			: channelLabel;
	}

	private getDisplayParticipantIndex = (participantIndex): number => {
		return participantIndex + 1;
	}
}

app.service('conversationParticipantService', downgradeInjectable(ConversationParticipantService));
