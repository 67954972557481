import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RequestAssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters-factory.class';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ReportModelsApiService } from '@app/modules/project/model/report-models-api.service';
import { ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';

@Injectable({
	providedIn: 'root'
})
export class ReportModelsService {
	constructor(
		private readonly reportModelsApi: ReportModelsApiService,
		private readonly projectAssetsHelper: ProjectAssetsHelper,
		private readonly modelsService: ModelsService,
	) {}

	getWidgetModels(widget: Widget): Promise<IReportModel[]> {
		let requestParams = RequestAssetParametersFactory.fromWidget(widget);
		let withHierarchies = true;
		return this.reportModelsApi.getModels(requestParams, withHierarchies);
	}

	getWidgetModelsWithoutHierarchies(widget: Widget): Promise<IReportModel[]> {
		let requestParams = RequestAssetParametersFactory.fromWidget(widget);
		let withHierarchies = false;
		return this.reportModelsApi.getModels(requestParams, withHierarchies);
	}

	getDashboardModels(dashboard: Dashboard): Promise<IReportModel[]> {
		return this.projectAssetsHelper.getDashboardProjectData<IReportModel[]>(
			dashboard,
			(project) => this.modelsService.getModels(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportModelsApi.getModels(viewParams),
				() => [])
		);
	}

	getWidgetUserHiddenModels(widget: Widget): Promise<IReportModel[]> {
		return this.getWidgetModels(widget)
			.then(_.identity, () => [])
			.then(attributes => attributes.filter(attr => attr.hide));
	}

	getDashboardModelTree(dashboard: Dashboard, modelId: number): Promise<ModelTree> {
		return this.projectAssetsHelper.getDashboardProjectData<ModelTree>(
			dashboard,
			(project) => this.modelsService.getModelTree(project, modelId),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportModelsApi.getModelTree(viewParams, modelId),
				() => undefined)
		);
	}

	getWidgetModelTree(widget: Widget, modelId: number): Promise<ModelTree> {
		return this.projectAssetsHelper.getWidgetProjectData<ModelTree>(
			widget,
			(project) => this.modelsService.getModelTree(project, modelId),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportModelsApi.getModelTree(viewParams, modelId),
				() => undefined)
		);
	}

	getWidgetNodeInfo(widget: Widget, nodeId: number): Promise<ModelTreeNode> {
		return this.projectAssetsHelper.getWidgetProjectData<ModelTreeNode>(
			widget,
			(project) => this.modelsService.getModelNode(project, nodeId),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportModelsApi.getNodeInfo(viewParams, nodeId),
				() => undefined)
		);
	}
}

app.service('reportModelsService', downgradeInjectable(ReportModelsService));
