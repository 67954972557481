import { Label } from '@cxstudio/dashboards/entity/label';


export default class BulkUpdateLabelsEntity {
	labels: Label[];
	ids: number[];


	constructor(labels: Label[], ids: number[]) {
		this.labels = labels;
		this.ids = ids;
	}
	
}