import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'dropdown-toggle-wrapper',
	template: `
		<ng-content></ng-content>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownToggleWrapperComponent {

}
