<modal-header
	[modalTitle]="'administration.editReportableParameters' | i18n"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<inclusion-list
		[allItems]="availableAttributes"
		[selectedItems]="selectedAttributes"
		(selectedItemsChange)="updateSelectedAttributes($event)"
		[selectedItemsLabelCallback]="selectedAttributesLabelCallback"
		[availableItemsLabelCallback]="availableAttributesLabelCallback"
	></inclusion-list>
</div>

<save-modal-footer
	(cancel)="cancel()"
	(save)="done()"
	[saveText]="'common.done' | i18n"
	[cancelText]="'common.cancel' | i18n"
	[isDisabled]="loading">
</save-modal-footer>

