import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { PagedResponse } from '@cxstudio/paged-response';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import MasterAccountSearch from '@app/modules/system-administration/master-account/entities/master-account-search';
import { MasterAccountQuery } from '@app/modules/system-administration/master-account/entities/master-account-query';
import MasterAccountSamlLink from '@app/modules/system-administration/master-account/entities/master-account-saml-link';

export interface MasterAccountEditContext {
	hasAdminUserAsDefault: boolean;
	customerAdminsCount: number;
}

@Injectable()
export class MasterAccountManageApi {

	constructor(
		private cxHttp: CxHttpService
	) {}

	getEditContext = (masterAccountId: number): Promise<MasterAccountEditContext> => {
		return this.cxHttp.get(`rest/system_admin/master_account/${masterAccountId}/edit-context`);
	}

	getMasterAccounts = (search: MasterAccountSearch, pagination: Pagination): Promise<PagedResponse<MasterAccount>> => {
		let url = 'rest/system_admin/master_account/paged';
		let params = this.getQueryParams(search, pagination);
		return this.cxHttp.get(url, params);
	}

	getExternalSamlEnabledMasterAccounts = (): Promise<MasterAccountSamlLink[]> => {
		return this.cxHttp.get('rest/system_admin/master_account/list/external/saml/enabled');
	}

	disableMasterAccount = (masterAccountId: number|string): Promise<void> =>
		this.cxHttp.post(`rest/system_admin/master_account/${masterAccountId}/disable`)

	enableMasterAccount = (masterAccountId: number|string): Promise<void> =>
		this.cxHttp.post(`rest/system_admin/master_account/${masterAccountId}/enable`)

	deleteMasterAccount = (masterAccountId: number|string): Promise<void> => {
		let params = new HttpParams().append('mode', 'remove');
		return this.cxHttp.delete(`rest/system_admin/master_account/${masterAccountId}`, { params });
	}

	updateMasterAccount = (masterAccount: MasterAccount): Promise<void> =>
		this.cxHttp.put('rest/system_admin/master_account/update', angular.toJson(masterAccount))

	addMasterAccount = (masterAccount: MasterAccount): Promise<MasterAccount> =>
		this.cxHttp.post('rest/system_admin/master_account/register', masterAccount)

	private getQueryParams(search: MasterAccountSearch, pagination: Pagination): CxHttpRequestOptions<HttpParams> {
		let queryParams: MasterAccountQuery = new MasterAccountQuery(search, pagination);
		return {
			params: queryParams.toHttpParams()
		};
	}
}

app.service('masterAccountManageApi', downgradeInjectable(MasterAccountManageApi));
