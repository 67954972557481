<form #unifiedLinksForm="ngForm" class="form-horizontal">

	<div class="row form-group">
		<label class="control-label col-md-4">
			{{'administration.oauthClientEnableUnifiedLinks'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.oauthEnableLinksHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-1">
			<input type="checkbox" class="checkbox"
				   [(ngModel)]="additionalInformation.enableUnifiedLinks"
				   (change)="updateUnifiedLinks()"
				   [disabled]="isLegacySuiteApplication"
				   name="enableUnifiedLinks">
			<cb-inline-help *ngIf="isLegacySuiteApplication">
				<help-body [innerHTML]="'administration.oauthLinksAreDisabledForLegacyApps'|i18n"></help-body>
			</cb-inline-help>
		</div>
	</div>

	<unified-link-visibility *ngIf="showUnifiedLinksSelector()"
		[additionalInformation]="additionalInformation"
		[masterAccounts]="masterAccounts"
		(unifiedLinksVisibilityUpdated)="updateUnifiedLinksVisibility($event)">
	</unified-link-visibility>

	<div class="row form-group" *ngIf="!unifiedLinksPopulated()">
		<label class="control-label col-md-3 text-danger">
			<span class="q-icon q-icon-warning" aria-hidden="true"></span>
		</label>
		<div class="col-md-8">
			<label class="text-danger">{{'administration.oauthUnifiedLinksMustBePopulated'|i18n}}</label>
		</div>
	</div>

	<div class="row form-group" *ngIf="showUnifiedLinksSelector()">
		<label class="control-label col-md-3">
			{{'administration.oauthClientUnifiedLinks'|i18n}}
			<cb-inline-help>
				<help-body [innerHTML]="'administration.oauthLinksHelp'|i18n"></help-body>
			</cb-inline-help>
		</label>
		<div class="col-md-8">
			<button type="button" class="btn btn-primary"(click)="addLink(0)" *ngIf="showAddFirstLinkButton()">
				<span class="q-icon q-icon-add" aria-hidden="true"></span>
			</button>
		</div>
	</div>

	<div class="unified-link-list" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="showUnifiedLinksSelector()">
		<div
			*ngFor="let link of additionalInformation.unifiedLinks; index as $index; trackBy:trackUnifiedLink"
			class="row form-group unified-link-box" cdkDrag>
			<label class="control-label col-md-3"></label>
			<div class="col-md-8 d-flex align-items-center">
				<div>
					<cb-drag-handle cdkDragHandle></cb-drag-handle>
				</div>
				<div class="col-md-4">
					<input class="form-control" autocomplete="nope" [(ngModel)]="link.name"
						   name="linkName_{{$index}}"
						   placeholder="{{'administration.oauthClientUnifiedLinkName'|i18n}}"
						   (change)="updateUnifiedLinks()"
						   required>
				</div>
				<div class="col-md-4">
					<input class="form-control" autocomplete="nope" [(ngModel)]="link.url"
						   name="linkUrl_{{$index}}"
						   placeholder="{{'administration.oauthClientUnifiedLinkUrl'|i18n}}"
						   (change)="updateUnifiedLinks()"
						   required>
				</div>
				<button type="button" class="btn btn-primary"(click)="addLink($index)">
					<span class="q-icon q-icon-add" aria-hidden="true"></span>
				</button>
				<button type="button" class="btn btn-primary" (click)="removeLink($index)">
					<span class="q-icon q-icon-delete" aria-hidden="true"></span>
				</button>
			</div>
		</div>
	</div>

	<div class="row form-group">
		<label class="control-label col-md-4">
			{{'administration.oauthClientParentFolder'|i18n}}
		</label>
		<div class="col-md-1">
			<input type="checkbox" class="checkbox"
				   [(ngModel)]="additionalInformation.parentFolderEnabled"
				   name="parentFolder">
		</div>
	</div>

	<div class="row form-group" *ngIf="isParentFolderEnabled()">
		<label class="control-label col-md-3"></label>
		<div class="col-md-8 d-flex align-items-center">
			<div class="col-md-4">
				<input class="form-control" autocomplete="nope"
						name="parentFolderName"
						[(ngModel)]="additionalInformation.parentFolderName"
						required>
			</div>
		</div>
	</div>

</form>
