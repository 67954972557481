export interface SpellCheckResult {
	type: SpellCheckType;
	text: string;
	errors: SpellCheckError[];
	id?: number;
}

export enum SpellCheckType {
	DASHBOARD_TITLE = 'DASHBOARD_TITLE',
	WIDGET_TITLE = 'WIDGET_TITLE',
	EXTRA_ISSUES = 'EXTRA_ISSUES',
	WIDGET_TEXT = 'WIDGET_TEXT'
}

export interface SpellCheckError {
	start: number;
	end: number;
	token: string;
}
