import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericShareUpgradeDirective } from '@app/modules/asset-sharing/generic-share.upgrade.directive';
import { IndirectShareTableComponent } from './indirect-share-table/indirect-share-table.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { AssetShareInviteComponent } from '@app/modules/asset-sharing/asset-share-invite/asset-share-invite.component';
import { IconModule } from '@app/modules/icon/icon.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		NgPipesModule,
		IconModule,
	],
	exports: [GenericShareUpgradeDirective],
	declarations: [
		GenericShareUpgradeDirective,
		IndirectShareTableComponent,
		AssetShareInviteComponent,
	],
})
export class AssetSharingModule { }
