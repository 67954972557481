<div [ngBusy]="loading">
	<modal-header
		[modalTitle]="'common.pleaseConfirm'|i18n"
		(cancel)="cancel()"
	></modal-header>
	<div class="modal-body">
		<div>
			<div>
				<p>{{'userAdministration.deleteMultipleUsersNote' | i18n}}</p>
			</div>
		</div>
		<table class="table">
			<tbody>
				<td class="w-40-percent">
					<div class="d-flex align-center">
						<span>{{'administration.manageEverything'|i18n}}</span>
						<cb-inline-help>
							<help-body>{{'administration.manageEverythingTooltip'|i18n}}</help-body>
						</cb-inline-help>
						<input type="checkbox" class="mt-0 ml-8" [(ngModel)]="manageEverything"/>
					</div>
				</td>
				<td class="w-50-percent">
					<simple-dropdown
						[(value)]="selectedPowerCandidateId"
						[options]="powerCandidates"
						valueField="id"
						displayField="email"
						[appendToBody]="false"
						[noMinWidth]="true"
						[searchable]="true"
						[disabled]="!manageEverything">
					</simple-dropdown>
				</td>
			</tbody>
		</table>
	</div>
	<save-modal-footer
		[saveText]="'common.confirm'|i18n"
		[isDisabled]="!manageEverything"
		(save)="save()"
		(cancel)="cancel()"
	></save-modal-footer>
</div>
