<div class="ph-32 pv-16">
	<label class="d-flex">{{'common.filter'|i18n}}
		<cb-inline-help>
			<help-body>{{'alert.filterHelpText'|i18n}}</help-body>
		</cb-inline-help>
	</label>
	<filter-builder *ngIf="!!filteringOptions"
		class="dropup"
		[filterableFields]="filteringOptions"
		[ruleSet]="alert.trigger.filterRules"
		(filterChange)="validate()"
		[projectProperties]="project"
		[maxRules]="5">
	</filter-builder>
	<form-error *ngIf="!!filterRulesError" text="{{'reportFilters.incompleteRules'|i18n}}"></form-error>
</div>
