import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { AttributesFilterComponent } from '@app/modules/project/attributes-filter.component';
import { AttributesSortComponent } from './attributes-sort.component';
import { ModelsSortComponent } from '@app/modules/project/models-sort.component';
import { ModelsFilterComponent } from '@app/modules/project/models-filter.component';
import { ScorecardNodesStepComponent } from './scorecard/editor/scorecard-nodes-step/scorecard-nodes-step.component';
import { ScorecardDefinitionStepComponent } from './scorecard/editor/scorecard-definition-step/scorecard-definition-step.component';
import { ScorecardEditorService } from '@app/modules/project/scorecard/editor/scorecard-editor.service';
import { ScorecardsEditorWizardComponent } from './scorecard/editor/scorecard-editor-wizard.component';
import { ScorecardEditorWizardService } from './scorecard/editor/scorecard-editor-wizard.service';
import { AttributeGridFormatterService } from '@app/modules/project/attribute/attribute-grid-formatter.service';
import { HiddenGroupAssetsGridDefinition } from '@app/modules/project/hidden-group-asset/hidden-group-assets-grid-definition';
import { HiddenGroupAssetListComponent } from '@app/modules/project/hidden-group-asset/hidden-group-asset-list.component';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { NgPipesModule } from 'ngx-pipes';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ScorecardPreviewResultModalComponent } from './scorecard/editor/preview-result/modal/scorecard-preview-result-modal.component';
import { DialogModule } from '../dialog/dialog.module';
import { AttributeActionsService } from './attribute/attribute-actions-service';
import { ModelActionsService } from './model/model-actions-service';
import { ModelsGridDefinitionService } from './model/models-grid-definition.service';
import { AttributesGridDefinitionService } from './attribute/attributes-grid-definition.service';
import { ScorecardsGridDefinitionService } from './scorecard/scorecards-grid-definition.service';
import { AttributeManagementService } from '@app/modules/project/attribute/attribute-management.service';
import { ModelManagementService } from '@app/modules/project/model/model-management.service';
import { ProjectAccessService } from './access/project-access.service';
import { GlobalOtherExplorerModalComponent } from './global-other-explorer-modal/global-other-explorer-modal.component';
import { GlobalOtherExplorerUpgradeDirective } from '@app/modules/project/global-other-explorer-modal/global-other-explorer.upgrade.directive';
import { ProjectAssetsManagementModule } from '@app/modules/project/project-assets-management/project-assets-management.module';

@NgModule({
	imports: [
		SharedModule,
		ItemGridModule,
		NgPipesModule,
		WizardModule,
		DialogModule,
		ProjectAssetsManagementModule,
	],
	exports: [
		AttributesFilterComponent,
		AttributesSortComponent,
		ModelsSortComponent,
		ModelsFilterComponent,
		HiddenGroupAssetListComponent,
		ScorecardsEditorWizardComponent,
		ScorecardDefinitionStepComponent,
		ScorecardNodesStepComponent
	],
	declarations: [
		AttributesFilterComponent,
		AttributesSortComponent,
		ModelsSortComponent,
		ModelsFilterComponent,
		HiddenGroupAssetListComponent,
		ScorecardsEditorWizardComponent,
		ScorecardDefinitionStepComponent,
		ScorecardNodesStepComponent,
		ScorecardPreviewResultModalComponent,
		GlobalOtherExplorerModalComponent,
		GlobalOtherExplorerUpgradeDirective,
	],
	providers: [
		AttributesService,
		ReportAttributesService,
		AttributeManagementService,
		AttributeGridFormatterService,
		ModelsService,
		ReportModelsService,
		ModelManagementService,
		ScorecardEditorService,
		ScorecardEditorWizardService,
		HiddenGroupAssetsGridDefinition,
		AttributeActionsService,
		ModelActionsService,
		ModelsGridDefinitionService,
		AttributesGridDefinitionService,
		ScorecardsGridDefinitionService,
		ProjectAccessService
	]
})
export class ProjectModule {}
