import { NgModule } from '@angular/core';
import { QuickInsightsSearchComponent } from './quick-insights-search/quick-insights-search.component';
import { QuickInsightsComponent } from './quick-insights/quick-insights.component';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { QuickInsightsDashboardGridDefinition } from './quick-insights/quick-insights-dashboard-grid-definition.service';
import { HomePageCommonModule } from '@app/modules/home-page/home-page-common/home-page-common.module';
import { QuickInsightsClearComponent } from './quick-insights-clear/quick-insights-clear.component';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { QuickInsightsPageComponent } from '@app/modules/home-page/quick-insights/quick-insights/quick-insights-page/quick-insights-page.component';

@NgModule({
	imports: [
		SharedModule,
		WidgetContainerModule,
		ItemGridModule,
		HomePageCommonModule,
		FilterBuilderModule,
	],
	providers: [
		QuickInsightsDashboardGridDefinition
	],
	declarations: [
		QuickInsightsSearchComponent,
		QuickInsightsComponent,
		QuickInsightsClearComponent,
		QuickInsightsPageComponent,
	],
	exports: [
		QuickInsightsSearchComponent,
		QuickInsightsComponent
	]
})
export class QuickInsightsModule { }
