import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { FileUploadResult } from '@app/modules/hierarchy/upload/upload-result/file-upload-result';
import { FormattedTableColumnsService } from '@app/shared/components/table/formatted-table-columns.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';

@Component({
	selector: 'upload-result',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div *ngIf="result.uploadSuccess === true">{{getUploadSuccessMessage()}}</div>
		<div *ngIf="result.uploadSuccess === false">{{getUploadFailureMessage()}}</div>
		<br>
		<div *ngIf="result.validationErrors" [i18n]="'common.errorsOccurred'"></div>
		<formatted-table *ngIf="result.validationErrors"
			[rows]="result.validationErrors"
			[columns]="errorTableColumns"
			view="comfort"
			[scrollable]="true"
		></formatted-table>
		<div *ngIf="result.validationMessage" class="text-danger">{{result.validationMessage}}</div>
		<div *ngIf="result.validationWarning" class="text-warning">{{result.validationWarning}}</div>
	`
})
export class UploadResultComponent implements OnInit {
	@Input() result: FileUploadResult;

	errorTableColumns: TableColumn<any>[];

	constructor(
		private formattedTableColumnsService: FormattedTableColumnsService,
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.errorTableColumns = this.formattedTableColumnsService.getValidateErrorColumns();
	}

	getUploadSuccessMessage(): string {
		return this.result.customUploadSuccessMessage ? this.result.customUploadSuccessMessage :
			this.locale.getString('common.uploadSuccess');
	}

	getUploadFailureMessage(): string {
		return this.result.customUploadfailedMessage ? this.result.customUploadfailedMessage :
			this.locale.getString('common.uploadFailed');
	}
}

app.directive('uploadResult', downgradeComponent({component: UploadResultComponent}));
