import { NgModule } from '@angular/core';
import { DateFiltersUpgrade } from '@app/modules/widget-settings/date-filters/date-filters.upgrade.directive';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		DateFiltersUpgrade,
	],
	exports: [
		DateFiltersUpgrade,
	],
})
export class DateFiltersModule { }
