import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ObjectListModule } from '@app/modules/object-list/object-list.module';
import { AssetTemplatesTableComponent } from '@app/modules/unified-templates/common-templates/asset-templates-table/asset-templates-table.component';
import { CreateFromTemplateDialogComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/create-from-template-dialog.component';
import { TemplateReplacementFilterRuleComponent } from '@app/modules/unified-templates/common-templates/asset/template-replacement-filter-rule/template-replacement-filter-rule.component';
import { TemplateReplacementSelectorComponent } from '@app/modules/unified-templates/common-templates/asset/template-replacement-selector/template-replacement-selector.component';
import { TemplateReplacementsListComponent } from '@app/modules/unified-templates/common-templates/asset/template-replacements-list/template-replacements-list.component';
import { TemplateCreateDialogComponent } from '@app/modules/unified-templates/common-templates/template/template-create-dialog/template-create-dialog.component';
import { TemplateMappingsComponent } from '@app/modules/unified-templates/common-templates/template/template-mappings/template-mappings.component';
import { TemplatePlaceholderUsageComponent } from '@app/modules/unified-templates/common-templates/template/template-placeholder-usage/template-placeholder-usage.component';
import { TemplatePlaceholdersListComponent } from '@app/modules/unified-templates/common-templates/template/template-placeholders-list/template-placeholders-list.component';
import { TemplatePropertiesComponent } from '@app/modules/unified-templates/common-templates/template/template-properties/template-properties.component';
import { UnitsModule } from '@app/modules/units/units.module';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		DialogModule,
		UnitsModule,
		WizardModule,
		NgPipesModule,
		ObjectListModule,
		CxFormModule,
		FilterBuilderModule,
	],
	declarations: [
		TemplateCreateDialogComponent,
		TemplatePlaceholdersListComponent,
		TemplatePlaceholderUsageComponent,
		CreateFromTemplateDialogComponent,
		TemplateReplacementsListComponent,
		TemplateReplacementSelectorComponent,
		TemplateReplacementFilterRuleComponent,
		AssetTemplatesTableComponent,
		TemplatePropertiesComponent,
		TemplateMappingsComponent,
	],
	exports: [
		AssetTemplatesTableComponent,
	],
})
export class CommonTemplatesModule { }
