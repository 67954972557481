import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';

@Component({
	selector: 'alert-actions-settings',
	templateUrl: './alert-actions-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertActionsSettingsComponent implements OnInit {

	@Input() alert: StudioAlert;
	@Output() validityChange = new EventEmitter<boolean>();
	@Output() testAlert = new EventEmitter<void>();

	errorContainer = {
		notificationSettingsError: false,
	};

	constructor() { }

	ngOnInit(): void {
	}

	validateAndPopulateErrors(valid: boolean): void {
		this.errorContainer.notificationSettingsError = this.validateNotification();
		this.validityChange.emit(valid && !this.errorContainer.notificationSettingsError);
	}

	private validateNotification(): boolean {
		let linkedDashboard = this.alert.notification?.linkDashboard;
		if (this.alert.notification && !linkedDashboard) {
			let messages = [this.alert.notification.message];
			if (this.alert.notification.notifyByEmail) {
				messages.pushAll([this.alert.notification.emailBody, this.alert.notification.emailSubject]);
			}

			return messages.some(msg => this.containLinkedDashboardPlaceHolder(msg));
		}
		return false;
	}

	private containLinkedDashboardPlaceHolder(message: string): boolean {
		return message && message.indexOf('{alertDashboard}') > -1;
	}
}
