import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import * as _ from 'underscore';
import { ContextMenuBaseController } from './context-menu-base-controller';


export class ContextMenuController extends ContextMenuBaseController {

	constructor(
		contextMenuTree: ContextMenuTree,
		$element: ng.IAugmentedJQuery,
		$timeout: ng.ITimeoutService
	) {
		super(contextMenuTree, $element, $timeout);
	}

}

app.component('contextMenu', {
	controller: ContextMenuController,
	templateUrl: 'partials/context-menu/context-menu-component.html',
	bindings: {
		options: '<',
		object: '<',
		posX: '<',
		posY: '<',
		maxWidth: '<',
		className: '@',
		onMenuClose: '&',
		onMenuInit: '&?',
		onKeyAction: '&?'
	}
});
