import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { CalendarSettingsEntry } from '../calendar-settings.entity';
import { CalendarFormatListActions } from './calendar-format-list-actions.service';


@Injectable()
export class CalendarFormatContextMenu extends BaseContextMenuUtils {

	private readonly OPTIONS: {[name: string]: ContextMenuItem<CalendarSettingsEntry>} = {
		EDIT: {
			name: 'edit',
			text: this.locale.getString('common.edit'),
			func: (settings: CalendarSettingsEntry) => this.calendarFormatListActions.edit(settings)
		}
	};

	constructor(private locale: CxLocaleService, private calendarFormatListActions: CalendarFormatListActions) {
		super();
	}

	getContextMenu(item: CalendarSettingsEntry): Array<ContextMenuItem<CalendarSettingsEntry>> {
		let options: Array<ContextMenuItem<CalendarSettingsEntry>> = [this.OPTIONS.EDIT];
		return options;
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}
