import { GridsterStateService } from '@app/modules/gridster/gridster-state.service';

app.directive('gridsterDragHandle', (gridsterState: GridsterStateService, $timeout: ng.ITimeoutService) => {
	return {
		restrict: 'A',
		link: () => {
			$timeout(() => gridsterState.refreshDragHandles(), 1);
		}
	};
});
