import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { logoutEndpointValidator } from './logout-endpoint-validator.directive';

@Directive({
	selector: '[validateLogoutEndpoint]',
	providers: [{ provide: NG_VALIDATORS, useExisting: LogoutEndpointValidatorDirective, multi: true }]
})
export class LogoutEndpointValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		return logoutEndpointValidator()(control);
	}
}