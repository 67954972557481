import { PersonalizationStateProvider } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state-provider.service';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';

export class DashboardPersonalizationProvider {
	constructor(
		private $rootScope, 
		private personalizationStateProvider: PersonalizationStateProvider
	) {}

	/**
	 * @param dashboard dashboard for wrapping into personalization object
	 */
	getInstance(dashboard, containerId: string): PersonalizationState {
		return this.getInstanceInternal(dashboard, containerId, null);
	}

	getViewAsInstance(dashboard, viewAs: string): PersonalizationState {
		return this.getInstanceInternal(dashboard, null, viewAs);
	}

	private getInstanceInternal(dashboard, containerId: string, viewAs: string): PersonalizationState {
		let properties = this.getProperties(dashboard);
		let hierarchyId = this.$rootScope.preselectedHierarchyId > 0
			? this.$rootScope.preselectedHierarchyId
			: (properties.allowPersonalization ? properties.hierarchyId : -1);
		let showingContext = this.getProperties(dashboard).showOrganizationContext;
		let preselectedNodeId: number = this.$rootScope.preselectedNodeId;
		return viewAs !== null
			? this.personalizationStateProvider.getViewAsInstance(hierarchyId, showingContext, viewAs, preselectedNodeId)
			: this.personalizationStateProvider.getInstance(hierarchyId, showingContext, containerId, preselectedNodeId);
	}

	private getProperties(dashboard): DashboardProperties {
		return dashboard && dashboard.properties || {};
	}
}

app.service('dashboardPersonalizationProvider', DashboardPersonalizationProvider);