import * as _ from 'underscore';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { IWidgetSettingsConfig, IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { UIOption } from '@clarabridge/unified-angular-components';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetConversionsService } from '@cxstudio/reports/providers/cb/widget-conversions.service';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { CBSettingsService, IWidgetSettingsOptions, IWidgetSettingsScope, IWidgetSettingsUI } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { DatePeriodField, DatePeriodName, DatePeriod } from '@cxstudio/reports/entities/date-period';
import { BubbleConfigurationService } from '@app/modules/widget-settings/bubble-configuration.service';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';


interface IModelViewerSettingsOptions extends IWidgetSettingsOptions {
	modelGroupings: any[];
}

export class ModelViewerDefinitionComponent implements ng.IComponentController, IWidgetSettingsScope {

	readonly DEFAULT_NODE_RADIUS_PX = 8;

	readonly DEFAULTS: Partial<VisualProperties> = {
		visualization: WidgetVisualization.HIERARCHY_TREE,
		backgroundColor: WidgetBackgroundColor.DEFAULT,
		size: '',
		scale: this.DEFAULT_NODE_RADIUS_PX,
		color: 'custom',
		showSampleSize: true,
		showLabels: true,
		orientation: DirectionalOrientation.VERTICAL,
	};

	sortDirections: Array<ListOption<string>> = [
		{ value: SortDirection.ASC, name: this.locale.getString('common.asc') },
		{ value: SortDirection.DESC, name: this.locale.getString('common.desc') }
	];

	loaded = false;
	settings: IWidgetSettings;
	constants;
	listTypes = KeyMetricListTypes;

	staticData: any;
	visualProps: VisualProperties;
	props: WidgetProperties;
	ui: IWidgetSettingsUI;
	widget: Widget;
	options: IModelViewerSettingsOptions;
	defaultColor: Partial<IColorSelectorPalette>;

	applyVisualChanges: () => void;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	clearErrors: () => void;
	sizeMetricsFilter: (metric) => boolean;
	updateCustomDateFilters: (dateFilters) => void;
	populateCurrentHierarchyCalculations: (metrics, grouping, calculations, models) => void;
	initializePeriods: () => void;
	reloadCommonSettings: (config) => ng.IPromise<IWidgetSettings>;
	configure: (attribute: AttributeGrouping, type: KeyMetricListTypes) => ng.IPromise<void>;
	conversionListener: (params: {$event: ReportWidget}) => void;

	combinedGrouping: TopicReportGrouping;
	modelLevels: UIOption<number>[];

	constructor(
		private $scope,
		private $controller: ng.IControllerService,
		private locale: ILocale,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private sampleDataApiService: SampleDataAPIServiceClass,
		private cbSettingsService: CBSettingsService,
		private bubbleConfigurationService: BubbleConfigurationService,
		private metricConstants: MetricConstants,
		private betaFeaturesService: BetaFeaturesService,
		private modelsService: ModelsService,
		private hierarchyService: HierarchyService,
		private optionsTemplatesService: OptionsTemplatesService,
		private widgetConversions: WidgetConversionsService,
		private reportSettingsService: ReportSettingsService,
		private metricCustomFormatUtils: MetricCustomFormatUtilsService,
	) {	}

	$onInit = () => {
		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this,
			selectionConfiguration: {
				multiAttributeSelection: true
			},
			customCallback: {}
		});
		this.constants = this.metricConstants.get();

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.staticData.ready = false;

		this.setDefaults();

		this.ui = this.ui || {};
		delete this.ui.periods;
		this.initProps();
		this.initialize();
	}

	$on = (event: string, handler: () => void): () => void => {
		return this.$scope.$on(event, handler);
	}

	reloadSettings = (event?, callback?: (result: boolean) => void): void => {
		this.initProps();

		let config: IWidgetSettingsConfig = {
			withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
		};

		this.reloadCommonSettings(config).then(result => {
			this.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.settings = result;

			this.options.modelGroupings = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.topics())
				.withModels(result.models)
				.build();

			this.cbSettingsService.initDefaultProperties(this.options.modelGroupings);

			this.reloadMetricsAndReferences();

			this.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
			this.options.studioMetrics = _.filter(result.metrics, (metric: any) => metric.projectId === this.props.project);

			this.updateCustomDateFilters(result.dateFilters);
			this.applyVisualChanges();
			callback?.(true);
		});
	}

	private initProps = (): void => {
		if (!this.props.selectedAttributes) {
			this.props.selectedAttributes = [];
		}

		if (!this.visualProps.attributeSelections) {
			this.visualProps.attributeSelections = {};
		}

		if (!this.props.selectedMetrics) {
			this.props.selectedMetrics = [];
			this.selectSize(this.constants.CONSTANT_SIZE);
		}

		if (!this.visualProps.sortBy) {
			this.visualProps.sortBy = StandardMetricName.ALPHANUMERIC;
		}

		if (!this.visualProps.direction) {
			this.visualProps.direction = SortDirection.ASC;
		}

		this.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		} as DatePeriod];

		this.initializePeriods();
	}

	private reloadMetricsAndReferences = (): void => {
		this.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(_.union(this.metricConstants.getStandardCalculations(), [this.constants.CONSTANT_SIZE]))
			.withPredefinedMetrics(this.settings.predefinedMetrics)
			.withAttributes(this.options.attributes, MetricFilters.CALCULATION)
			.withMetrics(this.settings.metrics, this.props.project)
			.withScorecardMetrics(this.settings.scorecardMetrics)
			.filterAvailableOptions(this.sizeMetricsFilter)
			.build();

		this.populateCurrentHierarchyCalculations(this.options.additionalMetrics, this.settings.hierarchyGrouping,
			this.settings.organizationCalculations, this.settings.hierarchyModels);

		this.options.cogSortByMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(this.metricConstants.getStandardCalculations())
			.withPredefinedMetrics(this.settings.predefinedMetrics)
			.withAttributes(this.options.attributes, MetricFilters.CALCULATION)
			.withMetrics(this.settings.metrics, this.props.project)
			.build();

		this.populateCurrentHierarchyCalculations(this.options.cogSortByMetrics, this.settings.hierarchyGrouping,
			this.settings.organizationCalculations, this.settings.hierarchyModels);

		this.options.sortMetrics = _.clone(this.options.additionalMetrics);
		this.options.sortMetrics.push(this.constants.ALPHANUMERIC);

	}


	private initialize = (): void => {
		if (!_.isEmpty(this.props.selectedAttributes)) {
			this.combinedGrouping = angular.copy(this.props.selectedAttributes[0]);
			delete this.combinedGrouping.selectedLevel;
			this.combinedGrouping.selectedLevels = _.map(this.props.selectedAttributes,
				(topic: TopicReportGrouping) => topic.selectedLevel);
			this.combinedGrouping.type = ReportAssetType.TOPICS;
			this.addLoadingPromise(this.getModelDepth(this.combinedGrouping.name)
				.then(maxLevels => this.initAvailableLevels(maxLevels)));
		}
		this.addLoadingPromise(this.sampleDataApiService.getHierarchyData('tree').then((resp) => {
			let data = resp.data;

			this.staticData.data = data;
			this.staticData.totalCount = 157;

			this.applyVisualChanges();
		}));
	}

	private setDefaults = (): void => {
		for (let key in this.DEFAULTS) {
			if (_.isUndefined(this.visualProps[key]))
				this.visualProps[key] = this.DEFAULTS[key];
		}
	}

	private resetSelections = (): void => {
		this.props.selectedMetrics = [];
		this.props.selectedAttributes = [];
		this.combinedGrouping = undefined;
		this.visualProps.color = this.DEFAULTS.color;
		this.selectSize(this.constants.CONSTANT_SIZE);

		this.applyVisualChanges();
	}

	changeModel = (node: TopicReportGrouping): void => {
		this.addLoadingPromise(this.getModelDepth(node.name).then(maxLevels => {
			this.initAvailableLevels(maxLevels);
			node.selectedLevels = _.range(1, maxLevels + 1);
			this.combinedGrouping = node;
			this.updateSelectedAttributes();
			this.applyVisualChanges();
		}));
	}

	private initAvailableLevels(maxLevels: number): void {
		this.modelLevels = [];
		for (let i = 1; i <= maxLevels; i++) {
			this.modelLevels.push({value: i, displayName: i + ''});
		}
		this.modelLevels.push({value: TopicReportGrouping.LEAF_LEVEL, displayName: this.locale.getString('widget.leaf')});
	}

	private getModelDepth(modelId: string): ng.IPromise<number> {
		let modelTreePromise = this.modelsService.getModelTree(ProjectIdentifier.fromWidgetProperties(this.props), parseInt(modelId, 10));
		return PromiseUtils.old(modelTreePromise).then(tree => {
			return this.hierarchyService.getDepth(tree.root);
		});
	}

	updateSelectedAttributes = (): void => {
		this.combinedGrouping.selectedLevels.sort((a, b) => a - b);
		this.props.selectedAttributes = _.map(this.combinedGrouping.selectedLevels, level => {
			let topicGroup = angular.copy(this.combinedGrouping);
			delete topicGroup.selectedLevels;
			topicGroup.selectedLevel = level;
			topicGroup.fullInclusion = true;
			if (level === TopicReportGrouping.LEAF_LEVEL)
				topicGroup.type = ReportAssetType.TOPIC_LEAF;
			return topicGroup;
		});
		GroupIdentifierHelper.populateIdentifier(this.props.selectedAttributes);
	}

	orientationChange = (newOrientation: DirectionalOrientation) => {
		if (this.visualProps.orientation !== newOrientation) {
			this.visualProps.orientation = newOrientation;
			this.applyVisualChanges();
		}
	}

	selectSize = (node: ReportCalculation): void => {
		node = this.metricCustomFormatUtils.initCalculationFormat(node);
		if (node.name || node.name === '') {
			this.props.selectedMetrics[0] = node;
			(this.props.selectedMetrics[0] as any).scale = this.DEFAULT_NODE_RADIUS_PX;
		} else {
			this.props.selectedMetrics.removeAt(0);
		}
		this.visualProps.size = node.name;
		this.applyVisualChanges();
	}

	configureModel = (): void => {
		this.configure(this.combinedGrouping, KeyMetricListTypes.GROUPING).then(() => {
			this.updateSelectedAttributes();
		});
	}

	configureNodeSize = (): void => {
		let map = {
			scale: 'scale'
		};

		this.bubbleConfigurationService.configure(map, this.visualProps).then((result) => {
			$.extend(this.visualProps, result);
			this.applyVisualChanges();
		});
	}

	showTypeSelector = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	}

	changeView = (type: WidgetType): void => {
		this.conversionListener({$event: this.widgetConversions.byWidgetType(type).targetWidget});
	}

	getWidgetDefaultColor = (): Partial<IColorSelectorPalette> => {
		return this.defaultColor;
	}

	setCalculationProperty = (name, node): ng.IPromise<void> => {
		return PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.props, node)).then((settings) => {
			_.extend(node, settings);

			this.props.selectedMetrics.push(node);
			this.visualProps[name] = node.name;
			this.visualProps.attributeSelections[name] = angular.copy(node);
		});
	}
}

app.component('modelViewerDefinition', {
	bindings: {
		props: '<',
		loadingPromise: '<',
		addLoadingPromise: '<',
		visualProps: '=',
		widget: '<',
		ui: '<',
		updateChartSettings: '<',
		clearErrors: '<',
		staticData: '<',
		options: '<',
		updateAutoTitle: '<',
		utils: '<',
		redrawTrigger: '<',
		dashboardFiltersApplied: '<',
		intersectionObserver: '<',
		conversionListener: '&',
		defaultColor: '<',
	},
	controller: ModelViewerDefinitionComponent,
	templateUrl: 'partials/widgets/settings/cb/components/model-viewer-definition.component.html'
});
