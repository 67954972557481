export class EmailUtils {
	static validate(email: string): boolean {
		// We skip for now the following special symbols: +?
		let emailPattern = /^[a-zA-Z0-9-_~!^$&*'`=|]+(?:\.[a-zA-Z0-9-_~!^$&*'`=|]+)*@(?:[a-zA-Z0-9-]+\.)+(?:[a-zA-Z]{2,63})$/;
		return new RegExp(emailPattern).test(email);
	}

	static encode(email: string): string {
		if (_.isUndefined(email) || email.indexOf('@') === -1) {
			return email;
		}
		let emailTokens = email.split('@');
		if (emailTokens.length !== 2) {
			return email;
		}
		let localPart = emailTokens[0];
		let domainPart = emailTokens[1];
		return encodeURIComponent(localPart) + '@' + domainPart;
	}
}
