<div>
	<div class="d-flex align-items-center">
		<button
			class="btn br-upload-button-wrapper btn-secondary"
			[disabled]="fileUploadIsSuccessful() || fileUploadHasErrors()"
			(click)=fileInput.click()
		>
			<span>{{getUploadButtonLabel()}}</span>
			<input
				name="fileInput"
				data-testid="file-upload-input"
				type="file"
				class="invisible"
				#fileInput
				[(ngModel)]="selectedFile"
				(change)="uploadFile($event)"
			/>
		</button>
		<div class="d-flex align-items-center ml-32">
			<span *ngIf="fileUploadIsSelected()">{{ uploadedFile?.name }}</span>
			<span *ngIf="fileUploadIsLoading()">
				<icon name="spinner" className="rotate-infinite"></icon>
				{{ 'userAdministration.uploadInProgress' | i18n:{ filename: uploadedFile?.name } }}
			</span>
			<span *ngIf="fileUploadIsSuccessful()">{{ uploadedFile?.name }}</span>
			<span *ngIf="fileUploadHasErrors()" class="text-danger-600">
				{{ 'userAdministration.uploadFailed' | i18n:{ filename: uploadedFile?.name } }}
			</span>
			<button
				*ngIf="fileUploadIsSuccessful() || fileUploadHasErrors()"
				type="button"
				class="btn btn-icon width-auto no-border no-background"
				(click)="removeSelectedFile()"
				title="{{ 'administration.delete' | i18n }}"
			>
				<icon name="trash"></icon>
			</button>
		</div>
	</div>
	<form-error *ngIf="fileUploadError" [text]="fileUploadError"></form-error>
	<label class="d-block mt-8" *ngIf="acceptedMediaTypes.length > 0">
		({{ 'userAdministration.fileTypesAccepted' | i18n }} {{ getAcceptedFileExtensions() }})
	</label>
</div>
