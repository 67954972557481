import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { AlertOptOutEntry } from '@cxstudio/alerts/entities/alert-opt-out-entry';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export class AlertingApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers) {
	}

	getStudioAlerts = (contentProviderId: number, accountId: number, projectId?: number): ng.IPromise<StudioAlert[]> => {
		return this.$http.get('rest/alerts/studio', {
			params: {
				cpId: contentProviderId,
				accountId,
				projectId
			},
			cache: false
		}).then(this.httpHandlers.success);
	}

	// get request for getting workspace information
	getStudioAlertsFromWorkspace = (project: WorkspaceProject): ng.IPromise<StudioAlert[]> => {
		return this.$http.get('rest/alerts/studio/ws', {
			params: {
				project
			},
			cache: false
		}).then(this.httpHandlers.success);
	}

	createAlert = (alert: StudioAlert): ng.IPromise<StudioAlert> => {
		return this.$http.post(`rest/alerts/studio`, alert).then(this.httpHandlers.success);
	}

	testAlert = (alert: StudioAlert): ng.IPromise<any> => {
		return this.$http.post(`rest/alerts/studio/test`, alert).then(this.httpHandlers.success);
	}

	updateAlert = (alert: StudioAlert): ng.IPromise<StudioAlert> => {
		return this.$http.put(`rest/alerts/studio/${alert.id}`, alert).then(this.httpHandlers.success);
	}

	renewExpiration = (metricAlertId: number): ng.IPromise<any> => {
		return this.$http.post('rest/alerts/studio/renew', metricAlertId);
	}

	changeAlertOwner = (alertId: number, newOwnerId: number, retainEditPermission: boolean): ng.IPromise<StudioAlert> => {
		return this.$http.put(`rest/alerts/studio/${alertId}/change-owner`, newOwnerId, { params: { retainEditPermission } })
			.then(this.httpHandlers.success);
	}

	shareAlert = (alertId: number, accessChange): ng.IPromise<SharingStatus> => {
		return this.$http.put(`rest/alerts/studio/access/${alertId}`, accessChange).then(this.httpHandlers.success);
	}

	getStudioAlert = (alertId: number): ng.IPromise<StudioAlert> => {
		return this.$http.get(`rest/alerts/studio/${alertId}`).then(this.httpHandlers.success);
	}

	getAlertAccess = (alertId: number): ng.IPromise<any> => {
		return this.$http.get(`rest/alerts/studio/access/${alertId}`).then(this.httpHandlers.success);
	}

	updateAlertState = (alertId: number, enabled: boolean): ng.IPromise<StudioAlert> => {
		return this.$http.put(`rest/alerts/studio/${alertId}/${enabled ? 'enable' : 'disable'}`, null)
			.then(this.httpHandlers.success);
	}

	duplicateAlert = (alert: StudioAlert): ng.IPromise<StudioAlert> => {
		return this.$http.post(`rest/alerts/studio/${alert.id}/copy`, { name: alert.name }).then(this.httpHandlers.success);
	}

	deleteAlert = (alertId: number): ng.IPromise<void> => {
		return this.$http.delete(`rest/alerts/studio/${alertId}`).then(this.httpHandlers.success);
	}

	private bulkUpdateAlertState(alertIds: number[], enabled: boolean): ng.IPromise<StudioAlert[]> {
		return this.$http.put(`rest/alerts/studio/bulk/${enabled ? 'enable' : 'disable'}`, alertIds)
			.then(this.httpHandlers.success);
	}

	bulkEnableAlerts = (alertIds: number[]): ng.IPromise<StudioAlert[]> => {
		return this.bulkUpdateAlertState(alertIds, true);
	}

	bulkDisableAlerts = (alertIds: number[]): ng.IPromise<StudioAlert[]> => {
		return this.bulkUpdateAlertState(alertIds, false);
	}

	bulkDeleteAlerts = (alertIds: number[]): ng.IPromise<void> => {
		return this.$http.put('rest/alerts/studio/bulk/delete', alertIds).then(this.httpHandlers.success);
	}

	loadDisabledRecipients = (alertId: number): ng.IPromise<AlertOptOutEntry[]> => {
		return this.$http.get(`rest/alerts/studio/${alertId}/blacklist`).then(this.httpHandlers.success);
	}

	removeFromBlacklist = (alertId: number, entries: string[]): ng.IPromise<StudioAlert> => {
		return this.$http.put(`rest/alerts/studio/${alertId}/blacklist/remove`, entries).then(this.httpHandlers.success);
	}

	disableNotifications = (alertDescriptor: any, period?: number): ng.IPromise<any> => {
		let params: any = { alert: alertDescriptor };
		if (period) {
			params.period = period;
		}
		return this.$http.get(`rest/public/alerts/snooze`, { params }).then(this.httpHandlers.success);
	}

}

app.service('alertingApiService', AlertingApiService);
