import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';

export interface SelectDialogOption {
	value: any;
	name: string;
}

export interface SelectDialogSettings {
	help?: string;
	search?: boolean;
}

@Component({
	selector: 'select-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<simple-dialog
			[ngBusy]="optionsPromise"
			[header]="header"
			[value]="value"
			[okBtnName]="okBtnName"
			[cancelBtnName]="cancelBtnName">
			<label>{{label}}
				<cb-inline-help *ngIf="!!settings.help">
					<help-body>{{settings.help}}</help-body>
				</cb-inline-help>
			</label>
			<simple-dropdown
				[options]="options"
				[(value)]="value"
				[searchable]="settings.search">
			</simple-dropdown>
		</simple-dialog>
	`
})
export class SelectDialogComponent implements OnInit {
	@Input() header: string;
	@Input() label: string;
	@Input() settings: SelectDialogSettings;
	@Input() options: SelectDialogOption[];
	@Input() optionsPromise: Promise<SelectDialogOption[]>;

	okBtnName: string;
	cancelBtnName: string;

	values;
	value: any;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.okBtnName = this.locale.getString('common.ok');
		this.cancelBtnName = this.locale.getString('common.cancel');
		this.settings = this.settings || {};

		if (this.optionsPromise) {
			this.optionsPromise.then(result => this.options = result).then(this.initValue);
		} else {
			this.initValue();
		}
	}

	private initValue = (): void => {
		if (this.options?.length) this.value = this.options[0].value;
	}
}
