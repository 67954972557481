import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from './form-error/form-error.component';
import { CxMaxLengthDirective } from '@app/modules/cx-form/validation/cx-max-length.directive';
import { UnifiedAngularComponentsModule } from '@clarabridge/unified-angular-components';
import { CxFormUtils } from './utils/form-utils';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent } from '@app/modules/cx-form/file-upload/file-upload.component';
import { IconModule } from '@app/modules/icon/icon.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UnifiedAngularComponentsModule,
		IconModule,
		I18nModule,
	],
	declarations: [
		FormErrorComponent,
		CxMaxLengthDirective,
		FileUploadComponent,
	],
	exports: [
		FormErrorComponent,
		CxMaxLengthDirective,
		FileUploadComponent,
	],
	providers: [
		CxFormUtils
	]
})

export class CxFormModule { }
