import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectUtils } from '@app/util/object-utils';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

@Component({
	selector: 'report-calculation-properties',
	templateUrl: './report-calculation-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCalculationPropertiesComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Input('calculation') originalCalculation: CalculationWithFormat;
	@Input() hideCalculationType: boolean; // for widget-specific cases like PoP/gauge
	@Input() inheritFormat: boolean; // e.g. calculation series
	@Input() showDirectionalColor: boolean; // e.g. table, metric difference
	@Output() calculationChange = new EventEmitter<CalculationWithFormat>();
	@Output() validityChange = new EventEmitter<boolean>();

	calculation: CalculationWithFormat;
	defaultFormatSettings: NumberFormatSettings;
	loadingDefaults: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private metricsService: MetricsService,
	) { }

	ngOnInit(): void {
		this.calculation = ObjectUtils.copy(this.originalCalculation);
		this.calculationChange.emit(this.calculation);
		this.validityChange.emit(false);
		this.loadingDefaults = true;
		this.getDefaultFormatSettings()
			.then(settings => this.defaultFormatSettings = settings)
			.finally(() => {
				this.loadingDefaults = false;
				this.validityChange.emit(true);
				this.ref.markForCheck();
			});
	}

	private getDefaultFormatSettings(): Promise<NumberFormatSettings> {
		if (AnalyticMetricTypes.isStudioMetric(this.calculation)) {
			return this.metricsService.getMetrics(this.project)
				.then(metrics => (_.findWhere(metrics, {id: this.calculation.id})?.format ?? {}) as NumberFormatSettings);
		} else {
			return Promise.resolve(undefined);
		}
	}

	isLoading(): boolean {
		return !!this.loadingDefaults;
	}

	isCalculationTypeAvailable(): boolean {
		return !this.hideCalculationType
			&& AnalyticMetricTypes.isAttribute(this.calculation)
			&& !this.isSatScore();
	}

	private isSatScore(): boolean {
		return this.calculation.type === ReportAssetType.SATSCORE;
	}


}
