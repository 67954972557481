/**
 * Directive for handling dropdown position, possible values: 'auto', 'up', 'left', 'right'
 */

const DROPDOWN_MENU_SELECTOR = '.dropdown-menu';

app.directive('dropdownPosition', ($window) => {
	return {
		restrict: 'A',
		scope: {
			dropdownPosition: '@'
		},
		link: (scope, element, attrs) => {
			if (scope.dropdownPosition === 'up') {
				$(element).addClass('dropup');
			} else if (scope.dropdownPosition === 'left') {
				$(element).addClass('dropleft');
			} else if (scope.dropdownPosition === 'right') {
				$(element).addClass('dropright');
			} else if (scope.dropdownPosition === 'auto') {
				$(element).click((event) => {
					let toggleElement = $(element);
					let spaceThreshold = 20;

					// clear old values
					toggleElement.removeClass('dropup');
					toggleElement.removeClass('dropright');
					toggleElement.removeClass('dropleft');
					toggleElement.find(DROPDOWN_MENU_SELECTOR).css('top', '');

					let menuHeight = toggleElement.find(DROPDOWN_MENU_SELECTOR).height();
					let menuWidth = toggleElement.find(DROPDOWN_MENU_SELECTOR).width();
					let windowHeight = $window.innerHeight;
					let windowWidth = $window.innerWidth;
					if (!menuHeight || !windowHeight)
						return;

					let topBound = attrs.dropdownPositionTopBoundElement
						? $(attrs.dropdownPositionTopBoundElement)[0].getBoundingClientRect().top
						: 0;

					if (event.clientY + toggleElement.height() + menuHeight > windowHeight - spaceThreshold) {
						// no space on bottom
						if (event.clientY - toggleElement.height() - menuHeight >= topBound + spaceThreshold) {
							// enough space on top
							toggleElement.addClass('dropup');
						} else {
							// no space on top - trying to move to left or right
							// if space is not enough moving to bottom

							let paddingLeft = parseInt(toggleElement.css('padding-left'), 10) || 0;
							let spaceOnLeft = paddingLeft + toggleElement.offset().left;
							let requiredSpaceOnLeft = menuWidth + spaceThreshold;

							let paddingRight = parseInt(toggleElement.css('padding-right'), 10) || 0;
							let spaceOnRight = windowWidth - toggleElement.offset().left - toggleElement.width() + paddingRight;
							let requiredSpaceOnRight = menuWidth + spaceThreshold;

							if (spaceOnLeft >= requiredSpaceOnLeft) {
								// enough space on left
								toggleElement.addClass('dropleft');
							} else if (spaceOnRight >= requiredSpaceOnRight) {
								// not enough space on left, moving right
								toggleElement.addClass('dropright');
							} else {
								// not enough space on left and right, moving to bottom
								return;
							}

							let toTop = (menuHeight - toggleElement.height()) / 2 - topBound;
							if (event.clientY + menuHeight / 2 > windowHeight - spaceThreshold) {
								// no space on bottom
								toTop = event.clientY - spaceThreshold - topBound;
							}
							if (toTop + spaceThreshold > event.clientY) {
								// not enough space on top
								toTop = event.clientY - spaceThreshold - topBound;
							}
							toggleElement.find(DROPDOWN_MENU_SELECTOR).css('top', `${-toTop}px`);
						}
					}
				});
			} else if (scope.dropdownPosition === 'right-fixed') {
				$(element).click(() => {
					let elem = $(element);
					let includeMargin = true;
					let menuOuterHeight = elem.find(DROPDOWN_MENU_SELECTOR).outerHeight(includeMargin);

					let windowHeight = $window.innerHeight;

					let SPACE_TO_ADD_BETWEEN_ELEMENTS = 10;

					let left = elem.offset().left + elem.width() + SPACE_TO_ADD_BETWEEN_ELEMENTS;
					let top = elem.offset().top;
					if ((menuOuterHeight && windowHeight) && (elem.offset().top + menuOuterHeight > windowHeight)) {
						//if not enough space on bottom
						let menuInnerHeight = elem.find(DROPDOWN_MENU_SELECTOR).height();
						let heightDiff = menuOuterHeight - menuInnerHeight;
						top = top - menuOuterHeight + heightDiff;
						top = top > 0 ? top : 0;
					}

					let style = {
						position: 'fixed',
						top: `${top}px`,
						left: `${left}px`
					};
					elem.find(DROPDOWN_MENU_SELECTOR).css(style);
					elem.addClass('dropdown-right');
				});
			}
		}
	};
});
