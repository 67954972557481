import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { MetricWidgetRenderingService } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ExportAssetsOptions } from '@cxstudio/reports/utils/export/export-utils.service';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import * as cloneDeep from 'lodash.clonedeep';

@Injectable()
export class StatsTableService {

	readonly TABLE_FORMATTING_SUPPORTED_WIDGETS: WidgetType[] = [
		WidgetType.METRIC, WidgetType.BAR, WidgetType.LINE,
		WidgetType.PIE, WidgetType.HEATMAP, WidgetType.SCATTER,
		WidgetType.CLOUD, WidgetType.NETWORK, WidgetType.TABLE
	];

	constructor(
		private readonly locale: CxLocaleService,
		private readonly metricWidgetRendering: MetricWidgetRenderingService,
	) {}

	getReportCalculationByName(selectedMetrics: ReportCalculation[], name: string): ReportCalculation {
		return _.findWhere(selectedMetrics, { name });
	}

	formatTableValue(widgetType: WidgetType, calculation: ReportCalculation, value: any, options: ExportAssetsOptions): string {
		if (_.isUndefined(value) || value === 'NaN') {
			return this.locale.getString('widget.na');
		}

		if (this.TABLE_FORMATTING_SUPPORTED_WIDGETS.contains(widgetType) && _.isNumber(value)) {
			let metrics: Metric[] = [].concat(options.predefinedMetrics).concat(options.studioMetrics);
			return this.metricWidgetRendering.formatMainValue(value, calculation, metrics);
		}

		return value;
	}

	mustInheritFormat(widget: Widget, calculation: ReportCalculation): boolean {
		let groupings: AttributeGrouping[]  = widget.properties.selectedAttributes;
		let firstCalculation: ReportCalculation = widget.properties.selectedMetrics[0];
		let secondayAxisCalculation: boolean = widget.visualProperties.visualization === VisualizationType.DUAL
			&& widget.visualProperties.secondaryYAxis === calculation.name;

		return widget.name === WidgetType.LINE && !secondayAxisCalculation
			&& groupings && groupings.length > 0 && groupings[0].metricType === AnalyticMetricType.TIME
			&& calculation.name !== firstCalculation.name;
	}

	// update all the formatting fields except conversion
	getCalculationWithInheritedFormat(baseCalculation: NumberFormatSettings, firstCalculation: NumberFormatSettings): ReportCalculation {
		baseCalculation = cloneDeep(baseCalculation);

		baseCalculation.prefix = firstCalculation.prefix;
		baseCalculation.thousandsDelimiter = firstCalculation.thousandsDelimiter;
		baseCalculation.decimalDelimiter = firstCalculation.decimalDelimiter;
		baseCalculation.suffix = firstCalculation.suffix;
		baseCalculation.decimals = firstCalculation.decimals;
		baseCalculation.truncation = firstCalculation.truncation;

		return baseCalculation as unknown as ReportCalculation;
	}

}

app.service('statsTableService', downgradeInjectable(StatsTableService));
