

export enum DrillType {
	TOPIC = 'topic',
	ATTRIBUTE = 'attribute',
	WORD = 'word',
	WORD_REPORT_TYPE = 'words',
	LC = 'lc',
	LC_REPORT_TYPE = 'associatedWords',
	HASHTAG = 'hashtag',
	METRIC_STUDIO = 'metricStudio',
	TIME = 'time',
	CUSTOM_GROUP = 'customGroup',
	ALERT_DOCUMENT = 'alertDocument',
	SENTENCE = 'sentence',
	PREVIEW = 'preview',
	SENTIMENT_RANGE = 'sentimentRange', // used in upgraded 1.0 widgets
	METRIC_PREDEFINED = 'metricPredefined',
	KEY_TERMS = 'key_terms',
	HIERARCHY_MODEL = 'hierarchyModel',
}

export enum DrillGroupBy {
	TOPIC = 'topics',
	TOPICLEAF = 'topicLeaf',
	WORD = '_mstokenname',
	LC = '_lc',
	HASHTAG = '_hashtag',
	KEY_TERMS = 'rootCause',
	SENTIMENT_3 = 'sentimentBreakdown3',
	SENTIMENT_5 = 'sentimentBreakdown5'
}
