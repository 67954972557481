
import { ShareComponentsModalController, ShareComponentsDialogParams } from './share-components-modal-component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { SharingService } from '@cxstudio/sharing/sharing-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SharableComponentGroup } from './sharable-component-group';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { AssetComponent } from '../asset-component';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';
import { DashboardComponentsSharingUtil } from '@app/modules/dashboard/services/assets/dashboard-components-sharing-utils';


export interface DashboardShareComponentsDialogParams extends ShareComponentsDialogParams {
	dashboard: Dashboard;
}

export class DashboardShareComponentsModalController extends ShareComponentsModalController<DashboardShareComponentsDialogParams> {

	constructor(
		$q: ng.IQService,
		locale: ILocale,
		dashboardComponentsApiService: DashboardComponentsApiService,
		sharingService: SharingService,
		security: Security,
		filterManagementApiService: FilterManagementApiService,
		metricApiService: MetricManagementApiService,
		globalNotificationService: GlobalNotificationService,
		dashboardApiService: DashboardApiService,
		private dashboardComponentsSharingUtil: DashboardComponentsSharingUtil
	) {
		super(
			$q,
			locale,
			dashboardComponentsApiService,
			sharingService,
			security,
			filterManagementApiService,
			metricApiService,
			globalNotificationService,
			dashboardApiService);
	}

	$onInit(): void {
		super.init();
	}

	protected loadComponentGroups(): ng.IPromise<Array<SharableComponentGroup<any>>> {
		let assetWithProject: ProjectAsset = this.buildProjectAsset();
		return PromiseUtils.old(this.dashboardComponentsSharingUtil.getSharableDashboardComponents(assetWithProject)).then(result => {
			return [
				this.buildMetricsGroup(result.metrics),
				this.buildFiltersGroup(result.filters)
			];
		});
	}

	private buildMetricsGroup(metrics: AssetComponent[]): SharableComponentGroup<AssetComponent> {
		return new SharableComponentGroup(
			metrics,
			() => this.locale.getString('administration.transferMetrics'),
			() => this.security.has('share_metric'),
			() => this.security.has('share_edit_metric'),
			(components, recipients) => this.metricApiService.shareMetrics((components as unknown) as Metric[], recipients));
	}

	private buildFiltersGroup(filters: AssetComponent[]): SharableComponentGroup<AssetComponent> {
		return new SharableComponentGroup(
			filters,
			() => this.locale.getString('administration.transferFilters'),
			() => this.security.has('share_filter'),
			() => this.security.has('share_edit_filter'),
			(components, recipients) => this.filterManagementApiService.shareFilters(components, recipients));
	}

	protected loadSharedEntities(): ng.IPromise<any> {
		return this.dashboardApiService.getDashboardUsersWithRawGroups(Number(this.resolve.asset.assetId));
	}

	protected getPublicStatus(): SharingStatus {
		return this.resolve.dashboard.sharingStatus;
	}

	protected getTitle(): string {
		return this.locale.getString('dashboard.shareComponentsTitle', { dashboardName: this.resolve.asset.name });
	}

	protected getPrompt(): string {
		return this.locale.getString('dashboard.shareComponentsNote', { assetType: this.locale.getString('object.dashboard').toLowerCase() });
	}

}

app.component('dashboardShareComponentsModal', {
	controller: DashboardShareComponentsModalController,
	templateUrl: 'partials/asset-management/share-components-modal-component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
