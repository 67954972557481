import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WidgetEditEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { SidebarEditorService } from '@app/modules/layout/sidebar-editor/sidebar-editor.service';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { WidgetSettingTab } from '@app/modules/widget-settings/settings/widget-settings-tab.enum';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';


@Component({
	selector: 'widget-settings',
	templateUrl: './widget-settings.component.html',
	styles: [`
		.nav-link.disabled {
			cursor: not-allowed;
			color: var(--gray-600);
		}
	`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSettingsComponent extends SelfCleaningComponent implements OnInit {
	activeTabId: string;
	readonly widgetSettingTab = WidgetSettingTab;
	widget: Widget;

	loading: Promise<any>;

	//filters
	projectTimezone: any;
	projectSelection: IProjectSelection;

	constructor(
		private sidebarEditor: SidebarEditorService,
		private eventBus: GlobalEventBus,
		private reportProjectContextService: ReportProjectContextService,
		private reportAssetUtilsService: ReportAssetUtilsService,
		private ref: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit(): void {
		this.widget = this.sidebarEditor.getEntity();

		//props
		let project = this.reportAssetUtilsService.getWidgetProject(this.widget);
		let isProjectSelected = WorkspaceTransitionUtils.isProjectSelected(project);

		//is active flag for properties tab
		this.activeTabId = isProjectSelected
			? this.widgetSettingTab.VISUAL_TAB
			: this.widgetSettingTab.END_POINT_TAB;

		this.addListener(this.eventBus.subscribe(WidgetEditEvent.WORKSPACE_PROJECT_UPDATE,
			(event, workspaceProject: WorkspaceProject, needReset = false) => {
				let isNewWidget = this.isNewWidgetInit();
				if (isNewWidget) {
					this.clearSettings(); // clear and init default props in all tabs
				}
				if (WorkspaceProjectUtils.isProjectSelected(workspaceProject)) {
					if (needReset) {
						this.clearSettings();
					}
					this.reloadTimezone();
				} else {
					if (!isNewWidget) {
						this.clearSettings();
					}
				}
			}));

		//filters
		this.updateProjectSelection();
	}

	isProjectSelected(): boolean {
		let project = this.reportAssetUtilsService.getWidgetProject(this.widget);
		return WorkspaceTransitionUtils.isProjectSelected(project);
	}

	onProjectUpdate(project: WorkspaceProject, needReset: boolean): void {
		// need to replace this with direct inputs
		this.eventBus.broadcast(WidgetEditEvent.WORKSPACE_PROJECT_UPDATE, project, needReset);
	}

	private isNewWidgetInit(): boolean {
		return this.widget.properties === null
			|| _.isUndefined(this.widget.properties.contentProviderId)
			|| this.widget.created;
	}

	private updateProjectSelection(): void {
		let projectIdentifier = ProjectIdentifier.fromWidgetProperties(this.widget.properties);
		if (ProjectIdentifier.isProjectSelected(projectIdentifier)
			|| InternalProjectTypes.isAdminProject(projectIdentifier.projectId)) {
			this.projectSelection = projectIdentifier;
		} else {
			delete this.projectSelection;
		}
		this.ref.markForCheck();
	}

	clearSettings = (): void => {
		//more in clarabridge-settings-controller
		this.updateProjectSelection();
		this.projectTimezone = null;
	}

	private reloadTimezone = (): void => {
		this.reportProjectContextService.getWidgetProjectTimezone(this.widget).then((timezone) => {
			this.projectTimezone = timezone;
		});
	}


}
