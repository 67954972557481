
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ListOption } from '@app/shared/components/forms/list-option';
import { CxLocaleService } from '@app/core';
import { ObjectListSort } from '@app/shared/components/sort/object-list-sort';
import { ObjectSortDirection } from '@app/shared/components/sort/object-sort-direction';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Component({
	selector: 'models-sort',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<object-list-sort
			[columns]="visibleColumns"
			[defaultSort]="defaultSort"
			(apply)="applySorts($event)"
		></object-list-sort>
	`
})
export class ModelsSortComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Output() apply = new EventEmitter<ObjectListSort[]>();
	defaultSort: ObjectListSort;

	visibleColumns: Array<ListOption<string>>;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.defaultSort = { field: 'name', direction: ObjectSortDirection.ASC };
	}

	@Input() set columns(columns: IGridColumn[]) {
		let defaultColumns = [
			{ name: this.locale.getString('common.name'), value: 'name' }
		];
		let visibleAdditionalColumns = columns
			.filter(column => column.selected)
			.map(column => {
				return { name: column.text, value: column.name };
			});

		this.visibleColumns = defaultColumns.concat(visibleAdditionalColumns);
	}

	applySorts = (sorts: ObjectListSort[]): void => {
		this.apply.emit(sorts);
	}
}

app.directive('modelsSort', downgradeComponent({component: ModelsSortComponent}) as angular.IDirectiveFactory);
