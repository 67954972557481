import { DashboardResponsiveState } from './dashboard-responsive-state';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { ResponsiveDashboard } from './responsive-dashboard';
import { ResponsiveReportResult } from '../entities/responsive-report-result.interface';
import { ReportResponsiveness } from './report-responsiveness';
import { WidgetReportDisplayState } from './widget-report-display-state';
import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';

export class ResponsiveDashboardService {

	private dashboard: ResponsiveDashboard;

	constructor(
		private currentWidgets: ICurrentWidgets,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private dashboardRunHelperService: DashboardRunHelperService
	) {
	}

	enterDashboard(dashboardId: number): void {
		this.rejectScheduledReportRuns();

		this.dashboard = {
			id: dashboardId,
			state: DashboardResponsiveState.FIRST_RUN,
			completeFirstRunDeferred: this.$q.defer()
		};
	}

	refreshDashboard(): void {
		this.dashboardRunHelperService.setRunStatus(this.dashboard.id, DashboardRunStatus.INTERRUPTED_INTERACTION);
		this.rejectScheduledReportRuns();
		this.dashboard.state = DashboardResponsiveState.AFTER_FIRST_RUN;
	}

	scheduleFreshDataReport(dataProvider: () => ng.IPromise<ResponsiveReportResult>, pause: number): ng.IPromise<ResponsiveReportResult> {
		let timeout = this.$timeout(pause);

		if (this.isExecutingFirstRun()) {
			let promise = this.$q.all([ timeout, this.dashboard.completeFirstRunDeferred.promise ]).then(() => dataProvider());
			this.ensureFreshDataRuns();

			return promise;
		} else {
			return timeout.then(() => dataProvider());
		}
	}

	ensureFreshDataRuns(): void {
		if (this.isExecutingFirstRun() && !this.hasWidgetsLoadingCachedData()) {
			this.completeFirstRun();
		}
	}

	private hasWidgetsLoadingCachedData(): boolean {
		let widgets = this.currentWidgets.getAllWidgets(this.dashboard.id.toString());
		return widgets && widgets.map(widget => widget.responsiveState)
			.filter(state => !!state)
			.filter(state => state.responsiveness === ReportResponsiveness.RESPONSIVE 
				&& state.displayState === WidgetReportDisplayState.LOADING_CACHED_DATA)
			.length > 0;
	}

	private completeFirstRun(): void {
		this.dashboard.state = DashboardResponsiveState.AFTER_FIRST_RUN;
		this.dashboard.completeFirstRunDeferred.resolve();
	}

	private isExecutingFirstRun(): boolean {
		return this.dashboard?.state === DashboardResponsiveState.FIRST_RUN;
	}

	private rejectScheduledReportRuns(): void {
		if (this.dashboard) {
			this.dashboard.completeFirstRunDeferred.reject();
		}
	}

}

app.service('responsiveDashboardService', ResponsiveDashboardService);