
import * as _ from 'underscore';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { DrillFilterEvent } from '@app/core/cx-event.enum';


export class WidgetLinkingService {

	constructor(
		private $rootScope: ng.IRootScopeService,
		private $timeout: ng.ITimeoutService,
		private currentWidgets: ICurrentWidgets,
		private pointSelectionUtils: PointSelectionUtils
	) {}

	isWidgetLinkingEnabled = (widget: Widget): boolean => {
		let type = widget.properties.widgetType;
		return ReportConstants.isAnalyticWidget(type)
			&& type !== WidgetType.METRIC;
	}

	isWidgetLinked = (widget: Widget, parentId: number): boolean => {
		return _.contains(widget.linkedTo, parentId);
	}

	isAvailableForLinking = (widget: Widget, parentWidget): boolean => {
		if (!parentWidget)
			return false;

		let notParent = !_.contains(widget.linkedWidgets, parentWidget.id);
		let allowedType = ReportConstants.isAnalyticWidget(widget.properties.widgetType);
		let notSameWidget = widget.id !== parentWidget.id;
		let sameProject = this.isProjectEqual(widget.properties, parentWidget.properties);
		let notAllowedType = widget.properties.widgetType !== WidgetType.SCORECARD;
		return notParent && allowedType && sameProject && notSameWidget && notAllowedType;
	}

	isProjectEqual = (props1: WidgetProperties, props2: WidgetProperties): boolean => {
		if (!props1 || !props2)
			return props1 === props2;
		return props1.contentProviderId === props2.contentProviderId && props1.project === props2.project;
	}

	toggleWidgetLinking = (widget: Widget, parent: Widget, allWidgets: Widget[]) => {
		if (this.isWidgetLinked(widget, parent.id)) {
			this.unlinkWidgets(widget, parent);
			return;
		}

		this.linkWidgets(widget, parent, allWidgets);
	}

	private unlinkWidgets(widget: Widget, parent: Widget): void {
		widget.linkedTo.remove(parent.id);
		if (!widget.linkedTo.length) {
			delete widget.linkedTo;
		}

		parent.linkedWidgets.remove(widget.id);
		if (!parent.linkedWidgets.length) {
			delete parent.linkedWidgets;
			this.pointSelectionUtils.enablePointSelection(widget.containerId, parent.id, false);
		}

		parent.dirty = true;
	}

	private getWidgetById(widgets: Widget[], widgetId: number): Widget {
		return _.findWhere(widgets, { id: widgetId});
	}

	private linkWidgets(widget: Widget, parent: Widget, widgets: Widget[]): void {
		if (_.contains(parent.linkedTo || [], widget.id)) return;
		this.setLinkBetweenWidgets(widget, parent);
		this.linkDescendants(widget, parent, widgets);
		this.linkToAncestors(widget, parent, widgets);
	}

	private linkDescendants(widget: Widget, parentWidget: Widget, widgets: Widget[]): void {
		let descendants = widget.linkedWidgets || [];
		descendants.forEach(descendantId => {
			let descendant = this.getWidgetById(widgets, descendantId);
			this.setLinkBetweenWidgets(descendant, parentWidget);

			let ancestors = parentWidget.linkedTo || [];
			ancestors.forEach(ancestorId => {
				let ancestor = this.getWidgetById(widgets, ancestorId);
				this.setLinkBetweenWidgets(descendant, ancestor);
			});
		});
	}

	private linkToAncestors(widget: Widget, parentWidget: Widget, widgets: Widget[]): void {
		let ancestors = parentWidget.linkedTo || [];
		ancestors.forEach(ancestorId => {
			let ancestor = this.getWidgetById(widgets, ancestorId);
			this.setLinkBetweenWidgets(widget, ancestor);

			let descendants = widget.linkedWidgets || [];
			descendants.forEach(descendantId => {
				let descendant = this.getWidgetById(widgets, descendantId);
				this.setLinkBetweenWidgets(descendant, ancestor);
			});
		});
	}

	private setLinkBetweenWidgets(widget: Widget, parent: Widget): void {
		if (widget.id === parent.id) return;
		if (_.contains(parent.linkedTo || [], widget.id)) return;
		parent.linkedWidgets = _.union(parent.linkedWidgets || [], [widget.id]);
		widget.linkedTo = _.union(widget.linkedTo || [], [parent.id]);
		parent.dirty = true;
	}

	resetWidgetFiltering = (widget: Widget): boolean => {
		let linkedFilters = this.currentWidgets.getLinkedFilters(widget.containerId, widget.id);
		if (_.isEmpty(linkedFilters))
			return false;
		_.each(linkedFilters, filter => this.pointSelectionUtils.enablePointSelection(widget.containerId, filter.id, false));
		this.currentWidgets.setLinkedFilters(widget.containerId, widget.id, []);
		return true;
	}

	applyWidgetFiltering = (widget: Widget, parent: Widget, point): boolean => {
		let linkedFilters = this.currentWidgets.getLinkedFilters(widget.containerId, widget.id) || [];
		let existing = _.findWhere(linkedFilters, {id: parent.id});
		point = point && _.omit(point, (value, key) => key === '_pop' || _.isFunction(value)); // ignore date filter in PoP and any actions
		if (existing) {
			linkedFilters.remove(existing);
			// element may change during click (e.g. preview 2.0), so ignoring it in equals
			// STUDIO-475: should be able to toggle by any column in the same row
			if (_.isEqual(
				_.omit(point, '_element', 'id', 'selectedMetricName', '_group'),
				_.omit(existing.point, '_element', 'id', 'selectedMetricName', '_group')
			)) {
				this.pointSelectionUtils.unselectPoint(widget.containerId, parent, point, false);
				point = null; // toggle by clicking the same point
			}
		} else if (!point) {
			return false; // no change
		}
		if (point) {
			this.pointSelectionUtils.enablePointSelection(widget.containerId, parent.id, true);
			this.pointSelectionUtils.selectPoint(widget.containerId, parent, point, false);
			linkedFilters.push({
				id: parent.id,
				widget: parent,
				point
			});
		} else {
			this.pointSelectionUtils.enablePointSelection(widget.containerId, parent.id, false);
		}

		this.currentWidgets.setLinkedFilters(widget.containerId, widget.id, linkedFilters);
		return true;
	}

	applySelectorWidgetFiltering = (widget: Widget, parent: Widget, point: DrillPoint | DrillPoint[], multiselect?: boolean): boolean => {
		let linkedFilters = this.currentWidgets.getLinkedFilters(widget.containerId, widget.id) || [];
		if (!point) {
			let updatedFilters = _.reject(linkedFilters, filter => filter.id === parent.id);
			if (updatedFilters.length === linkedFilters.length) {
				return false;
			}
			this.currentWidgets.setLinkedFilters(widget.containerId, widget.id, updatedFilters);
			return true;
		}
		if (_.isArray(point)) {
			let points = point;
			linkedFilters = _.reject(linkedFilters, filter => filter.id === parent.id);
			_.each(points, filterPoint => {
				linkedFilters.push({
					id: parent.id,
					widget: parent,
					point: filterPoint
				});
			});
		} else {
			let existing = _.find(linkedFilters, filter => filter.id === parent.id && filter.point._uniqName === point._uniqName);
			if (existing) {
				linkedFilters.remove(existing);
			} else {
				if (!multiselect) {
					linkedFilters = _.reject(linkedFilters, filter => filter.id === parent.id);
				}
				linkedFilters.push({
					id: parent.id,
					widget: parent,
					point
				});
			}
		}
		this.currentWidgets.setLinkedFilters(widget.containerId, widget.id, linkedFilters);
		return true;
	}

	deleteLinking = (widget: Widget, widgets: Widget[]): void => {
		if (widget.linkedTo) {
			_.each(widgets, parent => {
				if (this.isWidgetLinked(widget, parent.id))
					this.toggleWidgetLinking(widget, parent, widgets);
			});
			this.currentWidgets.setLinkedFilters(widget.containerId, widget.id, []);
		}

		if (widget.linkedWidgets) {
			this.$rootScope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, widget, null);
			// need to reset filters first, and then remove linking
			this.$timeout(() => {
				_.each(widgets, child => {
					if (_.contains(widget.linkedWidgets, child.id)) {
						this.toggleWidgetLinking(child, widget, widgets);
					}
				});
			});
		}
	}

	highlightLinkedWidgets = (widget: Widget, addParentClass: (id: number) => void, addChildClass: (id: number) => void) => {
		let selectedWidgets = this.currentWidgets.getLinkedFilters(widget.containerId, widget.id);
		if (selectedWidgets && !_.isEmpty(widget.linkedTo)) {
			selectedWidgets.forEach(selection => {
				addParentClass(selection.id);
			});
		}

		if (!_.isEmpty(widget.linkedWidgets)) {
			_.each(widget.linkedWidgets, addChildClass);
			addParentClass(widget.id);
		} else if (!_.isEmpty(selectedWidgets)) {
			addChildClass(widget.id);
		}
	}

}

app.service('widgetLinkingService', WidgetLinkingService);
