<modal-header
	(cancel)="cancel()"
	modalTitle="{{'templates.newTemplateTitle' | i18n }}">
</modal-header>

<div class="modal-body" [ngBusy]="[loading.save, loading.creatingPlaceholders]">
	<form #templateDialogForm="ngForm" class="form-horizontal" name="templateDialog" role="form">
		<!-- Name -->
		<div class="form-group">
			<label class="col-sm-3 control-label" [i18n]="'templates.name'"></label>
			<div class="col-sm-9" [ngClass]="{'has-error br-form-error': nameField?.errors?.required}">
				<input
					name="name"
					type="text"
					class="form-control"
					placeholder="{{'templates.namePlaceholder'|i18n}}"
					[(ngModel)]="model.name"
					#nameField="ngModel"
					required
					maxlength="300">
					<span class="help-block" *ngIf="nameField.errors?.required">{{'templates.emptyTemplateNameWarning'|i18n}}</span>
			</div>
		</div>

		<!-- Description -->
		<div class="form-group">
			<label class="col-sm-3 control-label" [i18n]="'templates.description'"></label>
			<description class="col-sm-9"
				name="description"
				[(ngModel)]="model.description"
				[placeholder]="'templates.descriptionPlaceholder' | i18n">
			</description>
		</div>

		<!-- Placeholders -->
		<div *ngIf="hasPlaceholders()">
			<hr>
			<p [i18n]="'templates.providePlaceholderDescriptions'"></p>

			<template-create-placeholders-list
				*ngIf="(model.placeholders.groupings | availablePlaceholders).length > 0"
				[placeholders]="model.placeholders.groupings"
				groupTitle="{{'templates.placeholderOriginalGroupings'|i18n}}"
				[type]="TemplatePlaceholderType.GROUPING"
				[showPlacement]="true">
			</template-create-placeholders-list>

			<template-create-placeholders-list
				*ngIf="(model.placeholders.calculations | availablePlaceholders).length > 0"
				[placeholders]="model.placeholders.calculations"
				groupTitle="{{'templates.placeholderOriginalCalculations'|i18n}}"
				[type]="TemplatePlaceholderType.CALCULATION"
				[showPlacement]="true">
			</template-create-placeholders-list>
		</div>

		<!-- Warning -->
		<hr>
		<div class="template-warning" [i18n]="'templates.templateCreationWarning'">
		</div>
	</form>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'administration.save' | i18n"
	[isDisabled]="templateDialogForm.invalid">
</save-modal-footer>
