import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';

export default class LinkedFilter {
	id: number;
	widget: Widget;
	point: DrillPoint;

	constructor(widgetId: number, widget: Widget, point: DrillPoint) {
		this.id = widgetId;
		this.widget = widget;
		this.point = point;
	}
}
