import { TEMPLATE_PLACEHOLDER_PREDEFINED_ITEMS } from '@cxstudio/dashboard-templates/placeholders/template-placeholder-predefined-items';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import ConversionWidgetProperty from '@cxstudio/reports/widgets/conversion-widget-property.class';
import { IReportWidgetContext, Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

const SELECTOR_VISUAL_PROPERTIES: ConversionWidgetProperty[] = [
	new ConversionWidgetProperty('visualization'), new ConversionWidgetProperty('multiselect'),
	new ConversionWidgetProperty('backgroundColor'), new ConversionWidgetProperty('hideTitle'),
	new ConversionWidgetProperty('customColor'), new ConversionWidgetProperty('direction'),
	new ConversionWidgetProperty('displaySize'), new ConversionWidgetProperty('orientation'),
	new ConversionWidgetProperty('color'),
];

const EXCLUDED_PREDEFINED_ATTRIBUTES: string[] = [ClarabridgeAttributeName.CB_EMAIL, ClarabridgeAttributeName.CB_PERSON];


export class SelectorWidget extends ReportWidget {

	constructor() {
		super(WidgetType.SELECTOR);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initFromContext(context);
		super.withFilters(context);
		super.withDateRange(context);
		super.initProperties(SELECTOR_VISUAL_PROPERTIES, context, 'visualProperties');

		if (this.widget.visualProperties.visualization === SelectorWidgetNavigationType.SELECTOR_DROPLIST) {
			this.widget.visualProperties.color = 'custom';
		}

		this.initDisplayOrder(context);
		this.initGrouping(context);
	}

	private initDisplayOrder(context: IReportWidgetContext): void {
		let metrics = WidgetPropertyService.getValue('properties.selectedMetrics', context, []);
		this.widget.properties.selectedMetrics = metrics;
		let metricName = metrics[0] && metrics[0].name;
		this.widget.visualProperties.sortBy = metricName || StandardMetricName.ALPHANUMERIC;
	}

	private initGrouping(context: IReportWidgetContext): void {
		let groupings = WidgetPropertyService.getValue('properties.selectedAttributes', context, []);
		let newGrouping = groupings[0] || {};
		let oldGrouping = context.source.properties.selectedAttributes?.[0] || {} as AttributeGrouping;

		if (oldGrouping.sortOrder === 'custom') {
			if (oldGrouping.name !== newGrouping.name || !this.preserveInclusionList(newGrouping)) {
				newGrouping.sortOrder = 'desc';
				newGrouping.size = oldGrouping.wordsList?.length || (oldGrouping as TopicReportGrouping).selectedNodes?.length || 10;
			} else {
				newGrouping.wordsFilteringMode = oldGrouping.wordsFilteringMode;
				newGrouping.wordsList = angular.copy(oldGrouping.wordsList);
				this.widget.properties.isCustomSelectorWidget = true;
			}
		}
		this.widget.properties.selectedAttributes = [newGrouping];
	}

	private preserveInclusionList(grouping: AttributeGrouping): boolean {
		if (AnalyticMetricTypes.isPredefinedGroup(grouping)) {
			return true;
		}
		let attributes = _.difference(TEMPLATE_PLACEHOLDER_PREDEFINED_ITEMS, EXCLUDED_PREDEFINED_ATTRIBUTES);
		return _.contains(attributes, grouping.name);
	}
}
