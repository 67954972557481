import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { duplicateNameValidator } from './duplicate-name-validator.directive';

@Directive({
	selector: '[duplicateNameCheck]',
	providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateNameValidatorDirective, multi: true }]
})
export class DuplicateNameValidatorDirective implements Validator {

	@Input('duplicateNameCheck') duplicateCheckPropertyNamePrefix: string;

	validate(control: AbstractControl): ValidationErrors | null {
		return duplicateNameValidator(this.duplicateCheckPropertyNamePrefix)(control);
	}
}