import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SelectorNavigationTypeComponent } from '@app/modules/widget-settings/selector-widget/selector-navigation-type/selector-navigation-type.component';
import { PushButtonsModule } from '@clarabridge/unified-angular-components';

@NgModule({
	imports: [
		SharedModule,
		PushButtonsModule,
	],
	declarations: [
		SelectorNavigationTypeComponent,
	],
})
export class SelectorWidgetSettingsModule { }
