import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class AlertSubscriptionApiService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	saveTopicSelection(alertTopicSelectionSavingEntity): Promise<any> {
		return this.cxHttp.put('rest/alerts/topicSelection', alertTopicSelectionSavingEntity);
	}

	saveHierarchySelection(alertHierarchySelectionSavingEntity): Promise<any> {
		return this.cxHttp.put('rest/alerts/hierarchySelection', alertHierarchySelectionSavingEntity);
	}
}
