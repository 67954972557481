import { Pagination } from '@app/shared/components/pagination/pagination';
import EmailsSearch from './emails-search';
import { EmailStatus } from './email-status';
import { HttpParams } from '@angular/common/http';
import { PaginationHttpParams } from '@app/shared/components/pagination/pagination-http-params';

export class EmailsQuery {
	masterAccountId?: number;
	recipient?: string;
	subject?: string;
	status?: EmailStatus;
	
	constructor(search: EmailsSearch, private pagination: Pagination) {
		this.recipient = search.recipient;
		this.subject = search.subject;
		this.status = search.status;
		if (search.masterAccount) {
			this.masterAccountId = search.masterAccount.accountId;
		}
	}

	toHttpParams(): HttpParams {
		let params: HttpParams = PaginationHttpParams.of(this.pagination);
		if (this.masterAccountId) params = params.set('masterAccountId', this.masterAccountId.toString());
		if (this.recipient) params = params.set('recipient', this.recipient);
		if (this.subject) params = params.set('subject', this.subject);
		if (this.status) params = params.set('status', this.status);
		return params;
	}
}