import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'no-published-report',
	template: `
		<div class="d-flex flex-nowrap nowrap align-center ph-8 pv-4">
			<span class='q-icon-calendar mr-4'></span>
			<span [i18n]="'dashboard.dashboardNoPublishedReport'"></span>
		</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoPublishedReportComponent {}
