<div class="cx-license-info" *ngIf="seats.length !== 0">
	<p class="cursor-pointer text-1rem font-bold"
		(click)="hideLicenses = !hideLicenses">{{"administration.licensingHeader"|i18n}}
		<i class="ml-16 q-icon"
			[ngClass]="{'q-icon-angle-bottom' : !hideLicenses, 'q-icon-angle-right' : hideLicenses}" aria-hidden="true"></i>
	</p>
	<div class="d-flex justify-between ph-16 overflow-hidden license-details"
		[ngStyle]="{height: hideLicenses ? '0' : '4em'}">
		<div class="d-flex l-margin-offset-6">
			<div class="license-count align-items-center" *ngFor="let info of seats; index as $index">
				<div class="display-sm">
					<span>{{info.used}}</span> / <span>{{info.contract}}</span>
				</div>

				<span>{{info.licenseName}}</span>
			</div>
		</div>

		<div class="license-count align-items-center">
			<div class="display-sm">
				<span>{{selectedUsers}}</span> / <span>{{totalUsers}}</span>
			</div>

			<span>{{'administration.usersSelected'|i18n}}</span>
		</div>
	</div>
</div>
