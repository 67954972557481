import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'description',
	template: `
<textarea
	name="description"
	rows="{{rows}}"
	class="form-control h-min-2_5em"
	maxlength="{{maxLength}}"
	[ngModel]="value"
	(ngModelChange)="writeValue($event)"
	[disabled]="disabled"
	placeholder="{{placeholder}}">
</textarea>
<label class="mt-8" [i18n]="'dashboard.charactersLeft'" [i18nParams]="{count: maxLength - value?.length || 0}"></label>
`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DescriptionComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionComponent implements ControlValueAccessor {

	@Input() placeholder: string = this.locale.getString('dashboard.descriptionPlaceholder');
	@Input() maxLength = 256;
	@Input() rows = 4;
	value: string;
	disabled: boolean;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: boolean) => void = _.noop;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {}

	//From ControlValueAccessor interface
	writeValue(value: any): void {
		if (value !== this.value) {
			this.value = value;
			this.onChangeCallback(value);
			this.onTouchedCallback();
			this.ref.detectChanges();
		}
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.ref.markForCheck();
	}

}
