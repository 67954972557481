import { Component, OnInit, ChangeDetectionStrategy, Inject, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ToastService, AlertLevel, UIOption } from '@clarabridge/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';


@Component({
	selector: 'dashboard-language-settings',
	templateUrl: './dashboard-language-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardLanguageSettingsComponent implements OnInit {
	@Input('dashboard') dashboard: Dashboard;
	@Output() defaultLocaleChange = new EventEmitter<string>();


	private readonly maxFileSize = 2 * Math.pow(2, 20); // 2Mb
	private readonly availableLanguages = ['EN', 'ES-ES', 'FR', 'DE', 'NL', 'IT', 'PT', 'PT-BR', 'FI', 'KO', 'JA',
	'ZH-S', 'ZH-T', 'PL', 'TR', 'CS', 'HU', 'EL', 'SK', 'RO', 'SL', 'BG', 'HR', 'TH', 'RU-RU', 'EN-GB',
	'UK', 'SV', 'DA', 'MS', 'HI', 'NO', 'ID', 'FR-CA', 'BS', 'LT', 'SR', 'VI', 'CY', 'HY', 'MK', 'LV', 'ET'];

	translationEnabled: boolean;
	uploader: FileUploader;
	fileError: string;
	downloading;
	loadingLocale;
	currentLocale: string;
	selectedFile?: string;

	constructor(
		private readonly betaService: BetaFeaturesService,
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		@Inject('dashboardApiService') private readonly dashboardApiService: DashboardApiService,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('security') private readonly security: Security
	) { }

	ngOnInit(): void {
		this.translationEnabled = this.betaService.isFeatureEnabled(BetaFeature.DASHBOARD_TRANSLATION);

		if (this.translationEnabled) {
			this.uploader = this.prepareUploader();
			this.processLocale(this.dashboard.properties.defaultLocale);
		}
	}

	downloadTemplate(): void {
		this.downloading = this.dashboardApiService.getTranslationTemplate(this.dashboard.id).then((data: any) => {
			this.exportUtils.exportCSV(`Dashboard-translations-${this.dashboard.id}.csv`, data.data);
		});
	}

	updateDefaultLocale(defaultLocale: UIOption<string>): void {
		this.defaultLocaleChange.emit(defaultLocale.value);
	}

	private prepareUploader(): FileUploader {
		const uploader = new FileUploader({
			url: this.urlService.getAPIUrl(`rest/dashboard-translation/${this.dashboard.id}`),
			filters: [{
				name: 'csv',
				fn: (item, options) => item?.name?.indexOf('.csv', item.name.length - '.csv'.length) !== -1
			}],
			headers: [{
				name: 'ma-id', value: this.security.getMasterAccountId().toString()
			}]
		});

		uploader.onWhenAddingFileFailed = (item: FileLikeObject) => {
			this.fileError = (item.size > this.maxFileSize) ?
				this.locale.getString('organization.exceedMaxSize') :
				this.locale.getString('common.unsupportedFileFormat');

			this.ref.detectChanges();
		};

		uploader.onCompleteAll = () => {
			this.toastService.addToast(this.locale.getString('common.uploadSuccess'), AlertLevel.CONFIRM);
		};

		return uploader;
	}

	uploadTranslations(event): void {
		// must be exactly 1 file to upload
		if (!(event?.target?.files?.length === 1)) return;

		delete this.fileError;
		this.ref.detectChanges();

		this.uploader.addToQueue(event.target.files);
		this.uploader.uploadAll();
	}

	getLocales(): UIOption<string>[] {
		return this.availableLanguages.map(
			(value) => ({ displayName: value, value })
		);
	}

	private processLocale = (dashboardLocale: string) => {
		if (dashboardLocale) {
			this.currentLocale = dashboardLocale;
		} else {
			const userPrefLanguage = this.locale.getLocale();
			const userPrefLanguageShort = userPrefLanguage.split('-')[0].toUpperCase();

			// this will not always work perfectly, but good enough for first pass
			if (this.availableLanguages.contains(userPrefLanguageShort)) {
				this.currentLocale = userPrefLanguageShort;
			}
		}

		if (this.currentLocale) {
			this.ref.detectChanges();
		}
	}
}

app.directive('dashboardLanguageSettings', downgradeComponent({ component: DashboardLanguageSettingsComponent }));
