import { Component, ChangeDetectionStrategy } from '@angular/core';
import { EngageStatusService } from '@app/modules/system-administration/status/integration-status/engage/engage-status.service';

@Component({
	selector: 'engage-status-tab',
	templateUrl: '../common/common-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngageStatusTabComponent {
	constructor(
		public service: EngageStatusService,
	) {}
}
