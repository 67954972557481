import { Inject, Injectable } from '@angular/core';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { ColumnFormatter } from '@app/modules/item-grid/slick-grid-formatter.service';
import { GridInputFormatterService } from '@app/modules/item-grid/grid-input-formatter.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { SettingsProperty, SettingsState } from '@app/modules/project/state-changes/settings-state.class';

interface IFolder {
	type: FolderTypes;
}

type AttributeGridItem = IAdminAttribute | IFolder;

@Injectable({
	providedIn: 'root'
})
export class AttributeGridFormatterService {

	constructor(
		private gridInputFormatterService: GridInputFormatterService<AttributeGridItem>,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('metricConstants') private metricConstants: MetricConstants
	) {}

	getCheckboxFormatter = (gridMode: GridMode, settingsState?: SettingsState): ColumnFormatter<boolean, AttributeGridItem> => {
		return (row, cell, value, columnDef, dataContext) => {
			if (this.isFolder(dataContext)) {
				return '';
			}

			if (!this.isAvailable(columnDef, dataContext)
					|| !this.isEnabled(columnDef, value, settingsState)) {
				let editable = false;
				let checked = false;
				return this.gridFormatterService.getCheckBoxFormatter(checked, !editable);
			}

			let formatter = this.gridInputFormatterService.getCheckboxFormatter(gridMode);
			return formatter(row, cell, value, columnDef, dataContext);
		};
	}

	private isFolder = (gridItem: AttributeGridItem): gridItem is IFolder => {
		return gridItem.type === FolderTypes.ATTRIBUTE;
	}

	private isAvailable = (columnDef, attribute: IAdminAttribute): boolean => {
		if (columnDef.field === SettingsProperty.SHOW_IN_DOC_EXPLORER
				&& this.metricConstants.isWordAttribute(attribute.name))
			return false;
		if (columnDef.field === SettingsProperty.USE_IN_CLARABRIDGE_SEARCH
				&& !this.isAvailableForSearch(attribute))
			return false;
		return true;
	}

	private isAvailableForSearch = (attribute: IAdminAttribute): boolean => {
		return (attribute.reportable && (attribute.type === AttributeType.TEXT || attribute.type === AttributeType.NUMBER))
			|| this.metricConstants.isWordAttribute(attribute.name);
	}

	private isEnabled = (columnDef, value, settingsState: SettingsState): boolean => {
		if (columnDef.field === SettingsProperty.USE_IN_CLARABRIDGE_SEARCH
				&& !settingsState.canAddEnabledForSearch()
				&& !value)
			return false;
		return true;
	}
}

app.service('attributeGridFormatterService', downgradeInjectable(AttributeGridFormatterService));
