import ILocale from '@cxstudio/interfaces/locale-interface';
import { IScheduledJobType } from './scheduled-job-type';
import { ScheduleSettings } from './../schedule-settings';
import { JobType } from './job-type';
import { CxLocaleService } from '@app/core';

export class RefreshJob implements IScheduledJobType {
	displayName: string;
	titleString: string;
	value = JobType.refresh;
	ignoreFields = ['emailTitle', 'emailBody', 'hierarchyId', 'useAutosizing', 'height', 'width', 'type', 'distribution'];
	apiPreprocessor = (settings: ScheduleSettings): void => {
		this.ignoreFields.forEach(field => {
			delete settings[field];
		});
	}

	constructor(locale: ILocale | CxLocaleService) {
		this.displayName = locale.getString('schedule.refresh');
		this.titleString = locale.getString('schedule.refresh');
	}

}
