import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ConversationSettingsListActions } from './conversation-settings-list-actions.service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { ConversationSettingsEntry, ConversationSettingsType } from './conversation-settings.entity';
import { CxLocaleService } from '@app/core';


@Injectable()
export class ConversationSettingsContextMenu extends BaseContextMenuUtils {

	private readonly OPTIONS: {[name: string]: ContextMenuItem<ConversationSettingsEntry>} = {
		EDIT: {
			name: 'edit',
			text: this.locale.getString('common.edit'),
			func: (settings: ConversationSettingsEntry) => this.conversationSettingsListActions.edit(settings)
		},

		RESET: {
			name: 'reset',
			text: this.locale.getString('common.resetDefault'),
			func: (settings: ConversationSettingsEntry) => this.conversationSettingsListActions.resetDefault(settings)
		},

		DELETE: {
			name: 'delete',
			text: this.locale.getString('common.delete'),
			func: (settings: ConversationSettingsEntry) => this.conversationSettingsListActions.delete(settings)
		}
	};

	constructor(private locale: CxLocaleService, private conversationSettingsListActions: ConversationSettingsListActions) {
		super();
	}

	getContextMenu(item: ConversationSettingsEntry): Array<ContextMenuItem<ConversationSettingsEntry>> {
		let options: Array<ContextMenuItem<ConversationSettingsEntry>> = [this.OPTIONS.EDIT];

		if (item.type !== ConversationSettingsType.SYSTEM) {
			options.push(this.OPTIONS.DELETE);
		} else {
			options.push(this.OPTIONS.RESET);
		}


		return options;
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}
