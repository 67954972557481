import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core/cx-http.service';
import { AssetCreationData } from '@app/modules/unified-templates/common-templates/dto/asset-creation-data';
import { AssetTemplateData } from '@app/modules/unified-templates/common-templates/dto/asset-template-data';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { UnifiedTemplateTreeItem, UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TemplateAssetData } from '@app/modules/unified-templates/common-templates/dto/template-asset-data';
import { TemplatePlaceholder, TemplatePlaceholderWithData } from '@app/modules/unified-templates/common-templates/dto/template-placeholder';
import { HttpPromise } from '@app/core/http-promise';

@Injectable({
	providedIn: 'root'
})
export class AssetTemplateApiService {
	constructor(
		private cxHttp: CxHttpService
	) {}

	getTemplateAssetData(type: TemplateAssetType, assetId: number): Promise<TemplateAssetData> {
		return this.cxHttp.get(`rest/asset-templates/${type.toLowerCase()}/asset/${assetId}/metadata`);
	}

	getTemplateAssetDataFromTemplate(type: TemplateAssetType, templateId: number): Promise<TemplateAssetData> {
		return this.cxHttp.get(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}/metadata`);
	}

	getTemplateAssetPlaceholdersData(type: TemplateAssetType, templateId: number): Promise<TemplatePlaceholderWithData[]> {
		return this.cxHttp.get(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}/placeholders`);
	}

	createTemplate(type: TemplateAssetType, assetId: number, template: AssetTemplateData): Promise<void> {
		return this.cxHttp.post(`rest/asset-templates/${type.toLowerCase()}/asset/${assetId}`, template);
	}

	getTemplatesTree(type: TemplateAssetType): Promise<UnifiedTemplateTreeItem[]> {
		return this.cxHttp.get(`rest/asset-templates/${type.toLowerCase()}`);
	}

	getTemplatesFolders(type: TemplateAssetType): Promise<IFolderItem[]> {
		return this.cxHttp.get(`rest/asset-templates/${type.toLowerCase()}/folders`);
	}

	createFromTemplate(type: TemplateAssetType, templateId: number, assetData: AssetCreationData): Promise<void> {
		return this.cxHttp.post(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}`, assetData);
	}

	changePublicStatus(type: TemplateAssetType, templateId: number, isPublic: boolean): Promise<void> {
		return this.cxHttp.put(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}/public/${isPublic}`);
	}

	deleteTemplate(type: TemplateAssetType, templateId: number): Promise<void> {
		return this.cxHttp.delete(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}`);
	}

	updateTemplateProperties(type: TemplateAssetType, templateId: number, template: UnifiedTemplate): Promise<any> {
		return this.cxHttp.put(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}/properties`, template);
	}

	updateTemplateMappings(type: TemplateAssetType, templateId: number, placeholders: TemplatePlaceholder[]): Promise<any> {
		return this.cxHttp.put(`rest/asset-templates/${type.toLowerCase()}/template/${templateId}/mappings`, placeholders);
	}

	exportTemplates(type: TemplateAssetType): HttpPromise<string> {
		return this.cxHttp.rawGet(`rest/asset-templates/${type.toLowerCase()}/transfer/export-all`);
	}

	exportTemplate(type: TemplateAssetType, templateId: number): HttpPromise<string> {
		return this.cxHttp.rawGet(`rest/asset-templates/${type.toLowerCase()}/transfer/template/${templateId}/export`);
	}

	getImportTemplatesUrl(type: TemplateAssetType) {
		return `rest/asset-templates/${type.toLowerCase()}/transfer/import-all`;
	}

}

app.service('assetTemplateApi', downgradeInjectable(AssetTemplateApiService));
