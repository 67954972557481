import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IDrillMenuOptionGroup } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { TopicDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/topic-drill-utils.class';
import * as _ from 'underscore';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { PromiseUtils } from '@app/util/promise-utils';

export class TopicChildrenDrill {

	constructor(
		private readonly locale: ILocale,
		private readonly DrillTo,
		private readonly reportModelsService: ReportModelsService,
	) {}

	getOptions = (params, point, grouping) => {
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 30,
			text: this.locale.getString('widget.children'),
			items: this.getDrillItems(params, point, grouping),
			name: 'children',
			searchBox: false,
			levels: 1,
			selected: true
		};
	}

	private getDrillItems(params: IDrillParams, point, grouping): () => ng.IPromise<any> {
		return () => {
			const modelId = parseInt(grouping.name, 10);
			const drillToHelper = new this.DrillTo(params.widget, params.menuActions, params.defaultColor);
			return PromiseUtils.old(this.reportModelsService.getWidgetModelTree(params.widget, modelId)).then(modelTree => {
				const tree = modelTree.root.children;
				const selectedNode = TopicDrillUtils.findInHierarchy(tree, 'id', point.modelNodeId);
				const selectedNodes = _.map(selectedNode.children, child => child.id);

				const groupBy = {
					selectedLevel: selectedNode.level + 1,
					type: grouping.type,
					name: grouping.name,
					displayName: grouping.displayName,
					selectedNodes,
					metricType: AnalyticMetricType.CLARABRIDGE
				};

				point.groupBy = groupBy;
				return drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, params.widget.properties.widgetType);
			});
		};
	}
}

app.service('topicChildrenDrill', TopicChildrenDrill);

