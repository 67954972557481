<div class="d-flex align-items-center br-dashboard-nav">

	<undo-controls *ngIf="editMode" class="mr-8"></undo-controls>

	<snapshot-date *ngIf="isSnapshotView() && dashboard.snapshotMetadata"
		[date]="dashboard.snapshotMetadata.createdDate">
	</snapshot-date>
	<div *ngIf="canToggleSnapshotView()"
		class="d-flex align-items-center mr-16">
		<cb-toggle
			data-testid="toggle-dashboard-snapshot-view"
			class="ml-4 mr-8"
			[ngModel]="isSnapshotView()"
			(ngModelChange)="onSnapshotViewChange($event)"
			[disabled]="!hasSnapshot()"
			[ngbTooltip]="getNoSnapshotDataTooltip()"
			placement="bottom"
		>
		</cb-toggle>
		<b [i18n]="'dashboard.snapshotView'"></b>
	</div>

	<button class="btn btn-primary br-dashboard-return"
		(click)="processReturnButton()"
		*ngIf="!tablet && showReturnButton()">
		{{getReturnButtonLabel()}}
	</button>

	<button
		data-testid="btn-dashboard-edit"
		class="btn btn-primary"
		(click)="edit.emit()"
		[attr.aria-label]="getEditDashboardLabel()"
		[disabled]="isSnapshotView()"
		*ngIf="canEditDashboard() && !tablet && !editMode && !isPreviewAsMode() && !isFullscreen() && isEditingAllowed()">
		{{'dashboard.edit' | i18n}}
	</button>

	<button class="btn btn-secondary br-dashboard-return-to-book"
		(click)="returnToBook.emit()"
		*ngIf="!editMode && !isFullscreen() && isEditingAllowed() && isEditedFromBook()">
		{{'dashboard.returnToBook' | i18n}}
	</button>

	<div class="mr-8 br-spot-check"
		*ngIf="editMode"
		ngbDropdown
		#spotCheckBox="ngbDropdown"
		display="static"
		[autoClose]="'outside'">
		<button class="btn btn-secondary" (keydown.enter)="onSpotCheckDialogOpenViaKeyboard($event)" ngbDropdownToggle>
			<span class="q-icon q-icon-bulb mr-8" aria-hidden="true"></span>
			<span [i18n]="'dashboardSpotCheck.spotCheck'"></span>
		</button>
		<div ngbDropdownMenu aria-labelledby="dropdownManual">
			<spot-check-modal
				*ngIf="spotCheckBox.isOpen()"
				[dashboardId]="dashboard.id"
				(closeDialog)="onSpotCheckDialogClose($event)"
				(keydown)="processSpotCheckModalKeyboard($event)"
			></spot-check-modal>
		</div>
	</div>

	<button
		data-testid="btn-dashboard-save-and-return"
		class="btn btn-primary"
		[disabled]="isSaveDisabled()"
		(click)="saveAndReturn()"
		*ngIf="canEditDashboard() && !tablet && editMode && isEditedFromBook()">
		{{'dashboard.saveAndReturn' | i18n}}
	</button>

	<button
		data-testid="btn-dashboard-save"
		class="btn btn-secondary"
		[ngClass]="{'btn-primary': !isEditedFromBook(), 'btn-secondary': isEditedFromBook()}"
		*ngIf="canEditDashboard() && !tablet && editMode"
		[disabled]="isSaveDisabled()"
		(click)="save()">
		{{'dashboard.dashboardSave' | i18n}}
	</button>

	<button
		data-testid="btn-dashboard-cancel"
		class="btn btn-secondary"
		*ngIf="canEditDashboard() && !tablet && editMode"
		(click)="cancel.emit()">{{'dashboard.dashboardCancel' | i18n}}
	</button>

	<button
		class="btn btn-secondary"
		data-testid="btn-dashboard-share"
		(click)="shareClick()"
		[disabled]="isSaveDisabled()"
		[attr.aria-label]="getShareDashboardLabel()"
		*ngIf="!mobile && canShareDashboard() && dashboardActionsAllowed()"
	>
		{{(editMode ? 'common.saveAndShare' : 'dashboard.dashboardShare') | i18n}}
	</button>

	<button class="btn btn-icon q-icon-help btn-margin-left no-border dark:text-white dark:hover:text-brand-color-300"
		*ngIf="canEditDashboard() && !tablet && editMode"
		title="{{'dashboardTroubleshooting.troubleshootingGuides'|i18n}}"
		attr.aria-label="{{'dashboardTroubleshooting.troubleshootingGuides'|i18n}}"
		(click)="openTroubleshootingGuidesModal()">
	</button>

	<fullscreen-button
		class="d-flex"
		[ariaName]="dashboard?.name"
		*ngIf="isFullscreenAllowed()">
	</fullscreen-button>

	<actions-menu
		*ngIf="dashboardActionsAllowed()"
		[menuItems]="dashboardMenu"
		[dashboard]="dashboard"
		[zoom]="layout.zoomLevel"
		[(widgetMovementOption)]="widgetMovementOption"
		(onWidgetMovementChange)="updateMovementOption($event)"
	></actions-menu>
</div>
