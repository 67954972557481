import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TemplatePlaceholder } from '@app/modules/unified-templates/common-templates/dto/template-placeholder';
import { PlaceholderDisplayType, PlaceholderUsage } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { AssetSpecificUtilsService } from '@app/modules/unified-templates/common-templates/asset-specific-utils.service';

export interface IPlaceholderItem {
	placeholder: TemplatePlaceholder;
	usages: PlaceholderUsage[];
	type: PlaceholderDisplayType;
}

@Component({
	selector: 'template-placeholders-list',
	templateUrl: './template-placeholders-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePlaceholdersListComponent {

	@Input() placeholders: IPlaceholderItem[];
	@Input() label: string;
	selectedItem: IPlaceholderItem |  null = null;

	constructor(
		private assetSpecificUtils: AssetSpecificUtilsService,
	) {}

	showUsageInline(item: IPlaceholderItem): boolean {
		return !_.isEmpty(item.usages)
			&& item.type !== PlaceholderDisplayType.GROUPING
			&& item.type !== PlaceholderDisplayType.CALCULATION;
	}

	getUsageText(usages: PlaceholderUsage[]): string {
		return usages.map(usage => this.assetSpecificUtils.getUsageDisplayName(usage)).join(', ');
	}

	selectRow(item: IPlaceholderItem): void {
		if (item.usages?.length > 0) {
			this.selectedItem = item;
		}
	}

	onSidebarClose(): void {
		this.selectedItem = null;
	}

}
