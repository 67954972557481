import { Component, OnInit, ChangeDetectionStrategy, Inject, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { SMTPServerSettings } from '@cxstudio/system-administration/master-accounts/integrations/smtp-server-settings';

@Component({
	selector: 'smtp-settings',
	templateUrl: './smtp-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmtpSettingsComponent implements OnInit, OnChanges {
	@Input() settings: SMTPServerSettings;	
	
	loading: boolean;
	initialSettings: SMTPServerSettings;
	smtpIncomplete: boolean;
	smtpUnchanged: boolean;
	smtpSubmissionSuccess: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
	) { }

	ngOnInit(): void {	
		this.settings = new SMTPServerSettings();
		this.loading = true;
		this.setInitialSMTPInfo();

		this.smtpIncomplete = true;
		this.smtpUnchanged = true;		
	}

	ngOnChanges(changes: SimpleChanges<SmtpSettingsComponent>): void {
		if (ChangeUtils.hasAnyChange(changes.settings)) {
			this.loading = false;
			this.setInitialSMTPInfo();
			this.validateSMTP();
		}
	}

	validateSMTP = (): void => {
		this.smtpUnchanged = _.isEqual(this.settings, this.initialSettings);
		this.smtpIncomplete = !this.settings.host
				|| !this.settings.port
				|| !this.settings.username
				|| !this.settings.password;
		this.ref.markForCheck();
	}

	setInitialSMTPInfo = (): void => {
		this.initialSettings = ObjectUtils.copy(this.settings);
	}

	switchEmailStatus = (enabled: boolean): void => {
		this.settings.enabled = enabled;

		if (enabled && this.smtpIncomplete) {
			return;
		}

		this.loading = true;
		this.masterAccountApiService.updateSmtpProperties(this.settings).then(() => {
			this.loading = false;
			this.ref.markForCheck();
		});
	}

	saveSMTP = (): void => {
		this.loading = true;
		this.masterAccountApiService.updateSmtpProperties(this.settings).then(() => {
			this.setInitialSMTPInfo();
			this.smtpUnchanged = true;
			this.smtpSubmissionSuccess = true;
			this.loading = false;
			this.ref.markForCheck();
		});
	}
}

app.directive('smtpSettings', downgradeComponent({component: SmtpSettingsComponent }));
