import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { DEFAULT_DATE_RANGE, IReportWidgetContext, Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export class MetricWidget extends ReportWidget {

	constructor() {
		super(WidgetType.METRIC);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initFromContext(context);
		super.withFilters(context);

		this.widget.properties.selectedMetrics = [];
		this.widget.visualProperties.visualization = WidgetVisualization.CB_AN_METRIC;

		super.withShowSampleSize(context);

		this.initDateRanges(context);
		this.initCalculations(context);

		super.inherit('visualProperties.previousPeriod', context, undefined);
		super.inherit('visualProperties.trendArrow', context, undefined);
	}

	private initDateRanges(context: IReportWidgetContext): void {
		let p2DateRange = {
			dateFilterMode: 'previousPeriod'
		};

		super.withDateRanges(context, {
			properties: [
				{name: 'dateRangeP1', default: DEFAULT_DATE_RANGE},
				{name: 'dateRangeP2', default: p2DateRange}
			],
			visual: [{
				name: 'periodLabel',
				default: {
					period_1_: '',
					period_2_: ''
				}
			}]
		});
	}

	private initCalculations(context): void {
		let calculations = WidgetPropertyService.getValue(
			'properties.selectedMetrics', context, []);

		this.widget.properties.selectedMetrics = calculations;
	}

}
