import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FeatureLimiterService } from '@app/modules/feature-limiter/feature-limiter.service';

@NgModule({
	providers: [
		FeatureLimiterService
	],
	imports: [
		CommonModule,
		SharedModule
	],
})
export class FeatureLimiterModule {}
