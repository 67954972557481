import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IAsset } from '@app/modules/access-management/groups/asset';
import { AssetType } from '@app/modules/access-management/groups/asset-type';
import { ModificationDelta } from '@app/modules/access-management/groups/modification-delta';
import { DependenciesDialogInput, DependenciesModalComponent } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { ModelDependencyTypesProvider } from '@app/modules/model/services/model-dependency-types-provider';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { ObjectListMenuService } from '@app/modules/object-list/object-list-menu.service';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { GlobalOtherExplorerModalComponent } from '@app/modules/project/global-other-explorer-modal/global-other-explorer-modal.component';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { ModelManagementService } from '@app/modules/project/model/model-management.service';
import { AdminAssetsManagementService, IExtendedAdminModel } from '@app/modules/project/project-assets-management/project-assets-list/admin-assets-management.service';
import { AssetDefaultsService } from '@app/modules/project/project-assets-management/project-assets-list/asset-defaults.service';
import { EditableAssetsTable } from '@app/modules/project/project-assets-management/project-assets-list/editable-assets-table.interface';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import ModelGeography from '@cxstudio/attribute-geography/model-geography';
import { Security } from '@cxstudio/auth/security-service';
import { Caches } from '@cxstudio/common/caches';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { ModelIdentifier } from '@cxstudio/projects/model-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectTabType } from '@cxstudio/projects/project-tab-type';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { ColDef, ColumnApi, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef } from 'ag-grid-community';

@Component({
	selector: 'models-table',
	templateUrl: './models-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelsTableComponent implements OnInit, OnChanges, OnDestroy, EditableAssetsTable {

	@Input() project: WorkspaceProjectData;
	@Input() accountProject: IProjectSelection;
	@Input() visibility: ProjectTabType;
	@Input() searchText: string;
	@Input() editMode: boolean;

	loading: Promise<unknown>;

	models: IExtendedAdminModel[];

	columnDefs: ColDef[];
	filterManager: TableFilterManager;
	gridApi: GridApi;
	gridColumnApi: ColumnApi;

	ProjectTabType = ProjectTabType;
	AssetType = AssetType;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private modelDependencyTypesProvider: ModelDependencyTypesProvider,
		private adminAssetsManagement: AdminAssetsManagementService,
		private assetAccessApiService: AssetAccessApiService,
		private objectListColumns: ObjectListColumnsService,
		private objectListMenu: ObjectListMenuService,
		private assetDefaultsService: AssetDefaultsService,
		private cachedHttpService: CxCachedHttpService,
		private cxDialogService: CxDialogService,
		private globalUnloadService: GlobalUnloadService,
		private modelManagementService: ModelManagementService,
		@Inject('security') private readonly security: Security,
		@Inject('securityApiService') private readonly securityApiService: SecurityApiService,
		@Inject('geographyOptionsService') private readonly geographyOptionsService: GeographyOptionsService,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
		@Inject('geographyApiService') private readonly geographyApiService: GeographyApiService,
	) { }

	ngOnInit(): void {

		this.filterManager = new TableFilterManager().withHiddenSupport();
		this.filterManager.getFilterChangeObservable().subscribe(() => this.filterAndRedraw());

		this.columnDefs = [
			this.objectListColumns.contextMenuColumn(),
			this.objectListColumns.textColumn('name', this.locale.getString('common.name')),
			this.objectListColumns.textColumn('boundaryFields', this.locale.getString('attributes.geography'), false),
			/* this.objectListColumns.simpleDropdownColumn<IExtendedAdminModel, BoundaryField>('boundaryFields',
				this.locale.getString('attributes.geography'),
				(model) => this.getGeographyOptions(model),
				() => this.isEditable()), */
			this.objectListColumns.dateColumn('lastClassified', this.locale.getString('administration.lastClassified')),
			this.objectListColumns.yesNoBooleanColumn('includeLocalOther', this.locale.getString('administration.localOther')),
			this.objectListColumns.yesNoBooleanColumn('incrementalDataLoads', this.locale.getString('administration.incrementalDataLoads')),
			this.objectListColumns.yesNoBooleanColumn('includeEmptyRecords', this.locale.getString('administration.emptyRecords')),
			this.objectListColumns.checkboxColumn('showInDrill', this.locale.getString('administration.showInDrill'),
				() => this.isEditable()),
			this.objectListColumns.checkboxColumn('showInDocExp', this.locale.getString('administration.showInDocExp'),
				() => this.isEditable()),
			this.objectListColumns.checkboxColumn('showInReporting', this.locale.getString('administration.showInReporting'),
				() => this.isEditable()),
		];
		this.loadModels();

		this.globalUnloadService.addHandler('models-edit', () => this.cancelChanges());
	}

	ngOnChanges(changes: SimpleChanges<ModelsTableComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.loadModels();
		}
		if (ChangeUtils.hasChange(changes.editMode)) {
			this.gridApi.redrawRows();
		}
	}

	ngOnDestroy(): void {
		this.globalUnloadService.removeHandler('models-edit');
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

	}

	private loadModels(): void {
		if (WorkspaceProjectUtils.isProjectSelected(this.project)) {
			this.loading = this.adminAssetsManagement.getModels(this.project).then(models => {
				this.setModels(models);
				this.ref.markForCheck();
				setTimeout(() => this.gridColumnApi.autoSizeAllColumns());
			});
		} else {
			this.setModels([]);
		}
	}

	private setModels(models: IExtendedAdminModel[]): void {
		this.models = models;
		if (!this.models.isEmpty() && this.models.every(attr => attr.hide)) {
			// set to show hidden if everything is hidden
			this.filterManager.setShowHidden(true);
		}
	}

	getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
		if (!params.node) {
			return;
		}
		if (FolderUtils.isFolder(params.node.data)) {
			return [];
		}
		const model = params.node.data as IExtendedAdminModel;
		let options: (string | MenuItemDef)[] = [];

		if (this.canManageDefaults()) {
			options.push({
				name: this.locale.getString('administration.editDefaults'),
				action: () => this.assetDefaultsService.editModelDefaults(model, this.project),
			});
		}
		options.push({
			name: model.hide ? this.locale.getString('common.show') : this.locale.getString('common.hide'),
			action: () => this.toggleHide(model),
		});
		options.push(this.objectListMenu.getAllHiddenOption(this.filterManager));

		options.push({
			name: this.locale.getString('common.dependencies'),
			action: () => this.openDependencies(model),
		});

		options.push({
			name: this.locale.getString('administration.exploreGlobalOther'),
			action: () => this.exploreGlobalOther(model),
		});
		return options;
	}

	private toggleHide(item: IAdminModel): void {
		item.hide = !item.hide;
		let key = this.getModelObjectKey(item);
		this.securityApiService.hideObjectForUser(HiddenItemType.MODELS, item.hide, key, item.name);
		this.security.getHiddenObject(HiddenItemType.MODELS)[key] = !!item.hide;
		this.cachedHttpService.cache(Caches.MODELS).invalidate();
		this.filterAndRedraw();
	}


	protected getModelObjectKey(item: IAdminModel): string {
		return [this.security.getMasterAccountId(), this.accountProject.contentProviderId, this.accountProject.accountId,
			item.id].join('-');
	}

	private filterAndRedraw(): void {
		this.gridApi?.onFilterChanged();
		this.gridApi?.redrawRows();
	}

	private openDependencies(model: IAdminModel): void {
		let input: DependenciesDialogInput<ProjectAsset> = {
			asset: {
				assetId: model.id,
				name: model.name,
				contentProviderId: this.accountProject.contentProviderId,
				projectId: this.accountProject.projectId,
				accountId: this.accountProject.accountId
			} as ProjectAsset,
			dependencyTypesProvider: this.modelDependencyTypesProvider
		};

		this.cxDialogService.openDialog(DependenciesModalComponent, input, { size: ModalSize.LARGE });
	}

	private canManageDefaults(): boolean {
		return this.project?.accessLevel === ProjectAccessLevelValue.MANAGER
			&& this.security.has(MasterAccountPermissionAction.MANAGE_PROJECTS);
	}

	/* private getGeographyOptions(model: IAdminModel): UIOption<BoundaryField>[] {
		return this.geographyOptionsService.getModelOptions();
	} */

	private isEditable(): boolean {
		return this.editMode;
	}

	// there is a lot going on here, need to change this to a single endpoint if possible
	saveChanges(): Promise<void> {
		const cpId = this.accountProject.contentProviderId;
		const accountId = this.accountProject.accountId;
		const projectId = this.accountProject.projectId;

		// doc explorer and drill items just save all enabled (as they were initially implemented that way)
		const hideInDocExplorerItems = this.models.filter(model => !model.showInDocExp);
		const hideInDrillItems = this.models.filter(model => !model.showInDrill);

		// reporting flag is saved as "delta" (added/removed) for improved reliability and performance
		const showInReportingItemsChanges = ObjectListUtils.getFieldChanges(this.models, 'showInReporting');
		const hideInReportingItemsDelta = new ModificationDelta<IAsset>();
		showInReportingItemsChanges.forEach(attr => {
			if (attr.showInReporting) {
				hideInReportingItemsDelta.removed.push({assetId: attr.id, assetType: AssetType.MODEL});
			} else {
				hideInReportingItemsDelta.added.push({assetId: attr.id, assetType: AssetType.MODEL});
			}
		});

		// geography saves only changed values
		const geographyChanges: ModelGeography[] = ObjectListUtils.getFieldChanges(this.models, 'boundaryFields')
			.map(model => ({modelId: model.id, boundaryFields: model.boundaryFields}));

		const promises = [
			PromiseUtils.wrap(this.masterAccountApiService.updateDocExplorerAttributes(cpId, accountId, projectId, hideInDocExplorerItems)),
			PromiseUtils.wrap(this.masterAccountApiService.updateDrillMenuAttributesAndModels(cpId, accountId, projectId, hideInDrillItems)),
			hideInReportingItemsDelta.isEmpty() ? Promise.resolve()
				: this.assetAccessApiService.updateReportingAssetAccess(this.project, hideInReportingItemsDelta),
			PromiseUtils.wrap(this.geographyApiService.saveModelGeographies(this.project, geographyChanges)),
		];
		return Promise.all(promises as any[]).then(() => {
			ObjectListUtils.clearDirty(this.models);
		});
	}

	cancelChanges(): Promise<void> {
		if (!this.hasChanges()) {
			return Promise.resolve();
		} else {
			const dialogMessage = this.locale.getString('administration.projectPropertiesMsg');
			const dialogTitle = this.locale.getString('administration.projectPropertiesTitle');
			return this.cxDialogService.showUnsavedChangesDialog(dialogTitle, dialogMessage).then(result => {
				if (result) {
					return this.saveChanges();
				} else {
					this.loadModels();
					return Promise.resolve();
				}
			}, _.noop);
		}
	}

	private hasChanges(): boolean {
		return this.models.some(item => {
			return !!item._dirty;
		});
	}

	export(): Promise<unknown> {
		if (this.visibility === ProjectTabType.HIDDEN) {
			return this.assetAccessApiService.exportHiddenAssetGroupsInfo(this.project, ObjectType.MODEL);
		} else {
			let visibleData: IExtendedAdminModel[] = [];
			this.gridApi.forEachNodeAfterFilter(node => {
				if (!FolderUtils.isFolder(node.data))
					visibleData.push(node.data);
			});
			return this.modelManagementService.requestModelsReport(this.project, this.filterManager.isShowHidden(),
				[], // TODO honor filters
				visibleData.map(attr => attr.id));
		}
	}

	private exploreGlobalOther(model: IAdminModel): void {
		if (!model.classified) {
			this.cxDialogService.notify(
				this.locale.getString('administration.globalOtherExplorer'),
				this.locale.getString('administration.notClassified'));
		}
		let modelIdentifier: ModelIdentifier = {
			project: this.project,
			modelId: model.id,
			modelName: model.name,
		};
		this.cxDialogService.openDialog(GlobalOtherExplorerModalComponent, {modelIdentifier}, {
			container: '#modal-container',
			size: ModalSize.LARGE,
			keyboard: false,
		});
	}

}
