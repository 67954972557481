import { CxLocaleService } from '@app/core';
import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class CxFormUtils {

	constructor(private readonly locale: CxLocaleService) {}


	getErrorMessage(key: string): string {
		return this.locale.getString('common.fieldRequiredError', { field: this.locale.getString(key) });
	}

	disableFields(...fields: FormControl[]) {
		this.changeDisableState(false, fields);
	}

	enableFields(...fields: FormControl[]) {
		this.changeDisableState(true, fields);
	}

	private changeDisableState(state: boolean, fields: FormControl[]): void {
		if (state) {
			fields.forEach(field => field.enable());
		} else {
			fields.forEach(field => field.disable());
		}
	}

	getErrorsCount(form: AbstractControl): number {
		if (form instanceof FormControl) {
			return form.errors && Object.keys(form.errors).length || 0;
		}
		else if (form instanceof FormGroup) {
			const childErrorsCount = _.chain(Object.keys(form.controls))
				.map((key) => this.getErrorsCount(form.controls[key]))
				.reduce((a, b) => a + b)
				.value();
			return childErrorsCount + (form.errors ? Object.keys(form.errors).length : 0);
		}
		return 0;
	}
}
