export enum AmplitudeExplorerTabName {
	ATTRIBUTES = 'Attributes',
	ENRICHMENTS = 'Enrichments',
	TOPICS = 'Topics',
	SCORECARDS = 'Intelligent Scoring',
	AUTOMATED_SUMMARIES = 'Automated Call Summaries',
	INBOX = 'Inbox',
	KEY_INFO = 'Key Info',
	COMPLIANCE_MANAGEMENT = 'Compliance Management'
}
