<div class="mobile-app-customizations">
	<div class="panel panel-default">
		<div class="panel-heading font-bold">
			{{'mobile.filters'|i18n}}
			<i *ngIf="errors.hasScope('saved-filters')" class="q-icon q-icon-warning tab-alert-icon alert-icon"></i>
		</div>
		<div class="panel-body clearfix">
			<div>
				<div class="col-md-4 ph-0 pt-8 saved-filters">
					<searchable-hierarchy
						placeholder="{{'mobile.searchSavedFilters'|i18n}}"
						[hierarchyList]="savedFilters"
						[displayProperty]="'name'"
						(onNodeClick)="selectSavedFilter($event.node)">
					</searchable-hierarchy>
				</div>
				<div class="col-md-8 pt-8">
					<alert type="warning" *ngIf="hasUserTriedToExceedSavedFiltersLimit()" text="{{'mobile.savedFiltersLimitReached'|i18n}}"></alert>
					<div class="font-bold" [i18n]="'mobile.availableSavedFilters'"></div>

					<table class="available-saved-filters draggable-items-table" cdkDropList (cdkDropListDropped)="dropSavedFilter($event)">
						<tbody>
							<tr *ngFor="let savedFilter of settings.availableSavedFilters" class="saved-filter" cdkDrag>
								<td class="grip-cell">
									<cb-drag-handle></cb-drag-handle>
								</td>

								<td class="saved-filter-name">{{savedFilter.name}}</td>

								<td class="remove-saved-filter">
									<a (click)="removeSavedFilter(savedFilter)"><i class="q-icon-delete"></i></a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
