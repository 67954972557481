import { Output, EventEmitter } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';

@Component({
	selector: 'description-menu-item',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<a (click)="!disabled && toggle.emit()"
	data-testid="widget-description-mode-toggle"
	class="dropdown-item option description-mode"
	[class.disabled]="disabled"
	role="menuitem"
	href="javascript:void(0)">{{getDescriptionText()}}</a>`
})
export class DescriptionMenuItemComponent {
	@Input() active: boolean;
	@Input() disabled: boolean;
	@Input() visualProperties: VisualProperties = {};
	@Input() properties: WidgetProperties = {};
	@Output() toggle = new EventEmitter<void>();

	constructor(
		private readonly locale: CxLocaleService
	) {

	}

	getDescriptionText(): string {
		let localizationEntry = !this.active ?
			'widget.enableDescriptionMode' :
			'widget.disableDescriptionMode';

		if (this.visualProperties.visualization === WidgetVisualization.CB_AN_TABLE
			|| this.visualProperties.visualization === WidgetVisualization.DOCUMENT_PREVIEW
			|| (this.visualProperties.visualization === WidgetVisualization.CB_AN_METRIC && this.properties?.selectedMetrics?.length > 1)) {
			localizationEntry = !this.active ?
				'widget.enableDescriptionMode' :
				'widget.disableTableDescriptionMode';
		}

		return this.locale.getString(localizationEntry);
	}
}

app.directive('descriptionMenuItem', downgradeComponent({component: DescriptionMenuItemComponent }));
