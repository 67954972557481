import * as moment from 'moment';
import { EventEmitter, Inject, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { ContractSecurityLevel } from '@cxstudio/master-accounts/contracts/contract-security-level';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { LicenseType } from '@cxstudio/common/license-types';

@Component({
	selector: 'contracts',
	templateUrl: './contracts.component.html'
})
export class ContractsComponent implements OnInit {

	@Input() contracts: Contract[];
	@Input() licenses: LicenseTypeItem[];
	@Output() updateTabContract: EventEmitter<Contract> = new EventEmitter<Contract>();
	@Output() deleteTabContract: EventEmitter<Contract> = new EventEmitter<Contract>();
	@Output() viewTabContract: EventEmitter<Contract> = new EventEmitter<Contract>();

	editMenuOption: ContextMenuItem<Contract>;
	deleteMenuOption: ContextMenuItem<Contract>;
	viewMenuOption: ContextMenuItem<Contract>;

	constructor(
		@Inject('security') private readonly security: Security,
		@Inject('dateService') private dateService: DateService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree,
		private betaFeaturesService: BetaFeaturesService,
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.editMenuOption = {
			text: this.locale.getString('administration.edit'),
			name: 'editContract',
			func: this.editContract
		};
		this.deleteMenuOption = {
			text: this.locale.getString('administration.delete'),
			name: 'deleteContract',
			func: this.deleteContract
		};
		this.viewMenuOption = {
			text: this.locale.getString('administration.view'),
			name: 'viewContract',
			func: this.viewContract
		};
	}

	private editContract = (contract: Contract) => {
		this.updateTabContract.emit(contract);
	}

	private deleteContract = (contract: Contract) => {
		this.deleteTabContract.emit(contract);
	}

	private viewContract = (contract: Contract) => {
		this.viewTabContract.emit(contract);
	}

	formatDate = (date) => {
		return this.dateService.format(date, DateTimeFormat.BASIC_DATE);
	}

	getLicenseSeats = (contract: Contract, licenseId: LicenseType) => {
		let seats = contract.licenses[licenseId];
		return seats ? seats : 0;
	}

	getActiveState = (contract: Contract) => {
		return contract.active ? this.locale.getString('common.yes') : this.locale.getString('common.no');
	}

	getPeriod = (contract: Contract) => {
		let defaultValue = '1/1';
		if (!contract.active) return;

		if (!contract.periods || contract.periods === 1) {
			return defaultValue;
		}

		let diff = moment(contract.endDate).add(1, 'days').diff(contract.startDate);
		if (!diff) return;

		let currentPeriod = moment().diff(contract.startDate) / diff;
		return Math.ceil(currentPeriod * contract.periods) + '/' + contract.periods;
	}

	getSecurityLevel = (contract: Contract) => {
		let level = contract.security;
		let uiSecurityLevel = ContractSecurityLevel[level];
		if (uiSecurityLevel) {
			return this.locale.getString('mAccount.' + uiSecurityLevel);
		}
	}

	private getMenuOptions(): Array<ContextMenuItem<Contract>> {
		if (this.security.has('system_administration') || this.security.has('account_owner')) {
			return [this.editMenuOption, this.deleteMenuOption, this.viewMenuOption];
		} else {
			return [this.viewMenuOption];
		}
	}

	showContractMenu = (event, contract: Contract) => {
		this.contextMenuTree.showObjectListMenu(event, contract, this.getMenuOptions(), 'contracts', 360);
	}

	private licenseHasContractWithSeats = (license: LicenseTypeItem) => {
		return _.filter(this.contracts, (contract) => {
			return contract.licenses[license.licenseTypeId] > 0;
		}).length;
	}

	licenseCountWithSeats = () => {
		let licenseMap = {};
		_.map(this.licenses, (license) => {
			licenseMap[license.licenseTypeId] = this.licenseHasContractWithSeats(license);
		});
		let licenseCount = 0;
		Object.keys(licenseMap).forEach((licenseTypeId) => {
			licenseCount += licenseMap[licenseTypeId] ? 1 : 0;
		});

		return licenseCount;
	}

	isAdditionalSecuritySpecified = () => {
		return _.filter(this.contracts, (contract) => {
			return contract.security === ContractSecurityLevel.EXTENDED;
		}).length;
	}

	isVoiceEnabled = () => {
		return this.security.isVoiceEnabled();
	}

	isIntelligentScoringEnabled = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	}

	getIntelligentScoring = (contract: Contract) => {
		return contract.intelligentScoring
			? this.locale.getString('common.yes')
			: this.locale.getString('common.no');
	}

	getCoverage = (contract: Contract) => {
		return contract.intelligentScoring
			? contract.coverage + '%'
			: '';
	}

	getAudioValue(contract: Contract): string {
		return contract.audioHours > 0 || contract.audioRetention > 0
			? this.locale.getString('common.yes')
			: this.locale.getString('common.no');
	}

	getAgentAssistValue(contract: Contract): string {
		return contract.realTimeAgentAssist || contract.automatedCallSummary
			? this.locale.getString('common.yes')
			: this.locale.getString('common.no');
	}
}
