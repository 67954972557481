<ul class="aggregations-submenu dropdown-menu dropdown-right w-auto w-max-280" id="{{id}}"
	[style.top]="getTop()">
	<li *ngFor="let aggregation of aggregations | filterBy:['displayName']; index as suggestionIndex"
		[class.active]="suggestionIndex === highlightedAggregationIndex"
		(click)="submenuItemClick.emit(aggregation)"
		(keydown)="onAggregationKeydown($event, aggregation, suggestionIndex)">
		<a class="math-aggregation d-flex align-items-center justify-between"
			href="javascript:void(0)">
			{{aggregation.displayName}}
		</a>
	</li>
</ul>
