<div>
	<div *ngIf="inheritFormatFromMetric !== undefined" class="mb-16 d-flex align-items-center">
		<cb-toggle
			[(ngModel)]="inheritFormatFromMetric"
			(ngModelChange)="updateInheritFormatFromMetric($event)"
		></cb-toggle>
		<span class="ml-16">{{"widget.inheritFormatFromMetric"|i18n}}</span>
	</div>
	<number-format-preview
		*ngIf="!simplePreview"
		class="d-inline-block w-100-percent"
		[format]="formatObjectForPreview"
		[defaultFormat]="defaultFormat">
	</number-format-preview>

	<simple-number-format-preview
		*ngIf="simplePreview"
		class="d-inline-block w-100-percent mb-8"
		[format]="formatObjectForPreview"
		[defaultFormat]="defaultFormat"
		[omitPreviewPrefix]="false">
	</simple-number-format-preview>

	<div class="mb-16 widget-data-format d-flex flex-wrap">
		<div class="w-120 mr-16 mb-8">
			<label for="prefix">{{'widget.prefix'|i18n}}</label>
			<div *ngIf="mustInherit">
				<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
			</div>
			<div *ngIf="!mustInherit">
				<input id="prefix"
					[disabled]="viewOnly()"
					class="form-control"
					[(ngModel)]="formatObject.prefix"
					(ngModelChange)="changeHandler()"
					maxlength="10">
			</div>
		</div>

		<div class="w-120 mr-16 mb-8">
			<label for="thousandsDelimiter">{{'widget.thousandsDelimiterFieldName'|i18n}}</label>
			<div *ngIf="mustInherit">
				<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
			</div>
			<div *ngIf="!mustInherit">
				<input-with-default
					class="thousands-delimiter"
					[disabled]="viewOnly()"
					defaultValue="{{'widget.delimiter'|i18n}}"
					[(ngModel)]="formatObject.thousandsDelimiter"
					(ngModelChange)="changeHandler()"
					[maxlength]=1
				></input-with-default>
			</div>
		</div>

		<div class="w-120 mr-16 mb-8">
			<label for="decimalDelimiter">{{'widget.decimal'|i18n}}</label>
			<div *ngIf="mustInherit">
				<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
			</div>
			<div *ngIf="!mustInherit">
				<input-with-default
					class="decimal-delimiter"
					[disabled]="viewOnly()"
					defaultValue="{{'widget.decimalPoint'|i18n}}"
					[(ngModel)]="formatObject.decimalDelimiter"
					(ngModelChange)="updatePrecisionOptions()"
					[maxlength]=1
				></input-with-default>
			</div>
		</div>

		<div class="w-120 mr-16 mb-8">
			<label for="suffix">{{'widget.suffix'|i18n}}</label>
			<div *ngIf="mustInherit">
				<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
			</div>
			<div *ngIf="!mustInherit">
				<input id="suffix"
					[disabled]="viewOnly()"
					class="form-control"
					[(ngModel)]="formatObject.suffix"
					(ngModelChange)="changeHandler()"
					maxlength="10">
			</div>
		</div>
	</div>

	<div class="mb-16 widget-data-format">
		<label for="">{{'widget.precision'|i18n}}</label>
		<div *ngIf="mustInherit">
			<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
		</div>
		<div *ngIf="!mustInherit">
			<radio-buttons
				class="br-metric-decimals"
				name="decimals"
				[disabled]="viewOnly()"
				[options]="options.decimals"
				[(ngModel)]="formatObject.decimals"
				(ngModelChange)="changeHandler()"
			></radio-buttons>
		</div>
	</div>

	<div class="mb-16 widget-data-format">
		<label for="">{{'widget.conversion'|i18n}}</label>
		<radio-buttons
			class="br-metric-conversion"
			name="conversion"
			[disabled]="viewOnly()"
			[options]="options.conversions"
			[(ngModel)]="formatObject.conversion"
			(ngModelChange)="changeHandler()"
		></radio-buttons>
	</div>

	<div class="widget-data-format">
		<label for="">{{'widget.truncation'|i18n}}</label>
		<div *ngIf="mustInherit">
			<label data-testid="force-inherit"><i>--{{'widget.inherit'|i18n}}--</i></label>
		</div>
		<div *ngIf="!mustInherit">
			<radio-buttons
				class="br-metric-truncation"
				name="truncation"
				[disabled]="viewOnly()"
				[options]="options.truncation"
				[(ngModel)]="formatObject.truncation"
				(ngModelChange)="changeHandler()"
			></radio-buttons>
		</div>
	</div>
</div>
