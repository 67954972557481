import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DialogStyle} from '../../dialog-style';

@Component({
	selector: 'alternate-confirm-dialog',
	templateUrl: './alternate-confirm-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlternateConfirmDialogComponent {
	@Input() header: string;
	@Input() text: string;
	@Input() confirmBtnName: string;
	@Input() altConfirmBtnName: string;
	@Input() cancelBtnName: string;
	@Input() dialogStyle: DialogStyle = DialogStyle.REGULAR;
	warningDialogStyle = DialogStyle.WARNING;

	constructor(
		private readonly modal: NgbActiveModal
	) {}

	confirm = (value: boolean): void => {
		this.modal.close(value);
	}

	cancel = (): void => {
		this.modal.dismiss();
	}
}
