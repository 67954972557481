import { Injectable } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CustomMathHighlightService } from './custom-math-highlight.service';
import { MathAggregation } from '../tokenizer/custom-math-tokenizer.service';
import { CustomMathSuggestion, TokenSuggestion } from './custom-math-suggestion.class';
import { MathMetricToken, MathMetricTokenType } from './math-metric-token';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { FormulaSegment } from '../adapter/formula-segment';
import { CustomMathUtils } from './custom-math-utils.service';
import { QualtricsIconsId } from '@clarabridge/unified-icons/src/types/qualtrics-icons';
import { AttributeCalculationsOptionsService } from '../attribute-calculations-options.service';

export interface StickSuggestionSegments {
	current: FormulaSegment;
	next: FormulaSegment;
	stored: FormulaSegment;
}

export interface StickSuggestionTokens {
	current: MathMetricToken;
	next: MathMetricToken;
	previous: MathMetricToken;
	storedCurrent: MathMetricToken;
	storedNext: MathMetricToken;
	storedPrevious: MathMetricToken;
}

export interface ReplaceText {
	from: number;
	to: number;
	value: string;
}

export enum SuggestionIcon {
	NUMERIC_ATTRIBUTE = 'number',
	TEXT_ATTRIBUTE = 'string',
	FUNCTION = 'aggregate-function',
	PREDEFINED_METRIC = 'qualtrics-xm',
	CUSTOM_METRIC = 'custom-metric',
	SCORECARD_METRIC = 'scorecard',
	HIERARCHY_METRIC = 'flow'
}

@Injectable({providedIn: 'root'})
export class CustomMathSuggestionsService {

	readonly NUMERIC_AGGREGATIONS =  [
		MathAggregation.AVERAGE, MathAggregation.COUNT,
		MathAggregation.STANDARD_DEVIATION, MathAggregation.SUM,
		MathAggregation.MIN, MathAggregation.MAX,
		MathAggregation.VARIANCE, MathAggregation.SUM_OF_SQUARES
	];

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		private customMathHighlightService: CustomMathHighlightService,
		private readonly attributeCalculationsOptions: AttributeCalculationsOptionsService
	) {}

	/**
	 * Returns numeric aggregations without generic ones.
	 */
	getNumericAggregations(): TokenSuggestion[] {
		return this.NUMERIC_AGGREGATIONS
			.map(word => {
				let suggestion = CustomMathSuggestion.getAggregationSuggestion(word, MathMetricTokenType.NUMERIC_AGGREGATION);
				suggestion.displayName = this.attributeCalculationsOptions.findKeyword(word).displayName;
				return suggestion;
			});
	}

	/**
	 * Returns aggregations which can be used at the same time for numeric and text attributes
	 */
	getGenericAggregations(): TokenSuggestion[] {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT)
			? [this.getCountDistinctAggregation(MathMetricTokenType.GENERIC_AGGREGATION)]
			: [];
	}

	getCountDistinctAggregation(type: MathMetricTokenType): TokenSuggestion {
		return {
			...CustomMathSuggestion.getAggregationSuggestion(MathAggregation.COUNT_DISTINCT, type),
			displayName: this.attributeCalculationsOptions.findKeyword(MathAggregation.COUNT_DISTINCT).displayName
		};
	}

	/**
	 * Returns aggregation index which should be focused
	 * Calculates for UP and DOWN events, otherwise returns -1
	 */
	getNextAggregationIndex(event: KeyboardEvent, aggregationIndex: number): number {
		let nextAggregationIndex: number = -1;
		let countDistinctIsEnabled: boolean = this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT);
		let aggregationsAmount =  this.getNumericAggregations().length + (countDistinctIsEnabled ? 1 : 0);
		if (KeyboardUtils.isEventKey(event, Key.UP)) {
			nextAggregationIndex = aggregationIndex === 0 ? aggregationsAmount - 1 : aggregationIndex - 1;
		} else if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			nextAggregationIndex = aggregationIndex === aggregationsAmount - 1 ? 0 : aggregationIndex + 1;
		}
		return nextAggregationIndex;
	}

	getSuggestionClasses = (tokenType: string, showSubmenuArrow: boolean) => {
		let suggestionClasses: string[] = [];

		suggestionClasses.push(this.customMathHighlightService.getSuggestionClass(tokenType));

		if (showSubmenuArrow) {
			suggestionClasses.push('suggestion-with-submenu');
		}

		return suggestionClasses;
	}

	getIconClass = (suggestion: TokenSuggestion): QualtricsIconsId | ''=> {
		switch (suggestion.tokenType) {
			case MathMetricTokenType.NUMERIC_ATTRIBUTE:
				return SuggestionIcon.NUMERIC_ATTRIBUTE;
			case MathMetricTokenType.TEXT_ATTRIBUTE:
				return SuggestionIcon.TEXT_ATTRIBUTE;
			case MathMetricTokenType.RESERVED_WORD:
			case MathMetricTokenType.NUMERIC_AGGREGATION:
			case MathMetricTokenType.TEXT_AGGREGATION:
			case MathMetricTokenType.GENERIC_AGGREGATION:
				return SuggestionIcon.FUNCTION;
			case MathMetricTokenType.PREDEFINED_METRIC:
				return SuggestionIcon.PREDEFINED_METRIC;
			case MathMetricTokenType.METRIC:
				return SuggestionIcon.CUSTOM_METRIC;
			case MathMetricTokenType.SCORECARD_METRIC:
				return SuggestionIcon.SCORECARD_METRIC;
			case MathMetricTokenType.HIERARCHY_METRIC:
				return SuggestionIcon.HIERARCHY_METRIC;
			default:
				return '';
		}
	}

	// suggestion applying

	isSuggestionInsideExistingAttribute(allTokens: MathMetricToken[], storedTokens: MathMetricToken[], textOffset: number): boolean {
		let tokens: any = this.getSuggestionTokens(allTokens, storedTokens, textOffset);
		let hasErrors = tokens['current'].errors?.length;
		let isPreviousBracket = tokens['previous']?.text === '[';
		let isNextBracket = tokens['next']?.text === ']';
		let isPreviousStoredBracket = tokens.storedPrevious?.text === '[';
		let isNextStoredBracket = tokens.storedNext?.text === ']';
		return hasErrors && isPreviousBracket && isNextBracket && isPreviousStoredBracket && isNextStoredBracket;
	}

	getSuggestionTokens(tokens: MathMetricToken[], storedTokens: MathMetricToken[], textOffset: number): StickSuggestionTokens {
		let currentToken: MathMetricToken = this.getTokenByOffset(tokens, textOffset);
		let nextToken: MathMetricToken = this.getNextToken(tokens, currentToken);
		let previousToken: MathMetricToken = this.getPreviousToken(tokens, currentToken);
		let storedCurrentToken: MathMetricToken = this.getTokenByOffset(storedTokens, textOffset);
		let storednextToken: MathMetricToken = this.getNextToken(storedTokens, storedCurrentToken);
		let storedPreviousToken: MathMetricToken = this.getPreviousToken(storedTokens, storedCurrentToken);
		return {
			current: currentToken,
			next: nextToken,
			previous: previousToken,
			storedCurrent: storedCurrentToken,
			storedNext: storednextToken,
			storedPrevious: storedPreviousToken
		};
	}

	getTokenByOffset(allTokens: MathMetricToken[], offset: number): MathMetricToken {
		return _.findWhere(allTokens, {offset});
	}

	private getNextToken(allTokens: MathMetricToken[], token: any): any {
		if (_.contains(allTokens, token)) {
			let nextTokenIndex: number = _.indexOf(allTokens, token) + 1;
			return allTokens[nextTokenIndex];
		}
	}

	private getPreviousToken(allTokens: MathMetricToken[], token: any): any {
		if (_.contains(allTokens, token)) {
			let previousTokenIndex: number = _.indexOf(allTokens, token) - 1;
			if (previousTokenIndex >= 0) {
				return allTokens[previousTokenIndex];
			}
		}
	}

	isSuggestionStickToAggregationOrKeyword(segments: FormulaSegment[], storedSegments: FormulaSegment[], textOffset: number): boolean {
		let stickSuggestionSegments: StickSuggestionSegments = this.getStickSuggestionSegments(segments, storedSegments, textOffset);

		if (!stickSuggestionSegments.current?.errors?.length || !stickSuggestionSegments.next || !stickSuggestionSegments.stored) {
			return false;
		}

		let nextSegmentText: string = stickSuggestionSegments.next.text;
		let nearestNotEmptyStoredSegment: FormulaSegment =
			this.getNearestNotEmptySegment(storedSegments, _.indexOf(storedSegments, stickSuggestionSegments.stored));

		return nextSegmentText.startsWith('[')
			&& nextSegmentText.endsWith(']')
			&& nearestNotEmptyStoredSegment?.text?.endsWith(nextSegmentText);
	}

	getStickSuggestionSegments(segments: FormulaSegment[], storedSegments: FormulaSegment[], textOffset: number): StickSuggestionSegments {
		let currentSegment: FormulaSegment = this.getSegmentByOffset(segments, textOffset);
		let nextSegment: FormulaSegment = this.getNextSegment(segments, currentSegment);
		let storedSegment: FormulaSegment = this.getSegmentByOffset(storedSegments, textOffset);

		return {
			current: currentSegment,
			next: nextSegment,
			stored: storedSegment
		};
	}

	getSegmentByOffset(segments: FormulaSegment[], startOffset: number): FormulaSegment {
		return _.findWhere(segments, {startOffset});
	}

	private getNextSegment(segments: FormulaSegment[], segment: FormulaSegment): FormulaSegment {
		if (this.hasNextSegment(segments, segment)) {
			let nextSegmentIndex: number = _.indexOf(segments, segment) + 1;
			return segments[nextSegmentIndex];
		}
	}

	private hasNextSegment(segments: FormulaSegment[], currentSegment: FormulaSegment): boolean {
		let currentSegmentIndex: number = _.indexOf(segments, currentSegment);
		return currentSegmentIndex < segments.length - 1;
	}

	getNearestNotEmptySegment(segments: FormulaSegment[], from: number): FormulaSegment {
		let nextNotEmptySegment: FormulaSegment;

		if (from <= segments.length - 1) {
			let nearest: FormulaSegment = segments[from];
			if (_.isEmpty(nearest.text.trim())) {
				nextNotEmptySegment = this.getNearestNotEmptySegment(segments, from + 1);
			} else {
				nextNotEmptySegment = nearest;
			}
		}
		return nextNotEmptySegment;
	}

	getReplaceTextForTokens = (suggestion: TokenSuggestion, tokens: StickSuggestionTokens): ReplaceText => {
		let from = tokens.previous.offset + 1;
		let to = tokens.next.offset - 1;

		return {
			from,
			to,
			value: suggestion.displayName
		};
	}

	getReplaceText = (
		suggestion: TokenSuggestion, stickSuggestionSegments: StickSuggestionSegments,
		nearestNotEmptyStoredSegment: FormulaSegment, currentTextBlockStartOffset: number
	): ReplaceText => {
		let invalidSegmentsText: string = stickSuggestionSegments.current.text + stickSuggestionSegments.next.text;
		let offsetDiff: number = nearestNotEmptyStoredSegment.startOffset - currentTextBlockStartOffset;
		let textDiff = invalidSegmentsText.length - nearestNotEmptyStoredSegment.text.length - offsetDiff;

		let from = currentTextBlockStartOffset;
		let to = currentTextBlockStartOffset + textDiff - 1;

		if (from > to) {
			// we have a modification of or deletion from the current segment, not an addition to it.
			// we can have this situation by pressing backspace, after CB-20748
			return {
				from: currentTextBlockStartOffset,
				to: currentTextBlockStartOffset + stickSuggestionSegments.current.text.length - 1,
				value: suggestion.displayName
			};
		}

		return {
			from: currentTextBlockStartOffset,
			to: currentTextBlockStartOffset + textDiff - 1,
			value: suggestion.insertValue(false, [])
		};
	}

	getAfterStickSuggestionAppliedCaretPosition(tokenType: MathMetricTokenType, currentTextBlockStartOffset: number, text: string): number {
		if (tokenType === MathMetricTokenType.NUMERIC_ATTRIBUTE ||  tokenType === MathMetricTokenType.TEXT_ATTRIBUTE) {
			return currentTextBlockStartOffset;
		} else if (CustomMathUtils.isAggregation(tokenType)) {
			return currentTextBlockStartOffset + text.length - 1;
		} else {
			// keyword: metric[..], scorecard[..] or hierarchy[..]
			return currentTextBlockStartOffset + text.length;
		}
	}

	getAfterInsideAttributeSuggestionAppliedCaretPosition(suggestion: TokenSuggestion, tokens: StickSuggestionTokens): number {
		return tokens.current.offset + suggestion.displayName.length + tokens.next.text.length + 1;
	}
}
