export enum CommonContextMenuAction {
	NEW = 'create',
	CREATE = 'create',
	DELETE = 'delete',
	VIEW = 'view',
	EDIT = 'edit',
	RENAME = 'rename',
	MOVE_TO = 'move',
	SHARE = 'share',
	UNSHARE = 'unshare',
	ADD_FOLDER = 'add_folder',
	COPY = 'copy',
	COPY_TO = 'copy-to-submenu',
	TRANSFER_SELECTED = 'transfer',
	TOGGLE_HIDDEN = 'toggleHidden',
	SHOW_HIDE = 'show_hide',
	DISABLE = 'disable',
	ENABLE = 'enable',
	EDIT_PROPERTIES = 'edit_properties',
	EDIT_PERMISSIONS = 'edit_permissions',
	REMOVE = 'remove',
	RESET_PASSWORD = 'resetPassword',
}

export enum DashboardListMenuAction {
	CREATE_TEMPLATE = 'createTemplate',
	VIEW_BOOK_COMPONENTS = 'book_components',
	CREATE_DASHBOARD_IN_FOLDER = 'create_dashboard_in_folder',
	HIDE_DASHBOARD = 'hideDashboard',
	SHOW_DASHBOARD = 'showDashboard',
	RATE = 'rate',
}

export enum DashboardMenuAction {
	COPY_TEXT = '_t-copy-text',
	FILTER = 'filter',
	SETTINGS = 'settings',
	EXPORT = 'export',
	SCHEDULE = 'schedule',
	EXPORT_PDF = 'export_pdf',
	EXPORT_ALL = 'export_all',
	EXPORT_PDF_MENU = 'export_pdf_menu',
	REFRESH = 'refresh',
	CONVERT = 'convert',
}


export enum WidgetMenuAction {
	WORDS_SELECTION = 'words-selection',
	FEEDBACK_SELECTION = 'feedback-selection',
	OPEN_IN_EXPLORER = 'explorer',
	SHOW_TOTALS = 'showTotals',
	HIDE_TOTALS = 'hideTotals',
}

export enum OtherMenuAction {
	EDIT_DEFAULTS = 'defaults', 		// attributes
	RESTORE = 'restore',				// archive
	MAKE_DEFAULT = 'make-default',		// color palettes
}


export const ContextMenuAction = {
	...CommonContextMenuAction,
	...DashboardListMenuAction,
	...DashboardMenuAction,
	...WidgetMenuAction,
	...OtherMenuAction,
};
