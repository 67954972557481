import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocationService, DOC_EXPLORER_PAGE_PATH } from '@app/core/cx-location.service';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DocumentExplorerFilters } from '../document-explorer-metric-filters/document-explorer-filters';
import { DocumentExplorerPageParams } from './document-explorer-page-params';
import { CxLocaleService } from '@app/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { ObjectUtils } from '@app/util/object-utils';

export const FULL_PAGE_CONTAINER_ID = 'fullPageDocExplorer';

@Injectable({
	providedIn: 'root'
})
export class FullPageDocumentExplorerService {
	private returnPath: string;
	private returnLabel: string;
	private widget: DocumentExplorerWidget;
	private isFullPageVisible: boolean;
	private documentFilters: DocumentExplorerFilters;
	private dashboardFilters: IDashboardHistoryInstance;
	private currentPage: number;

	constructor(
		private readonly cxLocation: CxLocationService,
		private readonly locale: CxLocaleService,
		private readonly modalService: NgbModal,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
	){}

	getCurrentWidget(): DocumentExplorerWidget {
		return this.widget;
	}

	getDocumentFilters(): DocumentExplorerFilters {
		return this.documentFilters;
	}

	getDashboardFilters(): IDashboardHistoryInstance {
		return this.dashboardFilters;
	}

	getReturnLabel(): string {
		return this.returnLabel;
	}

	getReturnPath(): string {
		return this.returnPath;
	}

	setFullPage(isFullPage: boolean): void {
		this.isFullPageVisible = isFullPage;
	}

	isFullPage(): boolean {
		return this.isFullPageVisible;
	}

	setCurrentPage(page: number): void {
		this.currentPage = page;
	}

	getCurrentPage(): number {
		return this.currentPage;
	}

	openFullPageDocExplorer(
		document: DocumentExplorerWidget,
		filters: DocumentExplorerFilters = {}
	): void {
		const dashboardHistory = this.currentWidgets.getDashboardHistory(document.containerId);

		let label = this.locale.getString('common.return');
		if (this.cxLocation.isDashboardOrBook()) {
			label = dashboardHistory.getDashboard().name;
		}

		this.setCurrentWidget(this.prepWidgetForFullPageExplorer(document));
		this.setReturnLabel(label);
		this.setReturnPath(this.cxLocation.url());
		this.setFilters(dashboardHistory, filters);
		this.modalService.dismissAll();
		this.cxLocation.url(DOC_EXPLORER_PAGE_PATH);
	}

	openFullPageDocExplorerByParams(params: DocumentExplorerPageParams) {
		this.cxLocation.url(DOC_EXPLORER_PAGE_PATH).search(params.toSearchParams());
	}

	supportDocLinkPage(): boolean {
		return this.cxLocation.isDocExplorerPage() || this.cxLocation.isInteractionExplorerPage();
	}

	goToDocExplorer(attributes): void {
		this.cxLocation.path('/document-explorer').search(attributes);
	}

	clear(): void {
		this.setFullPage(false);
		this.setCurrentWidget(undefined);
		this.setReturnLabel(undefined);
		this.setReturnPath(undefined);
		this.setCurrentPage(1);
		this.setFilters(undefined, {});
	}

	private setCurrentWidget(widget: DocumentExplorerWidget): void {
		this.widget = widget;
	}

	private setReturnLabel(label: string): void {
		this.returnLabel = label;
	}

	private setReturnPath(path: string): void {
		this.returnPath = path;
	}


	private setFilters(dashboardFilters: IDashboardHistoryInstance, documentFilters: DocumentExplorerFilters): void {
		this.dashboardFilters = dashboardFilters;
		this.documentFilters = documentFilters;
	}

	private prepWidgetForFullPageExplorer(widget: DocumentExplorerWidget): DocumentExplorerWidget {
		// set parent widget to preview so sorting direction will be preserved
		let copy = ObjectUtils.copy(widget);
		copy.parentWidget.name = DrillType.PREVIEW;
		copy.containerId = FULL_PAGE_CONTAINER_ID;
		return copy;
	}

}

app.service('fullPageDocExplorerService', downgradeInjectable(FullPageDocumentExplorerService));
