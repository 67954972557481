import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import ILocale from '@cxstudio/interfaces/locale-interface';

export class ObjectViewerWidgetPreviewComponent implements ng.IComponentController {

	sampleData;
	backgroundOptions = WidgetBackgroundColor;
	visualProperties: VisualProperties;

	constructor(
		private locale: ILocale
	) {}

	$onInit = () => {
		// required for typescript validation
	}

	showTotalCount = (): boolean => {
		return  this.visualProperties.showSampleSize;
	}

	getSummary = (): string => {
		let totalKey = this.visualProperties.normalized ? 'totalN' : 'total';
		let targetKey = this.visualProperties.normalized ? 'targetN' : 'target';
		let	total = '<b>' + this.locale.getString('scorecards.' + totalKey + 'Label') + ':</b> '
				+ this.sampleData[totalKey];
		let target = '<b>' + this.locale.getString('scorecards.' + targetKey + 'Label') + ':</b> '
				+ this.sampleData[targetKey];
		return total + ' ' + target;
	}
}

app.component('objectViewerWidgetPreview', {
	bindings: {
		visualProperties: '<',
		utils: '<',
		sampleData: '<',
		redrawTrigger: '<'
	},
	controller: ObjectViewerWidgetPreviewComponent,
	template: `
<div class="d-flex br-widget-content bg-dashboard w-100-percent"
	ng-class="{'bg-none': $ctrl.visualProperties.backgroundColor === $ctrl.backgroundOptions.NONE}">
	<div cb-definition-table
		demo="true"
		class="h-100-percent w-100-percent chart-demo br-widget-view p-8"
		options="$ctrl.visualProperties"
		trigger="$ctrl.redrawTrigger"
		data="$ctrl.sampleData"
		utils="$ctrl.utils"
	></div>
	<div class="br-widget-footer br-widget-summary">
		<widget-total-count class="br-widget-summary-item h-fit-content"
			ng-if="$ctrl.showTotalCount()">{{$ctrl.sampleData.totalCount | commaFormat}}
		</widget-total-count>
		<div ng-if="$ctrl.showTotalCount()" class="br-widget-summary-divider"> | </div>
		<div class="br-widget-summary-item" ng-bind-html="$ctrl.getSummary()"></div>
	</div>
</div>`
});
