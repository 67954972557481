<div [ngBusy]="loadingCPs">
	<div class="form-inline form-group">
		<div class="input-group">
			<clearable-input class="br-cp-filter"
				[(ngModel)]="searchText"
				(ngModelChange)="onSearchChange()"
				placeholder="{{'common.search'|i18n}}">
			</clearable-input>
		</div>
	</div>

	<ngb-accordion class="br-cp-accounts p-relative"
		[closeOthers]="false"
		type="default"
		(panelChange)="loadAccounts($event.panelId)">
		<ngb-panel *ngFor="let cp of contentProviders
			| orderBy:'name'
			| filterBy:['name']:searchText
			| slice:pagination.getPageStart():pagination.getPageEnd()"
			[id]="getPanelId(cp.id)">
			<ng-template ngbPanelHeader>
				<button ngbPanelToggle class="btn btn-link no-hover cp-toggle">
					<div class="w-100-percent d-flex justify-between overflow-hidden">
						<span title="{{cp.name }} ({{cp.endPoint}})" class="text-ellipsis cp-description">
							{{cp.name }} ({{cp.endPoint}})
						</span>
						<i class="q-icon-check" *ngIf="accountMap[cp.id]?.length"></i>
					</div>
				</button>
			</ng-template>
			<ng-template ngbPanelContent>
				<table class="table" [ngBusy]="loadingAccount[cp.id]">
					<thead>
						<tr>
							<th>#</th>
							<th [i18n]="'administration.accountName'"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let account of accounts[cp.id]" class="cp-account">
							<th scope="row">
								<input type="checkbox"
									id="acct_{{cp.id}}_{{account.accountId}}"
									class="account-checkbox"
									value="{{account.accountId}}"
									name="{{account.accountName}}"
									[checked]="getCheckedStatus(cp.id, account.accountId)"
									(click)="updateCheckedStatus(cp.id, account.accountId, $event)"/>
							</th>
							<td class="br-account-name">
								<label for="acct_{{cp.id}}_{{account.accountId}}" class="mb-0">
									{{account.accountName }}
								</label>
							</td>
						</tr>
					</tbody>
				</table>
			</ng-template>
		</ngb-panel>
	</ngb-accordion>

	<pagination *ngIf="pagination"
		[(pagination)]="pagination"
	></pagination>
</div>
