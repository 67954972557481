import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DrillProcessor } from '@cxstudio/reports/utils/contextMenu/drill/drill-to/drill-processor.class';

export abstract class DefaultDrillProcessor extends DrillProcessor {
	private additionalProperties = [];
	private additionalVisProperties = [];

	private readonly DEFAULT_INHERITED_PROPERTIES = CommonInherentProperties.altTextProperties.concat(CommonInherentProperties.common);
	private readonly DEFAULT_INHERITED_VIS_PROPERTIES = []; // none currently, maybe in the future?

	addInheritedVisualProperties(additionalVisProperties: string | string[]): void {
		this.additionalVisProperties = this.additionalVisProperties.concat(additionalVisProperties);
	}

	getInheritedVisualProperties(): string[] {
		return this.additionalVisProperties.concat(this.DEFAULT_INHERITED_VIS_PROPERTIES);
	}

	addInheritedProperties(additionalProperties: string | string[]): void {
		this.additionalProperties = this.additionalProperties.concat(additionalProperties);
	}

	getInheritedProperties(): string[] {
		return this.additionalProperties.concat(this.DEFAULT_INHERITED_PROPERTIES);
	}
}
