export class CustomGroupMetadata {
	name: string; // group value in resulting dataset
	// displayName is usually the same as name, 
	// but for PoP it contains real historic period name, whereas name contains current period
	displayName: string; 
	sortValue: any;
	rawQuery: string;

	projectDateFrom?: string;
	projectDateTo?: string;
}