import { Context } from '@cxstudio/auth/context';
import { Security } from '@cxstudio/auth/security-service';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { DiscussionMentionsUtils } from '@app/shared/components/discussions/discussion-mentions-utils.class';
import * as moment from 'moment';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { UniqueService } from '@cxstudio/services/unique.service';

export class DiscussionWidgetComponent {
	readonly COMMENT_PULL_INTERVAL: number = 20000;

	sharedUsersRequest = null;
	newComment: string = '';
	mentionSelector: string = '';
	prefix: string = '';
	suffix: string = '';
	mentionSearch: string = '';
	all = null;

	comments = [];
	commentListExhausted = false;
	latestCommentTimestamp = new Date().toISOString();
	oldestCommentTimestamp = new Date().toISOString();
	loadingComments = null;
	commentCount: number;
	oldestCommentId: number;

	formats = {
		bold: false,
		underline: false,
		italic: false,
		strikethrough: false
	};

	type: string;
	loading: Partial<{
		comments: ng.IPromise<any>,
		users: ng.IPromise<any>,
	}>;
	commentsInterval: ng.IPromise<any>;
	user: Context;
	sharingPerformed = false;
	objectId;
	allSharedEntities;
	isMobile: boolean;

	constructor(
		private $scope: ng.IScope,
		private $log: ng.ILogService,
		private $interval: ng.IIntervalService,
		private discussionService,
		private $timeout: ng.ITimeoutService,
		private $q: ng.IQService,
		private elementUtils: ElementUtils,
		private $filter: ng.IFilterService,
		private security: Security,
		private dashboardService: DashboardService,
		private $rootScope: ng.IRootScopeService,
		private dashboardApiService: DashboardApiService,
		private dashboardListService: DashboardListService,
	) { }


	$onInit(): void {
		this.reinitializeDiscussion();

		this.$scope.$watch('trigger', (newValue, oldValue) => {
			if (oldValue === newValue) return;
			if (this.commentsInterval) {
				this.$interval.cancel(this.commentsInterval);
			}
			this.reinitializeDiscussion();
		});

		this.$scope.$on('sharingPerformed', () => {
			this.sharingPerformed = true;
			this.sharedUsersRequest = null;
			this.all = null;
			this.getSharedEntities();
		});
	}

	$onDestroy(): void {
		if (this.commentsInterval) {
			this.$interval.cancel(this.commentsInterval);
		}
	}

	private reinitializeDiscussion = (): void => {
		this.user = this.security.loggedUser;
		this.isMobile = this.$rootScope.isMobile;

		this.setLoadingComments(false);
		this.getPreviousComments();
		this.getSharedEntities();
		this.commentsInterval = this.$interval(this.getNewComments, this.COMMENT_PULL_INTERVAL);
	}

	setLoadingComments = (value) => {
		if (!this.loading)
			return;
		this.loading.comments = value;
	}

	setLoadingUsers = (value) => {
		if (!this.loading)
			return;
		this.loading.users = value;
	}

	getPreviousComments = () => {
		this.setLoadingComments(true);
		this.loadingComments = this.discussionService.loadPreviousComments(
			this.type, this.objectId, this.oldestCommentTimestamp);
		this.loadingComments.then(this.addCommentsToHead, this.commentPullFail);
	}

	addCommentsToHead = (result): void => {
		let newComments = result.data;
		this.commentCount = result.totalCount;
		if (newComments && newComments.length === 0) {
			this.commentListExhausted = true;
			this.refreshComplete();
			return;
		}

		this.comments = newComments.concat(this.comments);
		if (this.oldestCommentId) {
			this.elementUtils.scrollTo('commentId_' + this.oldestCommentId);
		}
		this.updateFilters();
		this.refreshComplete();
	}

	getNewComments = () => {
		this.setLoadingComments(true);
		this.loadingComments = UniqueService.promise('discussionDirective::getNewComments', () => {
			return this.discussionService.loadNewComments(
				this.type, this.objectId, this.latestCommentTimestamp);
		}, this.addCommentsToTail, this.commentPullFail);
	}

	addCommentsToTail = (result): void => {
		let newComments = result.data;
		this.commentCount = result.totalCount;

		if (newComments && newComments.length > 0) {
			this.comments = this.comments.concat(newComments);
			this.updateFilters();
		}

		this.refreshComplete();
	}

	refreshComplete = (): void => {
		this.loadingComments = null;
		// force refresh icon to display a minimum .5s
		this.$timeout(() => this.setLoadingComments(false), 500);
	}

	updateFilters = (): void => {
		this.oldestCommentId = this.comments[0].id;
		this.oldestCommentTimestamp = this.comments[0].createTime;
		this.latestCommentTimestamp = this.comments.last().createTime;
	}

	commentPullFail = (err): void => {
		this.$log.debug('Error pulling discussion data', err);
		this.loadingComments = null;
		this.refreshComplete();
	}

	addComment = () => {
		// no blank comments
		let commentText = this.getCurrentComment();
		let hasText = $(`<div>${`${commentText}`}</div>`).text().trim().length > 0; // remove html tags, such as <br>
		let hasMention = $('<div>' + commentText + '</div>').find('input').length > 0;
		if (!commentText || !hasText && !hasMention)
			return;

		let comment = {
			likes: [],
			ownerId: this.user.user.userId,
			owner: {
				firstName: this.user.user.firstName,
				lastName: this.user.user.lastName,
				email: this.user.user.userEmail
			},
			createTime: moment().format(),
			lastModifiedTime: moment().format(),
			content: DiscussionMentionsUtils.replaceMentionTags(commentText),
			mentions: DiscussionMentionsUtils.extractMentions(commentText),
			status: 1,
			isLiked: false
		};
		// clear comment early
		this.newComment = '';
		this.mentionSelector = '';
		this.prefix = '';
		this.suffix = '';
		this.discussionService.saveNewComment(this.type, this.objectId, comment).then(this.applyNewComment, (err) => { });
	}


	applyNewComment = (): void => {
		this.getNewComments();
	}

	getCurrentComment = (): string => {
		return this.newComment || this.prefix; // when we apply suggestion, newComment becomes undefined.
	}

	getMentions = (val) => {
		if (this.type !== 'dashboard') return; // mentioning works only for dashboard now

		this.setLoadingUsers(true);

		let validationDefer = this.$q.defer();

		let previous = this.newComment && this.newComment.replace(/&nbsp;/g, ' ');
		let newValue = val && val.replace(/&nbsp;/g, ' ');
		let end = this.stringDiffPositionEnd(previous, newValue);
		let lastAddedMentionEnd = newValue.substring(0, end + 1).lastIndexOf('">');
		let lastNewMentionSymbol = newValue.substring(0, end + 1).lastIndexOf('@');
		let pos = lastNewMentionSymbol < lastAddedMentionEnd ? -1 : lastNewMentionSymbol;

		let textOnlyPreviousValue = $(`<div>${previous}</div>`).text();
		let textOnlyNewValue = $(`<div>${newValue}</div>`).text();
		let textOnlyEnd = this.stringDiffPositionEnd(textOnlyPreviousValue, textOnlyNewValue);
		let textOnlyPos = textOnlyNewValue.substring(0, textOnlyEnd + 1).lastIndexOf('@');

		// split into 2 parts to join later
		if (newValue.charAt(end) === '@'
			|| textOnlyNewValue.charAt(textOnlyEnd) === '@') {
			this.prefix = newValue.substr(0, pos);
			this.suffix = newValue.substr(pos + 1, newValue.length);
		}
		if (pos < 0) {
			validationDefer.resolve(false);
		} else if ((newValue[pos - 1] === undefined || newValue[pos - 1] === ' ')
			|| (textOnlyNewValue[textOnlyPos - 1] === undefined || textOnlyNewValue[textOnlyPos - 1] === ' ')) {
			//search only what we entered between @ and previous text, in case we put @ into the middle
			this.mentionSearch = newValue.substring(pos + 1, newValue.length - this.suffix.length);
		} else {
			validationDefer.resolve(false);
		}

		if (this.mentionSearch && !this.mentionSearch.trim()) {
			validationDefer.resolve(false);
		}

		let sharedEntitiesDefer = this.$q.defer();
		//filter the results
		let sharedEntitiesPromise = this.getSharedEntities();
		sharedEntitiesPromise.then((sharedEntities: any[]) => {
			validationDefer.resolve(true);

			if (!this.mentionSearch && !sharedEntities.length) {
				sharedEntitiesDefer.resolve(['']);
			} else {
				let limit = 10;
				let result = [];

				for (let entity of sharedEntities) {
					if (result.length > limit) {
						break;
					} else if (this.$filter('filter')([entity], { _search: this.mentionSearch }).length > 0) {
						result.push(entity);
					}
				}

				if (result.length === 0) {
					result = [''];
				}
				sharedEntitiesDefer.resolve(result);
			}
		});

		return validationDefer.promise.then((validationDeferResult) => {
			return sharedEntitiesDefer.promise.then((sharedEntitiesResult) => {
				this.setLoadingUsers(false);
				return validationDeferResult ? sharedEntitiesResult : [];
			});
		});
	}

	// return the position of last different char
	stringDiffPositionEnd = (str1: string, str2: string): number => {
		if (!str1)
			return str2.length - 1;
		if (!str2)
			return 0;

		if (str1 === str2)
			return str1.length - 1;
		let str1Length = str1.length;
		let str2Length = str2.length;
		let min = (str1Length < str2Length) ? str1Length : str2Length;
		for (let i = 1; i <= min; i++)
			if (str1.charAt(str1Length - i) !== str2.charAt(str2Length - i))
				return str2Length - i;
		return str2Length - min; // because str2 is longer
	}

	formatResult = (): string => {
		this.newComment = this.prefix;
		return this.prefix;
	}

	onMentionApply = (item): void => {
		if (item === '') {
			return;
		}
		this.prefix = this.prefix + DiscussionMentionsUtils.generateElement(item);
		if (!this.suffix || !this.suffix.match(/^[ !,\.\?-]/)) {
			this.prefix += '&nbsp;'; // add space, if on the end of line of in the middle of word
		}
		this.mentionSelector = `input[data-id=${item._id}][data-type=${item.type}]`;
		this.prefix += this.suffix ? this.suffix : '';
		this.suffix = '';
	}

	onMatchClick = ($event, match): void => {
		if (this.type !== 'dashboard') return;
		let target = $($event.target);
		if (match.model === '' && target.is('a:not(.mention-link)')) {
			let dashboard = _.findWhere(this.dashboardListService.getCurrentDashboardsList(), { id: this.objectId });
			this.dashboardService.shareDashboard(dashboard);
		}
	}

	hideDropdown = (matches: any): boolean => {
		let matchModels = _.chain(matches).pluck('model').filter(model => model).value();
		return this.all.length > 0 && matchModels.length === 0;
	}

	getSharedEntities = () => {
		if (this.type !== 'dashboard') return;
		let defer = this.$q.defer();
		if (!this.all || this.sharingPerformed) {
			this.sharingPerformed = false;
			this.sharedUsersRequest = this.sharedUsersRequest || this.dashboardApiService.getDashboardUsers(this.objectId);
			this.sharedUsersRequest.then((data) => {
				let allSharedEntities = {};
				allSharedEntities['user' + this.user.user.userEmail] = true; // ignore current user
				let users = DiscussionMentionsUtils.processUsers(data
					&& data.shareEntities['USER'], allSharedEntities) || []; // empty array if anything is undefined
				let groupsAndMembers = DiscussionMentionsUtils.processGroups(data
					&& data.shareEntities['GROUP'], allSharedEntities) || []; // empty array if anything is undefined
				this.all = users.concat(groupsAndMembers);
				defer.resolve(this.all);
			}, (err) => defer.reject);
		} else {
			defer.resolve(this.all);
		}
		return defer.promise;
	}

	applyFormatting = (value): void => {
		document.execCommand(value);
		this.formats[value] = !this.formats[value];
		setTimeout(() => document.getElementById('discussion-area').focus(), 10);
	}

	getCaretCharacterOffsetWithin = (element): number => {
		let caretOffset = 0;
		if (typeof window.getSelection !== 'undefined') {
			let range = window.getSelection().getRangeAt(0);
			let preCaretRange = range.cloneRange();
			preCaretRange.selectNodeContents(element);
			preCaretRange.setEnd(range.endContainer, range.endOffset);
			caretOffset = preCaretRange.toString().length;
		} else if (typeof (document as any).selection !== 'undefined' && (document as any).selection.type !== 'Control') {
			let textRange = (document as any).selection.createRange();
			let preCaretTextRange = (document.body as any).createTextRange();
			preCaretTextRange.moveToElementText(element);
			preCaretTextRange.setEndPoint('EndToEnd', textRange);
			caretOffset = preCaretTextRange.text.length;
		}
		return caretOffset;
	}

	determineStylesFromParents = (allParents): string[] => {
		let styles = [];
		for (let parent of allParents) {
			if (parent.style.fontStyle === 'italic') {
				styles.push('i');
			}
			if (parent.style.fontWeight === 'bold') {
				styles.push('b');
			}
			if (parent.style.textDecoration === 'line-through') {
				styles.push('strike');
			}
			if (parent.style.textDecoration === 'underline') {
				styles.push('u');
			}
		}
		return styles;
	}

	determineMarkups = (): string[] => {
		let markups = [];
		let current = document.getSelection().getRangeAt(0).endContainer;
		let allParents = [];
		let boldParents = $(current).parents('b, strong');
		let italicsParents = $(current).parents('i, em');
		let underlineParents = $(current).parents('u');
		let strikeParents = $(current).parents('strike');
		allParents.push.apply(allParents, boldParents);
		allParents.push.apply(allParents, italicsParents);
		allParents.push.apply(allParents, underlineParents);
		allParents.push.apply(allParents, strikeParents);
		let styles = this.determineStylesFromParents(allParents);
		markups.push.apply(markups, styles);
		if (boldParents.length > 0) {
			markups.push('b');
		}
		if (italicsParents.length > 0) {
			markups.push('i');
		}
		if (underlineParents.length > 0) {
			markups.push('u');
		}
		if (strikeParents.length > 0) {
			markups.push('strike');
		}
		return markups;
	}

	setButtons = (markupsApplied: string[]): void => {
		this.formats = { bold: false, underline: false, italic: false, strikethrough: false };

		for (let markup of markupsApplied) {
			if (markup === 'b') {
				this.formats['bold'] = true;
			}
			if (markup === 'u') {
				this.formats['underline'] = true;
			}
			if (markup === 'i') {
				this.formats['italic'] = true;
			}
			if (markup === 'strike') {
				this.formats['strikethrough'] = true;
			}
		}
	}

	calculateMarkupsApplied = (): void => {
		let el = document.getElementById('discussion-area');
		let markupsApplied = this.determineMarkups();
		this.setButtons(markupsApplied);
	}

	updateCommentCount = (count: number): void => {
		this.commentCount = count;
	}
}

app.component('discussionWidget', {
	bindings: {
		trigger: '=',
		objectId: '=',
		commentCount: '=',
		loading: '=?',
		type: '<'
	},
	controller: DiscussionWidgetComponent,
	templateUrl: 'partials/discussions/discussion-body.html',
});
