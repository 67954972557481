import PlotLineBuilder from '@app/modules/plot-lines/plot-lines/plot-line-builder';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';

export default class PlotLineStyle {
	type: PlotLineType;
	width: number;
	lineStyle: string;
	color: string;

	constructor(type: PlotLineType, width: number, color: string) {
		this.type = type;
		this.width = type === PlotLineType.NoLine ? 0 : width;
		this.lineStyle = PlotLineBuilder.getLineStyle(type, width);
		this.color = color;
	}
}
