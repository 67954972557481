<div class="sentence-container kb-focusable-inset" [attr.tabindex]="focusable ? 0 : null">
	<span class="show-participant action-hover-text">{{'common.show'|i18n}} {{getParticipantLabel()}}</span>
	<div *ngIf="!transcript.isSameChannelAsLast">
		<b>{{getParticipantLabel()}}</b>
	</div>
	<span *ngIf="!transcript.isSameChannelAsLast"
		class="sentence-icon speaker-icon"
		[ngClass]="getIconClass()"
		[style.color]="getIconColor()"></span>
	<span tabindex="0" *ngIf="!transcript.isSameChannelAsLast"
		(click)="onPlay.emit()"
		(keydown.enter)="onPlay.emit()"
		class="sentence-icon cluster-play-control q-icon q-icon-play text-blue-700 cursor-pointer"></span>
	<span tabindex="0" *ngIf="!transcript.isSameChannelAsLast"
		(click)="onPause.emit()"
		(keydown.enter)="onPause.emit()"
		class="sentence-icon cluster-play-control q-icon q-icon-pause text-blue-700 cursor-pointer"></span>
	<span *ngIf="showTimestamp()" class="timestamp text-gray-900">{{transcript.timestamp | secondsToTime}}</span>
	<p class="sentence-with-icons sentence-text-container">
		<voice-sentence
			id="voice-sentence-{{transcript.id}}"
			class="cursor-pointer"
			(click)="onSelect.emit($event)"
			[sentimentColorFunc]="formatters.sentimentColor"
			[sentence]="transcript">
		</voice-sentence>
		<span class="q-icon q-icon-volume sentence-play-control text-blue-700"></span>
		<span class="q-icon q-icon-play sentence-play-control text-blue-700 cursor-pointer"
			data-testid="sentence-play-button"
			(click)="onPlay.emit()"></span>
	</p>

	<sentence-pills *ngIf="!compact"
		class="sentence-topics lh-1"
		[topics]="filteredSentenceTopics"
		[auditMode]="auditMode"
		[enabledModels]="auditModeModels"
		[width]="messageWidth"
		[highlightTrigger]="highlightTrigger"
		[showAll]="expandTopics"
		[hasProfanity]="hasProfanity"
		[focusable]="focusable"
		[sentences]="[transcript]"
		[predefinedMetrics]="predefinedMetrics"
		[showTopics]="showTopics"
		[showEnrichments]="showEnrichments"
		(removeTopic)="removeTopic.emit($event)"
		(tuneEnrichment)="tuneEnrichment.emit({pill: $event.pill, event: $event.event, sentence: transcript})">
	</sentence-pills>

	<translated-text *ngIf="translate"
		class="d-flex mt-8"
		[cacheKey]="uniqueId"
		[text]="transcript.text">
	</translated-text>
</div>
