import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CxLocaleService, SupportedLocale } from '@app/core';
import { ConfigService } from '@app/core/config.service';
import { Security } from '@cxstudio/auth/security-service';

interface UserPostData {
	AuthKey: string;
	Email: string;
	Username: string;
	UserId: number;
	Name: string;
	Q_Language: string;
	'account.name': string;
	USOnly: 1 | 0;
}

interface AnonymousPostData {
	AuthKey: string;
	Q_Language: string;
}

type SupportPostData = UserPostData | AnonymousPostData;


@Component({
	selector: 'support-post-link',
	template: `
<form ngNoForm #supportPost name="supportPost"
	[action]="action"
	method="POST"
	target="_blank">
	<ng-container *ngFor="let item of data | keyvalue">
		<input type="hidden" [name]="item.key" [value]="item.value" />
	</ng-container>
</form>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportPostLinkComponent implements OnInit {

	private readonly US_ONLY_TAG = 'US Only';

	data: Record<keyof SupportPostData, any>; // keyvalue pipe doesn't work well with plain objects
	action: string;
	@ViewChild('supportPost') supportPost: ElementRef;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly configService: ConfigService,
		@Inject('security') private readonly security: Security,
	) {}

	ngOnInit() {
		this.data = this.security.isLoggedIn() ? this.getUserData() : this.getAnonymousData();
		this.action = this.configService.getConfig().support.link;
	}

	private getUserData(): SupportPostData {
		return {
			AuthKey: this.configService.getConfig().support.key,
			Email: this.security.getEmail(),
			Username: this.security.getEmail(),
			UserId: this.security.getUser().userId,
			Name: `${this.security.getUser().firstName} ${this.security.getUser().lastName}`,
			Q_Language: this.getSurveyLanguage(this.locale.getLocale() as SupportedLocale),
			'account.name': this.security.getCurrentMasterAccount().accountName,
			USOnly: _.contains(this.security.getCurrentMasterAccount().tags, this.US_ONLY_TAG) ? 1 : 0,
		};
	}

	private getAnonymousData(): AnonymousPostData {
		return {
			AuthKey: this.configService.getConfig().support.key,
			Q_Language: this.getSurveyLanguage(this.locale.getLocale() as SupportedLocale),
		};
	}

	// https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/translate-survey/#AvailableLanguageCodes
	private getSurveyLanguage(lang: SupportedLocale): string {
		switch (lang) {
			case SupportedLocale.en_US: return 'EN';
			case SupportedLocale.en_UK: return 'EN-GB';
			case SupportedLocale.es_EM: return 'ES-ES';
			case SupportedLocale.fr_FR: return 'FR';
		}
	}

	postRedirect(): void {
		this.supportPost.nativeElement.submit();
	}

}
