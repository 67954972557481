export type MobileWidgetConfigurationType = 'TITLE' | 'CALCULATIONS' | 'GROUPINGS' | 'FEEDBACK_LIST';

export interface MobileWidgetConfiguration {
	id: number;
	linkedWidgets?: number[];
	title: string;
	posX: number;
	posY: number;
	width: number;
	height: number;
}

export interface MobileScreenConfiguration {
	screenIndex: number;
	widgetConfigurations: Record<MobileWidgetConfigurationType, MobileWidgetConfiguration>;
}

export class MobileApplicationDashboardUtils {
	static FirstScreen = {
		screenIndex: 0,
		widgetConfigurations: {
			TITLE: {
				id: 1000,
				title: 'mobile.widgetFirstScreenTitle',
				posX: 0,
				posY: 0,
				width: 5,
				height: 3
			},

			CALCULATIONS: {
				id: 1001,
				title: 'mobile.widgetSummaryCalculations',
				posX: 0,
				posY: 3,
				width: 5,
				height: 7
			},

			GROUPINGS: {
				id: 1002,
				linkedWidgets: [2001, 2002, 3001, 4001],
				title: 'mobile.widgetGroupings',
				posX: 0,
				posY: 10,
				width: 5,
				height: 11
			}
		}
	} as MobileScreenConfiguration;

	static SecondScreen = {
		screenIndex: 1,
		widgetConfigurations: {
			TITLE: {
				id: 2000,
				title: 'mobile.widgetSecondScreenTitle',
				posX: 5,
				posY: 0,
				width: 5,
				height: 3
			},

			CALCULATIONS: {
				id: 2001,
				title: 'mobile.widgetSummaryCalculations',
				posX: 5,
				posY: 3,
				width: 5,
				height: 7
			},

			GROUPINGS: {
				id: 2002,
				linkedWidgets: [3001, 4001],
				title: 'mobile.widgetGroupings',
				posX: 5,
				posY: 10,
				width: 5,
				height: 11
			}
		}
	} as MobileScreenConfiguration;

	static ThirdScreen = {
		screenIndex: 2,
		widgetConfigurations: {
			TITLE: {
				id: 3000,
				title: 'mobile.widgetThirdScreenTitle',
				posX: 10,
				posY: 0,
				width: 5,
				height: 3
			},

			GROUPINGS: {
				id: 3001,
				linkedWidgets: [4001],
				title: 'mobile.widgetGroupings',
				posX: 10,
				posY: 3,
				width: 5,
				height: 18
			}
		}
	} as MobileScreenConfiguration;

	static FeedbackScreen = {
		screenIndex: 3,
		widgetConfigurations: {
			TITLE: {
				id: 4000,
				title: 'mobile.widgetFeedbackScreenTitle',
				posX: 15,
				posY: 0,
				width: 9,
				height: 3
			},

			FEEDBACK_LIST: {
				id: 4001,
				title: 'mobile.widgetFeedbackList',
				posX: 15,
				posY: 3,
				width: 9,
				height: 18
			}
		}
	} as MobileScreenConfiguration;

	static FeedbackScreenWide = {
		screenIndex: 3,
		widgetConfigurations: {
			TITLE: {
				id: 4000,
				title: 'mobile.widgetFeedbackScreenTitle',
				posX: 10,
				posY: 0,
				width: 14,
				height: 3
			},

			FEEDBACK_LIST: {
				id: 4001,
				title: 'mobile.widgetFeedbackList',
				posX: 15,
				posY: 3,
				width: 14,
				height: 18
			}
		}
	} as MobileScreenConfiguration;
}