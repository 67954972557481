import { Injectable, Inject, EventEmitter } from '@angular/core';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { SamlServiceProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-service-providers/saml-service-providers.component';
import { SamlServiceProviderApiService } from '@app/modules/system-administration/saml-settings/saml-service-provider-api.service';

@Injectable()
export class SamlServiceProvidersListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private locale: CxLocaleService,
		private serviceProviderApi: SamlServiceProviderApiService,
		@Inject('exportUtils') private exportUtils,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService
	) {}

	edit(serviceProvider: SamlServiceProvider, controller: SamlServiceProvidersComponent): void {
		controller.editServiceProvider(serviceProvider);
	}

	create = (serviceProvider: SamlServiceProvider): void => {
		this.serviceProviderApi.createServiceProvider(serviceProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('administration.samlSPAdded', {itemName: serviceProvider.entityId}));
			this.onChange.emit();
		});
	}

	update = (serviceProvider: SamlServiceProvider): void => {
		this.serviceProviderApi.updateServiceProvider(serviceProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('administration.samlSPUpdated', {itemName: serviceProvider.entityId}));
			this.onChange.emit();
		});
	}

	delete(serviceProvider: SamlServiceProvider): void {
		this.cbDialogService.confirm(this.locale.getString('common.delete'),
			this.locale.getString('administration.samlConfirmSPDelete', {itemName: serviceProvider.entityId})).result
			.then(() => {
				this.serviceProviderApi.deleteServiceProvider(serviceProvider.entityId).then(() => {
					this.globalNotificationService.addSuccessNotification(
						this.locale.getString('administration.samlSPDeleted', {itemName: serviceProvider.entityId}));
					this.onChange.emit();
				});
			});
	}

	toggle(serviceProvider: SamlServiceProvider): void {
		serviceProvider.enabled = !serviceProvider.enabled;

		let notificationMessage = serviceProvider.enabled
			? this.locale.getString('administration.samlSPEnabled', {itemName: serviceProvider.entityId})
			: this.locale.getString('administration.samlSPDisabled', {itemName: serviceProvider.entityId});

		this.serviceProviderApi.updateServiceProvider(serviceProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(notificationMessage);
			this.onChange.emit();
		});
	}

	downloadKeyPairFile(serviceProvider: SamlServiceProvider): void {
		this.serviceProviderApi.downloadKeyPairFile(serviceProvider.entityId).then(response => {
			this.exportUtils.downloadResponseFile(response);
		});
	}
}
