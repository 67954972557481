import * as _ from 'underscore';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { SentencePreviewElementBuildersService } from '../reports/utils/sentence-preview-element-builders.service';

@Component({
	selector: 'voice-sentence',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<span class="{{applyTopicIdClasses()}}"><span class="br-sentence-text"></span></span>`
})
export class VoiceSentenceComponent implements OnInit {
	@Input() sentence: ConversationSentence;
	@Input() sentimentColorFunc: (sentimentValue: number) => string;

	constructor(
		private sentencePreviewElementBuilders: SentencePreviewElementBuildersService,
		private elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		if (_.isUndefined(this.sentence)) {
			return;
		}
		let showSentiment = true; // hide using css '.hide-sentiment-formatting'
		let sentenceCell = this.sentencePreviewElementBuilders.buildSentenceText(this.sentence,
			showSentiment, this.sentimentColorFunc);
		$(this.elementRef.nativeElement).find('.br-sentence-text').append(sentenceCell);
	}

	/**
	 * Apply classes tying a sentence to certain topic ids for later styling
	 */
	applyTopicIdClasses(): string {
		if (!this.sentence?.sentenceTopics?.length) {
			return '';
		}
		return this.sentence?.sentenceTopics?.map(topic => `has-topic-${topic.id}`).join(' ');
	}

	applyEnrichmentClasses(): string {
		let classes = [];

		for (let key in this.sentence.attributes) {
			if (this.sentence.attributes.hasOwnKey(key) && this.sentence.attributes[key].length) {
				this.sentence.attributes[key].forEach(val => {
					classes.push(`${key}:${val.toAlphaNumeric('-')}`);
				});
			}
		}

		return classes.join(' ');
	}
}
