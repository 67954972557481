import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { TableService } from '@cxstudio/services/table-service';
import { IAugmentedJQuery } from 'angular';
import { ReportCalculation } from '../providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricService } from '../providers/cb/period-over-period/period-over-period-metric.service';
import { GroupIdentifierHelper } from '../utils/analytic/group-identifier-helper';
import { PointSelectionUtils } from '../utils/analytic/point-selection-utils.service';
import { ReportPeriods } from '../utils/analytic/report-periods';
import { ElementUtils } from '../utils/visualization/element-utils.service';
import { ReportUtils, ITableReportScope } from '../utils/visualization/report-utils.service';
import { CbAnSlickTableDefinition } from './definitions/slick/cb-an-table-slick-definition.factory';

/**
 * Table visualization for attr-report widget
 */
/* ignore_instrumentation */
app.directive('cbAnTable', (
	$rootScope, $timeout, tableService: TableService,
	cbAnTableSlickDefinition: CbAnSlickTableDefinition, reportUtils: ReportUtils,
	elementUtils: ElementUtils, periodOverPeriodMetricService: PeriodOverPeriodMetricService,
	pointSelectionUtils: PointSelectionUtils, keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper
	) => {
	return {
		restrict: 'A',
		template: '<div></div>',
		scope: {
			options: '=',
			props: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			widget: '=',
			handleClick: '<',
			handleRightClick: '<',
			exportingImage: '<'
		},

		link: (scope, element) => {

			const COMPACT_CLASS = 'br-table-compact';

			if (!scope.utils || isEmpty(scope.utils.allColumns))
				return;

			if (isFalse(scope.demo)) {
				if (!tableService.processIfNoData(element, scope.dataObject, 'data', 'key_metrics', scope.utils)) {
					return;
				}
			} else {
				scope.dataObject = {
					metadata: {
						isWeekDescription: false
					}
				};
			}

			// report slick table creation
			let tableOptions = cbAnTableSlickDefinition.getTableOptions(scope.options, scope.utils);

			let customFormatters = cbAnTableSlickDefinition.getCustomFormatters(scope.utils, scope.dataObject.metadata);
			let postRenderers = cbAnTableSlickDefinition.getPostRenderers(scope.utils);
			let customSorters = cbAnTableSlickDefinition.getCustomSorters(scope.utils);

			let slickDefinitionCustomRenderer = tableService.slickDefinitionCustomRenderer(customFormatters, postRenderers, customSorters);
			reportUtils.initDestroyListener(scope);
			let addShowTotalIfNeeded = (innerTableElement): boolean => {
				if (scope.options.showTotal) {
					innerTableElement.addClass('br-show-total');
				}
				return scope.options.showTotal;
			};

			let adjustShowTotal = (innerTableElement): void => {
				if (!addShowTotalIfNeeded(innerTableElement)) {
					innerTableElement.removeClass('br-show-total');
				}
			};

			let buildTableElement = (): IAugmentedJQuery => {
				let innerTableElement = scope.demo
					? reportUtils.buildReportElement({
						left: '10px',
						right: '10px',
						bottom: '30px',
						top: '40px'
					}, 'static', 'height: 400px;')
					: reportUtils.buildReportElement({
						left: '0',
						right: '0',
						bottom: '0',
						top: '0'
					});
				innerTableElement.addClass('br-attr-table');
				addShowTotalIfNeeded(innerTableElement);
				if (scope.options.layout === 'compact' || cbAnTableSlickDefinition.hasCustomHeight(scope.utils)) {
					innerTableElement.addClass(COMPACT_CLASS);
				}

				return innerTableElement;
			};

			let tableElement = buildTableElement();
			let initialColumns;
			let dataView = cbAnTableSlickDefinition.getDataView(scope.dataObject, scope.options);

			scope.inlineFilter = (item, args): boolean => args.showTotal || item.leaf;

			scope.updateFilter = (): void => {
				adjustShowTotal(tableElement);
				dataView.setFilterArgs({
					showTotal: scope.options.showTotal
				});
				if (scope.options.showTotal && isSortedByGrouping()) {
					setDefaultSort();
				} else if (!isSortedByGrouping()) {
					sortBySelectedCalculation();
				}

				updateSelectedPoint();

				dataView.refresh();
			};

			function isSortedByGrouping(): boolean {
				let currentSortIdentifier = scope.options.sortBy;

				return scope.utils.selectedAttributes
					.map(grouping => grouping.identifier)
					.filter(identifier => GroupIdentifierHelper.isSameGroupingIdentifiers(identifier, currentSortIdentifier))
					.length > 0;
			}

			function setDefaultSort(): void {
				scope.options.sortBy = 'id';
				scope.grid.setSortColumn('id', true);
				tableService.gridSingleSorter<any>(scope.options.sortBy, true, scope.grid, scope.tableData);
			}

			function enableSort(columns, enabled): void {
				_.each(columns, column => {
					column.sortable = enabled;
				});
			}

			function sortBySelectedCalculation(): void {
				let sort = scope.options.sortBy;
				let dir = scope.options.direction ? scope.options.direction === SortDirection.ASC : false;
				let args = {
					sortCols: [{
						sortCol: { field: sort },
						sortAsc: dir
					}]
				};

				tableService.analyticTableGridSorter(null, args, dataView, scope.options, scope.grid, scope);
			}

			function updateSelectedPoint(): void {
				let selectedPoint = pointSelectionUtils.getSelectedPoint(scope.utils.containerId, scope.utils.widgetId);
				if (selectedPoint && !scope.demo) {
					pointSelectionUtils.enablePointSelection(scope.utils.containerId, scope.utils.widgetId, true);
					let groupings = GroupIdentifierHelper.getGroupings(scope.utils.selectedAttributes);
					let gridData = scope.grid.getData();
					let selected = _.find(gridData.getItems(), (item) => {
						return pointSelectionUtils.generatePointId(item, groupings) === selectedPoint;
					});
					if (selected) {
						scope.grid.setActiveCell(gridData.getRowById(selected.id), 0);
						pointSelectionUtils.enableWrongSelectionWarning(scope.utils.containerId, scope.utils.widgetId, false);
					} else {
						pointSelectionUtils.enableWrongSelectionWarning(scope.utils.containerId, scope.utils.widgetId, true);
					}
				}
			}

			scope.columns_all = tableService.getColumns(scope.dataObject, scope.utils);
			if (scope.options.table && scope.options.table.columns) {
				for (let i = 0; i < scope.options.table.columns.length; i++) {

					if (scope.columns_all.length !== scope.options.table.columns.length
							|| scope.options.table.columns[i].field !== scope.columns_all[i].field) {
						scope.options.table = {};
						break;
					}

					// update column names
					if (!_.isUndefined(scope.columns_all[i].name)) {
						scope.options.table.columns[i].name = scope.columns_all[i].name;
					}

					if (!_.isUndefined(scope.columns_all[i].sentimentFormatter)) {
						scope.options.table.columns[i].sentimentFormatter = scope.columns_all[i].sentimentFormatter;
					}

					// these below seem to be deprecated and not used
					if (!_.isUndefined(scope.columns_all[i].deltaFormatter)) {
						scope.options.table.columns[i].deltaFormatter = scope.columns_all[i].deltaFormatter;
					}

					if (!_.isUndefined(scope.columns_all[i].percentChangeFormatter)) {
						scope.options.table.columns[i].percentChangeFormatter = scope.columns_all[i].percentChangeFormatter;
					}

					if (!_.isUndefined(scope.columns_all[i].easeFormatter)) {
						scope.options.table.columns[i].easeFormatter = scope.columns_all[i].easeFormatter;
					}

					if (!_.isUndefined(scope.columns_all[i].formatter)) {
						scope.options.table.columns[i].formatter = scope.columns_all[i].formatter;
					}

					// adds prefix to legacy CSS class names
					if (!_.isUndefined(scope.columns_all[i].cssClass)) {
						scope.options.table.columns[i].cssClass = scope.columns_all[i].cssClass;
					}
				}
			}

			let data;
			if (isTrue(scope.demo)) {
				let demoData = tableService.getAnalyticTableDemoData(scope.columns_all);
				data = demoData.data;
				scope.dataObject.total = demoData.total;
				scope.hasLeafs = true;
			} else {
				data = tableService.getAnalyticTableData(scope.dataObject, scope.utils.selectedAttributes);
				scope.hasLeafs = _.some(data, {leaf: true});
				if (!scope.hasLeafs) {
					scope.options.showTotal = true;
					scope.options.sortBy = 'id';
				}
			}

			scope.tableData = data;

			elementUtils.compose(element, {
				body: tableElement
			});

			function getDrillPointDisplayName(item: string): string {
				let lastGrouping = _.last(scope.utils.selectedAttributes);
				let identifier = GroupIdentifierHelper.getIdentifier(lastGrouping);
				let value = item[identifier];
				let formatter = scope.utils.getGroupingFormatters
					? scope.utils.getGroupingFormatters()[identifier]
					: val => val;

				return formatter(value);
			}

			function createTable(extCols?): void {
				let tableCreationData = tableService.createTable(extCols, tableElement,
					dataView, tableOptions, slickDefinitionCustomRenderer, scope.options,
					scope.columns_all, scope.props.useHistoricPeriod, scope.props.selectedAttributes.length);
				adjustDemoTableElement();

				scope.grid = tableCreationData.grid;
				scope.grid.setOptions({autoHeight: scope.exportingImage});
				scope.$on('$destroy', scope.grid.destroy);
				scope.$on('focusFirstGridHeader', (event) => {
					scope.grid.resetActiveCell();
					scope.grid.navigateNext();
				});
				initialColumns = tableCreationData.initialColumns;

				if ($rootScope.isMobile) {
					dataView.setPagingOptions({
						pageSize: 5
					});
				}

				tableService.registerCommonHandlers(dataView, scope.options, scope.grid,
					scope.utils.widgetType, element);

				tableService.registerAnalyticTableGridSorter(dataView, scope.options, scope.grid,
					scope.utils.widgetType, element, scope);

				tableService.registerColumnsResizeHandler(scope.options, scope.grid);
				processDynamicDefinition(scope.grid.getColumns(), true);
				processDynamicDefinition(initialColumns, false);

				if (!scope.demo) {
					scope.grid.onClick.subscribe((e) => {
						if (scope.grid.getColumns()[0].link
								|| $(e.target).hasClass('no-drill')) {
							return;
						}

						e.preventDefault();
						if (!_.isUndefined(scope.handleClick)) {
							let cell = scope.grid.getCellFromEvent(e);
							if (cell.row === 0 && scope.options.showTotal) {
								return;
							}
							let item = getProcessedItem(cell, e);
							scope.handleClick(item, e);
						}
					});

					scope.grid.onKeyDown.subscribe((event) => {
						if (keyboardNavigationDrillHelper.isTableCell(event.target)) {
							let cell = scope.grid.getCellFromEvent(event);
							let cellData = getProcessedItem(cell, event);
							let isWidgetUsedAsFilter = keyboardNavigationDrillHelper.isWidgetUsedAsFilter(scope.widget.linkedWidgets);
							keyboardNavigationDrillHelper.onDataPointKeyboard(event, cellData, isWidgetUsedAsFilter, scope.handleClick);
						}
					});


					scope.grid.onContextMenu.subscribe((e) => {
						e.preventDefault();

						if ($(e.target).hasClass('no-drill')) {
							scope.handleRightClick(e);
							return;
						}

						if (!_.isUndefined(scope.handleClick)) {
							let cell = scope.grid.getCellFromEvent(e);
							if (cell.row === 0 && scope.options.showTotal) {
								return;
							}
							let item = getProcessedItem(cell, e);
							scope.handleClick(item, e, true);
						}
					});
					scope.grid.onHeaderContextMenu.subscribe((e) => {
						e.preventDefault();
						scope.handleRightClick(e);
					});

				}

				dataView.setItems(scope.tableData);

				if (!_.isUndefined(scope.options.sortBy)) {
					let dir = scope.options.direction ? scope.options.direction === SortDirection.ASC : false;

					scope.grid.setSortColumn(scope.options.sortBy, dir);
					let sort = scope.options.sortBy;

					let args = {
						sortCols: [{
							sortCol: {
								field: sort,
								sorter: undefined
							},
							sortAsc: dir
						}]
					};

					if (!_.isUndefined(scope.options.table)) {
						let sortField = _.findWhere(scope.options.table.columns, { field: sort });
						if (!_.isUndefined(sortField) && !_.isUndefined(sortField.sorter)) {
							args.sortCols[0].sortCol.sorter = sortField.sorter;
						}
					}

					tableService.analyticTableGridSorter(null, args, dataView, scope.options, scope.grid, scope);
				}
				dataView.setFilterArgs({
					showTotal: scope.options.showTotal
				});
				dataView.setFilter(scope.inlineFilter);
				dataView.getItemMetadata = cbAnTableSlickDefinition.getMetadata(
					dataView.getItemMetadata, dataView);
				scope.grid.invalidate();

				scope.grid.render(() => {
					scope.$$postDigest(() => {
						reportUtils.handleWidgetRenderedEvent(scope.utils.widgetId, scope.utils.widgetType,
							scope.utils.containerId);
					});
				});

				function getProcessedItem(cell, event): any {
					let item = dataView.getItem([cell.row]);
					let selectedColumn = scope.grid.getColumns()[cell.cell];
					item._group = selectedColumn._group;
					item.selectedMetricName = selectedColumn.id;
					item.P1StartDate = scope.dataObject.metadata.P1StartDate;
					item.P1EndDate = scope.dataObject.metadata.P1EndDate;
					item.P2StartDate = scope.dataObject.metadata.P2StartDate;
					item.P2EndDate = scope.dataObject.metadata.P2EndDate;
					item._pop = selectedColumn.historicMetric ? ReportPeriods.HISTORIC : ReportPeriods.CURRENT;
					item.displayName = getDrillPointDisplayName(item);
					item._element = $(event.target).parents('.click-row');
					return item;
				}

				function setTableColumns(columns): void {
					enableSort(columns, scope.hasLeafs);
					scope.grid.setColumns(columns);
					scope.grid.invalidate();
					scope.grid.render();
				}

				function processDynamicDefinition(columns, rerender): void {
					updateColumnNames(columns, scope.utils.attributes);
					if (rerender) {
						setTableColumns(columns);
					}
				}

				// this is probably redundant, but have no time to investigate
				function updateColumnNames(columns, attributes): void {
					_.each(columns, (column) => {
						if (column.attribute || column.attributeMetric) {
							let id = column.parentId ? column.parentId : column.id;
							let displayName = findAttributeDisplayNameByName(attributes, id);
							if (displayName) {
								if (scope.props.useHistoricPeriod && column.attributeMetric) {
									let metric: Partial<ReportCalculation> = {
										name: column.id,
										parentMetricDisplayName: displayName,
										isPopMetric: column.historicMetric
									};
									metric.displayName = periodOverPeriodMetricService
										.getActualPeriodOverPeriodMetricDisplayName(metric as ReportCalculation, displayName);
									displayName = periodOverPeriodMetricService
										.getMetricDisplayNameWithPeriodPrefix(metric as ReportCalculation, scope.options);
								}
								column.name = displayName;
							}
						}
					});
				}

				function findAttributeDisplayNameByName(attributes, name): string {
					let attribute = attributes.filter((attr) => {
						return name.toLowerCase() === attr.name.toLowerCase();
					})[0];
					return attribute ?
						attribute.displayName :
						null;
				}

				dataView.onRowsChanged.subscribe((e, args) => {
					processDynamicDefinition(scope.grid.getColumns(), true);
					processDynamicDefinition(initialColumns, false);
				});

				function adjustDemoTableElement(): void {
					if (scope.demo) {
						tableElement.css('position', 'static');
					}
				}

				updateSelectedPoint();
			}

			createTable();

			reportUtils.initResizeHandler(scope, element, reportUtils.tableResizeHandler(scope as unknown as ITableReportScope, element));

			scope.$watch('$parent.isMobile', (isMobile, previous) => {
				if (isMobile === previous)
					return;
				$timeout(() => {
					createTable(initialColumns);
				}, 500);
			});

			// report properties changing watchers
			let detectDataChange = (): boolean => {
				let columnsAllNew = tableService.getColumns(scope.tableData, scope.utils);
				if (scope.options.table && scope.options.table.columns) {
					for (let i = 0; i < scope.options.table.columns.length; i++) {
						if (scope.options.table.columns[i].field !== columnsAllNew[i].name) {
							scope.options.table = {};
							break;
						}
					}
				}

				if (tableService.isColumnsChanged(columnsAllNew, scope.columns_all) ||
					scope.demo) {
					scope.columns_all = slickDefinitionCustomRenderer(columnsAllNew);
					scope.grid.setColumns(scope.columns_all);

					if (scope.demo) {
						let demoData = tableService.getAnalyticTableDemoData(scope.columns_all);
						scope.tableData = demoData.data;
						scope.dataObject.total = demoData.total;
						dataView.setItems(scope.tableData);
					}
					dataView.setFilterArgs({
						showTotal: scope.options.showTotal
					});
					adjustShowTotal(tableElement);

					return true;
				}

				return false;
			};

			let detectRowHeightChange = (): boolean => {
				let hasChanges = false;
				if (cbAnTableSlickDefinition.hasCustomHeight(scope.utils)) {
					tableElement.removeClass(COMPACT_CLASS);
					tableElement.addClass('br-table-custom-height');
					hasChanges = true;
				} else {
					tableElement.removeClass('br-table-custom-height');
					if (scope.options.layout === 'compact' && !tableElement.hasClass(
						COMPACT_CLASS)) {
						tableElement.addClass(COMPACT_CLASS);
						hasChanges = true;
					} else if (scope.options.layout !== 'compact' && tableElement.hasClass(
						COMPACT_CLASS)) {
						tableElement.removeClass(COMPACT_CLASS);
						hasChanges = true;
					}
				}
				if (hasChanges) {
					scope.grid.setOptions({
						rowHeight: cbAnTableSlickDefinition.getTableRowHeight(scope.options, scope.utils)
					});
				}
				return hasChanges;

			};

			scope.$watch('trigger', (newValue, oldValue) => {
				if (newValue === oldValue) return;
				if (_.isUndefined(scope.tableData) || scope.tableData.length === 0) {
					return;
				}
				let compactChange = detectRowHeightChange();
				let requiresRerender = detectDataChange() || compactChange;

				if (requiresRerender) {
					scope.grid.invalidate();
					scope.grid.render();
				}
			});
		}
	};
});
