export class ReportViewWrapperComponent implements ng.IComponentController {}

app.component('reportViewWrapper', {
	bindings: {
		visualization: '<',
		trigger: '<',
		data: '<',
		demo: '<',
		widget: '<',
		utils: '<',
		handleClick: '<',
		handleRightClick: '<',
		exportingImage: '<',
	},
	controller: ReportViewWrapperComponent,
	template: `
		<!--
			This ng-repeat is required to redraw the whole thing when visualization or trigger changes.
			It fixes "destroyed view" errors when using Angular components inside of AngularJS visualizations,
			which previously were not correctly detached and destroyed.
		-->
		<div ng-repeat="dummy in [$ctrl.visualization + '-' + $ctrl.trigger]"
			ng-class="{'border-b-radius-widget-default': !$ctrl.demo}"
			class="h-100-percent w-100-percent br-widget-view">
			<cb-report-view
				class="h-100-percent w-100-percent"
				view="$ctrl.visualization"
				trigger="$ctrl.trigger"
				options="$ctrl.widget.visualProperties"
				data="$ctrl.data"
				demo="$ctrl.demo"
				props="$ctrl.widget.properties"
				widget="$ctrl.widget"
				linked-widgets="$ctrl.widget.linkedWidgets"
				utils="$ctrl.utils"
				handle-click="$ctrl.handleClick"
				handle-right-click="$ctrl.handleRightClick"
				exporting-image="$ctrl.exportingImage"
			></cb-report-view>
		</div>`
});
