import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { EsQueryComponent } from '@app/modules/filter-builder/es-query/es-query.component';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { MixedFilterBuilderComponent } from './mixed-filter-builder/mixed-filter-builder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { TextFilterService } from '@app/modules/filter-builder/text-filter.service';
import { TopicMultiselectComponent } from '@app/modules/filter-builder/topic/topic-multiselect.component';
import { MultiselectFooterComponent } from './attribute/multiselect-footer/multiselect-footer.component';
import { MultiselectOptionsComponent } from './attribute/multiselect-options/multiselect-options.component';
import { MultiselectButtonComponent } from './attribute/multiselect-button/multiselect-button.component';
import { MultiselectComponent } from './attribute/multiselect/multiselect.component';
import { PagingMultiselectComponent } from './attribute/paging-multiselect/paging-multiselect.component';
import { FilterRuleComponent } from './filter-rule/filter-rule.component';
import { FilterBuilderComponent } from './filter-builder/filter-builder.component';
import { FilterCreationWizardComponent } from './filter-creation-wizard/filter-creation-wizard.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { IconModule } from '@app/modules/icon/icon.module';
import {
	DropdownToggleWrapperComponent
} from '@app/modules/filter-builder/attribute/multiselect/dropdown-toggle-wrapper/dropdown-toggle-wrapper.component';


@NgModule({
	imports: [
		SharedModule,
		AssetManagementModule,
		DragDropModule,
		ScrollingModule,
		ExperimentalScrollingModule,
		DialogModule,
		IconModule
	],
	exports: [
		MixedFilterBuilderComponent,
		TopicMultiselectComponent,
		MultiselectFooterComponent,
		MultiselectOptionsComponent,
		MultiselectButtonComponent,
		MultiselectComponent,
		PagingMultiselectComponent,
		FilterRuleComponent,
		FilterBuilderComponent,
		FilterCreationWizardComponent
	],
	declarations: [
		EsQueryComponent,
		MixedFilterBuilderComponent,
		TopicMultiselectComponent,
		MultiselectFooterComponent,
		MultiselectOptionsComponent,
		MultiselectButtonComponent,
		MultiselectComponent,
		PagingMultiselectComponent,
		FilterRuleComponent,
		FilterBuilderComponent,
		FilterCreationWizardComponent,
		DropdownToggleWrapperComponent
	],
	providers: [
		EsQueryService,
		TextFilterService,
		FilterRuleService,
	],
})
export class FilterBuilderModule {}
