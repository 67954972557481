
export enum OptionsConstant {
	GROUP_BY = 'groupBy',
	CALCULATION = 'calculation',

	DATE_RANGE = 'dateRange',
	PINNED_DATE_RANGE = 'pinnedDateRange',
	TOPICS = 'topics',
	TOPIC_LEAF = 'topicLeaf',
	NLP = 'nlp',
	WORDS = 'words',
	ENRICHMENT = 'enrichment',
	LANGUAGE = 'language',
	CONVERSATION = 'conversation',
	TIME = 'timeOptions',
	ATTRIBUTES = 'attributes',
	DERIVED_ATTRIBUTES = 'derivedAttributes',
	METRICS = 'metrics',
	STANDARD = 'standard',
	FILTERS = 'SAVED_FILTER',
	SCORECARD_FILTERS = 'SCORECARD_FILTER',
	CLARABRIDGE = 'clarabridge',
	DRIVERS = 'drivers',
	SCORECARDS = 'scorecards',
	HIERARCHY_MODEL = 'hierarchyModel',
	HIERARCHY_CALCULATIONS = 'hierarchyCalculations',
	HIERARCHY_ENRICHMENT_PROPERTY = 'hierarchyEnrichmentProperty',

	DERIVED_ATTRIBUTES_VALUE = 'Derived Attributes',

	ADMIN_PROJECT = 'adminProject',

	CLOUD_SHAPE_RECTANGLE = 'rectangle',
	CLOUD_SHAPE_CLOUD = 'cloud',
	CLOUD_SIZING_OPTION_ONE = 'optionOne',
	CLOUD_SIZING_OPTION_TWO = 'optionTwo',
}


