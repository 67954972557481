import { IDirectiveFactory, INgModelController } from 'angular';

// will strictly enforce a minimum numerical value onto a field
// values entered < min will be automatically updated to reflect the min attr
//
// example : <input type="number" min=1 strict-min ng-model="sample.value">

const strictMin = () => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: ($scope, element, attr, ctrl: INgModelController) => {
			if (!attr.min) return;

			let forceMin = Number(attr.min);

			let forceFunc = (val) => {
				if (Number(val) < forceMin) {
					ctrl.$setViewValue(forceMin);
					ctrl.$render();
					return forceMin;
				}
				return Number(val);
			};

			ctrl.$parsers.unshift(forceFunc);
			ctrl.$formatters.unshift(forceFunc);
		}
	};
};

app.directive('strictMin', strictMin as IDirectiveFactory);
