import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ReportScopeUtils } from '@cxstudio/reports/utils/report-scope-utils.service';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ISearchTerm } from '@cxstudio/reports/widget-types/selector/selector-widget-search.component';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';

interface ISelectorSearchScope extends ISimpleScope {
	options: VisualProperties;
	dataObject: WidgetProperties;
	demo: boolean;
	trigger: number;
	utils: WidgetUtils;
	handleClick: (items: DrillPoint[]) => void;
	handleRightClick: () => void;

	onSearch: (terms: ISearchTerm[]) => void;
	initialSelection: ISearchTerm[];
}

interface ISearchDrillPoint extends DrillPoint {
	_term: {
		name: string;
		displayName: string;
	};
}

app.directive('cbSelectorSearch', (
	reportUtils: ReportUtils,
	reportScopeUtils: ReportScopeUtils,
	currentWidgets: ICurrentWidgets,
	$timeout: ng.ITimeoutService,
) => {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			options: '<',
			dataObject: '<data',
			demo: '<',
			trigger: '<',
			utils: '<',
			handleClick: '<',
			handleRightClick: '<'
		},
		template: `<div>
			<selector-widget-search
				ng-if="dataObject"
				properties="dataObject"
				initial-selection="initialSelection"
				visual-props="::options"
				demo="::demo"
				on-search="onSearch($items)">
			</selector-widget-search>
		</div>`,
		link: (($scope: ISelectorSearchScope, element: ng.IAugmentedJQuery) => {
			if (!$scope.dataObject)
				return;
			reportUtils.initDestroyListener($scope);

			if (!$scope.demo) {
				reportScopeUtils.emitContextMenuHandling($scope, element, true);
			}

			render();

			$scope.onSearch = (items: ISearchTerm[]) => {
				if ($scope.demo)
					return;
				let drillPoints: ISearchDrillPoint[] = _.map(items, item => {
					let identifier = GroupIdentifierHelper.getIdentifier(item.attribute);
					let point = {
						_group: {
							identifier,
							name: item.attribute.name,
							type: item.attribute.type
						},
						_term: { // used internally by this widget
							name: item.name,
							displayName: item.displayName
						}
					};
					point[identifier] = item.name;
					return point as ISearchDrillPoint;
				});
				$scope.handleClick(drillPoints);
				let showClearSelection = !_.isEmpty(items);
				$scope.$emit('selector:updateClearSelections', showClearSelection);
			};

			if ($scope.demo) {
				// not required for real widget
				$scope.$watch(() => $scope.trigger, () => {
					let data = $scope.dataObject;
					$scope.dataObject = null;
					$timeout(() => {
						$scope.dataObject = data;
					}, 1);
				});
			}

			function render(): void {

				// process selected points for refresh and pdf export
				updateSelectedPoints();

				($scope as any).$$postDigest(() => {
					if (!$scope.demo) {
						reportUtils.handleWidgetRenderedEvent($scope.utils.widgetId, $scope.utils.widgetType, $scope.utils.containerId);
					}
				});
			}

			function updateSelectedPoints(): void {
				if ($scope.demo)
					return;
				let allLinkedFilters: LinkedFilter[] = _.chain(currentWidgets.getAllLinkedFilters($scope.utils.containerId))
					.values()
					.flatten()
					.value();
				let linkedFilters = _.filter(allLinkedFilters, linkedFilter => {
					return linkedFilter.widget.id === $scope.utils.widgetId;
				});
				if (!_.isEmpty(linkedFilters)) {
					$scope.initialSelection = [];
					_.each(linkedFilters, filter => {
						let point = filter.point as ISearchDrillPoint;
						let existing = _.findWhere($scope.initialSelection, point._term);
						if (!existing) {
							let attribute = _.findWhere($scope.utils.selectedAttributes, {name: point._group.name});
							$scope.initialSelection.push({
								attribute,
								name: point._term.name,
								displayName: point._term.displayName
							});
						}
					});
					$scope.$emit('selector:updateClearSelections', true);
				}
			}

		}) as any
	};
});
