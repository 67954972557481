export enum DateFilterMode {
	// TODO fill values on demand
	CUSTOM = 'custom',
	DYNAMIC = 'dynamic',
	LAST_4_HOURS = 'last4h',
	LAST_24_HOURS = 'last24h',
	LAST_7_DAYS = 'last7d',
	LAST_30_DAYS = 'last30d',
	LAST_90_DAYS = 'last90d',
	LAST_180_DAYS = 'last180d',
	LAST_365_DAYS = 'last365d',
	MISS_DATE = 'missDate',

	CURRENT_WEEK = 'currentw',
	CURRENT_MONTH = 'currentm',
	CURRENT_QUARTER = 'currentq',
	CURRENT_YEAR = 'currenty',
	LAST_WEEK = 'lastw',
	LAST_MONTH = 'lastm',
	LAST_QUARTER = 'lastq',
	LAST_YEAR = 'lasty',
	WEEK_TO_DATE = 'week_to_date',
	MONTH_TO_DATE = 'month_to_date',
	QUARTER_TO_DATE = 'quarter_to_date',
	YEAR_TO_DATE = 'year_to_date',
	PREVIOUS_12_MONTHS = 'previous12m',
	ROLLING_12_MONTHS = 'rolling12m',
	ROLLING_13_MONTHS = 'rolling13m',
	ROLLING_14_MONTHS = 'rolling14m',
	LAST_12_MONTHS = 'last12m',
	LAST_13_MONTHS = 'last13m',
	LAST_14_MONTHS = 'last14m',

}

export enum HistoricDateFilterMode {
	PREVIOUS_PERIOD = 'previousPeriod',
	SAME_LAST_WEEK = 'sameLastWeek',
	SAME_LAST_MONTH = 'sameLastMonth',
	SAME_LAST_QUARTER = 'sameLastQuarter',
	SAME_LAST_YEAR = 'sameLastYear',
	RUNNING_3_MONTHS = 'running3m',
}

export type AnyDateFilterMode = DateFilterMode | HistoricDateFilterMode;

export const DEFAULT_DATE_FILTER_MODE = DateFilterMode.LAST_30_DAYS;

export class DateFilterModes {
	static isCustomDateRange(value: string): boolean {
		return /customFilter/.test(value);
	}
}
