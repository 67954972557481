<modal-header
	(cancel)="close()"
	[modalTitle]="modalTitle">
</modal-header>
<div id="cb-modal-overlay" *ngIf="viewMode"></div>
<div class="modal-body" [ngBusy]="[loading, assetsLoading]" [blockKbNavigation]="viewMode">
	<metric-editor *ngIf="assets"
		[mode]="input.mode"
		[metric]="metric"
		(metricChange)="metricChangeHandler($event)"
		[project]="project"
		[assets]="assets"
		[selectedFolder]="input.selectedFolder"
		[folders]="input.folders"
		(onNameChange)="nameChangeHandler()"
		(onValidityChange)="validityChangeHandler($event)"
	></metric-editor>
	<alert type="danger" *ngIf="uniqueNameError">{{'metrics.nameNotUnique'|i18n}}</alert>
</div>
<div class="modal-footer">
	<button
		id="btn-metric-close"
		type="button"
		class="btn btn-secondary"
		(click)="close()">{{closeButtonText}}
	</button>
	<button *ngIf="!viewMode && !isPredefinedMetric() && canShare"
		id="save-and-share-metric"
		type="button"
		class="btn btn-secondary"
		[disabled]="isSaveDisabled()"
		(click)="save(true)">{{'common.saveAndShare'|i18n}}
	</button>
	<button *ngIf="!viewMode"
		id="btn-metric-save"
		type="button"
		class="btn btn-primary"
		[disabled]="isSaveDisabled()"
		(click)="save()">{{'common.save'|i18n}}
	</button>
</div>
