import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';

@Injectable()
export class RecentDateService {

	constructor(
		@Inject('dateService') private readonly dateService: DateService,
		private readonly cxLocalService: CxLocaleService,
	) { }

	formatToRecentDate = (dateStr: string | number) => {
		if (!dateStr) {
			return '';
		}
		let date = moment(dateStr).toDate();
		if (!moment(date).isValid()) {
			throw new Error(date + ' is not a date');
		}
		let today = moment().startOf('day');
		let yesterday = moment().add(-1, 'days').startOf('day');
		if (!today.isAfter(date)) {
			return this.cxLocalService.getString('common.today') + ', ' + this.dateService.format(date, DateTimeFormat.BASIC_TIME);
		}

		if (!yesterday.isAfter(date)) {
			return this.cxLocalService.getString('common.yesterday') + ', ' + this.dateService.format(date, DateTimeFormat.BASIC_TIME);
		}

		return this.dateService.format(date, DateTimeFormat.BASIC_DATE_TIME);
	}
}

app.service('recentDateService', downgradeInjectable(RecentDateService));
