import { TextToken, TextTokenType } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathToken } from '../tokenizer/custom-math-token';

export class CustomMathAdapterUtils {
	static OPEN_SQUARE_BRACKET = '[';
	static CLOSE_SQUARE_BRACKET = ']';

	static getCalculationName(expressionDisplay: string): string {
		let match = expressionDisplay.match(/^[^\[]*\[(.*)\]$/);
		return match ? match[1] : expressionDisplay;
	}
	
	static escapeSpecialChars(name: string): string {
		return name.replace('\\', '\\\\')
			.replace('[', '\\[')
			.replace(']', '\\]');
	}

	static unescapeSpecialChars = (text: string): string => {
		return text.replace(/\\\\/g, '\\')
			.replace(/\\\[/g, '[')
			.replace(/\\\]/g, ']');
	}

	static openBraceToken(): TextToken {
		return { text: '[', type: TextTokenType.SYNTAX };
	}

	static closeBraceToken(): TextToken {
		return { text: ']', type: TextTokenType.SYNTAX };
	}

	static getItemNameToken(token: CustomMathToken, type: TextTokenType, skipEscape?: boolean): TextToken {		
		let tokenText = token.text;
		if (this.isEmptyNotCompletedToken(tokenText)) {
			return {text: '', type};
		}
		if (skipEscape) {
			return {text: this.getRawText(tokenText), type};
		}

		return {text: this.getEscapedText(token), type};
	}

	private static isEmptyNotCompletedToken(tokenText: string): boolean {
		let openBraceIndex = tokenText.indexOf(this.OPEN_SQUARE_BRACKET);
		return !tokenText.includes(this.CLOSE_SQUARE_BRACKET) && openBraceIndex + 1 === tokenText.length;
	}

	private static getEscapedText(token: CustomMathToken): string {
		let tokenText: string = token.text;
		let escapedText: string;
		if (tokenText.includes(this.CLOSE_SQUARE_BRACKET)) {
			escapedText = token.value ? CustomMathAdapterUtils.escapeSpecialChars(token.value) : '';
		} else {
			let text = this.getRawText(tokenText);
			escapedText = CustomMathAdapterUtils.escapeSpecialChars(text);
		}

		return escapedText;
	}

	private static getRawText(tokenText: string): string {
		let openBraceIndex = tokenText.indexOf(this.OPEN_SQUARE_BRACKET);
		let indexEnd = tokenText.endsWith(this.CLOSE_SQUARE_BRACKET) ? -1 : tokenText.length;
		return tokenText.slice(openBraceIndex + 1, indexEnd);
	}

	static getSegmentTextTokens(keywordText: string, keywordType: TextTokenType, keywordContentToken: TextToken): TextToken[] {
		let textTokens: TextToken[] = [{ text: keywordText, type: keywordType }];

		textTokens.push(CustomMathAdapterUtils.openBraceToken());
		if (keywordContentToken) {
			textTokens.push(keywordContentToken);
		}
		textTokens.push(CustomMathAdapterUtils.closeBraceToken());
		
		return textTokens;
	}
}