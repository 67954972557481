<div class="dashboard-pinned-header"
	[ngStyle]="{width: getContainerWidth() + 'px'}">
	<div class="book-dashboard-header-panel d-flex flex-singleline w-100-percent justify-between align-center bg-dashboard">
		<dashboard-view-filters
			class="ph-32 overflow-hidden"
			toggle="icon"
			[dashboard]="dashboard"
			[dashboardHistory]="dashboardHistory"
			[personalization]="personalization"
		></dashboard-view-filters>

		<div class="d-flex flex-nowrap">
			<div *ngIf="isFrontlineDashboard()">
				<snapshot-date *ngIf="dashboard.snapshotMetadata" [date]="dashboard.snapshotMetadata.createdDate">
				</snapshot-date>
				<no-published-report class="font-size-2" *ngIf="!dashboard.snapshotMetadata"></no-published-report>
			</div>
			<button *ngIf="canEditDashboard()"
				class="bg-none border-0 dashboard-edit-btn text-gray-1000 flex-fixed ph-32 pv-4 hidden-fullscreen font-size-2 cursor-pointer d-flex align-center"
				[attr.aria-label]="getEditDashboardLabel()"
				(click)="editDashboard.emit(dashboard)"
				(keydown)="editDashboardKb($event)">
				<span class="q-icon q-icon-chart-5 mr-4"></span>
				<span [i18n]="'dashboard.editDashboard'"></span>
			</button>
		</div>
	</div>

	<div class="header-shadow" *ngIf="shadow"></div>
</div>
