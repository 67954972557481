<ul id="{{groupId}}" role="group" [attr.aria-label]="node[displayProperty]">
	<ng-container *ngFor="let item of node.children
			| treeSearch : search.hierarchySearch : displayProperty : node
			| treeSort : forcedOrder : displayProperty;
			index as $index">
		<tree-node
			class="cursor-pointer"
			[node]="item"
			[search]="search"
			[displayProperty]="displayProperty"
			[forcedOrder]="forcedOrder"
			[isDisabled]="isDisabled"
			[isSelected]="isSelected"
			[isNotRecommended]="isNotRecommended"
			[limitedWidth]="limitedWidth"
			[folderClickIgnore]="folderClickIgnore"
			[showNotRecommendedPrompt]="showNotRecommendedPrompt"
			[isVisibleOverrided]="isVisibleOverrided"
			[customValidation]="customValidation"
			(onNodeClick)="nodeClickHandler($event)"
			(onFolderExpand)="folderExpandHandler($event)"
			[showCheckboxes]="showCheckboxes"
			[showNodeCheckbox]="showNodeCheckbox"
			[isNodeCheckboxDisabled]="isNodeCheckboxDisabled"
			[nodeIndeterminate]="nodeIndeterminate"
			[isNodeChecked]="isNodeChecked"
			[isNodeMarked]="isNodeMarked"
			[getNodeTooltip]="getNodeTooltip"
			[isNodeHighlighted]="isNodeHighlighted"
			[setFocus]="getSetNodeFocusSubject(item).asObservable()"
			(onFocusMove)="focusMoveHandler($event)"
			[optionClassFormatter]="optionClassFormatter"
			[itemTemplate]="itemTemplate"
		></tree-node>
	</ng-container>
</ul>
