<modal-header [modalTitle]="'common.sessionExpired' | i18n" (cancel)="changeUser()">
</modal-header>
<div class="modal-body form-horizontal" [ngBusy]="loading">
	<div data-testid="session-expired-credentials" *ngIf="showLoginCredentialsInputFields()">
		<div class="row">
			<div class="col-sm-12 form-group mb-16">
				<label class="col-sm-3 control-label" for="login-username">{{ "login.email" | i18n}}</label>
				<input class="form-control" id="login-username" [ngModel]="input.username" disabled>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 form-group mb-16">
				<label class="col-sm-3 control-label" for="login-password">{{ "login.password" | i18n}}</label>
				<input class="form-control" focus-on-render type="password" id="login-password" required="true"
					[(ngModel)]="data.password" (keyup.enter)="login()">
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12">
				<alert type="danger" *ngIf="data.errorMessage" [text]="data.errorMessage"
					(dismiss)="cleanErrorMessage()"></alert>
			</div>
		</div>
	</div>
	<div data-testid="session-expired-continue" *ngIf="showContinueSessionMsg()">
		<span>{{ "login.continueSession" | i18n }}</span>
	</div>
	<div data-testid="session-expired-continue-xm" *ngIf="showContinueSessionMsgForXmEnforced()">
		<span>{{ "login.continueSessionXmEnforcedEnabled" | i18n }}</span>
	</div>
</div>
<div class="modal-footer">
	<button
		id="btn-dialog-cancel"
		type="button"
		class="btn btn-secondary bg-active-gray-1000 text-active-white"
		(click)="changeUser()"
	>{{ "common.changeUser" | i18n }}</button>
	<button
		*ngIf="ui.showQualtricsLogin"
		id="btn-dialog-qualtrics-login"
		type="button"
		class="btn btn-primary"
		(click)="loginViaQualtrics()"
	>{{ "login.qualtricsLogin" | i18n }}</button>
	<button
		*ngIf="showContinueButton()"
		data-testid="btn-session-expired-continue"
		id="btn-dialog-save"
		type="button"
		class="btn btn-primary"
		(click)="login()"
	>{{ "common.continue" | i18n }}</button>
</div>


