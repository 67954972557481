import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

export const COLORPICKER_STANDARD_PALETTES = [
	['#FFFFFF', '#BE0712', '#FC0D1B', '#FDBF2D', '#FFFD38', '#94CE58', '#1AAF54', '#1EB1ED', '#1072BD', '#02225E'],
	['#BFBFBF', '#E9ACB0', '#FEAEB3', '#FEE9B9', '#FFFEBC', '#DBEEC7', '#B2E4C6', '#B4E5F9', '#AFD0E9', '#AAB5C9'],
	['#808080', '#D35961', '#FD5D67', '#FDD473', '#FFFD7A', '#B7DE8F', '#66C98D', '#69CBF3', '#5FA1D3', '#566B93'],
	['#404040', '#7E040C', '#A80812', '#A87F1E', '#AAA825', '#62893A', '#117438', '#14769E', '#0A4C7E', '#01163E'],
	['#000000', '#3F0206', '#540409', '#543F0F', '#555412', '#31441D', '#083A1C', '#0A3B4F', '#05263F', '#000B1F'],
];

@Component({
	selector: 'color-picker-colors',
	template: `
<color-array *ngFor="let row of standardColors; let $last = last"
	class="d-flex"
	[class.mb-8]="!$last"
	[selectedColor]="selectedColor"
	[colors]="row"
	(colorSelect)="colorSelect.emit($event)">
</color-array>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerColorsComponent implements OnInit {
	@Input() selectedColor: string;
	@Output() colorSelect = new EventEmitter<string>();

	standardColors: string[][];

	ngOnInit(): void {
		this.standardColors = COLORPICKER_STANDARD_PALETTES;
	}

}
