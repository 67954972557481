import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { ContectMenuDividerItem, IContextMenuItem, ContextMenuListItem, ContextMenuTextItem, ContextMenuTemplateItem } from '@app/shared/components/kebab-menu/context-menu-item';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

@Component({
	selector: 'home-page-menu',
	templateUrl: './home-page-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageMenuComponent implements OnInit, AfterViewInit {
	@ViewChild('patternsFill') private patternsFill: TemplateRef<any>;

	menuItems: IContextMenuItem[];

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private userHomePageService: UserHomePageService
	) { }

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.userHomePageService.getUserHomePages()
			.then(homePages => {
				let homePageItems = [...homePages]
					.sort((a, b) => a.rank - b.rank)
					.map(homePage => new ContextMenuTextItem(
						`home-page`, this.formatHomePageName(homePage), () => this.onSwitchHomePage(homePage)));
				this.menuItems = [
					new ContextMenuListItem('switch-home-page', this.locale.getString('homePage.switchHomePage'), homePageItems),
					new ContectMenuDividerItem(),
					new ContextMenuTemplateItem(this.patternsFill)
				];
				if (this.ref && !(this.ref as ViewRef).destroyed) {
					this.ref.detectChanges();
				}
			});
	}

	private formatHomePageName(homePage: HomePage): string {
		return homePage.default
			? `${homePage.name} (${this.locale.getString('common.default')})`
			: homePage.name;
	}

	onSwitchHomePage = (homePage: HomePage) => {
		this.userHomePageService.changeHomePage(homePage);
	}

	focusToggle = (event: KeyboardEvent) => {
		// on enter toggle can be rendered in a different component, have to handle focus after a timeout
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			setTimeout(() => $('home-page-menu button[ngbdropdowntoggle]').trigger('focus'));
		}
	}
}
