import { Injectable } from '@angular/core';
import { LocallyStoredItems } from './locally-stored-items';
import { downgradeInjectable } from '@angular/upgrade/static';
import { LocalStorageService } from 'angular-2-local-storage';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import * as moment from 'moment';

export interface Expirable {
	ttl: number;
}

@Injectable({
	providedIn: 'root'
})
export class SessionPreferencesService {
	private values: {[key: string]: any} = {};

	constructor(
		private localStorageService: LocalStorageService
	) {
		this.init();
	}

	init = (): void => {
		for (const key of Object.keys(LocallyStoredItems)) {
			let itemName = LocallyStoredItems[key].storageName;
			let defaults = LocallyStoredItems[key].fallbackValue;
			if (this.localStorageService.isSupported) {
				if (_.isUndefined(defaults))
					this.values[itemName] = this.localStorageService.get(itemName);
				else this.values[itemName] = _.extend(defaults, this.localStorageService.get(itemName));
			} else {
				this.values[itemName] = defaults;
			}
		}
	}

	get = (key: string): any => {
		this.checkNestedTTL(key, ObjectUtils.copy(this.values[key]));
		return this.values[key];
	}

	private checkNestedTTL = (key: string, object: any): void => {
		if (!_.isObject(object)) { return; }

		for (const nestedKey in object) {
			if (!object.hasOwnProperty(nestedKey)) { continue; }

			if (nestedKey === 'ttl') {
				if (this.isExpired(object)) {
					this.unset(key);
				}

				return;
			}

			if (this.isExpired(object[nestedKey])) {
				this.unset(key, [nestedKey]);
			}
		}
	}

	private isExpired = (object: any): boolean => {
		if (_.isObject(object) && 'ttl' in (object as any)) {
			const ttlTimestamp = object['ttl'];
			return moment().valueOf() >= ttlTimestamp;
		}

		return false;
	}

	set = (key: string, value: any): void => {
		this.values[key] = ObjectUtils.copy(value);
		this.syncStorage(key);
	}

	push = (key: string, value: any, uniqueField: string = '', allowOverwrite: boolean = false): void => {
		if (!this.values[key]) {
			this.values[key] = [];
		}

		if (uniqueField) {
			const uniqueValuesMap = new Map(this.values[key].map(entry => [entry[uniqueField], entry]));
			const newValueUniqueField = value[uniqueField];

			if (allowOverwrite && uniqueValuesMap.has(newValueUniqueField)) {
				const existingEntry = uniqueValuesMap.get(newValueUniqueField);
				Object.assign(existingEntry, value);
				this.syncStorage(key);
			} else if (!uniqueValuesMap.has(newValueUniqueField)) {
				this.values[key].push(ObjectUtils.copy(value));
				this.syncStorage(key);
			}
		} else {
			this.values[key].push(ObjectUtils.copy(value));
			this.syncStorage(key);
		}
	}

	has = (key: string): boolean => {
		return !!this.values[key];
	}

	unset = (key: string, nestedKeys: string[] = []): void => {
		if (nestedKeys.length === 0) {
			delete this.values[key];
			this.syncStorage(key);

			return;
		}

		let currentValue = this.values[key];

		for (let i = 0; i < nestedKeys.length - 1; i++) {
			const nestedKey = nestedKeys[i];

			if (_.isObject(currentValue) && nestedKey in currentValue) {
				currentValue = currentValue[nestedKey];
			} else {
				// Key or nested key not found
				return;
			}
		}

		const lastNestedKey = nestedKeys[nestedKeys.length - 1];

		if (_.isArray(currentValue) && Number.isInteger(Number(lastNestedKey))) {
			const indexToRemove = Number(lastNestedKey);
			if (indexToRemove >= 0 && indexToRemove < currentValue.length) {
				currentValue.splice(indexToRemove, 1); // Use splice to remove the key from the array
				this.syncStorage(key);
			}
		}
	}

	clearSession = (): void => {
		this.unset('project');
		this.unset('homePageId');
		this.unset('interactionFilter');
	}

	setProperty = (key: string, property: string, value: any): void => {
		if (!this.values[key])
			this.values[key] = {};
		this.values[key][property] = ObjectUtils.copy(value);
		this.syncStorage(key);
	}

	getProperty = (key: string, property: string): any => {
		return ObjectUtils.copy(this.values[key]?.[property]);
	}

	setMaProperty = (key: string, maId: number, value: any): void => {
		this.setProperty(key, maId.toString(), ObjectUtils.copy(value));
	}

	getMaProperty = (key: string, maId: number): any => {
		let property = maId.toString();
		return ObjectUtils.copy(this.values[key][property]);
	}

	setRecentProject(maId: number, project: AccountOrWorkspaceProject) {
		if (!WorkspaceTransitionUtils.isWorkspaceProject(project)) {
			this.set(`project-maId-${maId}`, project);
		} else {
			this.set(`workspace-project-maId-${maId}`, project);
		}
	}

	getRecentProject(maId: number): IProjectSelection {
		return this.get(`project-maId-${maId}`);
	}

	getRecentWorkspaceProject(maId: number): WorkspaceProject {
		return this.get(`workspace-project-maId-${maId}`);
	}

	private syncStorage = (key: string): void => {
		if (this.localStorageService.isSupported) {
			if (this.values.hasOwnProperty(key)) {
				this.localStorageService.set(key, this.values[key]);
			} else {
				this.localStorageService.remove(key);
			}
		}
	}
}

app.service('sessionPreferences', downgradeInjectable(SessionPreferencesService));
