import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ColorFunctionService } from '@cxstudio/reports/coloring/color-function.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PaletteType, ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { WidgetColorPalette } from './entities/widget-color-palette';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ReportConstants } from '../report-constants.service';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export interface IColorSelectorPalette {
	name: string;
	type: PaletteType;
	displayName: string;
	order: number;
	disabled?: boolean;
	deleted?: boolean;
	replacement?: number;
	designerPalette?: boolean;
	colorPalette?: string[];
	customColor?: string;
	getColors?: () => string[];
	rawName?: string;
}

export interface IColorParent {
	name: string;
	customColor: string;
}

export class ColorSelectorController implements ng.IController {
	dropdownClass: string;

	visualProps: VisualProperties;
	properties: WidgetProperties;
	field: string;
	ignoredGroup: any;

	filter: PaletteType[];

	studioMetrics: Metric[];
	predefinedMetrics: Metric[];
	palettes: WidgetColorPalette[];

	providerColors: string[];
	parent: IColorParent;
	onChange: () => void;
	onInputChange: () => void;
	defaultColor: IColorSelectorPalette;

	private colorTypes: IColorSelectorPalette[];
	validPaletteOptions: IColorSelectorPalette[];
	selectedPalette: IColorSelectorPalette;

	customField: string;
	customFilter: (filter: IColorSelectorPalette) => boolean;

	constructor(
		private $scope: ISimpleScope,
		private colorUtils: ColorUtils,
		private locale: ILocale,
		private groupColorService,
		private calculationColorService,
		private $timeout: ng.ITimeoutService,
		private metricUtils,
		private colorFunctionService: ColorFunctionService,
		private applicationThemeService: ApplicationThemeService
	) {

	}

	$onInit(): void {
		if (!this.field)
			this.field = 'color';
		this.customField = ReportConstants.customColorFields[this.field];
		this.providerColors = this.providerColors || [];

		let noFilter = (...args) => true;
		this.customFilter = this.customFilter || noFilter;

		this.updateColorOptions(true, false);

		if (this.properties) {
			this.$scope.$watchCollection(() => this.properties.selectedAttributes, this.updateColorOptions );
			this.$scope.$watchCollection(() => this.properties.selectedMetrics, this.updateColorOptions );
		}
		if (this.studioMetrics)
			this.$scope.$watchCollection(() => this.studioMetrics, this.updateColorOptions );

		if (this.predefinedMetrics)
			this.$scope.$watchCollection(() => this.predefinedMetrics, this.updateColorOptions );

		this.$scope.$watchCollection(() => this.palettes, this.updateColorOptions );

		this.$scope.$watchCollection(() => this.filter, (newVal, oldVal) => {
			this.updateColorOptions(newVal, oldVal);
		});
		if (this.isDualChart() && this.field === 'color') {
			this.$scope.$watch(() => this.visualProps.subChartType, this.updateColorOptions );
		} else if (this.isDualChart() && this.field === 'secondaryColor') {
			this.$scope.$watch(() => this.visualProps.secondaryChartType, this.updateColorOptions );
		}

		this.$scope.$watch(() => this.visualProps[this.field], this.updateColorOptions );
		this.$scope.$watch(() => this.ignoredGroup, this.updateColorOptions );
	}

	fireChange = (callback: () => void) => {
		this.$timeout(() => {
			/* This seems to be the safest way to ignore callbacks from previous controller during visualization change.
				If we remove $timeout, it will start firing in a new controller, which also causes issues.
				This component needs a good refactoring to prevent it from firing events on external changes (right now
				it listens to selectedAttributes/Metrics, and changing them causes this callback to be called). */
			if (callback && !(this.$scope as ng.IScope).$$destroyed) {
				callback();
			}
		});
	}

	getColorDisplayName = (name: string) => {
		let color = _.find(this.colorTypes, {name});
		return color ? color.displayName : null;
	}

	showColorInput = (): boolean => {
		return this.visualProps[this.field] === ColorPaletteNames.CUSTOM;
	}

	private getColorArray = (colorName: string, custom: string): string[] => {
		return this.getColorArrayInternal(colorName, custom, this.parent);
	}

	private getColorArrayInternal(colorName: string, custom: string, parent?: IColorParent): string[] {
		let metricColorArray = this.colorFunctionService.getBuilder()
			.withMetrics(_.union(this.predefinedMetrics || [], this.studioMetrics || []))
			.withPalettes(this.palettes, this.providerColors)
			.buildColorArray(colorName);
		if (metricColorArray)
			return metricColorArray;
		let parentArray = parent ? this.getColorArrayInternal(parent.name, parent.customColor) : undefined;
		return this.colorUtils.getColorArray(colorName, custom, parentArray);
	}

	private colorFilter = (types: PaletteType[]): (item: IColorSelectorPalette) => boolean => { // gets types from arguments
		return (item: IColorSelectorPalette) => {
			let matches = _.contains(types, item.type);

			if (matches && item.name === 'provider' && !this.providerColors)
				return false;

			return matches;
		};
	}

	private isLineChart(): boolean {
		return this.isDualChart() &&
			this.visualProps.subChartType === ChartType.SPLINE &&
			this.field === 'color';
	}

	private isLineChartSecondary(): boolean {
		return this.isDualChart() &&
			this.visualProps.secondaryChartType === ChartType.SPLINE &&
			this.field === 'secondaryColor';
	}

	private isDualChart(): boolean {
		return this.visualProps && this.visualProps.visualization === WidgetVisualization.DUAL;
	}

	private updateColorOptions = (newValue, oldValue): void => {
		if (newValue === oldValue || !this.palettes)
			return;

		let isDarkMode = this.applicationThemeService.isShowingDarkTheme();

		let colors: IColorSelectorPalette[] = angular.copy(this.colorUtils.getColorTypes());

		colors = _.filter(colors, color => color.type !== PaletteType.PALETTE);
		let selectedColor = this.colorUtils.resolvePalette(this.palettes, this.visualProps[this.field]);
		let visiblePalettes = _.chain(this.palettes)
			.filter(palette => !palette.deleted && !palette.designerPalette)
			.filter(palette => palette.enabled || selectedColor?.name === palette.name)
			.map(palette => {
				return {
					id: palette.id,
					name: palette.name,
					displayName: palette.displayName,
					type: PaletteType.PALETTE,
					order: 10,
					colorPalette: isDarkMode && palette.darkModeColors || palette.colors
				} as IColorSelectorPalette;
			}).value();
		colors.pushAll(visiblePalettes);

		let designerPalette = this.getDesignerPalette();
		if (designerPalette)
			colors.push(designerPalette);

		if (this.properties) {
			let skipObjectBasedColor = false;
			if (this.isLineChart()) {
				if (!this.visualProps.attributeSelections ||
					!this.groupColorService.isGroupColorSupported(
						this.visualProps.attributeSelections.secondaryGroup)) {
					skipObjectBasedColor = true;
				}
			} else if (this.isLineChartSecondary()) {
				skipObjectBasedColor = true;
			}

			if (!skipObjectBasedColor && !this.properties.documentLevelOnly) {
				colors.pushAll(this.getCalculationColors());
			}

			if (this.properties.selectedAttributes && !this.properties.selectedAttributes.isEmpty()) {
				if (!skipObjectBasedColor) {
					let groupColors = this.groupColorService.getGroupColorOptions(this.getApplicableGroupings());
					colors = this.removeColorsByRawName(colors, _.map(groupColors, 'rawName'));
					colors.pushAll(groupColors);
				}
			}

		}
		colors.sort(this.colorUtils.getSortFunction());

		let allowedColorTypes = angular.copy(this.filter);
		// we can add folder type to everything, because folders should always be allowed
		allowedColorTypes.push(PaletteType.FOLDER);

		this.colorTypes = colors;
		this.validPaletteOptions = this.getAllowedPalettes(this.colorTypes, allowedColorTypes);
		this.validPaletteOptions.forEach(this.addGetColorFunction);

		// support defunct "solid" color, just in case
		if (this.visualProps[this.field] === PaletteType.SOLID) {
			ColorUtilsHelper.convertSolidToCustom(this.visualProps, this.field as ColorTypes);
		}

		let selected = _.findWhere(colors, { name: selectedColor ? selectedColor.name : this.visualProps[this.field] });

		if (!selected) {
			// look through folders
			selected = _.chain(colors)
				.filter({type: PaletteType.FOLDER} as any)
				.pluck('children')
				.flatten()
				.findWhere({name: this.visualProps[this.field]})
				.value();
		}
		if (!selected) {
			this.setCustomColor(this.colorUtils.getDefaultCustomColor(this.defaultColor));
			this.select(this.getDefaultSelection(colors));
		} else if (selected.name === ColorPaletteNames.CUSTOM && _.isUndefined(this.getCustomColor())) {
			selected.customColor = this.colorUtils.getDefaultCustomColor(this.defaultColor);
			this.setCustomColor(selected.customColor);
			this.selectedPalette = selected;
		} else {
			this.selectedPalette = selected;

		}
	}

	private getAllowedPalettes(palettes, allowedColorTypes: PaletteType[]): IColorSelectorPalette[] {
		return palettes.filter(palette => {
			if (palette.children) {
				// recurse over children
				palette.children = this.getAllowedPalettes(palette.children, allowedColorTypes);
				return true;
			}

			return !palette.designerPalette
				&& this.colorFilter(allowedColorTypes)(palette) // %%PROVIDER_PALETTE remove once provider's palette is moved to studio
				&& this.customFilter(palette);
		});
	}

	private getDesignerPalette(): IColorSelectorPalette {
		let palette = _.findWhere(this.palettes, {designerPalette: true});
		if (_.isEmpty(this.providerColors) || !palette)
			return null;
		return {
			id: palette.id,
			name: palette.name,
			displayName: palette.displayName,
			type: PaletteType.PROVIDER,
			order: 12,
			colorPalette: this.providerColors
		} as IColorSelectorPalette;
	}

	private getDefaultSelection(colors: IColorSelectorPalette[]): IColorSelectorPalette {
		let defaultMAPaletteName = _.findWhere(this.palettes, {defaultPalette: true}).name;
		let defaultSelection = _.findWhere(colors, {name: this.getDefaultPaletteName(defaultMAPaletteName)});
		if (!defaultSelection)
			defaultSelection = _.findWhere(colors, {name: ColorPaletteNames.PALETTE_1})
				|| _.findWhere(colors, {name: defaultMAPaletteName});
		return defaultSelection;
	}

	private getDefaultPaletteName(defaultMAPaletteName: string): string {
		let paletteName = this.colorUtils.getDefaultPalette(this.palettes, this.defaultColor, this.field);
		return paletteName || defaultMAPaletteName;
	}

	private getCustomColor(): string {
		return this.visualProps[this.customField];
	}

	private setCustomColor(color: string): void {
		this.visualProps[this.customField] = color;
	}

	private addGetColorFunction = (palette: IColorSelectorPalette): void => {
		palette.getColors = () => {
			if (palette.colorPalette) {
				return palette.colorPalette;
			}
			return this.getColorArray(palette.name, this.getCustomColor()
				|| (this.defaultColor && this.defaultColor.customColor));
		};
	}

	private getHierarchyIdsFromMetric = (metric): number[] => {

		if (!metric.definition.mathComponents) {
			return [];
		}

		return metric.definition.mathComponents
				.filter(component => component.type === ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC)
				.map(component => component.hierarchyId);
	}

	private getIdsFromHierarchyAttributes = (): number[] => {

		if (!this.properties.selectedAttributes) {
			return [];
		}

		return this.properties.selectedAttributes
			.filter(attribute => !!attribute)
			.filter(attribute => attribute.type === 'hierarchyModel')
			.map(attribute => Number(attribute.name));
	}


	private getCalculationColors(): IColorSelectorPalette[] {

		let validColorableMetrics: Metric[] = this.studioMetrics
			? _.filter(this.studioMetrics, this.colorUtils.isColorableMetric)
			: [];

		const attributeHierarchyIds = this.getIdsFromHierarchyAttributes();
		validColorableMetrics = validColorableMetrics.filter(metric => {
			let metricHierarchies = this.getHierarchyIdsFromMetric(metric);
			return metricHierarchies.every(hierarchyId => attributeHierarchyIds.includes(hierarchyId));
		});

		let colorMetricDefinitions = this.calculationColorService.getCalculationColorOptions(validColorableMetrics);

		let predefinedColors = this.getPredefinedCalculationColors();
		predefinedColors.forEach(this.addGetColorFunction);
		colorMetricDefinitions.forEach(this.addGetColorFunction);

		let result = [];
		if (this.field !== 'popColor' && this.field !== 'secondaryPopColor') {
			let metricColorFolder = {
				type: PaletteType.FOLDER,
				displayName: this.locale.getString('preview.colByMetric'),
				children: colorMetricDefinitions,
				order: 1000 // always at the bottom
			};
			if (colorMetricDefinitions.length) result.push(metricColorFolder);

			result.pushAll(predefinedColors);
		}
		return result;
	}

	private getPredefinedCalculationColors(): IColorSelectorPalette[] {
		let predefinedMetrics =
			this.predefinedMetrics && this.metricUtils.toPredefinedGroupBy(this.predefinedMetrics);
		let predefinedColors: any[] = this.calculationColorService.getPredefinedColorOptions(predefinedMetrics);
		if (this.ignoredGroup) {
			_.each(predefinedColors, option => {
				if (option.rawName === this.ignoredGroup.rawName) {
					// group is without "_calculation."" prefix
					option.disabled = !option.name.contains(this.ignoredGroup.name);
				}
			});
		}
		return predefinedColors;
	}

	private getApplicableGroupings(): any[] {
		let groupingFilter;
		if (this.ignoredGroup) {
			// CSI-8708 do not show stacked grouping colors for non-stacked secondary axis
			groupingFilter = (item: AttributeGrouping) => {
				return item && item.name !== this.ignoredGroup.name;
			};
		}
		return _.filter(this.properties.selectedAttributes, groupingFilter);
	}

	private removeColorsByRawName(colors: IColorSelectorPalette[], rawNames: string[]): IColorSelectorPalette[] {
		// removes calculation colors which should be replaced with grouping
		let filterFunc = item => {
			return !_.contains(rawNames, item.rawName);
		};
		let result = _.filter(colors, filterFunc);
		let folders = _.filter(result, {type: PaletteType.FOLDER} as any);
		_.each(folders, (folder: any) => folder.children = _.filter(folder.children, filterFunc));
		return result;
	}

	select = (palette: IColorSelectorPalette) => {
		if (!palette || palette.disabled) {
			return;
		}

		this.visualProps[this.field] = palette.name;
		this.selectedPalette = palette;
		if (palette.name === 'custom') {
			this.setCustomColor(this.getCustomColor() || this.colorUtils.getDefaultCustomColor(this.defaultColor));
		}
		this.fireChange(this.onChange);
	}

	onColorInputChange = () => {
		this.fireChange(this.onInputChange);
	}
}

app.component('colorSelector', {
	controller: ColorSelectorController,
	bindings: {
		//css
		dropdownClass: '@',

		dropdownDisabled: '<?',

		visualProps: '<', // visual properties object
		properties: '<', //properties
		field: '@', // property to change
		ignoredGroup: '<',

		filter: '<', // color type filter array

		studioMetrics: '<',
		predefinedMetrics: '<',
		palettes: '<',

		providerColors: '<',
		parent: '<',
		onChange: '&',
		onInputChange: '&',
		defaultColor: '<',
		customFilter: '<'
	},
	template: `
		<div class="dropdown br-color-dropdown" ng-class="$ctrl.dropdownClass" dropdown-position="auto">
			<hierarchy-dropdown
				class="w-100-percent"
				ng-disabled="$ctrl.dropdownDisabled"
				selected-item="$ctrl.selectedPalette"
				search-placeholder="{{::'widget.findPalette'|i18n}}"
				hierarchy-list="$ctrl.validPaletteOptions"
				display-property="displayName"
				on-node-click="$ctrl.select(node)"
				evaluate-current-value="$ctrl.getColorDisplayName($ctrl.visualProps[$ctrl.field])"
				selected-item-template="partials/custom/palette-selected-item-template.html"
				item-template="partials/custom/palette-item-template.html">
			</hierarchy-dropdown>
		</div>
		<div class="grouping-options"></div>
		<color-input ng-if="$ctrl.showColorInput()"
			class="br-color-input d-flex w-100-percent mt-8"
			ng-model="$ctrl.visualProps[$ctrl.customField]"
			ng-model-options="{debounce:500}"
			ng-change="$ctrl.onColorInputChange($event)"
			[show-swatch]="false">
		</color-input>
	`
});
