
/**
 * Simple directive to detect whether element (or its child) scroll position is on the edge
 * (currently only for vertical case)
 */
app.directive('scrollEdgeChange', () => {
	return {
		link: (scope, elem, attrs) => {
			let lastState = true;
			let targetElement = getTargetElement();
			targetElement.on('scroll', (e) => {
				let isEdge = targetElement.scrollTop() === 0;
				if (isEdge !== lastState) {
					lastState = isEdge;
					scope.$eval(attrs.scrollEdgeChange, {$isEdge: isEdge});
					scope.$apply();
				}
			});

			function getTargetElement(): JQuery {
				let targetSelector = attrs.scrollSelector;
				if (targetSelector)
					return elem.find(targetSelector);
				else return elem;
			}
		}
	};
});
