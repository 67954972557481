import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class ExpressionItem {
	type: ExpressionPieces;
	displayName: string;
	name?: string;
	value?: string;
	nextItemIsError?: boolean;

	constructor(type: ExpressionPieces, displayName: string, value?: string) {
		this.type = type;
		this.displayName = displayName;
		this.value = value;
	}
}