import { ChangeDetectionStrategy, Component, Input, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { HierarchyEnrichmentProperty, OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyComponentInput } from '../../hierarchy-component-input';


@Component({
	templateUrl: './hierarchy-enrichment-attribute-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyEnrichmentAttributeSelectorComponent
	implements OnInit, IModalComponent<HierarchyComponentInput> {

	@Input() input: HierarchyComponentInput;

	loading: Promise<any>;
	availableAttributes: any[];
	selectedAttributes: any[];

	constructor(
		private organizationApiService: OrganizationApiService,
		private modal: NgbActiveModal,
		private readonly locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.availableAttributes = [];
		this.selectedAttributes = [];

		this.loading = this.organizationApiService.getOrganizationCalculations(this.input.hierarchy.id, { numericOnly: true })
			.then((attributes: HierarchyEnrichmentProperty[]) => {

				let availableAttributes = [];
				let selectedAttributes = [];

				let id = 0;
				attributes.forEach(attr => {
					let reportingAttribute = {
						name: attr.propertyName,
						displayName: attr.propertyName,
						selected: attr.reportable,
						numeric: attr.numeric,
						id: id++
					};

					if (attr.reportable) {
						selectedAttributes.push(reportingAttribute);
					}

					availableAttributes.push(reportingAttribute);

				});

				this.availableAttributes = availableAttributes;
				this.selectedAttributes = selectedAttributes;
				this.loading = null;
			});
	}

	cancel(): void {
		this.modal.dismiss();
	}

	done(): void {
		let properties: HierarchyEnrichmentProperty[] = [];
		this.availableAttributes.forEach(param => {
			let selected: boolean = false;
			if (_.find(this.selectedAttributes, { name: param.name })) {
				selected = true;
			}
			properties.push({ propertyName: param.name, reportable: selected, numeric: param.numeric });
		});

		this.loading = this.organizationApiService.setOrganizationReportableParameters(this.input.hierarchy.id, properties)
			.then((result) => {
				this.modal.dismiss();
			}) as any;
	}

	updateSelectedAttributes(attributes: HierarchyEnrichmentProperty[]): void {
		this.selectedAttributes = attributes;
	}

	availableAttributesLabelCallback(): string {
		return this.locale.getString('organization.availableParameters');
	}

	selectedAttributesLabelCallback(): string {
		return this.locale.getString('organization.selectedParameters');
	}


}
