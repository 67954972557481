<projects-access
	*ngIf="!isWorkspaceEnabled && contentProvidersWithAccounts"
	[user]="user"
	[license]="license"
	[licenses]="licenses"
	[allowDisabled]="true"
	[contentProvidersWithAccounts]="contentProvidersWithAccounts"
	[sourceUserId]="sourceUserId"
	(changeProjectAccess)="changeProjectAccess.emit($event)"
></projects-access>

<workspaces-projects-access
	*ngIf="isWorkspaceEnabled"
	[user]="user"
	[license]="license"
	[sourceUserId]="sourceUserId"
	(accessChange)="accessChange.emit($event)"
></workspaces-projects-access>
