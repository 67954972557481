import { TimelineItem } from '@app/modules/widget-container/widget-stats/timeline-item';
import * as _ from 'underscore';

export enum TimelineType {
	FRONTEND = 'frontend',
	BACKEND = 'backend',
	ANALYTICS = 'analytics'
}

export class Timeline {
	type: TimelineType;
	items: TimelineItem[];
	total: number;

	constructor(type: TimelineType, items: TimelineItem[]) {
		this.type = type;
		this.items = items;
		this.total = _.chain(items)
			.filter(item => item.visible)
			.pluck('value')
			.reduce((sum, item) => sum + item, 0)
			.value();
	}
}
