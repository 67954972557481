import * as _ from 'underscore';
import {BetaFeatureTracker} from './beta-feature-tracker-class';
import {BetaFeature} from './beta-feature';
import BetaFeatureState from './beta-feature-state';
import { Caches } from '@cxstudio/common/caches';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import * as moment from 'moment/moment';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { LocallyStoredItems } from '@app/core/storage/locally-stored-items';

@Injectable({ providedIn: 'root' })
export class BetaFeaturesService {

	private betaFeatures = {};

	constructor(
		private readonly http: CxHttpService,
		private readonly httpHandlers: CxHttpHandlers,
		private readonly sessionPreferencesService: SessionPreferencesService
	) {
		// initialize all beta features to unresolved promises
		BetaFeature.values().forEach((betaFeature) => {
			this.betaFeatures[betaFeature.id] = new BetaFeatureState(betaFeature.id, false, false, false);
		});
	}

	// resolve initialized promises with actual values as needed
	init = (passedBetaFeatures: any): void => {
		BetaFeature.values().forEach((betaFeature) => {
			let actualBetaFeature = passedBetaFeatures[betaFeature.id];
			if (!actualBetaFeature) return;

			this.betaFeatures[betaFeature.id] = actualBetaFeature;
		});
	}

	setBetaFeatureState(betaFeature: BetaFeatureTracker, enabled: boolean): Promise<void> {
		return this.http.put(`rest/beta-feature/${betaFeature.id}/${enabled ? 'enable' : 'disable'}`, undefined)
			.then(() => {
				this.betaFeatures[betaFeature.id].switchOn = enabled;
				return this.refreshUserFeatureState(betaFeature);
			})
			.then(this.httpHandlers.invalidateCacheHandler(Caches.WIDGET_TEMPLATES));
	}

	refreshUserFeatureState = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.http.get(`rest/beta-feature/${betaFeature.id}/user-state`)
			.then(userState => {
				this.betaFeatures[betaFeature.id].enabledForUser = userState;
			});
	}

	getFeature = (betaFeature: BetaFeatureTracker): BetaFeatureState => {
		return this.betaFeatures[betaFeature.id];
	}

	isFeatureEnabled = (betaFeature: BetaFeatureTracker): boolean => {
		const actualBetaFeature = this.getFeature(betaFeature);

		return actualBetaFeature.enabledForUser;
	}

	isAnyFeatureEnabled = (...betaFeatures: BetaFeatureTracker[]): boolean => {
		return _.chain(betaFeatures)
			.map((feature) => this.getFeature(feature))
			.some((feature) => feature.enabledForUser)
			.value();
	}

	requestFeature(betaFeature: BetaFeatureTracker): Promise<void> {
		return this.http.post(`rest/beta-feature/${betaFeature.id}/request`, undefined)
			.then(() => {
				this.sessionPreferencesService.push(
					LocallyStoredItems.betaFeaturesRequested.storageName,
					{
						featureId: betaFeature.id,
						ttl: moment().add(48, 'hours').valueOf()
					},
					'featureId',
					true,
				);
			});
	}
}

app.service('betaFeaturesService', downgradeInjectable(BetaFeaturesService));
