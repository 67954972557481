import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import LinkedFilterContainer from '@cxstudio/dashboards/widgets/linked-filters-container';
import Widget from '@cxstudio/dashboards/widgets/widget';

export interface WidgetLinkingItem {
	id: number;
	linkedWidgets: number[];
}

export class WidgetLinkingAction implements DashboardChangeAction {
	private before: WidgetLinkingItem[];
	private after: WidgetLinkingItem[];

	static fromDifference(before: WidgetLinkingItem[], after: WidgetLinkingItem[]) {
		if (before.length !== after.length)
			throw new Error(`Incorrect linking data: ${before.length} vs ${after.length}`);
		let changes = {
			before: [],
			after: [],
		};
		before.forEach((item, index) => {
			let other = after[index];
			if (!_.isEqual(item.id, other.id)) {
				throw new Error(`Incorrect linking data, wrong positions`);
			}
			if (!_.isEqual(item.linkedWidgets, other.linkedWidgets)) {
				changes.before.push(item);
				changes.after.push(other);
			}
		});
		return _.isEmpty(changes.before) ? null : new WidgetLinkingAction(changes.before, changes.after);
	}

	constructor(
		before: WidgetLinkingItem[],
		after: WidgetLinkingItem[]
	) {
		this.before = ObjectUtils.copy(before);
		this.after = ObjectUtils.copy(after);
	}

	reverse(): DashboardChangeAction {
		return new WidgetLinkingAction(this.after, this.before);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let widgetLinkingData: {[widgetId: number]: number[]} = {};
		_.each(this.after, (linkingItem) => {
			let widgetId = idMapper.getCurrentId(linkingItem.id);
			let linkedWidgets = _.map(linkingItem.linkedWidgets, originalId => idMapper.getCurrentId(originalId));
			widgetLinkingData[widgetId] = linkedWidgets;
		});
		return api.updateWidgetLinking(widgets[0].dashboardId, widgetLinkingData).then(() => {
			_.each(widgetLinkingData, (linkedWIdgets, widgetId) => {
				let index = WidgetActionUtils.findWidgetIndexById(widgets, Number(widgetId), idMapper);
				widgets[index].linkedWidgets = linkedWIdgets;
			});
			LinkedFilterContainer.initWidgetLinking(widgets);
		}).catch((err) => {
			console.error(err);
		});
	}
}
