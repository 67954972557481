import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';
import { SearchMode } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { PreviewPredefinedColumns, PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DrillProcessor } from '@cxstudio/reports/utils/contextMenu/drill/drill-to/drill-processor.class';
import { CommonDrillService } from '../common-drill.service';

@Injectable({
	providedIn: 'root'
})
export class DrillToSentenceProcessorService extends DrillProcessor {

	previewSpecificProperties = [
		'snippets', 'page', 'itemsPerPage', 'dateRangeP1', 'analyticFeedbackSelection',
		'initialSentence', 'sentenceSearch', 'previewMode', 'documentExplorer'
	];

	reportLevelSpecificProperties = ['documentLevelOnly'];

	inheritedProperties = this.previewSpecificProperties
		.concat(CommonInherentProperties.preview).concat(this.reportLevelSpecificProperties);

	constructor(
		private readonly locale: CxLocaleService,
		private readonly commonDrill: CommonDrillService,
		@Inject('previewPredefinedColumns') private readonly previewPredefinedColumns: PreviewPredefinedColumns,
	) {
		super();
	}

	drillTo(widget: DocumentExplorerWidget, targetType, point: DrillPoint): DocumentExplorerWidget {
		widget.properties.page = {
			start: 0,
			lookAheadLimit: 100,
			sortMetric: {
				direction: AnSortDirection.DESC,
				displayName: PreviewSortAttributes.DOC_DATE
			}
		};

		if (point.id && point.id === point.verbatimId) {
			widget.properties.sentenceSearch = {
				searchMode: SearchMode.VERBATIM,
				searchId: point.verbatimId
			};
		} else {
			widget.properties.initialSentence = point.id as number;
		}

		//replace name and visual
		widget.name = WidgetType.PREVIEW;
		widget.properties.widgetType = WidgetType.PREVIEW;
		widget.properties.snippets = false;


		if (_.isUndefined(widget.properties.itemsPerPage)) {
			widget.properties.itemsPerPage = 20;
		}

		widget.properties.previewMode = PreviewMode.SENTENCES;
		if (targetType === 'an_doc_explorer') {
			widget.displayName =
				this.locale.getString('widget.preview') +
				this.locale.getString('widget.drillForLabel', widget);
			widget.properties.documentExplorer = true;
			widget.properties.previewMode = PreviewMode.DOCUMENT;
			widget.visualProperties = {
				visualization: WidgetVisualization.DOCUMENT_PREVIEW,
				sentimentHighlightingEnabled: true
			};
		} else {
			let columns = _.union(this.previewPredefinedColumns.getPredefinedMetricColumns(), [
				this.previewPredefinedColumns.get(PreviewColumn.SOURCE),
				this.previewPredefinedColumns.get(PreviewColumn.SENTENCE),
				this.previewPredefinedColumns.get(PreviewColumn.DOC_DATE)
			]);
			widget.visualProperties = {
				visualization: WidgetVisualization.PREVIEW_TABLE,
				itemsPerPage: widget.properties.itemsPerPage,
				sentimentHighlightingEnabled: true,
				showSampleSize: true,
				columns
			};
		}

		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);

		widget = this.inheritProperties(widget);

		return widget;
	}

	getInheritedProperties(): string[] {
		return this.inheritedProperties;
	}

	getInheritedVisualProperties(...args): string[] {
		throw new Error('Method not required by this visualization, and should not be called');
	}
}
