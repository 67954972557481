import { Inject, Injectable } from '@angular/core';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import * as Highcharts from 'highcharts';

export type ChartCallback = (chart: Highcharts.Chart) => void;
@Injectable({
	providedIn: 'root'
})
export class HighchartsRendererService {

	constructor(
		@Inject('highchartsAccessibilityUtils') private highchartsAccessibilityUtils: HighchartsAccessibilityUtils,
		@Inject('pointSelectionUtils') private pointSelectionUtils: PointSelectionUtils,
	) { }

	renderChart(chartOptions: Highcharts.Options, element: HTMLElement, callback?: ChartCallback): any {
		chartOptions.chart.renderTo = element;
		return new Highcharts.Chart(chartOptions, callback);
	}

	pointHighlightingCallback(utils: WidgetUtils, demo: boolean): ChartCallback {
		return (chart: Highcharts.Chart) => {
			this.highchartsAccessibilityUtils.applyLegendBorder(chart);
			let highlightFn = this.pointSelectionUtils.highlightSelectedPoints(utils.containerId, utils.widgetId,
				utils.getGroupings(), demo);
			highlightFn(chart);
		};
	}

	compositeCallback(...callbacks: ChartCallback[]): ChartCallback {
		return (chart: Highcharts.Chart) => {
			_.each(callbacks, callback => callback(chart));
		};
	}
}
