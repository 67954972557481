import { PipeTransform, Pipe, Inject } from '@angular/core';
import { AttributeValues } from '@app/modules/project/attribute/attribute-values';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';

@Pipe({
	name: 'explorerAttributeFilter'
})
export class ExplorerAttributeFilterPipe implements PipeTransform {

	constructor(@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService) {}

	transform(attributes: AttributeValues[], isDocExplorer: boolean, showEmpty: boolean): AttributeValues[] {
		if (!attributes) return [];

		return _.filter(attributes, attribute =>
			this.enrichmentAttributesService.isAttributeVisible(attribute, isDocExplorer, showEmpty));
	}
}
