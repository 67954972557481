import { Component, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QualtricsApiProvider } from './qualtrics-api-provider';
import { ObjectUtils } from '@app/util/object-utils';
import { OnDestroy } from '@angular/core';

export interface QualtricsApiProviderEditDialogInput {
	apiProvider: QualtricsApiProvider;
}

@Component({
	selector: 'qualtrics-api-provider-edit-dialog',
	templateUrl: './qualtrics-api-provider-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class QualtricsApiProviderEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() input: QualtricsApiProviderEditDialogInput;
	@ViewChild('apiProviderForm') public apiProviderForm: NgForm;

	invalidFields: any[] = [];
	apiProvider: QualtricsApiProvider;

	private apiProviderFormStatus$: Subscription;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly modal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
		this.apiProvider = ObjectUtils.copy(this.input.apiProvider);
	}

	ngAfterViewInit(): void {
		this.apiProviderFormStatus$ = this.apiProviderForm.statusChanges.subscribe(() => {
			let controls = this.apiProviderForm.controls;
			this.invalidFields = Object.keys(controls)
				.filter(key => (!controls[key].valid && controls[key].status !== 'DISABLED'));
		});
		this.ref.markForCheck();
	}

	ngOnDestroy(): void {
		if (this.apiProviderFormStatus$)
			this.apiProviderFormStatus$.unsubscribe();
	}

	cancel = (): void => {
		this.modal.dismiss();
	}

	save = () => {
		this.modal.close(this.apiProvider);
	}

	isSettingsValid = () => {
		return this.invalidFields.length === 0;
	}
}
