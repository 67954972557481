import { Timeline, TimelineType } from '@app/modules/widget-container/widget-stats/timeline';
import { TimelineItem } from '@app/modules/widget-container/widget-stats/timeline-item';
import { TimingType, WidgetTiming } from '@cxstudio/reports/timing/widget-timing';
import * as _ from 'underscore';

interface IBreakdownType {
	type: TimingType;
	timeline: TimelineType;
	label?: string;
}

export interface ITimeToRunParts {
	totalTime: number;
	parts: Timeline[];
}

export class TimeToRunUtils {

	private static TIME_BREAKDOWN: IBreakdownType[] = [
		{type: TimingType.initialization, timeline: TimelineType.FRONTEND},
		{type: TimingType.widgetPreparation, timeline: TimelineType.FRONTEND},
		{type: TimingType.waitingInQueue, timeline: TimelineType.FRONTEND},
		{type: TimingType.studioNetworkTransferRequest, timeline: TimelineType.FRONTEND, label: 'timeline_transfer'},

		{type: TimingType.studioOther, timeline: TimelineType.BACKEND},
		{type: TimingType.studioDataPreprocessing, timeline: TimelineType.BACKEND},
		{type: TimingType.platformNetworkTransferRequest, timeline: TimelineType.BACKEND, label: 'timeline_transfer'},
		{type: TimingType.platformOther, timeline: TimelineType.BACKEND},
		{type: TimingType.platformDataPreprocessing, timeline: TimelineType.BACKEND},

		{type: TimingType.analyticsNetworkTransferRequest, timeline: TimelineType.BACKEND, label: 'timeline_transfer'},
		{type: TimingType.analyticsExecution, timeline: TimelineType.ANALYTICS},
		{type: TimingType.analyticsNetworkTransferResponse, timeline: TimelineType.BACKEND, label: 'timeline_transfer'},

		{type: TimingType.platformDataPostprocessing, timeline: TimelineType.BACKEND},
		{type: TimingType.platformNetworkTransferResponse, timeline: TimelineType.BACKEND, label: 'timeline_transfer'},

		{type: TimingType.studioDataPostprocessing, timeline: TimelineType.BACKEND},

		{type: TimingType.studioNetworkTransferResponse, timeline: TimelineType.FRONTEND, label: 'timeline_transfer'},
		{type: TimingType.widgetRendering, timeline: TimelineType.FRONTEND},
	];

	static getTotal(widgetTiming: WidgetTiming): number {
		return _.reduce(widgetTiming.timings, (sum, item) => sum + item.time, 0);
	}

	static getParts(widgetTiming: WidgetTiming): Timeline[] {
		let totalTime = this.getTotal(widgetTiming);
		return [
			this.getTimeline(widgetTiming, totalTime, TimelineType.FRONTEND),
			this.getTimeline(widgetTiming, totalTime, TimelineType.BACKEND),
			this.getTimeline(widgetTiming, totalTime, TimelineType.ANALYTICS),
		];
	}

	private static getTimeline(widgetTiming: WidgetTiming, totalTime: number, timelineType: TimelineType): Timeline {
		let timelineItems = _.chain(this.TIME_BREAKDOWN)
			.map(item => {
				let timing = _.findWhere(widgetTiming.timings, {type: item.type});
				if (!timing)
					return TimelineItem.empty();
				let visible = item.timeline === timelineType;
				let label = item.label || `timeline_${item.type}`;
				return new TimelineItem(timing.time, timing.time / totalTime, `widget.${label}`, visible);
			})
			.value();
		return new Timeline(timelineType, timelineItems);
	}
}
