import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';

export class AnalyticsCacheOptions {
	CACHED = { apiValue: 'Cache', displayName: this.locale.getString('widget.statsModeReportCached') };
	NOT_CACHED = { apiValue: 'NoCache', displayName: this.locale.getString('widget.statsModeReportNotCached') };
	PARTIALLY_CACHED = { apiValue: 'PartialCache',
		displayName: this.locale.getString('widget.statsModeReportPartiallyCached') };

	UNKNOWN_VALUE = { apiValue: '', displayName: '' };
	values = [
		this.CACHED, this.NOT_CACHED, this.PARTIALLY_CACHED
	];
	constructor(
		private locale: ILocale,
	) {}

	byApiValue = (apiValue: string) => {
		let filtered = this.values.filter((option) => {
			return option.apiValue === apiValue;
		});
		return filtered.length > 0 ? filtered[0] : this.UNKNOWN_VALUE;
	}

}

app.service('analyticsCacheOptions', AnalyticsCacheOptions);
