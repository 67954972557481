<menu #ngDropdown ngbDropdown
	[autoClose]="'outside'"
	[display]="'static'"
	(openChange)="onMenuToggle($event)"
	class="dropdown br-actions-dropdown d-flex pl-0 mv-0">
	<button #ngDropdownToggle
		ngbDropdownToggle
		(keydown)="onMenuToggleKeydown($event)"
		[attr.aria-expanded]="isOpen()"
		[attr.aria-label]="getAriaLabel()"
		aria-haspopup="true"
		class="q-icon-dots icon-rotate-90 btn btn-icon border-0 kb-focusable"
		data-testid="dashboard-actions-menu"
		title="{{'common.options'|i18n}}"></button>
	<ul ngbDropdownMenu class="dropdown-menu pull-right" role="menu">

		<li *ngFor="let menuItem of menuItems"
			(click)="!menuItem.disabled && onItemClick(menuItem, $event)"
			(keydown)="!menuItem.disabled && onMenuKeydown(menuItem, $event)"
			[ngClass]="{'dropdown-submenu': menuItem.items, 'on-click': menuItem.items, 'open-menu': menuItem.open, 'divider': menuItem.isDivider }"
			[attr.role]="menuItem.isDivider ? 'separator' : 'menuitem'">
			<a href="javascript:void(0)" class="option"
				[attr.aria-haspopup]="!!menuItem.items"
				*ngIf="!menuItem.isDivider && !menuItem.component"
				[class.disabled]="menuItem.disabled"
				[ngClass]="[menuItem.name, menuItem.items ? 'has-submenu' : '']">
				<span class="q-icon q-icon-angle-left menu-icon" *ngIf="menuItem.items" aria-hidden="true"></span>
				{{menuItem.text}}
			</a>
			<ul class="dropdown-menu dropdown-left" *ngIf="menuItem.items && !menuItem.component">
				<li *ngFor="let subMenuItem of menuItem.items" (click)="onItemClick(subMenuItem, $event)">
					<a href="javascript:void(0)" class="option submenu-option" [ngClass]="[subMenuItem.name]">{{subMenuItem.text}}</a>
				</li>
			</ul>

			<div *ngIf="menuItem.component" [ngSwitch]="menuItem.name">
				<dashboard-theme-toggle *ngSwitchCase="'dark-mode-toggle'" [disabled]="menuItem.disabled"></dashboard-theme-toggle>
				<pattern-fills-toggle *ngSwitchCase="'pattern-fills'" [disabled]="menuItem.disabled"></pattern-fills-toggle>
				<experimental-ui-toggle *ngSwitchCase="'experimental-ui'"></experimental-ui-toggle>
				<ng-container *ngSwitchCase="'zoom'">
					<dashboard-zoom *ngIf="zoom !== undefined" [selectedZoomLevel]="zoom"></dashboard-zoom>
				</ng-container>
				<ng-container *ngSwitchCase="'widget-options'">
					<widget-movement-options *ngIf="widgetMovementOption !== undefined"
						(onWidgetMovementChange)="widgetMovementChange($event)"
						[widgetMovementOption]="widgetMovementOption"></widget-movement-options>
				</ng-container>
			</div>
		</li>
	</ul>
</menu>
