import {
	NarrativeSettingsGridDefinition
} from '@app/modules/account-administration/automated-narrative/narrative-settings-grid-definition.service';
import {
	ConversationSettingsGridDefinition
} from '@app/modules/account-administration/conversation-settings/conversation-settings-grid-definition.service';
import { HomePageGridDefinition } from '@app/modules/home-page/home-page-management/home-page-grid-definition.service';
import {
	QuickInsightsDashboardGridDefinition
} from '@app/modules/home-page/quick-insights/quick-insights/quick-insights-dashboard-grid-definition.service';
import { SampledAuditsGridDefinition } from '@app/modules/sampled-audits/sampled-audits-grid-definition.service';
import {
	InteractionExplorerGridDefinition
} from '@app/modules/interaction-explorer/interaction-explorer-grid-definition.service';
import AlertSubscriptionTemplateGridDefinition
	from '@app/modules/alert-subscription-template/services/alert-subscription-template-grid-definition.service';
import AlertGridDefinition from '@app/modules/alert/services/alert-grid-definition.service';
import StudioAlertGridDefinition from '@app/modules/alert/services/studio-alert-grid-definition.service';
import TemplatesGridDefinition from '@app/modules/dashboard-templates/services/templates-grid-definition.service';
import DriversGridDefinition from '@app/modules/drivers/services/drivers-grid-definition.service';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import
	ColorPaletteGridDefinition
	from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-grid-definition.service';
import MetricGridDefinition from '@cxstudio/metrics/metric-grid-definition';
import MobileSettingsGridDefinition from '@app/modules/mobile/mobile-settings/mobile-settings-grid-definition.service';
import {
	HiddenGroupAssetsGridDefinition
} from '@app/modules/project/hidden-group-asset/hidden-group-assets-grid-definition';
import FilterGridDefinitionService from '@app/modules/filter/services/filter-grid-definition.service';
import DashboardGridDefinitionService from '@app/modules/dashboard-list/services/dashboard-grid-definition.service';
import UsersBulkDataAccessGridDefinition
	from '@app/modules/user-bulk/grid-definitions/user-bulk-data-access-grid-definition.service';
import GroupsUserGridDefinition from '@app/modules/user-bulk/grid-definitions/groups-user-grid-definition.service';
import UsersGroupGridDefinition from '@app/modules/user-bulk/grid-definitions/users-group-grid-definition.service';
import UsersBulkPermissionsGridDefinition
	from '@app/modules/user-bulk/grid-definitions/users-bulk-permissions-grid-definition.service';
import {
	SamlIdentityProvidersGridDefinition
} from '@app/modules/system-administration/saml-settings/saml-identity-providers/miscellaneous/saml-identity-providers-grid-definition.service';
import {
	SamlServiceProvidersGridDefinition
} from '@app/modules/system-administration/saml-settings/saml-service-providers/miscellaneous/saml-service-providers-grid-definition.service';
import {
	MasterAccountsGridDefinition
} from '@app/modules/system-administration/master-account/master-accounts-table/miscellaneous/master-accounts-grid-definition.service';
import {
	EmbeddedWidgetGridDefinition
} from '@app/modules/account-administration/embedded-widget/embedded-widget-grid-definition.service';
import { ArchiveGridDefinition } from '@app/modules/archive/archive-page/archive-grid-definition.service';
import {
	SecurityAuditGridDefinitionService
} from '@app/modules/account-administration/security-audit/security-audit-table/security-audit-grid-definition.service';
import {
	BetaFeaturesGridDefinition
} from '@app/modules/account-administration/beta-features-tab/beta-features-grid-definition.service';
import {
	BetaFeaturesChecklistGridDefinition
} from '@app/modules/system-administration/master-account/beta-features-checklist/beta-features-checklist-grid-definition.service';
import {
	WorkspacesGridDefinition
} from '@app/modules/system-administration/platform/workspace/workspace-management/workspaces-grid-definition.service';
import { ModelsGridDefinitionService } from '@app/modules/project/model/models-grid-definition.service';
import { AttributesGridDefinitionService } from '@app/modules/project/attribute/attributes-grid-definition.service';
import { ScorecardsGridDefinitionService } from '@app/modules/project/scorecard/scorecards-grid-definition.service';
import ScheduleGridDefinition
	from '@app/modules/schedules/services/schedule-grid-definition.service';
import {
	CalendarFormatGridDefinition
} from '@app/modules/calendar/calendar-format/calendar-format-grid-definition.service';
import DashboardTemplatesGridDefinition
	from '@app/modules/unified-templates/unified-templates-management/dashboard-templates-management/dashboard-templates-grid-definition.service';

export class GridDefinitionFactory {

	constructor(
		private dashboardGridDefinition: DashboardGridDefinitionService,
		private filterGridDefinition: FilterGridDefinitionService,
		private metricGridDefinition: MetricGridDefinition,
		private alertGridDefinition: AlertGridDefinition,
		private alertSubscriptionTemplateGridDefinition: AlertSubscriptionTemplateGridDefinition,
		private driversGridDefinition: DriversGridDefinition,
		private templatesGridDefinition: TemplatesGridDefinition,
		private scheduleGridDefinition: ScheduleGridDefinition,
		private attributesGridDefinition: AttributesGridDefinitionService,
		private modelsGridDefinition: ModelsGridDefinitionService,
		private scorecardsGridDefinition: ScorecardsGridDefinitionService,
		private studioAlertGridDefinition: StudioAlertGridDefinition,
		private hiddenGroupAssetsGridDefinition: HiddenGroupAssetsGridDefinition,
		private usersGroupGridDefinition: UsersGroupGridDefinition,
		private usersBulkPermissionsGridDefinition: UsersBulkPermissionsGridDefinition,
		private colorPaletteGridDefinition: ColorPaletteGridDefinition,
		private mobileSettingsGridDefinition: MobileSettingsGridDefinition,
		private usersBulkDataAccessGridDefinition: UsersBulkDataAccessGridDefinition,
		private conversationSettingsGridDefinition: ConversationSettingsGridDefinition,
		private interactionExplorerGridDefinition: InteractionExplorerGridDefinition,
		private narrativeSettingsGridDefinition: NarrativeSettingsGridDefinition,
		private groupsUserGridDefinition: GroupsUserGridDefinition,
		private homePageGridDefinition: HomePageGridDefinition,
		private quickInsightsDashboardGridDefinition: QuickInsightsDashboardGridDefinition,
		private sampledAuditsGridDefinition: SampledAuditsGridDefinition,
		private samlIdentityProvidersGridDefinition: SamlIdentityProvidersGridDefinition,
		private samlServiceProvidersGridDefinition: SamlServiceProvidersGridDefinition,
		private embeddedWidgetGridDefinition: EmbeddedWidgetGridDefinition,
		private archiveGridDefinition: ArchiveGridDefinition,
		private masterAccountsGridDefinition: MasterAccountsGridDefinition,
		private readonly workspacesGridDefinition: WorkspacesGridDefinition,
		private readonly securityAuditGridDefinition: SecurityAuditGridDefinitionService,
		private readonly betaFeaturesGridDefinition: BetaFeaturesGridDefinition,
		private readonly betaFeaturesChecklistGridDefinition: BetaFeaturesChecklistGridDefinition,
		private calendarFormatGridDefinition: CalendarFormatGridDefinition,
		private dashboardTemplatesGridDefinition: DashboardTemplatesGridDefinition,
	) {
	}

	getDefinition(type: GridTypes): IGridDefinition<any> {
		switch (type) {
			case GridTypes.DASHBOARD:
				return this.dashboardGridDefinition;
			case GridTypes.FILTER:
				return this.filterGridDefinition;
			case GridTypes.METRIC:
				return this.metricGridDefinition;
			case GridTypes.ALERT:
				return this.alertGridDefinition;
			case GridTypes.ALERT_SUBSCRIPTION_TEMPLATE:
				return this.alertSubscriptionTemplateGridDefinition;
			case GridTypes.STUDIO_ALERT:
				return this.studioAlertGridDefinition;
			case GridTypes.DRIVERS:
				return this.driversGridDefinition;
			case GridTypes.TEMPLATES:
				return this.templatesGridDefinition;
			case GridTypes.SCHEDULE:
				return this.scheduleGridDefinition;
			case GridTypes.ATTRIBUTES:
				return this.attributesGridDefinition;
			case GridTypes.MODELS:
				return this.modelsGridDefinition;
			case GridTypes.SCORECARDS:
				return this.scorecardsGridDefinition;
			case GridTypes.HIDDEN_GROUP_ASSETS:
				return this.hiddenGroupAssetsGridDefinition;
			case GridTypes.PALETTES:
				return this.colorPaletteGridDefinition;
			case GridTypes.GROUPS_BULK_USERS:
				return this.groupsUserGridDefinition;
			case GridTypes.USERS_BULK_GROUPS:
				return this.usersGroupGridDefinition;
			case GridTypes.USERS_BULK_PERMISSIONS:
				return this.usersBulkPermissionsGridDefinition;
			case GridTypes.MOBILE_SETTINGS:
				return this.mobileSettingsGridDefinition;
			case GridTypes.USERS_BULK_DATA_ACCESS:
				return this.usersBulkDataAccessGridDefinition;
			case GridTypes.CONVERSATION_SETTINGS:
				return this.conversationSettingsGridDefinition;
			case GridTypes.INTERACTION_EXPLORER:
				return this.interactionExplorerGridDefinition;
			case GridTypes.AUTOMATED_NARRATIVE:
				return this.narrativeSettingsGridDefinition;
			case GridTypes.HOME_PAGES:
				return this.homePageGridDefinition;
			case GridTypes.QUICK_INSIGHTS_DASHBOARD:
				return this.quickInsightsDashboardGridDefinition;
			case GridTypes.SAMPLED_AUDITS:
				return this.sampledAuditsGridDefinition;
			case GridTypes.IDENTITY_PROVIDERS:
				return this.samlIdentityProvidersGridDefinition;
			case GridTypes.SERVICE_PROVIDERS:
				return this.samlServiceProvidersGridDefinition;
			case GridTypes.EMBEDDED_WIDGET_MANAGEMENT:
				return this.embeddedWidgetGridDefinition;
			case GridTypes.ARCHIVE:
				return this.archiveGridDefinition;
			case GridTypes.MASTER_ACCOUNTS:
				return this.masterAccountsGridDefinition;
			case GridTypes.WORKSPACES:
				return this.workspacesGridDefinition;
			case GridTypes.SECURITY_AUDIT:
				return this.securityAuditGridDefinition;
			case GridTypes.BETA_FEATURES:
				return this.betaFeaturesGridDefinition;
			case GridTypes.BETA_FEATURES_CHECKLIST:
				return this.betaFeaturesChecklistGridDefinition;
			case GridTypes.CALENDAR_FORMAT:
				return this.calendarFormatGridDefinition;
			case GridTypes.DASHBOARD_TEMPLATES:
				return this.dashboardTemplatesGridDefinition;
			default:
				throw new Error(`Unsupported grid type: ${type}`);
		}
	}
}

app.service('gridDefinitionFactory', GridDefinitionFactory);
