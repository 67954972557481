export class HtmlUtils {

	private static specialCharactersMap = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		'\'': '&#39;',
		'/': '&#x2F;'
	};

	static escapeHtml(text: string): string {
		if (!text) return text;
		return String(text).replace(/[&<>"'\/]/g, (s) => {
			return HtmlUtils.specialCharactersMap[s];
		});
	}
}