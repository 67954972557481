import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core/cx-http.service';
import { AgBaseFolderApi } from '@app/modules/object-list/folders/ag-base-folder-api';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { IFolderApi } from '@cxstudio/common/folders/folder-api.interface';

@Injectable({
	providedIn: 'root'
})
export class TemplateFolderApiFactory {

	constructor(
		private cxHttp: CxHttpService,
	) {}

	getAssetFolderApi(assetType: TemplateAssetType): IFolderApi<AgGridNestable> {
		return new AgBaseFolderApi(
			`rest/asset-templates/${assetType.toLowerCase()}/template`,
			`rest/asset-templates/${assetType.toLowerCase()}/folders`,
			this.cxHttp, null);
	}
}
