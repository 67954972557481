import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxHttpService } from '@app/core';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { IAccountSelection } from '@app/modules/account/account-selection.interface';

const SCORECARDS_BASE_URL = 'rest/scorecards';

@Injectable({
	providedIn: 'root'
})
export class ScorecardMetricsManagementApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
		private readonly http: CxHttpService,
		private readonly httpHandlers: CxHttpHandlers,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.METRICS);
	}

	getMetrics = (
		project: IProjectSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<ScorecardMetric[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/scorecard-metrics';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ScorecardMetric[]>;
	}

	getMetricsForAccount = (
		account: IAccountSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<ScorecardMetric[]> => {
		let config = { cache, local };
		let url = this.getBaseAccountPath(account) + '/scorecard-metrics';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ScorecardMetric[]>;
	}

	updateDisableState = (metric: ScorecardMetric, disabled: boolean): Promise<boolean> => {
		const name = metric.name;
		return this.http.put(`${SCORECARDS_BASE_URL}/metrics/${name}/disable/${disabled}`, metric)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.METRICS)) as Promise<boolean>;
	}

	private getBaseAccountPath = (account: IAccountSelection): string => {
		return `rest/manage/cp/${account.contentProviderId}/account/${account.accountId}/all-projects`;
	}

	private getBasePath = (project: IProjectSelection): string => {
		return `rest/manage/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	}
}
