import * as _ from 'underscore';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';


export class SelectFromHierarchyController implements ng.IController {

	hierarchyList: INode[];
	displayProperty: string;
	placeholder: string;
	searchPlaceholder: string;
	onNodeClick: (params: {node: INode, previous: INode}) => void;
	onClear: () => void;
	label: string;
	disabledProperty: string;
	selectedItem: INode;
	skipToProperty: boolean;
	keepRevokedItem: boolean;
	disabledItems: INode[];
	appendToBody: () => boolean;
	menuClass: string;
	notRecommendedItems: INode[];
	hideSearch: boolean;
	intersectionObserver: IntersectionObserver;
	customValidation: (node: INode) => boolean;

	model: ng.INgModelController;

	displayDefault: string;

	constructor(
		private locale: ILocale,
		private $scope: ISimpleScope,
		private reportMetricService: ReportMetricService,
	) {}

	$onInit(): void {
		this.displayDefault = this.placeholder || this.locale.getString('common.selectPrompt');

		if (!this.searchPlaceholder) {
			this.searchPlaceholder = this.locale.getString('common.find');
		}
		if (!this.displayProperty) {
			this.displayProperty = 'displayName';
		}
		if (!this.appendToBody) {
			this.appendToBody = () => false;
		}
		// initialize the select once we have our hierarchy list available
		this.$scope.$watch(() => this.hierarchyList, this.onHierarchyListChange);

		this.model.$formatters.push(this.parseModel);
	}

	private parseModel = (value) => {
		if (value === undefined || value === null) {
			delete this.selectedItem;
		} else if (this.hierarchyList) {
			if (this.skipToProperty) {
				this.selectedItem = SearchableHierarchyUtils.findMetricInHierarchy(this.hierarchyList, value);
			} else {
				this.selectedItem = SearchableHierarchyUtils.findMetricNameInHierarchy(this.hierarchyList, value);
			}

			if (this.keepRevokedItem && this.skipToProperty && !this.selectedItem) {
				this.selectedItem = value;
			}

			if (!this.selectedItem && this.onClear) {
				this.onClear();
			}
		}
	}

	getDisplayName = (): string => {
		if (!this.selectedItem) {
			return this.displayDefault;
		}

		let item = this.model.$modelValue;

		return this.reportMetricService.getTopicLevelString(item) || this.selectedItem[this.displayProperty];
	}

	onHierarchyListChange = (newVal) => {
		if (newVal !== undefined) {
			this.parseModel(this.model.$modelValue);
		}
	}

	updateValue = (node: INode) => {
		if (node.children) return;
		let previous = angular.copy(this.selectedItem) || {};
		this.selectedItem = node;
		this.model.$setViewValue(this.skipToProperty ? node : node.name);
		this.onNodeClick({node, previous});
	}
}

app.component('selectFromHierarchy', {
	controller: SelectFromHierarchyController,
	templateUrl: 'partials/custom/select-from-hierarchy.html',
	bindings: {
		hierarchyList: '<',
		displayProperty: '@',
		placeholder: '@',
		searchPlaceholder: '@',
		onNodeClick: '&',
		onClear: '&?',
		label: '@',
		disabledProperty: '<',
		lazy: '<',
		selectedItem: '<',
		skipToProperty: '@',
		keepRevokedItem: '<',
		disabledItems: '<',
		appendToBody: '&?',
		menuClass: '@?',
		notRecommendedItems: '<?',
		hideSearch: '<',
		intersectionObserver: '<?',
		customValidation: '<?'
	},
	require: {
		model: 'ngModel'
	},
});
