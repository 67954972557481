import { Directive, Input, Output, ElementRef, Injector, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Book } from '@cxstudio/dashboards/entity/book';

@Directive({
	selector: 'book-editor'
})
export class BookEditorUpgrade extends UpgradeComponent {
	@Input() book: Book;
	@Output() save: EventEmitter<Book>;
	@Output() cancel: EventEmitter<boolean>;
	@Output() createNew: EventEmitter<void>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('bookEditor', elementRef, injector);
	}
}
