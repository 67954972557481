import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'page-content-header',
	template: `
<div class="ph-32 d-flex align-items-center justify-between page-content-header header-color-2 header-shadow">
	<div class="br-page-title">
		<h1 class="mb-0">{{title}}</h1>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageContentHeaderComponent {
	@Input() title: string;
}

app.directive('pageContentHeader', downgradeComponent({component: PageContentHeaderComponent}) as angular.IDirectiveFactory);
