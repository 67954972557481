import { SecondaryTrackRendererType } from '../secondary-track-renderer-type.enum';
import { CircleGenerator } from './circle-generator.class';
import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackConfig } from './secondary-track-config.class';
import { DiamondGenerator } from './diamond-generator.class';
import { TriangleGenerator } from './triangle-generator.class';
import { ScorecardIconGenerator } from './scorecard-icon-generator.class';
import { EmoticonGenerator } from '@cxstudio/conversation/shapes/emoticon-generator.class';

export class EnrichmentIconRenderer {
	static drawPoints(filteredData: ConversationDataPoint[], trackConfig: SecondaryTrackConfig, rootSvg): void {
		switch (trackConfig.shape) {
			case SecondaryTrackRendererType.CIRCLE: return CircleGenerator.draw(filteredData, trackConfig, rootSvg);
			case SecondaryTrackRendererType.DIAMOND: return DiamondGenerator.draw(filteredData, trackConfig, rootSvg);
			case SecondaryTrackRendererType.TRIANGLE: return TriangleGenerator.draw(filteredData, trackConfig, rootSvg);
			case SecondaryTrackRendererType.SCORECARD: return ScorecardIconGenerator.draw(filteredData, trackConfig, rootSvg);
			case SecondaryTrackRendererType.EMOTICON: return EmoticonGenerator.draw(filteredData, trackConfig, rootSvg);
		}

	}
}
