import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import {
	OAuthClientDetailsApiService
} from '@app/modules/system-administration/oauth/service/oauth-client-details-api.service';


@Component({
	selector: 'connectors-settings-integration',
	template: `
<form [formGroup]="connectorsSettings">
	<article class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					class="mr-0"
					id="enableConnectors"
					name="enableConnectors"
					label="{{'mAccount.enableConnectorsIntegration'|i18n}}"
					formControlName="connectorsEnabled"
					(change)="fillConnectorsLandingPage()">
				</checkbox-button>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.connectorsApplication'|i18n}}</label>
			<div class="col-md-8">
				<simple-dropdown
					[options]="acqApplications"
					formControlName="applicationId"
					valueField="id"
					displayField="name"
					allowClear="true">
				</simple-dropdown>
				<span *ngIf="applicationId?.errors?.required" class="text-danger"><span class="q-icon-warning"></span>
					{{ formUtils.getErrorMessage('mAccount.connectorsApplication') }}
				</span>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.connectorsUserAccess'|i18n}}</label>
			<div class="col-md-8">
				<checkbox-button
					name="internalUsersOnly"
					label="{{'mAccount.connectorsInternalUsersOnly'|i18n}}"
					formControlName="internalUsersOnly">
				</checkbox-button>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.enableOAuthIntegration'|i18n}}</label>
			<div class="col-md-8">
				<checkbox-button
					name="enableOAuth"
					label="{{'mAccount.enableOAuthIntegration'|i18n}}"
					formControlName="oauthEnabled">
				</checkbox-button>
			</div>
		</div>

		<div class="form-group" *ngIf="oauthEnabled.value">
			<label class="control-label col-md-3">{{'administration.oauthClientId'|i18n}}</label>
			<div class="col-md-8">
				<simple-dropdown
					[options]="suiteClients"
					formControlName="oauthClientId"
					valueField="clientId"
					displayField="clientId"
					allowClear="false">
				</simple-dropdown>
				<span *ngIf="oauthClientId?.errors?.required" class="text-danger"><span class="q-icon-warning"></span>
					{{ formUtils.getErrorMessage('administration.oauthClientId') }}
				</span>
			</div>
		</div>
	</article>
</form>`,
styles: [`.rounded-grouping-label { top: -20px; }`],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectorsSettingsIntegrationComponent extends SelfCleaningComponent implements OnInit {
	acqApplications: ExternalApplication[];
	suiteClients: OAuthClientDetails[];

	connectorsSettings: FormGroup;

	constructor(readonly formUtils: CxFormUtils, private fb: FormBuilder,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		private externalApplicationsApiService: ExternalApplicationsApiService,
		private oauthClientDetailsApiService: OAuthClientDetailsApiService,
		private ref: ChangeDetectorRef
	) {
		super();
		this.connectorsSettings = this.fb.group({
			connectorsEnabled: [ false ],
			applicationId: [{ value: null, disabled: true }, [ Validators.required ]],
			internalUsersOnly: [{ value: false, disabled: true }],
			oauthEnabled: [{ value: false, disabled: true }],
			oauthClientId: [{ value: null, disabled: true }, [ Validators.required ]],
			landingPage: [null],
		});

		this.addSubscription(this.connectorsSettings.get('connectorsEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.applicationId, this.oauthEnabled, this.internalUsersOnly);
				this.fillConnectorsLandingPage();
				if (this.oauthEnabled.value) {
					this.formUtils.enableFields(this.oauthClientId);
				}
				this.connectorsSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.applicationId, this.oauthEnabled, this.oauthClientId, this.internalUsersOnly);
				this.connectorsSettings.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.connectorsSettings.get('oauthEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.oauthClientId);
				this.connectorsSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.oauthClientId);
				this.connectorsSettings.updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.CONNECTORS).then(response => {
			this.acqApplications = response;
			this.ref.markForCheck();
		});

		this.oauthClientDetailsApiService.getSuiteClientDetailsList().then(response => {
			this.suiteClients = response;
			this.ref.markForCheck();
		});
	}

	fillConnectorsLandingPage = (): void => {
		if (_.isEmpty(this.landingPage.value)) {
			this.masterAccountApiService.getConnectorsSettings().then((response: any) => {
				this.landingPage.setValue(response.data.landingPagePlaceholder);
			});
		}
	}

	getGroup(): FormGroup {
		return this.connectorsSettings;
	}

	get applicationId(): FormControl {
		return this.connectorsSettings.controls.applicationId as FormControl;
	}

	get oauthEnabled(): FormControl {
		return this.connectorsSettings.controls.oauthEnabled as FormControl;
	}

	get oauthClientId(): FormControl {
		return this.connectorsSettings.controls.oauthClientId as FormControl;
	}

	get landingPage(): FormControl {
		return this.connectorsSettings.controls.landingPage as FormControl;
	}

	get internalUsersOnly(): FormControl {
		return this.connectorsSettings.controls.internalUsersOnly as FormControl;
	}
}
