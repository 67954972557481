import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class AttributeToFilterService implements IPointToFilter {
	constructor(
		private readonly formatterBuilderUtils: FormatterBuilderUtilsService,
		private readonly reportAttributesService: ReportAttributesService,
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: ReportGrouping): DrillFilter | null => {
		const value = this.getAttributeDrillValue(point, groupBy);
		const displayName = this.getAttributeValueCustomDisplayName(point, groupBy);
		if (_.isUndefined(value)) {
			return null;
		}

		const id = '' + groupBy.id;
		const name = groupBy.name;

		if (value === undefined)
			return null;

		return {
			type: DrillType.ATTRIBUTE,
			name,
			customValueDisplayName: displayName,
			values: [id, value]
		};
	}

	toFilterTypes = (parsers: DrillParsers): void => {
		parsers[DrillType.ATTRIBUTE] = this;
	}

	getRuleString = (filter: DrillFilter, widget: Widget): Promise<string | void> => {
		return this.reportAttributesService.getWidgetAttributes(widget).then(attributes => {
			const attr = _.find(attributes, {name: filter.name});
			if (attr) {
				let valueDisplayName;
				if (filter.customValueDisplayName) {
					valueDisplayName = filter.customValueDisplayName;
				} else if (filter.values.length === 2) {
					valueDisplayName = filter.values[1];
					if (AnalyticMetricTypes.isNumber(attr)) {
						valueDisplayName = this.formatterBuilderUtils.formatNumberAsString(valueDisplayName);
					}
				}

				return `${attr.displayName}: ${valueDisplayName}`;
			}
			return;
		});
	}

	private readonly getAttributeDrillValue = (point, groupBy): string => {
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);
		const key = this.isRebuttalAttribute(groupBy) ? (identifier + ReportConstants.FULL_PATH) : identifier;
		return point[key];
	}

	private readonly getAttributeValueCustomDisplayName = (point, groupBy): string | undefined => {
		if (this.isRebuttalAttribute(groupBy)) {
			const identifier = GroupIdentifierHelper.getIdentifier(groupBy);
			return point[identifier];
		} else {
			return undefined;
		}
	}

	private readonly isRebuttalAttribute = (groupBy): boolean => {
		return /model_(\d+)_rebuttal/i.test(groupBy.name);
	}
}

app.service('attributeToFilterService', downgradeInjectable(AttributeToFilterService));
