import {MasterAccountVoiceStatus} from './master-account-voice-status';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';

@Injectable({
	providedIn: 'root'
})
export class VoiceStatusApiService {
	constructor(private cxHttp: CxHttpService) {}

	getVoiceStatuses = (): Promise<MasterAccountVoiceStatus[]> => {
		let url = 'rest/system-admin/status/voice';
		return this.cxHttp.get(url);
	}

	export = (search: IntegrationSatusSearch): Promise<void> => {
		let url = 'rest/system-admin/status/voice/export';
		return this.cxHttp.post(url, search);
	}
}
