import { UrlService } from '@cxstudio/common/url-service.service';

/**
 * Service for adding cache params to requests
 */
app.factory('cacheInterceptor', (urlService: UrlService) => {

	return {
		request: (config) => {
			if (urlService.isBackendCall(config.url) && (config.method.toUpperCase() === 'GET')) {
				let nocache = {
					'Cache-Control': 'no-store, no-cache, must-revalidate',
					Pragma: 'no-cache',
					Expires: 'Wed, 21 Oct 2015 04:29:00 GMT'
				};
				config.headers = {...config.headers, ...nocache};
			}

			return config;
		}
	};
});
