import { ComparisonData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { GaugeBaseLayout } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/layout/gauge-base-layout';
import { GaugeVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-variables';

export class GaugeVerticalLayout extends GaugeBaseLayout {

	getNeededWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return Math.max(this.getGaugeWidth(cssVars), this.getComparisonWidth(cssVars));
	}

	getNeededHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		let comparisonSpace = this.getComparisonHeight(cssVars) + cssVars.verticalSpacing;
		return this.getGaugeHeight(cssVars) + this.getComparisonsCount(comparisons) * comparisonSpace;
	}

	getComparisonsColumnHeight(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getNeededHeight(cssVars, comparisons);
	}

	getComparisonsRowWidth(cssVars: GaugeVariables, comparisons: ComparisonData[]): number {
		return this.getComparisonWidth(cssVars);
	}

	isComparisonsStacked(): boolean {
		return true;
	}
}
