import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DefaultDataFormatterBuilderService } from './default-data-formatter-builder.service';
import { FormatsService } from './formats.service';
import { NumberFormatHelperService } from './number-format-helper.service';

@Injectable({
	providedIn: 'root'
})
export class MetricCustomFormatUtilsService {
	constructor(
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private numberFormatHelper: NumberFormatHelperService
	) { }

	initCalculationFormat(metric: any): any {
		let hasDefaultFormat = this.numberFormatHelper.itemHasDefaultFormat(metric);
		if (hasDefaultFormat) {
			metric.useDefaultFormat = true;
			if (!metric.dataType && !metric.customFormatting) {
				let defaultSetting = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(metric);
				metric = _.extend(defaultSetting, metric.format || {}, metric);
			}
			let dataType = FormatsService.find(metric.dataType);
			if (dataType && !metric.customFormatting) {
				dataType.upgrade(metric);
			}
		}
		return metric;
	}
}

app.service('metricCustomFormatUtils', downgradeInjectable(MetricCustomFormatUtilsService));
