import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HtmlUtils } from '../util/html-utils.class';


@Injectable()
export class BulkService {
	
	getNamesList(objects: any[], nameField: string): string {
		return objects.length > 5 ? '' : this.buildNamesList(objects, nameField);
	}

	private buildNamesList(objects: any[], nameField: string): string {
		return '\n' + _.map(objects, (object: any) => HtmlUtils.escapeHtml(object[nameField])).join('\n') + '\r\n';
	}

	getEscapedName(objectName: string): string {
		return HtmlUtils.escapeHtml(objectName);
	}
}

app.service('bulkService', downgradeInjectable(BulkService));