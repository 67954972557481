<div class="items-selected">{{getSelectedItemsLabel()}}</div>
<div class="options">
	<context-menu-option
		role="presentation"
		*ngFor="let item of menuItems"
		class="standalone"
    	[option]="item"
		[level]="1"
		[active]="activeItem === item"
		[bounded]="false"
		(onSelect)="toggleItem(item)"
		(onAction)="closeMenu()"
		(keydown.escape)="closeMenu(); $event.stopPropagation();">
	</context-menu-option>
    <a class="separated option"
        (click)="onCancelSelection.emit()">
        {{getCancelLabel()}}
    </a>
</div>
