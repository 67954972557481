import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { WorkspaceId, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export default class WorkspaceMetricsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.METRICS);
	}

	getMetrics = (project: WorkspaceProject, cache = CacheOptions.CACHED, local = false): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBasePath(project) + '/metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	}

	getMetricsForWorkspace = (
		workspace: WorkspaceId, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBaseWorkspacePath(workspace) + '/metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	}

	getPredefinedMetrics = (project: WorkspaceProject, cache = CacheOptions.CACHED): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache };
		let url = this.getBasePath(project) + '/predefined-metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	}

	getPredefinedMetricsForWorkspace = (
		workspace: WorkspaceId, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBaseWorkspacePath(workspace) + '/predefined-metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	}

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/resources/ws/${project.workspaceId}/project/${project.projectId}`;
	}

	private getBaseWorkspacePath = (workspaceId: WorkspaceId): string => {
		return `rest/resources/ws/${workspaceId}/all-projects`;
	}
}
