import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { DualAxisTypes } from '@cxstudio/reports/providers/cb/definitions/dual-axis-types';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';

export class DualDefinitionUtils {

	constructor(
		private options: VisualProperties,
		private utils: WidgetUtils
	) {}

	hasIncludeEmptyPointsGrouping(): boolean {
		let selections = this.options.attributeSelections;
		if (!selections) return false;
		let includeEmptyPointsGroupings = _.chain([
			selections.primaryGroup,
			selections.secondaryGroup,
			selections.stackedGroup
		]).filter((group)  => {
			return this.isIncludeEmptyPointsGroup(group);
		}).value();

		return includeEmptyPointsGroupings.length > 0;
	}

	isIncludeEmptyPointsGroup = (group: AttributeGrouping): boolean => {
		return group
			&& AnalyticMetricTypes.isTime(group)
			&& this.isIncludingNulls(group);
	}

	private isIncludingNulls(group: AttributeGrouping): boolean {
		if (!group)
			return false;
		return group.nullInclude;
	}


	isIncludingEmptyPoints(): boolean {
		return this.isPop() || this.hasIncludeEmptyPointsGrouping();
	}

	getGroup(attribute): ReportGrouping {
		return GroupIdentifierHelper.getGroup(attribute);
	}

	getPopLevel() {
		return DualDefinitionHelper.getPopLevel(this.options);
	}

	sortByP1Volume() {
		return (!this.isStacked() || this.isStackedBarByNonTimeGrouping())
			&& this.hasPop() && (this.isBar() || this.isLine());
	}

	getSortIgnoreLevels() {
		if (DualDefinitionHelper.isTimePrimaryGrouping(this.options) && this.hasPop())
			return [0, 1];
		if (DualDefinitionHelper.isTimePrimaryGrouping(this.options))
			return [0];
		if (this.hasPop())
			return [this.getPopLevel()];
		return null;
	}

	isStackedBarByNonTimeGrouping() {
		return this.isStacked() && this.isBar() && !AnalyticMetricTypes.isTime(this.getStackingGroup());
	}

	isPopStacked(): boolean {
		return this.options.stackedGroup === StandardMetricName.POP;
	}

	isStacked(): boolean {
		return DualDefinitionHelper.isStacked(this.options, this.utils);
	}

	getStackingGroup() {
		if (this.isStacked()) {
			if (this.isBar()) {
				return this.options.attributeSelections.stackedGroup;
			}
			if (this.isLine()) {
				return this.options.attributeSelections.secondaryGroup;
			}
		}
		return undefined;
	}

	getChartType(): VisualizationType | ChartType {
		if (this.options.subChartType === ChartType.POLAR) {
			return VisualizationType.COLUMN;
		}
		if (this.options.subChartType === ChartType.RADAR) {
			return ChartType.SPLINE;
		}
		if (this.options.subChartType === ChartType.SPLINE && this.isStacked()) {
			return VisualizationType.AREA;
		}
		if (this.options.subChartType === ChartType.PARETO) {
			return VisualizationType.COLUMN;
		}
		return this.options.subChartType;
	}

	isBar(): boolean {
		return this.utils.widgetType === WidgetType.BAR;
	}

	isLine(): boolean {
		return this.utils.widgetType === WidgetType.LINE;
	}

	isBubble(): boolean {
		return DualAxisTypes.isBubbleVisualization(this.options);
	}

	isPop(): boolean {
		return DualDefinitionHelper.isPop(this.options);
	}

	hasPop(): boolean {
		return DualDefinitionHelper.hasPop(this.options);
	}

	hasSecondaryGroup(axisPosition?: PlotLineAxis): boolean {
		return DualDefinitionHelper.hasSecondaryGroup(this.options, axisPosition);
	}

	isLineChartSecondary(): boolean {
		return this.options.secondaryChartType === ChartType.SPLINE;
	}

	isLineChart(): boolean {
		return this.options.subChartType === ChartType.SPLINE;
	}

	isCalculationSeries(): boolean {
		return DualDefinitionHelper.isCalculationSeries(this.options, this.utils.widgetType);
	}

	isClusterable(): boolean {
		return (this.isBar() && this.options.secondaryGroup && !this.options.stackedGroup && !this.isPop());
	}

	applyClustering() {
		return this.isClusterable() && this.options.cluster;
	}

	isUseParentColor(chartAxis): boolean {
		let hasMultipleSeries;
		let withPop;
		if (chartAxis === PlotLineAxis.primary) {
			hasMultipleSeries = this.isStacked() || (this.isBubble() && this.hasSecondaryGroup()) || this.applyClustering();
			withPop = this.hasPop();
		} else {
			hasMultipleSeries = (this.isStacked() && this.options.secondaryStacked)
				|| (this.isLine() && this.hasSecondaryGroup())
				|| (this.isBar() && this.applyClustering());
			withPop = this.hasPop() && this.options.secondaryPop;
		}
		return !!(hasMultipleSeries && !withPop);
	}

	isPrimaryAndSecondarySameColor(): boolean {
		return (this.options.secondaryYAxis && this.options.secondaryYAxis.length > 0)
			&& (this.options.color === this.options.secondaryColor);
	}

	hasAnyRecolor(): boolean {
		return this.primaryHasRecolor() || this.secondaryHasRecolor();
	}

	primaryHasRecolor(): boolean {
		return this.options.recolors
			&& this.options.recolors.length > 0
			&& this.options.recolors.length > this.options.recolors.filter(this.secondaryRecolorsFilter).length;
	}

	secondaryHasRecolor(): boolean {
		return _.any(this.options.recolors, this.secondaryRecolorsFilter);
	}

	isMetricColor(property: string): boolean {
		return this.options[property].startsWith('_calculation_color')
			|| this.options[property].startsWith('_group_color');
	}

	secondaryRecolorsFilter(recolorDefinition) {
		return recolorDefinition.colorType.indexOf('secondary') === 0;
	}

	getPrimaryGroup() {
		let selections = this.options.attributeSelections;
		return selections && selections.primaryGroup;
	}
}
