import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetCreateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-create-action';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';

export class WidgetDeleteAction implements DashboardChangeAction {
	private widget: Widget;

	constructor(widget: Widget) {
		this.widget = ObjectUtils.copy(widget);
	}

	reverse(): DashboardChangeAction {
		return new WidgetCreateAction(this.widget);
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		widgets.removeAt(WidgetActionUtils.findWidgetIndexById(widgets, this.widget.id, idMapper));
		return api.deleteWidgets(WidgetActionUtils.getWidgetWithLatestId(this.widget, idMapper));
	}
}
