<div [ngBusy]="loading" class="pv-16 ph-32">
	<form #nameForm="ngForm" class="form-group">
		<label for="alertName" class="font-bold">{{'common.name'|i18n}}</label>
		<input
			class="d-flex w-100-percent"
			type="text"
			id="alertName"
			name="name"
			[(ngModel)]="alert.name"
			(ngModelChange)="setValid(nameForm.valid)"
			required
			uniqueName
			[itemList]="allAlerts"
			[initialItem]="alert">
		<form-error *ngIf="nameForm.controls.name?.dirty && nameForm.controls.name?.errors?.unique === true"
			class="mt-8"
			text="{{'alert.nameNotUnique'|i18n}}">
		</form-error>
	</form>

	<labeled-toggle-switch
		class="d-flex mb-16 font-bold"
		[label]="'alert.enableAlert' | i18n"
		[(value)]="alert.enabled"
		[disabled]="enabledAlertLimitReached"
		[alignBetween]="false"
		title="{{ enabledAlertLimitReached ? ('alert.metricAlertsMaxWarning' | i18n) : ''}}">
	</labeled-toggle-switch>

	<div class="d-flex flex-direction-column mb-24">
		<label class="font-bold">{{'alert.sendAlertOn'|i18n}}:</label>
		<div class="d-flex flex-direction-row ml-16">
			<div class="d-flex flex-direction-column no-date-clear">
				<label class="d-flex font-semibold capitalize h-24 align-center">{{'alert.startDate'|i18n}}
					<cb-inline-help>
						<help-body>{{'alert.startOnHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<date-picker-popup [(value)]="alert.startDate"></date-picker-popup>
			</div>
			<div class="d-flex flex-direction-column no-date-clear ml-8">
				<label class="d-flex font-semibold capitalize">{{'alert.expirationDate'|i18n}}</label>
				<date-picker-popup [(value)]="alert.endDate"></date-picker-popup>
			</div>
		</div>
	</div>

	<div class="d-flex flex-direction-column mb-16">
		<label class="font-bold">{{'alert.monitorEvery'|i18n}}:</label>
		<div class="d-flex flex-direction-row mb-8 ml-16">
			<div class="mr-8">
				<label class="d-flex font-semibold">{{'schedule.frequency'|i18n}}</label>
				<simple-dropdown
					[(value)]="alert.trigger.scheduleType"
					(onChange)="processCron()"
					[options]="frequencyOptions"
					[disableSort]="true">
				</simple-dropdown>
			</div>
			<div *ngIf="alert.trigger.scheduleType === SchedulePeriod.WEEKLY">
				<label class="d-flex h-24 align-center" for="">{{'schedule.selectDayOfWeek'|i18n}}
					<cb-inline-help>
						<help-body>{{'alert.frequencyHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div class="btn-group">
					<button *ngFor="let weekday of weekDaysOptions"
						class="btn"
						[attr.aria-label]="weekday.name"
						[title]="weekday.name"
						(click)="updateWeekSelection(weekday)"
						[class.btn-selected]="weekday.value">
						{{weekday.shortName}}
					</button>
				</div>
			</div>

			<div *ngIf="alert.trigger.scheduleType === SchedulePeriod.MONTHLY">
				<label class="d-flex" for="">{{'schedule.selectDayOfMonth'|i18n}}
					<cb-inline-help>
						<help-body>{{'alert.frequencyHelpText'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div>
					<label for="dayOfMonth" class="mr-8 mb-0">{{'schedule.dayTitle'|i18n}}</label>
					<input id="dayOfMonth" class="br-day-of-month number-textbox schedule-input"
						type="number"
						[strictMin]="1"
						[strictMax]="31"
						[(ngModel)]="recurrence.dayOfMonth"
						(ngModelChange)="processCron()">

					<label for="monthlyInterval" class="mh-8 mb-0">{{'schedule.ofEvery'|i18n}}</label>
					<input id="monthlyInterval"
						class="br-monthly-interval number-textbox schedule-input"
						type="number"
						[strictMin]="1"
						[strictMax]="12"
						[(ngModel)]="recurrence.month"
						(ngModelChange)="processCron()">

					<label for="monthlyInterval" class="ml-8 mb-0">{{'schedule.months'|i18n}}</label>
				</div>
			</div>
		</div>
		<div class="d-flex flex-direction-row mb-8">
			<div class="d-flex flex-direction-column ml-16">
				<label class="d-flex font-semibold">{{'alert.alertTimezone'|i18n}}</label>
				<simple-dropdown
					[(value)]="alert.timezoneOffset"
					displayField="displayName"
					class="d-flex schedule-input border-0"
					[options]="zoneOffsetOptions"
					(onChange)="processCron()"
					[noMinWidth]="true">
				</simple-dropdown>
			</div>
		</div>
	</div>
</div>
