<collapsing-panel
	[disableCollapse]="!carousel.enabled"
	[collapsed]="!carousel.enabled">
	<panel-heading class="align-items-center">
		<div class="d-flex align-items-center" [ngStyle]="{'height': carousel.enabled ? '0' : null }">
			<cb-toggle
				class="ml-4 mr-8"
				[(ngModel)]="carousel.enabled"
				(ngModelChange)="onChange.emit()">
			</cb-toggle>
			<b [i18n]="'homePage.dashboardCarousel'"></b>
		</div>
	</panel-heading>
	<panel-body>
		<div class="pl-16">
			<dashboard-list-tabs-editor class="d-flex"
				[ngBusy]="loading"
				[tabs]="carousel.tabs"
				[labels]="allLabels"
				(onChange)="onChange.emit()">
			</dashboard-list-tabs-editor>
		</div>
	</panel-body>
</collapsing-panel>
