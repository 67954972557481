import { Injectable } from '@angular/core';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { FormulaSegment, TextToken, TextTokenType, } from '../adapter/formula-segment';
import { CustomMathFunctionHelper } from './custom-math-function-helper.service';
import { MathItemColorClass } from './math-item-color-class.enum';

@Injectable({
	providedIn: 'root'
})
export class CustomMathHighlightService {

	constructor(private customMathFunctionHelper: CustomMathFunctionHelper) {}

	readonly FUNCTION_START = 'abs(';

	markFunctionTokensForHighlighting = (segments: FormulaSegment[]) => {
		let allTextTokens: TextToken[] = this.extractTokens(segments);
		let functionStartIndexes: number[] = this.customMathFunctionHelper.highlightFunctionStartTokens(allTextTokens);
		_.map(functionStartIndexes, startIndex => this.customMathFunctionHelper.highlightFunctionEndToken(allTextTokens, startIndex));
	}

	private extractTokens(segments: FormulaSegment[]): TextToken[] {
		return _.chain(segments).pluck('textTokens').flatten().value();
	}

	getTokenClass = (token: TextToken, expressionType: string): string => {
		let tokenClass = '';

		let tokenType = token.type;
		if (token.isFunction) {
			tokenClass = MathItemColorClass.FUNCTION;
		} else if (this.isAttributeName(expressionType, tokenType)) {
			tokenClass = MathItemColorClass.ATTRIBUTE;
		} else if (this.isAggregation(tokenType) || this.isAggregationSyntax(expressionType, tokenType)) {
			tokenClass = MathItemColorClass.AGGREGATION;
	 	} else if (this.isSystemMetricBrackets(expressionType, tokenType)) {
			return MathItemColorClass.PREDEFINED_METRIC;
		} else if (this.isKeywordExpressionPiece(expressionType, tokenType)) {
			tokenClass = this.getKeywordExpressionClass(expressionType);
		}

		return tokenClass;
	}

	private isAttributeName(expressionType: string, tokenType: string) {
		return expressionType === ExpressionPieces.ATTRIBUTE
			&& (tokenType === TextTokenType.NUMERIC_ATTRIBUTE || tokenType === TextTokenType.TEXT_ATTRIBUTE);
	}

	private isSystemMetricBrackets = (expressionType: string, tokenType: string): boolean => {
		return expressionType === ExpressionPieces.SYSTEM_METRIC && this.isKeywordPart(tokenType);
	}

	private isKeywordExpressionPiece = (expressionType: string, tokenType: string): boolean => {
		return (expressionType === ExpressionPieces.METRIC
			|| expressionType === ExpressionPieces.SCORECARD_STUDIO_METRIC
			|| expressionType === ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC) && this.isKeywordPart(tokenType);
	}

	private isKeywordPart = (tokenType: string): boolean => {
		return tokenType === TextTokenType.PREFIX || tokenType === TextTokenType.SYNTAX;
	}

	private getKeywordExpressionClass = (expressionType: string): string => {
		switch (expressionType) {
			case ExpressionPieces.METRIC:
				return MathItemColorClass.CUSTOM_METRIC;
			case ExpressionPieces.SCORECARD_STUDIO_METRIC:
				return MathItemColorClass.SCORECARD_METRIC;
			case ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC:
				return MathItemColorClass.HIERARCHY_METRIC;
		}
	}

	private isAggregation = (tokenType: string): boolean => {
		return tokenType === TextTokenType.NUMERIC_AGGREGATION
			|| tokenType === TextTokenType.TEXT_AGGREGATION
			|| tokenType === TextTokenType.GENERIC_AGGREGATION;
	}

	private isAggregationSyntax = (expressionType: string, tokenType: string): boolean => {
		return expressionType === ExpressionPieces.ATTRIBUTE && tokenType === TextTokenType.SYNTAX;
	}

	getSuggestionClass = (tokenType: string): string => {
		switch (tokenType) {
			case TextTokenType.RESERVED_WORD:
				return MathItemColorClass.FUNCTION;
			case TextTokenType.NUMERIC_ATTRIBUTE:
			case TextTokenType.TEXT_ATTRIBUTE:
				return MathItemColorClass.ATTRIBUTE;
			case TextTokenType.NUMERIC_AGGREGATION:
			case TextTokenType.TEXT_AGGREGATION:
			case TextTokenType.GENERIC_AGGREGATION:
				return MathItemColorClass.AGGREGATION;
			case TextTokenType.PREDEFINED_METRIC:
				return MathItemColorClass.PREDEFINED_METRIC;
			case TextTokenType.METRIC:
				return MathItemColorClass.CUSTOM_METRIC;
			case TextTokenType.SCORECARD_METRIC:
				return MathItemColorClass.SCORECARD_METRIC;
			case TextTokenType.HIERARCHY_METRIC:
				return MathItemColorClass.HIERARCHY_METRIC;
		}
	}

}
