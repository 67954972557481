import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ModelUtils } from '@app/modules/project/model/model-utils';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WidgetGroupingFilter } from '@app/modules/reports/filters/WidgetGroupingFilter';

@Injectable({
	providedIn: 'root'
})
export class WidgetModelGroupingFiltersService {

	constructor(
		private reportModelsService: ReportModelsService,
		private locale: CxLocaleService
	) {}

	getFilters = (widget: Widget, withDisabled: boolean): ng.IPromise<WidgetGroupingFilter[]> => {
		let promises = [];
		let filters: WidgetGroupingFilter[] = [];
		let rootNodeEnabled = this.isFilterEnabled(widget.properties, 'rootNodeFilter');
		if (rootNodeEnabled || withDisabled) {
			let rootNodeFiltersPromise = this.getRootNodeFilters(widget).then(data => {
				if (data.length) {
					filters.push({
						identity: 'rootNodeFilter',
						name: `${this.locale.getString('reportFilters.rootNodeFilter')}`,
						values: data,
						enabled: rootNodeEnabled
					});
				}
			});
			promises.push(rootNodeFiltersPromise);
		}

		let emptyVerbatimEnabled = this.isFilterEnabled(widget.properties, 'emptyVerbatimsFilter');
		if (emptyVerbatimEnabled || withDisabled) {
			let emptyVerbatimsFiltersPromise = this.getEmptyVerbatimsFilters(widget).then(data => {
				if (data.length) {
					filters.push({
						identity: 'emptyVerbatimsFilter',
						name: this.locale.getString('reportFilters.emptyVerbatimsFilter'),
						values: data,
						enabled: emptyVerbatimEnabled
					});
				}
			});
			promises.push(emptyVerbatimsFiltersPromise);
		}
		return PromiseUtils.old(Promise.all(promises)).then(() => filters, () => filters);
	}

	private isFilterEnabled(widgetProperties: WidgetProperties, identity: string): boolean {
		let groupingFilters = widgetProperties.groupingFilters;
		if (_.isUndefined(groupingFilters) || _.isUndefined(groupingFilters[identity]))
			return true;
		return groupingFilters[identity];
	}

	private getRootNodeFilters(widget: Widget): ng.IPromise<any> {
		let models = this.findModels(widget.properties);

		let rootNodePromises = _.map(models, model => {
			//old widget settings does not have rootNodeId field
			//if no exist node error, choose topic again to correct the value
			//CSI-3256
			let rootNodeId = model.rootNodeId || (parseInt(model.name, 10) + 1);
			return this.reportModelsService.getWidgetNodeInfo(widget, rootNodeId);
		});

		return PromiseUtils.old(Promise.all(rootNodePromises)).then(rootNodeDetailsList => {
			return this.getUniqueList(rootNodeDetailsList, 'modelName', { hasRules: true });
		});
	}

	private getEmptyVerbatimsFilters(widget: Widget): ng.IPromise<any> {
		let models = this.findModels(widget.properties);

		let modelPromises = _.map(models, model => {
			return this.reportModelsService.getWidgetModelTree(widget, parseInt(model.name, 10))
				.then(modelTree => ModelUtils.populateNodesPath(modelTree));
		});

		return PromiseUtils.old(Promise.all(modelPromises)).then(modelsList => {
			return this.getUniqueList(modelsList, 'name', { includeEmptyRecords: false });
		});
	}

	private getUniqueList(values: any[], property: string, filter: any): any[] {
		return _.chain(values)
				.where(filter)
				.sortBy(property)
				.pluck(property)
				.uniq(true)
				.value();
	}

	private findModels(properties: WidgetProperties): AttributeGrouping[] {
		let selectedAttributes = properties.selectedAttributes;
		return _.filter(selectedAttributes, selectedAttribute => AnalyticMetricTypes.isTopics(selectedAttribute));
	}

}
app.service('widgetModelGroupingFilters', downgradeInjectable(WidgetModelGroupingFiltersService));
