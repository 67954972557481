import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DashboardFilter, MetadataDashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';

@Component({
	selector: 'dashboard-filters-section',
	template: `
		<p role="menuitem" tabindex="0" aria-disabled="true"
			class="mb-0 dropdown-item white-space-normal"
			*ngFor="let dashboardFilter of getVisibleFilters()"
			[ngClass]="{'strike-through': dashboardFilter.ignored}">
			{{getDashboardFilterString(dashboardFilter)}}</p>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFiltersSectionComponent {
	@Input() filters: MetadataDashboardFilter[];
	@Input() timezone: string;
	@Input() skipIgnored: boolean;

	visibleFilters: DashboardFilter[];
	constructor(
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('dashboardFilterLabels') private dashboardFilterLabels: DashboardFilterLabelsService,
	) {

	}

	getVisibleFilters = (): DashboardFilter[] => {
		return this.filters.filter(dashboardFilter => this.visibilityFilter(dashboardFilter));
	}

	getDashboardFilterString = (dashboardFilter: DashboardFilter): string => {
		let displayName = this.dashboardFiltersService.getFilterDisplayName(dashboardFilter.selectedAttribute);

		if (this.dashboardFiltersService.isAttribute(dashboardFilter.selectedAttribute)) {
			let filterContent = this.dashboardFilterLabels.getFilterTooltip(dashboardFilter, undefined, true);
			return `${displayName} ${filterContent}`;
		}

		let ruleString = this.dashboardFilterLabels.getFilterLabel(
			dashboardFilter, this.timezone);

		if (dashboardFilter.isDrillToDashboardFilter) {
			return ruleString;
		}

		return `${displayName}: ${ruleString}`;
	}

	visibilityFilter = (dashboardFilter: MetadataDashboardFilter): boolean => {
		let notOrgFilter = !dashboardFilter.isOrgHierarchy;
		let skipped = this.skipIgnored && dashboardFilter.ignored;
		return notOrgFilter && !skipped && this.hasValue(dashboardFilter);
	}

	hasValue = (dFilter: DashboardFilter): boolean => {
		return this.dashboardFiltersService.hasValue(dFilter) || dFilter.selectedAttribute?.existMatch || dFilter.isDrillToDashboardFilter;
	}

}

app.directive('dashboardFiltersSection', downgradeComponent({component: DashboardFiltersSectionComponent}) as angular.IDirectiveFactory);
