import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { CxLocaleService } from '@app/core';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import { ITableColumnFormatter } from '@cxstudio/reports/entities/table-column';

@Component({
	selector: 'verbatim-text',
	template: `
		<span [innerHTML]="verbatimHtml"></span>
		<span *ngIf="!verbatim.feedbackTextComplete && !loadingFullText"
			class="verbatim-show-more">
			<a href="javascript:void(0)"
				role="button"
				aria-expanded="false"
				(click)="onShowMore($event)">
				{{getLoadText()}}
			</a>
		</span>
		<span *ngIf="loadingFullText" class="loading-spinner q-icon-spinner rotate-infinite"></span>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerbatimTextComponent implements OnInit {

	@Input() verbatim: PreviewSentence;
	@Input() capitalization?: CapitalizationType;
	@Output() showMore = new EventEmitter<(response: ReportDataObject) => void>();

	verbatimHtml = '';
	loadingFullText = false;

	private formatVerbatim: ITableColumnFormatter<PreviewSentence>;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
		@Inject('analyticPreviewFormatting') private readonly analyticPreviewFormatting: AnalyticPreviewFormatting,
	) { }

	ngOnInit(): void {
		this.formatVerbatim = this.analyticPreviewFormatting.verbatimFormatter(this.capitalization);
		this.initHtml();
	}

	private initHtml(): void {
		this.verbatimHtml = this.formatVerbatim(this.verbatim);
	}

	onShowMore(event: Event): void {
		this.loadingFullText = true;
		let callback = (response: ReportDataObject) => {
			if (response.data?.length) {
				this.verbatim.feedbackTextComplete = true;
				this.verbatim.chunks = response.data[0].chunks;
				this.initHtml();
			}
			this.loadingFullText = false;
			this.ref.markForCheck();
		};
		this.showMore.emit(callback);
		event.stopPropagation();

	}

	getLoadText(): string {
		return this.environmentService.isPdfExport()
			? this.locale.getString('preview.moreFeedback')
			: this.locale.getString('common.readMore');
	}

}
