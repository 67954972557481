import * as Highcharts from 'highcharts';
import { HighchartsZoomUtil } from '../utils/highchart/highcharts-zoom-util';
import { ChartValidator } from './chart-validator.service';

app.directive('highchartsTreemap', (tableService, reportDefinitionInitializers, reportUtils,
	reportScopeUtils, highchartsDefinitionFactory, pointSelectionUtils,
	chartValidator: ChartValidator,
	highchartsAccessibilityUtils) => {
	return {
		restrict: 'A',
		replace: true,
		template: '<div>Error</div>',
		scope: {
			options: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},
		link: (scope, element) => {
			scope.selectedPoint = null;
			reportUtils.initDestroyListener(scope);
			if (_.isUndefined(scope.utils)) {
				scope.utils = {widgetType: 'undefined'};
			}

			if (!tableService.processIfNoData(element, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			let treemapChartCallback = (chart) => {
				highchartsAccessibilityUtils.applyLegendBorder(chart);

				pointSelectionUtils.highlightSelectedPoints(scope.utils.containerId, scope.utils.widgetId,
					scope.utils.getGroupings(), scope.demo);
			};

			let renderChart = () => {
				// destroy chart if it already exists
				if (scope.chart && scope.chart.container)
					scope.chart.destroy();

				reportDefinitionInitializers.initializeSize(scope);
				let definition = highchartsDefinitionFactory.get(scope, element);
				let chartOptions = definition.getChartOptions();

				if (scope.demo || chartValidator.validate(chartOptions.series, scope.dataObject.data, element, scope.options)) {
					scope.chart = new Highcharts.Chart(chartOptions, treemapChartCallback);

					let destroyChart = scope.$on('$destroy', () => {
						// destroy chart if it has been rendered
						if (scope.chart.container) {
							scope.chart.destroy();
						}
						destroyChart();
					});
				}
			};
			renderChart();

			reportUtils.initResizeHandler(scope, element, reportUtils.chartResizeHandler(scope));

			if (!scope.demo) {
				reportScopeUtils.emitContextMenuHandling(scope, element);
				HighchartsZoomUtil.applyZoom(scope.chart, scope.options);
			}

			scope.$watch('trigger', reportUtils.chartTriggerHandler(scope, renderChart));

		} // link end
	};
});
