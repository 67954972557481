export enum MetricType {
	TOP_BOX = 'tb',
	BOTTOM_BOX = 'bb',
	SATISFACTION = 'nps',
	FILTER = 'filter',
	VARIABLE = 'variable',
	CUSTOM_MATH = 'custom',
	EASE_SCORE = 'ease',
	SENTIMENT = 'sentiment',
	EMOTION = 'emotion',
	NUMERIC_BREAKDOWN = 'numeric_breakdown',
	SCORECARD = 'metricScorecard'
}


