import { TransferGroup } from './transfer-group';
import { TransferItemCandidates } from './transfer-item-candidates';
import { TransferData } from './transfer-data';
import { TransferMode } from './transfer-mode';
import { TransferCandidate } from './transfer-candidate';
import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { UsersTransferStatus } from '@app/modules/user-administration/transfer/users-transfer-status';
import { BulkTransferData } from './bulk-transfer-data';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';


@Injectable()
export class TransferApiService {

	constructor(
		private http: CxHttpService
	) {}

	getTransferCandidates(userId: number, transferMode: TransferMode)
		: Promise<{number: {[key in TransferGroup]: TransferItemCandidates[]}}> {
		return this.http.get(`rest/transfer/user/${userId}/candidates/${transferMode}`);
	}

	getTransferCandidatesForSysAdminPage(userId: number, transferMode: TransferMode)
		: Promise<{number: {[key in TransferGroup]: TransferItemCandidates[]}}> {
		return this.http.get(`rest/transfer/user/${userId}/candidates/${transferMode}/admin`);
	}

	makeTransfer(transferData: TransferData): Promise<void> {
		return this.http.post('rest/transfer', transferData);
	}

	makeManageUsersTransfer(transferData: TransferData): Promise<void> {
		return this.http.post('rest/transfer/manage-users', transferData);
	}

	makeBulkManageUsersTransfer(transferData: BulkTransferData): Promise<void> {
		return this.http.post('rest/transfer/bulk/manage-users', transferData);
	}

	getTransferCandidatesByGroup(
		group: TransferGroup, projectIdentifier?: AccountOrWorkspaceProject
	): Promise<TransferCandidate[]> {
		return WorkspaceTransitionUtils.isWorkspaceProject(projectIdentifier)
			? this.http.post(`rest/transfer/ws-candidates/group/${group}`, projectIdentifier)
			: this.http.post(`rest/transfer/candidates/group/${group}`, projectIdentifier);
	}

	getUsersTransferStatus = (userIds: number[]): Promise<UsersTransferStatus> =>
		this.http.post('rest/transfer/user/status', userIds)
}

app.service('transferApiService', downgradeInjectable(TransferApiService));
