export class ElementMutationObserver {
	private observer: MutationObserver | undefined;

	constructor(
		private elementSelector: string,
		private callback: () => void,
		private delayMs = 100,
	) {}

	observe(): void {
		const targetNode = document.querySelector(this.elementSelector);
		if (!targetNode) {
			throw new Error(`Cannot find element with selector '${this.elementSelector}'`);
		}

		this.observer = new MutationObserver(this.handleMutation);

		const config = { childList: true, subtree: true };
		this.observer.observe(targetNode, config);
	}

	disconnect(): void {
		if (this.observer) {
			this.observer.disconnect();
			this.observer = undefined;
		}
	}

	private handleMutation = (mutations: MutationRecord[], observer: MutationObserver): void => {
		mutations.forEach((mutation) => {
			mutation.addedNodes.forEach((node) => {
				if (node.nodeType === Node.ELEMENT_NODE) {
					setTimeout(this.callback, this.delayMs);
				}
			});

			mutation.removedNodes.forEach((node) => {
				if (node.nodeType === Node.ELEMENT_NODE) {
					setTimeout(this.callback, this.delayMs);
				}
			});
		});
	}
}
