import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { GridsterEvent } from '@app/core/cx-event.enum';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';

@Injectable({
	providedIn: 'root'
})
export class GridsterStateService {

	private readonly refreshDraggableFn: () => void;

	constructor(
		@Inject('$rootScope') readonly $rootScope: ISimpleScope,
	) {
		this.refreshDraggableFn = _.throttle(() => $rootScope.$broadcast(GridsterEvent.RESET_DRAG), 500, {leading: false});
	}

	refreshDragHandles(): void {
		this.refreshDraggableFn();
	}

}

app.service('gridsterState', downgradeInjectable(GridsterStateService));
