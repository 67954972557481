import * as _ from 'underscore';
import ModalMode from '@cxstudio/common/modal/modal-mode.enum';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WizardStepComponentController } from '@cxstudio/components/wizard-step.component';


// only allow keys which refer to predicate functions
type PredicateKeyOf<T> = { [P in keyof T]: T[P] extends () => boolean? P : never}[keyof T];

export class WizardComponentController implements ng.IController {

	steps: WizardStepComponentController[];
	showCancelIcon: boolean;
	mode: ModalMode;
	allowNavigation: boolean;
	isViewMode: boolean;
	finishText: string;
	jumpToStep: number;
	header: string;
	finishCallback: () => void;
	cancelCallback: () => void;
	isLastStepFixed: () => boolean;
	isValid: () => boolean;
	loading: () => boolean;
	hidePrevious: boolean;

	private currentStep: number;

	constructor(
		private locale: ILocale
	) {}

	$onInit(): void {
		this.steps = [];
		this.currentStep = this.jumpToStep || 0;

		this.showCancelIcon = this.showCancelIcon || false;
		this.mode = this.mode || ModalMode.EDIT;
		this.allowNavigation = this.mode !== ModalMode.VIEW_LAST_PAGE_ONLY;
		this.isViewMode = this.mode === ModalMode.VIEW || this.mode === ModalMode.VIEW_LAST_PAGE_ONLY;

		this.finishText = this.finishText
			? this.finishText : this.locale.getString('common.finish');
	}

	getHeader = (): string => {
		let activeStep = this.steps[this.currentStep];
		if (activeStep && activeStep.getHeader()) {
			return activeStep.getHeader();
		}

		return this.header || '';
	}

	getNextButtonText = (): string => {
		let activeStep = this.steps[this.currentStep];
		if (activeStep && activeStep.getNextButtonText()) {
			return activeStep.getNextButtonText();
		}
		return this.locale.getString('common.next');
	}

	addStep = (step: WizardStepComponentController): void => {
		let isActive = (this.currentStep === this.steps.length);
		step.setActive(isActive);
		this.steps.push(step);
	}

	private hasSteps(): boolean {
		return this.steps.length > 0;
	}

	private getCurrentStep(): WizardStepComponentController {
		if (!this.hasSteps()) return undefined;
		return this.steps[this.currentStep];
	}

	private getStepCallback(callbackName: PredicateKeyOf<WizardStepComponentController>): () => boolean {
		let step = this.getCurrentStep();
		if (!_.isUndefined(step)) {
			return step[callbackName];
		} else {
			return undefined;
		}
	}

	private getStepCallbackResult(callbackName: PredicateKeyOf<WizardStepComponentController>, defaultValue): boolean {
		let result;
		let callback = this.getStepCallback(callbackName);
		if (!_.isUndefined(callback)) {
			result = callback();
		}
		if (!!_.isUndefined(result)) {
			result = defaultValue;
		}
		return result;
	}

	private invokeStepCallback(callbackName: PredicateKeyOf<WizardStepComponentController>): void {
		let callback = this.getStepCallback(callbackName);
		if (!_.isUndefined(callback)) {
			callback();
		}
	}

	private hasPrev(): boolean {
		return this.currentStep > 0 && !this.hidePrevious;
	}

	private hasNext(): boolean {
		return this.currentStep < this.steps.length - 1;
	}

	showPrev = () => {
		return this.allowNavigation && this.hasPrev();
	}

	showNext = () => {
		if (!this.hasNext() || !this.allowNavigation) return false;
		return this.getStepCallbackResult('showNext', true);
	}

	showFinish = () => {
		return !this.isViewMode && this.getStepCallbackResult('showFinish', !this.hasNext());
	}

	prevAllowed = () => {
		if (!this.hasNext() && this.isLastStepFixed) {
			return !this.isLastStepFixed();
		}
		return true;
	}

	nextAllowed = () => {
		let nextValid = this.getStepCallbackResult('isValid', true);
		if (nextValid) {
			//check to see if we should automatically go to next step
			let goNext = this.getStepCallbackResult('goNextAutomatically', false);
			if (goNext) {
				this.next();
			}
		}
		return nextValid;
	}

	finishAllowed = () => {
		if (!this.nextAllowed()) return false;
		if (this.isValid) {
			return this.isValid();
		}
		return true;
	}

	private changeStep(newStep): void {
		this.steps[this.currentStep].setActive(false);
		this.currentStep = newStep;
		this.steps[this.currentStep].setActive(true);
	}

	back = () => {
		this.changeStep(this.currentStep - 1);
	}

	next = () => {
		this.invokeStepCallback('onNext');
		this.changeStep(this.currentStep + 1);
	}

	finish = () => {
		this.finishCallback();
	}

	cancel = () => {
		this.cancelCallback();
	}
}

app.component('wizard', {
	controller: WizardComponentController,
	bindings: {
		header: '@?',
		finishCallback: '&finish',
		finishText: '@?',
		cancelCallback: '&cancel',
		isLastStepFixed: '&?',
		isValid: '&?',
		showCancelIcon: '<?',
		mode: '<?',
		jumpToStep: '<?',
		loading: '&?',
		hidePrevious: '<?'
	},
	transclude: true,
	templateUrl: 'partials/components/wizard-component.html',
});
