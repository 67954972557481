import * as _ from 'underscore';

import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetFiltersAccessService } from '@app/modules/widget-settings/widget-filters-access.service';
import { WidgetMetricsAccessService } from '@app/modules/widget-settings/widget-metrics-access.service';
import { WidgetDriversAccessService } from '@app/modules/widget-settings/widget-drivers-access.service';

@Injectable({
	providedIn: 'root'
})
export class WidgetDataAccessService {
	constructor(
		private widgetFiltersAccessService: WidgetFiltersAccessService,
		private widgetMetricsAccessService: WidgetMetricsAccessService,
		private widgetDriversAccessService: WidgetDriversAccessService,
	) {
	}

	hasAccessToPrivateObjects(widgets: Widget[]): Promise<boolean> {
		return Promise.all([
			this.widgetFiltersAccessService.hasPrivatePlatformFilters(widgets),
			this.widgetFiltersAccessService.hasPrivateStudioFilters(widgets),
			this.widgetMetricsAccessService.hasPrivateMetrics(widgets),
			this.widgetDriversAccessService.hasPrivateDrivers(widgets),
		]).then(responses => {
			return !_.contains(responses, true);
		});
	}
}

app.service('widgetDataAccessService', downgradeInjectable(WidgetDataAccessService));