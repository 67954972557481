import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core/cx-http.service';
import { RawConsumptionStatistics } from './entities/raw-consumption-statistics';
import { TrackingAssetType } from './entities/tracking-asset-type.enum';
import { StatisticRefinementMode } from './entities/statistic-refinement-mode.enum';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConsumptionTrackerApiService{
	constructor(
		private cxHttp: CxHttpService
	) {}

	getConsumptionStatistics(type: TrackingAssetType, refinementMode: StatisticRefinementMode): Promise<RawConsumptionStatistics[]> {
		let params = new HttpParams().append('refinementMode', refinementMode);
		return this.cxHttp.get(`rest/consumption/${type.toLowerCase()}/statistics`, { params });
	}

	exportData(type: TrackingAssetType, refinementMode: StatisticRefinementMode): Promise<void> {
		let params = new HttpParams().append('refinementMode', refinementMode);
		return this.cxHttp.post(`rest/consumption/${type.toLowerCase()}/statistics/export`, null, { params });
	}
}