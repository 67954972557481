import { PipeTransform, Pipe } from '@angular/core';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';

@Pipe({
	name: 'treeSort'
})
export class TreeSortPipe implements PipeTransform {
	transform(nodes: TreeNode[], forcedOrder: boolean, displayProperty: string): TreeNode[] {
		if (!nodes) return [];
		if (forcedOrder) return nodes;

		let comparator = this.getComparator(displayProperty);
		return _.sortBy(nodes, comparator);
	}

	getComparator = (displayProperty: string) => {
		return (item: TreeNode) => {
			return item.standardMetric ? this.standardMetricComparator(item) : item[displayProperty];
		};
	}

	private standardMetricComparator = (node: TreeNode): number => {
		return node.name === StandardMetricName.VOLUME 
			? 0
			: AnalyticMetricTypes.isPredefinedMetric(node as AttributeGrouping) ? 1 : 2;
	}
}