import * as _ from 'underscore';
import { ProjectAccessLevel } from '@cxstudio/user-administration/users/project-access/project-access-level-class';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { ProjectAccessDataHelperService } from '@app/modules/user-administration/projects-access/project-access-data-helper.service';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { ProjectsProviderBase } from '@cxstudio/common/projects-provider-base.class';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { ContentProviderOptionsService } from './data-services/content-provider-options.service';

export class DataProvidersService extends ProjectsProviderBase {
	constructor(
		private contentProviderOptionsService: ContentProviderOptionsService,
		private $q: ng.IQService,
		private projectAccessDataHelperService: ProjectAccessDataHelperService,
		private ProjectAccessLevels: ProjectAccessLevelItems
	) {
		super();
	}

	getContentProviders(): ng.IPromise<ContentProvider[]> {
		return this.contentProviderOptionsService.getContentProviders().then(response => response.data);
	}

	getAccounts(contentProviderId: number): ng.IPromise<ContentProviderAccount[]> {
		return this.contentProviderOptionsService.getUserAccounts(contentProviderId, {}).then(response => response.data);
	}

	getProjects(contentProviderId: number, accountId: number, projectAccessLevel?: ProjectAccessLevel): ng.IPromise<AccountProject[]> {
		let projectAccessLevelPromise = this.ProjectAccessLevels.MANAGER === projectAccessLevel
		? this.projectAccessDataHelperService.getCurrentUserProjectAccess(contentProviderId, [accountId])
		: this.$q.when();
		let projectsPromise = this.contentProviderOptionsService.getOptions(
				contentProviderId, 'projects', {contentProviderId, accountId}, true);
		return this.$q.all([projectsPromise, projectAccessLevelPromise]).then((responses: any[]) => {
			let projects = responses[0].data as AccountProject[];
			if (this.ProjectAccessLevels.MANAGER === projectAccessLevel) {
				let projectsAccess = this.getManagerLevelProjectList(responses[1].data?.data);
				return _.filter(projects, this.filterByManagerLevel(projectsAccess));
			} else {
				return projects;
			}
		});
	}

	private filterByManagerLevel(projectsAccess: number[]): (project: AccountProject) => boolean {
		return project => _.contains(projectsAccess, project.projectId);
	}

	private getManagerLevelProjectList(accounts): number[] {
		let filteredProjects = [];
		if (accounts) {
			accounts.forEach((account) => {
				_.chain(account.projects)
					.filter(project => this.ProjectAccessLevels.MANAGER.value === project.accessLevel)
					.forEach((project) => filteredProjects.push(project.project.id));
			});
		}

		return filteredProjects;
	}
}

app.service('dataProvidersRefreshService', DataProvidersService);
