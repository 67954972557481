import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AlertTriggerCriteria } from '@app/modules/alert/alert-trigger-criteria';
import { UIOption } from '@clarabridge/unified-angular-components';

@Injectable({
	providedIn: 'root'
})
export class AlertTriggerCriteriaOptionsService {
	private readonly BASIC_CRITERIA: AlertTriggerCriteria[] = [
		AlertTriggerCriteria.LESS_THAN_OR_EQUAL,
		AlertTriggerCriteria.GREATER_THAN_OR_EQUAL,
		AlertTriggerCriteria.INCREASE_GREATER_OR_EQUAL,
		AlertTriggerCriteria.DECREASE_GREATER_OR_EQUAL,
		AlertTriggerCriteria.CHANGE_GREATER_OR_EQUAL
	];

	constructor(private locale: CxLocaleService) {}

	getOption = (criterion: AlertTriggerCriteria): UIOption<AlertTriggerCriteria> => {
		return {
			displayName: this.locale.getString(`alert.${criterion}`),
			value: criterion
		};
	}

	getOptions = (criteria: AlertTriggerCriteria[]): UIOption<AlertTriggerCriteria>[] => {
		return criteria.map(criterion => this.getOption(criterion));
	}

	getBasicOptions = (): UIOption<AlertTriggerCriteria>[] => {
		return this.getOptions(this.BASIC_CRITERIA);
	}
}

app.service('alertTriggerCriteriaOptionsService', downgradeInjectable(AlertTriggerCriteriaOptionsService));
