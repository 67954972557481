import { Inject, Injectable } from '@angular/core';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { RowNode } from 'ag-grid-community';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { AgGridDashboardService } from '../ag-grid-dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class AgGridBookActionsService {

	constructor(
		private readonly dashboardGridHelperService: DashboardGridHelperService,
		private readonly agGridDashboardService: AgGridDashboardService,
		@Inject('redirectService') private readonly redirectService: RedirectService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
	) {}

	showComponents(book: Book): void {
		this.dashboardService.showBookComponents(book);
	}

	renameBook(node: RowNode): void {
		this.dashboardService.renameBook(node.data).then((renamedBook) => {
			this.dashboardGridHelperService.updateGridRow(node, renamedBook);
		});
	}

	copyLink(book: Book): void {
		this.agGridDashboardService.copyBookLink(book);
	}

	editBook(node: RowNode): void {
		this.redirectService.goToBookEditor(node.data.id);
	}

}
