import { Node } from 'prosemirror-model';
import { EditorView, NodeView } from 'prosemirror-view';
import { NodeSelection } from 'prosemirror-state';
import { ImageSettingsEntry } from './image-settings-modal.component';

export class ImageView implements NodeView {

	dom: HTMLElement;
	img: HTMLElement;
	handle: HTMLElement;
	actionPanel: HTMLElement;

	openImageSettingsDialog: (entry: ImageSettingsEntry) => Promise<any>;

	constructor(node: Node, view: EditorView, getPos: () => number) {
		const outer = this.initializeOuter(node);
		const img = this.initializeImg(node);
		const handle = this.initializeHandle(node, view, getPos, img);
		const actionPanel = this.initializeActionPanel(node, view, getPos, img);

		outer.appendChild(handle);
		outer.appendChild(actionPanel);
		outer.appendChild(img);

		outer.onmouseover = (e) => this.selectImage();
		outer.onmouseout = (e) => this.deselectImage();

		this.dom = outer;
		this.img = img;
		this.handle = handle;
		this.actionPanel = actionPanel;
	}

	initializeOuter = (node: Node): HTMLElement => {
		const outer = document.createElement('span');
		outer.style.position = 'relative';
		outer.style.margin = '16px';
		outer.style.display = 'inline-block';
		outer.style.lineHeight = '0';
		return outer;
	}

	initializeImg = (node: Node): HTMLImageElement => {
		const img = document.createElement('img');

		img.onload = () => {
			const aspectRatio = img.width / img.height;

			img.width = node.attrs.width;
			if (!node.attrs.height) {
				img.height = (node.attrs as any).height = (img.width / aspectRatio);
			} else {
				img.height = node.attrs.height;
			}
		};
		img.setAttribute('src', node.attrs.src);

		return img;
	}

	initializeHandle = (node: Node, view: EditorView, getPos: () => number, img: HTMLImageElement): HTMLElement => {
		const handle = document.createElement('span');
		handle.style.position = 'absolute';
		handle.style.bottom = '0px';
		handle.style.right = '0px';
		handle.style.width = '10px';
		handle.style.height = '10px';
		handle.style.margin = '-5px';
		handle.style.backgroundColor = '#67a0e6';
		handle.style.display = 'none';
		handle.style.cursor = 'nwse-resize';

		handle.onmousedown = (e: MouseEvent) => {
			e.preventDefault();

			const startX = e.pageX;
			const startY = e.pageY;

			const startWidth = node.attrs.width;
			const startHeight = node.attrs.height;
			const aspectRatio = startWidth / startHeight;

			const onMouseMove = (moveEvent: MouseEvent) => {
				const currentX = moveEvent.pageX;
				const currentY = moveEvent.pageY;

				const diffInPx = currentX - startX;
				const newWidth = startWidth + diffInPx;

				img.width = newWidth;
				img.height = newWidth / aspectRatio;
			};

			const onMouseUp = (mouseUpEvent: MouseEvent) => {
				mouseUpEvent.preventDefault();

				document.removeEventListener('mousemove', onMouseMove);
				document.removeEventListener('mouseup', onMouseUp);

				const transaction = view.state.tr.setNodeMarkup(getPos(), null, {
					src: node.attrs.src,
					alt: node.attrs.alt,
					width: img.width,
					height: img.height
				});
				view.dispatch(transaction);
			};

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		};

		return handle;
	}

	initializeActionPanel = (node: Node, view: EditorView, getPos: () => number, img: HTMLImageElement): HTMLElement => {
		const editImage = document.createElement('button');
		editImage.className = 'btn btn-icon q-icon-edit';
		editImage.style.margin = '5px 0';
		editImage.style.borderRadius = '100%';
		editImage.style.backgroundColor = '#43444f';
		editImage.style.color = 'white';
		editImage.style.cursor = 'pointer';


		editImage.onclick = (e) => {
			let entry: ImageSettingsEntry = {
				altText: node.attrs.alt,
				imageWidth: node.attrs.width,
				imageHeight: node.attrs.height
			};
			this.openImageSettingsDialog(entry)
				.then((output: ImageSettingsEntry) => {
					const imgPos = getPos();
					const transaction = view.state.tr.setNodeMarkup(getPos(), null, {
						src: node.attrs.src,
						alt: output.altText,
						width: output.imageWidth,
						height: output.imageHeight
					});
					view.dispatch(transaction);

					setTimeout(() => {
						const scrollToImg = view.state.tr
							.setSelection(new NodeSelection(view.state.doc.resolve(imgPos)))
							.scrollIntoView();
						view.dispatch(scrollToImg);
					});
				})
				.catch(() => {});
		};

		const deleteImage = document.createElement('button');
		deleteImage.className = 'btn btn-icon q-icon-trash';
		deleteImage.style.margin = '5px 0';
		deleteImage.style.borderRadius = '100%';
		deleteImage.style.backgroundColor = '#43444f';
		deleteImage.style.color = 'white';
		deleteImage.style.cursor = 'pointer';

		deleteImage.onclick = (e) => {
			const transaction = view.state.tr.deleteSelection();
			view.dispatch(transaction);
		};

		const actionPanel = document.createElement('span');
		actionPanel.style.position = 'absolute';
		actionPanel.style.top = '10px';
		actionPanel.style.right = '10px';
		actionPanel.style.width = '50px';
		actionPanel.style.backgroundColor = 'white';
		actionPanel.style.borderRadius = '10%';
		actionPanel.style.textAlign = 'center';
		actionPanel.style.display = 'none';

		actionPanel.appendChild(editImage);
		actionPanel.appendChild(deleteImage);

		return actionPanel;
	}

	selectImage = (): void => {
		this.img.classList.add('ProseMirror-selectednode');
		this.handle.style.display = '';
		this.actionPanel.style.display = '';
	}

	deselectImage = (): void => {
		this.img.classList.remove('ProseMirror-selectednode');
		this.handle.style.display = 'none';
		this.actionPanel.style.display = 'none';
	}

}
