import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { Component, OnInit, Input, Output, EventEmitter,
	OnChanges, SimpleChanges } from '@angular/core';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { FilterRuleTypes, FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FilterBuilderUtils } from '@app/modules/filter-builder/filter-builder-utils';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Component({
	selector: 'filter-builder',
	templateUrl: './filter-builder.component.html'
})
export class FilterBuilderComponent implements OnInit, OnChanges {
	@Input('maxRules') maxFilterRules: number;
	@Input('ruleSet') filterRules: IFilterRule[];
	@Input() filterableFields;
	@Input('projectProperties') projectSelection: AccountOrWorkspaceProject;
	@Input() documentLevelOnly: boolean;
	@Input() ngDisabled: boolean;
	@Input() removeDateRangeRestriction: boolean;
	@Input() appendToBody: boolean;
	@Input() displayVertical = false;

	@Output() filterChange = new EventEmitter<void>();

	allowDateRange: boolean;
	filterFieldsRefresh: number = 1;


	ngOnInit(): void {
		this.checkRestrictedRuleOptions();
		if (!this.filterRules) {
			this.filterRules = [cloneDeep(FilterEmptyObject.RULE)];
		} else if (this.filterRules.length === 0) {
			this.filterRules.push(cloneDeep(FilterEmptyObject.RULE));
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filterRules?.previousValue !== changes.filterRules?.currentValue
				&& !_.isEmpty(changes.filterRules?.currentValue)
				&& !changes.filterRules?.isFirstChange()) {
			this.filterChange.emit();
		}
		if (changes.filterableFields?.previousValue !== changes.filterableFields?.currentValue
				&& !changes.filterableFields?.isFirstChange()) {
			this.checkRestrictedRuleOptions();
		}
	}

	private isCountLimitReached = () => {
		return this.filterRules.length >= this.maxFilterRules;
	}

	getClasses = (rule: IFilterRule): any => {
		return FilterBuilderUtils.getClasses(rule);
	}

	filterRuleCanBeAdded = () => {
		if (this.removeDateRangeRestriction) {
			return !this.isCountLimitReached();
		}
		return !this.isCountLimitReached() && !this.hasDateRange();
	}

	private hasDateRange = (): boolean => {
		return !!_.find(this.filterRules, FilterRuleTypes.isDateRangeRule);
	}

	showCountNote = () => {
		return this.isCountLimitReached();
	}

	filterRuleCanBeRemoved = (rule: IFilterRule) => {
		return this.filterRuleIsDefined(rule) || (this.filterRules.length > 1);
	}

	filterRuleIsDefined = (rule: IFilterRule) => {
		if (rule?.type !== FilterRuleType.empty)
			return true;
	}

	shouldOpen = (rule: IFilterRule): boolean => {
		return this.filterRuleCanBeRemoved(rule) && !this.filterRuleIsDefined(rule);
	}

	addFilterRule = () => {
		if (this.filterRuleCanBeAdded()) {
			this.filterRules.push(cloneDeep(FilterEmptyObject.RULE));
			this.checkRestrictedRuleOptions();
		}
	}

	private filterRuleRequiresReset(): boolean {
		return this.filterRules.length === 1;
	}

	removeFilterRule = (index: number) => {
		if (this.filterRuleCanBeRemoved(this.filterRules[index]) && !this.filterRuleRequiresReset()) {
			this.filterRules.splice(index, 1);
		} else {
			this.filterRules[0] = cloneDeep(FilterEmptyObject.RULE);
		}
		this.checkRestrictedRuleOptions();
		this.filterChange.emit();
	}

	onRuleChange = (newRule: IFilterRule) => {
		this.checkRestrictedRuleOptions();
		this.filterChange.emit();
	}

	private checkRestrictedRuleOptions = () => {
		let disabledNlpQuery = _.findWhere(this.filterRules, {name: ClarabridgeMetricName.ES_QUERY});
		let appliedTextOption = _.findWhere(this.filterRules, {type: FilterRuleType.text});
		let nlpQueryOption = SearchableHierarchyUtils.deepSearchByName(this.filterableFields, ClarabridgeMetricName.ES_QUERY);
		let selectableTextOption = SearchableHierarchyUtils.deepSearchByName(this.filterableFields, FilterRuleType.text);

		let hideDateRange = this.removeDateRangeRestriction
			? this.hasDateRange()
			: this.filterRules.length > 1;

		this.allowDateRange = !hideDateRange; // any option selected

		if (nlpQueryOption)
			nlpQueryOption._disabled = disabledNlpQuery;

		if (selectableTextOption)
			selectableTextOption._disabled = !!appliedTextOption;

		// delay to allow changes propagate to child components
		setTimeout(() => this.filterFieldsRefresh++);
	}
}

app.directive('filterBuilder', downgradeComponent({component: FilterBuilderComponent}));
