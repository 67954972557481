import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { GridApi, MenuItemDef } from 'ag-grid-enterprise';
import { AgGridCommonActionsService } from '../actions/ag-grid-common-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ShowHideOptionService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly agGridCommonActionsService: AgGridCommonActionsService,
	) {}

	get = (subMenu: (string | MenuItemDef)[]) => {
		return {
			name: this.locale.getString('common.showHide'),
			subMenu
		};
	}

	getSubmenu = (gridApi: GridApi, object: any, filterManager: TableFilterManager): (string | MenuItemDef)[] => {
		let showOption = this.getShowOption(gridApi, object);
		let hideOption = this.getHideOption(gridApi, object);
		let showHideHiddenOption = this.getShowHideSubmenuOption(gridApi, filterManager);

		return [showOption, hideOption, DashboardGridHelperService.MENU_OPTIONS_SEPARATOR, showHideHiddenOption];
	}

	private getShowOption = (gridApi: GridApi, object: any): MenuItemDef => {
		return {
			name: this.locale.getString('common.show'),
			disabled: false,
			action: () => {
				this.onShow(gridApi, object);
				this.filterAndRedraw(gridApi);
			}
		};
	}
	private getHideOption = (gridApi: GridApi, object: any): MenuItemDef => {
		return {
			name: this.locale.getString('common.hide'),
			disabled: false,
			action: () => {
				this.onHide(gridApi, object);
				this.filterAndRedraw(gridApi);
			}
		};
	}

	getBulkSubmenu = (gridApi: GridApi, filterManager: TableFilterManager): (string | MenuItemDef)[] => {
		let bulkShowOption = this.getBulkShowOption(gridApi);
		let bulkHideOption = this.getBulkHideOption(gridApi);
		let showHideHiddenOption = this.getShowHideSubmenuOption(gridApi, filterManager);

		return [bulkShowOption, bulkHideOption, DashboardGridHelperService.MENU_OPTIONS_SEPARATOR, showHideHiddenOption];
	}

	private getBulkShowOption(gridApi: GridApi): MenuItemDef {
		return {
			name: this.locale.getString('common.show'),
			disabled: false,
			action: () => {
				this.getSelectedObjects(gridApi).forEach(object => {
					this.onShow(gridApi, object);
				});
				this.filterAndRedraw(gridApi);
			}
		};
	}

	private getBulkHideOption(gridApi: GridApi): MenuItemDef {
		return {
			name: this.locale.getString('common.hide'),
			disabled: false,
			action: () => {
				this.getSelectedObjects(gridApi).forEach(object => {
					this.onHide(gridApi, object);
				});
				this.filterAndRedraw(gridApi);
			}
		};
	}

	private getSelectedObjects(gridApi: GridApi): any[] {
		return gridApi.getSelectedRows();
	}

	private onShow(gridApi: GridApi, object: any) {
		object.hide = true;
		this.agGridCommonActionsService.toggleHide(object);
		gridApi.deselectAll();
	}

	private onHide(gridApi: GridApi, object: any) {
		object.hide = false;
		this.agGridCommonActionsService.toggleHide(object);
		gridApi.deselectAll();
	}

	private filterAndRedraw(gridApi: GridApi): void {
		gridApi.onFilterChanged();
		gridApi.redrawRows();
	}

	private getShowHideSubmenuOption(gridApi: GridApi, filterManager: TableFilterManager): MenuItemDef {
		return filterManager.isShowHidden()
			? this.getHideAllHiddenOption(gridApi, filterManager)
			: this.getShowAllHiddenOption(gridApi, filterManager);
	}

	private getShowAllHiddenOption(gridApi: GridApi, filterManager: TableFilterManager): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.showAllHidden'),
			action: () => {
				filterManager.setShowHidden(true);
			}
		};
	}

	private getHideAllHiddenOption(gridApi: GridApi, filterManager: TableFilterManager): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.hideAllHidden'),
			action: () => {
				filterManager.setShowHidden(false);
			}
		};
	}
}
