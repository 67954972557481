import { Subject } from 'rxjs';

export class Pagination {
	changeSubject = new Subject();
	pageSize: number;
	currentPage: number;
	totalItems: number;
	isPaginated?: boolean;

	constructor(pageSize: number, currentPage = 1) {
		this.pageSize = pageSize;
		this.currentPage = currentPage;
		this.totalItems = 0;
		this.isPaginated = pageSize > 0;
	}

	getPageStart(): number {
		return (this.currentPage - 1) * this.pageSize;
	}

	getPageEnd(): number {
		return this.currentPage * this.pageSize;
	}

	getChangeObserver() {
		return this.changeSubject.asObservable();
	}

	getTotalPages(): number {
		let exactTotal = this.totalItems / this.pageSize;
		return (Math.floor(exactTotal) === exactTotal) ? exactTotal : Math.ceil(exactTotal);
	}

	checkAvailablePages(): void {
		if (this.totalItems < this.pageSize) {
			this.currentPage = 1;
			if (this.totalItems !== 0) {
				this.changeSubject.next();
			}
		}
	}
}
