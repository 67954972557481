import { Injectable } from '@angular/core';
import { DocExplorerPreferences } from './doc-explorer-preferences.provider';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { DocViewPaneSettings } from './doc-view-pane-settings';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as cloneDeep from 'lodash.clonedeep';

@Injectable()
export class DocExplorerPreferencesProviderService {

	constructor(private sessionPreferences: SessionPreferencesService) {
	}

	getInstance(settings?: DocViewPaneSettings) {
		return new DocExplorerPreferences(settings, this.sessionPreferences);
	}

	getEmptyPreferences() {
		let preferences = cloneDeep(this.getInstance(DocViewPaneSettings.default()));
		delete preferences.sessionPreferences;
		return preferences;
	}
}
app.service('docExplorerPreferencesProvider', downgradeInjectable(DocExplorerPreferencesProviderService));
