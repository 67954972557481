import { HttpParams } from '@angular/common/http';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { PaginationHttpParams } from '@app/shared/components/pagination/pagination-http-params';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';


export class AssetApiUtils {

	static getAssetNameQueryParams = (asset: StudioAsset): HttpParams => {
		return new HttpParams().append('name', asset.name);
	}

	static getAssetQueryParams = (asset: ProjectAsset): HttpParams => {
		let params: HttpParams = AssetApiUtils.getStudioAssetParams(asset);

		if (!_.isUndefined(asset.contentProviderId)) {
			params = params.append('contentProviderId', asset.contentProviderId.toString());
		}

		if (!_.isUndefined(asset.accountId)) {
			params = params.append('accountId', asset.accountId.toString());
		}

		if (!_.isUndefined(asset.projectId)) {
			params = params.append('projectId', asset.projectId.toString());
		}

		return params;
	}

	private static getStudioAssetParams(asset: ProjectAsset): HttpParams {
		return new HttpParams()
			.append('assetId', asset.assetId.toString())
			.append('name', asset.name)
			.append('type', asset.type);
	}

	static getPaginationQueryParams(pagination: Pagination): HttpParams {
		return PaginationHttpParams.of(pagination);
	}

	static getAssetPaginationQueryParams(asset: ProjectAsset, pagination: Pagination): HttpParams {
		let paginationParams: HttpParams = AssetApiUtils.getPaginationQueryParams(pagination);

		return paginationParams
			.append('cpId', asset.contentProviderId.toString())
			.append('projectId', asset.projectId.toString());
	}

}
