<div class="d-flex flex-direction-column fill-container">
	<div class="grid-list-tools">
		<div class="tools">
			<div class="grid-filters justify-between">
				<label class="font-bold mb-0">
					{{searchLabel}}
					<input
						type="text"
						class="grid-search-bar d-block"
						attr.aria-label="{{searchLabel}}"
						[(ngModel)]="searchText"
						[debounce]="300">
				</label>
				<div class="grid-buttons">
					<button *ngIf="canTransferTemplates()"
						(click)="transferTemplates()"
						class="btn btn-primary">
						{{'templates.importExport'|i18n}}
					</button>
					<button
						class="btn btn-primary"
						*ngIf="canManageTemplates()"
						(click)="addFolder()"
						data-testid="add-folder-button"
					>
						{{'dashboard.newFolder'|i18n}}
					</button>
				</div>
			</div>
		</div>
	</div>

	<div [ngBusy]="loading" class="flex-fill">
		<default-objects-table
			tableName="assetTemplates"
			[getContextMenuItems]="getContextMenuItems"
			[columnDefs]="columnDefs"
			[rowData]="templates | nestedObjects"
			[quickFilterText]="searchText"
			(gridReady)="onGridReady($event)"
			(rowAction)="onTemplateAction($event)"
		></default-objects-table>
	</div>
</div>
