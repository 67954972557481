import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnInit, ChangeDetectorRef, OnChanges, Inject } from '@angular/core';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ComplianceManagementApiService, Survey, SurveyRequestDTO, SurveyResponseDTO } from './explorer-compliance-management-api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { TypeGuards } from '@app/util/typeguards.class';
import { Security } from '@cxstudio/auth/security-service';

interface SurveyDetails {
	DivisionID: string;
	LastAccessed: string | null;
	LastActivated: string;
	LastModified: string;
	Permissions: any;
	ProjectCategory: string;
	ProjectType?: string;
	SharedViaGroup: boolean;
	SurveyBrandID: string;
	SurveyCreationDate: string;
	SurveyDescription: string;
	SurveyID: string;
	SurveyLanguage: string;
	SurveyName: string;
	SurveyOwnerID: string;
	SurveyStatus: string;
	SurveyType: string;
	UserFirstName: string;
	UserLastName: string;
}

export enum ProjectType {
	TicketsFollowUpDetails = 'TicketsFollowUpDetails'
}

export enum SurveyStatus {
	Active = 'Active',
	Inactive = 'Inactive'
}

@Component({
	selector: 'explorer-compliance-management',
	templateUrl: './explorer-compliance-management.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExplorerComplianceManagementComponent implements OnInit, OnChanges, OnDestroy {
	@Input() document: ConversationDocument;
	iframeUrl: SafeResourceUrl;
	surveyList: Survey[];
	selectedSurvey: Survey;
	selectedSurveyId: string;
	previousResponse: SurveyResponseDTO;
	private isProcessingResponse: boolean = false;
	isLoading: boolean = false;


	constructor(
		protected readonly sanitizer: DomSanitizer,
		protected readonly ref: ChangeDetectorRef,
		private complianceManagementApiService: ComplianceManagementApiService,
		@Inject('security') protected readonly security: Security
	) {}

	ngOnInit(): void {
		this.resetSelections();
		window.addEventListener('message', this.captureResponse);
		this.isLoading = true;
		this.initializeTab();
	}

	ngOnChanges(changes: SimpleChanges<ExplorerComplianceManagementComponent>): void {
		if (ChangeUtils.hasChange(changes.document)) {
			this.isLoading = true;
			this.resetSelections();
			this.initializeTab();
		}
	}

	ngOnDestroy(): void {
		window.removeEventListener('message', this.captureResponse);
	}

	resetSelections(): void {
		this.selectedSurvey = undefined;
		this.selectedSurveyId = undefined;
		this.previousResponse = undefined;
	}

	captureResponse = async (event: MessageEvent) => {
		// debouncing concurrent executions
		if (this.isProcessingResponse) return;
		this.isProcessingResponse = true;
		const newResponseId = this.getNewResponseId(event);

		if (!newResponseId)  {
			this.isProcessingResponse = false;
			return;
		}

		const newComplianceSurveyData: SurveyRequestDTO = {
			documentId: this.document.id.toString(),
			surveyId: this.selectedSurvey.id,
			responseId: newResponseId,
			surveyName: this.selectedSurvey.name,
			brandURL: this.selectedSurvey.brandUrl
		};

		if(this.previousResponse) {
			await this.complianceManagementApiService.
				updateComplianceManagmentSurveyData(newComplianceSurveyData);
			this.previousResponse.audit = true;
			this.previousResponse.responseId = newResponseId;
		} else {
			await this.complianceManagementApiService.
				createComplianceManagementSurveyData(newComplianceSurveyData);

			this.previousResponse = {
				audit: false,
				... newComplianceSurveyData
			} as SurveyResponseDTO;
		}
		this.ref.markForCheck();
		this.isProcessingResponse = false;
	}

	getNewResponseId(event: MessageEvent): string {
		const regex = /([a-zA-Z0-9]*)\|(SV_[a-zA-Z0-9]*)\|(FS_[a-zA-Z0-9]*)/;
		if (event?.data && TypeGuards.isString(event.data)) {
			const parsedEvent = event.data.match(regex);
			if (parsedEvent) {
				const newResponseId = parsedEvent[3].replace('FS_', 'R_');
				this.selectedSurvey.responseId = newResponseId;
				return newResponseId;
			}
		}
		return null;
	}

	async initializeTab(): Promise<void> {
		this.previousResponse = await this.getLastResponse();

		if (this.previousResponse) {
			this.selectedSurveyId = this.previousResponse.surveyId;
			this.selectedSurvey = {
				id: this.previousResponse.surveyId,
				name: this.previousResponse.surveyName,
				brandUrl: this.previousResponse.brandURL,
				responseId: this.previousResponse.responseId,
			};
			this.isLoading = false;
			this.updateIframeUrl();
		} else {
			this.surveyList = [];
			this.isLoading = false;
			this.ref.markForCheck();
			this.getSurveyList();
		}
	}

	async getLastResponse(): Promise<SurveyResponseDTO> {
		if (!this.document) return undefined;
		return this.complianceManagementApiService.getComplianceManagementSurveyData(this.document.id.toString());
	}

	async getSurveyList(): Promise<void> {
		const res = await this.complianceManagementApiService.getSurveys();
		const parsedData = JSON.parse(res.surveys);
		const surveysArray: SurveyDetails[] = Object.values(parsedData.surveys);
		const brandUrl: string = res.brandUrl;
		// Filter for FDv2 survey project type and transform survey object
		this.surveyList = surveysArray
			.filter(survey => 
				survey.ProjectType === ProjectType.TicketsFollowUpDetails
				&& survey.SurveyStatus === SurveyStatus.Active
			)
			.map(survey => ({
				id: survey.SurveyID,
				name: survey.SurveyName,
				brandUrl
			} as Survey));

		this.ref.markForCheck();
	}

	switchSurvey(surveyId: string): void {
		this.selectedSurveyId = surveyId;
		this.selectedSurvey = this.surveyList.find(survey => survey.id === surveyId);
		this.selectedSurvey.responseId = undefined;
		this.updateIframeUrl();
	}

	updateIframeUrl(): void {
		let URL = `https://${this.selectedSurvey.brandUrl}/jfe/form/${this.selectedSurvey.id}?StudioUserId=${this.security.getUser().userEmail}`;
		if(this.selectedSurvey.responseId !== undefined) {
			URL = `${URL}&Q_R=${this.selectedSurvey.responseId}&Q_R_DEL=1`;
		}
		this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL);
		this.ref.markForCheck();
	}
}
