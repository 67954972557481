
import * as _ from 'underscore';
import * as uib from 'angular-ui-bootstrap';
import WidgetSettingsService from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import AssetSelectorComponentController from './asset-selector.component';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';

export default class GroupingSelectorComponentController extends AssetSelectorComponentController {

	onUpdateSortBy: (change: { $sortBy: any }) => void;
	private labelKey: string = 'mobile.grouping';
	keepRevokedItem: boolean;

	constructor(
		$scope: ng.IScope,
		widgetSettingsService: WidgetSettingsService,
		optionsBuilderProvider: OptionsBuilderProvider,
		$uibModal: uib.IModalService,
		tagService: TagsService,
		cbSettingsService,
		reportMetricService: ReportMetricService,
		private locale: ILocale,
		private reportSettingsService: ReportSettingsService
	) {
		super(
			$scope,
			widgetSettingsService,
			optionsBuilderProvider,
			$uibModal,
			tagService,
			cbSettingsService,
			reportMetricService,
			true);
	}

	init(): void {
	}

	getGroupingLabel = (): string => {
		return this.locale.getString(this.labelKey);
	}

	onChange = (node): void => {
		if (this.projectIdentifier) {
			let project: WidgetProject = {
				project: this.projectIdentifier.projectId,
				contentProviderId: this.projectIdentifier.contentProviderId,
				accountId: this.projectIdentifier.accountId
			};
			this.reportSettingsService.populateGroupingProjectDefaults(project, node).then(defaultSettings => {
				_.extend(node, defaultSettings);
				this.asset = node;
				this.updateAsset();
			});
		} else {
			this.updateAsset();
		}
	}

}

app.component('groupingSelector', {
	controller: GroupingSelectorComponentController,
	templateUrl: 'partials/components/grouping-selector.html',
	bindings: {
		projectIdentifier: '<',
		asset: '=',
		onAssetChange: '&',
		onAssetClear: '&?',
		onUpdateSortBy: '&?',
		labelKey: '@',
		keepRevokedItem: '<?',
		hideCapitalizationOption: '<?',
		// to be used together with other similar components (grouping and calculation selectors) to reuse the same cache
		widgetSettingsCache: '=?',
		// only necessary for mobile application settings
		screenIndex: '=?',
		showInlineHelp: '<?',
		hideClearButton: '<?',
		customValidation: '<?'
	}
});
