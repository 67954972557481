import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import HighchartsAccessibilityUtils from '@cxstudio/reports/utils/highchart/highcharts-accessibility-utils';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { IChartDefinitionFactory } from './chart-definition-factory.interface';
import { HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';

app.factory('HighchartsPieAnalyticDefinition', (highchartsAnalyticUtils: HighchartsAnalyticUtils, highchartsUtils: HighchartsUtilsService,
	highchartsAccessibilityUtils: HighchartsAccessibilityUtils) => {


	return class implements IChartDefinitionFactory {
		constructor(
			private reportDefinition,
			private element
		) {}

		getChartOptions = (): Highcharts.Options => {
			let factoryScope = this; // tslint:disable-line:no-invalid-this no-this-assignment

			let options = factoryScope.reportDefinition.options;
			let utils = factoryScope.reportDefinition.utils;

			let data = ObjectUtils.copy(factoryScope.reportDefinition.dataObject.data);
			let seriesNameFormatters = utils.getGroupingFormatters();
			let groups = utils.getGroupings();
			let hierarchyData = AnalyticDataUtils.getHierarchyData(
				data, groups, seriesNameFormatters);

			let metrics = utils.getMetricNames();
			let metric = metrics ? metrics[0] : '';
			let props = {
				formatter: utils.dataFormatter,
				metric,
				donut: isTrue(options.donut),
				sideBySide: isTrue(options.sideBySide)
			};

			let serieNames = ObjectUtils.copy(groups);
			let processors = [
				highchartsAnalyticUtils.dataLevelTransformer(serieNames),
				highchartsAnalyticUtils.seriesValuesProcessor(metric, true),
				factoryScope.applyValueAdjustments,
			];

			let series;

			// reuse colors if NOT using sentiment color
			let legendFunction = factoryScope.reportDefinition.utils.legendFunctions && factoryScope.reportDefinition.utils.legendFunctions.color;
			let reuseColors = !legendFunction;

			if (!props.sideBySide || (props.sideBySide && groups.length < 2)) {
				series = highchartsAnalyticUtils.getSeries(hierarchyData, processors);

				highchartsAnalyticUtils.applyColorProperties(series, utils.colorFunction, reuseColors);
				highchartsAnalyticUtils.applyPieTooltipProperties(series, props,
					options.attributeSelections && options.attributeSelections.size);
				highchartsAnalyticUtils.applyDataLabelsProperties(series,
					options.showGroupNames, options.showLabels, utils.dataFormatter);
				highchartsAnalyticUtils.applySizeProperties(series, groups.length, 1,
					props.donut, options.showGroupNames);

				series = _.union(series, factoryScope.generateLegendItems(series, false, legendFunction, factoryScope.reportDefinition) as _.List<any>);
			} else {
				serieNames.pop(); // remove one group
				let pies = _.map(hierarchyData, (node) => {
					serieNames[0] = node._name;
					return highchartsAnalyticUtils.getSeries(node._children, processors);
				});
				series = _.flatten(pies);

				highchartsAnalyticUtils.applyPieTooltipProperties(series, props,
					options.attributeSelections && options.attributeSelections.size);
				highchartsAnalyticUtils.applyDataLabelsProperties(series,
					options.showGroupNames, options.showLabels, utils.dataFormatter);

				let levelsCount = groups.length - 1;
				_.each(pies, (pieSeries, index) => {
					highchartsAnalyticUtils.applySizeProperties(pieSeries, levelsCount, pies.length,
						props.donut, options.showGroupNames);
					highchartsAnalyticUtils.applyPositionProperties(pieSeries, pies.length, index);

				});
				highchartsAnalyticUtils.applyColorProperties(factoryScope.getGroupedSeries(pies), utils.colorFunction, reuseColors);

				series = _.union(series, factoryScope.generateLegendItems(series, _.zip.apply(null, pies),
					legendFunction, factoryScope.reportDefinition) as _.List<any>);
			}

			let opts = {
				credits: {
					enabled: false
				},
				accessibility: {
					point: {
						descriptionFormatter: (point: Partial<HighchartsFunctionScope>) => {
							return highchartsAccessibilityUtils.generateAriaLabel(point);
						}
					}
				},
				chart: {
					spacingTop: 30,
					spacingBottom: highchartsUtils.getSpacingBottom(factoryScope.reportDefinition),
					type: 'pie',
					borderRadius: 0,
					renderTo: factoryScope.element[0]
				},
				title: {
					text: null
				},
				tooltip: {
					enabled: true
				},
				plotOptions: {
					pie: {
						allowPointSelect: false,
						cursor: 'pointer',
						dataLabels: {
							enabled: isTrue(options.showLabels) || isTrue(options.showGroupNames)
						},
						stickyTracking: false,
						showInLegend: false,
						point: {
							events: {
								mouseOver(): void {
									// tslint:disable-next-line:no-invalid-this
									highchartsUtils.selectPoint(factoryScope.reportDefinition, this);
								},
								mouseOut(): void {
									highchartsUtils.cancelPointSelection(factoryScope.reportDefinition);
								},
								legendItemClick(event): void {
									// tslint:disable-next-line:no-this-assignment no-invalid-this
									let currentPoint = this;
									if (!currentPoint.name) {
										event.preventDefault();
										return;
									}
									let serieIndexes = currentPoint.series.userOptions.linkedSeries;
									_.each(serieIndexes, (serieIndex) => {
										let originalSerie = currentPoint.series.chart.series[serieIndex];
										_.filter(originalSerie.data, {name: currentPoint.name}).forEach((point) => {
											point.setVisible(!currentPoint.visible);
										});
									});
								}
							}
						}
					},
					series: {
						events: {
							afterAnimate(): void {
								// tslint:disable-next-line:no-invalid-this
								highchartsUtils.handleRenderedEvent(this, factoryScope.reportDefinition);
							}
						}
					}
				},
				legend: {
					enabled: options.showLegend,
					symbolRadius: 0
				},
				exporting: {
					enabled: false
				},
				series
			};

			highchartsUtils.cleanDataNames(opts);
			highchartsAccessibilityUtils.applyBorder(opts);

			return opts as Highcharts.Options;
		}

		private applyValueAdjustments = (series): Array<Partial<Highcharts.Series>> => {
			let factoryScope = this; // tslint:disable-line:no-invalid-this no-this-assignment

			// adjust sizing as necessary, and save raw value for tooltip
			return _.each(series, (oneSeries) => {
				_.each(oneSeries.data, (dataPoint) => {
					if (!_.isUndefined(dataPoint.y) && dataPoint.object) {
						dataPoint.rawValue = dataPoint.y;
						dataPoint.y = factoryScope.reportDefinition.utils.sizeFunction(dataPoint.object);
					}
				});
			});
		}

		// return one serie for each level, with data points gathered from all pies
		private getGroupedSeries(pies): any[] {
			let pointsPerLevel = [];
			_.each(pies, (pieSeries) => {
				_.each(pieSeries, (serie, levelIndex) => {
					if (!pointsPerLevel[levelIndex]) {
						pointsPerLevel[levelIndex] = [];
					}
					pointsPerLevel[levelIndex].pushAll(serie.data);
				});
			});
			// convert to series format
			return _.map(pointsPerLevel, data => ({data}));
		}


		private defaultLegendItemGenerator = (seriesArray): Array<{name: string, color: string, y}> => {
			function generator(point): any {
				return {
					name: point.name,
					color: point.color,
					y: null
				};
			}

			return _.chain(seriesArray)
				.map('data')
				.flatten()
				.uniq(false, 'name')
				.map(generator).value();
		}


		// generator to use for sentiment -- needs to wrap the sentiment color function so we can refer back to it
		private sentimentLegendItemGenerator = (sentimentColorFunction, reportDefinition): (...args) => Array<Partial<Highcharts.Series>> => {
			function generator(point): any {
				let sentimentLegendItem = sentimentColorFunction(point.object);
				return {
					name: ColorUtilsHelper.checkForRecolor(point, reportDefinition.options,
						{ displayName: sentimentLegendItem.name },
						reportDefinition.utils.getGroupingFormatters()),
					color: point.color,
					y: null,
					events: { legendItemClick: () => false },
					order: sentimentLegendItem.order
				};
			}

			// filtering before generating items can result in some sentiments not getting representation in legend
			// therefore generate first, then filter to unique items
			return (seriesArray) => {
				return _.chain(seriesArray)
					.map('data')
					.flatten()
					.map(generator)
					.uniq(false, 'name').value();
			};
		}

		private generateLegendItems = (series, levelSeries, sentimentColorFunction, reportDefinition): Partial<Highcharts.Series> => {
			let factoryScope = this; // tslint:disable-line:no-invalid-this no-this-assignment

			if (!levelSeries) {
				levelSeries = _.map(series, serie => [serie]);
			}

			let isSentimentColor = !!sentimentColorFunction;
			let legendItemGenerator = isSentimentColor ?
				factoryScope.sentimentLegendItemGenerator(sentimentColorFunction, reportDefinition) :
				factoryScope.defaultLegendItemGenerator;

			if (isSentimentColor) {
				// if using sentiment color, flatten data out and treat it as a single pie -- we don't want separate sentiment color legend for each pie
				levelSeries = [_.flatten(levelSeries)];
			}


			let fakeSeries = _.map(levelSeries, (seriesArray) => {
				let data = legendItemGenerator(seriesArray);

				return {
					data,
					linkedSeries: _.map(seriesArray, serie => series.indexOf(serie)),
					showInLegend: true,
					visible: false,
					states: {
						hover: {
							enabled: false
						}
					}
				};
			});

			if (!isSentimentColor) {
				// put blank space between legend groups. sentiment color is a single group
				for (let i = 0; i < fakeSeries.length - 1; i++) {
					fakeSeries[i].data.push({
						name: '',
						color: '#fff',
						y: undefined
					});
				}
			}

			return fakeSeries as unknown as Partial<Highcharts.Series>;
		}

	};

});
