import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { LogoState } from '../logo-preview/logo-state.enum';
import { FileUploaderService } from '@cxstudio/services/file-upload.service';
import { PromiseUtils } from '@app/util/promise-utils';

@Injectable()

export class LogoSettingsServiceClass {
	readonly ALLOWED_EXTENSIONS = ['.gif', '.jpg', '.jpeg', '.png'];
	readonly MAX_FILE_SIZE = 2000000;

	uploader: any;
	uploading: boolean;

	lastSelectedFile: any;
	previewLogoSelected: boolean;
	deleteOnSave: boolean;

	logoFile;
	brandError: boolean;
	brandErrorMsg: string;
	private logoChangeSubject: Subject<File>;
	logoChange$: Observable<File>;
	private logoStateSubject: BehaviorSubject<LogoState>;
	logoState$: Observable<LogoState>;

	constructor(
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private applicationThemeService: ApplicationThemeService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		@Inject('security') private security: Security,
		@Inject('urlService') private urlService: UrlService,
		@Inject('fileUploadService') private fileUploadService: FileUploaderService
	) {
		this.initUploader();
		this.logoChangeSubject = new Subject();
		this.logoChange$ = this.logoChangeSubject.asObservable();
		this.logoStateSubject = new BehaviorSubject(LogoState.DEFAULT);
		this.logoState$ = this.logoStateSubject.asObservable();
	}

	getLogoUrl = () => {
		return this.urlService.getAPIUrl(`rest/master_account/uploaded_logo/${this.security.getMasterAccountId()}`)
					+ `?t=${new Date().getTime()}`;
	}

	isLogoApplied = (): boolean => {
		return this.security.getCurrentMasterAccount().customLogoEnabled;
	}

	uploadFile = (): Promise<void> => {
		let resolvePromise;
		const uploadPromise = new Promise<void>((resolve) => resolvePromise = resolve);
		this.uploading = true;
		this.lastSelectedFile.onComplete = () => {
			resolvePromise();
		};

		this.lastSelectedFile.upload();

		return uploadPromise;
	}

	deleteInPreviewMode = () => {
		this.lastSelectedFile = null;
		this.previewLogoSelected = false;
		this.clearFileSelection();
		if (this.isLogoApplied()) {
			this.deleteOnSave = true;
		}
		this.updateLogoState();
	}

	cancelLogoChanges = () => {
		this.lastSelectedFile = null;
		this.previewLogoSelected = null;
		this.deleteOnSave = false;
		this.clearFileSelection();
		this.updateLogoState();
	}


	initUploader = () => {
		if (!this.uploader) {
			let url = this.urlService.getAPIUrl('rest/master_account/branding/logo');
			this.uploader = this.fileUploadService.getUploader({
				url,
				filters: [{
					name: 'img',
					fn: (item: File) => {
						let isValidExtension = _.some(this.ALLOWED_EXTENSIONS, (extension) => {
							return item.name?.toLowerCase().endsWith(extension);
						});
						return isValidExtension && item.type && item.type.indexOf('image') === 0
							&& item.size <= this.MAX_FILE_SIZE;
					}
				}]
			});

			this.uploader.onWhenAddingFileFailed = (item: File) => {
				this.cxDialogService.notify(this.locale.getString('common.error'), item.size <= this.MAX_FILE_SIZE
					? this.locale.getString('common.unsupportedFileFormat')
					: this.locale.getString('appearance.exceedMaxSize'));
			};

			this.uploader.onAfterAddingFile = (fileItem) => {
				this.lastSelectedFile = fileItem;
				this.previewLogoSelected = true;
				this.updateLogoState();
				this.logoChangeSubject.next(this.getSelectedFile());
			};

			this.uploader.onSuccessItem = (item) => {
				this.lastSelectedFile = null;
				this.uploading = false;
				this.security.getCurrentMasterAccount().customLogoEnabled = true;
				this.previewLogoSelected = false;
				this.updateLogoState();

				this.applicationThemeService.applySelectedThemes();
			};
		}

		return this.uploader;
	}

	deleteLogo = (): Promise<void> => {
		this.uploading = true;
		return PromiseUtils.wrap(this.masterAccountApiService.deleteBrandingLogo()).then(() => {
			this.uploading = false;
			this.security.getCurrentMasterAccount().customLogoEnabled = false;
			this.deleteOnSave = false;
		});
	}

	getSelectedFile = () => {
		let fileIndex = this.uploader.queue.length - 1;
		return fileIndex >= 0 ? this.uploader.queue[fileIndex]._file : null;
	}

	updateLogoState(): void {
		if (this.previewLogoSelected) {
			this.logoStateSubject.next(LogoState.PREVIEW);
		}

		if (this.showAppliedLogo()) {
			this.logoStateSubject.next(LogoState.APPLIED);
		}

		if (this.showDefaultLogo()) {
			this.logoStateSubject.next(LogoState.DEFAULT);
		}
	}

	showAppliedLogo = () => {
		return this.isLogoApplied() && !this.deleteOnSave && !this.previewLogoSelected;
	}

	showDefaultLogo = () => {
		return !this.previewLogoSelected && !this.showAppliedLogo();
	}

	getLogoDescription = (): string => {
		let description;
		if (this.previewLogoSelected) {
			description = this.lastSelectedFile.file.name;
		} else if (this.showAppliedLogo()) {
			description = this.locale.getString('appearance.currentSavedLogo');
		} else {
			description = this.locale.getString('appearance.noImageSelected');
		}
		return description;
	}

	clearFileSelection = (): void => {
		document.querySelector('input#upload-logo').setAttribute('value', null);
	}

	isDefaultLogo = (): boolean => {
		return !(this.lastSelectedFile || this.security.getCurrentMasterAccount().customLogoEnabled);
	}

	setFiles = (element) => {
		if (element.files.length > 0) {
			let temp = element.files[0];
			if (temp.size > this.MAX_FILE_SIZE) {
				this.brandError = true;
				this.brandErrorMsg = this.locale.getString('administration.logoIsLarge');
				return;
			}
			if (temp.type.indexOf('image') >= 0) {
				this.logoFile = element.files[0];
			} else {
				this.brandError = true;
				this.brandErrorMsg += this.locale.getString('administration.fileTypeIsNotSupported');
				return;
			}
			this.brandError = false;
			this.brandErrorMsg = '';
		} else {
			this.logoFile = null;
			this.brandError = false;
			this.brandErrorMsg = '';
		}
	}

	hasPendingLogoChanges(): boolean {
		return this.deleteOnSave || this.previewLogoSelected;
	}

	updateLogo(): Promise<void> {
		if (this.deleteOnSave && this.previewLogoSelected) {
			return this.deleteLogo().then(() => {
				return this.uploadFile();
			});
		} else if (this.deleteOnSave) {
			return this.deleteLogo();
		} else if (this.previewLogoSelected) {
			return this.uploadFile();
		}
	}
}


app.service('logoSettingsService', downgradeInjectable(LogoSettingsServiceClass));
