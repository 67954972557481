import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface TemplateModalInput {
	id: number;
	name: string;
	title: string;
	description: string;
	image: string;
	type: string;
	system: boolean;
	custom: boolean;
	private: boolean;
	public: boolean;
	publicStatus: string;
	createdBy: any;
	dateAdded: any;
}

@Component({
	selector: 'template-modal',
	template: `
<modal-header modalTitle="{{'templates.templateLabel'|i18n}} {{input.name}}"
	(cancel)="dismiss()"></modal-header>
<div class="modal-body">
	<div class="row">
		<div class="col-md-4 text-center">
			<img src="img/templates/{{input.image}}" class="template-modal-preview" *ngIf="input.image && input.image.length !== 0" />
			<i class="en-icon-edit action-color template-modal-preview-empty" *ngIf="!input.image || input.image.length === 0"></i>
		</div>
		<div class="col-md-8 template-modal-details">
			<div [innerHTML]="input.description">
			</div>
			<p *ngIf="input.createdBy && input.createdBy.length > 0">{{'templates.createdBy'|i18n}} {{input.createdBy}}</p>
			<p *ngIf="input.dateAdded && input.dateAdded.length > 0">{{'templates.dateAdded'|i18n}} {{input.dateAdded}}</p>
		</div>
	</div>
</div>
<save-modal-footer
	saveText="{{'templates.useTemplate'|i18n}}"
	(save)="continueWithTemplate()"
	cancelText="{{'common.cancel'|i18n}}"
	(cancel)="dismiss()"></save-modal-footer>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateModalComponent {

	@Input() input: TemplateModalInput;

	constructor(
		private readonly activeModal: NgbActiveModal,
	) {}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	continueWithTemplate(): void {
		this.activeModal.close(this.input);
	}
}
