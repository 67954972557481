import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'sidebar-section',
	template: `
<collapsing-panel
	[collapsed]="isCollapsed()"
	[disableCollapse]="isDisabled()"
	[rounded]="false"
	[hidden]="hasNoChildren()">
	<panel-heading class="d-flex justify-between align-center w-100-percent">
		<strong>{{header}}</strong>
		<cb-toggle *ngIf="toggleable"
			[attr.aria-label]="'common.toggleObject' | i18n:{objectName:header}"
			[(ngModel)]="enabled"
			(ngModelChange)="enabledChange.emit($event)"
		></cb-toggle>
	</panel-heading>
	<panel-body>
		<div #contentRef>
			<ng-content></ng-content>
		</div>
	</panel-body>
</collapsing-panel>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionComponent implements AfterViewInit {
	@Input() header: string;
	@Input() toggleable: boolean;
	@Input() enabled: boolean;
	@Output() enabledChange = new EventEmitter<boolean>();
	@ViewChild('contentRef') contentRef: ElementRef<HTMLElement>;

	constructor(private ref: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		setTimeout(() => this.ref.markForCheck());
	}

	isCollapsed(): boolean {
		return this.isDisabled() || false;
	}

	isDisabled(): boolean {
		return this.toggleable && !this.enabled;
	}

	hasNoChildren(): boolean {
		return !this.contentRef?.nativeElement.children?.length;
	}
}
