import { AccountOrWorkspace } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { IFolderApi } from '@cxstudio/common/folders/folder-api.interface';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { NameService } from '@cxstudio/common/name-service';
import { DashboardTemplatesFolderApi } from '@cxstudio/dashboard-templates/api/dashboard-templates-folder-api.service';
import { DashboardFoldersState } from '@cxstudio/dashboards/dashboard-folders-state.service';
import { DriversFolderApi } from '@cxstudio/drivers/drivers-folder-api.service';
import { FolderAction } from '@cxstudio/folders/folder-action';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricFolderApi } from '@cxstudio/metrics/api/metric-folder-api.service';
import { FilterFolderApi } from '@cxstudio/report-filters/api/filter-folder-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { NLPAttributes } from '@cxstudio/reports/settings/options/nlp-attributes';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { FolderApiService } from '@cxstudio/services/data-services/folder-api.service';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { RenameService } from '@cxstudio/services/rename-service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import * as uib from 'angular-ui-bootstrap';
import * as _ from 'underscore';
import { FolderTypes } from './folder-types-constant';

export interface IFolderService {
	// folder is null when clicking button "Add folder"
	createFolder: (folder: any, itemList, space?: AccountOrWorkspace, skipTreeUpdate?: boolean) => ng.IPromise<any>;
	createAccountFolder: (folder, itemList, cpId?: number, accountId?: number) => any;
	moveToFolder: (folder, folderTo: IFolderItem, itemList, skipTreeUpdate?: boolean) => any;
	removeFolder: (folder, itemList, skipTreeUpdate?: boolean) => any;
	renameFolder: (folder, itemList, skipTreeUpdate?: boolean) => any;
	getFoldersForMove: (array, item, func, includeParent?: boolean) => any;
	getPlainFoldersForMove: <T>(array: T[], item: T) => T[];
	getDateFolder: (dateFilters?) => any;
	getScorecardsFolder: (name: string, id: number, type: FolderTypes, rootScorecardFolder: any) => any;
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('FolderService', function(
	locale: ILocale,
	nameService: NameService,
	$log: ng.ILogService,
	$uibModal: uib.IModalService,
	renameService: RenameService,
	cbDialogService: CBDialogService,
	filterFolderApi: FilterFolderApi,
	treeService: TreeService,
	folderApiService: FolderApiService,
	metricFolderApi: MetricFolderApi,
	dateFilterService: DateFilterService,
	globalNotificationService: GlobalNotificationService,
	dashboardFoldersState: DashboardFoldersState,
	metricConstants: MetricConstants,
	driversFolderApi: DriversFolderApi,
	dashboardTemplatesFolderApi: DashboardTemplatesFolderApi,
	folderContextMenuUtils: FolderContextMenuUtils) {

	return class FolderService implements IFolderService {

		apiService: IFolderApi<IFolderItem>;
		folderTypeString: string;
		folderType: FolderTypes;
		dateRanges = [];
		systemMetrics = metricConstants.getSystemIntNumberAttributes();
		nlpAttributes = new NLPAttributes(metricConstants);

		constructor(folderType: FolderTypes, apiService: IFolderApi<IFolderItem>) {
			this.folderType = folderType || FolderTypes.DASHBOARD;

			switch (folderType) {
				case FolderTypes.DASHBOARD:
					this.apiService = folderApiService;
					this.folderTypeString = locale.getString('dashboard.folder');
					break;
				case FolderTypes.FILTER:
					this.apiService = filterFolderApi;
					this.folderTypeString = locale.getString('reportFilters.folder');
					this.initDateRange();
					break;
				case FolderTypes.METRIC:
					this.folderTypeString = locale.getString('metrics.folder');
					this.apiService = metricFolderApi;
					break;
				case FolderTypes.DRIVERS:
					this.folderTypeString = locale.getString('drivers.folder');
					this.apiService = driversFolderApi;
					break;
				case FolderTypes.DASHBOARD_TEMPLATES:
					this.folderTypeString = locale.getString('dashboard.folder');
					this.apiService = dashboardTemplatesFolderApi;
					break;
				case FolderTypes.UNIFIED_TEMPLATES:
					this.folderTypeString = locale.getString('templates.unifiedTemplateFolder');
					this.apiService = apiService;
					break;
				default:
					this.apiService = folderApiService;
					break;
			}
		}

		private initDateRange = () => {
			this.dateRanges = dateFilterService.getDateFilterOptions().filter((range) =>
				//hide custom date filter in date folder.
				range.value !== 'custom');

			this.dateRanges.forEach((range) => {
				range.name = range.displayName;
				range.type = FilterTypes.CXDATE;
				range.id = `${FilterTypes.CXDATE}_${range.id}`;
			});
		}

		// dialog for editing folder properties
		/**
		 *
		 * @param {*} folder
		 * @param { isProperties: boolean, existingItem: boolean, itemList: [], hiddenItems: Object, customLabels: Object } isProperties
		 */
		private folderPropertiesDialog = (folder, config): uib.IModalInstanceService => {
			config.existingItem = config.existingItem || false;
			config.hiddenItems = config.hiddenItems || {};
			config.customLabels = config.customLabels || {};
			config.itemList = config.itemList || [];

			return $uibModal.open({
				templateUrl: 'partials/dashboards/folder-props-dialog.html',
				controller: 'DashboardPropsCtrl',
				backdrop: 'static',
				resolve: {
					treeItem: () => folder,
					config: () => config
				}
			});
		}

		// prep initial data for creating new folder
		defaultNewFolderDetails = (itemList): IFolderItem => {
			let name: string = nameService.uniqueName(locale.getString('dashboard.folderName'), itemList, 'name');

			return {
				name,
				type: this.folderType,
				description: ''
			} as IFolderItem;
		}


		// send folder creation/update details to API
		private updateFolderInAPI = (updateFolder: IFolderItem, newProps, action: FolderAction,
				parentFolder?: IFolderItem, itemList?: ITreeItem[], skipTreeUpdate?: boolean): ng.IPromise<any> => {
			updateFolder.name = newProps.name;
			updateFolder.description = newProps.description;

			let apiCall;
			if (action === FolderAction.CREATE) {
				apiCall = this.apiService.createFolder(updateFolder);
			} else {
				apiCall = this.apiService.updateFolder(updateFolder.id as number, updateFolder);
			}

			return apiCall.then((data) => {
				if (action === FolderAction.CREATE) {
					$log.debug('Created new folder:', data);
					if (!skipTreeUpdate) {
						treeService.addItem(itemList, data, parentFolder);
					}
				} else {
					$log.debug('Updated:', data);
				}
				return data;
			});
		}

		//Beta WORKSPACE: Remove once all asset support workspaces
		createAccountFolder = (folder: any, itemList, cpId?: number, accountId?: number): ng.IPromise<any> => {
			return this.createFolder(folder, itemList, { contentProviderId: cpId, accountId });
		}

		createFolder = (folder: any, itemList, space?: AccountOrWorkspace, skipTreeUpdate?: boolean): ng.IPromise<any> => {
			// folder is null when clicking button "Add folder"
			let newFolder = this.defaultNewFolderDetails(itemList);

			if (folder) {
				newFolder.parentId = folder.id;
			}

			let createFolderDialog = this.folderPropertiesDialog(newFolder, {
				isProperties: false,
				existingItem: false,
				itemList
			});
			return createFolderDialog.result.then(
				(createResult) => {
					let item = createResult.model;
					if (space && WorkspaceTransitionUtils.isWorkspaceSelected(space)) {
						const workspaceData = WorkspaceTransitionUtils.getWorkspaceData(space);
						_.extend(newFolder, workspaceData);
					}

					return this.updateFolderInAPI(newFolder, item, FolderAction.CREATE, folder, itemList, skipTreeUpdate).then((created: any) => {
						if (created.type === 'folder') {
							dashboardFoldersState.saveFolderState(created.id, false);
						}

						return created;
					});
				});
		}

		moveToFolder = (item: IFolderItem | ITreeItem, folderTo: IFolderItem, itemList: ITreeItem[],
				skipTreeUpdate?: boolean): PromiseLike<IFolderItem | ITreeItem> => {
			$log.debug(`Moving ${item.name} to ${folderTo.name}`);
			item.parentId = folderTo.id;

			return this.apiService.moveToFolder(item, folderTo.id as number).then(() => {
				if (!skipTreeUpdate) {
					if (!folderTo.id)
						folderTo = undefined;
					treeService.moveItem(itemList, item, folderTo);
				}
				return item;
			});
		}

		removeFolder = (folder: IFolderItem, itemList: ITreeItem[], skipTreeUpdate: boolean): PromiseLike<any> => {
			let deleteFolderLocal = (): PromiseLike<any> => {
				return this.apiService.deleteFolder(folder.id as number).then(() => {

					$log.debug('Removed folder:', folder);
					if (!skipTreeUpdate) {
						let removed = treeService.deleteItem(itemList, folder);
						removed.forEach((item) => {
							//exclude itself
							if (item.id !== folder.id) {
								if (item.parent.id === folder.id) {
									// if folder has parent, move items up to that parent
									if (folder.parent) {
										// link item to new parent folder
										item.parent = folder.parent;
										item.parentId = folder.parentId;

										// add item to new parent folder
										folder.parent.children = folder.parent.children || [];
										folder.parent.children.push(item);
									} else {
										delete item.parent;
										delete item.parentId;
									}
								}
								treeService.addItem(itemList, item, item.parent);
							}
						});
						return removed;
					}
				});
			};

			if (!folder.children || !folder.children.length) {
				return deleteFolderLocal();
			} else {
				let deleteFolderTitle, deletionConfirmation;

				if (/.*filter.*/.test(folder.type)) {
					deletionConfirmation = locale.getString('reportFilters.deleteFolderNote');
					deleteFolderTitle = locale.getString('reportFilters.deleteFolder', { folderName: folder.name });
				} else if (/.*metric.*/.test(folder.type)) {
					deletionConfirmation = locale.getString('metrics.deleteFolderNote');
					deleteFolderTitle = locale.getString('metrics.deleteFolder', { folderName: folder.name });
				} else if (/.*drivers.*/.test(folder.type)) {
					deletionConfirmation = locale.getString('drivers.deleteFolderNote');
					deleteFolderTitle = locale.getString('drivers.deleteFolder', { folderName: folder.name });
				} else {
					deletionConfirmation = locale.getString('dashboard.deleteFolderNote');
					deleteFolderTitle = locale.getString('dashboard.deleteFolder', { folderName: folder.name });
				}

				let deleteFolderModal = cbDialogService.danger(deleteFolderTitle, deletionConfirmation);
				return deleteFolderModal.result.then(deleteFolderLocal) as PromiseLike<any>;
			}
		}

		renameFolder = (folder, itemList, skipTreeUpdate?: boolean): ng.IPromise<any> => {
			let items = itemList.filter(item => FolderContextMenuUtils.FOLDER_REG.test(item.type));
			let renameModal = renameService.modal(folder, items, this.folderTypeString);

			return renameModal.result.then((props) => {
				globalNotificationService.addItemSavedNotification(props.name);
				return this.updateFolderInAPI(folder, props, FolderAction.UPDATE, null, null, skipTreeUpdate);
			}, () => {}) as unknown as ng.IPromise<any>;
		}

		getFoldersForMove = (array, item, func, includeParent?): any[] => {
			let folders = folderContextMenuUtils.getFoldersForMove(array, item, func, includeParent);

			return _.filter(folders, (folder) => {
				return !folder.obj.generatedFolderType;
			});
		}

		getPlainFoldersForMove = <T>(array: T[], item: T): T[] => {
			let folders = folderContextMenuUtils.getPlainFoldersForMove(array, item);

			return _.filter(folders, (folder) => {
				return !folder.generatedFolderType;
			});
		}

		getDateFolder = () => {
			return [angular.copy(dateFilterService.DATE_FOLDER)].concat(this.dateRanges);
		}

		getScorecardsFolder = (name: string, id: number, type: FolderTypes, rootScorecardFolder: any): any => {
			return {
				name,
				nameWithPath: name,
				displayName: name,
				type,
				description: '',
				id: 'scorecard_' + id,
				children: [],
				parent: rootScorecardFolder,
				parentId: rootScorecardFolder.id,
				level: 1,
				_collapsed: true,
				generatedFolderType: GeneratedFolderType.SYSTEM
			};
		}

	};
});
