<div id="suite-applications-dropdown"
	*ngIf="shouldDisplayWaffleMenu()"
	ngbDropdown
	role="menu"
	[attr.aria-expanded]="suiteApplicationsMenuOpen"
	[autoClose]="true"
	[display]="'static'"
	class="m-0 dropdown pull-left header-element">

	<a id="suite-applications-button"
		ngbDropdownToggle
		role="button"
		aria-haspopup="true"
		[attr.aria-expanded]="suiteApplicationsMenuOpen"
		(click)="onSuteApplicationsMenuToggle($event)"
		(keydown)="onSuiteApplicationsMenuToggleKeydown($event)"
		class="d-flex align-items-center header-link text-lg-1"
		tabindex="0">
		<span class="q-icon q-icon-waffle" aria-hidden="true"></span>
	</a>

	<ul ngbDropdownMenu id="suite-applications-menu" class="dropdown-menu" role="menu" (keydown)="onSuiteApplicationsMenuKeydown($event)">
		<li  class ="cursor-default">
			<span [i18n]="'header.switchTo'" class="font-bold"></span>
		</li>

		<li *ngFor="let app of apps">
			<a ngbDropdownItem [href]="app.destination" class="option" rel="noopener">
				<span [i18n]="app.labelKey"></span>
			</a>
		</li>

		<div *ngIf="isLoading" class="loader small"></div>

	</ul>
</div>
