import { DomElementSchemaRegistry, ElementSchemaRegistry, SchemaMetadata } from '@angular/compiler';
import { StaticProvider } from '@angular/core';
import * as SCHEMA from './studio-elements-schema.json';

// This class is required for JIT compilation (which we use in hybrid mode) in order to validate elements and tags.
// AOT compilation (ng build, which precompiles all templates during build) is handled by cb-ng.js
class StudioDomElementSchemaRegistry extends DomElementSchemaRegistry {
	constructor() {
		super();
	}

	hasElement(tagName: string, schemaMetas: SchemaMetadata[]): boolean {
		return SCHEMA.STUDIO_DOM_ELEMENT_SCHEMA.indexOf(tagName) > -1 ||
			super.hasElement(tagName, schemaMetas);
	}

	hasProperty(tagName: string, propName: string, schemaMetas: SchemaMetadata[]): boolean {
		const elementProperties = SCHEMA.STUDIO_CUSTOM_PROPERTIES_SCHEMA[tagName.toLowerCase()];
		return (elementProperties && elementProperties[propName]) ||
			super.hasProperty(tagName, propName, schemaMetas);
	}
}

export const StudioSchemaRegistryProvider: StaticProvider = {
	provide: ElementSchemaRegistry,
	useClass: StudioDomElementSchemaRegistry,
	deps: []
};
