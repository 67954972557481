import { SelectionControllerBase } from './selection-controller-base';
import { BaseSelectUtils } from '@app/modules/item-grid/selection/base-selection-utils';

export class GenericSelectUtils<T> extends BaseSelectUtils {
	private controller: SelectionControllerBase<T>;

	constructor(controller: SelectionControllerBase<T>) {
		super();
		this.controller = controller;
	}

	getVisibleObjectsList = (): any => {
		return this.controller.getVisibleItems() || [];
	}

	getObjectsList = (): any => {
		return this.controller.getVisibleItems() || [];
	}

	isSupportedType = (item: T): boolean => {
		return this.controller.isSelectionSupported(item);
	}

	getSearchFilter = (): string => {
		return this.controller.getSearchFilter();
	}

	refreshObjects = (items: T[]): void => {
		return this.controller.refreshGrid(items);
	}

	visibleObjFilter = (item: any): boolean => {
		let isProjectSelected = !_.isUndefined(this.controller.getProjectId());
		let notHidden = (!item.hide || this.controller.isShowHidden());
		let notFilteredBySearch = !item.hiddenViaFilter;
		let notFilteredByProject = (!isProjectSelected || item.projectId === this.controller.getProjectId());
		return this.isSupportedType(item) && notHidden && notFilteredBySearch && notFilteredByProject;
	}
}
