import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { WidgetManagementItem } from './widget-management-item.class';

@Injectable({
	providedIn: 'root'
})
export class EmbeddedWidgetManagementApi {
	readonly EMBEDDED_WIDGET_ENDPOINT = 'rest/embed/widget-management/embedded-widgets';

	constructor(
		private cxHttp: CxHttpService,
	) {}

	getEmbeddedWidgets(): Promise<WidgetManagementItem[]> {
		return this.cxHttp.get(this.EMBEDDED_WIDGET_ENDPOINT);
	}

	enableEmbeddedWidget(widgetId: number, enable: boolean): Promise<boolean> {
		return this.cxHttp.put(this.EMBEDDED_WIDGET_ENDPOINT + `/${widgetId}/enable/${enable}`);
	}

	enableEmbeddedWidgetEnterpriseView(widgetId: number, enable: boolean): Promise<boolean> {
		return this.cxHttp.put(this.EMBEDDED_WIDGET_ENDPOINT + `/${widgetId}/enterprise/enable/${enable}`);
	}
}
