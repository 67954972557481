import { SecondaryTrackConfig } from '../shapes/secondary-track-config.class';
import { SecondaryTrackRendererType } from '../secondary-track-renderer-type.enum';
import { ConversationDataPoint } from '../conversation-data-point.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';

import { ChannelTypes } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';

export class ReasonTrack extends SecondaryTrackConfig {
	static CRY_FOR_HELP_TYPE = 'cry for help';
	static CLIENT_SENTENCE_TYPES = ['cry for help', 'churn', 'tenure', 'not recommend'];
	static AGENT_SENTENCE_TYPES = ['don\'t know', 'disclosure', 'mini-miranda', 'transfer', 'hold'];


	shape = SecondaryTrackRendererType.DIAMOND;
	shapeWidth = 10;

	static hasReason = (dataPoint: ConversationDataPoint): boolean => {
		if (!dataPoint.attributes) return false;

		if (dataPoint.attributes && dataPoint.attributes[ClarabridgeAttributeName.REASON_DETECTION]) return true;

		let applicableSentenceTypes = dataPoint.channel === ChannelTypes.CLIENT ?
			ReasonTrack.CLIENT_SENTENCE_TYPES :
			ReasonTrack.AGENT_SENTENCE_TYPES;

		for (let targetType of applicableSentenceTypes) {
			if (dataPoint.attributes[ClarabridgeAttributeName.CB_SENTENCE_TYPE]?.contains(targetType)) return true;
		}

		if (dataPoint.attributes[ClarabridgeAttributeName.CB_SENTENCE_TYPE]?.contains('requests')) {
			if (!_.isUndefined(dataPoint.sentiment) && dataPoint.sentiment !== 2) return true;
			if (!_.isUndefined(dataPoint.easeScore) && dataPoint.easeScore !== 0) return true;
			if (!_.isUndefined(dataPoint.emotionIntensity) && dataPoint.emotionIntensity > 1) return true;
		}

		return false;
	}

	static generateReasonDetectionEnrichment(name: string = ''): ConversationEnrichment {
		return {
			hasValue: ReasonTrack.hasReason,
			getColorArray: () => ['#43444f', '#d3d4da'],
			getColorIndex: this.getColorIndex,
			tooltipFormatter: ReasonTrack.getPillsFormatter(),
			getName: () => name,
			getHeaderIcon: () => 'q-icon-diamond',
			iconType: SecondaryTrackRendererType.DIAMOND,
			enrichmentType: SingleLaneEnrichmentTypes.SENTENCE_TYPE,
		};
	}

	private static getPillsFormatter(): (data: ConversationDataPoint) => string {
		let classes = 'enrichment-pill border-radius-full label-bubble font-standard d-flex align-center';
		let formatPill = (value) => `<span class="${classes}">${value}</span>`;
		return (data: ConversationDataPoint) => {
			let reasons = _.chain(data.attributes[ClarabridgeAttributeName.REASON_DETECTION])
				.map(value => formatPill(value))
				.join('')
				.value();
			let sentenceTypes = _.chain(data.attributes[ClarabridgeAttributeName.CB_SENTENCE_TYPE])
				.map(value => formatPill(value))
				.join('')
				.value();
			let pills = reasons + sentenceTypes;
			return pills;
		};
	}

	private static getColorIndex = (dataPoint: ConversationDataPoint): number =>
		dataPoint.attributes[ClarabridgeAttributeName.REASON_DETECTION] ||
		dataPoint.attributes[ClarabridgeAttributeName.CB_SENTENCE_TYPE]?.contains(ReasonTrack.CRY_FOR_HELP_TYPE) ? 0 : 1

	dataFilter = (dataPoint: ConversationDataPoint): boolean => {
		return ReasonTrack.hasReason(dataPoint);
	}

}
