import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokensApiService } from './tokens-api.service';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		NgbModule
	],
	providers: [
		TokensApiService
	],
})
export class TokensModule {}
