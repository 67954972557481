<div class="panel panel-default widget-cache-settings">
	<div class="panel-heading" [i18n]="'mAccount.screeningPanelTitle'"></div>
	<div class="panel-body clearfix">
		<div class="d-flex align-items-center mb-16">
			<cb-toggle
				name="feedbackScreeningEnabled"
				[(ngModel)]="screeningSettings.feedbackScreeningEnabled"
				(ngModelChange)="changeSettings($event)"
			></cb-toggle>
			<span class="ml-16">{{feedbackScreeningLabel}}</span>
			<cb-inline-help>
				<help-body [i18n]="'mAccount.feedbackScreeningHelp'">
				</help-body>
			</cb-inline-help>
		</div>
	</div>
</div>
