import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class ChartAccessibilityService {

	constructor(@Inject('security') private security: Security) {}

	isPatternFillEnabled = (): boolean => {
		return this.security.loggedUser.patternFills;
	}

}

app.service('chartAccessibilityService', downgradeInjectable(ChartAccessibilityService));