import { Component } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CollapsingBaseComponent } from '@app/shared/components/structural/collapsing-base';


@Component({
	selector: 'collapsing-section',
	template: `
<section [attr.aria-expanded]="!panelCollapsed">
	<div class="d-flex justify-left pv-16 cxs-brand-primary"
		[ngClass]="{'cursor-pointer': !disableCollapse, 'expanded': !panelCollapsed}"
		(click)="toggleCollapse()">
		<ng-content select="panel-heading"></ng-content>
		<div class="ml-8" *ngIf="!disableCollapse">
			<i class="q-icon q-icon-angle-bottom" *ngIf="!panelCollapsed" aria-hidden="true"></i>
			<i class="q-icon q-icon-angle-right" *ngIf="panelCollapsed" aria-hidden="true"></i>
		</div>
	</div>
	<div *ngIf="!panelCollapsed">
		<ng-content select="panel-body"></ng-content>
	</div>
</section>
`})
export class CollapsingSectionComponent extends CollapsingBaseComponent {
	constructor(locale: CxLocaleService) {
		super(locale);
	}
}
