import { CxHttpService } from '@app/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { IFolderApi } from '@cxstudio/common/folders/folder-api.interface';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';

export class AgBaseFolderApi<T extends AgGridNestable> implements IFolderApi<T> {

	constructor(
		protected assetBaseUrl: string,
		protected folderBaseUrl: string,
		protected cxHttp: CxHttpService,
		protected cache: CxCachedHttp,
	) {
	}

	createFolder = (folderData: T): Promise<T> => {
		return this.cxHttp.post<T>(`${this.folderBaseUrl}`, folderData).then(this.responseHandler);
	}

	deleteFolder = (folderId: number): PromiseLike<void> => {
		return this.cxHttp.delete(`${this.folderBaseUrl}/${folderId}`).then(this.invalidateCacheHandler);
	}

	moveToFolder = (item: ITreeItem, folderId: number): PromiseLike<void> => {
		if (this.isFolder(item)) {
			return this.cxHttp.put(`${this.folderBaseUrl}/${item.id}/move-to/${folderId}`, null)
				.then(this.invalidateCacheHandler);
		} else {
			return this.cxHttp.put(`${this.assetBaseUrl}/${item.id}/move-to/${folderId}`, null)
				.then(this.invalidateCacheHandler);
		}
	}

	updateFolder = (folderId: number, folderData: T): PromiseLike<T> => {
		return this.cxHttp.put<T>(`${this.folderBaseUrl}/${folderId}`, folderData).then(this.responseHandler);
	}

	protected invalidateCacheHandler = (): void => {
		if (this.cache) this.cache.invalidate();
	}

	protected responseHandler = <R>(response: R): R => {
		this.invalidateCacheHandler();
		return response;
	}

	protected isFolder(item: ITreeItem): boolean {
		return /.*folder.*/i.test(item.type);
	}

}
