import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import AnalyticWidget from './analytic-widget.class';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PreviewVisualProperties } from '@cxstudio/reports/entities/preview-visual-properties';
import { FeedbackWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/feedback-widget-visual-properties.class';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';

export class FeedbackWidget extends AnalyticWidget {
	visualProperties: FeedbackWidgetVisualProperties;

	static get(): FeedbackWidget {
		return new FeedbackWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new PreviewVisualProperties();
		this.name = WidgetType.PREVIEW;
		this.visualProperties.visualization = WidgetVisualization.PREVIEW_BUBBLES;
		this.properties.widgetType = WidgetType.PREVIEW;
		this.properties.itemsPerPage = 20;
		this.visualProperties.itemsPerPage = 20;
		this.properties.page = {
			start: 0,
			lookAheadLimit: 100,
			sortMetric: {
				direction: AnSortDirection.DESC,
				displayName: PreviewSortAttributes.DOC_DATE
			}
		};
	}

	withPreviewMode(previewMode: PreviewMode): FeedbackWidget {
		this.properties.previewMode = previewMode;
		return this;
	}

	withColumns(columns: AttributeGrouping[]): FeedbackWidget {
		this.visualProperties.columns = columns;
		return this;
	}

	withBubbleColumns(bubbleColumns: number): FeedbackWidget {
		this.visualProperties.bubbleColumns = bubbleColumns;
		return this;
	}
}
