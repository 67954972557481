<modal-header
	[ngClass]="{'warning-modal warning-400': dialogStyle === warningDialogStyle}"
	(cancel)="cancel()"
	[modalTitle]="header">
</modal-header>
<div class="confirm-body modal-body">
	<div>{{text}}</div>
</div>
<save-modal-footer
	saveText="{{confirmBtnName}}"
	(save)="confirm(true)"
	secondaryBtnTxt="{{altConfirmBtnName}}"
	(secondaryAction)="confirm(false)"
	cancelText="{{cancelBtnName}}"
	(cancel)="cancel()"
	[style]="dialogStyle"
></save-modal-footer>
