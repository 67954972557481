import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { KEY_NOT_FOUND, LanguageLoaderService } from '@app/core/i18n/language-loader.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SupportedLocaleGenerated } from '@locales/supported-locales';
export { SupportedLocaleGenerated as SupportedLocale };

export interface ILocaleInstance {
	id: SupportedLocaleGenerated;
	label: string;
}

@Injectable({
	providedIn: 'root'
})
export class CxLocaleService implements ILocale {
	constructor(
		private languageLoader: LanguageLoaderService,
	) {}

	getString(key: string, params?: any, fallback?: string): string {
		const translationTemplate = this.languageLoader.getTranslation(key);

		if (translationTemplate === KEY_NOT_FOUND) {
			return fallback ?? key;
		}
		let translatedValue = translationTemplate;
		if (_.isUndefined(params)) {
			return translatedValue;
		}
		if (!_.isObject(params)) {
			params = [params];
		}
		if (_.isArray(params)) {
			params.forEach((value, index) => {
				translatedValue = translatedValue.replaceAll(`%${index + 1}`, value);
				translatedValue = translatedValue.replaceAll(`{${index + 1}}`, value);
			});
			return translatedValue;
		} else {
			let index = 0;
			_.each(params, (value, param) => {
				++index;
				translatedValue = translatedValue.replaceAll(`%${param}`, value);
				translatedValue = translatedValue.replaceAll(`%${index}`, value);
				translatedValue = translatedValue.replaceAll(`{${param}}`, value);
				translatedValue = translatedValue.replaceAll(`{${index}}`, value);
			});
			return translatedValue;
		}
	}

	/**
	 * Get user's preferred locale
	 */
	getLocale(): string {
		return this.languageLoader.getCurrentLanguage();
	}

	getSupportedLocales(): ILocaleInstance[] {
		return Object.entries(SupportedLocaleGenerated).map(([localeKey, localeEntry]) => {
			return {
				id: localeEntry,
				label: `userDialog.locale${localeKey.toUpperCase()}`
			} as ILocaleInstance;
		});
	}
}

app.service('locale', downgradeInjectable(CxLocaleService));
