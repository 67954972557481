<tags-input
	class="w-100-percent"
	name="accountOwners"
	[maxTags]="4"
	[(ngModel)]="values"
	[addOnBlur]="false"
	[addOnEnter]="false"
	[tagsSource]="tagsSource"
	(onTagAdded)="ownerAdded($event)"
	(onTagRemoved)="ownerRemoved($event)">
</tags-input>
