<page-content-header [title]="'header.archive' | i18n" class="mb-24"></page-content-header>
<div class="container-full">
	<div class="br-list-box" sticky-slick-header>
		<div [ngBusy]="loading">
			<item-grid
				class="h-100-percent w-100-percent br-grid"
				[treeData]="archiveItems"
				[gridType]="gridType"
				[nameField]="gridNameField"
				[gridOptions]="gridOptions"
				[gridChange]="changedItems">
			</item-grid>
		</div>
	</div>
</div>
