import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions } from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'widget-title-container',
	template: `
		<widget-title *ngIf="showTextTitle()" [widgetId]="widget.id" [title]="getTitle()"></widget-title>
		<editable-widget-title *ngIf="showEditableTitle()" [widget]="widget" [widgetActions]="widgetActions"></editable-widget-title>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTitleContainerComponent {

	@Input() editMode: boolean;
	@Input() widget: Widget;
	@Input() widgetActions: IWidgetActions;
	@Input() predefinedTitle: string;
	@HostBinding('class') hostClass = 'd-flex align-center overflow-hidden';

	getTitle(): string {
		return this.predefinedTitle ? this.predefinedTitle : this.widget.displayName;
	}

	showTextTitle(): boolean {
		return !this.showEditableTitle();
	}

	showEditableTitle(): boolean {
		return this.editMode && !this.predefinedTitle;
	}

}

app.directive('widgetTitleContainer', downgradeComponent({component: WidgetTitleContainerComponent}) as angular.IDirectiveFactory);
