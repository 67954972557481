
import { Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { EmbeddedWidgetUtils } from '@app/modules/account-administration/embedded-widget/embedded-widget-utils.service';
import { RandomUtils } from '@app/util/random-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetEmbedConfig } from '@cxstudio/dashboards/widgets/widget-embed-config.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeWidgetEventData } from '@app/modules/analytics/amplitude/amplitude-widget-event-data.class';
import { AmplitudeGroupsUtils } from '@app/modules/analytics/amplitude/amplitude-groups-utils';
import { AmplitudeGroups } from '@app/modules/analytics/amplitude/amplitude-groups';

@Component({
	selector: 'embed-widget-configuration',
	templateUrl: './embed-widget-configuration.component.html',
	styles: [`
		[contenteditable], .q-icon-clipboard {
			height: auto;
		}
	`]
})
export class EmbedWidgetConfigurationComponent implements OnInit {
	@Input() private widget: Widget;
	@Input() embedConfig: WidgetEmbedConfig;

	copyEvent: AmplitudeEvent;
	eventData: AmplitudeWidgetEventData;
	eventGroup: AmplitudeGroups;

	constructor(
		@Inject('security') private security: Security,
		private embeddedWidgetUtils: EmbeddedWidgetUtils
	) {}

	ngOnInit(): void {
		this.copyEvent = AmplitudeEvent.DASHBOARD_WIDGET_EMBEDDED_CODE_COPY;
		this.eventData = new AmplitudeWidgetEventData(this.widget);
		this.eventGroup = AmplitudeGroupsUtils.dashboardGroup(this.widget.dashboardId);
	}

	hasEmbedPermission = () => this.security.has('embed_widgets');

	hasEmbedEnterprisePermission = () => this.security.has('embed_widgets_enterprise');

	getEmbedWidgetCode = (): string => {
		return this.embeddedWidgetUtils.getEmbedWidgetCode(this.widget, this.embedConfig.identifier);
	}

	initIdentifier = (): void => {
		if (!this.embedConfig.identifier)
			this.embedConfig.identifier = RandomUtils.randomString();
	}

	showEnterpriseViewersOption = (): boolean => {
		return this.widget.name !== WidgetType.PREVIEW
				&& this.hasEmbedEnterprisePermission();
	}

}

app.directive('embedWidgetConfiguration', downgradeComponent({component: EmbedWidgetConfigurationComponent}) as angular.IDirectiveFactory);
