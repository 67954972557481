import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
@Component({
	selector: 'color-array',
	template: `
<div class="d-flex flex-direction-row">
	<figure *ngFor="let color of colors"
		tabindex="0"
		attr.aria-label="{{getColorLabel(color)}}"
		title="{{color.toUpperCase()}}"
		class="w-24 h-24 cursor-pointer {{getContrastColorClass(color)}}"
		[class.selected]="color.toUpperCase() === selectedColor?.toUpperCase()"
		(keydown.enter)="colorSelect.emit(color)"
		(click)="colorSelect.emit(color)"
		[style.background-color]="color">
	</figure>
</div>`,
	styles: [`
		figure:not(:last-child) {
			margin-right: 8px;
		}

		.needs-contrast {
			outline: 1px solid var(--gray-400);
			outline-offset: -1px;
		}

		/* this is not necessary after palette updates. original needs-contrast class will suffice */
		:host-context(.application-dark-mode) .needs-contrast:not(.selected),
		:host-context(.dark-mode) .needs-contrast:not(.selected) {
			outline: 1px solid var(--white);
		}

		.selected {
			border: 3px solid var(--white);
			outline: 1px solid var(--primary-color-500);
			outline-offset: -1px;
		}

		figure:focus-within {
			opacity: .5;
		}

	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorArrayComponent {
	@Input() selectedColor: string;
	@Input() colors: string[];
	@Output() colorSelect = new EventEmitter();

	constructor(
		private readonly locale: CxLocaleService
	) {}

	getContrastColorClass(color: string): string {
		if ([ColorConstants.WHITE, ColorConstants.CHARCOAL].contains(color.toLowerCase() as any)) {
			return 'needs-contrast';
		}
		return '';
	}

	getColorLabel(color: string): string {
		return this.locale.getString('colors.colorCode', { color: color.toUpperCase() });
	}
}
