<div class="searchable-hierarchy flex-fill">
	<div class="p-8" *ngIf="hasSearch()">
		<label *ngIf="searchLabel" for="input-search-{{styleId}}">{{searchLabel}}</label>
		<input class="search-box" name="searchBox"
			id="input-search-{{styleId}}"
			#searchInput
			[(ngModel)]="search.hierarchySearch"
			(ngModelChange)="searchChangeHandler()"
			autocomplete="off"
			[placeholder]="placeholder"
			attr.aria-label="{{placeholder}}"
			(click)="$event.stopPropagation()"
			(keydown.shift.tab)="moveFocusBack($event)"
			[disabled]="componentDisabled">
	</div>

	<div class="searchable-hierarchy-list" [ngClass]="{'searchable-hierarchy-list-limited-width': limitedWidth}">
		<ul role="tree" attr.aria-label="{{hierarchyName}}">
			<ng-container *ngIf="(hierarchyList | treeSearch : search.hierarchySearch : displayProperty) as matchedItems">
				<ng-container *ngFor="let item of matchedItems; index as $index">
					<tree-node
						class="searchable-hierarchy-root"
						show-empty="true"
						[node]="item"
						[search]="search"
						[displayProperty]="displayProperty"
						[forcedOrder]="forcedOrder"
						[isDisabled]="isDisabled"
						[isSelected]="isSelected"
						[isNotRecommended]="isNotRecommended"
						[limitedWidth]="limitedWidth"
						[folderClickIgnore]="folderClickIgnore"
						[showNotRecommendedPrompt]="showNotRecommendedPrompt"
						[isVisibleOverrided]="isVisibleOverrided"
						[customValidation]="customValidation"
						(onNodeClick)="nodeClickHandler($event)"
						(onFolderExpand)="folderExpandHandler($event)"
						[showCheckboxes]="showCheckboxes"
						[showNodeCheckbox]="showNodeCheckbox"
						[isNodeCheckboxDisabled]="isNodeCheckboxDisabled"
						[nodeIndeterminate]="nodeIndeterminate"
						[isNodeChecked]="nodeIsChecked"
						[isNodeMarked]="nodeIsMarked"
						[getNodeTooltip]="getNodeTooltip"
						[isNodeHighlighted]="nodeIsHighlighted"
						[setFocus]="getSetNodeFocusSubject(item).asObservable()"
						(onFocusMove)="focusMoveHandler($event)"
						(onFolderCollapse)="folderCollapseHandler($event)"
						[optionClassFormatter]="optionClassFormatter"
						[itemTemplate]="itemTemplate"
					></tree-node>
				</ng-container>
				<li *ngIf="matchedItems.length < 1" class="no-results"><i>{{getNoResultsText()}}</i></li>
			</ng-container>
		</ul>
	</div>
</div>
