import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ScheduleRunsComponent } from './schedule-runs.component';
import { ScheduleManagementComponent } from './schedule-management.component';
import { NgPipesModule } from 'ngx-pipes';
import { ItemGridModule } from '../item-grid/item-grid.module';
import ScheduleGridDefinition from '@app/modules/schedules/services/schedule-grid-definition.service';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		ItemGridModule
	],
	providers: [
		ScheduleGridDefinition
	],
	exports: [ ScheduleRunsComponent, ScheduleManagementComponent ],
	declarations: [ ScheduleRunsComponent, ScheduleManagementComponent ],
})
export class SchedulesModule {}
