import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ProfanityDisguiseService } from './profanity-disguise.service';

@NgModule({
	imports: [
		SharedModule,
	],
	providers: [
		ProfanityDisguiseService
	],
})

export class ProfanityModule {}
