import { Injectable, Inject } from '@angular/core';
import { CxEvent } from '@app/core/cx-event.enum';

@Injectable({
	providedIn: 'root'
})
export class GlobalEventBus {
	constructor(
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService
	) {}

	broadcast(eventType: CxEvent, ...args: any[]) {
		this.$rootScope.$broadcast.apply(this.$rootScope, [eventType].concat(args));
	}

	subscribe(eventType: CxEvent, callback: (...args) => void ): () => void {
		return this.$rootScope.$on(eventType, callback);
	}
}
