import { VisualizationService } from '@app/modules/widget-visualizations/visualization-service.service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

/**
 * Directive, which replaces itself with visualization from its attributes
 */
app.directive('cbReportView', (
	$compile: ng.ICompileService
) => {
	return {
		restrict: 'E',
		replace: true,
		template: '<div></div>',
		scope: true,
		link: (scope, element, attrs) => {
			scope.$watch(`[${attrs.trigger}, ${attrs.exportingImage}]`, (newValue, oldValue) => {
				let visualization = scope.$eval(attrs.view);
				let useAutoHeight = WidgetVisualization.DEFINITION_TABLE === visualization || WidgetVisualization.CB_AN_TABLE === visualization;

				if (!visualization || visualization === 'PAGE_BREAK' || newValue[1] !== oldValue[1] && !useAutoHeight)
					return;

				let options = scope.$eval(attrs.options);
				let utils = scope.$eval(attrs.utils);
				if (_.isEmpty(options) || _.isEmpty(utils))
					return;
				let view = VisualizationService.getView(visualization, utils);
				if (view) {
					element.attr(view, '');
				}
				if (useAutoHeight) {
					element.attr('exporting-image', scope.$eval(attrs.exportingImage));
				}
				$compile(element)(scope);
			});
		}
	};
});
