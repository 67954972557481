import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { UnifiedTemplatesManagementComponent } from './unified-templates-management/unified-templates-management.component';
import { DashboardTemplatesManagementComponent } from './unified-templates-management/dashboard-templates-management/dashboard-templates-management.component';
import DashboardTemplatesGridDefinition from './unified-templates-management/dashboard-templates-management/dashboard-templates-grid-definition.service';
import { DashboardTemplatesModule } from '@app/modules/unified-templates/dashboard-templates/dashboard-templates.module';
import { UnitsModule } from '@app/modules/units/units.module';
import { MetricCreationPropertiesComponent } from './metric-templates/metric-creation-properties/metric-creation-properties.component';
import { CommonTemplatesModule } from '@app/modules/unified-templates/common-templates/common-templates.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { FilterCreationPropertiesComponent } from './filter-templates/filter-creation-properties/filter-creation-properties.component';
import { DashboardCreationPropertiesComponent } from './dashboard-templates/dashboard-creation-properties/dashboard-creation-properties.component';
import { TemplateTransferModalComponent } from './template-transfer-modal/template-transfer-modal.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';

@NgModule({
	imports: [
		SharedModule,
		WidgetContainerModule,
		ItemGridModule,
		FilterBuilderModule,
		DashboardTemplatesModule,
		UnitsModule,
		CommonTemplatesModule,
		CxFormModule,
		DialogModule,
		I18nModule,
	],
	providers: [
		DashboardTemplatesGridDefinition,
	],
	declarations: [
		UnifiedTemplatesManagementComponent,
		DashboardTemplatesManagementComponent,
		DashboardCreationPropertiesComponent,
		MetricCreationPropertiesComponent,
		FilterCreationPropertiesComponent,
		TemplateTransferModalComponent,
	],
})
export class UnifiedTemplatesModule { }
