import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ClarabridgeAppType } from './clarabridge-app-type.enum';

export class FavoriteProperties {
	favoriteCP: number;
	favoriteAccount: number;
	favoriteProject: number;
	favoriteDashboard: number;
	startupApp: ClarabridgeAppType;
	workspaceProject: WorkspaceProject;
}