

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as _ from 'underscore';
import { UIOption } from '@clarabridge/unified-angular-components';

@Component({
	selector: 'checkbox-dropdown',
	template: `
<div ngbDropdown #dropdown='ngbDropdown'
	class="flex-fill chicklet option-chicklet dropdown-input p-0">
	<a ngbDropdownToggle role="button"
		href="javascript:void(0)"
		(keyup.enter)="dropdown.toggle()"
		aria-expanded="false" aria-haspopup="true"
		[title]="getToggleText()">
		{{getToggleText()}} <span class="caret"></span>
	</a>
	<div class="dropdown-menu" ngbDropdownMenu role="menu">
		<ul class="pl-0 mb-0 scrolling-list">
			<ng-container *ngFor="let option of options">
				<li class="d-flex" ngbDropdownItem
					tabindex="0"
					(click)="toggleOption(option, $event)"
					(keyup.enter)="toggleOption(option, $event)"
					(mousedown)="$event.stopPropagation()">
					<i class="mr-8" [ngClass]="getCheckedClass(option)"></i>
					{{option.displayName}}
				</li>
			</ng-container>
		</ul>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CheckboxDropdownComponent<T> implements OnInit {
	@Input() options: UIOption<T>[];
	@Input() selection: T[];
	@Input() truncateSelections?: boolean = false;
	@Output() selectionChange = new EventEmitter<T[]>();

	selectPrompt: string;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.selectPrompt = this.locale.getString('common.selectPrompt');
	}

	getCheckedClass(option: UIOption<T>): string {
		return this.selection.contains(option.value) ? 'q-icon q-icon-check' : 'q-icon q-icon-circle';
	}

	getToggleText = (): string => {
		if (this.truncateSelections) {
			return this.getTruncatedSelections() || this.selectPrompt;
		}

		let selectedOptions = _.chain(this.selection)
			.map(value => _.findWhere(this.options, {value}))
			.filter(option => !!option)
			.map(option => option.displayName)
			.join(', ')
			.value();
		return selectedOptions || this.selectPrompt;
	}

	private getTruncatedSelections(): string {
		if (this.selection.length) {
			let firstSelection = this.getDisplayNameByValue(this.selection[0]);

			if (this.selection.length === 1) {
				return firstSelection;
			}
			return `${firstSelection} + ${this.selection.length - 1}`;
		}
	}

	private readonly getDisplayNameByValue = (value: T): string => {
		return _.find(this.options, { value }).displayName;
	}

	toggleOption = (option: UIOption<T>, event: MouseEvent): void => {
		if (this.selection.contains(option.value))
			this.selection.remove(option.value);
		else this.selection.push(option.value);
		this.selectionChange.emit(this.selection);
		event.stopPropagation();
	}
}

app.directive('checkboxDropdown', downgradeComponent({component: CheckboxDropdownComponent}) as angular.IDirectiveFactory);
