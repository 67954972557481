app.filter('middleEllipsis', () => {
	return (str: string, maxLength: number) => {
		if (str.length > maxLength && maxLength >= 9) {
			return getLeadingPiece(str, maxLength) + '...' + getTrailingPiece(str, maxLength);
		}
		return str;
	};

	function getLeadingPiece(str: string, limit: number): string {
		let overflow = str.length - limit;
		let overflowRemove = Math.floor(overflow / 2);
		let leadLength = Math.floor(str.length / 2) - overflowRemove - 2; // -2 to make room for 2 of 3 ellipsis dots
		return str.left(leadLength);
	}

	function getTrailingPiece(str: string, limit: number): string {
		let overflow = str.length - limit;
		let overflowRemove = Math.ceil(overflow / 2);
		let trailingLength = Math.ceil(str.length / 2) - overflowRemove - 1; // -1 to make room for 1 of 3 ellipsis dots
		return str.right(trailingLength);
	}
});
