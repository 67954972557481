import * as _ from 'underscore';
import {GroupIdentifierHelper} from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { AnalyticMetricType, MetricTypePlaceholderProperties } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { WidgetConversionsService } from '@cxstudio/reports/providers/cb/widget-conversions.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { TEMPLATE_PLACEHOLDER_PREDEFINED_ITEMS } from '@cxstudio/dashboard-templates/placeholders/template-placeholder-predefined-items';
import { TemplatePlaceholderPlacements } from '@cxstudio/dashboard-templates/template-placeholder-placements';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { ReportAsset } from '@cxstudio/reports/entities/report-asset';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DateAttributeType } from '@cxstudio/reports/attributes/date-attribute-type';
import { SelectionPlaceholders } from '@app/modules/unified-templates/dashboard-templates/selection-placeholders.class';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { CBSettingsService } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { TemplateOptions, TemplateOptionsService } from '../template-options-service.service';

export interface ITemplateBasePlaceholder {
	key: ITemplatePlaceholderKey;
	originalDisplayName: string;
	system: boolean;
	hidden: boolean;
}

export interface IDashboardTemplatePlaceholder extends ITemplateBasePlaceholder {
	widgets: ITemplateWidgetKey[];
	dashboardFilters: any[];
}

export interface ITemplatePlaceholderKey {
	id: number;
	displayName: string;
}

interface ITemplateWidgetKey {
	id: number;
	displayName: string;
}

interface IReplacement {
	item?: ReportAsset;
	originalExtension?: any;
	placeholderWidget?: any;
}

interface ISingleReplacementResult {
	replacement?: IReplacement;
	missesReplacements: boolean;
}

interface IReplacementResult {
	replacements: IReplacement[];
	missesReplacements: boolean;
}

export interface IDashboardTemplatePlaceholders {
	calculations: IDashboardTemplatePlaceholder[];
	groupings: IDashboardTemplatePlaceholder[];
}

export class TemplatePlaceholderService {

	readonly UNIQUE_SYSTEM_ITEMS = [ '_calculation_series', '_pop', 'sentiment', 'easeScore'];
	readonly DOC_DATE_ATTRIBUTE = '_doc_time';

	constructor(
		private metricConstants: MetricConstants,
		private widgetConversions: WidgetConversionsService,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private cbSettingsService: CBSettingsService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private $q: ng.IQService,
		private templateOptionsService: TemplateOptionsService,
	) {}

	/**
	 * @param {*} widgets - dashboard widgets
	 * @returns {Promise<TemplatePlaceholders>} - placeholders for the provided list of widgets
	 */
	createPlaceholders(widgets: Widget[], dashboard?: Dashboard): ng.IPromise<IDashboardTemplatePlaceholders> {
		widgets = widgets
			.filter(widget => this.isReportWidget(widget))
			.filter(widget => !this.isStudioAdminWidget(widget));

		let calculationPlaceholders = [];
		let groupingPlaceholders = [];

		return this.$q.all([
			// primary
			this.populateIndexedPlaceholders(calculationPlaceholders, widgets,
				{ path: 'properties.selectedMetrics', placement: TemplatePlaceholderPlacements.PRIMARY }),
			this.populateIndexedPlaceholders(groupingPlaceholders, widgets,
				{ path: 'properties.selectedAttributes', placement: TemplatePlaceholderPlacements.PRIMARY }),
			// sort metrics
			this.populateGroupingSortMetricPlaceholders(calculationPlaceholders, widgets),
			this.populateGroupingSelectionSortMetricPlaceholders(calculationPlaceholders, widgets),
			// selections
			this.populateCalculationSelectionPlaceholders(calculationPlaceholders, widgets),
			this.populateGroupingSelectionPlaceholders(groupingPlaceholders, widgets),
			//populate dashboard filter placeholders
			this.populateGroupingDashboardFilterPlaceholders(groupingPlaceholders, dashboard),
			// other
			this.populateIndexedPlaceholders(calculationPlaceholders, widgets,
				{ path: 'visualProperties.calculationSeries', placement: TemplatePlaceholderPlacements.SERIES })
		]).then(() => {
			return {
				calculations: calculationPlaceholders,
				groupings: groupingPlaceholders
			};
		});
	}

	/**
	 * Applies placeholders for groupings, calculations and selected attributes in a given widgets.
	 * If replacement populator is not found for a given widget type (e.g. content widgets),
	 * population process is skipped.
	 * @param {*} widgets widgets to apply placeholders to
	 * @param {*} placeholders applied placeholders
	 */
	applyPlaceholders(widgets: Widget[], placeholders: IDashboardTemplatePlaceholders): Array<{widget: Widget, missesReplacements: boolean}> {
		return widgets.map((widget) => {
			let placeholderApplier = this.widgetConversions.byWidgetType(widget.name as WidgetType);
			if (!placeholderApplier) return {
				widget,
				missesReplacements: false
			};

			let calculationReplacementResult = this.compileCalculationReplacements(widget, placeholders);
			let groupingReplacementResult = this.compileGroupingReplacements(widget, placeholders);
			let selectionReplacementResult = this.compileSelectionReplacements(widget, placeholders);
			let calculationSeriesReplacementResult = this.compileCalculationSeriesReplacements(widget, placeholders);

			let replacements = {
				properties: {
					selectedMetrics: calculationReplacementResult.replacements
						.map((replacement) => replacement.item),
					selectedAttributes: groupingReplacementResult.replacements
						.map((replacement) => replacement.item)
				},
				visualProperties: {
					attributeSelections: selectionReplacementResult.replacement,
					calculationSeries: calculationSeriesReplacementResult.replacements
						.map((replacement) => replacement.item)
				}
			};

			let conversion = placeholderApplier.targetWidget as ReportWidget;
			conversion.initFrom(widget, replacements);

			let resultWidget = conversion.getWidget();
			resultWidget.visualProperties.attributeSelections = selectionReplacementResult.replacement as any;

			return {
				widget: resultWidget,
				missesReplacements: calculationReplacementResult.missesReplacements
					|| groupingReplacementResult.missesReplacements
					|| selectionReplacementResult.missesReplacements
			};
		});
	}

	/**
	 * @param {*} widget given widget
	 * @returns false for CONTENT widgets, otherwise true
	 */
	private isReportWidget(widget: Widget): boolean {
		return widget.type === WidgetDisplayType.CB;
	}

	/**
	 * @param {*} widget given widget
	 * @returns true for widget with studio admin project
	 */
	private isStudioAdminWidget(widget): boolean {
		return InternalProjectTypes.isStudioAdminProject(widget.properties.project);
	}



	/**
	 * Compiles primary calculation replacements for "properties.selectedMetrics" field.
	 * Inherits original properties for each calculation from widget.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholdes to compiled replacements from
	 */
	private compileCalculationReplacements(widget, placeholders): IReplacementResult {
		let replacementResult = this.compileIndexedReplacements(widget, placeholders.calculations,
			{ path: 'properties.selectedMetrics', placement: TemplatePlaceholderPlacements.PRIMARY });
		replacementResult.replacements.forEach((replacement) => {
			this.applyCalculationReplacementExtension(replacement);
		});

		return replacementResult;
	}

	/**
	 * Compiles primary groupng replacements for "properties.selectedAttributes" field.
	 * Inherits original properties for each grouping from widget.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholdes to compiled replacements from
	 */
	private compileGroupingReplacements(widget, placeholders): IReplacementResult {
		let replacementResult = this.compileIndexedReplacements(widget, placeholders.groupings,
			{ path: 'properties.selectedAttributes', placement: TemplatePlaceholderPlacements.PRIMARY});
		replacementResult.replacements.forEach((replacement, index) => {
			this.applyGroupingReplacementExtension(replacement, placeholders, index);
		});
		return replacementResult;
	}

	/**
	 * Compiles visual calculationSeries replacements for "visualProperties.calculationSeries" field.
	 * Inherits original properties for each calculation in series from widget.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholdes to compiled replacements from
	 */
	private compileCalculationSeriesReplacements(widget, placeholders): IReplacementResult {
		let replacementResult = this.compileIndexedReplacements(widget, placeholders.calculations,
			{ path: 'visualProperties.calculationSeries', placement: TemplatePlaceholderPlacements.SERIES });
		replacementResult.replacements.forEach((replacement) => {
			this.applyCalculationReplacementExtension(replacement);
		});

		return replacementResult;
	}

	/**
	 * Compiles a replacement object for "visualProperties.attributeSelections"
	 * @param {*} widget widget to find replacements for
	 * @param {*} placeholders placeholders to find replacements from
	 */
	private compileSelectionReplacements(widget, placeholders): ISingleReplacementResult {
		if (!placeholders) return { replacement: {}, missesReplacements: false };

		let replacement = {};
		let calculationReplacements = this.compileCalculationSelectionReplacements(widget, placeholders);
		let groupingReplacements = this.compileGroupingSelectionReplacements(widget, placeholders);
		_.extend(replacement, calculationReplacements.replacement);
		_.extend(replacement, groupingReplacements.replacement);

		return {
			replacement,
			missesReplacements: calculationReplacements.missesReplacements
				|| groupingReplacements.missesReplacements
		};
	}

	/**
	 * Compiles calculation replacements for "visualProperties.attributeSelections".
	 * Inherits original configuration from the widget for each calculation.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholders to compile calculation replacements from
	 */
	private compileCalculationSelectionReplacements(widget, placeholders): ISingleReplacementResult {
		let replacementResult = this.compileFieldSelectionReplacements(widget, placeholders.calculations);

		let selectionReplacement = {};
		replacementResult.replacements.forEach((replacement) => {
			this.applyCalculationReplacementExtension(replacement);
			selectionReplacement[replacement.placeholderWidget.name] = replacement.item;
		});

		return {
			replacement: selectionReplacement,
			missesReplacements: replacementResult.missesReplacements
		};
	}

	/**
	 * Compiles grouping replacements for "visualProperties.attributeSelections".
	 * Inherits original configuration from the widget for each grouping.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholders to compile grouping replacements from
	 */
	private compileGroupingSelectionReplacements(widget, placeholders): ISingleReplacementResult {
		let replacementResult = this.compileFieldSelectionReplacements(widget, placeholders.groupings);

		let selectionReplacement = {};
		replacementResult.replacements.forEach((replacement, index) => {
			this.applyGroupingReplacementExtension(replacement, placeholders, index);
			selectionReplacement[replacement.placeholderWidget.name] = replacement.item;
		});

		return {
			replacement: selectionReplacement,
			missesReplacements: replacementResult.missesReplacements
		};
	}

	/**
	 * Compiles replacements from "visualProperties.attributeSelections" property.
	 * @param {*} widget widget to compile replacements for
	 * @param {*} placeholders placeholders to compile replacements from
	 */
	private compileFieldSelectionReplacements(widget, placeholders): IReplacementResult {
		if (!placeholders) return { replacements: [], missesReplacements: false };

		let replacements: IReplacement[] = [];
		let missesReplacements = false;

		placeholders.forEach((placeholder) => {
			placeholder.widgets.forEach((placeholderWidget) => {
				if (placeholderWidget.id !== widget.id) return;
				if (placeholderWidget.placement !== TemplatePlaceholderPlacements.SELECTION) return;

				let replacement = this.findPlaceholderReplacement(placeholder);
				if (!replacement) {
					missesReplacements = true;
					return;
				}

				let originalExtension = (widget.visualProperties.attributeSelections
						&& widget.visualProperties.attributeSelections[placeholderWidget.name]) || {};
				replacements.push({
					item: replacement,
					originalExtension,
					placeholderWidget
				});
			});
		});

		return {
			replacements,
			missesReplacements
		};
	}

	/**
	 * @param {placement: PlacementType, index: number} placeholderWidget - grouping widget placement to find calculation sorting for
	 * @param {[CalculationPlaceholder]]} calculationPlaceholders - calculation placeholders to find calculation sortings from
	 * @returns grouping calculation sorting from provided calculation placeholders or NULL if could not find
	 */
	private findGroupingSortMetricReplacement(placeholderWidget, calculationPlaceholders): any {
		if (!calculationPlaceholders) return null;

		for (let placeholder of calculationPlaceholders) {
			for (let placement of placeholder.widgets) {
				if (placement.id === placeholderWidget.id
						&& placement.placement === TemplatePlaceholderPlacements.GROUPING_SORT_METRIC
						&& placement.index === placeholderWidget.index
						&& placement.name === placeholderWidget.name) {
					return this.findPlaceholderReplacement(placeholder);
				}
			}
		}

		return null;
	}

	/**
	 * @param {*} widget
	 * @param {*} placeholders
	 * @param {path: string, placement: templatePlaceholderPlacements} property
	 * @returns { replacements: [{ object: item, object: originalExtension }], missesReplacements: boolean }
	 */
	private compileIndexedReplacements(widget, placeholders, property): IReplacementResult {
		if (!placeholders) return { replacements: [], missesReplacements: false };

		// grouping and calculation replacements for each widget should preserve the original order
		let indexedReplacements = [];
		// returns true, if replacement was not found and widget should be marked as "templated"
		let missesReplacements = false;

		placeholders.forEach((placeholder) => {
			placeholder.widgets.forEach((placeholderWidget) => {
				if (placeholderWidget.id !== widget.id) return;
				if (placeholderWidget.placement !== property.placement) return;

				let replacement = this.getIndexedReplacement(placeholder, placeholderWidget, widget, property);
				if (!replacement) {
					missesReplacements = true;
					return;
				}

				indexedReplacements.push(replacement);
			});
		});

		indexedReplacements.sort((a, b) => a.index - b.index);
		let replacements = indexedReplacements.map((ir) => ir.replacement);

		return {
			replacements,
			missesReplacements
		};
	}

	/**
	 * @param {*} placeholder - the placeholder item
	 * @param {*} placeholderWidget - the placeholder widget description (index, original name, etc.)
	 * @param {*} widget - the target widget to put replacement into
	 * @param {path: string, placement: templatePlaceholderPlacements} property
	 * @returns indexed replacement or *null*, if replacement was not specified or found
	 */
	private getIndexedReplacement(placeholder, placeholderWidget, widget, property): {replacement: IReplacement, index: number} {
		let replacement = this.findPlaceholderReplacement(placeholder);
		if (!replacement) return null;

		let originalItems = WidgetPropertyService.resolve(widget, property.path) || [];
		let originalExtension = originalItems[placeholderWidget.index] || {};

		return {
			replacement: {
				item: replacement,
				originalExtension,
				// send this through for more convinient further processing
				placeholderWidget
			},
			index: placeholderWidget.index
		};
	}

	/**
	 * For a system placeholder (e.g. volume, sentiment) searches the item among default system items.
	 * Otherwise customly defined replacement for this placeholder.
	 * @param {*} placeholder placeholder to search replacement for
	 */
	private findPlaceholderReplacement(placeholder): ReportAsset {
		let replacement = placeholder.system ? this.findSystemItem(placeholder.key) : placeholder.replacement;
		// copy replacement object, so properties are not overriden by widget processing
		let newReplacement = angular.copy(replacement);
		if (newReplacement && !newReplacement.displayName && placeholder && placeholder.displayName) {
			newReplacement.displayName = placeholder.displayName;
		}
		return newReplacement;
	}

	/**
	 * Creates indexed placeholders (e.g. "properties.selectedAttributes" or "visualProperties.calcuulationSeries")
	 * @param {*} widgets widgets to create primary placeholders from
	 * @param {path: string, placement: templatePlaceholderPlacements} property
	 */
	private populateIndexedPlaceholders(placeholders, widgets, property): ng.IPromise<void> {
		if (!widgets) return this.$q.when();

		widgets.forEach((widget) => {
			let items = WidgetPropertyService.resolve(widget, property.path);
			if (!items) return;

			for (let index = 0; index < items.length; index++) {
				let item = items[index];
				this.populatePlaceholder(placeholders, widget, item, {
					placement: property.placement,
					index
				});
			}
		});
		return this.$q.when();
	}

	/**
	 * Populates sort metric placeholders for "properties.selectedAttributes" groupings
	 * @param {*} placeholders - the array to populate placeholders into
	 * @param {*} widgets - list of widgets to populate placeholders from
	 */
	private populateGroupingSortMetricPlaceholders(placeholders, widgets): ng.IPromise<void> {
		return this.populateWidgetSortMetricPlaceholders(placeholders, widgets,
			widget => this.primaryGroupingsWidgetSortMetricSource(widget));
	}

	/**
	 * Populates sort metric placeholders for "visualProperties.attributeSelections" groupings
	 * @param {*} placeholders - the array to populate placeholders into
	 * @param {*} widgets - list of widgets to populate placeholders from
	 */
	private populateGroupingSelectionSortMetricPlaceholders(placeholders, widgets): ng.IPromise<void> {
		return this.populateWidgetSortMetricPlaceholders(placeholders, widgets,
			widget => this.groupingSelectionWidgetSortMetricSource(widget));
	}

	/**
	 * Function to extract primary groupings and their placement extensions from "properties.selectedAttributes"
	 * @param {*} widget given widget
	 */
	private primaryGroupingsWidgetSortMetricSource(widget): any {
		if (!widget.properties.selectedAttributes) return [];

		return widget.properties.selectedAttributes.map((grouping, index) => {
			return {
				grouping,
				placement: {
					index
				}
			};
		});
	}

	/**
	 * Function to extract grouping attribute selections array with their placement extension from "visualProperties.attributeSelections"
	 * @param {*} widget given widget
	 */
	private groupingSelectionWidgetSortMetricSource(widget): any[] {
		let attributeSelections = widget.visualProperties.attributeSelections || {};
		return SelectionPlaceholders.groupings.map((groupingSelection) => {
			return {
				grouping: attributeSelections[groupingSelection],
				placement: {
					name: groupingSelection
				}
			};
		});
	}

	/**
	 * Populates sort metrics placeholders
	 * @param {*} placeholders target array
	 * @param {*} widgets widgets to populate sort metric placeholders from
	 * @param {*} sortMetricWidgetSourceFunc function to extract grouping items and their placement extensions from the widget
	 */
	private populateWidgetSortMetricPlaceholders(placeholders, widgets, sortMetricWidgetSourceFunc): ng.IPromise<void> {
		if (!widgets) return this.$q.when();
		let widgetTemplateOptionsPromises = widgets.map(widget => this.getWidgetTemplateOptions(widget));

		return this.$q.all(widgetTemplateOptionsPromises).then((res) => {
			for (let widgetIndex = 0; widgetIndex < widgets.length; widgetIndex++) {
				let widget = widgets[widgetIndex];
				let templateOptions = res[widgetIndex] as TemplateOptions;
				let calculationOptions = this.templateOptionsService.buildCalculationOptions(templateOptions, true);

				let groupingItems = sortMetricWidgetSourceFunc(widget);
				for (let groupingItem of groupingItems) {
					let grouping = groupingItem.grouping;

					if (!grouping || !grouping.sortBy) return;

					let item = this.findOptionByName(calculationOptions, grouping.sortBy);
					// if could not find sorting metric among available options
					if (!item) return;

					this.populatePlaceholder(placeholders, widget, item,
						_.extend({ placement: TemplatePlaceholderPlacements.GROUPING_SORT_METRIC }, groupingItem.placement));
				}
			}
		});
	}

	/**
	 * Gets widget template options (attributes, metrics, system attributes etc.)
	 * @param {*} widget given widget
	 */
	private getWidgetTemplateOptions(widget): ng.IPromise<TemplateOptions> {
		let project = this.reportAssetUtilsService.getWidgetProject(widget);
		return this.templateOptionsService.loadTemplateOptions(project);
	}

	/**
	 * Populates calculation placeholders from "visualProperties.attributeSelections"
	 * @param {*} placeholders array to populate placeholders into
	 * @param {*} widgets widgets to populate placeholders from
	 */
	private populateCalculationSelectionPlaceholders(placeholders, widgets): ng.IPromise<void> {
		return this.populateSelectionPlaceholders(placeholders, widgets, SelectionPlaceholders.calculations);
	}

	/**
	 * Populates grouping placeholders from "visualProperties.attributeSelections"
	 * @param {*} placeholders array to populate placeholders into
	 * @param {*} widgets widgets to populate placeholders from
	 */
	private populateGroupingSelectionPlaceholders(placeholders, widgets): ng.IPromise<void> {
		return this.populateSelectionPlaceholders(placeholders, widgets, SelectionPlaceholders.groupings);
	}

	/**
	 * Populates placeholders from "visualProperties.attributeSelections".
	 * @param {*} placeholders array to populate placeholders into
	 * @param {*} widgets widgets to iterate over to find placeholders
	 * @param {*} fields corresponding fields under the "attributeSelections" to iterate over
	 */
	private populateSelectionPlaceholders(placeholders, widgets, fields): ng.IPromise<void> {
		if (!widgets) return;

		widgets.forEach((widget) => {
			let selections = (widget.visualProperties && widget.visualProperties.attributeSelections) || {};
			fields.forEach((field) => {
				let item = selections[field];
				if (!item || !item.name) return;

				this.populatePlaceholder(placeholders, widget, item, {
					placement: TemplatePlaceholderPlacements.SELECTION,
					name: field
				});
			});
		});
	}

	private populateGroupingDashboardFilterPlaceholders(groupSelections, dashboard: Dashboard): ng.IPromise<void> {
		if (DashboardUtils.getDashboardFiltersCount(dashboard) > 0) {
			let filterIndex;
			let needsPlaceholder;
			for (filterIndex = 0; filterIndex < dashboard.appliedFiltersArray.length; filterIndex++) {
				let filter = dashboard.appliedFiltersArray[filterIndex];
				needsPlaceholder = false;
				if (filter.isOrgHierarchy) {
					continue;
				}
				let placeholderKey;
				let filterRule = filter.selectedAttribute;
				if (InternalProjectTypes.isStudioAdminAttribute(filterRule)) {
					continue;
				}
				if (filterRule.filterType === DashboardFilterTypes.TOPIC) {
					//for topic filter, save as grouping
					needsPlaceholder = true;
					placeholderKey = {metricType: AnalyticMetricType.CLARABRIDGE, name: filterRule.id + ''};
				}

				//remove CB attributes from placeolder in CXS-5784
				if (filterRule.filterType === DashboardFilterTypes.ATTRIBUTE) {
					needsPlaceholder = true;
					placeholderKey = {metricType: AnalyticMetricType.ATTRIBUTE, name: filterRule.name};

				}
				//nothing to do for saved filters
				//attribute filters replacement (check cb_)

				if (needsPlaceholder) {
					let placeholder = this.findExistingPlaceholder(groupSelections, placeholderKey);
					if (!placeholder) {
						placeholder = this.buildPlaceholder(filterRule as any, placeholderKey, false, false);
						placeholder.dashboardFilters.push({filterIndex});
						groupSelections.push(placeholder);
					} else {
						placeholder.dashboardFilters.push({filterIndex});
					}
				}
			}
		}
		return this.$q.when();
	}

	/**
	 * Either adds placeholder placement if placeholder already exists,
	 * or create placeholder with a single placement.
	 * @param {*} placeholders list of existing placeholders
	 * @param {*} widget widget to create placeholders for
	 * @param {*} item placeholder item
	 * @param {*} placement placeholder placement in the given widget
	 */
	private populatePlaceholder(placeholders, widget, item, placement): void {
		let matchingSystemItem = this.findSystemItem(item);
		let system = matchingSystemItem !== null;
		let hidden = false;
		if (system && _.contains(TEMPLATE_PLACEHOLDER_PREDEFINED_ITEMS, item.name)) {
			system = false;
			hidden = true;
		}

		let placeholderKey = this.buildPlaceholderKey(item);
		let placeholder = this.findExistingPlaceholder(placeholders, placeholderKey);
		if (!placeholder) {
			placeholder = this.buildPlaceholder(item, placeholderKey, system, hidden);
			placeholders.push(placeholder);
		}

		let widgetKey = this.buildWidgetKey(widget);
		_.extend(widgetKey, placement);
		if (!this.findExistingWidget(placeholder.widgets, widgetKey)) {
			placeholder.widgets.push(widgetKey);
		}
	}

	/**
	 * Finds predefined system item for a given metric/grouping (e.g "volume", "sentiment").
	 * @param {*} item given item
	 */
	private findSystemItem(item: ReportAsset): ReportAsset {
		let itemKey = this.buildPlaceholderKey(item);

		let systemItems = this.getSystemItems();
		for (let systemItemName in systemItems) {
			if (systemItems.hasOwnProperty(systemItemName)
					&& _.isMatch(itemKey, this.buildPlaceholderKey(systemItems[systemItemName]))) {
				return systemItems[systemItemName];
			}
		}

		return null;
	}

	/**
	 * Finds existing widget placement by key among given widgets
	 */
	private findExistingWidget(widgets: ITemplateWidgetKey[], key: ITemplateWidgetKey): ITemplateWidgetKey {
		return _.findWhere(widgets, key);
	}

	/**
	 * Builds placeholder base object
	 * @param {*} item item (grouping or calculation)
	 * @param {*} key placeholder key (set of item idenitifying fields and their values)
	 * @param {boolean} system if given item is a system item
	 */
	private buildPlaceholder(item: ReportAsset, key: ITemplatePlaceholderKey, system: boolean, hidden: boolean):
		IDashboardTemplatePlaceholder {
		return {
			originalDisplayName: item.displayName,
			key,
			widgets: [],
			system,
			hidden,
			dashboardFilters: []
		};
	}

	/**
	 * Builds item placement base object for a given widget
	 * @param {*} widget given widget
	 */
	private buildWidgetKey(widget): ITemplateWidgetKey {
		return {
			id: widget.id,
			displayName: widget.displayName
		};
	}

	/**
	 * Tries to find existing placeholders in a given array by placeholder key
	 * @param {*} placeholders given array
	 * @param {*} key placeholder key (set of placeholder identifiying fields with their values)
	 * @returns existing placeholder or *null*
	 */
	private findExistingPlaceholder(placeholders: IDashboardTemplatePlaceholder[], key): IDashboardTemplatePlaceholder {
		return _.find(placeholders, (placeholder) => {
			return _.isMatch(placeholder.key, key);
		});
	}

	/**
	 * Builds placeholder key for a given item (grouping or calculation)
	 * @param {*} item given item
	 */
	private buildPlaceholderKey(item: ReportAsset): ITemplatePlaceholderKey {
		let key = {} as ITemplatePlaceholderKey;

		let keyProperties = this.getPlaceholderKeyProperties(item);
		for (let keyProperty of keyProperties) {
			key[keyProperty] = item[keyProperty];
		}

		return key;
	}

	/**
	 * For a given item (grouping or calculation) returns a set of identifying object keys, depending on its type.
	 * For example for 'topic' or 'topicLeaf' the only identifying field is 'id'.
	 * Item type also always is included as identifying field.
	 * @param {*} item given item
	 */
	private getPlaceholderKeyProperties(item: ReportAsset): string[] {
		if (this.UNIQUE_SYSTEM_ITEMS.indexOf(item.name) > -1) return [ 'name' ];
		let field = 'metricType';

		let defaultProperties = [ 'metricType' ];
		let metricTypeProperties = MetricTypePlaceholderProperties[item[field]];

		return defaultProperties.concat(metricTypeProperties);
	}

	/**
	 * Returns an array of predefined system items, that are consisten across projects
	 */
	private getSystemItems(): {[key: string]: ReportAsset} {
		let systemItems = this.metricConstants.get();
		this.UNIQUE_SYSTEM_ITEMS.forEach((name) => {
			systemItems[name] = { name };
		});
		// document date time groupings
		TimePrimaryGroups.getValues().forEach((timeGroup) => {
			systemItems[timeGroup] = {
				attributeName: this.DOC_DATE_ATTRIBUTE,
				timeName: timeGroup,
				name: `${this.DOC_DATE_ATTRIBUTE}.${timeGroup}`,
				metricType: AnalyticMetricType.TIME,
				dateAttributeType: DateAttributeType.DATE_TIME
			};
		});

		return systemItems;
	}

	/**
	 * Finds an option by a name among a list of options (attributes, custom metrics, system items).
	 * Or returns *null*.
	 * @param {*} options given options
	 * @param {*} name option name
	 */
	private findOptionByName(options: any[], name: string): any {
		for (let option of options) {
			if (option.name === name) return option;

			if (!option.children) continue;

			let matchingChildOption = this.findOptionByName(option.children, name);
			if (matchingChildOption) return matchingChildOption;
		}

		return null;
	}

	/**
	 * For a given grouping replacement, applies its original properties (formatting, number of items, etc.) from original widget.
	 * Or populates default property values instead.
	 * @param {*} replacement replacement to populate
	 * @param {*} placeholders populated widget placeholders
	 */
	private applyGroupingReplacementExtension(replacement, placeholders, index): void {
		let groupingConfigurationExtension = _.pick(replacement.originalExtension, CommonInherentProperties.templateGroupingProperties);
		this.cbSettingsService.initDefaultProperties([ replacement.item ], groupingConfigurationExtension);

		let placeholderWidget = replacement.placeholderWidget;
		let groupingSortMetricReplacement = this.findGroupingSortMetricReplacement(placeholderWidget, placeholders.calculations);
		if (groupingSortMetricReplacement) {
			replacement.item.sortBy = groupingSortMetricReplacement.name;
		}
		replacement.item.identifier = GroupIdentifierHelper.generateIdentifier(replacement.item, index);
	}

	/**
	 * For a given calculation replacement, applies its original properties (formatting, number of items, etc.) from original widget.
	 * Or populates default property values instead.
	 * @param {*} replacement replacement to populate
	 */
	private applyCalculationReplacementExtension(replacement): void {
		let calculationDefaults = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(replacement.item);
		replacement.item = _.extend(replacement.item, calculationDefaults, replacement.originalExtension);
	}
}

app.service('templatePlaceholderService', TemplatePlaceholderService);
