import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

declare let d3;
declare let Math: IMath;

interface ICloudConfig {
	width: number;
	height: number;
	words: any[];
	angles: number[];
	spiral: string;
	maxWordLength: number;
}

interface IMath extends Math {
	nrand: () => number;
}

@Injectable({
	providedIn: 'root'
})
export class CloudProcessorService {

	process = (config: ICloudConfig,
		callback: (wordsData: any[]) => void) => {

		let width = config.width;
		let height = config.height;
		let spiral = config.spiral;
		let angles = config.angles;
		let words = config.words;
		let maxWordLength = config.maxWordLength;

		Math.nrand = () => {
			let x1;
			let x2;
			let rad;

			do {
				x1 = 2 * Math.random() - 1;
				x2 = 2 * Math.random() - 1;
				rad = x1 * x1 + x2 * x2;
			} while (rad >= 1 || rad === 0);

			let c = Math.sqrt(-2 * Math.log(rad) / rad);

			return x1 * c;
		};

		let spirals = {} as any;

		spirals.gaussianSpiral = (size: number[]) => {
			let e = size[0] / size[1];
			return (t) => {
				return [ e * (t *= 0.03) * Math.nrand(), t * Math.nrand() ];
			};
		};

		spirals.archimedeanSpiral = (size: number[]) => {
			let e = size[0] / size[1];
			return (t) => {
				return [e * (t *= 0.1) * Math.cos(t), t * Math.sin(t)];
			};
		};

		let d3Cloud = d3.layout.cloud()
			.size([ width ? width : 400, height ? height : 600 ])
			.words(words)
			.spiral(spirals[spiral])
			.rotate((d) => {
				if (d.code < 10 || d.text.length >= maxWordLength) {
					return 0;
				} else {
					return angles[Math.floor(Math.random() * angles.length)];
				}
			}).font('sans-serif').fontSize((d) => {
				return d.size;
			}).on('end', callback);

		d3Cloud.timeInterval(1) // to not freeze browser
			.start();

	}
}

app.service('cloudProcessor', downgradeInjectable(CloudProcessorService));
