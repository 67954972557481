import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';
import { PromiseUtils } from '@app/util/promise-utils';
import PermissionGroupData from '@app/modules/user-bulk/bulk-permissions/permission-group-data.class';

export interface BulkPermissionsDialogInput {
	bodyText: string;
}

export type BulkPermissionsUpdate = Record<'added' | 'removed' | 'none', MasterAccountPermissionsOnGrid[]>;

interface MasterAccountPermissionsOnGrid extends MasterAccountPermission {
	displayName: string;
	id: string;
	level: number;
	label: string;
	updateValue: BulkUpdateValue;
}

@Component({
	selector: 'bulk-permissions',
	templateUrl: './bulk-permissions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkPermissionsComponent implements OnInit {
	@Input() input: BulkPermissionsDialogInput;

	loading: Promise<any>;
	gridType = GridTypes.USERS_BULK_PERMISSIONS;
	private sections: string[];
	updateTrigger: boolean;

	permissionGroups: any[];
	permissions: MasterAccountPermissionsOnGrid[];

	constructor(
		private readonly modal: NgbActiveModal,
		private readonly dialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		private readonly permissionsService: PermissionsService,
		private readonly ref: ChangeDetectorRef,
		@Inject('usersGroupsApiService') private readonly usersGroupsApiService: UsersGroupsApiService,
	) { }

	ngOnInit(): void {
		this.sections = this.permissionsService.getGroups();
		this.loading = PromiseUtils.wrap(this.usersGroupsApiService.getPermissions().then(permissions => {
			this.permissions = this.processPermissions(permissions);
			this.permissionGroups = this.groupPermissions(this.permissions);
			this.ref.markForCheck();
		}));
	}

	private processPermissions(permissions: MasterAccountPermission[]): MasterAccountPermissionsOnGrid[] {
		return _.map(permissions, (permission: MasterAccountPermissionsOnGrid) => {
			let gridPermission = permission as MasterAccountPermissionsOnGrid;
			gridPermission.id = permission.action;
			gridPermission.level = 0;
			gridPermission.displayName = permission.label || this.locale.getString(`administration.${permission.action}`);
			return gridPermission;
		});
	}

	private groupPermissions(permissions: MasterAccountPermissionsOnGrid[]): PermissionGroupData[] {
		let groupedPermissions = _.groupBy(permissions, (permission: MasterAccountPermissionsOnGrid): string => {
			return this.permissionsService.getPermissionGroup(permission);
		});
		return _.chain(this.sections)
			.map((section: string): PermissionGroupData => {
				return new PermissionGroupData(
					this.getSectionDisplayName(section),
					groupedPermissions[section],
					section !== this.sections[0]);
			})
			.filter((group: PermissionGroupData) => {
				return !_.isEmpty(group.permissions);
			})
			.value();
	}

	onUpdate(item: MasterAccountPermissionsOnGrid): void {
		let requireRefresh = false;
		if (item.updateValue === BulkUpdateValue.ADD) {
			let dependsOn = this.permissionsService.getDependensOn(item);
			_.each(dependsOn, actionToAdd => {
				let toAdd = _.findWhere(this.permissions, {action: actionToAdd});
				if (toAdd) {
					toAdd.updateValue = BulkUpdateValue.ADD;
					requireRefresh = true;
				}
			});
		} else {
			let dependants = this.permissionsService.getDependants(item);
			_.each(dependants, actionToRemove => {
				let toRemove = _.findWhere(this.permissions, {action: actionToRemove});
				//if is not remove, set to remove or no change, if remove, don't change
				if (toRemove && toRemove.updateValue !== BulkUpdateValue.REMOVE) {
					toRemove.updateValue = item.updateValue;
					requireRefresh = true;
				}
			});
		}
		if (requireRefresh)
			this.updateTrigger = !this.updateTrigger;
	}

	save(): void {
		let res = _.groupBy(this.permissions, (permission) => {
			switch (permission.updateValue) {
				case BulkUpdateValue.ADD:
					return 'added';
				case BulkUpdateValue.REMOVE:
					return 'removed';
				default:
					return 'none';
			}
		}) as BulkPermissionsUpdate;
		this.modal.close(res);
	}

	cancel(): void {
		if (!this.isDataChanged()) {
			this.modal.dismiss();

			return;
		}

		this
			.dialogService
			.showUnsavedChangesDialogAndResolve(
				this.save,
				this.modal.dismiss,
				'permission.unsavedPermissionChangesHeader',
				'permission.unsavedPermissionChangesBody',
				false
			)
			.catch(() => {})
		;
	}

	private isDataChanged(): boolean {
		return _.some(this.permissions, (permission) => {
			return permission.updateValue && permission.updateValue !== BulkUpdateValue.NONE;
		});
	}

	private getSectionDisplayName(section: string): string {
		return this.locale.getString(`permission.${section}Permissions`);
	}

}
