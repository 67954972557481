import * as _ from 'underscore';
import { ModelIdentifier } from '@cxstudio/projects/model-identifier';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { ShowQuantitySettingsGroup } from '@app/modules/asset-management/entities/settings-groups/show-quantity-settings-group';
import { InclusionListSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/inclusion-list-settings-group';
import { SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { ModelGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { ISettingsEditor } from '@app/modules/asset-management/entities/settings-editor.interface';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { CapitalizationSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/capitalization-settings-group';
import { SettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.enum';
import { ModelsService } from '@app/modules/project/model/models.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';


export class ModelGroupingSettingsEditor implements ISettingsEditor<ModelGroupingSettings> {

	private groups: {[key: string]: ISettingsGroup<any>};

	constructor(
		private readonly optionsBuilderProvider: OptionsBuilderProvider,
		private readonly sortByOptionsService: SortByOptionsService,
		private readonly modelsService: ModelsService,
	) {}

	setSettings(source: ModelGroupingSettings, modelParams: ModelIdentifier): Promise<void> {
		let sortOptionsPromise = this.sortByOptionsService.getOptions(modelParams.project);
		let modelTreePromise = this.modelsService.getModelTree(modelParams.project, modelParams.modelId);
		return Promise.all([sortOptionsPromise, modelTreePromise]).then((result: any[]) => {
			let groups = {};

			let sortOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(result[0].standardMetrics)
				.withPredefinedMetrics(result[0].predefinedMetrics)
				.withAttributes(result[0].attributes, MetricFilters.CALCULATION)
				.withMetrics(result[0].metrics, modelParams.project.projectId)
				.build();

			groups[SettingsGroup.SHOW_QUANTITY] = new ShowQuantitySettingsGroup(source, sortOptions, true);
			groups[SettingsGroup.INCLUSION_LIST] = new InclusionListSettingsGroup(source, result[1].root);
			groups[SettingsGroup.CAPITALIZATION] = new CapitalizationSettingsGroup(source);

			this.groups = groups;
		});
	}

	getGroups(): {[key: string]: ISettingsGroup<any>} {
		return this.groups;
	}

	getSettings(): ModelGroupingSettings {
		let tabResult = {};

		for (const group of Object.keys(this.groups)) {
			tabResult = _.extend(tabResult, this.groups[group].postProcessSettings());
		}

		return tabResult as ModelGroupingSettings;
	}
}
