import { MapboxBuilderService } from '@app/modules/reports/visualizations/definitions/mapbox/mapbox-builder.service';
import { RandomUtils } from '@app/util/random-utils.class';
import GeographyReportData from '@cxstudio/attribute-geography/geography-report-data';
import GeographyDataItem from '@cxstudio/attribute-geography/geography-data-item';
import { ReportScopeUtils } from '../utils/report-scope-utils.service';
import { FeatureLimiterService } from '@app/modules/feature-limiter/feature-limiter.service';
import { LimitedFeature } from '@app/modules/feature-limiter/limited-feature';
import { FeatureLimiterResult } from '@app/modules/feature-limiter/feature-limiter-result';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ReportErrorMessagesService } from '@app/modules/widget-visualizations/utilities/report-error-messages.service';
import { WidgetError } from '@app/modules/widget-visualizations/common/widget-error/widget-error.class';

app.directive('mapboxMap', (
	tableService,
	reportUtils,
	highchartsTreeReportDataBuilder,
	mapboxBuilder: MapboxBuilderService,
	$rootScope: ng.IRootScopeService,
	$timeout: ng.ITimeoutService,
	$log: ng.ILogService,
	reportScopeUtils: ReportScopeUtils,
	featureLimiterService: FeatureLimiterService,
	reportErrorMessages: ReportErrorMessagesService,
	$q,
	locale: ILocale,
	applicationThemeService: ApplicationThemeService
) => {
	return {
		restrict: 'A',
		replace: true,
		template: `
			<div cg-busy="mapInit.promise">
				<div class="h-100-percent w-100-percent mapbox-map">
					<widget-error
						ng-if="pdf"
						[widget-error]="pdfError">
					</widget-error>
				</div>
			</div>
		`,
		scope: {
			options: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},
		link: (scope, element) => {
			let uniqueId = `mapbox-${RandomUtils.randomString()}`;
			let container = $(element).find('.mapbox-map');
			container.attr('id', uniqueId);

			scope.pdfError = new WidgetError(locale.getString('widget.mapWidgetPdfNotSupported'));

			let onRender = () => {
				reportUtils.handleWidgetRenderedEvent(
					scope.utils.widgetId, scope.utils.widgetType, scope.utils.containerId);
			};

			scope.pdf = $rootScope.pdfToken;
			if (scope.pdf) {
				scope.mapInit = $q.defer();
				onRender();
				scope.mapInit.resolve();
				return;
			}

			scope.selectedPoint = null;
			reportUtils.initDestroyListener(scope);
			if (_.isUndefined(scope.utils)) {
				scope.utils = {widgetType: 'undefined'};
			}

			if (!tableService.processIfNoData(container, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			let getReportData = (): GeographyReportData => {
				let selectedLevel = scope.utils.selectedAttributes.length - 1;
				let processedData = highchartsTreeReportDataBuilder.getAnalyticTreeData(scope);
				processedData.forEach(dataItem => dataItem.object.displayName = dataItem.displayName);
				processedData = processedData
					.map(dataItem => dataItem.object)
					.filter(dataItem => dataItem.level === selectedLevel);
				return {
					data: processedData,
					metadata: scope.dataObject.metadata
				};
			};

			let removeMap = () => {
				if (scope.map) {
					try {
						scope.map.remove();
					} catch (err) {
						$log.debug(`Could not fully destroy map ${uniqueId}. It is usually fine.`);
					}
				}
			};

			let onClick = (event) => {
				if (!scope.demo && scope.selectedPoint) {
					reportScopeUtils.onClick(scope, event);
				}
			};

			let onContextMenu = (event) => {
				if (!scope.demo && scope.selectedPoint) {
					reportScopeUtils.onContextMenu(scope, event);
				}
			};

			let onChangeActiveDataItem = (dataItem: GeographyDataItem) => {
				scope.selectedPoint = dataItem;
			};

			let onBoundsSet = () => {
				resolveMapInit();
			};

			let resolveMapInit = () => {
				if (scope.mapInit) {
					scope.mapInit.resolve();
				}
			};

			let renderChart = () => {
				if (!scope.demo) {
					scope.mapInit = $q.defer();
				}

				removeMap();

				featureLimiterService.useFeatureOnce(LimitedFeature.MAP_WIDGET).then(result => {
					if (result === FeatureLimiterResult.EXCEEDED_LIMIT) {
						reportErrorMessages.addErrorMessageToElement(container, locale.getString('widget.mapWidgetLimitMessage'));
						resolveMapInit();
						return;
					} else {
						let dataObject = getReportData();

						$timeout(() => {
							if ($(`.mapbox-map#${uniqueId}`).length !== 1) {
								return;
							}

							let map = mapboxBuilder.buildMap({
								isDemo: scope.demo,
								widgetType: scope.utils.widgetType,
								background: scope.options.mapBackground.name,
								isDarkMode: applicationThemeService.isShowingDashboardDarkTheme(),
								dataObject,
								uniqueId,
								showLabels: scope.options.showLabels,
								onRender,
								onClick,
								onContextMenu,
								onChangeActiveDataItem,
								onBoundsSet
							});
							if (map) {
								scope.map = map;
							}
						}, 0);
					}
				});
			};
			renderChart();

			// resize handler
			reportUtils.initResizeHandler(scope, container,
				(newValue: string, oldValue: string) => {
					if (!newValue || newValue === oldValue || !scope.map)
						return;

					$timeout(() => scope.map.resize(), 0);
				}
			);

			scope.$watch('trigger', reportUtils.chartTriggerHandler(scope, renderChart));
			scope.$on('$destroy', () => {
				removeMap();
			});
		} // link end
	};
});
