import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { CancellableDeferredFactory } from '@cxstudio/directives/utils/cancellable-deferred.factory';
import { NetworkErrorStatuses } from '../error_handlers/network-error-statuses.constant';
import { IErrorHandler } from './error-handler.class';

export class ErrorHandlerService {
	handlers: IErrorHandler[] = [
		new this.AuthErrorHandler(),
		new this.NetworkErrorHandler(),
		new this.ServerErrorHandler()
	];

	constructor(
		private readonly AuthErrorHandler,
		private readonly NetworkErrorHandler,
		private readonly ServerErrorHandler,
		private readonly cancellableDeferredFactory: CancellableDeferredFactory
	) { }

	getHandler(status): IErrorHandler | void {
		for (let handler of this.handlers) {
			if (handler.support(status)) {
				return handler;
			}
		}

		return undefined;
	}

	isHandledLocally(response): boolean {
		let status = response.status;
		if (status === CHANGE_MA_STATUS) {
			return true;
		}
		if (!response.config?.local) {
			return false;
		}

		let localHandling = response.config.local;
		if (status === 401 && !localHandling.unauthorized)
			return false; // if auth - always globally (except when specifically handled)

		let cancelledRequest = this.isRequestCancelled(response);
		if (_.contains(NetworkErrorStatuses, status) && !cancelledRequest)
			return false; // if network - always globally

		return true;
	}

	private isRequestCancelled(response): boolean {
		let config = response.config;
		return config.timeout && this.cancellableDeferredFactory.isCancelledPromise(config.timeout);
	}
}

/**
 * Modify rest url, add custom headers
 */
app.service('errorHandlerFactory', ErrorHandlerService);
