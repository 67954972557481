
export enum CaseEvent {
	ASSIGNED = 'assigned',
	CLOSED = 'closed',
	OPEN = 'open',
	PRIORITY_CHANGE = 'priority_change',
	REOPENED = 'reopened',
	TO_DO_COMPLETED = 'to_do_completed'
}

export class CaseVisualization {
	id: string;
	topicId: number;
	sourceId: string;
	title: string;
	displayName: string;
	events: CaseEvent[];
	color: string;
	date: { from: number; to: number };

	constructor(config?: Partial<CaseVisualization>) {
		return _.extend({
			date: {},
			events: []
		}, config) as CaseVisualization;
	}
}
