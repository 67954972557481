import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page/error-page.component';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	declarations: [ErrorPageComponent],
	imports: [
		CommonModule,
		I18nModule,
		SharedModule
	],
})

export class ErrorPageModule { }
