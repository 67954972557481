<div
	class="panel-body"
	style="min-height: 220px; max-height: 500px"
	[ngBusy]="loading"
>
	<ul class="nav nav-pills nav-stacked">
		<li
			[ngClass]="{ selected: isSelected(version) }"
			*ngFor="let version of versions"
			class="br-version-item"
		>
			<label (click)="selectVersion(version)">
				<p class="br-version-description mb-0">
					{{ getVersionDescription(version) }}
					<br />
					<span class="saved-by">
						{{ getVersionSaveStatus(version) }}</span
					>
				</p>
				<p
					[hidden]="!(isSelected(version) && version.id > 0)"
					class="br-version-save-status clearfix mb-0 mt-4"
				>
					<a
						(click)="revertVersion(version)"
						class="br-version-restore pull-left"
						[i18n]="'home.restore'"
					></a>
					<a
						(click)="saveVersion(version)"
						[hidden]="!showSaveAs()"
						class="br-version-save-as pull-right"
						[i18n]="'home.saveAs'"
					></a>
				</p>
			</label>
		</li>
		<li class="br-version-empty" *ngIf="versions?.length === 0">
			<em [i18n]="'home.noVersions'"></em>
		</li>
	</ul>
</div>
