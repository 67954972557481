<div [ngBusy]="statusPromise">
	<div class="d-flex w-100-percent align-items-end justify-between">
		<div class="d-flex align-items-end">
			<div class="mr-16">
				<label class="font-bold mb-0">{{'administration.masterAccountsSearchPlaceholder' | i18n}}
					<input type="text" class="form-control"
						[(ngModel)]="search.masterAccountName"
						(ngModelChange)="updateFilter()">
				</label>
			</div>
			<refresh-trigger
				class="btn btn-secondary"
				[refreshFunction]="reload"
				refreshLimit="10"
			></refresh-trigger>
		</div>
		<div>
			<button
				type="button"
				class="btn btn-secondary"
				[disabled]="!filteredRows?.length"
				(click)="exportData()"
				[i18n]="'administration.exportToExcel'">
			</button>
		</div>
	</div>
	<div class="mt-16">
		<formatted-table
				[rows]="filteredRows"
				[columns]="columns"
		></formatted-table>
	</div>
</div>
