import { Inject, Injectable } from '@angular/core';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { IProjectsAccess } from './project-access-interface';
import { ProjectAccessObject } from './project-access-object.class';


@Injectable({providedIn: 'root'})
export class ProjectAccessProvider {

	constructor(
		@Inject('ProjectAccessLevels') private ProjectAccessLevels: ProjectAccessLevelItems
	) {}

	getInstance(): IProjectsAccess {
		return new ProjectAccessObject(this.ProjectAccessLevels);
	}
}