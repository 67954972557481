import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import AnalyticWidget from './analytic-widget.class';

export class PieWidget extends AnalyticWidget {
	static get(): PieWidget {
		return new PieWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new VisualProperties();

		this.name = WidgetType.PIE;
		this.properties.widgetType = WidgetType.PIE;
		this.visualProperties.showLabels = true;
		this.visualProperties.showLegend = true;
		this.visualProperties.showGroupNames = true;
		this.visualProperties.visualization = WidgetVisualization.STACKEDPIE;
	}

	withDonutView(donutView: boolean) {
		this.visualProperties.donut = donutView;
		return this;
	}

	withCalculation(calculation: ReportCalculation): PieWidget {
		this.properties.selectedMetrics = [calculation];
		this.visualProperties.attributeSelections = {
			size: calculation
		};

		return this;
	}

}
