import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { WidgetMetricConfigurationOptions } from '@cxstudio/reports/providers/cb/constants/widget-metric-configuration-options.constant';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

export class GroupingConfigurerCogController implements ng.IController {

	private properties: any;
	private personalization: any;
	private grouping: any;
	private configureCallback: any;

	private stackedGrouping: boolean;
	private seriesGrouping: boolean;

	constructor(
		private hierarchyService: any) {
	}

	$onInit(): void { }

	configure = (): void => {
		this.configureCallback(
			this.grouping, KeyMetricListTypes.GROUPING, this.getAdditionalConfigurationOptions());
	}

	private getAdditionalConfigurationOptions = (): WidgetMetricConfigurationOptions[] => {
		const additionalConfigurationOptions: WidgetMetricConfigurationOptions[] = [];

		if (this.stackedGrouping && this.properties?.widgetType === WidgetType.BAR)
			additionalConfigurationOptions.push(WidgetMetricConfigurationOptions.STACKED_GROUPING);
		if (this.seriesGrouping && this.properties?.widgetType === WidgetType.LINE)
			additionalConfigurationOptions.push(WidgetMetricConfigurationOptions.SERIES_GROUPING);
		if (this.isPeerReportHierarchyGrouping())
			additionalConfigurationOptions.push(WidgetMetricConfigurationOptions.PEER_HIERARCHY_GROUPING);
		if (this.isPrimaryMapWidgetGrouping())
			additionalConfigurationOptions.push(WidgetMetricConfigurationOptions.MAP_WIDGET_GROUPING);

		return additionalConfigurationOptions;
	}

	private isPeerReportHierarchyGrouping = (): boolean => {
		return this.isPeerReport() && this.isHierarchyModelGrouping();
	}

	isPeerReport = (): boolean => {
		return this.hierarchyService.isPeerReport(this.personalization, this.properties);
	}

	private isHierarchyModelGrouping = (): boolean => {
		return AnalyticMetricTypes.isHierarchyModel(this.grouping);
	}

	private isPrimaryMapWidgetGrouping = (): boolean => {
		return this.properties?.widgetType === WidgetType.MAP
			&& this.properties.selectedAttributes[0].identifier === this.grouping.identifier;
	}

}

app.component('groupingConfigurerCog', {
	controller: GroupingConfigurerCogController,
	templateUrl: 'partials/components/grouping-configurer-cog-component.html',
	bindings: {
		properties: '<',
		visualProperties: '<',
		personalization: '<',
		grouping: '=',
		configureCallback: '<',
		additionalClass: '@',
		stackedGrouping: '<',
		seriesGrouping: '<'
	}
});
