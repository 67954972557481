import { NgModule } from '@angular/core';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { DashboardActionsModule } from '@app/modules/dashboard-actions/dashboard-actions.module';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { VersionsHeaderComponent } from '@app/modules/dashboard/dashboard-versions/versions-header.component';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { MetadataPreloaderService } from '@app/modules/dashboard/metadata-preloader.service';
import { DashboardUnsavedChangesService } from '@app/modules/dashboard/services/dashboard-unsaved-changes.service';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { SharedModule } from '@app/shared/shared.module';
import { MentionModule } from 'angular-mentions';
import { ConcurrentEditAlertComponent } from './concurrent-edit-alert/concurrent-edit-alert.component';
import { DashboardRunHelperService } from './dashboard-run-helper.service';
import { DashboardRunService } from './dashboard-run.service';
import { DashboardVersionsComponent } from './dashboard-versions/dashboard-versions.component';
import { DashboardViewService } from './dashboard-view/dashboard-view.service';
import { FloatableWidgetComponent } from './floatable-widget/floatable-widget.component';
import { FullscreenAutorefreshPanelComponent } from './fullscreen-autorefresh-panel/fullscreen-autorefresh-panel.component';
import { PdfHeaderComponent } from './pdf-header/pdf-header.component';
import { PreviewAsBarComponent } from './preview-as-bar.component';
import { ScheduleDistributionService } from './schedule/schedule-distribution.service';
import { SchedulePanelComponent } from './schedule/schedule-panel/schedule-panel.component';
import { ScheduleUtilsService } from './schedule/schedule-utils.service';
import { ScheduleWizardComponent } from './schedule/schedule-wizard/schedule-wizard.component';
import { DashboardComponentsApiService } from './services/assets/dashboard-components-api.service';
import { DashboardComponentsSharingUtil } from './services/assets/dashboard-components-sharing-utils';
import { DashboardWidgetsProjectsProvider } from './services/assets/dashboard-widgets-projects-provider.service';
import { DashboardHistoryApiService } from './services/dashboard-history-api-service/dashboard-history-api-service';
import { RecentDateService } from './services/recent-date-service/recent-date.service';
import { BookEditorUpgrade } from '@app/modules/dashboard/tabs/book-editor-upgrade.directive';
import { DashboardFiltersPanelComponent } from './dashboard-filters-panel/dashboard-filters-panel.component';
import { DashboardViewFiltersComponent } from './dashboard-view-filters/dashboard-view-filters.component';
import { DashboardFiltersEditUpgrade } from '@app/modules/dashboard/dashboard-filters-edit-upgrade.directive';
import { DashboardScheduleService } from './services/scheduling/dashboard-schedule.service';
import { TemplateModalComponent } from '@app/modules/dashboard/template-modal/template-modal.component';
import { DashboardChangeOwnerDialogComponent } from './dashboard-change-owner-dialog/dashboard-change-owner-dialog.component';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { DashboardPropsModule } from '@app/modules/dashboard/dashboard-props/dashboard-props.module';
import { WidgetVisibilityMonitorService } from './widget-visibility-monitor.service';
import { AppliedDashboardFiltersListComponent } from './applied-dashboard-filters-list/applied-dashboard-filters-list.component';
import {
	FullScreenAutoRefreshService
} from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh.service';
import {
	FullScreenAutoRefreshStoreService
} from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh-store.service';
import { WidgetDashboardCommonFiltersProcessorService } from './dashboard-filter-processors/widget-dashboard-common-filters-processor.service';
import { WidgetDashboardPersonalizationProcessorService } from './dashboard-filter-processors/widget-dashboard-personalization-processor.service';
import { WidgetDashboardTextFilterProcessorService } from './widget-dashboard-text-filter-processor.service';
import { WidgetTemplatesService } from '@app/modules/dashboard/widget-templates.service';
import { WidgetDrillableDashboardFilterProcessorService } from './dashboard-filter-processors/widget-drillable-dashboard-filter-processor.service';
import { WidgetSavedDashboardFiltersProcessorService } from './dashboard-filter-processors/widget-saved-dashboard-filters-processor.service';
import { WidgetDashboardDateFilterProcessorService } from './dashboard-filter-processors/widget-dashboard-date-filter-processor.service';
import { WidgetModelGroupingFiltersService } from './dashboard-filter-processors/widget-model-grouping-filters.service';
import { DashboardStateService } from '@app/modules/dashboard/services/dashboard-state.service';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		MentionModule,
		DashboardActionsModule,
		I18nModule,
		DashboardPropsModule,
	],
	exports: [
		VersionsHeaderComponent,
		FullscreenAutorefreshPanelComponent,
		BookEditorUpgrade,
		PreviewAsBarComponent,
		PdfHeaderComponent,
		DashboardFiltersPanelComponent,
		DashboardViewFiltersComponent,
		DashboardFiltersEditUpgrade,
		AppliedDashboardFiltersListComponent,
	],
	declarations: [
		VersionsHeaderComponent,
		FullscreenAutorefreshPanelComponent,
		TemplateModalComponent,
		BookEditorUpgrade,
		SchedulePanelComponent,
		ScheduleWizardComponent,
		DashboardVersionsComponent,
		FloatableWidgetComponent,
		PdfHeaderComponent,
		ConcurrentEditAlertComponent,
		PreviewAsBarComponent,
		PdfHeaderComponent,
		DashboardFiltersPanelComponent,
		DashboardViewFiltersComponent,
		DashboardFiltersEditUpgrade,
		DashboardChangeOwnerDialogComponent,
		AppliedDashboardFiltersListComponent,
	],
	providers: [
		DashboardAccessService,
		DashboardRunHelperService,
		DashboardRunService,
		DashboardViewService,
		MetadataPreloaderService,
		ScheduleDistributionService,
		ScheduleUtilsService,
		DomainsProcessingService,
		DashboardHistoryApiService,
		RecentDateService,
		DashboardComponentsApiService,
		DashboardComponentsSharingUtil,
		DashboardWidgetsProjectsProvider,
		VersionsHeaderService,
		DashboardUnsavedChangesService,
		DashboardScheduleService,
		DashboardStateService,
		WidgetVisibilityMonitorService,
		FullScreenAutoRefreshService,
		FullScreenAutoRefreshStoreService,
		WidgetDrillableDashboardFilterProcessorService,
		WidgetDashboardTextFilterProcessorService,
		WidgetTemplatesService,
		WidgetDashboardCommonFiltersProcessorService,
		WidgetDashboardPersonalizationProcessorService,
		WidgetSavedDashboardFiltersProcessorService,
		WidgetDashboardDateFilterProcessorService,
		WidgetModelGroupingFiltersService,
	]
})

export class DashboardModule { }
