import * as _ from 'underscore';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';

export default class PlotLineBuilder {

	static getLineStyle(type: PlotLineType, width: number): string {
		return this.getStyleArray(type).map(units => units * width).join(', ');
	}

	private static getStyleArray(type: PlotLineType): number[] {
		switch (type) {
			case PlotLineType.NoLine: return [0, 1];
			case PlotLineType.Solid: return [1, 0];
			case PlotLineType.ShortDash: return [3, 1];
			case PlotLineType.ShortDot: return [1, 1];
			case PlotLineType.ShortDashDot: return [3, 1, 1, 1];
			case PlotLineType.ShortDashDotDot: return [3, 1, 1, 1, 1, 1];
			case PlotLineType.Dot: return [1, 3];
			case PlotLineType.Dash: return [4, 3];
			case PlotLineType.LongDash: return [8, 3];
			case PlotLineType.DashDot: return [4, 3, 1, 3];
			case PlotLineType.LongDashDot: return [8, 3, 1, 3];
			case PlotLineType.LongDashDotDot: return [8, 3, 1, 3, 1, 3];
			default: throw new Error('Unsupported line style');
		}
	}
}
