import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import MobileAppSettingsTableController
	from '@cxstudio/mobile/mobile-app-settings-table.component';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import MobileAppSettingsListModel
	from '@cxstudio/mobile/mobile-app-settings-list-model.interface';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export default class MobileSettingsGridDefinition implements IGridDefinition<MobileAppSettingsTableController> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		private readonly gridUtils: GridUtilsService
	) {}

	init = (_gridMode: GridMode, controller: MobileAppSettingsTableController): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		},
			{
				id: 'spacer',
				sortable: false,
				minWidth: 16,
				width: 16,
				resizable: false,
				cssClass: 'no-border-if-folder',
				attributes: {
					'aria-hidden': true
				}
			},
			{
				id: 'enabled',
				name: this.locale.getString('common.enabled'),
				field: 'enabled',
				sortable: true,
				minWidth: 80,
				width: 80,
				searchable: false,
				resizable: false,
				formatter: this.gridFormatterService.buildToggleSwitchFormatter(
					'name',
					(item: MobileAppSettingsListModel) => !controller.canSwitchEnabledToggle(item),
					(item: MobileAppSettingsListModel) => controller.getDisabledSettingsMessage(item)),
				cssClass: 'text-center',
				headerCssClass: 'text-center'
			},
			{
				id: 'name',
				name: this.locale.getString('common.name'),
				field: 'name',
				sortable: true,
				minWidth: 280,
				width: 450,
				cssClass: 'cell-title br-mobile-settings-name',
				searchable: true,
				formatter: this.getMobileSettingsNameFormatter()
			},
			{
				id: 'owner',
				name: this.locale.getString('common.owner'),
				field: 'submitterEmail',
				sortable: true,
				minWidth: 180,
				width: 450,
				cssClass: 'cell-title br-mobile-settings-owner',
				searchable: true,
				formatter: this.gridFormatterService.getAuthorFormatter()
			},
			{
				id: 'created',
				name: this.locale.getString('dashboard.modifiedDate'),
				field: 'updatedDate',
				sortable: true,
				optional: true,
				minWidth: 100,
				width: 280,
				cssClass: 'cell-date',
				formatter: this.gridFormatterService.DateFormatter
			},
			{
				id: 'sharingStatus',
				name: this.locale.getString('common.status'),
				field: 'sharingStatus',
				sortable: true,
				optional: true,
				minWidth: 60,
				width: 150,
				formatter: this.gridFormatterService.SimpleStatusFormatter,
				cssClass: 'cell-status text-center',
				headerCssClass: 'text-center'
			}];

		return Promise.resolve(columns);
	}

	private getMobileSettingsNameFormatter = () => {
		return (_row, _cell, name: string, _columnDef, mobileSettings: MobileAppSettingsListModel): string => {
			name = this.gridUtils.replaceHTML(name);

			if (mobileSettings.internal) {
				name = `${name} (${this.locale.getString('mobile.internalApp')})`;
			}

			return `<span class='br-mobileSettings br-item-name'>${name}</span>`;
		};
	}


}

app.service('mobileSettingsGridDefinition', downgradeInjectable(MobileSettingsGridDefinition));
