import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core/cx-http.service';
import { ObjectUtils } from '@app/util/object-utils';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { UrlService } from '@cxstudio/common/url-service.service';
import en_US from '@locales/i18n/en-US.json';
import { SupportedLocaleGenerated } from '@locales/supported-locales';
import SupportedLocalesHashes from '@locales/supported-locales.json';
import { LocalStorageService } from 'angular-2-local-storage';
import { CookieService } from 'ngx-cookie-service';

export const KEY_NOT_FOUND = '%%KEY_NOT_FOUND%%';

@Injectable({
	providedIn: 'root'
})
export class LanguageLoaderService {

	private readonly LANGUAGE_KEY = 'user-language';
	private readonly LEGACY_COOKIE = 'pref_locale';
	// need to generate english and import it
	private translations: {[key: string]: string};
	private loaded = false;

	constructor(
		private localStorageService: LocalStorageService,
		private cookieService: CookieService,
		private cxHttp: CxHttpService,
		@Inject(Window) private window: Window,
		@Inject('urlService') private urlService: UrlService,
	) {
		this.translations = ObjectUtils.copy(en_US);
	}

	initialize(): PromiseLike<any> {
		if (this.loaded) {
			return Promise.resolve();
		}
		let currentLanguage = this.getCurrentLanguage();
		if (!currentLanguage) {
			currentLanguage = this.determineLanguage();
			this.localStorageService.set(this.LANGUAGE_KEY, currentLanguage);
		}
		if (currentLanguage !== SupportedLocaleGenerated.en_US) {
			return this.loadLanguage(currentLanguage).then(() => this.loaded = true);
		} else {
			this.loaded = true;
			return Promise.resolve();
		}
	}

	private determineLanguage(): SupportedLocaleGenerated {
		const supportedLanguages = Object.values(SupportedLocaleGenerated);
		const legacyCookie = this.cookieService.get(this.LEGACY_COOKIE);
		if (legacyCookie && supportedLanguages.includes(legacyCookie as any)) {
			this.cookieService.delete(this.LEGACY_COOKIE);
			return legacyCookie as SupportedLocaleGenerated;
		} else {
			const browserLanguage = this.window.navigator.language;
			if (supportedLanguages.includes(browserLanguage as any)) {
				return browserLanguage as SupportedLocaleGenerated;
			} else {
				const partialMatch = supportedLanguages.find(lang => lang.contains(browserLanguage));
				return partialMatch ?? SupportedLocaleGenerated.en_US;
			}
		}
	}

	private loadLanguage(lang: SupportedLocaleGenerated): PromiseLike<void> {
		const hash = _.findWhere(Object.values(SupportedLocalesHashes), {code: lang})?.hash;
		return this.cxHttp.rawGet(this.urlService.getInternalFile(`i18n/${lang}.json?v=${hash}`),
			{local: true, cache: CacheOptions.CACHED}).then(
				response => {
					_.extend(this.translations, response.data);
				},
				errorResponse => {
					console.warn(`Cannot load language: ${lang}, status: ${errorResponse.status}`, errorResponse);
				}
			);
	}

	ensureLanguage(lang: SupportedLocaleGenerated): void {
		if (this.getCurrentLanguage() !== lang) {
			this.localStorageService.set(this.LANGUAGE_KEY, lang);
			this.window.location.reload();
		}
	}

	getCurrentLanguage(): SupportedLocaleGenerated {
		return this.localStorageService.get(this.LANGUAGE_KEY);
	}

	getTranslation(key: string): string {
		if (!this.loaded) {
			console.warn('Translation is used before initialization: ' + key);
		}
		if (this.translations.hasOwnProperty(key)) {
			return this.translations[key];
		} else {
			return KEY_NOT_FOUND;
		}
	}
}

app.service('languageLoader', downgradeInjectable(LanguageLoaderService));
