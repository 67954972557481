import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { BaseActionsService } from '../actions/base-actions-service';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';

@Injectable()
export class ModelActionsService extends BaseActionsService {

	private hiddenType: HiddenItemType;
	private nameField: string;

	constructor(
		@Inject('security') readonly security: Security,
		@Inject('cachedHttpService') private readonly cachedHttpService: CachedHttpService,
		@Inject('securityApiService') private readonly securityApiService: SecurityApiService
	) {
		super(security);
		this.hiddenType = HiddenItemType.MODELS;
		this.nameField = 'name';
	}

	toggleHide(item: any, props: ProjectIdentifier): void {
		item.hide = !item.hide;
		let key = this.getAnalyzeObjectKey(item, props);
		this.securityApiService.hideObjectForUser(this.hiddenType, item.hide, key, item[this.nameField]);
		let tagFunc = item.hide ? TaggingHelper.tag : TaggingHelper.untag;
		tagFunc(item, TaggingHelper.tags.HIDDEN);
		this.security.getHiddenObject(this.hiddenType)[key] = !!item.hide;
		this.cachedHttpService.cache(Caches.MODELS).invalidate();
	}
}

app.service('modelActionsService', downgradeInjectable(ModelActionsService));
