<div [ngBusy]="busy">
	<div class="emails-filter row">
		<div class="form-group d-flex">
			<div class="col-sm-2">
				<label>{{'common.masterAccount'|i18n}}</label>
				<input type="text" class="form-control"
					editable="false"
					[(ngModel)]="search.masterAccount"
					[ngbTypeahead]="masterAccountSuggestions"
					[inputFormatter]="masterAccountSuggestionsFormatter"
					[resultFormatter]="masterAccountSuggestionsFormatter">
			</div>
			<div class="col-sm-2">
				<label>{{'administration.recipients'|i18n}}</label>
				<div><input type="text" class="form-control" [(ngModel)]="search.recipient"></div>
			</div>
			<div class="col-sm-2">
				<label>{{'administration.subject'|i18n}}</label>
				<div><input type="text" class="form-control" [(ngModel)]="search.subject"></div>
			</div>
			<div class="col-sm-2">
				<label>{{'administration.status'|i18n}}</label>
				<div>
					<simple-dropdown
						[(value)]="search.status"
						[options]="statuses"
						displayField="label"
						selectPrompt="{{'common.all'|i18n}}"
						allowClear="true">
					</simple-dropdown>
				</div>
			</div>
			<div class="col-sm-1 d-flex align-items-end">
				<button type="button" class="btn btn-primary"
					(click)="reload()" [i18n]="'common.filter'"></button>
			</div>
			<div *ngIf="isDeletionSupported()" class="col-sm-1 d-flex align-items-end">
				<button type="button" class="btn btn-secondary"
					(click)="deleteQueuedEmails()" [i18n]="'common.delete'"></button>
			</div>
		</div>
	</div>

	<div class="row col-sm-12">
		<formatted-table
			[rows]="rows"
			[columns]="columns"
		></formatted-table>
	</div>

	<pagination
		class="emails-pagination row col-sm-12"
		[pagination]="pagination"
		(paginationChange)="pageChanged($event)"
	></pagination>
</div>
