import * as _ from 'underscore';
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy,
	EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { ScorecardInDocView, ScorecardEmitterParameter } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { IntelligentScoringPreference } from './explorer-scorecard-sort.component';
import { ExplorerScorecardDisplayPreference } from './scorecards/explorer-scorecard-display-preference.service';
import { Subscription } from 'rxjs';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { AmplitudeDocumentType } from '../document-type-utils.class';

@Component({
	selector: 'explorer-scorecards-section',
	template: `
<div *ngFor="let scorecard of scorecards">
	<explorer-scorecard [scorecard]="scorecard"
		(onCollapse)="onCollapse(scorecard, $event)"
		(onFavoriteToggle)="onFavoriteToggle(scorecard)"
		(onTopicClick)="topicClick($event)"
		[expanded]="scorecard.expanded"
		[favorite]="scorecard.id == favoriteScorecard"
		[displayPreference]="displayPreference"
		(onDisplayPreferenceChange)="onDisplayPreferenceChange($event)"
		[rebuttalMode]="rebuttalMode"
		[isDocExplorer]="isDocExplorer"
		[selectedDocument]="selectedDocument"
		[documentType]="documentType"
		[projectSelection]="projectSelection">
	</explorer-scorecard>
</div>
`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplorerScorecardsSectionComponent implements OnInit, OnChanges, OnDestroy {
	@Input() scorecards: ScorecardInDocView[];
	@Input() selectedScorecard: number;
	@Input() favoriteScorecard: number;
	@Input() selectedDocument: number;
	@Input() rebuttalMode: boolean;
	@Input() projectSelection: ProjectIdentifier;
	@Input() isDocExplorer: boolean;
	@Input() documentType: AmplitudeDocumentType;
	@Output() onTopicClick = new EventEmitter<ScorecardEmitterParameter>();
	@Output() onExpandScorecard = new EventEmitter<ScorecardInDocView>();
	@Output() onFavoriteScorecard = new EventEmitter<ScorecardInDocView>();

	displayPreference: IntelligentScoringPreference;
	subscription: Subscription;

	constructor(
		private ref: ChangeDetectorRef,
		private explorerScorecardDisplayPreference: ExplorerScorecardDisplayPreference
	) {
	}

	ngOnInit(): void {
		this.initCollapsedState();
		this.subscription = this.explorerScorecardDisplayPreference.preference$.subscribe(displayPreference => {
			this.displayPreference = Object.assign({}, displayPreference);
			this.ref.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedScorecard && changes.selectedScorecard.previousValue !== changes.selectedScorecard.currentValue) {
			this.initCollapsedState();
		}

		if (changes.scorecards && this.selectedScorecard) {
			this.initCollapsedState();
		}
	}

	private initCollapsedState(): void {
		if (this.selectedScorecard)
			_.forEach(this.scorecards, (scorecard) => scorecard.expanded = scorecard.id === this.selectedScorecard);
	}

	onCollapse(scorecard, expanded): void {
		if (!expanded) {
			scorecard.expanded = false;
		} else {
			_.forEach(this.scorecards, (item) => item.expanded = false);
			scorecard.expanded = true;
			this.onExpandScorecard.emit(scorecard);
		}
	}

	onFavoriteToggle(scorecard): void {
		this.onFavoriteScorecard.emit(scorecard);
	}

	topicClick(node: ScorecardEmitterParameter): void {
		this.onTopicClick.emit(node);
	}

	onDisplayPreferenceChange(displayPreference: IntelligentScoringPreference) {
		this.explorerScorecardDisplayPreference.setValue(displayPreference);
	}
}
