import { StudioAsset } from '@cxstudio/asset-management/studio-asset';

import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';
import { DashboardWidgetsProjects } from './dashboard-widgets-projects.class';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';


@Injectable()
export class DashboardWidgetsProjectsProvider {

	constructor(
		private dashboardComponentsApiService: DashboardComponentsApiService,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService,
	) {}

	getInstance(dashboard: StudioAsset): DashboardWidgetsProjects {
		return new DashboardWidgetsProjects(dashboard,
			this.dashboardComponentsApiService,
			this.contentProviderOptionsService);
	}

}

app.service('dashboardWidgetsProjectsProvider', downgradeInjectable(DashboardWidgetsProjectsProvider));
