<div class="page-tab-content upgrade-management">
	<div class="panel panel-default" [ngBusy]="promises.loadingState">
		<div class="panel-heading">{{'upgrade.applicationUpgrade'|i18n}}</div>

		<div class="panel-body">
			<div class="col-md-12 mb-16">
				<label *ngIf="!upgrade.firstUpgrade && !upgrade.upgradingNow">
					{{'upgrade.currentUpgradeVersion'|i18n}} : {{ upgrade.componentSearchVersion }}</label>
				<label *ngIf="!upgrade.firstUpgrade && upgrade.upgradingNow">
					{{'upgrade.upgradingToVersion'|i18n}} : {{ upgrade.componentSearchVersion }}</label>
				<label *ngIf="upgrade.firstUpgrade">
					{{'upgrade.upgradeFromVersion'|i18n}} : </label>
				<input *ngIf="upgrade.firstUpgrade"
					[(ngModel)]="upgrade.componentSearchVersion"
					(ngModelChange)="cleanVersionData()"
					size="10"
					type="search"
					class="ml-8"/>

				<button class="btn btn-secondary ml-8 d-flex-inline align-items-center"
					*ngIf="upgrade.firstUpgrade"
					(click)="loadComponents()"
					[disabled]="!ui.searchButtonEnabled">
					<span class="q-icon q-icon-refresh"></span>
					<span>&nbsp;{{'upgrade.searchUpgradeComponents'|i18n}}</span>
				</button>

				<!-- Enabled state -->
				<button class="btn btn-primary"
					*ngIf="canPerformUpgrade()"
					(click)="performUpgrade()">
					<span class="q-icon q-icon-cog"></span>
					<span>&nbsp;{{'upgrade.performUpgrade'|i18n}}</span>
				</button>

				<!-- Disabled state -->
				<button class="btn btn-primary"
					*ngIf="isDisabledState()"
					(click)="reloadPage()">
					<span class="q-icon q-icon-spinner"></span>
					<span>&nbsp;{{'upgrade.upgradeInProcess'|i18n}}</span>
				</button>

				<!-- Upgraded state -->
				<span class="alert alert-sm alert-success" *ngIf="isUpgradedState()">
					{{'upgrade.fullyUpgraded'|i18n}}&nbsp;<span class="q-icon q-icon-check" aria-hidden="true"></span> </span>
			</div>

			<div class="col-md-12" *ngIf="hasComponents()">
				<table
					attr.aria-label="{{'administration.upgradeComponentsTable'|i18n}}"
					class="table table-bordered">
					<thead>
						<tr>
							<th>{{'upgrade.component'|i18n}}</th>
							<th>{{'upgrade.owner'|i18n}}</th>
							<th>{{'upgrade.version'|i18n}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let component of upgrade.components">
							<td class="v-middle">{{component.name}}
								<strong [hidden]="!component.optional">({{'upgrade.optional'|i18n}})</strong>
								<strong [hidden]="!component.forced" class="text-success">({{'upgrade.optionalComponentWillBeRun'|i18n}})</strong>
								<strong [hidden]="!component.skipped" class="text-danger">({{'upgrade.optionalComponentWillBeSkipped'|i18n}})</strong>
								<button class="btn btn-sm btn-success ml-4"
									*ngIf="component.optional && !component.forced"
									(click)="forceComponent(component)">
									{{'upgrade.runOptionalComponent'|i18n}}
								</button>
								<button class="btn btn-sm btn-danger ml-4"
									*ngIf="component.optional && !component.skipped"
									(click)="skipComponent(component)">
									{{'upgrade.skipOptionalComponent'|i18n}}
								</button>
								<button class="btn btn-sm ml-4"
									*ngIf="component.optional && (component.skipped || component.forced)"
									(click)="skipComponentForNow(component)">
									{{'upgrade.skipOptionalComponentForNow'|i18n}}
								</button>
							</td>
							<td>{{component.owner}}</td>
							<td>{{component.version}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- Upgrade sessions table -->
	<div class="panel panel-default" *ngIf="hasSessions()">
		<div class="panel-heading">{{'upgrade.upgradeSessions'|i18n}}</div>
		<div class="panel-body">
			<upgrade-session *ngFor="let session of upgrade.sessions"
				[session]="session"
				[upgradingNow]="upgrade.upgradingNow"
				(stateChange)="reloadPage()"
			></upgrade-session>
		</div>
	</div>
</div>
