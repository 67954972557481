<modal-header
	(click)="cancel()"
	[modalTitle]="'widget.recolorManagement'| i18n">
</modal-header>
<div class="modal-body">
	<label class="br-dialog-message" *ngIf="recolors.isEmpty()">{{'widget.noRecolors'|i18n}}</label>
	<div class="form-group no-split mb-16" *ngIf="!recolors.isEmpty()">
		<div class="row mb-8 d-flex align-items-center">
			<b class="col-sm-2 text-ellipsis" *ngIf="isBarOrLine()">{{'widget.axis'|i18n}}</b>
			<b class="col-sm-3 text-ellipsis">{{'widget.grouping'|i18n}}</b>
			<b class="col-sm-2 text-ellipsis">{{'widget.group'|i18n}}</b>
			<div class="col-sm-3 justify-between">
				<b>{{'common.color'|i18n}}</b>
			</div>
			<div class="col-sm-2">
				<button type="button" class="btn btn-link" *ngIf="recolors.length" (click)="removeAll()">{{'administration.removeAll'|i18n}}</button>
			</div>
		</div>
		<div class="row align-items-center pv-8" *ngFor="let item of recolors; let $index=index">
			<label class="col-sm-2 mb-0 text-ellipsis" *ngIf="isBarOrLine()">{{getColorType(item)}}</label>
			<label class="col-sm-3 mb-0 text-ellipsis">{{getGroupName(item)}}</label>
			<label class="col-sm-2 mb-0 text-ellipsis">{{getValue(item)}}</label>
			<div class="col-sm-3 align-items-center justify-between">
				<color-input
					class="case-viz-color mr-8"
					[(ngModel)]="item.color">
				</color-input>
			</div>
			<div class="col-sm-2">
				<button type="button" class="q-icon-trash btn btn-link btn-icon" (click)="remove($index)" title="{{'administration.remove'|i18n}}"></button>
			</div>
		</div>
	</div>
</div>
<save-modal-footer
	[saveText]="'common.save'|i18n"
	(save)="save()"
	(cancel)="cancel()">
</save-modal-footer>
