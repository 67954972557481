import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { DualAxisTypes } from '@cxstudio/reports/providers/cb/definitions/dual-axis-types';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';

export class DualDefinitionHelper {

	static getPopLevel(options: VisualProperties): number {
		if (this.isPop(options))
			return 1;
		if (options.stackedGroup === StandardMetricName.POP)
			return this.hasSecondaryGroup(options) ? 2 : 1;
		return undefined;
	}

	static isStacked(options: VisualProperties, utils: WidgetUtils): boolean {
		if (options.stackedGroup && options.stackedGroup !== '') {
			return true;
		}
		if (utils.widgetType === WidgetType.LINE && options.attributeSelections.secondaryGroup) {
			return options.attributeSelections.secondaryGroup.stackType
				&& options.attributeSelections.secondaryGroup.stackType !== StackType.NONE;

		}
		return false;
	}

	static isPop(options: VisualProperties): boolean {
		return options.secondaryGroup === StandardMetricName.POP;
	}

	static hasPop(options: VisualProperties): boolean {
		return options.secondaryGroup === StandardMetricName.POP || options.stackedGroup === StandardMetricName.POP;
	}

	static hasSecondaryGroup(options: VisualProperties, axisPosition?: PlotLineAxis): boolean {
		return options.secondaryGroup &&
			(axisPosition !== PlotLineAxis.secondary || options.secondaryGroup !== KeyMetricListTypes.CALCULATION_SERIES);
	}

	static getGroupings(options: VisualProperties): ReportGrouping[] {
		let selections = options.attributeSelections;
		return GroupIdentifierHelper.getGroupings([selections.primaryGroup, selections.secondaryGroup,
			selections.stackedGroup]);
	}

	static isTimePrimaryGrouping(options: VisualProperties): boolean {
		let selections = options.attributeSelections;
		let primaryGroup = selections && selections.primaryGroup;
		return AnalyticMetricTypes.isTime(primaryGroup);
	}

	static isCalculationSeries(options: VisualProperties, widgetType: WidgetType): boolean {
		return widgetType === WidgetType.LINE
			&& (DualDefinitionHelper.isLineChart(options) || DualAxisTypes.isBubbleVisualization(options))
			&& options.secondaryGroup === KeyMetricListTypes.CALCULATION_SERIES;
	}

	static isLineChart(options: VisualProperties): boolean {
		return options.subChartType === ChartType.SPLINE;
	}

	static isZeroBased(metricName: string): boolean {
		return metricName?.toLowerCase().indexOf('volume') >= 0;
	}
}
