import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';

import * as _ from 'underscore';
import { HierarchyLevel } from '@app/modules/hierarchy/hierarchy-level';
import { ISearchableHierarchyItem } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ObjectUtils } from '@app/util/object-utils';


export class HierarchyService {

	constructor() {}

	formatPathForDisplay = (path, separator?, matchRegex?): string => {
		separator = separator || ' / ';
		matchRegex = matchRegex || /\s*-->\s*/g;
		return path.replace(matchRegex, separator);
	}

	getAncestorIdsFromNode = (node): number[] => {
		if (!node.ancestors || node.ancestors.length === 0) {
			return [node.id];
		}
		if (node.children && node.children.length > 0) {
			let ids = [node.id];
			_.each(node.ancestors, (ancestor: any) => ids.push(ancestor.id));
			return ids;
		} else {
			return _.map(node.ancestors, 'id');
		}
	}

	private checkBranch(rootLevel, branch): any {
		rootLevel++;

		if (branch.children && branch.children.length) {
			let maximumBranchDepth = rootLevel;
			_.each(branch.children, child => {
				let branchDepth = this.checkBranch(rootLevel, child);
				if (branchDepth > maximumBranchDepth) {
					maximumBranchDepth = branchDepth;
				}
			});
			branch.depth = maximumBranchDepth;
			return maximumBranchDepth;
		}
		branch.depth = rootLevel;
		return rootLevel;
	}

	getDepth = (tree, includeRootInCount = false): number => {
		let maximumDepth = this.checkBranch(0, tree);
		return (includeRootInCount) ? maximumDepth : maximumDepth - 1;
	}

	applyRecursively = (root: any[], applyFunction, result) => {
		_.each(root, item => {
			applyFunction(item, result);

			if (item.children && item.children.length) {
				this.applyRecursively(item.children, applyFunction, result);
			}
		});
		return result;
	}

	isPeerReport = (widgetPersonalization, widgetProperties: WidgetProperties): boolean => {
		if (!widgetPersonalization || !widgetProperties) return false;

		let hierarchyGroupings = this.findHierarchyGroupings(widgetProperties);
		return hierarchyGroupings.length === 1
			&& this.isPeerReportGrouping(widgetPersonalization, hierarchyGroupings[0]);
	}

	isPeerReportGrouping = (widgetPersonalization, grouping): boolean => {
		if (!widgetPersonalization || !grouping || !widgetPersonalization.isHierarchyEnabled())
			return false;

		let dashboardHierarchyId = widgetPersonalization.getHierarchyId();
		return dashboardHierarchyId === parseInt(grouping.name, 10);
	}

	isInheritedReport = (props: WidgetProperties): boolean => {
		let hierarchyGroupings = this.findHierarchyGroupings(props);
		return hierarchyGroupings.length === 1
			&& hierarchyGroupings[0].peerReportType === PeerReportType.INHERITED_REPORT;
	}

	findSingleHierarchyGrouping = (widgetProperties: WidgetProperties) => {
		let groups = this.findHierarchyGroupings(widgetProperties);
		return isEmpty(groups) ? null : groups[0];
	}

	private findHierarchyGroupings(widgetProperties: WidgetProperties): any[] {
		return widgetProperties.selectedAttributes
			&& widgetProperties.selectedAttributes.filter(AnalyticMetricTypes.isHierarchyModel)
			|| [];
	}

	getOrgHierarchyLevelTree = (hierarchyLevels: HierarchyLevel[], level = 0): ISearchableHierarchyItem[] => {
		if (level < hierarchyLevels.length) {
			let hierarchyLevel = hierarchyLevels[level];
			return [{
				name: hierarchyLevel.type,
				displayName: hierarchyLevel.type,
				level: hierarchyLevel.level,
				_expanded: true,
				children: this.getOrgHierarchyLevelTree(hierarchyLevels, level + 1)
			}];
		}
	}

	private isDescendantOfCurrent = (checkPath, currentPath): boolean =>
		!!_.find(currentPath.descendants, (node: any) => node.id === checkPath.id)

	private hasAccessToNode = (node, explicitAccessNodes): boolean => {
		return _.findWhere(explicitAccessNodes, {id: node.id})
			|| _.some(explicitAccessNodes, explicitNode => this.isDescendantOfCurrent(node, explicitNode));
	}

	private checkNodeEnablement = (node, currentPath, explicitAccessNodes): void => {
		if (!(node.id === currentPath.id || this.isDescendantOfCurrent(node, currentPath) || this.hasAccessToNode(node, explicitAccessNodes)))
			node.disabled = true;
	}

	getFullHierarchyToPath = (rawHierarchy, currentPath, explicitAccessNodes?): IHierarchyNode[] => {
		rawHierarchy.forEach(child => this.checkNodeEnablement(child, currentPath, explicitAccessNodes || []));
		let returnHierarchy = _.uniq(ObjectUtils.copy(rawHierarchy), false, (node: any) => node.id);
		let requiresNesting: boolean = true;
		let loop: number = 0;

		while (requiresNesting && returnHierarchy.length > 0 && loop < 10) {
			requiresNesting = false;
			loop++;

			let maxDepth: number = _.max(returnHierarchy, (node: any) => node.level).level;
			if (maxDepth > 1) {
				requiresNesting = true;
				let parents = _.filter(returnHierarchy, (node: any) => node.level === (maxDepth - 1));
				parents.forEach((parent) => {
					parent.children.forEach((child) => {
						let childIndex: number = _.findIndex(returnHierarchy, (node: any) => node.id === child.id);
						child = $.extend(child, returnHierarchy[childIndex]);
						returnHierarchy.removeAt(childIndex);
					});
				});
			}
		}

		return [{ hierarchyId: rawHierarchy[0].hierarchyId, id: 0, children: returnHierarchy } as IHierarchyNode];
	}
}

app.service('hierarchyService', HierarchyService);
