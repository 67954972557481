import { Component, ChangeDetectionStrategy, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { PromiseUtils } from '@app/util/promise-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import Authorization from '@cxstudio/auth/authorization-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { DashboardTemplateTypes } from '@cxstudio/dashboard-templates/dashboard-template-types';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { DashboardTemplatesListActions } from './dashboard-templates-list-actions.service';
import { DashboardTemplatesContextMenu } from './dashboard-templates-context-menu.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { TemplateTransferModalComponent } from '@app/modules/unified-templates/template-transfer-modal/template-transfer-modal.component';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

export class DashboardTemplate {
	id: number;
	name: string;
	type: string;
	description: string;
	createdDate: Date;
	modifiedDate: Date;
	ownerId: number;
	ownerName: string;
	publicStatus?: SharingStatus;
}

@Component({
	selector: 'dashboard-templates-management',
	templateUrl: './dashboard-templates-management.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DashboardTemplatesContextMenu, DashboardTemplatesListActions]
})
export class DashboardTemplatesManagementComponent extends SelfCleaningComponent implements OnInit {
	loading: Promise<any>;
	searchText: string;
	templates: DashboardTemplate[];

	gridOptions: SlickgridOptions;
	gridType = GridTypes.DASHBOARD_TEMPLATES;

	changedItems: DashboardTemplate[];

	contextMenuUtils: DashboardTemplatesContextMenu;
	actionsService: DashboardTemplatesListActions;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private settingsContextMenu: DashboardTemplatesContextMenu,
		private settingsListActions: DashboardTemplatesListActions,
		private betaFeaturesService: BetaFeaturesService,
		private cxDialogService: CxDialogService,
		@Inject('security') private readonly security: Security,
		private readonly gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService
	) {
		super();
	}

	ngOnInit(): void {
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};

		this.contextMenuUtils = this.settingsContextMenu;
		this.actionsService = this.settingsListActions;

		this.addSubscription(this.actionsService.onChange.subscribe(this.refreshGrid));
		this.addSubscription(this.actionsService.onReload.subscribe(this.loadTemplates));

		this.loadTemplates();
	}

	private onItemClick(event, object: DashboardTemplate): void {
		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object,
				this.contextMenuUtils.getContextMenu(object, this), 'dashboard-templates', 360);
		}
	}

	private loadTemplates = (): void => {
		this.loading = PromiseUtils.wrap(this.dashboardTemplatesApiService.getTemplates()
			.then((response) => {
				_.each(response.data, (item: ITreeItem) => {
					if (item.type === DashboardTemplateTypes.CUSTOM)
						item.type = GridContext.DASHBOARD_TEMPLATE;
					if (this.gridUtils.isFolder(item))
						(item as IFolderItem)._collapsed = true;
				});
				this.templates = this.gridUtils.processItemsTree(response.data);
				this.refreshGrid();
			}));
		this.ref.detectChanges();
	}

	private refreshGrid = (updatedItems?: DashboardTemplate[]): void => {
		this.changedItems = updatedItems  || this.templates;
		this.ref.detectChanges();
	}

	canManageTemplates = (): boolean => this.authorization.hasManageTemplatePermission();

	addFolder = () => this.actionsService.createFolder(this.templates);

	canTransferTemplates = (): boolean => {
		return this.security.isAnyAdmin() && this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATES_IMPORT_EXPORT);
	}

	transferTemplates = () => {
		this.cxDialogService.openDialog(TemplateTransferModalComponent, {type: null}).result.finally(() => this.loadTemplates());
	}
}
