import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { DualAxisTypes } from '@cxstudio/reports/providers/cb/definitions/dual-axis-types';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { IConversionWidgetProperty } from '@cxstudio/reports/widgets/conversion-widget-property.class';
import { COLOR_TYPES, IReportWidgetContext, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export abstract class DualAxisWidget extends ReportWidget {

	private readonly POP_GROUPING_OPTIONS = [
		{name: 'popColor', default: 'lighten'},
		{name: 'customPopColor'}
	];
	protected readonly POP_GROUPING: IConversionWidgetProperty = {name: '_pop'};
	private readonly SECONDARY_COLOR_TYPES = {
		SECONDARY_COLOR: {color: 'secondaryColor', customColor: 'secondaryCustomColor'},
		SECONDARY_POINT_COLOR: {color: 'secondaryPointColor', customColor: 'secondaryPointCustomColor'},
		SECONDARY_POP_COLOR: {color: 'secondaryPopColor', customColor: 'secondaryCustomPopColor'}
	};
	private readonly VISUALIZATIONS_SPECIFIC = {
		COLUMN: {handler: context => this.initSecondaryColor(context)},
		BAR: {handler: context => this.initSecondaryColor(context)},
		SPLINE: {handler: context => this.initSecondaryLine(context)},
		BUBBLE: {handler: context => this.initSecondaryBubble(context)}
	};
	private CALCULATION_TYPES =  [
		{key: 'yAxis', handler: (calculation, context) => this.setPrimaryCalculation(calculation), index: 0},
		{key: 'secondaryYAxis', handler: (calculation, context) => this.initSecondaryAxis(calculation, context), index: 1},
		{key: 'color', handler: (calculation, context) => this.initColorCalcultionWithSelections(calculation)},
		{handler: (calculation, context) => this.initColorCalculation(calculation), index: 2},	// for scatter, we rely on index
		{key: 'sortBy', handler: (calculation, context) => this.initSortCalcultion(calculation, context)}
	];

	protected axisNames: IConversionWidgetProperty[] = [
		{name: 'y'}, {name: 'secondary'}
	];
	protected exceptionalGroupings: IConversionWidgetProperty[] = [this.POP_GROUPING];

	constructor(widgetType: WidgetType, protected metricConstants: MetricConstants) {
		super(widgetType);
	}

	protected initDualAxis(context: IReportWidgetContext): void {
		super.initFromContext(context);
		super.withFilters(context);

		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = [];
		this.widget.visualProperties.visualization = WidgetVisualization.DUAL;
		this.widget.visualProperties.attributeSelections = {};

		this.initCalculations(context);

		super.withDateRanges(context);

		super.withLegendOptions(context, [
			{name: 'showLabels', default: false},
			{name: 'showLegend', default: true}	//used with stacked grouping
		]);

		if (context.source.properties.widgetType === 'cb_an_scatter') {
			this.axisNames[1].sourceName = 'x';
		}

		let includeTimeReferences = false;
		if ((context.source.properties.widgetType === WidgetType.BAR || context.source.properties.widgetType === WidgetType.LINE)
			&& !_.isEmpty(context.source.visualProperties.timeReferenceLines)) {
			includeTimeReferences = true;
		}

		super.withAdvancedAxisOptions(context, this.axisNames, includeTimeReferences);
		this.initProperties([{name: 'caseVisualizations'}], context, 'visualProperties');
	}

	protected setPrimaryGrouping(grouping: ReportGrouping): void {
		this.setGrouping(grouping, 'primary');
		if (AnalyticMetricTypes.isTime(grouping)) {
			this.widget.properties.primaryTimeGrouping = grouping;
		}
	}

	private isPOPGrouping(grouping: ReportGrouping): boolean {
		return grouping.name === this.POP_GROUPING.name;
	}

	protected setGrouping(grouping: ReportGrouping, prefix: string): void {
		if (!_.findWhere(this.exceptionalGroupings, {name: grouping.name})) {
			this.widget.properties.selectedAttributes.push(grouping);
		}
		let visualProps = this.widget.visualProperties;
		visualProps.attributeSelections[prefix + 'Group'] = grouping;
		visualProps[prefix + 'Group'] = grouping.name;
	}

	protected withGroupingDependencies(grouping: ReportGrouping, context: IReportWidgetContext): void {
		if (this.isPOPGrouping(grouping)) {
			super.initProperties(
				this.POP_GROUPING_OPTIONS, context, 'visualProperties');
		}
	}

	private setPrimaryCalculation(calculation: ReportCalculation): void {
		this.setAxisCalculation(calculation);
	}

	private setSecondaryCalculation(calculation: ReportCalculation): void {
		this.setAxisCalculation(calculation, 'secondary');
	}

	private setAxisCalculation(calculation: ReportCalculation, prefix?: string): void {
		let axis = prefix ? prefix + 'YAxis' : 'yAxis';
		super.addCalculation(calculation);
		this.widget.visualProperties.attributeSelections[axis] = calculation;
		this.widget.visualProperties[axis] = calculation.name;
	}

	private setSecondarySize(calculation: ReportCalculation): void {
		this.widget.visualProperties.attributeSelections.secondarySize = calculation;
		this.widget.visualProperties.secondarySize = calculation.name;
	}

	private initSize(context: IReportWidgetContext): void {
		let defaultSize = this.metricConstants.get().CONSTANT_SIZE;
		let size = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections.secondarySize',
			context, defaultSize);
		this.setSecondarySize(size);
	}

	private initSecondaryColor(context: IReportWidgetContext): void {
		super.initColor(this.SECONDARY_COLOR_TYPES.SECONDARY_COLOR, context);
	}

	protected initPoints(context: IReportWidgetContext, secondary?: boolean): void {
		let pointsProperty = secondary ? 'secondaryPoints' : 'points';
		let pointsValue = WidgetPropertyService.getValue(
			'visualProperties.' + pointsProperty, context, false);
		this.widget.visualProperties[pointsProperty] = pointsValue;
		if (pointsValue) {
			let colorType = secondary
				? this.SECONDARY_COLOR_TYPES.SECONDARY_POINT_COLOR
				: COLOR_TYPES.POINT_COLOR;
			super.initColor(colorType, context);
		}
	}

	private initSecondaryLine(context: IReportWidgetContext): void {
		this.initSecondaryColor(context);
		this.initPoints(context, true);
	}

	private initSecondaryBubble(context: IReportWidgetContext): void {
		this.initSize(context);
		this.initSecondaryColor(context);
	}

	private initSecondaryAxis(calculation: ReportCalculation, context: IReportWidgetContext): void {
		let sourceWidgetType = WidgetPropertyService.getValue('properties.widgetType', context);
		let secondaryYAxis = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections.secondaryYAxis', context);
		if (sourceWidgetType !== WidgetType.SCATTER && sourceWidgetType !== WidgetType.TABLE
			&& _.isUndefined(secondaryYAxis)) {
			return;
		}

		this.setSecondaryCalculation(calculation);

		let secondaryType = WidgetPropertyService.getValue(
			'visualProperties.secondaryChartType', context, 'BUBBLE');

		this.widget.visualProperties.secondaryChartType = secondaryType;

		let secondarySpecific = this.VISUALIZATIONS_SPECIFIC[secondaryType];
		secondarySpecific.handler(context);
	}

	private initColorCalculation(calculation: ReportCalculation): void {
		super.addCalculation(calculation);
	}

	private initColorCalcultionWithSelections(calculation: ReportCalculation): void {
		this.initColorCalculation(calculation);
		this.widget.visualProperties.attributeSelections.color = calculation;
	}

	private initDirection(context: IReportWidgetContext): void {
		let DIRECTION = {name: 'direction', default: 'DESC'};
		super.initProperties([DIRECTION], context, 'visualProperties');
	}

	private initSortCalcultion(calculation: ReportCalculation, context: IReportWidgetContext): void {
		super.addCalculation(calculation);
		this.widget.visualProperties.attributeSelections.sortBy = calculation;
		this.widget.visualProperties.sortBy = calculation.name;

		this.initDirection(context);
	}

	private initCalculations(context: IReportWidgetContext): void {
		let calculations = WidgetPropertyService.getValue(
			'properties.selectedMetrics', context, []) as ReportCalculation[];

		if (_.isEmpty(calculations))
			calculations = [this.metricConstants.get().VOLUME];

		calculations = _.reject(calculations, this.isPoPCalculation);

		_.each(calculations, (calculation, index) => {
			this.handleCalculation(calculation, index, context);
		});

		super.withColors(context);
	}

	private handleCalculation(calculation: ReportCalculation, index: number, context: IReportWidgetContext): void {
		let selections = WidgetPropertyService.getValue(
			'visualProperties.attributeSelections', context, {});
		let handled = false;
		_.each(this.CALCULATION_TYPES, calculationType => {
			if (!_.isUndefined(calculationType.key)
					&& !_.isUndefined(selections[calculationType.key])
					&& _.isEqual(selections[calculationType.key].name, calculation.name)) {
				handled = true;
				calculationType.handler(calculation, context);
			}
		});

		if (!handled && !this.shouldHandleCalculationAsColor(calculation, context)) {
			this.useDefaultCalculationHandler(calculation, index, context);
		}

		this.initDirection(context);
	}

	private useDefaultCalculationHandler(calculation: ReportCalculation, index: number, context: IReportWidgetContext): void {
		let defaultType = _.findWhere(this.CALCULATION_TYPES, {index});
		if (!_.isEmpty(defaultType)) {
			defaultType.handler(calculation, context);
		}
	}

	private shouldHandleCalculationAsColor(calculation: ReportCalculation, context: IReportWidgetContext): boolean {
		let sourceWidgetType = WidgetPropertyService.getValue('properties.widgetType', context);
		//for scatter we rely on index
		if (sourceWidgetType === WidgetType.SCATTER) {
			return false;
		}

		let result = false;
		_.each(ReportConstants.colorFields, colorField => {
			let color = WidgetPropertyService.getValue('visualProperties.' + colorField, context);
			if (!_.isUndefined(color) && CalculationColorUtils.isCalculationColor(color)) {
				let calculationName = CalculationColorUtils.getCalculationColorAttributeName(color);
				if (calculationName === calculation.name) result = true;
			}
		});
		return result;
	}
}
