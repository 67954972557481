import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageListActions } from '@app/modules/home-page/home-page-management/home-page-list-actions.service';
import { HomePageManagementComponent } from '@app/modules/home-page/home-page-management/home-page-management/home-page-management.component';


@Injectable()
export class HomePageListMenu extends BaseContextMenuUtils {

	constructor(private locale: CxLocaleService, private homePageListActions: HomePageListActions) {
		super();
	}

	private getOptions(controller: HomePageManagementComponent): {[name: string]: ContextMenuItem<HomePage>} {
		return {
			EDIT: {
				name: 'edit',
				text: this.locale.getString('common.edit'),
				func: (homePage: HomePage) => this.homePageListActions.edit(homePage, controller)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (homePage: HomePage) => this.homePageListActions.delete(homePage)
			},

			COPY: {
				name: 'copy',
				text: this.locale.getString('common.makeCopy'),
				func: (homePage: HomePage) => this.homePageListActions.copy(homePage, controller)
			},

			MOVE_UP: {
				name: 'up',
				text: this.locale.getString('common.moveUp'),
				func: (homePage: HomePage) => this.homePageListActions.changeRank(homePage, homePage.rank - 1)
			},

			MOVE_DOWN: {
				name: 'down',
				text: this.locale.getString('common.moveDown'),
				func: (homePage: HomePage) => this.homePageListActions.changeRank(homePage, homePage.rank + 1)
			},

		};
	}

	getContextMenu(item: HomePage, controller: HomePageManagementComponent): Array<ContextMenuItem<HomePage>> {
		const OPTIONS = this.getOptions(controller);
		if (item.default)
			return [OPTIONS.EDIT, OPTIONS.COPY];

		let moveOptions = [];
		if (item.rank > 0)
			moveOptions.push(OPTIONS.MOVE_UP);
		
		if (item.rank < controller.homePages[controller.homePages.length - 2].rank) { // last is always default
			moveOptions.push(OPTIONS.MOVE_DOWN);
		}
		return moveOptions.concat([OPTIONS.EDIT, OPTIONS.DELETE, OPTIONS.COPY]);
	}


	isVisibleObject(item: any): boolean {
		return true;
	}


}
