import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { AdhocFilter, IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { MobileApplicationDashboardUtils, MobileScreenConfiguration } from './mobile-application-dashboard-utils';
import SimpleGroupingMobileScreenSettings from '@cxstudio/mobile/simple-grouping-mobile-screen-settings.interface';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ModelUtils } from '@app/modules/project/model/model-utils';

@Injectable()
export class MobileApplicationHelperService {

	constructor(
		private modelsService: ModelsService,
	) {}

	// for 1st and 2nd screens only as the 3rd one contains a single widget
	getGroupingsModelsIds(settings: MobileAppSettings): number[] {
		let modelIndexes: number[] = [];

		let firstScreenGrouping: ReportGrouping = this.getScreenGrouping(settings, MobileApplicationDashboardUtils.FirstScreen);
		if (AnalyticMetricTypes.isTopics(firstScreenGrouping)) {
			modelIndexes.push(+firstScreenGrouping.name);
		}

		let secondScreenGrouping: ReportGrouping = this.getScreenGrouping(settings, MobileApplicationDashboardUtils.SecondScreen);
		if (AnalyticMetricTypes.isTopics(secondScreenGrouping)) {
			modelIndexes.push(+secondScreenGrouping.name);
		}

		return _.unique(modelIndexes);
	}

	private getScreenGrouping(settings: MobileAppSettings, screenConfiguration: MobileScreenConfiguration): ReportGrouping {
		let screen: SimpleGroupingMobileScreenSettings =
			(settings.screens[screenConfiguration.screenIndex]) as SimpleGroupingMobileScreenSettings;
		return screen.grouping;
	}

	requestModels(modelsIds: number[], settings: MobileAppSettings): Promise<ModelTree[]> {
		let promises = modelsIds.map(modelId => this.getModelTree(modelId, settings));
		return Promise.all(promises);
	}

	private getModelTree(modelId: number, mobileSettings: MobileAppSettings): Promise<ModelTree> {
		return this.modelsService.getModelTree(
			new ProjectIdentifier(mobileSettings.contentProviderId, mobileSettings.accountId, mobileSettings.projectId),
			modelId).then(modelTree => ModelUtils.populateNodesPath(modelTree));
	}

	getCustomFilter(customerFilter: AdhocFilter, grouping: ReportGrouping, models: ModelTree[]): AdhocFilter {
		if (models && AnalyticMetricTypes.isTopics(grouping)) {
			let model: ModelTree = _.findWhere(models, {id: +grouping.name});
			return this.buildCustomFilter(customerFilter, grouping.displayName, model);
		} else {
			return customerFilter;
		}
	}

	private buildCustomFilter(customFilter: AdhocFilter, modelDisplayName: string, modelTree: ModelTree): AdhocFilter {
		let categorizedFilterRule: IFilterRule = this.buildCategorizedFilterRule(modelDisplayName, modelTree.id, modelTree.root.id);

		let filterRules: IFilterRule[] = [categorizedFilterRule];
		if (customFilter?.filterRules?.length) {
			filterRules.pushAll(customFilter.filterRules);
		}

		return {
			type: FilterTypes.AND,
			filterRules
		};
	}

	private buildCategorizedFilterRule(modelDisplayName: string, modelId: number, rootNodeId: number): IFilterRule {
		let filterRule: IFilterRule = {
			type: FilterRuleType.topicEquality,
			topicId: modelId,
			displayName: modelDisplayName,
			matchMode: FilterMatchModeValue.IS,
			modelMatch: true,
			values: [{
				idPath: `${rootNodeId}`,
				nodeId: rootNodeId
			}]
		};

		return filterRule;
	}
}

app.service('mobileApplicationHelperService', downgradeInjectable(MobileApplicationHelperService));
