<collapsing-panel>
	<panel-heading>
		<b [i18n]="'homePage.generalSettings'"></b>
	</panel-heading>
	<panel-body>
		<div class="pl-16">
			<label class="font-bold" for="homePageName" [i18n]="'homePage.homePageNameLabel'"></label>
			<input class="d-block w-100-percent"
				id="homePageName"
				[(ngModel)]="homePage.name"
				[maxlength]="TITLE_LIMIT"
				(ngModelChange)="onChange.emit()"/>
		</div>
		<div class="pl-16 mt-16" *ngIf="!homePage.default">
			<label class="font-bold">
				{{'homePage.homePageDisplayLabel'|i18n}}
				<cb-inline-help>
					<help-body>{{'homePage.homePageShareTooltip'|i18n}}</help-body>
				</cb-inline-help>
			</label>
			<label class="d-block" [i18n]="'homePage.shareInviteLabel'"></label>
			<home-page-sharing [homePage]="homePage" (onChange)="onChange.emit()" [saveListener]="saveListener"></home-page-sharing>
		</div>
		<div class="pl-16 mt-32">
			<home-reporting-settings [(reportProperties)]="homePage.reportProperties"
				(reportPropertiesChange)="onChange.emit()"></home-reporting-settings>
		</div>
	</panel-body>
</collapsing-panel>
