<menu role="menu" class="dropdown-submenu"
	(click)="toggleSubmenu($event)"
	(keydown)="checkKeydown($event)"
	#submenu>
	<a class="dropdown-item option copy-to-submenu"
		[ngClass]="{'disabled': disabled}"
		href="javascript:void(0)"
		role="menuitem"
		[attr.aria-expanded]="isOpened"
		aria-haspopup="true">{{label}}</a>
	<menu role="menu" class="dropdown-menu w-auto w-max-280"
		[ngClass]="{'dropdown-left':menuToLeft, 'dropdown-right': !menuToLeft}" 
		*ngIf="isOpened">
		
		<div role="menuitem" class="ph-8 mb-8">
			<input
				[(ngModel)]="searchFilter"
				class="w-100-percent"
				type="search"
				(click)="$event.stopPropagation();"
				placeholder="{{'common.search'|i18n}}">
		</div>
		<div role="group" class="menu-scroll h-max-200 overflow-y-auto">
			<a *ngIf="canCreate()"
				role="menuitem"
				class="new-dashboard dropdown-item option"
				(click)="performCopyToNew()"
				title="{{'dashboard.newDashboard'|i18n}}"
				href="javascript:void(0)">{{'dashboard.newDashboard'|i18n}}</a>

			<a *ngFor="let dashboard of getFilteredDashboards() | slice:0:currentLimit"
				role="menuitem"
				class="dropdown-item option dashboard"
				(click)="performCopy(dashboard)"
				title="{{dashboard.name}}"
				href="javascript:void(0)">{{dashboard.name}}</a>

			<a href="javascript:void(0)"
				role="menuitem"
				[ngClass]="getFilteredDashboards().length < currentLimit ? 'd-none' : 'd-flex'"
				class="dropdown-item align-items-center justify-center"
				(click)="$event.stopPropagation(); currentLimit = currentLimit + BASE_LIMIT"
				title="{{'common.showMore'|i18n}}"
				[attr.aria-label]="'common.showMore'|i18n">...</a>

		</div>
		
		<span 
			*ngIf="!hasEditDashboards && !canCreate()"
			class="dropdown-item empty-item d-block cursor-not-allowed option"
			tabindex="0"
			[attr.aria-label]="'widget.noAvailableDashboards'|i18n"
			i18n="widget.noAvailableDashboards"></span>
	</menu>
</menu>
