import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { CxLocaleService } from '@app/core';
import { JumpLink } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/jump-link.component';
import { SpotCheckResultStatus } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-status';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';


@Component({
	selector: 'jump-links',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<ng-container *ngFor="let link of getLinkList()">
		<jump-link [link]="link"
			[count]="statusCounts[link.status] || 0"
			[active]="link.status === selected"
			(click)="onClick(link)"
			(keydown.enter)="onEnter($event, link)"
		></jump-link>
	</ng-container>
	`
})
export class JumpLinksComponent implements OnInit {
	@Input() statusCounts: {[key in SpotCheckResultStatus]?: number};
	@Output() selectionChange = new EventEmitter<SpotCheckResultStatus>();

	links: JumpLink[];
	selected: SpotCheckResultStatus = SpotCheckResultStatus.ISSUE;

	constructor(
		private readonly locale: CxLocaleService,
	) {}

	ngOnInit(): void {
		this.links = [
			{ status: SpotCheckResultStatus.ISSUE, label: this.locale.getString('dashboardSpotCheck.issue') },
			{ status: SpotCheckResultStatus.TIP, label: this.locale.getString('dashboardSpotCheck.tip') },
			{ status: SpotCheckResultStatus.PASSED, label: this.locale.getString('dashboardSpotCheck.passed') },
			{ status: SpotCheckResultStatus.IGNORED, label: this.locale.getString('dashboardSpotCheck.ignored') }
		];
	}

	getLinkList(): JumpLink[] {
		return _.filter(this.links, (link) => {
			return this.shouldBeRendered(link);
		});
	}

	onClick(link: JumpLink): void {
		if (link.status !== this.selected) {
			this.selected = link.status;
			this.selectionChange.emit(this.selected);
		}
	}

	shouldBeRendered(link: JumpLink): boolean {
		return !!this.statusCounts[link.status] || link.status !== SpotCheckResultStatus.IGNORED;
	}

	onEnter(event: KeyboardEvent, link: JumpLink): void {
		KeyboardUtils.intercept(event);
		this.onClick(link);
	}
}
