import * as cloneDeep from 'lodash.clonedeep';

import { Inject, Injectable } from '@angular/core';
import { LegendLinkingUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/legend-linking-utils.service';
import { LegendSeriesRulesService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/legend-series-rules.service';
import { IDataPoint, IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DualDataProcessingService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-data-processing.service';
import { LegendFunction, LegendFunctions } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/legends/dual-legend.service';

@Injectable({
	providedIn: 'root'
})
export class DualLegendSeriesService {

	constructor(
		private legendLinkingUtils: LegendLinkingUtilsService,
		private legendSeriesRules: LegendSeriesRulesService,
		private dualDataProcessing: DualDataProcessingService,
	) { }

	processSeriesData(focusSeries: any, legendFunctions: LegendFunctions, 
			options: VisualProperties, utils: WidgetUtils, isSimpleLegend: boolean): any[] {
		let legendColorFunction: LegendFunction;
		if (focusSeries.isPrimary) {
			legendColorFunction = legendFunctions.color || legendFunctions.pointColor;
		} else if (!focusSeries.isPrimary) {
			legendColorFunction = legendFunctions.secondaryColor || legendFunctions.secondaryPointColor;
		}
		// if we dont know how to color the legend, abort
		if (!legendColorFunction) return [];
		let processedColors = [];
		return focusSeries.data.filter(dataPoint => {
			//  move on if we have no data object to process, or the data point should be skipped for any other reasons
			return !!dataPoint?.object 	&& !this.legendSeriesRules.skipDataPoint(dataPoint, options, processedColors);
		}).map((dataPoint: IDataPoint, i) => {
			let nameItem = legendColorFunction(dataPoint.object);
			let displayName = this.getDisplayNameForData(dataPoint, nameItem.name, focusSeries, options, utils);

			let generatedLegendItem = {
				identifier: displayName + focusSeries.type,
				name: displayName,
				color: dataPoint.color || focusSeries.color,
				marker: focusSeries.marker ? cloneDeep(focusSeries.marker) : {},
				data: [],
				grouping: false,
				showInLegend: true,
				type: focusSeries.type,
				order: nameItem.order,
				events: {
					legendItemClick: this.legendSeriesRules.disableColorClick(dataPoint, options) 
						? () => false // ignore click
						: this.legendLinkingUtils.createLegendSeriesLink(focusSeries)
				}
			};

			// if there are customizations needed, call it in the current context
			//don't customize recolored points
			if (!ColorUtilsHelper.dataPointHasRecolor(options, dataPoint)) {
				return this.getSeriesLegend(focusSeries, i, options, isSimpleLegend, generatedLegendItem);
			} else {
				return generatedLegendItem;
			}
		});
	}


	private getSeriesLegend(focusSeries: any, seriesIndex: number, options: VisualProperties,
			isSimpleLegend: boolean, generatedLegendItem: any): any[] {
		// if series does not need to be split between line + point
		if (isSimpleLegend) {
			$.extend(generatedLegendItem, { color: focusSeries.color });
			if (!generatedLegendItem.marker)
				generatedLegendItem.marker = {};
			let dataPoint = focusSeries.data[seriesIndex];

			generatedLegendItem.marker.fillColor = dataPoint.color || focusSeries.color;
			generatedLegendItem.color = dataPoint.color || focusSeries.color;

			return generatedLegendItem;
		} else if (this.isSimpleSeries(focusSeries, options)) {
			let seriesCopy = cloneDeep(focusSeries);
			seriesCopy.showInLegend = true;
			seriesCopy.grouping = false;

			if (seriesCopy.marker) {
				seriesCopy.marker.fillColor = focusSeries.data[seriesIndex].color;
			}
			delete seriesCopy.data;
			this.addLegendSeriesToggle(seriesCopy, [focusSeries]);

			return seriesCopy;
		} else {
			// else split into separate point and line
			let splitLegend = this.splitIntoPointAndLine(focusSeries, generatedLegendItem);

			// separate point legend entry should be deprecated now based on new processing of metrics and sentiment
			return (focusSeries.type === 'bubble') ? cloneDeep(splitLegend.point) : cloneDeep(splitLegend.line);
		}
	}

	private splitIntoPointAndLine(dataSeries, series) {
		let splitLegendLine = {
			identifier: dataSeries.name + dataSeries.type,
			name: dataSeries.name,
			color: dataSeries.color,
			data: [],
			grouping: false,
			showInLegend: true,
			type: dataSeries.type,
			isLine: true
		} as any;

		this.addLegendSeriesToggle(splitLegendLine, [dataSeries]);

		if (dataSeries.type === 'bubble')
			this.makeLegendSeriesPoint(series, [dataSeries]);
		else 
			this.makeLegendSeriesPoint(series);
		// hide point marker from line portion
		splitLegendLine.marker = {enabled: false};

		return {line: splitLegendLine, point: series};
	}

	private isSimpleSeries(series, options: VisualProperties): boolean {
		let colorTypes = series.isPrimary ? [ColorTypes.PRIMARY, ColorTypes.POINT] 
			: [ColorTypes.SECONDARY, ColorTypes.SECONDARY_POINT];
		return !ColorUtilsHelper.isObjectBasedColorPresent(colorTypes, options);
	}

	private addLegendSeriesToggle(legendSeries, linkToSeries: any[]) {
		legendSeries.events = {
			legendItemClick: this.legendLinkingUtils.createLegendSeriesLink(linkToSeries)
		};
	}

	// convert a legend series to show only point, no line
	private makeLegendSeriesPoint(pointLegendSeries: any, linkToSeries?: any) {
		if (pointLegendSeries.marker) pointLegendSeries.marker.fillColor = pointLegendSeries.color;

		pointLegendSeries.color = 'none';
		pointLegendSeries.events.legendItemClick = !!linkToSeries 
			? this.legendLinkingUtils.createLegendSeriesLink(linkToSeries)
			: () => false;
	}

	private getDisplayNameForData(data: IDataPoint, legendName: string, focusSeries, 
			options: VisualProperties, utils: WidgetUtils) {

		let seriesNameFormatters = this.dualDataProcessing.getGroupingFormatters(options, utils);

		let periodName = DualDefinitionHelper.isPop(options) ? this.getSeriesPeriodName(focusSeries, seriesNameFormatters._pop) : '';
		let name = {
			periodSuffix: periodName
		} as any;

		// if item is an un-recolored custom color, and is a POP data point, we're only going to list it once in the legend
		// and thus we only want the period name, not the data item name
		if (!this.legendSeriesRules.isRecolored(data, options) && periodName) {
			name.displayName = periodName;
		} else if (this.legendSeriesRules.isUnrecoloredCustom(data, options) && focusSeries) {
			name.displayName = focusSeries.name;
		} else {
			name.displayName = periodName ? `${legendName} - ${periodName}` : legendName;
		}
		
		// This should use dualLegendInstanceHelper.isSimpleLegend, 
		// but changing to that breaks a lot of other legends.
		// Commenting as it's always false and might be useful for aliens to undertand why mankind died
		// return dualUtils.isSimpleLegend 
		// 	? focusSeries.name 
		// 	: colorUtils.checkForRecolor(legendItem, reportDefinition, name, seriesNameFormatters);
		return ColorUtilsHelper.checkForRecolor(data, options, name, seriesNameFormatters, focusSeries);
	}


	private getSeriesPeriodName(dataSeries, periodFormatter: (period: string) => string) {
		let period = this.findSeriePopPeriodName(dataSeries);
		return periodFormatter(period);
	}

	private findSeriePopPeriodName(oneSeries) {
		let popItem = _.find(oneSeries.data as IDataPoint[], point => point?.object?._pop);
		return popItem?.object._pop;
	}
}
