import ILocale from '@cxstudio/interfaces/locale-interface';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';
import { ContractMode } from '@cxstudio/master-accounts/contracts/contract-mode';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { AppPage } from '@app/util/app-page';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { SelectAttributeModalTypes } from '@app/modules/hierarchy/attributes/select-attribute-modal-types';
import Widget, { ContentWidget, ExternalWidget } from '@cxstudio/dashboards/widgets/widget';
import { ObjectUtils } from '@app/util/object-utils';
import { EngagorCaseType } from '@cxstudio/reports/document-explorer/components/create-engagor-case-modal.component';
import { CaseDocument } from '@cxstudio/reports/entities/case-document.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';

export interface ICustomDialogButton {
	text: string;
	cssClass?: string;
	callback: (dialogInstance, data: ICustomDialogData) => void;
}

export interface ICustomDialogData {
	header: string;
	template?: string; // url for modal content
	text?: string; // optionally just display text
	windowClass?: string; // root modal element class
	ignoreEscape?: boolean;
	okBtnName?: string; // default "Yes"
	cancelBtnName?: string; // default "No"
	save?: CallbackFunction; // custom save handler
	cancel?: CallbackFunction; // custom cancel handler
	customButtons?: ICustomDialogButton[];
	confirmationDelay?: number; // number of milliseconds, while confirm button will be disabled
	[key: string]: any; // fields to use in template, accessible through $scope.data.<field_name>
}

type CallbackFunction = (modalInstance: ng.ui.bootstrap.IModalInstanceService, data?: any, instanceScope?: ng.IScope) => void;

export class CBDialogService {

	constructor(
		private $uibModal: ng.ui.bootstrap.IModalService,
		private $location: ng.ILocationService,
		private $q: ng.IQService,
		private locale: ILocale
	) {
	}

	showDialog = (modalData: ICustomDialogData): IModalInstanceService => {
		let dialog = {
			templateUrl: 'partials/custom/custom-dialog.html',
			controller: 'CustomDialogCtrl',
			backdrop: 'static',
			backdropClass: '',
			windowClass: ['br-custom-dialog', modalData.windowClass].join(' '),
			keyboard: !modalData.ignoreEscape,
			resolve: {
				data: () => modalData
			},
			danger: modalData.danger
		};

		return this.$uibModal.open(dialog);
	}

	danger = (header: string, msg: string, okBtnName?: string, cancelBtnName?: string, windowClass?: string,
		confirmationDelay?: number): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-dialog-content.html',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.delete'),
			cancelBtnName: cancelBtnName ? cancelBtnName : this.locale.getString('common.cancel'),
			msg,
			windowClass,
			danger: true,
			confirmationDelay
		});
	}

	confirm = (header: string, msg: string, okBtnName?: string, cancelBtnName?: string, windowClass?: string): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-dialog-content.html',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.yes'),
			cancelBtnName: cancelBtnName ? cancelBtnName : this.locale.getString('common.no'),
			msg,
			windowClass
		});
	}

	confirmTable = (header: string, msg: string, columns: Array<TableColumn<any>>,
		items: any[], okBtnName?: string, cancelBtnName?: string, danger?: boolean): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-table-dialog-content.html',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.yes'),
			cancelBtnName: cancelBtnName ? cancelBtnName : this.locale.getString('common.no'),
			msg,
			columns,
			items,
			danger
		});
	}

	confirmTableWithLoading = (header: string, msg: string, columns: Array<TableColumn<any>>,
		items: any[], promises: { [field: string]: ng.IPromise<any> },
		okBtnName?: string, cancelBtnName?: string, danger?: boolean): IModalInstanceService => {
		let modelData = {
			header,
			template: 'partials/custom/confirm-table-dialog-content.html',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.yes'),
			cancelBtnName: cancelBtnName ? cancelBtnName : this.locale.getString('common.no'),
			msg,
			columns,
			items,
			danger
		} as ICustomDialogData;
		modelData.loadingPromise = this.$q.all(promises).then(results => {
			_.extend(modelData, results);
		});
		return this.showDialog(modelData);
	}

	confirmYesNo = (header: string, msg: string, callbackFn: (dialogResult: boolean) => void, windowClass?: string): IModalInstanceService => {
		return this.pickOneOrCancel(header, msg, callbackFn, windowClass, undefined, undefined, 'btn-secondary');
	}

	pickOneOrCancel = (header: string, msg: string, callbackFn: (dialogResult: boolean) => void,
		windowClass?: string, yesBtnName?: string, noBtnName?: string, noBtnClass?: string): IModalInstanceService => {
		let yesCallback = (dialog: IModalInstanceService, result) => {
			dialog.close();
			callbackFn(true);
		};
		let noCallback = (dialog: IModalInstanceService, result) => {
			dialog.close();
			callbackFn(false);
		};
		let customButton = [{
			text: noBtnName || this.locale.getString('common.no'),
			cssClass: noBtnClass || 'btn-primary',
			callback: noCallback
		}];
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-dialog-content.html',
			okBtnName: yesBtnName || this.locale.getString('common.yes'),
			cancelBtnName: this.locale.getString('common.cancel'),
			msg,
			windowClass,
			customButtons: customButton,
			save: yesCallback
		});
	}

	custom = (header: string, template: string, model?: any, okBtnName?: string,
		cancelBtnName?: string, windowClass?: string): IModalInstanceService => {

		return this.showDialog({
			header,
			template,
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.ok'),
			cancelBtnName: cancelBtnName ? cancelBtnName : null,
			windowClass,
			model
		});
	}

	notify = (header: string, msg: string, okBtnName?: string, windowClass?: string): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-dialog-content.html',
			windowClass,
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.ok'),
			cancelBtnName: null,
			msg
		});
	}

	notifyTable = (header: string, msg: string, columns: Array<TableColumn<any>>,
		items: any[], okBtnName?: string, windowClass?: string): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-table-dialog-content.html',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.yes'),
			cancelBtnName: null,
			msg,
			columns,
			items,
			windowClass
		});
	}

	warning = (header: string, msg: string, okBtnName?: string): IModalInstanceService => {
		return this.showDialog({
			header,
			template: 'partials/custom/confirm-dialog-content.html',
			windowClass: 'br-warning-dialog',
			okBtnName: okBtnName ? okBtnName : this.locale.getString('common.ok'),
			cancelBtnName: null,
			msg
		});
	}

	showRegisterDialog = (user: User, passwordPolicy: any): void => {
		let dialog = this.$uibModal.open({
			templateUrl: 'partials/user-administration/change-password-dialog.html',
			controller: 'PasswordResetOnLoginCtrl',
			backdrop: 'static',
			windowClass: 'br-share-dialog',
			resolve: {
				user: () => user,
				passwordPolicy: () => passwordPolicy,
				forceRegistered: () => true
			}
		});

		dialog.result.then(_.noop, () => {
			this.$location.path(AppPage.LOGIN);
		});
	}

	showPasswordResetDialog = (user: User, passwordPolicy: any): void => {
		let dialog = this.$uibModal.open({
			templateUrl: 'partials/dialogs/expired-password-dialog.html',
			controller: 'PasswordResetOnLoginCtrl',
			backdrop: 'static',
			windowClass: 'br-share-dialog',
			resolve: {
				user: () => user,
				passwordPolicy: () => passwordPolicy,
				forceRegistered: () => false
			}
		});

		dialog.result.then(_.noop, () => {
			this.$location.path(AppPage.LOGIN);
		});
	}

	showStatsExportDialog = (exportText: string): IModalInstanceService => {
		return this.showDialog({
			header: this.locale.getString('widget.statsExportDialogTitle'),
			template: 'partials/dialogs/stats-export-dialog.html',
			okBtnName: this.locale.getString('common.ok'),
			cancelBtnName: null,
			exportText
		});
	}

	showLinkDialog = (save: CallbackFunction, remove: CallbackFunction, href?: string, title?: string): IModalInstanceService => {
		let removeButton = {
			text: this.locale.getString('widget.text_link_unLinkButton'),
			cssClass: 'btn-primary',
			callback: remove
		};
		let model = {
			header: this.locale.getString('widget.text_link_dialogPrompt'),
			template: 'partials/custom/edit-link-dialog.html',
			okBtnName: this.locale.getString('common.save'),
			cancelBtnName: this.locale.getString('common.cancel'),
			customButtons: !!href ? [removeButton] : undefined,
			save,
			remove,
			href,
			title,
			initialHref: href,
			initialTitle: title
		} as ICustomDialogData;
		model.isSaveDisabled = () => !model.href;
		return this.showDialog(model);
	}

	showContractDialog = (currentContract: Contract, mode: ContractMode, licenses: LicenseTypeItem[]): ng.IPromise<Contract> => {
		return this.$uibModal.open({
			backdrop: 'static',
			windowClass: 'modal-md',
			component: 'contractModal',
			resolve: {
				licenses: () => licenses,
				contract: () => currentContract,
				mode: () => mode,
			}
		}).result;
	}

	showColorPaletteModal = (palette: ColorPalette, allPalettes: ColorPalette[]): ng.IPromise<ColorPalette> => {
		return this.$uibModal.open({
			component: 'colorPaletteEditor',
			windowClass: 'modal-md br-palette-dialog',
			backdrop: 'static',
			resolve: {
				palette: () => palette,
				itemList: () => allPalettes
			}
		}).result;
	}

	showReportSettingsModal<T extends Widget>(widget: T): ng.IPromise<T> {
		const dialogClass = `br-settings-${widget.name}`;

		return this.$uibModal.open({
			component: 'reportSettingsModal',
			windowClass: `br-widget-dialog ${dialogClass}`,
			backdrop: 'static',
			resolve: {
				widget: () => ObjectUtils.copy(widget),
			}
		}).result;
	}

	showWidgetSettingsModal<T extends ContentWidget & ExternalWidget>(widget: T): ng.IPromise<T> {
		const dialogClass = `br-settings-${widget.name}`;

		return this.$uibModal.open({
			component: 'widgetSettingsModal',
			windowClass: `br-widget-dialog ${dialogClass}`,
			backdrop: 'static',
			resolve: {
				widget: () => ObjectUtils.copy(widget),
			}
		}).result;
	}

	// Remove the css class + file for org-enrichment-wizard-upload-z-index-1040 when the attributes-modal
	// is converted to angular
	showAttributesModal = (provider: any): ng.IPromise<any> => {
		return this.$uibModal.open({
			templateUrl: 'partials/master-accounts/attributes-modal.html',
			controller: 'attributesModalCtrl',
			windowClass: 'modal-md',
			resolve: {
				contentProvider: () => provider,
				maxAmountOfAttributes: () => null,
				modalType: () => SelectAttributeModalTypes.ORG_ENRICHMENT
			}
		}).result;
	}

	showCreateCaseModal = (
		type: EngagorCaseType,
		documents: CaseDocument[],
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject,
		assetParameters: AssetParameters
	): ng.IPromise<any> => {
		return this.$uibModal.open({
			component: 'createEngagorCaseModal',
			size: 'lg',
			windowClass: 'modal-max-1200',
			backdrop: 'static',
			resolve: {
				type: () => type,
				documents: () => documents,
				projectIdentifier: () => projectIdentifier,
				workspaceProject: () => workspaceProject,
				assetParameters: () => assetParameters
			}
		}).result;
	}

	showGroupsUserMembership = (): ng.IPromise<any> => {
		return this.$uibModal.open({
			component: 'groupsUserMembership',
			windowClass: 'modal-md',
			backdrop: 'static',
			keyboard: false
		}).result;
	}
}

app.service('cbDialogService', CBDialogService);
