export enum GridTypes {
	DASHBOARD = 'dashboard',
	FILTER = 'filter',
	METRIC = 'metric',
	ALERT = 'alert',
	STUDIO_ALERT = 'studioAlert',
	ALERT_SUBSCRIPTION_TEMPLATE = 'alertSubscriptionTemplate',
	DRIVERS = 'drivers',
	TEMPLATES = 'templates',
	SCHEDULE = 'schedule',
	ATTRIBUTES = 'attributes',
	MODELS = 'models',
	HIDDEN_GROUP_ASSETS = 'hiddenGroupAssets',
	GROUPS_BULK_USERS = 'groupsBulkUsers',
	USERS_BULK_GROUPS = 'usersBulkGroups',
	USERS_BULK_PERMISSIONS = 'usersBulkPermissions',
	USERS_BULK_DATA_ACCESS = 'usersBulkDataAccess',
	SCORECARDS = 'scorecards',
	PALETTES = 'palettes',
	MOBILE_SETTINGS = 'mobileSettings',
	CONVERSATION_SETTINGS = 'conversationSettings',
	INTERACTION_EXPLORER = 'interactionExplorer',
	AUTOMATED_NARRATIVE = 'automatedNarrative',
	HOME_PAGES = 'homePages',
	QUICK_INSIGHTS_DASHBOARD = 'quickInsightsDashboard',
	SAMPLED_AUDITS = 'sampledAudits',
	IDENTITY_PROVIDERS = 'identityProviders',
	SERVICE_PROVIDERS = 'serviceProviders',
	EMBEDDED_WIDGET_MANAGEMENT = 'embeddedWidgetManagement',
	ARCHIVE = 'archive',
	MASTER_ACCOUNTS = 'masterAccounts',
	WORKSPACES = 'workspaces',
	SECURITY_AUDIT = 'securityAudit',
	BETA_FEATURES = 'betaFeatures',
	BETA_FEATURES_CHECKLIST = 'betaFeaturesChecklist',
	CALENDAR_FORMAT = 'calendarFormat',
	DASHBOARD_TEMPLATES = 'dashboardTemplates',
}
