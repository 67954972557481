import ChartType from '@cxstudio/reports/entities/chart-type';
import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class BarDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let selections = this.visual.attributeSelections;
		if (!selections) {
			return '';
		}
		let primaryGroup = new Grouping(selections.primaryGroup?.displayName,
			this.utils.getGroupingType(selections.primaryGroup?.type),
			this.utils.getShowingOrder(selections.primaryGroup?.sortOrder)
		);
		let calculation = selections.yAxis?.displayName;
		if (this.visual.subChartType === ChartType.PARETO) {
			return this.getParetoDescription(primaryGroup, calculation);
		}

		let stackedBy = selections.stackedGroup
			? this.locale.getString('widgetDescription.stackedBy', {grouping: selections.stackedGroup.displayName})
			: '';
		let secondaryGroup = new Grouping(selections.secondaryGroup?.displayName || '',
			this.utils.getGroupingType(selections.secondaryGroup?.type),
			this.utils.getShowingOrder(selections.secondaryGroup?.sortOrder)
		);
		let groupedBy = selections.secondaryGroup?.name
			? this.locale.getString('widgetDescription.groupedBy', {grouping: secondaryGroup})
			: '';
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('barPrimaryPalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.barDescription', {
			chartName: this.utils.getChartName(this.visual.subChartType),
			grouping: primaryGroup,
			groupedBy,
			calculation,
			stackedBy,
			total,
			palette
		});
		if (this.visual.secondaryYAxis) {
			let displayType = this.utils.getChartName(this.visual.secondaryChartType);
			let secondaryPalette = this.utils.getPaletteDescription('barSecondaryPalette', this.visual.secondaryColor, displayType);
			let secondaryAxisKey = this.visual.secondaryStacked ? 'widgetDescription.secondaryAxisStacked' : 'widgetDescription.secondaryAxis';
			let secondary = this.locale.getString(secondaryAxisKey, {
				calculation: selections.secondaryYAxis?.displayName,
				displayType
			});
			description = `${description} ${secondary}${secondaryPalette}`;
		}
		if (!!data?.data && selections.primaryGroup && selections.yAxis) {
			let peak = this.utils.getChartFeatureDescription(selections.primaryGroup, selections.yAxis, data, true);
			let trough = this.utils.getChartFeatureDescription(selections.primaryGroup, selections.yAxis, data, false);
			description = this.utils.joinMessageParts([description, peak, trough]);
		}
		return description;
	}

	private getParetoDescription(grouping: Grouping, calculation: string, data?: any): string {
		if (!grouping.toString() || !calculation) {
			return '';
		}
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('barPrimaryPalette', this.visual.color);
		return this.locale.getString('widgetDescription.paretoDescription', {
			grouping,
			calculation,
			total,
			palette
		});
	}
}
