import { Directive, ElementRef, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
	selector: '[cxMaxLength]',
	providers: [{provide: NG_VALIDATORS, useExisting: CxMaxLengthDirective, multi: true}]
})
export class CxMaxLengthDirective implements Validator {
	@Input('cxMaxLength') maxLength: number;

	private onChange: () => void;

	constructor(private readonly el: ElementRef) {
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return Validators.maxLength(this.maxLength)(control);
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onChange = fn;
	}
}
