import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TimeToRunUtils } from '@app/modules/widget-container/widget-stats/time-to-run/time-to-run-utils';

@Injectable({
	providedIn: 'root'
})
export class TimeToRunFormatter {

	constructor(
		private locale: CxLocaleService,
	) {}

	clipboardFormatter = (value, data): any => {
		let timeToRunParts = TimeToRunUtils.getParts(data);
		let subitems = {};
		timeToRunParts.forEach((timeToRunPart) => {
			let label = this.locale.getString('widget.timelineType_' + timeToRunPart.type);
			subitems[label] = this.millisecondsFormatter(timeToRunPart.total);
		});
		return [this.millisecondsFormatter(value), subitems];
	}

	private millisecondsFormatter = (value: number): string => {
		let postfix = this.locale.getString('common.millisecondsPostfix');
		return !_.isUndefined(value) ? `${value} ${postfix}` : '';
	}
}
