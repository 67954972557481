import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { AccountOrWorkspace, AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { IAsset } from '../groups/asset';
import { IAssetAccess } from '../groups/asset-access';
import { IHiddenAssetGroupsInfo } from '../groups/hidden-asset-groups-info';
import { ModificationDelta } from '../groups/modification-delta';
import { IProjectSummary } from '../groups/project-summary';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { IHiddenObject } from '@cxstudio/services/user-objects.service';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';

@Injectable({
	providedIn: 'root'
})
export class AssetAccessApiService {
	private readonly cachedHttp: CxCachedHttp;

	constructor(
		private readonly http: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp = this.cxCachedHttpService.cache(Caches.ASSET_VISIBILITY);
	}

	getWorkspaceGroupAssetAccessSummary(workspace: AccountOrWorkspace, groupId: number): Promise<IProjectSummary[]> {
		let url = WorkspaceTransitionUtils.isWorkspace(workspace)
			? `rest/asset/visibility/summary/ws/${workspace}`
			: `rest/asset/visibility/summary/cp/${workspace.contentProviderId}/account/${workspace.accountId}`;
		let paramsObject = groupId ? { groupId: '' + groupId } : {};
		let params = new HttpParams({fromObject: paramsObject});
		return this.http.get(url, {params});
	}

	getGroupAssetAccess(project: AccountOrWorkspaceProject, groupId: number): Promise<IAsset[]> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/group/${groupId}`;
		return this.http.get(url);
	}

	updateGroupAssetAccess(project: AccountOrWorkspaceProject, groupId: number, changes: ModificationDelta<IAsset> ): Promise<void> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/group/${groupId}`;
		return this.http.put(url, changes);
	}

	getReportingAssetAccess(project: AccountOrWorkspaceProject): Promise<IAsset[]> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/reporting`;
		return this.http.get(url);
	}

	updateReportingAssetAccess(project: AccountOrWorkspaceProject, changes: ModificationDelta<IAsset>): Promise<void> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/reporting`;
		return this.http.put(url, changes);
	}

	getDrillMenuHiddenAssets(project: AccountOrWorkspaceProject, cache = CacheOptions.NOT_CACHED): Promise<IHiddenObject[]> {
		let config = { cache };
		const url = `${this.getAssetVisibilityBaseUrl(project)}/drill-menu`;
		return this.cachedHttp.get(url, config) as Promise<IHiddenObject[]>;
	}

	updateDrillMenuHiddenAssets(project: AccountOrWorkspaceProject, attributes: IAdminAttribute[]): Promise<void> {
		const url = `${this.getAssetVisibilityBaseUrl(project)}/drill-menu`;
		return this.http.put(url, attributes);
	}

	getDocExplorerHiddenAssets(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): Promise<IHiddenObject[]> {
		let config = { cache };
		const url = `${this.getAssetVisibilityBaseUrl(project)}/doc-explorer`;
		return this.cachedHttp.get(url, config) as Promise<IHiddenObject[]>;
	}

	updateDocExplorerHiddenAssets(project: AccountOrWorkspaceProject, attributes: IAdminAttribute[]): Promise<void> {
		const url = `${this.getAssetVisibilityBaseUrl(project)}/doc-explorer`;
		return this.http.put(url, attributes);
	}

	setHiddenAssetsForNewGroup(groupId: number, assetsAccess: IAssetAccess[]): Promise<void> {
		if (assetsAccess.isEmpty()) {
			return Promise.resolve();
		}
		let url = WorkspaceTransitionUtils.isWorkspaceProject(assetsAccess[0].project)
			? `rest/asset/visibility/group/${groupId}`
			: `rest/asset/visibility/group/${groupId}/new-group`;
		return this.http.put(url, assetsAccess);
	}

	getHiddenAssetGroupsInfo(project: AccountOrWorkspaceProject): Promise<IHiddenAssetGroupsInfo[]> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/groups-info`;
		return this.http.get(url);
	}

	exportHiddenAssetGroupsInfo(project: AccountOrWorkspaceProjectData, objectType: string): Promise<any> {
		let url = `${this.getAssetVisibilityBaseUrl(project)}/groups-info/xls`;
		let params = new HttpParams({fromObject: { projectName: project.projectName, objectType }});
		return this.http.get(url, { params });
	}

	private getAssetVisibilityBaseUrl(project: AccountOrWorkspaceProject): string {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? `rest/asset/visibility/ws/${project.workspaceId}/project/${project.projectId}`
			: `rest/asset/visibility/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	}
}

app.service('assetAccessApiService', downgradeInjectable(AssetAccessApiService));
