<div class="w-100-percent h-100-percent d-flex align-center justify-center bg-white p-relative">
	<div *ngIf="errorCode === ErrorCode.NOT_LINKED">
		<header class="mb-16 text-center">
			<span class="q-icon q-icon-warning text-lg-6 text-gray-800" aria-hidden="true"></span>
			<h2 class="text-lg-2 font-semibold">{{'error.externalUserNotLinkedToDiscover'|i18n}}</h2>
			<p class="text-base font-normal text-gray-800">{{'common.contactAdministratorForFurtherInformation'|i18n}}</p>
		</header>
		<footer class="d-flex flex-direction-column align-center">
			<div class="d-flex justify-center">
				<a class="btn btn-primary normal-case d-flex align-center"
					data-testid="error-page-take-back"
					[href]="externalLoginUrl"
					[i18n]="'login.takeMeBackTo'"
					[i18nParams]="{name: providerName}">
				</a>
			</div>
			<div [i18n]="'common.or'"></div>
			<button
				type="button"
				class="btn btn-primary normal-case"
				data-testid="error-page-login-to-discover"
				(click)="loginPopup()"
			>{{'login.signIntoXmDiscoverDifferentAccount'|i18n}}</button>
		</footer>
	</div>
	<div *ngIf="errorCode === ErrorCode.ENGAGE_REDIRECT">
		<header class="mb-16 text-center">
			<span class="q-icon q-icon-warning text-lg-6 text-gray-800" aria-hidden="true"></span>
			<h2 class="text-lg-2 font-semibold">{{'error.externalUserNotLinkedToEngage'|i18n}}</h2>
			<p class="text-base font-normal text-gray-800">{{'common.contactAdministratorForFurtherInformation'|i18n}}</p>
		</header>
		<footer class="w-100-percent text-center">
			<button
				type="button"
				class="btn btn-primary"
				data-testid="error-page-login-to-discover"
				(click)="loginPopup()"
			>{{'login.signIntoXmEngage'|i18n}}</button>
		</footer>
	</div>
	<div class="text-center" *ngIf="errorCode === ErrorCode.CANNOT_EMBED">
		<span class="q-icon q-icon-warning text-lg-6 text-gray-800" aria-hidden="true"></span>
		<h2 class="text-lg-2 font-semibold">{{'error.noEmbeddingSupportForPage'|i18n}}</h2>
		<p class="text-base font-normal text-gray-800" [i18n]="'common.openThisPageInNewTab'" [i18nParams]="{url: requestedPathLink}"></p>
	</div>
	<div class="text-center" *ngIf="errorCode === ErrorCode.REFRESH_REQUIRED">
		<span class="q-icon q-icon-refresh"></span>
		<h2 class="text-lg-2 font-semibold">{{'login.discoverIFrameRefresh'|i18n}}</h2>
		<p class="text-base font-normal text-gray-800">{{'login.discoverIFrameRefreshRequired'|i18n}}</p>
	</div>
	<div class="text-center" *ngIf="errorCode === ErrorCode.DEFAULT">
		<span class="q-icon q-icon-warning text-lg-6 text-gray-800" aria-hidden="true"></span>
		<h2 class="text-lg-2 font-semibold">{{'error.uncontrolledErrorForXmDiscoverPage'|i18n}}</h2>
		<p class="text-base font-normal text-gray-800">{{'common.otherOpenTabsForQualtricsBrand'|i18n}}</p>
		<p class="text-base font-normal text-gray-800">{{'common.contactSupportIfProblemPersists'|i18n}}</p>
		<button
			*ngIf="showRedirectToHome"
			type="button"
			class="btn btn-primary"
			(click)="close()"
		>{{'common.returnToHome'|i18n}}</button>
	</div>
</div>
