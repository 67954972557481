import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConversationEnrichmentUtils } from '@app/modules/conversation/enrichments/conversation-enrichment-utils.service';
import { Pill } from '@app/modules/pills/pill';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';

@Component({
	selector: 'enrichment-pills',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<collapsible-pills
			*ngIf="width && pills?.length"
			[pills]="pills"
			[showAll]="true"
			[useContrastTextColor]="false"
		></collapsible-pills>`,
})
export class EnrichmentPillsComponent implements OnInit, OnChanges {
	@Input() width: number;
	@Input() predefinedMetrics: Metric[];
	@Input() sentences: ConversationSentence[];

	pills: Pill[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly conversationEnrichmentUtils: ConversationEnrichmentUtils,
	) {}

	ngOnInit() {
		this.initPills();
	}

	ngOnChanges(changes: SimpleChanges) {
		const sentencesChange = changes.sentences;
		if (sentencesChange && !sentencesChange.firstChange && sentencesChange.currentValue !== sentencesChange.previousValue) {
			this.initPills();
			this.ref.detectChanges();
		}
	}

	private initPills() {
		this.pills = this.conversationEnrichmentUtils.getAllPills(this.sentences, this.predefinedMetrics);
	}


}
