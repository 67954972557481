<modal-header
	[modalTitle]="getHeader()"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<p>
		{{ 'organization.hierarchyReadyForProcessing' | i18n }}	
	</p>
	<checkbox-button class="d-block" 
		label="{{'organization.isIncludeEmptyRecords'|i18n}}"
		[(ngModel)]="publishConfiguration.includingEmptyRecords">
	</checkbox-button>
	<checkbox-button class="d-block" 
		label="{{'organization.isUseIncrementalMode'|i18n}}"
		[(ngModel)]="publishConfiguration.incrementalMode">
	</checkbox-button>
	<checkbox-button class="d-block" *ngIf="isUpdatingPublishedHierarchy()"
		label="{{'organization.classifyHistoricalData'|i18n}}"
		[(ngModel)]="publishConfiguration.runDeltaClassification">
	</checkbox-button>
</div>

<save-modal-footer
	(cancel)="cancel()"
	(save)="submit()"
	[saveText]="'organization.submit' | i18n"
	[cancelText]="'organization.cancel' | i18n">
</save-modal-footer>
