import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';
import { CasePriorityTypesService, ICasePriorityType } from '@app/modules/alert-subscription-template/services/case-priority-types.service';


@Component({
	selector: 'priority-selection',
	template: `
<div>
	<fieldset [disabled]="disabled">
		<div class="labeled-radio labeled-radio-inline" *ngFor="let option of priorityOptions">
			<input id="priority_{{option.value}}" type="radio" [checked]="model === option.value" (click)="onOptionClick(option.value)" value="{{option.value}}">
			<label for="priority_{{option.value}}">
				<span class="priority" [ngStyle]="{color: option.iconColor}">
					<span class="priority-base q-icon-priority-4"></span>
					<span class="priority-level q-icon-priority-{{option.level}}"></span>
				</span>{{option.displayName}}
			</label>
		</div>
	</fieldset>
</div>`
})

export class PrioritySelectionComponent implements OnInit {
	@Input() model: string;
	@Input() disabled: boolean;
	@Input() ticketing: boolean;
	@Output() modelChange = new EventEmitter<string>();
	priorityOptions: ICasePriorityType[];

	constructor(
		private readonly casePriorityTypes: CasePriorityTypesService
	) { }

	ngOnInit(): void {
		this.priorityOptions = this.casePriorityTypes.getPriorityTypes(this.ticketing);
	}

	onOptionClick = (priority: CasePriority): void => {
		this.model = priority;
		this.modelChange.emit(this.model);
	}
}

app.directive('prioritySelection', downgradeComponent({component: PrioritySelectionComponent}));
