import * as _ from 'underscore';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';

export class SelectorWidgetUtils {

	static isCustomSelection = (properties: WidgetProperties): boolean => {
		let attributes = properties?.selectedAttributes;
		if (attributes && attributes.length > 0) {
			return attributes[0]?.sortOrder === 'custom';
		}
		return false;
	}

}
