import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';

export class MetricExpressionItem extends ExpressionItem {
	id: number;
	name: string;

	constructor(
		id: number,
		name: string,
		displayName: string
	) {
		super(ExpressionPieces.METRIC, displayName);
		this.id = id;
		this.name = name;
	}
}
