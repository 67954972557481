import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { ObjectUtils } from '@app/util/object-utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { PredefinedMetricIcons } from '../icon/predefined-metric-icons';
import { ColorTypeMetrics, PredefinedMetricColorTypeDefinition } from './group-color-type.service';
import { MetricBandsService } from './metric-bands.service';
import { MetricThresholdRules } from './metric-threshold-rules.service';

export class CalculationColorTypeService {
	EASE_ICONS = ObjectUtils.copy(PredefinedMetricIcons.EASE_SCORE);
	EASE_THRESHOLD_RULES = MetricThresholdRules.getEaseRules();
	NUMERIC_BREAKDOWN_THRESHOLD_RULES = MetricThresholdRules.getNumericBreakdownCalculationRules();
	NUMERIC_BREAKDOWN_ICONS = PredefinedMetricIcons.NUMERIC_BREAKDOWN;
	SENTIMENT_ICONS: string[];
	SENTIMENT_THRESHOLD_RULES = MetricThresholdRules.getSentimentCalculationRules();
	NA_CATEGORY = {name: this.locale.getString('widget.na'), id: 'na', order: 5};

	types: {[key in ColorTypeMetrics]?: any};

	constructor(
		private readonly metricBandsService: MetricBandsService,
		private readonly locale: ILocale,
		private readonly betaFeaturesService: BetaFeaturesService
	) {
		const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
		this.SENTIMENT_ICONS = ObjectUtils.copy(PredefinedMetricIcons.getSentimentIcons(isMlSentimentEnabled));
		this.types = {
			SENTIMENT_3: new CalculationColorClass({
				name: PredefinedMetricConstants.SENTIMENT_3,
				rawName: PredefinedMetricConstants.SENTIMENT,
				getColorArray: (metric) => {
					let colors = ObjectUtils.copy(metric.definition.calculation.colorPalette);
					if (colors) {
						this.metricBandsService.removeInnerBands(colors);
					}
					return colors;
				},
				getColorFunction: (metric, field) => this.predefinedMetricColorFunction(metric, this.SENTIMENT_THRESHOLD_RULES, field, true),
				getCategoryFunction: (metric, field) => this.predefinedMetricCategoryFunction(metric, this.SENTIMENT_THRESHOLD_RULES, field, true),
				getDataForLegendItems: (metric) => this.predefinedMetricLegendItems(metric, true),
				getIconFunction: (metric, field) => this.predefinedIconFunction(metric, this.SENTIMENT_THRESHOLD_RULES, this.SENTIMENT_ICONS, field)
			}) as PredefinedMetricColorTypeDefinition,
			SENTIMENT_5: new CalculationColorClass({
				name: PredefinedMetricConstants.SENTIMENT_5,
				rawName: PredefinedMetricConstants.SENTIMENT,
				getColorArray: (metric) => metric.definition.calculation.colorPalette,
				getColorFunction: (metric, field) => this.predefinedMetricColorFunction(metric, this.SENTIMENT_THRESHOLD_RULES, field, false),
				getCategoryFunction: (metric, field) => this.predefinedMetricCategoryFunction(metric, this.SENTIMENT_THRESHOLD_RULES, field, false),
				getDataForLegendItems: (metric) => this.predefinedMetricLegendItems(metric),
				getIconFunction: (metric, field) => this.predefinedIconFunction(metric, this.SENTIMENT_THRESHOLD_RULES, this.SENTIMENT_ICONS, field)
			}) as PredefinedMetricColorTypeDefinition,
			TB: this.getStudioMetricColorFunctions(MetricType.TOP_BOX),
			BB: this.getStudioMetricColorFunctions(MetricType.BOTTOM_BOX),
			NPS: this.getStudioMetricColorFunctions(MetricType.SATISFACTION),
			FILTER: this.getStudioMetricColorFunctions(MetricType.FILTER),
			CUSTOM_MATH: this.getStudioMetricColorFunctions(MetricType.CUSTOM_MATH),
			EASE_3: new CalculationColorClass({
				name: PredefinedMetricConstants.EASE_3,
				rawName: PredefinedMetricConstants.EASE_SCORE,
				getColorArray: (metric) => {
					let colors = ObjectUtils.copy(metric.definition.calculation.colorPalette);
					if (colors) {
						this.metricBandsService.removeInnerBands(colors);
					}
					return colors;
				},
				getColorFunction: (metric, field) => this.predefinedMetricColorFunction(metric, this.EASE_THRESHOLD_RULES, field, true),
				getCategoryFunction: (metric, field) => this.predefinedMetricCategoryFunction(metric, this.EASE_THRESHOLD_RULES, field, true),
				getDataForLegendItems: (metric) => this.predefinedMetricLegendItems(metric, true),
				getIconFunction: (metric, field) => this.predefinedIconFunction(metric, this.EASE_THRESHOLD_RULES, this.EASE_ICONS, field)
			}) as PredefinedMetricColorTypeDefinition,
			EASE_5: new CalculationColorClass({
				name: PredefinedMetricConstants.EASE_5,
				rawName: PredefinedMetricConstants.EASE_SCORE,
				getColorArray: (metric) => metric.definition.calculation.colorPalette,
				getColorFunction: (metric, field) => this.predefinedMetricColorFunction(metric, this.EASE_THRESHOLD_RULES, field, false),
				getCategoryFunction: (metric, field) => this.predefinedMetricCategoryFunction(metric, this.EASE_THRESHOLD_RULES, field, false),
				getDataForLegendItems: (metric) => this.predefinedMetricLegendItems(metric),
				getIconFunction: (metric, field) => this.predefinedIconFunction(metric, this.EASE_THRESHOLD_RULES, this.EASE_ICONS, field)
			}) as PredefinedMetricColorTypeDefinition,
			EMOTION: this.getNumericBreakdownColorType(PredefinedMetricConstants.EMOTION_3,
				PredefinedMetricConstants.EMOTION) as PredefinedMetricColorTypeDefinition
		};
	}

	private colorFunction(field: string, colors: string[], thresholds: number[]): (item) => string {
		return (item) => {
			if (item) {
				let i;
				for (i = 0; i < thresholds.length; i++) {
					if (item[field] <= thresholds[i]) {
						return colors[i % colors.length];
					}
				}

				return colors[i % colors.length];
			}

			return colors[0];
		};
	}

	private studioMetricCategoryFunction(field, displayNames: string[], thresholds: number[]): (item) => any {
		return (item) => {
			if (item) {
				let i;
				for (i = 0; i < thresholds.length; i++) {
					if (item[field] <= thresholds[i]) {
						return {name: this.getCategoryName(displayNames, thresholds, i), id: `cal_color${i}`, order: i};
					}
				}

				return {name: this.getCategoryName(displayNames, thresholds, i), id: `cal_color${i}`, order: i};
			}
			return this.NA_CATEGORY;
		};
	}

	private getCategoryName(displayNames, thresholds: number[], index: number): string {
		if (displayNames && displayNames[index]) {
			return displayNames[index];
		}

		if (index === 0) {
			return `<= ${thresholds[index].toFixed(2)}`;
		} else if (index < thresholds.length) {
			return `${thresholds[index - 1].toFixed(2)} - ${thresholds[index].toFixed(2)}`;
		} else if (index === thresholds.length) {
			return `> ${thresholds[index - 1].toFixed(2)}`;
		}

		return this.locale.getString('widget.na');
	}

	private getStudioMetricColorFunctions(type): CalculationColorClass {
		return new CalculationColorClass({
			name: type,
			getColorArray: (metric) => ObjectUtils.copy(metric.definition.calculation.colorPalette),
			getColorFunction: (metric) => {
				let colors = metric.definition.calculation.colorPalette;
				let thresholds = metric.definition.calculation.thresholds;
				return this.colorFunction(metric.name, colors, thresholds);
			},
			getCategoryFunction: (metric) => {
				let thresholds = metric.definition.calculation.thresholds;
				let displayNames = metric.definition.calculation.displayNames;
				return this.studioMetricCategoryFunction(metric.name, displayNames, thresholds);
			},
			getDataForLegendItems: (metric) => {
				let thresholds = metric.definition.calculation.thresholds;
				let colors = metric.definition.calculation.colorPalette;
				let displayNames = metric.definition.calculation.displayNames;
				let legendItems = [];

				for (let i = 0; i <= thresholds.length; i++) {
					let categoryName = this.getCategoryName(displayNames, thresholds, i);
					let nameObject = {
						name: categoryName,
						id: `${metric.name}_${this.formatMetricThresholdName(categoryName)}`
					};
					legendItems.push({name: nameObject, color: colors[i]});
				}

				return {
					name: metric.name,
					values: legendItems
				};
			}
		});
	}

	private formatMetricThresholdName(name: string): string {
		return name.replace(/[^A-Za-z0-9_\-]/, '');
	}

	private predefinedMetricColorFunction(metric, rules, field: string, threeBands: boolean = false): (item) => string {
		field = field || metric.name;
		let calculation = metric.definition.calculation;
		let colorPalette = ObjectUtils.copy(calculation.colorPalette);
		let thresholds = ObjectUtils.copy(calculation.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);
		if (threeBands) {
			this.metricBandsService.removeInnerBands(colorPalette);
			this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);
		}

		return (item) => {
			if (item && _.isFinite(item[field])) {
				let value = item[field];
				let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
				return colorPalette[index];
			}
			return colorPalette[thresholds.length / 2];
		};
	}

	private predefinedIconFunction(attribute, rules, icons: string[], field?: string): (item) => string {
		let className = ObjectUtils.copy(icons);
		field = field || attribute.name;
		let definition = attribute.definition;
		let thresholds = ObjectUtils.copy(definition.calculation.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);

		this.metricBandsService.removeInnerBands(className);
		this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);

		return (item) => {
			if (item && _.isFinite(item[field])) {
				let value = item[field];
				let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
				return className[index];
			}
			return className[thresholds.length / 2];
		};
	}

	private predefinedMetricCategoryFunction(metric, rules, field: string, threeBands: boolean = false): (item) => any {
		field = field || metric.name;
		let calculation = metric.definition.calculation;
		let thresholds = ObjectUtils.copy(calculation.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);
		let displayNames = ObjectUtils.copy(metric.definition.displayNames);
		displayNames = _.map(displayNames, (name) => {
			return this.locale.getString(`metrics.${metric.definition.name}_${name}`);
		});

		if (threeBands) {
			this.metricBandsService.removeOuterBands(displayNames);
			this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);
		}

		return (item) => {
			if (item && _.isFinite(item[field])) {
				let value = item[field];
				let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
				return new Category(displayNames[index], index);
			}
			return this.NA_CATEGORY;
		};
	}

	private predefinedMetricLegendItems(metric, threeBands: boolean = false): {name: string, values: any[]} {
		let definition = metric.definition;
		let displayNames = ObjectUtils.copy(definition.displayNames);
		let colorPalette = ObjectUtils.copy(definition.calculation.colorPalette);
		if (threeBands) {
			// using inner names with outer colors
			this.metricBandsService.removeOuterBands(displayNames);
			this.metricBandsService.removeInnerBands(colorPalette);
		}

		let values = _.map(displayNames, (displayName, i) => {
			return {
				name: {
					name: this.locale.getString(`metrics.${definition.name}_${displayName}`),
					id: i
				},
				color: colorPalette[i]
			};
		});

		return { name: metric.name, values };
	}

	private getNumericBreakdownColorType(name: string, rawName: string): CalculationColorClass {
		return new CalculationColorClass({
			name,
			rawName,
			getColorArray: (metric) => ObjectUtils.copy(metric.definition.calculation.colorPalette),
			getColorFunction: (metric, field) => this.predefinedMetricColorFunction(metric, this.NUMERIC_BREAKDOWN_THRESHOLD_RULES, field, false),
			getCategoryFunction: (metric, field) =>
				this.predefinedMetricCategoryFunction(metric, this.NUMERIC_BREAKDOWN_THRESHOLD_RULES, field, false),
			getDataForLegendItems: (metric) => this.predefinedMetricLegendItems(metric, false),
			getIconFunction: (metric, field) => {
				return (item) => {
					return this.NUMERIC_BREAKDOWN_ICONS[rawName];
				};
			}
		});
	}

	typeOf = (name) => _.findWhere(_.values(this.types), { name });
}

class Category {
	name: string;
	id: string;
	order: number;

	constructor(name: string, order: number) {
		this.name = name;
		this.id = name;
		this.order = order;
	}
}

class CalculationColorClass {
	name: string;
	rawName?: string;
	getColorArray;
	getColorFunction;
	getCategoryFunction;
	getDataForLegendItems;
	getIconFunction;

	constructor(initObj) {
		this.name = initObj.name;
		this.rawName = initObj.rawName || initObj.name;
		this.getColorArray = initObj.getColorArray || _.noop;
		this.getColorFunction = initObj.getColorFunction || _.noop;
		this.getCategoryFunction = initObj.getCategoryFunction || _.noop;
		this.getDataForLegendItems = initObj.getDataForLegendItems || _.noop;
		this.getIconFunction = initObj.getIconFunction || _.noop;
	}
}

app.service('CalculationColorType', CalculationColorTypeService);
