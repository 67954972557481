import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QualtricsUserInfo } from '../qualtrics-embed-data';

@Component({
	selector: 'qualtrics-auth-error',
	styles: [`
		.message-panel { width: 400px; }
	`],
	template: `
		<div class="message-panel align-center text-center bg-white ph-16 pv-16">
			<alert type="danger" [dismissable]="false" [fullWidth]="true">{{ message }}</alert>
			<ng-container *ngIf="userInfo">
				<label class="mb-16" [i18n]="'error.logoutXmEngageMessage'" [i18nParams]="{
					firstName: userInfo.firstName,
					lastName: userInfo.lastName,
					brandId: userInfo.brandId
				}"></label>
				<button class="btn btn-primary" (click)="reload.emit()">{{ 'common.changeUser' | i18n }}</button>
			</ng-container>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsAuthErrorComponent implements OnInit {
	@Input() message: string;
	@Input() userInfo: QualtricsUserInfo;
	@Output() reload = new EventEmitter<void>();

	constructor(
	) {}

	ngOnInit(): void {
	}
}