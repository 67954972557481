import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import { BaseTreeRenderer } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/base-tree-renderer';
import { HierarchyTreeOptions } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/hierarchy-tree-options.class';
import { HorizontalTreeRenderer } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/horizontal-tree-renderer';
import { ITreeRenderer, ITreeSVG } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer.interface';
import { VerticalTreeRenderer } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/vertical-tree-renderer';

export interface ExtendedHierarchyNode extends d3.HierarchyNode<IDataPoint> {
	id: any;
	x0: number;
	y0: number;
	x: number;
	y: number;
	size: number;
	color: string;
	_children: ExtendedHierarchyNode[];
}

export interface HierarchyTreeDrillPoint extends DrillPoint {
	expand: () => void;
	expandAll: () => void;
	collapse: () => void;
}

export class HierarchyTreeRenderer {

	private gLink: ITreeSVG;
	private gNode: ITreeSVG;
	private svg: ITreeSVG;
	private gPatterns: ITreeSVG;
	private renderer: ITreeRenderer;

	constructor(
		private container: ng.IAugmentedJQuery,
		private root: ExtendedHierarchyNode,
		private isPatternFillEnabled: boolean,
		private isDarkMode: boolean) {
		this.container.find('svg').remove();
		this.svg = d3.select(this.container[0]).append('svg')
			.style('font', '12px sans-serif')
			.style('user-select', 'none') as ITreeSVG;
		
		this.gLink = this.svg.append('g')
			.classed('tree-links', true)
			.attr('fill', 'none')
			.attr('stroke', BaseTreeRenderer.LINE_COLOR)
			.attr('stroke-opacity', 0.4)
			.attr('stroke-width', 2);
		
		this.gNode = this.svg.append('g')
			//.attr('cursor', 'pointer')
			.attr('pointer-events', 'all');
		
		this.gPatterns = this.svg.append('g').attr('id', 'fill-patterns');
	}

	render(options: HierarchyTreeOptions): void {
		if (!this.renderer) {
			let classConstructor = options.orientation === DirectionalOrientation.HORIZONTAL ?
				HorizontalTreeRenderer :
				VerticalTreeRenderer;
			this.renderer = new classConstructor(
				options,
				this.container,
				this.root,
				this.svg,
				this.gNode,
				this.gLink,
				this.gPatterns,
				this.isPatternFillEnabled,
				this.isDarkMode);
		}

		this.renderer.renderNode(this.root);
	}

	scrollTo(node: ExtendedHierarchyNode): void {
		this.renderer.scrollTo(node);
	}

	scrollToRoot(): void {
		this.scrollTo(this.root);
	}

	redrawNode(node: ExtendedHierarchyNode): void {
		this.renderer.renderNode(node);
	}

	restrictHeight(): void {
		let fixedHeight = this.container[0].clientHeight;
		let viewBoxStr = this.svg.attr('viewBox');
		let viewBox = viewBoxStr.split(',');
		viewBox[viewBox.length - 1] = '' + fixedHeight;
		this.svg.attr('viewBox', viewBox.join(','));
		$(this.svg.node()).height(fixedHeight);
	}
}