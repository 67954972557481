<div
	[ngClass]="[getEmbedLocation(), 'w-100-percent h-100-percent']"
	[ngBusy]="validationLoading">
	<embedded-error-container *ngIf="hasError()"
		[errorType]="errorType"
		[message]="errorMessage"
		(reload)="init()">
	</embedded-error-container>
	<ng-container *ngIf="ready" [ngSwitch]="type">
		<embedded-dashboard-container [isEmbeddedInternally]="isEmbeddedInternally()" *ngSwitchCase="'DASHBOARD'"></embedded-dashboard-container>
		<embedded-widget-container *ngSwitchCase="'WIDGET'"></embedded-widget-container>
	</ng-container>
</div>
