import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';

export class PieWidget extends SimpleReportWidget {

	readonly DEFAULT_GROUPING_SIZE = 100;

	constructor(private metricConstants: MetricConstants) {
		super(WidgetType.PIE);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initSimpleReport(context);

		this.widget.visualProperties.visualization = WidgetVisualization.STACKEDPIE;

		super.inherit('visualProperties.sideBySide', context, true);
		super.inherit('visualProperties.donut', context, false);

		super.withLegendOptions(context, [
			{name: 'showLabels', default: false},
			{name: 'showLegend', default: false}
		]);

		super.initGroupings(context, 2, false);
		super.initSingleCalculation(context, this.metricConstants.get().VOLUME, this.filterValidCalculations);
	}

	private filterValidCalculations(calculations: ReportCalculation[]): ReportCalculation[] {
		return _.filter(calculations, calculation => calculation?.name !== StandardMetricName.CONSTANT_SIZE);
	}
}
