import { Inject, OnInit, ChangeDetectorRef, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import {
	ASTInclusionListUtilsService
} from '@app/modules/alert-subscription-template/services/ast-inclusion-list-utils.service';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';

export interface TuningSuggestionsModels {
	allModels: IReportModel[];
	selectedModels: IReportModel[];
}

export interface TuningSuggestionsModelsUpdate {
	added?: {name: string, id: number}[];
	removed?: {name: string, id: number}[];
}

@Component({
	selector: 'topics-tuning-suggestions',
	templateUrl: './topics-tuning-suggestions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicsTuningSuggestionsComponent implements OnInit {

	@Input() disabled: boolean;

	projectSelection: IProjectPreselection;
	projectsLoading: Promise<any>;
	showErrorsForCP: string[];
	contentProviderErrors: string[];
	isWorkspaceEnabled: boolean;
	workspaceProject: WorkspaceProject;

	modelsLoading: any;
	storedSelectedModels: IReportModel[];
	availableModels: IReportModel[];
	selectedModels: IReportModel[];
	hasChangesInModelsList: boolean;
	selectedModelsLabelCallback: (items: any, highlightedAvailableItems: any, highlightedSelectedItems: any, itemsLimit?: number) => string;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
		@Inject('astInclusionListUtils') private readonly astInclusionListUtils: ASTInclusionListUtilsService,
		private readonly betaFeaturesService: BetaFeaturesService
	) {}

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.selectedModelsLabelCallback = this.astInclusionListUtils.selectedModelsLabelCallback;

		this.initProjectSelection();
		this.loadModels();
	}

	updateSelectedModels = (models: IReportModel[]): void => {
		this.selectedModels = models;
		this.setChangesInModelsList();
	}

	private setChangesInModelsList = (): boolean => {
		if (!this.storedSelectedModels || !this.selectedModels) {
			return;
		}

		let itemsAddedToSelected: IReportModel[] = this.getSelectedModelsDifference(this.storedSelectedModels, this.selectedModels);
		let itemsRemovedFromSelected: IReportModel[] = this.getSelectedModelsDifference(this.selectedModels, this.storedSelectedModels);

		this.hasChangesInModelsList = itemsAddedToSelected.length > 0 || itemsRemovedFromSelected.length > 0;
	}

	private loadModels(): void {
		const projectIdentifier: AccountOrWorkspaceProject = this.getProjectIdentifier();
		const isWorkspaceProject = WorkspaceTransitionUtils.isWorkspaceProject(projectIdentifier)
				&& WorkspaceProjectUtils.isProjectSelected(projectIdentifier);

		if (ProjectIdentifier.isProjectSelected(projectIdentifier) || isWorkspaceProject) {
			this.modelsLoading = this.masterAccountApiService.getTuningSuggestionsModels(projectIdentifier);

			this.modelsLoading.then((tuningSuggestionsModels: TuningSuggestionsModels) => {
				this.availableModels = tuningSuggestionsModels.allModels;

				this.storedSelectedModels = tuningSuggestionsModels.selectedModels;
				this.selectedModels = tuningSuggestionsModels.selectedModels;

				this.processModels();
			});
		} else {
			this.storedSelectedModels = [];

			this.selectedModels = [];
			this.availableModels = [];
		}
	}

	private processModels(): void {
		this.availableModels.forEach(model => {
			// used in inclusion-list
			model.hide = false;
			model.displayName = model.name;

		});

		this.selectedModels.forEach(selectedModel => {
			selectedModel.selected = true;
			// used in inclusion-list
			selectedModel.hide = false;
			selectedModel.displayName = selectedModel.name;
		});
	}

	private initProjectSelection(): void {
		this.projectSelection = [] as IProjectPreselection;
		let favoriteProperties: FavoriteProperties = this.security.getContext().properties;
		if (favoriteProperties) {
			this.projectSelection.cbContentProvider = favoriteProperties.favoriteCP;
			this.projectSelection.cbAccount = favoriteProperties.favoriteAccount;
			this.projectSelection.project = favoriteProperties.favoriteProject;
		}
	}

	onProjectSelectionChange = (projectSelection: IProjectPreselection): void => {
		this.projectSelection.cbContentProvider = projectSelection.cbContentProvider;
		this.projectSelection.cbAccount = projectSelection.cbAccount;
		this.projectSelection.project = projectSelection.project;

		this.loadModels();
	}

	private getProjectIdentifier(): AccountOrWorkspaceProject {
		if (this.isWorkspaceEnabled) {
			return this.workspaceProject;
		}

		return {
			contentProviderId: this.projectSelection.cbContentProvider,
			accountId: this.projectSelection.cbAccount,
			projectId: this.projectSelection.project
		};
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.projectsLoading = loadingPromise;
		this.ref.markForCheck();
	}

	onCpErrorsChange = (errors: string[]) => {
		this.contentProviderErrors = errors;
	}

	getAvailableModelsTextCallback = (): string => this.locale.getString('docExplorer.selectModels');

	getAvailableItemsTooltip = (): string => this.locale.getString('cases.tuningSuggestionsSelectModelsTooltip');

	saveChanges = (): void => {
		let addedToSelectedModels: IReportModel[] = this.getSelectedModelsDifference(this.storedSelectedModels, this.selectedModels);
		let removedFromSelectedModels: IReportModel[] = this.getSelectedModelsDifference(this.selectedModels, this.storedSelectedModels);

		if (addedToSelectedModels.length > 0 || removedFromSelectedModels.length > 0) {
			let modelsUpdate: TuningSuggestionsModelsUpdate = {
				added: _.map(addedToSelectedModels, model => _.pick(model, 'id', 'name')),
				removed: _.map(removedFromSelectedModels, model => _.pick(model, 'id', 'name')),
			};
			this.modelsLoading =
				this.masterAccountApiService.bulkSaveTuningSuggestionsModelsSettings(this.getProjectIdentifier(), modelsUpdate);
		}

		this.storedSelectedModels = this.selectedModels;
		this.hasChangesInModelsList = false;
	}

	private getSelectedModelsDifference(sourceModels: IReportModel[], modelsToFind: IReportModel[]): IReportModel[] {
		let absentModels: IReportModel[] = [];

		modelsToFind.forEach(modelToFind => {
			let sourceModel: IReportModel = _.findWhere(sourceModels, {id: modelToFind.id});
			if (!sourceModel) {
				absentModels.push(modelToFind);
			}
		});

		return absentModels;
	}

	revertChanges = (): void => {
		this.availableModels.forEach(availableModel => availableModel.selected = false);
		this.selectedModels = this.storedSelectedModels;
		this.hasChangesInModelsList = false;
	}

	onWorkspaceChange = (workspace: Unit): void => {
		if (workspace) {
			this.projectSelection.cbContentProvider = workspace.contentProviderId;
			this.projectSelection.cbAccount = workspace.accountId;
		} else {
			this.projectSelection.cbContentProvider = -1;
			this.projectSelection.cbAccount = -1;
		}
	}

	onProjectChange = (workspaceProject: WorkspaceProject): void => {
		this.workspaceProject = workspaceProject;
		this.projectSelection.project = workspaceProject.projectId;

		this.loadModels();
	}
}
