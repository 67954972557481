import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { Unit } from '@app/modules/units/unit';
import { UserWorkspacesApi } from '@app/modules/units/workspace-project-selector/user-workspaces.api.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';


@Injectable({
	providedIn: 'root'
})
export class UserWorkspacesService {

	constructor(
		private readonly userWorkspacesApi: UserWorkspacesApi,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('adminProjectsService') private readonly adminProjectsService: AdminProjectsService,
	) {}

	getUserWorkspaces(): Promise<Unit[]> {
		return this.userWorkspacesApi.getUserWorkspaces();
	}

	getWorkspaceProjects(workspaceId: number): Promise<Project[]> {
		return this.userWorkspacesApi.getWorkspaceProjects(workspaceId);
	}

	getInternalProjects(): Project[] {
		let projects: Project[] = [];
		if (this.authorization.hasStudioAdminProjectAccess()) {
			let studioProject = this.adminProjectsService.getStudioAdminProject();
			projects.push({
				id: studioProject.projectId,
				name: studioProject.title,
			});
		}
		return projects;
	}
}
