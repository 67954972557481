import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { BasePagingMultiselect } from './base-paging-multiselect.controller';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { AttributeValueSearcher, SearchedAttribute, SearchTermsOptions, SearchTermsResult } from '@cxstudio/dashboards/attribute-value-searcher.service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { AttributeValueOption, IMultiselectConfiguration } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { MultiselectUtils } from '@app/modules/filter-builder/attribute/multiselect/multiselect-utils';
import { PagingSearchFactory } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

export enum PagingSearchState {
	FIRST_SEARCH = 'FIRST_SEARCH',
	FIRST_LOAD_MORE = 'FIRST_LOAD_MORE',
	REGULAR_LOAD_MORE = 'REGULAR_LOAD_MORE',
	NO_MORE_DATA = 'NO_MORE_DATA'
}

export class PagingMultiselect extends BasePagingMultiselect {
	private contentProviderId: number;
	private accountId: number;
	private projectId: number;
	private userEmail: string;
	useExternalSearch = true;
	hideCheckbox = true;
	hideSelections = true;

	attributes: SearchedAttribute[];
	selection: DashboardFilter;
	color?: string;

	disableSelection: (item, modelValue) => boolean;

	private onlyExactMatch = true;
	multiselectConfig: Partial<IMultiselectConfiguration>;

	constructor(
		private attributeValueSearcher: AttributeValueSearcher,
		private $scope: ng.IScope,
		filterUtils: FilterUtils,
	) {
		super(filterUtils);
	}

	$onInit(): void {
		this.pagingSearch = new PagingSearchFactory({
			doQuery: this.attributeValueSearcher.searchTerms,
			buildTypeSpecificOptions: this.buildBasicSearchTermsOptions,
			populateValues: this.populateValues
		});

		if (!this.useExternalSearch) {
			this.$scope.$watchCollection(() => this.attributes, () => {
				this.pagingSearch.resetSearch();
				if (!_.isEmpty(this.attributes))
					this.pagingSearch.searchTerms('');
			});
		}

		this.multiselectConfig = {
			showAttributeName: this.attributes?.length > 1,
		};
	}

	private populateValues = (result: SearchTermsResult): void => {
		this.selection.attrValues = this.selection.attrValues || [];
		if (this.pagingSearch.searchState === PagingSearchState.FIRST_SEARCH) {
			this.selection.attrValues = [];
		}

		this.selection.multiValues = this.selection?.multiValues ?
			this.selection.multiValues.filter((value: AttributeValueOption) => !this.onlyExactMatch || !value.special) :
			[];

		let resultValues: AttributeValueOption[] = this.onlyExactMatch ? [] : this.attributeValueSearcher.getDefaultAttributeOptions();

		if (this.pagingSearch.searchState !== PagingSearchState.FIRST_SEARCH) {
			resultValues.pushAll(this.getExistingValues());
			resultValues.forEach(value => value.newValue = false);
		}

		if (result.data.length > 0) {
			this.populateNewValues(resultValues, result.data);
		}

		resultValues = this.attributeValueSearcher.updateSelectedList(resultValues, this.selection.multiValues, false);
		this.selection.attrValues = resultValues;
		this.selection.nestedList = resultValues;

		if (this.externalSearch) {
			let nonSelected = _.filter(resultValues, MultiselectUtils.optionNotSelected);
			this.externalOpen = !nonSelected.isEmpty();
		}
	}

	private getExistingValues = (): AttributeValueOption[] => {
		return this.selection.attrValues.filter(value => !value.special);
	}

	private populateNewValues = (result: AttributeValueOption[], values: AttributeValueOption[]): void => {
		let populatedValues = this.selection.attrValues;
		let populatedValuesDisplayNames = populatedValues.map(value => value.displayName);

		let newValues = values.filter(value => !populatedValuesDisplayNames.contains(value.displayName));
		result.pushAll(newValues);

		this.pagingSearch.setHasDottedItems(false);

		if (this.pagingSearch.searchState === PagingSearchState.FIRST_LOAD_MORE) {
			this.pagingSearch.markDottedItemsOld(result, newValues);
		}
	}

	private buildBasicSearchTermsOptions = (query: string): SearchTermsOptions => {
		let isNumeric = _.size(this.attributes) === 1 && this.attributes[0]?.type === FilterAttributeTypes.NUMBER;
		let project: IProjectSelection = {
			contentProviderId: this.contentProviderId,
			accountId: this.accountId,
			projectId: this.projectId
		};
		return {
			project,
			userEmail: this.userEmail,
			attributes: this.attributes,
			isNumeric,
			filter: query
		} as SearchTermsOptions;
	}

	onChangeInternal = (): void => {
		if (this.onSelectionChange) this.onSelectionChange();
	}
}

app.component('oldPagingMultiselect', {
	controller: PagingMultiselect,
	bindings: {
		contentProviderId: '<',
		accountId: '<',
		projectId: '<',
		userEmail: '<',
		selection: '<',
		attributes: '<',
		color: '<?',
		disableSelection: '<',
		dropdownContainer: '<?',
		onSelectionChange: '&?',
		onNodeClick: '&?',
		externalSearch: '<?'
	},
	transclude: true,
	templateUrl: 'partials/components/multiselect/old-paging-multiselect.component.html',
});
