<header class="header-color-2 p-relative header-index">
	<div class="d-flex justify-between flex-nowrap page-content-header pl-16 pr-16">
		<div class="d-flex justify-between flex-nowrap pl-8 overflow-hidden">
			<dashboard-view-filters
				class="pr-8 d-flex align-items-center"
				[toggle]="getToggleType()"
				[dashboard]="dashboard"
				[dashboardHistory]="dashboardHistory"
				[personalization]="personalization"
				[embedded]="true"
				[isEmbeddedInternally]="isEmbeddedInternally"
				[truncate]="!isEmbeddedInternally"
			></dashboard-view-filters>
			<h1 *ngIf="!isEmbeddedInternally" class="text-1_5rem pv-8">{{ dashboard.name }}</h1>
		</div>
		<div class="d-flex align-center">
			<snapshot-date *ngIf="dashboard.snapshotMetadata"
				[date]="dashboard.snapshotMetadata.createdDate">
			</snapshot-date>
			<fullscreen-button
				*ngIf="!isEmbeddedInternally"
				class="br-book-fullscreen-button"
				[ariaName]="dashboard.name">
			</fullscreen-button>
			<actions-menu
				[menuItems]="menuItems"
				[dashboard]="dashboard"
				[zoom]="layout.zoomLevel"
			></actions-menu>
		</div>
	</div>
	<div *ngIf="!isEmbeddedInternally && getAppliedFiltersWithValue().length > 0"
		 class="d-flex justify-between flex-nowrap page-content-header pl-24 h-32
		 filters-bar-compact font-size-3 font-bold">
		<applied-dashboard-filters-list
			class="text-ellipsis pv-8"
			[embedded]="true"
			[truncate]="truncate"
			[filterLabel]="getFilterLabel()"
			[hasHierarchy]="hasHierarchy()"
			[hierarchyLabel]="getHierarchyLabel()"
			[appliedFiltersWithValue]="getAppliedFiltersWithValue()"
			[projectTimezone]="projectTimezone"
		></applied-dashboard-filters-list>
	</div>
</header>
