<ngb-accordion [closeOthers]="true">
	<ngb-panel *ngFor="let state of kafkaState" 
		[type]=getStatePanelType(state)>
		<ng-template ngbPanelHeader>
			<button ngbPanelToggle class="btn btn-link collapsed">
				{{state.contentProvider.name}}
			</button>
		</ng-template>
		<ng-template ngbPanelContent>
			<formatted-table
				[columns]="kafkaStatusColumns"
				[rows]="state.metrics"
			></formatted-table>
		</ng-template>
	</ngb-panel>
</ngb-accordion>
