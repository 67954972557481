import { SigningAlgorithm } from './saml-signinig-algorithms';

export class SamlServiceProvider {
	id: string;

	enabled: boolean;

	entityId: string;
	aliasName: string;
	entityBaseURL: string;
	description: string;

	signMetadata: boolean = false;
	signingAlgorithm: SigningAlgorithm = SigningAlgorithm.SHA_1;

	requestSigned: boolean = true;
	wantAssertionSigned: boolean = true;

	requireLogoutRequestSigned: boolean = true;
	requireLogoutResponseSigned: boolean = false;

	createdDate: Date;
	lastModifiedDate: Date;

	keyPair: KeyPairInfo = new KeyPairInfo();
}

export class KeyPairInfo {
	filename: string;
	password: string;
}