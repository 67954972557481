import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

interface HasType {
	type: string;
}

export interface UniqueNameValidatorConfig {
	nameField: string;
	folder: boolean;
	strict: boolean;
}

export class UniqueNameValidator<T extends object | HasType> {
	private names: string[];
	private initialName: string;
	private initialValid: boolean;

	constructor(
		initialItem: T,
		itemList: T[],
		options?: Partial<UniqueNameValidatorConfig>
	) {
		const config = _.extend(UniqueNameValidator.getUniqueNameValidatorDefaultConfig(), options);
		this.initialName = initialItem[config.nameField];
		this.names = this.listNames(itemList, config);
		this.initialValid = !config.strict || this.isInitialUnique();
	}

	private static getUniqueNameValidatorDefaultConfig(): UniqueNameValidatorConfig {
		return {
			nameField: 'name',
			folder: false,
			strict: false
		};
	}

	get(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const valid = !control.value || this.isUniqueName(control.value.trim());
			return valid ? null : { unique: true };
		};
	}

	private listNames(itemList: T[], config: UniqueNameValidatorConfig): any[] {
		return _.chain(itemList)
			.filter((item) => {
					return this.hasType(item)
							? config.folder === item.type.toLowerCase().endsWith('folder')
							: true;
			})
			.pluck(config.nameField)
			.filter(item => !!item)
			.value();
	}

	isUniqueName(value: string): boolean {
		return this.names.indexOf(value) === -1
			|| this.isValidInitialName(value);
	}

	private isValidInitialName(value: string): boolean {
		return value === this.initialName && this.initialValid;
	}

	private isInitialUnique(): boolean {
		return this.names.indexOf(this.initialName) === this.names.lastIndexOf(this.initialName);
	}

	private hasType(asset: object | HasType): asset is HasType {
		return !_.isUndefined((asset as HasType).type);
	}
}
