import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable, Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { Interval } from '@cxstudio/interval/interval.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { RandomUtils } from '@app/util/random-utils.class';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	readonly REFRESH_TOKEN_INTERVAL_NAME = 'refreshAccessTokenInterval';
	readonly MIN_TIME_OFFSET = 10;
	readonly MAX_TIME_OFFSET = 60;

	getTokenPromise: Promise<any>;

	constructor(
		@Inject('security') private security: Security,
		@Inject('interval') private interval: Interval,
		@Inject('securityApiService') private securityApiService: SecurityApiService
	) {
	}

	startRefreshAccessTokenWatcher = (expiresIn: number) => {
		if (expiresIn > this.MAX_TIME_OFFSET) {
			let timeOffset = RandomUtils.randomIntInclusive(this.MIN_TIME_OFFSET, this.MAX_TIME_OFFSET);
			this.interval.registerInterval(
				this.REFRESH_TOKEN_INTERVAL_NAME, this.refreshAccessToken, (expiresIn - timeOffset) * 1000, true);
		}
	}

	refreshAccessToken = (): void => {
		this.getAccessToken().then(response => {
			if (response.token) {
				this.security.setAccessToken(response.token);
			}
		});
	}

	getAccessToken = (): Promise<any> => {
		if (!this.getTokenPromise) {
			this.getTokenPromise = this.getAccessTokenPromise();
		}

		return this.getTokenPromise;
	}

	private getAccessTokenPromise = (): Promise<any> => {
		let deferred = PromiseUtils.defer<any>();

		this.securityApiService.getAccessToken().then((response) => {
			let token = response.data.token;
			let expiresIn = response.data.expiresIn;
			let userEmail = response.data.userEmail;

			if (expiresIn) {
				this.startRefreshAccessTokenWatcher(expiresIn);
			}

			this.getTokenPromise = null;
			if (token) {
				deferred.resolve({token, location, userEmail});
			} else {
				deferred.reject();
			}
		}, () => {
			this.getTokenPromise = null;
			deferred.reject();
		});

		return deferred.promise;
	}



	getAccessTokenWithRedirect = (redirect: boolean = false): Promise<any> => {
		let deferred = PromiseUtils.defer<any>();

		this.securityApiService.getAccessToken(redirect).then((response) => {
			let token = response.data.token;
			let samlSession = response.data.samlSession;
			let externalOAuthSession = response.data.externalOAuthSession;
			let expiresIn = response.data.expiresIn;
			let location = response.data.studioStartupAppUrl;

			if (expiresIn) {
				this.startRefreshAccessTokenWatcher(expiresIn);
			}

			if (token && !_.isUndefined(token)) {
				deferred.resolve({token, location, samlSession, externalOAuthSession});
			} else {
				deferred.reject();
			}
		}, () => {
			deferred.reject();
		});

		return deferred.promise;
	}

}

app.service('authenticationService', downgradeInjectable(AuthenticationService));
