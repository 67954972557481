import { TypeFilterCriteria } from '@app/modules/object-list/default-objects-table/default-objects-table.component';
import { RowNode } from 'ag-grid-enterprise';
import { Observable, Subject } from 'rxjs';

interface TableFilterKeywords {
	labels?: string;
}

export class TableFilterManager {
	private filterChangeSubject = new Subject<void>();

	private supportHidden = false;
	private showHidden = false;

	private typeFilter?: TypeFilterCriteria[];
	private filterText: string;

	private keywords?: TableFilterKeywords;
	private nameField = 'name';

	withHiddenSupport(): TableFilterManager {
		this.supportHidden = true;
		return this;
	}

	withTypeFilter(typeFilter: TypeFilterCriteria[]): TableFilterManager {
		this.typeFilter = typeFilter;
		return this;
	}

	withKeywords(keywords: TableFilterKeywords): TableFilterManager {
		this.keywords = keywords;
		return this;
	}

	getFilterChangeObservable(): Observable<void> {
		return this.filterChangeSubject.asObservable();
	}

	isShowHidden(): boolean {
		return this.showHidden;
	}

	setShowHidden(showHidden: boolean): void {
		this.showHidden = showHidden;
		this.onFilterChange();
	}

	toggleHidden(): void {
		this.showHidden = !this.showHidden;
		this.onFilterChange();
	}

	setFilterText(filterText: string) {
		this.filterText = filterText;
		this.onFilterChange();
	}

	hasFilter(): boolean {
		return this.hasHiddenFilter() || this.hasTypeFilter() || this.hasKeywordFilter();
	}

	doesPassFilter(node: RowNode): boolean {
		return this.doesPassHiddenFilter(node)
			&& this.doesPassTypeFilter(node)
			&& this.doesPassKeywordFilter(node);
	}

	getQuickFilter(): string {
		return this.hasTypeFilter() || this.hasKeywordFilter() ? null : this.filterText;
	}

	private onFilterChange(): void {
		this.filterChangeSubject.next();
	}

	private hasHiddenFilter(): boolean {
		return this.supportHidden;
	}

	private doesPassHiddenFilter(node: RowNode) {
		if (!this.hasHiddenFilter()) {
			return true;
		}
		return this.showHidden || !node.data.hide;
	}

	private hasTypeFilter(): boolean {
		return !!this.filterText
			&& !!this.typeFilter
			&& _.some(this.typeFilter, criteria => criteria.searchFilterValue === this.filterText);
	}

	private doesPassTypeFilter(node: RowNode) {
		if (!this.hasTypeFilter()) {
			return true;
		}
		let item = node.data;
		return !!_.findWhere(this.typeFilter, { searchFilterValue: this.filterText, itemType: item.type });
	}

	private hasKeywordFilter(): boolean {
		return !!this.filterText
			&& !!this.keywords
			&& _.values(this.keywords)
				.map(keyword => keyword.toLowerCase())
				.contains(this.filterText.toLowerCase());
	}

	private doesPassKeywordFilter(node: RowNode) {
		if (!this.hasKeywordFilter()) {
			return true;
		}
		let item = node.data;
		if (this.filterText.toLowerCase() === this.keywords?.labels.toLowerCase() && !_.isEmpty(item.labels)) {
			return true;
		}
		return item[this.nameField]?.toLowerCase().includes(this.filterText.toLowerCase());
	}
}
