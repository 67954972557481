import { ProjectSelectorController } from '@cxstudio/components/project-selector.component';

app.component('mobileProjectSelector', {
	controller: ProjectSelectorController,
	templateUrl: 'partials/mobile/mobile-project-selector-component.html',
	bindings: {
		loading: '<',
		allProjects: '<?',
		noProjects: '<?',
		preselected: '<?',
		disable: '<?',
		projectsProvider: '<?',
		onAccountChange: '&?',
		onProjectChange: '&?',
		onProjectSelectionFinished: '&?',
		alwaysShowSelectors: '<?',

		// mobile specific settings
		includePostConfiguration: '<?',
		promises: '<?',
		hideProjects: '<?',
		settings: '<?',
		canConfigureProject: '<?',
		errors: '='
	}
});
