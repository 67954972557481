import { IConfig } from '@cxstudio/config';
import { Injectable } from '@angular/core';
import { CxHttpService } from '.';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	promise: Promise<IConfig>;

	constructor(
		private readonly http: CxHttpService
	) {}

	getConfig(): IConfig {
		return CONFIG;
	}

	isConfigLoaded(): boolean {
		return !!CONFIG.sso;
	}

	loadServerConfig = (): Promise<void> => {
		if (this.isConfigLoaded()) {
			return Promise.resolve();
		}

		return this.getServerProperties().then((props) => {
			_.each(props, (value: any, key: string) => {
				if (CONFIG[key] !== undefined) {
					$.extend(CONFIG[key], value); // to not override defaults
				} else {
					CONFIG[key] = value;
				}
			});
		});
	}

	getServerProperties = (): Promise<IConfig> => {
		if (this.promise) {
			return this.promise;
		}
		this.promise = this.http.get('rest/config/properties');
		return this.promise;
	}

	getSidebarConfig = (): Promise<any> => this.http.get('rest/config/sidebar');
}

app.service('configService', downgradeInjectable(ConfigService));
