import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackRendererType } from '../secondary-track-renderer-type.enum';
import { ConversationChartOptions } from '@cxstudio/conversation/conversation-chart-options.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { SpineTooltipManager } from '@cxstudio/conversation/spine-tooltip-manager.class';

export abstract class SecondaryTrackConfig {
	enrichment: ConversationEnrichment;
	leftBoundary?: number;
	shape: SecondaryTrackRendererType = SecondaryTrackRendererType.CIRCLE;
	shapeWidth: number = 8;
	trackWidth: number = 21;
	tooltipManager: SpineTooltipManager;
	config: ConversationChartOptions;

	dataFilter: (dataPoint: ConversationDataPoint, i?: number) => boolean;

	constructor(enrichment: ConversationEnrichment, config: ConversationChartOptions, tooltip: SpineTooltipManager) {
		this.shape = enrichment.iconType;
		this.enrichment = enrichment;
		this.tooltipManager = tooltip;
		this.config = config;
	}

	getPointClass = (data) => {
		return `${data.isFiltered ? 'filtered' : ''} ${ConversationStyleUtils.getSecondaryTrackClass(this.enrichment.getColorIndex(data))}`;
	}

	mouseover = (event: MouseEvent, dataPoint: ConversationDataPoint): void => {
		if (this.config.getTooltip) {
			this.tooltipManager.showTooltip(this.config.getTooltip(dataPoint, [this.enrichment]), this.config.theme, event);
		}
	}

	mouseout = (): void => {
		this.tooltipManager.hideTooltip();
	}

	click = (event: MouseEvent, dataPoint: ConversationDataPoint): void => {
		if (dataPoint?.events?.click)
			dataPoint.events.click();
	}

}
