import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';

@Component({
	selector: 'favorite-item-icon',
	template: `
	<button *ngIf="favoritesPanel && !disableFavorites"
		class="text-gray-900 cursor-pointer q-icon-delete no-border no-background kb-focusable"
		(click)="onClick.emit()"
		[attr.aria-label]="getDeleteBtnAriaLabel()"></button>
	<button *ngIf="!favoritesPanel && !disableFavorites"
		class="cx-favorite-icon text-gray-900 cursor-pointer no-border no-background p-8 kb-focusable"
		[class.q-icon-star]="favoriteId | inArray:favoriteAttributes"
		[class.q-icon-star-outline]="favoriteId | inArray:favoriteAttributes | negate"
		(click)="onClick.emit()"
		[attr.aria-label]="getStarBtnAriaLabel()"></button>
	<span *ngIf="!favoritesPanel && disableFavorites"
		class="cx-favorite-icon text-gray-900 no-border no-background kb-focusable"
		[class.q-icon-star]="favoriteId | inArray:favoriteAttributes"></span>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FavoriteItemIconAttributeComponent {

	@Input() favoriteId: FavoriteId;
	@Input() favoriteDisplayName: string;
	@Input() favoriteAttributes: FavoriteId[];
	@Input() disableFavorites: boolean;
	@Input() favoritesPanel: boolean;
	@Output() onClick = new EventEmitter();

	constructor(
		private locale: CxLocaleService
	) {}

	getDeleteBtnAriaLabel(): string {
		return this.locale.getString('preview.deleteFromKeyInfo', {displayName: this.favoriteDisplayName});
	}

	getStarBtnAriaLabel(): string {
		let elementInFavorites: FavoriteId =_.find(this.favoriteAttributes, favoriteAttribute => {
			return _.isEqual(favoriteAttribute, this.favoriteId);
		});

		return _.isUndefined(elementInFavorites)
			? this.locale.getString('preview.starItem', {displayName: this.favoriteDisplayName})
			: this.locale.getString('preview.unstarItem', {displayName: this.favoriteDisplayName});
	}

}
