import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsiblePillsComponent } from '@app/modules/pills/collapsible-pills/collapsible-pills.component';
import { EnrichmentPillComponent } from '@app/modules/pills/enrichment-pill/enrichment-pill.component';
import { SharedModule } from '@app/shared/shared.module';
import { SentencePillComponent } from './collapsible-pills/sentence-pill.component';
import { ContextPillComponent } from './collapsible-pills/context-pill.component';

@NgModule({
	exports: [
		CollapsiblePillsComponent,
		EnrichmentPillComponent,
		SentencePillComponent,
		ContextPillComponent,
	],
	declarations: [
		CollapsiblePillsComponent,
		EnrichmentPillComponent,
		SentencePillComponent,
		ContextPillComponent,
	],
	imports: [
		CommonModule,
		SharedModule
	]
})
export class PillsModule { }
