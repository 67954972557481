<div *ngIf="!isEditing()">
	<p [i18n]="'homePage.tabTitle'" class="font-bold"></p>
	<p [i18n]="'homePage.tabDescription'"></p>
	<div class="grid-list-tools" [ngBusy]="loading">
		<div class="tools">
			<label class="font-bold mb-0">
				{{'homePage.searchPlaceholder'|i18n}}
				<input
					type="text"
					[disabled]="!homePages?.length"
					class="grid-search-bar d-block"
					attr.aria-label="{{'homePage.searchPlaceholder'|i18n}}"
					[(ngModel)]="nameSearch">
			</label>
			<div class="grid-buttons">
				<button
					class="btn btn-primary"
					[disabled]="!homePages?.length"
					(click)="createHomePage()">
					{{'homePage.newHomePage'|i18n}}
				</button>
			</div>
		</div>
	</div>

	<div [ngBusy]="loading">
		<item-grid
			class="h-100-percent w-100-percent br-grid"
			[treeData]="homePages"
			[gridOptions]="gridOptions"
			[gridFilter]="nameSearch"
			[gridType]="gridType"
			[gridChange]="changedItems">
		</item-grid>
	</div>
</div>
<home-page-edit *ngIf="isEditing()"
	[homePage]="selectedHomePage"
	[allItems]="homePages"
	(onCancel)="cancelEdit()"
	(onSave)="saveHomePage($event)"
></home-page-edit>
