import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { SortMetric } from '@cxstudio/reports/entities/sort-metric';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { GenericFormatterService, IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { MetricBoundsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/metric-bounds-utils.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { MetricWidgetRenderingService } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { Metric } from '@cxstudio/metrics/entities/metric.class';

@Injectable({
	providedIn: 'root'
})
export class WidgetDescriptionUtils {

	constructor(
		private locale: CxLocaleService,
		private readonly formatter: GenericFormatterService,
		@Inject('widgetUtilsService') private widgetUtilsService: WidgetUtilsService,
		@Inject('DateRange') private DateRange,
		private readonly highchartsUtils: HighchartsUtilsService,
		private readonly metricBoundsUtils: MetricBoundsUtilsService,
		private readonly metricWidgetRendering: MetricWidgetRenderingService,
	) { }

	getDateRangeName(filterMode: string): string {
		return this.DateRange.valueOf(filterMode).displayName;
	}

	getWidgetUtils(widget: Widget): Promise<WidgetUtils> {
		return this.widgetUtilsService.getBuilder(widget).build() as Promise<WidgetUtils>;
	}

	getTotal(visual: VisualProperties, data?: any): string {
		let total = data?.total?.volume;
		return visual.showSampleSize && total >= 0
			? this.locale.getString('widgetDescription.withTotal', { total })
			: '';
	}

	getPaletteDescription(key: string, name: string, displayType?: string): string {
		if(CalculationColorUtils.isCalculationColor(name)){
			let attribute = CalculationColorUtils.getCalculationColorAttributeName(name);
			if (Object.values(PredefinedMetricConstants.valueOf()).contains(attribute)) {
				let color = this.locale.getString(`metrics.${attribute}`);
				return this.locale.getString(`widgetDescription.${key}`, { color, displayType });
			}
		}
		return '';
	}

	getPreviewSort(sortMetric: SortMetric): string {
		let direction;
		let sort;
		if (sortMetric.displayName.contains('ease') || sortMetric.displayName.contains('sentiment')) {
			sort = sortMetric.displayName.contains('ease')
				? this.locale.getString('widgetDescription.effort')
				: this.locale.getString('widgetDescription.sentiment');
			direction = sortMetric.direction === AnSortDirection.ASC
				? this.locale.getString('widgetDescription.lth')
				: this.locale.getString('widgetDescription.htl');
		} else {
			sort = this.locale.getString('widgetDescription.date');
			direction = sortMetric.direction === AnSortDirection.ASC
				? this.locale.getString('widgetDescription.otn')
				: this.locale.getString('widgetDescription.nto');
		}
		return `${sort} ${direction}`;
	}

	getGroupingType(type: string): string {
		if (ReportAssetType.NUMBER === type || ReportAssetType.TEXT === type) {
			return this.locale.getString('widgetDescription.values');
		}
		if (ReportAssetType.TOPICS === type) {
			return this.locale.getString('widgetDescription.topics');
		}
		return '';
	}

	getShowingOrder(order: string): string {
		return AnSortDirection.ASC === order
			? this.locale.getString('widgetDescription.bottom')
			: this.locale.getString('widgetDescription.top');
	}

	getChartName(chartType: string): string {
		if (!chartType) {
			return '';
		}
		let key = chartType.toLocaleLowerCase();
		return this.locale.getString(`widgetDescription.${key}`);
	}

	getMetricDisplayName(metrics: any[], name: string): string {
		let metric = _.find(metrics, m => m.name === name);
		return metric?.displayName;
	}

	getMetricDisplayValue(value: number, originalMetric: ReportCalculation, studioMetrics?: Metric[]): string {
		let metricValue = this.metricWidgetRendering.getMainMetricDisplayValue(value, originalMetric, studioMetrics);
		return this.metricWidgetRendering.getDisplayMetricValueAsString(metricValue);
	}

	getLocaleService(): CxLocaleService {
		return this.locale;
	}

	getMetricBounds(props: WidgetProperties, visualProps: VisualProperties, value: number): any {
		return this.metricBoundsUtils.getMetricBounds(props, visualProps, value);
	}

	getValueFormatter(): IFormatBuilder {
		return this.formatter.getFormatter();
	}

	getFormattedDate(group: any, dataItem: any): string {
		return this.highchartsUtils.formatTrendLabel(
			dataItem[`_metadata_${group.name}`]?.displayName,
			group.trendBy
		);
	}

	getChartFeatureDescription(group: any, calculation: any, data: any, isPeak: boolean): string {
		const IGNORE_ALIGNMENT = {ignoreAlignment: true};
		if (AnalyticMetricTypes.isTime(group)) {
			let calcName = calculation.name;
			let leaves = _.filter(data.data, item => !!item.leaf);
			let prominentItem = isPeak ? _.max(leaves, item => item[calcName]) : _.min(leaves, item => item[calcName]);
			if (_.filter(leaves, item => item[calcName] === prominentItem[calcName]).length === 1) {
				let dateKey = group.trendBy === 'DAY' || group.trendBy === 'HOUR'
					? 'widgetDescription.onDate'
					: 'widgetDescription.inDate';
				let dateValue = this.getFormattedDate(group, prominentItem);
				let date = this.locale.getString(dateKey, { date:  dateValue });
				let key = isPeak ? 'widgetDescription.chartPeak' : 'widgetDescription.chartTrough';
				let value = this.getValueFormatter().format(prominentItem[calcName], calculation, undefined, IGNORE_ALIGNMENT);
				return this.locale.getString(key, {
					calculation: calculation.displayName,
					date,
					value
				});
			}
		}
		return '';
	}

	getTopItems(data: any, grouping: any, calculation: string, nameFunction?: (dataItem, group) => string): string[] {
		if (!!data?.data && grouping && calculation) {
			return _.chain(data.data)
				.sortBy(item => -item[calculation])
				.map(item => nameFunction
					? nameFunction(item, grouping)
					: item[grouping.identifier]
				)
				.value();
		}
		return [];
	}

	joinMessageParts(parts: string[]): string {
		return parts.filter(part => !!part).join(' ');
	}

}

export class Grouping {
	constructor(
		private name: string,
		private type?: string,
		private showing?: string,
	) {}

	public toString = (): string => {
		if (!this.type) {
			return this.name;
		}
		let result = `${this.name} ${this.type}`;
		if (this.showing) {
			return `${this.showing} ${result}`;
		} else {
			return result;
		}
	}
}

app.service('widgetDescriptionUtils', WidgetDescriptionUtils);
