import { AccountIdentifier } from '@app/modules/account/account-identifier';
import { SupportWorkspace, SupportWorkspaceProject } from '@app/modules/units/workspace-project/support-workspace';
import { AccountOrWorkspace, AccountOrWorkspaceProject, WorkspaceId, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';

export class WorkspaceTransitionUtils {
	static isWorkspaceSelected(workspace: AccountOrWorkspace): boolean {
		return this.isWorkspace(workspace)
			? WorkspaceProjectUtils.isWorkspaceSelected(workspace)
			: workspace.contentProviderId >= 0 && workspace.accountId >= 0;
	}

	static getWorkspaceData(workspace: AccountOrWorkspace): AccountIdentifier | SupportWorkspace {
		return this.isWorkspace(workspace)
			? { workspaceId: workspace }
			: {
				contentProviderId: workspace.contentProviderId,
				accountId: workspace.accountId
			};
	}

	static isWorkspace(workspace: AccountOrWorkspace): workspace is WorkspaceId {
		return _.isNumber(workspace);
	}

	static getWorkspace(project: AccountOrWorkspaceProject): AccountOrWorkspace {
		return this.isWorkspaceProject(project)
			? project.workspaceId
			: {
				contentProviderId: project.contentProviderId,
				accountId: project.accountId
			};
	}

	static getProject(workspace: AccountOrWorkspace, projectId: number): AccountOrWorkspaceProject {
		return this.isWorkspace(workspace)
			? {
				workspaceId: workspace,
				projectId
			}
			: {
				contentProviderId: workspace.contentProviderId,
				accountId: workspace.accountId,
				projectId
			};
	}

	static isProjectSelected(project: AccountOrWorkspaceProject): boolean {
		return this.isWorkspaceProject(project)
			? WorkspaceProjectUtils.isProjectSelected(project)
			: project
				&& ValueUtils.isSelected(project.contentProviderId)
				&& ValueUtils.isSelected(project.accountId)
				&& ValueUtils.isSelected(project.projectId);
	}

	static getProjectData(project: AccountOrWorkspaceProjectData, omitName = false): IProjectSelection | SupportWorkspaceProject {
		let projectData: IProjectSelection | SupportWorkspaceProject = this.isWorkspaceProject(project)
		? { workspaceProject: project }
		: {
			contentProviderId: project.contentProviderId,
			accountId: project.accountId,
			projectId: project.projectId
		};
		if (!omitName) {
			projectData.projectName = project.projectName;
		}
		return projectData;
	}

	static isWorkspaceProject(project: AccountOrWorkspaceProject): project is WorkspaceProject {
		return project && !_.isUndefined((project as WorkspaceProject).workspaceId);
	}
}
