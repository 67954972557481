import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ContentWidgetValidationService } from '@app/modules/widget-settings/content-widget/content-widget-validation.service';
import Widget, { ContentWidget, WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';

@Injectable({
	providedIn: 'root'
})
export class WidgetValidationService {

	constructor(
		private contentWidgetValidation: ContentWidgetValidationService,
	) { }

	validate(widget: Widget): boolean {
		if (widget.type === WidgetDisplayType.CONTENT) {
			return this.contentWidgetValidation.validate(widget as ContentWidget);
		}
		return true;
	}
}

app.service('widgetValidation', downgradeInjectable(WidgetValidationService));
