import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ScorecardEditorWizardInput, ScorecardsEditorWizardComponent } from './scorecard-editor-wizard.component';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})
export class ScorecardEditorWizardService {
	constructor(
		private cxDialogService: CxDialogService
	) {}

	open(input: ScorecardEditorWizardInput): NgbModalRef {
		return this.cxDialogService.openWizard(
			ScorecardsEditorWizardComponent, input, { size: ModalSize.LARGE, class: 'scorecard-editor-wizard' });
	}
}

app.service('scorecardEditorWizardService', downgradeInjectable(ScorecardEditorWizardService));