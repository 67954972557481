import { ApplicationTheme } from '@cxstudio/header/application-theme';

export class OvertalkOptions {
	static OVERTALK_COLOR = '#f68e2f';
	static OVERTALK_LIGHTEN_COLOR = '#f78e17';

	color: string;
	radius: number;
	opacity: number;

	constructor(theme?: ApplicationTheme) {
		if (theme === ApplicationTheme.DARK) {
			return {
				color: OvertalkOptions.OVERTALK_LIGHTEN_COLOR,
				radius: 0,
				opacity: .25
			};
		}
		return {
			color: OvertalkOptions.OVERTALK_LIGHTEN_COLOR,
			radius: 0,
			opacity: .2
		};
	}
}