import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';

@Component({
	selector: 'dashboard-list-section',
	template: `
<section class="d-flex flex-direction-column h-100-percent"
	[attr.aria-label]="'dashboard.listSection'|i18n">
	<dashboards-table-toolbar
		class="flex-fixed mb-16"
		[filterManager]="filterManager">
	</dashboards-table-toolbar>

	<dashboards-table class="flex-fill"
		[filterManager]="filterManager">
	</dashboards-table>
</section>
`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardListSectionComponent implements OnInit {
	textFilter: string;
	filterManager: TableFilterManager;

	constructor(
		private locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.filterManager = new TableFilterManager()
			.withHiddenSupport()
			.withTypeFilter([
				{ searchFilterValue: 'books', itemType: 'dashboardSet' },
				{ searchFilterValue: 'dashboards', itemType: 'dashboard' }
			])
			.withKeywords({
				labels: this.locale.getString('common.labelsSearchKeyword')
			});
	}
}
