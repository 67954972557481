<h3 [i18n]="'group.findMembers'"></h3>
<div class="form-group">
	<div class="col-md-6">
		<div class="input-group">
			<input type="text"
				class="form-control user-group-selector"
				data-testid="members-search"
				[ngbTypeahead]="membersSuggestions"
				(selectItem)="onNoMatches($event)"
				[(ngModel)]="search"
				(keydown.escape)="search = ''; $event.stopPropagation()"
				(keydown.enter)="addUser(search)"
				(ngModelChange)="updateSearch()">
			<button
				type="button"
				class="btn btn-primary"
				[disabled]="!canAddUser(search)"
				(click)="addUser(search)" data-testid="members-add">
				{{'common.add'|i18n}}
			</button>
		</div>
	</div>
</div>
<div class="group-search-result">
	<table class="table" attr.aria-label="{{'administration.members'|i18n}}">
		<thead>
			<tr>
				<th></th>
				<th [i18n]="'administration.firstName'"></th>
				<th [i18n]="'administration.lastName'"></th>
				<th [i18n]="'administration.userEmail'"></th>
				<th [i18n]="'administration.licenseType'"></th>
			</tr>
		</thead>
		<tbody [ngBusy]="searchPromise">
			<tr *ngFor="let user of users.addedFiltered | orderBy:'userEmail'"
				class="br-user-row">
				<td><a *ngIf="isEditable()"
					(click)="removeUserFromAdded(user)" class="q-icon-delete"></a></td>
				<td class="br-user-firstName">{{ user.firstName }}</td>
				<td class="br-user-lastName">{{ user.lastName }}</td>
				<td class="br-user-email">{{ user.userEmail }}</td>
				<td class="br-user-licenseTypeName">{{ user.licenseTypeName }}</td>
			</tr>
			<tr *ngFor="let user of users.currentFiltered | orderBy:'userEmail' | slice:0:CURRENT_USERS_DISPLAY_LIMIT"
				class="br-user-row">
				<td><a *ngIf="isEditable()"
					(click)="removeUserFromGroup(user)" class="q-icon-delete"></a></td>
				<td class="br-user-firstName">{{ user.firstName }}</td>
				<td class="br-user-lastName">{{ user.lastName }}</td>
				<td class="br-user-email">{{ user.userEmail }}</td>
				<td class="br-user-licenseTypeName">{{ user.licenseTypeName }}</td>
			</tr>
			<tr *ngIf="membersCount > CURRENT_USERS_DISPLAY_LIMIT" class="br-user-row">
				<td></td>
				<td colspan="4">{{getMoreUsersText()}}</td>
			</tr>
		</tbody>
	</table>
</div>
