import { Input, OnChanges, Output, EventEmitter, Directive } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SimpleChanges } from '@app/util/change-utils';

@Directive()
export class CollapsingBaseComponent implements OnChanges {
	@Input() collapsed: boolean;
	@Input() openPanel: boolean; // rewrite this to component function once all places where it's used are converted to angular
	@Input() disableCollapse: boolean;
	@Input() rounded = true;
	@Output() collapsedChange = new EventEmitter<boolean>();

	panelCollapsed: boolean;

	constructor(private locale: CxLocaleService) {}

	ngOnChanges(changes: SimpleChanges<CollapsingBaseComponent>): void {
		if (changes.openPanel?.previousValue !== changes.openPanel?.currentValue) {
			this.panelCollapsed = false;
		}

		if (changes.collapsed?.currentValue !== undefined) {
			this.panelCollapsed = changes.collapsed.currentValue;
		}
	}

	toggleCollapse = (): void => {
		if (this.disableCollapse)
			return;
		this.panelCollapsed = !this.panelCollapsed;
		this.collapsedChange.emit(this.panelCollapsed);
	}
}
