import { AssetParametersType } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-type';
import { AssetParameters } from './asset-parameters';

export abstract class AbstractAssetParameters implements AssetParameters {
	private email?: string;

	constructor(
		private type: AssetParametersType
	) {}

	getHttpParams(): {[key: string]: string} {
		return {
			type: this.type
		};
	}

	getCacheParams(): {[key: string]: string} {
		let cachePrams: {[key: string]: string} = {};
		if (this.email) {
			cachePrams.userEmail = this.email;
		}
		return cachePrams;
	}

	setRunAsEmail(email: string): void {
		this.email = email;
	}
}
