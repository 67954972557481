<div class="d-flex flex-direction-column">
	<saturation-component class="mt-8 mb-8"
		[(color)]="valueColor"
		(colorChange)="valueColorChange.emit($event)"
		[hue]="hueColor">
	</saturation-component>
	<hue-component class="mb-8"
		[(color)]="valueColor"
		(colorChange)="valueColorChange.emit($event)"
		[(hue)]="hueColor"
		(hueChange)="hueColorChange.emit($event)">
	</hue-component>

	<div class="d-flex flex-direction-row align-items-end mb-24 manual-input">
		<cb-push-buttons
			class="mr-8"
			[list]="colorOptions"
			[(selectedValue)]="colorType">
		</cb-push-buttons>

		<div class="d-flex flex-direction-column flex-fill">
			<rgba-input-component *ngIf="colorType === 'rgba'"
				class="d-flex"
				[alpha]="false"
				[(color)]="valueColor"
				(colorChange)="valueColorChange.emit($event)"
			></rgba-input-component>
			<hex-input-component *ngIf="colorType === 'hex'"
				prefix="#"
				[(color)]="valueColor"
				(colorChange)="valueColorChange.emit($event)"
			></hex-input-component>
		</div>

		<figure class="flex-fixed w-32 h-32" [style.background-color]="valueColor.toHexString()"></figure>
	</div>
</div>
