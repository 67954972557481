import { Security } from '@cxstudio/auth/security-service';
import {
	ASTInclusionListUtilsService
} from '@app/modules/alert-subscription-template/services/ast-inclusion-list-utils.service';
import IAlertSubscriptionTemplate from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import { ITemplateAttribute, ITemplatePinnedAttribute } from '@cxstudio/alert-subscription-templates/templates-editor-modal.component';
import { IListOption } from '@cxstudio/drivers/editor/list-option.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PromiseUtils } from '@app/util/promise-utils';

export class ASTInteractionInformationComponent implements ng.IComponentController {
	template: Partial<IAlertSubscriptionTemplate>;
	callbacks = {
		attributes: {
			available: this.astInclusionListUtils.availableAttributesLabelCallback,
			selected: this.astInclusionListUtils.selectedAttributesLabelCallback
		},
		models: {
			available: this.astInclusionListUtils.availableModelsLabelCallback,
			selected: this.astInclusionListUtils.selectedModelsLabelCallback
		}
	};

	isPastItemLimit = this.astInclusionListUtils.isPastItemLimit;

	currentModelTree: SimpleTopicSelection;
	includedModels: any[];
	selectedModel: any;
	modelLoading: ng.IPromise<any>;
	availableCaseAttributes: ITemplateAttribute[];

	nameAttributeName: string;
	emailAttributeName: string;
	selectionCallback: () => void;

	ticketingControls: boolean;

	constructor(
		private $timeout: ng.ITimeoutService,
		private security: Security,
		private astInclusionListUtils: ASTInclusionListUtilsService,
		private locale: ILocale,
	) {}

	$onInit = () => {
		this.includedModels = angular.copy(this.template.mentionMetadata.models);

		this.matchCaseAttributes();
	}

	updateSelectedAttributes = (attributes: IListOption[]): void => {
		this.template.mentionMetadata.attributes = attributes;
		this.selectionCallback();
	}

	updateSelectedModels = (models: IListOption[]): void => {
		this.includedModels = models;
		this.updateTemplateModel();
		this.selectionCallback();
	}

	isEngagorIntegrationEnabled = (): boolean => this.security.getCurrentMasterAccount().engagorEnabled;

	isEngagorExternalEngagementEnabled = (): boolean => this.security.getCurrentMasterAccount().engagorExternalEngagementEnabled;

	showContactDetailsControls = (): boolean => {
		return this.isEngagorExternalEngagementEnabled() || this.showTicketingControls();
	}

	onModelSelectionChanged = (models: any[]): void => {
		_.each(models, model => {
			if (!model.selected && this.currentModelTree?.getRoot().modelId === model.id)
				this.currentModelTree = undefined;
		});
	}

	onModelSelected = (models: any[]): void => {
		let modelId = _.size(models) === 1 ? models[0].id : undefined;
		if (!modelId) {
			this.currentModelTree = undefined;
			return;
		}
		if (this.currentModelTree?.getRoot().modelId !== modelId) {
			let props = this.astInclusionListUtils.getProjectSelection(this.template);
			this.selectedModel = _.findWhere(this.template.mentionMetadata.models, {id: modelId});
			this.modelLoading = PromiseUtils.old(
					this.astInclusionListUtils.getCurrentModelTree(modelId, props, this.selectedModel)
				).then((result: SimpleTopicSelection) => {
					this.currentModelTree = result;
				});
		}
	}

	private updateTemplateModel = (): void => {
		this.template.mentionMetadata.models = this.includedModels.map(model => {
			let existingModel = _.findWhere(this.template.mentionMetadata.models, {id: model.id});
			model.nodeIds = existingModel ? existingModel.nodeIds : [];
			return model;
		});
	}

	private matchCaseAttributes = (): void => {
		let caseSettings = this.template.caseSettings;
		if (caseSettings && caseSettings.nameAttribute) {
			this.nameAttributeName = caseSettings.nameAttribute.name;
		}
		if (caseSettings && caseSettings.emailAttribute) {
			this.emailAttributeName = caseSettings.emailAttribute.name;
		}
	}

	nameAttributeChange = (attribute: ITemplateAttribute): void => {
		let nameAttribute = angular.copy(attribute) as ITemplatePinnedAttribute;
		nameAttribute.mappedName = 'customerName';
		nameAttribute.nativeId = nameAttribute.id;
		this.template.caseSettings.nameAttribute = nameAttribute;
	}

	emailAttributeChange = (attribute: ITemplateAttribute): void => {
		let emailAttribute = angular.copy(attribute) as ITemplatePinnedAttribute;
		emailAttribute.mappedName = 'customerEmail';
		emailAttribute.nativeId = emailAttribute.id;
		this.template.caseSettings.emailAttribute = emailAttribute;
	}

	showTicketingControls = (): boolean => {
		return this.ticketingControls;
	}

	getPanelTitle = (): string => {
		if (this.showTicketingControls()) {
			return this.locale.getString('alertTemplates.contactDetails');
		}
		return this.locale.getString('alertTemplates.interactionInformation');
	}

}

app.component('astInteractionInformation', {
	bindings: {
		template: '<',
		modelLimit: '<',
		attributeLimit: '<',
		mentionMetadata: '=',
		availableCaseAttributes: '<',
		selectionCallback: '<',
		ticketingControls: '<',
	},
	controller: ASTInteractionInformationComponent,
	templateUrl: 'partials/alert-subscription-templates/ast-interaction-information.component.html'
});
