import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { SpineLane, SpineLaneStyle, SpineLaneType, ParticipantEnrichmentTypes } from '@cxstudio/conversation/entities/spine-lane.class';
import { SpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConversationSpineDefaults {
	static readonly GROUP_COLOR_PREFIX = '_group_color.';

	constructor() { }

	get(): SpineSettings {
		let defaults: SpineSettings = {
			participantLane: {
				enabled: true,
				definition: {
					name: ParticipantEnrichmentTypes.SENTIMENT,
					bandColor: this.get3BandColor(PredefinedMetricConstants.SENTIMENT)
				}
			},
			singleLanes: [
				{
					definition: {
						name: SingleLaneEnrichmentTypes.EFFORT,
						type: SpineLaneStyle.icon,
						icon: 'circle',
						laneType: SpineLaneType.DEFAULT,
						bandColor: this.get3BandColor(PredefinedMetricConstants.EASE_SCORE)
					},
					enabled: true
				},
				{
					definition: {
						name: SingleLaneEnrichmentTypes.EMOTIONAL_INTENSITY,
						type: SpineLaneStyle.icon,
						icon: 'circle',
						bandColor: this.get3BandColor(PredefinedMetricConstants.EMOTION)
					},
					enabled: true
				},
				{
					definition: {
						name: SingleLaneEnrichmentTypes.SENTENCE_TYPE,
						type: SpineLaneStyle.icon,
						laneType: SpineLaneType.DEFAULT,
						icon: 'diamond'
					},
					enabled: true
				},
				{
					definition: {
						name: SingleLaneEnrichmentTypes.SCORECARD,
						laneType: SpineLaneType.DEFAULT
					},
					enabled: false
				}
			],
			labels: { } as ConversationChannelLabels,
		};

		return defaults;
	}

	get3BandColor(metricName: string): string {
		return `${ConversationSpineDefaults.GROUP_COLOR_PREFIX}${metricName}${PredefinedMetricConstants.SUBTYPE_3}`;
	}

	getDefaultLane(): SpineLane {
		return {
			definition: {
				name: SingleLaneEnrichmentTypes.SENTIMENT,
				type: SpineLaneStyle.icon,
				icon: 'circle',
				bandColor: this.get3BandColor(PredefinedMetricConstants.SENTIMENT)
			},
			enabled: true
		};
	}
}
