import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import Listener from '@cxstudio/common/listener';
import { ISharePermissionNameGetter, IShareUiConfig } from '@cxstudio/sharing/generic-share.component';
import { SharableAsset, SharingParams } from '@cxstudio/sharing/sharing-service.service';

@Directive({
	selector: 'generic-share'
})

export class GenericShareUpgradeDirective extends UpgradeComponent {
	@Input() resolve: SharingParams;
	@Input() close: (value) => void;
	@Input() dismiss: () => void;
	@Input() getPermissionName: ISharePermissionNameGetter;
	@Input() getSharedUsersAndGroups: (items: any[]) => any;
	@Input() getShareStatus: () => any;
	@Input() objectTypeName: string;
	@Input() nameProperty: string;
	@Input() embedded: boolean;
	@Input() saveListener: Listener;
	@Input() oneClickRemoval: boolean;
	@Input() itemOwner: string;
	@Input() uiConfig: IShareUiConfig;
	@Input() changeTracker: any;
	@Input() onChange: () => void;
	@Input() addedEntities: SharableAsset[];
	@Input() validateInvite: (invite: string) => boolean;
	@Input() removeNewShare: (item: any) => void;
	@Input() viewToNotify: boolean;
	@Input() assetEditPermissionType: AssetEditPermissionAction;
	@Input() singlePermissionMode: boolean;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('genericShare', elementRef, injector);
	}
}