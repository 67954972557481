export enum ClarabridgeMetricName {
	LC = '_lc',
	WORDS = '_mstokenname',
	WORDS_MTOKEN = '_mtoken',
	HASHTAG = '_hashtag',
	ES_QUERY = '_es_query',

	PROJECT = 'project',
	MASTER_ACCOUNT = 'master_account',
	DOCUMENT_COUNT = 'document_count',
	SENTENCE_COUNT = 'sentence_count',
	VERBATIM_COUNT = 'verbatim_count',
	USER = 'user',
	USER_ACTION = 'user_action',
	SERVER_TIME = 'server_time',
	DOCUMENT_DATE = '_doc_date',
	OBJECT = 'object',
	TARGET = 'target',
	SOURCE = 'source',
	DETAILS = 'details',

	KEY_TERMS = 'rootCause',
	KEY_TERMS_SCORE = 'score',
	KEY_TERM_RANK = 'keyTermRank',

	PERCENT_DELTA = '_percent_delta'
}