import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { SubscriptionTemplateTitleVariable } from '@cxstudio/alert-subscription-templates/types/subscription-template-title-variable';
import { AuditSuggestion } from '@cxstudio/reports/document-explorer/audit-suggestion';

export interface EditableCaseDocumentValues {
	title?: string;
	priority?: string;
	assignee?: Assignee;
}

export interface CaseTitle {
	text: string;
	variables?: SubscriptionTemplateTitleVariable[];
}

export default class EngagorCreateCaseRequestDocument {
	naturalId: string;
	documentId: number;
	notes: string;
	auditSuggestion?: AuditSuggestion;
	caseTitle: CaseTitle;
	casePriority: string;
	caseAssignee: Assignee;

	withDocumentId = (documentId: number): EngagorCreateCaseRequestDocument => {
		this.documentId = documentId;
		return this;
	}

	withNaturalId = (naturalId: string): EngagorCreateCaseRequestDocument => {
		this.naturalId = naturalId;
		return this;
	}

	withAuditSuggestion = (auditSuggestion: AuditSuggestion): EngagorCreateCaseRequestDocument => {
		this.auditSuggestion = auditSuggestion;
		return this;
	}

	withNotes = (notes: string): EngagorCreateCaseRequestDocument => {
		this.notes = notes;
		return this;
	}

	withCaseTitle = (caseTitle: CaseTitle): EngagorCreateCaseRequestDocument => {
		this.caseTitle = caseTitle;
		return this;
	}

	withCasePriority = (casePriority: string): EngagorCreateCaseRequestDocument => {
		this.casePriority = casePriority;
		return this;
	}

	withCaseAssignee = (caseAssignee: Assignee): EngagorCreateCaseRequestDocument => {
		this.caseAssignee = caseAssignee;
		return this;
	}
}
