import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import * as _ from 'underscore';
import { CxLocaleService } from '@app/core';
import { IScheduledJobType } from './scheduled-job-type';
import { RefreshJob } from './refresh-job';
import { PdfDistributionJob } from './pdf-distribution-job';
import { JobType } from './job-type';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject, Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root'
})
export class ScheduledJobTypes {

	private jobTypes: { [key: string]: IScheduledJobType };

	constructor(
		private locale: CxLocaleService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		@Inject('dashboardExportService') private dashboardExportService: any
	) {
		this.jobTypes = {
			REFRESH: new RefreshJob(locale),
			DISTRIBUTE_PDF: new PdfDistributionJob(locale, dashboardExportService, currentWidgets)
		};
	}

	getTypes = (): { [key: string]: IScheduledJobType } => {
		return this.jobTypes;
	}

	findType = (value: JobType): IScheduledJobType => {
		return _.findWhere(_.values(this.jobTypes), {value});
	}
}

app.service('scheduledJobTypes', downgradeInjectable(ScheduledJobTypes));
