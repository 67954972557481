import { Injectable } from '@angular/core';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { WidgetError } from './common/widget-error/widget-error.class';
import WidgetType from '../widget-settings/widget-type.enum';

@Injectable({
	providedIn: 'root'
})
export class VisualizationDataService {
	constructor() { }

	validateData(dataObject: ReportDataObject, widgetType: string | WidgetType): WidgetError {
		if (!dataObject || !dataObject.data) {
			return WidgetError.DATA_ERROR;
		}

		if (this.isFilteredByConfidentiality(dataObject)) {
			if (dataObject?.total?.volume === 0) {
				return WidgetError.NO_DATA;
			}

			return widgetType === WidgetType.PREVIEW
				? WidgetError.PREVIEW_DATA_RESTRICTED
				: WidgetError.DATA_RESTRICTED;
		}

		if (_.all(dataObject.data, row => row.volume === 0)) {
			return WidgetError.NO_DATA;
		}

		return null;
	}

	private isFilteredByConfidentiality(dataObject: ReportDataObject): boolean {
		return dataObject?.metadata?.filteredByConfidentiality;
	}
}