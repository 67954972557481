import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { ObjectUtils } from '@app/util/object-utils';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { FormatsService } from './formats.service';
import { FormatterBuilderUtilsService } from './formatter-builder-utils.service';
import { NumberFormatHelperService } from './number-format-helper.service';

export interface IFormatOptions {
	ignoreAlignment?: boolean;
	ignoreConversion?: boolean;
	ignoreAffixes?: boolean;
}
export interface IFormatBuilder {
	format: (value: number | string, options?: CalculationWithFormat,
		defaultFormat?: NumberFormatSettings, formatOptions?: IFormatOptions) => string;
	simpleFormat: (value: number | string, format?: NumberFormatSettings, formatOptions?: IFormatOptions) => string;
	getTopLevelSettings: (defaultOptions: NumberFormatSettings, options: CalculationWithFormat,
		defaultFormat?: NumberFormatSettings) => NumberFormatSettings;
}

@Injectable({
	providedIn: 'root'
})
export class GenericFormatterService {

	constructor(
		private readonly formatterBuilderUtils: FormatterBuilderUtilsService,
		private readonly numberFormatHelper: NumberFormatHelperService,
		private readonly locale: CxLocaleService,
	) { }

	getFormatter = (defaultOption: NumberFormatSettings = {} as NumberFormatSettings): IFormatBuilder =>  {

		let format = (value: number | string, options?: CalculationWithFormat, defaultFormat?: NumberFormatSettings,
				formatOptions: IFormatOptions = {}): string => {
			return this.formatMetric(defaultOption, value, options, defaultFormat, formatOptions);
		};

		let simpleFormat = (value: number | string, formatting?: NumberFormatSettings, formatOptions?: IFormatOptions): string => {
			return this.formatMetric(defaultOption, value, formatting as CalculationWithFormat, undefined, formatOptions);
		};

		return {
			format,
			simpleFormat,
			getTopLevelSettings: this.getTopLevelSettings
		};
	}

	private formatMetric = (defaultOption: NumberFormatSettings, value: number | string,
		options?: CalculationWithFormat, defaultFormat?: NumberFormatSettings,
		formatOptions: IFormatOptions = {}): string => {
		// if they are using the old formatting options, we need to update to the new properties
		if (options?.dataType && (!options.customFormatting || options.usePercentChange)) {
			let dataFormat = FormatsService.find(options.dataType);

			if (dataFormat) {
				options = dataFormat.upgrade(options);
			}
		}

		let customFormattingOptions = this.getCustomFormattingOpts(defaultOption, options, defaultFormat);

		//Somewhat complicated logic, if using default format or its undefined (in case of settings never being opened)
		//set these automatically. For % change volume, %total, and %parent , we need to check if they have been
		//changed before trying to set them, as they have no concept of default format
		if (this.isPercentChange(options) && this.useDefaultFormat(options)) {
			customFormattingOptions = $.extend(customFormattingOptions, defaultOption);
		}
		let result: string;
		if (this.formatterBuilderUtils.isNA(value)) {
			result = this.locale.getString('widget.na');
		} else {
			let resultNum = value as number;
			if (!formatOptions.ignoreConversion) {
				resultNum = this.formatterBuilderUtils.multiplyValue(resultNum, customFormattingOptions.conversion);
			}
			resultNum = this.formatterBuilderUtils.formatTruncationValue(resultNum, customFormattingOptions.truncation);
			result = this.formatterBuilderUtils.formatDecimals(resultNum, customFormattingOptions.decimals);
			result = this.formatterBuilderUtils.formatDecimalSeparator(result, customFormattingOptions.decimalDelimiter);
			result = this.formatterBuilderUtils.formatAny(result, customFormattingOptions.thousandsDelimiter);
			result = this.formatterBuilderUtils.formatTruncationText(result, customFormattingOptions.truncation);
			if (!formatOptions.ignoreAffixes) {
				result = (customFormattingOptions.prefix || '') + result + (customFormattingOptions.suffix || '');
			}
		}

		if (!formatOptions.ignoreAlignment) {
			result = this.formatterBuilderUtils.formatAlignment(result, customFormattingOptions.alignment);
		}

		return result;
	}

	private tryUseMetricFormat = (options: ReportCalculation): boolean => {
		// if they have selected to use the default format, try to use the default format
		// if they have not explicitly unchecked the box, try to use the default format
		if (options.useDefaultFormat || options.useDefaultFormat === undefined) {
			return true;
		}

		return false;
	}

	private useDefaultFormat = (options) => {
		return (options.useDefaultFormat || _.isUndefined(options.useDefaultFormat))
			&& !this.isChangedStandardMetric(options);
	}
	private isPercentChange = (options): boolean => {
		return options?.name?.contains(PeriodOverPeriodMetricType.PERCENT_CHANGE) && !options.usePercentChange;
	}

	private isChangedStandardMetric = (options): boolean => {
		return options.standardMetric &&
			(_.isUndefined(options.conversion) ||
			(options.conversion !== MetricMultiplierType.TIMES_HUNDRED || options.suffix !== '%'));
	}


	getTopLevelSettings = (defaultOptions: NumberFormatSettings, options: CalculationWithFormat,
		defaultFormat?: NumberFormatSettings): NumberFormatSettings => {
		if (!options) {
			return defaultOptions;
		}

		if (this.tryUseMetricFormat(options)) {
			if (defaultFormat) {
				return ObjectUtils.copy(defaultFormat);
			}

			if (this.numberFormatHelper.hasFormattingOptions(options)) {
				return ObjectUtils.copy(options);
			}

			return ObjectUtils.copy(defaultOptions);
		}

		if (!options.useDefaultFormat) {
			return ObjectUtils.copy(options);
		}
	}


	private getCustomFormattingOpts(defaultOptions: NumberFormatSettings, options: CalculationWithFormat,
		defaultFormat?: NumberFormatSettings): NumberFormatSettings {
		let topLevelSettings = this.getTopLevelSettings(defaultOptions, options, defaultFormat);

		// copy defaults so we dont reuse settings across widgets incorrectly
		let returnOptions = ObjectUtils.copy(defaultOptions);
		$.extend(returnOptions, topLevelSettings);

		// if the top level settings do not have values for these fields, we should honor that
		this.numberFormatHelper.OPTIONAL_FIELDS.forEach((key) => {
			if (topLevelSettings[key] === undefined) {
				returnOptions[key] = undefined;
			}
		});

		return returnOptions;
	}
}
