import { GenericSelectUtils } from './generic-selection-utils.factory';
import { SelectionControllerBase } from './selection-controller-base';
import { MetricTreeItem } from '@cxstudio/metrics/metric-tree-item';
import { DriversTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { PaginatedSelectionUtils } from './paginated-selection-utils.factory';
import { PaginatedSelectionController } from './paginated-selection-controller';


export class SelectionUtils {

	static createMetricSelectionUtils(metricController: SelectionControllerBase<MetricTreeItem>):
			GenericSelectUtils<MetricTreeItem> {
		return new GenericSelectUtils<MetricTreeItem>(metricController);
	}

	static createDriversSelectionUtils(driversController: SelectionControllerBase<DriversTreeItem>):
			GenericSelectUtils<DriversTreeItem> {
		return new GenericSelectUtils<DriversTreeItem>(driversController);
	}

	static createInteractionsSelectionUtils(interactionsController: PaginatedSelectionController<CbDocument>):
			PaginatedSelectionUtils<CbDocument> {
		return new PaginatedSelectionUtils<CbDocument>(interactionsController);
	}
}
