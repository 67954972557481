import { Component, Input, Inject, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ApplicationThemeService } from '@app/core/application-theme.service';

declare let html2canvas: (element: Element, options?: any) => Promise<HTMLCanvasElement>;

@Component({
	selector: 'widget-image-export',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<a
	*ngIf="isExportWidgetAsImageVisible()"
	(click)="saveAsImage($event)"
	role="menuitem"
	href="javascript:void(0)"
	class="dropdown-item option save-as-image"
	[attr.disabled]="!isExportAsImageEnabled() ? 'true' : null"
	[ngClass]="{'disabled':state.loading}">
	{{'dashboard.exportAsImage'|i18n}}
	<i class="ml-8 rotate-infinite q-icon-spinner" *ngIf="state.isExportingImage"></i></a>`
})
export class WidgetImageExportComponent implements OnInit, OnDestroy{

	@Input() state;
	@Input() filteringFeedbackSelection: { isEnabled: () => boolean };
	@Input() widget: Widget;
	@Input() dashboard: Dashboard;
	private hiddenWatermark: Element | null = null;
	private isFooterHidden = false;

	readonly IMAGE_SCALING: number = 2;		// 1 = 100%, 1.5 = 150%, etc...
	readonly FOOTER_HEIGHT: number = 32; // should match widget.scss
	readonly PREVIEW_FOOTER_HEIGHT: number = 48; // an-preview-widget.scss
	readonly HEADER_HEIGHT: number = 41; // should match widget.scss
	readonly WIDGET_BORDER: number = 2;
	readonly HIDDEN_FOOTER_PADDING: number = 16;

	// use this for COMPLETELY disallowed widgets, not widgets that have to meet certain criteria
	readonly DISALLOWED_WIDGETS = [WidgetType.SELECTOR, WidgetType.MAP];

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('exportUtils') private readonly exportUtils,
		private readonly applicationThemeService: ApplicationThemeService) {
	}

	ngOnInit() {
		// preload the image
		this.hiddenWatermark = this.getWatermark()[0];
		document.body.appendChild(this.hiddenWatermark);
	}

	ngOnDestroy() {
		this.hiddenWatermark.remove();
	}

	// is the functionality visible?
	isExportWidgetAsImageVisible = (): boolean =>
		!!(this.widget.type === WidgetDisplayType.CB &&
			!this.state.statsMode &&
			!this.state.descriptionMode &&
			!this.state.message &&
			!this.filteringFeedbackSelection?.isEnabled() &&
			(!this.DISALLOWED_WIDGETS.contains(this.widget.name as WidgetType) &&
			(this.widget.name === WidgetType.PREVIEW
				|| (this.state.view && this.state.view.data && this.state.view.data.data.length))))


	// is the functionality allowed in the current state
	isExportAsImageEnabled = () => !(this.state.isExportingImage || this.state.loading);

	private getWatermark = (): JQuery => {
		let poweredBy = this.locale.getString('dashboard.poweredBy');
		let darkTheme = this.applicationThemeService.isShowingDashboardDarkTheme();
		let logoSrc = darkTheme ? 'img/QualtricsXM-Logo.png' : 'img/QualtricsXM-Logo.png';
		let darkThemeClass = darkTheme ? 'dark-theme' : ''; // default logo picture has padding unlike dark theme logo
		return $(`<div class="image-export-watermark ${darkThemeClass}">${poweredBy} <img src="${logoSrc}"></div>`);
	}

	private getFakeFooter = (): JQuery => {
		return $(`<div class="br-widget-footer justify-end" ng-class="{'cursor-move':$ctrl.editMode}" gridster-drag-handle></div>`);
	}

	private adjustStyles = (widgetCopy: JQuery): void => {
		let height = 0;
		if (widgetCopy.find('.slick-viewport').length) {
			widgetCopy.find('.slick-viewport').css('height', 'unset');
			widgetCopy.find('.ui-widget').css('bottom', 'unset').css('top', '0');
			height = widgetCopy.find('.ui-widget').outerHeight()
				+ this.FOOTER_HEIGHT + this.HEADER_HEIGHT + this.WIDGET_BORDER;
		} else if (widgetCopy.find('.cb-preview-table-container').length) {
			widgetCopy.find('.cb-preview-table.table-header').css('width', '100%');
			height = widgetCopy.find('.cb-preview-table-container').outerHeight()
				+ this.PREVIEW_FOOTER_HEIGHT + this.HEADER_HEIGHT + this.WIDGET_BORDER;
		} else if (widgetCopy.find('primary-pane .main-panel').length) {
			height = widgetCopy.find('primary-pane .main-panel').outerHeight()
				+ this.HEADER_HEIGHT;
		} else if (widgetCopy.find('.feedback-preview-bubble-view').length) {
			widgetCopy.find('.feedback-preview-bubble-view').each((i, col) => {
				let colHeight = $(col).outerHeight(true);
				height = colHeight > height ? colHeight : height;
			});
			height += this.HEADER_HEIGHT + this.PREVIEW_FOOTER_HEIGHT;
		} else if (this.isFooterHidden) {
			height = widgetCopy.outerHeight() + this.HIDDEN_FOOTER_PADDING;
		}

		if (height) widgetCopy.css('height', `${height}px`);
	}

	private prepareWidget = (widgetCopy: JQuery): void => {
		if (widgetCopy.find('.home-page-widget-container').length) {
			widgetCopy.removeClass('w-100-percent h-100-percent');
		}
	}

	private createExportableWidgetCopy = (originalWidget: JQuery, container: JQuery): JQuery => {
		// clone the original, scale it up, and move it somewhere that it's not visible to the user
		let widgetCopy = originalWidget.clone();
		this.prepareWidget(widgetCopy);

		let widgetId = widgetCopy.attr('id');
		widgetCopy.addClass('widget-image-export no-animation');
		widgetCopy.css('display', 'block');
		widgetCopy.css('z-index', '-1');
		widgetCopy.css('margin-top', '-10000px');
		widgetCopy.attr('id', `${widgetId}-image`);
		widgetCopy.css('height', originalWidget.outerHeight() + 'px');
		($(widgetCopy).get(0) as HTMLElement).style.setProperty('width', originalWidget.outerWidth() + 'px', 'important');
		let widgetFooter = widgetCopy.find('.br-widget-footer');
		if (widgetFooter.length === 0) {
			// modern qualtrics look and feel is enabled, and we are hiding the footer
			this.isFooterHidden = true;
			let contentDiv = widgetCopy.find('.br-widget-content');
			contentDiv.removeClass('footer-hidden');
			let widgetBox = widgetCopy.find('.br-widget-box');
			widgetFooter = this.getFakeFooter();
			widgetBox.append(widgetFooter);
		}
		container.append(widgetCopy);
		this.adjustStyles(widgetCopy);
		if (widgetCopy.find('.footer-hidden').length > 0 && !widgetCopy.find('.slick-viewport').length) {
			let originalHeight = widgetCopy.outerHeight();
			widgetCopy.height(`${originalHeight + this.FOOTER_HEIGHT}px`);
		}
		// scaling should change after we calculated height
		widgetCopy.css('transform', `scale(${this.IMAGE_SCALING})`);

		// add a watermark
		let waterMark = this.getWatermark();
		widgetFooter.append(waterMark);
		return widgetCopy;
	}

	// convert widget to image, then download it
	saveAsImage = ($event: JQuery.Event): void => {
		if (!this.isExportAsImageEnabled()) return;

		this.state.isExportingImage = true;

		setTimeout(() => {
			let originalWidgetElement = $(`#widget-${this.widget.id}`);
			let container = $('body');

			let widgetCopy = this.createExportableWidgetCopy(originalWidgetElement, container);

			html2canvas(widgetCopy[0], {
				onclone: (doc) => {
					let classList = doc.getElementsByTagName('html')[0].classList;
					classList.add('no-animation');
					classList.add('no-child-animation');
					if (this.widget.name === WidgetType.METRIC) {
						let clonedWidget = doc.getElementById(`widget-${this.widget.id}-image`);
						$(clonedWidget)?.find('widget-content')?.css('position', 'absolute');
					}
				}
			}).then((canvasElement) => {
				widgetCopy.remove();
				this.state.isExportingImage = false;

				if (canvasElement.toBlob) {
					canvasElement.toBlob((blob) => {
						this.exportUtils.downloadExportedData(blob, this.widget.displayName + '.png');
					});
				} else if ((canvasElement as any).msToBlob) { // edge legacy
					let blob = (canvasElement as any).msToBlob();
					this.exportUtils.downloadExportedData(blob, this.widget.displayName + '.png');
				}
			});
		});
	}

}

app.directive('widgetImageExport', downgradeComponent({component: WidgetImageExportComponent }));
