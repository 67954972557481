import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { downgradeInjectable } from '@angular/upgrade/static';
import {CxDialogService, ModalSize} from '@app/modules/dialog/cx-dialog.service';
import { ITransferDialogParams, TransferDialogComponent } from '@app/modules/user-administration/transfer/transfer-dialog.component';

@Injectable()
export class TransferDialogService {
	constructor(
		private cxDialogService: CxDialogService
	) {}

	open(input: ITransferDialogParams): NgbModalRef {
		if (input.isSystemAdminPage){
			return this.cxDialogService.openDialog(TransferDialogComponent, input, {
				size: ModalSize.MEDIUM,
			});
		} else {
			return this.cxDialogService.openDialog(TransferDialogComponent, input);
		}
	}
}

app.service('transferDialogService', downgradeInjectable(TransferDialogService));
