import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'stats-menu-item',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<a (click)="!disabled && toggle.emit()"
	class="dropdown-item option stats-mode"
	[class.disabled]="disabled"
	role="menuitem"
	href="javascript:void(0)">{{(active ? 'widget.disableStatsMode' : 'widget.enableStatsMode') | i18n}}</a>`
})
export class StatsMenuItemComponent {
	@Input() active: boolean;
	@Input() disabled: boolean;
	@Output() toggle = new EventEmitter<void>();
}

app.directive('statsMenuItem', downgradeComponent({component: StatsMenuItemComponent }));
