import { Component, OnInit, OnDestroy, Input, Inject, EventEmitter, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { WidgetEditorService, ReloadSettingsCallback } from '@app/modules/widget-settings/widget-editor.service';
import { Subscription } from 'rxjs';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetConversionsService } from '@cxstudio/reports/providers/cb/widget-conversions.service';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { TableColumnService } from '@cxstudio/reports/providers/cb/services/table-column-service.service';
import { WidgetUtilsResourcesService } from '@app/modules/reports/utils/widget-utils-resources.service';
import WidgetService from '@cxstudio/services/widget-service';
import Widget from '@cxstudio/dashboards/widgets/widget';

@Component({
	selector: 'cb-object-viewer-definition',
	templateUrl: './cb-object-viewer-definition.component.html'
})
export class CbObjectViewerDefinitionComponent implements OnInit, OnDestroy {
	@Input() widget: Widget;
	@Input() props: WidgetProperties;
	@Input() visualProps: VisualProperties;
	@Input() staticData;
	@Input() utils: WidgetUtils;
	@Input() redrawTrigger: number;
	@Input() updateChartSettings: () => void;
	@Input() loadingPromise: Promise<any>;
	@Input() addLoadingPromise: (promise: Promise<any> | any) => void;
	@Output() conversionListener = new EventEmitter<ReportWidget>();
	options: {
		scorecards?: Scorecard[],
		scorecardModels?: Model[]
	};

	loaded = false;
	backgroundOptions = WidgetBackgroundColor;
	scorecard: Scorecard;


	private reloadSettingsSubscription: Subscription;
	private clearSettingsSubscription: Subscription;

	readonly DEFAULTS: Partial<VisualProperties> = {
		visualization: WidgetVisualization.DEFINITION_TABLE,
		backgroundColor: WidgetBackgroundColor.DEFAULT,
		showTooltips: true,
		showSampleSize: true,
		normalized: true
	};

	constructor(
		private widgetEditorService: WidgetEditorService,
		private widgetUtilsResourcesService: WidgetUtilsResourcesService,
		@Inject('tableColumnService') private tableColumnService: TableColumnService,
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		@Inject('widgetConversions') private widgetConversions: WidgetConversionsService,
		@Inject('widgetService') private widgetService: WidgetService,
	) {}

	ngOnInit(): void {
		this.options = {};
		this.staticData.ready = false;
		this.reloadSettingsSubscription =
			this.widgetEditorService.reloadSettings$.subscribe(this.reloadSettings);
		this.clearSettingsSubscription =
			this.widgetEditorService.clearSettings$.subscribe(this.initProps);

		this.initProps();
		this.initSampleData();
	}

	ngOnDestroy(): void {
		this.reloadSettingsSubscription.unsubscribe();
		this.clearSettingsSubscription.unsubscribe();
	}

	private initSampleData(): void {
		this.staticData.totalCount = 1000;
		this.staticData.total = 10;
		this.staticData.totalN = 100;
		this.staticData.target = 7;
		this.staticData.targetN = 70;
		this.staticData.data = [{
			id: 1,
			nodeName: 'None A',
			present: true,
			weight: 6,
			weightN: 60
		}, {
			id: 2,
			nodeName: 'None B',
			present: false,
			autoFail: true
		}, {
			id: 3,
			nodeName: 'None C',
			present: false,
			weight: 4,
			weightN: 40
		}];

		this.staticData.ready = true;
	}

	private initProps = (): void => {
		this.props.selectedAttributes = this.props.selectedAttributes || [];
		for (let key in this.DEFAULTS) {
			if (this.visualProps[key] === undefined)
				this.visualProps[key] = this.DEFAULTS[key];
		}
	}

	private reloadSettings = (callback: ReloadSettingsCallback): void => {
		if (this.loaded) {
			this.resetSelections();
		}
		this.loaded = true;

		this.initProps();
		let project: ProjectIdentifier = {
			contentProviderId: this.props.contentProviderId,
			accountId: this.props.accountId,
			projectId: this.props.project
		};
		this.scorecard = undefined;

		let settingsPromise = this.scorecardsApiService.getProjectScorecards(project).then((result) => {
			this.options.scorecards = result;
			let grouping = this.props.selectedAttributes[0];
			if (grouping) {
				this.scorecard = this.options.scorecards.find(scorecard => scorecard.id === grouping.id);
			}
			callback(true);
		});
		this.addLoadingPromise(settingsPromise);
	}

	private resetSelections(): void {
		this.props.selectedAttributes = [];
	}

	showPreview = (): boolean => {
		return this.scorecard !== undefined;
	}

	onRubricChange = (scorecard: Scorecard): void => {
		this.scorecard = scorecard;
		let grouping: ReportGrouping = {
			id: scorecard.id,
			name: `sc_${scorecard.id}`,
			type: ReportAssetType.OBJECT_DEFINITION,
			metricType: AnalyticMetricType.SCORECARD
		};
		this.props.selectedAttributes[0] = grouping;
		this.widget.displayName = this.widgetService.updateAutoTitle(this.props, this.options);
		this.onColumnsChange();
	}

	onChange = (): void => {
		this.updateChartSettings();
	}

	onColumnsChange = (): void => {
		let lazyResources = this.widgetUtilsResourcesService.getLazyResources(this.widget);
		Promise.all([lazyResources.attributes(), lazyResources.scorecards(),
			lazyResources.scorecardModels(), lazyResources.formats()] as any).then((results: any[]) => {
				this.utils.allColumns = this.tableColumnService.getObjectViewerColumns(this.visualProps, this.props,
					results[0], results[1], results[2], results[3]);
				this.staticData.ready = true;
				this.onChange();
			});
	}

	changeView(type: WidgetType): void {
		this.conversionListener.emit(this.widgetConversions.byWidgetType(type).targetWidget);
	}
}

app.directive('cbObjectViewerDefinition', downgradeComponent({ component: CbObjectViewerDefinitionComponent }));
