/**
 * Originally this was a full fledged component with a template but was replaced by tabbed-context-pane
 * Need to refactor and remove this class entirely
 */

import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef,
	ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FavoriteAttribute, FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ContextHighlightType, IExplorerHighlighter } from '@cxstudio/reports/document-explorer/explorer-highlighter-factory';
import { Model } from '@cxstudio/reports/entities/model';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ScorecardEmitterParameter, ScorecardInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { NarrativeSettingEntry } from '@app/modules/account-administration/automated-narrative/narrative-settings-list.component';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import { IPreviewWidgetFavorites } from '@app/modules/document-explorer/context-pane/doc-explorer-favorites.class';
import { RandomUtils } from '@app/util/random-utils.class';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DocumentPreviewSelector } from '@app/shared/util/document-preview-selector';
import { CxLocaleService } from '@app/core';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { ContextPaneState, DocViewPaneSettings } from '@app/modules/document-explorer/preferences/doc-view-pane-settings';
import { DocExplorerPreferencesProvider } from '@app/modules/document-explorer/preferences/doc-explorer-preferences.provider';
import { Subject } from 'rxjs';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { debounceTime } from 'rxjs/operators';
import { SimpleChanges } from '@app/util/change-utils';
import { DocumentTypeUtils } from '../document-type-utils.class';
import { Security } from '@cxstudio/auth/security-service';

interface ContextSection {
	className: string;
	expand: () => void;
	isVisible: () => boolean;
}

@Component({
	selector: 'context-pane',
	providers: [DocExplorerPreferencesProvider],
	template: ``
	// using onpush breaks panel selection. Refactor to use NgRx and then we can enable onpush
})
export class ContextPaneComponent extends SelfCleaningComponent implements OnInit, OnChanges, AfterContentInit {
	readonly PANEL_HEADER_HEIGHT = 56;
	readonly SIDE_NAV_PADDING_ADJ = 16;

	@Input() document: ConversationDocument;
	@Input() favorites: IPreviewWidgetFavorites;
	@Input() preferences: DocViewPreferences;
	@Input() projectUniqueId: string;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() isDocExplorer: boolean;
	@Input() selectedDocument: number;
	@Input() worldAwareness: any[];
	@Input() attributes: any[];
	@Input() engagorCases: EngagorCase[];
	@Input() highlighter: IExplorerHighlighter;
	@Input() availableModels: Model[];
	@Input() auditMode: boolean;
	@Input() rebuttalMode: boolean;
	@Input() narrativeEntries: NarrativeSettingEntry[];
	@Input() scorecards: any[];
	@Input() panels: { [panelName: string]: ExplorerSectionGroup };
	@Input() showEmptyAttributes: boolean;
	@Input() loading: Promise<any>[];
	@Output() onModelsChanged = new EventEmitter<number>();
	@ViewChild('topicFilterDropdown') topicFilterDropdown: NgbDropdown;

	componentUI: ContextPaneState = {} as ContextPaneState;

	showFavoritesSearch: boolean;
	showSearch: boolean;
	showWASearch: boolean;
	showTopicsSearch: boolean;
	showModelHierarchy: boolean;
	isSelectDeselect: boolean;
	idPostfix: string;
	ContextHighlightType = ContextHighlightType;
	selectionChangeTrigger: number = 0;
	private focusableSections: ContextSection[];
	showEmptyAttrs: boolean;
	elementHeight: number;
	private readonly debouncedResize = new Subject<void>();

	constructor(
		@Inject('enrichmentAttributesService') protected readonly enrichmentAttributesService: EnrichmentAttributesService,
		@Inject('engagorService') protected readonly engagorService: EngagorService,
		@Inject(DocViewPreferences) protected readonly docExplorerPreferencesProvider,
		@Inject('security') protected readonly security: Security,
		protected readonly betaFeaturesService: BetaFeaturesService,
		protected readonly ref: ChangeDetectorRef,
		protected readonly locale: CxLocaleService,
		protected readonly el: ElementRef,
	) {
		super();
	}

	ngOnInit(): void {

		this.idPostfix = RandomUtils.randomString();
		this.focusableSections = [
			{
				className: 'panel-favorites',
				expand: () => this.componentUI.favoriteAttributesCollapsed = false,
				isVisible: this.isFavoritesPanelVisible
			},
			{
				className: 'panel-narrative',
				expand: () => this.componentUI.narrativeCollapsed = false,
				isVisible: this.isNarrativePanelVisible
			},
			{
				className: 'panel-engagor',
				expand: () => this.componentUI.engagorCollapsed = false,
				isVisible: this.isEngagorPanelVisible
			},
			{
				className: 'panel-scorecards',
				expand: () => this.componentUI.scorecardCollapsed = false,
				isVisible: this.isScorecardPanelVisible
			},
			{
				className: 'panel-automated-summaries',
				expand: () => this.componentUI.automatedSummariesCollapsed = false,
				isVisible: this.isAutomatedSummariesPanelVisible
			},
			{
				className: 'panel-compliance-management',
				expand: () => this.componentUI.complianceManagementCollapsed = false,
				isVisible: this.isComplianceManagementPanelVisible
			},
			{
				className: 'panel-attribute',
				expand: () => this.componentUI.attrCollapsed = false,
				isVisible: this.isAttributesPanelVisible
			},
			{
				className: 'panel-world-awareness',
				expand: () => this.componentUI.worldAwarenessCollapsed = false,
				isVisible: this.isEnrichmentsPanelVisible
			},
			{
				className: 'topics-panel',
				expand: () => this.componentUI.topicsCollapsed = false,
				isVisible: this.isTopicsPanelVisible
			}
		];
		this.preferences = this.preferences || this.docExplorerPreferencesProvider(DocViewPaneSettings.default());
		this.preferences.settings.paneCollapsedState = this.preferences.settings.paneCollapsedState || {} as ContextPaneState;
		this.componentUI = this.preferences.settings.paneCollapsedState;
		this.componentUI.attrTextFilter = '';
		this.componentUI.worldAwarenessSearch = '';
		this.componentUI.topicSearch = '';
		this.componentUI.favoritesTextFilter = '';
		this.componentUI.modelSearch = '';
		if (_.isUndefined(this.componentUI.scorecardCollapsed)) {
			this.componentUI.scorecardCollapsed = true;
		}
		if (_.isUndefined(this.componentUI.narrativeCollapsed)) {
			this.componentUI.narrativeCollapsed = true;
		}

		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.updateHeight()));
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(this.el.nativeElement, () => this.debouncedResize.next()));
		this.updateModelsSelection();

		this.addSubscription(this.documentManager.events.getRedrawTopicsObservable().subscribe(() => this.selectionChanged()));
	}

	ngAfterContentInit(): void {
		this.elementHeight = this.el.nativeElement.offsetHeight;
	}

	private isFavoritesPanelVisible = () => {
		return !this.auditMode && !this.rebuttalMode && !_.isEmpty(this.favorites?.favoriteAttributesDisplay);
	}

	isNarrativePanelVisible = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES)
			&& !this.auditMode
			&& !this.rebuttalMode
			&& this.isPanelVisible(FavoriteType.NARRATIVE)
			&& this.hasNarratives();
	}

	isTopicsPanelVisible = (): boolean => !this.rebuttalMode && this.isPanelVisible(FavoriteType.MODEL);

	isEngagorPanelVisible = (): boolean => {
		return !this.auditMode && !this.rebuttalMode
			&& this.engagorService.isIntegrationEnabled() && this.isPanelVisible(FavoriteType.ENGAGE);
	}

	isScorecardPanelVisible = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
			&& this.isPanelVisible(FavoriteType.SCORECARDS)
			&& (!this.auditMode || this.rebuttalMode);
	}

	isAutomatedSummariesPanelVisible = (): boolean => {
		return (this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_SUMMARIES) 
			|| this.betaFeaturesService.isFeatureEnabled(BetaFeature.COACHING_ASSISTANT))
			&& this.isPanelVisible(FavoriteType.AUTOMATED_SUMMARIES)
			&& !this.auditMode;
	}

	isComplianceManagementPanelVisible = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.COMPLIANCE_MANAGEMENT)
		&& this.isPanelVisible(FavoriteType.COMPLIANCE_MANAGEMENT)
		&& this.security.has('manual_quality_management')
		&& !this.auditMode;
	}

	isAttributesPanelVisible = (): boolean => {
		return !this.auditMode && !this.rebuttalMode && this.isPanelVisible(FavoriteType.ATTRIBUTE);
	}

	isEnrichmentsPanelVisible = (): boolean => {
		return !this.auditMode && !this.rebuttalMode && this.isPanelVisible(FavoriteType.WORLD_AWARENESS);
	}

	isCoachingAssistantEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.COACHING_ASSISTANT);
	}

	isAutomatedSummariesEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_SUMMARIES);
	}

	private isPanelVisible = (panelName: FavoriteType): boolean => this.panels?.[panelName]?.visible !== false;

	ngOnChanges(changes: SimpleChanges<ContextPaneComponent>): void {
		if (changes.selectedDocument) {
			this.showModelHierarchy = false;
			this.clearHighlighting();
		}
		if (this.preferences && this.componentUI !== this.preferences.settings.paneCollapsedState) {
			this.componentUI = this.preferences.settings.paneCollapsedState;
		}

		this.updateHeight();
	}

	updateHeight(): void {
		this.elementHeight = this.el.nativeElement.offsetHeight;
	}

	contextPanelKeydown = (event: KeyboardEvent, className: string) => {
		let section = _.findWhere(this.focusableSections, {className});
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && event.currentTarget === event.target) {
			KeyboardUtils.intercept(event);
			setTimeout(() =>
				$(`${this.getContextPaneSelector()} .${section.className} :focusable`).first().trigger('focus')
			);
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			KeyboardUtils.intercept(event);
			$(`${this.getContextPaneSelector()} .${section.className}`).trigger('focus');
		} else if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			this.handleSectionTab(event);
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			this.handleSectionShiftTab(event);
		}
	}

	private handleSectionTab = (event: KeyboardEvent) => {
			if (event.currentTarget === event.target) {
				let nextSection = $(event.currentTarget).next();
				if (nextSection.length) {
					KeyboardUtils.intercept(event);
					nextSection.trigger('focus');
				} else {
					let bypassBlock = $('#bypass-modal-block');
					if (bypassBlock.length) {
						KeyboardUtils.intercept(event);
						bypassBlock.find('a').trigger('focus');
					} else {
						$(event.currentTarget).find(':focusable').last().trigger('focus');
					}
				}
				return;
			}
			let isLastElement = $(event.currentTarget).find(`:focusable`).last()[0] === document.activeElement;
			if (isLastElement) {
				KeyboardUtils.intercept(event);
				$(event.currentTarget).find(`:focusable`).first().trigger('focus');
			}
	}

	private handleSectionShiftTab = (event: KeyboardEvent) => {
			if (event.currentTarget === event.target) {
				let prevSection = $(event.currentTarget).prev();
				if (prevSection.length) {
					KeyboardUtils.intercept(event);
					prevSection.trigger('focus');
				}
				return;
			}
			let isFirstElement = $(event.currentTarget).find(`:focusable`).first()[0] === document.activeElement;
			if (isFirstElement) {
				KeyboardUtils.intercept(event);
				$(event.currentTarget).find(`:focusable`).last().trigger('focus');
			}
	}

	private getContextPaneSelector(): string {
		return `#${DocumentPreviewSelector.CONTEXT_PANE_SELECTOR}-${this.idPostfix}`;
	}

	keyItemsTypeFilter = (type: FavoriteType): any => {
		return (keyItem: FavoriteAttribute): boolean => {
			return keyItem.favoriteType === type;
		};
	}

	showKeyInfoPanel = (): boolean => {
		if (this.auditMode || this.rebuttalMode) {
			return false;
		}

		if (!this.favorites || !this.favorites.favoriteAttributesDisplay) {
			return false;
		}
		let displayedKeyItems = this.favorites.favoriteAttributesDisplay;
		let favoriteAttributes = _.chain(displayedKeyItems)
			.where({ favoriteType: FavoriteType.ATTRIBUTE })
			.some((attr: any) => this.enrichmentAttributesService.isAttributeVisible(attr, this.isDocExplorer,
				this.preferences.settings.showEmptyAttributes))
			.value();
		if (favoriteAttributes) {
			return true;
		}
		let favoriteWorldAwareness = _.chain(displayedKeyItems)
			.some(this.keyItemsTypeFilter(FavoriteType.WORLD_AWARENESS))
			.value();
		if (favoriteWorldAwareness) {
			return true;
		}
		let favoriteTopics = _.chain(displayedKeyItems)
			.filter(this.keyItemsTypeFilter(FavoriteType.MODEL))
			.value();

		return _.some(favoriteTopics, model =>
			this.enrichmentAttributesService.isModelVisible(model, this.isDocExplorer, this.getModelSelection()));
	}

	toggleHierarchyState = (): void => {
		if (this.showTopicsSearch) {
			this.showTopicsSearch = !this.showTopicsSearch;
		}
		this.clearHighlighting();
		this.ref.detectChanges();
	}

	toggleSearchState = (): void => {
		if (this.showModelHierarchy) {
			this.showModelHierarchy = !this.showModelHierarchy;
		}

		const needToCloseTextSearch = !this.showTopicsSearch && !!this.highlighter.currentHighlight?.node;

		if (needToCloseTextSearch) {
			this.clearHighlighting();
			this.showTopicsSearch = false;
		} else {
			this.showTopicsSearch = !this.showTopicsSearch;
		}

		this.ref.detectChanges();
	}

	toggleWASearchState = (): void => {
		if (!this.showWASearch && !!this.highlighter.currentHighlight?.node) {
			this.clearEnrichmentHighlighting();
			this.showWASearch = false;
		} else {
			this.showWASearch = !this.showWASearch;
		}

		this.ref.detectChanges();
	}

	getModelSelection = () => {
		let settingsSelection = this.preferences?.settings?.modelSearch?.[this.projectUniqueId];
		if (!!settingsSelection && !_.isEqual(this.componentUI.modelSelection, settingsSelection)) {
			// create new object to update pipes
			this.updateModelsSelection();
		}
		return this.componentUI.modelSelection;
	}

	private updateModelsSelection(): void {
		this.componentUI.modelSelection = _.extend({}, this.preferences?.settings?.modelSearch?.[this.projectUniqueId]);
	}

	changeModelsSelection = (): void => {
		this.updateModelsSelection();
		this.onModelsChanged.emit();
	}

	highlightByScorecardTopic = (scorecardNode: ScorecardEmitterParameter): void => {
		let model = _.findWhere(this.document.classification, { modelId: scorecardNode.modelId });
		if (model) {
			let node = _.findWhere(model.nodes, { id: scorecardNode.nodeId });
			if (node) {
				this.highlighter.highlightModel(node as any);
			}
		}
	}

	onExpandScorecard = (scorecard: ScorecardInDocView): void => {
		this.documentManager.selectedScorecard = scorecard.id;
	}

	onFavoriteScorecard = (scorecard: ScorecardInDocView): void => {
		if (this.documentManager.favoriteScorecard === scorecard.id) {
			this.documentManager.favoriteScorecard = null;
		} else {
			this.documentManager.favoriteScorecard = scorecard.id;
		}
		this.favorites.toggleFavoriteScorecard(new FavoriteAttribute(scorecard.id, FavoriteType.SCORECARDS));
	}

	hasNarratives = (): boolean => {
		if (_.isEmpty(this.narrativeEntries)) {
			return false;
		}

		return _.some(this.narrativeEntries, entry => !!this.getNarrative(entry));
	}

	getNarrative = (entry: NarrativeSettingEntry): string => {
		let attribute = _.find(this.attributes, attr => attr.name.toLowerCase() === entry.attributeName.toLowerCase());
		return attribute?.value;
	}

	getNarrativeLines = (entry: NarrativeSettingEntry): string[] => {
		return this.getNarrative(entry)?.split('\n');
	}

	getNarrativeNames = (): string[] => {
		return _.map(this.narrativeEntries, entry => entry.attributeName.toLowerCase());
	}

	highlightTopic = ($event): void => {
		if (this.showTopicsSearch) {
			this.showTopicsSearch = !this.showTopicsSearch;
		}
		if (!DocumentTypeUtils.isConversation(this.document)) {
			this.ref.detectChanges();
			this.highlighter.highlightModel($event);
			this.ref.detectChanges();
			this.selectionChanged();
		} else {
			this.documentManager.state.highlightingTopic = new Promise<void>((resolve, reject) => {
				setTimeout(() => {
					this.ref.detectChanges();
					this.documentManager.state.highlightingTopicResolve = resolve;
					this.ref.detectChanges();
					this.highlighter.highlightModel($event);
					this.ref.detectChanges();
					this.selectionChanged();
				}, 1);
			});
		}
	}

	highlightWorldAwareness = ($event): void => {
		if (this.showWASearch) {
			this.showWASearch = !this.showWASearch;
		}
		this.ref.detectChanges();
		this.highlighter.highlightWorldAwareness($event); // sets highlight
		// switch off search if on
		this.selectionChanged();
	}

	switchSentence = (direction: NavigationDirection, isWorldAwareness: boolean = false): void => {
		if (isWorldAwareness) {
			this.highlighter.switchSentence(direction, isWorldAwareness);
		} else {
			this.documentManager.state.highlightingTopic = new Promise<void>((resolve, reject) => {
				setTimeout(() => {
					this.documentManager.state.highlightingTopicResolve = resolve;
					this.highlighter.switchSentence(direction, isWorldAwareness);
				}, 1);
			});
		}
	}

	clearHighlighting = (): void => {
		this.highlighter.clearHighlighting();
		this.selectionChanged();
	}

	clearTopicHighlighting = (): void => {
		this.clearHighlighting();
		if (!this.showTopicsSearch) {
			this.showTopicsSearch = !this.showTopicsSearch;
		}
		this.ref.detectChanges();
	}

	clearEnrichmentHighlighting = (): void => {
		this.clearHighlighting();
		if (!this.showWASearch) {
			this.showWASearch = !this.showWASearch;
		}
		this.ref.detectChanges();
	}

	private selectionChanged = (): void => {
		this.selectionChangeTrigger++;
		this.ref.detectChanges();
	}

	focusTopicFilterOnEsc = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) && this.topicFilterDropdown.isOpen()) {
			event.stopPropagation();
			this.topicFilterDropdown.close();
			$(`${this.getContextPaneSelector()} .topics-panel button[ngbDropdownToggle]`).first().trigger('focus');
		}
	}

	focusTopicFilterMenu = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && !this.topicFilterDropdown.isOpen()) {
			setTimeout(() =>
				$(`${this.getContextPaneSelector()} .topics-panel .topic-filter-dropdown :focusable`).first().trigger('focus')
			);
		}
	}

	handleSearchClick = (): void => {
		this.showSearch = !this.showSearch;
		this.componentUI.attrTextFilter = '';
	}

	onSearchToggle(event: KeyboardEvent, panelSelector: string): void {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			let section = _.findWhere(this.focusableSections, { className: panelSelector });
			section.expand();
			setTimeout(() => {
				let searchInput = this.getSearchInput(event.target, panelSelector);
				if (searchInput.length) {
					searchInput.first().trigger('focus');
				}
			});
		}
	}

	private getSearchInput(searchToggle: EventTarget, panelSelector: string): JQuery {
		return $(searchToggle)
			.closest(DocumentPreviewSelector.CONTEXT_PANE_SELECTOR)
			.find(`.${panelSelector} ${DocumentPreviewSelector.PANEL_BODY_SELECTOR} input`);
	}

	getSearchAriaLabel = (panelNameKey: string): string => {
		let panelName = this.locale.getString(panelNameKey);
		return this.locale.getString('preview.searchWithinPanel', { panelName });
	}

	getWorldAwarenessHighlightName = (node: { displayName?: string, value: string | number }): string => {
		return node.displayName
			? `${node.displayName} - ${node.value}`
			: '' + node.value;
	}

	isEmptyAttrsVisible(): boolean {
		return this.isDocExplorer ?
			this.preferences.settings.showEmptyAttributes :
			this.showEmptyAttributes;
	}

	getCollapseExpandBtnAriaLabel(panelNameKey: string, collapsed: boolean): string {
		let panelName = this.locale.getString(panelNameKey);
		if (!collapsed) {
			return this.locale.getString('preview.collapsePanel', { panelName });
		} else {
			return this.locale.getString('preview.expandPanel', { panelName });
		}
	}

	getAttributeAriaLabel<T extends { value, displayName: string }>(attribute: T): string {
		if (_.isUndefined(attribute.value) || _.isArray(attribute.value)) {
			return attribute.displayName;
		}

		return `${attribute.displayName} ${attribute.value}`;
	}

}

app.directive('contextPane', downgradeComponent({ component: ContextPaneComponent }) as angular.IDirectiveFactory);
