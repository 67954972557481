enum ColorShade {
	// consistent with brand.scss
	STOP_100 = 0.9,
	STOP_200 = 0.6,
	STOP_300 = 0.4,
	STOP_400 = 0,
	STOP_500 = -0.4,
	STOP_600 = -0.6
}

export class ColorShadeUtils {

	static lighten = (hex: string): string => {
		return hex && ColorShadeUtils.blendColors(hex, '#ffffff', 0.5);
	}

	static shade100 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_100);
	static shade200 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_200);
	static shade300 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_300);
	static shade400 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_400);
	static shade500 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_500);
	static shade600 = (hex: string) => ColorShadeUtils.shade(hex, ColorShade.STOP_600);

	private static shade(baseHex: string, shade: ColorShade): string {
		let blendColor = shade >= 0 ? '#ffffff' : '#111111';
		return baseHex && ColorShadeUtils.blendColors(baseHex, blendColor, Math.abs(shade));
	}

	static blendColors = (c0: string, c1: string, p: number): string => {
		let f = parseInt(c0.slice(1), 16),
			t = parseInt(c1.slice(1), 16),
			// tslint:disable-next-line:no-bitwise
			R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF, R2 = t >> 16, G2 = t >> 8 & 0x00FF, B2 = t & 0x0000FF;
		return '#' + (0x1000000 
				+ (Math.round((R2 - R1) * p) + R1) * 0x10000 
				+ (Math.round((G2 - G1) * p) + G1) * 0x100 
				+ (Math.round((B2 - B1) * p) + B1)
		).toString(16).slice(1);
	}
}