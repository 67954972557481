<div class="hierarchy-select-wrapper">
	<label class="mandatory" *ngIf="label">{{label}}</label>
	<div ngbDropdown
		class="dropdown-input w-100-percent"
		[container]="getContainer()">
		<a ngbDropdownToggle role="button"
			aria-expanded="false" aria-haspopup="true"
			[attr.disabled]="disabled ? 'disabled' : null"
			[title]="getDisplayName(!hasSelection())"
			class="button-text pr-32 border-radius-4">
			<div *ngIf="!itemTemplate">
				<span *ngIf="hasSelection()">{{getDisplayName()}}</span>
				<span *ngIf="!hasSelection()" [innerHTML]=getDisplayName()></span> <span class="caret"></span>
			</div>

			<div *ngIf="itemTemplate && selectedItem">
				<ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: selectedItem}"></ng-container>
			</div>
		</a>
		<div ngbDropdownMenu
			class="dropdown-menu dropdown-menu-hierarchy"
			ngClass="menuClass"
			role="menu">

			<searchable-tree
				*ngIf="!lazy || isOpen()"
				[autoFocus]="opened"
				[placeholder]="searchPlaceholder"
				[displayProperty]="displayProperty"
				[hierarchyList]="hierarchyList"
				(onNodeClick)="updateValue($event.node)"
				[selectedItem]=selectedItem
				[disabledItems]=disabledItems
				[notRecommendedItems]=notRecommendedItems
				[hideSearch]=hideSearch
				[customValidation]=customValidation
				[itemTemplate]="itemTemplate"
			></searchable-tree>
		</div>
	</div>
</div>
