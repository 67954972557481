import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { GroupColorUtils } from '@cxstudio/reports/utils/color/group-color-utils';
import * as _ from 'underscore';

export class GroupColorService {

	GROUP_COLOR_PREFIX = GroupColorUtils.GROUP_COLOR_PREFIX;

	constructor(
		private GroupColorType,
		private MetricCalculationsType: MetricCalculationsTypeService,
		private locale: ILocale,
	) {}

	isGroupColor(colorName: string): boolean {
		return GroupColorUtils.isGroupColor(colorName);
	}

	getGroupColorAttributeName(colorName: string): string {
		return GroupColorUtils.getGroupColorAttributeName(colorName);
	}

	getAttributeByGroupColor(colorName: string, properties: WidgetProperties): AttributeGrouping {
		let name = this.getGroupColorAttributeName(colorName);
		return _.findWhere(properties?.selectedAttributes, {name});
	}

	private isStudioMetric(item): boolean {
		return item && item.type === ReportAssetType.METRIC_STUDIO
			&& !this.MetricCalculationsType.FILTER_METRIC.is(item); //TODO: remove after implement percent in list group color.
	}

	isGroupColorSupported(item): boolean {
		return this.isStudioMetric(item) || AnalyticMetricTypes.isPredefinedGroup(item);
	}

	private getTypeFromColorName(colorName, grouping): any {
		let name = this.getGroupColorAttributeName(colorName);
		if (!/^_studio_metric_/.test(name)) {
			return this.GroupColorType.typeOf(name);
		} else {
			return grouping && this.GroupColorType.typeOf(grouping.definition.type);
		}
	}

	getColorFunction(colorName: string, grouping): (item) => string {
		let type = this.getTypeFromColorName(colorName, grouping);

		return type?.getColorFunction(grouping);
	}

	getColorArray(colorName, grouping): string[] {
		let type = this.getTypeFromColorName(colorName, grouping);

		return type?.getColorArray(grouping);
	}

	getLegendArray(metric, grouping): string[] {
		let type;

		if ((typeof metric === 'object') && metric.definition && metric.definition.type) {
			grouping = metric;
			if (AnalyticMetricTypes.isPredefinedGroup(metric)) {
				type = this.GroupColorType.typeOf(metric.name);
			} else {
				type = this.GroupColorType.typeOf(metric.definition.type);
			}
		} else {
			type = this.getTypeFromColorName(metric, grouping);
		}

		return type?.getDataForLegendItems(grouping);
	}

	getCategoryFunction(colorName, grouping): (item) => string {
		let type = this.getTypeFromColorName(colorName, grouping);

		return type?.getCategoryFunction(grouping);
	}

	getGroupColorOptions(selectedAttributes): any[] {

		if (selectedAttributes && !selectedAttributes.isEmpty()) {
			let options = [];

			let groupColorAttributes = selectedAttributes.filter(attr => this.isGroupColorSupported(attr));

			if (!groupColorAttributes.isEmpty()) {
				for (let i = 0; i < groupColorAttributes.length; i++) {
					let item = groupColorAttributes[i];
					if (AnalyticMetricTypes.isPredefinedMetric(item)) {
						let subtypes = this.getSubtypes(item);
						for (let j = 0; j < subtypes.length; j++) {
							let subtype = subtypes[j];
							let name = item.rawName + subtype;
							options.push({
								name: GroupColorUtils.GROUP_COLOR_PREFIX + name,
								type: 'group',
								displayName: this.locale.getString('metrics.' + name),
								rawName: item.rawName,
								order: 40 + j,
								disabled: item.subtype !== subtype
							});
						}
					} else if (this.isStudioMetric(item)) {
						options.push({
							name: GroupColorUtils.GROUP_COLOR_PREFIX + item.name,
							type: 'group',
							displayName: `${item.displayName} ${this.locale.getString('widget.group')}`,
							rawName: item.name,
							order: 44 + i
						});
					}
				}
			}

			return options;
		}
		return undefined;
	}

	private getSubtypes(item): string[] {
		return item.definition && item.definition.subtypes
			|| [PredefinedMetricConstants.SUBTYPE_3, PredefinedMetricConstants.SUBTYPE_5];
	}

}

app.service('groupColorService', GroupColorService);
