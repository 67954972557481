import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BypassBlockComponent } from './bypass-block/bypass-block.component';
import { BypassModalBlockComponent } from './bypass-block/bypass-modal-block.component';
import { KeyboardNavigationDrillHelper } from './drilling/keyboard-navigation-drill-helper.service';
import { CloudNavigation } from './cloud-navigation.service';

@NgModule({
	imports: [
		SharedModule
	],
	exports: [
		BypassBlockComponent,
		BypassModalBlockComponent,
	],
	declarations: [
		BypassBlockComponent,
		BypassModalBlockComponent
	],
	providers: [KeyboardNavigationDrillHelper, CloudNavigation],
	schemas: []
})
export class KeyboardNavigationModule {}
