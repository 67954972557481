<div class="page-tab-content">
	<ul ngbNav #nav="ngbNav" class="nav-tabs">
		<li ngbNavItem>
			<a ngbNavLink>{{'administration.contentProvidersTab'|i18n}}</a>
			<ng-template ngbNavContent>
				<sys-admin-content-providers></sys-admin-content-providers>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>{{'jms.failedMessages'|i18n}}</a>
			<ng-template ngbNavContent>
				<jms-failed-messages></jms-failed-messages>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>{{'cpCheck.cpCheck'|i18n}}</a>
			<ng-template ngbNavContent>
				<content-provider-version-check></content-provider-version-check>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>{{'mAccount.unitsIntegration'|i18n}}</a>
			<ng-template ngbNavContent>
				<workspace-management></workspace-management>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>{{'administration.dictionaryTab'|i18n}}</a>
			<ng-template ngbNavContent>
				<dictionary-tab></dictionary-tab>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
