import { VideoObject } from '@app/modules/widget-visualizations/content-widgets/video/video-object';

export class YoutubeVideo implements VideoObject {
	readonly YOUTUBE_REGEXP =
		/^(?:https?:\/\/|\/\/)?(?:www\.|m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?![\w-])/;

	readonly PREVIEW_YOUTUBE = 'http://img.youtube.com/vi/{id}/0.jpg';

	constructor(private url: string) {}

	getThumbnail(): Promise<string> {
		if (!this.url)
			return Promise.reject();
		return Promise.resolve(this.PREVIEW_YOUTUBE.replace('{id}', this.getId()));
	}

	getFullUrl(): string {
		return `https://www.youtube.com/embed/${this.getId()}?wmode=transparent`;
	}

	private getId(): string {
		if (!this.url)
			return;
		let match = this.url.match(this.YOUTUBE_REGEXP);
		return match ? match[1] : undefined;
	}
}
