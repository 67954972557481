import { CxLocaleService } from '@app/core';

app.directive('i18n', (locale: CxLocaleService, $sce: ng.ISCEService) => {
	return {
		restrict: 'A',
		scope: {
			i18n: '@'
		},
		link: (scope, element) => {
			const updateText = () => {
				const text = locale.getString(scope.i18n);
				if (text !== element.html()) {
					element.html($sce.getTrustedHtml(text));
				}
			};

			scope.$watch('i18n', (newValue, oldValue) => {
				if (newValue && newValue !== oldValue) {
					updateText();
				}
			});

			updateText();
		}
	};
});
