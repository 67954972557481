import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ConfigService } from '@app/core/config.service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import {
	MasterAccountPropertiesApiService
} from '@app/modules/account-administration/api/master-account-properties-api.service';
import { UrlService } from '@cxstudio/common/url-service.service';

@Component({
	selector: 'redirect-restrictions',
	templateUrl: './redirect-restrictions.component.html'
})
export class RedirectRestrictionsComponent implements OnInit {
	@Input() isEnabled = false;
	@Input() allowedDomains: string[] = [];
	externalRedirectDomain: string = '';
	hasValidErrors: boolean = false;
	public defaultAllowedDomains: string[] = [];

	constructor(
		private readonly domainsProcessingService: DomainsProcessingService,
		private readonly masterAccountPropertiesApiService: MasterAccountPropertiesApiService,
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		private readonly configService: ConfigService,
		@Inject('urlService') private readonly urlService: UrlService
	) {
	}

	ngOnInit(): void {
		this.defaultAllowedDomains = this.configService.getConfig().externalRedirect?.whitelistedDomains?.split(',') ?? [];
	}

	switchExternalRedirectDomainStatus(enabled: boolean): void {
		this.masterAccountPropertiesApiService.updateProperty('externalRedirectDomainRestricted', enabled)
			.then(() => {
				let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
				this.toastService.addToast(this.locale.getString(key, [
					this.locale.getString('mAccount.propRestrictExternalRedirect')
				]), AlertLevel.CONFIRM);
			});
	}

	addToExternalRedirectDomains(domainName: string): void {
		if (this.isDomainValid(domainName)) {
			this.masterAccountPropertiesApiService.addTrustedExternalRedirectDomain(domainName).then(() => {
				this.externalRedirectDomain = '';
				this.allowedDomains.push(domainName);
			});
		}
	}

	isDomainValid(domain: string): boolean {
		return this.urlService.isValidDomain(this.externalRedirectDomain)
			&& !this.domainsProcessingService.isDomainAllowed(this.defaultAllowedDomains, this.allowedDomains, domain);
	}

	deleteFromExternalRedirectDomains(domainName: string): void {
		this.masterAccountPropertiesApiService.removeTrustedExternalRedirectDomain(domainName);
		let idx = this.allowedDomains.indexOf(domainName);
		if (idx !== -1) {
			this.allowedDomains.splice(idx, 1);
		}
	}

	onDomainNameChange = (): void => {
		this.hasValidErrors = this.urlService.isNotValidDomain(this.externalRedirectDomain);
	}
}

app.directive('redirectRestrictions',
	downgradeComponent({ component: RedirectRestrictionsComponent }) as angular.IDirectiveFactory);
