import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, SimpleChanges, QueryList,
	ViewChildren, OnChanges } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbDropdown, NgbDropdownItem, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { RandomUtils } from '@app/util/random-utils.class';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { DropdownPosition } from '@cxstudio/dashboards/dashboard-filters/dropdown-position';

export interface SearchListEvent<T> {
	node: T;
	priorNode?: T;
}

export interface SearchListGroup<T> {
	label: string;
	list: T[];
}

const SPACE_TO_ADD_BETWEEN_ELEMENTS = 10;

@Component({
	selector: 'search-list',
	templateUrl: './search-list.component.html'
})
export class SearchListComponent<T> implements OnInit, AfterViewInit, OnChanges {
	@Input() dropdown?: boolean;
	@Input() listOptions?: T[];
	@Input() multiListOptions?: Array<SearchListGroup<T>>;
	@Input() multi: boolean;
	@Input() displayField: string;
	@Input() tooltipField: string;
	@Input() sortField: string;
	@Input() disableSort: boolean;
	@Input() data: any; //selected option
	@Input() disableSearch: boolean;
	@Input() fixedPosition: string; //dashboard filters popover
	@Input() searchPrompt: string;
	@Input() selectPrompt: string;
	@Input() matchFunction: any;
	@Input() nonclickable: any;
	@Input() fullWidth: boolean;
	@Input() selectorClass: string;
	@Input() optionValueField: string;
	@Input() appendToBody: boolean; //scorecards - presense
	@Input() isItemVisible: (node) => boolean;
	@Input() isItemDisabled: (node) => boolean;
	@Input() disabledItemTooltip: (node) => string;
	@Input() allowClear: boolean;
	@Input() autoOpen: boolean;
	// should the dropdown match the size of the select box?
	@Input() matchSize: boolean; //simple-dropdown
	@Input() matchToggleWidth: boolean;
	// refresh the dropdown size if element becomes visible
	@Input() visibilityChange: boolean; //simple-dropdown
	@Input() selectFirst: boolean;
	@Input() stopPropagation: boolean;
	@Input() searchLabel?: string;
	@Input() nodeTemplate?: (node) => string;

	@Output() onNodeSelection = new EventEmitter<SearchListEvent<any>>();
	@Output() onClearSelection = new EventEmitter<void>();
	@Output() onDropdownClick = new EventEmitter<void>();

	@ViewChild('ngDropdown', {static: false}) element: ElementRef;
	@ViewChild('listItems', {static: false}) listItems: ElementRef;
	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;
	@ViewChild(NgbDropdownToggle, {static: false}) private ngDropdownToggle: NgbDropdownToggle;
	@ViewChildren(NgbDropdownItem) private ngDropdownItems: QueryList<NgbDropdownItem>;

	ui = {
		searchTerm: undefined
	};
	menuStyle: any = {};
	minWidth: number;
	styleId: string;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.displayField = this.displayField || 'name';
		if (!this.disableSort) {
			this.sortField = this.sortField || 'name';
		} else {
			this.sortField = undefined;
		}

		this.tooltipField = this.tooltipField || this.displayField;
		this.searchPrompt = this.searchPrompt || this.locale.getString('common.search');
		this.selectPrompt = this.selectPrompt || this.locale.getString('common.selectPrompt');

		if (!this.matchFunction) {
			this.matchFunction = () => this.data;
		}

		if (this.data === undefined && this.selectFirst) {
			let list;
			if (this.multi) {
				let dataGroups = this.getOrderedMultiList()
					.filter(group => !group.list.isEmpty());
				if (!dataGroups.isEmpty()) {
					list = dataGroups[0].list;
				}
			} else {
				list = this.listOptions;
			}
			if (list && !list.isEmpty()) {
				let first = _.sortBy(list, this.sortField)[0];
				this.nodeClick(first);
			}

		}
		this.styleId = `s-${RandomUtils.randomString()}`;
	}

	ngAfterViewInit(): void {
		if (this.appendToBody && this.dropdown) {
			let dropdownEl = $(this.element.nativeElement);
			let containerEl = $((dropdownEl as any).scrollParent()[0]);
			containerEl.on('scroll', () => {
				if (this.ngDropdown.isOpen()) {
					this.ngDropdown.close();
				}
			});
		}

		if (this.autoOpen) {
			this.ngDropdown.open();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.matchSize) {
			let visibilityChangeState = changes.visibilityChange;
			if (visibilityChangeState && visibilityChangeState.currentValue !== visibilityChangeState.previousValue) {
				this.updateDropdownSizeWithTimeout();
			}
			if (changes.listOptions) {
				this.updateDropdownSizeWithTimeout();
			}
		}
	}

	getContainer = (): string => {
		return this.appendToBody ? 'body' : null;
	}

	getDisplay = (): string => {
		return this.fixedPosition ? 'static' : 'dynamic';
	}

	getToggleClasses = (): string[] => {
		let classes: string[] = [];

		if (this.nonclickable) classes.push('disabled');
		if (this.fullWidth) classes.push('w-100-percent');
		if (this.selectorClass) classes.push(this.selectorClass);
		if (this.data?.special) classes.pushAll(this.getSpecialItemClasses());

		return classes;
	}

	private getSpecialItemClasses(): string[] {
		return ['italic', 'font-bold'];
	}

	getToggleText = (): string => {
		return this.getMatchedDataField(this.displayField) || this.selectPrompt;
	}

	getToggleTooltip = (): string => {
		return this.getMatchedDataField(this.tooltipField) || '';
	}

	private getMatchedDataField = (field: string): any => {
		let matchedData = this.matchFunction(this.data);
		if (matchedData) return matchedData[field];
	}

	isNodeVisible = (node): boolean => {
		return this.isItemVisible ? this.isItemVisible(node) : !node.hideOption;
	}

	isNodeDisabled = (node): boolean => {
		return this.isItemDisabled && this.isItemDisabled(node);
	}

	getNodeClasses = (node, withNodeClass: boolean): any => {
		let classes: string[] = [];

		if (this.selectorClass) classes.push(this.selectorClass + '-node');
		if (this.optionValueField) classes.push('option-' + node[this.optionValueField]);
		if (withNodeClass && node.class) classes.push(node.class);
		if (this.isNodeDisabled(node)) classes.push('disabled');
		if (node.special) classes.pushAll(this.getSpecialItemClasses());

		return classes;
	}

	getNodeTitle = (node): string => {
		return this.isNodeDisabled(node) && this.disabledItemTooltip
			? this.disabledItemTooltip(node)
			: (node[this.tooltipField] || '');
	}

	nodeClick = (node, event?: MouseEvent): void => {
		if (this.isNodeDisabled(node)) return;
		if (this.stopPropagation) event.stopPropagation();

		this.onNodeSelection.emit({node, priorNode: this.data});
	}

	clearSelection = (): void => {
		this.ngDropdown.close();
		this.onClearSelection.emit();
	}

	dropdownClick = (): void => {
		if (this.nonclickable) {
			this.ngDropdown.close();
			return;
		}
		if (this.appendToBody && !this.isEnoughSpace()) {
			this.ngDropdown.close();
			return;
		}
		if (this.fixedPosition) {
			this.calculatePosition();
		}
		this.onDropdownClick.emit();
	}

	private isEnoughSpace = (): boolean => {
		if (this.dropdown) {
			let dropdownEl = $(this.element.nativeElement);
			let containerEl = $((dropdownEl as any).scrollParent()[0]);
			let dropdownTop = dropdownEl.offset().top;
			let dropdownBottom = dropdownTop + dropdownEl.height();
			let containerTop = containerEl.offset().top;
			let containerBottom = containerTop + containerEl.height();

			let hiddenOnTop = dropdownTop - containerTop < 0;
			let hiddenOnBottom = containerBottom - dropdownBottom < 0;

			return !(hiddenOnTop || hiddenOnBottom);
		} else {
			return true;
		}
	}

	private calculatePosition = (): void => {
		if (this.dropdown) {
			if (this.fixedPosition === DropdownPosition.RIGHT) {
				this.calculateDefaultPosition();
			} else {
				this.calculateSpecificPosition();
			}
		}
	}

	private calculateDefaultPosition = (): void => {
		//from dropdown-position directive
		let elem = $(this.element.nativeElement);
		const includeMargin = true;
		let menuOuterHeight = elem.find('.dropdown-menu').outerHeight(includeMargin);
		let windowHeight = window.innerHeight;


		let left = elem.offset().left + elem.width() + SPACE_TO_ADD_BETWEEN_ELEMENTS;
		let top = elem.offset().top;
		if (menuOuterHeight && windowHeight) {
			if (top + menuOuterHeight > windowHeight) {
				//if not enough space on bottom
				let menuInnerHeight = elem.find('.dropdown-menu').height();
				let heightDiff = menuOuterHeight - menuInnerHeight;
				top = top - menuOuterHeight + heightDiff;
				top = top > 0 ? top : 0;
			}
		}
		top -= this.getYTransform(elem);

		this.menuStyle.top = top + 'px';
		this.menuStyle.left = left + 'px';
	}

	private calculateSpecificPosition = (): void => {
		if (this.fixedPosition === DropdownPosition.BOTTOM) {
			let elem = $(this.element.nativeElement);
			let top = elem.offset().top + elem.height() + SPACE_TO_ADD_BETWEEN_ELEMENTS;
			top -= this.getYTransform(elem);
			this.menuStyle.top = top + 'px';
			this.menuStyle.left = elem.offset().left + 'px';
		}
	}

	private getYTransform = (elem: JQuery): number => {
		const transform = elem.parents('.dashboard-filters-pop').css('transform');
		return transform && transform !== 'none'
			? Number(transform.replace(/[^0-9\-.,]/g, '').split(',')[5])
			: 0;
	}

	searchFilter = (item): boolean => {
		if (this.ui.searchTerm) {
			let text = item[this.displayField];
			return text && text.toLowerCase().indexOf(this.ui.searchTerm.toLowerCase()) > -1;
		}
		return true;
	}

	getFilteredOptions = (options): any[] => {
		return _.chain(options)
			.filter(this.searchFilter)
			.sortBy(this.sortField)
			.value();
	}

	getOrderedMultiList = (): any[] => {
		return _.sortBy(this.multiListOptions, this.sortField);
	}

	private updateDropdownSizeWithTimeout = (): void => {
		setTimeout(() => this.updateDropdownSize(), 1);
	}

	private updateDropdownSize = (): void => {
		if (this.dropdown) {
			let minWidth = this.element.nativeElement.clientWidth;

			if (minWidth)
				this.minWidth = minWidth;
		}
	}

	getNodeTemplate = (node): string => {
		if (this.nodeTemplate) {
			return this.nodeTemplate(node);
		} else {
			return node[this.displayField];
		}
	}

	onDropdownKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && !this.ngDropdown.isOpen()) {
			event.preventDefault();
			event.stopPropagation();
			if (this.nonclickable) {
				return;
			}
			this.dropdownClick();
			this.ngDropdown.open();
			setTimeout(() => {
				if (this.searchInput) {
					this.searchInput.nativeElement.focus();
				} else {
					let firstDropdownItem = this.ngDropdownItems.first.elementRef.nativeElement;
					firstDropdownItem.focus();
				}
			});
		}
	}

	onDropdownItemKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE)) {
			event.preventDefault();
			event.stopPropagation();
			event.target.click();
			this.clickAndFocusOnDropdown();
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			// it's required for onListItemsKeyup ESCAPE handler
			event.preventDefault();
			event.stopPropagation();
		}
	}

	onSearchKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			// it's required for onListItemsKeyup ESCAPE handler
			event.preventDefault();
			event.stopPropagation();
		}
	}

	private clickAndFocusOnDropdown = (): void => {
		let dropdownToggle: HTMLElement = this.ngDropdownToggle.nativeElement;
		dropdownToggle.click();
		dropdownToggle.focus();
	}

	onListItemsKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.clickAndFocusOnDropdown();
		}
	}

	private isFirstItemFocused = (): boolean => {
		return $(this.listItems.nativeElement).find(':focusable').index(document.activeElement) === 0;
	}

	handleKeyboardNavigation = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)	&& this.isFirstItemFocused()) {
			event.preventDefault();
			event.stopPropagation();
			this.clickAndFocusOnDropdown();
		} else {
			KeyboardUtils.handleUpDownNavigation(event, this.listItems.nativeElement);
		}
	}

}

app.directive('searchList', downgradeComponent({component: SearchListComponent}) as angular.IDirectiveFactory);
