import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { SanitizePipe } from '@app/shared/pipes/sanitize.pipe';
import { HTMLStringUtils } from '@app/util/html-string-utils.class';
import { FontOptions } from '@cxstudio/common/font-options.class';
import { UrlService } from '@cxstudio/common/url-service.service';
import { TextRotation, TextVAlign, ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { autoJoin, baseKeymap, setBlockType, toggleMark, wrapIn } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { history } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { DOMParser, DOMSerializer, NodeRange, Schema } from 'prosemirror-model';
import { liftListItem, sinkListItem, wrapInList } from 'prosemirror-schema-list';
import { EditorState, Plugin } from 'prosemirror-state';
import { columnResizing, goToNextCell, tableEditing } from 'prosemirror-tables';
import { EditorView } from 'prosemirror-view';
import * as _ from 'underscore';
import { ImageSettingsEntry, ImageSettingsModalComponent } from './image-settings-modal.component';
import { ImageView } from './image-view';
import { buildInputRules } from './inputrules';
import { buildKeymap } from './keymap';
import { schema as cbSchema } from './schema';
import { ImageGalleryDialogComponent } from '@app/modules/widget-settings/content-widget/text/image-gallery-dialog/image-gallery-dialog.component';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

export interface ToolbarItem {
	name: string;
	tooltip: string;
	disabled: boolean;
	action: any;
	icon?: string;
	active?: boolean;
	selected?: any;
	options?: any[];
}
@Component({
	selector: 'cb-text-editor',
	templateUrl: './cb-text-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbTextEditorComponent implements OnInit {

	@Input() content: string;
	@Output() contentChange = new EventEmitter<string>();
	@Input() length: number;
	@Output() lengthChange = new EventEmitter<number>();
	@Input() isLabel: boolean;
	@Input() hierarchyEnrichmentOptions: string[];
	@Input() dashboardId: number;

	@Input() rotation: string;
	@Output() rotationChange = new EventEmitter<string>();
	@Input() valign: string;
	@Output() valignChange = new EventEmitter<string>();

	@Input() maxLength: number;
	@Input() backgroundColor: string;

	textItems: ToolbarItem[];
	listItems: ToolbarItem[];
	alignItems: ToolbarItem[];
	quoteItem: ToolbarItem;
	tag: ToolbarItem;
	fontSize: ToolbarItem;
	fontFamily: ToolbarItem;
	fontColor: ToolbarItem;
	highlightColor: ToolbarItem;
	clearItem: ToolbarItem;
	image: ToolbarItem;
	hierarchy: ToolbarItem;
	linkItem: ToolbarItem;

	rotationOptions: any[];
	valignOptions: any[];
	showHtml: boolean;
	private view: EditorView;
	private schema: Schema = cbSchema;
	rawHtml: string;
	private inputTimer = null;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private ngZone: NgZone,
		private renderer: Renderer2,
		private cxDialogService: CxDialogService,
		private textSanitizer: SanitizePipe,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('urlService') private urlService: UrlService
	) {
		this.textItems = [
			{
				name: 'strong',
				disabled: true,
				tooltip: 'widget.text_bold',
				icon: 'q-icon-bold',
				action: (e: Event) => this.toggleMarkByType(this.schema.marks.strong, e)
			},
			{
				name: 'em',
				disabled: true,
				tooltip: 'widget.text_italics',
				icon: 'q-icon-italic',
				action: (e: Event) => this.toggleMarkByType(this.schema.marks.em, e)
			},
			{
				name: 'u',
				disabled: true,
				tooltip: 'widget.text_underline',
				icon: 'q-icon-underline',
				action: (e: Event) => this.toggleMarkByType(this.schema.marks.u, e)
			},
			{
				name: 'del',
				disabled: true,
				tooltip: 'widget.text_strikeThrough',
				icon: 'q-icon-strikethrough',
				action: (e: Event) => this.toggleMarkByType(this.schema.marks.del, e)
			}
		];

		this.listItems = [
			{
				name: 'ul',
				disabled: true,
				tooltip: 'widget.text_ul',
				icon: 'q-icon-list',
				action: this.toggleUl
			},
			{
				name: 'ol',
				disabled: true,
				tooltip: 'widget.text_ol',
				icon: 'q-icon-list-numbered',
				action: this.toggleOl
			},
			{
				name: 'indent',
				disabled: true,
				tooltip: 'widget.text_indent',
				icon: 'q-icon-indent',
				action: this.indent
			},
			{
				name: 'outdent',
				disabled: true,
				tooltip: 'widget.text_outdent',
				icon: 'q-icon-outdent',
				action: this.outdent
			}
		];

		this.fontFamily = {
			name: 'fontFamily',
			disabled: true,
			tooltip: 'widget.text_fontName',
			action: this.changeFont,
			options: FontOptions.getOptions().map(font => {
				return {
					name: font.name,
					value: font.css.replace(/\'|\"/g, ''),
					css: 'font-' + font.name.replace(' ', '-').toLowerCase()
				};
			})
		};
		this.fontFamily.selected = this.fontFamily.options[9];

		this.fontSize = {
			name: 'fontSize',
			disabled: true,
			tooltip: 'widget.text_fontSize',
			action: this.changeFontSize,
			options: [
				{ name: locale.getString('widget.text_font_small'), value: '3', css: 'font-small' },
				{ name: locale.getString('widget.text_font_medium'), value: '4', css: 'font-medium' },
				{ name: locale.getString('widget.text_font_large'), value: '5', css: 'font-large' },
				{ name: locale.getString('widget.text_font_x-large'), value: '6', css: 'font-x-large' },
				{ name: locale.getString('widget.text_font_xx-large'), value: '7', css: 'font-xx-large' }
			]
		};
		this.fontSize.selected = this.fontSize.options[0];

		this.tag = {
			name: 'tag',
			disabled: true,
			tooltip: 'widget.text_tag',
			action: this.changeTag,
			options: [
				{ name: this.locale.getString('widget.headingSize', {size: '2'}), value: 'h2' },
				{ name: this.locale.getString('widget.headingSize', {size: '3'}), value: 'h3' },
				{ name: this.locale.getString('widget.headingSize', {size: '4'}), value: 'h4' },
				{ name: this.locale.getString('widget.headingSize', {size: '5'}), value: 'h5' },
				{ name: this.locale.getString('widget.headingSize', {size: '6'}), value: 'h6' },
				{ name: this.locale.getString('widget.text_p'), value: 'paragraph' },
				{ name: this.locale.getString('widget.text_pre'), value: 'code_block' }
			]
		};
		this.tag.selected = this.tag.options[6];

		this.quoteItem = {
			name: 'blockquote',
			disabled: true,
			tooltip: 'widget.text_quote',
			icon: 'q-icon-quote-2',
			action: this.toggleQuote
		};

		this.fontColor = {
			name: 'fontColor',
			disabled: true,
			tooltip: 'widget.text_fontColor',
			action: this.changeFontColor,
			icon: 'q-icon-text-2'
		};

		this.highlightColor = {
			name: 'highlightColor',
			disabled: true,
			tooltip: 'widget.text_hiliteColor',
			action: this.changeHighlightColor,
			icon: 'q-icon-highlighter'
		};

		this.clearItem = {
			name: 'clear',
			disabled: false,
			tooltip: 'widget.text_clear',
			action: this.clearMarks,
			icon: 'q-icon-accessdenied'
		};

		this.image = {
			name: 'image',
			disabled: false,
			tooltip: 'widget.text_image',
			action: this.embedImage,
			icon: 'q-icon-photo'
		};

		this.linkItem = {
			name: 'link',
			disabled: true,
			tooltip: 'widget.text_insertLink',
			action: this.handleLink,
			icon: 'q-icon-link'
		};

		this.hierarchy = {
			name: 'hierarchyEnrichments',
			disabled: true,
			tooltip: 'widget.text_hierarchyEnrichments',
			action: this.insertHierarchy,
			icon: 'q-icon-flow',
			options: []
		};

		this.alignItems = [
			{
				name: 'left',
				disabled: true,
				active: true,
				tooltip: 'widget.text_justifyLeft',
				action: (event: Event) => this.changeAlignment('left', event),
				icon: 'q-icon-align-left'
			},
			{
				name: 'center',
				disabled: true,
				active: false,
				tooltip: 'widget.text_justifyCenter',
				action: (event: Event) => this.changeAlignment('center', event),
				icon: 'q-icon-align-center'
			},
			{
				name: 'right',
				disabled: true,
				active: false,
				tooltip: 'widget.text_justifyRight',
				action: (event: Event) => this.changeAlignment('right', event),
				icon: 'q-icon-align-right'
			},
			{
				name: 'justify',
				disabled: true,
				active: false,
				tooltip: 'widget.text_justifyFull',
				action: (event: Event) => this.changeAlignment('justify', event),
				icon: 'q-icon-align-justify'
			}
		];

		this.rotationOptions = [
			{ value: '', icon: 'q-icon-arrow-right', name: 'widget.text_rotateNone' },
			{ value: TextRotation.DOWN, icon: 'q-icon-arrow-down', name: 'widget.text_rotateDown' },
			{ value: TextRotation.UP, icon: 'q-icon-arrow-up', name: 'widget.text_rotateUp' },
		];

		this.valignOptions = [
			{ value: '', icon: 'q-icon-align-image-top', name: 'widget.text_valignTop' },
			{ value: TextVAlign.MIDDLE, icon: 'q-icon-align-image-middle', name: 'widget.text_valignMiddle' },
			{ value: TextVAlign.BOTTOM, icon: 'q-icon-align-image-bottom', name: 'widget.text_valignBottom' }
		];

	}

	ngOnInit(): void {
		this.hierarchy.options = this.hierarchyEnrichmentOptions;

		let plugins = [
			buildInputRules(this.schema),
			keymap(buildKeymap(this.schema, undefined)),
			keymap(baseKeymap),
			keymap({
				Tab: goToNextCell(1),
				'Shift-Tab': goToNextCell(-1)
			}),
			dropCursor(),
			gapCursor(),
			history(),
			tableEditing(),
			columnResizing(undefined),
			new Plugin({
				props: {
					attributes: { class: 'font-x-small-scale', 'aria-label': this.getLabel() }
				}
			})
		];

		// this function is called by prosemirror on every state change of view, runs outside angular
		let dispatchTransaction = (tr) => {
			this.view.updateState(this.view.state.apply(tr));
			this.updateToolbar();
			if (tr.docChanged) {
				this.updateLengthTimed();
			}
		};

		let nodeViews = {
			image: (node, view, getPos): ImageView => {
				let imageViewInstance = new ImageView(node, view, getPos);
				imageViewInstance.openImageSettingsDialog = this.openImageSettingsDialog;
				return imageViewInstance;
			}
		};

		let doc = this.parseHtml(this.content);
		let stateProps = { doc, plugins };
		let state = EditorState.create(stateProps);
		this.ngZone.runOutsideAngular(() => {
			// all listeners and actions initiated by prosemirror will run outside angular
			this.view = new EditorView(document.querySelector('#text-editor .text-container'), { state, nodeViews, dispatchTransaction });
		});
		this.updateRotationValign();
		this.updateToolbar();
		this.updateContent();
		this.focus();
		this.renderer.listen(document.querySelector('#text-editor .text-container .ProseMirror'), 'blur', () => {
			this.updateContent();
		});

		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				this.updateContainerWidth();
			});
		});
	}

	openImageSettingsDialog = (entry: ImageSettingsEntry): Promise<any> => {
		return this.cxDialogService.openDialog(ImageSettingsModalComponent, entry).result;
	}

	emitLength = () => {
		this.ngZone.run(() => {
			this.lengthChange.emit(this.length);
		});
	}

	parseHtml = (html: string): any => {
		let node = document.createElement('div');
		(node as any).replaceChildren();
		const nodes = HTMLStringUtils.stringToDOM(this.textSanitizer.transform(html));
		while (nodes.length) {
			// nodes disappear from HTMLCollection as they are moved to div
			node.appendChild(nodes[0]);
		}
		return DOMParser.fromSchema(this.schema).parse(node);
	}

	getHtml = (content?: any): string => {
		let fragment = DOMSerializer.fromSchema(this.schema).serializeFragment(content || this.view.state.doc.content);
		let tmp = document.createElement('div');
		tmp.appendChild(fragment);
		return tmp.innerHTML;
	}

	setHtml = (html: string): void => {
		let newProps = {
			plugins: this.view.state.plugins,
			doc: this.parseHtml(html)
		};
		this.view.updateState(EditorState.create(newProps));
	}

	toggleHtml = () => {
		this.showHtml = !this.showHtml;
		if (this.showHtml) {
			this.rawHtml = HTMLStringUtils.formatHtml(this.getHtml());
		} else {
			this.setHtml(this.rawHtml);
			this.focus();
		}
		this.updateContent();
		this.updateToolbar();
	}


	updateLength = () => {
		this.ngZone.runOutsideAngular(() => {
			if (this.inputTimer) {
				clearTimeout(this.inputTimer);
				this.inputTimer = null;
			}
		});
		this.length = this.showHtml
			? $(`<div>${this.parseHtml(this.rawHtml)}</div>`).text().length
			: this.view.state.doc.textContent.length;
		this.emitLength();
	}

	updateContent = () => {
		this.content = this.showHtml
			? this.getHtml(this.parseHtml(this.rawHtml))
			: this.getHtml();
		this.updateLength();
		this.contentChange.emit(this.content);
	}

	// timer runs outside angular, but will enter angular zone to emit changes after updating length
	updateLengthTimed = () => {
		this.ngZone.runOutsideAngular(() => {
			if (this.inputTimer) {
				clearTimeout(this.inputTimer);
				this.inputTimer = null;
			}
			this.inputTimer = setTimeout(() => {
				this.inputTimer = null;
				this.updateLength();
			}, 200);
		});
	}

	updateRawHtml = (value: string) => {
		this.rawHtml = value;
		this.updateLengthTimed();
	}

	focus = () => {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				this.view.focus();
			});
		});
	}

	// toolbar

	// runs outside angular when called by prosemirror (on keypress, selection change, etc.)
	updateToolbar = (): void => {
		let blocks: any[] = this.getBlocksInSelection();
		let textBlocks: any[] = blocks.filter((block) => block.isTextblock);
		let markPositions: any[] = this.getMarkPositionsInSelection();
		this.updateCommonToolbarItems(textBlocks, markPositions.map(mp => mp.mark));
		if (!this.isLabel) {
			this.updateExtraToolbarItems(blocks, textBlocks, markPositions);
		}
	}

	private updateCommonToolbarItems = (textBlocks: any[], marks: any[]) => {
		this.clearItem.disabled = this.showHtml;
		this.hierarchy.disabled = this.showHtml;
		this.fontColor.disabled = this.showHtml;
		this.highlightColor.disabled = this.showHtml;

		let markTypes = this.schema.marks;

		this.textItems.forEach(item => {
			let markType = markTypes[item.name];
			item.active = !this.showHtml && this.isActive(markType);
			item.disabled = this.showHtml || !this.isApplicable(markType);
		});

		let font = this.getMarkAttribute(marks, markTypes.fontFamily, 'font');
		this.fontFamily.selected = _.findWhere(this.fontFamily.options, { value: font }) || this.fontFamily.options[9];
		let size = this.getMarkAttribute(marks, markTypes.fontSize, 'size');
		this.fontSize.selected = _.findWhere(this.fontSize.options, { value: '' + size }) || this.fontSize.options[0];

		this.fontColor.selected = this.getMarkAttribute(marks, markTypes.fontColor, 'color');
		this.highlightColor.selected = this.getMarkAttribute(marks, markTypes.highlightColor, 'color');

		let align = null;
		let alignDisabled = true;
		for (let block of textBlocks) {
			if (block.type.attrs.align) {
				alignDisabled = false;
				if (block.attrs.align) {
					align = block.attrs.align;
					break;
				}
			}
		}
		this.alignItems.forEach(item => {
			if (this.showHtml || alignDisabled) {
				item.disabled = true;
				item.active = false;
				return;
			}
			if (!align && item.name === 'left') {
				item.active = true;
			} else {
				item.active = item.name === align;
			}
			item.disabled = false;
		});

	}

	private updateExtraToolbarItems = (blocks: any[], textBlocks: any[], markPositions: any[]) => {
		let tagName = 'paragraph';
		for (let block of textBlocks) {
			if (/^(heading|paragraph|code_block)$/g.test(block.type.name)) {
				tagName = block.attrs?.level ? 'h' + block.attrs.level : block.type.name;
				break;
			}
		}
		this.tag.selected = _.findWhere(this.tag.options, { value: tagName }) || this.tag.options[6];

		let ul = this.schema.nodes.bullet_list;
		let ol = this.schema.nodes.ordered_list;
		let listNode = this.getParentListNode();
		this.listItems.forEach(item => {
			if (this.showHtml) {
				item.disabled = true;
				item.active = false;
				return;
			}
			switch (item.name) {
				case 'ul':
					item.disabled = !listNode && !wrapInList(ul)(this.view.state);
					item.active = listNode && listNode.node.type === ul;
					item.selected = listNode;
					break;
				case 'ol':
					item.disabled = !listNode && !wrapInList(ol)(this.view.state);
					item.active = listNode && listNode.node.type === ol;
					item.selected = listNode;
					break;
				case 'indent': item.disabled = !this.canIndent(); break;
				case 'outdent': item.disabled = !this.canOutdent(); break;
			}
		});

		this.image.disabled = this.showHtml || !this.view.state.selection.empty;

		this.quoteItem.active = !this.showHtml && _.findWhere(blocks, { type: this.schema.nodes.blockquote });
		this.quoteItem.disabled = this.showHtml;

		let linkMarkPos = _.find(markPositions, mp => mp.mark.type === this.schema.marks.link);
		this.linkItem.selected = linkMarkPos;
		this.linkItem.active = !this.showHtml && !!this.linkItem.selected;
		this.linkItem.disabled = this.showHtml || (!this.linkItem.active && this.view.state.selection.empty);
		this.updateContainerWidth();
	}

	getParentListNode = () => {
		let ol = this.schema.nodes.ordered_list;
		let ul = this.schema.nodes.bullet_list;
		let { $from, $to } = this.view.state.selection;
		if ($from.parent !== $to.parent) {
			let depth = 0;
			while (depth < $from.depth && depth < $to.depth) {
				if ($from.node(depth + 1) === $to.node(depth + 1)) {
					depth = depth + 1;
					continue;
				}
				break;
			}
			if (!depth) return;
			let pos = $from.before(depth);
			let node = $from.doc.nodeAt(pos);
			return node.type === ol || node.type === ul
				? { pos, node }
				: this.getClosestListNode($from.doc.resolve(pos));
		}
		return this.getClosestListNode($from);
	}

	getClosestListNode = ($pos) => {
		let ol = this.schema.nodes.ordered_list;
		let ul = this.schema.nodes.bullet_list;
		for (let i = $pos.depth; i > 0; i--) {
			let node = $pos.node(i);
			if (node.type === ol || node.type === ul) {
				return {
					pos: i > 0 ? $pos.before(i) : 0,
					node
				};
			}
		}
	}

	getMarkPositionsInSelection = (): any => {
		let markPositions = [];
		let { from, to, $from, empty } = this.view.state.selection;
		if (empty) {
			let marks = this.view.state.storedMarks || $from.marks() || [];
			return marks.map((mark) => {
				return { mark, pos: from - $from.parentOffset };
			});
		}
		this.view.state.doc.nodesBetween(from, to, (node, pos) => {
			if (this.schema.spec.marks.size <= markPositions.length) {
				return false;
			}
			if (!node.isInline) {
				return;
			}
			node.marks.forEach((mark) => {
				if (!_.some(markPositions, mp => mp.type === mark.type)) {
					markPositions.push({ mark, pos });
				}
			});
		});
		return markPositions;
	}

	getMarkAttribute = (marks: any[], markType: any, attrName: string) => {
		let result;
		for (let mark of marks) {
			if (mark.type === markType && mark.attrs[attrName]) {
				result = mark.attrs[attrName];
				break;
			}
		}
		return result;
	}

	getBlocksInSelection = (): any => {
		let state = this.view.state;
		let blocks = [];
		state.doc.nodesBetween(state.selection.from, state.selection.to, (node) => {
			if (node.isBlock) {
				blocks.push(node);
			}
		});
		return blocks;
	}

	isActive = (markType: any): boolean => {
		let { from, $from, to, empty } = this.view.state.selection;
		if (empty) {
			return !!markType.isInSet(this.view.state.storedMarks || $from.marks());
		}
		return this.view.state.doc.rangeHasMark(from, to, markType);
	}

	isApplicable = (markType: any): boolean => {
		return toggleMark(markType)(this.view.state);
	}

	canWrap = (nodeType, attrs?: any): boolean => {
		return wrapIn(nodeType)(this.view.state);
	}

	canIndent = (): boolean => {
		const li = this.schema.nodes.list_item;
		const { $from } = this.view.state.selection;
		return $from.node(-1)?.type === li && sinkListItem(li)(this.view.state);
	}

	canOutdent = (): boolean => {
		const li = this.schema.nodes.list_item;
		const { $from } = this.view.state.selection;
		return ($from.node(-1)?.type === li && liftListItem(li)(this.view.state));
	}

	//actions

	toggleMarkByType = (markType: any, event?: Event) => {
		if (event) event.preventDefault();
		toggleMark(markType)(this.view.state, this.view.dispatch);
	}

	changeFont = (option) => {
		let markType = this.schema.marks.fontFamily;
		if (option.value !== this.fontFamily.options[9].value) {
			this.applyMark(markType, { font: option.value });
		} else if (this.isActive(markType)) {
			this.toggleMarkByType(markType);
		}
		this.focus();
		this.fontFamily.selected = option;
	}

	changeFontSize = (option) => {
		let markType = this.schema.marks.fontSize;
		if (option.value !== '3') {
			this.applyMark(markType, { size: option.value });
		} else if (this.isActive(markType)) {
			this.toggleMarkByType(markType);
		}
		this.focus();
		this.fontSize.selected = option;
	}

	changeTag = (option) => {
		let nodeType;
		let attrs;
		if (/^h[1-6]$/g.test(option.value)) {
			nodeType = this.schema.nodes.heading;
			nodeType.attrs.level.hasDefault = false;
			attrs = { level: parseInt(option.value.slice(1), 10) };
		} else {
			nodeType = this.schema.nodes[option.value];
		}
		setBlockType(nodeType, attrs)(this.view.state, this.view.dispatch);
		this.focus();
		this.tag.selected = option;
	}

	clearMarks = (event: Event) => {
		event.preventDefault();
		let state = this.view.state;
		let { from, to, $cursor } = state.selection as any;
		if ($cursor) {
			let marks = state.storedMarks || $cursor.marks() || [];
			let tr = state.tr;
			marks.forEach(mark => {
				tr.removeStoredMark(mark.type);
			});
			this.view.dispatch(tr);
		} else {
			this.view.dispatch(state.tr.removeMark(from, to));
		}
	}

	embedImage = (event: Event) => {
		event.preventDefault();
		this.cxDialogService.openDialog(ImageGalleryDialogComponent, {
			properties: {} as ContentWidgetProperties,
			dashboardId: this.dashboardId
		}, {size: ModalSize.EXTRA_LARGE}).result.then((res: ContentWidgetProperties) => {
			let { state, dispatch } = this.view;
			let imageSrc = res.imageUrl;
			if (res.imageName) {
				imageSrc = this.urlService.getDashboardImageUrl(res.imageName, this.dashboardId);
			}
			let imageNode = this.schema.nodes.image.create({src: imageSrc});
			dispatch(state.tr.replaceSelectionWith(imageNode));
		});
	}

	toggleQuote = (event: Event) => {
		event.preventDefault();
		let nodeType = this.schema.nodes.blockquote;
		let { $from, $to, from, to } = this.view.state.selection;
		let tr = this.view.state.tr;
		let range: NodeRange;
		if (this.quoteItem.active) {
			let doc = this.view.state.doc;
			doc.nodesBetween(from, to, (node, pos) => {
				if (node.type === nodeType) {
					range = new NodeRange(doc.resolve(pos + 1), doc.resolve(pos + node.nodeSize - 1), 1);
					tr.lift(range, 0);
				}
				return false;
			});
		} else {
			range = new NodeRange($from, $to, 0);
			tr.wrap(range, [{ type: nodeType }]);
		}
		this.view.dispatch(tr.scrollIntoView());
	}

	toggleUl = (event: Event) => {
		event.preventDefault();
		let li = this.schema.nodes.list_item;
		let ul = this.schema.nodes.bullet_list;
		let { state, dispatch } = this.view;
		if (this.listItems[0].active) {
			liftListItem(li)(state, dispatch);
		} else if (this.listItems[1].active) {
			autoJoin((st, disp) => {
				disp(st.tr.setNodeMarkup(this.listItems[0].selected.pos, ul));
				return true;
			}, ['bullet_list'])(state, dispatch);
		} else {
			autoJoin(wrapInList(ul), ['bullet_list'])(state, dispatch);
		}
	}

	toggleOl = (event: Event) => {
		event.preventDefault();
		let li = this.schema.nodes.list_item;
		let ol = this.schema.nodes.ordered_list;
		let { state, dispatch } = this.view;
		if (this.listItems[1].active) {
			liftListItem(li)(state, dispatch);
		} else if (this.listItems[0].active) {
			autoJoin((st, disp) => {
				disp(st.tr.setNodeMarkup(this.listItems[1].selected.pos, ol));
				return true;
			}, ['ordered_list'])(state, dispatch);
		} else {
			autoJoin(wrapInList(ol), ['ordered_list'])(state, dispatch);
		}
	}

	indent = (event: Event) => {
		event.preventDefault();
		let li = this.schema.nodes.list_item;
		sinkListItem(li)(this.view.state, this.view.dispatch);
	}

	outdent = (event: Event) => {
		event.preventDefault();
		let li = this.schema.nodes.list_item;
		liftListItem(li)(this.view.state, this.view.dispatch);
	}

	changeAlignment = (align: string, event: Event) => {
		event.preventDefault();
		if (align === 'left') {
			align = null;
		}
		let { state, dispatch } = this.view;
		let tr = state.tr;
		state.doc.nodesBetween(state.selection.from, state.selection.to, (node: any, pos: number) => {
			if (node.isTextblock && node.type.attrs.align && node.attrs.align !== align) {
				tr.setNodeMarkup(pos, undefined, {...node.attrs, align });
			}
		});
		dispatch(tr.scrollIntoView());
	}

	changeFontColor = () => {
		let markType = this.schema.marks.fontColor;
		if (this.fontColor.selected) {
			this.applyMark(markType, { color: this.fontColor.selected });
		} else if (this.isActive(markType)) {
			this.toggleMarkByType(markType);
		}
		this.focus();
	}

	changeHighlightColor = () => {
		let markType = this.schema.marks.highlightColor;
		if (this.highlightColor.selected) {
			this.applyMark(markType, { color: this.highlightColor.selected });
		} else if (this.isActive(markType)) {
			this.toggleMarkByType(markType);
		}
		this.focus();
	}

	insertHierarchy = (option: string, event?: Event) => {
		if (event) {
			event.preventDefault();
		}
		this.hierarchy.active = false;
		let { state, dispatch } = this.view;
		let { from, to } = state.selection;
		dispatch(state.tr.insertText('{' + option + '}', from, to));
	}

	handleLink = (event: Event) => {
		event.preventDefault();
		let href;
		let title;
		if (this.linkItem.active) {
			href = this.linkItem.selected.mark.attrs.href;
			title = this.linkItem.selected.mark.attrs.title;
		}
		this.cbDialogService.showLinkDialog(this.updateLink, this.removeLink, href, title).result
			.finally(() => this.focus());
	}

	updateLink = (modalInstance, data) => {
		modalInstance.close();
		let { initialHref, initialTitle, href, title } = data;
		if (!(/^https?:\/\//ig.test(href))) {
			href = 'https://' + href;
		}
		let linkType = this.schema.marks.link;
		if (!this.linkItem.active) {
			this.applyMark(linkType, { href, title });
		} else {
			let oldMark = linkType.create({ href: initialHref, title: initialTitle });
			let newMark = linkType.create({ href, title });
			let { from, to } = this.getMarkRange(oldMark, this.linkItem.selected.pos);
			let tr = this.view.state.tr;
			tr.removeMark(from, to, oldMark);
			tr.addMark(from, to, newMark);
			this.view.dispatch(tr.scrollIntoView());
		}
	}

	removeLink = (modalInstance, data) => {
		modalInstance.close();
		let tr = this.view.state.tr;
		let linkType = this.schema.marks.link;
		let { from, to } = this.getParentNodeRange(this.linkItem.selected.pos);
		let { initialHref, initialTitle } = data;
		tr.removeMark(from, to, linkType.create({ href: initialHref, title: initialTitle }));
		this.view.dispatch(tr.scrollIntoView());
	}

	getParentNodeRange = (nodePos: number) => {
		let { parent, pos, parentOffset } = this.view.state.doc.resolve(nodePos);
		let from = pos - parentOffset;
		let to = from + parent.nodeSize - 1;
		return { from, to };
	}

	getMarkRange = (mark: any, nodePos: number) => {
		let { from, to } = this.getParentNodeRange(nodePos);
		let start;
		let end;
		this.view.state.doc.nodesBetween(from, to, (node, pos) => {
			let hasMark = mark.isInSet(node.marks);
			if (hasMark) {
				end = pos + node.nodeSize;
				if (start === undefined) {
					start = pos;
				}
			} else if (end !== undefined) {
				return false;
			}
		});
		return { from: start, to: end };
	}

	applyMark = (markType: any, attrs: any) => {
		if (!this.isApplicable(markType)) {
			return;
		}

		let { state, dispatch } = this.view;
		let { $cursor, ranges } = state.selection as any;

		if ($cursor) {
			let tr = state.tr;
			if (markType.isInSet(state.storedMarks || $cursor.marks())) {
				tr.removeStoredMark(markType);
			}
			dispatch(tr.addStoredMark(markType.create(attrs)));
		} else {
			let has = false;
			let tr = state.tr;
			for (let i = 0; !has && i < ranges.length; i++) {
				let { $from, $to } = ranges[i];
				has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
			}
			for (let range of ranges) {
				let { $from, $to } = range;
				if (has) {
					tr.removeMark($from.pos, $to.pos, markType);
				}
				tr.addMark($from.pos, $to.pos, markType.create(attrs));
			}
			dispatch(tr.scrollIntoView());
		}
	}

	rotate = (direction: string, event?: Event) => {
		if (event) event.preventDefault();
		if (direction !== this.rotation) {
			this.rotation = direction;
			this.updateRotationValign();
			this.rotationChange.emit(direction);
		}
	}

	alignVert = (align: string, event?: Event) => {
		if (event) event.preventDefault();
		if (align !== this.valign) {
			this.valign = align;
			this.updateRotationValign();
			this.valignChange.emit(align);
		}
	}

	private updateRotationValign = () => {
		let container = document.querySelector('#text-editor .text-container');
		container.classList.remove(TextVAlign.MIDDLE, TextVAlign.BOTTOM, TextRotation.UP, TextRotation.DOWN);
		if (this.valign) {
			container.classList.add(this.valign);
		}
		if (this.rotation) {
			container.classList.add(this.rotation);
		}
		this.updateContainerWidth();
	}

	private updateContainerWidth = () => {
		let container = document.querySelector('#text-editor .text-container') as any;
		if (!container) {
			return;
		}

		if (this.rotation) {
			let content = document.querySelector('#text-editor .ProseMirror');
			container.style.width = window.getComputedStyle(content).height;
		} else if (container.style.width) {
			container.style.width = '';
		}
	}

	getOptionIcon = (options: any[], value: string): string => {
		return _.findWhere(options, { value })?.icon;
	}

	getLabel = (): string => {
		return this.locale.getString('dashboard.charactersLimit', {count: this.maxLength});
	}

	@HostListener('document:selectionchange')
	onTextSelected(): void {
		setTimeout(() => this.ref.markForCheck());
	}

}
