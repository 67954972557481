<div class="form-group">
	<label>{{'selectorWidget.navigationType'|i18n}}</label>
	<div class="d-flex align-items-center">
		<cb-push-buttons
			class="_t-selector-visualization"
			[list]="options"
			[selectedValue]="visualization"
			(selectedValueChange)="visualizationChange.emit($event)"
		></cb-push-buttons>

		<div *ngIf="isMultiSelectVisible()">
			<checkbox-button
				label="{{'selectorWidget.multiselect'|i18n}}"
				[ngModel]="multiselect"
				(ngModelChange)="multiselectChange.emit($event)">
			</checkbox-button>
			<cb-inline-help>
				<help-body>{{'selectorWidget.multiselectHelpText'|i18n}}</help-body>
			</cb-inline-help>
		</div>
	</div>
</div>
