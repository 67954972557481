import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CxLocaleService } from '@app/core';

export enum WizardTagMode {
	COMPLETED = 'COMPLETED',
	UPDATE_REQIURED = 'UPDATE_REQIURED',
	NO_CHANGES = 'NO_CHANGES',
	ERROR = 'ERROR'
}

@Component({
	selector: 'cx-wizard-tag',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="text-white border-radius-4 text-sm-1"
				[ngClass]="background">
			<div class="d-flex ph-8 pv-4 align-items-center overflow-hidden">
				<span class="mr-8 q-icon" [ngClass]="icon" aria-hidden="true"></span>
				<div class="d-flex flex-direction-column">
					<p class="p-0 m-0 overflow-hidden">{{text}}</p>
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	`
})

export class WizardTagComponent implements OnInit, OnChanges {
	@Input() type: WizardTagMode | string;
		
	icon: string;
	background: string;
	text: string;
	
	constructor(
		private locale: CxLocaleService,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.process();
	}

	ngOnInit(): void {
		this.process();
	}

	private process(): void {
		this.type = this.type as WizardTagMode;
		switch (this.type) {
			case WizardTagMode.COMPLETED:
				this.icon = 'q-icon-check';
				this.background = 'bg-success-600';
				this.text = this.locale.getString('common.completed');
				break;
			case WizardTagMode.UPDATE_REQIURED:
				this.icon = 'q-icon-warning';
				this.background = 'bg-warning-600';
				this.text = this.locale.getString('common.updateRequired');
				break;
			case WizardTagMode.ERROR:
				this.icon = 'q-icon-warning';
				this.background = 'bg-warning-600';
				this.text = this.locale.getString('common.error');
				break;
			case WizardTagMode.NO_CHANGES:
				this.icon = 'q-icon-check';
				this.background = 'bg-gray-600';
				this.text = this.locale.getString('common.noChanges');
				break;	
			default:	
				delete this.type;
		}
	}

}

