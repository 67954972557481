import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IReportWidgetContext, Replacement } from '@cxstudio/reports/widgets/report-widget.class';
import { SimpleReportWidget } from '@cxstudio/reports/widgets/simple-report-widget.class';

export class CloudWidget extends SimpleReportWidget {

	readonly DEFAULT_GROUPING_SIZE = 100;

	constructor(private metricConstants: MetricConstants) {
		super(WidgetType.CLOUD);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);

		super.initSimpleReport(context);
		this.widget.visualProperties.visualization = WidgetVisualization.CLOUD;

		super.withShowSampleSize(context);

		this.initGroupings(context, 1, true);
		super.initSingleCalculation(context, this.metricConstants.get().VOLUME);
	}

	protected initGroupings(context: IReportWidgetContext, count: number, emptyAllowed: boolean): void {
		super.initGroupings(context, count, emptyAllowed);

		//add additional properties for Cloud Widget
		let groupings = this.widget.properties.selectedAttributes;
		if (groupings.length > 0) {
			this.widget.visualProperties.primaryGroup = groupings[0].name;
			if (groupings[0].size && groupings[0].size < this.DEFAULT_GROUPING_SIZE) {
				this.widget.properties.selectedAttributes[0].size = this.DEFAULT_GROUPING_SIZE;
			}
		}
	}
}
