import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AssetTemplateLazyResources, AssetTemplateResourcesService } from '@app/modules/unified-templates/common-templates/asset-template-resources.service';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-key';
import { TemplatePlaceholderReplacement } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-replacement';
import { TemplateReplacementData } from '@app/modules/unified-templates/common-templates/dto/template-replacement-data';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { PlaceholderDisplayType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';

@Component({
	selector: 'template-replacements-list',
	templateUrl: './template-replacements-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
		.h-56 {
			height: 56px;
		}
		`
	]
})
export class TemplateReplacementsListComponent extends SelfCleaningComponent implements OnInit, OnChanges {

	@Input() project: WorkspaceProject;
	@Input() replacements: TemplatePlaceholderReplacement[];
	@Input() replacementsLabel: string;
	@Output() update = new EventEmitter<void>();
	resources: AssetTemplateLazyResources;

	loading: Promise<any> = Promise.resolve();

	PlaceholderDisplayType = PlaceholderDisplayType;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private assetTemplateResources: AssetTemplateResourcesService,
	) {
		super();
	}

	ngOnInit(): void {
		this.initProject(this.project);
	}

	ngOnChanges(changes: SimpleChanges<TemplateReplacementsListComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.initProject(changes.project.currentValue);
		}
	}

	private initProject(project: WorkspaceProject): void {
		this.resources = this.assetTemplateResources.getLazyResources(project);
		this.addSubscription(this.resources.loadingChange.subscribe(loading => {
			this.loading = this.loading.then(() => loading);
			this.ref.markForCheck();
		}));
	}

	isStandardReplacementSelector(replacement: TemplatePlaceholderReplacement): boolean {
		let standardReplacements = [
			PlaceholderDisplayType.SELECTION,
			PlaceholderDisplayType.GROUPING,
			PlaceholderDisplayType.CALCULATION
		];
		return standardReplacements.includes(replacement.displayType);
	}

	shouldUseFullNodeReplacement(replacement: TemplatePlaceholderReplacement): boolean {
		let widgetReplacements = [
			PlaceholderDisplayType.GROUPING,
			PlaceholderDisplayType.CALCULATION
		];
		return widgetReplacements.includes(replacement.displayType);
	}

	applyReplacement(replacement: TemplatePlaceholderReplacement, replacementData: TemplateReplacementData): void {
		replacement.replacementData = replacementData;
		this.update.emit();
	}

	getOriginalLabel(type: TemplatePlaceholderType): string {
		return this.locale.getString(`templates.replacement_${type}`);
	}

	getReplacementLabel(type: TemplatePlaceholderType): string {
		return this.locale.getString('templates.replacementNew', {type: this.getOriginalLabel(type)});
	}

}
