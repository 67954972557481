import { ChangeDetectorRef, ElementRef, EventEmitter, Input, OnDestroy, Output, Directive } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import widgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import ResizeObserver from 'resize-observer-polyfill';
import { ElementResizer } from './element-resizer';
import { WidgetContentEvent, VisualizationComponent } from './visualization-component.interface';
import { WidgetError } from './common/widget-error/widget-error.class';


@Directive()
export class GenericVisualizationBaseComponent<T = ReportDataObject> implements OnDestroy, VisualizationComponent<T> {
	@Input() widget: Widget;
	@Input() dataObject: T;
	@Input() demo: boolean;
	@Input() utils: widgetUtils;

	@Output() handleRightClick = new EventEmitter<{event: MouseEvent}>();
	@Output() handleClick = new EventEmitter<{event: MouseEvent, point: DrillPoint, isRightClick?: boolean}>();
	@Output() contentEvent = new EventEmitter<WidgetContentEvent>();

	widgetError: WidgetError;

	constructor(
		protected ref: ChangeDetectorRef,
		protected reportUtils: ReportUtils,
	) { }

	resizeObserver: ResizeObserver;

	protected onChartResize(chart: Highcharts.Chart, currentSize: string, lastSize: string) {
		this.reportUtils.handleChartResize(chart, currentSize, lastSize);
	}

	protected checkError(widgetError: WidgetError): boolean {
		if (widgetError) {
			this.widgetError = widgetError;
			this.ref.markForCheck();
			this.markRendered();
			return true;
		} else if (this.widgetError) {
			delete this.widgetError;
			this.ref.markForCheck();
		}
		return false;
	}

	hasError(): boolean {
		return !!this.widgetError;
	}

	protected markRendered(): void {
		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
	}

	protected initResizer(element: ElementRef, callback: (newSize: string, oldSize: string) => void): void {
		let resizer = new ElementResizer(element.nativeElement, callback);
		let throttledResize = _.throttle(() => {
			resizer.resize();
		}, 200);

		this.resizeObserver = new ResizeObserver(throttledResize);
		this.resizeObserver.observe(element.nativeElement);
	}

	ngOnDestroy(): void {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
			delete this.resizeObserver;
		}
	}

}
