import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';

export class FilterAsset extends ProjectAsset {
	type: FilterTypes;

	constructor(contentProviderId: number, projectId: number, assetId: number, type: FilterTypes) {
		super(contentProviderId, projectId, assetId);
		this.type = type;
	}
}