export enum SettingsGroup {
	SHOW_QUANTITY = 'SHOW_QUANTITY',
	NULL_INCLUDE = 'NULL_INCLUDE',
	EMPTY_PERIODS = 'EMPTY_PERIODS',
	CAPITALIZATION = 'CAPITALIZATION',
	LIMIT_TO = 'LIMIT_TO',
	WHERE = 'WHERE',
	URL = 'URL',
	NUMBER_FORMAT = 'NUMBER_FORMAT',
	INCLUSION_LIST = 'INCLUSION_LIST',
	AGGREGATION = 'AGGREGATION'
}
