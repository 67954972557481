import { IFilterRule, AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
export interface ITimezone {
	name: string;
	offset: number;
}

export interface IDriversColumn {
	name: string;
}

export interface IDriversModelColumn extends IDriversColumn {
	nodeIds?: number[];
	topicLevel?: number;
}

export interface IDriversDateRange {
	fromDate: string;
	toDate: string;
}

export class DriversDefinition {
	attributes?: IDriversColumn[];
	models?: IDriversModelColumn[];
	additionalFilters?: AdhocFilter;
	dateRange?: IDriversDateRange;
	timezone?: ITimezone;
	includeSentiment?: boolean;
	includeEaseScore?: boolean;
	includeTopicNames?: boolean;
}