import * as _ from 'underscore';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { BaseFolderApi } from '@cxstudio/common/folders/base-folder-api.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';


export class FilterFolderApi extends BaseFolderApi<IFolderItem> {

	constructor(
		$http: ng.IHttpService,
		treeService: TreeService,
		cachedHttpService: CachedHttpService,
	) {
		super('rest/filter', $http, treeService, cachedHttpService.cache(Caches.FILTERS));
	}

}

app.service('filterFolderApi', FilterFolderApi);
