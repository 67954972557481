<ng-container *ngFor="let group of sentenceGroups">
	<sentence-preview *ngFor="let sentence of group.sentences"
		[sentence]="sentence"
		[showSentiment]="showEnrichment"
		[sentimentColorFunc]="sentimentColorFunc"
		[document]="true"
		[highlightTrigger]="highlightTrigger"
		[selectedSentence]="selectedSentence"
		(sentenceClick)="sentenceClick.emit($event)">
	</sentence-preview>
	<sentence-pills *ngIf="showTopics || showEnrichment"
		class="sentence-topics lh-1 mv-4"
		[topics]="group.topics"
		[enrichmentPills]="group.pills"
		[auditMode]="auditMode"
		[enabledModels]="auditModeModels"
		[width]="messageWidth"
		[highlightTrigger]="highlightTrigger"
		[showAll]="expandTopics"
		[hasProfanity]="group.hasProfanity"
		[showTopics]="showTopics"
		[showEnrichments]="showEnrichment"
		(tuneEnrichment)="onTuneEnrichment($event, group)"
		(removeTopic)="onTopicRemove(group, $event)">
	</sentence-pills>
</ng-container>
