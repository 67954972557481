import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITableData } from '@cxstudio/reports/stats-mode/simple-table-data-builder';

export interface SimpleTableData extends ITableData {
	additionalInfo?: string;
}
@Component({
	selector: 'simple-table',
	template: `
<p *ngIf="tableData.additionalInfo">{{tableData.additionalInfo}}</p>
<table class="simple-table kb-focusable" [ngClass]="tableClass" [attr.aria-describedby]="descriptionId" tabindex="0">
	<thead *ngIf="tableData.header">
		<tr>
			<th *ngFor="let header of tableData.header">
				{{header}}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of tableData.body" [class.blank-row]="row.empty">
			<td *ngFor="let cell of row">{{cell}}</td>
		</tr>
	</tbody>
</table>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent {
	@Input() tableData: SimpleTableData;
	@Input() tableClass: string;
	@Input() descriptionId?: string;
}
