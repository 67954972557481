import { EventEmitter, Output } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Pill } from '@app/modules/pills/pill';

@Component({
	selector: 'context-pill',
	template: `
	<button *ngIf="pill"
		class="label-bubble ellipsis cursor-pointer h-32 border-radius-16 font-standard ph-8 mr-8 mb-8"
		[ngClass]="classes"
		[attr.title]="pill.title"
		(click)="pillClick.emit($event)"
		(mouseover)="pillMouseOver.emit()"
		(mouseout)="pillMouseOut.emit()"
		[attr.tabindex]="inert ? -1 : null"
		[innerHTML]="pill.html || pill.name">
	</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextPillComponent {
	@Input() pill: Pill;
	@Input() classes: string[];
	@Input() inert: boolean;
	@Output() pillClick: EventEmitter<any> = new EventEmitter();
	@Output() pillMouseOver: EventEmitter<void> = new EventEmitter();
	@Output() pillMouseOut: EventEmitter<void> = new EventEmitter();
}
