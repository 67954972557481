import {Component, OnInit, ChangeDetectionStrategy, Inject, Input} from '@angular/core';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CxLocaleService} from '@app/core';
import {ObjectUtils} from '@app/util/object-utils';
import {
	IRecolorManageDialogParams
} from '@app/modules/widget-visualizations/recolor-manage-dialog/recolor-manage-dialog-params';
import {
	FormatterBuilderUtilsService
} from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import {CapitalizationService} from '@cxstudio/services/capitalization.service';
import {StandardMetricName} from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import {RecolorItemProperties} from '@cxstudio/reports/entities/recolor-item-properties';

@Component({
	selector: 'recolor-manage-dialog',
	templateUrl: './recolor-manage-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecolorManageDialogComponent implements OnInit {
	@Input() input: IRecolorManageDialogParams;
	recolors: any[];
	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('analyticsDataFormatting') private analyticsDataFormatting: AnalyticsDataFormattingService,
		private formatterBuilderUtils: FormatterBuilderUtilsService,
		@Inject('capitalization') private capitalization: CapitalizationService
	) { }

	ngOnInit(): void {
		this.recolors = ObjectUtils.copy(this.input.visualProps.recolors) || [];
	}

	private getValueLabel(metric: AttributeGrouping, name: string): string {
		if (!metric) return this.locale.getString('common.emptyDash');

		let labelFormatter = this.getLabelFormatter(metric);

		if (metric.type === ReportAssetType.NUMBER) {
			return this.formatterBuilderUtils.formatNumberAsString(name);
		}

		if (!metric.definition) return labelFormatter(name);

		let displayName = this.analyticsDataFormatting.formatStudioMetricLabel(name, metric.definition, labelFormatter);
		return displayName === name ?
			this.locale.getString('common.emptyDash') :
			displayName;
	}

	private getLabelFormatter(metric): any {
		let groupDefinition;

		if (metric.capitalization) {
			groupDefinition = metric;
		} else if (metric.definition) {
			groupDefinition = _.find(this.input.props.selectedAttributes, {name: metric.name});
		} else if (metric.group) {
			groupDefinition = _.find(this.input.props.selectedAttributes, {group: metric.group});
		}

		return groupDefinition
			? this.capitalization.getWrappedFormatter(groupDefinition.capitalization)
			: this.capitalization.reflect;
	}

	cancel(): void {
		this.modal.dismiss('cancel');
	}

	save(): void {
		this.modal.close(this.recolors);
	}

	remove = (index: number) => {
		if (this.recolors && this.recolors.length > index) {
			this.recolors.removeAt(index);
		}
	}

	removeAll = () => {
		this.recolors.removeAll();
	}

	getColorType = (item: RecolorItemProperties) => {
		let type = item.colorType || 'color';
		let colortype = this.locale.getString(`widget.${type}RecolorLabel`);
		return colortype.capitalize();
	}


	getValue = (item: RecolorItemProperties) => {
		if (this.isPop(item.groupName)) {
			let formatter = this.analyticsDataFormatting.formatPeriodLabels(
				this.input.visualProps.periodLabel, this.input.utils.periods);
			return formatter(item.uniqueName);
		}
		return this.getValueLabel(this.getGrouping(item), item.uniqueName);
	}

	private getGrouping(item: RecolorItemProperties): any {
		if (!this.input.utils)
			return undefined;
		let result;
		if (this.input.utils.attributes && this.input.utils.attributes.length) {
			result = _.findWhere(this.input.utils.attributes, {name: item.groupName});
		}

		if (!result && this.input.utils.metrics && this.input.utils.metrics.length) {
			result = _.findWhere(this.input.utils.metrics, {name: item.groupName});
		}
		if (!result && this.input.props.selectedAttributes && this.input.props.selectedAttributes.length) {
			if (item.groupIdentifier) {
				result = GroupIdentifierHelper.findGroup(this.input.props.selectedAttributes, item.groupIdentifier);
			} else {
				result = _.findWhere(this.input.props.selectedAttributes, {name: item.groupName});
			}
		}
		return result;
	}

	isBarOrLine = () => {
		return (this.input.props.widgetType === WidgetType.LINE)
			|| (this.input.props.widgetType === WidgetType.BAR);
	}

	getGroupName = (item: RecolorItemProperties) => {
		if (this.isPop(item.groupName))
			return this.locale.getString('widget.period_over_period');

		if (this.input.utils) {
			let group = this.getGrouping(item);

			if (!group) {
				if (/.+\.time\.\w+/.test(item.groupName)) {
					return this.locale.getString('widget.groupTimeOptions');
				}
			}

			// if other options are exhausted, we can filter out all of the groupings we already checked against,
			// and if there's only one remaining, that must be the correct grouping
			if (!group && this.input.props.selectedAttributes) {
				let possibleOptions = this.input.props.selectedAttributes.filter((attr) => !attr.name);
				if (possibleOptions.length === 1) {
					return possibleOptions[0].displayName;
				}
			}

			if (group) {
				return group.displayName;
			}
		}

		return this.locale.getString('common.emptyDash');
	}

	private isPop(groupName: string): boolean {
		return groupName === StandardMetricName.POP;
	}

}

