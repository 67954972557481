<div class="form-horizontal">
	<modal-header [modalTitle]="getHeader()" (cancel)="cancel()"></modal-header>
	<div class="modal-body">
		<p class="br-dialog-message" [innerHTML]="getTransferMessage()"></p>
		<div class="mb-16">
			<label for="newOwner">{{getNewOwnerText()}}</label>
			<select id="newOwner" class="form-control" [(ngModel)]="selectedCandidate">
				<option *ngFor="let user of candidates" [ngValue]="user">{{user.email}}</option>
			</select>
		</div>
		<div class="scrolling-list">
			<div *ngFor="let selectedObject of selectedObjects">
				<span class="br-left-dashboard-items" *ngIf="dashboardTransferMode">
					<span aria-hidden="true" class="q-icon mr-8 {{icons[selectedObject.type]}}"></span>
				</span>
				<span class="font-bold">{{getDisplayName(selectedObject)}}</span>
			</div>
		</div>
		<div class="labeled-checkbox mt-16" *ngIf="showRetainEditPermissionCheckbox()">
			<input name="retainEditPermission" id="retainEditPermission" type="checkbox"
				   [(ngModel)]="retainEditPermission">
			<label for="retainEditPermission">{{'administration.retainShareEditAccess'|i18n}}</label>
		</div>
	</div>
	<save-modal-footer [saveText]="getTransferBtnText()" (save)="continue()" (cancel)="cancel()"></save-modal-footer>
</div>
