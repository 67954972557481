import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { RedirectDestinationValues } from '@cxstudio/services/redirect-destination';
import { RedirectService } from '@cxstudio/services/redirect-service';

@Injectable({
	providedIn: 'root'
})
export class AttachmentsService {

	constructor(
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('exportApiService') private exportApiService: ExportApiService) {

	}

	private downloadDocumentFile(descriptor, exportCallback): void {
		let url = this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.DOWNLOAD_FILE,
			{ descriptor });
		this.exportApiService.downloadVociFile(url).then((response) => {
			let headers = response.headers;
			let data = response.data;
			let filename = headers('file-name');
			exportCallback(filename, data);
		});
	}

	documentHasAttachments = (document): boolean => {
		return !!(document &&
				(this.documentHasAudio(document)
				|| this.documentHasTranscript(document)
				|| document.transcriptionFileDescriptor));
	}

	documentHasAudio(document): boolean {
		return !!(document.audioFileDescriptor?.length);
	}

	documentHasTranscript(document): boolean {
		return !!(document.transcriptionFileDescriptor?.length);
	}

	downloadAudio = (document) => {
		if (document && document.audioFileDescriptor) {
			this.downloadDocumentFile(document.audioFileDescriptor, this.exportUtils.exportMp3);
		}
	}

	downloadTranscription = (document) => {
		if (document && document.textFileDescriptor) {
			this.downloadDocumentFile(document.textFileDescriptor, this.exportUtils.exportText);
		}
	}

	downloadTranscriptionUrl = (document) => {
		if (document && document.transcriptionFileDescriptor) {
			return this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.DOWNLOAD_FILE, {
				descriptor: document.transcriptionFileDescriptor
			});
		}
	}

	streamAudioUrl = (document) => {
		if (document && document.audioFileDescriptor) {
			return this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.STREAM_FILE, {
				descriptor: document.audioFileDescriptor
			});
		}
	}
}

app.service('attachmentsService', downgradeInjectable(AttachmentsService));
