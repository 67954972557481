<div class="bg-dashboard h-100-percent pb-16 d-flex flex-direction-column p-relative overflow-y-auto ">
	<home-page-header
		(searchValueChange)="this.openQuickSearch($event)"
		(nodeChange)="onNodeChange($event)">
	</home-page-header>
	<home-page-widgets-panel class="mh-24 mb-16 d-flex hide-empty"></home-page-widgets-panel>

	<dashboards-by-label class="mb-16 d-flex hide-empty"></dashboards-by-label>

	<dashboard-list-section class="mh-24 br-list-box" style="max-height: 800px;"></dashboard-list-section>

	<login-history-button class="d-flex justify-end mr-24 mt-8"></login-history-button>
</div>


