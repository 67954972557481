import { SortDirection } from '@cxstudio/common/sort-direction';
import { FieldQueryParam } from '@cxstudio/query/field-query-param';

export class SearchQueryParams {
	isPaginated: boolean;
	pageSize: number;
	currentPage: number;
	sortField: string;
	sortDirection: SortDirection;
	queryFilters: FieldQueryParam[];

	static searchBy(filterField: string, filterText: string, orderBy: string): SearchQueryParams {
		let result: SearchQueryParams = new SearchQueryParams();
		result.isPaginated = true;
		result.currentPage = 1;
		result.pageSize = 10;
		if (filterText && filterField) {
			result.queryFilters = [ new FieldQueryParam(filterField, filterText) ];
		}
		result.sortField = orderBy;
		result.sortDirection = SortDirection.ASC;
		return result;
	}
}