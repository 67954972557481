import { Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

@Injectable({
	providedIn: 'root'
})
export class BaseActionsService {

	constructor(
		protected readonly security: Security,
	) {}

	protected getAnalyzeObjectKey(item: any, props: ProjectIdentifier): string {
		return [this.security.getMasterAccountId(), props.contentProviderId, props.accountId, item.id].join('-');
	}
}
