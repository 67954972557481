<page-content-header [title]="getWizardHeader()"></page-content-header>

<sidebar-wizard
	#wizard
	class="w-100-percent h-100-percent"
	[ngBusy]="loading"

	[cancelLabel]="getBackToHierarchiesLabel()"
	(cancel)="redirectToHierarchies()"

	[additionalButtonOneLabel]="getDiscardLabel()"
	(handleAdditionalButtonOne)="processDiscard()"
	[additionalButtonOneEnabled]="isDiscardButtonEnabled()"
	[showAdditionalOneButton]="showDiscardButton()"

	[additionalButtonTwoLabel]="getApplyChangesLabel()"
	(handleAdditionalButtonTwo)="processApplyChanges()"
	[additionalButtonTwoEnabled]="isApplyChangesEnabled()"
	[showAdditionalTwoButton]="showApplyChangesButton()"

	[finishLabel]="getApplyAndPublishLabel()"
	(finish)="processApplyAndPublishChanges()"
	[finishButtonEnabled] = "isApplyAndPublishEnabled()"
	[showSaveButton] = "showApplyAndPublishButton()"

	[showDoneButton]="shouldShowDoneButton()"
	(done)="redirectToHierarchies()"
>

	<cx-wizard-step #structureUploadStep [header]="'organization.structure' | i18n">
		<hierarchy-structure-wizard-page
			[hierarchy]="hierarchy"
			(tagChange)="structureUploadStep.setTag($event)"
			(hierarchyUpdated)="reloadHierarchy()">
		</hierarchy-structure-wizard-page>
	</cx-wizard-step>

	<cx-wizard-step #filtersUploadStep [header]="'organization.datafilters' | i18n">
		<hierarchy-filters-wizard-page
			[hierarchy]="hierarchy"
			(tagChange)="filtersUploadStep.setTag($event)"
			(hierarchyUpdated)="reloadHierarchy()">
		</hierarchy-filters-wizard-page>
	</cx-wizard-step>

	<cx-wizard-step #usersUploadStep [header]="'organization.users' | i18n">
		<hierarchy-users-wizard-page
			[hierarchy]="hierarchy"
			(tagChange)="usersUploadStep.setTag($event)"
			(hierarchyUpdated)="reloadHierarchy()">
		</hierarchy-users-wizard-page>
	</cx-wizard-step>

	<cx-wizard-step #enrichmentUploadStep [header]="'organization.enrichment' | i18n">
		<hierarchy-enrichment-wizard-page
			[hierarchy]="hierarchy"
			(tagChange)="enrichmentUploadStep.setTag($event)"
			(hierarchyUpdated)="reloadHierarchy()">
		</hierarchy-enrichment-wizard-page>
	</cx-wizard-step>

</sidebar-wizard>
