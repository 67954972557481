import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { IdMapper } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/id-mapper';
import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetModificationService } from '@app/modules/dashboard-edit/widget-modification.service';
import { ObjectUtils } from '@app/util/object-utils';
import Widget, { IWidgetBox } from '@cxstudio/dashboards/widgets/widget';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';

export interface WidgetsLayoutState {
	[widgetId: string]: IWidgetBox;
}

export class LayoutChangeAction implements DashboardChangeAction {
	private before: WidgetsLayoutState;
	private after: WidgetsLayoutState;

	constructor(
		before: WidgetsLayoutState,
		after: WidgetsLayoutState,
	) {
		this.before = ObjectUtils.copy(before);
		this.after = ObjectUtils.copy(after);
	}

	reverse(): DashboardChangeAction {
		let revert = new LayoutChangeAction(this.after, this.before);
		return revert;
	}

	apply(widgets: Widget[], api: WidgetModificationService, idMapper: IdMapper): Promise<void> {
		let widgetLayoutData: WidgetsLayoutState = {};
		_.each(this.after, (widgetBox, originalId) => {
			let widgetId = idMapper.getCurrentId(Number(originalId));
			widgetLayoutData[widgetId] = widgetBox;
		});
		return api.updateWidgetsLayout(widgets[0].dashboardId, widgetLayoutData).then(() => {
			_.each(widgetLayoutData, (widgetBox, currentId) => {
				let index = WidgetActionUtils.findWidgetIndexById(widgets, Number(currentId), idMapper);
				_.extend(widgets[index], widgetBox); // apply x,y,w,h to original widget
			});
			DashboardUtils.sortWidgets(widgets);
		});
	}
}
