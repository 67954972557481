import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@clarabridge/unified-angular-components';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { Security } from '@cxstudio/auth/security-service';
import { EngagorApiService } from '@cxstudio/services/data-services/engagor-api.service';
import { CaseDocument } from '@cxstudio/reports/entities/case-document.class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AuditSuggestion, AuditSuggestionEnrichment } from '@cxstudio/reports/document-explorer/audit-suggestion';
import { Verbatim } from '@cxstudio/reports/entities/verbatim';
import {
	EngagorCaseType,
	ICreateEngagorCaseModalResult
} from '@cxstudio/reports/document-explorer/components/create-engagor-case-modal.component';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import EngagorCreateCaseRequest from '@cxstudio/engagor/engagor-create-case-request';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';

/**
 * Pieces required from doc explorer document manager class
 */
export interface CaseDocumentManager {
	documents: { [key: number]: CaseDocument };
	selectedDocument: number;
	getProjectIdentifier: () => ProjectIdentifier;
	getWorkspaceProject: () => WorkspaceProject;
	getAssetParameters: () => AssetParameters;
	loadEngagorCases?;
	widget?: PreviewWidget;
}

@Injectable({
	providedIn: 'root'
})
export class DocumentCaseService {
	constructor(
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('engagorApiService') private readonly engagorApiService: EngagorApiService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService
	) {
	}

	createAuditSuggestionCase = (
		document: CaseDocument,
		sentence: PreviewSentence,
		widget: Widget,
		enrichmentType: AuditSuggestionEnrichment,
		currentOption: { name: string },
		newOption: { name: string },
		assetParameters: AssetParameters
	): Promise<void> => {
		const projectIdentifier: ProjectIdentifier = ProjectIdentifier.fromWidgetProperties(widget.properties);
		const workspaceProject: WorkspaceProject = widget.properties.workspaceProject;

		let caseDocument: CaseDocument = ObjectUtils.copy(document);
		const verbatim: Verbatim = _.findWhere(document.verbatims, {id: sentence.verbatimId}) as Verbatim;
		const verbatimText: string = verbatim.sentences
			.map((verbatimSentence: PreviewSentence) => verbatimSentence.text)
			.join(' ');

		caseDocument.auditSuggestion = {
			sentenceId: sentence.id,
			sentenceText: sentence.text,
			verbatim,
			verbatimId: sentence.verbatimId,
			verbatimText,
			enrichment: enrichmentType,
			currentValue: currentOption?.name,
			newValue: newOption?.name,
			currentOption,
			newOption
		} as AuditSuggestion;

		return this.createCase(
			EngagorCaseType.SUGGESTION,
			caseDocument,
			projectIdentifier,
			workspaceProject,
			assetParameters,
			widget.id ? widget.dashboardId : null
		);
	}

	createRegularCase = (documents: CaseDocument | CaseDocument[], projectIdentifier: IProjectSelection): Promise<any> => {
		return this.createCase(EngagorCaseType.REGULAR, documents, projectIdentifier, null)
			.then(() => this.toastService.addToast(this.locale.getString('docExplorer.caseCreated'), AlertLevel.SUCCESS));
	}

	createCaseFromExplorer = (documents: CaseDocument | CaseDocument[], documentManager: CaseDocumentManager): Promise<void> => {
		const documentsArray: CaseDocument[] = [].concat(documents);
		const translationKey: string = !this.security.isEngagorIntegrationEnabled() ? 'docExplorer.ticketCreated' : 'docExplorer.caseCreated';

		return this.createEngagorCase(
			EngagorCaseType.REGULAR,
			documentsArray,
			documentManager.getProjectIdentifier(),
			documentManager.getWorkspaceProject(),
			documentManager.getAssetParameters()
		).then(() => this.toastService.addToast(this.locale.getString(translationKey), AlertLevel.SUCCESS));
	}

	/**
	 * Create a case without already being in document explorer
	 */
	private createCase(
		type: EngagorCaseType,
		documents: CaseDocument | CaseDocument[],
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject,
		assetParameters?: AssetParameters, dashboardId?: number
	): Promise<any> {
		const documentsArray = [].concat(documents);
		return this.createEngagorCase(type, documentsArray, projectIdentifier, workspaceProject, assetParameters, dashboardId);
	}

	private createEngagorCase = (
		type: EngagorCaseType,
		documents: CaseDocument[],
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject,
		assetParameters?: AssetParameters,
		dashboardId?: number
	): Promise<any> => {
		const createCaseModal = PromiseUtils.wrap(
			this.cbDialogService.showCreateCaseModal(type, documents, projectIdentifier, workspaceProject, assetParameters)
		);

		return createCaseModal.then((result: ICreateEngagorCaseModalResult) => {
			let request = new EngagorCreateCaseRequest(projectIdentifier, result);

			if (result.isXflow) {
				return this.sendXflowRequest(request);
			}

			request.dashboardId = dashboardId;
			return this.sendEngagorRequest(request);
		});
	}

	private sendEngagorRequest = (request: EngagorCreateCaseRequest): Promise<void> => {
		return PromiseUtils.wrap(this.engagorApiService.createCase(request));
	}

	private sendXflowRequest = (request: EngagorCreateCaseRequest): Promise<void> => {
		return PromiseUtils.wrap(this.engagorApiService.createXflowMessage(request));
	}
}

app.service('documentCaseService', downgradeInjectable(DocumentCaseService));
