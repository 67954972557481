<page-content-header [title]="'alert.createAlert' | i18n"></page-content-header>

<sidebar-wizard *ngIf="alert"
	#wizard
	class="w-100-percent h-100-percent"
	[ngBusy]="loading"
	(cancel)="redirectToAlerts()"
	(finish)="saveAlert()">

	<cx-wizard-step #generalStep [header]="'alert.generalStepHeading' | i18n">
		<alert-general-settings
			[alert]="alert"
			[project]="project"
			(validityChange)="generalStep.setValid($event)">
		</alert-general-settings>
	</cx-wizard-step>

	<cx-wizard-step [header]="'alert.createTrigger' | i18n">
		<alert-criteria-settings
			[alert]="alert"
			[project]="project">
		</alert-criteria-settings>
	</cx-wizard-step>

	<cx-wizard-step #scopeStep [header]="'alert.defineScopeAndMetrics' | i18n">
		<alert-scope-settings
			[alert]="alert"
			[project]="project"
			(validityChange)="scopeStep.setValid($event)">
		</alert-scope-settings>
	</cx-wizard-step>

	<cx-wizard-step [header]="'alert.spotCheckCriteria' | i18n">

	</cx-wizard-step>

	<cx-wizard-step #actionsStep [header]="'alert.defineActions' | i18n">
		<alert-actions-settings
			[alert]="alert"
			(validityChange)="actionsStep.setValid($event)"
			(testAlert)="testAlert()">
		</alert-actions-settings>
	</cx-wizard-step>

	<cx-wizard-step [header]="'alert.shareSettings' | i18n">

	</cx-wizard-step>

</sidebar-wizard>
