import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { MetricData } from '@app/modules/metric/entities/metric-data';
import { MetricProperties } from '@app/modules/metric/entities/metric-properties';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { IMetricAssets } from './metric-assets.interface';

export interface TabsValidity {
	metricDefinitionTab: boolean;
	metricFormatTab: boolean;
}

@Component({
	selector: 'metric-editor',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './metric-editor.component.html'
})
export class MetricEditorComponent implements OnInit {
	@Input() mode: MetricMode;

	@Input() metric: MetricData;
	@Output() metricChange = new EventEmitter<MetricData>();

	@Input() project: AccountOrWorkspaceProject;
	@Input() assets: IMetricAssets;
	@Input() selectedFolder: IFolderItem;
	@Input() folders: IFolderItem[];

	@Output() onNameChange = new EventEmitter<string>();
	@Output() onValidityChange = new EventEmitter<TabsValidity>();

	calculationType: MetricType;

	properties: Partial<MetricProperties>;
	definition: MetricDefinition;
	formatObject: NumberFormatSettings;

	tabsValidity: TabsValidity;

	defaultsRestoredSubject: Subject<void> = new Subject<void>();

	constructor(
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService
	) {}

	ngOnInit(): void {
		let supportedProperties = [
			'id',
			'displayName',
			'description',
			'ownerName',
			'parentId',
			'labels'
		];
		this.tabsValidity = {
			metricDefinitionTab: true,
			metricFormatTab: true
		};
		this.properties = _.pick(this.metric, supportedProperties);
		this.definition = this.metric?.definition;
		if (this.definition && this.usesColorVariables(this.definition)) {
			// can't use css variables in editor
			this.definition.colorPalette = this.definition.hexColors;
			this.definition.calculation.colorPalette = this.definition.hexColors;
		}
		this.formatObject = this.metric?.format ? this.metric.format : this.getDefaultFormat();
		this.changeHandler();
	}

	private usesColorVariables(definition: MetricDefinition): boolean {
		return !!(_.any(definition.colorPalette, ColorUtilsHelper.isCssVar) && definition.hexColors);
	}

	getDefaultFormat(): NumberFormatSettings {
		let metric = { definition: this.definition || {}, type: ReportAssetType.METRIC_STUDIO };
		return this.defaultDataFormatterBuilder.getDefaultFormatterSettings(metric as CalculationWithFormat, this.assets?.metrics || []);
	}

	changeHandler = (): void => {
		let metric: MetricData = Object.assign({},
			this.properties as MetricProperties,
			{definition: this.definition},
			{format: this.formatObject});
		this.metricChange.emit(metric);
	}

	propertiesChangeHandler = (properties: MetricProperties): void => {
		this.properties = properties;
		this.changeHandler();
	}

	definitionChangeHandler = (definition: MetricDefinition): void => {
		this.definition = definition;
		this.changeHandler();
	}

	restoreDefaultsHandler = (): void => {
		this.formatChangeHandler(this.getDefaultFormat());
		// trigger changes in child components at last
		setTimeout(() => this.defaultsRestoredSubject.next());
	}

	formatChangeHandler = (format: NumberFormatSettings): void => {
		this.formatObject = format;
		this.changeHandler();
	}

	nameChangeHandler = (name: string): void => {
		this.onNameChange.emit(name);
	}

	validityChangeHandler = (form: string, valid: boolean): void => {
		this.tabsValidity[form] = valid;
		this.onValidityChange.emit(this.tabsValidity);
	}
}
