
<div class="single-widget-container w-100-percent h-100-percent d-flex flex-direction-column"
	[ngBusy]="loading">
	<main *ngIf="!!embeddedWidget"
		class="embed-content d-flex flex-fill w-100-percent align-center justify-center gridster-loaded gridster-wrapper" >
		<section *ngIf="!!errorMessage"
			class="error-content d-flex flex-direction-column w-100-percent h-100-percent">
			<div class="br-widget-header d-flex flex-direction-row align-center">
				<widget-title [title]="embeddedWidget.displayName"></widget-title>
				<menu *ngIf="!forbiddenToRefresh()"
					title="{{'widget.widgetMenuText'|i18n}}"
					class="dropdown pull-right br-widget-menu"
					ngbDropdown>
					<button ngbDropdownToggle
						class="q-icon-dots icon-rotate-90 btn btn-icon border-0"
						aria-haspopup="true"
						[attr.aria-label]="'widget.menuAriaLabel' | i18n:{widgetTitle:embeddedWidget.displayName}"></button>
					<ul ngbDropdownMenu
						class="dropdown-menu widget-dropdown-menu dropdown-menu-right">
						<li ngbDropdownItem
							(click)="reloadWidget()"
							[i18n]="'dashboard.refresh'"></li>
					</ul>
				</menu>
			</div>
			<div class="flex-fill justify-center d-flex flex-direction-column align-center br-brand-primary-background">
				<span class="mb-8 text-center">{{errorMessage}}</span>
				<a *ngIf="isShowDashboardLink()"
					class="btn btn-secondary"
					[i18n]="'dashboard.viewDashboard'"
					[href]="getDashboardLink()"
					target="_blank"></a>
			</div>
		</section>
		<widget-container *ngIf="embeddedWidget.widget && !errorMessage"
			class="br-widget h-100-percent w-100-percent p-relative"
			[widget]="embeddedWidget.widget"
			widgetMode="embedded"
			[editMode]="false"
			[dashboardData]="dashboardData"
			[layout]="{}"
			id="widget-{{embeddedWidget.widget.id}}">
		</widget-container>
	</main>
	<footer class="embed-footer flex-fixed w-100-percent ph-16">
		<span>{{'dashboard.poweredBy' | i18n }} <img src="/dashboard/img/QualtricsXM-Logo.png" height="16" alt=""></span>
		<a *ngIf="!!embeddedWidget && isShowDashboardLink()"
			class="btn-link float-right"
			[href]="getDashboardLink()"
			target="_blank"
			[i18n]="'dashboard.viewDashboard'"></a>
	</footer>
</div>
