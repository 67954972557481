import { Injectable } from '@angular/core';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { ScorecardMetricAdapter } from '@app/modules/metric/definition/custom-math/adapter/scorecard-metric-adapter.service';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';

@Injectable({providedIn: 'root'})
export class ScorecardNodeMetricAdapter extends ScorecardMetricAdapter {
	
	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let scorecardMetricData = expression.name.split('_');

		let scorecardId = +scorecardMetricData[0];
		let nodeId = +scorecardMetricData[2];
		let passing = scorecardMetricData[1] === 'passing';

		// for passsing search for failing as it's used only for 1-scorecard[metricName] formula
		let scorecardMetric = _.findWhere(assets.scorecardMetrics, {scorecardId, nodeId, passing: !passing});
		
		return `scorecard[${scorecardMetric.displayName}]`;
	}
}