import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { DatePointMode } from '@cxstudio/reports/settings/date-point-constants';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';
import { downgradeComponent } from '@angular/upgrade/static';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import TimeReferenceLine from '@app/modules/plot-lines/reference-lines/time-reference-line';
import TimeReferenceType from '@app/modules/plot-lines/reference-lines/time-reference-line-type';
import PlotLineBuilder from '@app/modules/plot-lines/plot-lines/plot-line-builder';

@Component({
	selector: 'time-reference-line-builder',
	templateUrl: './time-reference-line-builder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeReferenceLineBuilderComponent implements OnInit {
	@Input() plotLine: TimeReferenceLine;
	@Input() index: number;

	@Output() addLine = new EventEmitter<TimeReferenceLine>();
	@Output() removeLine = new EventEmitter<void>();
	@Output() updateLine = new EventEmitter<TimeReferenceLine>();
	@Output() updateDateTitle = new EventEmitter<string>();


	private tempPlotLine: TimeReferenceLine;
	private edit: boolean;
	types: any[];

	pointMode: string;
	datePickerOptions: any;
	hideTime: boolean;

	private oneMonthAgo: string = moment().startOf('day').subtract(1, 'months').format();
	private endOfToday: string = moment().endOf('day').format();
	private startOfToday: string = moment().startOf('day').format();

	datePointValue;
	dateRange;

	constructor(
		private readonly locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.edit = false;
		this.hideTime = true;
		this.pointMode = DatePointMode.CUSTOM;
		this.datePickerOptions = {showWeeks: false};

		this.types = [
			{name: TimeReferenceType.EVENT, displayName: this.locale.getString('widget.event')},
			{name: TimeReferenceType.SPAN, displayName: this.locale.getString('widget.span')}
		];

		if (_.isUndefined(this.plotLine) || _.isUndefined(this.plotLine.timeReferenceType)) {
			this.initializeDefaultPlotLine();
		}

		this.datePointValue = {
			amount: 0,
			period: 'month',
			date: this.plotLine.date || cloneDeep(this.startOfToday)
		};

		this.dateRange = {
			type: FilterRuleType.dateRange,
			fromMode: DatePointMode.CUSTOM ,
			toMode: DatePointMode.CUSTOM,
			fromDate: this.plotLine.from || cloneDeep(this.oneMonthAgo),
			toDate: this.plotLine.to || cloneDeep(this.endOfToday)
		};
	}

	isSpanType = (): boolean => {
		return this.plotLine.timeReferenceType === TimeReferenceType.SPAN;
	}

	isEventType = (): boolean => {
		return this.plotLine.timeReferenceType === TimeReferenceType.EVENT;
	}

	changeType = (type: TimeReferenceType): void => {
		this.plotLine.timeReferenceType = type;

		if (type === TimeReferenceType.EVENT) {
			this.setDefaultEventLineOptions(this.plotLine);
			if (this.index === 0) {
				this.updateDateTitle.emit(this.locale.getString('widget.date'));
			}
			return;
		}

		if (type === TimeReferenceType.SPAN) {
			this.setDefaultSpanLineOptions(this.plotLine);
			if (this.index === 0) {
				this.updateDateTitle.emit(this.locale.getString('widget.dateRange'));
			}
		}
	}

	setDefaultEventLineOptions = (plotLine: TimeReferenceLine): void => {
		let defaultType = PlotLineType.Dot;
		let defaultWidth = 1;

		plotLine.type = defaultType;
		plotLine.width = defaultWidth;
		plotLine.lineStyle = PlotLineBuilder.getLineStyle(defaultType, defaultWidth);
	}

	setDefaultSpanLineOptions = (plotLine: TimeReferenceLine): void => {
		plotLine.type = null;
		plotLine.width = null;
		plotLine.lineStyle = null;
	}

	addPlotLine = (): void => {
		this.updateDates();
		this.addLine.emit(cloneDeep(this.plotLine));
	}

	updatePlotLine = (): void => {
		this.edit = false;
		this.updateDates();
		this.updateLine.emit(this.plotLine);
	}

	updateDates = (): void => {
		if (this.isSpanType()) {
			this.plotLine.from = this.dateRange.fromDate;
			this.plotLine.to = this.dateRange.toDate;
		}

		if (this.isEventType()) {
			this.plotLine.date = this.datePointValue.date;
		}
	}

	removePlotLine = (): void => {
		this.removeLine.emit();
	}

	editPlotLine = (): void => {
		this.edit = true;
		this.tempPlotLine = cloneDeep(this.getLineObject());
	}

	clearChanges = (): void => {
		this.edit = false;
		this.plotLine = cloneDeep(this.tempPlotLine);
		this.updateLine.emit(this.plotLine);
	}

	getLineObject = (): TimeReferenceLine => {
		return this.plotLine;
	}

	isBeingEdited = (): boolean => {
		return this.edit;
	}

	isCreationRow = (): boolean => {
		return this.index === 0;
	}

	editingForbidden = (): boolean => {
		return !this.isCreationRow() && !this.isBeingEdited();
	}

	showAddButton = (): boolean => {
		return this.isCreationRow();
	}

	showEditButton = (): boolean => {
		return this.index > 0 && !this.edit;
	}

	showRemoveButton = (): boolean => {
		return this.index > 0 && !this.edit;
	}

	//update and edit button should never be shown together
	showUpdateButton = (): boolean => {
		return this.index > 0 && this.edit;
	}

	showClearButton = (): boolean => {
		return this.index > 0 && this.edit;
	}

	initializeDefaultPlotLine(): void {
		this.plotLine = new TimeReferenceLine();

		if (this.updateLine) {
			this.updateLine.emit(this.plotLine);
		}
	}
}

app.directive('timeReferenceLineBuilder', downgradeComponent({component: TimeReferenceLineBuilderComponent}));
