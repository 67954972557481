import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';

app.directive('metricSpectrum', () => {
	return {
		restrict: 'E',
		scope: {
			topBoundary: '=?',
			bottomBoundary: '=?',
			topColor: '=?',
			bottomColor: '=?',
			middleColor: '=?',
			otherColor: '=?',
			minimum: '=',
			maximum: '='
		},
		link: (scope, elem, attrs) => {

			scope.topColor = scope.topColor || ColorConstants.GREEN;
			scope.bottomColor = scope.bottomColor || ColorConstants.RED;
			scope.middleColor = scope.middleColor || ColorConstants.GREY;
			scope.otherColor = scope.otherColor || ColorConstants.GREY;

			let getTopRange = () => {
				let displayRange;

				if (scope.topBoundary >= 0) {
					displayRange = (scope.topBoundary !== scope.maximum) ? `${scope.topBoundary} - ${scope.maximum}` : scope.topBoundary;

					return `<div class='metric-top-box' style='background:${scope.topColor}'>${displayRange}</div>`;
				}
			};


			let getBottomRange = () => {
				let displayRange;

				if (scope.bottomBoundary >= 0) {
					displayRange = (scope.bottomBoundary !== scope.minimum) ? `${scope.minimum} - ${scope.bottomBoundary}` : scope.bottomBoundary;
					return `<div class='metric-bottom-box' style='background:${scope.bottomColor}'>${displayRange}</div>`;
				}
			};

			let getNeutralRange = () => {
				let displayRange;

				if (scope.bottomBoundary >= 0 && scope.topBoundary >= 0) {
					displayRange = '';
					if ((scope.bottomBoundary + 1) < (scope.topBoundary - 1)) {
						displayRange = `${scope.bottomBoundary + 1} - ${scope.topBoundary - 1}`;
					} else if ((scope.bottomBoundary + 1) === (scope.topBoundary - 1)) {
						displayRange = (scope.bottomBoundary + 1);
					}

					return `<div class='metric-neutral-box metric-neutral-full-options' style='background:${scope.middleColor}'>${displayRange}</div>`;
				} else {
					if (scope.bottomBoundary) {
						if ((scope.bottomBoundary + 1) < scope.maximum) {
							displayRange = `${scope.bottomBoundary + 1} - ${scope.maximum}`; // (scope.bottomBoundary + 1);
						} else {
							displayRange = ((scope.bottomBoundary + 1) === scope.maximum) ? scope.maximum : '';
						}

						return `<div class='metric-neutral-box metric-neutral-bottom-only' style='background:${scope.otherColor}'>${displayRange}</div>`;
					} else {

						if ((scope.topBoundary - 1) > scope.minimum) {
							displayRange = `${scope.minimum} - ${scope.topBoundary - 1}`;
						} else {
							displayRange = ((scope.topBoundary - 1) === scope.minimum) ? scope.minimum : '';
						}

						return `<div class='metric-neutral-box metric-neutral-top-only' style='background:${scope.otherColor}'>${displayRange}</div>`;
					}
				}
			};

			scope.getTopRange = getTopRange;	// for testing
			scope.getBottomRange = getBottomRange;	// for testing
			scope.getNeutralRange = getNeutralRange;	// for testing

			elem.append(getBottomRange(), getNeutralRange(), getTopRange());
		}
	};

});
