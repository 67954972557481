

export enum FilterTypes {
	CXSTUDIO = 'studioFilter',
	CXANALYZE = 'analyzeFilter',
	CXDATE = 'studioDateFilter',
	CXSCORECARD = 'scorecardFilter',
	CMP = 'CMP',
	STUDIO = 'STUDIO',
	EMPTY = 'EMPTY',
	AND = 'AND',
	PREDEFINED = 'PREDEFINED',
	TEXT_FILTER = 'TEXT_FILTER',
	HIERARCHY_FILTER = 'hierarchyFilter',
	SCORECARD = 'SCORECARD',
	ATTRIBUTE = 'attribute',
}


