import { CustomDateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';

export enum DatePeriodField {
	PERIOD1 = 'dateRangeP1',
	PERIOD2 = 'dateRangeP2'
}

export enum DatePeriodName {
	PERIOD1 = 'period_1_',
	PERIOD2 = 'period_2_'
}

export interface IDateRange {
	from: string;
	to: string;
}

export class DatePeriod {
	field: DatePeriodField;
	name: DatePeriodName;
	dateFilterMode?: DateFilterMode | CustomDateFilter;
	dateFilterRange?: IDateRange;
	defaultMode?: DateFilterMode | CustomDateFilter;
	dateModeSelection?: (dateFilterMode: DateFilterMode, dateFilterRange: IDateRange, dateDisplayName: string) => void;

	constructor(field: DatePeriodField, name: DatePeriodName, defaultMode?: DateFilterMode) {
		this.field = field;
		this.name = name;
		this.defaultMode = defaultMode;
	}
}
