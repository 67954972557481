import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { PromiseUtils } from '@app/util/promise-utils';
import { MenuDividerShorthand } from '@cxstudio/context-menu/drill-menu-option.component';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { Model } from '@cxstudio/reports/entities/model';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '../../drill-menu-option';
import { DrillWidgetType } from '../drill-widget-type';


export class TopicDrillService {
	constructor(
		private readonly locale: ILocale,
		private readonly DrillTo,
		private readonly hierarchyService: HierarchyService,

		private readonly reportModelsService: ReportModelsService,
		private readonly reportSettingsService: ReportSettingsService,
		private readonly $q: ng.IQService
	) { }

	private createAddModelGroupByFunction(widget): (point: DrillPoint, model: ModelTree) => Promise<void> {
		return (point: DrillPoint, model: ModelTree) => {
			let groupBy = this.createModelGroupBy(model);
			point.groupBy = groupBy;
			return this.enrichWithProjectDefaults(point, widget, groupBy);
		};
	}

	private enrichWithProjectDefaults(point, widget, groupBy): Promise<void> {
		if (this.isDrillingToSameModel(widget, groupBy)) {
			point.groupBy = groupBy;
			return PromiseUtils.wrap(this.$q.when());
		} else {
			return this.reportSettingsService.populateGroupingProjectDefaults(widget.properties, groupBy)
				.then((enrichedModel) => {
					// additional safety check for async action
					if (point.groupBy && point.groupBy.metricType === enrichedModel.metricType && point.groupBy.name === enrichedModel.name) {
						point.groupBy = this.isMatchingTopicLevel(point.groupBy, enrichedModel) ? enrichedModel : groupBy;
					}
				});
		}
	}

	private createModelGroupBy(model: ModelTree): any {
		return {
			name: `${model.id}`,
			displayName: model.name,
			metricType: AnalyticMetricType.CLARABRIDGE,
			type: ReportAssetType.TOPICS
		};
	}

	private isDrillingToSameModel(widget: Widget, model: Model): boolean {
		let groupings = widget.properties.selectedAttributes || [];
		let grouping = groupings[groupings.length - 1];

		return AnalyticMetricTypes.isTopics(grouping)
			&& grouping.name === model.name;
	}

	private isMatchingTopicLevel(groupBy, defaults): boolean {
		return AnalyticMetricTypes.isTopics(groupBy)
			&& groupBy.selectedLevel
			&& groupBy.selectedLevel === defaults.selectedLevel;
	}

	private createTopicLeafDrill(widget: Widget, model: ModelTree): (point: DrillPoint, options: any) => ng.IPromise<any> {
		return (point: DrillPoint, options) => {
			point.groupBy = point.groupBy || this.createModelGroupBy(model);
			let groupBy = point.groupBy;

			this.addGroupBy(groupBy, options);

			if (groupBy.name === options.name) {
				groupBy.type = ReportAssetType.TOPIC_LEAF;
				groupBy.selectedLevel = Number.MAX_SHORT_INTEGER;
			}

			return PromiseUtils.old(this.enrichWithProjectDefaults(point, widget, groupBy));
		};
	}

	private createAddLevelGroupBy(widget: Widget, model: ModelTree): (point: DrillPoint, options: any) => ng.IPromise<any> {
		return (point: DrillPoint, options) => {
			point.groupBy = point.groupBy || this.createModelGroupBy(model);
			let groupBy = point.groupBy;

			this.addGroupBy(groupBy, options);
			return PromiseUtils.old(this.enrichWithProjectDefaults(point, widget, groupBy));
		};
	}

	private addGroupBy(groupBy, options): void {
		if (groupBy.name === options.name) {
			_.extend(groupBy, options);
			delete groupBy.checkedNodes;
		}
	}

	private addNodeGroupBy(point: DrillPoint, tree, node): void {
		let groupBy = point.groupBy;
		let opts: any = {
			type: 'topics',
			selectedNodes: []
		};
		if (node.children === null) {
			opts.selectedLevel = node.level;
			opts.selectedNodes.push(node.id);
		} else {
			opts.selectedLevel = node.level + 1;
			for (let nodeChild of node.children) {
				opts.selectedNodes.push(nodeChild.id);
			}
		}
		_.extend(groupBy, opts);
	}

	getModels(params: IDrillParams, models: Model[]): IDrillMenuOption[] {
		let modelsOpt = [];

		for (let model of models) {
			let itemsPromise = this.getModelOptionsPromise(params, model);
			modelsOpt.push({
				text: model.name,
				name: `model-${model.id}`,
				searchBox: false,
				obj: model,
				items: itemsPromise,
				asyncFunc: this.createAddModelGroupByFunction(params.widget),
				selected: false
			});
		}
		return modelsOpt;
	}

	private getModelOptionsPromise(params: IDrillParams, model: Model): () => Promise<any> {
		return () => {
			let props = params.widget.properties;
			let drillToHelper = new this.DrillTo(params.widget, params.menuActions, params.defaultColor);
			return this.reportModelsService.getWidgetModelTree(params.widget, model.id)
				.then((modelTree) => {
					let maxLevels = this.hierarchyService.getDepth(modelTree.root);

					let checkedNodes = [];
					this.hierarchyService.applyRecursively(
						modelTree.root.children, this.processNodeForChecked, checkedNodes);

					let selectedNodes = this.getNodesToSelect(checkedNodes);
					let opts = {
						name: '' + modelTree.id,
						type: 'topicLeaf',
						selectedLevel: Number.MAX_SHORT_INTEGER,
						selectedNodes
					};
					let levelItems: IDrillMenuOption[] = [{
						text: this.locale.getString('widget.topicLeaf'),
						name: 'topic-leaf',
						obj: opts,
						items: drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, params.widget.properties.widgetType),
						asyncFunc: this.createTopicLeafDrill(params.widget, modelTree),
						selected: true
					}];

					for (let level = 1; level <= maxLevels; level++) {
						selectedNodes = this.getNodesToSelect(checkedNodes, level);
						opts = {
							name: '' + modelTree.id,
							type: 'topics',
							selectedLevel: level,
							selectedNodes
						};
						levelItems.push({
							text: `${this.locale.getString('widget.level')} ${level}`,
							name: `model-level-${level}`,
							obj: opts,
							items: drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, params.widget.properties.widgetType),
							asyncFunc: this.createAddLevelGroupBy(params.widget, modelTree)
						});
					}

					levelItems.push(MenuDividerShorthand);
					levelItems.push({
						text: modelTree.name,
						name: `model-tree-${modelTree.id}`,
						items: drillToHelper.getDrillTo(DrillWidgetType.DEFAULT, params.widget.properties.widgetType),
						func: this.addNodeGroupBy,
						tree: [modelTree.root]
					} as unknown as IDrillMenuOption);

					return levelItems;
				});
		};
	}

	private processNodeForChecked(node, result): void {
		if (_.findIndex(result, {id: node.id}) < 0) {
			result.push({
				id: node.id,
				level: node.level,
				children: (node.children && node.children.length)
			});
		}
	}

	private getNodesToSelect(checkedNodes, selectedLevel?: number): any {
		let selectedNodes;
		if (!_.isUndefined(selectedLevel)) {
			selectedNodes = checkedNodes.map((node) => {
				return (node.level === selectedLevel)
					? node.id : undefined;
			});
		} else {
			selectedNodes = checkedNodes.map((node) => {
				return !(node.children) ? node.id : undefined;
			});
		}

		selectedNodes = selectedNodes.filter(node => !!node);
		return selectedNodes;
	}

	getOptions(params: IDrillParams, models, hasDefaultSelection: boolean = false): IDrillMenuOption {
		let opt: IDrillMenuOption = {
			group: IDrillMenuOptionGroup.drill,
			priority: 30,
			text: this.locale.getString('widget.topics'),
			items: this.getModels(params, models),
			name: 'topic',
			searchBox: true,
			levels: 3,
			noDefaultAction: true
		};
		if (!hasDefaultSelection && models.length > 0) {
			opt.selected = true;
		}
		return opt;
	}

}

app.service('topicDrill', TopicDrillService);
