import { Directive, EventEmitter, OnDestroy, OnInit, Optional } from '@angular/core';
import { AmplitudeToggleEvent } from './dropdown-toggle-option/dropdown-toggle-option.component';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { DropdownMenuGroupComponent } from '@app/modules/document-explorer/document-explorer-menu/dropdown-menu-group/dropdown-menu-group.component';

@Directive({
	selector: '[amplitudeTrackedToggle]'
})
export abstract class AmplitudeTrackedToggle implements OnInit, OnDestroy {
	amplitudeEvent?: AmplitudeToggleEvent;
	abstract valueChange: EventEmitter<boolean>;

	constructor(
		@Optional() protected readonly _parentComponent?: DropdownMenuGroupComponent
	) { }

	ngOnInit(): void {
		if (this.amplitudeEvent) {
			this.valueChange.subscribe(value => {
				const toggle = value ?
					'On' :
					'Off';

				let properties = {...this.amplitudeEvent.properties};

				if (this._parentComponent && this._parentComponent.amplitudeCategoryProperties) {
					properties = {...this._parentComponent.amplitudeCategoryProperties, ...properties};
				}

				AmplitudeAnalyticsService.trackEvent(
					this.amplitudeEvent.event,
					this.amplitudeEvent.group,
					{ ...properties, toggle }
				);
			});
		}
	}

	ngOnDestroy(): void {
		this.valueChange.unsubscribe();
	}
}
