
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { TypeGuards } from '@app/util/typeguards.class';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { AppRoute } from '@cxstudio/route/app-route';

export const CHANGE_MA_STATUS = 310;

/**
 * Service for generating backend url, 'rest' substring is replaced with current api version (e.g. api/v1)
 */
export class UrlService {
	private readonly TARGET_BLANK = 'target="_blank"';
	private global = new RegExp(/http?:\/\//);
	private restUrl = new RegExp(/^rest\//);
	private commonRestUrl = new RegExp(/^commonRest\//);
	private backendUrl = new RegExp('/' + CONFIG.backend + '/');

	private urlPattern = '(http(s?):(\\/){2}|www\\.)\\S*';
	private httpPattern = '(http(s?):(\\/){2})\\S*';

	private urlValueRegExp = new RegExp('^' + this.urlPattern + '$');
	private httpValueRegExp = new RegExp('^' + this.httpPattern + '$');

	private urlRegExp = new RegExp(this.urlPattern + '\\b(\\/?)');
	private httpRegExp = new RegExp(this.httpPattern + '\\b(\\/?)');

	constructor(
		private $location: ng.ILocationService,
		private $sce: ng.ISCEService
	) {}

	isCommonAPICall = (url: string): boolean => {
		return url && !!url.match(this.commonRestUrl);
	}
	isAPICall = (url: string): boolean => {
		return url && !!url.match(this.restUrl);
	}
	isBackendCall = (url: string): boolean => {
		return url && !!url.match(this.backendUrl);
	}

	/*
	* Do not use it with $http service.
	* Use 'rest' prefix for url instead.
	*/
	getAPIUrl(url: string): string {
		return this.replaceCustomUrlPrefix(url, 'rest', CONFIG.apiPrefix);
	}

	getCommonAPIUrl = (url: string): string => {
		return this.replaceCustomUrlPrefix(url, 'commonRest', CONFIG.commonApiPrefix);
	}

	private replaceCustomUrlPrefix(url: string, originalPrefix: string, apiPrefix: string): string {
		let prefix = CONFIG.backend.match(this.global) ? '' : '/';
		let suffix = CONFIG.backend[CONFIG.backend.length - 1] === '/' ? '' : '/';
		return prefix + CONFIG.backend + suffix + url.replace(originalPrefix, apiPrefix);
	}

	getDashboardUrl = (dashboardId: number): string => {
		return dashboardId !== undefined
			? `${this.getHostUrl()}/dashboard/#/home/${dashboardId}`
			: this.$location.absUrl();
	}

	getBookUrl = (bookId: number): string => {
		return bookId !== undefined
			? `${this.getHostUrl()}/dashboard/#/dashboard/${bookId}`
			: this.$location.absUrl();
	}

	getEmbedWidgetUrl = (dashboardId: number, identifier: string): string => {
		return dashboardId !== undefined
			? `${this.getHostUrl()}/dashboard/#/embed/dashboard/${dashboardId}/widget/${identifier}`
			: this.$location.absUrl();
	}

	// TODO: May be good to get this from backend in the future to keep them in sync easily
	/**
	 * Get embeddable link for a dashboard
	 */
	getDashboardEmbedLink = (dashboard: Dashboard): string => {
		return `${this.getHostUrl()}/dashboard/#/embed/dashboard/${dashboard.id}`;
	}

	getRequestedPathLink = (requestedPath: string): string => {
		return `${this.getHostUrl()}/dashboard/#${requestedPath}`;
	}


	getBaseUrl = (): string => {
		return !CONFIG.backend.match(this.global)
			? this.getHostUrl() + '/' + CONFIG.backend
			: CONFIG.backend;
	}

	getCXStudioEndpoint =  (): string => {
		let suffix = CONFIG.backend[CONFIG.backend.length - 1] === '/' ? '' : '/';
		let apiPrefix = CONFIG.apiPrefix;

		return this.getBaseUrl() + suffix + apiPrefix;
	}

	isCacheSensitive = (url: string): boolean => {
		return !!url.match(/.*lang\.json$/);
	}

	getDocumentUrl = (document): string => {
		return document && document.documentUrl;
	}

	substituteUrl = (text: string): string => {
		if (_.isUndefined(text)) {
			return text;
		}
		return text.replace(this.urlRegExp, (url) => {
			let hrefUrl = url;
			if (!url.match(this.httpRegExp)) {
				hrefUrl = `http://${url}`;
			}
			return this.$sce.trustAsHtml(`<a href="${hrefUrl}" ${this.TARGET_BLANK}> ${url}</a>`);
		});
	}

	isLinkClick(event: Event): boolean {
		if (event?.target && TypeGuards.isHTMLElement(event.target)) {
			const isATag = event.target.tagName === 'A';
			const hrefValue = event.target.getAttribute('href');

			return isATag && this.isUrl(hrefValue);
		}

		return false;
	}

	isUrl = (value: string): boolean => {
		if (!(value && value.length)) return false;
		return !!value.match(this.urlValueRegExp);
	}

	isValidDomain = (domainToCheck: string): boolean => {
		if (_.isUndefined(domainToCheck)) {
			return false;
		}

		// ^ matches the start of the string.
		// (?!-) is a negative lookahead assertion that excludes domain names starting with a hyphen.
		// [A-Za-z0-9-]+ matches one or more occurrences of letters, digits, or hyphens.
		// ([\\-\\.][a-z0-9]+)* is a group that matches zero or more occurrences of a hyphen or a dot followed by one or more letters or digits. This group allows subdomains to be included in the domain name.
		// \\. matches a dot character.
		// [A-Za-z]{2,6} matches two to six letters at the end of the string. This is to ensure that the domain name ends with a valid top-level domain (TLD), such as .com, .org, .edu, etc.
		// $ matches the end of the string
		return new RegExp('^(?!-)[A-Za-z0-9-]+([\\-\\.][a-z0-9]+)*\\.[A-Za-z]{2,6}$').test(domainToCheck);
	}

	isNotValidDomain = (domainToCheck: string): boolean => {
		return !this.isValidDomain(domainToCheck);
	}

	replaceUrlWithLink = (url: string, capitalizer?: (str: string) => string, omitHref?: boolean): string => {
		let urlText = HtmlUtils.escapeHtml(url);
		if (capitalizer) {
			urlText = capitalizer(urlText);
		}
		if (omitHref) {
			return `<a> ${urlText}</a>`;
		}
		if (url.match(this.httpValueRegExp)) {
			return `<a href="${url}" target="_blank"> ${urlText}</a>`;
		} else {
			return `<a href="http://${url}" target="_blank"> ${urlText}</a>`;
		}
	}

	getHostUrl(): string {
		let protocol = this.$location.protocol();
		let host = this.$location.host();
		let port = this.$location.port();

		return protocol + ':' + '\/\/' + host + ':' + port;
	}

	getRouteParam = (route: AppRoute, param: string): string | undefined => {
		if (this.hasRouteParam(route, param)) {
			return route.params[param];
		}
	}

	hasRouteParam = (route: AppRoute, param: string): boolean => {
		return !!(route && route.params && route.params[param]);
	}

	getDashboardImageUrl(imageName: string, dashboardId: number): string {
		return this.getCommonAPIUrl('commonRest/image/' + dashboardId) + '/' + encodeURIComponent(imageName);
	}

	getInternalImageUrl(imageName: string): string {
		return this.getHostUrl() + '/dashboard/img/' + imageName;
	}

	getInternalFile(filePath: string): string {
		return `${this.getHostUrl()}/dashboard/${filePath}`;
	}

	getImageUrl(imageUrl: string): string {
		return this.ensureValidImageUrl(imageUrl);
	}

	ensureValidImageUrl = (imageUrl: string): string => {
		if (!imageUrl || imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
			return imageUrl;
		}
		imageUrl = 'http://' + imageUrl;
		return imageUrl;
	}

	getImageNameFromUrl = (imageUrl, dashboardId): string => {
		let apiMatch = this.getAPIUrl('rest/image/' + dashboardId) + '/';
		let encodedName = imageUrl.split(apiMatch)?.[1] || '';
		return decodeURIComponent(encodedName);
	}

}

app.service('urlService', UrlService);
