import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Inject } from '@angular/core';
import { SampledAudits } from '@cxstudio/sampled-audits/sampled-audits.component';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';


@Injectable()
export class SampledAuditsGridDefinition implements IGridDefinition<SampledAudits> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter
	) {
	}

	init = (gridMode: GridMode, controller: SampledAudits): Promise<IGridColumn[]> => {
		let columns = [
			{
				id: 'checkbox',
				sortable: false,
				minWidth: 32,
				width: 32,
				headerCssClass: 'header-checkbox text-center',
				name: '<span></span>',
				cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
				formatter: this.gridFormatterService.SelectedRowFormatter,
				resizable: false
			},  {
				id: 'hamburger',
				sortable: false,
				minWidth: 40,
				width: 32,
				headerCssClass: 'header-hamburger text-center',
				name: '<i class="q-icon-layer"></i>',
				cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
				resizable: false
			},
			{
				id: 'spacer',
				sortable: false,
				minWidth: 16,
				width: 16,
				resizable: false,
				cssClass: 'no-border-if-folder',
				attributes: {
					'aria-hidden': true
				}
			},
			{
				id: 'name',
				name: this.locale.getString('common.name'),
				field: 'displayName',
				minWidth: 120,
				width: 350,
				cssClass: 'cell-name',
				searchable: true,
				formatter: this.gridFormatterService.getNameFormatter(GridTypes.SAMPLED_AUDITS),
				isObjectNameColumn: true
			},
			{
				id: 'workflow',
				name: this.locale.getString('sampledAudits.workflowColumn'),
				field: 'workflow',
				minWidth: 100,
				width: 240,
				cssClass: 'cell-workflow',
				formatter: this.gridFormatterService.NoFormat
			},
			{
				id: 'modified',
				name: this.locale.getString('dashboard.modifiedDate'),
				field: 'modifiedDate',
				minWidth: 100,
				width: 240,
				cssClass: 'cell-date',
				formatter: this.gridFormatterService.DateFormatter
			},
			{
				id: 'status',
				name: this.locale.getString('dashboard.status'),
				field: 'sharingStatus',
				minWidth: 60,
				width: 100,
				headerCssClass: 'text-center',
				formatter: this.gridFormatterService.StatusFormatter,
				cssClass: 'cell-status text-center'
			},
			{
				id: 'ownerName',
				name: this.locale.getString('common.owner'),
				field: 'ownerName',
				minWidth: 80,
				width: 260,
				cssClass: 'cell-owner-name',
				formatter: this.gridFormatterService.getAuthorFormatter()
			},
		];

		return Promise.resolve(columns);
	}

	sessionHamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		return this.gridFormatterService.HamburgerFormatter(row, cell, value, columnDef, dataContext);
	}

}

app.service('sampledAuditsGridDefinition', downgradeInjectable(SampledAuditsGridDefinition));
