import { CxHeadersUtilService } from '@app/core/http/cx-headers-util.service';

export class FileUploaderService {
	constructor(
		private readonly FileUploader,
		private readonly headersUtil: CxHeadersUtilService
	) {}

	getUploader = (options) => {
		let uploader = new this.FileUploader(options);
		uploader.onBeforeUploadItem = (fileItem) => {
			fileItem.headers = $.extend(fileItem.headers, this.headersUtil.getCustomHeaders());
			fileItem.headers = $.extend(fileItem.headers, {filename: encodeURI(fileItem.file.name)}); // for non-ascii filenames
		};
		return uploader;
	}

	getOHFileExtensionFilter = () => {
		return {
			name: 'ohFile',
			// tslint:disable:no-invalid-this
			fn(item): boolean {	// tslint:disable-line:only-arrow-functions
				return item.name
					&& (item.name.indexOf('.xls', this.length - '.xls'.length) !== -1
							|| item.name.indexOf('.xlsx', this.length - '.xlsx'.length) !== -1
							|| item.name.indexOf('.csv', this.length - '.csv'.length) !== -1);
			}

			// tslint:enable:no-invalid-this
		};
	}
}

app.service('fileUploadService', FileUploaderService);
