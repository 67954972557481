import { Injectable, Inject } from '@angular/core';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { CxHttpRequestOptions } from './cx-http-request-options';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { HttpParams } from '@angular/common/http';
import { HttpPromise } from '@app/core/http-promise';

@Injectable({
	providedIn: 'root'
})
export class CxHttpService {
	constructor(
		@Inject('$http') private $http: ng.IHttpService,
		@Inject('httpHandlers') private httpHandlers: HttpHandlers
	) {}

	rawGet<T, P extends HttpParams = HttpParams>(url: string, options?: CxHttpRequestOptions<P>): HttpPromise<T> {
		return Promise.resolve(this.$http.get<T>(url, CxHttpUtils.optionsToConfig(options)) as HttpPromise<T>);
	}
	get<T, P extends HttpParams = HttpParams>(url: string, options?: CxHttpRequestOptions<P>): Promise<T> {
		return Promise.resolve(this.$http.get<T>(url, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error));
	}
	getResponse<T, P extends HttpParams = HttpParams>(url: string, options?: CxHttpRequestOptions<P>): HttpPromise<T> {
		return this.rawGet<T, P>(url, options);
	}
	delete<T, P extends HttpParams = HttpParams>(url: string, options?: CxHttpRequestOptions<P>): Promise<T> {
		return Promise.resolve(this.$http.delete(url, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error));
	}
	rawPost<T, P extends HttpParams = HttpParams>(url: string, data?: any, options?: CxHttpRequestOptions<P>): HttpPromise<T> {
		return Promise.resolve(this.$http.post(url, data, CxHttpUtils.optionsToConfig(options)) as HttpPromise<T>);
	}
	post<T, P extends HttpParams = HttpParams>(url: string, data?: any, options?: CxHttpRequestOptions<P>): Promise<T> {
		return Promise.resolve(this.$http.post(url, data, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error));
	}
	rawPut<T, P extends HttpParams = HttpParams>(url: string, data?: any , options?: CxHttpRequestOptions<P>): HttpPromise<T> {
		return Promise.resolve(this.$http.put(url, data, CxHttpUtils.optionsToConfig(options)) as HttpPromise<T>);
	}
	put<T, P extends HttpParams = HttpParams>(url: string, data?: any , options?: CxHttpRequestOptions<P>): Promise<T> {
		return Promise.resolve(this.$http.put(url, data, CxHttpUtils.optionsToConfig(options))
			.then(this.httpHandlers.success, this.httpHandlers.error));
	}
}
