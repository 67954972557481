import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { IShareEntity, IShareEntityType } from '@cxstudio/sharing/sharing-service.service';
import { ShareAction } from '@cxstudio/common/share-actions.constant';

/* Remove some permissions, which are not allowed for entity type (e.g. OWN for groups). Used for Dashboard Share Dialog*/
app.filter('permissionFilter', () => {

	return (permissions, entity: IShareEntity) => {
		if (entity.type === IShareEntityType.USER) {
			return (entity.action === ShareAction.CREATE) ?
				_.filter(permissions, permItem => permItem.id === PermissionType.VIEW) :
				permissions;
		}

		if (entity.type === IShareEntityType.GROUP) {
			return permissions.filter(item => item.id !== PermissionType.OWN);
		}

		if (entity.type === IShareEntityType.PUBLIC) {
			return permissions.filter(item => (item.id === PermissionType.VIEW) || (item.id === PermissionType.REMOVE));
		}

		return permissions;
	};
});
