
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ListColumnFilter } from '@app/shared/components/filter/list-column-filter';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { SocialCaseLogApi } from './social-case-log.api.service';

@Component({
	selector: 'social-cases-log-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<object-list-filter
			[maxRules]="3"
			[columns]="columns"
			[pinnedColumns]="pinnedColumns"
			(apply)="applyFilters($event)"
		></object-list-filter>
	`
})
export class SocialCasesLogFilterComponent implements OnInit {

	@Output() apply = new EventEmitter<ObjectListFilter[]>();

	columns: ColumnFilterOption[];
	pinnedColumns: ColumnFilterOption[];

	constructor(
		private locale: CxLocaleService,
		private socialCaseLogApi: SocialCaseLogApi
	) {}

	ngOnInit(): void {

		let masterAccount: ListColumnFilter = {
			name: this.locale.getString('common.masterAccount'),
			value: 'message.masterAccountId',
			columnFilterType: ColumnFilterType.LIST,
			options: []
		};

		let messageType: ListColumnFilter = {
			name: this.locale.getString('cases.messageType'),
			value: 'message.messageType',
			columnFilterType: ColumnFilterType.LIST,
			options: []
		};

		let reason: ListColumnFilter = {
			name: this.locale.getString('cases.reason'),
			value: 'message.reason',
			columnFilterType: ColumnFilterType.LIST,
			options: []
		};

		let date: ColumnFilterOption = {
			name: this.locale.getString('administration.date'),
			value: 'metadata.date',
			columnFilterType: ColumnFilterType.DATE
		};

		let alert: ColumnFilterOption = {
			name: this.locale.getString('administration.alertName'),
			value: 'message.alertName',
			columnFilterType: ColumnFilterType.TEXT
		};

		let inboxTemplate: ColumnFilterOption = {
			name: this.locale.getString('cases.inboxTemplate'),
			value: 'message.subscriptionTemplateName',
			columnFilterType: ColumnFilterType.TEXT
		};
		
		this.columns = [masterAccount, messageType, reason, date, alert, inboxTemplate];
		this.socialCaseLogApi.getFilterOptions().then(response => {
			this.columns.forEach((column: any) => {
				let options = response[column.value];
				if (column.columnFilterType === ColumnFilterType.LIST && options) {
					column.options = options;
				}
			});
		});
	}

	applyFilters = (filters: ObjectListFilter[]): void => {
		this.apply.emit(filters || []);
	}
}

