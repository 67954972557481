import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';

export class ModelUtils {
	static populateNodesPath(modelTree: ModelTree): ModelTree {
		if (modelTree.root) {
			this.applyRecursive(modelTree.root, 'children',
				(parent, child) => this.populateParentRelation(parent, child));
		}
		return modelTree;
	}

	private static applyRecursive(parent: ModelTreeNode, field: string,
		reduceFn: (p: ModelTreeNode, c: ModelTreeNode) => void, root = true): void {
		if (root) reduceFn(null, parent);

		if (parent && parent[field]) {
			parent[field].forEach((child) => {
				if (reduceFn) reduceFn(parent, child);
				this.applyRecursive(child, field, reduceFn, false);
			});
		}
	}


	private static populateParentRelation(parent: ModelTreeNode, child: ModelTreeNode): void {
		child.path = parent && parent.path
			? parent.path + ' > ' + child.name
			: child.level === 0
				? ''
				: child.name;
		child.idPath = parent && parent.idPath
			? parent.idPath + '/' + child.id.toString()
			: child.id.toString();
	}
}
