import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface IColResizableOptions {
	disable?: boolean;
	liveDrag?: boolean;
	resizeMode?: 'fit' | 'flex';
	headerOnly?: boolean;
	gripInnerHtml?: string;
	draggingClass?: string;
	onDrag?: (event: any) => void;
	onResize?: (event: any) => void;
	onColumnDrag?: (columnWidths: number[], event: any) => void;
}

@Directive({
	selector: '[colResizable]'
})
export class ColResizableDirective extends SelfCleaningComponent implements OnInit {

	private options: IColResizableOptions;

	@Input('colResizable') set colResizable(value: object) {
		this.options = value;
		// disable it first as it may not update when enabled
		($(this.elm.nativeElement) as any).colResizable({ disable : true });
		this.debounceInitSubject.next();
	}

	private readonly defaultOptions: IColResizableOptions = {
		disable: true,
		liveDrag: true,
		resizeMode: 'fit',
		headerOnly: true,
		gripInnerHtml: '<div class="grip"></div>',
		draggingClass: 'dragging'
	};

	private debounceInitSubject = new Subject();
	private debounceDragSubject = new Subject<{widths: number[], event: MouseEvent}>();

	constructor(
		private elm: ElementRef<HTMLElement>,
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.debounceInitSubject.pipe(debounceTime(500))
			.subscribe(() => this.init()));
		this.addSubscription(this.debounceDragSubject.pipe(debounceTime(200))
			.subscribe(({widths, event}) => this.options.onColumnDrag(widths, event)));
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(this.elm.nativeElement,
			() => this.debounceInitSubject.next()));
	}

	private init(): void {
		setTimeout(() => {
			this.changeWidthToPercentage();
			($(this.elm.nativeElement) as any).colResizable(this.getOptions());
		});
	}

	private changeWidthToPercentage(): void {
		let headers: HTMLElement[] = $(this.elm.nativeElement).find('th').toArray() as HTMLElement[];
		let widths: number[] = _.map(headers, domElement => $(domElement).outerWidth());
		let total: number = _.reduce(widths, (memo, width) => memo + width, 0);

		_.each(headers, (header, index) => {
			let percentage: number = (widths[index] / total) * 100;
			$(header).css('width', `${percentage}%`);
		});
	}

	private getOptions(): IColResizableOptions {
		let opts: IColResizableOptions = _.extend({}, this.defaultOptions, this.options);
		if (opts.onColumnDrag) {
			let wrappedCallback = (event: MouseEvent) => {
				let widths = $(this.elm.nativeElement).find('th').toArray()
					.map(domElement => $(domElement).outerWidth());
				this.debounceDragSubject.next({widths, event});
			};
			opts.onDrag = wrappedCallback;
			opts.onResize = wrappedCallback;
		}
		return opts;
	}
}
