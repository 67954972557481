<div class="objects-filter">
	<div ngbDropdown #dropdown="ngbDropdown" [autoClose]="false" placement="bottom-right bottom bottom-left">
		<button ngbDropdownToggle type="button" class="btn btn-selected" [disabled]="!isProjectSelected()">
			{{'common.filter'|i18n}}
		</button>
		<div ngbDropdownMenu class="interaction-filter dropdown-menu filter-dropdown pv-0">
			<div class="filter-builder-wrapper">
				<div class="filter-builder">
					<div class="filter-builder-top-section">
						<div class="p-8 pl-16 text-0_75rem filter-builder-header">{{'common.filters'|i18n}}</div>
						<div class="ph-8 pt-8 filter-dropdown">
							<mixed-filter-builder *ngIf="_filter && filterItems"
								[filterableFields]="filterItems"
								[filter]="_filter"
								[savedFilterOptions]="savedFilterOptions"
								[canAdd]="canAddMore"
								[projectSelection]="projectSelection"
								[appendToBody]="true"
								(onItemAdded)="onItemAdded()"
								(onItemRemoved)="onItemRemoved()"
								(onItemChanged)="onItemChanged()"
								(onChangedSavedFilter)="onChangedSavedFilter($event.index, $event.filter)">
							</mixed-filter-builder>
						</div>
					</div>
					<div class="p-8 filter-builder-controls">
						<alert class="mb-0" type="danger" *ngIf="filterRulesError">
							<span>{{'reportFilters.incompleteRulesShort'|i18n}}</span>
						</alert>
						<alert class="mb-0 ml-8" type="info" *ngIf="countError">
							<span>{{ getCountNote() }}</span>
						</alert>
						<button class="btn btn-primary" (click)="applyFilter()">{{'common.apply'|i18n}}</button>
						<button class="btn btn-secondary" (click)="resetFilter()">{{'preview.clearAll'|i18n}}</button>
					</div>
				</div>
				<div class="saved-filters w-280">
					<div class="p-8 pl-16 text-0_75rem filter-builder-header">{{'filter.savedFilters'|i18n}}</div>
					<div class="pt-8">
						<search-list searchPrompt="{{'reportFilters.findMessage'|i18n}}" displayField="name"
							sortField="name" [multiListOptions]="savedFilterOptions" multi="true"
							(onNodeSelection)="onAddSavedFilter($event.node)" selectorClass="br-filter-selector"
							[autoOpen]="false">
						</search-list>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
