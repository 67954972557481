<scroll-carousel
	[labels]="{next: ('dashboard.showNextTab'|i18n), previous: ('dashboard.showPreviousTab'|i18n)}"
	[scroller]="scroller"
	role="navigation"
	[attr.aria-label]="'common.bookNavigation' | i18n">
	<div role="status" aria-live="assertive" class="sr-only">
		{{selectedTabName}} {{'administration.selected'|i18n}}
	</div>
	<ul class="scroll-carousel-target d-flex flex-direction-row overflow-hidden m-0 p-0 fade-container" role="tablist">
		<ng-container *ngFor="let tab of tabs; let $index = index;">
			<li id="book-tab-{{$index}}"
				class="dashboard-tab mr-8"
				role="tab"
				[class.selected]="tab.active"
				[attr.aria-selected]="tab.active">
				<book-simple-tab class="btn normal-case p-0"
					[index]="$index"
					(tabSelect)="onSelectTab(tab)">
					<simple-tab-content *ngIf="tab.isDashboardTab()"
						[name]="tab.name">
					</simple-tab-content>
					<qualtrics-tab-content *ngIf="tab.isEmbedTab()"
						[name]="tab.name"
						[embedData]="tab.metadata.embedData">
					</qualtrics-tab-content>
				</book-simple-tab>
			</li>
		</ng-container>
	</ul>
</scroll-carousel>
