import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import * as _ from 'underscore';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) {}
	
	getPendoData(): any {
		return {
			visitor: {
				is_internal: this.security.isQualtricsEmployee(),
				studio_app_version: CONFIG.version,
				id: this.security.getUniquePreferredUserId(),
				first_name: this.security.getUser().firstName,
				userlanguage: this.locale.getLocale(),
				QUIP_id: this.security.getUser().userId,
				QUIP_license_type: this.security.getLicenseType(),
				QUIP_license_type_id: this.security.getLicenseTypeId()
			},
			account: {
				id: this.security.getUniquePreferredAccountId(),
				QUIP_id: this.security.getMasterAccountId()
			}
		};
	}

	initPendo(): any {
		if (this.environmentService.isUnderTest() || this.environmentService.hasPdfToken()) return;

		pendo.initialize(this.getPendoData());
	}
}

app.service('analyticsService', downgradeInjectable(AnalyticsService));
