export class VoiceSettings {
	vociEnabled: boolean;
	voiceNfsApplicationId: number;
	voiceVttApplicationId: number;
	audioDownloadEnabled: boolean;
	transcriptDownloadEnabled: boolean;
	transcriptionService: TranscriptionService;
	meaningfulSilenceThreshold: number;
}

export enum TranscriptionService {
}
