<modal-header
	(cancel)="cancel()"
	[modalTitle]="getModalTitle()">
</modal-header>

<div class="modal-body" >
	<div class="form-group">
		<label for="customName" class="d-block mb-4">{{input.labelName}}</label>
		<input class="d-block w-100-percent" 
				id='customName'
				required
				[(ngModel)]="newFieldValue"
				[ngbTypeahead]="valueSuggestions"
				minlength="1"
				maxlength="25"/>
	</div>
</div>

<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
		[saveText]="'common.save' | i18n"
		[isDisabled]="isSaveDisabled()"
></save-modal-footer>`