import * as _ from 'underscore';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { MasterAccountEngageStatus } from './master-account-engage-status';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class EngageStatusTableColumnsService {
	private columns: Array<TableColumn<any>>;

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		return [{
			name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'accountId',
			displayName: this.locale.getString('mAccount.propEngagorAccountId'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'topicId',
			displayName: this.locale.getString('mAccount.propEngagorTopicId'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'landingPage',
			displayName: this.locale.getString('mAccount.propEngagorLandingPage'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'region',
			displayName: this.locale.getString('mAccount.propEngagorRegion'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}];
	}
}
