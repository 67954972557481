<div>
	<div *ngFor="let metric of metrics; index as $index" class="w-100-percent mb-8">
		<div class="metric-statement w-100-percent d-flex justify-left align-center">
			<select-from-tree
				class="metric-selector col-sm-4 pl-0"
				[hierarchyList]="hierarchyList"
				[disabledItems]="metrics"
				(onNodeClick)="onMetricChange($index, $event.node)"
				[ngModel]="metric.name">
			</select-from-tree>
			<div class="metric-tools d-inline-block">
				<button
					type="button"
					class="btn btn-sm btn-secondary btn-link btn-icon remove-metric-button"
					[disabled]="!metricCanBeRemoved(metric)"
					(click)="removeMetric($index)"
					title="{{'widget.minus'|i18n}}">
					<span class="q-icon q-icon-minus" [attr.aria-label]="'widget.minus'|i18n"></span>
				</button>
				<button
					type="button"
					class="btn btn-sm btn-secondary btn-link btn-icon add-metric-button ml-0"
					[disabled]="!metricCanBeAdded()"
					(click)="addMetric()"
					title="{{'widget.plus'|i18n}}">
					<span class="q-icon q-icon-add" [attr.aria-label]="'widget.plus'|i18n"></span>
				</button>
			</div>
		</div>
	</div>
</div>
