import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ExportTypes } from '@cxstudio/master-accounts/export-types.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as cloneDeep from 'lodash.clonedeep';
import { LogQueryOptionsInput } from '../entities/log-query-options';


@Component({
	selector: 'security-audit-export',
	templateUrl: './security-audit-export.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityAuditExportComponent implements OnInit {
	readonly reportTypes = ExportTypes;

	@Input('input') input: LogQueryOptionsInput;
	dateFilters: any;
	logQueryBy: any;
	query: any;


	constructor(
		public activeModal: NgbActiveModal,
	) {
	}

	ngOnInit(): void {
		//creates a deep copy
		let optionsCopy = cloneDeep(this.input); 
		if (optionsCopy.logQueryBy === 'actionDate') {
			this.logQueryBy = '$'; // everything
			this.query = {};
			this.dateFilters = {
				startDate: moment(optionsCopy.dateRange.from).toDate(),
				endDate: moment(optionsCopy.dateRange.to).toDate()
			};
		} else {
			this.logQueryBy = optionsCopy.logQueryBy;
			this.query = optionsCopy.query;
		}
	}

	cancel(): void {
		this.activeModal.dismiss('cancel');
	}

}
