import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { AddWidgetToolbarPosition } from '@cxstudio/dashboards/widgets-toolbar/add-widget-toolbar-position.service';

/*
  Fix for CES-2442 (old jira), CSI-595 (new jira). Takes the idea of the fix here:
  https://github.com/ManifestWebDesign/angular-gridster/issues/301#issuecomment-176751903

  Sets the fixed width for 'gridster' element based on browser visible width (client width).
*/
app.directive('gridsterFixedWidth', ($timeout, addWidgetToolbarPosition: AddWidgetToolbarPosition) => {
	return {
		restrict: 'A',
		link: (scope, $element) => {
			let resizeObserver;

			let scrollbarVisible = false;

			function adjustGridsterBounds(): void {
				$timeout(() => {
					let gridsterStyle = getStyleObject();

					if (needsFixedWidth()) {
						if (!scrollbarVisible && hasScrollbar()) {
							// if scrollbar ever appears - just keep showing it to avoid endless width "dancing"
							($element.get(0) as HTMLElement).style.overflowY = 'scroll';
							scrollbarVisible = true;
						}
						gridsterStyle.width = getClientWidth() + 'px';
						gridsterStyle.marginLeft = '0px';
						gridsterStyle.marginRight = '0px';
					} else {
						gridsterStyle.removeProperty('width');
						gridsterStyle.removeProperty('margin-left');
						gridsterStyle.removeProperty('margin-right');
					}
				}, 100);
			}

			function needsFixedWidth(): boolean {
				return hasDefaultZoom();
			}

			function hasScrollbar(): boolean {
				return ($element.get(0) as HTMLElement).offsetWidth - ($element.get(0) as HTMLElement).clientWidth !== 0;
			}

			function hasDefaultZoom(): boolean {
				return scope.layout.zoomLevel === '' || scope.layout.zoomLevel === 100;
			}

			function getGridsterElement(): HTMLElement {
				return $element.get(0).firstChild as HTMLElement;
			}

			function getStyleObject(): CSSStyleDeclaration  {
				return getGridsterElement().style;
			}

			function getClientWidth(): number {
				return $element.get(0).clientWidth;
			}

			function initializeListeners(): void {
				adjustGridsterBounds();
				scope.$on('gridster-resized', adjustGridsterBounds);
				scope.$watch('layout.zoomLevel', adjustGridsterBounds);
				resizeObserver = ResizeHandlerUtils.addResizeHandler($element[0], adjustGridsterBounds);
				addWidgetToolbarPosition.registerListener(adjustGridsterBounds);

				scope.$on('$destroy', () => {
					ResizeHandlerUtils.removeResizeHandler(resizeObserver);
					addWidgetToolbarPosition.unregisterListener(adjustGridsterBounds);
				});
			}

			initializeListeners();
		}
	};
});



