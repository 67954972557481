import { Pipe, PipeTransform } from '@angular/core';
import * as orderBy from 'lodash.orderby';

@Pipe({
	name: 'weightedSort'
})

/**
 * Sorts list with priority items first
 */
export class WeightedSortPipe<T> implements PipeTransform {
	transform(items: T[], weightFunction: (item: T) => number, sortField: string): T[] {
		if (!items || items.length < 1) return [];
		return orderBy(items, [(item) => weightFunction(item), sortField], ['desc', 'asc']);
	}
}