<aside class="p-8 border border-round">
	<div class="d-flex kb-focusable"
		tabindex="0"
		[attr.aria-label]="getCaseAriaLabel()">
		<i [title]="getCaseStatus()"
			[attr.aria-label]="getCaseStatus()"
			class="q-icon-check"
			[ngClass]="getCaseStatusIcon()"></i>
		<span [title]="getCasePriorityText()"
			class="priority lh-default mh-8"
			[style.color]="case.priority.color">
			<span class="priority-base q-icon-priority-4"></span>
			<span class="priority-level"
				[ngClass]="case.priority.icon"></span>
		</span>

		<div class="d-flex flex-direction-column">
			<header class="mb-0">
				<b attr.aria-label="{{'preview.engagorCaseId' | i18n }}">#{{getCaseId()}}</b>
				<b>{{case.message.title}}</b>
			</header>
			<span *ngIf="!!getCaseAssignment()">{{getCaseAssignment()}}</span>
		</div>

		<a *ngIf="hasCaseAccess()"
			title="{{'cases.viewCase'|i18n}}"
			class="text-gray-900 q-icon q-icon-clipboard h-fit-content ml-8"
			[attr.aria-label]="getCaseRedirectionAriaLabel()"
			target="_blank"
			[href]="caseRedirectionLink"></a>
	</div>
</aside>
