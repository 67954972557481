import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { DEFAULT_DATE_RANGE, IReportWidgetContext, Replacement, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export class TableWidget extends ReportWidget {

	constructor(
		private metricConstants: MetricConstants,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService
	) {
		super(WidgetType.TABLE);
	}

	initFrom(source: Widget, replace: Replacement): void {
		let context = super.getContext(source, replace);
		this.exceptionsPreprocess(context);

		super.initFromContext(context);
		super.withFilters(context);

		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = [];
		this.widget.visualProperties.visualization = WidgetVisualization.CB_AN_TABLE;
		this.widget.visualProperties.layout = 'compact';
		this.widget.visualProperties.showTotal = false;

		super.withShowSampleSize(context);

		this.initDateRanges(context);
		this.initCalculations(context);
		this.initGroupings(context);
		this.initSort(context);
	}

	private exceptionsPreprocess(context: IReportWidgetContext): void {
		if (!_.isEmpty(context.replace)) return;

		//for scatter we need only vertical and horizontal axis calculations
		if (context.source.properties.widgetType === WidgetType.SCATTER) {
			let calculations = WidgetPropertyService.getValue(
				'properties.selectedMetrics', context, []);
			calculations = _.first(calculations, 2);
			context.source.properties.selectedMetrics = calculations;
		}
	}

	private isDualAxisSource(context: IReportWidgetContext): boolean {
		return context.source.properties.widgetType === WidgetType.BAR
			|| context.source.properties.widgetType === WidgetType.LINE;
	}

	private hasSentimentColor(context: IReportWidgetContext): boolean {
		if (this.isDualAxisSource(context)) {
			let selections = WidgetPropertyService.getValue(
				'visualProperties.attributeSelections', context);
			let sentimentName = StandardMetricName.SENTIMENT;
			return selections.color && selections.color.name === sentimentName;
		} else {
			let color = WidgetPropertyService.getValue(
				'visualProperties.color', context, '');
			return color.startsWith('_calculation_color.sentimentBreakdown');
		}
	}

	private initCalculations(context: IReportWidgetContext): void {
		let calculations = WidgetPropertyService.getValue(
			'properties.selectedMetrics', context, []) as ReportCalculation[];
		let volume = this.metricConstants.get().VOLUME;

		if (this.isDualAxisSource(context)) {
			let selections = WidgetPropertyService.getValue(
				'visualProperties.attributeSelections', context);
			this.processSelectionsCalculations(selections);
		} else {
			_.each(calculations, calculation => {
				if (calculation?.name === StandardMetricName.CONSTANT_SIZE) {
					super.addCalculation(volume);
				} else {
					super.addCalculation(calculation);
				}
			});
		}

		if (this.hasSentimentColor(context)) {
			super.addCalculation(this.metricConstants.get().SENTIMENT);
		}
	}

	private processSelectionsCalculations(selections): void {
		let CALCULATION_OPTIONS = [
			'yAxis', 'size', 'secondaryYAxis', 'secondarySize'
		];

		CALCULATION_OPTIONS.forEach(option => {
			let calculation = selections[option];
			if (!_.isEmpty(calculation)
					&& calculation.name !== StandardMetricName.CONSTANT_SIZE ) {
				super.addCalculation(calculation);
				if (this.widget.properties.useHistoricPeriod) {
					let historic = this.periodOverPeriodMetricService.createMetric(
						calculation, PeriodOverPeriodMetricType.HISTORICAL);
					super.addCalculation(historic);
				}
			}
		});
	}

	private addGrouping(grouping: AttributeGrouping): void {
		if (!grouping) return;
		if (this.widget.properties.selectedAttributes.length === 3) return;
		delete grouping.stackType;
		this.widget.properties.selectedAttributes.push(grouping);
		if (AnalyticMetricTypes.isTime(grouping)) {
			this.widget.properties.primaryTimeGrouping = grouping;
		}
	}

	private initGroupings(context: IReportWidgetContext): void {
		let groupings = WidgetPropertyService.getValue(
			'properties.selectedAttributes', context, []);
		if (groupings.length > 1) {
			this.widget.properties.normalizeGroups = context.source.properties.normalizeGroups;
		}

		groupings.forEach(grouping => {
			this.addGrouping(grouping);
		});
	}

	private initDateRanges(context: IReportWidgetContext): void {
		let p2DateRange = {
			dateFilterMode: 'previousPeriod'
		};

		super.withDateRanges(context, {
			properties: [
				{name: 'dateRangeP1', default: DEFAULT_DATE_RANGE},
				{name: 'dateRangeP2', default: p2DateRange}
			],
			visual: [{
				name: 'periodLabel',
				default: {
					period_1_: '',
					period_2_: 'Historic'
				}
			}]
		});
	}

	private initSort(context: IReportWidgetContext): void {
		let SORT_PROPERTIES = [
			{name: 'sortBy', default: 'volume'},
			{name: 'direction', default: 'DESC'}
		];
		super.initProperties(SORT_PROPERTIES, context, 'visualProperties');
	}
}
