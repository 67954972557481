import { CxHttpService } from '@app/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Injectable } from '@angular/core';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountId, ContentProviderId } from '@cxstudio/generic-types';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { ConversationSettingsEntry } from '@app/modules/account-administration/conversation-settings/conversation-settings.entity';
import { ConversationSpineDefaults } from '@app/modules/conversation/conversation-spine-defaults';

@Injectable({ providedIn: 'root' })
export class ConversationSettingsApi {
	private readonly cachedHttp: CxCachedHttp;

	constructor(
		private readonly http: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		private readonly httpHandlers: CxHttpHandlers,
		private readonly conversationSpineDefaults: ConversationSpineDefaults,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.SPINE_SETTINGS);
	}

	getDefaultSpineSettings = (cache = CacheOptions.CACHED): Promise<SpineSettings> => {
		return this.cachedHttp.get('rest/spine-settings/default', { cache })
			.then((settings: SpineSettings) => {
				if (!settings || Object.keys(settings).length === 0) {
					return this.conversationSpineDefaults.get();
				}
				return settings;
			});
	}

	getSpineSettings = (project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): Promise<SpineSettings> => {
		const url = this.getProjectBaseUrl(project);
		return this.cachedHttp.get(url, { cache })
			.then((projectEntries: ConversationSettingsEntry[]) => {
				if (!projectEntries || !projectEntries.length || !projectEntries[0].enabled) {
					return this.getDefaultSpineSettings();
				} else {
					return projectEntries[0].settings;
				}
			});
	}

	private getProjectBaseUrl(project: AccountOrWorkspaceProject): string {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? `rest/spine-settings/ws/${project.workspaceId}/project/${project.projectId}/custom`
			: `rest/spine-settings/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}/custom`;
	}

	getCustomSpineSettings = (contentProviderId: ContentProviderId, accountId: AccountId): Promise<ConversationSettingsEntry[]> => {
		return this.http.get(`rest/spine-settings/cp/${contentProviderId}/account/${accountId}/custom`)
			.then((settings: ConversationSettingsEntry[]) => {
				if (!settings || settings.length === 0) {
					return [];
				}
				return settings;
			});
	}

	saveSpineSettings = (spineSettings: ConversationSettingsEntry): Promise<void> => {
		return this.http.post('rest/spine-settings', spineSettings)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SPINE_SETTINGS))
			.then(_.noop);
	}

	deleteCustomSpineSettings = (conversationSettings: ConversationSettingsEntry): Promise<void> => {
		return this.http.delete(`rest/spine-settings/${conversationSettings.id}`)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SPINE_SETTINGS))
			.then(_.noop);
	}
}

app.service('conversationSettingsApi', downgradeInjectable(ConversationSettingsApi));
