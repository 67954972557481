import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';

export const contextSectionsVisibility = (() => {
	const defaultVisibility = {};
	for (let key in FavoriteType) {
		if (FavoriteType.hasOwnProperty(key) && key !== 'SYSTEM') {
			defaultVisibility[FavoriteType[key]] = true;
		}
	}

	return defaultVisibility;
})();

// items stored in localstorage
export const LocallyStoredItems = {
	betaFeaturesRequested: {
		storageName: 'betaFeaturesRequested',
		fallbackValue: []
	},
	recentColors: {
		storageName: 'recentColors',
		// value to initialize to if storage value is unset or localstorage is not available
		fallbackValue: []
	},
	project: {
		storageName: 'project'
	},
	docExplorer: {
		storageName: 'docExplorerPreferences',
		fallbackValue: {
			showSentiment: true,
			visiblePanes: {
				sentence: true,
				context: true
			},
			modelSearch: {},
			keyInfo: [],
			contextSectionsVisibility,
			paneCollapsedState: {
				favoriteAttributesCollapsed: false,
				attrCollapsed: false,
				worldAwarenessCollapsed: false,
				topicsCollapsed: false
			},
			sentencePane: {
				showSentiment: true,
				showSource: true
			}
		}
	},
	conversation: {
		storageName: 'conversationPreferences',
		fallbackValue: {
			playbackSettings: {}
		}
	},
	dashboardEditor: {
		storageName: 'dashboardEditor',
		fallbackValue: {
			pushOnDrop: true,
			widgetsToolbarPosition: 'TOP'
		}
	},
	intelligentScoring: {
		storageName: 'intelligentScoring',
		fallbackValue: {
			sortCategory: 'default',
			sort: 'none'
		}
	},
	interactionFilter: {
		storageName: 'interactionFilter',
		fallbackValue: {}
	},
	homePageId: {
		storageName: 'homePageId',
		fallbackValue: undefined,
	}
};
