import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';

@Component({
	selector: 'labeled-toggle-switch',
	template: `
	<a class="option d-flex align-center w-100-percent{{small ? ' h-32' : ''}}"
		tabindex="-1"
		[attr.role]="dropdownItem ? 'menuitem' : ''"
		[class.justify-between]="alignBetween"
		[class.dropdown-item]="dropdownItem"
		[class.text-gray-900]="!dropdownItem"
		href="javascript:void(0)"
		[attr.aria-label]="getOptionAriaLabel()"
		(keydown.enter)="processKeyToggle($event)"
		(click)="processLabelClick()">
		<span id="toggle-switch-label" class="cursor-no-selection">{{label}}</span>
		<cb-toggle class="ml-8 flex-fixed {{small ? 'transform-70-percent': ''}}"
			[attr.role]="dropdownItem ? 'menuitemcheckbox' : ''"
			[attr.aria-label]="'common.toggleObject' | i18n:{objectName: label}"
			[(ngModel)]="value"
			(ngModelChange)="valueChange.emit($event)"
			[disabled]="disabled"
		></cb-toggle>
	</a>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabeledToggleSwitchComponent {

	@Input() label: string;
	@Input() groupLabel: string;
	@Input() disabled: boolean = false;
	@Input() small: boolean = false;
	@Input() value: boolean;
	@Input() dropdownItem: boolean;
	@Input() alignBetween: boolean = true;
	@Output() valueChange = new EventEmitter<boolean>();

	getOptionAriaLabel(): string {
		return _.isUndefined(this.groupLabel)
			? this.label
			: `${this.groupLabel}. ${this.label}`;
	}

	processLabelClick(): void {
		if (this.disabled) {
			return;
		}

		this.value = !this.value;
		this.valueChange.emit(this.value);
	}

	processKeyToggle(event: Event): void {
		event.stopImmediatePropagation();
		event.preventDefault();
		this.processLabelClick();
	}
}
