<div>
	<div class="grid-list-tools" [ngBusy]="loading">
		<div class="tools">
			<label class="font-bold mb-0">
				{{'mAccount.findEmbeddedWidgets'|i18n}}
				<input
					type="text"
					[disabled]="!embeddedWidgets?.length"
					class="grid-search-bar d-block"
					attr.aria-label="{{'common.searchAll'|i18n}}"
					[(ngModel)]="nameSearch">
			</label>
		</div>
	</div>

	<div [ngBusy]="loading">
		<item-grid
			class="h-100-percent w-100-percent br-grid"
			[treeData]="embeddedWidgets"
			[gridOptions]="gridOptions"
			[gridFilter]="nameSearch"
			[nameField]="gridNameField"
			[gridType]="gridType"
			[gridChange]="changedItems"
			[controller]="gridController">
		</item-grid>
	</div>
</div>