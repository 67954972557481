<page-content-header [title]="'header.schedules' | i18n" class="mb-24"></page-content-header>
<div class="container-full schedules-page">
	<div class="br-list-box" sticky-slick-header>
		<div class="grid-list-tools">
			<div class="tools">
				<div class="grid-filters">
					<simple-dropdown
							class="mr-8"
							[hidden]="!ui.showUserSelector()"
							[options]="userOptions"
							displayField="userEmail"
							valueField="userId"
							[(value)]='ui.currentUserId'
							(onChange)="filterSchedules()">
					</simple-dropdown>

					<label class="font-bold mb-0">
						{{'schedule.searchSchedules'|i18n}}
						<input
							type="text"
							class="br-schedules-search-bar grid-search-bar d-block"
							attr.aria-label="{{'schedule.searchSchedules'|i18n}}"
							(keydown.escape)="ui.searchSchedules=''"
							[(ngModel)]="ui.searchSchedules"
							debounce="300"
							(debouncedChange)="filterSchedules()">
					</label>
				</div>
			</div>
		</div>

		<div [ngBusy]="loading" class="br-metric-list">
			<item-grid
				class="h-100-percent w-100-percent br-schedule-grid br-grid"
				[treeData]="ui.filteredSchedulesList"
				[gridType]="gridType"
				[gridFilter]="ui.searchSchedules"
				[gridChange]="ui.lastChange"
				[gridOptions]="gridOptions"
				[nameField]="gridNameField">
			</item-grid>
		</div>
	</div>
</div>
