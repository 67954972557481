import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { ContentProviderServerContext } from '@app/modules/system-administration/content-provider/content-provider-server-context';
import { Security } from '@cxstudio/auth/security-service';
import { ContentProviderId } from '@cxstudio/generic-types';
import { Observable, forkJoin, of } from 'rxjs';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Caches } from '@cxstudio/common/caches';

export interface ContentProviderOAuthSettingsResponse {
	clientId: string;
	redirectUri: string;
	scope: any[];
}

@Injectable({
	providedIn: 'root'
})
export class ContentProviderApi {
	constructor(
		private cxHttp: CxHttpService,
		private cxCachedHttpService: CxCachedHttpService,
		@Inject('security') private security: Security,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService
	) {}

	getContentProviderServerContext = (contentProviderId: number): Promise<ContentProviderServerContext> => {
		return this.cxHttp.get(`rest/contentprovider/${contentProviderId}/context`);
	}

	getContentProviderVersion = (contentProviderId: ContentProviderId) =>
		this.cxHttp.get(`rest/contentprovider/cpVersion/${contentProviderId}`)

	getContentProviderApiVersion = (contentProviderId: ContentProviderId) =>
		this.cxHttp.get(`rest/contentprovider/apiVersion/${contentProviderId}`)

	getContentProviderFeatureVersion = (contentProviderId: ContentProviderId): Promise<string> =>
		this.cxCachedHttpService.cache(Caches.PROJECT_CONTEXT)
			.get(`rest/contentprovider/feature-version/${contentProviderId}`, {cache: CacheOptions.CACHED})

	getContentProviderList(): Promise<ContentProvider[]> {
		return this.hasFullDataAccess()
			? this.getMasterAccountContentProviders()
			: this.getUserContentProviderList();
	}

	getContentProviderAccounts(cpId: number): Promise<ContentProviderAccount[]> {
		if (this.hasFullDataAccess())
			return this.getContentProviderAccessibleAccounts(cpId);
		else return this.contentProviderOptionsService.getAccounts(cpId);
	}

	getAccountProjects(contentProviderId: number, accountId: number): Promise<AccountProject[]> {
		if (this.hasFullDataAccess())
			return this.getAllAccountProjects(contentProviderId, accountId);
		else return this.contentProviderOptionsService.getOptions(
			contentProviderId, 'projects', {contentProviderId, accountId}, true).then(response => response.data);
	}

	private hasFullDataAccess(): boolean {
		return this.security.isAnyAdmin() || this.security.isCustomerAdmin();
	}

	getAdminContentProviderList(): Promise<ContentProvider[]> {
		return this.cxHttp.get('rest/system-admin/content-providers/cblist');
	}

	getUserContentProviderList(): Promise<ContentProvider[]> {
		return this.cxHttp.get('rest/contentprovider/cblist');
	}

	private getMasterAccountContentProviders(): Promise<ContentProvider[]> {
		return this.cxHttp.get('rest/units/content-providers');
	}

	private getContentProviderAccessibleAccounts(cpId: number): Promise<ContentProviderAccount[]> {
		return this.cxHttp.get(`rest/units/content-providers/${cpId}/accounts`);
	}

	private getAllAccountProjects(cpId: number, accountId: number): Promise<AccountProject[]> {
		return this.cxHttp.get(`rest/units/content-providers/${cpId}/accounts/${accountId}/projects`);
	}

	getCompatibility = () => this.cxHttp.get('rest/contentprovider/compatibility').then((response: any) => response.data);

	getContentProviderOAuthSettings = (contentProviderId: ContentProviderId): Promise<ContentProviderOAuthSettingsResponse> =>
		this.cxHttp.get(`rest/contentprovider/${contentProviderId}/settings/oauth`, {local: true})

	getUserContentProvidersWithAccounts = (): Observable<any> => {
		return new Observable(obs => {
				this.getUserContentProviderList().then((contentProviders: any) => {
					if (!contentProviders || !contentProviders.length) {
						return of([]).subscribe(o => obs.next(o));
					}

					return forkJoin(contentProviders.map((contentProvider) => {
						return this.contentProviderOptionsService.getUserAccounts(contentProvider.id).then((accounts) => {
							contentProvider.accounts = accounts.data;
							return contentProvider;
						});
					})).subscribe(cpsWithAccts => obs.next(cpsWithAccts));
				});
			});
	}
}

app.service('contentProviderApiService', downgradeInjectable(ContentProviderApi));
