import { Injectable } from '@angular/core';
import Widget, { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MAX_TEXT_LENGTH, ButtonWidgetVisualProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})
export class ContentWidgetValidationService {

	constructor(
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
	) { }

	validate(widget: Widget): boolean {
		if (this.isTextWidget(widget)) {
			if (widget.properties.textLength > MAX_TEXT_LENGTH) {
				this.cxDialogService.warning(this.locale.getString('common.warning'),
					this.locale.getString('widgetSettings.textTooLong'));
				return false;
			}
		} else if (widget.properties.widgetType === WidgetType.BUTTON) {
			let visualProps = widget.visualProperties as ButtonWidgetVisualProperties;
			if (!visualProps.text?.trim()) {
				this.cxDialogService.warning(this.locale.getString('common.warning'),
					this.locale.getString('widgetSettings.buttonEmptyError'));
				return false;
			}

			if (visualProps.linkUrl && !/^https?:\/\//ig.test(visualProps.linkUrl)) {
				this.cxDialogService.warning(this.locale.getString('common.warning'),
					this.locale.getString('widgetSettings.buttonInvalidLink'));
				return false;
			}

		}
		return true;
	}

	private isTextWidget(widget: Widget): widget is ContentWidget {
		return widget.properties.widgetType === WidgetType.TEXT
			|| widget.properties.widgetType === WidgetType.LABEL;
	}
}
