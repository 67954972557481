import { IFilterRule, IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';

export enum MixedItemType {
	FILTER_RULE = 'FILTER_RULE',
	SAVED_FILTER = 'SAVED_FILTER',
	HARDCODED_FILTER = 'HARDCODED_FILTER'
}

export type MixedFilterRuleEntity = IFilterRule | HardcodedFilterItem;

export interface MixedFilterItem {
	type: MixedItemType;
	entity: MixedFilterRuleEntity;
	pinned?: boolean;
	skipLimit?: boolean;
}

export interface MixedFilter {
	items: MixedFilterItem[];
}

export interface HardcodedFilterItem {
	displayName: string;
	type: HardcodedFilterType;
	values?: IFilterRuleValue[];
}

export enum HardcodedFilterType {
	SKIP_RECENT = 'SKIP_RECENT',
	SHARED_DOCUMENTS = 'SHARED_DOCUMENTS'
}
