import { AccountOrWorkspaceProject, WorkspaceId, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export class WorkspaceProjectUtils {
	static isWorkspaceSelected(space: WorkspaceId): boolean {
			return space > -1;
	}

	static isProjectSelected(workspaceProject: WorkspaceProject): boolean {
		return workspaceProject
			&& this.isWorkspaceSelected(workspaceProject.workspaceId)
			&& workspaceProject.projectId
			&& workspaceProject.projectId !== -1;
	}
}
