import { ITableData, SimpleTableDataBuilder } from '@cxstudio/reports/stats-mode/simple-table-data-builder';

export class CsvTableDecomposer {

	static decompose(csv: string): ITableData {
		let tableData = this.decomposeCSVIntoTable(csv);

		tableData = tableData.filter(row => this.rowIsNotEmpty(row));
		tableData = this.expandShortRows(tableData);
		tableData = this.removeTotal(tableData);

		return new SimpleTableDataBuilder()
			.fromWellFormedData({
				header: tableData[0],
				body: tableData.slice(1)
			})
			.withoutEmptyColumns()
			.build();
	}

	private static decomposeCSVIntoTable(csv: string): string[][] {
		return csv.split(/\r\n/).map((row: string) => {
			return row.split('","').map((cell: string) => {
				return cell.replace(/\"/g, '');
			});
		});
	}

	private static rowIsNotEmpty(row: string[]): boolean {
		return _.any(row, cell => cell && cell.trim().length > 0);
	}

	private static expandShortRows(tableData: string[][]): string[][] {
		let maxColumns = 0;
		tableData.forEach((row: string[]) => {
			if (row.length > maxColumns) maxColumns = row.length;
		});
		return tableData.map((row: string[]) => {
			return row.length < maxColumns ? row.concat(new Array(maxColumns - row.length)) :
				row;
		});
	}

	private static removeTotal(tableData: string[][]): string[][] {
		return tableData.filter((row) => {
			return !row[0].startsWith('n=');
		});
	}

}
