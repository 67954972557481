import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextTokenType } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';


@Injectable({providedIn: 'root'})
export class SpaceAdapter implements CustomMathExpressionAdapter {
	constructor() {}
	
	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: [{
				text: token.text,
				type: TextTokenType.SPACE
			}]
		};
		return segment;
	}
	
	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		throw new Error('Unsupported operation');
	}
}