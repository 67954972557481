import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';

import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { IContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';

import * as _ from 'underscore';
import { IStudioAlertActionsService } from '@cxstudio/alerts/studio-alert-actions-service';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { Security } from '@cxstudio/auth/security-service';


export interface IStudioAlertsContextMenuScope {
	getRemainingEnabledAlertCount: () => number;
	alertList: any[];
	selectionUtils: GenericSelectUtils<StudioAlert>;
	actionsService: IStudioAlertActionsService;
	showHidden: boolean;
	projectSelection: IProjectSelection;
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.service('StudioAlertContextMenuUtils', function(locale, security: Security) {
	return class implements IContextMenuUtils<StudioAlert> {
		private scope: IStudioAlertsContextMenuScope;

		private readonly OPTIONS = {
			EDIT: {text: locale.getString('common.edit'), name: 'edit',
				func: (alert: StudioAlert) => this.scope.actionsService.editAlert(alert)},

			DELETE_ALERT: {text: locale.getString('common.delete'), name: 'delete',
				func: (alert: StudioAlert) => this.scope.actionsService.deleteAlert(alert)},

			COPY: {text: locale.getString('dashboard.copy'), name: 'copy',
				func: (alert: StudioAlert) => this.scope.actionsService.duplicateAlert(alert)},

			ENABLE: {text: locale.getString('common.enable'), name: 'enable-item',
				func: (alert: StudioAlert) => this.scope.actionsService.toggleState(alert)},

			DISABLE: {text: locale.getString('common.disable'), name: 'disable-item',
				func: (alert: StudioAlert) => this.scope.actionsService.toggleState(alert)},

			BULK_ENABLE: {text: locale.getString('common.enable'), name: 'bulk-enable-item',
				func: (alerts: StudioAlert[]) => this.scope.actionsService.bulkEnable(this.getSelectedAlerts())},

			BULK_DISABLE: {text: locale.getString('common.disable'), name: 'bulk-disable-item',
				func: (alerts: StudioAlert[]) => this.scope.actionsService.bulkDisable(this.getSelectedAlerts())},

			BULK_DELETE_ALERT: {text: locale.getString('common.delete'), name: 'bulkDelete',
				func: (alerts: StudioAlert[]) => this.scope.actionsService.bulkDeleteAlerts(this.getSelectedAlerts())},

			EMAIL_SUBSCRIPTION: {text: locale.getString('alert.optOuts'), name: 'emailOptOuts',
				func: (alert: StudioAlert) => this.scope.actionsService.emailOptOutsModal(alert)},

			TRANSFER_SELECTED: {text: locale.getString('dashboard.transferOwnerWidgetConfirmOption'), name: 'transfer'}
		};

		constructor(scope: IStudioAlertsContextMenuScope) {
			this.scope = scope;
		}

		getContextMenu(item: StudioAlert): Array<ContextMenuItem<StudioAlert>> {
			if (this.scope.selectionUtils.multipleObjectsSelected()) {
				return this.getMultiSelectMenuOptions();
			}
			return this.getAlertMenuOptions(item);
		}

		private getAlertMenuOptions(item: StudioAlert): Array<ContextMenuItem<StudioAlert>> {
			let items: Array<ContextMenuItem<StudioAlert>> = [];

			let canManageAlerts = security.has('manage_studio_alerts');

			if (canManageAlerts) {
				if (this.isOwner(item)) {
					items.push(this.OPTIONS.EDIT);
					if (item.enabled) {
						items.push(this.OPTIONS.DISABLE);
					} else if (this.scope.getRemainingEnabledAlertCount() > 0) {
						items.push(this.OPTIONS.ENABLE);
					}
					if (item.unsubscribedCount > 0) {
						items.push(this.OPTIONS.EMAIL_SUBSCRIPTION);
					}
					items.push(BaseContextMenuUtils.MENU_DIVIDER);
					items.push(this.OPTIONS.COPY);
					items.push(BaseContextMenuUtils.MENU_DIVIDER);
					items.push(this.OPTIONS.DELETE_ALERT);

					this.addTransferringOption(items, [item]);
				} else {
					items.push(this.OPTIONS.EDIT);
					if (item.enabled) {
						items.push(this.OPTIONS.DISABLE);
					} else if (this.scope.getRemainingEnabledAlertCount() > 0) {
						items.push(this.OPTIONS.ENABLE);
					}
				}
			}
			return items;
		}

		private getMultiSelectMenuOptions(): Array<ContextMenuItem<StudioAlert>> {
			let items: Array<ContextMenuItem<StudioAlert>> = [];

			let canManageAlerts = security.has('manage_studio_alerts');
			let selectedAlerts = this.getSelectedAlerts();

			let isOwnerOfAll = _.filter(selectedAlerts, this.isOwner).length === selectedAlerts.length;
			let disabledAlertsCount: number = _.countBy(selectedAlerts, 'enabled').false;
			if (canManageAlerts) {

				// if some of the alerts are disabled, and we have enough space to enable them all, add the enable option
				if ((disabledAlertsCount > 0) && (this.scope.getRemainingEnabledAlertCount() >= disabledAlertsCount)) {
					items.push(this.OPTIONS.BULK_ENABLE);
				}

				if (isOwnerOfAll) {

					if (selectedAlerts.length !== disabledAlertsCount) {
						items.push(this.OPTIONS.BULK_DISABLE);
					}

					if (!items.isEmpty()) {
						items.push(BaseContextMenuUtils.MENU_DIVIDER);
					}

					items.push(this.OPTIONS.BULK_DELETE_ALERT);
					this.addTransferringOption(items, selectedAlerts);
				} else {
					if (selectedAlerts.length !== disabledAlertsCount) {
						items.push(this.OPTIONS.BULK_DISABLE);
					}
				}

			}
			return items;
		}

		private addTransferringOption(menuItems: Array<ContextMenuItem<StudioAlert>>, selectedItems: any[]): void {
			if (!(this.scope.projectSelection.projectId > 0)) {
				return;
			}

			menuItems.push(this.extend(this.OPTIONS.TRANSFER_SELECTED, {
				func: () => {
					let props = this.scope.projectSelection;
					let projectIdentifier = new ProjectIdentifier(props.contentProviderId, props.accountId, props.projectId);

					this.scope.actionsService.transferMetricAlerts(selectedItems, projectIdentifier).then(() => {
						this.scope.selectionUtils.clearSelections();
						this.scope.selectionUtils.refreshObjects(selectedItems);
					});
				}
			}));
		}

		private isOwner(alert: StudioAlert): boolean {
			return security.isCurrentUser(alert.ownerName);
		}

		private getSelectedAlerts(): any[] {
			return _.filter(this.scope.alertList, (alert: StudioAlert) =>
				this.scope.selectionUtils.isSupportedType(alert) && (alert as any).selected
			);
		}

		extend(menuItem: any, settings: any): any {
			return angular.extend(angular.copy(menuItem), settings);
		}
	};
});
