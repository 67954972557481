import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IBookTab } from '@cxstudio/dashboards/entity/book';

@Directive({
	selector: 'book-tabs-editor'
})

export class BookTabsEditorUpgradeDirective extends UpgradeComponent {
	@Input() tabs: IBookTab[];
	@Output() onChange: EventEmitter<{$tab: IBookTab}>;
	@Input() onTabClick: EventEmitter<{$tab: IBookTab}>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('bookTabsEditor', elementRef, injector);
	}
}
