import { IDirectiveFactory, INgModelController } from 'angular';

const pwCheck = () => {
	return {
		require: 'ngModel',
		link: (scope, elem, attrs, ctrl: INgModelController) => {
			let firstPassword = `#${attrs.pwCheck}`;
			elem.add(firstPassword).on('keyup', () => {
				scope.$apply(() => {
					let v = elem.val() === $(firstPassword).val();
					ctrl.$setValidity('pwmatch', v);
				});
			});
		}
	};
};

/**
 * Directive for validating confirm password
 * Has NG Twin
 */
app.directive('pwCheck', pwCheck as IDirectiveFactory);
