import { NgModule } from '@angular/core';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { SharedModule } from '@app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmMobileAppOwnerChangeDialogComponent } from './confirm-mobile-app-owner-change-dialog.component';
import { MobileApplicationWidgetBuilderService } from './mobile-application-widget-builder.service';
import { MobileAppSavedFiltersComponent } from './mobile-app-saved-filters/mobile-app-saved-filters.component';
import { MobileApplicationHelperService } from './mobile-application-helper.service';
import { WrongTopicReportingComponent } from './wrong-topic-reporting/wrong-topic-reporting.component';
import { UnitsModule } from '../units/units.module';
import { MobileWorkspaceSelectorComponent } from './mobile-workspace-selector/mobile-workspace-selector.component';
import MobileSettingsGridDefinition from '@app/modules/mobile/mobile-settings/mobile-settings-grid-definition.service';
import MobileStudioApiService from '@app/modules/mobile/services/mobile-studio-api.service';

@NgModule({
	imports: [
		SharedModule,
		DragDropModule,
		DialogModule,
		UnitsModule
	],
	exports: [
		ConfirmMobileAppOwnerChangeDialogComponent,
		MobileAppSavedFiltersComponent,
		MobileWorkspaceSelectorComponent,
		WrongTopicReportingComponent
	],
	declarations: [
		ConfirmMobileAppOwnerChangeDialogComponent,
		MobileAppSavedFiltersComponent,
		MobileWorkspaceSelectorComponent,
		MobileAppSavedFiltersComponent,
		WrongTopicReportingComponent
	],
	providers: [
		ConfirmMobileAppOwnerChangeDialogComponent,
		MobileApplicationWidgetBuilderService,
		MobileApplicationHelperService,
		MobileSettingsGridDefinition,
		MobileStudioApiService
	],
})
export class MobileModule {}
