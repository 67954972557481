import { URLSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { HorizontalAlignment } from '../providers/cb/definitions/horizontal-alignment.enum';
import { VerticalAlignment } from '../providers/cb/definitions/vertial-alignment.enum';
import { FormattingOptions } from '../providers/cb/services/analytic-preview-formatting.service';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';

export type ITableColumnFormatter<T> = (item: T, field?: string, options?: FormattingOptions) => string;

export class TableColumn<T> implements Partial<URLSettings> {
	id?: string;
	name: string;
	path?: string;
	displayName?: string;
	width?: number; // 0..1 - percentage of total width
	align?: string;
	formatter?: ITableColumnFormatter<T>;
	type?: string;
	attributeType?: string;
	model?: boolean;
	isConfigurable?: boolean;
	capitalization?: CapitalizationType;
	action?: any;
	disabled?: boolean;
	scale?: string;
	verticalAlign?: VerticalAlignment;
	horizontalAlign?: HorizontalAlignment;
	urlType?: string;
	tableRowHeight?: number;
	parentId?: number;
	filterable?: boolean;
}
