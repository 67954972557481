import { Component, OnInit, ChangeDetectionStrategy, Inject,	Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-type.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { IDashboardTemplatePlaceholders, TemplatePlaceholderService } from '@cxstudio/dashboard-templates/placeholders/template-placeholder-service.service';
import { AvailablePlaceholdersPipe } from '../available-placeholders.pipe';
import * as cloneDeep from 'lodash.clonedeep';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';

interface TemplateMappingsInput {
	template: CustomTemplate;
}
@Component({
	selector: 'template-edit-mappings-dialog',
	templateUrl: './template-edit-mappings-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateEditMappingsDialogComponent implements OnInit {
	@Input('input') input: TemplateMappingsInput;
	@ViewChild('templateDialogForm', {static: false}) templateDialogForm;

	TemplatePlaceholderType = TemplatePlaceholderType;
	loading = {
		save: null
	};

	templateDialog: NgForm;
	model: {
		placeholders: IDashboardTemplatePlaceholders;
	};


	constructor(
		private readonly modal: NgbActiveModal,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('templatePlaceholderService') private readonly templatePlaceholderService: TemplatePlaceholderService,
		private readonly availablePlaceholders: AvailablePlaceholdersPipe
	) { }

	ngOnInit(): void {
		this.model = {
			placeholders: this.input.template.placeholders
		};
	}

	save(): void {
		let model = cloneDeep(this.model);

		this.preprocessModel(model);
		this.loading.save = this.dashboardTemplatesApiService
			.updateTemplateMappings(this.input.template.id as number, model)
			.then(() => this.modal.close());
	}

	cancel(): void {
		this.modal.dismiss('cancel');
	}

	hasPlaceholders(): boolean {
		let placeholders = this.model.placeholders;
		return placeholders &&
			(this.availablePlaceholders.transform(placeholders.calculations).length > 0
			|| this.availablePlaceholders.transform(placeholders.groupings).length > 0);
	}

	private preprocessModel(model): void {
		this.preprocessPlaceholders(model.placeholders);
	}

	private preprocessPlaceholders(placeholders): void {
		placeholders.calculations.forEach(this.preprocessPlaceholderName);
		placeholders.groupings.forEach(this.preprocessPlaceholderName);
	}

	private preprocessPlaceholderName(placeholder): void {
		placeholder.displayName = placeholder.displayName || placeholder.originalDisplayName;
	}
}
