<modal-header
	[modalTitle]="'scorecards.testScorecardResultsTitle' | i18n"
	(cancel)="close()">
</modal-header>

<section class="modal-body">
	<div class="scorecard-preview-result" [ngStyle]="{'min-height': getMinHeight()}" [ngBusy]="loading">
		<span *ngIf="showNoResultsMessage" [i18n]="'scorecards.spotCheckNoResults'"></span>
		<div *ngIf="!showNoResultsMessage">
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.outcome'"></span>
				</label>
				<div class="col-sm-9">
					<span [textContent]="outcomeText" class="_t-scorecard-outcome"></span>
				</div>
			</div>
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.targetScore'"></span>
				</label>
				<div class="col-sm-9">
					<span [textContent]="targetScore" class="_t-scorecard-targetScore"></span>
				</div>
			</div>
			<div class="row">
				<hr>
			</div>
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.minMaxScore'"></span>
				</label>
				<div class="col-sm-9">
					<span [textContent]="minMaxScoreText" class="_t-scorecard-minMaxScore"></span>
				</div>
			</div>
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.averageScore'"></span>
				</label>
				<div class="col-sm-9">
					<span [textContent]="averageScore" class="_t-scorecard-averageScore"></span>
				</div>
			</div>
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.percentiles'"></span>
				</label>
				<div class="col-sm-9">
					<span [textContent]="percentilesText" class="_t-scorecard-percentiles"></span>
				</div>
			</div>
			<div class="row">
				<hr>
			</div>
			<div class="row">
				<label class="col-sm-3 d-flex font-bold">
					<span [i18n]="'scorecards.potentialCoverage'"></span>
					<cb-inline-help bottom>
						<help-body>
							<p [i18n]="'scorecards.potentialCoverageTooltip'"></p>
						</help-body>
					</cb-inline-help>
				</label>
				<div class="col-sm-9">
					<span [textContent]="potentialCoverageText" class="_t-scorecard-potentialCoverage"></span>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6 d-flex">
					<button
						type="button"
						id="clear-cache"
						class="btn btn-secondary"
						(click)="loadPreview(true)"
						[i18n]="'scorecards.clearCache'"
					></button>
					<cb-inline-help fixed="true" class="d-flex ml-8">
						<help-body>
							<p>{{clearCacheTooltip}}</p>
						</help-body>
					</cb-inline-help>
				</div>
			</div>
		</div>
	</div>
</section>

<ok-modal-footer
	[okText]="'common.ok' | i18n"
	(cancel)="close()"
></ok-modal-footer>
