import * as _ from 'underscore';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Security } from '@cxstudio/auth/security-service';

export class ContentProviderLimiter {
	private queue: any = [];
	private currentCount: number = 0;
	private highPriorityGroup: number;

	constructor(
		private $q: ng.IQService,
		private security: Security,
		private widgetTimingService: WidgetTimingService
	) {}

	private getRequestsLimit(): number {
		let value = this.security.getCurrentMasterAccount()?.maxParallelRequests;
		return value >= 0 ? value : 8;
	}

	setHighPriorityGroup = (groupId: number) => {
		this.highPriorityGroup = groupId;
	}

	reset = () => {
		this.queue.removeAll();
		this.highPriorityGroup = undefined;
		this.currentCount = 0;
	}

	wrap = (requestFunction: any, priority: number, widget?: Widget) => {
		if (!_.isFunction(requestFunction) || isNaN(priority))
			throw new Error('wrong limiter usage');
		let createQueueItem = this.createQueueItem;
		let queue = this.queue;
		let widgetTimingService = this.widgetTimingService;
		let checkQueue = this.checkQueue;
		// tslint:disable-next-line: only-arrow-functions
		return function(): ng.IPromise<any> {
			let call = createQueueItem(requestFunction, arguments, priority, widget?.dashboardId);
			queue.push(call);
			if (widget)
				widgetTimingService.queuedDataLoading(widget);
			checkQueue();
			return call.deferred.promise;
		};
	}

	createQueueItem = (func: any, args: any, priority: number, priorityGroup: number) => {
		let deferred = this.$q.defer();
		return {
			func,
			args,
			priority,
			deferred,
			priorityGroup
		};
	}

	checkQueue = () => {
		let requestsLimit = this.getRequestsLimit();
		if (this.currentCount >= requestsLimit && requestsLimit > 0 || this.queue.length === 0)
			return;
		let call = this.getNextCall();
		call.func.apply(null, call.args).then(this.finishCall(call, true), this.finishCall(call, false));
		this.queue.remove(call);
		this.currentCount++;
	}

	private getNextCall = (): any => {
		let sortedCalls = _.sortBy(this.queue, 'priority');
		if (this.highPriorityGroup === undefined) {
			return sortedCalls[0];
		}
		return _.filter(sortedCalls, (call: any) => call.priorityGroup === this.highPriorityGroup)[0] || sortedCalls[0];
	}

	finishCall = (call, success) => {
		return (response) => {
			this.currentCount--;
			if (success)
				call.deferred.resolve(response);
			else call.deferred.reject(response);
			this.checkQueue();
		};
	}
}

app.service('contentProviderLimiter', ContentProviderLimiter);
