import { EventEmitter } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';

export enum WidgetContentEventType {
	OPEN_ADVANCED_SETTINGS = 'openAdvancedSettings',
	DISABLE_CASES_VISUALIZATION = 'disableCasesVisualization',
	LOAD_FULL_VERBATIM = 'loadFullVerbatim',
	RESIZE_TABLE_COLUMNS = 'resizeTableColumns',
}

export interface WidgetContentEvent {
	type: WidgetContentEventType;
	args?: any[];
}

export interface VisualizationComponent<T> {
	widget: Widget;
	dataObject?: T;
	utils: WidgetUtils;
	demo?: boolean;

	handleClick?: EventEmitter<{event: MouseEvent, point: DrillPoint, isRightClick?: boolean}>;
	handleRightClick?: EventEmitter<{event: MouseEvent}>;

	contentEvent?: EventEmitter<WidgetContentEvent>;
}
