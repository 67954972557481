import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

export interface IToggleListOption<T> {
	id: T;
	text: string;
	enabled: boolean;
	inactive?: boolean;
	title?: string;
}

@Component({
	selector: 'toggle-list',
	template: `
<div *ngIf="headers" class="d-flex justify-between p-8">
    <b *ngFor="let header of headers">{{header}}</b>
</div>
<div *ngFor="let row of data; index">
    <div class="d-flex justify-between p-8 rows-delimiter-top">
        {{row.text | i18n}}
        <cb-toggle
            class="smaller _t-{{row.id}}-switch"
            [title]="row.title"
            [disabled]="row.inactive || false"
            [(ngModel)]="row.enabled"
            (ngModelChange)="toggle.emit(row)">
        </cb-toggle>
    </div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleListComponent<T> {
	@Input() headers: string[];
	@Input() data: IToggleListOption<T>[];
	@Output() toggle = new EventEmitter<IToggleListOption<T>>();
}
