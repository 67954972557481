import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import {
	DashboardFromSelectionService,
	IDashboardCreationContext
} from '@app/modules/document-explorer/dashboard-from-selection.service';
import { PreviewAnalyticFeedbackSelection } from './preview-analytic-feedback-selection.class';
import { ExplorerAnalyticFeedbackSelectionClass } from './explorer-analytic-feedback-selection.class';
import {
	WidgetDocViewPreferences
} from '@app/modules/document-explorer/preferences/widget-doc-view-preference.provider';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { PromiseUtils } from '@app/util/promise-utils';
import { DocExplorerFavorites } from '@app/modules/document-explorer/context-pane/doc-explorer-favorites.class';

interface IDocumentPreviewerWidgetScope {
	dashboardData: any;
	widget: DocumentExplorerWidget;
	filters: any;
	widgetFavorites: any;
	preferences: any;
	creatingNewDashboard: any;
	createDashboardFromCurrentSelection: any;
	filterFeedbackSelection: PreviewAnalyticFeedbackSelection;
	sharingFeedbackSelection: ExplorerAnalyticFeedbackSelectionClass;
	showSentiment: () => boolean;
}

export class DocumentPreviewerWidget implements ng.IController {
	constructor(
		private $scope: IDocumentPreviewerWidgetScope,
		private security: Security,
		private locale: ILocale,
		private ExplorerAnalyticFeedbackSelection: any,
		private dashboardFromSelection: DashboardFromSelectionService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly projectAccessService: ProjectAccessService,
	) {
		this.$scope.filters = {
			sortBy: this.$scope.widget && this.$scope.widget.properties.page.sortMetric.displayName
		};
		this.$scope.widget.properties.favoriteAttributes = this.$scope.widget.properties.favoriteAttributes || [];

		this.$scope.preferences = new WidgetDocViewPreferences(this.$scope.widget);
		this.$scope.widgetFavorites = new DocExplorerFavorites(this.$scope.preferences);

		this.$scope.sharingFeedbackSelection = new ExplorerAnalyticFeedbackSelection(
			this.security, this.locale, this.$scope.widget, this.$scope.preferences,
			this.reportAssetUtilsService, this.projectAccessService);
		this.$scope.sharingFeedbackSelection.enable();

		this.$scope.createDashboardFromCurrentSelection = () => {
			this.$scope.creatingNewDashboard = PromiseUtils.old(
				this.dashboardFromSelection.create({
					currentWidget: this.$scope.widget,
					currentDashboard: this.$scope.dashboardData.dashboard,
					preferences: this.$scope.preferences,
					feedbackSelection: this.$scope.sharingFeedbackSelection,
					fromPreviewWidget: true
				} as IDashboardCreationContext)
			);
		};

		this.$scope.showSentiment = () => {
			return this.$scope.widget.visualProperties?.sentimentHighlightingEnabled
				&& this.$scope.preferences.settings.showSentiment;
		};
	}

	$onInit = () => {
		// required for typescript validation
	}
}


app.controller('documentPreviewWidget', DocumentPreviewerWidget);
