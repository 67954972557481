import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy,
} from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';

@Component({
	selector: 'context-navigation',
	template: ` <nav class="pt-0 pb-8 d-flex align-items-center justify-between">
		<div class="d-flex flex-fill align-items-center">
			<div class="p-relative">
				<input
				disabled="true"
				tabindex="0"
				class="form-control pr-32 h-32"
				aria-autocomplete="list"
				autocomplete="off"
				[value]="nodeName"
				/>
				<a
				(click)="closeClick.emit()"
				(keydown.enter)="closeClick.emit()"
				tabindex="0"
				id="clear-input-btn"
				class="clear-btn btn btn-icon no-border btn-secondary cursor-pointer text-default bg-none d-flex align-items-center"
				>
					<span class="q-icon q-icon-delete v-middle" [attr.aria-label]="'common.clear'|i18n"></span>
				</a>
			</div>
		</div>

		<div class="controls ml-16 text-right flex-nowrap">
			<!-- Should we include indexing?  Seems like a good feature -->
			<!-- <span class="topic-index">{{getIndexText()}}</span> -->
			<button
			(click)="arrowClick.emit(goPrevious)"
			class="cursor-pointer q-icon-triangle-up p-0 no-border kb-focusable btn btn-default text-0_875rem h-32"
			attr.aria-label="{{ 'common.previous' | i18n }}"
			></button>
			<button
			(click)="arrowClick.emit(goNext)"
			class="cursor-pointer q-icon-triangle-down mh-8 p-0 no-border kb-focusable btn btn-default text-0_875rem h-32"
			attr.aria-label="{{ 'common.next' | i18n }}"
			></button>
		</div>
	</nav>`,
	styles: [
		`
			.labeled-clearable-input {
				padding-right: 75px !important;
			}

			.labeled-clear-btn {
				width: 75px !important;
			}

			a {
				position: absolute;
				right: 0;
				top: 0;
			}
		`,
	],
})
export class ContextNavigationComponent implements OnInit, OnDestroy {
	@Input() total: number;
	@Input() currentIndex: number = 0;
	@Input() nodeName: string;
	@Output() closeClick = new EventEmitter<void>();
	@Output() arrowClick = new EventEmitter<number>();
	goNext = NavigationDirection.NEXT;
	goPrevious = NavigationDirection.PREVIOUS;

	constructor(private locale: CxLocaleService) {}

	ngOnInit(): void {}

	getIndexText(): string {
		return this.locale.getString('common.countOfTotal', {
			count: this.currentIndex,
			total: this.total,
		});
	}

	// make sure highlighting is cleared
	ngOnDestroy() {
		this.closeClick.emit();
	}
}
