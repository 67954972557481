import { NgModule } from '@angular/core';
import { HomePageWidgetsPanelComponent } from './home-page-widgets-panel/home-page-widgets-panel.component';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { SharedModule } from '@app/shared/shared.module';
import { HomePageCommonModule } from '@app/modules/home-page/home-page-common/home-page-common.module';

@NgModule({
	imports: [
		SharedModule,
		WidgetContainerModule,
		HomePageCommonModule,
	],
	declarations: [
		HomePageWidgetsPanelComponent,
	],
	exports: [
		HomePageWidgetsPanelComponent,
	],
})
export class HomePageWidgetsModule { }
