export class HTMLStringUtils {

	/**
	 * Takes a single line HTML block as input, and breaks it into multiple lines by tag
	 */
	static breakHtmlTags(html: string): string {
		html = HTMLStringUtils.breakOpeningTags(html);
		html = HTMLStringUtils.breakClosingTags(html);
		html = HTMLStringUtils.supportSingleLineElements(html);
		return HTMLStringUtils.removeConsecutiveLineBreaks(html);
	}

	/**
	 * Add line breaks after opening tags
	 */
	static breakOpeningTags(html: string): string {
		// can't add line breaks before/after non-block elements as they may change rendering
		// code is an exception
		return html.replace(/(<(p(?=[\s\t>])|div|h[1-6]|ul|ol|li|table|section|blockquote)[^\>]*>)/g, '$1\n');
	}

	/**
	 * Add line breaks before and after closing tags
	 */
	static breakClosingTags(html: string): string {
		// pre is a special case because it can only have line break _after_ tag
		html = html.replaceAll('</pre>', '</pre>\n');
		return html.replace(/(<\/(p|div|h[1-6]|ul|ol|li|table|section|blockquote)>)/g, '\n$1\n');
	}


	/**
	 * Allow elements that do not have children to remain on single line
	 */
	static supportSingleLineElements(html: string): string {
		return html.replace(/<(p|div|h[1-6]|ul|ol|li|table|section|blockquote)(.*)>[\n\t]*([^<\n\t]*)[\n\t]*<\/\1>/g, '<$1$2>$3</$1>');
	}

	static removeConsecutiveLineBreaks(html: string): string {
		return html.replace(/[\n]{2,}/g, '\n');
	}


	/**
	 * Applies approximate tab indentation on multi-line nested HTML
	 */
	static indentHtmlTags(html: string): string {
		let htmlArray = html.trim().split('\n');

		let openTags: string[] = [];

		htmlArray = htmlArray.map(line => {
			if (!this.isLineSelfClosing(line)) {
				if (this.closesLastTag(line, openTags.last())) {
					openTags.pop();
				}
			}

			let tabs = '\t'.repeat(openTags.length);


			if (this.opensTag(line)) {
				openTags.push(this.getTag(line));
			}

			return `${tabs}${line}`;
		});

		return htmlArray.join('\n');
	}

	/**
	 * Returns true if string closes the last tag that was open
	 */
	private static closesLastTag(tag: string, lastTag: string): boolean {
		return (`</${lastTag}>` === tag);
	}

	/**
	 * Returns true if string is a new opening tag
	 */
	private static opensTag(line: string): boolean {
		return /^<[^\/]+>$/.test(line);
	}

	/**
	 * Returns the element name for easy comparison
	 */
	private static getTag(line: string): string {
		return line.split(' ')[0].slice(1).replace(/[^A-Za-z0-9\-]/, '');
	}


	/**
	 * Returns true if the line of HTML closes itself, i.e. \<p\>Hello!\</p\>
	 */
	private static isLineSelfClosing(htmlLine: string): boolean {
		return /^<([^\s]*)[^>]*>[^\<]*<\/\1>/.test(htmlLine);
	}

	/**
	 * Breaks a single line of HTML into multiple lines, with approximate tab indentation for readability
	 */
	static formatHtml(html: string): string {
		return HTMLStringUtils.indentHtmlTags(HTMLStringUtils.breakHtmlTags(html));
	}

	/**
	 * Converts a string to an HTML collection
	 * Note: need to iterate over the result to append to DOM
	 */
	static stringToDOM(text: string): HTMLCollection {
		return new DOMParser().parseFromString(text, 'text/html').body.children;
	}
}
