<div class="d-flex flex-direction-column">
	<div class="">
		<h3 class="props-widget-name" [i18n]="'widgetSettings.buttonLink'"></h3>
	</div>
	<div class="flex-fill d-flex flex-direction-row">
		<div class="w-33-percent pv-16">
			<div class="form-group">
				<label for="button-text">{{'widgetSettings.buttonText'|i18n}}</label>

				<input id="button-text"
					type="text"
					class="w-100-percent"
					placeholder=""
					maxlength="30"
					required
					[(ngModel)]="widget.visualProperties.text"
					[debounce]="500"
					(debouncedChange)="redraw()">
			</div>
			<div class="form-group">
				<label for="link-url">{{'widgetSettings.linkUrl'|i18n}}</label>

				<input id="link-url"
					type="text"
					class="w-100-percent"
					placeholder=""
					maxlength="1000"
					placeholder="http://"
					pattern="https?://.+"
					[(ngModel)]="widget.visualProperties.linkUrl"
					[debounce]="500"
					(debouncedChange)="redraw()">
			</div>
			<div class="form-group">
				<label for="button-color">{{'widgetSettings.buttonColor'|i18n}}</label>

				<color-input id="button-color"
					[(ngModel)]="widget.visualProperties.buttonColor"
					(ngModelChange)="redraw()">
				</color-input>
			</div>
			<div class="form-group">
				<label for="button-position">{{'widgetSettings.buttonPosition'|i18n}}</label>

				<cb-push-buttons id="button-position"
					[list]="positionOptions"
					[(selectedValue)]="widget.visualProperties.position"
					(selectedValueChange)="redraw()"
				></cb-push-buttons>
			</div>
			<div class="form-group">
				<label for="full-width">{{'widgetSettings.fullWidth'|i18n}}</label>
				<cb-toggle id="full-width"
					[attr.aria-label]="'common.toggleObject' | i18n:{objectName: ('widgetSettings.fullWidth' | i18n)}"
					[(ngModel)]="widget.visualProperties.fullWidth"
					(ngModelChange)="redraw()"
				></cb-toggle>
			</div>
			<div class="form-group">
				<div class="d-flex align-center">
					<label for="instructional-text-toggle" class="m-0">{{'widgetSettings.instructionalTextToggle'|i18n}}</label>
					<cb-toggle if="instructional-text-toggle"
						[attr.aria-label]="'common.toggleObject' | i18n:{objectName: ('widgetSettings.instructionalText' | i18n)}"
						[(ngModel)]="widget.visualProperties.instructionalTextEnabled"
						(ngModelChange)="redraw()"
					></cb-toggle>
				</div>
				<div *ngIf="widget.visualProperties.instructionalTextEnabled"
					class="mt-16">
					<label for="instructional-text">{{'widgetSettings.instructionalText'|i18n}}</label>
					<textarea id="instructional-text"
						class="w-100-percent"
						rows="4"
						[(ngModel)]="widget.visualProperties.instructionalText"
						[debounce]="500"
						(debouncedChange)="redraw()"
						maxlength="150">
					</textarea>
					<characters-left-counter
						[maxLength]="150"
						[textLength]="widget.visualProperties.instructionalText?.length || 0"
					></characters-left-counter>

				</div>
				<div *ngIf="widget.visualProperties.instructionalTextEnabled">
					<label for="text-placement">{{'widgetSettings.textPlacement'|i18n}}</label>

					<cb-push-buttons id="text-placement"
						[list]="textPlacementOptions"
						[(selectedValue)]="widget.visualProperties.instructionalTextPosition"
						(selectedValueChange)="redraw()"
					></cb-push-buttons>
				</div>
			</div>
			<div class="form-group">
				<label for="background-type">{{'common.backgroundColor'|i18n}}</label>

				<background-color id="background-type"
					[(ngModel)]="widget.visualProperties.backgroundColor"
					(ngModelChange)="redraw()">
				</background-color>
			</div>
		</div>
		<div class="flex-fill m-24 bg-gray-100 p-32">
			<widget-content *ngIf="!!utils"
				class="h-100-percent w-100-percent widget-main-content bg-dashboard"
				[view]="widget.visualProperties.visualization"
				[trigger]="trigger"
				[widget]="widget"
				[data]="{}"
				[utils]="utils">
			</widget-content>
		</div>
	</div>
</div>
