import { Injectable } from '@angular/core';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import {
	DrillHelperService,
	IDrillTargetType
} from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class RecolorDrillService {
	constructor(
		private readonly drillHelperService: DrillHelperService
	) {}

	getRecolorOption(params: IDrillParams, point: DrillPoint): IDrillTargetType {
		if (params.widget.properties.widgetType === WidgetType.HEATMAP && point.drillAction) {
			return;
		}

		const drillToHelper = this.drillHelperService.create(params);

		return drillToHelper.getDrillToRecolor();
	}
}

app.service('recolorDrill', downgradeInjectable(RecolorDrillService));
