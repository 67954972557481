import { SharingStatus } from '@cxstudio/common/sharing-status';
import { AlertTrigger } from './alert-trigger';
import { AlertNotification } from './alert-notification';
import { AlertCaseSettings } from './alert-case-settings';

export const MAX_ACTIVE_ALERTS = 10;

export class StudioAlert {
	id?: number;
	name: string;
	contentProviderId: number;
	accountId: number;
	projectId: number;
	projectName?: string;
	ownerId: number;
	ownerName: string;
	selectedOwner?: any;
	startDate: Date; // comes as string from backend
	endDate?: Date;
	timezoneOffset: string;
	clientTimezoneName?: string;
	cronExpression?: string;

	enabled: boolean;

	status?: SharingStatus;
	modifiedDate?: Date;
	mostRecentAlert?: Date;
	alertsCount?: number;

	trigger?: AlertTrigger;
	notification?: AlertNotification;
	caseSettings: AlertCaseSettings;
	notificationsCount?: number;
	mostRecentNotificationDate?: Date;
	unsubscribedCount?: number;
}
