interface TextReference {
	index: number;
	text: string;
}

export class PreviewExportReferenceContainer {

	private firstOccurrence: {[id: number]: TextReference} = {};

	constructor(private textField: string, private idField: string, private relative: boolean) {}

	getTextOrReference(row: any, index: number): string {
		const text = row[this.textField];
		const id = row[this.idField];
		if (id === undefined)
			return text;
		if (this.firstOccurrence[id] !== undefined) {
			if (this.relative) {
				const relativeRowIndex = this.firstOccurrence[id].index - index;
				return `$RELATIVE:${relativeRowIndex}`;
			} else {
				return this.firstOccurrence[id].text;
			}
		} else {
			this.firstOccurrence[id] = {index, text};
			return text;
		}
	}
}
