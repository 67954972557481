import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HomePageReportSettingsProperties } from '@app/modules/home-page/home-page-common/entities/home-page-report-properties';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
	selector: 'home-reporting-settings',
	templateUrl: './home-reporting-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeReportingSettingsComponent implements OnInit {
	@Input() reportProperties: HomePageReportSettingsProperties;
	@Output() reportPropertiesChange = new EventEmitter<HomePageReportSettingsProperties>();
	@ViewChild('propertiesForm', {static: false}) propertiesForm: NgForm;

	properties: any;
	hierarchies: any[];
	hierarchyEnabled: boolean;

	contentProviderErrors: string[];

	projectsLoading: Promise<any>;
	hierarchiesLoading: Promise<any>;

	searchingOwnerCandidates: boolean;

	isWorkspaceEnabled: boolean;
	originalProjectSelection: IProjectSelection;
	originalWorkspaceProject: WorkspaceProject;
	projectChanged: boolean;

	constructor(
		private homePageApi: HomePageApiService,
		private organizationApiService: OrganizationApiService,
		private readonly betaFeaturesService: BetaFeaturesService
	) { }

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.properties = {
			cbContentProvider: this.reportProperties.contentProviderId,
			cbAccount: this.reportProperties.accountId,
			project: this.reportProperties.projectId
		};
		this.hierarchyEnabled = this.reportProperties.hierarchyId !== undefined;

		this.originalProjectSelection = this.getProjectSelection();
		this.originalWorkspaceProject = {...this.reportProperties.workspaceProject};

		this.reloadHierarchies();
	}

	onProjectSelectionChange(projectSelection: IProjectPreselection): void {
		this.properties.cbContentProvider = projectSelection.cbContentProvider;
		this.properties.cbAccount = projectSelection.cbAccount;
		this.properties.project = projectSelection.project;

		this.reportProperties = _.extend({}, this.reportProperties, {
			contentProviderId: this.properties.cbContentProvider,
			accountId: this.properties.cbAccount,
			projectId: this.properties.project,
		});

		this.projectChanged = this.isProjectSelected() && !_.isEqual(this.originalProjectSelection, this.getProjectSelection());
		this.reportPropertiesChange.emit(this.reportProperties);
		this.reloadHierarchies();
	}

	private getProjectSelection(): IProjectSelection {
		const fields = ['contentProviderId', 'accountId', 'projectId'];
		return _.pick(this.reportProperties, fields) as IProjectSelection;
	}

	onCpErrorsChange(errors: string[]) {
		this.contentProviderErrors = errors;
	}

	onProjectsLoading(loadingPromise: Promise<any>) {
		this.projectsLoading = loadingPromise;
	}

	onProjectChange = (): void => {
		this.projectChanged = this.isProjectSelected()
			&& !_.isEqual(this.originalWorkspaceProject, this.reportProperties.workspaceProject);
		this.reportPropertiesChange.emit(this.reportProperties);
		this.reloadHierarchies();
	}

	selectHierarchy(hierarchy): void {
		this.reportProperties = _.extend({}, this.reportProperties, {hierarchyId: hierarchy.id});
		this.reportPropertiesChange.emit(this.reportProperties);
	}

	setOrganizationContext(showOrganizationContext: boolean): void {
		this.reportProperties = _.extend({}, this.reportProperties, {showOrganizationContext});
		this.reportPropertiesChange.emit(this.reportProperties);
	}

	changeOwner(owner: string): void {
		if (!owner)
			return;
		this.reportProperties = _.extend({}, this.reportProperties, {owner});
		this.reportPropertiesChange.emit(this.reportProperties);
	}

	ownerCandidates = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(term => {
				this.searchingOwnerCandidates = true;
				return this.homePageApi.getOwnerCandidates(term, this.reportProperties).then(candidates => {
					this.searchingOwnerCandidates = false;
					return candidates;
				});
			})
		);
	}

	isProjectSelected(): boolean {
		if (this.isWorkspaceEnabled) {
			return WorkspaceProjectUtils.isProjectSelected(this.reportProperties.workspaceProject);
		} else {
			return ProjectIdentifier.isProjectSelected(this.reportProperties);
		}
	}

	clearText(): void {
		setTimeout(() => {
			if (!this.reportProperties.owner) {
				this.propertiesForm.form.controls.owner.setValue('');
			}
		}, 100);
	}

	private reloadHierarchies(): void {
		if (!this.isProjectSelected()) {
			return;
		}
		this.hierarchiesLoading = Promise.resolve(this.organizationApiService.getOrganizationList()).then(
			(result) => {
				this.hierarchies = result.data;
			});
	}

	onHierarchyEnabled(): void {
		if (!this.hierarchyEnabled) {
			delete this.reportProperties.hierarchyId;
			delete this.reportProperties.showOrganizationContext;
			this.reportProperties = _.extend({}, this.reportProperties);
			this.reportPropertiesChange.emit(this.reportProperties);
		}
	}
}
