import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, Validators } from '@angular/forms';

// It works together with [strictMin] and invokes on inpit changes
// It doesn't check the value until it changes
@Directive({
	selector: '[valueRequired]',
	providers: [{provide: NG_VALIDATORS, useExisting: ValueRequiredDirective, multi: true}]
})
export class ValueRequiredDirective implements Validator {
	@Input() valueRequired: boolean;
	@Input() strictMin: number;

	private onChange: () => void;

	validate(control: AbstractControl): ValidationErrors | null {
		// do nothing on init
		if (!control.dirty) {
			return;
		}

		let requiredErrors = Validators.required(control);
		if (requiredErrors !== null) {
			control.setValue(this.strictMin);
		}

		// never highlight with red
		return;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onChange = fn;
	}
}
