export class CommonInherentProperties {
	static projectSettings = [
		'accountId', 'accountName', 'project', 'projectName',
		'contentProviderId', 'contentProviderName',
		'runAs', 'workspaceProject',
		//widget settings
		'widgetType', 'isCustomTitle', 'timezoneOffset'
	];

	static filterProperties = [
		//filters
		'adhocFilter', 'appliedFilters',
		//additional filters
		'drillFilters',

		'ignoredDashboardFilters', 'ignoreDashboardTextFilter'
	];

	static analyticDateFilterProperties = [
		'dateRangeP1', 'dateRangeP2'
	];

	static analyticSelectionProperties = [
		'selectedAttributes', 'selectedMetrics'
	];

	static homePageProperties = ['homePageId'];

	static widgetDescriptionProperties = ['autoDescription'];

	static common = []
		.concat(CommonInherentProperties.projectSettings)
		.concat(CommonInherentProperties.filterProperties)
		.concat(CommonInherentProperties.analyticDateFilterProperties)
		.concat(CommonInherentProperties.analyticSelectionProperties)
		.concat(CommonInherentProperties.homePageProperties)
		.concat(CommonInherentProperties.widgetDescriptionProperties);

	static preview = []
		.concat(CommonInherentProperties.projectSettings)
		.concat(CommonInherentProperties.filterProperties)
		.concat(CommonInherentProperties.widgetDescriptionProperties);

	static visual = [
		'visualization', 'color', 'sortBy', 'direction', 'size', 'customColor', 'showSampleSize'
	];

	static highchartsVisual = [
		'showLabels', 'showLegend', 'referenceLines'
	].concat(CommonInherentProperties.visual);

	static dualVisual = [
		'pointColor', 'points', 'popColor', 'alignTicks', 'secondaryAxisAutoMin', 'secondaryAxisAutoMax',
		'secondaryChartType', 'secondaryColor', 'secondaryPointColor', 'secondaryPoints',
		'secondaryPopColor', 'secondarySize', 'secondaryYAxis',
		'yAxis', 'yAxisAutoMax', 'yAxisAutoMin', 'secondaryCustomColor', 'timeReferenceLines'
	].concat(CommonInherentProperties.highchartsVisual);

	static attributeProperties = [
		'nullInclude', 'size', 'sortBy', 'sortOrder', 'levels'
	];

	static templateGroupingProperties = [
		'size', 'nullInclude', 'sortOrder', 'minDocCount', 'capitalization'
	];

	static altTextProperties = [
		'altTextFromTitle', 'altText'
	];
}


