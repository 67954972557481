import Widget from '@cxstudio/dashboards/widgets/widget';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';


export interface IBasicDrillProcessor {
	drillTo(widget: Widget, drillToType: string, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget;
}

export interface IRedirectDrillProcessor {
	drillTo(widget: Widget, drillToType: string, point: DrillPoint, defaultColor?: IColorSelectorPalette): void;
}

export abstract class DrillProcessor implements IBasicDrillProcessor {
	abstract drillTo(widget: Widget, drillToType: string, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget;
	abstract getInheritedProperties(additionalProperties?: string[]): string[];
	abstract getInheritedVisualProperties(additionalProperties?: string[]): string[];

	protected inheritPropertiesAndVisProps<T extends Widget>(widget: T): T {
		return this.inheritVisualProperties(this.inheritProperties(widget));
	}

	protected inheritProperties<T extends Widget>(widget: T): T {
		widget.properties = _.pick(widget.properties, this.getInheritedProperties());
		return widget;
	}

	protected inheritVisualProperties<T extends Widget>(widget: T): T {
		widget.visualProperties = _.pick(widget.visualProperties, this.getInheritedVisualProperties());
		return widget;
	}
}
