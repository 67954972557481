import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AmplitudeAnalyticsService } from '../analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '../analytics/amplitude/amplitude-event';
import { AmplitudeGroupsUtils } from '../analytics/amplitude/amplitude-groups-utils';
import { AmplitudeWidgetEventData } from '../analytics/amplitude/amplitude-widget-event-data.class';
import { HomePageWidgetConstants } from '../home-page/home-page-common/home-page-widget-constants';
import { EnvironmentService } from '@cxstudio/services/environment-service';

@Injectable({
	providedIn: 'root'
})
export class WidgetVisibilityMonitorService {
	readonly WIDGET_VISIBILITY_THRESHOLD = .75;
	readonly WIDGET_VISIBILITY_DURATION_MS = 3000;

	scrollObserver: IntersectionObserver;
	loggingTimer: {[key: string]: any};
	widgetCallback: {[key: string]: void | any};

	constructor(
		@Inject('environmentService') private readonly environmentService: EnvironmentService
	) {}

	private init(): void {
		this.widgetCallback = {};
		this.loggingTimer = {};

		this.scrollObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			entries.forEach(this.verifyWidgetVisibility);
		}, {
			threshold: this.WIDGET_VISIBILITY_THRESHOLD
		});
	}

	private verifyWidgetVisibility = (widgetIntersection: IntersectionObserverEntry): void => {
		if (widgetIntersection.intersectionRatio < this.WIDGET_VISIBILITY_THRESHOLD) {
			if (this.loggingTimer[widgetIntersection.target.id]) {
				clearTimeout(this.loggingTimer[widgetIntersection.target.id]);
				delete this.loggingTimer[widgetIntersection.target.id];
			}
		} else {
			this.loggingTimer[widgetIntersection.target.id] = setTimeout(() => {
				this.widgetCallback[widgetIntersection.target.id](widgetIntersection.target);
			}, this.WIDGET_VISIBILITY_DURATION_MS);
		}
	}

	private destroy(): void {
		if (this.scrollObserver) {
			this.scrollObserver.disconnect();
			delete this.scrollObserver;
		}
		this.removeAllTimeouts();
	}

	/**
	 * Remove all timeouts so they don't fire after disconnect has occurred
	 */
	private removeAllTimeouts(): void {
		if (this.loggingTimer) {
			for (let key in this.loggingTimer) {
				if (this.loggingTimer.hasOwnProperty(key)) {
					clearTimeout(this.loggingTimer[key]);
					delete this.loggingTimer[key];
				}
			}
		}
	}

	reset(): void {
		this.destroy();
		this.init();
	}

	private getWidgetElementId = (widget: Widget): string => {
		return `widget-${widget.id}`;
	}

	getWidgetSelector = (widget: Widget): string => {
		const widgetIdStr = this.getWidgetElementId(widget);
		const widgetSelector = `#${widgetIdStr}`;
		const containerSelector = widget.containerId && !HomePageWidgetConstants.isHomePageWidget(widget) ?
			`#container-${widget.containerId}` :
			'';

		return `${containerSelector} ${widgetSelector}`;
	}

	/**
	 * Add one or more widgets to be monitored for entry into viewport
	 */
	observeWidgets(widgets: Widget | Widget[]): void {
		// we can't currently observe embedded as we won't know if it's in viewport
		if (this.environmentService.isIframe() || !this.scrollObserver) return;

		[].concat(widgets).forEach(w => {
			const widgetSelector = this.getWidgetSelector(w);
			const widgetIdStr = this.getWidgetElementId(w);

			this.scrollObserver.observe(document.querySelector(widgetSelector));
			this.widgetCallback[widgetIdStr] = (widgetElement: HTMLElement) => {
				AmplitudeAnalyticsService.trackEvent(
					AmplitudeEvent.DASHBOARD_WIDGET_VIEW,
					AmplitudeGroupsUtils.dashboardGroup(w.dashboardId),
					new AmplitudeWidgetEventData(w, widgetElement)
				);
			};
		});
	}

	disconnect(): void {
		this.destroy();
	}
}

app.service('widgetVisibilityMonitor', downgradeInjectable(WidgetVisibilityMonitorService));
