import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpParams } from '@angular/common/http';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { PaginationHttpParams } from '@app/shared/components/pagination/pagination-http-params';
import { ScheduledJob } from '@app/modules/system-administration/status/schedules-status/entities/scheduled-job';
import { JobIdentifier } from '@app/modules/system-administration/status/schedules-status/entities/job-identifier';
import { JobRun } from '@app/modules/system-administration/status/schedules-status/entities/job-run';
import { OptionalJob } from '@app/modules/system-administration/status/schedules-status/entities/optional-job';

export interface JobRequestParams {
	group?: string;
	pagination: Pagination;
}

@Injectable({
	providedIn: 'root'
})
export class JobsApi {
	constructor(
		private cxHttp: CxHttpService
	) {}

	getScheduledJobs = (params: JobRequestParams): Promise<ScheduledJob[]> => {
		return this.cxHttp.get('rest/jobs/scheduled', { params: this.getHttpParams(params) });
	}

	getScheduledJobGroups = (): Promise<string[]> => {
		return this.cxHttp.get('rest/jobs/scheduled/groups');
	}

	runScheduledJobNow = (job: ScheduledJob): Promise<void> => {
		let path = 'rest/jobs/scheduled'
			+ '/group/' + job.triggerGroup
			+ '/key/' + job.triggerName
			+ '/run';
		return this.cxHttp.post(path);
	}

	getCompletedJobs = (params: JobRequestParams): Promise<JobRun[]> => {
		return this.cxHttp.get('rest/jobs/history', { params: this.getHttpParams(params) });
	}

	getCompletedJobGroups = (): Promise<string[]> => {
		return this.cxHttp.get('rest/jobs/history/groups');
	}

	getOptionalJobs = (): Promise<OptionalJob[]> => {
		return this.cxHttp.get('rest/jobs/optional');
	}

	scheduleOptionalJob = (identity: JobIdentifier): Promise<void> => {
		return this.cxHttp.post('rest/jobs/optional/schedule', identity);
	}

	runOptionalJobNow = (identity: JobIdentifier): Promise<void> => {
		return this.cxHttp.post('rest/jobs/optional/run', identity);
	}

	unscheduleOptionalJob = (identity: JobIdentifier): Promise<void> => {
		let path = 'rest/jobs/optional/unschedule'
			+ '/group/' + identity.group
			+ '/key/' + identity.key;

		return this.cxHttp.delete(path);
	}

	private getHttpParams = (params: JobRequestParams): HttpParams => {
		let httpParams: HttpParams = PaginationHttpParams.of(params.pagination);
		httpParams = httpParams.append('withTotal', 'true');
		if (params.group) {
			httpParams = httpParams.append('group', params.group);
		}
		return httpParams;
	}
}
