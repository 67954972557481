import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ReportScopeUtils } from '@cxstudio/reports/utils/report-scope-utils.service';
import { HighchartsTreeReportDataBuilder } from '@cxstudio/reports/visualizations/definitions/highcharts/report-data-builders/highcharts-tree-report-data-builder.service';
import ReportDefinition, { IDataPoint, ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';
import { ReportDefinitionInitializers } from '@cxstudio/reports/utils/report-definition-initializers.service';
import { ExtendedHierarchyNode, HierarchyTreeDrillPoint, HierarchyTreeRenderer } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';
import { HierarchyTreeOptions } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/hierarchy-tree-options.class';
import { TreeRendererUtils } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer-utils';
import { BaseTreeRenderer } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/base-tree-renderer';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';

interface ScrollingJQueryElement extends ng.IAugmentedJQuery {
	kinetic(options?: any): ScrollingJQueryElement;
}

app.directive('d3HierarchyTree', (
	reportUtils: ReportUtils,
	tableService,
	pointSelectionUtils: PointSelectionUtils,
	reportDefinitionInitializers: ReportDefinitionInitializers,
	highchartsTreeReportDataBuilder: HighchartsTreeReportDataBuilder,
	reportScopeUtils: ReportScopeUtils,
	environmentService: EnvironmentService,
	$timeout: ng.ITimeoutService,
	chartAccessibilityService: ChartAccessibilityService,
	applicationThemeService: ApplicationThemeService
) => {
	return {
		restrict: 'A',
		replace: true,
		template: '<div><div class="tree-container overflow-y-auto overflow-x-auto w-100-percent h-100-percent text-center"></div></div>',
		scope: {
			options: '<',
			dataObject: '<data',
			demo: '<',
			view: '<',
			trigger: '<',
			utils: '<',
			handleClick: '<',
			handleRightClick: '<'
		},
		link: ($scope: ReportDefinitionScope, element: ng.IAugmentedJQuery) => {
			$scope.selectedPoint = null;
			reportUtils.initDestroyListener($scope);
			let treeContainer = element.find('.tree-container') as ScrollingJQueryElement;
			($ as any).Kinetic.prototype._setMoveClasses = () => {}; // don't modify classes while moving, as it affects performance
			treeContainer.kinetic();
			let chart: HierarchyTreeRenderer;
			if (!$scope.demo && !tableService.processIfNoData(element, $scope.dataObject, undefined, $scope.utils.widgetType, $scope.utils)) {
				return;
			}

			if (!$scope.demo) {
				reportScopeUtils.emitContextMenuHandling($scope, element);
			}

			if (!environmentService.isPdfExport()) {
				reportUtils.initResizeHandler($scope, element,
					reportUtils.handlerWithDelay(() => chart && chart.render(getOptions())));
			}
			$scope.$watch('trigger', reportUtils.chartTriggerHandler($scope, renderChart));

			renderChart();

			function renderChart(): void {
				const MIN_RADIUS = 2; // 4px diameter
				const MAX_RADIUS = BaseTreeRenderer.MAX_BUBBLE_DIAMETER / 2;
				reportDefinitionInitializers.initializeNormalizedSize($scope, MIN_RADIUS, MAX_RADIUS);
				let processedData = highchartsTreeReportDataBuilder.getAnalyticTreeData($scope as ReportDefinition);
				_.each(processedData, point => point.parent = point.parent || '__root__');
				// hierarchy requires single root
				processedData.push({
					id: '__root__',
					displayName: $scope.utils.selectedAttributes[0]?.displayName || 'Root',
				} as IDataPoint);
				let root = d3.stratify<IDataPoint>().id(d => d.id).parentId(d => d.parent)(processedData) as ExtendedHierarchyNode;

				root.descendants().forEach((d, index) => {
					d._children = d.children;
					d.size = $scope.utils.sizeFunction(d.data.object);
					d.color = $scope.utils.colorFunction(d.data.object, index) as string;
				});
				chart = new HierarchyTreeRenderer(
					treeContainer,
					root,
					chartAccessibilityService.isPatternFillEnabled(),
					applicationThemeService.isShowingDashboardDarkTheme());
				chart.render(getOptions());

				let selectedPoint = pointSelectionUtils.getSelectedPoint($scope.utils.containerId, $scope.utils.widgetId);
				if (selectedPoint && !$scope.demo) {
					pointSelectionUtils.enablePointSelection($scope.utils.containerId, $scope.utils.widgetId, true);
					let selectedNode = root.find(node => node.id === selectedPoint);
					if (selectedNode)
						chart.scrollTo(selectedNode);
					pointSelectionUtils.enableWrongSelectionWarning($scope.utils.containerId, $scope.utils.widgetId, !selectedNode);
				} else {
					chart.scrollToRoot();
				}

				if (environmentService.isPdfExport()) {
					// chart is blank in PDF if it's scroll area reaches the bottom of PDF.
					// this matches viewport height to widget height
					$timeout(() => {
						chart.restrictHeight();
						reportUtils.handleWidgetRenderedEvent($scope.utils.widgetId, $scope.utils.widgetType, $scope.utils.containerId);
					}, 1000);
				} else {
					reportUtils.handleWidgetRenderedEvent($scope.utils.widgetId, $scope.utils.widgetType, $scope.utils.containerId);
				}

			}

			function getOptions(): HierarchyTreeOptions {
				return {
					showLabels: $scope.options.showLabels,
					orientation: $scope.options.orientation,
					selectPoint,
					isSelected,
				};
			}

			function selectPoint(node: ExtendedHierarchyNode, svgElement: any): void {
				if (!node) {
					$scope.selectedPoint = null;
					return;
				}
				let point = node.data;
				let object = point.object;
				if (!object) {
					$scope.selectedPoint = {
						nonReporting: true
					};
					populateTreeActions(node, $scope.selectedPoint);
					return;
				}
				let selectedPoint: HierarchyTreeDrillPoint = {
					_selectionId: point.id,
					id: point.id,
					name: point.name,
					uniqueName: point._uniqName,
					displayName: point.displayName,
					color: object.color ? object.color : node.color,
					supportUseAsFilter: true,
				} as any;

				let elm = $(svgElement);
				if (!elm.hasClass('tree-node')) {
					elm = elm.parents('.tree-node');
				}
				selectedPoint._element = elm;
				populateTreeActions(node, selectedPoint);
				_.extend(selectedPoint, object);

				$scope.selectedPoint = selectedPoint;
			}

			function isSelected(node: ExtendedHierarchyNode): boolean {
				let point = pointSelectionUtils.getSelectedPoint($scope.utils.containerId, $scope.utils.widgetId);
				return point === node.id;
			}

			function populateTreeActions(node, point: HierarchyTreeDrillPoint): void {
				if (!TreeRendererUtils.isLeaf(node)) {
					if (TreeRendererUtils.isCollapsed(node)) {
						point.expand = () => {
							TreeRendererUtils.showChildren(node);
							chart.redrawNode(node);
						};
					} else {
						point.collapse = () => {
							TreeRendererUtils.hideChildren(node);
							chart.redrawNode(node);
						};
					}
				}
			}

			let destroyChart = $scope.$on('$destroy', () => {
				treeContainer.kinetic('detach');
				destroyChart();
			});

		}
	};
});
