import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import * as _ from 'underscore';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DatasetStats } from '@cxstudio/drivers/entities/dataset-stats';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { IDriversModelColumn, DriversDefinition } from '@cxstudio/drivers/entities/drivers-definition';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DatasetWarning, WarningSeverity, WarningType } from '@cxstudio/drivers/entities/dataset-warning';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { IDriversModelItem } from '@cxstudio/drivers/entities/drivers-model';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { Security } from '@cxstudio/auth/security-service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ModelsService } from '@app/modules/project/model/models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';


const DRIVERS_QUANTITY_THRESHOLD = 10;

export class DriversUtils {

	private constants;

	constructor(
		private modelsService: ModelsService,
		private driversApi: DriversApi,
		private $q: ng.IQService,
		private security: Security,
		private DateRange,
		private metricConstants: MetricConstants,
		private titleUtils
	) {
		this.constants = this.metricConstants.get();
	}

	getDatasetStats = (driver: DriversItem): ng.IPromise<DatasetStats> => {
		let statsPromise = PromiseUtils.old(this.driversApi.getDatasetStats(driver));
		let modelsPromise = this.loadModelTrees(driver);
		return this.$q.all([statsPromise, modelsPromise]).then(results => {
			return this.processStats(results[0], results[1], driver);
		});
	}

	//return true if stats has any warning with high severity
	driverHasDatasetErrors = (driver: DriversItem): ng.IPromise<boolean> => {
		return this.getDatasetStats(driver).then((datasetStats: DatasetStats) => {
			return !datasetStats.warnings.isEmpty() &&
				!!_.findWhere(datasetStats.warnings, {severity: WarningSeverity.HIGH});
		});
	}

	private processStats(stats: DatasetStats, modelTrees: ModelTree[], driver: DriversItem): DatasetStats {
		let fullStats = new DatasetStats();
		fullStats.volume = stats.volume;
		fullStats.targetPerc = stats.targetPerc;
		fullStats.targetVolume = Math.round(stats.volume * stats.targetPerc);
		fullStats.targetPercText = (Math.floor(stats.targetPerc * 10000) / 100) + '%'; // convert to % and keep 2 decimal
		fullStats.attributesAndModelsCount = this.getAttributesCount(driver) + this.getTopicsCount(modelTrees, driver);
		this.processWarnings(fullStats);
		return fullStats;
	}

	private processWarnings(fullStats: DatasetStats): void {
		let warnings = [];
		if (fullStats.volume <= 100) {
			this.addWarning(WarningType.VOLUME, WarningSeverity.HIGH, warnings);
		} else if (fullStats.volume <= 1000) {
			this.addWarning(WarningType.VOLUME, WarningSeverity.LOW, warnings);
		}
		if (fullStats.targetPerc === 1.0 || fullStats.targetVolume <= 10) {
			this.addWarning(WarningType.TARGET, WarningSeverity.HIGH, warnings);
		} else if (fullStats.targetPerc <= 0.01 || fullStats.targetPerc >= 0.9 || fullStats.targetVolume <= 20) {
			this.addWarning(WarningType.TARGET, WarningSeverity.LOW, warnings);
		}
		if (fullStats.attributesAndModelsCount <= 1) {
			this.addWarning(WarningType.ATTRIBUTES, WarningSeverity.HIGH, warnings);
		} else if (fullStats.attributesAndModelsCount < 10) {
			this.addWarning(WarningType.ATTRIBUTES, WarningSeverity.LOW, warnings);
		}
		fullStats.warnings = warnings;
	}
	private addWarning(type: WarningType, severity: WarningSeverity, warnings: DatasetWarning[]): void {
		warnings.push({
			type,
			severity
		});
	}

	private getAttributesCount(driver: DriversItem): number {
		return (driver.definition.attributes || []).length;
	}

	private getTopicsCount(modelTrees: ModelTree[], driver: DriversItem): number {
		let models = driver.definition.models;
		let allNodeIds = [];
		_.each(models, model => allNodeIds.pushAll(this.getTopicsForModel(model, modelTrees)));
		let targetAllNodeIds = this.getTargetTopics(driver);
		allNodeIds = _.difference(allNodeIds, targetAllNodeIds);
		return allNodeIds.length;
	}

	private getTopicsForModel(model: IDriversModelColumn, modelTrees: ModelTree[]): number[] {
		let dbModel = _.findWhere(modelTrees, {id: Number(model.name)});
		if (!dbModel)
			return [];
		let root = dbModel.root;
		if (model.nodeIds) {
			return model.nodeIds;
		} else { // no selection made, so consider all topics selected
			let selection = new SimpleTopicSelection(root, []);
			return selection.getAllNodeIds();
		}
	}
	private getTargetTopics(driver: DriversItem): number[] {
		let filters: AdhocFilter = driver.target.filters;
		return _.chain(filters.filterRules)
			.filter(filter => filter.type === FilterRuleType.topicEquality)
			.map(rule => rule.values)
			.flatten()
			.map(value => value.idPath)
			.map((path: string) => Number(path.substr(path.lastIndexOf('/') + 1)))
			.value();
	}
	private loadModelTrees(driver: DriversItem): ng.IPromise<ModelTree[]> {
		let props = driver as IProjectSelection;
		return this.$q.all(_.chain(driver.definition.models)
			.map(model => model.name)
			.map(modelId => PromiseUtils.old(this.modelsService.getModelTree(props, parseInt(modelId, 10))))
			.value());
	}

	processAxisRange = (driversModelItems: IDriversModelItem[], visualProperties: VisualProperties): void => {
		let modelItems = _.sortBy(driversModelItems, 'strength').reverse();
		if (_.filter(modelItems, (item) => item.strength >= 0).length < DRIVERS_QUANTITY_THRESHOLD) {
			visualProperties.yAxisMin = modelItems.length >= DRIVERS_QUANTITY_THRESHOLD
				? (Math.floor((modelItems[DRIVERS_QUANTITY_THRESHOLD - 1].strength - 0.1) * 10) / 10).toString() : '0';
		}
		visualProperties.yAxisAutoMax = modelItems.length > 0 && modelItems[0].strength < 0.7;
	}

	getDriverGrouping = (driver: DriversItem, withHidden: boolean = false): ReportGrouping => {
		const driverGrouping = _.pick(driver, 'id', 'name', 'type', 'displayName', 'hide') as unknown as ReportGrouping;
		if (driver.minVolume > 0) {
			driverGrouping.displayThreshold = {
				metricName: 'volume',
				notInBetween: false,
				criteria: {
					gte: driver.minVolume
				}
			};
		}
		driverGrouping.metricType = AnalyticMetricType.DRIVERS;
		if (withHidden)
			driverGrouping.withHidden = withHidden;
		return driverGrouping;
	}

	getReportSettings = (driversItem: DriversItem, withHidden?: boolean): Widget => {
		let reportSettings = {} as Widget;
		reportSettings.name = WidgetType.SCATTER;
		reportSettings.type = WidgetDisplayType.CB;
		reportSettings.properties = {} as WidgetProperties;
		reportSettings.properties.runAs = this.security.getEmail();
		reportSettings.properties.widgetType = WidgetType.SCATTER;
		reportSettings.properties.contentProviderId = driversItem.contentProviderId;
		reportSettings.properties.accountId = driversItem.accountId;
		reportSettings.properties.project = driversItem.projectId;
		let driverGrouping = this.getDriverGrouping(driversItem, withHidden);
		reportSettings.properties.selectedAttributes = [driverGrouping];
		reportSettings.properties.selectedMetrics = [this.constants.STRENGTH, this.constants.VOLUME];
		reportSettings.properties.dateRangeP1 = new DateFilter() ;
		reportSettings.properties.adhocFilter = driversItem.definition.additionalFilters;
		reportSettings.properties.appliedFilters = { type: FilterTypes.AND, filters: [] };
		reportSettings.displayName = this.titleUtils.generateScatterByDriversTitle(driversItem);
		reportSettings.properties.isCustomTitle = true;
		this.applyDriversFilters(reportSettings.properties, driversItem.definition);
		return reportSettings;
	}

	applyDriversFilters = (properties: WidgetProperties, definition: Partial<DriversDefinition>) => {
		let dateFilter = {
			dateFilterMode: this.DateRange.options.CUSTOM.value,
			from: definition.dateRange.fromDate,
			to: definition.dateRange.toDate
		};
		angular.extend(properties.dateRangeP1, dateFilter);

		// removing applied filters and override adhoc
		if (definition.additionalFilters && !_.isEmpty(definition.additionalFilters.filterRules)) {
			properties.adhocFilter = definition.additionalFilters;
		} else {
			angular.extend(properties.adhocFilter, { filterRules: [] });
		}
		angular.extend(properties.appliedFilters, {filters: []});
	}
}

app.service('driversUtils', DriversUtils);
