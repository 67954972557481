import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DocumentExplorerFilters } from '@app/modules/document-explorer/document-explorer-metric-filters/document-explorer-filters';
import { DocExplorerPreferencesProviderService } from '@app/modules/document-explorer/preferences/doc-explorer-preferences-provider.service';
import { DocExplorerPreferences } from '@app/modules/document-explorer/preferences/doc-explorer-preferences.provider';
import { ContextPaneState } from '@app/modules/document-explorer/preferences/doc-view-pane-settings';
import { SentencePredefinedFilter } from '@app/modules/document-explorer/sentence-predefined-filter';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { ReportRunPreparationService } from '@app/modules/reports/utils/report-run-preparation.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { DocumentExplorerPagination } from '@app/modules/widget-visualizations/report-pagination.service';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { DashboardFromSelectionService, IDashboardCreationContext } from '@app/modules/document-explorer/dashboard-from-selection.service';
import { DocExplorerHelperService } from '@cxstudio/reports/document-explorer/doc-explorer-helper.service';
import { DocExplorerQidsService } from '@cxstudio/reports/document-explorer/doc-explorer-qids.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ExplorerAnalyticFeedbackSelectionClass } from '@cxstudio/reports/preview/explorer-analytic-feedback-selection.class';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { AppliedFilters, AppliedFiltersFactory } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { FullPageDocumentExplorerService } from '../document-explorer-page/full-page-document-explorer.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { DocumentTypeUtils } from '../document-type-utils.class';
import { AmplitudeDocumentSource } from '../amplitude-document-source.enum';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';

@Component({
	selector: 'document-explorer-container',
	templateUrl: './document-explorer-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerContainerComponent implements OnInit, OnDestroy {

	@Input() header: TemplateRef<any>;
	@Input() widget: DocumentExplorerWidget;
	@Input() dashboard?: Dashboard;
	@Input() paging?: DocumentExplorerPagination;
	@Input() selectedScorecard?: number;
	@Input() isFullPage: boolean;

	filters: DocumentExplorerFilters;
	sortDirection: AnSortDirection;
	sentimentFilters: SentencePredefinedFilter;
	easeScoreFilters: SentencePredefinedFilter;
	preferences: DocExplorerPreferences;
	panels: {[panelName: string]: ExplorerSectionGroup};
	sharingFeedbackSelection: ExplorerAnalyticFeedbackSelectionClass;
	appliedFilters: AppliedFilters;
	auditMode: boolean;
	rebuttalMode: boolean;
	creatingNewDashboard: Promise<void>;
	documentManager: IDocumentPreviewerControls;
	requestInProgress: boolean;
	loadingStatistics: Promise<void>;
	searchValue: string = '';
	timeoutId: ReturnType<typeof setTimeout>;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly docExplorerPreferencesProvider: DocExplorerPreferencesProviderService,
		private readonly reportProcessingService: ReportProcessingService,
		private readonly fullPageDocExplorerService: FullPageDocumentExplorerService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly projectAccessService: ProjectAccessService,
		private readonly reportRunPreparationService: ReportRunPreparationService,
		private readonly dashboardFromSelection: DashboardFromSelectionService,
		@Inject('reportDataApiService') private readonly reportDataApiService: ReportDataApiService,
		@Inject('security') private readonly security: Security,
		@Inject('docExplorerQids') private readonly docExplorerQids: DocExplorerQidsService,
		@Inject('appliedFiltersFactory') private readonly appliedFiltersFactory: AppliedFiltersFactory,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('docExplorerHelperService') private readonly docExplorerHelperService: DocExplorerHelperService,
	) { }

	ngOnInit(): void {
		this.filters = $.extend({
			additionalFilter: {},
			sortBy: PreviewSortAttributes.DOC_DATE // used by nested components
		}, this.fullPageDocExplorerService.getDocumentFilters());
		this.sortDirection = AnSortDirection.DESC;

		this.sentimentFilters = new SentencePredefinedFilter(this.locale);
		this.easeScoreFilters = new SentencePredefinedFilter(this.locale);

		this.preferences = this.docExplorerPreferencesProvider.getInstance();

		this.panels = {};
		this.initLinkBetweenScopeAndPreference('favoriteAttributesCollapsed');
		this.initLinkBetweenScopeAndPreference('attrCollapsed');
		this.initLinkBetweenScopeAndPreference('worldAwarenessCollapsed');
		this.initLinkBetweenScopeAndPreference('topicsCollapsed');

		let sharingFeedbackSelection = new ExplorerAnalyticFeedbackSelectionClass(
			this.security, this.locale, this.widget, this.preferences,
			this.reportAssetUtilsService, this.projectAccessService);

		this.sharingFeedbackSelection = sharingFeedbackSelection;
		sharingFeedbackSelection.enable();

		if (this.paging) {
			this.widget.properties.itemsPerPage = this.paging.pages.pageSize;
			this.widget.properties.initialPageNumber = this.paging.pages.currentPage;
		}

		if (this.isParentPreviewWidget() && this.widget.properties.page.sortMetric) {
			this.filters.sortBy = this.widget.properties.page.sortMetric.displayName;
			this.sortDirection = this.widget.properties.page.sortMetric.direction;
		} else if (this.hasParentWidgetSortMetric() && this.widget.parentWidget.properties.page.sortMetric) {
			this.filters.sortBy = this.widget.parentWidget.properties.page.sortMetric.displayName;
			this.sortDirection = this.widget.parentWidget.properties.page.sortMetric.direction;
		}

		this.setWidgetLevelSortProperties();

		this.checkPreviewQuickFilter(); // this should not be placed into some delayed function (e.g. into promise callback)
		this.reportProcessingService.getWidgetFiltersMetadata(this.widget,
			this.currentWidgets.getDashboardHistoryIfAvailable(this.widget.containerId))
			.then((widgetMetadata) => {
				this.appliedFilters = this.appliedFiltersFactory.getAppliedFilters(widgetMetadata, true, true);
				/* need to populate additionalFilters populated in checkPreviewQuickFilter()
				cannot call that here because it causes a race condition of report data calls:
				1st location - from "toggleFilter()" which is called by checkPreviewQuickFilter()
				2nd location - initial data loading in widget-document-preview (get() function)
				This is very tricky, so leaving as is till some refactoring */
				this.reportProcessingService.setDocExplorerFilters(this.appliedFilters.getWidgetMetadata(),
					this.filters.additionalFilter);
				this.ref.markForCheck();
				this.focusOnHeaderButton();
			});
	}

	private initLinkBetweenScopeAndPreference(propName: string): void {
		if (!this.preferences.settings.paneCollapsedState) {
			this.preferences.settings.paneCollapsedState = {} as ContextPaneState;
		}
		if (_.isUndefined(this.preferences.settings.paneCollapsedState[propName])) {
			this.preferences.settings.paneCollapsedState[propName] = false;
		}
		this.preferences.storeUpdates();
	}

	private checkPreviewQuickFilter(): void {
		if (this.widget.parentWidget && this.widget.parentWidget.name === WidgetType.PREVIEW) {

			if (this.widget.parentWidget.properties.quickFilter) {
				let quickFilter = this.widget.parentWidget.properties.quickFilter;
				let type = quickFilter.type;
				let mode = quickFilter.value;

				this.toggleFilter(type, mode); // this causes race condition with initial document load, need to be careful
			}

		}
	}
	private hasParentWidgetSortMetric(): boolean {
		return this.widget.parentWidget.properties
				&& this.widget.parentWidget.properties.page
				&& this.widget.parentWidget.properties.page.sortMetric;
	}

	private isParentPreviewWidget(): boolean {
		return this.widget.parentWidget.properties
				&& DrillType.PREVIEW === this.widget.parentWidget.name;
	}

	private setWidgetLevelSortProperties(): void {
		this.widget.properties.page.sortMetric = {
			displayName: this.filters.sortBy,
			direction: this.sortDirection
		};
	}

	createDashboardFromCurrentSelection = () => {
		this.creatingNewDashboard = this.dashboardFromSelection.create({
			currentWidget: this.widget,
			currentDashboard: this.dashboard,
			preferences: this.preferences,
			feedbackSelection: this.sharingFeedbackSelection,
			availableScorecards: this.documentManager.availableScorecards,
			fromPreviewWidget: false
		} as IDashboardCreationContext);

		this.ref.markForCheck();
	}

	ngOnDestroy() {
		this.preferences.storeUpdates();
		clearTimeout(this.timeoutId);
	}

	private setFilter(type, mode, keepEnabled: boolean = false) {
		if (this.filters.additionalFilter[type] === mode && !keepEnabled) {
			this.filters.additionalFilter[type] = null;
		} else {
			this.filters.additionalFilter[type] = mode;
		}
		if (this.appliedFilters) {
			// this can be called during initial load in checkPreviewQuickFilter, which doesn't have appliedFilters initiated yet
			this.reportProcessingService.setDocExplorerFilters(this.appliedFilters.getWidgetMetadata(), this.filters.additionalFilter);
		}
	}

	private toggleFilter(type: PredefinedMetricConstants | DrillType.WORD_REPORT_TYPE, mode, keepEnabled: boolean = false): void {
		this.setFilter(type, mode, keepEnabled);
		this.trackFilterInAmplitude(type, mode);
		this.runReport();

		if (type === DrillType.WORD_REPORT_TYPE) {
			this.reloadStatistics();
		}
	}

	togglePredefinedFilter(type: PredefinedMetricConstants, value: PredefinedFilterValue) {
		this.toggleFilter(type, value);
	}

	toggleSort(sortBy: PreviewSortAttributes) {
		this.sortDirection = (this.sortDirection === AnSortDirection.DESC) ? AnSortDirection.ASC : AnSortDirection.DESC;
		this.filters.sortBy = sortBy;
		this.setWidgetLevelSortProperties();
		this.trackSortInAmplitude(sortBy);

		this.runReport();
	}

	private trackFilterInAmplitude = (type: PredefinedMetricConstants | DrillType.WORD_REPORT_TYPE, mode: PredefinedFilterValue): void => {
		let filter;

		// amplitude event data shouldnt be localized
		if (type === PredefinedMetricConstants.SENTIMENT) {
			switch (mode) {
				case PredefinedFilterValue.NEGATIVE:
					filter = 'Negative Sentiment';
					break;
				case PredefinedFilterValue.POSITIVE:
					filter = 'Positive Sentiment';
					break;
				case PredefinedFilterValue.NEUTRAL:
					filter = 'Neutral Sentiment';
					break;
				case PredefinedFilterValue.ALL:
					filter = 'Has Sentiment';
					break;
			}
		} else if (type === PredefinedMetricConstants.EASE_SCORE) {
			switch (mode) {
				case PredefinedFilterValue.NEGATIVE:
					filter = 'Negative Effort';
					break;
				case PredefinedFilterValue.POSITIVE:
					filter = 'Positive Effort';
					break;
				case PredefinedFilterValue.ALL:
					filter = 'Has Effort';
					break;
			}
		} else {
			filter = 'Key Word';
		}

		const currentDoc = this.documentManager?.documents[this.documentManager?.selectedDocument];
		const IS_DOC_EXPLORER = true;	// currently only possible in doc explorer

		const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(currentDoc, IS_DOC_EXPLORER);

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DOCEXPLORER_RESULTS_PANE_FILTER,
			{ documentId },
			{ filter, source, documentType }
		);
	}

	private trackSortInAmplitude(sortBy: PreviewSortAttributes): void {
		let sort;
		// amplitude event data shouldnt be localized
		if (sortBy === PreviewSortAttributes.DOC_DATE) {
			sort = 'Date';
		} else if (sortBy === PreviewSortAttributes.EFFORT) {
			sort = 'Effort Score';
		} else {
			sort = 'Sentiment';
		}

		let currentDoc = this.documentManager.documents[this.documentManager.selectedDocument];
		let documentType = DocumentTypeUtils.getAmplitudeDocumentType(currentDoc);
		let source = AmplitudeDocumentSource.DOC_EXPLORER;	// currently only possible in doc explorer

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DOCEXPLORER_RESULTS_PANE_SORT,
			{ documentId: currentDoc.id},
			{ sort, source, documentType }
		);
	}

	runReport(): void {
		if (this.documentManager) {
			let newWidget = this.documentManager.getWidgetWithFilter(this.widget);

			this.documentManager
				.runReportPaginated(newWidget, true)
				.then(() => {
					if (this.docExplorerHelperService.isRefreshPaginationOptions(this.documentManager)) {
						this.docExplorerHelperService.initPaginationOptions();
					}

					this.documentManager.autoSelect(this.widget);
				});
		}
	}

	searchDocuments(searchQuery: string) {
		this.documentManager.resetPagination();
		this.toggleFilter(DrillType.WORD_REPORT_TYPE, searchQuery, true);
	}

	isStatsEnabled = () => {
		return !this.requestInProgress;
	}

	processExplorerKeydown(event: KeyboardEvent) {
		let target = event.target;
		if (KeyboardUtils.isFocusBackward(event)) {
			if (target === $('.an-document-explorer :focusable:first')[0]) {
				event.stopPropagation();
				event.preventDefault();
				$('.an-document-explorer :focusable:last').trigger('focus');
			}
		} else if (KeyboardUtils.isFocusForward(event)) {
			if (target === $('.an-document-explorer :focusable:last')[0]) {
				event.stopPropagation();
				event.preventDefault();
				$('.an-document-explorer :focusable:first').trigger('focus');
			}
		}
	}

	handleTuningSuggestionsModeChange(enable: boolean) {
		if (enable) {
			this.preferences.settings.visiblePanes.sentence = true;
			this.preferences.settings.showSentiment = true;
			this.preferences.settings.visiblePanes.context = true;

			this.preferences.settings.singleVerbatimMode = false;
			this.preferences.settings.showEmptyAttributes = false;

			this.preferences.settings.sentencePane.showSentiment = true;
			this.preferences.settings.sentencePane.showSource = true;
		}
	}

	handleIntelligentScoringModeChange(enabled: boolean) {
		this.rebuttalMode = enabled;
	}

	showExportButton = () => {
		return this.documentManager?.data?.data?.length
			&& this.security.has('export_feedback');
	}

	reloadStatistics = () => {
		let stats = this.getStatistics();
		this.requestInProgress = true;

		this.loadingStatistics = stats.then((responses) => {
			delete this.requestInProgress;
			this.sentimentFilters.updateStatistics(responses[0].data);
			this.easeScoreFilters.updateStatistics(responses[1].data);
			this.docExplorerQids.sentimentQid = responses[0].metadata && responses[0].metadata.qid;
			this.docExplorerQids.easeQid = responses[1].metadata && responses[1].metadata.qid;
		}, () => {
			delete this.requestInProgress;
			this.sentimentFilters.resetStatistics();
			this.easeScoreFilters.resetStatistics();
		});
		this.ref.markForCheck();
	}

	private getStatistics(): Promise<any[]> {
		let runWidgetSettings = ObjectUtils.copy(this.documentManager.getWidgetWithFilter(this.widget));
		runWidgetSettings.properties.documentExplorer = true;
		runWidgetSettings.properties.drillFilters = this.widget.properties.drillFilters || [];
		this.reportRunPreparationService.populateBrowserProperties(runWidgetSettings);

		return PromiseUtils.wrap(this.documentManager.postProcessProperties(runWidgetSettings)).then((processedSettings) => {
			processedSettings.properties.selectedAttributes = [this.metricConstants.get().SENTIMENT_BREAKDOWN_3];

			let sentimentStat = this.reportDataApiService.getAnSentenceStatistics(processedSettings);
			let easeSettings = ObjectUtils.copy(processedSettings);
			easeSettings.properties.selectedAttributes = [this.metricConstants.get().EASE_BREAKDOWN_3];
			let easeStat = this.reportDataApiService.getAnSentenceStatistics(easeSettings);
			return Promise.all([sentimentStat, easeStat]);
		});
	}

	private focusOnHeaderButton = (): void => {
		this.timeoutId = setTimeout(() => {
			const filterButton = $('document-explorer-container')
				.find('filter-applied-dropdown')
				.find('button')[0];
			(filterButton as HTMLElement)?.focus();
		}, 100);
	}


	initDocumentManager = (documentManager: IDocumentPreviewerControls) => {
		this.documentManager = documentManager;

		let textFilter = this.fullPageDocExplorerService.getDocumentFilters()?.additionalFilter?.words;
		if (!!textFilter) {
			this.searchValue = textFilter;
		}
	}

	getDocumentExplorerName(): string {
		return this.widget.documentExplorerDrillPointDisplayName || this.widget.documentExplorerName;
	}

	goFullPage(): void {
		this.fullPageDocExplorerService.openFullPageDocExplorer(
			this.widget,
			this.filters
		);
	}

}
