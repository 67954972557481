import Widget from '@cxstudio/dashboards/widgets/widget';
import { DriversFormatting } from '@cxstudio/drivers/utils/drivers-formatting.service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { CustomGroupMetadata } from '@cxstudio/reports/entities/custom-group-metadata';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import {Inject, Injectable} from '@angular/core';
import {downgradeInjectable} from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class CustomGroupToFilterService implements IPointToFilter {
	constructor(
		@Inject('driversFormatting') private driversFormatting: DriversFormatting,
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: ReportGrouping): DrillFilter | null => {
		const name = GroupIdentifierHelper.getIdentifier(groupBy);

		const metadata = point[`_metadata_${name}`] as CustomGroupMetadata;
		if (metadata) {
			let displayName = metadata.displayName;
			if (AnalyticMetricTypes.isDrivers(groupBy))
				displayName = this.driversFormatting.getDriverDisplayName(displayName);
			return {
				name: '' + groupBy.name,
				type: DrillType.CUSTOM_GROUP,
				values: [displayName, metadata.rawQuery]
			};
		}

		return null;
	}

	toFilterTypes = (parsers: DrillParsers): void => {
		parsers[DrillType.CUSTOM_GROUP] = this;
	}

	getRuleString = (filter: DrillFilter): Promise<string | void> => {
		if (filter.values && filter.values.length === 2) {
			return Promise.resolve(filter.values[0]);
		}

		return Promise.resolve('');
	}
}

app.service('customGroupToFilterService', downgradeInjectable(CustomGroupToFilterService));
