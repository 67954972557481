import { Injectable } from '@angular/core';
import { AsyncTask } from './async-task';
import { AsyncTaskItemData } from '@app/modules/user-administration/bulk/async-task-item-data';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class AsyncTaskApiService {
	constructor(
		private http: CxHttpService
	) {}

	getTaskStatus<T extends AsyncTaskItemData>(taskId: string): Promise<AsyncTask<T>> {
		const url = `rest/async-task/${taskId}`;
		return this.http.get(url);
	}
}
