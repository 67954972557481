import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core';
import { CxDialogComponent } from '@app/modules/dialog/cx-dialog.component';


export interface ConfirmMobileAppOwnerChangeDialogInput {
	submitterEmail: string;
}

export interface ConfirmMobileAppOwnerChangeDialogOutput {
	retainingEditPermission: boolean;
}

@Component({
	selector: 'confirm-mobile-app-owner-change-dialog',
	templateUrl: './confirm-mobile-app-owner-change-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMobileAppOwnerChangeDialogComponent
	extends CxDialogComponent<ConfirmMobileAppOwnerChangeDialogInput, ConfirmMobileAppOwnerChangeDialogOutput> {

	constructor(
		private locale: CxLocaleService,
		modalService: NgbModal
	) {
		super(modalService);
	}

	initOutput(): void {
		this.output = {
			retainingEditPermission: true
		};
	}

	getPromptText(): string {
		return this.locale.getString('mobile.transferAppDialogPrompt', { submitterEmail: this.input.submitterEmail });
	}

	protected getDialogClass(): any {
		return ConfirmMobileAppOwnerChangeDialogComponent;
	}

}

app.service('confirmMobileAppOwnerChangeDialog', downgradeInjectable(ConfirmMobileAppOwnerChangeDialogComponent));