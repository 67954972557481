import { Input, Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
	selector: 'user-accounts-modal',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<modal-header
	(cancel)="cancel()"
	modalTitle="{{'administration.linkUserToCP'|i18n}}">
</modal-header>

<div class="modal-body">
	<label>{{'mAccount.selectCP'|i18n}}</label>
	<content-providers [accountMap]="selectedAccounts"></content-providers>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[saveText]="'administration.save' | i18n">
</save-modal-footer>`
})
export class UserAccountsModalComponent implements OnInit {
	@Input() input: { linkedAccounts: any[] };

	selectedAccounts;

	constructor(
		private readonly modal: NgbActiveModal
	) { }

	ngOnInit(): void {
		this.selectedAccounts = cloneDeep(this.input.linkedAccounts);
	}

	save = () => {
		this.modal.close(this.selectedAccounts);
	}

	cancel = () => {
		this.modal.dismiss('cancel');
	}
}
