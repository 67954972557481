import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ModelsService } from '@app/modules/project/model/models.service';
import { SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { ModelGroupingSettingsEditor } from './model-grouping-settings-editor';


@Injectable()
export class ModelGroupingSettingsEditorProvider {

	constructor(
		private readonly sortByOptionsService: SortByOptionsService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
		private readonly modelsService: ModelsService,
	) {}

	getInstance(): ModelGroupingSettingsEditor {
		return new ModelGroupingSettingsEditor(
			this.optionsBuilderProvider,
			this.sortByOptionsService,
			this.modelsService
		);
	}
}

app.service('modelGroupingSettingsEditorProvider', downgradeInjectable(ModelGroupingSettingsEditorProvider));
