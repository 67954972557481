import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UniqueNameValidator } from '@app/modules/asset-management/validation/unique-name-validator';
import { IAssetPropertiesComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/asset-properties-component.interface';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { UniqueNameService } from '@cxstudio/common/unique-name-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';

@Component({
	selector: 'dashboard-creation-properties',
	templateUrl: './dashboard-creation-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardCreationPropertiesComponent implements IAssetPropertiesComponent<Dashboard>, OnInit  {
	@Output() projectChange = new EventEmitter<WorkspaceProject>();
	@Output() validityChange = new EventEmitter<boolean>();

	template: UnifiedTemplate;
	dashboard: Dashboard;
	adminProjectOnly: boolean;
	invalidNameError: boolean;
	uniqueNameError: boolean;
	private nameValidator: UniqueNameValidator<Dashboard>;

	loaded: boolean = false;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
		@Inject('uniqueNameService') private readonly uniqueNameService: UniqueNameService,
		private assetTemplateApi: AssetTemplateApiService,
	) {}

	ngOnInit(): void {
		this.reloadDashboards();
	}

	initTemplate(template: UnifiedTemplate): void {
		this.dashboard = {
			name: template.name,
			workspaceProject: {} as WorkspaceProject
		} as Dashboard;
		this.template = template;

		this.assetTemplateApi.getTemplateAssetDataFromTemplate(template.assetType, template.id)
			.then(assetData => {
				this.adminProjectOnly = InternalProjectTypes.isStudioAdminProject(assetData.project.projectId);
				this.loaded = true;
				this.ref.markForCheck();
			});
	}

	getAsset(): Dashboard {
		return this.dashboard;
	}

	onProjectChange(): void {
		this.projectChange.emit(this.dashboard.workspaceProject);
	}

	isValid(): boolean {
		return !this.invalidNameError && !this.uniqueNameError && !_.isUndefined(this.uniqueNameError);
	}

	private reloadDashboards(): void {
		this.dashboardApiService.getAllDashboards().then(dashboards => {
			if (this.dashboard.name === this.template.name) {
				this.dashboard.name = this.uniqueNameService.resolveUniqueName(_.pluck(dashboards, 'name'), this.template.name);
			}
			this.nameValidator = new UniqueNameValidator({name: this.dashboard.name} as Dashboard,
				dashboards, {nameField: 'name'});
			this.checkName();
			this.validityChange.emit(this.isValid());
		});
	}

	checkName(): void {
		this.invalidNameError = !this.dashboard?.name.trim();
		this.uniqueNameError = this.nameValidator && !this.nameValidator.isUniqueName(this.dashboard.name);
		this.ref.markForCheck();
	}
}
