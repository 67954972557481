import { Pagination } from '@app/shared/components/pagination/pagination';
import * as cloneDeep from 'lodash.clonedeep';

export interface ReportPagination {
	pages: Pagination;
	state: {
		startBound: number;
		endBound: number;
		storedStart: number;
		storedEnd: number;
	};
}

export type DocumentExplorerPagination = Pick<ReportPagination, 'pages'>;

export class ReportPaginationService {

	static readonly AN_ITEMS_LIMIT: number = 5000;

	static populatePagingMetadata(pageData, pagination): void {
		pageData.pagingMetadata = cloneDeep(pagination);
	}

	static fillPageBounds(pagination: ReportPagination, offset = 0): void {
		pagination.state.endBound = offset + (pagination.pages.currentPage) * pagination.pages.pageSize;
		pagination.state.startBound = offset + (pagination.pages.currentPage - 1) * pagination.pages.pageSize;
	}

	static ensureStartEnd(pagination: ReportPagination, dataObject: {data: any[]}): void {
		if (_.isEmpty(dataObject?.data)) {
			// it can be empty if there is no data for current "window"
			// e.g. user is on 7th page, but bulk request for 6-10 pages is empty
			// in such case it automatically resets report pagination
			return;
		}
		let start = pagination.state.startBound - pagination.state.storedStart;
		if (start > dataObject.data.length) {
			let maxPage = Math.ceil(dataObject.data.length / pagination.pages.pageSize);
			pagination.pages.currentPage = maxPage;
			ReportPaginationService.fillPageBounds(pagination, pagination.state.storedStart);
		}
	}

	static isLimitReached(totalItems: number): boolean {
		return totalItems >= this.AN_ITEMS_LIMIT;
	}
}
