import { IDataPoint } from '@cxstudio/reports/entities/report-definition';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { ExtendedHierarchyNode } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';
import { ITreeRenderer, ITreeSVG } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer.interface';
import { BaseType, Transition, Selection, HierarchyLink, DefaultLinkObject, Link } from 'd3';
import { HierarchyTreeOptions } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/hierarchy-tree-options.class';
import { TreeRendererUtils } from '@cxstudio/reports/visualizations/definitions/d3/renderers/tree/tree-renderer-utils';

import * as CBIcons from '@clarabridge/unified-icons/src/codepoints.json';
import { RandomUtils } from '@app/util/random-utils.class';

export type TreeNodeSelection = Selection<BaseType, ExtendedHierarchyNode, SVGElement, ExtendedHierarchyNode>;
export type TreeTransition = Transition<BaseType, ExtendedHierarchyNode, any, ExtendedHierarchyNode>;

export abstract class BaseTreeRenderer implements ITreeRenderer {

	static LINE_COLOR = 'var(--gray-600)';
	static MAX_BUBBLE_DIAMETER = 32; // px

	protected transition: TreeTransition;
	private fillUrls: {[key: string]: string} = {};

	// patterns are reused from highcharts accessibility patterns
	private readonly allPatterns: string[] = [
		'M 0 3 L 10 3 M 0 8 L 10 8',
		'M 0 3 L 5 3 L 5 0 M 5 10 L 5 7 L 10 7',
		'M 3 3 L 8 3 L 8 8 L 3 8 Z',
		'M 5 5 m -4 0 a 4 4 0 1 1 8 0 a 4 4 0 1 1 -8 0',
		'M 10 3 L 5 3 L 5 0 M 5 10 L 5 7 L 0 7',
		'M 2 5 L 5 2 L 8 5 L 5 8 Z',
		'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
		'M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9',
		'M 3 0 L 3 10 M 8 0 L 8 10',
	];
	private patterns: string[];

	abstract renderNode(node: ExtendedHierarchyNode): void;
	abstract scrollTo(node: ExtendedHierarchyNode): void;
	protected abstract recalculateSize(): void;

	protected abstract drawShape(nodeEnter: TreeNodeSelection): TreeNodeSelection;
	protected abstract drawText(nodeEnter: TreeNodeSelection): TreeNodeSelection;
	protected abstract updateText(nodeUpdate: TreeTransition): TreeTransition;


	constructor(
		protected options: HierarchyTreeOptions,
		private usePatternFills: boolean = false,
		private isDarkMode: boolean = false) {}

	protected initTransition(svg: ITreeSVG, viewBox: number[]): TreeTransition {
		this.transition = svg.transition()
			.duration(250)
			.attr('viewBox', viewBox as any)
			.tween('resize', (window as any).ResizeObserver ? null : () => () => svg.dispatch('toggle'));
		return this.transition;
	}

	private drawLabel(node: TreeNodeSelection): void {
		this.drawText(node)
			.classed('tree-node-label', true)
			.clone(true).lower() // white outline for text
			.attr('stroke-linejoin', 'round')
			.attr('stroke-width', 3)
			.attr('stroke', 'white');
	}

	private drawTitle(node: TreeNodeSelection): void {
		node.append('title').text(d => d.data.displayName);
	}

	private drawParentIndicator(node: TreeNodeSelection): void {
		let hoverNode = node.filter(d => !TreeRendererUtils.isLeaf(d))
			.append('g')
			.classed('show-on-parent-hover', true);
		hoverNode.append('circle')
			.attr('r', 6)
			.attr('fill', (d: any) => {
				if (d.color)
					return d.color.pattern ? d.color.pattern.color : d.color;
				else return BaseTreeRenderer.LINE_COLOR;
			});
		hoverNode.append('text')
			.attr('fill', ColorConstants.WHITE)
			.attr('text-anchor', 'middle')
			.attr('dy', 3)
			.style('font-family', 'var(--qualtrics-icons)')
			.attr('font-size', 8)
			.attr('class', 'node-expand')
			.html(String.fromCodePoint(CBIcons.minus))
			.clone(true)
			.attr('class', 'node-collapse')
			.html(String.fromCodePoint(CBIcons['add-3']));
	}

	protected renderNodes(
		container: ITreeSVG,
		nodes: ExtendedHierarchyNode[],
		initialPosition: [number, number],
		targetPositionFn: (d: ExtendedHierarchyNode) => [number, number],
		finalPosition: [number, number]
	): void {
		let node = container.selectAll('g.tree-node')
			.data(nodes, (d: ExtendedHierarchyNode) => d.id);
		let nodeEnter = node.enter().append('g')
			.attr('transform', `translate(${initialPosition[0]},${initialPosition[1]})`)
			.attr('fill-opacity', 0)
			.attr('stroke-opacity', 0)
			.attr('cursor', 'pointer')
			.on('click', (e: MouseEvent, d: ExtendedHierarchyNode) => {
				if (TreeRendererUtils.isLeaf(d))
					return;
				TreeRendererUtils.toggleChildren(d);
				this.options.selectPoint(d, e.target); // need to recreate selectedPoint object due to changed state
				this.renderNode(d);
				e.stopPropagation(); // do not initiate drill
			})
			.on('mouseover', (e: MouseEvent, data) => this.options.selectPoint(data, e.target))
			.on('mouseout', (e, data) => this.options.selectPoint(null));
		this.drawShape(nodeEnter);
		if (this.options.showLabels) {
			this.drawLabel(nodeEnter);
		}
		this.drawTitle(nodeEnter);
		this.drawParentIndicator(nodeEnter);

		// Transition nodes to their new position.
		let nodeUpdate = node.merge(nodeEnter).transition(this.transition)
			.attr('transform', d => {
				let position = targetPositionFn(d);
				return `translate(${position[0]},${position[1]})`;
			})
			.attr('class', d => {
				let nodeType = TreeRendererUtils.isLeaf(d) ? 'leaf-node' : 'parent-node';
				let nodeState = TreeRendererUtils.isCollapsed(d) ? '' : 'open';
				return `tree-node hover-parent ${nodeType} ${nodeState}`;
			})
			.attr('selected', d => this.options.isSelected(d) ? true : undefined)
			.attr('fill-opacity', 1)
			.attr('stroke-opacity', 1);
		this.updateText(nodeUpdate.selectAll('.tree-node-label'));

		node.exit().transition(this.transition).remove()
			.attr('transform', `translate(${finalPosition[0]},${finalPosition[1]})`)
			.attr('fill-opacity', 0)
			.attr('stroke-opacity', 0);
	}

	protected renderLinks(
		container: ITreeSVG,
		links: Array<HierarchyLink<IDataPoint>>,
		diagonal: Link<any, DefaultLinkObject, [number, number]>,
		initialPosition: [number, number],
		targetPositionFn: (d: ExtendedHierarchyNode) => [number, number],
		finalPosition: [number, number]
	): void {
		let link = container.selectAll('path')
			.data(links, (d: any) => d.target.id);
		let linkEnter = link.enter().append('path')
			.attr('d', diagonal({source: initialPosition, target: initialPosition}));
		link.merge(linkEnter).transition(this.transition)
			.attr('d', d => {
				let s = d.source as ExtendedHierarchyNode;
				let t = d.target as ExtendedHierarchyNode;
				return diagonal({source: targetPositionFn(s), target: targetPositionFn(t)});
			});
		link.exit().transition(this.transition).remove()
			.attr('d', diagonal({source: finalPosition, target: finalPosition}));
	}

	protected getMinMax(root: ExtendedHierarchyNode): {min: number, max: number} {
		let left = root;
		let right = root;
		root.eachBefore((child: ExtendedHierarchyNode) => {
			if (child.x < left.x) left = child;
			if (child.x > right.x) right = child;
		});
		return {min: left.x, max: right.x};
	}

	isPattern(color: string | any): color is object {
		return typeof color === 'object';
	}

	getFill(color: string | {pattern: {color: string}}, patternGroup: ITreeSVG): string {
		let definedColor = color || BaseTreeRenderer.LINE_COLOR;
		if (!this.usePatternFills) return definedColor as string;

		let colorString = this.isPattern(definedColor) ? definedColor.pattern.color : definedColor;
		return this.getPatternFill(colorString, patternGroup);
	}

	private getPatternFill(colorKey: string, patternGroup: ITreeSVG): string {
		if (!this.fillUrls[colorKey]) {
			let id = `d3-pattern-${_.uniqueId()}-${RandomUtils.randomString()}`;

			let pattern = patternGroup.append('pattern');
			pattern.attr('id', id)
				.attr('aria-hidden', true)
				.attr('x', 0)
				.attr('y', 0)
				.attr('height', 10)
				.attr('width', 10)
				.attr('patternUnits', 'userSpaceOnUse');

			if (!this.patterns?.length) {
				this.patterns = [...this.allPatterns];
			}

			pattern.append('path')
				.attr('stroke', colorKey)
				.attr('d', this.patterns.shift())
				.attr('fill', 'none');

			this.fillUrls[colorKey] = id;
		}

		return `url(#${this.fillUrls[colorKey]})`;
	}

	getStrokeWidth(defaultWidth: number): number {
		return this.usePatternFills ? 2 : defaultWidth;
	}

	getStrokeColor(): string | undefined {
		if (!this.usePatternFills) return undefined;

		return this.isDarkMode ? ColorConstants.WHITE : ColorConstants.GRAY_600;
	}

}
