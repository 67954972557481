import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { UnitsApi } from '@app/modules/units/units.api.service';
import { WorkspaceProjectSelectorComponent } from './workspace-project-selector/workspace-project-selector.component';
import { WorkspaceSelectorComponent } from './workspace-selector/workspace-selector.component';
import { ProjectSelectionErrorComponent } from '@app/modules/units/project-selection-error/project-selection-error.component';

@NgModule({
	imports: [
		SharedModule,
	],
	exports: [
		WorkspaceProjectSelectorComponent,
		WorkspaceSelectorComponent,
		ProjectSelectionErrorComponent,
	],
	declarations: [
		WorkspaceProjectSelectorComponent,
		WorkspaceSelectorComponent,
		ProjectSelectionErrorComponent,
	],
	providers: [
		UnitsApi
	],
})
export class UnitsModule {}
