import { FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { DocViewPaneSettings } from './doc-view-pane-settings';

export abstract class DocViewPreferences {
	settings: DocViewPaneSettings;
	constructor() {
	}
	abstract getSavedFavorites(contentProviderId: number, accountId: number, projectId: number): any;
	abstract updateSavedFavorites(contentProviderId: number, accountId: number, projectId: number, favorites: FavoriteId[]): void;
	abstract storeUpdates(): void;
}