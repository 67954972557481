import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { DashboardEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DashboardChange } from '@app/modules/dashboard-actions/undo/dashboard-change';
import { DashboardHistoryStateService } from '@app/modules/dashboard-actions/undo/dashboard-history-state.service';
import { RecentDateService } from '@app/modules/dashboard/services/recent-date-service/recent-date.service';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { HybridModeUtils } from '@app/util/hybrid-mode-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'undo-controls',
	template: `
<div class="undo-controls d-flex flex-direction-row flex-nowrap">
	<div class="btn-group">
		<button class="btn btn-secondary"
			(click)="undo()"
			[disabled]="saving || !canUndo()"
			[attr.aria-label]="'dashboard.undo' | i18n"
			[title]="getUndoStates().last()?.description">
			<span class="q-icon q-icon-undo" aria-hidden="true"></span>
			<span [i18n]="'dashboard.undo'"></span>
		</button>
		<div ngbDropdown class="btn-group">
			<button ngbDropdownToggle
				class="btn btn-secondary q-icon q-icon-angle-bottom ph-8 border-r-radius-4"
				[disabled]="saving || !canUndo()"
				[attr.aria-label]="'dashboard.menuUndoLabel' | i18n">
			</button>
			<div ngbDropdownMenu>
				<a *ngFor="let state of getUndoStates() | reverse; let $count = count; let $index = index"
					ngbDropdownItem
					(click)="undo($count - $index - 1)"
					href="javascript:void(0)"
					class="d-flex flex-direction-column"
					[title]="state.description">
					<span class="mb-4">{{formatTimestamp(state.timestamp)}}</span>
					<span><strong>{{state.description}}</strong></span>
				</a>
			</div>
		</div>
	</div>
	<button *ngIf="canRedo()"
		class="btn btn-secondary"
		(click)="redo()"
		[disabled]="saving"
		[title]="getRedoTitle()"
		[attr.aria-label]="'dashboard.redo' | i18n">
		{{'dashboard.redo' | i18n}}
		<i class="q-icon q-icon-redo"></i>
	</button>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoControlsComponent extends SelfCleaningComponent implements OnInit {

	saving: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly eventBus: GlobalEventBus,
		private readonly dashboardHistoryState: DashboardHistoryStateService,
		private readonly recentDateService: RecentDateService,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
	) {
		super();
	}

	ngOnInit(): void {
		this.dashboardHistoryState.reset();
		this.addSubscription(this.dashboardHistoryState.getChangeObserver().subscribe(() => this.ref.markForCheck()));
		this.addSubscription(HybridModeUtils.convertObservable(this.widgetsEditService.getSavingObserver())
			.subscribe((saving) => {
				this.saving = saving;
				this.ref.markForCheck();
			}));
	}

	@HostListener('window:keydown.meta.z', ['$event'])
	@HostListener('window:keydown.control.z', ['$event'])
	onUndo(event: KeyboardEvent) {
		event.preventDefault();
		if (this.canUndo() && this.isModalClosed() && !this.isInput() &&
		KeyboardUtils.isWinCtrlOrMacCmd(event)) {
			this.undo();
		}
	}

	@HostListener('window:keydown.meta.shift.z', ['$event'])
	@HostListener('window:keydown.control.y', ['$event'])
	onRedo(event: KeyboardEvent) {
		event.preventDefault();
		if (this.canRedo() && this.isModalClosed() && !this.isInput() &&
			KeyboardUtils.isWinCtrlOrMacCmd(event)) {
			this.redo();
		}
	}

	isInput(): boolean {
		return $(document.activeElement).is('input');
	}

	isModalClosed(): boolean {
		if (document.querySelectorAll('ngb-modal-window, [uib-modal-window]').length === 0) {
			return true;
		}
		return false;
	}

	canUndo(): boolean {
		return this.dashboardHistoryState.canUndo();
	}

	getUndoStates(): DashboardChange[] {
		return this.dashboardHistoryState.getActiveStates();
	}

	formatTimestamp(timestamp: number): string {
		return this.recentDateService.formatToRecentDate(timestamp);
	}

	canRedo(): boolean {
		return this.dashboardHistoryState.canRedo();
	}

	getRedoTitle(): string {
		return this.dashboardHistoryState.getRedoState().description;
	}

	undo(index?: number): void {
		let changes = index >= 0 ? this.dashboardHistoryState.undoTo(index) : this.dashboardHistoryState.undo();
		this.eventBus.broadcast(DashboardEvent.APPLY_HISTORY_STATE, changes);
	}

	redo(): void {
		let changes = this.dashboardHistoryState.redo();
		this.eventBus.broadcast(DashboardEvent.APPLY_HISTORY_STATE, changes);
	}

}
