import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { IToggleListOption } from '@app/shared/components/toggle-list/toggle-list.component';
import { Security } from '@cxstudio/auth/security-service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

@Component({
	selector: 'tuning-suggestions-settings',
	templateUrl: './tuning-suggestions-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuningSuggestionsSettingsComponent implements OnInit {

	headers: string[];

	accountFeatures: IToggleListOption<SuggestionType>[];
	systemFeatures: IToggleListOption<SuggestionType>[];
	showTopicsBanner: boolean;

	constructor(
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService
	) {}

	ngOnInit(): void {
		this.headers = [this.locale.getString('widget.enrichment'), this.locale.getString('common.enabled')];
		let suggestionSettings = this.security.getCurrentMasterAccount().tuningSuggestionsSettings;
		this.accountFeatures = [
			{text: 'metrics.sentiment', id: SuggestionType.SENTIMENT, enabled: suggestionSettings[SuggestionType.SENTIMENT]},
			{text: 'widget.topics', id: SuggestionType.TOPIC, enabled: suggestionSettings[SuggestionType.TOPIC]},
		];
		let suggestionIntegrationEnabled = this.security.getCurrentMasterAccount().tuningSuggestionIntegration?.enabled;
		let systemFeaturesTitle = suggestionIntegrationEnabled ? null : this.locale.getString('mAccount.nlpSuggestionsDisabled');
		this.systemFeatures = [
			{
				text: 'metrics.easeScore',
				id: SuggestionType.EFFORT,
				enabled: suggestionSettings[SuggestionType.EFFORT] && suggestionIntegrationEnabled,
				inactive: !suggestionIntegrationEnabled,
				title: systemFeaturesTitle
			},
			{
				text: 'metrics.emotion',
				id: SuggestionType.EMOTION,
				enabled: suggestionSettings[SuggestionType.EMOTION] && suggestionIntegrationEnabled,
				inactive: !suggestionIntegrationEnabled,
				title: systemFeaturesTitle
			}
		];
	}

	isTopicsEnabled(): boolean {
		return this.security.getCurrentMasterAccount().tuningSuggestionsSettings.topic;
	}

	toggleFeature(feature: IToggleListOption<SuggestionType>): void {
		this.security.getCurrentMasterAccount().tuningSuggestionsSettings[feature.id] = feature.enabled;
		this.masterAccountApiService.updateTuningSuggestionProperty(feature.id, feature.enabled);

		this.showNotification(feature.text, feature.enabled);
		if (feature.id === SuggestionType.TOPIC && !feature.enabled) {
			this.showTopicsBanner = true;
		}
	}

	private showNotification(featureTitleKey: string, enabled: boolean): void {
		let featureDisplayName: string = this.locale.getString(featureTitleKey);
		let featureNewState: string = enabled ? this.locale.getString('common.enabled') : this.locale.getString('common.disabled');
		this.globalNotificationService.addSuccessNotification(`${featureDisplayName} ${featureNewState.toLowerCase()}`);
	}

}

app.directive('tuningSuggestionsSettings',
	downgradeComponent({component: TuningSuggestionsSettingsComponent}) as angular.IDirectiveFactory);
