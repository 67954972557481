import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
import Xrange from 'highcharts/modules/xrange';
import Networkgraph from 'highcharts/modules/networkgraph';
import Accessibility from 'highcharts/modules/accessibility';
import PatternFill from 'highcharts/modules/pattern-fill';
import Gauge from 'highcharts/modules/solid-gauge';

// tslint:disable
declare let module: any;
((Highcharts) => {

	More(Highcharts);
	Tree(Highcharts);
	Heatmap(Highcharts);
	Xrange(Highcharts);
	Networkgraph(Highcharts);
	Accessibility(Highcharts);
	PatternFill(Highcharts);
	Gauge(Highcharts);

	let HEADER_HEIGHT = 17;

	if ((Highcharts as any).seriesTypes.treemap) {
		// for headers
		Highcharts.wrap((Highcharts as any).seriesTypes.treemap.prototype, 'setPointValues', function (original): void {
			let series = this;
			// Call the original function
			original.apply(this, Array.prototype.slice.call(arguments, 1));
			// adjust rect size to free scape for headers
			if (series.options.showHeaders)
				adjustPoints(series);
		});

		// Wrappers for drill menu
		// to not call drillTo when we click on tree node
		Highcharts.wrap((Highcharts as any).seriesTypes.treemap.prototype, 'onClickDrillToNode', function (original): void {
			let series = this;
			if (series.options.allowTraversingTree !== 'custom') // drill is handled manually
				original.apply(this, Array.prototype.slice.call(arguments, 1));
		});

		// prevent context menu, when clicking "Back"
		Highcharts.wrap((Highcharts as any).seriesTypes.treemap.prototype, 'renderTraverseUpButton', function (original): void {
			let series = this;
			original.apply(this, Array.prototype.slice.call(arguments, 1));
			if (series.options.allowTraversingTree === 'custom' && series.drillUpButton) {
				series.drillUpButton.on('click', (e) => {
					series.drillUp();
					e.stopPropagation();
				});
			}
		});
	}
	function adjustPoints(series): void {
		_.each(series.points, (point) => {
			if (!point.node.values || !point.node.visible)
				return;
			let node = point.node;
			while (requireShift(node, series)) {
				point.shapeArgs.y += HEADER_HEIGHT;
				point.shapeArgs.height = Math.max(point.shapeArgs.height - HEADER_HEIGHT, 0);
				node = series.nodeMap[node.parent];
			};
		});
	}
	function requireShift(node, series): boolean {
		if (!node.parent || series.rootNode === node.id)
			return false;
		let currY = series.nodeMap[node.id].values.y;
		let parY = series.nodeMap[node.parent].values.y;
		return Math.abs(currY - parY) < 0.001; // in case of calculation errors
	}

	// adds a support for "ignoreWhenScaling" property, which allows series to not affect auto-scale
	Highcharts.wrap(Highcharts.Axis.prototype, 'getSeriesExtremes', function(original): void {
		let series = this.chart.series;
		setIgnoredSeriesVisibility(series, false);
		original.apply(this, Array.prototype.slice.call(arguments, 1));
		setIgnoredSeriesVisibility(series, true);
	});

	function setIgnoredSeriesVisibility(series, visible) {
		if (!series || !series.length)
			return;
		series.forEach(function(seriesObject) {
			if (seriesObject.options.ignoreWhenScaling === true)
				seriesObject.visible = visible;
		});
	}

	// Advanded z-indexing, which allow more flexible placement of grid/bands/lines/series
	// May break e2e tests, so covered by property
	Highcharts.wrap(Highcharts.Axis.prototype, 'render', function (proceed): any {
		let chart = this.chart;

		proceed.call(this);
		processZIndex(this.gridGroup, undefined, chart);

		return this;
	});
	Highcharts.wrap(Highcharts.PlotLineOrBand.prototype, 'render', function (proceed): any {
		let chart = this.axis.chart;

		proceed.call(this);

		processZIndex(this.svgElem, this.options.zIndex, chart);
		processZIndex(this.label, this.options.zIndex, chart);

		return this;
	});

	function processZIndex(element, zIndex, chart): void {
		if (!chart.options.chart || !chart.options.chart.advancedZIndex)
			return;
		if (!chart.seriesGroup) {
			chart.seriesGroup = chart.renderer.g('series-group')
				.attr({ zIndex: 3 })
				.add();
		}

		if (element && element.parentGroup !== chart.seriesGroup) {
			if (zIndex !== undefined)
				element = element.attr({ zIndex: zIndex });
			element.add(chart.seriesGroup);
		}
	}

	if ((Highcharts as any).layouts && (Highcharts as any).layouts['reingold-fruchterman']) {
		Highcharts.wrap((Highcharts as any).layouts['reingold-fruchterman'].prototype, 'attractiveForces', function (proceed): void {
			// override attractive forces to calculate based on cooccurrence
			if (this.options.customForces) {
				let layout = this,
					distanceXY,
					distanceR,
					force;
				layout.links.forEach(function (link) {
					if (link.fromNode && link.toNode) {
						distanceXY = layout.getDistXY(link.fromNode, link.toNode);
						distanceR = layout.vectorLength(distanceXY);
						if (distanceR !== 0) {
							let desiredDistance = getDesiredDistance(link.cooccurrence, layout.options.cooccurrenceRange, layout.k * 1.5);
							force = layout.attractiveForce(distanceR, desiredDistance);
							layout.force('attractive', link, force, distanceXY, distanceR);
						}
					}
				});
			} else {
				proceed.call(this);
			}
		});

		Highcharts.wrap((Highcharts as any).layouts['reingold-fruchterman'].prototype, 'repulsiveForces', function (proceed) {
			// override repulsive forces to calculate based on cooccurrence
			if (this.options.customForces && this.approximation !== 'barnes-hut') {
				let layout = this;
				layout.nodes.forEach(function (node) {
					layout.nodes.forEach(function (repNode) {
						let force,
							distanceR,
							distanceXY;
						if (
						// Node can not repulse itself:
						node !== repNode &&
							// Only close nodes affect each other:
							// layout.getDistR(node, repNode) < 2 * k &&
							// Not dragged:
							!node.fixedPosition) {
							distanceXY = layout.getDistXY(node, repNode);
							distanceR = layout.vectorLength(distanceXY);
							if (distanceR !== 0) {
								let linkCooccurrence = getLinkCooccurrenceFromNodes(layout.options.linksMap, node, repNode);
								let desiredDistance = getDesiredDistance(linkCooccurrence, layout.options.cooccurrenceRange, layout.k * 1.5);
								force = layout.repulsiveForce(distanceR, desiredDistance);
								layout.force('repulsive', node, force * repNode.mass, distanceXY, distanceR);
							}
						}
					});
				});
			} else {
				proceed.call(this);
			}
		});
	}

	function getLinkCooccurrenceFromNodes(linksMap, node1, node2): any {
		if (linksMap[node1.id] && linksMap[node1.id][node2.id]) {
			return linksMap[node1.id][node2.id];
		}
		if (linksMap[node2.id] && linksMap[node2.id][node1.id]) {
			return linksMap[node2.id][node1.id];
		}
		return 0;
	}

	function getDesiredDistance(value, range, maxDistance): number { // distance algorithm may subject to change
		if (value === 0 || range.max === range.min || maxDistance < 50) {
			return maxDistance;
		}
		let offset = Math.round(((range.max - value) / (range.max - range.min)) * (maxDistance - 50));
		return 50 + offset;
	}

	if ((Highcharts as any).seriesTypes.networkgraph) {
		Highcharts.wrap((Highcharts as any).seriesTypes.networkgraph.prototype.pointClass.prototype, 'renderLink', function (proceed) {
			proceed.call(this);
			this.graphic.element.point = this;
		});
	}

})(Highcharts);
/* global Highcharts module window:true */


const CategoriesFactory = (HC) => {
	/**
	 * Grouped Categories v1.1.0 (2016-06-21)
	 *
	 * (c) 2012-2016 Black Label
	 *
	 * License: Creative Commons Attribution (CC)
	 */

	let UNDEFINED = void 0,
		mathRound = Math.round,
		mathMin = Math.min,
		mathMax = Math.max,
		merge = HC.merge,
		pick = HC.pick,
		each = HC.each,
		// #74, since Highcharts 4.1.10 HighchartsAdapter is only provided by the Highcharts Standalone Framework
		inArray = ((window as any).HighchartsAdapter && (window as any).HighchartsAdapter.inArray) || HC.inArray,

		// cache prototypes
		axisProto = HC.Axis.prototype,
		tickProto = HC.Tick.prototype,

		// cache original methods
		protoAxisInit = axisProto.init,
		protoAxisRender = axisProto.render,
		protoAxisSetCategories = axisProto.setCategories,
		protoTickGetLabelSize = tickProto.getLabelSize,
		protoTickMoveLabel = tickProto.moveLabel,
		protoTickAddLabel = tickProto.addLabel,
		protoTickDestroy = tickProto.destroy,
		protoTickRender = tickProto.render;

	/**
	 *  Ticket : UX-88
	 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
	 *  Description: Initializing cumulativeLeavesCount and i for placing group level horizontal and vertical axis
	 */
	let i = 0;
	let cumulativeLeavesCount = 0;

	function deepClone(thing): any {
		return JSON.parse(JSON.stringify(thing));
	}

	function Category(obj, parent): void {
		this.userOptions = deepClone(obj);
		this.name = obj && obj.name || obj;
		this.parent = parent;

		return this;
	}

	Category.prototype.toString = function (): any {
		return this.name || '';
	};

	// returns sum of an array
	function sum(arr): number {
		let l = arr.length,
			x = 0;

		while (l--) {
			x += arr[l];
		}

		return x;
	}

	// Adds category leaf to array
	function addLeaf(out, cat, parent): void {
		out.unshift(new Category(cat, parent));

		while (parent) {
			parent.leaves = parent.leaves ? (parent.leaves + 1) : 1;
			parent = parent.parent;
		}
	}

	// Builds reverse category tree
	function buildTree(cats, out, options, parent?, depth?): void {
		let len = cats.length,
			cat;

		depth = depth ? depth : 0;
		options.depth = options.depth ? options.depth : 0;

		while (len--) {
			cat = cats[len];

			if (cat && cat.categories) {
				if (parent) {
					cat.parent = parent;
				}
				buildTree(cat.categories, out, options, cat, depth + 1);
			} else {
				addLeaf(out, cat, parent);
			}
		}
		options.depth = mathMax(options.depth, depth);
	}

	// Pushes part of grid to path
	function addGridPart(path, d, width): void {

		// Based on crispLine from HC (#65)
		if (d[0] === d[2]) {

			d[0] = d[2] = mathRound(d[0]) - (width % 2 / 2);
		}
		if (d[1] === d[3]) {

			d[1] = d[3] = mathRound(d[1]) + (width % 2 / 2);
		}

		path.push([
			'M',
			d[0], d[1],
			'L',
			d[2], d[3]
		]);
	}

	// Returns tick position
	function tickPosition(tick, pos): any {
		return tick.getPosition(tick.axis.horiz, pos, tick.axis.tickmarkOffset);
	}

	function walk(arr, key, fn): void {
		let l = arr.length,
			children;

		while (l--) {
			children = arr[l][key];

			if (children) {
				walk(children, key, fn);
			}
			fn(arr[l]);
		}
	}

	//
	// Axis prototype
	//

	axisProto.init = function (chart, options): void {
		// default behaviour
		protoAxisInit.call(this, chart, options);

		if (typeof options === 'object' && options.categories) {
			this.setupGroups(options);
		}
	};

	// setup required axis options
	axisProto.setupGroups = function (options): void {
		let categories,
			reverseTree = [],
			stats = {
				depth: undefined
			};

		categories = deepClone(options.categories);

		// build categories tree
		buildTree(categories, reverseTree, stats);

		// set axis properties
		this.categoriesTree = categories;
		this.categories = reverseTree;
		this.isGrouped = stats.depth !== 0;
		this.labelsDepth = stats.depth;
		this.labelsSizes = [];
		this.labelsGridPath = [];
		this.tickLength = options.tickLength || this.tickLength || null;
		// #66: tickWidth for x axis defaults to 1, for y to 0
		this.tickWidth = pick(options.tickWidth, this.isXAxis ? 1 : 0);
		this.directionFactor = [-1, 1, 1, -1][this.side];

		this.options.lineWidth = pick(options.lineWidth, 1);
	};


	// if secondary grouping label rotation has changed, adjust positioning
	axisProto.adjustSecondaryGroupingLabels = function(): void {
		if (this.horiz && (this.labelRotation!==this.lastRotation)) {
			if (this.labelAlign==='right' && !this.labelRotation) {
				delete this.options.labels.align;
				this.options.labels.x = 0;
			} else {
				this.options.labels.align='right';
				this.options.labels.x = 3;
			}
			this.lastRotation = this.labelRotation;
		}
		if (this.horiz && !this.labelRotation && !this.lastRotation) {
			delete this.options.labels.align;
			this.options.labels.x = 0;
		}
	};


	axisProto.render = function (): boolean | void {
		// clear grid path
		if (this.isGrouped) {
			this.labelsGridPath = [];
		}

		// cache original tick length
		if (this.originalTickLength === UNDEFINED) {
			this.originalTickLength = this.options.tickLength;
		}

		// use default tickLength for not-grouped axis
		// and generate grid on grouped axes,
		// use tiny number to force highcharts to hide tick
		this.options.tickLength = this.isGrouped ? 0.001 : this.originalTickLength;

		// update label options depending on rotation
		this.adjustSecondaryGroupingLabels();

		protoAxisRender.call(this);

		if (!this.isGrouped) {
			if (this.labelsGrid) {
				this.labelsGrid.attr({
					visibility: 'hidden'
				});
			}
			return false;
		}

		let axis = this,
			options = axis.options,
			top = axis.top,
			left = axis.left,
			right = left + axis.width,
			bottom = top + axis.height,
			visible = axis.hasVisibleSeries || axis.hasData,
			depth = axis.labelsDepth,
			grid = axis.labelsGrid,
			horiz = axis.horiz,
			d = axis.labelsGridPath,
			i = options.drawHorizontalBorders === false ? (depth + 1) : 0,
			offset = axis.opposite ? (horiz ? top : right) : (horiz ? bottom : left),
			tickWidth = axis.tickWidth,
			part,
			horizTickPosition = tickPositionSet('midTickHorizontal', axis),
			vertTickPosition = tickPositionSet('midTickVertical', axis);

		if (axis.userTickLength) {
			depth -= 1;
		}

		// render grid path for the first time
		if (!grid) {
			grid = axis.labelsGrid = axis.chart.renderer.path()
				.attr({
					// #58: use tickWidth/tickColor instead of lineWidth/lineColor:
					'stroke-width': tickWidth, // 4.0.3+ #30
					stroke: options.tickColor,
					class: 'highcharts-grid-line'
				})
				.add(axis.axisGroup);
		}

		// go through every level and draw horizontal grid line

		while (i <= depth) {

			offset += axis.groupSize(i);

			/**
			 *  Ticket : UX-88
			 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
			 *  Description : Calculating tickPositions based on horizontal and vertical groupings and placing it as per the calculation.
			 */

			part = horiz ?
				[left, offset, right - horizTickPosition, offset] :
				[offset, top, offset, bottom - vertTickPosition];

			/**
			 *  Ticket : UX-88
			 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
			 *  Description: To draw horizontal line only below group label, no horizontal line between group-labels and x-labels
			 */

			if (i == 2) {
				addGridPart(d, part, tickWidth);
			}
			i++;
		}


		// clean up paths to only render unique items
		let uniquePaths = _.chain(d)
			.uniq(path => path.join(' '))
			.flatten()
			.value();

		// draw grid path
		grid.attr({
			d: uniquePaths,
			visibility: visible ? 'visible' : 'hidden'
		});

		axis.labelGroup.attr({
			visibility: visible ? 'visible' : 'hidden'
		});


		walk(axis.categoriesTree, 'categories', function (group): boolean {
			let tick = group.tick;

			if (!tick) {
				return false;
			}
			if (tick.startAt + tick.leaves - 1 < axis.min || tick.startAt > axis.max) {
				tick.label.hide();
				tick.destroyed = 0;
			} else {
				tick.label.attr({
					visibility: visible ? 'visible' : 'hidden'
				});
			}
			return true;
		});
		return true;
	};

	axisProto.setCategories = function (newCategories, doRedraw): void {
		if (this.categories) {
			this.cleanGroups();
		}
		this.setupGroups({
			categories: newCategories
		});
		this.categories = this.userOptions.categories = newCategories;
		protoAxisSetCategories.call(this, this.categories, doRedraw);
	};

	// cleans old categories
	axisProto.cleanGroups = function (): void {
		let ticks = this.ticks,
			n;

		for (n in ticks) {
			if (ticks[n].parent) {
				delete ticks[n].parent;
			}
		}
		walk(this.categoriesTree, 'categories', (group): boolean => {
			let tick = group.tick;

			if (!tick) {
				return false;
			}
			tick.label.destroy();

			each(tick, (v, i) => {
				delete tick[i];
			});
			delete group.tick;

			return true;
		});
		this.labelsGrid = null;
	};

	// keeps size of each categories level
	axisProto.groupSize = function (level, position): number {
		let positions = this.labelsSizes,
			direction = this.directionFactor,
			groupedOptions = this.options.labels.groupedOptions ? this.options.labels.groupedOptions[level - 1] : false,
			userXY = 0;

		if (groupedOptions) {
			if (direction === -1) {
				userXY = groupedOptions.x ? groupedOptions.x : 0;
			} else {
				userXY = groupedOptions.y ? groupedOptions.y : 0;
			}
		}

		if (position !== UNDEFINED) {
			positions[level] = mathMax(positions[level] || 0, position + 10 + Math.abs(userXY));
		}

		if (level === true) {
			return sum(positions) * direction;
		} else if (positions[level]) {
			return positions[level] * direction;
		}

		return 0;
	};

	//
	// Tick prototype
	//

	// override moveLabel method
	tickProto.moveLabel = function(str, labelOptions): void {
		if (!this.isNewLabel) {
			protoTickMoveLabel.call(this, str, labelOptions);
		}
	};

	// Override methods prototypes
	tickProto.addLabel = function (): boolean {
		let category;

		protoTickAddLabel.call(this);

		if (!this.axis.categories || !(category = this.axis.categories[this.pos])) {
			return false;
		}

		// set label text - but applied after formatter #46
		if (this.label) {
			this.label.attr('text', this.axis.userOptions.labels.formatter.call({
				axis: this.axis,
				chart: this.axis.chart,
				isFirst: this.isFirst,
				value: category.name
			}));
			this.label.textPxLength = this.label.element.getBBox().width;
		}

		// create elements for parent categories
		if (this.axis.isGrouped && this.axis.options.labels.enabled) {
			this.addGroupedLabels(category);
		}
		return true;
	};

	// render ancestor label
	tickProto.addGroupedLabels = function (category): void {
		let tick = this,
			axis = this.axis,
			chart = axis.chart,
			options = axis.options.labels,
			useHTML = options.useHTML,
			css = options.style,
			userAttr = options.groupedOptions,
			attr = {
				align: 'center',
				rotation: options.rotation,
				x: 0,
				y: 0
			},
			size = axis.horiz ? 'height' : 'width',
			depth = 0,
			label;


		while (tick) {
			if (depth > 0 && !category.tick) {
				// render label element
				this.value = category.name;
				let name = options.formatter ? options.formatter.call(this, category) : category.name,
					hasOptions = userAttr && userAttr[depth - 1],
					mergedAttrs = hasOptions ? merge(attr, userAttr[depth - 1]) : attr,
					mergedCSS = hasOptions && userAttr[depth - 1].style ? merge(css, userAttr[depth - 1].style) : css;

				// #63: style is passed in CSS and not as an attribute
				delete mergedAttrs.style;

				label = chart.renderer.text(name, 0, 0, useHTML)
					.attr(mergedAttrs)
					.css(mergedCSS)
					.add(axis.labelGroup);

				// tick properties
				tick.startAt = this.pos;
				tick.childCount = category.categories.length;
				tick.leaves = category.leaves;
				tick.visible = this.childCount;
				tick.label = label;
				tick.labelOffsets = {
					x: mergedAttrs.x,
					y: mergedAttrs.y
				};

				// link tick with category
				category.tick = tick;
			}

			// set level size
			let labelRef = tick.label || tick.movedLabel;
			axis.groupSize(depth, labelRef.getBBox()[size]);

			// go up to the parent category
			category = category.parent;

			if (category) {
				tick = tick.parent = category.tick || {};
			} else {
				tick = null;
			}

			depth++;
		}
	};



	function renderStartingBarrier(grid, axis, TOP_OF_CHART, verticalBarrierBottomPoint, xy): void {

		let horiz = axis.horiz,
			tickWidth = axis.tickWidth,
			gridAttrs;

		if (horiz) {
			gridAttrs = [axis.left, TOP_OF_CHART, axis.left, verticalBarrierBottomPoint ];
		} else {
			if (axis.isXAxis) {
				gridAttrs = [(axis.chart.plotBox.x + axis.width+1), axis.chart.plotBox.y, xy.x + axis.groupSize(true), axis.chart.plotBox.y];
			} else {
				gridAttrs = [axis.chart.chartWidth, axis.top + axis.len, xy.x + axis.groupSize(true), axis.top + axis.len];
			}
		}
		addGridPart(grid, gridAttrs, tickWidth);
	}


	function renderBottomBarrier(grid, axis, xy): void {
		let tickWidth = axis.tickWidth;
		let chartBottom = axis.chart.plotTop + axis.chart.plotBox.height;
		let gridAttrs = [(axis.chart.plotBox.x + axis.width+1), chartBottom, xy.x + axis.groupSize(true), chartBottom];

		addGridPart(grid, gridAttrs, tickWidth);
	}


	// set labels position & render categories grid
	tickProto.render = function (index, old, opacity): void {
		protoTickRender.call(this, index, old, opacity);

		if (!this.axis.categories) {
			return;
		}
		let treeCat = this.axis.categories[this.pos];

		let TOP_OF_CHART = 29;

		if (!this.axis.isGrouped || !treeCat || this.pos > this.axis.max) {
			return;
		}

		let tick = this,
			group = tick,
			axis = tick.axis,
			tickPos = tick.pos,
			isFirst = tick.isFirst,
			max = axis.max,
			min = axis.min,
			horiz = axis.horiz,
			grid = axis.labelsGridPath,
			size = axis.groupSize(0),
			tickWidth = axis.tickWidth,
			xy = tickPosition(tick, tickPos),
			/**
			 *  Ticket : UX-88
			 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
			 *  Description : axis.chart.plotTop :For pointing group labels to top of chart.
			 *
			 */
			start = horiz ? (axis.chart.plotTop) * -2 : xy.x,
			baseLine = axis.chart.renderer.fontMetrics(axis.options.labels.style.fontSize).b,
			depth = 1,
			reverseCrisp = ((horiz && xy.x === axis.pos + axis.len) || (!horiz && xy.y === axis.pos)) ? -1 : 0, // adjust grid lines for edges
			gridAttrs,
			lvlSize,
			minPos,
			maxPos,
			attrs,
			bBox;


		let verticalBarrierBottomPoint = xy.y + axis.groupSize(true)-28;
		let horizontalBarrierLeftPoint = xy.x + axis.groupSize(true);

		renderStartingBarrier(grid, axis, TOP_OF_CHART, verticalBarrierBottomPoint, xy);

		let noOfGroups = axis.categoriesTree;

		// Only draw vertical line at the end of group
		/**
		 *  Ticket : UX-88
		 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
		 *  Description : tickPositionSet function for setting position of Horizontal and vertical ticks in group labels.
		 */
		if (noOfGroups[i] && index == ((noOfGroups[i].leaves - 1) + cumulativeLeavesCount )) {
			if (horiz && axis.left < xy.x) {

				let xCoord = xy.x - reverseCrisp - tickPositionSet('midTickHorizontal', axis);
				let yBottom = xy.y + size;

				if (i === noOfGroups.length - 1) {
					xCoord = axis.chart.plotBox.width + axis.chart.plotBox.x;
					yBottom = verticalBarrierBottomPoint;
				}

				addGridPart(grid, [xCoord, TOP_OF_CHART, xCoord, yBottom], tickWidth);
			} else if (!horiz) {
				renderBottomBarrier(grid, axis, xy);
			}
			// for incrementing count based on ticks in each group
			cumulativeLeavesCount = cumulativeLeavesCount + noOfGroups[i].leaves;

			i++;
		}

		// when index reaches its max value the count should be started with zero again
		if (index == axis.dataMax) {
			i = 0;
			cumulativeLeavesCount = 0;
		}

		size = start + size;

		function fixOffset(tCat): number {
			let ret = 0;
			if (isFirst) {
				ret = inArray(tCat.name, tCat.parent.categories);
				ret = ret < 0 ? 0 : ret;
				return ret;
			}
			return ret;
		}


		let horizTickPosition = tickPositionSet('midTickHorizontal', axis),
			vertTickPosition = tickPositionSet('midTickVertical', axis);

		while (group.parent) {
			group = group.parent;

			let fix = fixOffset(treeCat),
				userX = group.labelOffsets.x,
				userY = group.labelOffsets.y;

			minPos = tickPosition(tick, mathMax(group.startAt - 1, min - 1));
			maxPos = tickPosition(tick, mathMin(group.startAt + group.leaves - 1 - fix, max));
			bBox = group.label.getBBox(true);
			lvlSize = axis.groupSize(depth);
			// check if on the edge to adjust
			reverseCrisp = ((horiz && maxPos.x === axis.pos + axis.len) || (!horiz && maxPos.y === axis.pos)) ? -1 : 0;


			let SHOW_COLUMN_LABEL_TOP = false;
			let columnLabelY = SHOW_COLUMN_LABEL_TOP ?
				15 :
				axis.chart.chartHeight - (axis.chart?.legend?.legendHeight ? axis.chart.legend.legendHeight : 20) - 30;

			attrs = horiz ? {
				//To properly align the group label with horizontal center of group
				x: ((minPos.x + maxPos.x) / 2 + userX) - horizTickPosition,
				y: columnLabelY
			} : {
				x: size + lvlSize / 2 + userX,
				y: ((minPos.y + maxPos.y - bBox.height) / 2 + userY + baseLine)
			};

			if (horiz) {
				let minX = tick.isFirst ? minPos.x : attrs.x;
				if (tick.isLast) attrs.x = minX + horizTickPosition;

				if ((tick.isFirst || tick.isLast) && attrs.x < (minPos.x + (horizTickPosition/2))) attrs.x = (minPos.x + (horizTickPosition/2));
				let fullAvailableWidth = maxPos.x - minPos.x;
				group.label.css({'width': (fullAvailableWidth - 10) + 'px', 'text-overflow': 'ellipsis'});
			}

			if (!isNaN(attrs.x) && !isNaN(attrs.y)) {
				group.label.attr(attrs);

				// draw a grid line for groupings in BAR chart if it is not the last grouping item
				if (grid && !horiz && axis.top <= maxPos.y && !tick.isLast) {
					let yPosition = maxPos.y + reverseCrisp;
					addGridPart(grid, [size, yPosition, (axis.width + axis.chart.plotBox.x), yPosition], tickWidth);
				}
			}

			size += lvlSize;
			depth++;
		}
	};


	tickProto.destroy = function (): void {
		let group = this.parent;

		while (group) {
			group.destroyed = group.destroyed ? (group.destroyed + 1) : 1;
			group = group.parent;
		}

		protoTickDestroy.call(this);
	};

	// return size of the label (height for horizontal, width for vertical axes)
	tickProto.getLabelSize = function (): number {
		if (this.axis.isGrouped === true) {
			// #72, getBBox might need recalculating when chart is tall
			let size = protoTickGetLabelSize.call(this) + 10,
				topLabelSize = this.axis.labelsSizes[0];
			if (topLabelSize < size) {
				this.axis.labelsSizes[0] = size;
			}
			return sum(this.axis.labelsSizes);
		}
		return protoTickGetLabelSize.call(this);
	};

	/**
	 *  Ticket : UX-88
	 *  Ticket Title: Improve Bar Graph Secondary Grouping Labels and Placement
	 *  Description : tickPositionSet function for setting position of Horizontal and vertical ticks in group labels.
	 * @param midTick for having a middle tick in group label.
	 * @param axis to get the height and width of the plotting points.
	 * @returns {*} Midtick gives us the value where the ticks need to be provided based on horizontal and vertical grouping of labels.
	 */
	//Created for setting tick posiitons
	let tickPositionSet = function (midTick, axis): number {
		let plotWidth = axis.chart.plotWidth;
		let plotHeight = axis.chart.plotHeight;
		let pointCount = axis.dataMax + 1;
		let middleOfTick = 2;
		if (midTick == 'midTickHorizontal') {
			midTick = (((plotWidth / pointCount) / middleOfTick));
		}
		if (midTick == 'midTickVertical') {
			midTick = (((plotHeight / pointCount) / middleOfTick));
		}
		return midTick;
	};

}

((factory) => {
	if (typeof module === 'object' && module.exports) {
		module.exports = factory;
	} else {
		factory(Highcharts);
	}
})(CategoriesFactory);
