<div>
	<p [i18n]="'administration.samlSPTabTitle'" class="font-bold"></p>
	<p [i18n]="'administration.samlSPTabDescription'"></p>
	<div class="grid-list-tools" [ngBusy]="loading">
		<div class="tools">
			<input
				type="text"
				[disabled]="!serviceProviders?.length"
				class="grid-search-bar"
				placeholder="{{'administration.samlSPSearchPlaceholder'|i18n}}"
				attr.aria-label="{{'administration.samlSPSearchPlaceholder'|i18n}}"
				[(ngModel)]="nameSearch">
			<div class="grid-buttons">
				<button
					class="btn btn-primary"
					(click)="createServiceProvider()">
					{{'administration.samlRegisterServiceProvider'|i18n}}
				</button>
			</div>
		</div>
	</div>

	<div [ngBusy]="loading">
		<item-grid
			class="h-100-percent w-100-percent br-grid"
			[treeData]="serviceProviders"
			[gridOptions]="gridOptions"
			[gridFilter]="nameSearch"
			[gridType]="gridType"
			[gridChange]="changedItems">
		</item-grid>
	</div>
</div>