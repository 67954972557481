import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject, SimpleChanges } from '@angular/core';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ChangeUtils } from '@app/util/change-utils';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { IgnoredDashboardFilterTag } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-tag';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DatePeriod } from '@cxstudio/reports/entities/date-period';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DateFiltersOptions, DateFiltersSelectorProperties } from '@cxstudio/reports/settings/date-filters.component';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';

@Component({
	selector: 'report-period-selector',
	templateUrl: './report-period-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportPeriodSelectorComponent implements OnInit, OnChanges {
	@Input() project: WorkspaceProject;
	@Input() properties: WidgetProperties;
	@Input() visualProperties: VisualProperties;
	@Input() templateWidget: boolean;
	@Input() periods: DatePeriod[];
	@Input() historicOptions: boolean;
	@Input() selectorProperties: DateFiltersSelectorProperties;
	@Input() currentPeriodForHistoric: DateFilterMode;

	@Output() selectionChange = new EventEmitter<void>();

	projectTimezone: string;
	period: DatePeriod;
	periodOptions: DateFiltersOptions;

	disabled: boolean;

	constructor(
		private readonly projectContextService: ProjectContextService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly filtersService: FiltersService,
		@Inject('DateRange') private readonly DateRange,
		@Inject('datePeriodUtils') private readonly datePeriodUtils: DatePeriodUtils,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
		@Inject('dashboardFiltersService') private readonly dashboardFiltersService: DashboardFiltersService,
		@Inject('ignoredDashboardFilterService') private readonly ignoredDashboardFilterService: IgnoredDashboardFilterService,
	) {}

	ngOnInit(): void {
		this.initProps();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.initProps();
		}
	}

	private initProps(): void {
		this.initPeriod();

		this.disabled = true;

		let projectTimezonePromise: AssetPromise<string> = this.projectContextService.getProjectTimezone(this.project);

		let dateFiltersPromise: Promise<IFilter[]> = InternalProjectTypes.isStudioAdminProject(this.project.projectId)
			? this.filtersService.getStudioDateFilters(this.project)
			: this.reportFiltersService.getWidgetPropertiesDateFilters(this.properties);


		Promise.all([projectTimezonePromise, dateFiltersPromise]).then(result => {
			this.projectTimezone = result[0];
			this.updateCustomDateFilters(result[1]);

			this.disabled = false;
		});
	}

	private initPeriod(): void {
		this.datePeriodUtils.initializePeriods(this.properties, this.periods, this.templateWidget);
		this.period = this.periods[0];
	}

	private updateCustomDateFilters = (dateFilters): void => {
		if (!this.periodOptions) {
			this.periodOptions = {} as any;
		}

		let usedDateFilters = _.chain([this.properties.dateRangeP1, this.properties.dateRangeP2])
			.map(val => val && val.dateFilterMode)
			.filter(val => !!val)
			.value();
		this.periodOptions.dateFilters = this.datePeriodUtils.processDateFilters(dateFilters, usedDateFilters);
	}

	isInheritingDashboardDateFilter = (): boolean => {
		let ignoredFilters: IgnoredDashboardFilterTag[] = this.properties.ignoredDashboardFilters || [];

		return this.dashboardFiltersService.isDashboardDateFilterApplied(this.widgetsEditService.getDashboard())
				&& !this.ignoredDashboardFilterService.hasDateTag(ignoredFilters);
	}

	onFilterChange(dateFilter: DateFilter): void {
		this.period.dateModeSelection(dateFilter.dateFilterMode as any, dateFilter.dateFilterRange, dateFilter.dateDisplayName);
		this.selectionChange.emit();
	}

	periodOptionsFilter = (filter): boolean => {
		if (!InternalProjectTypes.isStudioAdminProject(this.properties.project)) {
			return true;
		}

		return !this.DateRange.isCustomDateRange(filter.value)
			&& _.some(this.DateRange.adminProjectOptions, (option: any) => option.value === filter.value);
	}

	disableDateFilters = (): boolean => {
		return this.properties.lockFilters || this.disabled;
	}

}
