import ILocale from '@cxstudio/interfaces/locale-interface';
import { DateFilterModes } from '@cxstudio/reports/entities/date-filter-mode';

// need to use locale, so can't use an actual constant
// when adding or removing options, be sure to make proper changes in

// com.clarabridge.cxstudio.services.common.StudioDateFilterMode of cbrestfulapi!
export class DateRangeConstantService {
	readonly rangeOptions = _.each({
		LAST_4_HOURS: {
			id: 2,
			value: 'last4h'
		},

		LAST_24_HOURS: {
			id: 3,
			value: 'last24h'
		},

		LAST_3_DAYS: {
			id: 30,
			value: 'last3d'
		},

		LAST_7_DAYS: {
			id: 4,
			value: 'last7d'
		},

		LAST_30_DAYS: {
			id: 5,
			value: 'last30d'
		},

		LAST_90_DAYS: {
			id: 6,
			value: 'last90d'
		},

		LAST_180_DAYS: {
			id: 7,
			value: 'last180d'
		},

		LAST_365_DAYS: {
			id: 8,
			value: 'last365d'
		},

		//For Jan 17, 2017: Jan 1, 2016-Jan 31, 2017
		LAST_13_MONTHS: {
			id: 26,
			value: 'last12m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},
		LAST_14_MONTHS: {
			id: 27,
			value: 'last13m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},
		LAST_15_MONTHS: {
			id: 28,
			value: 'last14m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		PREVIOUS_4_WEEKS: {
			id: 31,
			value: 'previous4w',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth']
		},
		PREVIOUS_12_WEEKS: {
			id: 32,
			value: 'previous12w',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		//For Jan 17, 2017: Jan 1, 2015-Dec 31, 2016
		PREVIOUS_12_MONTHS: {
			id: 29,
			value: 'previous12m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},
		//For Jan 17, 2017: Dec 1, 2015-Dec 31, 2016
		PREVIOUS_13_MONTHS: {
			id: 23,
			value: 'rolling12m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},
		PREVIOUS_14_MONTHS: {
			id: 24,
			value: 'rolling13m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},
		PREVIOUS_15_MONTHS: {
			id: 25,
			value: 'rolling14m',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		LAST_DAY: {
			id: 9,
			value: 'lastd'
		},

		LAST_WEEK: {
			id: 10,
			value: 'lastw',
			disabledHistoricPeriods: ['sameLastWeek']
		},

		LAST_MONTH: {
			id: 11,
			value: 'lastm',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth']
		},

		LAST_QUARTER: {
			id: 12,
			value: 'lastq',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		LAST_YEAR: {
			id: 13,
			value: 'lasty',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		CURRENT_WEEK: {
			id: 14,
			value: 'currentw',
			disabledHistoricPeriods: ['sameLastWeek']
		},

		CURRENT_MONTH: {
			id: 15,
			value: 'currentm',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth']
		},

		CURRENT_QUARTER: {
			id: 16,
			value: 'currentq',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		CURRENT_YEAR: {
			id: 17,
			value: 'currenty',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		YEAR_TO_DATE: {
			id: 18,
			value: 'year_to_date',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth', 'sameLastQuarter']
		},

		QUARTER_TO_DATE: {
			id: 19,
			value: 'quarter_to_date',
			disabledHistoricPeriods: ['sameLastWeek', 'sameLastMonth']
		},

		MONTH_TO_DATE: {
			id: 20,
			value: 'month_to_date',
			disabledHistoricPeriods: ['sameLastWeek']
		},

		WEEK_TO_DATE: {
			id: 21,
			value: 'week_to_date'
		},

		MISS_DATE: {
			id: 22,
			value: 'missDate'
		},

		CUSTOM: {
			id: 0,
			value: 'custom'
		}
	}, (key: any) => {
		key.displayName = this.locale.getString(`dateRange.${key.value}`);
	});

	readonly historicOptions = {

		PREVIOUS_PERIOD: {
			id: 100,
			value: 'previousPeriod',
			displayName: this.locale.getString('dateRange.previousPeriod')
		},
		SAME_LAST_YEAR: {
			id: 101,
			value: 'sameLastYear',
			displayName: this.locale.getString('dateRange.sameLastYear')
		},
		SAME_LAST_QUARTER: {
			id: 102,
			value: 'sameLastQuarter',
			displayName: this.locale.getString('dateRange.sameLastQuarter')
		},
		SAME_LAST_MONTH: {
			id: 103,
			value: 'sameLastMonth',
			displayName: this.locale.getString('dateRange.sameLastMonth')
		},
		SAME_LAST_WEEK: {
			id: 104,
			value: 'sameLastWeek',
			displayName: this.locale.getString('dateRange.sameLastWeek')
		},
		RUNNING_3_MONTHS: {
			id: 105,
			value: 'running3m',
			displayName: this.locale.getString('dateRange.running3m')
		}

	};

	readonly historicOptionsArray = [
		this.historicOptions.PREVIOUS_PERIOD,
		this.historicOptions.SAME_LAST_YEAR,
		this.historicOptions.SAME_LAST_QUARTER,
		this.historicOptions.SAME_LAST_MONTH,
		this.historicOptions.SAME_LAST_WEEK,
		this.historicOptions.RUNNING_3_MONTHS
	];

	readonly mobileOptions = [
		this.rangeOptions.LAST_4_HOURS,
		this.rangeOptions.LAST_24_HOURS,
		this.rangeOptions.LAST_3_DAYS,
		this.rangeOptions.LAST_7_DAYS,
		this.rangeOptions.LAST_30_DAYS,
		this.rangeOptions.LAST_90_DAYS,

		this.rangeOptions.LAST_DAY,
		this.rangeOptions.LAST_WEEK,
		this.rangeOptions.LAST_MONTH,
		this.rangeOptions.LAST_QUARTER,

		this.rangeOptions.CURRENT_WEEK,
		this.rangeOptions.CURRENT_MONTH,
		this.rangeOptions.CURRENT_QUARTER,

		this.rangeOptions.QUARTER_TO_DATE,
		this.rangeOptions.MONTH_TO_DATE,
		this.rangeOptions.WEEK_TO_DATE
	];

	readonly adminProjectOptions = [
		this.rangeOptions.LAST_24_HOURS,
		this.rangeOptions.LAST_3_DAYS,
		this.rangeOptions.LAST_7_DAYS,
		this.rangeOptions.LAST_30_DAYS,
		this.rangeOptions.LAST_90_DAYS
	];

	// deprecated, use `DefaultDateFilterMode` from date-filter-mode.ts
	readonly defaultDateRange = this.rangeOptions.LAST_30_DAYS;

	readonly START_STORED_DATE_RANGE_ID = 2;
	readonly END_STORED_DATE_RANGE_ID = 99;

	readonly options = this.rangeOptions;

	constructor(
		private readonly locale: ILocale
	) {}

	onLanguageChange = (): void => {
		for (let key in this.historicOptions) {
			if (this.historicOptions.hasOwnProperty(key)) {
				const displayName = this.locale.getString(`dateRange.${this.historicOptions[key].value}`);
				this.historicOptions[key].displayName = displayName;
			}
		}

		for (let key in this.rangeOptions) {
			if (this.rangeOptions.hasOwnProperty(key)) {
				const displayName = this.locale.getString(`dateRange.${this.rangeOptions[key].value}`);
				this.rangeOptions[key].displayName = displayName;
			}
		}
	}

	valueOf = (value?) => {
		let k: any = _.findKey(this.rangeOptions, {value});
		if (k) {
			return this.rangeOptions[k];
		}

		k = _.findKey(this.historicOptions, {value});
		if (k) {
			return this.historicOptions[k];
		}
		if (this.isCustomDateRange(value)) {
			return { value };
		}
		return {
			//default to current period
			displayName: this.locale.getString('widget.current_period')
		};
	}

	isCustomDateRange = (value: string) => DateFilterModes.isCustomDateRange(value);

	isStored = (id) => id >= this.START_STORED_DATE_RANGE_ID && id <= this.END_STORED_DATE_RANGE_ID;
}

app.service('DateRange', DateRangeConstantService);
