import { Injector } from '@angular/core';

export const RouteParamsProvider = {
	deps: ['$injector'],
	provide: '$routeParams',
	// tslint:disable-next-line: deprecation
	useFactory: (injector: Injector) => injector.get('$routeParams')
};

export interface IRouteParams {
	[key: string]: string;
}
