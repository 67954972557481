import { Component,	OnInit,	Inject,	Input, ViewChild} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-type.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailablePlaceholdersPipe } from '../available-placeholders.pipe';
import * as cloneDeep from 'lodash.clonedeep';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { TemplatePlaceholderService } from '@cxstudio/dashboard-templates/placeholders/template-placeholder-service.service';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';

@Component({
	selector: 'dashboard-template-create-dialog',
	templateUrl: './dashboard-template-create-dialog.component.html'
})
export class DashboardTemplateCreateDialogComponent implements OnInit {
	@Input('input') input;

	@ViewChild('templateDialogForm', {static: false}) templateDialogForm;

	TemplatePlaceholderType = TemplatePlaceholderType;

	loading;

	model: {
		name: string;
		description: string;
		placeholders: any;
	};

	constructor(
		private readonly modal: NgbActiveModal,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService,
		private readonly widgetApiService: WidgetApiService,
		@Inject('templatePlaceholderService') private readonly templatePlaceholderService: TemplatePlaceholderService,
		@Inject('dashboardTemplateService') private readonly dashboardTemplateService: DashboardTemplateService,
		private readonly availablePlaceholders: AvailablePlaceholdersPipe
	) { }

	ngOnInit(): void {
		this.loading = {
			save: null,
			creatingPlaceholders: null
		};

		this.model = {
			name: this.input.dashboard.name,
			description: '',
			placeholders: null
		};


		this.initPlaceholders();
	}

	save(): void {
		let model = cloneDeep(this.model);

		this.preprocessModel(model);
		this.dashboardTemplateService.addPredefinedDashboardFilters(model, this.input.dashboard);
		this.loading.save = this.dashboardTemplatesApiService
			.createTemplateFromDashboard(this.input.dashboard.id, model)
			.then(() => this.modal.close({}));
	}

	cancel(): void {
		this.modal.dismiss('cancel');
	}

	hasPlaceholders(): boolean {
		let placeholders = this.model.placeholders;
		return placeholders &&
			(this.availablePlaceholders.transform(placeholders.calculations).length > 0
			|| this.availablePlaceholders.transform(placeholders.groupings).length > 0);
	}

	private initPlaceholders(): void {
		this.loading.creatingPlaceholders = this.widgetApiService
			.getDashboardWidgets(this.input.dashboard.id)
			.then(widgets => _.filter(widgets, widget => !ReportConstants.isLegacyWidget(widget)))
			.then(widgets => PromiseUtils.wrap(this.templatePlaceholderService.createPlaceholders(widgets, this.input.dashboard)))
			.then(placeholders => this.model.placeholders = placeholders);
	}

	private preprocessModel(model): void {
		this.preprocessPlaceholders(model.placeholders);
	}

	private preprocessPlaceholders(placeholders): void {
		placeholders.calculations.forEach(this.preprocessPlaceholderName);
		placeholders.groupings.forEach(this.preprocessPlaceholderName);
	}

	private preprocessPlaceholderName(placeholder): void {
		placeholder.displayName = placeholder.displayName || placeholder.originalDisplayName;
	}
}
