import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import Listener from '@cxstudio/common/listener';
import { Errors } from '@cxstudio/common/errors';

interface MobileAppSettingsDialogScope extends ng.IScope {
	$ctrl: MobileAppSettingsSharingController;
	data: {
		settingsModel: MobileAppSettingsListModel,
		saveListener: Listener;
		errors: Errors;
	};
}

export default class MobileAppSettingsSharingController implements ng.IController {

	dialogData: any;
	settingsModel: MobileAppSettingsListModel;
	saveListener: Listener;
	errors: Errors;

	hasInviteError: boolean = false;

	constructor(
		private $scope: MobileAppSettingsDialogScope
	) {
		this.$scope.$ctrl = this;

		this.dialogData = this.$scope.data;
		this.settingsModel = this.$scope.data.settingsModel;
		this.saveListener = this.$scope.data.saveListener;
		this.errors = this.$scope.data.errors;
	}

	$onInit = () => {
	}

}

app.controller('MobileAppSettingsSharingCtrl', MobileAppSettingsSharingController);