import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';

export enum CuratedItemType {
	DOCUMENT = 'DOCUMENT',
	VERBATIM = 'VERBATIM',
	SENTENCE = 'SENTENCE'
}

export class CuratedItemTypeUtil {

	static fromPreviewMode(previewMode: PreviewMode): CuratedItemType {
		if (previewMode === PreviewMode.DOCUMENT) {
			return CuratedItemType.DOCUMENT;
		} else if (previewMode === PreviewMode.VERBATIM) {
			return CuratedItemType.VERBATIM;
		} else {
			return CuratedItemType.SENTENCE;
		}
	}

}

export interface CuratedItem {
	type: CuratedItemType;
	sentenceId?: number;
	verbatimId?: number;
	documentId?: number;
}