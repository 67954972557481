import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { SpotCheckKeyboardSelector } from './spot-check-keyboard-selector';
import { SpotCheckModalKeyboardUtils } from './spot-check-modal-keyboard-utils';

export class SpotCheckResultItemKeyboardUtils {

	static processAffectedWidgetsContainerKeydown(
		event: KeyboardEvent, spotCheckItem: HTMLElement, affectedWidgetsContainer: HTMLElement
	): void {
		let target: EventTarget = event.target;

		if (KeyboardUtils.isEventKey(event, Key.ENTER) && target === affectedWidgetsContainer) {
			KeyboardUtils.intercept(event);
			SpotCheckResultItemKeyboardUtils.getAffectedWidgetsPanelElements(spotCheckItem).first().trigger('focus');
		} else if (KeyboardUtils.isEventKey(event, Key.TAB) && target === affectedWidgetsContainer) {
			KeyboardUtils.intercept(event);
			let panelElements: JQuery = SpotCheckResultItemKeyboardUtils.getAffectedWidgetsPanelElements(spotCheckItem);
			let lastPanelElement: HTMLElement = panelElements.get(panelElements.length - 1) as HTMLElement;

			let modalElements: JQuery = SpotCheckModalKeyboardUtils.getAllFocusableModalElements();
			let lastModalElement: HTMLElement = modalElements.get(modalElements.length - 1) as HTMLElement;

			if (lastPanelElement === lastModalElement) {
				modalElements.first().trigger('focus');
			} else {
				let lastPanelElementIndex: number = _.findIndex(modalElements, (modalElement) => {
					return modalElement === lastPanelElement;
				});

				(modalElements.get(lastPanelElementIndex + 1) as HTMLElement).focus();
			}
		}
	}

	static processAffectedWidgetsPanelKeydown(event: KeyboardEvent, spotCheckItem: HTMLElement): void {
		let target: EventTarget = event.target;

		let panelElements: JQuery = SpotCheckResultItemKeyboardUtils.getAffectedWidgetsPanelElements(spotCheckItem);
		let panelElementsCount: number = panelElements.length;

		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			KeyboardUtils.intercept(event);
			$(SpotCheckKeyboardSelector.AFFECTED_WIDGETS_CONTAINER_SELECTOR).trigger('focus');
		} else if (KeyboardUtils.isEventKey(event, Key.TAB) && panelElementsCount && target === panelElements[panelElementsCount -1]) {
			KeyboardUtils.intercept(event);
			panelElements.first().trigger('focus');
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT) && panelElementsCount && target === panelElements[0]) {
			KeyboardUtils.intercept(event);
			panelElements.last().trigger('focus');
		}
	}

	private static getAffectedWidgetsPanelElements(spotCheckItem: HTMLElement): JQuery {
		return $(spotCheckItem).find(`${SpotCheckKeyboardSelector.COLLAPSING_PANEL_SELECTOR} :focusable`);
	}
}
