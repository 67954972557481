import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';

@Component({
	selector: 'color-picker-palettes',
	templateUrl: './color-picker-palettes.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerPalettesComponent {
	@Input() palettes: WidgetColorPalette[];
	@Input() selectedColor: string;
	@Output() colorSelect = new EventEmitter<string>();
}
