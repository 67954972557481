window.CONFIG = {
    "log": false,
    "backend": "cxstudio",
    "gaKey": "UA-113182221-1",
    "mapboxToken": "pk.eyJ1IjoiY2xhcmFicmlkZ2UiLCJhIjoiY2tjd2YzbGJzMGQ3YTM1bnlhMWIxOTlhciJ9.SVGaUZda9siLhEU7_2m_EQ",
    "mapboxWorkerUrl": "mapbox-gl-csp-worker.js",
    "agGridKey": "CompanyName=Qualtrics,LicensedApplication=Qualtrics,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-034907,SupportServicesEnd=15_November_2023_[v2]_MTcwMDAwNjQwMDAwMA==ba4984a3afbaea9e14790da8c2381e64",
    "dev": true,
    "apiVersion": "221_63581",
    "apiPrefix": "v221_63581/api",
    "commonApiPrefix": "api",
    "version": "7.0.1.221",
    "revision": "63581"
}