import { Directive, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
	selector: '[ngModel][trim]'
})
export class TrimDirective {

	constructor(private model: NgModel, private ref: ChangeDetectorRef) { }

	@HostListener('blur')
	onBlur() {
		const value = this.model.model;
		if (typeof value === 'string') {
			this.model.update.emit(value.trim());
			setTimeout(() => this.ref.markForCheck());
		}
	}

}
