import { DashboardFilterTypes } from './dashboard-filter-types-constant';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { EnrichmentAttributeSettings } from '@cxstudio/reports/document-explorer/doc-explorer-attribute-settings.interface';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';

export class DashboardFilterSelection {
	filterType: DashboardFilterTypes;
	name: string;
	displayName: string;
	isMetric?: boolean;
	type?: FilterAttributeTypes;
	id?: any;
	matchMode?: FilterMatchModeValue;
	existMatch?: boolean;
	modelMatch?: boolean;
	settings?: EnrichmentAttributeSettings;
	objectType?: AttributeObjectType; // not sure if this is used

	static isTopic = (item: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(item);
		return type === DashboardFilterTypes.TOPIC;
	}

	static isAttribute = (attr: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(attr);
		return type === DashboardFilterTypes.ATTRIBUTE ||
			type === DashboardFilterTypes.SATSCORE;
	}

	static isText = (attr: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(attr);
		return type === DashboardFilterTypes.TEXT;
	}

	static isMetricFilter = (attr: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(attr);
		return type === DashboardFilterTypes.METRIC;
	}

	static isDateRange = (attr: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(attr);
		return type === DashboardFilterTypes.DATE_RANGE;
	}

	static isSavedFilter = (attr: DashboardFilterSelection): boolean => {
		let type = DashboardFilterSelection.getFilterType(attr);
		return type === DashboardFilterTypes.SAVED_FILTER;
	}

	static getFilterType = (filter: DashboardFilterSelection): DashboardFilterTypes => {
		if (!filter) { return; }

		let filterType = filter.filterType;
		if (!filterType) {
			if (filter.isMetric) {
				filterType = DashboardFilterTypes.METRIC;
			} else {
				filterType = DashboardFilterTypes.ATTRIBUTE;
			}
		} else if (filterType === DashboardFilterTypes.ATTRIBUTE
				&& filter.type === FilterAttributeTypes.SAT) {
			filterType = DashboardFilterTypes.SATSCORE;
		}
		return filterType;
	}
}
