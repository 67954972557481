import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AlertSubscriptionTemplateCaseSettings } from './alert-subscription-template-case-settings';

export interface IAlertSubscriptionTemplateMetadata {
	attributes: any[];
	models: any[];
}

export interface IXflowMetadata {
	outcomeId: string;
	webhookUrl: string;
}

export enum AlertSubscriptionTemplateType {
	NEW_VERBATIM = 'NEW_VERBATIM',
	SCORECARD = 'SCORECARD',
	METRIC = 'METRIC',
	PRODUCT_FEEDBACK = 'PRODUCT_FEEDBACK',
	VERBATIM = 'VERBATIM',
	TICKETING = 'TICKETING'
}

export default interface IAlertSubscriptionTemplate {
	id?: number;
	ulid?: string;
	enabled: boolean;
	type: AlertSubscriptionTemplateType;
	name: string;
	masterAccountId: number;
	contentProviderId: number;
	accountId: number;
	projectId: number;
	workspaceProject: WorkspaceProject;
	owner: string;
	createdTime: Date | string;
	updatedTime: Date | string;
	topicId: number;
	attributes: any[];
	caseMetadata: IAlertSubscriptionTemplateMetadata;
	xflowMetadata?: IXflowMetadata;
	mentionMetadata: IAlertSubscriptionTemplateMetadata;
	
	caseSettings: AlertSubscriptionTemplateCaseSettings;
	description: string;
	notes?: string;

	scorecardId?: number;

	updateCases?: boolean;
	disabled?: boolean;
}
