import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { UIOption } from '@clarabridge/unified-angular-components';
import { SelectorDisplaySize } from './selector-display-size.class';
@Component({
	selector: 'selector-display-size',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<cb-radio-group
		id="displaySize"
		inline="true"
		[disabled]="disabled"
		[options]="options"
		[ngModel]="value"
		(ngModelChange)="valueChange.emit($event)">
	</cb-radio-group>`
})
export class SelectorDisplaySizeComponent {

	@Input() value: SelectorDisplaySize;
	@Input() disabled: boolean;
	@Output() valueChange = new EventEmitter<SelectorDisplaySize>();
	constructor(private locale: CxLocaleService) {
	}
	readonly options: UIOption<SelectorDisplaySize>[] = [
			{value: SelectorDisplaySize.EQUAL_SIZE, displayName: this.locale.getString('selectorWidget.equalSize')},
			{value: SelectorDisplaySize.RESPONSIVE, displayName: this.locale.getString('selectorWidget.responsive')},
			{value: SelectorDisplaySize.JUSTIFY, displayName: this.locale.getString('common.justify')}
		];

}

app.directive('selectorDisplaySize', downgradeComponent({component: SelectorDisplaySizeComponent}) as angular.IDirectiveFactory);
