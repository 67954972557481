export enum CalculationFunction {
	AVG = 'avg',
	SUM = 'sum',
	MIN = 'min',
	MAX = 'max',
	COUNT = 'count',
	COUNT_DISTINCT = 'distinct',
	STANDARD_DEVIATION = 'stdev',
	VARIANCE = 'variance',
	SUM_OF_SQUARES = 'sumsquares',
	LAST_VALUE = 'lastValue'
}