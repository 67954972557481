import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ToggleCellParams {
	action(item, value): Promise<unknown>;
	isDisabled(item): boolean;
}

@Component({
	selector: 'toggle-renderer',
	template: `
<cb-toggle
	class="h-100-percent d-flex align-items-center ml-4 mr-8"
	[(ngModel)]="data[property]"
	[disabled]="isDisabled(data)"
	(ngModelChange)="onChange($event)">
</cb-toggle>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleRendererComponent implements ICellRendererAngularComp {

	params: Partial<ToggleCellParams>;
	data: unknown;
	property: string;

	refresh(params: ICellRendererParams): boolean {
		this.agInit(params);
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.data = params.data;
		this.property = params.colDef.field;
		this.initParams(params.colDef.cellRendererParams);
	}

	private initParams(params: Partial<ToggleCellParams>) {
		this.params = params || {};
	}

	onChange(value: boolean): void {
		if (!this.params.action)
			return;
		this.params.action(this.data, value).then(
			() => {},
			() => {
				// rollback
				this.data[this.property] = !value;
			}
		);
	}

	isDisabled(data: unknown): boolean {
		return this.params?.isDisabled?.(data);
	}

}
