export enum TimePrimaryGroup {
	YEAR = 'time.year',
	QUARTER = 'time.quarter',
	MONTH = 'time.month',
	WEEK = 'time.week',
	DAY = 'time.day',
	HOUR = 'time.hour',
	WEEK_WITH_DES = 'time.week.des',
}

export enum TrendBy {
	YEAR = 'YEAR',
	QUARTER = 'QUARTER',
	MONTH = 'MONTH',
	WEEK = 'WEEK',
	DAY = 'DAY',
	HOUR = 'HOUR',
	WEEK_WITH_DES = 'WEEK_WITH_DES'
}

export class TimePrimaryGroups {

	static getValues(): TimePrimaryGroup[] {
		return [
			TimePrimaryGroup.YEAR,
			TimePrimaryGroup.QUARTER,
			TimePrimaryGroup.MONTH,
			TimePrimaryGroup.WEEK,
			TimePrimaryGroup.DAY,
			TimePrimaryGroup.HOUR,
		];
	}

	static isYear(name): boolean {
		return TimePrimaryGroup.YEAR === name;
	}

	static isWeek(name): boolean {
		return TimePrimaryGroup.WEEK === name;
	}

	static isQuarter(name): boolean {
		return TimePrimaryGroup.QUARTER === name;
	}

	static isMonth(name): boolean {
		return TimePrimaryGroup.MONTH === name;
	}

	static isDay(name): boolean {
		return TimePrimaryGroup.DAY === name;
	}
}