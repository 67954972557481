import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { MetricMultipliersService } from '@app/modules/widget-visualizations/formatters/metric-multipliers.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { NumberFormatSettings } from '../entities/settings.interfaces';
import { ObjectUtils } from '@app/util/object-utils';

@Component({
	selector: 'number-format',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './number-format.component.html'
})
export class NumberFormatComponent implements OnInit {
	@Input() formatObject: NumberFormatSettings;
	@Input() defaultFormat: NumberFormatSettings;
	@Input() readOnly: boolean;
	@Input() inheritFormatFromMetric: boolean;
	@Input() inheritMetric: NumberFormatSettings;
	@Input() simplePreview: boolean = false;
	@Input() mustInherit: boolean; // settings must be inherited from other calculation selection
	@Output() formatObjectChange = new EventEmitter<NumberFormatSettings>();
	@Output() inheritFormatFromMetricChange = new EventEmitter<boolean>();

	options: {
		decimals: ListOption<number>[],
		truncation: ListOption<string>[],
		conversions: ListOption<string>[]
	};

	formatObjectForPreview: NumberFormatSettings; // used to trigger change detection in preview component

	constructor(
		private locale: CxLocaleService,
		private MetricMultipliers: MetricMultipliersService
	) {}

	ngOnInit(): void {
		this.formatObject = this.formatObject || {} as NumberFormatSettings;
		this.options = {
			decimals: [],
			truncation: [],
			conversions: []
		};
		this.readOnly = this.readOnly !== undefined ? this.readOnly : false;

		this.formatObject.truncation = this.formatObject.truncation || Truncation.NONE;
		this.formatObject.decimals = this.formatObject.decimals || Decimals.NONE;
		this.formatObject.conversion = this.formatObject.conversion || MetricMultiplierType.NONE;

		this.options.truncation = [
			{name: this.locale.getString('widget.none'), value: Truncation.NONE},
			{name: this.locale.getString('widget.thousands'), value: Truncation.THOUSANDS},
			{name: this.locale.getString('widget.millions'), value: Truncation.MILLIONS}
		];

		this.options.conversions = this.MetricMultipliers.options.map(multiplier => {
			return {
				value: multiplier.name,
				name: multiplier.displayName
			};
		});

		this.updatePrecisionOptions();
	}

	// get display names for precision options
	updatePrecisionOptions = () => {
		let decimalDelimiter = this.formatObject?.decimalDelimiter !== undefined
			? this.formatObject.decimalDelimiter
			: this.locale.getString('widget.decimalPoint');

		this.options.decimals = [
			{name: this.locale.getString('widget.noDecimal'), value: Decimals.NONE},
			{name: `0${decimalDelimiter}0`, value: Decimals.ONE},
			{name: `0${decimalDelimiter}00`, value: Decimals.TWO},
			{name: `0${decimalDelimiter}000`, value: Decimals.THREE}
		];

		this.changeHandler();
	}

	editThousandsSeparator = () => {
		this.formatObject.thousandsDelimiter = this.locale.getString('widget.delimiter');
	}

	editDecimalSeparator = () => {
		this.formatObject.decimalDelimiter = this.locale.getString('widget.decimalPoint');
	}

	changeHandler = () => {
		this.formatObjectForPreview = ObjectUtils.copy(this.formatObject);
		this.formatObjectChange.emit(this.formatObject);
	}

	updateInheritFormatFromMetric = (value: boolean) => {
		this.inheritFormatFromMetricChange.emit(value);
	}

	viewOnly = (): boolean => this.readOnly || this.inheritFormatFromMetric;

	/*
	resetThousandsSeparator = () => {
		this.formatObject.thousandsDelimiter = undefined;
	}

	resetDecimalSeparator = () => {
		this.formatObject.decimalDelimiter = undefined;
	}
	*/
}

app.directive('numberFormat', downgradeComponent({component: NumberFormatComponent}));
