import { Component, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'toggle-lock',
	template: `
	<span *ngIf="locked" (click)="onIconClick()" class="q-icon-lock"></span>
	<span *ngIf="!locked" (click)="onIconClick()" class="q-icon-unlock"></span>`
})
export class ToggleLockComponent {
	@Input() locked: boolean;
	@Output() lockedChange = new EventEmitter<boolean>();

	onIconClick = (): void => {
		this.locked = !this.locked;
		this.lockedChange.emit(this.locked);
	}

}

app.directive('toggleLock', downgradeComponent({component: ToggleLockComponent}));
