import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { UserSearchPanelComponent } from '@app/modules/user-administration/search-panel/user-search-panel.component';
import { UserSearchAPI } from '@app/modules/user-administration/search-panel/user-search-api.service';
import { PermissionsComponent } from '@app/modules/user-administration/permissions/permissions.component';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';
import { AdminOrgPermissionsComponent } from '@app/modules/user-administration/system-permissions/admin-org-permissions.component';
import { CustomerPermissionsComponent } from '@app/modules/user-administration/system-permissions/customer-permissions.component';
import { SystemPermissionsService } from '@app/modules/user-administration/system-permissions/system-permissions.service';
import { UserManageApi } from '@app/modules/user-administration/editor/user-manage-api.service';
import { UserColumnComponent } from './user-column.component';
import { ContentProviderLinkingComponent } from '@app/modules/user-administration/content-provider-linking/content-provider-linking.component';
import { NgPipesModule } from 'ngx-pipes';
import { TransferDialogComponent } from '@app/modules/user-administration/transfer/transfer-dialog.component';
import { TransferDialogService } from '@app/modules/user-administration/transfer/transfer-dialog.service';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { BulkUserRemoveDialogComponent } from '@app/modules/user-administration/remove/bulk-user-remove-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { WorkspacesProjectsAccessComponent } from '@app/modules/user-administration/editor/workspaces-projects-access/workspaces-projects-access.component';
import { WorkspaceProjectsApi } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-projects.api.service';
import { ProjectsAccessComponent } from './projects-access/projects-access.component';
import { SelectItemsComponent } from '@app/shared/components/select-items/select-items.component';
import { ContentProvidersComponent } from './cb-content-providers/content-providers.component';
import { SideBySideSelectorModalComponent } from '@app/modules/user-administration/groups/side-by-side-selector-modal.component';
import { SideBySideSelectorComponent } from '@app/modules/user-administration/groups/side-by-side-selector.component';
import { UserCreateWizardComponent } from './user-create/user-create-wizard.component';
import { UserCreateMainStepComponent } from './user-create/steps/user-create-main.step.component';
import { WizardModule } from '../wizard/wizard.module';
import { UserModule } from '../user/user.module';
import { UserCreatePermissionsStepComponent } from './user-create/steps/user-create-permissions-step.component';
import { UserCreateGroupsStepComponent } from './user-create/steps/user-create-groups-step.component';
import { UserCreateLinkingStepComponent } from './user-create/steps/user-create-linking-step.component';
import { UserCreateProjectsAccessStepComponent } from './user-create/steps/user-create-projects-access-step.component';
import { UserCreateFinishStepComponent } from './user-create/steps/user-create-finish-step.component';
import { UserTableComponent } from './user-table/user-table.component';
import { LicenseInfoComponent } from './license-info/license-info.component';
import { UserAccountsModalComponent } from './projects-access/user-accounts-modal/user-accounts-modal.component';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { LicenseService } from './license.service';
import { UsersGroupsLogicService } from './services/users-groups-logic.service';
import { UserManagementHelperService } from './user-management-helper.service';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { FormsModule } from '@angular/forms';
import { AlertModule } from '../alert/alert.module';
import { AlertSubscriptionsTableComponent } from './groups/alert-subscription/alert-subscriptions-table.component';
import { AlertHierarchySelectionEditDialogComponent } from './groups/alert-subscription/alert-hierarchy-selection-edit-dialog.component';
import { SubscriptionsService } from './groups/alert-subscription/subscriptions.service';
import { UserUploadPageComponent } from './user-upload/user-upload-page/user-upload-page.component';
import { CollapsingPanelModule } from '@clarabridge/unified-angular-components';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { GroupMembersComponent } from './groups/group-members/group-members.component';
import { AsyncTaskStatusModalComponent } from './async-task-status-modal/async-task-status-modal.component';
import { LicenseApiService } from './services/license-api.service';
import { ProjectAccessDataHelperService } from './projects-access/project-access-data-helper.service';
import { GroupActionsService } from '@app/modules/user-administration/groups/group-actions.service';

@NgModule({
	imports: [
		SharedModule,
		NgPipesModule,
		DialogModule,
		WizardModule,
		UserModule,
		ItemGridModule,
		AlertModule,
		FormsModule,
		CollapsingPanelModule,
		CxFormModule,
	],
	exports: [
		AdminOrgPermissionsComponent,
		CustomerPermissionsComponent,
		PermissionsComponent,
		UserSearchPanelComponent,
		UserColumnComponent,
		WorkspacesProjectsAccessComponent,
		ProjectsAccessComponent,
		ContentProvidersComponent,
		SelectItemsComponent,
		SideBySideSelectorComponent,
		SideBySideSelectorModalComponent,
		UserCreateWizardComponent,
	],
	declarations: [
		AdminOrgPermissionsComponent,
		CustomerPermissionsComponent,
		PermissionsComponent,
		BulkUserRemoveDialogComponent,
		UserSearchPanelComponent,
		UserColumnComponent,
		TransferDialogComponent,
		WorkspacesProjectsAccessComponent,
		ProjectsAccessComponent,
		ContentProvidersComponent,
		ContentProviderLinkingComponent,
		SelectItemsComponent,
		SideBySideSelectorModalComponent,
		SideBySideSelectorComponent,
		UserCreateWizardComponent,
		UserCreateMainStepComponent,
		UserCreatePermissionsStepComponent,
		UserCreateGroupsStepComponent,
		UserCreateLinkingStepComponent,
		UserCreateProjectsAccessStepComponent,
		UserCreateFinishStepComponent,
		UserTableComponent,
		LicenseInfoComponent,
		UserAccountsModalComponent,
		AlertSubscriptionsTableComponent,
		EditUserModalComponent,
		AlertHierarchySelectionEditDialogComponent,
		UserUploadPageComponent,
		GroupMembersComponent,
		AsyncTaskStatusModalComponent,
	],
	providers: [
		PermissionsService,
		SystemPermissionsService,
		TransferDialogService,
		TransferApiService,
		UserManageApi,
		UserSearchAPI,
		WorkspaceProjectsApi,
		LicenseService,
		UsersGroupsLogicService,
		UserManagementHelperService,
		SubscriptionsService,
		LicenseApiService,
		ProjectAccessDataHelperService,
		GroupActionsService
	],
})
export class UserAdministrationModule {}
