import * as _ from 'underscore';

import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class WidgetMetricsAccessService {
	constructor(
		@Inject('metricApiService') private metricApiService: MetricManagementApiService,
	) {
	}

	hasPrivateMetrics(widgets: Widget[]): Promise<boolean> {
		let metrics = _.chain(widgets)
			.map(widget => _.union(widget.properties?.selectedAttributes, widget.properties?.selectedMetrics))
			.flatten()
			.filter(asset => asset.type === ReportAssetType.METRIC_STUDIO)
			.unique(asset => asset.id)
			.value();

		if (!metrics.length) {
			return Promise.resolve(false);
		}

		return Promise.resolve(this.metricApiService.verifyMetricsAccess(metrics).then(hasAccess => {
			return _.contains(Object.values(hasAccess), false);
		}) as PromiseLike<any>);
	}
}

app.service('widgetMetricsAccessService', downgradeInjectable(WidgetMetricsAccessService));
