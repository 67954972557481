import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { UniqueNameValidator } from '@app/modules/asset-management/validation/unique-name-validator';
import WorkspaceFiltersApiService from '@app/modules/filter/services/workspace-filters-api.service';
import { IAssetPropertiesComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/asset-properties-component.interface';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { UniqueNameService } from '@cxstudio/common/unique-name-service';
import IFilter from '@cxstudio/report-filters/entity/filter';

@Component({
	selector: 'filter-creation-properties',
	templateUrl: './filter-creation-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCreationPropertiesComponent implements IAssetPropertiesComponent<IFilter> {
	@Output() projectChange = new EventEmitter<WorkspaceProject>();
	@Output() validityChange = new EventEmitter<boolean>();

	template: UnifiedTemplate;
	filter: IFilter;
	invalidNameError: boolean;
	uniqueNameError: boolean;
	private nameValidator: UniqueNameValidator<IFilter>;

	constructor(
		private ref: ChangeDetectorRef,
		private workspaceFiltersApi: WorkspaceFiltersApiService,
		@Inject('uniqueNameService') private readonly uniqueNameService: UniqueNameService,
	) {}

	initTemplate(template: UnifiedTemplate): void {
		this.template = template;
		this.filter = {
			name: template.name,
			workspaceProject: {} as WorkspaceProject
		} as IFilter;
	}

	getAsset(): IFilter {
		return this.filter;
	}

	onProjectChange(): void {
		this.projectChange.emit(this.filter.workspaceProject);
		this.reloadFilters(this.filter.workspaceProject);
	}

	isValid(): boolean {
		return !this.invalidNameError && !this.uniqueNameError && !_.isUndefined(this.uniqueNameError);
	}

	private reloadFilters(project: WorkspaceProject): void {
		if (!WorkspaceProjectUtils.isProjectSelected(project)) {
			delete this.uniqueNameError;
			return;
		}
		this.workspaceFiltersApi.getStudioFilters(project).then(filters => {
			if (this.filter.name === this.template.name) {
				this.filter.name = this.uniqueNameService.resolveUniqueName(_.pluck(filters, 'name'), this.template.name);
			}
			this.nameValidator = new UniqueNameValidator({name: this.filter.name} as IFilter,
				filters, {nameField: 'name'});
			this.checkName();
			this.validityChange.emit(this.isValid());
		});
	}

	checkName(): void {
		this.invalidNameError = !this.filter?.name.trim();
		this.uniqueNameError = this.nameValidator && !this.nameValidator.isUniqueName(this.filter.name);
		this.ref.markForCheck();
	}
}

