import { URLEvent } from '@app/core/cx-event.enum';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IAsset } from '@app/modules/access-management/groups/asset';
import { AssetType } from '@app/modules/access-management/groups/asset-type';
import { ModificationDelta } from '@app/modules/access-management/groups/modification-delta';
import { DependenciesDialogInput } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { AttributeDependencyTypesProvider } from '@app/modules/attribute/services/assets/attribute-dependency-types-provider';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { ModelDependencyTypesProvider } from '@app/modules/model/services/model-dependency-types-provider';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { AttributeActionsService } from '@app/modules/project/attribute/attribute-actions-service';
import { AttributeManagementService } from '@app/modules/project/attribute/attribute-management.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { IAttributeGridColumn } from '@app/modules/project/attribute/attributes-grid-definition.service';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { ModelActionsService } from '@app/modules/project/model/model-actions-service';
import { ModelManagementService } from '@app/modules/project/model/model-management.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { AdminAssetsManagementService } from '@app/modules/project/project-assets-management/project-assets-list/admin-assets-management.service';
import { AssetDefaultsService } from '@app/modules/project/project-assets-management/project-assets-list/asset-defaults.service';
import { ScorecardEditorWizardInput } from '@app/modules/project/scorecard/editor/scorecard-editor-wizard.component';
import { ScorecardEditorWizardService } from '@app/modules/project/scorecard/editor/scorecard-editor-wizard.service';
import { SettingsProperty, SettingsState } from '@app/modules/project/state-changes/settings-state.class';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { Unit } from '@app/modules/units/unit';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProjectData, WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AgeService } from '@app/modules/utils/dates/age.service';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { ObjectListSort } from '@app/shared/components/sort/object-list-sort';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import AttributeGeography from '@cxstudio/attribute-geography/attribute-geography';
import { BoundaryField } from '@cxstudio/attribute-geography/boundary-field';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import ModelGeography from '@cxstudio/attribute-geography/model-geography';
import Authorization from '@cxstudio/auth/authorization-service';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { CxLocaleService } from '@app/core';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { Model } from '@cxstudio/reports/entities/model';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { IAngularEvent } from 'angular';
import * as orderBy from 'lodash.orderby';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ModelIdentifier } from './model-identifier';
import { IProjectSelection } from './project-selection.interface';
import { ProjectTabType } from './project-tab-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { RouteService } from '@cxstudio/services/route-service';

type AnyGeography = AttributeGeography | ModelGeography;

interface ProjectManagementErrors extends ProjectAssetsErrors {
	noManagerAccess?: boolean;
	noManagerAccessScorecard?: boolean;
	noScorecardModel?: boolean;
}

interface ProjectTab {
	id: string;
	type: ProjectTabType;
	name: string;
}

interface ResourceType {
	key: ObjectType;
	displayName: string;
	gridType: GridTypes;
	handler: () => any;
	export?: (project: AccountOrWorkspaceProject, withHidden: boolean, filters: ObjectListFilter[], order: number[]) => Promise<void>;
	actionsService?: any;
	gridColumns: IGridColumn[];
}

export class ProjectsManagementController implements ng.IController {
	project: AccountOrWorkspaceProjectData;
	//Till we adapt user, drill doc-explorer hiding, scorecards, geography, global other, dependencies
	oldProject: IProjectSelection;

	searchText: string;
	resourceTypes: ResourceType[];
	resourceType: ResourceType;
	items: any[];
	rawItems: any[];
	sortedRawItems: any[];
	lastChange: any[];
	gridOptions: SlickgridOptions;
	defaultGridOptions: SlickgridOptions;
	gridType: GridTypes;
	gridColumns: IGridColumn[];
	attributesGridColumns: any[];
	modelsGridColumns: any[];
	scorecardsGridColumns: any[];
	gridMode: GridMode;
	reinitTrigger: number = 0; // remove once ATTRIBUTE_SOURCE_COUNT feature available for all providers
	projects: any[];
	options;
	ui: {
		hideObjects: boolean;
		hasManagerAccess: () => boolean;
		projectTabs: ProjectTab[];
		lastCachedTimestamp?: string;
		filters: ObjectListFilter[];
		sorts: ObjectListSort[];
		selectedTab?: any;
	};
	folderSvc: any;

	loading: ProjectAssetsLoading = {};
	errors: ProjectManagementErrors = {};
	private hideInDrillItems: any;
	private hideInDocExpItems: any;
	private hideInReportingItems: any;
	private hideInDrillItemsMap; //for fast retrival
	private hideInDocExpItemsMap; //for fast retrival
	private hideInReportingItemsMap;
	settingsState: SettingsState;
	isWorkspaceEnabled: boolean;

	private initialGeographies: {[key: string]: AnyGeography} = {};
	private changedGeographies: {[key: string]: AnyGeography} = {};

	visibleAttributeColumns: IGridColumn[] = [];
	visibleModelColumns: IGridColumn[] = [];

	constructor(
		private locale: CxLocaleService,
		private $scope: ISimpleScope,
		private contextMenuTree,
		private attributeDependencyTypesProvider: AttributeDependencyTypesProvider,
		private modelDependencyTypesProvider: ModelDependencyTypesProvider,
		private scorecardsApiService: ScorecardsApiService,
		private cbDialogService: CBDialogService,
		private attributeActionsService: AttributeActionsService,
		private modelActionsService: ModelActionsService,
		private $q: ng.IQService,
		private masterAccountApiService: MasterAccountApiService,
		private ProjectAccessLevels: ProjectAccessLevelItems,
		private $location: ng.ILocationService,
		private authorization: Authorization,
		private betaFeaturesService: BetaFeaturesService,
		private assetDefaultsService: AssetDefaultsService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private FolderService,
		private gridUtils: GridUtilsService,
		private treeService: TreeService,
		private readonly attributeManagementService: AttributeManagementService,
		private readonly modelManagementService: ModelManagementService,
		private $timeout: ng.ITimeoutService,
		private assetAccessApiService: AssetAccessApiService,
		private adminAssetsManagement: AdminAssetsManagementService,
		private geographyApiService: GeographyApiService,
		private geographyOptionsService: GeographyOptionsService,
		private modelsService: ModelsService,
		private routeService: RouteService,
		private scorecardEditorWizardService: ScorecardEditorWizardService,
		private downgradeDialogService: DowngradeDialogService,
		private ageService: AgeService,
		private metricConstants: MetricConstants
	) {}

	$onInit(): void {
		this.loading = {};
		this.project = {} as AccountOrWorkspaceProjectData;
		this.oldProject = {} as IProjectSelection;

		this.items = [];
		this.rawItems = [];

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.gridMode = GridMode.VIEW;
		this.defaultGridOptions = {
			onClick: this.onClick,
			disableInitialSort: true
		};
		this.ui = {
			hideObjects: true,
			hasManagerAccess: this.hasManagerAccess,
			projectTabs: [
				{id: 'available', name: this.locale.getString('administration.available'), type: ProjectTabType.AVAILABLE},
				{id: 'hidden', name: this.locale.getString('administration.hidden'), type: ProjectTabType.HIDDEN}
			] as ProjectTab[],
			filters: [],
			sorts: [],
			selectedTab: {}
		};

		this.ui.selectedTab[ProjectTabType.AVAILABLE] = true;

		this.hideInDrillItemsMap = {};
		this.hideInDocExpItemsMap = {};
		this.hideInReportingItemsMap = {};
		this.settingsState = new SettingsState();
		this.folderSvc = new this.FolderService(FolderTypes.ATTRIBUTE);

		this.$scope.$on('projects:loaded', (event, projects): void => {
			this.projects = projects;
		});

		this.processSelectedTab();
		this.$scope.$on('$routeUpdate', (event) => this.processSelectedTab());

		this.initResourceTypes();

		this.options = {
			SHOW: {text: this.locale.getString('common.show'),  name: 'show-item', func: this.toggleHide},
			HIDE: {text: this.locale.getString('common.hide'), name: 'hide-item', func: this.toggleHide}
		};

		this.$scope.$on(URLEvent.SCOPE_LOCATION_CHANGE_START, (event: IAngularEvent, next, current) => {
			let path = this.$location.url();

			if (!this.hasChanges()) {
				this.$location.url(path);

				return;
			}

			if (event) {
				event.preventDefault();
			}

			this.onCancel().then(() => {
				this.$location.url(path);
			}).catch(() => {});
		});
	}

	isTabSelected = (type: ProjectTabType) => {
		return this.ui.selectedTab[type];
	}

	setTabSelected = (type: ProjectTabType) => {
		this.ui.selectedTab = {};
		this.ui.selectedTab[type] = true;
	}

	selectTab = (type: ProjectTabType) => {
		this.setTabSelected(type);
		this.$timeout(() => {
			if (type === ProjectTabType.AVAILABLE) {
				this.refreshGrid();
			}
		});
		if (this.resourceType.key !== ObjectType.ATTRIBUTE && this.resourceType.key !== ObjectType.MODEL) {
			this.resourceType = this.resourceTypes[0];
			this.resourceTypeChanged();
		}
		this.updatePath();
	}

	private processSelectedTab = (): void => {
		this.routeService.handleSelectedTab(this.ui.projectTabs, (selectedTab) => {
			this.setTabSelected(selectedTab.type);
			this.updatePath();
		}, () => {
			this.updatePath();
		});
	}

	private updatePath = (): void => {
		let selectedTab: ProjectTab = _.find(this.ui.projectTabs, (tab) => this.ui.selectedTab[tab.type]);
		if (selectedTab) {
			this.$location.search({ tab: selectedTab.id });
		}
	}

	private toggleHide = (item: any) => {
		this.resourceType.actionsService.toggleHide(item, this.oldProject);
		this.refreshGrid();
	}

	private initResourceTypes = (): void => {
		this.resourceTypes = [{
			key: ObjectType.ATTRIBUTE,
			displayName: this.locale.getString('common.attributes'),
			gridType: GridTypes.ATTRIBUTES,
			handler: this.getAttributes,
			export: this.attributeManagementService.requestAttributesReport,
			actionsService: this.attributeActionsService,
			gridColumns: this.attributesGridColumns
		}, {
			key: ObjectType.MODEL,
			displayName: this.locale.getString('common.models'),
			gridType: GridTypes.MODELS,
			handler: this.getModels,
			export: this.modelManagementService.requestModelsReport,
			actionsService: this.modelActionsService,
			gridColumns: this.modelsGridColumns
		}];

		if (this.isAuthorizedForScorecards()) {
			this.resourceTypes.push({
				key: ObjectType.SCORECARD,
				displayName: this.locale.getString('scorecards.scorecards'),
				gridType: GridTypes.SCORECARDS,
				handler: this.getScorecards,
				gridColumns: this.scorecardsGridColumns
			});
		}
		this.resourceType = this.resourceTypes[0];
		this.resourceTypeChanged();
	}

	getResourceType = (resourceKey: ObjectType): any => {
		return _.findWhere(this.resourceTypes, {key: resourceKey});
	}

	resourceTypeFilter = (resourceType: any): boolean => {
		return !this.isTabSelected(ProjectTabType.HIDDEN) || resourceType.key !== ObjectType.SCORECARD;
	}

	accountChanged = (newProps: IProjectSelection): void => {
		if (newProps) {
			this.updateProps(newProps);
			this.reinitTrigger++;
		}
	}

	projectChanged = (newProps: IProjectSelection): void => {
		this.cleanProjectErrors();
		if (newProps) {
			this.updateProps(newProps);
		}
		if (!newProps.projectId) {
			this.rawItems = [];
			this.items = [];
			this.refreshGrid();
		} else {
			this.reload();
		}
	}

	private updateProps = (newProps: IProjectSelection): void => {
		this.project = {
			contentProviderId: newProps.contentProviderId,
			accountId: newProps.accountId,
			projectId: newProps.projectId,
			projectName: newProps.projectName
		};
		this.oldProject = {
			contentProviderId: newProps.contentProviderId,
			accountId: newProps.accountId,
			projectId: newProps.projectId,
			projectName: newProps.projectName
		};
	}

	workspaceChanged = (workspace: Unit): void => {
		this.reinitTrigger++;
		this.cleanProjectErrors();
		if (workspace) {
			this.oldProject.contentProviderId = workspace.contentProviderId;
			this.oldProject.accountId = workspace.accountId;
		} else {
			this.oldProject.contentProviderId = -1;
			this.oldProject.accountId = -1;
		}
	}

	workspaceProjectChanged = (newProject?: WorkspaceProjectData): void => {
		this.cleanProjectErrors();
		if (newProject) {
			this.project = newProject;
			this.oldProject.projectId = newProject.projectId;
			this.oldProject.projectName = newProject.projectName;
		}
		if (!WorkspaceTransitionUtils.isProjectSelected(newProject)) {
			this.rawItems = [];
			this.items = [];
			this.refreshGrid();
		} else {
			this.reload();
		}
	}

	private cleanProjectErrors = (): void => {
		this.errors.noManagerAccess = false;
		this.errors.noScorecardModel = false;
		this.errors.noManagerAccessScorecard = false;
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	}

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	}

	resourceTypeChanged = (): void => {
		this.errors = {};
		this.gridType = this.resourceType.gridType;
		this.gridColumns = this.resourceType.gridColumns;
		this.gridOptions = _.extend({}, this.defaultGridOptions);
		this.ui.filters = [];
		this.ui.sorts = [];

		if (WorkspaceTransitionUtils.isProjectSelected(this.project)) this.reload();
	}

	hasProjectsManagementAccess = (): boolean => {
		return WorkspaceTransitionUtils.isProjectSelected(this.project)
			&& this.authorization.hasProjectsManagementAccess();
	}

	private reload = (): void => {
		this.loading.promise = this.resourceType.handler();
		this.loading.promise.then((data) => {
			this.rawItems = data;
			this.processRawItems(this.rawItems);
		});
	}

	private processRawItems = (rawItems: any[]): void => {
		this.sortedRawItems = this.sortAttributes(rawItems);

		let filteredAttributes = _.filter(this.sortedRawItems, (attr: any) => attr.type !== AttributeType.SATSCORE);
		this.items = this.processAttributesWithFolders(filteredAttributes);
		this.refreshGrid();
	}

	private processAttributesWithFolders = (attributes: any[]): any[] => {
		let folders = this.adminAssetsManagement.getAttributeDefaultFoldersOld();
		let tree = this.optionsBuilderProvider.getBuilder()
			.withTemplate(folders)
			.withStandardMetrics(attributes)
			.build();
		_.each(tree, (folder: any) => this.populateParentFolder(folder));

		let flatTree = TreeListTransformUtils.flat(tree);
		let rootLevelAttributes = _.filter(attributes, (attribute) => {
			return !flatTree.some((item) => {
				return !this.isFolder(item) && item.id === attribute.id;
			});
		});
		flatTree.pushAll(rootLevelAttributes);
		return flatTree;
	}

	private populateParentFolder = (item: any): void => {
		if (item.children) {
			_.each(item.children, (child: any) => {
				child.parent = item;
				this.populateParentFolder(child);
			});
		}
	}

	private isFolder = (item: any): boolean => {
		return item && item.type === FolderTypes.ATTRIBUTE;
	}

	private getAttributes = (): any => {
		let attributePromise = PromiseUtils.old(this.attributeManagementService.getAdminAttributes(this.project, this.ui.filters));
		let hideInDrillAttributesPromise = PromiseUtils.old(this.assetAccessApiService.getDrillMenuHiddenAssets(this.project));
		let hideInDocExpAttributesPromise = PromiseUtils.old(this.assetAccessApiService.getDocExplorerHiddenAssets(this.project));
		let hideInReportingAssetsPromise = PromiseUtils.old(this.assetAccessApiService.getReportingAssetAccess(this.project));

		return this.$q.all([
			attributePromise,
			hideInDrillAttributesPromise,
			hideInDocExpAttributesPromise,
			hideInReportingAssetsPromise,
		]).then((response) => {
			//need to transform drill path and parent ids to display names
			let attributes: IAdminAttribute[] = response[0];
			this.hideInDrillItems = response[1];
			this.hideInDocExpItems = response[2];
			this.hideInReportingItems = response[3];

			this.translateWordAttributes(attributes);
			this.populateLevel(attributes);
			this.populateUiStrings(attributes);
			this.populateHiddenTag(attributes);
			this.populateShowInDrill(attributes, this.hideInDrillItems, false);
			this.populateShowInDocExp(attributes, this.hideInDocExpItems, false);
			this.populateShowInReporting(attributes, this.hideInReportingItems, false);
			if (this.isAttributeStatsRequired()) {
				this.refreshAttrStats();
			}
			let enabledForSearchCount = attributes.filter(attr => attr.useInClarabridgeSearch).length;
			this.settingsState.init(enabledForSearchCount);
			return attributes;
		});
	}

	private sortAttributes(attributes: any[]): any[] {
		let validSorts = this.ui.sorts.filter(sort => sort.field && sort.direction);
		let sortFields = validSorts.map(sort => this.getColumnSort(sort.field));
		let sortDirections = validSorts.map(sort => sort.direction.toLowerCase());

		return orderBy(attributes, sortFields, sortDirections);
	}

	private getColumnSort(field: string): any {
		return (attribute) => {
			let fieldValue = attribute[field];
			if (fieldValue && typeof fieldValue === 'string') {
				return fieldValue.toLowerCase();
			} else {
				return fieldValue;
			}
		};
	}

	private translateWordAttributes = (attributesList: any[]): void => {
		attributesList.forEach((attribute) => {
			if (this.metricConstants.isWordAttribute(attribute.name)) {
				attribute.displayName = this.metricConstants.getWordDisplayName(attribute.name);
			}
		});
	}

	private populateLevel = (itemsList: any[]): void => {
		itemsList.forEach((item) => {
			item.level = 0;
		});
	}

	private populateUiStrings = (attributesList: any[]): void => {
		attributesList.forEach((attribute) => {
			attribute.uiType = this.getUiType(attribute);
			let uiParents = this.getUiParents(attribute);
			if (uiParents) {
				attribute.uiParents = uiParents;
			}
		});
	}

	private populateHiddenTag = (itemsList: any[]): void => {
		itemsList.forEach((item) => {
			if (item.hide) {
				TaggingHelper.tag(item, TaggingHelper.tags.HIDDEN);
			}
		});
	}

	private populateShowInDrill = (items: any[], hideInDrillItems, isModel: boolean): void => {
		this.resetHideInItemsMap(this.hideInDrillItemsMap);
		hideInDrillItems.filter(i => !!isModel === !!i.model)
				.map(i => this.hideInDrillItemsMap[this.gridType][i.id] = true);
		items.forEach((item) => {
			if (!this.isFolder(item)) {
				item.showInDrill = !this.hideInDrillItemsMap[this.gridType][item.id];
			}

		});
	}

	private populateShowInDocExp = (items: any[], hideInDocExpItems, isModel: boolean): void => {
		this.resetHideInItemsMap(this.hideInDocExpItemsMap);
		hideInDocExpItems.filter(i => !!isModel === !!i.model)
				.map(i => this.hideInDocExpItemsMap[this.gridType][i.id] = true);
		items.forEach((item) => {
			if (!this.isFolder(item)) {
				item.showInDocExp = !this.hideInDocExpItemsMap[this.gridType][item.id];
			}
		});
	}

	private populateShowInReporting = (items: any[], hideInReportingItems, isModel: boolean): void => {
		this.resetHideInItemsMap(this.hideInReportingItemsMap);
		hideInReportingItems.filter(i => !!isModel === !!(i.assetType === AssetType.MODEL))
				.map(i => this.hideInReportingItemsMap[this.gridType][i.assetId] = true);
		items.forEach((item) => {
			if (!this.isFolder(item)) {
				item.showInReporting = !this.hideInReportingItemsMap[this.gridType][item.id];
			}
		});
	}

	private populateAttrStats = (stats: any[]): void => {
		if (this.isAttributesType() && !_.isEmpty(this.items)) {
			let statsMap = _.indexBy(stats, 'id');
			this.items.forEach((attribute) => {
				_.extend(attribute, statsMap[attribute.id]);
			});
		}
	}

	private resetHideInItemsMap = (itemsMap): void => {
		itemsMap[GridTypes.MODELS] = {};
		itemsMap[GridTypes.ATTRIBUTES] = {};
	}

	private getUiType = (attribute): string | undefined => {
		let type = attribute.type;
		if (_.isUndefined(type)) return;
		//CONST_NAME to camelCase
		let localizationKey = type.toString()
			.toLowerCase()
			.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
		return attribute.derivedFromCategory
			? this.locale.getString('administration.derivedFromCategory')
			: this.locale.getString('administration.' + localizationKey);
	}

	private getUiParents = (attribute): string | undefined => {
		let parents = attribute.parentAttributesNames;
		if (attribute.derivedFromCategory) {
			return attribute.derivedNodeName;
		} else {
			if (_.isUndefined(parents)) return;
			return _.isArray(parents) ? parents.join() : parents;
		}
	}

	private getModels = (): any => {
		let modelPromise = PromiseUtils.old(this.modelManagementService.getAdminModels(this.oldProject, this.ui.filters));
		let hideInDrillAttributesPromise = PromiseUtils.old(this.assetAccessApiService.getDrillMenuHiddenAssets(this.project));
		let hideInDocExpAttributesPromise = PromiseUtils.old(this.assetAccessApiService.getDocExplorerHiddenAssets(this.project));
		let hideInReportingAssetsPromise = PromiseUtils.old(this.assetAccessApiService.getReportingAssetAccess(this.project));

		return this.$q.all([modelPromise, hideInDrillAttributesPromise, hideInDocExpAttributesPromise, hideInReportingAssetsPromise])
				.then((response) => {
					let isModel = true;
					//need to transform drill path and parent ids to display names
					let models: any[] = response[0];
					this.hideInDrillItems = response[1];
					this.hideInDocExpItems = response[2];
					this.hideInReportingItems = response[3];
					this.populateLevel(models);
					this.populateHiddenTag(models);
					this.populateShowInDrill(models, this.hideInDrillItems, isModel);
					this.populateShowInDocExp(models, this.hideInDocExpItems, isModel);
					this.populateShowInReporting(models, this.hideInReportingItems, isModel);
					return models;
				});
	}

	isAttributesType = (): boolean => {
		return this.resourceType && this.resourceType.key === ObjectType.ATTRIBUTE;
	}

	isModelsType = (): boolean => {
		return this.resourceType && this.resourceType.key === ObjectType.MODEL;
	}

	isFilterAndSortEnabled = (): boolean => {
		return WorkspaceTransitionUtils.isProjectSelected(this.project);
	}

	isModelsFilterSortEnabled = (): boolean => {
		return this.isModelsType() && this.isFilterAndSortEnabled();
	}

	isAttributesFilterAndSortEnabled = (): boolean => {
		return this.isAttributesType() && this.isFilterAndSortEnabled();
	}

	isSupportedTab = (tab: ProjectTab): boolean => {
		return tab.type === ProjectTabType.AVAILABLE || this.isAttributesType() || this.isModelsType();
	}


	showLastCachedTimestamp = (): boolean => {
		return this.isAttributeStatsRequired()
			&& WorkspaceTransitionUtils.isProjectSelected(this.project)
			&& this.hasProjectsManagementAccess()
			&& this.isAttributesType();
	}

	isAttributeStatsRequired = (): boolean => {
		return _.some(this.resourceType.gridColumns,
				(column: IAttributeGridColumn) => column.selected === true && column.requireAttrStats);
	}

	refreshAttrStats = (): void => {
		this.loading.statsPromise = this.attributeManagementService
				.getAttributesStats(this.project, this.ui.filters).then((response: any) => {
			let stats = response.attributesStats;
			this.ui.lastCachedTimestamp = this.getLastCachedTimestamp(new Date(response.lastCachedDate));
			this.populateAttrStats(stats);
			this.refreshGrid();
		}) as unknown as ng.IPromise<any>;
	}

	private getLastCachedTimestamp = (lastCachedDate: Date): string => {
		let deltaSeconds = moment(new Date()).diff(lastCachedDate, 'seconds');
		let ageText = deltaSeconds <= 60
			? this.locale.getString('common.lessThanMinute')
			: this.ageService.getAgeText(deltaSeconds);
		return this.locale.getString('attributes.statsCachedTimestamp', { value: ageText });
	}

	// BEGIN SCORECARDS ---------------------------------------------------------------------------
	/* STUDIO-284: show_rubrics_in_projects_page should be off by default */
	private isAuthorizedForScorecards = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.SHOW_RUBRICS_IN_PROJECTS_PAGE)
			&& this.authorization.hasScorecardsAccess();
	}

	isScorecardType = (): boolean => {
		return (this.resourceType && (this.resourceType.key === ObjectType.SCORECARD)) ? true : false;
	}

	private getScorecards = (): ng.IPromise<Scorecard[]> => {
		let getScorecardsPromise = this.scorecardsApiService.getScorecards(
				this.oldProject.contentProviderId, this.oldProject.accountId, this.oldProject.projectId);
		return this.$q.all([this.getScorecardModels(), getScorecardsPromise]).then((response: any[]) => {
			if (response[0]) {
				let models = response[0];
				let scorecards = response[1];
				this.populateLevel(scorecards);
				this.populateCreatorText(scorecards);
				this.populateMetadata(scorecards, models);
				return scorecards;
			}
		});
	}

	private populateCreatorText(scorecards: Scorecard[]): void {
		scorecards.forEach((scorecard: Scorecard) => {
			scorecard.creatorText = scorecard.creatorEmail ? scorecard.creatorEmail : scorecard.creator;
		});
	}

	private populateMetadata(scorecards: Scorecard[], models: Model[]): void {
		scorecards.forEach((card: Scorecard) => {
			if (!_.isEmpty(models)) {
				let model = _.findWhere(models, {id: card.modelId});
				card.categoryModel = model?.name;
			}
			this.populateScoringCriteriaCount(card);
		});
	}

	private populateScoringCriteriaCount(scorecard: Scorecard): void {
		let autoFailCount = 0;
		let weightedCount = 0;
		(scorecard.scorecardTopics || []).forEach((scorecardTopic: ScorecardTopic) => {
			if (scorecardTopic.autoFail) {
				autoFailCount++;
			} else if (scorecardTopic.topicWeight) {
				weightedCount++;
			}
		});
		scorecard.autoFailCriteria = autoFailCount;
		scorecard.scoredCriteria = weightedCount;
	}

	private removeScorecard = (scorecard: Scorecard): void => {
		if (!this.canModifyScorecard()) {
			return;
		}

		const dialog = this.cbDialogService.danger(
			this.locale.getString('scorecards.deleteScorecardsTitle', {name: scorecard.name}),
			this.locale.getString('scorecards.deleteScorecardsText', {name: scorecard.name})
		);

		dialog.result.then(() => {
			this.loading.promise = this.scorecardsApiService.remove(
				this.oldProject.contentProviderId, this.oldProject.accountId, scorecard).then(this.reload);
		});
	}

	private canModifyScorecard = (): boolean => {
		if (!this.hasManagerAccess()) {
			this.errors.noManagerAccessScorecard = true;
			return false;
		}
		return true;
	}

	private toggleActive = (scorecard: Scorecard): void => {
		scorecard.active = !scorecard.active;
		this.loading.promise = this.scorecardsApiService.updateActiveState(this.oldProject.contentProviderId,
			this.oldProject.accountId, scorecard)
				.then(this.reload, () => this.showErrorMessage(scorecard));
	}

	private showErrorMessage = (scorecard: Scorecard): void => {
		this.cbDialogService.warning(
			this.locale.getString('scorecards.enableErrorHeader', { name: scorecard.name }),
			this.locale.getString('scorecards.enableErrorMessage'));
	}

	newScorecard = (): void => {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.errors.noProjectSelected = true;
			return;
		}

		if (!this.canModifyScorecard()) return;

		this.getScorecardModels().then(models => {
			if (models) {
				const newScorecardModalResult = this.openScorecardsModal(CxWizardMode.EDIT, models);
				newScorecardModalResult.then(this.reload);
			}
		});
	}

	private openScorecardsModal = (mode: CxWizardMode, models: Model[], scorecard?: Scorecard): Promise<any> => {
		let input: ScorecardEditorWizardInput = {
			mode,
			models,
			props: this.oldProject,
			scorecard,
			scorecards: this.items
		};

		return this.scorecardEditorWizardService.open(input).result.then(result => result, _.noop);
	}

	private getScorecardModels = (): ng.IPromise<Model[]> => {
		this.errors.noScorecardModel = false;
		let getModelsPromise = this.scorecardsApiService.getScorecardModels(
				this.oldProject.contentProviderId, this.oldProject.accountId, this.oldProject.projectId)
			.then(result => {
				if (_.isEmpty(result)) {
					this.errors.noScorecardModel = true;
					return;
				}
				return result;
			});
		this.loading.promise = getModelsPromise;
		return getModelsPromise;
	}

	private editScorecard = (scorecard: Scorecard): void => {
		this.getScorecardModels().then((models: Model[]) => {
			if (models && models.length) {
				const editScorecardModalResult = this.openScorecardsModal(CxWizardMode.EDIT, models, scorecard);
				editScorecardModalResult.then(this.reload);
			}
		});
	}

	private viewScorecard = (scorecard: Scorecard): void => {
		this.getScorecardModels().then((model) => {
			if (model) {
				this.openScorecardsModal(CxWizardMode.VIEW_LAST_PAGE_ONLY, model, scorecard);
			}
		});
	}

	// END SCORECARDS -----------------------------------------------------------------------------

	private refreshGrid = (): void => {
		// make sure arrays are never the same instance
		this.lastChange = [].concat(this.items);
	}

	onClick = (event, object): void => {
		// single click only on title
		let target = $(event.target);

		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.getContextMenu(object), this.resourceType.key, 360);
		} else if (event.target.type === 'checkbox') {
			if (this.gridUtils.isCheckboxClick(event, 'cell-show-in-drill')) {
				object.showInDrill = !object.showInDrill;
			} else if (this.gridUtils.isCheckboxClick(event, 'cell-show-in-doc-exp')) {
				object.showInDocExp = !object.showInDocExp;
			} else if (this.gridUtils.isCheckboxClick(event, 'cell-show-in-reporting')) {
				object.showInReporting = !object.showInReporting;
			} else if (this.gridUtils.isCheckboxClick(event, 'cell-use-in-clarabridge-search')) {
				let newValue = !object.useInClarabridgeSearch;
				this.settingsState.addChange(object, SettingsProperty.USE_IN_CLARABRIDGE_SEARCH, newValue);
				object.useInClarabridgeSearch = newValue;
				this.refreshGrid();
			}
		} else if (this.gridUtils.isToggleClick(event)) {
			if (GridTypes.SCORECARDS === this.gridType
				&& this.hasManagerAccess()) {
				this.toggleActive(object);
			}
		} else if (target.parents('.cell-geography').length) {
			if (this.gridType === GridTypes.MODELS) {
				this.showModelGeographyOptions(event, object);
			} else if (this.gridType === GridTypes.ATTRIBUTES) {
				this.showAttributeGeographyOptions(event, object);
			}
		}
	}

	private showModelGeographyOptions = (event, model: IAdminModel): void => {
		this.loading.promise = this.getModelDepth(model).then(modelDepth => {
			let geographyOptions = this.geographyOptionsService.getModelOptions(model, modelDepth, this.setModelGeography);
			this.contextMenuTree.show(event, model, geographyOptions, 'geography-menu');
		});
	}

	private getModelDepth = (model): ng.IPromise<number> => {
		return PromiseUtils.old(this.modelsService.getModelTree(this.project, model.id))
			.then(modelTree => modelTree.depth);
	}

	private showAttributeGeographyOptions = (event, attribute): void => {
		if (attribute.type === AttributeType.DATE) {
			return;
		} else {
			let geographyOptions = attribute.type === AttributeType.NUMBER
				? this.geographyOptionsService.getNumericAttributeGeographyOptionsOld(attribute, this.setAttributeGeography)
				: this.geographyOptionsService.getGroupingGeographyOptionsOld(attribute, this.setAttributeGeography);

			this.contextMenuTree.show(event, attribute, geographyOptions, 'geography-menu');
		}
	}

	private setModelGeography = (model: IAdminModel, level: number, value: BoundaryField): void => {
		const identifier = model.id;
		if (!this.initialGeographies[identifier]) {
			this.initialGeographies[identifier] = {
				modelId: identifier,
				boundaryFields: ObjectUtils.copy(model.boundaryFields),
			};
		}
		if (!model.boundaryFields) {
			model.boundaryFields = {};
		}
		const boundaryFields = model.boundaryFields;
		boundaryFields[level] = value;
		this.setGeography(identifier, {
			modelId: identifier,
			boundaryFields
		});
	}

	private setAttributeGeography = (attribute: IAdminAttribute, value: BoundaryField): void => {
		const identifier = attribute.name.toLowerCase();
		if (!this.initialGeographies[identifier]) {
			this.initialGeographies[identifier] = {
				attributeName: identifier,
				boundaryField: attribute.boundaryField,
			};
		}
		attribute.boundaryField = value;
		this.setGeography(identifier, {
			attributeName: identifier,
			boundaryField: value
		});
	}

	private setGeography = (identifier: string | number, geography: AnyGeography): void => {
		this.changedGeographies[identifier] = geography;
		this.refreshGrid();
	}

	private getContextMenu = (object): any[] => {
		let menuItems = [];

		if (GridTypes.SCORECARDS === this.gridType) {
			if (this.hasManagerAccess()) {
				menuItems.push(
					{ name: 'edit', text: this.locale.getString('common.edit'),
						func: this.editScorecard },
					{ name: 'enable',
						text: object.active
							? this.locale.getString('common.disable')
							: this.locale.getString('common.enable'),
						func: this.toggleActive },
					BaseContextMenuUtils.MENU_DIVIDER,
					{ name: 'remove', text: this.locale.getString('common.delete'),
						func: this.removeScorecard },
				);
			} else {
				menuItems.push(
					{ name: 'view', text: this.locale.getString('common.view'),
						func: this.viewScorecard },
				);
			}
		} else {
			let editDefaultItem = this.getEditDefaultOption(object);
			if (editDefaultItem)
				menuItems.push(editDefaultItem);

			menuItems.push(object.hide ? this.options.SHOW : this.options.HIDE);

			if (GridTypes.ATTRIBUTES !== this.gridType || object.name !== '_doc_date') {
				let dependencyTypesProvider = GridTypes.ATTRIBUTES ===  this.gridType
					? this.attributeDependencyTypesProvider
					: this.modelDependencyTypesProvider;
				menuItems.push(
					{ name: 'dependencies', text: this.locale.getString('common.dependencies'),
						func: this.dependenciesFunction(dependencyTypesProvider) },
				);
			}

			if (GridTypes.MODELS === this.gridType) {
				menuItems.push(
					{
						name: 'globalOther', text: this.locale.getString('administration.exploreGlobalOther'),
						func: this.exploreGlobalOther
					});
			}
		}
		return menuItems;
	}

	private getEditDefaultOption = (object: IAdminAttribute): ContextMenuItem<any> | undefined => {
		if (!this.hasManagerAccess()) {
			return;
		}
		let func;
		if (this.gridType === GridTypes.ATTRIBUTES
				&& (object.type === AttributeType.NUMBER || object.type === AttributeType.TEXT
				|| this.metricConstants.isWordAttribute(object.name))) {
			func = (attribute) => this.assetDefaultsService.editAttributeDefaults(attribute, this.project);
		} else if (this.gridType === GridTypes.MODELS) {
			func = (model) => this.assetDefaultsService.editModelDefaults(model, this.project);
		}

		if (func) {
			return { name: 'defaults', text: this.locale.getString('administration.editDefaults'), func };
		}
	}

	private dependenciesFunction = (dependencyTypesProvider): (object: any) => void => {
		return object => {
			let input: DependenciesDialogInput<ProjectAsset> = {
				asset: {
					assetId: object.id,
					name: object.displayName ? object.displayName : object.name,
					contentProviderId: this.oldProject.contentProviderId,
					projectId: this.oldProject.projectId,
					accountId: this.oldProject.accountId
				} as any,
				dependencyTypesProvider
			};

			this.downgradeDialogService.openDependenciesModal(input);
		};
	}

	exportData = (): void => {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.errors.noProjectSelected = true;
			return;
		}

		if (this.isTabSelected(ProjectTabType.HIDDEN)) {
			this.assetAccessApiService.exportHiddenAssetGroupsInfo(this.project, this.resourceType.key)
				.then(this.exportNotify);
			return;
		}

		this.resourceType.export(this.project, !this.ui.hideObjects, this.ui.filters, this.getItemIds()).then(this.exportNotify);
	}

	private getItemIds = (): number[] => {
		return this.sortedRawItems.filter(item => !!item.id && typeof item.id === 'number').map(item => item.id);
	}

	private exportNotify = (): void => {
		let titleKey = 'administration.exportDialogTitle';
		if (this.isTabSelected(ProjectTabType.HIDDEN)) {
			titleKey = 'administration.exportDialogTitleForHidden';
		}

		let dialogMessage = this.locale.getString('administration.exportToExcelMessage');
		let dialogTitle = this.locale.getString(titleKey, {
			projectName: this.project.projectName,
			resourceName: this.resourceType.displayName
		});

		this.cbDialogService.notify(dialogTitle, dialogMessage);
	}

	private getProject = (id: number): any => {
		return _.findWhere(this.projects, {projectId: id});
	}

	isEditMode = (): boolean => {
		return this.gridMode === GridMode.EDIT;
	}

	hasManagerAccess = (): boolean => {
		if (!this.authorization.hasProjectsManagementAccess())
			return false;
		if (this.isWorkspaceEnabled) {
			const projectData = this.project as WorkspaceProjectData;
			return this.ProjectAccessLevels.MANAGER.value === projectData.accessLevel;
		} else {
			let project = this.getProject(this.project.projectId);
			return project && this.ProjectAccessLevels.MANAGER.value === project.accessLevel;
		}
	}

	changeToEditMode = () => {
		if (WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			if (this.hasManagerAccess()) {
				this.settingsState.reset();
				this.gridMode = GridMode.EDIT;
			} else {
				this.errors.noManagerAccess = true;
			}
		} else {
			this.errors.noProjectSelected = true;
		}
	}

	onCancel = (): Promise<void> => {
		return this.cancelChanges().then((result) => {
			if (result === true) {
				this.saveChangesInternal();

				return;
			}

			this.revertChanges();
		});
	}

	cancelChanges = (): Promise<boolean> => {
		if (!this.hasChanges()) {
			this.gridMode = GridMode.VIEW;

			return Promise.resolve(false);
		}

		let dialogMessage = this.locale.getString('administration.projectPropertiesMsg');
		let dialogTitle = this.locale.getString('administration.projectPropertiesTitle');

		return this
			.downgradeDialogService
			.showUnsavedChangesDialog(
				dialogTitle,
				dialogMessage,
				false,
				false
			)
		;
	}

	hasChanges = (): boolean => {
		return !this.isScorecardType()
			&& (this.hasChangesInternal('showInDrill', this.hideInDrillItemsMap)
			|| this.hasChangesInternal('showInDocExp', this.hideInDocExpItemsMap)
			|| this.hasChangesInternal('showInReporting', this.hideInReportingItemsMap))
			|| this.settingsState.hasChanges()
			|| Object.keys(this.changedGeographies).length > 0;
	}

	private hasChangesInternal = (field: string, itemsMap): boolean => {
		return this.items
			.filter(item => !this.isFolder(item))
			.some(i => i[field] !== !itemsMap[this.gridType][i.id]);
	}

	revertChanges = (): void => {
		this.reload();

		this.gridMode = GridMode.VIEW;
	}

	private revertUseInClarabridgeSearch = (): void => {
		this.settingsState.getPropertyInitial(SettingsProperty.USE_IN_CLARABRIDGE_SEARCH).forEach(change => {
			let changedItem = this.items.find(item => item.id === change.id);
			changedItem[SettingsProperty.USE_IN_CLARABRIDGE_SEARCH] = change.value;
		});
	}

	saveChanges = () => {
		if (this.hasChanges()) {
			this.saveChangesInternal();
		} else {
			this.gridMode = GridMode.VIEW;
		}
	}

	private isModel = (): boolean => {
		return this.gridType === GridTypes.MODELS;
	}

	private saveChangesInternal = () => {
		let cpId = this.oldProject.contentProviderId;
		let accountId = this.oldProject.accountId;
		let projectId = this.oldProject.projectId;
		let hideInDocExpItems = this.getHideInDocExpItems();
		this.masterAccountApiService.updateDocExplorerAttributes(cpId, accountId, projectId, hideInDocExpItems);
		let hideInDrillItems = this.getHideInDrillItems();
		this.masterAccountApiService.updateDrillMenuAttributesAndModels(cpId, accountId, projectId, hideInDrillItems);
		let hideInReportingItems = this.getHideInReportingItems();
		let accessChanges = this.getReportingAssetAccessChanges();
		this.assetAccessApiService.updateReportingAssetAccess(this.project, accessChanges);
		this.updateInitialState(hideInDocExpItems, hideInDrillItems, hideInReportingItems);
		this.saveGeographies();
		let searchChanges = this.settingsState.getPropertyChanges<boolean>(SettingsProperty.USE_IN_CLARABRIDGE_SEARCH);
		this.loading.promise = this.attributeManagementService.saveAttributesSettings(this.project, searchChanges)
			.then(() => {
				this.settingsState.save();
				this.gridMode = GridMode.VIEW;
			}, () => {
				this.revertUseInClarabridgeSearch();
				this.settingsState.reset();
				this.gridMode = GridMode.VIEW;
			}) as unknown as ng.IPromise<void>;
	}

	private saveGeographies = (): void => {
		let geographiesToSave: AnyGeography[] = [];

		this.initialGeographies = {};
		Object.keys(this.changedGeographies).forEach(identifier => {
			let changedGeography = this.changedGeographies[identifier];
			geographiesToSave.push(changedGeography);
		});

		this.changedGeographies = {};

		if (this.gridType === GridTypes.ATTRIBUTES) {
			this.geographyApiService.saveAttributeGeographies(this.project, geographiesToSave as AttributeGeography[]);
		} else if (this.gridType === GridTypes.MODELS) {
			this.geographyApiService.saveModelGeographies(this.project, geographiesToSave as ModelGeography[]);
		}
	}

	private updateInitialState = (hideInDocExpItems, hideInDrillItems, hideInReportingItems) => {
		this.hideInDocExpItems = hideInDocExpItems;
		this.hideInDrillItems = hideInDrillItems;
		this.hideInReportingItems = hideInReportingItems;
		this.populateShowInDrill(this.items, hideInDrillItems, this.isModel());
		this.populateShowInDocExp(this.items, hideInDocExpItems, this.isModel());
		this.populateShowInReporting(this.items, hideInReportingItems, this.isModel());
	}

	getHideInDocExpItems = () => {
		return this.getHideItems((i) => !i.showInDocExp, this.hideInDocExpItems);
	}

	getHideInDrillItems = () => {
		return this.getHideItems((i) => !i.showInDrill, this.hideInDrillItems);
	}

	getHideInReportingItems = () => {
		let assetType = this.isModel() ? AssetType.MODEL : AssetType.ATTRIBUTE;
		return _.chain(this.getProcessedHideItems(this.items))
			.filter(item => !item.showInReporting)
			.map(item => {
				return { assetId: item.id, assetType };
			})
			.value();
	}

	getReportingAssetAccessChanges = () => {
		let changes: ModificationDelta<IAsset> = new ModificationDelta();
		let assetType = this.isModel() ? AssetType.MODEL : AssetType.ATTRIBUTE;
		_.each(this.getProcessedHideItems(this.items), item => {
			if (item.showInReporting && this.hideInReportingItemsMap[this.gridType][item.id]) {
				changes.removed.push({ assetId: item.id, assetType });
			} else if (!item.showInReporting && !this.hideInReportingItemsMap[this.gridType][item.id]) {
				changes.added.push({ assetId: item.id, assetType });
			}
		});
		return changes;
	}

	private getHideItems = (filterFun: (i) => boolean, existingItems: any[]) => {
		let isModel = this.isModel();
		let hideItems = this.getProcessedHideItems(this.items).filter(filterFun);
		hideItems.forEach(i => i.model = isModel);
		hideItems.pushAll(existingItems.filter(i => isModel === !i.model));
		return hideItems;
	}

	private getProcessedHideItems = (items: any[]): any[] => {
		let result = angular.copy(items);
		return _.chain(result)
			.reject(this.isFolder)
			.map(this.treeService.copyItem)
			.value();
	}

	private exploreGlobalOther = (model: any): void => {
		if (!model.classified) {
			this.cbDialogService.notify(
				this.locale.getString('administration.globalOtherExplorer'),
				this.locale.getString('administration.notClassified'));
		}
		let modelIdentifier: ModelIdentifier = {
			project: this.project,
			modelId: model.id,
			modelName: model.name,
		};
		this.downgradeDialogService.openGlobalOtherModal({
			modelIdentifier
		});
	}


	applyFilters = (filters: any[]): void => {
		this.ui.filters = filters;
		this.reload();
	}

	applySorts = (sorts: ObjectListSort[]): void => {
		this.ui.sorts = sorts;
		this.processRawItems(this.rawItems);
	}

	onAttributeColumnsChange = (columns: IGridColumn[]): void => {
		this.visibleAttributeColumns = columns.filter(column => column.selected);
	}
	onModelColumnsChange = (columns: IGridColumn[]): void => {
		this.visibleModelColumns = columns.filter(column => column.selected);
	}
}

app.component('projectsManagement', {
	controller: ProjectsManagementController,
	templateUrl: 'partials/projects/projects-management-component.html'
});

