import { AlertEvent } from '@app/core/cx-event.enum';
import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { SelectionControllerBase } from '@app/modules/item-grid/selection/selection-controller-base';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { IStudioAlertActionsService, IStudioAlertActionsServiceScope } from '@cxstudio/alerts/studio-alert-actions-service';
import { IStudioAlertsContextMenuScope } from '@cxstudio/alerts/studio-alert-context-menu-utils';
import { StudioAlertServiceFactory } from '@app/modules/alert/services/studio-alert-service-factory';
import { IContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { IProjectSelection } from './../projects/project-selection.interface';


export class StudioAlertsListController implements ng.IController,
	SelectionControllerBase<StudioAlert>, IStudioAlertsContextMenuScope, IStudioAlertActionsServiceScope {

	active: boolean;
	projectSelection: IProjectSelection;
	projects: Project[];
	searchFilter: string;
	gridColumns: any[];
	gridType = GridTypes.STUDIO_ALERT;
	gridNameField = 'name';
	gridOptions: SlickgridOptions;
	lastChange: any[];
	gridLoading: ng.IPromise<any>;

	alertList: StudioAlert[];
	visibleItems?: StudioAlert[];
	showHidden: boolean;

	private lastChecked: StudioAlert;
	selectionUtils: GenericSelectUtils<StudioAlert>;
	actionsService: IStudioAlertActionsService;
	contextMenuUtils: IContextMenuUtils<StudioAlert>;
	errorText: string;
	isEnabledAlertLimitReached: (ownerName?: string) => boolean;
	getRemainingEnabledAlertCount: (ownerName?: string) => number;

	constructor(
		private $scope: ISimpleScope,
		private contextMenuTree,
		private alertingApiService: AlertingApiService,
		private $filter: ng.IFilterService,
		private studioAlertServiceFactory: StudioAlertServiceFactory,
		private gridUtils: GridUtilsService,
		private locale: ILocale,
	) {}

	$onInit(): void {

		this.$scope.$on(AlertEvent.RELOAD, (e, projectSelection: IProjectSelection) => this.reloadAlerts(projectSelection));
		this.$scope.$on(AlertEvent.CREATE, this.activeTabWrapper(this.addAlert));
		this.$scope.$on(AlertEvent.CLEAR, this.clear);

		this.alertList = [];
		this.visibleItems = [];
		this.gridOptions = {
			onClick: this.onClick
		};

		this.selectionUtils = this.studioAlertServiceFactory.createSelectionUtils(this);
		this.actionsService = this.studioAlertServiceFactory.createActionsService(this);
		this.contextMenuUtils = this.studioAlertServiceFactory.createContextMenuUtils(this);

		this.$scope.$watchCollection(() => this.alertList, this.updateVisibleItemsList);

		this.isEnabledAlertLimitReached = this.actionsService.isEnabledAlertLimitReached;
		this.getRemainingEnabledAlertCount = this.actionsService.getRemainingEnabledAlertCount;
	}

	private clear = (): void => {
		this.alertList = [];
		this.refreshAlerts();
	}

	private activeTabWrapper(fn: () => void): () => void {
		return () => {
			if (this.active) fn();
		};
	}

	getProjectId(): number {
		return this.projectSelection.projectId;
	}

	isShowHidden(): boolean {
		return !this.showHidden;
	}

	getSearchFilter(): string {
		return this.searchFilter;
	}

	getVisibleItems(): StudioAlert[] {
		return this.visibleItems;
	}

	isSelectionSupported(item: StudioAlert): boolean {
		return true;
	}

	private onClick = (event, object: StudioAlert) => {
		if (event.ctrlKey && this.selectionUtils.isSupportedType(object)) {
			this.lastChecked = object;
			this.selectionUtils.handleCtrlClick(object);
			this.$scope.$apply();
			return;
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object));
			return;
		} else if (this.gridUtils.isBulkCheckbox(event)) {
			this.selectionUtils.handleCheckboxClick(object, this.lastChecked, event.shiftKey);
			this.lastChecked = object;
			this.$scope.$apply();
		} else if (this.gridUtils.isToggleClick(event)) {
			// if we're maxed out on enabled alerts for that owner and the item is disabled, we can't enable it
			if (this.isEnabledAlertLimitReached(object.ownerName) && !object.enabled) {
				this.$scope.$apply(() => {
					this.errorText = this.locale.getString('alert.metricAlertsMaxWarning');
				});
				return;
			}

			this.errorText = '';
			this.actionsService.toggleState(object);
			return;
		} else if (this.gridUtils.isNameClick(event)) {
			this.actionsService.editAlert(object);
		}
	}

	refreshGrid = (items?: StudioAlert|StudioAlert[]) => {
		this.lastChange = [].concat(items);
	}

	addAlert = () => {
		this.actionsService.createAlert();
	}

	private reloadAlerts(newProps: IProjectSelection): void {
		let contentProviderId = newProps.contentProviderId;
		let accountId = newProps.accountId;
		if (ValueUtils.isNotSelected(contentProviderId) || ValueUtils.isNotSelected(accountId))
			return;

		let promise = this.alertingApiService.getStudioAlerts(contentProviderId, accountId, newProps.projectId);
		this.gridLoading = promise.then(studioAlerts => {
			this.actionsService.adjustAlertsDates(studioAlerts);

			let tree = TreeListTransformUtils.tree(studioAlerts);
			let sortedTree = this.$filter('orderBy')(tree, 'name');

			this.alertList = [];
			this.alertList.pushAll(sortedTree as StudioAlert[]);
			this.refreshAlerts();
		});
	}

	private updateVisibleItemsList = () => {
		this.visibleItems = [].concat(this.alertList);
	}

	refreshAlerts = () => {
		this.updateVisibleItemsList();
		this.refreshGrid(this.visibleItems);
	}
}
app.component('studioAlertsList', {
	bindings: {
		active: '<',
		projectSelection: '<',
		projects: '<',
		searchFilter: '<',
		gridColumns: '=',
		errorText: '='
	},
	templateUrl: 'partials/alerts/generic-alerts-list.html',
	controller: StudioAlertsListController
});
