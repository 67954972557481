import { CustomMathErrorType } from './custom-math-error';

export enum CustomMathTokenType {
	NUMBER = 'number',
	METRIC = 'metric',
	SYSTEM = 'system',
	SCORECARD_METRIC = 'scorecard',
	SCORECARD_NODE_METRIC = 'scorecard_node_metric', // seems deprecated
	ATTRIBUTE = 'attribute',
	SYMBOL = 'symbol',
	HIERARCHY_METRIC = 'hierarchy',
	SPACE = 'space'
}
export interface CustomMathToken {
	type: CustomMathTokenType;
	subtype?: string;
	value: string;
	text?: string;
	offset?: number;
	nextTokenIsError?: boolean;
	startOffset?: number;
	expression?;
	error?: CustomMathErrorType;
}
