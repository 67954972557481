
import { ObjectUtils } from '@app/util/object-utils';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';
export class IconsMap {

	static QUALTRICS_XM_ICON = 'q-icon-qualtrics-xm';

	static dashboardIconsMap: {[type in DashboardType]?: string} = {
		[DashboardType.BOOK]: 'q-icon-book',
		[DashboardType.DASHBOARD]: 'q-icon-chart-5',
	};

	static filterIconsMap: {[type in FilterTypes]?: string} = {
		[FilterTypes.CXDATE]: IconsMap.QUALTRICS_XM_ICON,
		[FilterTypes.PREDEFINED]: IconsMap.QUALTRICS_XM_ICON,
	};

	static gridContentIconsMap: {[type in GridContext]?: string} = {
		[GridContext.METRIC_PREDEFINED]: IconsMap.QUALTRICS_XM_ICON,
	};

	static driversContentIconsMap: {[type in DriversType]?: string} = {
		[DriversType.drivers_template]: IconsMap.QUALTRICS_XM_ICON
	};

	static getDashboardIconsMap = () => {
		//to prevent accidental modification
		return ObjectUtils.copy(IconsMap.dashboardIconsMap);
	}

	static  getFilterIconsMap = (): {[type in FilterTypes]?: string} => {
		return ObjectUtils.copy(IconsMap.filterIconsMap);
	}

	static getGridContextIconsMap = (): {[type in GridContext]?: string} => {
		return ObjectUtils.copy(IconsMap.gridContentIconsMap);
	}

	static getDriversContentIconsMap = (): {[type in DriversType]?: string} => {
		return ObjectUtils.copy(IconsMap.driversContentIconsMap);
	}
}
