import { NgModule } from '@angular/core';
import { BlockKbNavigationDirective } from '@app/modules/dialog/block-kb-navigation.directive';
import { AlternateConfirmDialogComponent } from '@app/modules/dialog/components/alternate-confirm-dialog/alternate-confirm-dialog.component';
import { ExportSelectorComponent } from '@app/modules/dialog/components/export-selector/export-selector.component';
import { ModalHeaderComponent } from '@app/modules/dialog/components/modal-header.component';
import { OkModalFooterComponent } from '@app/modules/dialog/components/ok-modal-footer.component';
import { SaveModalFooterComponent } from '@app/modules/dialog/components/save-modal-footer.component';
import { SharedModule } from '@app/shared/shared.module';
import { CbModalCloseComponent } from './components/cb-modal-close/cb-modal-close.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { SelectDialogComponent } from './components/select-dialog.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { CxDialogService } from './cx-dialog.service';
import {
	UnsavedChangesDialogComponent
} from '@app/modules/dialog/components/unsaved-changes/unsaved-changes-dialog.component';
import { CommonModule } from '@angular/common';
import { CustomSaveDialogComponent } from './components/custom-save-dialog/custom-save-dialog.component';
import { IconModule } from '@app/modules/icon/icon.module';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		IconModule
	],
	declarations: [
		SimpleDialogComponent,
		SelectDialogComponent,
		ConfirmDialogComponent,
		AlternateConfirmDialogComponent,
		CbModalCloseComponent,
		ModalHeaderComponent,
		OkModalFooterComponent,
		SaveModalFooterComponent,
		ExportSelectorComponent,
		BlockKbNavigationDirective,
		UnsavedChangesDialogComponent,
		CustomSaveDialogComponent,
		DeleteConfirmDialogComponent,
	],
	exports: [
		SimpleDialogComponent,
		CbModalCloseComponent,
		ModalHeaderComponent,
		OkModalFooterComponent,
		SaveModalFooterComponent,
		ExportSelectorComponent,
		BlockKbNavigationDirective,
	],
	providers: [
		CxDialogService
	],
})
export class DialogModule {}
