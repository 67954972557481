<div id="dashboardContainer"
	class="h-100-percent w-100-percent bg-dashboard"
	[ngBusy]="loading"
	[ngClass]="{'book-tab-top-panel' : showTopPanel() && !isPdfExport()}">
	<div>
		<book-dashboard-header
			*ngIf="showTopPanel()"
			class="hide-pdf"
			[containerId]="containerId"
			[dashboard]="dashboard"
			[dashboardHistory]="dashboardHistory"
			[personalization]="personalization"
			[shadow]="headerShadow"
			(editDashboard)="editDashboard.emit($event)"
		></book-dashboard-header>
		<pdf-header
			*ngIf="pdfExport"
			[dashboard]="dashboard"
			[personalization]="personalization"
		></pdf-header>
		<dashboard-view
			[loaded]="loaded"
			[firstLoad]="firstLoad"
			[containerId]="containerId"
			[dashboard]="dashboard"
			[dashboardHistory]="dashboardHistory"
			[personalization]="personalization"
			[widgets]="widgets"
			(scrollEdgeChange)="headerShadow = !$event">
		</dashboard-view>
	</div>
</div>
