import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

export class MAPropertiesService {

	constructor(
		private masterAccountApiService: MasterAccountApiService,
		private security: Security,
	) {}

	isFeatureEnabled(property: MasterAccountProperty): boolean {
		return !!this.security.getCurrentMasterAccount()?.publicProperties?.[property];
	}

	setFeatureEnabled(property: MasterAccountProperty, enabled: boolean): ng.IPromise<void> {
		return this.masterAccountApiService.updateProperty(property, enabled).then(() => {
			this.security.getCurrentMasterAccount().publicProperties[property] = enabled;
		});
	}
}

app.service('maPropertiesService', MAPropertiesService);
