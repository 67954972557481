import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { SocialCasesLogTabComponent } from './status/social-cases-log';
import { ConnectorsStatusTabComponent } from './status/integration-status/connectors/connectors-tab.component';
import { EngageStatusTabComponent } from './status/integration-status/engage/engage-tab.component';
import { PredictiveStatusTabComponent } from './status/integration-status/predictive/predictive-status-tab.component';
import { VoiceStatusTabComponent } from './status/integration-status/voice/voice-tab.component';
import { IntegrationStatusComponent } from './status/integration-status/integration-status.component';
import { JmsListenerStatusTabComponent } from './status/listener-status/jms-listener-status-tab.component';
import { SchedulesStatusTabComponent } from './status/schedules-status/schedules-status-tab.component';
import { EmailsTabComponent } from './status/emails/emails-tab.component';
import { MasterAccountIntegrationComponent } from './master-account/integration/master-account-integration.component';
import { StatusTabComponent } from './status/status-tab.component';
import { ContentProviderEditDialogComponent } from '@app/modules/system-administration/content-provider/content-provider-edit-dialog.component';
import { KafkaStatusTabComponent } from '@app/modules/system-administration/status/listener-status/kafka-status-tab.component';
import { SocialCasesLogFilterComponent } from './status/social-cases-log/social-cases-log-filter.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { SystemAuditExportComponent } from './admin-reports/system-audit-export/system-audit-export.component';
import { AdminReportComponent } from './admin-reports/admin-report/admin-report.component';
import { EngagorSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/engagor-settings-integration.component';
import { ConnectorsSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/connectors-settings-integration.component';
import { ExternalAuthSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/external-auth-settings-integration.component';
import { PredictiveSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/predictive-settings-integration.component';
import { VoiceSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/voice-settings-integration.component';

import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { UnitsModule } from '../units/units.module';
import { UnitsSettingsIntegrationComponent } from './master-account/integration/units-settings-integration.component';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';
import { AccountReportExportDialogComponent } from '@app/modules/system-administration/admin-reports/account-report-export/account-report-export-dialog.component';
import { TuningSuggestionSettingsIntegrationComponent } from '@app/modules/system-administration/master-account/integration/tuning-suggestion-integration.component';
import { NarrativeIntegrationComponent } from '@app/modules/system-administration/master-account/integration/narrative-integration/narrative-integration.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ExternalApplicationFilterComponent } from './external-application/external-application-filter/external-application-filter.component';
import { ExternalApplicationSortComponent } from './external-application/external-application-sort/external-application-sort.component';
import { WorkspaceStatusTabComponent } from '@app/modules/system-administration/status/integration-status/workspace/workspace-status-tab.component';
import { OauthClientDetailsAdditionalInformationComponent } from './oauth/partials/oauth-client-details-additional-information.component';
import { OAuthClientDetailsApiService } from './oauth/service/oauth-client-details-api.service';
import { OAuthDesignerClientDetailsEditDialogComponent } from './oauth/designer-client/oauth-designer-client-details-edit-dialog.component';
import { OAuthDesignerInternalClientsComponent } from './oauth/designer-client/oauth-designer-internal-clients.component';
import { OAuthSuiteClientDetailsEditDialogComponent } from './oauth/suite-client/oauth-suite-client-details-edit-dialog.component';
import { OAuthSuiteClientsComponent } from './oauth/suite-client/oauth-suite-clients.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OAuthStudioInternalClientsComponent } from './oauth/studio-client/oauth-studio-internal-clients.component';
import { OAuthStudioClientDetailsEditDialogComponent } from './oauth/studio-client/oauth-studio-client-details-edit-dialog.component';
import { OAuthLinkClientDetailsEditDialogComponent } from './oauth/link-client/oauth-link-client-details-edit-dialog.component';
import { OAuthLinkClientsComponent } from './oauth/link-client/oauth-link-clients.component';
import { OauthClientDetailsDesignerScopeComponent } from './oauth/partials/oauth-client-details-designer-scope.component';
import { DuplicateNameValidatorDirective } from './oauth/partials/validation/duplicate-name.directive';
import { ForbiddenNameValidatorDirective } from './oauth/partials/validation/forbidden-name.directive';
import { LogoutEndpointValidatorDirective } from './oauth/partials/validation/logout-endpoint.directive';
import { OauthClientDetailsUnifiedLinksComponent } from './oauth/partials/oauth-client-details-unified-links.component';
import { PlatformModule } from '@app/modules/system-administration/platform/platform.module';
import { UpgradeSessionComponent } from './upgrade/upgrade-session/upgrade-session.component';
import { UpgradeTabComponent } from './upgrade/upgrade/upgrade-tab.component';
import { UpgradeApiService } from './upgrade/upgrade-api.service';
import { AdminUsersComponent } from './admin-users/admin-users/admin-users.component';
import { UserAdministrationModule } from '@app/modules/user-administration/user-administration.module';
import { CommonModule } from '@angular/common';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { OAuthExternalProviderEditDialogComponent } from './oauth/external-provider/oauth-external-provider-edit-dialog.component';
import { OAuthExternalProvidersComponent } from './oauth/external-provider/oauth-external-providers.component';
import { OAuthExternalProvidersApiService } from './oauth/service/oauth-external-providers-api-service';
import { QualtricsIntegrationComponent } from './master-account/integration/qualtrics-integration/qualtrics-integration.component';
import { QualtricsIntegrationConfirmationDialogComponent } from './master-account/integration/qualtrics-integration/qualtrics-integration-confirmation-dialog.component';
import { QualtricsApiProviderEditDialogComponent } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-provider-edit-dialog.component';
import { QualtricsApiProvidersComponent } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-providers.component';
import { OAuthSettingsTabComponent } from '@app/modules/system-administration/oauth/oauth-settings-tab/oauth-settings-tab.component';
import { VisibilityRestrictionSelectorComponent } from './oauth/partials/link-visibility-restriction/visibility-restriction-selector.component';
import { UnifiedLinksVisibilityComponent } from './oauth/partials/link-visibility-restriction/unified-link-visibility.component';
import { TruncateNamesListPipe } from '@app/shared/pipes/truncate-names-list.pipe';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { QualtricsStatusComponent } from './status/integration-status/qualtrics/qualtrics-status.component';
import { AdminBetaFeaturesComponent } from './admin-beta-features/admin-beta-features.component';
import { NgPipesModule } from 'ngx-pipes';
import { ConsumptionTrackerModule } from '../consumption-tracker/consumption-tracker.module';
import { ContentProviderFeatureService } from '@app/modules/system-administration/content-provider/content-provider-feature.service';
import { DiscoverDesignerApiIntegrationComponent } from './content-provider/discover-designer-api-integration.component';
import { ModalModule } from '@clarabridge/unified-angular-components';
import { IconModule } from '@app/modules/icon/icon.module';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		UnitsModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule,
		NgPipesModule,
		PlatformModule,
		CommonModule,
		CxFormModule,
		UserAdministrationModule,
		ConsumptionTrackerModule,
		ModalModule,
		IconModule
	],
	exports: [
		ContentProviderEditDialogComponent,
		MasterAccountIntegrationComponent,
		StatusTabComponent,
		AdminReportsComponent,
		UpgradeSessionComponent,
		UpgradeTabComponent,
		AdminUsersComponent,
	],
	declarations: [
		MasterAccountIntegrationComponent,
		ContentProviderEditDialogComponent,
		StatusTabComponent,
		JmsListenerStatusTabComponent,
		KafkaStatusTabComponent,
		SchedulesStatusTabComponent,
		EmailsTabComponent,
		SocialCasesLogTabComponent,
		IntegrationStatusComponent,
		ConnectorsStatusTabComponent,
		EngageStatusTabComponent,
		PredictiveStatusTabComponent,
		VoiceStatusTabComponent,
		WorkspaceStatusTabComponent,
		SystemAuditExportComponent,
		AdminReportComponent,
		AdminReportsComponent,
		EngagorSettingsIntegrationComponent,
		SocialCasesLogFilterComponent,
		ConnectorsSettingsIntegrationComponent,
		ExternalAuthSettingsIntegrationComponent,
		PredictiveSettingsIntegrationComponent,
		VoiceSettingsIntegrationComponent,
		UnitsSettingsIntegrationComponent,
		TuningSuggestionSettingsIntegrationComponent,
		NarrativeIntegrationComponent,
		QualtricsIntegrationComponent,
		AccountReportExportDialogComponent,
		ExternalApplicationFilterComponent,
		ExternalApplicationSortComponent,
		OAuthSettingsTabComponent,
		OauthClientDetailsAdditionalInformationComponent,
		OAuthDesignerClientDetailsEditDialogComponent,
		OAuthDesignerInternalClientsComponent,
		OAuthSuiteClientDetailsEditDialogComponent,
		OAuthSuiteClientsComponent,
		OAuthStudioInternalClientsComponent,
		OAuthStudioClientDetailsEditDialogComponent,
		OAuthLinkClientDetailsEditDialogComponent,
		OAuthLinkClientsComponent,
		OAuthExternalProviderEditDialogComponent,
		OAuthExternalProvidersComponent,
		QualtricsApiProviderEditDialogComponent,
		QualtricsApiProvidersComponent,
		DuplicateNameValidatorDirective,
		OauthClientDetailsDesignerScopeComponent,
		ForbiddenNameValidatorDirective,
		LogoutEndpointValidatorDirective,
		OauthClientDetailsUnifiedLinksComponent,
		UpgradeSessionComponent,
		UpgradeTabComponent,
		AdminUsersComponent,
		VisibilityRestrictionSelectorComponent,
		UnifiedLinksVisibilityComponent,
		QualtricsStatusComponent,
		AdminBetaFeaturesComponent,
		DiscoverDesignerApiIntegrationComponent,
		QualtricsIntegrationConfirmationDialogComponent,
	],
	providers: [
		MasterAccountManageApi,
		EngagorService,
		OAuthClientDetailsApiService,
		OAuthExternalProvidersApiService,
		ExternalApplicationsApiService,
		UpgradeApiService,
		ContentProviderFeatureService,
		TruncateNamesListPipe
	]
})
export class SystemAdministrationModule {}
