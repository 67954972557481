import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { HierarchyChildrenDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/hierarchy-children-drill.service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DashboardDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/dashboard-drill.service.ts';
import * as _ from 'underscore';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { MetricAlertDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/metric-alert-drill.service';
import { KeyTermsDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/key-terms-drill.service';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { AdditionalDrill } from '@app/modules/reports/utils/context-menu/drill/drill-options/additional-drill.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { NLPAttributes } from '@cxstudio/reports/settings/options/nlp-attributes';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { PreviewDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/preview-drill.service';
import { MenuDividerShorthand } from '@cxstudio/context-menu/drill-menu-option.component';
import { TopicDrillService } from './topic-drill.service';
import { TrendDrillService } from './trend-drill.service';
import { WordsDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/words-drill.service';
import { AttributeDrillService } from './attribute-drill.service';
import { RecolorDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/recolor-drill.service';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';


interface DrillAssets {
	attributes;
	wordAttributes;
	models;
	hiddenModels;
	userAccess;
}

export class MenuOptionsUtils {

	constructor(
		private readonly metricConstants: MetricConstants,
		private readonly projectContextService: ProjectContextService,
		private readonly $q: ng.IQService,
		private readonly attributeDrill: AttributeDrillService,
		private readonly wordsDrillService: WordsDrillService,
		private readonly trendDrill: TrendDrillService,
		private readonly topicDrill: TopicDrillService,
		private readonly additionalDrill: AdditionalDrill,
		private readonly topicChildrenDrill,
		private readonly previewDrill: PreviewDrillService,
		private readonly dashboardDrill: DashboardDrillService,
		private readonly metricAlertDrill: MetricAlertDrillService,
		private readonly keyTermsDrill: KeyTermsDrillService,
		private readonly DateRange,
		private readonly recolorDrill: RecolorDrillService,
		private readonly routeService,
		private readonly security: Security,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly assetAccessApiService: AssetAccessApiService,
		private readonly hierarchyChildrenDrill: HierarchyChildrenDrillService,
		private readonly colorUtils: ColorUtils,
		private readonly colorPalettes: ColorPalettes,
		private readonly dashboardAccessService: DashboardAccessService,
	) {
	}

	getOptions = (point: DrillPoint, widget: Widget, attributes: AttributeGrouping[], wordAttributes: any[], models: any[],
		menuActions, editMode: boolean, dashboardProperties: DashboardProperties): ng.IPromise<IDrillMenuOption[]> => {

		if (this.security.has('drill_in_place') || editMode) {
			const project = this.reportAssetUtilsService.getWidgetProject(widget);
			const hiddenDrillMenuItemsPromise = PromiseUtils.old(this.assetAccessApiService.getDrillMenuHiddenAssets(
				project, CacheOptions.CACHED));
			const projectContextPromise = PromiseUtils.old(this.projectContextService.getProjectContext(project));
			const defaultColorPromise = this.getDefaultColor(dashboardProperties);
			const userAccessPromise = PromiseUtils.old(this.metricAlertDrill.checkUserAccessToWidgetData(widget));

			return this.$q.all([hiddenDrillMenuItemsPromise, projectContextPromise, defaultColorPromise, userAccessPromise]).then(responses => {
				const hiddenDrillMenuItems = responses[0];
				const defaultColor = responses[2];
				const userAccess = responses[3];

				const hiddenModels = _.filter(hiddenDrillMenuItems, { model: true } as any);
				const hiddenAttributes = _.filter(hiddenDrillMenuItems, (hiddenItem: any) => {
					return _.isUndefined(hiddenItem.model) || hiddenItem.model === false;
				});
				const drillParams: IDrillParams = {
					widget,
					menuActions,
					editMode,
					defaultColor,
					dashboardProperties,
				};

				if (!editMode) {
					//hide attributes and models in view mode
					models = this.removeHiddenItems(models, hiddenModels);
					attributes = this.removeHiddenItems(attributes, hiddenAttributes);
					wordAttributes = this.removeHiddenItems(wordAttributes, hiddenAttributes);
				}

				const drillAssets: DrillAssets = {
					attributes,
					wordAttributes,
					models,
					hiddenModels,
					userAccess
				};
				return this.getDashboarDrillOptions(drillParams, point).then(dashboardDrillOptions => {
					return this.populateOptionsList(drillParams, point, drillAssets, dashboardDrillOptions);
				});
			});
		} else {
			return this.$q.when([]);
		}
	}

	private getDashboarDrillOptions(drillParams, point): ng.IPromise<IDrillMenuOption[]> {
		if (this.dashboardDrill.hasDrillableDashboards(drillParams)) {
			return this.dashboardDrill.getOptions(drillParams, point)
				.then(options => {
					options.unshift(MenuDividerShorthand);
					return options;
				});
		} else {
			return this.$q.when([]);
		}
	}

	private populateOptionsList(drillParams: IDrillParams, point: DrillPoint,
		drillAssets: DrillAssets, dashboardDrillOptions: IDrillMenuOption[]): IDrillMenuOption[] {
		const mainItems = [] as IDrillMenuOption[];

		const nonReportingOptions = this.additionalDrill.getNonReportingOptions(drillParams.widget, point);
		if (nonReportingOptions.length > 0) {
			mainItems.pushAll(nonReportingOptions);
			if (this.isDrillable(point)) {
				mainItems.push(MenuDividerShorthand);
			}
		}
		if (!this.isDrillable(point)) {
			return mainItems;
		}

		if (this.canDrillToFeedbackInView(drillParams.dashboardProperties, drillParams.widget) || drillParams.editMode) {
			mainItems.push(this.previewDrill.getFeedbackOption(drillParams, point));
		}

		const topicChildren = this.getTopicChildrenOption(drillParams, point, drillAssets.hiddenModels);
		if (topicChildren) {
			mainItems.push(topicChildren);
		}
		const hierarchyChildren = this.getHierarchyChildrenOption(drillParams, point);
		if (hierarchyChildren) {
			mainItems.push(hierarchyChildren);
		}

		const nlpAttributesOptions = new NLPAttributes(this.metricConstants);
		const nlpAttributeFilter = (attr) => nlpAttributesOptions.drillWords.indexOf(attr.name) > -1;
		const wordsOptions = this.wordsDrillService.getNLPOptions(drillParams.widget, drillParams.menuActions,
			_.filter(drillAssets.attributes, nlpAttributeFilter), drillAssets.wordAttributes, drillParams.defaultColor as IColorSelectorPalette);
		if (wordsOptions.items.length) {
			mainItems.push(wordsOptions);
		}

		if (!this.isMissingDate(drillParams.widget)) {
			const trendOptions = this.trendDrill.getOptions(drillParams, drillAssets.attributes, point);
			if (!_.isUndefined(trendOptions)) {
				mainItems.push(trendOptions);
			}
		}

		if (drillAssets.models && drillAssets.models.length) {
			mainItems.push(this.topicDrill.getOptions(drillParams, drillAssets.models));
		}

		const isLanguageAndOtherAttribute = (attr) => nlpAttributesOptions.drillLanguageAndOther.indexOf(attr.name) > -1;
		const isEnrichmentAttribute = (attr) => nlpAttributesOptions.drillEnrichment.indexOf(attr.name) > -1;

		const filteredAttributes = drillAssets.attributes.filter(MetricFilters.NOT_DATE)
			.filter(attr => !nlpAttributeFilter(attr) && !isEnrichmentAttribute(attr) && !isLanguageAndOtherAttribute(attr));
		if (filteredAttributes.length) {
			mainItems.push(this.attributeDrill.getOptions(
				drillParams.widget, drillParams.menuActions, filteredAttributes, drillParams.defaultColor));
		}

		const languageAndOtherAttributes = drillAssets.attributes.filter(isLanguageAndOtherAttribute);
		const enrichmentAttributes = drillAssets.attributes.filter(isEnrichmentAttribute);
		if (enrichmentAttributes.length) {
			mainItems.push(this.attributeDrill.getDrillEnrichmentOptions(
				drillParams.widget, drillParams.menuActions, enrichmentAttributes, drillParams.defaultColor as IColorSelectorPalette,
				languageAndOtherAttributes));
		}

		if (!drillParams.editMode && !this.isTablePopWidget(drillParams.widget)) {
			mainItems.push(this.keyTermsDrill.getOptions(drillParams));
		}

		if (this.security.has('manage_studio_alerts') && !this.routeService.isEmbeddedView()) {
			mainItems.push(MenuDividerShorthand);
			mainItems.push(
				this.metricAlertDrill.getCreateAlertOption(
					drillParams,
					drillParams.widget,
					drillAssets.userAccess
				) as IDrillMenuOption
			);
		}

		if (!this.routeService.isEmbeddedView()) {
			mainItems.pushAll(dashboardDrillOptions);
		}

		if (this.supportRecolor(drillParams.widget) && drillParams.editMode) {
			const opt = this.recolorDrill.getRecolorOption(drillParams, point) as IDrillMenuOption;
			if (opt) {
				mainItems.push(MenuDividerShorthand);
				mainItems.push(opt);
			}
		}

		this.initDefaultAction(mainItems);
		return mainItems;
	}

	canDrillToFeedbackInView(dashboardProperties: DashboardProperties, widget: Widget): boolean {
		const dashboard = { id: widget.dashboardId, properties: dashboardProperties } as Dashboard;
		return this.dashboardAccessService.canDrillToFeedbackInView(dashboard);
	}

	private isTablePopWidget(widget: Widget): boolean {
		return widget.name === WidgetType.TABLE && widget.properties.useHistoricPeriod;
	}

	private initDefaultAction(items: IDrillMenuOption[]): void {
		_.each(items, menuItem => {
			if (menuItem && !!menuItem.items) {
				delete menuItem.selected;
				if (!menuItem.noDefaultAction) {
					menuItem.defaultAction = (point) => {
						return this.applyNestedFunctions(point, menuItem, true);
					};
				}
			}
		});
	}

	private getDefaultColor(properties: DashboardProperties): ng.IPromise<Partial<IColorSelectorPalette>> {
		const defaultColor = this.colorUtils.getDashboardDefaultColor(properties);
		if (defaultColor && defaultColor.name) {
			return this.$q.when(defaultColor);
		} else {
			return this.colorPalettes.getWidgetPalettes().then(palettes => {
				return {
					name: _.findWhere(palettes, { defaultPalette: true }).name
				};
			});
		}
	}

	applyNestedFunctions = (
		point: DrillPoint, option: IDrillMenuOption,
		withDefaultAction: boolean = false
	): ng.IPromise<boolean> => {
		let promise = this.$q.when();
		if (option.asyncFunc) {
			promise = option.asyncFunc(point, option.obj);
		} else if (option.func) {
			option.func(point, option.obj);
		}

		return promise.then(() => {
			const itemsPromise: ng.IPromise<IDrillMenuOption[]> = this.isAsyncOption(option)
				? (option as any).items()
				: this.$q.when(option.items);

			return itemsPromise.then(items => {
				const selectedItem = _.find(items, item => this.isMenuItemAutoselected(item, withDefaultAction));
				if (selectedItem) {
					return this.applyNestedFunctions(point, selectedItem, withDefaultAction);
				}
			});
		});
	}

	private isMenuItemAutoselected = (item: IDrillMenuOption, defaultAction: boolean): boolean => {
		const itemSelected = item && item.selected;
		const itemDefault = defaultAction && item && item.default;
		return itemSelected || itemDefault;
	}

	private isAsyncOption(option: IDrillMenuOption): boolean {
		return typeof option.items === 'function';
	}

	openAnDocumentExplorer = (point, widget, menuActions, fullPage: boolean = false): void => {
		let drillParams = {
			widget,
			menuActions
		};

		fullPage ?
			this.previewDrill.getFullPageOption(drillParams).func(point, 'full_page_doc_explorer') :
			this.previewDrill.getDocExplorerOption(drillParams).func(point, 'an_doc_explorer');
	}

	getDrillToChildrenModel = (point: DrillPoint, widget: Widget): ReportGrouping | undefined => {
		let grouping: ReportGrouping;
		if (point._group) {
			grouping = GroupIdentifierHelper.findGroup(
				widget.properties.selectedAttributes, point._group.identifier);
		} else {
			grouping = widget.properties.selectedAttributes[point.level];
		}

		if (!grouping || grouping.type !== ReportAssetType.TOPICS) {
			return;
		}

		const identifier = GroupIdentifierHelper.getIdentifier(grouping);

		point.isModelNodeLeaf = point[identifier + '_isModelNodeLeaf'];
		point.modelNodeId = point[identifier + '_modelNodeId'];

		if (point.isModelNodeLeaf) {
			return;
		}

		return grouping;
	}

	private isMissingDate(widget): boolean {
		const filterMode = widget.properties.dateRangeP1.dateFilterMode;
		return filterMode === this.DateRange.options.MISS_DATE.value;
	}

	private supportRecolor(widget): boolean {
		return ReportConstants.isAnalyticWidgetAndSupport(widget.name, { color: true });
	}

	private removeHiddenItems(baseList: any[], hiddenItems: any[]): any[] {
		return _.filter(baseList, item => {
			return !_.find(hiddenItems, { id: item.id });
		});
	}

	private isGroupingHiddenModel(grouping: ReportGrouping, hiddenModels: any[]): boolean {
		if (_.isUndefined(grouping)) {
			return false;
		}
		const modelId = parseInt(grouping.name, 10);
		return !!_.find(hiddenModels, { id: modelId });
	}

	private getTopicChildrenOption(drillParams: IDrillParams, point: DrillPoint, hiddenModels: any[]): IDrillMenuOption {
		let grouping = this.getDrillToChildrenModel(point, drillParams.widget);

		if (this.isGroupingHiddenModel(grouping, hiddenModels) && !drillParams.editMode) {
			grouping = null;
		}
		if (grouping) {
			return this.topicChildrenDrill.getOptions(drillParams, point, grouping);
		}
	}

	private getHierarchyChildrenOption(drillParams: IDrillParams, point: DrillPoint): IDrillMenuOption | undefined {
		let grouping: AttributeGrouping;
		if (point._group) {
			grouping = GroupIdentifierHelper.findGroup(
				drillParams.widget.properties.selectedAttributes, point._group.identifier);
		} else {
			grouping = drillParams.widget.properties.selectedAttributes[point.level];
		}
		if (!grouping || grouping.type !== ReportAssetType.HIERARCHY_MODEL) {
			return;
		}

		const identifier = GroupIdentifierHelper.getIdentifier(grouping);
		point.isModelNodeLeaf = point[identifier + '_isHierarchyNodeLeaf'];
		if (!point[identifier] || point.isModelNodeLeaf) {
			return;
		}

		return this.hierarchyChildrenDrill.getOptions(drillParams, point, grouping);
	}

	isDrillable(point: DrillPoint): boolean {
		return !point.nonReporting;
	}
}

app.service('menuOptionsUtils', MenuOptionsUtils);
