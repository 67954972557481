<modal-header
	(cancel)="close()"
	modalTitle="{{'templates.importExport' | i18n}}"
></modal-header>

<section class="modal-body" [ngBusy]="loading">
	<collapsing-panel>
		<panel-heading>{{ 'common.export' | i18n }}</panel-heading>
		<panel-body>
			<button class="btn btn-primary"
				(click)="downloadTemplateData()">
				{{'common.export'|i18n}}
			</button>
		</panel-body>
	</collapsing-panel>
	<collapsing-panel>
		<panel-heading>{{ 'common.import' | i18n }}</panel-heading>
		<panel-body>
			<section>
				<file-upload #fileUpload
					[fileUploadUrl]="getImportUrl()"
					[acceptedMediaTypes]="[MediaType.DAT]"
					[uploadOnSelect]="false"
					(selectUpload)="showUpload = true"
					(removeUpload)="showUpload = false"
					(successfulUpload)="handleSuccessfulUpload($event)">
				</file-upload>
				<button *ngIf="showUpload"
					class="btn btn-primary"
					(click)="fileUpload.uploadSelectedFile(); showUpload = false">
					{{'common.upload'|i18n}}
				</button>
			</section>
		</panel-body>
	</collapsing-panel>
</section>

<ok-modal-footer
	(cancel)="close()"
	okText="{{'common.ok' | i18n}}">
</ok-modal-footer>
