import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core/cx-http.service';

export type Survey = {
	id: string,
	name: string,
	brandUrl: string,
	responseId?: string,
};

export type SurveyRequestDTO = {
	documentId: string,
	surveyId: string,
	responseId: string,
	surveyName: string,
	brandURL: string,
};

export type SurveyResponseDTO = {
	id: number,
	documentId: string,
	responseId: string,
	surveyId: string,
	createdAt?: string,
	updatedAt?: string,
	audit: boolean,
	brandURL: string,
	surveyName: string,
};

export type SurveyProjectAPIResponse = {
	surveys: any;
	brandUrl: string;
};

@Injectable({
	providedIn: 'root'
})
export class ComplianceManagementApiService {
	readonly BASE_URL = 'rest/compliance-management';

	constructor(
		private readonly http: CxHttpService,
	) {}

	getSurveys = (): Promise<SurveyProjectAPIResponse> => {
		return this.http.post('rest/surveys');
	}

	getComplianceManagementSurveyData = ( documentId: string ): Promise<SurveyResponseDTO> => {
		return this.http.get(`${this.BASE_URL}/survey-responses/${documentId}`);
	}

	createComplianceManagementSurveyData = ( body: SurveyRequestDTO ): Promise<SurveyResponseDTO> => {
		return this.http.post(`${this.BASE_URL}/survey-responses`, body);
	}

	updateComplianceManagmentSurveyData = ( body: SurveyRequestDTO ): Promise<SurveyResponseDTO> => {
		return this.http.put(`${this.BASE_URL}/survey-responses/${body.documentId}`, body);
	}
}
