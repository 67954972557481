import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { UrlService } from '@cxstudio/common/url-service.service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'content-image-settings',
	template: `
<image-gallery
	[properties]="widget.properties"
	[dashboardId]="widget.dashboardId">
</image-gallery>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentImageSettingsComponent implements OnInit {

	@Input() widget: ContentWidget;

	constructor(
		@Inject('urlService') private readonly urlService: UrlService,
	) { }

	ngOnInit(): void {
		let props = this.widget.properties;
		props.imageUrl = this.urlService.ensureValidImageUrl(props.imageUrl);
		this.widget.visualProperties.visualization = WidgetVisualization.IMAGE;
	}

}
