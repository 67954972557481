import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'inArray'
})

export class InArrayPipe implements PipeTransform {
	transform(object: any, items: any[]): boolean {
		if (!object) return false;
		if (!items || items.length < 1) return false;
		return !!_.findWhere(items, object);
	}
}