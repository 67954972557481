import * as _ from 'underscore';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';


export class ContractApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers
	) {}

	getContracts(): ng.IHttpPromise<Contract[]> {
		return this.$http.get('rest/contracts');
	}

	createContract(contract: Contract): ng.IHttpPromise<Contract> {
		return this.$http.post('rest/contracts', angular.toJson(contract));
	}

	updateContract(contract: Contract): ng.IHttpPromise<void> {
		return this.$http.put('rest/contracts/' + contract.id, angular.toJson(contract));
	}

	deleteContract(contract: Contract): ng.IHttpPromise<void> {
		return this.$http.delete('rest/contracts/' + contract.id);
	}
	contractWithNameExists(contract: Contract): ng.IHttpPromise<{exist: boolean}> {
		return this.$http.post('rest/contracts/nameExists', contract);
	}
	getLongestAudioRetention(): ng.IPromise<number> {
		return this.$http.get('rest/contracts/audio-retention', {cache: true}).then(this.httpHandlers.success);
	}

	isQuickTranslateAvailable(): ng.IPromise<boolean> {
		return this.$http.get('rest/contracts/quick-translate', {cache: true}).then(this.httpHandlers.success);
	}

	isIntelligentScoringAvailable(): ng.IPromise<boolean> {
		return this.$http.get('rest/contracts/intelligent-scoring', {cache: true}).then(this.httpHandlers.success);
	}

}

app.service('contractApiService', ContractApiService);
