<div class="col-sm-12 cx-ma-contracts-tab-content">
	<div class="pull-right">
		<button
			id="btn-new-contract"
			class="btn btn-primary"
			*ngIf="canManageContracts()"
			(click)="showCreateContractDialog()"
		>{{"mAccount.create_contract" | i18n}}</button>
	</div>
	<contracts 
		[contracts]="contracts"
		[licenses]="licenses"
		(updateTabContract)="showEditContractDialog($event)"
		(deleteTabContract)="showDeleteContractConfirmation($event)"
		(viewTabContract)="showViewContractDialog($event)"
	></contracts>
</div>
