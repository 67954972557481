import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

declare let app: angular.IModule;

@Component({
	selector: 'cb-comment-format-bar',
	template: `
<div class="btn-group">
	<button type="button"
		*ngFor="let format of AVAILABLE_FORMATS"
		class="btn btn-icon"
		[class.active]="appliedFormats[format]"
		name="{{format}}"
		(mousedown)="onApplyFormatting.emit({ format: format })"
		title="{{('common.' + format) |i18n}}"
		tabindex="-1">
		<span class="q-icon q-icon-{{format}}" aria-hidden="true"></span>
	</button>
</div>`
})

export class CbCommentFormatBarComponent implements OnInit {
	@Input() appliedFormats;
	@Output() onApplyFormatting = new EventEmitter<{format: string}>();

	readonly AVAILABLE_FORMATS = ['bold', 'italic', 'underline', 'strikethrough'];

	ngOnInit(): void {}
}

app.directive('cbCommentFormatBar', downgradeComponent({component: CbCommentFormatBarComponent}) as angular.IDirectiveFactory);

