import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import AnalyticWidget from './analytic-widget.class';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/metric-widget-visual-properties.class';
import { ITableColumn } from '@cxstudio/reports/providers/cb/services/table-column-service.service';

export class MetricWidget extends AnalyticWidget {

	visualProperties: MetricWidgetVisualProperties;

	static get(): MetricWidget {
		return new MetricWidget();
	}

	constructor() {
		super();
		this.properties = {} as WidgetProperties;
		this.visualProperties = new MetricWidgetVisualProperties();
		this.name = WidgetType.METRIC;
		this.visualProperties.visualization = WidgetVisualization.CB_AN_METRIC;
		this.properties.widgetType = WidgetType.METRIC;
	}

	withCalculations(calculations: ReportCalculation[]): AnalyticWidget {
		this.properties.selectedMetrics = calculations;
		return this;
	}

	withColumns(tableColumns: ITableColumn[]): AnalyticWidget {
		this.visualProperties.table = {
			columns: tableColumns
		};

		return this;
	}

	withVisualProperties(visualProperties: MetricWidgetVisualProperties): AnalyticWidget {
		this.visualProperties.extend(visualProperties);
		return this;
	}
}
