<collapsing-panel *ngIf="translationEnabled">
	<panel-heading><b>{{'dashboard.languageSettingsSection'|i18n}}</b></panel-heading>
	<panel-body>
		<section class="mb-24 p-relative" [ngBusy]="loadingLocale">
			<h3>
				{{'common.defaultLanguage'|i18n}}
				<cb-inline-help>
					<help-body>{{'dashboard.defaultLanguageHelp'|i18n}}</help-body>
				</cb-inline-help>
			</h3>
			<simple-dropdown
				[(value)]="currentLocale"
				displayField="displayName"
				class="d-flex mb-16"
				[options]="getLocales()"
				(onChange)="updateDefaultLocale($event)"
				[noMinWidth]="true">
			</simple-dropdown>

			<h3 [i18n]="'dashboard.exportTranslationFile'"></h3>
			<p [i18n]="'dashboard.exportTranslationFileDescription'"></p>

			<button
				type="button"
				[ngBusy]="downloading"
				(click)="downloadTemplate()"
				(keyboard.enter)="downloadTemplate()"
				data-testid="btn-download-template"
				class="btn btn-secondary"
				[disabled]="!currentLocale">
				{{ 'organization.downloadTemplate'|i18n }}
			</button>
		</section>

		<section>
			<h3 [i18n]="'dashboard.importTranslationFile'"></h3>
			<p [i18n]="'dashboard.importTranslationFileDescription'"></p>
			<button
				type="button"
				(click)="fileInput.click()"
				(keyboard.enter)="fileInput.click()"
				data-testid="btn-upload-file"
				class="btn btn-secondary">
				{{ 'organization.uploadFile'|i18n }}
				<input
					name="fileInput"
					class="hidden"
					type="file"
					#fileInput
					[(ngModel)]="selectedFile"
					(change)="uploadTranslations($event)"/>
			</button>

			<cb-notice class="mt-16" *ngIf="fileError" type="warning">{{fileError}}</cb-notice>
		</section>
	</panel-body>
</collapsing-panel>
