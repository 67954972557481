import { IDirectiveFactory, INgModelController } from 'angular';

/**
 * Cross-browser consistent input clearing shortcut
 */
const clearInputDirective = ($compile) => {
	return {
		restrict: 'A',
		scope: {
			clearFunction: '&?',
			disable: '=ngDisabled'
		},
		require: '?ngModel',
		link: (scope, element, attrs, ngModel: INgModelController) => {
			element.addClass('clearable-input');
			element.parent().css({position: 'relative'});

			scope.clearFunction = scope.clearFunction || clearValue;
			scope.performClear = () => {
				if (!scope.disable) scope.clearFunction({ ngModel });
			};

			let buttonHTML = '<i ng-click="performClear()" ' +
					'ng-disabled="disable" ng-if="isValueSet()" ' +
					'class="q-icon-delete btn-clear-input cursor-pointer" ' +
					'title="{{\'common.clearValue\'|i18n}}"></i>';

			let clearButton = $compile(buttonHTML)(scope);
			element.after(clearButton);

			scope.isValueSet = () => ngModel.$modelValue?.length > 0;

			// if no custom function is set, just clear out the value
			function clearValue(): void {
				if (ngModel && !scope.disable) {
					ngModel.$setViewValue(undefined);
					ngModel.$render();
				}
			}
		}
	};
};

app.directive('clearInput', clearInputDirective as IDirectiveFactory);
