import { Security } from '@cxstudio/auth/security-service';

import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core/cx-locale.service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { TransferMode } from './transfer-mode';
import { TransferGroup } from './transfer-group';
import { TransferItemCandidates } from './transfer-item-candidates';
import { TransferCandidate } from './transfer-candidate';
import { TransferData } from './transfer-data';
import { Input } from '@angular/core';
import { UserProfile } from '@app/modules/user-administration/transfer/user-profile';
import {SystemAdminApiService} from '@cxstudio/services/data-services/system-admin-api.service';



export interface ITransferDialogParams {
	user: UserProfile;
	mode: TransferMode;
	note?: string;
	onTransferCompleted?: () => Promise<void>;
	confirmButton?: string;
	isSystemAdminPage?: boolean;
}

@Component({
	selector: 'transfer-dialog',
	templateUrl: './transfer-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferDialogComponent implements OnInit {
	@Input() input: ITransferDialogParams;

	user: UserProfile;
	mode: TransferMode;
	note: string;

	loading: Promise<any>;
	transferItems: {number: {[key in TransferGroup]: TransferItemCandidates[]}};
	masterAccounts: MasterAccount[];

	manageEverything: boolean;
	powerCandidates: TransferCandidate[];
	selectedPowerCandidateId: number;

	initialized: boolean;

	showAssetTransfer: boolean = false;
	private saving: boolean;
	private deleteOption: TransferCandidate;

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('transferApiService') private transferApiService: TransferApiService,
		@Inject('securityApiService') private securityApiService: SecurityApiService,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
		@Inject('security') private security: Security
	) {}

	ngOnInit(): void {
		this.initialized = false;
		this.user = this.input.user;
		this.mode = this.input.mode;
		this.note = this.input.note;
		this.deleteOption = {id: -1, email: this.locale.getString('common.delete').toUpperCase(), special: true};
		if (!this.input.isSystemAdminPage) {
			this.init();
		}
	}

	init(): void {
		let getCandidatesPromise;
		if (!this.input.isSystemAdminPage) {
			getCandidatesPromise = this.transferApiService
				.getTransferCandidates(this.user.userId, this.mode);
		} else {
			getCandidatesPromise = this.transferApiService
				.getTransferCandidatesForSysAdminPage(this.user.userId, this.mode);
		}
		let masterAccountsPromise = this.securityApiService
			.getAccessibleMasterAccounts(this.user.userId, true);
		this.loading = Promise.all([getCandidatesPromise, masterAccountsPromise]).then(
			(data) => {
				this.transferItems = data[0];
				this.masterAccounts = data[1].data;
				this.addSpecialOptions();
				this.initialized = true;
			},
			(errorResponse: any) =>  {
				this.cancel();
			}
		);
	}

	isDropdownEnabled() {
		if (this.input.isSystemAdminPage) {
			return false;
		}
		return !this.manageEverything;
	}

	showTransferCandidates = () : void => {
		this.showAssetTransfer = true;
		this.init();
	}

	private getItemsCandidates = (): TransferItemCandidates[] => {
		let masterAccountsMaps = _.values(this.transferItems);
		let afterMap = _.map(masterAccountsMaps, (accountData) => _.values(accountData));
		return _.flatten(afterMap, false);
	}

	private addSpecialOptions = (): void => {
		let itemsCandidates = this.getItemsCandidates();
		this.powerCandidates = itemsCandidates[0]?.candidates || [];
		itemsCandidates.forEach(itemCandidates => {
			this.powerCandidates = _.filter(this.powerCandidates, candidate => !!_.findWhere(itemCandidates.candidates, {id: candidate.id}));
			itemCandidates.candidates.unshift(this.deleteOption);
		});
		this.powerCandidates.unshift(this.deleteOption);
		let currentUser = this.security.getUser();
		this.selectedPowerCandidateId = !!_.findWhere(this.powerCandidates, {id: currentUser.userId}) ? currentUser.userId : this.deleteOption.id;
	}

	updateNewOwners = (): void => {
		if (this.manageEverything) {
			let itemsCandidates = this.getItemsCandidates();
			_.each(itemsCandidates, itemCandidates => {
				itemCandidates.selectedCandidateId = this.selectedPowerCandidateId;
			});
		}
	}

	getNewOwnerTooltip = (): string => !!this.manageEverything ? this.locale.getString('administration.newOwnerTooltip') : '';

	getTitle(): string {
		let transferItemsTitle = this.locale.getString('administration.transferTitle', {user: this.getFullName(this.user)});
		let noItemsTitle = this.locale.getString('common.pleaseConfirm');

		return this.initialized
			? this.hasTransferItems() ? transferItemsTitle : noItemsTitle
			: transferItemsTitle;
	}

	getMessage(): string {
		if (!this.initialized) {
			return '';
		} else if (this.input.isSystemAdminPage && !this.showAssetTransfer) {
			return this.locale.getString('administration.transferNoteForSysAdminPage', {user: this.getFullName(this.user)});
		} else if (this.hasTransferItems()) {
			return this.locale.getString('administration.transferNote', {user: this.getFullName(this.user)});
		} else {
			return this.locale.getString('administration.noTransferItems');
		}
	}

	getGroups(): string[] {
		return Object.values(TransferGroup);
	}

	getGroupHeader(group: string): string {
		return this.locale.getString('administration.transfer' + group.substr(0, 1).toUpperCase()  + group.substr(1));
	}

	hasGroupItems(group: string, accountId: number): boolean {
		return !!this.transferItems && !!this.transferItems[accountId][group] && this.transferItems[accountId][group].length > 0;
	}

	getGroupItems(group: string, accountId: number): TransferItemCandidates[] {
		return this.transferItems ? this.transferItems[accountId][group] : [];
	}

	isFinishEnabled(): boolean {
		return this.initialized && !this.saving;
	}

	disableUserAndTransfer(): void {
		this.saving = true;
		this.loading = this.makeTransfer().then(() => {
			let onCompletedPromise = Promise.resolve();
			if (this.input.onTransferCompleted) {
				onCompletedPromise = this.input.onTransferCompleted();
			}
			return onCompletedPromise.then(() => this.closeDialog());
		}).then(_.noop, () => { this.saving = false; });
	}

	disableUserWithoutTransfer(): void {
		this.saving = true;
		this.loading = this.disableUser().then(() => this.closeDialog()
		).then(_.noop, () => { this.saving = false; });
	}

	private disableUser(): Promise<void> {
		return this.systemAdminApiService.disableUser(this.user.userEmail) as Promise<any>;
	}

	private makeTransfer(): Promise<void> {
		let transferData = new TransferData();
		transferData.userId = this.user.userId;
		transferData.transferItems = this.transferItems;
		transferData.transferWidgetsInUnownedDashboards = true;
		transferData.retainEditPermission = false;
		return this.transferApiService.makeManageUsersTransfer(transferData);
	}

	private closeDialog(): void {
		this.modal.close();
	}

	cancel(): void {
		this.modal.dismiss({$value: 'cancel'});
	}

	private getFullName(user: UserProfile): string {
		return user.firstName + ' ' + user.lastName;
	}

	hasTransferItems(): boolean {
		let result: boolean = false;
		const keys: string[] = Object.values(TransferGroup);
		Object.keys(this.transferItems).forEach( accountId => {
			keys.forEach(key => {
				result = result || this.hasGroupItems(key, Number(accountId));
			});

		});
		return result;
	}

	showMasterAccountName(): boolean {
		return this.transferItems ? Object.keys(this.transferItems).length > 1 : false;
	}

	getAccountIds(): string[] {
		return this.transferItems ? Object.keys(this.transferItems) : [];
	}

	getAccountName(accountId: string): string {
		return _.find(this.masterAccounts, {accountId: Number(accountId)}).accountName;
	}

	getItemCandidates = (item: TransferItemCandidates): TransferCandidate[] => {
		return [].concat(this.deleteOption).concat(item.candidates);
	}

	disableUserWithoutTransferButton(): string {
		return this.locale.getString('userAdministration.disableUserWithoutTransferObjects');
	}

	disableUserAndTransferButton(): string {
		let disableUserAndTransfer = this.initialized && !this.hasTransferItems()
			? this.locale.getString('userAdministration.disableUser')
			: this.locale.getString('userAdministration.disableUserAndTransferObjects');
		return disableUserAndTransfer;
	}

}
