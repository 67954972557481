import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'versions-header',
	template: `
<div *ngIf="isEnabled()"
	class="alert-warning versions-header d-flex align-items-center">
	<h1 class="d-inline-block mh-32 mb-0">{{'header.dashboardVersions'|i18n}}</h1>
	<button class="btn btn-secondary br-versions-close" (click)="cancel()">{{'common.close'|i18n}}</button>
</div>`
})
export class VersionsHeaderComponent extends SelfCleaningComponent implements OnInit {
	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly versionsHeaderService: VersionsHeaderService,
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.versionsHeaderService.getObservable().subscribe(() => {
			this.ref.markForCheck();
		}));
	}

	isEnabled(): boolean {
		return this.versionsHeaderService.isEnabled();
	}

	cancel(): void {
		this.versionsHeaderService.disable();
	}
}

app.directive('versionsHeader', downgradeComponent({component: VersionsHeaderComponent}) as angular.IDirectiveFactory);
