export interface FontDefinition {
	name: string;
	css: string;
}

export class FontOptions {
	static getOptions = () => {
		return [
			{ name: 'Sans-Serif', css: 'Arial, Helvetica, sans-serif' },
			{ name: 'Serif', css: '\'times new roman\', serif' },
			{ name: 'Wide', css: '\'arial black\', sans-serif' },
			{ name: 'Narrow', css: '\'arial narrow\', sans-serif' },
			{ name: 'Courier New', css: '\'courier new\', monospace' },
			{ name: 'Garamond', css: 'garamond, serif' },
			{ name: 'Georgia', css: 'georgia, serif' },
			{ name: 'Tahoma', css: 'tahoma, sans-serif' },
			{ name: 'Trebuchet MS', css: '\'trebuchet ms\', sans-serif' },
			{ name: 'Helvetica', css: '\'Helvetica Neue\', Helvetica, Arial, sans-serif' },
			{ name: 'Verdana', css: 'verdana, sans-serif' },
			{ name: 'Proxima Nova', css: 'proxima_nova_rgregular' }
		];
	}
}

