import { DashboardEvent } from '@app/core/cx-event.enum';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { Security } from '@cxstudio/auth/security-service';
import DashboardPermissionUtils from '@cxstudio/common/context-menu-utils/dashboard-permission-utils';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { IMainDashboardCtrlScope } from '@cxstudio/main-dashboard.controller';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { DrillToDashboardService } from '@cxstudio/services/drill-to-dashboard.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import * as _ from 'underscore';
import { BrowserInfo } from '@app/util/browser-info';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ObjectUtils } from '@app/util/object-utils';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { EmbedDashboardService } from '@app/modules/dashboard-actions/embed-dashboard.service';
import { DashboardReportType } from '@app/modules/dashboard/entity/dashboard-report-type.enum';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

interface IDashboardOption {
	text?: string;
	name?: string;
	onClick: (dashboard?, event?) => void;
	isDivider?: boolean;
	component?: boolean;
	disabled?: boolean;
	items?: IDashboardOption[];
}

interface IDashboardOptionTemplate extends IDashboardOption {
	isAvailable: (item: Dashboard) => boolean;
	isDisabled?: (item: Dashboard) => boolean;
}

export default interface IDashboardOptions {
	get: (dashboard: Dashboard) => IDashboardOption[];
}


// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('DashboardOptions', function(
	locale: ILocale,
	dashboardService: DashboardService,
	security: Security,
	$rootScope: ng.IRootScopeService,
	dashboardListService: DashboardListService,
	$location: ng.ILocationService,
	dashboardTemplateService: DashboardTemplateService,
	cbDialogService: CBDialogService,
	widgetsEditService: WidgetsEditService,
	dashboardScheduleService: DashboardScheduleService,
	versionsHeaderService: VersionsHeaderService,
	dashboardApiService: DashboardApiService,
	drillToDashboardService: DrillToDashboardService,
	dashboardPermissionUtils: DashboardPermissionUtils,
	redirectService: RedirectService,
	currentObjects: CurrentObjectsService,
	dashboardAccessService: DashboardAccessService,
	globalUnloadService: GlobalUnloadService,
	betaFeaturesService: BetaFeaturesService,
	embedDashboard: EmbedDashboardService
) {

	return class DashboardOptions implements IDashboardOptions {

		private scope: IMainDashboardCtrlScope;

		private readonly OPTIONS: {[key: string]: IDashboardOptionTemplate } = {
			EDIT: {
				text: locale.getString('dashboard.editProps'),
				name: 'edit_properties',
				onClick: (dashboard) => {
					widgetsEditService.startSaving();
					dashboardService.renameDashboard(dashboard, true).finally(
						() => widgetsEditService.finishSaving()
					);
				},
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
				isAvailable: (dashboard) => dashboardAccessService.canEditDashboard(dashboard),
			},
			REFRESH: {
				text: locale.getString('dashboard.refresh'),
				name: 'refresh',
				onClick: (dashboard) => dashboardService.hardRefreshDashboard(dashboard.id),
				isAvailable: () => true,
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
			},
			RUN_REPORT_GENERATION: {
				text: locale.getString('dashboard.runReportGeneration'),
				name: 'report_generation',
				onClick: (dashboard) => dashboardService.runReportGeneration(dashboard.id),
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
				isAvailable: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& dashboardAccessService.canEditDashboard(dashboard),
			},
			COPY_LINK: {
				text: locale.getString('common.copyLink'),
				name: 'copy_link',
				onClick: (dashboard, event) => dashboardService.copyDashboardLink(dashboard, event),
				isAvailable: () => true,
			},
			COPY: {
				text: locale.getString('dashboard.duplicate'),
				name: 'copy',
				onClick: dashboard => this.copyAndShowDashboard(dashboard),
				isAvailable: () => security.has('create_dashboard') && !BrowserInfo.isTabletOrMobile()
			},
			NEW: {
				text: locale.getString('dashboard.new'),
				name: 'create',
				onClick: () => this.createAndShowDashboard(),
				isAvailable: () => security.has('create_dashboard') && !BrowserInfo.isTabletOrMobile()
			},
			SCHEDULE: {
				text: locale.getString('dashboard.schedule'),
				name: 'schedule',
				onClick: dashboard => this.checkChangesAndOpenScheduleDialog(dashboard),
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
				isAvailable: item => dashboardPermissionUtils.canScheduleDashboard(item)
			},
			SHARE: {
				text: locale.getString('dashboard.dashboardShare'),
				name: 'share',
				onClick: dashboard => this.scope.shareClick(dashboard),
				isAvailable: (dashboard) => dashboardAccessService.canShare(dashboard)
			},
			EXPORT_DATA: {
				text: locale.getString('dashboard.exportWidgetData'),
				name: 'export_all',
				onClick: () => this.exportAllWidgets(),
				isAvailable: () => !currentObjects.isEditMode() && security.has('export_widget_data') && !BrowserInfo.isTabletOrMobile()
			},
			EXPORT_PDF: {
				text: locale.getString('dashboard.exportPDF'),
				name: 'export_pdf_menu',
				onClick: this.menuEmptyFunction,
				items: [{
					text: locale.getString('dashboard.downloadPdf'),
					name: 'export_pdf',
					onClick: () => this.dashboardExportPdfDownload()
				}, {
					text: locale.getString('dashboard.ReceiveByEmail'),
					name: 'export_pdf_download',
					onClick: () => this.dashboardExportPdfSend()
				}],
				isAvailable: () => !currentObjects.isEditMode() && !BrowserInfo.isTabletOrMobile()
			},
			HIDE_TOOLBAR: {
				text: locale.getString('dashboard.hideToolbar'),
				name: 'toolbarOptions',
				onClick: () => this.scope.changeToolbarState(),
				isAvailable: () => currentObjects.isEditMode() && !this.scope.widgetToolbarHidden
			},
			SHOW_TOOLBAR: {
				text: locale.getString('dashboard.showToolbar'),
				name: 'toolbarOptions',
				onClick: () => this.scope.changeToolbarState(),
				isAvailable: () => currentObjects.isEditMode() && this.scope.widgetToolbarHidden
			},
			ZOOM: {
				component: true,
				name: 'zoom',
				onClick: () => this.zoomFunction(),
				isAvailable: () => !BrowserInfo.isTabletOrMobile()
			},
			DARK_MAGIC_MODE: {
				component: true,
				name: 'dark-mode-toggle',
				onClick: this.menuEmptyFunction,
				isAvailable: () => security.getCurrentMasterAccount().darkModeAvailable
			},
			EXPERIMENTAL_UI: {
				component: true,
				name: 'experimental-ui',
				onClick: this.menuEmptyFunction,
				isAvailable: () => true
			},
			PATTERN_FILLS: {
				component: true,
				name: 'pattern-fills',
				onClick: this.menuEmptyFunction,
				isAvailable: () => true
			},
			WIDGET_OPTIONS: {
				component: true,
				name: 'widget-options',
				onClick: this.menuEmptyFunction,
				isAvailable: () => currentObjects.isEditMode() && !BrowserInfo.isTabletOrMobile()
			},
			PUBLISH: {
				text: locale.getString('dashboard.publishFrom'),
				name: 'publish',
				onClick: dashboard => this.publishFromDashboard(dashboard),
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
				isAvailable: (dashboard) => dashboardAccessService.canEditDashboard(dashboard)
					&& !currentObjects.isEditMode() && !BrowserInfo.isTabletOrMobile()
			},
			VERSIONS: {
				text: locale.getString('dashboard.versions'),
				name: 'versions',
				onClick: () => versionsHeaderService.enable(),
				isDisabled: (dashboard) => dashboard.properties?.reportType === DashboardReportType.FRONTLINE
					&& currentObjects.isSnapshotView(),
				isAvailable: (dashboard) => dashboardAccessService.canEditDashboard(dashboard)
					&& !currentObjects.isEditMode() && !BrowserInfo.isTabletOrMobile()
			},
			DELETE: {
				text: locale.getString('common.delete'),
				name: 'delete',
				onClick: dashboard => this.removeDashboard(dashboard),
				isAvailable: item => item.permissions.OWN
			},
			EMBED_DASHBOARD: {
				text: locale.getString('common.embedMenuOption', {objectType: locale.getString('object.dashboard')}),
				name: 'embed-dashboard',
				onClick: dashboard => embedDashboard.openEmbedDashboardModal(dashboard),
				isAvailable: () => betaFeaturesService.isFeatureEnabled(BetaFeature.EMBED_DASHBOARDS)
			}
		};

		constructor(scope: IMainDashboardCtrlScope) {
			this.scope = scope;
		}

		private menuEmptyFunction(dashboard, event): void {
			event.stopPropagation();
		}

		private zoomFunction(): void {
			this.scope.hideWidgetToolbarOnZoom();
		}

		get = (dashboard: Dashboard): IDashboardOption[] => {
			// generate menu depending on permissions
			let item = dashboard;
			if (!item)
				return;

			const hasDrillToDashboardFilter = drillToDashboardService.hasFilters(item.id);
			if (hasDrillToDashboardFilter) {
				return this.processOptions([
					[
						this.OPTIONS.REFRESH,
						this.OPTIONS.RUN_REPORT_GENERATION,
						this.OPTIONS.COPY_LINK,
						this.OPTIONS.EMBED_DASHBOARD,
						this.OPTIONS.NEW,
					],
					[
						this.OPTIONS.EXPORT_DATA,
						this.OPTIONS.EXPORT_PDF,
					],
					[
						this.OPTIONS.SHOW_TOOLBAR,
						this.OPTIONS.HIDE_TOOLBAR,
					],
					[ this.OPTIONS.ZOOM ],
					[
						this.OPTIONS.DARK_MAGIC_MODE,
						this.OPTIONS.EXPERIMENTAL_UI,
						this.OPTIONS.PATTERN_FILLS,
					],
				], item);
			} else {
				return this.processOptions([
					[ this.OPTIONS.EDIT ],
					[
						this.OPTIONS.REFRESH,
						this.OPTIONS.RUN_REPORT_GENERATION,
						this.OPTIONS.COPY_LINK,
						this.OPTIONS.EMBED_DASHBOARD,
						this.OPTIONS.COPY,
						this.OPTIONS.NEW,
						this.OPTIONS.SCHEDULE,
					],
					[
						this.OPTIONS.SHARE,
						this.OPTIONS.EXPORT_DATA,
						this.OPTIONS.EXPORT_PDF,
					],
					[
						this.OPTIONS.SHOW_TOOLBAR,
						this.OPTIONS.HIDE_TOOLBAR,
					],
					[ this.OPTIONS.ZOOM ],
					[
						this.OPTIONS.DARK_MAGIC_MODE,
						this.OPTIONS.EXPERIMENTAL_UI,
						this.OPTIONS.PATTERN_FILLS,
					],
					[ this.OPTIONS.WIDGET_OPTIONS ],
					[
						this.OPTIONS.PUBLISH,
						this.OPTIONS.VERSIONS,
					],
					[ this.OPTIONS.DELETE ],
				], item);
			}
		}

		private processOptions(allOptions: IDashboardOptionTemplate[][], item: Dashboard): IDashboardOption[] {
			return _.chain(ObjectUtils.copy(allOptions))
				.map(group => group.filter(option => option.isAvailable(item)))
				.filter(group => !_.isEmpty(group))
				.map((group, index) => index > 0 ? [this.getDivider() as IDashboardOptionTemplate].concat(group) : group)
				.flatten()
				.each(option => {
					if (option.isDisabled) {
						option.disabled = option.isDisabled(item);
						delete option.isDisabled;
					}
					delete option.isAvailable;
				})
				.value();
		}

		private getDivider = (): IDashboardOption => {
			return {...MenuDivider, onClick: this.menuEmptyFunction};
		}


		copyAndShowDashboard = (dashboard): void => {
			this.scope.checkUnsavedChanges().then(() => {
				dashboardService.copyDashboard(dashboard).then((newDash) => {
					$location.path('/dashboard/' + newDash.id).search('edit', true);
				});
			}).catch(() => {});
		}

		exportAllWidgets = (): void => {
			this.scope.$broadcast('dashboardExportEvent');
		}

		dashboardExportPdfDownload = (): void => {
			this.scope.$broadcast('dashboardExportPdfDownloadEvent');
		}

		dashboardExportPdfSend = (): void => {
			this.scope.$broadcast('dashboardExportPdfSendEvent');
		}

		createAndShowDashboard = (): void => {
			dashboardTemplateService.templatesEnabled().then((enabled) => {
				// no need to check changes if templates enabled -- will be done automatically on page change
				if (enabled) {
					$location.path('/templates');
					return;
				}

				this.scope.checkUnsavedChanges().then(() => {
					dashboardService.createDashboard().then((newDash: Dashboard) => {
						$location.path('/dashboard/' + newDash.id).search('edit', true);
					});
				}).catch(() => {});
			});
		}

		checkChangesAndOpenScheduleDialog = (dashboard): void => {
			let callbackFunc = () => {
				dashboardScheduleService.openScheduleDialog(dashboard);
			};
			this.scope.checkUnsavedChanges('dashboard.unsavedChangesSchedule').then(callbackFunc).catch(() => {});
			/* this.scope.checkUnsavedChanges(callbackFunc, null, '', '',
				{ hideRollbackOption: true, bodyText: 'dashboard.unsavedChangesSchedule'}); */
		}

		removeDashboard = (dashboard) => {
			// prevents the system from attempting to unmark the dashboard as edited after it has already been deleted
			dashboardService.removeDashboard(dashboard).then(() => {
				this.scope.resetCurrentDashboard();
				globalUnloadService.removeHandler('dashboard-edit');
				redirectService.goToDashboardList();
			});
		}

		publishFromDashboard = (dashboard) => {
			let dialog = dashboardService.publishDashboardFromSource(dashboard, dashboardListService.getCurrentDashboardsList());
			dialog.result.then((result) => {
				let source: Dashboard = result.source;
				let promise = dashboardApiService.publishDashboardFromSource(dashboard.id, source.id);

				this.scope.publishing = promise;

				promise.then((response) => {
					cbDialogService.notify(locale.getString('dashboard.publishDashboardHeader'),
						locale.getString('dashboard.publishedSuccess',
							{sourceName: source.name, targetName: dashboard.name}));
					//response permissions is always empty;
					delete response.data.permissions;
					_.extend(dashboard, response.data);
					this.scope.dashboardContainer.dashboardHistory.applyFilterUpdates(dashboard.appliedFiltersArray);
					$rootScope.$broadcast(DashboardEvent.RELOAD);
				});
			});
		}
	};
});
