export enum AmplitudeEvent {
	DASHBOARD_VIEW = 'Dashboards.DashboardView',
	DASHBOARD_CREATE = 'Dashboards.DashboardCreate',
	STUDIO_LOGIN = 'Studio.LogIn',
	BOOK_VIEW = 'Book.View',
	DASHBOARD_SAVE = 'Dashboards.DashboardSave',
	DASHBOARD_WIDGET_VIEW = 'Dashboards.WidgetView',
	DASHBOARD_WIDGET_CREATE = 'Dashboards.WidgetCreate',
	DASHBOARD_APPLYFILTER = 'Dashboards.DashboardApplyFilter',
	DASHBOARD_UNDO = 'Dashboards.DashboardUndo',
	DASHBOARD_REDO = 'Dashboards.DashboardRedo',
	DASHBOARD_WIDGET_DROPDOWN_CLICK = 'Dashboards.WidgetDropDownClick',
	DASHBOARD_EXPORT = 'Dashboards.DashboardExport',
	DASHBOARD_WIDGET_EMBEDDED_CODE_COPY = 'Dashboards.WidgetEmbeddedCodeCopy',
	DASHBOARD_EDIT_PROPERTIES = 'Dashboards.DashboardEditProperties',
	DASHBOARD_WIDGET_EXPORT = 'Dashboards.WidgetExport',
	DASHBOARD_WIDGET_DELETE = 'Dashboards.WidgetDelete',
	DASHBOARD_DROPDOWN_CLICK = 'Dashboards.DashboardDropDownClick',
	DASHBOARD_EXPORT_ALL_WIDGETS = 'Dashboards.DashboardExportAllWidgets',
	DASHBOARD_WIDGET_EDIT = 'Dashboards.WidgetEdit',
	DASHBOARD_DATAWIDGET_DROPDOWN_CLICK = 'Dashboards.DataWidgetDropDownClick',
	DASHBOARD_SHARE = 'Dashboards.DashboardShare',
	DASHBOARD_SHARELINK = 'Dashboards.DashboardShareLink',
	DASHBOARD_WIDGET_EMBEDDED_VIEW = 'Dashboard.WidgetEmbeddedView',
	AUTOMATED_CALL_SUMMARY_RATING = 'AutomatedCallSummary.Rating',
	AUTOMATED_CALL_SUMMARY_LOADED = 'AutomatedCallSummary.Loaded',
	AUTOMATED_CALL_SUMMARY_GENERATE = 'AutomatedCallSummary.Generate',
	AUTOMATED_CALL_SUMMARY_REGENERATE = 'AutomatedCallSummary.Regenerate',
	COACHING_ASSISTANT_RATING = 'CoachingAssistant.Rating',
	COACHING_ASSISTANT_GENERATE = 'CoachingAssistant.Generate',
	COACHING_ASSISTANT_REGENERATE = 'CoachingAssistant.Regenerate',
	DRIVER_SAVE = 'Driver.Save',


	// doc explorer
	DOCEXPLORER_DOCUMENT_VIEW = 'DocExplorer.DocumentView',
	DOCEXPLORER_CONTEXT_PANE_CLICK = 'DocExplorer.ContextPaneClick',
	DOCEXPLORER_CONTEXT_PANE_TAB_NAVIGATE = 'DocExplorer.ContextPaneTabNavigate',
	DOCEXPLORER_RESULTS_PANE_SORT = 'DocExplorer.ResultsPaneSort',
	DOCEXPLORER_RESULTS_PANE_FILTER = 'DocExplorer.ResultsPaneFilter',
	DOCEXPLORER_KEYINFO_ADD = 'DocExplorer.KeyInfoAdd',
	DOCEXPLORER_SPINE_CLICK = 'DocExplorer.SpineClick',
	DOCEXPLORER_DOCUMENT_CHANGE = 'DocExplorer.DocumentChange',
	DOCEXPLORER_SETTING_CHANGE = 'DocExplorer.SettingChange',
	DOCEXPLORER_EXPORT = 'DocExplorer.Export',
	DOCEXPLORER_COPY_LINK = 'DocExplorer.CopyLink',
	DOCEXPLORER_CASE_CREATE = 'DocExplorer.CaseCreate',
	DOCEXPLORER_TRANSLATE_CLICK = 'DocExplorer.TranslateClick',
	DOCEXPLORER_REBUTTAL_SAVE = 'DocExplorer.RebuttalSave',
	DOCEXPLORER_CALL_PLAY = 'DocExplorer.CallPlay',
	DOCEXPLORER_CLOSE = 'DocExplorer.Close',
}
