<div class="w-100-percent d-flex flex-singleline">
	<div class="flex-fixed scroll-carousel-button">
		<a class="btn btn-icon no-border pull-right p-8"
			[attr.aria-label]="labels.previous"
			[class.hidden]="!scroller.canScrollStart()"
			(mousedown)="scroller.scrollToStart()"
			(mouseup)="scroller.stopScroll()"
			(mouseleave)="scroller.stopScroll()">
			<span class="q-icon q-icon-triangle-left pull-right"></span>
		</a>
	</div>
	<div class="d-flex flex-fill justify-start overflow-hidden flex-direction-row p-relative"
		[class.brand-secondary-bg-fade-start]="scroller.canScrollStart()"
		[class.brand-secondary-bg-fade-end]="scroller.canScrollEnd()">
		<ng-content></ng-content>
	</div>

	<div class="flex-fixed scroll-carousel-button">
		<a class="btn btn-icon no-border pull-left p-8"
			[attr.aria-label]="labels.next"
			[class.hidden]="!scroller.canScrollEnd()"
			(mousedown)="scroller.scrollToEnd()"
			(mouseup)="scroller.stopScroll()"
			(mouseleave)="scroller.stopScroll()">
			<span class="q-icon q-icon-triangle-right pull-left"></span>
		</a>
	</div>
</div>
