<div class="flex-fill d-flex flex-direction-column fill-container">
	<header
		gridsterDragHandle
		class=""
		title="{{widget.description}}"
		(click)="expanded = !expanded"
		(contextmenu)="handleRightClick($event)"
		(keydown.enter)="$event.stopPropagation()">
		<div *ngIf="isHeaderVisible()"
			class="flex-fixed d-flex flex-direction-row align-center br-widget-header"
			[class.fixed-header]="fixedHeader">
			<ng-container *ngTemplateOutlet="headerLeft"></ng-container>
			<cb-drag-handle
				*ngIf="(editable && editMode) || isContentWidget"
				class="ml-8"
				attr.aria-label="{{'dashboard.dragToMove'|i18n}}"
				tabindex="0">
			</cb-drag-handle>
			<widget-title-container
				[class.flex-fill]="editable && editMode"
				[editMode]="editable && editMode"
				[widget]="widget"
				[widgetActions]="widgetActions"
			></widget-title-container>
			<ng-container *ngTemplateOutlet="headerInfo"></ng-container>
			<widget-menu
				#mainMenu
				class="kb-next-widget grouped-menu"
				[class]="getWidgetMenuClass()"
				data-testid="widget-menu"
				[widget]="widget"
				[position]="menuState.position"
				(onOpen)="setMenuOpen(true)"
				(onClose)="setMenuOpen(false)">
				<ng-container *ngIf="menuState.open">
					<ng-container *ngTemplateOutlet="menu,context:{$implicit:getMenuActions()}"></ng-container>
				</ng-container>
			</widget-menu>
		</div>
	</header>
	<section class="flex-fill d-flex slide overflow-hidden border-radius-widget-default"
		[class.down]="expanded"
		(keydown)="processCommonKb($event)"
		(contextmenu)="handleRightClick($event)">
		<ng-content></ng-content>
	</section>
	<footer gridsterDragHandle class="flex-fixed">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</footer>
	<ng-container *ngTemplateOutlet="other,context:{expanded:expanded}"></ng-container>
	<ng-container *ngIf="!!layout">
		<div class="br-widget-overlay"
			*ngIf="layout.resizing || layout.dragging"
			(mousedown)="$event.preventDefault()">
		</div>
	</ng-container>
</div>
