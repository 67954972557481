<div class="cb-table" [ngClass]="getWrapperClasses()">
	<table class="w-100-percent">
		<thead class="cb-table-header">
			<th *ngFor="let column of columns"
				class="cb-table-header-cell"
				[ngStyle]="{'text-align': column.align, width: (column.width * 100 + '%')}"
				title="{{column.displayName}}">
				{{column.displayName}}
			</th>
		</thead>
		<tr *ngFor="let row of rows; odd as isOdd; even as isEven"
			class="cb-table-row"
			[class.odd]="isOdd"
			[class.even]="isEven">
			<td *ngFor="let column of columns"
				[ngClass]="column.name"
				(click)="onClick(row, column, $event)"
				[ngStyle]="{'text-align': column.align, width: (column.width * 100 + '%')}"
				[innerHTML]="getCellText(row, column)">
			</td>
		</tr>
	</table>
</div>
