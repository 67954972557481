import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Security } from '@cxstudio/auth/security-service';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import {
	DocExplorerPreferencesProviderService
} from '@app/modules/document-explorer/preferences/doc-explorer-preferences-provider.service';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ObjectUtils } from '@app/util/object-utils';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { AnalyticPreviewDefaultsInitializer } from '@cxstudio/reports/preview/analytic-preview-defaults-initializer';
import { PromiseUtils } from '@app/util/promise-utils';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export interface IDashboardCreationContext {
	availableScorecards?: any[];
	currentDashboard?: Dashboard;
	currentWidget: Widget;
	feedbackSelection?: any;
	fromPreviewWidget?: boolean;
	preferences?: any;
}

@Injectable({
	providedIn: 'root'
})
export class DashboardFromSelectionService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly docExplorerPreferencesProvider: DocExplorerPreferencesProviderService,
		private readonly sessionPreferences: SessionPreferencesService,
		private readonly dashboardListService: DashboardListService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('dateService') private readonly dateService: DateService,
		@Inject('security') private readonly security: Security,
		@Inject('analyticPreviewDefaultsInitializer')
		private readonly analyticPreviewDefaultsInitializer: AnalyticPreviewDefaultsInitializer,
		@Inject('metricUtils') private readonly metricUtils: MetricUtils,
		@Inject('projectSettingsService') private readonly projectSettingsService: ProjectSettingsService,
	) {
	}

	create = (context: IDashboardCreationContext): Promise<any> => {
		return this.buildPreviewWidget(context).then((previewWidget: PreviewWidget) => {
			const dashboardName: string = this.buildPreviewDashboardName();

			return PromiseUtils.wrap(this.dashboardService.createDashboardByPinnedFeedback(
				previewWidget,
				dashboardName,
				context.currentDashboard
			)).then((dashboard: Dashboard) => {
				return PromiseUtils.wrap(this.dashboardService.shareDashboard(dashboard)).then(
					() => this.reloadDashboards(),
					() => this.dashboardService.removeDashboard(dashboard, true)
				);
			});
		});
	}

	buildPreviewWidget = (context: IDashboardCreationContext) => {
		let previewWidget: PreviewWidget = ObjectUtils.copy(context.currentWidget) as PreviewWidget;
		const verbatimMode: boolean = !!context.preferences?.settings?.singleVerbatimMode;

		previewWidget.type = WidgetDisplayType.CB;
		previewWidget.name = 'cb_an_preview';
		previewWidget.displayName = this.locale.getString('widget.feedbackPreview');

		if (context.feedbackSelection) {
			this.setCreatedWidgetBox(previewWidget);
		}

		previewWidget.properties = previewWidget.properties || {} as WidgetProperties;
		delete previewWidget.properties.encodedDescriptor;

		let properties: WidgetProperties = previewWidget.properties;
		properties.widgetType = WidgetType.PREVIEW;
		properties.previewMode = PreviewMode.DOCUMENT;

		previewWidget.visualProperties = previewWidget.visualProperties || {} as VisualProperties;

		let visualProperties: VisualProperties = previewWidget.visualProperties;
		visualProperties.visualization = verbatimMode ? WidgetVisualization.VERBATIM_PREVIEW : WidgetVisualization.DOCUMENT_PREVIEW;
		visualProperties.preferences = this.docExplorerPreferencesProvider.getEmptyPreferences();

		const localPreferences = this.sessionPreferences.get('docExplorerPreferences');
		const contextVisibility = context.preferences?.settings?.contextSectionsVisibility || localPreferences?.contextSectionsVisibility;

		if (!context.fromPreviewWidget && contextVisibility) {
			let sections = {};
			Object.keys(contextVisibility).forEach(key => sections[key] = {
				key,
				visible: contextVisibility[key]
			});
			visualProperties.sections = sections;
		}

		const project: AccountOrWorkspaceProject = this.reportAssetUtilsService.getWidgetProject(previewWidget);
		const settingsPromise: Promise<IProjectSettings> = PromiseUtils.wrap(
			this.projectSettingsService.getSettings(project)
		);

		return settingsPromise.then((settings: IProjectSettings) => {
			if (properties.documentExplorer) {
				properties.favoriteAttributes = context.preferences.getSavedFavorites(properties.contentProviderId,
					properties.accountId, previewWidget.properties.project);

				settings.scorecards = context.availableScorecards || [];

				properties.selectedAttributes = this.getSelectedAttributes(settings, properties.favoriteAttributes);

				visualProperties.sentimentHighlightingEnabled = context.preferences.settings.showSentiment;
				visualProperties.sentencePaneEnabled = context.preferences.settings.visiblePanes.sentence;
				properties.includeSentenceAttributes = visualProperties.sentencePaneEnabled;
				visualProperties.contextPaneEnabled = context.preferences.settings.visiblePanes.context;

				properties.documentExplorer = false;
			}

			if (verbatimMode) {
				visualProperties.preferences.settings.singleVerbatimMode = true;
			}

			this.analyticPreviewDefaultsInitializer.initializeWidget(properties, visualProperties);
			this.analyticPreviewDefaultsInitializer.addDefaultColumns(visualProperties, verbatimMode);
			this.analyticPreviewDefaultsInitializer.addColumns(visualProperties, properties.selectedAttributes);

			if (context.feedbackSelection) {
				context.feedbackSelection.saveResultTo(previewWidget);
			}

			return previewWidget;
		});
	}

	private reloadDashboards(): void {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES)) {
			this.dashboardListService.reloadDashboards();
			return;
		}

		this.dashboardListService.reloadDashboardsOld();
	}

	// this function should make selectedAttributes to be as similar to selectedAttributes from original FB widget as possible
	private getSelectedAttributes = (settings: any, favoriteAttributes: any[]): any[] => {
		const keyInfoAttributes: any[] = this.getKeyInfoAttributes(settings.attributes, favoriteAttributes);
		const keyInfoModels: any[] = this.getKeyInfoModels(settings.models, favoriteAttributes);
		const keyInfoScorecards: any[] = this.getKeyInfoScorecards(settings.scorecards, favoriteAttributes);

		return this.uniteSelectedAttributes(keyInfoAttributes, keyInfoModels, keyInfoScorecards, favoriteAttributes);
	}

	private getKeyInfoScorecards = (scorecards: any[], favoriteAttributes: any[]): any[] => {
		const favoriteScorecardAttributes: any[] = favoriteAttributes.filter(
			favoriteAttribute => favoriteAttribute.type === FavoriteType.SCORECARDS
		);

		const scorecardIds: any[] = favoriteScorecardAttributes.map(
			favoriteScorecardAttribute => favoriteScorecardAttribute.scorecardId
		);

		return scorecardIds
			.map(scorecardId => scorecards.find((scorecard) => scorecard.id === scorecardId))
			.filter(scorecard => !!scorecard)
			.map(scorecard => {
				return {
					id: scorecard.id,
					name: scorecard.name,
					hide: scorecard.hide,
					type: FavoriteType.SCORECARDS
				};
			});
	}


	private getKeyInfoModels = (models: any[], favoriteAttributes: any[]): any[] => {
		const favoriteModelAttributes: any[] = favoriteAttributes.filter(
			favoriteAttribute => favoriteAttribute.type === FavoriteType.MODEL
		);

		const modelIds: any[] = favoriteModelAttributes.map(
			favoriteScorecardAttribute => favoriteScorecardAttribute.modelId
		);

		const keyInfoModels = modelIds
			.map(modelId => models.find((model) => model.id === modelId))
			.filter(model => !!model)
			.map(model => {
				return {
					id: model.id,
					name: model.name,
					hide: model.hide,
					rootNodeId: model.rootNodeId
				};
			});

		return this.metricUtils.buildModelOptions(keyInfoModels).topicModels;
	}

	private getKeyInfoAttributes = (attributes: any[], favoriteAttributes: any[]) => {
		const favoriteOtherAttributes: any[] = favoriteAttributes.filter(
			favoriteAttribute =>
				favoriteAttribute.type !== FavoriteType.MODEL && favoriteAttribute.type !== FavoriteType.SCORECARDS
		);

		const keyInfoAttributes: any[] = favoriteOtherAttributes
			.map(favoriteOtherAttribute => {
				if (favoriteOtherAttribute.type === FavoriteType.WORLD_AWARENESS) {
					return attributes.find(attribute => attribute.name === favoriteOtherAttribute.name);
				}

				return attributes.find(
					attribute => attribute.name.toUpperCase() === favoriteOtherAttribute.name.toUpperCase()
				);
			})
			.filter(attribute => !!attribute);

		this.metricUtils.buildAttributeOptions(keyInfoAttributes, () => true);

		return keyInfoAttributes;
	}

	private uniteSelectedAttributes = (
		attributes: any[],
		models: any[],
		scorecards: any[],
		favoriteAttributes: any[]
	): any[] => {
		return favoriteAttributes.map(favoriteAttribute => {
			if (favoriteAttribute.type === FavoriteType.SCORECARDS) {
				return scorecards.find(scorecard => scorecard.id === favoriteAttribute.scorecardId);
			}

			let selectedAttribute: any;

			if (favoriteAttribute.type === FavoriteType.MODEL) {
				if (favoriteAttribute.modelId) {
					selectedAttribute = models.find(model => model.name === (favoriteAttribute.modelId + ''));
				}
			} else {
				const name = favoriteAttribute.type === FavoriteType.WORLD_AWARENESS
					? favoriteAttribute.name : favoriteAttribute.name.toUpperCase();

				selectedAttribute = favoriteAttribute.type === FavoriteType.WORLD_AWARENESS
					? attributes.find(attribute => attribute.name === name)
					: attributes.find(attribute => attribute.name.toUpperCase() === name);
			}

			return selectedAttribute;
		}).filter(favAttr => !!favAttr);
	}

	private buildPreviewDashboardName = () => {
		const owner: string = this.security.getUser().userEmail;
		const date: string = this.dateService.format(new Date(), DateTimeFormat.BASIC_DATE);
		const baseName: string = this.locale.getString('docExplorer.sharedFeedbackDashboardTitle', {owner, date});

		return this.dashboardService.getUniqueDashboardName(baseName);
	}

	private setCreatedWidgetBox = (createdWidget: any): void => {
		createdWidget.posX = 1;
		createdWidget.posY = 0;

		const maximumWidth = 22;
		const maximumHeight = 20;

		createdWidget.width = maximumWidth;
		createdWidget.height = maximumHeight;
	}
}

app.service('dashboardFromSelection', downgradeInjectable(DashboardFromSelectionService));
