
import { Injectable, Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsHelperService {
	constructor(
		@Inject(Window) private window: Window,
		@Inject('security') private readonly security: Security,
	) {}

	getUserUniqueIdentifier(): string {
		const host = this.window.location.hostname;
		let prefix;
		switch (host) {
			case 'cxstudio.clarabridge.net': prefix = ''; break;
			case 'cxstudio-staging.clarabridge.net': prefix = 'staging-'; break;
			case 'cxstudio-dev.clarabridge.net': prefix = 'dev-'; break;
			default: prefix = 'other-'; break;
		}
		const userId = this.security.getUser().userId;
		return `${prefix}${userId}`;
	}

	isProduction(): boolean {
		const host = this.window.location.hostname;
		const isClarabridge = host.endsWith('.clarabridge.net');
		const isQualtrics = host.endsWith('.qualtrics.io');
		const isDevOrStaging = /(dev|stg|staging)/.test(host);
		
		return (isClarabridge || isQualtrics) && !isDevOrStaging;
	}
}

app.service('analyticsHelperService', downgradeInjectable(AnalyticsHelperService));
