<collapsing-panel disableCollapse="true" class="listeners-status">
	<panel-heading>{{'administration.jmsStatus'|i18n}}</panel-heading>
	<panel-body>
		<ngb-accordion closeOthers="true">
			<ngb-panel [type]="getEmailsListenersStatePanelType()">
				<ng-template ngbPanelTitle>
					{{'administration.emailQueues'|i18n}}
				</ng-template>
				<ng-template ngbPanelContent>
					<formatted-table
						[columns]="emailQueuesStatusColumns"
						[rows]="emailQueues"
					></formatted-table>
				</ng-template>
			</ngb-panel>
		</ngb-accordion>
	</panel-body>
</collapsing-panel>
