import { BoundaryType } from './boundary-type';

export class BoundaryTypeDefaults {

	static SIZE = {
		[ BoundaryType.COUNTRY ]: 200,
		[ BoundaryType.STATE ]: 55,
		[ BoundaryType.US_COUNTY ]: 3000,
		[ BoundaryType.POSTAL ]: 500
	};

}