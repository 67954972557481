import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DashboardEvent } from '@app/core/cx-event.enum';

@Injectable({
	providedIn: 'root'
})
export class VersionsHeaderService {

	private enabled: boolean = false;
	private dirty: boolean;
	private changeSubject: BehaviorSubject<boolean>;
	private change$: Observable<boolean>;

	constructor(
		private readonly eventBus: GlobalEventBus,
	) {
		this.changeSubject = new BehaviorSubject(this.enabled);
		this.change$ = this.changeSubject.asObservable();
	}

	isEnabled(): boolean {
		return this.enabled;
	}

	getObservable(): Observable<boolean> {
		return this.change$;
	}

	private setEnabled(value: boolean) {
		this.enabled = value;
		this.changeSubject.next(value);
	}

	setDirty(dirty: boolean): void {
		this.dirty = dirty;
	}

	enable(): void {
		this.setEnabled(true);
		this.dirty = false;
	}

	reset(): void {
		this.setEnabled(false);
		this.dirty = false;
	}

	disable(): void {
		this.setEnabled(false);
		if (this.dirty) {
			this.eventBus.broadcast(DashboardEvent.RELOAD);
			this.dirty = false;
		}
	}
}
app.service('versionsHeaderService', downgradeInjectable(VersionsHeaderService));
