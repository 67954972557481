<div id="text-editor">
	<div id="toolbar" class="btn-toolbar">
		<div class="btn-group">
			<button *ngFor="let item of textItems" type="button" class="btn btn-default" tabindex="-1"
				name="{{item.name}}" title="{{item.tooltip | i18n}}" [disabled]="item.disabled"
				[ngClass]="{active: item.active}" (mousedown)="item.action($event)"><i
					class="{{item.icon}}"></i></button>
		</div>

		<div class="btn-group" *ngIf="!isLabel">
			<simple-dropdown [options]="tag.options" [value]="tag.selected.value" valueField="value" displayField="name"
				noMinWidth="true" (onChange)="changeTag($event)" [disabled]="showHtml" title="{{tag.tooltip | i18n}}"
				id="{{tag.name}}" (mousedown)="$event.preventDefault()">
			</simple-dropdown>
		</div>
		<div class="btn-group">
			<simple-dropdown [options]="fontFamily.options" [value]="fontFamily.selected.name" valueField="name"
				noMinWidth="true" (onChange)="changeFont($event)" [disabled]="showHtml"
				title="{{fontFamily.tooltip | i18n}}" id="{{fontFamily.name}}" (mousedown)="$event.preventDefault()">
			</simple-dropdown>
		</div>
		<div class="btn-group">
			<simple-dropdown [options]="fontSize.options" [value]="fontSize.selected.value" sortField="value"
				noMinWidth="true" valueField="value" displayField="name" (onChange)="changeFontSize($event)"
				[disabled]="showHtml" title="{{fontSize.tooltip | i18n}}" id="{{fontSize.name}}"
				(mousedown)="$event.preventDefault()"></simple-dropdown>
		</div>

		<div class="btn-group">
			<color-dropdown
				[(ngModel)]="fontColor.selected"
				(ngModelChange)="fontColor.action()"
				[disabled]="fontColor.disabled"
				[showClear]="true"
				(clear)="fontColor.selected = null; fontColor.action()"
				(dropdownClose)="focus()">
				<button type="button"
					class="btn btn-default border-r-radius-0 border-r-0"
					tabindex="-1"
					name="{{fontColor.name}}"
					title="{{fontColor.tooltip | i18n}}"
					[disabled]="fontColor.disabled"
					(mousedown)="$event.preventDefault()">
					<i class="{{fontColor.icon}}"></i>
				</button>
			</color-dropdown>
			<color-dropdown
				[(ngModel)]="highlightColor.selected"
				(ngModelChange)="highlightColor.action()"
				[disabled]="highlightColor.disabled"
				[showClear]="true"
				(clear)="highlightColor.selected = null; highlightColor.action()"
				(dropdownClose)="focus()">
				<button type="button"
					class="btn btn-default border-l-radius-0"
					tabindex="-1"
					name="{{highlightColor.name}}"
					title="{{highlightColor.tooltip | i18n}}"
					[disabled]="highlightColor.disabled"
					(mousedown)="$event.preventDefault()">
					<i class="{{highlightColor.icon}}"></i>
				</button>
			</color-dropdown>
		</div>

		<div class="btn-group">
			<button type="button" class="btn btn-default" name="{{clearItem.name}}" tabindex="-1"
				title="{{clearItem.tooltip | i18n}}" [disabled]="clearItem.disabled"
				(mousedown)="clearItem.action($event)"><i class="{{clearItem.icon}}"></i></button>
		</div>

		<div class="btn-group" *ngIf="!isLabel">
			<button type="button" class="btn btn-default" name="{{image.name}}" tabindex="-1"
				title="{{image.tooltip | i18n}}" [disabled]="image.disabled"
				(mousedown)="image.action($event)"><i class="{{image.icon}}"></i></button>
		</div>

		<div class="btn-group" *ngIf="!isLabel">
			<button *ngFor="let item of listItems" type="button" class="btn btn-default" tabindex="-1"
				name="{{item.name}}" title="{{item.tooltip | i18n}}" [disabled]="item.disabled"
				[ngClass]="{active: item.active}" (mousedown)="item.action($event)"><i
					class="{{item.icon}}"></i></button>
		</div>

		<div class="btn-group" *ngIf="!isLabel">
			<button type="button" class="btn btn-default" tabindex="-1" name="{{quoteItem.name}}"
				title="{{quoteItem.tooltip | i18n}}" [disabled]="quoteItem.disabled"
				[ngClass]="{active: quoteItem.active}" (mousedown)="quoteItem.action($event)"><i
					class="{{quoteItem.icon}}"></i></button>
		</div>

		<div class="btn-group">
			<button *ngFor="let item of alignItems" type="button" class="btn btn-default" tabindex="-1"
				name="{{item.name}}" title="{{item.tooltip | i18n}}" [disabled]="item.disabled"
				[ngClass]="{active: item.active}" (mousedown)="item.action($event)"><i
					class="{{item.icon}}"></i></button>
		</div>

		<div class="btn-group" *ngIf="!isLabel">
			<button type="button" class="btn btn-default" tabindex="-1" name="html" title="{{'widget.toggleHTMLRichText'|i18n}}"
				[ngClass]="{active: showHtml}" (mousedown)="$event.preventDefault(); toggleHtml()"><i
					class="q-icon-code"></i></button>
			<button type="button" class="btn btn-default" name="{{linkItem.name}}" tabindex="-1"
				title="{{linkItem.tooltip | i18n}}" [disabled]="linkItem.disabled" [ngClass]="{active: linkItem.active}"
				(mousedown)="linkItem.action($event)"><i class="text-1rem {{linkItem.icon}}"></i></button>
		</div>

		<div ngbDropdown #rotationDropdown="ngbDropdown" class="btn-group" *ngIf="!isLabel">
			<button ngbDropdownToggle class="btn btn-default" name="rotation" tabindex="-1" type="button" [disabled]="showHtml"
				(mousedown)="$event.preventDefault()"><i [ngClass]="getOptionIcon(rotationOptions, rotation)"></i></button>
			<ul ngbDropdownMenu class="dropdown-menu text-left">
				<li *ngFor="let option of rotationOptions"><a class="icon-fixed-left"
						(mousedown)="rotate(option.value, $event); rotationDropdown.close()"><i
						class="{{option.icon}}"></i>{{option.name | i18n}}</a>
				</li>
			</ul>
		</div>

		<div ngbDropdown #valignDropdown="ngbDropdown" class="btn-group" *ngIf="!isLabel">
			<button ngbDropdownToggle class="btn btn-default" name="valign" tabindex="-1" type="button" [disabled]="showHtml"
				(mousedown)="$event.preventDefault()"><i [ngClass]="getOptionIcon(valignOptions, valign)"></i></button>
			<ul ngbDropdownMenu class="dropdown-menu text-left">
				<li *ngFor="let option of valignOptions"><a class="icon-fixed-left"
						(mousedown)="alignVert(option.value, $event); valignDropdown.close()"><i
						class="{{option.icon}}"></i>{{option.name | i18n}}</a>
				</li>
			</ul>
		</div>

		<div ngbDropdown #hierarchyDropdown="ngbDropdown" class="btn-group" *ngIf="!hierarchy.options.isEmpty()">
				<button ngbDropdownToggle class="btn btn-default" name="{{hierarchy.name}}" tabindex="-1"
					title="{{hierarchy.tooltip | i18n}}" type="button" [disabled]="hierarchy.disabled"
					(mousedown)="$event.preventDefault()"><i class="text-1rem {{hierarchy.icon}}"></i></button>
				<ul ngbDropdownMenu class="dropdown-menu text-left">
					<li *ngFor="let option of hierarchy.options"><a class="icon-fixed-right"
							(mousedown)="insertHierarchy(option, $event); hierarchyDropdown.close()">{{option}}</a>
					</li>
				</ul>
		</div>
	</div>
	<div class="text-wrapper" [hidden]="showHtml" [style.background-color]="backgroundColor">
		<div class="text-container"></div>
	</div>
</div>
<textarea id="html" [hidden]="!showHtml" [ngModel]="rawHtml" (input)="updateRawHtml($event.target.value)"
	(blur)="updateContent()" [attr.aria-label]="getLabel()"></textarea>
