import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { MetricColorDirection } from '@cxstudio/metrics/entities/metric-color-direction.enum';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PeriodOverPeriodMetricType } from '../providers/cb/period-over-period/period-over-period-metric-type';
import { DateFilter } from './date-filter';

export enum MetricComparisonType {
	TIME = 'TIME',
	GOAL = 'GOAL',
	HIERARCHY_ENRICHMENT = 'HIERARCHY_ENRICHMENT'
}

export interface GoalComparisonValue {
	goal: number;
}

export interface HierarchyIdentifier {
	id: number;
	name: string;
}

export interface HierarchyEnrichmentValue {
	hierarchy: HierarchyIdentifier;
	propertyName: string;
	displayName: string;
}

export type ComparisonDateRange = Pick<DateFilter, 'dateFilterMode' | 'from' | 'to'>;

export type ComparisonValue = ComparisonDateRange & Partial<GoalComparisonValue> & Partial<HierarchyEnrichmentValue>;

export interface MetricWidgetComparison {
	identifier: string;
	type: MetricComparisonType;
	calculation: PeriodOverPeriodMetricType;
	value: ComparisonValue;
	// ui properties
	label: string;
	format: NumberFormatSettings;
	inheritFormatFromMetric?: boolean;
	displayName?: string;
}

export interface MetricWidgetProperties extends WidgetProperties {
	comparisons?: MetricWidgetComparison[];
}
