import * as _ from 'underscore';
import { Inject, OnInit, Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { IRouteParams } from '@app/shared/providers/route-params-provider';

export type NotificationType = 'metric';

export interface IConfiguration {
	header: string;
	message: string;
	disableFunction: (descriptor: string, period: number) => Promise<string>;
}

@Component({
	templateUrl: './snooze-notifications.component.html'
})
export class SnoozeNotificationsComponent implements OnInit {
	constructor(
		private locale: CxLocaleService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		@Inject('$location') private $location: ng.ILocationService,
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('alertingApiService') private alertingApiService: AlertingApiService
	) {
	}

	private configurations: Record<NotificationType, IConfiguration>  = {
		metric: {
			header: this.locale.getString('notification.alertNotifications'),
			message: this.locale.getString('notification.alertNotificationsSnooze'),
			disableFunction: (descriptor: string, period: number) => {
				return Promise.resolve(this.alertingApiService.disableNotifications(descriptor, period));
			}
		}
	};

	ngOnInit(): void {
		const descriptor: string = this.$routeParams.descriptor;
		const type: string = this.$routeParams.type;
		const period: number = Number(this.$routeParams.period);
		const configuration = this.getConfiguration(type);

		if (!descriptor || !configuration) {
			this.redirectService.goToLogin();
			return;
		}

		// Remove URL parameters
		this.$location.url(this.$location.path());
		this.processRequest(descriptor, period, configuration);
	}

	private getConfiguration = (type: string) => {
		return this.configurations[type];
	}

	private processRequest = (descriptor: string, period: number, config: IConfiguration): void => {
		let dialog = this.cbDialogService.confirm(
			config.header, config.message,
			this.locale.getString('common.yes'), this.locale.getString('common.cancel'));

		(dialog.result as any as Promise<void>).then(() => {
			config.disableFunction(descriptor, period).then((result: string) => {
				this.cbDialogService.notify(config.header, result).result.then(() => {
					this.redirectService.goToLogin();
				}, this.redirectService.goToLogin);
			});
		}, this.redirectService.goToLogin);
	}
}

app.directive('snoozeNotifications', downgradeComponent({component: SnoozeNotificationsComponent}) as angular.IDirectiveFactory);
