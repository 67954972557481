<div class="dashboard-list-content flex-fill d-flex flex-direction-column overflow-hidden p-relative">
	<book-editor class="flex-fill overflow-hidden"
		*ngIf="tabsEditor.enabled && getDashboards()"
		[book]="tabsEditor.dashboard"
		(save)="onTabsSave($event)"
		(cancel)="onTabsCancel()"
		(createNew)="actionsService.enabletabsEditor()"
	></book-editor>
	<div *ngIf="!tabsEditor.enabled" class="h-100-percent">
		<quick-insights *ngIf="quickSearch"
			class="flex-fill m-16"
			[(searchValue)]="quickSearch"
			[dashboards]="getDashboards()">
		</quick-insights>
		<div *ngIf="!quickSearch" class="d-flex flex-direction-column overflow-hidden h-100-percent">
			<div class="p-relative overflow-y-auto">
				<home-page-header
					[(searchValue)]="quickSearch"
					(nodeChange)="onNodeChange($event)">
				</home-page-header>
				<div class="container-full-dashboard w-100-percent ph-24 pv-8">
					<home-page-widgets-panel class="mb-16 d-flex hide-empty"></home-page-widgets-panel>

					<dashboards-by-label class="mb-16 d-flex hide-empty"></dashboards-by-label>

					<section class="row" [attr.aria-label]="'dashboard.listSection'|i18n">
						<div class="col-sm-12">
							<div class="br-list-box" sticky-slick-header>
								<h2 class="font-bold mb-16">{{'dashboard.exploreDashboardsAndBooks' | i18n}}</h2>
								<dashboard-list-tools
									[isCurrentUserSelected]="isCurrentUserSelected"
									[ui]="ui"
									[selectedUser]="ui.selectedUser"
									(selectedUserChange)="onSelectedUserChange($event)"
									[actionsService]="actionsService"
									[selectionUtils]="selectionUtils"
									[permissions]="permissions"
									[isTablet]="isTablet"
									[loading]="loading"
									[gridMode]="gridMode"
									[getGridMenuItems]="contextMenuUtils.getShowHideOptions">
								</dashboard-list-tools>

								<mobile-dashboard-list
									*ngIf="isMobile"
									[dashboards]="getDashboards()"
									[changeDashboard]="gotoDashboard"
									[isCurrentUserSelected]="isCurrentUserSelected"
									[dashboardBurgerClick]="dashboardBurgerClick"
									[showDashboardRating]="showDashboardRating"
									[(search)]="ui.searchFilter"
									[changeFavoriteForDashboard]="changeFavoriteForDashboard">
								</mobile-dashboard-list>

								<div *ngIf="!isMobile">
									<item-grid
										class="w-100-percent br-dash-grid br-grid"
										[ngClass]="{'multiselectMode' : selectionUtils.multipleObjectsSelected(), 'fix-port-size': dashboardsFixedPortSize, 'h-100-percent': !dashboardsFixedPortSize}"
										[treeData]="getDashboards()"
										[gridFilter]="ui.searchFilter"
										[gridFilterFunction]="searchFilterFunction"
										[gridFilterCriteria]="searchFilterCriteria"
										[gridChange]="changedDashboards"
										[gridOptions]="gridOptions"
										[gridMode]="gridMode"
										[hideItems]="ui.hideDashboards"
										[gridType]="gridType"
										[gridColumns]="ui.dashboardsGridColumns"
										[controller]="gridController">
									</item-grid>
								</div>
							</div>
						</div>
					</section>
					<login-history-button ng-if="!isMobile" class="d-flex justify-end mt-8"></login-history-button>
				</div>
			</div>
			<dashboard-tooltip [tooltipStyle]="tooltipStyle">
				<dashboard-preview-tooltip *ngIf="tooltip.type === 'DASHBOARD'" [dashboard]="tooltip.dashboard"></dashboard-preview-tooltip>
				<dashboard-schedule-tooltip *ngIf="tooltip.type === 'SCHEDULE'" [dashboard]="tooltip.dashboard"></dashboard-schedule-tooltip>
				<dashboard-rating-tooltip
					*ngIf="showDashboardRating && tooltip.type === 'RATING'"
					[dashboard]="tooltip.dashboard"
					(onHover)="enterRating()"
					(onUpdate)="updateRating($event)">
				</dashboard-rating-tooltip>
			</dashboard-tooltip>
		</div>
	</div>

</div>
