import { ColorShadeUtils } from '@cxstudio/utilities/color-shade-utils.class';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { ParticipantEnrichmentTypes } from './entities/spine-lane.class';
import { ScorecardInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';

export enum ConversationSpineState {
	highlighted = 'highlighted',
	selected = 'selected',
	//clicked = 'clicked',
	hover = 'hover',
	topicHover = 'topicHover',
	topicHighlight = 'topicHighlight'
}

type ColorModifier = (color: string) => string;

interface IStyleConfig {
	classSuffix: string;
	fill?: ColorModifier;
	stroke?: ColorModifier;
	strokeWidth?: number;
}

type IConversationStyleConfigBase = {
	[state in ConversationSpineState]: IStyleConfig;
};
interface IConversationStyleConfig extends IConversationStyleConfigBase {
	default: IStyleConfig;
}

export class ConversationStyleUtils {
	static STYLE_PREFIX = 'cb-spine-';
	static SECONDARY_TRACK = 'cb-secondary-track-';
	static SENTIMENT_PREFIX = 'sentiment-color-';
	static EMOTION_PREFIX = 'emotion-color-';
	static EFFORT_PREFIX = 'effort-color-';
	static ENRICHMENT_PREFIX = 'cb-enrichment-color-';
	static CONVERSATION_PREFIX = 'cb-conv-';

	private static BASE_COLORS = {
		silence: '#ffffff',
		agent: '#ced2f7',
		client: '#babbc5',
		bot: '#78e8c7',
		unknown: '#babbc5'
	};

	private static COMMON_STYLE = `
		.cb-spine-item { cursor: pointer; }
		.cb-spine-item.filtered { opacity: .3 }
		.cb-secondary-track-1 .filtered,
		.cb-secondary-track-2 .filtered,
		.cb-secondary-track-3 .filtered,
		.cb-secondary-track-4 .filtered,
		[class *="cb-secondary-track"] .filtered { display: none; }
		[class *="cb-secondary-track"] > * { cursor: pointer }

		.${ConversationSpineState.topicHover} .cb-spine-item:not(.${ConversationSpineState.topicHover}) { opacity: .5 }
		.cb-spine-row { fill: none; }
		.cb-spine-row.${ConversationSpineState.topicHover} { fill: var(--blue-600)); }

		.cb-spine-row.selected, .cb-spine-row.selected.${ConversationSpineState.topicHover} { fill: none; opacity: 1; }

		.cb-spine-row.selected, .cb-spine-row.selected.${ConversationSpineState.topicHighlight} {
			fill: #fff;
			opacity: 1;
			stroke-width: 4.01px;
		}
		.${ConversationSpineState.topicHighlight} .cb-spine-item:not(.${ConversationSpineState.topicHighlight}) { opacity: .5 }

		.cb-spine-row.${ConversationSpineState.topicHighlight} {
			fill: #fff;
			stroke-width: 1px;
		}

		.cb-spine-row.selected {
			stroke-width: 1px;
			stroke: var(--blue-600));
		}
	`;

	/**
	 * primary color is not required for secondary tracks for now
	 */
	private static defaultConfig(): IConversationStyleConfig {
		return {
			default: {
				classSuffix: '',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			},
			highlighted: {
				classSuffix: '.highlighted',
				fill: c => c,
				stroke: c => 'var(--blue-700)',
				strokeWidth: 2
			},
			selected: {
				classSuffix: '.selected',
				fill: c => c,
				stroke: c => 'var(--blue-700)',
				strokeWidth: 2
			},
			/* clicked: {
				classSuffix: '.clicked',
				fill: ConversationColorUtils.shade500,
				stroke: ConversationColorUtils.shade300,
				strokeWidth: 4
			}, */
			hover: {
				classSuffix: ':hover',
				fill: ColorShadeUtils.shade100,
				stroke: ColorShadeUtils.shade500,
				strokeWidth: 1
			},
			topicHover: {
				classSuffix: '.topicHover',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			},
			topicHighlight: {
				classSuffix: '.topicHighlight',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			}
		};
	}

	private static darkModeConfig(): IConversationStyleConfig {
		return {
			default: {
				classSuffix: '',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			},
			highlighted: {
				classSuffix: '.highlighted',
				fill: c => c,
				stroke: c => 'var(--blue-700)',
				strokeWidth: 2
			},
			selected: {
				classSuffix: '.selected',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'var(--blue-700)',
				strokeWidth: 2
			},
			hover: {
				classSuffix: ':hover',
				fill: ColorShadeUtils.shade500,
				stroke: ColorShadeUtils.shade100,
				strokeWidth: 1
			},
			topicHover: {
				classSuffix: '.topicHover',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			},
			topicHighlight: {
				classSuffix: '.topicHighlight',
				fill: c => c === '#ffffff' ? 'transparent' : c,
				stroke: c => 'none',
				strokeWidth: 1
			}
		};
	}

	/**
	 * Generates classes like
	 *
	 * ```
	 * .cb-spine-sentiment-color-1{...}
	 * .cb-spine-sentiment-color-1:hover{...}
	 * .cb-spine-sentiment-color-1.selected{...}
	 * .cb-spine-silence{...}
	 * .cb-spine-agent{...}
	 * .cb-spine-client{...}
	 * ```
	 *
	 * 3 styles for each class ('',:hover,.selected)
	 *
	 * Each contains 'fill', 'stroke' and 'stroke-width' styles
	 */
	static getConversationChartStyle(enrichmentColorArray: string[], primaryColor: string,
		theme = ApplicationTheme.DEFAULT, enrichmentType = ParticipantEnrichmentTypes.SENTIMENT): string {
		let config = theme === ApplicationTheme.DEFAULT ? this.defaultConfig() : this.darkModeConfig();
		let colorClassPrefix = this.getStyleClassPrefix(enrichmentType);
		let allColors = angular.copy(this.BASE_COLORS);
		_.each(enrichmentColorArray, (color, index) => allColors[colorClassPrefix + index] = color);
		return this.minify(_.map(allColors, (color, className) => {
			let selectorBase = '.' + this.getSpineClass(className);
			return this.generateStylesForClass(selectorBase, color, config);
		}).join('\n') + this.COMMON_STYLE);
	}

	private static getStyleClassPrefix(enrichmentType: ParticipantEnrichmentTypes): string {
		switch (enrichmentType) {
			case ParticipantEnrichmentTypes.SENTIMENT:
				return this.SENTIMENT_PREFIX;
			case ParticipantEnrichmentTypes.EFFORT:
				return this.EFFORT_PREFIX;
			case ParticipantEnrichmentTypes.EMOTION:
				return this.EMOTION_PREFIX;
			case ParticipantEnrichmentTypes.NONE:
				return this.SENTIMENT_PREFIX;
			default:
				return this.SENTIMENT_PREFIX;

		}
	}

	static getSpineClass(baseClass: string): string {
		return this.STYLE_PREFIX + baseClass;
	}

	private static generateStylesForClass(selectorBase: string, baseColor: string,
		config: IConversationStyleConfig): string {

		return _.map(_.keys(config), type => {
			let styleType: IStyleConfig = config[type];
			let css = this.singleStyle(styleType.fill(baseColor), styleType.stroke(baseColor), styleType.strokeWidth);
			let style = `${selectorBase}${styleType.classSuffix} {
				${css}
			}`;
			return style;
		}).join('\n');
	}

	private static singleStyle(fillColor: string, stroke: string, strokeWidth: number): string {
		return `fill: ${fillColor};
			stroke: ${stroke};
			stroke-width: ${strokeWidth};`;
	}

	static getSecondaryTrackRootClass(trackIndex: number): string {
		return this.SECONDARY_TRACK + trackIndex;
	}

	/**
	 * Generates classes like
	 * .cb-secondary-track-2 .cb-enrichment-color-1{...}
	 * .cb-secondary-track-2 .cb-enrichment-color-1:hover{...}
	 * .cb-secondary-track-2 .cb-enrichment-color-1.highlighted{...}
	 * N styles for each color ('',:hover,.highlighted,.selected,...)
	 * Each contains 'fill', 'stroke' and 'stroke-width' styles
	 */
	static getSecondaryTrackStyle(enrichmentColors: string[], trackIndex: number, theme = ApplicationTheme.DEFAULT): string {
		let config = theme === ApplicationTheme.DEFAULT ? this.defaultConfig() : this.darkModeConfig();
		let parentClass = this.getSecondaryTrackRootClass(trackIndex);
		return this.minify(_.map(enrichmentColors, (color, index) => {
			let selectorBase = `.${parentClass} .${this.getSecondaryTrackClass(index)}`;
			return this.generateStylesForClass(selectorBase, color, config);
		}).join('\n'));
	}

	static getSecondaryTrackClass(colorIndex: number): string {
		return this.ENRICHMENT_PREFIX + colorIndex;
	}

	private static minify(style: string): string {
		// remove all whitespaces, but keep space between classes in selector (e.g. ".class1 .class2")
		return style.replace(/\s+(?!\.)/g, '');
	}

	static getScorecardVisibilityStyles(scorecards: ScorecardInDocView[]): string {
		return _.chain(scorecards)
			.map(scorecard => scorecard.id)
			.map(id => `.show-scorecard-${id} .scorecard-${id} { display: inline-flex !important; }`)
			.join('\n')
			.value();
	}

	static setDynamicStyleBlock(targetElement, style?: string, nonceAttr?: string): void {
		if (style?.length) {
			const styleBlock = `<style ${nonceAttr} type="text/css">${style}</style>`;
			targetElement.html(styleBlock);
		} else {
			targetElement.empty();
		}
	}

}
