import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@cxstudio/services/date-service.service';
// converts seconds to days, hours, minutes
// examples: 1 -> "<1m", 100.5 -> "1m", 18000 -> "5h", 165660 -> "1d 22h 1m"
@Pipe({
	name: 'secondsToDuration'
})
export class SecondsToDurationPipe implements PipeTransform {

	constructor(@Inject('dateService') private dateService: DateService) {}

	transform(seconds: number): string {
		return this.dateService.getElapsedTime(seconds);
	}
}