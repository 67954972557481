import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { MasterAccountsTableComponent } from '@app/modules/system-administration/master-account/master-accounts-table/master-accounts-table.component';
import { MasterAccountsListActions } from '@app/modules/system-administration/master-account/master-accounts-table/miscellaneous/master-accounts-list-actions.service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';

@Injectable()
export class MasterAccountsListMenu extends BaseContextMenuUtils {

	constructor(private locale: CxLocaleService, private masterAccountsListActions: MasterAccountsListActions) {
		super();
	}

	private getOptions(controller: MasterAccountsTableComponent): {[name: string]: ContextMenuItem<MasterAccount>} {
		return {
			EDIT: {
				name: 'edit',
				text: this.locale.getString('common.edit'),
				func: (masterAccount: MasterAccount) => this.masterAccountsListActions.edit(masterAccount, controller)
			},

			ENABLE: { 
				text: this.locale.getString('common.enable'),
				name: 'enable',
				func: (masterAccount: MasterAccount) => this.masterAccountsListActions.enable(masterAccount)
			},

			DISABLE: { 
				text: this.locale.getString('common.disable'),
				name: 'disable',
				func: (masterAccount: MasterAccount) => this.masterAccountsListActions.disable(masterAccount)
			},

			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (masterAccount: MasterAccount) => controller.deleteMasterAccount(masterAccount)
			},

			UPDATE_EXPIRATION: {
				name: '',
				text: this.locale.getString('common.updateExpiration'),
				func: (masterAccount: MasterAccount) => this.masterAccountsListActions.updateExpiration(masterAccount)
			}
		};
	}

	getContextMenu(masterAccount: MasterAccount, controller: MasterAccountsTableComponent): Array<ContextMenuItem<MasterAccount>> {
		const OPTIONS = this.getOptions(controller);

		let menuOptions: Array<ContextMenuItem<MasterAccount>> = [
			OPTIONS.EDIT,
			masterAccount.enabled ? OPTIONS.DISABLE : OPTIONS.ENABLE
		];

		if (masterAccount.isAdminOrg) {
			menuOptions.push(OPTIONS.UPDATE_EXPIRATION);
		}

		if (!masterAccount.enabled) {
			menuOptions.push(OPTIONS.DELETE);
		}

		return menuOptions;
	}

	isVisibleObject(item: any): boolean {
		return true;
	}
}