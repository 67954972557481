import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';

export class DocumentExplorerPageParams {
	contentProviderId: number;
	accountId: number;
	projectId: number;
	documentIds: number[];
	redirectTo: string;
	redirectId: number = 0;

	static fromSearchParams(searchParams: any): DocumentExplorerPageParams {
		if (_.isEmpty(searchParams) || ValueUtils.isNotSelected(searchParams.projectId)) {
			return null;
		}
		return new DocumentExplorerPageParams({
			contentProviderId: parseInt(searchParams.contentProviderId, 10),
			accountId: parseInt(searchParams.accountId, 10),
			projectId: parseInt(searchParams.projectId, 10),
		}, [].concat(searchParams.documentIds).map(id => parseInt(id, 10)), searchParams.redirectTo);
	}

	constructor(projectSelection: IProjectSelection, documentIds: number[], redirectTo?: string, redirectId?: number) {
		this.contentProviderId = projectSelection.contentProviderId;
		this.accountId = projectSelection.accountId;
		this.projectId = projectSelection.projectId;
		this.documentIds = documentIds;
		this.redirectTo = redirectTo;
		this.redirectId = redirectId;
	}

	toSearchParams(): any {
		return {
			contentProviderId: this.contentProviderId,
			accountId: this.accountId,
			projectId: this.projectId,
			documentIds: this.documentIds,
			redirectTo: this.redirectTo,
		};
	}
}
