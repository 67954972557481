<div class="d-flex text-default">
	<div class="flex-1" [class.w-100-percent]="selectedItem === null">
		<div class="form-group template-placeholder-group-title">
			<label class="col-sm-5 font-semibold">{{label}}</label>
			<label class="col-sm-7 font-semibold" [i18n]="'templates.placeholderText'"></label>
		</div>
		<div class="mt-16">
			<div *ngFor="let item of placeholders" class="form-group template-placeholder-item ml-0">
				<span (click)="selectRow(item)" [ngClass]="{'hover:bg-action-200 cursor-pointer' : item.usages && item.usages.length > 0}"
					class="h-min-2_5rem col-sm-5 p-0 pl-8 d-flex align-items-center">
					<span class="template-placeholder-original-display-name">
						{{item.placeholder.originalDisplayName}}
					</span>
					<span *ngIf="showUsageInline(item)"
						class="italic text-gray-400 ml-4">
						{{getUsageText(item.usages)}}
					</span>
				</span>
				<span class="p-0" [ngClass]="selectedItem ? 'col-sm-6' : 'col-sm-7'">
					<input type="text"
						class="template-placeholder-display-name-input"
						[(ngModel)]="item.placeholder.description"
						placeholder="{{item.placeholder.originalDisplayName}}">
				</span>
			</div>
		</div>
	</div>


	<aside *ngIf="selectedItem !== null" class="ml-auto w-320 border-solid border-1 border-gray-200" style="margin-right: -32px">
			<template-placeholder-usage
				[usages]="selectedItem?.usages"
				(dismiss)="onSidebarClose()"
				>
			</template-placeholder-usage>
	</aside>
</div>
