<modal-header
	[modalTitle]="'permission.updatePermissions' | i18n"
	(cancel)="cancel()">
</modal-header>

<div class="modal-body scroll-overflow" [ngBusy]="loading">
	<div class="mb-16">
		<label>{{input.bodyText}}</label>
	</div>
	<div *ngFor="let group of permissionGroups">
		<collapsing-panel [(collapsed)]="group.collapsed">
			<panel-heading><h3>{{group.name}}</h3></panel-heading>
			<panel-body>
				<user-bulk-update-table
					[items]="group.permissions"
					[gridType]="gridType"
					[updateTrigger]="updateTrigger"
					(update)="onUpdate($event)">
				</user-bulk-update-table>
			</panel-body>
		</collapsing-panel>
	</div>
</div>
<save-modal-footer
	(save)="save()"
	(cancel)="cancel()">
</save-modal-footer>
