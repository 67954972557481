import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BookApiService } from '@app/modules/book/book-api.service';
import { BookTabData } from '@app/modules/book/book-tab-data';
import { BookViewTab } from '@app/modules/book/book-view-tab';
import { ObjectUtils } from '@app/util/object-utils';
import { Book, BookDashboardTab, BookTabType, IBookTab } from '@cxstudio/dashboards/entity/book';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

export interface BookRequestParams {
	tabId?: string;
	tabVersionId?: string;
}

@Injectable({
	providedIn: 'root'
})
export class BookProcessingService {

	constructor(
		private readonly bookApi: BookApiService,
		@Inject('DashboardHistory') private readonly DashboardHistory,
	) { }

	getBookTabsData(book: Book, params?: BookRequestParams): Promise<BookViewTab[]> {
		let tabs = book.tabs;
		if (_.isEmpty(tabs)) return Promise.resolve([]);
		let httpParams = params ? new HttpParams().append('tabId', params.tabId).append('tabVersionId', params.tabVersionId) : undefined;
		return this.bookApi.getBookData(book.id, httpParams).then(bookData => {
			return _.map(tabs, tab => {
				if (tab.type === BookTabType.EMBED)
					return BookViewTab.embedTab(tab);
				else return this.getDashboardTab(tab, bookData);
			});
		});

	}

	private getDashboardTab(tab: IBookTab, bookData: BookTabData[]): BookViewTab {
		const tabData = tab.properties as BookDashboardTab;
		const bookTabData = bookData.find(data => data.dashboard.id === tabData.dashboardId);
		if (!bookTabData) {
			return BookViewTab.deletedDashboardTab(tab);
		}

		const dashboardData = ObjectUtils.copy(bookTabData);
		const dashboard = dashboardData.dashboard;
		dashboard.name = tab.name;

		if (dashboardData.frontline && dashboardData.dashboardSnapshot) {
			FrontlineDashboardUtils.mergeDashboardSnapshot(dashboard, dashboardData.dashboardSnapshot);
		}

		return BookViewTab.dashboardTab(tab, dashboardData, new this.DashboardHistory());
	}
}
