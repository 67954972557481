import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { BulkTransferDialogComponent } from '@app/modules/dashboard-list/bulk-transfer-dialog.component';
import { BulkUpdateLabelsModalComponent } from '@app/modules/dashboard-list/bulk-update-labels-modal.component';
import { DashboardsByLabelComponent } from '@app/modules/dashboard-list/dashboards-by-label/dashboards-by-label.component';
import { DashboardPreviewTooltipComponent } from '@app/modules/dashboard-list/tooltips/dashboard-preview-tooltip.component';
import { DashboardRatingTooltipComponent } from '@app/modules/dashboard-list/tooltips/dashboard-rating-tooltip.component';
import { DashboardScheduleTooltipComponent } from '@app/modules/dashboard-list/tooltips/dashboard-schedule-tooltip.component';
import { DashboardTooltipComponent } from '@app/modules/dashboard-list/tooltips/dashboard-tooltip.component';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { HomePageLayoutModule } from '@app/modules/home-page/home-page-layout/home-page-layout.module';
import { HomePageWidgetsModule } from '@app/modules/home-page/home-page-widgets/home-page-widgets.module';
import { QuickInsightsModule } from '@app/modules/home-page/quick-insights/quick-insights.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { ObjectListModule } from '@app/modules/object-list/object-list.module';
import { ScrollCarouselModule } from '@app/modules/scroll-carousel/scroll-carousel.module';
import { SharedModule } from '@app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { DashboardListToolsUpgrade } from './dashboard-list-tools-upgrade.directive';
import { DashboardListSectionComponent } from './home/dashboard-list-section/dashboard-list-section.component';
import { DashboardsHomePageComponent } from './home/dashboards-home-page/dashboards-home-page.component';
import { DashboardsTableToolbarComponent } from './home/dashboards-table-toolbar/dashboards-table-toolbar.component';
import { DashboardsTableComponent } from './home/dashboards-table/dashboards-table.component';
import { LoginHistoryButtonComponent } from './login-history-button/login-history-button.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { LoginHistoryService } from './login-history/login-history.service';
import { MobileDashboardListUpgrade } from './mobile-dashboard-list-upgrade.directive';
import { DashboardListPageComponent } from './page/dashboard-list-page.component';
import { PrepackagedTemplatesComponent } from './prepackaged-templates/prepackaged-templates.component';
import { TemplateTilesComponent } from './template-tiles/template-tiles.component';
import { DashboardPreviewImageComponent } from './tooltips/dashboard-preview-image.component';
import { DashboardTemplatesPageComponent } from './dashboard-templates-page/dashboard-templates-page.component';
import { CommonTemplatesModule } from '@app/modules/unified-templates/common-templates/common-templates.module';
import { CustomTemplatesUpgrade } from './dashboard-templates-page/custom-templates-upgrade.directive';
import DashboardGridDefinitionService from './services/dashboard-grid-definition.service';

@NgModule({
	imports: [
		SharedModule,
		DragDropModule,
		ScrollCarouselModule,
		DashboardModule,
		HomePageWidgetsModule,
		ItemGridModule,
		QuickInsightsModule,
		HomePageLayoutModule,
		DialogModule,
		AgGridModule,
		ObjectListModule,
		AssetManagementModule,
		CommonTemplatesModule,
	],
	exports: [
		DashboardPreviewImageComponent,
		DashboardPreviewTooltipComponent,
		DashboardTooltipComponent,
		TemplateTilesComponent,
		PrepackagedTemplatesComponent,
		DashboardTemplatesPageComponent,
	],
	declarations: [
		DashboardPreviewImageComponent,
		DashboardsByLabelComponent,
		DashboardPreviewTooltipComponent,
		DashboardScheduleTooltipComponent,
		DashboardRatingTooltipComponent,
		DashboardTooltipComponent,
		TemplateTilesComponent,
		PrepackagedTemplatesComponent,
		DashboardListToolsUpgrade,
		MobileDashboardListUpgrade,
		DashboardListPageComponent,
		BulkTransferDialogComponent,
		LoginHistoryComponent,
		LoginHistoryButtonComponent,
		DashboardsTableComponent,
		DashboardsHomePageComponent,
		DashboardsTableToolbarComponent,
		DashboardListSectionComponent,
		BulkUpdateLabelsModalComponent,
		DashboardTemplatesPageComponent,
		CustomTemplatesUpgrade,
	],
	providers: [
		CxDialogService,
		LoginHistoryService,
		DashboardGridDefinitionService
	]
})
export class DashboardListModule { }
