import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PopFormatterBuilder } from './pop-formatter-builder';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { IFormatBuilder, IFormatOptions } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

@Injectable({
	providedIn: 'root'
})
export class PopFormatterBuilderService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly applicationThemeService: ApplicationThemeService
	) {}

	getDeltaBuilder = (metric, builder): PopFormatterBuilder => {
		return new PopFormatterBuilder(this.locale as unknown as ILocale,
			metric, builder, PopDiffPrefix.DELTA, true, this.applicationThemeService);
	}

	getPercentChangeBuilder = (metric, builder): PopFormatterBuilder => {
		return new PopFormatterBuilder(this.locale as unknown as ILocale,
			metric, builder, PopDiffPrefix.PERCENT_CHANGE, false, this.applicationThemeService);
	}

	getSignificanceBuilder = (): IFormatBuilder => {
		let format = (value: number | string, options?: CalculationWithFormat, defaultFormat?: NumberFormatSettings,
				formatOptions: IFormatOptions = {}): string => {
			return this.formatSignificance(value);
		};

		let simpleFormat = (value: number | string, formatting?: NumberFormatSettings,
				formatOptions?: IFormatOptions): string => {
			return this.formatSignificance(value);
		};

		let getTopLevelSettings = (defaultOptions: NumberFormatSettings, options: ReportCalculation,
				defaultFormat?: NumberFormatSettings): NumberFormatSettings => {
			return {} as NumberFormatSettings;
		};

		return {
			format,
			simpleFormat,
			getTopLevelSettings
		};
	}

	private formatSignificance = (value: number | string): string => {
		if (value > 0) return '*'.repeat(value);
				return 'ns';
	}
}

app.service('popFormatterBuilderService', PopFormatterBuilderService);

