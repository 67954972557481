import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';

@Injectable({
	providedIn: 'root'
})
export class WidgetDashboardCommonFiltersProcessorService extends WidgetDashboardFilterProcessor {

	constructor(
		@Inject('dashboardFiltersService') private readonly dashboardFiltersService: DashboardFiltersService,
		@Inject('ignoredDashboardFilterService') private readonly ignoredDashboardFilterService: IgnoredDashboardFilterService
	) {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		let widgetSettings = filterApplication.widgetSettings;
		let dashboardFilters = filterApplication.filtersProvider.getAppliedFilters();

		let dashboardAttributeFilterRules = _.filter(dashboardFilters || [], filter => {
			return !this.ignoredDashboardFilterService.isIgnoredDashboardFilter(widgetSettings.properties,
				filterApplication.filtersProvider.getDashboard().properties, filter);
		}).filter(filter => this.isAttributeOrTopicFilter(filter))
		.map(filter => this.dashboardFiltersService.buildDashboardFilterRule(filter));

		widgetSettings.properties.dashboardAttributeFilters = {
			type: FilterTypes.AND,
			filterRules: dashboardAttributeFilterRules
		};
		return PromiseUtils.old(Promise.resolve());
	}

	private isAttributeOrTopicFilter(filter: DashboardFilter): boolean {
		return this.dashboardFiltersService.hasValue(filter)
			&& !filter.isDrillToDashboardFilter
			&& !DashboardFilterSelection.isSavedFilter(filter.selectedAttribute)
			&& !DashboardFilterSelection.isText(filter.selectedAttribute)
			&& !DashboardFilterSelection.isDateRange(filter.selectedAttribute);
	}

}
app.service('widgetDashboardCommonFiltersProcessor', downgradeInjectable(WidgetDashboardCommonFiltersProcessorService));
