import { Pagination } from '@app/shared/components/pagination/pagination';
import { PagedResponse } from '@cxstudio/paged-response';
import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';

import { CxHttpService } from '@app/core';

import { IExportHandler } from '@app/modules/asset-management/entities/export-handler.interface';
import { HttpParams } from '@angular/common/http';
import { AssetApiUtils } from './asset-api-utils.service';


export abstract class AbstractDependenciesApiService<T extends StudioAsset> implements IExportHandler<T> {

	protected abstract getBaseUrl: (asset: T) => string;

	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	getDependencies<R extends AssetDependency>(asset: T, postfix: string, pagination: Pagination): Promise<PagedResponse<R>> {
		let url: string = `${this.getBaseUrl(asset)}/${postfix}`;
		let params = AssetApiUtils.getPaginationQueryParams(pagination);
		return this.cxHttp.get(url, { params });
	}

	export(asset: T): Promise<void> {
		let url: string = `${ this.getBaseUrl(asset) }/export/xls`;
		let params: HttpParams = AssetApiUtils.getAssetNameQueryParams(asset);
		return this.cxHttp.get(url, { params });
	}

}
