import { ElementRef } from '@angular/core';
import { MetricCssVariables } from './metric-css-variables.class';
import { MetricWidgetSizer } from './metric-widget-sizer.class';

export class HorizontalMetricWidgetSizer extends MetricWidgetSizer {

	constructor() {
		super();
	}

	isFitHorizontal(widget: ElementRef, cssVars: Pick<MetricCssVariables, 'mainFontSize' | 'horizontalCompSpacing'>): boolean {
		let availableSpace = widget.nativeElement.offsetWidth;
		let neededSpace = (cssVars.mainFontSize * this.defaults.fontLetterWidthRatio * this.defaults.targetNumberOfDigits
			+ cssVars.mainFontSize * this.defaults.separatorCharacterWidthRatio
			* (this.defaults.targetNumberOfDigits + this.defaults.targetNumberOfSeparators))
			* 2 + cssVars.horizontalCompSpacing + (2 * this.defaults.margin);
		return availableSpace >= neededSpace;
	}

	isFitVertical(widget: ElementRef, cssVars: MetricCssVariables): boolean {
		let availableSpace = widget.nativeElement.offsetHeight;
		let neededSpace = cssVars.mainFontSize + this.defaults.labelSpacing + this.defaults.labelFontSize
			+ this.defaults.deltaSpacing + cssVars.deltaFontSize + (2 * this.defaults.margin);
		return availableSpace >= neededSpace;
	}

	isPeriodTextFit(widget: ElementRef, cssVars: MetricCssVariables): boolean {
		return this.isFitVertical(widget, cssVars) && this.isFitHorizontal(widget, cssVars);
	}

	getAdditionalCssVars = (widget: ElementRef, mainMetricSize: number): Partial<MetricCssVariables> => {
		let cssVars: Partial<MetricCssVariables> = {};
		cssVars.horizontalCompSpacing = mainMetricSize + 8;
		// horizontal doesnt have charts
		cssVars.chartSpacing = 0;
		cssVars.chartWidth = 0;

		return cssVars;
	}
}
