import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { BoxMetricDefinition } from '@app/modules/metric/definition/box-metric-definition';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { DriversFormatting } from '@cxstudio/drivers/utils/drivers-formatting.service';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { TimePrimaryGroup } from '@cxstudio/reports/attributes/time-primary-group.enum';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { DateFilterMode, HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { PredefinedMetricGrouping } from '@cxstudio/reports/groupings/predefined-metric-grouping';
import { MetricValues } from '@cxstudio/reports/providers/cb/constants/metric-values';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { CapitalizationUtils } from '@cxstudio/services/capitalization-utils.class';


export type IValueFormatter = (value: string) => string;

@Injectable({
	providedIn: 'root'
})
export class AnalyticsDataFormattingService {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('timePrimaryGroups') private readonly timePrimaryGroups: TimePrimaryGroupsService,
		@Inject('highchartsUtils') private readonly highchartsUtils: HighchartsUtilsService,
		@Inject('formatterBuilderUtils') private readonly formatterBuilderUtils: FormatterBuilderUtilsService,
		@Inject('driversFormatting') private readonly driversFormatting: DriversFormatting,
	) { }

	formatPeriodLabels(
			periodLabels: {[key in ReportPeriods]?: string},
			periods: {[key in ReportPeriods]?: DateFilterMode}): (period) => string {
		let labels = {};
		periodLabels = periodLabels || {};
		[ReportPeriods.CURRENT, ReportPeriods.HISTORIC].forEach((period) => {
			labels[period] = periodLabels[period];
			if (!labels[period] && periods) {
				let mode = DateRangeUtils.isCustomDateFilterMode(periods[period]) ? 'custom' : periods[period];
				labels[period] = this.locale.getString('dateRange.' + mode);
			}
		});
		if (labels[ReportPeriods.CURRENT] === labels[ReportPeriods.HISTORIC])
			labels[ReportPeriods.HISTORIC] += '(1)'; // cannot have identical items

		return (series) => {
			return labels[series];
		};
	}

	formatTimeLabel(value: string, timeName: TimePrimaryGroup, dateFilterMode?: DateFilterMode | HistoricDateFilterMode): string {
		let trendBy = this.timePrimaryGroups.getTrendBy(timeName);

		if (HistoricDateFilterMode.RUNNING_3_MONTHS === dateFilterMode) {
			value += ' - ' + this.locale.getString('dateRange.running3m');
		}

		return this.highchartsUtils.formatTrendLabel(value, trendBy);
	}

	timeGroupLabelFormatter(timeName: TimePrimaryGroup, labelFormatter: IValueFormatter): IValueFormatter {
		labelFormatter = labelFormatter || _.identity;
		return (value) => {
			return labelFormatter(this.formatTimeLabel(value, timeName));
		};
	}

	formatStudioMetricLabel(value: string, studioMetricDefinition: BoxMetricDefinition, labelFormatter?: IValueFormatter): string {
		labelFormatter = labelFormatter || _.identity;

		if (!studioMetricDefinition) {
			return labelFormatter(value);
		}
		let prefixLength = studioMetricDefinition.name.length + 1;
		let valueBox = _.isString(value) ? value.substr(prefixLength) : null;
		let returnValue;

		switch (valueBox) {
		case MetricValues.TOP_BOX:
			returnValue = studioMetricDefinition.topDisplayName;
			break;
		case MetricValues.MIDDLE_BOX:
			returnValue = studioMetricDefinition.middleDisplayName;
			break;
		case MetricValues.BOTTOM_BOX:
			returnValue = studioMetricDefinition.bottomDisplayName;
			break;
		case MetricValues.OTHER_BOX:
			returnValue = studioMetricDefinition.otherDisplayName;
			break;
		default:
			returnValue = value;
		}
		return labelFormatter(returnValue);
	}

	studioMetricLabelFormatter(studioMetricDefinition: BoxMetricDefinition, labelFormatter?: IValueFormatter): IValueFormatter {
		labelFormatter = labelFormatter || _.identity;
		return value => labelFormatter(this.formatStudioMetricLabel(value, studioMetricDefinition));
	}

	predefinedMetricFormatter(attribute: PredefinedMetricGrouping, labelFormatter: IValueFormatter): IValueFormatter {
		labelFormatter = labelFormatter || _.identity;
		let func = this.buildPredefinedMetricFormatter(attribute);
		return (value) => {
			return labelFormatter(func(value));
		};
	}

	buildPredefinedMetricFormatter(attribute: PredefinedMetricGrouping): IValueFormatter {
		// groupings will rely on rawName, while other places (legend, tooltip) rely on predefined attribute from db
		// need to refactor to use db-based object here (not the grouping)
		return (value) => {
			return !/Sent$/.test(value)
				? value : this.locale.getString('metrics.' + (attribute.rawName || (attribute as any).definition.name) + '_' + value);
		};
	}

	numericGroupFormatter(): IValueFormatter {
		return (value) => this.formatterBuilderUtils.formatNumberAsString(value);
	}

	getFormatterFromGroup(group, studioMetrics): IValueFormatter {
		let labelFormatter = CapitalizationUtils.getWrappedFormatter(group.capitalization);

		if (AnalyticMetricTypes.isStudioMetric(group)) {
			let definition;
			if (studioMetrics) {
				definition = this.getStudioMetricDefinition(group.id, studioMetrics);
				//If definition null, no studio metric can be found, so show format error
				if (!definition) {
					return definition;
				}
			}
			return this.studioMetricLabelFormatter(definition, labelFormatter);
		} else if (AnalyticMetricTypes.isTime(group)) {
			return this.timeGroupLabelFormatter(group.timeName, labelFormatter);
		} else if (AnalyticMetricTypes.isPredefinedGroup(group)) {
			return this.predefinedMetricFormatter(group, labelFormatter);
		} else if (AnalyticMetricTypes.isNumber(group)) {
			return this.numericGroupFormatter();
		} else if (AnalyticMetricTypes.isDrivers(group)) {
			return this.driversFormatting.getDriverFormatter(labelFormatter);
		}

		return (value) => labelFormatter(value);
	}

	getStudioMetricDefinition(metricId: number, studioMetrics: Metric[]): MetricDefinition {
		let metric = _.findWhere(studioMetrics, {id: metricId});
		return metric ? metric.definition : null;
	}
}

app.service('analyticsDataFormatting', AnalyticsDataFormattingService);
