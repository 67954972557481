import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import UserBulkUpdateGridDefinitionService from './user-bulk-update-grid-definition.service';

@Injectable({
	providedIn: 'root'
})
export default class GroupsUserGridDefinitionService extends UserBulkUpdateGridDefinitionService {

	updateType: string = 'administration.emailAddress';

	constructor(
		protected locale: CxLocaleService,
		@Inject('gridFormatterService') protected gridFormatterService
	) {
		super(locale, gridFormatterService);
	}
}

app.service('groupsUserGridDefinition', downgradeInjectable(GroupsUserGridDefinitionService));
