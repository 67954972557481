import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Unit } from '@app/modules/units/unit';
import { LabelPosition } from '@app/modules/units/workspace-project-selector/label-position';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import WidgetService from '@cxstudio/services/widget-service';

@Component({
	selector: 'widget-properties-tab',
	templateUrl: './widget-properties-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPropertiesTabComponent implements OnInit {
	@Input() widget: Widget;
	@Output() loading = new EventEmitter<Promise<unknown>>();
	@Output() projectChange = new EventEmitter<WorkspaceProject>();
	@Output() projectReset = new EventEmitter<WorkspaceProject>();
	@Input() refreshTrigger: unknown;

	LabelPosition = LabelPosition;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('security') private security: Security,
		@Inject('widgetService') private widgetService: WidgetService,
		private widgetDescriptionService: WidgetDescriptionService,
	) { }

	ngOnInit(): void {
		if (!this.widget.embedConfig) {
			this.widget.embedConfig = {
				enabled: false,
				showDashboardLink: true
			};
		}

		//set existing widget autoDescription to true when description is empty, avoid large upgrade script
		if (this.widget.properties.autoDescription === undefined) {
			this.widget.properties.autoDescription = !this.widget.description;
		}

		//only update if description is empty, otherwise use the description from settings
		if (!this.widget.description && this.widget.properties.autoDescription) {
			this.updateAutoDescription();
		}
	}

	hasEmbedPermission = () => this.security.has('embed_widgets');

	updateAutoTitle(autoTitle: boolean): void {
		this.widget.properties.isCustomTitle = !autoTitle;
		if (autoTitle) {
			const promise = this.widgetService.updateAutoTitleAsync(this.widget).then(() => {
				this.ref.markForCheck();
			});
			this.loading.emit(promise);
		}
	}

	isProjectSelected(): boolean {
		return WorkspaceProjectUtils.isProjectSelected(this.widget.properties.workspaceProject);
	}

	updateAutoDescription(): void {
		if (this.widget.properties.autoDescription) {
			const promise = this.widgetDescriptionService.generateDescription(this.widget).then((description) => {
				this.widget.description = description;
				this.ref.markForCheck();
			});
			this.loading.emit(promise);
		}
	}

	updateAltText(): void {
		if (this.isChartWidget() && this.widget.properties.altTextFromTitle) {
			this.widget.properties.altText = this.widget.displayName;
		}
	}

	onProjectInit(): void {
		this.projectChange.emit(this.widget.properties.workspaceProject);
	}

	onProjectChange(workspaceProject: WorkspaceProject): void {
		let needReset = this.isProjectSelected();
		this.widget.properties.workspaceProject = workspaceProject;
		this.widget.properties.project = workspaceProject.projectId;
		if (needReset) {
			this.projectReset.emit(workspaceProject);
		} else {
			this.projectChange.emit(workspaceProject);
		}
	}

	onWorkspaceChange(workspace: Unit): void {
		if (workspace) {
			this.widget.properties.contentProviderId = workspace.contentProviderId;
			this.widget.properties.accountId = workspace.accountId;
		} else {
			this.widget.properties.contentProviderId = -1;
			this.widget.properties.accountId = -1;
		}
	}

	isChartWidget(): boolean {
		return ReportConstants.isChartWidget(this.widget.properties.widgetType);
	}
}
