import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { DateService } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';

@Injectable()
export class SecurityAuditGridDefinitionService {

	constructor(
		private locale: CxLocaleService,
		@Inject('dateService') private dateService: DateService) { }

	init = (): Promise<any> => {
		let columns = [{
			id: 'source',
			name: this.locale.getString('widget.source'),
			field: 'source',
			sortable: true,
			width: 60,
			cssClass: 'source'
		}, {
			id: 'userName',
			name: this.locale.getString('mAccount.subject'),
			field: 'userName',
			sortable: true,
			width: 220,
			cssClass: 'userName'
		}, {
			id: 'objectName',
			name: this.locale.getString('mAccount.object'),
			field: 'objectName',
			sortable: true,
			width: 220,
			cssClass: 'objectName'
		}, {
			id: 'action',
			name: this.locale.getString('mAccount.action'),
			field: 'action',
			sortable: true,
			width: 220,
			cssClass: 'action',
			formatter: this.actionColumnFormat
		}, {
			id: 'performerTimestamp',
			name: this.locale.getString('mAccount.actionDate'),
			field: 'performerTimestamp',
			sortable: true,
			width: 150,
			cssClass: 'performerTimestamp',
			headerCssClass: 'actionDateHeader',
			formatter: this.performerDateFormatter
		}, {
			id: 'actionServerDate',
			name: this.locale.getString('mAccount.actionServerDate'),
			field: 'actionDate',
			sortable: true,
			width: 150,
			cssClass: 'actionDate',
			headerCssClass: 'actionDateHeader',
			formatter: this.serverDateFormatter

		}, {
			id: 'targetName',
			name: this.locale.getString('mAccount.target'),
			field: 'targetName',
			sortable: true,
			width: 220,
			cssClass: 'targetName'
		}, {
			id: 'details',
			name: this.locale.getString('mAccount.details'),
			field: 'details',
			sortable: false,
			width: 80,
			cssClass: 'auditRecordDetails',
			formatter: this.detailsColumnFormat
		},
		{
			id: 'apiCall',
			name: this.locale.getString('mAccount.apiCall'),
			field: 'apiCall',
			sortable: true,
			width: 220,
			cssClass: 'apiCall'
		}, {
			id: 'responseCode',
			name: this.locale.getString('mAccount.responseCode'),
			field: 'responseCode',
			sortable: true,
			width: 140,
			cssClass: 'responseCode'
		}];
		return Promise.resolve(columns);
	}

	private actionColumnFormat: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		return (dataContext.impersonateMode === true) ? `*${value}` : value;
	}

	private detailsColumnFormat: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (!value) {
			return;
		}

		return `<button href="javascript:void(0)" class="burger-wrap" title="${this.locale.getString('administration.details')}">
			<i class="q-icon-document"></i></button>`;
	}

	private serverDateFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (!value) return;

		let momentObj = moment.parseZone(value);
		let date = new Date(momentObj.year(), momentObj.month(), momentObj.date(), momentObj.hour(), momentObj.minute(), momentObj.second());
		return this.dateService.format(date);
	}

	private performerDateFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (!value) return;
		let momentObj = moment(value).utc();
		let date = new Date(momentObj.year(), momentObj.month(), momentObj.date(), momentObj.hour(), momentObj.minute(), momentObj.second());
		return this.dateService.format(date);
	}
}

app.service('securityAuditGridDefinition', downgradeInjectable(SecurityAuditGridDefinitionService));
