import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { SentenceTopic, PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { SentenceChunk } from '@cxstudio/reports/preview/sentence-chunk-class';
import { SentenceSentiment } from '@cxstudio/reports/preview/sentence-sentiment';

@Injectable({
	providedIn: 'root'
})
export class FeedbackDataGeneratorService {

	private lipsum: string[];
	private dataGenerators: {[key in PreviewDataType]: () => any};
	private topicsGenerator: () => SentenceTopic[];

	constructor(
		private locale: CxLocaleService
	) {
		this.lipsum = 'Lorem ipsum dolor sit amet consectetur adipiscing elit Aliquam eu ullamcorper libero Nunc odio turpis pretium'
			.split(' ');
		this.dataGenerators = {
			NUMBER: () => {
				return Number(Math.random() * 1000).toFixed(2);
			},
			TEXT: () => {
				return this.lipsum[Math.floor(Math.random() * this.lipsum.length)];
			},
			sys: () => {
				return this.lipsum[Math.floor(Math.random() * this.lipsum.length)];
			}
		};
		this.topicsGenerator = (): SentenceTopic[] => {
			let wordsCount = Math.ceil(Math.random() * 3);
			let multiTopics = [];

			for (let i = 0; i <= wordsCount; i++) {
				multiTopics.push({ id: i, name: this.lipsum[Math.floor(Math.random() * this.lipsum.length)] });
			}
			return multiTopics;
		};
	}

	getPreviewDemoData(): PreviewSentence[] {
		return [{
			chunks: this.parseChunks(this.locale.getString('widget.samplePreview1')),
			id: 19448.0,
			documentId: 8232.0,
			verbatimId: 11225.0,
			verbatimType: 'twitter',
			documentDate: '2015-03-31T17:51:31.000+00:00',
			dScore: -3.5,
			easeScore: 2,
			sentiment: SentenceSentiment.STRONGLY_NEGATIVE,
			smService: 'twitter',
			userName: 'user name',
			feedbackTextComplete: true,
			attributes: {}
		}, {
			chunks: this.parseChunks(this.locale.getString('widget.samplePreview2')),
			id: 19804.0,
			documentId: 8388.0,
			verbatimId: 11588.0,
			verbatimType: 'twitter',
			documentDate: '2015-03-27T16:17:25.000+00:00',
			dScore: 0.0,
			easeScore: -4,
			sentiment: SentenceSentiment.NEGATIVE,
			smService: 'facebook',
			userName: 'user name',
			feedbackTextComplete: true,
			attributes: {}
		}, {
			chunks: this.parseChunks(this.locale.getString('widget.samplePreview3')),
			id: 7060.0,
			documentId: 679.0,
			verbatimId: 773.0,
			verbatimType: 'twitter',
			documentDate: '2015-12-18T00:00:00.000+00:00',
			dScore: 1.0,
			easeScore: 0,
			sentiment: SentenceSentiment.POSITIVE,
			feedbackTextComplete: true,
			attributes: {
				_id_source: 'other'
			}
		}];
	}

	addFieldToData(rows: PreviewSentence[], option: AttributeGrouping): void {
		_.forEach(rows, (sentence: PreviewSentence, index: number) => {
			if (option.name === 'topics') {
				sentence.topics = this.topicsGenerator();
			} else {
				let sampleValue = this.dataGenerators[option.type] ? this.dataGenerators[option.type]() : index;
				sentence.attributes[option.name.toLowerCase()] = sampleValue;
			}
		});
	}

	parseChunks(text: string): SentenceChunk[] {
		return this.convertSentimentString(text);
	}

	private trimSentimentIdentifier(str: string): string {
		return str.replace(/(\s*%\w+\s*|{[\w\s]+})/, ' ');
	}

	private fixSpacing(str: string): string {
		return str.replace(/\s+/g, ' ').trim() + ' ';
	}

	private getSentimentDescriptors(str: string): string[] {
		let sentiment = str.match(/[%{]([\w]+)}?/);

		if (sentiment && sentiment.length > 0) {
			let descriptorWithSuffix = /Sentiment$/.test(sentiment[1]) ? sentiment[1] : sentiment[1] + 'Sentiment';
			return [descriptorWithSuffix];
		} else {
			return [];
		}
	}

	private convertSentimentString(str: string): SentenceChunk[] {
		let array = str.split('|');

		return _.map(array, (item): SentenceChunk => {
			return {
				text: this.fixSpacing(this.trimSentimentIdentifier(item)),
				descriptors: this.getSentimentDescriptors(item),
			};
		});
	}
}

app.service('feedbackDataGenerator', downgradeInjectable(FeedbackDataGeneratorService));
