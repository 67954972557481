import { Injectable } from '@angular/core';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';

@Injectable({
	providedIn: 'root'
})
export class CustomMetricExpressionProvider {
	getOperators = (): ExpressionItem[] => {
		return [
			new ExpressionItem(ExpressionPieces.SYMBOL, '+', '+'),
			new ExpressionItem(ExpressionPieces.SYMBOL, '-', '-'),
			new ExpressionItem(ExpressionPieces.SYMBOL, 'X', '*'),
			new ExpressionItem(ExpressionPieces.SYMBOL, '/', '/'),
			new ExpressionItem(ExpressionPieces.SYMBOL, '(', '('),
			new ExpressionItem(ExpressionPieces.SYMBOL, ')', ')'),
			new ExpressionItem(ExpressionPieces.ABSOLUTE, '| <i>x</i> |'),
			new ExpressionItem(ExpressionPieces.EXPONENT, '<i>x</i><sup>y</sup>')
		];
	}
}

app.service('customMetricExpressionProvider', CustomMetricExpressionProvider);
