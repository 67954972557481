import { FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';

export default class ExplorerSectionGroup {
	key: FavoriteType;
	visible: boolean;

	constructor(name: FavoriteType, visible) {
		this.key = name;
		this.visible = visible;
	}
}