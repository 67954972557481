<div
	class="d-flex align-center justify-between pv-2 ph-8 text-0_875rem bg-gray-400 border-solid border-1 border-b-radius-2 mb-16">
	<div>
		{{ "administration.passwordStrength" | i18n }}: {{ getStrengthText() }}
	</div>
	<div class="w-40-percent h-8 bg-gray-600">
		<div class="h-100-percent" [ngClass]="getStrengthLineColorClass()"
			[ngStyle]="{ width: getStrengthLineWidth() }"></div>
	</div>
</div>
