import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { BetaFeatureTracker } from '@app/modules/context/beta-features/beta-feature-tracker-class';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { LocallyStoredItems } from '@app/core/storage/locally-stored-items';

@Injectable()
export class BetaFeaturesGridDefinition implements IGridDefinition<BetaFeatureTracker> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		private gridUtils: GridUtilsService,
		@Inject('sessionPreferences') private sessionPreferences: SessionPreferencesService,
	) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [{
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: false,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true
		}, {
			id: 'description',
			name: this.locale.getString('common.description'),
			field: 'description',
			sortable: false,
			minWidth: 300,
			width: 800,
			cssClass: 'cell-description',
			formatter: this.gridFormatterService.buildCellWithTitleFormatter(),
			searchable: false,
		// }, {
		// 	id: 'isRequested',
		// 	name: this.locale.getString('common.requestAccess'),
		// 	field: 'isRequested',
		// 	sortable: false,
		// 	minWidth: 120,
		// 	width: 120,
		// 	cssClass: 'd-flex justify-center align-center',
		// 	formatter: this.buildRequestButton,
		// 	headerCssClass: 'text-center',
		// 	searchable: false,
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.gridFormatterService.buildToggleSwitchFormatter(
				'name',
				(dataContext) => !dataContext.available
			),
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}];

		return Promise.resolve(columns);
	}

	buildRequestButton: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (dataContext.available) return '';

		const featureInLocalStorage = this.sessionPreferences.get(LocallyStoredItems.betaFeaturesRequested.storageName);
		const wasRequested = featureInLocalStorage?.find(entry => entry.featureId === dataContext.id);

		const requestedLabel = this.locale.getString('administration.featureRequestedPending', dataContext.name);
		const requestLabel = this.locale.getString('administration.requestLabel', dataContext.name);
		const label = wasRequested ? requestedLabel : requestLabel;

		return `
            <button
                class="${!wasRequested ? 'js-btn-request' : 'cursor-default'} btn btn-secondary no-background no-border p-0"
                title="${this.gridUtils.replaceHTML(label)}"
                aria-label="${this.gridUtils.replaceHTML(label)}"
                >
                    <span class="${wasRequested ? 'q-icon-pending' : 'q-icon-raise-hand'}"></span>
            </button>
        `;
	}
}

app.service('betaFeaturesGridDefinition', downgradeInjectable(BetaFeaturesGridDefinition));
