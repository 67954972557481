<div id="real-data-preview" class="p-relative h-min-320 bg-white d-flex flex-direction-column mt-8 border-radius-2"
	 [ngStyle]="getWidgetDimensions()">
	<div *ngIf="canRunUpdate() && !isAutoPreview()"
		id="preview-bar"
		class="d-flex justify-between align-center border-solid border-b-0 border-t-1 border-h-1 border-gray-600">
		<div class="p-8 m-8">
			<span class="q-icon q-icon-info m-8" aria-hidden="true"></span>
			<span>{{'widget.previewNotificationBarText'|i18n}}</span>
		</div>
		<button
			type="button"
			class="btn btn-text m-8"
			(click)="runReport()">
			{{'common.updatePreview'|i18n}}
		</button>
	</div>
	<div *ngIf="hasWidgetHeader()" class="br-widget-header d-flex flex-direction-row align-center">
		<widget-title-container [widget]="widget" [editMode]="false" [widgetActions]="null" [predefinedTitle]="''"> <!-- no need to flex fill here-->
			<widget-title [title]="widget.displayName"></widget-title>
		</widget-title-container>
		<filter-applied-dropdown
			[isDocExplorer]="false"
			class="flex-fill"
			[textFilter]="''"
			[appliedFilters]="appliedFilters">
		</filter-applied-dropdown>
	</div>

	<div *ngIf="getCurrentView() === RealDataViewMode.INVALID_PROPERTIES"
		class="d-flex justify-center align-center empty-preview-container mb-8 h-min-320">
		<div class="text-center d-flex flex-direction-column">
			<label class="font-bold">{{'widget.previewUnavailable'|i18n}}</label>
			<label *ngIf="!isCalculationOnlyWidget()">{{'widget.previewUnavailableGroupingText'|i18n}}</label>
			<label *ngIf="isCalculationOnlyWidget()">{{'widget.previewUnavailableMetricText'|i18n}}</label>
		</div>
	</div>
	<div *ngIf="getCurrentView() === RealDataViewMode.NEED_UPDATE"
		class="d-flex justify-center align-center empty-preview-container mb-8 h-min-320">
		<div class="text-center d-flex flex-direction-column">
			<label>{{'widget.previewRequireUpdate'|i18n}}</label>
		</div>
	</div>
	<ng-container *ngIf="getCurrentView() === RealDataViewMode.CHART">
		<div *ngIf="showViewWrapper(widget)"
			class="h-100-percent w-100-percent widget-main-content flex-grow chart-demo mt-0 mb-0 p-relative">
			<report-view-wrapper class="h-100-percent w-100-percent pb-16"
				[visualization]="getVisualization()"
				[trigger]="trigger"
				[widget]="widget"
				[data]="data"
				[demo]="true"
				[utils]="utils"
			></report-view-wrapper>
		</div>
		<widget-content
			*ngIf="isNgWidgetContent(widget)"
			class="chart-demo d-block p-relative pb-32 h-min-320 h-320"
			[class.m-0]="hasChanges"
			[view]="getVisualization()"
			[trigger]="trigger"
			[widget]="widget"
			[data]="data"
			[utils]="utils">
		</widget-content>
	</ng-container>
	<widget-total-count
		*ngIf="showTotal()"
		[ngClass]="{'br-widget-total-real-preview mr-8': !hasWidgetHeader(), 'p-relative w-100 mr-16': hasWidgetHeader()}"
		class="align-self-end"
		[style.bottom]="hasWidgetHeader() ? '32px': '8px'"
	>{{total}}</widget-total-count>
</div>
