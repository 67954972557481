import { FilterMetricDefinition } from './filter-metric-definition.class';

export enum GlobalOtherMetricName {
	GLOBAL_OTHER_PARENT_PERCENT = 'globalOtherParentPercent',
	GLOBAL_OTHER_VOLUME = 'globalOtherVolume'
}

export class GlobalOtherMetric extends FilterMetricDefinition {
	name: GlobalOtherMetricName;
	modelId: number;
	constructor(metricName: GlobalOtherMetricName, modelId: number) {
		super();
		this.name = metricName;
		this.modelId = modelId;
		this.calculationName = metricName === GlobalOtherMetricName.GLOBAL_OTHER_VOLUME ? 'volume' : 'parentPercent';
		this.calculationOperator = 'sys';
	}
}