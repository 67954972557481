import { Injectable, NgZone } from '@angular/core';
import { asyncScheduler, SchedulerLike, Subscription } from 'rxjs';
import { debounceTime, observeOn } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DebounceUtilsService {
	constructor(
		private readonly zone: NgZone,
	) {}

	// this is required for repeated timeouts to not affect angular stable state by running setTimeout outside of angular zone
	// see https://stackoverflow.com/questions/43121400/run-ngrx-effect-outside-of-angulars-zone-to-prevent-timeout-in-protractor
	ngZoneLeaveDebounce(timeout: number) {
		return debounceTime(timeout, new LeaveZoneScheduler(this.zone, asyncScheduler));
	}

	// this should be called after ngZoneLeaveDebounce to enter angular again
	ngZoneEnterCallback() {
		return observeOn(new EnterZoneScheduler(this.zone, asyncScheduler));
	}
}

class LeaveZoneScheduler implements SchedulerLike {
	constructor(private zone: NgZone, private scheduler: SchedulerLike) { }

	now (): number {
		return this.scheduler.now();
	}

	schedule(...args: any[]): Subscription {
		return this.zone.runOutsideAngular(() =>
			this.scheduler.schedule.apply(this.scheduler, args)
		);
	}

}

class EnterZoneScheduler implements SchedulerLike {
	constructor(private zone: NgZone, private scheduler: SchedulerLike) { }

	now (): number {
		return this.scheduler.now();
	}

	schedule(...args: any[]): Subscription {
		return this.zone.run(() =>
			this.scheduler.schedule.apply(this.scheduler, args)
		);
	}
}
