import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { Component, ChangeDetectionStrategy, Input, OnInit, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScorecardPreviewResult } from '@cxstudio/projects/scorecards/entities/scorecard-preview-result';


export interface IScorecardPreviewResultParams {
	scorecard: Scorecard;
	props: IProjectSelection;
	isNew: boolean;
}

export type PercentilesMap = {
	[percentile in Percentiles]: number;
};

export enum Percentiles {
	Q1 = 25,
	Q2 = 50,
	Q3 = 75
}


@Component({
	selector: 'scorecard-preview-result-modal',
	templateUrl: './scorecard-preview-result-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorecardPreviewResultModalComponent implements OnInit {

	@Input() input: IScorecardPreviewResultParams;

	private readonly SAMPLE_SIZE: number = 10000;

	private readonly MIN_HEIGHT = '410px';
	private readonly NO_RESULTS_MIN_HEIGHT = '20px';

	loading: ng.IPromise<any>;
	outcomeText: string;
	minMaxScoreText: string;
	percentilesText: string;
	averageScore: number;
	targetScore: number;
	potentialCoverageText: string;
	clearCacheTooltip: string;
	showNoResultsMessage: boolean;

	constructor(
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		private locale: CxLocaleService,
		private modal: NgbActiveModal,
	) {}

	ngOnInit(): void {
		this.showNoResultsMessage = false;
		this.clearCacheTooltip = this.locale.getString('scorecards.clearCacheTooltip', {sampleSize: this.SAMPLE_SIZE});
		this.loadPreview(false);
	}

	loadPreview = (isNewSample: boolean): void => {
		this.loading = this.scorecardsApiService.getPreviewResult(
			this.input.props, this.input.scorecard, this.input.isNew, isNewSample, this.SAMPLE_SIZE
		).then((result: ScorecardPreviewResult) => {
			if (result.sampleSize === 0) {
				this.showNoResultsMessage = true;
			} else {
				this.targetScore = this.input.scorecard.threshold;

				let textParams = {
					sampleSize: result.sampleSize,
					hitsBelowThreshold: result.hitsBelowThreshold,
					percentBelowTarget: this.getDoubleWithPrecision(result.percentBelowTarget),
					targetScore: this.targetScore
				};
				this.outcomeText = this.locale.getString('scorecards.outcomeText', textParams);

				let minScore: number = this.getDoubleWithPrecision(result.minScore);
				let maxScore: number = this.getDoubleWithPrecision(result.maxScore);
				this.minMaxScoreText = `${minScore} / ${maxScore}`;

				this.setPercentilesText(result.percentiles);

				this.averageScore = this.getDoubleWithPrecision(result.averageScore);
				this.potentialCoverageText = (Math.floor(result.potentialCoverage * 10000) / 100) + '%';
			}
		});
	}

	private setPercentilesText(percentilesMap: PercentilesMap): void {
		let percentileQ1: number = percentilesMap[Percentiles.Q1];
		let percentileQ2: number = percentilesMap[Percentiles.Q2];
		let percentileQ3: number = percentilesMap[Percentiles.Q3];
		if (_.isNumber(percentileQ1) && _.isNumber(percentileQ2) && _.isNumber(percentileQ3)) {
			this.percentilesText = `${this.getDoubleWithPrecision(percentileQ1)} / ${this.getDoubleWithPrecision(percentileQ2)} / ${this.getDoubleWithPrecision(percentileQ3)}`;
		} else {
			this.percentilesText = this.locale.getString('widget.na');
		}
	}

	private getDoubleWithPrecision(value: number): number {
		return Math.floor(value * 1000) / 1000;
	}

	getMinHeight(): string {
		return this.showNoResultsMessage ? this.NO_RESULTS_MIN_HEIGHT : this.MIN_HEIGHT;
	}

	close(): void {
		this.modal.close();
	}

}
