import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as _ from 'underscore';
import * as merge from 'lodash.merge';
import { VoiceDurationService } from '@app/modules/conversation/voice-message/services/voice-duration.service';
import { ConversationDocument, IVoiceMetrics } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { ConversationChannelService, ChannelTypes, ParticipantKind } from '@cxstudio/reports/document-explorer/conversations/conversation-channel.service';
import { ConversationAttributes } from '@cxstudio/reports/document-explorer/conversations/conversation-attributes.constant';
import { DocumentTypeUtils } from '../document-type-utils.class';
import { Security } from '@cxstudio/auth/security-service';

@Injectable({
	providedIn: 'root'
})
export class ConversationAttributesService {
	constructor(
		@Inject('conversationChannelService') private conversationChannelService: ConversationChannelService,
		@Inject('security') private security: Security
	) { }

	sentenceHasAttributes = (sentence: ConversationSentence): boolean => {
		return this.hasAttribute(sentence.attributes, ConversationAttributes.CB_CONV_PARTICIPANT_TYPE)
			&& this.hasAttribute(sentence.attributes, ConversationAttributes.CB_CONV_SENTENCE_START_TIME_MS);
	}

	private hasAttribute = (object, attributeName): boolean => {
		return _.has(object, attributeName);
	}

	populateVoiceMetrics = (document: ConversationDocument): void => {
		this.populateSentenceLevelData(document.sentences);
		this.populateDocumentLevelData(document);
	}

	private populateSentenceLevelData = (sentences: ConversationSentence[]): void => {
		sentences.forEach((sentence: ConversationSentence) => {
			if (!this.sentenceHasAttributes(sentence)) {
				return;
			}

			_.extend(sentence, this.getSentenceLevelVoiceData(sentence));
		});
	}

	private populateDocumentLevelData = (document: ConversationDocument): void => {
		if (!DocumentTypeUtils.isConversation(document)) {
			return;
		}

		_.extend(document, {
			voiceMetrics: this.getDocumentLevelVoiceData(document),
			isChat: DocumentTypeUtils.isChat(document, this.security.isVoiceEnabled())
		});
	}

	private getSentenceLevelVoiceData = (sentence: ConversationSentence): any => {
		let sentenceStartTime = this.getNumberAttribute(sentence.attributes, ConversationAttributes.CB_CONV_SENTENCE_START_TIME_MS);
		let sentenceDuration = this.getNumberAttribute(sentence.attributes, ConversationAttributes.CB_CONV_SENTENCE_DURATION_MS);

		let participantKind = this.getStringAttribute(sentence.attributes, ConversationAttributes.CB_CONV_PARTICIPANT_KIND);
		let sentenceChannel = participantKind === ParticipantKind.CHAT_BOT || participantKind === ParticipantKind.IVR
			? ChannelTypes.BOT
			: this.getStringAttribute(sentence.attributes, ConversationAttributes.CB_CONV_PARTICIPANT_TYPE);

		if (sentenceChannel === ChannelTypes.BOT) this.removeBotMetadata(sentence);

		return {
			channel: sentenceChannel,
			timestamp: this.convertToSeconds(sentenceStartTime),
			endTimestamp: _.isUndefined(sentenceDuration)
				? undefined : this.convertToSeconds(sentenceStartTime + sentenceDuration),
			wordCount: this.getNumberAttribute(sentence.attributes, ConversationAttributes.CB_SENTENCE_WORD_COUNT)
		};
	}

	private removeBotMetadata = (sentence: ConversationSentence): any => {
		sentence.dScore = 0;
		delete sentence.sentiment;
		delete sentence.easeScore;
		delete sentence.emotionIntensity;

		sentence.chunks.forEach(chunk => {
			// remove everything other than 'link'
			chunk.descriptors = chunk.descriptors?.includes('link') ? ['link'] : [];
		});
	}

	private getDocumentLevelVoiceData = (document: ConversationDocument): IVoiceMetrics => {
		if (!(DocumentTypeUtils.isVoice(document))) {
			return this.getNonAudioDocumentMetrics(document);
		} else return this.getAudioDocumentMetrics(document);
	}

	private getNonAudioDocumentMetrics(document: ConversationDocument): IVoiceMetrics {
		let totalDuration = this.getNumberAttribute(document, ConversationAttributes.CB_CONV_DURATION);
		return {
			totalSecond: this.convertToSeconds(totalDuration)
		};
	}

	getAudioDocumentMetrics = (document: ConversationDocument): IVoiceMetrics => {
		let calculatedMetrics = this.calculateAudioDocumentMetrics(document);
		let analyticsMetrics = document.voiceMetrics;

		// attribute metrics > analytics metrics > calculated metrics
		let extendedMetrics: IVoiceMetrics = merge(calculatedMetrics, analyticsMetrics);

		let pctAgentAttr = _.find(document.attributes, {name: ConversationAttributes.CB_CONV_PERCENT_AGENT});
		let maxSilenceAttr = _.find(document.attributes, {name: ConversationAttributes.CB_CONV_LONGEST_SILENCE});

		if (pctAgentAttr) {
			extendedMetrics.channels.agent.percent = Number(pctAgentAttr.value);
		}

		if (maxSilenceAttr) {
			extendedMetrics.silence.max = Number(maxSilenceAttr.value);
		}

		return extendedMetrics;
	}

	calculateAudioDocumentMetrics = (document: ConversationDocument): IVoiceMetrics => {
		let agentDuration = 0;
		let clientDuration = 0;
		let maxSilenceDuration = 0;
		let sentenceIdBeforeMaxValue;

		let totalDuration = this.getNumberAttribute(document, ConversationAttributes.CB_CONV_DURATION);

		let stateTracking = {
			maxEndTimestamp: document.sentences[0]?.timestamp || 0
		};

		document.sentences.forEach((sentence: any, index: number, sentences: any[]) => {
			let sentenceDuration = this.getNumberAttribute(sentence.attributes, ConversationAttributes.CB_CONV_SENTENCE_DURATION_MS);

			if (this.conversationChannelService.isAgent(sentence.channel)) {
				agentDuration += sentenceDuration;
			}

			if (this.conversationChannelService.isClient(sentence.channel)) {
				clientDuration += sentenceDuration;
			}

			let silenceDuration = VoiceDurationService.getDurationOfLeadingSilence(sentence, stateTracking.maxEndTimestamp);
			stateTracking.maxEndTimestamp = Math.max(stateTracking.maxEndTimestamp, sentence.endTimestamp);
			if (silenceDuration > maxSilenceDuration) {
				maxSilenceDuration = silenceDuration;
				sentenceIdBeforeMaxValue = sentences[index - 1]?.id;
			}
		});

		return {
			totalSecond: this.convertToSeconds(totalDuration),
			silence: {
				max: maxSilenceDuration,
				total: this.convertToSeconds(this.getNumberAttribute(document, ConversationAttributes.CB_CONV_TOTAL_SILENCE)),
				percent: this.getFloatAttribute(document, ConversationAttributes.CB_CONV_PERCENT_SILENCE) * 100,
				sentenceIdBeforeMax: sentenceIdBeforeMaxValue
			},
			channels: {
				agent: {
					percent: (agentDuration / totalDuration) * 100
				},
				client: {
					percent: (clientDuration / totalDuration) * 100
				}
			}
		};
	}

	private getStringAttribute = (object, attributeName: string): string => {
		let attributeValue = object[attributeName];
		return _.isArray(attributeValue)
			? _.first(attributeValue)
			: attributeValue;
	}

	private getNumberAttribute = (object, attributeName: string): number => {
		let attributeValue = this.getStringAttribute(object, attributeName);
		return _.isUndefined(attributeValue) ? undefined : parseInt(attributeValue, 10);
	}

	private getFloatAttribute = (object, attributeName: string): number => {
		let attributeValue = this.getStringAttribute(object, attributeName);
		return _.isUndefined(attributeValue) ? 0 : parseFloat(attributeValue);
	}

	private convertToSeconds = (timeMillisecond: number): number => {
		return timeMillisecond / 1000;
	}
}

app.service('conversationAttributesService', downgradeInjectable(ConversationAttributesService));
