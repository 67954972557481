import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { OptionsBuilder } from './options-builder.class';
import { OptionsConstant } from './options-constant';

export class OptionsBuilderProvider {
	constructor(
		private readonly optionsTemplatesService: OptionsTemplatesService,
		private readonly metricUtils: MetricUtils,
		private readonly metricConstants: MetricConstants
	) {}

	getBuilder(type?: OptionsConstant): OptionsBuilder {
		return new OptionsBuilder(type, this.optionsTemplatesService, this.metricUtils, this.metricConstants);
	}
}

app.service('optionsBuilderProvider', OptionsBuilderProvider);
