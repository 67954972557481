import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';

export let TEMPLATE_PLACEHOLDER_PREDEFINED_ITEMS = Object.freeze([
	ClarabridgeAttributeName.CONTENT_TYPE,
	ClarabridgeAttributeName.CONTENT_SUBTYPE,
	ClarabridgeAttributeName.DETECTED_FEATURES,
	ClarabridgeAttributeName.PROCESSED_LANGUAGE,
	ClarabridgeAttributeName.DETECTED_LANGUAGE,
	ClarabridgeAttributeName.CB_BRAND,
	ClarabridgeAttributeName.CB_COMPANY,
	ClarabridgeAttributeName.CB_EMAIL,
	ClarabridgeAttributeName.CB_EMOTICON,
	ClarabridgeAttributeName.CB_EVENT,
	ClarabridgeAttributeName.CB_INDUSTRY,
	ClarabridgeAttributeName.CB_PERSON,
	ClarabridgeAttributeName.CB_PHONE,
	ClarabridgeAttributeName.CB_PRODUCT,
	ClarabridgeAttributeName.CB_CURRENCY,
	ClarabridgeAttributeName.CB_PROFANITY,
	ClarabridgeAttributeName.CB_SENTENCE_TYPE,
	ClarabridgeAttributeName.CB_SENTENCE_QUARTILE,
	ClarabridgeAttributeName.CB_SENTENCE_WORD_COUNT,
	ClarabridgeAttributeName.CB_DOCUMENT_WORD_COUNT,
	ClarabridgeAttributeName.CB_LOYALTY_TENURE,
	ClarabridgeAttributeName.CB_CONV_DURATION,
	ClarabridgeAttributeName.CB_CONV_PARTICIPANT_TYPE,
	ClarabridgeAttributeName.CB_CONV_PARTICIPANT_KIND,
	ClarabridgeAttributeName.CB_CONV_PERCENT_SILENCE,
	ClarabridgeAttributeName.CB_CONV_SENTENCE_DURATION_MS,
	ClarabridgeAttributeName.CB_CONV_SENTENCE_START_TIME_MS,
	ClarabridgeAttributeName.CB_CONV_TOTAL_DEAD_AIR,
	ClarabridgeAttributeName.CB_CONV_TOTAL_HESITATION,
	ClarabridgeAttributeName.CB_CONV_TOTAL_OVERTALK,
	ClarabridgeAttributeName.CB_CONV_TOTAL_SILENCE
]);