import { OnChanges, Component, OnInit, ChangeDetectionStrategy, SimpleChanges, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils } from '@app/util/change-utils';
import { LicenseType } from '@cxstudio/common/license-types';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { LicenseService } from '../license.service';

@Component({
	selector: 'license-info',
	templateUrl: './license-info.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseInfoComponent implements OnInit, OnChanges {

	@Input() contractInfo: MasterAccountContractInfo;
	@Input() licenseTypes: LicenseTypeItem[];
	@Input() totalUsers: number;
	@Input() selectedUsers: number;

	readonly LICENSE_TYPES_ORDER = [
		LicenseType.ANALYZE, LicenseType.CX_STUDIO_AND_CM,
		LicenseType.CX_STUDIO, LicenseType.CX_STUDIO_BASIC
	];

	hideLicenses: boolean;
	seats: any[];

	constructor(
		private licenseService: LicenseService
	) {}

	ngOnInit(): void {
		this.hideLicenses = true;
		this.seats = [];

		this.populateSeatsInfo();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.contractInfo)) {
			this.populateSeatsInfo();
		}
	}

	private populateSeatsInfo(): void {
		let orderedLicenses = [];
		let unorderedLicenses = [];

		this.licenseTypes.forEach(license => {
			let licenseTypeId = license.licenseTypeId;

			let contract = this.licenseService.getContractLicenses(
				this.contractInfo, licenseTypeId);
			if (contract > 0) {
				let used = this.licenseService.getUsedLicenses(
					this.contractInfo, licenseTypeId);

				let info = {
					licenseName: license.licenseTypeName,
					contract,
					used
				};

				let order = this.LICENSE_TYPES_ORDER.indexOf(licenseTypeId);
				if (order > -1) {
					orderedLicenses[order] = info;
				} else {
					unorderedLicenses.push(info);
				}
			}
		});

		this.seats = _.compact(orderedLicenses.concat(unorderedLicenses));
	}
}

app.directive('licenseInfo', downgradeComponent({component: LicenseInfoComponent }));
