import { Injectable } from '@angular/core';
import { GridApi, MenuItemDef, RowNode } from 'ag-grid-community';
import { BulkMenuOptionsProviderService } from './menu-providers/bulk-menu-options-provider.service';
import { DashboardMenuOptionsProviderService } from './menu-providers/dashboard-menu-options-provider.service';
import { FolderMenuOptionsProviderService } from './menu-providers/folder-menu-options-provider.service';
import { BookMenuOptionsProviderService } from './menu-providers/book-menu-options-provider.service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';


@Injectable({ providedIn: 'root' })
export class AgGridDashboardContextMenuService {

	constructor(
		private readonly bulkMenuOptionsProviderService: BulkMenuOptionsProviderService,
		private readonly dashboardMenuOptionsProviderService: DashboardMenuOptionsProviderService,
		private readonly bookMenuOptionsProviderService: BookMenuOptionsProviderService,
		private readonly folderMenuOptionsProviderService: FolderMenuOptionsProviderService,
	) {}

	getMenuItems(gridApi: GridApi, node: RowNode, filterManager: TableFilterManager): (string | MenuItemDef)[] {
		let dataType: DashboardType = node.data.type;

		if (gridApi.getSelectedRows().length > 1) {
			return this.bulkMenuOptionsProviderService.getOptions(gridApi, filterManager);
		}

		if (dataType === DashboardType.DASHBOARD) {
			return this.dashboardMenuOptionsProviderService.getOptions(gridApi, node, filterManager);
		}

		if (dataType === DashboardType.BOOK) {
			return this.bookMenuOptionsProviderService.getOptions(gridApi, node, filterManager);
		}

		if (node.data.id === DashboardType.FEEDBACK_FOLDER) {
			return [];
		}

		return this.folderMenuOptionsProviderService.getOptions(node);
	}

}
