import { Injectable } from '@angular/core';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAssetsHelper, AssetPromise, AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { Inject } from '@angular/core';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { MetricValidationApiService } from '@app/modules/metric/services/metric-validation-api.service';

@Injectable({
	providedIn: 'root'
})
export class MetricsManagementService {
	constructor(
		@Inject('metricApiService') private readonly metricManagementApiService: MetricManagementApiService,
		private readonly metricValidationApiService: MetricValidationApiService,
	) {}

	validateMetricName(project: AccountOrWorkspaceProject, name: string): AssetPromise<boolean> {
		return ProjectAssetsHelper.getAssetsForProject<boolean>(
			project,
			(accountProject) => PromiseUtils.wrap(this.metricManagementApiService.metricWithNameExists(
				accountProject.contentProviderId, accountProject.accountId, accountProject.projectId,
				name)) as AssetPromisePlatformProject<boolean>,
			(workspaceProject) => this.metricValidationApiService.validateName(workspaceProject, name),
			(projectId) => false
		);
	}
}
