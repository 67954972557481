<ng-template #rt let-r="result" let-t="term">
		<em [ngClass]="{'user': 'q-icon-user', 'group': 'q-icon-users'}[r.iconType]"></em>
        <ngb-highlight [result]="r.displayName" [term]="t"></ngb-highlight>
</ng-template>

<ng-template #smallLoader><div class="loader small"></div></ng-template>


<div>
	<form #scheduleForm="ngForm">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel id="schedule-panel-{{index}}">
                <ng-template ngbPanelTitle>
                    <div>
                        <em class="r-margin-checkbox" [ngClass]="{
                            'q-icon-check': settings.active,
                            'q-icon-circle': !settings.active
                        }"></em>
                        {{ getSummaryWithAction(index) }}
                        <em class="q-icon-warning text-danger"
                            *ngIf="isModified(index)"
                            title="{{'schedule.scheduleNotSaved'|i18n}}"></em>
                    </div>
                    <em class="q-icon-delete br-remove-schedule ml-auto"
                        (click)="deleteSchedule($event)"
                        title="{{'administration.remove'|i18n}}"></em>
                </ng-template>
                <ng-template ngbPanelContent class="container-fluid">
                    <div class="row m-0">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label class="label-left">{{'common.enabled'|i18n}}</label>
                                <input type="checkbox" [disabled]="expired" name="active" [(ngModel)]="settings.active">
                            </div>

                            <div class="form-group schedule-start-time clearfix">
                                <label class="form-label label-left pull-left floating-label">{{'schedule.startsOn'|i18n}}</label>

                                <div class="mb-8 input-group">
                                    <date-picker-popup
                                        [minDate]="minDate"
                                        [(value)]="settings.startDate"></date-picker-popup>
                                </div>

                                <div class="schedule-row-unlabeled" *ngIf="isRefreshJob()">
                                    <div class="input-group mr-8 pull-left">
                                        <select name="startTimeHour"
                                            [(ngModel)]="time.hour"
                                            (change)="updateHour(true)"
                                            class="br-time-hour-selector">
                                            <option *ngFor="let option of hourOptions"
                                                [ngValue]="option">{{ option }}</option>
                                        </select>

                                        <select name="startTimeMin"
                                            [(ngModel)]="time.min"
                                            (change)="updateMinute()"
                                            class="br-time-minute-selector">
                                            <option *ngFor="let option of minOptions"
                                                [ngValue]="option.value">{{ option.label }}</option>
                                        </select>

                                        <select name="startTimeAmPm"
                                            [(ngModel)]="time.ampm"
                                            (change)="updateAmPm()">
                                            <option *ngFor="let option of ampmOptions"
                                                [ngValue]="option">{{ option }}</option>
                                        </select>
                                    </div>

                                    <select name="timezone"
                                        [(ngModel)]="settings.timezoneOffset"
                                        (change)="updateTimezone()"
                                        class="schedule-input">
                                        <option *ngFor="let option of zoneOffsetOptions"
                                            [ngValue]="option.value">{{ option.displayName }}</option>
                                    </select>
                                </div>

                                <div class="pull-left schedule-row-unlabeled input-group" *ngIf="isPDFJob()">
                                    <select name="startTime"
                                        [(ngModel)]="time.hour"
                                        (change)="updateHour(false)"
                                        class="schedule-input">
                                        <option *ngFor="let option of startTimeOptions"
                                            [ngValue]="option.value">{{ option.name }}</option>
                                    </select>

                                    <select name="timezone"
                                        [(ngModel)]="settings.timezoneOffset"
                                        (change)="updateTimezone()"
                                        class="schedule-input">
                                        <option *ngFor="let option of zoneOffsetOptions"
                                            [ngValue]="option.value">{{ option.displayName }}</option>
                                    </select>
                                </div>

                            </div>

                            <div class="form-group form-inline flex-wrap">
                                <label class="label-left">{{'schedule.frequency'|i18n}}</label>

                                <div class="form-group next-item-wraps">
                                    <select name="freqType"
                                        [(ngModel)]="settings.type"
                                        (change)="updateFrequency()"
                                        class="br-scheduling-options-selector">
                                        <option *ngFor="let option of schedulingOptions"
                                            [ngValue]="option.value">{{ option.name }}</option>
                                    </select>
                                </div>

                                <checkbox-buttons *ngIf="settings.type === 'weekly'"
                                    class="this-item-wraps"
                                    name="weekDaysOptions"
                                    [(ngModel)]="weekDaysOptions"
                                    (updateSelection)="processCron()"></checkbox-buttons>

                                <div *ngIf="settings.type === 'monthly'" class="this-item-wraps">
                                    <label htmlFor="dayOfMonth">{{'schedule.dayTitle'|i18n}}: </label>
                                    <input id="dayOfMonth" class="br-day-of-month number-textbox schedule-input"
                                        type="number"
                                        name="dayOfMonth"
                                        [(ngModel)]="recurrence.dayOfMonth"
                                        (ngModelChange)="processCron()"
                                        min="1"
                                        max="31"
                                        pattern="^[1-9]$|^[1-2][0-9]$|^[3][0-1]$"
                                        required>
                                    <label htmlFor="monthlyInterval"> {{ 'schedule.ofEvery'|i18n }}: </label>
                                    <input id="monthlyInterval" class="br-monthly-interval number-textbox schedule-input"
                                        type="number"
                                        name="monthlyInterval"
                                        [(ngModel)]="recurrence.month"
                                        (ngModelChange)="processCron()"
                                        min="1"
                                        max="12"
                                        pattern="^[1-9]$|^[1][0-2]$"
                                        required>
                                    <label htmlFor="monthlyInterval"> {{ 'schedule.months'|i18n }} </label>
                                </div>
                            </div>

                            <div *ngIf="settings.type !== 'once'" class="schedule-expiration help-block">
                                <p>{{ expirationMessage }}</p>
                                <button class="btn btn-secondary btn-sm"
                                    (click)="renewSchedule()"
                                    *ngIf="isScheduleRenewable()">{{ 'schedule.expirationRenew'|i18n }}</button>
                            </div>

                            <div class="form-group" *ngIf="settings.type !== 'once' && !disableActiveFor">
                                <label class="label-left">{{ 'schedule.activeFor'|i18n }}</label>
                                <div class="d-inline-block">
                                    <select name="activeFor"
                                        [(ngModel)]="settings.activeFor"
                                        (change)="processCron()"
                                        class="br-schedule-active-for">
                                        <option *ngFor="let option of activeForOptions"
                                            [ngValue]="option.value">{{ option.displayName }}</option>
                                    </select>
                                </div>
                            </div>

                            <alert type="warning" class="monthly-warning"
                                *ngIf="settings.type === 'monthly' && recurrence.dayOfMonth > 28">{{ getWarningMessage() }}</alert>
                        </div>

                        <div class="col-sm-12 col-lg-6 p-static">
                            <div class="form-group form-inline flex-wrap">
                                <div class="form-group next-item-wraps">
                                    <label htmlFor="scheduleAction" class="label-left">{{ 'schedule.action'|i18n }}</label>
                                    <select id="scheduleAction"
                                        name="scheduleAction"
                                        [(ngModel)]="settings.jobType"
                                        (change)="jobTypeChanged()">
                                        <option *ngFor="let action of jobTypes"
                                            [ngValue]="action.value">{{ action.displayName }}</option>
                                    </select>
                                </div>
                                <button class="btn btn-link btn-sm this-item-wraps"
                                    (click)="sendTestEmail(index)"
                                    *ngIf="isPDFJob()">{{ 'schedule.sendTestToMe'|i18n }}</button>
                            </div>

                            <div *ngIf="isRefreshJob()">
                                <div class="form-group">
                                    <div>
                                        <label class="label-left">{{'schedule.notifyVia'|i18n}}</label>
                                        <div class="labeled-checkbox-inline next-item-wraps">
                                            <input type="checkbox"
                                                id="emailUsers{{index}}"
                                                class="br-schedule-email"
                                                name="notify"
                                                [(ngModel)]="settings.notify"/>
                                            <label htmlFor="emailUsers{{index}}">{{'schedule.emailSharedUsers'|i18n}}</label>
                                        </div>
                                        <div class="labeled-checkbox-inline this-item-wraps">
                                            <input type="checkbox"
                                                id="notifyInApp{{index}}"
                                                class="br-schedule-notify"
                                                name="notifyApp"
                                                [(ngModel)]="settings.notifyApp"/>
                                            <label htmlFor="notifyInApp{{index}}">{{'schedule.inApp'|i18n}}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="label-left"></label>
                                        <div class="labeled-checkbox-inline">
                                            <input type="checkbox"
                                                id="emailGroups{{index}}"
                                                class="br-schedule-email"
                                                name="notifyGroups"
                                                [(ngModel)]="settings.notifyGroups">
                                            <label htmlFor="emailGroups{{index}}">{{'schedule.EmailSharedGroups'|i18n}}</label>
                                        </div>
                                    </div>
                                </div>

                                <label htmlFor="message" class="label-left">{{ 'schedule.message'|i18n }}</label>
                                <textarea id="message"
                                    rows="10"
                                    class="schedule-message-details"
                                    name="message"
                                    [(ngModel)]="settings.message"></textarea>
                            </div>

                            <div *ngIf="isPDFJob()">
                                <div class="form-group form-inline pdf-distribution d-flex align-items-center flex-multiline">
                                    <label htmlFor="distributionType" class="label-left">{{ 'schedule.distribution'|i18n }}</label>
                                    <simple-dropdown
                                        id="distributionSelector"
                                        class="next-item-wraps"
                                        [value]="selectedDistribution"
                                        (onChange)="selectDistribution($event)"
                                        disableSort="true"
                                        displayField="displayName"
                                        valueField="uniqueValue"
                                        [options]="distributionOptions">
                                    </simple-dropdown>
                                    <p *ngIf="settings.distribution.type === distributionDefinitions.HIERARCHY.type"
                                        class="mb-0 help-block flex-direction-row this-item-wraps next-item-wraps {{emailWarningClass}}">{{ emailWarning }}</p>
                                    <p class="help-block this-item-wraps text-danger"
                                        *ngIf="settings.distribution.type === distributionDefinitions.HIERARCHY.type && hierarchyWarning">{{ hierarchyWarning }}</p>
                                </div>

                                <div class="labeled-checkbox form-group form-inline flex-wrap" *ngIf="!(settings.distribution.type === distributionDefinitions.SPECIFIC_USERS.type)">
                                    <label class="label-left" htmlFor="consolidateEmails">{{'schedule.consolidateEmails'|i18n}}</label>
                                    <input type="checkbox"
                                    id="consolidateEmails"
                                    class="br-schedule-email"
                                    name="consolidateEmails"
                                    [(ngModel)]="settings.consolidatedEmail">
                                </div>

                                <div *ngIf="settings.distribution.type === distributionDefinitions.SPECIFIC_USERS.type"
                                    class="schedule-row-unlabeled">
                                    <div class="form-group d-flex flex-multiline l-margin-offset-4">
                                        <div class="email-input-wrap input-group">
                                            <input id="permission-invite-input"
                                                type="text"
                                                class="flex-fill"
                                                size="25"
                                                name="inviteQuery"
                                                [(ngModel)]="inviteEntity"
                                                tabindex="3"
                                                attr.aria-label="{{ 'dashboard.inviteMessage'|i18n }}"
                                                placeholder="{{ 'dashboard.inviteMessage'|i18n }}"
                                                (keyup.enter)="immediateShare()"

                                                [ngbTypeahead]="inviteSuggestions"
                                                [inputFormatter]="formatter"
                                                (selectItem)="typeaheadOnSelect($event)"
                                                [resultTemplate]="rt"
                                                [focusFirst]="false"/>

                                            <button id="btn-add-share"
                                                [ngBusy]="{ busy: typeaheadSearchLoading, template: smallLoader }"
                                                tabindex="5"
                                                class="btn btn-primary"
                                                (click)="shareWith()"
                                                [disabled]="disableAddButton">{{ 'common.add' | i18n }}</button>
                                        </div>

                                        <input
                                            tabindex="6"
                                            type="text"
                                            name="searchQuery"
                                            [(ngModel)]="searchText"
                                            (keydown.escape)="searchText = ''"
                                            placeholder="{{ 'dashboard.findUserInList' | i18n }}">
                                    </div>

                                    <simple-share-table class="form-horizontal br-share-table br-share-changes d-block mb-16"
                                        id="lst-dashboard-change-entities"
                                        [ngStyle]="{'max-height': tableMaxHeight}"
                                        [searchText]="searchText"
                                        [items]="settings.distribution.subscribers"
                                        (onRemove)="removeUser($event)">
                                    </simple-share-table>
									<alert type="info" *ngIf="shouldShowEmailWarning()" [dismissable]="false" text="{{'sharing.emailBatchWarning'|i18n}}">
									</alert>
                                </div>

                                <div class="form-group">
                                    <label htmlFor="pdfName" class="label-left">{{'schedule.pdfName'|i18n}}</label>
                                    <input id="pdfName"
                                        class="form-control schedule-pdf-name"
                                        name="pdfName"
                                        [(ngModel)]="settings.pdfName"
                                        [mention]="getPDFInputOptions()"
                                        [mentionConfig]="emailVariableMentionConfig"
                                        maxlength="150"/>
                                    <div class="help-block">150 {{ 'schedule.characterLimit'|i18n }}</div>
                                </div>

                                <div class="form-group">
                                    <label htmlFor="emailSubject" class="label-left">{{ 'schedule.emailSubject'|i18n }}</label>
                                    <input id="emailSubject"
                                        class="form-control schedule-email-title"
                                        name="emailTitle"
                                        [(ngModel)]="settings.emailTitle"
                                        [mention]="getSubjectBodyInputOptions()"
                                        [mentionConfig]="emailVariableMentionConfig"
                                        maxlength="150"/>
                                    <div class="help-block">150 {{'schedule.characterLimit'|i18n}}</div>
                                </div>

                                <div class="form-group">
                                    <label htmlFor="emailBody" class="label-left">{{ 'schedule.emailBody'|i18n }}</label>
                                    <textarea type="text"
                                        id="emailBody"
                                        class="form-control schedule-email-body"
                                        name="emailBody"
                                        [(ngModel)]="settings.emailBody"
                                        [mention]="getSubjectBodyInputOptions()"
                                        [mentionConfig]="emailVariableMentionConfig"
                                        size="100"
                                        maxlength="250"></textarea>
                                    <div class="help-block">250 {{'schedule.characterLimit'|i18n }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-16 pull-right">
                            <button (click)="saveSchedule(index)"
                                class="btn btn-primary save-schedule"
                                [disabled]="disableSave()">{{'common.save' | i18n}}</button>
                            <button (click)="revert(index)"
                                class="btn btn-secondary revert-schedule"
                                [disabled]="!isModified(index)">{{'common.cancel' | i18n}}</button>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
	</form>
</div>
