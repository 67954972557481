<form>
	<collapsing-panel *ngIf="!isPredefinedMetric()">
		<panel-heading class="font-bold">{{'metrics.numericFormatting'|i18n}}</panel-heading>
		<panel-body>
			<number-format
				[formatObject]="format"
				(formatObjectChange)="changeHandler($event)"
				[defaultFormat]="metric?.format"
			></number-format>
		</panel-body>
	</collapsing-panel>
	<collapsing-panel *ngIf="showConditionalFormatting()">
		<panel-heading class="font-bold">{{'metrics.conditionalFormatting'|i18n}}</panel-heading>
		<panel-body>
			<div class="form-group">
				<div class="d-flex align-items-center mb-8" *ngIf="!isPredefinedMetric()">
					<input type="checkbox" class="mb-8"
						name="calculationEnabled"
						[(ngModel)]="calculationEnabled"
						(ngModelChange)="toggleCalculation()">
					<div class="line-label bg-pewter-400 ml-8">
						<label class="bg-white">{{'metrics.useConditionalFormatting'|i18n}}</label>
					</div>
				</div>
				<cb-color-grade-bar
					class="metric-color-grade"
					[isDisabled]="!calculationEnabled"
					[validateLanesOnInit]="true"
					[grades]="colorGrades"
					(gradesChange)="colorGradesChangeHandler($event)"
					[options]="options"
					[min]="definition.calculation.min"
					[max]="definition.calculation.max"
					(minMaxChange)="minMaxChangeHandler($event.isMin, $event.value)"
					(validityChange)="onValidityChange.emit($event)"
					[format]="format">
				</cb-color-grade-bar>
			</div>
			<alert *ngIf="isPredefinedMetric()" [type]="AlertLevel.INFO">{{'metrics.invertInDarkMode'|i18n}}</alert>
		</panel-body>
	</collapsing-panel>
</form>
