<div class="d-flex stack-medium-and-smaller">
	<div class="selection-deselection-list">
		<div class="available-items-description">
			<label *ngIf="availableItemsLabelCallback || selectedItemsLabelCallback">
				{{getAvailableItemsLabel()}}
			</label>
			<cb-inline-help *ngIf="availableItemsTooltipCallback">
				<help-body>
					<p>{{availableItemsTooltipCallback()}}</p>
				</help-body>
			</cb-inline-help>
		</div>

		<div class="filter-select-all form-group">
			<input
				*ngIf="uiOptions.searchable"
				name="availableFilter"
				[(ngModel)]="availableTextFilter"
				(ngModelChange)="highlightedAvailableItems.removeAll()"
				placeholder="{{uiOptions.searchAvailable}}"
				[attr.aria-label]="uiOptions.searchAvailable"
				[readonly]="disabled">
		</div>

		<select *ngIf="selectGroups"
			multiple
			class="item-list available-items"
			name="available"
			[(ngModel)]="highlightedAvailableItems"
			[disabled]="disabled">
			<optgroup
				*ngFor="let group of selectGroups"
				class="hide-if-empty"
				label="{{group}}">
				<option
					*ngFor="let item of getNotSelectedItems()
						| filterBy:['tag']:group
						| filterBy:['displayName']:availableTextFilter"
					[disabled]="item.disabled"
					[ngClass]="getOptionClass(item)"
					[attr.title]="getOptionTitle(item)"
					(dblclick)="selectItems()"
					value="{{item.name}}">
					{{item.displayName}}
				</option>
			</optgroup>
		</select>

		<select *ngIf="!selectGroups"
			multiple
			class="item-list available-items"
			name="available"
			[(ngModel)]="highlightedAvailableItems"
			[disabled]="disabled">
			<option
				*ngFor="let item of getNotSelectedItems()
					| filterBy:['displayName']:availableTextFilter"
				[disabled]="item.disabled"
				[ngClass]="getOptionClass(item)"
				[attr.title]="getOptionTitle(item)"
				(dblclick)="selectItems()"
				value="{{item.name}}">
				{{item.displayName}}
			</option>
		</select>
	</div>

	<div class="selection-deselection-buttons">
		<button
			attr.aria-label="{{'common.addToSelections'|i18n}}"
			id="add-selections-btn"
			class="btn btn-secondary btn-icon"
			(click)="selectItems()"
			[disabled]="isSelectionDisabled()">
			<span class="horizontal-orientation q-icon-arrow-right"></span>
			<span class="vertical-orientation q-icon-arrow-down"></span>
		</button>

		<button
			attr.aria-label="{{'common.removeFromSelections'|i18n}}"
			id="remove-selections-btn"
			class="btn btn-secondary btn-icon"
			(click)="deselectItems()"
			[disabled]="isDeselectionDisabled()">
			<span class="horizontal-orientation q-icon-arrow-left"></span>
			<span class="vertical-orientation q-icon-arrow-up"></span>
		</button>
	</div>

	<div class="selection-deselection-list">
		<label *ngIf="availableItemsLabelCallback || selectedItemsLabelCallback">
			{{getSelectedItemsLabel()}}
		</label>
		<div class="filter-select-all form-group">
			<input
				*ngIf="uiOptions.searchable"
				name="selectedFilter"
				[(ngModel)]="selectedTextFilter"
				(ngModelChange)="highlightedSelectedItems.removeAll()"
				placeholder="{{uiOptions.searchSelected}}"
				[attr.aria-label]="uiOptions.searchSelected"
				[readonly]="disabled">
		</div>

		<select *ngIf="selectGroups"
			multiple
			class="item-list selected-items"
			name="selected"
			[(ngModel)]="highlightedSelectedItems"
			(change)="onSelectedItemsHighlightingChange()"
			[disabled]="disabled">
			<option
				*ngFor="let item of pinnedSelection"
				disabled
				class="pinned-item"
				value="{{item.name}}">
				{{item.displayName}}
			</option>
			<optgroup
				*ngFor="let group of selectGroups"
				class="hide-if-empty"
				label="{{group}}">
				<option
					*ngFor="let item of selectedItems
						| filterBy:['tag']:group
						| filterBy:['displayName']:selectedTextFilter"
					[disabled]="item.disabled"
					[ngClass]="getOptionClass(item)"
					[attr.title]="getOptionTitle(item)"
					(dblclick)="deselectItems()"
					value="{{item.name}}">
					{{item.displayName}}
				</option>
			</optgroup>
		</select>

		<select *ngIf="!selectGroups"
			multiple
			class="item-list selected-items"
			name="selected"
			[(ngModel)]="highlightedSelectedItems"
			(change)="onSelectedItemsHighlightingChange()"
			[disabled]="disabled">
			<option
				*ngFor="let item of pinnedSelection"
				disabled
				class="pinned-item"
				value="{{item.name}}">
				{{item.displayName}}
			</option>
			<option
				*ngFor="let item of selectedItems
					| filterBy:['displayName']:selectedTextFilter"
				[disabled]="item.disabled"
				[ngClass]="getOptionClass(item)"
				[attr.title]="getOptionTitle(item)"
				(dblclick)="deselectItems()"
				value="{{item.name}}">
				{{item.displayName}}
			</option>
		</select>
	</div>

	<div class="selection-deselection-buttons pr-0" *ngIf="isOrderingEnabled">
		<button
			attr.aria-label="{{'common.moveUp'|i18n}}"
			id="move-selections-up-btn"
			class="btn btn-secondary btn-icon"
			(click)="moveHighlightedSelectedItemsUp()"
			[disabled]="!canMoveHighlightedSelectedItemsUp()">
			<span class="q-icon-arrow-up"></span>
		</button>

		<button
			attr.aria-label="{{'common.moveDown'|i18n}}"
			id="move-selections-down-btn"
			class="btn btn-secondary btn-icon"
			(click)="moveHighlightedSelectedItemsDown()"
			[disabled]="!canMoveHighlightedSelectedItemsDown()">
			<span class="q-icon-arrow-down"></span>
		</button>
	</div>
</div>
