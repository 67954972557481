import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SPINE_LANE_ICONS } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/spine-lane-icons';
import { ISpineMetricSettingsModalInput, SpineMetricSettingsModalComponent } from '@app/modules/account-administration/conversation-settings/spine-metric-settings-modal.component';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { SpineLaneStyle, TopicSpineLaneDefinition, UISpineLane } from '@cxstudio/conversation/entities/spine-lane.class';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
	selector: 'topic-spine-item',
	templateUrl: './topic-spine-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicSpineItemComponent implements AfterViewInit {

	@Input() lane: UISpineLane;
	@Input() options: INode[];
	@Input() projectSelection: IProjectSelection;
	@Output() groupingChange = new EventEmitter<INode>();

	laneStyles = [
		{ name: this.locale.getString('common.icon'), value: SpineLaneStyle.icon }
	];

	laneIcons = SPINE_LANE_ICONS;

	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private ref: ChangeDetectorRef,
	) { }

	ngAfterViewInit(): void {
		setTimeout(() => this.ref.detectChanges());
	}

	configureModel(): void {
		let grouping = cloneDeep(this.lane.grouping) as TopicReportGrouping;
		let definition = this.lane.definition as TopicSpineLaneDefinition;
		grouping.selectedNodes = definition.selectedNodes;
		grouping.selectedLevel = definition.selectedLevel;
		grouping.inheritTopics = definition.inheritTopics;

		let input: ISpineMetricSettingsModalInput = {
			grouping,
			projectSelection: this.projectSelection
		};
		this.cxDialogService.openDialog(SpineMetricSettingsModalComponent, input).result
			.then(resultGrouping => {
				definition.selectedLevel = resultGrouping.selectedLevel;
				definition.selectedNodes = resultGrouping.selectedNodes;
				definition.inheritTopics = resultGrouping.inheritTopics;
				definition.leaf = resultGrouping.selectedLevel === TopicReportGrouping.LEAF_LEVEL;
			}).catch(_.noop);
	}
}
