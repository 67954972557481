export enum FeatureType {
	BETA_FEATURE = 'BETA_FEATURE',
	CONTROLLED_RELEASE = 'CONTROLLED_RELEASE',
	DEV_TEST = 'DEV_TEST'
}

export enum DistributionType {
	MASTER_ACCOUNT = 'MASTER_ACCOUNT',
	INTERNAL = 'INTERNAL'
}

export class BetaFeatureTracker {

	static beta(id: string, defaultState = false): BetaFeatureTracker {
		return new BetaFeatureTracker(id, defaultState, FeatureType.BETA_FEATURE, [DistributionType.MASTER_ACCOUNT]);
	}

	static internalBeta(id: string, defaultState = false): BetaFeatureTracker {
		return new BetaFeatureTracker(id, defaultState, FeatureType.BETA_FEATURE, [DistributionType.MASTER_ACCOUNT, DistributionType.INTERNAL]);
	}

	static controlledRelease(id: string, defaultState = false): BetaFeatureTracker {
		return new BetaFeatureTracker(id, defaultState, FeatureType.CONTROLLED_RELEASE, [DistributionType.MASTER_ACCOUNT]);
	}

	static spike(id: string, defaultState = false): BetaFeatureTracker {
		return new BetaFeatureTracker(id, defaultState, FeatureType.DEV_TEST, [DistributionType.MASTER_ACCOUNT]);
	}

	private constructor(
		public id: string,
		public defaultState: boolean,
		public type: FeatureType,
		public distribution: DistributionType[]
	) {}
}
