export default class Listener {
	listeners: Array<() => any> = [];

	addListener = (listener: () => any): void => {
		this.listeners.push(listener);
	}

	removeListener = (listener: () => any): void => {
		this.listeners.remove(listener);
	}

	invokeListeners = (): void => {
		this.listeners.forEach(listener => listener());
	}
}