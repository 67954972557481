import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConversationModule } from '@app/modules/conversation/conversation.module';
import { ContextNavigationComponent } from '@app/modules/document-explorer/context-pane/context-navigation.component';
import { ContextPaneComponent } from '@app/modules/document-explorer/context-pane/context-pane.component';
import { ExplorerAttributeComponent } from '@app/modules/document-explorer/context-pane/explorer-attribute.component';
import { ExplorerEngagorCaseComponent } from '@app/modules/document-explorer/context-pane/explorer-engagor-case.component';
import { ExplorerScorecardComponent } from '@app/modules/document-explorer/context-pane/explorer-scorecard.component';
import { ExplorerScorecardsSectionComponent } from '@app/modules/document-explorer/context-pane/explorer-scorecards-section.component';
import { ExplorerWorldAwarenessComponent } from '@app/modules/document-explorer/context-pane/explorer-world-awareness.component';
import { FavoriteItemIconAttributeComponent } from '@app/modules/document-explorer/context-pane/favorite-item-icon.component';
import { PanelRibbonComponent } from '@app/modules/document-explorer/context-pane/panel-ribbon.component';
import { ExcludeNarrativeAttributeFilterPipe } from '@app/modules/document-explorer/context-pane/pipes/exclude-narrative-attribute-filter.pipe';
import { ExplorerAttributeFilterPipe } from '@app/modules/document-explorer/context-pane/pipes/explorer-attribute-filter.pipe';
import { ExplorerModelFilterPipe } from '@app/modules/document-explorer/context-pane/pipes/explorer-model-filter.pipe';
import { TopicChickletsComponent } from '@app/modules/document-explorer/context-pane/topic-chicklets.component';
import { ManualCreateCaseTableComponent } from '@app/modules/document-explorer/manual-create-case-table.component';
import { DocumentTranslationService } from '@app/modules/document-explorer/translation/document-translation.service';
import { TuningSuggestionsService } from '@app/modules/document-explorer/tuning-suggestions/tuning-suggestions.service';
import { PillsModule } from '@app/modules/pills/pills.module';
import { TranslationModule } from '@app/modules/translation/translation.module';
import { SharedModule } from '@app/shared/shared.module';
import { MentionModule } from 'angular-mentions';
import { NgPipesModule } from 'ngx-pipes';
import { AlertModule } from '../alert/alert.module';
import { ProfanityModule } from '../profanity/profanity.module';
import { ExplorerScorecardSortComponent } from './context-pane/explorer-scorecard-sort.component';
import { ExplorerScorecardDisplayPreference } from './context-pane/scorecards/explorer-scorecard-display-preference.service';
import { ConversationParticipantService } from './conversation-participant.service';
import { DateLabelComponent } from './date-label/date-label.component';
import { DocumentActionsComponent } from './document-actions/document-actions.component';
import { DocumentExplorerMenuComponent } from './document-explorer-menu/document-explorer-menu.component';
import { DropdownMenuGroupComponent } from './document-explorer-menu/dropdown-menu-group/dropdown-menu-group.component';
import { PlaybackMenuItemComponent } from './document-explorer-menu/playback-menu-item/playback-menu-item.component';
import { DocumentLinkCopyService } from './document-link-copy.service';
import { DocumentNavigationComponent } from './document-navigation/document-navigation.component';
import { FeedbackSentencesComponent } from './feedback-sentence/feedback-sentences.component';
import { FeedbackVerbatimComponent } from './feedback-verbatim/feedback-verbatim.component';
import { ParticipantSelectorComponent } from './participant-selector/participant-selector.component';
import { DocExplorerPreferencesProviderService } from './preferences/doc-explorer-preferences-provider.service';
import { SentencePreviewComponent } from './sentence-preview/sentence-preview.component';
import { SentenceStyleEditorService } from './sentence-style-editor.service';
import { TopicChickletsService } from './topic-chicklet.service';
import { TuningSuggestionsButtonComponent } from './tuning-suggestions/tuning-suggestions-button/tuning-suggestions-button.component';
import { TwitterMessageComponent } from './twitter-message/twitter-message.component';
import { TranslateButtonComponent } from './translation/translate-button/translate-button.component';
import { TopicCheckboxesComponent } from './context-pane/topic-checkboxes.component';
import { FiltersAppliedDropdownUpgrade } from '@app/modules/document-explorer/filter-applied-dropdown.upgrade.directive';
import { DocumentExplorerSortComponent } from './document-explorer-sort/document-explorer-sort.component';
import { DocumentExplorerMetricFiltersComponent } from './document-explorer-metric-filters/document-explorer-metric-filters.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { DocumentExplorerToolbarComponent } from '@app/modules/document-explorer/document-explorer-toolbar/document-explorer-toolbar.component';
import { DocumentExplorerModalComponent } from './document-explorer-modal/document-explorer-modal.component';
import { KeyboardNavigationModule } from '@app/modules/keyboard-navigation/keyboard-navigation.module';
import { WidgetDocumentPreviewUpgrade } from '@app/modules/document-explorer/widget-document-preview.upgrade.directive';
import { DocumentExplorerStatsModalComponent } from './document-explorer-stats-modal/document-explorer-stats-modal.component';
import { DocumentExplorerPageComponent } from './document-explorer-page/document-explorer-page.component';
import { DocumentExplorerContainerComponent } from './document-explorer-container/document-explorer-container.component';
import { DocumentExplorerMenuButtonComponent } from '@app/modules/document-explorer/document-explorer-menu-button/document-explorer-menu-button.component';
import { DocumentExplorerFullPageToggleComponent } from './document-explorer-full-page-toggle/document-explorer-full-page-toggle.component';
import { FullPageDocumentExplorerService } from './document-explorer-page/full-page-document-explorer.service';
import { TabbedContextPaneComponent } from './context-pane/tabbed-context-pane/tabbed-context-pane.component';
import { ScrollCarouselModule } from '../scroll-carousel/scroll-carousel.module';
import { AttachmentsService } from './attachments.service';
import { ConversationAttributesService } from './conversations/conversation-attributes.service';
import { IntelligentScoringButtonComponent } from './intelligent-scoring/intelligent-scoring-button/intelligent-scoring-button.component';
import { IntelligentScoringService } from './intelligent-scoring/intelligent-scoring.service';
import {
	SuggestionMenuOptionsUtils
} from '@app/modules/document-explorer/conversations/suggestion-menu-options-utils.service';
import { DocumentCaseService } from '@app/modules/document-explorer/document-cases/document-case.service';
import { DashboardFromSelectionService } from '@app/modules/document-explorer/dashboard-from-selection.service';
import { BreadcrumbModule } from '@app/modules/breadcrumb/breadcrumb.module';
import { PreviewChunkService } from './preview-chunk.service';
import { ExplorerAutomatedCallSummaryComponent } from './context-pane/explorer-automated-call-summary/explorer-automated-call-summary.component';
import { IconModule } from '@app/modules/icon/icon.module';
import { ACSSummaryPipe } from './context-pane/pipes/acs-summary.pipe';
import { CoachingTipsPipe } from './context-pane/pipes/coaching-tips.pipe';
import { ExplorerCoachingAssistantComponent } from './context-pane/explorer-coaching-assistant/explorer-coaching-assistant.component';
import { ExplorerComplianceManagementComponent } from './context-pane/explorer-compliance-management/explorer-compliance-management.component';

@NgModule({
	imports: [
		SharedModule,
		AlertModule,
		ConversationModule,
		NgPipesModule,
		MentionModule,
		PillsModule,
		CommonModule,
		ProfanityModule,
		TranslationModule,
		DialogModule,
		KeyboardNavigationModule,
		ScrollCarouselModule,
		BreadcrumbModule,
		IconModule,
	],
	exports: [
		ManualCreateCaseTableComponent,
		ParticipantSelectorComponent,
		ContextPaneComponent,
		SentencePreviewComponent,
		FeedbackVerbatimComponent,
		DateLabelComponent,
		TwitterMessageComponent,
		FiltersAppliedDropdownUpgrade,
		ExplorerAutomatedCallSummaryComponent,
		ACSSummaryPipe,
		CoachingTipsPipe,
		ExplorerCoachingAssistantComponent,
		ExplorerComplianceManagementComponent
	],
	declarations: [
		ManualCreateCaseTableComponent,
		ParticipantSelectorComponent,
		ContextNavigationComponent,
		TopicChickletsComponent,
		ExplorerWorldAwarenessComponent,
		ExplorerAttributeComponent,
		ExplorerEngagorCaseComponent,
		FavoriteItemIconAttributeComponent,
		PanelRibbonComponent,
		ContextPaneComponent,
		ExplorerScorecardsSectionComponent,
		ExplorerScorecardComponent,
		ExplorerAttributeFilterPipe,
		ExplorerModelFilterPipe,
		ExcludeNarrativeAttributeFilterPipe,
		ExplorerScorecardSortComponent,
		TuningSuggestionsButtonComponent,
		IntelligentScoringButtonComponent,
		SentencePreviewComponent,
		FeedbackVerbatimComponent,
		DateLabelComponent,
		FeedbackSentencesComponent,
		TwitterMessageComponent,
		DocumentExplorerMenuComponent,
		PlaybackMenuItemComponent,
		DropdownMenuGroupComponent,
		DocumentActionsComponent,
		DocumentNavigationComponent,
		TranslateButtonComponent,
		TopicCheckboxesComponent,
		FiltersAppliedDropdownUpgrade,
		WidgetDocumentPreviewUpgrade,
		DocumentExplorerSortComponent,
		DocumentExplorerMetricFiltersComponent,
		DocumentExplorerToolbarComponent,
		DocumentExplorerModalComponent,
		DocumentExplorerStatsModalComponent,
		DocumentExplorerPageComponent,
		DocumentExplorerContainerComponent,
		DocumentExplorerMenuButtonComponent,
		DocumentExplorerFullPageToggleComponent,
		TabbedContextPaneComponent,
		ExplorerAutomatedCallSummaryComponent,
		ACSSummaryPipe,
		ExplorerComplianceManagementComponent,
		CoachingTipsPipe,
		ExplorerCoachingAssistantComponent
	],
	providers: [
		ConversationParticipantService,
		SentenceStyleEditorService,
		DocumentLinkCopyService,
		TopicChickletsService,
		TuningSuggestionsService,
		IntelligentScoringService,
		ExplorerScorecardDisplayPreference,
		DocExplorerPreferencesProviderService,
		DocumentTranslationService,
		FullPageDocumentExplorerService,
		AttachmentsService,
		ConversationAttributesService,
		SuggestionMenuOptionsUtils,
		DocumentCaseService,
		DashboardFromSelectionService,
		PreviewChunkService,
	],
})
export class DocumentExplorerModule {}
