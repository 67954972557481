import { Injectable } from '@angular/core';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { EsQueryService } from '../filter-builder/es-query/es-query.service';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';

// adds dashboard text filter to adhocFilter
@Injectable({
	providedIn: 'root'
})
export class WidgetDashboardTextFilterProcessorService extends WidgetDashboardFilterProcessor {

	constructor(
		private esQueryService: EsQueryService,
		private locale: CxLocaleService
	) {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		let textFilter = filterApplication.filtersProvider.getTextFilter();
		if (textFilter) {
			if (!filterApplication.widgetSettings.properties.ignoreDashboardTextFilter) {
				let widgetProperties = filterApplication.widgetSettings.properties;
				widgetProperties.adhocFilter = widgetProperties.adhocFilter || {
					type: FilterTypes.AND,
					filterRules: []
				};
				let textSearchRule = {
					type: FilterRuleType.esQueryRule,
					esQueryObject: {
						keyword: this.esQueryService.processQuery(textFilter)
					},
					displayName: this.locale.getString('preview.esQuery')
				};
				widgetProperties.adhocFilter.filterRules.push(textSearchRule);
			}
		}
		return PromiseUtils.old(Promise.resolve());
	}
}

app.service('widgetDashboardTextFilterProcessor', downgradeInjectable(WidgetDashboardTextFilterProcessorService));
