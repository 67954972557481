import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'form-error',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cb-notice class="mt-8"
			type="danger"
			[dismissable]="false"
			[text]=text
		></cb-notice>
	`
})
export class FormErrorComponent implements OnInit {
	@Input() text: string;

	constructor() { }

	ngOnInit(): void {
	}

}
