import { SortDirection } from '@cxstudio/common/sort-direction';
import { IComparator, SortUtils } from '@app/shared/util/sort-utils';
import { PredefinedMetricValue } from '@cxstudio/metrics/predefined/predefined-metric-value';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IDataPoint, IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { MetricValues } from '@cxstudio/reports/providers/cb/constants/metric-values';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import * as cloneDeep from 'lodash.clonedeep';


export interface HasChildren<T> {
	_children: T[];
}

interface HasParent {
	_parentId?: string;
}

interface SeriesNameFormatter {
	[key: string]: (name: string) => string;
}

type INodeFunction<T> = (uniqId: string, name: string, uniqName: string, group: ReportGrouping, item: IDataPointObject, children: T[]) => T;

export interface AnalyticHierarchyData extends IDataPointObject, HasParent, HasChildren<AnalyticHierarchyData> {
	_id: string;
	_parentId?: string;
	_name: string;
	_uniqName: string;
	_group: ReportGrouping;
	_children: AnalyticHierarchyData[];
	name: string;
	displayName: string;
}

export interface AnalyticHierarchyDataV2 extends HasParent, HasChildren<AnalyticHierarchyDataV2> {
	_id: string;
	_parentId?: string;
	_uniqName: string;
	_children: AnalyticHierarchyDataV2[];
	name: string;
	displayName: string;
	row: IDataPointObject;
	leaf: boolean;
}
export class AnalyticDataUtils {

	static readonly DEFAULT_CHILDREN_FIELD = '_children';

	static recursiveSort(data: IDataPointObject[], comparators: Array<IComparator<IDataPointObject>> | IComparator<IDataPointObject>,
		field = AnalyticDataUtils.DEFAULT_CHILDREN_FIELD): IDataPointObject[] {

		if (!data || !data.length || !data[0]) {
			return data;
		}
		let comparator;
		if (comparators.constructor === Array) {
			comparator = comparators[data[0].level];
		} else {
			comparator = comparators;
		}
		let sorted = SortUtils.mergeSort(data, comparator);
		sorted.forEach((node) => {
			if (node[field])
				node[field] = AnalyticDataUtils.recursiveSort(node[field], comparators, field);
		});
		return sorted;
	}

	static getStackComparator(sortBy: string, direction: SortDirection) {
		let order = [
			MetricValues.OTHER_BOX,
			PredefinedMetricValue.NEGATIVE_STRONG,
			PredefinedMetricValue.NEGATIVE,
			MetricValues.BOTTOM_BOX,
			PredefinedMetricValue.NEUTRAL,
			MetricValues.MIDDLE_BOX,
			MetricValues.TOP_BOX,
			PredefinedMetricValue.POSITIVE,
			PredefinedMetricValue.POSITIVE_STRONG
		];
		let regex = new RegExp(`(${order.join('|')})`);
		function getIndex(row) {
			let match = row[sortBy] && row[sortBy].match(regex);
			let value = match && match[0];
			return order.indexOf(value);
		}
		let dir = direction === SortDirection.ASC ? 1 : -1;
		return (row1, row2) => {
			return dir * (getIndex(row1) - getIndex(row2));
		};
	}

	static getHierarchyData(data: IDataPoint[], groups: ReportGrouping[],
		seriesNameFormatter?: SeriesNameFormatter): AnalyticHierarchyData[] {
		let nodeFunction: INodeFunction<AnalyticHierarchyData> = (uniqId, name, uniqName, group, item, children) => {
			let node = item as AnalyticHierarchyData;
			node._id = uniqId;
			node._name = name;
			node.name = node.name || name;
			node.displayName = name;
			node._uniqName = uniqName;
			node._children = AnalyticDataUtils.updateParentId(children, uniqId);
			node._group = group;
			return node;
		};
		return AnalyticDataUtils.getHierarchyDataInternal(data, groups, seriesNameFormatter, nodeFunction);
	}

	static getHierarchyDataV2(rawData: IDataPoint[], groups: ReportGrouping[],
		seriesNameFormatter: SeriesNameFormatter): AnalyticHierarchyDataV2[] {
		let nodeFunction: INodeFunction<AnalyticHierarchyDataV2> = (uniqId, name, uniqName, group, item, children) => {
			item._group = group;
			return {
				_id: uniqId,
				name,
				displayName: name,
				_uniqName: uniqName,
				row: item,
				leaf: !(children && children.length),
				_children: AnalyticDataUtils.updateParentId(children, uniqId)
			};
		};

		return AnalyticDataUtils.getHierarchyDataInternal(rawData, groups, seriesNameFormatter, nodeFunction);
	}

	private static updateParentId<T extends HasParent>(array: T[], parentId: string): T[] {
		return _.each(array, (node) => {
			node._parentId = parentId;
		});
	}

	private static getHierarchyDataInternal<T extends HasChildren<T>>(rawData: IDataPoint[], groups: ReportGrouping[],
		seriesNameFormatter: SeriesNameFormatter, nodeFunction: INodeFunction<T>): T[] {
		let data = rawData as any as IDataPointObject[];
		let root = {
			_children: data
		} as unknown as T;
		let levelMap = _.groupBy(data, 'level');
		return AnalyticDataUtils.processLevelRecursively(0, root, nodeFunction, levelMap, groups, seriesNameFormatter);
	}

	private static processLevelRecursively<T extends HasChildren<T>>(level: number, parentNode: T | IDataPointObject,
		nodeFunction: INodeFunction<T>, levelMap: {[key: number]: IDataPointObject[]}, groups: ReportGrouping[],
		seriesNameFormatter: SeriesNameFormatter): T[] {
		if (!levelMap[level] || !groups[level]) {
			return;
		}
		let currentLevel: T[] = [];
		let group = groups[level];
		for (let i = 0; i < levelMap[level].length; i++) {
			let node = levelMap[level][i];
			if (AnalyticDataUtils.checkNodeGrouping(node, parentNode as any, groups, level)) {
				let value = AnalyticDataUtils.getCategoryItemName(group, node);
				//for recolor: classification name is not a unique value, use path instead
				let uniqName = node[group.identifier + ReportConstants.FULL_PATH] || value;
				if (seriesNameFormatter && seriesNameFormatter[group.identifier]) {
					value = seriesNameFormatter[group.identifier](value);
				}
				currentLevel.push(nodeFunction(
					`${level}_${i}`,
					value,
					uniqName,
					group,
					node,
					AnalyticDataUtils.processLevelRecursively(level + 1, node, nodeFunction, levelMap, groups, seriesNameFormatter)));
			}
		}
		return currentLevel;
	}

	private static checkNodeGrouping(node: IDataPointObject, parentNode: IDataPointObject, groups: ReportGrouping[], index: number): boolean {
		let i = 0;
		while (i < index) {
			let field = groups[i].identifier;
			if (!parentNode[field]
				|| (node[field] !== parentNode[field])
				|| (node[field + ReportConstants.FULL_PATH] !== parentNode[field + ReportConstants.FULL_PATH])) {
				return false;
			}
			i++;
		}

		return true;
	}

	private static getCategoryItemName(group: ReportGrouping, dataPoint: IDataPointObject, fallbackKey?: string): string {
		if (group.metricType === AnalyticMetricType.TIME) {
			let metadataFieldName = '_metadata_' + group.identifier;
			if (dataPoint[metadataFieldName]) {
				return dataPoint[metadataFieldName].displayName;
			}
		}

		if (group.identifier && dataPoint[group.identifier]) {
			return dataPoint[group.identifier];
		}

		if (fallbackKey && dataPoint[fallbackKey]) {
			return dataPoint[fallbackKey];
		}

		return null;
	}

	static transformToFlat<T extends HasChildren<T>>(data: T[]): T[] {
		if (data && data.length > 0) {
			let res = [];
			for (let node of data) {
				let childernNodes = node._children;
				delete node._children;
				res.push(node);
				res.pushAll(AnalyticDataUtils.transformToFlat(childernNodes));
			}
			return res;
		} else {
			return [];
		}
	}


	// adds parent for each pop pair, using values of current
	static processPopData(rawData: IDataPointObject[], popLevel: number, sortByP1Volume: boolean = false): IDataPointObject[] {
		popLevel -= 1; // returned data is 1 level less
		// remove unused period2 rows
		let filtered = _.filter(rawData, (row) => {
			return row.level >= popLevel || row._pop === ReportPeriods.CURRENT;
		});

		let result = [];
		_.each(filtered, (row, index) => {
			if (row.level === popLevel && row._pop === ReportPeriods.CURRENT) {
				let parent = cloneDeep(row);
				// sum periods values
				let next = filtered[index + 1];
				if (next && next._pop === ReportPeriods.HISTORIC && !sortByP1Volume) {
					parent.volume += next.volume || 0;
				}
				delete parent._pop;
				result.push(parent);
			}
			if (row.level < popLevel)
				delete row._pop;
			if (row.level >= popLevel)
				row.level += 1;
			result.push(row);
		});
		return result;
	}

	// used for PoP line/bar in stats/csv export
	static removeEmptyPointsForPop(rawData: IDataPointObject[], level: number, allGroupings: ReportGrouping[],
		popLevel: number): IDataPointObject[] {
		rawData = AnalyticDataUtils.processPopData(rawData, popLevel, false);
		allGroupings = [].concat(allGroupings);
		allGroupings.insert(popLevel, {identifier: '_pop'} as ReportGrouping);
		if (level >= popLevel) level += 1;

		let result = AnalyticDataUtils.removeEmptyPointsForTable(rawData, level, allGroupings);

		// removing auxiliary "parent" pop items, which were added in "processPopData()"
		result = _.filter(result, row => row.level !== popLevel - 1);
		return result;
	}

	static removeEmptyPointsForTable(rawData: IDataPointObject[], level: number, allGroupings: ReportGrouping[]): IDataPointObject[] {
		let groupIdentifiers = _.map(allGroupings, 'identifier');

		let emptyItems = _.chain(rawData)
			.filter(row => row.level === level)
			.filter(row => row.volume === 0)
			.map(row => AnalyticDataUtils.getIdPath(row, level, groupIdentifiers))
			.value();
		return _.filter(rawData, (row) => {
			// remove all empty items and their children
			return !(row.level >= level && _.contains(emptyItems, AnalyticDataUtils.getIdPath(row, level, groupIdentifiers)));
		});
	}

	static removeHiddedDataPoints(rawData: IDataPointObject[]): IDataPointObject[] {
		return _.filter(rawData, (row) => {
			return isFalse(row.filteredByConfidentiality);
		});
	}

	private static getIdPath(row: IDataPointObject, level: number, groupIdentifiers: string[]): string {
		return _.chain(groupIdentifiers)
			.first(level + 1)
			.map(field => row[field])
			.join('__')
			.value();
	}


}
