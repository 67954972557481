import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { CxLocaleService } from '@app/core';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import ColorPaletteActions
	from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-actions.service';

@Injectable({
	providedIn: 'root'
})
export class ColorPaletteContextMenu extends BaseContextMenuUtils {
	constructor(
		private locale: CxLocaleService,
		private actions: ColorPaletteActions
	) {
		super();
	}

	private readonly OPTIONS: {[name: string]: ContextMenuItem<ColorPalette>} = {
		EDIT: {
			name: 'edit',
			text: this.locale.getString('common.edit'),
			func: (palette: ColorPalette) => this.actions.editPalette(palette)
		},

		COPY: {
			name: 'copy',
			text: this.locale.getString('dashboard.copy'),
			func: (palette: ColorPalette) => this.actions.copyPalette(palette)
		},

		DELETE: {
			name: 'delete',
			text: this.locale.getString('common.delete'),
			func: (palette: ColorPalette) => this.actions.deleteAndReplacePalette(palette)
		},

		REPLACE: {
			name: 'replace',
			text: this.locale.getString('common.replace'),
			func: (palette: ColorPalette) => this.actions.replacePalette(palette)
		},

		MAKE_DEFAULT: {
			name: 'make-default',
			text: this.locale.getString('appearance.useAsAccountDefault'),
			func: (palette: ColorPalette) => this.actions.makeDefault(palette)
		},

		CURRENT_DEFAULT: {
			name: 'make-default',
			disabled: true,
			text: this.locale.getString('appearance.currentDefault')
		},
	};

	public getContextMenu(item: ColorPalette): Array<ContextMenuItem<ColorPalette>> {
		let options: Array<ContextMenuItem<ColorPalette>> = [];

		if (!item.system) {
			options.push(this.OPTIONS.EDIT);
		}

		if (!item.designerPalette) {
			options.push(this.OPTIONS.COPY);
			if (!item.defaultPalette) {
				options.push(item.enabled
					? this.OPTIONS.MAKE_DEFAULT
					: this.disabledOption(this.OPTIONS.MAKE_DEFAULT,
						this.locale.getString('appearance.disableDefaultPalette')));
			} else {
				options.push(this.OPTIONS.CURRENT_DEFAULT);
			}
		}

		options.push(!item.defaultPalette
			? this.OPTIONS.REPLACE
			: this.disabledOption(this.OPTIONS.REPLACE,
				this.locale.getString('appearance.replaceDefaultPalette')));

		options.push(this.getDeleteOption(item));

		return options;
	}

	public isVisibleObject(item: any): boolean {
		return true;
	}

	private getDeleteOption = (item: ColorPalette): ContextMenuItem<ColorPalette> => {
		if (!item.system && !item.defaultPalette) {
			return this.OPTIONS.DELETE;
		}

		return this.disabledOption(this.OPTIONS.DELETE,
			item.system
				? this.locale.getString('appearance.deleteSystemPalette')
				: this.locale.getString('appearance.deleteDefaultPalette'));
	}
}


