import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { JmsStatusApi } from './jms-status.api.service';
import { JmsListenerState, ListenerStatus, EmailListenerState } from './jms-listeners-state';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';

interface StatusFormatterData {
	icon: string;
	class: string;
	label: string;
}

@Component({
	selector: 'jms-listener-status-tab',
	templateUrl: './jms-listener-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JmsListenerStatusTabComponent implements OnInit {
	emailQueues: EmailListenerState[] = [];
	emailQueuesStatusColumns: Array<TableColumn<EmailListenerState>>;

	private statusesMap: {[key in ListenerStatus]: StatusFormatterData};

	constructor(
		private ref: ChangeDetectorRef,
		private jmsStatusApi: JmsStatusApi,
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService
	) {}

	ngOnInit(): void {
		this.statusesMap = this.getStatusesMap();
		this.emailQueuesStatusColumns = this.getEmailQueuesStatusColumns();
		this.refreshData();
	}

	getEmailsListenersStatePanelType = (): string => {
		return this.hasFailedEmailListeners() ? 'danger' : 'success';
	}

	private hasFailedEmailListeners = (): boolean => {
		let failedListener = this.emailQueues.filter(this.filterDownListeners);
		return (failedListener.length > 0 || this.emailQueues.length === 0);
	}

	private filterDownListeners = (listener: JmsListenerState): boolean => {
		return listener.status === ListenerStatus.FAILED || listener.status === ListenerStatus.EXPIRED;
	}

	restartEmailListener = (nodeId: string): void => {
		this.jmsStatusApi.restartEmailListener(nodeId).then(() => {
			setTimeout(() => this.refreshData(), 5000);
		});
	}

	private refreshData = (): void => {
		this.jmsStatusApi.getData().then((result) => {
			this.emailQueues = result.emailListeners;
			this.ref.markForCheck();
		});
	}

	private getEmailQueuesStatusColumns(): Array<TableColumn<EmailListenerState>> {
		return [{
			name: 'status',
			displayName: this.locale.getString('administration.jmsBrokerStatus'),
			formatter: this.statusFormatter,
			width: 0.1
		}, {
			name: 'nodeId',
			displayName: this.locale.getString('administration.jmsBrokerServer'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.3
		}, {
			name: 'message',
			displayName: this.locale.getString('administration.jmsBrokerInfo'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.3
		}, {
			name: 'queueSize',
			path: 'currentMetrics.queueSize',
			displayName: this.locale.getString('administration.queueSize'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'dequeueCount',
			path: 'currentMetrics.dequeueCount',
			displayName: this.locale.getString('administration.dequeueCount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'restart',
			displayName: this.locale.getString('administration.restart'),
			formatter: () => `
				<span class="btn btn-secondary">
					<span class="q-icon-refresh"></span> ${this.locale.getString('administration.restart')}
				</span>
			`,
			action: (listener) => this.restartEmailListener(listener.nodeId),
			width: 0.1
		}];
	}

	private getStatusesMap(): {[key in ListenerStatus]: StatusFormatterData} {
		return {
			OK: {
				icon: 'q-icon-check',
				class: 'text-success',
				label: this.locale.getString('administration.stateUp')
			}, FAILED: {
				icon: 'q-icon-warning',
				class: 'text-danger',
				label: this.locale.getString('administration.stateFailed')
			}, EXPIRED: {
				icon: 'q-icon-warning',
				class: 'text-danger',
				label: this.locale.getString('administration.stateExpired')
			}
		};
	}

	private statusFormatter = (object: JmsListenerState): string => {
		let status = object.status;
		let statusData = this.statusesMap[status];
		return `
			<b class="${statusData.class}">
				<span class="${statusData.icon}"></span> ${statusData.label}
			</b>
		`;
	}
}
