import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { forbiddenNameValidator } from './forbidden-name-validator.directive';

@Directive({
	selector: '[forbiddenNameCheck]',
	providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenNameValidatorDirective, multi: true }]
})
export class ForbiddenNameValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		return forbiddenNameValidator()(control);
	}
}