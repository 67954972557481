import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TranslationApi } from '@app/modules/translation/translation-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { Observable, Subject } from 'rxjs';
import * as _ from 'underscore';

@Injectable({
	providedIn: 'root'
})
export class DocumentTranslationService {

	private limitReached: boolean = true;
	private translateContractAvailable: boolean;
	private initialLimitCheckPromise: Promise<void>;
	private contractCheckPromise: Promise<void>;
	private readonly updateSubject = new Subject<void>();

	constructor(
		@Inject('maPropertiesService') private readonly maPropertiesService: MAPropertiesService,
		@Inject('contractApiService') private readonly contractApiService: ContractApiService,
		private readonly translationApi: TranslationApi,
	) {}

	getChangeObserver(): Observable<void> {
		return this.updateSubject.asObservable();
	}

	checkLimitReached(): Promise<void> {
		return this.translationApi.checkLimitReached().then(response => {
			let changed = this.limitReached !== response;
			this.limitReached = isTrue(response);
			if (changed) this.updateSubject.next();
		});
	}

	isReachedLimit = (): boolean => {
		return this.limitReached;
	}

	isEnabled = (): boolean => {
		if (!this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.QUICK_TRANSLATE))
			return false;
		this.ensureContract();
		this.ensureInitialLimitCheck();
		return this.translateContractAvailable;
	}

	private ensureContract(): void {
		if (_.isUndefined(this.contractCheckPromise)) {
			this.contractCheckPromise = PromiseUtils.wrap(this.contractApiService.isQuickTranslateAvailable().then(available => {
				this.translateContractAvailable = available;
				if (available)
					this.updateSubject.next();
			}));
		}
	}

	private ensureInitialLimitCheck(): void {
		if (_.isUndefined(this.initialLimitCheckPromise)) {
			this.initialLimitCheckPromise = this.checkLimitReached();
		}
	}

	getCurrentTranslationLanguageDisplayName = (): string => {
		return this.translationApi.getTargetLanguageDisplayName();
	}


}

app.service('documentTranslationService', downgradeInjectable(DocumentTranslationService));
