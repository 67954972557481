<div class="form-group">
	<label *ngIf="!isFilterRule" class="mandatory">{{'preview.esQuery'|i18n}}</label>
	<div>
		<table cellspacing="0" cellpadding="0" class="tblKey" aria-hidden="false">
			<tbody>
				<tr class="merged-textarea-row">
					<td class="ruleColor1">
						<div title="{{'preview.keywords'|i18n}}" class="text-center cursor-no-selection display-sm p-2">+</div>
					</td>
					<td width="100%">
						<textarea name="0"
							class="no-resize w-100-percent suggestion-rules-textarea" 
							placeholder="{{'preview.keyword_kw'|i18n}}" 
							[(ngModel)]="esQueryObject.keyword"
							(ngModelChange)="onChange()"
							[ngModelOptions]="{ allowInvalid: true }"
							[maxlength]="maxlength">
						</textarea>
					</td>
				</tr>
				<tr class="merged-textarea-row">
					<td class="ruleColor2">
						<div title="{{'preview.andKeywords'|i18n}}" class="text-center cursor-no-selection display-sm p-2">+</div>
					</td>
					<td width="100%">
						<textarea name="1" 
							class="no-resize no-round w-100-percent suggestion-rules-textarea" 
							placeholder="{{'preview.and1_kw'|i18n}}" 
							[(ngModel)]="esQueryObject.and1"
							(ngModelChange)="onChange()"
							[ngModelOptions]="{ allowInvalid: true }"
							[maxlength]="maxlength">
						</textarea>
					</td>
				</tr>
				<tr class="merged-textarea-row">
					<td class="ruleColor3">
						<div title="{{'preview.and2Keywords'|i18n}}"class="text-center cursor-no-selection display-sm p-2">+</div>
					</td>
					<td width="100%">
						<textarea name="2"
							class="no-resize no-round w-100-percent suggestion-rules-textarea" 
							placeholder="{{'preview.and2_kw'|i18n}}" 
							[(ngModel)]="esQueryObject.and2"
							(ngModelChange)="onChange()"
							[ngModelOptions]="{ allowInvalid: true }"
							[maxlength]="maxlength">
						</textarea>
					</td>
				</tr>
				<tr class="merged-textarea-row">
					<td class="ruleColor4">
						<div title="{{'preview.notKeywords'|i18n}}" class="text-center cursor-no-selection display-sm p-2">&ndash;</div>
					</td>
					<td width="100%">
						<textarea 
							name="3"
							class="no-resize w-100-percent suggestion-rules-textarea" 
							placeholder="{{'preview.not_kw'|i18n}}" 
							[(ngModel)]="esQueryObject.not"
							(ngModelChange)="onChange()"
							[ngModelOptions]="{ allowInvalid: true }"
							[maxlength]="maxlength">
						</textarea>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>