export class DashboardPropertiesConfig {
	existingItem: boolean;
	hiddenItems: any;
	customLabels: any;
	itemList: any[];
	folders: any[];
	explicitelyAllowFolderSelection: boolean;
	selectedFolder: any;
	isProperties: boolean;
	templateId: number;
}
