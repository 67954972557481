import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectInterceptComponent } from './redirect-intercept.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		RedirectInterceptComponent
	],
})
export class RedirectModule { }
