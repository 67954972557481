import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CustomMathFormula } from '@app/modules/metric/definition/custom-math/editor/custom-math-formula.class';
import { MathMetricToken } from '@app/modules/metric/definition/custom-math/editor/math-metric-token';
import { TokenSuggestion } from '@app/modules/metric/definition/custom-math/editor/custom-math-suggestion.class';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { SubmenuClickEvent } from '../custom-math-search-list/custom-math-search-list.component';
import { CustomMathMemoryService } from '../custom-math-memory.service';
import { CustomMetricExpressionProvider } from '@app/modules/metric/definition/custom-math/custom-metric-expression.provider';

declare let app: angular.IModule;


@Component({
	selector: 'custom-math-button-bar',
	template: `
<div class="math-button-bar d-flex flex-wrap">
	<div class="d-flex mb-8">
		<custom-math-search-list
			class="mr-4"
			id="custom-math-attributes-dropdown"
			[suggestions]="attributesSuggestions"
			displayField="displayName"
			selectPrompt="{{'metrics.attribute'|i18n}}"
			(click)="clickOnDropdown($event)"
			(selectSuggestion)="selectDropdownSuggestion($event)"
			[hasSubmenuArrow]="hasSubmenuArrow"
			(onSubmenuSuggestionSelection)="applySubmenuSuggestion($event)"
		></custom-math-search-list>
		<custom-math-search-list
			class="mr-4"
			id="custom-math-metrics-dropdown"
			[suggestions]="metricSuggestions"
			displayField="displayName"
			tooltipField="title"
			selectPrompt="{{'metrics.metrics'|i18n}}"
			(click)="clickOnDropdown($event)"
			(selectSuggestion)="selectDropdownSuggestion($event)"
		></custom-math-search-list>
		<custom-math-search-list
			class="org-hierarchy-search-list mr-4"
			id="custom-math-org-dropdown"
			[suggestions]="hierarchyMetricsSuggestions"
			displayField="displayName"
			selectPrompt="{{'organization.orgHierarchy'|i18n}}"
			(click)="clickOnDropdown($event)"
			(selectSuggestion)="selectDropdownSuggestion($event)"
		></custom-math-search-list>
	</div>
	<div class="btn-group mr-4 mb-8">
		<button *ngFor="let operator of operators"
			class="btn btn-secondary"
			[innerHTML]="operator.displayName"
			(click)="selectOperator(operator)">
		</button>
	</div>
	<div class="btn-group ml-0 mb-8">
		<button class="btn btn-secondary"
			[class.disabled]="!changesCanBeUndone()"
			title="{{'metrics.undoTitle'|i18n}}"
			(click)="undo.emit(true)"
			(keydown.enter)="undo.emit(true)">
			<span class="q-icon q-icon-undo" aria-hidden="true"></span>
		</button>
		<button class="btn btn-secondary"
			[class.disabled]="!changesCanBeRedone()"
			title="{{'metrics.redoTitle'|i18n}}"
			(click)="redo.emit(true)"
			(keydown.enter)="redo.emit(true)">
			<span class="q-icon q-icon-redo" aria-hidden="true"></span>
		</button>
	</div>
</div>
`,
	styles: [`
		:host > .formula-button-bar {
			 min-height: 40px;
		}
	`]
})

export class CustomMathButtonBarComponent implements OnInit {
	@Input() formula: CustomMathFormula;
	@Input() attributesSuggestions: TokenSuggestion[];
	@Input() metricSuggestions: TokenSuggestion[];
	@Input() hierarchyMetricsSuggestions: TokenSuggestion[];
	@Input() placeholderShown: boolean;

	@Output() onButtonBarClick = new EventEmitter<void>();
	@Output() onChange =  new EventEmitter<boolean>();
	@Output() onCaretPositionChange = new EventEmitter<number>();
	@Output() onTextAdd = new EventEmitter<string>();
	@Output() onAfterInsert = new EventEmitter<{suggestion: TokenSuggestion, leadingTokens: MathMetricToken[]}>();
	@Output() removePlaceholder = new EventEmitter<void>();
	@Output() updateAppliedMetrics = new EventEmitter<TokenSuggestion>();
	@Output() redo = new EventEmitter<boolean>();
	@Output() undo = new EventEmitter<boolean>();

	// aggregations submenu
	@Input() hasSubmenuArrow: (suggestion: TokenSuggestion) => boolean;
	@Output() applyAggregationSuggestion = new EventEmitter<TokenSuggestion>();

	operators: ExpressionItem[];

	constructor(
		private customMetricExpressionProvider: CustomMetricExpressionProvider,
		private customMathMemoryService: CustomMathMemoryService
	) {}

	ngOnInit(): void {
		this.operators = this.customMetricExpressionProvider.getOperators();
	}

	clickOnDropdown(event: any): void {
		if (event.target.id === 'custom-math-search-list-dropdown-toggle') {
			this.onButtonBarClick.emit();
		}
	}

	selectOperator(operator: ExpressionItem): void {
		if (this.placeholderShown) {
			this.removePlaceholder.emit();
		}

		this.onButtonBarClick.emit();
		let {operatorValue, caretOffset} = this.getOpeatorValue(operator);
		this.insertSimpleText(operatorValue, caretOffset);
	}

	private insertSimpleText(text: string, caretOffset: number): void {
		this.onTextAdd.emit(text);
		let newCaretPosition: number = this.calculateCaretPosition() + text.length - caretOffset;
		this.onCaretPositionChange.emit(newCaretPosition);
		this.onChange.emit(true);
	}

	private getOpeatorValue(operator: ExpressionItem): {operatorValue: string, caretOffset: number} {
		let operatorValue = operator.value;
		let operatorType = operator.type;
		if (operatorValue) {
			return {operatorValue, caretOffset: 0};
		} else if (operatorType === ExpressionPieces.ABSOLUTE) {
			return {operatorValue: 'abs()', caretOffset: 1};
		} else if (operatorType === ExpressionPieces.EXPONENT) {
			return {operatorValue: '^', caretOffset: 0};
		}
	}

	applySubmenuSuggestion(submenuClickEvent: SubmenuClickEvent) {
		this.selectDropdownSuggestion(submenuClickEvent.suggestion, true);
		this.applyAggregationSuggestion.emit(submenuClickEvent.numericAggregationSuggestion);
	}

	selectDropdownSuggestion(suggestion: TokenSuggestion, skipEditorStateRemembering?: boolean): void {
		if (this.placeholderShown) {
			this.removePlaceholder.emit();
		}

		let caretPoisiton: number = this.calculateCaretPosition();
		let leadingTokens: MathMetricToken[] = this.formula.getPreviousTokens(caretPoisiton);
		this.setElementContent(suggestion, leadingTokens);
		this.setNewCaretPosition(suggestion, leadingTokens, caretPoisiton);
		this.updateAppliedMetrics.emit(suggestion);
		this.onChange.emit(!skipEditorStateRemembering);

		if (suggestion.onAfterInsert) {
			this.onAfterInsert.emit({suggestion, leadingTokens});
		}
	}

	private calculateCaretPosition(): number {
		let lastToken = this.formula.getLastToken();
		return lastToken ? lastToken.offset + lastToken.text.length : 0;
	}

	private setElementContent(suggestion: TokenSuggestion, leadingTokens: MathMetricToken[]): void {
		let text = suggestion.insertValue(false, leadingTokens);
		this.onTextAdd.emit(text);
	}

	private setNewCaretPosition(suggestion: TokenSuggestion, leadingTokens: MathMetricToken[], caretPoisiton: number): void {
		let insertionCaretOffset = suggestion.getCaretPositionAfterInsert(false, leadingTokens);
		let newCaretPosition = caretPoisiton + insertionCaretOffset;
		this.onCaretPositionChange.emit(newCaretPosition);
	}

	changesCanBeUndone(): boolean {
		return this.customMathMemoryService.canUndo();
	}

	changesCanBeRedone(): boolean {
		return this.customMathMemoryService.canRedo();
	}

}

app.directive('customMathButtonBar', downgradeComponent({component: CustomMathButtonBarComponent}) as angular.IDirectiveFactory);
