import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { ContentWidget, ExternalWidget, WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetValidationService } from '@app/modules/widget-settings/widget-validation.service';

interface IWidgetSettingsModalParams {
	widget: ContentWidget | ExternalWidget;
}
export class WidgetSettingsModalComponent extends ModalBindings<IWidgetSettingsModalParams> {

	widget: ContentWidget | ExternalWidget;

	constructor(
		private readonly locale: ILocale,
		private readonly widgetValidation: WidgetValidationService,
	) {
		super();
	}

	$onInit(): void {
		this.widget = this.resolve.widget;
	}

	getHeader(): string {
		switch (this.widget.type) {
			case WidgetDisplayType.CONTENT: return this.widget.properties.widgetType === WidgetType.BUTTON
				? this.locale.getString('widgetSettings.addButton')
				: this.locale.getString('widgetSettings.addContent');
			case WidgetDisplayType.EXTERNAL: return this.locale.getString('widgetSettings.externalHeader');
		}
		throw new Error(`Invalid widget type: ${this.widget.type}`);
	}

	submit = (): void => {
		if (this.widgetValidation.validate(this.widget)) {
			this.close({$value: this.widget});
		}
	}
}

app.component('widgetSettingsModal', {
	controller: WidgetSettingsModalComponent,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	template: `
<modal-header
	(cancel)="$ctrl.dismiss()"
	[modal-title]="$ctrl.getHeader()">
</modal-header>
<div id="settings" class="modal-body p-0">
	<content-widget-settings ng-if="$ctrl.widget.type === 'CONTENT'" [widget]="$ctrl.widget"></content-widget-settings>
	<external-widget-settings ng-if="$ctrl.widget.type === 'EXTERNAL'" [widget]="$ctrl.widget"></external-widget-settings>
</div>
<save-modal-footer
	(save)="$ctrl.submit()"
	(cancel)="$ctrl.dismiss()"
	[save-text]="'common.ok' | i18n"
></save-modal-footer>
`,
});
