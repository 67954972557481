import { Inject, Injectable } from '@angular/core';

import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import * as _ from 'underscore';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { IRowFormatter, GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

export interface IDashboardListController {
	isBookEdit(): boolean;
}

@Injectable({
	providedIn: 'root'
})
export default class DashboardGridDefinitionService implements IGridDefinition<IDashboardListController> {

	private readonly NAME_FIELD = 'name';

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('maPropertiesService') private maPropertiesService: MAPropertiesService,
		@Inject('commonGridColumns') private commonGridColumns: CommonGridColumns) {}

	init = (gridMode: GridMode, controller: IDashboardListController) => {
		let columnDefinitions = {
			CHECKBOX: {
				id: 'checkbox',
				sortable: false,
				minWidth: 32,
				width: 32,
				headerCssClass: 'header-checkbox text-center',
				name: '<span></span>',
				cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
				formatter: this.gridFormatterService.getSelectedRowFormatterForObjectType(
					this.locale.getString('object.dashboardOrBook'), this.NAME_FIELD),
				enabledIf:  () => !controller.isBookEdit(),
				resizable: false,
				customCellAttributes: (row, cell, value, columnDef, dataContext): {[key: string]: any} | undefined => {
					if (this.gridFormatterService.omitCheckbox(dataContext)) {
						return { 'aria-hidden': true };
					}
				}
				// no name property, as we'll add the checkbox in the html
			},

			HAMBURGER: {
				id: 'hamburger',
				sortable: false,
				minWidth: 32,
				width: 32,
				headerCssClass: 'header-hamburger text-center',
				name: `<i class="q-icon-layer" aria-label="${this.locale.getString('common.genericObjectMenu')}"></i>`,
				cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
				formatter: this.dashboardHamburgerFormatter,
				enabledIf:  () => !controller.isBookEdit(),
				resizable: false,
				headerAttributes: {
					title: this.locale.getString('common.genericObjectMenu')
				}
			},

			FAVORITE: {
				id: 'favorite',
				sortable: false,
				minWidth: 32,
				width: 32,
				headerCssClass: 'header-favorite text-center',
				cssClass: 'cell-favorite text-center no-border-if-folder action-hover-text',
				name: `<i class="q-icon-star-outline" title="${this.locale.getString('common.favoriteDashboardOrBook')}" aria-label="${this.locale.getString('common.favoriteDashboardOrBook')}"></i>`,
				formatter: this.gridFormatterService.getFavoriteFormatter(this.NAME_FIELD),
				enabledIf:  () => !controller.isBookEdit(),
				resizable: false
			},

			LABELS: this.commonGridColumns.getLabelsColumn(this.locale.getString('object.dashboardOrBook')),

			SPACER: {
				id: 'spacer',
				sortable: false,
				minWidth: 16,
				width: 16,
				resizable: false,
				cssClass: 'no-border-if-folder',
				focusable: false,
				aria: {
					hidden: true
				}
			},

			NAME: {
				id: 'name',
				name: this.locale.getString('common.name'),
				field: this.NAME_FIELD,
				sortable: true,
				minWidth: 240,
				width: 660,
				defaultSortColumn: SortDirection.ASC,
				headerCssClass: 'cell-dashboard-title',
				cssClass: 'cell-title',
				formatter: this.gridFormatterService.getNameFormatter(GridTypes.DASHBOARD, gridMode),
				isObjectNameColumn: true
			},

			SCHEDULE: {
				id: 'schedule',
				field: 'scheduleCount',
				sortable: false,
				minWidth: 40,
				width: 40,
				cssClass: 'cell-schedule text-center',
				formatter: this.gridFormatterService.ScheduleFormatter,
				enabledIf:  () => !controller.isBookEdit(),
				customCellAttributes: (row, cell, value, columnDef, dataContext): {[key: string]: any} | undefined => {
					if (dataContext.type !== 'dashboard' || !dataContext.scheduleCount) {
						return { 'aria-hidden': true };
					}
				}
			},

			CREATED: {
				id: 'created',
				name: this.locale.getString('dashboard.modifiedDate'),
				field: 'modifiedDate',
				sortable: true,
				optional: true,
				minWidth: 100,
				width: 240,
				cssClass: 'cell-date',
				formatter: this.gridFormatterService.DateFormatter
			},

			OWNER: {
				id: 'ownerName',
				name: this.locale.getString('common.owner'),
				field: 'ownerName',
				sortable: true,
				optional: true,
				minWidth: 80,
				width: 260,
				cssClass: 'cell-owner-name',
				formatter: this.gridFormatterService.getAuthorFormatter(gridMode)
			},

			SHARED: {
				id: 'sharingStatus',
				name: this.locale.getString('dashboard.status'),
				field: 'sharingStatus',
				sortable: true,
				optional: true,
				minWidth: 60,
				width: 100,
				headerCssClass: 'text-center',
				formatter: this.gridFormatterService.StatusFormatter,
				cssClass: 'cell-status text-center'
			},

			VIEWS: {
				id: 'views',
				name: this.locale.getString('dashboard.views'),
				field: 'views',
				sortable: true,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-views text-right pr-16',
				headerCssClass: 'heading-views text-right'
			},

			RATING: {
				id: 'rating',
				name: this.locale.getString('dashboard.rating'),
				field: 'rating',
				sortable: true,
				optional: true,
				cssClass: 'br-rating cell-rating pl-8',
				headerCssClass: 'heading-rating pl-8',
				minWidth: 80,
				width: 200,
				formatter: this.gridFormatterService.getRatingFormatter(gridMode),
			}
		};

		let columns = [
			columnDefinitions.CHECKBOX,
			columnDefinitions.FAVORITE,
			columnDefinitions.LABELS,
			columnDefinitions.SPACER,
			columnDefinitions.NAME,
			columnDefinitions.SCHEDULE,
			columnDefinitions.CREATED,
			columnDefinitions.OWNER,
			columnDefinitions.SHARED,
			columnDefinitions.VIEWS
		];

		columnDefinitions.NAME.formatter = this.gridFormatterService.getNameFormatter(GridTypes.DASHBOARD, gridMode);
		columnDefinitions.OWNER.formatter = this.gridFormatterService.getAuthorFormatter(gridMode);

		if (gridMode !== GridMode.VIEW) {
			columns.insert(1, columnDefinitions.HAMBURGER);
		}

		if (this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_RATING)) {
			columnDefinitions.RATING.formatter = this.gridFormatterService.getRatingFormatter(gridMode);
			columns.push(columnDefinitions.RATING);
		}

		return Promise.resolve(columns);
	}

	dashboardHamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (this.ignoreHamburger(dataContext))
			return '';

		return this.gridFormatterService.getHamburger(dataContext, this.NAME_FIELD);
	}

	private ignoreHamburger(filter): boolean {
		return DashboardType.FOLDER === filter.type && DashboardType.FEEDBACK_FOLDER === filter.id;
	}
}
app.service('dashboardGridDefinition', downgradeInjectable(DashboardGridDefinitionService));

