import { MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { MetricDescriptionBuilder } from './metric-description-builder';

export class GaugeDescriptionBuilder extends MetricDescriptionBuilder {

	getDescription = (data?: any) => {
		const singleSelectedMetric: ReportCalculation = this.properties.selectedMetrics[0];
		let calculation: string = singleSelectedMetric.displayName;
		let hasDefinition: MetricDefinition = singleSelectedMetric.definition;
		let minMax: string = '';

		return this.utils.getWidgetUtils(this.widget).then(utils => {
			if (hasDefinition) {
				let value = data?.data[0][singleSelectedMetric.name];

				const bounds = this.utils.getMetricBounds(this.widget.properties, this.widget.visualProperties, value);
				let min = this.utils.getMetricDisplayValue(bounds.min, singleSelectedMetric, utils.metrics);
				let max = this.utils.getMetricDisplayValue(bounds.max, singleSelectedMetric, utils.metrics);
				minMax = this.locale.getString('widgetDescription.gaugeMinMax', { min, max });
			}
			let comparison: string = '';
			let comparisonsArr: MetricWidgetComparison[] = (this.properties as MetricWidgetProperties).comparisons;

			comparison = this.createComparisonsText(comparison, comparisonsArr, singleSelectedMetric, utils);

			let total = this.utils.getTotal(this.visual, data);
			let palette = this.utils.getPaletteDescription('gaugePalette', this.visual.color);

			if (calculation) {
				return this.locale.getString('widgetDescription.gaugeDescription', {
					calculation,
					minMax,
					comparison,
					total,
					palette
				});
			}
			return '';
		}) as any;
	}

	private createComparisonsText = (comparison: string, comparisonsArr: MetricWidgetComparison[],
		singleSelectedMetric: ReportCalculation, utils) => {

		if (!comparisonsArr) {
			return '';
		} else if (comparisonsArr.length === 1) {
				let comp: MetricWidgetComparison = comparisonsArr[0];
				let periodName: string = this.getComparisonName(comp, utils);
				let value: string | number = (comp.type === MetricComparisonType.GOAL) ?
					this.utils.getMetricDisplayValue(comp.value.goal, singleSelectedMetric, utils.metrics) : '';
				return this.locale.getString(`widgetDescription.genericComparison`, { comparisonName: periodName, value });
		} else {
			comparisonsArr.sort((compTypeA, compTypeB) => {
				return compTypeA.type.localeCompare(compTypeB.type);
			});

			comparisonsArr.forEach( comparisonObj => {
				let comp: MetricWidgetComparison = comparisonObj;
				let isGoalType: boolean = comp.type === MetricComparisonType.GOAL;
				let periodName: string = this.getComparisonName(comp, utils);
				let value: string | number = (isGoalType) ?
					this.utils.getMetricDisplayValue(comp.value.goal, singleSelectedMetric, utils.metrics) : '';

				comparison = isGoalType ?
					comparison.concat(this.locale.getString(`widgetDescription.genericComparison`,
					{ comparisonName: periodName, value })) :
					comparison.concat(this.locale.getString(`widgetDescription.gaugeCustomAndPeriod`));
				});
				return comparison;
		}
	}
}
