export class SMTPServerSettings {
	enabled: boolean;
	host: string;
	port: string;
	password: string;
	username: string;

	constructor(settings: Partial<SMTPServerSettings> = {}) {
		this.enabled = settings.enabled ?? false;
		this.host = settings.host ?? '';
		this.port = settings.port ?? '';
		this.password = settings.password ?? '';
		this.username = settings.username ?? '';
	}
}
