import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import * as _ from 'underscore';

import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { CxLocaleService } from '@app/core';
import { IFocusMoveParams } from '@app/shared/components/forms/tree/focus';
import { ITreeSelection, ITreeSelectionNode, TreeSelectionBuilder, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { TreeNode } from '../forms/tree/tree-node';


export interface ITreeSelectionUINode extends INode {
	treeRoot?: boolean;
	root?: boolean;
	parentId?: number;
	parent?: ITreeSelectionUINode;
	children?: ITreeSelectionUINode[];
	path?: string | number[];
	needsChildLoading?: boolean;
	placeholder?: boolean;
	idPath?: string;
	modelId?: number;
}

@Component({
	selector: 'tree-selection',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tree-selection.component.html',
})
export class TreeSelectionComponent implements OnInit, OnChanges {
	readonly PLACEHOLDER_NODE_ID = 0;
	readonly PLACEHOLDER_NODE_NAME = '';

	@Input() treeSelection: ITreeSelection;
	@Input() root: ITreeSelectionUINode;

	@Input() optionClassFormatter: (item) => string;
	@Input() onSelectNone: () => ITreeSelection;
	@Input() loadRootChildren: () => Promise<ITreeSelectionUINode[]>;
	@Input() loadChild: (node: ITreeSelectionUINode) => Promise<ITreeSelectionUINode[]>;
	@Input() convertNodes: (node: ITreeSelectionUINode, nodes: ITreeSelectionUINode[]) => any[];
	@Input() childSelectedExtra: (node: INode) => boolean;
	@Input() selectEverythingText: string;
	@Input() selectNoneText: string;
	@Input() searchLabel: string;
	@Input() highlightSelectedBackground: boolean;

	@Input() forcedOrder: boolean;
	@Input() showMultiselectButtons: boolean;
	@Input() ignoreDisabled: boolean;
	@Input() reloadWatch: any;
	@Input() refreshWatch: boolean;
	@Input() hideSearch: boolean;
	@Input() autoFocus: boolean;
	@Input() treeName?: string;

	@Input() nodeCheckboxDisabled: (node: INode) => boolean;
	@Input() getNodeTooltip: (node: TreeNode) => string;

	@Output() onTreeSelectionChanged = new EventEmitter<ITreeSelection>();
	@Output() onFocusMove = new EventEmitter<IFocusMoveParams>();

	lazyLoad = true;
	promises: { rootChildrenLoading: any; childLoading: any; };

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		if (!this.root.children) this.root.children = [];
		if (!this.loadRootChildren) this.loadRootChildren = this.returnNoChildren;
		if (!this.loadChild) {
			this.loadChild = this.returnNoChildren;
			this.lazyLoad = false;
		}
		if (!this.onSelectNone) this.onSelectNone = this.getNoneSelection;
		if (!this.convertNodes) this.convertNodes = this.returnNodeIds;
		if (!this.selectEverythingText) this.selectEverythingText = this.locale.getString('common.selectAll');
		if (!this.selectNoneText) this.selectNoneText = this.locale.getString('administration.selectNone');
		if (!this.highlightSelectedBackground) this.highlightSelectedBackground = false;
		if (_.isUndefined(this.showMultiselectButtons)) this.showMultiselectButtons = true;

		this.root.path = [];
		this.root.treeRoot = true;

		this.promises = {
			rootChildrenLoading: null,
			childLoading: null
		};

		this.loadRootChildrenInternal();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.reloadWatch?.previousValue !== changes.reloadWatch?.currentValue) {
			this.loadRootChildrenInternal();
		}

		if (changes.refreshWatch?.previousValue !== changes.refreshWatch?.currentValue) {
			this.ref.detectChanges();
		}
	}

	isNodeMarked = (node: ITreeSelectionUINode): boolean => {
		return this.isChildSelected(node);
	}

	private isChildSelected = (node: ITreeSelectionUINode): boolean => {
		if (this.childSelectedExtra && this.childSelectedExtra(node)) return true;

		return this.intersection(this.treeSelection.nodes, this.convertNodes(node, this.getNodeChildren(node)))
			.length > 0;
	}

	private loadRootChildrenInternal = (): void => {
		if (!this.promises) return;
		this.promises.rootChildrenLoading = this.loadRootChildren().then((rootChildren) => {
			rootChildren.forEach((rootChild) => {
				rootChild.root = true;
				if (this.lazyLoad && !rootChild.children) {
					rootChild.children = [ this.createPlaceholder() ];
				}
				rootChild.parent = this.root;
			});
			this.root.children = rootChildren;
		});
	}

	isShowingNodeCheckbox = (node: ITreeSelectionUINode): boolean => {
		return !this.isPlaceholder(node);
	}

	isNodeChecked = (node: ITreeSelectionUINode): boolean => {
		let ignoreDisabled = this.ignoreDisabled && !!this.nodeCheckboxDisabled;

		let strategy = this.treeSelection.strategy;
		if (strategy === TreeSelectionStrategy.EVERYTHING) {
			return ignoreDisabled ? !this.nodeCheckboxDisabled(node) : true;
		}
		if (strategy === TreeSelectionStrategy.NONE) {
			return false;
		}

		let subsetSelected = this.intersection(this.treeSelection.nodes, this.convertNodes(node, this.getNodePath(node)))
			.length > 0;

		return ignoreDisabled ? !this.nodeCheckboxDisabled(node) && subsetSelected : subsetSelected;
	}

	selectEverything = (): void => {
		let resultTreeSelection = TreeSelectionBuilder.everything();
		this.treeSelection.strategy = resultTreeSelection.strategy;
		this.treeSelection.nodes = resultTreeSelection.nodes;
		let modelId = 0;
		if (this.root && this.root.children && this.root.children[0] && this.root.children[0].parentId) {
			modelId = this.root.children[0].parentId;
		}
		this.onTreeSelectionChanged.emit({
			strategy: resultTreeSelection.strategy,
			nodes: [{ idPath: modelId + ''}]
		});

	}

	selectNone = (): void => {
		let resultTreeSelection = this.onSelectNone();
		this.treeSelection.strategy = resultTreeSelection.strategy;
		this.treeSelection.nodes = resultTreeSelection.nodes;
		this.onTreeSelectionChanged.emit(this.treeSelection);
	}

	private isAllRootsSelected = (): boolean => {
		let selectedRootNodes = this.treeSelection.nodes
			.filter((node) => node.id === node.rootNodeId)
			.map((node) => node.id);
		let availableRootNodes = this.root.children.map((node) => node.id);

		return _.isEqual(selectedRootNodes, availableRootNodes);
	}

	handleNodeCheck = (node: ITreeSelectionUINode, $event?): void => {
		if ($event) {
			if ($event.__treeSelectionComponentNode) return;
			$event.__treeSelectionComponentNode = node;
		}

		if (this.isNodeCheckboxDisabled(node)) {
			return;
		}

		let resultTreeSelection = null;
		let strategy = this.treeSelection.strategy;
		if (strategy === TreeSelectionStrategy.EVERYTHING) {
			resultTreeSelection = TreeSelectionBuilder.subset(this.excludeBranch(this.getRoots(), node));
		} else if (strategy === TreeSelectionStrategy.NONE) {
			resultTreeSelection = TreeSelectionBuilder.subset(this.convertNodes(node, [ node ]));
		} else {
			let path = this.convertNodes(node, this.getNodePath(node));
			if (this.intersection(this.treeSelection.nodes, path).length > 0)
				resultTreeSelection = TreeSelectionBuilder.subset(this.excludeBranch(this.treeSelection.nodes, node));
			else
				resultTreeSelection = TreeSelectionBuilder.subset(this.includeBranch(this.treeSelection.nodes, node));
		}

		this.treeSelection.strategy = resultTreeSelection.strategy;
		this.treeSelection.nodes = resultTreeSelection.nodes;

		if (node.root && this.isAllRootsSelected()) {
			this.selectEverything();
			return;
		}

		this.onTreeSelectionChanged.emit(this.treeSelection);
	}

	expandFolder = (node: ITreeSelectionUINode): void => {
		if (!this.isChildLoaded(node) || node.needsChildLoading) {
			this.loadChildInternal(node);
		}
		if (!this.isParentRelationPopulatedForChildren(node)) {
			this.populateParentRelations(node.parent, node);
		}
	}

	isChildLoaded = (node: ITreeSelectionUINode): boolean => {
		return !(node.children.length === 1 && this.isPlaceholder(node.children[0]));
	}

	isNodeCheckboxDisabled = (node: ITreeSelectionUINode): boolean => {
		return this.nodeCheckboxDisabled && this.nodeCheckboxDisabled(node);
	}

	focusMoveHandler = ($event: IFocusMoveParams) => {
		this.onFocusMove.emit($event);
	}

	private isParentRelationPopulatedForChildren = (node: ITreeSelectionUINode): boolean => {
		if (node.children && node.children[0] && !node.children[0].parent) {
			return false;
		}
		return true;
	}

	private loadChildInternal = (node: ITreeSelectionUINode): void => {
		node.children = [];

		this.promises.childLoading = this.loadChild(node).then((children) => {
			node.children = children;
			this.populateParentRelations(node.parent, node);
		});
	}

	private populateParentRelations = (parentNode: ITreeSelectionUINode, node: ITreeSelectionUINode): void => {
		if (node.path && node.path.constructor === Array) {
			node.path = parentNode !== null
				? (parentNode.path as Array<number>).concat(node.id)
				: [ node.id ];
		}
		node.parent = parentNode;

		if (!node.children) return;
		node.children.forEach((child) => {
			this.populateParentRelations(node, child);
		});
	}

	private getRoots = (): ITreeSelectionNode[] => {
		let ignoreDisabled = this.ignoreDisabled && !!this.nodeCheckboxDisabled;
		return this.root.children
			.filter((node) => {
				return !ignoreDisabled || !this.isNodeCheckboxDisabled(node);
			})
			.map((node) => {
				return this.convertNodes(node, [ node ])[0];
			});
	}

	private excludeBranch = (currentSelection: ITreeSelectionNode[], node: ITreeSelectionUINode): ITreeSelectionNode[] => {
		let childrenNodes = this.convertNodes(node, this.getNodeChildren(node));
		let result = this.difference(currentSelection, childrenNodes);

		let currentNode = node;
		let currentParent = node.parent;
		while (currentParent) {
			result = this.difference(result, this.convertNodes(node, [ currentNode ]));

			if (this.intersection(currentSelection, this.convertNodes(node, this.getNodePath(currentParent))).length > 0)
				result = result.concat(this.convertNodes(node, this.directChildrenExcluding(currentParent, currentNode.id)));
			else
				break;

			currentNode = currentParent;
			currentParent = currentNode.parent;
		}

		return result;
	}

	private getNodePath = (node: ITreeSelectionUINode): ITreeSelectionUINode[] => {
		let result = [ node ];
		while (!node.root && node.parent) {
			node = node.parent;
			result.splice(0, 0, node);
		}

		return result;
	}

	private directChildrenExcluding = (parent: ITreeSelectionUINode, excludingChildId: number): ITreeSelectionUINode[] => {
		return parent.children
			.filter((child) => child.id !== excludingChildId);
	}

	private includeBranch = (currentSelection: ITreeSelectionNode[], node: ITreeSelectionUINode): ITreeSelectionNode[] => {
		let childrenNodes = this.convertNodes(node, this.getNodeChildren(node));
		return this.difference(currentSelection, childrenNodes)
			.concat(this.convertNodes(node, [ node ])[0]);
	}

	private createPlaceholder = (): ITreeSelectionUINode => {
		return {
			id: this.PLACEHOLDER_NODE_ID,
			placeholder: true,
			name: this.PLACEHOLDER_NODE_NAME
		};
	}

	private getNodeChildren = (node: ITreeSelectionUINode): ITreeSelectionUINode[] => {
		if (this.ignoreDisabled && this.isNodeCheckboxDisabled(node)) {
			return [];
		}
		let result = [ node ];
		if (!node.children) return result;

		node.children.forEach((child) => {
			result = result.concat(this.getNodeChildren(child));
		});
		return result;
	}

	private isPlaceholder = (node: ITreeSelectionUINode): boolean => {
		return !!node.placeholder;
	}

	private returnNoChildren = (): Promise<any[]> => {
		return Promise.resolve([]);
	}

	private returnNodeIds = (node, nodes): number[] => {
		return nodes.map((child) => {
			return child.id;
		});
	}

	private getNoneSelection = (): ITreeSelection => {
		return TreeSelectionBuilder.none();
	}

	// deep equality overrides of underscore functions
	private intersection = (array1: ITreeSelectionNode[], array2: ITreeSelectionNode[]): ITreeSelectionNode[] => {
		return _.filter(_.uniq(array1), (item) => {
			return _.any(array2, (element) => _.isEqual(element, item));
		});
	}

	private difference = (array1: ITreeSelectionNode[], array2: ITreeSelectionNode[]): ITreeSelectionNode[] => {
		return _.filter(array1, (value) => {
			return !_.any(array2, (element) => {
				return _.isEqual(element, value);
			});
		});
	}
}

app.directive('treeSelection', downgradeComponent({component: TreeSelectionComponent}) as angular.IDirectiveFactory);
