export class AlertOptOutEntry {
	enabled?: boolean;
	permanent?: boolean;

	delayPeriodDays: number;

	name: string;
	recipient: string;

	startDate: Date;
	resumeDate: Date;
}