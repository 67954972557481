<div class="d-flex flex-direction-column w-max-50-percent" [ngBusy]="loading">
	<div class="form-group">
		<div class="col-sm-9 pl-0">
			<simple-dropdown
				[(value)]="selectedLocale"
				(valueChange)="resetCurrentDictionary($event)"
				valueField="id"
				displayField="name"
				role="listbox"
				attr.aria-label="{{'administration.dictionaryLocale'|i18n}}"
				[options]="locales"
				[disabled]="isEditing">
			</simple-dropdown>
		</div>
	</div>

	<div class="form-group">
		<textarea
			name="dictionary"
			class="h-min-160 w-100-percent rounded-16"
			[attr.readonly]="isEditing ? null : true"
			[(ngModel)]="currentDictionaryAsString">
		</textarea>
	</div>

	<div class="form-group d-flex justify-end">
		<button
			type="button"
			*ngIf="!isEditing"
			(click)="toggleEditing()"
			class="btn btn-primary">
			{{'common.edit' | i18n}}
		</button>

		<button
			type="button"
			*ngIf="isEditing"
			(click)="saveChanges()"
			class="btn btn-primary">
			{{'common.save' | i18n}}
		</button>

		<button
			type="button"
			*ngIf="isEditing"
			class="btn btn-secondary"
			(click)="toggleEditing()">
			{{'common.cancel' | i18n}}
		</button>
	</div>
</div>
