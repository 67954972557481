<div class="d-flex flex-direction-column p-16" [ngBusy]="loading">
	<div *ngIf="showStats">
		<button type="button"
			class="btn btn-secondary pull-right"
			ngxClipboard
			[cbContent]="getStatsClipboardText()"
			(cbOnSuccess)="showSuccessNotification()"
			(cbOnError)="showStatsExportDialog()"
			title="{{'common.copyToClipboard'|i18n}}">
			<i class="q-icon q-icon-copy"></i>
		</button>
		<button type="button" class="btn btn-secondary" (click)="exit.emit()">
			{{'widget.disableStatsModeShort'|i18n}}
		</button>
	</div>

	<div class="mt-16">

		<div *ngIf="showDescription" class="description-data kb-focusable" tabindex="0">
			<div *ngIf="widget.description">
				<h4 class="font-bold text-base">{{'common.widgetDescription'|i18n}}</h4>
				<p id="widget-description-{{widget.id}}">{{widget.description}}</p>
			</div>
			<div *ngFor="let metric of selectedMetrics">
				<h4 class="font-bold text-base">{{metric.displayName}} <span class="lowercase">{{'preview.description'|i18n}}</span></h4>
				<p class="white-space-pre-wrap">{{metric.description}}</p>
			</div>
			<h4 class="font-bold text-base">{{'dashboard.dashboardOwner'|i18n}}</h4>
			<p>{{getDashboardOwner()}}</p>
		</div>

		<current-sentences-table *ngIf="displayCurrentSentencesTableData()"
				[showDescription]="showDescription"
				[widget]="widget"
				[data]="currentSentencesTableData"
		></current-sentences-table>

		<h4 *ngIf="displayTableData()" class="font-bold text-base">{{getTableHeader()}}</h4>

		<stats-table *ngIf="showStats" [tableData]="statsTableConfig" tableClass="stats-block-info"></stats-table>

		<simple-table *ngIf="displayTableData()"
			[tableData]="widgetTableData"
			tableClass="mt-16 stats-table-data table table-bordered"
			[descriptionId]="showDescription && widget.description ? 'widget-description-' + widget.id : undefined">
		</simple-table>
	</div>
</div>
