<div class="page-tab-content">
	<div class="br-list-box sub-tab-content">
		<ul ngbNav #nav="ngbNav" class="nav-tabs">
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.jmsStatus'|i18n}}</a>
				<ng-template ngbNavContent>
					<jms-listener-status-tab></jms-listener-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.kafka'|i18n}}</a>
				<ng-template ngbNavContent>
					<kafka-status-tab></kafka-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.schedulerStatus'|i18n}}</a>
				<ng-template ngbNavContent>
					<schedules-status-tab></schedules-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.emailMessagesTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<emails-tab
						[masterAccounts]="masterAccounts"
					></emails-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'administration.socialCasesMessagesTab'|i18n}}</a>
				<ng-template ngbNavContent>
					<social-cases-log-tab></social-cases-log-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.engage'|i18n}}</a>
				<ng-template ngbNavContent>
					<engage-status-tab></engage-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.connectorsIntegration'|i18n}}</a>
				<ng-template ngbNavContent>
					<connectors-status-tab></connectors-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.predictiveIntegration'|i18n}}</a>
				<ng-template ngbNavContent>
					<predictive-status-tab></predictive-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.voice'|i18n}}</a>
				<ng-template ngbNavContent>
					<voice-status-tab></voice-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.unitsIntegration'|i18n}}</a>
				<ng-template ngbNavContent>
					<workspace-status-tab></workspace-status-tab>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>{{'mAccount.qualtricsIntegration'|i18n}}</a>
				<ng-template ngbNavContent>
					<qualtrics-status-tab></qualtrics-status-tab>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</div>
</div>
