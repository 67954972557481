import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { NumericAttributeGroupingSettingsEditor } from '../numeric-attribute-grouping-settings-editor';

@Injectable()
export class NumericAttributeGroupingSettingsEditorProvider {

	constructor(
		private readonly sortByOptionsService: SortByOptionsService,
		@Inject('optionsBuilderProvider') private readonly optionsBuilderProvider: OptionsBuilderProvider,
	) {}

	getInstance(): NumericAttributeGroupingSettingsEditor {
		return new NumericAttributeGroupingSettingsEditor(this.optionsBuilderProvider,  this.sortByOptionsService);
	}
}

app.service('numericAttributeGroupingSettingsEditorProvider', downgradeInjectable(NumericAttributeGroupingSettingsEditorProvider));
