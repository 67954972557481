import { MasterAccountConnectorsStatus } from './master-account-connectors-status';
import { CxHttpService } from '@app/core';
import { Injectable } from '@angular/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';

@Injectable({
	providedIn: 'root'
})
export class ConnectorsStatusApiService {
	constructor(private cxHttp: CxHttpService) {}

	getConnectorsStatuses = (): Promise<MasterAccountConnectorsStatus[]> => {
		let url = 'rest/system-admin/status/connectors';
		return this.cxHttp.get(url);
	}

	export = (search: IntegrationSatusSearch): Promise<void> => {
		let url = 'rest/system-admin/status/connectors/export';
		return this.cxHttp.post(url, search);
	}
}
