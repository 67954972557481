
export enum MasterAccountProperty {
	DASHBOARD_DISCUSSIONS = 'dashboardDiscussions',
	QUALTRICS_SITE_INTERCEPT = 'siteIntercept',
	DASHBOARD_TEMPLATES = 'dashboardTemplates',
	DASHBOARD_RATING = 'dashboardRating',
	IDEAS_FORUM = 'ideasForum',
	DEFAULT_DASHBOARD_OPTIMIZATION = 'defaultDashboardOptimization',
	QUICK_TRANSLATE = 'quickTranslate',
	ACCOUNT_OWNER_MANAGE_OBJECTS = 'accountOwnerManageObjects',
	EXPORT_USERS_AND_GROUPS = 'exportUsersAndGroups'
}
