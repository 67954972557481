import {MasterAccountPermission} from '@app/modules/user-administration/permissions/master-account-permission.class';

export default class PermissionGroupData {
	name: string;
	permissions: MasterAccountPermission[];
	collapsed: boolean;
	toggle = (collapsed: boolean): void => {
		this.collapsed = collapsed;
	}

	constructor(name: string, permissions: MasterAccountPermission[], collapsed: boolean) {
		this.name = name;
		this.permissions = permissions;
		this.collapsed = collapsed;
	}
}