
import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { MetricType } from '@app/modules/metric/entities/metric-type';

export class MetricCalculationsTypeClass {

	static defaultThresholds = [0.66];
	static defaultNPSThresholds = [-0.33, 0.33];

	defaultTitle: string;
	displayName: string;
	is: (m: any) => boolean;
	calculationMin: number;
	returnObject: (...args) => any;
	getData: (...args) => any;
	getCalculation: (...args) => any;
	getExpressionArray: (...args) => any;
	disabled: boolean;


	static validateCalculation = (calculation) => {
		if (!calculation) {
			return false;
		}

		if (!calculation.colorPalette || !calculation.thresholds) {
			return false;
		}

		if (calculation.colorPalette.length !== calculation.thresholds.length + 1) {
			return false;
		}

		return true;
	}

	static filterObjectToDefinition = (options) => {
		let filter = cloneDeep(options.filterObject);
		filter.filterRules = filter.filterRules.filter(f => f.type !== FilterTypes.EMPTY);

		return {
			rule: filter,
			type: MetricType.FILTER
		};
	}

	static isType = (apiTypes) => {
		return (metric) => {
			if (metric?.definition?.type) {
				return apiTypes.contains(metric.definition.type);
			}

			return false;
		};
	}

	static isNumericType = (name) => {
		return (metric) => {
			if (metric?.definition?.type) {
				return MetricType.NUMERIC_BREAKDOWN === metric.definition.type
					&& metric.definition.name === name;
			}
			return false;
		};
	}


	constructor(initObject: Partial<MetricCalculationsTypeClass>) {
		this.defaultTitle = initObject.defaultTitle;
		this.displayName = initObject.displayName;
		this.is = initObject.is || MetricCalculationsTypeClass.isType([]);
		this.calculationMin = initObject.calculationMin || 0;
		this.returnObject = initObject.returnObject || _.noop;
		this.getData = initObject.getData || _.noop;
		this.getCalculation = initObject.getCalculation || _.noop;
		this.getExpressionArray = initObject.getExpressionArray || _.noop;
		this.disabled = initObject.disabled || false;
	}
}
