import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { Unit } from '@app/modules/units/unit';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';


@Injectable({
	providedIn: 'root'
})
export class UserWorkspacesApi {

	constructor(
		private readonly cxHttp: CxHttpService
	) {}

	getUserWorkspaces(): Promise<Unit[]> {
		return this.cxHttp.get(`rest/user/workspaces`, {local: true});
	}

	getWorkspaceProjects(workspaceId: number): Promise<Project[]> {
		return this.cxHttp.get(`rest/user/workspaces/${workspaceId}/projects`, {local: true});
	}
}
