import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'custom-save-dialog',
	template: `
<modal-header
	(cancel)="cancel()"
	[modalTitle]="header">
</modal-header>
<div>
	<ng-container *ngTemplateOutlet="template;context:{$implicit:this}"></ng-container>
</div>
<save-modal-footer
	[saveText]="saveBtnName"
	(save)="save()"
	[cancelText]="cancelBtnName"
	(cancel)="cancel()"
	[isDisabled]="!valid"
></save-modal-footer>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSaveDialogComponent {

	@Input() header: string;
	@Input() template: TemplateRef<unknown>;
	@Input() saveBtnName: string;
	@Input() cancelBtnName: string;

	valid = true;
	entity: unknown;

	constructor(
		private readonly modal: NgbActiveModal
	) {}

	save(): void {
		this.modal.close(this.entity);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	setValid(valid: boolean): void {
		this.valid = valid;
	}

	setEntity(entity: unknown): void {
		this.entity = entity;
	}

}
