<div class="hierarchy-select-wrapper">
	<label class="mandatory" *ngIf="label">{{label}}</label>
	<div ngbDropdown
		class="dropdown-input">
		<a ngbDropdownToggle role="button"
			aria-expanded="false" aria-haspopup="true"
			[attr.disabled]="disabled ? 'disabled' : null"
			[title]="displayName"
			class="button-text pr-32">
			<span [innerHTML]=displayName></span> <span class="caret"></span>
		</a>
		
		<div ngbDropdownMenu
			class="dropdown-menu dropdown-menu-hierarchy" 
			role="menu"
			(click)="$event.stopPropagation()"
			(keyup.escape)="closePopup();">

			<searchable-tree
				*ngIf="isOpen()"
				[autoFocus]="opened"
				[placeholder]="searchPlaceholder"
				[displayProperty]="displayProperty"
				[hierarchyList]="hierarchyList"
				[folderClickIgnore]="folderClickIgnore"
				(onNodeClick)="updateValue($event.node)"
				[selectedItem]="getSelectedItem"
				[nodeIsChecked]="nodeIsChecked"
				[nodeIsMarked]="nodeIsMarked"
				[showNodeCheckbox]="showNodeCheckbox"
				[nodeCheckboxDisabled]="nodeCheckboxDisabled">
			</searchable-tree>
		</div>
	</div>
</div>
