import { Injectable } from '@angular/core';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { OrganizationEnrichmentApiService } from '@app/modules/hierarchy/enrichment/organization-enrichment-api.service';


@Injectable({
	providedIn: 'root'
})
export class OrganizationEnrichmentService {
	constructor(
		private readonly organizationApiService: OrganizationApiService,
		private readonly organizationEnrichmentApiService: OrganizationEnrichmentApiService,
	) {}

	getReportableHierarchies(project: AccountOrWorkspaceProject): AssetPromise<ReportableHierarchy[]> {
		return ProjectAssetsHelper.getAssetsForProject<ReportableHierarchy[]>(
			project,
			(accountProject) => this.organizationApiService.getReportableHierarchies(accountProject),
			(workspaceProject) => this.organizationEnrichmentApiService.getReportableHierarchies(workspaceProject),
			(projectId) => []
		);
	}
}
