<div ngbDropdown [hidden]="!isFullscreen()" class="auto-refresh-box">
	<button ngbDropdownToggle class="btn btn-secondary w-100-percent justify-center d-flex">
		<div [hidden]="commandCenterApplicationOutdated">
			<span class="q-icon q-icon-refresh mr-8" (click)="refreshDashboard(); $event.stopPropagation()"></span>
			<span [hidden]="!refreshState.refreshingDashboard" href="javascript:void(0)" [i18n]="'dashboard.autorefreshInProgress'"></span>
			<span [hidden]="refreshState.refreshingDashboard" href="javascript:void(0)">
				<span>{{'dashboard.autorefreshDescription' | i18n:{autoRefreshTime: refreshState.autoRefreshTime} }}</span>
				&nbsp; &nbsp; <i class="q-icon q-icon-angle-bottom" aria-hidden="true"></i>
			</span>
			<div class="time-bar-box" [hidden]="refreshState.refreshingDashboard">
				<div class="time-bar" [style.width]="(100 * refreshState.lastRefreshTime / refreshState.autoRefreshTime) + '%'"></div>
			</div>
		</div>

		<div [hidden]="!commandCenterApplicationOutdated" class="command-center-warning" [i18n]="'dashboard.applicationRefreshIsRequired'"></div>
	</button>
	<ul ngbDropdownMenu class="text-center w-100-percent">
		<li *ngFor="let time of autoRefreshTimes"
			[class.active]="refreshState.autoRefreshTime === time"
			(click)="selectAutoRefreshTime(time)">
			<a href="javascript:void(0)">
				<span>{{'dashboard.autorefreshOption' | i18n:{time: time} }}</span>
			</a>
		</li>
	</ul>
</div>
