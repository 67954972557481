import { ChangeDetectionStrategy, Component, Inject, ChangeDetectorRef } from '@angular/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { HierarchyUploadWizardPageBaseComponent } from '../hierarchy-upload-wizard-base.component';
import { CxLocaleService } from '@app/core';


@Component({
	selector: 'hierarchy-structure-wizard-page',
	templateUrl: './hierarchy-structure-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyStructureWizardPageComponent extends HierarchyUploadWizardPageBaseComponent {
	
	constructor(
		ref: ChangeDetectorRef,
		protected locale: CxLocaleService,
		private organizationApiService: OrganizationApiService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('urlService') private urlService: UrlService,
	) {
		super(ref, locale);
	}

	getFileName(): string {
		return this.hierarchy.structureFileName;
	}

	getUpdateFileName(): string {
		return this.hierarchy.updateStructureFileName;
	}

	downloadDataFile(): void {
		this.loading = this.organizationApiService.getOrganizationStructure(this.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	downloadTemplateFile(): void {
		this.loading = this.organizationApiService.getOrganizationStructureTemplate()
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			}) as any;
	}

	getFileUploadUrl(): string {
		return this.urlService.getAPIUrl(
			`rest/organization/structure/file/upload/${this.hierarchy.id}`);
	}

}


