<div [ngBusy]="[projectsLoading, hierarchiesLoading]" class="quick-insights-section form-horizontal">
	<label class="font-bold" [i18n]="'homePage.reportingDefaults'"></label>
	<label class="d-block" [i18n]="'homePage.reportingDefaultsDescription'"></label>
	<form #propertiesForm="ngForm">
		<ng-container *ngIf="!isWorkspaceEnabled">
			<project-selector
				[projectSelection]="properties"
				(projectSelectionChange)="onProjectSelectionChange($event)"
				(errorsChange)="onCpErrorsChange($event)"
				(loading)="onProjectsLoading($event)"
			>
			</project-selector>
			<alert type="danger" *ngFor="let cperror of contentProviderErrors">
				<span>{{ cperror }}</span>
			</alert>
		</ng-container>
		<workspace-project-selector
			*ngIf="isWorkspaceEnabled"
			[(value)]="reportProperties.workspaceProject"
			(valueChange)="onProjectChange()"
			labelPosition="left"
			(loading)="onProjectsLoading($event)"
		></workspace-project-selector>

		<div class="form-group mb-0" [ngClass]="{'has-error br-form-error': owner.errors?.required}">
			<label for="ownerSelector" class="control-label w-25-percent">{{'common.owner'|i18n}}</label>
			<div class="w-75-percent ph-16">
				<input
					id="ownerSelector"
					name="owner"
					#owner="ngModel"
					type="text"
					class="form-control mobile-owner-input"
					[ngClass]="{'shorter-from-right': searchingOwnerCandidates}"
					required
					[ngModel]="reportProperties.owner"
					(ngModelChange)="changeOwner($event)"
					[ngbTypeahead]="ownerCandidates"
					[editable]="false"
					(blur)="clearText()">
				<span *ngIf="searchingOwnerCandidates" class="ml-16 loading-spinner q-icon-spinner rotate-infinite"></span>
			</div>
		</div>
		<div class="form-group">
			<div class="col-sm-offset-3 col-sm-9">
				<span
					class="help-block text-danger mb-0"
					*ngIf="owner.errors?.required"
					[i18n]="'homePage.quickInsightsOwnerRequired'">
				</span>
			</div>
		</div>

		<div *ngIf="hierarchies && hierarchies.length">
			<div class="form-group">
				<label for="hierarchySelector" class="control-label w-25-percent">{{'dashboard.personalization'|i18n}}
					<cb-inline-help right>
						<help-body>{{'homePage.personalizationHelp'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div class="pl-16 d-flex align-center">
					<input name="hierarchy-toggle"
						class="mr-8"
						type="checkbox"
						[(ngModel)]="hierarchyEnabled"
						(ngModelChange)="onHierarchyEnabled()">
					<simple-dropdown
						*ngIf="hierarchyEnabled"
						class="form-control border-0 p-0"
						id="hierarchySelector"
						[options]="hierarchies"
						[(value)]="reportProperties.hierarchyId"
						[disabled]="!isProjectSelected()"
						[searchable]="true"
						valueField="id"
						sortField="id"
						(onChange)="selectHierarchy($event)">
					</simple-dropdown>
				</div>
			</div>
			<div class="form-group" *ngIf="hierarchyEnabled">
				<label for="hierarchy-context" class="w-25-percent control-label help-label">{{'dashboard.orgContext'|i18n}}
					<cb-inline-help right>
						<help-body>{{'dashboard.orgContextHelpBody'|i18n}}</help-body>
					</cb-inline-help>
				</label>
				<div class="pl-16 d-flex align-items-center">
					<input id="hierarchy-context" type="checkbox"
						name="hierarchy-context" class="m-0"
						[ngModel]="reportProperties.showOrganizationContext"
						(ngModelChange)="setOrganizationContext($event)">
				</div>
			</div>
		</div>

		<alert type="danger" *ngIf="projectChanged" class="mt-16">
			<div [i18n]="'mobile.warningChangedProject'"></div>
		</alert>
	</form>
</div>
