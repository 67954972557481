import * as $ from 'jquery';

export default class PopoverUtils {

	constructor(
		private $rootScope: ng.IScope
	) {}

	initOutsideClickHandler = (selector: string, handlerNameProvider: () => string, clickHandler: () => void) => {
		let ignoreInitial: boolean = true;
		$(document).on(handlerNameProvider(), (event) => {
			if (ignoreInitial) {
				ignoreInitial = false;
				return;
			}
			let target = $(event.target);

			// to ignore click on blank space inside panel
			let blankSpaceClick: boolean = false;
			if (selector.charAt(0) === '#') {
				blankSpaceClick = (target.attr('id') === selector.substr(1));
			}
			if (selector.charAt(0) === '.') {
				blankSpaceClick = target.hasClass(selector.substr(1));
			}

			if (!target.parents().last().is(document.documentElement) // if not attached to dom, ignore it
					|| target.parents(selector).length > 0 // ignore any elements inside panel
					|| blankSpaceClick)
				return;
			$(document).off(handlerNameProvider());
			this.$rootScope.$apply(clickHandler);
		});
	}
}

app.service('popoverUtils', PopoverUtils);
