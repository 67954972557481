import { ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, SimpleChanges, OnChanges, Component, OnInit, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

export interface InclusionListItem {
	name: string;
	displayName: string;
}

@Component({
	selector: 'inclusion-list',
	templateUrl: './inclusion-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InclusionListComponent implements OnInit, OnChanges {

	@Input() selectGroups: string[];
	@Input() allItems: any[];
	@Input() selectedItems: any[];

	@Input() isOrderingEnabled: boolean;
	@Input() itemsLimit: number;
	@Input() options: any[];
	@Input() pinnedSelection: InclusionListItem[];
	@Input() disabled: boolean;

	@Input() optionClassFormatter: (item: any) => string;
	@Input() optionTitleFormatter: (item: any) => string;

	@Input() disableSelection:
		(items: any[], highlightedAvailableItems: any[], highlightedSelectedItems: any[], itemsLimit: number) => boolean;
	@Input() availableItemsLabelCallback:
		(items: any[], highlightedAvailableItems: any[], highlightedSelectedItems: any[], itemsLimit: number) => string;
	@Input() selectedItemsLabelCallback:
		(items: any[], highlightedAvailableItems: any[], highlightedSelectedItems: any[], selectedItems: any[]) => string;
	@Input() availableItemsTooltipCallback: () => string;

	@Output() selectedItemsHighlightingChange = new EventEmitter<any[]>();
	@Output() moveItems = new EventEmitter<any[]>();
	@Output() selectedItemsChange = new EventEmitter<any[]>();

	uiOptions: any;
	highlightedAvailableItems: any[];
	highlightedSelectedItems: any[];
	availableTextFilter: string;
	selectedTextFilter: string;

	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		if (!this.allItems) {
			this.allItems = [];
		}
		if (!this.selectedItems) {
			this.selectedItems = [];
		}

		this.availableTextFilter = '';
		this.selectedTextFilter = '';

		this.highlightedAvailableItems = [];
		this.highlightedSelectedItems = [];

		let defaultOptions = {
			reordering: false,
			searchable: true,
			searchAvailable: this.locale.getString('common.find'),
			searchSelected: this.locale.getString('common.find')
		};
		this.uiOptions = _.extend(defaultOptions, this.options);

		this.refreshSelection(this.selectedItems);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedItems && changes.selectedItems.firstChange === false) {
			this.refreshSelection(this.selectedItems);
		}
	}

	private refreshSelection = (selectedItems: any[]) => {
		_.each(this.allItems, (item) => {
			item.selected = !!_.find(selectedItems, (selectedItem) => {
				return selectedItem.id === item.id && selectedItem.tag === item.tag;
			});
		});
		if (this.isOrderingEnabled) {
			this.selectedItems = [...selectedItems];
		} else {
			this.selectedItems = this.getSelectedItems();
		}
		this.ref.detectChanges();
	}

	getAvailableItemsLabel = (): string => {
		return this.availableItemsLabelCallback(this.allItems, this.highlightedAvailableItems, this.highlightedSelectedItems, this.itemsLimit);
	}

	getSelectedItemsLabel = (): string => {
		return this.selectedItemsLabelCallback(
			this.allItems, this.highlightedAvailableItems, this.highlightedSelectedItems, this.selectedItems);
	}

	getOptionClass = (item: any): string => {
		return this.optionClassFormatter ? this.optionClassFormatter(item) : '';
	}

	getOptionTitle = (item: any): string => {
		return this.optionTitleFormatter ? this.optionTitleFormatter(item) : '';
	}

	isSelectionDisabled = (): boolean => {
		return !this.highlightedAvailableItems.length
			|| (this.disableSelection
				&& this.disableSelection(this.allItems, this.highlightedAvailableItems, this.highlightedSelectedItems, this.itemsLimit));
	}

	isDeselectionDisabled = (): boolean => {
		return !this.highlightedSelectedItems.length;
	}

	getNotSelectedItems = (): any[] => {
		return this.allItems ? this.allItems.filter(this.notSelectedItemsFilter) : this.allItems;
	}

	private notSelectedItemsFilter = (item: any): boolean => {
		if (item.hide) return false;
		return !item.selected || item.disabled;
	}

	private getSelectedItems = (): any[] => {
		return this.allItems ? this.allItems.filter(this.selectedItemsFilter) : this.allItems;
	}

	private selectedItemsFilter = (item: any): boolean => {
		return item.selected && !item.disabled;
	}

	private getItemsByValues = (itemsValues: any[]): any[] => {
		return _.chain(itemsValues)
			.map(value => _.findWhere(this.allItems, {name: value}))
			.filter(item => !!item)
			.value();
	}

	selectItems = (): void => {
		if (this.disableSelection
				&& this.disableSelection(this.allItems, this.highlightedAvailableItems, this.highlightedSelectedItems, this.itemsLimit)) {
			return;
		}

		let items = this.getItemsByValues(this.highlightedAvailableItems);

		items.forEach(item => item.selected = true);

		this.moveItems.emit(items);

		if (this.isOrderingEnabled) {
			this.selectedItems.pushAll(items);
		} else {
			this.selectedItems = this.getSelectedItems();
		}

		this.selectedItemsChange.emit(this.selectedItems);

		this.highlightedAvailableItems.removeAll();
	}

	deselectItems = (): void => {
		let items = this.getItemsByValues(this.highlightedSelectedItems);

		items.forEach(item => delete item.selected);

		this.moveItems.emit(items);

		if (this.isOrderingEnabled) {
			this.selectedItems = this.selectedItems.filter( (item) => {
				return this.highlightedSelectedItems.indexOf(item.name) < 0;
			});
		} else {
			this.selectedItems = this.getSelectedItems();
		}

		this.selectedItemsChange.emit(this.selectedItems);

		this.highlightedSelectedItems.removeAll();
	}

	moveHighlightedSelectedItemsUp = (): void => {
		this.moveHighlightedSelectedItems(true);
	}

	moveHighlightedSelectedItemsDown = (): void => {
		this.moveHighlightedSelectedItems(false);
	}

	canMoveHighlightedSelectedItemsUp = (): boolean => {
		if (!this.highlightedSelectedItems || !this.highlightedSelectedItems.length) {
			return false;
		}
		let itemIndices = this.getIndicesInIncludedItems();
		let minIndex = itemIndices.reduce((acc, itemIndex) => {
			return (itemIndex < acc) ? itemIndex : acc;
		}, this.selectedItems.length);
		return (minIndex > 0);
	}

	canMoveHighlightedSelectedItemsDown = (): boolean => {
		if (!this.highlightedSelectedItems || !this.highlightedSelectedItems.length) {
			return false;
		}
		let itemIndices = this.getIndicesInIncludedItems();
		let maxIndex = itemIndices.reduce((acc, itemIndex) => {
			return (itemIndex > acc) ? itemIndex : acc;
		}, -1);
		return maxIndex < (this.selectedItems.length - 1);
	}

	private moveHighlightedSelectedItems = (isUp: boolean): void => {
		if (!this.isOrderingEnabled) {
			return;
		}

		let itemIndices = this.getIndicesInIncludedItems();

		if (!isUp) {
			itemIndices.reverse();
		}

		itemIndices.forEach((index) => {
			let switchIndex = isUp ? index - 1 : index + 1;
			let temp = this.selectedItems[index];
			this.selectedItems[index] = this.selectedItems[switchIndex];
			this.selectedItems[switchIndex] = temp;
		});

		this.selectedItemsChange.emit(this.selectedItems);
		this.refreshSelection(this.selectedItems);
	}

	private getIndicesInIncludedItems = (): any[] => {
		return _.map(this.highlightedSelectedItems, (value) => {
			return _.findIndex(this.selectedItems, {name: value});
		});
	}

	onSelectedItemsHighlightingChange = (): void => {
		this.selectedItemsHighlightingChange.emit(this.getItemsByValues(this.highlightedSelectedItems));
	}
}

app.directive('inclusionList',
	downgradeComponent({component: InclusionListComponent}) as angular.IDirectiveFactory);
