export enum DocumentPreviewSelector {
	WIDGET_DOCUMENT_PREVIEW_SELECTOR = 'widget-document-preview',

	SENTENCES_PANE_SELECTOR = 'sentences-pane',
	SENTENCES_CONTAINER_SELECTOR = '.dex-list-group',
	PAGINATION_DIV_SELECTOR = '.explorer-tools',
	PAGINATION_PAGE_SELECTOR = '.pagination-page',
	SELECTED_SENTENCE_SELECTOR = '.selected',

	PRIMARY_PANE_SELECTOR = 'primary-pane',

	CONTEXT_PANE_SELECTOR = 'tabbed-context-pane',
	PANEL_BODY_SELECTOR = '.panel-body',
	PANEL_HEADING_SELECTOR = '.panel-heading'
}
