import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Component({
	selector: 'app-colors-preview',
	templateUrl: './app-colors-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppColorsPreviewComponent {
	@Input('brandingColors') brandingColors$: Observable<{[key: string]: string}>;
}
