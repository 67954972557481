import * as _ from 'underscore';

enum Stage {
	INIT = 0, // once widget appeared in dashboard
	PREPARE = 1, // between first request and data request
	PENDING = 2, // waiting in report queue (contentProviderLimiter)
	DATA = 3,
	RENDER = 4, // between data response and finish
	DONE = 5
}

export interface ReportTimingMetadata { // data from the request
	analyticsExecutionTime: number; // pure AN

	platformPreprocessingTime: number; // platform side properties population, including maxDocDate
	platformPostprocessingTime: number; // data parsing/post processing in platform
	platformExecutionTime: number; // request to AN
	platformTotalTime: number;

	studioPreprocessingTime: number; // widget processors
	studioPostprocessingTime: number;
	studioExecutionTime: number; // request to CP
	// populated on UI
	studioTotalTime: number; // populated from headers
	frontendExecutionTime: number; // request to studio
	
}

export enum TimingType {
	initialization = 'initialization',
	widgetPreparation = 'widgetPreparation',
	waitingInQueue = 'waitingInQueue',
	studioDataPreprocessing = 'studioDataPreprocessing',
	platformDataPreprocessing = 'platformDataPreprocessing',
	analyticsExecution = 'analyticsExecution',
	platformDataPostprocessing = 'platformDataPostprocessing',
	studioDataPostprocessing = 'studioDataPostprocessing',
	widgetRendering = 'widgetRendering',

	// everything before pre- or after post-processing, e.g. permission checks, serialize
	// potentially redundant
	studioOther = 'studioOther', 
	platformOther = 'platformOther',

	// network transfers, assuming request and response take half of total time
	studioNetworkTransferRequest = 'studioNetworkTransferRequest', // ui - studio
	studioNetworkTransferResponse = 'studioNetworkTransferResponse', // ui - studio
	platformNetworkTransferRequest = 'platformNetworkTransferRequest', // studio - platform
	platformNetworkTransferResponse = 'platformNetworkTransferResponse', // studio - platform
	analyticsNetworkTransferRequest = 'analyticsNetworkTransferRequest', // platform - analytics
	analyticsNetworkTransferResponse = 'analyticsNetworkTransferResponse' // platform - analytics
}

export interface TimingItem {
	type: TimingType;
	time: number;
}

export class WidgetTiming {
	stage: Stage;
	timings: TimingItem[];
	lastUpdate: number;

	constructor() {
		this.init();
	}

	private setStage(stage: Stage): void {
		if (this.stage >= stage) { // need to reset timings if request is started again
			this.init();
		}
		this.stage = stage;
	}

	init(): void {
		this.stage = Stage.INIT;
		this.timings = [];
		this.lastUpdate = new Date().getTime();
	}

	addTiming(type: TimingType, time: number): void {
		this.timings.push({type, time});
	}

	private getAndUpdateTime(): number {
		let now = new Date().getTime();
		let timePassed = now - this.lastUpdate;
		this.lastUpdate = now;
		return timePassed;
	}

	startPreparation(): void {
		this.setStage(Stage.PREPARE);
		this.addTiming(TimingType.initialization, this.getAndUpdateTime());
	}

	queuedDataLoading(): void {
		this.setStage(Stage.PENDING);
		this.addTiming(TimingType.widgetPreparation, this.getAndUpdateTime());
	}

	startDataLoading(): void {
		this.setStage(Stage.DATA);
		this.addTiming(TimingType.waitingInQueue, this.getAndUpdateTime());
	}

	finishDataLoading(metadata: ReportTimingMetadata): void {
		this.setStage(Stage.RENDER);
		if (!metadata)
			return;
		this.addTiming(TimingType.studioDataPreprocessing, metadata.studioPreprocessingTime);
		this.addTiming(TimingType.platformDataPreprocessing, metadata.platformPreprocessingTime);
		this.addTiming(TimingType.analyticsExecution, metadata.analyticsExecutionTime);
		this.addTiming(TimingType.platformDataPostprocessing, metadata.platformPostprocessingTime);
		this.addTiming(TimingType.studioDataPostprocessing, metadata.studioPostprocessingTime);

		let studioNetworkTransfer = metadata.frontendExecutionTime - metadata.studioTotalTime;
		let platformNetworkTransfer = metadata.studioExecutionTime - metadata.platformTotalTime;
		let analyticsNetworkTransfer = metadata.platformExecutionTime - metadata.analyticsExecutionTime;
		this.addTiming(TimingType.studioNetworkTransferRequest, studioNetworkTransfer / 2);
		this.addTiming(TimingType.studioNetworkTransferResponse, studioNetworkTransfer / 2);
		this.addTiming(TimingType.platformNetworkTransferRequest, platformNetworkTransfer / 2);
		this.addTiming(TimingType.platformNetworkTransferResponse, platformNetworkTransfer / 2);
		this.addTiming(TimingType.analyticsNetworkTransferRequest, analyticsNetworkTransfer / 2);
		this.addTiming(TimingType.analyticsNetworkTransferResponse, analyticsNetworkTransfer / 2);

		this.addTiming(TimingType.studioOther, metadata.studioTotalTime - 
			(metadata.studioPreprocessingTime + metadata.studioExecutionTime + metadata.studioPostprocessingTime));
		this.addTiming(TimingType.platformOther, metadata.platformTotalTime - 
			(metadata.platformPreprocessingTime + metadata.platformExecutionTime + metadata.platformPostprocessingTime));
		this.getAndUpdateTime();
	}

	finishRendering(): void {
		this.setStage(Stage.DONE);
		this.addTiming(TimingType.widgetRendering, this.getAndUpdateTime());
	}

	getTotal(): number {
		return _.reduce(this.timings, (sum, timing) => sum + timing.time, 0);
	}
}
