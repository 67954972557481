
export enum FilterQueryField {
	EVERYTHING = 'everything',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	USER_EMAIL = 'userEmail',
	LOGIN_DATE = 'loginDate',
	LICENSE = 'licenseTypeName',
	CUSTOM = 'customField',
	STATUS = 'status',
	TYPE = 'type',
	TAGS = 'tags',
	DEFAULT_MA = 'defaultMA',
	XM_ACCOUNT_ID = 'xmAccountId',
	XM_GLOBAL_USER_ID = 'xmGlobalUserId',
	LDAP_USER_ID = 'ldapUid'
}


