import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterMultiValue } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-multi-value';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';

@Injectable({
	providedIn: 'root'
})
export class QuickInsightsSearchService {

	constructor(
		private locale: CxLocaleService,
		private userHomePageService: UserHomePageService,
		@Inject('ignoredDashboardFilterService') private ignoredDashboardFilterService: IgnoredDashboardFilterService
	) {}

	private readonly TEXT_SEARCH = 'textSearch';

	isAttributeSearch = (value: AttributeValueOption): boolean => {
		return value.name !== this.TEXT_SEARCH;
	}

	getTextSearchOption = (text: string): AttributeValueOption => {
		return {
			attributeDisplayName: this.locale.getString('homePage.quickInsightsTextSearch'),
			displayName: text || '',
			special: true,
			name: this.TEXT_SEARCH,
		};
	}

	applyFilter = (homePageId: number, dashboardHistory: IDashboardHistoryInstance,
			widgets: Widget[], value: AttributeValueOption): Promise<void> => {
		if (!value) return Promise.resolve();
		// 2nd widget uses filtered metric and should ignore dashboard filter
		let filteredMetricWidget = widgets[1];
		let dashboardFilters = dashboardHistory.getAppliedFilters();
		const attributeFilterIndex = 2;
		if (this.isAttributeSearch(value)) {
			return this.userHomePageService.getSearchAttributes(homePageId).then(searchAttributes => {
				let attribute = searchAttributes.find(item => item.name === value.attributeName);
				let isLc = value.attributeName === '_lc';
				let filterRule: IFilterRule = {
					type: isLc ? FilterRuleType.stringEqualityLC : FilterRuleType.stringEquality,
					attributeName: value.attributeName,
					attributeDisplayName: value.attributeDisplayName,
					matchMode: FilterMatchModeValue.IS,
					values: [{ text: value.displayName }]
				};
				let filterAttribute: DashboardFilterSelection = {
					...attribute,
					filterType: DashboardFilterTypes.ATTRIBUTE
				} as unknown as DashboardFilterSelection;
				let filter: DashboardFilter = {
					selectedAttribute: filterAttribute,
					multiValues: [value as DashboardFilterMultiValue],
					genericFilterFormat: filterRule
				};
				dashboardFilters[attributeFilterIndex] = filter;

				let ignoreTags = this.ignoredDashboardFilterService.getDashboardFilterAttributeTags([attribute]);
				filteredMetricWidget.properties.ignoredDashboardFilters = ignoreTags;
				_.each(filteredMetricWidget.properties.selectedMetrics, metric => {
					(metric.definition as any).rule = {
						type : FilterRuleType.and,
						filterRules: [filterRule]
					};
					(metric.definition as any).filterQuery = isLc
						? `${value.attributeName}:[${value.name.replace(' --> ', ', ')}]`
						: `${value.attributeName}:"${value.name}"`;
				});
			});
		} else {
			dashboardHistory.setTextFilter(value.displayName);
			dashboardFilters.splice(attributeFilterIndex, 1);

			delete dashboardFilters[attributeFilterIndex];
			_.each(filteredMetricWidget.properties.selectedMetrics, metric => {
				//text dasbhoard filter ignore is set on backend
				(metric.definition as any).filterQuery = value.displayName;
			});
			return Promise.resolve();
		}
	}
}
