import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import * as cloneDeep from 'lodash.clonedeep';
import { RequestSourceType } from '../../real-data-preview/request-source-type';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportRunPreparationService } from '@app/modules/reports/utils/report-run-preparation.service';
import { ContentProviderFeatureService } from '@app/modules/system-administration/content-provider/content-provider-feature.service';
import { ContentProviderFeature } from '@app/modules/system-administration/content-provider/content-provider-feature';

@Injectable({
	providedIn: 'root'
})
export class ReportRunHelperService {

	constructor(
		private readonly reportRunPreparationService: ReportRunPreparationService,
		private readonly contentProviderFeatureService: ContentProviderFeatureService,
		@Inject('customFilterService') private readonly customFilterService: CustomFilterService,
	) {}

	// these updates will be persisted for whole widget lifetime
	updateInitialWidget(widget: Widget, metrics: Metric[]): void {
		this.reportRunPreparationService.processWidgetCalculationsDefaultProperties(widget, metrics);
	}

	getWidgetCopyForReportRun(widget: Widget): Widget {
		const widgetForReportRun: Widget = cloneDeep(widget);
		widgetForReportRun.responsiveState = widget.responsiveState;

		return widgetForReportRun;
	}

	// these changes should be applied on widget copy used for report run
	updateWidgetForReportRun(widget: Widget, attributes: IReportAttribute[], metrics: Metric[]): Promise<void> {
		return this.contentProviderFeatureService.isFeatureSupported(widget.properties.contentProviderId,
			ContentProviderFeature.WIDGET_PREPROCESSING).then(supported => {
				if (!supported) {
					this.reportRunPreparationService.processGroupThreshold(widget, attributes, metrics);
					this.reportRunPreparationService.processGroupSortBy(widget, attributes, metrics);
				}

			});
	}

	populateGetDataProperties(runWidgetSettings: Widget, lastReportRequestTimestamp: moment.Moment,
		requestType: RequestSourceType): void {
		this.reportRunPreparationService.populateBrowserProperties(runWidgetSettings);
		runWidgetSettings.properties.requestType = requestType;
		runWidgetSettings.properties.timeStampMark = lastReportRequestTimestamp;
	}

	postProcessSettings(
		runWidgetSettings: Widget, dashboardHistoryInstance: IDashboardHistoryInstance, filteringFeedbackSelectionEnabled: boolean
	): Promise<Widget> {
		return PromiseUtils.wrap(this.customFilterService.postprocessWidgetProperties(
				runWidgetSettings, dashboardHistoryInstance, filteringFeedbackSelectionEnabled)
		).then((settings) => {
			this.reportRunPreparationService.addMetricWidgetPopMetrics(settings.properties);
			return settings;
		});
	}
}

app.service('reportRunHelperService', downgradeInjectable(ReportRunHelperService));
