import { Injectable } from '@angular/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxHttpUtils } from '@app/core/http/cx-http-utils.class';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { AssetPromisePlatformProject, AssetPromiseAnyProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ReportFiltersApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.FILTERS);
	}

	getFiltersWithFolders(
		assetParameters: RequestAssetParameters,
		cache = CacheOptions.CACHED,
	): AssetPromiseAnyProject<IFilter[]> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get('rest/report-assets/filters', options) as AssetPromiseAnyProject<IFilter[]>;
	}

	getPlatformFilters(
		assetParameters: RequestAssetParameters,
		cache = CacheOptions.CACHED,
	): AssetPromisePlatformProject<IPlatformFilter[]> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get('rest/report-assets/platform-filters', options) as AssetPromisePlatformProject<IPlatformFilter[]>;
	}

	getStudioFilters(
		assetParameters: RequestAssetParameters,
		cache = CacheOptions.CACHED,
	): AssetPromiseAnyProject<IFilter[]> {
		let options = CxHttpUtils.getAssetRequestOptions(assetParameters, cache);
		return this.cachedHttp.get('rest/report-assets/studio-filters', options) as AssetPromiseAnyProject<IFilter[]>;
	}
}
