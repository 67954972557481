import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Subject } from 'rxjs';

export type ReloadSettingsCallback = (ready: boolean, error?: string) => void;

@Injectable()
export class WidgetEditorService {

	private reloadSettingsSource = new Subject<ReloadSettingsCallback>();
	reloadSettings$ = this.reloadSettingsSource.asObservable();

	private clearSettingsSource = new Subject<void>();
	clearSettings$ = this.reloadSettingsSource.asObservable();

	constructor() {
	}

	reloadSettings(callback: ReloadSettingsCallback): void {
		this.reloadSettingsSource.next(callback);
	}

	clearSettings(): void {
		this.clearSettingsSource.next();
	}
}

app.service('widgetEditorService', downgradeInjectable(WidgetEditorService));