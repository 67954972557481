<div class="dex-model-node">
	<div class="favoritable-item">
		<h4 [title]="getTopicDisplayName(model.modelName)"
			class="d-inline-block ellipsis display-name font-bold text-base mv-0">
			{{getTopicDisplayName(model.modelName)}}
		</h4>
		<favorite-item-icon
			[favoriteId]="favoriteId"
			[favoriteDisplayName]="getTopicDisplayName(model.modelName)"
			[favoriteAttributes]="favoriteAttributes"
			[disableFavorites]="disableFavorites"
			[favoritesPanel]="favoritesPanel"
			(onClick)="toggleFavorite()"></favorite-item-icon>
	</div>

	<collapsible-pills
		*ngIf="width && pills?.length"
		[itemDisplayName]="getTopicDisplayName(model.modelName)"
		[pills]="pills"
		[width]="width"
		[classFunc]="getPillClass"
		(pillMouseover)="chickletHover.emit($event)"
		(pillMouseout)="chickletHover.emit(undefined)"
		(pillClick)="chickletClick.emit($event)"
		[useContrastTextColor]="true"
	></collapsible-pills>
</div>
