import { Pipe, PipeTransform } from '@angular/core';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

@Pipe({
	name: 'escapeHtml'
})
export class EscapeHtmlPipe implements PipeTransform {

	transform(item: string): string {
		return HtmlUtils.escapeHtml(item);
	}
}
