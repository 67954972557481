import { ElementRef, HostListener, Inject, HostBinding, ChangeDetectorRef,
	Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { Color } from '@iplab/ngx-color-picker';


export const MAX_RECENT_COLORS = 10;

@Component({
	selector: 'responsive-color-picker',
	templateUrl: './responsive-color-picker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveColorPickerComponent implements OnInit {

	@Input() set color(value: string) {
		this.valueColor = Color.from(value || '#000000');
	}
	@Input() showClear: boolean;
	@Input() pinnedPalette?: string;
	@Input() clickLocation: {x: number, y: number};
	@Output() colorChange: EventEmitter<string> = new EventEmitter();
	@Output() clear = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();


	verticalLimit: { maxHeight?: string; } = {};
	valueColor: Color;
	showPalettes: boolean = false;

	recentColors: string[];

	loadingPalettes: boolean;
	palettes: WidgetColorPalette[];

	constructor(
		private ref: ChangeDetectorRef,
		private sessionPreferences: SessionPreferencesService,
		@Inject('colorPalettes') private colorPalettes: ColorPalettes,
		private component: ElementRef
	) { }

	@HostBinding('class.horizontal-picker') useHorizontal: boolean = false;
	@HostBinding('class.vertical-picker') useVertical: boolean = true;

	ngOnInit(): void {
		this.recentColors = this.sessionPreferences.get('recentColors') || [];

		if (this.valueColor) {
			this.addRecentColor(this.valueColor.toHexString());
		}

		let palettesPromise = this.colorPalettes.getWidgetPalettes();
		this.loadingPalettes = true;
		palettesPromise.then(palettes => {
			this.palettes = palettes.filter(palette => palette.enabled).sort((a, b) => {
				if (a.name === this.pinnedPalette || b.name === this.pinnedPalette) {
					// pinned is also on the top
					return a.name === this.pinnedPalette ? -1 : 1;
				}
				if (a.defaultPalette || b.defaultPalette) {
					// default goes first after pinned
					return a.defaultPalette ? -1 : 1;
				}
				// then normal sort
				return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
			});
			this.loadingPalettes = false;
			this.chooseOrientation();
			this.ref.markForCheck();
		});
	}

	onChange(): void {
		this.colorChange.emit(this.valueColor.toHexString());
	}

	private addRecentColor(color: string): void {
		let existingIndex = this.recentColors.indexOf(color);
		if (existingIndex > -1) {
			this.recentColors.moveToBeginning(existingIndex);
		} else {
			this.recentColors.unshift(color);
		}

		if (this.recentColors.length > MAX_RECENT_COLORS) {
			this.recentColors.pop();
		}

		this.sessionPreferences.set('recentColors', this.recentColors);
	}

	onColorSelected(color: string): void {
		this.color = color;
		this.addRecentColor(color);
	}

	chooseOrientation(): void {
		let elHeight = $(this.component.nativeElement).innerHeight();
		let pos = this.clickLocation.y;
		const EXTRA_PADDING = 16; // prevent the popup from going to the very bottom edge

		if ((elHeight + pos) > (window.innerHeight - EXTRA_PADDING)) {
			this.useHorizontal = true;
			this.useVertical = false;
		} else {
			this.useHorizontal = false;
			this.useVertical = true;
			this.verticalLimit = { maxHeight: `${window.innerHeight - pos - EXTRA_PADDING}px` };		// prevent from expanding beyond window when brand tab is clicked
		}

		this.ref.detectChanges();
	}

	@HostListener('mousedown', ['$event'])
	onClick(event: MouseEvent) {
		// ignore changing focus (required for text editor) when clicking anywhere except inputs
		if (!$(event.target).is('input')) {
			event.preventDefault();
		}
	}
}

app.directive('responsiveColorPicker', downgradeComponent({component: ResponsiveColorPickerComponent}) as angular.IDirectiveFactory);
