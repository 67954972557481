import * as _ from 'underscore';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export default class DashboardTemplatesGridDefinition {
	constructor(
		protected locale: CxLocaleService,
		@Inject('gridFormatterService') protected gridFormatterService
	) {
	}

	init = (): Promise<IGridColumn[]> => {
		let columns: IGridColumn[] = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<span class="q-icon-layer"></span>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: true,
			minWidth: 280,
			width: 450,
			cssClass: 'cell-title',
			searchable: true,
			defaultSortColumn: SortDirection.ASC,
			formatter: this.gridFormatterService.getNameFormatter()
		}, {
			id: 'description',
			name: this.locale.getString('common.description'),
			field: 'description',
			sortable: true,
			minWidth: 200,
			width: 400,
			searchable: true
		}, {
			id: 'createdDate',
			name: this.locale.getString('dashboard.createdDate'),
			field: 'createdDate',
			sortable: true,
			minWidth: 100,
			width: 180,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'lastModified',
			name: this.locale.getString('templates.lastModified'),
			field: 'modifiedDate',
			sortable: true,
			minWidth: 100,
			width: 180,
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'owner',
			name: this.locale.getString('common.owner'),
			field: 'ownerName',
			sortable: true,
			minWidth: 140,
			width: 240,
			searchable: true,
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'publicStatus',
			name: this.locale.getString('common.status'),
			field: 'publicStatus',
			sortable: true,
			minWidth: 60,
			width: 60,
			formatter: this.gridFormatterService.StatusFormatter,
			cssClass: 'lighten-text cell-status text-center',
			headerCssClass: 'text-center'
		}];
		return Promise.resolve(columns);
	}
}

app.service('dashboardTemplatesGridDefinition', DashboardTemplatesGridDefinition);
