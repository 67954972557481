import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { PlaceholderUsage, PlaceholderUsageType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { AssetSpecificUtilsService } from '@app/modules/unified-templates/common-templates/asset-specific-utils.service';
import { ChangeUtils } from '@app/util/change-utils';

@Component({
	selector: 'template-placeholder-usage',
	template: `
	<div class="text-0_875rem">
		<div class="d-flex align-items-center h-2_5rem justify-between p-16 border-0 border-b-1 border-gray-200 border-solid">
			<span class="font-semibold">{{'templates.metricParameters'|i18n}}</span>
			<button (click)="onDismiss()" class="btn btn-icon p-absolute" [style.right.px]="0">
				<span class="q-icon q-icon-delete"></span>
			</button>
		</div>
		<div *ngIf="usages !== null" class="ph-16 pt-16">
			<ng-container *ngFor="let item of usageGroups | keyvalue">
				<p class="m-0 mb-16 font-semibold">{{ getUsageTitle(item.key) }}</p>
				<ng-container *ngIf="item.value.length > 0">
					<p *ngFor="let usage of item.value; let i = index" class="ml-8 mb-0"> {{i+1}}. {{ getUsageDetails(usage) }}</p>
				</ng-container>
			</ng-container>
		</div>
		<div class="mt-32 mb-16 ph-16">
			<button class="btn btn-secondary w-100-percent" (click)="onDismiss()">{{'common.close'|i18n}}</button>
		</div>
  	</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePlaceholderUsageComponent implements OnInit, OnChanges {

	@Input() usages: PlaceholderUsage[] |  null = null;
	@Output() dismiss = new EventEmitter<void>();

	usageGroups: {[key in PlaceholderUsageType]?: PlaceholderUsage[]};

	constructor(
		private readonly locale: CxLocaleService,
		private readonly assetSpecificUtils: AssetSpecificUtilsService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.setUsageGroups();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.usages)) {
			this.setUsageGroups();
			this.ref.detectChanges();
		}
	}

	setUsageGroups(): void {
		if (this.usages === null) {
			this.usageGroups = null;
			return;
		}
		this.usageGroups = _.groupBy(this.usages, usage => usage.type);
	}

	getUsageTitle(type: PlaceholderUsageType): string {
		return this.locale.getString(`templates.placeholderUsageType_${type}`);
	}

	onDismiss(): void {
		this.dismiss.emit();
	}

	getUsageDetails(usage: PlaceholderUsage): string {
		let displayName = this.assetSpecificUtils.getUsageDisplayName(usage);
		return `${this.locale.getString(`templates.placeholderUsageLocation_${usage.location}`)}: ${displayName}`;
	}
}
