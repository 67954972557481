// simple service to avoid having to bind this to a bunch of components
// TODO move versionId into versions-header-service
// TODO move personalizationInitializing somewhere
export class DashboardSaveState {
	versionId: number = null;
	personalizationInitializing: boolean = null;
	previewAs: boolean = null;
}

app.service('dashboardSaveState', DashboardSaveState);
