import { WidgetActionUtils } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-action-utils';
import { WidgetLinkingAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-linking-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { WidgetLinkingService } from '@cxstudio/reports/utils/analytic/widget-linking-service';
import * as _ from 'underscore';


export class WidgetLinkingController implements ng.IController {

	layout: LayoutHelper;
	widget: Widget;

	constructor(
		private widgetLinkingService: WidgetLinkingService,
		private widgetsEditService: WidgetsEditService,
	) { }

	$onInit(): void { }

	isLinkingDisabled = (): boolean => {
		return !this.isAvailableForLinking() && !this.isLinkingSource();
	}

	isLinkingSource = (): boolean => {
		return this.layout.linkingSource.id === this.widget.id;
	}

	saveWidgetLinking = () => {
		this.layout.linkingSource = undefined;
		let widgets = this.widgetsEditService.getWidgets();
		let sourceWidgets = _.filter(widgets, w => w.dirty);
		sourceWidgets.forEach(widget => {
			delete widget.dirty;
		});
		let stateBefore = this.layout.linkingState;
		this.layout.linkingSource = undefined;
		let stateAfter = WidgetActionUtils.getLinkingData(widgets);

		let action = WidgetLinkingAction.fromDifference(stateBefore, stateAfter);
		if (action) {
			this.widgetsEditService.applyDashboardChanges(this.widget.containerId, action);
			this.widgetsEditService.addDashboardHistoryState(DashboardChangeType.LINKED, [action]);
		}
	}

	isAvailableForLinking = () => this.widgetLinkingService.isAvailableForLinking(this.widget, this.layout.linkingSource);

	toggleWidgetLinking = () => {
		this.widgetLinkingService.toggleWidgetLinking(this.widget, this.layout.linkingSource, this.widgetsEditService.getWidgets());
	}

	isWidgetLinked = () => this.widgetLinkingService.isWidgetLinked(this.widget, this.layout.linkingSource.id);
}

app.component('widgetLinking', {
	controller: WidgetLinkingController,
	templateUrl: 'partials/widgets/components/widget-linking.component.html',
	bindings: {
		layout: '<',
		widget: '<'
	}
});
