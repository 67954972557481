import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { FormatDataType } from '@cxstudio/reports/formatting/format-data-type.enum';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import * as cloneDeep from 'lodash.clonedeep';
import { ClarabridgeAttributeName } from './clarabridge-attribute-name';
import { ClarabridgeMetricName } from './clarabridge-metrics-names';
import { StandardMetricName } from './standard-metrics-names';


const NLP_STUDIO_METRIC_PREFIX = '_nlp_studio_metric_';

export const MetricConstantValues = {
	VOLUME: {name: StandardMetricName.VOLUME, metricType: AnalyticMetricType.STANDARD,
		standardMetric: true, displayNameKey: 'widget.volume', decimals: Decimals.NONE },
	PERCENT_DELTA: {name: ClarabridgeMetricName.PERCENT_DELTA, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		displayNameKey: 'widget.prevalenceChange',
		dataType: FormatDataType.PERCENT_100,
		decimals: Decimals.ONE
	},
	PERCENT_OF_TOTAL: { name: StandardMetricName.PERCENT_OF_TOTAL, metricType: AnalyticMetricType.STANDARD,
		standardMetric: true, displayNameKey: 'widget.volumePercTotal'},
	PARENT_PERCENT: {name: StandardMetricName.PARENT_PERCENT, metricType: AnalyticMetricType.STANDARD,
		standardMetric: true, displayNameKey: 'widget.parentPerc' },
	SENTIMENT: {name: StandardMetricName.SENTIMENT,
		metricType: AnalyticMetricType.STANDARD, standardMetric: true,
		displayNameKey: 'widget.sentiment'},
	EFFORT: {name: PredefinedMetricConstants.EASE_SCORE,
		type: ReportAssetType.METRIC_PREDEFINED,
		definition: {type: 'ease', attributeName: 'cb_sentence_ease_score'},
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		standardMetric: true, displayNameKey: 'metrics.easeScore'},
	EMOTION: {name: PredefinedMetricConstants.EMOTION,
		type: ReportAssetType.METRIC_PREDEFINED,
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		standardMetric: true, displayNameKey: 'metrics.emotion'},
	ALPHANUMERIC: {name: StandardMetricName.ALPHANUMERIC, metricType: AnalyticMetricType.STANDARD,
		standardMetric: false, displayNameKey: 'widget.alphanumeric'},
	CUMULATIVE_PERCENT_TOTAL: {
		name: StandardMetricName.CUMULATIVE_PERCENT_TOTAL,
		type: 'postaggregation',
		metricType: AnalyticMetricType.STANDARD,
		standardMetric: false,
		displayNameKey: 'widget.cumulativePercentTotal'
	},

	// for historical reasons and to comply with existing widgets, we need to keep "name: ''""
	CONSTANT_SIZE: { name: '', metricType: AnalyticMetricType.STANDARD, standardMetric: true, displayNameKey: 'widget.optionConstantSize'	},

	NONE: { name: '', displayNameKey: 'widget.optionNone'	},

	STRENGTH: {name: StandardMetricName.STRENGTH, metricType: AnalyticMetricType.STANDARD,
		standardMetric: true, displayNameKey: 'widget.strength', decimals: Decimals.TWO },
	KEY_TERMS: {name: ClarabridgeMetricName.KEY_TERMS, type: 'sys', displayNameKey: 'widget.keyTerms',
		metricType: AnalyticMetricType.CLARABRIDGE, objectType: AttributeObjectType.SENTENCE},
	KEY_TERMS_SCORE: {name: ClarabridgeMetricName.KEY_TERMS_SCORE, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE },
	KEY_TERMS_RANK: {name: ClarabridgeMetricName.KEY_TERM_RANK, type: 'sys',
		displayNameKey: 'widget.keyTermRank', metricType: AnalyticMetricType.CLARABRIDGE},
	TOPICS: {name: '_topics', type: ReportAssetType.TOPICS, children: [], displayNameKey: 'widget.topics'},
	TOPIC_LEAF: {name: '_topic_leaf', type: ReportAssetType.TOPIC_LEAF, children: [], displayNameKey: 'widget.topicLeaf'},
	HIERARCHY_MODEL: {name: '_hierarchy_model', type: OptionsConstant.HIERARCHY_MODEL, children: [], displayNameKey: 'widget.hierarchyModel'},
	LC: {name: ClarabridgeMetricName.LC, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		displayNameKey: 'widget.assocWords', objectType: AttributeObjectType.SENTENCE},
	WORDS: {name: ClarabridgeMetricName.WORDS, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		displayNameKey: 'widget.wordTypeAllWords', objectType: AttributeObjectType.SENTENCE},
	WORDS_MTOKEN: {name: ClarabridgeMetricName.WORDS_MTOKEN, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		displayNameKey: 'widget.wordTypeAllWords'},
	HASHTAG: {name: ClarabridgeMetricName.HASHTAG, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		displayNameKey: 'widget.hashtags'},
	ES_QUERY: {name: ClarabridgeMetricName.ES_QUERY, type: 'sys', metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.SENTENCE,
		displayNameKey: 'widget.wordTypeESQuery'},
	SENTIMENT_BREAKDOWN_3: {
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		name: PredefinedMetricConstants.SENTIMENT_3,
		rawName: PredefinedMetricConstants.SENTIMENT,
		subtype: PredefinedMetricConstants.SUBTYPE_3,
		type: AnalyticMetricType.METRIC_PREDEFINED,
		displayNameKey: 'metrics.sentimentBreakdown3'
	},
	SENTIMENT_BREAKDOWN_5: {
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		name: PredefinedMetricConstants.SENTIMENT_5,
		rawName: PredefinedMetricConstants.SENTIMENT,
		subtype: PredefinedMetricConstants.SUBTYPE_5,
		type: AnalyticMetricType.METRIC_PREDEFINED,
		displayNameKey: 'metrics.sentimentBreakdown5'
	},
	EASE_BREAKDOWN_3: {
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		name: PredefinedMetricConstants.EASE_3,
		rawName: PredefinedMetricConstants.EASE_SCORE,
		subtype: PredefinedMetricConstants.SUBTYPE_3,
		type: AnalyticMetricType.METRIC_PREDEFINED,
		displayNameKey: 'metrics.easeScoreBreakdown3'
	},
	EASE_BREAKDOWN_5: {
		metricType: AnalyticMetricType.METRIC_PREDEFINED,
		name: PredefinedMetricConstants.EASE_5,
		rawName: PredefinedMetricConstants.EASE_SCORE,
		subtype: PredefinedMetricConstants.SUBTYPE_5,
		type: AnalyticMetricType.METRIC_PREDEFINED,
		displayNameKey: 'metrics.easeScoreBreakdown5'
	},

	CONTENT_TYPE: {name: ClarabridgeAttributeName.CONTENT_TYPE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CONTENT_SUBTYPE: {name: ClarabridgeAttributeName.CONTENT_SUBTYPE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	DETECTED_FEATURES: {name: ClarabridgeAttributeName.DETECTED_FEATURES, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	LANGUAGE: {name: ClarabridgeAttributeName.LANGUAGE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	PROCESSED_LANGUAGE: {name: ClarabridgeAttributeName.PROCESSED_LANGUAGE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	DETECTED_LANGUAGE: {name: ClarabridgeAttributeName.DETECTED_LANGUAGE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},

	CB_BRAND: {name: ClarabridgeAttributeName.CB_BRAND, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_COMPANY: {name: ClarabridgeAttributeName.CB_COMPANY, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_EMAIL: {name: ClarabridgeAttributeName.CB_EMAIL, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_EMOTICON: {name: ClarabridgeAttributeName.CB_EMOTICON, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_EMOTION: {name: ClarabridgeAttributeName.CB_EMOTION, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_CHAPTERS: {name: ClarabridgeAttributeName.CB_CHAPTERS, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_STATED_DURATION: {name: ClarabridgeAttributeName.CB_STATED_DURATION, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_EVENT: {name: ClarabridgeAttributeName.CB_EVENT, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_INDUSTRY: {name: ClarabridgeAttributeName.CB_INDUSTRY, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_PERSON: {name: ClarabridgeAttributeName.CB_PERSON, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_PHONE: {name: ClarabridgeAttributeName.CB_PHONE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_CURRENCY: {name: ClarabridgeAttributeName.CB_CURRENCY, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_PROFANITY: {name: ClarabridgeAttributeName.CB_PROFANITY, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_PRODUCT: {name: ClarabridgeAttributeName.CB_PRODUCT, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_SENTENCE_TYPE: {name: ClarabridgeAttributeName.CB_SENTENCE_TYPE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_SENTENCE_QUARTILE: {name: ClarabridgeAttributeName.CB_SENTENCE_QUARTILE, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_SENTENCE_WORD_COUNT: {name: ClarabridgeAttributeName.CB_SENTENCE_WORD_COUNT, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_DOCUMENT_WORD_COUNT: {name: ClarabridgeAttributeName.CB_DOCUMENT_WORD_COUNT, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_LOYALTY_TENURE: {name: ClarabridgeAttributeName.CB_LOYALTY_TENURE, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE},
	REASON_DETECTION: {name: ClarabridgeAttributeName.REASON_DETECTION, type: 'TEXT',
		metricType: AnalyticMetricType.ATTRIBUTE, objectType: AttributeObjectType.SENTENCE},
	CB_CONV_DURATION: {name: ClarabridgeAttributeName.CB_CONV_DURATION, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE},
	CB_CONV_PARTICIPANT_TYPE: {name: ClarabridgeAttributeName.CB_CONV_PARTICIPANT_TYPE, type: 'TEXT',
		metricType: AnalyticMetricType.ATTRIBUTE},
	CB_CONV_PARTICIPANT_KIND: {name: ClarabridgeAttributeName.CB_CONV_PARTICIPANT_KIND, type: 'TEXT',
		metricType: AnalyticMetricType.ATTRIBUTE},
	CB_CONV_PERCENT_SILENCE: { name: ClarabridgeAttributeName.CB_CONV_PERCENT_SILENCE, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_SENTENCE_DURATION_MS: { name: ClarabridgeAttributeName.CB_CONV_SENTENCE_DURATION_MS, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_SENTENCE_START_TIME_MS: { name: ClarabridgeAttributeName.CB_CONV_SENTENCE_START_TIME_MS, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_TOTAL_DEAD_AIR: { name: ClarabridgeAttributeName.CB_CONV_TOTAL_DEAD_AIR, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_TOTAL_HESITATION: { name: ClarabridgeAttributeName.CB_CONV_TOTAL_HESITATION, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_TOTAL_OVERTALK: { name: ClarabridgeAttributeName.CB_CONV_TOTAL_OVERTALK, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_TOTAL_SILENCE: { name: ClarabridgeAttributeName.CB_CONV_TOTAL_SILENCE, type: 'NUMBER', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONCLUDING_EVENT: { name: ClarabridgeAttributeName.CB_CONCLUDING_EVENT, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_CONV_OUTCOME: { name: ClarabridgeAttributeName.CB_CONV_OUTCOME, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_PARTICIPANT_OUTCOME: { name: ClarabridgeAttributeName.CB_PARTICIPANT_OUTCOME, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_PARTICIPANT_EMPATHY_SCORE:  { name: ClarabridgeAttributeName.CB_PARTICIPANT_EMPATHY_SCORE, type: 'NUMBER',
		metricType: AnalyticMetricType.ATTRIBUTE },
	CB_MEDICAL_CONDITION: { name: ClarabridgeAttributeName.CB_MEDICAL_CONDITION, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_MEDICAL_PROCEDURE: { name: ClarabridgeAttributeName.CB_MEDICAL_PROCEDURE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_RX: { name: ClarabridgeAttributeName.CB_RX, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_INTERACTION_TYPE: { name: ClarabridgeAttributeName.CB_INTERACTION_TYPE, type: 'TEXT', metricType: AnalyticMetricType.ATTRIBUTE },
	CB_INTERACTION_HIGHLIGHTS: { name: ClarabridgeAttributeName.CB_INTERACTION_HIGHLIGHTS, type: 'TEXT',
		metricType: AnalyticMetricType.ATTRIBUTE },

	//NLP studio metric (generated from attribute on the fly)
	NLP_CB_CONV_OUTCOME: { name: NLP_STUDIO_METRIC_PREFIX + ClarabridgeAttributeName.CB_CONV_OUTCOME,
		displayName: 'CB Conversation Outcome',
		type: ReportAssetType.METRIC_NLP,
		metricType: AnalyticMetricType.ATTRIBUTE },
	NLP_CB_AGENT_OUTCOME: { name: NLP_STUDIO_METRIC_PREFIX + 'cb_agent_outcome',
		displayName: 'CB Agent Outcome',
		type: ReportAssetType.METRIC_NLP,
		metricType: AnalyticMetricType.ATTRIBUTE },
	NLP_CB_CLIENT_OUTCOME: { name: NLP_STUDIO_METRIC_PREFIX + 'cb_client_outcome',
		displayName: 'CB Client Outcome',
		type: ReportAssetType.METRIC_NLP,
		metricType: AnalyticMetricType.ATTRIBUTE },

	USER: {
		id: -106,
		name: ClarabridgeMetricName.USER,
		displayNameKey: 'widget.user',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	},
	USER_ACTION: {
		id: -107,
		name: ClarabridgeMetricName.USER_ACTION,
		displayNameKey: 'widget.user_action',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	},
	SERVER_TIME: {
		id: -108,
		name: ClarabridgeMetricName.SERVER_TIME,
		displayNameKey: 'widget.server_time',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'DATE'
	},
	OBJECT: {
		id: -110,
		name: ClarabridgeMetricName.OBJECT,
		displayNameKey: 'widget.object',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	},
	TARGET: {
		id: -111,
		name: ClarabridgeMetricName.TARGET,
		displayNameKey: 'widget.target',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	},
	SOURCE: {
		id: -112,
		name: ClarabridgeMetricName.SOURCE,
		displayNameKey: 'widget.source',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	},
	DETAILS: {
		id: -113,
		name: ClarabridgeMetricName.DETAILS,
		displayNameKey: 'widget.details',
		metricType: AnalyticMetricType.CLARABRIDGE,
		objectType: AttributeObjectType.STUDIO_GROUPING,
		type: 'TEXT'
	}
};


export class MetricConstants {

	private readonly systemIntNumberAttributes = [
		'cb_document_word_count', 'cb_sentence_word_count', 'cb_sentence_quartile', 'cb_loyalty_tenure'
	];

	constructor(
		private locale: ILocale,
	) {}

	get(): any {
		const constantsCopy = cloneDeep(MetricConstantValues);
		_.mapObject(constantsCopy, (constantVal) => {
			if (constantVal.displayNameKey) {
				constantVal.displayName = this.locale.getString(constantVal.displayNameKey);
				delete constantVal.displayNameKey;
			}
		});
		return constantsCopy;
	}

	isIntNumberAttribute(attributeName: string): boolean {
		return attributeName && (this.systemIntNumberAttributes.indexOf(attributeName) !== -1);
	}

	isWordAttribute(attributeName: string): boolean {
		return attributeName && (attributeName === ClarabridgeMetricName.LC
			|| attributeName === ClarabridgeMetricName.WORDS
			|| attributeName === ClarabridgeMetricName.WORDS_MTOKEN
			|| attributeName === ClarabridgeMetricName.HASHTAG);
	}

	isHashtagAttribute(attributeName: string): boolean {
		return attributeName && attributeName === ClarabridgeMetricName.HASHTAG;
	}

	isLCAttribute(attributeName: string): boolean {
		return attributeName && attributeName === ClarabridgeMetricName.LC;
	}

	isESQueryAttribute(attributeName: string): boolean {
		return attributeName && attributeName === ClarabridgeMetricName.ES_QUERY;
	}

	shouldBeUppercased(attributeName: string): boolean {
		return attributeName && (attributeName === ClarabridgeMetricName.WORDS
			|| attributeName === ClarabridgeMetricName.WORDS_MTOKEN);
	}

	getWordDisplayName(attributeName: string): string {
		switch (attributeName) {
		case ClarabridgeMetricName.LC:
			return this.get().LC.displayName;
		case ClarabridgeMetricName.WORDS:
		case ClarabridgeMetricName.WORDS_MTOKEN:
			return this.get().WORDS.displayName;
		case ClarabridgeMetricName.HASHTAG:
			return this.get().HASHTAG.displayName;
		}
	}

	getStandardCalculations(projectId?: number): any[] {
		let copy = this.get();
		if (InternalProjectTypes.isStudioAdminProject(projectId))
			return [copy.VOLUME];
		else return [copy.VOLUME, copy.PERCENT_OF_TOTAL, copy.PARENT_PERCENT];
	}

	getSystemIntNumberAttributes(): string[] {
		return cloneDeep(this.systemIntNumberAttributes);
	}

	getNlpStudioMetricAttributeMap(): {cb_conv_outcome: any[], cb_participant_outcome: any[]} {
		const constantsCopy = this.get();
		return {
			cb_conv_outcome: [ constantsCopy.NLP_CB_CONV_OUTCOME],
			cb_participant_outcome: [constantsCopy.NLP_CB_AGENT_OUTCOME,
				constantsCopy.NLP_CB_CLIENT_OUTCOME]
		};
	}

	getMetricByName(name: string): {name: string, metricType: AnalyticMetricType, displayName: string} {
		return _.find(this.get(), c => c.name === name);
	}
}

app.service('metricConstants', MetricConstants);
