import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ContentProviderId, AccountId, ProjectId } from '@cxstudio/generic-types';
import { SpineSettings } from '@cxstudio/conversation/entities/spine-settings.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export enum ConversationSettingsType {
	SYSTEM = 'SYSTEM',
	CUSTOM = 'CUSTOM'
}

export enum ConversationSettingId {
	DEFAULT = -1
}

export class ConversationSettingsEntry implements IProjectSelection {
	id: number;
	contentProviderId: ContentProviderId;
	accountId: AccountId;
	projectId: ProjectId;
	workspaceProject: WorkspaceProject;
	name: string;
	displayName: string;
	type: string;
	settings: SpineSettings;
	modifiedDate?: Date;
	enabled: boolean;
}