import { Injectable } from '@angular/core';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { ITranslationDisplayData, TranslationApi } from '@app/modules/translation/translation-api.service';
import { Deferred, PromiseUtils } from '@app/util/promise-utils';
import { Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface TranslateQueueItem {
	key: string;
	text: string;
}

@Injectable({
	providedIn: 'root'
})
export class TranslationCacheService {

	private readonly DEBOUNCE_DELAY = 500;

	private cache: {[key: string]: Deferred<ITranslationDisplayData>} = {};
	private queue: TranslateQueueItem[] = [];

	private readonly debouncedSubject = new Subject<void>();

	private lastTranslatedLanguage: string;
	private readonly translateSubject = new Subject<void>();

	constructor(
		private readonly translationApi: TranslationApi,
		private readonly profanityDisguiseService: ProfanityDisguiseService,
	) {
		this.debouncedSubject.pipe(debounceTime(this.DEBOUNCE_DELAY))
			.subscribe(() => this.checkQueue());
	}

	reset(): void {
		this.cache = {};
		this.queue = [];
	}

	queueTranslation(key: string, text: string): Promise<ITranslationDisplayData> {
		if (!this.cache[key]) {
			let item: TranslateQueueItem = {
				key,
				text
			};
			this.queue.push(item);
			this.cache[key] = PromiseUtils.defer();
		}
		this.debouncedSubject.next();
		return this.cache[key].promise;
	}

	cancelTranslation(key: string): void {
		// only remove from cache if it's not executed yet
		let item = _.findWhere(this.queue, {key});
		if (item) {
			this.queue.remove(item);
			delete this.cache[key];
		}
	}

	private checkQueue(): void {
		if (this.queue.length > 0) {
			let items = this.queue.removeAll();
			let texts = items.map(item => this.processProfanityBeforeTranslate(item.text));
			this.translationApi.translate(texts).then(translateData => {
				let translated = translateData.items;
				if (translated.length !== items.length) {
					_.each(items, item => this.cache[item.key].reject('Invalid translation response'));
				}
				let fromLanguage = this.translationApi.getLanguageDisplayName(translateData.language) || translateData.language;
				let toLanguage = this.translationApi.getTargetLanguageDisplayName();
				_.each(translated, (text, index) => {
					let result = {
						from: fromLanguage,
						to: toLanguage,
						text: this.processProfanityAfterTranslate(text),
					};
					this.cache[items[index].key].resolve(result);
				});
			}, reason => {
				console.log(reason);
				_.each(items, item => this.cache[item.key].reject('Cannot translate'));
			});
		}
	}

	private processProfanityBeforeTranslate(text: string): string {
		return this.profanityDisguiseService.isFeedbackScreeningEnabled()
			? this.profanityDisguiseService.replaceProfanityMaskWithPlaceholder(text)
			: text;
	}

	private processProfanityAfterTranslate(text: string): string {
		return this.profanityDisguiseService.isFeedbackScreeningEnabled()
			? this.profanityDisguiseService.replacePlaceholderWithProfanityMask(text)
			: text;
	}

	updateLastTranslateLanguage(lang: string): void {
		if (this.lastTranslatedLanguage !== lang) {
			this.lastTranslatedLanguage = lang;
			this.translateSubject.next();
		}
	}

	getLastTranslateLanguage(): string {
		return this.lastTranslatedLanguage;
	}

	getTranslateObserver(): Observable<void> {
		return this.translateSubject.asObservable();
	}

}
