import { AuditSuggestion } from '@cxstudio/reports/document-explorer/audit-suggestion';
import { PreviewVerbatim } from '@cxstudio/reports/document-explorer/preview-verbatim';
import { CbDocument } from '../entities/cb-document.class';

export class PreviewDocument extends CbDocument {
	verbatims: PreviewVerbatim[];
	documentDescriptor: string;
	auditSuggestion?: AuditSuggestion;
	tweet?: any;
	translateActive?: boolean;
}
