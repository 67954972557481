import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SpineLaneCategory } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/spine-lane-category-utils';
import { SPINE_LANE_ICONS } from '@app/modules/account-administration/conversation-settings/conversation-settings-modal/spine-lane-icons';
import { ISpineMetricSettingsModalInput, SpineMetricSettingsModalComponent } from '@app/modules/account-administration/conversation-settings/spine-metric-settings-modal.component';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { SpineLaneStyle, UISpineLane } from '@cxstudio/conversation/entities/spine-lane.class';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
	selector: 'metric-attribute-spine-item',
	templateUrl: './metric-attribute-spine-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricAttributeSpineItemComponent implements AfterViewInit {

	@Input() lane: UISpineLane;
	@Input() type: SpineLaneCategory;
	@Input() options: INode[];
	@Input() projectSelection: IProjectSelection;
	@Input() predefinedMetrics: Metric[];
	@Output() groupingChange = new EventEmitter<INode>();

	laneStyles = [
		{ name: this.locale.getString('common.icon'), value: SpineLaneStyle.icon },
		{ name: this.locale.getString('common.emoticon'), value: SpineLaneStyle.emoticon }
	];


	laneIcons = SPINE_LANE_ICONS;

	constructor(
		@Inject('metricUtils') private metricUtils: MetricUtils,
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
	) { }

	ngAfterViewInit(): void {
		setTimeout(() => this.ref.detectChanges()); // "select-from-tree" doesn't show initial value without this
	}

	canChangeInclusionList(): boolean {
		return this.type !== SpineLaneCategory.recommended;
	}

	canChangeLaneStyle(): boolean {
		return this.type === SpineLaneCategory.attribute
			&& this.isEmoticonAttribute(this.lane.grouping);
	}

	private isEmoticonAttribute(grouping: AttributeGrouping): boolean {
		return grouping.name === ClarabridgeAttributeName.CB_EMOTICON;
	}

	canChangeIcon(): boolean {
		return this.type !== SpineLaneCategory.recommended;
	}

	getPredefinedMetric(): Metric {
		let metricName = this.lane.definition.name === SingleLaneEnrichmentTypes.EFFORT
			? PredefinedMetricConstants.EASE_SCORE : this.lane.definition.name;
		return _.findWhere(this.predefinedMetrics, {name: metricName});
	}

	onGroupingChange(node: AttributeGrouping): void {
		if (this.lane.definition.type === SpineLaneStyle.emoticon && !this.isEmoticonAttribute(node)) {
			this.lane.definition.type = SpineLaneStyle.icon;
		}
		if (this.isEmoticonAttribute(node)) {
			this.lane.definition.type = SpineLaneStyle.emoticon;
		}
		this.groupingChange.emit(node);
	}

	configureMetric(): void {
		let grouping = cloneDeep(this.lane.grouping);
		grouping.wordsFilteringMode = WordsFilteringMode.INCLUDE;
		grouping.wordsList = this.lane.definition.inclusionList;
		if (this.type === SpineLaneCategory.metric
			&& _.isEmpty(grouping.wordsList)) {
			grouping.wordsList = this.metricUtils.getPredefinedMetricValues(grouping);
		}

		let input: ISpineMetricSettingsModalInput = {
			grouping,
			projectSelection: this.projectSelection
		};
		this.cxDialogService.openDialog(SpineMetricSettingsModalComponent, input).result
			.then(wordsList => {
				this.lane.definition.inclusionList = wordsList;
			}).catch(_.noop);
	}

}
