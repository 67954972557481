<cx-wizard
	class="user-create-dialog"
	header="{{'administration.addUser'|i18n}}"
	finishText="{{'common.finish'|i18n}}"
	[loadingPromise]="model.projectsLoading"
	[activeStepIndex]="currentStep"
	(stepChange)="updateActiveStepIndex($event)"
	[mode]="mode"
	(onFinish)="save()"
	(onCancel)="cancel()"
	(onConfirm)="ok()">

	<cx-wizard-step>
		<user-create-main-step
			[user]="user"
			[passwordPolicy]="input.passwordPolicy"
			[allowedDomains]="input.allowedDomains"
			[licenseTypes]="input.licenseTypes"
			[permissionItems]="input.permissionItems"
			[licenseInfo]="input.licenseInfo"
			(dialogValidityChange)="updateDialogValidity($event)"
			(optionsChange)="updateOptions($event)"
			(licenseChange)="updateSelectedLicense($event)"
			(accountsChange)="updateAccounts($event)"
			(accountsReset)="onAccountsReset()"
		></user-create-main-step>
	</cx-wizard-step>

	<cx-wizard-step
		[hasCustomNext]="true"
		(customNext)="permissionsStepHandlers.next()">
		<user-create-permissions-step
			[last]="!isCPLinkingAllowed() && !isAddingToGroupAllowed()"
			[finishEnabled]="isFinishEnabled()"
			[permissions]="options.permissions"
			[selectedPermissions]="user.permissions"
			[license]="selectedLicense"
			[user]="user"
			(permissionsChange)="permissionsChangedCallback($event)">
		></user-create-permissions-step>
	</cx-wizard-step>

	<cx-wizard-step
		[skipped]="!isAddingToGroupAllowed()"
		[hasCustomNext]="true"
		(customNext)="groupsStepHandlers.next()">
		<user-create-groups-step
			[last]="!isCPLinkingAllowed()"
			[finishEnabled]="isFinishEnabled()"
			[user]="user"
		></user-create-groups-step>
	</cx-wizard-step>

	<cx-wizard-step
		[skipped]="isWorkspaceEnabled"
		[hasCustomNext]="true"
		(customNext)="linkingStepHandlers.next()"
		[hasCustomBack]="true"
		(customBack)="linkingStepHandlers.back()">
		<user-create-linking-step
			[skipLinkUser]="model.skipLinkUser"
			[finishEnabled]="isFinishEnabled()"
			(updateSkipLinkUser)="updateSkipLinkUser($event)"
			[selectedAccounts]="selectedAccounts"
		></user-create-linking-step>
	</cx-wizard-step>

	<cx-wizard-step
		[hasCustomBack]="true"
		(customBack)="projectsAccessStepHandlers.back()">
		<user-create-projects-access-step
			[finishEnabled]="isFinishEnabled()"
			[isWorkspaceEnabled]="isWorkspaceEnabled"
			[user]="user"
			[license]="selectedLicense"
			[licenses]="licenseTypes"
			[contentProvidersWithAccounts]="contentProvidersWithAccounts"
			[sourceUserId]="input.userData.sourceUserId"
			(changeProjectAccess)="changeProjectAccess($event)"
			(accessChange)="updateWorkspacesAccess($event)"
		></user-create-projects-access-step>
	</cx-wizard-step>

	<cx-wizard-step>
		<user-create-finish-step
			[loading]="model.loading"
			[loadingMessage]="model.loadingMessage"
			[errors]="model.errors"
			[done]="model.done"
		></user-create-finish-step>
	</cx-wizard-step>

</cx-wizard>
