import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

	transform(value: unknown): unknown {
		return (typeof value === 'string') ? value.capitalizeFirstLetter() : value;
	}

}
