import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import * as _ from 'underscore';


export class KeyTermsUtils {

	static isKeyTermsWidget(selectedAttributes: any[]): boolean {
		return _.findWhere(selectedAttributes, {name: ClarabridgeMetricName.KEY_TERMS});
	}

	static getDataWithRanks(data: any[]): any[] {
		let sortedData = this.sortByScoreAndVolume(data);
		return this.populateDataRanks(sortedData);
	}

	private static sortByScoreAndVolume(data: any[]): any[] {
		return _.chain(data)
			.sortBy(StandardMetricName.VOLUME)
			.sortBy(ClarabridgeMetricName.KEY_TERMS_SCORE)
			.reverse()
			.value();
	}

	private static populateDataRanks(data: any[]): any[] {
		_.each(data, (element, index, list) => {
			element.keyTermRank = index + 1;
		});

		return data;
	}

}
