import { Injectable, Type } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ContentButtonComponent } from '@app/modules/widget-visualizations/content-widgets/content-button/content-button.component';
import { ContentImageComponent } from '@app/modules/widget-visualizations/content-widgets/content-image/content-image.component';
import { ContentLabelComponent } from '@app/modules/widget-visualizations/content-widgets/content-label/content-label.component';
import { ContentTextComponent } from '@app/modules/widget-visualizations/content-widgets/content-text/content-text.component';
import { ContentVideoComponent } from '@app/modules/widget-visualizations/content-widgets/content-video/content-video.component';
import { PreviewBubblesComponent } from '@app/modules/widget-visualizations/feedback/preview-bubbles/preview-bubbles.component';
import { PreviewTableComponent } from '@app/modules/widget-visualizations/feedback/preview-table/preview-table.component';
import { HighchartsDualWithCasesComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-dual-with-cases.component';
import { HighchartsDualComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/highcharts-dual.component';
import { HighchartsGaugeComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge.component';
import { QualtricsWidgetComponent } from '@app/modules/widget-visualizations/qualtrics/qualtrics-widget.component';
import { VisualizationComponent } from '@app/modules/widget-visualizations/visualization-component.interface';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import ChartType from '@cxstudio/reports/entities/chart-type';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { MetricWidgetComponent } from './metric-widget/metric-widget.component';

@Injectable({
	providedIn: 'root'
})
export class VisualizationResolverService {

	private readonly FULLY_SUPPORTED_VISUALIZATIONS: WidgetVisualization[] = [
		WidgetVisualization.DUAL,
		WidgetVisualization.GAUGE,
		WidgetVisualization.QUALTRICS,
		WidgetVisualization.VIDEO,
		WidgetVisualization.IMAGE,
		WidgetVisualization.TEXT,
		WidgetVisualization.LABEL,
		WidgetVisualization.BUTTON,
		WidgetVisualization.PREVIEW_BUBBLES,
		WidgetVisualization.PREVIEW_TABLE,
	];

	isNgWidgetContent = (widget: Widget): boolean => {
		let visualization = widget.visualProperties.visualization as WidgetVisualization;
		return this.FULLY_SUPPORTED_VISUALIZATIONS.contains(visualization)
			|| this.isNgMetricWidget(visualization, widget.properties.selectedMetrics?.length);
	}

	private isNgMetricWidget(visualization: WidgetVisualization, metricsCount: number): boolean {
		// we don't use the angular 9+ metric widget yet if it requires table view....
		return (visualization === WidgetVisualization.CB_AN_METRIC && metricsCount === 1);
	}

	resolveVisualization(visualization: WidgetVisualization,
			widget: Widget, utils: WidgetUtils): Type<VisualizationComponent<any>> {
		if (visualization === WidgetVisualization.DUAL) {
			if (widget.visualProperties.subChartType !== ChartType.BAR
					&& widget.visualProperties.caseVisualizations?.enabled
					&& AnalyticMetricTypes.isTime(widget.properties.selectedAttributes[0])) {
				return HighchartsDualWithCasesComponent;
			} else {
				return HighchartsDualComponent;
			}
		}

		switch (visualization) {
			case WidgetVisualization.GAUGE: return HighchartsGaugeComponent;
			case WidgetVisualization.CB_AN_METRIC: return MetricWidgetComponent;
			case WidgetVisualization.PREVIEW_BUBBLES: {
				let filteringFeedbackSelection = utils.filteringFeedbackSelection;
				return filteringFeedbackSelection && filteringFeedbackSelection.isEnabled()
					? PreviewTableComponent : PreviewBubblesComponent;
			}
			case WidgetVisualization.PREVIEW_TABLE: return PreviewTableComponent;
			case WidgetVisualization.QUALTRICS: return QualtricsWidgetComponent;
			case WidgetVisualization.VIDEO: return ContentVideoComponent;
			case WidgetVisualization.IMAGE: return ContentImageComponent;
			case WidgetVisualization.TEXT: return ContentTextComponent;
			case WidgetVisualization.LABEL: return ContentLabelComponent;
			case WidgetVisualization.BUTTON: return ContentButtonComponent;
		}

		throw new Error(`Unsupported visualization: ${visualization}`);
	}
}

app.service('visualizationResolverService', downgradeInjectable(VisualizationResolverService));
