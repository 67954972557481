import { Injectable } from '@angular/core';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ColorPaletteStoreService {
	private colorPalettes: ColorPalette[];
	private readonly refreshSourceSubject = new Subject<void>();
	private readonly updatedSourceSubject = new Subject<ColorPalette>();
	private readonly toggledSourceSubject = new Subject<ColorPalette>();

	constructor() { }

	public getRefreshObserver(): Observable<void> {
		return this.refreshSourceSubject.asObservable();
	}

	public getUpdateObserver(): Observable<ColorPalette> {
		return this.updatedSourceSubject.asObservable();
	}

	public getToggleObserver(): Observable<ColorPalette> {
		return this.toggledSourceSubject.asObservable();
	}

	public refreshColorPalettes() {
		this.refreshSourceSubject.next();
	}

	public updateColorPalette(colorPalette: ColorPalette) {
		this.updatedSourceSubject.next(colorPalette);
	}

	public toggleColorPalette(colorPalette: ColorPalette) {
		this.toggledSourceSubject.next(colorPalette);
	}

	public setColorPalettes(colorPalettes: ColorPalette[]) {
		this.colorPalettes = colorPalettes;
	}

	public getColorPalettes() {
		return this.colorPalettes;
	}
}
