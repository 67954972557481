import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { DocViewPaneSettings } from './doc-view-pane-settings';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';

// simplified version of doc explorer preferences that strips out most functionality
export class WidgetDocViewPreferences extends DocViewPreferences {

	private widget: Widget;

	constructor(widget: Widget) {
		super();
		this.widget = widget;
		this.settings = widget.visualProperties?.preferences?.settings || DocViewPaneSettings.default();
	}

	getSavedFavorites = (contentProviderId: number, accountId: number, projectId: number) => this.widget.properties.favoriteAttributes;

	updateSavedFavorites = (contentProviderId: number, accountId: number, projectId: number, favorites: FavoriteId[]) => {
		this.widget.properties.favoriteAttributes = favorites as any[];
	}

	storeUpdates = () => {};
}
