import { IDateRange } from '@cxstudio/reports/entities/date-period';
import * as _ from 'underscore';
import { Security } from '@cxstudio/auth/security-service';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { UserSearchAPI } from '@app/modules/user-administration/search-panel/user-search-api.service';
import { UserQueryType } from '@cxstudio/query/user-query-type.enum';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ListOption } from '@app/shared/components/forms/list-option';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FilterQueryField } from '@cxstudio/query/filter-query-field.enum';

export interface IFilterOption {
	value: FilterQueryField;
	name: string;
	property?: string;
}

export interface IUserSearchModel {
	userQueryBy?: FilterQueryField;
	userQueryType?: UserQueryType;
	userQueryByName?: string;
	userQueryTypeSelection?: IFilterOption;
	text?: string;
	license?: number;
	date?: IDateRange;
	status?: string;
	type?: string;
	defaultMasterAccount?: number;
}

@Component({
	selector: 'user-search-panel',
	templateUrl: './user-search-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSearchPanelComponent implements OnInit {
	@Input('searchModel') model: IUserSearchModel;
	@Input() searchOptionFilter: (searchOption: IFilterOption) => boolean;
	@Input() readyCallback: () => void;
	@Output('onSearchChange') searchChange = new EventEmitter<IUserSearchModel>();


	userQueryByOptions: IFilterOption[];
	licenseTypes: LicenseTypeItem[];
	masterAccounts: MasterAccount[];

	statusOptions: ListOption<string>[];
	typeOptions: ListOption<string>[];


	private suggester: (text: string) => Promise<string[]>;

	constructor(
		private locale: CxLocaleService,
		private licenseApiService: LicenseApiService,
		private userSearchAPI: UserSearchAPI,
		@Inject('security') private security: Security,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
	) { }

	ngOnInit(): void {
		this.model = this.model || {};
		_.extend(this.model, {
			userQueryType: '',
			userQueryBy: '',
			userQueryByName: '',
			status: 'enabled',
			type: 'internal',
			date: {}
		});


		this.statusOptions = [{
			value: 'enabled',
			name: this.locale.getString('common.enabled')
		}, {
			value: 'disabled',
			name: this.locale.getString('common.disabled')
		}];

		this.typeOptions = [{
			value: 'internal',
			name: this.locale.getString('administration.internal')
		}, {
			value: 'external',
			name: this.locale.getString('administration.external')
		}];

		this.searchOptionFilter = this.searchOptionFilter || this.defaultSearchOptionsFilter;
		this.userQueryByOptions = this.getFilterOptions();
		if (this.userQueryByOptions.find(option => option.value === FilterQueryField.LICENSE)) {
			this.loadLicenseTypes();
		}
		if (this.userQueryByOptions.find(option => option.value === FilterQueryField.DEFAULT_MA)) {
			this.loadMasterAccounts();
		}
		this.onUserQueryByChange(this.userQueryByOptions[0]);
		if (this.readyCallback) this.readyCallback();
	}

	textSuggestions = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(term =>
				this.suggester
					? this.suggester(term)
					: Promise.resolve([])
			)
		)

	loadLicenseTypes = (): void => {
		this.licenseApiService.getLicenseTypes()
			.then(result => this.licenseTypes = result);
	}

	loadMasterAccounts = (): void => {
		this.systemAdminApiService.getAllMasterAccounts()
			.then(response => this.masterAccounts = response.data);
	}

	isText = (): boolean => this.model.userQueryType === UserQueryType.TEXT;
	isDate = (): boolean => this.model.userQueryType === UserQueryType.DATE;
	isStatus = (): boolean => this.model.userQueryBy === FilterQueryField.STATUS;
	isType = (): boolean => this.model.userQueryBy === FilterQueryField.TYPE;
	isLicenseType = (): boolean => this.model.userQueryBy === FilterQueryField.LICENSE;
	isDefaultMasterAccount = (): boolean => this.model.userQueryBy === FilterQueryField.DEFAULT_MA;

	private defaultSearchOptionsFilter = (searchOption: IFilterOption) => true;

	private getFilterOptions = () => {
		let filterOptions = [{
			value: FilterQueryField.EVERYTHING,
			name: this.locale.getString('administration.nameOrEmail')
		}, {
			value: FilterQueryField.FIRST_NAME,
			name: this.locale.getString('administration.firstName')
		}, {
			value: FilterQueryField.LAST_NAME,
			name: this.locale.getString('administration.lastName')
		}, {
			value: FilterQueryField.USER_EMAIL,
			name: this.locale.getString('administration.emailAddress')
		}, {
			value: FilterQueryField.LICENSE,
			name: this.locale.getString('administration.licenseType'),
			property: 'license'
		}, {
			value: FilterQueryField.LOGIN_DATE,
			name: this.locale.getString('administration.lastLogin')
		}, {
			value: FilterQueryField.STATUS,
			name: this.locale.getString('administration.status'),
			property: 'status'
		}, {
			value: FilterQueryField.TYPE,
			name: this.locale.getString('administration.userType'),
			property: 'type'
		}, {
			value: FilterQueryField.TAGS,
			name: this.locale.getString('administration.tags')
		}, {
			value: FilterQueryField.XM_ACCOUNT_ID,
			name: this.locale.getString('administration.xmAccountId')
		}, {
			value: FilterQueryField.XM_GLOBAL_USER_ID,
			name: this.locale.getString('administration.xmGlobalUserId')
		}, {
			value: FilterQueryField.DEFAULT_MA,
			name: this.locale.getString('administration.defaultMA'),
			property: 'defaultMasterAccount'
		}, {
			value: FilterQueryField.LDAP_USER_ID,
			name: this.locale.getString('administration.uid')
		}];

		let masterAccountCustomField = this.security.getCurrentMasterAccount().customField;
		if (masterAccountCustomField) {
			let customFieldOption = {
				value: FilterQueryField.CUSTOM,
				name: masterAccountCustomField
			};

			filterOptions.push(customFieldOption);
		}

		return _.filter(filterOptions, this.searchOptionFilter);
	}

	private getSearchQueryType = (): UserQueryType => {
		switch (this.model.userQueryBy) {
			case FilterQueryField.STATUS:
			case FilterQueryField.TYPE:
			case FilterQueryField.DEFAULT_MA:
			case FilterQueryField.LICENSE: return UserQueryType.CUSTOM;
			case FilterQueryField.LOGIN_DATE: return UserQueryType.DATE;
			default: return UserQueryType.TEXT;
		}
	}

	onChange = (): void => {
		this.searchChange.emit(this.model);
	}

	onUserQueryByChange = (option: IFilterOption): void => {
		this.model.userQueryTypeSelection = option;
		this.model.userQueryBy = option.value;
		this.model.userQueryByName = option.name;
		this.model.userQueryType = this.getSearchQueryType();

		this.suggester = this.getSuggester();
	}

	private getSuggester = (): (text: string) => Promise<string[]> | undefined => {
		switch (this.model.userQueryBy) {
			case 'customField': return this.userSearchAPI.getCustomFieldsSuggestion;
			case 'tags': return this.userSearchAPI.getTagsSuggestion;
		}
	}
}

app.directive('userSearchPanel', downgradeComponent({component: UserSearchPanelComponent}) as angular.IDirectiveFactory);
