import { TemplateRef } from '@angular/core';
import { WithTestId } from '@app/core/testability';

export enum ContextMenuItemType {
	TEXT = 'text',
	TEMPLATE = 'template',
	SUBMENU = 'submenu',
	DIVIDER = 'divider'
}

export interface IContextMenuItem {
	type: ContextMenuItemType;
}

export class ContectMenuDividerItem implements IContextMenuItem {
	readonly type: ContextMenuItemType;

	constructor() {
		this.type = ContextMenuItemType.DIVIDER;
	}
}

abstract class ContectMenuTextItemBase implements WithTestId {
	name: string;
	text: string;
	testId?: string;

	constructor(
		name: string,
		text: string,
		testId?: string
	) {
		this.name = name;
		this.text = text;
		if (testId) {
			this.testId = testId;
		}
	}
}

export class ContextMenuTextItem extends ContectMenuTextItemBase implements IContextMenuItem {
	readonly type: ContextMenuItemType;
	action: () => void;

	constructor(
		name: string,
		text: string,
		action?: () => void,
		testId?: string
	) {
		super(name, text, testId);
		this.type = ContextMenuItemType.TEXT;
		this.action = action;
	}
}

export class ContextMenuTemplateItem implements IContextMenuItem {
	readonly type: ContextMenuItemType;
	template: TemplateRef<any>;

	constructor(
		template: TemplateRef<any>
	) {
		this.type = ContextMenuItemType.TEMPLATE;
		this.template = template;
	}
}

export class ContextMenuListItem extends ContectMenuTextItemBase implements IContextMenuItem {
	readonly type: ContextMenuItemType;
	items: ContextMenuTextItem[];
	open: boolean;

	constructor(
		name: string,
		text: string,
		items: ContextMenuTextItem[]
	) {
		super(name, text);
		this.type = ContextMenuItemType.SUBMENU;
		this.items = items;
	}
}
