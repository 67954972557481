import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractsTabComponent } from './contracts-tab/contracts-tab.component';
import { ContractsComponent } from './contracts/contracts.component';
import { NgPipesModule } from 'ngx-pipes';
import { LicenseHasContractWithSeats } from './contracts/license-has-contract-with-seats.pipe';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		NgPipesModule
	],
	declarations: [
		ContractsTabComponent,
		ContractsComponent,
		LicenseHasContractWithSeats
	],
})
export class MasterAccountsContractsModule { }
