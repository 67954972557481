import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';

export class DashboardRenameAction implements DashboardChangeAction {

	private dashboardPropsService: DashboardPropsService;
	private currentProperty: string;

	constructor(private before: string,
		private after: string, private dashboardProps: DashboardPropsService) {
		this.currentProperty = after;
		this.dashboardPropsService = dashboardProps;
	}

	reverse(): DashboardChangeAction {
		return new DashboardRenameAction(this.after, this.before, this.dashboardPropsService);
	}

	apply(): Promise<void> {
		this.dashboardPropsService.current.name = this.currentProperty;
		return Promise.resolve();
	}
}

