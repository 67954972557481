import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { UniqueNameValidator } from '@app/modules/asset-management/validation/unique-name-validator';
import WorkspaceMetricsApiService from '@app/modules/metric/services/workspace-metrics-api.service';
import { IAssetPropertiesComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/asset-properties-component.interface';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { UniqueNameService } from '@cxstudio/common/unique-name-service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';

@Component({
	selector: 'metric-creation-properties',
	templateUrl: './metric-creation-properties.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricCreationPropertiesComponent implements IAssetPropertiesComponent<Metric> {
	@Output() projectChange = new EventEmitter<WorkspaceProject>();
	@Output() validityChange = new EventEmitter<boolean>();

	template: UnifiedTemplate;
	metric: Metric;
	invalidNameError: boolean;
	uniqueNameError: boolean;
	private nameValidator: UniqueNameValidator<Metric>;

	constructor(
		private ref: ChangeDetectorRef,
		private workspaceMetricsApi: WorkspaceMetricsApiService,
		@Inject('uniqueNameService') private readonly uniqueNameService: UniqueNameService,
	) {}

	initTemplate(template: UnifiedTemplate): void {
		this.template = template;
		this.metric = {
			displayName: template.name,
			workspaceProject: {} as WorkspaceProject
		} as Metric;
	}

	getAsset(): Metric {
		return this.metric;
	}

	onProjectChange(): void {
		this.projectChange.emit(this.metric.workspaceProject);
		this.reloadMetrics(this.metric.workspaceProject);
	}

	isValid(): boolean {
		return !this.invalidNameError && !this.uniqueNameError && !_.isUndefined(this.uniqueNameError);
	}

	private reloadMetrics(project: WorkspaceProject): void {
		if (!WorkspaceProjectUtils.isProjectSelected(project)) {
			delete this.uniqueNameError;
			return;
		}
		this.workspaceMetricsApi.getMetrics(project).then(metrics => {
			if (this.metric.displayName === this.template.name) {
				this.metric.displayName = this.uniqueNameService.resolveUniqueName(_.pluck(metrics, 'displayName'), this.template.name);
			}
			this.nameValidator = new UniqueNameValidator({displayName: this.metric.displayName} as Metric,
				metrics, {nameField: 'displayName'});
			this.checkName();
			this.validityChange.emit(this.isValid());
		});
	}

	checkName(): void {
		this.invalidNameError = !this.metric?.displayName.trim();
		this.uniqueNameError = this.nameValidator && !this.nameValidator.isUniqueName(this.metric.displayName);
		this.ref.markForCheck();
	}
}
