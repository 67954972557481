<ng-container *ngIf="!!metric">
	<label class="w-100-percent">
		<p class="mb-8 font-semibold text-left" [i18n]="'metrics.metricName'"></p>
		<div class="w-100-percent">
			<input id="metric-name"
				class="form-control"
				data-testid="name"
				name="name"
				type="text"
				[(ngModel)]="metric.displayName"
				(debouncedChange)="checkName()"
				[debounce]="500"
				size="60"
				maxlength="150"
				required>
			<form-error *ngIf="uniqueNameError" text="{{'metrics.nameNotUnique'|i18n}}"></form-error>
			<form-error *ngIf="invalidNameError" text="{{'metrics.nameRequiredError'|i18n}}"></form-error>
		</div>
	</label>
	<workspace-project-selector
		[(value)]="metric.workspaceProject"
		(valueChange)="onProjectChange()"
		[customProjectPromptLabel]="'templates.metricProjectLabel'"
		labelPosition="top"
		[hideInternalProjects]="true"
		[labelBold]="true">
	></workspace-project-selector>
</ng-container>
