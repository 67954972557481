import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RequestAssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters-factory.class';
import { ReportAttributeApiService } from '@app/modules/project/attribute/report-attributes-api.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';

@Injectable({
	providedIn: 'root'
})
export class ReportAttributesService {
	constructor(
		private readonly reportAttributeApi: ReportAttributeApiService,
		private readonly attributesService: AttributesService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		@Inject('adminProjectsService') private adminProjectsService: AdminProjectsService,
		private readonly projectAssetsHelper: ProjectAssetsHelper,
	) {}

	getWidgetAttributes(widget: Widget): Promise<IReportAttribute[]> {
		return this.projectAssetsHelper.getWidgetProjectData<IReportAttribute[]>(
			widget,
			(project) => this.attributesService.getAttributes(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportAttributeApi.getAttributes(viewParams),
				projectId => this.adminProjectsService.getProjectAttributes(projectId))
		);
	}

	getWidgetAttributesOrEmpty(widget: Widget): Promise<IReportAttribute[]> {
		//return empty list if CP is unavailable
		return this.getWidgetAttributes(widget).then(_.identity, () => []);
	}

	private getDashboardAttributes(dashboard: Dashboard): Promise<IReportAttribute[]> {
		return this.projectAssetsHelper.getDashboardProjectData<IReportAttribute[]>(
			dashboard,
			(project) => this.attributesService.getAttributes(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportAttributeApi.getAttributes(viewParams),
				projectId => this.adminProjectsService.getProjectAttributes(projectId))
		);
	}

	getDashboardAttributesOrEmpty(dashboard: Dashboard): Promise<IReportAttribute[]> {
		//return empty list if CP is unavailable
		return this.getDashboardAttributes(dashboard).then(_.identity, () => []);
	}

	getWidgetWordAttributes(widget: Widget): Promise<IReportAttribute[]> {
		let widgetProject = this.reportAssetUtilsService.getWidgetProject(widget);
		if (InternalProjectTypes.isAdminProject(widgetProject.projectId)) {
			return Promise.resolve([]);
		}
		let requestParams = RequestAssetParametersFactory.fromWidget(widget);
		return this.reportAttributeApi.getWordAttributes(requestParams);
	}

	getWidgetUserHiddenAttributes(widget: Widget): Promise<IReportAttribute[]> {
		return this.getWidgetAttributes(widget)
			.then(_.identity, () => [])
			.then(attributes => attributes.filter(attr => attr.hide));
	}

}

app.service('reportAttributesService', downgradeInjectable(ReportAttributesService));
