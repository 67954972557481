import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { KafkaState } from '@app/modules/system-administration/status/listener-status/kafka-state';
import { KafkaStatusApi } from '@app/modules/system-administration/status/listener-status/kafka-status.api.service';
import { MetricsState, MetricsStatus } from '@app/modules/system-administration/status/listener-status/listener-state';

interface StatusFormatterData {
	icon: string;
	class: string;
	label: string;
}

@Component({
	selector: 'kafka-status-tab',
	templateUrl: './kafka-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KafkaStatusTabComponent implements OnInit {

	kafkaState: KafkaState[] = [];
	kafkaStatusColumns: Array<TableColumn<KafkaState>>;

	private statusesMap: {[key in MetricsStatus]: StatusFormatterData};

	constructor(
		private ref: ChangeDetectorRef,
		private kafkaStatusApi: KafkaStatusApi,
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService
	) {}

	ngOnInit(): void {
		this.statusesMap = this.getStatusesMap();
		this.kafkaStatusColumns = this.getKafkaStatusColumns();
		this.refreshData();
	}

	private refreshData = (): void => {
		this.kafkaStatusApi.getData().then((result) => {
			this.kafkaState = result;
			this.ref.markForCheck();
		});
	}

	getStatePanelType = (state: KafkaState): string => {
		return this.hasFailed(state) ? 'danger' : 'success';
	}

	private hasFailed = (state: KafkaState): boolean => {
		const metrics = state.metrics || [];
		let failedListener = metrics.filter(this.filterDown);
		return (!failedListener.isEmpty() || metrics.isEmpty());
	}

	private filterDown = (state: MetricsState<any, any>): boolean => {
		return state.status === MetricsStatus.FAILED || state.status === MetricsStatus.EXPIRED;
	}

	private getKafkaStatusColumns(): Array<TableColumn<KafkaState>> {
		return [{
			name: 'status',
			displayName: this.locale.getString('administration.jmsBrokerStatus'),
			formatter: this.statusFormatter,
			width: 0.5
		}, {
			name: 'nodeId',
			displayName: this.locale.getString('administration.jmsBrokerServer'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.5
		}];
	}

	private getStatusesMap(): {[key in MetricsStatus]: StatusFormatterData} {
		return {
			OK: {
				icon: 'q-icon-check',
				class: 'text-success',
				label: this.locale.getString('administration.stateUp')
			}, FAILED: {
				icon: 'q-icon-warning',
				class: 'text-danger',
				label: this.locale.getString('administration.stateFailed')
			}, EXPIRED: {
				icon: 'q-icon-warning',
				class: 'text-danger',
				label: this.locale.getString('administration.stateExpired')
			}
		};
	}

	private statusFormatter = (object: MetricsState<any, any>): string => {
		let status = object.status;
		let statusData = this.statusesMap[status];
		return `
			<b class="${statusData.class}">
				<span class="${statusData.icon}"></span> ${statusData.label}
			</b>
		`;
	}
}
