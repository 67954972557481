import { Provider, FactoryProvider, ReflectiveInjector } from '@angular/core';

class Interoperability {

	/**
	 * Creates Angular providers for all dependencies, manually registered to the provided module.
	 * This *does not* register any 3rd party dependencies from dependent modules.
	 * 
	 * Such dependencies can then be injected in Angular components using @Inject(_dependencyName_).
	 */
	migrateModule(module: ng.IModule): Provider[] {
		return (module as any)._invokeQueue
			.map(injectableDescriptor => {
				const injectableName = injectableDescriptor[2][0];
				return this.buildFactoryProvider(injectableName);
			});
	}

	/**
	 * Creates Angular providers for provided deps.
	 * This method can be used to create providers for 3rd party js modules, like $log, $http, etc., if needed.
	 * 
	 * @NotRecommended However, it's recommended to use Angular native dependencies, that provide similar functionality
	 * instead of creating providers for AngularJS legacy modules.
	 * This method only exists to ensure full interoperability between all modules that we have and those, 
	 * that we cannot yet replace (e.g. 'locale').
	 * 
	 * Such dependencies can then be injected in Angular components using @Inject(_dependencyName_).
	 */
	migrateDeps(deps: string[]): Provider[] {
		return deps.map(this.buildFactoryProvider);
	}

	private buildFactoryProvider(injectableName: string): FactoryProvider {
		{
			return {
				provide: injectableName, 
				useFactory: ($injector: any) => $injector.get(injectableName), 
				deps: ['$injector']
			} as FactoryProvider;
		}
	}

}

export default new Interoperability();