<div class="br-list-box flex-fill pb-24" style="height:411px" *ngIf="widgetsEnabled">
	<div *ngIf="loaded">
		<div class="uppercase" [i18n]="'homePage.whatToWatchToday'"></div>
		<div class="custom-widgets d-flex flex-direction-row">
			<div *ngFor="let widget of widgets"
				class="w-100-percent h-100-percent p-relative mh-8 custom-widget"
				[attr.id]="'widget-' + widget.id">
				<widget-container
					[widget]="widget"
					[widgetMode]="widgetMode"
					[editMode]=false
					[dashboardData]="dashboardData"
					[layout]="{}"
					class="br-widget w-100-percent h-100-percent home-page-widget-container">
				</widget-container>
			</div>
		</div>
	</div>
</div>
