<div class="cx-wizard" [ngBusy]="loadingPromise">
	<div class="modal-header">
		<cb-modal-close
			(click)="cancel()"
			[hidden]="!isShowingCancelIcon()">
		</cb-modal-close>
		<h2 class="modal-title">{{getHeader()}}</h2>
	</div>

	<div id="cb-modal-overlay" [hidden]="!isViewMode()"></div>
	<div class="modal-body cx-wizard-modal" [blockKbNavigation]="isViewMode()">
		<ng-content></ng-content>
	</div>

	<div class="modal-footer" *ngIf="!isConfirmStep()">
		<button id="cx-btn-back" type="button" class="btn btn-secondary"
			*ngIf="isShowingBackButton()"
			(click)="back()">{{'common.back'|i18n}}</button>

		<button id="cx-btn-cancel" type="button" class="btn btn-secondary"
				(click)="cancel()">{{getCancelText()}}
		</button>
		<button id="cx-btn-finish" type="button" class="btn btn-primary"
				*ngIf="isShowingFinishButton()"
				(click)="finish()"
				[disabled]="!isFinishAllowed()">{{getFinishText()}}</button>

		<button id="cx-btn-next" type="button" class="btn btn-primary"
				*ngIf="isShowingNextButton()"
				(click)="next()"
				[disabled]="!isNextAllowed()">{{'common.next'|i18n}}</button>
	</div>

	<div class="modal-footer" *ngIf="isConfirmStep()">
		<button id="cx-btn-ok" type="button" class="btn btn-primary"
			(click)="confirm()" [disabled]="!isConfirmAllowed()">
			{{'common.ok'|i18n}}
		</button>
	</div>
</div>
