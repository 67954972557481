import * as _ from 'underscore';
import { SortDirection } from '@cxstudio/common/sort-direction';

export type IComparator<T = any> = (a: T, b: T) => number;

export class SortUtils {

	static getNumericComparator<T = any>(field: string, direction: number): IComparator<T> {
		return (row1, row2) => {
			let value1 = row1[field];
			let value2 = row2[field];

			if (isNaN(value1) !== isNaN(value2)) {
				return isNaN(value1) ? 1 : -1;
			}

			let result = (value1 === value2 ? 0 : (value1 > value2 ? 1 : -1));
			return result * direction;
		};
	}

	static getComparator<T = any>(sortBy: string, direction: SortDirection, alphanumericSort?: boolean): IComparator<T> {
		let dir = direction === SortDirection.ASC ? 1 : -1;
		if (alphanumericSort) {
			let sortByFunc = (row) => {
				return row[sortBy];
			};
			return SortUtils.getAlphanumericComparator(sortByFunc, direction);
		} else {
			return (row1, row2) => {
				let a = row1[sortBy] * dir;
				let b = row2[sortBy] * dir;
				if (isNaN(a) || isNaN(b)) {
					return isNaN(b) ? -1 : 1;
				}

				return a <= b ? -1 : 1;
			};
		}

	}

	static getAlphanumericComparator<T>(sortBy: (row: T) => string, direction: SortDirection): IComparator<T> {
		let dir: number = direction === SortDirection.ASC ? 1 : -1;
		return (row1, row2) => {
			return this.compareAlphanumerically(sortBy(row1), sortBy(row2)) * dir;
		};
	}

	static compareAlphanumerically(as: string, bs: string): number {
		if (isEmpty(as) || isEmpty(bs)) {
			return isEmpty(as) ? 1 : -1;
		}

		let a;
		let b;
		let a1;
		let b1;
		let n;
		// match negative and decimal only if the whole string is a number
		let numberPattern = /(^((-?\d+(\.\d+)?)|(\.\d+))$)|(\d+)|(\D+)/g;
		if (as === bs) return 0;
		a = as.toLowerCase().match(numberPattern);
		b = bs.toLowerCase().match(numberPattern);
		let index = 0;
		let count = a.length;
		while (index < count) {
			if (!b[index])
				return 1;

			a1 = a[index];
			b1 = b[index];
			index = index + 1;
			if (a1 !== b1) {
				n = a1 - b1;
				if (!isNaN(n)) return n;
				return a1 > b1 ? 1 : -1;
			}
		}
		return b[index] ? -1 : 0;
	}

	static mergeSort<T>(arr: T[], comparator: IComparator<T>): T[] {
		if (arr.length < 2)
			return arr;
		if (!comparator)
			return arr;
		let middle: number = Math.floor(arr.length / 2);
		let left: T[] = arr.slice(0, middle);
		let right: T[] = arr.slice(middle, arr.length);

		return this.merge(
			this.mergeSort(left, comparator),
			this.mergeSort(right, comparator),
			comparator
		);
	}

	private static merge<T>(left: T[], right: T[], comparator: IComparator<T>): T[] {
		let result: any[] = [];

		while (left.length && right.length) {
			if (comparator(left[0], right[0]) <= 0) {
				result.push(left.shift());
			} else {
				result.push(right.shift());
			}
		}
		while (left.length)
			result.push(left.shift());

		while (right.length)
			result.push(right.shift());

		return result;
	}

}


