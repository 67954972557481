import Hierarchy from '@cxstudio/organizations/Hierarchy';
import {HierarchyPublicationState, OrgHierarchyUIStatus} from '@cxstudio/organizations/hierarchy-publication-state';
import {HierarchyMode} from '@cxstudio/organizations/hierarchy-mode';

export class OrganizationUtils {
	static isHierarchyPublishing(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.PUBLISHING;
	}

	static isClassifyingHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.CLASSIFYING;
	}

	static isClassifiedHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.CLASSIFIED;
	}

	static isPublishedHierarchy(hierarchy: Hierarchy): boolean {
		return  hierarchy.publicationState === HierarchyPublicationState.PUBLISHED;
	}

	static isUnsynchronizedHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.NOT_SYNCHRONIZED;
	}

	static isUnpublishedHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.NOT_PUBLISHED
			|| hierarchy.publicationState === HierarchyPublicationState.REPLACED;
	}

	static structureFileUploaded(hierarchy: Hierarchy): string {
		return hierarchy.hasUpdate ? hierarchy.updateStructureFileName : hierarchy.structureFileName;
	}

	static filtersFileUploaded(hierarchy: Hierarchy): string {
		return hierarchy.hasUpdate ? hierarchy.updateFilterFileName : hierarchy.filterFileName;
	}

	static usersFileUploaded(hierarchy: Hierarchy): string {
		return hierarchy.hasUpdate ? hierarchy.updateUserFileName : hierarchy.userFileName;
	}

	static enrichmentsFileUploaded(hierarchy: Hierarchy): string {
		return hierarchy.hasUpdate ? hierarchy.updateEnrichmentFileName : hierarchy.enrichmentFileName;
	}

	static isFileWizardUploadComplete(hierarchy: Hierarchy): boolean {
		if (hierarchy.hasUpdate) {
			return hierarchy.hasUpdateReady;
		}
		return !!hierarchy.structureFileName && !!hierarchy.filterFileName && !!hierarchy.userFileName;
	}

	static allFileUploaded(hierarchy: Hierarchy): boolean {
		return !!(hierarchy.structureFileName && hierarchy.filterFileName && hierarchy.userFileName);
	}

	static isHierarchyStructureUploaded(hierarchy: Hierarchy): boolean {
		return !!hierarchy.structureFileName;
	}

	static isHierarchyFiltersUploaded(hierarchy: Hierarchy): boolean {
		return !!hierarchy.filterFileName;
	}

	static isDynamicFilteringHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING;
	}

	static isActiveAllowed(hierarchy: Hierarchy): boolean {
		return hierarchy.active || OrganizationUtils.allFileUploaded(hierarchy);
	}

	static isHierarchyClassifying(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.CLASSIFYING;
	}
	static isHierarchyUnpublishing(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.UNPUBLISHING;
	}

	static isReplacementFailedHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.REPLACEMENT_FAILED;
	}

	static isUnpublishingHierarchy(hierarchy: Hierarchy): boolean {
		return hierarchy.publicationState === HierarchyPublicationState.UNPUBLISHING;
	}

	static getOrgHierarchyUIStatus(hierarchy: Hierarchy): OrgHierarchyUIStatus {
		if (OrganizationUtils.isHierarchyPublishing(hierarchy) || OrganizationUtils.isClassifyingHierarchy(hierarchy)) {
			return OrgHierarchyUIStatus.PROCESSING;
		} else if (OrganizationUtils.isClassifiedHierarchy(hierarchy)|| OrganizationUtils.isPublishedHierarchy(hierarchy)) {
			return OrgHierarchyUIStatus.READY_PUBLISHED;
		} else if (OrganizationUtils.isUnsynchronizedHierarchy(hierarchy)) {
			return OrgHierarchyUIStatus.READY;
			// ready-unpublished = all files uploaded
		} else if (OrganizationUtils.isUnpublishedHierarchy(hierarchy) && OrganizationUtils.structureFileUploaded(hierarchy)
			&& OrganizationUtils.filtersFileUploaded(hierarchy) && OrganizationUtils.usersFileUploaded(hierarchy)) {
			return OrgHierarchyUIStatus.READY_UNPUBLISHED;
		} else if (!OrganizationUtils.isFileWizardUploadComplete(hierarchy)) {
			return OrgHierarchyUIStatus.NOT_READY;
		}
		return OrgHierarchyUIStatus.NOT_READY; // should this be the default in case we miss something?
	}
}
