import { Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'peer-report-warning',
	template:
`<aside class="row widget-warning-row">
	<p [i18n]="'widget.widgetPeerReportPrompt'" class="mb-0"></p>
</aside>`,
})

export class PeerReportWarningComponent { }

app.directive('peerReportWarning', downgradeComponent({component: PeerReportWarningComponent}));
