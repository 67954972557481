import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { ContentProviderId } from '@cxstudio/generic-types';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { CBProject } from './cb-project';

export interface ICBAccount {
	getProjects(): CBProject[];
}

app.factory('CBAccount', ($q, $log, $timeout, contentProviderOptionsService: ContentProviderOptionsService) => {
	return class implements ICBAccount {
		cpId: number;
		accountId: number;
		accountName: string;
		projectPromise: any;

		constructor(cpId: ContentProviderId, accountObject: ContentProviderAccount) {
			this.cpId = cpId;
			this.accountId = accountObject.accountId;
			this.accountName = accountObject.accountName;

			this.projectPromise = null;
		}

		getProjects = () => {
			if (!this.projectPromise) {
				let defer = $q.defer();
				this.projectPromise = defer.promise;

				contentProviderOptionsService.getOptions(this.cpId, 'projects', {
					contentProviderId: this.cpId,
					accountId: this.accountId
				}, true)
					.then((response) => {
						let projects = response && response.data;
						defer.resolve(_.map(projects, (project) => {
							return new CBProject(this.cpId, this.accountId, project);
						}));
					}, (reason) => {
						$log.warn(reason);
						defer.resolve([]);
						$timeout(() => { // reset this value in 1 sec to avoid multiple error messages
							this.projectPromise = null;
						}, 1000);
					});
			}
			return this.projectPromise;
		}
	};
});
