import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef, Inject } from '@angular/core';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { IAssetPropertiesComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/asset-properties-component.interface';
import { TemplatePlaceholderReplacement } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-replacement';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { SupportWorkspaceProject } from '@app/modules/units/workspace-project/support-workspace';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { CxWizardComponent } from '@app/modules/wizard/cx-wizard/cx-wizard.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplatePlaceholderWithData } from '@app/modules/unified-templates/common-templates/dto/template-placeholder';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { PlaceholderDisplayType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { FilterReplacement } from '@app/modules/unified-templates/common-templates/dto/template-replacement-data';
import { TemplateAssetType } from '../../dto/template-asset-type';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export interface AssetCreateFromTemplateData {
	modalTitle: string;
	template: UnifiedTemplate;
	placeholders: TemplatePlaceholderWithData[];
	propertiesComponent: Type<IAssetPropertiesComponent<SupportWorkspaceProject>>;
	replacementsLabel: string;
}

@Component({
	selector: 'create-from-template-dialog',
	templateUrl: './create-from-template-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFromTemplateDialogComponent implements OnInit, AfterViewInit, OnDestroy,
	IModalComponent<AssetCreateFromTemplateData> {

	@Input() input: AssetCreateFromTemplateData;

	@ViewChild(CxWizardComponent, {static: false}) wizard: CxWizardComponent;
	@ViewChild('propertiesStep', {static: false}) propertiesStep: CxWizardStepComponent;
	@ViewChild('replacementsStep', {static: false}) replacementsStep?: CxWizardStepComponent;
	@ViewChild('assetPropertiesContainer', { read: ViewContainerRef }) assetPropertiesContainer: ViewContainerRef;

	loading: Promise<any>;

	propertiesComponent: ComponentRef<IAssetPropertiesComponent<SupportWorkspaceProject>>;
	workspaceProject: WorkspaceProject;
	replacements: TemplatePlaceholderReplacement[];

	constructor(
		private ref: ChangeDetectorRef,
		private resolver: ComponentFactoryResolver,
		private modal: NgbActiveModal,
		private assetTemplateApi: AssetTemplateApiService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('filterValidationService') private readonly filterValidationService: FilterValidationService,
	) { }

	ngOnInit(): void {
		this.initPlaceholders();
	}

	ngAfterViewInit(): void {
		this.propertiesStep.setValid(false);
		this.replacementsStep?.setValid(false);
		this.createComponent(this.input.propertiesComponent);
	}

	onProjectChange(project: WorkspaceProject): void {
		this.workspaceProject = project;
		this.initPlaceholders();
		this.ref.markForCheck();
	}

	onPropertiesValidityChange(valid: boolean): void {
		this.propertiesStep.setValid(valid);
		this.ref.markForCheck();
	}

	createComponent(component: Type<IAssetPropertiesComponent<SupportWorkspaceProject>>) {
		this.assetPropertiesContainer.clear();

		let factory = this.resolver.resolveComponentFactory(component);
		this.propertiesComponent = this.assetPropertiesContainer.createComponent(factory);
		this.propertiesComponent.instance.projectChange.subscribe(event => this.onProjectChange(event));
		this.propertiesComponent.instance.validityChange.subscribe(event => this.onPropertiesValidityChange(event));
		this.propertiesComponent.instance.initTemplate(this.input.template);
		setTimeout(() => this.ref.markForCheck());
	}

	private initPlaceholders(): void {
		this.replacements = this.input.placeholders.map(placeholder => ({
			placeholderKey: placeholder.key,
			placeholderDisplayName: placeholder.originalDisplayName,
			placeholderDescription: placeholder.description,
			displayType: placeholder.displayType,
			replacementData: placeholder.initialData,
			locations: placeholder.locations,
		}));
	}

	isProjectSelected(): boolean {
		return WorkspaceProjectUtils.isProjectSelected(this.workspaceProject);
	}

	validateReplacements(): void {
		let allReplacementsValid = _.all(this.replacements, replacement => !!replacement.replacementData);
		let rulesValid = _.chain(this.replacements)
			.filter({displayType: PlaceholderDisplayType.FILTER_RULE})
			.filter(replacement => !!replacement.replacementData)
			.pluck('replacementData')
			.all((filterReplacement: FilterReplacement) =>
				this.filterValidationService.validateFilterRuleDefinition(filterReplacement.filterRule) === true)
			.value();

		this.replacementsStep.setValid(allReplacementsValid && rulesValid);
	}

	save(): void {
		if (!this.propertiesComponent.instance.isValid()) {
			this.wizard.activateStep(this.propertiesStep);
			return;
		}
		let properties = this.propertiesComponent.instance.getAsset();
		this.loading = this.assetTemplateApi.createFromTemplate(this.input.template.assetType, this.input.template.id,
			{properties, replacements: this.replacements})
		.then(asset => {
			if (this.input.template.assetType === TemplateAssetType.DASHBOARD) {
				this.dashboardService.trackDashboardCreation(asset as unknown as Dashboard, this.input.template);
			}
			return asset;
		})
		.then(asset => this.modal.close(asset));
	}

	cancel(): void {
		this.modal.dismiss();
	}

	ngOnDestroy() {
		this.propertiesComponent?.destroy();
	}

}
