import { ConversationDataPoint } from '../conversation-data-point.class';
import { SecondaryTrackConfig } from './secondary-track-config.class';

export class DiamondGenerator {
	static draw(dataArray: ConversationDataPoint[], shapeConfig: SecondaryTrackConfig, targetSvg): void {
		let centerX: number = shapeConfig.leftBoundary + (shapeConfig.trackWidth / 2);
		
		let pointsGenerator = DiamondGenerator.getPointsGenerator(centerX, shapeConfig.shapeWidth);

		targetSvg.selectAll('polygon')
			.data(dataArray)
			.enter()
			.append('polygon')
			.attr('class', shapeConfig.getPointClass)
			.attr('points', pointsGenerator)
			.on('mouseover', shapeConfig.mouseover)
			.on('mouseout', shapeConfig.mouseout)
			.on('click', shapeConfig.click);
	}

	private static getPointsGenerator = (centerX: number, width: number): (dataPoint: ConversationDataPoint) => string => {
		return (dataPoint: ConversationDataPoint): string => {
			let centerY: number = dataPoint.yPosition + (dataPoint.height / 2);
			let pointOffset: number = width / 2;

			let top: string = `${centerX},${centerY - pointOffset}`;
			let bottom: string = `${centerX},${centerY + pointOffset}`;
			let left: string = `${centerX - pointOffset},${centerY}`;
			let right: string = `${centerX + pointOffset},${centerY}`;
			
			return `${top} ${right} ${bottom} ${left}`;
		};
	}
}