<div *ngIf="hasErrors()">
	<div *ngIf="errors.noAccounts">
		<alert type="danger" text="{{'metrics.noAccounts'|i18n}}"></alert>
	</div>
	<div *ngIf="errors.noProjects">
		<alert type="danger" text="{{'metrics.noProjects'|i18n}}"></alert>
	</div>
	<div *ngIf="errors.tooManyProjects">
		<alert type="danger" text="{{getTooManyProjectsMessage()}}"></alert>
	</div>
	<div *ngIf="errors.noProjectSelected">
		<alert type="danger" text="{{'metrics.pleaseSelectAProject'|i18n}}"></alert>
	</div>
	<div *ngIf="errors.noProjectAttributes && !errors.noProjectSelected">
		<alert type="danger" text="{{'metrics.projectHasNoAttributes'|i18n}}"></alert>
	</div>
	<div *ngFor="let message of errors.messages">
		<alert type="danger" text="{{ message }}"></alert>
	</div>
</div>
