import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef,
	Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'color-dropdown',
	template: `
	<div class="d-flex h-100-percent w-100-percent"
		ngbDropdown
		#ngDropdown="ngbDropdown"
		container="body"
		(click)="toggleDropdown($event)">
		<button type="button"
			class="d-flex flex-fill no-background no-border p-0 kb-focusable"
			[disabled]="disabled || !customToggle.hasChildNodes()"
			#customToggle
			ngbDropdownAnchor>
			<ng-content></ng-content>
		</button>
		<ng-container *ngIf="!customToggle.hasChildNodes()">
			<button
				type="button"
				class="h-100-percent w-100-percent no-border p-0 kb-focusable"
				[disabled]="disabled"
				[style.background]="value">
			</button>
		</ng-container>
		<div ngbDropdownMenu class="p-0 cursor-default"
			(openChange)="!$event && dropdownClose.emit()"
			(click)="dropdownClick.emit($event)">
			<responsive-color-picker *ngIf="ngDropdown.isOpen()"
				[clickLocation]="clickLocation"
				[(color)]="value"
				(colorChange)="onChangeCallback($event); ngDropdown.close()"
				[showClear]="showClear"
				[pinnedPalette]="pinnedPalette"
				(clear)="clearAndClose()"
				(cancel)="ngDropdown.close()">
			</responsive-color-picker>
		</div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorDropdownComponent), multi: true}
	]
})
export class ColorDropdownComponent implements ControlValueAccessor, OnInit {
	value: string;
	disabled: boolean;
	@Input() showClear: boolean;
	@Input() pinnedPalette?: string;
	@Output() clear = new EventEmitter<void>();
	@Output() dropdownClick = new EventEmitter<MouseEvent>();
	@Output() dropdownClose = new EventEmitter<void>();

	@ViewChild('ngDropdown', {static: false}) ngDropdown: NgbDropdown;

	clickLocation: {x: number, y: number};

	private onTouchedCallback: (val: string) => void = _.noop;
	onChangeCallback: (val: string) => void = _.noop;

	constructor(private ref: ChangeDetectorRef,
		@Inject('widgetsEditService') private widgetsEditService: WidgetsEditService,
		private currentObjects: CurrentObjectsService
	) {}

	ngOnInit(): void {
		if (!this.pinnedPalette && this.currentObjects.isEditMode()) {
			this.pinnedPalette = this.widgetsEditService.getDashboard()?.properties.color;
		}
	}

	toggleDropdown(ev: PointerEvent): void {
		if (!this.disabled) {
			let y = $(ev.target).offset().top + $(ev.target).outerHeight(); // bottom of the target element
			let x = $(ev.target).offset().left;
			this.clickLocation = {x, y };
			this.ngDropdown.toggle();
		}
	}

	isDropdownOpened(): boolean {
		return this.ngDropdown?.isOpen();
	}

	closeDropdown(): void {
		this.ngDropdown.close();
	}

	writeValue(val: string): void {
		if (val !== this.value) {
			this.value = val;
			this.onChangeCallback(this.value);
		}
	}

	registerOnChange(fn: (val: string) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: (val: string) => void): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.ref.markForCheck();
	}

	clearAndClose(): void {
		this.clear.emit();
		this.ngDropdown.close();
	}
}

app.directive('colorDropdown', downgradeComponent({component: ColorDropdownComponent}) as angular.IDirectiveFactory);
