import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { MenuItemDef } from 'ag-grid-community';

@Injectable({
	providedIn: 'root'
})
export class ObjectListMenuService {

	constructor(
		private locale: CxLocaleService,
	) { }

	getAllHiddenOption(filterManager: TableFilterManager): MenuItemDef {
		return filterManager.isShowHidden()
			? this.getHideAllHiddenOption(filterManager)
			: this.getShowAllHiddenOption(filterManager);
	}

	private getShowAllHiddenOption(filterManager: TableFilterManager): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.showAllHidden'),
			action: () => {
				filterManager.setShowHidden(true);
			}
		};
	}

	private getHideAllHiddenOption(filterManager: TableFilterManager): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.hideAllHidden'),
			action: () => {
				filterManager.setShowHidden(false);
			}
		};
	}
}
