import { Caches } from '@cxstudio/common/caches';
import CachedHttpFactory from './cached-http.factory';
import { EnvironmentService } from '@cxstudio/services/environment-service';

export class CachedHttpService {

	private httpCaches: any;
	private throttledInvalideFn: () => void;

	constructor(
		private $http: ng.IHttpService,
		private $q: ng.IQService,
		private $cacheFactory: ng.ICacheFactoryService,
		private $httpParamSerializer: ng.IHttpParamSerializer,
		private environmentService: EnvironmentService,
	) {
		this.httpCaches = {};


	}

	cache = (cacheName: Caches): CachedHttpFactory => {

		if (!this.httpCaches[cacheName])
			this.httpCaches[cacheName] = this.$cacheFactory(cacheName);

		return new CachedHttpFactory(this.httpCaches[cacheName], this.$http, this.$httpParamSerializer, this.$q);
	}

	private ensureInvalidateFn(): void {
		if (this.throttledInvalideFn)
			return;
		const INVALIDATION_INTERVAL = Number(CONFIG.ui?.cacheInvalidationSeconds || 300) * 1000;
		this.throttledInvalideFn = this.environmentService.isUnderTest()
			? this.invalidateCaches
			: _.throttle(this.invalidateCaches, INVALIDATION_INTERVAL, {leading: false});
	}

	invalidateDashboardCache(): void {
		this.ensureInvalidateFn();
		this.throttledInvalideFn();
	}

	private invalidateCaches = () => {
		this.cache(Caches.FILTERS).invalidate();
		this.cache(Caches.METRICS).invalidate();
		this.cache(Caches.ATTRIBUTES).invalidate();
		this.cache(Caches.PALETTES).invalidate();
		this.cache(Caches.SPINE_SETTINGS).invalidate();
	}
}

app.service('cachedHttpService', CachedHttpService);
