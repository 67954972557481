import { HighchartsUtilsService } from './highcharts-utils.service';

export class HighchartsSeriesPropertiesService {

	constructor(
		private readonly highchartsUtils: HighchartsUtilsService
	) {	}

	applyZIndex = (type): (series) => void => {
		let serieZIndex = this.highchartsUtils.getZIndexByChartType(type);
		return (serie) => {
			if (serieZIndex) {
				serie.zIndex = serieZIndex;
			}
		};
	}

	applyChartType = (type): (series) => void => {
		return (serie) => {
			serie.type = type && type.toLowerCase();
		};
	}

	applyColor = (color): (series, index) => void => {
		return (serie, index: number) => {
			serie.color = color ? color(null, index) : null;
		};
	}

	applyBubbleMarker = (): (series) => void => {
		return (serie) => {
			serie.marker = {
				lineWidth: 5,
				fillOpacity: 0.3
			};
		};
	}

	applyId = (): (series, index) => void => {
		return (serie, index: number) => {
			serie.id = '' + index;
		};
	}
}

app.service('highchartsSerieProperties', HighchartsSeriesPropertiesService);
