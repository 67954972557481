import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';


@Component({
	selector: 'hierarchy-group',
	template: `
		<ul class="node-hierarchy" [ngClass]="{'mixed-nodes': isMixedNodes()}">
			<hierarchy-node 
				*ngFor="let child of nodes; last as isLast"
				[showContext]="showContext" 
				[groupLevel]="groupLevel" 
				[node]="child" 
				[selectedNode]="selectedNode" 
				(nodeClick)="onNodeClick($event)"
				[displayAttribute]="displayAttribute" 
				[isLast]="isLast" 
				[personalization]="personalization">
			</hierarchy-node>
		</ul>`,
	changeDetection: ChangeDetectionStrategy.Default
})
export class HierarchyGroupComponent implements OnInit {
	@Input() nodes: any[];
	@Input() selectedNode: any;
	@Input() displayAttribute: string;
	@Input() groupLevel: number;
	@Input() personalization: any;
	@Input() showContext: boolean;
	@Output() nodeClick = new EventEmitter<IHierarchyNode>();

	constructor(

	) {}

	ngOnInit(): void {
		
	}

	isMixedNodes = () => {
		let hasNonLeaf = _.any(this.nodes, (node) => !node.leaf);
		let hasLeaf = _.any(this.nodes, (node) => node.leaf);

		return hasNonLeaf && hasLeaf;
	}

	onNodeClick = (node: IHierarchyNode): void => {
		this.nodeClick.emit(node);
	}
}