import { NgModule } from '@angular/core';
import { AlertTriggerCriteriaOptionsService } from '@app/modules/alert/services/alert-trigger-criteria-options.service';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';
import { ReportGroupingModule } from '@app/modules/widget-settings/grouping/report-grouping.module';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { DialogModule } from '../dialog/dialog.module';
import { AlertAssigneeSelectorComponent } from './alert-assignee-selector.component';
import { AlertEditorModalComponent } from './alert-editor-modal/alert-editor-modal.component';
import { AlertCriteriaSettingsComponent } from './alert-wizard-page/alert-criteria-settings/alert-criteria-settings.component';
import { AlertGeneralSettingsComponent } from './alert-wizard-page/alert-general-settings/alert-general-settings.component';
import { AlertWizardPageComponent } from './alert-wizard-page/alert-wizard-page.component';
import { AlertService } from './alert.service';
import { OptOutsModalComponent } from './opt-outs-modal/opt-outs-modal.component';
import { DateFiltersModule } from '@app/modules/widget-settings/date-filters/date-filters.module';
import { PushButtonsModule } from '@clarabridge/unified-angular-components';
import { AlertScopeSettingsComponent } from './alert-wizard-page/alert-scope-settings/alert-scope-settings.component';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { AlertActionsSettingsComponent } from './alert-wizard-page/alert-actions-settings/alert-actions-settings.component';
import { AlertSharingSettingsComponent } from './alert-wizard-page/alert-sharing-settings/alert-sharing-settings.component';
import { AlertNotificationUpgrade } from '@app/modules/alert/alert-wizard-page/alert-actions-settings/alert-notification-settings/alert-notification.upgrade.directive';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import AlertGridDefinition from '@app/modules/alert/services/alert-grid-definition.service';
import StudioAlertGridDefinition from '@app/modules/alert/services/studio-alert-grid-definition.service';
import { AlertSettingsService } from '@app/modules/alert/alert-settings.service';
import { StudioAlertServiceFactory } from '@app/modules/alert/services/studio-alert-service-factory';
import { AlertPreviewService } from './services/alert-preview.service';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		WizardModule,
		I18nModule,
		AssetManagementModule,
		DateFiltersModule,
		ReportGroupingModule,
		PushButtonsModule,
		FilterBuilderModule,
		CxFormModule,
	],
	exports: [
		AlertAssigneeSelectorComponent
	],
	declarations: [
		AlertAssigneeSelectorComponent,
		AlertEditorModalComponent,
		OptOutsModalComponent,
		AlertWizardPageComponent,
		AlertGeneralSettingsComponent,
		AlertCriteriaSettingsComponent,
		AlertScopeSettingsComponent,
		AlertActionsSettingsComponent,
		AlertSharingSettingsComponent,
		AlertNotificationUpgrade,
	],
	providers: [
		AlertService,
		AlertTriggerCriteriaOptionsService,
		AlertGridDefinition,
		StudioAlertGridDefinition,
		AlertSettingsService,
		StudioAlertServiceFactory,
		AlertPreviewService
	]
})

export class AlertModule {}
