
export class IntervalInstance {
	name: string;
	func: () => any;
	delay: number;
	promise: ng.IPromise<any>;
	isActive: boolean;

	constructor(name: string, func: () => any, delay: number) {
		this.name = name;
		this.func = func;
		this.delay = delay;
		this.promise = null;
		this.isActive = false;
	}
}
