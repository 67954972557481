import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { ListOption } from '@app/shared/components/forms/list-option';
import Authorization from '@cxstudio/auth/authorization-service';

@Component({
	selector: 'resource-type-selector',
	template: `
<simple-dropdown
	data-testid="resource-type"
	[options]="resourceTypes"
	[value]="innerValue"
	(valueChange)="onChange($event)"
	[disabled]="disabled"
	[disableSort]="true"
></simple-dropdown>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ResourceTypeSelectorComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTypeSelectorComponent extends BaseControlValueAccessor<ObjectType> implements OnInit {

	resourceTypes: ListOption<ObjectType>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService,
		@Inject('authorization') private authorization: Authorization,
	) {
		super(ref, ObjectType.ATTRIBUTE);
	}

	ngOnInit(): void {
		this.resourceTypes = [{
			value: ObjectType.ATTRIBUTE,
			name: this.locale.getString('common.attributes'),
		}, {
			value: ObjectType.MODEL,
			name: this.locale.getString('common.models'),
		}];

		if (this.isAuthorizedForScorecards()) {
			this.resourceTypes.push({
				value: ObjectType.SCORECARD,
				name: this.locale.getString('scorecards.scorecards'),
			});
		}
	}
	/* STUDIO-284: show_rubrics_in_projects_page should be off by default */
	private isAuthorizedForScorecards(): boolean {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.SHOW_RUBRICS_IN_PROJECTS_PAGE)
			&& this.authorization.hasScorecardsAccess();
	}

}
