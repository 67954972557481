<div class="group-search-result br-alert-select-table">
	<table class="table">
		<thead>
			<tr class="default-font-size">
				<th class="text-center" [colSpan]="'3'">{{'alert.panelName'|i18n}}</th>
				<th class="width-2 table-split"></th>
				<th class="text-center" [colSpan]="getSubscriptionColumnsCount()">{{'alert.subscriptions'|i18n}}</th>
			</tr>
			<tr>
				<th [i18n]="'administration.alertName'"></th>
				<th [i18n]="'common.type'"></th>
				<th [i18n]="'administration.alertModel'"></th>
				<th class="width-2 table-split"></th>
				<th class="width-2 text-center" [i18n]="'administration.subscribe'"></th>
				<th class="width-7 text-center" [i18n]="'alertTemplates.subscriptionTemplateHeader'"></th>
				<th class="width-4 checkbox-column text-center" [i18n]="'administration.sendEmail'"></th>
				<th class="width-5 text-center" *ngIf="isAlertHierarchySelectionEnabled()" [i18n]="'administration.alertHierarchySelectionHeader'"></th>
				<th class="width-3 checkbox-column text-center" *ngIf="isCreateCaseColumnEnabled()" [i18n]="'administration.createCase'"></th>
				<th id="subscriptions-assignee-header" *ngIf="isAssigneeColumnEnabled()">{{ getAssigneeLabel() }}</th>
			</tr>
		</thead>
		<tbody>
			<tr class="br-alert-row" *ngFor="let subscription of getFilteredAlertSubscriptions(); index as $index; trackBy:trackByAlertSubscriptionIndex">
				<ng-template #tipContent>
					<p class="p-8 text-left">
						{{ subscription.alert.contentProviderName }}<br/>
						{{ subscription.alert.accountName }}<br/>
						{{ subscription.alert.projectName }}<br/>
						{{ subscription.alert.owner }}
					</p>
				</ng-template>

				<td class="br-alert-name">{{ subscription.alert.alertName }}</td>
				<td class="br-alert-type">{{ subscription.alert.typeName }}</td>
				<td class="br-alert-model">{{ subscription.alert.modelName }}</td>
				<td class="width-2 table-split"></td>

				<td class="width-2 text-center br-alert-subscription">
					<input type="checkbox"
						[(ngModel)]="subscription.subscribed"
						[disabled]="viewOnly"
						(change)="updateSubscriptionsCount(subscription)">
				</td>

				<td class="width-7 text-center">
					<span class="text-left">
						<search-list
							[dropdown]="true"
							[data]="getTemplateForSubscription(subscription)"
							[multiListOptions]="dropdownTemplates"
							[multi]="true"
							(onNodeSelection)="selectTemplate(subscription, $event.node)"
							(onDropdownClick)="retrieveTemplatesForSubscription(subscription)"
							[nonclickable]="isNotSubscribedOrViewOnly(subscription)"
						></search-list>
					</span>
				</td>

				<td class="width-4 text-center br-alert-email">
					<input type="checkbox"
						[(ngModel)]="subscription.sendEmail"
						[disabled]="isNotSubscribedOrViewOnly(subscription)"
						[checked]="subscription.sendEmail && subscription.subscribed">
				</td>

				<td class="width-5 text-center" *ngIf="isAlertHierarchySelectionEnabled()">
					<div class="br-alert-hierarchy-selection nobr">
						<span class="text-0_75rem pr-4" *ngIf="subscription.subscribed">({{ getHierarchyName(subscription) }})</span>
						<a class="btn-icon"
							(click)="subscription.subscribed && editAlertHierarchySelection(subscription)"
							[attr.disabled]="isNotSubscribedOrViewOnly(subscription) ? 'disabled' : null">
							<i class="q-icon-cog"></i>
						</a>
					</div>
				</td>

				<td *ngIf="isCreateCaseColumnEnabled()" class="width-3 text-center br-alert-create">
					<input type="checkbox"
						[(ngModel)]="subscription.createCase"
						[disabled]="isNotSubscribedOrViewOnly(subscription)"
						[checked]="subscription.subscribed && subscription.createCase"
						(click)="createCaseClicked(subscription)">
				</td>


				<td class="br-alert-assignee" *ngIf="isAssigneeColumnEnabled()">
					<alert-assignee-selector
						*ngIf="isAssigneeVisible(subscription)"
						[assignees]="engagorAssignees"
						[matchFunction]="getDefaultAssigneeFunction(subscription)"
						[nonclickable]="isAssigneeSelectorClickable(subscription)"
						(onSelectAssignee)="selectSubscriptionAssignee(subscription, $event.assignee)"
						(onClearAssignee)="clearAssignee(subscription)">
					</alert-assignee-selector>

					<input *ngIf="subscription.createCase && isHierarchicalCaseSubscription(subscription)"
						type="text"
						[disabled]="true"
						class="form-control alert-assignee-input form-control-scall"
						value="{{'common.organization'|i18n}}">
				</td>
			</tr>
		</tbody>
	</table>
</div>

<alert *ngIf="shouldShowEmailWarning()" type="info" [dismissable]="false" text="{{'sharing.emailBatchWarning'|i18n}}"></alert>
