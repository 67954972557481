import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { BookViewTab } from '@app/modules/book/book-view-tab';
import { SimpleContainerScroller } from '@app/modules/scroll-carousel/scrollers/simple-container-scroller.class';
import { IScrollerApi, IScroller } from '@app/modules/scroll-carousel/scrollers/scroller.interface';

@Component({
	selector: 'book-tabs',
	templateUrl: './book-tabs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookTabsComponent implements OnInit {

	@Input() tabs: BookViewTab[];
	@Output() tabSelect = new EventEmitter<BookViewTab>();
	scroller: IScroller & IScrollerApi;
	selectedTabName = '';

	constructor(private readonly ref: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.scroller = new SimpleContainerScroller(setTimeout);
		if (!_.isEmpty(this.tabs)) {
			if (!this.getCurrentTab())
				this.onSelectTab(this.tabs[0]);
			else this.scrollToTab(this.getCurrentTab());
		}
	}

	private getCurrentTab(): BookViewTab {
		return _.find(this.tabs, {active: true});
	}

	onSelectTab(tab: BookViewTab) {
		let previous = this.getCurrentTab();
		if (previous)
			previous.active = false;
		tab.active = true;
		this.selectedTabName = tab?.name;
		this.tabSelect.emit(tab);
		this.scrollToTab(tab);
	}

	private scrollToTab(tab: BookViewTab): void {
		setTimeout(() => this.scroller.scrollToItem(this.tabs.indexOf(tab)));
	}

	refreshTabs(): void {
		this.ref.markForCheck();
	}
}
