import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { SharingService } from '@cxstudio/sharing/sharing-service.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import * as moment from 'moment';
import * as cloneDeep from 'lodash.clonedeep';
import * as isNil from 'lodash.isnil';
import { ScheduleDistributionService } from '../schedule-distribution.service';
import { ScheduleUtilsService } from '../schedule-utils.service';
import { ScheduledJobTypes } from '@app/modules/schedules/jobs/scheduled-job-types.service';
import { ScheduleApiService } from '@app/modules/schedules/schedule-api-service';
import { ScheduleIdentity } from '@app/modules/schedules/schedule-identity';
import { DashboardScheduleService } from '../../services/scheduling/dashboard-schedule.service';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

export interface ScheduleWizardInput {
	dashboard: Dashboard;
	selectedScheduleIdentity: ScheduleIdentity;
}

@Component({
	selector: 'schedule-wizard',
	templateUrl: './schedule-wizard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleWizardComponent implements OnInit {
	@Input() input: ScheduleWizardInput;

	scheduleLimit = 5;
	dataReady = false;
	schedules = [];
	dateFormat = DateTimeFormat.DEFAULT_DATE;
	summaryList = [];
	valid = [];
	users = [];
	hierarchies: any[];
	subscribers: any[];
	masterScheduleList: any[];
	schedulesChanged: any;
	description: string;
	busy: any;
	numberOfUsers: any;
	updating: any;
	schedulesUpdating: any;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly activeModal: NgbActiveModal,
		private readonly cxDialogService: CxDialogService,
		private readonly scheduleDistributionService: ScheduleDistributionService,
		private readonly scheduleUtilsService: ScheduleUtilsService,
		private scheduleApiService: ScheduleApiService,
		private organizationApiService: OrganizationApiService,
		@Inject('sharingService') private readonly sharingService: SharingService,
		@Inject('scheduledJobTypes') private readonly scheduledJobTypes: ScheduledJobTypes,
		@Inject('dashboardScheduleService') private readonly dashboardScheduleService: DashboardScheduleService
	) { }

	ngOnInit(): void {
		this.busy = this.reloadHierarchies().then(this.initSchedules);
		this.description = `${this.locale.getString('schedule.theDashboard')}${this.input.dashboard.name} ${this.locale.getString('schedule.willBeUpdated')}`;
	}

	reloadHierarchies = () => {
		return this.organizationApiService.getOrganizationList().then((result) => {
				if (result.data.length === 0) {
					this.hierarchies = [];
				} else {
					this.hierarchies = result.data;
				}
			});
	}

	reloadSubscribers = (existingSchedules) => {
		const entities = {
			shareEntities: {
				USER: this.getSharedUsers(existingSchedules),
				GROUP: this.getSharedGroups(existingSchedules)
			}
		};

		return this.loadUsersAndGroups(entities).then((result) => {
			this.subscribers = [];
			this.subscribers.pushAll(result.users);
			this.subscribers.pushAll(result.groups);
		});
	}

	loadUsersAndGroups = (entities): Promise<any> => {
		return entities.shareEntities.USER.length > 0 || entities.shareEntities.GROUP.length > 0
			? this.sharingService.loadUsersAndGroups(entities, undefined) as unknown as Promise<any>
			: Promise.resolve({ users: [], groups: [] });
	}

	getSharedUsers = (existingSchedules) => {
		return this.getSharedEntities(existingSchedules, 'users');
	}

	getSharedGroups = (existingSchedules) => {
		return this.getSharedEntities(existingSchedules, 'groups');
	}

	getSharedEntities = (existingSchedules, field) => {
		existingSchedules = existingSchedules || [];

		return existingSchedules
			.filter((schedule) => schedule.distribution.type === this.scheduleDistributionService.getOptions().SPECIFIC_USERS.type)
			.map((schedule) => schedule.distribution[field])
			.reduce((left, right) => left.concat(right), []);
	}

	// date is returned in millisecond offset format, which means we lose UTC offset
	// have to manually readjust to compensate
	adjustStartDate = (settings) => {
		return this.scheduleUtilsService.adjustDate(settings.startDate, settings.timezoneOffset);
	}

	adjustEndDate = (settings) => {
		return this.scheduleUtilsService.adjustDate(settings.endDate, settings.timezoneOffset);
	}

	initDistribution = (settings) => {
		let distribution = settings.distribution;
		distribution.users.forEach((item) => {
			item._name = item.entity.userEmail;
		});

		distribution.groups.forEach((item) => {
			item._name = item.entity.groupName;
		});

		distribution.subscribers = distribution.users.concat(distribution.groups);

		return distribution;
	}

	initSchedules = () => {
		return (this.scheduleApiService.getDashboardSchedule(this.input.dashboard.id) as unknown as Promise<any>).then((dashboardSchedule) => {
			const existingSchedules = dashboardSchedule.settingsList;
			return this.reloadSubscribers(existingSchedules).then(() => {
				const numberOfUsers = dashboardSchedule.numberOfUsers;

				if (existingSchedules.length > 0) {
					for (const schedule of existingSchedules) {
						let newSchedule = {
							settings: schedule,
							numberOfUsers,
							autoOpen: _.isEqual(schedule.scheduleIdentity, this.input.selectedScheduleIdentity)
						} as any;
						newSchedule.settings.startDate = this.adjustStartDate(schedule); // string to date
						newSchedule.settings.endDate = this.adjustEndDate(schedule);
						newSchedule.settings.distribution = this.initDistribution(schedule);
						this.schedules.push(newSchedule);
						this.summaryList.push('');
						this.valid.push(true);
					}

					this.schedules.forEach((item, index) => {
						item.masterIndex = index;
						item.subscribers = cloneDeep(this.subscribers);
					});

					this.masterScheduleList = cloneDeep(this.schedules);
				}

				this.dataReady = true;
			});
		});
	}

	close = () => {
		if (this.isAnyScheduleChanged()) {
			this.cxDialogService.regularWithConfirm(
				this.locale.getString('schedule.unsavedChangesTitle'), this.locale.getString('schedule.unsavedChangesBody'),
				this.locale.getString('administration.dontsave'), this.locale.getString('common.cancel')
			).result.then(this.closeScheduler, _.noop);
		} else {
			this.closeScheduler();
		}
	}

	closeScheduler = () => {
		if (this.schedulesChanged) {
			this.activeModal.close(this.masterScheduleList);
		} else {
			this.activeModal.dismiss(); // nothing to update
		}
	}

	addSchedule = () => {
		if (this.schedules.length >= this.scheduleLimit) {
			return;
		}

		let subScope = {} as any;
		subScope.settings = {};
		subScope.settings.cronExpression = '0 0 0 ? * 1'; // every week on midnight of Monday

		subScope.subscribers = cloneDeep(this.subscribers);
		subScope.settings.dashboardId = this.input.dashboard.id;
		subScope.settings.startDate = moment().add(10, 'minutes').toDate();

		if (subScope.settings.type !== 'once') {
			subScope.settings.endDate = this.scheduleUtilsService.getScheduleExpirationDate(subScope.settings.startDate);
		}

		subScope.settings.active = true;
		subScope.settings.message = '';
		subScope.settings.notify = false;
		subScope.settings.notifyGroups = false;
		subScope.settings.notifyApp = true;

		// newly added schedule should open automatically
		// this will have no effect in old-style scheduler
		subScope.autoOpen = true;

		subScope.numberOfUsers = this.numberOfUsers;
		this.schedules.push(subScope);
		this.summaryList.push('');
		this.valid.push(true);
	}

	remove = (index) => {
		this.removeFromMasterList(index);
		this.schedules.splice(index, 1);
		this.summaryList.splice(index, 1);
		this.valid.splice(index, 1);
	}

	updateSummary = (index, text) => {
		this.summaryList[index] = text;
	}

	validateFunction = (valid, index) => {
		this.valid[index] = valid;
	}

	getSummary = (index) => {
		return (this.summaryList.length > index && index > -1) ? this.summaryList[index] : '';
	}

	revertSchedule = (index) => {
		if (!this.schedules[index].hasOwnProperty('masterIndex')) {
			this.schedules.splice(index, 1);
			return;
		}

		this.schedules[index] = cloneDeep(this.masterScheduleList[this.schedules[index].masterIndex]);
		this.schedules[index].autoOpen = true;
	}

	removeFromMasterList = (index) => {
		this.masterScheduleList = this.masterScheduleList || [];

		if (this.masterScheduleList.length > 0 && this.schedules[index].masterIndex >= 0) {
			this.masterScheduleList.splice(this.schedules[index].masterIndex, 1);
			const settings = this.schedules[index].settings;
			this.updating = this.dashboardScheduleService.removeSchedule(settings, this.input.dashboard.id);
			this.decrementIndices(index);

			this.schedulesChanged = true;
		}
	}

	// the masterIndex value that tracks the index in the master list needs to be shifted downward when elements are deleted
	// this will get v. nasty if we ever allow customers to reorder schedules :(
	decrementIndices = (afterIndex) => {
		for (let x = afterIndex; x < this.schedules.length; x++) {
			if (this.schedules[x].hasOwnProperty('masterIndex')) {
				this.schedules[x].masterIndex -= 1;
			}
		}

		for (let i = afterIndex; i < this.masterScheduleList.length; i++) {
			if (this.masterScheduleList[i].hasOwnProperty('masterIndex')) {
				this.masterScheduleList[i].masterIndex -= 1;
			}
		}
	}

	saveSchedule = (index) => {
		this.masterScheduleList = this.masterScheduleList || [];

		// update the master list with the modifications, then submit the master list to the API
		if (this.schedules[index].masterIndex >= 0) {
			this.masterScheduleList[this.schedules[index].masterIndex] = cloneDeep(this.schedules[index]);
		} else {
			this.schedules[index].masterIndex = this.masterScheduleList.length;
			this.masterScheduleList.push(cloneDeep(this.schedules[index]));
		}

		// preprocessing should be run on a copy sent to API, so as not to mess with UI
		const apiCopy = cloneDeep(this.masterScheduleList);
		let targetSchedule = apiCopy[this.schedules[index].masterIndex];

		this.schedulesChanged = true;
		this.processScheduleAndSendToAPI(targetSchedule);
	}

	updateMasterSchedule = (index, fields) => {
		const schedule = this.schedules[index];
		let masterSchedule = this.masterScheduleList[schedule.masterIndex];

		fields.forEach((field) => {
			masterSchedule.settings[field] = schedule.settings[field];
		});

		this.schedulesChanged = true;
		return masterSchedule.settings;
	}

	isAnyScheduleChanged = () => {
		for (const schedule of this.schedules) {
			if (this.isScheduleChanged(schedule)) {
				return true;
			}
		}
		return false;
	}

	isScheduleChangedByIndex = (index) => {
		return this.isScheduleChanged(this.schedules[index]);
	}

	isScheduleChanged = (scheduleItem) => {
		// if item has no masterIndex property, it must not have been saved
		if (!scheduleItem.hasOwnProperty('masterIndex')) {
			return true;
		}

		const actionType = this.scheduledJobTypes.findType(scheduleItem.settings.jobType);

		for (const field in scheduleItem.settings) {
			// ignore fields not relevant to the selected action type
			if (actionType.ignoreFields.indexOf(field) > -1) {
				continue;
			}

			if (field === 'endDate') {
				continue;
			}

			// ignore changes to the height and width values if autosizing is enabled
			if ((field === 'height' || field === 'width') && scheduleItem.settings.useAutosizing) {
				continue;
			}

			const newVal = scheduleItem.settings[field];
			const oldVal = this.masterScheduleList[scheduleItem.masterIndex].settings[field];

			if (this.isSchedulePropertyChanged(field, newVal, oldVal)) {
				return true;
			}
		}

		return false;
	}

	subscriptionCompareMap = (subscription) => {
		return _.pick(subscription.entity, 'userId', 'groupId');
	}

	isSchedulePropertyChanged = (field, newVal, oldVal) => {
		// cannot do simple comparison for date objects :(
		if (field === 'startDate') {
			if (newVal.getTime() !== oldVal.getTime()) {
				return true;
			}
		} else if (field === 'scheduleIdentity') {
			if (newVal?.key !== oldVal?.key || newVal?.group !== oldVal?.group) {
				return true;
			}
		} else if (field === 'distribution') {
			const isDistributionUpdated =
				newVal.type !== oldVal.type
					|| newVal.hierarchyId !== oldVal.hierarchyId
					|| !_.isEqual(
						_.map(newVal.subscribers, this.subscriptionCompareMap),
						_.map(oldVal.subscribers, this.subscriptionCompareMap));

			if (isDistributionUpdated) {
				return true;
			}
		} else if (!isNil(newVal) && !isNil(oldVal) && newVal !== oldVal) { // using != to handle null/undefined
			return true;
		}

		return false;
	}

	sendTestEmail = (index) => {
		let testSchedule = cloneDeep(this.schedules[index]);

		testSchedule.settings.testEmail = true;
		testSchedule.settings.active = true;

		// set start date to 1 hour ago, one time recurrence
		testSchedule.settings.startDate = moment().subtract(1, 'minutes').toDate();
		testSchedule.time = this.scheduleUtilsService.getDefaultTimeObject(testSchedule.settings.startDate);
		testSchedule.settings.type = this.scheduleUtilsService.getDefaultSchedulingOptions()[0].value;
		testSchedule.recurrence = this.scheduleUtilsService.getDefaultSchedulingOptions()[0].value;

		const cronProcessingResult = this.scheduleUtilsService.processCron(testSchedule.settings.startDate, testSchedule.settings.startDate,
			testSchedule.settings.type, testSchedule.settings.activeFor, testSchedule.time, testSchedule.recurrence, DateTimeFormat.BASIC_DATE,
			testSchedule.weekDaysOptions);
		testSchedule.settings.cronExpression = cronProcessingResult.cronExpression;

		this.sendTestEmailToAPI(testSchedule);
	}

	processScheduleAndSendToAPI = (schedule) => {
		const preprocessRules = this.scheduledJobTypes.findType(schedule.settings.jobType);
		preprocessRules.apiPreprocessor(schedule.settings, this.input.dashboard);

		if (!isEmpty(schedule.settings.distribution) && !isEmpty(schedule.settings.distribution.type)) {
			const distribution = this.scheduleDistributionService.findType(schedule.settings.distribution.type);
			distribution.apiPreprocessor(schedule.settings);
		}

		this.schedulesUpdating = this.dashboardScheduleService.updateSchedule(schedule.settings, this.input.dashboard.id);

		this.schedulesUpdating.then((response) => {
			schedule.settings.scheduleIdentity = response.data;
			this.updateIdentityInfo(schedule, response.data);
		});
	}

	updateIdentityInfo = (schedule, identityInfo) => {
		const masterIndex = schedule.masterIndex;

		let scopeSchedule = this.findScheduleByMasterIndex(this.schedules, masterIndex);
		let masterSchedule = this.findScheduleByMasterIndex(this.masterScheduleList, masterIndex);

		if (scopeSchedule) {
			scopeSchedule.settings.scheduleIdentity = identityInfo;
		}

		if (masterSchedule) {
			masterSchedule.settings.scheduleIdentity = identityInfo;
		}
	}

	findScheduleByMasterIndex = (list, masterIndex): any => {
		return _.findWhere(list, {masterIndex});
	}

	sendTestEmailToAPI = (testSchedule) => {
		this.masterScheduleList = this.masterScheduleList || [];
		this.processScheduleAndSendToAPI(testSchedule);
	}
}
