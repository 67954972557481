import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { CxLocalEvent, LocalEventBus } from '@app/core/local-event-bus.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Observable } from 'rxjs';

@Directive()
export class WidgetEventBridgeComponent extends SelfCleaningComponent implements OnInit, OnDestroy {

	@Input() eventSource: Observable<CxLocalEvent>; // temporarily until we convert widget-item to angular
	@Output() event = new EventEmitter<CxLocalEvent>(); // temporarily until we convert widget-item to angular
	constructor(private readonly eventBus: LocalEventBus) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.eventSource.subscribe(event => this.eventBus.passThrough(event)));
		this.addSubscription(this.eventBus.subscribeToEmit(event => this.event.emit(event)));
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.eventBus.destroy();
	}
}
