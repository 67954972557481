import { ApplicationTheme } from '@cxstudio/header/application-theme';

export class ScrollIndicatorOptions {
	color: string;
	opacity: number;

	constructor(theme?: ApplicationTheme) {

		let styles = {
			opacity: .2,
			color: 'var(--blue-400)'
		};

		if (theme === ApplicationTheme.DARK) {
			styles.opacity = .25;
		}

		return styles;

	}
}
