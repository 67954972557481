import { OvertalkOptions } from './overtalk-options.class';
import { ConversationDataPoint } from './conversation-data-point.class';
import { ParticipantEnrichments } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { PointClassProviders } from './point-class-providers.class';
import { ParticipantEnrichmentTypes } from './entities/spine-lane.class';
import { ApplicationTheme } from '@cxstudio/header/application-theme';

export class BasicConversationChartOptions {
	rightChannel: string;
	botChannel: string;
	radius: number;			// bubble radius
	bubbleSpacing: number;	// vertical spacing between the bubbles
	padding: number;		// horizontal padding on either side of the bubbles
	channelOffset: number;	// how far the two sides are offset from each other
	minBubbleHeight: number;
	maxBubbleHeight: number | string;
	overtalk: OvertalkOptions;
	width: number;
	style: string;
	pointClass: (data: ConversationDataPoint) => string;
	
	constructor(enrichmentColor: ParticipantEnrichments, primaryColor: string, 
			enrichmentType: ParticipantEnrichmentTypes, theme?: ApplicationTheme) {

		let pointBaseClass = (data: ConversationDataPoint): string => {
			switch (enrichmentType) {
				case ParticipantEnrichmentTypes.SENTIMENT:
					return PointClassProviders.sentiment(enrichmentColor.primary, data);
				case ParticipantEnrichmentTypes.EFFORT:
					return PointClassProviders.effort(enrichmentColor.primary, data);
				case ParticipantEnrichmentTypes.EMOTION:
					return PointClassProviders.emotion(enrichmentColor.primary, data);
				case ParticipantEnrichmentTypes.NONE:
					return PointClassProviders.none(data);
			}
			return PointClassProviders.sentiment(enrichmentColor.primary, data);
		};

		let getColorArray = (): string[] => {
			return enrichmentColor.primary?.getColorArray() || [];
		};

		return {
			rightChannel: 'agent',
			botChannel: 'bot',
			radius: 2,
			bubbleSpacing: 2,
			padding: 14,
			channelOffset: 24,
			minBubbleHeight: 6,
			maxBubbleHeight: '10%',	
			overtalk: new OvertalkOptions(theme),
			width: 104,
			style: ConversationStyleUtils.getConversationChartStyle(getColorArray(), primaryColor, theme, enrichmentType),
			pointClass: data => ConversationStyleUtils.getSpineClass(pointBaseClass(data)),
		};
	}

}