import { SecondaryTrackConfig } from '@cxstudio/conversation/shapes/secondary-track-config.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { TopicSpineLaneDefinition } from '@cxstudio/conversation/entities/spine-lane.class';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { IDocumentClassification, IDocumentClassificationNode } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { EnrichmentIcon, EnrichmentIconType } from '@cxstudio/reports/preview/enrichment-icon.class';


export class TopicTrack extends SecondaryTrackConfig {

	private static hasTopic = (dataPoint: ConversationDataPoint, definition: TopicSpineLaneDefinition): boolean => {
		return _.some(dataPoint?.sentenceTopics,
			(topic: SentenceTopic) => TopicTrack.isTopicIncluded(topic, definition));
	}

	private static isTopicIncluded(topic: SentenceTopic, definition: TopicSpineLaneDefinition): boolean {
		let modelIdMatch = topic.modelId === parseInt(definition.name, 10);
		if (!modelIdMatch)
			return false;

		let levelMatch = (topic.isLeaf && definition.leaf)
			|| (definition.selectedLevel
				? topic.modelIdPath.length === definition.selectedLevel
				: topic.modelIdPath.length === 1);
		if (!levelMatch)
			return false;

		let nodeMatch = _.isEmpty(definition.selectedNodes)
			|| _.contains(definition.selectedNodes, topic.id)
			|| _.intersection(definition.selectedNodes, topic.modelIdPath).length > 0;
		return nodeMatch;
	}

	static generateTopicEnrichment(definition: TopicSpineLaneDefinition, docTopic: IDocumentClassification): ConversationEnrichment {
		let modelId = parseInt(definition.name, 10);
		if (docTopic.modelId !== modelId
			|| _.every(docTopic.nodes,
				(node: IDocumentClassificationNode) => !TopicTrack.isTopicIncluded(node as any, definition))) {
			return null;
		}
		return {
			iconType: definition.icon as SecondaryTrackRendererType,
			enrichmentType: SingleLaneEnrichmentTypes.TOPIC,
			tooltipFormatter: data => data.sentenceTopics.map(topic => TopicTrack.getTooltipItem(definition, topic)).join(''),
			getValue: data => data.sentenceTopics,
			hasValue: data => TopicTrack.hasTopic(data, definition),
			getName: () => docTopic.modelName,
			getHeaderIcon: () => definition.icon === SecondaryTrackRendererType.DIAMOND ? 'q-icon-diamond' : 'q-icon-circle',
			getColorArray: () => [definition.customColor],
			getColorIndex: () => 0 // only solid color
		};
	}

	private static getTooltipItem(definition: TopicSpineLaneDefinition, topic: SentenceTopic): string {
		if (!TopicTrack.isTopicIncluded(topic, definition)) {
			return '';
		}
		const fillColor = definition.customColor;
		const text = topic.name;
		const icon = definition.icon === SecondaryTrackRendererType.DIAMOND ? 'q-icon-diamond' : 'q-icon-circle';

		return new EnrichmentIcon()
			.withDisplayName(text)
			.withTitle(text)
			.withIcon(icon)
			.withColor(fillColor)
			.as(EnrichmentIconType.PILL);
	}

	dataFilter = data => this.enrichment?.hasValue(data);

	getPointClass = (data) => {
		return data.isFiltered ? 'filtered' : '';
	}
}
