import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { Alignment } from '@cxstudio/reports/formatting/alignment.enum';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import * as cloneDeep from 'lodash.clonedeep';
import { GenericFormatterService, IFormatBuilder } from './generic-formatter.service';
import { INumberFormatBuilder } from './number-format-builder.interface';

@Injectable({
	providedIn: 'root',
})
export class NumberFormatterBuilderService implements INumberFormatBuilder {

	defaults: NumberFormatSettings;
	constructor(
		private readonly formatter: GenericFormatterService
	) {
		this.defaults = {
			decimals: Decimals.ONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			conversion: MetricMultiplierType.NONE,
			thousandsDelimiter: ''
		};
	}

	getBuilder(defaultOption): IFormatBuilder {
		let options = $.extend(cloneDeep(this.defaults), defaultOption);
		return this.formatter.getFormatter(options);
	}
}

app.service('numberFormatterBuilder', downgradeInjectable(NumberFormatterBuilderService));
