import { Component } from '@angular/core';

@Component({
	selector: 'loader',
	template: `<div class="loader"></div>`
})
export class LoaderComponent {
	constructor() {}

	/*ngOnInit(): void {
	}*/
}