import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ContentProviderApi } from '@app/modules/system-administration/content-provider/content-provider.api.service';
import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';

interface IContentProviderState {
	id: number;
	name: string;
	cpVersion: string;
	apiVersion: string;
	featureVersion?: string;
	loading: boolean;
}

@Component({
	selector: 'content-provider-version-check',
	templateUrl: './content-provider-version-check.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentProviderVersionCheckComponent implements OnInit {
	readonly CHECK_TIMEOUT: number = 3000;
	contentProviders: IContentProviderState[];
	filter: any = {
		apiVersion: null
	};
	checkEnabled: boolean = true;

	constructor(
		private readonly contentProviderManagementApiService: ContentProviderManagementApiService,
		@Inject('contentProviderApiService') private contentProviderApiService: ContentProviderApi,
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.filter = {
			apiVersion: null
		};
		this.contentProviders = [];

		this.contentProviderManagementApiService.getAllContentProviders().then((result) => {
			let contentProviders = result;
			this.contentProviders = contentProviders.map((contentProvider) => {
				return {
					id: contentProvider.id,
					name: contentProvider.name,
					cpVersion: null,
					apiVersion: null,
					loading: false
				};
			});
			this.ref.markForCheck();
		});
	}

	checkVersions(): void {
		this.disableCheckButton();

		this.contentProviders.forEach((contentProvider) => {
			contentProvider.cpVersion = null;
			contentProvider.apiVersion = null;

			contentProvider.loading = true;
			Promise.all([
				this.contentProviderApiService.getContentProviderVersion(contentProvider.id),
				this.contentProviderApiService.getContentProviderApiVersion(contentProvider.id),
				this.contentProviderApiService.getContentProviderFeatureVersion(contentProvider.id)
			]).then((result: any) => {
				let cpVersion = result[0].cpVersion;
				let apiVersion = result[1].apiVersion;
				let featureVersion = result[2];

				contentProvider.cpVersion = cpVersion || null;
				contentProvider.apiVersion = apiVersion || null;
				contentProvider.featureVersion = featureVersion || null;
				contentProvider.loading = false;
				this.ref.markForCheck();
			}, () => {
				contentProvider.loading = false;
			});
		});

		setTimeout(() => this.enableCheckButton(), this.CHECK_TIMEOUT);
	}

	filterByVersion(contentProvider: IContentProviderState): boolean {
		let apiVersion = this.filter.apiVersion;
		return !apiVersion || !contentProvider.apiVersion
			|| contentProvider.apiVersion.indexOf(apiVersion) > -1;
	}

	filterByVersionNegative(contentProvider: IContentProviderState): boolean {
		return !this.filterByVersion(contentProvider);
	}

	private enableCheckButton(): void {
		this.checkEnabled = true;
		this.ref.markForCheck();
	}

	private disableCheckButton(): void {
		this.checkEnabled = false;
	}

}
