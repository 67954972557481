import ILocale from '@cxstudio/interfaces/locale-interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { CalculationColorUtils } from './calculation-color-utils';

interface IColorType {
	getColorArray: (metric: Metric) => string[];
	getColorFunction: (metric: Metric) => (item: any, index: number) => string;
	getDataForLegendItems: (metric: Metric) => any[];
	getCategoryFunction: (metric: Metric) => (item: any) => string;
}

export class CalculationColorService {

	readonly SENTIMENT3 = CalculationColorUtils.CALCULATION_COLOR_PREFIX + PredefinedMetricConstants.SENTIMENT_3;
	readonly SENTIMENT5 = CalculationColorUtils.CALCULATION_COLOR_PREFIX + PredefinedMetricConstants.SENTIMENT_5;

	constructor(
		private CalculationColorType, 
		private locale: ILocale
	) {}

	isCalculationColor(colorName: string): boolean {
		return CalculationColorUtils.isCalculationColor(colorName);
	}

	getCalculationColorAttributeName(colorName: string): string {
		return CalculationColorUtils.getCalculationColorAttributeName(colorName);
	}
	
	getColorArray(colorName: string, metric: Metric): string[] {
		let type = this.getTypeFromColorName(colorName, metric);

		if (type) {
			return type.getColorArray(metric);
		}
		return undefined;
	}


	getColorFunction(colorName: string, metric: Metric): (item: any, index: number) => string {
		let type = this.getTypeFromColorName(colorName, metric);

		if (type) {
			return type.getColorFunction(metric);
		}
		return undefined;
	}

	getLegendArray(colorName: string, metric: Metric): any[] {
		let type = this.getTypeFromColorName(colorName, metric);

		if (type) {
			return type.getDataForLegendItems(metric);
		}
		return undefined;
	}

	getCategoryFunction(colorName: string, metric: Metric): (item) => string {
		let type = this.getTypeFromColorName(colorName, metric);

		if (type) {
			return type.getCategoryFunction(metric);
		}
		return undefined;
	}


	getCalculationColorOptions(selectedMetrics: ReportCalculation[]): any[] {
		let options = [];
		if (selectedMetrics && !selectedMetrics.isEmpty()) {
			let calculationColorAttributes = selectedMetrics.filter(metric => this.isStudioMetric(metric));
			if (!calculationColorAttributes.isEmpty()) {
				for (let i = 0; i < calculationColorAttributes.length; i++) {
					let item = calculationColorAttributes[i];
					options.push({
						name: CalculationColorUtils.CALCULATION_COLOR_PREFIX + item.name,
						type: 'calculation',
						displayName: item.displayName,
						rawName: item.name,
						order: 24 + i, //order in list, should behind predefined(2)
						colorPalette: item.definition.calculation.colorPalette
					});
				}
			}
		}
		return options;
	}

	getPredefinedColorOptions(metrics: ReportCalculation[]): any[] {
		let options = [];
		if (metrics && !metrics.isEmpty()) {
			for (let i = 0; i < metrics.length; i++) {
				let item = metrics[i];
				let colorType = this.CalculationColorType.typeOf(item.name);
				if (!colorType) continue;
				let colorPalette = colorType.getColorArray(item);
				let displayName = item.displayName;
				if (colorType.rawName === PredefinedMetricConstants.SENTIMENT) {
					// calculation color names are different from group's for sentiment
					displayName = this.locale.getString(
						colorType.name === PredefinedMetricConstants.SENTIMENT_3 ?
							'preview.col3sent' : 'preview.col5sent');
				}
				options.push({
					name: CalculationColorUtils.CALCULATION_COLOR_PREFIX + item.name,
					type: 'calculation',
					displayName,
					rawName: (item as any).rawName,
					order: 20 + i, 
					colorPalette
				});
			}
		}

		return options;
	}

	getMetricByCalculationColor(colorName: string, metrics: Metric[]): Metric {
		let name = CalculationColorUtils.getCalculationColorAttributeName(colorName);
		if (name && !/_studio_metric_/.test(name)) {
			name = this.CalculationColorType.typeOf(name).rawName;
		}

		if (!_.isEmpty(metrics)) {
			return _.findWhere(metrics, {name});
		}
		return undefined;
	}

	private getTypeFromColorName(colorName: string, metric: Metric): IColorType {
		let name = CalculationColorUtils.getCalculationColorAttributeName(colorName);
		if (name && !/_studio_metric_/.test(colorName)) {
			return this.CalculationColorType.typeOf(name);
		} else {
			return metric && this.CalculationColorType.typeOf(metric.definition.type);
		}
	}

	private isStudioMetric(item): boolean {
		return item && item.type === ReportAssetType.METRIC_STUDIO;
	}
}
app.service('calculationColorService', CalculationColorService);
