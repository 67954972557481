<div class="grid-list-tools" [ngBusy]="loading">
	<div class="tools">
		<div class="grid-filters flex-direction-column align-items-start">
			<workspace-project-selector
				[allProjects]="true"
				[(value)]="workspaceProject"
				labelPosition="top"
				[help]="true"
				[inline]="true"
				[hideInternalProjects]="true"
				(valueChange)="onProjectChange($event)"
				(loading)="onProjectsLoading($event)"
				(projectsReady)="projectsLoaded($event.projects)"
			></workspace-project-selector>
		</div>
	</div>
</div>

<div [ngBusy]="loading">
	<item-grid
		class="w-100-percent br-grid"
		[treeData]="calendarSettingsArray"
		[gridOptions]="gridOptions"
		[gridType]="gridType"
		[uiOptions]="{autoHeight: true}"
		[gridChange]="changedItems">
	</item-grid>
</div>
