import * as _ from 'underscore';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { InclusionListSettings } from '../settings.interfaces';


export class InclusionListSettingsGroup implements ISettingsGroup<InclusionListSettings> {

	settings: InclusionListSettings;
	modelTree;
	topicType;
	processSelectedNodes;

	constructor(
		source: InclusionListSettings,
		modelTree
	) {
		this.settings = source
			? _.pick(source, ['selectedLevel', 'selectedNodes'])
			: {} as InclusionListSettings;

		this.modelTree = modelTree;
	}

	postProcessSettings(): InclusionListSettings {
		this.processSelectedNodes();
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = {} as InclusionListSettings;
	}

}
