import * as uib from 'angular-ui-bootstrap';

import { User } from '@cxstudio/user-administration/users/entities/user';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { UserRemoveModes } from '@cxstudio/user-administration/users/removal/user-remove-modes';
import { TransferMode } from '@app/modules/user-administration/transfer/transfer-mode';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import { ITransferDialogParams } from '@app/modules/user-administration/transfer/transfer-dialog.component';
import { TransferDialogService } from '@app/modules/user-administration/transfer/transfer-dialog.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IPromise } from 'angular';

export class UserRemoveModalService {
	
	constructor(
		private locale: ILocale,
		private security: Security,
		private transferDialogService: TransferDialogService,
		private userModificationService: UserModificationApiService,
		private usersGroupsApiService: UsersGroupsApiService,
		private systemAdminApiService: SystemAdminApiService
	) {}

	showUserRemoveModal = (user: User, mode: UserRemoveModes, isSysAdminPage: boolean): uib.IModalInstanceService => {
		let transferMode: TransferMode = this.getTransferMode(mode);
		let note = this.getConfirmationMessage(user, mode);

		let input: ITransferDialogParams = {
			user,
			mode: transferMode,
			note,
			onTransferCompleted: this.getRemoveUserCallback(user, mode) as unknown as () => Promise<void>,
			isSystemAdminPage: isSysAdminPage
		};

		return this.transferDialogService.open(input) as unknown as uib.IModalInstanceService;

	}

	private getTransferMode(mode: UserRemoveModes): TransferMode {
		switch (mode) {
			case UserRemoveModes.SINGLE: return TransferMode.SINGLE;
			case UserRemoveModes.ALL_BUT_DEFAULT: return TransferMode.ALL_BUT_DEFAULT;
			case UserRemoveModes.DISABLE: return TransferMode.ALL;
		}
	}

	private getConfirmationMessage(user: User, mode: UserRemoveModes): string {
		switch (mode) {
			case UserRemoveModes.SINGLE: {
				let currentMasterAccountName = this.security.getCurrentMasterAccount().accountName;
				return this.locale.getString('mAccount.deleteUserNote', 
					{ fname: user.firstName, lname: user.lastName, currentAccountName: currentMasterAccountName});
			}
			case UserRemoveModes.ALL_BUT_DEFAULT: {
				return this.locale.getString('mAccount.deleteUserNoteAllButDefault', {mAccountName: user.defaultMasterAccountName});
			}
			case UserRemoveModes.DISABLE: {
				return this.locale.getString('mAccount.deleteUserNoteAll');
			}
		}
	}

	private getRemoveUserCallback = (user: User, mode: UserRemoveModes): () => IPromise<void> => {
		return () => {
			switch (mode) {
				case (UserRemoveModes.ALL_BUT_DEFAULT): {
					return this.usersGroupsApiService.removeUserFromNonDefaultMasterAccounts([user.userId]) as IPromise<any>;
				}
				case (UserRemoveModes.DISABLE): {
					return this.systemAdminApiService.disableUser(user.userEmail) as IPromise<any>;
				}
				default: {
					return PromiseUtils.old<void>(
						this.userModificationService.removeFromMA([user.userId])
					) as IPromise<void>;
				}
			}
		};
	}
}

app.service('userRemoveModalService', UserRemoveModalService);