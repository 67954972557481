<div class="page-tab-content" [ngBusy]="loading">
	<p [i18n]="'administration.betaFeaturesTabTitle'"></p>

	<div *ngFor="let featureGroup of featureGroups">
		<div *ngIf="featureGroup.features" class="panel panel-default">
			<div class="panel-heading">{{featureGroup.name}}</div>
			<div class="panel-body">
				<table class="table table-bordered sys-admin-beta-features-table"
					attr.aria-label="{{'administration.betaFeaturesListLabel'|i18n}}">
					<tbody>
						<tr *ngFor="let betaFeature of featureGroup.features | orderBy:'label'">
							<td class="beta-feature-name-column">{{getFeatureName(betaFeature)}}</td>
							<td class="globally-unavailable-column">
								<button *ngIf="hasMasterAccountDistribution(betaFeature)"
									class="btn btn-sm pv-0 ph-8 ml-4 btn-danger"
									(click)="makeBetaFeatureGloballyUnavailable(betaFeature)"
									[i18n]="'administration.makeBetaFeatureGloballyUnavailable'">
								</button>
							</td>
							<td class="disable-globally-column">
								<button *ngIf="hasMasterAccountDistribution(betaFeature)"
									class="btn btn-sm pv-0 ph-8 ml-4 btn-danger"
									(click)="disableBetaFeatureGlobally(betaFeature)"
									[i18n]="'administration.disableBetaFeatureGlobally'">
								</button>
							</td>
							<td class="enable-globally-column">
								<button *ngIf="canEnableGlobally(betaFeature, featureGroup)"
									class="btn btn-sm pv-0 ph-8 ml-4 btn-success"
									(click)="enableBetaFeatureGlobally(betaFeature)"
									[i18n]="'administration.enableBetaFeatureGlobally'">
								</button>
							</td>
							<td class="disable-internally-column">
								<button *ngIf="hasInternalDistribution(betaFeature)"
									class="btn btn-sm pv-0 ph-8 ml-4 btn-danger"
									(click)="disableInternally(betaFeature)"
									[i18n]="'administration.disableBetaFeatureInternally'">
								</button>
							</td>
							<td class="enable-internally-column">
								<button *ngIf="hasInternalDistribution(betaFeature)"
									class="btn btn-sm pv-0 ph-8 ml-4 btn-success"
									(click)="enableInternally(betaFeature)"
									[i18n]="'administration.enableBetaFeatureInternally'">
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
