import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { BarWidget } from '@app/modules/widget-settings/entities/widgets/bar-widget.class';
import { DualChartWidget } from '@app/modules/widget-settings/entities/widgets/dual-chart-widget.class';
import { ImageWidgetBuilder } from '@app/modules/widget-settings/entities/widgets/image-widget-builder';
import { MetricWidget } from '@app/modules/widget-settings/entities/widgets/metric-widget.class';
import { PieWidget } from '@app/modules/widget-settings/entities/widgets/pie-widget.class';
import { SelectorWidget } from '@app/modules/widget-settings/entities/widgets/selector-widget.class';
import { TextWidgetBuilder } from '@app/modules/widget-settings/entities/widgets/text-widget-builder';
import { WidgetBuilderService } from '@app/modules/widget-settings/services/widget-builder.service';
import { Security } from '@cxstudio/auth/security-service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { UrlService } from '@cxstudio/common/url-service.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DateAttributeType } from '@cxstudio/reports/attributes/date-attribute-type';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { BackgroundType, BorderOptions, BorderValue, TextVAlign } from '@cxstudio/reports/entities/content-widget-properties';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DEFAULT_DATE_FILTER_MODE } from '@cxstudio/reports/entities/date-filter-mode';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { DefaultMetricConfig } from '@cxstudio/reports/providers/cb/constants/default-metric-config';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { ITableColumn, TableColumnService } from '@cxstudio/reports/providers/cb/services/table-column-service.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { DataOverviewDashboardConfig, WidgetConfiguration } from './data-overview-dashboard-config';
import { DataOverviewWizardOutput } from './data-overview-wizard/data-overview-wizard.component';

@Injectable({
	providedIn: 'root'
})
export class DataOverviewWidgetBuilderService {
	readonly TEXT_PLACEHOLDER = 'placeholder';
	readonly H2_PATTERN = `<h2><span class='font-large-scale'><strong>${this.TEXT_PLACEHOLDER}</strong></span></h2>`;
	readonly H3_PATTERN = `<h3><span class='font-medium-scale'><strong>${this.TEXT_PLACEHOLDER}</strong></span></h3>`;
	readonly H4_PATTERN = `<h4 style='text-align: center'>
								<span class='font-small-scale'><strong>${this.TEXT_PLACEHOLDER}</strong></span></h4>`;

	constructor(
		private locale: CxLocaleService,
		private widgetBuilderService: WidgetBuilderService,
		@Inject('security') private security: Security,
		@Inject('urlService') private urlService: UrlService,
		@Inject('metricConstants') private metricConstants: MetricConstants,
		@Inject('tableColumnService') private tableColumnService: TableColumnService,
		@Inject('timePrimaryGroups') private timePrimaryGroups: TimePrimaryGroupsService,
		@Inject('calculationColorService') private calculationColorService: CalculationColorService,
	) {}

	getWidgetsFromWizardSettings(settings: DataOverviewWizardOutput): Widget[] {
		return [
			...this.getDataOverviewWidgets(settings),
			...this.getKeyMetricsWidgets(settings),
			...this.getDataSegmentsWidgets(settings)
		];
	}

	private getDataOverviewWidgets(settings: DataOverviewWizardOutput): Widget[] {
		const configuration = DataOverviewDashboardConfig.DataOverview;
		let textWidget = this.getTextWidget(configuration.TITLE, this.H2_PATTERN);
		let imageWidget = this.getImageWidget(configuration.LOGO);
		let pieWidget = this.getPieWidget(configuration.SOURCE, settings);
		let lineWidget = this.getLineWidget(configuration.TREND, settings);

		return [textWidget, imageWidget, pieWidget, lineWidget];
	}

	private getKeyMetricsWidgets(settings: DataOverviewWizardOutput): Widget[] {
		const configuration = DataOverviewDashboardConfig.KeyMetrics;
		let selectorWidget = this.getSelectorWidget(configuration.SELECTOR, settings);
		let metricWidget = this.getMetricWidget(configuration.METRIC, settings);

		return [selectorWidget, metricWidget];
	}

	private getDataSegmentsWidgets(settings: DataOverviewWizardOutput): Widget[] {
		const configuration = DataOverviewDashboardConfig.DataSegments;
		let textWidget = this.getTextWidget(configuration.TITLE, this.H3_PATTERN, true);
		let selectorWidget = this.getSelectorWidget(configuration.SELECTOR, settings);

		let segmentWidgets = _.chain(configuration.SEGMENTS)
			.map(segmentConfiguration => {
				let segmentTextWidget = this.getTextWidget(segmentConfiguration.TITLE, this.H4_PATTERN, true);
				let barWidget = this.getBarWidget(segmentConfiguration.BAR, settings);

				return [segmentTextWidget, barWidget];
			})
			.flatten()
			.value();

		return [textWidget, selectorWidget, ...segmentWidgets];
	}

	private getTextWidget(configuration: WidgetConfiguration, pattern: string = '', withBorders: boolean = false): Widget {
		let widgetText = pattern.replace(this.TEXT_PLACEHOLDER, this.locale.getString(configuration.title));
		if (configuration.description) {
			widgetText += this.locale.getString(configuration.description);
		}

		return TextWidgetBuilder.get()
			.withVerticalAlign(TextVAlign.MIDDLE)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withText(widgetText)
			.withBorder(BorderOptions.TOP, withBorders ? BorderValue.ON : BorderValue.OFF)
			.withBorder(BorderOptions.BOTTOM, withBorders ? BorderValue.ON : BorderValue.OFF)
			.withBackground(BackgroundType.NONE)
			.withRunAs(this.security.getEmail())
			.withId(configuration.id);
	}

	private getImageWidget(configuration: WidgetConfiguration): Widget {
		const imageUrl = this.urlService.getInternalImageUrl(configuration.imageName);
		return ImageWidgetBuilder.get()
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withImageUrl(imageUrl)
			.withRunAs(this.security.getEmail());
	}

	private getPieWidget(configuration: WidgetConfiguration, settings: DataOverviewWizardOutput): Widget {
		return PieWidget.get()
			.withCalculation(this.metricConstants.get().VOLUME)
			.withDonutView(true)
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString(configuration.title))
			.withProjectSelection(settings.projectSelection)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withGroupings([settings.grouping])
			.withRunAs(this.security.getEmail())
			.withDateRangeP1({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter);
	}

	private getLineWidget(configuration: WidgetConfiguration, settings: DataOverviewWizardOutput): Widget {
		let calculations = [ this.metricConstants.get().VOLUME ];

		let visualProperties = {
			subChartType: ChartType.SPLINE,
			yAxisAutoMax: true,
			yAxisAutoMin: true,
			showLabels: true,
			showLegend: true,
			points: false,
		} as VisualProperties;

		if (settings.calculation) {
			_.extend(visualProperties, {
				secondaryChartType: ChartType.SPLINE,
				secondaryPoints: false,
				secondaryAxisAutoMax: true,
				secondaryAxisAutoMin: true,
				secondaryCustomColor: '#4E3AE5',
				secondaryColor: ColorPaletteNames.CUSTOM
			});

			calculations.push(settings.calculation);
		}

		let monthGrouping: AttributeGrouping = this.widgetBuilderService.getTimeGrouping(this.timePrimaryGroups.MONTH);
		monthGrouping.dateAttributeType = DateAttributeType.DATE_TIME;

		let widgetName: string = _.map(calculations, calculation => calculation.displayName).join(' and ');

		return DualChartWidget.get()
			.withTimeGrouping(monthGrouping)
			.withCalculations(calculations)
			.withAutoTitle(false)
			.withDisplayName(this.locale.getString(configuration.title, { name: widgetName }))
			.withProjectSelection(settings.projectSelection)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withRunAs(this.security.getEmail())
			.withVisualProperties(visualProperties)
			.withDateRangeP1({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter)
			.withDateRangeP2({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter);
	}

	private getSelectorWidget(configuration: WidgetConfiguration, settings: DataOverviewWizardOutput): Widget {
		return SelectorWidget.get()
			.withSorting(StandardMetricName.ALPHANUMERIC, SortDirection.ASC)
			.withDisplayName(this.locale.getString(configuration.title))
			.withProjectSelection(settings.projectSelection)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withGroupings([settings.grouping])
			.withRunAs(this.security.getEmail())
			.withId(configuration.id)
			.withLinkedWidgets(configuration.linkedWidgets);
	}

	private getMetricWidget(configuration: WidgetConfiguration, settings: DataOverviewWizardOutput): Widget {
		let columns: ITableColumn[] = [
			this.tableColumnService.getMetricCalculationColumn(),
			this.tableColumnService.getMetricCurrentPeriodColumn()
		];

		return MetricWidget.get()
			.withColumns(columns)
			.withCalculations(settings.additionalMetrics)
			.withDisplayName(this.locale.getString(configuration.title))
			.withAutoTitle(false)
			.withProjectSelection(settings.projectSelection)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withRunAs(this.security.getEmail())
			.withId(configuration.id)
			.withDateRangeP1({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter);
	}

	private getBarWidget(configuration: WidgetConfiguration, settings: DataOverviewWizardOutput): Widget {
		let groupingAttribute: any = _.findWhere(settings.widgetAssets.attributes, {name: configuration.grouping});
		_.extend(groupingAttribute, DefaultMetricConfig);

		return BarWidget.get()
			.withLegend(true)
			.withCalculations([this.metricConstants.get().VOLUME, this.metricConstants.get().SENTIMENT])
			.withGrouping(groupingAttribute)
			.withColors(this.calculationColorService.SENTIMENT3)
			.withProjectSelection(settings.projectSelection)
			.withWidth(configuration.width)
			.withHeight(configuration.height)
			.withPosition(configuration.posX, configuration.posY)
			.withRunAs(this.security.getEmail())
			.withId(configuration.id)
			.withDateRangeP1({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter)
			.withDateRangeP2({dateFilterMode: DEFAULT_DATE_FILTER_MODE} as DateFilter);
	}
}