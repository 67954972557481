import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { ObjectListModule } from '@app/modules/object-list/object-list.module';
import { UnitsModule } from '@app/modules/units/units.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { ConsumptionStatisticsTableComponent } from './consumption-statistics-table/consumption-statistics-table.component';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		UnitsModule,
		NgPipesModule,
		ObjectListModule,
		CxFormModule
	],
	declarations: [
		ConsumptionStatisticsTableComponent,
	],
	exports: [
		ConsumptionStatisticsTableComponent,
	],
})
export class ConsumptionTrackerModule { }
