import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import ReportRunService from '@cxstudio/reports/report-run.service';
import {
	ReportUtils
} from '@cxstudio/reports/utils/visualization/report-utils.service';
import WidgetService from '@cxstudio/services/widget-service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { SidebarWidgetEditorService } from '@app/modules/widget-settings/widget-editor/sidebar-widget-editor.service';
import { CxLocaleService } from '@app/core';
import Widget, {
	ContentWidget, ExternalWidget,
	WidgetDisplayType
} from '@cxstudio/dashboards/widgets/widget';
import { ObjectUtils } from '@app/util/object-utils';
import {
	DashboardRunStatus
} from '@cxstudio/dashboards/constants/dashboard-run-status';
import { PromiseUtils } from '@app/util/promise-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})
export class WidgetSettingsModalService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly dashboardRunHelperService: DashboardRunHelperService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly widgetDescriptionService: WidgetDescriptionService,
		private readonly sidebarWidgetEditorService: SidebarWidgetEditorService,
		private readonly cxDialogService: CxDialogService,
		@Inject('security') private readonly security: Security,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('reportRunService') private readonly reportRunService: ReportRunService,
		@Inject('reportUtils') private readonly reportUtils: ReportUtils,
		@Inject('widgetService') private readonly widgetService: WidgetService,
		@Inject('customFilterService') private readonly customFilterService: CustomFilterService
	) {
	}

	openSettings(widget: Widget | ContentWidget): Promise<Widget> {
		const widgetCopy = ObjectUtils.copy(widget);

		if (widget.properties.runAs !== this.security.getEmail()) {
			return this.cxDialogService.regularWithConfirm(
				this.locale.getString('widget.editWidget'),
				this.locale.getString('widget.editedWidgetMayLookDiff')
			).result.then(() => {
				widgetCopy.properties.runAs = this.security.getEmail();
				return this.openSettingsModal(widgetCopy);
			});
		}

		return this.openSettingsModal(widgetCopy);
	}

	private openSettingsModal<T extends Widget>(widget: T): Promise<T> {
		let dialogResult: Promise<any>;

		let sidebarEditorEnabled = widget.name === WidgetType.CLOUD
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.WIDGET_EDIT_OVERHAUL);

		if (sidebarEditorEnabled) {
			dialogResult = this.sidebarWidgetEditorService.open(widget)
				.then(updatedWidget => this.updateReportWidgetProperties(widget, updatedWidget));
		} else {
			dialogResult = widget.type === WidgetDisplayType.CB
				? this.openReportSettings(widget)
				: this.openWidgetSettings(widget as any);
		}

		let stoppedReportsAmount = this.reportRunService.stopAllReports();
		if (stoppedReportsAmount > 0 || !this.dashboardRunHelperService.getRunStatus(widget.dashboardId)) {
			this.reportUtils.clearLoadingWidgets();
			this.dashboardRunHelperService.setRunStatus(widget.dashboardId, DashboardRunStatus.INTERRUPTED_INTERACTION);
		}
		return dialogResult.then((updatedWidget) => this.submitSettings(updatedWidget), () => this.dismissSettings());
	}

	private openReportSettings<T extends Widget>(widget: T): Promise<T> {
		return PromiseUtils.wrap(
			this.cbDialogService
				.showReportSettingsModal(widget)
		).then((updatedWidget: T) => this.updateReportWidgetProperties(widget, updatedWidget));
	}

	private openWidgetSettings<T extends ContentWidget & ExternalWidget>(widget: T): Promise<T> {
		return PromiseUtils.wrap(
			this.cbDialogService
				.showWidgetSettingsModal(widget)
		).then((updatedWidget) => this.updateContentWidgetProperties(widget, updatedWidget));
	}

	private submitSettings<T extends Widget>(result: T): Promise<T> {
		this.reportRunService.rerunStoppedReports();
		return Promise.resolve(result);
	}

	private dismissSettings(): Promise<void> {
		this.reportRunService.rerunStoppedReports();
		return Promise.reject();
	}

	private updateReportWidgetProperties<T extends Widget>(widget: T, updatedWidget: Widget): Promise<T> {
		widget.template = false;
		widget.created = false;

		widget.properties = updatedWidget.properties;

		widget.visualProperties = updatedWidget.visualProperties;

		widget.name = updatedWidget.name;
		widget.displayName = updatedWidget.displayName;
		widget.description = updatedWidget.description;

		const isNewEmbedConfig = !widget.embedConfig
			&& updatedWidget.embedConfig && updatedWidget.embedConfig.enabled;

		if (widget.embedConfig || isNewEmbedConfig) {
			widget.embedConfig = updatedWidget.embedConfig;
		}

		if (widget.name === WidgetType.SELECTOR) {
			widget.properties.isCustomSelectorWidget = this.widgetService.isCustomSelectorWidget(widget);
		}

		this.customFilterService.preprocessWidgetProperties(widget);
		if (updatedWidget.properties.autoDescription) {
			return this.widgetDescriptionService.generateDescription(updatedWidget).then((description) => {
				widget.description = description;
				return widget;
			});
		}

		return Promise.resolve(widget);
	}

	private updateContentWidgetProperties<T extends ContentWidget & ExternalWidget>(
		widget: T,
		updatedWidget: T
	): Promise<T> {
		widget.template = false;
		widget.created = false;

		let imageChanged = false;
		const props = widget.properties;
		const updatedProps = updatedWidget.properties;
		if (props.widgetType === WidgetType.IMAGE
			&& (props.imageName !== updatedProps.imageName
				|| props.imageUrl !== updatedProps.imageUrl)) {
			imageChanged = true;
		}

		widget.properties = updatedProps;

		if (imageChanged) {
			widget.description = '';
			widget.properties.altText = '';
		} else {
			widget.description = updatedWidget.description;
		}

		widget.displayName = updatedWidget.displayName;
		widget.visualProperties = updatedWidget.visualProperties;

		return Promise.resolve(widget);
	}
}

app.service('widgetSettingsModalService', downgradeInjectable(WidgetSettingsModalService));
