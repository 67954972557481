<div [ngBusy]="loadingHierarchy">
	<ng-container *ngIf="!!hierarchyModelTree">
		<div *ngIf="peerReportType === PeerReportType.INHERITED_REPORT">
			<label>{{'widget.level'|i18n}}</label>
			<searchable-hierarchy
				[hideSearch]="true"
				(onNodeClick)="onLevelNodeClick($event.node); $event.$event.stopPropagation()"
				[hierarchyList]="hierarchyModelTree"
				displayProperty="displayName"
				[selectedItem]="selectedLevelNode"
				[folderClickIgnore]="true">
			</searchable-hierarchy>
		</div>
		<div *ngIf="peerReportType === PeerReportType.PARENT_PEER_REPORT">
			<label>{{'widget.inclusionList'|i18n}}</label>
			<searchable-hierarchy
				class="topic-inclusion-list"
				[hideSearch]="true"
				[hierarchyList]="hierarchyModelInclusionTree"
				displayProperty="displayName"
				[folderClickIgnore]="true"
				[ngShowNodeCheckbox]="isShowCheckbox"
				[ngNodeIsChecked]="isInclusionNodeChecked"
				(onNodeClick)="handleInclusionNodeCheck($event.node); $event.$event.stopPropagation()">
			</searchable-hierarchy>
		</div>
	</ng-container>
</div>
