import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { UsersComponent } from '@cxstudio/user-administration/users/users.component';
import { IUserActionsService } from '@cxstudio/user-administration/users/user-actions.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { UserRemoveModes } from '@cxstudio/user-administration/users/removal/user-remove-modes';
import { UserSelectionMethod } from '@cxstudio/user-administration/users/entities/user-selection-method.enum';
import { UserManagementTabMode } from '@cxstudio/user-administration/users/entities/user-management-tab-mode';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';
import { UserUtils } from '@app/modules/user-administration/user-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { LicenseType } from '@cxstudio/common/license-types';
import { UserManagementHelperService } from '@app/modules/user-administration/user-management-helper.service';
import { ObjectUtils } from '@app/util/object-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { LicenseService } from '@app/modules/user-administration/license.service';
import { SystemPermissionsService } from '@app/modules/user-administration/system-permissions/system-permissions.service';


// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('UserContextMenuUtils', function(
	locale: ILocale,
	security: Security,
	UserActionsService,
	impersonateUserService: ImpersonateUserService,
	userManagementHelper: UserManagementHelperService,
	licenseService: LicenseService,
	systemPermissionsService: SystemPermissionsService,
) {
	return class UserContextMenuUtils extends BaseContextMenuUtils {
		private scope: UsersComponent;
		private actionsService: IUserActionsService;

		private readonly MENU_DIVIDER = BaseContextMenuUtils.MENU_DIVIDER;

		private readonly OPTIONS: {[key: string]: ContextMenuItem<User>} = {
			UPDATE_PERMISSIONS_BULK: {text: locale.getString('permission.updatePermissions'), name: 'updatePermissions',
				func: (user: User) => this.actionsService.updatePermissions([user])
			},
			UPDATE_PERMISSIONS_SINGLE: {text: locale.getString('permission.updatePermissions'), name: 'updatePermissions',
				func: (user: User) => this.scope.editUserFunction(user, 1)
			},
			EDIT: {text: locale.getString('administration.edit'), name: 'edit',
				func: (user: User) => this.scope.editUserFunction(user)
			},
			DUPLICATE: {text: locale.getString('administration.duplicate'), name: 'duplicate',
				func: (user: User) => this.scope.duplicateUser(user)
			},
			REMOVE_FROM_MA: {text: locale.getString('administration.removeFromMA'), name: 'removeFromMA',
				func: (user: User) => this.actionsService.openRemoveDialog([user], UserRemoveModes.SINGLE)
			},
			REMOVE_FROM_MULTIPLE_MA: {
				text: locale.getString('administration.removeUserFromMultipleMA'), name: 'removeFromMultipleMA',
				func: (user: User) => this.actionsService.openRemoveDialog([user], UserRemoveModes.ALL_BUT_DEFAULT)
			},
			UPDATE_GROUP_MEMBERSHIP: {
				text: locale.getString('administration.updateUsersGroup'), name: 'updateUsersGroups',
				func: (user: User) => this.actionsService.updateGroupMembership([user])
			},
			UPDATE_GROUP_MEMBERSHIP_SINGLE: {
				text: locale.getString('administration.updateUsersGroup'), name: 'updateUsersGroups',
				func: (user: User) => this.scope.editUserFunction(user, 4)
			},
			UPDATE_DATA_ACCESS: {
				text: locale.getString('administration.updateDataAccess'), name: 'dataAccess',
				func: (user: User) => this.actionsService.updateDataAccess([user])
			},
			UPDATE_DATA_ACCESS_SINGLE: {
				text: locale.getString('administration.updateDataAccess'), name: 'dataAccess',
				func: (user: User) => this.scope.editUserFunction(user, 3)
			},
			RESET_PASSWORD: {
				text: locale.getString('administration.resetPassword'), name: 'resetPassword',
				func: (user: User) => this.actionsService.resetPassword([user])
			},
			IMPERSONATE: {
				text: locale.getString('administration.impersonateUser'),
				name: 'impersonate',
				func: impersonateUserService.impersonateUser
			},
			UPDATE_LICENSE_TYPE: {
				text: locale.getString('administration.updateBulkLicenseType'),
				name: 'updateLicenseType',
				func: (user: User) => this.actionsService.updateLicenseTypes([user])
			},
			UPDATE_CUSTOM_FIELD_BULK: {
				text: locale.getString('administration.updateBulkLabels',
				{customField: security.getCurrentMasterAccount().customField}),
				name: 'updateBulkLabels',
				func: (user: User) => this.actionsService.updateCustomField([user])
			}
		};

		constructor(scope: UsersComponent) {
			super();
			this.scope = scope;
			this.actionsService = new UserActionsService(this.scope);
		}

		getContextMenu(user?: User): any[] {
			if (this.scope.selectedUsers.length > 1) {
				return BaseContextMenuUtils.enforceDividerRules(this.getMultiSelectMenuOptions());
			} else {
				return BaseContextMenuUtils.enforceDividerRules(this.getSingleUserMenuOptions(user));
			}
		}

		hasGroups = (): boolean => {
			return security.has('manage_groups') || security.isUserGroupOwner();
		}

		isVisibleObject(item: any): boolean {
			return true;
		}

		private getMultiSelectMenuOptions(): any[] {
			let items: ContextMenuItem<User>[] = [];
			items.push(this.OPTIONS.UPDATE_PERMISSIONS_BULK);

			if (this.hasGroups()) {
				items.push(this.OPTIONS.UPDATE_GROUP_MEMBERSHIP);
			}
			let selfCustomerAdmin = !!this.scope.selectedUsers.find(
				user => userManagementHelper.isSelfCustomerAdmin(user.userId));

			if (this.scope.getCustomFieldName()) {
				items.push(this.OPTIONS.UPDATE_CUSTOM_FIELD_BULK);
			}

			if (this.scope.noBasicUsers
					&& !selfCustomerAdmin
					&& this.showDataAccess()) {
				items.push(this.OPTIONS.UPDATE_DATA_ACCESS);
			}
			if (this.canUpdateLicenseType()) {
				items.push(this.OPTIONS.UPDATE_LICENSE_TYPE);

			}
			items.push(this.MENU_DIVIDER);
			this.addResetPasswordIfValid(items);
			items.push(this.OPTIONS.REMOVE_FROM_MA);

			return items;
		}

		private getSingleUserMenuOptions(user: User): any[] {
			let items: any[] = [];
			items.push(this.OPTIONS.EDIT);
			items.push(this.MENU_DIVIDER);

			items.push(this.OPTIONS.UPDATE_PERMISSIONS_SINGLE);

			if (this.hasGroups()) {
				items.push(this.OPTIONS.UPDATE_GROUP_MEMBERSHIP_SINGLE);
			}
			if (this.showDataAccess()
					&& user.licenseTypeId !== LicenseType.CX_STUDIO_BASIC
					&& !userManagementHelper.isSelfCustomerAdmin(user.userId)) {
				items.push(this.OPTIONS.UPDATE_DATA_ACCESS_SINGLE);
			}
			items.push(this.MENU_DIVIDER);
			this.addResetPasswordIfValid(items);

			if (this.showDuplicate()) {
				const contractInfo = this.scope.licenseInfo;
				const noLicense = contractInfo.licensingEnabled
					&& !licenseService.getAvailableLicenses(contractInfo, user.licenseTypeId);
				const accountAdminLimit = systemPermissionsService.hasCustomerPermissions() && user.customerAdmin
					? this.scope.customerAdminsCount >= security.getCurrentMasterAccount().maxCustomerAdmins
					: false;

				let duplicate = ObjectUtils.copy(this.OPTIONS.DUPLICATE);
				duplicate.disabled = noLicense || accountAdminLimit;
				if (duplicate.disabled) {
					let disableMessage = accountAdminLimit
						? locale.getString('administration.accountAdminsLimitReached')
						: locale.getString('administration.noLicenseSeats');
					duplicate.disabledMessage = disableMessage;
				}
				items.push(duplicate);
			}

			let removeOptions = [];
			if (!this.userHasPermanentAccess(user)) {
				removeOptions.push(this.OPTIONS.REMOVE_FROM_MA);
			}
			if (UserUtils.isAdmin(user) && !UserUtils.isAdminOne(user)) {
				removeOptions.push(this.OPTIONS.REMOVE_FROM_MULTIPLE_MA);
			}
			if (!removeOptions.isEmpty()) {
				items.push({text: locale.getString('common.remove'), name: 'remove', items: removeOptions});
			}

			if (impersonateUserService.canImpersonateUser(user)) {
				items.push(this.MENU_DIVIDER);
				items.push(this.OPTIONS.IMPERSONATE);
			}

			return items;
		}

		private userHasPermanentAccess(user: User): boolean {
			return user.systemAdmin
				|| (user.liteAdmin && this.scope.mode === UserManagementTabMode.ALL_USERS); //Internal master account
		}

		private showDataAccess(): boolean {
			let isAuthorizedAdminUser = security.isSysAdmin()
				|| security.isLiteAdmin()
				|| security.isAccountOwner()
				|| security.isCustomerAdmin();
			let isAuthorizedUser = security.has('manage_users')
				&& security.has('content_provider')
				&& security.getLicenseTypeId() === LicenseType.ANALYZE;
			return this.scope.selection.method !== UserSelectionMethod.ALL_MATCHING_FILTERS
				&& (isAuthorizedAdminUser || isAuthorizedUser);
		}

		private addResetPasswordIfValid(items: any[]): void {
			if (this.scope.mode !== UserManagementTabMode.CUSTOMER_USERS_ONLY || security.requiresPasswordControlsForMA()) {
				items.push(this.OPTIONS.RESET_PASSWORD);
			}
		}

		private showDuplicate(): boolean {
			let isAuthorizedUser = security.isAdminOrgUser() || security.isCustomerAdmin();
			return this.scope.mode !== UserManagementTabMode.ADMIN_ORG_USERS_ONLY && isAuthorizedUser;
		}

		private canUpdateLicenseType(): boolean {
			return this.scope.updateBulkLicenseAllowed
				&& (security.isSysAdmin() || security.isLiteAdmin() || security.has('manage_users'));
		}

	};

});

