import { Security } from '@cxstudio/auth/security-service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import ILocale from '@cxstudio/interfaces/locale-interface';

export class DashboardListToolsComponent implements ng.IComponentController {
	gridType = GridTypes.DASHBOARD;
	currentUser = this.security.getUser();

	selectionUtils;
	ui;
	getGridMenuItems;

	selectedUser: User;
	selectedUserChange: (params: {$event: User}) => void;

	constructor(
		private security: Security,
		private usersGroupsApiService: UsersGroupsApiService,
		private locale: ILocale
	) {}

	$onInit = () => {
	}

	canChangeUser = (): boolean => this.security.has('account_owner');

	getMasterAccountUsers = (emailSearch) => {
		let queryParams = {
			isPaginated: true,
			currentPage: 1,
			pageSize: 10,
			queryFilters: [{ filterText: emailSearch, filterField: 'userEmail' }],
			sortField: 'userEmail',
			sortDirection: SortDirection.ASC
		};

		return this.usersGroupsApiService.getMasterAccountUsersPaged(queryParams).then(response => response.data);
	}

	onUserChange = (selectedUser) => {
		this.selectionUtils.clearSelections();
		if (this.selectedUserChange)
			this.selectedUserChange(selectedUser);
	}

	updateSelectAllLabel = () => {
		let selected = this.selectionUtils.showSomeSelected() || this.selectionUtils.showAllSelected();
		let label = this.locale.getString(selected ? 'common.deselectAll' : 'common.selectAll');
		$('.slick-header-column.header-checkbox span.slick-column-name').attr('aria-label', label);
	}
}

app.component('dashboardListTools', {
	bindings: {
		ui: '<',
		actionsService: '<',
		selectionUtils: '<',
		permissions: '<',
		isTablet: '<',
		loading: '<',
		isCurrentUserSelected: '<',
		getGridMenuItems: '<',
		gridMode: '=',
		selectedUser: '<',
		selectedUserChange: '&',
	},
	controller: DashboardListToolsComponent,
	templateUrl: 'partials/dashboards/dashboard-list-tools.component.html'
});
