import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CxHttpService } from '@app/core';
import { ContentVisualizationBaseComponent } from '@app/modules/widget-visualizations/content-widgets/content-visualization-base';
import { VideoObject } from '@app/modules/widget-visualizations/content-widgets/video/video-object';
import { VimeoVideo } from '@app/modules/widget-visualizations/content-widgets/video/vimeo-video';
import { YoutubeVideo } from '@app/modules/widget-visualizations/content-widgets/video/youtube-video';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';

@Component({
	selector: 'content-video',
	template: `
		<div class="view-video kb-focusable-border w-100-percent h-100-percent" tabindex="0">
			<iframe *ngIf="!!videoUrl"
				width="100%"
				height="100%"
				[src]="videoUrl"
				[title]="'widget.video' | i18n"
				frameborder="0"
				wmode="Opaque">
			</iframe>
			<img *ngIf="!!imageUrl"
				#imageElement
				width="100%" height="100%"
				[src]="imageUrl">
			<empty-content *ngIf="empty" icon="q-icon-play" class="border-radius-widget-default"></empty-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentVideoComponent extends ContentVisualizationBaseComponent implements OnInit {

	videoUrl: SafeResourceUrl;
	imageUrl: string;
	@ViewChild('imageElement') imageElement: ElementRef<HTMLElement>;

	constructor(
		private readonly cxHttp: CxHttpService,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		readonly ref: ChangeDetectorRef,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
		private readonly sanitizer: DomSanitizer,
	) {
		super(ref, reportUtils);
	}

	ngOnInit(): void {
		this.initVideo(this.widget.properties.videoUrl);
	}

	private initVideo(url: string): void {
		let video = this.getVideo(url || '');
		if (video) {
			if (!this.$rootScope.pdfToken) {
				this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(video.getFullUrl());
				this.markRendered();
			} else {
				video.getThumbnail(this.cxHttp.get).then(img => {
					this.imageUrl = img;
					this.ref.markForCheck();
					setTimeout(() => this.addImageLoadingHandler(this.imageElement?.nativeElement));
				});
			}
		} else {
			this.showEmpty();
		}
	}

	private getVideo(url: string): VideoObject {
		if (this.isYoutube(url))
			return new YoutubeVideo(url);
		if (this.isVimeo(url))
			return new VimeoVideo(url);
	}

	private isYoutube(url: string): boolean {
		return url.indexOf('youtu') >= 0;
	}

	private isVimeo(url: string): boolean {
		return url.indexOf('vimeo') >= 0;
	}
}
