import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nPipe } from '@app/shared/i18n/i18n.pipe';
import { I18nCutPipe } from '@app/shared/i18n/i18nCut.pipe';
import { I18nDirective } from '@app/shared/i18n/i18n.directive';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
	imports: [
		CommonModule,
		NgPipesModule,
	],
	exports: [
		I18nPipe,
		I18nCutPipe,
		I18nDirective,
	],
	declarations: [
		I18nPipe,
		I18nCutPipe,
		I18nDirective,
	]
})

export class I18nModule { }
