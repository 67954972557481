import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { AppliedFilters } from '@cxstudio/reports/utils/applied-filters-factory.service';


@Directive({
	selector: 'filter-applied-dropdown'
})
export class FiltersAppliedDropdownUpgrade extends UpgradeComponent {
	@Input() appliedFilters: AppliedFilters;
	@Input() isDocExplorer: boolean;
	@Input() customAriaLabel: string;
	@Input() textFilter: string;
	// This is only a minimum required for doc explorer

	constructor(elementRef: ElementRef, injector: Injector) {
		super('filterAppliedDropdown', elementRef, injector);
	}
}
