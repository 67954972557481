interface SelectionIndexes {
	anchorIndex: number;
	focusIndex: number;
}

export class ContentEditableHelper {
	constructor(
		private input
	) {}

	getCaretPosition(): number {
		let caretOffset = 0;
		let doc = this.getDocument();
		if (this.isGetSelectionSupported()) {
			let win = this.getWindow();
			let sel = win.getSelection();
			if (sel.rangeCount > 0) {
				let range: Range = win.getSelection().getRangeAt(0);
				let preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(this.input);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				caretOffset = preCaretRange.toString().length;
				preCaretRange.detach();
			}
		} else if (doc.selection && doc.selection.type !== 'Control') {
			let textRange = doc.selection.createRange();
			let preCaretTextRange = doc.body.createTextRange();
			preCaretTextRange.moveToElementText(this.input);
			preCaretTextRange.setEndPoint('EndToEnd', textRange);
			caretOffset = preCaretTextRange.text.length;
		}
		return caretOffset;
	}

	/**
	 * Set the caret to a specific position.
	 * Defaults to positioning at the end of the string
	 */
	setCaretPosition(position: number = (this.input.textContent.length)): void {
		if (position < 0)
			position = 0;

		if (position > this.input.textContent.length)
			position = this.input.textContent.length;
		this.setCaretPositionInternal(position, this.input);
		this.input.focus();
	}

	private setCaretPositionInternal(pos, el): number {
		for (let node of el.childNodes) {
			if (node.nodeType === 3) {
				// text node, need to count characters
				if (node.length >= pos) {
					let range = document.createRange();
					let sel = window.getSelection();
					range.setStart(node, pos);
					range.collapse(true);
					sel.removeAllRanges();
					sel.addRange(range);
					return -1;
				} else {
					// target is not within this node, keep going...
					pos -= node.length;
				}
			} else {
				// node is not text but may have text nodes in it
				pos = this.setCaretPositionInternal(pos, node);
				if (pos === -1) {
					return -1;
				}
			}
		}
		return pos;
	}

	getCurrentSelection(): SelectionIndexes {
		const sel = window.getSelection();
		const textSegments = this.getTextSegments(this.input);
		let anchorIndex = null;
		let focusIndex = null;
		let currentIndex = 0;
		textSegments.forEach(({text, node}) => {
			if (node === sel.anchorNode) {
				anchorIndex = currentIndex + sel.anchorOffset;
			}
			if (node === sel.focusNode) {
				focusIndex = currentIndex + sel.focusOffset;
			}
			currentIndex += text.length;
		});
		return {anchorIndex, focusIndex};
	}

	private getTextSegments(element): any[] {
		const textSegments = [];
		Array.from(element.childNodes).forEach((node: any) => {
			switch (node.nodeType) {
				case Node.TEXT_NODE:
					textSegments.push({text: node.nodeValue, node});
					break;
				case Node.ELEMENT_NODE:
					textSegments.splice(textSegments.length, 0, ...(this.getTextSegments(node)));
					break;
				default:
					throw new Error(`Unexpected node type: ${node.nodeType}`);
			}
		});
		return textSegments;
	}

	restoreSelection(absoluteIndexes: SelectionIndexes) {
		let absoluteAnchorIndex = absoluteIndexes.anchorIndex;
		let absoluteFocusIndex = absoluteIndexes.focusIndex;

		const editor = this.input;
		const sel = window.getSelection();
		const textSegments = this.getTextSegments(editor);
		let anchorNode = editor;
		let anchorIndex = 0;
		let focusNode = editor;
		let focusIndex = 0;
		let currentIndex = 0;
		textSegments.forEach(({text, node}) => {
			const startIndexOfNode = currentIndex;
			const endIndexOfNode = startIndexOfNode + text.length;
			if (startIndexOfNode <= absoluteIndexes.anchorIndex && absoluteAnchorIndex <= endIndexOfNode) {
				anchorNode = node;
				anchorIndex = absoluteAnchorIndex - startIndexOfNode;
			}
			if (startIndexOfNode <= absoluteFocusIndex && absoluteFocusIndex <= endIndexOfNode) {
				focusNode = node;
				focusIndex = absoluteFocusIndex - startIndexOfNode;
			}
			currentIndex += text.length;
		});

		sel.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
	}

	getSelectionNode(): Node {
		return window.getSelection?.().anchorNode;
	}

	selectElementContent(element): void {
		let range = document.createRange();
		range.selectNodeContents(element);
		let sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	}

	isElementContentSelected(element): boolean {
		let range = this.getSelectionRange();
		if (!range || range.collapsed) {
			return false;
		}
		let cac = range.commonAncestorContainer;
		return $(cac).is(element) || $(cac).find(element).length > 0;
	}

	getSuggestionCoordinates(): {x: number, y: number} {
		if (!this.isGetSelectionSupported()) {
			return this.getFallbackSuggestionCoordinates();
		}
		let range = this.getSelectionRange();
		if (range?.getClientRects()) {
			range.collapse(true);
			let rect = range.getClientRects()[0];
			if (rect) {
				let inputEl = $(this.input);
				let parentLeft = inputEl.offset().left;
				let parentTop = inputEl.offset().top;
				let buttonBarHeight = ($('.math-button-bar').get(0) as HTMLElement).offsetHeight;
				return { x: rect.left - parentLeft, y: rect.bottom - parentTop + buttonBarHeight + rect.height };
			}
		}
		return this.getFallbackSuggestionCoordinates();
	}

	private getFallbackSuggestionCoordinates(): {x: number, y: number} {
		let inputEl = this.input;
		let x = inputEl.textContent.length * 6;
		let y = 0;

		if (inputEl.children.length) {
			const EXTRA_PADDING = 8; // a little extra space so the suggestions arent immediately beneath the text
			let parentPadding = parseInt(window.getComputedStyle(this.input).getPropertyValue('padding-top'), 10);
			let lineHeight = inputEl.children[0].offsetHeight;
			y = lineHeight + parentPadding + EXTRA_PADDING;
		} else {
			y = 30;
		}
		return {x, y};
	}

	private getDocument(): any {
		return this.input.ownerDocument || this.input.document;
	}

	private getWindow(): any {
		let doc = this.getDocument();
		return doc.defaultView || doc.parentWindow;
	}

	private isGetSelectionSupported(): boolean {
		let win = this.getWindow();
		return typeof win.getSelection !== 'undefined';
	}

	private getSelectionRange(): Range {
		if (this.isGetSelectionSupported()) {
			let win = this.getWindow();
			let sel = win.getSelection();
			if (sel.rangeCount > 0) {
				let range: Range = win.getSelection().getRangeAt(0);
				return range.cloneRange();
			}
		}
	}
}
