import {Project} from '@cxstudio/user-administration/users/project-access/project-class';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';

export enum AsyncTaskDataType {
	PERMISSIONS_MODIFICATION = 'PERMISSIONS_MODIFICATION',
	USER_GROUPS_MODIFICATION = 'USER_GROUPS_MODIFICATION',
	DATA_ACCESS_MODIFICATION = 'DATA_ACCESS_MODIFICATION',
	LICENSE_TYPE_MODIFICATION = 'LICENSE_TYPE_MODIFICATION',
	GROUP_USERS_MODIFICATION = 'GROUP_USERS_MODIFICATION',
	GROUP_PERMISSIONS_MODIFICATION = 'GROUP_PERMISSIONS_MODIFICATION',
	USER_CREATION = 'USER_CREATION',
	USER_DELETION = 'USER_DELETION'
}

export interface AsyncTaskData {
	userInfo: ShortUserInfoData;
	type: AsyncTaskDataType;
}

export interface IPermissionsModification extends AsyncTaskData {
	addedPermissions: MasterAccountPermission[];
	removedPermissions: MasterAccountPermission[];
}

export interface IUserGroupData {
	id: number;
	name: string;
}

export interface IUserGroupsModification extends AsyncTaskData {
	addedToGroups: IUserGroupData[];
	removedFromGroups: IUserGroupData[];
}

export interface IGroupUserData {
	userId: number;
	userEmail: string;
}

export interface IGroupUsersModification extends AsyncTaskData {
	addedUsers: IGroupUserData[];
	removedUsers: IGroupUserData[];
}

export interface IDataAccessModification extends AsyncTaskData {
	projects: Project[];
}

export interface ILicenseTypeModification extends AsyncTaskData {
	newLicenseType: LicenseTypeItem;
}

export interface IUserDeletionData extends AsyncTaskData {
	candidateEmail?: string;
}

export interface ShortUserInfoData {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
}
