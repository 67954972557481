import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ScorecardInDocView, ScorecardTopicInDocView, ScorecardEmitterParameter } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import { Icon, ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { ScorecardCriteriaSorting } from '@app/modules/scorecards/scorecard-criteria-sort.service';
import { Inject } from '@angular/core';
import { IntelligentScoringPreference } from './explorer-scorecard-sort.component';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { PromiseUtils } from '@app/util/promise-utils';
import { ScorecardRebuttedDocument } from '@cxstudio/projects/scorecards/entities/scorecard-rebutted-document';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ScorecardRebuttalTree } from '@app/modules/scorecards/rebuttal/scorecard-rebuttal-tree.class';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { AmplitudeDocumentType } from '../document-type-utils.class';

@Component({
	selector: 'explorer-scorecard',
	templateUrl: './explorer-scorecard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplorerScorecardComponent implements OnInit {
	@Input() scorecard: ScorecardInDocView;
	@Input() expanded: boolean;
	@Input() favorite: boolean;
	@Input() displayPreference: IntelligentScoringPreference;
	@Input() rebuttalMode: boolean;
	@Input() selectedDocument: number;
	@Input() projectSelection: ProjectIdentifier;
	@Input() isDocExplorer: boolean;
	@Input() documentType: AmplitudeDocumentType;
	@Output() onCollapse = new EventEmitter<boolean>();
	@Output() onTopicClick = new EventEmitter<ScorecardEmitterParameter>();
	@Output() onFavoriteToggle = new EventEmitter<boolean>();
	@Output() onDisplayPreferenceChange = new EventEmitter<IntelligentScoringPreference>();

	rebuttedIcon: Icon;

	defaultTopicOrder: ScorecardTopicInDocView[];
	noResults: boolean;

	scorecardRebuttalAllowed: boolean;
	scorecardRebuttalTree: ScorecardRebuttalTree;

	presenceOptions: ListOption<ScorecardTopicResult>[];

	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private scorecardSorting: ScorecardCriteriaSorting,
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService) {
	}

	ngOnInit(): void {
		this.rebuttedIcon = ScorecardDocExplorerUtils.getRebuttedIcon();
		this.defaultTopicOrder = [...this.scorecard.topics];

		this.presenceOptions = [{
			value: ScorecardTopicResult.PASSED,
			name: this.locale.getString('scorecards.passed')
		}, {
			value: ScorecardTopicResult.FAILED,
			name: this.locale.getString('scorecards.failed')
		}, {
			value: ScorecardTopicResult.NOT_APPLICABLE,
			name: this.locale.getString('scorecards.notConsidered')
		}];

		this.scorecardRebuttalAllowed = _.some(this.scorecard.topics, topic => !!topic.idPath);
		if (this.scorecardRebuttalAllowed) {
			this.scorecardRebuttalTree = new ScorecardRebuttalTree(this.scorecard.topics);
		}
	}

	getResult(result: ScorecardTopicResult): string {
		return ScorecardDocExplorerUtils.getTopicResultIcon(result);
	}

	getResultColor(result: ScorecardTopicResult): string {
		return ScorecardDocExplorerUtils.getTopicResultColor(result);
	}

	collapse(): void {
		this.onCollapse.emit(!this.expanded);
	}

	toggleFavorite(): void {
		this.onFavoriteToggle.emit(!this.favorite);
	}

	isClickable(topic: ScorecardTopicInDocView): boolean {
		return !this.rebuttalMode && topic.classified;
	}

	clickTopic(topic: ScorecardTopicInDocView): void {
		if (!this.isClickable(topic)) {
			return;
		}

		this.onTopicClick.emit({ nodeId: topic.nodeId, modelId: this.scorecard.modelId });
	}

	selectPresence(topic: ScorecardTopicInDocView, node: any): void {
		this.scorecardRebuttalTree.updateNodeState(topic.nodeId, node.value);
	}

	getPresenceObject(topic: ScorecardTopicInDocView): any {
		let nodeState: ScorecardTopicResult = this.scorecardRebuttalTree.getNodeData(topic.nodeId).updatedState;
		return this.getPresenceOption(nodeState);
	}

	getPresenceOption(topicResult: ScorecardTopicResult) {
		return this.presenceOptions.find(option => option.value === topicResult);
	}

	cancelChanges(): void {
		this.scorecardRebuttalTree.resetState();
	}

	hasChanges(): boolean {
		return this.scorecardRebuttalTree.hasChanges();
	}

	topicsChanged(initialTopics: Map<number, ScorecardTopicResult>, currentTopics: Map<number, ScorecardTopicResult>): boolean {
		return initialTopics.size !== currentTopics.size
				|| _.some(Object.keys(initialTopics), nodeId => !_.isEqual(initialTopics[nodeId], currentTopics[nodeId]));
	}

	confirmRebuttal() {
		let message = this.locale.getString('scorecards.rebuttalConfirmation') + '<br><br>'
				+ this.locale.getString('scorecards.rebuttalNotice');

		let confirmation = this.cxDialogService.regularWithConfirm(
				this.locale.getString('scorecards.rebutTitle'), message,
				this.locale.getString('common.submit'), this.locale.getString('common.cancel'));

		return confirmation.result
			.then(this.performRebuttal)
			.then(() => {
				this.scorecard.rebuttalInProgress = true;

				const {source} = AmplitudeEventUtils.getBaseDocumentViewEvent({} as CbDocument, this.isDocExplorer);


				AmplitudeAnalyticsService.trackEvent(
					AmplitudeEvent.DOCEXPLORER_REBUTTAL_SAVE,
					{ documentId: this.selectedDocument },
					{ source, documentType: this.documentType}
				);
			});
	}

	private performRebuttal = (): Promise<void> => {
		let rebuttedDocument: ScorecardRebuttedDocument = {
			modelId: this.scorecard.modelId,
			documentId: this.selectedDocument,
			rebuttedNodes: this.scorecardRebuttalTree.getNodesForRebuttal()
		};

		return PromiseUtils.wrap(
			this.scorecardsApiService.rebutDocument(this.projectSelection.contentProviderId, rebuttedDocument));
	}

	isRebutted(): boolean {
		return _.some(this.scorecard.topics, (topic: ScorecardTopicInDocView) => topic.rebutted);
	}

	getResultTitle(result: ScorecardTopicResult): string {
		let key = ScorecardDocExplorerUtils.getTopicResultIconKey(result);
		return this.locale.getString(key);
	}

	onSelectedValueChange(selectedValue: IntelligentScoringPreference) {
		this.onDisplayPreferenceChange.emit(selectedValue);
	}

	sortAndFilter(topics: ScorecardTopicInDocView[]): ScorecardTopicInDocView[] {
		let filtedTopics = this.scorecardSorting.sortAndFilter(topics, this.displayPreference.sort, this.displayPreference.filter);
		this.noResults = !filtedTopics.length;
		return filtedTopics;
	}

	getScorecardScore(): string {
		let scorecardScoreValue = '' + this.scorecard.score;
		return this.scorecard.settings
			? this.enrichmentAttributesService.formatAttributeValue(scorecardScoreValue, AttributeType.NUMBER, this.scorecard.settings)
			: scorecardScoreValue;
	}

	getScorecardTitleAriaLabel(): string {
		return `${this.scorecard.name} ${this.getScorecardScore()}`;
	}

	getExpandCollapseBtnAriaLabel(): string {
		let scorecardName: string = this.scorecard.name;

		return this.expanded
			? this.locale.getString('preview.collapsePanel', { panelName: scorecardName })
			: this.locale.getString('preview.expandPanel', { panelName: scorecardName });
	}

	getFavoriteBtnAriaLabel(): string {
		return this.favorite
			? this.locale.getString('preview.unstarItem', { displayName: this.scorecard.name })
			: this.locale.getString('preview.starItem', { displayName: this.scorecard.name });
	}

	showTable(): boolean {
		return !this.rebuttalMode || (this.rebuttalMode && this.scorecardRebuttalAllowed && !this.scorecard.rebuttalInProgress);
	}

	showButtons(): boolean {
		return this.rebuttalMode && this.scorecardRebuttalAllowed && !this.scorecard.rebuttalInProgress;
	}
}
