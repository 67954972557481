import {ConversationSentence} from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { ConversationAttributes } from './conversation-attributes.constant';

export enum ChannelTypes {
	CLIENT = 'client',
	AGENT = 'agent',
	BOT = 'bot',
	UNKNOWN = 'unknown'
}

export enum ParticipantKind {
	CHAT_BOT = 'chat_bot',
	IVR = 'ivr',
	HUMAN = 'human'
}

export class ConversationChannelService {
	private UNKNOWN_PARTICIPANT_KEY = 'cb_conv_participant_type';
	private DESIGNER_UNKNOWN_PARTICIPANT_TYPE = 'type_unknown';

	static getParticipant(sentence: ConversationSentence): {id: number, type: string} {
		let participantId = Number.parseInt(sentence?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_ID]?.[0], 10);
		let channel: string = sentence.channel;
		let type: string = !isNaN(participantId) && channel !== ChannelTypes.BOT
			? sentence?.attributes?.[ConversationAttributes.CB_CONV_PARTICIPANT_TYPE]?.[0]
			: channel;

		return {
			id: !isNaN(participantId) ? participantId : -1,
			type
		};
	}

	static isSameChannel(sentence1: ConversationSentence, sentence2: ConversationSentence): boolean {
		return _.isEqual(ConversationChannelService.getParticipant(sentence1), ConversationChannelService.getParticipant(sentence2));
	}

	constructor(private locale: ILocale) {}

	hasChannel = (sentence: ConversationSentence): boolean => {
		return !_.isUndefined(sentence.channel);
	}

	isClient = (type: string): boolean => {
		return type === ChannelTypes.CLIENT;
	}

	isClientSentence = (sentence: ConversationSentence): boolean => {
		return this.isClient(sentence.channel);
	}

	isAgent = (type: string): boolean => {
		return type === ChannelTypes.AGENT;
	}

	isAgentSentence = (sentence: ConversationSentence): boolean => {
		return this.isAgent(sentence.channel);
	}

	isBot = (type: string): boolean => {
		return type === ChannelTypes.BOT;
	}

	isBotSentence = (sentence: ConversationSentence): boolean => {
		return this.isBot(sentence.channel);
	}

	isUnknown = (type: string): boolean => {
		return type === ChannelTypes.UNKNOWN;
	}

	isUnknownSentence = (sentece: ConversationSentence): boolean => {
		return this.isUnknown(sentece.channel);
	}

	setUnknownParticipantType = (sentences: ConversationSentence[]): void => {
		_.each(sentences, sentence => {
			if (sentence?.attributes?.[this.UNKNOWN_PARTICIPANT_KEY]?.[0] === this.DESIGNER_UNKNOWN_PARTICIPANT_TYPE) {
				sentence.attributes[this.UNKNOWN_PARTICIPANT_KEY][0] = ChannelTypes.UNKNOWN;
			}
		});
	}

	getString = (type: string): string => {
		switch (type) {
			case ChannelTypes.CLIENT:
				return 'client';
			case ChannelTypes.AGENT:
				return 'agent';
			case ChannelTypes.BOT:
				return 'bot';
			case ChannelTypes.UNKNOWN:
				return 'unknown';
			default:
				return 'agent';
		}
	}

	getIconClass = (channel: string): string => {
		if (_.isUndefined(channel)) {
			return '';
		}

		let channelString: string = this.getString(channel);

		if (channelString === ChannelTypes.BOT) {
			return 'q-icon-robot';
		}

		if (channelString === ChannelTypes.UNKNOWN) {
			return 'q-icon-help';
		}

		return (channelString === ChannelTypes.AGENT)
			? 'q-icon-conversation-agent-circle'
			: 'q-icon-conversation-customer-circle';
	}

	getChannelLabel = (channel: string, customLabels?: ConversationChannelLabels): string => {
		if (_.isUndefined(channel)) {
			return this.locale.getString('docExplorer.unknownLabel');
		}

		let channelString: string = this.getString(channel);

		// use customized labels if we have them
		if (customLabels && customLabels[channelString])
			return customLabels[channelString];

		return this.locale.getString(`docExplorer.${channelString}Label`);
	}
}


app.service('conversationChannelService', ConversationChannelService);
