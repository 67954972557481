<checkbox-button
	[(ngModel)]="visualProps.plotBandsEnabled"
	(ngModelChange)="onPlotBandsEnabledChange()"
	buttonClass="plot-bands-checkbox btn-sm mb-8"
	label="{{'widget.quadrants'|i18n}}">
</checkbox-button>

<div *ngIf="visualProps.plotBandsEnabled" class="clearfix plot-band-configurer-content">
	<div class="row plot-line-selector col-sm-4 mb-16">
		<plot-line-dropdown
			[plotLine]="visualProps.plotBands.lineStyle"
		></plot-line-dropdown>
	</div>

	<div class="row plot-line-positions col-sm-12">
		<div class="col-sm-6 plot-dividers"
			*ngFor="let axisName of axisNames; index as axisNameIndex">
			<div class="form-group">
				<label for="axis_divider_{{axisNameIndex}}" [i18n]="'widget.axisDividerAt'" [i18nParams]="{metric : axisName}"></label>
				<div class="align-center">
					<input type="number"
						id="axis_divider_{{axisNameIndex}}"
						[valueRequired]
						[strictMin]="-1e12"
						[strictMax]="1e12"
						[(ngModel)]="visualProps.plotBands.lines[getAxisType(axisNameIndex)][0]">
				</div>
			</div>
		</div>
	</div>

	<div class="row plot-bands-grid col-sm-12">
		<div class="plot-band-item"
			[ngStyle]="{'flex-basis': 'calc(100% / ' + columnsCount + ')'}"
			*ngFor="let plotBand of visualProps?.plotBands?.bands; index as plotBandIndex">
			<label [innerHTML]="plotBandLabels[plotBandIndex]"></label>
			<plot-band-item [plotBand]="plotBand" class="d-flex" [ngClass]="getPlotBandIdentifierClass(plotBandIndex)"></plot-band-item>
		</div>
	</div>

	<div class="row col-sm-12">
		<div class="form-group">
			<label for="quadrantsTextSize" [i18n]="'widget.quadrantsTextSize'"></label>
			<div class="d-flex align-center">
				<input type="number"
					id="quadrantsTextSize"
					class="form-control"
					style="margin-right: 5px; flex: 0 1 80px;"
					name="bandFontSize"
					[(ngModel)]="visualProps.plotBands.fontSize"
					[valueRequired]
					[strictMin]="1"
					[strictMax]="99">
					px
			</div>
		</div>
	</div>
</div>
