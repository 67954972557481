import { PeriodOverPeriodMetric } from './period-over-period-metric';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';

export class DeltaMetric extends PeriodOverPeriodMetric {
	getType = (): PeriodOverPeriodMetricType => {
		return PeriodOverPeriodMetricType.DELTA;
	}
	getNamePrefix(): string {
		return 'delta_';
	}
	getDisplayName = (parentMetricDisplayName: string): string => {
		return '\u0394' + ' ' + parentMetricDisplayName;
	}
}

app.service('deltaMetric', DeltaMetric);