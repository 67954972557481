import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoLocationApiService } from './geolocation.api.service';

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		GeoLocationApiService
	],
})
export class GeoLocationModule { }
