import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { ObjectUtils } from '@app/util/object-utils';
import { CloudVisualProperties } from '@cxstudio/dashboards/widgets/type-definitions/cloud-visual-properties';
import { TypeGuards } from '@app/util/typeguards.class';

/**
 * Utilities for converting data to highcharts format
 */
export class HighchartsUtilsService {
	constructor(
		private readonly locale: ILocale,
		private readonly dateService: DateService,
		private readonly reportUtils: ReportUtils,
		private downgradeDialogService: DowngradeDialogService,
	) {}

	getDefaultConfig = (): Highcharts.Options => {
		return {
			credits: {
				enabled: false
			},
			title: {
				text: null
			},
			legend: {
				enabled: false,
				symbolRadius: 0
			},
			exporting: {
				enabled: false
			}
		};
	}

	getSpacingBottom = (reportDefinition, minOffset = 0) => {
		return reportDefinition.options.showSampleSize === false ? 10 : minOffset;
	}

	getAxisScale = (prefix, props) => {
		let min = null;
		let max = null;

		if (!_.isUndefined(props[`${prefix}AxisAutoMax`]) && !_.isUndefined(props[`${prefix}AxisAutoMin`])) {
			if (isFalse(props[`${prefix}AxisAutoMin`])) {
				const minValue = props[`${prefix}AxisMin`];
				if (TypeGuards.isNumber(minValue))
					min = Number(minValue);
			}
			if (isFalse(props[`${prefix}AxisAutoMax`])) {
				const maxValue = props[`${prefix}AxisMax`];
				if (TypeGuards.isNumber(maxValue))
					max = Number(maxValue);
			}

		} else if (isFalse(props[`${prefix}AxisAuto`])) {
			let autoValue = props[`${prefix}AxisMin`];
			if (TypeGuards.isNumber(autoValue))
				min = Number(autoValue);
			autoValue = props[`${prefix}AxisMax`];
			if (TypeGuards.isNumber(autoValue))
				max = Number(autoValue);
		}
		return { min, max };
	}

	getZIndexByChartType = (chartType): number => {
		switch (chartType) {
		case 'BUBBLE': return 10;
		case 'SPLINE': return 5;
		default: return null;
		}
	}

	formatTrendLabel = (label, format): string => {
		switch (format) {
		case 'HOUR':
			return this.formatDateLabel(label, DateTimeFormat.TREND_DATE_LONG, '');
		case 'DAY':
			return this.formatDateLabel(label, DateTimeFormat.TREND_DATE, '');
		case 'WEEK':
			if (moment(label, 'YYYY-MM-DD', true).isValid()) {
				return this.formatDateLabel(label, DateTimeFormat.TREND_DATE, this.locale.getString('widget.weekOf') + ' ');
			}
			return label;
		default:
			return label;
		}
	}

	private formatDateLabel(label, format, prefix: string): string {
		return prefix + this.dateService.format(label, format);
	}

	showAdvancedChartOptionsDialog = (
		widget: Widget,
		showSecondary: boolean,
		secondaryPrefix: string,
		primaryAxisDisplayName: string,
		secondaryAxisDisplayName: string,
		isCalculationSeries: boolean
	): Promise<void> => {
		const props = widget.properties;
		const visualProps = widget.visualProperties;

		const primaryPrefix = 'y';

		return this.downgradeDialogService.openAdvancedChartSettingsModal({
			widget: ObjectUtils.copy(widget),
			showSecondary,
			primaryPrefix,
			secondaryPrefix,
			primaryAxisDisplayName,
			secondaryAxisDisplayName,
			lockAxisLimits: visualProps.lockAxisLimits,
			widgetType: props.widgetType,
			isCalculationSeries,
			projectIdentifier: ProjectIdentifier.fromWidgetProperties(props),
			selectedMetrics: props.selectedMetrics
		}).then((processedProps: VisualProperties) => {
			visualProps.alignTicks = processedProps.alignTicks;
			visualProps[`${primaryPrefix}AxisAutoMax`] = processedProps[`${primaryPrefix}AxisAutoMax`];
			visualProps[`${primaryPrefix}AxisAutoMin`] = processedProps[`${primaryPrefix}AxisAutoMin`];
			visualProps[`${primaryPrefix}AxisMax`] = processedProps[`${primaryPrefix}AxisMax`];
			visualProps[`${primaryPrefix}AxisMin`] = processedProps[`${primaryPrefix}AxisMin`];

			visualProps[`${secondaryPrefix}AxisAutoMax`] = processedProps[`${secondaryPrefix}AxisAutoMax`];
			visualProps[`${secondaryPrefix}AxisAutoMin`] = processedProps[`${secondaryPrefix}AxisAutoMin`];
			visualProps[`${secondaryPrefix}AxisMax`] = processedProps[`${secondaryPrefix}AxisMax`];
			visualProps[`${secondaryPrefix}AxisMin`] = processedProps[`${secondaryPrefix}AxisMin`];

			// invoke after update referenceLines
			visualProps.referenceLines = processedProps.referenceLines;
			visualProps.axisTitle = processedProps.axisTitle;
			visualProps.plotBandsEnabled = processedProps.plotBandsEnabled;
			visualProps.plotBands = visualProps.plotBandsEnabled ? processedProps.plotBands : undefined;

			visualProps.timeReferenceLines = processedProps.timeReferenceLines;

			this.processCaseVisualizations(visualProps, processedProps);
		}).catch(() => {});
	}

	private processCaseVisualizations(visualProps: VisualProperties, processedProps: VisualProperties): void {
		visualProps.caseVisualizations = processedProps.caseVisualizations;
		if (visualProps.caseVisualizations) {
			visualProps.caseVisualizations.selectedCases = _.filter(visualProps.caseVisualizations.selectedCases, caseItem => {
				return caseItem && caseItem.sourceId !== undefined;
			});

			if (visualProps.caseVisualizations.selectedCases.length === 0) {
				visualProps.caseVisualizations.enabled = false;
			}
		}
	}

	showCloudAdvancedOptionsDialog = (visualProps: VisualProperties): Promise<CloudVisualProperties> => {
		return this.downgradeDialogService.openAdvancedCloudSettingsModal({
			visualProps: ObjectUtils.copy(visualProps)
		}).catch(() => {}) as Promise<CloudVisualProperties>;
	}

	private getSelectedPoint = (point: any) => {
		let selectedPoint: any = {
			id: point.id,
			name: point.name,
			uniqueName: point._uniqueName,
			displayName: point.displayName,
			fullPath: point.fullPath,
			idPath: point.idPath,
			color: point.color || point.series.color,
			attributeName: point.attributeName
		};
		if (point.graphic) {
			selectedPoint._element = point.graphic.element;
			selectedPoint._element.chart = point.series.chart; // chart is required to highlight similar points
		}
		return _.extend(selectedPoint, point.object);
	}

	selectPoint = (reportDefinition, point): void => {
		const selectedPoint = this.getSelectedPoint(point);
		if (point.fromNode && point.toNode) {
			selectedPoint.link = {
				from: this.getSelectedPoint(point.fromNode),
				to: this.getSelectedPoint(point.toNode)
			};
		}
		reportDefinition.selectedPoint = selectedPoint;
	}

	cancelPointSelection = (reportDefinition): void => {
		delete reportDefinition.selectedPoint;
	}

	handleRenderedEvent = (serie, reportDefinition): void => {
		serie.rendered = true;

		const amountOfRenderedSeries = _.filter(serie.chart.series, (item: any) => item.rendered).length;

		if (amountOfRenderedSeries === serie.chart.series.length) {
			this.reportUtils.handleWidgetRenderedEvent(reportDefinition.utils.widgetId, reportDefinition.utils.widgetType,
				reportDefinition.utils.containerId);
		}
	}

	cleanDataNames = (options: any): void => {
		if (!(options?.series)) return;

		for (let series of options.series) {
			this.cleanArrayProperties(series.data, 'name');
		}
	}

	cleanArrayProperties = <T>(array: T[], prop): void => {
		if (!array || !array.length) return;

		for (let dataPoint of array) {
			if (dataPoint && dataPoint[prop]) {
				dataPoint[prop] = this.cleanStringForDisplay(dataPoint[prop]);
			}
		}
	}

	cleanAxisCategories = (axis): void => {
		if (!axis || !axis.categories) return;

		for (let category of axis.categories) {
			category = this.cleanStringForDisplay(category);
		}
	}

	// helper so we can add more cleanup steps if we need to
	cleanStringForDisplay = (checkString: string): string => {
		if (typeof checkString !== 'string') return checkString;

		checkString = checkString.bracketsToEntities();
		return checkString;
	}
}

app.service('highchartsUtils', HighchartsUtilsService);
