app.directive('ngIndeterminate', () => {
	return {
		restrict: 'A',
		link: (scope, element, attrs) => {
			/**
			 * Whenever the bound value of the attribute changes we update
			 * the internal 'indeterminate' flag on the attached dom element;
			 * if also 'ng-indeterminate-followed-by-uncheck' is evaluated to true,
			 * the order of states will be changed to "checked->indeterminate->unchecked"
			 */
			let watcher = scope.$watch(attrs.ngIndeterminate, (value) => {
				(element[0] as any).indeterminate = value;

				if (!value
					&& attrs.ngIndeterminateFollowedByUncheck
					&& scope.$eval(attrs.ngIndeterminateFollowedByUncheck)) {
					(element[0] as any).checked = false;
				}
			});

			scope.$on('$destroy', () => {
				watcher();
			});
		}
	};
});
