<div class="br-log-panel ph-16">
	<h2 [i18n]="'mAccount.searchHeaderMessage'" [i18nParams]="{mAccountName: currentMasterAccountName}"></h2>
	<p [i18n]="'mAccount.description'" class="help-block"></p>

	<div class="form-group form-inline">
		<div class="input-group">
			<simple-dropdown
				[(value)]="logQueryBy"
				ngDefaultControl
				[attr.aria-label]="'common.searchBy'|i18n"
				(onChange)="onLogQueryByChange()"
				[options]="searchOptions"
				class="_t-log-filter-type p-0 border-0">
			</simple-dropdown>
			<date-picker
				[hidden]="!isDateFilter()"
				class="cx-date-filter-selector no-border"
				[dateRange]="dateRangeQuery"
				(onChange)="filter()">
			</date-picker>

			<input class="br-log-filter"
				[hidden]="isDateFilter()"
				type="search"
				[(ngModel)]="logQuery[logQueryBy]"
				(keydown.enter)="filter()"
				placeholder="{{'common.search'|i18n}}"
				[attr.aria-label]="'common.search'|i18n"
				(ngModelChange)="searchFilter = logQuery[logQueryBy]">

			<button id="btn-audit-log-search" type="button" class="btn btn-primary q-icon q-icon-search" (click)="filter()" [attr.aria-label]="'common.search'|i18n"></button>
		</div>

		<button id="btn-open-export-dialog" type="button" class="btn btn-secondary" (click)="showExportDialog()" [i18n]="'mAccount.export'"></button>
		<refresh-trigger
			class="btn btn-secondary ml-8"
			[refreshFunction]="reloadGrid"
			refreshLimit="30">
		</refresh-trigger>
	</div>

	<div class="br-log-list" [ngBusy]="loading">
		<item-grid
			class="br-log-grid d-flex flex-fill flex-direction-column br-grid"
			[treeData]="logEntries"
			[gridOptions]="gridOptions"
			[nameField]="gridNameField"
			[gridType]="gridType"
			[gridChange]="changedItems">
		</item-grid>
	</div>

	<div>
		<pagination
			[pagination]="pagination"
			[pullRight]="true"
			(paginationChange)="pageChanged($event)"
		></pagination>
	</div>

</div>
