import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import Widget from '@cxstudio/dashboards/widgets/widget';
import widgetTypeEnum from '@app/modules/widget-settings/widget-type.enum';

export type RefreshDashboardType = {
	dashboardId: number;
	skipSpinner: boolean;
};

@Injectable({
	providedIn: 'root'
})
export class FullScreenAutoRefreshStoreService {
	private readonly refreshDashboardSubject = new Subject<RefreshDashboardType>();
	refreshWidgetSubject: Subject<Widget>;

	constructor(
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService,
	) {}

	// Not used yet, but should be used for converted components/services who listen on dashboardRefreshEvent
	getRefreshDashboardObserver(): Observable<RefreshDashboardType> {
		return this.refreshDashboardSubject.asObservable();
	}

	refreshDashboard(dashboardId: number, skipSpinner: boolean = false): void {
		this.refreshDashboardSubject.next({
			dashboardId,
			skipSpinner
		});

		this.refreshDashboardLegacy(dashboardId, skipSpinner);
	}

	getRefreshWidgetObserver(): Observable<Widget> {
		return this.refreshWidgetSubject.asObservable();
	}

	refreshWidgets(widgets: Widget[]): void {
		let widgetsToRefresh = [...widgets];
		this.refreshWidgetSubject = new Subject<Widget>();

		const deregister = this.$rootScope.$on('widgetRefreshFinishedEvent', (_event, eventWidget) => {
			// remove the refreshed widget from widgetsToRefresh array
			widgetsToRefresh = widgetsToRefresh.filter(
				w => w.id !== eventWidget.id && w.properties.widgetType !== widgetTypeEnum.PAGE_BREAK
			);

			this.refreshWidgetSubject.next(eventWidget);

			if (widgetsToRefresh.length === 0) {
				// if there are no more widgets to refresh, send complete signal and deregister from widgetRefreshFinishedEvent
				this.refreshWidgetSubject.complete();
				deregister();
			}
		});
	}

	// This can be removed when all $rootScope.$on('dashboardRefreshEvent', ...) has been replaced with getRefreshDashboardObserver
	private refreshDashboardLegacy(dashboardId: number, skipSpinner: boolean = false): void {
		this.$rootScope.$broadcast('dashboardRefreshEvent', dashboardId, {
			skipSpinner
		});
	}
}

app.service('fullScreenAutoRefreshStore', FullScreenAutoRefreshStoreService);
