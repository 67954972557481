import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core/cx-http.service';

@Injectable({
	providedIn: 'root'
})
export class LoginHistoryService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) { }

	getLoginHistory = () => this.cxHttp.get('rest/security/login_history');
}

app.service('loginHistoryService', downgradeInjectable(LoginHistoryService));
