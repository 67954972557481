import { Directive, ElementRef, Input, HostBinding } from '@angular/core';
/*
 * Has AngularJS twin
 * Whenever the bound value of the attribute changes we update
 * the internal 'indeterminate' flag on the attached dom element;
 * if also 'ng-indeterminate-followed-by-uncheck' is evaluated to true,
 * the order of states will be changed to "checked->indeterminate->unchecked"
 */
@Directive({
	selector: '[checkboxIndeterminate]'
})
export class CheckboxIndeterminateDirective {
	
	private element;
	@Input() indeterminateFollowedByUncheck: boolean;
	
	constructor(el: ElementRef) { 
		this.element = el.nativeElement;
	}

	@Input() set checkboxIndeterminate(value: boolean) {
		this.element.indeterminate = value;
		if (!value && this.indeterminateFollowedByUncheck) {
			this.element.checked = false;
		}
	}
}