import * as _ from 'underscore';

import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { FilterParsingService } from '@cxstudio/services/filter-parsing-service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { PromiseUtils } from '@app/util/promise-utils';

@Injectable({
	providedIn: 'root'
})
export class WidgetFiltersAccessService {
	constructor(
		@Inject('filterParsingService') private filterParsingService: FilterParsingService,
		@Inject('filterManagementApiService') private filterManagementApiService: FilterManagementApiService,
	) {
	}

	hasPrivatePlatformFilters(widgets: Widget[]): Promise<boolean> {
		let filterPromises = _.map(widgets, (widget) => {
			return PromiseUtils.wrap(this.filterParsingService.getPrivateWidgetPlatformFilters(widget));
		});

		if (!filterPromises.length) {
			return Promise.resolve(false);
		}

		return Promise.all(filterPromises).then((responses) => {
			return _.some(responses, (privateFilters: any[]) => privateFilters.length > 0);
		});
	}

	hasPrivateStudioFilters(widgets: Widget[]): Promise<boolean> {
		let appliedFilterIds: number[] = _.chain(widgets)
			.map(widget => widget.properties?.appliedFilters?.filters)
			.flatten()
			.filter(filter => filter && filter.type === FilterTypes.STUDIO)
			.map(filter => filter.filterId)
			.value();

		let dateRangeFilterIds: number[] = _.chain(widgets)
			.map(widget => {
				return _.chain([widget.properties.dateRangeP1, widget.properties.dateRangeP2])
					.filter(DateRangeUtils.isCustomDateFilter)
					.map(filter => DateRangeUtils.getCustomDateFilterId(filter.dateFilterMode))
					.value();
			})
			.flatten()
			.value();

		let filterIds: number[] = _.union(appliedFilterIds, dateRangeFilterIds);

		if (!filterIds.length) {
			return Promise.resolve(false);
		}

		return Promise.resolve(this.filterManagementApiService.verifyFiltersAccess(filterIds).then(hasAccess => {
			return _.contains(Object.values(hasAccess), false);
		}) as PromiseLike<any>);
	}
}
