import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BackgroundType } from '@cxstudio/reports/entities/content-widget-properties';

const NONE_BACKGROUND = 'none';
const DEFAULT_BACKGROUND = '';
const INITIAL_CUSTOM_BACKGROUND = '#ffffff';

interface BackgroundOption {
	type: BackgroundType;
	name: string;
}

@Component({
	selector: 'background-options',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div class="btn-group" id="text-background-options">
		<button class="btn btn-secondary"
			*ngFor="let backgroundOption of options"
			id="_t-{{backgroundOption.type}}"
			[class.btn-selected]="isButtonSelected(backgroundOption.type)"
			(click)="toggleBackgroundType(backgroundOption.type)">
			{{backgroundOption.name}}
		</button>
		<color-input id="text-background-color"
			class="ml-4"
			*ngIf="showColorPicker()"
			[(ngModel)]="color"
			(ngModelChange)="changeColor()"
			[showSwatch]="false">
		</color-input>
	</div>
	`
})
export class BackgroundOptionsComponent implements OnInit {
	@Input() color: string;
	@Input() type: BackgroundType;

	@Output() onTypeChange = new EventEmitter<BackgroundType>();
	@Output() onColorChange = new EventEmitter<string>();

	options: BackgroundOption[] = [
		{type: BackgroundType.NONE, name: this.locale.getString('common.none')},
		{type: BackgroundType.DEFAULT, name: this.locale.getString('common.default')},
		{type: BackgroundType.CUSTOM, name: this.locale.getString('common.custom')}
	];

	constructor(private locale: CxLocaleService) {}

	ngOnInit(): void {
		if (!this.color) {
			this.type = BackgroundType.DEFAULT;
		} else if (!this.type) {
			this.type = BackgroundType.CUSTOM;
		}
	}

	toggleBackgroundType = (newType: BackgroundType): void => {
		this.type = newType;

		switch (newType) {
			case BackgroundType.NONE:
				this.color = NONE_BACKGROUND;
				break;
			case BackgroundType.DEFAULT:
				this.color = DEFAULT_BACKGROUND;
				break;
			case BackgroundType.CUSTOM:
				this.color = INITIAL_CUSTOM_BACKGROUND;
		}

		this.onTypeChange.emit(newType);
		this.onColorChange.emit(this.color);
	}

	changeColor = (): void => {
		this.onColorChange.emit(this.color);
	}

	showColorPicker = (): boolean => {
		return this.type === BackgroundType.CUSTOM;
	}

	isButtonSelected = (type: BackgroundType): boolean => {
		return this.type === type;
	}
}

app.directive('backgroundOptions', downgradeComponent({component: BackgroundOptionsComponent}) as angular.IDirectiveFactory);
