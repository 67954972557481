import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { Security } from '@cxstudio/auth/security-service';
import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';

@Component({
	selector: 'auth-callback',
	templateUrl: './auth-callback.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthCallbackComponent implements OnInit {

	@Input('redirect') redirect: boolean;

	constructor(
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('security') private security: Security,
		private authenticationService: AuthenticationService,
		@Inject('routeService') private routeService) { }

	ngOnInit(): void {
		this.processRequest();
	}

	processRequest() {
		this.security.setContext(undefined);
		this.security.setAccessToken(undefined);
		this.authenticationService.getAccessTokenWithRedirect(this.redirect).then((response: any) => {
			this.security.setAccessToken(response.token);
			this.security.setExternalOAuthSession(response.externalOAuthSession);
			if (response.location) {
				this.routeService.redirect(response.location);
			} else {
				this.redirectService.goToDashboardList();
			}
		});
	}

}
app.directive('authCallback', downgradeComponent({component: AuthCallbackComponent}) as angular.IDirectiveFactory);
