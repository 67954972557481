import { Inject, Injectable } from '@angular/core';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { MenuItemDef, RowNode } from 'ag-grid-community';
import { DashboardListService } from '../../dashboard-list.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { TemplateCreateDialogComponent, TemplateCreationData } from '@app/modules/unified-templates/common-templates/template/template-create-dialog/template-create-dialog.component';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { CxLocaleService } from '@app/core';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { AgGridDashboardService } from '../ag-grid-dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class AgGridDashboardActionsService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly dashboardListService: DashboardListService,
		private readonly dashboardTemplateService: DashboardTemplateService,
		private readonly dashboardScheduleService: DashboardScheduleService,
		private readonly assetTemplateApi: AssetTemplateApiService,
		private readonly dashboardGridHelperService: DashboardGridHelperService,
		private readonly agGridDashboardService: AgGridDashboardService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('dashboardExportService') private readonly dashboardExportService: DashboardExportService,
	) {}

	showComponents(dashboard: Dashboard): void {
		this.dashboardService.showDashboardComponents(dashboard);
	}

	edit(dashboard: Dashboard): void {
		this.navigationService.changeDashboard(dashboard, true);
	}

	editProperties(node: RowNode): void {
		let dashboard: Dashboard = node.data;
		let oldParent = (dashboard as any).parent;
		this.dashboardService.renameDashboard(dashboard, true).then((updatedDashboard) => {
			if (updatedDashboard && oldParent !== updatedDashboard.parent) {
				let folderTo = updatedDashboard.parent || {id: 0, name: '.'};
				updatedDashboard.parent = oldParent;
				this.dashboardListService.setLoading(this.dashboardService.moveDashboard(dashboard, folderTo)).then(() => {
					this.dashboardListService.updateDashboards([dashboard]);
				});
			} else {
				this.dashboardGridHelperService.updateGridRow(node, updatedDashboard);
			}
		});
	}

	rename(node: RowNode): void {
		this.dashboardService.renameDashboard(node.data).then(() => this.dashboardGridHelperService.updateGridRow(node));
	}

	copyLink(dashboard: Dashboard): void {
		this.agGridDashboardService.copyDashboardLink(dashboard);
	}

	exportPdfDownload(dashboard: any): void {
		this.dashboardExportService.exportPDFDownload(dashboard);
	}

	exportPdfSend(dashboard: Dashboard): void {
		this.dashboardExportService.exportPDF(dashboard);
	}

	saveAsTemplate(dashboard: Dashboard): void {
		this.dashboardTemplateService.saveDashboardAsTemplate(dashboard);
	}

	saveAsTemplateBeta(dashboard: Dashboard): void {
		let modalData: TemplateCreationData = {
			type: TemplateAssetType.DASHBOARD,
			assetId: dashboard.id,
			titleLabel: this.locale.getString('templates.newAssetTemplateTitle', {
				templateType: this.locale.getString('object.dashboard')
			}),
			showProperties: true,
			showMappings: true,
		};
		this.cxDialogService.openDialog(TemplateCreateDialogComponent, modalData, {size: ModalSize.MEDIUM})
			.result.then(result => {
				this.assetTemplateApi.createTemplate(TemplateAssetType.DASHBOARD, dashboard.id, result);
			}, _.noop);
	}

	schedule(dashboard: Dashboard): Promise<any> {
		return this.dashboardScheduleService.openScheduleDialog(dashboard).then((data) => {
			if (data) {
				dashboard.scheduleCount = data.filter((schedule: any) => {
					return schedule.settings.active;
				}).length;
			}
		}, _.noop);
	}

}
