import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { CxLocaleService } from '@app/core/cx-locale.service';
import { TrackingAssetType } from '@app/modules/consumption-tracker/entities/tracking-asset-type.enum';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AccountReportExportDialogComponent } from '@app/modules/system-administration/admin-reports/account-report-export/account-report-export-dialog.component';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { ExportService } from '@cxstudio/services/export-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemAuditExportComponent } from './system-audit-export/system-audit-export.component';
import { StatisticRefinementMode } from '@app/modules/consumption-tracker/entities/statistic-refinement-mode.enum';

@Component({
	selector: 'admin-reports',
	template: `
	<div class="d-flex flex-fill flex-direction-column">
		<div>
			<admin-report *ngFor="let item of reports;"
				[titleKey]="item.title"
				[textKey]="item.text"
				(createReport)="item.report()">
			</admin-report>
		</div>
		<collapsing-panel class="pr-16 pl-16" [collapsed]="false">
			<panel-heading>{{'administration.translateConsumption' | i18n}}</panel-heading>
			<panel-body>
				<consumption-statistics-table
					[assetType]="TrackingAssetType.TRANSLATE"
					[allowExport]="true"
					[searchLabel]="'administration.masterAccountsSearchPlaceholder'|i18n"
					[consumedColumnTitle]="'administration.translateConsumed'|i18n"
					[remainingColumnTitle]="'administration.translateRemaining'|i18n"
					[totalExpencesTitle]="'administration.translateCost'|i18n"
				>
				</consumption-statistics-table>
			</panel-body>
		</collapsing-panel>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminReportsComponent implements OnInit {
	public reports: any[];

	TrackingAssetType = TrackingAssetType;
	StatisticRefinementMode = StatisticRefinementMode;

	constructor(
		@Inject('exportApiService') private exportApiService: ExportApiService,
		@Inject('exportService') private exportService: ExportService,
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.reports = [
			{title: 'administration.usageReport', text: 'administration.createUsageReport', report: this.createUsageReport},
			{title: 'administration.auditReport', text: 'administration.createAuditReport', report: this.createAuditReport},
			{title: 'administration.widgetUsageReport', text: 'administration.exportWidgetUsageData',
				report: this.createWidgetUsageReport},
			{title: 'administration.accountOverviewReport', text: 'administration.createAccountOverviewReport',
				report: this.createAccountOverviewReport},
			{title: 'administration.organizationHierarchyReport', text: 'administration.requestHierarchyReport',
				report: this.createOrganizationHierarchyReport},
			{title: 'administration.betaFeaturesStatusReport', text: 'administration.requestBetaFeaturesStatusReport',
				report: this.createBetaFeaturesStatusReport},
			{title: 'administration.internalTemplatesReport', text: 'administration.requestInternalTemplatesReport',
				report: this.createInternalTemplatesReport},
			{title: 'administration.accountPropertiesReport', text: 'administration.createAccountPropertiesReport',
				report: this.createAccountPropertiesReport}
		];
	}

	createUsageReport = () => {
		this.exportApiService.requestUsageReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.createUsageReport'),
				this.locale.getString('administration.createUsageReportMessage'),
				{keyboard: true});
		});
	}

	createAuditReport = () => {
		this.modalService.open(SystemAuditExportComponent, {
			backdrop: 'static',
			windowClass: 'ng-modal-dialog',
		});
	}

	createWidgetUsageReport = () => {
		this.exportService.exportWidgetUsageReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.exportWidgetUsageData'),
				this.locale.getString('administration.requestResultMessage'),
				{keyboard: true});
		});
	}

	createAccountOverviewReport = () => {
		let header: string = this.locale.getString('administration.createAccountOverviewReport');
		this.cxDialogService.openDialog(AccountReportExportDialogComponent, { header }).result.then((settings) =>
			this.exportService.exportAccountOverviewReport(settings) as unknown as Promise<void>
		).then(() => {
			this.cxDialogService.notify(
				header,
				this.locale.getString('administration.requestResultMessage'));
		}).catch(() => {});

	}

	createOrganizationHierarchyReport = () => {
		this.exportApiService.requestOrganizationHierarchyReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.requestHierarchyReport'),
				this.locale.getString('administration.requestHierarchyReportMessage'),
				{keyboard: true});
		});
	}

	createBetaFeaturesStatusReport = () => {
		this.exportApiService.requestBetaFeaturesStatusReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.requestBetaFeaturesStatusReport'),
				this.locale.getString('administration.requestBetaFeaturesStatusReportMessage'),
				{keyboard: true});
		});
	}

	createInternalTemplatesReport = () => {
		this.exportApiService.requestInternalTemplatesReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.requestInternalTemplatesReport'),
				this.locale.getString('administration.requestInternalTemplatesReportMessage'),
				{keyboard: true});
		});
	}

	createAccountPropertiesReport = () => {
		let header: string = this.locale.getString('administration.createAccountPropertiesReport');
		this.cxDialogService.openDialog(AccountReportExportDialogComponent, { header }).result.then((settings) =>
			this.exportService.exportAccountPropertiesReport(settings) as unknown as Promise<void>
		).then(() => {
			this.cxDialogService.notify(
				header,
				this.locale.getString('administration.requestResultMessage'));
		}).catch(() => {});

	}

}

app.directive('adminReports', downgradeComponent({component: AdminReportsComponent}) as angular.IDirectiveFactory);
