<collapsing-panel disableCollapse="true" class="page-tab-content">
	<panel-heading>
		{{'cpCheck.cpCheck'|i18n}}
	</panel-heading>
	<panel-body>
		<div class="d-flex mb-16">
			<div class="w-50-percent">
				<button class="btn btn-primary"
					(click)="checkVersions()"
					[disabled]="!checkEnabled">
					<span class="q-icon-refresh"></span>
					<span> {{'cpCheck.checkVersions'|i18n}}</span>
				</button>
			</div>
			<div class="w-50-percent">
				<label for="apiVersion">{{'administration.apiVersion'|i18n}}</label>
				<div class="d-inline-block">
					<input id="apiVersion" [(ngModel)]="filter.apiVersion" type="search" clear-input/>
				</div>
			</div>
		</div>

		<div class="w-100-percent">
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>{{'cpCheck.contentProvider'|i18n}}</th>
						<th>{{'administration.version'|i18n}}</th>
						<th>{{'administration.apiVersion'|i18n}}</th>
						<th>{{'administration.featureVersion'|i18n}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let contentProvider of contentProviders | orderBy: filterByVersion"
						[class.danger]="filterByVersionNegative(contentProvider)">
						<td>{{contentProvider.name}}</td>
						<td>
							<span *ngIf="contentProvider.loading" class="q-icon-refresh"></span>
							<span *ngIf="!contentProvider.loading && contentProvider.cpVersion === null" class="q-icon-minus"></span>
							<span *ngIf="!contentProvider.loading && contentProvider.cpVersion !== null">
								{{contentProvider.cpVersion}}
							</span>
						</td>
						<td>
							<span *ngIf="contentProvider.loading" class="q-icon-refresh" ></span>
							<span *ngIf="!contentProvider.loading && contentProvider.apiVersion === null" class="q-icon-minus" ></span>
							<span *ngIf="!contentProvider.loading && contentProvider.apiVersion !== null">
								{{contentProvider.apiVersion}}
							</span>
						</td>
						<td>
							<span *ngIf="contentProvider.loading" class="q-icon-refresh"></span>
							<span *ngIf="!contentProvider.loading && contentProvider.featureVersion === null" class="q-icon-minus"></span>
							<span *ngIf="!contentProvider.loading && contentProvider.featureVersion !== null">
								{{contentProvider.featureVersion}}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</panel-body>
</collapsing-panel>
