import * as cloneDeep from 'lodash.clonedeep';

export class AddNestedChildrenUtils  {
	static transform(totalNodeList: any[], nodeIdsToExpand: number[], nestedHierarchy: any): any {
		let rawNodesMap = {};
		_.each(totalNodeList, (rawNode) => {
			rawNodesMap[rawNode.id] = rawNode;
		});

		let rootNode = {};
		if (nestedHierarchy && nestedHierarchy.length ) {
			rootNode = nestedHierarchy[0];
		}

		this.recurseNodes(rootNode, nodeIdsToExpand, rawNodesMap);
		return rootNode;
	}

	private static  recurseNodes(focusNode, expandedNodeIdsList, rawNodesMap) {
		if (focusNode.hasOwnProperty('children') && !focusNode.children.length) {
			delete focusNode.children;
			return;
		}
		if (!focusNode.children) return;

		let focusNodeIndex = _.indexOf(expandedNodeIdsList, focusNode.id);
		if (focusNodeIndex > -1) {
			focusNode.children = _.chain(focusNode.children)
				.filter((child) => !!rawNodesMap[child.id])
				.map((child) => cloneDeep(rawNodesMap[child.id]))
				.value();
		}
		for (let childFocusNode of focusNode.children) {
			this.recurseNodes(childFocusNode, expandedNodeIdsList, rawNodesMap);
		}
		if (focusNodeIndex > -1) {
			focusNode.expanded = true;
		}
	}
}