import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties, BaseWidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { WidgetEmbedConfig } from '@cxstudio/dashboards/widgets/widget-embed-config.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ResponsiveState } from '@cxstudio/reports/entities/responsive-state.interface';
import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import { ExternalWidgetProperties } from '@cxstudio/reports/entities/external-widget-properties';

export interface IFilterUi {
	textFilter: string;
}

export interface IDrillObject {
	point: DrillPoint;
	type: DrillType; // not sure about this type
}

export enum WidgetDisplayType {
	CB = 'CB',
	CONTENT = 'CONTENT',
	EXTERNAL = 'EXTERNAL',
}

export enum WidgetDisplayMode {
	EMBEDDED = 'embedded',
	ADHOC = 'adhoc',
	HOME_PAGE = 'homePage'
}

export interface IWidgetBox {
	width: number;
	height: number;
	posX: number;
	posY: number;
}

export default interface Widget<P extends BaseWidgetProperties = WidgetProperties, V = VisualProperties>
	extends IWidgetBox {
	id: number;
	dashboardId: number;
	containerId: string; // unique id for widget's container, could be either <dashboardId> or <dashboardId_$index>

	posX: number;
	posY: number;
	width: number;
	height: number;

	type: WidgetDisplayType;
	name: string | WidgetType;
	displayName: string;
	properties: P;
	visualProperties: V;
	drillDepth?: number;

	embedConfig?: WidgetEmbedConfig;

	description?: string;
	details?: any;

	linkedWidgets: number[];
	linkedTo: number[];
	dirty?: boolean; // used in linking
	parentWidget?: Widget;
	ignoreDateRangeFilters: boolean;
	selectivePreview: boolean;

	filterUi: IFilterUi;
	created?: boolean; // need to deprecate this
	template?: boolean;
	documentExplorerName?: string;
	hasDataAccess?: boolean; // project data access by current user, ui only

	drillPath?: IDrillObject[];
	conversionTooltip?: string;

	group?: number; // gridster multi-drag
	multiSelection?: boolean;

	// temp properties
	responsiveState?: ResponsiveState;

	firstPass?: boolean;
	dashboardRunTimestamp: string;
	intersectPageBreak?: boolean;
	ariaLabel: string;
}

export type ContentWidget<P extends BaseWidgetProperties = ContentWidgetProperties, V = VisualProperties> = Widget<P, V>;

export type ExternalWidget = Widget<ExternalWidgetProperties>;

