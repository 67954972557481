import { Injectable } from '@angular/core';
import { CxHttpService, SupportedLocale } from '@app/core';
import { SystemDictionaries } from '@app/modules/system-administration/platform/system-dictionary/system-dictionaries';

@Injectable({ providedIn: 'root' })
export class SystemDictionaryApi {
	readonly BASE_URL = 'rest/system-admin/dictionary';

	constructor(private readonly http: CxHttpService) { }

	getDictionaries = (): Promise<SystemDictionaries> => {
		return this.http.get(this.BASE_URL);
	}

	updateDictionary = (locale: SupportedLocale, dictionary: string[]): Promise<void> => {
		return this.http.put(`${this.BASE_URL}`, { locale, dictionary });
	}
}
