import { Component, OnInit, Input } from '@angular/core';
import { LogoSettingsServiceClass } from './logo-settings.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
	selector: 'logo-settings',
	templateUrl: './logo-settings.component.html'
})
export class LogoSettingsComponent implements OnInit {
	@Input('brandingColors') brandingColors$: Observable<{[key: string]: string}>;

	constructor(
		public logoManagement: LogoSettingsServiceClass
	) { }

	ngOnInit(): void {
		this.logoManagement.initUploader();
	}
}
