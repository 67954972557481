import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, HostListener, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SidebarEditorService } from '@app/modules/layout/sidebar-editor/sidebar-editor.service';
import { SidebarPropertiesComponent } from '@app/modules/layout/sidebar-properties/sidebar-properties.component';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'sidebar-editor',
	templateUrl: './sidebar-editor.component.html',
	styles: [`
		::ng-deep .sidebar-editor .not-scaled .ng-busy {
			left: 400px;
		}
		::ng-deep .sidebar-editor .not-scaled .ng-busy-backdrop {
			left: 400px;
		}
		::ng-deep .sidebar-editor .preview-content .loader {
			top: calc(50% - 5em);
		}
		:host {
			width: 100%;
			height: 100%;
		}
		.sidebar-editor {
			z-index: 1030;
			top: 0;
			bottom: 0;
		}
		.sidebar-panel {
			width: 400px;
		}
		.backdrop-mask {
			background: rgba(0, 0, 0, 0);
			animation: fadeBackground 0.15s;
			animation-fill-mode: forwards;
		}
		.sidebar-properties-panel {
			width: 600px;
			left: 400px;
			top: 0;
			bottom: 0;
		}
		@keyframes fadeBackground {
			from { background-color: rgba(0, 0, 0, 0); }
			to { background-color: var(--black-50-percent); }
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarEditorComponent extends SelfCleaningComponent implements AfterViewInit, OnInit {

	private static readonly SIDEBAR_WIDTH = 400;
	private static readonly PREVIEW_PADDING = 32;

	private readonly debouncedResize = new Subject<void>();

	previewContainerWidth: number;
	previewTransform: { transform?: string; } = {};

	@ViewChild('propertiesContainer', { read: ViewContainerRef }) propertiesContainer: ViewContainerRef;
	private propertiesComponentRef: ComponentRef<SidebarPropertiesComponent<any>>;
	private sidebarEditorService: SidebarEditorService;

	constructor(
		private ref: ChangeDetectorRef,
		private elementRef: ElementRef,
		private resolver: ComponentFactoryResolver,
		private injector: Injector,
	) {
		super();
	}

	ngOnInit(): void {
		this.sidebarEditorService = this.injector.get(SidebarEditorService);
	}

	ngAfterViewInit(): void {
		this.adaptPreviewSize();
		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.adaptPreviewSize()));
		let widthElement = this.elementRef.nativeElement.firstElementChild;
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(widthElement, () => this.debouncedResize.next()));
	}
	/* Detect when user active history changes, i.e. user changes url*/
	@HostListener('window:popstate', ['$event'])
	handlePopState() {
		this.sidebarEditorService.dismiss();
	}


	private adaptPreviewSize(): void {
		this.recalculatePreviewContainerWidth();
		this.scalePreview();
		this.ref.detectChanges();
	}

	private recalculatePreviewContainerWidth(): void {
		let takenWidth = SidebarEditorComponent.SIDEBAR_WIDTH + SidebarEditorComponent.PREVIEW_PADDING * 2;
		this.previewContainerWidth = $(this.elementRef.nativeElement).find('.sidebar-editor').outerWidth() - takenWidth;
	}

	private scalePreview(): void {
		let widthRatio = this.getPreviewWidthRatio();
		let heightRatio = this.getPreviewHeightRatio();
		let noScale = 1;
		let scale = Math.min(noScale, widthRatio, heightRatio);
		this.previewTransform = scale !== noScale ? { transform: `scale(${scale})` } : {};
	}

	private getPreviewWidthRatio(): number {
		let previewWidth = $(this.elementRef.nativeElement).find('.preview-content').outerWidth();
		return this.previewContainerWidth / previewWidth;
	}

	private getPreviewHeightRatio(): number {
		let containerHeight = $(this.elementRef.nativeElement).find('.preview-panel').height();
		let previewHeight = $(this.elementRef.nativeElement).find('.preview-content').outerHeight();
		return containerHeight / previewHeight;
	}

	onApply(): void {
		this.sidebarEditorService.apply();
	}

	onDiscard(): void {
		this.sidebarEditorService.dismiss();
	}

	openProperties<T>(header: string, template: TemplateRef<unknown>): Promise<T> {
		if (this.propertiesComponentRef) {
			this.propertiesComponentRef.destroy();
		}
		this.propertiesContainer.clear();

		let factory = this.resolver.resolveComponentFactory(SidebarPropertiesComponent);
		this.propertiesComponentRef = this.propertiesContainer.createComponent(factory);
		this.propertiesComponentRef.instance.template = template;
		this.propertiesComponentRef.instance.header = header;
		this.ref.markForCheck();
		return this.propertiesComponentRef.instance.getPromise().finally(() => this.closeProperties());
	}

	private closeProperties(): void {
		this.propertiesComponentRef.destroy();
		delete this.propertiesComponentRef;
		this.propertiesContainer.clear();
		this.ref.markForCheck();
	}

	isPropertiesVisible(): boolean {
		return !!this.propertiesComponentRef;
	}


}
