import * as _ from 'underscore';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { NumericFormats } from '@app/modules/project/attribute/attribute-settings.service';


export default class ScorecardUtils {
	private static readonly ATTRIBUTE_NAME_PATTERN = /^sc_(\d+)_score$/;

	static isScorecardAttribute = (attributeName: string): boolean => {
		return ScorecardUtils.ATTRIBUTE_NAME_PATTERN.test(attributeName);
	}

	static getScorecardAttributeName = (scorecard: Scorecard): string => {
		return `sc_${scorecard.id}_score`;
	}

	static getScorecardId(attributeName: string): number {
		let matches = ScorecardUtils.ATTRIBUTE_NAME_PATTERN.exec(attributeName);
		return matches ? Number(matches[1]) : undefined;
	}

	static getScorecardDecimal(scorecard: Scorecard, formats: NumericFormats, attributes: IReportAttribute[]): number {
		if (!scorecard || !formats) {
			return 1;
		}
		let name = this.getScorecardAttributeName(scorecard);
		let scorecardAttribute = _.findWhere(attributes, { name }) as any;
		if (scorecardAttribute) {
			let format = formats[scorecardAttribute.id];
			return format ? format.decimals : 1;
		}
		return 1;
	}
}
