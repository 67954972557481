export enum PermissionAction {
	CREATE_FILTER = 'createFilter',
	SHARE_FILTER = 'shareFilter',
	SHARE_EDIT_FILTER = 'shareEditFilter',
	MANAGE_DATE_FILTERS = 'manageDateFilters',
	CREATE_METRIC = 'createMetric',
	SHARE_METRIC = 'shareMetric',
	SHARE_EDIT_METRIC = 'shareEditMetric',
	MANAGE_ALERT_TEMPLATES = 'manageAlertTemplates',
	MANAGE_DRIVERS = 'manageDrivers',
	SHARE_CREATE_USER = 'shareCreateUser',
	MANUAL_CASE_CREATION= 'manualCaseCreation',
	VIEW_PROFANITY = 'viewProfanity',
}