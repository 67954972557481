<div *ngIf="!hasError()"
	class="d-flex h-100-percent overflow-auto">
	<ul *ngFor="let colSentences of columns"
		class="feedback-preview-bubble-view {{'col-sm-' + (12 / widget.visualProperties.bubbleColumns)}}"
		(click)="onContainerClick($event)"
		(contextmenu)="handleRightClick.emit($event)"
		[class.cursor-loading]="menuLoading">
		<li *ngFor="let sentence of colSentences"
			class="feedback-preview-bubble-item mb-16 kb-focusable"
			[id]="getBubbleId(sentence)"
			[attr.selected]="(sentence.selected || false)"
			(keydown.enter)="handleEnterKeyOnContainer($event)"
			[attr.aria-label]="getAriaLabelForBubble(sentence)"
			tabindex="0">
			<speech-bubble class="d-block p-relative"
				[border]="getBorderColor(sentence)"
				(keydown.enter)="handleEnterKeyOnSentence(sentence, $event)"
				(click)="onSentenceClick(sentence, $event)">
				<translated-text *ngIf="utils.translationEnabled"
					[cacheKey]="getUniqueId(sentence)"
					[showTitle]="false"
					[text]="getTranslationText(sentence)">
				</translated-text>
				<ng-container *ngIf="!utils.translationEnabled">
					<p *ngIf="!isVerbatimView()"
						[innerHTML]="formatSentence(sentence) | safeHTML"
						class="mb-0"></p>
					<verbatim-text *ngIf="isVerbatimView()"
						[verbatim]="sentence"
						(showMore)="showMore(sentence, $event)">
					</verbatim-text>
				</ng-container>
			</speech-bubble>

			<div class="feedback-source-container mt-4 d-flex align-items-center flex-multiline l-margin-offset-3 p-relative">
				<div *ngIf="showSentiment()"
					[innerHTML]="formatSentiment(sentence) | safeHTML"
					class="lh-1"
				></div>
				<div *ngIf="showEaseScore(sentence)"
					class="feedback-preview-icon feedback-preview-effort-icon"
					title="{{sentence.easeScore}}"
					[innerHTML]="formatEaseScore(sentence) | safeHTML"
				></div>
				<div *ngIf="showEmotion(sentence)"
					class="feedback-preview-icon feedback-preview-effort-icon"
					title="{{sentence.emotion}}"
					[innerHTML]="formatEmotion(sentence) | safeHTML"
				></div>
				<div *ngIf="showSource(sentence)"
					class="icon-highlight feedback-preview-icon feedback-preview-source-icon"
					title="{{getSourceName(sentence)}}"
					[ngClass]="['feedback-preview-' + getSourceName(sentence) + '-icon', 'text-' + getSourceName(sentence) + '-color']">
					<i [ngClass]="('q-icon q-icon-' + getSourceName(sentence))"></i>
				</div>

				<div [ngClass]="hasSMUserName(sentence) ? 'feedback-preview-username' : 'feedback-preview-date-only'">
					<h6 *ngIf="hasSMUserName(sentence)">{{getSMUserName(sentence)}}</h6>
					<p *ngIf="showDate()"
						class="mb-0"
						title="{{formatDate(sentence.documentDate, true)}}"
					>{{formatDate(sentence.documentDate, false)}}</p>
				</div>
			</div>

		</li>
	</ul>
</div>

<widget-error *ngIf="hasError()" [widgetError]="widgetError" [demo]="demo"></widget-error>
