import { ConversationAttributes } from '@cxstudio/reports/document-explorer/conversations/conversation-attributes.constant';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';

export enum DocumentInteractionType {
	CHAT = 'chat',
	VOICE = 'voice',
	FEEDBACK = 'feedback'
}

export enum AmplitudeDocumentType {
	CONVERSATION = 'Conversation',
	NONCONVERSATION = 'Non-conversation'
}

export class DocumentTypeUtils {
	static isTwitter = (document: ConversationDocument | PreviewDocument | CbDocument): boolean =>
		(document as CbDocument)?.sm_service?.toLowerCase() === 'twitter'

	static isConversation = (document: ConversationDocument | PreviewDocument | CbDocument): document is ConversationDocument => {
		return document && (DocumentTypeUtils.isChat(document) || DocumentTypeUtils.isVoice(document));
	}

	static isChat = (document: ConversationDocument | PreviewDocument | CbDocument, voiceEnabled: boolean = false): boolean => {
		return document &&
			(DocumentTypeUtils.isChatInteractionType(document) ||
			(DocumentTypeUtils.hasDocumentLevelDuration(document) && (!voiceEnabled || !DocumentTypeUtils.isVoice(document))));
	}

	static hasConversationMetrics = (document: ConversationDocument | PreviewDocument | CbDocument): document is ConversationDocument => {
		return !!((document && (document as ConversationDocument).voiceMetrics));
	}

	static hasVoiceMetrics = (document: ConversationDocument | PreviewDocument | CbDocument): boolean => {
		return !!(DocumentTypeUtils.hasConversationMetrics(document) && document.voiceMetrics.silence);
	}

	static isRVFVoiceDocument = (document: ConversationDocument): boolean => {
		return DocumentTypeUtils.hasDocumentLevelDuration(document);
	}

	private static hasDocumentLevelDuration(document: ConversationDocument | PreviewDocument | CbDocument): boolean {
		return DocumentTypeUtils.hasDurationAttribute(document, ConversationAttributes.CB_CONV_DURATION);
	}

	static hasSentenceLevelDuration = (document: ConversationDocument | PreviewDocument | CbDocument): boolean => {
		return document.sentences && DocumentTypeUtils.hasDurationAttribute(document.sentences[0]?.attributes,
			ConversationAttributes.CB_CONV_SENTENCE_DURATION_MS);
	}

	private static hasDurationAttribute(object, attribute: string): boolean {
		let value = object && object[attribute];
		if (_.isArray(value)) value = value[0];
		return value && value !== '0';
	}

	static isVoice = (document: ConversationDocument | PreviewDocument | CbDocument): boolean => {
		return (document &&
			(DocumentTypeUtils.isVoiceInteractionType(document) ||
			DocumentTypeUtils.hasVoiceMetrics(document) ||
			DocumentTypeUtils.hasSentenceLevelDuration(document) ||
			DocumentTypeUtils.isNaturalIdVoice(document?.natural_id)));
	}

	static isNaturalIdVoice = (naturalId: any): boolean => {
		let naturalIdString = String(naturalId);
		let prefixes = CONFIG.filestorage?.['webdav.prefixes']?.split(',') ?? [];

		for (let prefix of prefixes) {
			if (naturalIdString.left(prefix.length) === prefix) return true;
		}
		return false;
	}

	static isVoiceInteractionType(document: ConversationDocument | PreviewDocument | CbDocument): boolean {
		return DocumentTypeUtils.getInteractionType(document) === DocumentInteractionType.VOICE;
	}

	static isChatInteractionType(document: ConversationDocument | PreviewDocument | CbDocument): boolean {
		return DocumentTypeUtils.getInteractionType(document) === DocumentInteractionType.CHAT;
	}

	/**
	 * Despite comments in CB-4880, this cannot be fully trusted
	 * Older voice or chat documents may still have cb_interaction_type === 'feedback'
	 */
	private static isFeedback(document: ConversationDocument | PreviewDocument | CbDocument): boolean {
		return DocumentTypeUtils.getInteractionType(document) === DocumentInteractionType.FEEDBACK;
	}

	private static getInteractionType(document: ConversationDocument | PreviewDocument | CbDocument): DocumentInteractionType {
		let attribute = document?.attributes && _.find(document.attributes, {name: ConversationAttributes.CB_INTERACTION_TYPE });
		return attribute?.value as DocumentInteractionType;
	}

	static getAmplitudeDocumentType(document: ConversationDocument | PreviewDocument | CbDocument): AmplitudeDocumentType {
		return DocumentTypeUtils.isConversation(document) ?
			AmplitudeDocumentType.CONVERSATION :
			AmplitudeDocumentType.NONCONVERSATION;
	}
}
