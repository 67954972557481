import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ExternalWidget } from '@cxstudio/dashboards/widgets/widget';

@Component({
	selector: 'qualtrics-widget-settings',
	templateUrl: './qualtrics-widget-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsWidgetSettingsComponent {
	@Input() widget: ExternalWidget;
	//619fd157bcd7880010b8e7d7,Widget_b6a1de62-d41a-4b6f-bf45-21efa7a639f2,,
	readonly EMBED_CODE_PATTERN = '[a-z0-9]+,[a-zA-Z0-9_\-]+,[^,]*,[^,]*';

	constructor(
		private locale: CxLocaleService,
	) {}

	updateAutoTitle(): void {
		this.widget.displayName = this.locale.getString('widget.qualtrics');
	}
}
