import ExternalApplicationType from './external-application-type';

export class ExternalApplicationTypeUtils {

	private static readonly TYPE_NAMES = {
		CONNECTORS: 'Connectors',
		NFS: 'NFS',
		VTT: 'VTT',
		ENGAGE: 'Social Connect',
		TUNING_SUGGESTION: 'Tuning Suggestion',
		NARRATIVE: 'Narrative',
		TICKETING: 'Ticketing'
	};

	static getDisplayName(type: ExternalApplicationType | string): string {
		return this.TYPE_NAMES[type] || type;
	}

	static getTypeOptions(): {value: string, name: string}[] {
		return Object.keys(ExternalApplicationType).map(value => {
			let name = this.getDisplayName(value);
			return { value, name };
		});
	}
}
