
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';

export class Scorecard {
	id?: number;
	name?: string;
	modelId?: number;
	modelName?: string;
	threshold?: number;
	thresholdRaw?: number;
	active?: boolean;
	scorecardTopics?: ScorecardTopic[];

	// Transient
	categoryModel?: string;
	autoFailCriteria?: number;
	scoredCriteria?: number;

	creator?: string;
	creatorEmail?: string;
	creatorText?: string;
	lastModifiedDate?: Date;
	attributeId?: number;
}
