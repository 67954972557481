app.directive('percentTextInput', () => {
	return {
		require: 'ngModel',
		link: ($scope, element, attr, ctrl: ng.INgModelController & ng.IController) => {
			// should already be set to text, but just to be sure..
			element.attr('type', 'text');

			function getNumberFromString(val): number {
				let numberVal = parseFloat(val);
				if (isNaN(numberVal)) {
					numberVal = 0;
				}
				return numberVal;
			}

			function parser(val): number {
				let parsedVal = getNumberFromString(val);
				ctrl.$setViewValue(`${parsedVal}%`);
				ctrl.$render();
				return parsedVal;
			}

			function formatter(val): string {
				let parsedVal = getNumberFromString(val);
				return `${parsedVal}%`;
			}

			ctrl.$parsers.push(parser);
			ctrl.$formatters.unshift(formatter);
		}
	};
});
