import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import * as ClipboardJS from 'clipboard';

@Injectable()
export class ClipboardService {
	copyToClipboard = (text: string, target): void => {
		const cb = new ClipboardJS(target, {
			text: () => text
		}) as any;

		cb.onClick({ currentTarget: target });
		cb.destroy();
	}

	hasSelectionInWidget = (): boolean => {
		const selection = window.getSelection ? window.getSelection() : document.getSelection();
		return !selection.isCollapsed;
	}

}

app.service('clipboardService', downgradeInjectable(ClipboardService));