import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import {Project} from './project-class';

export class ProjectAccess {
	project: Project;
	accessLevel: ProjectAccessLevelValue;

	constructor(
		project: Project,
		accessLevel: ProjectAccessLevelValue
	) {
		this.project = project;
		this.accessLevel = accessLevel;
	}
}
