export interface SimpleChange<T = any> {
	previousValue: T;
	currentValue: T;
	firstChange: boolean;
	isFirstChange(): boolean;
}

export type SimpleChanges<T> = {[propName in keyof T]?: SimpleChange<T[propName]>};
export class ChangeUtils {
	/**
	 * Checks if an input value has changed, excluding the first change
	 */
	static hasChange(simpleChange: SimpleChange): boolean {
		return simpleChange && !simpleChange.firstChange && ChangeUtils.hasAnyChange(simpleChange);
	}

	/**
	 * Checks if an input value has changed, including the first change
	 */
	static hasAnyChange(simpleChange: SimpleChange): boolean {
		return simpleChange && simpleChange.currentValue !== simpleChange.previousValue;
	}
}
