export class SelectionPlaceholders {
	static values = {
		calculations: {
			SIZE: 'size',
			SECONDARY_SIZE: 'secondarySize',
			PRIMARY_AXIS: 'yAxis',
			HORIZONTAL_AXIS: 'xAxis',
			SECONDARY_AXIS: 'secondaryYAxis',
			SORT_BY: 'sortBy',
			COLOR: 'color'
		},
		groupings: {
			PRIMARY_GROUP: 'primaryGroup',
			SECONDARY_GROUP: 'secondaryGroup',
			STACKED_GROUP: 'stackedGroup'
		}
	};

	static calculations = SelectionPlaceholders.buildValuesArray(SelectionPlaceholders.values.calculations);
	static groupings = SelectionPlaceholders.buildValuesArray(SelectionPlaceholders.values.groupings);

	private static buildValuesArray(obj): any[] {
		let result = [];
		for (let property in obj) {
			if (obj.hasOwnProperty(property)) {
				result.push(obj[property]);
			}
		}

		return result;
	}
}
