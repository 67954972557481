import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { HomePageCommonModule } from '@app/modules/home-page/home-page-common/home-page-common.module';
import { HomePageHeaderComponent } from './home-page-header/home-page-header.component';
import { QuickInsightsModule } from '@app/modules/home-page/quick-insights/quick-insights.module';
import { HomePageMenuComponent } from './home-page-menu/home-page-menu.component';
import { DashboardActionsModule } from '@app/modules/dashboard-actions/dashboard-actions.module';
import { HierarchyModule } from '@app/modules/hierarchy/hierarchy.module';
import { HomePagePersonalizationComponent } from './home-page-personalization/home-page-personalization.component';

@NgModule({
	imports: [
		SharedModule,
		HomePageCommonModule,
		QuickInsightsModule,
		DashboardActionsModule,
		HierarchyModule
	],
	declarations: [
		HomePageHeaderComponent,
		HomePageMenuComponent,
		HomePagePersonalizationComponent
	],
	exports: [
		HomePageHeaderComponent
	],
})
export class HomePageLayoutModule { }
