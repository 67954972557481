import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { SamlServiceProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-service-providers/saml-service-providers.component';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SamlServiceProvider } from '../../entities/saml-service-provider';

@Injectable()
export class SamlServiceProvidersGridDefinition implements IGridDefinition<SamlServiceProvidersComponent> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<span class="q-icon-layer"></span>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'aliasName',
			name: this.locale.getString('administration.aliasName'),
			field: 'aliasName',
			sortable: false,
			minWidth: 100,
			width: 100,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.SERVICE_PROVIDERS),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'entityId',
			name: this.locale.getString('administration.entityId'),
			field: 'entityId',
			sortable: false,
			minWidth: 160,
			width: 250,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.SERVICE_PROVIDERS),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'metadataFilename',
			name: this.locale.getString('administration.metadataFile'),
			field: 'aliasName',
			sortable: false,
			minWidth: 160,
			width: 320,
			cssClass: 'br-metadataFile',
			searchable: false,
			defaultSortColumn: SortDirection.ASC,
			formatter: this.downloadMetadataFileFormatter
		}, {
			id: 'keyPairFile',
			name: this.locale.getString('administration.keyPairFile'),
			field: 'keyPair.filename',
			sortable: false,
			minWidth: 160,
			width: 100,
			cssClass: 'br-keyPairFile',
			searchable: false,
			defaultSortColumn: SortDirection.ASC,
			formatter: this.getDownloadFileFormatter((serviceProvider) => serviceProvider.keyPair.filename)
		}, {
			id: 'createdDate',
			name: this.locale.getString('dashboard.createdDate'),
			field: 'createdDate',
			sortable: false,
			optional: true,
			minWidth: 100,
			width: 200,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'lastModifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'lastModifiedDate',
			sortable: false,
			optional: true,
			minWidth: 100,
			width: 200,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}];

		return Promise.resolve(columns);
	}

	getDownloadFileFormatter = (fileNameResolver: any): (...args: any) => string => {
		return (row, cell, value, columnDef, dataContext) => {
			return `<a href='javascript:void(0)'>${fileNameResolver(dataContext)}</a>`;
		};
	}

	downloadMetadataFileFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, serviceProvider: SamlServiceProvider) => {
		let metadataUrl = CONFIG.sso.authServerBaseUrl + '/saml/metadata/alias/' + serviceProvider.aliasName;
		return `<a href='${metadataUrl}' target='_blank'>clarabridge-saml-metadata-${serviceProvider.aliasName}.xml</a>`;
	}

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, serviceProvider: SamlServiceProvider) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(serviceProvider.entityId);
		return getSwitch(value);
	}
}

app.service('samlServiceProvidersGridDefinition', downgradeInjectable(SamlServiceProvidersGridDefinition));
