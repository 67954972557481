import * as _ from 'underscore';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterRuleTypes, FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';

export enum FilterRuleMode {
	EQUAL = 'EQUAL',
	RANGE = 'RANGE',
	OPEN_RANGE = 'OPEN_RANGE',
	TEXT = 'TEXT',
}

enum FilterMatchModeType {
	IS = 'IS',
	IS_NOT = 'IS_NOT',
	CATEGORIZED = 'CATEGORIZED',
	BETWEEN = 'BETWEEN',
	NOT_BETWEEN = 'NOT_BETWEEN',
	GTE = 'GTE',
	LTE = 'LTE',
	CONTAINS_ALL = 'CONTAINS_ALL',
	NOT_CONTAINS = 'NOT_CONTAINS',
	CONTAINS_ANY = 'CONTAINS_ANY',
	EXIST = 'EXIST',
	NOT_EXIST = 'NOT_EXIST',
}

export interface IFilterMatchMode {
	mode: FilterRuleMode;
	apiValue: FilterMatchModeValue;
	displayName: string;
	displayNameKey: string;
	validate: (rule: IFilterRule, treatEmptyAsAll?: boolean) => boolean;
	toString: ((rule: IFilterRule) => string);
	existMatch?: boolean;
	modelMatch?: boolean;
}

export class FilterMatchModes {

	definitions: {[key in FilterMatchModeType]: IFilterMatchMode};

	constructor(
		private readonly locale: ILocale
	) {
		this.definitions = {
			IS: {
				mode: FilterRuleMode.EQUAL,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.is'),
				displayNameKey: 'reportFilters.is',
				validate: this.isValidation,
				toString: this.valuesToString
			},

			IS_NOT: {
				mode: FilterRuleMode.EQUAL,
				apiValue: FilterMatchModeValue.IS_NOT,
				displayName: locale.getString('reportFilters.isNot'),
				displayNameKey: 'reportFilters.isNot',
				validate: this.isValidation,
				toString: this.valuesToString
			},

			CATEGORIZED: {
				mode: FilterRuleMode.EQUAL,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.categorized'),
				displayNameKey: 'reportFilters.categorized',
				validate: this.containsValidation,
				toString: () => '',
				modelMatch: true
			},

			BETWEEN: {
				mode: FilterRuleMode.RANGE,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.between'),
				displayNameKey: 'reportFilters.between',
				validate: this.betweenValidation,
				toString: this.inBetweenToString
			},

			NOT_BETWEEN: {
				mode: FilterRuleMode.RANGE,
				apiValue: FilterMatchModeValue.IS_NOT,
				displayName: locale.getString('reportFilters.notBetween'),
				displayNameKey: 'reportFilters.notBetween',
				validate: this.betweenValidation,
				toString: this.inBetweenToString
			},

			GTE: {
				mode: FilterRuleMode.OPEN_RANGE,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.gte'),
				displayNameKey: 'reportFilters.gte',
				validate: this.isValidation,
				toString: this.valuesToString
			},

			LTE: {
				mode: FilterRuleMode.OPEN_RANGE,
				apiValue: FilterMatchModeValue.IS_NOT,
				displayName: locale.getString('reportFilters.lte'),
				displayNameKey: 'reportFilters.lte',
				validate: this.isValidation,
				toString: this.valuesToString
			},

			CONTAINS_ALL: {
				mode: FilterRuleMode.TEXT,
				apiValue: FilterMatchModeValue.CONTAINS,
				displayName: locale.getString('reportFilters.containsAll'),
				displayNameKey: 'reportFilters.containsAll',
				validate: this.containsValidation,
				toString: this.valuesToString
			},

			NOT_CONTAINS: {
				mode: FilterRuleMode.TEXT,
				apiValue: FilterMatchModeValue.IS_NOT,
				displayName: locale.getString('reportFilters.doesNotContain'),
				displayNameKey: 'reportFilters.doesNotContain',
				validate: this.containsValidation,
				toString: this.valuesToString
			},

			CONTAINS_ANY: {
				mode: FilterRuleMode.TEXT,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.containsAny'),
				displayNameKey: 'reportFilters.containsAny',
				validate: this.containsValidation,
				toString: this.valuesToString
			},

			EXIST: {
				mode: FilterRuleMode.EQUAL,
				apiValue: FilterMatchModeValue.IS,
				displayName: locale.getString('reportFilters.hasAnyValue'),
				displayNameKey: 'reportFilters.hasAnyValue',
				validate: this.emptyValidation,
				toString: () => '',
				existMatch: true
			},

			NOT_EXIST: {
				mode: FilterRuleMode.EQUAL,
				apiValue: FilterMatchModeValue.IS_NOT,
				displayName: locale.getString('reportFilters.hasNoValue'),
				displayNameKey: 'reportFilters.hasNoValue',
				validate: this.emptyValidation,
				toString: () => '',
				existMatch: true
			}
		};
	}

	private containsValidation = (rule: IFilterRule): boolean => {
		return (rule.values && rule.values.length > 0);
	}

	private emptyValidation = (rule: IFilterRule): boolean => {
		return !this.containsValidation(rule);
	}

	private isValidation = (rule: IFilterRule, treatEmptyAsAll?: boolean): boolean => {
		if (this.isOpenRange(rule)) {
			return !isEmpty(rule.value) && _.isNumber(rule.value);
		}

		return treatEmptyAsAll || rule.values && rule.values.length > 0;
	}

	private betweenValidation = (rule: IFilterRule): boolean => {
		return (
			(!isEmpty(rule.from) && !isEmpty(rule.to)) && // values exist
			(rule.from < rule.to) && // start point < end point
			(String(rule.from).length > 0) && (String(rule.to).length > 0)); // length >0
	}

	private valuesToString = (rule: IFilterRule) => {
		if (!_.isEmpty(rule?.values)) {
			return rule.values.map(r => r.text).join(', ');
		}

		return rule?.value;
	}

	private inBetweenToString = (rule: IFilterRule): string => {
		return `${rule.from} AND ${rule.to}`;
	}

	onLanguageChange = (): void => {
		for (let key in this.definitions) {
			if (this.definitions.hasOwnProperty(key)) {
				const displayName = this.locale.getString(this.definitions[key].displayNameKey);
				this.definitions[key].displayName = displayName;
			}
		}
	}

	valueOf = (rule: IFilterRule): IFilterMatchMode | undefined => {
		if (!rule)
			return;
		let exists = (FilterRuleTypes.isTopicRule(rule) && this.isModelMatch(rule)) || this.isExistMatch(rule);
		return this.getMatchMode(rule.type, exists, rule.matchMode);
	}

	getMatchMode(type: FilterRuleType, exists: boolean, matchMode: FilterMatchModeValue): IFilterMatchMode {
		if (type === FilterRuleType.topicEquality && exists) {
			return this.definitions.CATEGORIZED;
		}

		if (exists) {
			return matchMode === FilterMatchModeValue.IS ? this.definitions.EXIST : this.definitions.NOT_EXIST;
		}

		if (type === FilterRuleType.numericRange) {
			return matchMode === FilterMatchModeValue.IS ? this.definitions.BETWEEN : this.definitions.NOT_BETWEEN;
		} else if (type === FilterRuleType.numericOpenRange) {
			return matchMode === FilterMatchModeValue.IS ? this.definitions.GTE : this.definitions.LTE;
		} else {
			return matchMode === FilterMatchModeValue.IS ? this.definitions.IS : this.definitions.IS_NOT;
		}
	}

	isModelMatch = (rule: IFilterRule) => {
		if (rule.modelMatch !== undefined || rule.isModelMatch !== undefined) {
			return rule.modelMatch || rule.isModelMatch;
		} else {
			return rule.values && rule.values.length === 1 &&
				/^\d+$/.test(rule.values[0].idPath);
		}
	}

	isRange(filter: IFilterRule): boolean {
		return filter?.type === FilterRuleType.numericRange;
	}

	isOpenRange(filter: IFilterRule): boolean {
		return filter?.type === FilterRuleType.numericOpenRange;
	}

	isExistMatch = (rule: IFilterRule): boolean => {
		return rule?.existMatch;
	}

	isEqual = (rule: IFilterRule): boolean => {
		return rule && [FilterRuleType.numericEquality, FilterRuleType.stringEquality].contains(rule.type);
	}

}

app.service('filterMatchModes', FilterMatchModes);
