import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import PopoverUtils from '@cxstudio/services/popover-utils.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { DashboardHistoryStateService } from '@app/modules/dashboard-actions/undo/dashboard-history-state.service';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { DashboardRenameAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-rename-action';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

export class SecondaryHeaderComponent implements ng.IComponentController {

	dashboard: Dashboard;
	dashboardHistory: IDashboardHistoryInstance;
	dashboardFilters;
	filterPopoverOpen: boolean;
	oldName: any;
	newName: any;
	initialChange: boolean = true;

	dashboardProps = this.dashboardPropsService;
	showDashboardRating = this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_RATING)
		&& !this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES);

	constructor(
		private popoverUtils: PopoverUtils,
		private dashboardPropsService: DashboardPropsService,
		private dashboardFiltersService: DashboardFiltersService,
		private maPropertiesService: MAPropertiesService,
		private betaFeaturesService: BetaFeaturesService,
		private dashboardHistoryState: DashboardHistoryStateService,
		private $timeout: ng.ITimeoutService,
		private locale: ILocale,
		private $scope: ISimpleScope,
		private currentObjects: CurrentObjectsService
	) {}

	$onInit = () => {
		// required for typescript validation
		this.$scope.$on('popover:close', (event) => {
			this.toggleDashboardFiltersPopover();
			$('#filters-popover-toggle').trigger('focus');
		});
	}

	dashboardNameFocus = (dashboardName: any) => {
		this.oldName = dashboardName;
	}

	dashboardNameUnfocus = (dashboardName: any) => {
		this.newName = dashboardName;
		if (this.newName !== this.oldName) {
			let actions: DashboardChangeAction[] = [];
			actions.push(new DashboardRenameAction(this.oldName, this.newName, this.dashboardProps));
			this.dashboardHistoryState.addDashboardChange(DashboardChangeType.RENAME, actions);
		}
	}

	dashboardNameChanged = () => {
		return this.dashboardHistory.dashboardNameChanged();
	}

	addFilterChange = (type, currFilter) => {
		if (this.currentObjects.isEditMode()) {
			this.dashboardHistory.addFilterChange({ type, filter: currFilter });
		} else {
			this.dashboardHistory.addViewFilterChange({ type, filter: currFilter });
		}
	}
	getFiltersAriaLabel = (): string => {
		return this.locale.getString('filter.viewDashboardFilters', { name: this.dashboard?.name || '' });
	}

	toggleDashboardFiltersPopover = (): void => {
		this.filterPopoverOpen = !this.filterPopoverOpen;
		if (this.filterPopoverOpen) {
			this.popoverUtils.initOutsideClickHandler('.popover-content', this.getDashboardFiltersPopoverClickHandlerName,
				this.closeDashboardFiltersPopover);
		} else {
			$(document).off(this.getDashboardFiltersPopoverClickHandlerName());
		}
	}

	closeDashboardFiltersPopover = (): void => {
		this.filterPopoverOpen = false;
	}

	private getDashboardFiltersPopoverClickHandlerName = (): string => 'click.dashboardFiltersPopup';

	showFilterDropdown = (): boolean => {
		if (this.currentObjects.isEditMode())
			return true;
		let isHierarchyNodeSelectable = this.dashboardFilters.personalization && this.dashboardFilters.personalization.isHierarchySelectable();
		return this.dashboard
			&& this.dashboardFiltersService.canChangeFilters(this.dashboard.appliedFiltersArray, isHierarchyNodeSelectable);
	}

	getStarIcon = (star: number): string => {
		let rating = Math.round(this.dashboard.rating);
		if (rating >= star * 2)
			return 'q-icon-star';
		if (rating < star * 2 && rating >= star * 2 - 1)
			return 'q-icon-star-half-outline';
		return 'q-icon-star-outline';
	}

	clickToggleButton = (): void => {
		this.toggleDashboardFiltersPopover();
		this.$timeout(() => {
			$('#dashboard-filters-edit :focusable').first().trigger('focus');
		}, 100);
	}

	onFilterPopoverKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.toggleDashboardFiltersPopover();
			this.$timeout(() => $('#dashboard-filters-edit :focusable').first().trigger('focus'), 100);
		}
	}

	snapshotViewChange = (value: boolean): void => {
		this.currentObjects.setSnapshotView(value);
	}

}

app.component('secondaryHeader', {
	bindings: {
		dashboard: '<',
		editMode: '<',
		dashboardFilters: '<',
		isTablet: '<',
		isMobile: '<',
		dashboardMenu: '<',
		hierarchyAccessDenied: '<',
		isEditedFromBook: '<',
		processSaveButton: '<',
		processCancelButton: '<',
		returnToBook: '<',
		processEditButton: '<',
		applyAttributeFilters: '<',
		dashboardHistory: '<',
		projectAttributesFiltered: '<',
	},
	controller: SecondaryHeaderComponent,
	templateUrl: 'partials/header/secondary-header.component.html'
});
