<div class="input-group">
	<input ngbDatepicker
		#datepicker="ngbDatepicker"
		placeholder="yyyy-mm-dd"
		name="datepicker"
		class="date-picker-input"
		[(ngModel)]="value"
		(ngModelChange)="onValueChange()"
		[minDate]="minDate"
		[maxDate]="maxDate"
		[autoClose]="true"
		readonly>
	<button
		type="button"
		class="btn btn-primary q-icon-calendar"
		(click)="datepicker.toggle()"></button>
</div>
