import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { HomePageWidgetsPanelComponent } from '@app/modules/home-page/home-page-widgets/home-page-widgets-panel/home-page-widgets-panel.component';
import { QuickInsightsQuery } from '@app/modules/home-page/quick-insights/quick-insights/quick-insights-page/quick-insights-query';

@Component({
	selector: 'dashboards-home-page',
	templateUrl: './dashboards-home-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardsHomePageComponent implements OnInit {

	@ViewChild(HomePageWidgetsPanelComponent)
	private readonly homePageWidgetsPanelComponent!: HomePageWidgetsPanelComponent;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxLocation: CxLocationService,
	) { }

	ngOnInit(): void {

	}

	openQuickSearch(searchValue: AttributeValueOption): void {
		this.cxLocation.path('/quick-insights').search(QuickInsightsQuery.toQueryParams(searchValue));
	}

	onNodeChange(node: IHierarchyNode): void {
		if (this.homePageWidgetsPanelComponent) {
			(this.homePageWidgetsPanelComponent as any).hierarchyNodeChange(node);
		}
	}

}

app.directive('dashboardsHomePage', downgradeComponent({component: DashboardsHomePageComponent}) as angular.IDirectiveFactory);
