import * as _ from 'underscore';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';

interface IPaletteEditorScope extends ISimpleScope {
	formSettings: any; // UI form validation
}

interface IPaletteEditorParams {
	palette: ColorPalette;
	itemList: ColorPalette[];
}

export interface ColorItem {
	color: string;
	placeholder?: boolean;
}

export class ColorPaletteEditorController extends ModalBindings<IPaletteEditorParams> implements ng.IController {

	readonly DEFAULT_COLOR = '#000000';
	readonly MAX_PALETTE_COLORS = 10;

	palette: ColorPalette;
	initialState: {
		displayName: string,
		colors: string[]
	};

	colorItems: ColorItem[];

	copyToClipboardText: string = this.locale.getString('appearance.copyPaletteToClipboard');

	constructor(
		private $scope: IPaletteEditorScope,
		private locale: ILocale,
		private readonly downgradeDialogService: DowngradeDialogService,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService
	) {
		super();
	}

	$onInit(): void {
		this.palette = angular.copy(this.resolve.palette);
		this.colorItems = _.map(this.palette.colors, color => {
			return {
				color
			};
		});
		this.initialState = this.getCurrentState();
	}

	getModalTitle = (): string => {
		if (!_.isUndefined(this.palette.id))
			return this.locale.getString('common.edit');
		else return  this.locale.getString('common.create');
	}

	private getCurrentState(): any {
		return {
			displayName: this.palette.displayName,
			colors: _.pluck(this.colorItems, 'color')
		};
	}

	save = (): void => {
		this.palette.colors = _.pluck(this.colorItems, 'color');
		this.close({$value: this.palette});
	}

	cancel = (): void => {
		if (this.hasChanges()) {
			this
				.downgradeDialogService
				.showUnsavedChangesDialogAndResolve(
					this.save,
					this.dismiss,
					'appearance.unsavedChangesHeader',
					'appearance.unsavedChanges',
					false
				)
				.catch(() => {})
			;

			return;
		}

		this.dismiss();
	}

	isSaveDisabled = (): boolean => {
		let isNotUnique = this.$scope.formSettings.name.$error.unique;
		let isNameEmpty = !this.palette.displayName || _.isEmpty(this.palette.displayName.trim());
		let hasNoColors = _.isEmpty(this.colorItems);
		return isNotUnique || isNameEmpty || hasNoColors;
	}

	private hasChanges(): boolean {
		return !angular.equals(this.initialState, this.getCurrentState());
	}

	addNewPalette = () => {
		this.colorItems.push({color: this.DEFAULT_COLOR});
	}

	isAddDisabled = (): boolean => {
		return this.colorItems.length >= this.MAX_PALETTE_COLORS;
	}

	removeColor = (colorItem: ColorItem) => {
		this.colorItems.remove(colorItem);
	}

	dragStop = (colorItem: ColorItem) => {
		if (colorItem) // sometimes is null
			colorItem.placeholder = false;
	}

	dragMove = (colorItem: ColorItem, event) => {
		if (event.tx !== 0 || event.ty !== 0) // to avoid flickering on click
		colorItem.placeholder = true;

		// finds a proper place to insert current tab
		// logic is based on dragging over the center of an element,
		// i.e. if left/right border reaches the center of another tab, it's inserted into that place
		let clone = $('#palette-clone');
		let centerX = clone.offset().left + clone.width() / 2;
		let closestItem = this.findDropCandidate(centerX, event.element);
		let index = closestItem.index();

		if (index === -1) // current tab or outside
			return;

		let direction = index - this.colorItems.indexOf(colorItem);
		let needChange = false;
		let targetCenter = closestItem.offset().left + closestItem.width() / 2;

		if (direction > 0) {
			let rightBorder = centerX + event.element.width() / 2;
			needChange = rightBorder > targetCenter;
		} else {
			let leftBorder = centerX - event.element.width() / 2;
			needChange = leftBorder < targetCenter;
		}
		if (needChange) {
			this.colorItems.remove(colorItem);
			this.colorItems.insert(index, colorItem);
		}
	}

	private findDropCandidate(centerX, draggingElement): JQuery {
		let container = $('#palettes-container');
		return container.find('.palette-color').filter((index, element) => {
			if ($(element).is(draggingElement)) // current dragging item
				return false;
			let left = $(element).offset().left;
			let right = left + $(element).width();
			return left <= centerX && right >= centerX;
		});
	}

	getDialogLeftOffset = (): number => {
		return this.$element.offset().left;
	}

	applyImport = (paletteString: string): void => {
		if (!paletteString || !paletteString.length) return;

		let matches = paletteString.match(/#[0-9A-F]{6}/ig);
		if (matches?.length) {
			this.colorItems = matches.slice(0, this.MAX_PALETTE_COLORS)
				.map((color: string) => {
					return {color};
				});
		}
	}

	getColorsForClipboard = (): string => {
		return this.colorItems.map(colorDefinition => colorDefinition.color)
			.join(', ')
			.replace(/[^#0-9A-Fa-f,\s]/g, '');
	}

	paletteCopied(): void {
		this.copyToClipboardText = this.locale.getString('common.copied');
		this.$timeout(
			() => this.copyToClipboardText = this.locale.getString('appearance.copyPaletteToClipboard'),
			3000);
	}
}

app.component('colorPaletteEditor', {
	controller: ColorPaletteEditorController,
	templateUrl: 'partials/master-accounts/appearance/color-palette-editor.component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
