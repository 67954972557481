import { Injectable } from '@angular/core';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { CxLocaleService } from '@app/core';
import { ArchiveListActions } from '@app/modules/archive/archive-page/archive-list-actions.service';
import { ArchiveItem } from '@app/modules/archive/archive-page/archive-item';


@Injectable()
export class ArchiveListMenu extends BaseContextMenuUtils {

	constructor(
		private locale: CxLocaleService, 
		private actions: ArchiveListActions
	) {
		super();
	}

	private readonly OPTIONS: {[name: string]: ContextMenuItem<ArchiveItem>} = {
		RESTORE: {
			name: 'restore',
			text: this.locale.getString('archive.restore'),
			func: (item: ArchiveItem) => this.actions.restore(item)
		},
		RESTORE_TO: {
			name: 'restore_to',
			text: this.locale.getString('archive.restoreTo'),
			func: (item: ArchiveItem) => this.actions.restoreTo(item)
		}
	};

	getContextMenu(item: ArchiveItem): Array<ContextMenuItem<ArchiveItem>> {
		return item.ownerId ? [this.OPTIONS.RESTORE] : [this.OPTIONS.RESTORE_TO];
	}

	isVisibleObject(item: ArchiveItem): boolean {
		return true;
	}
}