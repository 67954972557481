import * as _ from 'underscore';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';


export interface ISelectedReportGroupings {
	populateTopicFields: (topic?: ReportGrouping) => void;
	setTopicSubtopic: (topic?: ReportGrouping) => void;
}

export interface IPropsWithGrouping {
	selectedAttributes: ReportGrouping[];
}

export class SelectedReportGroupings implements ISelectedReportGroupings {
	props: IPropsWithGrouping;

	constructor(props: IPropsWithGrouping) {
		this.props = props;
	}

	//add new or remove old
	populateTopicFields(topic?: ReportGrouping): void {
		if (topic && !AnalyticMetricTypes.isTopics(topic)) {
			return;
		}

		let chain = _.chain(this.props.selectedAttributes).filter(AnalyticMetricTypes.isTopics);

		if (topic) {
			chain = chain.filter((group: ReportGrouping) => {
				return group.name === topic.name;
			});
		}
		chain.groupBy((group: ReportGrouping): string => group.name)
			.values()
			.forEach((groups: ReportGrouping[]) => {
				if (groups.length > 1) {
					let subtopicsOnly: boolean = _.some(groups, group => group.subtopicsOnly !== false);
					groups.forEach((group: TopicReportGrouping) => {
						group.showLevel = true;
						group.subtopicsOnly = subtopicsOnly;
					});
				} else {
					groups[0].showLevel = false;
					groups[0].subtopicsOnly = false;
				}
			});
	}

	//change subtopics when open cog
	setTopicSubtopic(topic: ReportGrouping): void {
		if (!AnalyticMetricTypes.isTopics(topic)) {
			return;
		}
		_.chain(this.props.selectedAttributes).filter(AnalyticMetricTypes.isTopics)
			.filter((group) => {
				return group.name === topic.name;
			})
			.forEach((group: TopicReportGrouping) => {
				group.subtopicsOnly = topic.subtopicsOnly;
			});
	}

}
