import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';
import { TranscriptPiece } from '@cxstudio/reports/entities/transcript-piece';
import { SentenceScorecardTopic, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';

import * as cloneDeep from 'lodash.clonedeep';

export class AudioDocumentFormat {
	private static readonly MAX_GROUPING_DURATION = 30;

	static combineSentences(transcriptPieces: TranscriptPiece[]): TranscriptPiece[] {
		let newTranscript = [];

		let i = 0;
		while (i < transcriptPieces.length) {
			let j = 1;
			let thisPiece: TranscriptPiece = cloneDeep(transcriptPieces[i]);
			if (this.isUnmergeableSentence(thisPiece)) {
				i += j;
				newTranscript.push(thisPiece);
				continue;
			}

			let nextPiece = transcriptPieces[i + j];
			while (nextPiece?.isSameChannelAsLast && this.isNextSentenceMergeable(thisPiece, nextPiece)) {
				thisPiece.mergedSentences = thisPiece.mergedSentences || [];
				thisPiece.mergedSentences.push(nextPiece.id);
				thisPiece.chunks = thisPiece.chunks.concat(nextPiece.chunks);
				thisPiece.text += ' ' + nextPiece.text;
				thisPiece.endTimestamp = nextPiece.endTimestamp;
				(thisPiece as ConversationSentence).wordCount = this.addWordCount(thisPiece, nextPiece);

				// use the first available value for sentiment, emotion, if any merged sentence has values for those
				if (_.isUndefined((thisPiece as unknown as ConversationDataPoint).emotionIntensity)) {
					(thisPiece as unknown as ConversationDataPoint).emotionIntensity = (nextPiece as unknown as ConversationDataPoint).emotionIntensity;
				}

				if (_.isUndefined(thisPiece.dScore) || thisPiece.dScore === 0) {
					thisPiece.dScore = nextPiece.dScore;
				}

				thisPiece.attributes = this.combineEnrichments(thisPiece, nextPiece);
				thisPiece.scorecardTopics = this.combineScorecardTopics(thisPiece, nextPiece);
				thisPiece.sentenceTopics = this.combineTopics(thisPiece, nextPiece);
				j++;
				nextPiece = transcriptPieces[i + j];
			}
			i += j;

			newTranscript.push(thisPiece);
		}

		return newTranscript;
	}

	// these types should never be merged with others
	private static isUnmergeableSentence(piece: TranscriptPiece): boolean {
		return piece.isSilence || piece.isOvertalk;
	}

	private static addWordCount(thisPiece: ConversationSentence, nextPiece: ConversationSentence): number | undefined {
		let combinedCount = (thisPiece.wordCount || 0) + (nextPiece.wordCount || 0);
		return combinedCount > 0 ?
			combinedCount :
			undefined;
	}

	private static isNextSentenceMergeable(thisPiece: TranscriptPiece, nextPiece: TranscriptPiece): boolean {
		return (nextPiece.timestamp - thisPiece.timestamp < this.MAX_GROUPING_DURATION)
			&& !nextPiece.isOvertalk
			&& (nextPiece as any).easeScore === (thisPiece as any).easeScore;
	}

	private static combineEnrichments(
		sentence1: TranscriptPiece, sentence2: TranscriptPiece): {[key: string]: Array< number | string >} {
		
		let newAttributes = _.extend({}, sentence1.attributes);
		for (let key of _.keys(sentence2.attributes)) {
			if (newAttributes[key]) {
				newAttributes[key] = _.uniq(newAttributes[key].concat(sentence2.attributes[key]));
			} else {
				newAttributes[key] = sentence2.attributes[key];
			}
		}

		return newAttributes;
	}

	private static combineTopics(sentence1: TranscriptPiece, sentence2: TranscriptPiece): SentenceTopic[] {
		let sentenceTopics = sentence1.sentenceTopics || [];
		return _.uniq(sentenceTopics.concat(sentence2.sentenceTopics || []), false, (topic) => topic.id);
	}

	private static combineScorecardTopics(
			sentence1: TranscriptPiece, sentence2: TranscriptPiece): {[key: number]: Array<SentenceScorecardTopic>} {
		
		let newScorecards = _.extend({}, sentence1.scorecardTopics);
		for (let key of _.keys(sentence2.scorecardTopics)) {
			if (newScorecards[key]) {
				newScorecards[key] = _.uniq(newScorecards[key].concat(sentence2.scorecardTopics[key]), false, (item) => item.id);
			} else {
				newScorecards[key] = sentence2.scorecardTopics[key];
			}
		}

		return newScorecards;
	}
}
