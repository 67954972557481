<div>
	<span class="d-block pb-16" [i18n]="'administration.grantAccessToCP'"></span>
	<div>
		<label>
			<input
				id="link-user-later"
				type="radio"
				class="checkbox d-inline"
				[(ngModel)]="skipLinkUser"
				[value]="true"
				(change)="updateSkipLinkUser.emit(skipLinkUser)">
			{{'administration.linkToCPLater'|i18n}}
		</label>
		<br>
		<label>
			<input
				id="link-user-now"
				type="radio"
				class="checkbox d-inline"
				[(ngModel)]="skipLinkUser"
				[value]="false"
				(change)="updateSkipLinkUser.emit(skipLinkUser)">
			{{'administration.linkToCPNow'|i18n}}
		</label>
	</div>
	<div *ngIf="!skipLinkUser" class="add-user-cps">
		<div class="modal-body">
			<label>{{'mAccount.selectCP'|i18n}}</label>
			<content-providers
				[accountMap]="selectedAccounts"
				(accountMapUpdate)="onSelectedAccountsUpdate()">
			</content-providers>
		</div>
	</div>
</div>