import { Inject, Injectable } from '@angular/core';
import { MasterAccountPropertiesApiService } from '../account-administration/api/master-account-properties-api.service';
import { CxLocaleService } from '@app/core';
import { ConfigService } from '@app/core/config.service';
import { ExternalRedirectDomains } from '@cxstudio/master-accounts/master-account-properties.class';
import { CxDialogService } from '../dialog/cx-dialog.service';
import { ExternalUrlService } from './external-url.service';
import { TypeGuards } from '@app/util/typeguards.class';

@Injectable({
	providedIn: 'root'
})
export class RedirectInterceptService {
	allowedDomains: string[];
	isInterceptingEnabled: boolean;

	constructor(
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private configService: ConfigService,
		private masterAccountPropertiesApiService: MasterAccountPropertiesApiService,
		private externalUrlService: ExternalUrlService,
		@Inject(Window) private readonly window: Window
	) {
		this.allowedDomains = this.configService.getConfig().externalRedirect?.whitelistedDomains?.split(',') ?? [];

		this.masterAccountPropertiesApiService.getExternalRedirectDomains()
			.then((externalRedirectDomains: ExternalRedirectDomains) => {
				this.isInterceptingEnabled = externalRedirectDomains.enabled;
				this.allowedDomains.push(...(externalRedirectDomains.domains ? externalRedirectDomains.domains : []));
			})
			.catch(() => {});
	}

	/**
	 * Process a click event to see if it opens link that we need to intercept
	 */
	processClick(event: MouseEvent) {
		if (!this.isInterceptingEnabled) {
			return;
		}

		if (!TypeGuards.isHTMLElement(event.target)) {
			return;
		}

		const anchor = event.target.closest('a');

		if (!anchor) {
			return;
		}

		const href = anchor.getAttribute('href') || '';
		this.processUrl(href, event);
	}

	/**
	 * Check URL directly and open it if domain is approved, or prompt for confirmation if domain is not approved
	 */
	processUrl(url: string, event?: MouseEvent) {
		if (!url || !TypeGuards.isString(url)) {
			return;
		}

		if (!this.isInterceptingEnabled) {
			this.window.open(url, '_blank', 'noreferrer').focus();
			return;
		}

		if (this.externalUrlService.isExternalUrl(this.allowedDomains, url)) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}

			this.showConfirmationDialog(url);
		}
	}

	private showConfirmationDialog(href: string) {
		const message = this.locale.getString('common.redirectToExternalPageWarning', {
			targetUrl: href,
		});

		this.cxDialogService
			.warningWithConfirm(
				this.locale.getString('administration.externalRedirect'),
				message,
				this.locale.getString('common.confirm')
			)
			.result.then(() => {
				this.window.open(href, '_blank', 'noreferrer').focus();
			})
			.catch(() => { });
	}
}
