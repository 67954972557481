export class GroupColorUtils {
	static GROUP_COLOR_PREFIX = '_group_color.';

	static isGroupColor(colorName): boolean {
		return colorName && colorName.indexOf(GroupColorUtils.GROUP_COLOR_PREFIX) !== -1;
	}

	static getGroupColorAttributeName(colorName): string {
		return colorName && colorName.substring(GroupColorUtils.GROUP_COLOR_PREFIX.length, colorName.length);
	}
}
