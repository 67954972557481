app.filter('hierarchySearchFilter', () => {
	function searchFilter<T extends {children?: T[], _searchMatched: boolean}>(nodes: T[],
		search: string, displayProperty: keyof T, parent?: T): T[] {
		if (!nodes) {
			return [];
		}

		return _.filter(nodes, (node) => {
			if (!search || search.trim().length < 1)
				return true;

			let directMatch = checkMatch(node[displayProperty], search);
			let parentMatch = parent && parent._searchMatched;
			if (node.children) // populate only folders to not change attributes
				node._searchMatched = directMatch || parentMatch;

			let childrenMatch = searchFilter(node.children, search, displayProperty).length;

			return !!(directMatch || parentMatch || childrenMatch);
		});
	}

	function checkMatch(text, search): boolean {
		return (text.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0);
	}

	return searchFilter;
});
