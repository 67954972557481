<div class="grid-list-tools">
	<div class="tools">
		<div class="grid-filters">
			<label class="font-bold mb-0">
				{{'common.find'|i18n}}
				<input
					type="text"
					class="grid-search-bar d-block"
					attr.aria-label="{{'common.find'|i18n}}"
					[(ngModel)]="betaSearch"
					[debounce]="300">
			</label>
		</div>
		
		<div class="grid-buttons">
			<button
				type="button"
				class="btn btn-primary"
				(click)="selectAll()"
				[i18n]="'common.selectAll'">
			</button>
			<button
				type="button"
				class="btn btn-secondary"
				(click)="deselectAll()"
				[i18n]="'common.deselectAll'">
			</button>
		</div>
	</div>
</div>

<div class="beta-features-checklist-grid">
	<item-grid
		class="br-grid"
		[treeData]="betaFeatures"
		[gridOptions]="gridOptions"
		[gridFilter]="betaSearch"
		[gridType]="gridType"
		[gridChange]="changedItems"
		[uiOptions]="{autoHeight: true, rowHeight: 64}">
	</item-grid>
</div>