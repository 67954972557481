import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { SigningAlgorithm } from '@app/modules/system-administration/saml-settings/entities/saml-signinig-algorithms';
import { LogoutMechanism, SamlIdentityProvider, LdapSearchFilter } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { Security } from '@cxstudio/auth/security-service';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { SamlServiceProvider } from '@app/modules/system-administration/saml-settings/entities/saml-service-provider';
import { SamlServiceProviderApiService } from '@app/modules/system-administration/saml-settings/saml-service-provider-api.service';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import { SamlIdentityProviderApiService } from '../saml-identity-provider-api.service';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import {
	OAuthClientDetailsApiService
} from '@app/modules/system-administration/oauth/service/oauth-client-details-api.service';

export interface SamlIdenityProviderDialogInput {
	identityProvider: SamlIdentityProvider;
	allIdentityProviders: SamlIdentityProvider[];
}

@Component({
	selector: 'saml-identity-provider-dialog',
	templateUrl: './saml-identity-provider-dialog.component.html'
})

export class SamlIdentityProviderDialogComponent implements OnInit, AfterViewInit {
	@Input() input: SamlIdenityProviderDialogInput;

	@ViewChild('identityProviderForm', {static: false}) identityProviderForm: NgForm;
	@ViewChild('metadataUploaderButton', {static: true}) metadataUploaderButton: ElementRef;

	private identityProviderFormStatus$: Subscription;
	public readonly MediaType = MediaType;

	oauthClientDetails: OAuthClientDetails[];

	serviceProviders: SamlServiceProvider[];

	identityProvider: SamlIdentityProvider;
	invalidFields: any[] = [];
	identityProviderAlreadyExists: boolean = false;

	signatureAlgorithms: ListOption<string>[] = [
		{ value: SigningAlgorithm.SHA_1, name: this.locale.getString('mAccount.labelSignatureAlgorithmSHA1') },
		{ value: SigningAlgorithm.SHA_256, name: this.locale.getString('mAccount.labelSignatureAlgorithmSHA256') }
	];

	ldapSearchFilters: ListOption<string>[] = [
		{ value: LdapSearchFilter.USER_EMAIL, name: this.locale.getString('mAccount.labelAuthEmailAttr') },
		{ value: LdapSearchFilter.UNIQUE_ID, name: this.locale.getString('mAccount.labelAuthUniqueIdAttr') }
	];

	logoutOptions: ListOption<string>[] = [
		{ value: LogoutMechanism.LOCAL, name: this.locale.getString('mAccount.labelLocalLogout') },
		{ value: LogoutMechanism.GLOBAL, name: this.locale.getString('mAccount.labelGlobalLogout') }
	];

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		private identityProviderApi: SamlIdentityProviderApiService,
		private serviceProviderApi: SamlServiceProviderApiService,
		private oauthClientDetailsApiService: OAuthClientDetailsApiService,
		@Inject('urlService') private urlService: UrlService,
		@Inject('security') private security: Security,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService
	) {
	}

	onAliasNameInput = _.debounce(() => this.validateAliasName(), 300);

	ngOnInit(): void {
		this.identityProvider = cloneDeep(this.input.identityProvider);

		this.reloadOAuthClientDetails();
		this.reloadServiceProviders();
	}

	ngAfterViewInit(): void {
		this.identityProviderFormStatus$ = this.identityProviderForm.statusChanges.subscribe(() => {
			let controls = this.identityProviderForm.controls;
			this.invalidFields = Object.keys(controls)
				.filter(key => (!controls[key].valid && controls[key].status !== 'DISABLED'));
		});
	}

	reloadOAuthClientDetails = (): void => {
		this.oauthClientDetailsApiService.getInternalClientDetailsList()
			.then((clientDetails: OAuthClientDetails[]) => {
				this.oauthClientDetails = clientDetails;
			});
	}

	private reloadServiceProviders(): void {
		this.serviceProviderApi.getServiceProviders().then(serviceProviders => {
			this.serviceProviders = serviceProviders;
		});
	}

	validateAliasName(): void {
		let availableIdentityProvider = _.findWhere(this.input.allIdentityProviders, { aliasName: this.identityProvider.aliasName});
		if (!_.isUndefined(availableIdentityProvider)) {
			this.identityProviderForm.controls['aliasName'].setErrors({exist: true});
		}
	}

	validateEntityId(): void {
		let initialEntityId = this.input.identityProvider.entityId;
		let availableIdentityProvider = _.findWhere(this.input.allIdentityProviders, { entityId: this.identityProvider.entityId});
		this.identityProviderAlreadyExists = !_.isUndefined(availableIdentityProvider)
				&& initialEntityId !== availableIdentityProvider.entityId;
	}

	isSettingsValid(): boolean {
		return !_.isEmpty(this.identityProvider.aliasName)
			&& !this.identityProviderAlreadyExists
			&& this.invalidFields.length === 0;
	}

	isAliasNameDisabled(): boolean {
		return this.identityProvider.linkedMasterAccounts?.length > 0;
	}

	getAliasNameTitle(): string {
		return this.isAliasNameDisabled()
			? this.locale.getString('mAccount.aliasNameDisabledTitle')
			: '';
	}

	save(): void {
		this.input.identityProvider = this.identityProvider;
		this.modal.close(this.input.identityProvider);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	public getPreviouslyUploadedFile(): string | undefined {
		return this.identityProvider?.metadataFilename;
	}

	public getFileUploadUrl(): string {
		return this.urlService.getAPIUrl(`rest/saml/identity-provider/metadata/upload`);
	}

	public handleSuccessfulUpload(responseData: { filename: string, entityId: string }) {
		this.identityProvider.metadataFilename = responseData.filename;
		this.identityProvider.entityId = responseData.entityId;
		this.validateEntityId();
	}

	public handleFailedUpload() {
		this.identityProvider.metadataFilename = '';
		this.identityProvider.entityId = '';
	}

	public handleRemovedUpload() {
		if (isEmpty(this.identityProvider.metadataFilename)) {
			return;
		}

		this.identityProviderApi.removeMetadataFile(this.identityProvider.metadataFilename).then(() => {
			this.identityProvider.metadataFilename = '';
			this.identityProvider.entityId = '';
		});
	}
}
