import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';


export default class HierarchyItemTemplateController implements ng.IComponentController {

	item: any;
	intersectionObserver: IntersectionObserver;
	showCheckboxes: boolean;

	triggerClick: (item: any, event: MouseEvent) => void;

	constructor(
		private $element: ng.IAugmentedJQuery) {

	}

	$onInit(): void {
		if (this.intersectionObserver && this.isAttributeStatsItem(this.item)) {
			this.intersectionObserver.observe(this.$element[0]);
		}
	}

	$onDestroy(): void {
		if (this.intersectionObserver && this.isAttributeStatsItem(this.item)) {
			this.intersectionObserver.unobserve(this.$element[0]);
		}
	}

	isAttributeStatsItem(item): boolean {
		return item && (AnalyticMetricTypes.isAttribute(item) || AnalyticMetricTypes.isTopics(item));
	}

	handleClick(event: MouseEvent): void {
		if (this.showCheckboxes) {
			event.stopPropagation();
			this.triggerClick(this.item, event);
		}
	}
}

app.component('hierarchyItemTemplate', {
	controller: HierarchyItemTemplateController,
	templateUrl: 'partials/components/hierarchy-item-template.component.html',
	bindings: {
		item: '<',
		showCheckboxes: '<',
		triggerClick: '<',
		displayProperty: '<',
		itemTemplate: '<',
		limitedWidth: '<',
		intersectionObserver: '<'
	}
});
