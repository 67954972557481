<div [ngBusy]="loading">
	<div class="row">
		<div class="col-sm-12">
			<div class="form-group" *ngIf="!isWorkspaceEnabled">
				<label [i18n]="'packages.contentProviderSelectionTitle'"></label>
				<project-selector
					class="d-flex l-margin-offset-5"
					horizontal="true"
					(projectSelectionChange)="onProjectSelectionChange($event)"
					(errorsChange)="onCpErrorsChange($event)"
					(loading)="onProjectsLoading($event)"
				></project-selector>
				<alert type="danger" *ngFor="let cperror of showErrorsForCP">
					<span>{{ cperror }}</span>
				</alert>
			</div>

			<div class="form-group" *ngIf="isWorkspaceEnabled">
				<label [i18n]="'packages.workspaceSelectionTitle'"></label>
				<workspace-project-selector
					labelPosition="top"
					[help]="true"
					[inline]="true"
					(workspaceChange)="onWorkspaceChange($event)"
					(valueChange)="onProjectChange($event)"
					(loading)="onProjectsLoading($event)"
				></workspace-project-selector>
			</div>
		</div>
	</div>
</div>