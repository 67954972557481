import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';

@Injectable({
	providedIn: 'root'
})
export class WidgetDriversAccessService {
	constructor(
		private driversApi: DriversApi,
	) {
	}

	hasPrivateDrivers(widgets: Widget[]): Promise<boolean> {
		let drivers = _.chain(widgets)
			.map(widget => _.union(widget.properties?.selectedAttributes))
			.flatten()
			.filter(asset => asset.type === ReportAssetType.DRIVERS)
			.unique(asset => asset.id)
			.value();

		if (!drivers.length) {
			return Promise.resolve(false);
		}

		return this.driversApi.verifyDriversAccess(drivers).then(accessMap => {
			return _.contains(Object.values(accessMap), false);
		});
	}
}
