export default class OAuthExternalProvider {
	id: string;
	aliasName: string;

	clientId: string;
	clientSecret: string;

	scope: string[];

	authorizationCodeUri: string;
	createTokenUri: string;
	userInfoUri: string;

	checkTokenUri: string; // deprecated
	accessTokenParameterName: string; // deprecated

	userIdentityField: string = 'email';
	userSearchField: string = 'emailAddress';

	landingPage?: string;
	linkedMasterAccounts?: string;
	applicationKind: string = 'external';
}