import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


interface IMetricBand {
	name: string;
	value: string;
	colors: string[];
}

@Component({
	selector: 'color-band-selector',
	template: `
		<div class="dropdown fake-select" ngbDropdown *ngIf="metric">
			<button ngbDropdownToggle
				class="pr-32"
				[disabled]="!isSupportFiveBands() || disabled"
				aria-haspopup="true">
				<color-swatch class="w-100-percent justify-between"
					*ngIf="getSelectedBand() as selectedBand"
					[displayName]="selectedBand.name"
					[colors]="selectedBand.colors"
					[title]="selectedBand.name">
				</color-swatch>
				<span class="caret"></span>
			</button>
			<div ngbDropdownMenu class="ph-8">
				<div *ngFor="let band of getBands()"
					class="hierarchy-item pt-16"
					(click)="selectBand(band)">
					<color-swatch class="flex-multiline"
						[displayName]="band.name"
						[colors]="band.colors">
					</color-swatch>
				</div>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorBandSelectorComponent {
	private readonly GROUP_COLOR_PREFIX = '_group_color';

	@Input() metric: Metric;
	@Input() selectedValue: string;
	@Input() disabled: boolean;
	@Output() selectedValueChange = new EventEmitter<string>();
	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;

	constructor(
		@Inject('groupColorService') private groupColorService,
		private locale: CxLocaleService,
	) {}

	getSelectedBand(): IMetricBand {
		return _.findWhere(this.getBands(), {value: this.selectedValue});
	}

	getBands(): IMetricBand[] {
		let result = [{
			name: this.locale.getString('appearance.threeBandPalette'),
			value: `${this.GROUP_COLOR_PREFIX}.${this.metric.name}${PredefinedMetricConstants.SUBTYPE_3}`
		} as IMetricBand];
		if (this.isSupportFiveBands()) {
			result.push({
				name: this.locale.getString('appearance.fiveBandPalette'),
				value: `${this.GROUP_COLOR_PREFIX}.${this.metric.name}${PredefinedMetricConstants.SUBTYPE_5}`
			} as IMetricBand);
		}
		result.forEach(band => band.colors = this.groupColorService.getColorArray(band.value, this.metric));
		return result;
	}

	isSupportFiveBands(): boolean {
		return this.metric.name === PredefinedMetricConstants.SENTIMENT || this.metric.name === PredefinedMetricConstants.EASE_SCORE;
	}

	selectBand(band: IMetricBand): void {
		this.selectedValue = band.value;
		this.selectedValueChange.emit(this.selectedValue);
		this.dropdown.close();
	}
}
