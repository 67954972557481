import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges,
	ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { MultiselectUtils } from '@app/modules/filter-builder/attribute/multiselect/multiselect-utils';
import { AttributeValueOption, IMultiselectConfiguration, MultiselectComponent, NodeEvent } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { PagingSearchFactory, PagingSearchState } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AttributeValueSearcher, SearchedAttribute, SearchTermsOptions, SearchTermsResult } from '@cxstudio/dashboards/attribute-value-searcher.service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'paging-multiselect',
	templateUrl: './paging-multiselect.component.html'
})
export class PagingMultiselectComponent implements OnInit, OnChanges, OnDestroy {
	@Input() project: AccountOrWorkspaceProject;
	@Input() userEmail: string;

	pagingSearch: PagingSearchFactory;
	@Input() externalSearch?: string;
	externalOpen?: boolean;
	@Input() useExternalSearch: boolean;
	@Input() searchLabel: string;
	@Input() useChips: boolean;
	@Input() hideCheckbox: boolean;
	@Input() hideSelections: boolean;

	@Input() attributes: SearchedAttribute[];
	@Input() selection: DashboardFilter;
	@Input() color?: string;

	@Input() disableSelection: (item, modelValue) => boolean;

	@Output() onSelectionChange = new EventEmitter<void>();
	@Output() onNodeClick = new EventEmitter<NodeEvent>();

	@Input() autoFocus: boolean;
	@Input() dropdownContainer: string;
	@Input() dropdownPlacement: string;
	@Input() onlyExactMatch: boolean;
	@Input() dashboardFilter: boolean;
	@Input() specialOptions: AttributeValueOption[];

	multiselectConfig: Partial<IMultiselectConfiguration>;

	private stateChangedSubscription: Subscription;

	@ViewChild(MultiselectComponent, {static: false}) multiselect: MultiselectComponent;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('attributeValueSearcher') private attributeValueSearcher: AttributeValueSearcher,
		@Inject('filterUtils') private filterUtils: FilterUtils,
	) {}

	ngOnInit(): void {
		this.pagingSearch = new PagingSearchFactory({
			doQuery: this.attributeValueSearcher.searchTerms,
			buildTypeSpecificOptions: this.buildBasicSearchTermsOptions,
			populateValues: this.populateValues
		});
		this.stateChangedSubscription = this.pagingSearch.stateChanged$.subscribe(() => this.ref.detectChanges());

		this.multiselectConfig = {
			showSelectAll: false,
			showSearch: !this.useExternalSearch,
			showAttributeName: this.attributes?.length > 1 || !!this.specialOptions,
			highlightContains: _.size(this.attributes) <= 1,
			hideCheckbox: this.hideCheckbox,
			hideSelections: this.hideSelections,
		};
	}

	ngOnDestroy(): void {
		this.stateChangedSubscription?.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.useExternalSearch) {
			if (changes.attributes?.previousValue !== changes.attributes?.currentValue && !changes.attributes?.isFirstChange()) {
				this.onAttributesChange();
			}
		}
	}

	private onAttributesChange(): void {
		this.pagingSearch.resetSearch();
		if (!_.isEmpty(this.attributes))
			this.pagingSearch.searchTerms('');
	}

	onChangeInternal = (): void => {
		this.onSelectionChange.emit();
	}

	onNodeClickInternal = (node): void => {
		this.onNodeClick.emit({ node });
	}

	onUpdateSearchInternal = (search: string): void => {
		this.externalOpen = undefined;
		this.pagingSearch.resetSearch();
		this.pagingSearch.searchTerms(search);
	}

	filtersEqual = (filter1, filter2) => {
		return this.filterUtils.filtersEqual(filter1, filter2);
	}

	private populateValues = (result: SearchTermsResult): void => {
		this.selection.attrValues = this.selection.attrValues || [];
		if (this.pagingSearch.searchState === PagingSearchState.FIRST_SEARCH) {
			this.selection.attrValues = [];
		}

		this.selection.multiValues = this.selection?.multiValues ?
			this.selection.multiValues.filter((value: AttributeValueOption) => !this.onlyExactMatch || !value.special) :
			[];

		let resultValues: AttributeValueOption[] = [];
		if (!this.onlyExactMatch) resultValues.pushAll(this.attributeValueSearcher.getDefaultAttributeOptions());
		if (this.specialOptions) resultValues.pushAll(this.specialOptions);

		if (this.pagingSearch.searchState !== PagingSearchState.FIRST_SEARCH
				&& this.pagingSearch.searchState !== PagingSearchState.FIRST_LOAD_MORE) {
			// first search starts with no values,
			// second search should also start with no values, as it requests first 2 pages in a single request
			resultValues.pushAll(this.getExistingValues());
			resultValues.forEach(value => value.newValue = false);
		}

		if (result.data.length > 0) {
			resultValues.pushAll(this.processNewValues(result.data));
		}

		resultValues = this.attributeValueSearcher.updateSelectedList(resultValues, this.selection.multiValues, false);
		this.selection.attrValues = resultValues;
		this.selection.nestedList = resultValues;

		if (this.externalSearch) {
			let nonSelected = _.filter(resultValues, MultiselectUtils.optionNotSelected);
			this.externalOpen = !nonSelected.isEmpty();
		}
	}

	private getExistingValues = (): AttributeValueOption[] => {
		return this.selection.attrValues.filter(value => !value.special);
	}

	private processNewValues(values: AttributeValueOption[]): AttributeValueOption[] {
		this.pagingSearch.setHasDottedItems(false);

		if (this.pagingSearch.searchState === PagingSearchState.FIRST_LOAD_MORE) {
			this.pagingSearch.markDottedItems(this.selection.attrValues, values);
		}
		return values;
	}

	private buildBasicSearchTermsOptions = (query: string): SearchTermsOptions => {
		let isNumeric = _.size(this.attributes) === 1 && this.attributes[0]?.type === FilterAttributeTypes.NUMBER;
		return {
			project: this.project,
			userEmail: this.userEmail,
			attributes: this.attributes,
			isNumeric,
			filter: query
		} as SearchTermsOptions;
	}
}

app.directive('pagingMultiselect', downgradeComponent({component: PagingMultiselectComponent}) as angular.IDirectiveFactory);
