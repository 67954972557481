import { Directive, Input, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';

@Directive({
	selector: 'widget-container'
})
export class WidgetContainerUpgradeDirective extends UpgradeComponent {
	@Input() refreshTrigger: boolean = false;
	@Input() widget: Widget;
	@Input() widgetMode: WidgetDisplayMode;
	@Input() itemInitialized: boolean;
	@Input() dashboardData: IDashboardData;
	@Input() editMode: boolean;
	@Input() layout: LayoutHelper;
	@Input() widgetActions: IWidgetActions;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('widgetContainer', elementRef, injector);
	}
}
