import { Directive, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';

@Directive({
	selector: 'dashboard-view'
})

export class DashboardViewUpgradeDirective extends UpgradeComponent {
	@Input() dashboard: Dashboard;
	@Input() widgets: Widget[];
	@Input() containerId: string;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() personalization: PersonalizationState;
	@Input() loaded: boolean;
	@Input() firstLoad: boolean;

	@Output() scrollEdgeChange: EventEmitter<boolean>;
	//@Output() widthChange: EventEmitter<number>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('dashboardView', elementRef, injector);
	}
}
