<modal-header class="danger-modal"
	(cancel)="cancel()"
	[modalTitle]="'common.pleaseConfirm' | i18n">
</modal-header>

<div class="modal-body remove-user-modal" [ngBusy]="loading">
	<div>
		<p>{{disableNote}}</p>
	</div>
	<div *ngIf="!hasNoAssociatedUsers()" class="cx-user-transfer-table overflow-y-auto">
		<table class="table">
			<thead>
				<tr>
					<th class="w-40-percent" [i18n]="'administration.userEmail'"></th>
					<th class="w-50-percent" [i18n]="'administration.defaultMA'"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let userItem of users" class="transfer-item">
					<td>{{userItem.userEmail}}</td>
					<td>
						<simple-dropdown
							[(value)]="userItem.selectedDefaultMasterAccountId"
							[options]="userItem.linkedMasterAccounts"
							valueField="accountId"
							displayField="accountName"
							[appendToBody]="true"
							[noMinWidth]="true"
							[searchable]="true"
						></simple-dropdown>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="modal-footer">
	<button
		id="btn-remove-user-cancel"
		type="button"
		class="btn btn-secondary"
		[i18n]="'common.cancel'"
		(click)="cancel()">
	</button>
	<button
		id="btn-remove-user-confirm"
		type="button"
		class="btn br-btn-yes btn-danger"
		[i18n]="'common.disable'"
		(click)="finish()">
	</button>
</div>
