import { Inject, Injectable } from '@angular/core';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { ColumnFormatter } from '@app/modules/item-grid/slick-grid-formatter.service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class GridInputFormatterService<T> {

	constructor(
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter
	) {}

	getCheckboxFormatter = (gridMode: GridMode): ColumnFormatter<boolean, T> => {
		return (row, cell, value, columnDef, dataContext) => {
			let editable = gridMode === GridMode.EDIT;
			let checked = value;

			return this.gridFormatterService.getCheckBoxFormatter(checked, !editable);
		};
	}
}

app.service('gridInputFormatterService', downgradeInjectable(GridInputFormatterService));