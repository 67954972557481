import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';

export class ProjectAccessLevelUtils {
	private static levels = [
		ProjectAccessLevelValue.MANAGER,
		ProjectAccessLevelValue.VIEWER,
		ProjectAccessLevelValue.CUSTOM,
		ProjectAccessLevelValue.NONE,
	];

	static isLevelAvailableForEditor(editorLevel: ProjectAccessLevelValue, targetLevel: ProjectAccessLevelValue): boolean {
		return this.getLevelOrder(targetLevel) >= this.getLevelOrder(editorLevel);
	}

	private static getLevelOrder(value: ProjectAccessLevelValue): number {
		return this.levels.indexOf(value);
	}
}
