import { Component, ChangeDetectionStrategy, Input} from '@angular/core';
import { SpotCheckResultStatus } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-result-status';
import { SpotCheckUtils } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check-utils.class';

export interface JumpLink {
	label: string;
	status: SpotCheckResultStatus;
}

@Component({
	selector: 'jump-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		:host {
			cursor: pointer;
		}
		.active-jump-link { border-left-color: var(--action-1000); }
		.focus-outline-action-800:focus, .focus-outline-action-800:focus-visible {
			outline: 2px solid var(--action-800);
		}
	`],
	template: `
	<div [ngClass]="getStateClasses()">
		<div class="d-flex justify-between align-items-center w-100-percent p-8 kb-focusable focus-outline-action-800"
			tabindex="0">
			<span>{{link.label}}</span>
			<span class="h-24 w-24 border-solid border-2 border-radius-4 d-flex justify-center align-items-center"
				[ngClass]="getBadgeClasses()">{{count}}</span>
		</div>
	</div>
	`
})
export class JumpLinkComponent {
	@Input() link: JumpLink;
	@Input() count: number;
	@Input() active: boolean;

	getBadgeClasses(): string[] {
		return SpotCheckUtils.getBadgeClasses(this.link.status);
	}

	getStateClasses(): string[] {
		return this.active
			? ['border-l-4', 'border-l-solid', 'active-jump-link']
			: [];
	}
}
