import { ReportTimingMetadata } from '@cxstudio/reports/timing/widget-timing';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetTiming } from './widget-timing';




export class WidgetTimingService {

	private timings: {[id: string]: WidgetTiming};

	constructor(

	) {
		this.timings = {};
	}

	private generateId(widget: Widget): string {
		return widget.containerId ? `${widget.containerId}_${widget.id}` : `${widget.id}`;
	}

	private getWidgetTiming(widget: Widget): WidgetTiming {
		let id = this.generateId(widget);
		if (!id)
			return;
		if (!this.timings[id])
			this.timings[id] = new WidgetTiming();
		return this.timings[id];
	}

	initWidget = (widget: Widget) => {
		this.getWidgetTiming(widget).init();
	}

	startPreparation = (widget: Widget) => {
		this.getWidgetTiming(widget).startPreparation();
	}

	queuedDataLoading = (widget: Widget) => {
		this.getWidgetTiming(widget).queuedDataLoading();
	}

	startDataLoading = (widget: Widget) => {
		this.getWidgetTiming(widget).startDataLoading();
	}

	finishDataLoading = (widget: Widget, metadata: ReportTimingMetadata) => {
		this.getWidgetTiming(widget).finishDataLoading(metadata);
	}

	finishRendering = (widgetId: number, containerId: string) => {
		this.getWidgetTiming({id: widgetId, containerId} as Widget).finishRendering();
	}

	getTimingData = (widget: Widget) => {
		return this.getWidgetTiming(widget);
	}
}

app.service('widgetTimingService', WidgetTimingService);
