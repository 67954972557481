import { SentenceScorecardTopic, SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';

export class ConversationDataPoint {
	height: number;
	yPosition: number;
	isOvertalk: boolean;
	isOvertalkStart: boolean;
	isOvertalkEnd: any;
	channel: string;
	duration: number;
	timestamp: number;
	endTimestamp: number;
	wordCount: number;
	isSilence: boolean;
	easeScore: number;
	emotionIntensity: number;
	attributes?;
	text: string;
	isFiltered: boolean;
	sentiment: number;
	id: number;
	scorecardTopics: {[scorecardId: number]: SentenceScorecardTopic[]};

	events: { click: () => void };
	sentenceTopics: SentenceTopic[];

	participantIndex?: number;
}