<modal-header
	[modalTitle]="modalText.title"
	(cancel)="cancel()">
</modal-header>
<div class="modal-body">
	<form
		class="form-horizontal"
		#propertiesDialog="ngForm"
		(ngSubmit)="isFormModalValid() && save()"
		novalidate
		role="form">
		<div class="form-group">
			<label class="col-sm-3 control-label">{{modalText.nameLabel}}</label>
			<div class="col-sm-9" [ngClass]="{'has-error br-form-error': isFormModalNameInvalid()}">
				<input
					type="text"
					class="form-control"
					data-testid="properties-name"
					placeholder="{{modalText.namePlaceholder}}"
					name="name"
					[(ngModel)]="model.name"
					required
					maxlength="150"
					uniqueName
					[itemList]="itemList"
					[initialItem]="initialItem"
					[folder]="isFolder"
					[nameField]="nameField">
				<span class="help-block" [hidden]="!hasFormModalName()">{{modalText.nameEmptyWarning}}</span>
				<span class="help-block" [hidden]="!isFormModalNameNotUnique()">{{modalText.nameUniqueWarning}}</span>
			</div>
		</div>
		<div class="form-group">
			<label [attr.aria-label]="modalText.descriptionLabel + ' ' + ('dashboard.charactersLimit' | i18n:{count: maxLength})"
				htmlFor="rename-description" class="col-sm-3 control-label">{{modalText.descriptionLabel}}</label>
			<div class="col-sm-9">
				<textarea
					id="rename-description"
					rows="4"
					class="form-control"
					data-testid="properties-description"
					placeholder="{{modalText.descriptionPlaceholder}}"
					name="description"
					[(ngModel)]="model.description"
					maxlength="{{maxLength}}"
					trim="false"></textarea>
			</div>
		</div>
		<div class="form-group">
			<div class="col-sm-offset-3 col-sm-9">
				<characters-left-counter
					[maxLength]="maxLength"
					[textLength]="model.description?.length"></characters-left-counter>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button
		id="btn-cancel-properties"
		type="button"
		class="btn btn-secondary"
		(click)="cancel()">{{'common.cancel'|i18n}}</button>
	<button
		id="btn-rename-save"
		type="button"
		class="btn btn-primary"
		(click)="save()"
		[disabled]="!isFormModalValid()">{{'common.save'|i18n}}</button>
</div>
