

export enum LicenseType {
	ANALYZE = 1,
	CX_STUDIO_AND_CM = 5,
	CX_STUDIO = 2,
	CX_STUDIO_BASIC = 4
}


