import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MetricDisplayValue } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';

@Component({
	selector: 'metric-value',
	template: `
		<span class="value-affix">{{metricValue.prefix}}</span>
		<span>{{metricValue.value}}</span>
		<span class="value-affix">{{metricValue.suffix}}</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricValueComponent {
	@Input() metricValue: MetricDisplayValue;
}
