import Widget from '@cxstudio/dashboards/widgets/widget';

export class HomePageWidgetConstants {
	static readonly HOME_PAGE_DASHBOARD_ID = -3000;
	static readonly TEMP_WIDGET_ID = -3100; // used to run reports while editing home page
	static readonly LINE_WIDGET_ID = -3002;
	static readonly CONTAINER_ID = 'homePageView';

	static isHomePageWidget(widget: Widget): boolean {
		return widget.dashboardId === this.HOME_PAGE_DASHBOARD_ID;
	}

	static isQuickInsightsWidget(widget: Widget): boolean {
		return widget.id < 0;
	}

	static isTrendWidget(widget: Widget): boolean {
		return widget.id === this.LINE_WIDGET_ID;
	}
}