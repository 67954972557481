import { SentenceTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { PillType } from '@app/modules/pills/pill';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';

export class PillsUtils {

	private static readonly TUNABLE_PILL_TYPES = [
		PillType.SENTIMENT,
		PillType.EASE_SCORE,
		PillType.EMOTION,
		PillType.TOPIC,
	];

	static getDeselectedModelsIds = (availableModels: any[], selectedModelsFilter: (modelId: number) => boolean): number[] => {
		return _.chain(availableModels)
			.map(model => {
				if (!selectedModelsFilter(model.id)) {
					return model.id;
				}
			})
			.filter(id => id !== undefined)
			.value();
	}

	static getFilteredSentenceTopics = (sentenceTopics: SentenceTopic[],
			deselectedModelsIds: number[], leafOnly: boolean): SentenceTopic[] => {
		let modelFilter = _.isEmpty(deselectedModelsIds)
			? () => true
			: (topic: SentenceTopic) => !deselectedModelsIds.contains(topic.modelId);
		let leafFilter = leafOnly
			? (topic: SentenceTopic) => topic.isLeaf
			: () => true;

		return _.filter(sentenceTopics, topic => leafFilter(topic) && modelFilter(topic));
	}

	static isPillTunable(pillType: PillType): boolean {
		return this.TUNABLE_PILL_TYPES.contains(pillType);
	}

	static pillTypeToSuggestionType(pillType: PillType): SuggestionType {
		switch(pillType) {
			case PillType.SENTIMENT:
				return SuggestionType.SENTIMENT;
			case PillType.EASE_SCORE:
				return SuggestionType.EFFORT;
			case PillType.EMOTION:
				return SuggestionType.EMOTION;
			case PillType.TOPIC:
				return SuggestionType.TOPIC;
			default:
				throw new Error(`Invalid pill type: ${pillType}`);
		}
	}

	static previewColumnToPillType(column: PreviewColumn): PillType {
		switch(column) {
			case PreviewColumn.SENTIMENT:
				return PillType.SENTIMENT;
			case PreviewColumn.EFFORT:
				return PillType.EASE_SCORE;
			case PreviewColumn.EMOTION:
				return PillType.EMOTION;
			case PreviewColumn.TOPICS:
				return PillType.TOPIC;
			default:
				throw new Error(`Invalid preview column: ${column}`);
		}
	}

}
