<fieldset ngModelGroup="filterMetricDefinition">
	<div class="form-group">
		<label for="calculationName">{{'metrics.basedOn'|i18n}}</label>
		<div class="row">
			<select-from-tree
				name="calculationName"
				class="attribute-selector col-sm-4"
				[ngModel]="definition.calculationName"
				[hierarchyList]="ui.calculations"
				(onNodeClick)="selectCalculationNode($event.node)">
			</select-from-tree>
			<simple-dropdown *ngIf="showOperatorOptions()"
				class="col-sm-4"
				[options]="ui.calculationOperators"
				[(value)]="definition.calculationOperator"
				(onChange)="changeHandler()"
				displayField="displayName"
				valueField="operator"
				no-min-width="true">
			</simple-dropdown>
		</div>
	</div>
	<div class="form-group">
		<div class="line-label bg-pewter-400">
			<label class="bg-white">{{'metrics.conditions'|i18n}}</label>
		</div>
		<filter-builder
			[filterableFields]="ui.filterOptions"
			[ruleSet]="filterRules"
			[maxRules]="5"
			[projectProperties]="project"
			[removeDateRangeRestriction]="true"
			(filterChange)="changeHandler()"
		></filter-builder>
	</div>
	<collapsing-section [collapsed]="true">
		<panel-heading class="font-bold">{{'widget.advancedOptions'|i18n}}</panel-heading>
		<panel-body>
			<div class="row">
				<div class="col-sm-8 form-group">
					<div><label for="aggregationLevel">{{'metrics.aggrLevel'|i18n}}</label></div>
					<radio-buttons
						name="aggregationLevel"
						[options]="ui.aggrregationLevels"
						[(ngModel)]="definition.aggrLevel"
						(ngModelChange)="aggregationLevelChangeHandler()"
					></radio-buttons>
				</div>
			</div>
		</panel-body>
	</collapsing-section>
</fieldset>
