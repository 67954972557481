export default class PlotBand {
	backgroundColor: string;
	textColor: string;
	text: string;

	static defaultOne(): PlotBand {
		return new PlotBand('#bec4c7', '#556677', '');
	}

	static defaultTwo(): PlotBand {
		return new PlotBand('#BBDDEE', '#556677', '');
	}

	constructor(backgroundColor: string, textColor: string, text: string) {
		this.backgroundColor = backgroundColor;
		this.textColor = textColor;
		this.text = text;
	}
}
