import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'middleEllipsis'
})
export class MiddleEllipsisPipe implements PipeTransform {

	transform(str: string, maxLength: number, ellipsisStart?: number): string {
		if (str?.length > maxLength && maxLength >= 9) {
			return `${this.getLeadingPiece(str, maxLength, ellipsisStart)}...${this.getTrailingPiece(str, maxLength, ellipsisStart)}`;
		}
		return str;
	}

	private getLeadingPiece(str: string, limit: number, ellipsisStart?: number): string {
		let leadLength = ellipsisStart;
		if (leadLength === undefined) {
			let overflow = str.length - limit;
			let overflowRemove = Math.floor(overflow / 2);
			leadLength = Math.floor(str.length / 2) - overflowRemove - 2; // -2 to make room for 2 of 3 ellipsis dots
		}
		return str.left(leadLength);
	}

	private getTrailingPiece(str: string, limit: number, ellipsisStart?: number): string {
		let trailingLength;
		if (ellipsisStart === undefined) {
			let overflow = str.length - limit;
			let overflowRemove = Math.ceil(overflow / 2);
			trailingLength = Math.ceil(str.length / 2) - overflowRemove - 1; // -1 to make room for 1 of 3 ellipsis dots
		} else {
			trailingLength = limit - ellipsisStart - 3; // -3 ellipsis dots
		}
		return str.right(trailingLength);
	}
}
