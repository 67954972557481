import * as _ from 'underscore';
import { AlertPreviewService } from '@app/modules/alert/services/alert-preview.service';
import { Interval } from '@cxstudio/interval/interval.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { NotificationsApiService } from '@app/modules/notification/services/notifications-api.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DocExplorerHelperService } from '@cxstudio/reports/document-explorer/doc-explorer-helper.service';
import { MasterAccountIdentity } from '@app/modules/system-administration/master-account/entities/master-account-identity';

interface INotificationCollection {
	records: any[];
	unreadCount: number;
}

const NOTIFICATION_DROPDOWN_SELECTOR = '#notification-dropdown';
const ALERT_DROPDOWN_SELECTOR = '#alert-dropdown';

export class NotificationsComponent implements ng.IComponentController {

	readonly CATEGORY_NOTIFICATION: string = 'notification';
	readonly CATEGORY_ALERT: string = 'alert';
	readonly DEFAULT_BATCH_SIZE: number = 5;
	readonly RELOAD_INTERVAL = 60 * 1000; // 60 seconds
	readonly MENU_SELECTOR = '.br-alert-menu';

	alertCategory = this.CATEGORY_ALERT;
	notificationCategory = this.CATEGORY_NOTIFICATION;

	notification?: INotificationCollection;
	alert?: INotificationCollection;
	bucketSize: {
		alert: number;
		notification: number;
	} = { alert: this.DEFAULT_BATCH_SIZE, notification: this.DEFAULT_BATCH_SIZE };

	masterAccounts: MasterAccountIdentity[];
	notificationStates;
	accountMap = {};

	currentCategory: string = this.CATEGORY_NOTIFICATION;
	messagesFocusable: boolean = false;

	state: {
		open: boolean;
	};
	hasMultipleMasterAccounts: boolean;

	constructor(
		private $timeout: ng.ITimeoutService,
		private alertPreviewService: AlertPreviewService,
		private notificationsApiService: NotificationsApiService,
		private interval: Interval,
		private readonly docExplorerHelperService: DocExplorerHelperService,
	) {}

	$onInit = () => {
		this.state = {
			open: false
		};

		$(this.MENU_SELECTOR).on('click', `${NOTIFICATION_DROPDOWN_SELECTOR} .nf-list-group-item`, (event: Event) => {
			let jqueryThis = this;  // tslint:disable-line:no-invalid-this no-this-assignment
			if (!$(event.target).is('a')) {
				return false;
			}
			let notificationId = $(jqueryThis).attr('id');
			if (notificationId !== undefined) {
				this.markRead(notificationId);
			}
		});

		// to not hide by clicking on blank space in header
		this.$timeout(() => {
			$(`${this.MENU_SELECTOR} .nav-tabs`).on('click', () => {
				return false;
			});
		});

		this.masterAccounts.forEach((ma) => {
			this.accountMap[ma.accountId] = ma.accountName;
		});

		this.hasMultipleMasterAccounts = this.masterAccounts.length > 1;

		this.reloadNotifications();
		this.reloadAlerts();
		this.interval.registerInterval('notification', this.checkForNotifications, this.RELOAD_INTERVAL, true);
	}

	toggled = (open: boolean): void => {
		this.state.open = open;
	}

	showMoreChange = (notificationId) => {
		return (showFull) => {
			this.notificationStates[notificationId] = showFull;
		};
	}

	getAlertAndOpenDocExplorer = (alert) => {
		const widgetSettings = this.alertPreviewService.createDocumentExplorerSettings(alert);

		this.notificationsApiService.getAlertByNotificationId(alert.id).then((data) => {
			widgetSettings.properties.accountId = data.accountId;
			widgetSettings.properties.project = data.projectId;
			widgetSettings.properties.contentProviderId = data.cpId;

			// hack to keep alerts dropdown opened when operating with document explorer:
			// 1. Setting manually "open" as class for dropdown to keep it always opened
			// 2. Resetting drop-down functionality by "clicking" toggle element on closing of document explorer
			$(this.MENU_SELECTOR).addClass('open');

			const documentExplorerModal = this.openDocumentExplorer(widgetSettings);

			documentExplorerModal.result.finally(() => {
				this.$timeout(() => this.state.open = true, 100);
			});
		});
	}

	initAlertNotifications = () => {
		const self = this;  // tslint:disable-line:no-invalid-this no-this-assignment

		const alertLinkClickCallback = function(event): boolean {
			const jqueryThis = this;  // tslint:disable-line:no-invalid-this no-this-assignment

			if (!$(event.target).is('a')) {
				return false;
			}

			const alertId = $(jqueryThis).attr('id');

			if (alertId !== undefined) {
				const alert = _.findWhere(self.alert.records, {id: parseInt(alertId, 10)});
				if (alert) {
					self.getAlertAndOpenDocExplorer(alert);
				}
			}

			return false;
		};

		$(this.MENU_SELECTOR).on('click', `${ALERT_DROPDOWN_SELECTOR} .nf-list-group-item`, alertLinkClickCallback);
	}


	private reloadNotifications = () => {
		// Load notifications
		this.notificationsApiService.getMessages(this.CATEGORY_NOTIFICATION, this.bucketSize.notification).then((result) => {
			this.notification = result;
			this.addAccountMapping(this.notification.records);
			this.$timeout(() => {
				this.changeLinksFocusability($(`${NOTIFICATION_DROPDOWN_SELECTOR} a`));
			});
		});


	}
	private reloadAlerts = () => {
		// Load alerts
		this.notificationsApiService.getMessages(this.CATEGORY_ALERT, this.bucketSize.alert).then((result) => {
			this.alert = result;
			this.addAccountMapping(this.alert.records);
			this.$timeout(() => {
				this.changeLinksFocusability($(`${ALERT_DROPDOWN_SELECTOR} a`));
			});
		});
	}


	checkForNotifications = () => {
		if (!this.notification || !this.alert)
			return;

		// Check for new notifications
		this.notificationsApiService.getNewMessages(this.CATEGORY_NOTIFICATION, this.notification.unreadCount).then((result) => {
			if (result.newEvents) {
				this.reloadNotifications();
			}
		});

		// Check for new alerts
		this.notificationsApiService.getNewMessages(this.CATEGORY_ALERT, this.alert.unreadCount).then((result) => {
			if (result.newEvents) {
				this.reloadAlerts();
			}
		});
	}

	private addAccountMapping = (notifications) => {
		if (this.masterAccounts.length > 1) {
			notifications.forEach((n) => {
				n.accountName = this.accountMap[n.accountId];
			});
		}
	}

	loadMore = (category) => {
		if (category === this.CATEGORY_NOTIFICATION) {
			this.bucketSize.notification = this.bucketSize.notification + this.DEFAULT_BATCH_SIZE;
			this.reloadNotifications();
		}

		if (category === this.CATEGORY_ALERT) {
			this.bucketSize.alert = this.bucketSize.alert + this.DEFAULT_BATCH_SIZE;
			this.reloadAlerts();
		}
	}

	private reloadIfStatus = (status) => {
		if (status.data) {
			this.reloadNotifications();
			this.reloadAlerts();
		}
	}

	markRead = (notificationId) => {
		this.notificationsApiService.markRead(notificationId).then(this.reloadIfStatus);
	}

	markAllRead = (category) => {
		this.notificationsApiService.markAllRead(category).then(this.reloadIfStatus);
	}

	markAllReadExceptLink = (category) => {
		let notificationList = [];

		if (category === this.CATEGORY_NOTIFICATION) {
			if (this.notification)
				notificationList = this.getNotificationIdList(this.notification.records, true);
		} else if (category === this.CATEGORY_ALERT) {
			if (this.alert)
				notificationList = this.getNotificationIdList(this.alert.records, true);
		}

		if (notificationList.length > 0) {
			this.notificationsApiService.markMultipleRead(notificationList).then((status) => {
				if (status.data) {
					if (category === this.CATEGORY_NOTIFICATION) {
						this.reloadNotifications();
					} else if (category === this.CATEGORY_ALERT) {
						this.reloadAlerts();
					}
				}
			});
		}
	}

	getNotificationIdList = (notifications, skipLinkedEvent) => {
		const result = [];
		const pattern = new RegExp('<a href=');

		for (let index in notifications) {
			if (notifications[index].isNewEvent) {
				if (!skipLinkedEvent || (skipLinkedEvent && !pattern.test(notifications[index].message))) {
					result.push(notifications[index].id);
				}
			}
		}

		return result;
	}

	onNotificationsKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.$timeout(() => {
				$('.notification-tabs :focusable').first().trigger('focus');
				this.changeMessagesLinksFocusability();
			});
		}
	}

	onTabKeydown = (event: any, category: string): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && this.isHeadingKeydown(event, category)) {
			this.changeMessagesFocusability(category);
			if (this.messagesFocusable) {
				this.focusOnFirstMessage(category);
			}
		} else if (KeyboardUtils.isEventKey(event, Key.LEFT) || KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			if (category === this.notificationCategory) {
				$('#alert-heading').first().parent().trigger('focus');
			} else {
				$('#notification-heading').first().parent().trigger('focus');
			}

		}
	}

	onInTabsKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) && this.messagesFocusable) {
			event.preventDefault();
			event.stopPropagation();
			this.messagesFocusable = false;
			this.changeMessagesLinksFocusability();
			this.focusOnTabHeading();
		}
	}

	private focusOnTabHeading = (): void => {
		const tabLinks = $('.nav-link');

		if (this.currentCategory === this.notificationCategory) {
			tabLinks.first().trigger('focus');
		} else {
			tabLinks.last().trigger('focus');
		}
	}

	private focusOnFirstMessage = (category: string): void => {
		if (category === this.notificationCategory) {
			$(`${NOTIFICATION_DROPDOWN_SELECTOR} :focusable`).first().trigger('focus');
		} else {
			$(`${ALERT_DROPDOWN_SELECTOR} :focusable`).first().trigger('focus');
		}
	}

	private isHeadingKeydown = (event: any, category: string): boolean => {
		return category === this.notificationCategory ? this.isNotificationHeadingKeydown(event) : this.isAlertHeadingKeydown(event);
	}

	private isNotificationHeadingKeydown = (event: any): boolean => {
		return event.target.firstElementChild.id === 'notification-heading';
	}

	private isAlertHeadingKeydown = (event: any): boolean => {
		return event.target.firstElementChild.id === 'alert-heading';
	}

	private changeMessagesFocusability = (category: string): void => {
		if (category === this.currentCategory) {
			this.messagesFocusable = !this.messagesFocusable;
		} else {
			this.currentCategory = category;
			this.messagesFocusable = false;
		}

		this.changeMessagesLinksFocusability();
	}

	private changeMessagesLinksFocusability = (): void => {
		if (this.currentCategory === this.notificationCategory) {
			this.changeLinksFocusability($(`${NOTIFICATION_DROPDOWN_SELECTOR} a`));
		} else {
			this.changeLinksFocusability($(`${ALERT_DROPDOWN_SELECTOR} a`));
		}
	}

	private changeLinksFocusability = (links: JQuery): void => {
		if (links.length > 0) {
			_.each(links, link => {
				this.messagesFocusable ? link.removeAttribute('tabindex') : link.setAttribute('tabindex', '-1');
			});
		}
	}

	setCategory = (category: string) => {
		this.currentCategory = category;
	}

	onTabClick = (category: string): void => {
		this.setCategory(category);
		this.markAllReadExceptLink(category);
	}

	openDocumentExplorer = (widgetSettings: Widget) => {
		return this.docExplorerHelperService.openDocExplorer(widgetSettings);
	}
}

app.component('notifications', {
	bindings: {
		masterAccounts: '<'
	},
	controller: NotificationsComponent,
	templateUrl: 'partials/header/notifications.component.html'
});
