import { MetricCalculation } from '@cxstudio/metrics/entities/metric-calculation';
import { IMetricBounds } from './metric-bounds-utils.service';

export class GaugeBackgroundUtils {
	static getPlotBands(calculation: MetricCalculation, bounds: IMetricBounds, calculationName?: string,
		colorFunction: (color: any, index: number) => any = (val) => val) {
		const stops = [calculation.min].concat(calculation.thresholds).concat([calculation.max]);

		return calculation.colorPalette.map((color, index) => {
			let sectorStart = stops[index];
			const sectorEnd = stops[index + 1];
			let calculationColor: any = color;
			if (calculationName) {
				calculationColor = {};
				calculationColor[calculationName] = (sectorStart + sectorEnd) / 2;
			}
			const sector = {
				from: sectorStart,
				to: sectorEnd,
				color: colorFunction(calculationColor, index),
				thickness: '25%',
			};
			return sector;
		}).filter((stop) => {
			// only keep items within the boundaries
			return (stop.to >= bounds.min) && (stop.from <= bounds.max);
		});
	}


	static getDynamicStops(calculation: MetricCalculation, calculationName?: string, value?: number,
		colorFunction: (color: any, index: number) => any = (val) => val):
			Array<Array<number | Highcharts.ColorType>> {
		let calculationColor: string | MetricCalculation = calculation.colorPalette?.length ? calculation.colorPalette[0] : '';
		if (calculationName) {
			calculationColor = {} as any;
			calculationColor[calculationName] = value;
		}
		let colorValue = colorFunction(calculationColor, 0);
		return [[0, colorValue]] as Array<Array<number | Highcharts.ColorType>>;
	}
}
