import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { ScorecardFiltersManagementService } from '@app/modules/scorecards/filters/scorecard-filters-management.service';
import { ProjectsReadyEvent } from '@app/modules/units/workspace-project-selector/workspace-project-selector.component';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProjectData, WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { PromiseUtils } from '@app/util/promise-utils';
import { ProjectFilterData } from '@cxstudio/angular-filters/project-filter-data';
import Authorization from '@cxstudio/auth/authorization-service';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { IContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterUpdateService } from '@cxstudio/report-filters/filter-update-service.service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import * as _ from 'underscore';
import IFilter from './entity/filter';
import { FilterItemType } from './entity/filter-item-type.enum';
import { IFilterActionsService } from './filter-actions-service';
import { GeneratedFolderType } from './generated-folder-type';

interface FilterProps {
	attributes?: any[];
	wordAttributes?: any[];
	models?: IReportModel[];
	projectTimezone?: string | number;
	projectContext?: IProjectContext;
}

interface FilterTab {
	type: FilterTabType;
	name: string;
}

enum FilterTabType {
	CUSTOM = 'CUSTOM',
	SYSTEM = 'SYSTEM'
}

export class ReportFilterManagement {

	filterList: Array<IFilter|any>;
	visibleFilterList: Array<IFilter|any>;
	tabFilterList: Array<IFilter|any>;
	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.FILTER;
	actionsService: IFilterActionsService;
	customDateRanges: boolean;

	filterBurgerClick: (event, object) => void;

	folderSvc: IFolderService;
	selectionUtils;
	contextMenuUtils: IContextMenuUtils<IFilter>;
	filterEditors;
	dateFilterEditors;
	lastChecked;
	ui: {
		hideFilters: boolean;
		searchFilter: string;
		filterTabs: FilterTab[];
		selectedTab: FilterTabType;
	};
	lastChange;
	isWorkspaceEnabled: boolean;
	context: FilterProps;

	project: AccountOrWorkspaceProjectData;
	projectsAmount: OptionsAmount;
	projects: Project[];
	loading: ProjectAssetsLoading = {};
	errors: ProjectAssetsErrors = {};
	preselectedProject: FavoriteProperties;

	private decrementFilterId: () => number;

	constructor(
		private $scope: ng.IScope,
		private locale: ILocale,
		private $routeParams,
		private $location: ng.ILocationService,
		private $route, //: ng.route.IRouteService,
		private filterManagementApiService: FilterManagementApiService,
		private filtersService: FiltersService,
		private readonly contextMenuTree: ContextMenuTree,
		private $filter: ng.IFilterService,
		private $q: ng.IQService,
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private gridUtils: GridUtilsService,
		private security: Security,
		private authorization: Authorization,
		private FolderService,
		private FilterSelectUtils,
		private FilterContextMenuUtils,
		private FilterActionsService,
		private readonly globalNotificationService: GlobalNotificationService,
		private readonly scorecardFiltersManagementService: ScorecardFiltersManagementService,
		private readonly redirectService: RedirectService,
		private filterUpdateService: FilterUpdateService,
		private DateRange,
		private readonly betaFeaturesService: BetaFeaturesService,
	) { }

	$onInit = () => {
		// restrict this page to non-mobile
		this.security.preventMobileAccess();
		this.security.restrictPage(this.authorization.hasFilterManagementAccess);

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.context = {} as FilterProps;
		this.project = {} as AccountOrWorkspaceProjectData;

		this.decrementFilterId = this.nextFilterId();


		this.visibleFilterList = [];
		this.filterList = [];

		this.gridOptions = {
			onClick: this.onClick
		};

		this.folderSvc = new this.FolderService(FolderTypes.FILTER);

		this.lastChecked = null;

		this.ui = this.ui || {} as any;
		this.ui.searchFilter = '';
		this.ui.hideFilters = true;
		this.ui.filterTabs = [
			{name: this.locale.getString('common.custom'), type: FilterTabType.CUSTOM},
			{name: this.locale.getString('common.system'), type: FilterTabType.SYSTEM}
		] as FilterTab[];
		this.ui.selectedTab = FilterTabType.CUSTOM;

		this.customDateRanges = this.security.has('manage_date_filters');

		this.filterBurgerClick = this.onClick;

		this.selectionUtils = new this.FilterSelectUtils(this);
		this.contextMenuUtils = new this.FilterContextMenuUtils(this);
		this.actionsService = new this.FilterActionsService(this);

		this.$scope.$on('$routeUpdate', (event) => {
			if (this.$routeParams.filterId) {
				this.$route.reload();
			}
		});

		this.loadPreSelectedFilter().then((response) => {
			const result = (response || {}) as IFilter;
			this.preselectedProject = {
				favoriteCP: result.contentProviderId,
				favoriteAccount: result.accountId,
				favoriteProject: result.projectId
			} as FavoriteProperties;
		}, (response) => {
			let status = response.status;
			if (status === CHANGE_MA_STATUS) { // dashboard from another MA, need to reload
				this.redirectService.saveCurrentMA({accountId: response.data});
				return;
			}
			this.$location.url('/dashboards');
			this.$route.reload();
		});

		this.$scope.$watchCollection('$ctrl.filterList', this.updateVisibleFilterList);
		this.$scope.$watch('$ctrl.project.projectId', this.updateVisibleFilterList);

		this.$scope.$on('projects:loaded', (event, projects) => {
			this.projectsAmount = projects.length === 1 ? OptionsAmount.SINGLE_OPTION : OptionsAmount.SEVERAL_OPTIONS;
			this.projects = projects.map(project => {
				return {
					id: project.projectId,
					name: project.name
				};
			});
		});
	}

	accountChanged = (newProps?: IProjectSelection): void => {
		if (newProps) {
			this.project = newProps;
		}
		this.filterList.removeAll();

		if (!ValueUtils.isSelected(newProps.accountId)) {
			return;
		}
	}

	projectChanged = (newProps: IProjectSelection): void => {
		if (newProps) {
			this.project = newProps;
		}
		this.selectionUtils.clearSelections();
		this.filterList.removeAll();
		const accountProject = this.project as IProjectSelection;
		if (ValueUtils.isNotSelected(accountProject.accountId) || this.errors.tooManyProjects) {
			return;
		}

		this.loading.promise = this.reloadFilters().then(() => this.updateProjectData());
		this.refreshGrid();
	}

	workspaceChanged = () => {
		this.projectsAmount = undefined;
	}

	workspaceProjectChanged = (newProject?: any): void => {
		this.cleanProjectErrors();
		if (newProject) {
			this.project = newProject;
		}

		let projectSelectedCheck = WorkspaceProjectUtils.isProjectSelected(this.project as WorkspaceProject);

		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.clearAndRemoveAll();

		if (!_.isEmpty(this.project) && projectSelectedCheck) {
			this.runPromiseAndRefresh();
		} else if (this.checkManyProjectError(newProject)) {
			this.errors.tooManyProjects = true;
			return;
		} else {
			this.runPromiseAndRefresh();
		}
	}

	private runPromiseAndRefresh = (): void => {
		this.loading.promise = this.reloadFilters().then(() => this.updateProjectData());
		this.refreshGrid();
	}

	private clearAndRemoveAll = (): void => {
		this.selectionUtils.clearSelections();
		this.filterList.removeAll();
	}

	private checkManyProjectError = (newProject: WorkspaceProjectData): boolean => {
		if ( this.projectsAmount === OptionsAmount.MANY_OPTIONS ) {
			return true;
		}

		return false;
	}

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	}

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	}

	projectsLoaded = (event: ProjectsReadyEvent): void => {
		this.projectsAmount = event.optionsAmount;
		this.projects = event.projects;
		this.workspaceProjectChanged();
	}

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	}

	folderCount = (): number => {
		return this.filterList
			.filter(this.isFolder)
			.filter((item) => FilterTypes.CXDATE !== item.id).length;
	}

	isFolder = (item): boolean => {
		return /.*folder.*/i.test(item.type);
	}

	// handle click
	onClick = (event, object): void => {
		// single click only on title
		let target = $(event.target);

		if (event.ctrlKey && this.selectionUtils.isSupportedType(object) && this.verifyNoMixedFilterTypes(object)) {

			this.lastChecked = object;
			this.selectionUtils.handleCtrlClick(object);
			this.$scope.$apply();
			return;
		}


		if (this.isClickOnNameOfType(event, 'br-studiofilter')) {
			this.showFilterDialog(object);
		} else if (this.isClickOnNameOfType(event, 'br-predefined')) {
			if (this.actionsService.canEditPredefinedFilter(object)) {
				this.actionsService.editPredefinedFilter(object);
			} else {
				this.actionsService.viewPredefinedFilter(object);
			}
		} else if (this.isClickOnNameOfType(event, 'br-scorecardfilter')) {
			this.actionsService.viewFilter(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'dashboards', 360);
		} else if (this.gridUtils.isBulkCheckbox(event) && this.verifyNoMixedFilterTypes(object)) {
			this.selectionUtils.handleCheckboxClick(object, this.lastChecked, event.shiftKey);
			this.lastChecked = object;
			this.$scope.$apply();
		} else if (this.gridUtils.isToggleClick(event) && !this.gridUtils.isToggleDisabled(event)) {
			this.handleClickOnToggle(target, object);
		}
	}

	verifyNoMixedFilterTypes(item: any): boolean {
		let selectedFilters = _.where(this.filterList, {selected: true});
		//remove folders, as their type doesnt matter
		selectedFilters = _.reject(selectedFilters, this.isFolder);
		let scorecardFilterClick = item.type === FilterTypes.CXSCORECARD;
		if (selectedFilters.length) {
			return _.every(selectedFilters, (filter: any) => {
				// if clicking scorecard filter, all selected must also be scorecard
				// if clicking non-scorecard filter, all selected must be non-scorecard
				return scorecardFilterClick
					? filter.type === FilterTypes.CXSCORECARD
					: filter.type !== FilterTypes.CXSCORECARD;
			});
		}
		return true;
	}

	showSelectAll = (): boolean => {
		let selectedFilters = _.where(this.filterList, {selected: true});
		selectedFilters = _.reject(selectedFilters, this.isFolder);
		return _.every(selectedFilters, filter => filter.type !== FilterTypes.CXSCORECARD);
	}

	selectAllFilter = (item: any): boolean => {
		return this.selectionUtils.isSupportedType(item) && item.type !== FilterTypes.CXSCORECARD;
	}

	private isClickOnNameOfType(event, className: string): boolean {
		return $(event.target).hasClass(className) ||
			this.gridUtils.isNameClick(event) && this.gridUtils.isTargetHasChild(event, className);
	}

	private showFilterDialog(filter): void {
		if (this.security.isCurrentUser(filter.ownerName)
				|| (this.security.has(MasterAccountPermissionAction.CREATE_FILTER) && filter.permissionLevel === 'EDIT')) {
			this.actionsService.editFilter(filter);
		} else {
			this.actionsService.viewFilter(filter);
		}
	}

	private handleClickOnToggle = (target, object) => {
		if (object.type === FilterTypes.CXSCORECARD) {
			this.changeScorecardFilterState(object);
		} else if (!target[0].offsetParent.classList.contains('disabled')) {
			this.changeFilterState(object);
		}
	}

	private changeScorecardFilterState(scorecardFilter): void {
		if (this.security.has('manage_scorecards')) {
			this.scorecardFiltersManagementService.updateDisableState(scorecardFilter, !scorecardFilter.disabled).then(() => {
				scorecardFilter.disabled = !scorecardFilter.disabled;
				this.refreshGrid(scorecardFilter);
			});
		}
	}

	private changeFilterState = (object) => {
		let filterId = object.id.slice(object.id.indexOf('_') + 1);
		this.filterManagementApiService.updatePresetDateFilterState(filterId).then(() => {
			object.enabled = !object.enabled;
			this.refreshGrid(object);
		});
	}

	// closure to maintain an incrementing id for filters
	private nextFilterId = (): (() => number) => {
		let id = 0;

		return (): number => {
			--id;
			return id;
		};
	}

	// retrieve filters from API
	reloadFilters = (): ng.IPromise<void> => {
		let filtersPromise = WorkspaceTransitionUtils.isProjectSelected(this.project)
			? this.filtersService.getFiltersWithFolders(this.project, true)
			: this.filtersService.getFiltersForWorkspace(WorkspaceTransitionUtils.getWorkspace(this.project));
		let filterPromises: Array<ng.IPromise<any>> = [
			PromiseUtils.old(filtersPromise),
			PromiseUtils.old(this.scorecardFiltersManagementService.getScorecardFilters(this.project))
		];
		return this.$q.all(filterPromises).then((results: any[]) => {
			let dateFolder = this.getDateFolder();
			let filters = results[0];

			filters.push(this.getPredefinedFolder());
			filters.push(this.getCustomDateFolder());

			let scorecardFilters = results[1];
			let scorecardFolders = this.scorecardFiltersManagementService.getScorecardFolders(
				scorecardFilters, this.project, this.security.getMasterAccountId());

			let presetFiltersStatesPromise = this.filterManagementApiService.getPresetDateFiltersStates();
			return presetFiltersStatesPromise.then((presetFiltersStates) => {

				this.populateDateFiltersStates(dateFolder, presetFiltersStates);

				let filterList = dateFolder.concat(filters, scorecardFilters, scorecardFolders);

				// slick grid needs a unique ID on every item, but filterId is not guranteed to be unique, nor is it on all items
				// therefore we need to add a temporary ID on all items
				for (let eachFilter of filterList) {
					if (eachFilter.type !== FolderTypes.FILTER && !eachFilter.hasOwnProperty('id'))
						eachFilter.id = this.decrementFilterId();
				}

				let flatTree = this.gridUtils.processItemsTree(filterList, false);

				this.filterList.removeAll();
				this.filterList.pushAll(flatTree || []);
				flatTree.forEach((treeItem) => {
					if (/.*folder.*/i.test(treeItem.type)) {
						treeItem._collapsed = true;
					}

					if (treeItem.hide) {
						TaggingHelper.tag(treeItem, TaggingHelper.tags.HIDDEN);
					}
					this.updateProjectName(treeItem);
				});
				this.refreshGrid();

				if (this.$routeParams.filterId) {
					let filterId = parseInt(this.$routeParams.filterId, 10);
					let selectedFilter = _.findWhere(filterList, { id: filterId, type: FilterTypes.CXSTUDIO});
					if (selectedFilter) {
						this.showFilterDialog(selectedFilter);
					}
					this.$location.url('filters');
				}
			});
		});
	}

	populateDateFiltersStates = (dateFolder, presetFiltersStates: {[id: number]: boolean}) => {
		dateFolder.forEach(dateFilter => {
			if (dateFilter.type === 'studioDateFilter') {
				let filterId = dateFilter.id.slice(dateFilter.id.indexOf('_') + 1);
				dateFilter.enabled = presetFiltersStates[filterId];
			}
		});
	}

	getDateFolder = () => {
		if (this.customDateRanges) {
			return this.folderSvc.getDateFolder();
		}

		return [];
	}

	getPredefinedFolder = (): any => {
		return {
			name: this.locale.getString('reportFilters.predefined'),
			id: FilterTypes.PREDEFINED,
			type: FilterItemType.FOLDER,
			description: '',
			generatedFolderType: GeneratedFolderType.SYSTEM
		};
	}

	getCustomDateFolder = (): any => {
		return {
			name: this.locale.getString('filter.dateFilters'),
			id: FilterTypes.CXSTUDIO,
			type: FilterItemType.FOLDER,
			description: '',
			generatedFolderType: GeneratedFolderType.CUSTOM
		};
	}

	createFilter = (): void => {
		this.actionsService.createFilter();
	}

	updateProjectData = () => {
		let projectId = this.project.projectId;
		this.filterList
			.filter(this.isFolder)
			.forEach((folder) => folder.folderProjectId =
				WorkspaceTransitionUtils.isProjectSelected(this.project) ? projectId : undefined);

		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.context.attributes = [];
			this.context.models = [];
			this.context.projectTimezone = undefined;
			this.refreshGrid();
			return this.$q.resolve();
		}

		if (InternalProjectTypes.isAdminProject(projectId)) {
			return this.filterUpdateService.getFilterableAttributes(this.project)
				.then((attributes) => {
					this.context.attributes = attributes;
					this.context.models = [];
					this.context.wordAttributes = [];
					this.context.projectTimezone = 0;
					this.refreshGrid(this.filterList);
				});
		}

		let filterPromises = [
			this.filterUpdateService.getFilterableAttributes(this.project),
			this.filterUpdateService.getModels(this.project),
			PromiseUtils.old(this.projectContextService.getProjectTimezone(this.project)),
			PromiseUtils.old(this.projectContextService.getProjectContext(this.project)),
			PromiseUtils.old(this.attributesService.getWordAttributes(this.project))
		];

		return this.$q.all(filterPromises).then((data: any[]) => {
			this.context.attributes = data[0];
			this.context.models = data[1];
			this.context.projectTimezone = data[2];
			this.context.projectContext = data[3];
			this.context.wordAttributes = data[4];
			this.refreshGrid(this.filterList);
		});
	}

	validFilterSelection = (contentProviderId: number, accountId: number) => {
		return ((!this.isNullOrUndefined(contentProviderId) && contentProviderId >= 0) && (!this.isNullOrUndefined(accountId) && accountId >= 0));
	}

	isNullOrUndefined = (val): boolean => {
		return (_.isUndefined(val) || val === null);
	}

	refreshGrid = (items?): void => {
		this.lastChange = [].concat(items);
	}

	createFolder = (folder): void => {
		const space = WorkspaceTransitionUtils.getWorkspace(this.project);
		if (!WorkspaceTransitionUtils.isWorkspaceSelected(space)) {
			this.errors.noProjectSelected = true;
			return;
		} else this.errors.noProjectSelected = false;

		this.folderSvc.createFolder(folder, this.filterList, space)
			.then((created) => {
				this.refreshGrid(created);
				this.globalNotificationService.addFolderNotification(created.name);
			});
	}

	canCreateFilter = (): boolean => {
		return this.security.has(MasterAccountPermissionAction.CREATE_FILTER);
	}

	notifyFilterWasAdded = (filter: IFilter): void => {
		this.globalNotificationService.addCreatedNotification(filter.name);
	}

	updateVisibleFilterList = () => {
		let filterListFn: (list: IFilter[], projectDetails: ProjectFilterData) => any;
		filterListFn = this.$filter('projectFilter');

		let projectName;
		if (WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			//let project = _.findWhere(this.projects, {projectId: this.props.projectId});
			projectName = this.project.projectName;
			// populate generated filters (sentiment, scorecards) with selected project name
			_.chain(this.filterList)
				.filter((filter: IFilter) => filter.subtype === 'sentiment')
				.each((filter: IFilter) => {
					filter.projectName = projectName;
				});
			_.chain(this.filterList)
				.filter((filter: IFilter) => filter.type === FilterTypes.CXDATE)
				.each((filter: IFilter) => {
					let supported = !InternalProjectTypes.isAdminProject(this.project.projectId)
						||  _.some(this.DateRange.adminProjectOptions, (option: any) => option.value === filter.value);
					filter.projectId = supported ? this.project.projectId : 0;
					filter.projectName = supported && projectName ? projectName : '';
				});
		}

		this.visibleFilterList = filterListFn(
			this.filterList,
			{
				singleProject: this.projectsAmount === OptionsAmount.SINGLE_OPTION,
				projectId: WorkspaceTransitionUtils.isProjectSelected(this.project) ? this.project.projectId : undefined
			}
		);

		if (InternalProjectTypes.isAdminProject(this.project.projectId)) {
			this.visibleFilterList = this.visibleFilterList.filter(filter => {
				return filter.id !== 'PREDEFINED' || filter.type === FilterTypes.PREDEFINED;
			});
		}

		this.populateTabFilterList();
	}

	private populateTabFilterList = (): void => {
		if (this.ui.selectedTab === FilterTabType.CUSTOM) {
			this.tabFilterList = _.filter(this.visibleFilterList, item => {
				return item.ownerId || item.generatedFolderType === GeneratedFolderType.CUSTOM;
			});
		} else {
			this.tabFilterList = _.filter(this.visibleFilterList, item => {
				return !item.ownerId && item.generatedFolderType !== GeneratedFolderType.CUSTOM;
			});
		}
	}

	loadPreSelectedFilter = (): ng.IPromise<IFilter | {}> => {
		if (this.$routeParams.filterId) {
			let filterId = parseInt(this.$routeParams.filterId, 10);
			return this.filterManagementApiService.getFilter(filterId);
		} else {
			return this.$q.when({});
		}
	}

	isTabSelected = (type: FilterTabType) => {
		return this.ui.selectedTab === type;
	}

	setTabSelected = (type: FilterTabType) => {
		this.ui.selectedTab = type;
	}

	selectTab = (type: FilterTabType) => {
		this.setTabSelected(type);
		this.populateTabFilterList();
		this.refreshGrid();
	}

	createNewButtonsDisabled = (): boolean => {
		return this.ui.selectedTab === FilterTabType.SYSTEM;
	}

	private updateProjectName = (filter: IFilter): void => {
		if (this.projects && this.projects.length > 0) {
			let projectName = _.findWhere(this.projects, {id: filter.projectId})?.name;
			if (projectName) {
				filter.projectName = projectName;
			}
		}
	}
}

app.component('reportFilterManagement', {
	controller: ReportFilterManagement,
	templateUrl: 'partials/filters/report-filter-management.component.html'
});
