<widget-box *ngIf="!isPdfExport() && isEditMode()"
	[widget]="widget"
	[headerDelay]="0"
	[fixedHeader]="true"
	[editable]="false"
	[widgetActions]="widgetActions"
	[menu]="menu"
></widget-box>
<ng-template #menu>
	<li (click)="widgetActions.emit(WidgetAction.DELETE)"
		class="border-b-radius-4 overflow-hidden">
		<a class="option danger-menu-item delete d-flex justify-between" href="javascript:void(0)" role="menuitem">
			<span [i18n]="'dashboard.delete'"></span>
			<i class="q-icon q-icon-trash"></i>
		</a>
	</li>
</ng-template>
