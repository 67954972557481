import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { ColorsCache } from '@cxstudio/dashboards/widgets/colors-cache.service';
import IDashboardWidgets from '@cxstudio/dashboards/widgets/dashboard-widgets.factory';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import * as _ from 'underscore';
import { WidgetsCache } from '@cxstudio/dashboards/widgets/widgets-cache.factory';
import { CommonDrillService } from '@app/modules/reports/utils/context-menu/drill/common-drill.service';


export default interface ICurrentWidgets {
	getDashboardContainer(containerId: string): IDashboardWidgets;
	getAllWidgets(containerId: string): Widget[];
	setWidgets(containerId: string, widgets: Widget[], dashboardHistory: IDashboardHistoryInstance): void;
	setBaseWidget(containerId: string, widgetId: number, widget: Widget): void;
	getBaseWidget(containerId: string, widgetId: number): Widget;
	getWidgetsCache(containerId: string): WidgetsCache;
	getLinkedFilters(containerId: string, widgetId: number): LinkedFilter[];
	getAllLinkedFilters(containerId: string): {[widgetId: number]: LinkedFilter[]};
	getLinkedFiltersProcessed(containerId: string, widgetId: number, isDocumentLevelOnly?: boolean): any[];
	setLinkedFilters(containerId: string, widgetId: number, filters: LinkedFilter[]): void;
	getPagination(containerId: string, widgetId: number): number;
	setPagination(containerId: string, widgetId: number, page: number): void;
	getPaginationTotal(containerId: string, widgetId: number): number;
	setPaginationTotal(containerId: string, widgetId: number, totalItem: number): void;
	getDashboardHistory(containerId: string): IDashboardHistoryInstance;
	getDashboardHistoryIfAvailable(containerId: string): IDashboardHistoryInstance | undefined;
	getExportParams(containerId: string): any;
	getSelectedSentence(containerId: string, widgetId: number): number;
	setSelectedSentence(containerId: string, widgetId: number, sentenceId: number): void;
	getPersonalization(containerId: string): PersonalizationState;
	setPersonalization(containerId: string, personalization: PersonalizationState): void;
	clear(): void;
}

class CurrentWidgets implements ICurrentWidgets {
	private dashboardContainers: {[containerId: string]: IDashboardWidgets} = {};
	constructor(
		private readonly DashboardWidgets,
		private readonly commonDrill: CommonDrillService,
		private readonly pointSelectionUtils: PointSelectionUtils,
		private readonly $rootScope,
		private readonly colorsCache: ColorsCache,
	) {}

	getDashboardContainer(containerId: string): IDashboardWidgets {
		let container = this.dashboardContainers[containerId] || new this.DashboardWidgets();
		if (!this.dashboardContainers[containerId]) {
			this.dashboardContainers[containerId] = container;
		}
		return container;
	}

	private containerExists(containerId: string): boolean {
		return !!this.dashboardContainers[containerId];
	}

	setWidgets(containerId: string, widgets: Widget[], dashboardHistory: IDashboardHistoryInstance): void {
		_.each(widgets, (widget) => {
			widget.containerId = containerId;
		});
		this.colorsCache.clear(containerId);

		let container = this.getDashboardContainer(containerId);
		container.setWidgets(widgets);
		container.setDashboardHistory(dashboardHistory);
		this.pointSelectionUtils.resetSelections(containerId);

		container.processExportParams(this.$rootScope);
	}

	getAllWidgets(containerId: string): Widget[] {
		return this.getDashboardContainer(containerId).getWidgets();
	}

	getWidgetsCache(containerId: string): WidgetsCache {
		return this.getDashboardContainer(containerId).getWidgetsCache();
	}

	getAllLinkedFilters(containerId: string): {[widgetId: number]: LinkedFilter[]} {
		return this.getDashboardContainer(containerId).getAllLinkedFilters();
	}

	getLinkedFilters(containerId: string, widgetId: number): LinkedFilter[] {
		return this.getDashboardContainer(containerId).getLinkedFilters(widgetId);
	}

	getLinkedFiltersProcessed(containerId: string, widgetId: number, documentLevelOnly = false): any[] {
		let filters = this.getLinkedFilters(containerId, widgetId);
		let widgetObject = {} as Widget;
		widgetObject.properties = { documentLevelOnly } as WidgetProperties;
		_.each(filters, (filter) => {
			this.commonDrill.addFiltersFromPoint(filter.widget, filter.point, widgetObject, true);
		});
		return widgetObject.properties.drillFilters;
	}

	setLinkedFilters(containerId: string, widgetId: number, filters: LinkedFilter[]): void {
		this.getDashboardContainer(containerId).setLinkedFilters(widgetId, filters);
	}

	getPagination(containerId: string, widgetId: number): number {
		return this.getDashboardContainer(containerId).getPagination(widgetId);
	}

	setPagination(containerId: string, widgetId: number, page: number): void {
		this.getDashboardContainer(containerId).setPagination(widgetId, page);
	}

	getPaginationTotal(containerId: string, widgetId: number): number {
		return this.getDashboardContainer(containerId).getPaginationTotal(widgetId);
	}

	setPaginationTotal(containerId: string, widgetId: number, totalItem: number): void {
		this.getDashboardContainer(containerId).setPaginationTotal(widgetId, totalItem);
	}

	getDashboardHistory(containerId: string): IDashboardHistoryInstance {
		return this.getDashboardContainer(containerId).getDashboardHistory();
	}

	// sometimes the code is executed from a place which don't have dashboard data populated (e.g. dashboard list)
	// currently the only difference from "getDashboardHistory" is the name
	getDashboardHistoryIfAvailable(containerId: string): IDashboardHistoryInstance | undefined {
		return this.containerExists(containerId) ? this.getDashboardContainer(containerId).getDashboardHistory() : undefined;
	}

	getSelectedSentence(containerId: string, widgetId: number): number {
		return this.getDashboardContainer(containerId).getSelectedSentence(widgetId);
	}

	setSelectedSentence(containerId: string, widgetId: number, sentenceId: number): void {
		this.getDashboardContainer(containerId).setSelectedSentence(widgetId, sentenceId);
	}

	setBaseWidget(containerId: string, widgetId: number, widget: Widget): void {
		this.getDashboardContainer(containerId).setBaseWidget(widgetId, widget);
	}

	getBaseWidget(containerId: string, widgetId): Widget {
		return this.getDashboardContainer(containerId).getBaseWidget(widgetId);
	}

	getExportParams(containerId: string): any {
		let container = this.getDashboardContainer(containerId);
		return {
			linkedFilters: container.getLinkedFiltersForExport(),
			pagination: container.getPaginationForExport(),
			selectedSentences: container.getSelectedSentencesForExport(),
			textFilters: container.getTextFiltersForExport()
		};
	}

	clear(): void {
		this.dashboardContainers = {};
	}

	setPersonalization(containerId: string, personalization: PersonalizationState): void {
		this.getDashboardContainer(containerId).setPersonalization(personalization);
	}

	getPersonalization(containerId: string): PersonalizationState {
		return this.getDashboardContainer(containerId).getPersonalization();
	}
}

app.service('currentWidgets', CurrentWidgets);
