import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CxDialogService, ModalSize } from '../dialog/cx-dialog.service';
import { DocumentExplorerModalComponent, IDocumentExplorerInput } from './document-explorer-modal/document-explorer-modal.component';

@Injectable({
	providedIn: 'root'
})
export class DocumentExplorerDialogService {

	constructor(
		private cxDialogService: CxDialogService
	) {}

	open = (input: IDocumentExplorerInput, fullscreen = false): NgbModalRef => {
		return this.cxDialogService.openDialog(DocumentExplorerModalComponent, input, {
			container: '#modal-container',
			size: ModalSize.EXTRA_LARGE,
			class: 'document-preview-modal-window' + (fullscreen ? ' modal-fullscreen' : ''),
		});
	}
}

app.service('documentExplorerDialogService', downgradeInjectable(DocumentExplorerDialogService));
