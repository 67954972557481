import PlotLineStyle from '@app/modules/plot-lines/plot-lines/plot-line-style';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';
import * as _ from 'underscore';

export class PlotLineConstants {
	private static readonly DEFAULT_WIDTH = 3;
	private static readonly types = [
		PlotLineType.NoLine,
		PlotLineType.Solid,
		PlotLineType.ShortDash,
		PlotLineType.ShortDot,
		PlotLineType.ShortDashDot,
		PlotLineType.ShortDashDotDot,
		PlotLineType.Dot,
		PlotLineType.Dash,
		PlotLineType.LongDash,
		PlotLineType.DashDot,
		PlotLineType.LongDashDot,
		PlotLineType.LongDashDotDot
	];

	static getLineStyles() {
		return _.map(this.types, type => new PlotLineStyle(type, this.DEFAULT_WIDTH, 'black'));
	}

	static getLineWidthStyles() {
		return _.map([1, 2, 3, 4, 5], width => new PlotLineStyle(PlotLineType.Solid, width, 'black'));
	}
}
