<div [ngBusy]="[projectsLoading, modelsLoading]" class="flex-direction-column">
	<ng-container *ngIf="!isWorkspaceEnabled">
		<project-selector
			class="d-flex l-margin-offset-5"
			horizontal="true"
			[disable]="disabled"
			[projectSelection]="projectSelection"
			(projectSelectionChange)="onProjectSelectionChange($event)"
			(errorsChange)="onCpErrorsChange($event)"
			(loading)="onProjectsLoading($event)"
		></project-selector>
		<alert type="danger" *ngFor="let cperror of contentProviderErrors">
			<span>{{ cperror }}</span>
		</alert>
	</ng-container>
	<workspace-project-selector
		*ngIf="isWorkspaceEnabled"
		[(value)]="workspaceProject"
		labelPosition="top"
		[help]="true"
		[inline]="true"
		(workspaceChange)="onWorkspaceChange($event)"
		(valueChange)="onProjectChange($event)"
		(loading)="onProjectsLoading($event)"
	></workspace-project-selector>

	<inclusion-list
		[allItems]="availableModels"
		[selectedItems]="selectedModels"
		(selectedItemsChange)="updateSelectedModels($event)"
		[disabled]="disabled"
		[selectedItemsLabelCallback]="selectedModelsLabelCallback"
		[availableItemsLabelCallback]="getAvailableModelsTextCallback"
		[availableItemsTooltipCallback]="getAvailableItemsTooltip"
	></inclusion-list>

	<div class="d-flex justify-end mt-24">
		<button type="button"
			class="btn btn-primary"
			(click)=saveChanges()
			[i18n]="'administration.save'"
			[disabled]="!hasChangesInModelsList"
		></button>
		<button type="button"
			class="btn btn-secondary"
			(click)=revertChanges()
			[i18n]="'administration.cancel'"
			[disabled]="!hasChangesInModelsList"
		></button>
	</div>
</div>
