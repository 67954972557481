import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { Alignment } from '@cxstudio/reports/formatting/alignment.enum';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { GenericFormatterService, IFormatBuilder } from './generic-formatter.service';
import { INumberFormatBuilder } from './number-format-builder.interface';
import { NumberFormatterBuilderService } from './number-formatter-builder.service';
import { ObjectUtils } from '@app/util/object-utils';

@Injectable({
	providedIn: 'root'
})
export class NumberDelimiterFormatterBuilderService implements INumberFormatBuilder {

	defaults: NumberFormatSettings;

	constructor(
		private readonly formatter: GenericFormatterService
	) {
		this.defaults = {
			decimals: Decimals.ONE,
			truncation: Truncation.NONE,
			alignment: Alignment.RIGHT,
			conversion: MetricMultiplierType.NONE,
			thousandsDelimiter: undefined	// using undefined here will cause this to use the locale default character
		};
	}

	getBuilder(defaultOption?: Partial<NumberFormatSettings>): IFormatBuilder {
		let options: NumberFormatSettings = $.extend(ObjectUtils.copy(this.defaults), defaultOption);
		return this.formatter.getFormatter(options);
	}
}

app.service('numberDelimiterFormatterBuilder', downgradeInjectable(NumberFormatterBuilderService));
