import { Injectable } from '@angular/core';
import { SidebarEditorService } from '@app/modules/layout/sidebar-editor/sidebar-editor.service';
import { CloudSettingsComponent } from '@app/modules/widget-settings/cloud-settings/cloud-settings.component';
import { WidgetPreviewComponent } from '@app/modules/widget-settings/widget-preview/widget-preview.component';
import Widget from '@cxstudio/dashboards/widgets/widget';

@Injectable({ providedIn: 'root' })
export class SidebarWidgetEditorService {
	constructor(
		private sidebarEditorService: SidebarEditorService
	) {}

	open(widget: Widget): Promise<Widget> {
		return this.sidebarEditorService.open(widget,
			CloudSettingsComponent, WidgetPreviewComponent, '#main-content');
	}
}
