import { EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils } from '@app/util/change-utils';
import { AccountsMap } from '../user-create-wizard.component';

@Component({
	selector: 'user-create-linking-step',
	templateUrl: './user-create-linking-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateLinkingStepComponent implements OnInit, OnChanges{
	@Input() skipLinkUser: boolean;
	@Input() finishEnabled: boolean;
	@Input() selectedAccounts: AccountsMap;

	@Output() updateSkipLinkUser = new EventEmitter<boolean>();

	constructor(
		private wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.updateBtnAndValidity();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.skipLinkUser)) {
			this.updateBtnAndValidity();
		}
	}

	onSelectedAccountsUpdate (): void {
		this.updateBtnAndValidity();
	}

	private getSelectedAccountsAmount(): number {
		return _.chain(this.selectedAccounts).values().map(accountsArray => accountsArray.length).reduce((memo, num) => memo + num).value();
	}

	private updateBtnAndValidity(): void {
		let last: boolean = this.skipLinkUser;
		this.wizardStep.markAsLast(this.skipLinkUser);

		if (last) {
			this.wizardStep.setValid(this.finishEnabled);
		} else {
			this.wizardStep.setValid(this.getSelectedAccountsAmount() > 0);
		}
	}
}