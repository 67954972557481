import * as moment from 'moment';
import * as _ from 'underscore';
import CategoryIndexSearchMode from './category-index-search-mode';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { TimeGrouping } from '@cxstudio/reports/groupings/time-grouping';
import { DateInterval, PointMetadataUtils } from '@app/modules/plot-lines/reference-lines/point-metadata-utils';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TimeReferenceLinesIndexSearcher {

	constructor() {}

	getCategoryIndex = (
		datePickerDate: string, hierarchyData: any[], options: VisualProperties,
		utils: WidgetUtils, searchMode: CategoryIndexSearchMode
	): number => {
		let secondaryGroupCategoriesShouldBeConsidered: boolean = this.secondaryGroupCategoriesShouldBeConsidered(options, utils);
		if (secondaryGroupCategoriesShouldBeConsidered) {
			hierarchyData = this.getFullDataList(hierarchyData);
		}

		let dateIntervals = PointMetadataUtils.extractDateIntervals(hierarchyData,
			options.attributeSelections.primaryGroup as TimeGrouping);

		// We need to omit the timezone here to avoid the reference line shift
		let datePickerMoment: moment.Moment = moment(datePickerDate, 'YYYY-MM-DDTHH:mm:ss');

		return this.getIndex(dateIntervals, datePickerMoment, searchMode, secondaryGroupCategoriesShouldBeConsidered);
	}

	secondaryGroupCategoriesShouldBeConsidered = (options: VisualProperties, utils: WidgetUtils): boolean => {
		let cluster: boolean = options.cluster;

		return utils.widgetType === WidgetType.BAR
			&& DualDefinitionHelper.hasSecondaryGroup(options)
			&& !DualDefinitionHelper.isPop(options)
			&& (DualDefinitionHelper.isStacked(options, utils) || !cluster);
	}

	getFullDataList = (hierarchyData: any[]): any[] => {
		let pointsList = [];
		_.map(hierarchyData, hierarchyDataPoint => {
			pointsList.push(hierarchyDataPoint);
			let children = hierarchyDataPoint._children;
			if (children) {
				_.map(children, child => {
					pointsList.push(child);
				});
			}
		});
		return pointsList;
	}


	private getIndex = (dateIntervals: DateInterval[], datePickerMoment: moment.Moment, searchMode: CategoryIndexSearchMode,
		embdeddedDataShouldBeConsidered: boolean): number => {
		let index: number = this.getRelevantDateIntervalIndex(dateIntervals, datePickerMoment);
		if (index === -1) {
			index = this.calculateNearestAppropriateIndex(dateIntervals, datePickerMoment, searchMode);
		}

		if (index !== -1 && embdeddedDataShouldBeConsidered) {
			index = this.getMiddleSecondaryGroupIndex(index, datePickerMoment, dateIntervals);
		}

		return index;
	}

	private getRelevantDateIntervalIndex(dateIntervals: DateInterval[], datePickerMoment: moment.Moment): number {
		return _.findIndex(dateIntervals, (interval) => {
			return interval.from.isSameOrBefore(datePickerMoment) && interval.to.isSameOrAfter(datePickerMoment);
		});
	}

	private calculateNearestAppropriateIndex = (dateIntervals: DateInterval[],
			datePickerMoment: moment.Moment, searchMode: CategoryIndexSearchMode) => {
		switch (searchMode) {
			case CategoryIndexSearchMode.FROM:
				return this.getNearestFrom(dateIntervals, datePickerMoment);
			case CategoryIndexSearchMode.TO:
				return this.getNearestTo(dateIntervals, datePickerMoment);
			default:
				return -1;
		}
	}

	private getNearestFrom(dateIntervals: DateInterval[], datePickerMoment: moment.Moment): number {
		return _.findIndex(dateIntervals, (interval) => {
			return interval.from.isAfter(datePickerMoment);
		});
	}

	private getNearestTo(dateIntervals: DateInterval[], datePickerMoment: moment.Moment): number {
		let index = -1;
		for (let i = dateIntervals.length - 1; i >= 0; i--) {
			if (dateIntervals[i].to.isBefore(datePickerMoment)) {
				index = i;
				break;
			}
		}
		return index;
	}

	getMiddleSecondaryGroupIndex = (index: number, datePickerMoment: moment.Moment, dateIntervals: DateInterval[]): number => {
		let secondaryGroupingFirstCategoryIndex = index + 1;
		let secondaryGroupingCategoriesAmount = 0;

		for (let i = secondaryGroupingFirstCategoryIndex; i < dateIntervals.length; i++) {
			if (dateIntervals[i].from.isSameOrBefore(datePickerMoment) && dateIntervals[i].to.isSameOrAfter(datePickerMoment)) {
				secondaryGroupingCategoriesAmount++;
			} else {
				break;
			}
		}
		return index + Math.floor(secondaryGroupingCategoriesAmount / 2);
	}
}
