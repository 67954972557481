import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'simple-feature-toggle',
	template: `
	<div class="panel panel-default">
		<div class="panel-heading">{{label}}</div>
		<div class="panel-body">
			<cb-toggle
				[disabled]="disabled"
				[ngModel]="featureEnabled"
				(ngModelChange)="featureEnabledChange.emit($event)">
			</cb-toggle>
		</div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFeatureToggleComponent {

	@Input() featureEnabled: boolean;
	@Input() label: string;
	@Input() disabled: boolean = false;
	@Output() featureEnabledChange = new EventEmitter<boolean>();


}
