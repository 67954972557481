import { NgModule } from '@angular/core';
import { HierarchyGroupComponent } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-group.component';
import { HierarchyNodeComponent } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node.component';
import {
	PersonalizationStateProvider
} from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state-provider.service';
import { SharedModule } from '@app/shared/shared.module';
import { HierarchyTreeSelectorComponent } from './hierarchy-tree-selector/hierarchy-tree-selector.component';
import { HierarchyPublishingDialogComponent } from './hierarchy-publishing-dialog.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { OrganizationApiService } from './organization-api.service';
import { UnitsModule } from '../units/units.module';
import { HierarchyDependenciesApiService } from './services/hierarchy-dependencies-api-service';
import { HierarchyDependencyTypesProvider } from './services/hierarchy-dependency-types-provider';
import { AssetManagementModule } from '../asset-management/asset-management.module';
import { UploadResultComponent } from './upload/upload-result/upload-result.component';
import { HierarchyDeltaPublishingDialogComponent } from './hierarchy-delta-publishing-dialog.component';
import { HierarchyUploadWizardPageComponent } from './upload-wizard-page/hierarchy-upload-wizard-page.component';
import { WizardModule } from '@app/modules/wizard/wizard.module';
import { HierarchyStructureWizardPageComponent } from './upload-wizard-page/structure/hierarchy-structure-wizard-page.component';
import { HierarchyUsersWizardPageComponent } from './upload-wizard-page/users/hierarchy-users-wizard-page.component';
import { HierarchyFiltersWizardPageComponent } from './upload-wizard-page/filters/hierarchy-filters-wizard-page.component';
import { HierarchyEnrichmentWizardPageComponent } from './upload-wizard-page/enrichment/hierarchy-enrichment-wizard-page.component';
import { HierarchyFiltersProjectSelectorComponent } from './upload-wizard-page/filters/project-selector/hierarchy-filters-project-selector.component';
import { HierarchyEnrichmentAttributeSelectorComponent } from './upload-wizard-page/enrichment/attributes-selector/hierarchy-enrichment-attribute-selector.component';
import { HierarchyUploadWizardSubmitDialogComponent } from './upload-wizard-page/dialog/hierarchy-upload-wizard-submit-dialog.component';
import { OrganizationTableStatusTagComponent } from './upload-wizard-page/organizations-table-status-tag.component';
import {
	OrganizationStructureWizardUploadModalComponent
} from '@app/modules/hierarchy/structure/upload/organization-structure-wizard-upload-modal.component';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { OrganizationFiltersWizardUploadModalComponent } from './filters/upload/organization-filters-wizard-upload-modal.component';
import { OrganizationEnrichmentWizardUploadModalComponent } from '@app/modules/hierarchy/enrichment/upload/organization-enrichment-wizard-upload-modal.component';
import { NewOrganizationDialogComponent } from './new-organization-dialog.component';
import {
	SelectAttributeModalTypesService
} from '@app/modules/hierarchy/attributes/select-attribute-modal-types-service';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		UnitsModule,
		AssetManagementModule,
		WizardModule,
		CxFormModule,
	],
	declarations: [
		HierarchyTreeSelectorComponent,
		HierarchyNodeComponent,
		HierarchyGroupComponent,
		HierarchyPublishingDialogComponent,
		HierarchyDeltaPublishingDialogComponent,
		UploadResultComponent,
		HierarchyUploadWizardPageComponent,
		HierarchyStructureWizardPageComponent,
		HierarchyUsersWizardPageComponent,
		HierarchyFiltersWizardPageComponent,
		HierarchyEnrichmentWizardPageComponent,
		HierarchyFiltersProjectSelectorComponent,
		HierarchyEnrichmentAttributeSelectorComponent,
		HierarchyUploadWizardSubmitDialogComponent,
		OrganizationTableStatusTagComponent,
		OrganizationStructureWizardUploadModalComponent,
		OrganizationFiltersWizardUploadModalComponent,
		OrganizationEnrichmentWizardUploadModalComponent,
		NewOrganizationDialogComponent,
	],
	exports: [
		HierarchyTreeSelectorComponent,
		UploadResultComponent,
		OrganizationStructureWizardUploadModalComponent,
		OrganizationFiltersWizardUploadModalComponent,
		OrganizationEnrichmentWizardUploadModalComponent,
		OrganizationTableStatusTagComponent,
		NewOrganizationDialogComponent
	],
	providers: [
		PersonalizationStateProvider,
		OrganizationApiService,
		HierarchyDependenciesApiService,
		HierarchyDependencyTypesProvider,
		SelectAttributeModalTypesService
	],
})
export class HierarchyModule { }
