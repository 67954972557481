export const ROOT_FOLDER_ID = 0;

export const Folders = {
	ROOT_FOLDER: {
		id: ROOT_FOLDER_ID,
		name: '.',
		displayName: ' ',
		class: 'blank',
		nameWithPath: ' '
	}
};



