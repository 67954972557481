export class ProjectListIdentifier {
	contentProviderId: number;
	accountId: number;
	projectIds: number[];
	
	constructor(contentProviderId, accountId, projectIds) {
		this.contentProviderId = contentProviderId;
		this.accountId = accountId;
		this.projectIds = projectIds;
	}
}