<menu role="menu" *ngIf="!layoutHelperService.isZoomModeOn()">
	<a (click)="refreshAll()"
		class="dropdown-item option refresh"
		[i18n]="'dashboard.refreshAll'"
		href="javascript:void(0)"
		role="menuitem"></a>

	<a *ngIf="!forbiddenToCopy()"
		(click)="widgetsEditService.copySelectedWidgets()"
		class="copy-strike-through dropdown-item option duplicate"
		[i18n]="'dashboard.duplicateAll'"
		href="javascript:void(0)"
		role="menuitem"></a>

	<copy-submenu *ngIf="!forbiddenToCopy()"
		[dashboard]="dashboard"
		label="{{'widget.copySelectedTo' | i18n}}"
		[getWidgets]="widgetsEditService.getSelectedWidgets"
		(targetSelected)="closeMenu.emit()">
	</copy-submenu>
</menu>
<menu role="menu">
	<push-widget-menu
		[isBulk]="true">
	</push-widget-menu>

	<span class="divider d-block" role="separator"></span>

	<div role="group" *ngIf="!layoutHelperService.zoomLevel">
		<a (click)="widgetsEditService.removeSelectedWidgets()"
			class="dropdown-item border-b-radius-4 overflow-hidden option danger-menu-item delete d-flex justify-between"
			href="javascript:void(0)"
			role="menuitem">
			<span [i18n]="'dashboard.deleteAll'"></span>
			<icon name="trash"></icon>
		</a>
	</div>
</menu>
