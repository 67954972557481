import { CancellableDeferred } from './cancellable-deferred';


export class CancellableDeferredFactory {
	constructor(
		private $q: angular.IQService) {
	}

	defer = (): CancellableDeferred<any> => {
		let defer = this.$q.defer();
		return new CancellableDeferred(defer);
	}

	isCancelledPromise = (promise: any): boolean => {
		return promise.cancelled === true;
	}
}

// tslint:disable-next-line: only-arrow-functions & typedef
app.factory('cancellableDeferredFactory', function($q) {
	return new CancellableDeferredFactory($q);
});
