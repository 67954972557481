import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

export enum LoadingErrorType {
	noAccess = 'noAccess',
	message = 'message',
}

@Component({
	selector: 'embedded-error-container',
	templateUrl: './embedded-error-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedErrorContainerComponent {
	@Input() errorType: LoadingErrorType;
	@Input() message: string;
	@Output() reload = new EventEmitter<void>();

	constructor() {}

	isGenericError(): boolean {
		return this.errorType === LoadingErrorType.message;
	}
}
