import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SubscriptionsService } from '@app/modules/user-administration/groups/alert-subscription/subscriptions.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CxDialogService, ModalSize } from '../dialog/cx-dialog.service';
import { AlertEditorModalComponent } from './alert-editor-modal/alert-editor-modal.component';

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	constructor(
		private readonly cxDialogService: CxDialogService,
		private readonly subscriptionsService: SubscriptionsService
	) { }

	openAlertEditor(projectSelection, alert?): Promise<NgbModalRef> {
		let alertToPass = alert;
		let alertPromise = Promise.resolve();

		if (alert) {
			alertPromise = this.subscriptionsService.getAlertDetail(
				projectSelection.contentProviderId, alert);
		}

		return alertPromise.then((alertDetails: any) => {
			if (!!alertDetails) {
				alertToPass = alertDetails;
			}

			let input = {
				props: projectSelection,
				alert: alertToPass
			};
			return this.cxDialogService.openDialog(AlertEditorModalComponent, input, { size: ModalSize.MEDIUM });
		});
	}


	addAlert(projectSelection): Promise<any> {
		return this.openAlertEditor(projectSelection).then(modal => {
			return modal.result.then(alert => {
				return this.subscriptionsService.addAlert(projectSelection.contentProviderId, alert).then(result =>  {
					let newAlert = result;
					newAlert.id = this.getUniqueId(newAlert);
					return newAlert;
				});
			});
		});
	}

	editAlert(projectSelection, alert): Promise<any> {
		return this.openAlertEditor(projectSelection, alert).then(modal => {
			return modal.result.then(alertResult =>  {
				return this.subscriptionsService.updateAlert(projectSelection.contentProviderId, alertResult).then(result =>  {
					_.extend(alertResult, result);
					alertResult.id = this.getUniqueId(alertResult);
					return alertResult;
				});
			});
		});
	}

	getUniqueId(alert): string {
		return `${alert.projectId}_${alert.alertId}`;
	}
}

app.service('alertService', downgradeInjectable(AlertService));
