import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';

@Component({
	selector: 'report-property-switcher',
	templateUrl: './report-property-switcher.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportPropertySwitcherComponent implements OnInit {
	@Input() trigger: boolean;
	@Input() visualProperties: VisualProperties;
	@Input() property: string;
	@Input() label: string;
	@Input() enableText: string;
	@Input() disableText: string;
	@Input() enableClass: string;
	@Input() disableClass: string;
	@Output() updateChart: EventEmitter<void> = new EventEmitter();

	constructor(
		private readonly locale: CxLocaleService
	) { }

	ngOnInit(): void {
		this.enableText = this.enableText || this.locale.getString('common.show');
		this.disableText = this.disableText || this.locale.getString('common.hide');
		this.enableClass = this.enableClass || '';
		this.disableClass = this.disableClass || '';
	}

	onChangeInternal(): void {
		this.updateChart.emit();
	}
}

app.directive('reportPropertySwitcher', downgradeComponent({ component: ReportPropertySwitcherComponent }));
