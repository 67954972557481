import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportAsset } from '@cxstudio/reports/entities/report-asset';

@Injectable({
	providedIn: 'root'
})
export class NumberFormatHelperService {

	// fields that cannot be undefined
	readonly REQUIRED_FIELDS = ['decimals', 'truncation', 'conversion'];

	// fields that are allowed to be undefined
	readonly OPTIONAL_FIELDS = ['decimalDelimiter', 'thousandsDelimiter', 'prefix', 'suffix'];
	readonly DEPRECATED_FIELDS = ['dataType'];

	// all fields
	fields: Array<string>;

	constructor(
		@Inject('MetricCalculationsType') private readonly MetricCalculationsType: MetricCalculationsTypeService
	) {
		this.fields = [].concat(this.OPTIONAL_FIELDS).concat(this.REQUIRED_FIELDS).concat(this.DEPRECATED_FIELDS);
	}

	// does an object have any properties associated with formatting options
	hasFormattingOptions(object): boolean {
		for (let field of this.fields) {
			if (object[field] !== undefined) return true;
		}

		return false;
	}

	itemHasDefaultFormat = (metric: Metric): boolean => {
		return !!metric?.format && this.hasDefaultFormat(metric);
	}

	private hasDefaultFormat(metric: Metric): boolean {
		return this.MetricCalculationsType.TOP_BOX.is(metric)
			|| this.MetricCalculationsType.BOTTOM_BOX.is(metric)
			|| this.MetricCalculationsType.SATISFACTION.is(metric)
			|| this.MetricCalculationsType.FILTER_METRIC.is(metric)
			|| this.MetricCalculationsType.CUSTOM_MATH.is(metric)
			|| this.MetricCalculationsType.VARIABLE.is(metric);
	}
}

app.service('numberFormatHelper', downgradeInjectable(NumberFormatHelperService));
