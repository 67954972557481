import { HighchartsVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization.interface';
import { VisualizationComponent } from '@app/modules/widget-visualizations/visualization-component.interface';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import * as cloneDeep from 'lodash.clonedeep';

export class EventHandlerUtils {

	static getProcessedPoint(component: HighchartsVisualization & VisualizationComponent<ReportDataObject>): DrillPoint {
		if (component.selectedPoint && !(component.chart as any)?.cancelClick) {
			let point = cloneDeep(component.selectedPoint);
			point._element = component.selectedPoint._element;
			point.isWeekDescription = component.dataObject.metadata.isWeekDescription;
			//add information about dateRangeP1
			point.P1StartDate = component.dataObject.metadata.P1StartDate;
			point.P1EndDate = component.dataObject.metadata.P1EndDate;
			point.dateRangeP2 = component.dataObject.metadata.dateRangeP2;
			return point;
		}
		return null;
	}
}
