import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DateFilterMode, HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

export const DASHBOARD_PROPS = {
		properties: {
			sampling: 'SAMPLE',
			viewOnlyDrill: true,
			defaultShowTotal: true,
			cbContentProvider: 10,
			cbAccount: 0,
			project: 25181,
			preventDashboardEditorsShare: true,
			userPlacementEnabled: false,
			userPlacementColor: '#67a0e6',
			customColor: '#3F51B5',
			color: 'clarabridge',
			optimization: false
		},
		type: ObjectType.DASHBOARD,
		name: 'Dashboard 9 3',
		description: '',
		labels: [],
		appliedFiltersArray: []
};

export const DASHBOARD_WIDGET = {
		type: 'CB',
		properties: {
			workspaceProject: {},
			altTextFromTitle: true,
			altText: 'Metric : PS test - top box',
			widgetType: WidgetType.METRIC,
			appliedFilters: {
				type: FilterTypes.AND,
				filters: []
			},
			groupingFilters: {},
			selectedMetrics: [],
			dateRangeP1: {
				dateFilterMode: DateFilterMode.LAST_30_DAYS
			},
			dateRangeP2: {
				dateFilterMode: HistoricDateFilterMode.PREVIOUS_PERIOD
			},
			useHistoricPeriod: false
		},
		visualProperties: {
			visualization: WidgetVisualization.CB_AN_METRIC,
			showSampleSize: true,
			periodLabel: {},
			attributeSelections: {}
		},
		posX: 0,
		posY: 0,
		width: 4,
		height: 11,
		displayName: 'Metric : PS test - top box',
		template: false,
		drillDepth: 0,
		name: WidgetType.METRIC,
};
