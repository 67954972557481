import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { JmsMessagesState } from '@app/modules/system-administration/platform/jms-failed-messages/jms-messages-state';

@Injectable({
	providedIn: 'root'
})
export class FailedMessagesApiService {

	constructor(
		private cxHttp: CxHttpService,
	) {}

	getFailedMessages(): Promise<JmsMessagesState> {
		return this.cxHttp.get('rest/failed-messages');
	}

	processAlertMessage(messageId: number): Promise<void> {
		return this.cxHttp.post(`rest/failed-messages/alert/${messageId}/process`);
	}

	processCompletionMessage(messageId: number): Promise<void> {
		return this.cxHttp.post(`rest/failed-messages/completion/${messageId}/process`);
	}
}
