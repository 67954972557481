export interface NavigableModal {
	type: NavigableModalType;
	mainContentSelector: string;
}

export enum NavigableModalType {
	DOCUMENT_EXPLORER = 'DOCUMENT_EXPLORER'
}

export class BypassModalBlockHelper {

	private static supportedModals: NavigableModal[] = [
		{
			type: NavigableModalType.DOCUMENT_EXPLORER,
			mainContentSelector: 'primary-pane :focusable'
		}
	];

	static showBypassModalBlock(): boolean {
		return !!this.getNavigableModalType();
	}

	static getCurrentModal(): NavigableModal {
		let modalType: NavigableModalType = this.getNavigableModalType();
		return this.getNavigableModal(modalType);
	}

	private static getNavigableModalType(): NavigableModalType {
		if (this.isDocumentExplorer()) {
			return NavigableModalType.DOCUMENT_EXPLORER;
		}
	}

	static isDocumentExplorer(): boolean {
		let DOCUMENT_EXPLORER_SELECTOR = 'document-explorer-modal';
		return $(DOCUMENT_EXPLORER_SELECTOR).length === 1;
	}

	private static getNavigableModal(type: NavigableModalType): NavigableModal {
		return _.findWhere(this.supportedModals, {type});
	}

}
