import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WidgetDescriptionUtils } from './widget-description-utils';

export interface WidgetDescriptionBuilder {
	getDescription: (data?: any) => string | Promise<string>;
	getAriaLabel: (widget: Widget, data?: any) => Promise<string>;
}

export class BasicWidgetDescriptionBuilder implements WidgetDescriptionBuilder {

	widget: Widget;
	properties: WidgetProperties;
	visual: VisualProperties;
	utils: WidgetDescriptionUtils;
	locale: CxLocaleService;

	constructor(widget: Widget, utils: WidgetDescriptionUtils) {
		this.widget = widget;
		this.properties = widget.properties;
		this.visual = widget.visualProperties;
		this.utils = utils;
		this.locale = this.utils.getLocaleService();
	}

	getDescription = (...args) => '';
	getAriaLabel = (...args) => Promise.resolve('');
}
