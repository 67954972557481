import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { MasterAccountConnectorsStatus } from './master-account-connectors-status';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable } from '@angular/core';
import { ConnectorsStatusApiService } from '@app/modules/system-administration/status/integration-status/connectors/connectors-status-api.service';
import { ConnectorsStatusTableColumnsService } from '@app/modules/system-administration/status/integration-status/connectors/connectors-status-table-columns.service';

@Injectable({providedIn: 'root'})
export class ConnectorsStatusService implements IntegrationStatusService<MasterAccountConnectorsStatus> {
	constructor(
		private connectorsStatusApiService: ConnectorsStatusApiService,
		private connectorsStatusTableColumnsService: ConnectorsStatusTableColumnsService,
	) {}

	getPageKey(): string {
		return 'mAccount.connectorsIntegration';
	}

	getColumns(): TableColumn<MasterAccountConnectorsStatus>[] {
		return this.connectorsStatusTableColumnsService.getColumns();
	}

	getData(): Promise<MasterAccountConnectorsStatus[]> {
		return this.connectorsStatusApiService.getConnectorsStatuses();
	}

	exportData(search: IntegrationSatusSearch): Promise<void> {
		return this.connectorsStatusApiService.export(search);
	}
}
