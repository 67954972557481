<div cdkDropList
	[cdkDropListData]="comparisons"
	(cdkDropListDropped)="onDrop($event)">
	<div *ngFor="let comparison of comparisons; index as $index">
		<hr *ngIf="$index > 0"/>
		<div class="d-flex" cdkDrag [cdkDragDisabled]="comparisons.length < 2">
			<cb-drag-handle *ngIf="comparisons.length > 1" cdkDragHandle class="mr-8 pt-8"></cb-drag-handle>
			<comparison-item
				class="flex-fill d-flex flex-direction-column overflow-hidden"
				[comparison]="comparison"
				(comparisonChange)="onComparisonChanged($event, $index)"
				(update)="update.emit()"
				[options]="getComparisonOptions(comparison)"
				[primaryDateFilterMode]="props.dateRangeP1.dateFilterMode"
				[projectTimezone]="projectTimezone"
				[dateFilters]="dateFilters"
				[hierarchyEnrichments]="hierarchyEnrichments"
				[metric]="props.selectedMetrics.length === 1 ? props.selectedMetrics[0] : undefined"
				[metrics]="metrics">
				<button class="btn btn-icon no-border color-inherit ml-8 cb-comparison-remove"
					(click)="removeComparison(comparison)" title="{{'widget.minus'|i18n}}" [attr.aria-label]="'widget.minus'|i18n">
					<span class="q-icon q-icon-minus" aria-hidden="true"></span>
				</button>
				<button class="btn btn-icon no-border color-inherit ml-8 cb-comparison-add"
					*ngIf="canAddComparisons()"
					(click)="addComparison()"
					title="{{'common.add'|i18n}}"
					[attr.aria-label]="'common.add'|i18n">
					<span class="q-icon q-icon-add" aria-hidden="true"></span>
				</button>
			</comparison-item>
		</div>
	</div>
	<div *ngIf="showNewComparison()">
		<hr *ngIf="comparisons.length > 0"/>
		<simple-dropdown
			class="flex-fill dropdown-selector _t-comparison-type"
			[options]="getComparisonOptions()"
			(onChange)="onComparisonSelected($event)"
			displayField="displayName"
			valueField="type"
			appendToBody="true">
		</simple-dropdown>
	</div>
</div>
<p *ngIf="hierarchyEnrichmentError"
	class="help-block text-danger mt-4"
	[innerHTML]="hierarchyEnrichmentError">
</p>
